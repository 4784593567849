import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { BatchLightEditorComponent } from './batch-light-editor/batch-light-editor.component';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { WidgetsModule } from 'src/app/widgets/widgets.module';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { PipesModule } from 'src/app/providers/pipes/pipes.module';
import { SingleEditorComponent } from './single-editor/single-editor.component';
import {MatToolbarModule} from "@angular/material/toolbar";
import {ReactiveFormsModule} from "@angular/forms";
import { NoLocationComponent } from './no-location/no-location.component';
import {MatLegacyDialogModule as MatDialogModule} from "@angular/material/legacy-dialog";
import { BatchEditorFilesComponent } from './batch-editor-files/batch-editor-files.component';
import {ConnectedFilesModule} from "../connected-files/connected-files.module";
import {MatLegacyTabsModule as MatTabsModule} from "@angular/material/legacy-tabs";
import { BatchEditorAssignSchedeManutenzioneComponent } from './batch-editor-assign-schede-manutenzione/batch-editor-assign-schede-manutenzione.component';
import {BoxesModule} from "../../boxes/boxes.module";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {MatBadgeModule} from "@angular/material/badge";
import { BatchRemoteControlSettingsComponent } from './batch-remote-control-settings/batch-remote-control-settings.component';
import {MatLegacyListModule as MatListModule} from "@angular/material/legacy-list";
import { BatchVirtualMidnightComponent } from './batch-virtual-midnight/batch-virtual-midnight.component';
import {VirtualMidNightModule} from "../virtual-mid-night/virtual-mid-night.module";
import {MatLegacyMenuModule as MatMenuModule} from "@angular/material/legacy-menu";
import {MatRippleModule} from "@angular/material/core";
import { BatchEditorFotoTipologiaComponent } from './batch-editor-foto-tipologia/batch-editor-foto-tipologia.component';



@NgModule({
  declarations: [BatchLightEditorComponent, SingleEditorComponent, NoLocationComponent, BatchEditorFilesComponent, BatchEditorAssignSchedeManutenzioneComponent, BatchRemoteControlSettingsComponent, BatchVirtualMidnightComponent, BatchEditorFotoTipologiaComponent],
    imports: [
        CommonModule,
        MatCardModule,
        TranslateModule,
        MatTableModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatIconModule,
        MatButtonModule,
        MatSortModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatTooltipModule,
        WidgetsModule,
        MatInputModule,
        PipesModule,
        MatToolbarModule,
        ReactiveFormsModule,
        MatDialogModule,
        ConnectedFilesModule,
        MatTabsModule,
        BoxesModule,
        ScrollingModule,
        MatBadgeModule,
        MatListModule,
        VirtualMidNightModule,
        MatMenuModule,
        MatRippleModule,
    ],
  exports: [BatchLightEditorComponent, SingleEditorComponent, NoLocationComponent]
})
export class BatchLightEditorModule { }
