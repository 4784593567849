<app-loader [loaderInput]="loading"></app-loader>

<ng-container *ngIf="searchEnabled">
  <div style="display: flex; flex-direction:column;  margin-top: 0.5rem;">
    <form [formGroup]="searchForm" class="form-search">
      <mat-form-field appearance="fill" floatLabel="always">
        <mat-label>{{'paginator.search' | translate}}</mat-label>
        <input formControlName="search" matInput>
      </mat-form-field>
    </form>
  </div>
</ng-container>


<div class="list-paginator" *ngIf="totalCount > take">
  <mat-paginator [length]="totalCount" [pageSize]="take" [pageIndex]="Math.floor(skip/take)" (page)="onChangePage($event)" showFirstLastButtons>
  </mat-paginator>
</div>

<div class="items">
  <ng-container class="item" *ngFor="let item of items">
    <ng-container *ngTemplateOutlet="template; context: getTemplateContext(item)"></ng-container>
  </ng-container>
</div>

<div class="list-paginator" *ngIf="totalCount > take">
  <mat-paginator [length]="totalCount" [pageSize]="take" [pageIndex]="Math.floor(skip/take)" (page)="onChangePage($event)" showFirstLastButtons>
  </mat-paginator>
</div>

<ng-container *ngIf="error">
  <div fxLayout="column" [style.height]="'100%'" fxLayoutAlign="left left" class="cmp">
    <app-template-form title="{{'error' | translate}}!" [informativa]="false">
      <div class="spacer">{{errorKey | translate}}.<br>
        {{'platform_mainten' | translate}}
        <a class="email" style="text-decoration: underline" href="mailto:info@huna.io" label="Email">info@huna.io</a>
      </div>
    </app-template-form>
  </div>
</ng-container>

<ng-container *ngIf="!loading && !error && totalCount === 0">
  <div fxLayout="column" [style.height]="'100%'" fxLayoutAlign="left left" class="cmp">
    <app-template-form title="{{noResultKey | translate}}" [informativa]="false"></app-template-form>
  </div>
</ng-container>