import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ChipsFromArrayService} from "../../providers/services/chips-from-array.service";
import {isNotNullOrUndefined, stringIsSet} from "../../models/Models";

@Component({
    selector: 'app-chips-from-array-label',
    templateUrl: './chips-from-array-label.component.html',
    styleUrls: ['./chips-from-array-label.component.scss']
})
export class ChipsFromArrayLabelComponent implements OnInit, OnChanges {

    @Input() arrayValue: any[] = [];
    @Input() styleChips: 'drop' | 'rounded' = 'rounded';
    @Input() label: string;
    @Input() selectedChips = [];
    @Output() clickChips = new EventEmitter<{ value: string, }>()
    @Input() public distinctValue;
    @Input() disabled = false;
    @Input() required = false;

    constructor(private chipsFromArrayService: ChipsFromArrayService) {
    }

    ngOnInit(): void {

    }


    ngOnChanges(changes: SimpleChanges): void {
        if (!isNotNullOrUndefined(this.distinctValue)) {
            this.distinctValue = this.chipsFromArrayService.getDistinctValues(this.arrayValue, [this.label])[this.label];
        }
    }


    isSelected(chipName) {
        if (this.selectedChips.length > 0) {
            const index = this.selectedChips.findIndex(value => this.chipsFromArrayService.isEquals(value, chipName))
            return index >= 0
        } else {
            return false
        }
    }

    clickChip(chipName) {
        if(!this.disabled && (!this.required || this.selectedChips.length !== 1 || !this.isSelected(chipName))){
            this.clickChips.next({value: chipName})
        }
    }


    getValue(value) {
        if (typeof value == "number") {
            return value.toString();
        } else if (stringIsSet(value)) {
            return value;
        } else {
            return 'NaN';
        }
    }

}
