import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-container-child',
  templateUrl: './container-child.component.html',
  styleUrls: ['./container-child.component.scss']
})
export class ContainerChildComponent implements OnInit {

  @Input()
  width;

  constructor() { }

  ngOnInit(): void {
  }



}
