import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-box-progect',
    templateUrl: './box-progect.component.html',
    styleUrls: ['./box-progect.component.scss']
})
export class BoxProgectComponent implements OnInit {

    @Input()
    public provincia:string;
    @Input()
    public image;
    @Input()
    public logo;
    @Input()
    public numeroCircuito;
    @Input()
    public numeroPuntiLuce;
    @Input()
    public sigla:string;
    @Input()
    isNewProject: boolean = false;

    public loading:boolean=false;
    constructor() {
    }

    ngOnInit(): void {
        this.numeroCircuito = (!this.numeroCircuito) ? 0 : this.numeroCircuito;
        this.numeroPuntiLuce = (!this.numeroPuntiLuce) ? 0 : this.numeroPuntiLuce;
        if (this.sigla==undefined || this.sigla.trim().length==0) {
            this.sigla = this.provincia.substring(0, 3).toUpperCase();
        }
        this.loading=true;
    }

    public resizeText() {
         if (this.sigla.length < 3) {
            return null;

        } else if (this.sigla.length > 3 && this.sigla.length < 6) {
            return 10 + 'px';
        } else {
            this.sigla = this.sigla.substring(0, 5);
            return 10 + 'px';
        }
    }

    // cambia il colore di sfondo nel caso di new project
    public get backGroundColor(){
        const color=(this.isNewProject)?'#ffc60099':'#5dbed7';
        return color;
    }
}
