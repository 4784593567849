import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-container-a4',
    templateUrl: './container-a4.component.html',
    styleUrls: ['./container-a4.component.scss']
})
export class ContainerA4Component implements OnInit {

    constructor() {
    }

    @Input()
    page;
    @Input()
    projectName;
    @Input()
    nowDate;

    @Input()
    logoAzienda;
    @Input()
    nomeAzienda;


    get height() {
        if (navigator.userAgent.toUpperCase().includes('CHROME')) {
            return 296.9;
        } else {
            return 298;
        }
    }

    ngOnInit(): void {
    }

}
