import {Injectable} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {SegnalazioniParse} from "../../models/Segnalazioni.Parse";
import {ReportStates} from "../../../config/static-data";

@Injectable({
    providedIn: 'root'
})
export class ReportFormService {

    constructor(
        private fb: UntypedFormBuilder
    ) {
    }

    private keysForm = ['titolo', 'puntiLuce', 'circuito', 'foto', 'progetto', 'descrizione', 'stato', 'user', 'privata', 'priorita', 'tipologiaIntervento', 'codice', 'nomeAutore', 'presaInCaricoDa', 'ultimoAggiornamentoDa']


    private getValue(report: SegnalazioniParse | undefined, key: string) {
        if (key == "priorita") {
            return (report != null && report[key].toString) ? report[key].toString() : undefined
        } else {
            return (report != null) ? report[key] : undefined
        }
    }

    public getReportForm(report: SegnalazioniParse | undefined): UntypedFormGroup {
        const obj = {};
        this.keysForm.forEach(key => {
            obj[key] = this.getValue(report, key)
        })
        return this.fb.group(obj);
    }


    updateForm(form: UntypedFormGroup, report: SegnalazioniParse | undefined,disable:boolean) {
        this.keysForm.forEach(key => {
            if (form.contains(key)) {
                const value = this.getValue(report, key)
                if (value != null) {
                    form.get(key).setValue(value, {emitEvent: false})
                } else {
                    form.get(key).reset(null, {emitEvent: false})
                }
                if (disable) {
                    form.get(key).disable({emitEvent: false})
                } else {
                    form.get(key).enable({emitEvent: false})
                }
            }
        })
    }

    public getCreateReportForm(): UntypedFormGroup {
        return this.fb.group({
                titolo: ['', Validators.required],
                priorita: [''],
                tipologiaIntervento: [''],
                privata: [false],
                descrizione: ['', Validators.required],
                foto: [''],
            }
        );
    }

    public getCreateGroupForm(): UntypedFormGroup {
        return this.fb.group({
                nome: ['', Validators.required]
            }
        );
    }

}
