import {Injectable} from '@angular/core';
import {ProgrammiMVParse} from "../../models/ProgrammiMV.Parse";
import {fromPromise} from "rxjs/internal-compatibility";
import * as Parse from 'parse';
import {CircuitiParse} from "../../models/Circuiti.Parse";
import {UserService} from './user.service';
import {PuntiLuceParse} from "../../models/PuntiLuce.Parse";
import {ProjectService} from "./project.service";
import {catchError, expand, map, switchMap} from "rxjs/operators";
import {combineLatest, EMPTY, Observable, of, throwError} from "rxjs";
import {arrayIsSet, PaginationParserequestType, stringIsSet} from "../../models/Models";

@Injectable({
    providedIn: 'root'
})
export class ProgrammiMvService {

    constructor(private userService: UserService,) {
    }

    public getProgrammiMv(circuitoId) {
        const query = new Parse.Query(ProgrammiMVParse);
        const circuito = new CircuitiParse();
        circuito.id = circuitoId;
        query.equalTo("circuito", circuito);
        query.descending('createdAt');
        query.limit(30);
        return fromPromise(query.find({sessionToken: this.userService.sessionToken()}));
    }

    public getProgrammiMvWithDeviceIdGruppoId(circuitoId: string, deviceId: string, gruppoId: string): Observable<ProgrammiMVParse[]> {
        const queries = [];
        if (deviceId == null) {
            return throwError('Error - device id required');
        }
        const queryDevice = new Parse.Query(ProgrammiMVParse);
        const circuito = new CircuitiParse();
        circuito.id = circuitoId;
        queryDevice.equalTo("circuito", circuito);
        queryDevice.equalTo('deviceId', deviceId);
        queryDevice.descending('createdAt');
        queries.push(queryDevice);


        const queryGruppo1 = new Parse.Query(ProgrammiMVParse);
        queryGruppo1.equalTo("circuito", circuito);
        queryGruppo1.doesNotExist('groupId');
        const queryGruppo2 = new Parse.Query(ProgrammiMVParse);
        queryGruppo2.equalTo("circuito", circuito);
        queryGruppo1.doesNotExist('deviceId');


        queries.push(Parse.Query.and(queryGruppo1, queryGruppo2))

        const queryGruppo3 = new Parse.Query(ProgrammiMVParse);
        queryGruppo3.equalTo("circuito", circuito);
        queryGruppo3.lessThanOrEqualTo("groupId", 0);
        queryGruppo3.descending('createdAt');


        queries.push(queryGruppo3);
        if (stringIsSet(gruppoId)) {
            const queryGruppo3 = new Parse.Query(ProgrammiMVParse);
            queryGruppo3.equalTo("circuito", circuito);
            queryGruppo3.equalTo("groupId", gruppoId);
            queryGruppo3.descending('createdAt');
            queries.push(queryGruppo3);
        }

        const query: Parse.Query<ProgrammiMVParse> = Parse.Query.or(...queries);
        query.descending('createdAt');
        query.limit(30);
        return fromPromise(query.find());
    }

    public updateTableProgrammiMV(tables: { name: string, value }[], circuitoId) {
        const programmaMv = new ProgrammiMVParse();
        tables.forEach((table) => {
            programmaMv[table.name] = table.value;
            // console.log(table.name, table.value);
        });
        const circuito = new CircuitiParse();
        circuito.id = circuitoId;
        programmaMv.circuito = circuito;
        return fromPromise(programmaMv.save());
    }

    groupIsComplete$(gruoupId: number, circuitoId: string, lightPointIds: string[]): Observable<boolean> {
        const query = new PuntiLuceParse().query;
        const circuito = new CircuitiParse();
        circuito.id = circuitoId;
        query.equalTo('circuito', circuito);
        query.notContainedIn('objectId', lightPointIds);
        query.equalTo('idGruppoLightMate', gruoupId);
        return fromPromise(query.first()).pipe(
            map((lightPoint => lightPoint == null))
        )
    }


    public saveProgrammiMvByCircuits(tables: { name: string, value }[], circuits: PuntiLuceParse[]): Observable<PaginationParserequestType<ProgrammiMvService>> {
        const fetchPage = (page = 0,) => {
            let isLast = page + 1 >= circuits.length;
            const progress = Math.ceil(page / circuits.length * 100)
            const circuito = circuits[page];
            const programmaMv = new ProgrammiMVParse()
            programmaMv.circuito = circuito;
            tables.forEach(t => {
                programmaMv[t.name] = t.value
            })
            const value$ = fromPromise(programmaMv.save());
            return value$.pipe(
                map((item) => {
                    return {
                        items: [item],
                        progress: progress,
                        nextPage: isLast ? undefined : (page += 1),
                        finished: isLast,
                        error: null
                    };
                }),
                catchError(error => {
                    return of({
                        items: undefined,
                        progress: progress,
                        nextPage: isLast ? undefined : (page += 1),
                        finished: isLast,
                        error: error
                    });
                }),
            );
        }
        return fetchPage(0).pipe(
            expand(({nextPage}) => nextPage ? fetchPage(nextPage) : EMPTY)
        ) as Observable<PaginationParserequestType<ProgrammiMvService>>
    }

    public saveProgrammiMvForLightPoints(tables: { name: string, value }[], puntiLuce: PuntiLuceParse[]): Observable<any> {
        const lightPointWithGroupId = puntiLuce.filter(p => p.circuito != null && p.idGruppoLightMate != null);
        const lightPointWithoutGroupId = puntiLuce.filter(p => p.circuito != null && p.idGruppoLightMate == null);
        let valuesLightPointWithGroupId$: Observable<{ circuito: any, tables: any }[]>[] = [];
        let valuesLightPointWithoutGroupId$: Observable<{ circuito: any, tables: any }[]>;
        if (arrayIsSet(lightPointWithGroupId)) {
            const lightPointForObjectIdCircuito = lightPointWithGroupId.reduce((prev, current) => {
                const idCircuito = current.circuito.objectId
                const groupId = current.idGruppoLightMate;
                if (prev[idCircuito] == null) {
                    prev[idCircuito] = {}
                }
                if (prev[idCircuito][groupId] == null) {
                    prev[idCircuito][groupId] = [];
                }
                prev[idCircuito][groupId].push(current)
                return prev;
            }, {})
            Object.keys(lightPointForObjectIdCircuito).forEach(idCircuito => {
                    Object.keys(lightPointForObjectIdCircuito[idCircuito]).forEach(groupId => {
                        const intGroupId = parseInt(groupId)
                        const valueLightPointWithGroupId$ = this.groupIsComplete$(intGroupId, idCircuito, lightPointForObjectIdCircuito[idCircuito][groupId].map(p => p.objectId)).pipe(
                            map(value => {
                                if (value) {
                                    const circuito = new CircuitiParse()
                                    circuito.id = idCircuito;
                                    return [{
                                        circuito,
                                        tables: tables.concat([{
                                            name: 'groupId',
                                            value: intGroupId
                                        }])
                                    }];
                                } else {
                                    return lightPointForObjectIdCircuito[idCircuito][groupId].map(lightPoint => {
                                        return {
                                            circuito: lightPoint.circuito,
                                            tables: tables.concat([{
                                                name: 'deviceId',
                                                value: lightPoint.idMezzanotte
                                            }])
                                        }
                                    })
                                }
                            })
                        )

                        valuesLightPointWithGroupId$.push(valueLightPointWithGroupId$)
                    })
                }
            )
        }
        if (arrayIsSet(lightPointWithoutGroupId)) {
            valuesLightPointWithoutGroupId$ = of(
                lightPointWithoutGroupId.map(lightPoint => {
                    return {
                        circuito: lightPoint.circuito,
                        tables: tables.concat([{
                            name: 'deviceId',
                            value: lightPoint.idMezzanotte
                        }])
                    }
                }));
        }
        const allValues$: Observable<{ circuito: any, tables: any }[]>[] = []

        if (valuesLightPointWithoutGroupId$ != null) {
            allValues$.push(valuesLightPointWithoutGroupId$)
        }
        if (arrayIsSet(valuesLightPointWithGroupId$)) {
            allValues$.push(
                combineLatest(valuesLightPointWithGroupId$).pipe(
                    map(values => {
                        return values.reduce((prev, current) => {
                            if (arrayIsSet(current)) {
                                prev = prev.concat(current);
                            }
                            return prev;
                        }, [])
                    }),
                )
            )
        }

        return combineLatest(allValues$).pipe(
            map(values => {
                return values.reduce((prev, current) => {
                    if (arrayIsSet(current)) {
                        prev = prev.concat(current);
                    }
                    return prev;
                }, [])
            }),
            switchMap(values => {
                return combineLatest(values.map(v => this.updateTableProgrammiMV(v.tables, v.circuito.objectId)))
            })
        )

    }

}
