<mat-card>
    <div style="position: absolute;top: -7px;right: 0">
        <button mat-icon-button color="accent" (click)="clickHidden()">
            <mat-icon>
                <ng-container *ngIf="!hiddenInfo;else expand_less_all">
                    expand_more
                </ng-container>
                <ng-template #expand_less_all>
                    expand_less
                </ng-template>
            </mat-icon>
        </button>
    </div>
    <form [formGroup]="formPage" *ngIf="formPage">
        <div [hidden]="hiddenInfo" style="margin-top: 10px">
            <mat-form-field appearance="fill">
                <mat-label>{{'arredoUrbano'|translate}}</mat-label>
                <input matInput formControlName="typeArredoUrbano">
                <mat-hint>{{'dashboard_sidenav.ArredoUrbano.selectType'|translate}}</mat-hint>
            </mat-form-field>
            <div class="flex-around-center w100 flex-flow-row scrollbar-visible" [ngStyle]="{maxHeight:maxHeigth}" style="margin-top: 8px; overflow: auto">

                <div *ngFor="let type of typesArredoUrbano|filterByPredicateFunction:predicateFunction:formPage.get('typeArredoUrbano').value">
                    <button mat-raised-button color="primary" style="margin: 10px"
                            (click)="addTypeArredoUrbano(type.key)" [matTooltip]="type.traduction">
                        <div style="width: 100%;margin-top: 5px;margin-bottom: 5px"
                             class="flex-center-width100">
                            <svg width="30px" height="30px">
                                <g>
                                    <path [attr.d]="type.path" fill="#FFFFFF"></path>
                                </g>
                            </svg>
                        </div>
                    </button>
                </div>
                <ng-container *ngIf="customTypeArredoUrbano">
                    <div *ngFor="let type of customTypeArredoUrbano|filterByPredicateFunction:predicateFunctionCustom:formPage.get('typeArredoUrbano').value" class="huna-button" style="width:calc(100% - 20px);">
                        <button mat-raised-button color="accent" style="margin-bottom: 5px;width: 100%;"
                                (click)="addTypeArredoUrbano(type)" [matTooltip]="type">
                            {{type}}
                        </button>
                    </div>
                </ng-container>
            </div>
            <div class="selected-element-info" *ngIf="formPage.get('typeArredoUrbano').value">{{'dashboard_sidenav.ArredoUrbano.clickToAdd'|translate}} <strong>{{selectedType}}</strong></div>
        </div>
    </form>
</mat-card>