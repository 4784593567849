<mat-dialog-content>
    <div mat-dialog-title *ngIf="data.message">
        {{data.message|translate}}
    </div>
    <div class="flex-flow-row flex-around-center w100" style="margin-bottom:5px" *ngIf="data.actions">
        <ng-container *ngFor="let action of data.actions">
            <ng-container>
                <mat-card style="cursor: pointer"
                          [ngStyle]="{width:action.width?action.width:'300px'}"
                          (click)="selectActions(action)" matRipple>
                    <div style="width: 100%;text-align: center" class="flex-center-Between flex-flow-column">
                        <h5 *ngIf="action.traduction">{{action.traduction|translate}}</h5>
                        <button class="w100" mat-flat-button [color]="action.icon.color?action.icon.color:'primary'"
                                *ngIf="action.icon || action.icons;">
                            <ng-container *ngIf="action.icons;else Icon">
                                <ng-container *ngFor="let icon of action.icons"
                                              [ngTemplateOutlet]="getIcon"
                                              [ngTemplateOutletContext]="{icon:icon}"
                                >
                                </ng-container>
                            </ng-container>
                            <ng-template #Icon>
                                <ng-container
                                        [ngTemplateOutlet]="getIcon"
                                        [ngTemplateOutletContext]="{icon:action.icon}"
                                >
                                </ng-container>
                            </ng-template>
                        </button>
                    </div>
                </mat-card>
            </ng-container>
        </ng-container>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-flat-button color="accent" [mat-dialog-close]="undefined">
        <mat-icon>clear</mat-icon>
    </button>
</mat-dialog-actions>

<ng-template #getIcon let-icon="icon">
    <ng-container *ngIf="icon.traduction;else IconCustomIconImage ">
        {{icon.traduction|translate}}
    </ng-container>
    <ng-template #IconCustomIconImage>

        <mat-icon style="margin: 0 10px"
                  *ngIf="icon.material;else customIcon">
            {{icon.material}}
        </mat-icon>
        <ng-template #customIcon>
            <ng-container *ngIf="icon.svgIcon;else customImage">
                <mat-icon style="margin: 0 10px"
                          [svgIcon]="icon.svgIcon"></mat-icon>
            </ng-container>
            <ng-template #customImage>
                <img [src]="icon.custom"
                     style="max-height: 24px;min-height: 24px;margin: 0 10px">
            </ng-template>
        </ng-template>

    </ng-template>
</ng-template>