<div class="flex-start-center flex-flow-row" style="max-width: 100%">
    <div class="box-image" *ngIf="visualizeAddButton">
        <ngx-dropzone
                [disabled]="loadingImage"
                (change)="onLoadImage($event)"
                class="drop-zone-item-background drop-zone"
                accept="image/jpeg,image/jpg,image/png"
        >
            <ng-container *ngIf="!loadingImage;else Loading">
                <mat-icon>add_a_photo</mat-icon>
            </ng-container>
        </ngx-dropzone>
    </div>
    <ng-container *ngIf="isSetImages">
        <ng-container *ngFor="let image of images">
            <div class="box-image" *ngIf="image.src">
                <img src="{{image.src}}" class="fit-image" (click)="$event.stopPropagation();opeImageFull(image)">
            </div>
        </ng-container>
    </ng-container>
</div>

<ng-template #Loading>
    <mat-spinner [diameter]="20"></mat-spinner>
</ng-template>