import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable, of, Subscription} from "rxjs";
import {SchedaManutenzioneParse} from "../../../models/SchedaManutenzione.Parse";
import {UserRoleNumber} from "../../../../config/static-data";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {DialogPopUpInfoService} from "../../../providers/services/dialog-pop-up-info.service";
import {
    arrayIsSet,
    className,
    getParseObjectByClassNameObjectId,
    parseFetchAll$,
    stringIsSet
} from "../../../models/Models";
import {BozzaSchedaManutenzioneCompilata, LocalSotrageService} from "../../../providers/services/local-sotrage.service";
import {map, switchMap} from "rxjs/operators";
import {MaintenanceService} from "../../../providers/services/maintenance.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-list-schedule-small',
    templateUrl: './list-schedule-small.component.html',
    styleUrls: ['./list-schedule-small.component.scss']
})
export class ListScheduleSmallComponent implements OnInit {
    @Input() schedeManutenzione: SchedaManutenzioneParse[];
    @Input() abbonamentiAttivi: { gestioneAttivo } = {gestioneAttivo: false};
    @Input() myRole: UserRoleNumber;
    @Output() savedValue = new EventEmitter();
    @Input() cssPositionSaveButton: 'fab' | 'centerPositionButtonSave' = 'fab';
    @Input() visualizeSchedeDaSelezionare = true;
    @Input() disabled = false;
    @Input() loading = false;
    @Input() elementSchedaCompilata: { className: string, objectId: string } | undefined;
    @Input() bozzeSchedePrecompilate: BozzaSchedaManutenzioneCompilata[] | undefined;


    subscriptionSavedCompleted: Subscription;
    subscriptions: Subscription[] = [];
    formElements: UntypedFormGroup;

    constructor(
        private fb: UntypedFormBuilder,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private dialogPopUpInfoService: DialogPopUpInfoService,
        private maintencaService: MaintenanceService,
        private transalteService: TranslateService
    ) {
        this.matIconRegistry.addSvgIcon(
            "schedule",
            this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icon/hamburgerNavbar/calendar-setting.svg")
        );
        this.formElements = this.fb.group({
            selectScheduleMaintenance: null,
            valueFormScheduleMaintenance: null,
            valuesToSave: null
        })
        const sub = this.formElements.get('valuesToSave').valueChanges.subscribe(value => {
            this.save(value)
        })
        this.subscriptions.push(sub);
    }



    get abbonamentiRoleIsAttivi() {
        return this.myRole >= UserRoleNumber.INSTALLATORE && this.abbonamentiAttivi.gestioneAttivo
    }

    get errors(): { title: string, value: string }[] {
        const errors = [
            {
                title: 'errors.currentRoleAccesibility',
                value: 'errors.' + (this.myRole >= UserRoleNumber.INSTALLATORE)
            },
            {
                title: 'errors.activeCurrentSubscription',
                value: 'errors.' + this.abbonamentiAttivi.gestioneAttivo
            }
        ];
        return errors;
    }

    ngOnInit(): void {

    }

    multipleValues = false;


    getId(schedaManutenzione: SchedaManutenzioneParse) {
        return schedaManutenzione.objectId
    }

    getValue(schedaManutenzione: SchedaManutenzioneParse, key: string) {
        if (key != 'form') {
            return schedaManutenzione[key]
        } else {
            return schedaManutenzione[key].length
        }
    }


    getColorIcon(schedaManutenzione: SchedaManutenzioneParse) {
        return false ? 'primary' : 'accent';
    }


    get valuesForm(): SchedaManutenzioneParse {
        return this.formElements.get('valueFormScheduleMaintenance').value
    }

    save(event) {
        this.savedValue.emit(event);
    }


    openForm(schedaManutenzione: SchedaManutenzioneParse) {
        if (!this.disabled) {
            const obj = {schedaSelezionata: schedaManutenzione,};
            this.maintencaService.getLocalSchedaPrecompilata$(schedaManutenzione.objectId, this.elementSchedaCompilata).pipe(map(scheda => {
                if (scheda != null && arrayIsSet(scheda.valuesForm)) {
                    obj['valuesForm'] = scheda.valuesForm;
                }
                return obj;
            })).pipe(
                switchMap(obj => this.dialogPopUpInfoService.openFormScheduleMaintenance(obj))
            )
                .subscribe(val => {
                    if (val != null) {
                        this.formElements.get('selectScheduleMaintenance').setValue(schedaManutenzione)
                        this.formElements.get('valuesToSave').setValue(val)
                        if (arrayIsSet(val.keys)) {
                            const valueForm = []
                            val.keys.forEach(key => {
                                if (val.valuesSchedaManutenzione != null && val.valuesSchedaManutenzione[key.formControlName] != null) {
                                    valueForm.push({
                                        traduction: key.traduction,
                                        value: val.valuesSchedaManutenzione[key.formControlName]
                                    })
                                }
                            })
                            this.formElements.get('valueFormScheduleMaintenance').setValue(valueForm)
                        }
                    }
                })
        }
    }

    opeDetail(schedaManutenzione: SchedaManutenzioneParse) {
        const nameTrad = this.transalteService.instant('scheduleMaintenance.name');
        const noteTrad = this.transalteService.instant('scheduleMaintenance.note');
        const texts = [nameTrad, this.getValue(schedaManutenzione, 'name')];
        const note = this.getValue(schedaManutenzione, 'note')
        if (stringIsSet(note)) {
            texts.push(null);
            texts.push(noteTrad);
            texts.push(note);
        }
        this.dialogPopUpInfoService.openExpandedDetail({texts, title: null, subTitle: null, image: null}).subscribe()
    }

    predicateFunction(schedePreCompilate: BozzaSchedaManutenzioneCompilata[], schedaManutenzione: SchedaManutenzioneParse, elementSchedaCompilata: { className: string, objectId: string }) {
        if (arrayIsSet(schedePreCompilate)) {
            const index = schedePreCompilate.findIndex(scheda => {
                const element = scheda.element;
                return scheda.schedamanutenzioneId === schedaManutenzione.objectId && element.className === elementSchedaCompilata.className && element.objectId === elementSchedaCompilata.objectId;
            });
            return index >= 0 ? 'primary' : '';
        } else {
            return '';
        }
    }

}
