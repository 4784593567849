import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'transformForTranslate'
})
//transformazione per il translate lato  html
export class TransformForTranslatePipe implements PipeTransform {

    transform(value: string, ...args: unknown[]): unknown {
        if (typeof value == 'number' || value == null) {
            return value
        } else if (value && typeof value =='boolean') {
            return "true";
        } else if (!value && typeof value =='boolean') {
            return "false";
        } else {
            return value;
        }
    }

}
