import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {ProjectService} from "../services/project.service";
import {UserRoleNumber} from "../../../config/static-data";

@Injectable({
    providedIn: 'root'
})
export class IsAmministratoreGuard implements CanActivate {

    constructor(private projectService: ProjectService,
                private router: Router
    ) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (!this.projectService.isAmministratore) {
            this.router.navigate(['/map']);
            return false;
        } else {
            return true;
        }
    }

}
