import {Validators} from "@angular/forms";
import {HunaValidators} from "../../providers/validators/HunaValidators";
import {arrayIsSet, isDate, isValidDate, stringIsSet} from "../Models";
import {dataForm} from "../../components/confirm-delete/select-or-create/select-or-create.component";
import {DatePipe} from "@angular/common";

export type PossibleValuesType = { dataBaseValue: string | number, traduction?: string, noTraduction?: string };
export type configurationElement = {
    keyName: string,
    varianteKey: string | undefined,
    traductionKey: string,
    typeForm: typeFormValue,
    typeDatabase: typeDatabase,
    editable: boolean,
    required: boolean,
    showInForm: boolean,
    headerImport?: boolean,
    filtrable: boolean,
    copyable: boolean,
    possibleValues: PossibleValuesType[] | undefined,
    pointer: { className: string, getValue: (...args) => any, getValueExport?: (...args) => string, setValue?: (...args) => any, getId: (...args) => string } | undefined,
    sortingValue: number,
    section: number,
    forms:
        {
            databaseKey: string,
            values: { [k: string]: formField[] }
        }
};


export type formField = {
    keyName: string,
    varianteKey: string | undefined,
    keyTraduction: string,
    sortingValue: number,
    required: boolean,
    typeForm: typeFormValue,
    typeDatabase: typeDatabase,
    possibleValues: PossibleValuesType[] | undefined
}


export type arredoUrbanoType =
    "attraversamentoPedonale"
    | "bikeSharing"
    | "cassonetto"
    | "cestino"
    | "colonnineRicaricaElettrica"
    | "dissuasore"
    | "dosso"
    | "fermataBus"
    | "fermataTaxi"
    | "fermataTram"
    | "fontana"
    | "fotoZona"
    | "panchina"
    | "parcoGiochi"
    | "parcometro"
    | "rastrelliera"
    | "segnaleStradale"
    | "semaforo"
    | "telecamera"
    | "tombino";


export enum typeFormValue {
    ELENCO_APERTO,
    ELENCO_VINCOLATO,
    INPUT_TEXT,
    INPUT_NUMBER,
    INPUT_INTEGER,
    CHECKBOX,
    DATE,
    MOUNTH,
    IMAGE,
    FILE,
    GEO_POINT,
    RADIO_BUTTON
}


export enum typeDatabase {
    STRING,
    POINTER,
    ARRAY,
    OBJECT,
    FILE,
    BOOLEAN,
    NUMBER,
    GEOPOINT,
    POLYGON,
    DATE,
    RELATION,
}

export const getElementByKeyName = (keyName, elements: configurationElement[] | { [k: string]: configurationElement }): configurationElement | undefined => {
    const objectIsSet = typeof elements === 'object' && elements.hasOwnProperty(keyName)
    const arrayIsSet = Array.isArray(elements) && elements.length > 0
    let element;
    if (objectIsSet) {
        element = elements[keyName];
    } else if (arrayIsSet) {
        const index = (elements as configurationElement[]).findIndex(element => element.keyName === keyName);
        if (index >= 0) {
            element = elements[index];
        }
    }
    return element;
}


export const getValidator = (element: configurationElement): Validators[] | undefined => {
    const validators = []
    if (element.required) {
        validators.push(Validators.required);
    }
    if (element.typeForm === typeFormValue.INPUT_INTEGER) {
        validators.push(HunaValidators.isNumber());
    } else if (element.typeForm == typeFormValue.INPUT_NUMBER) {
        validators.push(HunaValidators.isNumberWithDecimal());
    }
    return validators.length > 0 ? validators : undefined;
}

export const getValidatorFormField = (formField: formField): Validators[] | undefined => {
    const validators = []
    if (formField.required) {
        validators.push(Validators.required);
    }
    if (formField.typeForm === typeFormValue.INPUT_INTEGER) {
        validators.push(HunaValidators.isNumber());
    } else if (formField.typeForm == typeFormValue.INPUT_NUMBER) {
        validators.push(HunaValidators.isNumberWithDecimal());
    }
    return validators.length > 0 ? validators : undefined;
}


export const getSubForm = (element: configurationElement, possibleValue: string): formField[] | undefined => {
    const valuesIsSet = (element, possibleValue) => {
        return element.forms && typeof element.forms.values == 'object' && element.forms.values.hasOwnProperty(possibleValue);
    }
    if (valuesIsSet(element, possibleValue)) {
        return element.forms.values[possibleValue]
    }
}

export const isPointerConfigurationElement = (element: configurationElement): boolean => {
    return element.pointer != null && stringIsSet(element.pointer.className);
}

export const getTypeDataBase = (element: configurationElement): typeDatabase | null => {
    return element != null ? element.typeDatabase : null;
}


// tslint:disable-next-line:max-line-length
export function getPossibleValuePointer(currentValue: any, element: configurationElement, elementsInProject: any[] | undefined): { currentValue: any, possibleValues: dataForm[] } {
    let valueForm;
    let possibleValues;
    if (arrayIsSet(elementsInProject)) {
        const currentId = currentValue != null ? element.pointer.getId(currentValue) : null;
        const possibleValuesPointer: dataForm[] = elementsInProject.map(el => {
            const id = element.pointer.getId(el);
            if (currentId != null && currentId === id) {
                valueForm = id;
            }
            const obj: dataForm = {
                valueForm: id,
                html: element.pointer.getValue(el),
                traduction: element.pointer.getValue(el)
            };
            return obj;
        });
        possibleValues = possibleValuesPointer.sort((a, b) => {
            let aString = ' ';
            let bString = ' ';
            if (a.traduction != null) {
                if (stringIsSet(a.traduction)) {
                    aString = a.traduction;
                } else if (a.traduction.toString) {
                    aString = a.traduction.toString();
                }
            }
            if (b.traduction != null) {
                if (stringIsSet(b.traduction)) {
                    bString = b.traduction;
                } else if (b.traduction.toString) {
                    bString = b.traduction.toString();
                }
            }
            return aString.localeCompare(bString, 'it', {numeric: true});
        });
    }
    return {currentValue: valueForm, possibleValues};
}

export function castValueByConfigurationElement(value: any, element: configurationElement, pointerValues: any[] | undefined, localized: string = 'it-IT'): any {
    let localValue = value;
    if (arrayIsSet(element.possibleValues)) {
        const find = element.possibleValues.find(v => v.dataBaseValue === value);
        if (find != null) {
            localValue = find.dataBaseValue;
        }
    }
    if (localValue == null) {
        return value;
    } else if (element.typeDatabase === typeDatabase.POINTER && arrayIsSet(pointerValues)) {
        const find = pointerValues.find(p => p.objectId === value);
        return find;
    } else if (element.typeDatabase === typeDatabase.POINTER && element.pointer.setValue != null) {
        return element.pointer.setValue(value);
    } else if (element.typeDatabase === typeDatabase.STRING) {
        if (typeof localValue === 'string') {
            return localValue;
        } else if (localValue.toString) {
            return localValue.toString();
        } else {
            return null;
        }
    } else if (element.typeDatabase === typeDatabase.NUMBER) {
        if (typeof localValue === 'number') {
            return localValue;
        } else if (localValue.toNumber) {
            return localValue.toNumber();
        } else {
            const castValue = parseFloat(localValue);
            if (isNaN(castValue)) {
                return null;
            } else {
                return castValue;
            }
        }
    } else if (element.typeDatabase === typeDatabase.BOOLEAN) {
        let castValue;
        // tslint:disable-next-line:triple-equals
        if (localValue == true || localValue == 'true') {
            castValue = true;
            // tslint:disable-next-line:triple-equals
        } else if (localValue == false || localValue == 'false') {
            castValue = false;
        }
        return castValue;
    } else if (element.typeDatabase === typeDatabase.DATE) {
        const convertToLocalDate = (responseDate: any) => {
            try {
                if (stringIsSet(responseDate)) {
                    let finalDate: Date;
                    const getSplitString = (stringDate: string) => {
                        const splitters = ['/', '-', ' '];
                        const splitterIndex = splitters
                            .findIndex(splitter => {
                                    const splitted = stringDate.split(splitter);
                                    return arrayIsSet(splitted) && splitted.length > 2;
                                }
                            );
                        if (splitterIndex >= 0) {
                            return stringDate.split(splitters[splitterIndex]);
                        } else {
                            return undefined;
                        }
                    }
                    const splittedDate = getSplitString(responseDate);
                    if (arrayIsSet(splittedDate)) {
                        let date;
                        let month;
                        let year;
                        year = splittedDate[2] && splittedDate[2].toString().length == 2 ? 20 + splittedDate[2] : splittedDate[2];
                        if (localized.includes('en')) {
                            date = splittedDate[1];
                            month = splittedDate[0];
                        } else {
                            date = splittedDate[0];
                            month = splittedDate[1];
                        }
                        finalDate = new Date();
                        finalDate.setHours(0, 0, 0, 0);
                        finalDate.setDate(date);
                        finalDate.setMonth(month - 1);
                        finalDate.setFullYear(year);
                    }
                    return isValidDate(finalDate) ? finalDate : responseDate;
                } else {
                    return undefined;
                }
            } catch (error) {
                return responseDate;
            }
        }
        let castValue = convertToLocalDate(localValue);
        return castValue;
    } else {
        return localValue;
    }


}

