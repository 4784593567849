import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {MatSort} from "@angular/material/sort";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {arrayIsSet} from "../../../models/Models";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-confirm-element-before-save',
    templateUrl: './confirm-element-before-save.component.html',
    styleUrls: ['./confirm-element-before-save.component.scss']
})
export class ConfirmElementBeforeSaveComponent implements OnInit, OnDestroy {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    dataSource: MatTableDataSource<any>;
    formElements: UntypedFormGroup = this.fb.group({});
    private subscriptions: Subscription[] = []

    get multipleValues() {
        return this.data.multipleValues;
    }

    constructor(public dialogRef: MatDialogRef<ConfirmElementBeforeSaveComponent>,
                @Inject(MAT_DIALOG_DATA)
                public data: {
                    elements: any[],
                    displayedColumns: string[],
                    getValueFunction,
                    getIdFunction,
                    multipleValues,
                    title: string
                },
                private fb: UntypedFormBuilder) {
        this.dataSource = new MatTableDataSource<any>();
    }

    visualizedCheckButton = false;

    ngOnInit(): void {
        this.dataSource.data = this.data.elements
        if (this.displayedColumns.includes('checkBox')) {
            this.formElements = this.getForm(this.data.elements)
            this.visualizedCheckButton = true;
        }

        // if (this.multipleValues) {
        //     const sub = this.formElements.valueChanges
        //         .pipe(
        //             pairwise(),
        //             map((prev, curr) => {
        //                 const keys = Object.keys(prev)
        //                 const keyPrev = keys.filter(key => prev[key]);
        //                 if (arrayIsSet(keyPrev)) {
        //                     console.log(keyPrev)
        //                     keyPrev.forEach(key => {
        //                         curr[key] = false;
        //                     })
        //                     const keys = Object.keys(curr);
        //                     const index = keys.findIndex(key => curr[key]);
        //                     return (index >= 0) ? keys[index] : null
        //                 } else {
        //                     return null;
        //                 }
        //             })
        //         )
        //         .subscribe(val => {
        //             if (isNotNullOrUndefined(val)) {
        //                 console.log(val)
        //             }
        //         })
        //     this.subscriptions.push(sub)
        // }

    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe())
    }

    get displayedColumns() {
        return (arrayIsSet(this.data.displayedColumns)) ? this.data.displayedColumns : []
    }


    globalCheckBoxChange(checked) {
        if (checked) {
            Object.keys(this.formElements.value).forEach(idUser => {
                this.formElements.get(idUser).setValue(checked)
            })
        } else {
            this.formElements.reset()
        }
    }


    getForm(elements: any[]): UntypedFormGroup {
        const objForm = {}
        if (this.multipleValues) {
            elements.forEach(element => {
                objForm[element.id] = false
            })
        } else {
            objForm['objectId'] = null;
        }


        return this.fb.group(objForm)
    }

    getValue(element, column) {
        return this.data.getValueFunction(element, column)
    }


    closeDialog() {
        this.dialogRef.close(undefined)
    }

    getId(item) {
        if (this.data.getIdFunction) {
            return this.data.getIdFunction(item)
        } else {
            return item.objectId
        }
    }

    getActivedElements() {
        const activeElements = []
        if (this.multipleValues) {
            Object.keys(this.formElements.value).forEach((idElement => {
                if (this.formElements.get(idElement).value) {
                    const index = this.data.elements.findIndex(element => this.getId(element) === idElement);
                    if (index >= 0) {
                        activeElements.push(this.data.elements[index])
                    }
                }
            }))
        } else {
            const idElement = this.formElements.get('objectId').value
            const index = this.data.elements.findIndex(element => this.getId(element) === idElement);
            if (index >= 0) {
                activeElements.push(this.data.elements[index])
            }
        }
        this.dialogRef.close(activeElements)
    }


}
