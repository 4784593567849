import * as Parse from 'parse';


export class FotoCircuitiParse extends Parse.Object {
    public static CLASS_NAME = 'fotoCircuiti';

    constructor() {
        super(FotoCircuitiParse.CLASS_NAME);
    }


    public get objectId() {
        return this.id;
    }

    public set objectId(value) {
        this.id = value;
    }

    public set circuito(value) {
        super.set('circuito', value);
    }

    public get circuito() {
        return super.get('circuito');
    }

    public set fotoEsterno(value) {
        super.set('fotoEsterno', value);
    }

    public get fotoEsterno(): Parse.File {
        return super.get('fotoEsterno');
    }

    public set fotoInterno(value) {
        super.set('fotoInterno', value);
    }

    public get fotoInterno(): Parse.File {
        return super.get('fotoInterno');
    }

    public set progetto(value) {
        super.set('progetto', value);
    }

    public get progetto() {
        return super.get('progetto');
    }

    public set numeroQuadro(value) {
        super.set('numeroQuadro', value);
    }

    public get numeroQuadro() {
        return super.get('numeroQuadro');
    }

}

Parse.Object.registerSubclass(FotoCircuitiParse.CLASS_NAME, FotoCircuitiParse);
