<mat-dialog-content>
    <div class="flex-around-center flex-flow-column" style="height: 100%">
        <div class="flex">
            <div class="data" style="text-align: center;">
                <H4>
                    <img class="img-icon-xl" src="/assets/icon/dialogCompnent/ask.png">
                </H4>
                <h5 *ngFor="let message of data.messages" style="margin-top: -5px">{{message |translate}}</h5>
            </div>
        </div>
        <form [formGroup]="formGroup" class=" width-form-field" >
            <div class="flex-center-width100 flex-flow-column">
                <ng-container *ngFor="let formField of formsValue">
                    <div style="width: 100%">
                        <ng-container [ngSwitch]="formField.type">
                            <ng-container *ngSwitchCase="typeFormField.inputString">
                                <mat-form-field appearance="fill" class=" width-form-field" >
                                    <mat-label>
                                        {{formField.traduction|translate}}
                                    </mat-label>
                                    <textarea style="height: 100px"
                                              matInput
                                              [formControlName]="formField.key"></textarea>
                                </mat-form-field>
                            </ng-container>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </form>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button (click)="closeDialog(false)"
            mat-raised-button color="warn">
        <mat-icon>clear</mat-icon>
    </button>
    <button
            [disabled]="formGroup.invalid"
            (click)="closeDialog(true)"
            mat-raised-button color="primary">
        <mat-icon>check</mat-icon>
    </button>
</mat-dialog-actions>