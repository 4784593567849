<form [formGroup]="dateForm">
    <div class="flex-center-width100" style="margin-top: 20px">
        <mat-chip-list>
            <mat-chip class="chip-list-width"
                      value="LUX"
                      [color]="chipColor('LUX')"
                      (click)="setIlluminamentoAterra('LUX')"
                      selected>
                <div class="chip-list-text">{{'illuminamentoAterra.lux'|translate}}</div>
            </mat-chip>
            <mat-chip class="chip-list-width"
                      value="AIR_PM2.5"
                      [color]="chipColor('AIR_PM2.5')"
                      selected
                      (click)="setIlluminamentoAterra('AIR_PM2.5')">
                <div class="chip-list-text">{{'illuminamentoAterra.pm2_5'|translate}}</div>
            </mat-chip>
            <mat-chip class="chip-list-width"
                      value="AIR_PM10"
                      [color]="chipColor('AIR_PM10')"
                      selected
                      (click)="setIlluminamentoAterra('AIR_PM10')">
                <div class="chip-list-text">{{'illuminamentoAterra.pm10'|translate}}</div>
            </mat-chip>
        </mat-chip-list>
    </div>
    <div class="flex-center-width100"
         style="flex-flow: row;flex-wrap: wrap;justify-content: space-evenly;margin-top:40px;margin-bottom:10px ">
        <button color="accent"
                mat-mini-fab
                [disabled]="!illuminamentoATerraAttivo"
                (click)="changeYearPrevius()"
        >
            <mat-icon>keyboard_arrow_left</mat-icon>
            <mat-icon style="margin-left: -15px;margin-right: 3px;">keyboard_arrow_left</mat-icon>
        </button>
        <button (click)="changeMonthPrevius()"
                color="accent"
                mat-mini-fab
                [disabled]="!illuminamentoATerraAttivo"
        >
            <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <mat-card class="date-container">
            {{dateForHtml}}
        </mat-card>
        <button (click)="changeMonthNext()"
                [disabled]="!illuminamentoATerraAttivo||monthIsAfterToday"
                color="accent"
                mat-mini-fab
        >
            <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
        <button color="accent"
                mat-mini-fab
                [disabled]="!illuminamentoATerraAttivo||yearIsAfterToday"
                (click)="changeYearNext()"
        >
            <mat-icon style="margin-left: 3px;">keyboard_arrow_right</mat-icon>
            <mat-icon style="margin-left: -15px;">keyboard_arrow_right</mat-icon>
        </button>

        <!--        <mat-form-field appearance="fill" class="chield-form-field">-->
        <!--            <mat-label>{{'mese'| translate}}</mat-label>-->
        <!--            <mat-select [formControlName]="'monthLuxCluster'">-->
        <!--                <mat-option *ngFor="let month of months" [value]="month">-->
        <!--                    {{getMonthExtendedName(month - 1) }}-->
        <!--                </mat-option>-->
        <!--            </mat-select>-->
        <!--        </mat-form-field>-->
        <!--        <mat-form-field appearance="fill" class="chield-form-field">-->
        <!--            <mat-label>{{ 'anno'| translate}}</mat-label>-->
        <!--            <mat-select [formControlName]="'yearLuxCluster'">-->
        <!--                <mat-option *ngFor="let year of years" [value]="year">-->
        <!--                    {{year}}-->
        <!--                </mat-option>-->
        <!--            </mat-select>-->
        <!--        </mat-form-field>-->
        <!--        <button-->
        <!--                style="margin-top:-25px;margin-bottom: 5px"-->
        <!--                mat-raised-button color="primary" (click)="emitNewMonthYear()"-->
        <!--                [disabled]="!dateForm.dirty || monthYearNotChanged() || !illuminamentoATerraAttivo">-->
        <!--            <mat-icon>check</mat-icon>-->
        <!--        </button>-->
    </div>
    <div class="flex-center-width100" style="margin-top: 30px">
        <app-button-spinner color="primary" type="mat-raised-button" [diameter]="20" [loading]="loading.print">
            <button [disabled]="!illuminamentoATerraAttivo||!enoughZoomDownload"
                    mat-raised-button color="primary" (click)="downloadFileLuxDataCluster()">
                <mat-icon>archive</mat-icon>
            </button>
        </app-button-spinner>
    </div>
    <div class="flex-center-width100" style="margin-top: 30px" *ngIf="!enoughZoomDownload&&illuminamentoATerraAttivo">
        {{'clustersLuxData.notEnoughZoom'|translate}}
    </div>

    <!--    <mat-form-field appearance="fill">-->
    <!--        <mat-label>{{ 'Mostra linee fino a zoom:'| translate}}</mat-label>-->
    <!--        <mat-select [formControlName]="'zoomTresholdLineToCircle'">-->
    <!--            <mat-option *ngFor="let level of [19,20,21,22]" [value]="level">-->
    <!--                {{level}}-->
    <!--            </mat-option>-->
    <!--        </mat-select>-->
    <!--    </mat-form-field>-->
</form>
<!--<form [formGroup]="zoomTresholdForm" style="padding: 10px">-->

<!--</form>-->
