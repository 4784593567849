import * as Parse from 'parse';


export class CaricoEsogenoParse extends Parse.Object {
    public static CLASS_NAME = 'CaricoEsogeno';

    constructor() {
        super(CaricoEsogenoParse.CLASS_NAME);
    }

    public set objectId(value) {
        this.id = value;
    }

    public get objectId() {
        return this.id;
    }

    public set updatedAt(value) {
        super.set('updatedAt', value);
    }

    public get updatedAt(): Date {
        return super.get('updatedAt');
    }

    public set createdAt(value) {
        super.set('createdAt', value);
    }

    public get createdAt(): Date {
        return super.get('createdAt');
    }

    public set note(value) {
        super.set('note', value);
    }

    public get note(): string {
        return super.get('note');
    }

    public set arredoUrbano(value) {
        super.set('arredoUrbano', value);
    }

    public get arredoUrbano(): any {
        return super.get('arredoUrbano');
    }

    public set progetto(value) {
        super.set('progetto', value);
    }

    public get progetto(): any {
        return super.get('progetto');
    }

    public set tipologia(value) {
        super.set('tipologia', value);
    }

    public get tipologia(): string {
        return super.get('tipologia');
    }

    public set potenzaNominale(value) {
        super.set('potenzaNominale', value);
    }

    public get potenzaNominale(): number {
        return super.get('potenzaNominale');
    }

    public set potenzaEffettiva(value) {
        super.set('potenzaEffettiva', value);
    }

    public get potenzaEffettiva(): number {
        return super.get('potenzaEffettiva');
    }

    public set oreFunzionamentoAnno(value) {
        super.set('oreFunzionamentoAnno', value);
    }

    public get oreFunzionamentoAnno(): number {
        return super.get('oreFunzionamentoAnno');
    }

    public set oreFunzionamentoGiorno(value) {
        super.set('oreFunzionamentoGiorno', value);
    }

    public get oreFunzionamentoGiorno(): number {
        return super.get('oreFunzionamentoGiorno');
    }

    public set consumoAnno(value) {
        super.set('consumoAnno', value);
    }

    public get consumoAnno(): number {
        return super.get('consumoAnno');
    }

    public set dataAttivazione(value) {
        super.set('dataAttivazione', value);
    }

    public get dataAttivazione(): Date {
        return super.get('dataAttivazione');
    }

    public set dataDisattivazione(value) {
        super.set('dataDisattivazione', value);
    }

    public get dataDisattivazione(): Date {
        return super.get('dataDisattivazione');
    }

    get isActive(): boolean {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        if (this.dataAttivazione != null && this.dataDisattivazione != null) {
            return this.dataAttivazione.getTime() >= today.getTime() && this.dataAttivazione.getTime() >= this.dataDisattivazione.getTime();
        } else if (this.dataDisattivazione == null && this.dataAttivazione != null) {
            return this.dataAttivazione.getTime() >= today.getTime();
        } else {
            return false;
        }
    }

    public set circuito(value) {
        super.set('circuito', value);
    }

    public get circuito(): any {
        return super.get('circuito');
    }

    public set puntoLuce(value) {
        super.set('puntoLuce', value);
    }

    public get puntoLuce(): any {
        return super.get('puntoLuce');
    }

    public set foto(value) {
        super.set('foto', value);
    }

    public get foto(): any {
        return super.get('foto');
    }


    public get query() {
        return new Parse.Query(CaricoEsogenoParse)
    }


}

Parse.Object.registerSubclass(CaricoEsogenoParse.CLASS_NAME, CaricoEsogenoParse);

