import {Component, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {configurationPropertyElectricLine} from '../../../models/configurationProperty/electricLine';
import {dataForm} from '../../confirm-delete/select-or-create/select-or-create.component';
import {Subject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {DataTypeLegend} from '../../scale-for-map/legend-key-color/legend-key-color.component';
import {stringIsSet} from "../../../models/Models";

@Component({
    selector: 'app-scale-color-and-label',
    templateUrl: './scale-color-and-label.component.html',
    styleUrls: ['./scale-color-and-label.component.scss']
})
export class ScaleColorAndLabelComponent implements OnInit, OnChanges, OnDestroy {
    public form: UntypedFormGroup;
    public keysScaleColor: dataForm[];
    public fieldColor;
    private subscriptions: Subscription[] = [];
    public defaultValue = 'name';
    @Input() keyScaleColorSet: string;
    @Input() scaleColor: DataTypeLegend[];
    @Output() changeScaleColor = new Subject<any>();

    constructor(private fb: UntypedFormBuilder) {
        this.form = this.fb.group({key: null});
        this.keysScaleColor = configurationPropertyElectricLine.map(key => {
            const obj: dataForm = {
                traduction: key.traductionKey,
                valueForm: key.keyName,
                html: key.keyName
            };
            return obj;
        });
        const sub = this.form.get('key').valueChanges.pipe(
            debounceTime(400),
            distinctUntilChanged()
        )
            .subscribe(value => {
                const index = this.keysScaleColor.findIndex(key => key.html === value || key.valueForm === value);
                if (index >= 0) {
                    this.changeScaleColor.next({key: value});
                }
            });
        this.subscriptions.push(sub);
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.keyScaleColorSet) {
            if (stringIsSet(this.keyScaleColorSet)) {
                this.form.get('key').setValue(this.keyScaleColorSet, {emitEvent: false, onlySelf: true});
            } else {
                this.unset('key', this.defaultValue);
                setTimeout(() => {
                    this.changeScaleColor.next({key: this.form.get('key').value});
                });
            }
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    unset(key, defaultValue: string = null) {
        this.form.get(key).reset(defaultValue);
    }

    onOptionSelected(e: { formValues: dataForm | null}) {
        if (!e.formValues) {
            this.unset('key', this.defaultValue);
        }
    }

}
