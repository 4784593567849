import * as Parse from 'parse';
import {className} from "./Models";

export type IntervalsType = {
    hour: number,
    minute: number,
    power: number,
}

export class TLC_ProfiloFunzionamentoParse extends Parse.Object {

    public static CLASS_NAME = className.tlcProfiloFunzionamento;
    private _icon;

    constructor() {
        super(TLC_ProfiloFunzionamentoParse.CLASS_NAME);
    }


    public get icon(): any {
        return this._icon;
    }

    public set icon(value) {
        this._icon = value;
    }

    public set objectId(value) {
        this.id = value;
    }

    public get objectId(): string {
        return this.id;
    }

    public set ACL(value) {
        super.set('ACL', value);
    }

    public get ACL() {
        return super.get('ACL');
    }

    public set createdAt(value) {
        super.set('createdAt', value);
    }

    public get createdAt(): Date {
        return super.get('createdAt');
    }

    public set updatedAt(value) {
        super.set('updatedAt', value);
    }

    public get updatedAt(): Date {
        return super.get('updatedAt');
    }

    public set intervals(value) {
        super.set('intervals', value);
    }

    public get intervals(): IntervalsType[] {
        return super.get('intervals');
    }

    public set lastUpdateBy(value) {
        super.set('lastUpdateBy', value);
    }

    public get lastUpdateBy(): Parse.User {
        return super.get('lastUpdateBy');
    }

    public set name(value) {
        super.set('name', value);
    }

    public get name(): string {
        return super.get('name');
    }

    public set progetto(value) {
        super.set('progetto', value);
    }

    public get progetto(): Parse.Object<any> {
        return super.get('progetto');
    }


    public get query() {
        let query = new Parse.Query(TLC_ProfiloFunzionamentoParse);
        return query;
    }
}

Parse.Object.registerSubclass(TLC_ProfiloFunzionamentoParse.CLASS_NAME, TLC_ProfiloFunzionamentoParse);
