import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges, OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {ProgettiParse} from '../../../models/Progetti.Parse';
import {FormControl, UntypedFormGroup} from '@angular/forms';
import {MyErrorStateMatcher} from '../../../providers/matchers/error-state-matcher';
import {ProjectService} from '../../../providers/services/project.service';
import {
    MatLegacyAutocomplete as MatAutocomplete,
    MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent
} from '@angular/material/legacy-autocomplete';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatLegacyChipInputEvent as MatChipInputEvent} from '@angular/material/legacy-chips';
import {User} from 'parse';
import {UserService} from '../../../providers/services/user.service';
import {AlertService} from '../../../providers/services/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {DialogPopUpService} from '../../../providers/services/dialog-pop-up.service';
import {ImageService} from '../../../providers/services/image.service';
import {DomSanitizer} from '@angular/platform-browser';
import {delay} from 'rxjs/operators';
import {HunaValidators} from '../../../providers/validators/HunaValidators';
import {isNotNullOrUndefined} from "../../../models/Models";


@Component({
    selector: 'app-sidenav-progect',
    templateUrl: './sidenav-progect.component.html',
    styleUrls: ['./sidenav-progect.component.scss']
})

export class SidenavProgectComponent implements OnInit, OnChanges, OnDestroy {


    //chiplist
    visible = true;
    selectable = true;
    removable = true;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    @ViewChild('installatoriChipList') installatoriInput;
    @ViewChild('gestoriChipList') gestoriInput;
    @ViewChild('operatoriChipList') operatoriInput;
    @ViewChild('utentiChipList') utentiInput;

    public idComune;
    //sidebar
    @Output() close = new Subject();


    // variabili del component
    @Input()
    progetto: ProgettiParse;
    installatoriOperatoriUtentiGestori: { installatori: User[], operatori: User[], utenti: User[], gestori: User[] } = {
        installatori: [],
        operatori: [],
        utenti: [],
        gestori: []
    };
    public subscriptionForm;
    public messageError: string;
    @Output() deleteProgect = new EventEmitter();
    public salvaImage;
    public addImageDisabled: { htmlLogo: boolean, image: boolean } = {htmlLogo: false, image: false};
    public instanzaDialogDeleteImmagine;
    public cominiPerAutoCompletare;

    constructor(private projectService: ProjectService,
                private userService: UserService,
                private alertService: AlertService,
                private translateService: TranslateService,
                private myDialog: DialogPopUpService,
                private imageService: ImageService,
                private _sanitizer: DomSanitizer,) {
        this.filterForm = this.projectService.projectForm;

    }


    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.progetto.id != this.oldProjectId) {
            this.addImageDisabled = {htmlLogo: false, image: false};
            this.filterForm.reset();
            this.destroyFormSubscription();
            const arrayUtenti = (this.progetto.utenti) ? this.progetto.utenti.map((a) => a.id) : [];
            const arrayInstallatori = (this.progetto.installatori) ? this.progetto.installatori.map((a) => a.id) : [];
            const arrayOperatori = (this.progetto.operatori) ? this.progetto.operatori.map((a) => a.id) : [];
            const arrayGestori = (this.progetto.gestori) ? this.progetto.gestori.map((a) => a.id) : [];
            this.userService.getUserDetail(arrayUtenti.concat(arrayInstallatori).concat(arrayOperatori).concat(arrayGestori)).subscribe((utentiOperatoriInstallatori) => {
                this.installatoriOperatoriUtentiGestori.utenti = utentiOperatoriInstallatori.filter((autenteOperatoreInstallatore) => arrayUtenti.includes(autenteOperatoreInstallatore.id));
                this.installatoriOperatoriUtentiGestori.operatori = utentiOperatoriInstallatori.filter((autenteOperatoreInstallatore) => arrayOperatori.includes(autenteOperatoreInstallatore.id));
                this.installatoriOperatoriUtentiGestori.installatori = utentiOperatoriInstallatori.filter((autenteOperatoreInstallatore) => arrayInstallatori.includes(autenteOperatoreInstallatore.id));
                this.installatoriOperatoriUtentiGestori.gestori = utentiOperatoriInstallatori.filter((autenteOperatoreInstallatore) => arrayGestori.includes(autenteOperatoreInstallatore.id));
            });
            this.setFilterForm(this.progetto);
            this.oldProjectId = this.progetto.id;
            this.cominiPerAutoCompletare = [];
            const subScriptionGestori = this.filterForm.get('gestori').valueChanges.subscribe((value) => {
                if (isNotNullOrUndefined(value) && value.length == 10) {
                    this.addChips(value, "gestori");
                }
            });
            const subScriptionInstallatori = this.filterForm.get('installatori').valueChanges.subscribe((value) => {
                if (isNotNullOrUndefined(value) && value.length == 10) {
                    this.addChips(value, "installatori");
                }
            });
            const subScriptionOperatori = this.filterForm.get('operatori').valueChanges.subscribe((value) => {
                if (isNotNullOrUndefined(value) && value.length == 10) {
                    this.addChips(value, "operatori");
                }
            });
            const subScriptionUtenti = this.filterForm.get('utenti').valueChanges.subscribe((value) => {
                if (isNotNullOrUndefined(value) && value.length == 10) {
                    this.addChips(value, "utenti");
                }
            });
            this.subscriptionForm = {
                gestori: subScriptionGestori,
                installatori: subScriptionInstallatori,
                operatori: subScriptionOperatori,
                utenti: subScriptionUtenti,
            }
        }
    }

    ngOnDestroy(): void {
        this.destroyFormSubscription();
    }


    destroyFormSubscription() {
        if (isNotNullOrUndefined(this.subscriptionForm)) {
            Object.keys(this.subscriptionForm).forEach(key => {
                if (isNotNullOrUndefined(this.subscriptionForm[key])) {
                    this.subscriptionForm[key].unsubscribe();
                }
            })
        }

    }

    public arrivoProgettoEliminato(progetto: ProgettiParse) {
        this.deleteProgect.emit(progetto);
    }

    public oldProjectId: string;
    public filterForm: UntypedFormGroup;
    public matcher = new MyErrorStateMatcher();//gestisce gli errori del textarea lato html


    private setFilterForm(progetto: ProgettiParse): void {
        const name = (progetto.name == null || progetto.name == undefined) ? '' : progetto.name;
        const costoMedioEnergia = (progetto.costoMedioEnergia == null || progetto.costoMedioEnergia == undefined) ? '' : progetto.costoMedioEnergia;
        const nomeGestore = (progetto.nomeGestore == null || progetto.nomeGestore == undefined) ? '' : progetto.nomeGestore;
        const responsabileComune = (progetto.responsabileComune == null || progetto.responsabileComune == undefined) ? '' : progetto.responsabileComune;
        const sigla = (progetto.sigla == null || progetto.sigla == undefined) ? '' : progetto.sigla;
        const comune = (progetto.comune == null || progetto.comune == undefined) ? '' : progetto.comune;
        if (comune != '') {
            comune.fetch().then((comune) => {
                this.filterForm.get('comune').setValue(comune.nome);
            });
        }
        this.filterForm.get('sigla').setValue(sigla);
        this.filterForm.get('name').setValue(name);
        this.filterForm.get('nomeGestore').setValue(nomeGestore);
        this.filterForm.get('costoMedioEnergia').setValue(costoMedioEnergia);
        this.filterForm.get('responsabileComune').setValue(responsabileComune);
        this.filterForm.get('image').setValue(null);
        this.filterForm.get('htmlLogo').setValue(null);
        this.filterForm.get('comune').setValidators(null);
    }

    public eliminaCampo(campo) {
        if (campo == 'comune') {
            this.idComune = false;
        }
        this.filterForm.get(campo).setValue('');
    }

    closeSidenav() {
        this.close.next();
    }

    onSubmit() {
        this.addImageDisabled['htmlLogo'] = !!this.filterForm.get('htmlLogo').value;
        this.addImageDisabled['image'] = !!this.filterForm.get('image').value;
        const values = Object.keys(this.filterForm.value).reduce((prev, key) => {
            if (this.filterForm.get(key).dirty) {
                prev[key] = this.filterForm.get(key).value;
            }
            return prev;
        }, {})
        this.projectService.updateProgetto(this.progetto.id, values, this.installatoriOperatoriUtentiGestori, this.idComune).subscribe(() => {
            const message = this.translateService.instant('alert.success');
            this.alertService.success(message);
            this.filterForm.get('image').setValue(null);
            this.filterForm.get('htmlLogo').setValue(null);
            this.addImageDisabled['htmlLogo'] = !!this.filterForm.get('htmlLogo').value;
            this.addImageDisabled['image'] = !!this.filterForm.get('image').value;
            this.closeSidenav();
        }, error => {
            this.alertService.error(error);
        });
    }

    private messageAndAddElement(chi: 'installatori' | 'utenti' | 'gestori' | 'operatori', errore: boolean, message: string) {
        switch (chi) {
            case 'installatori':
                this.installatoriInput.errorState = errore;
                this.messageError = message;
                break;
            case 'utenti':
                this.utentiInput.errorState = errore;
                this.messageError = message;
                break;
            case 'gestori':
                this.gestoriInput.errorState = errore;
                this.messageError = message;
                break;
            case 'operatori':
                this.operatoriInput.errorState = errore;
                this.messageError = message;
                break;

        }
    }

    addChips(value, chi: 'installatori' | 'utenti' | 'gestori' | 'operatori') {
        const giaPresente = (value.trim().length == 0) ? true : !this.installatoriOperatoriUtentiGestori[chi].every((a) => {
            return a.id != value;
        });

        if (giaPresente) {
            this.messageAndAddElement(chi, true, 'giaPrensete');
            // console.log("gia presente")
        } else {
            this.userService.getUserDetail([value]).subscribe((user) => {
                if (user.length > 0) {
                    this.messageAndAddElement(chi, false, '');
                    this.installatoriOperatoriUtentiGestori[chi] = this.installatoriOperatoriUtentiGestori[chi].concat(user);
                } else {
                    this.messageAndAddElement(chi, true, 'nonPresenteNelDatabase');
                }
            }, (error) => {
                this.messageAndAddElement(chi, true, error);
                console.log(error);
            });
            this.filterForm.get(chi).reset();
        }

    }

    changeInput(event) {
        console.log(event);
    }

    add(event: MatChipInputEvent, chi: 'installatori' | 'utenti' | 'gestori' | 'operatori'): void {
        const input = event.input;
        const value: string = event.value;
        this.addChips(value, chi);
    }

    remove(installatore: User, chi: 'installatori' | 'utenti' | 'gestori' | 'operatori'): void {
        const index = this.installatoriOperatoriUtentiGestori[chi].indexOf(installatore);
        this.installatoriInput.errorState = false;
        if (index >= 0) {
            this.installatoriOperatoriUtentiGestori[chi].splice(index, 1);
        }
    }


    public copyInClickBoard(id) {
        const el = document.createElement('textarea');
        el.value = id;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.alertService.success(this.translateService.instant('copy'));
    }


    public eliminaProgetto() {
        this.myDialog.openDialogForDelete(this.translateService.instant('dialog.elimina_elemento')).subscribe(result => {
            if (result) {
                this.projectService.eliminaProgetto(this.progetto).subscribe((progetto) => {
                        //salvataggio avvenuto senza errrori
                        const salvato: string = this.translateService.instant('dashboard_sidenav.alert.formgroup.success');
                        //il messaggio popup
                        this.arrivoProgettoEliminato(progetto);
                        this.alertService.success(salvato);
                        this.closeSidenav();
                    },
                    (error) => {
                        //salvataggiato non avvenuto segnaliamo l errore nel popup
                        const erroreGenerato: string = error.message;
                        //il messaggio popup
                        this.alertService.error(erroreGenerato);
                        // console.log("B", error.message)
                    });
            }
        });
    }

    onLoadImage(files: File[], label: string) {
        try {
            const fileUploadControl = files[0];
            // const loaderId = this.loaderService.addLoader();
            if (fileUploadControl) {
                const file = fileUploadControl;
                const name = label;
                const reader = new FileReader();
                reader.onloadend = (e: ProgressEvent) => {
                    const base64 = (e.target as FileReader).result as any;
                    this.imageService.compressImage(base64).then(compressed => {
                        const base64Compressa: string = compressed as string;
                        this.filterForm.get(label).setValue({
                            html: this._sanitizer.bypassSecurityTrustUrl(base64Compressa),
                            file: base64Compressa,
                            nome: name
                        });
                        this.filterForm.get(label).markAsDirty();
                        this.salvaImage = true;
                    });
                };
                reader.readAsDataURL(file);
            } else {
                this.alertService.error(this.translateService.instant('load_correct_file'));
            }
        } catch (e) {
            this.alertService.error(this.translateService.instant('error') + ' ' + e.message);
        }

    }

    deleteSavedFile(label, form?) {
        this.salvaImage = false;
        this.filterForm.get(label).reset(null);
        this.filterForm.get(label).markAsDirty();
    }


    public deleteFoto(key: 'htmlLogo' | 'image') {
        this.addImageDisabled[key] = true;
        this.instanzaDialogDeleteImmagine = this.myDialog.openDialogForDelete(this.translateService.instant('dialog.' + key)).subscribe(result => {
            if (result) {
                this.projectService.deleteFotoProgetto(this.progetto.id, key)
                    .subscribe((progetto) => {
                        this.filterForm.get(key).reset();
                        progetto.unset(key)
                    }, error => {
                        this.alertService.error(error)
                    });
            }
            this.addImageDisabled[key] = false;
        });

    }

    public getComuniDaAutocompletare(subString, secondi: number) {
        if (subString.length > 3) {
            this.projectService.getComuneAutocompletamento(subString.toUpperCase()).pipe(
                delay(secondi * 1000)
            ).subscribe((comuni) => {
                    this.cominiPerAutoCompletare = comuni;
                    this.filterForm.get('comune').setValidators(HunaValidators.isInArray(this.cominiPerAutoCompletare));
                    this.idComune = this.verifiaComuneInserito();
                }
            );
        }
    }


    public verifiaComuneInserito() {
        const filtraComuni = this.cominiPerAutoCompletare.filter(comune => {
            return comune.nome == this.filterForm.get('comune').value;
        });
        return (Array.isArray(filtraComuni) && filtraComuni.length > 0) ? filtraComuni[0].id : [];
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        this.idComune = this.verifiaComuneInserito();
    }


}
