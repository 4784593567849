import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {UntypedFormBuilder} from "@angular/forms";
import {className} from "../../../models/Models";

@Component({
    selector: 'app-import-localized-image',
    templateUrl: './import-localized-image.component.html',
    styleUrls: ['./import-localized-image.component.scss']
})
export class ImportLocalizedImageComponent implements OnInit {
    public className = className;

    public formPage = this.fb.group({
        classNames: this.className.puntiLuce + this.className.circuiti,
    })

    constructor(public dialogRef: MatDialogRef<ImportLocalizedImageComponent>,
                @Inject(MAT_DIALOG_DATA)
                public data: { src: string, type: string },
                private fb: UntypedFormBuilder
    ) {
    }

    ngOnInit(): void {
    }

    get response() {
        const classNames = this.formPage.get('classNames').value;
        if (classNames === this.className.puntiLuce) {
            return [this.className.puntiLuce]
        } else if (classNames === this.className.circuiti) {
            return [this.className.circuiti]
        } else if (classNames === this.className.puntiLuce + this.className.circuiti) {
            return [this.className.puntiLuce, this.className.circuiti]
        }
    }

    sendResponse() {
        this.dialogRef.close(this.response)
    }
}
