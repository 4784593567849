
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardSidenavComponent } from "./dashboard-sidenav/dashboard-sidenav.component";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatLegacyTooltipModule as MatTooltipModule } from "@angular/material/legacy-tooltip";
import { TranslateModule } from "@ngx-translate/core";
import { DirectivesModule } from "../../providers/directives/directives.module";
import { ReactiveFormsModule } from "@angular/forms";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { PipesModule } from "../../providers/pipes/pipes.module";
import { InputWithErrorsModule } from "../input-with-errors/input-with-errors.module";
import { ConfirmDeleteModule } from '../confirm-delete/confirm-delete.module';
import { DialogPopUpService } from 'src/app/providers/services/dialog-pop-up.service';
import {NgxDropzoneModule} from "ngx-dropzone";
import {MatLegacyListModule as MatListModule} from "@angular/material/legacy-list";
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from "@angular/material/legacy-progress-spinner";
import {VirtualMidNightModule} from "../virtual-mid-night/virtual-mid-night.module";
import { HistoryLightPointComponent } from './history-light-point/history-light-point.component';
import {WidgetsModule} from "../../widgets/widgets.module";
import {CoreModule} from "../../core/core.module";
import {MatLegacyPaginatorModule as MatPaginatorModule} from "@angular/material/legacy-paginator";
import {MatSortModule} from "@angular/material/sort";
import {BoxesModule} from "../../boxes/boxes.module";
import {ConnectedFilesModule} from "../connected-files/connected-files.module";
import { FormItemOnMapComponent } from './form-item-on-map/form-item-on-map.component';
import {MatDatepickerModule} from "@angular/material/datepicker";
import {CaricoEsogenoModule} from "../carico-esogeno/carico-esogeno.module";
import {MatLegacyProgressBarModule as MatProgressBarModule} from "@angular/material/legacy-progress-bar";
import {OverlayModule} from "@angular/cdk/overlay";
import {ReportLightPointModule} from "../aggregate-data-report.elements/report-light-point.module";

@NgModule({
    declarations: [DashboardSidenavComponent, HistoryLightPointComponent, FormItemOnMapComponent],
    imports: [
        CommonModule,
        MatToolbarModule,
        MatTableModule,
        MatTabsModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatIconModule,
        MatCardModule,
        MatTooltipModule,
        TranslateModule,
        DirectivesModule,
        ReactiveFormsModule,
        MatInputModule,
        MatSelectModule,
        MatFormFieldModule,
        PipesModule,
        InputWithErrorsModule,
        ConfirmDeleteModule,
        NgxDropzoneModule,
        MatListModule,
        MatProgressSpinnerModule,
        VirtualMidNightModule,
        CoreModule,
        MatPaginatorModule,
        MatSortModule,
        BoxesModule,
        ConnectedFilesModule,
        MatDatepickerModule,
        CaricoEsogenoModule,
        MatProgressBarModule,
        OverlayModule,
        ReportLightPointModule,
        WidgetsModule
    ],
    exports: [DashboardSidenavComponent, HistoryLightPointComponent],
    providers: [DialogPopUpService]
})
export class DashboardSidenavModule {
}
