<ng-container *ngIf="abbonamentiRoleIsAttivi;else abbonamentiNonAttivi">
    <mat-tab-group #tabSidenav>
        <mat-tab label="{{'ScheduleMaintenance'|translate}}" aria-labelledby="schedeManutenzioni" >
            <div class="maintenance-tab">
                <mat-accordion>
                    <mat-expansion-panel [expanded]="isSetSchedeManutenzioneExpired" #schedeManutenzioneExpiredPanel
                        [disabled]="!isSetSchedeManutenzioneExpired">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{'scheduleMaintenance.expired'|translate}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ng-container *ngIf="isSetSchedeManutenzioneExpired;">
                            <div style="max-width: 100%;overflow-y: scroll;margin-bottom: 10px;">
                                <div class="container-center flex-flow-column">
                                    <div class="w100" style="max-width: 100%;">
                                        <div class="w100">
                                            <div class="flex-center-width100 flex-flow-column">
                                                <ng-container *ngFor="let schedaManutenzione of schedeManutenzioneExpired"
                                                    [ngTemplateOutlet]="boxManutenzione"
                                                    [ngTemplateOutletContext]="{schedaManutenzione:schedaManutenzione}">
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </mat-expansion-panel>
                    <mat-expansion-panel [disabled]="!isSetSchedeManutenzioneExpiring" #schedeManutenzioneExpiringPanel>
                        <mat-expansion-panel-header>
                            <mat-panel-description>
                                {{'scheduleMaintenance.expiring'|translate}}
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <ng-container *ngIf="isSetSchedeManutenzioneExpiring;">
                            <div style="max-width: 100%;overflow-y: scroll;margin-bottom: 10px;">
                                <div class="container-center flex-flow-column">
                                    <div class="w100" style="max-width: 100%;">
                                        <div class="w100">
                                            <div class="flex-center-width100 flex-flow-column">
                                                <ng-container *ngFor="let schedaManutenzione of schedeManutenzioneExpiring"
                                                    [ngTemplateOutlet]="boxManutenzione"
                                                    [ngTemplateOutletContext]="{schedaManutenzione:schedaManutenzione}">
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </mat-expansion-panel>
                    <mat-expansion-panel [disabled]="!isSetSchedeManutenzioneNext" #schedeManutenzioneNextPanel>
                        <mat-expansion-panel-header>
                            <mat-panel-description>
                                {{'scheduleMaintenance.next'|translate}}
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <ng-container *ngIf="isSetSchedeManutenzioneNext">
                            <div style="max-width: 100%;overflow-y: scroll;margin-bottom: 10px;">
                                <div class="container-center flex-flow-column">
                                    <div class="w100" style="max-width: 100%;">
                                        <div class="w100">
                                            <div class="flex-center-width100 flex-flow-column">
                                                <ng-container *ngFor="let schedaManutenzione of schedeManutenzioneNext"
                                                    [ngTemplateOutlet]="boxManutenzione"
                                                    [ngTemplateOutletContext]="{schedaManutenzione:schedaManutenzione}">
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </mat-expansion-panel>
                    <mat-expansion-panel [disabled]="!isSetSchedeManutenzioneCompiled" #schedeManutenzioneCompiledPanel>
                        <mat-expansion-panel-header>
                            <mat-panel-description>
                                {{'scheduleMaintenance.compiled'|translate}}
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <ng-container *ngIf="isSetSchedeManutenzioneCompiled;">
                            <div style="max-width: 100%;overflow-y: scroll;margin-bottom: 10px;">
                                <div class="container-center flex-flow-column">
                                    <div class="w100" style="max-width: 100%;">
                                        <div class="w100">
                                            <div class="flex-center-width100 flex-flow-column">
                                                <ng-container
                                                    *ngFor="let schedaManutenzioneCompilata of schedeManutenzioneCompiled"
                                                    [ngTemplateOutlet]="boxSchedaManutenzioneCompilata"
                                                    [ngTemplateOutletContext]="{schedaManutenzioneCompilata:schedaManutenzioneCompilata,rejectedBy:rejectedBy[schedaManutenzioneCompilata.objectId],compiledBy:compiledBy[schedaManutenzioneCompilata.objectId]}">
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
            <ng-template #nessunaScheda>
                <app-list-is-empty *ngIf="!loading.schedeManutenzione;else loadSchede">
                    <p> {{"scheduleMaintenance.noElement"|translate}}</p>
                </app-list-is-empty>
                <ng-template #loadSchede>
                    <mat-spinner></mat-spinner>
                </ng-template>
            </ng-template>
        </mat-tab>
        <mat-tab label="{{'reportSegnalazioni.reports'|translate}}" *ngIf="iSetSegnalazioni" aria-labelledby="reports">
            <div style="max-width: 100%;">
                <div style="max-width: 100%;">
                    <div class="container-center flex-flow-column">
                        <app-scroll-with-button position="bottom" color="accent" style="max-width: 100%" [step]="(1 / segnalazioniAperte.length) * 100">
                            <div style="min-width:150px;max-width: 100%;">
                                <div class="container-start">
                                    <div class="container-center">
                                        <div class="flex-item-segnalazione"
                                            *ngFor="let segnalazione of segnalazioniAperte">
                                            <app-box-segnalazione [segnalazione]="segnalazione"
                                                [selected]="segnalazioneSelezionata && segnalazioneSelezionata.objectId === segnalazione.objectId"
                                                (click)="clickSegnalazione(segnalazione)">

                                            </app-box-segnalazione>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </app-scroll-with-button>
                    </div>
                </div>
                <ng-container *ngIf="segnalazioneSelezionata">
                    <div style="max-width: 100%">
                        <app-update-segnalazione
                                (assignTo)="clickAssignTo($event)"
                                (reopenReport)="clickSearchIntervention($event)"
                                (generateReport)="clickGenerateReport($event)"
                                (closeReport)="clickCloseReport($event)"
                                (takeCharge)="clickTakeCharge($event)"
                                (sendComment)="clickSendComment($event)"
                                [disabledAllButton]="leastOneLoadReportIsTrue"
                                [loadingButton]="loadingSaveReport"
                                [currentUser]="currentUser"
                                [role]="myRole"
                                [segnalazione]="segnalazioneSelezionata">
                        </app-update-segnalazione>
                    </div>
                </ng-container>
            </div>
        </mat-tab>
    </mat-tab-group>
</ng-container>
<ng-template #abbonamentiNonAttivi>
    <app-box-errors style="width: 350px;max-width: 100%" [subTitle]="'error_roleAbbonamento'" [errors]="errors">
    </app-box-errors>
</ng-template>

<ng-template #boxManutenzione let-schedaManutenzione="schedaManutenzione">
    <div (click)="clickBox(schedaManutenzione)" class="flex-item zoom-on-hover"
        [ngClass]="{disabled:isDisabled(schedaManutenzione)}">
        <mat-card class="dimension-box border">
            <mat-card-title class="menu-accent-card maintenance-card-title">
                <div style="width: 48px;"></div>
                {{schedaManutenzione.name}}
                <div style="width: 48px;display: flex;justify-content: flex-end;">
                    <mat-spinner diameter="24" *ngIf="isDisabled(schedaManutenzione)" class="circle-color-white"></mat-spinner>
                    <mat-icon *ngIf="isSuggerita(schedaManutenzione)" color="warn">
                        assignment_late
                    </mat-icon>
                </div>

            </mat-card-title>
            <div class="w100 flex-start-start  flex-flow-column" *ngIf="schedaManutenzione"
                style="max-height: calc(100% - 50px);padding: 0 5px 0 5px">
                <ng-container *ngIf="getDetail(schedaManutenzione) as detailsScheda;">
                    <ng-container *ngIf="detailsScheda.nextMaintenanceDate">
                        <div class="flex-around-center " style=" width: calc(100% - 10px);">
                            <div style="max-width: calc(100% - 50px);text-align: start">
                                <div style="font-weight: bold" class="spacer-width-primary-text color-accent">
                                    {{"scheduleMaintenance.nextMaintenanceDate"|translate}}
                                </div>
                                <div class="spacer-width-text-secondary-text">
                                    {{detailsScheda.nextMaintenanceDate|customDate:'mediumDate'|async}}
                                </div>
                            </div>
                            <div style="max-width: 50px">
                                <button mat-icon-button
                                    (click)="$event.stopPropagation();openDetail(schedaManutenzione,detailsScheda)"
                                    color="accent">
                                    <mat-icon>info</mat-icon>
                                </button>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </mat-card>
    </div>
</ng-template>

<ng-template #boxSchedaManutenzioneCompilata let-schedaManutenzioneCompilata="schedaManutenzioneCompilata"
    let-rejectedBy="rejectedBy" let-compiledBy="compiledBy">
    <div class="zoom-on-hover flex-item">
        <mat-card class="dimension-box huna-material-class" style="cursor: pointer"
        (click)="singleCardClick(schedaManutenzioneCompilata)">
            <mat-card-title class="background-default-not-select maintenance-card-title">
                <span style="flex-grow: 1;" *ngIf="schedaManutenzioneCompilata.name">
                    {{schedaManutenzioneCompilata.name}}
                </span>
            </mat-card-title>
            

            <div style="margin: 5px" class="w100-40">
                <div class="flex-around-center flex-flow-row w100">
                    <ng-container *ngIf="schedaManutenzioneCompilata.createdAt!=null">
                        <div>
                            <mat-card-subtitle style="margin-bottom: 0">
                                {{"scheduleMaintenance.completedOn"|translate}}
                            </mat-card-subtitle>
                            <div>
                                {{schedaManutenzioneCompilata.createdAt|customDate :'longDate'|async}}
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="compiledBy!=null&&(compiledBy.nome||compiledBy.cognome)">
                        <div>
                            <mat-card-subtitle style="margin-bottom: 0;margin-top: 1px;">
                                {{"by"|translate}}
                            </mat-card-subtitle>
                            <div>
                                {{compiledBy.nome}} {{compiledBy.cognome}}
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="rejectedBy!=null&&(rejectedBy.nome||rejectedBy.cognome)">
                        <div>
                            <mat-card-subtitle class="color-warn" style="margin-bottom: 0;margin-top: 5px;">
                                {{"rejectedBy"|translate}}
                            </mat-card-subtitle>
                            <div>
                                {{rejectedBy.nome}} {{rejectedBy.cognome}}
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </mat-card>
    </div>
</ng-template>