<app-list-box-schede-manutenzione
        [schedeManutenzione]="data.schedeManutenzione"
        dimensionCardMaintenance="M"
        dimensionContainerCardMaintenance="L"
        (clickSchedaManutenzione)="clickSchedaManutenzione($event)"
        [selectedSchedeManutenzione]="selectedSchedeManutenzione"
        [disabledSchedeManutenzione]="data.schedeManutenzioniGiaAssociate"
        [selectionMode]="true"
>
</app-list-box-schede-manutenzione>
<div style="width: 1px; height: 36px; margin-top: 24px;"></div>
<div style="display: flex; justify-content: flex-end; margin-top: 24px; right: 24px; bottom: 24px; position: absolute;">
    <button (click)="closeDialog(false)" mat-raised-button style="margin-right: 24px;">
        <mat-icon>clear</mat-icon>
    </button>
    <div style="background-color: white;">
        <button [matBadge]="selectedSchedeManutenzione.length.toString()"
                matBadgePosition="before"
                matBadgeColor="accent"
                [matBadgeDisabled]="selectedSchedeManutenzione.length===0"
                [disabled]="selectedSchedeManutenzione.length===0"
                (click)="closeDialog(true)" mat-raised-button color="primary">
            <mat-icon>add_task</mat-icon>
        </button>
    </div>
</div>