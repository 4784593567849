<ng-container [ngSwitch]="data.type">
    <ng-container *ngSwitchCase="'image'">
        <mat-card style="height: calc(100% - 10px)">
            <ng-container *ngIf="editAble;else NoEdit">
                <mat-card-content style="max-width: 100%;max-height: 100%;height: calc(100% - 60px)">
                    <div class="h100">
                        <ngx-dropzone
                                style="width:calc(100% - 10px);margin:5px;height:100%!important;"
                                class="drop-zone-item-background"
                                accept="image/jpeg,image/jpg,image/png"
                                (change)="onLoadImage($event.addedFiles)"
                        >
                            <ng-container *ngIf="newImagesUrl==null;else Images">
                                <ngx-dropzone-label>
                                    {{ 'fileManager.copyFile' | translate}}
                                </ngx-dropzone-label>
                            </ng-container>
                            <ng-template #Images>
                                <div class="w100 h100 ">
                                    <img [src]="newImagesUrl" class="img-object-fit-scale-down">
                                </div>
                            </ng-template>
                        </ngx-dropzone>
                    </div>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-flat-button color="primary" [disabled]="newImages==null" (click)="updateImage()">
                        <mat-icon>save</mat-icon>
                    </button>
                </mat-card-actions>
            </ng-container>
            <ng-template #NoEdit>
                <app-pinch-to-zoom [properties]="pzProperties">
                    <img [src]="data.src" class="img-object-fit-scale-down" [ngStyle]="{height:heigth}">
                </app-pinch-to-zoom>
            </ng-template>
        </mat-card>
    </ng-container>
    <ng-container *ngSwitchCase="'kml'">
        <google-map width="100%" height="100%">
            <map-kml-layer [url]="data.src" *ngIf="data.src"></map-kml-layer>
        </google-map>
    </ng-container>
    <ng-container *ngSwitchCase="'kmz'">
        <google-map width="100%" height="100%">
            <map-kml-layer [url]="data.src" *ngIf="data.src"></map-kml-layer>
        </google-map>
    </ng-container>
</ng-container>
<div class="flex-end-center"
     style="position: absolute;left: calc(100% - 34px);transform: translateX(-61px);top:11px;width: 81px">
    <button mat-icon-button color="warn" [mat-dialog-close]="{destroy:true}" *ngIf="visualizeDestroyButton">
        <mat-icon>delete</mat-icon>
    </button>
    <button mat-icon-button color="accent" *ngIf="visualizeEditButton" (click)="clickEdit()">
        <mat-icon>edit</mat-icon>
    </button>
    <button mat-icon-button color="accent" [mat-dialog-close]="undefined">
        <mat-icon>cancel</mat-icon>
    </button>
</div>
