import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DisableControlDirective} from './disable-control.directive';
import {ResponsiveDirective} from './responsive.directive';
import {ResponsiveClassDirective} from './responsive-class.directive';
import {AutorotateDirective} from './autorotate.directive';
import {ClickOutsideDirective} from './click-outside.directive';
import {SafeInnerHtmlDirective} from './safe-inner-html.directive';
import {CompletedNgForDirective} from './completed-ng-for.directive';
import {ImageDisabledDirective} from './image-disabled.directive';
import {ScroolToDirective} from './scrool-to.directive';
import {ChangeOrientationDirective} from './change-orientation.directive';
import {ScrollEventDirective} from './scroll-event.directive';
import {LongPressDirective} from './long-press.directive';
import {GetGoogleAddressDirective} from './get-google-address.directive';
import {TruncateMiddleDirective} from './truncate-middle.directive';
import {FitTextDirective} from './fit-text.directive';
import { CopyPasteDirective } from './copy-paste.directive';

@NgModule({
    declarations: [DisableControlDirective, ResponsiveDirective, ResponsiveClassDirective, AutorotateDirective, AutorotateDirective, ClickOutsideDirective, SafeInnerHtmlDirective, CompletedNgForDirective, ImageDisabledDirective, ScroolToDirective, ChangeOrientationDirective, ScrollEventDirective, LongPressDirective, GetGoogleAddressDirective, TruncateMiddleDirective, FitTextDirective, CopyPasteDirective,],
    imports: [
        CommonModule
    ],
    exports: [DisableControlDirective, ResponsiveDirective, ResponsiveClassDirective, AutorotateDirective, AutorotateDirective, ClickOutsideDirective, SafeInnerHtmlDirective, CompletedNgForDirective, ImageDisabledDirective, ScroolToDirective, ChangeOrientationDirective, ScrollEventDirective, LongPressDirective, GetGoogleAddressDirective, TruncateMiddleDirective, FitTextDirective, CopyPasteDirective]
})
export class DirectivesModule {
}
