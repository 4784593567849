<app-light-result
        style="min-width: 200px" [street]="street" [result]="computationResult">
</app-light-result>
<div style="display: flex; flex-flow: row; align-items: center; justify-content: center; margin-top: 1em;"
     [style.opacity]="showBestResult ? .5 : 1" [style.pointerEvents]="showBestResult ? 'none' : ''">
    <button type="button" class="fix-centering" mat-mini-fab color="primary" (click)="showResult(allResultsIndex-1)"
            [disabled]="allResultsIndex === 0" [matTooltip]="'streets.previous' | translate">
        <mat-icon>arrow_left</mat-icon>
    </button>
    <div style="padding: 0 2ch; text-align: center">
        <ng-container *ngIf="showBestResult;else noBestResult">
            {{'streets.best'|translate}}
        </ng-container>
        <ng-template #noBestResult>
            {{'streets.result'|translate}}: {{allResultsIndex + 1}}/{{computationResults.allResults.length}}<br>
            <mat-icon *ngIf="computationResults.allResults[allResultsIndex].verificato" aria-hidden="false"
                      style="color: green;" [attr.aria-label]="'streets.verified'|translate">done
            </mat-icon>
            <mat-icon *ngIf="!computationResults.allResults[allResultsIndex].verificato" aria-hidden="false"
                      style="color: #f44336;" [attr.aria-label]="'streets.not_verified'|translate">close
            </mat-icon>
        </ng-template>
    </div>
    <button type="button" class="fix-centering" mat-mini-fab color="primary" (click)="showResult(allResultsIndex+1)"
            [disabled]="allResultsIndex === computationResults.allResults.length - 1"
            [matTooltip]="'streets.next' | translate">
        <mat-icon>arrow_right</mat-icon>
    </button>
</div>
<div style="text-align: center; margin-top: 1em;">
    <mat-checkbox *ngIf="computationResults.bestResult" [checked]="showBestResult" #check
                  (change)="setShowBestResult(check.checked)" [disabled]="!allResultsIsSet">
        {{'streets.show_best_result'|translate}}
    </mat-checkbox>
    <ng-container *ngIf="!computationResults.bestResult">
        {{'streets.no_best_result'|translate}}
    </ng-container>
</div>

<table class="results-table key-value">
    <tr>
        <td>{{'streets.lamp'|translate}}:</td>
        <td>{{photometryResult.nomeLampada || 'N/A'}}</td>
    </tr>
    <tr>
        <td>{{'streets.manufacturer'|translate}}:</td>
        <td>{{photometryResult.produttore || 'N/A'}}</td>
    </tr>
    <tr>
        <td>{{'streets.product_name'|translate}}:</td>
        <td>{{photometryResult.nomeProdotto || 'N/A'}}</td>
    </tr>
    <tr>
        <td>{{'streets.photometry'|translate}}:</td>
        <td>{{photometryResult.nomeFile || 'N/A'}}</td>
    </tr>
    <tr>
        <td>{{'streets.power'|translate}}:</td>
        <td>{{fixedDigits(computationResult.potenza) || 'N/A'}}</td>
    </tr>
    <tr>
        <td>{{'streets.ipea'|translate}}:</td>
        <td>{{computationResult.IPEA || 'N/A'}}</td>
    </tr>
    <tr>
        <td>{{'streets.ipei'|translate}}:</td>
        <td>{{computationResult.IPEI || 'N/A'}}</td>
    </tr>
    <tr>
        <td>{{'streets.flux'|translate}}:</td>
        <td>{{fixedDigits(computationResult.flusso) || 'N/A'}}</td>
    </tr>
    <tr>
        <td>{{'streets.efficiency'|translate}}:</td>
        <td>{{fixedDigits(computationResult.efficienza) || 'N/A'}}</td>
    </tr>
    <ng-container *ngFor="let item of reverse(street.layout); let i = index" [ngSwitch]="item.tipo">
        <ng-container *ngIf="computationResult.risultati[street.layout.length - 1 - i].limiti">
            <tr>
                <td colspan="2">&nbsp;</td>
            </tr>
            <tr>
                <td colspan="2">
                    <ng-container *ngSwitchCase="StreetLayoutItemTypes.carreggiata">
                        {{'streets.roadway' | translate}}
                    </ng-container>
                    <ng-container *ngSwitchCase="StreetLayoutItemTypes.marciapiede">
                        {{'streets.sidewalk' | translate}}
                    </ng-container>
                    <ng-container *ngSwitchCase="StreetLayoutItemTypes.spartitraffico">
                        {{'streets.traffic_divider' | translate}}
                    </ng-container>
                </td>
            </tr>
            <tr *ngFor="let key of getObjectKeys(computationResult.risultati[street.layout.length - 1 - i].valori)">
                <td>
                    &nbsp;&nbsp;{{('streets.limits.' + computationResult.risultati[street.layout.length - 1 - i].limiti[key].name) | translate}}
                </td>
                <td>
                    <span *ngIf="computationResult.risultati[street.layout.length - 1 - i].limiti[key].min !== undefined"
                          class="little">{{fixedDigits(computationResult.risultati[street.layout.length - 1 - i].limiti[key].min)}}
                        /</span>
                    <span [class]="getClassFromValueAndLimit(computationResult.risultati[street.layout.length - 1 - i].valori[key], computationResult.risultati[street.layout.length - 1 - i].limiti[key])">
            {{fixedDigits(computationResult.risultati[street.layout.length - 1 - i].valori[key]) || 'N/A'}}
          </span>
                    <span *ngIf="computationResult.risultati[street.layout.length - 1 - i].limiti[key].max !== undefined"
                          class="little">/ {{fixedDigits(computationResult.risultati[street.layout.length - 1 - i].limiti[key].max)}}</span>
                </td>
            </tr>
        </ng-container>
    </ng-container>
</table>


<div style="display: flex; flex-flow: column; align-items: center; justify-content: center; margin-top: 1em;">
    <app-huna-spinner [avvia]="hunaLoader.avviaLoad" [dimension]="80"
                      [instantValue]="hunaLoader.valueSpinner"
                      [fakeActivies]="false"
                      *ngIf="hunaLoader.avviaLoad">
    </app-huna-spinner>

    <app-button-spinner [loading]="loading.print" style="margin-bottom: 5px">
        <button (click)="openDialog()" mat-flat-button color="primary" style="margin-bottom: 5px">
            <mat-icon>print</mat-icon>
        </button>
    </app-button-spinner>
    <app-button-spinner [loading]="loading.assegnaFotometrie" style="margin-bottom: 5px">
        <button (click)="assegnaFotometria()" mat-flat-button color="primary">
            {{'fotometrie.saveResult'|translate}}
        </button>
    </app-button-spinner>
    <button mat-flat-button color="primary" style="margin-bottom: 5px"
            [disabled]="hunaLoader.avviaLoad"
            (click)="updateVarianteKey()">{{'streets.updateLightPointsProject'|translate}}
    </button>
</div>
