import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {SchedaManutenzioneParse} from "../../../models/SchedaManutenzione.Parse";

@Component({
    selector: 'app-schedule-maintenance-detail',
    templateUrl: './schedule-maintenance-detail.component.html',
    styleUrls: ['./schedule-maintenance-detail.component.scss']
})
export class ScheduleMaintenanceDetailComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<ScheduleMaintenanceDetailComponent>,
                @Inject(MAT_DIALOG_DATA)
                public data: any) {
    }

    ngOnInit(): void {
    }

    get detailsScheda() {
        return this.data.detailsScheda
    }

    get schedaManutenzione(): SchedaManutenzioneParse {
        return this.data.schedaManutenzione
    }

    closeDialog(select = false) {
        this.dialogRef.close({select: select, schedaManutenzione: this.schedaManutenzione})
    }
}
