import {Component, ElementRef, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {DocumentsFolderParse} from "../../../models/DocumentsFolder.Parse";
import {FileManagerService} from "../../../providers/services/file-manager.service";
import {AlertService} from "../../../providers/services/alert.service";
import {UtilsService} from "../../../providers/services/utils.service";
import {forkJoin} from "rxjs";
import {DocumentsFileParse} from "../../../models/DocumentsFile.Parse";
import {isNotNullOrUndefined, traductionFunctionMasterFolder} from "../../../models/Models";

@Component({
    selector: 'app-navigate-in-file-and-folder',
    templateUrl: './navigate-in-file-and-folder.component.html',
    styleUrls: ['./navigate-in-file-and-folder.component.scss']
})
export class NavigateInFileAndFolderComponent implements OnInit {
    public loading = {folder: false, files: false};
    public folders: DocumentsFolderParse[];
    public files: DocumentsFileParse[];
    public selectedFiles: DocumentsFileParse[] = [];
    private stackFolderFileNavigation = new Map();
    public homeFolderName = 'home';
    private comeBackNavigation = [];
    public openPaths = [];
    public openSelectedFile = false;


    constructor(
        public dialogRef: MatDialogRef<NavigateInFileAndFolderComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: { folders: DocumentsFolderParse[], currentFolder: DocumentsFolderParse, numberOfFiles: number },
        private fileManagerService: FileManagerService,
        private alertService: AlertService,
        private utilService: UtilsService
    ) {
    }

    ngOnInit(): void {
        this.getFileAndFolder(undefined)
    }

    public getIndexSelectedFile(file: DocumentsFileParse): number {
        if (Array.isArray(this.selectedFiles)) {
            return this.selectedFiles.findIndex(fileSelected => fileSelected.objectId === file.objectId)
        } else {
            return -1
        }
    }

    isSelectedFile(file: DocumentsFileParse): boolean {
        return this.getIndexSelectedFile(file) >= 0;
    }

    getColorIcon(file) {
        return this.isSelectedFile(file) ? 'primary' : ''
    }

    selectFile(file: DocumentsFileParse): void {
        const index = this.getIndexSelectedFile(file);
        if (index >= 0) {
            this.selectedFiles.splice(index, 1);
            if (this.selectedFiles.length === 0) {
                this.openSelectedFile = false;
            }
        } else {
            this.selectedFiles.push(file);
        }
    }

    getFileAndFolder(parentFolder: DocumentsFolderParse) {
        let folder;
        if (isNotNullOrUndefined(parentFolder)) {
            folder = parentFolder.objectId;
            this.openPaths = [...this.openPaths, parentFolder.name];
        } else {
            folder = this.homeFolderName;
        }
        this.comeBackNavigation.push(folder);
        if (!this.stackFolderFileNavigation.has(folder)) {
            this.loading.folder = true;
            forkJoin([this.fileManagerService.getAllFoldersInFolder(parentFolder, false), this.fileManagerService.getAllFilesInFolder(parentFolder)]).subscribe(
                foldersFile => {
                    this.folders = foldersFile[0];
                    this.files = foldersFile[1];
                    this.stackFolderFileNavigation.set(folder, {folders: this.folders, files: this.files})
                    this.loading.folder = false;
                }, error => {
                    this.alertService.error(error);
                    this.loading.folder = false;
                }
            )
        } else {
            const fileFolders = this.stackFolderFileNavigation.get(folder);
            this.folders = fileFolders.folders;
            this.files = fileFolders.files;
            this.loading.folder = false;
        }
    }

    openFolder(folder) {
        this.getFileAndFolder(folder);
    }

    comeBack() {
        const indexLastFolders = this.comeBackNavigation.length - 2;
        if (indexLastFolders >= 0) {
            const lastFolder = this.comeBackNavigation[indexLastFolders];
            if (lastFolder !== this.homeFolderName) {
                const folder = new DocumentsFolderParse();
                folder.objectId = lastFolder;
            }
            const fileFolder = this.stackFolderFileNavigation.get(lastFolder);
            this.folders = fileFolder.folders;
            this.files = fileFolder.files;
            this.comeBackNavigation.pop()
            this.openPaths = this.openPaths.slice(0, -1);
        }
    }

    goBackMultipleSteps(steps: number) {
        for (let step = 0; step < steps; step++) {
            this.comeBack();
        }
    }

    pathButtonDisabled(i: number) {
        return this.openPaths.length - i <= 1;
    }

    get disableComeBackButton() {
        return this.comeBackNavigation.length == 0 || this.comeBackNavigation[this.comeBackNavigation.length - 1] == this.currentFolderName;
    }

    get disableSaveButton() {
        return !Array.isArray(this.selectedFiles) || this.selectedFiles.length == 0;
    }

    get currentFolderName() {
        const name = (this.data.currentFolder) ? this.data.currentFolder.objectId : this.homeFolderName;
        return name;
    }

    get numberFileSelected() {
        return Array.isArray(this.selectedFiles) ? this.selectedFiles.length : 0
    }

    closeDialog(what: 'close' | 'connectFile') {
        if (what === 'close') {
            this.dialogRef.close(undefined)
        } else if (what == 'connectFile') {
            this.dialogRef.close({files: this.selectedFiles})
        }
    }

    // getmaxHeigth(div: HTMLDivElement) {
    //     // const heigth = div.clientHeight + 28;
    //     // console.log(heigth)
    //     return 'calc(100% - ' + 50 + 'px )'
    // }


    traductionFunction = traductionFunctionMasterFolder;

}
