import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FotometriaParse} from "../../models/Fotometria.Parse";
import {TranslateService} from "@ngx-translate/core";
import {isNotNullOrUndefined} from "../../models/Models";

@Component({
    selector: 'app-box-photometry',
    templateUrl: './box-photometry.component.html',
    styleUrls: ['./box-photometry.component.scss']
})
export class BoxPhotometryComponent implements OnInit {

    @Input()
    photometry: FotometriaParse
    @Input()
    disabled = false;
    @Input()
    showHeader = true;
    @Output() clickButton = new EventEmitter()
    @Input() icon: { matIcon: boolean, pathName: string };

    constructor(private translateService: TranslateService) {
    }

    ngOnInit(): void {
    }

    getFotometriaValue(fotometria: FotometriaParse, label) {
        const value = fotometria[label];
        if (!isNotNullOrUndefined(value)) {
            const ND = this.translateService.instant('NaN');
            return ND;
        } else if (label == 'tipologiaCorpoIlluminante') {
            const traduction = this.translateService.instant('dashboard_sidenav.PuntiLuce.tipologiaCorpoIlluminante.possibleValues.' + value.toString().replace(/ /g, '_'));
            return (traduction.includes('dashboard_sidenav.PuntiLuce')) ? value : traduction;
        } else if (typeof value == "string") {
            return value;
        } else if (typeof value == "number") {
            return Math.round(value * 100) / 100
        } else {
            const ND = this.translateService.instant('NaN');
            return ND;
        }
    }

    getUnit(label: string) {
        if (label.includes('potenza')) {
            return 'W'
        } else if (label.includes('efficenza')) {
            return 'lm/W'

        } else if (label.includes('flusso')) {
            return 'lm'
        } else {
            return ''
        }
    }

}
