import * as Parse from 'parse';
import {arrayIsSet, KeyStringValueAny} from "./Models";

export type LayoutType = {
    tipo: string,
    categoriaIlluminotecnica: string,
    larghezza: number,
    numeroCorsie: number
}
export type LampadeType = {
    altezza: number,
    origine_x: number,
    origine_y: number,
    sbraccio: number,
    angoloRotazione: number,
    angoloInclinazione: number,
    gruppo: number
}

export class StradeParse extends Parse.Object {

    public static CLASS_NAME = 'Strade';

    constructor() {
        super(StradeParse.CLASS_NAME);
    }


    public set objectId(value) {
        this.id = value;
    }

    public get objectId() {
        return this.id;
    }

    public set angoloTilt(value) {
        super.set('angoloTilt', value);
    }

    public get angoloTilt(): number {
        return super.get('angoloTilt');
    }

    public set classificazioneStrada(value) {
        super.set('classificazioneStrada', value);
    }

    public get classificazioneStrada(): string {
        return super.get('classificazioneStrada');
    }

    public set fattoreManutenzione(value) {
        super.set('fattoreManutenzione', value);
    }

    public get fattoreManutenzione(): number {
        return super.get('fattoreManutenzione');
    }

    public set interdistanza(value) {
        super.set('interdistanza', value);
    }

    public get interdistanza(): number {
        return super.get('interdistanza');
    }

    public set includiMarciapiedi(value) {
        super.set('includiMarciapiedi', value);
    }

    public get includiMarciapiedi(): boolean {
        return super.get('includiMarciapiedi');

    }

    public set calcoloSemicilindrico(value) {
        super.set('calcoloSemicilindrico', value);
    }

    public get calcoloSemicilindrico(): boolean {
        return super.get('calcoloSemicilindrico');
    }

    public set gruppoFotometrie(value) {
        super.set('gruppoFotometrie', value);
    }

    public get gruppoFotometrie(): Parse.Object<any> {
        return super.get('gruppoFotometrie');
    }


    public set progetto(value) {
        super.set('progetto', value);
    }

    public get progetto(): any {
        return super.get('progetto');
    }

    public set layout(value) {
        super.set('layout', value);
    }

    public get layout(): LayoutType[] {
        return super.get('layout');
    }

    public addLayout(value): void {
        super.get('layout').add(value);
    }

    public removeLayouts(layouts: LayoutType[]) {
        if (arrayIsSet(this.layout)) {
            const isEquals = (l1: LayoutType, l2: LayoutType) => {
                const keysL1 = Object.keys(l1)
                const keysL2 = Object.keys(l2)
                if (keysL1.length !== keysL2.length) {
                    return false
                } else {
                    const indexKey1 = keysL1.findIndex(key =>
                        l1[key] != l2[key]
                    )
                    const indexKey2 = keysL2.findIndex(key =>
                        l2[key] != l1[key]
                    )
                    return indexKey1 >= 0 || indexKey2 >= 0
                }
            }
            this.layout = this.layout.filter(l => {
                const index = layouts.findIndex(layoutRemove => isEquals(l, layoutRemove))
                return index < 0
            });
        }
    }

    public set lampade(value) {
        super.set('lampade', value);
    }

    public get lampade(): LampadeType[] {
        return super.get('lampade');
    }

    public addLampade(value): void {
        super.get('lampade').add(value);
    }

    public removeLampade(lampade: LampadeType[]) {
        if (arrayIsSet(this.lampade)) {
            const isEquals = (l1: LampadeType, l2: LampadeType) => {
                const keysL1 = Object.keys(l1)
                const keysL2 = Object.keys(l2)
                if (keysL1.length !== keysL2.length) {
                    return false
                } else {
                    const indexKey1 = keysL1.findIndex(key =>
                        l1[key] != l2[key]
                    )
                    const indexKey2 = keysL2.findIndex(key =>
                        l2[key] != l1[key]
                    )
                    return indexKey1 >= 0 || indexKey2 >= 0
                }
            }
            this.lampade = this.lampade.filter(l => {
                const index = lampade.findIndex(layoutRemove => isEquals(l, layoutRemove))
                return index < 0
            })
        }
    }

    public set fotometrie(value) {
        super.set('fotometrie', value);
    }

    public unSetFotometrie() {
        super.unset('fotometrie');
    }

    public get fotometrie(): any[] {
        return super.get('fotometrie');
    }

    public addFotometria(value): void {
        if (value != null) {
            if (arrayIsSet(this.fotometrie)) {
                const index = this.fotometrie.findIndex(fotometria => fotometria.objectId === value.objectId)
                if (index < 0) {
                    this.fotometrie.push(value)
                }
            } else {
                this.fotometrie = [value];
            }
        }
    }

    public removeFotometrie(fotometrie: any[]) {
        if (arrayIsSet(this.fotometrie)) {
            const isEquals = (l1: any, l2: any) => {
                return l1.objectId === l2.objectId;
            }
            this.fotometrie = this.fotometrie.filter(f => {
                const index = fotometrie.findIndex(fotometriaRemove => isEquals(f, fotometriaRemove))
                return index < 0
            })
        }
    }


    public set nome(value) {
        super.set('nome', value);
    }

    public get nome(): string {
        return super.get('nome');
    }

    public set note(value) {
        super.set('note', value);
    }

    public get note(): string {
        return super.get('note');
    }

    public set noteCalcolo(value) {
        super.set('noteCalcolo', value);
    }

    public get noteCalcolo(): KeyStringValueAny {
        return super.get('noteCalcolo');
    }

    public get isCentroStorico(): string {
        return super.get('isCentroStorico');
    }

    public set isCentroStorico(value) {
        super.set('isCentroStorico', value);
    }

    public get verificaIPEI_A(): string {
        return super.get('verificaIPEI_A');
    }

    public set verificaIPEI_A(value) {
        super.set('verificaIPEI_A', value);
    }

    public get tipologiaAreaIlluminata(): string {
        return super.get('tipologiaAreaIlluminata');
    }

    public set tipologiaAreaIlluminata(value) {
        super.set('tipologiaAreaIlluminata', value);
    }


    public get query(): Parse.Query<StradeParse> {
        return new Parse.Query(StradeParse)
    }

}

Parse.Object.registerSubclass(StradeParse.CLASS_NAME, StradeParse);
