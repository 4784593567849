<ng-container *ngIf="!navbarService.disabledNavbar;else noNavBar">
    <app-navbar *ngIf="isLogged"></app-navbar>
</ng-container>
<ng-template #noNavBar>
    <div style="padding-bottom: -100px"></div>
</ng-template>
<mat-sidenav-container fullscreen [class.sidenav-container-custom]="isLogged && !navbarService.disabledNavbar">
    <mat-sidenav #sidenav mode="over">
        <mat-nav-list>
            <ng-container
                    *ngFor="let link of links|convertValueByPredicateFunction:filterLinkPredicate:(roles$|async):(abbonamentoGestioneAttivo$|async):(abbonamentoTelecontrolloAttivo$|async):(abbonamentoProgettazioneAttivo$|async):verificaUserLevel">
                <ng-container *ngIf="!link.isTitleGroup;else TitleGroup">
                    <a style="padding-left: 10px"
                       mat-list-item
                       [ngClass]="link|convertValueByPredicateFunction:getClassLink:(currentLink$|async)"
                       (click)="onClickNav(link)">
                        <ng-container *ngIf="link.iconCustom;else icon">
                            <img src="{{link.iconCustom}}" class="img-icon-L">
                        </ng-container>
                        <ng-template #icon>
                            <mat-icon *ngIf="link.icon">{{link.icon}}</mat-icon>
                        </ng-template>
                        <div style="margin-left: 0.4rem;">{{link.title | translate}}</div>
                    </a>
                </ng-container>
                <ng-template #TitleGroup>
                    <mat-divider style="margin-bottom: 10px;"></mat-divider>
                    <span style="margin-left: 10px;font-weight: bold;text-transform: uppercase">{{link.title|translate}}</span>
                </ng-template>
            </ng-container>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <div [ngClass]="cssClass" *ngIf="backgroundIsActive"
             style="z-index: 1001;">
            <ng-container>
                <ng-container *ngIf="backgroundLoadvalue$ |async as valuesUploading">
                    <mat-card>
                        <mat-icon style="position: absolute;top: -3px;right: 3px;cursor: pointer"
                                  (click)="closeShowOrHidden()" color="primary">
                            minimize
                        </mat-icon>
                        <mat-card-subtitle *ngIf="valuesUploading.message" class="showHidden"
                                           style="margin-right: 12px;margin-bottom: -5px">
                            {{valuesUploading.title}}
                        </mat-card-subtitle>
                        <mat-card-actions class="showHidden">
                            <span style="font-size: small" *ngIf="valuesUploading.message" class="showHidden">
                                {{valuesUploading.message}}
                            </span>
                            <mat-progress-bar mode="determinate" [value]="valuesUploading.progress">
                            </mat-progress-bar>
                        </mat-card-actions>
                    </mat-card>
                </ng-container>
            </ng-container>
        </div>
        <router-outlet>

        </router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
<app-loader></app-loader>
