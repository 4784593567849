import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService, LangChangeEvent} from '@ngx-translate/core';
import {map, startWith} from 'rxjs/operators';
import {formatDate} from '@angular/common';
import {Observable} from "rxjs";
import {isNotNullOrUndefined} from "../../models/Models";

@Pipe({
    name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {

    constructor(private translateService: TranslateService,
    ) {

    }

    transform(value: string | Date | number, extendMode, manualMode: string = undefined, time = 'shortTime'): Observable<any> {
        return this.translateService.onLangChange.pipe(
            startWith({lang: this.translateService.currentLang}),
            map((lang: LangChangeEvent) => {
                return lang.lang + '-' + lang.lang.toUpperCase();
                // il formato dell ora viene  preso dalla lingua impostata
            }),
            map((locale) => {
                return this.getDate(value, extendMode, time, locale, manualMode)
            }));
    }

    getDate(value, extendMode, time, locale, manualMode) {
        if (locale == null) {
            locale = this.translateService.currentLang
        }
        if (!isNotNullOrUndefined(value)) {
            return value;
        } else if (isNotNullOrUndefined(manualMode)) {
            if ((extendMode.includes('Date') || extendMode.includes('date')) && manualMode === 'noYear') {
                let date = formatDate(value, extendMode, locale!);
                return date.slice(0, -4).replace(',', '');
            } else {
                return (formatDate(value, manualMode, locale));
            }
        } else if (extendMode.includes('Date') || extendMode.includes('date')) {
            return (formatDate(value, extendMode, locale));
        } else {
            return (formatDate(value, extendMode + 'Date', locale) + ' ' + formatDate(value, time, locale));
        }
    }
}
