import {Injectable} from '@angular/core';
import {BehaviorSubject, ReplaySubject, Subject} from "rxjs";
import {arrayIsSet, getToday, stringIsSet} from "../../models/Models";
import {ProgettiParse} from "../../models/Progetti.Parse";
import {fromPromise} from "rxjs/internal-compatibility";

export type BozzaSchedaManutenzioneCompilata = {
    schedamanutenzioneId: string,
    valuesForm: { traduction: string, value: any } [],
    element: { className: string, objectId: string },
    createdAt: number
}

@Injectable({
    providedIn: 'root'
})
export class LocalSotrageService {

    protected readonly PROJECT_ID_STORAGE = 'projectId';
    protected readonly PROJECT_FIRST_VIEW_STORAGE = 'projectFirstViewOnOpening';
    public changeProject$: Subject<any> = new Subject();
    public currentProjectEmit = new BehaviorSubject(undefined);
    protected readonly PROJECT_SIGLA_STORAGE = 'projectSigla';
    protected readonly PROJECT_NAME_STORAGE = 'projectName';
    protected readonly PROJECT_ROLE_STORAGE = 'role';
    protected readonly MODALITY_VISUALIZATION_MAIN_MAP = 'modalityVisualizationMainMap';
    protected readonly LAST_SEARCH_VALUE = 'LAST_SEARCH_VALUE';
    protected readonly SCHEDA_MANUTENZIONE_PRE_COMPILATA = 'SCHEDA_MANUTENZIONE_PRE_COMPILATA';
    protected readonly FAVORITE_KEYS_CIRCUITI = ' FAVORITE_KEYS_CIRCUITI';
    protected readonly MAP_LAT = 'MAP_LAT';
    protected readonly MAP_LNG = 'MAP_LNG';
    protected readonly MAP_ZOOM = 'MAP_ZOOM';
    protected readonly FAVORITE_KEYS_PUNTI_LUCI = 'FAVORITE_KEYS_PUNTI_LUCI';
    protected readonly LABEL_ETICHETTE_PUNTI_LUCI = 'LABEL_ETICHETTE_PUNTI_LUCI';
    protected readonly LABEL_SCALE_COLOR_PUNTI_LUCI = 'LABEL_SCALE_COLOR_PUNTI_LUCI';
    protected readonly LABEL_ETICHETTE_CIRCUITI = 'LABEL_ETICHETTE_CIRCUITI';
    protected readonly LABEL_SCALE_COLOR_CIRCUITI = 'LABEL_SCALE_COLOR_CIRCUITI';
    protected readonly BATCH_LIGHTPOINTS_EDITOR = 'BATCH_LIGHTPOINTS_EDITOR';
    protected readonly RANGE_DATE_CALENDARIO = 'RANGE_DATE_CALENDARIO';

    constructor() {
        const idPROJECT = sessionStorage.getItem(this.PROJECT_ID_STORAGE);
        if (stringIsSet(idPROJECT)) {
            const p = new ProgettiParse()
            p.objectId = idPROJECT;
            this.project = p
            p.fetch$().subscribe(p => {
                this.project = p
            })

        }
    }

    set project(project: ProgettiParse) {
        this.currentProjectEmit.next(project);
        sessionStorage.setItem(this.PROJECT_ID_STORAGE, project.objectId);
    }

    get project(): ProgettiParse {
        return this.currentProjectEmit.value;
    }

    set projectId(value) {
        this.changeProject$.next(value);
        const p = new ProgettiParse()
        p.objectId = value
        this.currentProjectEmit.next(p)
        sessionStorage.setItem(this.PROJECT_ID_STORAGE, value);
    }

    get projectId() {
        return sessionStorage.getItem(this.PROJECT_ID_STORAGE);
    }

    set siglaProgetto(value) {
        sessionStorage.setItem(this.PROJECT_SIGLA_STORAGE, value);
    }

    get siglaProgetto() {
        return sessionStorage.getItem(this.PROJECT_SIGLA_STORAGE);
    }

    set nomeProgetto(value) {
        sessionStorage.setItem(this.PROJECT_NAME_STORAGE, value);
    }

    get nomeProgetto() {
        return sessionStorage.getItem(this.PROJECT_NAME_STORAGE);
    }

    set roleProgetto(value: string []) {
        sessionStorage.setItem(this.PROJECT_ROLE_STORAGE, JSON.stringify(value));
    }

    get roleProgetto(): string[] {
        return JSON.parse(sessionStorage.getItem(this.PROJECT_ROLE_STORAGE));
    }

    set projectFirstView(value) {
        sessionStorage.setItem(this.PROJECT_FIRST_VIEW_STORAGE, value);
    }

    get projectFirstView() {
        return sessionStorage.getItem(this.PROJECT_FIRST_VIEW_STORAGE);
    }

    set modalityViewMap(value: string) {
        localStorage.setItem(this.MODALITY_VISUALIZATION_MAIN_MAP, value);
    }

    get modalityViewMap(): string {
        return localStorage.getItem(this.MODALITY_VISUALIZATION_MAIN_MAP);
    }

    set lastSmartSearchValue(value: string[]) {
        localStorage.setItem(this.LAST_SEARCH_VALUE, JSON.stringify(value));
    }

    get lastSmartSearchValue(): string[] {
        return JSON.parse(localStorage.getItem(this.LAST_SEARCH_VALUE));
    }

    destroyLastSmartSearchValue() {
        localStorage.removeItem(this.LAST_SEARCH_VALUE);
    }


    set favoritesKeyCircuiti(value: string[]) {
        if (value == null) {
            localStorage.removeItem(this.FAVORITE_KEYS_CIRCUITI)
        } else {
            localStorage.setItem(this.FAVORITE_KEYS_CIRCUITI, JSON.stringify(value));
        }
    }

    get favoritesKeyCircuiti(): string[] {
        return JSON.parse(localStorage.getItem(this.FAVORITE_KEYS_CIRCUITI));
    }

    set favoritesKeyPuntiLuce(value: string[]) {
        if (value == null) {
            localStorage.removeItem(this.FAVORITE_KEYS_PUNTI_LUCI);
        } else {
            localStorage.setItem(this.FAVORITE_KEYS_PUNTI_LUCI, JSON.stringify(value));
        }
    }

    get favoritesKeyPuntiLuce(): string[] {
        return JSON.parse(localStorage.getItem(this.FAVORITE_KEYS_PUNTI_LUCI));
    }

    set schedeManutenzionePrecompilate(value: BozzaSchedaManutenzioneCompilata[]) {
        localStorage.setItem(this.SCHEDA_MANUTENZIONE_PRE_COMPILATA, JSON.stringify(value));
    }

    get schedeManutenzionePrecompilate(): BozzaSchedaManutenzioneCompilata[] {
        return JSON.parse(localStorage.getItem(this.SCHEDA_MANUTENZIONE_PRE_COMPILATA));
    }

    pushOrSubstitionSchedaCompilata(value: BozzaSchedaManutenzioneCompilata): BozzaSchedaManutenzioneCompilata[] {
        let schedePrecompilate = this.schedeManutenzionePrecompilate;
        if (!arrayIsSet(schedePrecompilate)) {
            schedePrecompilate = [value];
        } else {
            const index = schedePrecompilate.findIndex(scheda => scheda.schedamanutenzioneId == value.schedamanutenzioneId && this.isEqualsElement(scheda.element, value.element))
            if (index >= 0) {
                schedePrecompilate[index] = value;
            } else {
                const maxNumberSchedePrecompilate = 10;
                if (schedePrecompilate.length > maxNumberSchedePrecompilate) {
                    let indexLastCreatedAt;
                    let lastCreatedAt = 0;
                    schedePrecompilate.forEach((schedePrecompilate, index) => {
                        if (schedePrecompilate.createdAt > lastCreatedAt) {
                            lastCreatedAt = schedePrecompilate.createdAt;
                            indexLastCreatedAt = index;
                        }
                    })
                    schedePrecompilate[index] = value;
                } else {
                    schedePrecompilate.push(value);
                }
            }
        }
        this.schedeManutenzionePrecompilate = schedePrecompilate;
        return schedePrecompilate;
    }

    private isEqualsElement(element1: { className: string, objectId: string }, element2: {
        className: string,
        objectId: string
    }): boolean {
        return element1.className === element2.className && element1.objectId === element2.objectId;
    }

    getSchedaManutenzionePrecompilataBySchedaManutenzioneIdElementId(schedaManutenzioneId: string, element: {
        className: string,
        objectId: string
    }): BozzaSchedaManutenzioneCompilata {
        let schedaPrecompilata;
        if (arrayIsSet(this.schedeManutenzionePrecompilate)) {
            schedaPrecompilata = this.schedeManutenzionePrecompilate.find(scheda => scheda.schedamanutenzioneId === schedaManutenzioneId && this.isEqualsElement(scheda.element, element))
        }
        return schedaPrecompilata;
    }


    destroySchedaManutenzionePrecompilataByElementIdSchedamanutenzioneId(schedaManutenzioneId: string, element: {
        className: string,
        objectId: string
    }): void {
        let schedeManutenzione;
        if (arrayIsSet(this.schedeManutenzionePrecompilate)) {
            schedeManutenzione = this.schedeManutenzionePrecompilate.filter(scheda => scheda.schedamanutenzioneId !== schedaManutenzioneId || !this.isEqualsElement(scheda.element, element))
        }
        if (arrayIsSet(schedeManutenzione)) {
            this.schedeManutenzionePrecompilate = schedeManutenzione;
        } else {
            this.destroySchedaManutenzionePrecompilata();
        }
    }

    destroySchedaManutenzionePrecompilata(): void {
        localStorage.removeItem(this.SCHEDA_MANUTENZIONE_PRE_COMPILATA);
    }

    get mapLat() {
        return localStorage.getItem(this.MAP_LAT);
    }

    set mapLat(value) {
        localStorage.setItem(this.MAP_LAT, value);
    }

    get mapLng() {
        return localStorage.getItem(this.MAP_LNG);
    }

    set mapLng(value) {
        localStorage.setItem(this.MAP_LNG, value);
    }

    get mapZoom() {
        return localStorage.getItem(this.MAP_ZOOM);
    }

    set mapZoom(value) {
        localStorage.setItem(this.MAP_ZOOM, value);
    }

    set labelScaleColorLightPoints(value: string) {
        if (stringIsSet(value)) {
            localStorage.setItem(this.LABEL_SCALE_COLOR_PUNTI_LUCI, value);
        } else {
            localStorage.removeItem(this.LABEL_SCALE_COLOR_PUNTI_LUCI);
        }
    }

    get labelScaleColorLightPoints(): string {
        return localStorage.getItem(this.LABEL_SCALE_COLOR_PUNTI_LUCI);
    }

    set labelLabelsLightPoints(value: string) {
        if (stringIsSet(value)) {
            localStorage.setItem(this.LABEL_ETICHETTE_PUNTI_LUCI, value);
        } else {
            localStorage.removeItem(this.LABEL_ETICHETTE_PUNTI_LUCI);
        }
    }

    get labelLabelsLightPoints(): string {
        return localStorage.getItem(this.LABEL_ETICHETTE_PUNTI_LUCI);
    }

    set labelScaleColorCircuits(value: string) {
        if (stringIsSet(value)) {
            localStorage.setItem(this.LABEL_SCALE_COLOR_CIRCUITI, value);
        } else {
            localStorage.removeItem(this.LABEL_SCALE_COLOR_CIRCUITI);
        }
    }

    get labelScaleColorCircuits(): string {
        return localStorage.getItem(this.LABEL_SCALE_COLOR_CIRCUITI);
    }

    set labelLabelsCircuits(value: string) {
        if (stringIsSet(value)) {
            localStorage.setItem(this.LABEL_ETICHETTE_CIRCUITI, value);
        } else {
            localStorage.removeItem(this.LABEL_ETICHETTE_CIRCUITI);
        }
    }

    get labelLabelsCircuits(): string {
        return localStorage.getItem(this.LABEL_ETICHETTE_CIRCUITI);
    }

    set batchLightPointsEditor(value: string[]) {
        if (arrayIsSet(value)) {
            localStorage.setItem(this.BATCH_LIGHTPOINTS_EDITOR, JSON.stringify(value));
        } else {
            localStorage.removeItem(this.BATCH_LIGHTPOINTS_EDITOR);
        }
    }

    get batchLightPointsEditor(): string[] {
        return JSON.parse(localStorage.getItem(this.BATCH_LIGHTPOINTS_EDITOR));
    }

    set rangeDateCalendario(value: { fromDate: Date, toDate: Date }) {
        if (value != null && value.fromDate && value.toDate) {
            const fromDate = value.fromDate.getTime();
            const toDate = value.toDate.getTime();
            localStorage.setItem(this.RANGE_DATE_CALENDARIO, JSON.stringify({fromDate, toDate}));
        } else {
            localStorage.removeItem(this.RANGE_DATE_CALENDARIO);
        }
    }

    get rangeDateCalendario(): { fromDate: Date, toDate: Date } {
        const value = JSON.parse(localStorage.getItem(this.RANGE_DATE_CALENDARIO))
        let fromDate, toDate;
        if (value != null) {
            fromDate = new Date(value.fromDate);
            toDate = new Date(value.toDate);
        } else {
            const before = getToday();
            before.setDate(before.getDate() - 7);
            fromDate = before;
            toDate = getToday();
        }
        return {fromDate, toDate};
    }

    removeMapItems() {
        localStorage.removeItem(this.MAP_LAT);
        localStorage.removeItem(this.MAP_LNG);
        localStorage.removeItem(this.MAP_ZOOM);
    }

    removeAll() {
        sessionStorage.removeItem(this.PROJECT_ID_STORAGE);
        sessionStorage.removeItem(this.PROJECT_ROLE_STORAGE);
        sessionStorage.removeItem(this.PROJECT_NAME_STORAGE);
        sessionStorage.removeItem(this.PROJECT_SIGLA_STORAGE);
        sessionStorage.removeItem(this.PROJECT_FIRST_VIEW_STORAGE);
        localStorage.removeItem(this.MODALITY_VISUALIZATION_MAIN_MAP);
        localStorage.removeItem(this.LABEL_ETICHETTE_PUNTI_LUCI);
        localStorage.removeItem(this.LABEL_SCALE_COLOR_PUNTI_LUCI);
        localStorage.removeItem(this.LABEL_ETICHETTE_CIRCUITI);
        localStorage.removeItem(this.LABEL_SCALE_COLOR_CIRCUITI);
        localStorage.removeItem(this.BATCH_LIGHTPOINTS_EDITOR);
        localStorage.removeItem(this.RANGE_DATE_CALENDARIO);
        this.destroyLastSmartSearchValue();
        this.removeMapItems();
    }
}


