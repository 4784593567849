import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BoxCircuitComponent} from './box-circuit/box-circuit.component';
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {PipesModule} from "../providers/pipes/pipes.module";
import {RouterModule} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import { BoxProgectComponent } from './box-progect/box-progect.component';
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import {MatIconModule} from "@angular/material/icon";
import {MatGridListModule} from "@angular/material/grid-list";
import { BoxInstallazioneLampadeComponent } from './box-installazione-lampade/box-installazione-lampade.component';
import { BoxAddingTabComponent } from './box-adding-tab/box-adding-tab.component';
import {MatBadgeModule} from "@angular/material/badge";
import {MatLegacyMenuModule as MatMenuModule} from "@angular/material/legacy-menu";
import {MatLegacyCheckboxModule as MatCheckboxModule} from "@angular/material/legacy-checkbox";
import {BoxGroupPhotometriesComponent} from "./box-photometries/box-group-photometries.component";
import { BoxPhotometryComponent } from './box-photometry/box-photometry.component';
import { BoxDocumentComponent } from './box-document/box-document.component';
import { BoxSchedeManutenzioneComponent } from './box-schede-manutenzione/box-schede-manutenzione.component';
import {MatLegacyTableModule as MatTableModule} from "@angular/material/legacy-table";
import {WidgetsModule} from "../widgets/widgets.module";
import { BoxSchedaManutenzioneCompilataComponent } from './box-scheda-manutenzione-compilata/box-scheda-manutenzione-compilata.component';
import { BoxGeneralInfoComponent } from './box-general-info/box-general-info.component';
import { BoxErrorsComponent } from './box-errors/box-errors.component';
import {ClipboardModule} from "@angular/cdk/clipboard";
import { BoxSegnalazioneComponent } from './box-segnalazione/box-segnalazione.component';
import {ReactiveFormsModule} from "@angular/forms";
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from "@angular/material/legacy-progress-spinner";
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { BoxCaricoEsogenoComponent } from './box-carico-esogeno/box-carico-esogeno.component';
import {MatExpansionModule} from "@angular/material/expansion";
import {MatLegacyTooltipModule as MatTooltipModule} from "@angular/material/legacy-tooltip";
import { BoxProfiloFunzionamentoComponent } from './box-profilo-funzionamento/box-profilo-funzionamento.component';


@NgModule({
    declarations: [BoxCircuitComponent, BoxProgectComponent, BoxInstallazioneLampadeComponent, BoxAddingTabComponent, BoxGroupPhotometriesComponent, BoxPhotometryComponent, BoxDocumentComponent, BoxSchedeManutenzioneComponent, BoxSchedaManutenzioneCompilataComponent, BoxGeneralInfoComponent, BoxErrorsComponent, BoxSegnalazioneComponent, BoxCaricoEsogenoComponent, BoxProfiloFunzionamentoComponent,],
    imports: [
        CommonModule,
        MatCardModule,
        PipesModule,
        RouterModule,
        TranslateModule,
        MatButtonModule,
        MatIconModule,
        MatGridListModule,
        MatBadgeModule,
        MatMenuModule,
        MatCheckboxModule,
        MatTableModule,
        WidgetsModule,
        ClipboardModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatExpansionModule,
        MatDividerModule,
        MatListModule,
        MatTooltipModule,

    ],
    exports: [BoxCircuitComponent, BoxProgectComponent, BoxInstallazioneLampadeComponent, BoxAddingTabComponent, BoxGroupPhotometriesComponent, BoxPhotometryComponent, BoxDocumentComponent, BoxSchedeManutenzioneComponent, BoxSchedaManutenzioneCompilataComponent, BoxGeneralInfoComponent, BoxErrorsComponent, BoxSegnalazioneComponent, BoxCaricoEsogenoComponent, BoxProfiloFunzionamentoComponent]
})
export class BoxesModule {
}
