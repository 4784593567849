import {Injectable} from '@angular/core';
import * as Parse from 'parse';

export class GeoPoint extends Parse.GeoPoint {

}


// export const getTiles = (bounds, quattroN) => {
//     const getTilesInBounds = (tile: google.maps.LatLngBounds) => {
//         const ne = tile.getNorthEast();
//         const sw = tile.getSouthWest();
//         const center = tile.getCenter();
//         const tilesInBounds = []
//         tilesInBounds.push(
//             new google.maps.LatLngBounds(
//                 new google.maps.LatLng(center.lat(), sw.lng()),
//                 new google.maps.LatLng(ne.lat(), center.lng()),
//             )
//         );
//         tilesInBounds.push(
//             new google.maps.LatLngBounds(
//                 new google.maps.LatLng(center.lat(), center.lng()),
//                 new google.maps.LatLng(ne.lat(), ne.lng()),
//             )
//         );
//         tilesInBounds.push(
//             new google.maps.LatLngBounds(
//                 new google.maps.LatLng(sw.lat(), sw.lng()),
//                 new google.maps.LatLng(center.lat(), center.lng()),
//             )
//         );
//         tilesInBounds.push(
//             new google.maps.LatLngBounds(
//                 new google.maps.LatLng(sw.lat(), center.lng()),
//                 new google.maps.LatLng(center.lat(), ne.lng()),
//             )
//         );
//         return tilesInBounds;
//     }
//     let tiles = [bounds];
//     let i = 1;
//     while (i <= quattroN) {
//         tiles = tiles.reduce((prev, tile) => {
//             prev = prev.concat(getTilesInBounds(tile));
//             return prev;
//         }, []);
//         i++;
//     }
//     return tiles;
// }

@Injectable({
    providedIn: 'root'
})
export class GeoPointService {

    constructor() {
    }

    getDistance(start: GeoPoint | any, end: GeoPoint | any): number {
        if (start.kilometersTo != null) {
            return start.kilometersTo(end);
        } else if (start.lat) {
            const geoPointStart = this.newGeopoint({lat: start.lat(), lng: start.lng()})
            const geoPointEnd = this.newGeopoint({lat: end.lat(), lng: end.lng()})
            return geoPointStart.kilometersTo(geoPointEnd);
        } else if (start.latitude) {
            const geoPointStart = this.newGeopoint({lat: start.latitude, lng: start.longitude})
            const geoPointEnd = this.newGeopoint({lat: end.latitude, lng: end.longitude})
            return geoPointStart.kilometersTo(geoPointEnd);
        } else {
            return NaN;
        }
    }

    newGeopoint(point: { lat: number, lng: number }): GeoPoint {
        return new GeoPoint(point.lat, point.lng);
    }

    withinGeoBox<T>(elements: T[], northEast: GeoPoint, sudWest: GeoPoint): { in: T[], out: T[] } {
        const inElements = [];
        const outElements = [];
        const northEastGeo = new GeoPoint(northEast.latitude, northEast.longitude);
        const sudWestGeo = new GeoPoint(sudWest.latitude, sudWest.longitude);
        const northWest = new GeoPoint(sudWest.latitude, northEast.longitude);
        const sudEast = new GeoPoint(northEast.latitude, sudWest.longitude);
        const geoBox = new Parse.Polygon([northWest, northEastGeo, sudEast, sudWestGeo]);
        elements.forEach(element => {
            const location = (element as any).location != null ? new Parse.GeoPoint((element as any).location.latitude, (element as any).location.longitude) : undefined;
            if (location != null && geoBox.containsPoint(location)) {
                inElements.push(element);
            } else {
                outElements.push(element);
            }
        });
        return {in: inElements, out: outElements};
    }

    getAngle(start: GeoPoint, end: GeoPoint): number {
        return start.radiansTo(end);
    }
}
