<form [formGroup]="form" style="margin: 8px;">
    <app-auto-complete-form
            [title]="'titleSetting'|translate"
            [items]="keysScaleColor"
            appearance="outline"
            formControlName="key"
            [hiddenClearButton]="false"
            (optionSelected)="onOptionSelected($event)"
    >
    </app-auto-complete-form>
</form>
<ng-container>
    <app-legend-key-color [data]="scaleColor"
                          titleSxColumn="{{'colorSettings'|translate}}"
                          titleDxColumn="{{'rappresentedElement'|translate}}"
    >
    </app-legend-key-color>
</ng-container>