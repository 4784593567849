import {AfterViewInit, Component, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Chart} from 'chart.js';
import 'chartjs-plugin-datalabels';
import 'chartjs-plugin-zoom';
import {isNotNullOrUndefined} from "../../models/Models";
import {Subject} from "rxjs";

@Component({
    selector: 'app-chart-js',
    templateUrl: './chart-js.component.html',
    styleUrls: ['./chart-js.component.scss']
})
export class ChartJsComponent implements OnChanges, OnInit, AfterViewInit {

    @Input() type;
    @Input() labels;
    @Input() datasets;
    @Input() options;
    @Input() plugins;
    @Input() isDialogComponent = false;
    @Input() maxDensityBarInChart: number;
    @Output() chartReady = new Subject<Chart>();


    @ViewChild('lineChart', {static: true}) private chartRef;
    private chart: Chart;

    constructor() {
    }

    ngOnInit() {

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.type != null && changes.type.previousValue != changes.type.currentValue) {
            const {type, labels, datasets, options, plugins} = this;
            this.chart = new Chart(this.chartRef.nativeElement, {
                type,
                data: {
                    labels,
                    datasets
                },
                options,
                plugins,
            });
            this.zoomModify();
            this.chartReady.next(this.chart);
        } else {
            let changed = false;
            Object.keys(changes).forEach(el => {
                if (!changes[el].firstChange) {
                    changed = true;
                    switch (el) {
                        case 'labels':
                        case 'datasets':
                            this.chart.data[el] = changes[el].currentValue;
                            break;
                        default:
                            this.chart[el] = changes[el].currentValue;
                            break;
                    }
                }
            });
            if (changed) {
                this.chart.update();
            }
            this.zoomModify();
        }
        if (this.type == 'doughnut') {
            if (changes.plugins != null) {
                const {type, labels, datasets, options, plugins} = this;
                setTimeout(() => {
                    this.chart = new Chart(this.chartRef.nativeElement, {
                        type,
                        data: {
                            labels,
                            datasets
                        },
                        options,
                        plugins,
                    });
                    this.chart.update();
                }, 100)
            }
        }
        // if (isNotNullOrUndefined(changes.showLegend)) {
        //     if (this.chart && this.chart.options && this.chart.options.legend && this.chart.options.legend.display) {
        //         this.chart.options.legend.display = this.showLegend;
        //     }
        // }


    }


    private enableDisableDataLabels() {
        if (isNotNullOrUndefined(this.maxDensityBarInChart)) {
            const width = this.chart.width;
            const numberLabels = this.chart.data.labels.length !== 0 ? this.chart.data.labels.length : 1;
            if (width / numberLabels < this.maxDensityBarInChart) {
                this.chart.options.plugins.datalabels.display = false;
                this.chart.update()
            } else {
                this.chart.options.plugins.datalabels.display = true;
                this.chart.update()
            }
        }
    }

    ngAfterViewInit(): void {
        this.enableDisableDataLabels();
    }

    zoomModify() {
        if (this.chart) {
            const xMax = this.chart['scales']['x-axis-0'].max;
            const xMin = this.chart['scales']['x-axis-0'].min;
            const yMax = this.chart['scales']['y-axis-0'].max;
            const yMin = this.chart['scales']['y-axis-0'].min;

            if (this.chart.options['pan']) {
                this.chart.options['pan'].rangeMax.x = xMax;
                this.chart.options['pan'].rangeMin.x = xMin;
                this.chart.options['pan'].rangeMax.y = yMax;
                this.chart.options['pan'].rangeMin.y = yMin;
                this.chart.options['zoom'].rangeMax.x = xMax;
                this.chart.options['zoom'].rangeMin.x = xMin;
                this.chart.options['zoom'].rangeMax.y = yMax;
                this.chart.options['zoom'].rangeMin.y = yMin;
            }
        }
    }


}
