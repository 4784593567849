import {Injectable} from '@angular/core';
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {ForErrorComponent} from "../../components/for-error/for-error.component";

@Injectable({
    providedIn: 'root'
})
export class ErrorsService {

    constructor(private _snackBar: MatSnackBar,
                private snackBar: MatSnackBar) {

    }

// i colori su trovano nel fyle src/style/scss/styless.scss
    public messagePopUpOk(message: string, action: string) {
        this._snackBar.open(message, action, {
            duration: 2000,
            horizontalPosition: "right",
            verticalPosition: "top",
            panelClass: ['snackbar-background']
        });
    }

    public messagePopUpError(message: string, action: string) {
        this._snackBar.open(message, action, {
            duration: 2000,
            horizontalPosition: "right",
            verticalPosition: "top",
            panelClass: ['snackbar-error']
        });
    }

    public messagePopUpWarning(message: string, action: string) {
        this._snackBar.open(message, action, {
            data: {"firstLine": "some data", "secondLine": "seocond data"},
            duration: 5000,
            horizontalPosition: "right",
            verticalPosition: "top",
            panelClass: ['snackbar-warning']
        });
    }


    // "error"|"warning"|"success" sono gli stile del scss //ritonrna una promise
    public openSnackBarcomponent(text: string[], title: string, azione: "error" | "warning" | "success") {
        return (this.snackBar.openFromComponent(ForErrorComponent, {
            data: {text, title, azione},
            duration: 5000,
            horizontalPosition: "right",
            verticalPosition: "top",
            panelClass: ['snackbar-background-' + azione]
        })).afterDismissed();
    }

}
