<mat-card>
    <mat-dialog-content>
        <div style="position: absolute;top: 55px;left: 0;z-index: 10000 ;" class="flex-center-width100"
             #pathContainerHtml>
            <div class="flex-around-center flex-flow-column shadow"
                 style="position: fixed;width: 350px;max-width: calc(100% - 50px);background: white">
                <div>
                    <h3>{{'fileManager.moveToFoleder'|translate}} {{(nameFolder|translateByPredicateFunction:traductionFunction|async)|convertValueByPredicateFunction:predicateFucntionToTransformStringTitle:getTextWidth:pathContainer}}</h3>
                </div>
                <div class="flex-start-center w100 flex-flow-row"
                >
                    <div style="margin: 10px"></div>
                    <ng-container *ngFor="let path of navigationPath; let first=first">
                        <mat-icon *ngIf="!first">keyboard_arrow_right</mat-icon>
                        <p class="truncate-text"
                           style="max-width: calc(100% - 100px)">{{path|translateByPredicateFunction:traductionFunction|async}}</p>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="flex-center-width100 flex-flow-column huna-class-selected-mouse-over" style="margin-top: 108px;">
            <div class="w100">
                <app-button-spinner [loading]="loading.createNewFolder" type="mat-icon-button">
                    <button mat-flat-button style="padding: 0!important;" (click)="createNewFolder()">
                        <div style="display: flex;align-items: center">
                            <mat-icon color="accent">create_new_folder</mat-icon>
                            <p style="margin: auto;margin-left:5px;font-weight: bold">{{'fileManager.createNew'|translate}}</p>
                        </div>
                    </button>
                </app-button-spinner>
            </div>
            <ng-container *ngIf="!loading.folder; else loadingFolder">
                <ng-container *ngFor="let folder of folders">
                    <div class="w100 selectable-item"
                         [ngClass]="{'selected':isSelectedFolder(folder)}"
                         style="display: flex;justify-content: space-between;padding: 5px"
                         (click)="selectFolder(folder)">
                        <div style="display: flex;">
                            <div style="margin: auto">
                                <mat-icon color="primary">folder_open</mat-icon>
                            </div>
                            <p style="margin: auto;margin-left:5px;font-weight: bold">{{folder.name|translateByPredicateFunction:traductionFunction|async}}</p>
                        </div>
                        <button mat-icon-button (click)="$event.stopPropagation();openFolder(folder)">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                        </button>
                    </div>
                </ng-container>
            </ng-container>
            <ng-template #loadingFolder>
                <mat-spinner style="margin-top: 100px"></mat-spinner>
            </ng-template>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <div class="flex-center-width100">
            <div class="flex-around-center" style="width: 350px;max-width: calc(100% - 50px)">
                <button [disabled]="disableComeBackButton"
                        (click)="comeBack()"
                        mat-flat-button color="accent" type="button">
                    <mat-icon>arrow_back</mat-icon>
                </button>
                <button (click)="closeDialog('close')" mat-flat-button color="primary" type="button">
                    <mat-icon>clear</mat-icon>
                </button>
                <button [disabled]="disableForwardButton"
                        (click)="closeDialog('move')"
                        mat-flat-button color="primary" type="button">
                    <mat-icon>forward</mat-icon>
                </button>
            </div>
        </div>
    </mat-dialog-actions>
</mat-card>

