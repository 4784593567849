import {Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';
import {UtilsService} from "../services/utils.service";

@Directive({
  selector: '[clickOutside]'
})
export class ClickOutsideDirective {

  @Output() clickOutside = new EventEmitter<void>();

  constructor(private eRef: ElementRef) {
  }

  @HostListener('document:click', ['$event'])
  onClickOut = (event) => {
    if (!this.eRef.nativeElement.contains(event.target) && this.checkIfElementIsWithinViewport(this.eRef.nativeElement)) {
      this.clickOutside.emit();
    }
  };

  private checkIfElementIsWithinViewport = (element) => {
    if ((element != undefined) ) {
      const element_offset = element.getBoundingClientRect();
      return (
          (element_offset.top >= 0) &&
          (element_offset.left >= 0) &&
          (element_offset.bottom <= window.innerHeight) &&
          (element_offset.right <= window.innerWidth)
      );
    } else {
      return false;
    }
  }
}