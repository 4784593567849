import {Injectable} from '@angular/core';
import * as Parse from 'parse';
import {PuntiLuceParse} from "../../models/PuntiLuce.Parse";
import {fromPromise} from "rxjs/internal-compatibility";
import {CircuitiParse} from "../../models/Circuiti.Parse";
import {Observable} from "rxjs";
import {ProjectService} from "./project.service";
import {ProgettiParse} from "../../models/Progetti.Parse";
import {paramsApiParse_v2} from "../../models/Models";

@Injectable({
    providedIn: 'root'
})
export class CircuitiService {

    constructor(private projectService: ProjectService) {
    }


    public getCurrentProjectMy() {
        try {
            return this.projectService.currentProject
        } catch (e) {
            throw e;
        }
    }

    public getTuttiCircuitiAppartentiAlProgetto(): Observable<CircuitiParse[]> {
        return fromPromise(
            Parse.Cloud.run('getCircuiti',
                {
                    progetto: this.getCurrentProjectMy().id,
                }, {})
        );
    }

    getCircuiti$(project: ProgettiParse | undefined = undefined, keys: string[] = undefined, constraints: string[] = undefined, values: any[] = undefined, schedeManutenzioneIds: string[] = undefined, documentFileId: string = undefined, idNonAccettabili: string[] = undefined, geoBox: {
        NE: Parse.GeoPoint,
        SW: Parse.GeoPoint
    } = undefined): Observable<CircuitiParse[]> {
        let projectId;
        if (project != null) {
            projectId = project.objectId
        } else {
            projectId = this.projectService.currentProject.objectId;
        }
        let NE, SW;
        if (geoBox != null) {
            SW = [geoBox.SW.latitude, geoBox.SW.longitude]
            NE = [geoBox.NE.latitude, geoBox.NE.longitude]
        }

        const params = paramsApiParse_v2({
            progetto: projectId,
            schedeManutenzioneIds,
            documentFileId,
            idNonAccettabili,
            SW,
            NE,
            keys,
            constraints,
            values,

        })
        const res = Parse.Cloud.run('getCircuiti_v2',
            params, {});
        return fromPromise(res)
    }
}
