import {Injectable, NgZone} from "@angular/core";
import {ClusterLuxDataParse} from "../../models/ClusterLuxData.Parse";
import {ScaleColorService} from "./scale-color.service";

const jsgraphs = require('js-graph-algorithms');

import MarkerClusterer, {ClusterIconInfo} from "@googlemaps/markerclustererplus";

const geohash = require('ngeohash');
import {LuxDataClusterService} from "./lux-data-cluster.service";
import {MatLegacySnackBar as MatSnackBar, MatLegacySnackBarConfig as MatSnackBarConfig} from "@angular/material/legacy-snack-bar";
import {Observable, } from "rxjs";
import {GoogleServiceService} from "./google-service.service";
import {isNotNullOrUndefined} from "../../models/Models";
import {MarkerClustererOptions} from "@angular/google-maps";

@Injectable({
    providedIn: 'root'
})
//Todo: quando il server avrà i dti, va tolto tutto il codice fittizio (segnato da //fittizio)


export class LuxDataMapElementService {


    map: google.maps.Map;
    markers: google.maps.Marker[] = [];
    markerClusterer: MarkerClusterer
    polylinesCluster: google.maps.Polyline[] = [];
    circleMarkers: google.maps.Marker[] = [];
    gridParts: google.maps.Rectangle[] = [];
    maxDisplayForGrid = 10240

    zoomTresholdGrid = 8; //primo livello di zoom che mostra griglia
    zoomTresholdGridToLine = 16; //fisso. Da questo zoom vengono mostrate le linee
    zoomTresholdLineToCircleMarker = 22; //Ultimo zoom che mostra linee

    luxDataClustersShowed: ClusterLuxDataParse[] = [] //lux data clusters mostrati

    constructor(private scaleColorService: ScaleColorService,
                private luxDataClusterService: LuxDataClusterService,
                private snackBar: MatSnackBar,
                private googleService: GoogleServiceService,
                private zone: NgZone) {
    }

    openUpdateData() {
        //riceve i dati, crea gli elementi corrispondenti e li mostra sulla mappa
        this.luxDataClusterService.subscritionLuxDataCluster = this.luxDataClusterService.luxDataClustersToShow.subscribe(
            clusters => {
                this.destroyAll(true)
                if (clusters.length == 0) {
                    return this.openSnackbarNodata();
                }
                this.createMapElementBasedOnZoom(clusters)
                this.luxDataClustersShowed = clusters
            }
        )
    }

    get currentType() {
        return this.luxDataClusterService.currentType;
    }


    /**
     * MARKERS: create and delete. Creato uno per ogni lux data clusters.
     * A partire da questi vengono creati i Marker Cluster, utilizzati per le linee e per i circle marker
     **/
    createMarkers(clusters: ClusterLuxDataParse[]): void {
        if (this.markerClusterer) {
            this.markerClusterer.clearMarkers()
        }
        for (let cluster of clusters) {
            let marker = new google.maps.Marker({
                position: ClusterLuxDataParse.getLatLngFromLocation(cluster),
                icon: {
                    url: '/assets/marker/blue-dot.png',
                    size: new google.maps.Size(1, 1)
                },
                visible: false,
                map: this.map
            });
            marker.set('lux', cluster.get('value'));
            this.markers.push(marker);
        }
        this.markerClusterer = new MarkerClusterer(this.map, this.markers, this.createMarkerClustererOptions(this.getGridSizeByZoom()));
        this.createMarkerClustererAndLineOrCircle();
    }

    deleteMarkers() {
        if (this.markerClusterer) {
            this.markerClusterer.clearMarkers();
        }
        this.markers.forEach(marker => {
            this.removeInfoWindowOnMapByMarker(marker);
            marker.setMap(null)
        });
        this.markers = [];
    }

    /**
     * @private
     * Crea il marker clusterer e in base allo zoom mostra le polyline o i circle marker
     */
    private createMarkerClustererAndLineOrCircle() {
        google.maps.event.addListenerOnce(this.markerClusterer, 'clusteringend', () => {
            //console.log("clusteringend,", this.markerClusterer.getTotalClusters())
            if (this.markerClusterer.getTotalClusters() === 0) {
                return false
            }
            const actualZoom = this.map.getZoom()
            if (actualZoom > this.zoomTresholdLineToCircleMarker) {
                this.createCircleMarkers();
            } else if (actualZoom >= this.zoomTresholdGridToLine) {
                this.createMSTCluster();
            } else {
                this.createMarkerWhenZoomIsLow()
            }

        });

    }

    /**
     * Opzioni per la creazione del marker cluster.
     * La funzione calculator assegna il titolo a un cluster con
     * scritto il valore medio di lux all'interno. Se il cluster ha un'icona, andando sopra col mouse mostra il titolo.
     * Il metodo getTitle restituisce un titolo undefined perchè è riferito a tutti i Marker Clusterer, anche se ognuno
     * dovrebbe avere il proprio. Se verrà data la possibilità di fare getTitle per ogni cluster,
     * si potrà richiamare getTitle al posto di getMeanLuxOfmarkersInCluster, velocizzando la creazione.
     * @param gridSize: più è piccola, più i Marker Cluster sono vicini
     */
    createMarkerClustererOptions(gridSize: number): MarkerClustererOptions {
        return {
            styles: [{
                textColor: '#000000',
                url: '/assets/cluster/cerchiob3.png',
                height: 0,
                width: 0,
                textSize: 0,
            }],
            zoomOnClick: false,
            minimumClusterSize: 1,
            averageCenter: true,
            ignoreHidden: false,
            maxZoom: 21,
            gridSize: gridSize,
            calculator(markers: google.maps.Marker[], numStyles: number): ClusterIconInfo {
                let index = 0;
                let count = markers.length;
                let sum = 0;
                for (let m of markers) {
                    sum += m.get("lux")//parseFloat(m.getTitle());
                }
                let average = sum / count;

                let textToShow = Math.floor(average).toString();
                return {
                    title: textToShow,
                    text: "",//textToShow,
                    index: numStyles
                };
            }
        };
    }

    getMiddlePoint(p1: google.maps.LatLng |
        google.maps.LatLngLiteral, p2: google.maps.LatLng |
        google.maps.LatLngLiteral): google.maps.LatLng {
        var bounds = new google.maps.LatLngBounds();
        bounds.extend(p1);
        bounds.extend(p2);
        return bounds.getCenter();
    }

    /**
     * Crea le polyline tra i cluster secondo l'algoritmo MST.
     * Visualizzati in zoom 17-18-19
     */
    createMSTCluster() {
        try {
            const luxDataCluster = this.luxDataClusterService.luxDataClustersInLocal;
            let mst = this.luxDataClusterService.mstInLocal;
            for (let i = 0; i < mst.length; ++i) {
                let e = mst[i];
                const distanceAndstroke = this.getDistanceToIgnoreAndStrokeWeight();
                if (e.weight > distanceAndstroke[0]) {
                    continue;
                }
                let v = e.v;
                let w = e.w;
                if (luxDataCluster[v] == null || luxDataCluster[v].location == null || luxDataCluster[w] == null || luxDataCluster[w].location == null) {
                    continue;
                }
                let mean = luxDataCluster[v].value;
                let meanEx = luxDataCluster[w].value;
                let color = this.scaleColorService.interpolateColorForClusters((mean + meanEx) / 2, this.currentType);
                //let color=this.colorScaleService.prendiColore((mean + meanEx) / 2);
                const line = new google.maps.Polyline({
                    path: [
                        new google.maps.LatLng(luxDataCluster[v].location.latitude, luxDataCluster[v].location.longitude),
                        new google.maps.LatLng(luxDataCluster[w].location.latitude, luxDataCluster[w].location.longitude)
                    ],
                    map: this.map,
                    strokeColor: color,
                    strokeWeight: distanceAndstroke[1],
                    strokeOpacity: 0.8,
                    visible: true,//this.visibilitaLine,
                    geodesic: true,
                    zIndex: 3
                });
                // const middlePoint = this.getMiddlePoint(clusters[v].getCenter(), clusters[w].getCenter());
                // this.addInfoWindowOnMapByMarker(line, middlePoint, Math.round(100 * (mean + meanEx) / 2) / 100, this.currentType);
                //console.log('(' + v + ', ' + w + '): ' + e.weight);
                this.polylinesCluster.push(line);
            }


        } catch (e) {
            console.log(e);
        }
    }

    destroyLineCluster() {
        for (let polyline of this.polylinesCluster) {
            this.removeInfoWindowOnMapByMarker(polyline);
            polyline.setMap(null);
        }
        this.polylinesCluster = [];
    }

    /**
     * Ritorna valore medio di lux in un cluster

     */
    getMeanLuxOfMarkersInCLuster(markers: google.maps.Marker[]) {
        let sum = 0;
        for (let marker of markers) {
            sum += parseFloat(marker.get('lux'));
        }
        return sum / markers.length;
    }


    /**
     * In base allo zoom, definisce la distanza per cui due punti non vengono uniti nell'MST.
     * Questo per evitare linee tra due punti lontani che non avrebbero senso.
     * Ritorna anche lo spessore della polyline
     */
    getDistanceToIgnoreAndStrokeWeight() {
        switch (this.map.getZoom()) {
            case 22:
            case 21 :
                return [30, 15];
            case 20 :
                return [30, 15];
            case 19 :
                return [30, 12];
            case 18 :
                return [45, 10];
            case 17:
                return [80, 10];
            case 16:
                return [90, 8];
        }
    }

    /**
     * Definisce la grid size per il Marker Cluster in base allo zoom
     */
    getGridSizeByZoom() {
        //return this.clusterGridSize
        switch (this.map.getZoom()) {
            case 22:
            case 21:
                return 60;//55
            case 20:
                return 70;//55
            case 19:
                return 35;
            case 18 :
                return 20;
            case 17:
                return 18;
            case 16:
                return 10;
            default:
                return 70;
        }
    }

    /**
     * Crea marker circolari. Viene creato un circle marker per ogni cluster del Marker Clusterer.
     * Visualizzati dallo zoom 20
     */
    createCircleMarkers() {
        for (let cluster of this.markerClusterer.getClusters()) {
            const lux = this.getMeanLuxOfMarkersInCLuster(cluster.getMarkers());

            let marker = new google.maps.Marker({
                icon: {
                    path: google.maps.SymbolPath.CIRCLE,
                    fillOpacity: 0.8,
                    fillColor: this.scaleColorService.interpolateColorForClusters(lux, this.currentType),
                    strokeOpacity: 1,
                    strokeColor: '#ffffff',
                    strokeWeight: 0.5,
                    scale: 15
                },
                position: cluster.getCenter(),//ClusterLuxDataParse.getLatLngFromLocation(cluster),
                visible: true,//this.visibilitaCircle,
                map: this.map,
            });
            marker.set('lux', lux);
            this.addInfoWindowOnMapByMarker(marker, marker.getPosition(), Math.round(lux * 100) / 100, this.currentType)
            this.circleMarkers.push(marker);

        }
        console.log("circle creati:", this.circleMarkers.length)
    }

    deleteCircleMarkers() {
        this.circleMarkers.forEach(marker => {
            this.removeInfoWindowOnMapByMarker(marker);
            marker.setMap(null)
        });
        this.circleMarkers = [];

    }

    /**
     * Quando lo zoom è minore di 8, mostra un marker nella posizione di ogni Marker Cluster
     * @private
     */
    private createMarkerWhenZoomIsLow() {
        for (let cluster of this.markerClusterer.getClusters()) {
            let marker = new google.maps.Marker({
                position: cluster.getCenter(),
                icon: {
                    url: '/assets/marker/red-dot.png',
                },
                visible: true,
                map: this.map
            });
            const meanLux = this.getMeanLuxOfMarkersInCLuster(cluster.getMarkers());
            this.addInfoWindowOnMapByMarker(marker, marker.getPosition(), Math.round(meanLux * 100) / 100, this.currentType);
            this.markers.push(marker);
        }
    }

    /**
     * Elimina tutti gli elementi dalla mappa.
     * @param newDataToShow: true: indica che saranno mostrati nuovi dati (es. quando riceve valore nella subscribe)
     *        false: i dati mostrati saranno gli stessi, è cambiata la variabile di rappresentazione. (es. quando cambia
     *        zoomTresholdLineToCircle in setting.component. Se non si vuole far scegliere all'utente questa variabile,
     *        si può anche eliminare questo parametro boolean)
     */
    destroyAll(newDataToShow: boolean) {
        //console.log("destroy all")
        this.markers.length > 0 ? this.deleteMarkers() : null;
        this.polylinesCluster.length > 0 ? this.destroyLineCluster() : null;
        this.circleMarkers.length > 0 ? this.deleteCircleMarkers() : null;
        this.gridParts.length > 0 ? this.destroyGrid() : null

        newDataToShow ? this.luxDataClustersShowed = [] : null


    }

    private createInfoWindow(center: google.maps.LatLng | google.maps.LatLngLiteral, value: number, unit) {
        const contentString = (value, unitMeasure) => '<h5 style="text-align: center">' + value + ' ' + unitMeasure + '</h5>'
        return new google.maps.InfoWindow({
            content: '<div style="position: absolute; top: 0px; right: 0px; width: 20px; height: 20px; background-color: white; z-index: 2;"></div>' + contentString(value, unit),
            position: center,
        });
    }

    private addInfoWindowOnMapByMarker(marker, center: google.maps.LatLng | google.maps.LatLngLiteral, value: number, type: string) {
        const infowindow = this.createInfoWindow(center, value, type)
        marker.set('hunaInfoWindow', infowindow);
        let listeners = []
        let listener = marker.addListener("mouseover", () => {
            infowindow.open(marker.getMap());
        })
        listeners.push(listener);
        listener = marker.addListener("mouseout", () => {
            infowindow.close();
        })
        listeners.push(listener);
        marker.set('hunaListeners', listeners);
    }

    private removeInfoWindowOnMapByMarker(marker) {
        const infoWindow = marker.get('hunaInfoWindow');
        if (isNotNullOrUndefined(infoWindow)) {
            infoWindow.close()
        }
        const listener = marker.get('hunaListeners')
        if (Array.isArray(listener)) {
            listener.forEach(listener => google.maps.event.removeListener(listener))
        }
    }

    /**
     * Divide la mappa in una griglia basata sul geohash. Se si ha un cluster con l'hash corrispondente a quella cella,
     * chiama drawBox() per disegnarlo.
     * Visualizzati quando zoom <=16 e fino allo zoom 7
     */
    drawGrid() {
        const level = this.luxDataClusterService.getGeohashPrecisionByZoom(this.map.getZoom());
        let bounds = this.map.getBounds(),
            ne = bounds.getNorthEast(),
            sw = bounds.getSouthWest(),
            neHash = geohash.encode(ne.lat(), ne.lng(), level),
            nwHash = geohash.encode(ne.lat(), sw.lng(), level),
            swHash = geohash.encode(sw.lat(), sw.lng(), level),
            seHash = geohash.encode(sw.lat(), ne.lng(), level),

            currentHash = neHash,
            eastBound = neHash,
            westBound = nwHash,
            maxHash = this.maxDisplayForGrid;

        this.destroyGrid();
        while (maxHash > 0) {
            if (this.luxDataClusterService.sortedMapHashColor.has(currentHash)) {
                this.drawBox(currentHash)
            }
            do {
                currentHash = geohash.neighbor(currentHash, [0, -1]); //west
                if (this.luxDataClusterService.sortedMapHashColor.has(currentHash)) {
                    this.drawBox(currentHash)
                }
            } while (maxHash-- > 0 && currentHash != westBound);
            if (currentHash == swHash) {
                return;
            }
            westBound = geohash.neighbor(currentHash, [-1, 0]); //south
            currentHash = eastBound = geohash.neighbor(eastBound, [-1, 0]); //south
            maxHash--
        }

        //questo log non dovrebbe scattare perchè se è andato tutto bene esce col return
        console.log('defaults.maxDisplay limit reached');
        this.destroyGrid();
    }

    private unitMeasure = {
        LUX: 'lx',
        "AIR_PM2.5": 'μg/m' + '<sup>3</sup></p>',
        "AIR_PM10": 'μg/m' + '<sup>3</sup></p>'
    }

    /**
     * Disegna il singolo rettangolo della griglia e gli assegna un colore.
     * @param hash: hash del cluster e della cella
     */
    drawBox(hash) {
        const bounds = geohash.decode_bbox(hash);
        const gb = new google.maps.LatLngBounds(new google.maps.LatLng(bounds[0], bounds[1]), new google.maps.LatLng(bounds[2], bounds[3]));
        const hashData = this.luxDataClusterService.sortedMapHashColor.get(hash);
        let rect = new google.maps.Rectangle({
            map: this.map,
            bounds: gb,
            strokeColor: '#2d2d2d',
            strokeOpacity: 1,
            strokeWeight: 0.8,
            fillColor: hashData.color,
            fillOpacity: 0.5
        });
        this.addInfoWindowOnMapByMarker(rect, rect.getBounds().getCenter(), Math.round(hashData.value * 100) / 100, this.unitMeasure[hashData.type]);
        this.gridParts.push(rect);
    }

    destroyGrid() {
        for (let i = 0; i < this.gridParts.length; i++) {
            this.removeInfoWindowOnMapByMarker(this.gridParts[i]);
            this.gridParts[i].setMap(null);
        }
        this.gridParts = [];
    }


    /**
     * Snackbar aperto quando non ci sono dati da mostrare (es. area o mese senza rilevazioni)
     * @private
     */
    private openSnackbarNodata() {
        const config = new MatSnackBarConfig();
        config.duration = 1500;
        config.verticalPosition = 'top';
        config.horizontalPosition = 'center';
        config.panelClass = ['snackbar'];
        this.zone.run(() => {
            this.snackBar.open('No data to show', '', config);
        });

    }

    /**
     * Restituisce tutti i cluster di precisione 9 (massima) nei bounds della mappa visulizzati.
     * Questi saranno salvati nel file scaricato.
     */
    getAllLuxDataClusterForFile() {
        return this.luxDataClusterService.getLuxDataClusterMaxPrecisionInBounds(this.map.getBounds())
    }

    /**
     * Aggiorna la soglia dopo il quale non vengono più visualizzate le polyline, ma i circle markers.
     * @param value
     */
    updateZoomTresholdLineToCircle(value: number) {
        this.zoomTresholdLineToCircleMarker = value;
        this.destroyAll(false)
        this.createMapElementBasedOnZoom(this.luxDataClustersShowed)

    }

    /**
     * In base allo zoom, crea i markers (dai quali saranno creati polyline o circle) oppure la griglia geohash.
     * @param clusters
     * @private
     */
    private createMapElementBasedOnZoom(clusters: ClusterLuxDataParse[]) {
        const actualZoom = this.map.getZoom()
        if ((actualZoom >= this.zoomTresholdGridToLine) || actualZoom < this.zoomTresholdGrid) {
            this.createMarkers(clusters)
        } else {
            this.drawGrid()
        }

    }


    public getForPrintLuxData(): Observable<ClusterLuxDataParse[]> {
        return this.luxDataClusterService.getLuxDataClusterForPrint(this.map.getBounds())
    }

}


