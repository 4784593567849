import {Component, Inject, OnInit} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {PlansService} from '../../../providers/services/plans.service';
import {LoaderService} from '../../../providers/services/loader.service';
import {AlertService} from '../../../providers/services/alert.service';
import {Router} from '@angular/router';
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-info-save-dialog',
    templateUrl: './info-save-dialog.component.html'
})
export class InfoSaveDialogComponent implements OnInit {


    constructor(
        public dialogRef: MatDialogRef<InfoSaveDialogComponent>,
        private plansService: PlansService,
        @Inject(MAT_DIALOG_DATA) public data: { planObjectId: string, userSession: string },
        private loaderService: LoaderService,
        private alertServices: AlertService,
        private router: Router,
        private translate: TranslateService
    ) {

    }

    ngOnInit() {

    }

    public async closePlan() {
        const loaderId = this.loaderService.addLoader();
        const resp = await this.plansService.closePlan(this.data.planObjectId, this.data.userSession);
        if (resp.status === 200 || resp.status === 204) {
            this.dialogRef.close(true);
            this.alertServices.success(this.translate.instant('success'));
            this.router.navigate(['/planimetrie/modifica/' + this.data.planObjectId]);
        } else {
            this.dialogRef.close();
            this.alertServices.error(this.translate.instant('error'));

        }
        this.loaderService.removeLoader(loaderId);
    }


}
