<a (click)="chooseOpen()" style="cursor: pointer" >
    <mat-card [ngClass]="{'circuit-card-old':isOldDate}" class="circuit-card">
        <div class="circuit-content">
            <span class="font-weight-600" style="margin-bottom: 0.6rem">{{onTime}}&nbsp;-&nbsp;{{offTime}}</span>
            <span class="font-weight-600" style="font-size: 20px">{{ultimaPotenza | powerFormat}}</span>
            <span class="time">
                <ng-container
                        *ngIf="ultimaData!=undefined && ultimaData!=null">{{ultimaData |customDate :'medium'|async}}</ng-container>
            </span>
            <!--            <div style="display: flex; flex-direction: row; align-items: center; justify-content: space-between;">-->
            <div class="control-icons" style="margin-top: 0.3rem;">
                <div class="control-icons-item">
                    <div [ngStyle]="{'background-color': statoGuasto ? 'green' : 'red'}"
                         class="circuit-state control-icons-item"></div>
                </div>
                <div class="control-icons-item" *ngIf="!statoAnalizzatore">
                    <img height="35" style="width: auto;" src="assets/remotes-icons/comunicazioneAnalizzatore.png">
                </div>
                <div class="control-icons-item" *ngIf="!statoPresenzaRete">
                    <img class="control-icons-item" height="35" src="assets/remotes-icons/presenza_rete.png"
                         style="width: auto;">
                </div>
            </div>
            <div class="control-icons">
                <div class="control-icons-item">
                    <ng-container [ngSwitch]="statoAccensione">
                        <img *ngSwitchCase="0" height="35" src="assets/remotes-icons/Lampada_OFF.png"
                             style="width: auto;">
                        <img *ngSwitchCase="1" height="35" src="assets/remotes-icons/Lampada_ON.png"
                             style="width: auto;">
                        <img *ngSwitchCase="2" height="35" src="assets/remotes-icons/Lampada_parziale.png"
                             style="width: auto;">
                    </ng-container>
                </div>
                <div class="control-icons-item" *ngIf="accensioneConOrologio">
                    <img height="35" style="width: auto;" src="assets/remotes-icons/Timer_icon_v.png">
                </div>
                <div class="control-icons-item" *ngIf="!statoCrepuscolare">
                    <img height="35" style="width: auto;" src="assets/remotes-icons/crepuscolare.png">
                </div>
                <!--                <img height="35"-->
                <!--                     src="https://cdn0.iconfinder.com/data/icons/complete-electronic-equipment/24/25-512.png">-->
            </div>
        </div>
        <ng-content select="[bottom-card]">

        </ng-content>
    </mat-card>
</a>