import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges, TemplateRef,
    ViewChild
} from '@angular/core';
import { SchedaManutenzioneParse } from "../../../models/SchedaManutenzione.Parse";
import {
    arrayIsSet, className, isNotNullOrUndefined,
} from "../../../models/Models";
import { fromPromise } from "rxjs/internal-compatibility";
import { combineLatest, EMPTY, Observable, of, Subscription } from "rxjs";
import {
    MaintenanceService,
    typeFormFieldMaintenance
} from "../../../providers/services/maintenance.service";
import { AlertService } from "../../../providers/services/alert.service";
import { TranslateService } from "@ngx-translate/core";
import { ImageService } from "../../../providers/services/image.service";
import { UserRoleNumber } from "../../../../config/static-data";
import { SegnalazioniParse } from "../../../models/Segnalazioni.Parse";
import { MatLegacyTabGroup as MatTabGroup } from "@angular/material/legacy-tabs";
import { DialogPopUpInfoService } from "../../../providers/services/dialog-pop-up-info.service";
import { CalendarioManutenzioniParse } from "../../../models/CalendarioManutenzioni.Parse";
import { SchedaManutenzioneCompilataParse } from "../../../models/SchedaManutenzioneCompilata.Parse";
import { LocalSotrageService } from "../../../providers/services/local-sotrage.service";
import { map, switchMap } from "rxjs/operators";
import {  MatExpansionPanel } from '@angular/material/expansion';


@Component({
    selector: 'app-form-schedule-maintenance',
    templateUrl: './form-schedule-maintenance.component.html',
    styleUrls: ['./form-schedule-maintenance.component.scss']
})
export class FormScheduleMaintenanceComponent implements OnInit, OnChanges, OnDestroy {

    @ViewChild('tabSidenav', { static: false }) tabSidenav: MatTabGroup;
    @ViewChild('schedeManutenzioneCompiledPanel') schedeManutenzioneCompiledPanel: MatExpansionPanel;
    @ViewChild('schedeManutenzioneExpiredPanel') schedeManutenzioneExpiredPanel: MatExpansionPanel;
    @ViewChild('schedeManutenzioneExpiringPanel') schedeManutenzioneExpiringPanel: MatExpansionPanel;
    @ViewChild('schedeManutenzioneNextPanel') schedeManutenzioneNextPanel: MatExpansionPanel;

    @Input() elemento;
    @Input() currentUser;
    @Input() sidenavIsOpen;
    @Input() savedCompleted: Observable<any>;

    @Input() clusterCalendariManutenzioneSelezionato: any[];
    schedeManutenzioneSuggeriteId: string[];
    schedeManutenzioneSuggerite: SchedaManutenzioneParse[];
    @Input() segnalazioniAperteOnMap: SegnalazioniParse[];
    segnalazioniAperte: SegnalazioniParse[];
    @Input() abbonamentiAttivi: { gestioneAttivo } = { gestioneAttivo: false };
    @Input() myRole: UserRoleNumber;
    @Input() cssPositionSaveButton: 'fab' | 'centerPositionButtonSave' = 'fab';
    @Input() calendariByElement: CalendarioManutenzioniParse[] | undefined;
    @Input() schedeManutenzioneCompiled: SchedaManutenzioneCompilataParse[];
    @Output() savedValue = new EventEmitter();
    @Output() takeCharge = new EventEmitter();
    @Output() generateReport = new EventEmitter();
    @Output() reopenReport = new EventEmitter();
    @Output() closeReport = new EventEmitter();
    @Output() assignTo = new EventEmitter();
    @Output() sendComment = new EventEmitter();
    @Output() getCalendariByElement = new EventEmitter<{ elementParse: any, idsDisplayed: string[] }>();


    private subscriptionSavedCompleted: Subscription | undefined;

    public schedaSelezionata: SchedaManutenzioneParse;
    typeFormField = typeFormFieldMaintenance;
    public loading = {
        schedeManutenzione: false,
        saveScheda: false,
        uploadTemporaryFile: false
    };
    public loadingSaveReport = this.resetValueLoadingSaveReport();
    segnalazioneSelezionata: SegnalazioniParse | undefined;
    public compiledBy: { [k: string]: any } = {};
    public rejectedBy: { [k: string]: any } = {};

    schedeManutenzioneExpired: SchedaManutenzioneParse[] | undefined;
    schedeManutenzioneExpiring: SchedaManutenzioneParse[] | undefined;
    schedeManutenzioneNext: SchedaManutenzioneParse[] | undefined;
    isSetSchedeManutenzioneExpired: boolean;
    isSetSchedeManutenzioneExpiring: boolean;
    isSetSchedeManutenzioneNext: boolean;
    isSetSchedeManutenzioneCompiled: boolean;
    openCompiled = false;
    public resetValueLoadingSaveReport() {
        return {
            generateReport: false,
            closeReport: false,
            assignTo: false,
            takeCharge: false,
            searchIntervention: false,
            sendComment: false
        };
    }

    public numberDayConsiderNext = 15;
    public detailsScheda = {};

    constructor(
        private alertService: AlertService,
        public translate: TranslateService,
        private imageService: ImageService,
        private maintenanceService: MaintenanceService,
        private localStorageService: LocalSotrageService,
        private dialogInfo: DialogPopUpInfoService,
    ) {
    }


    get abbonamentiRoleIsAttivi() {
        return this.myRole >= UserRoleNumber.INSTALLATORE && this.abbonamentiAttivi.gestioneAttivo;
    }

    get errors(): { title: string, value: string }[] {
        const errors = [
            {
                title: 'errors.currentRoleAccesibility',
                value: 'errors.' + (this.myRole >= UserRoleNumber.INSTALLATORE)
            },
            {
                title: 'errors.activeCurrentSubscription',
                value: 'errors.' + this.abbonamentiAttivi.gestioneAttivo
            }
        ];
        return errors;
    }

    getDetail(schedaManutenzioneParse: SchedaManutenzioneParse) {
        return isNotNullOrUndefined(this.detailsScheda) && this.detailsScheda[schedaManutenzioneParse.objectId] ? this.detailsScheda[schedaManutenzioneParse.objectId] : null;
    }


    ngOnInit(): void {
    }

    private closePanels() {
        this.schedeManutenzioneCompiledPanel?.close();
        this.schedeManutenzioneExpiredPanel?.close();
        this.schedeManutenzioneExpiringPanel?.close();
        this.schedeManutenzioneNextPanel?.close();
    }

    private loadData() {
        this.schedeManutenzioneExpired = undefined;
        this.schedaSelezionata = undefined;
        this.detailsScheda = undefined;
        this.resetScehdeManutenzione();
        this.loading.schedeManutenzione = true;
        if (arrayIsSet(this.clusterCalendariManutenzioneSelezionato)) {
            this.closePanels();
            const calendariVisualizzati = [];
            this.clusterCalendariManutenzioneSelezionato.forEach(calendari => {
                if (arrayIsSet(calendari.calendariManutenzione)) {
                    calendari.calendariManutenzione.forEach(calendario => {
                        const elementParse = (this.elemento.className === className.puntiLuce) ? calendario.puntoLuce : calendario.circuito;
                        if (elementParse != null && elementParse.objectId === this.elemento.objectId) {
                            calendariVisualizzati.push(calendario);
                        }
                    });
                }
            });
            const schedeDaVisualizzare = calendariVisualizzati.map(calendario => calendario.schedaManutenzione);
            this.fechingSchedeManutenzione(schedeDaVisualizzare).subscribe(schedeManutenzione => {
                if (arrayIsSet(schedeManutenzione)) {
                    schedeManutenzione.forEach(scheda => {
                        scheda.form = scheda.form.sort((a, b) => {
                            if (a.sortingNumber != null && b.sortingNumber != null) {
                                return a.sortingNumber - b.sortingNumber;
                            } else if (a.required === b.required) {
                                return a.traduction.localeCompare(b.traduction);
                            } else if (b.required) {
                                return 1;
                            } else {
                                return -1;
                            }
                        });
                    });
                    this.getCalendariByElement.next({
                        elementParse: this.elemento,
                        idsDisplayed: calendariVisualizzati.map(calendario => calendario.objectId)
                    });
                    this.categorizeSchedeManutenzione(calendariVisualizzati);
                } else {
                    this.getCalendariByElement.next({
                        elementParse: this.elemento,
                        idsDisplayed: undefined
                    });
                    this.schedeManutenzioneExpired = undefined;
                }
                this.loading.schedeManutenzione = false;
            }, error => {
                this.loading.schedeManutenzione = false;
                this.alertService.error(error);
            });

        } else {
            this.schedeManutenzioneExpired = undefined;
            this.isSetSchedeManutenzioneExpired = false;
            this.schedaSelezionata = undefined;
            this.getCalendariByElement.next({ elementParse: this.elemento, idsDisplayed: undefined });
        }
    }

    private categorizeSchedeManutenzione(calendari: CalendarioManutenzioniParse[]) {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrow.setHours(0, 0, 0, 0);
        let idSchede = [];
        const expired = this.getScheduleMaintenanceExpired(calendari, tomorrow);
        if (arrayIsSet(expired)) {
            if (arrayIsSet(this.schedeManutenzioneExpired)) {
                this.schedeManutenzioneExpired = this.schedeManutenzioneExpired.concat(expired);
            } else {
                this.schedeManutenzioneExpired = expired;
            }
            idSchede = idSchede.concat(this.schedeManutenzioneExpired.map(scheda => scheda.objectId));
        }
        const expiring = this.getScheduleMaintenanceExpiring(calendari, tomorrow);
        if (arrayIsSet(expiring)) {
            this.schedeManutenzioneExpiring = expiring;
            idSchede = idSchede.concat(this.schedeManutenzioneExpiring.map(scheda => scheda.objectId));
        }

        const next = this.getScheduleMaintenanceNext(calendari, tomorrow);
        if (arrayIsSet(next)) {
            this.schedeManutenzioneNext = next;
            idSchede = idSchede.concat(this.schedeManutenzioneNext.map(scheda => scheda.objectId));
        }

        if (arrayIsSet(idSchede)) {
            this.maintenanceService.getInfoForSchedaManutenzione(idSchede, this.elemento.objectId, this.elemento.className)
                .subscribe(
                    val => {
                        this.detailsScheda = this.getDetailsScheda(val, this.detailsScheda);
                        this.isSetSchedeManutenzioneExpired = arrayIsSet(this.schedeManutenzioneExpired);
                        this.isSetSchedeManutenzioneExpiring = arrayIsSet(this.schedeManutenzioneExpiring);
                        this.isSetSchedeManutenzioneNext = arrayIsSet(this.schedeManutenzioneNext);
                    }, error => {
                        this.resetScehdeManutenzione();
                        this.alertService.error(error);
                    }
                );
        }
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (!this.subscriptionSavedCompleted && this.savedCompleted) {
            this.subscriptionSavedCompleted = this.savedCompleted.subscribe(
                value => {
                    if (value.schedaManutenzione) {
                        this.loading.saveScheda = false;
                        this.openCompiled = true;
                        this.loadData();
                    }
                    if (value.saveReport) {
                        setTimeout(() => {
                            const ariaLabeleds = this.tabSidenav._allTabs.map(item => {
                                return item.ariaLabelledby;
                            });
                            const index = ariaLabeleds.findIndex(val => val === 'reports');
                            if (index >= 0) {
                                this.tabSidenav.selectedIndex = index;
                            }
                        }, 200);
                        this.loadingSaveReport[value.saveReport.key] = !value.saveReport.value;
                        this.loadingSaveReport = {...this.loadingSaveReport}
                    }
                }
            );
        }
        if (changes.elemento || changes.clusterCalendariManutenzioneSelezionato) {
            this.loadData();

            const segnalazionePrecedente = this.segnalazioneSelezionata;
            this.segnalazioneSelezionata = undefined;
            setTimeout(() => {
                const segnalazioni = this.getSegnalazioniAperte(this.segnalazioniAperteOnMap, this.elemento.objectId, segnalazionePrecedente);
                this.segnalazioneSelezionata = segnalazioni.segnalazioneSelezionata;
                this.segnalazioniAperte = segnalazioni.segnalazioniAperte;
                setTimeout(() => {
                    if (this.iSetSegnalazioni) {
                        const ariaLabeleds = this.tabSidenav._allTabs.map(item => {
                            return item.ariaLabelledby;
                        });
                        const index = ariaLabeleds.findIndex(val => val === 'reports');
                        if (index >= 0) {
                            this.tabSidenav.selectedIndex = index;
                        }
                    }
                }, 20);
            });
        }

        if (isNotNullOrUndefined(changes.segnalazioniAperteOnMap) || isNotNullOrUndefined(changes.elemento)) {
            const segnalazionePrecedente = this.segnalazioneSelezionata;
            this.segnalazioneSelezionata = undefined;
            this.segnalazioniAperte = undefined;
            setTimeout(() => {
                const segnalazioni = this.getSegnalazioniAperte(this.segnalazioniAperteOnMap, this.elemento.objectId, segnalazionePrecedente);
                this.segnalazioneSelezionata = segnalazioni.segnalazioneSelezionata;
                this.segnalazioniAperte = segnalazioni.segnalazioniAperte;
            });
        }

        if (changes.calendariByElement != null) {
            if (arrayIsSet(this.calendariByElement)) {
                this.categorizeSchedeManutenzione(this.calendariByElement);
            } else {
                this.resetScehdeManutenzione();
            }

        }

        if (changes.schedeManutenzioneCompiled) {
            this.isSetSchedeManutenzioneCompiled = arrayIsSet(this.schedeManutenzioneCompiled);
            if (this.isSetSchedeManutenzioneCompiled) {
                const keys = ['nome', 'cognome'];
                this.schedeManutenzioneCompiled
                    .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
                    .forEach(schedaCompilata => {
                        if (schedaCompilata.compiledBy) {
                            this.compiledBy[schedaCompilata.objectId] = {};
                            const user = schedaCompilata.compiledBy;
                            keys.forEach(key => {
                                this.compiledBy[schedaCompilata.objectId][key] = user.get(key);
                            });
                        }
                        if (schedaCompilata.rejectedBy) {
                            this.rejectedBy[schedaCompilata.objectId] = {};
                            const user = schedaCompilata.rejectedBy;
                            keys.forEach(key => {
                                this.rejectedBy[schedaCompilata.objectId][key] = user.get(key);
                            });
                        }
                    });
                if (this.openCompiled) {
                    this.schedeManutenzioneCompiledPanel.open();
                    this.openCompiled = false;
                }
            } else {
                this.openCompiled = false;
            }
        }

    }

    resetScehdeManutenzione() {
        this.schedeManutenzioneExpiring = undefined;
        this.schedeManutenzioneNext = undefined;
        this.isSetSchedeManutenzioneExpired = arrayIsSet(this.schedeManutenzioneExpired);
        this.isSetSchedeManutenzioneExpiring = false;
        this.isSetSchedeManutenzioneNext = false;
        this.isSetSchedeManutenzioneCompiled = false;
    }


    getSegnalazioniAperte(segnalazioniAperteOnMap: SegnalazioniParse[], elementId: string, segnalazionePrecedente): { segnalazioniAperte: SegnalazioniParse[], segnalazioneSelezionata: SegnalazioniParse } {
        let segnalazioniAperte;
        let segnalazioneSelezionata;
        if (arrayIsSet(segnalazioniAperteOnMap)) {
            segnalazioniAperte = segnalazioniAperteOnMap.filter(segnalazione => {
                if (this.elemento.className === className.puntiLuce) {
                    return arrayIsSet(segnalazione.puntiLuce) && segnalazione.puntiLuce.findIndex(lightPoint => lightPoint.objectId === this.elemento.objectId) >= 0;
                } else if (this.elemento.className === className.circuiti) {
                    return isNotNullOrUndefined(segnalazione.circuito) && segnalazione.circuito.objectId === this.elemento.objectId;
                } else {
                    return false;
                }
            });

            if (arrayIsSet(segnalazioniAperte)) {
                const index = segnalazioniAperte.findIndex(segnalazione => {
                    if (isNotNullOrUndefined(segnalazionePrecedente)) {
                        return segnalazione.objectId === segnalazionePrecedente.objectId;
                    } else {
                        return true;
                    }
                });
                segnalazioneSelezionata = segnalazioniAperte[index];
            }
        }
        return { segnalazioniAperte, segnalazioneSelezionata };
    }

    ngOnDestroy(): void {
        if (this.subscriptionSavedCompleted) {
            this.subscriptionSavedCompleted.unsubscribe();
            this.subscriptionSavedCompleted = undefined;
        }
    }


    getDetailsScheda(val, currentValue: any | undefined): any {
        const obj: { [k: string]: { [k: string]: any } } = {};
        Object.keys(val).forEach(idScheda => {
            obj[idScheda] = { compiledBy: {} };
            Object.keys(val[idScheda]).forEach(subKey => {
                if (subKey === 'doneBy') {
                    if (isNotNullOrUndefined(val[idScheda][subKey])) {
                        obj[idScheda].compiledBy = {
                            nome: val[idScheda][subKey].get('nome'),
                            cognome: val[idScheda][subKey].get('cognome')
                        };
                    } else {
                        obj[idScheda].compiledBy = {};
                    }
                } else {
                    if (isNotNullOrUndefined(val[idScheda][subKey])) {
                        obj[idScheda][subKey] = val[idScheda][subKey];
                    }
                }
            });
        }
        );
        if (currentValue !== undefined) {
            return { ...obj, ...currentValue };
        } else {
            return obj;
        }
    }

    fechingSchedeManutenzione(schedeManutenzione: SchedaManutenzioneParse[]): Observable<SchedaManutenzioneParse[]> {
        if (arrayIsSet(schedeManutenzione)) {
            const schedeObs: Observable<SchedaManutenzioneParse>[] = [];
            schedeManutenzione.forEach(scheda => schedeObs.push(fromPromise(scheda.fetch())))
            return combineLatest(schedeObs);
        } else {
            return of([]);
        }
    }


    isSelected(schedaManutenzione: SchedaManutenzioneParse) {
        return isNotNullOrUndefined(this.schedaSelezionata) && this.schedaSelezionata.objectId === schedaManutenzione.objectId;
    }

    isSuggerita(schedaManutenzione: SchedaManutenzioneParse): boolean {
        return arrayIsSet(this.schedeManutenzioneSuggeriteId) && this.schedeManutenzioneSuggeriteId.includes(schedaManutenzione.objectId);
    }

    isDisabled(schedaManutenzione: SchedaManutenzioneParse) {
        return this.loading.saveScheda && this.isSelected(schedaManutenzione) || this.loading.uploadTemporaryFile;
    }


    clickBox(schedaManutenzione: SchedaManutenzioneParse) {
        if (!this.isDisabled(schedaManutenzione)) {
            this.loading.uploadTemporaryFile = true;
            this.schedaSelezionata = schedaManutenzione;
            const referenceElement = { className: this.elemento.className, objectId: this.elemento.objectId }
            this.maintenanceService.getLocalSchedaPrecompilata$(schedaManutenzione.objectId, referenceElement).pipe(map(scheda => {
                const obj = {
                    schedaSelezionata: schedaManutenzione,
                    cssPositionSaveButton: 'notShow',
                }
                if (scheda != null && arrayIsSet(scheda.valuesForm)) {
                    obj['valuesForm'] = scheda.valuesForm;
                }
                return obj;
            }),
                switchMap((obj) => this.dialogInfo.openFormScheduleMaintenance(obj)),
                switchMap((val) => {
                    if (val != null) {
                        let localEvent;
                        localEvent = {
                            ...val,
                            elementToSave: referenceElement
                        }
                        return this.maintenanceService.saveLocalObject(localEvent).pipe(
                            map((valueFormWithTemporaryFile) => {
                                //substitution the temporary file at image in base 64 before the save
                                valueFormWithTemporaryFile.valuesForm.forEach(valWithImage => {
                                    if (valWithImage.type === typeFormFieldMaintenance.image) {
                                        if (val.valuesSchedaManutenzione[valWithImage.formControlName].file != null) {
                                            val.valuesSchedaManutenzione[valWithImage.formControlName].file = valWithImage.value
                                        }
                                    }
                                });
                                return {
                                    ...val,
                                    finished: valueFormWithTemporaryFile.finished,
                                    progress: valueFormWithTemporaryFile.progress
                                }
                            })
                        )
                    } else {
                        this.loading.uploadTemporaryFile = false;
                        return EMPTY;
                    }
                }
                ),
            ).subscribe(val => {
                if (val != null) {
                    if (val.finished) {
                        this.loading.uploadTemporaryFile = false;
                        if (val.saveOnDatabase) {
                            this.saveAll(val);
                        }
                    }
                } else {
                    this.loading.uploadTemporaryFile = false;
                }
            }, error => {
                this.alertService.error(error);
                this.loading.uploadTemporaryFile = false;
            }
            );
        }

    }


    saveAll(event) {
        this.loading.saveScheda = true;
        this.savedValue.emit(
            {
                maintenanceScheduleReference: event.maintenanceScheduleReference,
                elementToSave: this.elemento,
                valuesSchedaManutenzione: event.valuesSchedaManutenzione,
                keys: event.keys
            }
        );
    }


    // copied() {
    //     this.alertService.success(this.translate.instant('copy'))
    // }

    get iSetSegnalazioni() {
        return arrayIsSet(this.segnalazioniAperte);
    }


    clickSegnalazione(segnalazione: SegnalazioniParse) {
        this.segnalazioneSelezionata = segnalazione;
    }

    clickCloseReport(event) {
        this.loadingSaveReport.closeReport = true;
        this.closeReport.emit(event);
    }

    clickSearchIntervention(event) {
        this.loadingSaveReport.searchIntervention = true;
        this.reopenReport.emit(event);
    }

    clickTakeCharge(event) {
        this.loadingSaveReport.takeCharge = true;
        this.takeCharge.emit(event);
    }

    clickGenerateReport(event) {
        this.loadingSaveReport.generateReport = true;
        this.generateReport.emit(event);
    }

    clickAssignTo(event) {
        this.loadingSaveReport.assignTo = true;
        this.assignTo.emit(event);
    }

    clickSendComment(event) {
        this.loadingSaveReport.sendComment = true;
        this.sendComment.emit(event);
    }

    get leastOneLoadReportIsTrue() {
        return Object.values(this.loadingSaveReport).findIndex(load => load) >= 0;
    }


    openDetail(schedaManutenzione, detailsScheda) {
        this.dialogInfo.openScheduleMaintenanceDetail({ schedaManutenzione, detailsScheda })
            .subscribe(response => {
                if (response && response.select && response.schedaManutenzione && !this.isSelected(schedaManutenzione)) {
                    this.clickBox(response.schedaManutenzione);
                }
            });
    }


    getScheduleMaintenanceExpired(calendari: CalendarioManutenzioniParse[], tomorrow: Date): SchedaManutenzioneParse[] | undefined {
        const calendarInDate = calendari.filter(calenario => calenario.maintenanceDate.getTime() < tomorrow.getTime())
        if (arrayIsSet(calendarInDate)) {
            return calendarInDate.map(calendario => calendario.schedaManutenzione);
        } else {
            return undefined;
        }
    }


    getScheduleMaintenanceExpiring(calendari: CalendarioManutenzioniParse[], tomorrow: Date): SchedaManutenzioneParse[] | undefined {
        const afterNext = new Date(tomorrow);
        afterNext.setDate(afterNext.getDate() + this.numberDayConsiderNext);
        const calendarInDate = calendari.filter(calenario => calenario.maintenanceDate.getTime() >= tomorrow.getTime() && calenario.maintenanceDate.getTime() < afterNext.getTime());
        if (arrayIsSet(calendarInDate)) {
            return calendarInDate.map(calendario => calendario.schedaManutenzione);
        } else {
            return undefined;
        }
    }

    getScheduleMaintenanceNext(calendari: CalendarioManutenzioniParse[], tomorrow: Date): SchedaManutenzioneParse[] | undefined {
        const afterNext = new Date(tomorrow);
        afterNext.setDate(afterNext.getDate() + this.numberDayConsiderNext);
        const calendarInDate = calendari.filter(calenario => calenario.maintenanceDate.getTime() >= afterNext.getTime());
        if (arrayIsSet(calendarInDate)) {
            return calendarInDate.map(calendario => calendario.schedaManutenzione);
        } else {
            return undefined;
        }
    }


    singleCardClick(schedaCompilata: SchedaManutenzioneCompilataParse) {
        this.dialogInfo.openVisualedOtherValue({ form: schedaCompilata.form, formTemplate: schedaCompilata.schedaManutenzione.form });
    }
}
