import {Injectable} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";

@Injectable({
    providedIn: 'root'
})


export class FilterFormService {


    constructor(
        private fb: UntypedFormBuilder
    ) {
    }

    public getForm(): UntypedFormGroup {
        return this.fb.group({
                address: null,
                advancedFilter: [],
                circuits: [],
                lightPoints: [],
                puntiStampa: [],
                confiniAmministrativi: [],
                gruppiPuntiLuce: [],
                arredoUrbano: [],
                electricLines: [],
                activeLightPoints: activeLightPointBoth
            },
        );
    }


}

export interface FormFilterComponent {
    address: string,
    advancedFilter: any[],
    circuits: string[],
    lightPoints: string[],
    puntiStampa: string[],
    confiniAmministrativi: string[],
    gruppiPuntiLuce: string[],
    arredoUrbano: any[],
    electricLines: any []
    activeLightPoints: boolean | string
}

export const activeLightPointBoth = '__both__';