<form [formGroup]="filterForm" style="margin: 5px 8px">
    <app-auto-complete-form
        appearance="outline"
        style="width: 100%;"
        formControlName="scalaColore"
        [hiddenClearButton]="false"
        [title]="'titleSetting'|translate" 
        [items]="circuitAutocompleteFields"
    >
    </app-auto-complete-form>
</form>
<ng-container *ngIf="elementiScalaArray&&elementiScalaArray.length>0">
    <div style="display:flex;align-items: center; justify-content: center;">
        <table mat-table [dataSource]="elementiScalaArray" class="mat-elevation-z4" style="margin-bottom: 20px;">
            <ng-container matColumnDef="color">
                <th mat-header-cell *matHeaderCellDef>{{'colorSettings'|translate}}</th>
                <td mat-cell *matCellDef="let element">
                    <div class="elementoLeggenda" [ngStyle]="{'background-color':element.color}">
                    </div>
                </td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="key">
                <th mat-header-cell *matHeaderCellDef>{{'rappresentedElement'|translate}}</th>
                <td mat-cell *matCellDef="let element">
                    <ng-container
                            *ngIf="element.key!='Altro'&& element.key!='undefined'&& element.key!='Non_specificato'&& element.key!='Non specificato';else altroNonSpecificato">
                        <ng-container [ngSwitch]="tipoDato">
                            <ng-container *ngSwitchCase="'text'">
                                {{element.key|translate|sostituisciUndercoreConSpazi}}
                            </ng-container>
                            <ng-container *ngSwitchCase="'bool'">
                                {{element.key|translate|sostituisciUndercoreConSpazi}}
                            </ng-container>
                            <ng-container *ngSwitchCase="'targa'">
                                {{element.key|translate|sostituisciUndercoreConSpazi}}
                            </ng-container>
                            <ng-container *ngSwitchCase="'elenco'">
                                {{pathForTranslate + campoScalaColore + '.possibleValues.' + (element.key|transformForTranslate)|translate}}
                            </ng-container>
                            <ng-container *ngSwitchCase="'pointer_circuiti'">
                                {{element.key}}
                            </ng-container>
                            <ng-container *ngSwitchCase="'date'">
                                {{element.key|customDate :'longDate'|async}}
                            </ng-container>
                            <ng-container *ngSwitchCase="'number'">
                                {{element.key}}
                            </ng-container>
                            <ng-container *ngSwitchCase="'int'">
                                {{element.key}}
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                {{element.key}}
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <ng-template #altroNonSpecificato>
                        {{element.key|translate|sostituisciUndercoreConSpazi}}
                    </ng-template>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</ng-container>