export const STATIC_DATA = {
    // gender: [
    //     {
    //         id:'F',
    //         value:'F'
    //     },
    //     {
    //         id:'M',
    //         value:'M'
    //     }
    // ]
};

export enum UserRole {
    UTENTE = 'Utente',
    OPERATORE = 'Operatore',
    INSTALLATORE = 'Installatore',
    AMMINISTRATORE = 'Amministratore',
    Sviluppatore = 'Sviluppatore',
    GESTORE = 'Gestore'
};

export enum UserRoleNumber {
    UTENTE = 0,
    INSTALLATORE = 1,
    OPERATORE = 2,
    GESTORE = 3,
    AMMINISTRATORE = 4,
}


export enum ReportStates {
    RISOLTO = 'Risolto',
    CHIUSO = 'Chiuso',
    COMPLETATO = 'Intervento completato',
    INCARICO = 'Presa in carico',
    APERTO = 'Aperto',
    MATERIALE = 'In attesa di materiale',
    INTERVENTO = 'In attesa di un intervento',
    ASSEGNATOA = 'Assegnata a',
    INSTALLATO_MULETTO = 'Installato muletto',
    MESSA_IN_SICUREZZA = 'Messa in sicurezza',
    EMESSO_PREVENTIVO = 'Emesso preventivo',
    RICERCA_GUASTO = 'Ricerca guasto',
    IN_ATTESA = 'In attesa',
};

export enum Priority {
    BASSA = 'Bassa',
    MEDIA = 'Media',
    ALTA = 'Alta'
}


export const TIPOSEGNALAZIONE = [
    "Lampada spenta",
    "Corpo lluminante malfunzionante",
    "Corpo lluminante danneggiato",
    "Corpo lluminante mancante",
    "Scarso illuminamento",
    "Alterazione colore",

    "Dispersione sulla linea",
    "Sfascettamento",
    "Perdita condizioni di sicurezza linea elettrica",
    "Corto circuito",
    "Problemi su pozzetti o sottoquadri",

    "Ora accensione",
    "Ora spegnimento",
    "Guasto sul quadro",
    "Guasto apparecchiatura di telecontrollo",
    "Mancanza del servizio",
    "Quadro incidentato",
    "Quadro danneggiato",
    "Quadro manomesso",
    "Consumo anomalo",

    "Palo inclinato",
    "Sostegno pericolante",
    "Sostegno corroso",
    "Base palo corrosa",
    "Mancanza o danneggiamento della portella",
    "Danneggiamento sostegno",
    "Palo incidentato",
    "Morsettiera danneggiata",
];

export const CHART_COLORS = [
    {r:93,g:190,b:215},
    {r:243,g:130,b:50},
    {r:255,g:220,b:13},
    {r:172,g:114,b:173},
    {r:255,g:99,b:132},
    {r:15,g:14,b:255},
    {r:255,g:9,b:5},
    {r:30,g:255,b:48},
    {r:74,g:255,b:223},
    {r:255,g:215,b:20},
    {r:128,g:22,b:26},
    {r:255,g:118,b:21},
    {r:169,g:15,b:255},
    {r:211,g:255,b:17},
    {r:167,g:166,b:255},
]
//
// {
//     '#5dbed7',
//         '#f38232',
//         '#ffdc0d',
//         '#ac72ad'
// }
