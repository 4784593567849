<mat-dialog-content>
    <form class="maintenance-form" [formGroup]="forms" *ngIf="forms&&formFieldSchedaSelezionata">
        
        <ng-container *ngFor="let formField of formFieldSchedaSelezionata">
            <div>
                <ng-container [ngSwitch]="formField.type">
                    <ng-container *ngSwitchCase="typeFormField.inputString">
                        <mat-form-field appearance="fill" class=" width-form-field">
                            <mat-label>
                                {{formField.traduction}}
                            </mat-label>
                            <input matInput [formControlName]="formField.formControlName">
                            <div matPrefix class="flex-around-center flex-flow-row">
                                <ng-container
                                        [ngTemplateOutlet]="clearInfoButton"
                                        [ngTemplateOutletContext]="{formField:formField}">
                                </ng-container>
                            </div>
                            <mat-hint *ngIf="showError(formField,'required')"
                                        class="color-red">{{'mandatory_field_message'|translate}}</mat-hint>
                        </mat-form-field>
                        <div style="margin-top: -31px;margin-bottom: 34px;">
                            <ng-container
                                    [ngTemplateOutlet]="expandedDetailFormField"
                                    [ngTemplateOutletContext]="{title:getFieldName(formField),note:getFieldNote(formField),expanded:getExpandMoreLessNote(formField)}">
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="typeFormField.inputNumber">
                        <mat-form-field appearance="fill" class=" width-form-field">
                            <mat-label>
                                {{formField.traduction}}
                            </mat-label>
                            <input type="number" matInput
                                    [formControlName]="formField.formControlName">
                            <div matPrefix class="flex-around-center flex-flow-row">
                                <ng-container
                                        [ngTemplateOutlet]="clearInfoButton"
                                        [ngTemplateOutletContext]="{formField:formField}">
                                </ng-container>
                            </div>
                            <mat-hint *ngIf="showError(formField,'required')"
                                        class="color-red">{{'mandatory_field_message'|translate}}</mat-hint>
                        </mat-form-field>
                        <div style="margin-top: -31px;margin-bottom: 34px;">
                            <ng-container
                                    [ngTemplateOutlet]="expandedDetailFormField"
                                    [ngTemplateOutletContext]="{title:getFieldName(formField),note:getFieldNote(formField),expanded:getExpandMoreLessNote(formField)}">
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="typeFormField.multiSelect">
                        <mat-form-field appearance="fill" class=" width-form-field">
                            <mat-label>
                                {{formField.traduction}}
                            </mat-label>
                            <mat-select [formControlName]="formField.formControlName">
                                <mat-option [value]="opt.html"
                                            *ngFor="let opt of formField.possibleValuesWithKey">
                                    {{opt.html}}
                                </mat-option>
                            </mat-select>
                            <div matPrefix class="flex-around-center flex-flow-row">
                                <ng-container
                                        [ngTemplateOutlet]="clearInfoButton"
                                        [ngTemplateOutletContext]="{formField:formField}">
                                </ng-container>
                            </div>
                            <mat-hint *ngIf="showError(formField,'required')"
                                        class="color-red">{{'mandatory_field_message'|translate}}</mat-hint>
                        </mat-form-field>
                        <div style="margin-top: -31px;margin-bottom: 34px;">
                            <ng-container
                                    [ngTemplateOutlet]="expandedDetailFormField"
                                    [ngTemplateOutletContext]="{title:getFieldName(formField),note:getFieldNote(formField),expanded:getExpandMoreLessNote(formField)}">
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="typeFormField.autoComplete">
                        <app-auto-complete-form
                                class="width-form-field"
                                classStyle=""
                                [title]="getFieldName(formField)"
                                [items]="formField.possibleValuesWithKey"
                                appearance="fill"
                                [hiddenClearButton]="true"
                                [formControlName]="formField.formControlName"
                                [hasError]="isInError(formField,'required')"
                                messageError="{{'mandatory_field_message'|translate}}"
                        >
                            <div prefix class="flex-around-center flex-flow-row">
                                <ng-container
                                        [ngTemplateOutlet]="clearInfoButton"
                                        [ngTemplateOutletContext]="{formField:formField}">
                                </ng-container>
                            </div>
                        </app-auto-complete-form>
                        <div style="margin-top: -31px;margin-bottom: 34px;">
                            <ng-container
                                    [ngTemplateOutlet]="expandedDetailFormField"
                                    [ngTemplateOutletContext]="{title:getFieldName(formField),note:getFieldNote(formField),expanded:getExpandMoreLessNote(formField)}">
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="typeFormField.dataPicker">
                        <mat-form-field appearance="fill" class=" width-form-field ">
                            <mat-label> {{formField.traduction}}</mat-label>
                            <input matInput [matDatepicker]="picker"
                                    [formControlName]="formField.formControlName">
                            <div matPrefix class="flex-around-center flex-flow-row">
                                <ng-container
                                        [ngTemplateOutlet]="clearInfoButton"
                                        [ngTemplateOutletContext]="{formField:formField}">
                                </ng-container>
                            </div>
                            <mat-datepicker-toggle matSuffix
                                                    [for]="picker">

                            </mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-hint *ngIf="isInError(formField,'required')"
                                        class="color-red">{{'mandatory_field_message'|translate}}
                            </mat-hint>
                        </mat-form-field>
                        <div style="margin-top: -31px;margin-bottom: 34px;">
                            <ng-container
                                    [ngTemplateOutlet]="expandedDetailFormField"
                                    [ngTemplateOutletContext]="{title:getFieldName(formField),note:getFieldNote(formField),expanded:getExpandMoreLessNote(formField)}">
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="typeFormField.hour">
                        <div class="maintenance-form-field">
                            <div class="ngx-theme">
                                <div style="display: flex;justify-content: space-between"
                                        [ngClass]="{'error-button-yesOrNotCheck':isInError(formField,'required')}">
                                    <div style="max-width: calc(100% - 182px);" class="truncate-text">
                                        <button style="margin-left: 8px;" *ngIf="visualizedUnsetButton"
                                                mat-icon-button
                                                (click)="$event.stopPropagation();unsetField(formField.formControlName);">
                                            <mat-icon>clear</mat-icon>
                                        </button>
                                        <button style="margin-left: 8px;"
                                                mat-icon-button
                                                (click)="$event.stopPropagation();setExpandMoreLessNote(formField);">
                                            <mat-icon style="color: #1a1a1a">info</mat-icon>
                                        </button>
                                        {{getFieldName(formField)}}
                                    </div>
                                    <app-form-material-time-picker
                                            [formControlName]="formField.formControlName"
                                            [submitLabel]="'remote_control_settings.form.timepicker.submit' | translate"
                                            [revertLabel]="'remote_control_settings.form.timepicker.revert' | translate"
                                            color="accent"
                                    >
                                    </app-form-material-time-picker>
                                </div>
                            </div>
                        </div>
                        <span *ngIf="showError(formField,'required')"
                                class="hint-font color-red">{{'mandatory_field_message'|translate}}</span>
                        <div style="margin-bottom: 34px;">
                            <ng-container
                                    [ngTemplateOutlet]="expandedDetailFormField"
                                    [ngTemplateOutletContext]="{title:getFieldName(formField),note:getFieldNote(formField),expanded:getExpandMoreLessNote(formField)}">
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="typeFormField.image">
                        <div class="flex-around-center w100 ">
                            <ngx-dropzone
                                    (change)="onLoadImage($event.addedFiles,formField.formControlName)"
                                    class="drop-zone-item-background"
                                    accept="image/jpeg,image/jpg,image/png"
                            >
                                <ng-container
                                        *ngIf="forms.get(formField.formControlName).value!=null&&forms.get(formField.formControlName).value.file!=null&&forms.get(formField.formControlName).value.file.length>0;else noFileSet">

                                    <ng-container *ngIf="!loadImage[formField.formControlName];else loadImage">
                                        <mat-card style="padding: 0;margin: 0"
                                                    *ngFor="let file of forms.get(formField.formControlName).value.file">
                                            <div style="position: absolute;top:0px;right:1px;z-index: 10"
                                                    (click)="$event.stopPropagation();onRemoveFile(formField.formControlName,file.image)">
                                                <mat-icon [svgIcon]="nameIconCancel"></mat-icon>
                                            </div>
                                            <img [src]="file.image??file.url" class="img-icon-xxl">
                                        </mat-card>
                                    </ng-container>
                                </ng-container>
                                <ng-template #noFileSet>
                                    <ngx-dropzone-label class="w100">
                                        <div class="flex-around-center w100"
                                                [ngClass]="{'error-button-yesOrNotCheck':showError(formField,'required')}"
                                        >
                                            <div style="width: 51px;text-align: end">
                                                <button mat-icon-button
                                                        (click)="$event.stopPropagation();setExpandMoreLessNote(formField);">
                                                    <mat-icon style="color: black">info</mat-icon>
                                                </button>
                                            </div>
                                            <div class="flex-start-start flex-flow-column"
                                                    style="width: calc(100% - 51px)">
                                                <span style="text-align: start;font-size: 14px;margin-bottom: 3px;margin-left: 20px">{{ getFieldName(formField)}}</span>
                                                <div class="w100"
                                                        style="text-align: center">   {{ 'fileManager.copyFile' | translate}}</div>
                                            </div>
                                        </div>
                                    </ngx-dropzone-label>
                                </ng-template>
                            </ngx-dropzone>
                        </div>
                        <div style="margin-bottom: 34px;margin-top: -28px;">
                            <ng-container
                                    [ngTemplateOutlet]="expandedDetailFormField"
                                    [ngTemplateOutletContext]="{title:getFieldName(formField),note:getFieldNote(formField),expanded:getExpandMoreLessNote(formField)}">
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="typeFormField.checkBox">
                        <div class="maintenance-form-field"
                                [ngClass]="{'error-button-yesOrNotCheck':isInError(formField,'required')}"
                        >
                            <div style="padding: 5px;" class="flex-center-Between w100">
                                <p style="margin:5px 0  5px 7px;">
                                    <!--                                <div matPrefix class="flex-around-center flex-flow-row">-->
                                    <ng-container
                                            [ngTemplateOutlet]="clearInfoButton"
                                            [ngTemplateOutletContext]="{formField:formField}">
                                    </ng-container>
                                    <!--                                </div>-->
                                    <!--                                    <button matPrefix mat-icon-button-->
                                    <!--                                            (click)="$event.stopPropagation();setExpandMoreLessNote(formField);">-->
                                    <!--                                        <mat-icon style="color: black">info</mat-icon>-->
                                    <!--                                    </button>-->
                                    {{getFieldName(formField)}}
                                </p>
                                <mat-checkbox
                                        style="margin-right: 25px"
                                        [formControlName]="formField.formControlName"></mat-checkbox>
                            </div>
                        </div>
                        <span *ngIf="showError(formField,'required')"
                                class="hint-font color-red">{{'mandatory_field_message'|translate}}</span>
                        <div style="margin-bottom: 34px;">
                            <ng-container
                                    [ngTemplateOutlet]="expandedDetailFormField"
                                    [ngTemplateOutletContext]="{title:getFieldName(formField),note:getFieldNote(formField),expanded:getExpandMoreLessNote(formField)}">
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="typeFormField.yesOrNot">
                        <div class="maintenance-form-field"
                                [ngClass]="{'error-button-yesOrNotCheck':isInError(formField,'required')}">
                            <div style="margin:5px 0  5px 0;" class="flex-center-Between w100">
                                <p style="margin:5px 0  5px 11px;">
                                    <button matPrefix mat-icon-button
                                            (click)="$event.stopPropagation();setExpandMoreLessNote(formField);">
                                        <mat-icon style="color: black">info</mat-icon>
                                    </button>
                                    {{getFieldName(formField)}}
                                    <button mat-icon-button
                                            (click)="unsetField(formField.formControlName)">
                                        <mat-icon>replay_circle_filled</mat-icon>
                                    </button>
                                </p>
                                <div>
                                    <button style="margin: 5px" mat-flat-button
                                            [color]="getColorYesOrNotButton(formField.formControlName,true)"
                                            (click)="setValueYesOrNotButton(formField.formControlName,true)">
                                        <mat-icon>check_circle</mat-icon>
                                    </button>
                                    <button style="margin: 5px" mat-flat-button
                                            [color]="getColorYesOrNotButton(formField.formControlName,false)"
                                            (click)="setValueYesOrNotButton(formField.formControlName,false)">
                                        <mat-icon>cancel</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <span *ngIf="showError(formField,'required')"
                                class="hint-font color-red">{{'mandatory_field_message'|translate}}</span>
                        <div style="margin-bottom: 34px;">
                            <ng-container
                                    [ngTemplateOutlet]="expandedDetailFormField"
                                    [ngTemplateOutletContext]="{title:getFieldName(formField),note:getFieldNote(formField),expanded:getExpandMoreLessNote(formField)}">
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>
        
    </form>
    <ng-template #expandedDetailFormField let-title='title' let-note='note' let-expanded='expanded'>
        <mat-card *ngIf="expanded">
            <mat-card-subtitle>{{title}}</mat-card-subtitle>
            {{note}}
        </mat-card>
    </ng-template>
    <ng-template #error let-formField='formField' let-keyError='keyError'>

    </ng-template>

    <ng-template #clearInfoButton let-formField='formField'>
        <button mat-icon-button *ngIf="visualizedUnsetButton"
                (click)="$event.stopPropagation();unsetField(formField.formControlName);">
            <mat-icon>clear</mat-icon>
        </button>
        <button mat-icon-button
                style="color: #1a1a1a"
                (click)="$event.stopPropagation();setExpandMoreLessNote(formField);">
            <mat-icon>info</mat-icon>
        </button>
    </ng-template>
</mat-dialog-content>
<mat-dialog-actions *ngIf="isOpenByDialog">
    <button [matTooltip]="'button.close'|translate"
            color="warn"
            mat-flat-button
            (click)="$event.stopPropagation();confirmCloseDialog();">
        <mat-icon>clear</mat-icon>
    </button>
    <button [matTooltip]="'button.saveBozza'|translate"
            color="primary"
            mat-flat-button
            (click)="$event.stopPropagation();saveAll(false);">
        <mat-icon>playlist_add_check</mat-icon>
    </button>
    <button [matTooltip]="'button.save'|translate"
            [disabled]="forms.invalid"
            color="primary"
            mat-flat-button
            (click)="$event.stopPropagation();saveAll(true);">
        <mat-icon>check</mat-icon>
    </button>
</mat-dialog-actions>
<ng-template #loadImage>
    <mat-spinner></mat-spinner>
</ng-template>