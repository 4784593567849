import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SettingsComponent} from './settings/settings.component';
import {ReactiveFormsModule, FormGroupDirective, FormsModule} from '@angular/forms';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {PipesModule} from 'src/app/providers/pipes/pipes.module';
import {WidgetsModule} from '../../widgets/widgets.module';
import {MatGridListModule} from '@angular/material/grid-list';
import {CircuitScaleColorComponent} from './circuit-scale-color/circuit-scale-color.component';
import {CircuitLabelMarkerComponent} from './circuit-label-marker/circuit-label-marker.component';
import {IlluminamentoTerraComponent} from './illuminamento-terra/illuminamento-terra.component';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio';
import {MatLegacyChipsModule as MatChipsModule} from '@angular/material/legacy-chips';
import {MaintenanceModalityComponent} from './maintenance-modality/maintenance-modality.component';
import {BoxesModule} from '../../boxes/boxes.module';
import {MatBadgeModule} from '@angular/material/badge';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {ScaleColorAndLabelComponent} from './scale-color-and-label/scale-color-and-label.component';
import {ScaleForMapModule} from "../scale-for-map/scale-for-map.module";
import {DirectivesModule} from "../../providers/directives/directives.module";


@NgModule({
    declarations: [SettingsComponent, CircuitScaleColorComponent, CircuitLabelMarkerComponent, IlluminamentoTerraComponent, MaintenanceModalityComponent, ScaleColorAndLabelComponent],
    exports: [SettingsComponent, CircuitScaleColorComponent, IlluminamentoTerraComponent],
    imports: [
        CommonModule,
        MatToolbarModule,
        MatTableModule,
        MatTabsModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatInputModule,
        MatCardModule,
        MatTooltipModule,
        TranslateModule,
        ReactiveFormsModule,
        MatInputModule,
        MatSelectModule,
        MatFormFieldModule,
        MatIconModule,
        PipesModule,
        WidgetsModule,
        MatGridListModule,
        FormsModule,
        MatCheckboxModule,
        MatRadioModule,
        MatChipsModule,
        BoxesModule,
        MatBadgeModule,
        MatProgressSpinnerModule,
        ScaleForMapModule,
        DirectivesModule,
    ]
})
export class SettingModule {
}
