import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {MapService} from '../../../providers/services/map.service';
import {configurationPropertyUrbanForniture} from '../../../models/configurationProperty/urbanFurniture';
import {arrayIsSet, className, isNotNullOrUndefined, stringIsSet} from '../../../models/Models';
import {
    castValueByConfigurationElement,
    configurationElement,
    getElementByKeyName, getPossibleValuePointer,
    getSubForm,
    getValidator,
    getValidatorFormField,
    isPointerConfigurationElement,
    PossibleValuesType,
    typeDatabase,
    typeFormValue
} from '../../../models/configurationProperty/configurationPropertyUtils';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ArredoUrbanoParse} from '../../../models/ArredoUrbano.Parse';
import {dataForm} from '../../confirm-delete/select-or-create/select-or-create.component';
import {EMPTY, Subject, Subscription} from 'rxjs';
import {configurationPropertyElectricLine} from '../../../models/configurationProperty/electricLine';
import {LineaElettricaParse} from '../../../models/LineaElettrica.Parse';
import {CircuitiParse} from '../../../models/Circuiti.Parse';
import {CaricoEsogenoParse} from "../../../models/CaricoEsogeno.Parse";

// tslint:disable-next-line:class-name
export interface htmlFormElement {
    type: typeFormValue;
    possibleValues: dataForm[];
    formControlName: string;
    traduction: string;
    visualizeButton?: boolean;
    detailButton?: { icon: string, message: string };
    nameVariableToGetPossibleValues?: string;
    formGroupChild: {
        formGroupName: string,
        fields: {
            type: typeFormValue,
            possibleValues: { valueKey, traduction }[],
            formControlName: string,
            traduction: string,
        }[]
    } | undefined;
}

// tslint:disable-next-line:class-name
export interface htmlData {
    tabGroupName: string;
    forms: htmlFormElement[];
}

@Component({
    selector: 'app-form-item-on-map',
    templateUrl: './form-item-on-map.component.html',
    styleUrls: ['./form-item-on-map.component.scss']
})
export class FormItemOnMapComponent implements OnInit, OnChanges, OnDestroy {

    @Input() detail;
    @Input() disabledSaveButton = false;
    @Input() circuitsInProject: CircuitiParse[] | undefined;
    @Input() carichiEsogeniAppartenentiAlProgetto: CaricoEsogenoParse[] | undefined;
    isSetCircuitsInProject: boolean;
    @Output() clickSave = new EventEmitter();
    sectionNumberName;
    currentClassname;
    isSetForm = new Subject();
    isSetForm$ = this.isSetForm.asObservable();


    private nomiClassi = className;

    public typeForm = typeFormValue;


    public htmlData: htmlData[] = [];
    public configElementSubForm: { element: configurationElement, htmlFormElement: htmlFormElement }[] = [];
    public subscriptionsKeyWithSubForm: Subscription[] = [];
    public form: UntypedFormGroup;
    private configurationData;
    public loading = {};

    constructor(
        private mapService: MapService,
        private fb: UntypedFormBuilder,
        private cd: ChangeDetectorRef
    ) {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (isNotNullOrUndefined(changes.detail)) {
            this.loadData(this.detail);
        }
        // if (changes.carichiEsogeniAppartenentiAlProgetto) {
        //     this.htmlData.forEach(html => html.forms.forEach(field => {
        //             if (stringIsSet(field.nameVariableToGetPossibleValues) && arrayIsSet(this[field.nameVariableToGetPossibleValues])) {
        //                 const element = getElementByKeyName(field.formControlName, this.configurationData);
        //                 const el = this[field.nameVariableToGetPossibleValues].find(el => element.pointer.getId(el) === this.form.get(element.keyName).value)
        //                 if (el != null) {
        //                     const pc = getPossibleValuePointer(el, element, this[field.nameVariableToGetPossibleValues]);
        //                     const valueForm = pc.currentValue;
        //                     field.possibleValues = pc.possibleValues;
        //                 }
        //             }
        //         })
        //     );
        // }
    }

    ngOnDestroy(): void {
        this.closeSubscriptionForm();
        this.isSetForm.complete();
    }

    private closeSubscriptionForm() {
        this.subscriptionsKeyWithSubForm.forEach(sub => sub.unsubscribe());
        this.subscriptionsKeyWithSubForm = [];
    }

    // tslint:disable-next-line:no-shadowed-variable
    private addHtmlElement(element: configurationElement, htmlFormElement: htmlFormElement, htmlData: htmlData[]): void {
        const tabGroupName = this.sectionNumberName.hasOwnProperty(element.section) ? this.sectionNumberName[element.section] : 'null';
        const index = htmlData.findIndex(htmlValue => htmlValue.tabGroupName === tabGroupName);
        if (index >= 0) {
            htmlData[index].forms.push(htmlFormElement);
        } else {
            htmlData.push({tabGroupName, forms: [htmlFormElement]});
        }
    }


    private getPossibleValuesCurrentValue(possibleValues: PossibleValuesType[], cValue): {
        possibleValues: dataForm[] | undefined,
        currentValue: string | undefined
    } {
        let currentValue;
        if (arrayIsSet(possibleValues)) {
            const pValues = possibleValues.map((possibleValue) => {
                const keyForm = possibleValue.dataBaseValue;
                if (keyForm === cValue) {
                    currentValue = keyForm.toString();
                }
                const obj: dataForm = {
                    valueForm: keyForm.toString(),
                    html: keyForm.toString(),
                    traduction: '',
                };
                if (possibleValue.traduction != null) {
                    obj.traduction = possibleValue.traduction;
                } else if (possibleValue.noTraduction != null) {
                    obj.traduction = possibleValue.noTraduction;
                }
                return obj;
            });
            if (cValue != null) {
                const index = pValues.findIndex((value) => value.valueForm === (cValue.toString != null ? cValue.toString() : cValue));
                if (index < 0) {
                    pValues.push({valueForm: cValue, html: cValue, traduction: cValue});
                    currentValue = cValue;
                }
            }
            return {possibleValues: pValues, currentValue};
        } else {
            return {possibleValues: undefined, currentValue: cValue};
        }
    }


    private getFormGroupChild(element: configurationElement, detail, referenceKey: string, className: string): {
        formGroupChild: { formGroupName, fields } | undefined,
        subObjForm: { key: string, formGroup: UntypedFormGroup } | undefined
    } {
        const subForm = getSubForm(element, referenceKey);
        let formGroupChild;
        let formGroup;
        if (isNotNullOrUndefined(subForm)) {
            const subObjForm = {};
            const subDetail = isNotNullOrUndefined(detail[element.forms.databaseKey]) ? detail[element.forms.databaseKey] : {};
            const subHtml = [];
            subForm
                .sort((aValue, bValue) => aValue.sortingValue - bValue.sortingValue)
                .forEach(subElement => {
                    const pValueCurrent = this.getPossibleValuesCurrentValue(subElement.possibleValues, subDetail[subElement.keyName]);
                    subObjForm[subElement.keyName] = [{
                        disabled: !element.editable,
                        value: pValueCurrent.currentValue,
                    }, getValidatorFormField(subElement)];
                    // tslint:disable-next-line:no-shadowed-variable
                    const subForm = {
                        type: subElement.typeForm,
                        possibleValues: pValueCurrent.possibleValues,
                        formControlName: subElement.keyName,
                        traduction: subElement.keyTraduction
                    };
                    subHtml.push(subForm);
                });
            formGroupChild = {formGroupName: undefined, fields: undefined};
            formGroupChild.formGroupName = element.forms.databaseKey;
            formGroupChild.fields = subHtml;
            formGroup = this.fb.group(subObjForm);

            return {formGroupChild, subObjForm: {key: element.forms.databaseKey, formGroup}};
        } else {
            return {formGroupChild: undefined, subObjForm: undefined};
        }
    }

    // htmlButton(message: string, form) {
    //     if (message === this.nomiClassi.caricoEsogeno) {
    //         if (stringIsSet(form.nameVariableToGetPossibleValues)) {
    //             this.loading[form.nameVariableToGetPossibleValues] = true
    //         }
    //         const formFields: FormFieldPopUpType[] = this.caricoEsogeno.getFormFiledsForDialog();
    //         const data: FormFieldPopUpData = {
    //             formFields,
    //             title: {traduction: 'dashboard_sidenav.fieldVisualizable'},
    //             disableClose: false,
    //             visualizeCheckAllButton: false,
    //             visualizedSearch: false
    //         };
    //         this.dialogInfo.openFormFields(data, {width: '600px', maxWidth: '100%'})
    //             .pipe(switchMap(values => {
    //                 if (values == null) {
    //                     this.loading[form.nameVariableToGetPossibleValues] = false;
    //                     return EMPTY
    //                 } else {
    //                     return this.caricoEsogeno.createCaricoEsogeno(values)
    //                 }
    //             }))
    //             .subscribe(
    //                 caricoEsogenoCreato => {
    //                     const element = getElementByKeyName(form.formControlName, this.configurationData);
    //                     if (element != null && element.pointer != null && element.pointer.getId != null) {
    //                         this.form.get(form.formControlName).setValue(element.pointer.getId(caricoEsogenoCreato))
    //                     } else {
    //                         this.form.get(form.formControlName).setValue(caricoEsogenoCreato)
    //                     }
    //                     if (stringIsSet(form.nameVariableToGetPossibleValues)) {
    //                         this.loading[form.nameVariableToGetPossibleValues] = false
    //                     }
    //                 }, error => {
    //                     if (stringIsSet(form.nameVariableToGetPossibleValues)) {
    //                         this.loading[form.nameVariableToGetPossibleValues] = false
    //                     }
    //                     this.alertService.error(error);
    //                 }
    //             )
    //     }
    // }

    getFormHtmlData(configurationData: configurationElement[], detail): {
        formGruop: UntypedFormGroup,
        htmlData: htmlData [],
        objForm: any,
        configElementSubForm: { element: configurationElement, htmlFormElement: htmlFormElement }[]
    } {
        const html: htmlData[] = [];
        const objForm = {};
        const configElementSubForm = [];
        configurationData.forEach(element => {
            let possibleValues;
            let valueForm;
            let visualizeButton;
            let detailButton = {icon: null, message: null};
            let nameVariableToGetPossibleValues;
            if (element.typeDatabase === typeDatabase.POINTER && (element.typeForm === typeFormValue.ELENCO_VINCOLATO || element.typeForm === typeFormValue.ELENCO_APERTO)) {
                if (element.pointer != null && element.pointer.className === this.nomiClassi.circuiti) {
                    if (arrayIsSet(this.circuitsInProject)) {
                        const pc = getPossibleValuePointer(detail[element.keyName], element, this.circuitsInProject);
                        possibleValues = pc.possibleValues;
                        valueForm = pc.currentValue;
                    }
                } else if (element.pointer != null && element.pointer.className === this.nomiClassi.caricoEsogeno) {
                    const pc = getPossibleValuePointer(detail[element.keyName], element, this.carichiEsogeniAppartenentiAlProgetto);
                    possibleValues = pc.possibleValues;
                    valueForm = pc.currentValue;
                    visualizeButton = true;
                    detailButton.icon = 'add';
                    detailButton.message = element.pointer.className;
                    nameVariableToGetPossibleValues = 'carichiEsogeniAppartenentiAlProgetto';
                }

            } else if (element.typeForm === typeFormValue.GEO_POINT) {
                valueForm = {latitude: detail[element.keyName].latitude, longitude: detail[element.keyName].longitude};
            } else if (isPointerConfigurationElement(element)) {
                valueForm = element.pointer.getValue(detail);
            } else if (arrayIsSet(element.possibleValues)) {
                const pValueCurrent = this.getPossibleValuesCurrentValue(element.possibleValues, detail[element.keyName]);
                possibleValues = pValueCurrent.possibleValues;
                valueForm = pValueCurrent.currentValue;
            } else {
                valueForm = detail[element.keyName];
            }

            // tslint:disable-next-line:no-shadowed-variable
            const htmlFormElement: htmlFormElement = {
                type: element.typeForm,
                possibleValues,
                visualizeButton,
                detailButton,
                formControlName: element.keyName,
                traduction: element.traductionKey,
                formGroupChild: undefined,
                nameVariableToGetPossibleValues
            };
            const validators = getValidator(element);
            if (validators != null) {
                objForm[element.keyName] = [{
                    value: valueForm,
                    disabled: !element.editable
                }, validators];
            } else {
                objForm[element.keyName] = [{
                    value: valueForm,
                    disabled: !element.editable
                }];
            }
            const subFormValue = this.getFormGroupChild(element, detail, detail[element.keyName], detail.className);
            if (isNotNullOrUndefined(subFormValue.formGroupChild)) {
                htmlFormElement.formGroupChild = subFormValue.formGroupChild;
                objForm[subFormValue.subObjForm.key] = subFormValue.subObjForm.formGroup;
                configElementSubForm.push({element, htmlFormElement});
            }
            this.addHtmlElement(element, htmlFormElement, html);
        });
        return {formGruop: this.fb.group(objForm), objForm, htmlData: html, configElementSubForm};
    }

    private loadData(detail) {
        this.closeSubscriptionForm();
        this.isSetForm.next(false);
        if (!isNotNullOrUndefined(detail)) {
            this.htmlData = [];
            this.form = undefined;
            this.configElementSubForm = [];
        } else if (detail.className === this.nomiClassi.puntiLuce) {
            // this.composePuntoLuceInfo(detail);
        } else if (detail.className === this.nomiClassi.circuiti) {
            // viene creata il data source per i circuiti
            // this.composeCircuitoInfo(detail);
        } else if (detail.className === this.nomiClassi.puntiStampa) {
            // this.composePuntiStampaInfo(detail);
        } else if (detail.className === this.nomiClassi.arredoUrbano) {
            this.sectionNumberName = {0: 'dashboard_sidenav.ArredoUrbano.features', 1: 'finale'};
            this.currentClassname = this.nomiClassi.arredoUrbano;
            this.composeArredoUrbanoInfo(detail);
        } else if (detail.className === this.nomiClassi.lineaElettrica) {
            this.sectionNumberName = {0: 'dashboard_sidenav.LineaElettrica.features', 1: 'finale'};
            this.currentClassname = this.nomiClassi.lineaElettrica;
            this.composeElectricLineInfo(detail);
        }
    }

    public composeArredoUrbanoInfo(detail: ArredoUrbanoParse) {
        this.htmlData = [];
        this.form = undefined;
        this.configElementSubForm = [];
        setTimeout(() => {
            const configurationData = configurationPropertyUrbanForniture
                .sort((aValue, bValue) => aValue.sortingValue - bValue.sortingValue);
            this.configurationData = configurationData;
            const valueFormHtml = this.getFormHtmlData(configurationData, detail);
            this.form = this.fb.group(valueFormHtml.objForm);
            this.htmlData = valueFormHtml.htmlData;
            this.configElementSubForm = valueFormHtml.configElementSubForm;
            this.configElementSubForm.forEach(elementHtml => {
                const subScription = this.form.get(elementHtml.element.keyName).valueChanges.subscribe(
                    changeValue => {
                        elementHtml.htmlFormElement.formGroupChild = this.getFormGroupChild(elementHtml.element, {}, changeValue, this.nomiClassi.arredoUrbano).formGroupChild;
                    });
                this.subscriptionsKeyWithSubForm.push(subScription);
            });
            let username;
            if (detail.lastUpdateBy != undefined) {
                this.mapService.getuserLastUpdateBy(detail.lastUpdateBy.id).subscribe((user) => {
                    username = user[0].get('nome') + ' ' + user[0].get('cognome');
                    this.form.get('lastUpdateBy').setValue(username);
                    this.isSetForm.next(true);

                });
            } else {
                this.isSetForm.next(true);
            }
        }, 100);
    }


    public composeElectricLineInfo(detail: LineaElettricaParse) {
        this.htmlData = [];
        this.form = undefined;
        this.configElementSubForm = [];
        setTimeout(() => {
            const configurationData = configurationPropertyElectricLine.sort((aValue, bValue) => aValue.sortingValue - bValue.sortingValue);
            this.configurationData = configurationData;
            const valueFormHtml = this.getFormHtmlData(configurationData, detail);
            this.form = valueFormHtml.formGruop;
            this.htmlData = valueFormHtml.htmlData;
            this.configElementSubForm = valueFormHtml.configElementSubForm;
            this.configElementSubForm.forEach(elementHtml => {
                const subScription = this.form.get(elementHtml.element.keyName).valueChanges.subscribe(
                    changeValue => {
                        elementHtml.htmlFormElement.formGroupChild = this.getFormGroupChild(elementHtml.element, {}, changeValue, this.nomiClassi.lineaElettrica).formGroupChild;
                    });
                this.subscriptionsKeyWithSubForm.push(subScription);
            });
            this.isSetForm.next(true);
        });

    }

    clickSaveButton() {
        let configurationData;
        if (this.currentClassname === this.nomiClassi.arredoUrbano) {
            configurationData = configurationPropertyUrbanForniture;
        } else if (this.currentClassname === this.nomiClassi.lineaElettrica) {
            configurationData = configurationPropertyElectricLine;
        }
        const obj = {};
        Object.keys(this.form.value).forEach(key => {
            if (this.form.get(key).dirty) {
                const element = getElementByKeyName(key, configurationData);
                let value;
                if (element != null) {
                    if (element.pointer != null && element.pointer.className === this.nomiClassi.circuiti) {
                        value = castValueByConfigurationElement(this.form.value[key], element, this.circuitsInProject);
                    } else if (element.pointer != null && element.pointer.className === this.nomiClassi.caricoEsogeno) {
                        value = castValueByConfigurationElement(this.form.value[key], element, this.carichiEsogeniAppartenentiAlProgetto);
                    } else {
                        value = castValueByConfigurationElement(this.form.value[key], element, undefined);
                    }
                } else {
                    value = this.form.value[key];
                }
                if (value != null) {
                    obj[key] = value;
                }
            }
        });
        this.clickSave.emit(obj);
    }

}
