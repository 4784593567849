import {Component, Input, OnInit, Output} from '@angular/core';
import {Subject} from "rxjs";

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

    @Input() iconOpened;
    @Input() iconClose;
    @Input() vis;
    @Input() loading=false;
    @Output() changeVisibility: Subject<boolean> = new Subject();

    isVisible;

    constructor() {
    }

    ngOnInit() {
        if (this.vis) {
            this.onChangeVisibility()
        }
    }

    onChangeVisibility() {
        this.isVisible = !this.isVisible;
        this.changeVisibility.next(this.isVisible);
    }
}
