
<app-split-screen [breakPoint]="breakPointFilterTable">
    <div sx class="half-screen-wrapper">
        <mat-card class="margin-card-title">
            <mat-card-subtitle class="title-size">
                {{'SchedaManutenzione'|translate}}
            </mat-card-subtitle>
        </mat-card>
        <mat-card>
            <mat-card-subtitle>
                <form [formGroup]="formFilter">
                    <div class="flex-around-center w100 flex-flow-row">
                        <app-auto-complete-form
                                [classStyle]="'marginForCenterFormInTable'"
                                title="{{'scheduleMaintenance.filterName'|translate}}"
                                formControlName="progetti"
                                [items]="itemsAutoCompleteFilter"
                                appearance="fill"
                                [hiddenClearButton]="true">
                            <div suffix>
                                <mat-icon>search</mat-icon>
                            </div>
                        </app-auto-complete-form>
                    </div>
                </form>
            </mat-card-subtitle>
            <div style="max-width: 100% ;overflow: hidden">
                <cdk-virtual-scroll-viewport itemSize="50" class="container-schede-manutenzione scrollbar-visible">
                    <div class="flex-center-width100 flex-flow-column">
                        <div class="flex-start-start flex-flow-row container-child-width">
                            <div *cdkVirtualFor="let schedeManutenzione of schedeManutenzione|filterByPredicateFunction:predicateFunction:formFilter.get('progetti').value; templateCacheSize: 0"
                                 class="dimension-card-maintenance">
                                <app-box-schede-manutenzione
                                        [settingDataDiScadenza]="getCaelndario(schedeManutenzione)"
                                        (dataDiScadenza)="dataDiScadenza($event,schedeManutenzione)"
                                        (clickCard)="clickSchedaManutenzione(schedeManutenzione)"
                                        [visualizedMenu]="false"
                                        [selected]="isSelectedSchedaManutenzione(schedeManutenzione)"
                                        [disabled]="schedeManutenzione.isOnlyOnce && !this.getCaelndario(schedeManutenzione)"
                                        [schedeManutenzione]="schedeManutenzione"
                                >
                                </app-box-schede-manutenzione>
                            </div>
                        </div>
                    </div>
                </cdk-virtual-scroll-viewport>
            </div>
        </mat-card>
    </div>
    <div dx class="half-screen-wrapper">
        <mat-card class="margin-card-title">
            <mat-card-subtitle class="title-size">
                {{titleTraduction|translate}}
            </mat-card-subtitle>
        </mat-card>
        <mat-card>
            <div class="scroll scrollbar-visible" *ngIf="isSetDataSource">
                <table mat-table [dataSource]="dataSource" class="table">
                    <!-- Implicit Column -->
                    <ng-container [matColumnDef]="col" *ngFor="let col of displayedColumns">
                        <th mat-header-cell *matHeaderCellDef> {{getPathTraductionWithKey(col)|translate}} </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container [ngSwitch]="col">
                                <ng-container *ngSwitchCase="'circuito'">
                                    {{getValueCircuito(data[col])}}
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    {{getValue(data, col)}}
                                </ng-container>
                            </ng-container>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                        [ngClass]="{'selected-row': schedaIsInElement(row)}"></tr>
                </table>
            </div>
        </mat-card>
    </div>
</app-split-screen>
    <div style="width: 100%; display: flex; justify-content: end; margin-top: 8px;">
        <button (click)="closeDialog(false)" mat-raised-button style="margin-right: 16px;">
            <mat-icon>clear</mat-icon>
        </button>
        <button [matBadge]="removedSchedeManutenzioneLength()"
                matBadgePosition="before"
                matBadgeColor="accent"
                [matBadgeDisabled]="removedSchedeManutenzioneLength()===0"
                [disabled]="removedSchedeManutenzioneLength()===0"
                (click)="removeSchedule()" mat-raised-button color="warn"  style="margin-right: 16px;">
            <mat-icon>remove_task</mat-icon>
        </button>
        <button [matBadge]="selectedSchedeManutenzione.length.toString()"
                matBadgePosition="before"
                matBadgeColor="accent"
                [matBadgeDisabled]="selectedSchedeManutenzione.length===0"
                [disabled]="selectedSchedeManutenzione.length===0"
                (click)="closeDialog(true)" mat-raised-button color="primary" style="margin-right: 8px;">
            <mat-icon>add_task</mat-icon>
        </button>
    </div>