import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {formFiledMaintenance, typeFormFieldMaintenance} from "../../../providers/services/maintenance.service";
import {arrayIsSet, getItemInArrayByKeyValue, isNotNullOrUndefined, stringIsSet} from '../../../models/Models';
import {Subscription} from "rxjs";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {HunaValidators} from "../../../providers/validators/HunaValidators";

@Component({
    selector: 'app-add-form-field',
    templateUrl: './add-form-field.component.html',
    styleUrls: ['./add-form-field.component.scss']
})
export class AddFormFieldComponent implements OnInit, OnDestroy {
    public typeFormField = typeFormFieldMaintenance;

    formType: UntypedFormGroup;

    subscriptions: Subscription[] = [];

    constructor(public dialogRef: MatDialogRef<AddFormFieldComponent>,
                @Inject(MAT_DIALOG_DATA)
                public data: { traductionSaved: string[], fieldValue: formFiledMaintenance },
                private fb: UntypedFormBuilder,) {
        const objForm = {};
        objForm['traduction'] = [{
            value: null,
            disabled: false
        }, Validators.required]
        objForm['note'] = [{
            value: null,
            disabled: false
        },]
        objForm['type'] = [{
            value: null,
            disabled: false
        }, Validators.required]
        objForm['required'] = [{
            value: false,
            disabled: false
        }]
        objForm['possibleValues'] = [{
            value: [],
            disabled: false
        }]
        objForm['formPossibleValues'] = this.fb.group({
            possibleValue: [{
                value: null,
                disabled: false
            }]
        })
        this.formType = this.fb.group(objForm);
        const subscription = this.formType.get('type').valueChanges
            .pipe(
                debounceTime(200),
                distinctUntilChanged()
            )
            .subscribe(type => {
                if (this.visualizedAddOption) {
                    this.formType.get('possibleValues').setValidators(HunaValidators.arrayIsSet())
                    this.formType.get('possibleValues').updateValueAndValidity()
                } else {
                    this.formType.get('possibleValues').clearValidators()
                    this.formType.get('possibleValues').updateValueAndValidity()
                }
                if (type == typeFormFieldMaintenance.checkBox) {
                    this.formType.get('required').setValue(true);
                    this.formType.get('required').disable();
                } else {
                    this.formType.get('required').reset();
                    this.formType.get('required').enable();
                }

            })
        this.subscriptions.push(subscription)

    }

    ngOnInit(): void {
        this.formType.get('traduction').setValidators([HunaValidators.notInArray(this.data.traductionSaved, null), Validators.required])
        if (isNotNullOrUndefined(this.data.fieldValue)) {
            Object.keys(this.data.fieldValue).forEach(key => {
                if (this.formType.get(key)) {
                    this.formType.get(key).setValue(this.data.fieldValue[key], {emitEvent: false})
                }
            })
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe())
    }

    confirm(openNew = false) {
        const response = this.formType.value;
        const keyRequired = 'required';
        if (this.formType.get(keyRequired).disabled && isNotNullOrUndefined(this.formType.getRawValue())) {
            response[keyRequired] = isNotNullOrUndefined(this.formType.getRawValue()[keyRequired]) ? this.formType.getRawValue()[keyRequired] : null;
        }
        delete response.formPossibleValues;
        if (!this.visualizedAddOption) {
            response.possibleValues = undefined;
        }
        this.dialogRef.close({value: response, openNew})
    }

    getAutoComplete(values: string[]) {
        return values.map(value => {
            return {html: value, valueForm: value}
        })
    }

    get visualizedAddOption() {
        return this.formType.get('type').value == typeFormFieldMaintenance.multiSelect || this.formType.get('type').value == typeFormFieldMaintenance.autoComplete
    }


    get possibleValues() {
        if (this.formType.get('possibleValues') && Array.isArray(this.formType.get('possibleValues').value)) {
            return this.formType.get('possibleValues').value
        } else {
            return []
        }
    }

    remove(item) {
        if (this.formType.get('possibleValues') && Array.isArray(this.formType.get('possibleValues').value)) {
            const possibleValues = this.formType.get('possibleValues').value
            const index = possibleValues.findIndex(value => value == item);
            if (index >= 0) {
                possibleValues.splice(index, 1)
            }
            if (arrayIsSet(possibleValues)) {
                this.formType.get('possibleValues').setValue(possibleValues)
            } else {
                this.formType.get('possibleValues').reset()
            }
        }
    }

    addPossibleValue() {
        const value = this.formType.get('formPossibleValues').get('possibleValue').value;
        if (stringIsSet(value) && !this.isPresentPossibleValues) {
            const possibleValues = [...this.possibleValues]
            possibleValues.push(value)
            this.formType.get('possibleValues').setValue(possibleValues)
        }
        this.formType.get('formPossibleValues').get('possibleValue').reset()
    }


    get isPresentPossibleValues() {
        const value = this.formType.get('formPossibleValues').get('possibleValue').value;
        return isNotNullOrUndefined(getItemInArrayByKeyValue(this.possibleValues, value))
    }


    getErrors(key, type: 'isPresentInArray') {
        return isNotNullOrUndefined(this.formType.get(key).errors) && isNotNullOrUndefined(this.formType.get(key).errors[type])
    }

    resetForm(key: string) {
        this.formType.get(key).reset();
    }
}
