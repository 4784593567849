import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {SegnaliTlcNodoChangesHistoryParse} from "../../../models/SegnaliTlcNodoChangesHistory.Parse";


@Component({
    selector: 'app-history-light-point',
    templateUrl: './history-light-point.component.html',
    styleUrls: ['./history-light-point.component.scss']
})
export class HistoryLightPointComponent implements OnInit, OnChanges, AfterViewInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    @Input() historyLightPoint: SegnaliTlcNodoChangesHistoryParse[];
    @Input() isSviluppatore: boolean;
    displayedColumns: string[] = ['updatedAt', 'lampadaFunzionante'];
    dataSource: MatTableDataSource<SegnaliTlcNodoChangesHistoryParse>;

    constructor() {
    }

    get historyLightPointIsSet() {
        return Array.isArray(this.historyLightPoint) && this.historyLightPoint.length > 0
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    ngOnInit(): void {
        this.dataSource = new MatTableDataSource(this.historyLightPoint);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.isSviluppatore) {
            if (this.isSviluppatore) {
                this.displayedColumns = ['updatedAt', 'lampadaFunzionante', 'fase'];
            }
        }
    }

    classColorLampadaFunzionantePredicate(row: SegnaliTlcNodoChangesHistoryParse) {
        const lampadaFunzionate = row.lampadaFunzionante;
        const problemaDispositivoTelecontrollo = row.problemaDispositivoTelecontrollo
        if (lampadaFunzionate == null && problemaDispositivoTelecontrollo == null) {
            return ['lampadaUndefined']
        } else if (lampadaFunzionate) {
            if (problemaDispositivoTelecontrollo) {
                return ['lampadaDisconnessa']
            } else {
                return ['lampadaOn']
            }
        } else {
            return ['lampadaOff']
        }
    }
}




