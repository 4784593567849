import * as Parse from 'parse';


export class ComandiParse extends Parse.Object {
  public static CLASS_NAME = 'Comandi';
  constructor() {
    super(ComandiParse.CLASS_NAME);
  }

  public set objectId(value) {
    this.id = value;
  }

  public get objectId() {
    return this.id;
  }

  public set updatedAt(value) {
    super.set('updatedAt', value);
  }
  public get updatedAt() {
    return super.get('updatedAt');
  }

  public set createdAt(value) {
    super.set('createdAt', value);
  }
  public get createdAt() {
    return super.get('createdAt');
  }

  public set ACL(value) {
    super.set('ACL', value);
  }
  public get ACL() {
    return super.get('ACL');
  }

  public set circuito(value) {
    super.set('circuito', value);
  }
  public get circuito() {
    return super.get('circuito');
  }

  public set comando(value) {
    super.set('comando', value);
  }
  public get comando() {
    return super.get('comando');
  }

  public set isShellCommand(value) {
    super.set('isShellCommand', value);
  }
  public get isShellCommand() {
    return super.get('isShellCommand');
  }

  public set messaggio(value) {
    super.set('messaggio', value);
  }
  public get messaggio() {
    return super.get('messaggio');
  }

  public set puntoLuce(value) {
    super.set('puntoLuce', value);
  }
  public get puntoLuce() {
    return super.get('puntoLuce');
  }

  public set ricevuto(value) {
    super.set('ricevuto', value);
  }
  public get ricevuto() {
    return super.get('ricevuto');
  }
}

Parse.Object.registerSubclass(ComandiParse.CLASS_NAME, ComandiParse);
