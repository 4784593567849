import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ChangeDetectorRef,
    OnDestroy,
    OnChanges,
    SimpleChanges
} from "@angular/core";
import {UntypedFormGroup, FormControl, UntypedFormBuilder} from "@angular/forms";
import {BehaviorSubject, combineLatest, Observable, Subscription} from "rxjs";
import {debounceTime, map, startWith} from "rxjs/operators";
import {DomSanitizer} from "@angular/platform-browser";
import {arrayIsSet} from "../../models/Models";
import {statusStreetInQueue} from "../../providers/services/streets.service";
import {dataForm} from "../../components/confirm-delete/select-or-create/select-or-create.component";
import {Priority} from "../../../config/static-data";

@Component({
    selector: "app-paginator",
    templateUrl: "./paginator.component.html",
    styleUrls: ["./paginator.component.scss"],
})
export class PaginatorComponent implements OnInit, OnChanges, OnDestroy {
    searchForm: UntypedFormGroup;
    public keyChipsActivedFilter: dataForm[] = [
        {
            valueForm: 'isReady',
            cssClass: 'background-green',
            traduction: 'isReady',
            html: undefined
        }, {
            valueForm: 'isWaiting',
            color: 'primary',
            traduction: 'isWaiting',
            html: undefined
        }, {
            valueForm: 'isError',
            color: 'warn',
            traduction: 'isError',
            html: undefined
        }, {
            valueForm: 'isOther',
            cssClass: 'background-other-color',
            traduction: 'other',
            html: undefined
        }, {
            valueForm: 'isSovraIlluminato',
            color: 'accent',
            traduction: 'isSovraIlluminato',
            html: undefined
        },
    ]
    private keysButtonSearchForm = this.keyChipsActivedFilter
        .map(v => v.valueForm)
    private searchFormSubscription: Subscription;


    @Input()
    searchEnabled: boolean = true;


    @Input()
    forceUpdate: boolean;
    @Input()
    selectItem: boolean;
    @Input()
    clickableRows: boolean = true;
    @Input()
    filter: Filter = {search: "", filterActived: this.keysButtonSearchForm};
    @Input()
    order: ColumnsOrdering = {};
    @Input()
    take: number = 10;
    @Input()
    pageSizeOptions: number[] = [this.take];
    @Input()
    skip: number = 0;
    @Input()
    columns: ColumnConfig[] = [];
    @Input()
    dataSource: AsyncDataSource = () => Promise.resolve({items: [], total: 0});
    @Input()
    errorKey: string = "paginator.error";
    @Input()
    noResultKey: string = "paginator.no_result_found";
    @Input() objectValue: any
    @Input() transformFunction: (...args) => any
    @Output()
    itemClick = new EventEmitter<object>();
    @Output()
    clickPhotometry = new EventEmitter<any>();
    @Output()
    clickGroupPhotometry = new EventEmitter<any>();
    @Output()
    clickOtherProperty = new EventEmitter<{ element, column: string, value }>();
    @Output() clickCheck = new EventEmitter();

    visibleColumns: ColumnConfig[] = [];
    form: UntypedFormGroup | undefined;
    subscriptionsForm: Subscription;

    minPageSize = Math.min(...this.pageSizeOptions, 10);

    checkAll = false;

    get visibleColumnLabels() {
        return this.visibleColumns.map(x => x.label);
    }

    get visibleColumnProperties() {
        return this.visibleColumns.map(x => x.property);
    }

    get columnProperties() {
        return this.columns.map(x => x.property);
    }

    error: boolean = false;
    errorMessage: String;
    loading: boolean = true;
    items: object[];
    totalCount: number = 0;
    otherValueCountEmit = new BehaviorSubject<{ isReady?: number, isError?: number, isWaiting?: number, isOther?: number, isSovraIlluminato?: number }>({});
    otherValueCount$: Observable<{ isReady?: number, isError?: number, isWaiting?: number, isOther?: number }> = this.otherValueCountEmit.asObservable();
    searchFilter$: Observable<{ isReady, isError, isWaiting, isOther }>

    constructor(
        private sanitizer: DomSanitizer,
        private formBuilder: UntypedFormBuilder,
        private changeDetection: ChangeDetectorRef) {
    }

    getHtml(html) {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }

    onChangePage(event) {
        const pageIndex = event.pageIndex
        const pageSize = event.pageSize
        this.take = pageSize;
        this.skip = pageIndex * this.take;
        this.update();
    }

    // Utile per futuro supporto a ordinamenti multipli, sostituita da funzione onSortChange
    /*
    private readonly sortSequence: OrderingDirection[] = [
      "",
      "asc",
      "desc",
    ];
    onColumnClick(e: MouseEvent, property: string) {
      if (!(e.ctrlKey || e.shiftKey)) {
        this.order = {};
      }
      this.order[property] = this.sortSequence[
        (this.sortSequence.indexOf(this.order[property]) + 1) %
          this.sortSequence.length
      ];
      this.update();
    }*/
    getFormByItem(items: any[]) {
        const obj = {}
        const otherKeys = this.columns
            .filter(col => col.type === 'checkBox')
            .map(col => col.property);
        if (arrayIsSet(items)) {
            items.forEach(item => {
                const subForm = {checkBox: false};
                if (this.form && this.form.get(item.objectId) && this.form.get(item.objectId).get('checkBox') && this.form.get(item.objectId).get('checkBox').value) {
                    subForm.checkBox = true;
                }
                otherKeys.forEach(key => {
                    subForm[key] = item[key]
                });
                obj[item.objectId] = this.formBuilder.group(subForm)
            })
        }
        return this.form = this.formBuilder.group(obj);
    }

    onSortChange({active, direction}: { active: string, direction: OrderingDirection }) {
        this.order = {
            [active]: direction
        };
        this.update();
    }

    onSearch(term: { search: string, isReady: boolean, isWaiting: boolean, isError: boolean }) {
        this.filter = term;
        this.update();
    }

    ngOnInit() {
        if (this.searchEnabled) {
            this.searchForm = this.formBuilder.group({
                search: "",
                filterActived: [this.filter.filterActived]
            });
            this.searchFilter$ = this.searchForm.get('filterActived').valueChanges.pipe(
                startWith(this.searchForm.get('filterActived').value),
                map(values => {
                    if (arrayIsSet(values)) {
                        return this.keysButtonSearchForm.reduce((prev, currentKey) => {
                            prev[currentKey] = values.includes(currentKey);
                            return prev;
                        }, {}) as { isReady, isError, isWaiting, isOther }
                    } else {
                        return this.keysButtonSearchForm.reduce((prev, currentKey) => {
                            prev[currentKey] = false;
                            return prev;
                        }, {}) as { isReady, isError, isWaiting, isOther }
                    }
                })
            )
            this.searchFormSubscription = this.searchForm
                .valueChanges.pipe(debounceTime(400))
                .subscribe((term) => {
                    this.onSearch(term);
                });

        }
        this.visibleColumns = this.columns.filter((col) =>
            col.visible === undefined ? true : col.visible
        );
        if (this.selectItem) {
            this.visibleColumns.unshift({
                type: 'checkBox',
                property: 'checkBox',
                label: '',
                orderable: false,
                visible: true,
                sticky: true
            })
        }
        if (this.objectValue != null && this.transformFunction != null) {
            this.visibleColumns.unshift({
                type: 'transformValue',
                property: 'transformValue',
                label: '',
                orderable: false,
                visible: true,
                sticky: true
            })
        }
        this.update();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.forceUpdate) {
            this.update();
        }
    }

    ngOnDestroy(): void {
        if (this.searchFormSubscription) {
            this.searchFormSubscription.unsubscribe();
        }
        if (this.subscriptionsForm != null) {
            this.subscriptionsForm.unsubscribe();
        }
    }

    onItemClick(item) {
        this.itemClick.emit(item);
    }

    async update() {
        try {
            this.error = false;
            this.loading = true;
            const {items, total} = await this.dataSource(
                this.filter,
                this.take,
                this.skip,
                this.order
            );
            if (this.subscriptionsForm != null) {
                this.subscriptionsForm.unsubscribe();
            }
            this.checkAll = false;
            this.form = this.getFormByItem(items);
            const changesValue: Observable<any>[] = [];
            items.forEach(item => {
                const changeValue$ = this.form.get((item as any).objectId).get('checkBox').valueChanges
                    .pipe(
                        startWith(this.form.get((item as any).objectId).get('checkBox').value),
                        map((value) => {
                            return {...item, checked: value}
                        })
                    );
                changesValue.push(changeValue$)
            })
            this.subscriptionsForm = combineLatest(changesValue).pipe(
                map((values) => {
                    return values.filter(value => value.checked == true)
                })
            )
                .subscribe(ids => {
                    this.clickCheck.emit(ids)
                })
            this.form.updateValueAndValidity()
            this.items = items;
            this.totalCount = total;
            if (this.objectValue != null && arrayIsSet(Object.keys(this.objectValue)) && arrayIsSet(this.items)) {
                let isReadyCount = 0;
                let isWaitingCount = 0;
                let isErrorCount = 0;
                let isSovraIlluminato = 0;
                this.items.forEach((item: any) => {
                    if (this.objectValue[item.objectId] != null) {
                        if (this.objectValue[item.objectId].status == statusStreetInQueue.WAITING || this.objectValue[item.objectId].status == statusStreetInQueue.PROCESSING) {
                            isWaitingCount++;
                        } else if (this.objectValue[item.objectId].status == statusStreetInQueue.ERROR) {
                            isErrorCount++;
                        }
                    } else if (arrayIsSet(item.fotometrie)) {
                        isReadyCount++;
                    }
                    if (item.noteCalcolo && item.noteCalcolo.sovrailluminato == true) {
                        isSovraIlluminato++;
                    }
                });
                this.otherValueCountEmit.next({
                    isReady: isReadyCount,
                    isWaiting: isWaitingCount,
                    isError: isErrorCount,
                    isOther: this.items.length - isWaitingCount - isErrorCount - isReadyCount,
                    isSovraIlluminato: isSovraIlluminato
                })
            } else if (arrayIsSet(this.items)) {
                const isReady = this.items.filter((item: any) => arrayIsSet(item.fotometrie)).length
                const isSovraIlluminato = this.items.filter((item: any) => item.noteCalcolo && item.noteCalcolo.sovrailluminato == true).length
                this.otherValueCountEmit.next({
                    isReady,
                    isWaiting: 0,
                    isError: 0,
                    isOther: this.items.length - isReady,
                    isSovraIlluminato
                })
            } else {
                this.otherValueCountEmit.next({
                    isReady: 0,
                    isWaiting: 0,
                    isError: 0,
                    isOther: 0,
                    isSovraIlluminato: 0
                })
            }

        } catch (e) {
            this.error = true;
            this.errorMessage = e.message;
            console.log(e);
            return e;
        } finally {
            this.loading = false;
        }
        this.changeDetection.detectChanges();
    }

    clickPhotometryEmit(element) {
        this.clickPhotometry.emit(element);
    }

    clickGroupPhotometryEmit(element) {
        this.clickGroupPhotometry.emit(element);
    }

    clickOtherPropertyEmit(element, column, value) {
        if (column != 'checkBox') {
            this.clickOtherProperty.emit({element, column, value})
        }
    }

    clickChecBoxAll(checked) {
        Object.keys(this.form.value).forEach(key => this.form.get(key).get('checkBox').setValue(checked, {emitEvent: true}))
        this.checkAll = checked;
        // this.clickCheck.emit({element, checked})
    }


    predicateFilter(item: any, filter: any, objectValues: any) {
        const filterBoolIsSet = (filter.isError == false || filter.isWaiting == false || filter.isReady == false || filter.isOther == false) && objectValues != null
        let holdStreet = true;

        if (filterBoolIsSet) {
            if (filter.isOther == false && holdStreet) {
                holdStreet = !(objectValues[item.objectId] == null && !arrayIsSet(item.fotometrie))
            }
            if (filter.isReady == false && holdStreet) {
                holdStreet = !(objectValues[item.objectId] == null && arrayIsSet(item.fotometrie))
            }
            if (!filter.isError && holdStreet) {
                holdStreet = !(objectValues[item.objectId] != null && objectValues[item.objectId].status == statusStreetInQueue.ERROR)
            }
            if (!filter.isWaiting && holdStreet) {
                holdStreet = !(objectValues[item.objectId] != null && (objectValues[item.objectId].status == statusStreetInQueue.WAITING || objectValues[item.objectId].status == statusStreetInQueue.PROCESSING))
            }
        }
        if (filter.isSovraIlluminato == true && !holdStreet) {
            holdStreet = (item.noteCalcolo != null && item.noteCalcolo.sovrailluminato == true);
        }
        return holdStreet;
    }

    clickChips(event: any[]) {
        event.forEach(key => {
            if (this.searchForm.get(key) && this.searchForm.get(key).value == false) {
                this.searchForm.get(key).setValue(true)
            }
        })
        console.log(event);
    }
}

export interface ColumnConfig {
    property: string;
    label: string;
    render?: (x: any) => string;
    orderable?: boolean;
    visible?: boolean;
    photometryGroup?: boolean;
    type?: string;
    textAlign?: string
    width?: string
    sticky?: boolean
}

export type OrderingDirection = "asc" | "desc" | "";

export type ColumnsOrdering = { [property: string]: OrderingDirection };

export interface Filter {
    search: string;
    filterActived?: string[];
}

export interface DataSourceResult {
    items: object[];
    total: number;
}

export type AsyncDataSource = (filter: Filter, take: number | 'all', skip: number, order: ColumnsOrdering) => Promise<DataSourceResult>;
