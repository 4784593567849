import {Component, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject, Subscription} from "rxjs";
import {SchedaManutenzioneParse} from "../../models/SchedaManutenzione.Parse";
import {formFiledMaintenance} from "../../providers/services/maintenance.service";
import {ProgettiParse} from "../../models/Progetti.Parse";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {DomSanitizer} from "@angular/platform-browser";
import {MatIconRegistry} from "@angular/material/icon";
import {typeFormValue} from "../../models/configurationProperty/configurationPropertyUtils";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {isNotNullOrUndefined} from "../../models/Models";

@Component({
    selector: 'app-box-schede-manutenzione',
    templateUrl: './box-schede-manutenzione.component.html',
    styleUrls: ['./box-schede-manutenzione.component.scss']
})
export class BoxSchedeManutenzioneComponent implements OnInit, OnDestroy {

    @Input() visualizedMoveInMenu = true;
    @Input() selected
    @Input() disabled
    @Input() schedeManutenzione: SchedaManutenzioneParse;
    @Input() visualizedMenu = true;
    @Input() selectionMode = false;
    @Output() clickCard = new Subject();
    @Output() dbClickCard = new Subject();
    @Output() clickMenu = new Subject<any>();
    @Input() settingDataDiScadenza: Date | undefined;
    @Output() dataDiScadenza = new Subject<any>();

    minDate: Date;

    formGroup: UntypedFormGroup;

    form: formFiledMaintenance[];
    periodo: number;
    note: string;
    name: string;
    progetti: ProgettiParse[];
    public typeFormGetFormField = typeFormValue
    public dataSourceProject: MatTableDataSource<ProgettiParse>;
    public displayColumnsProject: string[] = ['name', 'sigla', 'numeroPuntiLuce', 'numeroCircuiti'];

    private timerSingleClick;
    private preventSingleClick = false;

    private subscriptions: Subscription[] = []

    constructor(private matIconRegistry: MatIconRegistry,
                private domSanitizer: DomSanitizer,
                private fb: UntypedFormBuilder) {
        const today = new Date();
        today.setDate(today.getDate() + 1);
        today.setHours(0, 0, 0, 0);
        this.minDate = today;
        this.matIconRegistry.addSvgIcon(
            "copyEdit",
            this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icon/buttonUtilities/copyEdit.svg")
        );
    }

    ngOnInit(): void {
        this.form = (this.schedeManutenzione.form) ? this.schedeManutenzione.form : [];
        this.periodo = this.schedeManutenzione.periodo;
        this.progetti = (this.schedeManutenzione.progetti) ? this.schedeManutenzione.progetti : [];
        this.note = this.schedeManutenzione.note;
        this.name = this.schedeManutenzione.name;
        this.dataSourceProject = new MatTableDataSource<ProgettiParse>(this.progetti)
        if (this.showSetDataDiScadenza) {
            this.formGroup = this.fb.group({dataDiScadenza: null})
            const sub = this.formGroup.get('dataDiScadenza').valueChanges.subscribe(value => {
                this.dataDiScadenza.next(value);
            })
            this.subscriptions.push(sub)
        }
    }


    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe())
    }

    get disableButton() {
        return this.disabled;
    }


    get selectDisable() {
        if (this.disabled) {
            return 'card-disabled'
        } else if (this.selected) {
            return 'menu-select-card';
        } else if (!this.selectionMode) {
            return 'menu-accent-card'
        }
        return 'background-white';
    }

    get cursor() {
        return (this.disabled) ? 'cursor-default' : 'cursor-pointer';
    }


    get colorIcon() {
        if (this.disabled) {
            return 'disable';
        } else if (this.selected) {
            return 'primary';
        } else if (!this.selectionMode) {
            return 'accent'
        }
        return '';
    }

    get colorIconImage() {
        return 'img-icon-color-' + this.colorIcon;
    }

    singleCardClick() {
        if (!this.disabled) {
            this.preventSingleClick = false;
            this.timerSingleClick = setTimeout(() => {
                if (!this.preventSingleClick) {
                    this.clickCard.next(true)
                    //Navigate on single click
                }
            }, 200)
        }
    }

    dbCardClick() {
        if (!this.disabled) {
            if (isNotNullOrUndefined(this.timerSingleClick)) {
                this.preventSingleClick = true;
                clearTimeout(this.timerSingleClick);
            }
            this.dbClickCard.next(['asdsaf', 'fddsadf'])
        }
    }

    getValueHtmlProgetto(progetto: ProgettiParse, key: string) {
        return progetto[key]
    }

    updateBeforeImport() {
        this.clickMenu.next({copyData: true})
    }

    get colorText() {
        return 'color-' + this.colorIcon
    }

    get showSetDataDiScadenza() {
        return this.schedeManutenzione.isOnlyOnce
    }

}
