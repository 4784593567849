import {Directive, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Directive({
    selector: '[appCompletedNgFor]'
})
export class CompletedNgForDirective implements OnInit {

    @Input() lastElement: any;
    @Output() completed = new EventEmitter();

    constructor() {
        // somehow lastElem value is available on next tick
    }

    ngOnInit(): void {
        if (this.lastElement) {
            this.completed.emit(this.lastElement);
        }
    }
}
