<form [formGroup]="labelForm" style="margin: 5px 8px">
    <app-auto-complete-form
        appearance="outline"
        style="width: 100%;"
        formControlName="label"
        [hiddenClearButton]="false"
        [title]="'titleSetting'|translate" 
        [items]="circuitAutocompleteFields"
    >
    </app-auto-complete-form>
</form>
<ng-container *ngIf="!!numeroElementiPerValore">
    <app-huna-card>
        <mat-grid-list cols="24" [rowHeight]="rowHeight">
            <mat-grid-tile [colspan]="19"
                           [rowspan]="1">
                <b>{{'value'|translate}}</b>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="5"
                           [rowspan]="1">
                <b style="text-align: center;width: 100%">{{'number'|translate}}</b>
            </mat-grid-tile>
            <ng-container *ngFor="let key of numeroElementiPerValore |keyvalue">
                <mat-grid-tile [colspan]="19"
                               [rowspan]="1">
                    <p style="font-size: 14px">{{key.key|sostituisciUndercoreConSpazi}}</p>
                </mat-grid-tile>
                <mat-grid-tile [colspan]="5"
                               [rowspan]="1">
                    <p style="font-size: 14px;text-align: center;width: 100%">{{key.value}}</p>
                </mat-grid-tile>
            </ng-container>
        </mat-grid-list>
    </app-huna-card>
</ng-container>