import {Injectable} from '@angular/core';
import {Observable,} from "rxjs";
import {map} from "rxjs/operators";
import {ClusterIconStyle} from "@angular/google-maps";
import {arrayIsSet} from "../../models/Models";
import {ClusterType} from "../../components/component-with-map/main-map/main-map.component";

declare var google: any;

@Injectable({
    providedIn: 'root'
})
export class GoogleServiceService {
    get browserSupportGeolocation(): boolean {
        return this.geoGeoLocation != null;
    }

    get geoGeoLocation(): Geolocation {
        return navigator.geolocation;
    }

    customError = {
        browerNotSupported: {code: 10001, message: 'unavaibleForThisBrowser'},
        timeOut: {code: 10002, message: 'timeOut'}
    }

    constructor() {
    }

    public midPoint(SW, NE) {
        const locSw = new google.maps.LatLng(SW.latitude, SW.longitude);
        const locNe = new google.maps.LatLng(NE.latitude, NE.longitude);
        const bounds = new google.maps.LatLngBounds();
        bounds.extend(locSw);
        bounds.extend(locNe);
        return bounds.getCenter();
    }

    public midPointFromPoints(points: { location: { latitude: number, longitude: number } }[]) {
        const bounds = new google.maps.LatLngBounds();
        points.forEach(p => {
            const locSw = new google.maps.LatLng(p.location.latitude, p.location.longitude);
            bounds.extend(locSw);
        })
        const center = bounds.getCenter()
        const lat = center.lat()
        const lng = center.lng()
        return {lat, lng};
    }

    public getNeSW(values: any[]) {
        const bounds = new google.maps.LatLngBounds();
        values.forEach(value => {
            const locSw = new google.maps.LatLng(value.location.latitude, value.location.longitude);
            bounds.extend(locSw);
        })
        return {NE: bounds.getNorthEast(), SW: bounds.getSouthWest(), center: bounds.getCenter()};
    }

    private static getImageClusterLightPoint(color) {
        var encoded = window.btoa('<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-100 -100 200 200"><defs><g id="a" transform="rotate(45)"><path d="M0 47A47 47 0 0 0 47 0L62 0A62 62 0 0 1 0 62Z" fill-opacity="0.7"/><path d="M0 67A67 67 0 0 0 67 0L81 0A81 81 0 0 1 0 81Z" fill-opacity="0.5"/><path d="M0 86A86 86 0 0 0 86 0L100 0A100 100 0 0 1 0 100Z" fill-opacity="0.3"/></g></defs><g fill="' + color + '"><circle r="42"/><use xlink:href="#a"/><g transform="rotate(120)"><use xlink:href="#a"/></g><g transform="rotate(240)"><use xlink:href="#a"/></g></g></svg>');
        return ('data:image/svg+xml;base64,' + encoded);
    }

    public getIconClusterLightPoint(color): ClusterIconStyle[] {
        let textColor: string = '#ffffff';
        if (color == '#000000') {
            textColor = '#DDDDDD';
        }
        return [
            {
                width: 70,
                height: 70,
                url: GoogleServiceService.getImageClusterLightPoint(color),
                textColor: textColor,
                textSize: 12,
                anchorText: [29, 0]
            }
        ];
    }

    newKmlLayer(src, map) {
        // const src = 'https://developers.google.com/maps/documentation/javascript/examples/kml/westcampus.kml';
        const kmlLayer = new google.maps.KmlLayer(src, {
            suppressInfoWindows: true,
            preserveViewport: false,
            map: map
        });
    }

    newPoint(x, y): google.maps.Point {
        return new google.maps.Point(x, y);
    }

    isBetween(start, end, point, numberDecimalTollarance = 9) {
        const path = [
            new google.maps.LatLng(start.latitude, start.longitude),
            new google.maps.LatLng(end.latitude, end.longitude),
        ];
        const line = new google.maps.Polyline();
        line.setPath(path);
        return google.maps.geometry.poly.isLocationOnEdge(
            point.latLng,
            line,
            Math.pow(10, -numberDecimalTollarance)
        );
    }

    getPointAtFixedDistanceBetweenTwoPoint(LatLngP1, LatLngP2, distanceInKm) {
        const heading = google.maps.geometry.spherical.computeHeading(LatLngP1, LatLngP2);
        const p = google.maps.geometry.spherical.computeOffset(LatLngP1, distanceInKm * 1000, heading);
        return p;
    }

    newLatLng(latitude: number, longitude: number): google.maps.LatLng {
        return new google.maps.LatLng(latitude, longitude);
    }

    getPointAtFixedDistanceAndAngle(p1: google.maps.LatLng, distanceInKm, angle: number): google.maps.LatLng {
        return google.maps.geometry.spherical.computeOffset(p1, distanceInKm * 1000, angle);
    }


    getCurrentPosition$(): Observable<GeolocationPosition> {
        return this.getWatchPosition$(true).pipe(
            map(geoPositionWatchPostionId => {
                this.destroyWatchPosition(geoPositionWatchPostionId.watchPositionId);
                return geoPositionWatchPostionId.geoPosition;
            }));
    }

    getWatchPosition$(oneEvent = false): Observable<{ geoPosition: GeolocationPosition, watchPositionId: number }> {
        const options = {
            enableHighAccuracy: true,
        };
        return new Observable(subscriber => {
            if (this.browserSupportGeolocation) {
                const success = (event: GeolocationPosition) => {
                    subscriber.next({geoPosition: event, watchPositionId});
                    if (oneEvent) {
                        this.destroyWatchPosition(watchPositionId);
                        subscriber.complete();
                        subscriber.unsubscribe();
                    }
                }
                const error = (error) => {
                    subscriber.error(error);
                    subscriber.complete();
                    subscriber.unsubscribe();
                    this.destroyWatchPosition(watchPositionId);
                }
                const watchPositionId = this.geoGeoLocation.watchPosition(success, error, options)
            } else {
                subscriber.error(this.customError.browerNotSupported);
                subscriber.complete();
                subscriber.unsubscribe();
            }
        })
    }

    destroyWatchPosition(watchPositionId) {
        if (this.browserSupportGeolocation && watchPositionId != null) {
            this.geoGeoLocation.clearWatch(watchPositionId)
        }
    }

    visualizedToExternalMap(location: Parse.GeoPoint) {
        return 'https://www.google.com/maps/search/?api=1&query=' + location.latitude + ',' + location.longitude;
    }

    /**
     *
     * @param ne
     * @param sw
     * @param center
     * @param quattroN=1 restituisce la mappa divisa in 4, quattroN=2 in 16,quattroN=3 diviso in 64,
     */
    tilesMap(bounds: google.maps.LatLngBounds, quattroN = 1): google.maps.LatLngBounds[] {
        const getTilesInBounds = (tile: google.maps.LatLngBounds) => {
            const ne = tile.getNorthEast();
            const sw = tile.getSouthWest();
            const center = tile.getCenter();
            const tilesInBounds = []
            tilesInBounds.push(
                new google.maps.LatLngBounds(
                    new google.maps.LatLng(center.lat(), sw.lng()),
                    new google.maps.LatLng(ne.lat(), center.lng()),
                )
            );
            tilesInBounds.push(
                new google.maps.LatLngBounds(
                    new google.maps.LatLng(center.lat(), center.lng()),
                    new google.maps.LatLng(ne.lat(), ne.lng()),
                )
            );
            tilesInBounds.push(
                new google.maps.LatLngBounds(
                    new google.maps.LatLng(sw.lat(), sw.lng()),
                    new google.maps.LatLng(center.lat(), center.lng()),
                )
            );
            tilesInBounds.push(
                new google.maps.LatLngBounds(
                    new google.maps.LatLng(sw.lat(), center.lng()),
                    new google.maps.LatLng(center.lat(), ne.lng()),
                )
            );
            return tilesInBounds;
        }
        let tiles = [bounds];
        let i = 1;
        while (i <= quattroN) {
            tiles = tiles.reduce((prev, tile) => {
                prev = prev.concat(getTilesInBounds(tile));
                return prev;
            }, []);
            i++;
        }
        return tiles;
    }

    splitClustersWithEqualsCenter(clustersInMapToSplit) {
        const getIdCluster = (center: google.maps.LatLng) => {
            const lat = center.lat();
            const lng = center.lng();
            return lat + '' + lng;
        }
        let clustersInMap = clustersInMapToSplit
            .map(cluster => {
                const boundsRaw = cluster.bounds;
                const center = new google.maps.LatLng(cluster.center.latitude, cluster.center.longitude);
                const northEast = new google.maps.LatLng(boundsRaw.northEast.latitude, boundsRaw.northEast.longitude);
                const southWest = new google.maps.LatLng(boundsRaw.southWest.latitude, boundsRaw.southWest.longitude);
                cluster.bounds = new google.maps.LatLngBounds(southWest, northEast);
                cluster.center = center;
                return cluster
            })
        let clusterWithEqualCenter = clustersInMapToSplit.reduce((prev, current) => {
            if (current.center) {
                const id = getIdCluster(current.center);
                if (prev[id] == null) {
                    prev[id] = []
                }
                prev[id].push(current);
            }
            return prev
        }, {});

        clusterWithEqualCenter = Object.keys(clusterWithEqualCenter).reduce((prev, current) => {
            if (clusterWithEqualCenter[current].length > 1) {
                prev[current] = clusterWithEqualCenter[current];
            }
            return prev
        }, {});
        Object.keys(clusterWithEqualCenter).forEach(key => {
            let allIndex = [];
            clustersInMapToSplit.forEach((cluster, index) => {
                if (key === getIdCluster(cluster.center)) {
                    allIndex.push(index)
                }
            })
            if (arrayIsSet(allIndex)) {
                const sliceAngle = 360 / allIndex.length
                clusterWithEqualCenter[key].forEach((cluster, index) => {
                    const indexCluster = allIndex[index]
                    const newCenter = this.getPointAtFixedDistanceAndAngle(cluster.center, cluster.radius / 1000, sliceAngle * index)
                    clustersInMapToSplit[indexCluster].center = newCenter;
                })
            }
        })

        return clustersInMap
            .map((cluster: ClusterType) => {
                const boundsByLocationsMarker = cluster.locations.reduce((bounds, location) => {
                    const loc = new google.maps.LatLng(location.latitude, location.longitude);
                    bounds.extend(loc);
                    return bounds;
                }, new google.maps.LatLngBounds())
                cluster.bounds = boundsByLocationsMarker;
                cluster.center = boundsByLocationsMarker.getCenter();
                return cluster
            });
    }

}
