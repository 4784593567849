import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {PuntiLuceParse} from "../../../models/PuntiLuce.Parse";
import {CircuitiParse} from "../../../models/Circuiti.Parse";
import {AggregateDataService, DataRawReportAggregate} from "../../../providers/services/aggregate-data.service";
import {TranslateService} from "@ngx-translate/core";
import {arrayIsSet} from "../../../models/Models";

@Component({
    selector: 'app-aggregate-data-report',
    templateUrl: './aggregate-data-report.component.html',
    styleUrls: ['./aggregate-data-report.component.scss']
})
export class AggregateDataReportComponent implements OnInit, OnChanges {
    @Input() aggregateDataReport: DataRawReportAggregate;
    @Input() loading: boolean = false;
    @Input() showDate = true;
    lightPoint: PuntiLuceParse;
    circuit: CircuitiParse;
    chartReady;
    getChartData;

    constructor(private aggregateDataService: AggregateDataService,
                private ts: TranslateService) {
        this.getChartData = (profile: { time: number, power: number }[], lastDayOpened, pointRadius = 0, maintainAspectRatio = false) => {
            const title = {
                x: this.ts.instant('lightMate.hours'),
                y: this.ts.instant('lightMate.power'),
                labelDataSet: this.ts.instant('lightMate.functionProfile')
            }
            return this.aggregateDataService.getDataChartProfile(profile, lastDayOpened, pointRadius, maintainAspectRatio, title)
        }
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges) {
    }

    chartIsReady(chart) {
        setTimeout(() => {
            this.chartReady = chart
        })
    }

    predicateHtmValue(values: DataRawReportAggregate, getDataChart: (...args) => any): any {
        if (values == null) {
            return undefined
        } else {
            const hourOpened = values.totalHourOpened
            const energyReducedConsuption = values.totalEnergyReducedConsuption
            let energyEconomy;
            if (values.totalEnergyReducedConsuption >= 0 && values.totalEnergyNotReducedConsuption > 0) {
                energyEconomy = 100 - Math.trunc(values.totalEnergyReducedConsuption / values.totalEnergyNotReducedConsuption * 100)
            }
            const period = values.period
            const valuesInError = Math.trunc(values.dataHourInError / values.totalData);
            let error;
            if (valuesInError > 15) {
                error = {icon: 'warning', message: 'errorDataCalc', percent: valuesInError}
            }

            let cardSegnali
            if (values.lampadaFunzionante != null || values.lampadaAccesa != null || values.fase != null) {
                const getTraduction = (value) => {
                    if (value == null) {
                        return undefined
                    } else if (value == true) {
                        return 'bool.true'
                    } else {
                        return 'bool.false'
                    }
                }
                const currentDate=values.dateCurrentState;
                let lampadaAccesa;
                let lampadaFunzionante;
                let fase = typeof values.fase == "number" ? values.fase.toString() : undefined
                if (values.lampadaAccesa != null) {
                    const cssClass = values.lampadaAccesa ? 'background-primary' : undefined;
                    lampadaAccesa = {
                        traduction: getTraduction(values.lampadaAccesa),
                        cssClass,
                        color:values.lampadaAccesa?'white':undefined
                    };
                }
                if (values.lampadaFunzionante != null) {
                    lampadaFunzionante = {
                        traduction: getTraduction(values.lampadaFunzionante),
                        cssClass: values.lampadaFunzionante ? 'background-green' : 'background-warn'
                    };
                }
                cardSegnali = {
                    currentDate,
                    lampadaAccesa,
                    lampadaFunzionante,
                    fase
                }
            }

            let infoSensorData;
            if (arrayIsSet(values.sensorData)) {
                infoSensorData = values.sensorData.map(v => {
                    const unit =  v.type;
                    const title =  v.type;
                    const value = v.value;
                    return {unit, title, value};
                })
            }
            return {
                chartProfile: getDataChart(values.lastFunctionProfile, values.lastDayOpened),
                energyReducedConsuption: energyReducedConsuption,
                energyEconomy: energyEconomy,
                hourOpened: hourOpened,
                period,
                error,
                cardSegnali,
                infoSensorData
            }
        }
    }

    predicateHour(hour: number) {
        return Math.trunc(hour) + ' h'
    }

    predicateEnergy(energy: number) {
        if (energy / 1000 > 0) {
            return Math.round(energy / 1000 * 100) / 100 + ' kWh'
        } else {
            return Math.round(energy) + ' Wh'
        }
    }

    predicatePercent(energy: number) {
        return energy + ' %'
    }


}
