import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'getClassByValues'
})
export class GetClassByValuesPipe implements PipeTransform {

    transform<T>(value: any, predicateFunction: (a, ...args) => T, ...args): T {
        return predicateFunction(value, ...args);
    }

}
