import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {animate, keyframes, state, style, transition, trigger} from "@angular/animations";

//quando cambia un valore la navbar viene rivisualizzata. conviene sepre utilizzare changeValueForOpenSnackBar;

@Component({
    selector: 'app-snackbar-for-full-screen',
    templateUrl: './snackbar-for-full-screen.component.html',
    animations: [
        trigger('openClose', [
            // ...
            state('open', style({
                visibility: 'hidden',
            }), {params: {backColor: "yellow"}}),
            state('closed', style({
                visibility: 'hidden',
            }), {params: {backColorClose: "yellow"}}),
            transition('open <=> closed',
                animate('3s',
                    keyframes([
                        style({
                            opacity: 0.5,
                            visibility: 'visible',
                            color: 'white',
                            backgroundColor: "{{backColor}}",
                            offset: 0,
                        }),
                        style({
                            visibility: 'visible',
                            opacity: 1,
                            color: 'white',
                            backgroundColor: "{{backColor}}",
                            transform: (window.innerWidth > 400) ? 'translatey(30%)' : 'translatex(30%)',
                            offset: 0.7
                        }),
                        style({
                            visibility: 'visible',
                            opacity: 0.3,
                            color: 'white',
                            backgroundColor: 'transparent', offset: 1.0
                        })
                    ])), {params: {backColor: "yellow"}}),
        ]),
    ],
    styleUrls: ['./snackbar-for-full-screen.component.scss']
})

export class SnackbarForFullScreenComponent implements OnInit, OnChanges {
    @Input()
    text;
    @Input()
    state: 'warning' | 'error' | 'success' = "success";
    @Input()
    changeValueForOpenSnackBar = false;
    @Output()
    closeBar = new EventEmitter();


    constructor() {

    }

    public icon: string;


    public get color() {
        if (this.state == 'error') {
            this.icon = 'error.svg';
        } else if (this.state == 'warning') {
            this.icon = 'warning.svg';
        } else if (this.state == 'success') {
            this.icon = 'verified.svg';
        } else {
            this.icon = undefined;
        }
        return 'rgba(50,50,50,0.88)';
    }


    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.changeValueForOpenSnackBar = !this.changeValueForOpenSnackBar;
    }


    // per fare in modo venga riaperta quando riassegnato openSnackBar
    closeSnackBar(event) {
        if (event.toState == 'closed') {
            this.closeBar.emit({closed: true});
        }
    }
}


