import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-info-group',
    templateUrl: './info-group.component.html'
})
export class InfoGroupComponent implements OnInit {

    constructor(

    ) {
    }

    ngOnInit() {
    }
}
