import {Injectable} from '@angular/core';
import * as Parse from 'parse';
import {environment} from "../../../environments/environment";
import {catchError} from "rxjs/operators";
import {Observable, throwError} from "rxjs";
import {fromPromise} from "rxjs/internal-compatibility";
import {Router} from "@angular/router";

export const jobPossibleStatus = {
    running: 'running',
    succeeded: 'succeeded',
}

@Injectable({
    providedIn: 'root'
})
export class ParseApiService {


    constructor() {
        Parse.initialize(
            environment.parse.applicationId,
            environment.parse.javascriptKey
        );
        // (Parse as any).serverURL = environment.parse.serverUrl;
        Parse.CoreManager.set('SERVER_URL', environment.parse.serverUrl)
    }


    public getJobInfo$(jobStatusId: string): Observable<Parse.Object> {
        return fromPromise(
            Parse.Cloud.run('getJobStatus', {jobStatusId})
        )
    }

    controlAccessToken$<T>(observableFunction: Observable<T>): Observable<T> {
        return observableFunction.pipe(
            catchError(error => {
                console.log(error);
                if (error.code == Parse.Error.INVALID_SESSION_TOKEN) {
                    Parse.User.logOut();
                    // this.routing.navigate(['/auth/login']);
                }
                return throwError(error)
            })
        )
    }

    runCloudFunction<T>(name: string, requestParams: any, options: any=undefined): Observable<any> {
        return this.controlAccessToken$(fromPromise(Parse.Cloud.run(name, requestParams,options)))
    }

}
