import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'roundNumber'
})
export class RoundNumberPipe implements PipeTransform {

    transform(value: number | string, toFixed: number = 2, ...args: unknown[]): string {
        if(!!value){
        return parseFloat(value.toString()).toFixed(toFixed);}
        else return 'NaN';
    }

}
