import {Injectable} from '@angular/core';
import jsPDF from "jspdf";
import {PrintPageService} from "./print-page.service";
import {TranslateService} from "@ngx-translate/core";
import {formatDate} from "@angular/common";
import {ProgettiParse} from "../../models/Progetti.Parse";
import {EMPTY, forkJoin, Observable, of} from "rxjs";
import {SchedaManutenzioneCompilataParse} from "../../models/SchedaManutenzioneCompilata.Parse";
import {
    arrayIsSet,
    className,
    convertDecimalHourTodate,
    getItemInArrayByKeyValue, isNotNullOrUndefined, isValidDate, paramsApiParse_v2,
    stringIsSet
} from "../../models/Models";
import {environment} from "../../../environments/environment";
import {CircuitiParse} from "../../models/Circuiti.Parse";
import {PuntiLuceParse} from "../../models/PuntiLuce.Parse";
import {CommentiSegnalazioniParse} from "../../models/CommentiSegnalazioni.Parse";
import {SegnalazioniParse} from "../../models/Segnalazioni.Parse";
import {MaintenanceService, typeFormFieldMaintenance} from "./maintenance.service";
import {Priority, ReportStates} from "../../../config/static-data";
import {expand, map, switchMap} from "rxjs/operators";
import {fromPromise} from "rxjs/internal-compatibility";
import {UserService} from "./user.service";
import {ActivitiesService} from "./activities.service";


@Injectable({
    providedIn: 'root'
})
export class ReportMaintenanceService {

    constructor(private printPage: PrintPageService,
                private userService: UserService,
                private maintenanceService: MaintenanceService,
                private activitiesService: ActivitiesService,
                private translateService: TranslateService) {
    }

    firstPage(project: ProgettiParse, reportsLength: string, referenceDate: Date, today: Date): Observable<jsPDF> {
        return new Observable<jsPDF>((subscriber) => {
            this.printPage.headHeight = 25;
            const doc = new jsPDF({orientation: 'p', format: 'a4', unit: 'mm'});
            this.printPage.addFont(doc);
            const createFirstPage = (logo: any | undefined = undefined) => {
                this.printPage.firstPage(doc, null, today.getTime(), logo);
                const title = this.translateService.instant('reportSegnalazioni.reportSegnalazioni');
                this.printPage.addH1(doc, title, 100, 80);
                const meseAnnoRiferimento = formatDate(referenceDate, 'MMMM yyyy', this.translateService.currentLang);
                this.printPage.addH2(doc, meseAnnoRiferimento, 100, 120);
                this.printPage.addH2(doc, project.name, 100, 140);
                const circuitiTitle = this.translateService.instant('reportSegnalazioni.totalReports') + ' ' + reportsLength;
                this.printPage.addH2(doc, circuitiTitle, 100, 160);
                subscriber.next(doc);
                subscriber.complete();
                subscriber.unsubscribe();
            }
            let imagePromise: Promise<{ image: HTMLImageElement }> | undefined
            if (isNotNullOrUndefined(project) && isNotNullOrUndefined(project.organizzazione) && isNotNullOrUndefined(project.organizzazione.logo)) {
                imagePromise = this.printPage.getImageWithUrl(project.organizzazione.logo.url())

            } else if (isNotNullOrUndefined(project) && isNotNullOrUndefined(project.htmlLogo)) {
                imagePromise = this.printPage.getImageWithUrl(project.htmlLogo.url())
            } else {
                createFirstPage();
            }

            if (isNotNullOrUndefined(imagePromise)) {
                let logo;
                imagePromise
                    .then(image => {
                        if (image != null && image.image != null) {
                            logo = image.image
                        }
                    })
                    .finally(() => {
                        createFirstPage(logo);
                    })
            }

        })


    }

    getImageId() {

    }

    async fetchAllValueForPrint(schedaManutenzione: SchedaManutenzioneCompilataParse, usersDetails = []): Promise<{
        schedaManutenzione: SchedaManutenzioneCompilataParse,
        allImages: { image: HTMLImageElement, traduction: string, fullSize: boolean }[],
        activities: { activity: CommentiSegnalazioniParse, HTMLImage: HTMLImageElement | undefined }[],
        usersDetails
    }> {
        try {
            const allImages: { image: HTMLImageElement, traduction: string, fullSize: boolean }[] = [];
            if (arrayIsSet(schedaManutenzione.form)) {
                for (const field of schedaManutenzione.form) {
                    if (this.getTypeToVisualized(field.value) == "image") {
                        if (field.value != null && field.value.url != null) {
                            const {image} = await this.getImageWithUrl(field.value.url())
                            allImages.push({
                                image: image,
                                traduction: schedaManutenzione.objectId + field.traduction,
                                fullSize: false
                            })
                        }
                    } else if (this.getTypeToVisualized(field.value) == "multipleImage") {
                        for (let documentFile of field.value) {
                            if (documentFile.file != null && documentFile.file.url != null) {
                                const {image} = await this.getImageWithUrl(documentFile.file.url())
                                allImages.push({
                                    image: image,
                                    traduction: documentFile.objectId + schedaManutenzione.objectId + field.traduction,
                                    fullSize: false
                                })
                            }
                        }
                    }
                }
            }
            const idUserUpdated = usersDetails.map(user => user.id);
            let idNewUsersDetail = [];
            const getId = (item) => item.id;

            let activities: { activity: CommentiSegnalazioniParse, HTMLImage: HTMLImageElement | undefined }[] = [];
            if (schedaManutenzione.segnalazione) {
                if (schedaManutenzione.segnalazione.foto && schedaManutenzione.segnalazione.foto.url) {
                    const {image} = await this.getImageWithUrl(schedaManutenzione.segnalazione.foto.url())
                    allImages.push({image: image, traduction: schedaManutenzione.objectId, fullSize: false})
                }
                for (const activity of await this.activitiesService.getActivitiesOfReport(schedaManutenzione.segnalazione)) {
                    let imageHtml
                    if (activity.foto && activity.foto.url) {
                        const {image} = await this.getImageWithUrl(activity.foto.url())
                        imageHtml = image;
                    }
                    if (activity.user) {
                        const id = getId(activity.user)
                        if (!isNotNullOrUndefined(getItemInArrayByKeyValue(idNewUsersDetail.concat(idUserUpdated), id))) {
                            idNewUsersDetail.push(id)
                        }
                    }
                    if (activity.objectUser) {
                        const id = getId(activity.objectUser)
                        if (!isNotNullOrUndefined(getItemInArrayByKeyValue(idNewUsersDetail.concat(idUserUpdated), id))) {
                            idNewUsersDetail.push(id)
                        }
                    }
                    activities.push({activity, HTMLImage: imageHtml})
                }
                if (arrayIsSet(schedaManutenzione.segnalazione.puntiLuce)) {
                    schedaManutenzione.segnalazione.puntiLuce = await this.maintenanceService.fetchAll(schedaManutenzione.segnalazione.puntiLuce).toPromise()
                }

            }
            let newUsersDetail = [];
            if (arrayIsSet(idNewUsersDetail)) {
                newUsersDetail = await this.userService.getUserDetail(idNewUsersDetail).toPromise();
            }
            return {
                schedaManutenzione: schedaManutenzione,
                allImages: allImages,
                activities: activities,
                usersDetails: usersDetails.concat(newUsersDetail)
            }
        } catch (e) {
            // this.alertService.error(e.message)
        }
    }

    getName(date: Date) {
        return this.maintenanceService.project.name + '_' + 'report' + '_' + date.getMonth() + '_' + date.getFullYear();
    }

    get project() {
        return this.maintenanceService.project
    }

    createDocumentPdf(schedeManutenzioneCompilate: SchedaManutenzioneCompilataParse[], referenceDate: Date, today: Date): Observable<{
        progress: number,
        isLast: boolean
    }> {
        const project = this.maintenanceService.project
        return new Observable(subscriber => {
            this.firstPage(project, schedeManutenzioneCompilate.length.toString(), referenceDate, today)
                .pipe(
                    switchMap((doc) => {
                        const schedeSegnalzioniToFetch = []
                        const schedeManutenzione = schedeManutenzioneCompilate;
                        schedeManutenzione.forEach(schedaManutenzione => {
                            if (schedaManutenzione.segnalazione) {
                                if (!getItemInArrayByKeyValue(schedeSegnalzioniToFetch, schedaManutenzione.objectId, 'objectId')) {
                                    schedeSegnalzioniToFetch.push(fromPromise(schedaManutenzione.segnalazione.fetchWithInclude(['circuito', 'puntoLuce'])));
                                }
                            }
                        })
                        if (arrayIsSet(schedeSegnalzioniToFetch)) {
                            return forkJoin(schedeSegnalzioniToFetch)
                                .pipe(
                                    map(
                                        () => {
                                            return {
                                                doc: doc,
                                                schedeManutenzione: schedeManutenzione
                                            }
                                        })
                                );
                        } else {
                            return of({
                                doc: doc,
                                schedeManutenzione: schedeManutenzione
                            })
                        }
                    }),
                    switchMap((docSchedeManutenzione) => {
                        const idUsers = []
                        const getId = (item) => item.id;
                        const schedeManutenzione = docSchedeManutenzione.schedeManutenzione;
                        const doc = docSchedeManutenzione.doc
                        schedeManutenzione.forEach(schedaManutenzione => {
                            if (schedaManutenzione.compiledBy) {
                                const id = getId(schedaManutenzione.compiledBy)
                                if (!isNotNullOrUndefined(getItemInArrayByKeyValue(idUsers, id))) {
                                    idUsers.push(id)
                                }
                            }
                            const segnalazione = schedaManutenzione.segnalazione as SegnalazioniParse
                            if (segnalazione) {
                                if (segnalazione.user) {
                                    const id = getId(segnalazione.user)
                                    if (!isNotNullOrUndefined(getItemInArrayByKeyValue(idUsers, id))) {
                                        idUsers.push(id)
                                    }
                                }
                                if (segnalazione.presaInCaricoDa) {
                                    const id = getId(segnalazione.presaInCaricoDa)
                                    if (!isNotNullOrUndefined(getItemInArrayByKeyValue(idUsers, id))) {
                                        idUsers.push(id)
                                    }
                                }
                                if (segnalazione.ultimoAggiornamentoDa) {
                                    const id = getId(segnalazione.ultimoAggiornamentoDa)
                                    if (!isNotNullOrUndefined(getItemInArrayByKeyValue(idUsers, id))) {
                                        idUsers.push(id)
                                    }
                                }
                            }
                        })
                        if (arrayIsSet(idUsers)) {
                            return this.userService.getUserDetail(idUsers)
                                .pipe(
                                    map(usersDetail => {
                                        return {
                                            doc,
                                            usersDetail: usersDetail,
                                            schedeManutenzione
                                        }
                                    })
                                )
                        } else {
                            return of({doc, usersDetail: undefined, schedeManutenzione})
                        }
                    })
                ).subscribe(async docUserSchede => {
                const doc = docUserSchede.doc;
                const usersDetails = docUserSchede.usersDetail;
                const schedeManutenzione = docUserSchede.schedeManutenzione;
                const todayString = formatDate(today, 'shortDate', this.translateService.currentLang);

                let iterator = 0;
                const numberObject = schedeManutenzione.length + 1;
                const getProgress = (iterator) => {
                    return Math.ceil(iterator / numberObject * 100)
                }
                const reportPageNumber: number[] = []
                let progress = 0;
                subscriber.next({progress: progress, isLast: false})
                for (const schedaManutenzione of schedeManutenzione) {
                    iterator++;
                    progress = getProgress(iterator);
                    subscriber.next({progress: progress, isLast: iterator == numberObject})
                    const values = await this.fetchAllValueForPrint(schedaManutenzione, usersDetails);
                    reportPageNumber.push(doc.getCurrentPageInfo().pageNumber);
                    this.addPageScheduleMaintenance(doc, values.schedaManutenzione, values.allImages, values.activities, values.usersDetails)
                }
                let valuesForPage;
                if (reportPageNumber.length > 1) {
                    const traductionReport = this.translateService.instant('Report')
                    valuesForPage = reportPageNumber.map((page, index) => {
                        const currentPage = page;
                        let succesivePage;
                        if ((reportPageNumber.length - 1) !== index) {
                            succesivePage = reportPageNumber[index + 1]
                        } else {
                            succesivePage = doc.getNumberOfPages()
                        }

                        const pages = [];
                        let i = currentPage;
                        while (i <= succesivePage) {
                            pages.push(i);
                            i++;
                        }
                        return {
                            page: pages, text: traductionReport + ' ' + (index + 1)
                        }

                    })
                }
                this.addHeader(doc, project, todayString, valuesForPage);
                this.downloadPDf(doc, this.getName(referenceDate));
                iterator++;
                progress = getProgress(iterator);
                subscriber.next({progress: progress, isLast: iterator == numberObject})
                subscriber.next({progress: 100, isLast: true});
                subscriber.complete();
                subscriber.unsubscribe();
            }, error => {
                subscriber.error(error)
                subscriber.complete();
                subscriber.unsubscribe();

            })

        })
    }


    async getActivityAndDetailSegnalazione(segnalazione: SegnalazioniParse, usersDetails): Promise<{
        segnalazione: SegnalazioniParse,
        allImages: { image: HTMLImageElement, traduction: string, fullSize: boolean }[],
        activities: { activity: CommentiSegnalazioniParse, HTMLImage: HTMLImageElement | undefined }[],
        usersDetails: any[]
    }> {
        const idUserUpdated = usersDetails.map(user => user.id);
        let idNewUsersDetail = [];
        const getId = (item) => item.id;
        let activities: { activity: CommentiSegnalazioniParse, HTMLImage: HTMLImageElement | undefined }[] = [];
        const allImages: { image: HTMLImageElement, traduction: string, fullSize: boolean }[] = [];
        if (segnalazione.foto && segnalazione.foto.url) {
            const {image} = await this.getImageWithUrl(segnalazione.foto.url())
            allImages.push({image: image, traduction: segnalazione.objectId, fullSize: false})
        }


        for (const activity of await this.activitiesService.getActivitiesOfReport(segnalazione)) {
            let imageHtml
            if (activity.foto && activity.foto.url) {
                const {image} = await this.getImageWithUrl(activity.foto.url())
                imageHtml = image;
            }
            if (activity.user) {
                const id = getId(activity.user)
                if (!isNotNullOrUndefined(getItemInArrayByKeyValue(idNewUsersDetail.concat(idUserUpdated), id))) {
                    idNewUsersDetail.push(id)
                }
            }
            if (activity.objectUser) {
                const id = getId(activity.objectUser)
                if (!isNotNullOrUndefined(getItemInArrayByKeyValue(idNewUsersDetail.concat(idUserUpdated), id))) {
                    idNewUsersDetail.push(id)
                }
            }
            if (isNotNullOrUndefined(activity.schedaManutenzioneCompilata) && arrayIsSet(activity.schedaManutenzioneCompilata.form)) {
                for (const field of activity.schedaManutenzioneCompilata.form) {
                    if (this.getTypeToVisualized(field.value) == "image") {
                        if (field.value != null && field.value.url != null) {
                            const {image} = await this.getImageWithUrl(field.value.url())
                            allImages.push({
                                image: image,
                                traduction: activity.schedaManutenzioneCompilata.objectId + field.traduction,
                                fullSize: false
                            })
                        }
                    } else if (this.getTypeToVisualized(field.value) == "multipleImage") {
                        for (let documentFile of field.value) {
                            if (documentFile.file != null && documentFile.file.url != null) {
                                const {image} = await this.getImageWithUrl(documentFile.file.url())
                                allImages.push({
                                    image: image,
                                    traduction: documentFile.objectId + activity.schedaManutenzioneCompilata.objectId + field.traduction,
                                    fullSize: false
                                })
                            }
                        }
                    }
                }
            }
            activities.push({activity, HTMLImage: imageHtml})
        }
        let newUsersDetail = [];
        if (arrayIsSet(idNewUsersDetail)) {
            newUsersDetail = await this.userService.getUserDetail(idNewUsersDetail).toPromise();
        }
        return {
            segnalazione: segnalazione,
            allImages: allImages,
            activities: activities,
            usersDetails: usersDetails.concat(newUsersDetail)
        }
    }


    public createPdfByReports(segnalazioni: SegnalazioniParse[], usersDetails, referenceDtae, today) {
        const reportPageNumber: number[] = []
        const last = segnalazioni.length;
        const fetchPage = (page, segnalazioni, doc: jsPDF) => {
            const segnalazione = segnalazioni[page];
            const progress = Math.ceil((page + 1) / last * 100)
            let isLast;
            if (page + 1 == last) {
                isLast = true;
            } else {
                isLast = false;
            }
            return fromPromise(segnalazione.fetch())
                .pipe(
                    switchMap((segnalazione: SegnalazioniParse) => {
                        if (segnalazione.circuito) {
                            return fromPromise(segnalazione.circuito.fetch()).pipe(
                                map(circuito => {
                                        segnalazione.circuito = circuito;
                                        return segnalazione;
                                    }
                                )
                            );
                        } else if (arrayIsSet(segnalazione.puntiLuce)) {
                            return this.maintenanceService.fetchAll(segnalazione.puntiLuce).pipe(
                                map(puntiLuce => {
                                    segnalazione.puntiLuce = puntiLuce;
                                    return segnalazione;
                                })
                            )
                        } else {
                            return of(segnalazione)
                        }

                    }),
                    switchMap((segnalazione) => fromPromise(this.getActivityAndDetailSegnalazione(segnalazione, usersDetails))),
                    switchMap((values) => {
                        const usersDetails = values.usersDetails;
                        const activities = values.activities;
                        const allImages = values.allImages;
                        const segnalazione = values.segnalazione;
                        const scheda = new SchedaManutenzioneCompilataParse();
                        scheda.segnalazione = segnalazione;
                        reportPageNumber.push(doc.getCurrentPageInfo().pageNumber)
                        this.addPageScheduleMaintenance(doc, scheda, allImages, activities, usersDetails);
                        if (isLast) {

                            let valuesForPage;
                            if (reportPageNumber.length > 1) {
                                const traductionReport = this.translateService.instant('Report')
                                valuesForPage = reportPageNumber.map((page, index) => {
                                    const currentPage = page;
                                    let succesivePage;
                                    if ((reportPageNumber.length - 1) !== index) {
                                        succesivePage = reportPageNumber[index + 1]
                                    } else {
                                        succesivePage = doc.getNumberOfPages()
                                    }

                                    const pages = [];
                                    let i = currentPage;
                                    while (i <= succesivePage) {
                                        pages.push(i);
                                        i++;
                                    }
                                    return {
                                        page: pages, text: traductionReport + ' ' + (index + 1)
                                    }

                                })
                            }

                            const todayString = formatDate(today, 'shortDate', this.translateService.currentLang);
                            this.addHeader(doc, this.project, todayString, valuesForPage);
                            this.downloadPDf(doc, this.getName(today))
                        }
                        return of(values)
                    }),
                    map((item) => {
                        return {
                            progress: progress,
                            nextPage: isLast ? undefined : (page += 1),
                            finished: isLast,
                        };
                    }),
                );
        }
        const paginatePdf = (firstPage, segnalazioni, doc) => {
            return fetchPage(firstPage, segnalazioni, doc).pipe(
                expand(({nextPage}) => nextPage ? fetchPage(nextPage, segnalazioni, doc) : EMPTY),
            )
        }
        return this.firstPage(this.project, segnalazioni.length.toString(), referenceDtae, today).pipe(
            switchMap(doc => {
                return paginatePdf(0, segnalazioni, doc).pipe(
                    map(item => {
                        return {doc, currentValue: item}
                    })
                )
            })
        )


    }

    //
    // public async getBase64FromUrl(url): Promise<string> {
    //     return await this.printPage.getBase64FromUrl(url);
    //     // const headers = new Headers();
    //     // headers.set('Access-Control-Allow-Origin', '*');
    //     // headers.set('X-Parse-Javascript-Key', environment.parse.javascriptKey);
    //     // headers.set("X-Parse-Application-Id", environment.parse.applicationId);
    //     // const blob = await fetch(url, {
    //     //     cache: 'no-cache',
    //     //     headers: headers,
    //     //     referrerPolicy: 'strict-origin-when-cross-origin'
    //     // })
    //     //     .then(data => {
    //     //         return data.blob();
    //     //     })
    //     //     .catch((e) => {
    //     //         console.error(e);
    //     //         return null
    //     //     });
    //     // return new Promise((resolve) => {
    //     //     if (!isNotNullOrUndefined(blob)) {
    //     //         resolve(null);
    //     //     } else {
    //     //         const reader = new FileReader();
    //     //         reader.readAsDataURL(blob);
    //     //         reader.onloadend = () => {
    //     //             const base64data = reader.result;
    //     //             resolve(base64data as string);
    //     //         }
    //     //         reader.onerror = (e) => {
    //     //             resolve(null);
    //     //         }
    //     //     }
    //     // });
    // }

    getSizeImageWithMaxSize(maxSize, ratio): { width: number, height: number } {
        let width = maxSize;
        let height = width / ratio;
        if (height > maxSize) {
            height = maxSize;
            width = height * ratio
        }
        return {width, height}
    }


    public async getImageWithUrl(url: string): Promise<{ image: HTMLImageElement } | undefined> {
        return await this.printPage.getImageWithUrl(url)
    }

    public getImageWitTraduction(allImages: {
        image: HTMLImageElement,
        traduction: string,
        fullSize
    }[], traduction): HTMLImageElement | undefined {
        const index = allImages.findIndex(image => {
            return image.traduction == traduction;
        })
        if (index >= 0) {
            return allImages[index].image
        } else {
            return undefined;
        }
    }


    getUserDetialByUser(user, usersDetail: any[]) {
        let detail = user;
        const getId = (item) => item.id;

        if (arrayIsSet(usersDetail)) {
            const index = usersDetail.findIndex(userDetail => {
                    return getId(userDetail) == getId(user)
                }
            );
            if (index >= 0) {
                detail = usersDetail[index]
            }
        }

        return detail;
    }


    getValueUserToPrint(user, usersDetail, ndTraduction) {
        if (!isNotNullOrUndefined(user)) {
            return ndTraduction
        }
        const detail = this.getUserDetialByUser(user, usersDetail);
        const nome = detail.get("nome");
        const cognome = detail.get("cognome");

        let printValue = '';
        if (stringIsSet(nome)) {
            printValue += nome;
            printValue += ' ';
        }
        if (stringIsSet(cognome)) {
            printValue += cognome
        }
        if (stringIsSet(printValue)) {
            return printValue
        } else {
            return ndTraduction
        }
    }

    createCircuitoHeader(doc: jsPDF, schedaManutenzione: SchedaManutenzioneCompilataParse, circuito: CircuitiParse, last, margin: {
        x: number,
        y: number
    }, usersDetail, isSegnalazione = false) {
        const column1 = margin.x;
        let column2;
        let column3;
        let column4;
        const ndTraduction = this.translateService.instant('NaN');
        const width = doc.internal.pageSize.getWidth() - margin.x * 2

        const compiledOnTraduction = this.translateService.instant('scheduleMaintenance.completedOn')
        const numeroQuadroTraduction = this.translateService.instant('dashboard_sidenav.Circuiti.numeroQuadro.title')
        const numeroPodTraduction = this.translateService.instant('dashboard_sidenav.Circuiti.POD.title')
        const indirizzoTraduction = this.translateService.instant('dashboard_sidenav.Circuiti.indirizzo.title')
        const compiledByTraduction = this.translateService.instant('scheduleMaintenance.compledByFilter')


        if (isSegnalazione) {
            const rightTraduction = [numeroQuadroTraduction];
            const leftTraduction = [numeroPodTraduction];
            const maxWidthColumn = width / 2 - margin.x / 2
            let widthColumn12 = 0;
            let widthColumn34 = 0;
            rightTraduction.forEach(key => {
                const length = doc.getTextWidth(key);
                if (length > maxWidthColumn / 2) {
                    widthColumn12 = maxWidthColumn / 2;
                } else if (length >= widthColumn12) {
                    widthColumn12 = length;
                }
            })
            leftTraduction.forEach(key => {
                const length = doc.getTextWidth(key);
                if (length > maxWidthColumn) {
                    widthColumn34 = maxWidthColumn;
                } else if (length >= widthColumn34) {
                    widthColumn34 = length;
                }
            })


            column2 = column1 + widthColumn12 + margin.x / 2;
            const maxWidthColumns12 = maxWidthColumn - widthColumn12 - margin.x / 2;
            column3 = column1 + maxWidthColumn + margin.x / 2;
            column4 = column3 + widthColumn34 + margin.x / 2;
            const maxWidthColumns34 = maxWidthColumn - widthColumn34;


            this.printPage.addText(doc, numeroQuadroTraduction, column1, last.lastY, {
                align: "left",
                maxWidth: widthColumn12,
                baseline: "top"
            })
            const numeroQuadro = circuito.numeroQuadro ? circuito.numeroQuadro : ndTraduction;
            this.printPage.addText(doc, numeroQuadro, column2, last.lastY, {
                align: "left",
                maxWidth: maxWidthColumns12,
                baseline: "top"
            })
            this.printPage.addText(doc, numeroPodTraduction, column3, last.lastY, {
                align: "left",
                maxWidth: widthColumn34,
                baseline: "top"
            })
            const numeroPod = circuito.POD ? circuito.POD : ndTraduction;
            last = this.printPage.addText(doc, numeroPod, column4, last.lastY, {
                align: "left",
                maxWidth: maxWidthColumns34,
                baseline: "top"
            })
            this.printPage.addText(doc, indirizzoTraduction, column1, last.lastY + margin.y, {
                align: "left",
                maxWidth: widthColumn12,
                baseline: "top"
            })
            let indirizzo = circuito.indirizzo;
            indirizzo = !stringIsSet(indirizzo) && stringIsSet(circuito.indirizzoFornitura) ? circuito.indirizzoFornitura : ndTraduction;
            last = this.printPage.addText(doc, indirizzo, column2, last.lastY + margin.y, {
                align: "left",
                maxWidth: maxWidthColumns12 + maxWidthColumns34,
                baseline: "top"
            })

        } else {
            const rightTraduction = [numeroQuadroTraduction, numeroPodTraduction, indirizzoTraduction];
            const leftTraduction = [compiledByTraduction,];
            const maxWidthColumn = width / 2 - margin.x / 2
            let widthColumn12 = 0;
            let widthColumn34 = 0;
            rightTraduction.forEach(key => {
                const length = doc.getTextWidth(key);
                if (length > maxWidthColumn / 2) {
                    widthColumn12 = maxWidthColumn / 2;
                } else if (length >= widthColumn12) {
                    widthColumn12 = length;
                }
            })
            leftTraduction.forEach(key => {
                const length = doc.getTextWidth(key);
                if (length > maxWidthColumn) {
                    widthColumn34 = maxWidthColumn;
                } else if (length >= widthColumn34) {
                    widthColumn34 = length;
                }
            })


            column2 = column1 + widthColumn12 + margin.x / 2;
            const maxWidthColumns12 = maxWidthColumn - widthColumn12 - margin.x / 2;
            column3 = column1 + maxWidthColumn + margin.x / 2;
            column4 = column3 + widthColumn34 + margin.x / 2;
            const maxWidthColumns34 = maxWidthColumn - widthColumn34;


            this.printPage.addText(doc, numeroQuadroTraduction, column1, last.lastY, {
                align: "left",
                maxWidth: widthColumn12,
                baseline: "top"
            })
            const numeroQuadro = circuito.numeroQuadro ? circuito.numeroQuadro : ndTraduction;
            this.printPage.addText(doc, numeroQuadro, column2, last.lastY, {
                align: "left",
                maxWidth: maxWidthColumns12,
                baseline: "top"
            })
            this.printPage.addText(doc, compiledByTraduction, column3, last.lastY, {
                align: "left",
                maxWidth: widthColumn34,
                baseline: "top"
            })
            const compiledBy = this.getValueUserToPrint(schedaManutenzione.compiledBy, usersDetail, ndTraduction);
            last = this.printPage.addText(doc, compiledBy, column4, last.lastY, {
                align: "left",
                maxWidth: maxWidthColumns34,
                baseline: "top"
            })
            this.printPage.addText(doc, numeroPodTraduction, column1, last.lastY + margin.y, {
                align: "left",
                maxWidth: widthColumn12,
                baseline: "top"
            })
            const numeroPod = circuito.POD ? circuito.POD : ndTraduction;
            this.printPage.addText(doc, numeroPod, column2, last.lastY + margin.y, {
                align: "left",
                maxWidth: maxWidthColumns12,
                baseline: "top"
            })
            this.printPage.addText(doc, compiledOnTraduction, column3, last.lastY + margin.y, {
                align: "left",
                maxWidth: widthColumn34,
                baseline: "top"
            })
            let compiledon
            if (isNotNullOrUndefined(schedaManutenzione.createdAt)) {
                compiledon = formatDate(schedaManutenzione.createdAt, 'medium', this.translateService.currentLang);
            } else {
                compiledon = formatDate(schedaManutenzione.segnalazione.createdAt, 'medium', this.translateService.currentLang);
            }
            last = this.printPage.addText(doc, compiledon, column4, last.lastY + margin.y, {
                align: "left",
                maxWidth: maxWidthColumns34,
                baseline: "top",
            })
            this.printPage.addText(doc, indirizzoTraduction, column1, last.lastY + margin.y, {
                align: "left",
                maxWidth: widthColumn12,
                baseline: "top"
            })
            let indirizzo = circuito.indirizzo;
            indirizzo = !stringIsSet(indirizzo) && stringIsSet(circuito.indirizzoFornitura) ? circuito.indirizzoFornitura : ndTraduction;
            last = this.printPage.addText(doc, indirizzo, column2, last.lastY + margin.y, {
                align: "left",
                maxWidth: maxWidthColumns12,
                baseline: "top"
            })
        }

        doc.line(10, last.lastY + margin.y, 200, last.lastY + margin.y);
        last.lastY += +5 + margin.y * 2
        return last;

    }


    createPuntoLuceHeader(doc: jsPDF, schedaManutenzione: SchedaManutenzioneCompilataParse, puntoLuce: PuntiLuceParse, last, margin: {
        x: number,
        y: number
    }, usersDetail, preview = false) {

        const column1 = margin.x;
        let column2;
        let column3;
        let column4;
        const ndTraduction = this.translateService.instant('NaN');
        const width = doc.internal.pageSize.getWidth() - margin.x * 2

        const compiledOnTraduction = this.translateService.instant('scheduleMaintenance.completedOn')
        const compiledByTraduction = this.translateService.instant('scheduleMaintenance.compledByFilter')
        const targaTraduction = this.translateService.instant('dashboard_sidenav.PuntiLuce.targa.title')
        const targaCustomTraduction = this.translateService.instant('dashboard_sidenav.PuntiLuce.targaCustom.title')
        const indirizzoTraduction = this.translateService.instant('dashboard_sidenav.PuntiLuce.indirizzo.title')

        const rightTraduction = [targaTraduction, targaCustomTraduction, indirizzoTraduction];
        const leftTraduction = [compiledByTraduction,];
        const maxWidthColumn = width / 2 - margin.x / 2
        let widthColumn12 = 0;
        let widthColumn34 = 0;
        rightTraduction.forEach(key => {
            const length = doc.getTextWidth(key);
            if (length > maxWidthColumn / 2) {
                widthColumn12 = maxWidthColumn / 2;
            } else if (length >= widthColumn12) {
                widthColumn12 = length;
            }
        })
        leftTraduction.forEach(key => {
            const length = doc.getTextWidth(key);
            if (length > maxWidthColumn) {
                widthColumn34 = maxWidthColumn;
            } else if (length >= widthColumn34) {
                widthColumn34 = length;
            }
        })


        column2 = column1 + widthColumn12 + margin.x / 2;
        const maxWidthColumns12 = maxWidthColumn - widthColumn12 - margin.x / 2;
        column3 = column1 + maxWidthColumn + margin.x / 2;
        column4 = column3 + widthColumn34 + margin.x / 2;
        const maxWidthColumns34 = maxWidthColumn - widthColumn34;

        if (preview) {
            this.printPage.previewAddText(doc, targaTraduction, column1, last.lastY, {
                align: "left",
                maxWidth: widthColumn12,
                baseline: "top"
            })
            const targa = puntoLuce.targa ? puntoLuce.targa : ndTraduction;
            this.printPage.previewAddText(doc, targa, column2, last.lastY, {
                align: "left",
                maxWidth: maxWidthColumns12,
                baseline: "top"
            })
            this.printPage.previewAddText(doc, compiledByTraduction, column3, last.lastY, {
                align: "left",
                maxWidth: widthColumn34,
                baseline: "top"
            })
            const compiledBy = this.getValueUserToPrint(schedaManutenzione.compiledBy, usersDetail, ndTraduction);
            last = this.printPage.previewAddText(doc, compiledBy, column4, last.lastY, {
                align: "left",
                maxWidth: maxWidthColumns34,
                baseline: "top"
            })
            this.printPage.previewAddText(doc, targaCustomTraduction, column1, last.lastY + margin.y, {
                align: "left",
                maxWidth: widthColumn12,
                baseline: "top"
            })
            const targaCustom = puntoLuce.targaCustom ? puntoLuce.targaCustom : ndTraduction;
            this.printPage.previewAddText(doc, targaCustom, column2, last.lastY + margin.y, {
                align: "left",
                maxWidth: maxWidthColumns12,
                baseline: "top"
            })
            this.printPage.previewAddText(doc, compiledOnTraduction, column3, last.lastY + margin.y, {
                align: "left",
                maxWidth: widthColumn34,
                baseline: "top"
            })
            const compiledon = formatDate(schedaManutenzione.createdAt, 'medium', this.translateService.currentLang);
            last = this.printPage.previewAddText(doc, compiledon, column4, last.lastY + margin.y, {
                align: "left",
                maxWidth: maxWidthColumns34,
                baseline: "top",
            })
            this.printPage.previewAddText(doc, indirizzoTraduction, column1, last.lastY + margin.y, {
                align: "left",
                maxWidth: widthColumn12,
                baseline: "top"
            })
            let indirizzo = stringIsSet(puntoLuce.indirizzo) ? puntoLuce.indirizzo : ndTraduction;
            last = this.printPage.previewAddText(doc, indirizzo, column2, last.lastY + margin.y, {
                align: "left",
                maxWidth: maxWidthColumns12,
                baseline: "top"
            })
            const reportTraduction = this.translateService.instant('reportTitlePrint');
            doc.line(10, last.lastY + margin.y, 200, last.lastY + margin.y);
            last = this.printPage.previewAddText(doc, reportTraduction, width / 2 + margin.x / 2, last.lastY + 5 + margin.y * 2);
            return last;
        } else {
            this.printPage.addText(doc, targaTraduction, column1, last.lastY, {
                align: "left",
                maxWidth: widthColumn12,
                baseline: "top"
            })
            const targa = puntoLuce.targa ? puntoLuce.targa : ndTraduction;
            this.printPage.addText(doc, targa, column2, last.lastY, {
                align: "left",
                maxWidth: maxWidthColumns12,
                baseline: "top"
            })
            this.printPage.addText(doc, compiledByTraduction, column3, last.lastY, {
                align: "left",
                maxWidth: widthColumn34,
                baseline: "top"
            })
            const compiledBy = this.getValueUserToPrint(schedaManutenzione.compiledBy, usersDetail, ndTraduction);
            last = this.printPage.addText(doc, compiledBy, column4, last.lastY, {
                align: "left",
                maxWidth: maxWidthColumns34,
                baseline: "top"
            })
            this.printPage.addText(doc, targaCustomTraduction, column1, last.lastY + margin.y, {
                align: "left",
                maxWidth: widthColumn12,
                baseline: "top"
            })
            const targaCustom = puntoLuce.targaCustom ? puntoLuce.targaCustom : ndTraduction;
            this.printPage.addText(doc, targaCustom, column2, last.lastY + margin.y, {
                align: "left",
                maxWidth: maxWidthColumns12,
                baseline: "top"
            })
            this.printPage.addText(doc, compiledOnTraduction, column3, last.lastY + margin.y, {
                align: "left",
                maxWidth: widthColumn34,
                baseline: "top"
            })
            const compiledon = formatDate(schedaManutenzione.createdAt, 'medium', this.translateService.currentLang);
            last = this.printPage.addText(doc, compiledon, column4, last.lastY + margin.y, {
                align: "left",
                maxWidth: maxWidthColumns34,
                baseline: "top",
            })
            this.printPage.addText(doc, indirizzoTraduction, column1, last.lastY + margin.y, {
                align: "left",
                maxWidth: widthColumn12,
                baseline: "top"
            })
            let indirizzo = stringIsSet(puntoLuce.indirizzo) ? puntoLuce.indirizzo : ndTraduction;
            last = this.printPage.addText(doc, indirizzo, column2, last.lastY + margin.y, {
                align: "left",
                maxWidth: maxWidthColumns12,
                baseline: "top"
            })
            doc.line(10, last.lastY + margin.y, 200, last.lastY + margin.y);
            last.lastY += 5 + margin.y * 2
            return last;
        }


    }

    createSegnalazioneHeader(doc: jsPDF, schedaManutenzione: SchedaManutenzioneCompilataParse, segnalazione: SegnalazioniParse, last, margin: {
        x: number,
        y: number
    }, usersDetail) {
        const width = doc.internal.pageSize.getWidth() - margin.x * 2
        last = this.printPage.addBoldString(doc, segnalazione.titolo, width / 2 + margin.x / 2, last.lastY + margin.y, "center");
        last.lastY -= doc.getFontSize() / 2;
        const column1 = margin.x;
        let column2;
        let column3;
        let column4;
        const ndTraduction = this.translateService.instant('NaN');

        const getPriorita = (priorita) => {
            if (priorita == 0) {
                return this.translateService.instant('segnalazioniParse.priorita.' + Priority.BASSA)
            } else if (priorita == 1) {
                return this.translateService.instant('segnalazioniParse.priorita.' + Priority.MEDIA)
            } else if (priorita == 2) {
                return this.translateService.instant('segnalazioniParse.priorita.' + Priority.ALTA)
            } else {
                return ndTraduction
            }
        }

        const priorityTraduction = this.translateService.instant('segnalazioniParse.priorita.title')
        const tipologyTraduction = this.translateService.instant('segnalazioniParse.tipologiaIntervento.title')

        const openFromTraduction = this.translateService.instant('reportSegnalazioni.openFrom.title');
        const updatedFromTraduction = this.translateService.instant('reportSegnalazioni.updatedFrom.title')


        const toTraduction = this.translateService.instant('reportSegnalazioni.to')

        const statoTraduction = this.translateService.instant('segnalazioniParse.stato.title');
        const presaInCaricoDaTraduction = this.translateService.instant('reportSegnalazioni.presaInCaricoDa')

        const rightTraduction = [priorityTraduction, openFromTraduction, toTraduction, statoTraduction];
        const leftTraduction = [tipologyTraduction, updatedFromTraduction, toTraduction, presaInCaricoDaTraduction];
        const maxWidthColumn = width / 2 - margin.x / 2
        let widthColumn12 = 0;
        let widthColumn34 = 0;
        rightTraduction.forEach(key => {
            const length = doc.getTextWidth(key);
            if (length > maxWidthColumn / 2) {
                widthColumn12 = maxWidthColumn / 2;
            } else if (length >= widthColumn12) {
                widthColumn12 = length;
            }
        })
        leftTraduction.forEach(key => {
            const length = doc.getTextWidth(key);
            if (length > maxWidthColumn) {
                widthColumn34 = maxWidthColumn;
            } else if (length >= widthColumn34) {
                widthColumn34 = length;
            }
        })


        column2 = column1 + widthColumn12 + margin.x / 2;
        const maxWidthColumns12 = maxWidthColumn - widthColumn12 - margin.x / 2;
        column3 = column1 + maxWidthColumn + margin.x / 2;
        column4 = column3 + widthColumn34 + margin.x / 2;
        const maxWidthColumns34 = maxWidthColumn - widthColumn34;


        this.printPage.addText(doc, priorityTraduction, column1, last.lastY, {
            align: "left",
            maxWidth: widthColumn12,
            baseline: "top"
        })
        const priorita = getPriorita(segnalazione.priorita);
        this.printPage.addText(doc, priorita, column2, last.lastY, {
            align: "left",
            maxWidth: maxWidthColumns12,
            baseline: "top"
        })
        this.printPage.addText(doc, tipologyTraduction, column3, last.lastY, {
            align: "left",
            maxWidth: widthColumn34,
            baseline: "top"
        })
        const tipology = stringIsSet(segnalazione.tipologiaIntervento) ? segnalazione.tipologiaIntervento : ndTraduction
        last = this.printPage.addText(doc, tipology, column4, last.lastY, {
            align: "left",
            maxWidth: maxWidthColumns34,
            baseline: "top"
        })
        this.printPage.addText(doc, openFromTraduction, column1, last.lastY + margin.y, {
            align: "left",
            maxWidth: widthColumn12,
            baseline: "top"
        })
        const openTo = this.getValueUserToPrint(segnalazione.user, usersDetail, ndTraduction)
        this.printPage.addText(doc, openTo, column2, last.lastY + margin.y, {
            align: "left",
            maxWidth: maxWidthColumns12,
            baseline: "top"
        })

        this.printPage.addText(doc, updatedFromTraduction, column3, last.lastY + margin.y, {
            align: "left",
            maxWidth: widthColumn34,
            baseline: "top"
        })
        const updated = this.getValueUserToPrint(segnalazione.ultimoAggiornamentoDa, usersDetail, ndTraduction);
        last = this.printPage.addText(doc, updated, column4, last.lastY + margin.y, {
            align: "left",
            maxWidth: maxWidthColumns34,
            baseline: "top",
        })


        this.printPage.addText(doc, toTraduction, column1, last.lastY + margin.y, {
            align: "left",
            maxWidth: widthColumn12,
            baseline: "top"
        })
        const toDate = formatDate(segnalazione.createdAt, 'medium', this.translateService.currentLang);
        this.printPage.addText(doc, toDate, column2, last.lastY + margin.y, {
            align: "left",
            maxWidth: maxWidthColumns12,
            baseline: "top"
        })
        this.printPage.addText(doc, toTraduction, column3, last.lastY + margin.y, {
            align: "left",
            maxWidth: widthColumn34,
            baseline: "top"
        })
        const updatedToDate = formatDate(segnalazione.updatedAt, 'medium', this.translateService.currentLang);
        last = this.printPage.addText(doc, updatedToDate, column4, last.lastY + margin.y, {
            align: "left",
            maxWidth: maxWidthColumns34,
            baseline: "top",
        })


        this.printPage.addText(doc, statoTraduction, column1, last.lastY + margin.y, {
            align: "left",
            maxWidth: widthColumn12,
            baseline: "top"
        })
        const stato = stringIsSet(segnalazione.stato) ? segnalazione.stato : ndTraduction;
        this.printPage.addText(doc, stato, column2, last.lastY + margin.y, {
            align: "left",
            maxWidth: maxWidthColumns12,
            baseline: "top"
        })
        this.printPage.addText(doc, presaInCaricoDaTraduction, column3, last.lastY + margin.y, {
            align: "left",
            maxWidth: widthColumn34,
            baseline: "top"
        })
        const presaInCaricoDa = this.getValueUserToPrint(segnalazione.presaInCaricoDa, usersDetail, ndTraduction);
        last = this.printPage.addText(doc, presaInCaricoDa, column4, last.lastY + margin.y, {
            align: "left",
            maxWidth: maxWidthColumns34,
            baseline: "top",
        })

        doc.line(10, last.lastY + margin.y, 200, last.lastY + margin.y);
        last.lastY += margin.y * 2
        return last;
    }


    private visualizeObjectUser(acticity: CommentiSegnalazioniParse, usersDetail, ndTraduction) {
        return isNotNullOrUndefined(acticity.objectUser) && this.getValueUserToPrint(acticity.objectUser, usersDetail, ndTraduction) != ndTraduction &&
            (acticity.nuovoStato == ReportStates.ASSEGNATOA || acticity.nuovoStato == ReportStates.INCARICO)
    }

    public convertHourToDate(oraDecimal: string | number): string {
        return convertDecimalHourTodate(oraDecimal)
    }


    addFormKey = (doc: jsPDF, margin: { x: number, y: number }, allImages, formKey: {
        traduction,
        value,
        type,
        schedaManutenzioneCompilataId?
    }, last: {
        lastY: number,
        lastX: number
    }, preview = false, ndTraduction = this.translateService.instant('NaN')) => {
        const typeToVisualized = formKey.type
        const marginXInRect = 3;
        const wRect = 190;
        const rBorder = 2.5;
        const spaceInRect = wRect - marginXInRect * 2;
        const isUnset = (formKey: { traduction, value, type, schedaManutenzioneCompilataId? }) => {
            if (typeToVisualized == 'image') {
                const idTraductuionImage = (stringIsSet(formKey.schedaManutenzioneCompilataId)) ? formKey.schedaManutenzioneCompilataId + formKey.traduction : formKey.traduction;
                const image = this.getImageWitTraduction(allImages, idTraductuionImage);
                return image == null;
            } else if (typeToVisualized == 'multipleImage') {
                const images = []
                if (arrayIsSet(formKey.value)) {
                    formKey.value.forEach(documentFile => {
                        const idTraductuionImage = (stringIsSet(formKey.schedaManutenzioneCompilataId)) ? documentFile.objectId + formKey.schedaManutenzioneCompilataId + formKey.traduction : documentFile.objectId + formKey.traduction;
                        const image = this.getImageWitTraduction(allImages, idTraductuionImage);
                        if (image != null) {
                            images.push(image)
                        }
                    })
                }
                return !arrayIsSet(images);
            } else if (typeToVisualized == 'date') {
                return !(isValidDate(formKey.value) || typeof formKey.value == "number" || typeof formKey.value == "string")
            } else if (typeToVisualized == 'hour') {
                return !(typeof formKey.value == "number" || typeof formKey.value == "string")
            } else if (typeToVisualized == 'bool') {
                return formKey.value != undefined && formKey.value != true && formKey.value != false
            } else {
                let value;
                if (formKey.value == null) {
                    value = undefined;
                } else if (typeof value == "string") {
                    value = formKey.value
                } else if (formKey.value.toString != null) {
                    value = formKey.value.toString()
                } else {
                    value = formKey.value
                }
                return !stringIsSet(value)
            }
        }
        if (isUnset(formKey)) {
            return last;
        } else if (preview) {
            const yPosition = last.lastY + margin.y;
            const sizetraduction = doc.getTextDimensions(formKey.traduction, {maxWidth: spaceInRect})
            last = this.printPage.previewAddText(doc, formKey.traduction, margin.x + marginXInRect, last.lastY + margin.y + 6, {maxWidth: spaceInRect})
            if (typeToVisualized == 'image') {
                const idTraductuionImage = (stringIsSet(formKey.schedaManutenzioneCompilataId)) ? formKey.schedaManutenzioneCompilataId + formKey.traduction : formKey.traduction;
                const image = this.getImageWitTraduction(allImages, idTraductuionImage);
                if (isNotNullOrUndefined(image)) {
                    let propertyImage = doc.getImageProperties(image);
                    const ratio = propertyImage.width / propertyImage.height;
                    const {width, height} = this.getSizeImageWithMaxSize(doc.internal.pageSize.getHeight() / 4, ratio);
                    const hRect = height + margin.y * 3 + sizetraduction.h;
                    const wRect = doc.internal.pageSize.getWidth() - margin.x * 2;
                    last = {lastX: wRect, lastY: yPosition + hRect}
                }
            } else if (typeToVisualized == 'multipleImage') {
                const images = []
                if (arrayIsSet(formKey.value)) {
                    formKey.value.forEach(documentFile => {
                        const idTraductuionImage = (stringIsSet(formKey.schedaManutenzioneCompilataId)) ? documentFile.objectId + formKey.schedaManutenzioneCompilataId + formKey.traduction : documentFile.objectId + formKey.traduction;
                        const image = this.getImageWitTraduction(allImages, idTraductuionImage);
                        if (image != null) {
                            images.push(image)
                        }
                    })
                }
                if (arrayIsSet(images)) {
                    images.forEach(image => {
                        if (isNotNullOrUndefined(image)) {
                            let propertyImage = doc.getImageProperties(image);
                            const ratio = propertyImage.width / propertyImage.height;
                            const {
                                width,
                                height
                            } = this.getSizeImageWithMaxSize(doc.internal.pageSize.getHeight() / 4, ratio);
                            const numeroFotoInRiga = Math.ceil(images.length / 2)// 2 foto per riga
                            const hRect = height * numeroFotoInRiga + margin.y * 3 + sizetraduction.h;
                            const wRect = doc.internal.pageSize.getWidth() - margin.x * 2;
                            last = {lastX: wRect, lastY: yPosition + hRect}
                        }
                    })
                }
            } else if (typeToVisualized == 'date') {
                const value = formKey.value != null ? formatDate(formKey.value, 'longDate', this.translateService.currentLang) : ndTraduction;
                const sizeValue = doc.getTextDimensions(value, {maxWidth: spaceInRect})
                const hRect = sizetraduction.h + sizeValue.h + margin.y * 4
                this.printPage.previewAddText(doc, value, margin.x + marginXInRect + 3, last.lastY + margin.y, {maxWidth: spaceInRect})
                last = {lastX: wRect, lastY: last.lastY + hRect}
            } else if (typeToVisualized == 'hour') {
                const value = this.convertHourToDate(formKey.value);
                const sizeValue = doc.getTextDimensions(value, {maxWidth: spaceInRect})
                const hRect = sizetraduction.h + sizeValue.h + margin.y * 4
                this.printPage.previewAddText(doc, value, margin.x + marginXInRect + 3, last.lastY + margin.y, {maxWidth: spaceInRect})
                last = {lastX: wRect, lastY: last.lastY + hRect}
            } else if (typeToVisualized == 'bool') {
                const value = formKey.value;
                const valueTraduction = this.translateService.instant('bool.' + value)
                const sizeValue = doc.getTextDimensions(valueTraduction, {maxWidth: spaceInRect})
                const hRect = sizetraduction.h + sizeValue.h + margin.y * 4
                last = {lastX: wRect, lastY: yPosition + hRect}
            } else {
                formKey.value = isNotNullOrUndefined(formKey.value) ? formKey.value : '';
                formKey.value = isNotNullOrUndefined(formKey.value.toString) ? formKey.value.toString() : formKey.value;
                const sizeValue = doc.getTextDimensions(formKey.value, {maxWidth: spaceInRect});
                const hRect = sizetraduction.h + sizeValue.h + margin.y * 4;
                last = {lastX: wRect, lastY: yPosition + hRect}
            }
            return last
        } else {
            let yPosition = last.lastY + margin.y;
            const sizetraduction = doc.getTextDimensions(formKey.traduction, {maxWidth: spaceInRect})
            last = this.printPage.addText(doc, formKey.traduction, margin.x + marginXInRect, last.lastY + margin.y + 6, {maxWidth: spaceInRect})
            if (typeToVisualized == 'image') {
                const idTraductuionImage = (stringIsSet(formKey.schedaManutenzioneCompilataId)) ? formKey.schedaManutenzioneCompilataId + formKey.traduction : formKey.traduction;
                const image = this.getImageWitTraduction(allImages, idTraductuionImage);
                if (isNotNullOrUndefined(image)) {
                    let propertyImage = doc.getImageProperties(image);
                    const ratio = propertyImage.width / propertyImage.height;
                    const {width, height} = this.getSizeImageWithMaxSize(doc.internal.pageSize.getHeight() / 4, ratio);
                    const xPosition = doc.internal.pageSize.getWidth() / 2 - width / 2
                    const hRect = height + margin.y * 3 + sizetraduction.h;
                    const wRect = doc.internal.pageSize.getWidth() - margin.x * 2;
                    doc.addImage(image, xPosition, last.lastY, width, height);
                    doc.roundedRect(10, yPosition, wRect, hRect, rBorder, rBorder, 'S');
                    last = {lastX: wRect, lastY: yPosition + hRect}
                } else {
                    const value = '-';
                    const sizeValue = doc.getTextDimensions(value, {maxWidth: spaceInRect})
                    const hRect = sizetraduction.h + sizeValue.h + margin.y * 4
                    this.printPage.addText(doc, value, margin.x + marginXInRect + 3, last.lastY + margin.y, {maxWidth: spaceInRect})
                    doc.roundedRect(10, yPosition, wRect, hRect, rBorder, rBorder, 'S');
                    last = {lastX: wRect, lastY: yPosition + hRect}
                }
                // last = this.printPage.addImage(doc, formKey.value.url(), 180, doc.internal.pageSize.getHeight() / 5, 10, last.lastY)
            } else if (typeToVisualized == 'multipleImage') {
                const images = []
                if (arrayIsSet(formKey.value)) {
                    formKey.value.forEach(documentFile => {
                        const idTraductuionImage = (stringIsSet(formKey.schedaManutenzioneCompilataId)) ? documentFile.objectId + formKey.schedaManutenzioneCompilataId + formKey.traduction : documentFile.objectId + formKey.traduction;
                        const image = this.getImageWitTraduction(allImages, idTraductuionImage);
                        if (image != null) {
                            images.push(image)
                        }
                    })
                }

                if (arrayIsSet(images)) {
                    if (images.length == 1) {
                        const image = images[0];
                        if (isNotNullOrUndefined(image)) {
                            let propertyImage = doc.getImageProperties(image);
                            const ratio = propertyImage.width / propertyImage.height;
                            const {
                                width,
                                height
                            } = this.getSizeImageWithMaxSize(doc.internal.pageSize.getHeight() / 4, ratio);
                            const xPosition = doc.internal.pageSize.getWidth() / 2 - width / 2
                            const hRect = height + margin.y * 3 + sizetraduction.h;
                            const wRect = doc.internal.pageSize.getWidth() - margin.x * 2;
                            doc.addImage(image, xPosition, last.lastY, width, height);
                            doc.roundedRect(10, yPosition, wRect, hRect, rBorder, rBorder, 'S');
                            last = {lastX: wRect, lastY: yPosition + hRect}
                        }
                    } else {
                        let hRect = margin.y * 3 + sizetraduction.h;
                        const wRect = doc.internal.pageSize.getWidth() - margin.x * 2;
                        let maxHeigth;
                        images
                            .forEach((image, index) => {
                                if (isNotNullOrUndefined(image)) {
                                    let propertyImage = doc.getImageProperties(image);
                                    const ratio = propertyImage.width / propertyImage.height;
                                    const {
                                        width,
                                        height
                                    } = this.getSizeImageWithMaxSize(doc.internal.pageSize.getHeight() / 4, ratio);
                                    maxHeigth = height;
                                    let xPosition;
                                    let lastY;
                                    if (index % 2 == 0) {
                                        maxHeigth = height
                                        lastY = last.lastY;
                                        xPosition = doc.internal.pageSize.getWidth() * 1 / 4 - width / 2
                                        if (last.lastY > doc.internal.pageSize.getHeight() - hRect) {
                                            doc.roundedRect(10, yPosition, wRect, hRect, rBorder, rBorder, 'S');
                                            hRect = margin.y * 3 + sizetraduction.h;
                                            doc.addPage();
                                            last = {lastX: 10, lastY: this.printPage.headHeight + 5 + 3};
                                            yPosition = last.lastY + margin.y;
                                            last = this.printPage.addText(doc, formKey.traduction, margin.x + marginXInRect, last.lastY + margin.y + 6, {maxWidth: spaceInRect})
                                            lastY = last.lastY;
                                        }
                                        if (index == (images.length - 1)) {
                                            hRect += maxHeigth + margin.y;
                                            lastY = last.lastY + height + margin.y
                                        }
                                    } else {
                                        if (maxHeigth < height) {
                                            maxHeigth = height;
                                        }
                                        hRect += maxHeigth + margin.y;
                                        lastY = last.lastY + height + margin.y
                                        xPosition = doc.internal.pageSize.getWidth() * 3 / 4 - width / 2
                                    }

                                    doc.addImage(image, xPosition, last.lastY, width, height);
                                    last.lastX = wRect;
                                    last.lastY = lastY;
                                }
                            })
                        doc.roundedRect(10, yPosition, wRect, hRect, rBorder, rBorder, 'S');
                    }
                }
            } else if (typeToVisualized == 'date') {
                const value = formatDate(formKey.value, 'longDate', this.translateService.currentLang);
                const sizeValue = doc.getTextDimensions(value, {maxWidth: spaceInRect})
                const hRect = sizetraduction.h + sizeValue.h + margin.y * 4
                this.printPage.addText(doc, value, margin.x + marginXInRect + 3, last.lastY + margin.y, {maxWidth: spaceInRect})
                doc.roundedRect(10, yPosition, wRect, hRect, rBorder, rBorder, 'S');
                last = {lastX: wRect, lastY: yPosition + hRect}
            } else if (typeToVisualized == 'hour') {
                const value = this.convertHourToDate(formKey.value);
                const sizeValue = doc.getTextDimensions(value, {maxWidth: spaceInRect})
                const hRect = sizetraduction.h + sizeValue.h + margin.y * 4
                this.printPage.addText(doc, value, margin.x + marginXInRect + 3, last.lastY + margin.y, {maxWidth: spaceInRect})
                doc.roundedRect(10, yPosition, wRect, hRect, rBorder, rBorder, 'S');
                last = {lastX: wRect, lastY: yPosition + hRect}
            } else if (typeToVisualized == 'bool') {
                const value = formKey.value;
                const valueTraduction = this.translateService.instant('bool.' + value)
                const sizeValue = doc.getTextDimensions(valueTraduction, {maxWidth: spaceInRect})
                const hRect = sizetraduction.h + sizeValue.h + margin.y * 4

                const xPosition = this.printPage.checkbox(doc, margin.x + marginXInRect + 3, last.lastY, 5, value).lastX
                this.printPage.addText(doc, valueTraduction, xPosition + marginXInRect, last.lastY + margin.y, {
                    maxWidth: spaceInRect,
                    baseline: "middle"
                })
                doc.roundedRect(10, yPosition, wRect, hRect, rBorder, rBorder, 'S');
                last = {lastX: wRect, lastY: yPosition + hRect}
            } else {
                formKey.value = isNotNullOrUndefined(formKey.value) ? formKey.value : '';
                formKey.value = isNotNullOrUndefined(formKey.value.toString) ? formKey.value.toString() : formKey.value;
                const sizeValue = doc.getTextDimensions(formKey.value, {maxWidth: spaceInRect});
                const hRect = sizetraduction.h + sizeValue.h + margin.y * 4;
                doc.roundedRect(10, yPosition, wRect, hRect, rBorder, rBorder, 'S');
                this.printPage.addText(doc, formKey.value, margin.x + marginXInRect + 3, last.lastY + margin.y, {maxWidth: spaceInRect});
                last = {lastX: wRect, lastY: yPosition + hRect}
            }
            return last;
        }

    }


    async addPageScheduleMaintenance(doc: jsPDF, schedaManutenzione: SchedaManutenzioneCompilataParse, allImages: {
        image: HTMLImageElement,
        traduction: string,
        fullSize: boolean
    }[] = [], activities: {
        activity: CommentiSegnalazioniParse,
        HTMLImage: HTMLImageElement | undefined
    }[], usersDetail) {
        doc.addPage();
        const marginx = 10
        const marginy = 3
        const width = doc.internal.pageSize.getWidth() - marginx * 2

        const ndTraduction = this.translateService.instant('NaN');


        let last = {lastX: 10, lastY: this.printPage.headHeight + 5 + 3};
        if (isNotNullOrUndefined(schedaManutenzione.circuito)) {
            const circuitoTraduction = this.translateService.instant('Circuito');
            last = this.printPage.addH4(doc, circuitoTraduction, width / 2 + marginx / 2, last.lastY)
            last = this.createCircuitoHeader(doc, schedaManutenzione, schedaManutenzione.circuito, last, {
                x: marginx,
                y: marginy
            }, usersDetail, false);

        } else if (isNotNullOrUndefined(schedaManutenzione.puntoLuce)) {
            const puntoLuceTraduction = this.translateService.instant('PuntiLuce');
            last = this.printPage.addH4(doc, puntoLuceTraduction, width / 2 + marginx / 2, last.lastY)
            last = this.createPuntoLuceHeader(doc, schedaManutenzione, schedaManutenzione.puntoLuce, last, {
                x: marginx,
                y: marginy
            }, usersDetail, false);

        } else if (isNotNullOrUndefined(schedaManutenzione.segnalazione)) {
            const segnlazioniTraduction = this.translateService.instant('Segnalazioni');
            last = this.printPage.addH4(doc, segnlazioniTraduction, width / 2 + marginx / 2, last.lastY)
            const segnalazione: SegnalazioniParse = schedaManutenzione.segnalazione as SegnalazioniParse;
            last = this.createSegnalazioneHeader(doc, schedaManutenzione, segnalazione, last, {
                x: marginx,
                y: marginy
            }, usersDetail)

            const marginXInRect = 3;
            const wRect = doc.internal.pageSize.getWidth() / 2;
            const spaceInRect = wRect - marginXInRect * 2;


            // centrale se non è presente la descrizione
            let xPosition = {description: undefined, image: undefined};


            const yPosition = last.lastY + marginy;
            // necessario nelle stampe dei reports senza schedacompilata
            const idImage = stringIsSet(schedaManutenzione.objectId) ? schedaManutenzione.objectId : segnalazione.objectId
            const image = this.getImageWitTraduction(allImages, idImage);

            let descriptions;
            if (stringIsSet(segnalazione.descrizione)) {
                descriptions = segnalazione.descrizione
                    .replace('\r', '\n')
                    .split('\n')
                    .filter(s => stringIsSet(s))
            }
            const descriptionIsSet = (arrayIsSet(descriptions) && descriptions.length > 1) || stringIsSet(segnalazione.descrizione)
            if (isNotNullOrUndefined(image) && descriptionIsSet) {
                xPosition = {
                    image: doc.internal.pageSize.getWidth() * (1 / 4),
                    description: doc.internal.pageSize.getWidth() * (2 / 4)
                }
            } else if (isNotNullOrUndefined(image) && !descriptionIsSet) {
                xPosition = {
                    image: doc.internal.pageSize.getWidth() * (2 / 4),
                    description: undefined
                }
            } else if (!isNotNullOrUndefined(image) && descriptionIsSet) {
                xPosition = {
                    image: undefined,
                    description: 0
                }
            }


            if (isNotNullOrUndefined(image) && typeof xPosition.image == "number") {
                let propertyImage = doc.getImageProperties(image);
                const ratio = propertyImage.width / propertyImage.height;
                const {
                    width,
                    height
                } = this.getSizeImageWithMaxSize(doc.internal.pageSize.getWidth() / 2 - marginx * 2, ratio);

                const foto = this.translateService.instant('segnalazioniParse.foto.title')
                const sizetraduction = doc.getTextDimensions(foto, {maxWidth: spaceInRect})
                // const spaceY = this.printPage.addText(doc, foto, xPosition - width / 2, last.lastY + marginy, {maxWidth: spaceInRect},true).lastY
                const spaceY = this.printPage.addBoldString(doc, foto, xPosition.image - width / 2, yPosition + marginy, "left").lastY -= doc.getFontSize() / 2;
                const hRect = height + marginy * 3 + sizetraduction.h;
                const wRect = doc.internal.pageSize.getWidth() - marginx * 2;
                doc.addImage(image, xPosition.image - width / 2, spaceY, width, height);
                last = {lastX: wRect, lastY: yPosition + hRect}
            }

            if (descriptionIsSet && typeof xPosition.description == "number") {
                const descrizione = this.translateService.instant('segnalazioniParse.descrizione.title')
                const spaceY = this.printPage.addBoldString(doc, descrizione, xPosition.description + marginx + marginXInRect + 3, yPosition + marginy, "left").lastY -= doc.getFontSize() / 2;
                let localLast = {lastX: 0, lastY: spaceY + marginy}
                if (arrayIsSet(descriptions) && descriptions.length > 1) {
                    descriptions.forEach(d => {
                        const heigthText = doc.getTextDimensions(d, {maxWidth: spaceInRect}).h;
                        if (localLast.lastY + heigthText + 10 + marginy > doc.internal.pageSize.getHeight()) {
                            doc.addPage();
                            localLast = {lastX: 10, lastY: this.printPage.headHeight + 5 + 3};
                            last.lastY = localLast.lastY;
                        }
                        localLast = this.printPage.addText(doc, d, xPosition.description + marginx + marginXInRect + 3, localLast.lastY + marginy, {maxWidth: spaceInRect})
                    })
                } else {
                    // without carriage return and new line
                    const numberLength = segnalazione.descrizione.length;
                    let i = 1;
                    let localString = '';
                    let lastNumber = 0;
                    while (i < numberLength) {
                        localString = segnalazione.descrizione.slice(lastNumber, i);
                        const heigthText = doc.getTextDimensions(localString, {maxWidth: spaceInRect}).h;
                        if (localLast.lastY + heigthText + 10 + marginy > doc.internal.pageSize.getHeight()) {
                            this.printPage.addText(doc, localString, xPosition.description + marginx + marginXInRect + 3, localLast.lastY + marginy, {maxWidth: spaceInRect})
                            doc.addPage();
                            localLast = {lastX: 10, lastY: this.printPage.headHeight + 5 + 3};
                            last.lastY = localLast.lastY;
                            lastNumber = i - 1;
                        }
                        i++;
                    }
                    localLast = this.printPage.addText(doc, localString, xPosition.description + marginx + marginXInRect + 3, localLast.lastY + marginy, {maxWidth: spaceInRect})
                }

                const lastDescription = localLast;
                if (lastDescription.lastY + marginy > last.lastY + marginy) {
                    last.lastY = lastDescription.lastY + marginy;
                } else {
                    last.lastY += marginy;
                }
                // doppia colonna centrale
            }
            doc.line(10, last.lastY, 200, last.lastY);
            last.lastY += marginy;
            if (last.lastY > doc.internal.pageSize.getHeight() / 2 + 10) {
                doc.addPage();
                last = {lastX: 10, lastY: this.printPage.headHeight + 5 + 3};
            }
            // doc.addPage();
            // last.lastY = this.printPage.headHeight + marginy;


            if (segnalazione.circuito) {
                last = this.printPage.addH4(doc, this.translateService.instant('Circuito') + ' ' + this.translateService.instant('segnalazioniParse.elementReports'), width / 2 + marginx / 2, last.lastY + marginy)
                last = this.createCircuitoHeader(doc, schedaManutenzione, segnalazione.circuito, last, {
                    x: marginx,
                    y: marginy
                }, usersDetail, true);
            } else if (arrayIsSet(segnalazione.puntiLuce)) {
                last = this.printPage.addH4(doc, this.translateService.instant('PuntiLuce') + ' ' + this.translateService.instant('segnalazioniParse.elementReports'), width / 2 + marginx / 2, last.lastY + marginy)

                let keyInTable;
                const index = segnalazione.puntiLuce.findIndex(pl => pl.targaCustom)
                if (index >= 0) {
                    keyInTable = ['targa', 'targaCustom', 'indirizzo']
                } else {
                    keyInTable = ['targa', 'indirizzo']
                }
                const dataTable = segnalazione.puntiLuce
                    .map(puntoLuce => {
                        const obj = {}
                        keyInTable.forEach(key => {
                            const traduction = 'dashboard_sidenav.PuntiLuce.' + key + '.title';
                            obj[traduction] = puntoLuce[key] ? puntoLuce[key] : ndTraduction;
                        })
                        return obj;
                    })
                last = this.printPage.addTable(doc, dataTable, null, "horizzontal", last.lastY, "auto", false)
                last.lastY += marginy * 3;
            }


        }

        if (!isNotNullOrUndefined(schedaManutenzione.segnalazione)) {

            const reportTraduction = this.translateService.instant('reportTitlePrint');
            last = this.printPage.addH4(doc, reportTraduction, width / 2 + marginx / 2, last.lastY);
            if (arrayIsSet(schedaManutenzione.form)) {
                schedaManutenzione.form
                    .forEach((formKey) => {
                        const formKeyType = {
                            ...formKey,
                            type: this.getTypeToVisualized(formKey.value),
                            schedaManutenzioneCompilataId: schedaManutenzione.objectId
                        }
                        if (schedaManutenzione.schedaManutenzione && arrayIsSet(schedaManutenzione.schedaManutenzione.form)) {
                            const index = schedaManutenzione.schedaManutenzione.form.findIndex((formNotCompiled => formNotCompiled.traduction == formKey.traduction))
                            if (index >= 0) {
                                formKeyType.type = this.getTypeToVisualizedByFormfieldType(schedaManutenzione.schedaManutenzione.form[index].type, formKeyType.value)
                            }
                        }
                        if (this.addFormKey(doc, {
                            x: marginx,
                            y: marginy
                        }, allImages, formKeyType, last, true).lastY > doc.internal.pageSize.getHeight() - 10) {
                            doc.addPage();
                            last.lastY = this.printPage.headHeight + 10;
                            last = this.addFormKey(doc, {x: marginx, y: marginy}, allImages, formKeyType, last, false)
                        } else {
                            last = this.addFormKey(doc, {x: marginx, y: marginy}, allImages, formKeyType, last, false)
                        }
                    })
            }

        } else if (arrayIsSet(activities)) {
            if (last.lastY > doc.internal.pageSize.getHeight() / 2 + 10) {
                doc.addPage();
                last = {lastX: 10, lastY: this.printPage.headHeight + 5 + 3};
            }
            const marginXInRect = 3;
            const wRect = 190;
            const rBorder = 2.5;
            const spaceInRect = wRect - marginXInRect * 2;
            const activityTraduction = this.translateService.instant('list_activity');
            const reportCompilati: {
                activity: CommentiSegnalazioniParse,
                pageNumber: number,
                lastHeader: { lastX: number, lastY: number }
            }[] = [];
            last = this.printPage.addH4(doc, activityTraduction, width / 2 + marginx / 2, last.lastY)
            activities.forEach(activityImage => {
                const activity = activityImage.activity;
                const image = activityImage.HTMLImage
                const header = activity.nomeAutore + ' ' + formatDate(activity.createdAt, 'medium', this.translateService.currentLang);
                const sizeHeader = doc.getTextDimensions(header, {maxWidth: spaceInRect})
                let value;
                let lastTextToAdLink;
                if (image) {
                    let propertyImage = doc.getImageProperties(image);
                    const ratio = propertyImage.width / propertyImage.height;
                    const {width, height} = this.getSizeImageWithMaxSize(doc.internal.pageSize.getHeight() / 4, ratio);
                    const xPosition = doc.internal.pageSize.getWidth() / 2 - width / 2
                    const hRect = height + marginy * 3 + sizeHeader.h;
                    const wRect = doc.internal.pageSize.getWidth() - marginx * 2;
                    if (last.lastY + hRect > doc.internal.pageSize.getHeight() - this.printPage.headHeight - 5) {
                        doc.addPage();
                        last = {lastX: 10, lastY: this.printPage.headHeight + 5 + 3};
                    }
                    doc.addImage(image, xPosition, last.lastY + marginy * 3, width, height);
                    doc.roundedRect(10, last.lastY, wRect, hRect, rBorder, rBorder, 'S');
                    this.printPage.addText(doc, header, marginx + marginXInRect, last.lastY + marginy * 2, {maxWidth: spaceInRect});
                    lastTextToAdLink = {lastX: marginx + marginXInRect + sizeHeader.w, lastY: last.lastY + marginy * 2}
                    last = {lastX: wRect, lastY: last.lastY + hRect + marginy}
                } else if (activity.allegato && activity.allegato.name && stringIsSet(activity.allegato.name())) {
                    value = activity.allegato.name();
                    const sizeValue = doc.getTextDimensions(value, {maxWidth: spaceInRect});
                    const hRect = sizeHeader.h + marginy / 2 + sizeValue.h + marginy * 2;
                    if (last.lastY + hRect > doc.internal.pageSize.getHeight() - this.printPage.headHeight - 5) {
                        doc.addPage();
                        last = {lastX: 10, lastY: this.printPage.headHeight + 5 + 3};
                    }
                    doc.roundedRect(10, last.lastY, wRect, hRect, rBorder, rBorder, 'S');
                    this.printPage.addText(doc, header, marginx + marginXInRect, last.lastY + marginy * 2, {maxWidth: spaceInRect});
                    lastTextToAdLink = {lastX: marginx + marginXInRect + sizeHeader.w, lastY: last.lastY + marginy * 2}
                    doc.setTextColor(0, 84, 194);
                    doc.textWithLink(value, marginx + marginXInRect + 3, last.lastY + marginy * 2 + marginy / 2 + sizeHeader.h, {url: activity.allegato.url()})
                    doc.setTextColor(0, 0, 0);
                    // this.printPage.addText(doc, value, marginx + marginXInRect + 3, last.lastY + marginy * 2 + marginy / 2 + sizeHeader.h, {maxWidth: spaceInRect});
                    last = {lastX: wRect, lastY: last.lastY + hRect + marginy}
                } else {
                    let sizeH = marginy / 2 + sizeHeader.h + marginy * 2;
                    let maxLengthCommentState = 0;

                    const textToPrintCoordinate: {
                        x: number,
                        y: number,
                        value: string,
                        align: undefined | string
                    }[] = []

                    if (stringIsSet(activity.nuovoStato)) {
                        const tradStatoPrecedente = this.translateService.instant('segnalazioniParse.stato.' + activity.statoPrecedente)
                        const tradNuovoStato = this.translateService.instant('segnalazioniParse.stato.' + activity.nuovoStato)
                        value = '';
                        if (tradStatoPrecedente.includes('segnalazioniParse.stato.')) {
                            value = value + activity.statoPrecedente
                        } else {
                            value = value + tradStatoPrecedente;
                        }
                        value = value + ' >> ';
                        if (tradNuovoStato.includes('segnalazioniParse.stato.')) {
                            value = value + activity.nuovoStato
                        } else {
                            value = value + tradNuovoStato;
                        }
                        const sizeValue = doc.getTextDimensions(value, {maxWidth: spaceInRect});
                        if (sizeValue.w > maxLengthCommentState) {
                            maxLengthCommentState = sizeValue.w
                        }

                        textToPrintCoordinate.push({
                            x: marginx + marginXInRect + 3,
                            y: sizeH,
                            value: value,
                            align: undefined
                        })
                        // this.printPage.addText(doc, value, marginx + marginXInRect + 3, last.lastY + sizeH, {maxWidth: spaceInRect});
                        sizeH += sizeValue.h + marginy / 2
                    }
                    if (stringIsSet(activity.comment)) {
                        value = activity.comment
                        const sizeValue = doc.getTextDimensions(value, {maxWidth: spaceInRect});
                        if (sizeValue.w > maxLengthCommentState) {
                            maxLengthCommentState = sizeValue.w
                        }
                        textToPrintCoordinate.push({
                            x: marginx + marginXInRect + 3,
                            y: sizeH,
                            value: value,
                            align: undefined
                        })
                        // this.printPage.addText(doc, value, marginx + marginXInRect + 3, last.lastY + sizeH, {maxWidth: spaceInRect});
                        sizeH += sizeValue.h + marginy / 2
                    }
                    if (this.visualizeObjectUser(activity, usersDetail, ndTraduction)) {
                        value = this.getValueUserToPrint(activity.objectUser, usersDetail, ndTraduction)
                        const sizeValue = doc.getTextDimensions(value, {maxWidth: spaceInRect});
                        textToPrintCoordinate.push({
                            x: marginx + marginXInRect + 3 + maxLengthCommentState / 2,
                            y: sizeH,
                            value: value,
                            align: "center"
                        })
                        sizeH += sizeValue.h + marginy / 2
                    }
                    // const sizeValue = doc.getTextDimensions(value, {maxWidth: spaceInRect});
                    const hRect = sizeH;
                    if (last.lastY + hRect > doc.internal.pageSize.getHeight() - this.printPage.headHeight - 5) {
                        doc.addPage();
                        last = {lastX: 10, lastY: this.printPage.headHeight + 5 + 3};
                    }
                    doc.roundedRect(10, last.lastY, wRect, hRect, rBorder, rBorder, 'S');
                    this.printPage.addText(doc, header, marginx + marginXInRect, last.lastY + marginy * 2, {maxWidth: spaceInRect});
                    lastTextToAdLink = {lastX: marginx + marginXInRect + sizeHeader.w, lastY: last.lastY + marginy * 2}
                    textToPrintCoordinate.forEach(item => {
                        const options = {maxWidth: spaceInRect}
                        if (item.align) {
                            options['align'] = item.align;
                        }
                        this.printPage.addText(doc, item.value, item.x, last.lastY + item.y, options);
                    })
                    last = {lastX: wRect, lastY: last.lastY + hRect + marginy}
                }

                if (activity.schedaManutenzioneCompilata) {
                    reportCompilati.push({
                        activity: activity,
                        pageNumber: doc.getCurrentPageInfo().pageNumber,
                        lastHeader: lastTextToAdLink
                    })
                }
            })

            if (arrayIsSet(reportCompilati)) {
                last.lastY += marginy
                if (last.lastY > doc.internal.pageSize.getHeight() / 2 + 10) {
                    doc.addPage();
                    last = {lastX: 10, lastY: this.printPage.headHeight + 5 + 3};
                }

                reportCompilati.forEach(activityPage => {
                    const schedaManutenzioneCompilata: SchedaManutenzioneCompilataParse = activityPage.activity.schedaManutenzioneCompilata
                    if (arrayIsSet(schedaManutenzioneCompilata.form)) {
                        const page = '(' + this.translateService.instant('pg') + '. ' + (activityPage.pageNumber - 1) + ')';
                        const title = this.translateService.instant('reportTitlePrint') + ' ' + formatDate(activityPage.activity.createdAt, 'medium', this.translateService.currentLang)


                        const sizeTitle = doc.getTextDimensions(title)
                        const sizepage = doc.getTextDimensions(page)

                        if (last.lastY + sizeTitle.h > doc.internal.pageSize.getHeight() - 20) {
                            doc.addPage()
                            last.lastY = this.printPage.headHeight + 10;
                        }

                        if (activityPage.lastHeader) {
                            const lastTemp = activityPage.lastHeader
                            const currentPage = doc.getCurrentPageInfo().pageNumber;
                            const subPage = '(' + this.translateService.instant('pg') + '. ' + (currentPage - 1) + ')'
                            const sizeSubPage = doc.getTextDimensions(page)
                            doc.setFontSize(doc.getFontSize() - 2);
                            doc.setPage(activityPage.pageNumber)
                            this.printPage.addTextWithLink(doc, subPage, lastTemp.lastX + marginx / 2 + sizeSubPage.w / 2, lastTemp.lastY, {pageNumber: currentPage})
                            doc.setPage(currentPage)
                            this.printPage.setDefaultText(doc)
                        }
                        this.printPage.setH4Text(doc);


                        const lastTemp = this.printPage.addText(doc, title, width / 2 + marginx / 2, last.lastY + marginy,)
                        this.printPage.setDefaultText(doc);
                        this.printPage.addTextWithLink(doc, page, lastTemp.lastX + marginx / 2 + sizepage.w / 2, last.lastY + marginy - (sizeTitle.h - sizepage.h) / 2, {pageNumber: activityPage.pageNumber})
                        last = lastTemp;
                        schedaManutenzioneCompilata.form
                            .forEach((formKey) => {
                                const formKeyType = {
                                    ...formKey, type: this.getTypeToVisualized(formKey.value),
                                    schedaManutenzioneCompilataId: schedaManutenzioneCompilata.objectId
                                }
                                if (schedaManutenzioneCompilata.schedaManutenzione && arrayIsSet(schedaManutenzioneCompilata.schedaManutenzione.form)) {
                                    const index = schedaManutenzioneCompilata.schedaManutenzione.form.findIndex((formNotCompiled => formNotCompiled.traduction == formKey.traduction))
                                    if (index >= 0) {
                                        formKeyType.type = this.getTypeToVisualizedByFormfieldType(schedaManutenzioneCompilata.schedaManutenzione.form[index].type, formKeyType.value)
                                    }
                                }
                                if (this.addFormKey(doc, {
                                    x: marginx,
                                    y: marginy
                                }, allImages, formKeyType, last, true).lastY > doc.internal.pageSize.getHeight() - 10) {
                                    doc.addPage();
                                    last.lastY = this.printPage.headHeight + 10;
                                    last = this.addFormKey(doc, {
                                        x: marginx,
                                        y: marginy
                                    }, allImages, formKeyType, last, false)
                                } else {
                                    last = this.addFormKey(doc, {
                                        x: marginx,
                                        y: marginy
                                    }, allImages, formKeyType, last, false)
                                }
                                last.lastY += marginy
                            })
                    }


                })
            }


        }

    }

    addHeader(doc: jsPDF, project: ProgettiParse, today: string, reports): jsPDF {
        this.printPage.addHeader(doc, project.organizzazione, project.name, today, reports)
        return doc;
    }

    downloadPDf(doc: jsPDF, name: string) {
        this.printPage.convertToPdf(doc, name)
        return doc;
    }

    getTypeToVisualized(value): 'default' | 'bool' | 'date' | 'image' | 'multipleImage' | 'hour' {
        if (!isNotNullOrUndefined(value)) {
            return 'default'
        } else if (typeof value == "boolean") {
            return 'bool'
        } else if (value instanceof Date) {
            return 'date'
        } else if (value.url) {
            return 'image'
        } else if (arrayIsSet(value) && value[0].className != null && value[0].className == className.documentsFile) {
            return 'multipleImage'
        } else {
            return 'default'
        }
    }

    getTypeToVisualizedByFormfieldType(valueType, value = undefined) {
        if (!isNotNullOrUndefined(valueType)) {
            return 'default'
        } else if (valueType == typeFormFieldMaintenance.checkBox || valueType == typeFormFieldMaintenance.yesOrNot) {
            return 'bool'
        } else if (valueType == typeFormFieldMaintenance.dataPicker) {
            return 'date'
        } else if (valueType == typeFormFieldMaintenance.image) {
            return arrayIsSet(value) && value[0].className == className.documentsFile ? 'multipleImage' : 'image'
        } else if (valueType == typeFormFieldMaintenance.hour) {
            return 'hour'
        } else {
            return 'default'
        }
    }



}
