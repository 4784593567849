import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputErrorsComponent } from './input-errors/input-errors.component';
import {ReactiveFormsModule} from "@angular/forms";
import {MatLegacyInputModule as MatInputModule} from "@angular/material/legacy-input";



@NgModule({
  declarations: [InputErrorsComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule
  ],
  exports: [InputErrorsComponent]
})
export class InputWithErrorsModule { }
