import * as Parse from 'parse';


export class ComuniParse extends Parse.Object {
  public static CLASS_NAME = 'Comuni';
  constructor() {
    super(ComuniParse.CLASS_NAME);
  }

  public set objectId(value) {
    super.set('objectId', value);
  }
  public get objectId() {
    return super.get('objectId');
  }

  public set updatedAt(value) {
    super.set('updatedAt', value);
  }
  public get updatedAt() {
    return super.get('updatedAt');
  }

  public set createdAt(value) {
    super.set('createdAt', value);
  }
  public get createdAt() {
    return super.get('createdAt');
  }

  public set ACL(value) {
    super.set('ACL', value);
  }
  public get ACL() {
    return super.get('ACL');
  }

  public set codiceComune(value) {
    super.set('codiceComune', value);
  }
  public get codiceComune() {
    return super.get('codiceComune');
  }

  public set confiniAmministrativiFile(value) {
    super.set('confiniAmministrativiFile', value);
  }
  public get confiniAmministrativiFile() {
    return super.get('confiniAmministrativiFile');
  }

  public set countryCode(value) {
    super.set('countryCode', value);
  }
  public get countryCode() {
    return super.get('countryCode');
  }

  public set lunghezzaPerimetro(value) {
    super.set('lunghezzaPerimetro', value);
  }
  public get lunghezzaPerimetro() {
    return super.get('lunghezzaPerimetro');
  }

  public set nazione(value) {
    super.set('nazione', value);
  }
  public get nazione() {
    return super.get('nazione');
  }

  public set nome(value) {
    super.set('nome', value);
  }
  public get nome() {
    return super.get('nome');
  }

  public set nomeRicerca(value) {
    super.set('nomeRicerca', value);
  }
  public get nomeRicerca() {
    return super.get('nomeRicerca');
  }

  public set numeroAbitanti(value) {
    super.set('numeroAbitanti', value);
  }
  public get numeroAbitanti() {
    return super.get('numeroAbitanti');
  }

  public set numeroAbitantiUomo(value) {
    super.set('numeroAbitantiUomo', value);
  }
  public get numeroAbitantiUomo() {
    return super.get('numeroAbitantiUomo');
  }

  public set numeroAbitantiDonna(value) {
    super.set('numeroAbitantiDonna', value);
  }
  public get numeroAbitantiDonna() {
    return super.get('numeroAbitantiDonna');
  }

  public set regione(value) {
    super.set('regione', value);
  }
  public get regione() {
    return super.get('regione');
  }

  public set superficie(value) {
    super.set('superficie', value);
  }
  public get superficie() {
    return super.get('superficie');
  }

}

Parse.Object.registerSubclass(ComuniParse.CLASS_NAME, ComuniParse);
