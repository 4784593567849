import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MatLegacyTabGroup as MatTabGroup} from "@angular/material/legacy-tabs";
import { Subscription} from "rxjs";
import {isNotNullOrUndefined} from "../../../models/Models";

@Component({
    selector: 'app-open-new-tab',
    templateUrl: './open-new-tab.component.html',
    styleUrls: ['./open-new-tab.component.scss']
})
export class OpenNewTabComponent implements OnInit, OnChanges, OnDestroy {
    @Input()
    openedList = [];
    @Input()
    whatOpen: 'plan' | 'report' | 'street' | 'schedeManutenzione';
    @Input()
    visualezedButtonSeaMap = true;
    // se viene richiamato openNewTab all interno di openNewTab bisogna passare settare a true per eliminare i margini del  sub mat-tab-group
    @Input()
    isSubGroup = false;

    @Output()
    itemModified = new EventEmitter<{
        action: 'remove' | 'create' | 'close',
        item
    }>();
    @Output()
    updateItem = new EventEmitter();

    private subscriptionIndexChange: Subscription;

    @Output()
    currentIndex = new EventEmitter<{ value: number }>();

    @Output()
    updateList = new EventEmitter<void>();
    public newPlan = 'newPlan';
    @Input()
    public indexTab;
    @Input()
    public emitIndex = false;
    @Input()
    public forceUpdate;
    @Input()
    public photometries;
    @Input()
    public gruppiFotomotrie;
    @Input() forceUpdateItemByList;
    @ViewChild('matTabGroup') matTabGroup: MatTabGroup;


    constructor() {
    }

    ngOnInit(): void {
    }

    ngOnChanges(change): void {
        if (isNotNullOrUndefined(this.matTabGroup) && !isNotNullOrUndefined(this.subscriptionIndexChange) && this.emitIndex) {
            this.subscriptionIndexChange = this.matTabGroup.selectedIndexChange.subscribe(index => this.currentIndex.next({value:index}))
        }
        if (isNotNullOrUndefined(this.matTabGroup) && 'openedList' in change) {
            const previouslySelectedTab = change.openedList.previousValue[this.matTabGroup.selectedIndex - 1];
            if (change.openedList.currentValue.length > change.openedList.previousValue.length) {
                // a new tab has been opened
                this.matTabGroup.selectedIndex = 1; // the new tab must be selected
            } else if (!change.openedList.currentValue.some((tab) => tab === previouslySelectedTab)) {
                // the selected tab has been closed
                this.matTabGroup.selectedIndex = this.matTabGroup.selectedIndex - 1;
            } else if (change.openedList.currentValue.length < change.openedList.previousValue.length) {
                // another tab has been closed
                this.matTabGroup.selectedIndex = change.openedList.currentValue.findIndex((tab) => tab === previouslySelectedTab) + 1;
            }
        }else  if (change.indexTab&&this.matTabGroup!=null) {
            this.matTabGroup.selectedIndex = this.indexTab.value
        }

        // if (!isNotNullOrUndefined(change.indexTab)) this.indexTab = (this.openedList.length > 0) ? 1 : 0;
    }

    ngOnDestroy(): void {
        if (isNotNullOrUndefined(this.subscriptionIndexChange)) {
            this.subscriptionIndexChange.unsubscribe();
        }
    }


    public newPlanIsReady(event) {
        const newPlan = {
            objectId: event.objectId,
            titolo: event.nome,
            priority: this.newPlan
        };
        this.itemModified.emit({
            action: "create",
            item: newPlan
        });
    }

    public closeTab(event, removeReports) {
        event.stopPropagation();
        this.itemModified.emit({
            action: "close",
            item: removeReports
        });
    }


    public removedPlan(event) {
        const remove = {
            objectId: event.objectId,
            titolo: null,
            priority: null
        };
        this.itemModified.emit({
            action: "remove",
            item: remove
        });
    }

    updateItemEmit(emit, street) {
        street.titolo = emit.nome;
        street.objectId = emit.objectId;
        this.updateItem.next(emit)
    }
}
