<app-loader [loaderInput]="loading"></app-loader>
<ng-container *ngIf="searchEnabled">
    <div style="display: flex; flex-direction:column;  margin-top: 0.5rem;">
        <form [formGroup]="searchForm" class="form-search">
            <mat-form-field floatLabel="auto" appearance="fill">
                <mat-label>{{'paginator.search' | translate}}</mat-label>
                <input formControlName="search" matInput>
            </mat-form-field>
            <div class="w100 flex-around-center flex-flow-row" style="background: white">
                <div class="width-button-paginator flex-center-Between flex-flow-row" style="margin: 5px">
                    <app-chips-multiselect-form-field [possibleValues]="keyChipsActivedFilter"
                                                      [showMenu]="true"
                                                      [otherValues]="otherValueCount$|async"
                                                      formControlName="filterActived"
                    >
                    </app-chips-multiselect-form-field>
                </div>
                <div class="width-paginator" *ngIf="totalCount > 0">
                    <mat-paginator [length]="totalCount" [pageSize]="take" [pageSizeOptions]="pageSizeOptions"
                                   (page)="onChangePage($event)" showFirstLastButtons>
                    </mat-paginator>
                </div>
            </div>
        </form>
    </div>
</ng-container>
<div>
    <div class="example-container " style="border-top: 1px solid #0000001F" *ngIf="totalCount > 0">
        <div class="scrollbar-visible table-scroll-wrapper">
            <div style="min-width: max-content;">
                <table mat-table
                    [dataSource]="items|filterByPredicateFunction:predicateFilter:(this.searchFilter$|async):objectValue"
                    matSort (matSortChange)="onSortChange($event)" [formGroup]="form"
                    class="table-center-title">
                    <ng-container *ngFor="let column of visibleColumns">
                        <ng-container [matColumnDef]="column.property" [sticky]="column.sticky==true">
                            <th style="padding: 0 10px;" mat-header-cell *matHeaderCellDef mat-sort-header
                                [disabled]="column.orderable === undefined ? false : !column.orderable">
                                <ng-container *ngIf="!(selectItem&&column.property=='checkBox');else CheckBox">
                                    {{column.label|translate}}
                                </ng-container>
                                <ng-template #CheckBox>
                                    <mat-checkbox (click)="$event.stopPropagation();"
                                                (change)="clickChecBoxAll($event.checked)"
                                                [checked]="checkAll"
                                    >
                                    </mat-checkbox>
                                </ng-template>

                            </th>
                            <td *matCellDef="let element" mat-cell style=" padding:0 10px;"
                            >
                                <ng-container [ngSwitch]="column.type">
                                    <ng-container *ngSwitchCase="'groupPhotometry'">
                                        <button mat-flat-button
                                                (click)="$event.stopPropagation();clickGroupPhotometryEmit(element)"
                                                color="accent" style="width: 150px;font-weight: bold!important;"
                                        >
                                            <ng-container
                                                    *ngIf=" column.render(element[column.property])==null;else namePhotometry">
                                                <mat-icon>add</mat-icon>
                                            </ng-container>
                                            <ng-template #namePhotometry>
                                                <span appTruncateMiddle
                                                    [inputText]="column.render(element[column.property])">
                                                </span>
                                            </ng-template>
                                        </button>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'photometry'">
                                        <button mat-flat-button (click)="$event.stopPropagation();clickPhotometryEmit(element)"
                                                color="accent"
                                                style="width: 200px;font-weight: bold!important;">
                                            <ng-container
                                                    *ngIf="column.render(element[column.property])==null;else namePhotometry">
                                                <mat-icon>add</mat-icon>
                                            </ng-container>
                                            <ng-template #namePhotometry>
                                            <span appTruncateMiddle *ngIf="
                                                column.render
                                                    ? column.render(element[column.property])
                                                    : element[column.property]
                                                as name"
                                                [inputText]="name">
                                                </span>
                                            </ng-template>
                                        </button>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'checkBox'">
                                        <ng-container [formGroupName]="element.objectId">
                                            <div class="flex-center-width100">
                                                <mat-checkbox
                                                        (click)="$event.stopPropagation();"
                                                        (change)="clickOtherPropertyEmit(element,column.property,$event.checked)"
                                                        [formControlName]="column.property">
                                                </mat-checkbox>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'transformValue'">
                                        <ng-container>
                                            <div class="flex-center-width100"
                                                *ngIf=" element|convertValueByPredicateFunction:transformFunction:objectValue as value">
                                                <ng-container *ngIf="value.traduction ||value.notTraduction;else NoMatTooltip">
                                                    <mat-icon *ngIf="value.icon" [color]="value.icon.color"
                                                            [matTooltip]="value.notTraduction??value.traduction|translate"
                                                    >
                                                        {{value.icon.material}}
                                                    </mat-icon>
                                                </ng-container>
                                                <ng-template #NoMatTooltip>
                                                    <mat-icon *ngIf="value.icon" [color]="value.icon.color"
                                                    >
                                                        {{value.icon.material}}
                                                    </mat-icon>
                                                </ng-template>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngSwitchDefault>
                                        <div style="width: 200px" [ngStyle]="{textAlign:column.textAlign,width:column.width}">
                                            {{
                                            column.render
                                                ? column.render(element[column.property])
                                                : element[column.property]
                                            }}
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </td>
                        </ng-container>

                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="visibleColumnProperties"></tr>
                    <tr mat-row *matRowDef="let row; columns: visibleColumnProperties" (click)="onItemClick(row)"
                        [ngStyle]="{cursor: clickableRows ? 'pointer' : 'inherit'}"></tr>
                </table>
            </div>
        </div>
    </div>
</div>
<ng-container *ngIf="error">
    <div fxLayout="column" [style.height]="'100%'" fxLayoutAlign="left left" class="cmp">
        <app-template-form title="{{'error' | translate}}!" [informativa]="false"
                           subtitle="{{'plans_error' | translate}}">
            <div class="spacer"><h4>{{errorMessage}}</h4>
                {{'contact_info'| translate}}
                <!-- {{'platform_mainten' | translate}} -->
                <a mat-icon-button color="accent" class="email" style="text-decoration: underline"
                   href="mailto:info@huna.io"
                   label="Email">
                    <mat-icon>info</mat-icon>
                </a>
            </div>
        </app-template-form>
    </div>
</ng-container>
<ng-container *ngIf="!loading && !error && totalCount === 0">
    <div fxLayout="column" [style.height]="'100%'" fxLayoutAlign="left left" class="cmp">
        <app-template-form title="{{noResultKey | translate}}" [informativa]="false"></app-template-form>
    </div>
</ng-container>

