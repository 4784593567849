import {Injectable} from '@angular/core';
import {ParseApiService} from './parse-api.service';
import * as Parse from 'parse';
import {ProjectService} from './project.service';
import {fromPromise} from 'rxjs/internal-compatibility';
import {TranslateService} from '@ngx-translate/core';
import {UtilsService} from './utils.service';
import {UserService} from "./user.service";
import {IoTDevicesParse} from "../../models/IoTDevices.Parse";
import {CircuitiParse} from "../../models/Circuiti.Parse";
import {EMPTY, Observable, of, throwError} from "rxjs";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {CircuitiService} from "./circuiti.service";
import {ComandiParse} from "../../models/Comandi.Parse";
import {SegnaliTlcNodoParse} from "../../models/SegnaliTlcNodo.Parse";
import {
    arrayIsSet, className, fetchParseObject$, getUniqueValueInArray, hunaChartColors,
    isNotNullOrUndefined, isValidDate,
    PaginationParserequestType,
    paramsApiParse, paramsApiParse_v2,
    stringIsSet
} from "../../models/Models";
import {SettaggiTlcCircuitiParse} from "../../models/SettaggiTlcCircuiti.Parse";
import {catchError, expand, map,} from "rxjs/operators";
import {OrariAccensioneCircuitiParse} from "../../models/OrariAccensioneCircuiti.Parse";
import {SegnaliTlcCircuitiParse} from "../../models/SegnaliTlcCircuiti.Parse";
import {CHART_COLORS} from "../../../config/static-data";
import {Chart} from "chart.js";
import {SettaggiTlcNodoParse} from "../../models/SettaggiTlcNodo.Parse";
import {ChartServiceService} from "./chart-service.service";
import {IconService} from "./icon.service";
import {CustomDatePipe} from "../pipes/custom-date.pipe";
import {formatDate} from "@angular/common";
import {PuntiLuceParse} from "../../models/PuntiLuce.Parse";
import {SegnaliTlcNodoChangesHistoryParse} from "../../models/SegnaliTlcNodoChangesHistory.Parse";

export type GraficoOrarioSettingsType = {
    title?: string, limits?: { min?: number, max?: number }
}
export type KeysInGraficoOrario = 'energiaImportata' | 'potenzaMinima' | 'potenzaMassima' | 'fattoreDiPotenza';
export type GraficoOrarioDataChartType = {
    date: Date,
    key?: string,
    ora?: number
} & { [k in KeysInGraficoOrario]?: number }
export type GraficoOrarioType = {
    settingsChart: {
        [k in KeysInGraficoOrario]?: { x?: GraficoOrarioSettingsType, y?: GraficoOrarioSettingsType, title: string }
    },
    dataChart: GraficoOrarioDataChartType[],
    possibleKeysData: string[]
}
export type GraficoRealTimeSettingsType = {
    title?: string, limits?: { min?: number, max?: number }
}
export type KeysInGraficoRealTime =
    'potenza'
    | 'tensione1'
    | 'tensione2'
    | 'tensione3'
    | 'corrente1'
    | 'corrente2'
    | 'corrente3';
export type GraficoRealTimeChartType = {
    date: Date,
    key?: string,
    ora?: number
} & { [k in KeysInGraficoRealTime]?: number }
export type GraficoRealTimeType = {
    settingsChart: {
        [k in KeysInGraficoRealTime]?: {
            x?: GraficoRealTimeSettingsType,
            y?: GraficoRealTimeSettingsType,
            title: string
        }
    },
    dataChart: GraficoRealTimeChartType[],
    possibleKeysData: string[]
}

export const TypeOrderData = {orderedDay: 'orderedDay', orderedHour: 'orderedHour'};
// export type ChartType<Keys,settingsType,ChartData> = {
//     settingsChart: {
//         [k in Keys]?: { x?: settingsType, y?: settingsType }
//     },
//     dataChart: ChartData[],
//     possibleKeysData: string[]
// }
export type CreaReportAttivitaLightMatePaginateType = {
    puntoLuce: PuntiLuceParse,
    days: { day: number, month: number, year: number, segnali: SegnaliTlcNodoChangesHistoryParse[] }[]
}

@Injectable({
    providedIn: 'root'
})
export class RemoteControlService extends ParseApiService {

    constructor(private projectService: ProjectService,
                private utilsService: UtilsService,
                protected translate: TranslateService,
                private userService: UserService,
                private fb: UntypedFormBuilder,
                private circuitiService: CircuitiService,
                private chartService: ChartServiceService,
    ) {
        super();
    }


    private get currentLang() {
        const currentLang = this.translate.currentLang
        if (!stringIsSet(currentLang)) {
            return currentLang
        } else if (currentLang.toLowerCase().includes('es')) {
            return 'es-ES'
        } else if (currentLang.toLowerCase().includes('en')) {
            return 'en-EN'
        } else {
            return currentLang
        }
    }

    public async getTelecontrolloDashboardData() {
        const progettoId = this.projectService.getProjectLocal();
        const response = await Parse.Cloud.run('getTelecontrolloDashbordData', {progettoId, isNodo: false});
        return this.projectService.mapData(response);

    }


    public getDatiGraficoOrario(circuitoId, fromDate, toDate, withForecast = undefined): Observable<GraficoOrarioType> {
        return fromPromise(Parse.Cloud.run('getDatiGraficoOrario_V2', paramsApiParse({
                circuitoId,
                fromDate,
                toDate,
                withForecast,
                language: this.currentLang
            })
        ));

    }

    public getDatiGraficoRealTime(circuitoId, fromDate, toDate): Observable<GraficoRealTimeChartType> {
        return fromPromise(Parse.Cloud.run('getDatiGraficoRealTime_V2', {
            circuitoId,
            fromDate,
            toDate,
            language: this.currentLang
        }));
    }

    public getDatiGraficoGiornaliero(circuitoId, fromDate, toDate) {
        return fromPromise(Parse.Cloud.run('getDatiGraficoGiornaliero', {
            circuitoId,
            fromDate,
            toDate,
            language: this.currentLang
        }));
    }

    public getCircuito(circuitId) {
        return fetchParseObject$(className.circuiti, circuitId) as Observable<CircuitiParse>;
    }


    public getSettings$(circuitId): Observable<SettaggiTlcCircuitiParse> {
        const Circuiti = Parse.Object.extend('Circuiti');
        let circuito = new Circuiti()
        circuito.id = circuitId
        let query = new SettaggiTlcCircuitiParse().query;
        query.equalTo("circuito", circuito);
        return fromPromise(query.first({sessionToken: this.userService.sessionToken()}));
    }

    public getSettingsFromCircuits$(circuits): Observable<SettaggiTlcCircuitiParse[]> {
        const params = paramsApiParse_v2({circuitiIds: circuits.map(c => c.objectId)});
        return fromPromise(Parse.Cloud.run('getSettaggiTlcCircuiti', params));
    }


    public getCalendar$(circuitId): Observable<OrariAccensioneCircuitiParse[]> {
        const circuito = new CircuitiParse();
        circuito.objectId = circuitId;
        let query = new OrariAccensioneCircuitiParse().query;
        query.equalTo("circuito", circuito);
        return fromPromise(query.find({sessionToken: this.userService.sessionToken()}));
    }


    public getSignal$(circuitId): Observable<SegnaliTlcCircuitiParse> {
        const circuito = new CircuitiParse();
        circuito.objectId = circuitId;
        let query = new SegnaliTlcCircuitiParse().query;
        query.equalTo("circuito", circuito);
        return fromPromise(query.first({sessionToken: this.userService.sessionToken()}));
    }

    public updateSettings(settings) {
        const SettaggiTlcCircuiti = Parse.Object.extend('SettaggiTlcCircuiti');
        let q = new SettaggiTlcCircuiti();
        q = this.setAllItemsAtParseClass(q, settings);
        return fromPromise(q.save(null, {sessionToken: this.userService.sessionToken()}));
    }

    public updateSettaggiTlcCircuiti(objectId: string | undefined, values): Observable<SettaggiTlcCircuitiParse> {
        const settaggio = new SettaggiTlcCircuitiParse();
        if (stringIsSet(objectId)) {
            settaggio.objectId = objectId;
        }
        Object.keys(values).forEach(key => {
            if (values[key] != null) {
                settaggio[key] = values[key];
            } else {
                settaggio.unset(key);
            }
        })
        return fromPromise(settaggio.save())
    }

    updateMultipleSettings(values: {
        settaggioTlcCircuiti: any,
        values: any
    }[]): Observable<PaginationParserequestType<SettaggiTlcCircuitiParse>> {
        const fetchPage = (page = 0) => {
            const value = values[page];
            const objectId = value.settaggioTlcCircuiti.objectId
            const newValues = value.values;
            let isLast = page + 1 >= values.length;
            const progress = Math.ceil((page + 1) / values.length * 100)
            return this.updateSettaggiTlcCircuiti(objectId, newValues)
                .pipe(
                    map(item => {
                        return {
                            items: [item],
                            progress: progress,
                            nextPage: isLast ? undefined : (page += 1),
                            finished: isLast,
                            error: null
                        };
                    }),
                    catchError(error => {
                        return of({
                            items: undefined,
                            progress: progress,
                            nextPage: isLast ? undefined : (page += 1),
                            finished: isLast,
                            error: error
                        });
                    }),
                );
        };
        return fetchPage(0).pipe(
            expand(({nextPage}) => nextPage >= 0 ? fetchPage(nextPage) : EMPTY),
        );
    }

    public updateCalendars(calendars) {
        const SettaggiTlcCircuiti = Parse.Object.extend('OrariAccensioneCircuiti');
        const items = [];
        calendars.forEach((el) => {
                let item = new SettaggiTlcCircuiti();
                item = this.setAllItemsAtParseClass(item, el);
                items.push(item);
            }
        );
        return fromPromise(Parse.Object.saveAll(items));
    }

    protected whereRelation(query: Parse.Query<Parse.Object>, relationObjectName: string, relationColumnName: string, value, checkCurrentProject = false, otherColumnName: string = 'objectId') {
        const Relation = Parse.Object.extend(relationObjectName);
        let innerQuery = new Parse.Query(Relation);
        innerQuery.equalTo(otherColumnName, value);
        if (checkCurrentProject) {
            innerQuery = this.whereProjectIsTheCurrent(innerQuery);
        }
        query.matchesQuery(relationColumnName, innerQuery);
        return query;
    }

    protected whereProjectIsTheCurrent(query: Parse.Query<Parse.Object>) {
        const progetto = this.projectService.currentProject;
        query.equalTo("progetto", progetto);
        return query//this.whereRelation(query, 'Progetti', 'progetto', localStorage.getItem(this.PROJECT_ID_STORAGE));
    }

    private setAllItemsAtParseClass(parseClass, items: {}) {
        Object.keys(items).forEach(key => {
            parseClass.set(key, items[key]);
        });
        return parseClass;
    }

    public createRemoteControl(circuitId): Observable<IoTDevicesParse> {
        const newIotDevices = new IoTDevicesParse();
        newIotDevices.progetto = this.projectService.actualProject;
        const circuito = new CircuitiParse();
        circuito.objectId = circuitId;
        newIotDevices.circuito = circuito;
        newIotDevices.seriale = '';
        newIotDevices.nodo = false;
        return fromPromise(newIotDevices.save())
    }

    get formGroupNewIotDevices(): UntypedFormGroup {
        return this.fb.group({
            circuiti: [{
                value: '',
                disabled: false
            }, [Validators.required]],
        })
    }

    get formFilterGroup(): UntypedFormGroup {
        return this.fb.group({
            selected: [{
                value: undefined,
                disabled: false
            }, []],
        })
    }

    getAllCircuiti(): Observable<CircuitiParse[]> {
        return this.circuitiService.getTuttiCircuitiAppartentiAlProgetto()
    }


    getEnergyDashboardForCircuito$(circuitoId: string): Observable<TypeGetEnergyDashboardForCircuito> {
        return fromPromise(Parse.Cloud.run('getEnergyDashboardForCircuito',
            {circuitoId}));
    }

    isEmptyString(value) {
        if (!isNotNullOrUndefined(value)) {
            return true;
        } else if (typeof value === "number") {
            return false
        } else if (typeof value === "string") {
            return value.trim().length === 0
        } else {
            return false
        }
    }

    updateIotDevices(iotDevice: IoTDevicesParse, newValue, subsitutionPairCode = false) {
        if (subsitutionPairCode) {
            iotDevice.unset('seriale');
        }
        Object.keys(newValue).forEach((key) => {
            if (iotDevice.hasProperty(key) && !this.isEmptyString(newValue[key])) {
                iotDevice[key] = newValue[key];
            } else if (key == 'meter') {
                iotDevice.unset('meter');
            }
        });
        return fromPromise(iotDevice.save())
    }

    public getRealTimesValues(circuitiId: string[]): Observable<any> {
        return fromPromise(Parse.Cloud.run('getLastRealTimeValues',
            {circuitiIds: circuitiId})
        ).pipe(
            map(values => {
                let filteredValues
                if (arrayIsSet(values)) {
                    filteredValues = values.filter(value => value != null)
                }
                if (arrayIsSet(filteredValues)) {
                    return filteredValues
                } else {
                    return undefined;
                }
            })
        );
    }

    updateComandoCircuito(circuitoId: string): Observable<ComandiParse> {
        const circuito = new CircuitiParse();
        circuito.objectId = circuitoId;
        const comando = new ComandiParse();
        comando.circuito = circuito;
        comando.comando = "invia dati tempo reale";
        comando.isShellCommand = false;
        comando.ricevuto = false;
        return fromPromise(comando.save());
    }

    public getAssociatedLightMate(circuitoId: string): Observable<SegnaliTlcNodoParse[]> {
        const segnaliTlcNodo$ = Parse.Cloud.run('getSegnaliTlcNodoForCircuito', paramsApiParse_v2({circuitoId}));
        return fromPromise(segnaliTlcNodo$);
    }


    getDataChart_V2(data: GraficoOrarioType, yValuesType: KeysInGraficoOrario, typeOrderData: string, pointRadius = 1, borderWidth = 2, approximationAtHours = false) {
        if (data == null || !arrayIsSet(Object.keys(data))) {
            return undefined
        }
        let settingsChart: { x?: GraficoOrarioSettingsType, y?: GraficoOrarioSettingsType, title: string };
        if (yValuesType != null && data.settingsChart != null && data.settingsChart[yValuesType] != null) {
            settingsChart = data.settingsChart[yValuesType];
        }
        const datasets = [];
        let xAxesValue = {}
        let tooltips: any = {
            callbacks: {
                label: (tooltipItem, data) => {
                    const y = tooltipItem.yLabel.toFixed(2);
                    return y;
                },
            }
        };
        if (yValuesType != null && arrayIsSet(data.dataChart)) {
            let valuesChart = [];
            if (typeOrderData === TypeOrderData.orderedHour) {
                xAxesValue = {
                    type: 'linear',
                    ticks: {
                        autoSkip: false,
                        min: 0,
                        max: 24,
                        stepSize: 1
                    }
                }

                if (!approximationAtHours) {
                    tooltips.callbacks['title'] = (tooltipItem, data) => {
                        const xLabel = tooltipItem[0].xLabel
                        if (typeof xLabel == "number") {
                            let hour = Math.trunc(xLabel)
                            let minute = convertToSessaDecimal(xLabel - hour);
                            if (minute >= 60) {
                                hour += 1;
                                minute = 0;
                            }
                            return hour + ':' + minute.toString().padStart(2, '0')
                        }
                        return xLabel;
                    }
                }
                const convertToSessaDecimal = (minutes) => {
                    return Math.floor(minutes * 100 * (60 / 100));
                }
                const convertToDecimal = (minutes) => {
                    return minutes * (10 / 60) / 10
                }
                const objForDay = data.dataChart.reduce((prev, current) => {
                    let key, x, y;
                    if (current.key == 'forecast') {
                        key = this.translate.instant('charts.forecast')
                        x = current.ora
                    } else if (current.date) {
                        key = this.translate.instant('week.' + current.date.getDay()) + ' ' + current.date.getDate() + '/' + (current.date.getMonth() + 1)
                        x = current.date.getHours()
                        if (!approximationAtHours) {
                            x += convertToDecimal(current.date.getMinutes());
                        }
                    }
                    y = current[yValuesType];
                    if (prev[key] == null) {
                        prev[key] = [];
                    }
                    const lastValue = prev[key][prev[key].length - 1];
                    if (lastValue != null && lastValue.x < x) {
                        prev[key].unshift({x, y})
                    } else {
                        prev[key].push({x, y})
                    }
                    return prev
                }, {})
                Object.keys(objForDay).forEach((key, index) => {
                    let color;
                    color = CHART_COLORS[index % CHART_COLORS.length];
                    datasets.push(
                        {
                            label: key,
                            data: objForDay[key],
                            fill: false,
                            borderColor: hunaChartColors.colorOpacity(color.r, color.g, color.b, 1),
                            pointBorderColor: hunaChartColors.colorOpacity(color.r, color.g, color.b, 1),
                            pointHoverBackgroundColor: hunaChartColors.colorOpacity(color.r, color.g, color.b, 1),
                            pointHoverBorderColor: hunaChartColors.colorOpacity(color.r, color.g, color.b, 1),
                            pointBackgroundColor: hunaChartColors.colorOpacity(color.r, color.g, color.b, 0.3),
                            borderWidth,
                            pointRadius,
                            datalabels: {
                                display: false
                            }
                        }
                    )

                })

            } else {
                tooltips.callbacks['title'] = (tooltipItem, data) => {
                    const xLabel = tooltipItem[0].xLabel
                    const xLabelDate = new Date(xLabel);
                    if (isValidDate(xLabelDate)) {
                        const customDate = formatDate(xLabelDate, 'longDate', this.translate.currentLang) + ' ' + formatDate(xLabelDate, 'H:mm', this.translate.currentLang);
                        return stringIsSet(customDate) ? customDate : xLabel;
                    } else {
                        return xLabel;
                    }
                }
                valuesChart = data.dataChart
                    .filter(value => value.key == null || value.key != 'forecast')
                    .map(a => ({x: a.date, y: a[yValuesType]}))
                if (arrayIsSet(valuesChart)) {
                    xAxesValue = {
                        type: 'time',
                        time: {
                            unit: 'hour',
                            displayFormats: {
                                hour: 'DD/M/YYYY, HH:00'
                            }
                        },
                        ticks: {
                            autoSkip: false,
                            min: settingsChart && settingsChart.x && settingsChart.x.limits && settingsChart.x.limits.min != null ? settingsChart.x.limits.min : undefined,
                            max: settingsChart && settingsChart.x && settingsChart.x.limits && settingsChart.x.limits.max != null ? settingsChart.x.limits.max : undefined,
                        }
                    }
                    const color = hunaChartColors.orange
                    datasets.push(
                        {
                            backgroundColor: (context) => {
                                const ctx = context && context.chart ? context.chart.canvas : undefined
                                return this.chartService.getGradientBackGroundColorChart('orange', undefined, ctx);
                            },

                            label: this.translate.instant('charts.selectedPeriod'),
                            data: valuesChart,
                            borderColor: color,
                            pointBorderColor: color,
                            pointBackgroundColor: hunaChartColors.orangeOpacity(0.3),
                            pointHoverBackgroundColor: color,
                            pointHoverBorderColor: color,
                            borderWidth,
                            pointRadius,
                            datalabels: {
                                display: false
                            }
                        }
                    )
                }
                const valuesChartForecast = data.dataChart
                    .filter(value => value.key && value.key == 'forecast')
                    .reduce((prev, value,) => {
                        if (value.ora != null) {
                            if (prev == null) {
                                prev = {};
                            }
                            prev[value.ora] = value[yValuesType]
                        }
                        return prev
                    }, undefined as any)
                if (valuesChartForecast != null) {
                    const lastDate = valuesChart[valuesChart.length - 1].x
                    const lastValue = valuesChart[valuesChart.length - 1].y
                    const lastHour = lastDate.getHours();
                    const dataChart = [{x: lastDate, y: lastValue}]
                    let i = 0;
                    while (i < 24) {
                        const date = new Date(lastDate);
                        let value;
                        if (i < lastHour + 1) {
                            date.setDate(date.getDate() + 1)
                        }
                        date.setHours(i, 0, 0, 0);
                        if (valuesChartForecast[i] != null) {
                            value = valuesChartForecast[i]
                        }
                        dataChart.push({x: date, y: value});
                        i++;
                    }
                    dataChart.sort((a, b) => {
                        return a.x.getTime() - b.x.getTime()
                    })
                    const color = hunaChartColors.blue
                    datasets.push(
                        {
                            backgroundColor: (context) => {
                                const ctx = context && context.chart ? context.chart.canvas : undefined
                                return this.chartService.getGradientBackGroundColorChart('blue', undefined, ctx)
                            },
                            label: this.translate.instant('charts.forecast'),
                            data: dataChart,
                            borderColor: color,
                            pointBorderColor: [hunaChartColors.orangeOpacity(0.3), hunaChartColors.blue],
                            pointBackgroundColor: [hunaChartColors.orangeOpacity(0.3), hunaChartColors.blueOpacity(0.3)],
                            pointHoverBackgroundColor: color,
                            pointHoverBorderColor: color,
                            borderWidth,
                            pointRadius,
                            datalabels: {
                                display: false
                            }
                        }
                    )
                }
            }
        }

        let dataChart;
        if (arrayIsSet(datasets)) {
            dataChart = {
                type: 'line',
                datasets,
                options: {
                    tooltips,
                    animation: {
                        duration: 0
                    },
                    maintainAspectRatio: false,
                    spanGaps: false,
                    responsive: true,
                    scales: {
                        xAxes: [{
                            ...xAxesValue,
                            position: 'bottom',
                            scaleLabel: {
                                labelString: settingsChart && settingsChart.x && stringIsSet(settingsChart.x.title) ? settingsChart.x.title : '',
                                display: true
                            },
                        }],
                        yAxes: [
                            {
                                display: true,
                                scaleLabel: {
                                    labelString: settingsChart && settingsChart.y && stringIsSet(settingsChart.y.title) ? settingsChart.y.title : '',
                                    display: true
                                },
                                ticks: {
                                    suggestedMin: settingsChart && settingsChart.y && settingsChart.y.limits && settingsChart.y.limits.min != null ? settingsChart.y.limits.min : undefined,
                                    suggestedMax: settingsChart && settingsChart.y && settingsChart.y.limits && settingsChart.y.limits.max != null ? settingsChart.y.limits.max : undefined,
                                }
                            }
                        ],
                        title: {
                            display: false,
                        }
                    },
                    title: {
                        display: true,
                        text: settingsChart && settingsChart.title && stringIsSet(settingsChart.title) ? this.translate.instant('charts.' + settingsChart.title) : '',
                        fontSize: 14
                    },
                    legend: {
                        display: true,
                    },
                    elements: {
                        line: {
                            tension: 0
                        },
                        point: {
                            radius: pointRadius
                        }
                    },
                },
                plugins: {
                    datalabels: {
                        display: false
                    }
                }
            }
        }

        return dataChart;
    }


    getDataChartBar(data, yValuesType, maintainAspectRatio = false) {
        if (!arrayIsSet(Object.keys(data)) || yValuesType == null || data.yValues[yValuesType] == null) {
            return undefined
        }
        let key = data.yValuesStructKey.findIndex(yKey => yKey === yValuesType);
        key = key < 0 ? 0 : key;
        return {
            datasets: [
                {
                    data: data.yValues[yValuesType][0],
                    borderColor: '#2196f3',
                    borderWidth: 1,
                    backgroundColor: 'rgba(33,150,243,0.25)'
                }
            ],
            type: 'bar',
            labels: data.xValues,
            options: {
                tooltips: {
                    callbacks: {
                        label: (tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData): string | string[] => {
                            return Number(tooltipItem.value).toFixed(0);
                        },
                    }
                },
                animation: {
                    duration: 0
                },
                maintainAspectRatio,
                onResize(newSize: Chart.ChartSize) {
                    // const ratioChart = newSize.width / newSize.height
                    // const heigthWindow = window.innerHeight
                    // const widthWindow = window.innerWidth
                    // console.log(ratioChart)
                    // console.log(16 / 9)
                    // if (newSize.height > heigthWindow - 220) {
                    //     newSize.height = heigthWindow - 220;
                    // }
                    // if (newSize.height < 200 && heigthWindow < 350) {
                    //     newSize.height = 350
                    // }
                    // console.log(newSize)
                },
                title: {
                    display: true,
                    text: data.title[key],
                    fontSize: 14
                },
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [
                        {
                            type: 'category',
                            position: 'bottom',
                            scaleLabel: {
                                labelString: data.xTitle[key] ? data.xTitle[key] : '',
                                display: true
                            }
                        }
                    ],
                    yAxes: [
                        {
                            display: true,
                            scaleLabel: {
                                labelString: data.yTitle[key] ? data.yTitle[key] : '',
                                display: true
                            },
                            ticks: {
                                suggestedMin: data.yLimits[0] ? data.yLimits[0] : null
                            }
                        }
                    ],
                },
                plugins: {
                    datalabels: {
                        display: true,
                        anchor: 'end',
                        formatter: (i: number) => i ? i.toFixed(0) : i,
                        align: 'top',
                        font: {
                            weight: 'bold'
                        }
                    },
                }
            }
        };
    }

    getIotDevices(lightPointsId = undefined, circuitsId = undefined): Observable<IoTDevicesParse[]> {
        let data = {};
        if (arrayIsSet(lightPointsId)) {
            data['lightPointsId'] = lightPointsId;
        } else if (arrayIsSet(circuitsId)) {
            data['circuitsId'] = circuitsId;
        } else {
            data['projectId'] = this.projectService.currentProject.objectId;
        }
        const iotDevices$ = Parse.Cloud.run('getIotDevices', paramsApiParse(data));
        return fromPromise(iotDevices$);
    }

    getSettaggiTlcNodo(lightPointsId = undefined, circuitsId = undefined, includePointerKeys: string[] = undefined): Observable<SettaggiTlcNodoParse[]> {
        let data = {};
        if (arrayIsSet(lightPointsId)) {
            data['lightPointsId'] = lightPointsId;
        } else if (arrayIsSet(circuitsId)) {
            data['circuitsId'] = circuitsId;
        } else {
            data['projectId'] = this.projectService.currentProject.objectId;
        }
        if (arrayIsSet(includePointerKeys)) {
            data['includePointerKeys'] = includePointerKeys;
        }
        const settaggiTlcNodo$ = Parse.Cloud.run('getSettaggiTlcNodo', paramsApiParse_v2(data));
        return fromPromise(settaggiTlcNodo$).pipe(
            map(settaggi => {
                if (arrayIsSet(settaggi)) {
                    return settaggi.sort((settaggioA, settaggioB) => {
                        const dateA = settaggioA.giornoSettimana ? settaggioA.giornoSettimana : 0;
                        const dateB = settaggioB.giornoSettimana ? settaggioB.giornoSettimana : 0;
                        return dateA - dateB;
                    })
                } else {
                    return []
                }
            })
        );
    }

    addSettaggiTlcNodo(profileTlcId: string, days: number[], lightPointsId = undefined, circuitsId = undefined, dates: Date[] = undefined, repeatYear: boolean = undefined): Observable<SegnaliTlcNodoParse[]> {
        let data = {profileTlcId};
        if (arrayIsSet(days)) {
            data['days'] = days;
        } else if (arrayIsSet(dates)) {
            data['dates'] = dates.map(data => data.getTime());
        }
        if (repeatYear != null) {
            data['repeatYear'] = repeatYear;
        }
        if (arrayIsSet(lightPointsId)) {
            data['lightPointsId'] = lightPointsId;
        } else if (arrayIsSet(circuitsId)) {
            data['circuitsId'] = circuitsId;
        } else {
            data['projectId'] = this.projectService.currentProject.objectId;
        }
        const addSettaggiTlcNodo$ = Parse.Cloud.run('addSettaggiTlcNodo', paramsApiParse_v2(data));
        return fromPromise(addSettaggiTlcNodo$).pipe(
            map(settaggi => {
                if (arrayIsSet(settaggi)) {
                    return settaggi.map(s => {
                        const settaggioParse = new SettaggiTlcNodoParse()
                        settaggioParse.objectId = s.id;
                        return settaggioParse;
                    })
                } else {
                    return [];
                }
            })
        );
    }

    deleteSettaggiTlcNodo(objectsId: string[]): Observable<SettaggiTlcNodoParse[]> {
        let data = {};
        if (arrayIsSet(objectsId)) {
            data['objectsId'] = objectsId;
        }
        const addSettaggiTlcNodo$ = Parse.Cloud.run('deleteSettaggiTlcNodo', paramsApiParse(data));
        return fromPromise(addSettaggiTlcNodo$).pipe(
            map(settaggi => {
                if (arrayIsSet(settaggi)) {
                    return settaggi.map(s => {
                        const settaggioParse = new SettaggiTlcNodoParse()
                        settaggioParse.objectId = s.id;
                        return settaggioParse;
                    })
                } else {
                    return [];
                }
            })
        );
    }

    creaReportAttivitaLightMate$(puntiLuceIds: string[], fromDate: Date, toDate: Date): Observable<CreaReportAttivitaLightMatePaginateType[]> {
        const params = paramsApiParse_v2({puntiLuceIds, fromDate, toDate})
        return fromPromise(Parse.Cloud.run('creaReportAttivitaLightMate', params))
    }

    creaReportAttivitaLightMatePaginate$(puntiLuceIds: string[], fromDate: Date, toDate: Date, numberElementForCycle = 500): Observable<PaginationParserequestType<CreaReportAttivitaLightMatePaginateType>> {

        if (arrayIsSet(puntiLuceIds)) {
            const lengthArray = puntiLuceIds.length;

            const fetchPage = (page = 0) => {
                const lastElement = ((page + 1) * numberElementForCycle < lengthArray) ? (page + 1) * numberElementForCycle : lengthArray;
                const paginationArray = puntiLuceIds.slice(page * numberElementForCycle, lastElement);
                const progress = Math.ceil(lastElement / lengthArray * 100)
                // query.equalTo('progetto', project);

                return this.creaReportAttivitaLightMate$(paginationArray, fromDate, toDate).pipe(
                    catchError(err => {
                        return of({
                            items: undefined,
                            nextPage: lastElement >= lengthArray ? undefined : (page += 1),
                            progress,
                            finished: lastElement >= lengthArray,
                            error: err
                        })
                    }),
                    map((item) => {
                        return {
                            items: item,
                            nextPage: lastElement >= lengthArray ? undefined : (page += 1),
                            progress,
                            finished: lastElement >= lengthArray
                        };
                    }),
                );
            }

            return fetchPage().pipe(
                expand(({nextPage}) => nextPage ? fetchPage(nextPage) : EMPTY),
            ) as Observable<PaginationParserequestType<CreaReportAttivitaLightMatePaginateType>>

        } else {
            return throwError('puntiluceids-DATA_MISSING')
        }


    }
}

export const TypeVersioneProtocolloLM = {
    '3_0': '3.0',
    '2_0': '2.0',
    '1_0': '1.0',
    rt_v1: 'RT_V1',
    prt_v1: 'PRT_V1'
}
export const possibleValuesMeter = ['OR-WE-517', 'PAC3200', 'UPM209', 'ECA380D', 'ECR140D', 'PRY-CAM-HOME-3F'];
export const possibleValuesVersioneProtocolloLM = Object.values(TypeVersioneProtocolloLM);
export type TypeGetEnergyDashboardForCircuito = {
    datiAccensioneImpianto_30g: { oreAcceso: number, oreSpento: number, oreOffline: number },
    energyConsumption_1y: { timeBy: number, energyConsumption: number, firstDate: Date, lastDate: Date },
    energyConsumption_7g: { timeBy: number, energyConsumption: number, firstDate: Date, lastDate: Date },
    energyConsumption_24h: { timeBy: number, energyConsumption: number, firstDate: Date, lastDate: Date },
    energyConsumption_30g: { timeBy: number, energyConsumption: number, firstDate: Date, lastDate: Date },
    energyConsumption_activation: { timeBy: number, energyConsumption: number, firstDate: Date, lastDate: Date },
    oreNominaliAnno: number,
    oreNominaliMese: number,
    potenzaMedia30Giorni: number,
    potenzaNominaleCircuito: number,
    riduzioneMedia: number,
    stimaConsumo12Mesi: number
}
