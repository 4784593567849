import {Directive, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {startWith} from "rxjs/operators";

@Directive({
    selector: '[appResponsive]'
})
export class ResponsiveDirective implements OnDestroy, OnInit {

    @Input() appResponsive;
    @Input() responsiveXS;
    @Input() responsiveS;
    @Input() responsiveM;
    @Input() responsiveL;
    @Input() responsiveXL;

    private subscription: Subscription;

    constructor(private el: ElementRef, private breakpoint: BreakpointObserver) {
    }

    ngOnInit(): void {
        this.subscription = this.breakpoint.observe([
            Breakpoints.XSmall,
            Breakpoints.Small,
            Breakpoints.Medium,
            Breakpoints.Large,
            Breakpoints.XLarge
        ]).pipe(startWith(0)).subscribe(() => {
            this.setItem();
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private setItem() {
        let item;
        if (this.breakpoint.isMatched(Breakpoints.XLarge)) {
            item = this.getCurrentItem(Breakpoints.XLarge);
        } else if (this.breakpoint.isMatched(Breakpoints.Large)) {
            item = this.getCurrentItem(Breakpoints.Large);
        } else if (this.breakpoint.isMatched(Breakpoints.Medium)) {
            item = this.getCurrentItem(Breakpoints.Medium);
        } else if (this.breakpoint.isMatched(Breakpoints.Small)) {
            item = this.getCurrentItem(Breakpoints.Small);
        } else if (this.breakpoint.isMatched(Breakpoints.XSmall)) {
            item = this.getCurrentItem(Breakpoints.XSmall);
        }
        this.el.nativeElement.style[this.appResponsive] = item;

    }

    private getCurrentItem(current) {
        switch (current) {
            case Breakpoints.XLarge:
                if (this.responsiveXL) {
                    return this.responsiveXL;
                }
            // tslint:disable-next-line:no-switch-case-fall-through
            case Breakpoints.Large:
                if (this.responsiveL) {
                    return this.responsiveL;
                }
            // tslint:disable-next-line:no-switch-case-fall-through
            case Breakpoints.Medium:
                if (this.responsiveM) {
                    return this.responsiveM;
                }
            // tslint:disable-next-line:no-switch-case-fall-through
            case Breakpoints.Small:
                if (this.responsiveS) {
                    return this.responsiveS;
                }
            // tslint:disable-next-line:no-switch-case-fall-through
            case Breakpoints.XSmall:
                if (this.responsiveXS) {
                    return this.responsiveXS;
                }
            // tslint:disable-next-line:no-switch-case-fall-through
            default :
                return 'initial';
        }

    }
}
