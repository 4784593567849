<mat-form-field appearance="fill">
    <mat-label>{{element.value}}</mat-label>
    <input matInput [formControlName]="element.formControlName"
           [errorStateMatcher]="matcher">
    <mat-hint
            *ngIf="filterForm.get(element.formControlName).hasError('required');else nonerrore">
        Campo obbligatorio
    </mat-hint>
    <ng-template #nonerrore> Valore accettato</ng-template>
    <mat-error
            *ngIf="filterForm.get(element.formControlName).hasError('notADecimalNumber') && !filterForm.get(element.formControlName).hasError('required')">
        Please enter a valid number
    </mat-error>
    <mat-error
            *ngIf="filterForm.get(element.formControlName).hasError('required')">
        Number is <strong>required</strong>
    </mat-error>
</mat-form-field>