import {Injectable} from '@angular/core';
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";

@Injectable({
    providedIn: 'root'
})
export class DimensionsService {


    constructor(
        private breakpoint: BreakpointObserver
    ) {
    }

    isXs() {
        return this.breakpoint.isMatched(Breakpoints.XSmall);
    }

}

