import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-color-lamp',
    templateUrl: './color-lamp.component.html',
    styleUrls: ['./color-lamp.component.scss']
})
export class ColorLampComponent implements OnInit {

    @Input()
    color = '#FFD517';
    @Input()
    height = 400;

    constructor() {
    }

    ngOnInit(): void {
    }


}
