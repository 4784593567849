import {Injectable} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {LongPressDirective} from '../directives/long-press.directive';
import {arrayIsSet} from "../../models/Models";

// tslint:disable-next-line:class-name
export interface fakeButtonClick {
    objectId: string;
    longPressDirective: LongPressDirective;
    value?: any;
}

export interface IdSubscriptions {
    short: Subscription | null;
    long: Subscription | null;
    objectId: string | null;
}

@Injectable({
    providedIn: 'root'
})
export class EmulateLongPressService {

    private fakesButtonClick: fakeButtonClick[] = [];


    private newFakeButtonClick(): fakeButtonClick {
        const longPressDirective = new LongPressDirective();
        longPressDirective.emitOnceForClick = true;
        const obj = {objectId: this.getId(), longPressDirective};
        this.fakesButtonClick.push(obj);
        return obj;
    }

    public get newSubscriptionObject(): IdSubscriptions {
        return {
            short: null,
            long: null,
            objectId: null
        };
    }


    public onDown(objectId, value: any) {
        const longPress = this.getValue(objectId);
        this.setValue(objectId, 'value', value);
        if (longPress != null) {
            this.setValue(objectId, 'value', value);
            longPress.longPressDirective.onMouseDown(undefined);
        }

    }

    public onUp(objectId) {
        const longPress = this.getValue(objectId);
        if (longPress != null) {
            longPress.longPressDirective.onMouseUp(undefined);
        }
    }


    constructor() {
    }

    /**
     *  example
     *  const {shortPress, longPress, objectId} = this.emulateLongPress.init();
     *  this.lineId = objectId;
     *  this.shortPressLineSubcription = shortPress.subscribe(value => {
     *       console.log("short", value);
     *  });
     *  this.longPresslineSubcription = longPress.subscribe(value => {
     *       console.log("long", value);
     *  });
     */
    public init(): { longPress: Observable<any>, shortPress: Observable<any>, objectId: string } {
        const {objectId, longPressDirective} = this.newFakeButtonClick();
        return {
            longPress: longPressDirective.longPress.asObservable().pipe(
                map(() => {
                    return this.getValue(objectId).value;
                })
            ),
            shortPress: longPressDirective.shortPress.asObservable().pipe(
                map(() => {
                    return this.getValue(objectId).value;
                })
            ),
            objectId
        };
    }

    private getId(numberCycle = 0) {
        const rand = Math.round(Math.random() * 1000);
        const objectId = (new Date().getTime() + rand).toString(32);
        const index = this.fakesButtonClick.findIndex(p => p.objectId === objectId);
        if (index < 0 || numberCycle > 10) {
            return objectId;
        } else {
            return this.getId(numberCycle + 1);
        }
    }

    /**
     *
     * @param longPress subscription of long press
     * @param shortPress subscription of short press
     * @param objectId to identify process
     * exmple
     *  this.emulateLongPress.destroy(this.longPresslineSubcription, this.shortPressLineSubcription, this.lineId);
     *
     */
    public destroy(longPress: Subscription | undefined, shortPress: Subscription | undefined, objectId: string) {
        if (longPress != null) {
            longPress.unsubscribe();
        }
        if (shortPress != null) {
            shortPress.unsubscribe();
        }
        this.destroVariablesById(objectId);
    }


    public destroyAll(subscriptions: IdSubscriptions[]) {
        if (arrayIsSet(subscriptions)) {
            subscriptions.forEach(sub => {
                this.destroy(sub.long, sub.short, sub.objectId);
            });
        }
        this.fakesButtonClick.forEach(fake => this.destroVariablesById(fake.objectId));
        this.fakesButtonClick = [];
    }

    private destroVariablesById(objectId: string) {
        const fake = this.getValue(objectId);
        if (fake != null) {
            fake.longPressDirective.ngOnDestroy();
            this.fakesButtonClick = this.fakesButtonClick.filter(fakeStored => fakeStored.objectId !== objectId);
        }
    }


    private setValue(objectId: string, key: keyof fakeButtonClick, newValue) {
        const index = this.fakesButtonClick.findIndex(fake => fake.objectId === objectId);
        if (index >= 0) {
            // @ts-ignore
            this.fakesButtonClick[index][key] = newValue;
        }
    }

    private getValue(objectId: string): fakeButtonClick {
        const index = this.fakesButtonClick.findIndex(fake => fake.objectId === objectId);
        if (index >= 0) {
            return this.fakesButtonClick[index];
        }
    }


}
