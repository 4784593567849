<div class="container-hunaCard mat-elevation-z3">
    <div class="huna-card-title" *ngIf="title!=undefined">{{title}}</div>
    <div class="container-hunaCard-father">
        <div style="width: 100%">
            <ng-content>

            </ng-content>
        </div>
    </div>
</div>
