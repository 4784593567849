<div class="flex-center-width100">
    <div class="flex-start-center flex-flow-row">
        <ng-container
                *ngFor="let schedaManutenzione of schedeManutenzione">
            <mat-card [ngClass]="{'disabled-box':disabled}" class="flex-item" style="cursor: pointer"
                      (click)="openForm(schedaManutenzione)">
                <div class="w100 " style="display: flex;flex-flow: row;align-items: center">
                    <mat-card-header>
                        <div mat-card-avatar>
                            <button mat-icon-button>
                                <ng-container *ngIf="!loading;else savingSchede">
                                    <mat-icon svgIcon="schedule"
                                              [ngClass]="['img-icon-color-'+(bozzeSchedePrecompilate|convertValueByPredicateFunction:predicateFunction:schedaManutenzione:elementSchedaCompilata)]"></mat-icon>
                                </ng-container>
                                <ng-template #savingSchede>
                                    <mat-spinner diameter="30"></mat-spinner>
                                </ng-template>

                            </button>
                        </div>
                        <mat-card-title>
                            <div class="flex-start-center">
                                <button mat-icon-button
                                        (click)="$event.stopPropagation();opeDetail(schedaManutenzione)">
                                    <mat-icon>info</mat-icon>
                                </button>
                                <span class="truncate-text-2-line">
                                 {{getValue(schedaManutenzione, 'name')}}&#160;
                            </span>
                            </div>

                        </mat-card-title>
                        <mat-card-subtitle>
                            {{'numeroCampiDaCompilare'|translate}}&#160;{{getValue(schedaManutenzione, 'form')}}
                        </mat-card-subtitle>
                    </mat-card-header>
                </div>
            </mat-card>
        </ng-container>
    </div>
</div>
