import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {combineLatest, Observable, Subscription} from "rxjs";
import {map, startWith} from "rxjs/operators";
import {arrayIsSet} from "../../../models/Models";


@Component({
    selector: 'app-batch-remote-control-settings',
    templateUrl: './batch-remote-control-settings.component.html',
    styleUrls: ['./batch-remote-control-settings.component.scss']
})
export class BatchRemoteControlSettingsComponent implements OnInit, OnDestroy {
    dataSource;
    public formGrup: UntypedFormGroup
    public keysSubFormAllarmi = ['allarmeSportelloAperto', 'allarmePresenzaRete', 'allarmeDifferenziale', 'allarmeCrepuscolare', 'allarmeConsumiAnomali',];
    public keysSubFormLine = ['allarmeMagnetoTermico', 'allarmeTeleruttore'];
    public keysSubForm = ['deltaMinutiAccensione', 'deltaMinutiSpegnimento', 'tolleranzaAllarmiConsumi',];
    public columnName = 'name';
    public checkBoxName = 'checkBox';
    public globalFormName = 'globalForm';
    public subStringSelectKeyCol = 'selectKeyCol';
    public displayedColumnsToVisualization: { type?: string, key?: string }[] = [];
    public displayedColumns: string[] = [];
    public selectedColKeys: string[] = [];
    public rowsTable = [];
    private maxNumberLine = 4;
    public currentValues$: Observable<any>;
    private subscription = new Subscription();

    constructor(public dialogRef: MatDialogRef<BatchRemoteControlSettingsComponent>,
                @Inject(MAT_DIALOG_DATA) public data: { settaggiTlcCircuito: any[] },
                private fb: UntypedFormBuilder) {
        this.dataSource = this.data.settaggiTlcCircuito;
        const objForm = this.data.settaggiTlcCircuito.reduce((prev, currentItem, index) => {
            const objSubForm = {};
            this.keysSubForm.forEach(key => {
                if (currentItem[key] == null) {
                    objSubForm[key] = undefined;
                } else if (key === 'deltaMinutiSpegnimento') {
                    objSubForm[key] = -currentItem[key];
                } else if (key === 'tolleranzaAllarmiConsumi') {
                    objSubForm[key] = Math.round(currentItem[key] * 100);
                } else {
                    objSubForm[key] = currentItem[key];
                }
                if (index === 0) {
                    this.displayedColumnsToVisualization.push({key, type: 'number'});
                    objSubForm[this.subStringSelectKeyCol + key] = undefined;
                    this.selectedColKeys.push(this.subStringSelectKeyCol + key);
                }
            })
            this.keysSubFormAllarmi.forEach(key => {
                objSubForm[key] = currentItem[key];
                if (index === 0) {
                    this.displayedColumnsToVisualization.push({key, type: 'bool'});
                }
            })
            this.keysSubFormLine.forEach(key => {
                let i = 1;
                while (i <= this.maxNumberLine) {
                    if (index === 0) {
                        this.displayedColumnsToVisualization.push({key: key + i, type: 'bool'});
                    }
                    if ((key + i) in currentItem) {
                        objSubForm[key + i] = currentItem[key + i];
                    } else {
                        objSubForm[key + i] = [{value: undefined, disabled: true}];
                    }
                    i++;
                }
            })
            prev[currentItem.objectId] = this.fb.group(objSubForm);
            if (prev[this.globalFormName] == null) {
                Object.keys(objSubForm).forEach(key => {
                    objSubForm[key] = undefined;
                });
                objSubForm['selected'] = undefined;
                prev[this.globalFormName] = this.fb.group(objSubForm);
            }
            return prev;
        }, {selectedRows: undefined})
        this.formGrup = this.fb.group(objForm);
        let sub = this.formGrup.get('globalForm').get('selected').valueChanges.subscribe(event => {
            if (event) {
                const selectable = this.data.settaggiTlcCircuito.map(item => item.objectId);
                this.formGrup.get('selectedRows').setValue(selectable);
            } else {
                this.formGrup.get('selectedRows').reset();
            }
        })
        this.subscription.add(sub);
        const changeValueColumn$ = this.displayedColumnsToVisualization
            .filter(key => this.selectedColKeys.includes(this.subStringSelectKeyCol + key.key))
            .map(key => {
                return this.formGrup.get(this.globalFormName).get(key.key).valueChanges.pipe(
                    startWith(this.formGrup.get(this.globalFormName).get(key.key).value),
                    map((value) => {
                        return {key: key.key, dirty: this.formGrup.get(this.globalFormName).get(key.key).dirty};
                    })
                )
            });
        sub = combineLatest(changeValueColumn$).subscribe(values => {
            if (arrayIsSet(values)) {
                values
                    .filter(v => v.dirty)
                    .forEach(v => {
                        this.formGrup.get(this.globalFormName).get(this.subStringSelectKeyCol + v.key).setValue(true)
                    })
            }
        })
        this.subscription.add(sub);
        this.formGrup.get('globalForm').get('selected').setValue(true);
        this.currentValues$ = this.formGrup.valueChanges.pipe(
            startWith(this.formGrup.value),
            map(values => {
                if (values == null) {
                    return {};
                } else {
                    const selectedRows = this.formGrup.get('selectedRows').value
                    const globalFormValues = Object.keys(values[this.globalFormName])
                        .filter(column => this.formGrup.get(this.globalFormName).get(column).dirty && !this.selectedColKeys.includes(column))
                        .reduce((prev, column) => {
                            if (prev[this.globalFormName] == null) {
                                prev[this.globalFormName] = {}
                            }
                            Object.keys(values[this.globalFormName]).forEach(column => {
                                    if (prev[this.globalFormName][column] == null && this.formGrup.get(this.globalFormName).get(column).dirty) {
                                        prev[this.globalFormName][column] = {dirty: true}
                                    }
                                }
                            )
                            return prev;
                        }, {})
                    let otherValues = {};
                    if (arrayIsSet(selectedRows)) {
                        otherValues = Object.keys(values)
                            .filter(objectId => selectedRows.includes(objectId))
                            .reduce((prev, currentObjectId) => {
                                if (prev[currentObjectId] == null) {
                                    prev[currentObjectId] = {}
                                }
                                Object.keys(values[currentObjectId]).forEach(column => {
                                        if (prev[currentObjectId][column] == null && this.formGrup.get(currentObjectId).get(column).dirty) {
                                            prev[currentObjectId][column] = {dirty: true}
                                        }
                                    }
                                )
                                return prev;
                            }, {})
                    }
                    return {...globalFormValues, ...otherValues}

                }
            })
        )
        const keysDisplayedColumnsToVisualization = this.displayedColumnsToVisualization.map(k => k.key)
        this.displayedColumns =
            keysDisplayedColumnsToVisualization
                .slice(0, 3)
        this.displayedColumns = this.displayedColumns
            .concat(['allarme'])
            .concat(
                keysDisplayedColumnsToVisualization
                    .slice(3)
            )
        this.displayedColumns.unshift(this.columnName)
        this.displayedColumns.unshift(this.checkBoxName)
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    save() {
        const selectedRows = this.formGrup.get('selectedRows').value
        const values = Object.keys(this.formGrup.value)
            .filter(objectId => arrayIsSet(selectedRows) && selectedRows.includes(objectId))
            .reduce((prev, objectId) => {
                const values = {};
                Object.keys(this.formGrup.get(objectId).value)
                    .filter(key => this.formGrup.get(objectId).get(key).dirty)
                    .forEach(key => {
                        if (key == 'deltaMinutiSpegnimento') {
                            values[key] = -this.formGrup.get(objectId).get(key).value
                        } else if (key == 'tolleranzaAllarmiConsumi') {
                            values[key] = Math.round(this.formGrup.get(objectId).get(key).value) / 100;
                        } else {
                            values[key] = this.formGrup.get(objectId).get(key).value
                        }
                    });
                if (arrayIsSet(Object.keys(values))) {
                    const settaggioTlcCircuiti = this.data.settaggiTlcCircuito.find(currentItem => currentItem.objectId === objectId)
                    prev.push({values, settaggioTlcCircuiti});
                }
                return prev;
            }, []);
        this.dialogRef.close(values);
    }

    applyValue() {
        const objectIdsSelected = this.formGrup.get('selectedRows').value;
        if (arrayIsSet(objectIdsSelected)) {
            const getIsSet = (key: string) => {
                if (this.formGrup.get(this.globalFormName).get(this.subStringSelectKeyCol + key) != null) {
                    return key != 'selected' &&
                        !this.selectedColKeys.includes(key) &&
                        this.formGrup.get(this.globalFormName).get(this.subStringSelectKeyCol + key).value == true
                } else {
                    return key != 'selected' &&
                        !this.selectedColKeys.includes(key) &&
                        this.formGrup.get(this.globalFormName).get(key).value != null
                }
            }
            Object.keys(this.formGrup.get(this.globalFormName).value)
                .filter(
                    key =>
                        getIsSet(key)
                )
                .forEach(key => {
                    objectIdsSelected.forEach(objectId => {
                        const valueSet = this.formGrup.get(this.globalFormName).get(key).value;
                        if (valueSet != null) {
                            this.formGrup.get(objectId).get(key).setValue(valueSet);
                        } else {
                            this.formGrup.get(objectId).get(key).reset();
                        }
                        this.formGrup.get(objectId).get(key).markAsDirty();
                    })
                })
            this.formGrup.get(this.globalFormName).reset({selected: this.formGrup.get(this.globalFormName).get('selected').value});
        }
    }

    isDirtyPredicate(values, element: any, column: string): boolean {
        const objectId = element.objectId;
        return values[objectId] && values[objectId][column] && values[objectId][column].dirty
    }

    isDirtyPredicateGlobalForm(values, keyGlobalForm, column: string): boolean {
        return values[keyGlobalForm] && values[keyGlobalForm][column] && values[keyGlobalForm][column].dirty
    }

    yesOrNotButton(event: boolean, col: string) {
        if (event == this.formGrup.get(this.globalFormName).get(col).value) {
            this.formGrup.get(this.globalFormName).get(col).reset();
            this.formGrup.get(this.globalFormName).get(col).markAsPristine();
        } else {
            this.formGrup.get(this.globalFormName).get(col).setValue(event);
            this.formGrup.get(this.globalFormName).get(col).markAsDirty();
        }
    }

    unsetGlobalFormField(column: string) {
        this.formGrup.get(this.globalFormName).get(column).reset();
        this.formGrup.get(this.globalFormName).get(this.subStringSelectKeyCol + column).setValue(true);
    }
}
