<div class="data" style="text-align: center;margin-top: -30px;margin-bottom: 20px">
    <H4>
        <img class="icon-dimension" src="/assets/icon/dialogCompnent/ask.png">
    </H4>
    <h5>{{data.message|translate}}</h5>
    <h5 style="color: #535353">{{'forCorrectImportFile'|translate}}</h5>
    <mat-grid-list cols="12" rowHeight="25px">
        <mat-grid-tile [colspan]="12"
                       [rowspan]="1">
            {{'lights'|translate}}
        </mat-grid-tile>
        <mat-grid-tile [colspan]="4"
                       [rowspan]="1">
            {{'dashboard_sidenav.PuntiLuce.circuito.title'|translate}}
        </mat-grid-tile>
        <mat-grid-tile [colspan]="4"
                       [rowspan]="1">
            {{'dashboard_sidenav.PuntiLuce.classe.title'|translate}}
        </mat-grid-tile>
        <mat-grid-tile [colspan]="4"
                       [rowspan]="1">
            ...
        </mat-grid-tile>
        <mat-grid-tile [colspan]="4"
                       [rowspan]="1">
            VV001
        </mat-grid-tile>
        <mat-grid-tile [colspan]="4"
                       [rowspan]="1">
            1
        </mat-grid-tile>
        <mat-grid-tile [colspan]="4"
                       [rowspan]="1">
            ...
        </mat-grid-tile>
        <mat-grid-tile [colspan]="4"
                       [rowspan]="1">
            VV002
        </mat-grid-tile>
        <mat-grid-tile [colspan]="4"
                       [rowspan]="1">
            ...
        </mat-grid-tile>
        <mat-grid-tile [colspan]="4"
                       [rowspan]="1">
            ...
        </mat-grid-tile>
    </mat-grid-list>
</div>
<div class="flex-container" *ngIf="visualizzaBottone;">
    <button mat-mini-fab [mat-dialog-close]="nomiClasse.puntiLuce">
        <img src="/assets/icon/dialogCompnent/lampione.svg" style="max-height: 24px;min-height: 24px;" alt="lampione">
    </button>
    <button mat-mini-fab [mat-dialog-close]="nomiClasse.circuiti">
        <img src="/assets/icon/dialogCompnent/quadro.png" style="max-height: 24px;min-height: 24px;"
             alt="quadro elettrico">
    </button>
    <button mat-mini-fab [mat-dialog-close]="nomiClasse.strade">
        <img src="assets/icon/hamburgerNavbar/street.png" style="max-height: 18px;min-height: 18px;"
             alt="strada" class="invert-color">
    </button>
    <button mat-mini-fab [mat-dialog-close]="nomiClasse.caricoEsogeno">
        <mat-icon>electrical_services</mat-icon>
    </button>
</div>
