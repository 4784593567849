<mat-card class="dimension-box huna-material-class">
    <button [disabled]="disabled" class="flex-around-center box-main-card-clickable-header" [ngClass]="[selectDisable]" (click)="singleCardClick()" (dblclick)="dbCardClick()">
        <mat-card-title
                style="min-height: 20px;margin-bottom: 0px!important;padding: 10px;font-size: 1em">
            <span *ngIf="schedaManutenzioneCompilata.name">
         {{schedaManutenzioneCompilata.name}}
            </span>
            <div style="position: absolute;right: 0px;top:0;transform: scale(0.8)">
                <ng-content select="[badge]">

                </ng-content>
            </div>
        </mat-card-title>
    </button>
    <mat-card-content class="box-main-card-content">
        <mat-card style="padding: 0;">
            <mat-list>
                <ng-container *ngIf="!notVisualized.createdAt">
                    <mat-list-item>
                        <span class="list-item-label" mat-line>
                            {{"scheduleMaintenance.completedOn"|translate}}
                        </span>
                        <span mat-line>
                            {{schedaManutenzioneCompilata.createdAt|customDate :'longDate'|async}}
                        </span>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                </ng-container>
                <ng-container *ngIf="!notVisualized.compiledBy&&(compiledBy.nome||compiledBy.cognome)">
                    <mat-list-item>
                        <span class="list-item-label" mat-line>
                            {{"by"|translate}}
                        </span>
                        <span mat-line>
                            {{compiledBy.nome}} {{compiledBy.cognome}}
                        </span>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                </ng-container>
                <mat-list-item>
                    <span class="list-item-label" mat-line>
                        {{"scheduleMaintenance.indirizzo"|translate}}
                    </span>
                    <span mat-line>
                        {{indirizzo}}
                    </span>
                    <mat-divider></mat-divider>
                </mat-list-item>
                <ng-container *ngIf="showCauseRejecet">
                    <mat-list-item>
                        <span mat-line class="color-warn" style="font-size: 14px;">
                            {{"rejectedBy"|translate}}
                        </span>
                        <span mat-line>
                            {{rejectedBy.nome}} {{rejectedBy.cognome}}
                        </span>
                        <mat-divider></mat-divider>
                    </mat-list-item>
                    <mat-list-item>
                        <span mat-line class="color-warn" style="font-size: 14px;">
                            {{"scheduleMaintenance.motivoRifiuto"|translate}}
                        </span>
                        <span mat-line>
                            {{schedaManutenzioneCompilata.motivoRifiuto}}
                        </span>
                    </mat-list-item>
                </ng-container>
            </mat-list>
        </mat-card>
        <mat-expansion-panel *ngIf="elementAssociated">
            <mat-expansion-panel-header>
                <mat-icon [svgIcon]="associatedElementIcon"
                            class="img-icon-M "
                            [ngClass]="[colorIconImage]">
                </mat-icon>
                <div style="text-align: center;font-weight: bold; flex-grow: 1;">
                    {{className|translate}}
                </div>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <mat-list>
                    <ng-container *ngFor="let key of keysVisualizedExpandElementDetail">
                        <mat-list-item>
                            <span class="list-item-label" mat-line>
                                {{getTraductionPath(key)|translate}}
                            </span>
                            <span mat-line>
                                {{elementAssociated[key]}}
                            </span>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                    </ng-container>
                </mat-list>
            </ng-template>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="elementAssociated" [disabled]="!isSetPuntoLuceOCircuito">
            <mat-expansion-panel-header>
                <mat-icon [color]="colorIcon" [ngStyle]="{opacity: opacity}">
                    playlist_add_check
                </mat-icon>
                <div style="text-align: center; font-weight: bold; flex-grow: 1;">
                    {{'scheduleMaintenance.form'|translate}}
                </div>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <mat-list>
                <ng-container *ngFor="let form of schedaManutenzioneCompilata.form">
                    <mat-list-item style="height: auto; padding: 16px 0;">
                        <span class="list-item-label" mat-line>
                            {{form.traduction}}
                        </span>
                        <span mat-line>
                            <ng-container [ngSwitch]="typeSchede[form.traduction]">
                                <ng-container *ngSwitchCase="'hour'">
                                    {{anticonvertHourTODecimalHour(form.value)}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'date'">
                                    {{form.value|customDate :'longDate'|async}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'image'">
                                    <span class="flex-around-center w100 flex-flow-row"
                                            *ngIf="images[form.traduction]!=null;else noImage">
                                        <ng-container *ngFor="let url of images[form.traduction]">
                                            <button style="cursor: pointer; appearance: none; border: none; background: inherit;" (click)="openImageFull(url)">
                                                <img [src]="url" class="img-icon-xxl"/>
                                            </button>
                                        </ng-container>
                                    </span>
                                    <ng-template #noImage>
                                        <mat-icon>image_not_supported</mat-icon>
                                    </ng-template>
                                </ng-container>
                                <ng-container *ngSwitchCase="'bool'">
                                    <ng-container *ngIf="form.value;else falseCheckBox">
                                        <span style="width: 60px;max-width: 100%" class="flex-center-Between">
                                            {{'bool.true'|translate}}
                                            <mat-icon [color]="colorIcon">check_box</mat-icon>
                                        </span>
                                    </ng-container>
                                    <ng-template #falseCheckBox>
                                        <span style="width: 60px;max-width: 100%" class="flex-center-Between">
                                            {{'bool.false'|translate}}
                                            <mat-icon [color]="colorIcon">check_box_outline_blank</mat-icon>
                                        </span>
                                    </ng-template>
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    {{form.value}}
                                </ng-container>
                            </ng-container>
                        </span>
                </mat-list-item>
                <mat-divider></mat-divider>

                </ng-container>
                </mat-list>
            </ng-template>
        </mat-expansion-panel>
    </mat-card-content>
</mat-card>