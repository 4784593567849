import {
    AfterViewInit,
    Component,
    ElementRef,
    Input, OnDestroy,
    ViewChild
} from '@angular/core';
import { Observable, Subscription} from "rxjs";
import {UtilsService} from "../../providers/services/utils.service";
import {delay, distinctUntilChanged, map, repeatWhen, take, takeWhile, toArray} from "rxjs/operators";
import {isNotNullOrUndefined} from "../../models/Models";

@Component({
    selector: 'app-scroll-with-button',
    templateUrl: './scroll-with-button.component.html',
    styleUrls: ['./scroll-with-button.component.scss']
})
export class ScrollWithButtonComponent implements OnDestroy, AfterViewInit {
    @ViewChild('scrollDiv') scrollDiv: ElementRef;

    @Input() visualizeButtonLeftRight = true
    @Input() position: 'top' | 'bottom' | 'overlay' = 'top';
    @Input() color: 'accent' | 'primary' | 'warn' = 'accent';
    @Input() step = 5;
    valueScroll = 0;

    constructor(private utilsService: UtilsService,
    ) {
    }

    subscription: Subscription;

    maxScrolWith = 0;

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

    }

    ngAfterViewInit(): void {
        this.setMaxScroll();
        const resize$ = new Observable(
            subscriber => {
                const clientWidth = this.scrollDiv.nativeElement.clientWidth;
                subscriber.next(clientWidth);
                subscriber.complete();
            }
        )
        this.subscription = resize$.pipe(
            repeatWhen((a) => {
                return a.pipe(delay(400))
            }),
            distinctUntilChanged(),
            takeWhile(clientWidth => {
                return clientWidth == 0;
            }),
            take(10),
            toArray(),
            map(clientWidths => {
                return clientWidths[clientWidths.length - 1]
            })
        ).subscribe(
            clientWidth => {
                this.setMaxScroll();
            },
            error => {
                console.log(error)
            }
        )
    }

    setMaxScroll() {
        setTimeout(() => {
            let maxScrolWith
            if (isNotNullOrUndefined(this.scrollDiv) && isNotNullOrUndefined(this.scrollDiv.nativeElement)) {
                const scrollWidth = this.scrollDiv.nativeElement.scrollWidth;
                const clientWidth = this.scrollDiv.nativeElement.clientWidth;
                maxScrolWith = scrollWidth - clientWidth;
            } else {
                maxScrolWith = 0;
            }
            this.maxScrolWith = maxScrolWith
        })
    }

    public stopScrool: Subscription;

    activeScroll(direction: 'right' | 'left') {
        if (direction === "right") {
            this.valueScroll = Math.ceil(Math.min(this.valueScroll + this.step, 100));
        } else {
            this.valueScroll = Math.floor(Math.max(0, this.valueScroll - this.step));
        }
        this.moveSlider(this.valueScroll);
    }

    stoppingScroll() {
        if (this.stopScrool) {
            this.stopScrool.unsubscribe()
        }
    }

    public get isMobile() {
        return this.utilsService.isMobile
    }

    moveSlider(event) {
        this.scrollDiv.nativeElement.scrollTo({
            left: this.maxScrolWith * (event / 100),
            behavior: 'smooth'
        });
    }

    // get heigthScrollDiv() {
    //     if (isNotNullOrUndefined(this.scrollDiv) && isNotNullOrUndefined(this.scrollDiv.nativeElement)) {
    //         return 179 + (this.scrollDiv.nativeElement.offsetHeight / 2) + 'px'
    //     } else {
    //         return undefined
    //     }
    // }



}
