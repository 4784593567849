<div class="flex-around-center">
    <div style="width:65px;border-radius:5px;background:white;padding:5px;font-weight: bold;cursor: unset">
        <div class="flex-around-center">
            <div style="margin-top: 2px;" [ngClass]="{'disabled':disabled}">
                {{form.get('hour').value}}
            </div>
            <div class="flex-center-start flex-flow-column">
                <button class="arrow-button" (click)="updateHour(1)">
                    <mat-icon class="icon" [ngClass]="{'disabled':disabled}">expand_less
                    </mat-icon>
                </button>
                <button class="arrow-button" (click)="updateHour(-1)">
                    <mat-icon class="icon" [ngClass]="{'disabled':disabled}">expand_more
                    </mat-icon>
                </button>
            </div>
        </div>
    </div>
    <span style="font-weight: bold;font-size: x-large">&nbsp;:&nbsp;</span>
    <div style="width:65px;border-radius:5px;background:white;padding:5px;font-weight: bold;cursor: unset">
        <div class="flex-around-center">
            <div style="margin-top: 2px;" [ngClass]="{'disabled':disabled}">
                {{form.get('minute').value}}
            </div>
            <div class="flex-center-start flex-flow-column">
                <button class="arrow-button" (click)="updateMinute(1)">
                    <mat-icon class="icon" [ngClass]="{'disabled':disabled}">expand_less
                    </mat-icon>
                </button>
                <button class="arrow-button" (click)="updateMinute(-1)">
                    <mat-icon class="icon" [ngClass]="{'disabled':disabled}">expand_more
                    </mat-icon>
                </button>
            </div>
        </div>
    </div>
    <button mat-icon-button [color]="color" [disabled]="disabled" (click)="showPicker()">
        <mat-icon>access_time</mat-icon>
    </button>
</div>