import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    OnInit,
} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {PrintPageService} from '../../../providers/services/print-page.service';
import {ProjectService} from '../../../providers/services/project.service';
import {DialogPopUpInfoService} from '../../../providers/services/dialog-pop-up-info.service';
import {TranslateService} from '@ngx-translate/core';
import jsPDF from 'jspdf';
import 'jspdf-autotable'
import {HttpClient} from "@angular/common/http";
import {formatDate} from "@angular/common";
import {OrganizzazioneParse} from "../../../models/Organizzazione.Parse";
import {isNotNullOrUndefined} from "../../../models/Models";

@Component({
    selector: 'app-print-report-energia',
    templateUrl: './print-report-energia.component.html',
    styleUrls: ['./print-report-energia.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintReportEnergiaComponent implements OnInit, AfterViewInit {

    public today = Date.now();

    public loaderPrint: boolean = false;

    constructor(
        private http: HttpClient,
        private printPage: PrintPageService,
        private translateService: TranslateService,
        private elementRef: ElementRef,
        private projectService: ProjectService,
        private dialogInfo: DialogPopUpInfoService,
        private changeDetectorRef: ChangeDetectorRef,
        public dialogRef: MatDialogRef<PrintReportEnergiaComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: { chart: any[], organizzazione: OrganizzazioneParse, dataRiferimento: Date }
    ) {
    }


    ngAfterViewInit(): void {
        this.generateNumberPages();
    }

    ngOnInit(): void {
    }


    public closeDialog() {
        this.dialogRef.close();
    }

    openInfo() {
        this.dialogInfo.openInfoPrintStreet();
    }


    private addChartFirstPage(doc, chart, lastCoords, pageWidth) {
        let addTitle = 0;
        if (isNotNullOrUndefined(chart.data.consumoOrario)) {
            const incidenzaSullaMedia = this.translateService.instant('reportEnergy.incidentAverage') + ' ' + this.getIncidentAverage(chart, 'curvaOrariaPotenze');
            if (this.getIncidentAverage(chart, 'curvaOrariaPotenze') !== this.translateService.instant('NaN')) {
                this.printPage.addH3(doc, incidenzaSullaMedia, pageWidth / 2, lastCoords.lastY);
                addTitle = 15;
            }
            lastCoords = this.printPage.addImage(doc, chart.data.consumoOrario, 200, 80, pageWidth / 2, lastCoords.lastY + 12 + addTitle);
        }
        addTitle = 0;
        if (isNotNullOrUndefined(chart.data.energiaAttiva)) {
            if (this.getIncidentAverage(chart, 'energiaAttivaGiornaliera') !== this.translateService.instant('NaN')) {
                const incidenzaSullaMedia = this.translateService.instant('reportEnergy.incidentAverage') + ' ' + this.getIncidentAverage(chart, 'energiaAttivaGiornaliera');
                this.printPage.addH3(doc, incidenzaSullaMedia, pageWidth / 2, lastCoords.lastY);
                addTitle = 15;
            }
            lastCoords = this.printPage.addImage(doc, chart.data.energiaAttiva, 200, 80, pageWidth / 2, lastCoords.lastY + 12 + addTitle);
        }
        addTitle = 0;
        if (isNotNullOrUndefined(chart.data.energiaReattiva)) {
            if (this.getIncidentAverage(chart, 'energiaReattivaGiornaliera') !== this.translateService.instant('NaN')) {
                const incidenzaSullaMedia = this.translateService.instant('reportEnergy.incidentAverage') + ' ' + this.getIncidentAverage(chart, 'energiaReattivaGiornaliera');
                this.printPage.addH3(doc, incidenzaSullaMedia, pageWidth / 2, lastCoords.lastY);
                addTitle = 15;
            }
            lastCoords = this.printPage.addImage(doc, chart.data.energiaReattiva, 200, 80, pageWidth / 2, lastCoords.lastY + 12 + addTitle);
        }
    }

    private addChartSecondPage(doc, chart, lastCoords, pageWidth) {
        let addTitle = 0;
        if (isNotNullOrUndefined(chart.data.F1F2F3)) {
            if (this.getIncidentAverage(chart, 'fasce') !== this.translateService.instant('NaN')) {
                const incidenzaSullaMedia = this.translateService.instant('reportEnergy.incidentAverage') + ' ' + this.getIncidentAverage(chart, 'fasce');
                this.printPage.addH3(doc, incidenzaSullaMedia, pageWidth / 2, lastCoords.lastY);
                addTitle = 15;
            }
            return this.printPage.addImage(doc, chart.data.F1F2F3, 150, 50, pageWidth / 2, 90 + addTitle);
        }
    }


    private addAllTable(doc, chart, lastCoords) {

        const tableCost = this.getTable(chart, 'costConsuption');
        const tablePower = this.getTable(chart, 'power');
        const tableYearConsuption = this.getTable(this.data.chart[0], 'yearConsuption');
        const tableHour = this.getTable(chart, 'hour');
        let tableForPrint = [];
        let titleTableForPrint = [];
        if (tableCost.length > 0) {
            tableForPrint.push(tableCost);
            titleTableForPrint.push(this.translateService.instant('reportEnergy.costConsuption.title'));
        }
        if (tablePower.length > 0) {
            tableForPrint.push(tablePower);
            titleTableForPrint.push(this.translateService.instant('reportEnergy.power.title'));
        }
        lastCoords = this.printPage.sideBySideTable(doc, tableForPrint, titleTableForPrint, "vertical", 80, lastCoords.lastY + 20);
        tableForPrint = [];
        titleTableForPrint = [];
        if (tableYearConsuption.length > 0) {
            tableForPrint.push(tableYearConsuption);
            titleTableForPrint.push(this.translateService.instant('reportEnergy.yearConsuption.title'));
        }
        if (tableHour.length > 0) {
            tableForPrint.push(tableHour);
            titleTableForPrint.push(this.translateService.instant('reportEnergy.hour.title'));
        }
        const lastX = (titleTableForPrint.length == 1) ? lastCoords.spaceBetweenTable : 0;
        lastCoords = this.printPage.sideBySideTable(doc, tableForPrint, titleTableForPrint, "vertical", 80, lastCoords.lastY + 20, lastX);
    }


    private addPageChartAndTableSumm(doc, chart) {
        const pageWidth = doc.internal.pageSize.getWidth();
        const firstYavaiableInPage = this.printPage.headHeight;
        doc.addPage();
        let lastCoords: any = {};
        lastCoords['lastY'] = 70;
        this.printPage.addH2(doc, this.translateService.instant('reportEnergy.summAllCircuit'), pageWidth / 2, firstYavaiableInPage + 15);
        this.addChartFirstPage(doc, chart, lastCoords, pageWidth);
        doc.addPage();
        this.printPage.addH2(doc, this.translateService.instant('reportEnergy.summAllCircuit'), pageWidth / 2, firstYavaiableInPage + 15);
        lastCoords = this.addChartSecondPage(doc, chart, lastCoords, pageWidth);
        this.addAllTable(doc, chart, lastCoords);
    }

    private addPageChartAndTableSingle(doc: jsPDF, chart) {
        const pageWidth = doc.internal.pageSize.getWidth();
        const firstYavaiableInPage = this.printPage.headHeight;
        doc.addPage();
        let lastCoords: any = {};
        lastCoords['lastY'] = 70;
        const circuiti = this.translateService.instant('dashboard_sidenav.Circuiti.numeroQuadro.title');
        const numeroQuadro = chart.circuito.numeroQuadro;
        const pod = this.translateService.instant('dashboard_sidenav.Circuiti.POD.title');
        const numeroPod = chart.circuito.numeroPod;
        this.printPage.addH3(doc, circuiti, 30, firstYavaiableInPage + 15);
        this.printPage.addH3(doc, numeroQuadro, 30 + doc.getTextWidth(circuiti) + 10, firstYavaiableInPage + 15);
        this.printPage.addH3(doc, pod, pageWidth - 20 - doc.getTextWidth(numeroPod) / 2 - doc.getTextWidth(pod) - doc.getTextWidth(pod) / 2 - 20, firstYavaiableInPage + 15);
        this.printPage.addH3(doc, numeroPod, pageWidth - 20 - doc.getTextWidth(numeroPod) / 2, firstYavaiableInPage + 15);
        this.addChartFirstPage(doc, chart, lastCoords, pageWidth);
        doc.addPage();
        this.printPage.addH3(doc, circuiti, 30, firstYavaiableInPage + 15);
        this.printPage.addH3(doc, numeroQuadro, 30 + doc.getTextWidth(circuiti) + 10, firstYavaiableInPage + 15);
        this.printPage.addH3(doc, pod, pageWidth - 20 - doc.getTextWidth(numeroPod) / 2 - doc.getTextWidth(pod) - doc.getTextWidth(pod) / 2 - 20, firstYavaiableInPage + 15);
        this.printPage.addH3(doc, numeroPod, pageWidth - 20 - doc.getTextWidth(numeroPod) / 2, firstYavaiableInPage + 15);
        lastCoords = this.addChartSecondPage(doc, chart, lastCoords, pageWidth);
        this.addAllTable(doc, chart, lastCoords);
    }


    get urlLogoOrganizzazione() {
        if (isNotNullOrUndefined(this.data.organizzazione) && isNotNullOrUndefined(this.data.organizzazione.logo)) {
            return this.data.organizzazione.logo.url()
        } else {
            return undefined;
        }
    }


    callPrint() {
        this.loaderPrint = true;
        setTimeout(() => {
            if (isNotNullOrUndefined(this.data.organizzazione) && isNotNullOrUndefined(this.data.organizzazione.logo)) {
                const image = new Image()
                image.src = this.data.organizzazione.logo.url();
                this.printPage.organizzazioneLogo = image;
            }
            const firstYavaiableInPage = this.printPage.headHeight;
            const doc = new jsPDF();
            this.printPage.addFont(doc);
            const pageWidth = doc.internal.pageSize.getWidth();
            this.printPage.headHeight = 25;
            this.printPage.firstPage(doc, this.data.organizzazione, this.today);
            const title = this.translateService.instant('reportEnergy.printTitle');
            this.printPage.addH1(doc, title, 100, 80);
            const meseAnnoRiferimento = formatDate(this.data.dataRiferimento, 'MMMM yyyy', this.translateService.currentLang);
            this.printPage.addH2(doc, meseAnnoRiferimento, 100, 120);
            this.printPage.addH2(doc, this.projectName, 100, 140);
            const circuitiTitle = this.translateService.instant('reportEnergy.totalPOD') + ' ' + ((Array.isArray(this.data.chart)) ? this.data.chart.length - 1 : 0).toString();
            this.printPage.addH2(doc, circuitiTitle, 100, 160);
            this.data.chart.forEach((chart, index) => {
                if (index > 0) {
                    this.addPageChartAndTableSingle(doc, chart);
                } else {
                    this.addPageChartAndTableSumm(doc, chart);
                }
            });
            const today = formatDate(this.today, 'shortDate', this.translateService.currentLang);
            this.printPage.addHeader(doc, this.data.organizzazione, this.projectName, today);
            this.printPage.convertToPdf(doc, title + ' ' + this.projectName);
            this.loaderPrint = false;
            this.changeDetectorRef.detectChanges();
        }, 500);
    }

    get projectName() {
        return this.projectService.actualProject.name;
    }

    generateNumberPages() {
        const elementsToPrint = this.elementRef.nativeElement.getElementsByClassName('printableArea');
        this.printPage.generateNumberPages(elementsToPrint);
    }

    getIncidentAverage(chart, key: 'curvaOrariaPotenze' | 'energiaAttivaGiornaliera' | 'energiaReattivaGiornaliera' | 'fasce') {
        if (isNotNullOrUndefined(chart.incidentAverage)) {
            if (chart.incidentAverage[key] > 0) {
                return '+' + chart.incidentAverage[key] + ' %';
            } else {
                return chart.incidentAverage[key] + ' %';
            }

        } else {
            return this.translateService.instant('NaN');
        }
    }

    getValueWithUnitMisure(value, keytable: 'hour' | 'costConsuption' | 'yearConsuption' | 'power', keyValue) {
        if (!isNotNullOrUndefined(value)) {
            return this.translateService.instant('NaN');
        } else {
            let unitMisure;
            const maxNumberDigit = (value > 1000) ? 0 : 2;
            switch (keytable) {
                case 'hour':
                    return new Intl.NumberFormat(this.translateService.currentLang, {maximumFractionDigits: 0}).format(value) + ' h';
                case 'costConsuption':
                    const euro = '  €';
                    unitMisure = (keyValue === 'saving') ? ' %' : euro;
                    return new Intl.NumberFormat(this.translateService.currentLang, {maximumFractionDigits: 0}).format(value) + unitMisure;
                case 'yearConsuption':
                    return new Intl.NumberFormat(this.translateService.currentLang, {maximumFractionDigits: 0}).format(value) + ' kWh';
                case 'power':
                    const kw = ' ' + this.translateService.instant('measureUnit.kw');
                    unitMisure = (keyValue === 'fattoreDiPotenza') ? '' : kw;
                    return new Intl.NumberFormat(this.translateService.currentLang, {maximumFractionDigits: maxNumberDigit}).format(value) + unitMisure;
            }
        }
    }

    getTable(chart, keyTable: 'hour' | 'costConsuption' | 'yearConsuption' | 'power') {
        const table = [];
        if (isNotNullOrUndefined(chart[keyTable])) {
            Object.keys(chart[keyTable]).forEach((key) => {
                if (isNotNullOrUndefined(chart[keyTable][key])) {
                    table.push({
                        value0: 'reportEnergy.' + keyTable + '.' + key,
                        value1: this.getValueWithUnitMisure(chart[keyTable][key], keyTable, key)
                    });
                }
            });
        }
        return table;
    }

}
