<mat-dialog-content>
    <ng-container *ngIf="elements">
        <form [formGroup]="filtersForm" [hidden]="!filtersForm">
            <mat-card-header>
                <div mat-card-avatar>
                    <mat-icon>search</mat-icon>
                </div>
                <mat-card-subtitle>
                    {{'filter'|translate}}
                </mat-card-subtitle>
            </mat-card-header>
            <div class="flex-center-width100 flex-flow-row">
                <mat-form-field class="filter-field">
                    <mat-label>{{'name'|translate}}</mat-label>
                    <input matInput formControlName="nome">
                </mat-form-field>
                <mat-form-field class="filter-field">
                    <mat-label>{{'surname'|translate}}</mat-label>
                    <input matInput formControlName="cognome">
                </mat-form-field>
            </div>
        </form>
        <form [formGroup]="formElements" [hidden]="!formElements" style="overflow-y:auto">
            <ng-container *ngIf="multipleValues;else singleValue">
                <div class="user-card-container">
                    <ng-container
                        *ngFor="let user of (elements|filterByPredicateFunction:predicateFunction:this.filtersForm.get('nome').value:this.filtersForm.get('cognome').value)">
                        <mat-card class="user-card">
                            <mat-card-header>
                                <div mat-card-avatar class="user-avatar">
                                    <mat-icon class="icon-L" [color]="getColorIcon(user)">account_circle</mat-icon>
                                </div>
                                <mat-card-title>{{getValue(user, 'nome')}}&#160;{{getValue(user, 'cognome')}}
                                </mat-card-title>
                                <mat-card-subtitle>
                                    <ng-container *ngFor="let role of getValue(user, 'role')">
                                        {{role|translate}} &#160;
                                    </ng-container>
                                </mat-card-subtitle>
                            </mat-card-header>

                            <mat-checkbox [formControlName]="getId(user)" color="primary"></mat-checkbox>

                        </mat-card>
                    </ng-container>
                </div>
            </ng-container>
            <ng-template #singleValue>
                <mat-radio-group formControlName="objectId" class="user-card-container">
                    <ng-container
                        *ngFor="let user of elements|filterByPredicateFunction:predicateFunction:this.filtersForm.get('nome').value:this.filtersForm.get('cognome').value">
                        <mat-card class="user-card">
                            <mat-card-header>
                                <div mat-card-avatar class="user-avatar">
                                    <mat-icon class="icon-L" [color]="getColorIcon(user)">account_circle
                                    </mat-icon>
                                </div>
                                <mat-card-title>{{getValue(user, 'nome')}}&#160;{{getValue(user, 'cognome')}}
                                </mat-card-title>
                                <mat-card-subtitle>
                                    <ng-container *ngFor="let role of getValue(user, 'role')">
                                        {{role|translate}} &#160;
                                    </ng-container>
                                </mat-card-subtitle>
                            </mat-card-header>
                            <mat-radio-button [value]="getId(user)" style="margin: 0;padding: 0" color="primary">
                            </mat-radio-button>
                        </mat-card>
                    </ng-container>
                </mat-radio-group>
            </ng-template>
        </form>
    </ng-container>
</mat-dialog-content>
<mat-dialog-actions class="flex-end-center">
    <button [mat-dialog-close]="undefined" mat-raised-button>
        <mat-icon>clear</mat-icon>
    </button>
    <button (click)="getActivedElements()" mat-raised-button color="primary">
        <mat-icon>check</mat-icon>
    </button>
</mat-dialog-actions>