<mat-card class="card-size" [ngClass]="{'disabled-card':disabled}">
    <mat-card-header *ngIf="showHeader">
        <mat-card-title>{{photometry.nomeFamiglia}}</mat-card-title>
        <mat-card-subtitle>{{photometry.nomeLampada}}</mat-card-subtitle>
        <button mat-flat-button color="accent" class="button-card-sovrapossition"
                (click)="clickButton.emit()" *ngIf="icon">
            <mat-icon *ngIf="icon.matIcon;else noMatIcon">{{icon.pathName}}</mat-icon>
            <ng-template #noMatIcon>
                <img [src]="icon.pathName" style="width: 24px;height: 24px">
            </ng-template>
        </button>
    </mat-card-header>
    <div class="flex-start-start flex-flow-column text-container " *ngIf="photometry.nomeFotometria.length">
        <div class="text-child">{{'fotometrie.nomeFotometria'|translate}}</div>
        <div>{{photometry.nomeFotometria}}</div>
    </div>
    <div class="flex-start-center w100 flex-flow-row">
        <div class="flex-start-start flex-flow-column text-container" *ngIf="photometry.produttore">
            <div class="text-child">{{'fotometria.produttore'|translate}}</div>
            <div>{{getFotometriaValue(photometry, 'produttore')}}</div>
        </div>
        <div class="flex-start-start flex-flow-column text-container"
             *ngIf="photometry.potenza">
            <div class="text-child">{{'fotometria.potenza'|translate}}</div>
            <div>{{getFotometriaValue(photometry, 'potenza')}} {{getUnit('potenza')}}</div>
        </div>
        <div class="flex-start-start flex-flow-column text-container" *ngIf="photometry.ottica">
            <div class="text-child">{{'fotometria.ottica'|translate}}</div>
            <div>{{getFotometriaValue(photometry, 'ottica')}}</div>
        </div>
        <div class="flex-start-start flex-flow-column text-container" *ngIf="photometry.temperaturaColore">
            <div class="text-child">{{'fotometria.temperaturaColore'|translate}}</div>
            <div>{{getFotometriaValue(photometry, 'temperaturaColore')}}</div>
        </div>
        <div class="flex-start-start flex-flow-column text-container" *ngIf="photometry.flussoLuminoso">
            <div class="text-child">{{'fotometria.flussoLuminoso'|translate}}</div>
            <div>{{getFotometriaValue(photometry, 'flussoLuminoso')}} {{getUnit('flusso')}}</div>
        </div>
    </div>
</mat-card>
