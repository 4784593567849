import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output, SimpleChanges
} from '@angular/core';
import {CircuitiParse} from "../../../models/Circuiti.Parse";
import {PuntiLuceParse} from "../../../models/PuntiLuce.Parse";
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {arrayIsSet, DefaultCenterMap, isNotNullOrUndefined, objectIsSet} from "../../../models/Models";
import {ClusterType, InfoWindowValueType, MarkerType} from "../../component-with-map/main-map/main-map.component";
import {GoogleServiceService} from "../../../providers/services/google-service.service";
import {IconService} from "../../../providers/services/icon.service";

@Component({
    selector: 'app-visualized-elements-in-map',
    templateUrl: './visualized-elements-in-map.component.html',
    styleUrls: ['./visualized-elements-in-map.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VisualizedElementsInMapComponent implements OnChanges, OnDestroy {

    map: google.maps.Map;
    @Input() circuiti: CircuitiParse[];
    @Input() puntiLuce: PuntiLuceParse[];
    @Input() fitBounds = true;
    @Input() boundChangeActive = false;
    @Input() height: string = '100vh';
    @Input() mapControl = {type: false};
    @Input() markerCliccable = false;
    @Input() maxZoomCluster = 20;
    @Output() boundChange = new EventEmitter();
    @Output() mapReady = new EventEmitter<google.maps.Map>();
    @Output() emitMarker = new EventEmitter();
    boundChangeWithContolOnValue = new EventEmitter();
    infoWindowValueEmit = new BehaviorSubject<InfoWindowValueType>(undefined);
    infoWindowValue$: Observable<InfoWindowValueType>;
    firstLoad = true;
    subScriptionBoundChages: Subscription;
    markerLightPointsEmit = new BehaviorSubject<any>(undefined);
    markerLightPoints$ = this.markerLightPointsEmit.asObservable();
    mapChangeEmit = new BehaviorSubject<{
        tiles: google.maps.LatLngBounds[],
        zoom: number,
        center: google.maps.LatLng
    } | undefined>(undefined)
    mapChange$ = this.mapChangeEmit.asObservable();

    public subscriptions = new Subscription();

    constructor(private googleService: GoogleServiceService,
                private iconService: IconService) {
        this.subscriptions.add(
            this.mapChange$.pipe(
                debounceTime(300)
            ).subscribe(mapChange => {
                this.getElementsInMapWebWorkers(this.puntiLuce, mapChange);
            })
        )
    }


    ngOnChanges(changes: SimpleChanges): void {
        if (changes.boundChangeActive) {
            if (changes.boundChangeActive.currentValue) {
                if (isNotNullOrUndefined(this.map)) {
                    const bounds = this.map.getBounds();
                    this.boundChange.emit(bounds);
                }
                this.subScriptionBoundChages = this.boundChangeWithContolOnValue.pipe(
                    debounceTime(600),
                    distinctUntilChanged()
                ).subscribe(
                    bounds => {
                        this.boundChange.emit(bounds)
                    }
                )
            } else {
                if (isNotNullOrUndefined(this.subScriptionBoundChages)) {
                    this.subScriptionBoundChages.unsubscribe();
                }
            }
        }
        if (changes.puntiLuce || changes.circuiti) {
            const previusPuntiLuceIsSet = changes.puntiLuce && arrayIsSet(changes.puntiLuce.previousValue);
            const currentPuntiLuceIsSet = changes.puntiLuce && arrayIsSet(changes.puntiLuce.currentValue);
            const previusCircuitiIsSet = changes.circuiti && arrayIsSet(changes.circuiti.previousValue);
            const currentCircuitiIsSet = changes.circuiti && arrayIsSet(changes.circuiti.currentValue);
            if (!(previusPuntiLuceIsSet && previusCircuitiIsSet) && (currentPuntiLuceIsSet || currentCircuitiIsSet)) {
                let bounds = this.getBoundsElementsInMap;
                if (this.map && this.fitBounds) {
                    this.map.fitBounds(bounds);
                    this.firstLoad = false;
                }
            }
            if (changes.puntiLuce != null) {
                this.getElementsInMapWebWorkers(this.puntiLuce, this.mapChange);
            }
        }
    }

    ngOnDestroy(): void {
        if (isNotNullOrUndefined(this.subScriptionBoundChages)) {
            this.subScriptionBoundChages.unsubscribe();
        }
    }

    get getBoundsElementsInMap() {
        let bounds = new google.maps.LatLngBounds();
        if (!arrayIsSet(this.puntiLuce) && !arrayIsSet(this.circuiti)) {
            bounds.extend(DefaultCenterMap)
        } else {
            if (arrayIsSet(this.puntiLuce)) {
                this.puntiLuce.forEach(puntoLuce => {
                    const latLng = new google.maps.LatLng(puntoLuce.location.latitude, puntoLuce.location.longitude);
                    bounds.extend(latLng);
                })
            }
            if (arrayIsSet(this.circuiti)) {
                this.circuiti.forEach(circuito => {
                    const latLng = new google.maps.LatLng(circuito.location.latitude, circuito.location.longitude);
                    bounds.extend(latLng);
                })
            }
        }
        return bounds
    }

    getElementsInMapWebWorkers(elements: PuntiLuceParse[], mapChange: {
        tiles: google.maps.LatLngBounds[],
        zoom: number,
        center: google.maps.LatLng
    }) {
        if (typeof Worker !== 'undefined') {
            const worker = new Worker(new URL('../../../providers/webWorkers/calculate-clusters.worker', import.meta.url));
            if (!arrayIsSet(elements)) {
                this.markerLightPoints = undefined;
            } else if (objectIsSet(mapChange)) {
                const tiles = mapChange.tiles.map(tile => {
                    const northEast = tile.getNorthEast();
                    const southWest = tile.getSouthWest();
                    return {
                        northEast: {latitude: northEast.lat(), longitude: northEast.lng()},
                        southWest: {latitude: southWest.lat(), longitude: southWest.lng()},
                        center: {latitude: tile.getCenter().lat(), longitude: tile.getCenter().lng()}
                    }
                })
                const center = {latitude: mapChange.center.lat(), longitude: mapChange.center.lat()};
                const zoom = mapChange.zoom;
                worker.postMessage({
                    mapChange: {tiles, center, zoom},
                    lightPoints: elements.map(element => {
                        return {
                            objectId: element.objectId,
                            location: {latitude: element.location.latitude, longitude: element.location.longitude},
                            icon: element.icon,
                        }
                    })
                });
                worker.onmessage = (a) => {
                    if (arrayIsSet(a.data.elementsInMap) || arrayIsSet(a.data.clustersInMap)) {
                        if (arrayIsSet(a.data.elementsInMap)) {
                            const ids = a.data.elementsInMap.map(idLight => idLight.objectId)
                            a.data.elementsInMap = elements
                                .filter(l => ids.includes(l.objectId))
                                .map(l => new MarkerType(l))
                        }
                        if (arrayIsSet(a.data.clustersInMap)) {
                            a.data.clustersInMap = this.googleService.splitClustersWithEqualsCenter(a.data.clustersInMap)
                                .map(cluster => {
                                    cluster.icon = this.iconService.getImageClusterLightPoint(cluster.numeroPuntiLuce, cluster.color, 0.5)
                                    return cluster
                                })
                        }
                    }
                    this.markerLightPoints = a.data;
                };


            }
        }
    }

    get markerLightPoints() {
        return this.markerLightPointsEmit.value
    }

    set markerLightPoints(event) {
        this.markerLightPointsEmit.next(event)
    }

    get mapChange() {
        return this.mapChangeEmit.value
    }

    set mapChange(event) {
        this.mapChangeEmit.next(event)
    }

    public trackRowsPuntiLuce(index: number, puntoLuce): string {
        //console.log("tb",m.id);
        return puntoLuce.objectId;
    }

    public trackRowsCircuiti(index: number, circuito): string {
        //console.log("tb",m.id);
        return circuito.objectId;
    }


    isSet(who) {
        return Array.isArray(who) && who.length > 0
    }


    mapBoundChange(event) {
        if (this.boundChangeActive) {
            const bounds = this.map.getBounds();
            this.boundChangeWithContolOnValue.next(bounds)
        }
        const bounds = this.map.getBounds();
        if (bounds != null) {
            let tiles: google.maps.LatLngBounds[];
            const center = this.map.getCenter();
            const zoom = this.map.getZoom();
            if (this.map.getZoom() > this.maxZoomCluster && this.map.getBounds() != null) {
                tiles = this.googleService.tilesMap(bounds, 0);
            } else if (this.map.getZoom() > (this.maxZoomCluster - 2) && this.map.getBounds() != null) {
                tiles = this.googleService.tilesMap(bounds, 1);
            } else {
                tiles = this.googleService.tilesMap(bounds, 2);
            }
            this.mapChange = {tiles, zoom, center}
        }
    }

    mapIsReady(event) {
        this.map = event;
        this.map.setOptions({
            tilt: 45,
            center: new google.maps.LatLng(DefaultCenterMap.lat, DefaultCenterMap.lng),
            mapTypeId: google.maps.MapTypeId.SATELLITE,
            fullscreenControl: false,
            minZoom: 7,
        });
        if (this.firstLoad) {
            this.firstLoad = false;
            const bounds = this.getBoundsElementsInMap
            if (bounds != null) {
                this.map.fitBounds(bounds);
            }
        }
        this.mapBoundChange(this.map.getBounds())
        this.mapReady.next(this.map)
    }

    clickMarker(marker: PuntiLuceParse | CircuitiParse) {
        this.emitMarker.next(marker)
    }

    clickPuntoLuce(marker: MarkerType) {
        const puntoLuce = this.puntiLuce.find(pl => pl.objectId === marker.objectId)
        this.clickMarker(puntoLuce);
    }

    clickCircuito(marker: MarkerType) {
        const circuito = this.circuiti.find(circuito => circuito.objectId === marker.objectId)
        this.clickMarker(circuito);
    }

    getPredicateMarkerType(circuiti: CircuitiParse[]) {
        if (arrayIsSet(circuiti)) {
            return circuiti.map(circuito => new MarkerType(circuito))
        } else {
            return undefined
        }
    }

    closeInfoWindow() {
        this.infoWindowValue = undefined;
    }

    get infoWindowValue(): InfoWindowValueType {
        return this.infoWindowValueEmit.value
    }

    set infoWindowValue(value: InfoWindowValueType | undefined) {
        this.infoWindowValueEmit.next(value);
    }

    clusterClick(cluster: ClusterType) {
        const center = cluster.center ? cluster.center : cluster.bounds.getCenter();
        const zoom = this.map.getZoom() < 15 ? this.map.getZoom() + 2 : this.map.getZoom() + 1
        this.map.moveCamera({center, zoom})
    }
}
