<div class="data" style="text-align: center;margin-top: -30px;">
    <H4>
        <img class="icon-dimension" src="/assets/icon/dialogCompnent/ask.png">
    </H4>
</div>
<div class="flex-container" *ngIf="visualizzaBottone;else confermDelete">
    <div style="width: 100%;text-align: center">
        <h5>{{'selectDelete'|translate}}</h5>
    </div>
    <button mat-mini-fab color="warn" (click)="selectElementDelete(nomiClasse.puntiLuce)">
        <img src="/assets/icon/dialogCompnent/lampione.svg" style="max-height: 24px;min-height: 24px;" alt="lampione">
    </button>
    <button mat-mini-fab color="warn" (click)="selectElementDelete(nomiClasse.circuiti)">
        <img src="/assets/icon/dialogCompnent/quadro.png" style="max-height: 24px;min-height: 24px;"
             alt="quadro elettrico">
    </button>
    <button mat-mini-fab [mat-dialog-close]="false">
        <mat-icon>close</mat-icon>
    </button>
</div>
<ng-template #confermDelete>
    <div class="flex-container">
        <ng-container *ngIf="data['number' + deleteName]>0;else noElementDelete">
            <div style="width: 100%;text-align: center">
                <h5>{{'confermDelete'|translate}} {{data['number' + deleteName]}}  {{deleteName |translate}}</h5>
            </div>
            <button mat-mini-fab color="warn" [mat-dialog-close]="deleteName">
                <mat-icon>check</mat-icon>
            </button>
            <div style="margin-right: 10px"></div>
        </ng-container>
        <ng-template #noElementDelete>
            <div style="width: 100%;text-align: center">
                <h5>{{'noElementDelete'|translate}}</h5>
            </div>
        </ng-template>
        <button mat-mini-fab [mat-dialog-close]="false">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</ng-template>
