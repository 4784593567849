import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {ArredoUrbanoUtilsService} from "../../providers/services/arredo-urbano-utils.service";
import {TranslateService} from "@ngx-translate/core";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {arrayIsSet, isNotNullOrUndefined, stringIsSet} from "../../models/Models";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-widget-select-arredo-urbano',
    templateUrl: './widget-select-arredo-urbano.component.html',
    styleUrls: ['./widget-select-arredo-urbano.component.scss']
})
export class WidgetSelectArredoUrbanoComponent implements OnInit, OnChanges, OnDestroy {
    typesArredoUrbano
    public formPage: UntypedFormGroup;
    @Output() changeValue = new EventEmitter();
    @Input() maxHeigth = '';
    @Input() typesArredoUrbanoInProject: string[];
    customTypeArredoUrbano: string[];

    hiddenInfo = false;
    subscriptions: Subscription[] = [];

    constructor(
        private arredoUrbanoUtilsService: ArredoUrbanoUtilsService,
        private translateService: TranslateService,
        private fb: UntypedFormBuilder
    ) {
        this.formPage = this.fb.group({typeArredoUrbano: null})

        this.typesArredoUrbano = this.arredoUrbanoUtilsService.keysTipologieArredoUrbano.map(
            value => {
                const traduction = this.translateService.instant('dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.' + value.key);
                return {
                    ...value,
                    traduction
                }
            }
        );


        const subscription = this.formPage.get('typeArredoUrbano').valueChanges.subscribe(val => {
            this.changeValue.next(val);
        })
        this.subscriptions.push(subscription);
    }

    ngOnInit(): void {

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (isNotNullOrUndefined(changes.typesArredoUrbanoInProject) && arrayIsSet(this.typesArredoUrbanoInProject)) {
            const typesArredoUrbano = this.typesArredoUrbano.map(type => type.key)
            this.customTypeArredoUrbano = this.typesArredoUrbanoInProject.filter(typeInProject => !typesArredoUrbano.includes(typeInProject))
        } else {
            this.customTypeArredoUrbano = undefined;
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    addTypeArredoUrbano(key) {
        if (this.formPage.get('typeArredoUrbano')) {
            this.formPage.get('typeArredoUrbano').setValue(key);
        }
    }

    predicateFunction(typeArredoUrbano, valueType) {
        if (stringIsSet(typeArredoUrbano.key) && stringIsSet(valueType)) {
            const typeArredoUrbanoLower = typeArredoUrbano.key.toLowerCase();
            const valueTypeLower = valueType.toLowerCase();
            return typeArredoUrbanoLower.includes(valueTypeLower);
        } else {
            return true;
        }
    }

    predicateFunctionCustom(typeArredoUrbano, valueType) {
        if (stringIsSet(typeArredoUrbano) && stringIsSet(valueType)) {
            const typeArredoUrbanoLower = typeArredoUrbano.toLowerCase();
            const valueTypeLower = valueType.toLowerCase();
            return typeArredoUrbanoLower.includes(valueTypeLower);
        } else {
            return true;
        }
    }

    clickHidden() {
        this.hiddenInfo = !this.hiddenInfo;
    }

    get selectedType() {
        const key = this.formPage.get('typeArredoUrbano').value;
        const existingType = this.typesArredoUrbano.find((type) => type.key === key);
        if (existingType) { return existingType.traduction };
        return key;
    }
}
