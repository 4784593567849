import {
    AfterViewInit,
    Component,
    ElementRef,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    QueryList,
    Renderer2,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {PuntiLuceParse, PuntoLuce_v2} from 'src/app/models/PuntiLuce.Parse';
import {ordinamentoEcampiTraduzioni} from 'src/app/models/ordinamentoEcampiTraduzioni';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import {MatSort, MatSortHeader} from '@angular/material/sort';
import {combineLatest, Observable, of, Subscription} from 'rxjs';
import {AlertService} from 'src/app/providers/services/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {StradeParse} from "../../../models/Strade.Parse";
import {CircuitiParse} from "../../../models/Circuiti.Parse";
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {
    arrayIsSet,
    cleanToSpecialCharacterAndLowerCaseString,
    isNotNullOrUndefined,
    KeyStringValue,
    stringIsSet
} from "../../../models/Models";
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {typeFormValue} from "../../../models/configurationProperty/configurationPropertyUtils";
import {dataForm} from "../../confirm-delete/select-or-create/select-or-create.component";
import {filter, map, startWith, switchMap, take} from "rxjs/operators";
import {LocalSotrageService} from "../../../providers/services/local-sotrage.service";
import {MatLegacySlideToggleChange as MatSlideToggleChange} from "@angular/material/legacy-slide-toggle";
import {DialogPopUpInfoService} from "../../../providers/services/dialog-pop-up-info.service";
import {FormFieldPopUpData, FormFieldPopUpType} from "../../pop-up-info/form-field/form-field.component";
import {MatLegacySelectionList as MatSelectionList} from "@angular/material/legacy-list";

export type htmlForm = {
    formControlName: string;
    traduction: string;
    notTraduction: string;
    traductions?: { keys: string[]; separetor?: string };
    typeField: typeFormValue;
    possibleValues: dataForm[];
    editable: boolean;
    pointer?: {
        getValue: (p: PuntiLuceParse) => string,
        getValueToSetInLightPoint: (value: string, possibleValues: dataForm[]) => any,
        getValueForSave: (value: any) => any,
    }
}


@Component({
    selector: 'app-batch-light-editor',
    templateUrl: './batch-light-editor.component.html',
    styleUrls: ['./batch-light-editor.component.scss']
})
export class BatchLightEditorComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('"scrollDiv"') scrollDiv: ElementRef;
    @ViewChild('ClickSelectsElement') clickSelectsElement: MatSelectionList;

    @Input()
    editableLights: any [] = [];

    dataSource: MatTableDataSource<PuntiLuceParse>;

    @ViewChild(MatPaginator, {static: true})
    paginator: MatPaginator;

    @ViewChild(MatSort, {static: true})
    sort: MatSort;


    @ViewChildren('columnsTable')
    columnsTable: QueryList<MatSortHeader>;


    newValue: any;


    allColumnsHtml: htmlForm[];


    keySearchInColumn$: Observable<string>
    visibleColumns$: Observable<htmlForm[]>
    visibleColumnsKeys$: Observable<string[]>
    visibleColumnsPossibleValues$: Observable<dataForm[]>
    visibleColumnsIsFullKeys$: Observable<boolean>
    searchInColumn$: Observable<htmlForm>
    searchInColumnType$: Observable<typeFormValue>
    activedEditField$: Observable<string[]>
    typeFormValue = typeFormValue;
    form: UntypedFormGroup;
    typeForm = typeFormValue
    subscriptions = new Subscription();
    translateInstant: any;
    searchKeyShowColumn$: Observable<string | undefined>
    favorites: string[];
    elementUpdatedValues$: Observable<KeyStringValue<any>>
    selectsElementId$: Observable<string[]>
    numberSelectsElementId$: Observable<number>
    public loading = {progress: 0, active: false};

    constructor(
        public dialogRef: MatDialogRef<BatchLightEditorComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            puntiLuce: PuntiLuceParse [],
            circuitiAppartenentiAlProgettoInput: CircuitiParse[],
            stradeAppartenentiAlProgettoInput: StradeParse[]
        },
        private dialogService: DialogPopUpInfoService,
        private alertService: AlertService,
        private translate: TranslateService,
        private localStorageService: LocalSotrageService,
        private render: Renderer2,
        private fb: UntypedFormBuilder
    ) {
        this.translateInstant = (key) => this.translate.instant(key);
        this.dataSource = new MatTableDataSource(this.data.puntiLuce);
        this.favorites = this.localStorageService.favoritesKeyPuntiLuce;
        const fieldPuntiLuce = Object.keys(ordinamentoEcampiTraduzioni.PuntiLuce)
            .filter(key => ordinamentoEcampiTraduzioni.PuntiLuce[key].showInForm == true)
            .sort((keyA, keyB) => {
                const sortingValueA = ordinamentoEcampiTraduzioni.PuntiLuce[keyA].sortingValue != null ? ordinamentoEcampiTraduzioni.PuntiLuce[keyA].sortingValue : 0;
                const sortingValueB = ordinamentoEcampiTraduzioni.PuntiLuce[keyB].sortingValue != null ? ordinamentoEcampiTraduzioni.PuntiLuce[keyB].sortingValue : 0;
                return sortingValueA - sortingValueB;
            });
        this.allColumnsHtml = fieldPuntiLuce.reduce((prev, key) => {
            const editable = ordinamentoEcampiTraduzioni.PuntiLuce[key].editableMassive != null ? ordinamentoEcampiTraduzioni.PuntiLuce[key].editableMassive : ordinamentoEcampiTraduzioni.PuntiLuce[key].editable != false;
            const obj = () => {
                const formControlName = key;
                const type = ordinamentoEcampiTraduzioni.PuntiLuce[key].type;
                let pointer;
                let typeForm = ordinamentoEcampiTraduzioni.PuntiLuce[key].typeForm;
                if (typeForm == null) {
                    if (type == 'BOOL') {
                        typeForm = typeFormValue.CHECKBOX
                    } else if (type == 'NUMBER') {
                        typeForm = typeFormValue.INPUT_NUMBER
                    } else if (type == 'INT') {
                        if (arrayIsSet(ordinamentoEcampiTraduzioni.PuntiLuce[key].possibleValues)) {
                            typeForm = typeFormValue.ELENCO_VINCOLATO
                        } else {
                            typeForm = typeFormValue.INPUT_INTEGER
                        }
                    } else if (type == 'TEXT') {
                        typeForm = typeFormValue.INPUT_TEXT
                    } else if (type == 'TARGA') {
                        typeForm = typeFormValue.INPUT_TEXT
                    } else if (type == 'GEO_POINT') {
                        typeForm = typeFormValue.GEO_POINT
                    } else if (type == 'DATE') {
                        typeForm = typeFormValue.DATE
                    } else if (type == 'ELENCO') {
                        typeForm = typeFormValue.ELENCO_APERTO
                    }
                }

                const traduction = 'dashboard_sidenav.PuntiLuce.' + key + '.title';
                let notTraduction;
                let possibleValues
                if (type === 'POINTER_PUNTOLUCE') {
                    if (arrayIsSet(this.data.puntiLuce)) {
                        possibleValues = this.data.puntiLuce.map(puntoLuce => {
                            const dataform: dataForm = {
                                valueForm: puntoLuce.objectId,
                                html: puntoLuce.targaCustom ?? puntoLuce.targa,
                                registerValue: puntoLuce
                            }
                            return dataform
                        })
                    }
                    pointer = {
                        getValue: (p: PuntiLuceParse) => p.puntoLucePadre ? p.puntoLucePadre.targaCustom ?? p.puntoLucePadre.targa : undefined,
                        getValueToSetInLightPoint: (id: string, possibleValues: dataForm[]) => {
                            const index = possibleValues.findIndex(pValue => pValue.valueForm === id)
                            if (index >= 0) {
                                return {puntoLucePadre: possibleValues[index].registerValue}
                            } else {
                                return {puntoLucePadre: {objectId: id}}
                            }
                        },
                        getValueForSave: (pl: PuntiLuceParse) => {
                            return {objectId: pl.objectId}
                        }
                    }
                } else if (type === 'POINTER_CIRCUITI') {
                    if (arrayIsSet(this.data.circuitiAppartenentiAlProgettoInput)) {
                        possibleValues = this.data.circuitiAppartenentiAlProgettoInput.map(circuito => {
                            const dataform: dataForm = {
                                valueForm: circuito.objectId,
                                html: circuito.numeroQuadro,
                                registerValue: circuito
                            }
                            return dataform
                        })
                    }
                    pointer = {
                        getValue: (p: PuntiLuceParse) => p.circuito ? p.circuito.numeroQuadro : undefined,
                        getValueToSetInLightPoint: (id: string, possibleValues: dataForm[]) => {
                            const index = possibleValues.findIndex(pValue => pValue.valueForm === id)
                            if (index >= 0) {
                                return {circuito: possibleValues[index].registerValue}
                            } else {
                                return {circuito: {objectId: id}}
                            }
                        },
                        getValueForSave: (circuito: CircuitiParse) => {
                            return {objectId: circuito.objectId}
                        }
                    }

                } else if (type === 'POINTER_STRADE') {
                    if (arrayIsSet(this.data.stradeAppartenentiAlProgettoInput)) {
                        possibleValues = this.data.stradeAppartenentiAlProgettoInput.map(strada => {
                            const dataform: dataForm = {
                                valueForm: strada.objectId,
                                html: strada.nome,
                                registerValue: strada
                            }
                            return dataform
                        })
                    }
                    pointer = {
                        getValue: (p: PuntiLuceParse) => p.strada ? p.strada.nome : undefined,
                        getValueToSetInLightPoint: (id: string, possibleValues: dataForm[]) => {
                            const index = possibleValues.findIndex(pValue => pValue.valueForm === id)
                            if (index >= 0) {
                                return {strada: possibleValues[index].registerValue}
                            } else {
                                return {strada: {objectId: id}}
                            }
                        },
                        getValueForSave: (strada: StradeParse) => {
                            return {objectId: strada.objectId}
                        }
                    }

                } else if (arrayIsSet(ordinamentoEcampiTraduzioni.PuntiLuce[key].possibleValues)) {
                    possibleValues = ordinamentoEcampiTraduzioni.PuntiLuce[key].possibleValues.map(
                        pValue => {
                            const dataform: dataForm = {
                                traduction: 'dashboard_sidenav.PuntiLuce.' + key + '.possibleValues.' + pValue,
                                valueForm: pValue,
                                html: pValue,
                                registerValue: pValue
                            }
                            return dataform
                        }
                    )
                }

                return {
                    formControlName,
                    traduction,
                    notTraduction,
                    pointer,
                    typeField: typeForm,
                    possibleValues,
                    editable
                }
            }
            const currentKey = obj();
            prev.push(currentKey);
            const varianteKey = ordinamentoEcampiTraduzioni.PuntiLuce[key].varianteKey
            if (varianteKey) {
                const variante = {...currentKey}
                variante['traductions'] = {keys: ['project', variante.traduction], separetor: ' - '}
                delete variante.traduction;
                variante.formControlName = varianteKey;
                if (variante.pointer) {
                    const pointer = {...variante.pointer}
                    delete variante.pointer;
                    if (pointer.getValue) {
                        pointer.getValue = (p: PuntiLuceParse) => p.varianteCircuito ? p.varianteCircuito.numeroQuadro : undefined
                    }
                    if (pointer.getValueToSetInLightPoint) {
                        pointer.getValueToSetInLightPoint = (id: string, possibleValues: dataForm[]) => {
                            const index = possibleValues.findIndex(pValue => pValue.valueForm === id)
                            if (index >= 0) {
                                return {varianteCircuito: possibleValues[index].registerValue}
                            } else {
                                return {varianteCircuito: {objectId: id}}
                            }
                        }
                    }
                    variante['pointer'] = pointer
                }
                prev.push(variante)
            }
            return prev;
        }, [] as htmlForm[])
            .sort(this.sortingColumn)
        this.form = this.fb.group({
            filter: fb.group({
                searchInColumn: undefined,
                keySearchInColumn: undefined,
                showColumnHeader: undefined,
                showColumns: undefined
            }),
            massiveValuesSet: fb.group({selectsElementId: undefined, updatedValues: undefined}),
            showColumnsMenu: fb.group({searchKey: undefined})
        });
        if (arrayIsSet(localStorageService.batchLightPointsEditor)) {
            this.visibleColumns = localStorageService.batchLightPointsEditor
                .reduce((prev, formControlName) => {
                    const col = this.allColumnsHtml.find(col => col.formControlName == formControlName)
                    if (col != null) {
                        prev.push(col);
                    }
                    return prev
                }, [])
        } else {
            this.visibleColumns = [...this.allColumnsHtml];
        }
        const sub = this.filterForm.get('showColumnHeader').valueChanges.subscribe(column => {
            this.findInBrowser();
        })
        this.subscriptions.add(sub)
        this.visibleColumns$ = this.filterForm.get('showColumns').valueChanges.pipe(
            startWith(undefined),
            map(() => {
                const visibleColumns = this.filterForm.get('showColumns').value
                if (arrayIsSet(visibleColumns)) {
                    visibleColumns.sort(this.sortingColumn)
                }
                return visibleColumns
            })
        )
        this.visibleColumnsKeys$ = this.visibleColumns$.pipe(
            map((columns) => {
                if (arrayIsSet(columns)) {
                    return ['objectId'].concat(columns.map(col => col.formControlName))
                } else {
                    return ['objectId'];
                }
            })
        )
        this.visibleColumnsPossibleValues$ = this.visibleColumns$.pipe(
            map((columns) => {
                if (arrayIsSet(columns)) {
                    return columns.map(col => {
                        let traduction;
                        let html;
                        if (col.traductions && arrayIsSet(col.traductions.keys)) {
                            html = col.traductions.keys.reduce((prev, key, index) => {
                                prev += this.translate.instant(key)
                                if (stringIsSet(col.traductions.separetor) && index < col.traductions.keys.length - 1) {
                                    prev += col.traductions.separetor;
                                }
                                return prev
                            }, '')
                        } else if (stringIsSet(col.traduction)) {
                            traduction = col.traduction
                        } else {
                            html = col.formControlName
                        }
                        const dataForm: dataForm = {
                            traduction,
                            valueForm: col.formControlName,
                            registerValue: col.formControlName,
                            html
                        }
                        return dataForm;
                    })
                } else {
                    return [];
                }
            })
        )

        this.keySearchInColumn$ = this.filterForm.get('keySearchInColumn').valueChanges.pipe(
            startWith(undefined),
            map(() => {
                return this.filterForm.get('keySearchInColumn').value
            })
        )

        this.searchInColumn$ = this.filterForm.get('searchInColumn').valueChanges.pipe(
            startWith(undefined),
            map(() => {
                return this.filterForm.get('searchInColumn').value
            })
        )
        this.searchInColumnType$ = this.searchInColumn$.pipe(
            map((col) => col != null ? col.typeField : undefined)
        )
        this.subscriptions.add(
            combineLatest([this.searchInColumn$, this.keySearchInColumn$])
                .subscribe((values) => {
                    if (arrayIsSet(values)) {
                        const keySearch = stringIsSet(values[1]) ? values[1] : ' ';
                        this.dataSource.filter = keySearch;
                    }
                })
        )
        this.searchKeyShowColumn$ = this.showColumnsMenuForm.get('searchKey').valueChanges.pipe(
            startWith(undefined),
            map(() => this.showColumnsMenuForm.get('searchKey').value)
        )

        this.elementUpdatedValues$ = this.massiveValuesSetForm.get('updatedValues').valueChanges.pipe(
            startWith(undefined),
            map(() => {
                const formValues = this.massiveValuesSetForm.get('updatedValues').value;
                if (formValues == null) {
                    return {}
                } else {
                    return formValues;
                }
            })
        )
        this.selectsElementId$ = this.massiveValuesSetForm.get('selectsElementId').valueChanges.pipe(
            startWith(undefined),
            map(() => {
                const value = this.massiveValuesSetForm.get('selectsElementId').value;
                if (arrayIsSet(value)) {
                    return value
                } else {
                    return this.data.puntiLuce.map(p => p.objectId)
                }
            })
        )
        this.numberSelectsElementId$ = this.selectsElementId$.pipe(
            map(values => values.length)
        )


        if (arrayIsSet(this.data.puntiLuce)) {
            this.dataSource = new MatTableDataSource<PuntiLuceParse>([])
        }
    }

    get filterForm(): AbstractControl {
        return this.form.get('filter')
    }

    get showColumnsMenuForm(): AbstractControl {
        return this.form.get('showColumnsMenu')
    }

    get massiveValuesSetForm(): AbstractControl {
        return this.form.get('massiveValuesSet')
    }

    set visibleColumns(values) {
        this.filterForm.get('showColumns').setValue(values);
    }

    get visibleColumns(): htmlForm[] {
        return this.filterForm.get('showColumns').value;
    }

    sortingColumn(colA: htmlForm, colB: htmlForm,): number {
        const nameA = colA.formControlName;
        const nameB = colB.formControlName;
        if (nameA.includes('variante')) {
            return 1;
        } else if (nameB.includes('variante')) {
            return -1;
        } else {
            return 0
        }
    }

    ngOnInit(): void {
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.dataSource.data = this.data.puntiLuce;
        }, 200)
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        const filterPredicate = (data: PuntiLuceParse, filterKey: string, keysToSearchValue: htmlForm[], updatedValues: KeyStringValue<any>, instant) => {
            if (stringIsSet(filterKey)) {
                const cleanedFilterKey = cleanToSpecialCharacterAndLowerCaseString(filterKey)
                return keysToSearchValue.some(col => {
                    const value = this.predicateGetValue(data, col, updatedValues, instant);
                    if (stringIsSet(value)) {
                        return cleanToSpecialCharacterAndLowerCaseString(value).includes(cleanedFilterKey);
                    } else {
                        return false;
                    }
                })
            } else {
                return true;
            }
        };
        this.dataSource.filterPredicate = (data, filter) => {
            const searchColumn = this.filterForm.get('searchInColumn').value;
            let keysToSearchValue;
            if (searchColumn != null) {
                keysToSearchValue = [searchColumn]
            } else {
                keysToSearchValue = this.allColumnsHtml
            }
            const updatedValues = this.massiveValuesSetForm.get('updatedValues').value;
            return filterPredicate(data, filter, keysToSearchValue, updatedValues, this.translateInstant)
        }

        this.dataSource.sortingDataAccessor = (data, col) => {
            switch (col) {
                case 'strada':
                    return data[col] != null ? data[col].name : ''
                case 'circuito':
                case 'varianteCircuito':
                    return data[col] != null ? data[col].numeroQuadro : ''
                case 'puntoLucePadre':
                    return data[col] != null ? data[col].targa ?? data[col].targaCustom : ''
                default:
                    return data[col];
            }
        }
    }

    updateValueSearch() {
        const keySearchInColumn = this.filterForm.get('keySearchInColumn').value
        this.filterForm.get('keySearchInColumn').setValue(keySearchInColumn);
    }


    save() {
        let error;
        this.loading.active = true;
        this.elementUpdatedValues$.pipe(
            take(1),
            filter(value => value != null && arrayIsSet(Object.keys(value))),
            map(values => {
                const keyFunction = this.allColumnsHtml.reduce((prev, field) => {
                    if (field.pointer && field.pointer.getValueForSave) {
                        prev[field.formControlName] = field.pointer.getValueForSave;
                    }
                    return prev;
                }, {})
                return Object.keys(values).reduce((prev, id) => {
                    const plJson = {}
                    plJson['objectId'] = id;
                    Object.keys(values[id]).forEach(key => {
                        if (keyFunction[key]) {
                            plJson[key] = keyFunction[key](values[id][key]);
                        } else {
                            plJson[key] = values[id][key];
                        }
                    })
                    prev.push(plJson)
                    return prev;
                }, [])
            }),
            switchMap(values => {
                return new PuntoLuce_v2(undefined).edits$(values, 1000)
            })
        )
            .subscribe(values => {
                    this.loading.active = !values.finished;
                    this.loading.progress = values.progress;
                    if (values.error != null) {
                        error = values.error;
                    }
                    if (values.finished) {
                        if (error == null) {
                            this.dialogRef.close()
                        } else {
                            this.alertService.error(error);
                        }
                    }
                },
                error => {
                    this.alertService.error(error)
                    this.loading.active = false;
                    this.loading.progress = 0;
                }, () => {
                    this.loading.active = false;
                    this.loading.progress = 0;
                })

    }

    get ordinamentoEcampiTraduzioni() {
        return ordinamentoEcampiTraduzioni;
    }


    urlIsSet(element, col) {
        if (col === 'fotoTipologia') {
            return isNotNullOrUndefined(element[col]) && isNotNullOrUndefined(element[col].foto) && isNotNullOrUndefined(element[col].foto.url())
        } else if (col === 'foto') {
            return isNotNullOrUndefined(element[col]) && isNotNullOrUndefined(element[col].url())
        }
    }

    getUrl(element, col) {
        if (col === 'fotoTipologia') {
            return element[col].foto.url();
        } else if (col === 'foto') {
            return element[col].url();
        }
    }

    disableSearchColumn: boolean = false;

    findInBrowser() {
        this.disableSearchColumn = true;
        const key = this.filterForm.get('showColumnHeader').value;
        if (stringIsSet(key)) {
            const columns = this.columnsTable.toArray();
            const index = columns.findIndex(column => (column as any).id == key);
            if (index >= 0) {
                const column = columns[index] as any;
                const opt = {
                    block: "center",
                    inline: "center"
                }
                // column._elementRef.nativeElement.scrollIntoView()
                this.render.selectRootElement(column._elementRef.nativeElement, true).scrollIntoView(opt);
                this.render.addClass(column._elementRef.nativeElement, 'select-text');
                setTimeout(() => {
                    this.render.addClass(column._elementRef.nativeElement, 'unset-color-text');
                    setTimeout(() => {
                        this.render.removeClass(column._elementRef.nativeElement, 'unset-color-text');
                        this.render.removeClass(column._elementRef.nativeElement, 'select-text');
                        this.disableSearchColumn = false;
                    }, 1000)
                }, 5000)
            } else {
                const text = this.translate.instant("columnNotPresent")
                this.alertService.warning(text)
                this.disableSearchColumn = false;

            }
        }

    }

    resetFilterForm(key: string, emitEvent: boolean = true) {
        this.filterForm.get(key).reset(null, {emitEvent: emitEvent})
    }


    predicateIsSet(col: htmlForm, visibleColumns: htmlForm[]): {
        iconName: string,
        cssClass?: string,
        colorIcon?: string
    } {
        if (!arrayIsSet(visibleColumns)) {
            return {iconName: 'visibility_off'};
        } else if (arrayIsSet(visibleColumns) && stringIsSet(col.formControlName) && visibleColumns.some(visibleCol => visibleCol.formControlName == col.formControlName)) {
            return {iconName: 'visibility', cssClass: 'color-select', colorIcon: 'primary'};
        } else {
            return {iconName: 'visibility_off'};
        }
    }

    setVisibleColumns(col: htmlForm) {
        const visibleCol = arrayIsSet(this.visibleColumns) ? [...this.visibleColumns] : [];
        const index = visibleCol.findIndex(visibleCol => visibleCol.formControlName === col.formControlName)
        if (index >= 0) {
            visibleCol.splice(index, 1);
        } else {
            visibleCol.push(col)
        }
        this.visibleColumns = visibleCol;
        this.localStorageService.batchLightPointsEditor = visibleCol.map(col => col.formControlName);
    }

    setShowAllVisibleColumns() {
        let visibleCol;
        visibleCol = [...this.allColumnsHtml];
        this.visibleColumns = visibleCol;
        this.localStorageService.batchLightPointsEditor = visibleCol.map(col => col.formControlName);
    }

    setFavoritesAllVisibleColumns() {
        let visibleCol;
        if (arrayIsSet(this.favorites)) {
            visibleCol = this.favorites.reduce((prev, fav) => {
                const colFind = this.allColumnsHtml.find(col => col.formControlName == fav)
                if (colFind != null) {
                    prev.push(colFind);
                }
                return prev;
            }, [])
        }
        this.visibleColumns = visibleCol;
        this.localStorageService.batchLightPointsEditor = visibleCol.map(col => col.formControlName);
    }

    setHiddenAllVisibleColumns() {
        let visibleCol;
        visibleCol = undefined
        this.visibleColumns = visibleCol;
        this.localStorageService.batchLightPointsEditor = undefined;
    }

    filterShowKeyPredicate(col: htmlForm, key: string, translate: (key: string) => string): boolean {
        if (stringIsSet(key) && key.length > 2) {
            let currentKey = '';
            if (col.traductions && arrayIsSet(col.traductions.keys)) {
                currentKey = col.traductions.keys.reduce((prev, key) => {
                    prev += translate(key);
                    return prev;
                }, '')
            } else if (stringIsSet(col.traduction)) {
                currentKey = translate(col.traduction);
            } else if (stringIsSet(col.notTraduction)) {
                currentKey = col.notTraduction;
            }
            return cleanToSpecialCharacterAndLowerCaseString(currentKey).includes(cleanToSpecialCharacterAndLowerCaseString(key))
        } else {
            return true
        }
    }

    setMassiveValuesSet(key: string, value: any) {
        if (this.massiveValuesSetForm.get(key) != null) {
            this.massiveValuesSetForm.get(key).setValue(value);
            this.massiveValuesSetForm.get(key).markAsDirty();
        }
    }

    unsetMassiveValuesSet(key: string, value: any) {
        if (this.massiveValuesSetForm.get(key) != null) {
            this.massiveValuesSetForm.get(key).reset(value);
            this.massiveValuesSetForm.get(key).markAsDirty();
        }
    }

    predicateIsUpdated(element: PuntiLuceParse, col: htmlForm, values: KeyStringValue<any>): boolean {
        if (values != null) {
            return values[element.objectId] && col.formControlName in values[element.objectId]
        } else {
            return false;
        }
    }

    predicateGetValue(element: PuntiLuceParse, col: htmlForm, values: KeyStringValue<any>, instant: (key: string) => string) {

        let elementUpdated;

        if (values != null && values[element.objectId] != null && col.formControlName in values[element.objectId]) {
            elementUpdated = values[element.objectId]
        } else {
            elementUpdated = element
        }

        let value = elementUpdated[col.formControlName];
        if (elementUpdated == null) {
            return '';
        } else if (col.pointer != null) {
            return col.pointer.getValue(elementUpdated)
        } else if (col.typeField === typeFormValue.CHECKBOX) {
            if (value == true) {
                return instant('bool.true');
            } else if (value == false) {
                return instant('bool.false');
            }
        } else if (col.typeField === typeFormValue.ELENCO_APERTO || col.typeField === typeFormValue.ELENCO_VINCOLATO) {
            if (arrayIsSet(col.possibleValues)) {
                const index = col.possibleValues.findIndex(pValue => pValue.valueForm === value)
                if (index >= 0) {
                    value = instant(col.possibleValues[index].traduction)
                }
            }
            return value;
        } else if (col.typeField === typeFormValue.GEO_POINT) {
            const latitude = value.latitude
            const longitude = value.longitude
            return value = latitude + ',' + longitude;
        } else {
            return value;
        }
    }


    attiveDisactiveEditField(checked: MatSlideToggleChange, col: htmlForm) {
        const currentValue = arrayIsSet(this.massiveValuesSetForm.get('selectsColumns').value) ? [...this.massiveValuesSetForm.get('selectsColumns').value] : [];
        const index = currentValue.findIndex(v => v === col.formControlName)
        if (checked.checked) {
            if (index < 0) {
                currentValue.push(col.formControlName)
            }
        } else {
            if (index >= 0) {
                currentValue.splice(index)
            }
        }
        this.massiveValuesSetForm.get('selectsColumns').setValue(currentValue)
    }

    editMassiveField(col: htmlForm) {
        let possibleValues;
        if (arrayIsSet(col.possibleValues)) {
            possibleValues = col.possibleValues.map(pValue => {
                const traduction = pValue.traduction ?? pValue.html
                return {
                    value: pValue.valueForm,
                    title: {traduction}
                }
            })
        }
        let traduction;
        let notTraduction;
        if (col.traductions && arrayIsSet(col.traductions.keys)) {
            traduction = col.traductions.keys.reduce((prev, key, currentIndex) => {
                prev += this.translateInstant(key);
                if (currentIndex < col.traductions.keys.length - 1) {
                    prev += col.traductions.separetor;
                }
                return prev;
            }, '')
        } else if (stringIsSet(col.traduction)) {
            traduction = col.traduction
        } else if (stringIsSet(col.notTraduction)) {
            notTraduction = col.notTraduction
        }
        let formFields: FormFieldPopUpType[]
        if (col.typeField === typeFormValue.CHECKBOX) {
            formFields = [
                {
                    title: {traduction: undefined},
                    type: typeFormValue.RADIO_BUTTON,
                    formControlName: col.formControlName,
                    possibleValues: [
                        {value: true, title: {traduction: 'bool.true'}},
                        {value: false, title: {traduction: 'bool.false'}},
                    ],
                    defaultValue: undefined,
                }
            ]
        } else {
            formFields = [
                {
                    title: {traduction: undefined},
                    type: col.typeField,
                    formControlName: col.formControlName,
                    possibleValues,
                    defaultValue: undefined
                }
            ]
        }
        const data: FormFieldPopUpData = {
            formFields,
            title: {traduction, notTraduction},
            disableClose: false,
            visualizeCheckAllButton: false,
            unsetField: true,
        }

        this.dialogService.openFormFields(data, undefined).pipe(
            filter(value => value != null && (value[col.formControlName] != null || value.unsetField != null)),
            map(value => {
                if (col.pointer && col.pointer.getValueToSetInLightPoint) {
                    return col.pointer.getValueToSetInLightPoint(value[col.formControlName], col.possibleValues)
                } else {
                    return value
                }
            })
        )
            .subscribe(value => {
                const ids = arrayIsSet(this.massiveValuesSetForm.get('selectsElementId').value) ? this.massiveValuesSetForm.get('selectsElementId').value : this.data.puntiLuce.map(p => p.objectId);
                const updateValues = this.massiveValuesSetForm.get('updatedValues').value != null ? {...this.massiveValuesSetForm.get('updatedValues').value} : {};
                if (arrayIsSet(ids)) {
                    ids.forEach(id => {
                        if (updateValues[id] == null) {
                            updateValues[id] = {}
                        }
                        if (value.unsetField) {
                            updateValues[id][col.formControlName] = null;
                        } else {
                            updateValues[id][col.formControlName] = value[col.formControlName];
                        }
                    })
                }
                this.massiveValuesSetForm.get('updatedValues').setValue(updateValues)
            })
    }


    unsetFilterKey(key: string) {
        this.filterForm.get(key).reset();
    }

    clickClose() {
        this.elementUpdatedValues$
            .pipe(
                switchMap(value => {
                    if (value != null && arrayIsSet(Object.keys(value))) {
                        const data: FormFieldPopUpData = {
                            formFields:[],
                            title: {traduction: 'changedNotSaved'},
                            disableClose: false,
                            visualizeCheckAllButton: false,
                            unsetField: false,
                        }
                        return this.dialogService.openFormFields(data, undefined).pipe(
                            map(v => {
                                return v != null
                            })
                        )
                    } else {
                        return of(true)
                    }
                })
            )
            .subscribe(v => {
                if (v) {
                    this.dialogRef.close(undefined);
                }
            })
    }

    clickRow(puntoLuce: PuntiLuceParse) {
        const option = this.clickSelectsElement.options
            .toArray()
            .find(a => a.value == puntoLuce.objectId)
        if (option != null) {
            option.toggle();
        }
        // if (this.clickSelectsElement.selectedOptions.isSelected(option) && this.clickSelectsElement.selectedOptions.isMultipleSelection()) {
        //     this.clickSelectsElement.selectedOptions.deselect(option);
        // } else {
        //     this.clickSelectsElement.selectedOptions.select(option);
        // }
    }
}

