import {Directive, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
    selector: '[appChangeOrientation]'
})
export class ChangeOrientationDirective {


    @Output()
    changeOrientation = new EventEmitter<{}>();

    constructor() {
    }


    @HostListener('window:orientationchange', ['$event'])
    onOrientationChange(event) {
        const width = window.innerWidth;
        const heigth = window.innerHeight;
        if (width < heigth) {
            this.changeOrientation.emit({
                orientation: 'landscape',
                currentWidth: heigth,
                currentHeigth: width
            })
        } else {
            this.changeOrientation.emit({
                orientation: 'portrait',
                currentWidth: heigth,
                currentHeigth: width
            })
        }
    }

}
