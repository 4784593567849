import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {AbstractControl, FormGroup, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {formatDate} from "@angular/common";
import {TranslateService} from "@ngx-translate/core";
import {typeElementScheduleMaintence} from "../../../providers/services/maintenance.service";
import {Observable, Subscription} from "rxjs";
import {CalendarioManutenzioniParse} from "../../../models/CalendarioManutenzioni.Parse";
import {CalendarioManutenzioniService} from "../../../providers/services/calendario-manutenzioni-service";
import {
    arrayIsSet,
    className,
    getArrayToRemveItem,
    getItemInArrayByKeyValue, getToday, isNotNullOrUndefined,
    valuePeriodOne
} from "../../../models/Models";
import {SchedaManutenzioneParse} from "../../../models/SchedaManutenzione.Parse";
import {ChipsFromArrayService} from "../../../providers/services/chips-from-array.service";
import {Priority} from "../../../../config/static-data";
import {dataForm} from "../../confirm-delete/select-or-create/select-or-create.component";
import {map, startWith} from "rxjs/operators";
import {LocalSotrageService} from "../../../providers/services/local-sotrage.service";

export type DetailBySchedeManutenzione = {
    numeroCircuiti: number,
    numeroPuntiLuce: number,
    schedaManutenzione: SchedaManutenzioneParse
};
export type ClusterDetailBySchedeManutenzione = {
    clusterId: string,
    schedeManutenzioneDetails: DetailBySchedeManutenzione[]
};

@Component({
    selector: 'app-maintenance-modality',
    templateUrl: './maintenance-modality.component.html',
    styleUrls: ['./maintenance-modality.component.scss']
})
export class MaintenanceModalityComponent implements OnInit, OnChanges, OnDestroy {
    public dateForm: UntypedFormGroup
    public fromDate$: Observable<Date>
    public toDate$: Observable<Date>
    public deltaDate$: Observable<number>
    currentMonthYear = {month: new Date().getMonth() + 1, year: new Date().getFullYear()}
    storedMonthYear = {month: this.currentMonthYear.month, year: this.currentMonthYear.year}
    typeElementScheduleMaintence = typeElementScheduleMaintence;
    subscriptions = new Subscription();

    @Output() changeValues = new EventEmitter();
    @Output() clickCalendario = new EventEmitter();
    @Input() disabled = false;
    @Input() disabledKey: { [k: string]: boolean } | undefined;
    @Input() defaultValue: any;
    @Input() calendarioManutenzioni: { date: number, calendariManutenzione: CalendarioManutenzioniParse[] };
    @Input() calendarioManutenzioniNonEffetuate: { date: number, calendariManutenzione: CalendarioManutenzioniParse[] };
    @Input() clusterVisualizzatoSullaMappa: any[] = [];

    selectedCard: any[] = [];
    selectedCardByDate: { objectId: string, selectedCard: any[] }[] = []

    public keyChipsPrioritySegnalazioni: dataForm[] = [
        {
            valueForm: Priority.BASSA,
            cssClass: 'background-green',
            traduction: 'segnalazioniParse.priorita.' + Priority.BASSA,
            html: undefined
        }, {
            valueForm: Priority.MEDIA,
            color: 'accent',
            traduction: 'segnalazioniParse.priorita.' + Priority.MEDIA,
            html: undefined
        }, {
            valueForm: Priority.ALTA,
            color: 'warn',
            traduction: 'segnalazioniParse.priorita.' + Priority.ALTA,
            html: undefined
        },
    ]

    public clusterManutenzioni: {
        objectId: string,
        date: Date,
        calendariManutenzione: CalendarioManutenzioniParse[]
    }[];
    public clusterManutenzioniNonCompilate: {
        objectId: string,
        date,
        calendariManutenzione: CalendarioManutenzioniParse[]
    }[];
    public allDetailsSchedeCluster: ClusterDetailBySchedeManutenzione[];

    constructor(private translate: TranslateService,
                private calendarioManutenzioniService: CalendarioManutenzioniService,
                private localStorage: LocalSotrageService,
    ) {
        const rangeDate = this.localStorage.rangeDateCalendario;

        this.dateForm = new UntypedFormGroup({
            typeSchedaMautenzione: new UntypedFormControl({
                value: null,
                disabled: false
            }),
            month: new UntypedFormControl({
                value: null,
                disabled: false
            }),
            rangeDate: new UntypedFormControl({
                value: {fromDate: rangeDate.fromDate, toDate: rangeDate.toDate, deltaDate: 7},
                disabled: false
            }),
            visualizedExpired: new UntypedFormControl({value: false, disabled: false}),
            visualizedSegnalzioni: new UntypedFormControl({value: true, disabled: false}),
            prioritySegnalzioni: new UntypedFormControl({value: null, disabled: false}),
        });

        const rangeDate$ = this.rangeDateForm.valueChanges.pipe(
            startWith(undefined),
            map(() => this.rangeDateForm.value)
        )
        this.subscriptions.add(
            rangeDate$.subscribe(range => {
                this.localStorage.rangeDateCalendario = range;
            })
        )
        this.fromDate$ = rangeDate$.pipe(
            map(rangeDate => {
                const fromDate = rangeDate != null && rangeDate.fromDate != null ? new Date(rangeDate.fromDate) : undefined;
                return fromDate;
            })
        )
        this.toDate$ = rangeDate$.pipe(
            map(rangeDate => {
                const toDate = rangeDate != null && rangeDate.toDate != null ? new Date(rangeDate.toDate) : undefined;
                return toDate;
            })
        )
        this.deltaDate$ = rangeDate$.pipe(
            map(rangeDate => {
                const delta = rangeDate != null && rangeDate.deltaDate != null ? rangeDate.deltaDate : 7;
                return delta;
            })
        )


    }

    ngOnInit(): void {
        let subscription = this.dateForm.valueChanges
            .subscribe((value) => {
                this.changeValues.emit(value);
            });
        this.subscriptions.add(subscription);

        if (arrayIsSet(this.clusterVisualizzatoSullaMappa)) {
            const date = this.calendarioManutenzioniService.getDateById(this.clusterVisualizzatoSullaMappa[0].objectId);
            if (isNotNullOrUndefined(date)) {
                this.currentMonthYear.month = date.getMonth() + 1;
                this.currentMonthYear.year = date.getFullYear();
            }
        }
        // this.dateForm.get('year').setValue(this.currentMonthYear.year, {emitEvent: false})
        // this.dateForm.get('month').setValue(this.currentMonthYear.month)
        this.dateForm.get('prioritySegnalzioni').setValue(this.defaultValue?.prioritySegnalzioni, {emitEvent: false});

        this.addToSelectedCardByDate(this.clusterVisualizzatoSullaMappa)

        subscription = this.dateForm.get('visualizedExpired').valueChanges
            .subscribe((value) => {
                if (!value && isNotNullOrUndefined(this.clusterManutenzioniNonCompilate)) {

                    const idsCluster = arrayIsSet(this.clusterManutenzioni) ? this.clusterManutenzioni.map(clust => clust.objectId) : []
                    const selectCardInCurrentCluster = this.selectedCard.filter(clusterSelect => {
                        return idsCluster.includes(clusterSelect.objectId)
                    })

                    // const idsClusterScadute = this.clusterManutenzioniNonCompilate.map(cluster => cluster.objectId);
                    this.selectedCard = selectCardInCurrentCluster;
                    this.clickCalendario.emit(this.selectedCard);
                }
            });
        this.subscriptions.add(subscription);
    }

    get rangeDateForm(): AbstractControl {
        return this.dateForm.get('rangeDate')
    }

    get idRangeDate() {
        // const month = this.dateForm.get('month').value
        // const year = this.dateForm.get('year').value
        const fromDate = this.rangeDateForm.value.fromDate;
        const toDate = this.rangeDateForm.value.toDate;
        if (fromDate && toDate) {
            return fromDate.getTime().toString(32) + toDate.getTime().toString(32)
        } else {
            return undefined
        }

    }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes.defaultValue) {
            if (isNotNullOrUndefined(this.defaultValue)) {
                Object.keys(this.defaultValue).forEach(key => {
                    if (isNotNullOrUndefined(this.dateForm.get(key))) {
                        this.dateForm.get(key).setValue(this.defaultValue[key])
                    }
                })
            }
            // this.dateForm.get('year').setValue(this.currentMonthYear.year, {emitEvent: false})
            // this.dateForm.get('month').setValue(this.currentMonthYear.month)
            this.selectedCard = [];
        }
        if (changes.disabled) {
            if (this.disabled) {
                this.dateForm.disable({emitEvent: false})
                this.selectedCard = []
                this.clickCalendario.emit(this.selectedCard);
            } else {
                this.selectedCard = []
                this.clickCalendario.emit(this.selectedCard);
                this.dateForm.enable()
            }
        }
        if (changes.disabledKey) {
            if (!this.disabled && this.disabledKey != null && this.dateForm != null) {
                const keysForm = Object.keys(this.dateForm.getRawValue())
                const keys = Object.keys(this.disabledKey).filter(key => keysForm.includes(key))
                keys.forEach(key => {
                    if (this.disabledKey[key]) {
                        this.dateForm.get(key).disable({emitEvent: false})
                    } else {
                        this.dateForm.get(key).enable({emitEvent: false})
                    }
                })
            }
        }

        if (isNotNullOrUndefined(changes.calendarioManutenzioni)) {
            const item = getItemInArrayByKeyValue(this.selectedCardByDate, this.idRangeDate, 'objectId')
            if (isNotNullOrUndefined(item)) {
                this.selectedCard = item.selectedCard;
            } else {
                this.selectedCard = [];
            }
            this.clickCalendario.emit(this.selectedCard);
            if (isNotNullOrUndefined(this.calendarioManutenzioni) && arrayIsSet(this.calendarioManutenzioni.calendariManutenzione)) {
                this.clusterManutenzioni = this.calendarioManutenzioniService.getClusterCalendariSchedeManutenzione(this.calendarioManutenzioni.calendariManutenzione)
            } else {
                this.clusterManutenzioni = undefined;
            }
            // console.log(this.clusterManutenzioni, this.idRangeDate)
            // if (arrayIsSet(this.clusterManutenzioniNonCompilate)) {
            //     const idsClusterScadute = this.clusterManutenzioniNonCompilate.map(cluster => cluster.objectId);
            //     const index = this.selectedCard.findIndex(cluster => idsClusterScadute.includes(cluster.objectId));
            //     if (index >= 0) {
            //         this.dateForm.get('visualizedExpired').setValue(true)
            //     }
            // }

            if (arrayIsSet(this.clusterManutenzioni)) {
                this.clusterManutenzioni = this.clusterManutenzioni.sort((a, b) => b.date.getTime() - a.date.getTime())
                if (!arrayIsSet(this.selectedCard)) {
                    this.clickCard(this.clusterManutenzioni[0])
                }
            }
        }

        if (isNotNullOrUndefined(changes.calendarioManutenzioniNonEffetuate)) {
            if (isNotNullOrUndefined(this.calendarioManutenzioniNonEffetuate) && arrayIsSet(this.calendarioManutenzioniNonEffetuate.calendariManutenzione)) {
                this.clusterManutenzioniNonCompilate = this.calendarioManutenzioniService.getClusterCalendariSchedeManutenzione(this.calendarioManutenzioniNonEffetuate.calendariManutenzione)
            } else {
                this.clusterManutenzioniNonCompilate = undefined;
            }
        }
        // if (changes.clusterVisualizzatoSullaMappa) {
        //     if (arrayIsSet(this.clusterVisualizzatoSullaMappa)) {
        //         this.selectedCard = this.clusterVisualizzatoSullaMappa;
        //         this.clickCalendario.emit(this.selectedCard);
        //     }
        // }
        // if (arrayIsSet(this.selectedCard)) {
        //     if (arrayIsSet(this.clusterManutenzioni)) {
        //         const idsCluster = this.clusterManutenzioni.map(cluster => cluster.objectId);
        //         const idsClusterScadute = (arrayIsSet(this.clusterManutenzioniNonCompilate)) ? this.clusterManutenzioniNonCompilate.map(cluster => cluster.objectId) : [];
        //         // const difference = this.selectedCard
        //         //     .filter(cluster => !idsCluster.includes(cluster.objectId))
        //         //     .filter(cluster => !idsClusterScadute.includes(cluster.objectId));
        //         // this.clusterManutenzioni = this.clusterManutenzioni.concat(difference)
        //     } else {
        //         const idsClusterScadute = (arrayIsSet(this.clusterManutenzioniNonCompilate)) ? this.clusterManutenzioniNonCompilate.map(cluster => cluster.objectId) : [];
        //         const difference = this.selectedCard
        //             .filter(cluster => !idsClusterScadute.includes(cluster.objectId));
        //         this.clusterManutenzioni = difference
        //     }
        //
        //
        // }
        const allDetailsSchedeCluster = this.getAllDatailsSchede(this.clusterManutenzioni);
        const allDetailsSchedeClusterNonCompilate = this.getAllDatailsSchede(this.clusterManutenzioniNonCompilate);
        this.allDetailsSchedeCluster = allDetailsSchedeCluster.concat(allDetailsSchedeClusterNonCompilate);
    }


    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    getAllDatailsSchede(clusterManutenzioni): ClusterDetailBySchedeManutenzione[] {

        const getObjDetailByCalendario = (calendario) => {
            const obj = {};
            obj['numeroPuntiLuce'] = 0;
            obj['numeroCircuiti'] = 0;
            obj['schedaManutenzione'] = calendario.schedaManutenzione;
            if (isNotNullOrUndefined(calendario.puntoLuce)) {
                obj['numeroPuntiLuce'] = 1;
            } else if (isNotNullOrUndefined(calendario.circuito)) {
                obj['numeroCircuiti'] = 1;
            }
            return obj
        }
        if (arrayIsSet(clusterManutenzioni)) {
            const allDetailsSchedeCluster = [];
            clusterManutenzioni.forEach((cluster) => {
                cluster.calendariManutenzione.forEach(calendario => {
                    const indexClusterDetail = allDetailsSchedeCluster.findIndex(clusterDetail => clusterDetail.clusterId == cluster.objectId);
                    if (indexClusterDetail >= 0) {
                        const index = allDetailsSchedeCluster[indexClusterDetail].schedeManutenzioneDetails.findIndex(clusterDetails => {
                            return clusterDetails.schedaManutenzione.objectId === calendario.schedaManutenzione.objectId
                        })
                        if (index >= 0) {
                            if (isNotNullOrUndefined(calendario.puntoLuce)) {
                                allDetailsSchedeCluster[indexClusterDetail].schedeManutenzioneDetails[index].numeroPuntiLuce += 1;
                            } else if (isNotNullOrUndefined(calendario.circuito)) {
                                allDetailsSchedeCluster[indexClusterDetail].schedeManutenzioneDetails[index].numeroCircuiti += 1;
                            }
                        } else {
                            allDetailsSchedeCluster[indexClusterDetail].schedeManutenzioneDetails.push(getObjDetailByCalendario(calendario))
                        }
                    } else {
                        allDetailsSchedeCluster.push({
                            clusterId: cluster.objectId,
                            schedeManutenzioneDetails: [getObjDetailByCalendario(calendario)]
                        });
                    }
                })
            })
            return allDetailsSchedeCluster
        } else {
            return [];
        }
    }


    getDetailCluster(cluster): DetailBySchedeManutenzione[] {
        const item = getItemInArrayByKeyValue(this.allDetailsSchedeCluster, cluster.objectId, 'clusterId')
        if (isNotNullOrUndefined(item)) {
            return item.schedeManutenzioneDetails;
        } else {
            return []
        }
    }

    changeMonthNext() {
        const month = this.dateForm.get('month').value - 1// è traslato di un piu uno il backend
        const year = this.dateForm.get('year').value
        const date = new Date(year, month + 1, 1, 0, 0, 0, 0);
        if (date.getFullYear() == year) {
            this.dateForm.get('month').setValue(date.getMonth() + 1);
        } else {
            this.dateForm.get('month').setValue(date.getMonth() + 1, {emitEvent: false});
            this.dateForm.get('year').setValue(date.getFullYear());
        }
    }

    changeMonthPrevius() {
        const month = this.dateForm.get('month').value - 1// è traslato di un piu uno il backend;
        const year = this.dateForm.get('year').value;
        const date = new Date(year, month - 1, 1, 0, 0, 0, 0);
        if (date.getFullYear() == year) {
            this.dateForm.get('month').setValue(date.getMonth() + 1);
        } else {
            this.dateForm.get('month').setValue(date.getMonth() + 1, {emitEvent: false});
            this.dateForm.get('year').setValue(date.getFullYear());
        }
    }

    changeYearNext() {
        const year = this.dateForm.get('year').value;
        this.dateForm.get('year').setValue(year + 1);
    }

    changeYearPrevius() {
        const year = this.dateForm.get('year').value;
        this.dateForm.get('year').setValue(year - 1);
    }


    get dateForHtml() {
        const month = this.dateForm.get('month').value
        const year = this.dateForm.get('year').value
        return this.getMonthYear(month - 1, year);
    }

    getMonthYear(month, year) {
        const locale = this.translate.currentLang;
        if (!isNotNullOrUndefined(month) || !isNotNullOrUndefined(year)) {
            return month + ' ' + year;
        } else {
            const monthNumber = (typeof month == "number") ? month : parseInt(month);
            const yearNumber = (typeof year == "number") ? year : parseInt(year);
            if (!isNaN(monthNumber) && !isNaN(yearNumber)) {
                const date = new Date(yearNumber, monthNumber, 1, 0, 0, 0, 0);
                const separator = (locale == 'en') ? ', ' : ' ';
                const stringDate = formatDate(date, 'MMMM' + separator + 'y', locale);
                return stringDate;
            } else {
                return month + ' ' + year;
            }
        }
    }

    get monthIsAfterToday() {
        const year = this.dateForm.get('year').value;
        const month = this.dateForm.get('month').value;
        const today = new Date();
        return month > today.getMonth() && year + 1 > today.getFullYear();
    }

    get yearIsAfterToday() {
        const year = this.dateForm.get('year').value;
        const today = new Date();
        return year + 1 > today.getFullYear();
    }

    chipColor(value) {
        if (this.chipIsSelected(value)) {
            return 'primary';
        } else {
            return '';
        }
    }

    chipIsSelected(value) {
        const values = this.dateForm.get('typeSchedaMautenzione').value
        if (!arrayIsSet(values)) {
            return false;
        } else {
            return values.includes(value);
        }
    }

    setTypeSchedaMautenzione(value) {
        if (!this.dateForm.disabled) {
            if (this.chipIsSelected(value)) {
                const values = getArrayToRemveItem(this.dateForm.get('typeSchedaMautenzione').value, value);
                this.dateForm.get('typeSchedaMautenzione').setValue(values)
            } else {
                const values = arrayIsSet(this.dateForm.get('typeSchedaMautenzione').value) ? this.dateForm.get('typeSchedaMautenzione').value : []
                values.push(value);
                this.dateForm.get('typeSchedaMautenzione').setValue([...values]);
            }
        }
    }


    addToSelectedCardByDate(selectedCard: any[]) {
        if (arrayIsSet(selectedCard)) {
            const index = this.selectedCardByDate.findIndex(select => select.objectId === this.idRangeDate);
            const idsCluster = arrayIsSet(this.clusterManutenzioni) ? this.clusterManutenzioni.map(clust => clust.objectId) : [];
            const selectCardInCurrentCluster = selectedCard.filter(clusterSelect => {
                return idsCluster.includes(clusterSelect.objectId);
            });
            if (index >= 0) {
                this.selectedCardByDate[index].selectedCard = selectCardInCurrentCluster;
            } else {
                this.selectedCardByDate.push({objectId: this.idRangeDate, selectedCard: selectCardInCurrentCluster});
            }
        }
    }

    clickCard(cluster) {
        if (this.dateForm.enabled) {
            if (this.isSelect(cluster)) {
                this.selectedCard = getArrayToRemveItem(this.selectedCard, cluster.objectId, 'objectId')
            } else {
                this.selectedCard.push(cluster)
            }
            // if (arrayIsSet(this.clusterManutenzioni) && cluster.date.getMonth() != this.dateForm.get('month').value - 1) {
            //     this.clusterManutenzioni = getArrayToRemveItem(this.clusterManutenzioni, cluster.objectId, 'objectId');
            // }

            this.addToSelectedCardByDate(this.selectedCard)
            this.clickCalendario.emit(this.selectedCard);
        }
    }

    isSelect(cluster) {
        const item = getItemInArrayByKeyValue(this.selectedCard, cluster.objectId, 'objectId');
        return isNotNullOrUndefined(item);
    }


    getBgClass(cluster, expired = false) {
        if (this.dateForm.disabled) {
            return ['card-disabled'];
        } else if (this.isSelect(cluster)) {
            if (expired) {
                return ['menu-select-card-warn'];
            }
            return ['menu-select-card'];
        } else {
            return ['background-default-not-select'];
        }
    }

    predicateFunction(cluster, types: string[]) {
        if (!isNotNullOrUndefined(cluster) || !arrayIsSet(types)) {
            return true;
        } else {
            let visualized = cluster.calendariManutenzione.findIndex(calendario => {
                return types.findIndex(type => {
                    let toConserv = false;
                    if (type == typeElementScheduleMaintence.PuntiLuce) {
                        toConserv = isNotNullOrUndefined(calendario.puntoLuce);
                    } else if (type == typeElementScheduleMaintence.Circuiti) {
                        toConserv = isNotNullOrUndefined(calendario.circuito);
                    }
                    return toConserv;
                }) >= 0
            }) >= 0
            return visualized;
        }
    }

    getTitleIcon(value: DetailBySchedeManutenzione): { title: string, icon: string, iconPath: string } {
        let title;
        let icon;
        let iconPath;
        const quantita = this.translate.instant('Q_ta');
        if (value.numeroCircuiti > 0) {
            title = this.translate.instant(className.circuiti) + '    ' + quantita + ' ' + value.numeroCircuiti;
            icon = 'Circuiti';
            iconPath = '/assets/marker/quadro.svg';
        } else if (value.numeroPuntiLuce > 0) {
            title = this.translate.instant(className.puntiLuce) + '    ' + quantita + ' ' + value.numeroPuntiLuce;
            icon = 'PuntiLuce';
            iconPath = '/assets/icon/dialogCompnent/lampione.svg';
        }
        return {title, icon, iconPath}
    }

    getDetail(value: DetailBySchedeManutenzione) {

        if (value.schedaManutenzione.periodo > valuePeriodOne) {
            return [
                {traduction: 'scheduleMaintenance.name', values: [value.schedaManutenzione.name], overflow: 'unset'},
                {
                    traduction: 'scheduleMaintenance.periodo',
                    values: [value.schedaManutenzione.periodo],
                    overflow: 'unset'
                },
                {traduction: 'scheduleMaintenance.note', values: [value.schedaManutenzione.note], overflow: 'auto'},
            ]
        } else {
            return [
                {traduction: 'scheduleMaintenance.name', values: [value.schedaManutenzione.name], overflow: 'unset'},
                {traduction: 'scheduleMaintenance.note', values: [value.schedaManutenzione.note], overflow: 'auto'},
            ]
        }

    }

    getLength(array): number {
        return Array.isArray(array) ? array.length : 0
    }

    clusterTracker(i: number, cluster) {
        const item = getItemInArrayByKeyValue(this.allDetailsSchedeCluster, cluster.objectId, 'clusterId');
        return item?.clusterId ?? i;
    }

    changeDateStart(event) {
        const start = event;
        if (isNotNullOrUndefined(start)) {
            const rangeDate = this.rangeDateForm.value != null ? {...this.rangeDateForm.value} : {}
            rangeDate['fromDate'] = start;
            if ('toDate' in rangeDate) {
                delete rangeDate.toDate;
            }
            this.rangeDateForm.setValue(rangeDate);
        }
    }


    changeDateEnd(event) {
        const end = event;
        if (isNotNullOrUndefined(end)) {
            const rangeDate = this.rangeDateForm.value != null ? {...this.rangeDateForm.value} : {}
            rangeDate['toDate'] = end;
            this.rangeDateForm.setValue(rangeDate);
        }
    }

    changeCalendar(event) {
        let start = event.start;
        let end = event.end;
        const deltDate = end.getTime() - start.getTime();
        const millisecInDay = 1 * 24 * 3600 * 1000;
        const rangeDate = this.rangeDateForm.value != null ? {...this.rangeDateForm.value} : {}
        rangeDate['fromDate'] = start;
        rangeDate['toDate'] = end;
        rangeDate['deltaDate'] = Math.round(deltDate / millisecInDay) - 1;
        this.rangeDateForm.setValue(rangeDate);
    }

}
