import * as Parse from 'parse';
import {allPropertyClass} from "./Models";

export class CalendarioManutenzioniParse extends Parse.Object {

    public static CLASS_NAME = 'CalendarioManutenzioni';

    constructor() {
        super(CalendarioManutenzioniParse.CLASS_NAME);
    }


    public set objectId(value) {
        this.id = value;
    }

    public get objectId(): string {
        return this.id;
    }

    public set ACL(value) {
        super.set('ACL', value);
    }

    public get ACL() {
        return super.get('ACL');
    }

    public set createdAt(value) {
        super.set('createdAt', value);
    }

    public get createdAt(): Date {
        return super.get('createdAt');
    }

    public set updatedAt(value) {
        super.set('updatedAt', value);
    }

    public get updatedAt(): Date {
        return super.get('updatedAt');
    }

    public set maintenanceDate(value) {
        super.set('maintenanceDate', value);
    }

    public get maintenanceDate(): Date {
        return super.get('maintenanceDate');
    }

    public set circuito(value) {
        super.set('circuito', value);
    }

    public get circuito() {
        return super.get('circuito');
    }


    public set progetto(value) {
        super.set('progetto', value);
    }

    public get progetto() {
        return super.get('progetto');
    }

    public set puntoLuce(value) {
        super.set('puntoLuce', value);
    }

    public get puntoLuce() {
        return super.get('puntoLuce');
    }

    public set location(value) {
        super.set('location', value);
    }

    public get location(): Parse.GeoPoint {
        return super.get('location');
    }

    public set schedaManutenzione(value) {
        super.set('schedaManutenzione', value);
    }

    public get schedaManutenzione(): any {
        return super.get('schedaManutenzione');
    }

    public get query(): Parse.Query<CalendarioManutenzioniParse> {
        let query = new Parse.Query(CalendarioManutenzioniParse);
        return query;
    }

    public allProperty() {
        return allPropertyClass(CalendarioManutenzioniParse)
    }

    public allPropertyOnParse() {
        const notInParse = ['constructor', 'query'];
        return this.allProperty().filter(key => !notInParse.includes(key))
    }
}

Parse.Object.registerSubclass(CalendarioManutenzioniParse.CLASS_NAME, CalendarioManutenzioniParse);
