export class MonthYear{
  month:number
  year: number

  constructor(month: number, year: number) {
    this.month = month;
    this.year = year;
  }
  static equals(my1:MonthYear,my2:MonthYear){
    return my1.year===my2.year && my1.month===my2.month

  }

  equalsTo(my2:MonthYear){
    return this.year===my2.year && this.month===my2.month
}

  toString(){
    return "Month year è " + this.month+ "/"+this.year
  }
}
