import {Pipe, PipeTransform} from '@angular/core';
import {ChipsFromArrayService} from "../services/chips-from-array.service";
import {isNotNullOrUndefined} from "../../models/Models";

@Pipe({
    name: 'filterList'
})
export class FilterListPipe implements PipeTransform {
    constructor(
        private chipsFromArrayService: ChipsFromArrayService,
    ) {
    }

    transform(values: any[], key, valueFitler: string, includesInArray: { key, value: string[] } = undefined): any[] {
        if (Array.isArray(values) && isNotNullOrUndefined(key) && isNotNullOrUndefined(valueFitler)) {
            const filteredValues= values.filter(value => {
                let isIncludes = true;
                let stringIsIncludes = true;
                if (isNotNullOrUndefined(includesInArray) && Array.isArray(includesInArray.value) && includesInArray.value.length > 0) {
                    isIncludes = includesInArray.value.includes(value[includesInArray.key])
                }
                if (isIncludes && isNotNullOrUndefined(value[key]) && typeof value[key] === 'string') {
                    stringIsIncludes = this.chipsFromArrayService.isIncludes(value[key], valueFitler)
                }
                return isIncludes && stringIsIncludes;
            });
            return filteredValues
        } else {
            return values
        }
    }

}
