<mat-card class="dimension-box huna-material-class"
          [ngClass]="[selectDisable]">
    <!--    <span [ngClass]="[badgeSize,badgeColor]"-->
    <!--          class="badge badge-content">{{size}}</span>-->
    <div class="flex-center-Between w100" style="height: 100%">
        <div class="button-container background-other"
             [ngClass]="['background-' + typeFile.type]"
             (click)="singleCardClick()" (dblclick)="dbCardClick()">
            <ng-container *ngIf="!loading;else loader">
                <ng-container *ngIf="typeFile.type!=='folder';else folder">
                    <div class="text-item">
                                        <span class="color-other"
                                              [ngClass]="['color-' + typeFile.type]"
                                              style="font-weight: bold;">
                                            {{typeFile.symbol}}
                                        </span>
                    </div>
                </ng-container>
                <ng-template #folder>
                    <mat-icon class="icon-xl">folder</mat-icon>
                </ng-template>
            </ng-container>
            <ng-template #loader>
                <mat-spinner [diameter]="20"></mat-spinner>
            </ng-template>
        </div>
        <div style="flex:1;"
             class="text-container-center-card"
             (click)="singleCardClick()" (dblclick)="dbCardClick()">
            <div style="display: flex;justify-content: flex-start;align-items: center" *ngIf="parentFolderName">
                <mat-icon>folder_open</mat-icon>
                <span>{{parentFolderName}}</span>
            </div>
            <h4 class="text-h4">{{name}}</h4>
            <div style="text-align: start" *ngIf="updatedAt">
                <h6 style="padding: 0;margin: 0">
                    <!--                    {{'fileManager.updatedAt'|translate}} {{getTraductionFromDate(updatedAt)|translate}}-->
                    <span style="font-size: small;font-weight: normal">{{updatedAt|customDate:'mediumDate'|async}}</span>
                </h6>
            </div>
        </div>
        <div class="option-button-container">
            <button mat-icon-button [matMenuTriggerFor]="menu" [disabled]="disableButton">
                <mat-icon>more_vert</mat-icon>
            </button>
        </div>
    </div>
</mat-card>
<mat-menu #menu="matMenu" xPosition="before">
    <!--    <button mat-menu-item [matMenuTriggerFor]="photometryList">{{'fotometrie.add'|translate}}</button>-->
    <button mat-menu-item (click)="rename()" *ngIf="visualizedRenameButton">
        <mat-icon color="accent">edit</mat-icon>
        {{'fileManager.rename'|translate}}
    </button>
    <button mat-menu-item (click)="preview()"
            *ngIf="!notPreviewType.includes(typeFile.type) && visualizedPreviewButton">
        <mat-icon color="accent">remove_red_eye</mat-icon>
        {{'fileManager.preview'|translate}}
    </button>
    <button mat-menu-item (click)="downolad()"
            *ngIf="!notDownloadType.includes(typeFile.type) && visualizedDownloadButton">
        <mat-icon color="accent">get_app</mat-icon>
        {{'fileManager.download'|translate}}
    </button>
    <button mat-menu-item (click)="goToMap()" *ngIf="visualizedMapdButton">
        <mat-icon color="primary">map</mat-icon>
        {{'fileManager.map'|translate}}
    </button>
    <button mat-menu-item (click)="select()" *ngIf="visualizedSelectButton">
        <mat-icon color="primary">
            checklist_rtl
        </mat-icon>
        {{'fileManager.select'|translate}}
    </button>
    <ng-container *ngIf="visualizedMoveInMenu">
        <button mat-menu-item (click)="move()" *ngIf="!notMoveType.includes(typeFile.type)">
            <mat-icon color="primary">forward</mat-icon>
            {{'fileManager.move'|translate}}
        </button>
    </ng-container>
    <button mat-menu-item (click)="remove()" *ngIf="visualizedRemoveButton">
        <mat-icon color="warn">clear</mat-icon>
        {{'fileManager.remove'|translate}}
    </button>

</mat-menu>