<div mat-dialog-content>
    <mat-card>
        <mat-card-title>{{'info_create_report' | translate}}</mat-card-title>
        <mat-card-content>
            <div class="spacer">
                {{'info_create_report_content' | translate}}
            </div>
        </mat-card-content>
    </mat-card>
</div>
