import {Injectable} from '@angular/core';
import {BehaviorSubject, EMPTY, forkJoin, Observable, of, Subscription, throwError} from "rxjs";
import {CaricoEsogenoParse} from "../../models/CaricoEsogeno.Parse";
import {ProjectService} from "./project.service";
import {fromPromise} from "rxjs/internal-compatibility";
import {AlertService} from "./alert.service";
import {catchError, expand, map, switchMap, take} from "rxjs/operators";
import {
    arrayIsSet, isDate, isValidDate,
    masterFoldersFileManager,
    objectForkJoin$,
    PaginationParserequestType, paramsApiParse_v2,
    stringIsSet
} from "../../models/Models";
import {FormFieldPopUpType} from "../../components/pop-up-info/form-field/form-field.component";
import {configurationPropertyCaricoEsogeno} from "../../models/configurationProperty/caricoEsogeno";
import {FileManagerService} from "./file-manager.service";
import {DocumentsFolderParse} from "../../models/DocumentsFolder.Parse";
import {
    castValueByConfigurationElement,
    getElementByKeyName,
    getTypeDataBase,
    getValidator, typeDatabase,
    typeFormValue,
} from "../../models/configurationProperty/configurationPropertyUtils";
import {DialogPopUpInfoService, selectTypePopUp} from "./dialog-pop-up-info.service";
import {TranslateService} from "@ngx-translate/core";
import * as Parse from 'parse';
import {formatDate} from "@angular/common";

@Injectable({
    providedIn: 'root'
})
export class CaricoEsogenoService {
    private carichiEsogeniEmit = new BehaviorSubject<CaricoEsogenoParse[]>([])
    public carichiEsogeni$ = this.carichiEsogeniEmit.asObservable();
    private subscription: Subscription;
    filterFields: { formControlName: string, typeForm: typeFormValue, traduction }[];
    objFunctionGetValue: { [k: string]: (v: any) => any }

    constructor(private projectService: ProjectService,
                private alertService: AlertService,
                private fileMangerService: FileManagerService,
                private dialogInfo: DialogPopUpInfoService,
                private ts: TranslateService
    ) {
        this.filterFields = configurationPropertyCaricoEsogeno
            .filter(field => field.filtrable && field.pointer == null && [typeFormValue.DATE, typeFormValue.INPUT_TEXT, typeFormValue.INPUT_NUMBER, typeFormValue.INPUT_INTEGER, typeFormValue.ELENCO_APERTO].includes(field.typeForm))
            .map(filed => {
                return {formControlName: filed.keyName, typeForm: filed.typeForm, traduction: filed.traductionKey}
            })
        this.objFunctionGetValue = configurationPropertyCaricoEsogeno.reduce(
            (prev, element) => {
                let returnValueFunction = (value) => value;
                if (element.typeDatabase == typeDatabase.POINTER) {
                    returnValueFunction = (value) => {
                        if (value != null) {
                            if (element.pointer.getValueExport != null) {
                                return element.pointer.getValueExport(value)
                            } else {
                                return element.pointer.getValue(value)
                            }
                        } else {
                            return value;
                        }
                    };
                } else if (element.typeDatabase == typeDatabase.DATE) {
                    returnValueFunction = (value) => {
                        const date = new Date(value);
                        if (isValidDate(date)) {
                            return formatDate(date, 'shortDate', this.ts.currentLang)
                        } else {
                            return value;
                        }
                    };
                } else if (element.typeDatabase == typeDatabase.BOOLEAN) {
                    returnValueFunction = (value) => {
                        if (value != null) {
                            return this.ts.instant('bool.' + value)
                        } else {
                            return '';
                        }
                    }
                } else if (element.typeDatabase == typeDatabase.FILE) {
                    returnValueFunction = (value: Parse.File | undefined) => {
                        if (value != null) {
                            return value.name();
                        } else {
                            return '';
                        }
                    }
                }
                prev[element.keyName] = returnValueFunction;
                return prev
            }, {}
        )
    }

    get project() {
        return this.projectService.currentProject;
    }

    initCarichiEsogeni() {
        this.destroyCarichiEsogeni();
        this.updateCarichiEsogeni();
        this.subscription = this.projectService.projectChange().subscribe(projectId => {
            this.updateCarichiEsogeni();
        })
    }

    destroyCarichiEsogeni() {
        if (this.subscription != null) {
            this.subscription.unsubscribe();
        }
        this.subscription = undefined;
    }

    getCarichiEsogeniInProject(): Observable<CaricoEsogenoParse[]> {
        const params = paramsApiParse_v2({progettoId: this.project.objectId});
        return fromPromise(Parse.Cloud.run('getAllCaricoEsogeno', params)).pipe(
            map(carichi => {
                if (arrayIsSet(carichi)) {
                    return carichi.map(c => {
                        const calendarioParse = new CaricoEsogenoParse()
                        calendarioParse.objectId = c.id;
                        return calendarioParse;
                    })
                } else {
                    return undefined;
                }
            })
        );
        // const query = new CaricoEsogenoParse().query;
        // query.equalTo('progetto', );
        // query.include('circuito');
        // query.include('puntoLuce');
        // query.include('arredoUrbano');
        // return fromPromise(query.find());
    }

    updateCarichiEsogeni() {
        this.getCarichiEsogeniInProject().subscribe(carichi => {
            this.carichiEsogeniEmit.next(carichi);
        }, error => {
            this.carichiEsogeniEmit.next([])
            this.alertService.error(error);
        })
    }

    getMasterFolder$(): Observable<DocumentsFolderParse> {
        const query = new DocumentsFolderParse().query;
        query.equalTo('progetto', this.project);
        query.equalTo('name', masterFoldersFileManager.caricoEsogeno);
        return fromPromise(query.first()).pipe(
            switchMap(masterFoder => {
                if (masterFoder == null) {
                    return this.fileMangerService.createNewFolder(masterFoldersFileManager.caricoEsogeno, undefined)
                } else {
                    return of(masterFoder);
                }
            })
        );
    }

    createCaricoEsogeno(values, caricoEsogenoToSave: CaricoEsogenoParse = undefined): Observable<CaricoEsogenoParse> {
        let caricoEsogeno: CaricoEsogenoParse;
        if (caricoEsogenoToSave != null && caricoEsogenoToSave.objectId != null) {
            caricoEsogeno = caricoEsogenoToSave;
        } else {
            caricoEsogeno = new CaricoEsogenoParse();
            caricoEsogeno.progetto = this.project;
        }

        const photos$ = Object.keys(values)
            .filter(key => values[key] && values[key].file != null)
            .reduce((previus, currentKey) => {
                const fileUploadControl = values[currentKey].fileUploadControl
                const nameFile = values[currentKey].name
                const extension = this.fileMangerService.getExtensionByName(nameFile)
                previus[currentKey] = this.getMasterFolder$().pipe(
                    switchMap((masterFolder) => this.fileMangerService.createNewFileAndConnectToElement(nameFile, extension, fileUploadControl, masterFolder, undefined, undefined, undefined))
                )
                return previus;
            }, {})
        let values$: Observable<any>
        if (arrayIsSet(Object.keys(photos$))) {
            values$ = objectForkJoin$(photos$).pipe(
                map(photos => {
                    Object.keys(photos).forEach(key =>
                        values[key] = photos[key]
                    )
                    return values
                })
            );
        } else {
            values$ = of(Object.keys(values)
                .filter(key => values[key] == null || values[key] && values[key].file == null)
                .reduce((previus, currentKey) => {
                    previus[currentKey] = values[currentKey]
                    return previus;
                }, {})
            );
        }
        return values$.pipe(
            switchMap((valuesUpdated) => {
                let filesToRemove = [];
                Object.keys(valuesUpdated).forEach(key => {
                    if (values[key] != null) {
                        caricoEsogeno[key] = valuesUpdated[key]
                    } else {
                        if (caricoEsogeno[key] != null && caricoEsogeno[key].className != null) {
                            const fileToRemove = caricoEsogeno[key]
                            if (fileToRemove != null) {
                                filesToRemove.push(fileToRemove.destroy());
                            }
                        }
                        caricoEsogeno.unset(key);
                    }
                })
                if (arrayIsSet(filesToRemove)) {
                    return forkJoin(filesToRemove)
                        .pipe(
                            catchError(() => of([])),
                            switchMap(() => fromPromise(caricoEsogeno.save()))
                        )
                } else {
                    return fromPromise(caricoEsogeno.save());
                }
            }),

            switchMap((savedCaricoEsogeno) => this.currentCarichiEsogeni$.pipe(
                map(carichiEsogeni => {
                    return {savedCaricoEsogeno, carichiEsogeni}
                })
            )),
            map((values) => {
                const carichiEmit = arrayIsSet(values.carichiEsogeni) ? [...values.carichiEsogeni] : [];
                const index = carichiEmit.findIndex(caricoEs => caricoEs.objectId === values.savedCaricoEsogeno.objectId)
                if (index < 0) {
                    carichiEmit.push(values.savedCaricoEsogeno);
                } else {
                    carichiEmit[index] = values.savedCaricoEsogeno;
                }
                this.carichiEsogeniEmit.next(carichiEmit);
                return values.savedCaricoEsogeno
            })
        )
    }

    deleteCaricoEsogeno(caricoEsogeno: CaricoEsogenoParse) {
        return this.deletesCarichiEsogeni([caricoEsogeno]).pipe(
            switchMap(carichi => {
                if (arrayIsSet(carichi.carichiEsogeniNotDestroyed)) {
                    return throwError(carichi.carichiEsogeniNotDestroyed[0].error)
                } else {
                    return of(caricoEsogeno);
                }

            })
        )
    }


    get currentCarichiEsogeni$(): Observable<CaricoEsogenoParse[]> {
        return this.carichiEsogeni$.pipe(take(1))
    }

    getFormFiledsForDialog(currentValue: any = undefined): FormFieldPopUpType[] {
        return configurationPropertyCaricoEsogeno
            .filter(element => element.showInForm)
            .map(element => {
                let possibleValues;
                if (arrayIsSet(element.possibleValues)) {
                    possibleValues = element.possibleValues.map(value => {
                        return {value: value.dataBaseValue, title: {traduction: value.traduction}}
                    })
                }
                let defaultValue;
                if (currentValue != null && currentValue[element.keyName] != null) {
                    if (element.pointer && element.pointer.getValue != null) {
                        defaultValue = element.pointer.getValue(currentValue[element.keyName]);
                    } else {
                        defaultValue = currentValue[element.keyName];
                    }
                }
                const validator = getValidator(element)
                const obj: FormFieldPopUpType = {
                    type: element.typeForm,
                    title: {traduction: element.traductionKey},
                    formControlName: element.keyName,
                    validator: arrayIsSet(validator) ? validator : undefined,
                    possibleValues,
                    defaultValue
                }
                return obj
            })
    }

    formUpdateCaricoEsogeno$(caricoEsogeno: CaricoEsogenoParse): Observable<any> {
        return this.dialogInfo.openFormFields({
            formFields: this.getFormFiledsForDialog(caricoEsogeno),
            disableClose: false,
            title: {traduction: 'CaricoEsogeno'},
            visualizeCheckAllButton: false,
            visualizedSearch: true,
            onlyFormFieldDirty: true
        }, "default")
    }

    openSelectCaricoEsogeno$(carichiEsogeniEsterni: CaricoEsogenoParse[]): Observable<{ add: CaricoEsogenoParse[] }> {
        return this.dialogInfo.openSelectCaricoEsogeno(carichiEsogeniEsterni, selectTypePopUp.radio)
    }


    importCaricoEsogenoByRawFile$(rawFile: { data: any[] }, indexCategories: {
        keyTrad: string | undefined,
        indexCol: number
    }[]): Observable<{
        pagination: PaginationParserequestType<{ saved?: CaricoEsogenoParse[], error?: any[] }>,
        route: string
    }> {
        const fetchPage = (page = 0) => {
            let isLast = page + 1 >= rawFile.data.length;
            const progress = Math.ceil(page / rawFile.data.length * 100)
            const rawRow = rawFile.data[page];
            const values = {};
            indexCategories.forEach(key => {
                const element = getElementByKeyName(key.keyTrad, configurationPropertyCaricoEsogeno)
                if (key.keyTrad == 'objectId') {
                    if (stringIsSet(rawRow[key.indexCol])) {
                        values[key.keyTrad] = rawRow[key.indexCol];
                    }
                } else if (element != null) {
                    const castValue = castValueByConfigurationElement(rawRow[key.indexCol], element, undefined, this.ts.currentLang)
                    if (castValue != null) {
                        values[key.keyTrad] = castValue;
                    }
                }

            });
            const options = {
                projectId: this.project.objectId,
                carichiEsogeni: [values]
            }
            const value$ = fromPromise(
                Parse.Cloud.run('importCaricoEsogenoFromJSON', options)
            );
            return value$.pipe(
                map((items) => {
                    return {
                        items: [items],
                        progress: progress,
                        nextPage: isLast ? undefined : (page += 1),
                        finished: isLast,
                        error: null
                    };
                }),
                catchError(error => {
                    values['errorMessage'] = error;
                    return of({
                        items: [{error: [values]}],
                        progress: progress,
                        nextPage: isLast ? undefined : (page += 1),
                        finished: isLast,
                        error: error
                    });
                }),
            );
        }
        return fetchPage(0).pipe(
            expand(({nextPage}) => nextPage ? fetchPage(nextPage) : EMPTY),
        ).pipe(
            map(v => {
                return {pagination: v, route: '/carichi-esogeni'}
            })
        )


        // return of(
        //     {pagination: {progress: 100, finished: true, items: [], nextPage: undefined}, route: ''}
        // );
    }

    // getTraductionBeyKeyDataBase(valueDatabase: string, key: string): string {
    //     // getElementByKeyName()
    // }

    getValueForExport(key: string, value: any) {
        if (this.objFunctionGetValue[key] != null) {
            return this.objFunctionGetValue[key](value[key])
        } else if (key.includes('error')) {
            const translate = this.ts.instant('parseError.message.' + value[key])
            if (translate.includes('parseError.message.')) {
                return value[key];
            } else {
                return translate;
            }
        } else {
            return value[key];
        }
    }

    getErrorNameFile(fileName: string) {
        let name = this.ts.instant('errorImport');
        if (stringIsSet(fileName)) {
            name += ' ' + fileName;
        }
        name += ' ' + this.project.name;
        return name;
    }

    getHeaderCaricoEsogenoDownload() {
        return configurationPropertyCaricoEsogeno
            .filter(el => el.showInForm)
            .sort((a, b) => {
                const sortingValueA = a.sortingValue != null ? a.sortingValue : 0;
                const sortingValueB = b.sortingValue != null ? b.sortingValue : 0;
                return sortingValueB - sortingValueA;
            })
            .map(el => el.keyName)

    }

    deletesCarichiEsogeni(carichiEsogeni: CaricoEsogenoParse[]): Observable<{
        carichiEsogeniDestroyed: { objectId: string }[],
        carichiEsogeniNotDestroyed: { objectId: string, error: any }[]
    }> {
        const params = paramsApiParse_v2({caricoEsogenoIds: carichiEsogeni.map(c => c.objectId)});
        return fromPromise(Parse.Cloud.run('deleteCaricoEsogeno', params)).pipe(
            map(carichiDestroyed => {
                if (arrayIsSet(carichiDestroyed.carichiEsogeniDestroyed) && arrayIsSet(this.carichiEsogeniEmit.value)) {
                    let currentCarichiNelProgetto = this.carichiEsogeniEmit.value;
                    const idsDestroyed = carichiDestroyed.carichiEsogeniDestroyed.map(c => c.objectId);
                    currentCarichiNelProgetto = currentCarichiNelProgetto.filter(carico => !idsDestroyed.includes(carico.objectId))
                    this.carichiEsogeniEmit.next(currentCarichiNelProgetto);
                }
                return carichiDestroyed;
            })
        );
    }


    getHeaderImport() {
        const confProperty = configurationPropertyCaricoEsogeno
            .filter(el => el.headerImport != false)
        confProperty.push({keyName: 'objectId'} as any)
        return confProperty
    }
}
