import {Injectable} from '@angular/core';
import {ProjectService} from "./project.service";
import {EMPTY, Observable, of} from "rxjs";
import * as Parse from 'parse';
import {PuntiLuceParse} from "../../models/PuntiLuce.Parse";
import {fromPromise} from "rxjs/internal-compatibility";
import {SegnaliTlcNodoParse} from "../../models/SegnaliTlcNodo.Parse";
import {expand, map, toArray} from "rxjs/operators";
import {KeyStringValue, paramsApiParse_v2} from "../../models/Models";

@Injectable({
    providedIn: 'root'
})
export class SegnaliTlcNodoService {

    constructor(private projectService: ProjectService) {
    }

    // todo ricorsivo piu di mille non vengono scaricati
    // provare ad utilizzare l operatore retrywhen
    getLampadaFunzionante_v2(puntiLuce: PuntiLuceParse[]): Observable<KeyStringValue<{
        segnale: SegnaliTlcNodoParse
    }>> {
        const numberElementForCycle = 1000;
        const lengthArray = puntiLuce.length;

        function fetchPage(page = 0) {
            const lastElement = ((page + 1) * numberElementForCycle < lengthArray) ? (page + 1) * numberElementForCycle : lengthArray;
            const paginationArray = puntiLuce.slice(page * numberElementForCycle, lastElement);
            const params = paramsApiParse_v2({
                puntiLuceIds: paginationArray
                    .map(p => p.objectId)
            })

            const getMapSegnaliTlcNodo$ = fromPromise(
                Parse.Cloud.run('getMapSegnaliTlcNodo', params)
            )
            // query.equalTo('progetto', project);

            return getMapSegnaliTlcNodo$.pipe(
                map((item) => {
                    return {
                        items: item,
                        nextPage: (page + 1) * numberElementForCycle >= lengthArray ? undefined : (page += 1),
                    };
                }),
            );
        }


        return fetchPage().pipe(
            expand(({nextPage}) => nextPage ? fetchPage(nextPage) : EMPTY),
            map(values => values.items)
        )
    }

    getLampadaFunzionante(puntiLuce: PuntiLuceParse[]): Observable<SegnaliTlcNodoParse[]> {
        // const params = paramsApiParse_v2({puntiLuceIds: puntiLuce.map(p => p.objectId)})
        const query = new Parse.Query(SegnaliTlcNodoParse);
        query.limit(1000);
        const numberElementForCycle = 1000;
        const lengthArray = puntiLuce.length;
        const project = this.projectService.actualProject;

        function fetchPage(page = 0) {
            const lastElement = (page * numberElementForCycle < lengthArray) ? page * numberElementForCycle + numberElementForCycle : lengthArray;
            const paginationArray = puntiLuce.slice(page * numberElementForCycle, lastElement);
            // query.equalTo('progetto', project);
            query.containedIn('puntoLuce', paginationArray);
            const res = fromPromise(query.find());
            return res.pipe(
                map((item) => {
                    return {
                        items: item,
                        nextPage: (page + 1) * numberElementForCycle >= lengthArray ? undefined : (page += 1),
                    };
                }),
            );
        }

        // emulate response server
        // const newSegnali = [];
        // const rand = [undefined, true, false];
        // puntiLuce.forEach(
        //     pl => {
        //         const newSegnale = new SegnaliTlcNodoParse();
        //         newSegnale.puntoLuce = pl;
        //         const index = Math.floor(Math.random() * 3);
        //         newSegnale.lampadaFunzionante = rand[index];
        //         newSegnali.push(newSegnale);
        //     }
        // )
        // return of(newSegnali);

        return fetchPage().pipe(
            expand(({nextPage}) => nextPage ? fetchPage(nextPage) : EMPTY),
            // to array accumula le risposte come elementi di un array
            toArray()
        ).pipe(
            map((segnali) => {
                // viene trasformato l array di array di risposte in un singolo array contenente tutti i segnali tlc
                const flatArraySegnali = [];
                segnali.forEach(sengnale => {
                    if (sengnale.items.length > 0) {
                        flatArraySegnali.push(...sengnale.items);
                    }
                });
                return flatArraySegnali;
            }));

    }


}
