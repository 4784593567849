<ng-container *ngIf="abbonamentoAttivo; else noAbbonamento"><!-- > -->
    <mat-tab-group (selectedIndexChange)="onChangeTab($event)" dynamicHeight class="huna-material-class-open-new-tab">
        <mat-tab id="reports" label="{{'reports' | translate}}">
           <app-list-reports></app-list-reports>
        </mat-tab>
        <mat-tab id="activities" label="{{'activities' | translate}}" style="margin-top: -21px">
            <ng-template matTabContent><app-list-activities></app-list-activities></ng-template>
        </mat-tab>
    </mat-tab-group>
</ng-container>
<ng-template #noAbbonamento>
    <!--viene verificata che la chiamata  è stato terminata-->
    <ng-container *ngIf="!loadingAbbonamento;else noLoading">
        <div fxLayout="column" [style.height]="'100%'" fxLayoutAlign="left left" class="cmp">
            <app-template-form title="{{'error' | translate}}!" [informativa]="false"
                               subtitle="{{'list.error' | translate}}">
                <div class="spacer"><h4>{{'list.reports.subscriptionRequired'|translate}}</h4>
                    {{'contact_info'| translate}}
                    <a mat-icon-button color="accent" class="email" style="text-decoration: underline"
                       href="mailto:info@huna.io"
                       label="Email">
                        <mat-icon>info</mat-icon>
                    </a>
                </div>
            </app-template-form>
        </div>
    </ng-container>
    <ng-template #noLoading>
        <div class="container">
            <app-loader [loaderInput]="true"></app-loader>
        </div>
    </ng-template>
</ng-template>
