import * as Parse from 'parse';




export class ExportedFile extends Parse.Object {
  public static CLASS_NAME = 'ExportedFile';
  constructor() {
    super(ExportedFile.CLASS_NAME);
  }

  public set objectId(value) {
  this.id=value
  }
  public get objectId() {
    return this.id;
  }

  public set updatedAt(value) {
    super.set('updatedAt', value);
  }
  public get updatedAt() {
    return super.get('updatedAt');
  }

  public set createdAt(value) {
    super.set('createdAt', value);
  }
  public get createdAt() {
    return super.get('createdAt');
  }

  public set ACL(value) {
    super.set('ACL', value);
  }
  public get ACL() {
    return super.get('ACL');
  }

  public set name(value) {
    super.set('name', value);
  }
  public get name() {
    return super.get('name');
  }

  public set type(value) {
    super.set('type', value);
  }
  public get type() {
    return super.get('type');
  }

  public set progetto(value) {
    super.set('progetto', value);
  }
  public get progetto() {
    return super.get('progetto');
  }

  public set file(value) {
    super.set('file', value);
  }
  public get file() {
    return super.get('file');
  }

  public set fileUrl(value) {
    super.set('fileUrl', value);
  }
  public get fileUrl() {
    return super.get('fileUrl');
  }
}

Parse.Object.registerSubclass(ExportedFile.CLASS_NAME, ExportedFile);
