<ng-container
        *ngIf=" sidenavIsOpen&&detail&&(detail.className==nomiClassi.arredoUrbano||detail.className==nomiClassi.lineaElettrica);else noArredoUrbano">
    <app-form-item-on-map [detail]="detail"
                          [circuitsInProject]="circuitiAppartenentiAlProgettoInput"
                          (clickSave)="onSubmitFormItem($event)"
                          [disabledSaveButton]="saveBottonDisabled">
    </app-form-item-on-map>
</ng-container>
<ng-template #noArredoUrbano>
    <form [formGroup]="tabsForm">
        <app-select-toolbar
                [possibleValues]="possibleTabs|convertValueByPredicateFunction:possibleTabsPredicate:keyPossibleTabs:detail:(abbonamentoGestioneAttivo$|async):(abbonamentoTelecontrolloAttivo$|async):(isGestore$|async):isSviluppatore:visualizedTabHistoryLightPoint:visualizedTabVirtualMidNight:visualizedTabConnectedFile:visualizedTabSchedaManutenzione:visualizedTabCaricoEsogeno"
                formControlName="selectedTab">

        </app-select-toolbar>
    </form>
    <ng-container *ngIf="selectedTab$|async as selectedTab">
        <ng-container [ngSwitch]="selectedTab">
            <ng-container *ngSwitchDefault>
                <form [formGroup]="filterForm" style="margin-top: 10px">
                    <div class="flex-center-width100">
                        <mat-button-toggle-group class="accent" formControlName="typeVisualizedKeys">
                            <mat-button-toggle
                                    value="all">{{'dashboard_sidenav.all' | translate}}</mat-button-toggle>
                            <mat-button-toggle
                                    value='favorites'>{{'dashboard_sidenav.favorite' | translate}}</mat-button-toggle>
                            <mat-button-toggle
                                    value='compiled'>{{'dashboard_sidenav.compiled'| translate}}</mat-button-toggle>
                            <mat-button-toggle
                                    value='updated'>{{'dashboard_sidenav.updated'| translate}}</mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                    <div style="padding: 10px">
                        <mat-form-field appearance="outline">
                            <mat-label>
                                {{'filterName'|translate}}
                            </mat-label>
                            <input formControlName="searchName" matInput>
                            <mat-icon matSuffix>search</mat-icon>
                        </mat-form-field>
                    </div>
                </form>

                <form [formGroup]="detailForm" *ngIf="detailForm" style="height: calc(100vh - 305px)">
                    <cdk-virtual-scroll-viewport itemSize="40" class="w100 h100">
                        <mat-list style="padding-bottom: 60px"
                                  *ngIf="detail|convertValueByPredicateFunction:getFormByDetailPredicate:selectedTab as fields">
                            <ng-container
                                    *ngFor="let field of fields[0]|convertValueByPredicateFunction:predicateFilterFunction:(favorites$|async):(searchName$|async):(typeVisualizedKeys$|async):(compiledFormFields$|async):(detailFormValues$|async):translateInstant">
                                <ng-container *ngIf="field.sectionName==null;else ContainerSectionName">
                                    <mat-list-item style="height: unset">
                                        <button mat-icon-button matListItemIcon
                                                [disabled]="field.disabled"
                                                (click)="clickFavorite(field.formControlName)">
                                            <mat-icon
                                                    [ngClass]="{'color-primary':field.formControlName|convertValueByPredicateFunction:favoriteIsSetPredicate:(favorites$|async)}">
                                                star
                                            </mat-icon>
                                        </button>
                                        <div matListItemTitle style="margin-bottom: -25px" class="w100">
                                            <ng-container
                                                    *ngIf="field|convertValueByPredicateFunction:isInLoadiongPredicate:(loadingFormField$|async);else LoadingPossibleValues">
                                                <app-get-form-fiel-by-configuration-element
                                                        (focusin)="focusIn(field)"
                                                        [type]="field.typeForm"
                                                        [titleTraduction]="field.traduction"
                                                        [possibleValues]="(field|convertValueByPredicateFunction:possibleValuesPredicate:circuitiAppartenentiAlProgettoInput:stradeAppartenentiAlProgettoInput:lightPointsNearest)"
                                                        [formControlName]="field.formControlName"
                                                >
                                                </app-get-form-fiel-by-configuration-element>
                                            </ng-container>
                                        </div>
                                        <!--                                    <div matListItemLine>{{folder.updated | date}}</div>-->
                                    </mat-list-item>


                                </ng-container>
                                <ng-template #ContainerSectionName>
                                    <ng-container
                                            [ngTemplateOutlet]="SectionName"
                                            [ngTemplateOutletContext]="{sectionName:field.sectionName}"
                                    >
                                    </ng-container>
                                </ng-template>
                            </ng-container>

                        </mat-list>
                    </cdk-virtual-scroll-viewport>
                </form>

            </ng-container>
            <ng-container *ngSwitchCase="keyPossibleTabs.images">
                <div style="height: calc(100vh - 105px);overflow: scroll">
                    <form [formGroup]="imageForm"
                          *ngIf="detail|convertValueByPredicateFunction:getTraductionFormImageByDetailPredicate:selectedTab as traductionsImageForm">
                        <ng-container *ngFor="let field of traductionsImageForm">
                            <mat-toolbar color="primary">
                                <mat-toolbar-row>
                                    <span style="color: #2196f3">{{field.traduction|translate}}</span>
                                </mat-toolbar-row>
                            </mat-toolbar>
                            <div style="height: 500px"
                                 *ngIf="field|convertValueByPredicateFunction:imageIsSetAndFormIsEnabled:(rawValuesImageFormWithEnable$|async)">
                                <app-get-form-fiel-by-configuration-element
                                        [type]="field.typeForm"
                                        [formControlName]="field.formControlName"
                                >
                                </app-get-form-fiel-by-configuration-element>
                            </div>
                        </ng-container>
                    </form>
                    <mat-toolbar color="primary">
                        <mat-toolbar-row>
                            <span style="color: #2196f3">{{'otherImage'|translate}}</span>
                        </mat-toolbar-row>
                    </mat-toolbar>
                    <app-gallery
                            [loadingImage]="(loading$|async)|convertValueByPredicateFunction:loadingPredicate:'addImageFile'"
                            (addImages)="addImagesToElement($event)"
                            (removeImages)="removeImagesToElement($event)"
                            [images]="connectedImages|convertValueByPredicateFunction:transformDocumentFilesImages"
                            [visualizeRemoveButton]="isGestore$|async"
                            [visualizeAddButton]="isGestore$|async"
                    >
                    </app-gallery>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="keyPossibleTabs.virtualMidNight">
                <ng-container *ngIf="detail.className==nomiClassi.circuiti;else noCircuito">
                    <ng-container
                            *ngIf="iotDevice != null && iotDevice.versioneProtocolloLM!==typeVersioneProtocolloLM.prt_v1;else NoDisponibile">
                        <app-light-mate-group
                                [settaggiTlcNodo]="settaggiTlcNodo"
                                [externalLoading]="loadVirtualMidnight"
                                [circuitoId]="detail.id"
                                [yesGroupId]="true"
                                [device]="iotDevice"
                                [profilesTlc]="profilesTlc"
                                (action)="updateRemoveSettaggi($event)"
                        >
                        </app-light-mate-group>
                    </ng-container>
                    <ng-template #NoDisponibile>
                        <div class=" flex-center-width100">
                            <div class="container-linee w100">
                                <div class="items-text-100">
                                    <mat-icon color="warn">error</mat-icon>
                                </div>
                                <div class="items-linee items-width-80">
                                    <h4>{{'lightMate.notDisponibileCircuit' | translate}}</h4>
                                </div>
                            </div>
                        </div>
                    </ng-template>

                </ng-container>
                <ng-template #noCircuito>
                    <ng-container
                            *ngIf="detail.circuito!=undefined && (detail.idMezzanotte!=undefined||iotDevice!=null&&iotDevice.versioneProtocolloLM===typeVersioneProtocolloLM.prt_v1);else nessunQuadro">
                        <app-light-mate-group
                                [externalLoading]="loadVirtualMidnight"
                                [device]="iotDevice"
                                [profilesTlc]="profilesTlc"
                                [settaggiTlcNodo]="settaggiTlcNodo"
                                [circuitoId]="detail.circuito.id"
                                [lightPointId]="detail.objectId"
                                [deviceId]="detail.idMezzanotte"
                                [groupId]="detail.groupId"
                                [isLightPoint]="true"
                                (action)="updateRemoveSettaggi($event)"
                                [yesGroupId]="false">
                        </app-light-mate-group>
                    </ng-container>
                    <ng-template #nessunQuadro>
                        <app-light-mate-group
                                [circuitoId]="chiaviFiltri.nessunQuadro"
                                [deviceId]="detail.idMezzanotte"
                                [groupId]="detail.groupId"
                                [isLightPoint]="true"
                                [yesGroupId]="false">

                        </app-light-mate-group>
                    </ng-template>
                </ng-template>
                <div style="padding: 31px 0"></div>
            </ng-container>
            <ng-container *ngSwitchCase="keyPossibleTabs.historyLightPoint">
                <div class="flexDisplay" *ngIf="loadHistoryLightPoint;else historyLightPointIsReady">
                    <mat-spinner></mat-spinner>
                </div>
                <ng-template #historyLightPointIsReady>
                    <app-history-light-point
                            [historyLightPoint]="historyLigthPoint"
                            [isSviluppatore]="isSviluppatore">
                    </app-history-light-point>
                </ng-template>
                <div style="padding: 31px 0"></div>
            </ng-container>
            <ng-container *ngSwitchCase="keyPossibleTabs.caricoEsogeno">
                <app-list-carichi-esogeni
                        [addSpacer]="true"
                        [carichiEsogeni]="carichiEsogeniElemento"
                        [carichiEsogeniExternal]="carichiEsogeniExternal"
                        (updateCaricoEsogeno)="updateCaricoEsogeno($event)"
                >
                </app-list-carichi-esogeni>
            </ng-container>
            <ng-container *ngSwitchCase="keyPossibleTabs.reportsAggregate">
                <app-aggregate-data-report [aggregateDataReport]="aggregateDataReport$|async"
                                           [loading]="(loading$|async)|convertValueByPredicateFunction:loadingPredicate:'aggregateDataReport'">
                    <ng-container stateEnergy [formGroup]="aggregateForm">
                        <mat-button-toggle-group class="accent" style="margin: 10px 0" formControlName="period">
                            <mat-button-toggle *ngFor="let periodType of (typePeriodAggregateData|keyvalue)"
                                               [value]="periodType.value">{{'reportsAggregate.' + periodType.value | translate}}
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                        <!--                    <app-move-date-with-range *ngIf="aggregateForm&&aggregateForm.get('fromDate').value!=null"-->
                        <!--                                              style="width: 350px;max-width: 100%"-->
                        <!--                                              [settingDateMin]="aggregateForm.get('fromDate').value"-->
                        <!--                                              [settingDateMax]="aggregateForm.get('toDate').value"-->
                        <!--                                              [deltaDate]="aggregateForm.get('deltaDate').value"-->
                        <!--                                              (changeDateEnd)="changeDateEnd($event)"-->
                        <!--                                              (changeDateStart)="changeDateStart($event)"-->
                        <!--                                              (changeCalendar)="changeCalendar($event)"-->

                        <!--                    >-->
                        <!--                    </app-move-date-with-range>-->
                    </ng-container>

                </app-aggregate-data-report>
            </ng-container>
            <ng-container *ngSwitchCase="keyPossibleTabs.scheduleMaintenance">
                <div class="flexDisplay" *ngIf="loadScheduleMaintence;else scheduleIsReady">
                    <mat-spinner></mat-spinner>
                </div>
                <ng-template #scheduleIsReady>
                    <app-assignement-schedule-maintence
                            [scheduleMaintenace]="detail.schedeManutenzione"
                            (clickRemoveSchedaManutenzione)="removeSchedaManutenzione($event)"
                            (addNewSchedaManutenzione)="addSchedaManutenzione()"
                    >
                    </app-assignement-schedule-maintence>
                </ng-template>
                <div style="padding: 31px 0"></div>
            </ng-container>
            <ng-container *ngSwitchCase="keyPossibleTabs.connectedFiles">
                <div class="flexDisplay" *ngIf="loadConnectedFile;else connectedFilesReady">
                    <mat-spinner></mat-spinner>
                </div>
                <ng-template #connectedFilesReady>
                    <div class="flex-center-width100" style="padding: 10px 5px; box-sizing: border-box;">
                        <button mat-flat-button color="primary" (click)="setTab(keyPossibleTabs.images)">
                            <mat-icon>photo_library</mat-icon>
                            {{'photoGallery'|translate}}
                        </button>
                    </div>
                    <app-connected-files
                            [loading]="(loading$|async)|convertValueByPredicateFunction:loadingPredicate:'addImageFile'"
                            [connectedFiles]="connectedFiles"
                            (addingFile)="connectFileToElement($event)"
                            (removingFile)="removingFileToElement($event)"
                            (refreshButton)="getConnectedFile()"
                            (searchButton)="openNavigateInFileManager($event)"
                    >
                    </app-connected-files>
                </ng-template>
                <div style="padding: 31px 0"></div>
            </ng-container>
        </ng-container>
    </ng-container>
    <!--    <mat-tab-group animationDuration="0ms" (selectedTabChange)="clickTab($event)" #tabGroup>-->
    <!--        <ng-container *ngIf="sidenavIsOpen">-->
    <!--            <ng-container-->
    <!--                    *ngIf="detail|convertValueByPredicateFunction:getFormByDetailPredicate as dataSources ">-->
    <!--                <mat-tab *ngFor="let tab of dataSources" label="{{tab.tab|translate}}" aria-labelledby="{{tab.tab}}">-->

    <!--                </mat-tab>-->
    <!--                <mat-tab label="{{'dashboard_sidenav.images'|translate}}" aria-labelledby="dashboard_sidenav.images"-->
    <!--                         *ngIf="detail|convertValueByPredicateFunction:getTraductionFormImageByDetailPredicate as traductionsImageForm">-->

    <!--                </mat-tab>-->
    <!--                <mat-tab-->
    <!--                        id="virtualMidNight"-->
    <!--                        label="{{'virtualMidNight' | translate}}"-->
    <!--                        aria-labelledby="virtualMidNight"-->
    <!--                        *ngIf=" &&sidenavIsOpen&&visualizedTabVirtualMidNight">-->

    <!--                </mat-tab>-->
    <!--            </ng-container>-->
    <!--        </ng-container>-->
    <!--        <mat-tab id="historyLightPoint" label="{{'historyLightPoint.titleTab' | translate}}"-->
    <!--                 aria-labelledby="historyLightPoint"-->
    <!--                 *ngIf=" && isSviluppatore &&sidenavIsOpen&&visualizedTabHistoryLightPoint">-->
    <!--        </mat-tab>-->
    <!--        <mat-tab id="connectedFiles" label="{{'fileManager.connectedFile.title' | translate}}"-->
    <!--                 aria-labelledby="connectedFiles"-->
    <!--                 *ngIf="abbonamentoGestioneAttivo&&isGestoreOnLocalStorage() && classNameIsIncludes(detail,[nomiClassi.puntiLuce,nomiClassi.circuiti,nomiClassi.arredoUrbano])&&sidenavIsOpen&&visualizedTabConnectedFile">-->
    <!--        </mat-tab>-->
    <!--        <mat-tab id="schduleMaintenance" label="{{'SchedaManutenzione' | translate}}"-->
    <!--                 aria-labelledby="schduleMaintenance"-->
    <!--                 *ngIf="&&sidenavIsOpen&&visualizedTabSchedaManutenzione">-->

    <!--        </mat-tab>-->
    <!--        <mat-tab id="reportsAggregate" label="{{'reportsAggregate.title' | translate}}"-->
    <!--                 aria-labelledby="reportsAggregate"-->
    <!--                 *ngIf="&&sidenavIsOpen">-->

    <!--        </mat-tab>-->
    <!--        <mat-tab id="caricoEsogeno" label="{{'dashboard_sidenav.CaricoEsogeno.title' | translate}}"-->
    <!--                 aria-labelledby="caricoEsogeno"-->
    <!--                 *ngIf="isGestoreOnLocalStorage() && classNameIsIncludes(detail,[nomiClassi.puntiLuce,nomiClassi.circuiti,nomiClassi.arredoUrbano])&&sidenavIsOpen">-->

    <!--        </mat-tab>-->
    <!--    </mat-tab-group>-->
</ng-template>

<!--<button (click)="closeSidenav()" class="fab" color="accent"-->
<!--        mat-mini-fab-->
<!--        matTooltip="{{'button.close' | translate}}"-->
<!--        style="z-index: 10000" type="button">-->
<!--    <mat-icon>close</mat-icon>-->
<!--</button>-->
<ng-container *ngIf="!disabledGroupButtonSidenav&&(isGestore$|async)&&visualizedButtonSave">
    <button (click)="removeItem()" *ngIf="detail && !classNameIsIncludes(detail,[nomiClassi.circuiti]) && isReport()"
            class="fab"
            color="accent" mat-mini-fab matTooltip="{{'button.remove_to_selection' | translate}}"
            style="z-index: 10000; margin-right: 3rem;" type="button">
        <mat-icon>remove</mat-icon>
    </button>
    <!--    <button (click)="openUrlNavigator()"-->
    <!--            *ngIf="detail && !classNameIsIncludes(detail,[nomiClassi.circuiti]) && !isReport()"-->
    <!--            class="fab"-->
    <!--            color="accent" fab mat-mini-fab matTooltip="{{'button.add_to_report' | translate}}"-->
    <!--            style="z-index: 10000; margin-right: 3rem;" type="button">-->
    <!--        <mat-icon>navigation</mat-icon>-->
    <!--    </button>-->
    <button (click)="circuitReport()" *ngIf="detail && classNameIsIncludes(detail,[nomiClassi.circuiti]) && !isReport()"
            class="fab"
            color="accent" mat-mini-fab matTooltip="{{'button.create_report' | translate}}"
            style="z-index: 10000; margin-right: 3rem;" type="button">
        <mat-icon>error_outline</mat-icon>
    </button>
    <!--il bottone del form-->
    <button class="fab" color="accent" [disabled]="saveBottonDisabled" mat-mini-fab
            *ngIf="detail && !classNameIsIncludes(detail,[nomiClassi.arredoUrbano,nomiClassi.lineaElettrica])"
            matTooltip="{{'button.save' | translate}}" style="z-index: 10000;margin-right: 6rem;" type="button"
            (click)="onSubmit()">
        <mat-icon>save</mat-icon>
    </button>
    <button class="fab" color="accent" [disabled]="saveBottonDisabled" mat-mini-fab
            matTooltip="{{'button.delete' | translate}}" style="z-index: 10000;margin-right: 9rem;" type="button"
            (click)="eliminaElemento()">
        <mat-icon>delete</mat-icon>
    </button>
</ng-container>
<ng-template #LoadingPossibleValues>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>
<ng-template #SectionName let-sectionName="sectionName">
    <mat-toolbar color="primary">
        <mat-toolbar-row>
            <span style="color: #2196f3">{{sectionName|translate}}</span>
        </mat-toolbar-row>
    </mat-toolbar>
</ng-template>