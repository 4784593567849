<mat-dialog-content>
    <form [formGroup]="form">
        <h1 mat-dialog-title *ngIf="titleIsSet" style="display: flex;justify-content: space-between;">
            <ng-container
                    [ngTemplateOutlet]="traductionTemplate"
                    [ngTemplateOutletContext]="title"
            >
            </ng-container>
            <mat-checkbox style="margin-right: 29px" (change)="setUnset($event)"
                          *ngIf="data.visualizeCheckAllButton==true"
                          [checked]="checkedAll$|async"
            >
            </mat-checkbox>
        </h1>
        <div class="flex-around-center flex-flow-column">
            <app-get-form-fiel-by-configuration-element *ngIf="data.visualizedSearch==true"
                                                        style="width: 100%"
                                                        [type]="typesFormValue.INPUT_TEXT"
                                                        [formControlName]="filterKeyName"
                                                        titleTraduction="search_generic"
            >
            </app-get-form-fiel-by-configuration-element>
            <ng-container
                    *ngFor="let field of formFields|convertValueByPredicateFunction:filterFunction:(filterKey$|async)">
                <ng-container [ngSwitch]="field.type">
                    <ng-container *ngSwitchCase="typesFormValue.RADIO_BUTTON">
                        <label class="label" *ngIf="field.title!=null">
                            <ng-container
                                    [ngTemplateOutlet]="traductionTemplate"
                                    [ngTemplateOutletContext]="field.title"
                            >
                            </ng-container>
                        </label>
                        <mat-radio-group [formControlName]="field.formControlName" style="padding: 10px">
                            <mat-radio-button style="margin-top: 0.2rem;" [value]="possibleValue.value"
                                              *ngFor="let possibleValue of field.possibleValues">
                                <ng-container
                                        [ngTemplateOutlet]="traductionTemplate"
                                        [ngTemplateOutletContext]="possibleValue.title"
                                >
                                </ng-container>
                            </mat-radio-button>
                        </mat-radio-group>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <app-get-form-fiel-by-configuration-element style="width: 100%"
                                                                    [type]="field.type"
                                                                    [formControlName]="field.formControlName"
                                                                    [titleTraduction]="field.title.traduction!=null?field.title.traduction:field.title.notTraduction"
                                                                    [possibleValues]="field.possibleValues|convertValueByPredicateFunction:getPossibleValues"
                        >
                        </app-get-form-fiel-by-configuration-element>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-flat-button color="accent" [mat-dialog-close]="undefined" *ngIf="!data.disableClose">
        <mat-icon>clear</mat-icon>
    </button>
    <button mat-flat-button color="accent" (click)="svuotaCampo()" *ngIf="data.unsetField==true">
        {{'unsetField'|translate}}
    </button>
    <button mat-flat-button color="primary" (click)="checkValue()" [disabled]="form.invalid">
        <mat-icon>check</mat-icon>
    </button>
</mat-dialog-actions>
<ng-template #traductionTemplate let-traduction="traduction" let-notTraduction="notTraduction">
     <span *ngIf="traduction;else titleNotTraductionTemplate">
            {{traduction|translate}}
        </span>
    <ng-template #titleNotTraductionTemplate>
        {{notTraduction}}
    </ng-template>
</ng-template>