import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserService} from '../../providers/services/user.service';
import {AuthService} from '../../providers/services/auth.service';
import {ProjectService} from '../../providers/services/project.service';
import {FiltersideService} from "../../providers/services/filterside.service";
import {NavService} from "../../providers/services/nav.service";
import {DetailsideService} from "../../providers/services/detailside.service";
import {TranslateService} from '@ngx-translate/core';
import {NavbarService} from "../../providers/services/navbar.service";
import {UserRoleNumber} from "../../../config/static-data";
import {arrayIsSet, currentVersion} from "../../models/Models";
import {Observable} from "rxjs";
import {ProgettiParse} from "../../models/Progetti.Parse";
import {map} from "rxjs/operators";

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {

    public user;
    public myProjects$: Observable<ProgettiParse[]>;
    public freeProjects$: Observable<ProgettiParse[]>;
    private subscription;
    public isLogged;
    public iconLanguage = 'it';
    public userRoleNumber = UserRoleNumber;
    public currentVersion = currentVersion;


    constructor(
        private userService: UserService,
        private authService: AuthService,
        private navService: NavService,
        private detailSideService: DetailsideService,
        private filterSideService: FiltersideService,
        private projectService: ProjectService,
        public translate: TranslateService,
        public navbarService: NavbarService
    ) {
        this.initUser();
        this.initSidebar();
        this.iconLanguage = translate.currentLang;
        this.subscription = this.authService.getObservableForLogin().subscribe(res => {
            this.isLogged = res;
        });
        this.myProjects$ = this.userService.userProjects$.pipe(
            map(projects => {
                if (arrayIsSet(projects)) {
                    return projects.filter(p => !p.progettoPubblico)
                } else {
                    return undefined;
                }
            })
        )
        this.freeProjects$ = this.userService.userProjects$.pipe(
            map(projects => {
                if (arrayIsSet(projects)) {
                    return projects.filter(p => p.progettoPubblico)
                } else {
                    return undefined;
                }
            })
        )
    }

    ngOnInit() {
    }


    private async initUser() {
        this.user = await this.userService.user();
    }


    private initSidebar() {

    }

    projectActive(id) {
        return id === this.projectService.getProjectLocal()
    }

    logout() {
        this.authService.logout();
        this.navService.close();
        this.detailSideService.close();
        this.filterSideService.close();
    }

    setProject(id, role, project) {
        this.projectService.setProjectLocal(id, project);
        this.navService.close();
        this.detailSideService.close();
        this.filterSideService.close();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }


    onClickNav() {
        this.navService.toggle();
        this.detailSideService.close();
        this.filterSideService.close();
    }

    setLanguage(language) {
        if (this.translate.getLangs().includes(language)) {
            localStorage.setItem('savedLanguage', language);
            this.translate.use(language);
            this.iconLanguage = language;
        }

    }

    public get nameProject() {
        return (window.innerWidth >= 500) ? this.projectService.currentProject.name : this.projectService.currentProject.sigla;
    }

    public getPathProject(firstViewOnOpening) {
        if (firstViewOnOpening === 'TELECONTROLLO') {
            return '/controllo-remoto';
        } else {
            return '/';
        }
    }

    public get isAmministratore() {
        return this.projectService.isAmministratore
    }

}
