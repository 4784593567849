import {Injectable} from '@angular/core';
import {LocalSotrageService} from "./local-sotrage.service";

@Injectable({
    providedIn: 'root'
})
export class NavbarService {

    constructor(private localStorage: LocalSotrageService) {
    }

    public disabledHamburger: boolean = false;
    public disabledNavbar: boolean = false;

    public get nameProject() {
        return (this.localStorage.nomeProgetto) ? this.localStorage.nomeProgetto : '';
    }

    public get siglaProject() {
        return (this.localStorage.siglaProgetto) ? this.localStorage.siglaProgetto : '';
    }
}
