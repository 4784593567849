<app-container>
    <app-template-form [informativa]="false" [title]="'historyLightPoint.storicSignalLightPoint' | translate" style="margin: 10px">
        <div class="mat-elevation-z8" *ngIf="historyLightPointIsSet;else emptyList">
            <table mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="updatedAt">
                    <th mat-header-cell *matHeaderCellDef
                        mat-sort-header> {{"historyLightPoint.updatedAt"|translate}} </th>
                    <td mat-cell *matCellDef="let row"> {{row.updatedAt|customDate:'long'|async}} </td>
                </ng-container>
                <ng-container matColumnDef="lampadaFunzionante">
                    <th mat-header-cell *matHeaderCellDef
                        mat-sort-header> {{"historyLightPoint.lampadaFunzionante"|translate}} </th>
                    <td mat-cell *matCellDef="let row">
                        <div class="items-text-circle-20"
                             [ngClass]="row|convertValueByPredicateFunction:classColorLampadaFunzionantePredicate"
                        ></div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="fase">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"historyLightPoint.fase"|translate}} </th>
                    <td mat-cell *matCellDef="let row"> {{row.fase}} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>
        <ng-template #emptyList>
            <app-list-is-empty>
               <p> {{"historyLightPoint.noStoric"|translate}}</p>
            </app-list-is-empty>
        </ng-template>
    </app-template-form>
</app-container>
