import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges, OnDestroy
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {StreetsService, Photometry, ComputeResults} from 'src/app/providers/services/streets.service';
import {AlertService} from 'src/app/providers/services/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {DialogPopUpService} from "../../../providers/services/dialog-pop-up.service";
import {GruppoFotometrieParse} from "../../../models/GruppoFotometrie.Parse";
import {GruppoFotometrieService} from "../../../providers/services/gruppo-fotometrie.service";
import {FotometriaParse} from "../../../models/Fotometria.Parse";
import {arrayIsSet, stringIsSet} from "../../../models/Models";
import {StradeParse} from "../../../models/Strade.Parse";
import {fromPromise} from "rxjs/internal-compatibility";
import {delay, map, switchMap} from "rxjs/operators";
import {EMPTY, of, Subscription} from "rxjs";

@Component({
    selector: 'app-light-street-form',
    templateUrl: './light-street-form.component.html',
    styleUrls: ['./light-street-form.component.scss']
})
export class LightStreetFormComponent implements OnInit, OnChanges, OnDestroy {

    @Output()
    compute: EventEmitter<ComputeResults> = new EventEmitter();

    @Input() streetId: string = null!;
    strada: StradeParse | undefined;
    oldFotometrieSalvate: FotometriaParse[] | undefined;
    gruppoFakeFotometrieSalvate: GruppoFotometrieParse | undefined;
    @Input()
    streetData: any = null!;
    formGroup = new UntypedFormGroup({});
    computing = false;
    filter = {
        nomeFile: '',
        produttore: '',
        nomeFamiglia: '',
        nomeProdotto: '',
        nomeLampada: '',
        ottica: '',
        potenza: {
            min: undefined,
            max: undefined,
        },
        flusso: {
            min: undefined,
            max: undefined,
        },
        efficienza: {
            min: undefined,
            max: undefined,
        },
        colore: {
            min: undefined,
            max: undefined,
        },
        CRI: {
            min: undefined,
            max: undefined,
        },
    };
    computeSidewalks = false;
    @Input()
    photometries: FotometriaParse[] = [];
    @Input()
    gruppiFotometrie: GruppoFotometrieParse[] = [];
    // photometries: Photometry[] x = [];
    loading = true;

    filteredPhotometries: Photometry[] = [];
    selectedPhotometries: Photometry[] = [];

    form: UntypedFormGroup;
    subscriptions: Subscription[] = [];

    constructor(
        private streetsService: StreetsService,
        private alertService: AlertService,
        private translateService: TranslateService,
        private myDialog: DialogPopUpService,
        private gruppoFotometrieService: GruppoFotometrieService,
        private fb: UntypedFormBuilder
    ) {
        this.form = this.fb.group({fotometrieSalvate: true});
        const sub = this.form.get('fotometrieSalvate').valueChanges.subscribe(
            fotometrieSalvate => {
                let select = arrayIsSet(this.selectedPhotometries) ? [...this.selectedPhotometries] : [];
                if (arrayIsSet(this.strada.fotometrie)) {
                    if (fotometrieSalvate) {
                        const idsSelected = select.map(fotometria => fotometria.objectId);
                        this.strada.fotometrie.forEach(fotometria => {
                            if (!idsSelected.includes(fotometria.objectId)) {
                                select.push(fotometria);
                            }
                        });
                    } else {
                        const idsGruppiSelezionati = [];
                        this.gruppiSelezionati
                            .forEach(gruppoFotometria => {
                                    if (arrayIsSet(gruppoFotometria.fotometrieScaricate)) {
                                        gruppoFotometria.fotometrieScaricate.forEach(fotometria => {
                                            idsGruppiSelezionati.push(fotometria.objectId);
                                        })
                                    }
                                }
                            )
                        const idsFotmetrieStrade = this.strada.fotometrie
                            .map(fotometria => fotometria.objectId);
                        const idsFotometrieStradaNonPresentiInGruppiSelezionati = idsFotmetrieStrade
                            .filter(id => !idsGruppiSelezionati.includes(id))
                        select = select
                            .filter(f => !idsFotometrieStradaNonPresentiInGruppiSelezionati.includes(f.objectId))
                    }
                }
                this.selectedPhotometries = select;
            }
        )
        this.subscriptions.push(sub);
    }

    getSavedPhotometryNotInGroupSelected(fotometrieSalvate) {
        const isSet = this.form.get('fotometrieSalvate').value
        let fotometrieSalvateCopy = isSet && arrayIsSet(fotometrieSalvate) ? [...fotometrieSalvate] : [];
        let idsFotometrieGruppi = [];
        this.gruppiSelezionati.forEach(gruppo => {
            if (arrayIsSet(gruppo.fotometrieScaricate)) {
                idsFotometrieGruppi = idsFotometrieGruppi.concat(gruppo.fotometrieScaricate.map(f => f.objectId));
            }
        })
        return fotometrieSalvateCopy.filter(fotometria => !idsFotometrieGruppi.includes(fotometria.objectId))
    }

    replaceOldPhotometriesWithNewPhometriesInSelectFotometries(old: FotometriaParse[], newFotometries: FotometriaParse[]) {
        const getIndex = (objectId) => {
            return this.selectedPhotometries.findIndex(f => f.objectId === objectId);
        }
        old.forEach((oldPhotmetry, index) => {
            const indexSelect = getIndex(oldPhotmetry.objectId)
            if (indexSelect >= 0) {
                if (newFotometries[index] != null) {
                    this.selectedPhotometries[indexSelect] = newFotometries[index];
                } else {
                    this.selectedPhotometries.splice(indexSelect, 1);
                }
            }
        })
    }

    ngOnInit() {

    }

    ngOnChanges(changes: SimpleChanges): void {
        this.loading = false;
        if (changes.streetData && stringIsSet(this.streetId)) {
            const oldFotometrie = this.getSavedPhotometryNotInGroupSelected(this.oldFotometrieSalvate);
            const strada = new StradeParse()
            strada.objectId = this.streetId;
            fromPromise(strada.fetchWithInclude(['fotometrie'])).pipe(
                switchMap((strada: StradeParse) => {
                    this.strada = strada;
                    this.oldFotometrieSalvate = arrayIsSet(strada.fotometrie) ? [...strada.fotometrie] : undefined;
                    if (arrayIsSet(this.selectedPhotometries)) {
                        const newFotometrie = this.getSavedPhotometryNotInGroupSelected(strada.fotometrie);
                        this.replaceOldPhotometriesWithNewPhometriesInSelectFotometries(oldFotometrie, newFotometrie)
                    } else if (arrayIsSet(strada.fotometrie)) {
                        this.selectedPhotometries = [...strada.fotometrie];
                    } else {
                        return EMPTY;
                    }
                    return fromPromise(this.runCompute());
                })
            )
                .subscribe(async (strada) => {
                }, error => {
                    console.error(error)
                })
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe())
    }

    async runCompute() {
        let fotometrie$ = of(undefined);
        if (arrayIsSet(this.gruppiSelezionati)) {
            fotometrie$ = this.gruppoFotometrieService.getAllPhotometryByGroup$(this.gruppiSelezionati).pipe(
                map(fotometrie => {
                    if (arrayIsSet(fotometrie)) {
                        return arrayIsSet(this.selectedPhotometries) ? this.selectedPhotometries.concat(fotometrie) : fotometrie
                    } else {
                        return this.selectedPhotometries
                    }
                })
            );
        } else if (arrayIsSet(this.selectedPhotometries)) {
            fotometrie$ = of(this.selectedPhotometries)
        }
        // else {
        //     this.alertService.success(this.translateService.instant('streets.computing'));
        //     this.computing = true;
        //     try {
        //         let fotometrieIds = this.selectedPhotometries.map(p => p.objectId);
        //         const result = await this.streetsService.compute(this.streetId, fotometrieIds, this.strada.includiMarciapiedi);
        //         this.compute.emit(result);
        //     } catch (e) {
        //         console.error(e);
        //         this.alertService.error(this.translateService.instant(e.message));
        //     }
        //     this.computing = false;
        // }
        this.alertService.success(this.translateService.instant('streets.computing'));
        this.computing = true;
        fotometrie$.pipe(
            switchMap(fotometrie => {
                if (arrayIsSet(fotometrie)) {
                    const fotometrieIds = fotometrie.map(fotometria => fotometria.objectId)
                    return fromPromise(this.streetsService.compute(this.streetId, fotometrieIds, this.strada.includiMarciapiedi))
                } else {
                    this.alertService.warning(this.translateService.instant('alert.notElementSelected'))
                    return of({allFotometrie: [], allResults: []})
                }
            })
        )
            .subscribe(result => {
                this.compute.emit(result);
                this.computing = false
            }, error => {
                this.alertService.error(error)
                this.computing = false
            },()=>{
                this.computing = false
            })
    }

    add(photometry: Photometry) {
        this.selectedPhotometries.push(photometry);
        this.selectedPhotometries = this.selectedPhotometries.slice();
    }

    remove(photometry: Photometry) {
        this.selectedPhotometries = this.selectedPhotometries
            .filter(x => x !== photometry);
    }

    get window() {
        return window;
    }


    createNewFotometry() {
        const valueForm = this.gruppiFotometrie.map(gruppo => {
            return {
                valueForm: gruppo.objectId,
                html: gruppo.name
            }
        })
        this.gruppoFotometrieService.openCreateUpdateGruppoFotometrie(this.photometries, valueForm, this.gruppiSelezionati).subscribe(result => {
                if (result) {
                    const index = this.gruppiFotometrie.findIndex(gruppo => gruppo.objectId == result.objectId);
                    if (index < 0) {
                        this.gruppiFotometrie.push(result);
                    } else {
                        this.gruppiFotometrie[index] = result
                    }
                    const message = this.translateService.instant('alert.success');
                    this.alertService.success(message);
                }
            },
            error => {
                this.alertService.error(error)
            })
    }

    updateGroupPhotometry(groupPhotometry: GruppoFotometrieParse) {
        this.gruppoFotometrieService.openUpdatePhotometry(this.photometries, groupPhotometry).subscribe(
            result => {
                const message = this.translateService.instant('alert.success');
                this.alertService.success(message);
            }, error => {
                this.alertService.error(error)
            }
        )
    }

    private gruppiSelezionati: GruppoFotometrieParse[] = [];

    private addSelectedPhotometry(fotometrie: FotometriaParse[]) {
        fotometrie.forEach(
            fotometria => {
                const index = this.selectedPhotometries.findIndex(fotometriaSelezionata => fotometriaSelezionata.objectId == fotometria.objectId)
                if (index < 0) {
                    this.selectedPhotometries.push(fotometria);
                }
            }
        )
    }

    private removeSelectedPhotometry(fotometrie: FotometriaParse[]) {
        fotometrie.forEach(
            fotometria => {
                const index = this.selectedPhotometries.findIndex(fotometriaSelezionata => fotometriaSelezionata.objectId == fotometria.objectId)
                if (index >= 0) {
                    this.selectedPhotometries.splice(index, 1);
                }
            }
        )
    }

    selectGroupPhotometries(gruppoFotometria: GruppoFotometrieParse) {
        const index = this.gruppiSelezionati.findIndex(gruppoSelezionato => gruppoSelezionato.objectId == gruppoFotometria.objectId)
        if (index < 0) {
            this.gruppiSelezionati.push(gruppoFotometria)
        } else {
            this.gruppiSelezionati.splice(index, 1);
        }
    }

    isSelectedGruppoFotometria(gruppoFotometria: GruppoFotometrieParse) {
        const index = this.gruppiSelezionati.findIndex(gruppoSelezionato => gruppoSelezionato.objectId == gruppoFotometria.objectId)
        return index >= 0;
    }

    removeGroupPhotometries(photometry: GruppoFotometrieParse) {
        this.gruppoFotometrieService.destroyGruppiFotometrie([photometry]).subscribe(
            gruppi => {
                gruppi.forEach(gruppoEliminato => {
                    const index = this.gruppiFotometrie.findIndex(gruppoFotometrie => gruppoFotometrie.objectId == gruppoEliminato.objectId)
                    if (index >= 0) {
                        this.gruppiFotometrie.splice(index, 1);
                    }
                })
                const message = this.translateService.instant('alert.success');
                this.alertService.success(message);
            }, error => {
                this.alertService.error(error);
            }
        )
    }

    getNumberPhotometry(gruppoFotometrie: GruppoFotometrieParse): number {
        return gruppoFotometrie.fotometrieCounter != null ? gruppoFotometrie.fotometrieCounter : 0
    }

    load = {gruppiFotometrie: false, photometries: false}

    refreshPhotometriesAndGruopPhotometries() {
        this.load.gruppiFotometrie = true
        this.load.photometries = true
        this.gruppoFotometrieService.getAllGruppiFotometrie().subscribe(
            gruppiFotomotrie => {
                this.gruppiFotometrie = arrayIsSet(gruppiFotomotrie) ? gruppiFotomotrie : [];
                this.load.gruppiFotometrie = false;
            }
        )
        this.streetsService.getAllPhotometries().then(photometries => {
            this.photometries = photometries;
            this.load.photometries = false;
        });
    }

    selectFotometrieSalvate() {
        this.selectedPhotometries = this.strada.fotometrie;
    }
}
