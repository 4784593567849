<div class="container">
    <app-template-form [informativa]="false" [title]="'fileRequest' | translate">
        <mat-card>
            <div style="display:flex;align-items: center;justify-content: start;margin-left: 20px">
                <div>{{ 'fotoFileRequest' | translate}} <strong>{{ 'fileReady' | translate}}</strong></div>
            </div>
            <form [formGroup]="formExport">
                <mat-grid-list cols="12" rowHeight="40px">
                    <mat-grid-tile [colspan]="4"
                                   [rowspan]="1">
                        <div class="w100" style="display: flex;justify-content: flex-end">
                            <mat-slide-toggle
                                    (change)="changeAll($event)"
                                    color="primary"
                                    formControlName="tutto"></mat-slide-toggle>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="8"
                                   [rowspan]="1">
                        <div class="w100" style="margin-left:20px;display: flex;justify-content: flex-start">
                            {{'exportImage.tutto' | translate}}
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="4"
                                   [rowspan]="1">
                        <div class="w100" style="display: flex;justify-content: flex-end">
                            <mat-slide-toggle
                                    (change)=" changeSingle($event,'FOTO_CIRCUITI')"
                                    color="primary"
                                    formControlName="FOTO_CIRCUITI"></mat-slide-toggle>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="8"
                                   [rowspan]="1">
                        <div class="w100" style="margin-left:20px;display: flex;justify-content: flex-start">
                            {{'exportImage.FOTO_CIRCUITI' | translate}}
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="4"
                                   [rowspan]="1">
                        <div class="w100" style="display: flex;justify-content: flex-end">
                            <mat-slide-toggle
                                    (change)=" changeSingle($event,'FOTO_TIPOLOGIE_PUNTI_LUCE')"
                                    color="primary"
                                    formControlName="FOTO_TIPOLOGIE_PUNTI_LUCE"></mat-slide-toggle>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="8"
                                   [rowspan]="1">
                        <div class="w100" style="margin-left:20px;display: flex;justify-content: flex-start">
                            {{'exportImage.FOTO_TIPOLOGIE_PUNTI_LUCE' | translate}}
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="4"
                                   [rowspan]="1">
                        <div class="w100" style="display: flex;justify-content: flex-end">
                            <mat-slide-toggle (change)=" changeSingle($event,'FOTO_PUNTI_LUCE')"
                                              color="primary"
                                              formControlName="FOTO_PUNTI_LUCE"></mat-slide-toggle>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="8"
                                   [rowspan]="1">
                        <div class="w100" style="margin-left:20px;display: flex;justify-content: flex-start">
                            {{'exportImage.FOTO_PUNTI_LUCE' | translate}}
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="4"
                                   [rowspan]="1">
                        <div class="w100" style="display: flex;justify-content: flex-end">
                            <mat-slide-toggle (change)=" changeSingle($event,'XML_PELL')"
                                              color="primary"
                                              formControlName="XML_PELL"></mat-slide-toggle>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="8"
                                   [rowspan]="1">
                        <div class="w100" style="margin-left:20px;display: flex;justify-content: flex-start">
                            {{'exportImage.XML_PELL' | translate}}
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="4"
                                   [rowspan]="1">
                        <div class="w100" style="display: flex;justify-content: flex-end">
                            <mat-slide-toggle (change)="changeSingle($event,'XLS_ENEL')"
                                              color="primary"
                                              formControlName="XLS_ENEL"></mat-slide-toggle>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="8"
                                   [rowspan]="1">
                        <div class="w100" style="margin-left:20px;display: flex;justify-content: flex-start">
                            {{'exportImage.XLS_ENEL' | translate}}
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="4"
                                   [rowspan]="1">
                        <div class="w100" style="display: flex;justify-content: flex-end">
                            <mat-slide-toggle (change)="changeSingle($event,'XLS_PROGETTO')"
                                              color="primary"
                                              formControlName="XLS_PROGETTO"></mat-slide-toggle>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="8"
                                   [rowspan]="1">
                        <div class="w100" style="margin-left:20px;display: flex;justify-content: flex-start">
                            {{'exportImage.XLS_PROGETTO' | translate}}
                        </div>
                    </mat-grid-tile>
                </mat-grid-list>
            </form>
            <div style="display:flex;align-items: center;justify-content: start;">
                <div style="text-align: end;margin: 20px;width: 300px;max-width: 70%">
                    {{ 'sendeRequest' | translate}}
                </div>
                <div style="margin: 20px">
                    <button (click)="sendRequest()"
                            mat-flat-button color="primary"
                            aria-label="download file"
                            [disabled]="!isOneTrue()"
                    >
                        <mat-icon>send</mat-icon>
                    </button>
                </div>
            </div>
        </mat-card>
        <div class="spacer"></div>
        <mat-card>
            <div style="display:flex;align-items: center;justify-content: start;margin-left: 20px">
                <h5>    {{ 'fileDownload' | translate}}</h5>
            </div>
            <div class="button-text-container">
                <div class="text-item">
                    {{ 'filePuntiLuceCsv' | translate}}
                </div>
                <div class="button-item">
                    <ng-container *ngIf="!downloadFile.puntiLuce;else loadingPuntiLuce">
                        <button style="margin-right: 10px;width: 85px"
                                (click)="downloadFileCsvPuntiLuce()"
                                mat-flat-button color="primary"
                        >
                            <div class="text-icon">
                                <span style="padding:0;margin:-5px;font-weight: bold">CSV</span>
                                <mat-icon>archive</mat-icon>
                            </div>
                        </button>
                        <button style="margin-right: 10px"
                                (click)="downloadFileGeojsonPuntiLuce()"
                                mat-flat-button color="primary"
                        >
                            <div class="text-icon">
                                <span style="padding:0;margin:-5px;font-weight: bold">geojson</span>
                                <mat-icon>archive</mat-icon>
                            </div>
                        </button>
                        <button (click)="downloadFileKmlPuntiLuce()"
                                mat-flat-button color="primary"
                        >
                            <div class="text-icon">
                                <span style="padding:0;margin:-5px;font-weight: bold">klm/kmz</span>
                                <mat-icon>archive</mat-icon>
                            </div>
                        </button>
                    </ng-container>
                    <ng-template #loadingPuntiLuce>
                        <div style="margin: auto">
                            <mat-spinner [diameter]="20"></mat-spinner>
                        </div>
                    </ng-template>
                </div>
            </div>
            <div class="button-text-container">
                <div class="text-item">
                    {{ 'fileCircuitiCsv' | translate}}
                </div>
                <div class="button-item">
                    <ng-container *ngIf="!downloadFile.circuiti;else loadingCircuiti">
                        <button style="margin-right: 10px;width: 85px"
                                (click)="downloadFileCsvCircuiti()"
                                mat-flat-button color="primary"
                        >
                            <div class="text-icon">
                                <span style="padding:0;margin:-5px;font-weight: bold">CSV</span>
                                <mat-icon>archive</mat-icon>
                            </div>
                        </button>
                        <button style="margin-right: 10px"
                                (click)="downloadFileGeojsonCircuiti()"
                                mat-flat-button color="primary"
                        >
                            <div class="text-icon">
                                <span style="padding:0;margin:-5px;font-weight: bold">geojson</span>
                                <mat-icon>archive</mat-icon>
                            </div>
                        </button>
                        <button (click)="downloadFileKmlCircuiti()"
                                mat-flat-button color="primary"
                        >
                            <div class="text-icon">
                                <span style="padding:0;margin:-5px;font-weight: bold">klm/kmz</span>
                                <mat-icon>archive</mat-icon>
                            </div>
                        </button>
                    </ng-container>
                    <ng-template #loadingCircuiti>
                        <div style="margin: auto">
                            <mat-spinner [diameter]="20"></mat-spinner>
                        </div>
                    </ng-template>
                </div>
            </div>
            <div class="button-text-container">
                <div class="text-item">
                    {{ 'combinedFilePuntiLuceCircuiti' | translate}}
                </div>
                <div class="button-item">
                    <ng-container *ngIf="!downloadFile.dxf;else loadingDxf">
                        <button style="width: 85px"
                                (click)="downloadFileDxf()"
                                mat-flat-button color="primary"
                        >
                            <div class="text-icon">
                                <span style="padding:0;margin:-5px;font-weight: bold">DXF</span>
                                <mat-icon>archive</mat-icon>
                            </div>
                        </button>
                    </ng-container>
                    <ng-template #loadingDxf>
                        <div style="margin: auto">
                            <mat-spinner [diameter]="20"></mat-spinner>
                        </div>
                    </ng-template>
                </div>
            </div>
            <!--            <div style="display:flex;align-items: center;justify-content: start;">-->
            <!--                <div style="margin: 20px;width: 300px;max-width: 70%">-->
            <!--                    {{ 'File CSV contenente i Cluster Lux Data' | translate}}-->
            <!--                </div>-->
            <!--                <div style="margin: 20px">-->
            <!--                    <ng-container *ngIf="!downloadFile.luxdataCluster;else loadingLuxData">-->
            <!--                        <button (click)="downloadFileLuxDataCluster()"-->
            <!--                                mat-flat-button color="primary"-->
            <!--                                aria-label="download file"-->
            <!--                        >-->
            <!--                            <mat-icon>archive</mat-icon>-->
            <!--                        </button>-->
            <!--                    </ng-container>-->
            <!--                    <ng-template #loadingLuxData>-->
            <!--                        <mat-spinner [diameter]="20"></mat-spinner>-->
            <!--                    </ng-template>-->
            <!--                </div>-->
            <!--            </div>-->
        </mat-card>
    </app-template-form>
</div>
