<mat-chip-list [multiple]="true">
    <mat-chip class="clickable-chip"
              *ngFor="let value of distinctValue"
              [disabled]="disabled"
              (click)="clickChip(value)"
              [selected]="isSelected(value)"
    >
        <ng-container *ngIf="styleChips==='rounded';else drop">
            {{getValue(value)|translate}}
        </ng-container>
        <ng-template #drop>
            <div class="text-item text-container">
                <span style="font-weight: bold;">
                    {{getValue(value)|translate}}
                </span>
            </div>
        </ng-template>
    </mat-chip>
</mat-chip-list>
<!--<div class="flex-center-width100" style="height: 100%">-->
<!--    <div style="  flex: 1;"-->
<!--         class="button-container border-radius-card background-other"-->
<!--    >-->
<!--        &lt;!&ndash;                <img [src]="icon" style="width: 48px">&ndash;&gt;-->
<!--        <div class="text-item" *ngFor="let value of distinctValue" [ngClass]="[getColorChips(value)]">-->
<!--                    <span class="color-other"-->
<!--                          style="font-weight: bold;">-->
<!--                        {{value}}-->
<!--                    </span>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->