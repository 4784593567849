import { Inject, Pipe, PipeTransform, PLATFORM_ID } from '@angular/core';
import * as moment from 'moment';
import { isPlatformBrowser } from "@angular/common";

@Pipe({
    name: 'myDate'
})
export class MyDatePipe implements PipeTransform {

    constructor(@Inject(PLATFORM_ID) private platformId) {
    }

    transform(value: any, args: string = 'lll'): any {
        if (isPlatformBrowser(this.platformId)) {
            if (value == null) {
                return null;
            }
            else if (value !== '') {
                return moment(value).format(args);
            } else {
                return moment().locale(navigator.language).format(args);
            }
        } else {
            return value;
        }
    }

}