import {Component, Input, OnInit} from '@angular/core';
import {SegnalazioniParse} from "../../models/Segnalazioni.Parse";
import {DialogPopUpInfoService} from "../../providers/services/dialog-pop-up-info.service";
import {TranslateService} from "@ngx-translate/core";
import {ReportStates} from "../../../config/static-data";

@Component({
    selector: 'app-box-segnalazione',
    templateUrl: './box-segnalazione.component.html',
    styleUrls: ['./box-segnalazione.component.scss']
})
export class BoxSegnalazioneComponent implements OnInit {
    @Input() segnalazione: SegnalazioniParse;
    @Input() selected: boolean;
    backgroundColor = '';
    descriptionClass = 'box-description-close';
    public assegnatoA = ReportStates.ASSEGNATOA;

    constructor(private dialogInfo: DialogPopUpInfoService,
                private translateService: TranslateService) {
    }

    ngOnInit(): void {
        if (this.segnalazione.priorita === 2) {
            this.backgroundColor = 'background-color-warn-gradient-vertical';
        } else if (this.segnalazione.priorita === 1) {
            this.backgroundColor = 'background-color-yellow-gradient-vertical';
        } else if (this.segnalazione.priorita === 0) {
            this.backgroundColor = 'background-color-green-gradient-vertical';
        }
    }


    openDescription() {
        const data: { texts: string[], image: { url: string }, subTitle: string, title: string } = {
            texts: this.segnalazione.descrizione.split('\n'),
            image: undefined,
            subTitle: this.translateService.instant('segnalazioniParse.descrizione.title'),
            title: null
        };
        this.dialogInfo.openExpandedDetail(data);
    }

}
