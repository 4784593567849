<app-template-form title="{{'settings.title' | translate}}">
    <ng-container titleSx>
        <button mat-flat-button color="primary" matTooltip="{{ 'save' | translate}}"
                (click)="onSubmitButtonClick($event)">
            <mat-icon>save</mat-icon>
        </button>
    </ng-container>
    <form [formGroup]="formGroup" ngNativeValidate (ngSubmit)="save()" #nativeForm>
        <fieldset [disabled]="saving">
            <app-form-group-template title="{{ 'streets.main_info' | translate}}">
                <div class="w100 flex-flow-row flex-around-center">
                    <mat-checkbox [checked]="street.isCentroStorico" style="margin-bottom: 10px"
                                  (change)="updateObjectAndRefresh(street, 'isCentroStorico', $event.checked, null)">
                        {{'streets.isCentroStorico'| translate}}
                    </mat-checkbox>
                    <mat-checkbox [checked]="street.includiMarciapiedi" style="margin-bottom: 10px"
                                  (change)="updateObjectAndRefresh(street, 'includiMarciapiedi', $event.checked, null)">
                        {{'streets.includiMarciapiedi'| translate}}
                    </mat-checkbox>
                    <mat-checkbox [checked]="street.calcoloSemicilindrico" style="margin-bottom: 10px"
                                  (change)="updateObjectAndRefresh(street, 'calcoloSemicilindrico', $event.checked, null)">
                        {{'streets.calcoloSemicilindrico'| translate}}
                    </mat-checkbox>
                    <mat-checkbox [checked]="street.verificaIPEI_A" style="margin-bottom: 10px"
                                  (change)="updateObjectAndRefresh(street, 'verificaIPEI_A', $event.checked, null)">
                        {{'streets.verificaIPEI_A'| translate}}
                    </mat-checkbox>
                </div>

                <mat-form-field floatLabel="auto" appearance="fill">
                    <mat-label>{{ 'streets.name' | translate}}</mat-label>
                    <input type="text" matInput
                           (change)="updateObjectAndRefresh(street, 'nome', $event.target.value, 'String')"
                           required [value]="street.nome">
                </mat-form-field>
                <mat-form-field floatLabel="auto" appearance="fill">
                    <mat-label>{{ 'streets.maintenance_index' | translate}}</mat-label>
                    <input type="number" step="0.01" min="0" max="1" matInput required
                           (change)="updateObjectAndRefresh(street, 'fattoreManutenzione', $event.target.value, 'Number')"
                           [value]="street.fattoreManutenzione">
                </mat-form-field>
                <mat-form-field floatLabel="auto" appearance="fill">
                    <mat-label>{{ 'streets.notes' | translate}}</mat-label>
                    <textarea matInput (change)="updateObjectAndRefresh(street, 'note', $event.target.value, 'String')"
                              rows="6">{{street.note}}</textarea>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>{{'streets.tipologiaAreaIlluminata.title'|translate}}</mat-label>
                    <mat-select [value]="street['tipologiaAreaIlluminata']"
                                (selectionChange)="updateObjectAndRefresh(street, 'tipologiaAreaIlluminata', $event.value)">
                        <mat-option *ngFor="let tipology of tipologiaAreaIlluminata"
                                    [value]="tipology">{{getTraduction('streets.tipologiaAreaIlluminata.', tipology)}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </app-form-group-template>
            <app-form-group-template title="{{ 'streets.layout' | translate}}">
                <mat-form-field floatLabel="auto" appearance="fill">
                    <mat-label>{{ 'streets.interdistance' | translate}}</mat-label>
                    <input type="number" min="1" max="300" step=".01" matInput [value]="street.interdistanza" required
                           (change)="updateObjectAndRefresh(street, 'interdistanza', $event.target.value, 'Number')">
                </mat-form-field>
                <mat-menu #addItemMenu>
                    <button type="button" mat-menu-item *ngFor="let type of layoutItemTypes"
                            (click)="addItem(type)">{{'streets.type.' + type | translate}}</button>
                </mat-menu>
                <div class="edit-actions-container">
                    <button type="button" class="fix-centering" mat-mini-fab color="primary"
                            [matTooltip]="'streets.add_item' | translate" [matMenuTriggerFor]="addItemMenu">
                        <mat-icon>add</mat-icon>
                    </button>
                    <button type="button" class="fix-centering" mat-mini-fab color="alert" (click)="resetLayout()"
                            [matTooltip]="'streets.remove_all' | translate">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
                <app-orderable-list (orderChange)="moveItem($event)" [reverse]="true">
                    <ng-container *ngFor="let item of street.layout; let i = index" [ngSwitch]="item.tipo">
                        <mat-accordion style="flex-grow: 1;" *orderable-list-item>
                            <mat-expansion-panel #panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        {{('streets.type.' + item.tipo) | translate}}
                                    </mat-panel-title>
                                    <mat-panel-description>{{'streets.expand_to_edit' | translate}}</mat-panel-description>
                                </mat-expansion-panel-header>
                                <mat-form-field floatLabel="auto" appearance="fill">
                                    <mat-label>{{ 'streets.width' | translate}}</mat-label>
                                    <input type="number" min="0.01" step="0.01" matInput required
                                           [value]="item.larghezza"
                                           (change)="updateObjectAndRefresh(item, 'larghezza', $event.target.value, 'Number')">
                                </mat-form-field>
                                <ng-container *ngSwitchCase="StreetLayoutItemType.carreggiata">
                                    <mat-form-field floatLabel="auto" appearance="fill">
                                        <mat-label>{{'streets.classification' | translate}}</mat-label>
                                        <mat-select [value]="item['categoriaIlluminotecnica']" required
                                                    (selectionChange)="updateObjectAndRefresh(item, 'categoriaIlluminotecnica', $event.value)">
                                            <mat-option *ngFor="let category of roadwayCategories"
                                                        [value]="category">{{category}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field floatLabel="auto" appearance="fill">
                                        <mat-label>{{ 'streets.lanes' | translate}}</mat-label>
                                        <input type="number" min="1" max="10" step="1" matInput required
                                               [value]="item['numeroCorsie']"
                                               (change)="updateObjectAndRefresh(item, 'numeroCorsie', $event.target.value, 'Number')">
                                    </mat-form-field>
                                </ng-container>
                                <mat-form-field floatLabel="auto" appearance="fill"
                                                *ngSwitchCase="StreetLayoutItemType.marciapiede">
                                    <mat-label>{{'streets.classification' | translate}}</mat-label>
                                    <mat-select [value]="item['categoriaIlluminotecnica']" required
                                                (selectionChange)="updateObjectAndRefresh(item, 'categoriaIlluminotecnica', $event.value)">
                                        <mat-option *ngFor="let category of sidewalkCategories"
                                                    [value]="category">{{category}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field floatLabel="auto" appearance="fill"
                                                *ngSwitchCase="StreetLayoutItemType.parcheggio">
                                    <mat-label>{{'streets.classification' | translate}}</mat-label>
                                    <mat-select [value]="item['categoriaIlluminotecnica']" required
                                                (selectionChange)="updateObjectAndRefresh(item, 'categoriaIlluminotecnica', $event.value)">
                                        <mat-option *ngFor="let category of parkingCategories"
                                                    [value]="category">{{category}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field floatLabel="auto" appearance="fill"
                                                *ngSwitchCase="StreetLayoutItemType.pistaciclabile">
                                    <mat-label>{{'streets.classification' | translate}}</mat-label>
                                    <mat-select [value]="item['categoriaIlluminotecnica']" required
                                                (selectionChange)="updateObjectAndRefresh(item, 'categoriaIlluminotecnica', $event.value)">
                                        <mat-option *ngFor="let category of parkingCategories"
                                                    [value]="category">
                                            {{category}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-action-row>
                                    <button type="button" mat-button color="alert" (click)="deleteItem(item)"
                                            [matTooltip]="'delete' | translate">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </mat-action-row>
                                <button *ngIf="panel.expanded" type="submit"
                                        style="position: absolute; opacity: 0; pointer-events: none;"
                                        (click)="$event.preventDefault(); panel.close()"></button>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </ng-container>
                </app-orderable-list>
            </app-form-group-template>
            <app-form-group-template title="{{ 'streets.lamps' | translate}}">
                <mat-menu #addLampMenu>
                    <button type="button" mat-menu-item
                            (click)="addLamp()">{{'streets.add_one_lamp' | translate}}</button>
                    <ng-container *ngIf="roads.length > 1">
                        <button type="button" *ngFor="let key of lampPresetsKeys" [matMenuTriggerFor]="chooseRoadMenu"
                                [matMenuTriggerData]="{'key': key}"
                                mat-menu-item>{{'streets.lamp_presets.' + key | translate}}</button>
                    </ng-container>
                    <ng-container *ngIf="roads.length === 1">
                        <button type="button" *ngFor="let key of lampPresetsKeys"
                                (click)="addPreset(key, reversedRoads[0])"
                                mat-menu-item>{{'streets.lamp_presets.' + key | translate}}</button>
                    </ng-container>
                </mat-menu>
                <mat-menu #chooseRoadMenu>
                    <ng-template matMenuContent let-key="key">
                        <button type="button" *ngFor="let road of reversedRoads; let i = index" mat-menu-item
                                (click)="addPreset(key, road)">{{'streets.roadway' | translate}} {{i + 1}}</button>
                    </ng-template>
                </mat-menu>
                <div class="edit-actions-container">
                    <button type="button" class="fix-centering" mat-mini-fab color="primary"
                            [matTooltip]="'streets.add_lamp' | translate" [matMenuTriggerFor]="addLampMenu">
                        <mat-icon>add</mat-icon>
                    </button>
                    <button type="button" class="fix-centering" mat-mini-fab color="alert" (click)="resetLamps()"
                            [matTooltip]="'streets.remove_all' | translate">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
                <mat-accordion style="display: block; margin-bottom: .5em;"
                               *ngFor="let lamp of street.lampade; let i = index">
                    <mat-expansion-panel #panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{('streets.lamp' | translate) + ' ' + (i + 1)}}
                            </mat-panel-title>
                            <mat-panel-description>{{'streets.expand_to_edit' | translate}}</mat-panel-description>
                        </mat-expansion-panel-header>
                        <mat-form-field floatLabel="auto" appearance="fill">
                            <mat-label>{{ 'streets.origin_x' | translate}}</mat-label>
                            <input type="number" min="0" [attr.max]="street.interdistanza" step=".01" matInput required
                                   [value]="lamp.origine_x"
                                   (change)="updateObjectAndRefresh(lamp, 'origine_x', $event.target.value, 'Number')">
                        </mat-form-field>
                        <mat-form-field floatLabel="auto" appearance="fill">
                            <mat-label>{{ 'streets.origin_y' | translate}}</mat-label>
                            <input type="number" min="0" [attr.max]="maxY" step=".01" matInput required
                                   [value]="lamp.origine_y"
                                   (change)="updateObjectAndRefresh(lamp, 'origine_y', $event.target.value, 'Number')">
                        </mat-form-field>
                        <mat-form-field floatLabel="auto" appearance="fill">
                            <mat-label>{{ 'streets.height' | translate}}</mat-label>
                            <input type="number" min=".1" step=".1" matInput required [value]="lamp.altezza"
                                   (change)="updateObjectAndRefresh(lamp, 'altezza', $event.target.value, 'Number')">
                        </mat-form-field>
                        <mat-form-field floatLabel="auto" appearance="fill">
                            <mat-label>{{ 'streets.outreach' | translate}}</mat-label>
                            <input type="number" min="0" [attr.max]="maxY" step=".01" matInput required
                                   [value]="lamp.sbraccio"
                                   (change)="updateObjectAndRefresh(lamp, 'sbraccio', $event.target.value, 'Number')">
                        </mat-form-field>
                        <mat-form-field floatLabel="auto" appearance="fill">
                            <mat-label>{{ 'streets.rotation' | translate}}</mat-label>
                            <input type="number" min="-360" max="360" step="1" matInput required
                                   [value]="lamp.angoloRotazione"
                                   (change)="updateObjectAndRefresh(lamp, 'angoloRotazione', $event.target.value, 'Number')">
                        </mat-form-field>
                        <mat-form-field floatLabel="auto" appearance="fill">
                            <mat-label>{{ 'streets.tilt' | translate}}</mat-label>
                            <input type="number" min="-360" max="360" step="1" matInput required
                                   [value]="lamp.angoloInclinazione"
                                   (change)="updateObjectAndRefresh(lamp, 'angoloInclinazione', $event.target.value, 'Number')">
                        </mat-form-field>
                        <mat-action-row>
                            <button type="button" mat-button color="alert" (click)="deleteLamp(lamp)"
                                    [matTooltip]="'delete' | translate">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </mat-action-row>
                        <button *ngIf="panel.expanded" type="submit"
                                style="position: absolute; opacity: 0; pointer-events: none;"
                                (click)="$event.preventDefault(); panel.close()"></button>
                    </mat-expansion-panel>
                </mat-accordion>
            </app-form-group-template>
        </fieldset>
    </form>
</app-template-form>
