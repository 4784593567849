import {Component, OnInit, Inject} from '@angular/core';
import {MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import {className} from 'src/app/models/Models';
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";


export interface numeroQuadro {
    name: string;
}

export interface typesArredi {
    key: string;
    path: string;
    traduction:string
}

@Component({
    selector: 'app-select-new-element',
    templateUrl: './select-new-element.component.html',
    styleUrls: ['./select-new-element.component.scss']
})
export class SelectNewElementComponent implements OnInit {
    public nomiClasse = className;
    public visualizzaBottone = true;
    public formType: UntypedFormGroup

    constructor(
        public dialogRef: MatDialogRef<SelectNewElementComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { message, numeriQuadro, typesArredoUrbano: typesArredi },
        private fb: UntypedFormBuilder
    ) {
        // this.spliceSigleNumeri(data.numeriQuadro);
        this.formType = this.fb.group({
            type: [{
                value: '',
                disabled: false
            }, []],
        })
    }



    ngOnInit() {
    }

    typeSelect = '';


    // onNoClick(): void {
    //     this.dialogRef.close();
    // }
    // visible = true;
    // selectable = true;
    // removable = true;
    // removableTypesArredi = false;
    // addOnBlur = true;
    // numeriQuadro: numeroQuadro[];
    // readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    //
    //
    // addNumeroQuadro(event: MatChipInputEvent): void {
    //     const input = event.input;
    //     const value = event.value;
    //     this.dialogRef.close([this.nomiClasse.circuiti, value]);
    // }
    //
    // removeNumeroQuadro(numeroQuadro: numeroQuadro): void {
    //     const index = this.numeriQuadro.indexOf(numeroQuadro);
    //     if (index >= 0) {
    //         this.numeriQuadro.splice(index, 1);
    //     }
    // }

    // changeInput(event) {
    //     console.log(event)
    // }


    // private spliceSigleNumeri(quadri) {
    //     let quadroSigla = [];
    //     quadri.forEach(element => {
    //         if (!quadroSigla.includes(element.slice(0, 2))) {
    //             quadroSigla.push(element.slice(0, 2));
    //         }
    //     });
    //     this.numeriQuadro = quadroSigla;
    // }

    // selectMe(numeroQuadro) {
    //     // console.log(numeroQuadro);
    // }
    //
    // addTypeArredoUrbano(key): void {
    //     this.dialogRef.close([this.nomiClasse.arredoUrbano, {tipologia: key}]);
    // }



    // sendType() {
    //     if (this.formType && this.formType.get('type')) {
    //         const key = typeof this.formType.get('type').value == "string" && this.formType.get('type').value.trim().length > 0 ? this.formType.get('type').value : 'arredoGenerico'
    //         this.addTypeArredoUrbano(key)
    //     }
    // }
    //
    // get typesArredoUrbano(): typesArredi {
    //     return this.data.typesArredoUrbano
    // }
    //
    // get disableButtonSendType() {
    //     return isNotNullOrUndefined(this.formType) && isNotNullOrUndefined(this.formType.get('type')) && (this.formType.get('type').disabled) || (typeof this.formType.get('type').value == "string" && this.formType.get('type').value.trim().length == 0)
    // }

}
