import {Injectable} from '@angular/core';
import {tabMaxNumber} from "../../models/Models";

@Injectable({
    providedIn: 'root'
})
export class AddingTabInComponentService {

    constructor() {
    }

    public addingElement(elementList: { objectId, titolo, priority }[], newElement: { objectId, titolo, priority }): {openedList:{ objectId, titolo, priority }[],index} {
        const index = elementList.findIndex(report => report.objectId == newElement.objectId);
        newElement.priority = this.getColor(newElement.priority);
        newElement.titolo = this.getTitle(newElement.titolo);
        // this.reportsList.splice(indice, 1);
        if (index<0) {
            elementList = [newElement].concat(elementList);
            if (elementList.length >= tabMaxNumber) {
                elementList.pop();
            }
        }
        return {openedList:elementList,index:index};
    }

    public removingElement(elementList: { objectId, titolo, priority }[], removeElement: { objectId, titolo, priority }): { objectId, titolo, priority }[] {
        return elementList.filter((report) => report.objectId !== removeElement.objectId);
    }

    public getColor(priority): string|null {
        switch (priority) {
            case 0:
                return '#00FF00';
            case 1:
                return 'yellow'
            case 2:
                return 'red'
            default:
                return null;
        }
    }

    public getTitle(title: string): string {
        if (title) {
            return title
        } else {
            return 'noTitle'
        }
    }
}
