import {Component, OnInit, Input, OnChanges, ViewChild, ElementRef, Output, EventEmitter} from '@angular/core';
import {StreetLayoutItem, StreetLamp, StreetLayoutItemType} from 'src/app/providers/services/streets.service';
import {TranslateService} from '@ngx-translate/core';
import {isNotNullOrUndefined} from "../../../models/Models";

@Component({
    selector: 'app-street-preview',
    templateUrl: './street-preview.component.html',
    styleUrls: ['./street-preview.component.scss']
})
export class StreetPreviewComponent implements OnInit, OnChanges {

    @Input()
    padding: number = .5;

    @Input()
    layoutItems: StreetLayoutItem[] = [];
    @Input()
    lamps: StreetLamp[] = [];
    @Input()
    interdistance: number = 100;
    @Input()
    generateLabelLamp: boolean = false;
    @Input()
    generateLabelLayout: boolean = false;
    @Input()
    generateMisureInizialAndFinal: boolean = false;
    @Input()
    printPreview: boolean = false;
    @Input()
    distanceInizialPosition: number = 0;


    @Input()
    widthSVG;
    @Input()
    heigthSVG;

    @Output()
    lampClick: EventEmitter<number> = new EventEmitter();

    @ViewChild('svg')
    svg: ElementRef<SVGElement>;

    viewBoxHeight: number = 50;

    get viewBoxWidth() {
        return this.interdistance;
    }

    get viewBoxWidthPadding() {
        return this.viewBoxWidth + 2 * this.padding;
    }

    get StreetLayoutItemTypes() {
        return StreetLayoutItemType;
    }

    constructor(private translateService: TranslateService) {
    }

    ngOnInit() {
    }

    getNameLayout(index) {
        const tipo = this.layoutItems[index].tipo;
        let i = 0;
        let numberForType = 1;
        while (i < index) {
            if (tipo === this.layoutItems[i].tipo) {
                numberForType++;
            }
            i++;
        }
        const tipoTrad = this.translateService.instant('fotometria.' + tipo);
        return tipoTrad + ' ' + numberForType;
    }

    sameOrder(arr1: any[], arr2: any[]) {
        if (arr1.length !== arr2.length) {
            return false;
        }
        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) {
                return false;
            }
        }
        return true;
    }

    ngOnChanges() {
        this.viewBoxHeight = this.layoutItems.reduce((a, i) => a + i.larghezza, 0);
    }

    private convertToRad(angle) {
        const angleConver = angle * Math.PI / 180;
        return Math.round(angleConver * 100) / 100;
    }

    get viewBox() {
        const addingForInitialPosition = (this.generateMisureInizialAndFinal) ? 10 : 0;
        let yFinale = this.viewBoxHeight;
        let yIniziale = 0;
        if (this.generateLabelLamp || this.printPreview) {
            // se le lampade si trovano al di fuori del bordo inferiore o superiore della strada disegnata viene aumentata la viewBox
            let minY = 0;
            let maxY = this.viewBoxHeight;
            let sbraccioMax = 0;
            let sbraccioMin = 0;
            this.lamps.forEach((lamp) => {
                const lunghezzaTotale = lamp.sbraccio * Math.cos(this.convertToRad(lamp.angoloRotazione)) + lamp.origine_y;
                if (maxY < lunghezzaTotale || !isNotNullOrUndefined(maxY)) {
                    maxY = lunghezzaTotale;
                    sbraccioMax = Math.abs(lamp.sbraccio * Math.cos(this.convertToRad(lamp.angoloRotazione)));
                }
                if (minY > lunghezzaTotale || !isNotNullOrUndefined(minY)) {
                    minY = lunghezzaTotale;
                    sbraccioMin = Math.abs(lamp.sbraccio * Math.cos(this.convertToRad(lamp.angoloRotazione)));
                }
            });
            if (maxY <= this.viewBoxHeight) {
                maxY = this.viewBoxHeight;
            }
            if (minY >= 0) {
                minY = 0;
            }
            yIniziale = minY - 2;
            yFinale = maxY + sbraccioMax + sbraccioMin + 3;
        }
        // viene aumentata la larghezza della viewbox in modo tale da contenere le leggenda
        return `${-this.padding} ${yIniziale} ${this.viewBoxWidthPadding} ${yFinale + addingForInitialPosition }`;
    }

    getYByIndex(currentIndex) {
        let y = 0;
        for (let i = 0; i < currentIndex; i++) {
            y += this.layoutItems[i].larghezza;
        }
        return y;
    }

    isQuasiZero(number: number): boolean {
        return number <= Number.EPSILON;
    }

    isQuasiEqual(number1: number, number2: number): boolean {
        return Math.abs(number1 - number2) <= Number.EPSILON;
    }

    isRound(lamp: StreetLamp) {
        if (!this.isQuasiZero(lamp.sbraccio)) {
            return false;
        }
        for (let i = 0; i < this.layoutItems.length; i++) {
            if (
                this.layoutItems[i].tipo === StreetLayoutItemType.carreggiata &&
                this.isQuasiEqual(this.getYByIndex(i) + this.layoutItems[i].larghezza / 2, lamp.origine_y)
            ) {
                return true;
            }
        }
        return false;
    }

    onLampClick(index: number) {
        this.lampClick.emit(index);
    }

    textCoordx(x) {
        if (x < 0) {
            return 2;
        } else {
            return x;
        }
    }

    textCoordy(y, lamp) {
        return y - lamp.sbraccio * Math.cos(this.convertToRad(lamp.angoloRotazione));
    }

    rectCorrdx(x) {
        if (x < 0) {
            return 1;
        } else {
            return x;
        }
    }

    rectCorrdy(y, lamp) {
        return y - lamp.sbraccio * Math.cos(this.convertToRad(lamp.angoloRotazione));
    }

    private get changeSize() {
        return this.viewBoxWidth / (this.viewBoxHeight / this.layoutItems.length) < 20;
    }

    get fontSize() {
        if (this.changeSize) {
            return 1;
        } else {
            return 2;

        }
    }

    get widthRectangle() {
        if (this.changeSize) {
            return 8;
        } else {
            return 14;
        }
    }

    get heigthRectangle() {
        if (this.changeSize) {
            return 1.5;
        } else {
            return 2.5;
        }
    }


    getNameLamp(i) {
        if (isNotNullOrUndefined(this.lamps[i].name)) {
            return this.lamps[i].name;
        } else {
            return i + 1;
        }
    }

}
