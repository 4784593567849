<mat-dialog-content [formGroup]="formPage">
    <mat-card style="margin-bottom: 5px">
        <mat-card-subtitle>
            {{'fileManager.info_4'|translate}}
        </mat-card-subtitle>
        <mat-card-content>
            <mat-radio-group formControlName="classNames">
                <mat-radio-button [value]="className.puntiLuce">
                    {{className.puntiLuce|translate}}
                </mat-radio-button>
                <mat-radio-button [value]="className.circuiti">
                    {{className.circuiti|translate}}
                </mat-radio-button>
                <mat-radio-button [value]="className.puntiLuce+className.circuiti">
                    {{className.puntiLuce|translate}}, {{className.circuiti|translate}}
                </mat-radio-button>
            </mat-radio-group>
        </mat-card-content>
    </mat-card>
    <mat-card class="info" style="margin-bottom: 5px">
        <mat-card-subtitle>
            {{'fileManager.info_5'|translate}}
        </mat-card-subtitle>
        <mat-card-content>
            <p> {{'fileManager.info_1'|translate}}</p>
            <p>  {{'fileManager.info_puntiLuce_1'|translate}}</p>
            <p>   {{'fileManager.info_puntiLuce_2'|translate}}</p>
            <p>   {{'fileManager.info_circuiti_1'|translate}}</p>
            <p>  {{'fileManager.info_circuiti_2'|translate}}</p>
        </mat-card-content>
    </mat-card>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-flat-button color="accent" [mat-dialog-close]="undefined">
        <mat-icon>clear</mat-icon>
    </button>
    <button mat-flat-button color="primary" (click)="sendResponse()">
        <mat-icon>check</mat-icon>
    </button>
</mat-dialog-actions>
