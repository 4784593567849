import {Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef} from '@angular/core';
import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {BehaviorSubject, Observable, Subscription} from "rxjs";

@Component({
    selector: 'app-long-list-infinity-scroll',
    templateUrl: './long-list-infinity-scroll.component.html',
    styleUrls: ['./long-list-infinity-scroll.component.scss']
})
export class LongListInfinityScrollComponent<T> implements OnInit, OnChanges {
    @Input() itemTemplate: TemplateRef<any>;
    @Input() items: T[] = []
    @Input() itemSize = 40;

    dynamicDataSources: MyDataSource<T>;

    constructor() {
    }

    ngOnInit(): void {

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.items != null) {
            this.dynamicDataSources = new MyDataSource(this.items, 10);
        }
    }
}

export class MyDataSource<T> extends DataSource<T | undefined> {
    constructor(data, pagesize = 100) {
        super();
        this.cachedData = data;
        this._pageSize = pagesize;
    }

    private _length;
    private _pageSize;
    private _cachedData = [];
    private _fetchedPages = new Set<number>();
    private _dataStream;
    private readonly _subscription = new Subscription();

    public set cachedData(value) {
        this._cachedData = value;
        this._dataStream = new BehaviorSubject<(T | undefined)[]>(this._cachedData);
        this._length = this._cachedData.length;
    }

    connect(collectionViewer: CollectionViewer): Observable<(T | undefined)[]> {
        this._subscription.add(
            collectionViewer.viewChange.subscribe(range => {
                const startPage = this._getPageForIndex(range.start);
                const endPage = this._getPageForIndex(range.end - 1);
                for (let i = startPage; i <= endPage; i++) {
                    this._fetchPage(i);
                }
            }),
        );
        return this._dataStream;
    }

    disconnect(): void {
        this._subscription.unsubscribe();
    }

    private _getPageForIndex(index: number): number {
        return Math.floor(index / this._pageSize);
    }

    private _fetchPage(page: number) {
        if (this._fetchedPages.has(page)) {
            return;
        }
        this._fetchedPages.add(page);

        // Use `setTimeout` to simulate fetching data from server.
        const cacheData = [...this._cachedData];
        const start = page * this._pageSize;
        let last = start + this._pageSize;
        if (last > this._length) {
            last = this._length;
        }
        // can be added in asynchronously
        cacheData.splice(
            start,
            this._pageSize,
            ...this._cachedData.slice(start, last),
        )
        this._dataStream.next(cacheData);
    }
}