import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {UserService} from "../services/user.service";
import {ProjectService} from "../services/project.service";

@Injectable({
    providedIn: 'root'
})
export class LoggedGuard implements CanActivate {

    constructor(
        private userService: UserService,
        private projectService: ProjectService,
        private router: Router
    ) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        if (this.userService.isLogged()) {
            this.router.navigate(['/']);
            return false;
        } else {
            return true;
        }
    }
}
