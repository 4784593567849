<div class="map-full">
    <google-map height="100%" width="100%"
                (mapInitialized)="mapReady($event)"
                (mapClick)="mapClick($event)"
                (mapDblclick)="mapDbClick($event)"
                (boundsChanged)="mapBoundChange()"
    >
        <ng-container>
            <ng-container
                    *ngIf="(markerLightPoints$|async) as lightPointsCluster">
                <ng-container>
                    <ng-container *ngFor="let puntoLuce of lightPointsCluster.elementsInMap;trackBy:trackRowsPuntiLuce">
                        <map-marker
                            #marker="mapMarker"
                            [options]="{draggable:markerSpostabile}"
                            [position]="puntoLuce['position']"
                            (mapClick)="openInfoWindowPuntoLuce(marker,puntoLuce)"
                            (mapDragstart)="dragStart(puntoLuce)"
                            (mapDragend)="dragEnd(puntoLuce,$event)"
                            [icon]="puntoLuce.icon"
                            [label]="puntoLuce.labelMarker"
                        >
                        </map-marker>
                    </ng-container>
                    <ng-container *ngFor="let cluster of lightPointsCluster.clustersInMap">
                        <!--                        <map-circle-->
                        <!--                                    [center]="cluster.center"-->
                        <!--                                    [options]="{fillColor:cluster.color,fillOpacity:0.7,strokeOpacity:0}"-->
                        <!--                                    [radius]="cluster.radius"-->
                        <!--                                    (circleClick)="clusterClick(cluster)"-->
                        <!--                        >-->
                        <!--                        </map-circle>-->
                        <map-marker
                                (mapClick)="clusterClick(cluster)"
                                [position]="cluster.center"
                                [icon]="cluster.icon"
                                [options]="{zIndex:1100}"

                        >
                        </map-marker>
                        <!--                        <map-rectangle [bounds]="cluster.bounds"-->
                        <!--                                       [options]="{strokeOpacity:1,strokeColor:'#FFFFFF'}"-->
                        <!--                                       (rectangleClick)="clusterClick(cluster)"-->

                        <!--                        >-->
                        <!--                        </map-rectangle>-->
                        <!--                        <map-circle [radius]="cluster.radius"-->
                        <!--                                    [options]="{fillColor:cluster.color,strokeOpacity:0.2,strokeColor:'#FFFFFF'}"-->
                        <!--                                    (circleClick)="clusterClick(cluster)"-->
                        <!--                                    [center]="cluster.center"-->

                        <!--                        >-->
                        <!--                        </map-circle>-->
                    </ng-container>
                </ng-container>
            </ng-container>
            <ng-container
                    *ngIf="(markerCircuits$|async) as circuitsCluster">
                <ng-container>
                    <ng-container *ngFor="let circuito of circuitsCluster.elementsInMap;">
                        <map-marker
                            #marker="mapMarker"
                            [options]="{draggable:markerSpostabile,zIndex:1099}"
                            [position]="circuito.position"
                            (mapClick)="openInfoWindowCircuito(marker,circuito)"
                            (mapDragstart)="dragStart(circuito)"
                            (mapDragend)="dragEnd(circuito,$event)"
                            [icon]="circuito.icon"
                            [label]="circuito.labelMarker"
                        >
                        </map-marker>
                    </ng-container>
                </ng-container>
            </ng-container>
            <ng-container
                    *ngIf="(markerArredoUrbano$|async) as arrediCluster">
                <ng-container>
                    <ng-container *ngFor="let arredo of arrediCluster.elementsInMap;trackBy:trackRowsArrediUrbani">
                        <map-marker
                            #marker="mapMarker"
                            [options]="{draggable:markerSpostabile}"
                            [position]="arredo.position"
                            (mapClick)="openInfoWindowArredoUrbano(marker,arredo)"
                            (mapDragstart)="dragStart(arredo)"
                            (mapDragend)="dragEnd(arredo,$event)"
                            [icon]="arredo.icon"
                            [label]="arredo.labelMarker"
                        >
                        </map-marker>
                    </ng-container>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="openedRuler&&(rulerDistancesIsSet$|async)">
                <ng-container *ngIf="(markerRulerDistances$|async) as rulersDistances">
                    <map-polyline
                            *ngFor="let polyLinePoint of rulersDistances.polyLines"
                            [options]="{strokeColor:polyLinePoint.color}"
                            [path]="polyLinePoint.vertices"
                    >
                    </map-polyline>
                    <map-marker *ngFor="let marker of rulersDistances.markers"
                                #MapMarker="mapMarker"
                                [position]="marker.position"
                                [icon]="rulerIcon"
                                (mapMouseover)="clickMarkerRuler(marker,MapMarker)"
                    >
                    </map-marker>
                </ng-container>
            </ng-container>
        </ng-container>
        <map-info-window (closeclick)="closeInfoWindow()">
            <ng-container *ngIf="infoWindowValue$|async as infoWindowValue">
                <h4 *ngIf="infoWindowValue.traductions!=null">
                    <ng-container
                            *ngFor="let traducion of infoWindowValue.traductions;let first = first">
                        <ng-container *ngIf="!first">
                            /
                        </ng-container>
                        {{traducion|translate}}
                    </ng-container>
                </h4>
                <div *ngIf="infoWindowValue.values!=null">
                    <ng-container *ngFor="let value of infoWindowValue.values;let first = first">
                        <ng-container *ngIf="!first">
                            /
                        </ng-container>
                        {{value}}
                    </ng-container>
                </div>
            </ng-container>
        </map-info-window>
        <ng-container *ngIf="myPosition&&myPosition.isEnabled">
            <map-marker
                    [position]="myPosition"
                    [icon]="myPosition.icon"
            >
            </map-marker>
        </ng-container>
        <ng-container *ngIf="confiniAmministrativi">
            <map-polyline *ngFor="let confine of confiniAmministrativi"
                          [options]="{strokeColor:'#01B9FF'}"
                          [path]="confine">
            </map-polyline>
        </ng-container>
        <ng-container *ngIf="openedInterdistances">
            <ng-container *ngIf="markerInterdistances$|async as markerInterdistances">
                <map-polyline *ngFor="let interdistance of markerInterdistances"
                              [options]="{strokeColor:interdistance.color}"
                              [path]="interdistance.vertices"
                >
                </map-polyline>

            </ng-container>
        </ng-container>
        <ng-container *ngIf="electricLinesIsSet$|async">
            <ng-container *ngIf="markerElectricLines$|async as markerPolyLine">
                <ng-container *ngIf="markerPolyLine.polyLines">
                    <map-polyline *ngFor="let line of markerPolyLine.polyLines"
                                  [options]="line|convertValueByPredicateFunction:getOptionElectricLines:selectedElectricLines"
                                  [path]="line.vertices"
                                  (polylineMouseover)="lineMouseOver($event,line)"
                                  (polylineMouseout)="lineMouseOut($event,line)"
                                  (polylineMousedown)="lineMouseDown($event,line,actionLine.addPoint)"
                                  (polylineMouseup)="lineMouseUp($event)"
                    >
                    </map-polyline>
                </ng-container>
                <ng-container *ngIf="markerPolyLine.markers">
                    <map-marker *ngFor="let marker of markerPolyLine.markers"
                                [options]="{draggable:repositioningElectricLines}"
                                [position]="marker.position"
                                [icon]="(marker|convertValueByPredicateFunction:getOptionMarkerElectricLines:selectedElectricLines:getIconMarkerRuler$)|async"
                                (mapClick)="doubleClickPointInLine($event,marker)"
                                (mapDragend)="dragEndElectricLines($event,marker)"
                    >
                    </map-marker>
                </ng-container>

            </ng-container>
        </ng-container>
        <ng-container *ngIf="drawedElectricLinesIsSet$|async">
            <ng-container *ngIf="markerDrawedElectricLines$|async as markerPolyLine">
                <map-polyline *ngIf=" markerPolyLine.polyLine as line"
                              [options]="line|convertValueByPredicateFunction:getOptionElectricLines:selectedElectricLines"
                              [path]="line.vertices"
                              (polylineMouseover)="lineMouseOver($event,line)"
                              (polylineMouseout)="lineMouseOut($event,line)"
                              (polylineMousedown)="lineMouseDown($event,line,actionLine.addPoint)"
                              (polylineMouseup)="lineMouseUp($event)"
                >
                </map-polyline>
                <ng-container *ngIf="markerPolyLine.markers">
                    <map-marker *ngFor="let marker of markerPolyLine.markers;let first=first"
                                [position]="marker.position"
                                [options]="{draggable:!(first && repositioningElectricLines)}"
                                [icon]="(marker|convertValueByPredicateFunction:getOptionMarkerDrawedElectricLines:getIconMarkerRuler$)|async"
                                (mapDragend)="dragEndLine(marker,$event)"
                    >
                    </map-marker>
                </ng-container>
            </ng-container>
        </ng-container>
    </google-map>
</div>
<mat-card style="position:absolute;bottom: 70px;right: 100px;background: white"
          *ngIf="rulerDistancesTotalLength$|async as rulerDistancesTotalLength">
    <mat-card-content style="padding: 0">
        <ng-container *ngIf="expandListRulers;else Maximize">

            <mat-list>
                <div mat-subheader>
                    {{'dashboard.button.interdistancesTotal'|translate}}
                    <button mat-icon-button style="margin-top: -25px" (click)="clickExpandRulerList()">
                        <mat-icon>minimize</mat-icon>
                    </button>
                </div>
                <ng-container *ngFor="let distance of rulerDistancesTotalLength">
                    <mat-list-item style="margin-bottom: 5px">
                        <div matListItemIcon [ngStyle]="{background:distance.color}"></div>
                        <div matListItemTitle>{{distance.length}}</div>
                    </mat-list-item>
                </ng-container>
            </mat-list>
        </ng-container>
        <ng-template #Maximize>
            <button mat-icon-button (click)="clickExpandRulerList()" color="primary">
                <mat-icon>open_in_full</mat-icon>
            </button>
        </ng-template>
    </mat-card-content>
</mat-card>
