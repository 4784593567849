import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-info-report',
    templateUrl: './info-report.component.html'
})
export class InfoReportComponent implements OnInit {

    constructor(
    ) {
    }

    ngOnInit() {
    }

}
