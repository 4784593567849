import {Injectable} from '@angular/core';
import {DocumentsFolderParse} from "../../models/DocumentsFolder.Parse";
import {MoveToFolderComponent} from "../../components/pop-up-move-in-folder/move-to-folder/move-to-folder.component";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {
    NavigateInFileAndFolderComponent
} from "../../components/pop-up-move-in-folder/navigate-in-file-and-folder/navigate-in-file-and-folder.component";

@Injectable({
    providedIn: 'root'
})
export class MoveFolderPopUpService {

    constructor(private dialog: MatDialog) {
    }


    public openMoveToFolder(folders: DocumentsFolderParse[], currentFolder: DocumentsFolderParse, backFolders: DocumentsFolderParse[], foldersToMove: DocumentsFolderParse[], numberOfFiles: number) {
        const width = 500;
        const dialogRef = this.dialog.open(MoveToFolderComponent, {
            width: width + 'px',
            maxWidth: '100%',
            data: {folders, currentFolder, numberOfFiles, backFolders, foldersToMove}
        });
        return dialogRef.afterClosed();
    }

    public openMoveToFoldersFiles() {
        const width = 500;
        const height = 800;
        const right = window.innerWidth / 2 - width / 2 + 20;
        const bottom = window.innerHeight / 2 - height / 2;
        const dialogRef = this.dialog.open(NavigateInFileAndFolderComponent, {
            width: width + 'px',
            maxWidth: '100%',
            data: {}
        });
        return dialogRef.afterClosed();
    }
}
