<!-- non rimuovere da qui altrimenti linnerhtml non lo riconosce per le stampe-->
<div [ngStyle]="{height:height+'mm'}"
     style="width: 201mm;max-width: 201mm;">
    <header style="
   display: -webkit-box;
display: -moz-box;
display: -webkit-flex;
display:-ms-flexbox;
display:-webkit-flex;
display:flex;
        font-family: Roboto, 'Helvetica Neue', sans-serif;
  background: white;
  flex-wrap: wrap;
  -webkit-flex-flow: column;
        -ms-flex-direction: column;
       -webkit-flex-wrap: wrap;
       -ms-flex-wrap: wrap;
       flex-direction: column;
  flex-flow: row;
  align-items: center;
  justify-content: space-around;
  width: 200mm;
  height: 20mm;
  border-bottom: 1px solid black;
" *ngIf="!!page&&page!==0">
        <img *ngIf="!!logoAzienda" class="img" [src]="logoAzienda" style="height: 9mm;" alt="logo">
        <h5 *ngIf="!!nomeAzienda">{{nomeAzienda}}</h5>
        <h3 *ngIf="projectName">{{projectName}}</h3>
        <h5 *ngIf="nowDate">{{nowDate|customDate :'shortDate'|async}}</h5>
        <h5 style="display: inline-flex;" *ngIf="page">{{'pageNumber'|translate}}&#160;
            <div class="numberPage">{{page}}</div>
        </h5>
        <img class="img" src="/assets/logo.png" style="height: 5mm;" alt="HUNA">
    </header>
    <div style="
  display: -webkit-box;
display: -moz-box;
display: -webkit-flex;
display:-ms-flexbox;
display:-webkit-flex;
display:flex;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
  background: white;
  align-items: start;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 200mm;
  max-width: 200mm;
  height: 276mm;
">
        <ng-content>

        </ng-content>
    </div>
</div>
