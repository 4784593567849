<div class="flex-center-width100 flex-flow-row" (clickOutside)="clickOutside()" style="max-height: 100%;overflow: scroll">
    <ng-container *ngIf="orderRendering as orderTemplate">
        <div [hidden]="orderTemplate.searchBar.hidden"
             class="w100 margin-10"
             [ngStyle]="{'order':orderTemplate.searchBar.order}">
            <ng-template [ngTemplateOutlet]="searchBar"></ng-template>
        </div>
        <div [hidden]="orderTemplate.chipsList.hidden"
             [ngStyle]="{'order':orderTemplate.chipsList.order}" class="w100">
            <ng-template [ngTemplateOutlet]="chipsList"></ng-template>
        </div>
        <div class="w100"
             [hidden]="orderTemplate.listSelect.hidden"
             [ngStyle]="{'order':orderTemplate.listSelect.order}">
            <ng-template [ngTemplateOutlet]="selectionList"></ng-template>
        </div>
    </ng-container>
</div>
<form [formGroup]="formGroup">
    <ng-template #searchBar>
        <div class="flex-center-width100">
            <mat-form-field (focusin)="inSearchIn()" >
                <mat-label>{{labelSearch|translate}}</mat-label>
                <input matInput formControlName="name"  [autofocus]="false">
                <button (click)="$event.stopPropagation();clickIcon()" mat-icon-button matSuffix
                        [disabled]="formGroup.disabled">
                    <mat-icon>
                        <ng-container *ngIf="!openList;else closeList">
                            search
                        </ng-container>
                        <ng-template #closeList>
                            clear
                        </ng-template>
                    </mat-icon>
                </button>
            </mat-form-field>
        </div>
    </ng-template>
    <ng-template #selectionList>
        <button mat-stroked-button color="primary" class="w100" (click)="clickMostraNonAttivi()"
                *ngIf="showButtonShowDisabledItem">
            <ng-container *ngIf="(showNotActived$|async)==true;else Hidden">
                {{'hidden_disabled'|translate}}
                <mat-icon>expand_less</mat-icon>
            </ng-container>
            <ng-template #Hidden>
                {{'show_disabled'|translate}}
                <mat-icon>expand_more</mat-icon>
            </ng-template>
        </button>
        <mat-selection-list
                class="w100"
                formControlName="options"
                *ngIf="isSetPossibleValues"
        >
            <div class="flex-start-center flex-flow-row w100">
                <ng-container
                        *ngFor="let value of possibleValues">
                    <div class="item"
                         [ngClass]="{'not-display':(value|convertValueByPredicateFunction:predicateFilterFunction:(filteredName$|async):translateService)||
                         ((showNotActived$|async)!=true&&(value|convertValueByPredicateFunction:predicateIsDisabled:activedPossibleValues:disabledPossibleValues))}">
                        <mat-list-option
                                [disabled]="value.disabled||(value|convertValueByPredicateFunction:predicateIsDisabled:activedPossibleValues:disabledPossibleValues)"
                                [value]="value.objectId">
                            <span style="word-break: break-word;">
                                <ng-container *ngIf="value.traduction;else noTraduction">
                                  {{value.traduction|translate}}
                                </ng-container>
                               <ng-template #noTraduction>
                                 {{value.noTraduction}}
                              </ng-template></span>
                        </mat-list-option>
                    </div>
                </ng-container>
            </div>
        </mat-selection-list>

    </ng-template>
    <ng-template #chipsList>
        <div class="flex-start-center flex-flow-row">
            <div class="chips-child box-shadow-3d background-accent" style="word-break: break-all"
                 *ngFor="let value of( selectedChips$|async)">
                <div class="flex-start-center">
                    <mat-icon (click)="removeToSelect(value)" class="icon-small" style="cursor: pointer">clear
                    </mat-icon>
                    <span style="margin-left: 5px;margin-right: 5px;">
                        <ng-container *ngIf="value.traduction;else noTraduction">
                          {{value.traduction|translate}}
                        </ng-container>
                        <ng-template #noTraduction>
                          {{value.noTraduction}}
                        </ng-template>
                    </span>
                </div>
            </div>
        </div>
    </ng-template>
</form>