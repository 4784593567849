<app-loader [loaderInput]="computing"></app-loader>

<form [formGroup]="form">
    <div style="text-align: center; margin-top: 1em">
        <button mat-flat-button color="primary"
                (click)="runCompute()">{{ 'streets.compute' | translate}}
        </button>
    </div>
    <div class="flex-start-start w100 flex-flow-column"
         *ngIf="strada!=null&&strada.fotometrie!=null&&strada.fotometrie.length>0">
        <div class="flex-start-center w100 flex-flow-row"
        >
            <h3 style="margin: 5px 10px">{{'fotometrie.saved'|translate}}
            </h3>
            <mat-checkbox formControlName="fotometrieSalvate">
            </mat-checkbox>
        </div>
        <div style="margin-left: 15px;" *ngFor="let photometry of strada.fotometrie">
            <app-box-photometry
                    [showHeader]="false"
                    [photometry]="photometry">
            </app-box-photometry>
        </div>
    </div>

    <div class="flex-start-center w100 flex-flow-row">
        <h3 style="margin-left: 10px">{{'fotometrie.favorites'|translate}}
        </h3>
        <app-button-spinner [loading]="load.photometries||load.gruppiFotometrie">
            <button style="margin-left: 20px" mat-mini-fab color="accent"
                    (click)="refreshPhotometriesAndGruopPhotometries()">
                <mat-icon>refresh</mat-icon>
            </button>
        </app-button-spinner>
    </div>
    <div class="flex-center-width100 flex-flow-column">
        <div class="flex-start-center flex-flow-row container-child-width">
            <div class="dimension-favorites" *ngFor="let gruppo of gruppiFotometrie">
                <app-box-group-photometries [name]="gruppo.name"
                                            [photometryNumber]="getNumberPhotometry(gruppo)"
                                            [selected]="isSelectedGruppoFotometria(gruppo)"
                                            (clickCard)="selectGroupPhotometries(gruppo)"
                                            (clickUpdate)="updateGroupPhotometry(gruppo)"
                                            (clickRemove)="removeGroupPhotometries(gruppo)"
                                            [disabled]="load.photometries||load.gruppiFotometrie"
                >
                </app-box-group-photometries>
            </div>
            <div class="dimension-favorites flex-center-width100">
                <button mat-mini-fab color="accent" (click)="createNewFotometry()">
                    <mat-icon>create_new_folder</mat-icon>
                </button>
            </div>
        </div>
    </div>
</form>
<!--<app-template-form title="{{'settings.title' | translate}}">-->
<!--    <form [formGroup]="formGroup" ngNativeValidate>-->
<!--        <fieldset [disabled]="computing">-->
<!--            <app-form-group-template title="{{ 'streets.filter' | translate}}">-->
<!--                <mat-form-field floatLabel="auto" appearance="fill">-->
<!--                    <mat-label>{{ 'streets.photometry' | translate}}</mat-label>-->
<!--                    <input type="text" matInput-->
<!--                           (change)="updateObjectAndFilter(filter, 'nomeFile', $event.target.value, 'String')"-->
<!--                           [value]="filter.nomeFile">-->
<!--                </mat-form-field>-->
<!--                <mat-form-field floatLabel="auto" appearance="fill">-->
<!--                    <mat-label>{{ 'streets.manufacturer' | translate}}</mat-label>-->
<!--                    <input type="text" matInput-->
<!--                           (change)="updateObjectAndFilter(filter, 'produttore', $event.target.value, 'String')"-->
<!--                           [value]="filter.produttore">-->
<!--                </mat-form-field>-->
<!--                <mat-form-field floatLabel="auto" appearance="fill">-->
<!--                    <mat-label>{{ 'streets.family_name' | translate}}</mat-label>-->
<!--                    <input type="text" matInput-->
<!--                           (change)="updateObjectAndFilter(filter, 'nomeFamiglia', $event.target.value, 'String')"-->
<!--                           [value]="filter.nomeFamiglia">-->
<!--                </mat-form-field>-->
<!--                <mat-form-field floatLabel="auto" appearance="fill">-->
<!--                    <mat-label>{{ 'streets.product_name' | translate}}</mat-label>-->
<!--                    <input type="text" matInput-->
<!--                           (change)="updateObjectAndFilter(filter, 'nomeProdotto', $event.target.value, 'String')"-->
<!--                           [value]="filter.nomeProdotto">-->
<!--                </mat-form-field>-->
<!--                <mat-form-field floatLabel="auto" appearance="fill">-->
<!--                    <mat-label>{{ 'streets.lamp_name' | translate}}</mat-label>-->
<!--                    <input type="text" matInput-->
<!--                           (change)="updateObjectAndFilter(filter, 'nomeLampada', $event.target.value, 'String')"-->
<!--                           [value]="filter.nomeLampada">-->
<!--                </mat-form-field>-->
<!--                <mat-form-field floatLabel="auto" appearance="fill">-->
<!--                    <mat-label>{{ 'streets.optics' | translate}}</mat-label>-->
<!--                    <input type="text" matInput-->
<!--                           (change)="updateObjectAndFilter(filter, 'ottica', $event.target.value, 'String')"-->
<!--                           [value]="filter.ottica">-->
<!--                </mat-form-field>-->
<!--                <div>-->
<!--                    <label>{{'streets.power' | translate}}</label>-->
<!--                    <div class="flex-horizontal">-->
<!--                        <mat-form-field floatLabel="auto" appearance="fill">-->
<!--                            <mat-label>{{ 'streets.min' | translate}}</mat-label>-->
<!--                            <input type="number" min="0" step="1" matInput-->
<!--                                   (change)="updateObjectAndFilter(filter.potenza, 'min', $event.target.value === '' ? undefined : window.Number($event.target.value))"-->
<!--                                   [value]="filter.potenza.min">-->
<!--                        </mat-form-field>-->
<!--                        <mat-form-field floatLabel="auto" appearance="fill">-->
<!--                            <mat-label>{{ 'streets.max' | translate}}</mat-label>-->
<!--                            <input type="number" [min]="filter.potenza.min || 0" step="1" matInput-->
<!--                                   (change)="updateObjectAndFilter(filter.potenza, 'max', $event.target.value === '' ? undefined : window.Number($event.target.value))"-->
<!--                                   [value]="filter.potenza.max">-->
<!--                        </mat-form-field>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div>-->
<!--                    <label>{{'streets.flux' | translate}}</label>-->
<!--                    <div class="flex-horizontal">-->
<!--                        <mat-form-field floatLabel="auto" appearance="fill">-->
<!--                            <mat-label>{{ 'streets.min' | translate}}</mat-label>-->
<!--                            <input type="number" min="0" step="1" matInput-->
<!--                                   (change)="updateObjectAndFilter(filter.flusso, 'min', $event.target.value === '' ? undefined : window.Number($event.target.value))"-->
<!--                                   [value]="filter.flusso.min">-->
<!--                        </mat-form-field>-->
<!--                        <mat-form-field floatLabel="auto" appearance="fill">-->
<!--                            <mat-label>{{ 'streets.max' | translate}}</mat-label>-->
<!--                            <input type="number" [min]="filter.flusso.min || 0" step="1" matInput-->
<!--                                   (change)="updateObjectAndFilter(filter.flusso, 'max', $event.target.value === '' ? undefined : window.Number($event.target.value))"-->
<!--                                   [value]="filter.flusso.max">-->
<!--                        </mat-form-field>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div>-->
<!--                    <label>{{'streets.efficiency' | translate}}</label>-->
<!--                    <div class="flex-horizontal">-->
<!--                        <mat-form-field floatLabel="auto" appearance="fill">-->
<!--                            <mat-label>{{ 'streets.min' | translate}}</mat-label>-->
<!--                            <input type="number" min="0" step="1" matInput-->
<!--                                   (change)="updateObjectAndFilter(filter.efficienza, 'min', $event.target.value === '' ? undefined : window.Number($event.target.value))"-->
<!--                                   [value]="filter.efficienza.min">-->
<!--                        </mat-form-field>-->
<!--                        <mat-form-field floatLabel="auto" appearance="fill">-->
<!--                            <mat-label>{{ 'streets.max' | translate}}</mat-label>-->
<!--                            <input type="number" [min]="filter.efficienza.min || 0" step="1" matInput-->
<!--                                   (change)="updateObjectAndFilter(filter.efficienza, 'max', $event.target.value === '' ? undefined : window.Number($event.target.value))"-->
<!--                                   [value]="filter.efficienza.max">-->
<!--                        </mat-form-field>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div>-->
<!--                    <label>{{'streets.color' | translate}}</label>-->
<!--                    <div class="flex-horizontal">-->
<!--                        <mat-form-field floatLabel="auto" appearance="fill">-->
<!--                            <mat-label>{{ 'streets.min' | translate}}</mat-label>-->
<!--                            <input type="number" min="0" step="1" matInput-->
<!--                                   (change)="updateObjectAndFilter(filter.colore, 'min', $event.target.value === '' ? undefined : window.Number($event.target.value))"-->
<!--                                   [value]="filter.colore.min">-->
<!--                        </mat-form-field>-->
<!--                        <mat-form-field floatLabel="auto" appearance="fill">-->
<!--                            <mat-label>{{ 'streets.max' | translate}}</mat-label>-->
<!--                            <input type="number" [min]="filter.colore.min || 0" step="1" matInput-->
<!--                                   (change)="updateObjectAndFilter(filter.colore, 'max', $event.target.value === '' ? undefined : window.Number($event.target.value))"-->
<!--                                   [value]="filter.colore.max">-->
<!--                        </mat-form-field>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div>-->
<!--                    <label>{{'streets.cri' | translate}}</label>-->
<!--                    <div class="flex-horizontal">-->
<!--                        <mat-form-field floatLabel="auto" appearance="fill">-->
<!--                            <mat-label>{{ 'streets.min' | translate}}</mat-label>-->
<!--                            <input type="number" min="0" step="1" matInput-->
<!--                                   (change)="updateObjectAndFilter(filter.CRI, 'min', $event.target.value === '' ? undefined : window.Number($event.target.value))"-->
<!--                                   [value]="filter.CRI.min">-->
<!--                        </mat-form-field>-->
<!--                        <mat-form-field floatLabel="auto" appearance="fill">-->
<!--                            <mat-label>{{ 'streets.max' | translate}}</mat-label>-->
<!--                            <input type="number" [min]="filter.CRI.min || 0" step="1" matInput-->
<!--                                   (change)="updateObjectAndFilter(filter.CRI, 'max', $event.target.value === '' ? undefined : window.Number($event.target.value))"-->
<!--                                   [value]="filter.CRI.max">-->
<!--                        </mat-form-field>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </app-form-group-template>-->
<!--            <div *ngIf="loading" style="display: flex; justify-content: center; align-items: center;">-->
<!--                <mat-spinner></mat-spinner>-->
<!--            </div>-->
<!--            <ng-container *ngIf="!loading">-->
<!--                <app-form-group-template title="{{'streets.results' | translate}}">-->
<!--                    <div class="flex-horizontal">-->
<!--                        <button mat-flat-button color="primary" type="button"-->
<!--                                matTooltip="{{ 'streets.select_all' | translate}}"-->
<!--                                (click)="selectAll()">-->
<!--                            <img src="assets/icon/street/selectall.png" style="max-width: 24px;">-->
<!--                        </button>-->
<!--                        <button mat-flat-button color="primary" type="button"-->
<!--                                matTooltip="{{ 'streets.select_none' | translate}}"-->
<!--                                (click)="selectNone()">-->
<!--                            <img src="assets/icon/street/unselectall.png" style="max-width: 24px;">-->
<!--                        </button>-->
<!--                        <button mat-flat-button color="primary" type="button"-->
<!--                                matTooltip="{{ 'streets.invert_selection' | translate}}"-->
<!--                                (click)="invertSelection()">-->
<!--                            <img src="assets/icon/street/inverted.png" style="max-width: 24px;">-->
<!--                        </button>-->
<!--                    </div>-->
<!--                    <div style="margin: .5em">-->
<!--                        {{'streets.selected' | translate}}: {{selectedPhotometries.length}}-->
<!--                        ({{'streets.max' | translate}}:-->
<!--                        {{maxSelectable}})-->
<!--                    </div>-->
<!--                    <mat-tab-group mat-align-tabs="center" dynamicHeight (selectedIndexChange)="setTabIndex($event)"-->
<!--                                   class="huna-material-class">-->
<!--                        <mat-tab-->
<!--                                [label]="('streets.filtered' | translate) + ' (' + filteredPhotometries.length + ')'">-->
<!--                            <div class="tab-wrapper">-->
<!--                                <app-template-paginator [noResultKey]="'paginator.no_result'"-->
<!--                                                        [dataSource]="paginateFiltered"-->
<!--                                                        [observe]="filteredPhotometries" [searchEnabled]="false">-->
<!--                                    <ng-template let-photometry="item">-->
<!--                                        <div class="photometry-checkbox">-->
<!--                                            <mat-checkbox #ref [checked]="selectedPhotometries.includes(photometry)"-->
<!--                                                          (change)="$event.checked ? add(photometry) : remove(photometry)">-->
<!--                                            </mat-checkbox>-->
<!--                                            <app-photometry-->
<!--                                                    (click)="ref.toggle(); ref.checked ? add(photometry) : remove(photometry)"-->
<!--                                                    [photometry]="photometry"></app-photometry>-->
<!--                                        </div>-->
<!--                                    </ng-template>-->
<!--                                </app-template-paginator>-->
<!--                            </div>-->
<!--                        </mat-tab>-->
<!--                        <mat-tab-->
<!--                                [label]="('streets.selected' | translate) + ' (' + selectedPhotometries.length + ')'">-->
<!--                            <div class="tab-wrapper">-->
<!--                                <app-template-paginator [noResultKey]="'paginator.no_result'"-->
<!--                                                        [dataSource]="paginateSelected"-->
<!--                                                        [observe]="selectedPhotometries" [searchEnabled]="false">-->
<!--                                    <ng-template let-photometry="item">-->
<!--                                        <div class="photometry-checkbox">-->
<!--                                            <mat-checkbox #ref [checked]="true" (change)="remove(photometry)">-->
<!--                                            </mat-checkbox>-->
<!--                                            <app-photometry (click)="ref.toggle(); remove(photometry)"-->
<!--                                                            [photometry]="photometry">-->
<!--                                            </app-photometry>-->
<!--                                        </div>-->
<!--                                    </ng-template>-->
<!--                                </app-template-paginator>-->
<!--                            </div>-->
<!--                        </mat-tab>-->
<!--                        <mat-tab-->
<!--                                [label]="('streets.unselected' | translate) + ' (' + unselectedPhotometries.length + ')'">-->
<!--                            <div class="tab-wrapper">-->
<!--                                <app-template-paginator [noResultKey]="'paginator.no_result'"-->
<!--                                                        [dataSource]="paginateUnselected"-->
<!--                                                        [observe]="selectedPhotometries" [searchEnabled]="false">-->
<!--                                    <ng-template let-photometry="item">-->
<!--                                        <div class="photometry-checkbox">-->
<!--                                            <mat-checkbox #ref [checked]="false" (change)="add(photometry)">-->
<!--                                            </mat-checkbox>-->
<!--                                            <app-photometry (click)="ref.toggle();add(photometry)"-->
<!--                                                            [photometry]="photometry"></app-photometry>-->
<!--                                        </div>-->
<!--                                    </ng-template>-->
<!--                                </app-template-paginator>-->
<!--                            </div>-->
<!--                        </mat-tab>-->
<!--                    </mat-tab-group>-->
<!--                </app-form-group-template>-->
<!--                &lt;!&ndash;                <div style="text-align: center; margin-top: 1em">&ndash;&gt;-->
<!--                &lt;!&ndash;                    <button mat-flat-button color="primary"&ndash;&gt;-->
<!--                &lt;!&ndash;                            (click)="aggiungiPreferiti()">{{ 'fotometrie.addPreferiti' | translate}}&ndash;&gt;-->
<!--                &lt;!&ndash;                    </button>&ndash;&gt;-->
<!--                &lt;!&ndash;                </div>&ndash;&gt;-->
<!--            </ng-container>-->
<!--        </fieldset>-->
<!--    </form>-->
<!--</app-template-form>-->
