<form [formGroup]="formGroup" class="w100" style="margin-bottom: 5px">
    <div class="update-segnalazione-wrapper">
        <mat-card class="actions-wrapper">
            <mat-card-subtitle>{{'manageReport' | translate}}</mat-card-subtitle>
            <div class="action-buttons-wrapper" [ngClass]="{'huna-disabled':disabledAllButton}">
                <!--                <div fxFlex *ngIf="true" style="padding-right: 0.2rem;">-->
                <!--                    <a (click)="reopenReport()">-->
                <!--                        <div class="box warn">-->
                <!--                            <div class="">-->
                <!--                                <mat-icon>lock_open</mat-icon>-->
                <!--                            </div>-->
                <!--                            <div style="padding: 0.2rem;">-->
                <!--                                {{ 're_open' | translate}}-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                    </a>-->
                <!--                </div>-->
                <button mat-flat-button color="accent" *ngIf="visualizedAssengaA" class="action-button"
                    (click)="clickAssignTo()">
                    <div class="action-button-icon">
                        <ng-container [ngTemplateOutlet]="iconOrSpinner"
                            [ngTemplateOutletContext]="{load:loadingButton.assignTo,icon:'assignment_ind'}">
                        </ng-container>
                    </div>
                    <div class="action-button-text">
                        {{ 'assignedTo' | translate}}
                    </div>
                </button>
                <button mat-flat-button color="primary" *ngIf="visualizedPresaInCarico" class="action-button"
                    (click)="clickTakeCharge()">
                    <div class="action-button-icon">
                        <ng-container [ngTemplateOutlet]="iconOrSpinner"
                            [ngTemplateOutletContext]="{load:loadingButton.takeChange,icon:'assignment_turned_in'}">
                        </ng-container>
                    </div>
                    <div class="action-button-icon">
                        {{ 'take_charge' | translate}}
                    </div>
                </button>
                <button mat-flat-button color="accent" *ngIf="visualizedGeneraReport" class="action-button"
                    (click)="clickGenerateReport()">
                    <div class="action-button-icon">
                        <ng-container [ngTemplateOutlet]="iconOrSpinner"
                            [ngTemplateOutletContext]="{load:loadingButton.generateReport,icon:'add_comment'}">
                        </ng-container>
                    </div>
                    <div class="action-button-text">
                        {{ 'generates_report' | translate}}
                    </div>
                </button>
                <button mat-flat-button color="primary" *ngIf="visualizedRichiediIntervento" class="action-button"
                    (click)="clickSearchIntervention()">
                    <div class="action-button-icon">
                        <ng-container [ngTemplateOutlet]="iconOrSpinner"
                            [ngTemplateOutletContext]="{load:loadingButton.searchIntervention,icon:'announcement'}">
                        </ng-container>
                    </div>
                    <div class="action-button-text">
                        {{ 'intervention' | translate}}
                    </div>
                </button>
                <button color="warn" *ngIf="visualizedChiudiSegnalazione" class="action-button"
                    (click)="clickCloseReport()">
                    <div class="action-button-icon">
                        <ng-container [ngTemplateOutlet]="iconOrSpinner"
                            [ngTemplateOutletContext]="{load:loadingButton.closeReport,icon:'lock'}">
                        </ng-container>
                    </div>
                    <div class="action-button-text">
                        {{ 'close_report' | translate}}
                    </div>
                </button>
            </div>
        </mat-card>

        <mat-card class="new-comment-wrapper">
            <mat-card-content>
                <mat-expansion-panel class="activities-wrapper mat-elevation-z0" (opened)="onOpenActivities()" (closed)="activitiesExpanded = false" [expanded]="activitiesExpanded">
                    <mat-expansion-panel-header>
                        {{ 'list_activity' | translate}}
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                        <mat-spinner style="margin: 10px auto;" *ngIf="loading.activity" diameter="20"></mat-spinner>
                        <div class="list-activity-wrapper scrollbar-visible" #activitiesScrollableArea>
                            <app-list-activity *ngIf="!loading.activity && activities" [activities]="activities">
                            </app-list-activity>
                        </div>
                    </ng-template>
                </mat-expansion-panel>
                <mat-divider></mat-divider>
                <h4 class="add-comment-title"> {{'activity' | translate}}</h4>
                <h6 class="add-comment-subtitle">{{'new_activity' | translate}}</h6>

                <div class="flex-center-width100 flex-flow-column">

                    <mat-radio-group formControlName="typeActivity" class="w100">
                        <mat-radio-button style="margin-top: 0.2rem;" [value]="valueCommentRadio.comment">
                            {{'text' | translate}}
                        </mat-radio-button>
                        <mat-radio-button style="margin-top: 0.2rem;" [value]="valueCommentRadio.foto">
                            {{'photo' | translate}}</mat-radio-button>
                        <mat-radio-button style="margin-top: 0.2rem;" [value]="valueCommentRadio.file">
                            {{'attachment' | translate}}
                        </mat-radio-button>
                    </mat-radio-group>

                    <div class="w100">
                        <ng-container [ngSwitch]="typeComment">
                            <ng-container *ngSwitchCase="valueCommentRadio.comment">
                                <app-form-group-template title="{{ 'comment' | translate}}">
                                    <mat-form-field floatLabel="auto" appearance="fill">
                                        <mat-label>{{ 'comment' | translate}}</mat-label>
                                        <input formControlName="comment" matInput>
                                    </mat-form-field>
                                </app-form-group-template>
                            </ng-container>
                            <ng-container *ngSwitchCase="valueCommentRadio.foto">
                                <div>
                                    <div class="mat-form-field border-left-1-dotted-primary"
                                        style="overflow: scroll; padding-left: 10px;">
                                        <label class="h2"> {{ 'photo' | translate}}</label>
                                        <ngx-dropzone (change)="onLoadImage($event.addedFiles,'foto')" accept="image/*">
                                            <ngx-dropzone-label>
                                                {{ 'drag_image' | translate}}
                                            </ngx-dropzone-label>
                                        </ngx-dropzone>
                                        <mat-list role="list">
                                            <ng-container *ngIf="formGroup.get('foto').value">
                                                <div>
                                                    <img [src]="formGroup.get('foto').value.url"
                                                        class="object-fit_cover" height="30px">
                                                </div>
                                                <button class="remove-attachment-button"
                                                    (click)="deleteSavedFile('foto')">
                                                    {{ 'remove_attachement' | translate}}
                                                </button>
                                            </ng-container>
                                        </mat-list>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="valueCommentRadio.file">
                                <div>
                                    <div class="mat-form-field border-left-1-dotted-primary"
                                        style="overflow: scroll; padding-left: 10px;">
                                        <label class="h2"> {{ 'attachment' | translate}}</label>
                                        <ngx-dropzone (change)="onLoadImage($event.addedFiles,'file')"
                                            accept="application/pdf">
                                            <ngx-dropzone-label>
                                                {{ 'drag_file' | translate}}
                                            </ngx-dropzone-label>
                                        </ngx-dropzone>
                                        <mat-list role="list">
                                            <ng-container
                                                *ngIf="formGroup.get('file').value&&formGroup.get('file').value.name">
                                                <div>
                                                    <div class="flex-start-centers">
                                                        <mat-icon height="30px">attachment</mat-icon>
                                                        <span style="margin-left: 0.5rem">
                                                            {{formGroup.get('file').value.name}}
                                                        </span>
                                                    </div>
                                                    <!--                                            <img src="{{activityForm.get('allegato').value.url}}" height="30px">-->
                                                </div>
                                                <button class="remove-attachment-button"
                                                    (click)="deleteSavedFile('file')">
                                                    {{ 'remove_attachement' | translate}}
                                                </button>
                                            </ng-container>
                                        </mat-list>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                <div class="w100" style="text-align: end" (click)="clickSendComment()">
                    <button mat-raised-button color="primary"
                            [disabled]="formGroup.invalid ||loadingButton.sendComment">
                        <mat-icon>send</mat-icon>
                    </button>
                </div>
                </div>
            </mat-card-content>
           <!--  <p>&nbsp;</p>
            <hr>
            <p>&nbsp;</p> -->
            <!-- <mat-card-content>
                <div class="spacer">
                    <span (click)="$event.stopPropagation();clickOpenCloseActivies()"
                          class="card-label color-primary" style="cursor: pointer">{{ 'list_activity' | translate}}
                        <ng-container *ngIf="!openActivies;else openClass">+</ng-container>
                       <ng-template #openClass>-</ng-template>
                       <mat-spinner *ngIf="loading.activity" diameter="20"></mat-spinner>
                    </span>
                    <br>
                    <app-list-activity *ngIf="openActivies" [activities]="activities"></app-list-activity>
                </div>


            </mat-card-content> -->
        </mat-card>
    </div>
</form>
<ng-template #iconOrSpinner let-load='load' let-icon='icon'>
    <ng-container *ngIf="!load;else noLoad">
        <mat-icon>{{icon}}</mat-icon>
    </ng-container>
    <ng-template #noLoad>
        <mat-spinner class="circle-color-white" style="margin: 0 auto;" diameter="20"></mat-spinner>
    </ng-template>
</ng-template>