import {Directive, ElementRef, Input, OnDestroy, Output} from '@angular/core';
import {Subject} from 'rxjs';

@Directive({
    selector: '[appGetGoogleAddress]'
})
export class GetGoogleAddressDirective implements OnDestroy {
    @Output() changeAddress = new Subject();
    @Input() adressType: 'geocode' | 'establishment' | 'address' = 'geocode';
    private id;

    constructor(private elementRef: ElementRef) {
        this.getPlaceAutocomplete();
    }

    private getPlaceAutocomplete() {
        if (this.elementRef != null && this.elementRef.nativeElement != null) {
            const sub = new google.maps.places.Autocomplete(this.elementRef.nativeElement,
                {
                    types: [this.adressType],
                    strictBounds: true
                });
            this.id = sub.addListener('place_changed', () => {
                const place = sub.getPlace();
                // const formattedAddress = place.formatted_address;
                if (place != null && place.geometry != null && place.geometry.location != null && place.geometry.location.lat != null && place.geometry.location.lng() != null) {
                    this.changeAddress.next({
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng()
                    });
                } else {
                    this.changeAddress.next(undefined);
                }
            });
        }
    }

    ngOnDestroy() {
        this.changeAddress.complete();
        if (this.id != null) {
            google.maps.event.removeListener(this.id);
        }
    }
}
