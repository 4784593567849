<div class="flex-center-width100 flex-flow-row" (click)="setInnerClick()">
        <div
             class="w100 margin-10" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
            <ng-template [ngTemplateOutlet]="searchBar"></ng-template>
        </div>
</div>
<form [formGroup]="formGroup">
    <ng-template #searchBar>
        <div class="flex-center-width100">
            <mat-form-field
                    (focusin)="inSearchIn()">
                <mat-label>{{labelSearch|translate}}</mat-label>
                <mat-chip-list #chipList>
                    <mat-chip *ngFor="let item of selectedChips"
                        (removed)="removeToSelect(item)"
                        >
                        {{getNameTraduction(item)}}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip>
                    <input matInput formControlName="name" [matChipInputFor]="chipList">
                    <button (click)="$event.stopPropagation();clickIcon()" mat-icon-button matSuffix
                            [disabled]="formGroup.disabled">
                        <mat-icon>
                            <ng-container *ngIf="!openList;else closeList">
                                search
                            </ng-container>
                            <ng-template #closeList>
                                clear
                            </ng-template>
                        </mat-icon>
                    </button>
                </mat-chip-list>
            </mat-form-field>
        </div>
    </ng-template>

    <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayWidth]="searchBarWidth"
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="openList"
    >
        <mat-selection-list
                (click)="setInnerClick()"
                class="w100 mat-elevation-z2"
                style="background-color: white; padding: 0;"
                formControlName="items"
                *ngIf="allItems.length>0"
        >
            <div class="flex-start-center flex-flow-row w100">
                <ng-container *ngFor="let item of allItems">
                    <div [hidden]="hiddenValue(item)" [style.width]="searchBarWidth > 600 ? '50%' : '100%'">
                        <mat-list-option
                                [value]="getUniqueId(item)">
                            <span style="word-break: break-word;">{{getNameTraduction(item)}}</span>
                        </mat-list-option>
                    </div>
                </ng-container>
            </div>
        </mat-selection-list>
    </ng-template>
</form>