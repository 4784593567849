import {Injectable} from '@angular/core';
import * as Parse from 'parse';
import {forkJoin, Observable} from "rxjs";
import {hunaParseFileName, isNotNullOrUndefined} from "../../models/Models";

@Injectable({
    providedIn: 'root'
})
export class UtilityParseFileService {

    constructor() {
    }


    getBase64(file: File): Observable<{ base64: string, name: string }> {
        return new Observable((subscriber) => {
            const fileUploadControl = file;
            if (fileUploadControl) {
                const fileRead = fileUploadControl;
                const name = fileUploadControl.name;
                const reader = new FileReader();
                reader.onloadend = (e: ProgressEvent) => {
                    const base64 = (e.target as FileReader).result as any;
                    subscriber.next({base64, name});
                    subscriber.complete();
                    subscriber.unsubscribe();
                };
                reader.onerror = (e) => {
                    subscriber.error(e);
                    subscriber.complete();
                    subscriber.unsubscribe();
                }
                reader.readAsDataURL(fileRead);
            }
        })
    }

    getBases64(files: File[]): Observable<{ base64: string, name: string }[]> {
        const allFiles = [];

        files.forEach(
            file => {
                allFiles.push(this.getBase64(file))
            }
        )
        return forkJoin(allFiles) as Observable<{ base64: string, name: string }[]>
    }


    getParseFile(file, name = undefined): Parse.File {
        if (file) {
            if (!isNotNullOrUndefined(name)) {
                name = file.name
            }
            return new Parse.File(hunaParseFileName(name), file);
        }
    }

    getParseFile$(file, name = undefined): Observable<Parse.File> {
        return new Observable((subscriber) => {
            const fileUploadControl = file;
            if (fileUploadControl) {
                if (!isNotNullOrUndefined(name)) {
                    name = fileUploadControl.name
                }
                try {
                    const parseFile = new Parse.File(hunaParseFileName(name), fileUploadControl);
                    subscriber.next(parseFile);
                } catch (e) {
                    subscriber.error(e);
                    // console.error('result:', base64);
                    // console.error('splitted base64:', base64DataString);
                    console.error(e);
                } finally {
                    subscriber.complete();
                    subscriber.unsubscribe();
                }
                // const reader = new FileReader();
                // reader.onloadend = () => {
                //     const base64 = reader.result as string;
                //     let base64DataString = '';
                //
                //     if (stringIsSet(base64)) {
                //         let base64Data = base64.split(",");
                //         base64DataString = base64Data[base64Data.length - 1];
                //     }else {
                //         console.error('result not string')
                //     }
                //
                // };
                //
                // reader.onerror = (error) => {
                //     subscriber.error(error);
                //     subscriber.complete();
                //     subscriber.unsubscribe();
                // }
                // reader.readAsDataURL(fileUploadControl);
            }
        })


        // return this.getBase64(file).pipe(
        //     map(base64 => {
        //         console.log(base64)
        //         const parseFile = new Parse.File(name, {base64: base64});
        //         return parseFile;
        //     })
        // )
    }


}
