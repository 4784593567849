import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SchedaManutenzioneParse} from "../../../models/SchedaManutenzione.Parse";
import {arrayIsSet,} from "../../../models/Models";

@Component({
    selector: 'app-assignement-schedule-maintence',
    templateUrl: './assignement-schedule-maintence.component.html',
    styleUrls: ['./assignement-schedule-maintence.component.scss']
})
export class AssignementScheduleMaintenceComponent implements OnInit {

    constructor() {
    }

    @Input() scheduleMaintenace: SchedaManutenzioneParse[]
    // @Input() selectedScheduleMaintenace: SchedaManutenzioneParse[] = [];

    @Output() clickRemoveSchedaManutenzione = new EventEmitter();
    @Output() addNewSchedaManutenzione = new EventEmitter();


    ngOnInit(): void {
    }

    get isSetScheduleMaintenace(): boolean {
        return arrayIsSet(this.scheduleMaintenace);
    }

    addElement() {
        this.addNewSchedaManutenzione.emit()
    }


}
