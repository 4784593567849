import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DialogExportComponent} from './dialog-export/dialog-export.component';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {DialogPopUpService} from "../../providers/services/dialog-pop-up.service";
import {TranslateModule} from "@ngx-translate/core";
import {MatLegacySlideToggleModule as MatSlideToggleModule} from "@angular/material/legacy-slide-toggle";
import {ReactiveFormsModule} from "@angular/forms";
import {MatLegacyInputModule as MatInputModule} from "@angular/material/legacy-input";
import {ReqestFileComponent} from './reqest-file/reqest-file.component';
import {MatGridListModule} from "@angular/material/grid-list";
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from "@angular/material/legacy-progress-spinner";
import {CoreModule} from "../../core/core.module";
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {SeeOnTheMapComponent} from "./see-on-the-map/see-on-the-map.component";
import {DashboardModule} from "../../pages-modules/dashboard/dashboard.module";
import {PrintStreetComponent} from './print-street/print-street.component';
import {WidgetsModule} from '../../widgets/widgets.module';
import {DirectivesModule} from '../../providers/directives/directives.module';
import {StreetModule} from '../street/street.module';
import {PipesModule} from '../../providers/pipes/pipes.module';
import {PrintReportEnergiaComponent} from './print-report-energia/print-report-energia.component';
import {VisualizedElementsInMapComponent} from "./visualized-elements-in-map/visualized-elements-in-map.component";
// import {AgmCoreModule} from "@agm/core";
// import {AgmJsMarkerClustererModule} from "@agm/js-marker-clusterer";
import {MatLegacyRadioModule as MatRadioModule} from "@angular/material/legacy-radio";
import {MatSidenavModule} from "@angular/material/sidenav";
import {DashboardSidenavModule} from "../dashboard-sidenav/dashboard-sidenav.module";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatLegacySelectModule as MatSelectModule} from "@angular/material/legacy-select";
import {GoogleMapsModule} from "@angular/google-maps";
import {MatChipsModule} from "@angular/material/chips";
import {MatExpansionModule} from "@angular/material/expansion";

@NgModule({
    declarations: [DialogExportComponent, ReqestFileComponent, SeeOnTheMapComponent, PrintStreetComponent, PrintReportEnergiaComponent, VisualizedElementsInMapComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        MatFormFieldModule,
        MatTooltipModule,
        DirectivesModule,
        TranslateModule,
        MatSlideToggleModule,
        ReactiveFormsModule,
        MatInputModule,
        MatGridListModule,
        MatProgressSpinnerModule,
        CoreModule,
        MatCardModule,
        DashboardModule,
        WidgetsModule,
        StreetModule,
        PipesModule,
        // AgmCoreModule,
        // AgmJsMarkerClustererModule,
        MatRadioModule,
        MatSidenavModule,
        DashboardSidenavModule,
        MatButtonToggleModule,
        MatSelectModule,
        GoogleMapsModule,
        MatExpansionModule,
    ],
    exports: [DialogExportComponent, ReqestFileComponent, SeeOnTheMapComponent, VisualizedElementsInMapComponent],
    providers: [DialogPopUpService]
})
export class PopUpExportModule {
}
