<div class="box-wrapper zoom-on-hover" [ngClass]="{'selected': selected}">
    <mat-card class="dimension border opacity box-shadow-set"
              [ngClass]="{'low-priority': segnalazione.priorita === 0, 'medium-priority': segnalazione.priorita === 1, 'high-priority': segnalazione.priorita === 2}">
        <mat-card-title class="subtitle-text background">
            {{segnalazione.titolo}}
        </mat-card-title>
        <mat-card-content class="box-content">
            <mat-card class="dimension-info">
                <div class="detail-subtitle default-color-text">
                    {{'segnalazioniParse.createdAt.title'|translate}}
                </div>
                <div class="detail-value">
                    {{segnalazione.createdAt|customDate:'medium'|async}}
                </div>
                <div class="detail-subtitle default-color-text" *ngIf="segnalazione.nomeAutore">
                    {{'by'|translate}}
                </div>
                <div class="detail-value">
                    {{segnalazione.nomeAutore}}
                </div>
            </mat-card>
            <mat-card class="dimension-info ">
                <div class="detail-subtitle default-color-text">
                    {{'segnalazioniParse.updatedAt.title'|translate}}
                </div>
                <div class="detail-value">
                    {{segnalazione.updatedAt|customDate:'medium'|async}}
                </div>
                <div class="detail-subtitle default-color-text" *ngIf="segnalazione.stato">
                    {{'segnalazioniParse.stato.title'|translate}}
                </div>
                <div class="detail-value">
                    {{('segnalazioniParse.stato.' + segnalazione.stato|translate)|notPresentInTraduction:segnalazione.stato:'segnalazioniParse.stato.'}}
                </div>
                <ng-container *ngIf="segnalazione.presaInCaricaDetail">
                    <div class="detail-subtitle default-color-text"
                         *ngIf="segnalazione.stato&&segnalazione.stato!=assegnatoA">
                        {{'by'|translate}}
                    </div>
                    <div class="detail-value">
                        {{segnalazione.presaInCaricaDetail.get('nome')}}
                        &#32;{{segnalazione.presaInCaricaDetail.get('cognome')}}
                    </div>
                </ng-container>
            </mat-card>
            <mat-card class="dimension-info " (click)="$event.stopPropagation();openDescription()"
                      *ngIf="segnalazione.descrizione">
                <div class="detail-subtitle default-color-text">
                    {{'segnalazioniParse.descrizione.title'|translate}} &#32;
                </div>
                <div class="box-description-close">
                    {{segnalazione.descrizione}}
                </div>
                <mat-icon class="default-color-text">
                    info
                </mat-icon>
            </mat-card>
        </mat-card-content>
    </mat-card>
</div>