<div class="page-wrapper">
    <div class="split-container scrollbar-visible">
        <div class="split-child-sx scrollbar-visible">
            <div style="width: 100%;">
                <div class="flex-center-width100">
                    <div class="card-child">
                        <mat-card>
                            <mat-card-header>
                                <div mat-card-avatar>
                                    <mat-icon>search</mat-icon>
                                </div>
                                <mat-card-title>{{'fotometrie.type' |translate}}</mat-card-title>
                            </mat-card-header>
                            <app-chips-from-array-label
                                    (clickChips)="clickChipTypePhotometry($event.value)"
                                    [distinctValue]="['public','private','shared']"
                                    [selectedChips]="chipsSelectedTypePhotometry">
                            </app-chips-from-array-label>
                        </mat-card>
                    </div>
                </div>
                <div class="flex-center-width100">
                    <div class="card-child" *ngFor="let distinctValue of produttoreChipsLabel|keyvalue">
                        <mat-card>
                            <mat-card-header>
                                <div mat-card-avatar>
                                    <mat-icon>search</mat-icon>
                                </div>
                                <mat-card-title>{{'fotometrie.' + distinctValue.key|translate}}</mat-card-title>
                            </mat-card-header>
                            <form [formGroup]="formAutoCompleteFotometrie">
                                <ng-container>
                                    <app-auto-complete-form
                                            title="{{'fotometrie.produttore'|translate}}"
                                            formControlName="produttore"
                                            [items]="itemsProduttore"
                                            appearance="fill"
                                            [hiddenClearButton]="true"
                                            (optionSelected)="optSel($event,'produttore')">
                                    </app-auto-complete-form>
                                </ng-container>
                            </form>
                            <app-chips-from-array-label
                                    (clickChips)="clickChipProduttore(distinctValue.key,$event)"
                                    [distinctValue]="chipsSelectedProduttore"
                                    [selectedChips]="chipsSelectedProduttore">
                            </app-chips-from-array-label>
                        </mat-card>
                    </div>
                </div>
                <div class="flex-center-width100" style="flex-wrap: wrap;">                    
                    <div class="card-child" *ngFor="let label of labelsChips">
                        <mat-card>
                            <mat-card-header>
                                <div mat-card-avatar>
                                    <mat-icon>search</mat-icon>
                                </div>
                                <mat-card-title>{{'fotometrie.' + label|translate}}</mat-card-title>
                                <!--                <mat-card-subtitle>Dog Breed</mat-card-subtitle>-->
                            </mat-card-header>
                            <app-chips-from-array-label
                                    (clickChips)="clickChip(label,$event)"
                                    [distinctValue]="distinctValues[label]"
                                    [selectedChips]="getchipsSelected(label)">
                            </app-chips-from-array-label>
                        </mat-card>
                    </div>
                </div>
                <!--            <div class="flex-center-width100">-->
                <!--                <div>-->
                <!--                    <div class="card-child">-->
                <!--                        <mat-card>-->
                <!--                            <mat-card-header>-->
                <!--                                <div mat-card-avatar>-->
                <!--                                    <mat-icon>search</mat-icon>-->
                <!--                                </div>-->
                <!--                                <mat-card-title>{{'fotometrie.flusso' |translate}}</mat-card-title>-->
                <!--                            </mat-card-header>-->
                <!--                            <div style="display: flex;justify-content: space-between;align-items: center">-->
                <!--                                <h5 style="width: 50%">{{'fotometrie.flussoMin' |translate}}</h5>-->
                <!--                                <h5 style="width: 50%">{{'fotometrie.flussoMax' |translate}}</h5>-->
                <!--                            </div>-->
                <!--                            <div style="display: flex;justify-content: space-between;align-items: center">-->
                <!--                                <div style="width: 50%;padding: 5px"-->
                <!--                                     *ngIf="isSetMinMax('flussoMin')" class="custom-slider-accent">-->
                <!--                                    <ngx-slider [value]="minMaxValue.flussoMin.min"-->
                <!--                                                [highValue]="formFilter.get('flussoMin').get('max').value"-->
                <!--                                                [options]="minMaxValue.flussoMin.options"-->
                <!--                                                (valueChange)="valueChange('flussoMin','min',$event)"-->
                <!--                                                (highValueChange)="valueChange('flussoMin','max',$event)"-->
                <!--                                    ></ngx-slider>-->
                <!--                                </div>-->
                <!--                                <div style="width: 50%;padding: 5px"-->
                <!--                                     *ngIf="isSetMinMax('flussoMax')" class="custom-slider-primary">-->
                <!--                                    <ngx-slider [value]="minMaxValue.flussoMax.min"-->
                <!--                                                [highValue]="minMaxValue.flussoMax.max"-->
                <!--                                                [options]="minMaxValue.flussoMax.options"-->
                <!--                                                (valueChange)="valueChange('flussoMax','min',$event)"-->
                <!--                                                (highValueChange)="valueChange('flussoMax','max',$event)"></ngx-slider>-->
                <!--                                </div>-->
                <!--                            </div>-->
                <!--                        </mat-card>-->
                <!--                    </div>-->
                <!--                    <div class="card-child">-->
                <!--                        <mat-card>-->
                <!--                            <mat-card-header>-->
                <!--                                <div mat-card-avatar>-->
                <!--                                    <mat-icon>search</mat-icon>-->
                <!--                                </div>-->
                <!--                                <mat-card-title>{{'fotometrie.potenza' |translate}}</mat-card-title>-->
                <!--                            </mat-card-header>-->
                <!--                            <div style="display: flex;justify-content: space-between;align-items: center">-->
                <!--                                <h5 style="width: 50%">{{'fotometrie.potenzaMin' |translate}}</h5>-->
                <!--                                <h5 style="width: 50%">{{'fotometrie.potenzaMax' |translate}}</h5>-->
                <!--                            </div>-->
                <!--                            <div style="display: flex;justify-content: space-between;align-items: center">-->
                <!--                                <div style="width: 50%;padding: 5px"-->
                <!--                                     *ngIf="isSetMinMax('potenzaMin')" class="custom-slider-accent">-->
                <!--                                    <ngx-slider [value]="minMaxValue.potenzaMin.min"-->
                <!--                                                [highValue]="formFilter.get('potenzaMin').get('max').value"-->
                <!--                                                [options]="minMaxValue.potenzaMin.options"-->
                <!--                                                (valueChange)="valueChange('potenzaMin','min',$event)"-->
                <!--                                                (highValueChange)="valueChange('potenzaMin','max',$event)"-->
                <!--                                    ></ngx-slider>-->
                <!--                                </div>-->
                <!--                                <div style="width: 50%;padding: 5px"-->
                <!--                                     *ngIf="isSetMinMax('potenzaMax')" class="custom-slider-primary">-->
                <!--                                    <ngx-slider [value]="minMaxValue.potenzaMax.min"-->
                <!--                                                [highValue]="minMaxValue.potenzaMax.max"-->
                <!--                                                [options]="minMaxValue.potenzaMax.options"-->
                <!--                                                (valueChange)="valueChange('potenzaMax','min',$event)"-->
                <!--                                                (highValueChange)="valueChange('potenzaMax','max',$event)"></ngx-slider>-->
                <!--                                </div>-->
                <!--                            </div>-->
                <!--                        </mat-card>-->
                <!--                    </div>-->
                <!--                    <div class="card-child">-->
                <!--                        <mat-card>-->
                <!--                            <mat-card-header>-->
                <!--                                <div mat-card-avatar>-->
                <!--                                    <mat-icon>search</mat-icon>-->
                <!--                                </div>-->
                <!--                                <mat-card-title>{{'fotometrie.efficienza' |translate}}</mat-card-title>-->
                <!--                            </mat-card-header>-->
                <!--                            <div style="display: flex;justify-content: space-between;align-items: center">-->
                <!--                                <h5 style="width: 50%">{{'fotometrie.efficienzaMin' |translate}}</h5>-->
                <!--                                <h5 style="width: 50%">{{'fotometrie.efficienzaMax' |translate}}</h5>-->
                <!--                            </div>-->
                <!--                            <div style="display: flex;justify-content: space-between;align-items: center">-->
                <!--                                <div style="width: 50%;padding: 5px"-->
                <!--                                     *ngIf="isSetMinMax('efficienzaMin')" class="custom-slider-accent">-->
                <!--                                    <ngx-slider [value]="minMaxValue.efficienzaMin.min"-->
                <!--                                                [highValue]="formFilter.get('efficienzaMin').get('max').value"-->
                <!--                                                [options]="minMaxValue.efficienzaMin.options"-->
                <!--                                                (valueChange)="valueChange('efficienzaMin','min',$event)"-->
                <!--                                                (highValueChange)="valueChange('efficienzaMin','max',$event)"-->
                <!--                                    ></ngx-slider>-->
                <!--                                </div>-->
                <!--                                <div style="width: 50%;padding: 5px"-->
                <!--                                     *ngIf="isSetMinMax('efficienzaMax')" class="custom-slider-primary">-->
                <!--                                    <ngx-slider [value]="minMaxValue.efficienzaMax.min"-->
                <!--                                                [highValue]="minMaxValue.efficienzaMax.max"-->
                <!--                                                [options]="minMaxValue.efficienzaMax.options"-->
                <!--                                                (valueChange)="valueChange('efficienzaMax','min',$event)"-->
                <!--                                                (highValueChange)="valueChange('efficienzaMax','max',$event)"></ngx-slider>-->
                <!--                                </div>-->
                <!--                            </div>-->
                <!--                        </mat-card>-->
                <!--                    </div>-->
                <!--                </div>-->
                <!--            </div>-->
                <form class="flex-center-width100" [formGroup]="formTextFotometrie">
                    <div class="card-child">
                        <mat-card>
                            <mat-card-header>
                                <div mat-card-avatar>
                                    <mat-icon>search</mat-icon>
                                </div>
                                <mat-card-title>{{'fotometrie.nome' |translate}}</mat-card-title>
                            </mat-card-header>
                            <mat-form-field floatLabel="auto" appearance="fill" *ngFor="let label of labelsText">
                                <mat-label>{{ 'fotometrie.' + label | translate}}</mat-label>
                                <input [formControlName]="label" matInput>
                            </mat-form-field>
                        </mat-card>
                    </div>
                </form>
            </div>
        </div>
        <div class="split-child-dx">
            <div class="drag-and-drop-wrapper" cdkDropListGroup>
                <div class="container-drag-box">
                    <h3 style="width: 100%;text-align: center">{{'fotometrie.notSelected'|translate}}
                        <span *ngIf="!loading.fotometrieNonSelezionate">
                            ({{filteredPhotometries.length}}
                            /{{photometriesFilteredByTypePhotometry.length}})</span></h3>
                    <div style="margin: auto;margin-bottom:5px;width: 30px">
                        <mat-spinner [diameter]="30"
                                    *ngIf="loading.fotometrieNonSelezionate;else noLoadingNonSelezionate"></mat-spinner>
                        <ng-template #noLoadingNonSelezionate>
                            <button mat-flat-button
                                    [disabled]="filteredPhotometries.length==0"
                                    color="accent" class="button-card-sovrapossition"
                                    (click)="massiveSelect()">
                                <mat-icon>arrow_forward</mat-icon>
                            </button>
                        </ng-template>
                    </div>
                    <!-- [cdkDropListData]="filteredPhotometries" -->
                    <cdk-virtual-scroll-viewport itemSize="210"
                                                class="container-list scrollbar-visible"
                                                cdkDropList
                                                #filteredPhotometriesWrapper
                                                cdkDropListSortingDisabled
                                                (cdkDropListDropped)="drop($event)"
                    >
                        <div cdkDrag 
                            *cdkVirtualFor="let photometry of filteredPhotometries;let i=index;trackBy:photometryTracker" 
                            class="box-drag-drop" [cdkDragData]="photometry" [attr.data-objectId]="photometry.objectId"
                            (cdkDragReleased)="dragReleased($event)">
                            <app-box-photometry
                                    [icon]="{matIcon: true, pathName: 'arrow_forward'}"
                                    [photometry]="photometry" class="w100"
                                    (clickButton)="moveElement(photometry,i,'moveToSelected')">
                            </app-box-photometry>
                        </div>
                    </cdk-virtual-scroll-viewport>
                </div>
                <div class="container-drag-box">
                    <h3 style="width: 100%;text-align: center">{{'fotometrie.selected'|translate}}
                        <span *ngIf="!loading.fotometrieSelezionate">({{selectedPhotometries.length}}
                            /{{photometriesFilteredByTypePhotometry.length}})</span></h3>
                    <div style="margin: auto;margin-bottom:5px;width: 30px">
                        <mat-spinner [diameter]="30"
                                    *ngIf="loading.fotometrieSelezionate;else noLoadingSelezionate"></mat-spinner>
                        <ng-template #noLoadingSelezionate>
                            <button [disabled]="selectedPhotometries.length==0"
                                    mat-flat-button color="accent" class="button-card-sovrapossition"
                                    (click)="massiveDeSelect()">
                                <mat-icon>arrow_back</mat-icon>
                            </button>
                        </ng-template>
                    </div>
                    <div
                            class="container-list scrollbar-visible"
                            cdkDropList
                            #selectedPhotometriesWrapper
                            cdkDropListSortingDisabled
                            (cdkDropListDropped)="drop($event)">
                        <div 
                            cdkDrag 
                            *ngFor="let photometry of selectedPhotometries;let i = index;trackBy:photometryTracker" 
                            class="box-drag-drop" [cdkDragData]="photometry" [attr.data-objectId]="photometry.objectId"
                            (cdkDragReleased)="dragReleased($event)">
                            
                                <app-box-photometry
                                        [icon]="{matIcon: true, pathName: 'arrow_back'}"
                                        [photometry]="photometry" class="w100"
                                        (clickButton)="moveElement(photometry,i,'moveToFiltered')">
                                </app-box-photometry>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="dialog-actions-wrapper w100">
        <button (click)="closeDialog(false)" mat-raised-button color="warn">
            <mat-icon>clear</mat-icon>
        </button>
        <button style="margin-left: 8px;" *ngIf="formListFotometrie"
                (click)="closeDialog(true)" mat-raised-button color="primary">
            <mat-icon>save</mat-icon>
        </button>
    </div>
</div>
