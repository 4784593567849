import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-template-form',
    templateUrl: './template-form.component.html',
    styleUrls: ['./template-form.component.scss']
})
export class TemplateFormComponent implements OnInit {

    @Input() title;
    @Input() subtitle;
    @Input() logo = false;
    @Input() informativa = false;
    @Input() sizeCss: 'S' | 'M' | 'L' | 'XL' = 'M'

    constructor() {
    }

    ngOnInit() {
    }

    getSizeCss() {
        return 'width-cmp-' + this.sizeCss;
    }

}
