import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    UntypedFormBuilder,
    UntypedFormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR, ValidationErrors
} from "@angular/forms";
import {
    arrayIsSet, className,
    cleanToSpecialCharacterAndLowerCaseString,
    isNotNullOrUndefined, KeyStringValueAny, objectIsSet,
    stringIsSet
} from "../../models/Models";
import {BehaviorSubject, Observable, of, Subscription} from "rxjs";
import {ordinamentoEcampiTraduzioni} from "../../models/ordinamentoEcampiTraduzioni";
import {getValidator, typeFormValue} from "../../models/configurationProperty/configurationPropertyUtils";
import {dataForm} from "../../components/confirm-delete/select-or-create/select-or-create.component";
import {
    FormFieldsHtmlType,
    FormFieldsTabsHtmlType
} from "../../components/dashboard-sidenav/dashboard-sidenav/dashboard-sidenav.component";
import {PuntiLuceParse} from "../../models/PuntiLuce.Parse";
import {CircuitiParse} from "../../models/Circuiti.Parse";
import {StradeParse} from "../../models/Strade.Parse";
import {MapService} from "../../providers/services/map.service";
import {AlertService} from "../../providers/services/alert.service";
import {TranslateService} from "@ngx-translate/core";
import {filter, map, startWith} from "rxjs/operators";
import {LocalSotrageService} from "../../providers/services/local-sotrage.service";
import {SelectToolbarItemType} from "../select-toolbar/select-toolbar.component";

@Component({
    selector: 'app-field-edit-light-points',
    templateUrl: './field-edit-light-points.component.html',
    styleUrls: ['./field-edit-light-points.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: FieldEditLightPointsComponent
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: FieldEditLightPointsComponent
        }
    ]
})
export class FieldEditLightPointsComponent implements OnChanges, OnDestroy, ControlValueAccessor {
    @Input() disabled;
    formGroup: UntypedFormGroup;
    @Input() puntoLuce: PuntiLuceParse;
    puntoLuce$: Observable<PuntiLuceParse>;
    @Input() circuiti: CircuitiParse[];
    @Input() strade: StradeParse[];
    @Input() isGestore: boolean;
    @Input() isSviluppatore: boolean;
    @Input() abbonamentoGestioneAttivo: boolean;
    @Input() abbonamentoTelecontrolloAttivo: boolean;
    @Output() dirtyValues = new EventEmitter<KeyStringValueAny>();
    keyPossibleTabs = {
        state_of_fact: 'state_of_fact',
        project: 'project',
        images: 'images',
        virtualMidNight: 'virtualMidNight',
        connectedFiles: 'connectedFiles',
        historyLightPoint: 'historyLightPoint',
        caricoEsogeno: 'caricoEsogeno',
        reportsAggregate: 'reportsAggregate',
        scheduleMaintenance: 'scheduleMaintenance',
    }
    @Input() possibleTabs: SelectToolbarItemType[] = [
        {
            displayValue: 'state_of_fact',
            key: this.keyPossibleTabs.state_of_fact,
            registerValue: this.keyPossibleTabs.state_of_fact
        }, {
            displayValue: 'project',
            key: this.keyPossibleTabs.project,
            registerValue: this.keyPossibleTabs.project
        },
    ]
    lightPointsNearest: PuntiLuceParse[];
    public favorites$: Observable<string[]>;
    public typeVisualizedKeys$: Observable<'all' | 'favorites' | 'compiled' | 'updated'>;
    public searchName$: Observable<string>;
    public selectedTab$: Observable<string>;
    public etichetta$: Observable<{ traductions: string[], value: string }>;
    public detailFormValues$: Observable<{ [k: string]: any }>;
    public compiledFormFields$: Observable<{ [k: string]: any }>;
    public loadingFormFieldEmit = new BehaviorSubject<{ [k: string]: boolean }>(undefined);
    public loadingFormField$ = this.loadingFormFieldEmit.asObservable();
    translateInstant;
    private subScriptions = new Subscription();

    constructor(private fb: UntypedFormBuilder,
                private mapService: MapService,
                private alertService: AlertService,
                private translateService: TranslateService,
                private localStorageService: LocalSotrageService) {
        this.translateInstant = (t) => this.translateService.instant(t)
        const objFormPuntoLuce = this.getFormPuntoLuce()
        this.formGroup = this.fb.group({
            filter: this.fb.group({
                selectedTab: [{value: this.possibleTabs[0].key, disabled: false}],
                typeVisualizedKeys: 'all',
                searchName: undefined,
                favorites: undefined
            }),
            puntoLuce: fb.group(objFormPuntoLuce)
        });
        setTimeout(() => {
            Object.keys(objFormPuntoLuce)
                .filter(key => objFormPuntoLuce[key][0].disabled == true)
                .forEach(key => {
                    if (this.puntoLuceForm.get(key) != null) {
                        this.puntoLuceForm.get(key).disable({emitEvent: false})
                    }
                })
        })
        this.filterForm.get('favorites').setValue(this.localStorageService.favoritesKeyPuntiLuce)
        this.detailFormValues$ = this.puntoLuceForm.valueChanges.pipe(
            startWith(undefined),
            map(() => this.puntoLuceForm.value)
        );
        this.etichetta$ = this.detailFormValues$.pipe(
            map(values => {
                let etitchetta = '';
                let traductions = [];
                if (stringIsSet(this.puntoLuceForm.get('targa').value)) {
                    etitchetta += this.puntoLuceForm.get('targa').value + ' / ';
                    traductions.push('license_plate')
                }
                if (stringIsSet(values.targaCustom)) {
                    etitchetta += values.targaCustom;
                    traductions.push('dashboard_sidenav.PuntiLuce.targaCustom.title')
                }
                if (stringIsSet(etitchetta)) {
                    return {value: etitchetta, traductions};
                } else {
                    return undefined;
                }
            })
        )
        this.compiledFormFields$ = this.detailFormValues$.pipe(
            map(values => {
                if (values == null) {
                    return undefined;
                } else {
                    return Object.keys(values).reduce((prev, key) => {
                        if (prev == null) {
                            prev = {};
                        }
                        if (this.puntoLuceForm.get(key).dirty) {
                            prev[key] = {dirty: true};
                        }
                        return prev;
                    }, undefined as KeyStringValueAny)
                }
            })
        )
        this.subScriptions.add(this.compiledFormFields$
            .pipe(
                filter(values => objectIsSet(values)),
                map(values => Object.keys(values)
                    .reduce((prev, key) => {
                        prev[key] = this.puntoLuceForm.get(key).value;
                        return prev;
                    }, {}))
            )
            .subscribe(values => {
                this.dirtyValues.next(values);
            }))
        this.favorites$ = this.filterForm.get('favorites').valueChanges.pipe(
            startWith(this.filterForm.get('favorites').value),
            map(() => this.filterForm.get('favorites').value)
        )
        this.searchName$ = this.filterForm.get('searchName').valueChanges.pipe(
            startWith(this.filterForm.get('searchName').value),
            map(() => this.filterForm.get('searchName').value),
        )
        this.typeVisualizedKeys$ = this.filterForm.get('typeVisualizedKeys').valueChanges.pipe(
            startWith(this.filterForm.get('typeVisualizedKeys').value),
            map(() => this.filterForm.get('typeVisualizedKeys').value),
        )
        this.subScriptions.add(
            this.favorites$
                .subscribe(favorites => {
                    this.localStorageService.favoritesKeyPuntiLuce = favorites
                })
        )
        this.selectedTab$ = this.filterForm.get('selectedTab').valueChanges.pipe(
            startWith(undefined),
            map(() => this.filterForm.get('selectedTab').value)
        )

    }

    get filterForm(): AbstractControl {
        return this.formGroup.get('filter')
    }

    get puntoLuceForm(): AbstractControl {
        return this.formGroup.get('puntoLuce')
    }

    disableOrEnableForm() {
        if (this.disabled || !this.isGestore || !this.abbonamentoGestioneAttivo) {
            this.formGroup.disable();
        } else {
            this.formGroup.enable();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.disabled) {
            this.disableOrEnableForm();
        }
        if (changes.isGestore) {
            this.disableOrEnableForm();
        }
        if (changes.abbonamentoGestioneAttivo) {
            this.disableOrEnableForm();
        }
    }

    ngOnDestroy(): void {
        this.subScriptions.unsubscribe()
    }

    onTouched: Function = () => {
    };


    registerOnChange(onChange: any) {
        this.subScriptions.add(
            this.puntoLuceForm.valueChanges.pipe(
                map(values => {
                    if (objectIsSet(values)) {
                        const objectId = this.puntoLuceForm.get('objectId').value;
                        return Object.keys(values).reduce((prev, key) => {
                            const dirty = this.puntoLuceForm.get(key).dirty
                            // let type;
                            let value = values[key];
                            // if (value != null && ordinamentoEcampiTraduzioni.PuntiLuce[key] && ordinamentoEcampiTraduzioni.PuntiLuce[key].type) {
                            //     type = ordinamentoEcampiTraduzioni.PuntiLuce[key].type;
                            //     if (type === 'POINTER_PUNTOLUCE') {
                            //         const puntoLuce = this.lightPointsNearest.find(p => p.objectId === values[key].objectId)
                            //         value = puntoLuce;
                            //     } else if (type === 'POINTER_CIRCUITI') {
                            //         const circuito = this.circuiti.find(p => p.objectId === values[key].objectId)
                            //         value = circuito;
                            //     } else if (type === 'POINTER_STRADE') {
                            //         const strada = this.strade.find(p => p.objectId === values[key].objectId)
                            //         value = strada;
                            //     }
                            // }
                            prev[key] = {value, dirty};
                            return prev;
                        }, {objectId})
                    } else {
                        return undefined;
                    }
                })
            ).subscribe(onChange)
        )
    }

    registerOnTouched(onTouched: Function) {
        this.onTouched = onTouched;
    }


    setDisabledState(disabled: boolean) {
        if (disabled) {
            this.formGroup.disable();
        } else {
            this.formGroup.enable();
        }
    }

    writeValue(value: any) {
        this.puntoLuceForm.reset();
        this.puntoLuceForm.get('objectId').reset();
        if (value != null) {
            if (stringIsSet(value.objectId)) {
                this.puntoLuceForm.get('objectId').setValue(value.objectId);
            } else {
                this.puntoLuceForm.get('objectId').reset();
            }
            Object.keys(ordinamentoEcampiTraduzioni.PuntiLuce).forEach(key => {
                if (this.puntoLuceForm.get(key) != null) {
                    if (value[key] != null) {
                        if (stringIsSet(value[key].objectId)) {
                            this.puntoLuceForm.get(key).setValue(value[key].objectId, {emitEvent: false});
                        } else {
                            this.puntoLuceForm.get(key).setValue(value[key], {emitEvent: false});
                        }
                    } else {
                        this.puntoLuceForm.get(key).reset(undefined, {emitEvent: false})
                    }
                    this.puntoLuceForm.get(key).markAsPristine();
                }
                const varianteKey = ordinamentoEcampiTraduzioni.PuntiLuce[key].varianteKey;
                if (stringIsSet(varianteKey) && this.puntoLuceForm.get(varianteKey) != null) {
                    if (value[varianteKey] != null) {
                        if (stringIsSet(value[key].objectId)) {
                            this.puntoLuceForm.get(varianteKey).setValue(value[varianteKey].objectId, {emitEvent: false});
                        } else {
                            this.puntoLuceForm.get(varianteKey).setValue(value[varianteKey], {emitEvent: false});
                        }
                    } else {
                        this.puntoLuceForm.get(varianteKey).reset(undefined, {emitEvent: false})
                    }
                    this.puntoLuceForm.get(varianteKey).markAsPristine();
                }
            })
        }
        setTimeout(() => {
            this.puntoLuceForm.updateValueAndValidity()
        })

    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (!isNotNullOrUndefined(this.puntoLuceForm.validator)) {
            this.puntoLuceForm.setValidators(control.validator)
        }
        if (this.puntoLuceForm.valid) {
            return null
        } else {
            return this.puntoLuceForm.errors;
        }
    }

    getFormByDetailPredicate(currentTabKey: 'state_of_fact' | 'project' | 'images' = undefined): FormFieldsTabsHtmlType[] {
        const values: FormFieldsTabsHtmlType[] = [
            {tab: 'state_of_fact', formFields: []},
            {tab: 'project', formFields: []},
            {tab: 'dashboard_sidenav.images', formFields: []},
        ]
        const allFormFileds = ordinamentoEcampiTraduzioni.PuntiLuce
        if (allFormFileds != null) {
            const getFieldsForm = (key) => {
                let typeForm;
                const type = stringIsSet(allFormFileds[key].type) ? allFormFileds[key].type : '';
                let possibleValues: dataForm[];
                if (arrayIsSet(allFormFileds[key].possibleValues)) {
                    possibleValues = allFormFileds[key].possibleValues.map(p => {
                        return {
                            valueForm: p,
                            traduction: 'dashboard_sidenav.PuntiLuce.' + key + '.possibleValues.' + p,
                            html: p
                        }
                    });
                }
                let tabName;
                if (allFormFileds[key].typeForm != null) {
                    typeForm = allFormFileds[key].typeForm
                } else if (type.toLowerCase() === 'elenco') {
                    typeForm = typeFormValue.ELENCO_APERTO;
                } else if (type.toLowerCase() === 'bool') {
                    typeForm = typeFormValue.RADIO_BUTTON;
                    possibleValues = [
                        {valueForm: true as any, traduction: 'yes', html: 'true'},
                        {valueForm: false as any, traduction: 'no', html: 'false'}
                    ]
                } else if (type.toLowerCase() === 'number') {
                    typeForm = typeFormValue.INPUT_NUMBER;
                } else if (type.toLowerCase() === 'int') {
                    if (arrayIsSet(possibleValues)) {
                        possibleValues = possibleValues.map(pValue => {
                            delete pValue.traduction;
                            return pValue
                        })
                        typeForm = typeFormValue.ELENCO_APERTO;
                    } else {
                        typeForm = typeFormValue.INPUT_NUMBER;
                    }
                } else if (type.toLowerCase() === 'date') {
                    typeForm = typeFormValue.DATE;
                } else if (type.toLowerCase() === 'geo_point') {
                    typeForm = typeFormValue.GEO_POINT;
                } else if (type.toLowerCase() === 'text') {
                    typeForm = typeFormValue.INPUT_TEXT;
                } else {
                    typeForm = typeFormValue.INPUT_TEXT;
                }
                if (typeForm === typeFormValue.IMAGE || (stringIsSet(type) && type.includes('fotoPuntiLuce'))) {
                    tabName = 'dashboard_sidenav.images';
                }
                const traduction = typeForm != typeFormValue.GEO_POINT ? 'dashboard_sidenav.PuntiLuce.' + key + '.title' : undefined;
                const fieldKeys = []
                tabName = stringIsSet(tabName) ? tabName : 'state_of_fact';
                if (currentTabKey == null || currentTabKey == tabName) {
                    const fieldKey = {
                        key,
                        ...allFormFileds[key],
                        possibleValues,
                        typeForm,
                        tab: tabName,
                        traduction
                    };
                    fieldKeys.push(fieldKey)
                }
                tabName = stringIsSet(tabName) && tabName != 'state_of_fact' ? tabName : 'project';
                if ((currentTabKey == null || currentTabKey == tabName) && stringIsSet(allFormFileds[key].varianteKey)) {
                    const varianteFieldKey = {
                        ...allFormFileds[key],
                        possibleValues,
                        typeForm,
                        tab: tabName,
                        key: allFormFileds[key].varianteKey,
                        traduction
                    };
                    fieldKeys.push(varianteFieldKey)
                }
                return fieldKeys;
            }
            const keys = Object
                .keys(allFormFileds)
                .filter(key => allFormFileds[key].showInForm != false)
                .sort((keyA, keyB) => {
                    const sectionA = allFormFileds[keyA].section != null ? allFormFileds[keyA].section : 0;
                    const sectionB = allFormFileds[keyB].section != null ? allFormFileds[keyB].section : 0;
                    if (sectionA === sectionB) {
                        const sortingValueA = allFormFileds[keyA].sortingValue != null ? allFormFileds[keyA].sortingValue : 0;
                        const sortingValueB = allFormFileds[keyB].sortingValue != null ? allFormFileds[keyB].sortingValue : 0;
                        return sortingValueA - sortingValueB;
                    }
                    return sectionA - sectionB;
                })
            let previusSection;
            keys.forEach((key) => {
                const fields = getFieldsForm(key);
                if (arrayIsSet(fields)) {
                    fields.forEach((field, index) => {
                        const possibleValues = arrayIsSet(field.possibleValues) ? field.possibleValues : undefined;
                        const value = {
                            type: field.type,
                            traduction: field.traduction,
                            formControlName: field.key,
                            formGroupName: undefined,
                            possibleValues,
                            required: field.required,
                            disabled: field.editable == false,
                            typeForm: field.typeForm
                        }

                        let indexValues;
                        if (field.tab === 'dashboard_sidenav.images') {
                            indexValues = 2;
                        } else if (field.tab == 'project') {
                            indexValues = 1;
                        } else {
                            indexValues = 0;
                        }
                        const currentSection = field.section;
                        if (previusSection == null || currentSection != previusSection) {
                            let sectionName
                            if (currentSection === 2) {
                                sectionName = 'dashboard_sidenav.management_parameters'
                            } else if (currentSection === 1) {
                                sectionName = 'dashboard_sidenav.electrical_characteristics'
                            } else {
                                sectionName = 'dashboard_sidenav.general_features'
                            }
                            values[indexValues].formFields.push({sectionName});
                        }
                        values[indexValues].formFields.push(value);
                        previusSection = field.section ? field.section : 0;
                    })
                }
            });
            return values.filter(v => arrayIsSet(v.formFields))
        }
        return undefined;
    }

    getFormPuntoLuce(): any {
        // il nome viene caricato in parallelo
        const formFields = this.getFormByDetailPredicate()
        const objectForm = formFields.reduce((objForm, fieldHtml) => {
            fieldHtml.formFields.forEach((field) => {
                const formControlName = field.formControlName;
                const disabled = field.disabled;
                const required = field.required;
                const typeForm = field.typeForm;
                const value = undefined;
                const validatori = getValidator({required, typeForm} as any)
                objForm[formControlName] = [{value: value, disabled}, validatori]
            })
            return objForm;
        }, {objectId: [{value: undefined, disabled: true}]})

        return objectForm;
    }

    predicateFilterFunction(fields: FormFieldsTabsHtmlType, favorites: string[], searchName: string, typeVisualizedKeys: 'all' | 'favorites' | 'compiled' | 'updated', compiledFormFields: {
        [k: string]: { dirty: boolean }
    }, values, translateInstant: (string) => string): FormFieldsHtmlType[] {
        const searchNameIsSet = stringIsSet(searchName) && searchName.length > 2;
        if (searchNameIsSet || (stringIsSet(typeVisualizedKeys) && typeVisualizedKeys != 'all') || arrayIsSet(favorites) && arrayIsSet(fields.formFields)) {
            return fields.formFields.filter(field => {
                let preserve = true;
                if (stringIsSet(field.formControlName)) {
                    if (stringIsSet(typeVisualizedKeys)) {
                        if (typeVisualizedKeys == 'favorites') {
                            preserve = favorites.includes(field.formControlName);
                        } else if (typeVisualizedKeys == 'compiled') {
                            preserve = values[field.formControlName] != null;
                        } else if (typeVisualizedKeys == 'updated') {
                            preserve = compiledFormFields[field.formControlName] != null && compiledFormFields[field.formControlName].dirty === true;
                        }
                    }
                    if (preserve && searchNameIsSet) {
                        const key = stringIsSet(field.traduction) ? translateInstant(field.traduction) : field.formControlName;
                        preserve = cleanToSpecialCharacterAndLowerCaseString(key).includes(cleanToSpecialCharacterAndLowerCaseString(searchName));
                    }
                }
                return preserve
            })
        } else {
            return fields.formFields
        }
    }

    favoriteIsSetPredicate(formControlName: string, favorites: string[]): boolean {
        if (arrayIsSet(favorites)) {
            const index = favorites.indexOf(formControlName)
            return index >= 0;
        } else {
            return false;
        }
    }

    isInLoadiongPredicate(field: FormFieldsHtmlType, loading: { [k: string]: boolean }) {
        return loading == null || loading[field.formControlName] != true;
    }

    unsetValue() {
        this.formGroup.get('value').reset()
    }

    possibleValuesPredicate(field: FormFieldsHtmlType, circuiti: CircuitiParse[], strade: StradeParse[], lightPointsNearest: PuntiLuceParse[]): dataForm[] {
        let possibleValues: dataForm[] = []
        if (stringIsSet(field.type) && field.type.toUpperCase() === 'POINTER_CIRCUITI' && arrayIsSet(circuiti)) {
            possibleValues = circuiti.map(c => {
                const objDataForm: dataForm = {
                    traduction: undefined,
                    html: c.numeroQuadro,
                    valueForm: c.objectId,
                    registerValue: {objectId: c.objectId},
                }
                return objDataForm
            });
        }
        if (stringIsSet(field.type) && field.type.toUpperCase() === 'POINTER_STRADE' && arrayIsSet(strade)) {
            possibleValues = strade.map(s => {
                const objDataForm: dataForm = {
                    traduction: undefined,
                    html: s.nome,
                    valueForm: s.objectId,
                    registerValue: {objectId: s.objectId},
                }
                return objDataForm
            });
        }
        if (stringIsSet(field.type) && field.type.toUpperCase() === 'POINTER_PUNTOLUCE') {
            if (arrayIsSet(lightPointsNearest)) {
                possibleValues = lightPointsNearest.map(p => {
                    const objDataForm: dataForm = {
                        traduction: undefined,
                        html: p.getTarga(),
                        valueForm: p.objectId,
                        registerValue: {objectId: p.objectId},
                    }
                    return objDataForm
                });
            }
        } else if (arrayIsSet(field.possibleValues)) {
            possibleValues = field.possibleValues
        }
        return possibleValues;
    }

    clickFavorite(formControlName: string) {
        const favorites = arrayIsSet(this.filterForm.get('favorites').value) ? [...this.filterForm.get('favorites').value] : [];
        const index = favorites.indexOf(formControlName)
        if (index >= 0) {
            favorites.splice(index, 1)
        } else {
            favorites.push(formControlName);
        }
        this.filterForm.get('favorites').setValue(favorites);
    }

    focusIn(field: FormFieldsHtmlType) {
        const values = (this.puntoLuceForm as UntypedFormGroup).getRawValue();
        let puntoLuce;
        if (stringIsSet(values.objectId)) {
            puntoLuce = new PuntiLuceParse();
            puntoLuce.id = values.objectId;
        }
        if (stringIsSet(field.type) && field.type.toUpperCase() === 'POINTER_PUNTOLUCE') {
            let loadingFormField
            const key = field.formControlName;
            let lightPointsNearest$: Observable<PuntiLuceParse[]>;
            if (arrayIsSet(this.lightPointsNearest)) {
                lightPointsNearest$ = of(this.lightPointsNearest)
            } else {
                if (this.loadingFormFieldEmit.value == null) {
                    loadingFormField = {};
                } else {
                    loadingFormField = {...this.loadingFormFieldEmit.value};
                }
                loadingFormField[key] = true;
                this.loadingFormFieldEmit.next(loadingFormField);
                loadingFormField = {...loadingFormField};
                lightPointsNearest$ = this.mapService.getNearetLightPoint(puntoLuce);
            }
            lightPointsNearest$.subscribe(
                pls => {
                    this.lightPointsNearest = pls;
                    if (loadingFormField && key in loadingFormField) {
                        delete loadingFormField[key];
                        this.loadingFormFieldEmit.next(loadingFormField)
                    }
                },
                error => {
                    this.alertService.error(error);
                    if (loadingFormField && key in loadingFormField) {
                        delete loadingFormField[key];
                        this.loadingFormFieldEmit.next(loadingFormField)
                    }
                }
            )
        }
    }

    possibleTabsPredicate(keys: SelectToolbarItemType[], possibleKeys, abbonamentoGestioneAttivo, abbonamentoTelecontrollo, isGestoreOnLocalStorage, isSviluppatore) {
        if (arrayIsSet(keys)) {

            const filterFuntion = (key: SelectToolbarItemType) => {
                if (key.key === possibleKeys.caricoEsogeno) {
                    return isGestoreOnLocalStorage;
                } else if (key.key === possibleKeys.reportsAggregate) {
                    const circuito = this.puntoLuceForm.get('circuito').value
                    const idMezzanotte = this.puntoLuceForm.get('idMezzanotte').value
                    const idGruppoLightMate = this.puntoLuceForm.get('idGruppoLightMate').value
                    return abbonamentoTelecontrollo && isGestoreOnLocalStorage && circuito != null && (idMezzanotte != null || idGruppoLightMate != null)
                } else if (key.key === possibleKeys.scheduleMaintenance) {
                    return abbonamentoGestioneAttivo && isGestoreOnLocalStorage
                } else if (key.key === possibleKeys.connectedFiles) {
                    return abbonamentoGestioneAttivo && isGestoreOnLocalStorage
                } else if (key.key === possibleKeys.historyLightPoint) {
                    return abbonamentoGestioneAttivo && isGestoreOnLocalStorage && isSviluppatore
                } else if (key.key === possibleKeys.virtualMidNight) {
                    return abbonamentoTelecontrollo && isGestoreOnLocalStorage
                } else {
                    return true;
                }
            }
            return keys.filter(filterFuntion)
        }
        return keys;
    }

}
