import * as Parse from 'parse';
import {Injectable} from '@angular/core';
import {ParseApiService} from './parse-api.service';
import {FilterService} from './filter.service';
import {TranslateService} from '@ngx-translate/core';
import {fromPromise} from 'rxjs/internal-compatibility';
import {CircuitiParse} from '../../models/Circuiti.Parse';
import {PuntiLuceParse, PuntoLuce_v2, ResponseDeleteLightPoints} from '../../models/PuntiLuce.Parse';
import {ProgettiParse} from '../../models/Progetti.Parse';
import {StradeParse} from '../../models/Strade.Parse';
import {ordinamentoEcampiTraduzioni} from '../../models/ordinamentoEcampiTraduzioni';
import {FotoCircuitiParse} from 'src/app/models/FotoCircuiti.Parse';
import {FotoPuntiLuceParse} from 'src/app/models/FotoPuntiLuce.Parse';
import {PictureCameraPointParse} from 'src/app/models/PictureCameraPoint.Parse';
import {
    catchError,
    concatMap,
    expand,
    map,
    switchMap,
} from 'rxjs/operators';
import {FormGroup} from '@angular/forms';
import {ComuniParse} from 'src/app/models/Comuni.Parse';
import {
    arrayIsSet,
    chiaviFiltri,
    className,
    hunaParseFloat,
    stringIsSet,
    isNotNullOrUndefined, PaginationParserequestType, paramsApiParse_v2, fetchParseObject$, fetchParseObjects$
} from '../../models/Models';
import {UserService} from './user.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {EMPTY, Observable, of, Subject} from 'rxjs';
import {GruppiPuntiLuceParse} from "../../models/GruppiPuntiLuce.Parse";
import {DocumentsFileParse} from "../../models/DocumentsFile.Parse";
import {ArredoUrbanoParse} from "../../models/ArredoUrbano.Parse";
import {imgExtensions} from "./file-manager.service";
import {ClusterLuxDataParse} from "../../models/ClusterLuxData.Parse";
import {ProjectService} from "./project.service";
import {LocalSotrageService} from "./local-sotrage.service";
import {CircuitiService} from "./circuiti.service";
import {FotoTipologiaType} from "./foto-tipologia.service";


declare var google: any;


enum Map {
    LATITUDE = 'lat',
    LONGITUDE = 'lng',
    LATITUDE_DEFAULT = 'lat-default',
    LONGITUDE_DEFAULT = 'lng-default',
    ZOOM = 'zoom'
}

//chiamate a parse
export interface InterdistanceLightPoint {
    objectId: string;
    start: Parse.GeoPoint;
    end: Parse.GeoPoint;
    distance: number;
    color: string;
}


export interface RulerInfoWindow {
    lat: number;
    lng: number;
    color: string;
    distance: number;
    persistent: boolean;
}

export interface DistancesRuler {
    objectId: string;
    start;
    color: string;
    polylineInfoWindow: RulerInfoWindow;
}

export interface ElectricLinesMap {
    objectId?: string;
    customObjectId?: string;
    start: { latitude: number, longitude: number };
    color: string;
}


// interdistanza è la media dell array distanze
export interface CloudCalcolaInterdistanzaMedia {
    distanzaTotale: number
    interdistanza: number
    interdistanzaMax: number
    interdistanzaMin: number
    isValid: boolean
    path: any[]
}

export interface paramsForClusterLuxDataQuery {
    coordinate_poligono: number[][], //4 vertici della mappa [ [NE.lat, NE.lng],[NW.lat,NW.lng], [SW.lat,SW.lng],[SE.lat,SE.lng]]
    month: number,
    year: number,
    pageSize?: number,
    page?: number,
    precision: number, //calcolata in base allo zoom
    type: string //LUX, AIR PM10, AIR PM2.5,
    projectId: string,
    isDownload: boolean
}

export enum ActionClickMarker {
    addPoint,
    movePoint,
    extendLine,
    removePoint,
    separePoint,
    keepChange,
    addPointAndKeepChange,
    updatePositionAll,
    updatePositionCurrent
}

export class GeoPointH {
    constructor(latitude: number, longitude: number) {
        this.latitude = latitude;
        this.longitude = longitude;
    }

    public latitude: number;
    public longitude: number;
}

@Injectable({
    providedIn: 'root'
})
export class MapService extends ParseApiService {


    constructor(private filterService: FilterService,
                private userService: UserService,
                protected translate: TranslateService,
                private httpClient: HttpClient,
                private projectService: ProjectService,
                private localStorageService: LocalSotrageService,
                private circuitiService: CircuitiService) {
        super();
        const varianteKeyAssociationCircuiti: object = {};//contine le variante key dei cicuiti
        const varianteKeyAssociationPuntiLuce: object = {};//contine le variante key dei punti luce
        const varianteKeyAssociationPictureCameraPoint: object = {};//contine le variante key dei punti luce
        //viene cotruito un oggetto chiave(varianteKey:key) dal file di ordinamentoecampitraduzione.ts
        Object.keys(this.ordinamentoecampi.PuntiLuce).forEach((key) => {
            if (this.ordinamentoecampi.PuntiLuce[key].hasOwnProperty('varianteKey')) {
                varianteKeyAssociationPuntiLuce[this.ordinamentoecampi.PuntiLuce[key].varianteKey] = key;
            }
        });
        Object.keys(this.ordinamentoecampi.Circuiti).forEach((key) => {
            if (this.ordinamentoecampi.Circuiti[key].hasOwnProperty('varianteKey')) {
                varianteKeyAssociationCircuiti[this.ordinamentoecampi.Circuiti[key].varianteKey] = key;
            }
        });
        Object.keys(this.ordinamentoecampi.PictureCameraPoint).forEach((key) => {
            if (this.ordinamentoecampi.PictureCameraPoint[key].hasOwnProperty('varianteKey')) {
                varianteKeyAssociationPictureCameraPoint[this.ordinamentoecampi.PictureCameraPoint[key].varianteKey] = key;
            }
        });

        this.varianteKeyAssociation = {
            PuntiLuce: varianteKeyAssociationPuntiLuce,
            Circuiti: varianteKeyAssociationCircuiti,
            PictureCameraPoint: varianteKeyAssociationPictureCameraPoint
        };

    }

    private ordinamentoecampi = ordinamentoEcampiTraduzioni;
    public varianteKeyAssociation = {
        PuntiLuce: {},
        Circuiti: {},
        PictureCameraPoint: {}
    };

    public setLat(lat) {
        this.localStorageService.mapLat = lat;
    }

    public setLng(lng) {
        this.localStorageService.mapLng = lng;
    }

    public setZoom(zoom) {
        this.localStorageService.mapZoom = zoom;
    }

    public getLat() {
        const lat = (!!Number(this.localStorageService.mapLat)) ? Number(this.localStorageService.mapLat) : 46;
        return lat;
    }

    public getLng() {
        const lng = (!!Number(this.localStorageService.mapLng)) ? Number(this.localStorageService.mapLng) : 16;
        return lng;
    }

    public getZoom() {
        const zoom = (!!Number(this.localStorageService.mapZoom)) ? Number(this.localStorageService.mapZoom) : 5;
        return zoom;
    }


    public destroyLatLng() {
        this.localStorageService.removeMapItems()
    }

    public destroyAllFilter() {
        this.localStorageService.removeMapItems()
    }

    public async getPuntiLuce(elementiPerPagina?: number, paginaNumero?: number, {filters, lightPoints} = {
        filters: [],
        lightPoints: undefined
    }, idPuntiLuceDaCercare = []) {
        try {
            const progetto = this.getCurrentProjectMy();
            let chiavi: string[] = [];
            let operatori: string[] = [];
            let valori: any[] = [];

            if (filters && filters.length > 0) {
                filters.forEach((x) => {
                    let y = this.filterService.getFilter(x);
                    chiavi.push(y[0]);
                    operatori.push(y[1]);
                    valori.push(y[2]);
                });
            }
            let dataApi;
            if (!!idPuntiLuceDaCercare && idPuntiLuceDaCercare.length > 0) {
                dataApi = {
                    pageSize: elementiPerPagina,
                    requestedPage: paginaNumero,
                    progetto: progetto.id,
                    idAccettabili: idPuntiLuceDaCercare,
                    keys: (chiavi) ? chiavi : [],
                    constraints: (operatori) ? operatori : [],
                    values: (valori) ? valori : []
                };
            } else {
                dataApi = {
                    pageSize: elementiPerPagina,
                    requestedPage: paginaNumero,
                    progetto: progetto.id,
                    circuiti: (lightPoints) ? lightPoints : [],
                    keys: (chiavi) ? chiavi : [],
                    constraints: (operatori) ? operatori : [],
                    values: (valori) ? valori : []
                };
            }
            // ai punti luce senza circuito bisogna dare come objectId NO_CIRC
            let puntiLuceConQuadro;
            puntiLuceConQuadro = await Parse.Cloud.run('getPuntiLuceAndMapIcons',
                dataApi, {});

            const values = puntiLuceConQuadro[0];//qui viene riportata la tabella lightpoint
            const icons = puntiLuceConQuadro[1];//il nome dell' icona associata


            const daRitornare: PuntiLuceParse[] = values.map((item, index) => {
                // console.log("icon",item.icon);
                item.icon = icons[index];//icon non esiste nel database, perciò deve essere aggiunta manualmente
                // console.log("icon",item.icon);
                return item;
            });
            return daRitornare;
        } catch (e) {
            throw e;
        }
    }

    fetchPuntiLuce$(puntiLuce: PuntiLuceParse[]) {
        const res = Parse.Cloud.run('getPuntiLuceAndMapIcons',
            {
                idAccettabili: puntiLuce.map(p => p.objectId)
            }, {});
        return fromPromise(res).pipe(
            map((ligthPointsIcon,) => {
                return ligthPointsIcon[0].map((lightPoint, index) => {
                    const icon = ligthPointsIcon[1][index];
                    lightPoint.icon = icon;
                    return lightPoint;
                })
            })
        );
    }

    public updatePuntiLuce(id, form: any) {
        //l update avviene come
        const editP$ = new PuntoLuce_v2(id).edit$(form)
        return editP$.pipe(
            map(p => {
                return p
            })
        )
        // const getIdByForm = (key) => {
        //     const objectId = form[key] != null && stringIsSet(form[key].objectId) ? form[key].objectId : form[key];
        //     return objectId;
        // }
        // // console.log(form);
        // Object.keys(form).forEach((key) => {
        //     // console.log(key);
        //     //in caso di stringa vuota fa unset sul database
        //     if (typeof form[key] == 'string' && form[key].trim().length == 0 || form[key] == null) {
        //         puntoLuce.unset(key);
        //     } else if (key == 'location') {
        //         //location è di tipo geopoint
        //         const point = new Parse.GeoPoint(Number.parseFloat(form[key].latitude), Number.parseFloat(form[key].longitude));
        //         puntoLuce.location = point;
        //     } else if (key == 'circuito' || key == 'varianteCircuito') {
        //         const circuito = new CircuitiParse();
        //         circuito.objectId = getIdByForm(key);
        //         puntoLuce[key] = circuito;
        //     } else if (key == 'strada') {
        //         const strada = new StradeParse();
        //         strada.objectId = getIdByForm(key);
        //         puntoLuce[key] = strada;
        //     } else if (key == 'puntoLucePadre') {
        //         const pl = new PuntiLuceParse();
        //         pl.objectId = getIdByForm(key);
        //         puntoLuce[key] = pl;
        //     } else {
        //         //il tipo di dato delle varianteKey viene preso dalle key
        //         if (this.varianteKeyAssociation.PuntiLuce[key] == undefined) {
        //             tipoDiDato = this.ordinamentoecampi.PuntiLuce[key].type;
        //         } else {
        //             tipoDiDato = this.ordinamentoecampi.PuntiLuce[this.varianteKeyAssociation.PuntiLuce[key]].type;
        //         }
        //         //viene verificato il tipo di dato da salvare
        //         if (tipoDiDato.toUpperCase() == 'NUMBER' || tipoDiDato.toUpperCase() == 'INT') {
        //             datoDaSalvare = hunaParseFloat(form[key]);
        //         } else {
        //             datoDaSalvare = form[key];
        //         }
        //         // console.log(key,datoDaSalvare);
        //         puntoLuce[key] = datoDaSalvare;
        //     }
        // console.log(key, "P", puntoLuce[key], "F", form[key]);
        // });
        // return fromPromise(puntoLuce.save(null, {sessionToken: this.userService.sessionToken()}))
        //     .pipe(
        //         switchMap((puntoLuce) => this.getIconsPuntiLuce([puntoLuce])),
        //         map(puntoLuce => puntoLuce[0])
        //     );
    }

    private setUnsetPuntoLuce(value, tipo) {
        if (!isNotNullOrUndefined(value)) {
            return value;
        }
        let castedValue = value;
        if (typeof value == 'string' && value.trim().length == 0) {
            castedValue = null;
        } else if (tipo.toUpperCase() == 'INT') {
            const number = parseInt(value);
            castedValue = (isNaN(number)) ? null : number;
        } else if (tipo.toUpperCase() == 'NUMBER') {
            const number = hunaParseFloat(value);
            castedValue = (isNaN(number)) ? null : number;
        } else if (tipo.toUpperCase().includes('POINTER')) {
            if (isNotNullOrUndefined(value.className)) {
                castedValue = value;
            } else {
                castedValue = undefined;
            }
        } else if (tipo.toUpperCase() == 'BOOL') {
            if (typeof value == 'string') {
                castedValue = value.toUpperCase() === 'TRUE';
            } else {
                castedValue = value;
            }
        }
        return castedValue;
    }

    private castPuntiLuce(puntoLuce: PuntiLuceParse): PuntiLuceParse {
        let puntoLuceCastato = puntoLuce;
        Object.keys(ordinamentoEcampiTraduzioni.PuntiLuce).forEach((key) => {
            const tipo = ordinamentoEcampiTraduzioni.PuntiLuce[key].type;
            const varianteKey = ordinamentoEcampiTraduzioni.PuntiLuce[key]['varianteKey'];
            const castedValueKey = this.setUnsetPuntoLuce(puntoLuce[key], tipo);
            if (isNotNullOrUndefined(castedValueKey)) {
                puntoLuceCastato[key] = castedValueKey;
            } else {
                puntoLuceCastato.unset(key);
            }
            if (isNotNullOrUndefined(varianteKey)) {
                const castedValueVarianteKey = this.setUnsetPuntoLuce(puntoLuce[varianteKey], tipo);
                if (isNotNullOrUndefined(castedValueVarianteKey)) {
                    puntoLuceCastato[varianteKey] = castedValueVarianteKey;
                } else {
                    puntoLuceCastato.unset(varianteKey);
                }
            }
        });
        return puntoLuceCastato;
    }

    public updatePuntiLuceBatch(puntiLuce: PuntiLuceParse[]): Promise<PuntiLuceParse[]> {
        puntiLuce.map((puntoLuce) => this.castPuntiLuce(puntoLuce));
        return Parse.Object.saveAll(puntiLuce, {sessionToken: this.userService.sessionToken()});
    }

    public updateLocationPuntiLuce(coordinate: { lat, lng }, id) {
        let puntoLuce = new PuntiLuceParse();
        puntoLuce.id = id;
        const point = new Parse.GeoPoint(Number.parseFloat(coordinate.lat), Number.parseFloat(coordinate.lng));
        puntoLuce.location = point;
        return (fromPromise(puntoLuce.save()));
    }

    public updateAddressPuntiLuce(coordinate: { lat, lng }, id, indirizzo = null) {
        let puntoLuce = new PuntiLuceParse();
        puntoLuce.id = id;
        if (indirizzo == null) {
            const point = {lat: Number.parseFloat(coordinate.lat), lng: Number.parseFloat(coordinate.lng)};
            let geocoder = new google.maps.Geocoder;
            geocoder.geocode({'location': point}, (results) => {
                if (results[0]) {
                    puntoLuce.unset('indirizzo');
                    puntoLuce.indirizzo = results[0].formatted_address;
                } else {
                    console.log('No results found');
                }
            });
        } else {
            puntoLuce.indirizzo = indirizzo;
        }
        return (fromPromise(puntoLuce.save()));
    }

    public updateLocationCircuiti(coordinate: { lat, lng }, id) {
        let circuito = new CircuitiParse();
        circuito.id = id;
        const point = new Parse.GeoPoint(Number.parseFloat(coordinate.lat), Number.parseFloat(coordinate.lng));
        circuito.location = point;
        return (fromPromise(circuito.save()));
    }

    public updateAddressCircuiti(coordinate: { lat, lng }, id, indirizzo = null) {
        let circuito = new CircuitiParse();
        circuito.id = id;
        if (indirizzo == null) {
            const point = {lat: Number.parseFloat(coordinate.lat), lng: Number.parseFloat(coordinate.lng)};
            let geocoder = new google.maps.Geocoder;
            geocoder.geocode({'location': point}, (results) => {
                if (results[0]) {
                    circuito.unset('indirizzo');
                    circuito.indirizzo = results[0].formatted_address;
                } else {
                    console.log('No results found');
                }
            });
        } else {
            circuito.indirizzo = indirizzo;
        }
        return (fromPromise(circuito.save()));
    }

    public updateLocationPuntiCamera(coordinate: { lat, lng }, id) {
        let puntoCamera = new PictureCameraPointParse();
        puntoCamera.id = id;
        const point = new Parse.GeoPoint(Number.parseFloat(coordinate.lat), Number.parseFloat(coordinate.lng));
        puntoCamera.location = point;
        return (fromPromise(puntoCamera.save()));
    }


    public updateCircuito(id, form: object) {
        //l'update avviene come
        let circuito = new CircuitiParse();
        circuito.objectId = id;
        const getIdByForm = (key) => {
            const objectId = form[key] != null && stringIsSet(form[key].objectId) ? form[key].objectId : form[key];
            return objectId;
        }
        let tipoDiDato;
        Object.keys(form).forEach((key) => {
            tipoDiDato = this.ordinamentoecampi.Circuiti[key].type;
            if (typeof form[key] == 'string' && form[key].trim().length == 0 || !isNotNullOrUndefined(form[key])) {
                circuito.unset(key);
            } else {
                if (this.varianteKeyAssociation.Circuiti[key] == undefined) {
                    tipoDiDato = this.ordinamentoecampi.Circuiti[key].type;
                } else {
                    tipoDiDato = this.ordinamentoecampi.Circuiti[this.varianteKeyAssociation.Circuiti[key]].type;
                }
                //viene verificato il tipo di dato da salvare
                if (key == 'circuitoPadre' || key == 'circuitoAccorpamento') {
                    const circuitoPointer = new CircuitiParse();
                    circuitoPointer.id = getIdByForm(key);
                    circuito[key] = circuitoPointer;
                } else if (key == 'foto') {
                    const fotoCircuito = new FotoCircuitiParse();
                    fotoCircuito.id = form[key];
                    circuito[key] = fotoCircuito;
                } else if (tipoDiDato.toUpperCase() == 'NUMBER' || tipoDiDato.toUpperCase() == 'INT') {
                    circuito[key] = hunaParseFloat(form[key]);
                } else if (tipoDiDato.toUpperCase() == 'BOOL') {
                    if (typeof form[key] == 'string') {
                        circuito[key] = form[key].toUpperCase() === 'TRUE';
                    } else {
                        circuito[key] = form[key];
                    }
                } else {
                    circuito[key] = form[key];
                }
            }
        });
        return (fromPromise(circuito.save()));
    }

    public updateLocationQuadro(coordinate: { lat, lng }, id) {
        let quadro = new CircuitiParse();
        quadro.id = id;
        const point = new Parse.GeoPoint(Number.parseFloat(coordinate.lat), Number.parseFloat(coordinate.lng));
        quadro.location = point;
        return (fromPromise(quadro.save()));
    }

    public updateAddressQuadro(coordinate: { lat, lng }, id, indirizzo = null) {
        let quadro = new CircuitiParse();
        quadro.id = id;
        if (indirizzo == null) {
            const point = {lat: Number.parseFloat(coordinate.lat), lng: Number.parseFloat(coordinate.lng)};
            let geocoder = new google.maps.Geocoder;
            geocoder.geocode({'location': point}, (results) => {
                if (results[0]) {
                    quadro.unset('indirizzo');
                    quadro.indirizzo = results[0].formatted_address;
                } else {
                    console.log('No results found');
                }
            });
        } else {
            quadro.indirizzo = indirizzo;
        }
        return (fromPromise(quadro.save()));
    }

    public getComuniByObjectId(id) {
        return fetchParseObject$(ComuniParse.CLASS_NAME, id);
    }

    public getprogettoAttuale() {
        return this.projectService.currentProject.fetch$()
    }


    public async getPuntiLuceReport(reportId) {
        try {
            const progetto = this.getCurrentProjectMy();

            let report = await this.getReport(reportId);

            let light = [];
            report.puntiLuce.forEach(item => {
                light.push(item.objectId);
            });

            //sono due valore da dare getPuntiLuceAndMapIcons
            // pageSize       numero di elementi della query
            // requestedPage  0,1
            const res = await Parse.Cloud.run('getPuntiLuceAndMapIcons',
                {
                    progetto: progetto.id,
                    circuiti: this.filterService.getLocaleLightPoints() ? this.filterService.getLocaleLightPoints() : [],
                    idAccettabili: light,
                    keys: [],
                    constraints: [],
                    values: [],

                }, {});

            let values = res[0];
            const icons = res[1];

            return values.map((item, index) => {
                let x = item;
                x.icon = icons[index];
                return x;
            });
        } catch (e) {
            throw e;
        }
    }

    public async getReport(reportObjectd) {
        try {
            const Segnalazioni = Parse.Object.extend('Segnalazioni');
            const query = new Parse.Query(Segnalazioni);
            const res = await query.equalTo('objectId', reportObjectd).first({sessionToken: this.userService.sessionToken()});
            return res.toJSON();
        } catch (e) {
            throw e;
        }
    }

    getCircuiti$(projectId: string | undefined = undefined, keys: string[] = undefined, constraints: string[] = undefined, values: any[] = undefined, schedeManutenzioneIds: string[] = undefined, documentFileId: string = undefined, idNonAccettabili: string[] = undefined, geoBox: {
        NE: Parse.GeoPoint,
        SW: Parse.GeoPoint
    } = undefined): Observable<CircuitiParse[]> {
        let project;
        if (stringIsSet(projectId)) {
            project = new ProgettiParse()
            project.objectId = projectId;
        }
        return this.circuitiService.getCircuiti$(project, keys, constraints, values, schedeManutenzioneIds, documentFileId, idNonAccettabili, geoBox)
    }

    public getCircuiti(filters?, projectId = undefined) {

        let progetto = new ProgettiParse();
        if (isNotNullOrUndefined(projectId)) {
            progetto.objectId = projectId;
        } else {
            progetto = this.getCurrentProjectMy();
        }
        if (arrayIsSet(filters)) {
            const query = new Parse.Query(CircuitiParse);
            query.equalTo('progetto', progetto);
            query.containedIn('objectId', filters);
            query.ascending('numeroQuadro');
            query.limit(10000);
            return fromPromise(query.find({sessionToken: this.userService.sessionToken()}));
        } else {
            return this.getCircuiti$(progetto.objectId)
        }

    }

    public getStrade(filters?, projectId = undefined) {

        const query = new Parse.Query(StradeParse);
        let progetto = new ProgettiParse();
        if (isNotNullOrUndefined(projectId)) {
            progetto.objectId = projectId;
        } else {
            progetto = this.getCurrentProjectMy();
        }
        query.equalTo('progetto', progetto);

        if (filters) {
            query.containedIn('objectId', filters);
        }
        query.ascending('nome');
        query.limit(1000);
        return fromPromise(query.find({sessionToken: this.userService.sessionToken()}));

    }

    public async getCurrentProject() {
        try {
            return await this.projectService.currentProject.fetch$().toPromise();
        } catch (e) {
            throw e;
        }
    }

    public getCurrentProjectMy() {
        return this.projectService.currentProject;
    }


    public async getRoad(objectId) {
        try {
            const Strade = Parse.Object.extend('Strade');
            const queryRoad = new Parse.Query(Strade);
            return await queryRoad.first(objectId);
        } catch (e) {
            throw e;
        }
    }

    // ritorna il circuito relativo all' ide mandata come parametro
    public getCircuitoByObjectId(objectId) {
        try {
            const query = new Parse.Query(CircuitiParse);
            query.equalTo('objectId', objectId);
            return query.find({sessionToken: this.userService.sessionToken()});
        } catch (e) {
            throw e;
        }
    }

    public async getLightByObjectId(objectId) {
        try {
            const query = new Parse.Query(PuntiLuceParse);
            query.equalTo('objectId', objectId);
            const sessionToken = this.userService.sessionToken();// Parse.User.current().getSessionToken()
            const res = await query.first({sessionToken: sessionToken});
            return res;
        } catch (e) {
            throw e;
        }
    }


    public getPhotoPuntoLuceByObjectId(fotoTipologia: FotoPuntiLuceParse): Observable<FotoTipologiaType> {
        try {
            const params = paramsApiParse_v2({fotoTipologiaId: fotoTipologia.id ?? fotoTipologia.objectId});
            return fromPromise(Parse.Cloud.run('fetchFotoPuntiLuce', params));
        } catch (e) {
            throw e;
        }
    }

    public getPhotoCircuitoByCircuito(circuito: CircuitiParse): Observable<FotoCircuitiParse> {
        try {
            const query = new Parse.Query(FotoCircuitiParse);
            query.equalTo('circuito', circuito);
            const res = query.first({sessionToken: this.userService.sessionToken()});
            return fromPromise(res);
        } catch (e) {
            throw e;
        }
    }

    public getPhotoCircuitoByObjectId(foto: FotoCircuitiParse): Observable<FotoCircuitiParse> {
        try {
            return fetchParseObject$(FotoCircuitiParse.CLASS_NAME, foto.objectId);
        } catch (e) {
            throw e;
        }
    }


    public getuserLastUpdateBy(id) {
        return this.getUserDetail([id]);
    }

    public getUserDetail(ids: string[]): Observable<Parse.User[]> {
        const res = Parse.Cloud.run('getUsers',
            {
                objectsIds: ids
            }, {});
        return (fromPromise(res));
    }

    public getPuntiCamera() {
        const query = new Parse.Query(PictureCameraPointParse);
        if (this.getCurrentProjectMy()) {
            const progetto = this.getCurrentProjectMy();
            query.equalTo('progetto', progetto);
            query.find({sessionToken: Parse.User.current().getSessionToken()});
        }
        return fromPromise(query.find({sessionToken: this.userService.sessionToken()}));
    }

    public getPuntiCameraObjectId(objectId) {
        const query = new Parse.Query(PictureCameraPointParse);
        query.equalTo('objectId', objectId);
        query.find({sessionToken: this.userService.sessionToken()}); //Parse.User.current().getSessionToken()
        return fromPromise(query.first({sessionToken: this.userService.sessionToken()}));
    }

    public updatePuntiCamera(id, form: object) {
        //l update avviene come

        const puntoCamere = new PictureCameraPointParse();
        puntoCamere.id = id;
        let datoDaSalvare;
        let tipoDiDato;
        Object.keys(form).forEach((key) => {
            tipoDiDato = this.ordinamentoecampi.PictureCameraPoint[key].type;
            if (typeof form[key] == 'string' && form[key].trim().length == 0) {
                puntoCamere.unset(key);
            } else {
                if (this.varianteKeyAssociation.PictureCameraPoint[key] == undefined) {
                    tipoDiDato = this.ordinamentoecampi.PictureCameraPoint[key].type;
                } else {
                    tipoDiDato = this.ordinamentoecampi.PictureCameraPoint[this.varianteKeyAssociation.PictureCameraPoint[key]].type;
                }
                //viene verificato il tipo di dato da salvare
                if (tipoDiDato.toUpperCase() == 'NUMBER' || tipoDiDato.toUpperCase() == 'INT') {
                    datoDaSalvare = hunaParseFloat(form[key]);
                } else {
                    datoDaSalvare = form[key];
                }
                puntoCamere[key] = datoDaSalvare;
            }
        });
        return (fromPromise(puntoCamere.save()));
    }

    private puntiLuceSenzaQuadro(progetto) {
        const puntiLuceSenzaQuadro = Parse.Cloud.run('getPuntiLuceAndMapIcons',
            {
                progetto: progetto.id,
                keys: ['circuito'],
                constraints: ['IS NULL'],
                values: []
            }, {});
        return fromPromise(puntiLuceSenzaQuadro);
    }

    private puntiLuceConQuadro(progetto, objectId, lightPoints = null) {
        const puntiLuceConQuadro = Parse.Cloud.run('getPuntiLuceAndMapIcons',
            {
                circuiti: lightPoints,
                idAccettabili: [objectId],
                progetto: progetto.id,
            }, {});
        return fromPromise(puntiLuceConQuadro);
    }


    // ritorna l oggetto punto luce con quell objectid se presente nei filtri
    public getPuntoLuceByObjectIdAndLocalLIghtPoint(objectId, lightPoints = null) {
        const progetto = this.getCurrentProjectMy();

        let nessunQuadroAssente: boolean = true;
        if (!!lightPoints) {
            if (lightPoints.includes(chiaviFiltri.nessunQuadro)) {
                nessunQuadroAssente = false;
            }
        }

        if (nessunQuadroAssente) {
            return this.puntiLuceConQuadro(progetto, objectId, lightPoints).pipe(map(([values, icons]) => {
                if (!!values[0] && !!icons[0]) {
                    values[0].icon = icons[0];
                }
                return values[0];
            }));
        } else {
            //viene ritornato un array che va aggiunto icons e filtrato per object id
            let puntoLuceDaRitornare;
            return this.puntiLuceSenzaQuadro(progetto).pipe(map(([values, icons]) => {
                if (!!values[0] && !!icons[0]) {
                    values.every((puntoLuce, index) => {
                        if (puntoLuce.id == objectId) {
                            puntoLuceDaRitornare = puntoLuce;
                            puntoLuceDaRitornare.icon = icons[index];
                            return false;
                        } else {
                            return true;
                        }
                    });
                }
                return puntoLuceDaRitornare;
            }));
        }
    }

    public getPuntiLuceTramiteCoordinate(spigoli, elementiPerPagina?, paginaNumero?): Observable<PuntiLuceParse[]> {
        const spigoloNe = {
            latitudine: spigoli.getNorthEast().lat(),
            longitudine: spigoli.getNorthEast().lng()
        };
        const spigoloSw = {
            latitudine: spigoli.getSouthWest().lat(),
            longitudine: spigoli.getSouthWest().lng()
        };
        const progetto = this.getCurrentProjectMy();
        // NE, EW gli spigoli del quadrato
        const res = Parse.Cloud.run('getPuntiLuceAndMapIcons',
            {
                pageSize: elementiPerPagina,
                requestedPage: paginaNumero,
                NE: [spigoloSw.latitudine, spigoloSw.longitudine],
                SW: [spigoloNe.latitudine, spigoloNe.longitudine],
                progetto: progetto.id,
            }, {});

        return fromPromise(res).pipe(map(([values, icons]) => {
            return values.map((item, index) => {
                // icon non esiste nel database, perciò deve essere aggiunta manualmente
                item.icon = icons[index];
                return item;
            });
        }));
    }

    public getPuntiLuceByGeoBox(sudWest: Parse.GeoPoint, northEast: Parse.GeoPoint, visualized: PuntiLuceParse[]): Observable<PuntiLuceParse[]> {
        const progetto = this.getCurrentProjectMy();
        // NE, EW gli spigoli del quadrato
        const res = Parse.Cloud.run('getPuntiLuceAndMapIcons',
            {
                NE: [northEast.latitude, northEast.longitude],
                SW: [sudWest.latitude, sudWest.longitude],
                idNonAccettabili: visualized.map(visual => visual.objectId),
                progetto: progetto.id,
            }, {});

        return fromPromise(res).pipe(map(([values, icons]) => {
            return values.map((item, index) => {
                // icon non esiste nel database, perciò deve essere aggiunta manualmente
                item.icon = icons[index];
                return item;
            })
                .concat(visualized);
        }));
    }


    public getCircuitsByGeoBox(sudWest: Parse.GeoPoint, northEast: Parse.GeoPoint, visualized: CircuitiParse[]) {
        const query = new Parse.Query(CircuitiParse);
        query.equalTo('progetto', this.getCurrentProjectMy());
        query.withinGeoBox('location', sudWest, northEast);
        if (arrayIsSet(visualized)) {
            query.notContainedIn('objectId', visualized.map(circuito => circuito.objectId));
        }
        return fromPromise(query.find()).pipe(
            map((newCircuitsInMap) => {
                return arrayIsSet(visualized) ? newCircuitsInMap.concat(visualized) : newCircuitsInMap;
            })
        );
    }

    public getCircuitsByGeoBox$(sudWest: Parse.GeoPoint, northEast: Parse.GeoPoint, visualized: CircuitiParse[]) {
        return this.getCircuiti$(undefined, undefined, undefined, undefined, undefined, undefined, visualized.map(c => c.objectId), {
            NE: northEast,
            SW: sudWest
        })
    }


    public getPuntiLuceTramiteCoordinatePaginate(spigoli: google.maps.LatLngBounds, elementiPerPagina = 1000, idNonAccettabili: string[] = undefined): Observable<{
        puntiLuce: PuntiLuceParse[],
        finished: boolean
    }> {
        const spigoloNe = {
            latitudine: spigoli.getNorthEast().lat(),
            longitudine: spigoli.getNorthEast().lng()
        };
        const spigoloSw = {
            latitudine: spigoli.getSouthWest().lat(),
            longitudine: spigoli.getSouthWest().lng()
        };
        const projectId = this.getCurrentProjectMy().objectId;

        function fetchPage(page = 0) {
            const dataApi = {
                pageSize: elementiPerPagina,
                requestedPage: page,
                progetto: projectId,
                NE: [spigoloSw.latitudine, spigoloSw.longitudine],
                SW: [spigoloNe.latitudine, spigoloNe.longitudine],
            };
            if (Array.isArray(idNonAccettabili) && idNonAccettabili.length > 0) {
                dataApi['idNonAccettabili'] = idNonAccettabili;
            }
            const res = Parse.Cloud.run('getPuntiLuceAndMapIcons',
                dataApi, {});
            return fromPromise(res).pipe(
                map((item) => {
                    const puntiLuce: PuntiLuceParse[] = item[0].map((puntoLuce, index) => {
                        const newPuntoLuce = puntoLuce;
                        puntoLuce.icon = item[1][index];
                        return newPuntoLuce;
                    });
                    const isLast = puntiLuce.length < elementiPerPagina;
                    return {
                        item: puntiLuce,
                        nextPage: isLast ? undefined : (page += 1),
                        finished: isLast
                    };
                }),
            );
        }

        return fetchPage().pipe(
            expand(({nextPage}) => nextPage ? fetchPage(nextPage) : EMPTY),
            concatMap(({item, finished}) => {
                let items = [];
                items.push(item);
                return of({puntiLuce: item, finished: finished});
            }),
        ) as Observable<{ puntiLuce: PuntiLuceParse[], finished: boolean }>;
    }

    public eliminaElemnto(elemtentoDaEliminare: Parse.Object) {
        if (elemtentoDaEliminare.className == className.circuiti && (elemtentoDaEliminare as CircuitiParse).foto != undefined) {
            return fromPromise(elemtentoDaEliminare.destroy()).pipe(
                switchMap(() => {
                        const fotoDaElimnare: CircuitiParse = elemtentoDaEliminare as CircuitiParse;
                        return fromPromise(fotoDaElimnare.foto.destroy());
                    }
                )
            );
        } else {
            return fromPromise(elemtentoDaEliminare.destroy());
        }
    }

    public createNewCircuito(coordinate: { lat, lng }, numeroQuadro): Observable<CircuitiParse> {
        let nuovoCircuito = new CircuitiParse();
        const point = new Parse.GeoPoint(Number.parseFloat(coordinate.lat), Number.parseFloat(coordinate.lng));
        nuovoCircuito.location = point;
        const pointGeoEncode = {lat: Number.parseFloat(coordinate.lat), lng: Number.parseFloat(coordinate.lng)};
        let geocoder = new google.maps.Geocoder;
        geocoder.geocode({'location': pointGeoEncode}, (results) => {
            if (results[0]) {
                nuovoCircuito.indirizzo = results[0].formatted_address;
            } else {
                console.log('No results found');
            }
        });
        nuovoCircuito.progetto = this.getCurrentProjectMy();
        if (stringIsSet(numeroQuadro)) {
            nuovoCircuito.numeroQuadro = numeroQuadro;
        }
        return fromPromise(nuovoCircuito.save());
    }

    public createNewPuntoLuce(coordinate: { lat, lng }, datiPuntoLuce = null): Observable<PuntiLuceParse> {
        const location = new GeoPointH(coordinate.lat, coordinate.lng)
        const newLightPoint$ = new PuntoLuce_v2(undefined).create$(this.getCurrentProjectMy().objectId, location)
        return newLightPoint$.pipe(
            map(p => {
                return p
            })
        )
        // let nuovoPuntoLuce = new PuntiLuceParse();
        // const point = new Parse.GeoPoint(Number.parseFloat(coordinate.lat), Number.parseFloat(coordinate.lng));
        // nuovoPuntoLuce.location = point;
        // const pointGeoEncode = {lat: Number.parseFloat(coordinate.lat), lng: Number.parseFloat(coordinate.lng)};
        // let geocoder = new google.maps.Geocoder;
        // geocoder.geocode({'location': pointGeoEncode}, (results) => {
        //     if (results[0]) {
        //         nuovoPuntoLuce.indirizzo = results[0].formatted_address;
        //     } else {
        //         console.log('No results found');
        //     }
        // });
        // if (!!datiPuntoLuce) {
        //     Object.keys(datiPuntoLuce).forEach((key) => nuovoPuntoLuce[key] = datiPuntoLuce[key]);
        // }
        // nuovoPuntoLuce.progetto = this.getCurrentProjectMy();
        // return fromPromise(nuovoPuntoLuce.save());
    }

    public createNewPuntiCamera(coordinate: { lat, lng }): Observable<PictureCameraPointParse> {
        let nuovoPuntoCamera = new PictureCameraPointParse();
        const point = new Parse.GeoPoint(Number.parseFloat(coordinate.lat), Number.parseFloat(coordinate.lng));
        nuovoPuntoCamera.location = point;
        nuovoPuntoCamera.progetto = this.getCurrentProjectMy();
        return fromPromise(nuovoPuntoCamera.save());
    }


    public getLuxeScale(spigoli, numeroDiOggetti: number) {
        const spigoloNe = {
            latitudine: spigoli.getNorthEast().lat(),
            longitudine: spigoli.getNorthEast().lng()
        };
        const spigoloSe = {
            latitudine: spigoli.getSouthWest().lat(),
            longitudine: spigoli.getNorthEast().lng()
        };
        const spigoloSw = {
            latitudine: spigoli.getSouthWest().lat(),
            longitudine: spigoli.getSouthWest().lng()
        };
        const spigoloNw = {
            latitudine: spigoli.getNorthEast().lat(),
            longitudine: spigoli.getSouthWest().lng()
        };

        const loactionObjectForRequestApi = {
            'location': {
                '$geoWithin': {
                    '$polygon': [

                        {
                            '__type': 'GeoPoint',
                            'latitude': spigoloNe.latitudine,
                            'longitude': spigoloNe.longitudine
                        },
                        {
                            '__type': 'GeoPoint',
                            'latitude': spigoloSe.latitudine,
                            'longitude': spigoloSe.longitudine
                        },
                        {
                            '__type': 'GeoPoint',
                            'latitude': spigoloSw.latitudine,
                            'longitude': spigoloSw.longitudine
                        },
                        {
                            '__type': 'GeoPoint',
                            'latitude': spigoloNw.latitudine,
                            'longitude': spigoloNw.longitudine

                        }
                    ]
                }
            }
        };

        const url = environment.apiParse.urlApiParse + 'LuxData?limit=' + numeroDiOggetti + '&where=' + encodeURIComponent(JSON.stringify(loactionObjectForRequestApi));
        return this.httpClient.get(url, {
            headers: {
                "X-Parse-Application-Id": environment.apiParse.XParseApplicationId,
                "X-Parse-REST-API-Key": environment.apiParse.XParseRESTAPIKey
            }
        });
    }

    public destroyAllCircuit(circuiti: CircuitiParse[]): Observable<CircuitiParse[]> {
        return fromPromise(Parse.Object.destroyAll(circuiti, {
            sessionToken: this.userService.sessionToken(),
            batchSize: 100
        }));
    }

    public destroyAllpuntiLuce(puntiLuce: PuntiLuceParse[]): Observable<PaginationParserequestType<ResponseDeleteLightPoints>> {
        return new PuntoLuce_v2(undefined).deletes$(puntiLuce.map(p => p.objectId), 100)
    }


    private chunkAdvance: Subject<{ progress, error, finished }> = new Subject();

    public getChunkObservable(): Observable<{ progress, error, finished }> {
        return this.chunkAdvance.asObservable();
    }

    private updateRatingSaveChunk(progress, error, finish) {
        this.chunkAdvance.next({progress: progress, error: error, finished: finish});
    }

    private delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    /**
     *  in caso di campo non presente non fa l unset
     * @param puntoLuce
     */
    private castPuntiLuceSenzaUnset(puntoLuce: PuntiLuceParse): PuntiLuceParse {
        let puntoLuceCastato = puntoLuce;
        Object.keys(ordinamentoEcampiTraduzioni.PuntiLuce).forEach((key) => {
            const tipo = ordinamentoEcampiTraduzioni.PuntiLuce[key].type;
            const varianteKey = ordinamentoEcampiTraduzioni.PuntiLuce[key]['varianteKey'];
            const castedValueKey = this.setUnsetPuntoLuce(puntoLuce[key], tipo);
            if (isNotNullOrUndefined(castedValueKey)) {
                puntoLuceCastato[key] = castedValueKey;
            }
            if (typeof castedValueKey !== 'object' && tipo.toUpperCase().includes('POINTER')) {
                puntoLuceCastato.unset(key);
            }
            if (isNotNullOrUndefined(varianteKey)) {
                const castedValueVarianteKey = this.setUnsetPuntoLuce(puntoLuce[varianteKey], tipo);
                if (isNotNullOrUndefined(castedValueVarianteKey)) {
                    puntoLuceCastato[varianteKey] = castedValueVarianteKey;
                }
                if (typeof castedValueVarianteKey !== 'object' && tipo.toUpperCase().includes('POINTER')) {
                    puntoLuceCastato.unset(varianteKey);
                }
            }
        });
        return puntoLuceCastato;
    }

    public castCircuitiSenzaUnset(circuito: CircuitiParse): CircuitiParse {
        let circuitoCastato = circuito;
        Object.keys(ordinamentoEcampiTraduzioni.Circuiti).forEach((key) => {
            const tipo = ordinamentoEcampiTraduzioni.Circuiti[key].type;
            const varianteKey = ordinamentoEcampiTraduzioni.Circuiti[key]['varianteKey'];
            const castedValueKey = this.setUnsetPuntoLuce(circuito[key], tipo);
            if (isNotNullOrUndefined(castedValueKey)) {
                circuitoCastato[key] = castedValueKey;
            }
            if (typeof castedValueKey !== 'object' && tipo.toUpperCase().includes('POINTER')) {
                circuitoCastato.unset(key);
            }
            if (isNotNullOrUndefined(varianteKey)) {
                const castedValueVarianteKey = this.setUnsetPuntoLuce(circuito[varianteKey], tipo);
                if (isNotNullOrUndefined(castedValueVarianteKey)) {
                    circuitoCastato[varianteKey] = castedValueVarianteKey;
                }
                if (typeof castedValueVarianteKey !== 'object' && tipo.toUpperCase().includes('POINTER')) {
                    circuitoCastato.unset(varianteKey);
                }
            }
        });
        return circuitoCastato;
    }


    public async chunkSaveAsyncLightPoint(arrayLightPoint: any[], chunk, castLightPoint: boolean = true): Promise<PuntiLuceParse[]> {
        if (castLightPoint) {
            arrayLightPoint.map((puntoLuce) => {
                this.castPuntiLuceSenzaUnset(puntoLuce);
                puntoLuce.progetto = this.getCurrentProjectMy();
            });
        }
        let objsToSave;
        let processedCounter = 0;
        const response = [];
        const numeroDiStep = Math.ceil(arrayLightPoint.length / chunk);
        for (let i = 0; i < arrayLightPoint.length; i += chunk) {
            const lastElement = (i + chunk < arrayLightPoint.length) ? i + chunk : arrayLightPoint.length;
            objsToSave = arrayLightPoint.slice(i, lastElement);
            try {
                const singleResponse = await Parse.Object.saveAll(objsToSave, {sessionToken: this.userService.sessionToken()});
                response.push(...singleResponse);
                processedCounter += 1;
                this.updateRatingSaveChunk(Math.ceil(processedCounter / numeroDiStep * 100), false, lastElement === arrayLightPoint.length);
            } catch (error) {
                this.updateRatingSaveChunk(Math.ceil(processedCounter / numeroDiStep * 100), error, lastElement === arrayLightPoint.length);
                console.log('errore salvataggio: ', error);
            }
        }
        return response;
    }

    public async chunkSaveAsyncCircuiti(arrayCircuiti: CircuitiParse[], chunk, castLightPoint: boolean = true): Promise<CircuitiParse[]> {
        if (castLightPoint) {
            arrayCircuiti.map((circuito) => {
                console.log(circuito.circuitoPadre)
                console.log(circuito.circuitoAccorpamento)
                this.castCircuitiSenzaUnset(circuito);
                circuito.progetto = this.getCurrentProjectMy();
            });
        }
        let objsToSave;
        let processedCounter = 0;
        const response = [];
        const numeroDiStep = Math.ceil(arrayCircuiti.length / chunk);
        for (let i = 0; i < arrayCircuiti.length; i += chunk) {
            const lastElement = (i + chunk < arrayCircuiti.length) ? i + chunk : arrayCircuiti.length;
            objsToSave = arrayCircuiti.slice(i, lastElement);
            try {
                const singleResponse = await Parse.Object.saveAll(objsToSave, {sessionToken: this.userService.sessionToken()});
                response.push(...singleResponse);
                processedCounter += 1;
                this.updateRatingSaveChunk(Math.ceil(processedCounter / numeroDiStep * 100), false, lastElement === arrayCircuiti.length);
            } catch (error) {
                this.updateRatingSaveChunk(Math.ceil(processedCounter / numeroDiStep * 100), error, lastElement === arrayCircuiti.length);
                console.log('errore salvataggio: ', error);
            }
        }
        return response;
    }


    /**
     * il salvataggio avviene nel database, vengono inviati i json
     * @param puntiLuce i puntiLuce non ancora creati
     * @param soloCircuitoId
     * @param numberElementForCycle il numero di oggetti vengono salvati per ogni paginazione
     */
    public saveOnDataBaseJsonLightPoint(puntiLuce: PuntiLuceParse[], soloCircuitoId: boolean, numberElementForCycle = 100): Observable<{
        progress: number,
        finished: boolean,
        nextPage: number,
        error?: any,
        puntiLuce: any[]
    }> {
        const puntiLuceMainInfo: {
            numeroQuadro,
            location,
            nomeStrada
        } [] = [];
        const puntiLuceJson = [];
        const allPropertyPuntiLuce = new PuntiLuceParse().allPropertyOnParse();
        puntiLuce.forEach((puntoLuce) => {
            // viene spostata la targa in targa custom

            if (stringIsSet(puntoLuce.targa)) {
                puntoLuce.targaCustom = puntoLuce.targa;
            }
            let numeroQuadro = (stringIsSet(puntoLuce.circuito)) ? puntoLuce.circuito : undefined;
            let nomeStrada = (stringIsSet(puntoLuce.strada)) ? puntoLuce.strada : undefined;
            puntiLuceMainInfo.push({
                numeroQuadro,
                location: [puntoLuce.location.latitude, puntoLuce.location.longitude],
                nomeStrada
            });
            const obj = {};
            if (stringIsSet((puntoLuce as any).myObjectId)) {
                obj['objectId'] = (puntoLuce as any).myObjectId;
            }
            const notImportKey = ['circuito', 'progetto', 'varianteCircuito', 'foto', 'fotoTipologia', 'strada', 'puntoLucePadre', 'schedeManutenzione', 'files', 'lastUpdatedBy', 'objectId'];
            allPropertyPuntiLuce.forEach(key => {
                if (!notImportKey.includes(key)) {
                    obj[key] = (isNotNullOrUndefined(puntoLuce[key])) ? puntoLuce[key] : null;
                }
            });
            //console.log(obj)
            puntiLuceJson.push(obj);
        })

        // simulate network request
        const projectId = this.getCurrentProjectMy().id;

        const lengthArray = puntiLuce.length;

        function fetchPage(page = 0) {
            const firstElement = page * numberElementForCycle;
            const lastElement = (firstElement + numberElementForCycle < lengthArray) ? firstElement + numberElementForCycle : lengthArray;

            const res = Parse.Cloud.run('getInfoForImportPuntiLuce',
                {
                    progettoId: projectId,
                    puntiLuceMainInfo: puntiLuceMainInfo.slice(firstElement, lastElement),
                    puntiLuce: puntiLuceJson.slice(firstElement, lastElement),
                    checkIfExists: !soloCircuitoId,
                }, {});
            return fromPromise(res).pipe(
                map(() => {
                    return {
                        progress: Math.round(lastElement / lengthArray * 100),
                        finished: lastElement >= lengthArray,
                        nextPage: (page + 1) * numberElementForCycle >= lengthArray ? undefined : (page += 1),
                        puntiLuce: puntiLuceJson.slice(firstElement, lastElement),
                    };
                }),
                catchError((error) => {
                    return of({
                        progress: Math.round(lastElement / lengthArray * 100),
                        finished: lastElement >= lengthArray,
                        nextPage: (page + 1) * numberElementForCycle >= lengthArray ? undefined : (page += 1),
                        error,
                        puntiLuce: puntiLuceJson.slice(firstElement, lastElement),
                    })
                })
            );
        }

        return fetchPage().pipe(
            expand(({nextPage}) => nextPage ? fetchPage(nextPage) : EMPTY)
        );
    }

    public paginateSaveCircuiti(circuiti: CircuitiParse[], numberElementForCycle, castLightPoint: boolean = true): Observable<{
        progress: number,
        finished: boolean,
        nextPage: number,
        circuitiConCircuitiPadre: any[],
        circuitiSalvati: CircuitiParse[]
    }> {

        const lengthArray = circuiti.length;
        const progetto = this.getCurrentProjectMy();
        const castCircuitiSenzaUnset = (circuito) => this.castCircuitiSenzaUnset(circuito);

        function fetchPage(page = 0, circuitiGiaSalvati = [], circuitiConCircuitiPadreGiaSalvati = []) {
            const firstElement = page * numberElementForCycle;
            const lastElement = (firstElement + numberElementForCycle < lengthArray) ? firstElement + numberElementForCycle : lengthArray;
            let chunckCircuiti = [];

            const circuitiConCircuitiPadre = [];
            if (castLightPoint) {
                chunckCircuiti = circuiti.slice(firstElement, lastElement).map((circuito, index) => {
                    if (stringIsSet(circuito.circuitoPadre) || stringIsSet(circuito.circuitoAccorpamento)) {
                        // per la proprietà dei singleton viene associato l objectId dopo effettuato il salvataggio
                        circuitiConCircuitiPadre.push({
                            index: index,
                            circuito: undefined,
                            circuitoPadre: circuito.circuitoPadre,
                            circuitoAccorpamento: circuito.circuitoAccorpamento
                        });

                    }
                    castCircuitiSenzaUnset(circuito);
                    if (circuito.progetto == null) {
                        circuito.progetto = progetto;
                    }
                    return circuito
                });
            }
            const res = Parse.Object.saveAll(chunckCircuiti, {batchSize: numberElementForCycle});
            return fromPromise(res).pipe(
                map((circuiti) => {
                    circuitiConCircuitiPadre.forEach(
                        circuitoCircuitoPadre => {
                            circuitoCircuitoPadre.circuito = chunckCircuiti[circuitoCircuitoPadre.index]
                        }
                    )
                    return {
                        progress: Math.round(lastElement / lengthArray * 100),
                        finished: lastElement >= lengthArray,
                        nextPage: (page + 1) * numberElementForCycle >= lengthArray ? undefined : (page += 1),
                        circuitiSalvati: circuitiGiaSalvati.concat(circuiti),
                        circuitiConCircuitiPadre: circuitiConCircuitiPadreGiaSalvati.concat(circuitiConCircuitiPadre)
                    };
                }),
            );
        }

        return fetchPage().pipe(
            expand(({
                        nextPage,
                        circuitiSalvati,
                        circuitiConCircuitiPadre
                    }) => nextPage ? fetchPage(nextPage, circuitiSalvati, circuitiConCircuitiPadre) : EMPTY)
        );
    }

    public paginateUpdateElements(elements: any[], numberElementForCycle, className): Observable<any> {

        const lengthArray = elements.length;

        function fetchPage(page = 0, elementiSalvati = []) {
            const firstElement = page * numberElementForCycle;
            const lastElement = (firstElement + numberElementForCycle < lengthArray) ? firstElement + numberElementForCycle : lengthArray;
            const res = Parse.Object.saveAll(elements.slice(firstElement, lastElement), {batchSize: numberElementForCycle});
            return fromPromise(res).pipe(
                map((elements) => {
                    const obj = {};
                    obj['progress'] = Math.round(lastElement / lengthArray * 100);
                    obj['finished'] = lastElement >= lengthArray;
                    obj['nextPage'] = (page + 1) * numberElementForCycle >= lengthArray ? undefined : (page += 1);
                    obj[className] = elementiSalvati.concat(elements);
                    return obj
                }),
            );
        }

        return fetchPage().pipe(
            expand((val: any) => {
                    if (val.nextPage) {
                        return fetchPage(val.nextPage, val[className]);
                    } else {
                        return EMPTY
                    }
                }
            )
        );
    }


    getCircuitiFromNumeroQuadro(numeriQuadro: string[]): Observable<CircuitiParse[]> {
        const query = new Parse.Query(CircuitiParse);
        query.equalTo('progetto', this.getCurrentProjectMy());
        query.containedIn('numeroQuadro', numeriQuadro);
        return fromPromise(query.find({sessionToken: this.userService.sessionToken()}));
    }


    getInterdistanceLightPoint(objectIdLIghtPoints: String[]): Observable<CloudCalcolaInterdistanzaMedia> {
        const res = Parse.Cloud.run('calcolaInterdistanzaMedia_v2',
            {
                puntiLuceIds: objectIdLIghtPoints
            }, {});
        return fromPromise(res);
    }


    public getLightPointPaginate(numberElementForCycle = 100, circuiti = [], advancedFileter = undefined, idPuntiLuceDaCercare: string[] = undefined): Observable<{
        puntiLuce: PuntiLuceParse[],
        finished: boolean
    }> {
        // simulate network request
        const projectId = this.getCurrentProjectMy().objectId;
        let chiavi: string[] = [];
        let operatori: string[] = [];
        let valori: any[] = [];
        if (Array.isArray(advancedFileter) && advancedFileter.length > 0) {
            advancedFileter.forEach((x) => {
                let y = this.filterService.getFilter(x);
                chiavi.push(y[0]);
                operatori.push(y[1]);
                valori.push(y[2]);
            });
        }
        let dataApi;

        function fetchPage(page = 0) {
            if (!Array.isArray(idPuntiLuceDaCercare)) {
                dataApi = {
                    pageSize: numberElementForCycle,
                    requestedPage: page,
                    progetto: projectId,
                    circuiti: circuiti,
                    keys: (chiavi) ? chiavi : [],
                    constraints: (operatori) ? operatori : [],
                    values: (valori) ? valori : []
                };
            } else {
                dataApi = {
                    pageSize: numberElementForCycle,
                    requestedPage: page,
                    progetto: projectId,
                    circuiti: circuiti,
                    keys: (chiavi) ? chiavi : [],
                    constraints: (operatori) ? operatori : [],
                    values: (valori) ? valori : [],
                    idAccettabili: idPuntiLuceDaCercare
                };
            }

            const res = Parse.Cloud.run('getPuntiLuceAndMapIcons',
                dataApi, {});
            return fromPromise(res).pipe(
                map((item) => {
                    const puntiLuce: PuntiLuceParse[] = item[0].map((puntoLuce, index) => {
                        const newPuntoLuce = puntoLuce;
                        puntoLuce.icon = item[1][index];
                        return newPuntoLuce;
                    });
                    const isLast = puntiLuce.length < numberElementForCycle;
                    return {
                        item: puntiLuce,
                        nextPage: isLast ? undefined : (page += 1),
                        finished: isLast
                    };
                })
            );
        }

        return fetchPage().pipe(
            expand(({nextPage}) => nextPage ? fetchPage(nextPage) : EMPTY),
            concatMap(({item, finished}) => {
                let items = [];
                items.push(item);
                return of({puntiLuce: item, finished: finished});
            }),
        ) as Observable<{ puntiLuce: PuntiLuceParse[], finished: boolean }>;
    }


    getLightPointByAdvancedFilter(values: any): Observable<PuntiLuceParse[]> {
        const projectId = this.getCurrentProjectMy().objectId;
        const dataApi = {
            progetto: projectId,
        };
        if (values != null) {
            Object.keys(values).forEach(key => {
                dataApi[key] = values[key]
            })
        }
        const res = Parse.Cloud.run('getPuntiLuceAndMapIcons',
            dataApi, {});
        return fromPromise(res).pipe(
            map((item) => {
                const puntiLuce: PuntiLuceParse[] = item[0].map((puntoLuce, index) => {
                    const newPuntoLuce = puntoLuce;
                    puntoLuce.icon = item[1][index];
                    return newPuntoLuce;
                });
                return puntiLuce
            })
        );
    }


    refreshParseObject<T>(objectUpdate: T []): Observable<T[]> {
        if (arrayIsSet(objectUpdate)) {
            const className = (objectUpdate[0] as any).className
            const includes = ['progetto']
            return fetchParseObjects$(className, objectUpdate.map((o: any) => o.objectId), includes)
        } else {
            return of([]);
        }
    }

    getNearetLightPoint(puntoLuce: PuntiLuceParse) {
        const query = new Parse.Query(PuntiLuceParse);
        query.equalTo('progetto', this.getCurrentProjectMy());
        // query.near("location", geoPoint);
        query.notEqualTo("objectId", puntoLuce.objectId);
        query.withinKilometers("location", puntoLuce.location, 0.005, true);
        query.limit(5);
        return fromPromise(query.find());
    }

    public raggruppaPuntiLuce(objectIdLIghtPoints: string[]): Observable<PuntiLuceParse[]> {
        const res = Parse.Cloud.run('unisciPuntiLuceVicini',
            {
                puntiLuceIds: objectIdLIghtPoints
            }, {});
        return fromPromise(res);
    }

    distanceBetweenPoint(from: { latitude: number, longitude: number }, to: {
        latitude: number,
        longitude: number
    }): number {
        const fromGeoPoint = new Parse.GeoPoint(from.latitude, from.longitude);
        const toGeoPoint = new Parse.GeoPoint(to.latitude, to.longitude);
        return Math.round(fromGeoPoint.kilometersTo(toGeoPoint) * 1000 * 100) / 100;
    }


    fetchCircuiti(circuiti: CircuitiParse[]): Observable<CircuitiParse[]> {
        return fromPromise(Parse.Object.fetchAll(circuiti, {sessionToken: this.userService.sessionToken()}))
    }

    getLuxDataClusterInBounds(params: paramsForClusterLuxDataQuery): Observable<{
        clusterLuxData: ClusterLuxDataParse[],
        mst: mstType[]
    }> {
        const result = Parse.Cloud.run('getLuxDataCluster', params);
        return fromPromise(result);
    }

    queryProvaCluster() {
        let query = new Parse.Query('ClusterLuxData')
        query.limit(100)
        const params = {
            coordinate_poligono: [[45.84478503607852, 10.587100476173074], [45.29586096118372, 10.587100476173074],
                [45.29586096118372, 8.621921032813699], [45.84478503607852, 8.621921032813699]],
            month: 3,
            year: 2021,
            type: "LUX",
            projectId: this.getCurrentProjectMy().objectId
        }

        query.equalTo("referenceMonth", 3)
        return fromPromise((query.find()))
    }

    getGruppiPuntiLuce(projectId: string = undefined) {
        let progetto;
        if (isNotNullOrUndefined(projectId)) {
            progetto = new ProgettiParse()
            progetto.objectId = projectId;
        } else {
            progetto = this.getCurrentProjectMy()
        }
        return new GruppiPuntiLuceParse().getGroupsLightPoint$(progetto)
    }

    getIconsPuntiLuce(puntiLuce: PuntiLuceParse[]): Observable<PuntiLuceParse[]> {
        const keyForGetIcons = ['tipologiaCorpoIlluminante', 'tipologiaSostegno', 'numeroLampade', 'artistico'];
        const tiplogieDiverse = [];
        puntiLuce.forEach(pl => {
            const forGetIcons = {};
            keyForGetIcons.forEach(key => {
                if (isNotNullOrUndefined(pl[key])) {
                    forGetIcons[key] = pl[key];
                } else {
                    forGetIcons[key] = null;
                }
            });
            tiplogieDiverse.push(forGetIcons);
        });
        const iconsQuery = Parse.Cloud.run('getPuntiLuceIcons',
            {puntiLuceData: tiplogieDiverse}, {})
        return fromPromise(iconsQuery).pipe(
            map(icons => {
                return puntiLuce.map((pl, index) => {
                    pl.icon = icons[index].iconName;
                    return pl
                })
            })
        )
    }

    getPuntiLuceDelGruppo(gruppoId: string): Observable<PuntiLuceParse[]> {
        const query = new Parse.Query(GruppiPuntiLuceParse);
        query.equalTo('progetto', this.getCurrentProjectMy());
        query.equalTo('objectId', gruppoId);
        return fromPromise(query.first()).pipe(
            switchMap(gruppo => {
                const queryPl = gruppo.puntiLuce.query()
                queryPl.limit(20000)
                return fromPromise(queryPl.find())
            }),
            switchMap((puntiLuce: PuntiLuceParse[]) => {
                return this.getIconsPuntiLuce(puntiLuce).pipe(
                    map(icons => {
                        return puntiLuce.map((pl, index) => {
                            pl.gruppiId = [gruppoId];
                            return pl
                        })
                    })
                )
            })
        )
    }


    addFilesRelationLightPoint(lightPoint: PuntiLuceParse, files: DocumentsFileParse[]): Observable<{
        lightPoint: PuntiLuceParse,
        files: DocumentsFileParse[]
    }> {
        files.forEach(file => {
            lightPoint.files.add(file);
        })
        return fromPromise(lightPoint.save())
            .pipe(
                map(lightPoint => {
                    return {lightPoint, files}
                })
            )
    }

    addFilesRelationLightPoints(lightPoints: PuntiLuceParse[], files: DocumentsFileParse[]): Observable<{
        lightPoints: PuntiLuceParse[],
        files: DocumentsFileParse[]
    }> {
        lightPoints.forEach(
            lightPoint => {
                files.forEach(file => {
                    lightPoint.files.add(file);
                })
            }
        );
        return fromPromise(Parse.Object.saveAll(lightPoints))
            .pipe(
                map(lightPoints => {
                    return {lightPoints, files}
                })
            )
    }

    addFilesRelationCircuit(circuit: CircuitiParse, files: DocumentsFileParse[]): Observable<{
        circuit: CircuitiParse,
        files: DocumentsFileParse[]
    }> {
        files.forEach(file => {
            circuit.files.add(file);
        })
        return fromPromise(circuit.save()).pipe(
            map(circuit => {
                return {circuit, files}
            })
        )
    }

    addFilesRelationGeneric(item: any, files: DocumentsFileParse[]): Observable<{
        item: any,
        files: DocumentsFileParse[]
    }> {
        files.forEach(file => {
            item.files.add(file);
        })
        return fromPromise(item.save()).pipe(
            map(item => {
                return {item, files}
            })
        )
    }

    addFilesRelationCircuits(circuits: CircuitiParse[], files: DocumentsFileParse[]): Observable<{
        circuits: CircuitiParse[],
        files: DocumentsFileParse[]
    }> {
        circuits.forEach(
            circuit => {
                files.forEach(file => {
                    circuit.files.add(file);
                })
            }
        )
        return fromPromise(Parse.Object.saveAll(circuits)).pipe(
            map(circuits => {
                return {circuits, files}
            })
        )
    }

    removeFilesRelationLightPoint(lightPoint: PuntiLuceParse, files: DocumentsFileParse[]): Observable<PuntiLuceParse> {
        files.forEach(file => {
            lightPoint.files.remove(file);
        })
        return fromPromise(lightPoint.save())
    }

    removeFilesRelationCircuit(circuit: CircuitiParse, files: DocumentsFileParse[]): Observable<CircuitiParse> {
        files.forEach(file => {
            circuit.files.remove(file);
        })
        return fromPromise(circuit.save())
    }

    removeFilesRelationGeneric(item: CircuitiParse, files: DocumentsFileParse[]): Observable<CircuitiParse> {
        files.forEach(file => {
            item.files.remove(file);
        })
        return fromPromise(item.save())
    }

    getDocumentsFile(element: CircuitiParse | PuntiLuceParse | ArredoUrbanoParse): Observable<DocumentsFileParse[]> {
        if (element.files != null && element.files.query() != null) {
            const query = element.files.query()
            query.limit(10000);
            query.include('parentFolder');
            return fromPromise(query.find()).pipe(
                map(value => {
                    return value as DocumentsFileParse[]
                })
            );
        } else {
            return of([]);
        }
    }

    getDocumentsFileImage(element: CircuitiParse | PuntiLuceParse | ArredoUrbanoParse): Observable<DocumentsFileParse[]> {
        if (element.files != null) {
            const getParams = () => {
                const classNameObject = (element as any).className
                if (classNameObject === className.circuiti) {
                    return {circuitoId: element.objectId}
                } else if (classNameObject === className.puntiLuce) {
                    return {puntoLuceId: element.objectId}
                } else if (classNameObject === className.caricoEsogeno) {
                    return {caricoEsogenoId: element.objectId}
                }

            }
            const params = paramsApiParse_v2(getParams());
            return fromPromise(Parse.Cloud.run('getImagesByElement', params));
        } else {
            return of([]);
        }
    }

    getDocumentsFileNotImage(element: CircuitiParse | PuntiLuceParse | ArredoUrbanoParse): Observable<DocumentsFileParse[]> {
        if (element.files != null) {
            const getParams = () => {
                const classNameObject = (element as any).className
                if (classNameObject === className.circuiti) {
                    return {circuitoId: element.objectId}
                } else if (classNameObject === className.puntiLuce) {
                    return {puntoLuceId: element.objectId}
                } else if (classNameObject === className.caricoEsogeno) {
                    return {caricoEsogenoId: element.objectId}
                }

            }
            const params = paramsApiParse_v2(getParams());
            return fromPromise(Parse.Cloud.run('getFilesByElement', params));
        } else {
            return of([]);
        }
    }

// aggiorna qualsiasi oggetto di parse
    updateAll(elementToSave, chunk = 100) {
        return fromPromise(Parse.Object.saveAll(elementToSave, {batchSize: chunk}))
    }

    getUrlNavigator(origin: { lat, lng }, destination: { lat, lng }) {
        return 'https://www.google.com/maps/dir/?api=1&origin=' + origin.lat + ',' + origin.lng + '&destination=' + destination.lat + ',' + destination.lng + '&travelmode=driving';
    }

    getDistinctValueForKey(keys: string[], className) {
        const res = Parse.Cloud.run('getDistinctValueForKey',
            {
                progettoId: this.getCurrentProjectMy().objectId,
                className: className,
                keys: keys
            }, {});
        return (fromPromise(res));
    }

}

export interface positionMarker {
    id: string;
    latitudine: number;
    longitudine: number;
    indirizzo: string;
    nomeClasse: string;
    line?: ElectricLinesMap;
    lineParseElements?: any;
}

export type mstType =
    {
        v: number;
        w: number;
        weight: number;
    }