<div>
    <form [formGroup]="formFilter" *ngIf="!!formFilter">
        <mat-slider
                formControlName="min"
                [ngStyle]="{width:widthSxSlider+'%',maxWidth:widthSxSlider+'%'}"
                style="margin-right: -10px"
                thumbLabel
                [tickInterval]="tickInterval"
                [step]="step"
                [min]="min"
                [max]="maxSet"
                [displayWith]="displayLabel"
                [maxlength]="widthSxSlider"
                invert
                aria-label="units">
        </mat-slider>
        <mat-slider
                formControlName="max"
                [ngStyle]="{width:widthDxSlider+'%',maxWidth:widthDxSlider+'%'}"
                [maxlength]="widthDxSlider"
                style="margin-left: -6px"
                thumbLabel
                [tickInterval]="tickInterval"
                [step]="step"
                [min]="minSet"
                [max]="max"
                aria-label="units">
        </mat-slider>
    </form>
</div>
