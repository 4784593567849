import {PuntiLuceParse} from "../../models/PuntiLuce.Parse";
import {isNotNullOrUndefined, stringIsSet} from "../../models/Models";

import {CircuitiParse} from "../../models/Circuiti.Parse";
import {element} from "protractor";


export interface colorElement {
    color: string,
    element: PuntiLuceErrori[] | CircuitiErrori[]
}

export interface updateAllVector {
    allUpdated: boolean,
    lastFieldUpdated: string
}

export enum LevelError {
    warning,
    error
}

export interface forHtmlVisualizedEditor {
    title,
    dataTable: {
        key: string,
        type: string,
        possibleValues,
        value,
        error,
        sortingValue: number
    }[]
}

export const messageError = {
    number: 'notFloatNumber',
    int: 'notIntNumber',
    bool: 'notBooleanValue',
    elenco: 'notPossibleValues',
    uniqueValue: 'valueIsPresent',
};

export const colorError = {
    noError: '#0f7602',
    warning: '#ff970c',
    error: '#FF0000'
}

export interface errorType {
    level: string | number,
    message: string,
    key: string,
}

export class PuntiLuceErrori extends PuntiLuceParse {
    private _error: errorType[];
    private _myLocalId: string;
    private _positionInFile: string | number;
    myObjectId: string;

    get localOrObjectId(): string {
        if (stringIsSet(this.objectId)) {
            return this.objectId
        } else if (stringIsSet(this.myObjectId)) {
            return this.myObjectId
        } else {
            return this.myLocaId;
        }
    }

    public get error(): errorType[] {
        return this._error;
    }

    public set error(value: errorType[]) {
        if (isNotNullOrUndefined(this.error)) {
            this._error = this._error.concat(value);
        } else {
            this._error = value;
        }
    }

    public hasError(): boolean {
        return (isNotNullOrUndefined(this.error)) ? this.error.length > 0 : false;
    }

    public get myLocaId(): string {
        return this._myLocalId;
    }

    public set myLocaId(value) {
        this._myLocalId = value;
    }

    public get positionInFile(): string | number {
        return this._positionInFile;
    }

    public set positionInFile(value) {
        this._positionInFile = value;
    }


}

export class CircuitiErrori extends CircuitiParse {
    private _error: errorType[];
    private _myLocalId: string;
    private _positionInFile: string | number;
    myObjectId: string;


    public get error(): errorType[] {
        return this._error;
    }

    public set error(value: errorType[]) {
        if (isNotNullOrUndefined(this.error)) {
            this._error = this._error.concat(value);
        } else {
            this._error = value;
        }
    }

    public hasError(): boolean {
        return (isNotNullOrUndefined(this.error)) ? this.error.length > 0 : false;
    }


    public get myLocaId(): string {
        return this._myLocalId;
    }

    public set myLocaId(value) {
        this._myLocalId = value;
    }

    get localOrObjectId(): string {
        if (stringIsSet(this.objectId)) {
            return this.objectId
        } else if (stringIsSet(this.myObjectId)) {
            return this.myObjectId
        } else {
            return this.myLocaId;
        }
    }

    public get positionInFile(): string | number {
        return this._positionInFile;
    }

    public set positionInFile(value) {
        this._positionInFile = value;
    }
}


