import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InfoPrintStreetComponent} from './info-print-street/info-print-street.component';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';
import {DialogPopUpService} from '../../providers/services/dialog-pop-up.service';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {WidgetsModule} from '../../widgets/widgets.module';
import {TranslateModule} from '@ngx-translate/core';
import {CreateUpdatePhotometryComponent} from './create-update-photometry/create-update-photometry.component';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatLegacySliderModule as MatSliderModule} from '@angular/material/legacy-slider';
import {ReactiveFormsModule} from '@angular/forms';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatSortModule} from '@angular/material/sort';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {BoxesModule} from '../../boxes/boxes.module';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {VisualizedImageComponent} from './visualized-image/visualized-image.component';
// import {AgmCoreModule} from '@agm/core';
import {SelectedUsersInTableComponent} from './selected-users-in-table/selected-users-in-table.component';
import {MatLegacyPaginatorModule as MatPaginatorModule} from '@angular/material/legacy-paginator';
import {ConfirmElementBeforeSaveComponent} from './confirm-element-before-save/confirm-element-before-save.component';
import {ConfirmDeleteWithNoteComponent} from './confirm-delete-with-note/confirm-delete-with-note.component';
import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio';
import {SelectUserFromListComponent} from './select-user-from-list/select-user-from-list.component';
import {PipesModule} from '../../providers/pipes/pipes.module';
import {ExpandDetailComponent} from './expand-detail/expand-detail.component';
import {ScheduleMaintenanceDetailComponent} from './schedule-maintenance-detail/schedule-maintenance-detail.component';
import {VisualizedFormSchedeComponent} from './visualized-array-values/visualized-form-schede';
import {FormFieldComponent} from './form-field/form-field.component';
import {ImportLocalizedImageComponent} from './import-localized-image/import-localized-image.component';
import {SelectPhotometryComponent} from './select-photometry/select-photometry.component';
import {SelectCaricoEsogenoComponent} from './select-carico-esogeno/select-carico-esogeno.component';
import {MatRippleModule} from '@angular/material/core';
import {NgxDropzoneModule} from "ngx-dropzone";
import {GoogleMapsModule} from "@angular/google-maps";

//todo aggiornanre mappa

@NgModule({
    declarations: [InfoPrintStreetComponent, CreateUpdatePhotometryComponent, VisualizedImageComponent, SelectedUsersInTableComponent, ConfirmElementBeforeSaveComponent, ConfirmDeleteWithNoteComponent, SelectUserFromListComponent, ExpandDetailComponent, ScheduleMaintenanceDetailComponent, VisualizedFormSchedeComponent, FormFieldComponent, ImportLocalizedImageComponent, SelectPhotometryComponent, SelectCaricoEsogenoComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        WidgetsModule,
        TranslateModule,
        MatCardModule,
        MatSliderModule,
        ReactiveFormsModule,
        MatTableModule,
        MatSortModule,
        MatCheckboxModule,
        MatListModule,
        DragDropModule,
        BoxesModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressSpinnerModule,
        ScrollingModule,
        // AgmCoreModule,
        MatPaginatorModule,
        MatRadioModule,
        PipesModule,
        MatRippleModule,
        MatIconModule,
        NgxDropzoneModule,
        GoogleMapsModule
    ],
    exports: [InfoPrintStreetComponent, CreateUpdatePhotometryComponent],
    providers: [DialogPopUpService]
})
export class PopUpInfoModule {
}
