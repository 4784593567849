import {
    Component,
    OnInit,
    ViewChild,
    Input,
    ElementRef,
    SimpleChanges,
    AfterViewChecked,
    Output,
    EventEmitter
} from '@angular/core';
import {
    LayoutItemResult,
    ColorMapItem,
    ComputeResult,
    EditableStreet
} from 'src/app/providers/services/streets.service';
import * as Color from 'color';

@Component({
    selector: 'app-light-result',
    templateUrl: './light-result.component.html',
    styleUrls: ['./light-result.component.scss']
})
export class LightResultComponent implements OnInit, AfterViewChecked {

    @Input()
    result: ComputeResult;

    @Input()
    street: EditableStreet;
    @Input()
    resolution = 1;

    @Output()
    urlImg = new EventEmitter();

    @Input()
    width;
    @Input()
    height;

    layoutItemsY: number[];
    canvasWidth: number;
    canvasHeight: number;


    @ViewChild('canvas')
    canvasOverlay: ElementRef<HTMLCanvasElement> = undefined!;


    constructor() {
    }

    ngOnInit() {
    }


    ngOnChanges(changes: SimpleChanges) {
        this.layoutItemsY = new Array(this.street.layout.length);
        this.layoutItemsY[0] = 0;
        for (let i = 0; i < this.street.layout.length - 1; i++) {
            this.layoutItemsY[i + 1] = this.layoutItemsY[i] + this.street.layout[i].larghezza;
        }
        this.canvasWidth = this.resolution * this.street.interdistanza;
        this.canvasHeight = this.resolution * (this.layoutItemsY[this.layoutItemsY.length - 1] + this.street.layout[this.street.layout.length - 1].larghezza);
    }

    get ratio() {
        return this.canvasWidth / this.canvasHeight;
    }

    get horizontalCentering() {
        return this.ratio < 2;
    }

    ngAfterViewChecked() {
        if (this.ratio < 2) {
            this.canvasOverlay.nativeElement.style.height = '100%';
            this.canvasOverlay.nativeElement.style.top = '0%';
            this.canvasOverlay.nativeElement.style.width = this.ratio * 50 + '%';
            this.canvasOverlay.nativeElement.style.left = (100 - this.ratio * 50) / 2 + '%';
        } else {
            this.canvasOverlay.nativeElement.style.width = '100%';
            this.canvasOverlay.nativeElement.style.left = '0%';
            this.canvasOverlay.nativeElement.style.height = (1 / this.ratio) * 200 + '%';
            this.canvasOverlay.nativeElement.style.top = (100 - (1 / this.ratio) * 200) / 2 + '%';
        }
        this.canvasOverlay.nativeElement.getContext('2d').clearRect(0, 0, this.canvasWidth, this.canvasHeight);
        for (let i = 0; i < this.result.risultati.length; i++) {
            if (this.result.risultati[i].matriceValori) {
                this.fillArea(this.result.risultati[i], i);
            }
        }
    }


    private getColorForTest(index) {
        let rgb;
        if (index % 2 === 0) {
            rgb = {
                r: 255,
                g: 0,
                b: 0,
            };
        } else {
            rgb = {
                r: 0,
                g: 0,
                b: 255,
            };
        }
        return Color(rgb).string();
    }


    // i rettangoli sono sempre 1x1 la risoluzione cambia la misura totale dell immagine utile nelle stampe
    fillArea(itemResult: LayoutItemResult, index: number) {
        const vector = itemResult.matriceValori[0];
        const matrix = [];
        const x_calc = itemResult.x_calc;
        const y_calc = itemResult.y_calc;
        for (let i = 0; i < x_calc.length; i++) {
            matrix.push([]);
            for (let j = 0; j < y_calc.length; j++) {
                matrix[i][j] = vector[i * y_calc.length + j];
            }
        }
        const context = this.canvasOverlay.nativeElement.getContext('2d');
        const incrementoX = 1 / this.resolution;
        const incrementoY = 1 / this.resolution;
        for (let x = 0; x < this.street.interdistanza; x += incrementoX) {
            for (let y = this.layoutItemsY[index]; y < this.layoutItemsY[index] + this.street.layout[index].larghezza; y += incrementoY) {
                const value = this.getValueFromTable(itemResult.x_calc, itemResult.y_calc, matrix, x, y);
                if (value !== null) {
                    context.fillStyle = this.getColor(value, itemResult.colorMap);
                    // context.fillStyle = this.getColorForTest(indexx);
                    context.globalAlpha = 0.9;
                    context.fillRect(x * this.resolution, this.canvasHeight - y * this.resolution - (incrementoY * this.resolution), incrementoX * this.resolution, incrementoY * this.resolution);
                } else {
                    console.log('val', value);
                }
            }
        }
    }

    getColor(value: number, colorMap: ColorMapItem[]) {
        let fromIndex = 0;
        let toIndex = colorMap.length - 1;
        for (let i = 0; i < colorMap.length; i++) {
            if (value < colorMap[i].value) {
                fromIndex = Math.max(i - 1, 0);
                toIndex = i;
                break;
            }
        }
        const ratio = Math.max(
            0,
            Math.min(
                1,
                (value - colorMap[fromIndex].value) / (colorMap[toIndex].value - colorMap[fromIndex].value)
            )
        );
        const fromRgb = Color(colorMap[fromIndex].color).rgb().object();
        const toRgb = Color(colorMap[toIndex].color).rgb().object();
        const rgb = {
            r: (toRgb.r - fromRgb.r) * ratio + fromRgb.r,
            g: (toRgb.g - fromRgb.g) * ratio + fromRgb.g,
            b: (toRgb.b - fromRgb.b) * ratio + fromRgb.b,
        };
        return Color(rgb).string();
    }


    getValueFromTable(xTable: number[], yTable: number[], zTable: number[][], x: number, y: number): number | null {
        if (xTable.length <= 0) {
            return null;
        }
        if (yTable.length <= 0) {
            return null;
        }

        if (xTable.length != zTable.length) {
            return null;
        }
        if (yTable.length != zTable[0].length) {
            return null;
        }

        let index11 = -1;
        let index12 = -1;
        let index21 = -1;
        let index22 = -1;
        let peso11 = 0.0;
        let peso12 = 0.0;
        let peso21 = 0.0;
        let peso22 = 0.0;

        let x_calc = x;
        let y_calc = y;

        if (x > xTable[xTable.length - 1]) {
            x_calc = xTable[xTable.length - 1];
        }
        if (y > yTable[yTable.length - 1]) {
            y_calc = yTable[yTable.length - 1];
        }
        for (let i = 0; i < xTable.length; i++) {
            let x_test = xTable[i];
            let diff = x_calc - x_test;
            if (diff <= 0) {
                if (i == 0) {
                    index12 = i + 1;
                    index11 = i;
                    peso11 = 1;
                    peso12 = 0;
                    break;
                } else {
                    index12 = i;
                    index11 = i - 1;
                    let diff1 = Math.abs(x_calc - xTable[i - 1]);
                    let diff2 = Math.abs(diff);
                    peso11 = 1 - diff1 / (diff1 + diff2);
                    peso12 = 1 - peso11;
                    break;
                }
            }
        }

        for (let i = 0; i < yTable.length; i++) {
            let y_test = yTable[i];
            let diff = y_calc - y_test;
            if (diff <= 0) {
                if (i == 0) {
                    index22 = i + 1;
                    index21 = i;
                    peso21 = 1;
                    peso22 = 0;
                    break;
                } else {
                    index22 = i;
                    index21 = i - 1;
                    let diff1 = Math.abs(y_calc - yTable[i - 1]);
                    let diff2 = Math.abs(diff);
                    peso21 = 1 - diff1 / (diff1 + diff2);
                    peso22 = 1 - peso21;
                    break;
                }
            }
        }


        if (index11 < 0 || index12 < 0 || index21 < 0 || index22 < 0) {
            return -1;
        }
        if (zTable.length <= index12) {
            return -1;
        }
        if (zTable[0].length <= index22) {
            return -1;
        }

        let val11 = zTable[index11][index21];
        let val12 = zTable[index11][index22];
        let val21 = zTable[index12][index21];
        let val22 = zTable[index12][index22];

        let val = val11 * peso11 * peso21 + val12 * peso11 * peso22 + val21 * peso12 * peso21 + val22 * peso12 * peso22;
        return val;
    }


}
