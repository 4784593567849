<div class="position-top-right">
    <button mat-icon-button color="accent" mat-dialog-close>
        <mat-icon>cancel</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <mat-card class="example-card" style="margin-bottom: 5px">
        <mat-card-title-group>
            <mat-card-title *ngIf="title">{{title}}</mat-card-title>
            <mat-card-subtitle *ngIf="subTitle">{{subTitle}}</mat-card-subtitle>
            <img mat-card-sm-image [src]="imageUrl" *ngIf="imageUrl">
        </mat-card-title-group>
        <mat-card-content *ngIf="texts" [ngClass]="[cardContentSize]">
            <ng-container *ngFor="let text of texts">
                <div *ngIf="text;else noText">
                    {{text}}
                </div>
                <ng-template #noText>
                    <br>
                </ng-template>
            </ng-container>
        </mat-card-content>
    </mat-card>
</mat-dialog-content>