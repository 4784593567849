import * as Parse from 'parse';


export class FileDaEsportare extends Parse.Object {
    public static CLASS_NAME = 'FileDaEsportare';

    constructor() {
        super(FileDaEsportare.CLASS_NAME);
    }

    public set objectId(value) {
        super.set('objectId', value);
    }

    public get objectId() {
        return super.get('objectId');
    }

    public set updatedAt(value) {
        super.set('updatedAt', value);
    }

    public get updatedAt() {
        return super.get('updatedAt');
    }

    public set createdAt(value) {
        super.set('createdAt', value);
    }

    public get createdAt() {
        return super.get('createdAt');
    }

    public set ACL(value) {
        super.set('ACL', value);
    }

    public get ACL() {
        return super.get('ACL');
    }

    public set progetto(value) {
        super.set('progetto', value);
    }

    public get progetto() {
        return super.get('progetto');
    }

    public set language(value) {
        super.set('language', value);
    }

    public get language() {
        return super.get('language');
    }

    public set oggettoDaEsportare(value) {
        super.set('oggettoDaEsportare', value);
    }

    public get oggettoDaEsportare() {
        return super.get('oggettoDaEsportare');
    }

    public set requestedBy(value) {
        super.set('requestedBy', value);
    }

    public get requestedBy() {
        return super.get('requestedBy');
    }

    public set modalita(value) {
        super.set('modalita', value);
    }

    public get modalita() {
        return super.get('modalita');
    }

    public set errorMessage(value) {
        super.set('errorMessage', value);
    }

    public get errorMessage() {
        return super.get('errorMessage');
    }

    public set failed(value) {
        super.set('failed', value);
    }

    public get failed() {
        return super.get('failed');
    }

    public set processing(value) {
        super.set('processing', value);
    }

    public get processing() {
        return super.get('processing');
    }


}

Parse.Object.registerSubclass(FileDaEsportare.CLASS_NAME, FileDaEsportare);
