import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {WTimeDialogComponent} from "../w-time-dialog/w-time-dialog.component";
import {ITime} from "../w-clock/w-clock.component";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {
    AbstractControl, ControlValueAccessor,
    UntypedFormBuilder,
    UntypedFormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors
} from "@angular/forms";
import {Subscription} from "rxjs";
import {isNotNullOrUndefined} from "../../../models/Models";

@Component({
    selector: 'app-form-material-time-picker',
    templateUrl: './form-material-time-picker.component.html',
    styleUrls: ['./form-material-time-picker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: FormMaterialTimePickerComponent
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: FormMaterialTimePickerComponent
        }
    ]
})
export class FormMaterialTimePickerComponent implements OnInit, OnDestroy, ControlValueAccessor {
    userTime = {
        hour: 0,
        minute: 0,
        meriden: 'AM',
        format: 12
    };

    @Input() stepSize = 1;
    @Input() revertLabel;
    @Input() submitLabel;
    @Input() disableEmitEvent = true;
    @Input() color: 'accent' | 'primary' | 'warn' = 'accent'
    subscriptions: Subscription[] = []
    public form: UntypedFormGroup;

    constructor(
        private fb: UntypedFormBuilder,
        private dialog: MatDialog) {
        this.form = this.fb.group({time: null, minute: 0, hour: 0})
        let sub = this.form.get('hour').valueChanges.subscribe(
            hour => {
                const date = {hour, minute: this.form.get('minute').value}
                this.form.get('time').setValue(this.convertDateToDecimal(date))
            }
        )
        this.subscriptions.push(sub);
        sub = this.form.get('minute').valueChanges.subscribe(
            minute => {
                const date = {hour: this.form.get('hour').value, minute}
                this.form.get('time').setValue(this.convertDateToDecimal(date))
            }
        )
        this.subscriptions.push(sub);
    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe())
    }


    showPicker() {
        if (!this.disabled) {
            const dialogRef = this.dialog.open(WTimeDialogComponent, {
                data: {
                    time: {
                        hour: parseInt(this.form.get('hour').value) % 12,
                        minute: parseInt(this.form.get('minute').value),
                        meriden: parseInt(this.form.get('hour').value) >= 12 ? 'PM' : 'AM',
                        format: 12
                    },
                    color: 'accent',
                    revertLabel: this.revertLabel,
                    submitLabel: this.submitLabel
                }
            });
            dialogRef.afterClosed().subscribe((result: ITime | -1) => {
                // result will be update userTime object or -1 or undefined (closed dialog w/o clicking cancel)
                if (result === undefined) {
                    return;
                } else if (result !== -1) {
                    this.userTime = {
                        format: 24,
                        hour: (result.hour + (result.meriden === 'PM' ? 12 : 0)) % 24,
                        minute: result.minute,
                        meriden: 'AM'
                    };
                    this.form.get('hour').setValue(this.userTime.hour.toString().padStart(2, '0'), {emitEvent: false})
                    this.form.get('minute').setValue(this.userTime.minute.toString().padStart(2, '0'), {emitEvent: true})
                }
            });
            return false;
        }
    }

    onTouched: Function = () => {
    };


    getHour(hour: number): string {
        if (hour >= 24 || hour < 0) {
            return '00'
        } else {
            return hour.toString().padStart(2, '0')
        }
    }

    getMinute(minute: number): string {
        if (minute >= 60 || minute < 0) {
            return '00'
        } else {
            return minute.toString().padStart(2, '0')
        }
    }

    registerOnChange(onChange: any) {
        const sub = this.form.get('time').valueChanges
            .subscribe(onChange);
        this.subscriptions.push(sub)
    }

    registerOnTouched(onTouched: Function) {
        this.onTouched = onTouched;
    }


    setDisabledState(disabled: boolean) {
        if (disabled) {
            this.form.disable({emitEvent: this.disableEmitEvent});
        } else {
            this.form.enable({emitEvent: this.disableEmitEvent});
        }
    }

    writeValue(value: any) {
        if (value == null) {
            this.form.reset();
            this.form.get('hour').setValue(this.getHour(0), {emitEvent: false});
            this.form.get('minute').setValue(this.getMinute(0), {emitEvent: false});
        } else {
            this.form.get('time').setValue(value);
            const houurMinute = this.convertToHExadecimal(value)
            this.form.get('hour').setValue(houurMinute.hour, {emitEvent: false});
            this.form.get('minute').setValue(houurMinute.minute, {emitEvent: false});
        }
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (!isNotNullOrUndefined(this.form.get('time').validator)) {
            this.form.get('time').setValidators(control.validator)
        }
        if (this.form.get('time').valid) {
            return null
        } else {
            return this.form.get('time').errors;
        }
    }


    convertToHExadecimal(value: number): { hour: string, minute: string } {
        const hourMinute = value.toString();
        const hour = this.getHour(parseInt(hourMinute));
        const minute = this.getMinute(Math.round(60 * (value - parseInt(hourMinute))))
        return {hour, minute}
    }

    convertDateToDecimal(date: { hour: string, minute: string }) {
        if (date == null) {
            return null
        }
        let hour = parseInt(date.hour)
        let minute = parseInt(date.minute);
        if (isNaN(hour)) {
            hour = 0
        }
        if (isNaN(minute)) {
            minute = 0
        }

        return hour + Math.round(minute / 60 * 10000) / 10000
    }

    updateHour(direction: 1|-1) {
        if (!this.disabled) {
            const formField = this.form.get('hour');
            const updatedValue = (parseInt(formField.value) + this.stepSize * direction + 24) % 24;
            formField.setValue(updatedValue.toString().padStart(2, '0'));
        }
    }

    updateMinute(direction: 1|-1) {
        if (!this.disabled) {
            const formField = this.form.get('minute');
            const updatedValue = (parseInt(formField.value) + this.stepSize * direction + 60) % 60;
            formField.setValue(updatedValue.toString().padStart(2, '0'));
        }
    }

    get disabled() {
        return this.form.get('time').disabled
    }
}
