import {Component, Input, OnInit} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {NewReportCommentDialogComponent} from "../new-report-comment-dialog/new-report-comment-dialog.component";
import {UtilsService} from "../../../providers/services/utils.service";


@Component({
    selector: 'app-new-report-comment',
    templateUrl: './new-report-comment.component.html'
})
export class NewReportCommentComponent implements OnInit {

    @Input() report;

    constructor(public dialog: MatDialog,
                private utilsService: UtilsService) {
    }

    ngOnInit(): void {
    }


    openDialog() {
        this.utilsService.lockBody();
        const a = this.dialog.open(NewReportCommentDialogComponent, {
            data: {
                reportObjectId: this.report
            }
        });
        a.afterClosed().subscribe(() => this.utilsService.unlockBody());
    }
}
