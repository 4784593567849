import {ChangeDetectorRef, Component, EventEmitter, NgZone, OnInit, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {PlanFormService} from '../../../providers/forms/plan-form.service';
import {PlansService} from '../../../providers/services/plans.service';
import {LoaderService} from '../../../providers/services/loader.service';
import {AlertService} from '../../../providers/services/alert.service';
import {Router} from '@angular/router';
import {UserService} from '../../../providers/services/user.service';
import {ProjectService} from "../../../providers/services/project.service";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {InfoLockedPlanDialogComponent} from "../../locked-plan/info-locked-plan-dialog/info-locked-plan-dialog.component";
import {UtilsService} from "../../../providers/services/utils.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-create-plan',
    templateUrl: './create-plan.component.html'
})
export class CreatePlanComponent implements OnInit {

    public planForm: UntypedFormGroup;
    public user;
    public project;
    public loading = true;
    public creatingPlan:boolean = false;

    public canICreatePlan = false;

     @Output()
     planIsReady=new EventEmitter<{}>();

    constructor(
        private planUserForm: PlanFormService,
        private planService: PlansService,
        private loaderService: LoaderService,
        private alertServices: AlertService,
        private userService: UserService,
        private router: Router,
        private projectService: ProjectService,
        public dialog: MatDialog,
        private utilsService: UtilsService,
        private translate: TranslateService,
        private ngZone: NgZone
    ) {
        this.loadData();
    }

    ngOnInit() {
    }

    private async loadData() {
        this.user = this.userService.user();
        this.planForm = this.planUserForm.getCreatePlanForm();
        this.canICreatePlan = await this.projectService.isValidSubscription(5, false, true, false, false);
    }


    async createPlan() {
        this.creatingPlan=true;
        if (this.planForm.invalid) {
            this.alertServices.error(this.translate.instant('compile'));
            this.creatingPlan=false;
        } else {
            const loaderId = this.loaderService.addLoader();
            try {
                if (await this.projectService.isValidSubscription(5, false, true, false, false)) {
                    const res = await this.planService.createPlan(null, this.planForm.value.name);
                    this.alertServices.success(this.translate.instant('services.request_completed_successfully'));
                    this.planIsReady.emit(res);
                    // this.ngZone.run(() => {
                    //     this.router.navigate(['/planimetrie/modifica/' + res.objectId]);
                    // });
                } else {
                    this.utilsService.lockBody();
                    const dialogRef = this.dialog.open(InfoLockedPlanDialogComponent, {
                        width: '600px',
                        data: {
                            userSession: this.userService.sessionToken()
                        }
                    });
                    dialogRef.afterClosed().subscribe(res => {
                        if (res) {
                            this.loadData();
                        }
                        this.utilsService.unlockBody();
                    });
                }
            } catch (e) {
                this.alertServices.error(e.message);
            } finally {
                this.creatingPlan=false;
                this.loaderService.removeLoader(loaderId);
            }
        }
    }

}
