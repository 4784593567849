import {Injectable} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import * as FileSaver from 'file-saver';
import {Papa} from "ngx-papaparse";
import {combineLatest, Observable, throwError} from "rxjs";
import {ordinamentoEcampiTraduzioni} from "../../models/ordinamentoEcampiTraduzioni";
import {arrayIsSet, className, isNotNullOrUndefined, stringIsSet} from "../../models/Models";
import {map} from "rxjs/operators";
import {PuntiLuceParse} from "../../models/PuntiLuce.Parse";
import {CircuitiParse} from "../../models/Circuiti.Parse";
import {LocationService} from "./location.service";
import {fromPromise} from "rxjs/internal-compatibility";
import {error} from "protractor";
import {DomSanitizer} from "@angular/platform-browser";

const tokml = require("tokml");
const JSZip = require("jszip");
const Drawing = require('dxf-writer');
export type DownloadJsonHeaderType = { traduction?: string, notTraduction?: string, key?: string }
export type DownloadJsonObjectType = { [k: string]: string }

@Injectable({
    providedIn: 'root'
})
export class FileServiceService {


    public className = className;

    constructor(
        private translate: TranslateService,
        private convertFile: Papa,
        private locationService: LocationService,
        private domSanitizer:DomSanitizer
    ) {

    }

// verifica se esiste la traduzione
    private verificaTraduzione(value, original) {
        return (typeof value == 'string' && value.includes('dashboard_sidenav.')) ? original : value;
    }

    //viene creato il file csv partendo da un oggetto o un array
    private lightPointsCircuitsConvertToCSV(objArray, headerList, className, delimeter: ',' | ';') {
        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        let row = '';
        let titleCsv;
        str += this.translate.instant(className) + delimeter + '\r\n';
        for (let index in headerList) {
            if (headerList[index].includes('progetto12345')) {
                const headerDepurato = headerList[index].replace('progetto12345', '');
                titleCsv = this.translate.instant('dashboard_sidenav.' + className + '.' + headerDepurato + '.title');
                titleCsv = this.verificaTraduzione(titleCsv, headerDepurato);
                titleCsv = this.translate.instant('projectName') + ' ' + titleCsv;
            } else {
                titleCsv = this.translate.instant('dashboard_sidenav.' + className + '.' + headerList[index] + '.title');
                titleCsv = this.verificaTraduzione(titleCsv, headerList[index]);
            }
            row += titleCsv + delimeter;
        }
        row = row.slice(0, -1);
        str += row + '\r\n';
        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in headerList) {
                let head = headerList[index];
                line += array[i][head] + delimeter;
            }
            str += line + '\r\n';
        }
        return str;
    }

    private convertToCSVHeaderListTranslated(data: any[], headerList: string[], className, delimeter: ',' | ';') {
        let str = '';
        let row = '';
        str += this.translate.instant(className) + delimeter + '\r\n';
        headerList.forEach(
            header => {
                row += header + delimeter;
            }
        );
        // for (let index in headerList) {
        //     if (headerList[index].includes('progetto12345')) {
        //         const headerDepurato = headerList[index].replace('progetto12345', '');
        //         titleCsv = this.translate.instant('dashboard_sidenav.' + className + '.' + headerDepurato + '.title');
        //         titleCsv = this.verificaTraduzione(titleCsv, headerDepurato);
        //         titleCsv = this.translate.instant('projectName') + ' ' + titleCsv;
        //     } else {
        //         titleCsv = this.translate.instant('dashboard_sidenav.' + className + '.' + headerList[index] + '.title');
        //         titleCsv = this.verificaTraduzione(titleCsv, headerList[index]);
        //     }
        //     row += titleCsv + delimeter;
        // }
        row = row.slice(0, -1);
        str += row + '\r\n';
        const keys = Object.keys(data[0]);
        data.forEach(date => {
            let line = '';
            keys.forEach(
                key => {
                    const value = date[key] != null ? date[key] : ''
                    line += value + delimeter;
                });
            str += line + '\r\n';
        });
        return str;
    }

    getTitleLastTemplate(classe: className.puntiLuce | className.circuiti): string[] {
        const keys = (Object.keys(ordinamentoEcampiTraduzioni[classe]) as any[]).sort((a, b) => {
            return ordinamentoEcampiTraduzioni[classe][a].sortingValue - ordinamentoEcampiTraduzioni[classe][b].sortingValue
        });
        let varianteKeyList = [];
        keys.forEach((key) => {
            const varianteKey = ordinamentoEcampiTraduzioni[classe][key].varianteKey;
            if (isNotNullOrUndefined(varianteKey)) {
                varianteKeyList.push(varianteKey);
            }
        });
        return keys.concat(varianteKeyList);
    }

    /**
     * recupera le traduzioni in base alla classe. location viene divisa in locationLatitude e locationLongitude
     *Viene creato un oggetto
     * @param classe
     * @return Observable {
     *                     allTraduction: {key:[ {title, possibleValues} ]} sono le traduzioni appartenenti a quella classe, è un oggetto dove la chiave coincide con la chiave della tradzuione,
     *                          come valore un array di oggetti composto {title: la truduzione
     *                                                                    possibleValue:le traduzioni dei possible value}
     *                     projectName:[] un array con le traduzioni del project name
     *                    }
     */
    getTitleKeyAssociation(classe: className.puntiLuce | className.circuiti | className.caricoEsogeno): Observable<{
        allTraduction,
        projectName
    }> {
        const lang: string[] = this.translate.getLangs();
        const arrayLangSubscribe = [];
        lang.forEach((lang) => {
            arrayLangSubscribe.push(this.translate.getTranslation(lang))
        });
        return combineLatest(arrayLangSubscribe).pipe(
            map((allLangTraduction) => {
                let objectAllTratuction = {};
                let projectName: string[] = [];
                allLangTraduction.forEach((singleLangTraduction) => {
                    projectName.push(singleLangTraduction['projectName']);
                    const traductionClass = singleLangTraduction['dashboard_sidenav'][classe];
                    if (traductionClass != null) {
                        Object.keys(traductionClass).forEach((key) => {
                            if (key == 'location') {
                                if (isNotNullOrUndefined(objectAllTratuction[key + 'latitude'])) {
                                    objectAllTratuction[key + 'latitude'].push(traductionClass[key]['latitude']);
                                } else {
                                    objectAllTratuction[key + 'latitude'] = [traductionClass[key]['latitude']];
                                }
                                if (isNotNullOrUndefined(objectAllTratuction[key + 'longitude'])) {
                                    objectAllTratuction[key + 'longitude'].push(traductionClass[key]['longitude']);
                                } else {
                                    objectAllTratuction[key + 'longitude'] = [traductionClass[key]['longitude']];
                                }
                            } else {
                                if (isNotNullOrUndefined(objectAllTratuction[key])) {
                                    objectAllTratuction[key].push(traductionClass[key]);
                                } else {
                                    objectAllTratuction[key] = [traductionClass[key]];
                                }
                            }
                        });
                    }
                });
                return {allTraduction: objectAllTratuction, projectName: projectName};
            })
        )
    }

    /**
     * @return {
     *     circuiti: le traduzioni dei circuiti
     *     puntiLuce: le traduzioni dei punti luce
     * }
     */
    get titleKeyAssociationClassName(): Observable<{ Circuiti, PuntiLuce }> {
        const lang: string[] = this.translate.getLangs();
        const arrayLangSubscribe = [];
        lang.forEach((lang) => {
            arrayLangSubscribe.push(this.translate.getTranslation(lang))
        });
        return combineLatest(arrayLangSubscribe).pipe(
            map((allLangTraduction) => {
                let className: { PuntiLuce: string[], Circuiti: string[], CaricoEsogeno: string[] } = {
                    PuntiLuce: [],
                    Circuiti: [],
                    CaricoEsogeno: []
                };
                allLangTraduction.forEach((singleLangTraduction) => {
                    className.Circuiti.push(singleLangTraduction['Circuiti']);
                    className.PuntiLuce.push(singleLangTraduction['PuntiLuce']);
                    className.CaricoEsogeno.push(singleLangTraduction['CaricoEsogeno']);
                });
                return className;
            })
        )
    }


    getAllTraduction(classe: className.strade): Observable<{ allTraduction }> {
        const lang: string[] = this.translate.getLangs();
        const arrayLangSubscribe = [];
        lang.forEach((lang) => {
            arrayLangSubscribe.push(
                this.translate.getTranslation(lang).pipe(
                    map(a => {
                        if (classe === className.strade) {
                            return a.streets
                        } else {
                            return a[classe];
                        }
                    })
                )
            )
        });
        return combineLatest(arrayLangSubscribe).pipe(
            map((allLangTraduction) => {
                let objectAllTratuction: any = {};
                let projectName: string[] = [];
                allLangTraduction.forEach((singleLangTraduction) => {
                    const traductionClass = singleLangTraduction;
                    Object.keys(traductionClass).forEach((key) => {
                        if (arrayIsSet(objectAllTratuction[key])) {
                            objectAllTratuction[key].push(traductionClass[key]);
                        } else {
                            objectAllTratuction[key] = [traductionClass[key]];
                        }
                    });
                });
                return {allTraduction: objectAllTratuction};
            })
        )
    }


    /**
     * effettua una pausa
     * @param ms i numeri di ms da attendere
     */
    private delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    /**
     * trasforma un file csv in observable dove i dati vengoni inviati  spezzoni
     * le prime due righe vengono saltate
     * @param file il file da leggere
     * @param delimeter il delimitatore usato
     * @param numberElementForCycle il numero di elementi vengono letti per ogni step
     * @param secondDiAttesa il numero di secondi devono trascorrere tra due step
     */
    convertToJson(file, delimeter: ';' | ',', numberElementForCycle, secondDiAttesa = 0.5, skipLine = 2): Observable<{
        data,
        errors,
        meta,
        index,
        progressPercent,
        finished
    }> {
        return new Observable(
            subscriber => {
                this.convertFile.parse(file, {
                    comments: '#',
                    delimiter: delimeter,
                    skipEmptyLines: true,
                    complete: async (result) => {
                        let i = skipLine;
                        const data = result.data.filter(row => {
                            const index = row.findIndex(cell => stringIsSet(cell))
                            return index >= 0
                        });
                        const lengthArray = data.length;
                        do {
                            const lastElement = (i + numberElementForCycle < lengthArray) ? i + numberElementForCycle : lengthArray;
                            await this.delay(secondDiAttesa * 1000);
                            subscriber.next({
                                data: data.slice(i, lastElement),
                                errors: result.errors,
                                meta: result.meta,
                                index: i + 1,
                                progressPercent: Math.ceil(lastElement / lengthArray * 100),
                                finished: lastElement === lengthArray
                            });
                            i += numberElementForCycle;
                        } while (i < data.length);
                    }
                });
            }
        );
    }


    /**
     *
     * @param file dove recuperare le informazioni
     * @param delimeter il delimitatore usato
     * @return class primo leemento del file columnTitle seconda riga del file
     */
    convertToJsonGetClassAndColomunTitle(file, delimeter: ';' | ','): Observable<{ class, colomunTitle }> {
        return new Observable(
            subscriber => {
                this.convertFile.parse(file, {
                    comments: '#',
                    delimiter: delimeter,
                    skipEmptyLines: true,
                    complete: async (result) => {
                        subscriber.next({
                            class: result.data[0][0],
                            colomunTitle: result.data[1],
                        });
                    }
                });
            }
        );
    }

    getInitRowFile(file, delimeter: ';' | ',', firstLine = 0, lastLine = 2): Observable<{ data: string[] }> {
        return new Observable(
            subscriber => {
                let index = 0;
                let data = []
                this.convertFile.parse(file, {
                    comments: '#',
                    delimiter: delimeter,
                    skipEmptyLines: true,
                    step: (result, parser) => {
                        if (arrayIsSet(result.data) && index >= firstLine && index < lastLine) {
                            data.push(result.data)
                        }
                        if (index >= lastLine) {
                            parser.abort();
                            subscriber.next({
                                data
                            });
                            subscriber.complete()
                        }
                        index++;
                    },
                    error: (e) => {
                        subscriber.error(e)
                        subscriber.complete()
                    }
                });
            }
        );
    }

    convertToJsonPaginate(file, delimeter: ';' | ',', skipInitLine = 0): Observable<any> {
        return new Observable(
            subscriber => {
                let data = []
                let index = 0;
                this.convertFile.parse(file, {
                    comments: '#',
                    delimiter: delimeter,
                    step: (a, parser) => {
                        if (arrayIsSet(a.data)) {
                            if (index >= skipInitLine) {
                                const index = a.data.findIndex(value => stringIsSet(value));
                                if (index >= 0) {
                                    data.push(a.data)
                                }
                            }
                        }
                        index++;
                    },
                    skipEmptyLines: true,
                    complete: async (result) => {
                        subscriber.next({
                            data
                        });
                        subscriber.complete();
                    }, error: (e) => {
                        subscriber.error(e)
                        subscriber.complete();
                    }
                });
            }
        );
    }


    downloadDatabase(classe: className.puntiLuce | className.circuiti, delimeter: ',' | ';') {
        const header: string[] = [];
        const allPossibileValues: { [k: string]: string[] } = {};
        let maxLengthPossibileValues = 0;
        const allKeys = this.getTitleLastTemplate(classe);
        allKeys.forEach(key => {
            const values = ordinamentoEcampiTraduzioni[classe][key];
            if (values != null && arrayIsSet(values.possibleValues)) {
                allPossibileValues[key] = values.possibleValues.slice();
                if (values.varianteKey != null) {
                    allPossibileValues[values.varianteKey] = values.possibleValues.slice();
                }
                if (values.possibleValues.length > maxLengthPossibileValues) {
                    maxLengthPossibileValues = values.possibleValues.length;
                }
            }
            let titleCsv;
            // il titolo della variante key puo fallire cosi da sistemare
            if (!key.includes('variante')) {
                titleCsv = this.translate.instant('dashboard_sidenav.' + classe + '.' + key + '.title');
            } else {
                titleCsv = this.translate.instant('projectName') + ' ' + this.translate.instant('dashboard_sidenav.' + classe + '.' + key + '.title');
            }
            header.push(this.verificaTraduzione(titleCsv, key));
        })
        let csv = this.convertFile.unparse({
            "fields": header,
        }, {
            delimiter: delimeter
        });
        // il primo elemento serve per riconoscere la classe negli import quindi va aggiunto
        csv = classe + '\r\n'.concat(csv) + '\r\n';
        for (let i = 0; i < maxLengthPossibileValues; i++) {
            let line = '';
            for (let index in header) {
                const key = allKeys[index];
                if (arrayIsSet(allPossibileValues[key])) {
                    const valoreTradotto = this.translate.instant('dashboard_sidenav.' + classe + '.' + key + '.possibleValues.' + this.sostituisciSpaziconUnderscore(allPossibileValues[key][0]));
                    const value = this.verificaTraduzione(valoreTradotto, allPossibileValues[key][0]);
                    line += value;
                    allPossibileValues[key].shift();
                } else {
                    line += '';
                }
                line += delimeter
            }
            csv += line + '\r\n';
        }

        let blob = new Blob(['\ufeff' + csv], {type: 'text/csv;charset=utf-8;'});
        this.openLink(blob, 'database_' + classe + '.csv');
    }

    downloadTemplate(classe: className.puntiLuce | className.circuiti, delimeter: ',' | ';') {
        const header: string[] = [];
        this.getTitleLastTemplate(classe).forEach((key) => {
            if (key.toUpperCase() == 'LOCATION') {
                let titleCsv = this.translate.instant('dashboard_sidenav.' + classe + '.' + key + '.' + 'latitude' + '.title');
                header.push(this.verificaTraduzione(titleCsv, 'latitude'));
                titleCsv = this.translate.instant('dashboard_sidenav.' + classe + '.' + key + '.' + 'longitude' + '.title');
                header.push(this.verificaTraduzione(titleCsv, 'longitude'));
            } else {
                const titleCsv = this.translate.instant('dashboard_sidenav.' + classe + '.' + key + '.title');
                header.push(this.verificaTraduzione(titleCsv, key));
            }
        });
        var csv = this.convertFile.unparse({
            "fields": header,
        }, {
            delimiter: delimeter
        });
        // il primo elemento serve per riconoscere la classe negli import quindi va aggiunto
        csv = classe + '\r\n'.concat(csv);
        let blob = new Blob(['\ufeff' + csv], {type: 'text/csv;charset=utf-8;'});
        this.openLink(blob, 'template_' + classe + '.csv');
    }

    downloadFile(data, filename = 'data', nomeClasse, headerTranslated = undefined) {
        let intestazione = [];
        let csvData;
        if (nomeClasse == this.className.circuiti || nomeClasse == this.className.puntiLuce) {
            Object.keys(data[0]).forEach((key) => {
                if (key != 'objectId') {
                    intestazione.push(key);
                }
            });
            intestazione.push('objectId');
            csvData = this.lightPointsCircuitsConvertToCSV(data, intestazione, nomeClasse, ';');
        } else {
            csvData = this.convertToCSVHeaderListTranslated(data, headerTranslated, nomeClasse, ';');
        }
        let blob = new Blob(['\ufeff' + csvData], {type: 'text/csv;charset=utf-8;'});
        this.openLink(blob, filename);
    }


    saveForTestJson(list: any[], filename: string) {
        let csvData = JSON.stringify(list);
        let blob = new Blob(['\ufeff' + csvData], {type: 'text/csv;charset=utf-8;'});
        this.openLink(blob, filename);
    }

    openLink(blob, filename) {
        FileSaver.saveAs(blob, filename);
        //
        // const link=window.URL.createObjectURL(blob)
        // let element = document.createElement('a');
        // element.href = link;
        // element.download = filename;
        // document.body.appendChild(element);
        // element.click();
        // document.body.removeChild(element);
        // window.open(link);
    }

    getBlobByJson(fileName: string, values: DownloadJsonObjectType[], headers: DownloadJsonHeaderType[] = undefined, delimeter: ';' | ',' = ';', firstCell: string = undefined): Blob {
        let csv = '';
        if (stringIsSet(firstCell)) {
            csv += firstCell + '\n';
        }
        if (arrayIsSet(headers)) {
            let line = '';
            headers.forEach(headerKey => {
                if (!stringIsSet(headerKey.traduction) && !stringIsSet(headerKey.notTraduction)) {
                    line += '';
                    line += delimeter;
                } else {
                    if (stringIsSet(headerKey.traduction)) {
                        line += this.translate.instant(headerKey.traduction);
                    }
                    if (stringIsSet(headerKey.notTraduction)) {
                        line += headerKey.notTraduction;
                    }
                    line += delimeter;
                }

            })
            line += '\r\n';
            csv += line;
        }
        const keyHeaderIsPresent = arrayIsSet(headers) ? headers.find(h => h.key != null) != null : false
        const getValueToWrite = (value: any) => {
            let returnValue
            if (stringIsSet(value)) {
                returnValue = value;
            } else if (value != null && value.toString != null) {
                returnValue = value.toString();
            } else {
                returnValue = '';
            }
            return returnValue.includes(delimeter) ? "\"" + returnValue + "\"" : returnValue;
        }
        values.forEach(val => {
            let line = '';
            if (keyHeaderIsPresent) {
                headers.forEach(h => {
                    line += getValueToWrite(val[h.key]);
                    line += delimeter;
                })
            } else {
                Object.values(val).forEach(value => {
                    line += getValueToWrite(value);
                    line += delimeter;
                });
            }


            line += '\r\n';
            csv += line;
        })
        return new Blob(['\ufeff' + csv], {type: 'text/csv;charset=utf-8;'});
    }

    dowloadJson(fileName: string, values: DownloadJsonObjectType[], headers: DownloadJsonHeaderType[] = undefined, delimeter: ';' | ',' = ';', firstCell: string = undefined) {
        const blob = this.getBlobByJson(fileName, values, headers, delimeter, firstCell)
        this.openLink(blob, fileName + '.csv');
    }


    private hasPossibleValueAndTypeArray(className: string, key: string): boolean {
        if (isNotNullOrUndefined(ordinamentoEcampiTraduzioni.PuntiLuce[key]) && 'possibleValues' in ordinamentoEcampiTraduzioni.PuntiLuce[key]) {
            return Array.isArray(ordinamentoEcampiTraduzioni.PuntiLuce[key].possibleValues);
        } else {
            return false;
        }
    }

    private convertPlToGeojson(pls: PuntiLuceParse[], filename: string, className: string) {
        let geojson = {
            "name": filename,
            "type": "FeatureCollection",
            "features": []
        };
        if (pls == null || pls.length == 0) {
            return geojson
        }
        for (let pl of pls) {
            const coordinates = [pl.location.longitude, pl.location.latitude]
            let plsJson = pl.toJSON();
            delete plsJson.objectId;
            delete plsJson.createdBy;
            delete plsJson.lastUpdatedBy;
            delete plsJson.fotoTipologia;
            delete plsJson.foto;
            delete plsJson.customKeys;
            delete plsJson.location;
            delete plsJson.ACL;
            delete plsJson.lastUpdateBy;
            if (plsJson.circuito != null) {
                plsJson.circuito = plsJson.circuito.numeroQuadro;
            }
            if (plsJson.varianteCircuito != null) {
                plsJson.varianteCircuito = plsJson.varianteCircuito.numeroQuadro;
            }
            if (plsJson.strada != null) {
                plsJson.strada = plsJson.strada.name
            }
            if (plsJson.puntoLucePadre != null) {
                plsJson.puntoLucePadre = plsJson.puntoLucePadre.targa;
            }
            if (plsJson.progetto != null) {
                plsJson.progetto = plsJson.progetto.name;
            }

            let properties = {}
            Object.keys(plsJson).forEach((fieldName) => {
                const titleTraduction = this.translate.instant('dashboard_sidenav.' + className + '.' + fieldName + '.title');
                const fieldNameTradotto = this.verificaTraduzione(titleTraduction, fieldName);  //qui puoi tradurre le chiavi
                let value = plsJson[fieldName];
                if (!isNotNullOrUndefined(value)) {
                    value = '';
                } else if (this.hasPossibleValueAndTypeArray(className, fieldName)) {
                    const valoreTradotto = this.translate.instant('dashboard_sidenav.' + className + '.' + fieldName + '.possibleValues.' + this.sostituisciSpaziconUnderscore(plsJson[fieldName]));
                    value = this.verificaTraduzione(valoreTradotto, value);
                }
                properties[fieldNameTradotto] = value //qui puoi tradurre i valori
            });
            const feature = {
                "type": "Feature",
                "geometry": {
                    "type": "Point",
                    "coordinates": coordinates
                },
                "properties": properties
            }
            geojson.features.push(feature)
        }
        return geojson
    }

    getTypeOrdinamentoCampo(className, field): string {
        if (ordinamentoEcampiTraduzioni[className] && ordinamentoEcampiTraduzioni[className][field]) {
            return ordinamentoEcampiTraduzioni[className][field].type
        } else {
            return ''
        }
    }

    getPointerKey(className, field): string {
        if (ordinamentoEcampiTraduzioni[className] && ordinamentoEcampiTraduzioni[className][field] && ordinamentoEcampiTraduzioni[className][field].possibleValues) {
            const pointerKey = ordinamentoEcampiTraduzioni[className][field].possibleValues.split('.')[1];
            return pointerKey
        } else {
            return ''
        }
    }


    getValue(value, className, fieldName) {
        let castValue;
        if (!isNotNullOrUndefined(value)) {
            castValue = '';
        } else if (this.getTypeOrdinamentoCampo(className, fieldName).toLocaleLowerCase().includes('pointer')) {
            const pointerkey = this.getPointerKey(className, fieldName);
            castValue = (isNotNullOrUndefined(value[pointerkey])) ? value[pointerkey] : '';
        } else if (this.hasPossibleValueAndTypeArray(className, fieldName)) {
            const valoreTradotto = this.translate.instant('dashboard_sidenav.' + className + '.' + fieldName + '.possibleValues.' + this.sostituisciSpaziconUnderscore(value));
            castValue = this.verificaTraduzione(valoreTradotto, value);
        } else {
            castValue = value;
        }
        return castValue;
    }

    public convertCircuitiToGeojson(circuiti: CircuitiParse[], filename: string, className: string) {
        let geojson = {
            "name": filename,
            "type": "FeatureCollection",
            "features": []
        };
        if (circuiti == null || circuiti.length == 0) {
            return geojson
        }
        const notConvertKey = ['objectId', 'createdBy', 'lastUpdatedBy', 'fotoTipologia', 'foto', 'customKeys', 'location', 'ACL', 'lastUpdateBy', 'progetto']
        const keys = circuiti[0].allPropertyOnParse().filter(key => !notConvertKey.includes(key));
        circuiti.forEach(circuito => {
            const coordinates = [circuito.location.longitude, circuito.location.latitude]
            let properties = {}
            properties['progetto'] = circuito.progetto.name;
            keys.forEach((fieldName) => {
                const titleTraduction = this.translate.instant('dashboard_sidenav.' + className + '.' + fieldName + '.title');
                const fieldNameTradotto = this.verificaTraduzione(titleTraduction, fieldName);  //qui puoi tradurre le chiavi
                let value = circuito[fieldName];
                properties[fieldNameTradotto] = this.getValue(value, className, fieldName) //qui puoi tradurre i valori
            });
            const feature = {
                "type": "Feature",
                "geometry": {
                    "type": "Point",
                    "coordinates": coordinates
                },
                "properties": properties
            }
            geojson.features.push(feature)
        });
        return geojson;
    }

    private async comprimiFile(data, filename): Promise<any> {
        try {

            const zip = new JSZip();
            zip.file(filename + ".kml", data);
            return await zip.generateAsync({
                type: "blob",
                compression: "DEFLATE",
                compressionOptions: {
                    level: 9
                },
            })
        } catch (error) {
            console.log(error)
        }
    }

    comprimiFiles(datas: { folderName?: string, name: string, blob: Blob } []): Observable<Blob> {
        try {
            const zip = new JSZip();

            datas
                .sort((a, b) => {
                    const folderA = stringIsSet(a.folderName) ? a.folderName : '';
                    const folderB = stringIsSet(b.folderName) ? b.folderName : '';
                    return folderA.localeCompare(folderB)
                })
                .forEach(data => {
                    let folder = zip;
                    if (stringIsSet(data.folderName)) {
                        folder = zip.folder(data.folderName)
                    }
                    folder.file(data.name, data.blob)
                })
            return fromPromise(zip.generateAsync({
                type: "blob",
                compression: "DEFLATE",
                compressionOptions: {
                    level: 9
                },
            })) as Observable<Blob>
        } catch (error) {
            console.log(error)
            return throwError(error)
        }
    }

    getBlobByUrl(url: string): Observable<Blob> {
        try {
            return fromPromise(fetch(url).then(r => r.blob()));
        } catch (e) {
            return throwError(e);
        }
    }

    downloadKml(puntiLuce, filename: string, className: string): Observable<{ downloadFile: string, error }> {
        const finished = {kml: false, kmz: false};
        return new Observable(
            subscriber => {
                let kml;
                try {
                    let geojson;
                    if (className == this.className.puntiLuce) {
                        geojson = this.convertPlToGeojson(puntiLuce, filename, className);
                    } else if (className == this.className.circuiti) {
                        geojson = this.convertCircuitiToGeojson(puntiLuce, filename, className);
                    }
                    kml = tokml(geojson);
                } catch (error) {
                    subscriber.error({downloadFile: 'kml', error: error});
                    subscriber.error({downloadFile: 'kmz', error: error});
                    subscriber.complete();
                    subscriber.unsubscribe();
                }
                try {
                    let blob = new Blob(['\ufeff' + kml], {type: 'text/kml;charset=utf-8;'});
                    this.openLink(blob, filename + '.kml');
                    subscriber.next({downloadFile: 'kml', error: false})
                } catch (error) {
                    subscriber.error({downloadFile: 'kml', error: error})
                } finally {
                    finished.kml = true;
                    if (finished.kml && finished.kmz) {
                        subscriber.complete();
                        subscriber.unsubscribe();
                    }
                }
                this.comprimiFile(kml, filename).then(blob => {
                    this.openLink(blob, filename + '.kmz');
                    subscriber.next({downloadFile: 'kmz', error: false})
                }).catch(error => {
                    subscriber.error({downloadFile: 'kmz', error: error})
                }).finally(() => {
                    finished.kmz = true;
                    if (finished.kml && finished.kmz) {
                        subscriber.complete();
                        subscriber.unsubscribe();
                    }
                })
            }
        )
    }


    getXYinMetersWithCenter(center, point): { x: number, y: number } {
        const tempPointX = this.locationService.getNewPoint(center.latitude, point.longitude);
        const tempPointY = this.locationService.getNewPoint(point.latitude, center.longitude);
        let xInMeters = center.kilometersTo(tempPointX) * 1000;
        let yInMeters = center.kilometersTo(tempPointY) * 1000;
        if (center.latitude > point.latitude) {
            yInMeters = -yInMeters
        }
        if (center.longitude > point.longitude) {
            xInMeters = -xInMeters
        }
        return {x: xInMeters, y: yInMeters}
    }

    downloadDxfFile(puntiLuce: PuntiLuceParse[], circuiti: CircuitiParse[], filename = 'aaaa') {
        const all = circuiti
            .map(circuito => {
                return {location: circuito.location}
            })
            .concat(puntiLuce
                .map(pl => {
                    return {location: pl.location}
                })
            );
        const getName = (circuito: CircuitiParse) => {
            if (isNotNullOrUndefined(circuito) && stringIsSet(circuito.numeroQuadro)) {
                return circuito.numeroQuadro.replace(/ /g, '_')
            } else {
                return this.translate.instant('filterPage.nessunQuadro').replace(/ /g, '_')
            }
        }
        const locationPoint = this.locationService.getCenterCoordinates(all);
        const centerOfMap = locationPoint.center;
        const size = 0.3;
        const drawer = new Drawing();
        const circuitoTraduction = 'Circuiti';
        drawer.setUnits('Meters');
        const colors = ['RED', 'YELLOW', 'GREEN', 'CYAN', 'BLUE', 'MAGENTA'];
// gli undefined generano errori
        // CREAZIONE LAYER
        drawer.addLayer(getName(null), Drawing.ACI[colors[0]], 'CONTINUOUS'); // Primo layer per i punti luce senza quadro
        circuiti.forEach(
            (circuito, index) => {
                const color = colors[(index + 1) % colors.length]
                drawer.addLayer(getName(circuito), Drawing.ACI[color], 'CONTINUOUS'); // Un layer per ogni quadro
            }
        )


        // AGGIUNGO ENTITÀ AI LAYER LAYER
        circuiti.forEach(
            (circuito, index) => {
                drawer.setActiveLayer(getName(circuito));
                const block = drawer.addBlock(circuitoTraduction + getName(circuito))
                const point = this.getXYinMetersWithCenter(centerOfMap, circuito.location)
                drawer.drawRect(point.x - size, point.y - size, point.x + size, point.y + size, null, null, block);
            }
        )

        // const block = drawer.addBlock('BlockName' + getName(circuito));
        // console.log("block", block)
        // drawer.drawLine(0, 0, size, size, block);
        // drawer.drawCircle(0, 0, size, block);
        // drawer.drawRect(0 - size, 0 - size, 0 + size, 0 + size, null, null, block);

        // rivedere dxf-writer fork branch on fit dev-mmv
        // non funziona correttamente drawer.drawArc(0, 0, size, 0, 180, block);
        // non funziona correttamente drawer.drawEllipse(0, 0, size,size/2,1.5,0,360, block);

        puntiLuce.forEach(
            (puntoLuce, index) => {
                const numeroQuadro = getName(puntoLuce.circuito);
                drawer.setActiveLayer(numeroQuadro);
                const targa = puntoLuce.targa || puntoLuce.targaCustom;
                const block = drawer.addBlock(targa)
                const point = this.getXYinMetersWithCenter(centerOfMap, puntoLuce.location)
                if (puntoLuce.numeroLampade > 1) {
                    let i = 0;
                    const r = 1;
                    const partialAngle = 2 * Math.PI / puntoLuce.numeroLampade;
                    while (i < puntoLuce.numeroLampade) {
                        const partialX = r * Math.sin(i * partialAngle) + point.x;
                        const partialY = r * Math.cos(i * partialAngle) + point.y;
                        drawer.drawLine(point.x, point.y, partialX, partialY, block);
                        drawer.drawCircle(partialX, partialY, size, block);
                        i++;
                    }
                } else {
                    drawer.drawCircle(point.x, point.y, size, block);
                }
            }
        )

        // AGGIUNGO "TAG" PER AUTOCAD
        drawer.generateAutocadExtras();
        // CREO LA STRINGA
        const blob = new Blob([drawer.toDxfString()]);
        this.openLink(blob, filename + '.dxf');
    }


    private downloadJsonFile(json: object, filename) {
        const dataStr = JSON.stringify(json);
        let blob = new Blob(['\ufeff' + dataStr], {type: 'text/kml;charset=utf-8;'});
        this.openLink(blob, filename + '.geojson');
    }

    downloadGeojsonElement(element: PuntiLuceParse[] | CircuitiParse[], className, filename) {
        let geojson;
        if (className == this.className.puntiLuce) {
            geojson = this.convertPlToGeojson(element as PuntiLuceParse[], filename, className);
        } else {
            geojson = this.convertCircuitiToGeojson(element as CircuitiParse[], filename, className);
        }
        this.downloadJsonFile(geojson, filename);
    }

    public sostituisciSpaziconUnderscore(value) {
        if (typeof value == 'string') {
            return value.replace(/ /g, '_');
        } else {
            return value;
        }
    }

    public downloadLocalFile(fileName: string, url: string): Observable<void> {
        return new Observable(subscriber => {
            var request = new XMLHttpRequest();
            request.open('GET', url, true);
            request.responseType = 'blob';
            request.onload = () => {
                this.openLink(request.response, fileName);
                subscriber.next();
                subscriber.complete();
            };
            request.onerror = (e) => {
                subscriber.error();
                subscriber.complete();
            }
            request.send();
        })

    }


    getUint8Array$(file: File): Observable<any> {
        return new Observable(subscriber => {
            const _arrayBufferToBase64 = (buffer) => {
                var binary = '';
                var bytes = new Uint8Array(buffer);
                var len = bytes.byteLength;
                for (var i = 0; i < len; i++) {
                    binary += String.fromCharCode(bytes[i]);
                }
                return window.btoa(binary);
            }
            file.arrayBuffer()
                .then(buffer => {
                    subscriber.next({base64: _arrayBufferToBase64(buffer)});
                    subscriber.complete()
                    subscriber.unsubscribe()
                    // perform all required operations with x here.
                })
                .catch(error => {
                    subscriber.error(error)
                    subscriber.complete()
                    subscriber.unsubscribe()
                })
        })
    }
}