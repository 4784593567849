<form [formGroup]="formGroup" *ngIf="formGroup" style="height: calc(100vh - 305px)">
    <div class="flex-center-width100" style="margin: 5px">
        <mat-card *ngIf="etichetta$|async as etichetta" class="background-primary ">
            <!--                <mat-card-subtitle>-->
            <!--                    <ng-container-->
            <!--                            *ngFor="let traducion of etichetta.traductions;let first = first">-->
            <!--                        <ng-container *ngIf="!first">-->
            <!--                            /-->
            <!--                        </ng-container>-->
            <!--                        {{traducion|translate}}-->
            <!--                    </ng-container>-->
            <!--                </mat-card-subtitle>-->
            <mat-card-content class="text-title">
                {{etichetta.value}}
            </mat-card-content>
        </mat-card>
    </div>
    <ng-container formGroupName="filter">
        <app-select-toolbar
                [possibleValues]="possibleTabs|convertValueByPredicateFunction:possibleTabsPredicate:keyPossibleTabs:abbonamentoGestioneAttivo:abbonamentoTelecontrolloAttivo:isGestore:isSviluppatore"
                formControlName="selectedTab">

        </app-select-toolbar>
        <div class="flex-center-width100">
            <mat-button-toggle-group class="accent" formControlName="typeVisualizedKeys">
                <mat-button-toggle
                        value="all">{{'dashboard_sidenav.all' | translate}}</mat-button-toggle>
                <mat-button-toggle
                        value='favorites'>{{'dashboard_sidenav.favorite' | translate}}</mat-button-toggle>
                <mat-button-toggle
                        value='compiled'>{{'dashboard_sidenav.compiled'| translate}}</mat-button-toggle>
                <mat-button-toggle
                        value='updated'>{{'dashboard_sidenav.updated'| translate}}</mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <div style="padding: 10px">
            <mat-form-field appearance="outline" style="margin-bottom: -28px">
                <mat-label>
                    {{'filterName'|translate}}
                </mat-label>
                <input formControlName="searchName" matInput>
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
        </div>
    </ng-container>
    <ng-container formGroupName="puntoLuce">
        <cdk-virtual-scroll-viewport itemSize="40" class="w100 h100">
            <mat-list style="padding-bottom: 60px"
                      *ngIf="(selectedTab$|async)|convertValueByPredicateFunction:getFormByDetailPredicate as fields">
                <ng-container
                        *ngFor="let field of fields[0]|convertValueByPredicateFunction:predicateFilterFunction:(favorites$|async):(searchName$|async):(typeVisualizedKeys$|async):(compiledFormFields$|async):(detailFormValues$|async):translateInstant">
                    <ng-container *ngIf="field.sectionName==null;else ContainerSectionName">
                        <mat-list-item style="height: unset">
                            <button mat-icon-button matListItemIcon
                                    [disabled]="field.disabled"
                                    (click)="clickFavorite(field.formControlName)">
                                <mat-icon
                                        [ngClass]="{'color-primary':field.formControlName|convertValueByPredicateFunction:favoriteIsSetPredicate:(favorites$|async)}">
                                    star
                                </mat-icon>
                            </button>
                            <div matListItemTitle style="margin-bottom: -25px" class="w100">
                                <ng-container
                                        *ngIf="field|convertValueByPredicateFunction:isInLoadiongPredicate:(loadingFormField$|async);else LoadingPossibleValues">
                                    <app-get-form-fiel-by-configuration-element
                                            (focusin)="focusIn(field)"
                                            [type]="field.typeForm"
                                            [titleTraduction]="field.traduction"
                                            [possibleValues]="(field|convertValueByPredicateFunction:possibleValuesPredicate:circuiti:strade:lightPointsNearest)"
                                            [formControlName]="field.formControlName"
                                    >
                                    </app-get-form-fiel-by-configuration-element>
                                </ng-container>
                            </div>
                            <!--                                    <div matListItemLine>{{folder.updated | date}}</div>-->
                        </mat-list-item>


                    </ng-container>
                    <ng-template #ContainerSectionName>
                        <ng-container
                                [ngTemplateOutlet]="SectionName"
                                [ngTemplateOutletContext]="{sectionName:field.sectionName}"
                        >
                        </ng-container>
                    </ng-template>
                </ng-container>

            </mat-list>
        </cdk-virtual-scroll-viewport>
    </ng-container>


</form>

<ng-template #LoadingPossibleValues>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>
<ng-template #SectionName let-sectionName="sectionName">
    <mat-toolbar color="primary">
        <mat-toolbar-row>
            <span style="color: #2196f3">{{sectionName|translate}}</span>
        </mat-toolbar-row>
    </mat-toolbar>
</ng-template>