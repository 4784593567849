<ng-container *ngIf="loading;else noLoad">
    <ng-container *ngIf="!progressIsSet;else prorgressContainer">
        <button mat-button [ngClass]="type" [color]="color" disabled="true">
            <mat-icon>
                <mat-spinner [diameter]="diameter" style="margin-left: 2px;margin-top: 2px"></mat-spinner>
            </mat-icon>
        </button>
    </ng-container>
    <ng-template #prorgressContainer>
        <button mat-button [ngClass]="type" [color]="color" disabled="true">
            <!--            <div style="position:absolute;text-align: center;right: 50%;top:50%;transform: translate(50%,-50%);font-size: xx-small;font-weight: bold">-->
            <!--            </div>-->
            <div style="font-size: x-small;font-weight: bold;margin-top: -2px;" class="color-primary">
                {{progress}} %
            </div>
            <mat-progress-bar mode="indeterminate" style="margin-top: -7px;margin-bottom: 5px;"></mat-progress-bar>
        </button>
    </ng-template>
</ng-container>
<ng-template #noLoad>
    <ng-content>

    </ng-content>
</ng-template>
