import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmDeleteDialogComponent } from './confirm-delete-dialog/confirm-delete-dialog.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { SelectNewElementComponent } from './select-new-element/select-new-element.component';
import { EliminateAllElementComponent } from './eliminate-all-element/eliminate-all-element.component';
import {TranslateModule} from "@ngx-translate/core";
import { WhatImportComponent } from './what-import/what-import.component';
import {MatGridListModule} from "@angular/material/grid-list";
import { SelectRowComponent } from './select-row/select-row.component';
import {MatLegacyTableModule as MatTableModule} from "@angular/material/legacy-table";
import {ReactiveFormsModule} from "@angular/forms";
import {MatLegacySelectModule as MatSelectModule} from "@angular/material/legacy-select";
import {MatLegacyInputModule as MatInputModule} from "@angular/material/legacy-input";
import {MatLegacyListModule as MatListModule} from "@angular/material/legacy-list";
import {WidgetsModule} from '../../widgets/widgets.module';
import { CreateNewIotDevicesComponent } from './create-new-iot-devices/create-new-iot-devices.component';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from "@angular/material/legacy-autocomplete";
import { SelectOrCreateComponent } from './select-or-create/select-or-create.component';
import { SelectElementToProceedComponent } from './select-element-to-proceed/select-element-to-proceed.component';
import { ChooseActionsComponent } from './choose-actions/choose-actions.component';
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {MatRippleModule} from "@angular/material/core";




@NgModule({
    declarations: [ConfirmDeleteDialogComponent, SelectNewElementComponent, EliminateAllElementComponent, WhatImportComponent, SelectRowComponent, CreateNewIotDevicesComponent, SelectOrCreateComponent, SelectElementToProceedComponent, ChooseActionsComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        MatFormFieldModule,
        MatChipsModule,
        MatTooltipModule,
        TranslateModule,
        MatGridListModule,
        MatTableModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatInputModule,
        MatListModule,
        WidgetsModule,
        MatAutocompleteModule,
        MatCardModule,
        MatRippleModule
    ],
    exports: [SelectOrCreateComponent]
})
export class ConfirmDeleteModule { }
