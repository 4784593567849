import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AggregateDataReportComponent} from "./aggregate-data-report/aggregate-data-report.component";
import {PipesModule} from "../../providers/pipes/pipes.module";
import {WidgetsModule} from "../../widgets/widgets.module";
import {DirectivesModule} from "../../providers/directives/directives.module";
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {TranslateModule} from "@ngx-translate/core";
import {MatIconModule} from "@angular/material/icon";
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from "@angular/material/legacy-progress-spinner";
import {MatToolbarModule} from "@angular/material/toolbar";


@NgModule({
    declarations: [
        AggregateDataReportComponent
    ],
    imports: [
        CommonModule,
        PipesModule,
        WidgetsModule,
        DirectivesModule,
        MatCardModule,
        TranslateModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatToolbarModule
    ],
    exports: [AggregateDataReportComponent]
})
export class ReportLightPointModule {
}
