import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImportFileMapComponent } from './import-file-map/import-file-map.component';
// import {AgmCoreModule} from "@agm/core";
import {MatSidenavModule} from "@angular/material/sidenav";
// import {AgmJsMarkerClustererModule} from "@agm/js-marker-clusterer";
import { MainMapComponent } from './main-map/main-map.component';
import {TranslateModule} from "@ngx-translate/core";
import {PipesModule} from "../../providers/pipes/pipes.module";
import {GoogleMapsModule} from "@angular/google-maps";
import {MatCardModule} from "@angular/material/card";
import {MatListModule} from "@angular/material/list";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";

//todo aggiornanre mappa


@NgModule({
  declarations: [ImportFileMapComponent, MainMapComponent],
  exports: [
    ImportFileMapComponent,
    MainMapComponent
  ],
    imports: [
        CommonModule,
        // AgmCoreModule,
        MatSidenavModule,
        // AgmJsMarkerClustererModule,
        TranslateModule,
        PipesModule,
        GoogleMapsModule,
        MatCardModule,
        MatListModule,
        MatIconModule,
        MatButtonModule
    ]
})
export class ComponentWithMapModule { }
