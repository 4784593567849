<mat-tab-group #matTabGroup class="huna-material-class-open-new-tab"
               [ngClass]="{'mat-tab-group-sub':isSubGroup}">
    <mat-tab label="{{'listTab'|translate}}">
        <ng-content>

        </ng-content>
    </mat-tab>
    <ng-container [ngSwitch]="whatOpen">
        <ng-container *ngSwitchCase="'plan'">
            <ng-container *ngFor="let plan of openedList">
                <mat-tab>
                    <ng-container *ngIf="plan.objectId!=newPlan;else createNewPlan">
                        <app-update-plan [planSlug]="plan.objectId" (planIsRemoved)="removedPlan($event)">

                        </app-update-plan>
                    </ng-container>
                    <ng-template #createNewPlan>
                        <app-create-plan (planIsReady)="newPlanIsReady($event)">

                        </app-create-plan>
                    </ng-template>
                    <ng-template mat-tab-label>
                        <app-box-adding-tab [titolo]="plan.titolo" [color]="plan.priority">
                            <button mat-icon-button (click)="closeTab($event,plan)">
                                <mat-icon>clear</mat-icon>
                            </button>
                        </app-box-adding-tab>
                    </ng-template>
                </mat-tab>
            </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'report'">
            <ng-container *ngFor="let report of openedList">
                <mat-tab>
                    <app-update-report [planSlug]="report.objectId" [visualezedButtonSeaMap]="visualezedButtonSeaMap" (update)="updateList.emit()">

                    </app-update-report>
                    <ng-template mat-tab-label>
                        <app-box-adding-tab [titolo]="report.titolo" [color]="report.priority">
                            <button mat-icon-button (click)="closeTab($event,report)">
                                <mat-icon>clear</mat-icon>
                            </button>
                        </app-box-adding-tab>
                    </ng-template>
                </mat-tab>
            </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'street'">
            <ng-container *ngFor="let street of openedList">
                <mat-tab>
                    <app-edit-street [streetId]="street.objectId"
                                     [photometries]="photometries"
                                     [gruppiFotometrie]="gruppiFotomotrie"
                                     [forceUpdateItemByList]="forceUpdateItemByList"
                                     (updateStreet)="updateItemEmit($event,street)"
                    >

                    </app-edit-street>
                    <ng-template mat-tab-label>
                        <app-box-adding-tab [titolo]="street.titolo" [color]="street.priority">
                            <button mat-icon-button (click)="closeTab($event,street)">
                                <mat-icon>clear</mat-icon>
                            </button>
                        </app-box-adding-tab>
                    </ng-template>
                </mat-tab>
            </ng-container>
        </ng-container>
    </ng-container>
</mat-tab-group>
