import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class RolesService {

    constructor() {
    }

    /**
     * @param rolesActive   sono i ruoli attualmente attivi sul progetto selezionato, per l'utente loggato
     * @param roles         sono i ruoli che possono effettuare una qualsiasi attività
     * @return boolean
     */
    public checkRoles(rolesActive: string[], roles: string[]): boolean {
        for (const roleActive of rolesActive) {
            for (const role of roles) {
                if (roleActive == role) {
                    return true;
                }
            }
        }
        return false;
    }
}
