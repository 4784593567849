import {Injectable} from '@angular/core';
import {CircuitiParse} from "../../models/Circuiti.Parse";
import {ScaleColorService} from "./scale-color.service";
import {ordinamentoEcampiTraduzioni} from "../../models/ordinamentoEcampiTraduzioni";
import {
    chiaviScala,
    circuitiScalaColore,
    isNotNullOrUndefined,
    qualitativeScaleColor,
    stringIsSet
} from "../../models/Models";
import {TransformForTranslatePipe} from "../pipes/transform-for-translate.pipe";
import {DatePipe} from "@angular/common";
import {IconService} from "./icon.service";
import {PuntiLuceParse} from "../../models/PuntiLuce.Parse";
import {LocalSotrageService} from "./local-sotrage.service";

@Injectable({
    providedIn: 'root'
})
export class CircuitScaleColorService {

    private _circuitiInMappa: CircuitiParse[] = [];
    private _campoScalaColore: string;
    private scalaColorePredefinite = circuitiScalaColore;
    private qualitativeScale = qualitativeScaleColor;
    private colorTrue: string = '#00FF00';
    private colorFalse: string = '#FF0000';
    private chiaviScalaColore = chiaviScala;
    public scalaColoreCompleta;
    public scalaColoreArray;

    constructor(private scaleColorService: ScaleColorService,
                private datePipe: DatePipe,
                private iconService: IconService,
                private localStorage: LocalSotrageService
    ) {
        if (stringIsSet(localStorage.labelLabelsCircuits) && !stringIsSet(this.campoScalaColore)) {
            this.campoScalaColore = localStorage.labelScaleColorCircuits;
        }
    }

    resetAllVariable() {
        this.circuitiInMappa = [];
        this.campoScalaColore = undefined;
        this.scalaColoreCompleta = undefined;
        this.scalaColoreArray = undefined;
    }

    transformScalaColoreInArray(keyColor) {
        return this.scaleColorService.transformScalaColoreInArray(keyColor)
    }


    private sortCircuiti(a, b) {

    }

    public createKey(key: any | undefined) {
        if (!isNotNullOrUndefined(key) || typeof key === 'object') {
            const filterPipe = new TransformForTranslatePipe();
            const chiaveSenzaSpazi = filterPipe.transform(this.chiaviScalaColore.non_specificato) as string;
            return chiaveSenzaSpazi;
        } else if (typeof key === 'string') {
            const filterPipe = new TransformForTranslatePipe();
            const chiaveSenzaSpazi = filterPipe.transform(key) as string;
            return chiaveSenzaSpazi;
        } else {
            return key;
        }
    }

    public getKey(circuito: any | undefined, key) {
        let value = circuito[key]
        let tipoDato = this.tipoDato
        if (ordinamentoEcampiTraduzioni.Circuiti.hasOwnProperty(this.campoScalaColore) && ordinamentoEcampiTraduzioni.Circuiti[this.campoScalaColore].getValueInLabel != null) {
            const {
                key,
                showingLikeType
            } = ordinamentoEcampiTraduzioni.Circuiti[this.campoScalaColore].getValueInLabel(circuito);
            value = key;
            tipoDato = showingLikeType
        }
        if (!isNotNullOrUndefined(value)) {
            const filterPipe = new TransformForTranslatePipe();
            const chiaveSenzaSpazi = filterPipe.transform(this.chiaviScalaColore.non_specificato) as string;
            return chiaveSenzaSpazi;
        } else if (
            tipoDato === 'text' ||
            tipoDato === 'targa' ||
            tipoDato === 'elenco' ||
            this.scalaColorePredefinite.hasOwnProperty(this.campoScalaColore)
        ) {
            const filterPipe = new TransformForTranslatePipe();
            const chiaveSenzaSpazi = filterPipe.transform(value) as string;
            return chiaveSenzaSpazi;
        } else if (tipoDato === 'date') {
            const key = new Date(this.datePipe.transform(value, 'yyyy-MM-dd')).getTime();
            return key;
        } else if (tipoDato === 'bool') {
            const key = (value) ? 'true' : 'false';
            return key;
        } else if (tipoDato === 'file') {
            const key = (value != null) ? 'true' : 'false';
            return key;
        } else if (this.isPointer) {
            const pointerClass = this.possibleValues[0];
            const pointerKey = this.possibleValues[1];
            const poineterValue = value[pointerKey];
            const filterPipe = new TransformForTranslatePipe();
            if (isNotNullOrUndefined(poineterValue)) {
                return filterPipe.transform(poineterValue) as string;
            } else {
                return filterPipe.transform(this.chiaviScalaColore.non_specificato) as string;
            }
        } else {
            return value;
        }
    }


    public get tipoDato() {
        let tipoDato;
        if (ordinamentoEcampiTraduzioni.Circuiti[this.campoScalaColore] == null || ordinamentoEcampiTraduzioni.Circuiti[this.campoScalaColore].type == null) {
            tipoDato = 'elenco';
        } else {
            tipoDato = ordinamentoEcampiTraduzioni.Circuiti[this.campoScalaColore].type.toLowerCase();
        }
        return tipoDato;
    };

    get possibleValues(): string[] {
        if (this.tipoDato.includes('pointer')) {
            return (ordinamentoEcampiTraduzioni.Circuiti[this.campoScalaColore] &&
                ordinamentoEcampiTraduzioni.Circuiti[this.campoScalaColore].hasOwnProperty('possibleValues') &&
                typeof ordinamentoEcampiTraduzioni.Circuiti[this.campoScalaColore].possibleValues === 'string') ?
                ordinamentoEcampiTraduzioni.Circuiti[this.campoScalaColore].possibleValues.split('.') : [];
        } else {
            return (
                ordinamentoEcampiTraduzioni.Circuiti[this.campoScalaColore] &&
                ordinamentoEcampiTraduzioni.Circuiti[this.campoScalaColore].hasOwnProperty('possibleValues')) ?
                ordinamentoEcampiTraduzioni.Circuiti[this.campoScalaColore].possibleValues : [];
        }
    }

    get campoScalaColore() {
        return this._campoScalaColore;
    }

    set campoScalaColore(value) {
        this._campoScalaColore = value;
        this.localStorage.labelScaleColorCircuits = value;
    }

    get circuitiInMappa() {
        return this._circuitiInMappa;
    }

    set circuitiInMappa(value) {
        this._circuitiInMappa = value;
    }

    public get isPointer() {
        return this.tipoDato.includes('pointer');
    }

    private linearTransformation(x: number, min: number, max: number, lengthArray: number): number {
        return this.scaleColorService.linearTransformation(x, min, max, lengthArray);
    }

    creaScalaColore(campoScalaColore: string, elementiInMap: CircuitiParse[] | PuntiLuceParse[], scalaColorePredefinite, tipoDato, isPointer, possibleValues): {
        scalaColore,
        scalaColoreHtml
    } {
        return this.scaleColorService.creaScalaColore(campoScalaColore, elementiInMap, scalaColorePredefinite, tipoDato, isPointer, possibleValues)
    }

    calcolaScalaColore(campoScalaColore: string, circuitiInMappa) {
        if (!isNotNullOrUndefined(campoScalaColore)) {
            this.resetAllVariable()
        } else {
            this.campoScalaColore = campoScalaColore;
            this.circuitiInMappa = circuitiInMappa;
            let tipoDato = this.tipoDato;
            if (ordinamentoEcampiTraduzioni.PuntiLuce.hasOwnProperty(this.campoScalaColore) && ordinamentoEcampiTraduzioni.PuntiLuce[this.campoScalaColore].getValueInLabel != null) {
                const {
                    key,
                    showingLikeType
                } = ordinamentoEcampiTraduzioni.PuntiLuce[this.campoScalaColore].getValueInLabel(undefined);
                tipoDato = showingLikeType
            }
            const scale = this.creaScalaColore(this.campoScalaColore, this.circuitiInMappa, this.scalaColorePredefinite, tipoDato, this.isPointer, this.possibleValues);
            this.scalaColoreCompleta = scale.scalaColore;
            this.scalaColoreArray = scale.scalaColoreHtml;
        }
    }

    aggiornaScalaColore() {
        let tipoDato = this.tipoDato;
        if (ordinamentoEcampiTraduzioni.PuntiLuce.hasOwnProperty(this.campoScalaColore) && ordinamentoEcampiTraduzioni.PuntiLuce[this.campoScalaColore].getValueInLabel != null) {
            const {
                key,
                showingLikeType
            } = ordinamentoEcampiTraduzioni.PuntiLuce[this.campoScalaColore].getValueInLabel(undefined);
            tipoDato = showingLikeType
        }
        const scale = this.creaScalaColore(this.campoScalaColore, this.circuitiInMappa, this.scalaColorePredefinite, tipoDato, this.isPointer, this.possibleValues);
        this.scalaColoreCompleta = scale.scalaColore;
        this.scalaColoreArray = scale.scalaColoreHtml;
    }

    public getIconCircuito(circuito) {
        if (!isNotNullOrUndefined(this.campoScalaColore)) {
            return this.iconService.iconQuadroMy();
        } else {
            const key = this.campoScalaColore;
            const scalaColore = this.scalaColoreCompleta;
            const keyCircuito = this.getKey(circuito, key);
            const indexColor = Object.keys(scalaColore).findIndex(key => key == keyCircuito)
            if (indexColor >= 0) {
                return this.iconService.iconQuadroChangedColor(scalaColore[keyCircuito]);
            } else {
                const keyUndefined = this.createKey(undefined);
                return this.iconService.iconQuadroChangedColor(scalaColore[keyUndefined]);
            }
        }
    }

    public circuitiContainedNewValue(circuiti: CircuitiParse[]) {
        if (!isNotNullOrUndefined(this.campoScalaColore) || !(Array.isArray(circuiti) && circuiti.length >= 0)) {
            return false
        } else {
            const key = this.campoScalaColore;
            const scalaColore = this.scalaColoreCompleta;
            const newValueForScaleColor = circuiti.findIndex(
                circuito => {
                    const keyCircuito = this.getKey(circuito, key)
                    const indexColor = Object.keys(scalaColore).findIndex(key => key == keyCircuito)
                    return indexColor < 0;
                }
            );
            return newValueForScaleColor >= 0
        }
    }

}
