<div class="position-top-right">
    <ng-content select="[topRight]">

    </ng-content>
</div>
<ng-container [ngSwitch]="field.type">
    <ng-container *ngSwitchCase="typeFormField.inputString">
        <mat-form-field appearance="fill" class="marginForCenterFormInTable width-item">
            <mat-label>
                {{getFieldName(field)}}
            </mat-label>
            <input matInput>
            <span matPrefix>
            <button mat-icon-button (click)="$event.stopPropagation();expandMoreLessNote();">
                <mat-icon>info</mat-icon>
            </button>
            </span>
        </mat-form-field>
        <mat-card *ngIf="expandNote">
            <mat-card-subtitle>{{getFieldName(field)}}</mat-card-subtitle>
            {{getNote(field)}}
        </mat-card>
    </ng-container>
    <ng-container *ngSwitchCase="typeFormField.inputNumber">
        <mat-form-field appearance="fill" class="marginForCenterFormInTable width-item">
            <mat-label>
                {{getFieldName(field)}}
            </mat-label>
            <input type="number" matInput>
            <span matPrefix>
            <button mat-icon-button (click)="$event.stopPropagation();expandMoreLessNote();">
                <mat-icon>info</mat-icon>
            </button>
            </span>
        </mat-form-field>
        <mat-card *ngIf="expandNote">
            <mat-card-subtitle>{{getFieldName(field)}}</mat-card-subtitle>
            {{getNote(field)}}
        </mat-card>
    </ng-container>
    <ng-container *ngSwitchCase="typeFormField.multiSelect">
        <mat-form-field appearance="fill" class="marginForCenterFormInTable width-item">
            <mat-label>
                {{getFieldName(field)}}
            </mat-label>
            <mat-select>
                <mat-option *ngFor="let opt of field.possibleValues">
                    {{opt}}
                </mat-option>
            </mat-select>
            <span matPrefix>
            <button mat-icon-button (click)="$event.stopPropagation();expandMoreLessNote();">
                <mat-icon>info</mat-icon>
            </button>
            </span>
        </mat-form-field>
        <mat-card *ngIf="expandNote">
            <mat-card-subtitle style="font-weight: bold">{{getFieldName(field)}}</mat-card-subtitle>
            {{getNote(field)}}
        </mat-card>
    </ng-container>
    <ng-container *ngSwitchCase="typeFormField.autoComplete">
        <app-auto-complete-form
                classStyle="marginForCenterFormInTable"
                [title]="getFieldName(field)"
                [items]="getAutoComplete(field.possibleValues)"
                appearance="fill"
                [hiddenClearButton]="true"
        >
            <div prefix>
                <button mat-icon-button (click)="$event.stopPropagation();expandMoreLessNote();">
                    <mat-icon>info</mat-icon>
                </button>
            </div>
        </app-auto-complete-form>
        <mat-card *ngIf="expandNote">
            <mat-card-subtitle style="font-weight: bold">{{getFieldName(field)}}</mat-card-subtitle>
            {{getNote(field)}}
        </mat-card>
    </ng-container>
    <ng-container *ngSwitchCase="typeFormField.dataPicker">
        <mat-form-field appearance="fill" class="marginForCenterFormInTable width-item">
            <mat-label>{{getFieldName(field)}}</mat-label>
            <input matInput [matDatepicker]="picker">
            <span matPrefix>
                <button mat-icon-button (click)="$event.stopPropagation();expandMoreLessNote();">
                    <mat-icon>info</mat-icon>
                </button>
            </span>
            <mat-datepicker-toggle matSuffix
                                   [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-card *ngIf="expandNote">
            <mat-card-subtitle style="font-weight: bold">{{getFieldName(field)}}</mat-card-subtitle>
            {{getNote(field)}}
        </mat-card>
    </ng-container>
    <ng-container *ngSwitchCase="typeFormField.hour">
        <div style="margin: 0px 0 0px 0;background: rgb(245,245,245);border-bottom: 1px solid rgb(142 142 142);color:rgba(0,0,0,0.65);">
            <div class="ngx-theme">
                <div style="display: flex;justify-content: space-between">
                    <div style="max-width: 190px;" class="truncate-text">
                        <button style="margin-left: 8px;"
                                mat-icon-button (click)="$event.stopPropagation();expandMoreLessNote();">
                            <mat-icon>info</mat-icon>
                        </button>
                        {{getFieldName(field)}}
                    </div>
                    <ngx-timepicker-field
                            style="margin-right: 10px"
                            [format]="24"
                            [clockTheme]="customThemNgxDataPicker"
                            [confirmBtnTmpl]="confirmBtn"
                            [cancelBtnTmpl]="cancelBtn"
                            [toggleIcon]="icon"
                    >
                    </ngx-timepicker-field>
                    <ng-template #icon>
                        <mat-icon color="accent">watch_later</mat-icon>
                    </ng-template>
                    <ng-template #confirmBtn>
                        <button mat-mini-fab color="accent" style="margin-left: 10px">
                            <mat-icon>done</mat-icon>
                        </button>
                    </ng-template>
                    <ng-template #cancelBtn>
                        <button mat-mini-fab color="accent">
                            <mat-icon>clear</mat-icon>
                        </button>
                    </ng-template>
                </div>
            </div>
        </div>
        <mat-card *ngIf="expandNote">
            <mat-card-subtitle style="font-weight: bold">{{getFieldName(field)}}</mat-card-subtitle>
            {{getNote(field)}}
        </mat-card>
    </ng-container>
    <ng-container *ngSwitchCase="typeFormField.image">
        <ngx-dropzone class="drop-zone-item-background"
                      accept="*"
        >
            <ngx-dropzone-label class="w100">
                <div class="flex-around-center w100">
                    <div style="width: 47px;text-align: end">
                        <button mat-icon-button (click)="$event.stopPropagation();expandMoreLessNote();">
                            <mat-icon style="color: black">info</mat-icon>
                        </button>
                    </div>
                    <div class="flex-start-start flex-flow-column" style="width: calc(100% - 47px)">
                        <span style="text-align: start;font-size: 14px;margin-bottom: 3px;margin-left: 20px">{{ getFieldName(field)}}</span>
                        <div class="w100" style="text-align: center">   {{ 'fileManager.copyFile' | translate}}</div>
                    </div>
                </div>
            </ngx-dropzone-label>
        </ngx-dropzone>
        <mat-card *ngIf="expandNote" style="margin-top: -12px;margin-bottom: 12px;">
            <mat-card-subtitle style="font-weight: bold">{{getFieldName(field)}}</mat-card-subtitle>
            {{getNote(field)}}
        </mat-card>
    </ng-container>
    <ng-container *ngSwitchCase="typeFormField.checkBox">
        <div style="margin: 0px 0 0px 0;background: rgb(245,245,245);border-bottom: 1px solid rgb(142 142 142);color:rgba(0,0,0,0.65);">
            <div class="flex-center-Between w100">
                <p style="margin:5px 0  5px 7px;">
                    <button matPrefix mat-icon-button
                            (click)="$event.stopPropagation();expandMoreLessNote();">
                        <mat-icon style="color: black">info</mat-icon>
                    </button>
                    {{getFieldName(field)}}
                </p>
                <mat-checkbox
                        style="margin-right: 25px"
                >
                </mat-checkbox>
            </div>
        </div>
        <div>
            <mat-card *ngIf="expandNote">
                <mat-card-subtitle style="font-weight: bold">{{getFieldName(field)}}</mat-card-subtitle>
                {{getNote(field)}}
            </mat-card>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="typeFormField.yesOrNot">
        <div style="margin: 0 0 0 0;background: rgb(245,245,245);border-bottom: 1px solid rgb(142 142 142);">
            <div class="flex-center-Between w100">
                <p style="color:rgb(98,98,98);margin: 5px 0 5px 10px ">
                    <span>
                          <button mat-icon-button (click)="$event.stopPropagation();expandMoreLessNote();">
                            <mat-icon style="color: black">info</mat-icon>
                         </button>
                    </span>
                    &#160;
                    {{getFieldName(field)}}
                </p>
                <div>
                    <button style="margin: 5px" mat-flat-button
                            [color]="getColorYesOrNotButton('yes')"
                            (click)="setValueYesOrNotButton('yes',true)">
                        <mat-icon>check_circle</mat-icon>
                    </button>
                    <button style="margin: 5px 20px 5px 5px" mat-flat-button
                            (click)="setValueYesOrNotButton('not',true)"
                            [color]="getColorYesOrNotButton('not')">
                        <mat-icon>cancel</mat-icon>
                    </button>
                </div>
            </div>
            <mat-card *ngIf="expandNote">
                <mat-card-subtitle style="font-weight: bold">{{getFieldName(field)}}</mat-card-subtitle>
                {{getNote(field)}}
            </mat-card>
        </div>
    </ng-container>
</ng-container>