import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatLegacyTabsModule as MatTabsModule} from "@angular/material/legacy-tabs";
import {MatIconModule} from "@angular/material/icon";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import {TranslateModule} from "@ngx-translate/core";
import {BoxesModule} from "../../boxes/boxes.module";
import {CreatePlanComponent} from "./create-plan/create-plan.component";
import {CoreModule} from "../../core/core.module";
import {MatLegacyFormFieldModule as MatFormFieldModule} from "@angular/material/legacy-form-field";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from "@angular/material/legacy-progress-spinner";
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {MatLegacyTooltipModule as MatTooltipModule} from "@angular/material/legacy-tooltip";
import {MatLegacyInputModule as MatInputModule} from "@angular/material/legacy-input";
import {UpdatePlanComponent} from "./update-plan/update-plan.component";
import {InfoDeleteDialogComponent} from "./info-delete-dialog/info-delete-dialog.component";
import {InfoPlanComponent} from "./info-plan/info-plan.component";
import {InfoPlanDialogComponent} from "./info-plan-dialog/info-plan-dialog.component";
import {InfoSaveDialogComponent} from "./info-save-dialog/info-save-dialog.component";
import {WidgetsModule} from "../../widgets/widgets.module";
import {MatLegacyRadioModule as MatRadioModule} from "@angular/material/legacy-radio";
import {MatLegacySelectModule as MatSelectModule} from "@angular/material/legacy-select";
import {MatLegacyCheckboxModule as MatCheckboxModule} from "@angular/material/legacy-checkbox";
import {NgxDropzoneModule} from "ngx-dropzone";
import {MatLegacyListModule as MatListModule} from "@angular/material/legacy-list";
import {InfoLockedPlanDialogComponent} from "../locked-plan/info-locked-plan-dialog/info-locked-plan-dialog.component";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MatGridListModule} from "@angular/material/grid-list";
import {MatLegacySlideToggleModule as MatSlideToggleModule} from "@angular/material/legacy-slide-toggle";
import {MatLegacyChipsModule as MatChipsModule} from "@angular/material/legacy-chips";
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from "@angular/material/legacy-autocomplete";
import {MatLegacySliderModule as MatSliderModule} from "@angular/material/legacy-slider";
import {MatDividerModule} from "@angular/material/divider";
import {MatLegacyDialogModule as MatDialogModule} from "@angular/material/legacy-dialog";
import {DirectivesModule} from "../../providers/directives/directives.module";
import {InfoLockedPlanModule} from "../locked-plan/info-locked-plan.module";
import {UpdateReportComponent} from "./update-report/update-report.component";
import {PipesModule} from "../../providers/pipes/pipes.module";
import {VirtualMidNightModule} from "../virtual-mid-night/virtual-mid-night.module";
import {MatDatepickerModule} from "@angular/material/datepicker";
// import {AgmCoreModule} from "@agm/core";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MaterialTimePickerModule} from "../../dependecies/material-time-picker/material-time-picker.module";
import {ScheduleMaintenaceUtilitiesModule} from "../schedule-maintenace-utilities/schedule-maintenace-utilities.module";
import {ListElementModule} from "../list-element/list-element.module";
//todo aggiornanre mappa


@NgModule({
    declarations: [CreatePlanComponent,
        UpdatePlanComponent,
        InfoDeleteDialogComponent,
        InfoPlanComponent,
        InfoPlanDialogComponent,
        InfoSaveDialogComponent,
        UpdateReportComponent,
    ],
    exports: [
        CreatePlanComponent,
        UpdatePlanComponent,
        InfoDeleteDialogComponent,
        InfoPlanComponent,
        InfoPlanDialogComponent,
        InfoSaveDialogComponent,
        UpdateReportComponent,
    ],
    imports: [
        CommonModule,
        MatSelectModule,
        CoreModule,
        FlexLayoutModule,
        MatButtonModule,
        MatInputModule,
        MatGridListModule,
        MatSlideToggleModule,
        FormsModule,
        MatTooltipModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        TranslateModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatSliderModule,
        MatDividerModule,
        MatRadioModule,
        WidgetsModule,
        NgxDropzoneModule,
        MatIconModule,
        MatListModule,
        MatDialogModule,
        MatCardModule,
        MatCheckboxModule,
        DirectivesModule,
        InfoLockedPlanModule,
        MatProgressSpinnerModule,
        BoxesModule,
        MatTabsModule,
        PipesModule,
        VirtualMidNightModule,
        MatDatepickerModule,
        // AgmCoreModule,
        MatButtonToggleModule,
        MaterialTimePickerModule,
        ScheduleMaintenaceUtilitiesModule,
        ListElementModule,
    ]
})
export class UpdateCreateModule {
}
