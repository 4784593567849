<div class="w100">
    <div class="flex-center flex-flow-row w100">
        <button mat-raised-button  [matMenuTriggerFor]="menu" [disabled]="isDisabled$|async"
                style="width: calc(100% - 80px);min-height: 50px">
            <span class="text-title"
                  *ngIf="(currentValue$|async)as currentValue">
                {{currentValue.displayValue|traductionOnlyHasTraduction|async}}
            </span>
            <mat-icon>arrow_drop_down</mat-icon>
        </button>
        <div style="width: 50px">
            <button mat-icon-button (click)="clickPossibleValue(previus)" *ngIf="previusValue$|async as previus"
                    [disabled]="(isDisabled$|async)"
                    color="accent">
                <mat-icon>expand_less</mat-icon>
            </button>
            <button mat-icon-button (click)="clickPossibleValue(next)" *ngIf="nextValue$|async as next" color="accent"
                    [disabled]="isDisabled$|async">
                <mat-icon>expand_more</mat-icon>
            </button>
        </div>
    </div>
</div>


<mat-menu #menu="matMenu" xPosition="before">
    <button mat-menu-item *ngFor="let possibleValue of possibleValues" (click)="clickPossibleValue(possibleValue)"
            [ngClass]="{'background-primary-transparent':possibleValue|convertValueByPredicateFunction:isSetPredicate:(currentValue$|async)}"
    >
        <span>{{possibleValue.displayValue|traductionOnlyHasTraduction|async}}</span>
    </button>
</mat-menu>