<ng-container *ngIf="searchInfo">
    <div style="display: flex; flex-direction:column;  margin-top: 0.5rem;">
        <form [formGroup]="searchForm" *ngIf="config.search" class="form-search">
            <mat-form-field floatLabel="auto" appearance="fill">
                <mat-label>{{'list.search' | translate}}</mat-label>
                <input formControlName="search" matInput>
            </mat-form-field>
        </form>
        <div *ngIf="config.resolved" style="margin-bottom: 0.5rem;">
            <mat-checkbox [checked]="isResolved"
                              (change)="onResolve()">{{'view_resolved' | translate}}</mat-checkbox>
        </div>
    </div>
</ng-container>
<ng-content select="app-list-custom-filter">

</ng-content>
<div *ngIf="config.filters">
    <ng-container *ngFor="let filters of config.filters.filters; let ind = index">
        <div [ngSwitch]="filters.type">
            <div *ngSwitchCase="'checkbox'" style="margin-bottom: 5px;">
                <label class="label">{{ filters.label|translate}}</label>
                <div style="display: flex; flex-direction: row; flex-wrap: wrap">
                    <mat-checkbox (ngModelChange)="onChangeAllFilters($event, ind, filters.code, filters.type)"
                                  style="margin-right: 1.2rem"
                                  [(ngModel)]="allFiltersActive[filters.code]"
                                  [checked]="true">
                        {{'list.all' | translate}}
                    </mat-checkbox>
                    <mat-checkbox (ngModelChange)="onChangeFilters(ind, filters.code, filters.type)"
                                  *ngFor="let filter of filters.items"
                                  [(ngModel)]="filter.isActive"
                                  style="margin-right: 1.2rem">
                        {{filter.label|translate}}
                    </mat-checkbox>
                </div>
            </div>
            <div *ngSwitchCase="'radio'">
                <label class="label">{{filters.label|translate}}</label>
                <div style="display: flex; flex-direction: column">
                    <mat-radio-group (ngModelChange)="onChangeFilters(ind, filters.code, filters.type)"
                                     [(ngModel)]="filters.value">
                        <mat-radio-button [value]="undefined" style="margin-bottom: 0.3rem;">{{'list.all' | translate}}
                        </mat-radio-button>
                        <mat-radio-button *ngFor="let item of filters.items" [value]="item.value"
                                          style="margin-bottom: 0.3rem;">{{item.label|translate}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </div>
    </ng-container>
</div>
<div *ngIf="config.dataFilter" style="margin-bottom: 1rem;">

    <label class="label">{{ 'filter_data' | translate}}</label>
    <div style="display: flex; flex-direction: column">

        <div *ngIf="dimensionService.isXs()">
            <mat-radio-group (change)="onFilterDate($event)">
                <mat-radio-button style="margin-bottom: 0.3rem; width: 45%;" value="0"
                                  [checked]="dataFilterChecked['one_week']">{{'one_week' | translate}}
                </mat-radio-button>
                <mat-radio-button style="margin-bottom: 0.3rem; width: 45%;" value="1"
                                  [checked]="dataFilterChecked['two_week']">{{'two_week' | translate}}
                </mat-radio-button>
                <mat-radio-button style="margin-bottom: 0.3rem; width: 45%;" value="2"
                                  [checked]="dataFilterChecked['one_month']">{{'one_month' | translate}}
                </mat-radio-button>
                <mat-radio-button style="margin-bottom: 0.3rem; width: 45%;" value="3"
                                  [checked]="dataFilterChecked['six_month']">{{'six_month' | translate}}
                </mat-radio-button>
                <mat-radio-button style="margin-bottom: 0.3rem; width: 45%;" value="4"
                                  [checked]="dataFilterChecked['one_year']">{{'one_year' | translate}}
                </mat-radio-button>
                <mat-radio-button style="margin-bottom: 0.3rem; width: 45%;" value="5"
                                  [checked]="dataFilterChecked['all_data']">{{'all_data' | translate}}
                </mat-radio-button>
            </mat-radio-group>
        </div>

        <div *ngIf="!dimensionService.isXs()">
            <mat-radio-group (change)="onFilterDate($event)">
                <mat-radio-button style="margin-bottom: 0.3rem;" value="0"
                                  [checked]="dataFilterChecked['one_week']">{{'one_week' | translate}}
                </mat-radio-button>
                <mat-radio-button style="margin-bottom: 0.3rem;" value="1"
                                  [checked]="dataFilterChecked['two_week']">{{'two_week' | translate}}
                </mat-radio-button>
                <mat-radio-button style="margin-bottom: 0.3rem;" value="2"
                                  [checked]="dataFilterChecked['one_month']">{{'one_month' | translate}}
                </mat-radio-button>
                <mat-radio-button style="margin-bottom: 0.3rem;" value="3"
                                  [checked]="dataFilterChecked['six_month']">{{'six_month' | translate}}
                </mat-radio-button>
                <mat-radio-button style="margin-bottom: 0.3rem;" value="4"
                                  [checked]="dataFilterChecked['one_year']">{{'one_year' | translate}}
                </mat-radio-button>
                <mat-radio-button style="margin-bottom: 0.3rem;" value="5"
                                  [checked]="dataFilterChecked['all_data']">{{'all_data' | translate}}
                </mat-radio-button>
            </mat-radio-group>
        </div>

    </div>
</div>
<div class="container-list scrollable" *ngIf="data.count > 0">
    <div class="mat-elevation-z8" fxLayout="column">
        <div class="scrollbar-visible" style="max-width: 100%; overflow-x: auto; width: 100%;">
            <div style="min-width: max-content;">
                <table mat-table [dataSource]="data.rows" matSort (matSortChange)="onChangeSort($event)">
                    <ng-container *ngFor="let item of config.properties">
                        <ng-container *ngFor="let el of item | keyvalue" [matColumnDef]="el.key">
                            <th style="padding-right: 10px;" mat-header-cell *matHeaderCellDef mat-sort-header
                                [disabled]="sortDisabled(el.key)">{{el.value |translate}}
                            </th>
                            <td *matCellDef="let element" mat-cell
                                style="word-break: break-all; padding-right: 10px;">
                                <div [ngSwitch]="el.key">
                                    <ng-container *ngSwitchCase="'priorita'">
                                        <div class="priority-item priority-item-{{element[el.key]}}"></div>
                                    </ng-container>
                                    <!--                            <ng-container *ngSwitchCase="'stato'">-->
                                    <!--                                <div style="display: flex; flex-direction: row; align-items: center">-->
                                    <!--                                    <div class="priority-item priority-item-{{element[el.key]}}"></div>-->
                                    <!--                                    &nbsp;{{element[el.key]}}-->
                                    <!--                                </div>-->
                                    <!--                            </ng-container>-->
                                    <ng-container
                                            *ngSwitchDefault>{{element[el.key] | formatter:getValueFunction:el.key | maybeAsync }}</ng-container>
                                </div>
                        </ng-container>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="config.columnsToDisplay"></tr>
                    <tr style="cursor: pointer;" mat-row *matRowDef="let row; columns: config.columnsToDisplay;"
                        [ngStyle]="row.stato === 'Risolto' && {'background-color': 'rgba(232, 255, 232, 0.55)'}"
                        (click)="onUpdateElement(row)"></tr>
                </table>
            </div>
        </div>
        <div class="list-paginator">
            <mat-paginator *ngIf="config.pagination"
                           [length]="data.count"
                           [pageSize]="config.take"
                           [pageIndex]="pageIndex"
                           (page)="onChangePage($event)" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>
