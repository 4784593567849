import {Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR,
    UntypedFormBuilder,
    UntypedFormGroup,
    ValidationErrors
} from "@angular/forms";
import {combineLatest, Observable, Subscription} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {getToday, isNotNullOrUndefined} from "../../models/Models";
import {map, startWith} from "rxjs/operators";

@Component({
    selector: 'app-select-month-year-field',
    templateUrl: './select-month-year-field.component.html',
    styleUrls: ['./select-month-year-field.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: SelectMonthYearFieldComponent
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: SelectMonthYearFieldComponent
        }]
})
export class SelectMonthYearFieldComponent implements OnChanges, OnDestroy, ControlValueAccessor {
    private subScriptions = new Subscription();
    public formGroup: UntypedFormGroup;
    possibleValuesYear$: Observable<number[]>
    disableAddYear$: Observable<boolean>
    possibleValuesMonth$: Observable<{ date:Date,value:number }[]>
    disableAddMonth$: Observable<boolean>
    @Input() disabled
    @Input() label
    months = [];

    constructor(private fb: UntypedFormBuilder,
                public translateService: TranslateService) {
        let i=0;

        while (i<12){
            const date=new Date()
            date.setMonth(i)
            this.months.push({date,value:i})
            i++;
        }
        const today = getToday()
        this.formGroup = this.fb.group({month: today.getMonth(), year: today.getFullYear()});
        this.possibleValuesYear$ = this.formGroup.get('year').valueChanges.pipe(
            startWith(undefined),
            map(() => {
                const fullYear = getToday().getFullYear();
                const year = this.year != null ? this.year : fullYear;
                const years = [];
                let i = -4;
                while ((i < 4) && (year + i) <= fullYear) {
                    years.push(year + i);
                    i++;
                }
                return years
            })
        )
        this.disableAddYear$=this.formGroup.get('year').valueChanges.pipe(
            startWith(undefined),
            map(()=>{
                const currentYear=getToday().getFullYear();
              return  this.year>= currentYear;
            })
        )
        this.disableAddMonth$=combineLatest([this.disableAddYear$,
            this.formGroup.get('month').valueChanges.pipe(
            startWith(undefined))
        ]).pipe(
            map((values)=>{
                const disableYear=values[0];
                const currentMonth=getToday().getMonth()
                return disableYear&&this.month>=currentMonth
            })
        )

        this.possibleValuesMonth$=this.disableAddMonth$.pipe(
            map((disable)=>{
                if(disable){
                    const currentMonth=getToday().getMonth()
                    return this.months.filter(month=>month.value<=currentMonth)
                }else {
                    return this.months
                }
            })
        )

        this.subScriptions.add(
            this.possibleValuesMonth$.subscribe(months=>{
                const lastMonth=months[months.length-1]
                if(this.month>lastMonth.value){
                    this.month=lastMonth.value
                }
            })
        )

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.disabled) {
            if (this.disabled == true) {
                this.formGroup.disable();
            } else {
                this.formGroup.enable();
            }
        }
    }

    ngOnDestroy() {
        this.subScriptions.unsubscribe();

    }

    onTouched = () => {
    }


    registerOnChange(onChange: any) {
        const sub = this.formGroup.valueChanges
            .subscribe(onChange);
        this.subScriptions.add(sub);
    }

    registerOnTouched(onTouched) {
        this.onTouched = onTouched;
    }


    setDisabledState(disabled: boolean) {
        if (disabled) {
            this.formGroup.disable();
        } else {
            this.formGroup.enable();
        }
    }


    writeValue(value: any) {
        if (!isNotNullOrUndefined(value)) {
            const today = getToday();
            this.formGroup.reset({month: today.getMonth(), year: today.getFullYear()});
        } else {
            this.formGroup.setValue(value);
        }
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (!isNotNullOrUndefined(this.formGroup.validator)) {
            this.formGroup.setValidators(control.validator);
        }
        if (this.formGroup.valid) {
            return null;
        } else {
            return this.formGroup.errors;
        }
    }

    get month(): number {
        return this.formGroup.get('month').value;
    }

    set month(month: number) {
        this.formGroup.get('month').setValue(month);
    }

    get year(): number {
        return this.formGroup.get('year').value;
    }

    set year(year: number) {
        this.formGroup.get('year').setValue(year);
    }

    addMounth() {
        const currentMonth = this.month;
        this.month = (currentMonth + 1) % 12;
    }

    removeMounth() {
        const currentMonth = this.month;
        this.month = (currentMonth - 1) > 0 ? currentMonth - 1 : 12;
    }

    addYear() {
        const currentYear = this.year;
        this.year = (currentYear + 1);
    }

    removeYear() {
        const currentYear = this.year;
        this.year = currentYear - 1;
    }
}
