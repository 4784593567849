<mat-dialog-content style="padding: 0;height: calc(100vh - 94px)">
    <form [formGroup]="form" (ngSubmit)="$event.stopPropagation()">
        <mat-card>
            <div class="flex-center-Between flex-flow-row w100" formGroupName="filter">
                <mat-card class="mat-select-size">
                    <mat-card-content style="margin-bottom: -10px">
                        <div class="flex-around-center w100">
                            <mat-form-field appearance="fill" style="margin:0 5px">
                                <mat-label>{{ 'batchLightEditor.searchInColumn' | translate }}</mat-label>
                                <mat-select formControlName="searchInColumn">
                                    <mat-option *ngFor="let col of visibleColumns$|async"
                                                [value]="col">
                                        {{(col| traductionNotTraduction)|async }}
                                    </mat-option>
                                </mat-select>
                                <button mat-icon-button matPrefix
                                        (click)="$event.stopPropagation();unsetFilterKey('searchInColumn')">
                                    <mat-icon>clear</mat-icon>
                                </button>
                            </mat-form-field>
                            <mat-form-field appearance="fill" style="margin:0 5px">
                                <mat-label>{{ 'batchLightEditor.keySearchInColumn' | translate }}</mat-label>
                                <input type="search" placeholder="" matInput
                                       formControlName="keySearchInColumn">
                                <button mat-icon-button matPrefix
                                        (click)="$event.stopPropagation();unsetFilterKey('keySearchInColumn')">
                                    <mat-icon>clear</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>
                    </mat-card-content>
                    <mat-card-footer style="padding: 0 20px 10px 20px">
                        {{'batchLightEditor.searchInTable'|translate}}
                        <button mat-mini-fab color="primary" aria-label="search" (click)="updateValueSearch()">
                            <mat-icon>search</mat-icon>
                        </button>
                    </mat-card-footer>
                </mat-card>
                <mat-card class="search-column-size">
                    <mat-card-content style="margin-bottom: -10px">
                        <div>
                            <app-auto-complete-form
                                    formControlName="showColumnHeader"
                                    registerOnChangeKeyValue="valueForm"
                                    title="{{'batchLightEditor.searchColumn'|translate}}"
                                    [items]="visibleColumnsPossibleValues$|async"
                                    appearance="fill"
                                    [hiddenClearButton]="true"
                            >
                                <div prefix>
                                    <button mat-icon-button
                                            [disabled]="disableSearchColumn"
                                            color="primary"
                                            (click)="resetFilterForm('showColumnHeader',false)">
                                        <mat-icon>clear</mat-icon>
                                    </button>
                                </div>
                                <div suffix>
                                    <button mat-icon-button [disabled]="disableSearchColumn"
                                            color="primary"
                                            (click)="findInBrowser()">
                                        <mat-icon>forward</mat-icon>
                                    </button>
                                </div>
                            </app-auto-complete-form>
                        </div>
                    </mat-card-content>
                    <mat-card-footer style="padding: 0 20px 10px 20px">
                        {{'batchLightEditor.showColumn'|translate}}
                        <button mat-icon-button [disabled]="disableSearchColumn"
                                color="primary"
                                (click)="findInBrowser()">
                            <mat-icon>forward</mat-icon>
                        </button>
                    </mat-card-footer>
                </mat-card>
            </div>
            <div class="flex-center-Between w100 flex-flow-row">
                <mat-paginator [pageSizeOptions]="[20, 50, 100, 500]" (click)="$event.stopPropagation()">
                </mat-paginator>
            </div>
            <mat-card-content formGroupName="massiveValuesSet">
                <div>
                    <app-scroll-with-button color="accent" position="overlay">
                        <mat-selection-list formControlName="selectsElementId" #ClickSelectsElement>
                            <table mat-table matSort [dataSource]="dataSource" class="huna-table selectable-rows">
                                <ng-container *ngIf="elementUpdatedValues$|async as elementUpdatedValues">
                                    <ng-container matColumnDef="objectId" sticky="true">
                                        <th mat-header-cell *matHeaderCellDef>
                                                    <span class="color-select text-title">
                                                    {{(numberSelectsElementId$|async)}}
                                                    </span>
                                        </th>
                                        <td mat-cell
                                            *matCellDef="let element">
                                            <mat-list-option [value]="element.objectId"
                                                             [disableRipple]="true"
                                                             (click)="$event.stopPropagation()">
                                            </mat-list-option>
                                        </td>
                                    </ng-container>
                                    <ng-container *ngFor="let col of (visibleColumns$|async)">
                                        <ng-container [matColumnDef]="col.formControlName">
                                            <th mat-header-cell mat-sort-header *matHeaderCellDef #columnsTable
                                                class="w100" style="padding: 5px">
                                                <div class="flex-center-width100 flex-flow-column">
                                                    <div class="color-text" style="padding: 5px">
                                                        <ng-container>
                                                            {{(col|traductionNotTraduction)|async}}
                                                        </ng-container>
                                                    </div>
                                                    <div *ngIf="col.editable" class="flex-flow-row flex-center"
                                                         style="min-width: 220px">
                                                        <button mat-stroked-button color="primary"
                                                                (click)="$event.stopPropagation();editMassiveField(col)">
                                                            <mat-icon style="margin: 0 5px">edit</mat-icon>
                                                        </button>
                                                    </div>
                                                </div>
                                            </th>
                                            <td mat-cell
                                                [ngClass]="{'background-primary-transparent':element|convertValueByPredicateFunction:predicateIsUpdated:col:elementUpdatedValues}"
                                                *matCellDef="let element">
                                                <ng-container
                                                        *ngIf="col.typeField!==typeFormValue.DATE;else DateType">
                                                    {{element|convertValueByPredicateFunction:predicateGetValue:col:elementUpdatedValues:translateInstant}}
                                                </ng-container>
                                                <ng-template #DateType>
                                                    {{((element|convertValueByPredicateFunction:predicateGetValue:col:elementUpdatedValues:translateInstant)|customDate:'medium')|async}}
                                                </ng-template>
                                            </td>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="visibleColumnsKeys$|async as visibleColumnsKeys">
                                        <tr mat-header-row *matHeaderRowDef="visibleColumnsKeys"></tr>
                                        <tr mat-row *matRowDef="let row; columns: visibleColumnsKeys;"
                                            (click)="clickRow(row)"></tr>
                                    </ng-container>
                                </ng-container>
                            </table>
                        </mat-selection-list>
                    </app-scroll-with-button>
                </div>
            </mat-card-content>
        </mat-card>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="footer-actions">
        <button
                mat-flat-button
                color="accent"
                (click)="clickClose()">
            <mat-icon>close</mat-icon>
        </button>
        <button mat-flat-button style="margin: 0"
                color="primary"
                [matMenuTriggerFor]="menu">
            <mat-icon>visibility</mat-icon>
        </button>
        <app-button-spinner [loading]="loading.active" [progress]="loading.progress">
            <button mat-flat-button color="primary" type="button" matTooltip="{{ 'save' | translate}}"
                    (click)="save()">
                <mat-icon>save</mat-icon>
            </button>
        </app-button-spinner>
    </div>
</mat-dialog-actions>

<mat-menu #menu="matMenu">
    <div style="padding: 0 15px" (click)="$event.stopPropagation();">
        <form [formGroup]="form" (keydown)="$event.stopPropagation()" class="w100">
            <ng-container formGroupName="showColumnsMenu">
                <mat-form-field appearance="fill" class="w100">
                    <mat-label>{{'search_generic'|translate}}</mat-label>
                    <input matInput formControlName="searchKey">
                </mat-form-field>
            </ng-container>
        </form>
    </div>
    <div class="flex-center-Between" style="margin: 0 15px;width: calc(100% - 30px)">
        <button mat-flat-button color="accent" (click)="$event.stopPropagation();setHiddenAllVisibleColumns()">
            <mat-icon>visibility_off</mat-icon>
        </button>
        <button mat-flat-button color="accent" (click)="$event.stopPropagation();setFavoritesAllVisibleColumns()">
            <mat-icon>star</mat-icon>
        </button>
        <button mat-flat-button color="primary" (click)="$event.stopPropagation();setShowAllVisibleColumns()">
            <mat-icon>visibility</mat-icon>
        </button>
    </div>
    <ng-container *ngFor="let col of allColumnsHtml">


        <div mat-menu-item
             *ngIf="col|convertValueByPredicateFunction:filterShowKeyPredicate:(searchKeyShowColumn$|async):translateInstant">
            <div class="flex-center-Between" matRipple (click)="$event.stopPropagation();setVisibleColumns(col)">
                <ng-container
                        *ngIf="col|convertValueByPredicateFunction:predicateIsSet:(visibleColumns$|async) as icon">
                <span class="text-title" [ngClass]="icon.cssClass">
                    {{(col|traductionNotTraduction)|async}}
                </span>
                    <mat-icon [color]="icon.colorIcon">
                        {{icon.iconName}}
                    </mat-icon>
                </ng-container>
            </div>
        </div>
    </ng-container>
</mat-menu>
<ng-template>

</ng-template>