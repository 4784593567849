import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {SchedaManutenzioneParse} from "../../../models/SchedaManutenzione.Parse";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {arrayIsSet, getItemInArrayByKeyValue, isNotNullOrUndefined, stringIsSet} from "../../../models/Models";
import {DialogPopUpService} from "../../../providers/services/dialog-pop-up.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-list-box-schede-manutenzione',
    templateUrl: './list-box-schede-manutenzione.component.html',
    styleUrls: ['./list-box-schede-manutenzione.component.scss']
})
export class ListBoxSchedeManutenzioneComponent implements OnInit {

    @Input() schedeManutenzione: SchedaManutenzioneParse [];
    @Input() selectedSchedeManutenzione: SchedaManutenzioneParse [] = [];
    @Input() disabledSchedeManutenzione: SchedaManutenzioneParse [] = [];
    @Input() visualizedRemoveButton: boolean = false;
    @Input() dimensionCardMaintenance: 'XL' | 'M' = 'XL';
    @Input() dimensionContainerCardMaintenance: 'L' | 'M' = 'M';
    @Input() selectionMode = false;
    @Output() clickSchedaManutenzione = new EventEmitter<{ calendari, schedaCliccata: SchedaManutenzioneParse }>();
    @Output() clickRemoveSchedaManutenzione = new EventEmitter();
    public formFilter: UntypedFormGroup;
    public formDate: UntypedFormGroup;
    public itemsAutoCompleteFilter = []


    constructor(
        private fb: UntypedFormBuilder,
        private myDialog: DialogPopUpService,
        private translateService: TranslateService
    ) {
        this.formFilter = this.fb.group({progetti: null})
    }


    ngOnInit(): void {
        const itemsAutoComplete = []
        const objFormSchedeDate = {};
        this.schedeManutenzione.forEach(scheda => {
            if (scheda.isOnlyOnce) {
                objFormSchedeDate[scheda.objectId] = scheda.dataDiScadenza
            }
            if (stringIsSet(scheda.name)) {
                const isIncludes = getItemInArrayByKeyValue(itemsAutoComplete, scheda.objectId, 'valueForm')
                if (!isIncludes) {
                    const dataForm = {
                        valueForm: scheda.objectId,
                        html: scheda.name
                    }
                    itemsAutoComplete.push(dataForm)
                }
            }
        })
        this.itemsAutoCompleteFilter = itemsAutoComplete;
        this.formDate = this.fb.group(objFormSchedeDate)

    }

    predicateFunction(schedaManutenzione: SchedaManutenzioneParse, value) {
        if (stringIsSet(value)) {
            const getLowerCase = (value) => (stringIsSet(value)) ? value.toLowerCase() : '';
            const lowerName = getLowerCase(schedaManutenzione.name);
            return (lowerName.trim().length > 0) ? lowerName.includes(getLowerCase(value)) : false;
        } else {
            return true
        }
    }


    emitSchedaManutenzione(schedaManutenzione: SchedaManutenzioneParse) {
        if (!this.isDisabledSchedaManutenzione(schedaManutenzione)) {
            this.clickSchedaManutenzione.emit({calendari: this.formDate.value, schedaCliccata: schedaManutenzione});
        }
    }

    isSelectedSchedaManutenzione(schedaManutenzione: SchedaManutenzioneParse) {
        return isNotNullOrUndefined(getItemInArrayByKeyValue(this.selectedSchedeManutenzione, schedaManutenzione.objectId, 'objectId'))
    }

    isDisabledSchedaManutenzione(schedaManutenzione: SchedaManutenzioneParse) {
        return arrayIsSet(this.disabledSchedeManutenzione) && isNotNullOrUndefined(getItemInArrayByKeyValue(this.disabledSchedeManutenzione, schedaManutenzione.objectId, 'objectId')) || (schedaManutenzione.isOnlyOnce && !this.getCaelndario(schedaManutenzione))
    }

    clickRemove(schedaManutenzione: SchedaManutenzioneParse) {
        this.myDialog.openDialogForDelete(this.translateService.instant('scheduleMaintenance.removeSchedule')).subscribe(result => {
            if (result) {
                this.clickRemoveSchedaManutenzione.emit(schedaManutenzione)
            }
        })
    }

    getCaelndario(schedaDiManutnezione: SchedaManutenzioneParse) {
        if (this.formDate.get(schedaDiManutnezione.objectId)) {
            return this.formDate.get(schedaDiManutnezione.objectId).value
        }
    }

    dataDiScadenza(event, schedaDiManutnezione: SchedaManutenzioneParse) {
        if (this.formDate.get(schedaDiManutnezione.objectId)) {
            this.formDate.get(schedaDiManutnezione.objectId).setValue(event)
        }

        if (!this.isSelectedSchedaManutenzione(schedaDiManutnezione)) {
            this.emitSchedaManutenzione(schedaDiManutnezione);
        }
    }

}
