import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {CircuitiParse} from "../../../models/Circuiti.Parse";
import {RemoteControlService} from "../../../providers/services/remote-control.service";
import {Observable} from "rxjs";
import {map, startWith} from "rxjs/operators";
import {UntypedFormGroup} from "@angular/forms";

@Component({
    selector: 'app-create-new-iot-devices',
    templateUrl: './create-new-iot-devices.component.html',
    styleUrls: ['./create-new-iot-devices.component.scss']
})
export class CreateNewIotDevicesComponent implements OnInit {


    constructor(public dialogRef: MatDialogRef<CreateNewIotDevicesComponent>,
                @Inject(MAT_DIALOG_DATA) public data: { circuiti: CircuitiParse [] },
                private remoteControlService: RemoteControlService) {
        this.newIotDevicesForm = this.remoteControlService.formGroupNewIotDevices;
    }

    newIotDevicesForm: UntypedFormGroup;
    filteredOptions: Observable<CircuitiParse[]>;

    ngOnInit() {
        // inizializziamo l observable sui filtri
        this.filteredOptions = this.newIotDevicesForm.valueChanges
            .pipe(
                startWith(''),
                map(value => (value as any).circuiti ? this._filter((value as any).circuiti) : this.data.circuiti.slice())
            );
    }


    displayFnNumeroQuadro(id): string {
        return (this.data.circuiti.filter((option) => option.id == id)[0]) ? this.data.circuiti.filter((option) => option.id == id)[0].numeroQuadro : '';
    }

    selectCircuit(id) {
        this.newIotDevicesForm.get('circuiti').setValue(id);
    }

    private _filter(value: string): CircuitiParse[] {
        const filterValue = value.toLowerCase();
        return this.data.circuiti.filter(circuito => circuito.numeroQuadro.toLowerCase().includes(filterValue));
    }

}
