import * as Parse from 'parse';

export class ArredoUrbanoParse extends Parse.Object {

    public static CLASS_NAME = 'ArredoUrbano';
    private _icon;

    constructor() {
        super(ArredoUrbanoParse.CLASS_NAME);
    }


    public get icon(): any {
        return this._icon;
    }

    public set icon(value) {
        this._icon = value;
    }

    public set objectId(value) {
        this.id = value;
    }

    public get objectId(): string {
        return this.id;
    }

    public set ACL(value) {
        super.set('ACL', value);
    }

    public get ACL() {
        return super.get('ACL');
    }

    public set createdAt(value) {
        super.set('createdAt', value);
    }

    public get createdAt(): Date {
        return super.get('createdAt');
    }

    public set updatedAt(value) {
        super.set('updatedAt', value);
    }

    public get updatedAt(): Date {
        return super.get('updatedAt');
    }

    public set caricoEsogeno(value) {
        super.set('caricoEsogeno', value);
    }

    public get caricoEsogeno(): Date {
        return super.get('caricoEsogeno');
    }

    public set detail(value) {
        super.set('detail', value);
    }

    public get detail(): any {
        return super.get('detail');
    }


    public set progetto(value) {
        super.set('progetto', value);
    }

    public get progetto() {
        return super.get('progetto');
    }


    public set createdBy(value) {
        super.set('createdBy', value);
    }

    public get createdBy() {
        return super.get('createdBy');
    }

    public set lastUpdateBy(value) {
        super.set('lastUpdateBy', value);
    }

    public get lastUpdateBy(): Parse.User {
        return super.get('lastUpdateBy');
    }

    public set tipologia(value) {
        super.set('tipologia', value);
    }

    public get tipologia(): string {
        return super.get('tipologia');
    }

    public set location(value) {
        super.set('location', value);
    }

    public get location(): Parse.GeoPoint {
        return super.get('location');
    }

    public set note(value) {
        super.set('note', value);
    }

    public get note(): string {
        return super.get('note');
    }

    public set files(value) {
        super.set('files', value);
    }

    public get files(): Parse.Relation {
        const relation = this.relation('files')
        return relation;
    }

    public set foto(value) {
        super.set('foto', value);
    }

    public get foto(): Parse.File {
        return super.get('foto');
    }

    public set schedeManutenzione(value) {
        super.set('schedeManutenzione', value);
    }

    public get schedeManutenzione(): any[] {
        return super.get('schedeManutenzione');
    }

    public get query() {
        let query = new Parse.Query(ArredoUrbanoParse);
        return query;
    }
}

Parse.Object.registerSubclass(ArredoUrbanoParse.CLASS_NAME, ArredoUrbanoParse);
