<div class="container">
    <form [formGroup]="formExport">
        <mat-form-field appearance="fill">
            <mat-label>Enter your email</mat-label>
            <input matInput placeholder="pat@example.com" formControlName="email" required>
            <mat-error *ngIf="this.formExport.get('email').invalid">
                <ng-container *ngIf="this.formExport.get('email').hasError('required');else emailNonValida">
                    {{ 'mandatory_field_message' | translate}}
                </ng-container>
                <ng-template #emailNonValida>
                    {{ 'emailNonValida' | translate}}
                </ng-template>
            </mat-error>
        </mat-form-field>
        <div class="spacer center-textBeforSlide">
            <p>Circuiti</p>
            <div class="center-Slide">
                <div class="spacer-v">
                    <mat-slide-toggle formControlName="fotoCircuiti">
                        {{ 'photo' | translate}}*
                    </mat-slide-toggle>
                </div>
                <div class="spacer-v">
                    <mat-slide-toggle formControlName="fileCircuiti">
                        {{ 'file' | translate}}
                    </mat-slide-toggle>
                </div>
            </div>
        </div>
        <div class="spacer center-textBeforSlide">
            <p>Punti luce</p>
            <div class="center-Slide">
                <div class="spacer-v">
                    <mat-slide-toggle formControlName="fotoPuntiLuce">
                        {{ 'photo' | translate}}*
                    </mat-slide-toggle>
                </div>
                <div class="spacer-v">
                    <mat-slide-toggle formControlName="filePuntiLuce" id="newsletter" name="newsletter">
                        {{ 'file' | translate}}
                    </mat-slide-toggle>
                </div>
            </div>
        </div>
        <div class="spacer">

        </div>
        <div class="spacer ">
            <br>
            <button [disabled]="this.formExport.get('email').invalid||!(this.formExport.get('fotoCircuiti').value ||this.formExport.get('fileCircuiti').value ||this.formExport.get('fotoPuntiLuce').value ||this.formExport.get('filePuntiLuce').value )"
                    [mat-dialog-close]="formExport.value"
                    mat-flat-button class="w100" color="accent"
                    type="submit">{{ 'exportDate' | translate}}</button>
        </div>
        <div class="spacer ">
            <div style="color: #7b7b7b;font-size: small">
                {{ 'fileCreationReady' | translate}}
            </div>
            <a href="/esporta-file"
                    mat-flat-button class="w100" color="primary"
            >{{ 'fileReady' | translate}}</a>
        </div>
    </form>
    <div style="color: #7b7b7b;font-size: small">
        {{ 'notFunctionImage' | translate}}
    </div>
    <div class="spacer" style="margin-top: 20px">
        <h5>
            {{ 'exportText' | translate}}</h5>
    </div>
</div>