import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {DomSanitizer} from "@angular/platform-browser";

@Component({
    selector: 'app-visualized-image',
    templateUrl: './visualized-image.component.html',
    styleUrls: ['./visualized-image.component.scss']
})
export class VisualizedImageComponent implements OnInit {
    pzProperties = {
        zoomControlScale: 3,
        minScale: 2,
        limitPan: true,
        limitZoom: 20,
        wheelZoomFactor: 1
    };

    editAble = false;
    newImages = undefined
    newImagesUrl = undefined

    constructor(public dialogRef: MatDialogRef<VisualizedImageComponent>,
                @Inject(MAT_DIALOG_DATA)
                public data: {
                    src: string,
                    type: string,
                    visualizeDestroyButton: boolean,
                    visualizeEditButton: boolean
                },
                private sanitizer: DomSanitizer
    ) {
    }

    ngOnInit(): void {
    }

    get visualizeDestroyButton(): boolean {
        return !!this.data.visualizeDestroyButton
    }

    get visualizeEditButton(): boolean {
        return !!this.data.visualizeEditButton
    }

    get heigth(): string {
        return (window.innerHeight - 84) + 'px';
    }

    clickEdit() {
        this.editAble = !this.editAble;
    }

    onLoadImage(files: File[]) {
        this.newImages = files
        this.newImagesUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(files[0]));
    }

    updateImage() {
        this.newImagesUrl = undefined;
        this.dialogRef.close({edit: {newImages: this.newImages}})
    }

}
