import * as Parse from 'parse';
import {formFiledMaintenance} from "../providers/services/maintenance.service";
import {valuePeriodOne} from "./Models";

export class SchedaManutenzioneParse extends Parse.Object {

    public static CLASS_NAME = 'SchedaManutenzione';

    constructor() {
        super(SchedaManutenzioneParse.CLASS_NAME);
    }


    public set objectId(value) {
        this.id = value;
    }

    public get objectId(): string {
        return this.id;
    }

    public set ACL(value) {
        super.set('ACL', value);
    }

    public get ACL() {
        return super.get('ACL');
    }

    public set createdAt(value) {
        super.set('createdAt', value);
    }

    public get createdAt(): Date {
        return super.get('createdAt');
    }

    public set updatedAt(value) {
        super.set('updatedAt', value);
    }

    public get updatedAt(): Date {
        return super.get('updatedAt');
    }

    public set dataDiScadenza(value) {
        super.set('dataDiScadenza', value);
    }

    public get dataDiScadenza(): Date {
        return super.get('dataDiScadenza');
    }

    public set periodo(value) {
        super.set('periodo', value);
    }

    public get periodo(): number {
        return super.get('periodo');
    }

    public set progetti(value) {
        super.set('progetti', value);
    }

    public get progetti(): any[] {
        return super.get('progetti');
    }

    public set form(value) {
        super.set('form', value);
    }

    public get form(): formFiledMaintenance[] {
        return super.get('form');
    }

    public set name(value) {
        super.set('name', value);
    }

    public get name(): string {
        return super.get('name');
    }


    public set note(value) {
        super.set('note', value);
    }

    public get note(): string {
        return super.get('note');
    }

    public set type(value) {
        super.set('type', value);
    }

    public get type(): string {
        return super.get('type');
    }

    public get query() {
        let query = new Parse.Query(SchedaManutenzioneParse);
        return query;
    }

    public allProperty() {
        return Object.keys(SchedaManutenzioneParse.prototype).filter(key => key != "constructor")
    }

    public allPropertyOnParse() {
        const notInParse = ['constructor', 'query'];
        return this.allProperty().filter(key => !notInParse.includes(key))
    }

    get isOnlyOnce() {
        return this.periodo && this.periodo == valuePeriodOne;
    }

}

Parse.Object.registerSubclass(SchedaManutenzioneParse.CLASS_NAME, SchedaManutenzioneParse);
