import {Injectable} from '@angular/core';
import {ordinamentoEcampiTraduzioni} from "../../models/ordinamentoEcampiTraduzioni";
import {PuntiLuceParse} from "../../models/PuntiLuce.Parse";
import {TransformForTranslatePipe} from "../pipes/transform-for-translate.pipe";
import {className, isNotNullOrUndefined, stringIsSet} from "../../models/Models";
import {LabelAndListMarkerTextService} from "./label-and-list-marker-text.service";
import {LocalSotrageService} from "./local-sotrage.service";

@Injectable({
    providedIn: 'root'
})
export class PuntiLuceLabelService {

    private _campoLabel: string;
    private lightPoints: PuntiLuceParse[] = [];
    public numeroElementiPerValore;

    constructor(private labelMarkerText: LabelAndListMarkerTextService,
                private localStorage: LocalSotrageService) {
        if (stringIsSet(localStorage.labelLabelsLightPoints) && !stringIsSet(this.campoLabel)) {
            this.campoLabel = localStorage.labelLabelsLightPoints;
        }
    }

    public get campoLabel() {
        return this._campoLabel
    }

    public set campoLabel(value) {
        this._campoLabel = value;
        this.localStorage.labelLabelsLightPoints = value;
    }


    public get tipoDato() {
        if (ordinamentoEcampiTraduzioni.PuntiLuce.hasOwnProperty(this.campoLabel)) {
            return ordinamentoEcampiTraduzioni.PuntiLuce[this.campoLabel].type.toLowerCase();
        } else if (this.campoLabel == 'lampadaFunzionante') {
            return 'bool';
        } else {
            const key = this.keyOfVarianteKey;
            if (isNotNullOrUndefined(key)) {
                const tipoDato = ordinamentoEcampiTraduzioni.PuntiLuce[key].type.toLowerCase();
                return tipoDato
            }
        }
    };

    get keyOfVarianteKey() {
        const keys = Object.keys(ordinamentoEcampiTraduzioni.PuntiLuce);
        const index = keys.findIndex(
            key => {
                return ordinamentoEcampiTraduzioni.PuntiLuce[key].hasOwnProperty('varianteKey') && ordinamentoEcampiTraduzioni.PuntiLuce[key].varianteKey === this.campoLabel
            }
        );
        return (index >= 0) ? keys[index] : undefined
    }


    private get isPointer() {
        return this.tipoDato?.includes('pointer') ?? false;
    }


    get possibleValues(): string[] {
        if (this.isPointer && ordinamentoEcampiTraduzioni.PuntiLuce.hasOwnProperty(this.campoLabel)) {
            return (ordinamentoEcampiTraduzioni.PuntiLuce[this.campoLabel] &&
                ordinamentoEcampiTraduzioni.PuntiLuce[this.campoLabel].hasOwnProperty('possibleValues') &&
                typeof ordinamentoEcampiTraduzioni.PuntiLuce[this.campoLabel].possibleValues === 'string') ?
                ordinamentoEcampiTraduzioni.PuntiLuce[this.campoLabel].possibleValues.split('.') : [];
        } else if (ordinamentoEcampiTraduzioni.PuntiLuce.hasOwnProperty(this.campoLabel)) {
            return (
                ordinamentoEcampiTraduzioni.PuntiLuce[this.campoLabel] &&
                ordinamentoEcampiTraduzioni.PuntiLuce[this.campoLabel].hasOwnProperty('possibleValues')) ?
                ordinamentoEcampiTraduzioni.PuntiLuce[this.campoLabel].possibleValues : [];
        } else {
            const key = this.keyOfVarianteKey;
            if (this.isPointer) {
                return (ordinamentoEcampiTraduzioni.PuntiLuce[key] &&
                    ordinamentoEcampiTraduzioni.PuntiLuce[key].hasOwnProperty('possibleValues') &&
                    typeof ordinamentoEcampiTraduzioni.PuntiLuce[key].possibleValues === 'string') ?
                    ordinamentoEcampiTraduzioni.PuntiLuce[key].possibleValues.split('.') : [];
            } else {
                return (
                    isNotNullOrUndefined(key) &&
                    ordinamentoEcampiTraduzioni.PuntiLuce[key] &&
                    ordinamentoEcampiTraduzioni.PuntiLuce[key].hasOwnProperty('possibleValues')) ?
                    ordinamentoEcampiTraduzioni.PuntiLuce[key].possibleValues : [];
            }

        }
    }

    private getTextLabel(puntoLuce: PuntiLuceParse) {
        return this.labelMarkerText.getTextLabel(className.puntiLuce, puntoLuce, this.tipoDato, this.campoLabel, this.possibleValues)
    }

    private getNumberElementLoad(puntoLuce): { text: string, key: string } {
        let text: string = this.getTextLabel(puntoLuce);
        const filterPipe = new TransformForTranslatePipe();
        const chiaveSenzaSpazi = filterPipe.transform(text) as string;
        text = (text.length > 13) ? text.substring(0, 13) + '...' : text;
        return {text: text, key: chiaveSenzaSpazi};
    }

    public calcNumeroElementiPerValore(campoLabel, puntiLuce) {
        this.campoLabel = campoLabel;
        this.lightPoints = puntiLuce;
        this.createValueHtml();
    }


    public createValueHtml(puntiLuce: PuntiLuceParse [] = undefined) {
        if (isNotNullOrUndefined(puntiLuce)) {
            this.lightPoints = puntiLuce;
        }
        let numeroElementiPerLabel;
        if (isNotNullOrUndefined(this.campoLabel)) {
            numeroElementiPerLabel = {};
            if (this.lightPoints.length > 0 && isNotNullOrUndefined(this.campoLabel)) {
                this.lightPoints.forEach((puntoLuce) => {
                    const textKey = this.getNumberElementLoad(puntoLuce);
                    if (isNotNullOrUndefined(numeroElementiPerLabel[textKey.key])) {
                        numeroElementiPerLabel[textKey.key] += 1;
                    } else {
                        numeroElementiPerLabel[textKey.key] = 1;
                    }
                });
            }
        }
        this.numeroElementiPerValore = numeroElementiPerLabel
    }

    public getLabelMarker(puntoLuce: PuntiLuceParse, color: string='white'): {
        color: string,
        fontWeight: string,
        fontSize: string,
        text,
        className: 'text-with-border-border-black'
    } {
        if (!isNotNullOrUndefined(this.campoLabel)) {
            return null;
        } else {
            const text = this.getNumberElementLoad(puntoLuce).text;
            return this.getOnlyLabel(text, color);
        }
    }

    getOnlyLabel(text: string, color: string): {
        color: string,
        fontWeight: string,
        fontSize: string,
        text,
        className: 'text-with-border-border-black'
    } {
        if (stringIsSet(text)) {
            return {
                color: color,
                fontWeight: 'bold',
                fontSize: '12px',
                text: text,
                className: 'text-with-border-border-black'
            };
        } else {
            return null;
        }
    }


    public puntiLuceContainedNewLabel(puntiLuce: PuntiLuceParse[]) {
        if (!isNotNullOrUndefined(this.campoLabel) || !(Array.isArray(puntiLuce) && puntiLuce.length >= 0)) {
            return false
        } else {
            const scalaColore = this.numeroElementiPerValore;
            const newValueForScaleColor = puntiLuce.findIndex(
                puntoLuce => {
                    const textKey = this.getNumberElementLoad(puntoLuce);
                    const indexColor = Object.keys(scalaColore).findIndex(key => key == textKey.key)
                    return indexColor < 0;
                }
            );
            return newValueForScaleColor >= 0
        }
    }

}
