import {Component, Input, OnInit} from '@angular/core';
import {AlertService} from "../../providers/services/alert.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-box-errors',
    templateUrl: './box-errors.component.html',
    styleUrls: ['./box-errors.component.scss']
})
export class BoxErrorsComponent implements OnInit {
    @Input() errors: { title: string, value: string }[];
    @Input() subTitle: string;
    @Input() title: string = 'error';

    constructor(private alertService: AlertService,
                private translateService: TranslateService) {
    }


    ngOnInit(): void {
    }

    copied() {
        this.alertService.success(this.translateService.instant('copy'))
    }

}
