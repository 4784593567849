import {Component, Input, OnInit} from '@angular/core';
import {CommentiSegnalazioniParse} from "../../../models/CommentiSegnalazioni.Parse";
import {DialogPopUpInfoService} from "../../../providers/services/dialog-pop-up-info.service";
import {arrayIsSet, isNotNullOrUndefined, stringIsSet} from "../../../models/Models";
import {SchedaManutenzioneCompilataParse} from "../../../models/SchedaManutenzioneCompilata.Parse";
import {UserService} from "../../../providers/services/user.service";
import {ReportStates} from "../../../../config/static-data";

@Component({
    selector: 'app-list-activity',
    templateUrl: './list-activity.component.html',
    styleUrls: ['./list-activity.component.scss']
})
export class ListActivityComponent implements OnInit {
    @Input() activities: CommentiSegnalazioniParse[]
    user;
    private states = ReportStates;

    constructor(private popUpInfoService: DialogPopUpInfoService,
                private userService: UserService) {
        this.user = this.userService.currentUser.toJSON()
    }

    ngOnInit(): void {
    }

    commentOfAuthor(nomeAutore) {
        return (this.user.nome + ' ' + this.user.cognome) == nomeAutore
    }

    onOpenImage(activity) {
        this.popUpInfoService.openVisualizedImage(activity.foto.url(), 'image')
    }

    getSplitNameObject(obj: any, key: string) {
        if (isNotNullOrUndefined(obj) && isNotNullOrUndefined(obj.allegato) && isNotNullOrUndefined(obj.allegato[key]) && stringIsSet(obj.allegato[key])) {
            return obj.allegato[key].split('_')[1];
        }
    }

    showAlert(schedaManutenzione: SchedaManutenzioneCompilataParse) {
        return schedaManutenzione.rifiutata == true;
    }

    get activityIsSet() {
        return arrayIsSet(this.activities)
    }


    visualizeObjectUser(acticity: CommentiSegnalazioniParse) {
        return isNotNullOrUndefined(acticity.objectUser) && stringIsSet(this.getNameUser(acticity.objectUser)) &&
            (acticity.nuovoStato == this.states.ASSEGNATOA || acticity.nuovoStato == this.states.INCARICO)
    }

    getNameUser(user) {
        return user.attributes.nome + ' ' + user.attributes.cognome;
    }

}
