<svg xmlns="http://www.w3.org/2000/svg" [attr.x]="x" [attr.y]="y" [attr.height]="height" [attr.width]="width" [attr.viewBox]="viewBox"  version="1.1" preserveAspectRatio="none">
  <rect y="0" x="0" height="100" width="100"
    style="opacity:1;fill:#535353;fill-opacity:1;stroke:none;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:normal" />
  <g *ngFor="let d of dividers">
    <rect
      style="opacity:1;fill:#fffffd;fill-opacity:1;stroke:none;stroke-width:0.70411688;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:normal"
      width="20" [attr.height]="lineHeight" x="10" [attr.y]="d" />
    <rect
      style="opacity:1;fill:#fffffd;fill-opacity:1;stroke:none;stroke-width:0.70411688;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:normal"
      width="20" [attr.height]="lineHeight" x="40" [attr.y]="d" />
    <rect
      style="opacity:1;fill:#fffffd;fill-opacity:1;stroke:none;stroke-width:0.70411688;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:normal"
      width="20" [attr.height]="lineHeight" x="70" [attr.y]="d" />
  </g>
  <g>
    <rect
      style="opacity:1;fill:#fffffd;fill-opacity:1;stroke:none;stroke-width:0.70411688;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:normal"
      width="100" [attr.height]="lineHeight" x="0" [attr.y]="lineHeight" />
    <rect
      style="opacity:1;fill:#fffffd;fill-opacity:1;stroke:none;stroke-width:0.70411688;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:normal"
      width="100" [attr.height]="lineHeight" x="0" [attr.y]="100 - (2*lineHeight)" />
  </g>
</svg>
