<div class="example-container mat-elevation-z8">
    <table mat-table [dataSource]="noLocation" class="custom-mat-table">
        <ng-container *ngFor="let key of keys">
            <ng-container [ngSwitch]="key">
                <ng-container *ngSwitchCase="'positionInFile'">
                    <ng-container matColumnDef="positionInFile" sticky>
                        <th mat-header-cell *matHeaderCellDef> {{getPathTraduction(key)|translate}}</th>
                        <td mat-cell *matCellDef="let element"> {{element[key]}} </td>
                    </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'end'">
                    <ng-container matColumnDef="end" stickyEnd>
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <mat-icon>more_vert</mat-icon>
                        </td>
                    </ng-container>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <ng-container [matColumnDef]="key">
                        <th mat-header-cell *matHeaderCellDef
                            style="padding: 5px;border-left: 1px solid rgba(128,128,128,0.37);border-top: 1px solid rgba(128,128,128,0.37);">{{getPathTraduction(key)|translate}}</th>
                        <td mat-cell *matCellDef="let element"
                            style="padding: 5px;text-align: center;border-left: 1px solid rgba(128,128,128,0.37);">
                            <ng-container [ngSwitch]="key">
                                <ng-container *ngSwitchCase="'latitude'">
                                    {{getLat(element)}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'longitude'">
                                    {{getLng(element)}}
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    {{element[key]}}
                                </ng-container>
                            </ng-container>
                        </td>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="keys"></tr>
        <tr mat-row *matRowDef="let row; columns: keys;"></tr>
    </table>
</div>