<div class="container">
    <form [formGroup]="filterForm" *ngIf="filterForm">
        <mat-card>
            <mat-card-title>{{'search_address' | translate}}</mat-card-title>
            <ng-container>
                <mat-form-field appearance="fill" floatLabel="always">
                    <mat-label>{{'research_address' | translate}}</mat-label>
                    <input class="input" placeholder="{{'research_address' | translate}}" matInput type="text"
                           appGetGoogleAddress (changeAddress)="setAddress($event)" [adressType]="adressType"
                    >
                </mat-form-field>
            </ng-container>
            <div class="spacer">
                <button class="w100" (click)="onSearch()" type="button" mat-flat-button
                        color="primary">{{ 'search' | translate}}
                </button>
            </div>
        </mat-card>
        <div class="spacer">&nbsp;</div>
        <mat-card *ngIf="loaded && sidenavIsOpened">
            <mat-card-title>{{'filters' | translate}}</mat-card-title>
            <div class="spacer-plus">
                <label>{{'filters_label' | translate}}</label>
                <br>
                <label class="label-select-all"><a (click)="onChangeMassive('lightPoints', 'selectAll')">
                    {{'select' | translate}}</a>&nbsp;/&nbsp;<a
                        (click)="onChangeMassive('lightPoints', 'deselectAll')">{{'unselect' | translate}}</a>&nbsp;{{'all' | translate}}
                </label>
<!--                <div>-->
<!--                    <mat-radio-group formControlName="activeLightPoints">-->
<!--                        <mat-radio-button [value]="bothActiveDisactiveKey">{{'all'|translate}}</mat-radio-button>-->
<!--                        <mat-radio-button [value]="true">{{'active'|translate}}</mat-radio-button>-->
<!--                        <mat-radio-button [value]="false">{{'unactive'|translate}}</mat-radio-button>-->
<!--                    </mat-radio-group>-->
<!--                </div>-->
                <div class="list-scrool scrollbar-visible">
                    <mat-selection-list
                            formControlName="lightPoints">
                        <div style="text-align: center;">
                            <mat-list-option matTooltip="{{'filterPage.nessunQuadro' | translate}}"
                                             class="list-scrool-item"
                                             [value]="chiaviFiltri.nessunQuadro">
                                <img src="assets/icon/filter/nonquadro.png" style="max-height: 24px;max-width: 24px;">
                            </mat-list-option>
                        </div>
                        <div style="text-align: center;" *ngIf="circuits.length>0">
                            <mat-list-option *ngFor="let circuit of circuits"
                                             class="list-scrool-item"
                                             [value]="circuit.formValues">
                                {{circuit.formHtml}}
                            </mat-list-option>
                        </div>
                    </mat-selection-list>
                </div>
            </div>
            <div class="spacer-plus">
                <label>{{'circuits_label' | translate}}</label>
                <br><label class="label-select-all"><a (click)="onChangeMassive('circuits', 'selectAll')">
                {{'select' | translate}}</a>&nbsp;/&nbsp;
                <a (click)="onChangeMassive('circuits', 'deselectAll')">{{'unselect' | translate}}</a>&nbsp;{{'all' | translate}}
            </label>
                <div class="list-scrool scrollbar-visible">
                    <mat-selection-list
                            formControlName="circuits">
                        <div style="text-align: center;" *ngIf="circuits.length>0">
                            <mat-list-option *ngFor="let circuit of circuits"
                                             class="list-scrool-item"
                                             [value]="circuit.formValues">
                                {{circuit.formHtml}}
                            </mat-list-option>
                        </div>
                    </mat-selection-list>
                </div>
            </div>
            <div class="border-list-scroll" *ngIf="!isUtente&&isSeGruppiPuntiLuce">
                <app-multi-select-form-field
                        formControlName="gruppiPuntiLuce"
                        [possibleValues]="gruppiPuntiLuce|convertValueByPredicateFunction:getGruppiPiuntiLucePossibleValuesPredicateFunction"
                        [labelSearch]="'groupLightPoints_label'|translate">
                </app-multi-select-form-field>
            </div>
            <div class="border-list-scroll" *ngIf="isSetArrediUrbani">
                <app-multi-select-form-field
                        formControlName="arredoUrbano"
                        [possibleValues]="tipologiaArrediurbani|convertValueByPredicateFunction:getArredoUrbanoPossibleValuesPredicateFunction:this.translate"
                        [labelSearch]="'arredoUrbano'|translate"
                >
                </app-multi-select-form-field>
            </div>
            <div class="border-list-scroll" *ngIf="isSetElectricLines">
                <app-multi-select-form-field
                        formControlName="electricLines"
                        [possibleValues]="electricLines|convertValueByPredicateFunction:getElectricLinePredicateFunction"
                        [labelSearch]="'LineaElettrica'|translate"
                >
                </app-multi-select-form-field>
            </div>
            <!--            <div class="spacer-plus">-->
            <!--                <mat-selection-list-->
            <!--                        formControlName="puntiStampa">-->
            <!--                    <mat-list-option [value]="chiaviFiltri.puntiStampa">-->
            <!--                        {{'dashboard.puntiStampa.visualizza' | translate}}-->
            <!--                    </mat-list-option>-->
            <!--                </mat-selection-list>-->
            <!--            </div>-->
            <div class="spacer-plus">
                <mat-selection-list
                        formControlName="confiniAmministrativi">
                    <mat-list-option [value]="chiaviFiltri.confiniAmministrativi">
                        {{'filterPage.confiniAmministrativi' | translate}}
                    </mat-list-option>
                </mat-selection-list>
            </div>
            <div class="spacer-plus">
                <button matBadge="{{numberFilter}}" class="btn-filter" matBadgePosition="after" matBadgeColor="priamary"
                        mat-flat-button color="accent" type="button" (click)="openFiltersDialog()">
                    {{'advanced_filter' | translate}}
                </button>
            </div>
            <div class="spacer">
                <button class="w100" (click)="onFilter()" type="button" mat-flat-button
                        color="primary">{{ 'filter' | translate}}
                </button>
            </div>
            <div class="spacer">
                <button mat-stroked-button class="w100" color="primary" type="reset"
                        (click)="destroyFilter();">
                    {{'delete_filter' | translate}}
                </button>
            </div>
        </mat-card>
        <div class="spacer">&nbsp;</div>
    </form>
</div>