import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA, MatLegacySnackBarRef as MatSnackBarRef} from "@angular/material/legacy-snack-bar";

@Component({
    selector: 'app-for-error',
    templateUrl: './for-error.component.html',
    styleUrls: ['./for-error.component.scss']
})
export class ForErrorComponent implements OnInit {

    constructor(public snackBarRef: MatSnackBarRef<ForErrorComponent>,
                @Inject(MAT_SNACK_BAR_DATA) public data: any) {
        this.controllaAzione();
    }


    public textColor:string;
    public iconTitle:string;

    public controllaAzione() {
        if (this.data.azione == 'error') {
            this.textColor='#dc3d2c';
            this.iconTitle='error.svg';

        } else if (this.data.azione == 'warning') {
            this.textColor='#fbb335';
            this.iconTitle='warning.svg';

        } else {
            this.textColor='#00bc3c';
            this.iconTitle='verified.svg';
        }

    }

    ngOnInit(): void {
    }

}
