import { ErrorStateMatcher } from "@angular/material/core";
import {UntypedFormControl, FormGroupDirective, NgForm} from "@angular/forms";

export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
        const invalidParent = (!!(control && control.parent && control.parent.invalid && control.parent.dirty)) && control.parent.hasError('mismatch');
        return (invalidCtrl || invalidParent);
    }
}
