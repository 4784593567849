import {Injectable} from '@angular/core';
import * as moment from "moment";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
    providedIn: 'root'
})
export class FormatterPipeService {


    constructor(private translateService: TranslateService) {
    }

    transform(value: any, args?: any): any {
        const typeOf = typeof (value);
        // console.log("v", value);
        // console.log("t", typeOf);

        if (typeOf === 'boolean') {
            if (value === true) {
                return this.translateService.instant('yes');
            } else {
                return this.translateService.instant('no');
            }
        }
        return moment(value).locale(navigator.language).format('lll');
    }
}
