<ng-container *ngIf="orientation==='horizontal';else vertical">
    <table [ngStyle]="{minWidth:widthTable,width:widthTable}"
           style="height:20mm;padding:1mm;border-radius: 10px;border-collapse: separate;border-spacing:0;font-size: 0.9em;font-family: sans-serif;">
        <thead style="
        border-top-right-radius: 10px;border-top-left-radius: 10px;
         background-color: #f38232;color: #ffffff;text-align: left;">
        <tr style="padding: 12px 15px;">
            <ng-container *ngFor="let titleName of titlecolumn; first as isFirst; last as isLast">
                <th [ngStyle]="{fontSize:fontSize}"
                    *ngIf="isLast"
                    style="
                    border-bottom: 1px solid #bbb;
                    border-top-right-radius: 10px;padding: 7px 10px;">{{titleName|translate}}</th>
                <th [ngStyle]="{fontSize:fontSize}"
                    *ngIf="isFirst"
                    style="border-top: 1px solid #bbb;
                    border-top-left-radius: 10px;padding: 7px 10px;">{{titleName|translate}}</th>
                <th [ngStyle]="{fontSize:fontSize}"
                    *ngIf="!isFirst && !isLast" style="padding: 7px 10px;">{{titleName|translate}}</th>
            </ng-container>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let data of dataSet;last as isLastX first as isFirstX">
            <tr>
                <td [ngStyle]="getNgTdStyleHorizontalTable(isFirstX,isLastX,isFirstY,isLastY)"
                    *ngFor="let titleName of titlecolumn; last as isLastY;first as isFirstY"
                    style="
                    white-space: nowrap; padding: 7px 10px;">{{data[titleName]}}</td>
            </tr>
        </ng-container>
        <!-- and so on... -->
        </tbody>
    </table>
</ng-container>
<ng-template #vertical>
    <table [ngStyle]="{minWidth:widthTable,width:widthTable}"
           style="height:20mm;padding:1mm;border-radius: 10px;border-collapse: separate;border-spacing:0;font-size: 0.9em;font-family: sans-serif;">
        <thead style=" border-top-right-radius: 10px;border-top-left-radius: 10px;background-color: #f38232;color: #ffffff;text-align: left;">
        <tr style="padding: 7px 10px;">
            <th style=" border-top-right-radius: 10px;border-top-left-radius: 10px;padding: 7px 10px; text-align: center"
                colspan="100%">
                <ng-container *ngIf="verticalTitle">
                    {{verticalTitle|translate}}
                </ng-container>
            </th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let data of dataSet;first as isFirstRow;last as isLastRow">
            <tr>
                <ng-container *ngFor="let titleName of titlecolumn;first as isFirstY;last as isLastY">
                    <ng-container *ngIf="isInnerHtml;else noInnerHtml">
                        <td [ngStyle]="getNgTdStyleVerticalTable(isFirstRow,isLastRow,isFirstY,isLastY)"
                            style="white-space: nowrap; padding: 7px 10px;" [innerHTML]="sanizeHtml(data[titleName])">
                        </td>
                    </ng-container>
                    <ng-template #noInnerHtml>
                        <td
                                [ngStyle]="getNgTdStyleVerticalTable(isFirstRow,isLastRow,isFirstY,isLastY)"
                                style="white-space: nowrap; padding: 7px 10px;">
                            <ng-container *ngIf="!isFirstY;else isFirstTd">
                                {{data[titleName]}}
                            </ng-container>
                            <ng-template #isFirstTd>
                                {{data[titleName]|translate}}
                            </ng-template>
                        </td>
                    </ng-template>
                </ng-container>
            </tr>
        </ng-container>
        <!-- and so on... -->
        </tbody>
    </table>
</ng-template>
