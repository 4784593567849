<form [formGroup]="form" style="max-width: 100%;" class="h100">
    <ng-container [ngSwitch]="type">
        <ng-container *ngSwitchCase="typeForm.INPUT_TEXT">
            <mat-form-field [appearance]="appearance">
                <mat-label>
                    {{titleTraduction|translate}}
                </mat-label>
                <input matInput formControlName="value">
                <button mat-icon-button
                        matSuffix
                        [disabled]="form.get('value').disabled"
                        (click)="$event.stopPropagation();unsetValue()">
                    <mat-icon>clear</mat-icon>
                </button>
            </mat-form-field>
        </ng-container>
        <ng-container *ngSwitchCase="typeForm.INPUT_NUMBER">
            <mat-form-field [appearance]="appearance">
                <mat-label>
                    {{titleTraduction|translate}}
                </mat-label>
                <input matInput formControlName="value" type="number" step="0.1">
                <button mat-icon-button matSuffix
                        [disabled]="form.get('value').disabled"
                        (click)="$event.stopPropagation();unsetValue()">
                    <mat-icon>clear</mat-icon>
                </button>
            </mat-form-field>
        </ng-container>
        <ng-container *ngSwitchCase="typeForm.INPUT_INTEGER">
            <mat-form-field [appearance]="appearance">
                <mat-label>
                    {{titleTraduction|translate}}
                </mat-label>
                <input matInput formControlName="value" type="number" step="1">
                <button mat-icon-button matSuffix
                        [disabled]="form.get('value').disabled"
                        (click)="$event.stopPropagation();unsetValue()">
                    <mat-icon>clear</mat-icon>
                </button>
            </mat-form-field>
        </ng-container>
        <ng-container *ngSwitchCase="typeForm.CHECKBOX">
            <div style="padding:10px;margin: 0 0 30px 0;background: rgb(245,245,245);border-bottom: 1px solid rgb(142 142 142);color:rgba(0,0,0,0.65);"
                 [ngClass]="{'error-button-yesOrNotCheck':isInError('required')}"
            >
                <div style="padding: 5px;" class="flex-center-Between w100">
                    <p style="margin:5px 0  5px 7px;">
                        {{titleTraduction|translate}}
                    </p>
                    <mat-checkbox
                            style="margin-right: 25px"
                            formControlName="value">
                    </mat-checkbox>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="typeForm.RADIO_BUTTON">
            <div class="background-gray flex-center-Between "
                 style="width: calc(100% - 10px);border-bottom: 1px solid #8F8F8F;height: 53px;margin-bottom: 30px;padding-left: 10px;padding-top:4px">
                <div matPrefix *ngIf="titleTraduction" class="color-gray">
                    {{titleTraduction|translate}}
                </div>
                <div style="min-width: 142px">
                    <mat-button-toggle-group class="primary" formControlName="value">
                        <mat-button-toggle *ngFor="let pValue of possibleValues"
                                           [value]='pValue.valueForm'>{{pValue.traduction | translate}}
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                    <button mat-icon-button
                            [disabled]="form.get('value').disabled"
                            (click)="$event.stopPropagation();unsetValue()">
                        <mat-icon>clear</mat-icon>
                    </button>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="typeForm.DATE">
            <app-datepicker
                    [label]="titleTraduction | translate"
                    (dateChange)="changeDate($event)"
                    [minDate]="minDate"
                    [appearance]="appearance"
                    [disabled]="form.get('value').disabled"
                    [value]="form.get('value').value">
            </app-datepicker>
        </ng-container>
        <ng-container *ngSwitchCase="typeForm.MOUNTH">
            <app-select-month-year-field
                    formControlName="value"
                    [label]="titleTraduction|translate"
            >

            </app-select-month-year-field>
        </ng-container>
        <ng-container *ngSwitchCase="typeForm.GEO_POINT">
            <div style="margin-bottom: 34px">
                <div style="margin-left:10px;margin-bottom: 5px"
                     *ngIf="titleTraduction">{{titleTraduction|translate}}</div>
                <mat-form-field [appearance]="appearance"
                                style="min-width: 150px;width: calc(50% - 5px);margin-bottom: -34px;margin-right: 10px">
                    <mat-label>
                        {{'latitude'|translate}}
                    </mat-label>
                    <input [disabled]="form.get('value').disabled"
                           matInput type="number" (change)="changeLatLng('latitude',$event.target.value)"
                           [value]="getValueLatLng('latitude')" step="0.0000000001">
                </mat-form-field>
                <mat-form-field [appearance]="appearance"
                                style="min-width: 150px;width: calc(50% - 5px);margin-bottom: -34px">
                    <mat-label>
                        {{'longitude'|translate}}
                    </mat-label>
                    <input matInput type="number" (change)="changeLatLng('longitude',$event.target.value)"
                           [value]="getValueLatLng('longitude')" step="0.0000000001"
                           [disabled]="form.get('value').disabled"
                    >
                </mat-form-field>
            </div>
        </ng-container>

        <ng-container *ngSwitchCase="typeForm.ELENCO_VINCOLATO">
            <mat-form-field [appearance]="appearance" *ngIf="possibleValuesIsSet">
                <mat-label>
                    {{titleTraduction|translate}}
                </mat-label>
                <mat-select formControlName="value">
                    <mat-option *ngFor="let possibleValue of possibleValues"
                                value="{{possibleValue.valueForm}}"
                    >
                        <ng-container *ngIf="possibleValue.traduction;else NoTraduction">
                            {{possibleValue.traduction  | translate}}
                        </ng-container>
                        <ng-template #NoTraduction>
                            {{possibleValue.html}}
                        </ng-template>
                    </mat-option>
                </mat-select>
                <button mat-icon-button
                        matPrefix
                        [disabled]="form.get('value').disabled"
                        (click)="$event.stopPropagation();unsetValue()">
                    <mat-icon>clear</mat-icon>
                </button>
            </mat-form-field>
        </ng-container>
        <ng-container *ngSwitchCase="typeForm.ELENCO_APERTO">
            <app-auto-complete-form
                    class="width-form-field"
                    classStyle=""
                    [title]="titleTraduction|translate"
                    [items]="possibleValues"
                    [appearance]="appearance"
                    [hiddenClearButton]="false"
                    formControlName="value"
                    [hasError]="isInError('required')"
                    [messageError]="'mandatory_field_message'|translate"
            >
            </app-auto-complete-form>
        </ng-container>
        <ng-container *ngSwitchCase="typeForm.IMAGE">
            <div class="flex-around-center w100 h100">
                <ngx-dropzone *ngIf="form.get('value').enabled;else ShowOnlyLectureImage"
                              [disabled]="form.get('value').disabled"
                              (change)="onLoadImage($event.addedFiles)"
                              class="drop-zone-item-background "
                              accept="image/jpeg,image/jpg,image/png"
                >
                    <ngx-dropzone-label class="w100 h100 flex-center-Between" style="margin :0;max-height: 100%">
                        <div class="flex-around-center flex-flow-column w100"
                             [ngClass]="{'error-button-yesOrNotCheck':isInError('required')}"
                             style="height: calc(100% - 20px)"
                        >
                            <ng-container *ngIf="!imageIsSet;else imageIsSetted">
                                <div class="flex-start-start flex-flow-column" style="width: calc(100% - 51px)">
                                    <span style="text-align: start;font-size: 14px;margin-bottom: 3px;margin-left: 20px">{{titleTraduction|translate}}</span>
                                    <div class="w100"
                                         style="text-align: center">{{ 'fileManager.copyFile' | translate}}</div>
                                </div>
                            </ng-container>
                            <ng-template #imageIsSetted>
                                <div style="padding: 0;margin: 0 10px;min-height: 55px;width: calc(100% - 20px);max-height:100%">
                                    <img *ngIf="visualizeImage"
                                         [src]="form.get('value').value.url"
                                         class="object-fit_cover image-home">
                                </div>
                                <div style="margin-top: -50px">
                                    <button mat-icon-button color="primary"
                                            (click)="$event.stopPropagation();openImageFull()">
                                        <mat-icon>
                                            open_in_full
                                        </mat-icon>
                                    </button>
                                    <button mat-icon-button color="accent"
                                            (click)="$event.stopPropagation();unsetValueFile()">
                                        <mat-icon>cancel</mat-icon>
                                    </button>
                                </div>
                            </ng-template>
                        </div>
                    </ngx-dropzone-label>
                </ngx-dropzone>
                <ng-template #ShowOnlyLectureImage>
                    <div class="w100 h100 flex-center-Between flex-flow-column" *ngIf="imageIsSet;"
                         style="margin :0;max-height: 100%;border:solid 1px  rgb(200,200,200)">
                        <div style="padding: 0;margin: 0 10px;min-height: 55px;width: calc(100% - 20px);max-height:100%">
                            <img *ngIf="visualizeImage"
                                 [src]="form.get('value').value.url"
                                 class="object-fit_cover image-home">
                        </div>
                        <div style="margin-top: -50px">
                            <button mat-icon-button color="primary"
                                    (click)="$event.stopPropagation();openImageFull()">
                                <mat-icon>
                                    open_in_full
                                </mat-icon>
                            </button>
                        </div>
                    </div>
                </ng-template>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="typeForm.FILE">
            <div class="flex-around-center w100 ">
                <ngx-dropzone
                        [disabled]="form.get('value').disabled"
                        (change)="onLoadFile($event.addedFiles)"
                        class="drop-zone-item-background "
                        accept="*"
                >
                    <ngx-dropzone-label class="w100">
                        <div class="flex-around-center flex-flow-column w100 "
                             [ngClass]="{'error-button-yesOrNotCheck':isInError('required')}"
                        >
                            <ng-container *ngIf="!fileIsSet;else fileIsSetted">
                                <div class="flex-start-start flex-flow-column" style="width: calc(100% - 51px)">
                                    <span style="text-align: start;font-size: 14px;margin-bottom: 3px;margin-left: 20px">{{titleTraduction|translate}}</span>
                                    <div class="w100"
                                         style="text-align: center">{{ 'fileManager.copyFile' | translate}}</div>
                                </div>
                            </ng-container>
                            <ng-template #fileIsSetted>
                                <div class="flex-around-center flex-flow-row w100">
                                    <mat-card style="min-width: 50px;min-height:40px;background: rgba(33,128,218,0.44)">
                                        <div style="position: absolute;top: -10px;right: -8px;z-index: 10000">
                                            <button mat-icon-button
                                                    (click)="$event.stopPropagation();unsetValueFile()">
                                                <mat-icon style="color: white">cancel</mat-icon>
                                            </button>
                                        </div>
                                        <h3 style="color: white;">{{form.get('value').value.ext}}</h3>
                                    </mat-card>
                                    <h4>{{form.get('value').value.name}}</h4>
                                </div>
                            </ng-template>
                        </div>
                    </ngx-dropzone-label>
                </ngx-dropzone>
            </div>
        </ng-container>
    </ng-container>
</form>