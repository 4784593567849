import {EventEmitter, Injectable, Output} from '@angular/core';
import {ordinamentoEcampiTraduzioni} from 'src/app/models/ordinamentoEcampiTraduzioni';
import {PuntiLuceParse} from 'src/app/models/PuntiLuce.Parse';
import {CalcolariceEsadecimaleService} from './calcolarice-esadecimale.service';
import {DatePipe} from '@angular/common';
import {
    chiaviScala,
    qualitativeScaleColor, puntiLuceValueScalaColore, isNotNullOrUndefined, arrayIsSet
} from 'src/app/models/Models';
import {TransformForTranslatePipe} from '../pipes/transform-for-translate.pipe';
import {CircuitiParse} from "../../models/Circuiti.Parse";

const interpolate = require('color-interpolate');

@Injectable({
    providedIn: 'root'
})
export class ScaleColorService {

    private elementiScala = {};
    private _elementoScalaAttuale;
    private colorTrue: string = '#00FF00';
    private colorFalse: string = '#FF0000';
    private _elementiVisualizzatiInMappa: PuntiLuceParse[] = [];

    private chiaviScalaColore = chiaviScala;
    private qualitativeScale = qualitativeScaleColor;


    public minEcambiato: boolean;
    public maxEcambiato: boolean;
    public scaleEcambiato: boolean;

    @Output() scalaColoreCircuiti = new EventEmitter();
    @Output() elementoEtichettaCircuiti = new EventEmitter();


    constructor(
        private calcHex: CalcolariceEsadecimaleService,
        private datePipe: DatePipe
    ) {
    }


    set ElementiVisualizzatiInMappa(value: PuntiLuceParse[]) {
        this._elementiVisualizzatiInMappa = value;
    }

    get elementoScalaAttuale() {
        return this._elementoScalaAttuale;
    }

    set elementoScalaAttuale(value) {
        this._elementoScalaAttuale = value;
    }


    get keyAltro() {
        return this.createKey(this.chiaviScalaColore.altro);
    }

    get keyNonSpecificato() {
        return this.createKey(this.chiaviScalaColore.non_specificato);
    }

    get tipoDato(): string {
        const tipoDato = (this.elementoScalaAttuale == 'lampadaFunzionante') ? 'bool' : ordinamentoEcampiTraduzioni.PuntiLuce[this.elementoScalaAttuale].type.toLowerCase();
        return tipoDato;
    }

    public getelementiScala(): object {
        // this.creaTutteLeScale(this.elementoScalaAttuale);
        return this.elementiScala;
    }


    getAllColorVisibleSpectrum(numeroDiColori) {
        const colors = [];
        for (let i = 0; i < numeroDiColori; i++) {
            const color = this.hexVisibleSpectrum(i / numeroDiColori);
            colors.push('#' + color);
        }
        return colors
    }

    hexVisibleSpectrum(ratio: number) {
        //we want to normalize ratio so that it fits in to 6 regions
        //where each region is 256 units long
        const normalized = Math.trunc(ratio * 256 * 6);
        //find the distance to the start of the closest region
        const x = normalized % 256;
        let red = 0, grn = 0, blu = 0;
        switch (Math.trunc(normalized / 256)) {
            case 0:
                red = 255;
                grn = x;
                blu = 0;
                break;//red
            case 1:
                red = 255 - x;
                grn = 255;
                blu = 0;
                break;//yellow
            case 2:
                red = 0;
                grn = 255;
                blu = x;
                break;//green
            case 3:
                red = 0;
                grn = 255 - x;
                blu = 255;
                break;//cyan
            case 4:
                red = x;
                grn = 0;
                blu = 255;
                break;//blue
            case 5:
                red = 255;
                grn = 0;
                blu = 255 - x;
                break;//magenta
        }
        return red.toString(16).padStart(2, '0').toUpperCase() + grn.toString(16).padStart(2, '0').toUpperCase() + blu.toString(16).padStart(2, '0').toUpperCase();
    }

    redYellowGreen(ratio: number) {
        //we want to normalize ratio so that it fits in to 6 regions
        //where each region is 256 units long
        const normalized = Math.trunc(ratio * 256 * 2);
        //find the distance to the start of the closest region
        const x = normalized % 256;
        let red = 0, grn = 0, blu = 0;
        switch (Math.trunc(normalized / 256)) {
            case 0:
                red = 255;
                grn = x;
                blu = 0;
                break;//red
            case 1:
                red = 255 - x;
                grn = 255;
                blu = 0;
                break;//yellow
        }
        return red.toString(16).padStart(2, '0').toUpperCase() + grn.toString(16).padStart(2, '0').toUpperCase() + blu.toString(16).padStart(2, '0').toUpperCase();
    }


    getBicolorGradient(typeGradient: 'redBlue' | 'redGreen' | 'greenBlue', numeroDiColori): string[] {
        const colors = [];
        const incremento = Math.ceil(255 / (numeroDiColori - 1));
        if (typeGradient === 'redBlue') {
            let i = 0;
            while (i < numeroDiColori) {
                const red = Math.max(0, 255 - i * incremento);
                const blue = Math.min(0 + i * incremento, 255);
                const green = 0;
                const color = red.toString(16).padStart(2, '0').toUpperCase() + green.toString(16).padStart(2, '0').toUpperCase() + blue.toString(16).padStart(2, '0').toUpperCase();
                colors.push('#' + color);
                i++;
            }
        } else if (typeGradient === 'redGreen') {
            for (let i = 0; i < numeroDiColori; i++) {
                const color = this.redYellowGreen(i / numeroDiColori);
                colors.push('#' + color);
            }
            return colors
        } else if (typeGradient === 'greenBlue') {
            let i = 0;
            while (i < numeroDiColori) {
                const red = 0
                const blue = 0 + i * incremento;
                const green = 255 - i * incremento;
                const color = red.toString(16).padStart(2, '0').toUpperCase() + green.toString(16).padStart(2, '0').toUpperCase() + blue.toString(16).padStart(2, '0').toUpperCase();
                colors.push('#' + color);
                i++;
            }
        }
        return colors
    }

    public linearTransformation(x: number, min: number, max: number, lengthArray: number): number {
        return Math.trunc((lengthArray / (max - min)) * (x - min));
    }

    public createKey(key: any | undefined) {
        if (!isNotNullOrUndefined(key) || typeof key === 'object') {
            const filterPipe = new TransformForTranslatePipe();
            const chiaveSenzaSpazi = filterPipe.transform(this.chiaviScalaColore.non_specificato) as string;
            return chiaveSenzaSpazi;
        } else if (typeof key === 'string') {
            const filterPipe = new TransformForTranslatePipe();
            const chiaveSenzaSpazi = filterPipe.transform(key) as string;
            return chiaveSenzaSpazi;
        } else {
            return key;
        }
    }

    getKeyDate(value: Date) {
        return new Date(this.datePipe.transform(value, 'yyyy-MM-dd')).getTime();
    }

    private sortNumberKey(a, b) {
        const valueA = parseFloat(a.key);
        const valueB = parseFloat(b.key);
        if (isNaN(valueA) || isNaN(valueB)) {
            return -1
        } else {
            return valueA - valueB;
        }
    }

    private sortKey(a, b) {
        const keyA = this.createKey(a.key);
        const keyB = this.createKey(b.key);
        if (keyA === this.keyNonSpecificato || keyA === this.keyAltro) {
            return -1
        } else if (keyB === this.keyNonSpecificato || keyB === this.keyAltro) {
            return 1
        } else {
            return keyA.localeCompare(keyB);
        }
    }


    transformScalaColoreInArray(keyColor): { key: string, color: string }[] | undefined {
        if (keyColor) {
            return Object.keys(keyColor).map(
                key => {
                    return {key: key, color: keyColor[key]};
                }
            );
        } else {
            return undefined;
        }
    }

    creaScalaColore(campoScalaColore: string, elementiInMap: CircuitiParse[] | PuntiLuceParse[], scalaColorePredefinite, tipoDato, isPointer, possibleValues): {
        scalaColore,
        scalaColoreHtml,
        keyValue?
    } {
        let keyColor = {};
        let keyValue = {};
        keyColor[this.keyAltro] = this.chiaviScalaColore.greyColor;
        keyColor[this.keyNonSpecificato] = this.chiaviScalaColore.blackColor;
        const elementInMapCopy = arrayIsSet(elementiInMap) ? [...elementiInMap] : []
        if (scalaColorePredefinite.hasOwnProperty(campoScalaColore)) {
            const allValues = [];
            const allkey = [];
            Object.keys(scalaColorePredefinite[campoScalaColore]).forEach(
                key => {
                    const value = scalaColorePredefinite[campoScalaColore][key];
                    if (this.createKey(key) !== this.keyAltro || this.createKey(key) !== this.keyNonSpecificato) {
                        if (isNotNullOrUndefined(value) && !isNaN(value)) {
                            allValues.push(value);
                            allkey.push(key);
                        }
                    }
                }
            );
            if (allValues.length > 0) {
                const min = Math.min(...allValues);
                const max = Math.max(...allValues);
                const colors = this.getBicolorGradient("redGreen", allValues.length);
                allValues.forEach(
                    (value, index) => {
                        const key = this.createKey(allkey[index]);
                        const indexColor = (min == max) ? 0 : this.linearTransformation(value, min, max, colors.length - 1);
                        keyColor[key] = colors[indexColor]
                    }
                )
            }
            return {scalaColore: keyColor, scalaColoreHtml: this.transformScalaColoreInArray(keyColor)};
        } else if ((tipoDato === 'bool' || tipoDato === 'file')) {
            keyColor['true'] = this.colorTrue;
            keyColor['false'] = this.colorFalse;
            return {scalaColore: keyColor, scalaColoreHtml: this.transformScalaColoreInArray(keyColor)};
        } else if (tipoDato === 'elenco') {
            if (possibleValues.length > 0) {
                const possibleValuesWithoutAltroNonSpecificato = possibleValues.filter(
                    possibleValue => {
                        return this.createKey(possibleValue) != this.keyAltro &&
                            this.createKey(possibleValue) != this.keyNonSpecificato
                    }
                );
                const length = possibleValuesWithoutAltroNonSpecificato.length;
                const colors = this.getAllColorVisibleSpectrum(length);
                possibleValuesWithoutAltroNonSpecificato.forEach(
                    (possibleValue, index) => {
                        keyColor[this.createKey(possibleValue)] = colors[index];
                    }
                );
            }
            return {scalaColore: keyColor, scalaColoreHtml: this.transformScalaColoreInArray(keyColor)};
        } else if (tipoDato === 'text' || tipoDato === 'targa') {
            let getValue;
            if (ordinamentoEcampiTraduzioni.PuntiLuce.hasOwnProperty(campoScalaColore) && ordinamentoEcampiTraduzioni.PuntiLuce[campoScalaColore].getValueInLabel != null) {
                getValue = ordinamentoEcampiTraduzioni.PuntiLuce[campoScalaColore].getValueInLabel;
            }
            const allValues = [];
            elementInMapCopy.forEach(
                elemento => {
                    const value = getValue != null ? getValue(elemento).key : elemento[campoScalaColore]
                    if (isNotNullOrUndefined(value) && !allValues.includes(value)) {
                        allValues.push(value)
                    }
                }
            );
            allValues.sort();
            allValues.forEach(
                (value, index) => {
                    const key = this.createKey(value);
                    keyColor[key] = this.qualitativeScale[index % (this.qualitativeScale.length)]
                    keyValue[key] = value;
                }
            );
            return {
                scalaColore: keyColor,
                scalaColoreHtml: this.transformScalaColoreInArray(keyColor).sort((a, b) => this.sortKey(a, b)),
                keyValue
            };
        } else if (tipoDato === 'number' || tipoDato === 'int') {
            const allValues = [];
            elementInMapCopy.forEach(
                elemento => {
                    const value = (typeof elemento[campoScalaColore] === 'number') ? elemento[campoScalaColore] : parseInt(elemento[campoScalaColore]);
                    if (isNotNullOrUndefined(value) && !allValues.includes(value) && !isNaN(value)) {
                        allValues.push(value)
                    }
                }
            );
            if (allValues.length > 0) {
                const min = Math.min(...allValues);
                const max = Math.max(...allValues);
                const colors = this.getBicolorGradient("redGreen", allValues.length);
                allValues.forEach(
                    (value, i) => {
                        const key = this.createKey(value);
                        const index = (min == max) ? 0 : this.linearTransformation(value, min, max, colors.length - 1);
                        keyColor[key] = colors[index];
                        keyValue[key] = value;
                    }
                )
            }
            return {
                scalaColore: keyColor,
                scalaColoreHtml: this.transformScalaColoreInArray(keyColor).sort((a, b) => this.sortNumberKey(a, b)),
                keyValue
            };
        } else if (tipoDato === 'date') {
            const allValues = [];
            elementInMapCopy.forEach(
                elemento => {
                    if (isNotNullOrUndefined(elemento[campoScalaColore])) {
                        const daIncludere = this.getKeyDate(elemento[campoScalaColore]);
                        if (!allValues.includes(daIncludere)) {
                            allValues.push(daIncludere)
                        }
                    }
                }
            );
            allValues.sort((a, b) => a - b)
            allValues.forEach(
                (value, index) => {
                    const key = this.createKey(value);
                    keyColor[key] = this.qualitativeScale[index % (this.qualitativeScale.length)];
                    keyValue[key] = value;
                }
            )
            return {scalaColore: keyColor, scalaColoreHtml: this.transformScalaColoreInArray(keyColor), keyValue};
        } else if (isPointer) {
            const pointerClass = possibleValues[0];
            const pointerKey = possibleValues[1];
            const pointerTipoDato = (ordinamentoEcampiTraduzioni.hasOwnProperty(pointerClass) && ordinamentoEcampiTraduzioni[pointerClass].hasOwnProperty(pointerKey)) ? ordinamentoEcampiTraduzioni[pointerClass][pointerKey].type.toLowerCase() : undefined;
            const allValues = [];
            elementInMapCopy.forEach(
                elemento => {
                    const value = (isNotNullOrUndefined(elemento[campoScalaColore])) ? elemento[campoScalaColore][pointerKey] : undefined;
                    if (isNotNullOrUndefined(value) && !allValues.includes(value)) {
                        allValues.push(value)
                    }
                }
            );
            if (pointerTipoDato === 'text' || pointerTipoDato === 'targa') {
                allValues.sort();
            }
            allValues.forEach(
                (value, index) => {
                    const key = this.createKey(value);
                    keyColor[key] = this.qualitativeScale[index % (this.qualitativeScale.length)];
                    keyValue[key] = value;
                }
            );
            const scalaColorehtml = (pointerTipoDato === 'text' || pointerTipoDato === 'targa') ? this.transformScalaColoreInArray(keyColor).sort((a, b) => this.sortKey(a, b)) : this.transformScalaColoreInArray(keyColor);
            return {
                scalaColore: keyColor,
                scalaColoreHtml: scalaColorehtml,
                keyValue
            }
        }
    }

    //restituisce colore in base al valore di lux. Utilizzato per lux data clusters
    interpolateColorByLuxForClusters(lux: number) {
        let color
        // if (lux == -1 || lux == undefined) {
        //     return 'rgba(0,0,0,0)';
        // } else if (lux >= 0 && lux < 1) {
        //     let colormap = interpolate(["#7d0000", "#ff0000"])
        //     color = colormap(lux / 30);
        // }  else if (lux >= 1 && lux < 5) {
        //     let colormap = interpolate(["#ff0000", "#ffff00"])
        //     color = colormap((lux-1) / 4);
        // } else if (lux >= 5 && lux < 15) {
        //     let colormap = interpolate(["#ffFF00", "#00ff00"])
        //     color = colormap((lux-5) / 10);
        // } else if (lux >= 15 && lux < 40) {
        //     let colormap = interpolate(["#00ff00",'#0000ff'])
        //     color = colormap((lux-15) / 25);
        // } else {
        //     const rangeValue = 100 - 30;
        //     const scaledValue = lux > 100 ? rangeValue : lux - 30;
        //     let colormap = interpolate(["#0000ff", "#ffffff", ])
        //     color = colormap(scaledValue / rangeValue)
        // }
        if (lux == -1 || lux == undefined) {
            return 'rgba(0,0,0,0)';
        }  else if (lux >= 0 && lux < 15) {
            let colormap = interpolate(["#0000ff", "#00ff00"])
            color = colormap((lux) / 15);
        } else if (lux >= 15 && lux < 35) {
            let colormap = interpolate(["#00FF00", "#ffff00"])
            color = colormap((lux-15) / 25);
        } else {
            const rangeValue = 40 - 35;
            const scaledValue = lux > 40 ? rangeValue : lux - 35;
            let colormap = interpolate(["#ffff00", "#ff0000",])
            color = colormap(scaledValue / rangeValue)
        }
        return color
    }

    interpolateColorByAirDataClusters(value: number, firstBreackPoint, secondBreackPoint) {
        if (value < 0 || value == null) {
            return 'rgba(0,0,0,0)';
        } else if (value <= firstBreackPoint) {
            const colormap = interpolate(["#0000ff", "#00ffff", "#00ff00", "#ffff00", "#ff7f00"])
            return colormap(value / firstBreackPoint);
        } else {
            const rangeValue = secondBreackPoint - firstBreackPoint;
            const scaledValue = value > secondBreackPoint ? rangeValue : value - firstBreackPoint;
            const colormap = interpolate(["#ff7f00", "#ff0000"])
            return colormap(scaledValue / rangeValue);
        }
    }

    interpolateColorForClusters(value: number, type: string) {
        switch (type) {
            case "LUX": {
                return this.interpolateColorByLuxForClusters(value)
            }
            case "AIR_PM2.5":
            case "AIR_PM10": {
                let limiteNormativo;
                if (type == "AIR_PM2.5") {
                    limiteNormativo = 25;
                } else {
                    limiteNormativo = 40;
                }
                return this.interpolateColorByAirDataClusters(value, limiteNormativo, limiteNormativo * 2)
            }
        }
    }
}
