import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UserService} from './providers/services/user.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from './providers/services/auth.service';
import {NavService} from './providers/services/nav.service';
import {MatSidenav} from '@angular/material/sidenav';
import {DetailsideService} from './providers/services/detailside.service';
import {FiltersideService} from './providers/services/filterside.service';
import {UserRole, UserRoleNumber} from '../config/static-data';
import {ProjectService} from './providers/services/project.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {NavbarService} from './providers/services/navbar.service';
import {ReadValueBackgroundUploading, UploadBackgroundService} from "./providers/services/upload-background.service";
import {BehaviorSubject, Observable} from "rxjs";
import {arrayIsSet, isNotNullOrUndefined} from "./models/Models";
import {ParseApiService} from "./providers/services/parse-api.service";
import {filter, map} from "rxjs/operators";
import {AlertService} from "./providers/services/alert.service";

export const AbbonamentiDaVerificare = {
    progettazioneAttivo: 'progettazioneAttivo',
    telecontrolloAttivo: 'telecontrolloAttivo',
    gestioneAttivo: 'gestioneAttivo'
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit {

    public userRoleNumber = UserRoleNumber;
    public isLogged;
    private subscription;
    private subscriptionProject;
    private subscriptionRouter;
    public user;
    private istanzaEsportaDatiDialog;
    public roles$: Observable<string[]>
    public abbonamentoGestioneAttivo$: Observable<boolean>
    public abbonamentoProgettazioneAttivo$: Observable<boolean>
    public abbonamentoTelecontrolloAttivo$: Observable<boolean>
    public currentLinkEmit = new BehaviorSubject(undefined)
    public currentLink$: Observable<string> = this.currentLinkEmit.asObservable();

    @ViewChild(MatSidenav, {static: true}) public sidenav: MatSidenav;

    public links = [
        {
            abbonamentoDaVerifcare: undefined,
            path: '/project',
            icon: 'assignment',
            title: 'hamburgerNavbar.select_project'
        },
        {
            abbonamentoDaVerifcare: undefined,
            path: '/map',
            icon: 'explore',
            title: 'hamburgerNavbar.map'
        },
        {
            isTitleGroup: true,
            title: 'hamburgerNavbar.design',
            acceptedRoles: [UserRole.GESTORE || UserRole.OPERATORE],
        },
        {
            abbonamentoDaVerifcare: [AbbonamentiDaVerificare.progettazioneAttivo],
            levelUser: 3,
            acceptedRoles: [UserRole.GESTORE || UserRole.OPERATORE],
            path: '/planimetrie',
            icon: 'map',
            title: 'hamburgerNavbar.manage_plans'
        },
        {
            abbonamentoDaVerifcare: [AbbonamentiDaVerificare.progettazioneAttivo],
            levelUser: 3,
            acceptedRoles: [UserRole.GESTORE || UserRole.OPERATORE],
            path: '/group',
            icon: 'group_work',
            title: 'groupLightPoints_label'
        },
        {
            abbonamentoDaVerifcare: [AbbonamentiDaVerificare.progettazioneAttivo],
            levelUser: 3,
            acceptedRoles: [UserRole.GESTORE],
            path: '/foto-tipologia',
            icon: 'photo_library',
            title: 'hamburgerNavbar.fotoTipologia'
        },
        {
            abbonamentoDaVerifcare: [AbbonamentiDaVerificare.progettazioneAttivo],
            levelUser: 3,
            acceptedRoles: [UserRole.GESTORE || UserRole.OPERATORE],
            path: '/carichi-esogeni',
            icon: 'electrical_services',
            title: 'hamburgerNavbar.carichi_esogeni'
        },
        {
            abbonamentoDaVerifcare: [AbbonamentiDaVerificare.progettazioneAttivo],
            levelUser: 3,
            acceptedRoles: [UserRole.GESTORE || UserRole.OPERATORE],
            path: '/strade',
            iconCustom: 'assets/icon/hamburgerNavbar/street.png',
            title: 'hamburgerNavbar.street'
        }, {
            abbonamentoDaVerifcare: [AbbonamentiDaVerificare.progettazioneAttivo],
            levelUser: 3,
            acceptedRoles: [UserRole.GESTORE || UserRole.OPERATORE],
            path: '/verifica-illuminotecnica',
            iconCustom: 'assets/icon/hamburgerNavbar/verify.png',
            title: 'hamburgerNavbar.verificaIlluminotecnica'
        },
        {
            abbonamentoDaVerifcare: [AbbonamentiDaVerificare.progettazioneAttivo],
            levelUser: 3,
            acceptedRoles: [UserRole.GESTORE || UserRole.OPERATORE],
            path: '/fotometrie',
            iconCustom: 'assets/icon/hamburgerNavbar/ldtIcon.svg',
            title: 'hamburgerNavbar.importFotometrie'
        },
        {
            abbonamentoDaVerifcare: [AbbonamentiDaVerificare.progettazioneAttivo],
            levelUser: 0,
            acceptedRoles: [UserRole.GESTORE || UserRole.OPERATORE],
            path: '/importazione',
            iconCustom: 'assets/icon/hamburgerNavbar/importFile.svg',
            title: 'hamburgerNavbar.upload'
        },
        {
            abbonamentoDaVerifcare: [AbbonamentiDaVerificare.progettazioneAttivo],
            levelUser: 0,
            acceptedRoles: [UserRole.GESTORE || UserRole.OPERATORE],
            path: '/esporta-file',
            iconCustom: 'assets/icon/hamburgerNavbar/exportFile.svg',
            title: 'hamburgerNavbar.exportDate'
        },
        {
            isTitleGroup: true,
            title: 'hamburgerNavbar.remoteControllEnergy',
        },
        {
            abbonamentoDaVerifcare: [AbbonamentiDaVerificare.telecontrolloAttivo],
            path: '/controllo-remoto',
            iconCustom: 'assets/icon/hamburgerNavbar/icon-telecontrollo.png',
            title: 'hamburgerNavbar.remot_control'
        },
        {
            abbonamentoDaVerifcare: [AbbonamentiDaVerificare.telecontrolloAttivo],
            acceptedRoles: [UserRole.GESTORE],
            path: '/new-profile',
            iconCustom: 'assets/icon/hamburgerNavbar/time-settings-icon.svg',
            title: 'hamburgerNavbar.TLC_profile'
        },
        {
            abbonamentoDaVerifcare: [AbbonamentiDaVerificare.telecontrolloAttivo],
            acceptedRoles: [UserRole.GESTORE],
            path: '/energia-report',
            iconCustom: 'assets/icon/hamburgerNavbar/reportingEnergy.png',
            title: 'hamburgerNavbar.energy_report'
        },
        {
            isTitleGroup: true,
            title: 'hamburgerNavbar.managementMaintenance',
        },
        {
            abbonamentoDaVerifcare: [AbbonamentiDaVerificare.gestioneAttivo, AbbonamentiDaVerificare.telecontrolloAttivo],
            path: '/segnalazioni',
            icon: 'report',
            title: 'hamburgerNavbar.reports'
        },
        {
            abbonamentoDaVerifcare: [AbbonamentiDaVerificare.gestioneAttivo],
            acceptedRoles: [UserRole.GESTORE],
            path: '/manuntenzione-programmata',
            iconCustom: 'assets/icon/hamburgerNavbar/calendar-setting.svg',
            title: 'hamburgerNavbar.scheduleMaintenance'
        },
        {
            abbonamentoDaVerifcare: [AbbonamentiDaVerificare.gestioneAttivo, AbbonamentiDaVerificare.telecontrolloAttivo],
            acceptedRoles: [UserRole.GESTORE],
            path: '/segnalazioni-report',
            iconCustom: 'assets/icon/hamburgerNavbar/report-segnalazioni.svg',
            title: 'hamburgerNavbar.reportSegnalazioni'
        },
        {
            abbonamentoDaVerifcare: [AbbonamentiDaVerificare.gestioneAttivo, AbbonamentiDaVerificare.progettazioneAttivo],
            acceptedRoles: [UserRole.GESTORE],
            levelUser: 6,
            path: '/file-manager',
            iconCustom: 'assets/icon/hamburgerNavbar/fileManager.svg',
            title: 'hamburgerNavbar.fileManager'
        }, {
            abbonamentoDaVerifcare: [AbbonamentiDaVerificare.gestioneAttivo, AbbonamentiDaVerificare.telecontrolloAttivo],
            acceptedRoles: [UserRole.GESTORE],
            path: '/new-installation',
            iconCustom: 'assets/icon/hamburgerNavbar/newInstallation.png',
            title: 'hamburgerNavbar.newInstallation'
        }
    ];
    verificaUserLevel = (v: any) => this.userService.abbonamentoAlmenoLivello(v)

    private instanzaQueryParam;

    constructor(
        private parseApiService: ParseApiService,
        public userService: UserService,
        private navService: NavService,
        private detailSideService: DetailsideService,
        private filterSideService: FiltersideService,
        public translate: TranslateService,
        private authService: AuthService,
        private projectService: ProjectService,
        private activatedRoute: ActivatedRoute,
        public navbarService: NavbarService,
        private uploadBackgroundService: UploadBackgroundService,
        private router: Router,
        private alertService: AlertService
    ) {
        this.roles$ = this.projectService.currentRoles$;
        this.abbonamentoGestioneAttivo$ = this.projectService.abbonamentoGestioneAttivo$;
        this.abbonamentoProgettazioneAttivo$ = this.projectService.abbonamentoProgettazioneAttivo$;
        this.abbonamentoTelecontrolloAttivo$ = this.projectService.abbonamentoTelecontrolloAttivo$;
        this.instanzaQueryParam = this.activatedRoute.queryParams.subscribe((params) => {
            if (!this.navbarService.disabledNavbar) {
                this.navbarService.disabledNavbar = (params.navbarOn == 'false');
            }
        });

        this.isLogged = this.userService.isLogged();
        this.userService.fetchUser$().subscribe()
        this.subscription = this.authService.getObservableForLogin().subscribe(res => {
            this.isLogged = res;
        });
        translate.addLangs(['it', 'en', 'es', 'ca','fr']);
        translate.setDefaultLang('it');
        const savedLanguage = localStorage.getItem('savedLanguage');
        if (translate.getLangs().includes(savedLanguage)) {
            translate.use(savedLanguage);
        } else {
            translate.use(translate.getBrowserLang());
        }

        this.subscriptionRouter = router.events.pipe(
            filter(event => event instanceof NavigationEnd),
        ).subscribe((a: any) => this.currentLinkEmit.next(a.url))
    }



    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.subscriptionProject.unsubscribe();
        this.subscriptionRouter.unsubscribe();
        this.istanzaEsportaDatiDialog.unsubscribe();
        this.instanzaQueryParam.unsubscribe();
    }

    ngOnInit() {
        this.userService.invalidSessionTokenTest_v2().subscribe(
            values => {
                if (values.logout == true) {
                    this.authService.goToLogIn();
                }
            }
        );
        this.navService.setSidenav(this.sidenav);
    }

    onClickNav(link: any) {
        if (link.disabled) {
            this.alertService.warning(this.translate.instant('errors.requestSubscriptionWithEmail'), 13000)
        } else {
            this.navService.close();
            this.detailSideService.close();
            this.filterSideService.close();
            this.router.navigate([link.path]);
        }
    }

    public get onwardsGestore() {
        return !this.isUser;
    }

    public get isUser() {
        return (isNotNullOrUndefined(this.projectService.actualRoles)) ? this.projectService.actualRoles.includes(UserRole.UTENTE) : false;
    }

    public get myRole() {
        return this.projectService.myRole
    }


    getClassLink(link: any, currentUrl): string {
        let classCss
        if (link.disabled) {
            classCss = 'opacity-disabled';
        } else if (currentUrl === link.path) {
            classCss = 'background-primary-transparent';
        }
        return classCss;
    }

    public get abbonamentoGestioneAttivo() {
        return this.projectService.abbonamentoGestioneAttivo
    }

    get backgroundLoadvalue$(): Observable<ReadValueBackgroundUploading> {
        return this.uploadBackgroundService.uploadingReadValue$
    }

    get backgroundIsActive(): boolean {
        return !this.uploadBackgroundService.uploadingTerminate
    }


    get cssClass(): string[] {
        return this.uploadBackgroundService.cssClass
    }

    closeShowOrHidden() {
        this.uploadBackgroundService.closeShowOrHidden();
    }

    filterLinkPredicate<T>(links: T[] | any[], roles: string[], abbonamentoGestioneAttivo: boolean, abbonamentoTelecontrollo: boolean, abbonamentoProgettazione: boolean, verificaUserLevel: (v: any) => boolean): T[] {
        if (arrayIsSet(links)) {
            const verificaAbbonamento = (link) => {
                if (arrayIsSet(link.abbonamentoDaVerifcare)) {
                    const abbonamentoAttivo = link.abbonamentoDaVerifcare.findIndex(abbonamento => {
                        if (abbonamento === AbbonamentiDaVerificare.gestioneAttivo) {
                            return abbonamentoGestioneAttivo
                        } else if (abbonamento === AbbonamentiDaVerificare.telecontrolloAttivo) {
                            return abbonamentoTelecontrollo
                        } else if (abbonamento === AbbonamentiDaVerificare.progettazioneAttivo) {
                            return abbonamentoProgettazione
                        }
                    }) >= 0;
                    return abbonamentoAttivo
                }
                return true;
            }
            const verificaUser = (link) => {
                if (link.levelUser != null) {
                    return verificaUserLevel(link.levelUser)
                }
                return false;
            }

            const disactiveLink = (link: any, abbonamentoProgettazione: boolean, abbonamentoTelecontrollo: boolean, abbonamentoGestioneAttivo: boolean, verificaUserLevel: (v: any) => boolean) => {
                const obj = {...link}
                let block = true;
                if (link.abbonamentoDaVerifcare != null) {
                    block = verificaUser(link) || verificaAbbonamento(link);
                }
                obj['disabled'] = !block
                return obj;
            }

            const filteredLinks = links
                .filter(link => {
                    let block = true;
                    if (arrayIsSet(link.acceptedRoles)) {
                        if (arrayIsSet(roles)) {
                            block = link.acceptedRoles.find(role => roles.includes(role)) != null
                        } else {
                            block = false;
                        }
                    }
                    return block
                })
            return filteredLinks
                .map(link => disactiveLink(link, abbonamentoProgettazione, abbonamentoTelecontrollo, abbonamentoGestioneAttivo, verificaUserLevel))
        } else {
            return []
        }
    }


    // forceReload() {
    //     window.location.reload(true);
    //     // window.history.go(0)
    //     // window.location.href = window.location.href;
    // }

}