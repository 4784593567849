import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
//import {Chart} from "chart.js";
import {ProjectService} from "../../../providers/services/project.service";

@Component({
    selector: 'app-list-control',
    templateUrl: './list-control.component.html',
    styleUrls: ['./list-control.component.scss']
})
export class ListControlComponent implements OnInit {

    public loadingAbbonamento = true;

    constructor(private activatedRoute: ActivatedRoute,
                private projectService: ProjectService
    ) {
        const {id} = this.activatedRoute.snapshot.params;
    }

    async ngOnInit() {
        this.loadingAbbonamento = true;
        this.loadingAbbonamento = false;
    }

    public get abbonamentoAttivo(){
        return this.projectService.abbonamentoGestioneAttivo;
    }

    onChangeTab(e: Tabs) {
        switch (e) {
            case Tabs.REPORTS:
                break;
            case Tabs.ACTIVITIES:
                break;
        }
    }

}

enum Tabs {
    REPORTS,
    ACTIVITIES
}
