import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConnectedFilesComponent} from "./connected-files/connected-files.component";
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {MatIconModule} from "@angular/material/icon";
import {TranslateModule} from "@ngx-translate/core";
import {NgxDropzoneModule} from "ngx-dropzone";
import {BoxesModule} from "../../boxes/boxes.module";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import { AssignementScheduleMaintenceComponent } from './assignement-schedule-maintence/assignement-schedule-maintence.component';
import {ListElementModule} from "../list-element/list-element.module";
import {WidgetsModule} from "../../widgets/widgets.module";
import {PipesModule} from "../../providers/pipes/pipes.module";
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from "@angular/material/legacy-progress-spinner";


@NgModule({
    declarations: [ConnectedFilesComponent, AssignementScheduleMaintenceComponent],
    imports: [
        CommonModule,
        MatCardModule,
        MatIconModule,
        TranslateModule,
        NgxDropzoneModule,
        BoxesModule,
        MatButtonModule,
        ListElementModule,
        WidgetsModule,
        PipesModule,
        MatProgressSpinnerModule
    ],
    exports: [ConnectedFilesComponent, AssignementScheduleMaintenceComponent]
})
export class ConnectedFilesModule {
}
