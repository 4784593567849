import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Observable} from "rxjs";
import {map, startWith} from "rxjs/operators";
import {isNotNullOrUndefined} from "../../../models/Models";

export interface dataForm {
    valueForm: string;
    html: string;
    traduction?: string | undefined;
    registerValue?: any | undefined;
    traductions?: string[] | undefined;
    cssClass?: string;
    color?: 'primary' | 'accent' | 'warn';
}

@Component({
    selector: 'app-select-or-create',
    templateUrl: './select-or-create.component.html',
    styleUrls: ['./select-or-create.component.scss']
})
export class SelectOrCreateComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<SelectOrCreateComponent>,
                @Inject(MAT_DIALOG_DATA) public data: { data: dataForm[], title: { popUp: string, form: string, createNew: string, update: string }, icon: { createNew: string, update: string } },
                private fb: UntypedFormBuilder) {
        this.createUpdateForm = this.fb.group({
            selectValue: [{
                value: '',
                disabled: false
            }, [Validators.required]],
        });
    }

    createUpdateForm: UntypedFormGroup;
    filteredOptions: Observable<dataForm[]>;

    ngOnInit() {
        // inizializziamo l observable sui filtri
        this.filteredOptions = this.createUpdateForm.valueChanges
            .pipe(
                startWith(''),
                map(value => {
                    return (value as any).selectValue ? this._filter((value as any).selectValue) : this.data.data.slice()
                })
            );
    }


    displayFnNumeroQuadro(id): string {
        const index = this.data.data.findIndex(data => data.valueForm == id);
        return (index >= 0) ? this.data.data[index].html : '';
    }

    private _filter(value: string): dataForm[] {
        const filterValue = value.toLowerCase();
        const filtered = this.data.data.filter(data => data.html.toLowerCase().includes(filterValue))
        return filtered;
    }

    valueIsInDatSet() {
        const valueForm = this.createUpdateForm.get('selectValue').value
        if (isNotNullOrUndefined(valueForm) && typeof valueForm == "string" && valueForm.trim().length > 0) {
            const id = this.createUpdateForm.get('selectValue').value;
            const index = this.data.data.findIndex(data => data.valueForm == id);
            return (index >= 0)
        } else {
            return true
        }
    }

    getValue(isNew: boolean) {
        return {isNew, valueForm: this.createUpdateForm.get('selectValue').value}
    }


    get fontWeigthText() {
        return (this.createUpdateForm.valid) ? 'bold-text' : 'disabled-text'
    }

}
