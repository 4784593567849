import {Pipe, PipeTransform} from '@angular/core';
import {isNotNullOrUndefined} from "../../models/Models";

@Pipe({
    name: 'convertSecInhms'
})
export class ConvertSecInhmsPipe implements PipeTransform {


    private getDecimal(value: number) {
        return value - Math.round(value);
    }

    transform(value: unknown, ...args: unknown[]): unknown {
        if (!isNotNullOrUndefined(value) || (typeof value !== 'string' && typeof value !== 'number')) {
            return '-';
        }
        let secondiTotali: number;
        if (typeof value === 'string') {
            secondiTotali = parseInt(value);
        } else if (typeof value == "number") {
            secondiTotali = Math.round(value);
        } else {
            secondiTotali = value;
        }
        if (isNaN(secondiTotali)) {
            return '-';
        } else {
            const ore = Math.floor(secondiTotali / 3600);
            const minuti = Math.floor((secondiTotali % 3600) / 60);
            const secondi = (secondiTotali % 3600) % 60;
            let orarioCompleto = '';
            orarioCompleto += (ore > 0) ? 'h: ' + ore + ' ' : '';
            orarioCompleto += (minuti > 0) ? 'm: ' + minuti + ' ' : '';
            orarioCompleto += (secondi > 0) ? 's: ' + secondi : '';
            return orarioCompleto;
        }
    }

}
