import {Component, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    UntypedFormBuilder,
    UntypedFormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR, ValidationErrors
} from '@angular/forms';
import {Observable, Subject, Subscription} from 'rxjs';
import {
    arrayIsSet,
    isNotNullOrUndefined,
    KeyStringValue,
    PossibleValuesFormField,
    stringIsSet
} from '../../models/Models';
import {TranslateService} from '@ngx-translate/core';
import {map, startWith} from "rxjs/operators";



@Component({
    selector: 'app-multi-select-form-field',
    templateUrl: './multi-select-form-field.component.html',
    styleUrls: ['./multi-select-form-field.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: MultiSelectFormFieldComponent
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: MultiSelectFormFieldComponent
        }]
})
export class MultiSelectFormFieldComponent implements OnChanges, OnDestroy, ControlValueAccessor {
    private subScriptions = new Subscription();
    public formGroup: UntypedFormGroup;
    openList = false;
    orderRendering;
    @Output() emitValues=new Subject()
    @Input() labelSearch: string;
    @Input() possibleValues: PossibleValuesFormField[];
    @Input() activedPossibleValues: KeyStringValue<boolean>;
    @Input() disabledPossibleValues: KeyStringValue<boolean>;
    public isSetPossibleValues = false;
    public showButtonShowDisabledItem = false;
    private subscripitonOptions: Subscription;

    public selectedChips$: Observable<PossibleValuesFormField[]>
    public filteredName$: Observable<string>
    public showNotActived$: Observable<boolean>

    constructor(
        private fb: UntypedFormBuilder,
        public translateService: TranslateService
    ) {
        this.formGroup = this.fb.group({
            name: '',
            registerValues: [],
            options: [],
            chips: [],
            showNotActived: false
        });
        this.selectedChips$ = this.formGroup.get('chips').valueChanges.pipe(
            startWith(this.selectedChips),
            map(() => this.selectedChips)
        )
        this.filteredName$ = this.formGroup.get('name').valueChanges.pipe(
            startWith(this.filteredName),
            map(() => this.filteredName)
        )
        this.showNotActived$ = this.formGroup.get('showNotActived').valueChanges.pipe(
            startWith(undefined),
            map(() => this.showNotActived)
        )
        this.orderRendering = this.getOrderRendering();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.subscripitonOptions == null) {
            this.subscripitonOptions = this.formGroup.get('options').valueChanges.subscribe(options => {
                if (arrayIsSet(options)) {

                    let registerValues;

                    if (!arrayIsSet(this.possibleValues)) {
                        registerValues = options;
                        this.selectedChips = options.map(option => {
                            const obj: PossibleValuesFormField = {
                                traduction: option,
                                registerValue: option,
                                objectId: option
                            }
                            return obj;
                        })
                    } else {
                        registerValues = this.possibleValues
                            .filter(value => options.includes(value.objectId))
                            .map(value => value.registerValue);
                        this.selectedChips = this.possibleValues
                            .filter(value => options.includes(value.objectId))
                            .map(value => {
                                const obj = {...value};
                                delete obj.registerValue;
                                return obj;
                            });
                    }
                    this.selectedRegisterValues = registerValues;

                } else {
                    this.selectedRegisterValues = [];
                    this.selectedChips = [];
                }
                this.emitValues.next(this.selectedRegisterValues)
                this.orderRendering = this.getOrderRendering();
            });
        }
        if (changes.disabledPossibleValues != null || changes.activedPossibleValues != null) {
            this.showButtonShowDisabledItem = (this.activedPossibleValues != null && arrayIsSet(Object.keys(this.activedPossibleValues))) || (this.disabledPossibleValues != null && arrayIsSet(Object.keys(this.disabledPossibleValues)))
        }
        if (changes.possibleValues != null) {
            this.isSetPossibleValues = arrayIsSet(this.possibleValues);
            if (this.isSetPossibleValues) {
                if (arrayIsSet(this.selectedOptions)) {
                    setTimeout(() => {
                        const currentItem = this.possibleValues.map(value => value.objectId);
                        this.selectedOptions = this.selectedOptions.filter(option => currentItem.includes(option));
                    });
                }
            } else {
                this.formGroup.get('registerValues').reset();
            }
        }
    }

    ngOnDestroy() {
        this.subScriptions.unsubscribe()
        if (this.subscripitonOptions != null) {
            this.subscripitonOptions.unsubscribe();
        }
    }


    unsetValue() {
        this.formGroup.reset();
    }

    onTouched = () => {
    }


    registerOnChange(onChange: any) {
        const sub = this.formGroup.get('registerValues').valueChanges
            .subscribe(onChange);
        this.subScriptions.add(sub);
    }

    registerOnTouched(onTouched) {
        this.onTouched = onTouched;
    }


    setDisabledState(disabled: boolean) {
        if (disabled) {
            this.formGroup.disable();
        } else {
            this.formGroup.enable();
        }
    }


    writeValue(value: any) {
        if (!isNotNullOrUndefined(value)) {
            this.formGroup.reset();
        } else if (arrayIsSet(value)) {
            this.formGroup.get('options').setValue(value.map(val => (val.objectId) ? val.objectId : val));
        }
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (!isNotNullOrUndefined(this.formGroup.get('registerValues').validator)) {
            this.formGroup.get('registerValues').setValidators(control.validator);
        }
        if (this.formGroup.get('registerValues').valid) {
            return null;
        } else {
            return this.formGroup.get('registerValues').errors;
        }
    }

    get selectedRegisterValues(): PossibleValuesFormField[] {
        return this.formGroup.get('registerValues').value;
    }

    set selectedRegisterValues(values: PossibleValuesFormField[]) {
        this.formGroup.get('registerValues').setValue(values);
    }

    get selectedChips(): PossibleValuesFormField[] {
        return this.formGroup.get('chips').value;
    }

    set selectedChips(values: PossibleValuesFormField[]) {
        this.formGroup.get('chips').setValue(values);
    }

    get selectedOptions(): string[] {
        return this.formGroup.get('options').value;
    }


    set selectedOptions(values: string[]) {
        this.formGroup.get('options').setValue(values);
    }

    get filteredName(): string {
        return this.formGroup.get('name').value;
    }

    set showNotActived(values: boolean) {
        this.formGroup.get('showNotActived').setValue(values);
    }

    get showNotActived(): boolean {
        return this.formGroup.get('showNotActived').value;
    }

    removeToSelect(item: PossibleValuesFormField) {
        if (arrayIsSet(this.selectedOptions)) {
            const filteredregisterValues = this.selectedOptions.filter(option => option !== item.objectId);
            this.formGroup.get('options').setValue(filteredregisterValues);
        }
    }

    getOrderRendering(): {
        searchBar: { order: number, hidden: boolean },
        chipsList: { order: number, hidden: boolean },
        listSelect: { order: number, hidden: boolean }
    } {
        if (!isNotNullOrUndefined(this.formGroup)) {
            return {
                searchBar: {order: 0, hidden: false},
                chipsList: {order: 2, hidden: true},
                listSelect: {order: 1, hidden: true}
            };
        }
        const itemsIsSet = arrayIsSet(this.selectedRegisterValues);
        if (!this.openList && !itemsIsSet) {
            return {
                searchBar: {order: 0, hidden: false},
                chipsList: {order: 2, hidden: true},
                listSelect: {order: 1, hidden: true}
            };
        } else if (this.openList && !itemsIsSet) {
            return {
                searchBar: {order: 0, hidden: false},
                chipsList: {order: 2, hidden: true},
                listSelect: {order: 1, hidden: false}
            };
        } else if (!this.openList && itemsIsSet) {
            return {
                searchBar: {order: 0, hidden: false},
                chipsList: {order: 1, hidden: false},
                listSelect: {order: 2, hidden: true}
            };
        } else if (this.openList && itemsIsSet) {
            return {
                searchBar: {order: 1, hidden: false},
                chipsList: {order: 0, hidden: false},
                listSelect: {order: 2, hidden: false}
            };
        } else {
            return {
                searchBar: {order: 0, hidden: false},
                chipsList: {order: 2, hidden: true},
                listSelect: {order: 1, hidden: true}
            };
        }
    }


    inSearchIn() {
        this.openList = true;
        this.orderRendering = this.getOrderRendering();
        if (this.formGroup.get('name')) {
            this.formGroup.get('name').updateValueAndValidity();
        }
    }

    clickOutside() {
        if (this.openList) {
            this.clickIcon();
        }
    }

    clickIcon() {
        this.openList = !this.openList;
        this.orderRendering = this.getOrderRendering();
        if (!this.openList) {
            this.formGroup.get('name').setValue('');
        }
    }

    predicateFilterFunction(possibleValue: PossibleValuesFormField, filteredName: string, translate): boolean {
        if (!isNotNullOrUndefined(possibleValue) ||
            (!stringIsSet(possibleValue.traduction) && !stringIsSet(possibleValue.noTraduction)) ||
            !stringIsSet(filteredName)) {
            return false;
        } else {
            // tslint:disable-next-line:no-shadowed-variable
            const castValue = (value: string) => {
                return value
                    .replace(/_/g, ' ')
                    .replace(/-/g, ' ')
                    .replace(/\./g, '')
                    .trim()
                    .toLowerCase();
            };
            const isIncludes = (value1: string, value2: string) => {
                const castValue1 = castValue(value1);
                const castValue2 = castValue(value2);
                return castValue1.includes(castValue2);
            };
            const value = stringIsSet(possibleValue.traduction) ? translate.instant(possibleValue.traduction) : possibleValue.noTraduction;
            return !isIncludes(value, filteredName);
        }
    }

    public predicateIsDisabled(possibleData: PossibleValuesFormField, actived: KeyStringValue<boolean>, disabled: KeyStringValue<boolean>) {
        if (possibleData) {
            if (actived != null) {
                return actived[possibleData.objectId] != true
            } else if (disabled) {
                return disabled[possibleData.objectId] == true
            }
        }
        return false
    }

    clickMostraNonAttivi() {
        this.showNotActived = !this.showNotActived;
    }



}
