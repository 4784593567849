import * as Parse from 'parse';
import {className} from "./Models";

export class SegnaliTlcCircuitiParse extends Parse.Object {
    public static CLASS_NAME = className.segnaliTlcCircuiti;

    constructor() {
        super(SegnaliTlcCircuitiParse.CLASS_NAME);
    }

    public set objectId(value) {
        super.set('objectId', value);
    }

    public get objectId() {
        return super.get('objectId');
    }

    public set updatedAt(value) {
        super.set('updatedAt', value);
    }

    public get updatedAt() {
        return super.get('updatedAt');
    }

    public set createdAt(value) {
        super.set('createdAt', value);
    }

    public get createdAt() {
        return super.get('createdAt');
    }

    public set alimentazioneEsterna(value) {
        super.set('alimentazioneEsterna', value);
    }

    public get alimentazioneEsterna(): boolean {
        return super.get('alimentazioneEsterna');
    }

    public set batteriaScarica(value) {
        super.set('batteriaScarica', value);
    }

    public get batteriaScarica(): boolean {
        return super.get('batteriaScarica');
    }

    public set circuito(value) {
        super.set('circuito', value);
    }

    public get circuito(): Parse.Object<any> {
        return super.get('circuito');
    }

    public set comunicazioneAnalizzatore(value) {
        super.set('comunicazioneAnalizzatore', value);
    }

    public get comunicazioneAnalizzatore(): boolean {
        return super.get('comunicazioneAnalizzatore');
    }

    public set comunicazioneRTC(value) {
        super.set('comunicazioneRTC', value);
    }

    public get comunicazioneRTC(): boolean {
        return super.get('comunicazioneRTC');
    }

    public set crepuscolare(value) {
        super.set('crepuscolare', value);
    }

    public get crepuscolare(): boolean {
        return super.get('crepuscolare');
    }

    public set differenziale(value) {
        super.set('differenziale', value);
    }

    public get differenziale(): boolean {
        return super.get('differenziale');
    }

    public set magnetoTermico1(value) {
        super.set('magnetoTermico1', value);
    }

    public get magnetoTermico1(): boolean {
        return super.get('magnetoTermico1');
    }

    public set magnetoTermico2(value) {
        super.set('magnetoTermico2', value);
    }

    public get magnetoTermico2(): boolean {
        return super.get('magnetoTermico2');
    }

    public set magnetoTermico3(value) {
        super.set('magnetoTermico3', value);
    }

    public get magnetoTermico3(): boolean {
        return super.get('magnetoTermico3');
    }

    public set magnetoTermico4(value) {
        super.set('magnetoTermico4', value);
    }

    public get magnetoTermico4(): boolean {
        return super.get('magnetoTermico4');
    }

    public set oraAccensioneAstronomico(value) {
        super.set('oraAccensioneAstronomico', value);
    }

    public get oraAccensioneAstronomico(): number {
        return super.get('oraAccensioneAstronomico');
    }

    public set oraAccensione1(value) {
        super.set('oraAccensione1', value);
    }

    public get oraAccensione1(): number {
        return super.get('oraAccensione1');
    }

    public set oraAccensione2(value) {
        super.set('oraAccensione2', value);
    }

    public get oraAccensione2(): number {
        return super.get('oraAccensione2');
    }

    public set oraAccensione3(value) {
        super.set('oraAccensione3', value);
    }

    public get oraAccensione3(): number {
        return super.get('oraAccensione3');
    }

    public set oraAccensione4(value) {
        super.set('oraAccensione4', value);
    }

    public get oraAccensione4(): number {
        return super.get('oraAccensione4');
    }

    public set oraSpegnimentoAstronomico(value) {
        super.set('oraSpegnimentoAstronomico', value);
    }

    public get oraSpegnimentoAstronomico(): number {
        return super.get('oraSpegnimentoAstronomico');
    }

    public set oraSpegnimento1(value) {
        super.set('oraSpegnimento1', value);
    }

    public get oraSpegnimento1(): number {
        return super.get('oraSpegnimento1');
    }

    public set oraSpegnimento2(value) {
        super.set('oraSpegnimento2', value);
    }

    public get oraSpegnimento2(): number {
        return super.get('oraSpegnimento2');
    }

    public set oraSpegnimento3(value) {
        super.set('oraSpegnimento3', value);
    }

    public get oraSpegnimento3(): number {
        return super.get('oraSpegnimento3');
    }

    public set oraSpegnimento4(value) {
        super.set('oraSpegnimento4', value);
    }

    public get oraSpegnimento4(): number {
        return super.get('oraSpegnimento4');
    }

    public set presenzaRete(value) {
        super.set('presenzaRete', value);
    }

    public get presenzaRete(): boolean {
        return super.get('presenzaRete');
    }

    public set progetto(value) {
        super.set('progetto', value);
    }

    public get progetto() {
        return super.get('progetto');
    }

    public set sportelloAperto(value) {
        super.set('sportelloAperto', value);
    }

    public get sportelloAperto(): boolean {
        return super.get('sportelloAperto');
    }

    public set teleruttore1(value) {
        super.set('teleruttore1', value);
    }

    public get teleruttore1(): boolean {
        return super.get('teleruttore1');
    }

    public set teleruttore2(value) {
        super.set('teleruttore2', value);
    }

    public get teleruttore2(): boolean {
        return super.get('teleruttore2');
    }

    public set teleruttore3(value) {
        super.set('teleruttore3', value);
    }

    public get teleruttore3(): boolean {
        return super.get('teleruttore3');
    }

    public set teleruttore4(value) {
        super.set('teleruttore4', value);
    }

    public get teleruttore4(): boolean {
        return super.get('teleruttore4');
    }


    public get query(): Parse.Query<SegnaliTlcCircuitiParse> {
        return new Parse.Query(SegnaliTlcCircuitiParse)
    }
}

Parse.Object.registerSubclass(SegnaliTlcCircuitiParse.CLASS_NAME, SegnaliTlcCircuitiParse);
