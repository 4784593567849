<mat-expansion-panel>
    <mat-expansion-panel-header>
        <mat-icon *ngIf="titleCardIcon.iconPath;else iconMaterial" [svgIcon]="titleCardIcon.icon" class="img-icon-M fill-color-text"></mat-icon>
        <ng-template #iconMaterial>
            <mat-icon>{{titleCardIcon.icon}}</mat-icon>
        </ng-template>
        <span style="align-self: center;">{{titleCardIcon.title}}</span>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
        <ng-container *ngFor="let detail of details">
            <div style="font-weight: bold" class="color-default-color-text spacer-width-primary-text">
                {{detail.traduction|translate}}
            </div>
            <div *ngFor="let value of detail.values" [ngStyle]="{overflow:getOverflow(detail)}"
                style="white-space: normal;overflow: auto;text-align: start;" class="spacer-width-text-secondary-text">
                {{value}}
            </div>
        </ng-container>
    </ng-template>
</mat-expansion-panel>