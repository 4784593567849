<svg xmlns="http://www.w3.org/2000/svg" [attr.viewBox]="viewBox" [attr.width]="widthSVG" [attr.height]="heigthSVG" #svg>
    <ng-container *ngFor="let item of layoutItems; let i = index" [ngSwitch]="item.tipo">
        <ng-container *ngSwitchCase="StreetLayoutItemTypes.carreggiata">
            <g
                    [matTooltip]="'streets.roadway' | translate"
                    roadway
                    [height]="item.larghezza"
                    [lanes]="item['numeroCorsie']"
                    [width]="viewBoxWidth+2*padding"
                    [y]="viewBoxHeight - item.larghezza - getYByIndex(i)"
                    [x]="-padding"
            ></g>
        </ng-container>
        <ng-container *ngSwitchCase="StreetLayoutItemTypes.pistaciclabile">
            <g
                    [matTooltip]="'streets.roadway' | translate"
                    roadway
                    [height]="item.larghezza"
                    [lanes]="0"
                    [width]="viewBoxWidth+2*padding"
                    [y]="viewBoxHeight - item.larghezza - getYByIndex(i)"
                    [x]="-padding"
            ></g>
        </ng-container>
        <g *ngSwitchCase="StreetLayoutItemTypes.marciapiede"
           [matTooltip]="'streets.sidewalk' | translate"
           sidewalk
           [height]="item.larghezza"
           [width]="viewBoxWidth+2*padding"
           [y]="viewBoxHeight - item.larghezza - getYByIndex(i)"
           [x]="-padding"
        ></g>
        <g *ngSwitchCase="StreetLayoutItemTypes.parcheggio"
           [matTooltip]="'streets.sidewalk' | translate"
           sidewalk
           [height]="item.larghezza"
           [width]="viewBoxWidth+2*padding"
           [y]="viewBoxHeight - item.larghezza - getYByIndex(i)"
           [x]="-padding"
        ></g>
        <g *ngSwitchCase="StreetLayoutItemTypes.spartitraffico"
           [matTooltip]="'streets.traffic_divider' | translate"
           traffic-divider
           [height]="item.larghezza"
           [width]="viewBoxWidth+2*padding"
           [y]="viewBoxHeight - item.larghezza - getYByIndex(i)"
           [x]="-padding"
        ></g>
        <ng-container *ngIf="generateLabelLayout">
            <!--            <line [attr.x1]="5" [attr.y1]="viewBoxHeight - item.larghezza - getYByIndex(i)"-->
            <!--                  [attr.x2]="5" [attr.y2]="viewBoxHeight - item.larghezza - getYByIndex(i)+item.larghezza"-->
            <!--                  style="stroke:rgb(255,0,0);stroke-width:2"/>-->
            <rect [attr.x]="viewBoxWidth/2-widthRectangle/2"
                  [attr.y]="viewBoxHeight - item.larghezza - getYByIndex(i)+item.larghezza/2-1"
                  [attr.width]="widthRectangle"
                  [attr.height]="heigthRectangle"
                  rx="0.5"
                  fill="rgba(50,50,50,0.9)"/>
            <text [attr.x]="viewBoxWidth/2"
                  [attr.y]="viewBoxHeight - item.larghezza - getYByIndex(i)+item.larghezza/2-0.25"
                  [attr.font-size]="fontSize"
                  fill="white" dominant-baseline="middle" text-anchor="middle">{{getNameLayout(i)}}</text>
        </ng-container>
    </ng-container>
    <ng-container *ngFor="let item of lamps; let i = index">
        <g
                [matTooltip]="('streets.lamp' | translate) + ' ' + (i + 1)"
                lamp
                [outreach]="item.sbraccio"
                [x]="item.origine_x"
                [y]="viewBoxHeight - item.origine_y"
                [rotate]="item.angoloRotazione"
                [round]="isRound(item)"
                (click)="onLampClick(i)"
        >
        </g>
        <ng-container *ngIf="generateLabelLamp">
            <rect [attr.x]="rectCorrdx(item.origine_x-2)"
                  [attr.y]="rectCorrdy(viewBoxHeight - item.origine_y-1,item)" width="2"
                  height="2" rx="0.5"
                  fill="rgba(50,50,50,0.9)"/>
            <text [attr.x]="textCoordx(item.origine_x-1)"
                  [attr.y]="textCoordy(viewBoxHeight - item.origine_y-0,item)"
                  [attr.font-size]="fontSize"
                  fill="white" dominant-baseline="middle" text-anchor="middle">{{getNameLamp(i)}}</text>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="generateMisureInizialAndFinal">
        <rect [attr.x]="-padding"
              [attr.y]="viewBoxHeight"
              width="15"
              height="4" rx="0.5"
              fill="rgba(50,50,50,0.9)"/>
        <text [attr.x]="7.5-padding"
              [attr.y]="viewBoxHeight+2"
              [attr.font-size]="3"
              fill="white" dominant-baseline="middle" text-anchor="middle">{{distanceInizialPosition + ' (m)'}}</text>
        <rect [attr.x]="interdistance+padding-15"
              [attr.y]="viewBoxHeight"
              width="15"
              height="4" rx="0.5"
              fill="rgba(50,50,50,0.9)"/>
        <text [attr.x]="interdistance-7.5"
              [attr.y]="viewBoxHeight+2"
              [attr.font-size]="3"
              fill="white" dominant-baseline="middle"
              text-anchor="middle">{{(interdistance + distanceInizialPosition) + '(m)'}}</text>
    </ng-container>
</svg>
