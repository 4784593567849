import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {ITime} from '../w-clock/w-clock.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {WTimeDialogComponent} from '../w-time-dialog/w-time-dialog.component';
import {Utils} from '../utils';


@Component({
    selector: 'app-material-timepicker',
    styleUrls: ['./material-timepicker.component.scss'],
    templateUrl: './material-timepicker.component.html'
})
export class MaterialTimePickerComponent implements OnInit {
    @Input() label = 'Hour';
    @Input() appearance = 'legacy';
    @Input() userTime: ITime;
    @Input() color: string;
    @Input() revertLabel: string;
    @Input() submitLabel: string;
    @Input() disabled;

    @Output() change: EventEmitter<ITime> = new EventEmitter<ITime>();


    constructor(private dialog: MatDialog) {
    }

    ngOnInit() {
        if (!this.userTime) {
            this.userTime = {
                hour: 0,
                minute: 0,
                meriden: 'PM',
                format: 24
            };
        }
    }

    time(): string {
        if (!this.userTime) {
            return '';
        }
        let meriden = `${this.userTime.meriden}`;
        if (this.userTime.format === 24) {
            meriden = '';
        }

        let hour = `${this.userTime.hour}`;
        if (this.userTime.hour === 24) {
            hour = '00';
        }
        let time;
        if (this.userTime.minute === 0) {
            time = `${hour}:00 ${meriden}`;
        } else if (this.userTime.minute < 10) {
            const tt = '0' + String(this.userTime.minute);
            time = `${hour}:${tt} ${meriden}`;
        } else {
            time = `${hour}:${this.userTime.minute} ${meriden}`;
        }
        return time;
    }

    updateUserTime(e: Event) {
        e.stopPropagation();
        const value: string = (e.target as HTMLInputElement).value;
        const timePartsRegex = /(\d?\d):(\d\d)\s?(AM|PM)?/;
        // if value is not a valid time, it gets ignored
        if (value) {
            const timeParts = value.match(timePartsRegex);
            if (timeParts && timeParts[1] && timeParts[2]) {
                const hours = Number(timeParts[1]);
                const minutes = Number(timeParts[2]);
                if (timeParts[3] === undefined) {
                    if (hours < 24 && minutes < 60) {
                        this.emitChange({
                            format: 24,
                            hour: hours,
                            minute: minutes,
                        });
                    }
                } else if (hours < 13 && minutes < 60) {
                    this.emitChange({
                        format: 12,
                        hour: hours,
                        minute: minutes,
                        meriden: timeParts[3]
                    })
                }
            }
        }
    }

    showPicker() {
        if (!this.disabled) {
            const dialogRef = this.dialog.open(WTimeDialogComponent, {
                data: {
                    time: {
                        hour: this.userTime.hour,
                        minute: this.userTime.minute,
                        meriden: this.userTime.meriden,
                        format: this.userTime.format
                    },
                    color: this.color,
                    revertLabel: this.revertLabel,
                    submitLabel: this.submitLabel
                }
            });
            dialogRef.afterClosed().subscribe((result: ITime | -1) => {
                // result will be update userTime object or -1 or undefined (closed dialog w/o clicking cancel)
                if (result === undefined) {
                    return;
                } else if (result !== -1) {
                    this.userTime = result;
                    const hour = result.hour;
                    const minute = result.minute;
                    const dataEvent = {
                        hour: Utils.formatHour(result.format, hour),
                        minute: minute,
                        meriden: this.userTime.meriden,
                        format: this.userTime.format
                    };
                    this.emitChange(dataEvent);
                }
            });
            return false;
        }
    }

    private emitChange(data) {
        this.change.emit(data);
    }
}
