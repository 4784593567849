import {Component, Inject, OnInit} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";

@Component({
  selector: 'app-info-locked-plan-dialog',
  templateUrl: './info-locked-plan-dialog.component.html',
  styleUrls: ['./info-locked-plan-dialog.component.scss']
})
export class InfoLockedPlanDialogComponent implements OnInit {

  constructor(
      public dialogRef: MatDialogRef<InfoLockedPlanDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: { userSession: string },
  ) {

  }

  ngOnInit() {
  }

}
