import {Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {SegnalazioniParse} from "../../../models/Segnalazioni.Parse";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ImageService} from "../../../providers/services/image.service";
import {AlertService} from "../../../providers/services/alert.service";
import {TranslateService} from "@ngx-translate/core";
import {CommentiSegnalazioniParse} from "../../../models/CommentiSegnalazioni.Parse";
import {arrayIsSet, getItemInArrayByKeyValue, isNotNullOrUndefined} from "../../../models/Models";
import {of, Subscription} from "rxjs";
import {ActivitiesService} from "../../../providers/services/activities.service";
import {ReportStates, UserRoleNumber} from "../../../../config/static-data";
import {UserService} from "../../../providers/services/user.service";
import {map, switchMap} from "rxjs/operators";

@Component({
    selector: 'app-update-segnalazione',
    templateUrl: './update-segnalazione.component.html',
    styleUrls: ['./update-segnalazione.component.scss']
})
export class UpdateSegnalazioneComponent implements OnChanges, OnDestroy {
    @Input() segnalazione: SegnalazioniParse;
    @Input() role: any;
    @Input() currentUser: any;
    @Input() loadingButton: {
        generateReport: boolean,
        closeReport: boolean,
        assignTo: boolean,
        takeChange: boolean,
        searchIntervention: boolean,
        sendComment: boolean
    };
    @Input() disabledAllButton: boolean = false;
    @Output() takeCharge = new EventEmitter();
    @Output() generateReport = new EventEmitter();
    @Output() reopenReport = new EventEmitter();
    @Output() closeReport = new EventEmitter();
    @Output() assignTo = new EventEmitter();
    @Output() sendComment = new EventEmitter();

    private _activitiesScrollableArea: ElementRef<HTMLDivElement>;

    @ViewChild('activitiesScrollableArea') set content(content: ElementRef<HTMLDivElement>|undefined) {
        if (content) {
            this._activitiesScrollableArea = content;
            this.activitiesScrollToBottom();
        }
    }

    public activities: CommentiSegnalazioniParse[];
    activitiesExpanded = false;
    loading = {activity: false, schedeManutenzione: false};
    public formGroup: UntypedFormGroup;
    public subscriptions: Subscription[] = [];

    public valueCommentRadio = {comment: 'comment', file: 'file', foto: 'foto'}

    constructor(private fb: UntypedFormBuilder,
                private alertService: AlertService,
                private translateService: TranslateService,
                private imageService: ImageService,
                private activitiesService: ActivitiesService,
                private userService: UserService
    ) {

        const obj = {}
        obj['typeActivity'] = [{
            value: this.valueCommentRadio.comment,
            disabled: false
        }, []];

        Object.values(this.valueCommentRadio).forEach(key => {
            obj[key] = [{
                value: null,
                disabled: false
            }];
        })

        this.formGroup = this.fb.group(obj)

        const sub = this.formGroup.get('typeActivity').valueChanges.subscribe(val => {
            Object.values(this.valueCommentRadio)
                .filter(key => key != val)
                .forEach(key => {
                    this.formGroup.get(key).clearValidators();
                    this.formGroup.get(key).reset();
                })
            this.formGroup.get(val).setValidators(Validators.required);
            this.formGroup.get(val).updateValueAndValidity({emitEvent: false});
        })
        this.subscriptions.push(sub)
        this.formGroup.get('typeActivity').setValue(this.valueCommentRadio.comment)
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (isNotNullOrUndefined(changes.segnalazione)) {
            this.activities = undefined;
            this.activitiesExpanded = false;
        }
        if (changes.loadingButton != null && changes.loadingButton.currentValue != null && changes.loadingButton.currentValue.sendComment == false) {
            Object.values(this.valueCommentRadio).forEach(key => {
                this.formGroup.get(key).reset();
            })
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe())
    }


    private activitiesScrollToBottom() {
        if (this._activitiesScrollableArea?.nativeElement) {
            this._activitiesScrollableArea.nativeElement.scrollTo({
                top: this._activitiesScrollableArea.nativeElement.scrollHeight,
                behavior: 'smooth',
            })
            
        }
    }

    get typeComment() {
        return this.formGroup.get('typeActivity').value
    }

    onLoadImage(files: File[], label: 'file' | 'foto') {
        try {
            const fileUploadControl = files[0];
            // const loaderId = this.loaderService.addLoader();
            if (fileUploadControl) {
                const file = fileUploadControl;
                const name = label;
                const reader = new FileReader();
                reader.onloadend = (e: ProgressEvent) => {
                    const base64 = (e.target as FileReader).result as any;
                    if (label == 'foto') {
                        this.imageService.compressImage(base64).then(compressed => {
                            const base64Compressa: string = compressed as string;
                            this.formGroup.get(label).setValue({
                                ...this.imageService.getFormValue(base64Compressa, name),
                                fileUploadControl
                            });
                        });
                    } else {
                        this.formGroup.get(label).setValue({
                            name: name,
                            file: base64,
                            url: null,
                            fileUploadControl
                        })
                    }

                };
                reader.readAsDataURL(file);
            } else {
                this.alertService.error(this.translateService.instant('load_correct_file'));
            }
        } catch (e) {
            this.alertService.error(this.translateService.instant('error') + ' ' + e.message);
        }

    }

    deleteSavedFile(label) {
        if (this.formGroup.get(label)) {
            this.formGroup.get(label).reset()
        }
    }


    getActives() {
        if (arrayIsSet(this.activities)) {
            return of(this.activities)
        } else {
            return this.activitiesService.getActivitiesOfReportObs(this.segnalazione)
                .pipe(
                    switchMap((activities) => {
                        const objUser = []
                        const getId = (item) => item.id;
                        activities.forEach(activity => {
                            if (activity.objectUser) {
                                const id = getId(activity.objectUser)
                                if (!isNotNullOrUndefined(getItemInArrayByKeyValue(objUser, id))) {
                                    objUser.push(id)
                                }
                            }
                        })
                        if (arrayIsSet(objUser)) {
                            return this.userService.getUserDetail(objUser)
                                .pipe(
                                    map(userDetail => {
                                        userDetail.forEach(user => {
                                            const index = activities.findIndex(activity => {
                                                if (activity.objectUser) {
                                                    const id = getId(activity.objectUser)
                                                    return id == getId(user)
                                                } else {
                                                    return false
                                                }
                                            })
                                            if (index >= 0) {
                                                activities[index].objectUser = user;
                                            }
                                        })
                                        return activities;
                                    }))
                        } else {
                            return of(activities)
                        }
                    })
                )
        }
    }

    onOpenActivities() {
        this.loading.activity = true;
        this.activitiesExpanded = true;
        this.getActives().subscribe(activies => {
            this.activities = activies;
            this.loading.activity = false;
            setTimeout(() => {
                this.activitiesScrollToBottom();
            });
        }, error => {
            this.loading.activity = false
            this.alertService.error(error.message)
        });        
    }

    get visualizedPresaInCarico() {
        return this.segnalazione.stato == ReportStates.APERTO && (this.role == UserRoleNumber.GESTORE || this.role == UserRoleNumber.OPERATORE || this.role == UserRoleNumber.INSTALLATORE)
    }

    get visualizedAssengaA() {
        return this.role == UserRoleNumber.GESTORE && (
            this.segnalazione.stato == ReportStates.APERTO
            || this.segnalazione.stato == ReportStates.INCARICO
            || this.segnalazione.stato == ReportStates.ASSEGNATOA
            || this.segnalazione.stato == ReportStates.INTERVENTO
            || this.segnalazione.stato == ReportStates.MATERIALE
        )
    }


    get visualizedGeneraReport() {
        return isNotNullOrUndefined(this.segnalazione.presaInCaricoDa)
            && this.segnalazione.presaInCaricoDa.id == this.currentUser.id
            && (
                this.role == UserRoleNumber.GESTORE
                || this.role == UserRoleNumber.OPERATORE
                || this.role == UserRoleNumber.INSTALLATORE
            )
            && (
                this.segnalazione.stato == ReportStates.APERTO
                || this.segnalazione.stato == ReportStates.INCARICO
                || this.segnalazione.stato == ReportStates.ASSEGNATOA
                || this.segnalazione.stato == ReportStates.INTERVENTO
                || this.segnalazione.stato == ReportStates.MATERIALE
            )
    }

    get visualizedChiudiSegnalazione() {
        return this.role == UserRoleNumber.GESTORE && (this.segnalazione.stato == ReportStates.COMPLETATO)
    }


    get visualizedRichiediIntervento() {
        return this.visualizedChiudiSegnalazione
    }

    clickCloseReport() {
        if (!this.disabledAllButton && !this.loadingButton.closeReport) {
            this.closeReport.emit({close: true, report: this.segnalazione})
        }
    }

    clickSearchIntervention() {
        if (!this.disabledAllButton && !this.loadingButton.searchIntervention) {
            this.reopenReport.emit({reopen: true, report: this.segnalazione})
        }
    }

    clickTakeCharge() {
        if (!this.disabledAllButton && !this.loadingButton.takeChange) {
            this.takeCharge.emit({takeCharge: true, report: this.segnalazione})
        }
    }

    clickGenerateReport() {
        if (!this.disabledAllButton && !this.loadingButton.generateReport) {
            this.generateReport.emit({generateReport: true, report: this.segnalazione})
        }
    }

    clickAssignTo() {
        if (!this.disabledAllButton && !this.loadingButton.assignTo) {
            this.assignTo.emit({assignTo: true, report: this.segnalazione})
        }
    }

    clickSendComment() {
        if (!this.disabledAllButton && !this.loadingButton.sendComment) {
            const typeActivity = this.formGroup.get('typeActivity').value
            if (typeActivity === this.valueCommentRadio.comment) {
                this.sendComment.next({comment: this.formGroup.get('comment').value, report: this.segnalazione})
            } else if (typeActivity === this.valueCommentRadio.file) {
                this.sendComment.next({file: this.formGroup.get('file').value, report: this.segnalazione})
            } else if (typeActivity === this.valueCommentRadio.foto) {
                this.sendComment.next({foto: this.formGroup.get('foto').value, report: this.segnalazione})
            }
        }

    }
}
