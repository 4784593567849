import * as Parse from 'parse';
import {allPropertyClass} from "./Models";


export class IoTDevicesParse extends Parse.Object {
    public static CLASS_NAME = 'IoTDevices';

    constructor() {
        super(IoTDevicesParse.CLASS_NAME);
    }

    public set objectId(value) {
        super.set('objectId', value);
    }

    public get objectId() {
        return super.get('objectId');
    }

    public set updatedAt(value) {
        super.set('updatedAt', value);
    }

    public get updatedAt() {
        return super.get('updatedAt');
    }

    public set createdAt(value) {
        super.set('createdAt', value);
    }

    public get createdAt() {
        return super.get('createdAt');
    }

    public set ACL(value) {
        super.set('ACL', value);
    }

    public get ACL() {
        return super.get('ACL');
    }

    public set autorizzatoLuxData(value) {
        super.set('autorizzatoLuxData', value);
    }

    public get autorizzatoLuxData() {
        return super.get('autorizzatoLuxData');
    }

    public set circuito(value) {
        super.set('circuito', value);
    }

    public get circuito() {
        return super.get('circuito');
    }

    public set configVersion(value) {
        super.set('configVersion', value);
    }

    public get configVersion() {
        return super.get('configVersion');
    }

    public set modello(value) {
        super.set('modello', value);
    }

    public get modello() {
        return super.get('modello');
    }

    public set fornitore(value) {
        super.set('fornitore', value);
    }

    public get fornitore() {
        return super.get('fornitore');
    }

    public set hardwareVersion(value) {
        super.set('hardwareVersion', value);
    }

    public get hardwareVersion() {
        return super.get('hardwareVersion');
    }

    get allProperty(): string[] {
        return allPropertyClass(IoTDevicesParse)
            .filter(key => key != 'query')
    }

    public hasProperty(key: string): boolean {
        return this.allProperty.includes(key);
    }

    public set ipAddress(value) {
        super.set('ipAddress', value);
    }

    public get ipAddress() {
        return super.get('ipAddress');
    }


    public set modbusRTUId(value) {
        super.set('modbusRTUId', value);
    }

    public get modbusRTUId() {
        return super.get('modbusRTUId');
    }

    public set meter(value) {
        super.set('meter', value);
    }

    public get meter() {
        return super.get('meter');
    }

    public set mainVersion(value) {
        super.set('mainVersion', value);
    }

    public get mainVersion() {
        return super.get('mainVersion');
    }

    public set methodsVersion(value) {
        super.set('methodsVersion', value);
    }

    public get methodsVersion() {
        return super.get('methodsVersion');
    }

    public set nodo(value) {
        super.set('nodo', value);
    }

    public get nodo() {
        return super.get('nodo');
    }

    public set osVersion(value) {
        super.set('osVersion', value);
    }

    public get osVersion() {
        return super.get('osVersion');
    }

    public set organizzazione(value) {
        super.set('organizzazione', value);
    }

    public get organizzazione() {
        return super.get('organizzazione');
    }


    public set progetto(value) {
        super.set('progetto', value);
    }

    public get progetto() {
        return super.get('progetto');
    }

    public set puntoLuce(value) {
        super.set('puntoLuce', value);
    }

    public get puntoLuce() {
        return super.get('puntoLuce');
    }

    public set pairCode(value) {
        super.set('pairCode', value);
    }

    public get pairCode() {
        return super.get('pairCode');
    }

    public set scriptVersion(value) {
        super.set('scriptVersion', value);
    }

    public get scriptVersion() {
        return super.get('scriptVersion');
    }

    public set seriale(value) {
        super.set('seriale', value);
    }

    public get seriale() {
        return super.get('seriale');
    }


    public set simNumber(value) {
        super.set('simNumber', value);
    }

    public get simNumber() {
        return super.get('simNumber');
    }

    public set valoreInizialeLetturaEnergiaAttiva(value) {
        super.set('valoreInizialeLetturaEnergiaAttiva', value);
    }

    public get valoreInizialeLetturaEnergiaAttiva() {
        return super.get('valoreInizialeLetturaEnergiaAttiva');
    }

    public set valoreInizialeLetturaEnergiaReattiva(value) {
        super.set('valoreInizialeLetturaEnergiaReattiva', value);
    }

    public get valoreInizialeLetturaEnergiaReattiva() {
        return super.get('valoreInizialeLetturaEnergiaReattiva');
    }

    public set versioneProtocolloLM(value) {
        super.set('versioneProtocolloLM', value);
    }

    public get versioneProtocolloLM() {
        return super.get('versioneProtocolloLM');
    }

    public set vpnIp(value) {
        super.set('vpnIp', value);
    }

    public get vpnIp() {
        return super.get('vpnIp');
    }


}

Parse.Object.registerSubclass(IoTDevicesParse.CLASS_NAME, IoTDevicesParse);
