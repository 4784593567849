import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {ordinamentoEcampiTraduzioni} from "../../../models/ordinamentoEcampiTraduzioni";
import {ScaleColorService} from "../../../providers/services/scale-color.service";
import {CircuitScaleColorService} from "../../../providers/services/circuit-scale-color.service";
import {dataForm} from '../../confirm-delete/select-or-create/select-or-create.component';
import {BehaviorSubject, combineLatest, Subscription} from "rxjs";
import {debounceTime, filter, startWith} from "rxjs/operators";
import {stringIsSet} from "../../../models/Models";

@Component({
    selector: 'app-circuit-scale-color',
    templateUrl: './circuit-scale-color.component.html',
    styleUrls: ['./circuit-scale-color.component.scss']
})
export class CircuitScaleColorComponent implements OnInit, OnChanges, OnDestroy {

    public filterForm: UntypedFormGroup;
    pathForTranslate = "dashboard_sidenav.Circuiti.";
    displayedColumns: string[] = ['color', 'key'];
    public elementiScala;
    circuitAutocompleteFields: dataForm[] = [];
    @Output() campoScalaCaloreCircuitoCambiato = new EventEmitter();
    @Output() fieldLabelCircuitoChange = new EventEmitter();
    private circuitiEmit;
    private circuiti$;
    private subscriptions = new Subscription();

    @Input()
    public circuiti;

    // public elementiScalaArray;
    get elementiScalaArray() {
        return this.circuitScaleColor.scalaColoreArray
    }

    constructor(
        private scaleColor: ScaleColorService,
        private circuitScaleColor: CircuitScaleColorService,
        private fb: UntypedFormBuilder,
    ) {
        this.circuitiEmit = new BehaviorSubject(this.circuiti);
        this.circuiti$ = this.circuitiEmit.asObservable();
        let campiSelectTemp = [];
        Object.keys(this.filterOrdinamento().Circuiti).forEach((key) => {
            if (key != 'location') {
                campiSelectTemp.push({key: key, sortingValue: ordinamentoEcampiTraduzioni.Circuiti[key].sortingValue});
            }
        });
        campiSelectTemp.sort((a, b) => a.sortingValue - b.sortingValue);
        this.circuitAutocompleteFields = campiSelectTemp.map(({key}) => ({
            traduction: this.pathForTranslate + key + '.title',
            html: key,
            valueForm: key,
        }));
        const valueForm = {};
        valueForm['scalaColore'] = [{value: this.campoScalaColore, disabled: false}];
        this.filterForm = this.fb.group(valueForm);
        this.subscriptions.add(
            combineLatest(
                [
                    this.filterForm.get('scalaColore').valueChanges.pipe(
                        startWith(this.filterForm.get('scalaColore').value),
                        filter(key => {
                            let index = 0;
                            if (stringIsSet(key)) {
                                index = this.circuitAutocompleteFields.findIndex(v => (v.valueForm == key || v.html == key))
                            }
                            return index >= 0
                        })
                    ),
                    this.circuiti$
                ]
            ).subscribe(values => {
                const key = stringIsSet(values[0]) ? values[0] : undefined;
                const circuiti = values[1];
                this.circuitScaleColor.calcolaScalaColore(key, circuiti);
                this.campoScalaCaloreCircuitoCambiato.emit(key);
            })
        )
    }

    unsteValue() {
        this.filterForm.get('scalaColore').reset();
    }

    public get campoScalaColore() {
        return this.circuitScaleColor.campoScalaColore;
    }

    public set campoScalaColore(value) {
        this.circuitScaleColor.campoScalaColore = value;
    }


    public get tipoDato() {
        return this.circuitScaleColor.tipoDato;
    }


    private filterOrdinamento(): {
        PuntiLuce,
        PictureCameraPoint,
        Circuiti
    } {
        let newObject: {
            PuntiLuce,
            PictureCameraPoint,
            Circuiti
        } = {
            PuntiLuce: null,
            PictureCameraPoint: null,
            Circuiti: null
        };
        Object.keys(ordinamentoEcampiTraduzioni).forEach((classe) => {
            let field = {};
            Object.keys(ordinamentoEcampiTraduzioni[classe]).forEach((key) => {
                if (ordinamentoEcampiTraduzioni[classe][key].showInForm||ordinamentoEcampiTraduzioni[classe][key].showInLabel) {
                    field[key] = ordinamentoEcampiTraduzioni[classe][key];
                }
            });
            newObject[classe] = field;
        });
        return newObject;
    }

    ngOnInit(): void {
        this.circuitScaleColor.circuitiInMappa = this.circuiti;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.circuiti) {
            this.circuitiEmit.next(this.circuiti)
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
