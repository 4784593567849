import {BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule} from '@angular/platform-browser';
import {NgModule, LOCALE_ID} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatLegacySnackBarModule as MatSnackBarModule} from '@angular/material/legacy-snack-bar';
import {LayoutModule} from './layout/layout.module';
import {WidgetsModule} from './widgets/widgets.module';
import {CommonModule, registerLocaleData, DatePipe} from '@angular/common';
// import {AgmCoreModule, GoogleMapsAPIWrapper} from "@agm/core";
import {ForErrorComponent} from './components/for-error/for-error.component';
import {environment} from 'src/environments/environment';
import localeIT from '@angular/common/locales/it'
import localeES from '@angular/common/locales/es'
import localeCA from '@angular/common/locales/ca'
import localeEN from '@angular/common/locales/en'
import localeFR from '@angular/common/locales/fr'
import {MatLegacyDialogModule as MatDialogModule} from "@angular/material/legacy-dialog";
import {PopUpExportModule} from "./components/pop-up-export/pop-up-export.module";
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from "@angular/material/legacy-progress-spinner";
import {FormsModule} from "@angular/forms";
import {PopUpInfoModule} from './components/pop-up-info/pop-up-info.module';
import {ConfirmDeleteModule} from "./components/confirm-delete/confirm-delete.module";
import {PopUpMoveInFolderModule} from "./components/pop-up-move-in-folder/pop-up-move-in-folder.module";
import {PopUpCreateNewModule} from "./components/pop-up-create-new/pop-up-create-new.module";
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {MatLegacyProgressBarModule as MatProgressBarModule} from "@angular/material/legacy-progress-bar";
import {ReportsModule} from "./pages-modules/reports/reports.module";
import {PipesModule} from "./providers/pipes/pipes.module";
import {GoogleMapsModule} from "@angular/google-maps";
//todo aggiornanre mappa
// todo togliere il reports module, necessario solo per aprire il dialog genera reports.
const HttpLoaderFactory = (http: HttpClient) => {
    return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
};

registerLocaleData(localeIT)
registerLocaleData(localeEN)
registerLocaleData(localeES)
registerLocaleData(localeCA)
registerLocaleData(localeFR)

@NgModule({
    declarations: [
        AppComponent,
        ForErrorComponent,
    ],
    imports: [
        CommonModule,
        LayoutModule,
        MatIconModule,
        MatSnackBarModule,
        GoogleMapsModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        MatSidenavModule,
        WidgetsModule,
        MatListModule,
        PopUpExportModule,
        PopUpInfoModule,
        PopUpCreateNewModule,
        PopUpMoveInFolderModule,
        ConfirmDeleteModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        FormsModule,
        HammerModule,
        MatCardModule,
        MatProgressBarModule,
        ReportsModule,
        PipesModule
    ],
    providers: [
        // GoogleMapsAPIWrapper,
        DatePipe,
        {
            provide: LOCALE_ID,
            useValue: 'it-IT'
        },
    ],
    bootstrap: [AppComponent],
    exports: []
})
export class AppModule {
}
