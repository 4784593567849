<div class="data" style="text-align: center;margin-top: -30px;">
    <H4>
        <img class="icon-dimension" src="/assets/icon/dialogCompnent/ask.png">
    </H4>
</div>
<div class="flex-container">
    <div style="width: 100%;text-align: center">
        <h5>{{'selectDelete'|translate}}</h5>
    </div>
    <button mat-mini-fab color="primary" [mat-dialog-close]="nomiClasse.puntiLuce "
            [disabled]="data.disabledButton.lightPoint">
        <img src="/assets/icon/dialogCompnent/lampione.svg" style="max-height: 24px;min-height: 24px;" alt="lampione">
    </button>
    <button mat-mini-fab color="primary" [mat-dialog-close]="nomiClasse.circuiti"
            [disabled]="data.disabledButton.circuits">
        <img src="/assets/icon/dialogCompnent/quadro.png" style="max-height: 24px;min-height: 24px;"
             alt="quadro elettrico">
    </button>
    <button mat-mini-fab color="primary" [mat-dialog-close]="nomiClasse.arredoUrbano"
            [disabled]="data.disabledButton.arredoUrbano">
        <img src="/assets/icon/dashboard/segnaleStradale.svg"
             style="max-height: 24px;min-height: 24px;filter: invert(1)"
             alt="arredo urbano">
    </button>
    <button mat-mini-fab color="warn" [mat-dialog-close]="false">
        <mat-icon>close</mat-icon>
    </button>
</div>