import {Injectable} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {forkJoin, Observable} from "rxjs";
import {hunaParseFileName} from "../../models/Models";

// const EXIF = require("exif-js");

@Injectable({
    providedIn: 'root'
})
export class ImageService {

    constructor(private _sanitizer: DomSanitizer) {
    }

    private maxDimension: number = 2000;

    public compressImage(src, blob?: false): Promise<string>
    public compressImage(src, blob: true): Promise<{ string: string, blob: Blob }>
    public compressImage(src, blob = false): Promise<string|{ string: string, blob: Blob }> {
        return new Promise((res, rej) => {
            const img = new Image();
            img.src = src;
            img.onload = () => {
                const elem = document.createElement('canvas');
                if (img.width > this.maxDimension || img.height > this.maxDimension) {
                    if (img.width > img.height) {
                        const ratio = img.height / img.width;
                        elem.width = this.maxDimension;
                        elem.height = this.maxDimension * ratio;
                    } else {
                        const ratio = img.width / img.height;
                        elem.width = this.maxDimension * ratio;
                        elem.height = this.maxDimension;
                    }
                } else {
                    elem.width = img.width;
                    elem.height = img.height;
                }
                const ctx = elem.getContext('2d');
                ctx.drawImage(img, 0, 0, elem.width, elem.height);
                const data = ctx.canvas.toDataURL('image/jpeg', 0.7);
                if (blob) {
                    ctx.canvas.toBlob((blob) => {
                        res({ string: data, blob })
                    }, 'image/jpeg', 0.7);
                } else {
                    res(data);
                }
            };
            img.onerror = error => rej(error);
        })
    }

    getFormValue(base64Compressa, name) {
        return {
            url: this._sanitizer.bypassSecurityTrustUrl(base64Compressa),
            file: base64Compressa,
            nome: name
        }
    }


    getImageByFile(file: File): Observable<string> {
        return new Observable(subscriber => {
            const reader = new FileReader();
            reader.onloadend = (e: ProgressEvent) => {
                const base64 = (e.target as FileReader).result as any;
                this.compressImage(base64).then(baseCompressed => {
                    subscriber.next(baseCompressed)
                    subscriber.complete();
                    subscriber.unsubscribe();
                }).catch(e => {
                    subscriber.error(e)
                    subscriber.complete();
                    subscriber.unsubscribe();
                })
            };
            reader.onerror = (e) => {
                subscriber.error(e);
                subscriber.complete();
                subscriber.unsubscribe();
            }
            reader.readAsDataURL(file);
        })

    }

    getImagesByFiles(files: File[]): Observable<{ image: string, name: string }[]> {
        return new Observable(subscriber => {
            const imagesCompressed: Observable<string>[] = files.map(file => this.getImageByFile(file))
            forkJoin(imagesCompressed).subscribe(imagesCompressed => {
                subscriber.next(imagesCompressed.map((image, index) => {
                    return {image: image, name: hunaParseFileName(files[index].name), pureFile: files[index]}
                }))
                subscriber.complete();
                subscriber.unsubscribe();
            }, error => {
                subscriber.error(error);
                subscriber.complete();
                subscriber.unsubscribe();
            })
        })

    }

    // getLocationImage(url: string): Observable<any> {
    //     return new Observable(subscriber => {
    //         const getData = (value) => {
    //             console.log(value);
    //             const allMetaData = EXIF.getAllTags(this);
    //             subscriber.next({value, allMetaData});
    //         }
    //         EXIF.getData(url, getData)
    //     })
    // }
}
