import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {UntypedFormGroup} from "@angular/forms";
import * as Parse from 'parse';
import {LoaderService} from "../../../providers/services/loader.service";
import {ActivityFormService} from "../../../providers/forms/activity-form.service";
import {ReportStates} from "../../../../config/static-data";
import {of, Subject, Subscription, throwError} from "rxjs";
import {
    formFiledMaintenanceFormContolName, MaintenanceService,
    typeFormFieldMaintenance
} from "../../../providers/services/maintenance.service";
import {SchedaManutenzioneParse} from "../../../models/SchedaManutenzione.Parse";
import {BozzaSchedaManutenzioneCompilata} from "../../../providers/services/local-sotrage.service";
import {arrayIsSet, className, isNotNullOrUndefined} from "../../../models/Models";
import {AlertService} from "../../../providers/services/alert.service";
import {map, switchMap} from "rxjs/operators";


@Component({
    selector: 'app-new-report-comment-dialog',
    templateUrl: './new-report-comment-dialog.component.html'
})
export class NewReportCommentDialogComponent implements OnInit, OnDestroy {

    public activityForm: UntypedFormGroup;
    public readonly states = ReportStates;
    public saving = false;
    public photo = false;
    public file = false;

    public loadImage = {progress: 0, show: false}

    get bozzeSchedePrecompilate(): BozzaSchedaManutenzioneCompilata[] {
        return this.maintenanceService.getRawValueBozzaSchedaManutenzioneBySchedaManutenzioneId()
    }

    // heigthExpand = 'calc(100vh - 182px)';
    // heigthCompressed = 'calc(100vh - 282px)';

    private mobileCssPosition = 'fab';
    private desktopCssPosition = 'centerPositionButtonSave';

    get cssPositionButton() {
        if (this.visualizedMultiSelect) {
            return this.mobileCssPosition
        } else {
            return this.desktopCssPosition
        }
    }

    private subscriptions: Subscription[] = []

    //   public typeReport: 'comment' | 'photo' | 'file' | 'schedaManutenzione' = 'comment'


    constructor(
        public dialogRef: MatDialogRef<NewReportCommentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { reportObjectId: string, userSession: string, myRole, tuttiAbbonamenti, schedeManutenzione: SchedaManutenzioneParse[] },
        private loaderService: LoaderService,
        private activityFormService: ActivityFormService,
        private maintenanceService: MaintenanceService,
        private alertService: AlertService
    ) {
        this.loadData();
        let subscription = this.activityForm.get('typeReport').valueChanges.subscribe((typeReport) => {
            this.disable.schedaManutenzione = false;
            this.onChangeComment(typeReport);
        })
        this.subscriptions.push(subscription)
    }

    ngOnInit() {

    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe())
    }

    get visualizedMultiSelect() {
        return window.innerWidth < 500
    }

    get typeReport() {
        return this.activityForm.get('typeReport').value
    }

    private async loadData() {
        let formValues;
        if (arrayIsSet(this.data.schedeManutenzione)) {
            formValues = {typeReport: 'schedaManutenzione'}
        }
        this.activityForm = this.activityFormService.getCreateNewReportCommentForm(formValues);

    }


    public async closeDialog() {
        this.dialogRef.close();

    }

    onLoadImage(files: File[], label) {
        const fileUploadControl = files[0];
        const loaderId = this.loaderService.addLoader();
        if (fileUploadControl) {
            const file = fileUploadControl;
            const name = fileUploadControl.name;
            const reader = new FileReader();
            reader.onloadend = (e: ProgressEvent) => {
                const base64 = (e.target as FileReader).result as any;
                const parseFile = new Parse.File(name, {base64: base64});
                return parseFile.save().then(() => {
                    this.activityForm.get(label).setValue({
                        name: parseFile.name(),
                        url: parseFile.url(),
                        file: parseFile
                    });
                    this.loaderService.removeLoader(loaderId);
                });

            };
            reader.readAsDataURL(file);
        }
    }

    async deleteSavedFile(label) {
        this.activityForm.get(label).setValue(null);
    }

    load = {schedeManutenzione: false};


    onChangeComment(type) {
        // if (type == 'schedaManutenzione') {
        // }
        this.activityForm.get('comment').reset();
        if (type != 'compiledScheduleMaintenance') {
            this.activityForm.get('compiledScheduleMaintenance').reset(null, {emitEvent: false})
        }
    }

    save() {
        if (this.typeReport != 'schedaManutenzione') {
            this.saving = true;
            const values = {...this.activityForm.value};
            if (isNotNullOrUndefined(values.typeReport)) {
                delete values.typeReport;
            }
            //Richiede intervento generando un nuovo report
            this.dialogRef.close({
                typeReport: this.typeReport,
                reportObjectId: this.data.reportObjectId,
                value: values
            });
        } else {
            // console.log("scheda",this.activityForm.get('compiledScheduleMaintenance').value)
            this.dialogRef.close(this.activityForm.get('compiledScheduleMaintenance').value)
        }

        // Richiama la funzione   salvaNuovoReportSegnalazione

    }


    public saveSchedaCompilata = new Subject()
    public disable = {schedaManutenzione: false};

    saveSchedaManutenzione(event: { elementToSave: any, valuesSchedaManutenzione: any, keys: formFiledMaintenanceFormContolName[], maintenanceScheduleReference: SchedaManutenzioneParse, saveOnDatabase: boolean }) {
        this.loadImage.progress = 0;
        setTimeout(() => {
            if (this.loadImage.progress < 3) {
                this.loadImage.progress = 3;
            }
        }, 100)
        this.loadImage.show = true;
        const saveValue = (saveOnDatabase: boolean) => {
            if (saveOnDatabase) {
                this.activityForm.get('compiledScheduleMaintenance').setValue({
                    typeReport: this.typeReport,
                    event,
                    nuovoStato: this.activityForm.get('nuovoStato').value,
                    reportObjectId: this.data.reportObjectId,
                })
                this.save();
            }
        }
        this.disable.schedaManutenzione = true;
        let localEvent;
        if (event != null) {
            localEvent = {
                ...event,
                elementToSave: {className: className.segnalazioni, objectId: this.data.reportObjectId}
            }
        }

        this.maintenanceService.saveLocalObject(localEvent).pipe(
            switchMap(valueFormWithTemporaryFile => {
                if (valueFormWithTemporaryFile.error) {
                    return throwError(valueFormWithTemporaryFile.error);
                }
                return of(valueFormWithTemporaryFile)
            })
        )
            .subscribe(valueFormWithTemporaryFile => {
                    if (arrayIsSet(valueFormWithTemporaryFile.valuesForm)) {
                        valueFormWithTemporaryFile.valuesForm.forEach(valWithImage => {
                            if (valWithImage.type === typeFormFieldMaintenance.image) {
                                if (event.valuesSchedaManutenzione[valWithImage.formControlName].file != null) {
                                    event.valuesSchedaManutenzione[valWithImage.formControlName].file = valWithImage.value;
                                }
                            }
                        });
                    }
                    this.disable.schedaManutenzione = !valueFormWithTemporaryFile.finished;
                    this.loadImage.show = !valueFormWithTemporaryFile.finished;
                    this.loadImage.progress = valueFormWithTemporaryFile.progress;
                    saveValue(event.saveOnDatabase && valueFormWithTemporaryFile.finished)
                }, error => {
                    this.alertService.error(error);
                    this.disable.schedaManutenzione = false
                    this.loadImage.show = false;
                    this.loadImage.progress = 0;
                }
            )
    }


    predicateFunctionConvertValue(objectId: string): { className: string, objectId: string } {
        return {className: className.segnalazioni, objectId: objectId};
    }
}
