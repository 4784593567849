import * as Parse from 'parse';

export class SegnalazioniParse extends Parse.Object {
    public static CLASS_NAME = 'Segnalazioni';

    private _presaInCaricaDetail;

    constructor() {
        super(SegnalazioniParse.CLASS_NAME);
    }

    public get objectId() {
        return this.id;
    }

    public set objectId(value) {
        this.id = value;
    }

    public get presaInCaricaDetail() {
        return this._presaInCaricaDetail;
    }

    public set presaInCaricaDetail(value) {
        this._presaInCaricaDetail = value;
    }

    public set ACL(value) {
        super.set('ACL', value);
    }

    public get ACL() {
        return super.get('ACL');
    }

    public set createdAt(value) {
        super.set('createdAt', value);
    }

    public get createdAt(): Date {
        return super.get('createdAt');
    }

    public set updatedAt(value) {
        super.set('updatedAt', value);
    }

    public get updatedAt(): Date {
        return super.get('updatedAt');
    }

    public set circuito(value) {
        super.set('circuito', value);
    }

    public get circuito(): any {
        return super.get('circuito');
    }

    public set codice(value) {
        super.set('codice', value);
    }

    public get codice(): number {
        return super.get('codice');
    }

    public set descrizione(value) {
        super.set('descrizione', value);
    }

    public get descrizione(): string {
        return super.get('descrizione');
    }

    public set foto(value) {
        super.set('foto', value);
    }

    public get foto(): Parse.File {
        return super.get('foto');
    }

    public set nomeAutore(value) {
        super.set('nomeAutore', value);
    }

    public get nomeAutore(): string {
        return super.get('nomeAutore');
    }

    public set oggetto(value) {
        super.set('oggetto', value);
    }

    public get oggetto(): string {
        return super.get('oggetto');
    }

    public set priorita(value) {
        super.set('priorita', value);
    }

    public get priorita(): number {
        return super.get('priorita');
    }

    public set privata(value) {
        super.set('privata', value);
    }

    public get privata(): boolean {
        return super.get('privata');
    }

    public set puntiLuce(value) {
        super.set('puntiLuce', value);
    }

    public get puntiLuce(): any[] {
        return super.get('puntiLuce');
    }

    public set presaInCaricoDa(value) {
        super.set('presaInCaricoDa', value);
    }

    public get presaInCaricoDa(): Parse.User {
        return super.get('presaInCaricoDa');
    }

    public set progetto(value) {
        super.set('progetto', value);
    }

    public get progetto(): Parse.Object<any> {
        return super.get('progetto');
    }

    public set stato(value) {
        super.set('stato', value);
    }

    public get stato(): string {
        return super.get('stato');
    }

    public set tipologiaIntervento(value) {
        super.set('tipologiaIntervento', value);
    }

    public get tipologiaIntervento(): string {
        return super.get('tipologiaIntervento');
    }

    public set titolo(value) {
        super.set('titolo', value);
    }

    public get titolo(): string {
        return super.get('titolo');
    }

    public set user(value) {
        super.set('user', value);
    }

    public get user(): Parse.User {
        return super.get('user');
    }

    public set ultimoAggiornamentoDa(value) {
        super.set('ultimoAggiornamentoDa', value);
    }

    public get ultimoAggiornamentoDa(): Parse.User {
        return super.get('ultimoAggiornamentoDa');
    }

    public get query(): Parse.Query<SegnalazioniParse> {
        let query = new Parse.Query(SegnalazioniParse);
        return query;
    }
}

Parse.Object.registerSubclass(SegnalazioniParse.CLASS_NAME, SegnalazioniParse);

