import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormScheduleMaintenanceComponent } from './form-schedule-maintenance/form-schedule-maintenance.component';
import {ScrollingModule} from "@angular/cdk/scrolling";
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {TranslateModule} from "@ngx-translate/core";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import {MatIconModule} from "@angular/material/icon";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatLegacyFormFieldModule as MatFormFieldModule} from "@angular/material/legacy-form-field";
import {MatLegacyInputModule as MatInputModule} from "@angular/material/legacy-input";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {WidgetsModule} from "../../widgets/widgets.module";
import {MatLegacySelectModule as MatSelectModule} from "@angular/material/legacy-select";
import {NgxDropzoneModule} from "ngx-dropzone";
import {MatLegacyListModule as MatListModule} from "@angular/material/legacy-list";
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from "@angular/material/legacy-progress-spinner";
import {PipesModule} from "../../providers/pipes/pipes.module";
import {ClipboardModule} from "@angular/cdk/clipboard";
import {BoxesModule} from "../../boxes/boxes.module";
import {MatLegacyCheckboxModule as MatCheckboxModule} from "@angular/material/legacy-checkbox";
import {MatLegacyTabsModule as MatTabsModule} from "@angular/material/legacy-tabs";
import { UpdateSegnalazioneComponent } from './update-segnalazione/update-segnalazione.component';
import {MatLegacyRadioModule as MatRadioModule} from "@angular/material/legacy-radio";
import {ListElementModule} from "../list-element/list-element.module";
import {MatRippleModule} from "@angular/material/core";
import { ListScheduleSmallComponent } from './list-schedule-small/list-schedule-small.component';
import { FormToCompileScheduleMaintenanceComponent } from './form-to-compile-schedule-maintenance/form-to-compile-schedule-maintenance.component';
import {NgxMaterialTimepickerModule} from "ngx-material-timepicker";
import { FormToFilterComponent } from './form-to-filter/form-to-filter.component';
import {MatLegacyDialogModule as MatDialogModule} from "@angular/material/legacy-dialog";
import {MaterialTimePickerModule} from "../../dependecies/material-time-picker/material-time-picker.module";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatLegacyTooltipModule as MatTooltipModule} from "@angular/material/legacy-tooltip";



@NgModule({
    declarations: [FormScheduleMaintenanceComponent, UpdateSegnalazioneComponent, FormToCompileScheduleMaintenanceComponent, FormToFilterComponent,ListScheduleSmallComponent],
    exports: [
        FormScheduleMaintenanceComponent,
        FormToCompileScheduleMaintenanceComponent,
        FormToFilterComponent,
        ListScheduleSmallComponent
    ],
    imports: [
        CommonModule,
        ScrollingModule,
        MatCardModule,
        TranslateModule,
        MatButtonModule,
        MatIconModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        WidgetsModule,
        MatSelectModule,
        NgxDropzoneModule,
        MatListModule,
        MatProgressSpinnerModule,
        PipesModule,
        ClipboardModule,
        BoxesModule,
        MatCheckboxModule,
        MatTabsModule,
        MatRadioModule,
        ListElementModule,
        MatRippleModule,
        NgxMaterialTimepickerModule,
        FormsModule,
        MatDialogModule,
        MaterialTimePickerModule,
        MatExpansionModule,
        MatTooltipModule,
    ]
})
export class ScheduleMaintenaceUtilitiesModule { }
