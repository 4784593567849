import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListCarichiEsogeniComponent } from './list-carichi-esogeni/list-carichi-esogeni.component';
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import {MatIconModule} from "@angular/material/icon";
import {BoxesModule} from "../../boxes/boxes.module";
import {TranslateModule} from "@ngx-translate/core";
import {ScrollingModule} from "@angular/cdk/scrolling";



@NgModule({
    declarations: [
        ListCarichiEsogeniComponent
    ],
    exports: [
        ListCarichiEsogeniComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        BoxesModule,
        TranslateModule,
        ScrollingModule
    ]
})
export class CaricoEsogenoModule { }
