import {ChangeDetectorRef, Component, OnDestroy, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {Filter, ListAbstract} from "../../../abstract/list-abstract";
import {TranslateService} from "@ngx-translate/core";
import * as Parse from "parse";
import {arrayIsSet, className, listFilter} from "../../../models/Models";
import {AddingTabInComponentService} from "../../../providers/services/adding-tab-in-component.service";
import {UserService} from "src/app/providers/services/user.service";
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {filter, map, startWith} from "rxjs/operators";
import {ProjectService} from "src/app/providers/services/project.service";
import {dataForm} from "../../../components/confirm-delete/select-or-create/select-or-create.component";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";

export enum LocalFilters {
    CIRCUITI = "circuito",
    PUNTI_LUCE = "puntiLuce",
    TELECONTROLLO = "codice",
}

export interface TabsReports {
    objectId: string;
    titolo: string;
    priority;
}

const filterFunction = (
    query: Parse.Query<Parse.Object>,
    model: string,
    filtersActive: {
        label: string;
        items: Filter[];
        type: string;
        code: string;
        value?: any;
    }[]
) => {
    filtersActive.forEach((el) => {
        switch (el.code) {
            case "general":
                const queries = [];
                el.items.forEach((el2) => {
                    const innerQuery = new Parse.Query(Parse.Object.extend(model));
                    switch (el2.type) {
                        case LocalFilters.TELECONTROLLO:
                            queries.push(innerQuery.exists(el2.type));
                            break;
                        case LocalFilters.PUNTI_LUCE:
                            queries.push(innerQuery.exists(el2.type));
                            break;
                        case LocalFilters.CIRCUITI:
                            innerQuery.exists(el2.type);
                            innerQuery.doesNotExist(LocalFilters.TELECONTROLLO);
                            queries.push(innerQuery);
                            break;
                    }
                });
                query = Parse.Query.and(Parse.Query.or(...queries), query);
                break;
            case "priority":
                query.equalTo("priorita", el.value);
                break;
        }
    });
    return query;
};

@Component({
    selector: "app-list-reports",
    templateUrl: "./list-reports.component.html",
    styleUrls: ["./list-reports.component.scss"],
})
// export class ListReportsComponent implements OnInit {
export class ListReportsComponent extends ListAbstract implements OnInit, OnDestroy {
    private project = false;
    public openedReportsList: TabsReports[] = [];
    private cachedUsers = [];
    loadingUsers = new BehaviorSubject(false);
    keysAcceptedFilter: dataForm[] = [
        {
            valueForm: className.circuiti,
            html: className.circuiti,
            traduction: className.circuiti,
            registerValue: className.circuiti,
            color: 'primary'
        },
        {
            valueForm: className.puntiLuce,
            html: className.puntiLuce,
            traduction: className.puntiLuce,
            registerValue: className.puntiLuce,
            color: 'accent'
        },
    ]
    formGroupFiter: UntypedFormGroup;
    private subscriptions = new Subscription();

    constructor(
        private router: Router,
        public translateService: TranslateService,
        protected changeDetection: ChangeDetectorRef,
        private addingTab: AddingTabInComponentService,
        private userService: UserService,
        protected projectService: ProjectService,
        private fb: UntypedFormBuilder
    ) {
        super(
            {
                take: 20,
                properties: [
                    {titolo: "list.reports.title"},
                    {stato: "list.reports.state"},
                    {priorita: "list.reports.priority"},
                    {nomeAutore: "list.reports.author"},
                    {createdAt: "list.reports.created"},
                    {updatedAt: "list.reports.updated"},
                    {presaInCaricoDa: "list.reports.presaInCaricoDa"},
                    {oggetto: "list.reports.oggetto"},
                ],
                columnsToDisplay: [
                    "titolo",
                    "oggetto",
                    "stato",
                    "nomeAutore",
                    "priorita",
                    "createdAt",
                    "updatedAt",
                    "presaInCaricoDa",
                ],
                columnsToSearch: ["titolo", "nomeAutore", 'codice'],
                order: [
                    "titolo",
                    "oggetto",
                    "stato",
                    "nomeAutore",
                    "createdAt",
                    "updatedAt",
                    "presaInCaricoDa",
                ],
                search: true,
                resolved: true,
                pagination: true,
                dataFilter: true,
                model: "Segnalazioni",
                project: true,
                filters: {
                    filters: listFilter,
                    filterFunction,
                },
            },
            projectService
        );
        this.formGroupFiter = this.fb.group({
            telecontrollo: undefined,
            acceptedElements: undefined,
        })
        this.formGroupFiter.get('acceptedElements').setValue(this.keysAcceptedFilter.map(v => v.registerValue))
        this.formGroupFiter.get('telecontrollo').setValue(true)
        this.subscriptions.add(
            this.formGroupFiter.valueChanges.subscribe(value => {
                const acceptedElements = value.acceptedElements;
                const telecontrollo = value.telecontrollo;
                let filters: {
                    label: string, items: Filter[], type: string, code: string
                }[] = []
                if (arrayIsSet(acceptedElements)) {
                    const filter = {
                        label: 'list.reports.filters.filters',
                        type: 'checkbox',
                        code: 'general',
                        items: []
                    }


                    if (acceptedElements.includes(className.puntiLuce)) {
                        filter.items.push({
                            label: 'list.reports.filters.light_points',
                            isActive: true,
                            type: LocalFilters.PUNTI_LUCE
                        })
                    }
                    if (acceptedElements.includes(className.circuiti)) {
                        filter.items.push({
                            label: 'list.reports.filters.circuits',
                            isActive: false,
                            type: LocalFilters.CIRCUITI
                        })
                    }

                    if (telecontrollo) {
                        filter.items.push({
                            label: 'list.reports.filters.remote_control',
                            isActive: true,
                            type: LocalFilters.TELECONTROLLO
                        })
                    }
                    filters.push(filter)
                }
                this.addOrSubstituionFilter(filters)
                // this.onFilter()
            })
        )
        this.subscriptions.add(
            this.data$.subscribe(data => {
                this.updateUsers()
            })
        )

        //console.log("in list",listFilter, JSON.parse(localStorage.getItem('reportsFilter')).filters);
    }

    public getValueObject = {
        presaInCaricoDa: (value: any) => {
            if (value?.objectId) {
                if (!(value.objectId in this.usersObservables)) {
                    this.usersObservables[value.objectId] = new BehaviorSubject(
                        this.cachedUsers.find((u) => u.objectId === value?.objectId)
                            ?.fullName ??
                        this.translateService.instant("list.reports.loading")
                    );
                }
                return this.usersObservables[value.objectId];
            }
            return "";
        },
        stato: (value) => {
            return this.translateService.onLangChange.pipe(
                startWith(this.translateService.currentLang),
                map(v => {
                    const trad = this.translateService.instant('segnalazioniParse.stato.' + value)
                    return trad.includes('segnalazioniParse.stato.') ? value : trad;
                })
            )
        }

    };


    ngOnInit() {
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    public indexTab;
    public forceUpdateTab: boolean;

    public onUpdateElement(newReport: TabsReports) {
        const element = this.addingTab.addingElement(
            this.openedReportsList,
            newReport
        );
        this.openedReportsList = element.openedList;
        this.forceUpdateTab = !this.forceUpdateTab;
        this.indexTab = {value: (element.index >= 0) ? element.index + 1 : 1};
        // this.router.navigate(['segnalazioni/modifica/', event]);
    }

    public openReport(id) {
        // this.router.navigate(['/'], {queryParams: {reportId: id}});
    }

    public itemModified(event) {
        switch (event.action) {
            case "close":
                this.openedReportsList = this.addingTab.removingElement(
                    this.openedReportsList,
                    event.item
                );
                return "close";
        }
    }

    private usersObservables: Record<string, BehaviorSubject<string>> = {};

    private updateUsers() {
        let missingUserIds = []
        if (this.data && this.data.rows) {
            missingUserIds = this.data.rows
                .map((r) => r.presaInCaricoDa)
                .filter(
                    (user) => {
                        return user && !this.cachedUsers.some((u) => u.objectId === user.objectId)
                    }
                )
                .map((user) => user.objectId);
        }

        if (missingUserIds.length) {
            this.loadingUsers.next(true);
            this.userService.getUserDetail(missingUserIds).subscribe(
                (userDetails) => {
                    const newCachedUsers = userDetails
                        .map((u) => u.toJSON())
                        .map((user) => ({
                            fullName: `${user.nome} ${user.cognome}`,
                            objectId: user.objectId,
                        }));
                    this.cachedUsers = [...this.cachedUsers, ...newCachedUsers];
                    newCachedUsers.forEach((u) => {

                        if (u.objectId in this.usersObservables) {
                            this.usersObservables[u.objectId].next(u.fullName);
                        } else {
                            this.usersObservables[u.objectId] = new BehaviorSubject(
                                u.fullName
                            );
                        }
                    });
                },
                (err) => console.error(err),
                () => this.loadingUsers.next(false)
            );
        }
    }

    public async getData(): Promise<void> {
        await super.getData();
    }

    async refreshData() {
        await this.getData();
        this.openedReportsList = this.openedReportsList.map((el) => {
            const updatedEl = this.data?.rows.find((r) => r.objectId === el.objectId);
            if (updatedEl) {
                el.priority = this.addingTab.getColor(updatedEl.priorita);
                el.titolo = updatedEl.titolo;
            }
            return el;
        });
    }

    protected readonly filter = filter;
}
