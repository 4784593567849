import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    UntypedFormBuilder,
    UntypedFormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR, ValidationErrors
} from "@angular/forms";
import {combineLatest, forkJoin, Observable, Subscription} from "rxjs";
import {map, startWith} from "rxjs/operators";
import {isNotNullOrUndefined} from "../../models/Models";

export type SelectToolbarItemType = { displayValue: string, key: string, registerValue: any }

@Component({
    selector: 'app-select-toolbar',
    templateUrl: './select-toolbar.component.html',
    styleUrls: ['./select-toolbar.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: SelectToolbarComponent
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: SelectToolbarComponent
        }
    ]
})
export class SelectToolbarComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
    @Input() disabled: boolean;
    @Input() possibleValues: SelectToolbarItemType[]
    public form: UntypedFormGroup;
    public currentValue$: Observable<SelectToolbarItemType>
    public previusValue$: Observable<SelectToolbarItemType>
    public nextValue$: Observable<SelectToolbarItemType>
    public isDisabled$: Observable<boolean>
    private subScriptions = new Subscription();

    constructor(private fb: UntypedFormBuilder) {
        this.form = this.fb.group({
            value: undefined,
        });
        this.currentValue$ = this.form.get('value').valueChanges.pipe(
            startWith(undefined),
            map(() => {
                return this.form.get('value').value

            })
        )
        this.previusValue$ = this.currentValue$.pipe(
            map((value) => {
                const length = this.possibleValues.length
                if (value != null && length > 1) {
                    const index = this.possibleValues.findIndex(pValue => pValue.key === value.key);
                    const beforeIndex = index > 0 ? index - 1 : length - 1
                    return this.possibleValues[beforeIndex]
                }
                return undefined

            })
        )
        this.nextValue$ = this.currentValue$.pipe(
            map((value) => {
                const length = this.possibleValues.length
                if (value != null && length > 1) {
                    const index = this.possibleValues.findIndex(pValue => pValue.key === value.key);
                    const nextIndex = (index + 1) < length ? index + 1 : 0
                    return this.possibleValues[nextIndex]
                }
                return undefined

            })
        )

        this.isDisabled$ = combineLatest(
            [
                this.form.valueChanges.pipe(
                    startWith()
                ),
                this.form.statusChanges.pipe(
                    startWith()
                ),
            ]
        ).pipe(
            map(values => {
                const disabled = this.form.disabled || this.form.get('value').disabled
                return disabled;
            })
        )
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.possibleValues) {
            const currentValue = this.form.get('value').value;
            let index = 0;
            if (currentValue != null) {
                const indexCurrent = this.possibleValues.findIndex(pValue => pValue.key === currentValue.key)
                if (indexCurrent >= 0) {
                    index = indexCurrent;
                }
            }
            this.form.get('value').setValue(this.possibleValues[index])
        }
        if (changes.disabled && this.disabled == true) {
            this.form.disable();
            setTimeout(()=>{
                this.form.updateValueAndValidity();
            },0)
        } else if (this.form.disabled && this.disabled != true) {
            this.form.enable();
            setTimeout(()=>{
            this.form.updateValueAndValidity();
            },0)
        }
    }

    ngOnDestroy() {
        this.subScriptions.unsubscribe();
    }

    clickPossibleValue(value: SelectToolbarItemType) {
        this.form.get('value').setValue(value)
    }

    isSetPredicate(value: SelectToolbarItemType, currentValue: SelectToolbarItemType) {
        return currentValue != null && value.key === currentValue.key
    }

    onTouched: Function = () => {
    };


    registerOnChange(onChange: any) {
        const sub = this.currentValue$.pipe(
            map(v => v != null ? v.registerValue : undefined)
        )
            .subscribe(onChange);
        this.subScriptions.add(sub)
    }

    registerOnTouched(onTouched: Function) {
        this.onTouched = onTouched;
    }


    setDisabledState(disabled: boolean) {
        if (disabled) {
            this.form.disable();
        } else {
            this.form.enable();
        }
        setTimeout(()=>{
            this.form.updateValueAndValidity();
        },0)
    }

    writeValue(value: any) {
        if (value == null) {
            this.form.get('value').reset();
        } else {
            let index;
            if (value.registerValue != null) {
                index = this.possibleValues.findIndex(pValue => pValue.key === value.registerValue)
            } else {
                index = this.possibleValues.findIndex(pValue => pValue.key === value)
            }

            if (index >= 0) {
                this.form.get('value').setValue(this.possibleValues[index]);
            }
        }
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (!isNotNullOrUndefined(this.form.get('value').validator)) {
            this.form.get('value').setValidators(control.validator)
        }
        if (this.form.get('value').valid) {
            return null
        } else {
            return this.form.get('value').errors;
        }
    }
}
