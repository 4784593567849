import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TemplateFormComponent } from './template-form/template-form.component';
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { ListComponent } from "./list/list.component";
import { PipesModule } from "../providers/pipes/pipes.module";
import { MatLegacyRadioModule as MatRadioModule } from "@angular/material/legacy-radio";
import { MatLegacyCheckboxModule as MatCheckboxModule } from "@angular/material/legacy-checkbox";
import { MatLegacySlideToggleModule as MatSlideToggleModule } from "@angular/material/legacy-slide-toggle";
import { PaginatorComponent } from './paginator/paginator.component';
import { OrderableListItemDirective } from './orderable-list-item.directive';
import { OrderableListComponent } from './orderable-list/orderable-list.component';
import { TemplatePaginatorComponent } from './template-paginator/template-paginator.component';
import { WidgetsModule } from '../widgets/widgets.module';
import {MatLegacyTooltipModule as MatTooltipModule} from "@angular/material/legacy-tooltip";
import {DirectivesModule} from "../providers/directives/directives.module";
import {MatButtonToggleModule} from "@angular/material/button-toggle";

@NgModule({
    declarations: [
        TemplateFormComponent,
        ListComponent,
        PaginatorComponent,
        OrderableListItemDirective,
        OrderableListComponent,
        TemplatePaginatorComponent
    ],
    imports: [
        FlexModule,
        FormsModule,
        PipesModule,
        CommonModule,
        MatMenuModule,
        RouterModule,
        TranslateModule,
        MatSortModule,
        MatIconModule,
        MatCardModule,
        MatInputModule,
        MatTableModule,
        MatListModule,
        MatSelectModule,
        MatPaginatorModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatRadioModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        WidgetsModule,
        MatTooltipModule,
        DirectivesModule,
        MatButtonToggleModule
    ],
    exports: [TemplateFormComponent,
        ListComponent, PaginatorComponent,
        OrderableListItemDirective,
        OrderableListComponent,
        TemplatePaginatorComponent]
})
export class CoreModule {
}
