import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ListStreetsComponent} from "./list-streets/list-streets.component";
import { EditStreetComponent } from '../../components/street/edit-street/edit-street.component';

const routes: Routes = [
    {
        path: '',
        component: ListStreetsComponent
    },
    {
        path: 'dettaglio',
        component: EditStreetComponent
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class StreetsRoutingModule {
}
