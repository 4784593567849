import {Pipe, PipeTransform} from '@angular/core';
import {arrayIsSet, isNotNullOrUndefined} from "../../models/Models";

@Pipe({
    name: 'extractUniqueValueByPredicateFunction'
})
export class ExtractUniqueValueByPredicateFunctionPipe implements PipeTransform {

    transform<T>(values: T[], predicateFunction: (a, valueToReturn: any[], ...args) => T[], ...args): T[] {
        if (Array.isArray(values) && isNotNullOrUndefined(predicateFunction)) {
            let valuesToReturn = []
            values.forEach(value => {
                const values = predicateFunction(value, valuesToReturn, ...args)
                if (arrayIsSet(values)) {
                    valuesToReturn = predicateFunction(value, valuesToReturn, ...args)
                }
            })
            return valuesToReturn
        } else {
            return values
        }
    }

}
