import {NgxMaterialTimepickerTheme} from 'ngx-material-timepicker';
import {forkJoin, Observable, ObservedValueOf, of, OperatorFunction} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import * as Parse from 'parse';
import {fromPromise} from "rxjs/internal-compatibility";


export type PaginationParserequestType<T> = {
    items: T[] | undefined,
    progress: number,
    nextPage: number | undefined,
    finished: boolean,
    error?
}

export interface PossibleValuesFormField {
    traduction?: string;
    noTraduction?: string;
    registerValue: any;
    objectId: string;
    disabled?: boolean;
}

export enum Settings_Circuit {
    AUTOMATIC = 'automatico',
    CREPUSCULAR = 'crepuscolare',
    MANUAL = 'manuale'
}

export enum chiaviFiltri {
    puntiStampa = 'puntiStampa',
    nessunQuadro = 'NO_CIRC',
    confiniAmministrativi = 'confiniAmministrativi'
}

export enum className {
    comune = 'Comuni',
    circuiti = 'Circuiti',
    fotoCircuiti = 'fotoCircuiti',
    puntiLuce = 'PuntiLuce',
    puntiStampa = 'PictureCameraPoint',
    strade = 'Strade',
    arredoUrbano = 'ArredoUrbano',
    segnaliTlcNodo = 'SegnaliTlcNodo',
    clusterLuxData = 'ClusterLuxData',
    segnalazioni = 'Segnalazioni',
    documentsFile = 'DocumentsFile',
    documentsFodler = 'DocumentsFolder',
    lineaElettrica = 'LineaElettrica',
    caricoEsogeno = 'CaricoEsogeno',
    gruppiFotometrie = 'GruppoFotometrie',
    tlcProfiloFunzionamento = 'TLC_ProfiloFunzionamento',
    statisticheTelecontrolloParse = 'StatisticheTelecontrollo',
    orariAccensioneCircuiti = 'OrariAccensioneCircuiti',
    segnaliTlcCircuiti = 'SegnaliTlcCircuiti',
    schedaManutenzione = 'SchedaManutenzione'
}

export const DefaultCenterMap = {lat: 41.9027, lng: 12.4962}
export const chiaviScala = {
    altro: 'Altro',
    non_specificato: 'Non specificato',
    daVerificare: 'Da verificare',
    greyColor: '#CCCCCC',
    blackColor: '#000000'
};
export const puntiLuceValueScalaColore = {
    statoDiConservazioneSbraccio: {
        Da_verificare: 'Da verificare',
        Non_specificato: chiaviScala.non_specificato,
        Accettabile: 6,
        Da_riverniciare: 5,
        Da_riposizionare: 4,
        Da_sostituire: 3,
        Mancante: 2,
        Pericoloso: 1
    },
    statoDiConservazioneCorpoIlluminante: {
        Da_verificare: 'Da verificare',
        Non_specificato: chiaviScala.non_specificato,
        Accettabile: 4,
        Lampada_da_sostituire: 3,
        Corpo_illuminante_da_sostituire: 2,
        Mancante: 1,
    },
    statoDiConservazione: {
        Da_verificare: 'Da verificare',
        Non_specificato: chiaviScala.non_specificato,
        Accettabile: 6,
        Da_riverniciare: 5,
        Da_riposizionare: 4,
        Da_sostituire: 3,
        Mancante: 2,
        Pericoloso: 1
    },
    statoDiConservazionePozzetto: {
        Da_verificare: 'Da verificare',
        Non_specificato: chiaviScala.non_specificato,
        Accettabile: 6,
        Da_riverniciare: 5,
        Da_riposizionare: 4,
        Da_sostituire: 3,
        Mancante: 2,
        Pericoloso: 1
    },
    attaccoCorpoIlluminante: {
        Non_specificato: chiaviScala.blackColor,
        Altro: chiaviScala.greyColor,
        D30: 1,
        D40: 2,
        D50: 3,
        D60: 4,
        D70: 5,
        D80: 6,
        D90: 7,
        D100: 8,
        D110: 9,
        D120: 10,
    }
};
export const puntiLuceScalaColorePredefiniti = {
    temperaturaColore: {
        RGB: '#00CC00',
        Altro: chiaviScala.greyColor,
        "Non specificato": chiaviScala.blackColor,
        2200: '#FF9600',
        2700: '#FC8000',
        3000: '#FFFF55',
        3500: '#FFFF96',
        4000: '#EEEEEE',
        5000: '#85E3FF',
    },
    tipologiaSorgenteLuminosa: {
        LED: '#1364F0',
        SAP: '#f7b729',
        SBP: '#f7b729',
        JM: '#E6FFFF',
        HG: '#E6F0FF',
        ALO: '#F0F0AF',
        FL: '#00BE50',
        INC: '#FFFF00',
        Neon: '#00FFFF',
        Altro: chiaviScala.greyColor,
        "Non specificato": chiaviScala.blackColor
    },
    lampadaFunzionante: {
        FUNZIONANTE: '#00CC00',
        NON_FUNZIONANTE: '#FF0000',
        DISCONESSA: '#00FFFF',
        "Non specificato": chiaviScala.blackColor
    },
};


export const circuitiScalaColore = {
    statoOperativo: {
        Da_verificare: chiaviScala.daVerificare,
        Non_specificato: chiaviScala.non_specificato,
        DA_REALIZZARE: 7,
        IN_COSTRUZIONE: 6,
        ATTIVO: 5,
        IN_MANUTENZIONE: 4,
        ATTIVO_DA_RIMUOVERE: 3,
        IN_DISUSO: 2,
        NON_ATTIVO_DA_RIMUOVERE: 1,
    },
    statoDiConservazione: {
        Non_specificato: chiaviScala.non_specificato,
        NEW: 5,
        GOOD: 4,
        BAD: 3,
        DANGEROUS: 2,
        SPORTELLO_ROTTO: 1,
    }
};

export const tabMaxNumber = 5;

export const valuePeriodOne = -1;


export const customThemeNgxDataPicker: NgxMaterialTimepickerTheme = {
    container: {
        bodyBackgroundColor: '#fff'
    },
    dial: {
        dialBackgroundColor: 'rgba(255,152,0)',
    },
    clockFace: {
        clockFaceBackgroundColor: '#555',
        clockHandColor: 'rgba(255,152,0)',
        clockFaceTimeInactiveColor: '#fff'
    }
};
const version = require('package.json').version

export const currentVersion = version;


export const paramsApiParse = (params: object) => {
    return {version: '2.0.0', platform: 'web', ...params}
}
export const paramsApiParse_v2 = (params: object = undefined): {
    version: string,
    platform: string,
    params?: KeyStringValue<any>
} => {
    if (params != null) {
        return {version: '2.0.0', platform: 'web', params}
    } else {
        return {version: '2.0.0', platform: 'web'}
    }
}

export function fetchParseObject$(className: string, objectId: string) {
    const params = paramsApiParse_v2({className, objectId});
    const fetched = Parse.Cloud.run('fetchObject', params)
    return fromPromise(fetched);
}

export function fetchParseObjects$<T>(className: string, objectIds: string[], includes: string[] = undefined): Observable<T[]> {
    const params = paramsApiParse_v2({
        className,
        objectIds: objectIds,
        includes
    });
    const fetched$ = fromPromise(Parse.Cloud.run('fetchObjects', params));
    return fetched$
}

export enum daysInWeek {
    sunday,
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday
}


export const masterFoldersFileManager = {
    schedeManutenzioneCompilate: '__SCHEDE_MANUTENZIONI_COMPILATE_FOLDER__',
    puntiLuce: "__PUNTI_LUCE_MASTER_FOLDER__",
    circuiti: "__CIRCUITI_MASTER_FOLDER__",
    caricoEsogeno: "__CARICO_ESOGENO_MASTER_FOLDER__"
};


export interface scalaColoreNomeTabella {
    scalaColore: object;
    tabellaImpostataScala: string;
}

export const qualitativeScaleColor = [
    '#0000ff',
    '#ff0000',
    '#ffd300',
    '#00ff00',
    '#ff1ab8',
    '#00007b',
    '#b82417',
    '#8e7700',
    '#005700',
    '#850e60',
    '#8383ff',
    '#9e4f46',
    '#d0d322',
    '#95d34f',
    '#ff8fc0',
    '#008395',
    '#c81c45',
    '#f68308',
    '#00953c',
    '#7b1a69',
];
export const hunaChartColors = {
    blue: '#5dbed7',
    orange: '#f38232',
    yellow: '#ffdc0d',
    violet: '#ac72ad',
    verdeBlue: '#22a694',
    rosso: '#ff326f',
    blueOpacity: (opacity: number) => 'rgba(93, 190, 215,' + opacity + ')',
    orangeOpacity: (opacity: number) => 'rgba(243, 130, 50,' + opacity + ')',
    yellowOpacity: (opacity: number) => 'rgba(255, 220, 13,' + opacity + ')',
    violetOpacity: (opacity: number) => 'rgba(172, 114, 173,' + opacity + ')',
    verdeBlueOpacity: (opacity: number) => 'rgba(34, 166, 148,' + opacity + ')',
    colorOpacity: (r, g, b, opacity: number) => 'rgba(' + r + ', ' + g + ', ' + b + ',' + opacity + ')',
    rossoOpacity: (opacity: number) => 'rgba(255, 50, 113,' + opacity + ')'
};

enum LocalFilters {
    CIRCUITI = 'circuito',
    PUNTI_LUCE = 'puntiLuce',
    TELECONTROLLO = 'codice'
}

export interface KeyStringValueString {
    [k: string]: string;
}

export interface KeyStringValueAny {
    [k: string]: any;
}

export interface KeyStringValue<T> {
    [k: string]: T;
}

export function isNotNullOrUndefined(input: any) {
    return input != null;
}

export function isDate(value: any): boolean {
    return value instanceof Date;
}

export function isValidDate(value: any): boolean {
    return isDate(value) && !isNaN(value.getTime());
}

export function substitutionElementInArray<T>(array: T[], element: T, keyToSearch: string = 'objectId'): T[] {
    if (arrayIsSet(array) && element != null) {
        return array.filter(val => val[keyToSearch] !== element[keyToSearch]).concat(element);
    } else {
        return [];
    }
};

export const checkDiff = (array) => {
    return arrayIsSet(array) && new Set(array).size !== 1;
}
export const listFilter =
    [
        {
            label: 'list.reports.filters.priorities',
            type: 'radio',
            value: undefined,
            code: 'priority',
            items: [
                {
                    label: 'list.reports.filters.low',
                    value: 0
                },
                {
                    label: 'list.reports.filters.medium',
                    value: 1
                },
                {
                    label: 'list.reports.filters.high',
                    value: 2
                }
            ]
        }
    ];

export const modalityViewMap = {
    scheduleMaintenance: 'modalitaManutenzione',
    default: null
};

export function hunaParseFloat(value) {
    let number;
    if (isNotNullOrUndefined(value) && typeof value === 'string' || typeof value === 'number') {
        number = parseFloat(value.toString().replace(',', '.'));
    }
    return (!isNotNullOrUndefined(number) || isNaN(number)) ? null : number;
}


export function hunaSortNumericString(a, b, lang = 'it') {
    const intA = parseInt(a);
    const intB = parseInt(b);
    if (isNaN(intA) && isNaN(intB)) {
        return a.localeCompare(b, lang, {
            numeric: true,
            ignorePunctuation: true
        });
    } else if (isNaN(intA) && !isNaN(intB)) {
        return -1;
    } else if (!isNaN(intA) && isNaN(intB)) {
        return 1;
    } else {
        return a - b;
    }
}

export function hunaParseFileName(name: string) {
    if (typeof name == 'string') {
        const regex = /[^a-zA-Z0-9_.]/g;
        return name.replace(regex, '_');
    } else {
        return '';
    }
}

export function objectIsSet(obj: any): obj is KeyStringValue<any> {
    return obj != null && arrayIsSet(Object.keys(obj));
}

export function arrayIsSet(array: any[]): boolean {
    return Array.isArray(array) && array.length > 0;
}

export function stringIsSet(value: any): boolean {
    if (typeof value != 'string') {
        return false;
    } else {
        return value.trim().length > 0;
    }
}

export function stringIsSetWithoutSpecialCharacter(value: any): boolean {
    if (typeof value != 'string') {
        return false;
    } else {
        return stringIsSet(cleanToSpecialCharacterAndLowerCaseString(value));
    }
}

export function removeSpecialCharacterAndSepareCharacterToNumber(stringValue: string): string[] {
    if (stringValue == null && typeof stringValue != "string") {
        return stringValue
    } else {
        return stringValue
            .replace(/[^\w]/gi, '')
            .replace('_', '')
            .split(/(\d+)/)
            .filter(value => stringIsSet(value))
    }
}

export function cleanToSpecialCharacterAndLowerCaseString(value) {
    const key = stringIsSet(value) ? value : '';
    return key
        .trim()
        .replace(/ /g, '')
        .replace(/-/g, '')
        .replace(/_/g, '')
        .replace(/\./g, '')
        .toLowerCase()
}

export function valueOverlapStrings(string1, string2) {
    if (stringIsSet(string1) && stringIsSet(string2)) {
        let longer;
        let shorter;
        if (string1.length < string2.length) {
            longer = string2.trim();
            shorter = string1.trim();
        } else {
            longer = string1.trim();
            shorter = string2.trim();
        }
        const longerLength = longer.length;
        if (longerLength == 0) {
            return 1.0;
        }
        const editDistance = (string1, string2) => {
            const s1 = string1.toLowerCase();
            const s2 = string2.toLowerCase();
            const costs = [];
            for (let i = 0; i <= s1.length; i++) {
                let lastValue = i;
                for (let j = 0; j <= s2.length; j++) {
                    if (i == 0) {
                        costs[j] = j;
                    } else {
                        if (j > 0) {
                            let newValue = costs[j - 1];
                            if (s1.charAt(i - 1) != s2.charAt(j - 1)) {
                                newValue = Math.min(Math.min(newValue, lastValue),
                                    costs[j]) + 1;
                            }
                            costs[j - 1] = lastValue;
                            lastValue = newValue;
                        }
                    }
                }
                if (i > 0) {
                    costs[s2.length] = lastValue;
                }
            }
            return costs[s2.length];
        };
        return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
    }
}

export function getItemInArrayByKeyValue<T>(array: T[], value, key: string = undefined): T | undefined {
    let item;
    if (!arrayIsSet(array)) {
        item = undefined;
    } else if (isNotNullOrUndefined(key)) {
        const index = array.findIndex(item => item[key] === value);
        if (index >= 0) {
            item = array[index];
        }
    } else {
        const index = array.indexOf(value);
        if (index >= 0) {
            item = array[index];
        }
    }
    return item;
}

export function getArrayToRemveItem<T>(array: T[], value, key: string = undefined): T[] {
    if (isNotNullOrUndefined(key)) {
        return array.filter(item => item[key] !== value);
    } else {
        return array.filter(item => item != value);
    }
}

export function getDifferenceArray<T>(array1: T[], array2: T[], simmetricDifference = true): T[] {
    const arr1 = Array.isArray(array1) ? array1 : [];
    const arr2 = Array.isArray(array2) ? array2 : [];
    let difference;
    if (arr1.length == 0 && arr2.length == 0) {
        difference = [];
    } else if (simmetricDifference) {
        difference = arr1
            .filter(x => !arr2.includes(x))
            .concat(
                arr2.filter(x => !arr1.includes(x)));
    } else {
        difference = arr1
            .filter(x => !arr2.includes(x))
    }
    return difference;
}

export function getDifferenceArrayWithKey<T>(array1: T[], array2: T[], key1: string, key2: string): T[] {

    let difference;
    if (!arrayIsSet(array1) && !arrayIsSet(array2)) {
        difference = [];
    } else if (arrayIsSet(array1) && !arrayIsSet(array2)) {
        difference = array1;
    } else if (!arrayIsSet(array1) && arrayIsSet(array2)) {
        difference = array2;
    } else {
        const arr1 = array1.map(item => item[key1]);
        const arr2 = array2.map(item => item[key2]);
        difference = array1
            .filter(x => !arr2.includes(x[key1]))
            .concat(
                array2.filter(x => !arr1.includes(x[key2])));
    }
    return difference;
}

export function getDifferenceArrayGenricObject<T>(array1: T[], array2: T[]): T[] {
    let difference;
    if (!arrayIsSet(array1) && !arrayIsSet(array2)) {
        difference = [];
    } else if (arrayIsSet(array1) && !arrayIsSet(array2)) {
        difference = array1
    } else if (!arrayIsSet(array1) && arrayIsSet(array2)) {
        difference = array2
    } else {
        difference = array1
            .filter(obj1 => {
                const index = array2.findIndex(obj2 => objectsEqual(obj1, obj2))
                return index < 0;
            })
            .concat(
                array2.filter(obj2 => {
                    const index = array1.findIndex(obj1 => objectsEqual(obj1, obj2))
                    return index < 0;
                })
            )
    }
    return difference;
}


export function getUniqueValueInArray(array1: any[], key: string = undefined) {
    const onlyUnique = (value, index, self, key) => {
        if (stringIsSet(key)) {
            const indexFilter = self.findIndex(obj => obj[key] == value[key]);
            return index === indexFilter;
        } else {
            return self.indexOf(value) === index;
        }
    };

    return array1.filter((value, index, self) => onlyUnique(value, index, self, key));
}

export function objectsEqual(o1, o2) {
    if (o2 == null && o1 != null) {
        return false;
    } else if (o1 != null && o1.objectId != null && o2 != null && o2.objectId != null) {
        return o1.objectId === o2.objectId;
    }
    return o1 !== null && typeof o1 === 'object' && Object.keys(o1).length > 0 ?
        Object.keys(o1).length === Object.keys(o2).length &&
        Object.keys(o1).every(p => objectsEqual(o1[p], o2[p]))
        : (o1 !== null && Array.isArray(o1) && Array.isArray(o2) && !o1.length &&
            !o2.length) ? true : o1 === o2;
};

// 3.5 -> 03:30 AM 15.5 -> 03:30 PM
export function convertDecimalHourTodate(oraDecimal: number | string): string {
    if (oraDecimal) {
        const oraDecimalString = oraDecimal.toString();
        const ore = parseInt(oraDecimalString);
        const minute = Math.floor((parseFloat(oraDecimalString) - ore) * 60).toString().padStart(2, '0');
        let aMpM;
        let ora;
        if (ore > 12) {
            ora = (ore - 12).toString().padStart(2, '0');
            aMpM = 'PM';
        } else {
            ora = ore.toString().padStart(2, '0');
            aMpM = 'AM';
        }
        return ora + ':' + minute + ' ' + aMpM;
    } else {
        return '-';
    }
}

export function objectForkJoin$(object: { [k: string]: Observable<any> }): Observable<{ [k: string]: any }> {
    const observables = Object.values(object);
    return forkJoin(observables).pipe(
        map(values => {
            const obj = {};
            Object.keys(object).forEach((key, index) => {
                obj[key] = values[index];
            });
            return obj;
        })
    );
}


export function getParseObjectByClassNameObjectId(className: string, objectId: string) {
    const ParseClass = Parse.Object.extend(className);
    const parseObj = new ParseClass();
    parseObj.set('id', objectId);
    return parseObj;
}

export function getDifferenceDateInDay(date1: Date, date2: Date): number {
    const diff = Math.abs(date1.getTime() - date2.getTime());
    const oneDay = 1000 * 60 * 60 * 24;
    const day = Math.floor(diff / oneDay);
    return day;
}

export function getToday(): Date {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
}


export function parseFetchAll(parseObject: any[]) {
    return Parse.Object.fetchAll(parseObject, {});
}

export function parseFetchAll$(parseObject: any[]): Observable<any[]> {
    return fromPromise(parseFetchAll(parseObject));
}

export function returnsValueWithFunctionIsInError$<T>(valueToReturnWithError: T): OperatorFunction<any, ObservedValueOf<Observable<T>> | any> {
    return catchError(err => {
        return of(valueToReturnWithError);
    })
}

export function traductionFunctionMasterFolder(value): { traduction: string | null, notTraduction: string | null } {
    if (!isMasterFolder(value)) {
        return {notTraduction: value, traduction: null};
    } else {
        return {notTraduction: null, traduction: value};
    }
}

export function isMasterFolder(value): boolean {
    const namesMasterFolders = Object.values(masterFoldersFileManager)
    return namesMasterFolders.includes(value);
}

export function allPropertyClass(classForKey: any): string[] {
    return Object.getOwnPropertyNames(classForKey.prototype)
        .filter(key => key != "constructor")
}

export function decimalHorToMinuteAndHour(value: number | string): { hour: number, minute: number } {
    if (value == null) {
        return undefined;
    }
    try {
        const oraDecimalString = value.toString();
        const oraTemp = Math.floor(parseInt(oraDecimalString) / 10);
        let hour = oraTemp
        const minute = Math.round((Number(value) % 10) * 6);
        return {hour, minute};
    } catch (e) {
        throw e;
    }
}

export const convertHourTODecimalHour = (ora: number | string, minute: number | string) => {
    return parseInt(ora.toString()) * 10 + parseInt(minute.toString()) / 6;
}