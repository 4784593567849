import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListBoxSchedeManutenzioneComponent } from './list-box-schede-manutenzione/list-box-schede-manutenzione.component';
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {WidgetsModule} from "../../widgets/widgets.module";
import {MatIconModule} from "@angular/material/icon";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {BoxesModule} from "../../boxes/boxes.module";
import {ReactiveFormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {PipesModule} from "../../providers/pipes/pipes.module";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import { ListAndSelectToAddSchedeManutenzioneComponent } from './list-and-select-to-add-schede-manutenzione/list-and-select-to-add-schede-manutenzione.component';
import {MatBadgeModule} from "@angular/material/badge";
import { ListActivityComponent } from './list-activity/list-activity.component';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';



@NgModule({
    declarations: [ListBoxSchedeManutenzioneComponent, ListAndSelectToAddSchedeManutenzioneComponent, ListActivityComponent],
    exports: [
        ListBoxSchedeManutenzioneComponent,
        ListActivityComponent
    ],
    imports: [
        CommonModule,
        MatCardModule,
        WidgetsModule,
        MatIconModule,
        ScrollingModule,
        BoxesModule,
        ReactiveFormsModule,
        TranslateModule,
        PipesModule,
        MatButtonModule,
        MatBadgeModule,
        MatCheckboxModule,
    ]
})
export class ListElementModule { }
