import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {NavbarService} from "./navbar.service";
import {ProjectService} from "./project.service";
import {LocalSotrageService} from "./local-sotrage.service";

@Injectable({
    providedIn: 'root'
})
export class ChangeElementInComponentService {

    constructor(private navbarService: NavbarService,
                private localStorage: LocalSotrageService) {
    }


    public changeProject(value: { id }, nameProject = null, siglaProject = null) {
        this.localStorage.projectId = value.id;
    }


}
