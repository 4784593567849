import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {formField, typeFormValue} from "../../../models/configurationProperty/configurationPropertyUtils";
import {arrayIsSet, stringIsSet} from "../../../models/Models";
import {UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators} from "@angular/forms";
import {MatLegacyCheckboxChange as MatCheckboxChange} from "@angular/material/legacy-checkbox";
import {debounceTime, map, startWith} from "rxjs/operators";
import {dataForm} from "../../confirm-delete/select-or-create/select-or-create.component";

export type FormFieldPopUpType = {
    type: typeFormValue,
    title: { traduction?: string, notTraduction?: string },
    formControlName: string,
    possibleValues: { value, title: { traduction?: string, notTraduction?: string } }[],
    defaultValue: any,
    filterKey?: string,
    validator?: Validators[]
}
export type FormFieldPopUpData = {
    formFields: FormFieldPopUpType[],
    title: { traduction?: string, notTraduction?: string },
    disableClose: boolean,
    unsetField?: boolean,
    visualizeCheckAllButton: boolean,
    visualizedSearch?: boolean,
    onlyFormFieldDirty?: boolean
}

@Component({
    selector: 'app-form-field',
    templateUrl: './form-field.component.html',
    styleUrls: ['./form-field.component.scss']
})
export class FormFieldComponent implements OnInit {
    public typesFormValue = typeFormValue;
    public form: UntypedFormGroup;
    public filterKeyName = 'filterKey' + new Date().getTime().toString(32);
    public filterKey$;
    public checkedAll$;

    constructor(public dialogRef: MatDialogRef<FormFieldComponent>,
                @Inject(MAT_DIALOG_DATA) public data: FormFieldPopUpData,
                private fb: UntypedFormBuilder) {
        dialogRef.disableClose = this.data.disableClose;
        const obj = {}
        obj[this.filterKeyName] = null;
        this.formFields.forEach(formField => {

            if (arrayIsSet(formField.validator)) {
                obj[formField.formControlName] = [{
                    value: formField.defaultValue,
                    disabled: false
                }, formField.validator];
            } else {
                obj[formField.formControlName] = [{
                    value: formField.defaultValue,
                    disabled: false
                }];
            }
        });
        this.form = this.fb.group(obj);
        this.filterKey$ = this.form.get(this.filterKeyName).valueChanges.pipe(
            debounceTime(300)
        );
        this.checkedAll$ = this.form.valueChanges.pipe(
            startWith(this.form.value),
            debounceTime(150),
            map(values => {
                return Object.keys(values)
                    .filter(key => key != this.filterKeyName)
                    .findIndex(key => values[key] != true) < 0
            })
        )


    }

    get formFields(): FormFieldPopUpType[] {
        if (this.data != null) {
            return this.data.formFields
        } else {
            return [];
        }
    }

    get titleIsSet(): boolean {
        if (this.data != null && this.data.title) {
            return stringIsSet(this.data.title.traduction) || stringIsSet(this.data.title.notTraduction)
        } else {
            return false;
        }
    }

    get title(): { traduction?: string, notTraduction?: string } {
        if (this.titleIsSet) {
            return this.data.title
        } else {
            return {};
        }
    }

    get titleNotTraduction(): string {
        if (this.data != null && this.data.title && this.data.title.notTraduction) {
            return this.data.title.notTraduction
        } else {
            return null;
        }
    }

    get titleTraduction(): string {
        if (this.data != null && this.data.title && this.data.title.traduction) {
            return this.data.title.traduction
        } else {
            return null;
        }
    }

    ngOnInit(): void {
    }

    checkValue() {
        const onlyDirty = (key: string) => this.data.onlyFormFieldDirty == true ? this.form.get(key).dirty : true;
        const values = {}
        Object.keys(this.form.value)
            .filter(key => key != this.filterKeyName && onlyDirty(key))
            .forEach(key => {
                values[key] = this.form.get(key).value;
            })
        this.dialogRef.close(values);
    }

    svuotaCampo() {
        this.dialogRef.close({unsetField: true});
    }

    setUnset(event: MatCheckboxChange) {
        Object.keys(this.form.value)
            .filter(key => key != this.filterKeyName)
            .forEach(key => {
                this.form.get(key).setValue(event.checked)
            })
    }

    filterFunction(values: FormFieldPopUpType[], filterKey: string) {
        if (arrayIsSet(values) && stringIsSet(filterKey)) {
            return values.filter(val => {
                const key1 = stringIsSet(val.filterKey) ? val.filterKey : val.formControlName;
                return key1.toLowerCase().includes(filterKey.toLowerCase())
            })
        }
        return values
    }

    getPossibleValues(values: { value, title: { traduction?: string, notTraduction?: string } }[]): dataForm[] {
        return values.map(val => {
            const obj: dataForm = {
                valueForm: val.value,
                html: val.value,
                traduction: val.title.traduction
            };
            return obj
        })
    }
}
