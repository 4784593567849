import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {DocumentsFolderParse} from "../../../models/DocumentsFolder.Parse";
import {FileManagerService} from "../../../providers/services/file-manager.service";
import {AlertService} from "../../../providers/services/alert.service";
import {UtilsService} from "../../../providers/services/utils.service";
import {arrayIsSet, isNotNullOrUndefined, stringIsSet, traductionFunctionMasterFolder} from "../../../models/Models";
import {map} from "rxjs/operators";
import {Observable, of} from "rxjs";

@Component({
    selector: 'app-move-to-folder',
    templateUrl: './move-to-folder.component.html',
    styleUrls: ['./move-to-folder.component.scss']
})
export class MoveToFolderComponent implements OnInit {
// todo create new folder in move folder not worked correctly, add currente folder
    private selectedFolder: DocumentsFolderParse;
    private homeFolderName = 'home';
    public stackFolderFileNavigation = new Map();
    public comeBackNavigation = [];
    public foldersWhereAddedFolder = [];

    public folders: DocumentsFolderParse[];

    public loading = {folder: false, createNewFolder: false};

    @ViewChild('pathContainerHtml', {static: true}) public pathContainer: ElementRef;


    constructor(
        public dialogRef: MatDialogRef<MoveToFolderComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: { folders: DocumentsFolderParse[], currentFolder: DocumentsFolderParse, backFolders: DocumentsFolderParse[], foldersToMove: DocumentsFolderParse[], numberOfFiles: number },
        private fileManagerService: FileManagerService,
        private alertService: AlertService,
        private utilService: UtilsService
    ) {
    }

    public isSelectedFolder(folder: DocumentsFolderParse) {
        return isNotNullOrUndefined(this.selectedFolder) && this.selectedFolder.objectId === folder.objectId;
    }

    public get disableSave() {
        return !isNotNullOrUndefined(this.selectedFolder)
    }

    public get nameFolder() {
        return isNotNullOrUndefined(this.selectedFolder) ? this.selectedFolder.name : '...'
    }

    get projectName() {
        return this.fileManagerService.projectName;
    }

    ngOnInit(): void {
        if (arrayIsSet(this.data.foldersToMove)) {
            const ids = this.data.foldersToMove.map(f => f.objectId);
            this.folders = this.data.folders.filter(f => !ids.includes(f.objectId));
        } else {
            this.folders = [...this.data.folders];
        }
        const name = this.currentFolderName;

        this.selectedFolder = this.data.currentFolder;
        this.data.backFolders
            .filter(f => f != null)
            .forEach(folder => {
                this.comeBackNavigation.push(folder.objectId);
            })
    }

    get currentFolderName() {
        const name = (this.data.currentFolder) ? this.data.currentFolder.objectId : this.homeFolderName;
        return name;
    }

    get selectedFolderName() {
        const name = (this.selectedFolder) ? this.selectedFolder.objectId : this.homeFolderName;
        return name;
    }

    openFolder(folder) {
        this.selectedFolder = folder;
        this.getFileAndFolder(folder);
    }


    selectFolder(folder) {
        if (this.isSelectedFolder(folder)) {
            this.selectedFolder = undefined;
        } else {
            this.selectedFolder = folder;
        }
    }

    closeDialog(what: 'close' | 'move') {
        if (what === 'close') {
            this.dialogRef.close({
                saveFile: false,
                destinationFolder: undefined,
                foldersWhereAddFolder: this.foldersWhereAddedFolder
            })
        } else if (what == 'move') {
            this.dialogRef.close({
                saveFile: true,
                destinationFolder: this.selectedFolder,
                foldersWhereAddFolder: this.foldersWhereAddedFolder
            })
        }
    }


    addFolderToFolders(folder: DocumentsFolderParse, parentFolder: DocumentsFolderParse) {
        this.folders = this.folders.concat([folder]);
        const name = (isNotNullOrUndefined(parentFolder)) ? parentFolder.objectId : this.homeFolderName;
        this.stackFolderFileNavigation.set(name, {folders: this.folders});
    }

    createNewFolder() {
        this.loading.createNewFolder = true;
        this.fileManagerService.dialogCreateNewFolder(this.selectedFolder)
            .subscribe(newFolder => {
                this.addFolderToFolders(newFolder, this.selectedFolder);
                this.foldersWhereAddedFolder.push(this.selectedFolderName);
                this.loading.createNewFolder = false;
            }, error => {
                this.loading.createNewFolder = false;
                this.alertService.error(error)
            })
    }

    getFileAndFolder(parentFolder: DocumentsFolderParse) {
        let folder;
        if (isNotNullOrUndefined(parentFolder)) {
            folder = parentFolder.objectId;
        }
        // else {
        //     folder = this.homeFolderName;
        // }
        this.loading.folder = true;
        this.comeBackNavigation.push(folder);
        this.getFolders$(folder).subscribe(folders => {
            this.loading.folder = false;
            this.folders = folders;
        })
        // if (!this.stackFolderFileNavigation.has(folder)) {
        //     this.loading.folder = true;
        //     this.fileManagerService.getAllFoldersInFolder(parentFolder, false).subscribe(
        //         folders => {
        //             this.folders = folders;
        //             this.stackFolderFileNavigation.set(folder, {folders: this.folders})
        //             this.loading.folder = false;
        //         }, error => {
        //             this.alertService.error(error);
        //             this.loading.folder = false;
        //         }
        //     )
        // } else {
        //     const fileFolders = this.stackFolderFileNavigation.get(folder);
        //     this.folders = fileFolders.folders;
        //     this.loading.folder = false;
        // }
    }

    getFolders$(parentFolderId: string): Observable<DocumentsFolderParse[]> {
        let folder;
        let parentFolder;
        if (stringIsSet(parentFolderId)) {
            folder = parentFolderId;
            parentFolder = new DocumentsFolderParse();
            parentFolder.objectId = parentFolderId;
        } else {
            folder = this.homeFolderName;
        }
        let folders$: Observable<DocumentsFolderParse[]>;
        if (this.stackFolderFileNavigation.has(folder)) {
            const fileFolders = this.stackFolderFileNavigation.get(folder);
            if (arrayIsSet(fileFolders.folders)) {
                folders$ = of(fileFolders.folders);
            }

        }
        if (folders$ == null) {
            folders$ = this.fileManagerService.getAllFoldersInFolder(parentFolder, false).pipe(
                map(folders => {
                    this.stackFolderFileNavigation.set(folder, {folders: folders})
                    return folders
                })
            )
        }
        return folders$.pipe(
            map(folders => {
                if (arrayIsSet(folders)) {
                    if (arrayIsSet(this.data.foldersToMove)) {
                        const ids = this.data.foldersToMove.map(f => f.objectId);
                        return folders.filter(f => !ids.includes(f.objectId));
                    } else {
                        return folders;
                    }
                } else {
                    return []
                }
            })
        );
    }

    comeBack() {
        const indexLastFolders = this.comeBackNavigation.length - 2;
        if (indexLastFolders >= 0) {
            let lastFolderId = this.comeBackNavigation[indexLastFolders];
            if (lastFolderId !== this.homeFolderName) {
                const folder = new DocumentsFolderParse();
                folder.objectId = lastFolderId
                this.selectedFolder = folder;
            } else {
                this.selectedFolder = undefined;
                lastFolderId = undefined;
            }
            this.getFolders$(lastFolderId).subscribe(folders => {
                this.folders = folders;
                this.comeBackNavigation.pop()
                this.comeBackNavigation = [...this.comeBackNavigation]
            })
        }
    }

    get disableComeBackButton() {
        return this.comeBackNavigation.length == 0;
    }

    get disableForwardButton() {
        return this.selectedFolderName == this.currentFolderName;
    }

    getTextWidth = this.utilService.getTextWidth

    get navigationPath() {
        const innerWidth = this.pathContainer.nativeElement.clientWidth;
        let path = [this.projectName];
        if (this.comeBackNavigation.length == 1) {
            return path;
        } else if (this.comeBackNavigation.length == 2) {
            const folder = new DocumentsFolderParse();
            folder.objectId = this.comeBackNavigation[this.comeBackNavigation.length - 1];
            path.push(folder.name);
        } else if (innerWidth < 100) {
            if (this.comeBackNavigation.length > 2) {
                path.push('...');
                const folder = new DocumentsFolderParse();
                folder.objectId = this.comeBackNavigation[this.comeBackNavigation.length - 1];
                path.push(folder.name);
            } else if (this.comeBackNavigation.length > 1) {
                const folder = new DocumentsFolderParse();
                folder.objectId = this.comeBackNavigation[this.comeBackNavigation.length - 1];
                path.push(folder.name);
            }
        } else {
            const widthIcon = 24;
            const width3point = this.getTextWidth('...');
            const initialPath = [this.projectName];
            let totalLength = 0;
            let indexInit = 0;
            let indexFinish = 0;
            const maxLenght = innerWidth - width3point - 40;
            let i = 1;
            const folder = new DocumentsFolderParse();
            while (i < this.comeBackNavigation.length && totalLength < maxLenght) {
                folder.objectId = this.comeBackNavigation[i];
                totalLength += this.getTextWidth(folder.name) + widthIcon;
                if (totalLength < maxLenght) {
                    indexInit++;
                }
                folder.objectId = this.comeBackNavigation[i - 1];
                totalLength += this.getTextWidth(folder.name) + widthIcon;
                if (totalLength < maxLenght) {
                    indexFinish++;
                }
                i++;
            }
            if (i == this.comeBackNavigation.length) {
                path = this.comeBackNavigation
                    .map(folderId => {
                        if (folderId == this.homeFolderName) {
                            return this.projectName;
                        }
                        const folder = new DocumentsFolderParse();
                        folder.objectId = folderId;
                        return folder.name;
                    });
            } else {
                path = this.comeBackNavigation
                    .slice(0, indexInit - 1)
                    .map(folderId => {
                        if (folderId == this.homeFolderName) {
                            return this.projectName;
                        }
                        const folder = new DocumentsFolderParse();
                        folder.objectId = folderId;
                        return folder.name;
                    })
                    .concat(['...'])
                    .concat(
                        this.comeBackNavigation
                            .slice(this.comeBackNavigation.length - indexFinish, this.comeBackNavigation.length)
                            .map(folderId => {
                                const folder = new DocumentsFolderParse();
                                folder.objectId = folderId;
                                return folder.name;
                            })
                    );
            }
        }
        let length = 0;
        path.forEach(name => length += this.getTextWidth(name));
        if (length > innerWidth - 20) {
            path =
                ['...'].concat(this.comeBackNavigation
                    .slice(this.comeBackNavigation.length - 1, this.comeBackNavigation.length)
                    .map(folderId => {
                        const folder = new DocumentsFolderParse();
                        folder.objectId = folderId;
                        return folder.name;
                    }));
        }
        return path;
    }

    traductionFunction = traductionFunctionMasterFolder;

    predicateFucntionToTransformStringTitle(value: string, getTextWidth: any, container: ElementRef): string {
        let valueReturn = value;
        if (stringIsSet(value)) {
            let widthContainer = window.innerWidth;
            const widthText = getTextWidth(value, '500 20px roboto');
            if (container != null && container.nativeElement != null && container.nativeElement.clientWidth != null) {
                widthContainer = container.nativeElement.clientWidth
            }
            if (widthText > widthContainer - 170) {
                const numberCharacter = Math.round((widthContainer - widthText - 30) / 10)
                const initNumberCharacter = Math.round(numberCharacter / 2);
                const lastNumberCharacter = numberCharacter - initNumberCharacter;
                valueReturn = value.slice(0, initNumberCharacter) + ' ... ' + value.slice(value.length - lastNumberCharacter, value.length);
            }
        }
        return valueReturn;
    }
}
