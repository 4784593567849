import * as Parse from 'parse';


export class SettaggiTlcNodoParse extends Parse.Object {
    public static CLASS_NAME = 'SettaggiTlcNodo';

    constructor() {
        super(SettaggiTlcNodoParse.CLASS_NAME);
    }

    public set objectId(value) {
        this.id = value;
    }

    public get objectId() {
        return this.id;
    }

    public set createdAt(value) {
        super.set('createdAt', value);
    }

    public get createdAt(): Date {
        return super.get('createdAt');
    }

    public set updatedAt(value) {
        super.set('updatedAt', value);
    }

    public get updatedAt(): Date {
        return super.get('updatedAt');
    }

    public set circuito(value) {
        super.set('circuito', value);
    }

    public get circuito(): any {
        return super.get('circuito');
    }

    public set data(value) {
        super.set('data', value);
    }

    public get data(): Date {
        return super.get('data');
    }

    public set giornoSettimana(value) {
        super.set('giornoSettimana', value);
    }

    public get giornoSettimana(): number {
        return super.get('giornoSettimana');
    }

    public set pManuale(value) {
        super.set('pManuale', value);
    }

    public get pManuale(): number {
        return super.get('pManuale');
    }

    public set progetto(value) {
        super.set('progetto', value);
    }

    public get progetto(): any {
        return super.get('progetto');
    }

    public set puntoLuce(value) {
        super.set('puntoLuce', value);
    }

    public get puntoLuce(): any {
        return super.get('puntoLuce');
    }

    public set repeatYear(value) {
        super.set('repeatYear', value);
    }

    public get repeatYear(): boolean {
        return super.get('repeatYear');
    }
    public set ricevuto(value) {
        super.set('ricevuto', value);
    }

    public get ricevuto(): boolean {
        return super.get('ricevuto');
    }

    public set tlcProfiloFunzionamento(value) {
        super.set('tlcProfiloFunzionamento', value);
    }

    public get tlcProfiloFunzionamento(): any {
        return super.get('tlcProfiloFunzionamento');
    }


    public get query(): Parse.Query<SettaggiTlcNodoParse> {
        return new Parse.Query(SettaggiTlcNodoParse)
    }
}

Parse.Object.registerSubclass(SettaggiTlcNodoParse.CLASS_NAME, SettaggiTlcNodoParse);
