<mat-card class="card-container ">
    <div>
        <form [formGroup]="formFilter">
            <div class="flex-around-center w100 flex-flow-row">
                <mat-form-field appearance="fill" style="min-width: 300px;width: 49%">
                    <mat-label>Filter value</mat-label>
                    <input formControlName="filterValue" matInput>
                </mat-form-field>
                <app-auto-complete-form
                        style="min-width: 300px;width: 49%"
                        title="key to Filter"
                        formControlName="keyFilter"
                        [items]="displayColumnsAutocomplete"
                        appearance="fill"
                        [hiddenClearButton]="true">
                </app-auto-complete-form>
            </div>
        </form>
        <mat-paginator [pageSizeOptions]="[ 10, 50, 100,500]" aria-label="Select page of users"></mat-paginator>
    </div>
    <div>
        <form [formGroup]="formUsers" [hidden]="!formUsers">
            <table mat-table [dataSource]="dataSource" matSort  matSortActive="checkBox" matSortDirection="desc" class="huna-table">
                <ng-container [matColumnDef]="'checkBox'">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        <mat-checkbox (change)="globalCheckBoxChange($event.checked)"></mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let user">
                        <mat-checkbox [formControlName]="user.id"></mat-checkbox>
                    </td>
                </ng-container>
                <ng-container *ngFor="let column of displayedColumnsNotForm">
                    <ng-container [matColumnDef]="column">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{column}}</th>
                        <td mat-cell *matCellDef="let user"> {{getValue(user, column)}} </td>
                    </ng-container>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </form>
    </div>
    <div style="position: absolute;bottom: 2px;left: 0;z-index: 10000 ;" class="flex-end-center w100">
        <div class="flex-around-center" style="position: fixed;width: 180px;max-width: calc(100% - 50px)">
            <button (click)="closeDialog()"
                    mat-raised-button color="primary">
                <mat-icon>clear</mat-icon>
            </button>
            <button (click)="addUser()"
                    mat-raised-button color="accent">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </div>
</mat-card>
