import {Component, Input, OnInit} from '@angular/core';
import {DialogPopUpInfoService} from "../../providers/services/dialog-pop-up-info.service";

@Component({
    selector: 'app-truncate-text',
    templateUrl: './truncate-text.component.html',
    styleUrls: ['./truncate-text.component.scss']
})
export class TruncateTextComponent implements OnInit {
    @Input() text: string;
    @Input() title: string;
    @Input() subTitle: string;

    constructor(private openDialogInfo: DialogPopUpInfoService) {
    }

    ngOnInit(): void {
    }

    openInfo() {
        this.openDialogInfo.openExpandedDetail({
            texts: [this.text],
            title: this.title,
            subTitle: this.subTitle,
            image: undefined
        }).subscribe()
    }
}
