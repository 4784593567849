<button mat-flat-button [color]="color" (click)="clickButton()" [matBadge]="(tresholdClickButton - numberClick)"
        [matBadgeColor]="color">
    <ng-container *ngIf="numberClick<=0; else Traduction">
        <ng-content>

        </ng-content>
    </ng-container>
</button>
<ng-template #Traduction>
    {{traductionAfterNumberClick|translate}}
</ng-template>