import {Component, OnInit, ContentChildren, QueryList, Output, EventEmitter, Input} from '@angular/core';
import {OrderableListItemDirective} from '../orderable-list-item.directive';
import {delay} from "rxjs/operators";

@Component({
    selector: 'app-orderable-list',
    templateUrl: './orderable-list.component.html',
    styleUrls: ['./orderable-list.component.scss']
})
export class OrderableListComponent implements OnInit {
    @ContentChildren(OrderableListItemDirective, {descendants: true})
    children: QueryList<OrderableListItemDirective>;

    get orderedChildren() {
        if (this.reverse) {
            return this.children.toArray().reverse();
        } else {
            return this.children;
        }
    }

    @Input()
    reverse = false;

    @Output()
    orderChange: EventEmitter<OrderChanged> = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

    animatingSwap = false;

    async moveItem(previousIndex: number, delta: -1 | 1, element: HTMLElement) {
        if (!this.animatingSwap) {
            const swapElement = element[delta === -1 ? 'previousElementSibling' : 'nextElementSibling'] as HTMLElement | null;
            if (swapElement) {
                await this.animateSwap(element, swapElement);
                if (this.reverse) {
                    this.orderChange.emit({
                        oldIndex: this.children.length - 1 - previousIndex,
                        newIndex: this.children.length - 1 - (previousIndex + delta)
                    });
                } else {
                    this.orderChange.emit({
                        oldIndex: previousIndex,
                        newIndex: previousIndex + delta
                    });
                }
            }
        }
    }

    moveUp(index: number, element: HTMLElement) {
        this.moveItem(index, -1, element);
    }

    moveDown(index: number, element: HTMLElement) {
        this.moveItem(index, 1, element);
    }

    async animateSwap(foreElement: HTMLElement, backElement: HTMLElement) {
        this.animatingSwap = true;

        const transitionDuration = 0.4;
        const transitionFunction = 'ease';

        let deltaFore;
        let deltaBack;

        if (foreElement.offsetTop > backElement.offsetTop) {
            deltaFore = -(foreElement.offsetTop - backElement.offsetTop);
            deltaBack = foreElement.offsetTop - backElement.offsetTop + foreElement.offsetHeight - backElement.offsetHeight;
        } else {
            deltaBack = +(foreElement.offsetTop - backElement.offsetTop);
            deltaFore = -(foreElement.offsetTop - backElement.offsetTop + foreElement.offsetHeight - backElement.offsetHeight);
        }

        foreElement.style.position = 'relative';
        foreElement.style.zIndex = '1';
        backElement.style.position = 'relative';
        backElement.style.zIndex = '0';
        foreElement.style.top = `0px`;
        backElement.style.top = `0px`;
        foreElement.style.transition = `top ${transitionDuration}s ${transitionFunction}`;
        backElement.style.transition = `top ${transitionDuration}s ${transitionFunction}`;
        void foreElement.clientHeight;
        foreElement.style.top = `${deltaFore}px`;
        backElement.style.top = `${deltaBack}px`;
        foreElement.style.animation = `pulse-forward ${transitionDuration}s ${transitionFunction}`;
        backElement.style.animation = `pulse-backward ${transitionDuration}s ${transitionFunction}`;
        await delay(transitionDuration * 1000)
        foreElement.style.position = '';
        foreElement.style.zIndex = '';
        backElement.style.position = '';
        backElement.style.zIndex = '';
        foreElement.style.transition = '';
        backElement.style.transition = '';
        foreElement.style.top = '';
        backElement.style.top = '';
        foreElement.style.animation = '';
        backElement.style.animation = '';
        this.animatingSwap = false;
    }

}

export interface OrderChanged {
    oldIndex: number;
    newIndex: number;
}