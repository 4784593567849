import {Injectable} from '@angular/core';
import {
    ConfirmDeleteDialogComponent
} from 'src/app/components/confirm-delete/confirm-delete-dialog/confirm-delete-dialog.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {
    SelectNewElementComponent
} from 'src/app/components/confirm-delete/select-new-element/select-new-element.component';
import {DialogExportComponent} from '../../components/pop-up-export/dialog-export/dialog-export.component';
import {SeeOnTheMapComponent} from '../../components/pop-up-export/see-on-the-map/see-on-the-map.component';
import {
    EliminateAllElementComponent
} from '../../components/confirm-delete/eliminate-all-element/eliminate-all-element.component';
import {WhatImportComponent} from '../../components/confirm-delete/what-import/what-import.component';
import {SelectRowComponent} from '../../components/confirm-delete/select-row/select-row.component';
import {Observable, Subject, Subscription} from 'rxjs';
import {PrintStreetComponent} from '../../components/pop-up-export/print-street/print-street.component';
import {
    PrintReportEnergiaComponent
} from '../../components/pop-up-export/print-report-energia/print-report-energia.component';
import {InfoPrintStreetComponent} from '../../components/pop-up-info/info-print-street/info-print-street.component';
import {
    CreateNewIotDevicesComponent
} from "../../components/confirm-delete/create-new-iot-devices/create-new-iot-devices.component";
import {
    BatchLightEditorComponent
} from "../../components/batch-light-editor/batch-light-editor/batch-light-editor.component";
import {PuntiLuceParse} from "../../models/PuntiLuce.Parse";
import {SelectOrCreateComponent} from "../../components/confirm-delete/select-or-create/select-or-create.component";
import {CircuitiParse} from "../../models/Circuiti.Parse";
import {
    BatchEditorFilesComponent
} from "../../components/batch-light-editor/batch-editor-files/batch-editor-files.component";
import {DocumentsFileParse} from "../../models/DocumentsFile.Parse";
import {
    SelectElementToProceedComponent
} from "../../components/confirm-delete/select-element-to-proceed/select-element-to-proceed.component";
import {ArredoUrbanoParse} from "../../models/ArredoUrbano.Parse";
import {
    BatchEditorAssignSchedeManutenzioneComponent
} from "../../components/batch-light-editor/batch-editor-assign-schede-manutenzione/batch-editor-assign-schede-manutenzione.component";
import {SchedaManutenzioneParse} from "../../models/SchedaManutenzione.Parse";
import {
    ListAndSelectToAddSchedeManutenzioneComponent
} from "../../components/list-element/list-and-select-to-add-schede-manutenzione/list-and-select-to-add-schede-manutenzione.component";
import {
    NewReportCommentDialogComponent
} from "../../pages-modules/reports/new-report-comment-dialog/new-report-comment-dialog.component";
import {ChooseActionsComponent} from "../../components/confirm-delete/choose-actions/choose-actions.component";
import {arrayIsSet, getUniqueValueInArray, isNotNullOrUndefined, KeyStringValue} from "../../models/Models";
import {
    ImportLocalizedImageComponent
} from "../../components/pop-up-info/import-localized-image/import-localized-image.component";
import {
    BatchRemoteControlSettingsComponent
} from "../../components/batch-light-editor/batch-remote-control-settings/batch-remote-control-settings.component";
import {
    BatchVirtualMidnightComponent
} from "../../components/batch-light-editor/batch-virtual-midnight/batch-virtual-midnight.component";
import {TLC_ProfiloFunzionamentoParse} from "../../models/TLC_ProfiloFunzionamento.Parse";
import {FotoTipologiaType} from "./foto-tipologia.service";
import {
    BatchEditorFotoTipologiaComponent
} from "../../components/batch-light-editor/batch-editor-foto-tipologia/batch-editor-foto-tipologia.component";
import {debounceTime, distinctUntilChanged, map, startWith} from "rxjs/operators";

export type TypeBatchEditVirtualMidnight = {
    lightPoints: PuntiLuceParse[],
    programmiMvValues?: any,
    profileTlc?: TLC_ProfiloFunzionamentoParse,
    days?: number[],
    repeatYear: boolean,
    dates?: Date[],
    versioneProtocollo?: string
    removeDatesSettings?: string[]
}

@Injectable({
    providedIn: 'root'
})
export class DialogPopUpService {

    constructor(private dialog: MatDialog) {
    }

    public openDialogForDelete(messages: string | string[], position = undefined) {
        const arrayMessage: string[] = (Array.isArray(messages)) ? messages : [messages];
        if (!isNotNullOrUndefined(position)) {
            position = {top: '30px', right: '30px'}
        } else if (position == 'center') {
            position = undefined;
        }
        const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            width: '340px',
            minHeight: '200px',
            position,
            data: {messages: arrayMessage}
        });
        return dialogRef.afterClosed();
    }

    public openDialogForDeleteAllVisualizedElement(numberCircuit, numberLightPoint) {
        const width = 340;
        const heigth = 200;
        const right = window.innerWidth / 2 - width / 2 + 20;
        const bottom = window.innerHeight / 2 - heigth / 2;
        const dialogRef = this.dialog.open(EliminateAllElementComponent, {
            width: width + 'px',
            height: heigth + 'px',
            position: {right: right + 'px', bottom: bottom + 'px'},
            data: {numberCircuiti: numberCircuit, numberPuntiLuce: numberLightPoint}
        });
        return dialogRef.afterClosed();
    }

    public openDialogSelectElementToProceed(disabledButton: {
        lightPoint: boolean,
        circuits: boolean,
        arredoUrbano: boolean
    }): Observable<any> {
        const dialogRef = this.dialog.open(SelectElementToProceedComponent, {
            data: {disabledButton}
        });
        return dialogRef.afterClosed();
    }

    public openDialogForNewElement(message, numeriQuadro, typesArredoUrbano) {
        const right = window.innerWidth / 2 - 340 / 2 + 20;
        const bottom = window.innerHeight / 2 - 200 / 2;
        const dialogRef = this.dialog.open(SelectNewElementComponent, {
            width: '340px',
            height: '300px',
            position: {right: right + 'px', bottom: bottom + 'px'},
            data: {message: message, numeriQuadro: numeriQuadro, typesArredoUrbano}
        });
        return dialogRef.afterClosed();
    }

    public openDialogForImportElement(message) {
        const width = 350;
        const height = 400;
        const right = window.innerWidth / 2 - width / 2 + 20;
        const bottom = window.innerHeight / 2 - height / 2;
        const dialogRef = this.dialog.open(WhatImportComponent, {
            width: width + 'px',
            height: height + 'px',
            position: {right: right + 'px', bottom: bottom + 'px'},
            data: {message: message}
        });
        return dialogRef.afterClosed();
    }

    public openDialogForImportElementSelectRow(message, actualRowTitle, allTitle, rowTitleRaw, className, getPathTraduction: (key: string) => string = undefined): Observable<{
        newRowTitle
    }> {
        const width = window.innerWidth;
        const height = 800;
        const right = 0;
        const bottom = window.innerHeight / 2 - height / 2;
        const dialogRef = this.dialog.open(SelectRowComponent, {
            width: 100 + 'vW',
            data: {
                message: message,
                actualRowTitle: actualRowTitle,
                allTitle: allTitle,
                rowTitleRaw: rowTitleRaw,
                className: className,
                getPathTraduction
            }
        });
        return dialogRef.afterClosed();
    }

    public openDialogForExportDate(email) {
        const width = (window.innerWidth < 320) ? window.innerWidth : 320;
        const height = (window.innerHeight < 500) ? window.innerHeight : 500;
        const right = window.innerWidth / 2 - width / 2;
        const bottom = window.innerHeight / 2 - height / 2;
        const dialogRef = this.dialog.open(DialogExportComponent, {
            width: width + 'px',
            height: height + 'px',
            position: {right: right + 'px', bottom: bottom + 'px'},
            data: {email: email}
        });
        return dialogRef.afterClosed();
    }

    public openDialogSeeMap(cosa: 'planimetry' | 'report', ciruitiInProject, puntiLuce, circuiti, showDetails = false, showLabels = false, selectable = false, fitBounds = true,maxLightPointSelectable=undefined,selectedLightPoints=undefined) {
        const width = window.innerWidth;
        const height = window.innerHeight;
        const right = window.innerWidth / 2 - width / 2 + 20;
        const bottom = window.innerHeight / 2 - height / 2;
        let data = {};
        if (ciruitiInProject != null) {
            data = {ciruitiInProject, showSearchLightPoint: false};
        }
        data['showDetails'] = showDetails;
        data['showLabels'] = showLabels;
        data['selectable'] = selectable;
        data['fitBounds'] = fitBounds;
        data['maxLightPointSelectable'] = maxLightPointSelectable;
        data['selectedLightPoints'] = selectedLightPoints;
        if (Array.isArray(puntiLuce)) {
            data['puntiLuce'] = puntiLuce;
        }
        if (Array.isArray(circuiti)) {
            data['circuiti'] = circuiti;
        }
        const dialogRef = this.dialog.open(SeeOnTheMapComponent, {
            minWidth: '100%',
            maxWidth: '100%',
            minHeight: height + 'px',
            maxHeight: '100%',
            panelClass: 'custom-full-screen-dialog-container',
            data: data
        });
        return dialogRef.afterClosed();
    }

    public openDialogSeeMapLive$(values$: Observable<any> | undefined = undefined, filtri: KeyStringValue<any>, showDetails = false, showLabels = false, showSearchLightPoint = false): Observable<{
        save?: any,
        circuiti?: CircuitiParse[],
        puntiLuce?: PuntiLuceParse[],
        center?: google.maps.LatLng,
        tipologiaMappa?: google.maps.MapTypeId,
        zoom?: number,
        closed?: boolean,
        add?: {
            filtriCircuiti: string[],
            filtriPuntiLuce: string[],
            filtriObjectIdPuntiLuce: string[],
            boundsPuntiLuce: google.maps.LatLngBounds | undefined,
            searchSmart: KeyStringValue<any> | undefined,
        },
        remove?: {
            filtriCircuiti: string[],
            filtriPuntiLuce: string[],
            filtriObjectIdPuntiLuce: string[],
            searchSmart: KeyStringValue<any> | undefined,
        },
    }> {
        const width = window.innerWidth;
        const height = window.innerHeight;

        return new Observable((subscriber => {
            const dialogRef = this.dialog.open(SeeOnTheMapComponent, {
                minWidth: '100%',
                maxWidth: '100%',
                minHeight: height + 'px',
                maxHeight: '100%',
                panelClass: 'custom-full-screen-dialog-container',
                data: {showDetails, showLabels, showSearchLightPoint}
            });
            const subs = new Subscription()
            const valueInComponent = dialogRef.componentInstance.livesValues
            dialogRef.componentInstance.loadingElement = true;
            subs.add(values$.subscribe(values => {
                dialogRef.componentInstance.loadingElement = false;
                if (values != null) {
                    valueInComponent.get('inValues').setValue(values);
                } else {
                    valueInComponent.get('inValues').reset();
                }
            }))

            const outValuesAdd$ = valueInComponent.get('outValues').get('add').valueChanges.pipe(
                startWith(undefined),
                map(() => valueInComponent.get('outValues').get('add').value)
            )
            const outValuesRemove$ = valueInComponent.get('outValues').get('remove').valueChanges.pipe(
                startWith(undefined),
                map(() => valueInComponent.get('outValues').get('remove').value)
            )
            subs.add(
                outValuesAdd$.subscribe(values => {
                    dialogRef.componentInstance.loadingElement = true;
                    subscriber.next({add: values});
                })
            )
            subs.add(
                outValuesRemove$.subscribe(values => {
                    dialogRef.componentInstance.loadingElement = true;
                    subscriber.next({remove: values});
                })
            )
            if (filtri != null) {
                valueInComponent.get('supportValues').reset()
                Object.keys(filtri).forEach(key => {
                    if (valueInComponent.get('supportValues').get(key) != null) {
                        valueInComponent.get('supportValues').get(key).setValue(arrayIsSet(filtri[key]) ? getUniqueValueInArray(filtri[key]) : [],);
                    }
                })
            }

            dialogRef.afterClosed().subscribe(value => {
                subscriber.next(value);
                subscriber.complete();
                subscriber.unsubscribe();
                subs.unsubscribe();
            });
        }))


        // const width = window.innerWidth;
        // const height = window.innerHeight;
        // const right = window.innerWidth / 2 - width / 2 + 20;
        // const bottom = window.innerHeight / 2 - height / 2;
        // let data = {};
        // if (ciruitiInProject != null) {
        //     data = {ciruitiInProject};
        // }
        // data['showDetails'] = showDetails;
        // data['showLabels'] = showLabels;
        // if (Array.isArray(puntiLuce)) {
        //     data['puntiLuce'] = puntiLuce;
        // }
        // if (Array.isArray(circuiti)) {
        //     data['circuiti'] = circuiti;
        // }
        // const dialogRef = this.dialog.open(SeeOnTheMapComponent, {
        //     minWidth: '100%',
        //     maxWidth: '100%',
        //     minHeight: height + 'px',
        //     maxHeight: '100%',
        //     panelClass: 'custom-full-screen-dialog-container',
        //     data: data
        // });
        // return dialogRef.afterClosed();
    }

    public openPrintStreet(strada, risultatiCalcolo, fotometria, organizzazione, ipea) {
        const width = window.innerWidth;
        const height = window.innerHeight;
        const right = window.innerWidth / 2 - width / 2 + 20;
        const bottom = window.innerHeight / 2 - height / 2;
        const dialogRef = this.dialog.open(PrintStreetComponent, {
            minWidth: '100%',
            maxWidth: '100%',
            minHeight: height + 'px',
            maxHeight: '100%',
            data: {
                strada: strada,
                risutatiCalcoloIlluminotecnico: risultatiCalcolo,
                fotometria: fotometria,
                organizzazione: organizzazione,
                ipea: ipea
            }
        });
        return dialogRef.afterClosed();
    }

    public openPrintReportEnergia(dataForChart, organizzazione, dataRiferimento) {
        const width = window.innerWidth;
        const height = window.innerHeight;
        const right = window.innerWidth / 2 - width / 2 + 20;
        const bottom = window.innerHeight / 2 - height / 2;
        const dialogRef = this.dialog.open(PrintReportEnergiaComponent, {
            minWidth: '100%',
            maxWidth: '100%',
            minHeight: height + 'px',
            maxHeight: '100%',
            data: {chart: dataForChart, organizzazione: organizzazione, dataRiferimento}
        });
        return dialogRef.afterClosed();
    }

    public openInfoPrintStreet() {
        const width = window.innerWidth;
        const height = window.innerHeight;
        const right = window.innerWidth / 2 - width / 2 + 20;
        const bottom = window.innerHeight / 2 - height / 2;
        const dialogRef = this.dialog.open(InfoPrintStreetComponent, {
            minWidth: '100%',
            maxWidth: '100%',
            minHeight: height + 'px',
            maxHeight: '100%',
            data: {}
        });
        return dialogRef.afterClosed();
    }

    public openCreateIotDevices(circuiti) {
        const width = 310;
        const height = 450;
        const dialogRef = this.dialog.open(CreateNewIotDevicesComponent, {
            width: width + 'px',
            height: height + 'px',
            data: {circuiti: circuiti},
            position: {right: '10px', bottom: '50px'},
            autoFocus: false,
        });
        return dialogRef.afterClosed();
    }

    public openCreateUpdate(data: { valueForm: string, html: string }[], title: {
        popUp: string,
        form: string,
        createNew: string,
        update: string
    }, icon: { createNew: string, update: string }) {
        const width = 310;
        const height = 450;
        const dialogRef = this.dialog.open(SelectOrCreateComponent, {
            width: width + 'px',
            height: height + 'px',
            data: {data, title, icon},
            autoFocus: false,
        });
        return dialogRef.afterClosed();
    }

    public openCreateUpdateGrouopPhotometry(data: { valueForm: string, html: string }[]): Observable<{
        isNew,
        valueForm
    }> {
        const title =
            {
                popUp: 'fotometrie.addToPhotometries',
                form: 'fotometrie.favorites',
                createNew: 'fotometrie.createNew',
                update: 'fotometrie.update'
            }
        const icon = {
            createNew: 'create_new_folder',
            update: 'add'
        }
        return this.openCreateUpdate(data, title, icon);
    }

    public openCreateUpdateFolder(data: { valueForm: string, html: string }[]): Observable<{ isNew, valueForm }> {
        const title =
            {
                popUp: 'fileManager.creteNewFolder',
                form: 'fileManager.name',
                createNew: 'fileManager.createNew',
                update: 'fileManager.update'
            }
        const icon = {
            createNew: 'create_new_folder',
            update: 'add'
        }
        return this.openCreateUpdate(data, title, icon);
    }


    public openBatchEdit(puntiLuce: PuntiLuceParse[], circuitiAppartenentiAlProgettoInput, stradeAppartenentiAlProgettoInput) {
        const width = window.innerWidth;
        const height = window.innerHeight;
        const right = window.innerWidth / 2 - width / 2 + 20;
        const bottom = window.innerHeight / 2 - height / 2;
        const dialogRef = this.dialog.open(BatchLightEditorComponent, {
            minWidth: '100%',
            maxWidth: '100%',
            minHeight: height + 'px',
            maxHeight: '100vh',
            autoFocus: false,
            panelClass: ['custom-dialog-container'],

            data: {puntiLuce, circuitiAppartenentiAlProgettoInput, stradeAppartenentiAlProgettoInput}
        });
        return dialogRef.afterClosed();
    }


    public openBatchEditFotoTipologia(lightPoints: PuntiLuceParse[], fotoTipologia: FotoTipologiaType[], width: string = '100%', height: string = '100%'): Observable<any> {
        const dialogRef = this.dialog.open(BatchEditorFotoTipologiaComponent, {
            width: width,
            maxWidth: '100%',
            autoFocus: false,
            data: {lightPoints, fotoTipologia}
        });
        return dialogRef.afterClosed();
    }

    public openBatchEditConnectedFile(lightPoints: PuntiLuceParse[], circuits: CircuitiParse[], arrediUrbani: ArredoUrbanoParse[], width: string = '100%', height: string = '100%'): Observable<{
        newFilesToConnect: File[],
        existedFilesToConnect: DocumentsFileParse[]
    }> {
        const dialogRef = this.dialog.open(BatchEditorFilesComponent, {
            width: width,
            maxWidth: '100%',
            autoFocus: false,
            data: {lightPoints, circuits, arrediUrbani}
        });
        return dialogRef.afterClosed();
    }

    public openBatchRemoteControl(settaggiTlcCircuito: any[], width: string = undefined, height: string = undefined): Observable<{
        settaggioTlcCircuiti: any,
        values: any
    }[]> {
        const dialogRef = this.dialog.open(BatchRemoteControlSettingsComponent, {
            width: width,
            maxWidth: '100%',
            height: height,
            maxHeight: '100%',
            autoFocus: false,
            data: {settaggiTlcCircuito}
        });
        return dialogRef.afterClosed();
    }

    public openBatchEditAssignSchedaManutenzione(lightPoints: PuntiLuceParse[], circuits: CircuitiParse[], arrediUrbani: ArredoUrbanoParse[], schedeManutenzione: SchedaManutenzioneParse[], dialog, width: string = '100%', height: string = '100%'): Observable<{
        selectedSchedeManutenzione: { remove: boolean, items: any[] },
        calendariDaCreare: { items: { maintenanceDate: Date, schedaManutenzione: SchedaManutenzioneParse }[] }
    }> {
        const dialogRef = this.dialog.open(BatchEditorAssignSchedeManutenzioneComponent, {
            width: width,
            maxWidth: '100%',
            height: height,
            maxHeight: '100%',
            autoFocus: false,
            data: {lightPoints, circuits, arrediUrbani, schedeManutenzione, dialog}
        });
        return dialogRef.afterClosed();
    }

    public openAddAssignSchedaManutenzione(schedeManutenzione: SchedaManutenzioneParse[], schedeManutenzioniGiaAssociate: SchedaManutenzioneParse[], width: string = '100%', height: string = '100%'): Observable<{
        selectedSchedeManutenzione: any[],
        calendariDaCreare: { maintenanceDate: Date, schedaDiManutenzione: SchedaManutenzioneParse }[]
    }> {
        const dialogRef = this.dialog.open(ListAndSelectToAddSchedeManutenzioneComponent, {
            width: width,
            maxWidth: '100%',
            height: height,
            maxHeight: '100%',
            autoFocus: false,
            data: {schedeManutenzione, schedeManutenzioniGiaAssociate}
        });
        return dialogRef.afterClosed();
    }

    public openBatchEditVirtualMidnight(lightPoints: PuntiLuceParse[], circuits, otherValues = undefined, width: string = '100%', height: string = '100%'): Observable<TypeBatchEditVirtualMidnight> {
        let data;
        if (otherValues != null) {
            data = {lightPoints, circuits, ...otherValues}
        } else {
            data = {lightPoints, circuits};
        }

        const dialogRef = this.dialog.open(BatchVirtualMidnightComponent, {
            width: width,
            maxWidth: '100%',
            maxHeight: '100%',
            height: height,
            autoFocus: false,
            panelClass: ['custom-dialog-container'],
            data
        });
        return dialogRef.afterClosed();
    }

    public openGenerateReport(planSlug, tuttiAbbonamenti, myRole, schedeManutenzione, style = null): Observable<{
        reportObjectId: string,
        typeReport: string,
        value: any,
        event: any,
        nuovoStato: string
    }> {
        const data = {
            reportObjectId: planSlug,
            tuttiAbbonamenti: tuttiAbbonamenti,
            myRole: myRole,
            schedeManutenzione: schedeManutenzione
        }
        let config;
        if (isNotNullOrUndefined(style)) {
            config = {
                data, ...style,
                hasBackdrop: true,
                backdropClass: 'feedbackBackdrop',
                autoFocus: false,
            }
        } else {
            config = {
                data,
                hasBackdrop: true,
                backdropClass: 'feedbackBackdrop', autoFocus: false,
            }
        }
        const a = this.dialog.open(NewReportCommentDialogComponent, config)
        a.afterOpened().subscribe(a => {
            try {
                window.document.querySelector<any>('.feedbackBackdrop').parentNode.style.zIndex = "900"
            } catch (e) {
                console.error(e)
            }
        })
        return a.afterClosed()
    }

    public openDialogPossibleActions(message: string, actions: any[], size: 'S' | 'M' = undefined) {
        let width;
        if (size === "S") {
            width = '300px';
        } else if (size === "M") {
            width = '500px';
        } else if (arrayIsSet(actions)) {
            width = Math.min(actions.length * 300 + 200, window.innerWidth) + 'px';
        } else {
            width = '500px';
        }
        const dialogRef = this.dialog.open(ChooseActionsComponent, {
            width,
            maxWidth: '100%',
            data: {message, actions}
        });
        return dialogRef.afterClosed();
    }

    public openDialogAssociateImageAtElement(value) {
        let width;
        if (window.innerWidth - 20 < 500) {
            width = '100vw';
        } else {
            width = '500px';
        }
        const dialogRef = this.dialog.open(ImportLocalizedImageComponent, {
            width,
            maxWidth: '100%',
            data: value
        });
        return dialogRef.afterClosed();
    }


}
