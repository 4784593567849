import {Pipe, PipeTransform} from '@angular/core';
import {UserService} from "../services/user.service";
import {EMPTY, Observable} from "rxjs";
import {map} from "rxjs/operators";

@Pipe({
    name: 'getUserDetails'
})
export class GetUserDetailsPipe implements PipeTransform {
    constructor(private userService: UserService) {
    }

    transform(user: Parse.User, getValue?: (u: Parse.User) => any): Observable<any> {
        if(user==null){
            return EMPTY;
        }
        if (getValue != null) {
            return this.userService.getUserDetails$(user).pipe(
                map(u => {
                   return  getValue(u)
                })
            )
        } else {
            return this.userService.getUserDetails$(user)
        }
    }

}
