import {Directive, ElementRef, Input, SimpleChanges} from '@angular/core';

@Directive({
    selector: '[appImageDisabled]'
})
export class ImageDisabledDirective {
    @Input() appImageDisabled: 'default' | 'warning' | 'disactived';

    constructor(private elementRef: ElementRef) {
    }

    ngOnChanges(changes: SimpleChanges) {
        this.updateItem();
    }

    private updateItem() {
        if (this.appImageDisabled === 'warning') {
            this.elementRef.nativeElement.style.filter = 'invert(70%) sepia(36%) saturate(4779%) hue-rotate(338deg) brightness(98%) contrast(94%)';
        } else if (this.appImageDisabled === 'disactived') {
            this.elementRef.nativeElement.style.opacity = 0.3;
            this.elementRef.nativeElement.style.filter = 'grayscale(' + 100 + '%)';
        } else {
            this.elementRef.nativeElement.style.filter = '';
            this.elementRef.nativeElement.style.opacity = 1;
        }
    }
}
