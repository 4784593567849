import {Injectable} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {isNotNullOrUndefined} from "../../models/Models";

@Injectable({
    providedIn: 'root'
})
export class PlanFormService {

    constructor(
        private fb: UntypedFormBuilder
    ) {

    }

    public getPlanForm(plan): UntypedFormGroup {
        const template = !isNotNullOrUndefined(plan.template) ? '3' : plan['template'].toString();
        return this.fb.group({
                name: [plan['nome']],
                title: [plan['titolo']],
                logoAzienda: [plan['logoAzienda']],
                logoProgetto: [plan['logoProgetto']],
                image1: [plan['image1']],
                image2: [plan['image2']],
                image3: [plan['image3']],
                image4: [plan['image4']],
                definitivo: [plan['definitivo']],
                legenda: [plan['legenda'] ? plan['legenda'] : false],
                tipologia: [plan['tipologia']],
                formato: [plan['formato']],
                template: [template],
                mapType: [plan['tipologiaMappa']],
                etichettaPuntoLuce: [plan['etichettaPuntoLuce']]
            }
        );
    }

    public getCreatePlanForm(): UntypedFormGroup {
        return this.fb.group({
                name: ['', Validators.required]
            }
        );
    }

}
