import {ElementRef, Injectable} from '@angular/core';
import {
    Chart,
    ChartData,
    ChartDataSets, ChartPluginsOptions,
    ChartPoint,
    ChartScales,
    LinearScale,
    LogarithmicScale,
    TimeScale
} from 'chart.js';
import {arrayIsSet, hunaChartColors, stringIsSet} from "../../models/Models";
import {FileServiceService} from "./file-service.service";
import {Context} from "chartjs-plugin-datalabels/types/context";

@Injectable({
    providedIn: 'root'
})
export class ChartServiceService {

    constructor() {
    }

    /**
     * funziona bene con con type chart  bar line
     * @param istanzaGrafico è il riferimento al grafico nel lato html
     * @param labels sono i titoli sull asse delle x
     * @param dataSetGraph sono i set delle varie componenti del grafico
     */

    private tooltipOption = {
        enabled: true,
        callbacks: {
            title(item: Chart.ChartTooltipItem[], data: Chart.ChartData): string | string[] {
                return ['aaa'];
            },
            label(tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData): string | string[] {
                return 'bbb';
            },
        }
    };

    getDataChartMixedBarLineChart(labels: string[] | number[], dataSetGraph: ChartDataSets[], title: {
        yLabel: string,
        chartTitle: string
    }, animationDuration = 0) {
        let backgroundColor = 'white';
        Chart.plugins.register({
            beforeDraw: function (c) {
                var ctx = c.ctx;
                ctx.fillStyle = backgroundColor;
                ctx.fillRect(0, 0, c.width, c.height);
            }
        });
        const data = {
            labels: labels,
            datasets:
            dataSetGraph
            ,
        }

        return {
            type: 'line',
            data,
            options: {
                animation: {duration: animationDuration},
                responsive: true,
                maintainAspectRatio: false,
                title: {
                    display: !!title.chartTitle,
                    text: title.chartTitle
                },
                // tooltips: this.tooltipOption,
                plugins: {
                    showTooltips: false,
                    datalabels: {
                        display: false,
                        color: 'red',
                        labels: {
                            title: {
                                font: {
                                    weight: 'bold'
                                }
                            },
                            value: {
                                color: 'green'
                            }
                        }
                    }
                },
                legend: {
                    labels: {
                        fontStyle: 'bold',
                        fontSize: 15,
                    }
                },
                scales: {
                    yAxes: [{
                        scaleLabel: {
                            fontStyle: 'bold',
                            fontSize: 20,
                            display: true,
                            labelString: title.yLabel
                        },
                        ticks: {
                            fontStyle: 'bold',
                            fontSize: 15,
                            suggestedMin: null,
                            suggestedMax: null
                        },
                    }],
                    xAxes: [{
                        ticks: {
                            fontStyle: 'bold',
                            fontSize: 15,
                        },
                    }],
                }
            }
        }


    }

    public getMixedBarLineChart(istanzaGrafico: ElementRef, labels: string[] | number[], dataSetGraph: ChartDataSets[], title: {
        yLabel: string,
        chartTitle: string
    }, animationDuration = 0): Chart {

        let backgroundColor = 'white';
        Chart.plugins.register({
            beforeDraw: function (c) {
                var ctx = c.ctx;
                ctx.fillStyle = backgroundColor;
                ctx.fillRect(0, 0, c.width, c.height);
            }
        });
        const data = {
            labels: labels,
            datasets: [
                ...dataSetGraph
            ],
        }
        return (
            new Chart(istanzaGrafico.nativeElement, {
                type: 'bar',
                data,
                options: {
                    aspectRatio: 4,
                    animation: {duration: animationDuration},
                    responsive: true,
                    maintainAspectRatio: false,
                    title: {
                        display: !!title.chartTitle,
                        text: title.chartTitle
                    },
                    // tooltips: this.tooltipOption,
                    plugins: {
                        showTooltips: false,
                        datalabels: {
                            display: false,
                            color: 'red',
                            labels: {
                                title: {
                                    font: {
                                        weight: 'bold'
                                    }
                                },
                                value: {
                                    color: 'green'
                                }
                            }
                        }
                    },
                    legend: {
                        labels: {
                            fontStyle: 'bold',
                            fontSize: 15,
                        }
                    },
                    scales: {
                        yAxes: [{
                            scaleLabel: {
                                fontStyle: 'bold',
                                fontSize: 20,
                                display: true,
                                labelString: title.yLabel
                            },
                            ticks: {
                                fontStyle: 'bold',
                                fontSize: 15,
                                suggestedMin: null,
                                suggestedMax: null
                            },
                        }],
                        xAxes: [{
                            ticks: {
                                fontStyle: 'bold',
                                fontSize: 15,
                            },
                        }],
                    }
                }
            })
        );
    }

    public getBoubleChart(istanzaGrafico: ElementRef, labels: string[] | number[], dataSetGraph: ChartDataSets[], Y: {
        min: number,
        max: number
    }, title: { label: string, yLabel: string, chartTitle: string }, animationDuration = 3): Chart {
        return (
            new Chart(istanzaGrafico.nativeElement, {
                type: 'bubble',
                data: {
                    datasets: [...dataSetGraph],
                },
                options: {
                    aspectRatio: 4,

                    animation: {duration: animationDuration},
                    title: {
                        display: true,
                        text: title.chartTitle
                    },
                    plugins: {
                        showTooltips: false,
                        datalabels: {
                            display: false,
                            color: 'red',
                            labels: {
                                title: {
                                    font: {
                                        weight: 'bold'
                                    }
                                },
                                value: {
                                    color: 'green'
                                }
                            }
                        }
                    },
                    scales: {
                        yAxes: [{
                            scaleLabel: {
                                display: true,
                                labelString: title.yLabel
                            },
                            ticks: {
                                suggestedMin: Y.min,
                                suggestedMax: Y.max
                            }
                        }],
                    }

                }
            })
        );
    }

    public getDataPieDoughnotChart(labels: string[] | number[], dataSetGraph: ChartDataSets[], title: {
        titleTooltip: string
    }, type: 'doughnut' | 'pie' = 'doughnut', animationDuration = 0, unitOfMeasure = 'kW', typeFormatterFunction = '%') {

        let backgroundColor = 'white';
        Chart.plugins.register({
            beforeDraw: function (c) {
                var ctx = c.ctx;
                ctx.fillStyle = backgroundColor;
                ctx.fillRect(0, 0, c.width, c.height);
            }
        });

        const tooltipOption = {
            enabled: true,
            callbacks: {
                label(tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData): string | string[] {
                    return '';
                },
                beforeTitle(item: Chart.ChartTooltipItem[], data: Chart.ChartData): string | string[] {
                    return title.titleTooltip;
                },
                title(item: Chart.ChartTooltipItem[], data: Chart.ChartData): string | string[] {
                    return parseFloat((data.datasets[0].data[item[0].index].toString())).toFixed(3) + '\n' + unitOfMeasure;

                },

            }
        };
        let formatterFunction;
        if (typeFormatterFunction == '%') {
            formatterFunction = function (value, context) {
                let sum: number = 0;
                context.dataset.data.forEach((data) => sum += data);
                if (sum > 0) {
                    return Math.round((context.dataset.data[context.dataIndex] as number / sum) * 100) + ' %';
                } else {
                    return parseFloat((context.dataset.data[context.dataIndex] as string)).toFixed(2) + '\n' + unitOfMeasure;
                }

            }
        }
        const plugins = {
            afterLayout: function (chart) {
                let total = chart.data.datasets[0].data.reduce((a, b) => {
                    return a + b;
                });
                chart.legend.legendItems.forEach(
                    (label) => {
                        let value = chart.data.datasets[0].data[label.index];

                        label.text += ' - ' + (value / total * 100).toFixed(0) + '%';
                        return label;
                    }
                );
            }
        };
        return {
            type: type,
            data: {
                labels: labels,
                datasets: dataSetGraph,

            },
            options: {
                animation: false,
                title: {
                    display: false,
                    text: null
                },
                tooltips: tooltipOption,
                legend: {
                    display: true,
                    position: 'right',
                    labels: {}
                },
                plugins: {
                    showTooltips: false,
                    datalabels: {
                        display: true,
                        formatter: formatterFunction,
                        color: 'rgb(64,64,64)',
                        labels: {
                            title: {
                                font: {
                                    weight: 'normal'
                                }
                            },
                            value: {
                                color: 'rgb(64,64,64)'
                            }
                        }
                    }
                },
                scales: {
                    yAxes: [{
                        display: false,
                        scaleLabel: {
                            display: false,
                            labelString: null
                        },
                        gridLines: {
                            display: false
                        },
                        ticks: {
                            suggestedMin: null,
                            suggestedMax: null
                        }
                    }],
                    xAxes: [{
                        display: false,
                        scaleLabel: {
                            display: false,
                            labelString: null
                        },
                        gridLines: {
                            display: false
                        },
                        ticks: {
                            suggestedMin: null,
                            suggestedMax: null
                        }
                    }],
                }

            }
        }
    }

    public getPieDoughnotChart(riferimentoGrafico: ElementRef, labels: string[] | number[], dataSetGraph: ChartDataSets[], title: {
        titleTooltip: string
    }, type: 'doughnut' | 'pie' = 'doughnut', animationDuration = 0, unitOfMeasure = 'kW', typeFormatterFunction = '%'): Chart {

        let backgroundColor = 'white';
        Chart.plugins.register({
            beforeDraw: function (c) {
                var ctx = c.ctx;
                ctx.fillStyle = backgroundColor;
                ctx.fillRect(0, 0, c.width, c.height);
            }
        });

        const tooltipOption = {
            enabled: true,
            callbacks: {
                label(tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData): string | string[] {
                    return '';
                },
                beforeTitle(item: Chart.ChartTooltipItem[], data: Chart.ChartData): string | string[] {
                    return title.titleTooltip;
                },
                title(item: Chart.ChartTooltipItem[], data: Chart.ChartData): string | string[] {

                    return parseFloat((data.datasets[0].data[item[0].index].toString())).toFixed(3) + '\n' + unitOfMeasure;

                },

            }
        };
        let formatterFunction;
        if (typeFormatterFunction == '%') {
            formatterFunction = function (value, context) {
                let sum: number = 0;
                context.dataset.data.forEach((data) => sum += data);
                if (sum > 0) {
                    return Math.round((context.dataset.data[context.dataIndex] as number / sum) * 100) + ' %';
                } else {
                    return parseFloat((context.dataset.data[context.dataIndex] as string)).toFixed(2) + '\n' + unitOfMeasure;
                }
            }
        }

        const plugins = {
            afterLayout: function (chart) {
                let total = chart.data.datasets[0].data.reduce((a, b) => {
                    return a + b;
                });
                chart.legend.legendItems.forEach(
                    (label) => {
                        let value = chart.data.datasets[0].data[label.index];

                        label.text += ' - ' + (value / total * 100).toFixed(0) + '%';
                        return label;
                    }
                );
            }
        };


        return new Chart(riferimentoGrafico.nativeElement, {
            type: type,
            data: {
                labels: labels,
                datasets: [
                    ...dataSetGraph
                ],

            },
            options: {
                animation: {duration: animationDuration},
                title: {
                    display: false,
                    text: null
                },
                tooltips: tooltipOption,
                legend: {
                    display: true,
                    position: 'right',
                    labels: {}
                },
                plugins: {
                    showTooltips: false,
                    datalabels: {
                        display: true,
                        formatter: formatterFunction,
                        color: 'rgb(64,64,64)',
                        labels: {
                            title: {
                                font: {
                                    weight: 'normal'
                                }
                            },
                            value: {
                                color: 'rgb(64,64,64)'
                            }
                        }
                    }
                },
                scales: {
                    yAxes: [{
                        display: false,
                        scaleLabel: {
                            display: false,
                            labelString: null
                        },
                        ticks: {
                            suggestedMin: null,
                            suggestedMax: null
                        }
                    }],
                    xAxes: [{
                        display: false,
                        scaleLabel: {
                            display: false,
                            labelString: null
                        },
                        ticks: {
                            suggestedMin: null,
                            suggestedMax: null
                        }
                    }],
                }

            }
        });
    }

    getGradientBackGroundColorChart(color: 'violet' | 'yellow' | 'orange' | 'verdeBlue' | 'blue' | {
        r: number,
        g: number,
        b: number
    }, gradients: { offset: number, opacity: number }[] = undefined, ctx = undefined) {
        if (!arrayIsSet(gradients)) {
            gradients = [
                {offset: 0.0, opacity: 0.7},
                {offset: 0.3, opacity: 0.5},
                {offset: 0.6, opacity: 0.2},
                {offset: 0.8, opacity: 0.1},
                {offset: 1, opacity: 0},
            ]
        }
        let getColor;
        if (stringIsSet(color)) {
            if (color === 'violet') {
                getColor = hunaChartColors.violetOpacity
            } else if (color == 'yellow') {
                getColor = hunaChartColors.yellowOpacity
            } else if (color == 'verdeBlue') {
                getColor = hunaChartColors.verdeBlueOpacity
            } else if (color == 'blue') {
                getColor = hunaChartColors.blueOpacity
            } else {
                getColor = hunaChartColors.orangeOpacity
            }
        } else {
            getColor = (opacity) => hunaChartColors.colorOpacity((color as any).r, (color as any).g, (color as any).b, opacity)
        }
        if (ctx == null) {
            ctx = document.createElement('canvas');
        }
        const heigth = ctx.height ? ctx.height : 400
        const gradientCanvas = ctx.getContext('2d').createLinearGradient(0, 0, 0, heigth);
        gradients.forEach(gradient => {
            gradientCanvas.addColorStop(gradient.offset, getColor(gradient.opacity));
        })
        return gradientCanvas;
    }

    getChartForPdf(width: string, heigth: string, type: string, data: ChartData, otherOptions: {
        yAxes: any,
        xAxes: any,
        legend: any
    } = undefined, otherPlugins: any = undefined): Chart {
        // canvas invert heigth and width;
        const getFontSizeLabel = () => {
            const max = 25;
            const min = 8;
            if (window.innerWidth > 1024) {
                return max;
            } else if (window.innerWidth < 400) {
                return min;
            } else {
                return 0.027 * window.innerWidth - 2.9;
            }
        }
        const getFontSizeTicket = () => {
            if (window.innerWidth > 1024) {
                return 20;
            } else if (window.innerWidth < 400) {
                return 5;
            } else {
                return 0.024 * window.innerWidth - 4.6;

            }
        }
        let yAxes = [{
            scaleLabel: {
                fontStyle: 'bold',
                fontSize: 20,
                display: true,
            },
            ticks: {
                fontStyle: 'bold',
                fontSize: getFontSizeTicket(),
                suggestedMin: null,
                suggestedMax: null
            },
        }];
        let xAxes = [{
            ticks: {
                fontStyle: 'bold',
                fontSize: getFontSizeTicket(),
            },
        }];
        let legend = {
            labels: {
                fontStyle: 'bold',
                fontSize: getFontSizeLabel(),
            }
        }
        if (otherOptions) {
            if (otherOptions.yAxes != null) {
                yAxes = otherOptions.yAxes
            }
            if (otherOptions.xAxes != null) {
                xAxes = otherOptions.xAxes
            }
            if (otherOptions.legend != null) {
                Object.keys(otherOptions.legend).forEach(key => {
                    legend[key] = otherOptions.legend[key];
                })
            }
        }
        const formatter: ((value: any, context: Context) => any | null) = (value, context) => {
            let sum: number = 0;
            context.dataset.data.forEach((data) => sum += data);
            if (sum > 0) {
                return Math.round((context.dataset.data[context.dataIndex] as number / sum) * 100) + ' %';
            } else {
                return ''
            }

        }

        let plugins: ChartPluginsOptions = {
            showTooltips: true,
            tooltip: {
                enabled: true,
            },
            datalabels: {
                display: true,
                formatter: formatter,
                font: {
                    weight: 'bold',
                    size: getFontSizeLabel(),
                }

            }
        }
        if (otherPlugins != null) {

            Object.keys(otherPlugins).forEach(key => {
                if (typeof otherPlugins[key] == "object") {
                    Object.keys(otherPlugins[key]).forEach(subKey => {
                        plugins[key][subKey] = otherPlugins[key][subKey];
                    })
                } else {
                    plugins[key] = otherPlugins[key]
                }
            })
        }
        const chartEl = document.createElement("canvas");
        const ctx = chartEl.getContext("2d");
        chartEl.setAttribute("width", width);
        chartEl.setAttribute("height", heigth);
        chartEl.style.display = "none";
        document.body.append(chartEl);


        let chart = new Chart(ctx, {
            type,
            data,
            options: {
                animation: {
                    duration: 0,
                },
                legend,
                scales: {
                    yAxes,
                    xAxes,
                },
                plugins
            },
        });
        chart.render();

        const canvas = chart;
        chartEl.remove();
        return canvas;
    }

}
