import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {PlanFormService} from '../../../providers/forms/plan-form.service';
import {AlertService} from '../../../providers/services/alert.service';
import {PlansService} from '../../../providers/services/plans.service';
import {ActivatedRoute, Router} from '@angular/router';
import {LoaderService} from '../../../providers/services/loader.service';
import * as Parse from 'parse';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {UserService} from '../../../providers/services/user.service';
import {InfoSaveDialogComponent} from '../info-save-dialog/info-save-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {InfoDeleteDialogComponent} from '../info-delete-dialog/info-delete-dialog.component';
import {UtilsService} from '../../../providers/services/utils.service';
import {DialogPopUpService} from '../../../providers/services/dialog-pop-up.service';
import {PrintPageService} from "../../../providers/services/print-page.service";
import jsPDF from 'jspdf';
import {forkJoin, Observable, of, Subject, Subscription} from "rxjs";
import {ordinamentoEcampiTraduzioni} from "../../../models/ordinamentoEcampiTraduzioni";
import {environment} from "../../../../environments/environment";
import {
    arrayIsSet,
    getDifferenceArray,
    hunaParseFileName,
    isNotNullOrUndefined, stringIsSet
} from "../../../models/Models";
import {ImageService} from "../../../providers/services/image.service";
import {map, switchMap, toArray} from "rxjs/operators";
import {MapService} from "../../../providers/services/map.service";
import {ScaleColorService} from "../../../providers/services/scale-color.service";
import {PuntiLuceScaleColorService} from "../../../providers/services/punti-luce-scale-color.service";
import {CircuitScaleColorService} from "../../../providers/services/circuit-scale-color.service";
import {CircuitiParse} from "../../../models/Circuiti.Parse";
import {keyframes} from "@angular/animations";
import {PuntiLuceParse} from "../../../models/PuntiLuce.Parse";

@Component({
    selector: 'app-update-plan',
    templateUrl: './update-plan.component.html',
    styleUrls: ['./update-plan.component.scss']
})
export class UpdatePlanComponent implements OnInit, OnDestroy {

    public planForm: UntypedFormGroup;
    public loading = true;
    public plan: any;
    public previewLink;
    public fileLink;
    @Input()
    private planSlug: string;
    public user;
    public saved;

    private subscriptions: Subscription[] = [];

    public zoomRange = ['Auto', 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22];
    public images = ['image1', 'image2', 'image3', 'image4'];
    public tipologiaValues;
    public savingPlan: boolean = false;
    @Output()
    public planIsRemoved = new EventEmitter();

    public loadPdf = false;
    etichettePuntiLuce = [];
    pathForTranslate = "dashboard_sidenav.PuntiLuce.";
    @ViewChild('imaggine', {'static': false}) imaggine: ElementRef;

    circuitiInProject: CircuitiParse[];

    constructor(
        private planUserForm: PlanFormService,
        private planService: PlansService,
        private route: ActivatedRoute,
        private alertService: AlertService,
        private loaderService: LoaderService,
        private router: Router,
        private userService: UserService,
        public dialog: MatDialog,
        public translate: TranslateService,
        private utilsService: UtilsService,
        private myDialog: DialogPopUpService,
        private printPage: PrintPageService,
        private imageService: ImageService,
        private mapService: MapService,
        private scaleColorPuntiLuce: PuntiLuceScaleColorService,
        private scaleColorCircuiti: CircuitScaleColorService,
    ) {
        const etichette = [];
        Object.keys(this.filterOrdinamento().PuntiLuce)
            .filter((key) => key != 'location')
            .forEach(key => {
                if (ordinamentoEcampiTraduzioni.PuntiLuce[key].hasOwnProperty('varianteKey')) {
                    etichette.push({
                        key: ordinamentoEcampiTraduzioni.PuntiLuce[key].varianteKey,
                        sortingValue: 100000 + ordinamentoEcampiTraduzioni.PuntiLuce[key].sortingValue
                    })
                }
                etichette.push({
                    key: key,
                    sortingValue: ordinamentoEcampiTraduzioni.PuntiLuce[key].sortingValue
                })
            });
        this.etichettePuntiLuce = etichette
            .sort((a, b) => {
                return a.sortingValue - b.sortingValue
            })
            .map(etichetta => etichetta.key)
    }

    private filterOrdinamento(): {
        PuntiLuce,
        PictureCameraPoint,
        Circuiti
    } {
        let newObject: {
            PuntiLuce,
            PictureCameraPoint,
            Circuiti
        } = {
            PuntiLuce: null,
            PictureCameraPoint: null,
            Circuiti: null
        };
        Object.keys(ordinamentoEcampiTraduzioni).forEach((classe) => {
            let field = {};
            Object.keys(ordinamentoEcampiTraduzioni[classe]).forEach((key) => {
                if (ordinamentoEcampiTraduzioni[classe][key].showInForm) {
                    field[key] = ordinamentoEcampiTraduzioni[classe][key];
                }
            });
            newObject[classe] = field;
        });
        return newObject;
    }


    ngOnInit() {
        this.planSlug = (isNotNullOrUndefined(this.planSlug)) ? this.planSlug : this.route.snapshot.params.slug;
        this.loadData().then(
            () => {
                const sub = this.planForm.get('formato').valueChanges.subscribe(value => {
                    if (value == 'a4' && this.planForm.get('template').value == 2) {
                        this.planForm.get('template').setValue('3');
                    }
                })
                this.subscriptions.push(sub);
            }
        );
        this.tipologiaValues = this.planService.getTipologiaValues();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe())
    }


    unsteValue(name) {
        this.planForm.get(name).reset()
    }


    private async loadData(plan = undefined) {
        try {
            if (isNotNullOrUndefined(plan)) {
                this.plan = plan;
            } else {
                this.plan = await this.planService.getPlan(this.planSlug);
            }
        } catch (e) {
            this.planIsRemoved.emit({objectId: this.planSlug});
            // this.router.navigate(['/planimetrie']);
        }
        this.planForm = this.planUserForm.getPlanForm(this.plan);
        this.user = await this.userService.user();
        this.saved = this.planForm.get('definitivo').value;
        this.previewLink = await this.planService.previewLink(this.plan.objectId);
        this.fileLink = await this.planService.fileLink(this.plan);
    }

    async deletePlan() {
        this.utilsService.lockBody();
        const dialogRef = this.dialog.open(InfoDeleteDialogComponent, {
            width: '600px',
            data: {
                planObjectId: this.planSlug
            }
        });

        dialogRef.afterClosed().subscribe(res => {
            if (res) {
                this.loadData();
            }
            this.utilsService.unlockBody();
        });

    }

    getSizeImageWithMaxSize(maxSize, ratio): { width: number, height: number } {
        let width = maxSize;
        let height = width / ratio;
        if (height > maxSize) {
            height = maxSize;
            width = height * ratio
        }
        return {width, height}
    }


    public async getBase64FromUrl(url): Promise<string> {
        const headers = new Headers();
        headers.set('Access-Control-Allow-Origin', '*');
        headers.set('X-Parse-Javascript-Key', environment.parse.javascriptKey);
        headers.set("X-Parse-Application-Id", environment.parse.applicationId);
        const blob = await fetch(url, {
            cache: 'no-cache',
            headers: headers,
            referrerPolicy: 'strict-origin-when-cross-origin'
        })
            .then(data => {
                return data.blob();
            })
            .catch((e) => {
                console.error(e);
                return null
            });
        return new Promise((resolve) => {
            if (!isNotNullOrUndefined(blob)) {
                resolve(null);
            } else {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    const base64data = reader.result;
                    resolve(base64data as string);
                }
                reader.onerror = (e) => {
                    resolve(null);
                }
            }
        });
    }


    private async addImageWithUrl(doc, url: string, xPosition, yPosition, maxSize): Promise<{
        lastX: number,
        lastY: number
    }> {
        const base64 = await this.getBase64FromUrl(url);
        if (!isNotNullOrUndefined(base64)) {
            return {lastX: xPosition, lastY: yPosition};
        } else {
            const image = new Image();
            image.src = base64;
            let propertyImage = doc.getImageProperties(image);
            const ratio = propertyImage.width / propertyImage.height;
            const {width, height} = this.getSizeImageWithMaxSize(maxSize, ratio);
            doc.addImage(image.src, 'PNG', xPosition - width / 2, yPosition, width, height);
            return {lastX: xPosition + width, lastY: yPosition + height};
        }
    }

    private async addLogosSectionPdf(doc: jsPDF, plan, last: {
        lastX: number,
        lastY: number
    }, margin: number, widthRect: number) {
        let countRow = 0;
        const maxSize = 20;
        const distanceWithMargin = widthRect / 4;
        let row = last.lastY + distanceWithMargin / 2;
        if (plan.image1) {
            const xPosition = margin + distanceWithMargin;
            last = await this.addImageWithUrl(doc, plan.image1.url, xPosition, row, maxSize)
            countRow++;
        }
        if (plan.image2) {
            const xPosition = (countRow == 1) ? widthRect - distanceWithMargin : margin + distanceWithMargin
            last = await this.addImageWithUrl(doc, plan.image2.url, xPosition, row, maxSize);
            countRow++;
        }
        if (plan.image3) {
            row = (countRow == 2) ? last.lastY + 10 : row + 5;
            const xPosition = (countRow == 1) ? widthRect - distanceWithMargin : margin + distanceWithMargin
            last = await this.addImageWithUrl(doc, plan.image3.url, xPosition, row, maxSize);
            countRow++;
        }
        if (plan.image4) {
            row = (countRow == 2) ? last.lastY + 10 : row + 5;
            const xPosition = (countRow % 2 != 0) ? widthRect - distanceWithMargin : margin + distanceWithMargin
            last = await this.addImageWithUrl(doc, plan.image4.url, xPosition, row, maxSize);
            countRow++;
        }
        return {lastX: last.lastX + maxSize / 2, lastY: last.lastY + maxSize / 2}
    }


    async addProjectCompanyImage(doc: jsPDF, plan, last: {
        lastX: number,
        lastY: number
    }, margin: number, widthRect: number) {
        let row = last.lastY + 5;
        let countRow = 0;
        const maxSize = 40;
        let lastY = last.lastY;
        let lastX = last.lastX;
        const distanceWithMargin = widthRect / 4;
        if (plan.logoProgetto && plan.logoProgetto.url) {
            try {
                const xPosition = margin + distanceWithMargin;
                const lastPosition1 = await this.addImageWithUrl(doc, plan.logoProgetto.url, xPosition, row, maxSize);
                lastY = lastPosition1.lastY;
                lastX = lastPosition1.lastX;
                countRow++;
            } catch (e) {
                throw e
            }
        }
        if (plan.logoAzienda && plan.logoAzienda.url) {
            try {
                const xPosition = (countRow == 1) ? widthRect - distanceWithMargin : margin + distanceWithMargin
                const lastPosition2 = await this.addImageWithUrl(doc, plan.logoAzienda.url, xPosition, row, maxSize);
                if (lastPosition2.lastY > lastY) {
                    lastY = lastPosition2.lastY;
                }
                if (lastPosition2.lastX > lastX) {
                    lastX = lastPosition2.lastX;
                }
                countRow++;
            } catch (e) {
                throw e
            }
        }
        return {lastX, lastY}
    }

    addshape(doc: jsPDF, shape: string, color, x, y, r) {
        const fillColor = doc.getFillColor();
        doc.setDrawColor(color);
        doc.setFillColor(color);
        let xPosition;
        let yPosition;
        if (shape == 'circle') {
            xPosition = x + r;
            yPosition = y;
            doc.circle(xPosition, yPosition, r / 2, 'FD');
            xPosition += r / 2;
            yPosition += r / 2;
        } else if (shape == 'square') {
            xPosition = x + r / 2;
            yPosition = y - r / 2;
            doc.roundedRect(xPosition, yPosition, r, r, r * 0.1, r * 0.1, 'FD')
            xPosition += r;
            yPosition += r;
        } else if (shape == 'triangle') {
            xPosition = x + r / 2;
            yPosition = y - r / 2;
            doc.triangle(x + r / 2, y + r - r / 2, x + r / 2 + r / 2, y - r / 2, x + r + r / 2, y + r - r / 2, 'FD');
            xPosition += r;
            yPosition += r;
        }
        doc.setDrawColor(fillColor);
        doc.setFillColor(fillColor);
        return {lastX: xPosition, lastY: yPosition}
    }

    createNewPage(doc: jsPDF, margin, title: string, dimensionsRectangle: { x: number, y: number }) {
        doc.addPage();
        const pageWidth = doc.internal.pageSize.getWidth();
        const last = this.printPage.addH2(doc, title, pageWidth / 2, margin + 5);
        if (isNotNullOrUndefined(dimensionsRectangle)) {
            doc.line(margin + dimensionsRectangle.x, last.lastY, margin + dimensionsRectangle.x, last.lastY + dimensionsRectangle.y);
            // doc.roundedRect(margin, last.lastY, dimensionsRectangle.x, dimensionsRectangle.y, 8, 8, 'S');
        }
        return last
    }

    createAddLegend(doc: jsPDF, legenda: { value, color, numberOfObject, shape }[], first: {
        x: number,
        y: number
    }, dimensionsRectangle: { x: number, y: number }, title: string) {
        const sizeShape = 5;
        const spaceBetweenImage = 2;
        const margin = first.x;
        let numberTableForPage = 0;
        let lastX = first.x;
        let positionTitle;
        const legendaTitle = 'LEGENDA';
        const minColumnHeigth = 10;
        const widthFirstColumn = sizeShape + spaceBetweenImage * 2;
        let widthThirdColumn = 20;
        let widthSecondColumn = dimensionsRectangle.x - widthThirdColumn - widthFirstColumn - margin;
        const titleSecondColumn = 'Descrizione';
        const titleThirdColumn = 'Q.ta';
        const dimensionText = doc.getTextDimensions(titleSecondColumn);
        let lastPosition = this.printPage.addH4(doc, legendaTitle, margin + dimensionsRectangle.x / 2, first.y + 5 + 5);
        lastPosition = {lastX: first.x, lastY: lastPosition.lastY};
        let xPositionFirstColumnTable = lastX + sizeShape / 2 - spaceBetweenImage
        doc.rect(xPositionFirstColumnTable + widthFirstColumn, lastPosition.lastY, widthSecondColumn, minColumnHeigth);
        this.printPage.addBoldString(doc, titleSecondColumn, xPositionFirstColumnTable + widthFirstColumn + spaceBetweenImage, lastPosition.lastY + minColumnHeigth / 2, "left", 'middle')
        doc.rect(xPositionFirstColumnTable + widthFirstColumn + widthSecondColumn, lastPosition.lastY, widthThirdColumn, minColumnHeigth);
        this.printPage.addBoldString(doc, titleThirdColumn, xPositionFirstColumnTable + widthFirstColumn + widthSecondColumn + spaceBetweenImage, lastPosition.lastY + minColumnHeigth / 2, "left", 'middle')
        lastPosition.lastY += minColumnHeigth - dimensionText.h / 2;
        legenda.forEach((row, index) => {
            widthSecondColumn = dimensionsRectangle.x - widthThirdColumn - widthFirstColumn - margin;
            let text;
            if (typeof row.value == 'number') {
                text = row.value.toString()
            } else if (typeof row.value == 'string') {
                text = row.value
            } else {
                text = 'N.D.'
            }
            const dimensionText2 = doc.getTextDimensions(text, {maxWidth: widthSecondColumn - spaceBetweenImage * 2});
            const lastY = lastPosition.lastY;
            lastPosition = this.addshape(doc, row.shape, row.color, lastX, lastY + spaceBetweenImage * 2 + dimensionText.h / 2, sizeShape);
            lastPosition.lastY = lastY + spaceBetweenImage * 2
            lastPosition.lastY += (dimensionText2.h > sizeShape) ? dimensionText2.h : sizeShape
            const columnHeigth = lastPosition.lastY - lastY;
            const yPositionColumnTable = lastY + dimensionText.h / 2
            doc.rect(xPositionFirstColumnTable, yPositionColumnTable, widthFirstColumn, columnHeigth);
            doc.rect(xPositionFirstColumnTable + widthFirstColumn, yPositionColumnTable, widthSecondColumn, columnHeigth);
            doc.text(text, lastPosition.lastX + 2 * spaceBetweenImage, lastY + spaceBetweenImage * 2, {
                maxWidth: widthSecondColumn - spaceBetweenImage * 2,
                baseline: "top"
            })
            doc.rect(xPositionFirstColumnTable + widthFirstColumn + widthSecondColumn, yPositionColumnTable, widthThirdColumn, columnHeigth);
            doc.text(row.numberOfObject.toString(), xPositionFirstColumnTable + widthFirstColumn + widthSecondColumn + widthThirdColumn - spaceBetweenImage, lastY + spaceBetweenImage * 2, {
                maxWidth: widthThirdColumn - spaceBetweenImage * 2,
                align: "right",
                baseline: "top"
            })
            if (lastY + 2 * sizeShape > doc.internal.pageSize.getHeight() - 2 * margin && (legenda.length - 1) > index) {
                dimensionsRectangle.x = 85.6;
                const totalNumberPageForTable = Math.floor(doc.internal.pageSize.getWidth() / dimensionsRectangle.x);
                if (numberTableForPage % totalNumberPageForTable == 0) {
                    lastX = margin;
                    positionTitle = this.createNewPage(doc, margin, title, dimensionsRectangle);
                    lastPosition = {lastX: positionTitle.lastX, lastY: positionTitle.lastY + margin};
                    lastPosition = this.printPage.addH4(doc, legendaTitle, margin + dimensionsRectangle.x / 2, lastPosition.lastY);
                } else {
                    lastX = margin + (dimensionsRectangle.x + margin) * (numberTableForPage % totalNumberPageForTable);
                    lastPosition = this.printPage.addH4(doc, legendaTitle, lastX + dimensionsRectangle.x / 2, positionTitle.lastY + margin);
                    doc.line(lastX + dimensionsRectangle.x, positionTitle.lastY, lastX + dimensionsRectangle.x, positionTitle.lastY + dimensionsRectangle.y);
                    xPositionFirstColumnTable = lastX
                    // doc.roundedRect(lastX, positionTitle.lastY, dimensionsRectangle.x, dimensionsRectangle.y, 8, 8, 'S');
                }
                numberTableForPage++;
            }
        })
        return {lastX: lastPosition.lastX + dimensionsRectangle.x, lastY: lastPosition.lastY}

    }

    public async createPdf(legenda, planimetria) {
        try {
            const title = isNotNullOrUndefined(this.plan.titolo) ? this.plan.titolo : this.plan.nome;
            const formato = this.plan.formato ? this.plan.formato : 'a4';
            const doc = new jsPDF({orientation: 'l', format: formato, unit: 'mm'});
            const pageWidth = doc.internal.pageSize.getWidth();
            const pageHeigth = doc.internal.pageSize.getHeight();
            const margin = 10;
            this.printPage.addFont(doc);
            let last;
            last = this.printPage.addH2(doc, title, pageWidth / 2, margin + 5);
            const propertyPlanimetryImage = doc.getImageProperties(planimetria);
            const ratioPlanimetria = propertyPlanimetryImage.width / propertyPlanimetryImage.height;
            const planimetriaHeigth = pageHeigth - margin * 2 - last.lastY + 10;
            const planimetriaWidth = planimetriaHeigth * ratioPlanimetria;
            const yPositionPlanimetria = last.lastY;
            // doc.addImage(propertyPlanimetryImage.data, pageWidth - planimetriaWidth - margin, yPositionPlanimetria, planimetriaWidth, planimetriaHeigth)
            last = this.printPage.addImage(doc, planimetria.src, planimetriaWidth, planimetriaHeigth, pageWidth - planimetriaWidth - margin, yPositionPlanimetria, "start start", ratioPlanimetria);
            const widthRect = pageWidth - planimetriaWidth - margin * 3;
            let legendaMaxHeight;
            last = {lastX: margin, lastY: yPositionPlanimetria}
            last = await this.addProjectCompanyImage(doc, this.plan, last, margin, widthRect);
            last = await this.addLogosSectionPdf(doc, this.plan, last, margin, widthRect);
            doc.line(margin + widthRect, yPositionPlanimetria, margin + widthRect, planimetriaHeigth + yPositionPlanimetria);
            if (this.plan.legenda) {
                // const legenda = new Image()
                // legenda.src = 'assets/temp/legenda.png';
                // const propertylegendaImage = doc.getImageProperties(legenda);
                // const ratiolegenda = propertylegendaImage.width / propertylegendaImage.height;
                // const legendaWidth = widthRect;
                // const legendaHeigth = widthRect / ratiolegenda;
                // last = this.printPage.addImage(doc, legenda.src, legendaWidth, legendaHeigth, margin, last.lastY, "start start", ratiolegenda);
                last = this.createAddLegend(doc, legenda, {x: margin, y: last.lastY}, {
                    x: widthRect,
                    y: planimetriaHeigth
                }, title)
            }
            this.printPage.convertToPdf(doc, 'planimetria');
        } catch (e) {
            throw e
        }
    }


    async onLink() {
        this.loadPdf = true;
        await this.updatePlan();
        if (this.planForm.get('template').value == 1 || this.planForm.get('template').value == 2) {
            setTimeout(() => {
                window.open(this.previewLink);
                this.loadPdf = false;
            }, 100);
        } else {
            setTimeout(() => {
                try {
                    this.planService.getImageAndLegendForPlanimetry(this.plan.objectId, this.plan.progetto.objectId, this.plan.etichettaPuntoLuce).subscribe(
                        plan => {
                            const planimetria = new Image();
                            planimetria.src = plan.planimetria;
                            this.createPdf(plan.legenda, planimetria)
                                .then(() => {
                                    this.loadPdf = false
                                })
                                .catch((e) => {
                                    this.alertService.error(e.message);
                                    this.loadPdf = false
                                });
                        }, error => {
                            this.alertService.error(error.message);
                            this.loadPdf = false
                        }
                    )
                } catch (e) {
                    this.alertService.error(e.message);
                    this.loadPdf = false

                }
            }, 200);
        }
    }

    updatePlan() {
        this.savingPlan = true;
        let logoAzienda;
        if (this.planForm.value.logoAzienda == null) {
            logoAzienda = null;
        } else {
            if (this.planForm.value.logoAzienda.file) {
                const image = this.planForm.value.logoAzienda;
                const name = image.name;
                const parseFile = new Parse.File(name, {base64: image.file});
                logoAzienda = parseFile;
            } else {
                logoAzienda = this.planForm.value.logoAzienda;
            }
        }
        let logoProgetto;
        if (this.planForm.value.logoProgetto == null) {
            logoProgetto = null;
        } else {
            if (this.planForm.value.logoProgetto.file) {
                const image = this.planForm.value.logoProgetto;
                const name = image.name;
                const parseFile = new Parse.File(name, {base64: image.file});
                logoProgetto = parseFile;
            } else {
                logoProgetto = this.planForm.value.logoProgetto;
            }
        }
        let imagesArray = [];
        if (this.planForm.value.image1 != null) {
            if (this.planForm.value.image1.file) {
                const image = this.planForm.value.image1;
                const name = image.name;
                const parseFile = new Parse.File(name, {base64: image.file});
                imagesArray.push(parseFile);
            } else {
                imagesArray.push(this.planForm.value.image1);
            }
        }
        if (this.planForm.value.image2 != null) {
            if (this.planForm.value.image2.file) {
                const image = this.planForm.value.image2;
                const name = image.name;
                const parseFile = new Parse.File(name, {base64: image.file});
                imagesArray.push(parseFile);
            } else {
                imagesArray.push(this.planForm.value.image2);
            }
        }
        if (this.planForm.value.image3 != null) {
            if (this.planForm.value.image3.file) {
                const image = this.planForm.value.image3;
                const name = image.name;
                const parseFile = new Parse.File(name, {base64: image.file});
                imagesArray.push(parseFile);
            } else {
                imagesArray.push(this.planForm.value.image3);
            }
        }
        if (this.planForm.value.image4 != null) {
            if (this.planForm.value.image4.file) {
                const image = this.planForm.value.image4;
                const name = image.name;
                const parseFile = new Parse.File(name, {base64: image.file});
                imagesArray.push(parseFile);
            } else {
                imagesArray.push(this.planForm.value.image4);
            }
        }
        if (this.planForm.value.zoom == 'Auto') {
            this.planForm.value.zoom = null;
        }

        if (this.planForm.value.formato == 'a4' && this.planForm.value.template == '2') {
            this.planForm.value.template = '3';
        }

        const res = this.planService.updatePlan(
            this.planSlug,
            this.planForm.value,
            logoAzienda,
            logoProgetto,
            imagesArray
        );
        res.then(resp => {
            this.alertService.success(this.translate.instant('plan_success_update'));
            setTimeout(() => this.loadData(resp.toJSON())
                , 100);
        }).catch(error => {
            this.loadData(this.plan)
            this.alertService.error(error)
        }).finally(() => {
            this.loading = false;
            this.savingPlan = false;
        })
    }

    isDisabled() {
        for (let im of this.images) {
            if (this.planForm.get(im).value == null || this.planForm.get(im).value == undefined) {
                return false;
            }
        }
        return true;
    }

    async addImageToPlanFormFromFile(fileUploadControl, label) {
        return new Promise((resolve, reject) => {
            const file = fileUploadControl;
            const name = fileUploadControl.name;
            const reader = new FileReader();
            reader.onloadend = (e: ProgressEvent) => {
                const base64 = (e.target as FileReader).result as any;
                this.getImageForForm(base64, name).then(
                    (value) => {
                        this.planForm.get(label).setValue(value);
                        resolve(value);
                    }
                );
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    }


    getImageForForm(base64, name) {
        return this.imageService.compressImage(base64).then(compressed => {
            const base64Compressa: string = compressed as string;
            return {
                url: base64Compressa,
                file: base64Compressa,
                name: hunaParseFileName(name)
            };
            // this.salvaImage = true;
        })
    }

    async onLoadImage(files: File[], label) {
        try {
            const loaderId = this.loaderService.addLoader();
            let fileUploadControl = files[0];
            if (files.length == 0) {
                this.loaderService.removeLoader(loaderId);
                this.alertService.error(this.translate.instant('load_correct_file'));
            } else {
                if (label == 'images') {
                    let count = 0;
                    files.sort((a, b) => a.name.localeCompare(b.name));
                    for (let im of this.images) {
                        if (this.planForm.get(im).value == null || this.planForm.get(im).value == undefined) {
                            label = im;
                            fileUploadControl = files[count];
                            if (fileUploadControl) {
                                const value = await this.addImageToPlanFormFromFile(fileUploadControl, label);
                            }
                            count++;
                        }
                    }
                    this.loaderService.removeLoader(loaderId);
                } else {
                    if (fileUploadControl) {
                        const file = fileUploadControl;
                        const name = fileUploadControl.name;
                        const reader = new FileReader();
                        reader.onloadend = (e: ProgressEvent) => {
                            const base64 = (e.target as FileReader).result as any;
                            this.getImageForForm(base64, name).then(
                                (value) => {
                                    this.planForm.get(label).setValue(value);
                                    this.loaderService.removeLoader(loaderId);
                                }
                            );

                            // const parseFile = new Parse.File(name, {base64: base64});
                            //
                            // return parseFile.save().then(() => {
                            //     this.planForm.get(label).setValue({
                            //         name: parseFile.name(),
                            //         url: parseFile.url(),
                            //         file: parseFile
                            //     });
                            //
                            //     this.loaderService.removeLoader(loaderId);
                            // });

                        };
                        reader.onerror = (e) => {
                            this.alertService.error(e)
                        }
                        reader.readAsDataURL(file);

                    }
                }
            }
        } catch (e) {
            console.error(e);
        }
    }

    getCircuitiInProject$() {
        let circuitiInProject$: Observable<CircuitiParse[]>;
        if (arrayIsSet(this.circuitiInProject)) {
            circuitiInProject$ = of(this.circuitiInProject)
        } else {
            circuitiInProject$ = this.mapService.getCircuiti$(this.plan.progetto.objectId).pipe(
                map(circuiti => {
                    if (arrayIsSet(circuiti)) {
                        this.circuitiInProject = circuiti;
                        return circuiti.map(circuito => {
                            circuito.icon = this.scaleColorCircuiti.getIconCircuito(circuito);
                            return circuito;
                        })
                    } else {
                        return []
                    }
                })
            )
        }
        return circuitiInProject$
    }

    getCircuitiPuntiLuce$(filtriCircuiti, filtriPuntiLuce, filtriObjectIdPuntiLuce, boundsPuntiLuce: google.maps.LatLngBounds, addSearchSmart): Observable<{
        circuitiInProject?: CircuitiParse[],
        puntiLuce?: PuntiLuceParse[],
        circuiti?: CircuitiParse[]
    }> {
        return this.getCircuitiInProject$().pipe(
            switchMap((circuitiInProject) => {
                let values$ = [];
                if (arrayIsSet(filtriPuntiLuce)) {
                    values$.push(this.mapService.getLightPointPaginate(environment.lightPointLimit, filtriPuntiLuce, undefined)
                        .pipe(
                            toArray(),
                            map(puntiLucePaginate => {
                                const puntiLuce = puntiLucePaginate.reduce((prev, current) => {
                                    if (arrayIsSet(current.puntiLuce)) {
                                        prev = prev.concat(current.puntiLuce.map(l => {
                                            const icon = this.scaleColorPuntiLuce.getIconPuntoLuce(l);
                                            l.icon = icon;
                                            return l;
                                        }))
                                    }
                                    return prev;
                                }, [])
                                return {puntiLuce: puntiLuce}
                            })
                        )
                    )
                }
                if (arrayIsSet(filtriObjectIdPuntiLuce)) {
                    values$.push(this.mapService.fetchPuntiLuce$(
                        filtriObjectIdPuntiLuce
                            .map(objectId => {
                                return {objectId}
                            })
                    ).pipe(
                        map(puntiLuce => {
                            if (puntiLuce != null) {
                                puntiLuce = puntiLuce.map(l => {
                                    const icon = this.scaleColorPuntiLuce.getIconPuntoLuce(l);
                                    l.icon = icon;
                                    return l;
                                });
                            }
                            return {puntiLuce: puntiLuce}
                        })
                    ))
                }
                if (boundsPuntiLuce != null) {
                    values$.push(
                        this.mapService.getPuntiLuceTramiteCoordinatePaginate(boundsPuntiLuce, environment.lightPointLimit, undefined).pipe(
                            toArray(),
                            map(puntiLucePaginate => {
                                const puntiLuce = puntiLucePaginate.reduce((prev, current) => {
                                    if (arrayIsSet(current.puntiLuce)) {
                                        prev = prev.concat(current.puntiLuce.map(l => {
                                            const icon = this.scaleColorPuntiLuce.getIconPuntoLuce(l);
                                            l.icon = icon;
                                            return l;
                                        }))
                                    }
                                    return prev;
                                }, [])
                                return {puntiLuce: puntiLuce}
                            })
                        )
                    )
                }
                if (addSearchSmart != null) {
                    values$.push(
                        this.mapService.getLightPointByAdvancedFilter(addSearchSmart).pipe(
                            map(puntiLuce => {
                                if (puntiLuce != null) {
                                    puntiLuce = puntiLuce.map(l => {
                                        const icon = this.scaleColorPuntiLuce.getIconPuntoLuce(l);
                                        l.icon = icon;
                                        return l;
                                    });
                                }
                                return {puntiLuce: puntiLuce}
                            })
                        )
                    )
                }
                if (arrayIsSet(filtriCircuiti)) {
                    const circuiti = circuitiInProject
                        .filter(circuito => filtriCircuiti.includes(circuito.objectId))
                    values$.push(of({circuiti}));
                }
                if (arrayIsSet(values$)) {
                    return forkJoin(values$).pipe(
                        map(values => {
                            const obj: any = {circuitiInProject};
                            if (arrayIsSet(values)) {
                                values.forEach((value: any) => {
                                    if ('puntiLuce' in value) {
                                        if (!arrayIsSet(obj['puntiLuce'])) {
                                            obj['puntiLuce'] = []
                                        }
                                        obj['puntiLuce'] = obj['puntiLuce'].concat(value.puntiLuce)
                                    }
                                    if ('circuiti' in value) {
                                        if (!arrayIsSet(obj['circuiti'])) {
                                            obj['circuiti'] = []
                                        }
                                        obj['circuiti'] = obj['circuiti'].concat(value.circuiti)
                                    }
                                })
                            }
                            return obj
                        })
                    );
                } else {
                    return of({circuitiInProject})
                }
            }),
        )


    }

    async deleteSavedFile(label) {
        this.planForm.get(label).setValue(null);
    }


    async definitelySave() {
        this.utilsService.lockBody();
        const dialogRef = this.dialog.open(InfoSaveDialogComponent, {
            width: '600px',
            data: {
                planObjectId: this.planSlug,
                userSession: this.userService.sessionToken()
            }
        });

        dialogRef.afterClosed().subscribe(res => {
            if (res) {
                this.loadData();
            }
            this.utilsService.unlockBody();
        });

    }

    seaMap() {
        this.savingPlan = true;
        const values$ = new Subject();
        let oldValues: any = {};
        this.scaleColorPuntiLuce.calcolaScalaColore('tipologiaSorgenteLuminosa', []);
        const filtriCircuiti = stringIsSet(this.plan.filtriCircuiti) ? JSON.parse(this.plan.filtriCircuiti) : this.plan.filtriCircuiti;
        const filtriPuntiLuce = stringIsSet(this.plan.filtriPuntiLuce) ? JSON.parse(this.plan.filtriPuntiLuce) : this.plan.filtriPuntiLuce;
        const filtriObjectIdPuntiLuce = arrayIsSet(this.plan.filtriObjectIdPuntiLuce) ? this.plan.filtriObjectIdPuntiLuce : [];
        this.myDialog.openDialogSeeMapLive$(values$.asObservable(), {
            filtriCircuiti,
            filtriPuntiLuce,
            filtriObjectIdPuntiLuce
        }, true, true,true)
            .pipe(switchMap(values => {
                if (values != null && values.save == true) {
                    let objectIdCircuiti;
                    if (arrayIsSet(values.circuiti)) {
                        objectIdCircuiti = values.circuiti.map(circuito => circuito.objectId);
                    }
                    let objectIdPuntiLuce;
                    if (arrayIsSet(values.puntiLuce)) {
                        objectIdPuntiLuce = values.puntiLuce.map(pl => pl.objectId);
                    }
                    return this.planService.savePlanimetry$(this.plan.objectId, values.zoom, values.center, objectIdCircuiti, objectIdPuntiLuce).pipe(map((plan) => {
                        return {save: true, plan}
                    }))
                } else if (values != null && values.closed == true) {
                    return of(values)
                } else {
                    if (values.add) {
                        const addFiltriCircuiti = values && values.add && arrayIsSet(values.add.filtriCircuiti) ? values.add.filtriCircuiti : undefined;
                        const addFiltriPuntiLuce = values && values.add && arrayIsSet(values.add.filtriPuntiLuce) ? values.add.filtriPuntiLuce : undefined;
                        const addFiltriObjectIdPuntiLuce = values && values.add && arrayIsSet(values.add.filtriObjectIdPuntiLuce) ? values.add.filtriObjectIdPuntiLuce : undefined;
                        const addBoundsPuntiLuce = values && values.add && values.add.boundsPuntiLuce != null ? values.add.boundsPuntiLuce : undefined;
                        let addSearchSmart = values && values.add && values.add.searchSmart != null ? values.add.searchSmart : undefined;
                        if (addSearchSmart != null) {
                            addSearchSmart = Object.keys(addSearchSmart)
                                .reduce((prev, current) => {
                                    if (addSearchSmart[current] != null) {
                                        if (prev == null) {
                                            prev = {}
                                        }
                                        prev[current] = addSearchSmart[current];
                                    }
                                    return prev;
                                }, undefined as any)
                        }
                        return this.getCircuitiPuntiLuce$(addFiltriCircuiti, addFiltriPuntiLuce, addFiltriObjectIdPuntiLuce, addBoundsPuntiLuce, addSearchSmart).pipe(
                            map(currentValues => {
                                ['circuiti', 'puntiLuce'].forEach(key => {
                                    if (arrayIsSet(oldValues[key])) {
                                        if (arrayIsSet(currentValues[key])) {
                                            currentValues[key] = currentValues[key].concat(oldValues[key]);
                                        } else {
                                            currentValues[key] = oldValues[key]
                                        }
                                    }
                                    if (arrayIsSet(oldValues.circuitiInProject) && arrayIsSet(currentValues.circuitiInProject)) {
                                        const idsOld = oldValues.circuitiInProject.map(circuito => circuito.objectId)
                                        const idsCurrent = currentValues.circuitiInProject.map(circuito => circuito.objectId)
                                        if (!arrayIsSet(getDifferenceArray(idsOld, idsCurrent, true))) {
                                            delete currentValues.circuitiInProject;
                                        }
                                    }
                                })
                                return currentValues;
                            })
                        )
                    } else if (values.remove) {
                        const removeFiltriCircuiti = values && values.remove && arrayIsSet(values.remove.filtriCircuiti) ? values.remove.filtriCircuiti : undefined;
                        const removeFiltriPuntiLuce = values && values.remove && arrayIsSet(values.remove.filtriPuntiLuce) ? values.remove.filtriPuntiLuce : undefined;
                        const removeFiltriObjectIdPuntiLuce = values && values.remove && arrayIsSet(values.remove.filtriObjectIdPuntiLuce) ? values.remove.filtriObjectIdPuntiLuce : undefined;
                        let removeSearchSmart = values && values.remove && values.remove.searchSmart != null ? values.remove.searchSmart : undefined;
                        if (removeSearchSmart != null) {
                            removeSearchSmart = Object.keys(removeSearchSmart)
                                .reduce((prev, current) => {
                                    if (removeSearchSmart[current] != null) {
                                        if (prev == null) {
                                            prev = {}
                                        }
                                        prev[current] = removeSearchSmart[current];
                                    }
                                    return prev;
                                }, undefined as any)
                        }
                        const obj = {};
                        if (arrayIsSet(removeFiltriCircuiti) && arrayIsSet(oldValues.circuiti)) {
                            obj['circuiti'] = oldValues.circuiti.filter(circuito => !removeFiltriCircuiti.includes(circuito.objectId))
                        }

                        if (arrayIsSet(removeFiltriObjectIdPuntiLuce) && arrayIsSet(oldValues.puntiLuce)) {
                            obj['puntiLuce'] = oldValues.puntiLuce.filter(puntuLuce => !removeFiltriObjectIdPuntiLuce.includes(puntuLuce.objectId));
                        }
                        if (arrayIsSet(removeFiltriPuntiLuce) && arrayIsSet(oldValues.puntiLuce)) {
                            obj['puntiLuce'] = oldValues.puntiLuce.filter(puntuLuce => puntuLuce.circuito != null && !removeFiltriPuntiLuce.includes(puntuLuce.circuito.objectId));
                        }
                        if (removeSearchSmart != null && arrayIsSet(oldValues.puntiLuce)) {
                            obj['puntiLuce'] = oldValues.puntiLuce.filter(puntuLuce => {
                                let block = true
                                Object.keys(removeSearchSmart).forEach(key => {
                                    if (key == 'targhePuntiLuce') {
                                        block = !puntuLuce.getTarga() == removeSearchSmart[key]
                                    } else {
                                        block = !puntuLuce[key] == removeSearchSmart[key]
                                    }
                                })
                                return block
                            });
                        }
                        return of(obj)
                    }

                }
            }))
            .subscribe((values: any) => {
                    values$.next(values)
                    Object.keys(values).forEach(key => {
                        oldValues[key] = values[key];
                    })
                    if (values.save || values.closed) {
                        this.savingPlan = false;
                    }
                }, error => {
                    this.alertService.error(error);
                    this.savingPlan = false;
                },
                () => {
                    this.savingPlan = false;
                })


        this.getCircuitiPuntiLuce$(filtriCircuiti, filtriPuntiLuce, filtriObjectIdPuntiLuce, undefined, undefined).subscribe(values => {
            values$.next(values)
            oldValues = values;
        })
    }


    public addProjectTraduction(key: string) {
        if (isNotNullOrUndefined(key) && typeof key == "string" && key.includes('variante')) {
            return ' - ' + this.translate.instant('project')
        }
    }

    getTranslate(key) {
        if (typeof key == "string") {
            return this.translate.instant(key.toLowerCase().replace(/ /g, '_'));
        } else {
            return key
        }
    }

    /*
        public async openPlan() {
            const loaderId = this.loaderService.addLoader();

            const resp = await this.planService.openPlan(this.planSlug, this.userService.sessionToken());

            if (resp.status === 200 || resp.status === 204) {
                this.alertService.success(this.translate.instant('plan_success_open'));
                this.loadData();
            } else {
                this.alertService.error(this.translate.instant('error_verified'));
            }
            this.loaderService.removeLoader(loaderId);
        }
    */
}


/*
/*
 <ng-container *ngIf="(el.key== 'pdf' || el.key== 'plan'); else notIcon">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            [disabled]="sortDisabled(el.key)">{{el.value}}
                        </th>
                        <ng-container *ngIf="(el.key== 'pdf'); else notPdf">
                            <td (click)="$event.stopPropagation()" style="text-align: center; !important;" mat-cell
                                *matCellDef="let element">
                                <ng-container *ngIf="element.filePDF; else notUrl">
                                    <a href="{{element.filePDF.url}}" style="text-decoration: underline"
                                       target="_blank">
                                        <mat-icon style="margin-left: -60px !important;">file_copy</mat-icon>
                                    </a>
                                </ng-container>
                                <ng-template #notUrl>
                                    <mat-icon style="margin-left: -60px !important;">close</mat-icon>
                                </ng-template>
                            </td>
                        </ng-container>
                        <ng-template #notPdf>
                            <td (click)="$event.stopPropagation()" style="text-align: center; !important;" mat-cell
                                *matCellDef="let element"><a (click)="openFx(element.objectId)"
                                                             style="text-decoration: underline">
                                <mat-icon style="margin-left: -60px !important;">add_location</mat-icon>
                            </a></td>
                        </ng-template>
                    </ng-container>
 */