<mat-dialog-content>
    <ng-container *ngFor="let field of form">
        <mat-card style="margin: 5px;border-bottom-right-radius: 30px" class="w100-40">
            <mat-card-subtitle>
                {{field.traduction}}
            </mat-card-subtitle>
            <ng-container [ngSwitch]="typeSchede[field.traduction]">
                <ng-container *ngSwitchCase="'hour'">
                    {{anticonvertHourTODecimalHour(field.value)}}
                </ng-container>
                <ng-container *ngSwitchCase="'date'">
                    {{field.value|customDate :'longDate'|async}}
                </ng-container>
                <ng-container *ngSwitchCase="'image'">
                    <div class="flex-around-center w100 flex-flow-row"
                         *ngIf="images[field.traduction]!=null;else noImage">
                        <ng-container *ngFor="let url of images[field.traduction]">
                            <img [src]="url" class="img-icon-xxl"
                                 (click)="$event.stopPropagation();openImageFull(url)">
                        </ng-container>
                    </div>
                    <ng-template #noImage>
                        <mat-icon>image_not_supported</mat-icon>
                    </ng-template>
                </ng-container>
                <ng-container *ngSwitchCase="'bool'">
                    <ng-container *ngIf="field.value;else falseCheckBox">
                        <div style="width: 60px;max-width: 100%" class="flex-center-Between">
                            {{'bool.true'|translate}}
                            <mat-icon [color]="colorIcon">check_box</mat-icon>
                        </div>
                    </ng-container>
                    <ng-template #falseCheckBox>
                        <div style="width: 60px;max-width: 100%" class="flex-center-Between">
                            {{'bool.false'|translate}}
                            <mat-icon [color]="colorIcon">check_box_outline_blank</mat-icon>
                        </div>
                    </ng-template>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    {{field.value}}
                </ng-container>
            </ng-container>
        </mat-card>

    </ng-container>
</mat-dialog-content>
<mat-dialog-actions class="flex-end-center">
    <button mat-dialog-close mat-flat-button color="accent">
        <mat-icon>clear</mat-icon>
    </button>
</mat-dialog-actions>