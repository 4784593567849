<ng-container *ngIf="loading">
    <div class="flex-center-width100">
        <mat-spinner></mat-spinner>
    </div>
</ng-container>
<ng-container
        *ngIf="aggregateDataReport|convertValueByPredicateFunction:predicateHtmValue:getChartData as values;else NoData">
    <ng-container>
        <mat-toolbar color="primary">
            <mat-toolbar-row>
                <span style="color: #2196f3">{{'associatedLightMateId.stateEnergy'|translate}}</span>
            </mat-toolbar-row>
        </mat-toolbar>
        <div class="flex-around-center flex-flow-row w100" style="margin: 10px 0">
            <ng-container *ngIf="showDate&&values.period!=null">
                <mat-card class="date-container">
                    <mat-card-subtitle style="margin-bottom: 5px">{{'periodReferement'|translate}}</mat-card-subtitle>
                    {{values.period.fromDate|customDate:'mediumDate':'noYear'|async}}
                    - {{values.period.toDate|customDate:'mediumDate':'noYear'|async}}
                    ,  {{values.period.toDate|customDate:'custom':'yyyy'|async}}
                </mat-card>
            </ng-container>
            <ng-content select="[stateEnergy]">

            </ng-content>
        </div>
    </ng-container>
    <ng-container *ngIf="values.period!=null">
        <ng-container *ngIf="values.error!=null">
            <div class="flex-around-center flex-flow-row w100" style="margin: 10px 0">
                <mat-card style="display: flex">
                    <mat-icon color="accent" style="margin: 0 10px 0 0"
                              *ngIf="values.error.icon">{{values.error.icon}}</mat-icon>
                    <div
                            *ngIf="values.error.message">
                        {{values.error.message|translate:values.error.percent}}
                    </div>
                </mat-card>
            </div>
        </ng-container>
        <ng-container *ngIf="values.hourOpened!=null||values.energyReducedConsuption!=null||values.energyEconomy!=null">
            <div class="flex-start-stretch flex-flow-row w100">
                <div *ngIf="values.hourOpened!=null" style="flex: 1;margin: 5px">
                    <mat-card class="background-primary">
                        <mat-card-subtitle
                                style="color: white;font-weight: bold">{{'openedHour'|translate}}</mat-card-subtitle>
                        <h3 style="text-align: center">
                            {{values.hourOpened|convertValueByPredicateFunction:predicateHour}}
                        </h3>
                    </mat-card>
                </div>
                <div *ngIf="values.energyEconomy!=null" style="flex: 1;margin: 5px">
                    <mat-card class="background-green">
                        <mat-card-subtitle
                                style="color: white;font-weight: bold">{{'energyEconomy'|translate}}</mat-card-subtitle>
                        <h3 style="text-align: center">
                            {{values.energyEconomy|convertValueByPredicateFunction:predicatePercent}}
                        </h3>
                    </mat-card>
                </div>
                <div *ngIf="values.energyReducedConsuption!=null" style="flex: 1;margin: 5px">
                    <mat-card class="background-accent">
                        <mat-card-subtitle
                                style="color: white;font-weight: bold">{{'energyAbosorbed'|translate}}</mat-card-subtitle>
                        <h3 style="text-align: center">
                            {{values.energyReducedConsuption|convertValueByPredicateFunction:predicateEnergy}}
                        </h3>
                    </mat-card>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="values.chartProfile as dataChart">
            <div [responsiveClassL]="'container-row-inputs'"
                 [responsiveClassXS]="'container-column-inputs'"
                 appResponsiveClass class="ratio">
                <div
                        class="h100">
                    <div class="h100" style="background: white"
                    >
                        <app-chart-js
                                [datasets]="dataChart.datasets"
                                [labels]="dataChart.labels"
                                [options]="dataChart.options"
                                [plugins]="dataChart.plugins"
                                [type]="dataChart.type"
                                [maxDensityBarInChart]="50"
                                (chartReady)="chartIsReady($event)"
                        >
                        </app-chart-js>

                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="values.cardSegnali!=null">
        <mat-toolbar color="primary">
            <mat-toolbar-row>
                <span style="color: #2196f3">
                    <span>{{'associatedLightMateId.currentState'|translate}} </span>
                    <!--                    <span style="color: #2196f3;font-size: medium;font-weight: bold;">&nbsp;{{values.cardSegnali.currentDate|customDate:'medium'|async}}</span>-->
                </span>
            </mat-toolbar-row>
        </mat-toolbar>
        <div class="flex-start-stretch flex-flow-row w100">
            <ng-container *ngIf="values.cardSegnali.lampadaAccesa!=null">
                <mat-card style="flex: 1;margin: 5px" [ngClass]="values.cardSegnali.lampadaAccesa.cssClass">
                    <mat-card-subtitle
                            [ngStyle]="{color:values.cardSegnali.lampadaAccesa.color}"
                            style="font-weight: bold;">{{'associatedLightMateId.lampadaAccesa'|translate}}</mat-card-subtitle>
                    <h3 style="text-align: center">
                        {{values.cardSegnali.lampadaAccesa.traduction|translate}}
                    </h3>
                </mat-card>
            </ng-container>
            <ng-container *ngIf="values.cardSegnali.lampadaFunzionante!=null">
                <mat-card style="flex: 1;margin: 5px;" [ngClass]="values.cardSegnali.lampadaFunzionante.cssClass">
                    <mat-card-subtitle
                            style="font-weight: bold;color: white">{{'associatedLightMateId.lampadaFunzionante'|translate}}</mat-card-subtitle>
                    <h3 style="text-align: center">
                        {{values.cardSegnali.lampadaFunzionante.traduction|translate}}
                    </h3>
                </mat-card>
            </ng-container>
            <ng-container *ngIf="values.cardSegnali.fase!=null">
                <mat-card style="flex: 1;margin: 5px" class="background-accent">
                    <mat-card-subtitle
                            style="font-weight: bold;color: white">{{'associatedLightMateId.fase'|translate}}</mat-card-subtitle>
                    <h3 style="text-align: center">
                        {{values.cardSegnali.fase|translate}}
                    </h3>
                </mat-card>
            </ng-container>
        </div>
    </ng-container>
    <ng-container *ngIf="values.infoSensorData!=null">
        <mat-toolbar color="primary">
            <mat-toolbar-row>
                <span style="color: #2196f3">{{'associatedLightMateId.infoSensorData'|translate}}</span>
            </mat-toolbar-row>
        </mat-toolbar>
        <div class="flex-start-stretch flex-flow-row w100">
            <ng-container *ngFor="let sensorData of values.infoSensorData">

                <mat-card style="text-align: center;min-width: 80px;margin: 5px">
                    <mat-card-subtitle style="font-weight: bold;margin-bottom: 5px"
                                       *ngIf="sensorData.title">{{('sensorData.' + sensorData.title + '.title')|traductionOnlyHasTraduction:undefined:sensorData.title|async}}</mat-card-subtitle>
                    <div style="text-align: center;padding: 0;font-weight: bold;font-size: large">
                        {{sensorData.value}}
                        <ng-container *ngIf="sensorData.unit">
                            &nbsp;{{('sensorData.' + sensorData.unit + '.unit')|traductionOnlyHasTraduction:undefined:''|async}}
                        </ng-container>
                    </div>
                </mat-card>
            </ng-container>
        </div>
    </ng-container>
</ng-container>
<ng-template #NoData>
    <ng-container *ngIf="!loading">
        <app-list-is-empty>
            <div>{{'errorNoDataPeriod'|translate}}</div>
        </app-list-is-empty>
    </ng-container>
</ng-template>