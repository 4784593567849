import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {DashboardComponent} from './dashboard/dashboard.component';
import {SelectProjectComponent} from './select-project/select-project.component';
import {PathGuard} from "../../providers/guard/path.guard";
import {PagesMainMapComponent} from "./pages-main-map/pages-main-map.component";
import {IsSetProjectGuard} from "../../providers/guard/is-set-project.guard";

const routes: Routes = [
    {
        path: '',
        component: PagesMainMapComponent,
        canActivate: [PathGuard, IsSetProjectGuard]
    },
    {
        path: 'main-map',
        component: DashboardComponent,
    },
    {
        path: 'project',
        component: SelectProjectComponent
    },
    {
        path: 'map',
        component: PagesMainMapComponent,
        canActivate: [IsSetProjectGuard]
    },

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DashboardRoutingModule {
}
