<ng-container *ngIf="canICreatePlan">
    <form *ngIf="planForm" [formGroup]="planForm">
        <h3 mat-dialog-title>
            <div>{{'report' | translate}}</div>
            <div style="font-size: 14px; font-weight: normal; color: rgba(0,0,0,.64);">{{'create_report' | translate}}</div>
        </h3>
        <mat-dialog-content style="font-size: 14px;">
            <mat-form-field floatLabel="auto" appearance="fill">
                <mat-label>{{ 'title' | translate}}</mat-label>
                <input formControlName="titolo" matInput required>
            </mat-form-field>
            <div class="spacer">
                <label class="label">{{ 'priority' | translate}}</label>
                <div>
                    <mat-radio-group formControlName="priorita" id="priorita">
                        <mat-radio-button style="margin-top: 0.3rem;" value="0">{{'low' | translate}}
                        </mat-radio-button>
                        <mat-radio-button style="margin-top: 0.3rem;" value="1">{{'middle' | translate}}
                        </mat-radio-button>
                        <mat-radio-button style="margin-top: 0.3rem;" value="2">{{'high' | translate}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <br>
            <mat-form-field class="example-full-width" floatLabel="auto" appearance="fill">
                <mat-label>{{'intervent_type' | translate}}</mat-label>
                <input formControlName="tipologiaIntervento" type="text"
                    matInput [formControl]="myControl" [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option" (click)="setType(option)">
                        {{option}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <div *ngIf="!isUser()">
                <mat-slide-toggle formControlName="privata">{{'privata' | translate}}</mat-slide-toggle>
            </div>
            <br>
            <mat-form-field floatLabel="auto" appearance="fill">
                <mat-label>{{ 'description' | translate}}</mat-label>
                <textarea class="scrollbar-visible" formControlName="descrizione" matInput
                        required
                        rows="12"></textarea>
            </mat-form-field>

            <div class="mat-form-field">
                <label class="label"> {{ 'photo' | translate}}</label>
                <ngx-dropzone (change)="onLoadImage($event.addedFiles,'foto')"
                            accept="image/*">
                    <ngx-dropzone-label>
                        {{ 'drag_image' | translate}}
                    </ngx-dropzone-label>
                </ngx-dropzone>
                <mat-list role="list">
                    <ng-container *ngIf="planForm.get('foto').value">
                        <div>
                            <a href="{{planForm.get('foto').value.url}}" target="_blank">
                                <img src="{{planForm.get('foto').value.url}}" height="30px">
                            </a>
                        </div>
                        <button class="remove-attachment-button"  (click)="deleteSavedFile('foto')">
                            {{ 'remove_attachement' | translate}}
                        </button>
                    </ng-container>
                </mat-list>
            </div>
            <div class="spacer" *ngIf="lightNumber > 0 && !circuit">
                <div><label
                        class="label">{{ 'lights_number' | translate}}</label>&ensp;{{lightNumber}}
                </div>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions style="justify-content: end;">
            <button matTooltip="Salva" mat-flat-button color="primary" class=""
                    (click)="createReport(); ">{{ 'create' |translate}}
            </button>
        </mat-dialog-actions>
    </form>
</ng-container>
<ng-container *ngIf="!canICreatePlan && loaded">
    <h3 mat-dialog-title>
        <div>{{'report' | translate}}</div>
        <div style="font-size: 14px; font-weight: normal; color: rgba(0,0,0,.64);">{{'create_report' | translate}}</div>
    </h3>
    <mat-dialog-content style="font-size: 14px;">
        <div>
            {{'create_report_error' | translate}}
        </div>
    </mat-dialog-content>
</ng-container>