<div class="flex-center-width100"
     style="flex-flow: row;flex-wrap: wrap;justify-content: space-evenly;margin-top:10px;margin-bottom:10px ">

    <button [disabled]="dateForm.disabled"
            color="accent"
            mat-mini-fab
            (click)="changeYearPrevius()"
    >
        <mat-icon>keyboard_arrow_left</mat-icon>
        <mat-icon style="margin-left: -15px;margin-right: 3px;">keyboard_arrow_left</mat-icon>
    </button>
    <button [disabled]="dateForm.disabled"
            (click)="changeMonthPrevius()"
            color="accent"
            mat-mini-fab
    >
        <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <mat-card class="date-container">
        {{dateForHtml}}
    </mat-card>
    <button [disabled]="monthIsAfterToday"
            (click)="changeMonthNext()"
            color="accent"
            mat-mini-fab
    >
        <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
    <button [disabled]="yearIsAfterToday"
            color="accent"
            mat-mini-fab
            (click)="changeYearNext()"
    >
        <mat-icon style="margin-left: 3px;">keyboard_arrow_right</mat-icon>
        <mat-icon style="margin-left: -15px;">keyboard_arrow_right</mat-icon>
    </button>
</div>