import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {className} from "../../../models/Models";

@Component({
  selector: 'app-eliminate-all-element',
  templateUrl: './eliminate-all-element.component.html',
  styleUrls: ['./eliminate-all-element.component.scss']
})
export class EliminateAllElementComponent implements OnInit {

  constructor(
      public dialogRef: MatDialogRef<EliminateAllElementComponent>,
      @Inject(MAT_DIALOG_DATA) public data: {}
  ) { }

  public nomiClasse = className;
  public visualizzaBottone = true;
  public deleteName;

  selectElementDelete(deleteName){
    this.visualizzaBottone=false;
    this.deleteName=deleteName;
  }

  ngOnInit(): void {
  }

}
