<mat-expansion-panel>
    <mat-expansion-panel-header>
        <mat-panel-title>
            <ng-content select="[titleDescription]">

            </ng-content>

        </mat-panel-title>
        <mat-panel-description>
            <ng-content select="[headerDescription]">

            </ng-content>
           <span style="color: black;"> {{profiloFunzionamento.name}}</span>
        </mat-panel-description>
    </mat-expansion-panel-header>
    <mat-list role="list">
        <ng-container *ngFor="let fascia of profiloFunzionamento.intervals; let last=last">
            <ng-container>
                <mat-list-item role="listitem" [ngClass]="{'border-bottom-solid':!last}">
                    <div class="flex-around-center flex-flow-row w100">
                        <div class="flex-start-start flex-flow-column">
                            <div>
                                {{'pageProfile.initHuor'|translate}}
                            </div>
                            <div>
                                {{fascia|convertValueByPredicateFunction:predicateFunctionGetHour}}
                            </div>
                        </div>
                        <div class="flex-start-start flex-flow-column">
                            <div>
                                {{'pageProfile.power'|translate}}
                            </div>
                            <div>
                                {{fascia.power}}&nbsp;%
                            </div>
                        </div>
                    </div>
                </mat-list-item>
            </ng-container>
        </ng-container>
    </mat-list>
</mat-expansion-panel>
