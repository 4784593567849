import {ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ordinamentoEcampiTraduzioni} from '../../../models/ordinamentoEcampiTraduzioni';
import {BehaviorSubject, combineLatest, EMPTY, forkJoin, Observable, of, Subject, Subscription} from 'rxjs';
import {ProjectService} from '../../../providers/services/project.service';
import {
    ActionClickMarker,
    DistancesRuler, ElectricLinesMap, GeoPointH,
    InterdistanceLightPoint,
    MapService,
    positionMarker
} from '../../../providers/services/map.service';
import {catchError, delay, filter, map, startWith, switchMap, toArray} from 'rxjs/operators';
import {CircuitiParse} from '../../../models/Circuiti.Parse';
import {StradeParse} from '../../../models/Strade.Parse';
import {PuntiLuceParse, PuntoLuce_v2} from '../../../models/PuntiLuce.Parse';
import {checkDiff, isNotNullOrUndefined, KeyStringValue, objectIsSet} from "../../../models/Models";
import {HttpClient} from '@angular/common/http';
import {FormFilterComponent} from '../../../providers/forms/filter-form.service';
import {LoaderService} from '../../../providers/services/loader.service';
import {
    arrayIsSet,
    chiaviFiltri,
    className,
    getArrayToRemveItem, getDifferenceArrayWithKey,
    getItemInArrayByKeyValue, getUniqueValueInArray,
    modalityViewMap, objectForkJoin$, stringIsSet, substitutionElementInArray
} from 'src/app/models/Models';
import {PictureCameraPointParse} from '../../../models/PictureCameraPoint.Parse';
import {LocalDataBaseParseService} from '../../../providers/services/local-data-base-parse.service';
import {FileServiceService} from '../../../providers/services/file-service.service';
import {InfoDialogComponent} from '../info-dialog/info-dialog.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {DatePipe} from '@angular/common';
import {SegnaliTlcNodoService} from '../../../providers/services/segnali-tlc-nodo.service';
import {SegnaliTlcNodoParse} from '../../../models/SegnaliTlcNodo.Parse';
import {TranslateService} from '@ngx-translate/core';
import {LabelAndListMarkerTextService} from '../../../providers/services/label-and-list-marker-text.service';
import {Priority, ReportStates, UserRoleNumber} from '../../../../config/static-data';
import {UserService} from '../../../providers/services/user.service';
import {ReportsService} from '../../../providers/services/reports.service';
import {MatSidenav} from '@angular/material/sidenav';
import {CreateReportComponent} from '../../reports/create-report/create-report.component';
import {InfoReportComponent} from '../../reports/info-report/info-report.component';
import {CreateGroupComponent} from '../../reports/create-group/create-group.component';
import {InfoGroupComponent} from '../../reports/info-group/info-group.component';
import {AlertService} from '../../../providers/services/alert.service';
import {valueLinearScaleColorService} from '../../../providers/services/value-linear-color.service';
import {GoogleServiceService} from '../../../providers/services/google-service.service';
import {DialogPopUpService, TypeBatchEditVirtualMidnight} from '../../../providers/services/dialog-pop-up.service';
import {environment} from '../../../../environments/environment';
import {FilterService} from '../../../providers/services/filter.service';
import {CircuitScaleColorService} from '../../../providers/services/circuit-scale-color.service';
import {CircuitLabelService} from '../../../providers/services/circuit-label.service';
import {PuntiLuceScaleColorService} from '../../../providers/services/punti-luce-scale-color.service';
import {PuntiLuceLabelService} from '../../../providers/services/punti-luce-label.service';
import {LuxDataClusterService} from '../../../providers/services/lux-data-cluster.service';
import {LuxDataMapElementService} from '../../../providers/services/lux-data-map-element.service';
import {GruppiPuntiLuceParse} from '../../../models/GruppiPuntiLuce.Parse';
import {MoveFolderPopUpService} from '../../../providers/services/move-folder-pop-up.service';
import {FileManagerService} from '../../../providers/services/file-manager.service';
import {DocumentsFileParse} from '../../../models/DocumentsFile.Parse';
import {ArredoUrbanoUtilsService, typeKeyConstrainValue} from '../../../providers/services/arredo-urbano-utils.service';
import {ArredoUrbanoParse} from '../../../models/ArredoUrbano.Parse';
import {SchedaManutenzioneParse} from '../../../models/SchedaManutenzione.Parse';
import {
    formFiledMaintenanceFormContolName,
    MaintenanceService,
    typeElementScheduleMaintence
} from '../../../providers/services/maintenance.service';
import {CurrentViewService} from '../../../providers/services/current-view.service';
import {CalendarioManutenzioniService} from '../../../providers/services/calendario-manutenzioni-service';
import {CalendarioManutenzioniParse} from '../../../models/CalendarioManutenzioni.Parse';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {SegnalazioniParse} from '../../../models/Segnalazioni.Parse';
import {ActivitiesService} from '../../../providers/services/activities.service';
import {fromPromise} from 'rxjs/internal-compatibility';
import {DialogPopUpInfoService} from '../../../providers/services/dialog-pop-up-info.service';
import {PuntiLuceService} from '../../../providers/services/punti-luce.service';
import {SmartSearchServiceService} from '../../../providers/services/smart-search-service.service';
import {SchedaManutenzioneCompilataParse} from '../../../models/SchedaManutenzioneCompilata.Parse';
import {LineaElettricaService, valueDatalabel} from '../../../providers/services/linea-elettrica.service';
import {DialogPopUpCreateNewService} from '../../../providers/services/dialog-pop-up-create-new.service';
import {LineaElettricaParse} from '../../../models/LineaElettrica.Parse';
import {GeoPoint, GeoPointService} from '../../../providers/services/geo-point.service';
import {IconService} from '../../../providers/services/icon.service';
import {
    DataTypeLegend,
} from '../../../components/scale-for-map/legend-key-color/legend-key-color.component';
import {
    FormFieldPopUpData,
    FormFieldPopUpType
} from "../../../components/pop-up-info/form-field/form-field.component";
import {typeFormValue} from "../../../models/configurationProperty/configurationPropertyUtils";
import {CaricoEsogenoService} from "../../../providers/services/carico-esogeno.service";
import {CaricoEsogenoParse} from "../../../models/CaricoEsogeno.Parse";
import {UtilityParseFileService} from "../../../providers/services/utility-parse-file.service";
import {ProgrammiMvService} from "../../../providers/services/programmi-mv.service";
import {ProfileTlcService} from "../../../providers/services/profile-tlc.service";
import {TLC_ProfiloFunzionamentoParse} from "../../../models/TLC_ProfiloFunzionamento.Parse";
import {RemoteControlService, TypeVersioneProtocolloLM} from "../../../providers/services/remote-control.service";
import {AggregateDataService} from "../../../providers/services/aggregate-data.service";
import {StreetsService} from "../../../providers/services/streets.service";
import * as Parse from "parse";
import {FotoTipologiaService} from "../../../providers/services/foto-tipologia.service";


@Component({
    selector: 'app-pages-main-map',
    templateUrl: './pages-main-map.component.html',
    styleUrls: ['./pages-main-map.component.scss']
})
export class PagesMainMapComponent implements OnInit, OnDestroy {


    /**
     * livello in meno cluster
     *
     * @private
     */
    private reportStates = ReportStates;
    public maxZoomCluster = 19;
    abbonamentoTelecontrolloAttivo$: Observable<boolean>
    abbonamentoProgettazioneAttivo$: Observable<boolean>
    abbonamentoSegnalazioniAttivo$: Observable<boolean>
    abbonamentoIlluminamentoAttivo$: Observable<boolean>
    tuttiGliAbbonamenti$: Observable<KeyStringValue<boolean>>
    public isGestore$: Observable<boolean>
    public isOperatore$: Observable<boolean>

    constructor(
        private changeDetection: ChangeDetectorRef,
        private projectService: ProjectService,
        private userService: UserService,
        private mapService: MapService,
        private stradeService: StreetsService,
        private serviHttp: HttpClient,
        private loaderService: LoaderService,
        private localDataBaseParseService: LocalDataBaseParseService,
        private fileService: FileServiceService,
        public dialog: MatDialog,
        private datePipe: DatePipe,
        private segnaliTlcNodoService: SegnaliTlcNodoService,
        private translateService: TranslateService,
        private labelMarker: LabelAndListMarkerTextService,
        private reportsService: ReportsService,
        private alertService: AlertService,
        private linearScaleService: valueLinearScaleColorService,
        private googleService: GoogleServiceService,
        private dialogPopUpService: DialogPopUpService,
        private moveToFolder: MoveFolderPopUpService,
        private filterService: FilterService,
        private scaleColorPuntiLuce: PuntiLuceScaleColorService,
        private puntiLuceLabelService: PuntiLuceLabelService,
        private scaleColorCircuiti: CircuitScaleColorService,
        private circutiLabelService: CircuitLabelService,
        private luxDataClusterService: LuxDataClusterService,
        private luxDataMapElementService: LuxDataMapElementService,
        private fileManagerService: FileManagerService,
        private arredoUrbanoUtilsService: ArredoUrbanoUtilsService,
        private maintenanceService: MaintenanceService,
        private activitiesService: ActivitiesService,
        private currentViewService: CurrentViewService,
        private calendarioManutenzioneService: CalendarioManutenzioniService,
        private dialogPopUpInfoService: DialogPopUpInfoService,
        private fb: UntypedFormBuilder,
        private puntiLuceService: PuntiLuceService,
        private smartSearchServiceService: SmartSearchServiceService,
        private lineaElettricaService: LineaElettricaService,
        private dialogPopUpCreateNewService: DialogPopUpCreateNewService,
        private caricoEsogenoService: CaricoEsogenoService,
        private geoPointService: GeoPointService,
        private iconService: IconService,
        private utilityParseFile: UtilityParseFileService,
        private programMvService: ProgrammiMvService,
        private profileTlcService: ProfileTlcService,
        private remoteContolService: RemoteControlService,
        private aggregateDataService: AggregateDataService,
        private fotoTipologiaService: FotoTipologiaService
    ) {
        this.getIconSelected = (pl, opacity) => {
            return this.scaleColorPuntiLuce.getIconPuntoLuce(pl, opacity)
        }
        const formDynamicView = {
            isOpenedSetting: false,
            isOn: false,
            bounds: null,
            searchAddress: null
        };
        formDynamicView[this.nomeClassi.puntiLuce] = false;
        formDynamicView[this.nomeClassi.circuiti] = false;
        formDynamicView[this.nomeClassi.arredoUrbano] = false;
        formDynamicView[this.nomeClassi.lineaElettrica] = false;
        this.formPage = this.fb.group({
            oldValueFilter: null,
            numeroQuadro: null,
            typeArredoUrbano: null,
            searchSmart: this.smartSearchServiceService.formSmartSearch(['lightPointIdEtichette', 'oldValue']),
            addElectricLine: this.lineaElettricaService.getForm(new LineaElettricaParse()),
            dynamicView: this.fb.group(formDynamicView),
            electricLinesMap: this.fb.group({key: 'name', scaleColor: null})
        });
        this.resetVariable();
        this.initComponent();
        this.scaleColorPuntiLuce.calcolaScalaColore(this.scaleColorPuntiLuce.campoScalaColore, this.lightPoints);
        this.scaleColorCircuiti.calcolaScalaColore(this.scaleColorCircuiti.campoScalaColore, this.circuits);
        this.formPage.get('searchSmart').get('oldValue').setValue(this.smartSearchServiceService.lastActiveSmartSearch);
        this.initDynamicScaleChangeColorElectricLines();
        this.carichiEsogeniNelPorgetto$ = this.caricoEsogenoService.carichiEsogeni$;
        this.abbonamentoTelecontrolloAttivo$ = this.projectService.abbonamentoTelecontrolloAttivo$;
        this.abbonamentoProgettazioneAttivo$ = this.projectService.abbonamentoProgettazioneAttivo$;
        this.abbonamentoSegnalazioniAttivo$ = this.projectService.abbonamentoGestioneAttivo$;
        this.abbonamentoIlluminamentoAttivo$ = this.projectService.abbonamentoIlluminamentoAttivo$;
        this.tuttiGliAbbonamenti$ = combineLatest([this.abbonamentoSegnalazioniAttivo$, this.abbonamentoTelecontrolloAttivo$, this.abbonamentoProgettazioneAttivo$, this.abbonamentoIlluminamentoAttivo$]).pipe(
            map((values) => {
                const gestioneAttivo = values[0];
                const telecontrolloAttivo = values[1];
                const progettazioneAttivo = values[2];
                const illuminamentoAttivo = values[3];
                return {
                    telecontrolloAttivo,
                    illuminamentoAttivo,
                    gestioneAttivo,
                    progettazioneAttivo
                };
            })
        )

        this.isGestore$ = this.projectService.isGestore$;
        this.isOperatore$ = this.projectService.isOperatore$;
        // this.caricoEsogenoService.initCarichiEsogeni();
        // this.carichiEsogeniInProject$ = this.caricoEsogenoService.carichiEsogeni$;
    }


    setSearchAddress(event: any) {
        if (event != null) {
            this.formPage.get('dynamicView').get('searchAddress').setValue(event);
        } else {
            this.formPage.get('dynamicView').get('searchAddress').reset();
        }
    }

    get fromElectricLine(): UntypedFormGroup {
        return this.formPage.get('electricLinesMap') as UntypedFormGroup;
    }

    get electricLineKey(): string {
        return this.formPage.get('electricLinesMap').get('key').value;
    }

    get electricLineScaleColor(): DataTypeLegend[] {
        return this.formPage.get('electricLinesMap').get('scaleColor').value;
    }

    set electricLineScaleColor(value: DataTypeLegend[]) {
        if (value != null) {
            this.formPage.get('electricLinesMap').get('scaleColor').setValue(value);
        } else {
            this.formPage.get('electricLinesMap').get('scaleColor').reset();
        }
    }

    get currentUser() {
        return this.userService.currentUser;
    }

    get appFilterAllVariableIsReady() {
        let isReady = true;
        Object.values(this.appFilterCircuitiGruppiLightPointIsReady).forEach(
            value => {
                if (!value) {
                    isReady = false;
                }
            }
        );
        return isReady;
    }

    public get lightPoints(): PuntiLuceParse[] {
        let allLightPoints = [];
        if (Array.isArray(this.clustersLightPoints)) {
            const lightPointsNotInUniqueArray = this.clustersLightPoints.map(cluster => cluster.element);
            allLightPoints = (!Array.isArray(lightPointsNotInUniqueArray) || lightPointsNotInUniqueArray.length <= 0) ? [] : lightPointsNotInUniqueArray.reduce((a, b) => {
                const aIsArray = Array.isArray(a);
                const bIsArray = Array.isArray(b);
                if (aIsArray && bIsArray) {
                    return a.concat(b);
                } else if (aIsArray && !bIsArray) {
                    return a;
                } else if (!aIsArray && bIsArray) {
                    return b;
                } else {
                    return [];
                }
            });
        }
        return allLightPoints;
    }

    public get circuits(): CircuitiParse[] {
        let allCircuits = [];

        if (Array.isArray(this.clustersCircuits)) {
            const lightPointsNotInUniqueArray = this.clustersCircuits.map(cluster => cluster.element);
            allCircuits = (!Array.isArray(lightPointsNotInUniqueArray) || lightPointsNotInUniqueArray.length <= 0) ? [] : lightPointsNotInUniqueArray.reduce((a, b) => {
                const aIsArray = Array.isArray(a);
                const bIsArray = Array.isArray(b);
                if (aIsArray && bIsArray) {
                    return a.concat(b);
                } else if (aIsArray && !bIsArray) {
                    return a;
                } else if (!aIsArray && bIsArray) {
                    return b;
                } else {
                    return [];
                }
            });
        }
        return allCircuits;
    }


    get streetViewIsOpened() {
        return isNotNullOrUndefined(this.map) && isNotNullOrUndefined(this.map.streetView) && this.map.streetView.getVisible();
    }

    public get gestore() {
        return this.projectService.isGestore();
    }

    public get isOperatore() {
        return this.projectService.isOperatore();
    }

    public get abbonamentoSegnalazioniAttivo(): boolean {
        return this.projectService.abbonamentoGestioneAttivo;
    }

    public get abbonamentoIlluminametoAttivo(): boolean {
        return this.projectService.abbonamentoIlluminamentoAttivo;
    }

    public get abbonamentoProgettazioneAttivo(): boolean {
        return this.projectService.abbonamentoProgettazioneAttivo;
    }

    public get userAlmenoLivello5(): boolean {
        return this.userService.abbonamentoAlmenoLivello(5);
    }

    public get userAlmenoLivello0(): boolean {
        return this.userService.abbonamentoAlmenoLivello(0);
    }

    get tuttiAbbonamenti() {
        return {
            telecontrolloAttivo: this.projectService.abbonamentoTelecontrolloAttivo,
            illuminamentoAttivo: this.abbonamentoIlluminametoAttivo,
            gestioneAttivo: this.abbonamentoSegnalazioniAttivo
        };
    }

    get projectLoaded() {
        return Boolean(this.projectService.actualProject);
    }

    get isSetInterdistance() {
        return !this.interdistanceOff && isNotNullOrUndefined(this.interdistances) && this.interdistances.length > 0;
    }

    public get classNameAddElement(): string {
        if (arrayIsSet(this.classElementToAdd) && this.classElementToAdd.includes(this.nomeClassi.circuiti)) {
            return this.nomeClassi.circuiti;
        } else if (arrayIsSet(this.classElementToAdd) && this.classElementToAdd.includes(this.nomeClassi.arredoUrbano)) {
            return this.nomeClassi.arredoUrbano;
        } else {
            return undefined;
        }
    }

    private get circuitiKeyScalaColore() {
        return {
            key: this.scaleColorCircuiti.campoScalaColore,
            scalaColore: this.scaleColorCircuiti.scalaColoreCompleta
        };
    }


    public get myRole() {
        return this.projectService.myRole;
    }

    get disableButtonConnection() {
        return (this.circuits.length === 0 && this.lightPoints.length === 0) || this.disableButton.fileConnections;
    }


    get isActiveModalitaManutenzione() {
        return this.currentViewService.isActiveModalitaManutenzione;
    }

    get currentVisualization() {
        return this.currentViewService.currentView;
    }

    get dynamicViewIsOn() {
        return this.formPage.get('dynamicView').get('isOn').value;
    }

    get dynamicViewOpenSetting$() {
        return this.formPage.get('dynamicView').get('isOpenedSetting').valueChanges;
    }

    private subscriptionScaleColorElectricLine: Subscription[] | undefined;

    @ViewChild('sidenavStart', {static: false}) sidenavStart: MatSidenav;
    @ViewChild('sidenavEnd', {static: false}) sidenavEnd: MatSidenav;


    public userRoleNumber = UserRoleNumber;
    public lightPointLimit = environment.lightPointLimit;
    public stradeAppartenentiAlProgetto: StradeParse[] = [];
    public circuitiAppartenentiAlProgetto: CircuitiParse[] = [];
    public gruppiAppartenentiAlProgetto: GruppiPuntiLuceParse[] = [];
    // public carichiEsogeniInProject$: Observable<CaricoEsogenoParse[]>
    public arredoUrbanoAppartenentiAlProgettoExport: { [k: string]: any } = {};
    private subscriptionForonDestroy: Subscription[] = [];
    // public clustersLightPoints: { color: string, element: PuntiLuceParse[] }[] = [];
    public clustersLightPointsEmit = new BehaviorSubject<{ color: string, element: PuntiLuceParse[] }[]>([]);

    lightPoints_v2_Emit = new BehaviorSubject<PuntiLuceParse[]>([]);
    lightPoints$_v2 = this.lightPoints_v2_Emit.asObservable();
    selectedLightPoints$ = this.reportsService.reportsInMap$
    getIconSelected
    public lightPointsIsSet$: Observable<boolean> = this.lightPoints$_v2.pipe(
        map((lightPoint) => {
            return arrayIsSet(lightPoint);
        })
    );

    get lightPoints_v2(): PuntiLuceParse[] {
        return this.lightPoints_v2_Emit.value;
    }

    set lightPoints_v2(value: PuntiLuceParse[]) {
        this.lightPoints_v2_Emit.next(value);
    }

    circuits_v2_Emit = new BehaviorSubject<CircuitiParse[]>([]);
    circuits$_v2 = this.circuits_v2_Emit.asObservable();

    get circuits_v2(): CircuitiParse[] {
        return this.circuits_v2_Emit.value;
    }

    set circuits_v2(value: CircuitiParse[]) {
        this.circuits_v2_Emit.next(value);
    }

    addLightPoints_v2(puntiLuce: PuntiLuceParse[]) {
        const currents = this.lightPoints_v2.slice();
        const ids = currents.map(p => p.objectId);
        puntiLuce.forEach(pl => {
            if (!ids.includes(pl.objectId)) {
                currents.push(pl)
            }
        })
        this.lightPoints_v2 = currents;
    }


    substitutionOrAddLightPoint(puntoLuce: PuntiLuceParse) {
        const lightPoints = this.lightPoints_v2.slice()
        const index = lightPoints.findIndex(c => c.objectId === puntoLuce.objectId)

        if (index >= 0) {
            const icon = lightPoints[index].icon;
            const label = lightPoints[index].labelMarker;
            puntoLuce.icon = icon;
            puntoLuce.labelMarker = label;
            lightPoints.splice(index - 1, 1)
        }
        lightPoints.push(puntoLuce)
        this.lightPoints_v2 = lightPoints;
    }

    addCircuits_v2(circuiti: CircuitiParse[]) {
        const currents = this.circuits_v2.slice();
        const ids = currents.map(c => c.objectId);
        circuiti.forEach(circuito => {
            if (!ids.includes(circuito.objectId)) {
                currents.push(circuito)
            }
        })
        this.circuits_v2 = currents;
    }


    substitutionOrAddCircuit(circuito: CircuitiParse) {
        const circuits = this.circuits_v2.slice();
        const index = circuits.findIndex(c => c.objectId === circuito.objectId)
        if (index >= 0) {
            const icon = circuits[index].icon;
            const label = circuits[index].labelMarker;
            circuito.icon = icon;
            circuito.labelMarker = label;
            circuits[index] = circuito
        } else {
            circuits.push(circuito)
        }
        this.circuits_v2 = circuits
    }


    public get clustersLightPoints(): { color: string, element: PuntiLuceParse[] }[] {
        return this.clustersLightPointsEmit.value;
    }

    public set clustersLightPoints(value) {
        this.clustersLightPointsEmit.next(value);
    }

    // public clustersCircuits: { color: string, element: CircuitiParse[] }[] = [];
    public clustersCircuitsEmit = new BehaviorSubject<{ color: string, element: CircuitiParse[] }[]>([]);
    public clustersCircuits$: Observable<{
        color: string,
        element: CircuitiParse[]
    }[]> = this.clustersCircuitsEmit.asObservable();
    public circuits$: Observable<CircuitiParse[]> = this.clustersCircuits$.pipe(
        map((clusters) => {
            if (arrayIsSet(clusters)) {
                return this.circuits;
            } else {
                return []
            }
        })
    );

    public get clustersCircuits(): { color: string, element: CircuitiParse[] }[] {
        return this.clustersCircuitsEmit.value;
    }

    public set clustersCircuits(value) {
        this.clustersCircuitsEmit.next(value);
    }

    public forceRendering = false;
    public confiniAmministrativi;
    private appValueFilters: { previusValue: FormFilterComponent, currentValue: FormFilterComponent };
    private allLightPointsInProject: PuntiLuceParse[] = [];
    public puntiStampa: PictureCameraPointParse[] = [];
    public arrediUrbaniOnMap: ArredoUrbanoParse[] = [];
    public profileTlcInDatabase: TLC_ProfiloFunzionamentoParse[];

    public chiaviFitri = chiaviFiltri;
    public forceUpdateCenterMap = false;
    public lightPointFilter;

    public changeZoomInMap: Subject<boolean> = new Subject();
    public changeFilter: Subject<{
        puntiLuce?: boolean,
        circuiti?: boolean,
        addElectricLines?: LineaElettricaParse[]
    }> = new Subject();
    public resetIlluminamentoTerra: Subject<boolean> = new Subject();

    public visible = false;

    public openedDrawingManager = false;


    public sidenavEndWhoOpen: 'settings' | 'formLightPoint';

    public elementoCliccato: PuntiLuceParse | CircuitiParse | ArredoUrbanoParse;

    get elementoCliccatoIsCircuito(): boolean {
        return this.elementoCliccato != null && this.elementoCliccato.className === this.nomeClassi.circuiti
    }

    public nomeClassi = className;
    public formOnMapToVisualize = {searchSmart: 'searchSmart', dynamicView: 'dynamicView'};

    public elementiScalaLux;
    public luxmax;
    public luxmin;

    public interdistances: InterdistanceLightPoint[] = [];
    public distances: DistancesRuler[] = [];
    drawedElectricLines: ElectricLinesMap[] | undefined;
    electricLines: LineaElettricaParse[] | undefined;
    electricLinesOnMap: LineaElettricaParse[] | undefined;
    public distancess: [DistancesRuler[]];
    // i valori degli  infoWindow da visualizzare per ogni polyline
    public polyLineInfoWindow: { objectId, lat, lng, persistent }[] = [];
    public interdistanceOff = true;
    myPosition: { isEnabled: boolean, lat: number, lng: number, icon } = {
        isEnabled: false,
        lat: null,
        lng: null,
        icon: {url: '/assets/icon/newLamp/myPosition.svg', anchor: {x: 16, y: 28}}
    };
    istanzaWarchPosition;
    public interdistanceDetail: { average: number, total: number, maximun: number, minimum: number };
    openedAddPuntiLuceCircuito = false;
    openedRuler = false;
    openedDrawingElectricLine = false;
    private allCircuitsInProject;

    appFilterCircuitiGruppiLightPointIsReady = {circuiti: false, gruppiLightPoint: false, arrediUrbani: false};

    // emette valore boolean al click del pulsante
    // public listenToChangeLuxDataVisibility= new Subject<boolean>();
    // contiene l'idle listener. Se null il listener non è attivo


    public disableButton: {
        addElement: boolean,
        deleteAll: boolean,
        groupLightPoint: boolean,
        fileConnections: boolean,
        handButton: boolean,
        batchEdit: boolean,
        move: boolean,
        ruler: boolean,
        openedDrawingElectricLine: boolean
    } = {
        addElement: false,
        deleteAll: false,
        handButton: false,
        batchEdit: false,
        move: false,
        groupLightPoint: false,
        ruler: false,
        fileConnections: false,
        openedDrawingElectricLine: false,

    };

    public loading: {
        eyeButton: boolean,
        settingsButton: boolean,
        refreshButton: boolean,
        elementsInMap: boolean,
        plusButton: boolean,
        moveSave: boolean,
        updateLightPoint: boolean,
        searchSmart: boolean,
        saveLine: boolean,
        navigate: boolean,
        batchVirtualMidnight: boolean
    } = {
        eyeButton: false,
        elementsInMap: false,
        settingsButton: false,
        refreshButton: false,
        plusButton: false,
        moveSave: false,
        updateLightPoint: false,
        searchSmart: false,
        saveLine: false,
        navigate: false,
        batchVirtualMidnight: false
    };

    public markerClickable = true;


    private map;
    private subscriptionListner;

    private schedeManutenzioneDelProgetto = {};

    public modalityViewMap = modalityViewMap;
    public saveSchedaCompilata = new Subject();
    public getElementOnParseLoad = new Subject();

    calendariManutenzione: {
        date: number,
        fromDate: number,
        toDate: number,
        calendariManutenzione: CalendarioManutenzioniParse[]
    };
    calendariManutenzioneNonEffettuate: { date: number, calendariManutenzione: CalendarioManutenzioniParse[] };

    public segnalazioniInProjects: { [key: string]: SegnalazioniParse[]; };

    public formPage: UntypedFormGroup;
    public openSearchSmart = false;

    private projectChanged = false;

    public searchAddressCoordinate;

    public saveElement;

    private classElementToAdd;
    private customTypeArredoUrbano;

    public inSaveAddElement = false;

    public abilitaSpostamentoMarker;
    private posizioniIniziliaMarker: positionMarker[] = [];

    get isSetPosizioneInizialiMarker() {
        return arrayIsSet(this.posizioniIniziliaMarker);
    }

    oldBounds;

    forceUpdateDashboardSidenav = false;

    public clusterCalendariManutenzioneSelezionato;
    public segnalazioniAperteOnMap = [];
    public objectIdSchedeManutenzioneOnMap = {circuiti: [], puntiLuce: []};
    public objectIdSegnalazioniOnMap = {circuiti: [], puntiLuce: []};

    public defaultValue = {maintenanceComponent: {prioritySegnalzioni: [Priority.ALTA]}};

    calendariByElement: CalendarioManutenzioniParse[] | undefined;
    schedeCompilateByElement: SchedaManutenzioneCompilataParse[] | undefined;


    searchSmartSubscription: Subscription | undefined;
    dynamicViewSubscriptions: Subscription[] | undefined;

    repositioningElectricLines = false;
    locationsToCenterZoom: GeoPoint[] | undefined;
    mapOpenedFirstTimeInProject = true;
    public actionLine = ActionClickMarker;

    private valuesRepositoning: Subject<any>;
    expandSearchAddress = false;

    public carichiEsogeniNelPorgetto$: Observable<CaricoEsogenoParse[]>

    setScaleColor(value: any) {
        const keys = Object.keys(value);
        if (arrayIsSet(keys)) {
            keys.forEach(key => {
                if (this.fromElectricLine.get(key) != null) {
                    this.fromElectricLine.get(key).setValue(value[key]);
                }
            });
        }
    }


    initDynamicScaleChangeColorElectricLines() {
        if (!arrayIsSet(this.subscriptionScaleColorElectricLine)) {
            this.subscriptionScaleColorElectricLine = [];
            const sub = this.fromElectricLine.get('key').valueChanges.subscribe(key => {
                this.updateColorElectricLinesOnMap();
            });
            this.subscriptionScaleColorElectricLine.push(sub);
        }
    }

    destroyDynamicScaleChangeColorElectricLines() {
        if (arrayIsSet(this.subscriptionScaleColorElectricLine)) {
            this.subscriptionScaleColorElectricLine.forEach(sub => sub.unsubscribe());
            this.subscriptionScaleColorElectricLine = undefined;
        }
    }

    private closeAllSidenav() {
        this.sidenavEnd.close();
        this.sidenavStart.close();
    }

    public lengthAtLeast(array: any[] | undefined, minLength) {
        return arrayIsSet(array) && array.length > minLength;
    }

    getCalendariManutenzione(forceReload, dayGetTime: number, project = null, rangeDate: {
        fromDate: Date,
        toDate: Date
    } = undefined): Observable<{
        calendariDaEffetuare: {
            date: number,
            fromDate: number,
            toDate: number,
            calendariManutenzione: CalendarioManutenzioniParse[]
        },
        calendariManutenzioneNonEffettuate: { date: number, calendariManutenzione: CalendarioManutenzioniParse[] }
    }> {
        const getIdDate = () => {
            if (dayGetTime) {
                return dayGetTime
            } else {
                return rangeDate.fromDate.getTime() + ',' + rangeDate.toDate.getTime();
            }
        }
        let isCorrectDay = isNotNullOrUndefined(this.calendariManutenzione) && this.calendariManutenzione.date ? this.calendariManutenzione.date == getIdDate() : false;
        let calendarioManutenzione$;
        if (isNotNullOrUndefined(this.calendariManutenzione) && arrayIsSet(this.calendariManutenzione.calendariManutenzione) && isCorrectDay && !forceReload) {
            calendarioManutenzione$ = of({...this.calendariManutenzione});
        } else {
            calendarioManutenzione$ = this.calendarioManutenzioneService.getCalendarioManutenzioni(dayGetTime, project, rangeDate).pipe(
                map((calendariEffetuati) => {
                    return {
                        date: getIdDate(),
                        fromDate: rangeDate.fromDate.getTime(),
                        toDate: rangeDate.toDate.getTime(),
                        calendariManutenzione: calendariEffetuati
                    };
                }));
        }
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        isCorrectDay = isNotNullOrUndefined(this.calendariManutenzioneNonEffettuate) && this.calendariManutenzioneNonEffettuate.date ? this.calendariManutenzioneNonEffettuate.date == today.getTime() : false;
        let calendariManutenzioneNonEffettuate$;
        if (arrayIsSet(isNotNullOrUndefined(this.calendariManutenzioneNonEffettuate) && this.calendariManutenzioneNonEffettuate.calendariManutenzione) && isCorrectDay && !forceReload) {
            calendariManutenzioneNonEffettuate$ = of({...this.calendariManutenzioneNonEffettuate});
        } else {
            calendariManutenzioneNonEffettuate$ = this.calendarioManutenzioneService.getCalendariNonEffetuati(today.getTime(), project, rangeDate).pipe(
                map((calendariNonEffetuati) => {
                    return {date: today.getTime(), calendariManutenzione: calendariNonEffetuati};
                }));
        }
        return calendarioManutenzione$.pipe(
            switchMap(calendariDaEffetuare => {
                return calendariManutenzioneNonEffettuate$.pipe(
                    map((calendariManutenzioneNonEffettuate) => {
                        return {calendariDaEffetuare, calendariManutenzioneNonEffettuate};
                    })
                );
            })
        );
    }


    updateCalendariManutenzione(dayGetTime: number, rangeDate: { fromDate: Date, toDate: Date } = undefined) {
        this.getElementOnParseLoad.next({month: true, year: true});
        this.getCalendariManutenzione(false, dayGetTime, undefined, rangeDate).subscribe((
            {
                calendariDaEffetuare,
                calendariManutenzioneNonEffettuate
            }) => {
            this.calendariManutenzione = calendariDaEffetuare;
            this.calendariManutenzioneNonEffettuate = calendariManutenzioneNonEffettuate;
            this.getElementOnParseLoad.next({month: false, year: false});
        }, error => {
            this.getElementOnParseLoad.next({month: false, year: false});
            this.alertService.error(error);
        });
    }

    /**
     * Aggiunge o elimina il listener idle alla mappa. Se attivo crea i lux data clusters.
     * Se disattivato, cancella i clusters e il listener.
     * @private
     * @param typeRequest
     */
    public changeLuxDataVisibility(typeRequest: 'LUX' | 'AIR_PM2.5' | 'AIR_PM10' | null) {
        if (typeRequest) {
            this.luxDataClusterService.updateType(typeRequest);
            this.luxDataMapElementService.openUpdateData();
            // esegue richiesta perchè sennò non appare niente finchè non scatta idle
            this.luxDataClusterService.getLuxDataCluster(this.map.zoom, this.map.getBounds());
            this.subscriptionListner = this.luxDataClusterService.getListnerMap(this.map).subscribe(() => {
                this.luxDataClusterService.getLuxDataCluster(this.map.zoom, this.map.getBounds());
            });
        } else {
            this.luxDataMapElementService.destroyAll(true);
            if (isNotNullOrUndefined(this.subscriptionListner)) {
                this.luxDataClusterService.removeListner();
                this.subscriptionListner.unsubscribe();
                this.subscriptionListner = undefined;
            }
        }
    }

    private resetVariable() {

        this.lineaElettricaService.resetLocalLocationElements();
        this.confiniAmministrativi = undefined;
        this.clustersLightPoints = [];
        this.lightPoints_v2 = [];
        this.clustersCircuits = [];
        this.circuits_v2 = [];
        this.stradeAppartenentiAlProgetto = [];
        this.circuitiAppartenentiAlProgetto = [];
        this.gruppiAppartenentiAlProgetto = [];
        this.arredoUrbanoAppartenentiAlProgettoExport = {};
        this.arrediUrbaniOnMap = [];
        this.allLightPointsInProject = [];
        this.puntiStampa = [];
        this.lightPointFilter = undefined;
        this.projectChanged = false;
        this.appFilterCircuitiGruppiLightPointIsReady.circuiti = false;
        this.appFilterCircuitiGruppiLightPointIsReady.gruppiLightPoint = false;
        this.appFilterCircuitiGruppiLightPointIsReady.arrediUrbani = false;
        this.allCircuitsInProject = undefined;
        this.schedeManutenzioneDelProgetto = {};
        this.segnalazioniInProjects = {};
        this.segnalazioniInProjects[Priority.ALTA] = [];
        this.segnalazioniInProjects[Priority.MEDIA] = [];
        this.segnalazioniInProjects[Priority.BASSA] = [];
        this.clusterCalendariManutenzioneSelezionato = undefined;
        this.segnalazioniAperteOnMap = [];
        this.calendariManutenzione = {date: null, fromDate: null, toDate: null, calendariManutenzione: null};
        this.calendariManutenzioneNonEffettuate = {date: null, calendariManutenzione: null};
        this.objectIdSchedeManutenzioneOnMap = {circuiti: [], puntiLuce: []};
        this.objectIdSegnalazioniOnMap = {circuiti: [], puntiLuce: []};
        this.defaultValue = {maintenanceComponent: {prioritySegnalzioni: [Priority.ALTA]}};
        this.drawedElectricLines = undefined;
        this.electricLinesOnMap = undefined;
        this.formPage.get('addElectricLine').reset();
        this.formPage.get('electricLinesMap').get('key').reset('name');
        this.formPage.get('electricLinesMap').get('scaleColor').reset();

        // if (this.filterService.hasPuntiStampa()) {
        //     this.filterForm.get('puntiStampa').setValue(this.filterService.getLocalePuntiStampa(), {emitEvent: false});
        // }
        this.formPage.get('oldValueFilter').reset();
        this.mapOpenedFirstTimeInProject = true;
    }

    private forceRenderingMap() {
        setTimeout(() => {
            this.forceRendering = !this.forceRendering;
        });
    }

    private getConfini() {
        let progetto;
        if (this.projectService.avaiableProject) {
            progetto = this.projectService.actualProject;
        } else {
            progetto = this.mapService.getCurrentProjectMy();
        }
        if (isNotNullOrUndefined(progetto) && isNotNullOrUndefined(progetto.comune)) {
            return this.mapService.getComuniByObjectId(progetto.comune.id)
                .pipe(
                    filter((comune) => comune != undefined), // caso in cui il campo comune non è definito altrimenti darebbe errore
                    map((comune) => comune.confiniAmministrativiFile._url),
                    switchMap((confini) => {
                        return this.serviHttp.get(confini)
                    }),
                    map((progetti: any[]) => {
                        return progetti.map(progetto => {
                            return progetto.map((a) => {
                                return {lat: a[0], lng: a[1]};
                            });
                        });
                        // return {lat: a[0], lng: a[1]};
                    }));
        } else {
            return this.mapService.getprogettoAttuale().pipe(
                switchMap((progetto) => {
                    // this.projectService.actualProject = progetto;
                    if (!isNotNullOrUndefined(progetto.comune)) {
                        return of([]);
                    } else {
                        return this.mapService.getComuniByObjectId((!!progetto.comune) ? progetto.comune.id : '').pipe(
                            filter((comune) => comune != undefined), // caso in cui il campo comune non è definito altrimenti darebbe errore
                            map((comune) => comune.confiniAmministrativiFile._url),
                            switchMap((confini) => {
                                    return this.serviHttp.get(confini);
                                }
                            ),
                            map((progetti: any[]) => {
                                return progetti.map(progetto => {
                                    return progetto.map((a) => {
                                        return {lat: a[0], lng: a[1]};
                                    });
                                });
                                // return {lat: a[0], lng: a[1]};
                            })
                        );
                    }

                })
            );
        }

    }


    // private loadBackgrounOnDb() {
    //     this.allLightPointsInProject = [];
    //     const loader = this.loaderService.addLoader();
    //     this.localDataBaseParseService.dbExistForProject()
    //         .pipe(
    //             switchMap((exist) => {
    //                 if (exist) {
    //                     return this.localDataBaseParseService.getPuntiLuceFromLocale()
    //                 } else {
    //                     return EMPTY
    //                 }
    //             })
    //         ).subscribe(puntiLuce => {
    //         this.allLightPointsInProject.push(...puntiLuce.puntiLuce);
    //         if (puntiLuce.finished) {
    //             let puntiLuceFiltrati = [];
    //             if (this.lightPointFilter) {
    //                 puntiLuceFiltrati = this.allLightPointsInProject
    //                     .filter(
    //                         puntoLuce => {
    //                             if (isNotNullOrUndefined(puntoLuce.circuito)) {
    //                                 return this.lightPointFilter.includes(puntoLuce.circuito.objectId)
    //                             } else {
    //                                 return this.lightPointFilter.includes(chiaviFiltri.nessunQuadro)
    //                             }
    //                         }
    //                     );
    //                 this.lightPointFilter = undefined;
    //             }
    //             this.createClusterArray(puntiLuceFiltrati);
    //             this.forceRenderingMap();
    //             this.loaderService.removeLoader(loader);
    //             console.timeEnd('all')
    //         }
    //     }, error => {
    //         this.loaderService.removeLoader(loader);
    //     });
    //     console.time('all')
    // }


    private getInitValuesOnDatabase(project) {
        const combine = [];
        combine.push(this.mapService.getCircuiti$(project));
        combine.push(this.stradeService.getAllStradeParse$());
        combine.push(this.mapService.getGruppiPuntiLuce(project));
        combine.push(this.arredoUrbanoUtilsService.getDistinctValueOnParse(project));


        if (isNotNullOrUndefined(this.calendariManutenzione) && isNotNullOrUndefined(this.calendariManutenzione.fromDate) && isNotNullOrUndefined(this.calendariManutenzione.toDate)) {
            const rangeDate = {
                fromDate: new Date(this.calendariManutenzione.fromDate),
                toDate: new Date(this.calendariManutenzione.toDate)
            }
            combine.push(this.getCalendariManutenzione(true, undefined, project, rangeDate));
        } else if (isNotNullOrUndefined(this.calendariManutenzione) && isNotNullOrUndefined(this.calendariManutenzione.date)) {
            const date = this.calendariManutenzione.date;
            combine.push(this.getCalendariManutenzione(true, date, project));
        } else {
            combine.push(of({
                calendariManutenzione: undefined,
                calendariManutenzioneNonEffettuate: undefined
            }));
        }
        combine.push(this.lineaElettricaService.getElectricLines(undefined));
        return combineLatest(combine);
    }

    private initComponent() {
        // this.loadBackgrounOnDb();
        const subscriptionProject = this.projectService.projectChange()
            .pipe(delay(500))
            .subscribe(project => {
                this.projectChanged = true;
                this.getInitValuesOnDatabase(project)
                    .subscribe((items: any[]) => {
                        this.resetVariable();
                        this.changeFilter.next({puntiLuce: true, circuiti: true});
                        this.resetIlluminamentoTerra.next(true);
                        this.filterService.destroyAllFilter();
                        this.filterService.destoryLocalePuntiStampa();
                        const circuiti = items[0] as CircuitiParse[];
                        this.setCircuiti(circuiti);
                        const strade = items[1];
                        const gruppi = items[2] as GruppiPuntiLuceParse[];
                        const arrediUrbani = (items[3] as string[]);
                        this.appFilterCircuitiGruppiLightPointIsReady.circuiti = true;
                        this.appFilterCircuitiGruppiLightPointIsReady.gruppiLightPoint = true;
                        this.appFilterCircuitiGruppiLightPointIsReady.arrediUrbani = true;
                        this.circuitiAppartenentiAlProgetto = circuiti;
                        this.stradeAppartenentiAlProgetto = strade
                        this.setGruppiPuntiLuce(gruppi);
                        this.setArredoUrbanoFilter(arrediUrbani, 'tipologia');
                        if (items[4]) {
                            this.calendariManutenzione = items[4].calendariManutenzione;
                            this.calendariManutenzione = items[4].calendariManutenzioneNonEffettuate;
                        } else {
                            this.calendariManutenzione = undefined;
                            this.calendariManutenzioneNonEffettuate = undefined;
                        }
                        if (arrayIsSet(items[5])) {
                            this.electricLines = items[5];
                        } else {
                            this.electricLines = undefined;
                        }
                        this.projectChanged = false;

                    }, error => {
                        this.alertService.error(error);
                    });
            });
        this.subscriptionForonDestroy.push(subscriptionProject);
        this.mapService.getCircuiti$().subscribe((circuiti) => {
                this.appFilterCircuitiGruppiLightPointIsReady.circuiti = true;
                this.setCircuiti(circuiti);
            }
        );
        this.stradeService.getAllStradeParse$().subscribe((ArrayStrade) => {
                this.setStrade(ArrayStrade);
            }
        );
        this.mapService.getGruppiPuntiLuce().subscribe(gruppi => {
            this.appFilterCircuitiGruppiLightPointIsReady.gruppiLightPoint = true;
            this.setGruppiPuntiLuce(gruppi);
        });
        this.arredoUrbanoUtilsService.getDistinctValueOnParse().subscribe(arrediUrbani => {
            this.setArredoUrbanoFilter(arrediUrbani, 'tipologia');
        });
        this.lineaElettricaService.getElectricLines(undefined).subscribe(lineeElettriche => {
            this.electricLines = lineeElettriche;
        });
        // setTimeout(() => {
        //     this.lineaElettricaService.getElectricLineMapByLineaElettrica(this.electricLines).subscribe(lineeElettriche => {
        //         this.electricLines = lineeElettriche;
        //     });
        // }, 10000);
    }

    ngOnInit(): void {
        this.caricoEsogenoService.initCarichiEsogeni();
    }

    ngOnDestroy(): void {
        this.subscriptionForonDestroy.forEach(subscription => subscription.unsubscribe());
        this.changeLuxDataVisibility(null);
        this.destroyDynamicScaleChangeColorElectricLines();
        this.destroyAddElectricLine();
        this.destroyRepositioningValue();
        this.destroyDynamicView();
        this.caricoEsogenoService.destroyCarichiEsogeni();
    }


    symmetricDifferenceInArray(arr1: any[], arr2: any[]): {
        isPositive: boolean,
        difference,
        massiveDeselect: boolean
    } {
        if (Array.isArray(arr1) && Array.isArray(arr2)) {
            let arr1Filter;
            let arr2Filter;
            if (typeof arr1[0] === 'number' || stringIsSet(arr1[0])) {
                arr1Filter = arr1.filter(x => !arr2.includes(x));
                arr2Filter = arr2.filter(x => !arr1.includes(x));
            } else {
                const id1 = arr1.filter(v => v != null).map(value => value.objectId);
                const id2 = arr2.filter(v => v != null).map(value => value.objectId).filter(v => v != null);
                arr1Filter = arr1.filter(v => v != null).filter(x => !id2.includes(x.objectId));
                arr2Filter = arr2.filter(v => v != null).filter(x => !id1.includes(x.objectId));
            }
            const difference = arr1Filter.concat(arr2Filter);
            return {
                isPositive: arrayIsSet(difference) ? arr1Filter.length < arr2Filter.length : false,
                difference,
                massiveDeselect: false
            };
        } else if (Array.isArray(arr1) && !Array.isArray(arr2)) {
            return {
                isPositive: false,
                difference: arr1,
                massiveDeselect: true
            };
        } else {
            // isPositive is only true if the second array is not empty (no elements have been added from null to [])
            // otherwise this makes all the urban elements appear on the map after resetting the filters 
            // even if no type is selected
            return {
                isPositive: Array.isArray(arr2) && arr2.length > 0,
                difference: arr2,
                massiveDeselect: false
            };
        }

    }

    filterObjectId(elememnts: any[], ids: string[]) {
        if (ids.length === 0) {
            return [];
        } else {
            return elememnts.filter(element => {
                const objectId = element.objectId;
                return ids.includes(objectId);
            });
        }
    }

    getObservableCircuiti(arrayCircuiti) {
        if (arrayIsSet(this.allCircuitsInProject)) {
            return of({
                element: this.filterObjectId(this.allCircuitsInProject, arrayCircuiti),
                finished: true,
                storedVariableInComponent: 'circuits'
            });
        } else if (arrayIsSet(arrayCircuiti)) {
            return this.mapService.getCircuiti(arrayCircuiti).pipe(
                map((circuiti) => {
                    return {
                        element: circuiti,
                        finished: true,
                        storedVariableInComponent: 'circuits'
                    };
                })
            );
        } else {
            return of({
                element: [],
                finished: true,
                storedVariableInComponent: 'circuits'
            });
        }
    }

    getElementFromParse(key: string, value: any[] | any, currentValue) {
        if (key === 'lightPoints') {
            const advancedFilter = (Array.isArray(currentValue.advancedFilter) && currentValue.advancedFilter.length > 0) ? currentValue.advancedFilter : null;
            if (Array.isArray(value) && value.length > 0) {
                return this.mapService.getLightPointPaginate(this.lightPointLimit, value, advancedFilter)
                    .pipe(
                        map((puntiLuce) => {
                            return {
                                element: puntiLuce.puntiLuce,
                                finished: puntiLuce.finished,
                                storedVariableInComponent: 'lightPoints'
                            };
                        })
                    );
                // if (this.allLightPointsInProject.length > 0) {
                //     const puntiLuceFiltrati = this.allLightPointsInProject
                //         .filter(
                //             puntoLuce => {
                //                 if (isNotNullOrUndefined(puntoLuce.circuito)) {
                //                     return value.includes(puntoLuce.circuito.objectId)
                //                 } else {
                //                     return value.includes(chiaviFiltri.nessunQuadro)
                //                 }
                //             }
                //         );
                //     return of(
                //         puntiLuceFiltrati
                //     ).pipe(
                //         switchMap(item => {
                //             return this.localDataBaseParseService.paginationObservableArray(0, item, 2000).pipe(
                //                 expand(({nextPage, items}) => nextPage ? this.localDataBaseParseService.paginationObservableArray(nextPage, item, 2000) : EMPTY),
                //                 concatMap(({items, finished}) => {
                //                     return of({
                //                         element: items as PuntiLuceParse[],
                //                         finished: finished,
                //                         storedVariableInComponent: 'lightPoints'
                //                     });
                //                 })
                //             )
                //         })
                //     )
                // } else {
                //     // return this.localDataBaseParseService.getPuntiLuceFromLocale().pipe(
                //     //     map((puntiLuce) => {
                //     //         const puntiLuceFiltrati = this.lightPoints
                //     //             .filter(
                //     //                 puntoLuce => {
                //     //                     if (isNotNullOrUndefined(puntoLuce.circuito)) {
                //     //                         return value.includes(puntoLuce.circuito.objectId)
                //     //                     } else {
                //     //                         return value.includes(chiaviFiltri.nessunQuadro)
                //     //                     }
                //     //                 }
                //     //             );
                //     //         console.log("ll", this.lightPoints, puntiLuceFiltrati);
                //     //         return {
                //     //             element: puntiLuceFiltrati,
                //     //             finished: puntiLuce.finished,
                //     //             storedVariableInComponent: 'lightPoints'
                //     //         }
                //     //     })
                //     // )
                //     // )
                //
                //
                // }
            }
        } else if (key === 'circuits') {
            const arrayCircuiti = (Array.isArray(value)) ? value : [];
            return this.getObservableCircuiti(arrayCircuiti);

        } else if (key === 'confiniAmministrativi' && Array.isArray(value) && value.length > 0) {
            return this.getConfini().pipe(
                map((confini) => {
                    return {element: confini, finished: true, storedVariableInComponent: 'confiniAmministrativi'};
                })
            );
        } else if (key === 'puntiStampa' && Array.isArray(value) && value.length > 0) {
            return this.mapService.getPuntiCamera().pipe(
                map((pictureCamperaPoint) => {
                    return {
                        element: pictureCamperaPoint,
                        finished: true,
                        storedVariableInComponent: 'puntiStampa'
                    };
                })
            );
        } else if (key === 'advancedFilter') {
            if (Array.isArray(currentValue.lightPoints) && currentValue.lightPoints.length > 0) {
                return this.mapService.getLightPointPaginate(this.lightPointLimit, currentValue.lightPoints, value)
                    .pipe(
                        map((puntiLuce) => {
                            return {
                                element: puntiLuce.puntiLuce,
                                finished: puntiLuce.finished,
                                storedVariableInComponent: 'advancedFilter'
                            };
                        })
                    );
            }
        } else if (key === 'gruppiPuntiLuce') {
            if (Array.isArray(value) && value.length > 0) {
                const allLightPoint = [];
                value.forEach(gruppoId => {
                    const gruppoIdObs = this.mapService.getPuntiLuceDelGruppo(gruppoId);
                    allLightPoint.push(gruppoIdObs);
                });
                return forkJoin(allLightPoint).pipe(
                    toArray(),
                    map(arrayArrayPuntiLuce => {
                        const arrayPuntiLuce: PuntiLuceParse[] = [];
                        arrayArrayPuntiLuce.forEach((arrayArraypls: PuntiLuceParse[][]) => {
                            arrayArraypls.forEach((pls, indx) => {
                                pls.forEach(pl => {
                                    const includeInArray = pl.includesInArray(arrayPuntiLuce);
                                    if (includeInArray.presente) {
                                        if (Array.isArray(arrayPuntiLuce[includeInArray.indice].gruppiId) && pl.isSetGruppoId()) {
                                            arrayPuntiLuce[includeInArray.indice].gruppiId.push(...pl.gruppiId);
                                        }
                                    } else {
                                        arrayPuntiLuce.push(pl);
                                    }
                                });
                            });
                        });
                        return arrayPuntiLuce;
                    }),
                    map((puntiLuce) => {
                        return {
                            element: puntiLuce,
                            finished: true,
                            storedVariableInComponent: 'gruppoIdPuntiLuce'
                        };
                    })
                );
            }
        } else if (key === 'arredoUrbano') {
            let keysContrainsValue: typeKeyConstrainValue[];
            if (arrayIsSet(value as string[])) {
                keysContrainsValue = (value as string[]).map(val => {
                    return {
                        key: 'tipologia',
                        value: val,
                        constrain: '='
                    };
                });
            } else {
                keysContrainsValue = [];
            }
            return this.arredoUrbanoUtilsService.getArrediUrbani(0, environment.lightPointLimit, keysContrainsValue)
                .pipe(
                    map((arrediUrbani) => {
                        return {
                            element: arrediUrbani.arrediUrbani,
                            finished: arrediUrbani.finished,
                            storedVariableInComponent: 'arrediUrbaniOnMap'
                        };
                    })
                );
        }
        if (key === 'electricLines') {
            return this.lineaElettricaService.getElectricLineMapByLineaElettrica(value).pipe(
                map((lineeElettriche) => {
                    return {
                        element: lineeElettriche,
                        finished: true,
                        storedVariableInComponent: 'electricLinesOnMap'
                    };
                })
            );
        }
    }

    public arrayDifferenceFilterIsEquals(advancedFilterPrevius, advancedFilterCurrent) {
        const objectsDifferenceFilterIsEqual = (o1, o2) => {
            return o1.operator === o2.operator &&
                o1.value === o2.value &&
                o1.key.key.key === o2.key.key.key;
        };
        const arraysEqual = (a1, a2) =>
            a1.length === a2.length && a1.every((o, index) => objectsDifferenceFilterIsEqual(o, a2[index]));
        return arraysEqual(advancedFilterPrevius, advancedFilterCurrent);
    }

    private updateColorElectricLinesOnMap() {
        this.electricLinesOnMap = this.updateColorAtLines(this.electricLinesOnMap, false);
    }

    private updateColorAtLines(electricLines: LineaElettricaParse[], forceUpdate = false) {
        const key = this.fromElectricLine.get('key').value;
        if (arrayIsSet(electricLines) && stringIsSet(key)) {
            let electricLinesOnMap = [...electricLines];
            const scalaColore = this.lineaElettricaService.getScalaColore(key, electricLinesOnMap, this.electricLineScaleColor);
            let update = true;
            if (arrayIsSet(scalaColore.legend) && arrayIsSet(this.electricLineScaleColor)) {
                const difference = getDifferenceArrayWithKey(scalaColore.legend, this.electricLineScaleColor, 'key', 'key');
                update = arrayIsSet(difference);
            }
            if (update || forceUpdate) {
                this.electricLineScaleColor = scalaColore.legend;
                electricLinesOnMap = this.lineaElettricaService.changeColorLinesMap(electricLinesOnMap, key, scalaColore.scaleColor);
            }
            return electricLinesOnMap;
        } else {
            return electricLines;
        }
    }

    private getDifferenceFilter(previus: FormFilterComponent, current: FormFilterComponent) {
        const differences = [];
        const keyAdvancedFilter = 'advancedFilter';
        if (isNotNullOrUndefined(previus)) {
            const keys = Object.keys(previus);
            const index = keys.indexOf(keyAdvancedFilter);
            if (index >= 0) {
                keys.splice(index, 1);
            }
            keys.forEach(key => {
                if (key === 'address') {
                    differences.push({
                        key,
                        isPositiveDifference: isNotNullOrUndefined(current.address),
                        value: current.address,
                        massiveDeselect: false
                    });
                } else if (!isNotNullOrUndefined(previus[key]) && !isNotNullOrUndefined(current[key])) {
                    differences.push({
                        key,
                        isPositive: false,
                        difference: [],
                        massiveDeselect: true
                    });
                } else if (Array.isArray(previus[key]) || Array.isArray(current[key])) {
                    const difference = this.symmetricDifferenceInArray(previus[key], current[key]);
                    differences.push({
                        key,
                        isPositiveDifference: difference.isPositive,
                        value: difference.difference,
                        massiveDeselect: difference.massiveDeselect
                    });
                } else if ((!isNotNullOrUndefined(previus[key])) || typeof previus[key] === 'string' || typeof previus[key] === 'number') {
                    differences.push((previus[key] !== current[key]) ? {
                        key,
                        isPositiveDifference: true,
                        value: current[key],
                    } : {
                        key,
                        isPositiveDifference: false,
                        value: undefined
                    });
                }
            });
            const previewFilterIsSet = Array.isArray(previus[keyAdvancedFilter]);
            const currentFilterSet = Array.isArray(current[keyAdvancedFilter]);
            const differenceFilterIsEquals = (previewFilterIsSet && currentFilterSet) ? !this.arrayDifferenceFilterIsEquals(previus[keyAdvancedFilter], current[keyAdvancedFilter]) : currentFilterSet;
            differences.push({
                key: keyAdvancedFilter,
                isPositiveDifference: differenceFilterIsEquals,
                value: current[keyAdvancedFilter]
            });
            return differences;
        } else {
            const keys = Object.keys(current);
            const index = keys.indexOf(keyAdvancedFilter);
            if (index >= 0) {
                keys.splice(index, 1);
            }
            const objCurrent = keys.map(key => {
                return {
                    key,
                    isPositiveDifference: isNotNullOrUndefined(current[key]),
                    value: current[key]
                };
            });
            objCurrent.push({
                key: keyAdvancedFilter,
                isPositiveDifference: (Array.isArray(current[keyAdvancedFilter]) && current[keyAdvancedFilter].length > 0),
                value: current[keyAdvancedFilter]
            });
            return objCurrent;
        }
    }

    appFilterValueChanges(appValueFilter: { previusValue: FormFilterComponent, currentValue: FormFilterComponent }) {
        // viene chiamata ogni qualvolta lvengono modificati i filtri
        // const activeLightPoints = appValueFilter.currentValue.activeLightPoints;
        // delete appValueFilter.currentValue.activeLightPoints;
        // console.log(activeLightPoints)
        setTimeout(() => {
            this.formPage.get('oldValueFilter').setValue(appValueFilter.currentValue);
        }, 100);
        const keyAdvancedFilter = 'advancedFilter';
        if (!isNotNullOrUndefined(appValueFilter.previusValue)) {
            this.lightPointFilter = appValueFilter.currentValue.lightPoints;
        }
        this.appValueFilters = appValueFilter;
        const collectObservable = [];
        let loaderId = this.loaderService.addLoader();
        const filterDifference = (this.projectChanged) ? this.getDifferenceFilter(undefined, appValueFilter.currentValue) : this.getDifferenceFilter(appValueFilter.previusValue, appValueFilter.currentValue);
        const indexAdvancedFilter = filterDifference.findIndex(filter => filter.key === keyAdvancedFilter);
        const advancedFilter = filterDifference[indexAdvancedFilter];
        const isPresentGruppiPuntiLuce = Array.isArray(appValueFilter.currentValue.gruppiPuntiLuce) && appValueFilter.currentValue.gruppiPuntiLuce.length > 0;
        const isPresentFiltriPuntiLuce = Array.isArray(appValueFilter.currentValue.lightPoints) && appValueFilter.currentValue.lightPoints.length > 0;
        if (advancedFilter.isPositiveDifference) {
            // con i filtri avanzati la query viene sempre effetuata
            this.clustersLightPoints = [];
            this.lightPoints_v2 = [];
        }
        try {
            filterDifference
                .forEach((difference => {
                    if (difference.isPositiveDifference || this.projectChanged) {
                        if (difference.key === 'address') {
                            this.searchAddressCoordinate = {...difference.value};
                        } else if (!(difference.key === 'lightPoints' && advancedFilter.isPositiveDifference)) {
                            const elementoParse = this.getElementFromParse(difference.key, difference.value, appValueFilter.currentValue);
                            if (isNotNullOrUndefined(elementoParse)) {
                                collectObservable.push(elementoParse);
                            }
                        }
                    } else {
                        if (difference.key === 'puntiStampa' && Array.isArray(difference.value) && difference.value.length > 0) {
                            this.puntiStampa = [];
                        }
                        if (difference.key === 'arredoUrbano' && Array.isArray(difference.value) && difference.value.length > 0) {
                            this.removeArrediUrbaniForKey(difference.value, 'tipologia');
                            // this.arrediUrbaniOnMap = [];
                        } else if (difference.key === 'arredoUrbano' && difference.massiveDeselect) {
                            this.arrediUrbaniOnMap = [];
                        }
                        if (difference.key === 'confiniAmministrativi' && Array.isArray(difference.value) && difference.value.length > 0) {
                            this.confiniAmministrativi = [];
                        }
                        if (difference.key === 'circuits' && Array.isArray(this.circuits)) {
                            this.removeCircuiti(difference.value);
                        }
                        if (difference.key === 'lightPoints' && Array.isArray(this.clustersLightPoints)) {
                            const loaderId = this.loaderService.addLoader();
                            setTimeout(() => {
                                if (difference.massiveDeselect && !isPresentGruppiPuntiLuce) {
                                    this.clustersLightPoints = [];
                                    this.lightPoints_v2 = [];
                                } else if (Array.isArray(difference.value)) {
                                    this.clustersLightPoints = this.clustersLightPoints.filter(
                                        cluster => {
                                            cluster.element = cluster.element.filter(puntoLuce => {
                                                if (puntoLuce.isSetGruppoId()) {
                                                    return true;
                                                } else if (isNotNullOrUndefined(puntoLuce.circuito)) {
                                                    return !difference.value.includes(puntoLuce.circuito.objectId);
                                                } else {
                                                    return !difference.value.includes(this.chiaviFitri.nessunQuadro);
                                                }
                                            });
                                            return cluster.element.length > 0;
                                        }
                                    );
                                }
                                this.lightPoints_v2 = this.lightPoints_v2.filter(puntoLuce => {
                                    if (puntoLuce.isSetGruppoId()) {
                                        return true;
                                    } else if (isNotNullOrUndefined(puntoLuce.circuito)) {
                                        return !difference.value.includes(puntoLuce.circuito.objectId);
                                    } else {
                                        return !difference.value.includes(this.chiaviFitri.nessunQuadro);
                                    }
                                })
                                this.loaderService.removeLoader(loaderId);
                                this.puntiLuceLabelService.createValueHtml(this.lightPoints);
                                this.forceRenderingMap();
                            }, 200);
                        }
                        if (difference.key === 'gruppiPuntiLuce' && Array.isArray(this.clustersLightPoints)) {
                            const loaderId = this.loaderService.addLoader();
                            setTimeout(() => {
                                if (difference.massiveDeselect && !isPresentFiltriPuntiLuce) {
                                    this.clustersLightPoints = [];
                                    this.lightPoints_v2 = [];
                                } else if (Array.isArray(difference.value)) {
                                    const value = difference.value;
                                    this.clustersLightPoints = this.clustersLightPoints.filter(
                                        cluster => {
                                            cluster.element = cluster.element.filter(puntoLuce => {
                                                if (Array.isArray(puntoLuce.gruppiId) && puntoLuce.gruppiId.length > 0) {
                                                    const difference = puntoLuce.gruppiId.filter(x => !value.includes(x));
                                                    puntoLuce.gruppiId = difference;
                                                    return puntoLuce.gruppiId.length > 0;
                                                } else {
                                                    return true;
                                                }
                                            });
                                            return cluster.element.length > 0;
                                        }
                                    );
                                    this.lightPoints_v2 = this.lightPoints_v2.filter(puntoLuce => {
                                        if (arrayIsSet(puntoLuce.gruppiId)) {
                                            const difference = puntoLuce.gruppiId.filter(x => !value.includes(x));
                                            puntoLuce.gruppiId = difference;
                                            return puntoLuce.gruppiId.length > 0;
                                        } else {
                                            return true;
                                        }
                                    })
                                }
                                this.loaderService.removeLoader(loaderId);
                                this.puntiLuceLabelService.createValueHtml(this.lightPoints);
                                this.forceRenderingMap();
                            }, 200);
                        }
                        if (difference.key === 'electricLines') {
                            if (arrayIsSet(this.electricLinesOnMap) && arrayIsSet(difference.value)) {
                                const idToRemove: string[] = difference.value.map(linea => linea.objectId);
                                this.electricLinesOnMap = this.electricLinesOnMap.filter(linea => !idToRemove.includes(linea.objectId));
                            }
                        }
                        this.forceRenderingMap();
                    }
                }));
            this.projectChanged = false;
        } catch (e) {
            console.error(e);
        } finally {
            this.loaderService.removeLoader(loaderId);
        }


        if (collectObservable.length > 0) {
            loaderId = this.loaderService.addLoader();
            let circuitFinished = false;
            let lightPointFinished = false;
            let confiniAmministrativiFinished = false;
            let puntiStampaFinished = false;
            let arrediUrbaniFinished = false;
            let electricLineFinished = false;
            combineLatest(collectObservable)
                .subscribe(
                    (aggregateData: { element, finished, storedVariableInComponent }[]) => {
                        // con combine latest le chaiamate vengo inviate quando sono pront
                        // allCompleted serve per verificare quando tutte li osservabile arrivano a termine
                        // console.log(aggregateData)
                        let allCompleted = true;
                        aggregateData.forEach(
                            data => {
                                allCompleted = allCompleted && data.finished;
                                if ((data.storedVariableInComponent === 'lightPoints' || data.storedVariableInComponent === 'advancedFilter') && !lightPointFinished) {
                                    const color = (isNotNullOrUndefined(this.map) && this.map.mapTypeId == 'roadmap') ? 'black' : 'white';
                                    data.element.forEach(pl => pl.labelMarker = this.puntiLuceLabelService.getLabelMarker(pl, color));
                                    if (this.scaleColorPuntiLuce.campoScalaColore === 'lampadaFunzionante' && (data.element.length > 0)) {
                                        // this.segnaliTlcNodoService.getLampadaFunzionante(data.element).subscribe(
                                        //     (segnali: SegnaliTlcNodoParse[]) => {
                                        //         segnali.forEach(segnale => {
                                        //             const index = data.element.findIndex(puntoLuce => puntoLuce.objectId == segnale.puntoLuce.objectId);
                                        //             if (index > -1) {
                                        //                 data.element[index].lampadaFunzionante = segnale.lampadaFunzionante;
                                        //             }
                                        //         });
                                        //         this.addPuntiLuceInCluster(data.element);
                                        //         if (segnali.length > 0) {
                                        //             this.forceRenderingMap();
                                        //         }
                                        //     }, error => {
                                        //         this.addPuntiLuceInCluster(data.element);
                                        //     }, () => {
                                        //         this.forceRenderingMap();
                                        //     }
                                        // );
                                        this.segnaliTlcNodoService.getLampadaFunzionante_v2(data.element).subscribe(
                                            (segnali) => {
                                                let forceUpdate = false;
                                                if (segnali != null) {
                                                    data.element.forEach(element => {
                                                        if (segnali[element.objectId] != null && segnali[element.objectId].segnale != null) {
                                                            const segnale = segnali[element.objectId].segnale;
                                                            element.setSegnale(segnale)
                                                            element.icon = this.scaleColorPuntiLuce.getIconPuntoLuce(element);
                                                            forceUpdate = true;

                                                        }
                                                    })
                                                }
                                                // console.log('segnali', segnali)
                                                //todo sistemare il cluster
                                                this.addPuntiLuceInCluster(data.element);
                                                if (forceUpdate) {
                                                    this.forceRenderingMap();
                                                }
                                                const newValueIsPresent = this.scaleColorPuntiLuce.lightPointsContainedNewValue(data.element);
                                                if (newValueIsPresent) {
                                                    this.scaleColorPuntiLuce.puntiLuceInMappa = this.lightPoints;
                                                    this.scaleColorPuntiLuce.aggiornaScalaColore();
                                                    this.cambiaColoreDelleIcone(null);
                                                }
                                                this.puntiLuceLabelService.createValueHtml(this.lightPoints);
                                            }, error => {
                                                this.addPuntiLuceInCluster(data.element);
                                            }, () => {
                                                this.forceRenderingMap();
                                            }
                                        );


                                    } else {
                                        this.addPuntiLuceInCluster(data.element);
                                        const newValueIsPresent = this.scaleColorPuntiLuce.lightPointsContainedNewValue(data.element);
                                        if (newValueIsPresent) {
                                            this.scaleColorPuntiLuce.puntiLuceInMappa = this.lightPoints;
                                            this.scaleColorPuntiLuce.aggiornaScalaColore();
                                            this.cambiaColoreDelleIcone(null);
                                        }
                                        this.puntiLuceLabelService.createValueHtml(this.lightPoints);
                                        lightPointFinished = data.finished;
                                    }
                                    lightPointFinished = data.finished;
                                } else if (data.storedVariableInComponent === 'circuits' && !circuitFinished) {
                                    const color = (isNotNullOrUndefined(this.map) && this.map.mapTypeId == 'roadmap') ? 'black' : 'white';
                                    this.addCircuitiInCluster(data.element.map(circuito => {
                                        circuito.labelMarker = this.circutiLabelService.getLabelMarker(circuito, color);
                                        circuito.icon = this.scaleColorCircuiti.getIconCircuito(circuito);
                                        return circuito;
                                    }));
                                    if (this.scaleColorCircuiti.circuitiContainedNewValue(data.element)) {
                                        this.scaleColorCircuiti.circuitiInMappa = this.circuits;
                                        this.scaleColorCircuiti.aggiornaScalaColore();
                                        this.cambioScalaColoreCircuito(null);
                                    }
                                    circuitFinished = data.finished;
                                } else if (data.storedVariableInComponent === 'confiniAmministrativi' && !confiniAmministrativiFinished) {
                                    this[data.storedVariableInComponent] = data.element;
                                    confiniAmministrativiFinished = data.finished;
                                    this.forceUpdateCenterMap = !this.forceUpdateCenterMap;
                                } else if (data.storedVariableInComponent === 'puntiStampa' && !puntiStampaFinished) {
                                    this.puntiStampa = data.element;
                                    puntiStampaFinished = data.finished;
                                } else if (data.storedVariableInComponent === 'gruppoIdPuntiLuce') {
                                    this.addPuntiLuceInCluster(data.element);
                                    const newValueIsPresent = this.scaleColorPuntiLuce.lightPointsContainedNewValue(data.element);
                                    if (newValueIsPresent) {
                                        this.scaleColorPuntiLuce.puntiLuceInMappa = this.lightPoints;
                                        this.scaleColorPuntiLuce.aggiornaScalaColore();
                                        this.cambiaColoreDelleIcone(null);
                                    }
                                    this.puntiLuceLabelService.createValueHtml(this.lightPoints);
                                    lightPointFinished = data.finished;
                                } else if (data.storedVariableInComponent === 'arrediUrbaniOnMap' && !arrediUrbaniFinished) {
                                    this.arrediUrbaniOnMap = this.arrediUrbaniOnMap.concat(data.element);
                                    arrediUrbaniFinished = data.finished;
                                } else if (data.storedVariableInComponent === 'electricLinesOnMap' && !electricLineFinished) {
                                    if (arrayIsSet(data.element)) {
                                        if (arrayIsSet(this.electricLinesOnMap)) {
                                            this.electricLinesOnMap = this.electricLinesOnMap.concat(data.element);
                                        } else {
                                            this.electricLinesOnMap = data.element;
                                        }
                                        this.updateColorElectricLinesOnMap();
                                    }
                                    electricLineFinished = data.finished;
                                } else {
                                    this[data.storedVariableInComponent] = data.element;
                                }
                            }
                        );
                        setTimeout(() => {
                            this.forceRenderingMap();
                        }, 20);
                        if (allCompleted) {
                            this.scaleColorPuntiLuce.puntiLuceInMappa = this.lightPoints;
                            if (this.mapOpenedFirstTimeInProject) {
                                setTimeout(() => {
                                    this.locationsToCenterZoom = this.getLocationZoom();
                                })
                                this.mapOpenedFirstTimeInProject = !arrayIsSet(this.locationsToCenterZoom);
                            }
                            // this.updateColorArrayLightPoint(this.scalaColore, this.lightPoints);
                            // this.clustersLightPoints = this.createClusterLightPoint(this.lightPoints);
                            this.loaderService.removeLoader(loaderId);
                            // this.lightPoints = [];
                        }
                    }, error => {
                        this.loaderService.removeLoader(loaderId);
                    }, () => {
                        this.loaderService.removeLoader(loaderId);
                    }
                );
        }
    }

    getLocationZoom(): GeoPoint[] {
        let locations = [];
        if (arrayIsSet(this.confiniAmministrativi)) {
            this.confiniAmministrativi.forEach(confine => {
                confine.forEach((coordinate) => {
                    const loc = this.geoPointService.newGeopoint(coordinate);
                    locations.push(loc);
                });
            });
        } else if (arrayIsSet(this.circuits)) {
            locations = this.circuits.map(circuit => circuit.location);
        } else {
            if (arrayIsSet(this.lightPoints)) {
                locations = locations.concat(this.lightPoints.map(lightPoint => lightPoint.location));
            }
            if (arrayIsSet(this.arrediUrbaniOnMap)) {
                locations = locations.concat(this.arrediUrbaniOnMap.map(arredo => arredo.location));
            }
            if (arrayIsSet(this.electricLinesOnMap)) {
                this.electricLinesOnMap.forEach(line => {
                    line.linesMap.forEach(point => locations.push(point.start));
                });
            }
        }
        return locations;
    }

    private addPuntiLuceInCluster(newLightPoint: PuntiLuceParse[]) {
        const allLightPoint = this.lightPoints;
        if (allLightPoint.length > 0) {
            const differenceLightPoint = newLightPoint.filter(
                puntoLuce => {
                    const puntoLuceIncludes = puntoLuce.includesInArray(allLightPoint);
                    if (puntoLuceIncludes.presente && puntoLuce.isSetGruppoId()) {
                        allLightPoint[puntoLuceIncludes.indice].gruppiId = puntoLuce.gruppiId;
                    }
                    return !puntoLuceIncludes.presente;
                }
            );
            this.updateColorArrayLightPoint(differenceLightPoint);
            this.updateClusterLightPoint(this.clustersLightPoints, differenceLightPoint);
            this.addLightPoints_v2(differenceLightPoint);
        } else {
            this.updateColorArrayLightPoint(newLightPoint);
            this.clustersLightPoints = this.createClusterLightPoint(newLightPoint);
            this.lightPoints_v2 = newLightPoint
        }
    }

    private substitutionPuntiLuceInCluster(puntiLuce: PuntiLuceParse[]) {
        puntiLuce.forEach(puntoLuce => {
            this.clustersLightPoints.every(
                cluster => {
                    const index = cluster.element.findIndex(puntoLuceNonAggiornato => puntoLuceNonAggiornato.objectId === puntoLuce.objectId);
                    if (index >= 0) {
                        cluster.element[index] = puntoLuce;
                        return false;
                    } else {
                        return true;
                    }
                }
            );
        });
    }

    private addCircuitiInCluster(newCircuiti: CircuitiParse[]) {
        if (arrayIsSet(this.circuits_v2)) {
            const differenceCircuits = newCircuiti.filter(
                circuito => {
                    return circuito.includesInArray(this.circuits_v2) < 0;
                }
            );
            this.updateClusterCircuiti(this.clustersCircuits, differenceCircuits);
            this.addCircuits_v2(differenceCircuits);
        } else {
            this.clustersCircuits = this.createclustersCircuits(newCircuiti);
            this.circuits_v2 = newCircuiti;
        }
        this.changeDetection.detectChanges();
    }

    private removeCircuitiInCluster(removeCircuiti: CircuitiParse[]) {
        if (this.circuits.length > 0) {
            const differenceCircuits = getDifferenceArrayWithKey(this.circuits, removeCircuiti, 'objectId', 'objectId');
            this.clustersCircuits = this.createclustersCircuits(differenceCircuits);
            this.circuits_v2 = differenceCircuits;
        }
    }

    private updateClusterCircuiti(clusterCircuit: any[], newCircuit: CircuitiParse[]) {
        newCircuit.forEach(circuito => {
            const index = clusterCircuit.findIndex(cluster => cluster.color === circuito.icon.strokeColor);
            if (index > -1) {
                clusterCircuit[index].element.push(circuito);
            } else {
                clusterCircuit.push({color: circuito.icon.strokeColor, element: [circuito]});
            }
        });
    }


    private setCircuiti(circuiti: CircuitiParse[]) {
        this.allCircuitsInProject = circuiti;
        this.circuitiAppartenentiAlProgetto = circuiti;
        const circuits = this.circuitiAppartenentiAlProgetto.map(el => el.objectId);
        this.formPage.get('oldValueFilter').setValue({circuits});
    }

    private setStrade(strade: StradeParse[]) {
        this.stradeAppartenentiAlProgetto = strade
    }

    private setGruppiPuntiLuce(gruppiPuntiLuce: GruppiPuntiLuceParse[]) {
        this.gruppiAppartenentiAlProgetto = gruppiPuntiLuce;
    }


    private setArredoUrbanoFilter(distinctTipologie: string[], key) {
        const objArredo = this.arredoUrbanoAppartenentiAlProgettoExport != null ? {...this.arredoUrbanoAppartenentiAlProgettoExport} : {};
        if (!Array.isArray(distinctTipologie)) {
            objArredo[key] = [];
        } else {
            objArredo[key] = distinctTipologie.map((value, index) => {
                return this.arredoUrbanoUtilsService.mapArredoUrbanoValue(value, key, index);
            });
        }
        this.arredoUrbanoAppartenentiAlProgettoExport = objArredo;
    }


    private aggiornaVettoreDetailFilter(arrayElementi, nomeTabellaDaPossibleValues) {
        const vettoreDaAggiornare = [];
        let tabellaDaRecuperare = nomeTabellaDaPossibleValues;
        tabellaDaRecuperare = tabellaDaRecuperare.split('.')[tabellaDaRecuperare.split('.').length - 1];
        arrayElementi.forEach((element) => {
            vettoreDaAggiornare.push({
                formValues: element.id || element.objectId,
                formHtml: element[tabellaDaRecuperare]
            });
        });
        return vettoreDaAggiornare;
    }

    clickElement(event, sidenav: MatSidenav) {
        const element = event.element;
        const zoom = event.zoom;
        if (this.openedDrawingElectricLine) {
            const location = element.location != null ? element.location : element.start;
            if (location != null) {
                this.addElectricLine({lat: location.latitude, lng: location.longitude}, zoom);
            }
        } else {
            this.openedSidenavInfoElement(sidenav);
            this.elementoCliccato = element;
            this.sidenavEndWhoOpen = 'formLightPoint';
            if (this.nomeClassi.puntiLuce === element.className && this.openedDrawingManager) {
                if (this.reportsService.isReport(element)) {
                    this.reportsService.removeItem(element);
                } else {
                    this.reportsService.addItem(element);
                }
            }
        }
    }

    public closeRepositioning() {
        setTimeout(() => {
            this.repositioningElectricLines = false;
        })
        this.electricLines.forEach(line => line.unsetLinesMap());
        this.destroyAddElectricLine();
        this.destroyDynamicView();
        this.destroyRepositioningValue();
        this.electricLineScaleColor = null;
    }

    public destroyAddElectricLine() {
        this.openedDrawingElectricLine = false;
        this.formPage.get('addElectricLine').reset();
        this.drawedElectricLines = [];
    }

    public initAddElectricLine(values: any) {
        this.openedDrawingElectricLine = true;
        if (values != null) {
            Object.keys(this.formPage.get('addElectricLine').value).forEach(key => {
                if (values[key] != null) {
                    this.formPage.get('addElectricLine').get(key).setValue(values[key]);
                }
            });
        }

    }

    private substitutionElectricLineElements(array: LineaElettricaParse[], element: LineaElettricaParse): LineaElettricaParse[] {
        const values = substitutionElementInArray(array, element);
        return this.updateColorAtLines(values, true);
    }

    public getPossibleActions(possibleActions: ActionClickMarker[], message): Observable<ActionClickMarker> {
        const actions = possibleActions.map((action, index) => {
            let traduction;
            let icon;
            let icons;
            if (action === this.actionLine.extendLine) {
                icon = {
                    svgIcon: this.iconService.addSvgIcon('extend' + index, '/assets/icon/geometry/extend.svg'),
                    color: 'primary'
                };
                traduction = 'actionsOnDotLine.extend';
            } else if (action === this.actionLine.separePoint) {
                icon = {
                    svgIcon: this.iconService.addSvgIcon('separe' + index, '/assets/icon/geometry/separe.svg'),
                    color: 'accent'
                };
                traduction = 'actionsOnDotLine.separe';
            } else if (action === this.actionLine.removePoint) {
                icon = {material: 'delete', color: 'warn'};
                traduction = 'actionsOnDotLine.delete';
            } else if (action === this.actionLine.movePoint) {
                icon = {material: 'zoom_out_map', color: 'primary'};
                traduction = 'actionsOnDotLine.move';
            } else if (action === this.actionLine.keepChange) {
                icon = {material: 'edit', color: 'primary'};
                traduction = 'actionsOnDotLine.keepChange';
            } else if (action === this.actionLine.updatePositionAll) {
                icon = {
                    svgIcon: this.iconService.addSvgIcon('addPoint' + index, '/assets/icon/geometry/moveBlockPoint.svg'),
                    color: 'accent'
                };
                traduction = 'actionsOnDotLine.updatePositionAll';
            } else if (action === this.actionLine.updatePositionCurrent) {
                icon = {
                    svgIcon: this.iconService.addSvgIcon('addPoint' + index, '/assets/icon/geometry/moveSinglePoint.svg'),
                    color: 'primary'
                };
                traduction = 'actionsOnDotLine.updatePositionCurrent';
            } else if (action === this.actionLine.addPoint) {
                icon = {
                    svgIcon: this.iconService.addSvgIcon('addPoint' + index, '/assets/icon/geometry/addPoint.svg'),
                    color: 'primary'
                };
                traduction = 'actionsOnDotLine.addPoint';
            } else if (action === this.actionLine.addPointAndKeepChange) {
                icon = {
                    svgIcon: this.iconService.addSvgIcon('addPoint' + index, '/assets/icon/geometry/addPoint.svg'),
                    color: 'accent'
                };
                traduction = 'actionsOnDotLine.addPointAndKeepChange';
            }
            return {
                traduction,
                icon,
                value: action
            };
        }).sort((a, b) => {
            if (a.icon && b.icon && stringIsSet(a.icon.color) && stringIsSet(b.icon.color)) {
                return a.icon.color.localeCompare(b.icon.color);
            } else if (stringIsSet(a.traduction) && stringIsSet(b.traduction)) {
                return this.translateService.instant(a.traduction).localeCompare(this.translateService.instant(b.traduction));
            } else {
                return 0;
            }
        });
        return this.dialogPopUpService.openDialogPossibleActions(message, actions).pipe(
            map(action => {
                if (action != null && action.value != null) {
                    return action.value as ActionClickMarker;
                } else {
                    return action;
                }
            })
        );
    }

    dragChangePosition(event: { point: ElectricLinesMap, line: LineaElettricaParse, location: Parse.GeoPoint }) {
        const point = event.point;
        const line = event.line;
        const location = event.location;
        this.lineaElettricaService.fetchPoint(point.objectId)
            .pipe(
                switchMap(pointFetched => {
                    if (arrayIsSet(pointFetched.lineeElettriche) && pointFetched.lineeElettriche.length > 1) {
                        return this.getPossibleActions([this.actionLine.updatePositionAll, this.actionLine.updatePositionCurrent], 'actionsOnDotLine.updatePositionMessage').pipe(
                            switchMap((action) => {
                                if (action == null) {
                                    const refectroing = [...this.electricLinesOnMap];
                                    this.electricLinesOnMap = [];
                                    setTimeout(() => {
                                        this.electricLinesOnMap = refectroing;
                                    });
                                    return EMPTY;
                                } else if (action === this.actionLine.updatePositionAll) {
                                    return this.lineaElettricaService.updateLocationPoint(line, pointFetched, location);
                                } else if (action === this.actionLine.updatePositionCurrent) {
                                    return this.lineaElettricaService.separatesPointFromLine(pointFetched, line).pipe(
                                        map(newPoint => {
                                            return newPoint.locationElement;
                                        }),
                                        switchMap((newPoint) => this.lineaElettricaService.updateLocationPoint(line, newPoint, location))
                                    );
                                }
                                return of(pointFetched);
                            })
                        );
                    } else {
                        return this.lineaElettricaService.updateLocationPoint(line, pointFetched, location);
                    }
                }),
                map(pointFetched => {
                    const updatedLine = [];
                    if (arrayIsSet(pointFetched.lineeElettriche)) {
                        pointFetched.lineeElettriche.forEach(lineaElettrica => {
                            const lineOnmap = getItemInArrayByKeyValue(this.electricLinesOnMap, lineaElettrica.objectId, 'objectId');
                            if (lineOnmap != null && arrayIsSet(lineOnmap.linesMap)) {
                                const index = lineOnmap.linesMap.findIndex(p => p.objectId === pointFetched.objectId);
                                if (index >= 0) {
                                    lineOnmap.linesMap[index].start = {
                                        latitude: pointFetched.location.latitude,
                                        longitude: pointFetched.location.longitude
                                    };
                                    updatedLine.push(lineOnmap);
                                }
                            }
                        });
                    }
                    return {updatedLine};
                })
            )
            .subscribe(values => {
                    let electricLinesOnMap = [...this.electricLinesOnMap];
                    this.electricLinesOnMap = [];
                    setTimeout(() => {
                        if (arrayIsSet(values.updatedLine)) {
                            values.updatedLine.forEach(electricLine => {
                                electricLinesOnMap = this.substitutionElectricLineElements(electricLinesOnMap, electricLine);
                            });
                            this.electricLinesOnMap = electricLinesOnMap;
                        }
                        const message = this.translateService.instant('save_success');
                        this.alertService.success(message);
                    });
                },
                error => {
                    this.alertService.error(error);
                });
    }

    longPressMarker(event) {
        if (event.action === this.actionLine.addPoint) {
            const line = event.element;
            const message = this.translateService.instant('dashboard.addPointToLineRequest');
            let possibleActions;
            if (this.repositioningElectricLines) {
                possibleActions = [this.actionLine.addPoint];
            } else {
                possibleActions = [this.actionLine.keepChange, this.actionLine.addPointAndKeepChange];
            }

            this.getPossibleActions(possibleActions, message)
                .pipe(
                    switchMap(action => {
                        if (action == null) {
                            return EMPTY;
                        } else if (action === this.actionLine.addPoint || action === this.actionLine.addPointAndKeepChange) {
                            return this.lineaElettricaService.getLocationsElementsByLineaElettrica(line).pipe(
                                switchMap((points) => {
                                    const newPoint = event.newPoint;
                                    const start = event.start;
                                    return this.lineaElettricaService.updateSortingNumberLocations(line, points, start, newPoint);
                                }),
                                map(points => {
                                    return {points, action};
                                })
                            );
                        } else if (action === this.actionLine.keepChange) {
                            return of({action, points: null});
                        }
                    })
                )
                .subscribe(values => {
                    if (values.action === this.actionLine.addPoint) {
                        if (arrayIsSet(values.points)) {
                            line.linesMap = this.lineaElettricaService.getLineMapByPoints(values.points);
                            this.electricLinesOnMap = this.substitutionElectricLineElements(this.electricLinesOnMap, line);
                            this.alertService.success(this.translateService.instant('save_success'));
                        }
                    } else if (values.action === this.actionLine.keepChange) {
                        // if (event.element && arrayIsSet(event.element.linesMap)) {
                        //     this.locationsToCenterZoom = event.element.linesMap.map(point => point.start);
                        // }
                        this.initDynamicView();
                        this.repositioningElectricLines = true;
                    } else if (values.action === this.actionLine.addPointAndKeepChange) {
                        if (arrayIsSet(values.points)) {
                            line.linesMap = this.lineaElettricaService.getLineMapByPoints(values.points);
                            this.electricLinesOnMap = this.substitutionElectricLineElements(this.electricLinesOnMap, line);
                            this.alertService.success(this.translateService.instant('save_success'));
                        }
                        // if (event.element && arrayIsSet(event.element.linesMap)) {
                        //     this.locationsToCenterZoom = event.element.linesMap.map(point => point.start);
                        // }
                        this.initDynamicView();
                        this.repositioningElectricLines = true;
                    }
                }, error => {
                    this.alertService.error(error);
                });
        } else {
            const point = event.point;
            const line = event.line;
            this.lineaElettricaService.fetchPoint(point.objectId).pipe(
                switchMap((pointFeched) => this.lineaElettricaService.fetchLine(line.objectId).pipe(
                        map(lineFetched => {
                            const possibleActions = [];
                            if (arrayIsSet(lineFetched.linesMap) && lineFetched.linesMap.length > 2) {
                                possibleActions.push(this.actionLine.removePoint);
                            }
                            if (arrayIsSet(lineFetched.linesMap)) {
                                const isFirst = lineFetched.linesMap[0].objectId === pointFeched.objectId;
                                const isLast = lineFetched.linesMap[lineFetched.linesMap.length - 1].objectId === pointFeched.objectId;
                                if (isFirst || isLast) {
                                    possibleActions.push(this.actionLine.extendLine);
                                }
                                if (arrayIsSet(pointFeched.lineeElettriche) && pointFeched.lineeElettriche.length > 1) {
                                    possibleActions.push(this.actionLine.separePoint);
                                }
                            }
                            const actions = possibleActions.map((action, index) => {
                                let traduction;
                                let icon;
                                if (action === this.actionLine.extendLine) {
                                    icon = {
                                        svgIcon: this.iconService.addSvgIcon('extend' + index, '/assets/icon/geometry/extend.svg'),
                                        color: 'primary'
                                    };
                                    traduction = 'actionsOnDotLine.extend';
                                } else if (action === this.actionLine.separePoint) {
                                    icon = {
                                        svgIcon: this.iconService.addSvgIcon('separe' + index, '/assets/icon/geometry/separe.svg'),
                                        color: 'accent'
                                    };
                                    traduction = 'actionsOnDotLine.separe';
                                } else if (action === this.actionLine.removePoint) {
                                    icon = {material: 'delete', color: 'warn'};
                                    traduction = 'actionsOnDotLine.delete';
                                } else if (action === this.actionLine.movePoint) {
                                    icon = {material: 'zoom_out_map', color: 'primary'};
                                    traduction = 'actionsOnDotLine.move';
                                }
                                return {
                                    value: action,
                                    traduction,
                                    icon,
                                };
                            }).sort((a, b) => {
                                if (stringIsSet(a.icon.color) && stringIsSet(b.icon.color)) {
                                    return a.icon.color.localeCompare(b.icon.color);
                                } else {
                                    return -1;
                                }
                            });
                            return {possibleActions: actions, lineFetched, locationElment: pointFeched};
                        }),
                        switchMap(({
                                       possibleActions,
                                       lineFetched,
                                       locationElment
                                   }) => this.dialogPopUpService.openDialogPossibleActions('actionsOnDotLine.choose', possibleActions)
                            .pipe(
                                map(actionValue => {
                                    const action = actionValue != null && actionValue.value != null ? actionValue.value : actionValue;
                                    return {action, lineFetched, locationElment};
                                })
                            )
                        )
                    ),
                ),
                switchMap((values) => {
                    if (values.action == null) {
                        return EMPTY;
                    } else if (values.action === this.actionLine.removePoint) {
                        return this.lineaElettricaService.destroyLocationElementsForLine(values.locationElment, values.lineFetched);
                    } else if (values.action === this.actionLine.separePoint) {
                        return this.lineaElettricaService.separatesPointFromLine(values.locationElment, values.lineFetched);
                    } else if (values.action === this.actionLine.extendLine) {
                        this.initAddElectricLine(values.lineFetched);
                        if (values.lineFetched != null) {
                            const coord = {
                                id: values.locationElment.objectId,
                                lat: values.locationElment.location.latitude,
                                lng: values.locationElment.location.longitude
                            };
                            this.drawedElectricLines = this.lineaElettricaService.getLineMap([], coord, line.linesMap[0].color);
                        }
                        return this.initRepositioningValue().pipe(
                            switchMap((locationsAction) => {
                                if (locationsAction.close) {
                                    return EMPTY;
                                } else {
                                    return this.lineaElettricaService.extendLocationElementsToElectricLine(values.lineFetched, locationsAction.locations);
                                }
                            }),
                            map(newLine => {
                                return {
                                    line: newLine,
                                    locationElement: values.locationElment,
                                    action: values.action
                                };
                            })
                        );
                    } else {
                        return of({
                            line: values.lineFetched,
                            locationElement: values.locationElment,
                            action: values.action
                        });
                    }
                })
            ).subscribe(response => {
                if (response != null) {
                    // @ts-ignore
                    if (response.line != null) {
                        this.electricLinesOnMap = this.substitutionElectricLineElements(this.electricLinesOnMap, response.line);
                        this.destroyRepositioningValue();
                        const message = this.translateService.instant('save_success');
                        this.alertService.success(message);
                        // console.log(response.line);
                    }
                }

            }, error => {
                this.alertService.error(error);
                this.destroyRepositioningValue();
            });
            // console.log(point);
            // console.log(line);
            // const actions = event.possibleActions.map((action, index) => {
            //     let traduction;
            //     let icon;
            //     if (action === this.actionLine.extendLine) {
            //         icon = {
            //             svgIcon: this.iconService.addSvgIcon('extend' + index, '/assets/icon/geometry/extend.svg'),
            //             color: 'primary'
            //         };
            //         traduction = 'actionsOnDotLine.extend';
            //     } else if (action === this.actionLine.separePoint) {
            //         icon = {
            //             svgIcon: this.iconService.addSvgIcon('separe' + index, '/assets/icon/geometry/separe.svg'),
            //             color: 'accent'
            //         };
            //         traduction = 'actionsOnDotLine.separe';
            //     } else if (action === this.actionLine.removePoint) {
            //         icon = {material: 'delete', color: 'warn'};
            //         traduction = 'actionsOnDotLine.delete';
            //     } else if (action === this.actionLine.movePoint) {
            //         icon = {material: 'zoom_out_map', color: 'primary'};
            //         traduction = 'actionsOnDotLine.move';
            //     }
            //     return {
            //         traduction,
            //         icon,
            //     };
            // }).sort((a, b) => {
            //     if (stringIsSet(a.icon.color) && stringIsSet(b.icon.color)) {
            //         return a.icon.color.localeCompare(b.icon.color);
            //     } else {
            //         return -1;
            //     }
            // });
            // this.dialogPopUpService.openDialogPossibleActions('opened', actions).subscribe(choose => {
            //     console.log(choose);
            // });
        }
    }

    openedSidenavInfoElement(sidenav: MatSidenav) {
        if (!this.openedDrawingManager) {
            sidenav.open();
        }
    }

    // viene aggiornata se valore non presente la scala colore;
    private eliminaOAggiornaPuntiLuce(event: {
        id: string,
        classe: string,
        action: 'updated' | 'removed',
        values: any,
        filtroCircuito: string
    }) {
        if (event.action === 'removed') {
            this.clustersLightPoints.every(
                cluster => {
                    const index = cluster.element.findIndex(puntoLuce => puntoLuce.objectId === event.id);
                    if (index >= 0) {
                        cluster.element.splice(index, 1);
                        this.forceRenderingMap();
                        return false;
                    } else {
                        return true;
                    }
                }
            );
        } else {
            let puntoLuce;
            if (isNotNullOrUndefined(event.values) && isNotNullOrUndefined(event.values.puntoLuce)) {
                puntoLuce = event.values.puntoLuce;
            } else {
                puntoLuce = new PuntiLuceParse();
                puntoLuce.objectId = event.id;
            }
            if (this.puntiLuceLabelService.campoLabel != null) {
                const color = (isNotNullOrUndefined(this.map) && this.map.mapTypeId == 'roadmap') ? 'black' : 'white';
                puntoLuce.labelMarker = this.puntiLuceLabelService.getLabelMarker(puntoLuce, color);
                this.puntiLuceLabelService.calcNumeroElementiPerValore(this.puntiLuceLabelService.campoLabel, this.lightPoints);
            }
            puntoLuce.icon = this.scaleColorPuntiLuce.getIconPuntoLuce(puntoLuce);
            this.substitutionPuntiLuceInCluster([puntoLuce]);
            const field = this.scaleColorPuntiLuce.campoScalaColore;
            const scala = this.scaleColorPuntiLuce.scalaColoreCompleta;
            const keyPuntoLuce = this.scaleColorPuntiLuce.getKey(puntoLuce, field);
            const indexColor = Object.keys(scala).findIndex(key => key === keyPuntoLuce);
            if (indexColor < 0) {
                this.scaleColorPuntiLuce.aggiornaScalaColore();
                this.cambiaColoreDelleIcone(null);
            }
            this.forceRenderingMap();
        }
    }

    private eliminaOAggiornaPuntiStampa(event: {
        id: string,
        classe: string,
        action: 'updated' | 'removed',
        filtroCircuito: string
    }) {
        if (event.action === 'removed') {
            const index = this.puntiStampa.findIndex(puntoStampa => puntoStampa.objectId === event.id);
            if (index >= 0) {
                this.puntiStampa.splice(index, 1);
                this.forceRenderingMap();
            }
        } else {
            this.forceRenderingMap();
        }
    }

    private removeCircuiti(objectIds: string[]) {
        if (isNotNullOrUndefined(this.clustersCircuits)) {
            this.clustersCircuits = this.clustersCircuits.filter(
                cluster => {
                    const circuitiFiltrati = cluster.element.filter(circuito => !objectIds.includes(circuito.objectId));
                    if (circuitiFiltrati.length > 0) {
                        cluster.element = circuitiFiltrati;
                    }
                    return circuitiFiltrati.length > 0;
                }
            );
            this.circuits_v2 = this.circuits_v2.filter(circuito => {
                return !objectIds.includes(circuito.objectId);
            })
        }
    }


    private removeLightPoints(objectIds: string[]) {
        if (isNotNullOrUndefined(this.clustersLightPoints)) {
            this.clustersLightPoints = this.clustersLightPoints.filter(
                cluster => {
                    const lightPointsFiltrati = cluster.element.filter(lightPoint => !objectIds.includes(lightPoint.objectId));
                    if (lightPointsFiltrati.length > 0) {
                        cluster.element = lightPointsFiltrati;
                    }
                    return lightPointsFiltrati.length > 0;
                }
            );
            this.lightPoints_v2 = this.lightPoints_v2.filter(pl => {
                return !objectIds.includes(pl.objectId)
            })
        }
    }


    private removeArrediUrbaniForKey(values: string[], key) {
        if (Array.isArray(this.arrediUrbaniOnMap)) {
            this.arrediUrbaniOnMap = this.arrediUrbaniOnMap.filter(
                arredo => {
                    return !values.includes(arredo[key]);
                }
            );
        }
    }

    private removeArrediUrbaniById(objectIds: string[]) {
        if (Array.isArray(this.arrediUrbaniOnMap)) {
            this.arrediUrbaniOnMap = this.arrediUrbaniOnMap.filter(
                arredo => {
                    return !objectIds.includes(arredo.objectId);
                }
            );
        }
    }

    private updateValueInArrayArredoUrbano(arredo: ArredoUrbanoParse) {
        if (Array.isArray(this.arrediUrbaniOnMap) && isNotNullOrUndefined(arredo)) {
            const index = this.arrediUrbaniOnMap.findIndex(arredoStored => {
                return arredoStored.objectId === arredo.objectId;
            });
            if (index >= 0) {
                this.arrediUrbaniOnMap[index] = arredo;
            }
        }
    }


    private eliminaOAggiornaCircuiti(event: {
        id: string,
        classe: string,
        action: 'updated' | 'removed',
        filtroCircuito: string
    }) {
        if (event.action === 'removed') {
            this.removeCircuiti([event.id]);
            if (arrayIsSet(this.circuitiAppartenentiAlProgetto)) {
                this.circuitiAppartenentiAlProgetto = this.circuitiAppartenentiAlProgetto.filter(c => event.id != c.objectId)

            }
        } else {
            const index = this.circuits.findIndex(circuito => circuito.objectId === event.id);
            const scalaKey = this.circuitiKeyScalaColore;
            if (index >= 0) {
                const circuito = this.circuits[index];
                if (isNotNullOrUndefined(scalaKey.scalaColore)) {
                    const keyCircuito = this.scaleColorCircuiti.getKey(circuito, scalaKey.key);
                    const indexColor = Object.keys(scalaKey.scalaColore).findIndex(key => key === keyCircuito);
                    if (indexColor >= 0) {
                        circuito.icon = this.scaleColorCircuiti.getIconCircuito(circuito);
                    } else {
                        this.scaleColorCircuiti.aggiornaScalaColore();
                        this.cambioScalaColoreCircuito(null);
                    }
                }

                if (this.circutiLabelService.campoLabel != null) {
                    const color = (isNotNullOrUndefined(this.map) && this.map.mapTypeId == 'roadmap') ? 'black' : 'white';
                    circuito.labelMarker = this.circutiLabelService.getLabelMarker(circuito, color);
                    this.circutiLabelService.calcNumeroElementiPerValore(this.circutiLabelService.campoLabel, this.circuits);
                }


                this.forceRenderingMap();
            }
        }
    }

    aggiornaElementoInDetail(event: {
        id: string,
        classe: string,
        action: 'updated' | 'removed',
        filtroCircuito: string,
        values: object
    }) {
        if (event.classe === this.nomeClassi.puntiLuce) {
            this.eliminaOAggiornaPuntiLuce(event);
            this.saveElement = {className: this.nomeClassi.puntiLuce, action: event.action};
        } else if (event.classe === this.nomeClassi.puntiStampa) {
            this.eliminaOAggiornaPuntiStampa(event);
            this.saveElement = {className: this.nomeClassi.puntiStampa, action: event.action};
        } else if (event.classe === this.nomeClassi.circuiti) {
            this.eliminaOAggiornaCircuiti(event);
            this.saveElement = {className: this.nomeClassi.circuiti, action: event.action};
        } else if (event.classe === this.nomeClassi.arredoUrbano) {
            this.eliminaOAggiornaArredoUrbano(event);
        } else if (event.classe === this.nomeClassi.lineaElettrica) {
            this.eliminaOAggiornalineaElettrica(event);
        } else if (event.classe === this.nomeClassi.caricoEsogeno) {
            this.eliminaOAggiornaCaricoEsogeno(event);
        }
    }

    eliminaOAggiornaCaricoEsogeno(event: {
        id: string,
        classe: string,
        action: 'updated' | 'removed',
        filtroCircuito: string,
        values: object
    }) {
        let action$: Observable<CaricoEsogenoParse>;
        const caricoEsogeno = new CaricoEsogenoParse();
        if (event.id != null) {
            caricoEsogeno.objectId = event.id;
        }
        if (event.action == 'removed') {
            action$ = this.caricoEsogenoService.deleteCaricoEsogeno(caricoEsogeno)
        } else {
            action$ = this.caricoEsogenoService.createCaricoEsogeno(event.values, caricoEsogeno)
        }
        action$.subscribe(caricoEsogeno => {
            this.alertService.success(this.translateService.instant('alert.success'))
        }, error => {
            this.alertService.error(error)
        })
    }

    eliminaOAggiornalineaElettrica(event: {
        id: string,
        classe: string,
        action: 'updated' | 'removed',
        filtroCircuito: string,
        values: object
    }) {
        const index = this.electricLinesOnMap.findIndex(line => line.objectId === event.id);
        let lineaElettrica;
        if (index >= 0) {
            lineaElettrica = this.electricLinesOnMap[index];
        } else {
            lineaElettrica = new LineaElettricaParse();
            lineaElettrica.objectId = event.id;
        }
        if (event.action === 'removed') {
            this.lineaElettricaService.destroyElectricLines(lineaElettrica).subscribe(lineaDestroyed => {
                this.electricLinesOnMap = this.electricLinesOnMap.filter(line => line.objectId !== lineaDestroyed.objectId);
                this.electricLines = this.electricLines.filter(line => line.objectId !== lineaDestroyed.objectId);
                const salvato: string = this.translateService.instant('dashboard_sidenav.alert.formgroup.success');
                this.alertService.success(salvato);
                this.saveElement = {className: this.nomeClassi.lineaElettrica, action: event.action};
            }, error => {
                this.saveElement = {className: this.nomeClassi.lineaElettrica, error};
                this.alertService.error(error);
            });
        } else {
            this.lineaElettricaService.updateElectricLines(lineaElettrica, event.values).subscribe(lineaUpdated => {
                this.electricLinesOnMap = this.substitutionElectricLineElements(this.electricLinesOnMap, lineaUpdated);
                const salvato: string = this.translateService.instant('dashboard_sidenav.alert.formgroup.success');
                this.alertService.success(salvato);
                this.saveElement = {className: this.nomeClassi.lineaElettrica, action: event.action};
            }, error => {
                this.saveElement = {className: this.nomeClassi.lineaElettrica, error};
                this.alertService.error(error);
            });
        }
    }

    eliminaOAggiornaArredoUrbano(event: {
        id: string,
        classe: string,
        action: 'updated' | 'removed',
        filtroCircuito: string,
        values: object
    }) {
        if (event.action === 'removed') {
            const arredoUrbano = new ArredoUrbanoParse();
            arredoUrbano.objectId = event.id;
            this.arredoUrbanoUtilsService.destroyArredoUrbano(arredoUrbano).subscribe(
                value => {
                    // this.elementoCliccato = undefined;
                    const salvato: string = this.translateService.instant('dashboard_sidenav.alert.formgroup.success');
                    this.removeArrediUrbaniById([value.objectId]);
                    this.alertService.success(salvato);
                    this.saveElement = {className: this.nomeClassi.arredoUrbano, action: event.action};
                },
                error => {
                    this.mapService.refreshParseObject([arredoUrbano]).subscribe(
                        arredi => {
                            arredi.forEach(arredo => {
                                this.updateValueInArrayArredoUrbano(arredo);
                            });
                        }
                    );
                    this.alertService.error(error);
                    this.saveElement = {className: this.nomeClassi.arredoUrbano, error};
                }
            );
        } else {
            const arredoUrbano = new ArredoUrbanoParse();
            arredoUrbano.objectId = event.id;
            this.arredoUrbanoUtilsService.updateArredoUrbano(arredoUrbano, event.values).subscribe(
                value => {
                    this.elementoCliccato = value;
                    this.updateValueInArrayArredoUrbano(value);
                    const key = 'tipologia';
                    this.addItemNotPresentInArredoUrbanoAppartenentiAlProgettoExportFromKey(value, key);
                    this.saveElement = {className: this.nomeClassi.arredoUrbano, action: event.action};
                    this.forceRenderingMap();
                    const salvato: string = this.translateService.instant('dashboard_sidenav.alert.formgroup.success');
                    this.alertService.success(salvato);
                },
                error => {
                    this.mapService.refreshParseObject([arredoUrbano]).subscribe(
                        arredi => {
                            arredi.forEach(arredo => {
                                this.updateValueInArrayArredoUrbano(arredo);
                            });
                        }
                    );
                    this.alertService.error(error);
                    this.saveElement = {className: this.nomeClassi.arredoUrbano, error};
                }
            );
        }
    }


    fetchPuntiLuce$(lightPoints: PuntiLuceParse[]) {
        if (arrayIsSet(lightPoints)) {
            const color = (isNotNullOrUndefined(this.map) && this.map.mapTypeId == 'roadmap') ? 'black' : 'white';
            return this.mapService.fetchPuntiLuce$(lightPoints).pipe(
                map(lightPoints => {
                    return lightPoints.map(l => {
                        const icon = this.scaleColorPuntiLuce.getIconPuntoLuce(l);
                        l.labelMarker = this.puntiLuceLabelService.getLabelMarker(l, color);
                        l.icon = icon;
                        return l;
                    });
                })
            )
        } else {
            return of([])
        }
    }

    fetchCircuiti$(circuits: CircuitiParse[]): Observable<CircuitiParse[]> {
        if (arrayIsSet(circuits)) {
            const color = (isNotNullOrUndefined(this.map) && this.map.mapTypeId == 'roadmap') ? 'black' : 'white';
            return this.mapService.refreshParseObject(circuits).pipe(
                map(circuitsFetched => {
                    return circuitsFetched.map(circuito => {
                        circuito.labelMarker = this.circutiLabelService.getLabelMarker(circuito, color);
                        circuito.icon = this.scaleColorCircuiti.getIconCircuito(circuito);
                        return circuito;
                    });
                })
            );
        } else {
            return of([] as CircuitiParse[])
        }
    }

    refresh() {
        this.lineaElettricaService.resetLocalLocationElements();
        this.aggregateDataService.unsetAggregateLocal();
        if (arrayIsSet(this.electricLinesOnMap)) {
            this.electricLinesOnMap.forEach(line => line.unsetLinesMap());
        }
        this.loading.refreshButton = true;

        this.fetchPuntiLuce$(this.lightPoints).pipe(
            map(lightPoints => {
                return lightPoints.map(l => {
                    const icon = this.scaleColorPuntiLuce.getIconPuntoLuce(l);
                    l.icon = icon;
                    return l;
                });
            }),
            switchMap((lightPoints) => {
                if (this.scaleColorPuntiLuce.campoScalaColore === 'lampadaFunzionante' && arrayIsSet(lightPoints)) {

                    // return this.segnaliTlcNodoService.getLampadaFunzionante(lightPoints).pipe(
                    //     map((segnali) => {
                    //         segnali.forEach(segnale => {
                    //             const index = lightPoints.findIndex(puntoLuce => puntoLuce.objectId == segnale.puntoLuce.objectId);
                    //             if (index >= 0) {
                    //                 lightPoints[index].lampadaFunzionante = segnale.lampadaFunzionante;
                    //             }
                    //         });
                    //         return lightPoints;
                    //     })
                    // ) ;
                    return this.segnaliTlcNodoService.getLampadaFunzionante_v2(lightPoints).pipe(
                        map((segnali) => {
                            if (segnali != null) {
                                lightPoints.forEach(lightPoint => {
                                    if (segnali[lightPoint.objectId] != null && segnali[lightPoint.objectId].segnale != null) {
                                        const segnale = segnali[lightPoint.objectId].segnale;
                                        lightPoint.setSegnale(segnale);
                                    }
                                })
                            }
                            return lightPoints;
                        })
                    )

                } else {
                    return of(lightPoints)
                }
            }),
            switchMap((lightPoints: PuntiLuceParse[]) => {
                return this.fetchCircuiti$(this.circuits).pipe(
                    map((circuits: CircuitiParse[]) => {
                        return {lightPoints, circuits};
                    })
                );
            }),
            switchMap(({lightPoints, circuits}) => {
                return this.mapService.refreshParseObject(this.arrediUrbaniOnMap).pipe(
                    map((arrediUrbani: ArredoUrbanoParse[]) => {
                        return {lightPoints, circuits, arrediUrbani};
                    })
                );
            }),
            switchMap(({lightPoints, circuits, arrediUrbani}) => {
                const typesObs = Object.keys(this.schedeManutenzioneDelProgetto).map(type => this.getSchedeManutenzione(type, true));
                if (arrayIsSet(typesObs)) {
                    return forkJoin(typesObs).pipe(
                        map((values) => {
                            const obj = {};
                            Object.keys(this.schedeManutenzioneDelProgetto).forEach((key, index) => {
                                obj[key] = values[index];
                            });
                            return {lightPoints, circuits, arrediUrbani, schedeManutenzioneDelProgetto: obj};
                        })
                    );
                } else {
                    const obj = {};
                    return of({lightPoints, circuits, arrediUrbani, schedeManutenzioneDelProgetto: obj});
                }
            }),
            switchMap(({lightPoints, circuits, arrediUrbani, schedeManutenzioneDelProgetto}) => {
                if (!isNotNullOrUndefined(this.calendariManutenzione) && !isNotNullOrUndefined(this.calendariManutenzioneNonEffettuate)) {
                    return of({
                        lightPoints,
                        circuits,
                        arrediUrbani,
                        schedeManutenzioneDelProgetto,
                        calendariManutenzione: {
                            calendariDaEffetuare: undefined,
                            calendariManutenzioneNonEffetuate: undefined
                        },
                    });
                } else {
                    let getCalendariManutenzione$;
                    if (isNotNullOrUndefined(this.calendariManutenzione) && isNotNullOrUndefined(this.calendariManutenzione.fromDate) && isNotNullOrUndefined(this.calendariManutenzione.toDate)) {
                        const rangeDate = {
                            fromDate: new Date(this.calendariManutenzione.fromDate),
                            toDate: new Date(this.calendariManutenzione.toDate)
                        }
                        getCalendariManutenzione$ = this.getCalendariManutenzione(true, undefined, undefined, rangeDate)

                    } else if (isNotNullOrUndefined(this.calendariManutenzione) && isNotNullOrUndefined(this.calendariManutenzione.date)) {
                        const date = this.calendariManutenzione.date;
                        getCalendariManutenzione$ = this.getCalendariManutenzione(true, date, undefined)
                    }


                    return getCalendariManutenzione$.pipe(
                        map((calendariManutenzione) => {
                            return {
                                lightPoints,
                                circuits,
                                arrediUrbani,
                                schedeManutenzioneDelProgetto,
                                calendariManutenzione
                            };
                        })
                    );
                }
            }),
            switchMap(({lightPoints, circuits, arrediUrbani, schedeManutenzioneDelProgetto, calendariManutenzione}) => {
                return this.lineaElettricaService.getElectricLines(undefined).pipe(map((lineeElettriche) => {
                    return {
                        lightPoints,
                        circuits,
                        arrediUrbani,
                        schedeManutenzioneDelProgetto,
                        calendariManutenzione,
                        lineeElettriche
                    };
                }));
            }),
            switchMap(({
                           lightPoints,
                           circuits,
                           arrediUrbani,
                           schedeManutenzioneDelProgetto,
                           calendariManutenzione,
                           lineeElettriche
                       }) => {
                return this.lineaElettricaService.getElectricLineMapByLineaElettrica(this.electricLinesOnMap).pipe(
                    map((electricLinesOnMap) => {
                        return {
                            lightPoints,
                            circuits,
                            arrediUrbani,
                            schedeManutenzioneDelProgetto,
                            calendariManutenzione,
                            lineeElettriche,
                            electricLinesOnMap: this.updateColorAtLines(electricLinesOnMap, true)
                        };
                    }));
            }),
        ).subscribe(
            ({
                 lightPoints,
                 circuits,
                 arrediUrbani,
                 schedeManutenzioneDelProgetto,
                 calendariManutenzione,
                 lineeElettriche,
                 electricLinesOnMap
             }) => {
                this.updateColorArrayLightPoint(lightPoints);
                this.clustersLightPoints = this.createClusterLightPoint(lightPoints);
                this.lightPoints_v2 = lightPoints;

                this.schedeManutenzioneDelProgetto = schedeManutenzioneDelProgetto;
                this.calendariManutenzione = calendariManutenzione.calendariDaEffetuare;
                this.calendariManutenzioneNonEffettuate = calendariManutenzione.calendariDaEffetuare;

                this.arrediUrbaniOnMap = arrediUrbani;
                this.electricLines = lineeElettriche;
                this.clustersCircuits = this.createclustersCircuits(circuits);
                this.circuits_v2 = circuits;
                this.electricLinesOnMap = electricLinesOnMap;
                if (arrayIsSet(this.circuitiAppartenentiAlProgetto)) {
                    this.circuitiAppartenentiAlProgetto.sort((a, b) => {
                        const numeroQuadroA = stringIsSet(a.numeroQuadro) ? a.numeroQuadro : '';
                        const numeroQuadroB = stringIsSet(b.numeroQuadro) ? b.numeroQuadro : '';
                        return numeroQuadroA.localeCompare(numeroQuadroB);
                    })
                }
                this.forceRenderingMap();
                this.loading.refreshButton = false;
            }, error => {
                this.loading.refreshButton = false;
            }, () => {
            }
        );
    }

    visualizedAllMarkerInMap() {
        this.changeZoomInMap.next(true);
    }

    // corrisponde a premere deseleziona tutto dalla pagina di filtri
    removeAllLightPoint() {
        if (this.dynamicViewIsOn) {
            this.destroyDynamicView();
        } else {
            this.changeFilter.next({puntiLuce: true, circuiti: false});
        }
    }


    shortPressEye() {
        if (this.dynamicViewIsOn) {
            this.formPage.get('dynamicView').get('isOpenedSetting').setValue(true);
        } else {
            this.getLightPointInMap();
        }
    }

    getLightPointInMap() {
        this.loading.eyeButton = true;
        const idGiaPresenti = this.lightPoints.map(puntoLuce => puntoLuce.objectId);
        this.mapService.getPuntiLuceTramiteCoordinatePaginate(this.map.getBounds(), this.lightPointLimit, idGiaPresenti)
            .pipe(
                switchMap((lightPoints) => {
                    if (this.scaleColorPuntiLuce.campoScalaColore === 'lampadaFunzionante' && arrayIsSet(lightPoints.puntiLuce)) {
                        // return this.segnaliTlcNodoService.getLampadaFunzionante(lightPoints.puntiLuce).pipe(
                        //     map((segnali) => {
                        //         segnali.forEach(segnale => {
                        //             const index = lightPoints.puntiLuce.findIndex(puntoLuce => puntoLuce.objectId == segnale.puntoLuce.objectId);
                        //             if (index >= 0) {
                        //                 lightPoints.puntiLuce[index].lampadaFunzionante = segnale.lampadaFunzionante;
                        //             }
                        //         });
                        //         return lightPoints;
                        //     })
                        // )

                        return this.segnaliTlcNodoService.getLampadaFunzionante_v2(lightPoints.puntiLuce).pipe(
                            map((segnali) => {
                                if (segnali != null) {
                                    lightPoints.puntiLuce.forEach(lightPoint => {
                                        if (segnali[lightPoint.objectId] != null && segnali[lightPoint.objectId].segnale != null) {
                                            const segnale = segnali[lightPoint.objectId].segnale;
                                            lightPoint.setSegnale(segnale)
                                        }
                                    })
                                }
                                return lightPoints;
                            })
                        )

                    } else {
                        return of(lightPoints)
                    }
                })
            )
            .subscribe(
                (lightPoints) => {
                    if (lightPoints.puntiLuce.length > 0) {
                        const color = (isNotNullOrUndefined(this.map) && this.map.mapTypeId == 'roadmap') ? 'black' : 'white';
                        lightPoints.puntiLuce.forEach(pl => pl.labelMarker = this.puntiLuceLabelService.getLabelMarker(pl, color));
                        this.addPuntiLuceInCluster(lightPoints.puntiLuce);
                        const newValueIsPresent = this.scaleColorPuntiLuce.lightPointsContainedNewValue(lightPoints.puntiLuce);
                        if (newValueIsPresent) {
                            this.scaleColorPuntiLuce.puntiLuceInMappa = this.lightPoints;
                            this.scaleColorPuntiLuce.aggiornaScalaColore();
                            this.cambiaColoreDelleIcone(null);
                        }
                        this.puntiLuceLabelService.createValueHtml(this.lightPoints);
                        this.forceRenderingMap();
                    }
                    this.loading.eyeButton = !lightPoints.finished;
                }, error => {
                    this.loading.eyeButton = false;
                }
            );
    }

    assignMap(event) {
        this.map = event;
    }

    addElementoByStreetView() {
        // this.map.streetView.setVisible(false)
        const getd = (zAngle) => {
            const min = 1;
            const max = 11;
            return Math.round(((max - (min + max) / 2) / 90) * zAngle + (min + max) / 2);
        };

        const sin = (angle) => Math.sin(angle);
        const cos = (angle) => Math.cos(angle);
        const asin = (angle) => Math.asin(angle);
        const atan2 = (x, y) => Math.atan2(x, y);
        const d = getd(this.map.streetView.getPov().pitch);
        const rbearing = this.map.streetView.getPov().heading * Math.PI / 180;
        const currentLat = this.map.streetView.getPosition().lat();
        const currentLng = this.map.streetView.getPosition().lng();
        const distRadians = d / 6372797.6;
        const lat1 = currentLat * Math.PI / 180;
        const lon1 = currentLng * Math.PI / 180;
        const lat2 = asin(sin(lat1) * cos(distRadians) + cos(lat1) * sin(distRadians) * cos(rbearing));
        const lng2 = lon1 + atan2(sin(rbearing) * sin(distRadians) * cos(lat1), cos(distRadians) - sin(lat1) * sin(lat2));
        const lastLat = lat2 * 180 / Math.PI;
        const lastLng = lng2 * 180 / Math.PI;
        const coords = {lat: lastLat, lng: lastLng};
        this.mapClicksCoords({coords});
    }

    openMapInfo() {
        // la somma dei punti luce visualizzati con l' occhio
        const puntiLuceTotali = this.lightPoints;
        const a = this.dialog.open(InfoDialogComponent, {
            width: '600px',
            data: {
                puntiLuce: puntiLuceTotali,
                circuiti: this.circuits
            }
        });
        a.afterClosed();
    }


    changeLabel(event) {
        const color = (this.map.mapTypeId == 'roadmap') ? 'black' : 'white';
        const lightPoints = [...this.lightPoints_v2];
        lightPoints.forEach(
            puntoLuce => {
                puntoLuce.labelMarker = this.puntiLuceLabelService.getLabelMarker(puntoLuce, color);
            }
        );
        this.lightPoints_v2 = lightPoints;
        this.forceRenderingMap();
    }

    cambiaColoreDelleIcone(event) {
        this.loading.updateLightPoint = true;
        this.loading.settingsButton = true;
        if (this.scaleColorPuntiLuce.campoScalaColore === 'lampadaFunzionante' && (this.lightPoints.length > 0)) {
            // this.segnaliTlcNodoService.getLampadaFunzionante(this.lightPoints).subscribe(
            //     (segnali: SegnaliTlcNodoParse[]) => {
            //         segnali.forEach(segnale => {
            //             const index = this.lightPoints.findIndex(puntoLuce => puntoLuce.objectId == segnale.puntoLuce.objectId);
            //             if (index > -1) {
            //                 this.lightPoints[index].lampadaFunzionante = segnale.lampadaFunzionante;
            //             }
            //         });
            //         this.updateColorArrayLightPoint(this.lightPoints);
            //         this.clustersLightPoints = this.createClusterLightPoint(this.lightPoints);
            //         this.loading.settingsButton = false;
            //         this.loading.updateLightPoint = false;
            //     }, error => {
            //         this.mapService.refreshParseObject(this.lightPoints).subscribe(lightPoints => {
            //             this.updateColorArrayLightPoint(this.lightPoints);
            //             this.clustersLightPoints = this.createClusterLightPoint(this.lightPoints);
            //             this.loading.settingsButton = false;
            //             this.loading.updateLightPoint = false;
            //         }, error => {
            //             this.loading.settingsButton = false;
            //             this.loading.updateLightPoint = false;
            //         });
            //
            //     }
            // );
            this.segnaliTlcNodoService.getLampadaFunzionante_v2(this.lightPoints).subscribe(
                (segnali) => {
                    if (segnali != null) {
                        this.lightPoints.forEach(element => {
                            if (segnali[element.objectId] != null && segnali[element.objectId].segnale != null) {
                                const segnale = segnali[element.objectId].segnale;
                                element.setSegnale(segnale);
                            }
                        })
                    }

                    this.updateColorArrayLightPoint(this.lightPoints);
                    this.clustersLightPoints = this.createClusterLightPoint(this.lightPoints);
                    this.lightPoints_v2 = this.lightPoints_v2;
                    this.loading.settingsButton = false;
                    this.loading.updateLightPoint = false;
                }, error => {
                    this.fetchPuntiLuce$(this.lightPoints).subscribe(lightPoints => {
                        this.updateColorArrayLightPoint(this.lightPoints);
                        this.clustersLightPoints = this.createClusterLightPoint(this.lightPoints);
                        this.lightPoints_v2 = this.lightPoints_v2.slice();
                        this.loading.settingsButton = false;
                        this.loading.updateLightPoint = false;
                    }, error => {
                        this.loading.settingsButton = false;
                        this.loading.updateLightPoint = false;
                    });

                }
            );
        } else {
            setTimeout(() => {
                this.updateColorArrayLightPoint(this.lightPoints_v2);
                this.clustersLightPoints = this.createClusterLightPoint(this.lightPoints_v2);
                this.lightPoints_v2 = [...this.lightPoints_v2];
                this.loading.settingsButton = false;
                this.loading.updateLightPoint = false;
            }, 300);
        }

    }


    settings() {
        this.sidenavEndWhoOpen = 'settings';
    }


    public calcColorIconVector(puntoLuce: PuntiLuceParse): object {
        return this.scaleColorPuntiLuce.getIconPuntoLuce(puntoLuce);
    }

    private updateColorArrayLightPoint(allLightPoint: PuntiLuceParse[]) {
        allLightPoint.forEach((puntoLuce) => {
            const icon = this.scaleColorPuntiLuce.getIconPuntoLuce(puntoLuce);
            puntoLuce.icon = icon;
        });

    }

    private createClusterLightPoint(allLightPoints: PuntiLuceParse[]) {
        const clusterLightPoint = [];
        allLightPoints.forEach(puntoLuce => {
            const index = clusterLightPoint.findIndex(cluster => cluster.color == puntoLuce.icon.strokeColor);
            if (index > -1) {
                clusterLightPoint[index].element.push(puntoLuce);
            } else {
                clusterLightPoint.push({color: puntoLuce.icon.strokeColor, element: [puntoLuce]});
            }
        });
        return clusterLightPoint;
    }

    private updateClusterLightPoint(clusterLightPoint: any[], allLightPoints: PuntiLuceParse[]) {
        allLightPoints.forEach(puntoLuce => {
            const index = clusterLightPoint.findIndex(cluster => cluster.color == puntoLuce.icon.strokeColor);
            if (index > -1) {
                clusterLightPoint[index].element.push(puntoLuce);
            } else {
                clusterLightPoint.push({color: puntoLuce.icon.strokeColor, element: [puntoLuce]});
            }
        });
    }

    isDisableIcon(value: boolean) {
        if (value) {
            return 'Disable';
        } else {
            return null;
        }
    }

    // viene chiamato ogni volta app-button cambia visibilità, buttonIsOpen dice lo stato del bottone
    closeDrawingManager(buttonIsOpen: boolean) {
        if (!buttonIsOpen) {
            if (this.openedDrawingElectricLine) {
                this.destroyDynamicView();
            }
            this.openedDrawingManager = false;
            this.reportsService.clearReportsInMap();
            this.resetAllButton();
            this.distances = [];
            this.distancess = undefined;
            this.drawedElectricLines = undefined;
        }

    }

    openDrawingManager() {
        this.openedDrawingManager = true;
        this.disableButton.addElement = true;
    }

    drawingManager(event) {
        if (event.funct) {
            this.lightPoints.forEach(puntoLuce => {
                if (event.funct(puntoLuce, event.secondParam)) {
                    if (!this.reportsService.isReport(puntoLuce)) {
                        this.reportsService.addItem(puntoLuce);
                    }
                }
                // else if (this.reportsService.isReport(puntoLuce)) {
                //     // this.reportsService.removeItem(puntoLuce)
                // }
            });
        }

    }


    createReports() {
        if (!arrayIsSet(this.reportsService.reportsInMap) && arrayIsSet(this.lightPoints)) {
            this.lightPoints.forEach(puntoLuce => {
                this.reportsService.addItem(puntoLuce);
            });
        }
        if (this.reportsService.reportsInMap.length > 0) {
            // this.loading.plusButton=true;
            // const type = typeElementScheduleMaintence.Segnalazioni;
            // this.getSchedeManutenzione(type, false).pipe(
            //     switchMap(schedeManutenzione => {
            //         this.schedeManutenzioneDelProgetto[type] = schedeManutenzione;
            //         return this.dialog.open(CreateReportComponent).afterClosed();
            //     })
            // ).subscribe(result=>{
            //     this.loading.plusButton=false;
            // },error => {
            //     this.loading.plusButton=false;
            // })
            this.dialog.open(CreateReportComponent);
        } else {
            this.dialog.open(InfoReportComponent);
        }
    }

    createGroups() {
        if (!arrayIsSet(this.reportsService.reportsInMap) && arrayIsSet(this.lightPoints)) {
            this.lightPoints.forEach(puntoLuce => {
                this.reportsService.addItem(puntoLuce);
            });
        }
        if (this.reportsService.reportsInMap.length > 0) {
            this.dialog.open(CreateGroupComponent).afterClosed().subscribe(group => {
                    if (group) {
                        this.gruppiAppartenentiAlProgetto = this.gruppiAppartenentiAlProgetto.concat([group]);
                    }
                }
            );
        } else {
            this.dialog.open(InfoGroupComponent);
        }
    }


    public getPrendiColoreInbaseAlValore(value): string {
        if (!isNotNullOrUndefined(value)) {
            return '#000000';
        } else {
            const castValue = (typeof value != 'number') ? parseFloat(value) : value;
            return this.linearScaleService.PrendiColore(castValue);
        }
    }

    getInterdistance(objectIdLightPoints: String[]) {
        return this.mapService.getInterdistanceLightPoint(objectIdLightPoints);
    }

    public midPoint(SW, NE) {
        return this.googleService.midPoint(SW, NE);
    }

    public getDistance(from, to) {
        return this.mapService.distanceBetweenPoint(from, to);
    }

    calcolaInterdistanza() {
        if (this.reportsService.reportsInMap.length > 0) {
            this.interdistanceOff = false;
            this.getInterdistance(this.reportsService.reportsInMap).subscribe((distances) => {
                this.closeDrawingManager(true);
                this.polyLineInfoWindow = [];
                this.interdistances = [];
                this.interdistanceDetail = undefined;
                this.linearScaleService.generateScale(4, true);
                this.linearScaleService.setMinMaxValue(distances.interdistanzaMin, distances.interdistanzaMax, 100);
                this.elementiScalaLux = this.linearScaleService.ElementiScala;
                this.luxmin = Math.round(this.linearScaleService.keyMin);
                this.luxmax = Math.round(this.linearScaleService.keyMax);
                this.interdistanceDetail = {
                    average: Math.round(distances.interdistanza),
                    total: Math.round(distances.distanzaTotale),
                    maximun: Math.round(distances.interdistanzaMax),
                    minimum: Math.round(distances.interdistanzaMin)
                };
                distances.path.forEach((distance, index) => {
                    const start = distance[0];
                    const end = distance[1];
                    const newInterdistance: InterdistanceLightPoint = {
                        objectId: start.objectId + end.objectId,
                        color: this.getPrendiColoreInbaseAlValore(Math.round(distance[2])),
                        start: start.location,
                        end: end.location,
                        distance: Math.round(distance[2])
                    };
                    const isPresent = this.interdistances.findIndex((interdistanza) =>
                        interdistanza.objectId.includes(start.objectId) && interdistanza.objectId.includes(end.objectId)
                    );
                    if (isPresent < 0) {
                        const middlePoint = this.midPoint(start.location, end.location);
                        this.interdistances.push(newInterdistance);
                        this.polyLineInfoWindow.push({
                            objectId: start.objectId + end.objectId,
                            lat: middlePoint.lat(),
                            lng: middlePoint.lng(),
                            persistent: true
                        });
                    }
                });
            });
        } else {
            this.alertService.warning(this.translateService.instant('dashboard.alert.notLightPoints'));
        }
    }

    disableInterdistance() {
        this.interdistanceOff = true;
        this.polyLineInfoWindow = [];
        this.interdistances = [];
        this.interdistanceDetail = undefined;
    }

    resetAllButton() {
        Object.keys(this.disableButton).forEach(
            key => {
                this.disableButton[key] = false;
            }
        );
        this.abilitaSpostamentoMarker = false;
        this.openedAddPuntiLuceCircuito = false;
        this.openedRuler = false;
        this.openedDrawingElectricLine = false;
        this.classElementToAdd = undefined;
        this.markerClickable = true;
        const keyNotReset = ['dynamicView'];
        Object.keys(this.formPage.value)
            .filter(key => !keyNotReset.includes(key))
            .forEach(key => this.formPage.get(key).reset());
    }

    addPuntoLuceCircuito() {
        // let numeriQuadro = this.circuitiAppartenentiAlProgettoExport.map((a) => a.formHtml);
        // numeriQuadro.push('AA');
        // numeriQuadro.push('BB');

        this.dialogPopUpService.openDialogForNewElement(this.translateService.instant('dashboard.newElementAsk'), null, null).subscribe(
            classElementAdd => {
                if (isNotNullOrUndefined(classElementAdd)) {
                    this.classElementToAdd = classElementAdd;
                } else {
                    this.classElementToAdd = undefined;
                    this.resetAllButton();
                }
                const isClass = (classElement, key) => {
                    return arrayIsSet(classElement) ? classElement.includes(key) : classElement === key;
                };
                if (isClass(classElementAdd, this.nomeClassi.puntiLuce) || isClass(classElementAdd, this.nomeClassi.circuiti) || isClass(classElementAdd, this.nomeClassi.arredoUrbano)) {
                    this.openedAddPuntiLuceCircuito = true;
                    this.disableButton.handButton = true;
                    this.disableButton.deleteAll = true;
                    this.disableButton.batchEdit = true;
                    this.disableButton.move = true;
                    this.disableButton.ruler = true;
                    this.classElementToAdd = classElementAdd;
                    this.inSaveAddElement = false;
                    if (isClass(classElementAdd, this.nomeClassi.arredoUrbano)) {
                        this.mapService.getDistinctValueForKey(['tipologia'], this.nomeClassi.arredoUrbano).subscribe(val => {
                            this.customTypeArredoUrbano = (arrayIsSet(val.tipologia)) ? val.tipologia : undefined;
                        });
                    }
                    this.abilitaSpostaPuntiLuce();
                } else if (isClass(classElementAdd, this.nomeClassi.lineaElettrica)) {
                    this.resetAllButton();
                    this.openAddElectricLine();
                }

            }
        );
    }

    addTypeArredoUrbano(key) {
        if (this.formPage.get('typeArredoUrbano')) {
            this.formPage.get('typeArredoUrbano').setValue(key);
        }
    }

    private setCursorLoad() {
        if (isNotNullOrUndefined(this.map)) {
            this.map.setOptions({draggableCursor: 'wait'});
            this.inSaveAddElement = true;
        }
    }

    private setCursorDefault() {
        if (isNotNullOrUndefined(this.map)) {
            this.map.setOptions({draggableCursor: ''});
        }
    }


    private setCursorAddLightPoint() {
        if (isNotNullOrUndefined(this.map)) {
            this.map.setOptions({draggableCursor: 'url(/assets/icon/dashboard/picket24.svg), auto'});
            this.inSaveAddElement = false;
        }
    }

    private setCursorLoadOrDefault() {
        if (this.openedAddPuntiLuceCircuito) {
            this.setCursorAddLightPoint();
        } else {
            this.setCursorDefault();
        }
    }


    mapClicksCoords(event) {
        const coords = event.coords;
        const zoom = event.zoom;
        if (this.openedAddPuntiLuceCircuito) {
            const numeroQuadro = isNotNullOrUndefined(this.formPage) && isNotNullOrUndefined(this.formPage.get('numeroQuadro')) && stringIsSet(this.formPage.get('numeroQuadro').value) ? this.formPage.get('numeroQuadro').value : undefined;
            const typeArredoUrbano = isNotNullOrUndefined(this.formPage) && isNotNullOrUndefined(this.formPage.get('typeArredoUrbano')) && stringIsSet(this.formPage.get('typeArredoUrbano').value) ? {tipologia: this.formPage.get('typeArredoUrbano').value} : undefined;
            if (this.classNameAddElement == this.nomeClassi.arredoUrbano && !isNotNullOrUndefined(typeArredoUrbano)) {
                const message = this.translateService.instant('dashboard_sidenav.ArredoUrbano.mustSelectBeforeType');
                this.alertService.warning(message);
            } else {
                this.addElementOnServer(coords, numeroQuadro, typeArredoUrbano);
            }

        } else if (this.openedRuler) {
            this.ruler(coords);
        } else if (this.openedDrawingElectricLine) {
            this.addElectricLine(coords, zoom);
        }
    }


    addItemNotPresentInArredoUrbanoAppartenentiAlProgettoExportFromKey(arredoUrbano: ArredoUrbanoParse, key) {
        const obj = this.arredoUrbanoAppartenentiAlProgettoExport != null ? {...this.arredoUrbanoAppartenentiAlProgettoExport} : {};
        if (arrayIsSet(obj[key])) {
            const index = this.arredoUrbanoAppartenentiAlProgettoExport[key].findIndex(
                item => {
                    return this.arredoUrbanoUtilsService.isEquals(item[key], arredoUrbano.tipologia);
                }
            );
            if (index < 0) {
                obj[key].push(this.arredoUrbanoUtilsService.mapArredoUrbanoValue(arredoUrbano[key], key, 100 + Math.floor(Math.random() * 1000)));
            }
            this.arredoUrbanoAppartenentiAlProgettoExport = obj;
        }

    }

    addElementOnServer(coords, numeroQuadro, typeArredoUrbano) {
        if (!this.inSaveAddElement) {
            this.setCursorLoad();
            if (this.classElementToAdd.includes(this.nomeClassi.puntiLuce)) {
                const color = (isNotNullOrUndefined(this.map) && this.map.mapTypeId == 'roadmap') ? 'black' : 'white';
                this.mapService.createNewPuntoLuce(coords).subscribe((puntoLuce) => {
                    puntoLuce.icon = this.scaleColorPuntiLuce.getIconPuntoLuce(puntoLuce);
                    puntoLuce.labelMarker = this.puntiLuceLabelService.getLabelMarker(puntoLuce, color);
                    this.addPuntiLuceInCluster([puntoLuce]);
                    this.setCursorAddLightPoint();
                    this.forceRenderingMap();
                }, (error) => {
                    console.error(error);
                    const erroreGenerato: string = error.message;
                    this.alertService.error(erroreGenerato);
                    this.setCursorAddLightPoint();
                });
            } else if (this.classElementToAdd.includes(this.nomeClassi.circuiti)) {
                const color = (isNotNullOrUndefined(this.map) && this.map.mapTypeId == 'roadmap') ? 'black' : 'white';
                this.mapService.createNewCircuito(coords, numeroQuadro).pipe(
                    map((circuito) => {
                            circuito.labelMarker = this.circutiLabelService.getLabelMarker(circuito, color);
                            circuito.icon = this.scaleColorCircuiti.getIconCircuito(circuito);
                            return circuito;
                        }
                    )).subscribe(
                    circuito => {
                        this.addCircuitiInCluster([circuito]);
                        this.circuitiAppartenentiAlProgetto.push(circuito);
                        this.setCursorAddLightPoint();
                        this.forceRenderingMap();
                    }, error => {
                        this.setCursorAddLightPoint();
                        const erroreGenerato: string = error.message;
                        this.alertService.error(erroreGenerato);
                        this.forceRenderingMap();
                    }
                );
            } else if (this.classElementToAdd.includes(this.nomeClassi.puntiStampa)) {
                this.mapService.createNewPuntiCamera(coords).pipe(delay(200)).subscribe((puntiCamera) => {
                    this.puntiStampa.push(puntiCamera);
                    this.setCursorAddLightPoint();
                    this.forceRenderingMap();
                }, (error) => {
                    console.error(error);
                    this.setCursorAddLightPoint();
                    const erroreGenerato: string = error.message;
                    this.alertService.error(erroreGenerato);
                });
            } else if (this.classElementToAdd.includes(this.nomeClassi.arredoUrbano)) {
                const value = typeArredoUrbano;
                this.arredoUrbanoUtilsService.createNewArredoUrbano(coords, value).pipe(delay(200)).subscribe((arredoUrbano) => {
                    const key = 'tipologia';
                    this.addItemNotPresentInArredoUrbanoAppartenentiAlProgettoExportFromKey(arredoUrbano, key);
                    this.arrediUrbaniOnMap.push(arredoUrbano);
                    this.setCursorAddLightPoint();
                    this.forceRenderingMap();
                }, (error) => {
                    console.error(error);
                    this.setCursorAddLightPoint();
                    const erroreGenerato: string = error.message;
                    this.alertService.error(erroreGenerato);
                });
            }
        }
    }


    deleteAllElement() {
        let editableLightPoints;
        if (Array.isArray(this.reportsService.reportsInMap) && this.reportsService.reportsInMap.length > 0) {
            editableLightPoints = this.reportsService.reportsInMap.map((idLightPoint) => {
                const puntoLuce = new PuntiLuceParse();
                puntoLuce.objectId = idLightPoint;
                return puntoLuce;
            });
        } else {
            editableLightPoints = this.lightPoints;
        }
        this.loading.plusButton = true;
        const numeroCircuiti = (isNotNullOrUndefined(this.circuits)) ? this.circuits.length : 0;
        const numeroPuntiLuce = (isNotNullOrUndefined(editableLightPoints)) ? editableLightPoints.length : 0;
        this.visualizedAllMarkerInMap();
        this.dialogPopUpService.openDialogForDeleteAllVisualizedElement(numeroCircuiti, numeroPuntiLuce).pipe(
            switchMap((result) => {
                if (result == className.circuiti) {
                    return this.mapService.destroyAllCircuit(this.circuits).pipe(
                        map((result) => {
                            return {eliminato: className.circuiti, eliminate: result, paginateResult: undefined};
                        })
                    );
                } else if (result == className.puntiLuce) {
                    return this.mapService.destroyAllpuntiLuce(editableLightPoints).pipe(
                        map((result) => {
                            return {eliminato: className.puntiLuce, eliminate: undefined, paginateResult: result};
                        })
                    );
                } else {
                    return of({eliminato: false});
                }
            })
        ).subscribe(
            eliminate => {
                if (eliminate.eliminato === className.circuiti) {
                    this.clustersCircuits = [];
                    this.circuits_v2 = [];
                    this.alertService.success(this.translateService.instant('deleteSuccessufully'));
                    if (arrayIsSet(this.circuitiAppartenentiAlProgetto) && arrayIsSet(eliminate.eliminate)) {
                        const eliminatIds = eliminate.eliminate.map(circuito => circuito.objectId);
                        this.circuitiAppartenentiAlProgetto = this.circuitiAppartenentiAlProgetto.filter(c => !eliminatIds.includes(c.objectId))
                    }
                } else if (eliminate.eliminato === className.puntiLuce) {
                    if (eliminate.paginateResult.error != null) {
                        this.alertService.error(eliminate.paginateResult.error);
                    } else if (eliminate.paginateResult && arrayIsSet(eliminate.paginateResult.items) && arrayIsSet(eliminate.paginateResult.items[0].errors)) {
                        const lightPoints = eliminate.paginateResult.items[0].errors.map(error => {
                            const pl = new PuntiLuceParse()
                            pl.objectId = error.puntoLuceId
                            return pl;
                        });
                        this.clustersLightPoints = this.createClusterLightPoint(lightPoints);
                        this.lightPoints_v2 = lightPoints;
                        this.alertService.error(eliminate.paginateResult.items[0].errors[0].error)
                    } else {
                        this.clustersLightPoints = [];
                        this.lightPoints_v2 = [];
                        this.alertService.success(this.translateService.instant('deleteSuccessufully'));
                    }
                } else {
                    this.alertService.success(this.translateService.instant('alert.operationCancel'));
                }
                this.loading.plusButton = false;
            }, error => {
                this.loading.plusButton = false;
                this.alertService.error(error);
            }
        );
    }

    batchEdit() {
        this.changeDetection.detectChanges();
        let editableLightPoints;
        if (Array.isArray(this.reportsService.reportsInMap) && this.reportsService.reportsInMap.length > 0) {
            editableLightPoints = this.reportsService.reportsInMap.map((idLightPoint) => {
                const puntoLuce = new PuntiLuceParse();
                puntoLuce.objectId = idLightPoint;
                return puntoLuce;
            });
        } else {
            editableLightPoints = this.lightPoints;
        }
        this.dialogPopUpService.openBatchEdit(editableLightPoints, this.circuitiAppartenentiAlProgetto, this.stradeAppartenentiAlProgetto).subscribe(
            updated => {
                if (updated) {
                    this.refresh();
                }
            },
            error => {
                this.changeDetection.reattach()
            }, () => {
                this.changeDetection.reattach()
            }
        );
    }

    abilitaSpostaPuntiLuce() {
        this.abilitaSpostamentoMarker = true;
        this.disableButton.batchEdit = true;
        this.disableButton.handButton = true;
        this.disableButton.deleteAll = true;
        // this.disableButton.addElement = true;
    }

    conservaPosizioniIniziali(event: positionMarker) {
        const index = this.posizioniIniziliaMarker.findIndex(posizioneIniziale => (posizioneIniziale.id == event.id && event.nomeClasse == posizioneIniziale.nomeClasse));
        if (index < 0) {
            this.posizioniIniziliaMarker.push(event);
        }
    }

    private isEquals(coords1: { latitude, longitude }, coords2: { latitude, longitude }) {
        return (isNotNullOrUndefined(coords1) && isNotNullOrUndefined(coords2)) && (coords1.latitude == coords2.latitude) && (coords1.longitude == coords2.longitude);
    }

    spostaElemento(event: positionMarker) {
        if (!this.openedDrawingElectricLine) {
            setTimeout(() => {
                if (this.isEquals(this.oldBounds, {latitude: event.latitudine, longitude: event.longitudine})) {
                    this.updateLocationAddress(event);
                }
            }, 1000);
            this.oldBounds = {latitude: event.latitudine, longitude: event.longitudine};
        } else {
            if (event.line != null && event.latitudine != null && event.longitudine != null) {
                const line = this.lineaElettricaService.substitutionInLine(this.drawedElectricLines, event.line, {
                    lat: event.latitudine,
                    lng: event.longitudine
                });
                this.drawedElectricLines = [];
                setTimeout(() => {
                    this.drawedElectricLines = line;
                });
            }
        }

    }

    ripristinaSpostaPuntiLuceQuadri() {
        const lastValue = this.posizioniIniziliaMarker[this.posizioniIniziliaMarker.length - 1];
        this.updateLocationAddress(lastValue, true);
    }


    private updateLocationAddress(event: positionMarker, ripristinaPosizione: boolean = false) {
        this.loading.moveSave = true;
        this.setCursorLoad();
        const nomeClasse = event.nomeClasse;
        const coordinate = {lat: event.latitudine, lng: event.longitudine};
        const objectId = event.id;
        const indirizzo = event.indirizzo;
        if (event.nomeClasse == className.puntiLuce) {
            const coordinateH = new GeoPointH(event.latitudine, event.longitudine)
            const puntoLuce = this.lightPoints_v2.find(pl => pl.objectId === objectId);
            if (puntoLuce != null) {
                puntoLuce.location = coordinateH as any
                this.substitutionOrAddLightPoint(puntoLuce);
            }
            const updateAddress$ = new PuntoLuce_v2(objectId).changePuntoLuceLocation$(coordinateH).pipe(
                catchError(error => {
                    this.alertService.error(error);
                    console.error(error);
                    return this.fetchPuntiLuce$([puntoLuce]).pipe(
                        map(puntiLuce => puntiLuce[0])
                    )
                })
            )
            // forkJoin([this.mapService.updateLocationPuntiLuce(coordinate, objectId),
            //     this.mapService.updateAddressPuntiLuce(coordinate, objectId, indirizzo)]
            // )
            updateAddress$.subscribe((plUpdated) => {
                const message: string = this.translateService.instant('dashboard.alert.riposizionato.success');
                this.alertService.success(message);
                this.setCursorLoadOrDefault();
                this.substitutionOrAddLightPoint(plUpdated)
                if (ripristinaPosizione) {
                    this.posizioniIniziliaMarker.pop();
                    this.forceRenderingMap();
                }
                this.loading.moveSave = false;
            }, (error) => {
                console.error(error);
            });
        } else if (nomeClasse == className.circuiti) {
            const circuito = this.circuits_v2.find(c => c.objectId === objectId);
            if (circuito != null) {
                circuito.location.latitude = coordinate.lat;
                circuito.location.longitude = coordinate.lng;
                this.substitutionOrAddCircuit(circuito);
            }
            forkJoin([this.mapService.updateLocationCircuiti(coordinate, objectId),
                this.mapService.updateAddressCircuiti(coordinate, objectId, indirizzo)]
            ).pipe(
                catchError(error => {
                    this.alertService.error(error);
                    console.error(error);
                    return this.fetchCircuiti$([circuito])
                })
            ).subscribe((circuito) => {
                const message: string = this.translateService.instant('dashboard.alert.riposizionato.success');
                this.alertService.success(message);
                this.substitutionOrAddCircuit(circuito[0]);
                this.setCursorLoadOrDefault();
                if (ripristinaPosizione) {
                    this.posizioniIniziliaMarker.pop();
                    this.forceRenderingMap();
                }
                this.loading.moveSave = false;
            }, (error) => {
            });
        } else if (nomeClasse == className.puntiStampa) {
            this.mapService.updateLocationPuntiCamera(coordinate, objectId).subscribe(() => {
                const message: string = this.translateService.instant('dashboard.alert.riposizionato.success');
                this.alertService.success(message);
                this.setCursorLoadOrDefault();
                if (ripristinaPosizione) {
                    this.posizioniIniziliaMarker.pop();
                    this.forceRenderingMap();
                }
                this.loading.moveSave = false;
            }, (error) => {
                const erroreGenerato: string = error.message;
                this.alertService.error(erroreGenerato);
                this.setCursorLoadOrDefault();
                this.loading.moveSave = false;
                console.error(error);
            });
        } else if (nomeClasse == className.arredoUrbano) {
            const arredoUrbano = new ArredoUrbanoParse();
            arredoUrbano.objectId = objectId;
            this.arredoUrbanoUtilsService.updateLocation(arredoUrbano, coordinate).subscribe(() => {
                const message: string = this.translateService.instant('dashboard.alert.riposizionato.success');
                this.alertService.success(message);
                this.setCursorLoadOrDefault();
                if (ripristinaPosizione) {
                    this.posizioniIniziliaMarker.pop();
                    this.forceRenderingMap();
                }
                this.loading.moveSave = false;
            }, (error) => {
                const erroreGenerato: string = error.message;
                this.alertService.error(erroreGenerato);
                this.setCursorLoadOrDefault();
                this.loading.moveSave = false;
                console.error(error);
            });
        }
    }

    public raggruppaPuntiLuce() {
        this.disableButton.groupLightPoint = true;
        if (Array.isArray(this.reportsService.reportsInMap) && this.reportsService.reportsInMap.length > 0) {
            const editableLightPoints = this.reportsService.reportsInMap;
            this.mapService.raggruppaPuntiLuce(editableLightPoints).subscribe(
                pl => {
                    if (pl.length > 0) {
                        const message = (pl.length + 1) + ' ' + this.translateService.instant('alert.groupLightPoint');
                        this.alertService.success(message);
                    } else {
                        const message = this.translateService.instant('alert.notGroupLightPoint');
                        this.alertService.warning(message);
                    }
                    this.disableButton.groupLightPoint = false;
                }, error => {
                    this.disableButton.groupLightPoint = false;
                    this.alertService.error(error.message);
                }
            );
        } else {
            this.disableButton.groupLightPoint = false;
            this.alertService.warning(this.translateService.instant('dashboard.alert.notLightPoints'));
        }
    }

    @HostListener('window:unload')
    public beforeunloadHandler() {
        this.filterService.destroyAllFilter();
        this.filterService.destoryLocaleConfiniAmministrativi();
        this.filterService.destoryLocalePuntiStampa();
    }

    private disableAllbuttonExcept(keyButton: string): void {
        Object.keys(this.disableButton)
            .filter(key => key !== keyButton)
            .forEach(key => this.disableButton[key] = true);
    }

    public openRuler() {
        this.disableAllbuttonExcept('ruler');
        this.openedRuler = true;
        this.markerClickable = false;
    }

    public disableRuler() {
        this.resetAllButton();
        this.distances = [];
        this.distancess = undefined;
        this.markerClickable = true;
    }

    private ruler(coords) {
        const color = ['#FF0000', '#ff8c0b', '#fffa19', '#1b29ff'];
        const coordsL = {
            objectId: '1111',
            location: {latitude: coords.lat, longitude: coords.lng}
        };
        let distancess: [DistancesRuler[]] = arrayIsSet(this.distancess) ? [...this.distancess] : [] as any;

        if (this.distances.length > 0) {
            const colorIndex = (distancess.length - 1) % color.length;
            const start = this.distances[this.distances.length - 1].start;
            const end = coordsL.location;
            const middlePoint = this.midPoint(start, end);
            const polylineInfoWindow = {
                lat: middlePoint.lat(),
                lng: middlePoint.lng(),
                color: color[colorIndex],
                distance: this.getDistance(start, end),
                persistent: true
            };
            const newInterdistance: DistancesRuler = {
                objectId: coordsL.objectId,
                color: color[colorIndex],
                start: coordsL.location,
                polylineInfoWindow,
            };
            this.distances.push(newInterdistance);
        } else {
            const colorIndex = (isNotNullOrUndefined(distancess)) ? (distancess.length) % color.length : 0;
            const newInterdistance: DistancesRuler = {
                objectId: coordsL.objectId,
                color: color[colorIndex],
                start: coordsL.location,
                polylineInfoWindow: null,
            };
            this.distances.push(newInterdistance);
            if (isNotNullOrUndefined(distancess)) {
                distancess.push(this.distances);
            } else {
                distancess = [this.distances];
            }
        }
        this.distancess = distancess
        // this.forceRenderingMap();
    }


    private addElectricLine(coords, zoom) {
        if (zoom <= this.luxDataMapElementService.zoomTresholdGridToLine) {
            this.alertService.warning(this.translateService.instant('modalityActivedZoomLessThan'));
        } else {
            const color = this.lineaElettricaService.getColor(0);
            const line = this.lineaElettricaService.getLineMap(this.drawedElectricLines, coords, color);
            this.drawedElectricLines = line;
            this.forceRenderingMap();
        }

    }

    initRepositioningValue(): Observable<any> {
        this.valuesRepositoning = new Subject();
        return this.valuesRepositoning.asObservable();
    }

    destroyRepositioningValue() {
        if (this.valuesRepositoning != null) {
            this.loading.saveLine = true;
            this.valuesRepositoning.next({close: true});
            this.valuesRepositoning.complete();
        }
        this.destroyAddElectricLine();
        this.valuesRepositoning = undefined;
    }

    emitPointElectricLines() {
        if (this.repositioningElectricLines) {
            this.loading.saveLine = true;
            this.valuesRepositoning.next({action: 'save', locations: this.drawedElectricLines});
        } else if (this.openedDrawingElectricLine) {
            this.loading.saveLine = true;
            const locations = this.drawedElectricLines.map(point => point.start);
            this.lineaElettricaService.createNewElectricLines(
                this.formPage.get('addElectricLine').value,
                locations
            ).pipe(
                switchMap((lineaElettrica) => this.lineaElettricaService.getElectricLineMapByLineaElettrica([lineaElettrica])),
                map(linee => linee[0])
            )
                .subscribe(lineaElettrica => {
                    if (arrayIsSet(this.electricLinesOnMap)) {
                        this.electricLinesOnMap = this.electricLinesOnMap.concat([lineaElettrica]);
                    } else {
                        this.electricLinesOnMap = [lineaElettrica];
                    }
                    if (arrayIsSet(this.electricLines)) {
                        this.electricLines = this.electricLines.concat([lineaElettrica]);
                    } else {
                        this.electricLines = [lineaElettrica];
                    }
                    const salvato: string = this.translateService.instant('dashboard_sidenav.alert.formgroup.success');
                    this.alertService.success(salvato);
                    this.loading.saveLine = false;
                    this.openedDrawingElectricLine = false;
                    this.changeFilter.next({addElectricLines: [lineaElettrica]});
                    this.formPage.get('addElectricLine').reset();
                    this.openEditNewElectricLine(this.translateService.instant('dashboard_sidenav.LineaElettrica.continuosToCreateLine'));
                }, error => {
                    this.loading.saveLine = false;
                    this.openedDrawingElectricLine = false;
                    this.alertService.error(error);
                });
            this.drawedElectricLines = [];
        }
    }

    mapDoubleClick(event) {

        if (this.distances.length === 1) {
            this.distancess.splice(this.distancess.length - 1, 1);
        }
        this.distances = [];

    }

    private resetIconCircuit() {
        const circuits = this.circuits_v2.slice();
        circuits.forEach(
            circuito => {
                circuito.icon = this.scaleColorCircuiti.getIconCircuito(circuito);
            });
        this.circuits_v2 = circuits;
        this.clustersCircuits = this.createclustersCircuits(circuits);
    }

    cambioScalaColoreCircuito(campo) {
        const circuits = this.circuits_v2.slice();
        try {
            circuits.forEach(
                circuito => {
                    circuito.icon = this.scaleColorCircuiti.getIconCircuito(circuito);
                }
            );
        } catch (e) {
            this.resetIconCircuit();
            this.alertService.error(e.message);
        }
        this.circuits_v2 = circuits;
        this.clustersCircuits = this.createclustersCircuits(circuits);
    }

    labelCircuitiChange(event) {
        const color = (this.map.mapTypeId == 'roadmap') ? 'black' : 'white';
        this.circuits_v2.forEach(
            circuito => {
                circuito.labelMarker = this.circutiLabelService.getLabelMarker(circuito, color);
            }
        );
        this.circuits_v2 = this.circuits_v2.slice()
        this.forceRenderingMap();
    }


    private createclustersCircuits(circuiti: CircuitiParse[]) {
        const clusterCircuit = [];
        circuiti.forEach(circuito => {
            const index = clusterCircuit.findIndex(cluster => cluster.color == circuito.icon.strokeColor);
            if (index > -1) {
                clusterCircuit[index].element.push(circuito);
            } else {
                clusterCircuit.push({color: circuito.icon.strokeColor, element: [circuito]});
            }
        });
        return clusterCircuit;
    }

    /**
     * Aggiorna la data del service, che eseguirà nuova richiesta a Parse
     **/
    updateMonthYearForLuxDataClusters(event: any) {
        this.luxDataClusterService.updateMonthYear(event, this.map.zoom, this.map.getBounds());
    }


    private showDynamicPosition() {
        if (navigator.geolocation) {
            // this.myPosition.isEnabled = true
            this.istanzaWarchPosition = navigator.geolocation.watchPosition(
                (position) => {
                    if (!!position.coords.latitude && !!position.coords.longitude) {
                        const center = {
                            isEnabled: true,
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                            icon: this.myPosition.icon,
                        };
                        if (!isNotNullOrUndefined(this.myPosition.lat) || !isNotNullOrUndefined(this.myPosition.lng)) {
                            this.myPosition = center;

                        } else if ((this.myPosition.lat - center.lat) != 0 || (this.myPosition.lng - center.lng) != 0) {
                            this.myPosition = center;
                        }
                    }
                    this.myPosition.isEnabled = true;
                },
                (error) => {
                    if (error.code == error.PERMISSION_DENIED) {
                        this.alertService.warning(this.translateService.instant('newInstallation.refreshPageForConsens'));
                    } else {
                        this.alertService.warning(this.translateService.instant(error.message));
                    }
                    const center = {
                        isEnabled: false,
                        lat: null,
                        lng: null,
                        icon: this.myPosition.icon,
                    };
                    this.myPosition = center;
                },
                {
                    enableHighAccuracy: true,
                }
            );
        } else {
            const center = {
                isEnabled: false,
                lat: null,
                lng: null,
                icon: this.myPosition.icon,
            };
            this.myPosition = center;
            // Browser doesn't support Geolocation
            this.alertService.error(this.translateService.instant('newInstallation.unavaibleForThisBrowser'));
        }
    }

    openCloseGps() {
        if (this.myPosition.isEnabled) {
            navigator.geolocation.clearWatch(this.istanzaWarchPosition);
            this.myPosition = {
                isEnabled: false,
                icon: this.myPosition.icon,
                lat: null,
                lng: null
            };
        } else {
            this.showDynamicPosition();
        }
    }

    groupFileConnections() {
        let lightPoints;
        if (Array.isArray(this.reportsService.reportsInMap) && this.reportsService.reportsInMap.length > 0) {
            lightPoints = this.reportsService.reportsInMap.map((idLightPoint) => {
                const puntoLuce = new PuntiLuceParse();
                puntoLuce.objectId = idLightPoint;
                return puntoLuce;
            });
        } else {
            lightPoints = this.lightPoints;
        }
        this.loading.plusButton = true;
        let classNameSelected;
        let elements;
        this.dialogPopUpService.openDialogSelectElementToProceed({
            lightPoint: (arrayIsSet(lightPoints)) ? lightPoints.length <= 0 : true,
            circuits: (arrayIsSet(this.circuits)) ? this.circuits.length <= 0 : true,
            arredoUrbano: (arrayIsSet(this.arrediUrbaniOnMap)) ? this.arrediUrbaniOnMap.length <= 0 : true
        })
            .pipe(
                switchMap((nomeClasse) => {
                    if (!nomeClasse) {
                        this.loading.plusButton = false;
                        return EMPTY;
                    } else if (nomeClasse == className.circuiti) {
                        classNameSelected = className.circuiti;
                        elements = this.circuits;
                        return this.dialogPopUpService.openBatchEditConnectedFile(undefined, this.circuits, undefined);
                    } else if (nomeClasse == className.puntiLuce) {
                        classNameSelected = className.puntiLuce;
                        elements = lightPoints;
                        return this.dialogPopUpService.openBatchEditConnectedFile(lightPoints, undefined, undefined);
                    } else {
                        classNameSelected = className.arredoUrbano;
                        elements = this.arrediUrbaniOnMap;
                        return this.dialogPopUpService.openBatchEditConnectedFile(undefined, undefined, elements);
                    }
                }),
                switchMap(result => {
                    const saveFile = [];
                    if (!isNotNullOrUndefined(result) || (!arrayIsSet(result.newFilesToConnect) && !arrayIsSet(result.existedFilesToConnect))) {
                        this.loading.plusButton = false;
                        return EMPTY;
                    }
                    if (arrayIsSet(result.newFilesToConnect)) {
                        const files: File[] = result.newFilesToConnect
                        saveFile.push(
                            this.fileManagerService.createNewFilesPaginate(files, undefined, 1, elements)
                        );
                    }
                    if (arrayIsSet(result.existedFilesToConnect)) {
                        saveFile.push(this.addFilesToElements(classNameSelected, elements, result.existedFilesToConnect));
                    }
                    return forkJoin(saveFile);
                })
            )
            .subscribe(() => {
                    this.disableButton.fileConnections = false;
                    this.loading.plusButton = false;
                    const message = this.translateService.instant('save_success');
                    this.alertService.success(message);
                },
                error => {
                    this.disableButton.fileConnections = false;
                    this.loading.plusButton = false;
                    if (error.message.toLowerCase().includes('nome file')) {
                        const message = this.translateService.instant('fileManager.fileExistent');
                        this.alertService.warning(message);
                    } else {
                        this.alertService.error(error.message);
                    }
                });
    }

    openBatchFotoTipologia() {
        let editableLightPoints;
        if (Array.isArray(this.reportsService.reportsInMap) && this.reportsService.reportsInMap.length > 0) {
            editableLightPoints = this.lightPoints
                .filter(puntoLuce => this.reportsService.reportsInMap.includes(puntoLuce.objectId));
        } else {
            editableLightPoints = this.lightPoints;
        }
        if (arrayIsSet(editableLightPoints)) {

            const iconsLightPoints = editableLightPoints.reduce((prev, current) => {
                prev[current.objectId] = current.icon;
                return prev;
            }, {})
            this.fotoTipologiaService.getFotoTipologia$(undefined).pipe(
                switchMap(fotoTipologie => {
                    return this.dialogPopUpService.openBatchEditFotoTipologia(editableLightPoints, fotoTipologie)
                }),
                switchMap(fotoTipologia => {
                    if (fotoTipologia && fotoTipologia.removeFotoTipologia) {
                        return this.fotoTipologiaService.removeAssignedFotoPuntiLuceToPuntiLuce$(editableLightPoints.map(pl => pl.objectId)).pipe(
                            map(() => ({lightPoints: editableLightPoints, fotoTipologia: undefined}))
                        )
                    } else if (fotoTipologia && fotoTipologia.selected != null && stringIsSet(fotoTipologia.selected.objectId)) {
                        return this.fotoTipologiaService.assignFotoTipologiaToLightPoints$(editableLightPoints.map(pl => pl.objectId), fotoTipologia.selected.objectId).pipe(
                            map(() => ({lightPoints: editableLightPoints, fotoTipologia}))
                        )
                    } else {
                        return EMPTY;
                    }
                })
            ).subscribe(values => {
                if (values != null && arrayIsSet(values.lightPoints)) {
                    editableLightPoints.forEach(pl => {
                        if (values.fotoTipologia && values.fotoTipologia.selected != null) {
                            pl.fotoTipologia = values.fotoTipologia.selected
                        } else {
                            pl.fotoTipologia = undefined;
                        }
                        pl.icon = iconsLightPoints[pl.objectId];
                        const icon = this.scaleColorPuntiLuce.getIconPuntoLuce(pl);
                        pl.labelMarker = this.puntiLuceLabelService.getLabelMarker(pl, undefined);
                        pl.icon = icon;
                    });
                }
                this.puntiLuceLabelService.calcNumeroElementiPerValore(this.puntiLuceLabelService.campoLabel, this.lightPoints);
                setTimeout(() => {
                    const newValueIsPresent = this.scaleColorPuntiLuce.lightPointsContainedNewValue(editableLightPoints);
                    if (newValueIsPresent) {
                        this.scaleColorPuntiLuce.puntiLuceInMappa = this.lightPoints;
                        this.scaleColorPuntiLuce.aggiornaScalaColore();
                        this.cambiaColoreDelleIcone(null);
                    }
                    this.substitutionPuntiLuceInCluster(editableLightPoints);
                    this.updateColorArrayLightPoint(this.lightPoints);
                    this.clustersLightPoints = this.createClusterLightPoint(this.lightPoints);
                    this.lightPoints_v2 = this.lightPoints_v2;
                });
                this.alertService.success(this.translateService.instant('alert.success'))
            }, error => {
                this.alertService.error(error)
            })
        }
    }

    getSchedeManutenzione(type, forceRefresh = false): Observable<SchedaManutenzioneParse[]> {
        if (arrayIsSet(this.schedeManutenzioneDelProgetto[type]) && !forceRefresh) {
            return of(this.schedeManutenzioneDelProgetto[type]);
        } else {
            return this.maintenanceService.getAllSchedeManutenzioneByProjectAndOthers(null, {type});
        }
    }

    updateSchedeManutenzione(event) {
        const type = event.typeSchedaManutenzione;
        const elementoDaAggiornare = event.elemento;
        this.getSchedeManutenzione(type, false).pipe(
            switchMap(schede => {
                const schedeGiaAssociate = (arrayIsSet(this.elementoCliccato.schedeManutenzione)) ? this.elementoCliccato.schedeManutenzione : [];
                if (arrayIsSet(schede)) {
                    return this.dialogPopUpService.openAddAssignSchedaManutenzione(schede, schedeGiaAssociate);
                } else {
                    return this.alertService.warning(this.translateService.instant('alert.notElementToVisualized'));
                }
            }),
            switchMap((schedeManutenzione) => {
                const schedeManutenzioneDaAggiungere = (typeof schedeManutenzione == 'object' && schedeManutenzione.hasOwnProperty('selectedSchedeManutenzione')) ? schedeManutenzione.selectedSchedeManutenzione : [];
                const calendariDaCreare = (typeof schedeManutenzione == 'object' && schedeManutenzione.hasOwnProperty('calendariDaCreare')) ? schedeManutenzione.calendariDaCreare : [];
                if (!arrayIsSet(schedeManutenzioneDaAggiungere) && !arrayIsSet(calendariDaCreare)) {
                    return EMPTY;
                } else {
                    const obs$ = [];
                    if (arrayIsSet(schedeManutenzioneDaAggiungere)) {
                        if (!arrayIsSet(elementoDaAggiornare.schedeManutenzione)) {
                            elementoDaAggiornare.schedeManutenzione = schedeManutenzioneDaAggiungere;
                        } else {
                            schedeManutenzioneDaAggiungere.forEach(schedaDaAggiungere => {
                                const item = getItemInArrayByKeyValue(elementoDaAggiornare.schedeManutenzione, schedaDaAggiungere.objectId, 'objectId');
                                if (!isNotNullOrUndefined(item)) {
                                    elementoDaAggiornare.schedeManutenzione.push(schedaDaAggiungere);
                                }
                            });
                        }
                        obs$.push(this.mapService.updateAll(elementoDaAggiornare, 100)
                            .pipe(
                                map(elementoDaAggiornare => {
                                    return {key: 'schedeManutenzione', finished: true, item: elementoDaAggiornare};
                                })
                            )
                        );

                    } else {
                        obs$.push(of({finished: true}));
                    }


                    if (arrayIsSet(calendariDaCreare)) {

                        let circuitiIds;
                        let puntiLuceIds;
                        if (elementoDaAggiornare.className == this.nomeClassi.circuiti) {
                            circuitiIds = [elementoDaAggiornare.objectId];
                        } else if (elementoDaAggiornare.className == this.nomeClassi.puntiLuce) {
                            puntiLuceIds = [elementoDaAggiornare.objectId];
                        }

                        const calendari = (calendariDaCreare as {
                            maintenanceDate: Date,
                            schedaDiManutenzione: SchedaManutenzioneParse
                        }[]).map(calendrio => {
                            return {
                                maintenanceDate: calendrio.maintenanceDate,
                                schedaManutenzioneId: calendrio.schedaDiManutenzione.objectId,
                                puntiLuceIds,
                                circuitiIds
                            };
                        });
                        obs$.push(
                            this.calendarioManutenzioneService.createCalendariManutenzione(calendari)
                                .pipe(
                                    map(calendario => {
                                        const obj = {
                                            key: 'calendarioManutenzione',
                                            progress: calendario.progress,
                                            finished: calendario.finished,
                                            item: calendario.item
                                        };
                                        return obj;
                                    })
                                )
                        );
                    } else {
                        obs$.push(of({finished: true}));
                    }
                    return combineLatest(obs$);
                }
            })
        ).subscribe(results => {
                const isAllFinished = !(results.findIndex((result: any) => result.finished == false) >= 0);
                if (isAllFinished) {
                    const message = this.translateService.instant('save_success');
                    this.alertService.success(message);
                    const index = results.findIndex((result: any) => result.key && result.key == 'schedeManutenzione');
                    if (index >= 0 && (results as any)[index].item && (results as any)[index].item.schedeManutenzione) {
                        this.elementoCliccato.schedeManutenzione = [...(results as any)[index].item.schedeManutenzione];
                    }
                    this.forceUpdateDashboardSidenav = !this.forceUpdateDashboardSidenav;
                }

            },
            error => {
                this.alertService.error(error.message);
            });
    }

    groupAssignSchedaManutenzione() {
        let lightPoints;
        if (Array.isArray(this.reportsService.reportsInMap) && this.reportsService.reportsInMap.length > 0) {
            lightPoints = this.reportsService.reportsInMap.map((idLightPoint) => {
                const puntoLuce = new PuntiLuceParse();
                puntoLuce.objectId = idLightPoint;
                return puntoLuce;
            });
        } else {
            lightPoints = this.lightPoints;
        }
        this.loading.plusButton = true;
        let classNameSelected;
        let elements;
        let type;
        this.dialogPopUpService.openDialogSelectElementToProceed({
            lightPoint: (arrayIsSet(lightPoints)) ? lightPoints.length <= 0 : true,
            circuits: (arrayIsSet(this.circuits)) ? this.circuits.length <= 0 : true,
            arredoUrbano: true
        }).pipe(
            switchMap((nomeClasse) => {
                if (!nomeClasse) {
                    this.loading.plusButton = false;
                    return EMPTY;
                } else if (nomeClasse == className.circuiti) {
                    classNameSelected = className.circuiti;
                    elements = this.circuits;
                    type = typeElementScheduleMaintence.Circuiti;
                } else if (nomeClasse == className.puntiLuce) {
                    classNameSelected = className.puntiLuce;
                    elements = lightPoints;
                    type = typeElementScheduleMaintence.PuntiLuce;
                } else {
                    classNameSelected = className.arredoUrbano;
                    elements = this.arrediUrbaniOnMap;
                    type = null;
                }
                return this.getSchedeManutenzione(type, false);
            }),
            switchMap((schedeManutenzione) => {
                this.schedeManutenzioneDelProgetto[type] = schedeManutenzione;
                if (classNameSelected === className.circuiti) {
                    return this.dialogPopUpService.openBatchEditAssignSchedaManutenzione(undefined, this.circuits, undefined, this.schedeManutenzioneDelProgetto[type], this.dialogPopUpService);
                } else if (classNameSelected === className.puntiLuce) {
                    return this.dialogPopUpService.openBatchEditAssignSchedaManutenzione(lightPoints, undefined, undefined, this.schedeManutenzioneDelProgetto[type], this.dialogPopUpService);
                } else {
                    return this.dialogPopUpService.openBatchEditAssignSchedaManutenzione(undefined, undefined, this.arrediUrbaniOnMap, this.schedeManutenzioneDelProgetto[type], this.dialogPopUpService);
                }
            }),
            switchMap(schedeManutenzione => {
                const elementsToSave = [];
                const toSave$ = [];

                const isSet = (key: 'selectedSchedeManutenzione' | 'calendariDaCreare') => {
                    return (isNotNullOrUndefined(schedeManutenzione) && isNotNullOrUndefined(schedeManutenzione[key]) && arrayIsSet(schedeManutenzione[key].items));
                };
                if (!isNotNullOrUndefined(schedeManutenzione) ||
                    (!arrayIsSet(elements) ||
                        !isSet('selectedSchedeManutenzione') && !isSet('calendariDaCreare')
                    )
                ) {
                    this.loading.plusButton = false;
                    return EMPTY;
                } else {
                    if (isNotNullOrUndefined(schedeManutenzione) && !schedeManutenzione.selectedSchedeManutenzione.remove) {
                        elements.forEach(element => {
                            if (arrayIsSet(element.schedeManutenzione)) {
                                schedeManutenzione.selectedSchedeManutenzione.items.forEach(schedaDaAggiungere => {
                                    const isPresent = isNotNullOrUndefined(getItemInArrayByKeyValue(element.schedeManutenzione, schedaDaAggiungere.objectId, 'objectId'));
                                    if (!isPresent) {
                                        element.schedeManutenzione.push(schedaDaAggiungere);
                                        const isPresentElement = isNotNullOrUndefined(getItemInArrayByKeyValue(elementsToSave, element.objectId, 'objectId'));
                                        if (!isPresentElement) {
                                            elementsToSave.push(element);
                                        }
                                    }
                                });
                            } else {
                                element.schedeManutenzione = schedeManutenzione.selectedSchedeManutenzione.items;
                                const isPresentElement = isNotNullOrUndefined(getItemInArrayByKeyValue(elementsToSave, element.objectId, 'objectId'));
                                if (!isPresentElement) {
                                    elementsToSave.push(element);
                                }
                            }
                        });
                    } else if (isNotNullOrUndefined(schedeManutenzione) && schedeManutenzione.selectedSchedeManutenzione.remove) {
                        elements.forEach(element => {
                            if (arrayIsSet(element.schedeManutenzione)) {
                                schedeManutenzione.selectedSchedeManutenzione.items.forEach(schedaDaRimuovere => {
                                    if (arrayIsSet(element.schedeManutenzione)) {
                                        const isPresent = isNotNullOrUndefined(getItemInArrayByKeyValue(element.schedeManutenzione, schedaDaRimuovere.objectId, 'objectId'));
                                        if (isPresent) {
                                            const schedeManutenzione = getArrayToRemveItem(element.schedeManutenzione, schedaDaRimuovere.objectId, 'objectId');
                                            if (arrayIsSet(schedeManutenzione)) {
                                                element.schedeManutenzione = schedeManutenzione;
                                            } else {
                                                element.unset('schedeManutenzione');
                                            }
                                            const isPresentElement = isNotNullOrUndefined(getItemInArrayByKeyValue(elementsToSave, element.objectId, 'objectId'));
                                            if (!isPresentElement) {
                                                elementsToSave.push(element);
                                            }
                                        }
                                    }
                                });
                            }
                        });
                    }
                    if (isSet('calendariDaCreare')) {
                        let puntiLuceIds;
                        let circuitiIds;
                        if (type == typeElementScheduleMaintence.PuntiLuce) {
                            puntiLuceIds = elements.map(element => element.objectId);
                        } else if (type == typeElementScheduleMaintence.Circuiti) {
                            circuitiIds = elements.map(element => element.objectId);
                        }
                        const calendari = schedeManutenzione.calendariDaCreare.items.map(calendario => {
                            return {
                                maintenanceDate: calendario.maintenanceDate,
                                schedaManutenzioneId: calendario.schedaManutenzione.objectId,
                                puntiLuceIds,
                                circuitiIds
                            };
                        });
                        toSave$.push(
                            this.calendarioManutenzioneService.createCalendariManutenzione(calendari)
                                .pipe(
                                    map(calendario => {
                                        const obj = {
                                            key: 'calendarioManutenzione',
                                            progress: calendario.progress,
                                            finished: calendario.finished,
                                            item: calendario.item
                                        };
                                        return obj;
                                    })
                                )
                        );
                        // this.calendarioManutenzioneService.createCalendariManutenzione(calendari);
                        // console.log(schedeManutenzione.calendariDaCreare.items)
                    } else {
                        toSave$.push(of({key: 'calendarioManutenzione', finished: true}));
                    }


                }
                if (arrayIsSet(elementsToSave)) {
                    toSave$.push(
                        this.mapService.paginateUpdateElements(elementsToSave, 50, classNameSelected)
                            .pipe(
                                map(element => {
                                    const obj = {
                                        key: classNameSelected,
                                        progress: element.progress,
                                        finished: element.finished,
                                        item: element[classNameSelected]
                                    };
                                    return obj;
                                })
                            )
                    );
                } else {
                    toSave$.push(of({key: classNameSelected, finished: true}));
                }
                return combineLatest(toSave$);
            })
        ).subscribe(results => {
                const isAllFinished = !(results.findIndex((result: any) => result.finished == false) >= 0);
                if (isAllFinished) {
                    this.loading.plusButton = false;
                    const message = this.translateService.instant('save_success');
                    this.alertService.success(message);
                }
            },
            error => {
                this.loading.plusButton = false;
                console.error(error);
                this.alertService.error(error.message);
            });
    }


    createFilesAndAddToElements(nomeClasse, elements: [], files) {
        if (nomeClasse == className.puntiLuce) {

            return this.fileManagerService.createNewFiles(files, undefined, undefined, undefined, undefined).pipe(
                switchMap((fileSaved) => {
                    return this.mapService.addFilesRelationLightPoints(elements, fileSaved);
                })
            );
        } else if (nomeClasse == className.circuiti) {
            return this.fileManagerService.createNewFiles(files, undefined, undefined, undefined, undefined).pipe(
                switchMap((fileSaved) => {
                    return this.mapService.addFilesRelationCircuits(elements, fileSaved);
                })
            );
        } else if (nomeClasse == className.arredoUrbano) {
            return this.fileManagerService.createNewFiles(files, undefined, undefined, undefined, undefined).pipe(
                switchMap((fileSaved) => {
                    return this.arredoUrbanoUtilsService.addFilesRelationArrediurbani(elements, fileSaved);
                })
            );
        }
    }

    addFilesToElements(nomeClasse, elements: [], files: DocumentsFileParse[]) {
        if (nomeClasse == className.puntiLuce) {
            return this.mapService.addFilesRelationLightPoints(elements, files);
        } else if (nomeClasse == className.circuiti) {
            return this.mapService.addFilesRelationCircuits(elements, files);
        } else if (nomeClasse == className.arredoUrbano) {
            return this.arredoUrbanoUtilsService.addFilesRelationArrediurbani(elements, files);
        }
    }

    public getDifferenceWithFirstArray(arr1: string[], arr2: string[]) {
        return arr1.filter((idSegnalazione) => !arr2.includes(idSegnalazione));
    }

    scheduleMaintenanceChangeValue(event: any) {
        setTimeout(() => {
            if (event.active.value) {
                if (event.active.changed) {
                    this.clustersCircuits = [];
                    this.circuits_v2 = [];
                    this.clustersLightPoints = [];
                    this.lightPoints_v2 = [];
                    this.arrediUrbaniOnMap = [];
                    this.clusterCalendariManutenzioneSelezionato = undefined;
                }
                if (this.sidenavStart.opened) {
                    this.sidenavStart.close();
                }
                this.currentViewService.activeModalitaManutenzione();
            } else {
                if (event.active.changed) {
                    this.clustersCircuits = [];
                    this.circuits_v2 = [];
                    this.clustersLightPoints = [];
                    this.lightPoints_v2 = [];
                    this.arrediUrbaniOnMap = [];
                    this.clusterCalendariManutenzioneSelezionato = undefined;
                }
                this.currentViewService.disActiveModalitaManutenzione();
            }
            if (event.values && event.values.month && event.values.year && (event.values.month.changed || event.values.year.changed)) {
                const day = new Date();
                day.setDate(1);
                day.setMonth(event.values.month.value - 1);
                day.setFullYear(event.values.year.value);
                day.setHours(0, 0, 0, 0);
                this.updateCalendariManutenzione(day.getTime());
            }
            let rangeDate
            if (event.values && event.values.rangeDate && event.values.rangeDate.fromDate && event.values.rangeDate.toDate) {
                rangeDate = {
                    fromDate: event.values.rangeDate.fromDate.value,
                    toDate: event.values.rangeDate.toDate.value
                }
                if ((event.values.rangeDate.fromDate.changed || event.values.rangeDate.toDate.changed)) {
                    this.updateCalendariManutenzione(undefined, rangeDate);
                }
            }

            if (this.isActiveModalitaManutenzione) {
                let schedeManutenzione$ = of({
                    add: {puntiLuce: undefined, circuiti: undefined},
                    remove: {puntiLuce: undefined, circuiti: undefined}
                });
                // console.log(event.calendarsActive)
                if (event.calendarsActive && event.calendarsActive.changed) {
                    const circuitiSchedeManutenzione = [];
                    const puntiLuceSchedeManutenzione = [];
                    this.clusterCalendariManutenzioneSelezionato = event.calendarsActive.value;
                    if (arrayIsSet(event.calendarsActive.value)) {
                        event.calendarsActive.value.forEach(item => {
                            if (arrayIsSet(item.calendariManutenzione)) {
                                item.calendariManutenzione.forEach(calendario => {
                                    if (isNotNullOrUndefined(calendario.circuito)) {
                                        const circuito = calendario.circuito;
                                        if (!isNotNullOrUndefined(getItemInArrayByKeyValue(circuitiSchedeManutenzione, circuito.objectId, 'objectId'))) {
                                            circuitiSchedeManutenzione.push(circuito);
                                        }
                                    } else if (isNotNullOrUndefined(calendario.puntoLuce)) {
                                        const puntoLuce = calendario.puntoLuce;
                                        if (!isNotNullOrUndefined(getItemInArrayByKeyValue(puntiLuceSchedeManutenzione, puntoLuce.objectId, 'objectId'))) {
                                            puntiLuceSchedeManutenzione.push(puntoLuce);
                                        }
                                    }
                                });
                            }
                        });
                    }


                    const obj = {
                        add: {circuiti: undefined, puntiLuce: undefined},
                        remove: {circuiti: undefined, puntiLuce: undefined}
                    };
                    if (arrayIsSet(circuitiSchedeManutenzione)) {
                        obj.add.circuiti = circuitiSchedeManutenzione;
                        this.objectIdSchedeManutenzioneOnMap.circuiti = this.objectIdSchedeManutenzioneOnMap.circuiti
                            .concat(
                                circuitiSchedeManutenzione
                                    .map(circuito => circuito.objectId)
                                    .filter(id => !this.objectIdSchedeManutenzioneOnMap.circuiti.includes(id))
                            );
                    } else {
                        obj.remove.circuiti = this.getDifferenceWithFirstArray(this.objectIdSchedeManutenzioneOnMap.circuiti, this.objectIdSegnalazioniOnMap.circuiti);
                        this.objectIdSchedeManutenzioneOnMap.circuiti = [];
                    }

                    if (arrayIsSet(puntiLuceSchedeManutenzione)) {
                        obj.add.puntiLuce = puntiLuceSchedeManutenzione;
                        const difference = this.objectIdSchedeManutenzioneOnMap.puntiLuce
                            .concat(
                                puntiLuceSchedeManutenzione
                                    .map(puntoLuce => puntoLuce.objectId)
                                    .filter(id => !this.objectIdSchedeManutenzioneOnMap.puntiLuce.includes(id))
                            );
                        this.objectIdSchedeManutenzioneOnMap.puntiLuce = difference;
                        obj.remove.puntiLuce = this.getDifferenceWithFirstArray(this.objectIdSchedeManutenzioneOnMap.puntiLuce, puntiLuceSchedeManutenzione.map(puntiLuce => puntiLuce.objectId));
                    } else {
                        obj.remove.puntiLuce = this.getDifferenceWithFirstArray(this.objectIdSchedeManutenzioneOnMap.puntiLuce, this.objectIdSegnalazioniOnMap.puntiLuce);
                        this.objectIdSchedeManutenzioneOnMap.puntiLuce = [];
                    }
                    schedeManutenzione$ = of(obj);

                }
                // console.log(event.values.visualizedSegnalzioni)
                let segnalazioni$ = of({
                    add: {puntiLuce: undefined, circuiti: undefined},
                    remove: {circuiti: undefined, puntiLuce: undefined}
                });
                if (event.values.visualizedSegnalzioni) {
                    if (event.values.visualizedSegnalzioni && event.values.visualizedSegnalzioni.value) {
                        if (event.values.prioritySegnalzioni != null) {
                            this.defaultValue.maintenanceComponent.prioritySegnalzioni = event.values.prioritySegnalzioni.value;
                        }
                        segnalazioni$ = this.getSegnalazioniInProject(this.defaultValue.maintenanceComponent.prioritySegnalzioni, false, rangeDate)
                            .pipe(
                                catchError(error => {
                                    this.alertService.error(error);
                                    return of([])
                                }),
                                map(segnalazioni => {
                                    this.segnalazioniAperteOnMap = segnalazioni;
                                    const circuitiSegnalazioni = [];
                                    const puntiLuceSegnalazioni = [];
                                    segnalazioni.forEach(segnalazione => {
                                        if (isNotNullOrUndefined(segnalazione.circuito)) {
                                            const circuito = segnalazione.circuito;
                                            if (!isNotNullOrUndefined(getItemInArrayByKeyValue(circuitiSegnalazioni, circuito.objectId, 'objectId'))) {
                                                circuitiSegnalazioni.push(circuito);
                                            }
                                        }
                                        if (arrayIsSet(segnalazione.puntiLuce)) {
                                            const puntiLuce = segnalazione.puntiLuce;
                                            puntiLuce.forEach(puntoLuce => {
                                                if (!isNotNullOrUndefined(getItemInArrayByKeyValue(puntiLuceSegnalazioni, puntoLuce.objectId, 'objectId'))) {
                                                    puntiLuceSegnalazioni.push(puntoLuce);
                                                }
                                            });
                                        }
                                    });
                                    const obj = {
                                        add: {circuiti: undefined, puntiLuce: undefined},
                                        remove: {circuiti: undefined, puntiLuce: undefined}
                                    };
                                    if (arrayIsSet(circuitiSegnalazioni)) {
                                        obj.add.circuiti = circuitiSegnalazioni;
                                        this.objectIdSegnalazioniOnMap.circuiti = this.objectIdSegnalazioniOnMap.circuiti
                                            .concat(
                                                circuitiSegnalazioni
                                                    .map(circuito => circuito.objectId)
                                                    .filter(id => !this.objectIdSegnalazioniOnMap.circuiti.includes(id))
                                            );
                                        const circuitiInMapSegnalazioni = this.getDifferenceWithFirstArray(this.objectIdSegnalazioniOnMap.circuiti, this.objectIdSchedeManutenzioneOnMap.circuiti);
                                        const idDaVisualizzare = circuitiSegnalazioni.map(ci => ci.objectId);
                                        const remove = circuitiInMapSegnalazioni.filter(id => {
                                            return !idDaVisualizzare.includes(id);
                                        });
                                        obj.remove.circuiti = remove;
                                    } else {
                                        obj.remove.circuiti = this.getDifferenceWithFirstArray(this.objectIdSegnalazioniOnMap.circuiti, this.objectIdSchedeManutenzioneOnMap.circuiti);
                                        this.objectIdSegnalazioniOnMap.circuiti = [];
                                    }
                                    if (arrayIsSet(puntiLuceSegnalazioni)) {
                                        obj.add.puntiLuce = puntiLuceSegnalazioni;
                                        this.objectIdSegnalazioniOnMap.puntiLuce = this.objectIdSegnalazioniOnMap.puntiLuce
                                            .concat(
                                                puntiLuceSegnalazioni
                                                    .map(puntoLuce => puntoLuce.objectId)
                                                    .filter(id => !this.objectIdSegnalazioniOnMap.puntiLuce.includes(id))
                                            );

                                        const puntiLuceInMapSegnalazioni = this.getDifferenceWithFirstArray(this.objectIdSegnalazioniOnMap.puntiLuce, this.objectIdSchedeManutenzioneOnMap.puntiLuce);
                                        const idDaVisualizzare = puntiLuceSegnalazioni.map(pl => pl.objectId);
                                        const remove = puntiLuceInMapSegnalazioni.filter(id => {
                                            return !idDaVisualizzare.includes(id);
                                        });
                                        obj.remove.puntiLuce = remove;
                                    } else {
                                        obj.remove.puntiLuce = this.getDifferenceWithFirstArray(this.objectIdSegnalazioniOnMap.puntiLuce, this.objectIdSchedeManutenzioneOnMap.puntiLuce);
                                        this.objectIdSegnalazioniOnMap.puntiLuce = [];
                                    }
                                    return obj;
                                }),
                            );

                    } else {
                        const removeIdsCircuiti = this.getDifferenceWithFirstArray(this.objectIdSegnalazioniOnMap.circuiti, this.objectIdSchedeManutenzioneOnMap.circuiti);
                        const removeIdsPuntiLuce = this.getDifferenceWithFirstArray(this.objectIdSegnalazioniOnMap.puntiLuce, this.objectIdSchedeManutenzioneOnMap.puntiLuce);
                        // this.removeCircuiti(removeIds);
                        const obj = {
                            add: {circuiti: undefined, puntiLuce: undefined},
                            remove: {circuiti: undefined, puntiLuce: undefined}
                        };
                        obj.remove.circuiti = removeIdsCircuiti;
                        obj.remove.puntiLuce = removeIdsPuntiLuce;
                        segnalazioni$ = of(obj);
                        this.segnalazioniAperteOnMap = undefined;
                    }
                }

                combineLatest([schedeManutenzione$, segnalazioni$])
                    .subscribe(
                        (circuitiPuntiLuce) => {
                            const circuitiAdd = [];
                            const circuitiremove = [];
                            const puntiLuceAdd = [];
                            const puntiLuceRemove = [];

                            circuitiPuntiLuce.forEach(circuitiPuntiLuceAddRemove => {
                                if (arrayIsSet(circuitiPuntiLuceAddRemove.add.puntiLuce)) {
                                    circuitiPuntiLuceAddRemove.add.puntiLuce.forEach(puntoLuce => {
                                        if (!getItemInArrayByKeyValue(puntiLuceAdd, puntoLuce, 'objectId')) {
                                            puntiLuceAdd.push(puntoLuce);
                                        }
                                    });
                                }
                                if (arrayIsSet(circuitiPuntiLuceAddRemove.remove.puntiLuce)) {
                                    circuitiPuntiLuceAddRemove.remove.puntiLuce.forEach(puntoLuce => {
                                        if (!getItemInArrayByKeyValue(puntiLuceRemove, puntoLuce, 'objectId')) {
                                            puntiLuceRemove.push(puntoLuce);
                                        }
                                    });
                                }
                                if (arrayIsSet(circuitiPuntiLuceAddRemove.add.circuiti)) {
                                    circuitiPuntiLuceAddRemove.add.circuiti.forEach(circuito => {
                                        if (!getItemInArrayByKeyValue(circuitiAdd, circuito, 'objectId')) {
                                            circuitiAdd.push(circuito);
                                        }
                                    });
                                }
                                if (arrayIsSet(circuitiPuntiLuceAddRemove.remove.circuiti)) {
                                    circuitiPuntiLuceAddRemove.remove.circuiti.forEach(circuito => {
                                        if (!getItemInArrayByKeyValue(circuitiremove, circuito, 'objectId')) {
                                            circuitiremove.push(circuito);
                                        }
                                    });
                                }
                            });

                            if (arrayIsSet(circuitiAdd)) {
                                const color = (isNotNullOrUndefined(this.map) && this.map.mapTypeId == 'roadmap') ? 'black' : 'white';
                                this.addCircuitiInCluster(circuitiAdd.map(circuito => {
                                    circuito.labelMarker = this.circutiLabelService.getLabelMarker(circuito, color);
                                    circuito.icon = this.scaleColorCircuiti.getIconCircuito(circuito);
                                    return circuito;
                                }));
                                if (this.scaleColorCircuiti.circuitiContainedNewValue(circuitiAdd)) {
                                    this.scaleColorCircuiti.circuitiInMappa = this.circuits;
                                    this.scaleColorCircuiti.aggiornaScalaColore();
                                    this.cambioScalaColoreCircuito(null);
                                }
                            }
                            if (arrayIsSet(circuitiremove)) {
                                this.removeCircuiti(circuitiremove);
                            }
                            if (arrayIsSet(puntiLuceAdd)) {
                                this.fetchPuntiLuce$(puntiLuceAdd)
                                    .pipe(
                                        switchMap((puntiLuce) => {
                                            if (arrayIsSet(puntiLuce)) {
                                                return this.puntiLuceService.getIconForLigthPoint(puntiLuce);
                                            } else {
                                                return of([]);
                                            }
                                        }),
                                        map((puntiLuce) => {
                                            const puntiLuceWidthIcon: PuntiLuceParse[] = [];
                                            puntiLuce.forEach((puntoLuce) => {
                                                const icon = this.scaleColorPuntiLuce.getIconPuntoLuce(puntoLuce);
                                                puntoLuce.icon = icon;
                                                puntiLuceWidthIcon.push(puntoLuce);
                                            });
                                            return puntiLuceWidthIcon;
                                        })
                                    )
                                    .subscribe(puntiLuce => {
                                        this.addPuntiLuceInCluster(puntiLuce);
                                        const newValueIsPresent = this.scaleColorPuntiLuce.lightPointsContainedNewValue(puntiLuce);
                                        if (newValueIsPresent) {
                                            this.scaleColorPuntiLuce.puntiLuceInMappa = this.lightPoints;
                                            this.scaleColorPuntiLuce.aggiornaScalaColore();
                                            this.cambiaColoreDelleIcone(null);
                                        }
                                        this.puntiLuceLabelService.createValueHtml(this.lightPoints);
                                        this.clustersLightPoints = this.clustersLightPoints.slice();
                                        this.lightPoints_v2 = this.lightPoints_v2.slice();
                                    });
                            }
                            if (arrayIsSet(puntiLuceRemove)) {
                                this.removeLightPoints(puntiLuceRemove);
                            }
                            this.clustersLightPoints = this.clustersLightPoints.slice();
                            this.lightPoints_v2 = this.lightPoints_v2.slice();
                            this.clustersCircuits = this.clustersCircuits.slice();
                            this.circuits_v2 = this.circuits_v2.slice()
                            this.forceRenderingMap();
                        }
                    );

            }
        });
    }

    saveSchedaManutenzione(event: {
        elementToSave: any,
        valuesSchedaManutenzione: any,
        keys: formFiledMaintenanceFormContolName[],
        maintenanceScheduleReference: SchedaManutenzioneParse
    }) {
        const parseElement = event.elementToSave;
        const valueForm = event.valuesSchedaManutenzione;
        const keys = event.keys;
        const schedaManutenzione = event.maintenanceScheduleReference;
        const valueFormCasted = this.maintenanceService.getCastValueToSaveByKeys(valueForm, keys)
        this.maintenanceService.createSchedaManutenzioneCompilataCloud(schedaManutenzione.objectId, valueFormCasted, parseElement).subscribe(
            scheda => {
                if (arrayIsSet(this.clusterCalendariManutenzioneSelezionato)) {
                    this.clusterCalendariManutenzioneSelezionato.forEach(cluster => {

                        cluster.calendariManutenzione = cluster.calendariManutenzione.filter(calendario => {
                            let element;
                            if (calendario.circuito != null) {
                                element = calendario.circuito;
                            } else if (calendario.puntoLuce != null) {
                                element = calendario.puntoLuce;
                            }
                            return !(calendario.schedaManutenzione.objectId === schedaManutenzione.objectId && (element != null && element.objectId === parseElement.objectId));
                        });
                    });
                    this.clusterCalendariManutenzioneSelezionato = [...this.clusterCalendariManutenzioneSelezionato];
                }
                if (arrayIsSet(this.schedeCompilateByElement)) {
                    this.schedeCompilateByElement.push(scheda);
                } else {
                    this.schedeCompilateByElement = [scheda];
                }
                this.maintenanceService.destroyLocalSchedaPrecompilata(schedaManutenzione.objectId, parseElement);
                this.saveSchedaCompilata.next({schedaManutenzione: true});
                const message = this.translateService.instant('save_success');
                this.alertService.success(message);
            }, error => {
                this.saveSchedaCompilata.next({schedaManutenzione: {error: true}});
                this.alertService.error(error);
            }
        );
    }


    getKeyPriorityByValue(value: string) {
        if (value === Priority.ALTA) {
            return 2;
        } else if (value === Priority.MEDIA) {
            return 1;
        } else {
            return 0;
        }
    }


    private getSegnalazioneWithPresoInCaricoDetail(segnalazioni: SegnalazioniParse[]): Observable<SegnalazioniParse[]> {
        const userIdInSegnalazioni = getUniqueValueInArray(
            segnalazioni
                .filter(segnalazione => segnalazione.presaInCaricoDa && segnalazione.presaInCaricoDa.id)
                .map(segnalazione => segnalazione.presaInCaricoDa.id)
        );
        if (arrayIsSet(userIdInSegnalazioni)) {
            return this.mapService.getUserDetail(userIdInSegnalazioni)
                .pipe(
                    map(users => {
                        users.forEach(user => {
                            const userId = user.id;
                            const index = segnalazioni.findIndex(segnalazione => isNotNullOrUndefined(segnalazione.presaInCaricoDa) && segnalazione.presaInCaricoDa.id == userId);
                            if (index >= 0) {
                                segnalazioni[index].presaInCaricaDetail = user;
                            }
                        });
                        return segnalazioni;
                    })
                );
        } else {
            return of(segnalazioni);
        }


    }

    public getSegnalazioniInProject(allPriority: string[] = undefined, forceReload: boolean = false, rangeDate: {
                                        fromDate: Date,
                                        toDate: Date
                                    }
    ): Observable<SegnalazioniParse[]> {
        const keySegnalazioniToLocal = [];
        const keySegnalazioniToRequestServer = [];
        if (allPriority == null || rangeDate == null) {
            return of([]);
        }
        const getKey = (priority) => {
            if (rangeDate != null) {
                return rangeDate.fromDate.getTime().toString(32) + rangeDate.toDate.getTime().toString(32) + this.getKeyPriorityByValue(priority)
            } else {
                return this.getKeyPriorityByValue(priority)
            }
        }
        allPriority = (arrayIsSet(allPriority)) ? allPriority : Object.values(Priority);
        allPriority.forEach(priority => {
            if (arrayIsSet(this.segnalazioniInProjects[getKey(priority)])) {
                keySegnalazioniToLocal.push(getKey(priority));
            } else {
                keySegnalazioniToRequestServer.push(this.getKeyPriorityByValue(priority));
            }
        });
        let segnalazioni$: Observable<SegnalazioniParse[]>[] = [];
        segnalazioni$ = segnalazioni$.concat(keySegnalazioniToLocal.map(key => of(this.segnalazioniInProjects[key])));
        if (arrayIsSet(keySegnalazioniToRequestServer)) {
            segnalazioni$.push(
                this.maintenanceService.getSegnalazioni(keySegnalazioniToRequestServer, rangeDate).pipe(
                    switchMap(segnalazioni => {
                        return this.getSegnalazioneWithPresoInCaricoDetail(segnalazioni);
                    }),
                )
            );
        }
        return forkJoin(segnalazioni$).pipe(
            map((segnalazioni) => {
                const allSegnalazioni: SegnalazioniParse[] = [].concat.apply([], segnalazioni);
                Object.values(Priority).forEach(key => {
                    this.segnalazioniInProjects[getKey(key)] = allSegnalazioni.filter((segnalazione) => segnalazione.priorita == this.getKeyPriorityByValue(key));
                });
                return allSegnalazioni;
            })
        );
    }

    clickAssignToInSegnalazione(event) {
        // const users = [this.userService.currentUser];
        const getValue = (user, key) => {
            let value;
            if (key === 'organizzazione') {
                value = (user.get('organizzazione')) ? user.get('organizzazione').nome : null;
            } else if (key == 'objectId') {
                value = user.id;
            } else if (key == 'role') {
                return user.role;
            } else {
                value = user.get(key);
            }
            return value;
        };
        const getId = (item) => item.id;
        const usersIdInstallatori = [];
        const usersIdOperatori = [];
        const usersIdGestori = [];
        const project = this.projectService.actualProject;
        if (arrayIsSet(project.installatori)) {
            project.installatori.forEach(user => {
                const id = getId(user);
                if (!isNotNullOrUndefined(getItemInArrayByKeyValue(usersIdInstallatori, id))) {
                    usersIdInstallatori.push(id);
                }
            });
        }
        if (arrayIsSet(project.operatori)) {
            project.operatori.forEach(user => {
                const id = getId(user);
                if (!isNotNullOrUndefined(getItemInArrayByKeyValue(usersIdOperatori, id))) {
                    usersIdOperatori.push(id);
                }
            });
        }
        if (arrayIsSet(project.gestori)) {
            project.gestori.forEach(user => {
                const id = getId(user);
                if (!isNotNullOrUndefined(getItemInArrayByKeyValue(usersIdGestori, id))) {
                    usersIdGestori.push(id);
                }
            });
        }
        const uniqueId = getUniqueValueInArray(usersIdInstallatori.concat(usersIdOperatori).concat(usersIdGestori));
        if (arrayIsSet(uniqueId)) {
            this.mapService.getUserDetail(uniqueId).pipe(
                map((users) => {
                    return users.map((user: any) => {
                        user.role = [];
                        if (getItemInArrayByKeyValue(usersIdGestori, getId(user))) {
                            user.role.push('gestore');
                        }
                        if (getItemInArrayByKeyValue(usersIdInstallatori, getId(user))) {
                            user.role.push('installatore');
                        }
                        if (getItemInArrayByKeyValue(usersIdOperatori, getId(user))) {
                            user.role.push('operatore');
                        }
                        return user;
                    });
                }),
                switchMap((users) => {
                    return this.dialogPopUpInfoService.openSelectUsers(users, getValue, getId, false, event.report.presaInCaricoDa?.id ?? undefined);
                }),
                switchMap(usersSelect => {
                    if (arrayIsSet(usersSelect)) {
                        const userId = getId(usersSelect[0]);
                        if (userId !== event.report.presaInCaricoDa?.id) {
                            return this.reportsService.assegnaLaSegnalazione(event.report.objectId, userId)
                                .pipe(
                                    switchMap(segnalazione => {
                                        return this.getSegnalazioneWithPresoInCaricoDetail([segnalazione]);
                                    }),
                                    map(segnalazione => segnalazione[0])
                                );
                        }
                        // reassigning to the same user, nothing to do here
                        return of(null);
                    } else {
                        return of(null);
                    }
                })
            ).subscribe(segnalazione => {
                if (isNotNullOrUndefined(segnalazione)) {
                    this.segnalazioniAperteOnMap = this.substitionSegnalazione(this.segnalazioniAperteOnMap, segnalazione);
                }
                this.saveSchedaCompilata.next({saveReport: {key: 'assignTo', value: true, error: false}});
            }, error => {
                this.alertService.error(error.message);
                this.saveSchedaCompilata.next({saveReport: {key: 'assignTo', value: true, error: true}});

            });
        } else {
            this.alertService.warning(this.translateService.instant('noOperatorOrInstallator'));
            this.saveSchedaCompilata.next({saveReport: {key: 'assignTo', value: true, error: false}});
        }

    }

    clickCloseReportInSegnalazione(event) {
        this.reportsService.closeReportObs(event.report.objectId).subscribe(segnalazione => {
            this.segnalazioniAperteOnMap = this.substitionSegnalazione(this.segnalazioniAperteOnMap, segnalazione);
            this.alertService.success(this.translateService.instant('close_report_success'));
            this.saveSchedaCompilata.next({saveReport: {key: 'closeReport', value: true, error: false}});

        }, error => {
            this.alertService.error(error.message);
            this.saveSchedaCompilata.next({saveReport: {key: 'closeReport', value: true, error: true}});
        });


    }

    substitionSegnalazione(segnalazioni: SegnalazioniParse[], segnalazioneAggiornata: SegnalazioniParse) {
        let segnalazioniDaAggiornare = [];
        if (arrayIsSet(segnalazioni)) {
            segnalazioniDaAggiornare = [...segnalazioni];
        }
        if (arrayIsSet(segnalazioniDaAggiornare) && isNotNullOrUndefined(segnalazioneAggiornata)) {
            const index = segnalazioniDaAggiornare.findIndex(segnalazioneDaSostituire => segnalazioneDaSostituire.objectId == segnalazioneAggiornata.objectId);
            if (index >= 0) {
                segnalazioniDaAggiornare[index] = segnalazioneAggiornata;
            }
        }
        return segnalazioniDaAggiornare;
    }

    clicktakeChargeInSegnalazione(event) {
        this.reportsService.prendiInCaricoLaSegnalazioneObs(event.report.objectId)
            .pipe(
                switchMap(segnalazione => {
                    return this.getSegnalazioneWithPresoInCaricoDetail([segnalazione]);
                }),
                map(segnalazione => segnalazione[0])
            )
            .subscribe(segnalazione => {
                this.alertService.success(this.translateService.instant('take_charged'));
                this.segnalazioniAperteOnMap = this.substitionSegnalazione(this.segnalazioniAperteOnMap, segnalazione);
                this.saveSchedaCompilata.next({saveReport: {key: 'takeCharge', value: true, error: false}});
            }, error => {
                this.saveSchedaCompilata.next({saveReport: {key: 'takeCharge', value: true, error: true}});
                this.alertService.error(error.message);
            });


    }

    saveSchedaManutenzioneWithActivity(event: {
        elementToSave: any,
        valuesSchedaManutenzione: any,
        keys: formFiledMaintenanceFormContolName[],
        maintenanceScheduleReference: SchedaManutenzioneParse
    }, nuovoStato = null) {
        const parseElement = event.elementToSave;
        const valueForm = event.valuesSchedaManutenzione;
        const keys = event.keys;
        const schedaManutenzione = event.maintenanceScheduleReference;
        const statusObj = isNotNullOrUndefined(nuovoStato) ? {nuovoStato} : null;
        const valueFormCasted = this.maintenanceService.getCastValueToSaveByKeys(valueForm, keys);
        return this.maintenanceService.createSchedaManutenzioneCompilataCloud(schedaManutenzione.objectId, valueFormCasted, parseElement).pipe(
            switchMap(schedaCompilata => {
                const res = this.activitiesService.createActivity(
                    event.elementToSave, null, null, null, schedaCompilata, statusObj
                );
                this.maintenanceService.destroyLocalSchedaPrecompilata(schedaManutenzione.objectId, parseElement);
                return fromPromise(res);
            })
        );
    }

    clickGenerateReportInSegnalazione(event) {
        const type = typeElementScheduleMaintence.Segnalazioni;
        this.getSchedeManutenzione(type)
            .pipe(
                switchMap((schedeManutenzione) => {
                    const minWidth = window.innerWidth <= 500 ? '100vw' : null;
                    this.schedeManutenzioneDelProgetto[type] = schedeManutenzione;
                    return this.dialogPopUpService.openGenerateReport(event.report.objectId, this.tuttiAbbonamenti, this.myRole, schedeManutenzione, {minWidth});
                }),
                switchMap((value) => {
                    if (!isNotNullOrUndefined(value)) {
                        return of(undefined);
                    } else if (value.typeReport !== 'schedaManutenzione') {
                        const report = new SegnalazioniParse();
                        report.objectId = value.reportObjectId;
                        return this.activitiesService.createActivityObs(
                            report, value.value.comment, value.value.foto, value.value.allegato, null, {nuovoStato: value.value.nuovoStato}
                        );
                    } else if (value.typeReport == 'schedaManutenzione') {
                        const formFields: FormFieldPopUpType[] = [
                            {
                                title: null,
                                type: typeFormValue.RADIO_BUTTON,
                                formControlName: 'nuovoStato',
                                possibleValues: [
                                    {
                                        value: this.reportStates.COMPLETATO,
                                        title: {traduction: 'completed_intervention'}
                                    },
                                    {value: this.reportStates.MATERIALE, title: {traduction: 'material_waiting'}},
                                    {value: this.reportStates.INTERVENTO, title: {traduction: 'intervention_waiting'}},
                                    {
                                        value: this.reportStates.INSTALLATO_MULETTO,
                                        title: {traduction: 'segnalazioniParse.stato.' + this.reportStates.INSTALLATO_MULETTO}
                                    },
                                    {
                                        value: this.reportStates.MESSA_IN_SICUREZZA,
                                        title: {traduction: 'segnalazioniParse.stato.' + this.reportStates.MESSA_IN_SICUREZZA}
                                    },
                                    {
                                        value: this.reportStates.EMESSO_PREVENTIVO,
                                        title: {traduction: 'segnalazioniParse.stato.' + this.reportStates.EMESSO_PREVENTIVO}
                                    },
                                    {
                                        value: this.reportStates.RICERCA_GUASTO,
                                        title: {traduction: 'segnalazioniParse.stato.' + this.reportStates.RICERCA_GUASTO}
                                    },
                                    {
                                        value: this.reportStates.IN_ATTESA,
                                        title: {traduction: 'segnalazioniParse.stato.' + this.reportStates.IN_ATTESA}
                                    },
                                ],
                                defaultValue: value.nuovoStato
                            }
                        ]
                        const data: FormFieldPopUpData = {
                            formFields,
                            title: {traduction: 'confermeStatuReports'},
                            disableClose: true,
                            visualizeCheckAllButton: false
                        }
                        return this.dialogPopUpInfoService.openFormFields(data, undefined).pipe(
                            map((response) => {
                                Object.keys(response)
                                    .forEach(key => {
                                        if (value[key] != null) {
                                            value[key] = response[key];
                                        }
                                    })
                                return value
                            }),
                            switchMap((value) => {
                                const report = new SegnalazioniParse();
                                report.objectId = value.reportObjectId;
                                value.event.elementToSave = report;
                                return this.saveSchedaManutenzioneWithActivity(value.event, value.nuovoStato);
                            })
                        )
                    }
                }),
                switchMap((activity) => {
                    if (isNotNullOrUndefined(activity)) {
                        return this.mapService.refreshParseObject([event.report]);
                    } else {
                        return of(undefined);
                    }
                })
            )
            .subscribe(response => {
                if (isNotNullOrUndefined(response)) {
                    const segnalazione = response[0];
                    this.segnalazioniAperteOnMap = this.substitionSegnalazione(this.segnalazioniAperteOnMap, segnalazione);
                    this.alertService.success(this.translateService.instant('activity_success_create'));
                    this.saveSchedaCompilata.next({saveReport: {key: 'generateReport', value: true, error: false}});
                } else {
                    this.saveSchedaCompilata.next({saveReport: {key: 'generateReport', value: true, error: false}});
                }
            }, error => {
                this.alertService.error(error.message);
                this.saveSchedaCompilata.next({saveReport: {key: 'generateReport', value: true, error: true}});
            });
    }

    clickReopenReportInSegnalazione(event) {
        this.reportsService.richiediInterventoSuSegnalazioneObs(event.report.objectId).subscribe(
            segnalazione => {
                this.segnalazioniAperteOnMap = this.substitionSegnalazione(this.segnalazioniAperteOnMap, segnalazione);
                this.saveSchedaCompilata.next({saveReport: {key: 'searchIntervention', value: true, error: false}});
                this.alertService.success(this.translateService.instant('reopen_report_success'));
            }, error => {
                this.saveSchedaCompilata.next({saveReport: {key: 'searchIntervention', value: true, error: true}});
                this.alertService.error(error.message);
            }
        );
    }

    clickSendComment(event) {
        let comment;
        let foto;
        let allegato;
        if (event.comment) {
            comment = event.comment;
        } else if (event.foto != null && event.foto.fileUploadControl) {
            foto = {file: this.utilityParseFile.getParseFile(event.foto.fileUploadControl)}
        } else if (event.file != null && event.file.fileUploadControl) {
            allegato = {file: this.utilityParseFile.getParseFile(event.file.fileUploadControl)}
        }
        this.activitiesService.createActivityObs(
            event.report, comment, foto, allegato, null, undefined).subscribe(r => {
            this.alertService.success(this.translateService.instant('activity_success_create'));
            this.saveSchedaCompilata.next({saveReport: {key: 'sendComment', value: true, error: false}});
        }, error => {
            this.alertService.error(error)
            this.saveSchedaCompilata.next({saveReport: {key: 'sendComment', value: true, error: false}});
        });

    }

    clickGetCalendari(event) {
        const obs = [
            this.calendarioManutenzioneService.getCalendarioManutenzioniByElement(event.elementParse, event.idsDisplayed),
            this.maintenanceService.getSchedeByElementParse(event.elementParse).pipe(
                switchMap((schedeCompilate) => this.maintenanceService.getUserDetailSchede(schedeCompilate)),
                switchMap((schedeCompilate) => this.maintenanceService.fetchDocumentFileInFormSchedeCompilate(schedeCompilate)),
            )
        ];

        combineLatest(obs).subscribe(
            allValue => {
                const calendari = allValue[0] as CalendarioManutenzioniParse[];
                const schedeCompilate = allValue[1] as SchedaManutenzioneCompilataParse[];
                if (arrayIsSet(calendari)) {
                    this.calendariByElement = calendari;
                } else {
                    this.calendariByElement = undefined;
                }
                if (arrayIsSet(schedeCompilate)) {
                    this.schedeCompilateByElement = schedeCompilate;
                } else {
                    this.schedeCompilateByElement = undefined;
                }

            }, error => {
                this.alertService.error(error);
                this.calendariByElement = undefined;
                this.schedeCompilateByElement = undefined;

            }
        );
    }

    openNavigator(event: any | undefined, element: any | undefined) {
        this.loading.navigate = true;
        let destination;
        if (event != null) {
            destination = event.destination;
        } else if (element != null && element.location != null) {
            destination = {lat: element.location.latitude, lng: element.location.longitude};
        } else if (element != null && element.className === this.nomeClassi.lineaElettrica) {
            const linesMap = (element as LineaElettricaParse).linesMap
            if (arrayIsSet(linesMap)) {
                const midPointIndex = Math.floor((linesMap.length - 1) / 2)
                const lat = linesMap[midPointIndex].start.latitude;
                const lng = linesMap[midPointIndex].start.longitude;
                destination = {lat, lng};
            }
        }
        let position$;
        if (destination == null) {
            position$ = EMPTY;
            this.loading.navigate = false;
            this.alertService.warning(this.translateService.instant('notDestintion'));
        } else if (isNotNullOrUndefined(this.myPosition) && (isNotNullOrUndefined(this.myPosition.lat) && isNotNullOrUndefined(this.myPosition.lng)) && this.myPosition.isEnabled) {
            const position = {coords: {latitude: null, longitude: null}};
            position.coords.latitude = this.myPosition.lat;
            position.coords.longitude = this.myPosition.lng;
            position$ = of(position);
        } else {
            position$ = this.googleService.getCurrentPosition$()
                .pipe(
                    catchError(error => {
                        if (error != null && error.code === this.googleService.customError.timeOut.code) {
                            this.alertService.warning(this.translateService.instant(error.message));
                        } else if (error != null && error.code === this.googleService.customError.browerNotSupported.code) {
                            this.alertService.error(this.translateService.instant('newInstallation.unavaibleForThisBrowser'));
                        } else {
                            this.alertService.error(error.message);
                        }
                        const position = {coords: {latitude: null, longitude: null}};
                        if (isNotNullOrUndefined(this.myPosition) && (isNotNullOrUndefined(this.myPosition.lat) && isNotNullOrUndefined(this.myPosition.lng))) {
                            position.coords.latitude = this.myPosition.lat;
                            position.coords.longitude = this.myPosition.lng;
                        } else if (isNotNullOrUndefined(this.map)) {
                            position.coords.latitude = this.map.getCenter().lat();
                            position.coords.longitude = this.map.getCenter().lng();
                        }
                        return of(position);
                    })
                )
        }
        position$.subscribe(position => {
            if (position != null && position.coords != null && position.coords.latitude != null && position.coords.longitude != null) {
                const origin = {lat: null, lng: null};
                origin.lat = position.coords.latitude;
                origin.lng = position.coords.longitude;
                window.open(this.mapService.getUrlNavigator(origin, destination), '_blank');
            } else {
                this.alertService.warning(this.translateService.instant('notDestintion'));
            }
            this.loading.navigate = false;
        })
    }

    getLightPointsSearchSmart$(values: any): Observable<PuntiLuceParse[]> {
        return this.mapService.getLightPointByAdvancedFilter(values).pipe(
            switchMap(lightPoints => {
                if (arrayIsSet(lightPoints)) {
                    if (this.scaleColorPuntiLuce.campoScalaColore === 'lampadaFunzionante') {
                        return this.segnaliTlcNodoService.getLampadaFunzionante_v2(lightPoints).pipe(
                            map((segnali) => {
                                if (segnali != null) {
                                    lightPoints.forEach(lightPoint => {
                                        if (segnali[lightPoint.objectId] != null && segnali[lightPoint.objectId].segnale != null) {
                                            const segnale = segnali[lightPoint.objectId].segnale;
                                            lightPoint.setSegnale(segnale)
                                        }
                                    })
                                }
                                return lightPoints;
                            }));
                    } else {
                        return of(lightPoints);
                    }
                } else {
                    return of([]);
                }
            }),
            map(lightPoints => {
                if (arrayIsSet(lightPoints)) {
                    return lightPoints.map(pl => {
                        pl.labelMarker = this.puntiLuceLabelService.getLabelMarker(pl, 'white');
                        const icon = this.scaleColorPuntiLuce.getIconPuntoLuce(pl);
                        pl.icon = icon;
                        return pl;
                    })
                } else {
                    return []
                }
            })
        )
    }

    clickChipsSearchSmart(values) {
        const targa = values.click;
        this.getLightPointsSearchSmart$({targhePuntiLuce: [targa]}).pipe(
            map(lightPoints => {
                return {lightPoints, targhePuntiLuce: targa, removeValues: undefined};
            })
        ).subscribe(lightPointsValues => {
            let lightPointIdEtichette = arrayIsSet(this.formPage.get('searchSmart').get('lightPointIdEtichette').value) ? this.formPage.get('searchSmart').get('lightPointIdEtichette').value : [];
            lightPointIdEtichette = this.addLightPointBySearchSmart(lightPointsValues, lightPointIdEtichette);
            this.formPage.get('searchSmart').get('lightPointIdEtichette').setValue(lightPointIdEtichette);
        }, error => {

            this.alertService.error(error);
        });
    }

    addLightPointBySearchSmart(lightPointsValues: any, lightPointIdEtichette: {
        targhePuntiLuce: string,
        lighitPointId: string[]
    }[]): { targhePuntiLuce: string, lighitPointId: string[] }[] {
        const lightPoints = lightPointsValues.lightPoints;

        if (arrayIsSet(lightPoints)) {
            const visualizedChips = this.formPage.get('searchSmart').get('visualizedChips').value !== null ? this.formPage.get('searchSmart').get('visualizedChips').value : {};
            visualizedChips[lightPointsValues.targhePuntiLuce] = lightPoints.length > 0;
            this.formPage.get('searchSmart').get('visualizedChips').setValue(visualizedChips);
            const idCurrentLightPoint = this.lightPoints.map(lightPoint => lightPoint.objectId);
            const notInMapLightPoint = lightPoints.filter(lightPoint => !idCurrentLightPoint.includes(lightPoint.objectId));
            if (arrayIsSet(notInMapLightPoint)) {
                lightPointIdEtichette.push({
                    targhePuntiLuce: lightPointsValues.targhePuntiLuce,
                    lighitPointId: notInMapLightPoint.map(val => val.objectId)
                });
                this.addPuntiLuceInCluster(notInMapLightPoint);
                const newValueIsPresent = this.scaleColorPuntiLuce.lightPointsContainedNewValue(notInMapLightPoint);
                if (newValueIsPresent) {
                    this.scaleColorPuntiLuce.puntiLuceInMappa = this.lightPoints;
                    this.scaleColorPuntiLuce.aggiornaScalaColore();
                    this.cambiaColoreDelleIcone(null);
                }
                this.puntiLuceLabelService.createValueHtml(this.lightPoints);
                this.clustersLightPoints = this.clustersLightPoints.slice();
                this.lightPoints_v2 = this.lightPoints_v2.slice();

            }

        }
        return lightPointIdEtichette;
    }

    initSearchSmart() {
        this.openSearchSmart = true;
        if (this.searchSmartSubscription != null) {
            this.searchSmartSubscription.unsubscribe();
        }
        this.searchSmartSubscription = this.formPage.get('searchSmart').get('targhePuntiLuce').valueChanges
            .pipe(switchMap(value => {
                if (value && value.remove && arrayIsSet(value.remove)) {
                    return of({lightPoints: [], targhePuntiLuce: '', removeValues: value.remove});
                } else if (value) {
                    const oldValue = this.formPage.get('searchSmart').get('oldValue').value;
                    this.formPage.get('searchSmart').disable({emitEvent: false});
                    let difference = value.add;
                    if (arrayIsSet(oldValue) && arrayIsSet(value.add)) {
                        // tslint:disable-next-line:no-shadowed-variable
                        difference = value.add.filter(value => !oldValue.includes(value));
                    }
                    difference = difference
                        .map(diff => {
                            if (stringIsSet(diff)) {
                                return diff.trim();
                            } else if (diff.toString != null) {
                                return diff
                                    .toString()
                                    .trim();
                            } else {
                                return ''
                            }
                        })
                        .filter(v => stringIsSet(v))
                    if (arrayIsSet(difference)) {
                        return this.getLightPointsSearchSmart$({targhePuntiLuce: difference}).pipe(
                            map(lightPoints => {
                                return {lightPoints, targhePuntiLuce: difference[0], removeValues: undefined};
                            })
                        );
                    }
                }
                return of({lightPoints: [], targhePuntiLuce: '', removeValues: undefined});
            }))
            .subscribe(lightPointsValues => {
                    const currentValue = this.formPage.get('searchSmart').get('targhePuntiLuce').value;
                    let lightPointIdEtichette = arrayIsSet(this.formPage.get('searchSmart').get('lightPointIdEtichette').value) ? this.formPage.get('searchSmart').get('lightPointIdEtichette').value : [];
                    let idLightPointsToRemove = [];
                    if (arrayIsSet(lightPointsValues.removeValues)) {
                        lightPointsValues.removeValues.forEach(
                            removeValue => {
                                if (stringIsSet(removeValue)) {
                                    if (arrayIsSet(lightPointIdEtichette)) {
                                        const index = lightPointIdEtichette.findIndex(val => val.targhePuntiLuce === removeValue);
                                        if (index >= 0) {
                                            idLightPointsToRemove = idLightPointsToRemove.concat(lightPointIdEtichette[index].lighitPointId);
                                            lightPointIdEtichette.splice(index, 1);
                                        }
                                    }
                                }
                            }
                        );
                        if (arrayIsSet(idLightPointsToRemove)) {
                            this.removeLightPoints(idLightPointsToRemove);
                        }
                    }
                    lightPointIdEtichette = this.addLightPointBySearchSmart(lightPointsValues, lightPointIdEtichette);
                    // if (arrayIsSet(lightPoints)) {
                    // const visualizedChips = this.formPage.get('searchSmart').get('visualizedChips').value !== null ? this.formPage.get('searchSmart').get('visualizedChips').value : {};
                    // visualizedChips[lightPointsValues.targhePuntiLuce] = lightPoints.length > 0;
                    // this.formPage.get('searchSmart').get('visualizedChips').setValue(visualizedChips)
                    //
                    //
                    // const idCurrentLightPoint = this.lightPoints.map(lightPoint => lightPoint.objectId)
                    // const notInMapLightPoint = lightPoints.filter(lightPoint => !idCurrentLightPoint.includes(lightPoint.objectId))
                    // if (arrayIsSet(notInMapLightPoint)) {
                    //     lightPointIdEtichette.push({
                    //         targhePuntiLuce: lightPointsValues.targhePuntiLuce,
                    //         lighitPointId: notInMapLightPoint.map(val => val.objectId)
                    //     })
                    //     this.addPuntiLuceInCluster(notInMapLightPoint);
                    //     const newValueIsPresent = this.scaleColorPuntiLuce.lightPointsContainedNewValue(notInMapLightPoint)
                    //     if (newValueIsPresent) {
                    //         this.scaleColorPuntiLuce.puntiLuceInMappa = this.lightPoints;
                    //         this.scaleColorPuntiLuce.aggiornaScalaColore();
                    //         this.cambiaColoreDelleIcone(null);
                    //     }
                    //     this.puntiLuceLabelService.createValueHtml(this.lightPoints);
                    //     this.clustersLightPoints = this.clustersLightPoints.slice()
                    // }
                    // }
                    this.formPage.get('searchSmart').get('lightPointIdEtichette').setValue(lightPointIdEtichette);
                    this.formPage.get('searchSmart').get('oldValue').setValue(currentValue.add);
                    this.formPage.get('searchSmart').get('targhePuntiLuce').enable({emitEvent: false});
                    this.smartSearchServiceService.lastActiveSmartSearch = currentValue.add;
                },
                error => {
                    this.alertService.error(error);
                });
    }

    closeSearchSmart() {
        this.openSearchSmart = false;
        if (this.searchSmartSubscription != null) {
            this.searchSmartSubscription.unsubscribe();
        }
    }


    openAddElectricLine(message: string = undefined) {
        const circuiti = this.circuitiAppartenentiAlProgetto;
        this.dialogPopUpCreateNewService.openNewElectricLine({
            oldValue: this.formPage.get('addElectricLine').value,
            message,
            circuitsInProject: circuiti
        }).subscribe(
            formValue => {
                if (formValue != null) {
                    Object.keys(formValue).forEach(key => {
                        if (this.formPage.get('addElectricLine').get(key) != null && formValue[key] != null) {
                            this.formPage.get('addElectricLine').get(key).setValue(formValue[key]);
                        }
                    });
                    this.disableAllbuttonExcept('openedDrawingElectricLine');
                    this.openedDrawingElectricLine = true;
                    this.initDynamicView();
                } else {
                    this.formPage.get('addElectricLine').reset();
                }
            }
        );
    }


    openEditNewElectricLine(message: string = undefined) {
        const circuiti = this.circuitiAppartenentiAlProgetto;
        this.dialogPopUpCreateNewService.openNewElectricLine({
            oldValue: this.formPage.get('addElectricLine').value,
            message,
            circuitsInProject: circuiti
        }).subscribe(
            formValue => {
                if (formValue != null) {
                    this.openedDrawingElectricLine = true;
                    Object.keys(formValue).forEach(key => {
                        if (this.formPage.get('addElectricLine').get(key) != null) {
                            if (formValue[key] != null) {
                                this.formPage.get('addElectricLine').get(key).setValue(formValue[key]);
                            } else {
                                this.formPage.get('addElectricLine').get(key).reset();
                            }
                        }
                    });
                }
            }
        );
    }

    initDynamicView() {
        if (!arrayIsSet(this.dynamicViewSubscriptions)) {
            this.maxZoomCluster = this.luxDataMapElementService.zoomTresholdGridToLine;
            this.closeAllSidenav();
            this.dynamicViewSubscriptions = [];
            const keyChangesActive = Object.keys(this.formPage.get('dynamicView').value)
                .filter(key => !['isOn', 'isOpenedSetting', 'searchAddress'].includes(key));
            const valuesChanges: Observable<any>[] = [];
            keyChangesActive.forEach(key => {
                const currentValue = this.formPage.get('dynamicView').get(key).value;
                valuesChanges.push(this.formPage.get('dynamicView').get(key).valueChanges.pipe(startWith(currentValue)));
            });
            let sub = this.formPage.get('dynamicView').get('searchAddress').valueChanges.subscribe(address => {
                if (address != null) {
                    this.searchAddressCoordinate = address;
                    this.formPage.get('dynamicView').get('searchAddress').disable({emitEvent: false});
                    setTimeout(() => {
                        this.searchAddressCoordinate = undefined;
                        this.formPage.get('dynamicView').get('searchAddress').enable({emitEvent: false});
                    }, 6000);
                }
            }, error => {
                this.alertService.error(error);
            });
            this.dynamicViewSubscriptions.push(sub);
            // @ts-ignore
            sub = combineLatest(valuesChanges).pipe(
                map(values => {
                    this.loading.eyeButton = true;
                    const obj: { [k: string]: any } = {};
                    keyChangesActive.forEach((key, index) => {
                        obj[key] = this.formPage.get('dynamicView').get(key).value;
                    });
                    return obj;
                }),
                map((values) => {
                    const index = Object.values(values).findIndex(val => val === true);
                    if (index < 0) {
                        Object.keys(values)
                            .filter(key => key !== 'bounds')
                            .forEach((key) => {
                                this.formPage.get('dynamicView').get(key).setValue(true, {emitEvent: false});
                            });
                        const obj: { [k: string]: any } = {};
                        keyChangesActive.forEach((key) => {
                            obj[key] = this.formPage.get('dynamicView').get(key).value;
                        });
                        return obj;
                    } else {
                        return values;
                    }
                }),
                switchMap(values => {
                    if (values.bounds != null && values.bounds.zoom != null &&
                        values.bounds.zoom <= this.luxDataMapElementService.zoomTresholdGridToLine) {
                        this.loading.eyeButton = false;
                        return this.alertService.warning(this.translateService.instant('modalityActivedZoomLessThan'));
                    } else {
                        if (values.bounds != null) {
                            const obj: { [k: string]: Observable<any> } = {};
                            Object.keys(values)
                                .filter(key => key !== 'bounds')
                                .forEach(key => {
                                    if (values[key]) {
                                        if (key === this.nomeClassi.circuiti) {
                                            const elements = this.geoPointService.withinGeoBox(this.circuits, values.bounds.northEast, values.bounds.sudWest);
                                            const color = (isNotNullOrUndefined(this.map) && this.map.mapTypeId === 'roadmap') ? 'black' : 'white';
                                            obj[key] = this.mapService.getCircuitsByGeoBox$(values.bounds.northEast, values.bounds.sudWest, elements.in).pipe(
                                                map((circuiti) =>
                                                    circuiti.map(circuito => {
                                                        circuito.labelMarker = this.circutiLabelService.getLabelMarker(circuito, color);
                                                        circuito.icon = this.scaleColorCircuiti.getIconCircuito(circuito);
                                                        return circuito;
                                                    }).concat(elements.in)
                                                )
                                            );
                                        } else if (key === this.nomeClassi.lineaElettrica) {
                                            obj[key] = this.lineaElettricaService.getElectricLinesByGeoBox(values.bounds.northEast, values.bounds.sudWest, []).pipe(
                                                map(linesGeoBox => {
                                                    return this.updateColorAtLines(linesGeoBox, true)
                                                })
                                            );
                                        } else if (key === this.nomeClassi.arredoUrbano) {
                                            const elements = this.geoPointService.withinGeoBox(this.arrediUrbaniOnMap, values.bounds.northEast, values.bounds.sudWest);
                                            obj[key] = this.arredoUrbanoUtilsService.getArredoUrbanoByGeoBox(values.bounds.northEast, values.bounds.sudWest, elements.in);
                                        } else if (key === this.nomeClassi.puntiLuce) {
                                            const color = (isNotNullOrUndefined(this.map) && this.map.mapTypeId === 'roadmap') ? 'black' : 'white';
                                            const elements = this.geoPointService.withinGeoBox(this.lightPoints, values.bounds.northEast, values.bounds.sudWest);
                                            obj[key] = this.mapService.getPuntiLuceByGeoBox(values.bounds.northEast, values.bounds.sudWest, elements.in).pipe(
                                                switchMap((lightPoints) => {
                                                    if (this.scaleColorPuntiLuce.campoScalaColore === 'lampadaFunzionante' && arrayIsSet(lightPoints)) {


                                                        // return this.segnaliTlcNodoService.getLampadaFunzionante(lightPoints).pipe(
                                                        //     map((segnali) => {
                                                        //         segnali.forEach(segnale => {
                                                        //             const index = lightPoints.findIndex(puntoLuce => puntoLuce.objectId == segnale.puntoLuce.objectId);
                                                        //             if (index >= 0) {
                                                        //                 lightPoints[index].lampadaFunzionante = segnale.lampadaFunzionante;
                                                        //             }
                                                        //         });
                                                        //         return lightPoints;
                                                        //     })
                                                        // )
                                                        return this.segnaliTlcNodoService.getLampadaFunzionante_v2(lightPoints).pipe(
                                                            map((segnali) => {
                                                                if (segnali != null) {
                                                                    lightPoints.forEach(lightPoint => {
                                                                        if (segnali[lightPoint.objectId] != null && segnali[lightPoint.objectId].segnale != null) {
                                                                            const segnale = segnali[lightPoint.objectId].segnale;
                                                                            lightPoint.setSegnale(segnale)
                                                                        }
                                                                    })
                                                                }
                                                                return lightPoints;
                                                            })
                                                        )
                                                    } else {
                                                        return of(lightPoints)
                                                    }
                                                }),
                                                map((puntiLuce) =>
                                                    puntiLuce.map(puntoLuce => {
                                                        puntoLuce.labelMarker = this.puntiLuceLabelService.getLabelMarker(puntoLuce, color);
                                                        return puntoLuce;
                                                    })
                                                )
                                            );
                                        }
                                    }
                                });
                            return objectForkJoin$(obj);
                        } else {
                            return of(values);
                        }
                    }
                })
            ).subscribe((values) => {
                if (arrayIsSet(values?.[this.nomeClassi.lineaElettrica])) {
                    this.electricLinesOnMap = values[this.nomeClassi.lineaElettrica];
                    this.updateColorElectricLinesOnMap();
                } else {
                    this.electricLinesOnMap = [];
                }
                if (arrayIsSet(values?.[this.nomeClassi.circuiti])) {
                    this.clustersCircuits = this.createclustersCircuits(values[this.nomeClassi.circuiti]);
                    this.circuits_v2 = values[this.nomeClassi.circuiti];
                } else {
                    this.clustersCircuits = [];
                    this.circuits_v2 = [];
                }
                if (arrayIsSet(values?.[this.nomeClassi.arredoUrbano])) {
                    this.arrediUrbaniOnMap = values[this.nomeClassi.arredoUrbano];
                } else {
                    this.arrediUrbaniOnMap = [];
                }
                if (arrayIsSet(values?.[this.nomeClassi.puntiLuce])) {
                    this.addPuntiLuceInCluster(values[this.nomeClassi.puntiLuce]);
                    this.clustersLightPoints = this.clustersLightPoints.slice();
                    this.lightPoints_v2 = this.lightPoints_v2.slice();

                } else {
                    this.clustersLightPoints = [];
                    this.lightPoints_v2 = [];
                }
                this.loading.eyeButton = false;
            }, error => {
                this.loading.eyeButton = false;
                this.alertService.error(error);
            });
            this.dynamicViewSubscriptions.push(sub);
            sub = this.formPage.get('dynamicView').get('isOn').valueChanges.subscribe(isOn => {
                if (isOn) {
                    this.formPage.get('dynamicView').get('isOpenedSetting').setValue(isOn);
                } else {
                    this.destroyDynamicView();
                }
            });
            this.dynamicViewSubscriptions.push(sub);
            this.formPage.get('dynamicView').get('isOn').setValue(true);
        }
    }

    destroyDynamicView() {
        if (this.dynamicViewSubscriptions != null) {
            this.dynamicViewSubscriptions.forEach(sub => sub.unsubscribe());
            this.formPage.get('dynamicView').reset();
            this.dynamicViewSubscriptions = undefined;
            this.clustersLightPoints = [];
            this.lightPoints_v2 = [];
            this.arrediUrbaniOnMap = [];
            this.electricLinesOnMap = [];
            this.clustersCircuits = [];
            this.circuits_v2 = [];
            this.maxZoomCluster = 19;
        }

    }

    longPressEye() {
        this.initDynamicView();
    }

    mapBoundChange(event) {
        this.formPage.get('dynamicView').get('bounds').setValue(event);
    }

    closeDynamicView() {
        this.formPage.get('dynamicView').get('isOpenedSetting').setValue(false);
        this.expandSearchAddress = false;
    }

    filterCarichiEsogeni(cairchiEsogeni: CaricoEsogenoParse[], selectItem: any, classNames: any, equalItem = true) {
        let className;
        let id;
        if (selectItem != null) {
            className = selectItem.className;
            id = selectItem.objectId;
        }
        if (!(stringIsSet(className) && stringIsSet(id))) {
            return arrayIsSet(cairchiEsogeni) ? cairchiEsogeni : [];
        } else if (arrayIsSet(cairchiEsogeni)) {
            const caricoEsogenoContainedSelectItem = (caricoEsogneo: CaricoEsogenoParse) => {
                let objectId;
                if (className === classNames.circuiti) {
                    objectId = caricoEsogneo.circuito != null && caricoEsogneo.circuito.objectId;
                } else if (className === classNames.puntiLuce) {
                    objectId = caricoEsogneo.puntoLuce != null && caricoEsogneo.puntoLuce.objectId;
                } else if (className === classNames.arredoUrbano) {
                    objectId = caricoEsogneo.arredoUrbano != null && caricoEsogneo.arredoUrbano.objectId;
                }
                return equalItem ? objectId == id : objectId != id;
            }
            return cairchiEsogeni.filter(caricoEsogeno => caricoEsogenoContainedSelectItem(caricoEsogeno));
        } else {
            return []
        }
    }

    getProfiliFunzionamento$(): Observable<TLC_ProfiloFunzionamentoParse[]> {
        if (arrayIsSet(this.profileTlcInDatabase)) {
            return of(this.profileTlcInDatabase)
        } else {
            return this.profileTlcService.getProfiliTelecontrollo().pipe(
                map(profiles => {
                    this.profileTlcInDatabase = profiles;
                    return profiles
                })
            )
        }
    }


    openBatchVirtualMidnight() {
        this.changeDetection.detectChanges();
        this.loading.batchVirtualMidnight = true;
        let editableLightPoints;
        if (Array.isArray(this.reportsService.reportsInMap) && this.reportsService.reportsInMap.length > 0) {
            editableLightPoints = this.reportsService.reportsInMap.map((idLightPoint) => {
                const puntoLuce = new PuntiLuceParse();
                puntoLuce.objectId = idLightPoint;
                return puntoLuce;
            });
        } else {
            editableLightPoints = this.lightPoints;
        }
        const circuitsInLightPoints = editableLightPoints.reduce((circuits: CircuitiParse[], lightPoint: PuntiLuceParse) => {
            const circuito = lightPoint.circuito;
            if (circuito != null) {
                if (!circuits.some(c => c.objectId === circuito.objectId)) {
                    circuits.push(circuito);
                }
            }
            return circuits;
        }, [])

        let dialog$: Observable<TypeBatchEditVirtualMidnight>;
        if (arrayIsSet(editableLightPoints)) {
            dialog$ = this.remoteContolService.getIotDevices(undefined, circuitsInLightPoints.map(c => c.objectId)).pipe(
                switchMap(iotDevices => {
                    const versioniProtocolloLM = iotDevices
                        .map(iotDevice => iotDevice.versioneProtocolloLM)
                    if (checkDiff(versioniProtocolloLM)) {
                        this.alertService.warning(this.translateService.instant('alert.versionDifferent'))
                        return EMPTY;
                    }
                    return this.getProfiliFunzionamento$().pipe(
                        switchMap(profilesTlc => {
                            return this.remoteContolService.getSettaggiTlcNodo(editableLightPoints.map(p => p.objectId)).pipe(
                                map((settaggiTlcNodo) => ({profilesTlc, iotDevices, settaggiTlcNodo}))
                            )
                        }),
                    )
                }),
                switchMap((values) => this.dialogPopUpService.openBatchEditVirtualMidnight(editableLightPoints, undefined, values))
            )
        }
        dialog$
            .pipe(
                switchMap((value) => {
                    if (value != null) {
                        if (arrayIsSet(value.programmiMvValues) && arrayIsSet(value.lightPoints)) {
                            return this.programMvService.saveProgrammiMvForLightPoints(value.programmiMvValues, value.lightPoints)
                        } else if (value.versioneProtocollo === TypeVersioneProtocolloLM.prt_v1) {
                            return this.remoteContolService.addSettaggiTlcNodo(value.profileTlc.objectId, value.days, value.lightPoints.map(l => l.objectId), undefined, value.dates, value.repeatYear)
                        } else if (value.removeDatesSettings) {
                            return this.remoteContolService.deleteSettaggiTlcNodo(value.removeDatesSettings)
                        }
                    }
                    return EMPTY;
                })
            )
            .subscribe(
                updated => {
                    this.changeDetection.reattach();
                    this.loading.batchVirtualMidnight = false;
                    const message = this.translateService.instant('lightMate.savedSuccessufully');
                    this.alertService.success(message);
                },
                error => {
                    console.log(error)
                    this.changeDetection.reattach();
                    this.alertService.error(error)
                    this.loading.batchVirtualMidnight = false;
                },
                () => {
                    this.changeDetection.reattach();
                    this.loading.batchVirtualMidnight = false;
                }
            );
    }

    predicateGetCircuitiPossibleValues(circuiti: CircuitiParse[]): { formHtml: string, formValues: string }[] {
        if (arrayIsSet(circuiti)) {
            return circuiti.map(c => {
                return {formHtml: c.numeroQuadro, formValues: c.objectId}
            })
        } else {
            return []
        }
    }

    predicateGetGruppiPuntiLucePossibleValues(gruppi: GruppiPuntiLuceParse[]): {
        formHtml: string,
        formValues: string
    }[] {
        if (arrayIsSet(gruppi)) {
            return gruppi.map(g => {
                return {formHtml: g.nome, formValues: g.objectId}
            })
        } else {
            return []
        }
    }

    loadingElementsInMap(event) {
        setTimeout(() => {
            if (event == null) {
                this.loading.elementsInMap = false;
            } else {
                this.loading.elementsInMap = Object.keys(event).findIndex(key => event[key] == true) >= 0;
            }
        })
    }

    getIconSelectedLightpointsPredicate(lightPoints: PuntiLuceParse[], selected: string[] | undefined, getIconSelected: any): PuntiLuceParse[] {
        if (arrayIsSet(lightPoints) && arrayIsSet(selected)) {
            return lightPoints.map(pl => {
                if (selected.includes(pl.objectId)) {
                    pl.icon = getIconSelected(pl, 1)
                } else {
                    pl.icon = getIconSelected(pl, 0.2)
                }
                return pl
            })
        } else {
            return lightPoints.map(pl => {
                pl.icon = getIconSelected(pl, 1)
                return pl
            })
        }
    }
}

