import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {arrayIsSet, isNotNullOrUndefined, stringIsSet} from "../../models/Models";
import {
    AbstractControl,
    ControlValueAccessor,
    UntypedFormBuilder,
    UntypedFormGroup, NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors
} from "@angular/forms";
import {Subscription} from "rxjs";

export type emitValueClickChips = {
    add: string[] | undefined,
    remove: string[] | undefined,
    click: string,
    visualized: string[]
};


@Component({
    selector: 'app-multiple-value-free-text',
    templateUrl: './multiple-value-free-text.component.html',
    styleUrls: ['./multiple-value-free-text.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: MultipleValueFreeTextComponent
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: MultipleValueFreeTextComponent
        }
    ]
})


export class MultipleValueFreeTextComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor {


    @Input() titleCard: string
    @Input() label: string
    @Input() visualizedChips: boolean = true;
    @Input() highlightedChips: { [k: string]: boolean };
    @Input() isSetting: boolean
    @Input() disableEmitEvent = true;
    @Output() clickChips = new EventEmitter<emitValueClickChips>();
    @Output() emitChips = new EventEmitter<emitValueClickChips>();
    form: UntypedFormGroup
    subscriptions = new Subscription();

    constructor(private fb: UntypedFormBuilder,
    ) {
        this.form = this.fb.group({setTargheEtichette: null, targhePuntiLuce: undefined});
        this.subscriptions.add(
            this.form.get('targhePuntiLuce').valueChanges.subscribe(targhe => {
                this.emitChips.next(targhe);
            })
        )
    }

    clickSet() {
        if (this.form.enabled) {
            const value = this.form.get('setTargheEtichette').value;
            const activeTarghe = [...this.activeTarghe];
            let emitEvent = false;
            if (!(arrayIsSet(activeTarghe) && activeTarghe.includes(value)) && stringIsSet(value)) {
                activeTarghe.push(value);
                emitEvent = true;
            } else {
                // const index = activeTarghe.indexOf(value)
                // activeTarghe.splice(index, 1);
                emitEvent = false
            }
            this.form.get('targhePuntiLuce').setValue({add: activeTarghe, remove: undefined}, {emitEvent});
            this.form.get('setTargheEtichette').reset(null, {emitEvent: false});
        }
    }


    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {

    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }


    onTouched: Function = () => {
    };


    registerOnChange(onChange: any) {
        const sub = this.form.get('targhePuntiLuce').valueChanges
            .subscribe(onChange);
        this.subscriptions.add(sub)
    }

    registerOnTouched(onTouched: Function) {
        this.onTouched = onTouched;
    }


    setDisabledState(disabled: boolean) {
        if (disabled) {
            this.form.disable({emitEvent: this.disableEmitEvent});
        } else {
            this.form.enable({emitEvent: this.disableEmitEvent});
        }
    }

    writeValue(value: any) {
        if (!isNotNullOrUndefined(value)) {
            this.form.get('targhePuntiLuce').reset();
        } else if (value != null) {
            this.form.get('targhePuntiLuce').setValue(value);
        }
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (!isNotNullOrUndefined(this.form.get('targhePuntiLuce').validator)) {
            this.form.get('targhePuntiLuce').setValidators(control.validator)
        }
        if (this.form.get('targhePuntiLuce').valid) {
            return null
        } else {
            return this.form.get('targhePuntiLuce').errors;
        }
    }

    getFilterActived() {
        return this.isSetting ? 'card-resulted' : 'card-not-resulted';
    }

    unset(formControlName: string) {
        if (this.form != null && this.form.get('targhePuntiLuce').enabled) {
            const currentValue = this.form.get(formControlName).value ? this.form.get(formControlName).value.add : undefined
            this.form.get(formControlName).reset({add: undefined, remove: currentValue})
        }
    }

    get activeTarghe() {
        return this.form.get('targhePuntiLuce').value != null && arrayIsSet(this.form.get('targhePuntiLuce').value.add) ? this.form.get('targhePuntiLuce').value.add : []
    }

    removeStringElement(value: string, formControlName: string) {
        if (this.form.get(formControlName) != null && arrayIsSet(this.activeTarghe)) {
            const values = [...this.activeTarghe]
            const index = values.indexOf(value)
            if (index >= 0) {
                values.splice(index, 1);
                if (arrayIsSet(values)) {
                    this.form.get(formControlName).setValue({add: values, remove: [value]})
                } else {
                    this.unset(formControlName)
                }
            }
        }
    }

    getColorChipsSearch(value, values) {
        return this.isSetting && values && values[value] == true;
    }

    _clickChips(targa) {
        const visualized = Object.keys(this.highlightedChips).filter(key => this.highlightedChips[key])
        this.clickChips.emit({...this.form.get('targhePuntiLuce').value, click: targa, visualized})
    }
}
