import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ControlContainer} from "@angular/forms";
import {typeFormValue} from "../../models/configurationProperty/configurationPropertyUtils";
import {Subscription} from "rxjs";
import {debounceTime} from "rxjs/operators";

@Component({
    selector: 'app-range-form-field',
    templateUrl: './range-form-field.component.html',
    styleUrls: ['./range-form-field.component.scss']
})
export class RangeFormFieldComponent implements OnInit, OnDestroy {
    @Input() type: typeFormValue
    typeFormValues = typeFormValue
    @Input() title: string
    @Input() traductionMin: string
    @Input() traductionMax: string
    @Input() appearance: 'legacy' | 'standard' | 'fill' | 'outline' = 'standard';
    @Input() showHeader: boolean = false;
    private subscriptions: Subscription[] = [];

    constructor(
        private controlContainer: ControlContainer) {
    }


    getValue(value: any) {
        if (value == null) {
            return value
        } else if (this.type == typeFormValue.DATE && value.getDate != null) {
            return value.getTime()
        } else {
            return value
        }
    }

    get max() {
        return this.getValue(this.form.get('max').value)
    }

    get min() {
        return this.getValue(this.form.get('min').value)
    }

    ngOnInit(): void {
        if (this.form) {
            let sub = this.form.get('min').valueChanges
                .pipe(
                    debounceTime(500)
                )
                .subscribe(min => {
                    const max = this.max
                    if (min != null && max != null && this.min > max) {
                        this.form.get('min').setValue(max)
                    }
                })
            this.subscriptions.push(sub)
            sub = this.form.get('max').valueChanges
                .pipe(
                    debounceTime(500)
                )
                .subscribe(max => {
                    const min = this.min;
                    if (min != null && max != null && this.max < min) {
                        this.form.get('max').setValue(min)
                    }
                })
            this.subscriptions.push(sub)
        }

    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe())
    }

    get form() {
        return this.controlContainer.control;
    }


}
