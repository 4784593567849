import {Pipe, PipeTransform} from '@angular/core';
import {ChipsFromArrayService} from "../services/chips-from-array.service";
import {isNotNullOrUndefined} from "../../models/Models";

@Pipe({
    name: 'sortList'
})
export class SortListPipe implements PipeTransform {

    constructor(private chipsFromArrayService: ChipsFromArrayService,
    ) {
    }

    transform<T>(values: T [], label: { key: string, type: string, direction: string }): T [] {
        if (Array.isArray(values) && isNotNullOrUndefined(label)) {
            const valuesNewReference = [...values]
            return valuesNewReference.sort((a, b) => {
                if (label.type == 'string') {
                    const castA = this.chipsFromArrayService.castValue(a[label.key]);
                    const castB = this.chipsFromArrayService.castValue(b[label.key]);
                    return (label.direction == 'DSC') ? castA.localeCompare(castB, undefined, {numeric: true}) : castB.localeCompare(castA, undefined, {numeric: true})
                } else if (label.type == 'date') {
                    return (label.direction == 'DSC') ? a[label.key].getTime() - b[label.key].getTime() : b[label.key].getTime() - a[label.key].getTime()
                }
            });
        } else {
            return values
        }
    }
}
