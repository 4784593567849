import {Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {typeFormValue} from "../../models/configurationProperty/configurationPropertyUtils";
import {
    AbstractControl,
    ControlValueAccessor,
    UntypedFormBuilder,
    UntypedFormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors
} from "@angular/forms";
import {arrayIsSet, hunaParseFileName, isNotNullOrUndefined, stringIsSet} from "../../models/Models";
import {Subscription} from "rxjs";
import {dataForm} from "../../components/confirm-delete/select-or-create/select-or-create.component";
import {ImageService} from "../../providers/services/image.service";
import {DomSanitizer} from "@angular/platform-browser";
import {UtilsService} from "../../providers/services/utils.service";
import {MatLegacyFormFieldAppearance as MatFormFieldAppearance} from '@angular/material/legacy-form-field';
import {DialogPopUpInfoService} from "../../providers/services/dialog-pop-up-info.service";

@Component({
    selector: 'app-get-form-fiel-by-configuration-element',
    templateUrl: './get-form-fiel-by-configuration-element.component.html',
    styleUrls: ['./get-form-fiel-by-configuration-element.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: GetFormFielByConfigurationElementComponent
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: GetFormFielByConfigurationElementComponent
        }
    ]
})
export class GetFormFielByConfigurationElementComponent implements OnChanges, OnDestroy, ControlValueAccessor {

    @Input() titleTraduction: string;
    @Input() possibleValues: dataForm[];
    @Input() type: typeFormValue;
    @Input() minDate: Date | undefined;
    @Input() appearance: MatFormFieldAppearance = 'fill';
    @Input() disabled: boolean = false;
    public form: UntypedFormGroup;
    possibleValuesIsSet = false;
    visualizeImage = true;

    private subScriptions: Subscription[] = []

    public typeForm = typeFormValue;

    constructor(
        private fb: UntypedFormBuilder,
        private imageService: ImageService,
        private _sanitizer: DomSanitizer,
        private utils: UtilsService,
        private dialogPopUpInfo: DialogPopUpInfoService
    ) {
        this.form = this.fb.group({value: null})
    }


    ngOnChanges(changes: SimpleChanges): void {
        if (isNotNullOrUndefined(changes.possibleValues)) {
            this.possibleValuesIsSet = arrayIsSet(this.possibleValues);
        }
        if (changes.disabled) {
            if (this.disabled) {
                this.form.get('value').disable()
            } else {
                this.form.get('value').enable()
            }
        }
    }

    unsetValue() {
        this.form.get('value').reset()
    }

    unsetValueFile() {
        this.form.get('value').reset()
        this.form.get('value').markAsTouched();
    }


    openImageFull() {
        const image = this.form.get('value').value
        let urlImage;
        if (image != null) {
            urlImage = image.file ? image.file : image.url;
            if (stringIsSet(urlImage)) {
                this.dialogPopUpInfo.openVisualizedImage(urlImage, 'image', false)
                    .subscribe();
            }
        }
    }

    ngOnDestroy(): void {
        this.subScriptions.forEach(sub => sub.unsubscribe())
    }

    onTouched: Function = () => {
    };


    registerOnChange(onChange: any) {
        const sub = this.form.get('value').valueChanges
            .subscribe(onChange);
        this.subScriptions.push(sub)
    }

    registerOnTouched(onTouched: Function) {
        this.onTouched = onTouched;
    }


    setDisabledState(disabled: boolean) {
        if (disabled) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    writeValue(value: any) {
        if (!isNotNullOrUndefined(value)) {
            this.form.get('value').reset();
        } else if (value != null) {
            this.form.get('value').setValue(value);
        }
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (!isNotNullOrUndefined(this.form.get('value').validator)) {
            this.form.get('value').setValidators(control.validator)
        }
        if (this.form.get('value').valid) {
            return null
        } else {
            return this.form.get('value').errors;
        }
    }


    getImageForForm(base64, name) {
        return this.imageService.compressImage(base64).then(compressed => {
            const base64Compressa: string = compressed as string;
            return {
                url: this._sanitizer.bypassSecurityTrustUrl(base64Compressa),
                file: base64Compressa,
                name: hunaParseFileName(name)
            };
            // this.salvaImage = true;
        })
    }

    onLoadImage(files: File[], label = 'value') {
        try {
            let fileUploadControl = files[0];
            if (files.length == 0) {
                // this.alertService.error(this.translate.instant('load_correct_file'));
            } else {
                if (fileUploadControl) {
                    const file = fileUploadControl;
                    const name = fileUploadControl.name;
                    const reader = new FileReader();
                    reader.onloadend = (e: ProgressEvent) => {
                        const base64 = (e.target as FileReader).result as any;
                        this.getImageForForm(base64, name).then(
                            (value) => {
                                this.form.get('value').setValue({...value, fileUploadControl});
                                this.form.get('value').markAsTouched();
                            }
                        );
                    };
                    reader.onerror = (e) => {
                        // this.alertService.error(e)
                    }
                    reader.readAsDataURL(file);

                }
            }
        } catch (e) {
            console.error(e);
        }
    }

    onLoadFile(files: File[], label = 'value') {
        try {
            let fileUploadControl = files[0];
            const name = hunaParseFileName(fileUploadControl.name);
            const value = {name: name, file: fileUploadControl, ext: this.utils.getExtensionFileFromFileName(name)}
            this.form.get('value').markAsTouched();
            this.form.get('value').setValue(value);
        } catch (e) {
            console.error(e);
        }
    }

    isInError(keyError: string) {
        return this.form.touched && this.form.get('value').hasError(keyError)
    }

    get imageIsSet() {
        return this.typeForm.IMAGE === this.type && isNotNullOrUndefined(this.form.get('value')) && isNotNullOrUndefined(this.form.get('value').value) && isNotNullOrUndefined(this.form.get('value').value.url)
    }

    get fileIsSet() {
        return this.typeForm.FILE === this.type && isNotNullOrUndefined(this.form.get('value')) && isNotNullOrUndefined(this.form.get('value').value) && isNotNullOrUndefined(this.form.get('value').value.file)
    }


    changeDate(event: Date | null) {
        this.form.get('value').setValue(event);
        this.form.get('value').markAsTouched();
    }

    changeLatLng(who: 'latitude' | 'longitude', event) {
        const value = this.form.get('value').value;
        value[who] = parseFloat(event);
        this.form.get('value').setValue(value);
        this.form.get('value').markAsTouched();
    }

    getValueLatLng(who: 'latitude' | 'longitude') {
        const value = this.form.get('value').value;
        return isNotNullOrUndefined(value) && isNotNullOrUndefined(value[who]) ? value[who] : 0
    }


}
