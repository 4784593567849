import {Injectable} from '@angular/core';
import * as Parse from 'parse';
import {ProjectService} from "./project.service";
import {EMPTY, forkJoin, Observable, of} from "rxjs";
import {concatMap, delay, expand, map, switchMap, toArray} from "rxjs/operators";
import {fromPromise} from "rxjs/internal-compatibility";
import {className, isNotNullOrUndefined} from "../../models/Models";
import {PuntiLuceParse} from "../../models/PuntiLuce.Parse";

@Injectable({
    providedIn: 'root'
})
export class LocalDataBaseParseService {

    constructor(private projectService: ProjectService) {

    }

    get projectId() {
        return this.projectService.projectId;
    }

    private dbName = 'GlobalDataHuna'

    async convertToTestQuery(className, files) {
        const fileUploadControl = files[0];
        // const loaderId = this.loaderService.addLoader();
        if (fileUploadControl) {
            let blob = new Blob([new Uint8Array(await fileUploadControl.arrayBuffer())], {type: 'text/json'});
            const text = await fileUploadControl.text();
            this.saveOnIdexedDb(className, blob);
            const objParse = JSON.parse(text)
                .map(
                    parseObj => {
                        return Parse.Object.fromJSON({
                            className: className,
                            ...parseObj
                        })
                    }
                )
                .filter(parseObj => parseObj.potenzaTotale > 15)
            return objParse
        }
    }


    private getAllFile(): Observable<any[]> {
        return new Observable(subscriber => {
            const dbconnect = window.indexedDB.open(this.dbName);
            dbconnect.onsuccess = ev => {
                const db = (ev.target as any).result;
                // const transaction = this.putValue(db, className, file);
                const store = db.transaction(this.projectId, 'readonly').objectStore(this.projectId);
                //const query = store.get(1); // Einzel-Query
                const query = store.openCursor();
                query.onerror = ev => {
                    subscriber.error(ev.target.error.message);
                    subscriber.complete();
                };
                query.onsuccess = ev => {
                    const cursor = ev.target.result;
                    if (cursor) {
                        subscriber.next(cursor.value);
                        cursor.continue();
                    } else {
                        subscriber.complete();
                    }
                }
            }
        })
    }


    private getFileForClassName(className: string): Observable<any> {

        return new Observable(subscriber => {
            const dbconnect = window.indexedDB.open(this.dbName);
            dbconnect.onsuccess = ev => {

                const db = (ev.target as any).result;
                // const transaction = this.putValue(db, className, file);
                const store = db.transaction(this.projectId, 'readonly').objectStore(this.projectId);
                var index = store.index('classname');
                index.get(className).onsuccess = function (event) {
                    subscriber.next({classname: event.target.result.classname, value: event.target.result.value});
                    subscriber.complete();
                };
                //const query = store.get(1); // Einzel-Query
                // const query = store.openCursor();
                // query.onerror = ev => {
                //     subscriber.error(ev.target.error.message);
                //     subscriber.complete();
                // };
                // query.onsuccess = ev => {
                //     const cursor = ev.target.result;
                //     if (cursor.value.classname === className) {
                //         subscriber.next(cursor.value);
                //         subscriber.complete();
                //     } else {
                //         subscriber.complete();
                //     }
                // }
            }
        })
    }


    public paginationObservableArray(page = 0, array: any[], numberElementForCycle = 500) {
        const lengthArray = array.length;
        const lastElement = (page + numberElementForCycle < lengthArray) ? page + numberElementForCycle : lengthArray;
        const res = of(array.slice(page, lastElement))
        return res.pipe(
            delay(100),
            map((item) => {
                const nextPage = (page + numberElementForCycle) >= lengthArray ? undefined : (page += numberElementForCycle);
                return {
                    items: item,
                    nextPage: nextPage,
                    finished: !nextPage
                };
            }));
    }

    getPuntiLuceFromLocale(): Observable<{
        puntiLuce: PuntiLuceParse[],
        finished: boolean
    }> {
        return this.getFileForClassName(className.puntiLuce)
            .pipe(
                switchMap((cursorValue: any) => {
                    return forkJoin([fromPromise(cursorValue.value.text()), of(cursorValue.classname)])
                }),
                map((stringValue: string[]) => {
                    if (isNotNullOrUndefined(JSON.parse(stringValue[0]))) {
                        return JSON.parse(stringValue[0]).map(
                            el => {
                                const objParse = Parse.Object.fromJSON({
                                    ...el,
                                    className: stringValue[1]
                                });
                                if (isNotNullOrUndefined(el) && isNotNullOrUndefined(el['icon'])) {
                                    objParse['icon'] = el['icon'];
                                }
                                return objParse;
                            }
                        )
                    } else return [];
                }),
                switchMap(item => {
                    return this.paginationObservableArray(0, item, 10000).pipe(
                        expand(({nextPage, items}) => nextPage ? this.paginationObservableArray(nextPage, item, 10000) : EMPTY),
                        concatMap(({items, finished}) => {
                            return of({puntiLuce: items as PuntiLuceParse[], finished: finished});
                        })
                    )
                })
            ) as Observable<{
            puntiLuce: PuntiLuceParse[],
            finished: boolean
        }>
    }


    getAllParseFromLocale() {
        return this.getAllFile().pipe(
            switchMap((cursorValue: any) => {
                return forkJoin([fromPromise(cursorValue.value.text()), of(cursorValue.classname)])
            }),
            map((stringValue: string[]) => {
                if (isNotNullOrUndefined(JSON.parse(stringValue[0]))) {
                    return JSON.parse(stringValue[0]).map(
                        el => Parse.Object.fromJSON({
                            ...el,
                            className: stringValue[1]
                        })
                    )
                } else return [];
            }),
            toArray()
        )
    }

    private updateFile(db, className, file) {
        const store = db.transaction(this.projectId, 'readwrite').objectStore(this.projectId);
        //const query = store.get(1); // Einzel-Query
        const query = store.openCursor();
        query.onerror = ev => {
            console.error('Errore!', ev.target.error.message);
        };
        query.onsuccess = ev => {
            const cursor = ev.target.result;
            if (cursor) {
                if (cursor.value.classname === className) {
                    const request = cursor.update({value: file, classname: className});
                    request.onsuccess = ev => {
                        console.log('up eseguito');
                        db.close();
                    }
                    request.onerror = ev => {
                        console.log('up annullato');
                        db.close();
                    }
                } else {
                    cursor.continue();
                }
            } else {
                db.close();
            }
        };
    }

    private putValue(db, className, file) {
        const transaction = db.transaction(this.projectId, 'readwrite');
        const store = transaction.objectStore(this.projectId);
        const data = [
            {value: file, classname: className}
        ];
        data.forEach(el => store.add(el, className));
        transaction.onerror = ev => {
            this.updateFile(db, className, file);
        };
    }


    getVersionDb(): Observable<number> {
        return new Observable(subscriber => {
            const request = indexedDB.open(this.dbName);
            request.onsuccess = (e: any) => {
                const database = e.target.result;
                const version = parseInt(database.version);
                database.close();
                subscriber.next(version);
                subscriber.complete();
            }
        })
    }

    private delete(db, className) {
        const store = db.transaction(this.projectId, 'readwrite').objectStore(this.projectId);
        const query = store.openCursor();
        query.onerror = ev => {
            console.error('Errore!', ev.target.error.message);
        };
        query.onsuccess = ev => {
            const cursor = ev.target.result;
            if (cursor) {
                if (cursor.value.classname === className) {
                    const request = cursor.delete();
                    request.onsuccess = ev => {
                    }
                    request.onerror = ev => {
                    }
                }
                cursor.continue();
            }
        };
    }

    removeObjecStore() {
        this.getVersionDb().subscribe(version => {
            let dbconnect = window.indexedDB.open(this.dbName, version + 1);
            dbconnect.onupgradeneeded = ev => {
                console.log('delete', version, this.projectId);
                const db = (ev.target as any).result;
                if (db.objectStoreNames.contains(this.projectId)) {
                    db.deleteObjectStore(this.projectId);
                }
                db.close();
            }
        })
    }

    private createObjectStore(db: any) {
        const store = db.createObjectStore(this.projectId, {autoIncrement: true});
        store.createIndex('classname', 'classname', {unique: true});
        store.createIndex('value', 'value', {unique: false});
    }


    dbExistForProject(): Observable<boolean> {
        return new Observable(subscriber => {
            let dbconnect = window.indexedDB.open(this.dbName, undefined);
            dbconnect.onsuccess = ev => {
                const db = (ev.target as any).result;
                subscriber.next(db.objectStoreNames.contains(this.projectId));
                db.close();
                subscriber.complete()
            }
        })
    }

    saveOnIdexedDb(className, elementList) {
        // work on chrome
        // const $window: any = window as any;
        // console.log(navigator)
        // // console.log($window.requestFileSystem , $window.webkitRequestFileSystem);
        // $window.requestFileSystem = $window.requestFileSystem || $window.webkitRequestFileSystem;
        // $window.requestFileSystem($window.TEMPORARY, 5 * 1024 * 1024 /*5MB*/, fs => this.readFile(fs), this.errorHandler);
        let dbconnect = window.indexedDB.open(this.dbName, undefined);
        dbconnect.onupgradeneeded = ev => {
            const db = (ev.target as any).result;
            this.createObjectStore(db);
            db.close();
            console.log("change");
        }

        dbconnect.onsuccess = ev => {
            const keys = new PuntiLuceParse().allProperty();
            const pls = elementList.map(pl => {
                const obj = {}
                keys.forEach(
                    key => {
                        obj[key] = pl[key]
                    }
                );
                return obj;
            });
            const csvData = JSON.stringify(pls);
            const blob = new Blob(['\ufeff' + csvData], {type: 'text/json'});
            const db = (ev.target as any).result;
            if (!db.objectStoreNames.contains(this.projectId)) {
                this.getVersionDb().subscribe((version: number) => {
                    db.close();
                    dbconnect = window.indexedDB.open(this.dbName, version + 1);
                    dbconnect.onupgradeneeded = ev => {
                        const db = (ev.target as any).result;
                        this.createObjectStore(db);
                        console.log("change");
                    }
                    dbconnect.onsuccess = ev => {
                        const db = (ev.target as any).result;
                        console.log("successo");
                        const transaction = this.putValue(db, className, blob);
                    }
                    console.log(version);

                })
            } else {
                const transaction = this.putValue(db, className, blob);
            }
        };
    }


    /**
     * sdk parse
     *
     *  public inti() {
        // Parse.CoreManager.setStorageController(Parse.IndexedDB);
        Parse.enableLocalDatastore();
    }

     async getWithObjectId(objectId) {
        const query = new Parse.Query(CircuitiParse);
        query.equalTo('objectId', objectId)
        query.fromLocalDatastore();
        return await query.find();
    }


     async getAll(nome: string) {
        const allObjs = [];
        let objs = [];
        let i = 0;
        const pag = 3000;
        while (objs.length == pag || i == 0) {
            const query = new Parse.Query(PuntiLuceParse);
            query.fromLocalDatastore();
            query.fromPinWithName(nome);
            query.ascending('objectId');
            query.skip(i * pag);
            query.limit(pag);
            objs = await query.find();
            allObjs.push(...objs);
            i++;
        }
        return allObjs.slice(0, 1);
    }

     async saveAll(list: any [], name: string) {
        let i = 0;
        const numberOngSave = 10;
        const savedElement = [];
        while (i < list.length) {
            const lastElement = (i + numberOngSave < list.length) ? i + numberOngSave : list.length;
            savedElement.push(await Parse.Object.pinAllWithName(name, list.slice(i, lastElement)));
            i += numberOngSave;
        }
        return savedElement;

    }
     *
     */


    /**
     * file system work only chrome
     *
     *   public readFile(fs) {
        function errorHandler(e) {
            var msg = '';
            console.log('Error: ' + msg);
        }

        fs.root.getFile('log.txt', {}, function (fileEntry) {

            // Get a File object representing the file,
            // then use FileReader to read its contents.
            fileEntry.file(function (file) {
                var reader = new FileReader();

                reader.onloadend = function (e) {
                    console.log(JSON.parse(e.target.result as string))
                };

                reader.readAsText(file);
                console.log(reader);
            }, errorHandler);

        }, errorHandler);
    }

     public writeFile(fs, file) {
        function errorHandler(e) {
            var msg = '';
            console.log('Error: ' + msg);
        }

        fs.root.getFile('log.txt', {create: true}, function (fileEntry) {
            // Create a FileWriter object for our FileEntry (log.txt).
            fileEntry.createWriter(function (fileWriter) {

                fileWriter.onwriteend = function (e) {
                    console.log('Write completed.');
                };

                fileWriter.onerror = function (e) {
                    console.log('Write failed: ' + e.toString());
                };

                // Create a new Blob and write it to log.txt.

                fileWriter.write(file);

            }, errorHandler);

        }, errorHandler);

    }

     errorHandler(e) {
        var msg = '';
        console.log('Error: ' + msg);
    }

     */

}