<google-map height="100%" width="100%"
            (mapInitialized)="mapIsReady($event)"
            (boundsChanged)="mapBoundChange($event)"
>
    <ng-container *ngIf=" markerLightPoints$|async as markersPuntiuce">
        <ng-container *ngFor="let marker of markersPuntiuce.elementsInMap;trackBy:trackRowsPuntiLuce">
            <map-marker
                    (mapClick)="clickPuntoLuce(marker)"
                    [icon]="marker.icon"
                    [position]="marker.position"
                    [label]="marker.labelMarker"
            >
            </map-marker>
        </ng-container>
        <ng-container *ngFor="let cluster of markersPuntiuce.clustersInMap">
            <map-marker
                    (mapClick)="clusterClick(cluster)"
                    [position]="cluster.center"
                    [icon]="cluster.icon"
                    [options]="{zIndex:1100}"

            >
            </map-marker>
        </ng-container>

    </ng-container>
    <ng-container *ngIf=" circuiti|convertValueByPredicateFunction:getPredicateMarkerType as markersCircuiti">
        <map-marker *ngFor="let marker of markersCircuiti"
                    (mapClick)="clickCircuito(marker)"
                    [icon]="marker.icon"
                    [position]="marker.position"
                    [label]="marker.labelMarker"
        >

        </map-marker>
    </ng-container>
    <map-info-window (closeclick)="closeInfoWindow()">
        <ng-container *ngIf="infoWindowValue$|async as infoWindowValue">
            <h4 *ngIf="infoWindowValue.traductions!=null">
                <ng-container
                        *ngFor="let traducion of infoWindowValue.traductions;let first = first">
                    <ng-container *ngIf="!first">
                        /
                    </ng-container>
                    {{traducion|translate}}
                </ng-container>
            </h4>
            <div *ngIf="infoWindowValue.values!=null">
                <ng-container *ngFor="let value of infoWindowValue.values;let first = first">
                    <ng-container *ngIf="!first">
                        /
                    </ng-container>
                    {{value}}
                </ng-container>
            </div>
        </ng-container>
    </map-info-window>
</google-map>
