import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {arrayIsSet, chiaviScala} from '../../../models/Models';
import {TypeDatalabel, valueDatalabel} from '../../../providers/services/linea-elettrica.service';

export interface DataTypeLegend {
    type: TypeDatalabel;
    key: string | number | Date;
    color: string;
}


@Component({
    selector: 'app-legend-key-color',
    templateUrl: './legend-key-color.component.html',
    styleUrls: ['./legend-key-color.component.scss']
})
export class LegendKeyColorComponent implements OnInit, OnChanges {

    @Input() public data: DataTypeLegend[];
    @Input() public titleSxColumn: string;
    @Input() public titleDxColumn: string;
    displayedColumns: string[] = ['color', 'key'];
    types = valueDatalabel;

    private chiaviScala = chiaviScala;

    constructor() {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.data) {
            if (!arrayIsSet(this.data)) {
                this.data = [
                    {
                        key: this.chiaviScala.non_specificato,
                        color: this.chiaviScala.blackColor,
                        type: this.types.traduction
                    },
                    {
                        key: this.chiaviScala.altro,
                        color: this.chiaviScala.greyColor,
                        type: this.types.traduction
                    },
                ];
            }
        }
    }

}
