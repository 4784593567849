<ng-container [formGroup]="form">
    <mat-radio-group formControlName="selected">
        <div class="flex-start-stretch flex-flow-row w100">
            <form class="flex-start-center flex-flow-column" [ngClass]="classSizeBox">
                <mat-card class="box-filter" style="padding: 14px">
                    <mat-card-content>
                        <mat-form-field appearance="outline" style="margin-bottom: -14px;margin-top: -5px;">
                            <mat-label>{{'filterName'|translate}}</mat-label>
                            <input matInput formControlName="searchName">
                        </mat-form-field>
                    </mat-card-content>
                </mat-card>
                <mat-card style="padding: 0" class="w100 h100" *ngIf="addNew">
                    <div style="height: 48px;" class="flex-center">
                        <span class="text-title"> {{'fotoTipologia.createNew'|translate}}</span>
                    </div>
                    <mat-card-content style="max-width: 100%;max-height: 100%;height: calc(100% - 60px)">
                        <div class="h100">
                            <ngx-dropzone
                                    [disabled]="savingNewFoto"
                                    style="width:calc(100% - 10px);margin:5px;height:100%!important;"
                                    class="drop-zone-item-background"
                                    accept="image/jpeg,image/jpg,image/png"
                                    (change)="onLoadImage($event.addedFiles)"
                            >
                                <ngx-dropzone-label>
                                    <ng-container *ngIf="!savingNewFoto;else Loading">
                                        {{ 'fileManager.copyFile' | translate}}
                                    </ng-container>
                                </ngx-dropzone-label>
                            </ngx-dropzone>
                        </div>
                    </mat-card-content>
                </mat-card>
            </form>
            <mat-card [ngClass]="classSizeBox"
                      [ngStyle]="{cursor:foto|convertValueByPredicateFunction:cursorPredicate:loadingFotoTipologia}"
                      *ngFor="let foto of fotoTipologie|convertValueByPredicateFunction:filterPredicate:(searchName$|async)"
                      (click)="openFullImage(foto)">
                <div style="height: 48px;padding: 5px" class="flex-center">
                    <mat-radio-button [value]="foto.objectId" *ngIf="selectable;else NoSelectable"
                                      (click)="$event.stopPropagation()">
                        <span class="text-title"> {{foto.name}}
                        </span>
                    </mat-radio-button>
                    <ng-template #NoSelectable>
                        <div style="height: 48px;" class="flex-center">
                            <mat-spinner [diameter]="20" style="margin-right: 10px"
                                         *ngIf="foto|convertValueByPredicateFunction:areLoadingPredicate:loadingFotoTipologia">
                            </mat-spinner>
                            <div class="text-title">
                                {{foto.name}}
                            </div>
                        </div>
                    </ng-template>
                </div>
                <mat-card-content style="max-width: 100%;max-height: 100%">
                    <img style="max-width: 100%;max-height: 100%"
                         class="box-image" [src]="foto.url">
                </mat-card-content>
                <!--                <mat-card-actions style="margin: 0 0 5px 0;padding: 0;display: flex;justify-content: space-evenly"-->
                <!--                                  class="w100">-->
                <!--&lt;!&ndash;                    <button mat-flat-button color="warn" (click)="deleteImage(foto)">&ndash;&gt;-->
                <!--&lt;!&ndash;                        <mat-icon>delete</mat-icon>&ndash;&gt;-->
                <!--&lt;!&ndash;                    </button>&ndash;&gt;-->
                <!--&lt;!&ndash;                    <button mat-flat-button color="accent">&ndash;&gt;-->
                <!--&lt;!&ndash;                        <mat-icon>edit</mat-icon>&ndash;&gt;-->
                <!--&lt;!&ndash;                    </button>&ndash;&gt;-->
                <!--&lt;!&ndash;                    <button mat-flat-button color="primary">&ndash;&gt;-->
                <!--&lt;!&ndash;                        <mat-icon>fullscreen</mat-icon>&ndash;&gt;-->
                <!--&lt;!&ndash;                    </button>&ndash;&gt;-->
                <!--                </mat-card-actions>-->
            </mat-card>
        </div>
    </mat-radio-group>
</ng-container>
<ng-template #Loading>
    <mat-spinner [diameter]="20"></mat-spinner>
</ng-template>