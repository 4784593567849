import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MoveToFolderComponent} from './move-to-folder/move-to-folder.component';
import {MatIconModule} from "@angular/material/icon";
import {TranslateModule} from "@ngx-translate/core";
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import {WidgetsModule} from "../../widgets/widgets.module";
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from "@angular/material/legacy-progress-spinner";
import { NavigateInFileAndFolderComponent } from './navigate-in-file-and-folder/navigate-in-file-and-folder.component';
import {PipesModule} from "../../providers/pipes/pipes.module";
import {MatLegacyDialogModule as MatDialogModule} from "@angular/material/legacy-dialog";
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatExpansionModule } from '@angular/material/expansion';


@NgModule({
    declarations: [MoveToFolderComponent, NavigateInFileAndFolderComponent],
    imports: [
        CommonModule,
        MatIconModule,
        TranslateModule,
        MatCardModule,
        MatButtonModule,
        WidgetsModule,
        MatProgressSpinnerModule,
        PipesModule,
        MatDialogModule,
        MatChipsModule,
        MatListModule,
        MatExpansionModule
    ]
})
export class PopUpMoveInFolderModule {
}
