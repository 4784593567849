<div style="display: flex;align-items: center;justify-content: center;flex-wrap: wrap;flex-flow: column">
    <div>
        <button style="margin-right: 10px"
                mat-flat-button color="primary" (click)="closeDialog()">
            <mat-icon>clear</mat-icon>
        </button>
        <button mat-flat-button color="primary" (click)="callPrint()" [disabled]="false"
                *ngIf="!loaderPrint;else printing">
            <mat-icon>print</mat-icon>
        </button>
        <ng-template #printing>
            <div style="width: 50px">
                <mat-spinner diameter="40"></mat-spinner>
            </div>
        </ng-template>
    </div>
    <app-container-a4 class="printableArea">
        <div style="display: flex;align-items: center;justify-content: space-around;flex-wrap: wrap;flex-direction: column;height: 250mm">
            <header style="
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);border-radius:3mm;
            display: flex;justify-content: space-around;
            align-items: center;
            flex-wrap: wrap;
            flex-flow: row;
            width: 180mm">
                <img
                        class="imgLogo" [src]="urlLogoOrganizzazione"
                        style="width: 30mm;padding: 4mm"
                        alt="logo" *ngIf="!!data.organizzazione.logo">
                <h2>{{data.organizzazione.nome}}</h2>
            </header>
            <h1>{{'reportEnergy.printTitle'|translate}}</h1>
            <h2 style="margin-top:-15mm;" *ngIf="data.dataRiferimento">{{data.dataRiferimento|date:'MMMM yyyy'}}</h2>
            <h2 style="margin-top:-15mm;">{{projectName}}</h2>
            <h2 style="margin-top:-15mm;">{{'reportEnergy.totalPOD'|translate}} {{(!!data.chart) ? data.chart.length - 1 : 0}}</h2>
            <footer style="
            width: 150mm;
            margin-bottom: -10mm;
            display: flex;
            align-items: center;
            justify-content: space-around;
            text-align: center;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
            border-radius:3mm;">
                <img class="img" src="/assets/logo.png" style="height: 10mm;;padding: 4mm"
                     alt="huna">
                <b>{{today |customDate :'longDate'|async}}</b>
            </footer>
        </div>
    </app-container-a4>
    <ng-container *ngFor="let chart of data.chart; first as isFirst;">
        <app-container-a4
                [nowDate]="today"
                [logoAzienda]="urlLogoOrganizzazione"
                [nomeAzienda]="data.organizzazione.nome"
                [projectName]="projectName"
                page="auto" class="printableArea">
            <ng-container *ngIf="!isFirst;else noFirst">
                <h3 style="width: 90mm;text-align: center">{{'dashboard_sidenav.Circuiti.numeroQuadro.title'|translate}}
                    : {{chart.circuito.numeroQuadro}}</h3>
                <h3 style="width: 90mm;text-align: center">{{'dashboard_sidenav.Circuiti.POD.title'|translate}}
                    : {{chart.circuito.numeroPod}}</h3>
            </ng-container>
            <ng-template #noFirst>
                <h3 style="width: 200mm;text-align: center">{{'reportEnergy.summAllCircuit'|translate}}</h3>
            </ng-template>
            <div style="width:185mm;">
                <h4 *ngIf="!isFirst"
                    style="margin-bottom: 2mm;width: 185mm;text-align: center">{{'reportEnergy.incidentAverage'|translate}}
                    : {{getIncidentAverage(chart, 'curvaOrariaPotenze')}}</h4>
                <div style="width: 185mm;height: 50mm">
                    <img class="img" [src]="chart.data.consumoOrario"
                         style="width: 185mm;height:50mm;"
                         alt="logo" *ngIf="!!chart.data.consumoOrario">
                </div>
            </div>
            <div style="width:185mm;">
                <h4 *ngIf="!isFirst"
                    style="margin-bottom: 2mm;width: 190mm;text-align: center">{{'reportEnergy.incidentAverage'|translate}}
                    : {{getIncidentAverage(chart, 'energiaAttivaGiornaliera')}}</h4>
                <div style="width: 185mm;height: 50mm">
                    <img class="img" [src]="chart.data.energiaAttiva"
                         style="width: 185mm;height:50mm;"
                         alt="logo" *ngIf="!!chart.data.energiaAttiva">
                </div>
            </div>
            <div style="width:185mm;">
                <h4 *ngIf="!isFirst"
                    style="margin-bottom: 2mm;width: 185mm;text-align: center">{{'reportEnergy.incidentAverage'|translate}}
                    : {{getIncidentAverage(chart, 'energiaReattivaGiornaliera')}}</h4>
                <div style="width: 185mm;height: 50mm">
                    <img class="img" [src]="chart.data.energiaReattiva"
                         style="width: 185mm;height:50mm;"
                         alt="logo" *ngIf="!!chart.data.energiaReattiva">
                </div>
            </div>
        </app-container-a4>
        <app-container-a4
                [nowDate]="today" [logoAzienda]="urlLogoOrganizzazione"
                [nomeAzienda]="data.organizzazione.nome"
                [projectName]="projectName"
                page="auto" class="printableArea">
            <ng-container *ngIf="!isFirst;else noFirst">
                <h3 style="width: 90mm;text-align: center">{{'dashboard_sidenav.Circuiti.numeroQuadro.title'|translate}}
                    : {{chart.circuito.numeroQuadro}}</h3>
                <h3 style="width: 90mm;text-align: center">{{'dashboard_sidenav.Circuiti.POD.title'|translate}}
                    : {{chart.circuito.numeroPod}}</h3>
            </ng-container>
            <ng-template #noFirst>
                <h3 style="width: 200mm;text-align: center">{{'reportEnergy.summAllCircuit'|translate}}</h3>
            </ng-template>
            <div style="width:190mm;">
                <div style="width: 120mm;height: 60mm">
                    <img class="img" [src]="chart.data.F1F2F3"
                         style="width: 160mm;height:40mm;"
                         alt="logo" *ngIf="!!chart.data.F1F2F3">
                </div>
            </div>
            <div style="width:190mm;margin-left:5mm;display: flex;align-items: center;justify-content: center;flex-wrap: wrap;flex-direction: row">
                <div style="width: 90mm;height: 50mm">
                    <app-huna-print-table
                            class="#my-table"
                            widthTable="90mm"
                            [verticalFirstRowIsBold]="false"
                            [verticalTitle]="'reportEnergy.power.title'"
                            [orientation]="'vertical'"
                            [dataSet]="getTable(chart,'power')">
                    </app-huna-print-table>
                </div>
                <div style="width: 90mm;height: 50mm">
                    <app-huna-print-table
                            widthTable="90mm"
                            [verticalFirstRowIsBold]="false"
                            [verticalTitle]="'reportEnergy.costConsuption.title'"
                            [orientation]="'vertical'"
                            [dataSet]="getTable(chart,'costConsuption')">
                    </app-huna-print-table>
                </div>
                <div style="width: 90mm;height: 50mm">
                    <app-huna-print-table
                            widthTable="90mm"
                            [verticalFirstRowIsBold]="false"
                            [verticalTitle]="'reportEnergy.yearConsuption.title'"
                            [orientation]="'vertical'"
                            [dataSet]="getTable(chart,'yearConsuption')">
                    </app-huna-print-table>
                </div>
                <div style="width: 90mm;height: 50mm" *ngIf="getTable(chart,'hour').length>0">
                    <app-huna-print-table
                            widthTable="90mm"
                            [verticalFirstRowIsBold]="false"
                            [verticalTitle]="'reportEnergy.hour.title'"
                            [orientation]="'vertical'"
                            [dataSet]="getTable(chart,'hour')">
                    </app-huna-print-table>
                </div>
            </div>
        </app-container-a4>
    </ng-container>
</div>

