<div class="container">
    <app-template-form subtitle="{{'create_new_plan' | translate}}" title="{{'plan' | translate}}" [informativa]="false"
                       *ngIf="canICreatePlan;else noCreatePlan">
        <form *ngIf="planForm" [formGroup]="planForm">
            <mat-form-field floatLabel="auto" appearance="fill">
                <mat-label>{{ 'file_name' | translate}}</mat-label>
                <input formControlName="name" matInput required>
            </mat-form-field>
            <div class="spacer">
                <ng-container *ngIf="!creatingPlan;else notCreatingPlan">
                    <button matTooltip="Salva" mat-flat-button color="primary" class=""
                            (click)="createPlan()">{{ 'create' |
                        translate}}
                    </button>
                </ng-container>
                <ng-template #notCreatingPlan>
                    <mat-spinner [diameter]="20"></mat-spinner>
                </ng-template>
            </div>
        </form>
    </app-template-form>
    <ng-template #noCreatePlan>
        <app-template-form subtitle="{{'create_new_plan' | translate}}"
                           title="{{'plan' | translate}}" [informativa]="false">
            <div mat-card-content>
                <div>
                    {{'create_plan_error' | translate}}
                </div>
            </div>
        </app-template-form>
    </ng-template>
</div>
