import {Component, NgZone, OnDestroy, OnInit} from "@angular/core";
import {MapService} from "src/app/providers/services/map.service";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {
    Street,
    StreetsService,
    StreetLayoutItemType, StreetStateInQueue, statusStreetInQueue,
} from "src/app/providers/services/streets.service";
import {
    ColumnConfig,
    DataSourceResult,
    Filter,
    ColumnsOrdering,
    AsyncDataSource,
} from "src/app/core/paginator/paginator.component";
import {AddingTabInComponentService} from "../../../providers/services/adding-tab-in-component.service";
import {GruppoFotometrieParse} from "../../../models/GruppoFotometrie.Parse";
import {GruppoFotometrieService} from "../../../providers/services/gruppo-fotometrie.service";
import {AlertService} from "../../../providers/services/alert.service";
import jsPDF from "jspdf";
import {
    filter,
    map,
    pairwise,
    switchMap,
} from "rxjs/operators";
import {fromPromise} from "rxjs/internal-compatibility";
import {
    arrayIsSet,
    className,
    cleanToSpecialCharacterAndLowerCaseString, getUniqueValueInArray, KeyStringValueAny,
    stringIsSet
} from "../../../models/Models";
import {PrintPageService} from "../../../providers/services/print-page.service";
import {EMPTY, forkJoin, Observable, of, throwError} from "rxjs";
import {FotometriaParse} from "../../../models/Fotometria.Parse";
import {StradeParse} from "../../../models/Strade.Parse";
import {FotometrieUtilsService} from "../../../providers/services/fotometrie-utils.service";
import {DialogPopUpInfoService} from "../../../providers/services/dialog-pop-up-info.service";
import {DownloadJsonHeaderType, FileServiceService} from "../../../providers/services/file-service.service";
import {DialogPopUpService} from "../../../providers/services/dialog-pop-up.service";
import {ParseApiService} from "../../../providers/services/parse-api.service";
import {TranslateParseErrorService} from "../../../providers/services/translate-parse-error.service";

@Component({
    selector: "app-list-streets",
    templateUrl: "./list-streets.component.html",
    styleUrls: ["./list-streets.component.scss"],
})
export class ListStreetsComponent implements OnInit, OnDestroy {
    columns: ColumnConfig[];
    streetSummariesPromise: Promise<StreetSummary[]>;
    public openedStreetList = [];
    public forceUpdateTab: boolean;
    public indexTab;
    public photometries = [];
    gruppiFotometrieInDataBase: GruppoFotometrieParse[]

    disable = {groupPhotometry: false, printAll: false}
    loading = {
        printAll: {progress: 0, active: false},
        destroyAll: {progress: 0, active: false},
        export: {progress: 0, active: false},
        abortProcessCalc: {progress: undefined, active: false},
    }
    forceUpdateList;
    forceUpdateItemByList;
    elementChecked = undefined;
    valuesStatusStreet$: Observable<StreetStateInQueue>;
    predicateFunctionValuesInStreet = (strada: any, valuesStatusStreet: StreetStateInQueue) => {
        if (valuesStatusStreet[strada.objectId] != null) {
            const status = valuesStatusStreet[strada.objectId].status
            const notTraduction = valuesStatusStreet[strada.objectId].message;
            if (status === statusStreetInQueue.WAITING) {
                return {icon: {material: 'pending', color: 'primary'}, traduction: 'waiting'}
            } else if (status === statusStreetInQueue.PROCESSING) {
                return {icon: {material: 'fmd_bad', color: 'primary'}, traduction: 'processing'}
            } else if (status === statusStreetInQueue.ERROR) {
                return {
                    icon: {material: 'error', color: 'warn'},
                    notTraduction
                }
            }
            return 'pending'
        }

        return 'icon'
    }


    constructor(
        private router: Router,
        protected mapService: MapService,
        private translateService: TranslateService,
        private alertService: AlertService,
        public streetsService: StreetsService,
        private parseApiUtilsService: ParseApiService,
        private printPageService: PrintPageService,
        private addingTab: AddingTabInComponentService,
        private gruppoFotometrieService: GruppoFotometrieService,
        private fotometrieservice: FotometrieUtilsService,
        private openDialogInfo: DialogPopUpInfoService,
        private myDialog: DialogPopUpService,
        private fileService: FileServiceService,
        translateParseErrorService: TranslateParseErrorService
    ) {

        this.valuesStatusStreet$ = this.streetsService.streetsInQueue$.pipe(
            map((values) => {
                Object.keys(values).forEach(idStreet => {
                    if (values[idStreet].message != null) {
                        const message = translateParseErrorService.getTraductionByError(values[idStreet].message)
                        values[idStreet].message = message;
                    }
                })
                return values;
            })
        );
        this.valuesStatusStreet$.pipe(
            pairwise(),
            map(([previus, current]) => {
                const idsPrevius = Object.keys(previus);
                const idsCurrent = Object.keys(current);
                if (idsPrevius.length !== idsCurrent.length) {
                    return true
                } else {
                    let index = idsPrevius.findIndex(id => !idsCurrent.includes(id))
                    if (index < 0) {
                        index = idsCurrent.findIndex(id => !idsPrevius.includes(id))
                    }
                    return index >= 0
                }
            }),
            filter((change) => change)
        ).subscribe(() => {
            this.initVariable().then(() => {
                this.forceUpdateList = !this.forceUpdateList
                this.forceUpdateItemByList = !this.forceUpdateItemByList
            })
        })
        this.columns = [
            {
                label: "streets.name",
                property: "nome",
                width: '150px',
                sticky: true
            }, {
                label: "streets.photometry",
                property: "fotometrie",
                type: 'photometry',
                render: (x: FotometriaParse[] | undefined) => {
                    if (!arrayIsSet(x)) {
                        return undefined
                    } else {
                        return x[0].nomeFile
                    }
                },
            }, {
                label: "streets.gruppoFotometrie",
                property: "gruppoFotometrie",
                type: 'groupPhotometry',
                render: (x: GruppoFotometrieParse) => {
                    return x != null ? x.name : undefined
                }
            }, {
                label: "streets.isCentroStorico",
                property: "isCentroStorico",
                type: 'checkBox'
            }, {
                label: "streets.includiMarciapiedi",
                property: "includiMarciapiedi",
                type: 'checkBox'
            }, {
                label: "streets.calcoloSemicilindrico",
                property: "calcoloSemicilindrico",
                type: 'checkBox'
            }, {
                label: "streets.verificaIPEI_A",
                property: "verificaIPEI_A",
                type: 'checkBox'
            }, {
                label: "streets.noteCalcolo",
                property: "noteCalcolo",
                textAlign: 'start',
                render: (x: KeyStringValueAny) => {
                    return x != null ? Object.keys(x).filter(key => x[key] === true).toString() : undefined
                }
            },
            {
                label: "streets.classification",
                property: "classificazione",
                textAlign: 'start'
            },
            {
                label: "streets.width",
                property: "larghezza",
                width: '150px',
                textAlign: 'center'
            },
            {
                label: "streets.height",
                property: "altezza",
                width: '150px',
                textAlign: 'center'
            },
            {
                label: "streets.interdistance",
                property: "interdistanza",
                textAlign: 'end'
            },
            {
                label: "streets.notes",
                property: "note",
                width: '150px'

            },
            {
                label: "streets.created_at",
                property: "createdAt",
                render: (x: Date) => x.toLocaleDateString() + ' ' + x.toLocaleTimeString()
            },
            {
                label: "streets.updated_at",
                property: "updatedAt",
                render: (x: Date) => x.toLocaleDateString() + ' ' + x.toLocaleTimeString()
            },
            // {
            //     label: "streets.updated_at",
            //     property: "updatedAt2",
            //     photometryGroup: true
            // }
        ];
    }

    onStreetClick(item: StreetSummary) {
        this.getGruppiFotometrie().subscribe()
        const newElement = {
            objectId: item.objectId,
            titolo: item.nome,
            priority: null
        };
        const element = this.addingTab.addingElement(this.openedStreetList, newElement);
        this.openedStreetList = element.openedList;
        this.indexTab = {value: (element.index >= 0) ? element.index + 1 : 1};
        this.forceUpdateTab = !this.forceUpdateTab;
        // this.ngZone.run(() => {
        //     this.router.navigate(["/strade/dettaglio/", {
        //         id: item.objectId
        //     }]);
        // });
    }

    public itemModified(event) {
        this.openedStreetList = this.addingTab.removingElement(this.openedStreetList, event.item);
    }


    paginate: AsyncDataSource = (filter: Filter, numberElement: number | 'all', skip: number, order: ColumnsOrdering): Promise<DataSourceResult> => {
        const paginaTeStreetPromise = () => this.streetSummariesPromise
            .then((streets) => {
                    const filterStringIsSet = stringIsSet(filter.search)
                    const filterFunction = (s: StreetSummary) => {
                        let holdStreet;
                        const filterSearchKey = cleanToSpecialCharacterAndLowerCaseString(filter.search)
                        holdStreet = cleanToSpecialCharacterAndLowerCaseString(s.altezza.toString()).includes(filterSearchKey)
                            || cleanToSpecialCharacterAndLowerCaseString(s.classificazione).includes(filterSearchKey)
                            || cleanToSpecialCharacterAndLowerCaseString(s.larghezza).includes(filterSearchKey)
                            || cleanToSpecialCharacterAndLowerCaseString(s.nome).includes(filterSearchKey)
                            || cleanToSpecialCharacterAndLowerCaseString(s.note).includes(filterSearchKey)
                        return holdStreet;
                    }
                    if (filterStringIsSet) {
                        return streets.filter(filterFunction)
                    } else {
                        return streets;
                    }
                }
            )
            .then((streets) =>
                !order[Object.keys(order)[0]]
                    ? streets
                    : streets.sort((a, b) => {
                        // Supporto limitato a ordinamento singolo (prima chiave dell'oggetto order)
                        const multiplier = order[Object.keys(order)[0]] === "desc" ? -1 : 1;
                        const aValue = a[Object.keys(order)[0]];
                        const bValue = b[Object.keys(order)[0]];
                        if (aValue > bValue) {
                            return 1 * multiplier;
                        } else if (aValue < bValue) {
                            return -1 * multiplier;
                        } else {
                            return 0;
                        }
                    })
            )
            .then((summaries): DataSourceResult => ({
                items: (numberElement == 'all') ? summaries : summaries.slice(skip, skip + numberElement),
                total: summaries.length,
            }))
        return paginaTeStreetPromise()

    };


    async ngOnInit() {

        await this.initVariable();
        this.streetsService.initGetDynamicStreetInQueue();
    }

    ngOnDestroy() {
        this.streetsService.destroyGetDynamicStreetInQueue();
    }

    numberStreet = 0;

    async initVariable() {
        this.streetSummariesPromise = this.streetsService.getAll()
            .then((streets) => {
                    if (arrayIsSet(streets)) {
                        this.numberStreet = streets.length;
                    } else {
                        this.numberStreet = 0
                    }
                    return streets.map(
                        (street: Street): StreetSummary => {
                            return {
                                objectId: street.objectId,
                                gruppoFotometrie: street.gruppoFotometrie,
                                calcoloSemicilindrico: street.calcoloSemicilindrico,
                                isCentroStorico: street.isCentroStorico,
                                includiMarciapiedi: street.includiMarciapiedi,
                                verificaIPEI_A: street.verificaIPEI_A,
                                noteCalcolo: street.noteCalcolo,
                                fotometrie: street.fotometrie,
                                nome: street.nome || "",
                                classificazione: !street.layout
                                    ? "N/A"
                                    : street.layout.filter(x => x.tipo === StreetLayoutItemType.marciapiede || x.tipo === StreetLayoutItemType.carreggiata).map((x: any) => x.categoriaIlluminotecnica).join(" - "),
                                larghezza: !street.layout
                                    ? "N/A"
                                    : street.layout.map((x) => x.larghezza.toFixed(1)).join(" - "),
                                altezza: (() => {
                                    if (street.lampade && street.lampade.length > 0) {
                                        const minMax = street.lampade.reduce(
                                            (minMax, x) => ({
                                                max: minMax.max !== undefined ? Math.max(minMax.max, x.altezza) : x.altezza,
                                                min: minMax.min !== undefined ? Math.min(minMax.min, x.altezza) : x.altezza,
                                            }),
                                            {min: undefined, max: undefined}
                                        );

                                        return `min: ${minMax.min.toFixed(1)} - max: ${minMax.max.toFixed(1)}`;
                                    } else {
                                        return "N/A";
                                    }
                                })(),
                                interdistanza: street.interdistanza ? street.interdistanza.toFixed(1) : "N/A",
                                note: street.note || "",
                                createdAt: new Date(street.createdAt),
                                updatedAt: new Date(street.updatedAt)
                            }
                        }
                    )
                }
            );

        const filter: Filter = {search: undefined};
        const order: ColumnsOrdering = {};
        const take = 'all';
        const skip = 1;
        const {items, total} = await this.paginate(
            filter,
            take,
            skip,
            order
        );
        // this.getGruppiFotometrie().subscribe(
        //     gruppiFotomotrie => {
        //         this.groupPhotometries = gruppiFotomotrie;
        //         this.disable.groupPhotometry = false
        //     }, error => {
        //         this.disable.groupPhotometry = false
        //     }
        // )
        this.streetsService.updateValueStreetsInQueue();
        // this.photometries = await this.streetsService.getAllPhotometries();
    }

    indexChange(event) {
        this.indexTab = event;
    }

    openDialog() {
        this.disable.groupPhotometry = true;

        this.getGruppiFotometrie().pipe(
            switchMap((gruppi) => {
                const valueForm = gruppi.map(gruppo => {
                    return {
                        valueForm: gruppo.objectId,
                        html: gruppo.name
                    }
                })
                return this.gruppoFotometrieService.openCreateUpdateGruppoFotometrie(this.photometries, valueForm, gruppi)
            })
        )
            .subscribe(result => {
                    if (result) {
                        const index = this.gruppiFotometrieInDataBase.findIndex(gruppo => gruppo.objectId == result.objectId);
                        if (index < 0) {
                            this.gruppiFotometrieInDataBase.push(result);
                        } else {
                            this.gruppiFotometrieInDataBase[index] = result
                        }
                        const message = this.translateService.instant('alert.success');
                        this.alertService.success(message);
                    }
                    this.disable.groupPhotometry = false;
                },
                error => {
                    this.disable.groupPhotometry = false;
                    this.alertService.error(error)
                }, () => {
                    this.disable.groupPhotometry = false;
                })
    }

    setProgressPrint(key: string, progress: number, active: boolean) {
        if (this.loading[key] != null) {
            if (this.loading[key].progress != null && progress != null) {
                this.loading[key].progress = progress
            }
            if (this.loading[key].active != null && active != null) {
                this.loading[key].active = active
            }
            this.loading = {...this.loading}
        }
    }

    unSetProgressPrint(key: string) {
        if (this.loading[key] != null) {
            if (this.loading[key].progress != null) {
                this.loading[key].progress = 0
            }
            if (this.loading[key].active != null) {
                this.loading[key].active = false;
            }
            this.loading = {...this.loading}
        }
    }

    exportCsv() {
        const keyExport = 'export';
        this.setProgressPrint(keyExport, 1, true)
        this.getSelectStreet$()
            .pipe(
                map(streets => {
                    const {
                        maxNumberOfLayout,
                        maxNumberOfLight,
                        maxNumberOfFotometrie
                    } = this.streetsService.getMaxNumberOfLayoutMaxNumberOfLightMaxNumberOfFotometrie(streets);
                    const header = this.streetsService.getHeaderToExportStreets(maxNumberOfLayout, maxNumberOfLight, maxNumberOfFotometrie)
                    return {streets, header}
                })
            )
            .subscribe(streetsData => {
                this.setProgressPrint(keyExport, 30, true)
                const streetsCsv = []
                const streets = streetsData.streets
                const header = streetsData.header
                streets.map(street => {
                    streetsCsv.push(this.streetsService.getStreetJsonforCsv(street, header))
                })
                const headerTrad: DownloadJsonHeaderType[] = header.map(col => {
                    return {notTraduction: col.traduction}
                })
                const blobJson = this.fileService.getBlobByJson(this.streetsService.project.name, streetsCsv, headerTrad, ';', className.strade)
                const dataZip = [{
                    folderName: undefined,
                    name: this.translateService.instant('streets.page_title') + '.csv',
                    blob: blobJson
                }
                ]
                let fotometrieToBlob
                if (arrayIsSet(streetsData.streets)) {
                    fotometrieToBlob = [];
                    streetsData.streets.forEach(street => {
                        if (arrayIsSet(street.fotometrie)) {
                            fotometrieToBlob =
                                fotometrieToBlob.concat(
                                    street.fotometrie
                                        .filter((f: FotometriaParse) => f.fileLDT && f.fileLDT.url && f.fileLDT.url())
                                        .map((f: FotometriaParse) => {
                                            return {folderName: street.nome, name: f.nomeFile, url: f.fileLDT.url()};
                                        })
                                );

                        }
                    })
                }

                let downloadFiles$ = this.fileService.comprimiFiles(dataZip);
                if (arrayIsSet(fotometrieToBlob)) {
                    const differentFotometrieUrl = getUniqueValueInArray(fotometrieToBlob.map(f => f.url));
                    downloadFiles$ = forkJoin(
                        differentFotometrieUrl.map(url => this.fileService.getBlobByUrl(url).pipe(
                                map(blob => ({blob, url}))
                            )
                        )
                    ).pipe(
                        map((blobs: { blob: Blob, url: string }[]) => {
                            if (arrayIsSet(blobs)) {
                                const dataZipBlobFile = [];
                                fotometrieToBlob.forEach(f => {
                                    const index = blobs.findIndex(b => b.url == f.url)
                                    if (index >= 0) {
                                        delete f.url;
                                        const blob = blobs[index].blob;
                                        dataZipBlobFile.push({blob, ...f})
                                    }
                                })
                                return dataZip.concat(dataZipBlobFile)
                            } else {
                                return dataZip;
                            }
                        }),
                        switchMap((values) => {
                            return this.fileService.comprimiFiles(values)
                        })
                    )
                }
                downloadFiles$.subscribe(blob => {
                        this.fileService.openLink(blob, this.translateService.instant('streets.page_title') + '_' + this.streetsService.project.name + '.zip')
                        this.setProgressPrint(keyExport, 100, true)
                        this.unSetProgressPrint(keyExport);
                    },
                    error => {
                        this.alertService.error(error)
                        this.setProgressPrint(keyExport, 100, true)
                        this.unSetProgressPrint(keyExport);
                    }, () => {
                        this.setProgressPrint(keyExport, 100, true)
                        this.unSetProgressPrint(keyExport);
                    })

            })
    }

    printAll() {
        const streetWithPhotometry$ = this.getSelectStreet$().pipe(
            map(streets => streets
                .filter(street => arrayIsSet(street.fotometrie))
            )
        )

        const streetWithoutPhotometry$ = this.getSelectStreet$().pipe(
            map(streets => streets.filter(street => !arrayIsSet(street.fotometrie)))
        )

        const streetsError: { error: string }[] = [];
        const csvRow = [];
        this.disable.printAll = true;
        const keyloadPrint = 'printAll';
        this.setProgressPrint(keyloadPrint, 0.1, true)
        let print = false;
        const doc = new jsPDF();
        this.printPageService.addFont(doc);
        let headerFooterOptions;
        const progetto = this.streetsService.project;
        // this.myDialog.openDialogForDelete('vuoi includere i marciapiedi nel calcolo', "center")


        this.streetsService.projectWithOrganizzazioneComune$.pipe(
            map(progetto => {
                return {progetto}
            })
        ).pipe(
            switchMap(({progetto}) => {
                return streetWithPhotometry$.pipe(
                    map(strade => {
                        return {progetto, strade}
                    })
                );
            }),
            switchMap(({strade}) => {
                if (arrayIsSet(strade)) {
                    return this.streetsService.paginationComputeResultStreets$(strade)
                } else {
                    return of({
                        items: undefined,
                        progress: 100,
                        finished: true,
                        error: null
                    })
                }
            })
        )
            .subscribe(value => {
                    if (value != null) {
                        this.setProgressPrint(keyloadPrint, value.progress, !value.finished)
                    }
                    if (value.error == null && arrayIsSet(value.items)) {
                        const calcoliIlliminotecnici = value.items[0].calcoliilluminotecnici;
                        const strada = value.items[0].strada;
                        let results;
                        if (calcoliIlliminotecnici.bestResult != null) {
                            results = [calcoliIlliminotecnici.bestResult]
                        } else {
                            results = calcoliIlliminotecnici.allResults;
                        }
                        if (arrayIsSet(results) && arrayIsSet(results[0].risultati)) {
                            print = true;
                            let logoComune
                            if (progetto.htmlLogo != null && progetto.htmlLogo.url != null) {
                                logoComune = progetto.htmlLogo.url();
                            }
                            let nomeComune
                            if (progetto.comune != null && progetto.comune.nome != null) {
                                nomeComune = progetto.comune.nome;
                            }
                            const headerFooter = this.printPageService.streetPageLayoutType(doc, logoComune, nomeComune, strada, calcoliIlliminotecnici, true)
                            if (headerFooter != null) {
                                headerFooterOptions = headerFooter;
                            }
                            if (headerFooter != null && arrayIsSet(headerFooter.forCsv)) {
                                csvRow.push(...headerFooter.forCsv);
                            }
                        } else {
                            streetsError.push({error: strada.nome + ' no result'})
                        }
                    }
                    if (value.error != null) {
                        this.alertService.error(value.error)
                        streetsError.push({error: value.error.toString()})
                    }
                    if (value.finished) {
                        this.unSetProgressPrint(keyloadPrint)
                        let logoComune
                        if (progetto.htmlLogo != null && progetto.htmlLogo.url != null) {
                            logoComune = progetto.htmlLogo.url();
                        }
                        let logoAziendale
                        if (progetto.organizzazione != null && progetto.organizzazione.get('logo') != null && progetto.organizzazione.get('logo').url != null) {
                            logoAziendale = progetto.organizzazione.get('logo').url();
                        }
                        let nomeComune
                        if (progetto.comune != null && progetto.comune.nome != null) {
                            nomeComune = progetto.comune.nome;
                        } else {
                            nomeComune = progetto.name
                        }
                        doc.deletePage(doc.getNumberOfPages())
                        if (print) {
                            if (headerFooterOptions != null) {
                                this.printPageService.addHeaderFooter(doc, headerFooterOptions, nomeComune, logoComune, logoAziendale)
                            }
                            this.printPageService.convertToPdf(doc, this.translateService.instant('streets.nameFile.calcoloIlluminotecnico') + '_' + progetto.name)
                            this.disable.printAll = false;
                            const dataToCsv = this.streetsService.getDataCsv(csvRow)
                            this.fileService.dowloadJson(this.translateService.instant('streets.nameFile.csvFile') + '_' + progetto.name, dataToCsv.rows, dataToCsv.header, ';')
                        }
                        streetWithoutPhotometry$.subscribe(strade => {
                            if (arrayIsSet(strade)) {
                                strade.forEach(strada => {
                                    streetsError.push({error: strada.nome + ', ' + this.translateService.instant('fotometria.notPresent')})
                                })
                            }
                            if (arrayIsSet(streetsError)) {
                                this.fileService.dowloadJson(this.translateService.instant('streets.nameFile.errorFile') + '_' + progetto.name, streetsError, [{notTraduction: 'error'}], ';')
                            }
                        }, error => {
                            if (arrayIsSet(streetsError)) {
                                this.fileService.dowloadJson(this.translateService.instant('streets.nameFile.errorFile') + '_' + progetto.name, streetsError, [{notTraduction: 'error'}], ';')
                            }
                        })
                    }
                }
            )
    }

    fotometrieInDataBase: FotometriaParse[]

    getFotometrie() {
        if (arrayIsSet(this.fotometrieInDataBase)) {
            return of(this.fotometrieInDataBase)
        } else {
            return this.fotometrieservice.getFotometrieByUser().pipe(
                map(f => {
                    this.fotometrieInDataBase = f;
                    return f
                })
            )
        }
    }


    getGruppiFotometrie() {
        if (arrayIsSet(this.gruppiFotometrieInDataBase)) {
            return of(this.gruppiFotometrieInDataBase)
        } else {
            return this.gruppoFotometrieService.getAllGruppiFotometrie().pipe(
                map(gruppiFotometrie => {
                    if (arrayIsSet(gruppiFotometrie)) {
                        this.gruppiFotometrieInDataBase = gruppiFotometrie;
                        return gruppiFotometrie;
                    } else {
                        this.gruppiFotometrieInDataBase = [];
                        return [];
                    }
                })
            )
        }
    }


    clickGroupPhotometry(event) {
        if (stringIsSet(event.objectId)) {
            const strada = new StradeParse()
            strada.objectId = event.objectId
            this.getGruppiFotometrie()
                .pipe(
                    switchMap((gruppiFotometrie) => {
                        const displayColumns = ['name', 'fotometrieCounter']
                        const trad = {}
                        displayColumns.forEach(key => {
                            trad[key] = this.translateService.instant('fotometrie.' + key)
                        })
                        const defvalues = {}
                        if (strada.gruppoFotometrie != null) {
                            defvalues[strada.gruppoFotometrie.id] = true;
                        }
                        return this.openDialogInfo.openSelectPhotometry(gruppiFotometrie, undefined, undefined, displayColumns, trad, defvalues)
                    }),
                    switchMap(((gruppoFotometrie) => {
                        if (gruppoFotometrie == null) {
                            return EMPTY;
                        } else if (arrayIsSet(gruppoFotometrie)) {
                            strada.gruppoFotometrie = gruppoFotometrie[0];
                        } else {
                            strada.unset('gruppoFotometrie');
                        }
                        return fromPromise(strada.save())
                    })),
                    switchMap(strada => fromPromise(this.initVariable()).pipe(map(() => strada)))
                )
                .subscribe(strada => {
                        this.forceUpdateList = !this.forceUpdateList
                        this.forceUpdateItemByList = !this.forceUpdateItemByList
                    },
                    error => {
                        this.forceUpdateList = !this.forceUpdateList
                        this.forceUpdateItemByList = !this.forceUpdateItemByList
                        this.alertService.error(error)
                    })
        }
    }

    clickPhotometry(event) {
        if (stringIsSet(event.objectId)) {
            const strada = new StradeParse()
            strada.objectId = event.objectId
            this.getFotometrie().pipe(
                switchMap((fotometrie) => {
                    const displayColumns = ['nomeFile', 'produttore', 'ottica', 'potenza', 'efficenza', 'temperaturaColore']
                    const trad = {}
                    displayColumns.forEach(key => {
                        trad[key] = this.translateService.instant('fotometrie.' + key)
                    })
                    const defvalues = {}
                    if (arrayIsSet(strada.fotometrie)) {
                        defvalues[strada.fotometrie[0].objectId] = true;
                    }
                    return this.openDialogInfo.openSelectPhotometry(fotometrie, undefined, undefined, displayColumns, trad, defvalues)
                }),
                switchMap(((fotometrieSelect: FotometriaParse[]) => {
                    if (fotometrieSelect == null) {
                        return EMPTY;
                    } else if (arrayIsSet(fotometrieSelect)) {
                        strada.fotometrie = fotometrieSelect;
                    } else {
                        strada.unSetFotometrie();
                    }
                    return fromPromise(strada.save())
                })),
                switchMap(strada => fromPromise(this.initVariable()).pipe(map(() => strada)))
            ).subscribe(fotometrie => {
                this.forceUpdateList = !this.forceUpdateList
                this.forceUpdateItemByList = !this.forceUpdateItemByList
            })
        }
    }

    updateStreet(event) {
        fromPromise(this.initVariable())
            .pipe(map(() => event))
            .subscribe(() => this.forceUpdateList = !this.forceUpdateList)
    }


    clickCheck(event) {
        if (arrayIsSet(event)) {
            this.elementChecked = event.map(street => street.objectId);
        } else {
            this.elementChecked = undefined;
        }
    }

    clickOtherProperty(event) {
        const strada = new StradeParse()
        strada.objectId = event.element.objectId;
        strada[event.column] = event.value;
        return fromPromise(strada.save())
            .pipe(switchMap(strada => fromPromise(this.initVariable()).pipe(map(() => strada))))
            .subscribe(strada => {
                this.forceUpdateList = !this.forceUpdateList
                this.forceUpdateItemByList = !this.forceUpdateItemByList
            }, error => {
                this.alertService.error(error);
                this.forceUpdateList = !this.forceUpdateList
                this.forceUpdateItemByList = !this.forceUpdateItemByList
            })
    }


    getSelectStreet$(): Observable<StradeParse[]> {
        let streets$: Observable<StradeParse[]>
        if (arrayIsSet(this.elementChecked)) {
            streets$ = of(this.elementChecked
                .map(
                    el => {
                        const strada = new StradeParse()
                        strada.objectId = el;
                        return strada;
                    }
                )
            )
        } else {
            streets$ = fromPromise(this.streetSummariesPromise).pipe(
                map(streets => {
                    return streets.map(
                        el => {
                            const strada = new StradeParse()
                            strada.objectId = el.objectId;
                            return strada;
                        }
                    )
                })
            )
        }
        return streets$.pipe(
            map((streets) => streets
                .sort((streetA, streetB) => {
                    const nameA = cleanToSpecialCharacterAndLowerCaseString(streetA.nome)
                    const nameB = cleanToSpecialCharacterAndLowerCaseString(streetB.nome)
                    return nameA.localeCompare(nameB);
                }))
        )
    }

    destroyAll() {
        let message;
        let streets$: Observable<StradeParse[]> = this.getSelectStreet$();
        if (arrayIsSet(this.elementChecked)) {
            message = 'dialog.elimina_elementi'
        } else {
            message = 'dialog.elimina_tutti_elementi'
        }
        const keyDestroy = 'destroyAll';
        this.setProgressPrint(keyDestroy, 0.1, true)
        this.myDialog.openDialogForDelete(this.translateService.instant(message), "center").pipe(
            switchMap((response) => {
                if (response) {
                    return streets$
                } else {
                    this.unSetProgressPrint(keyDestroy)
                    return EMPTY
                }
            }),
            switchMap(streets => {
                return this.streetsService.destroyAllStreet(streets)
            })
        )
            .subscribe(value => {
                if (value != null) {
                    this.setProgressPrint(keyDestroy, value.progress, !value.finished)
                    if (value.finished) {
                        this.unSetProgressPrint(keyDestroy)
                        this.elementChecked = undefined;
                        this.updateStreet('')
                    }
                    if (value.error) {
                        this.alertService.error(value.error)
                    }
                }
            }, error => {
                this.alertService.error(error)
            })
    }

    associaPhotometry() {
        const streets$ = this.getSelectStreet$().pipe(
            map(streets => streets.filter(street => street.gruppoFotometrie != null))
        );
        streets$.pipe(
            switchMap(streets => {
                if (streets == null || streets.length == 0) {
                    this.alertService.warning(this.translateService.instant('streets.notStreetToCalcGruopPhotmetry'))
                    return EMPTY
                }
                const message = this.translateService.instant('associatePhotometrySubstitutionMessage', {numberStreet: streets.length})
                return this.myDialog.openDialogPossibleActions(message, [
                    {
                        width: '50px',
                        icon: {
                            traduction: 'no',
                            color: 'accent'
                        },
                        forceSubstitution: false
                    }, {
                        width: '50px',
                        icon: {
                            traduction: 'yes',
                            color: 'primary'
                        },
                        forceSubstitution: true
                    },
                ], 'S').pipe(
                    switchMap((resp) => {
                        if (resp == null) {
                            return EMPTY;
                        } else if (resp.forceSubstitution) {
                            return of(streets)
                        } else {
                            return of(streets.filter(street => !arrayIsSet(street.fotometrie)))
                        }
                    })
                )
            }),
            switchMap(streets => {
                if (arrayIsSet(streets)) {
                    return this.streetsService.assignedPhotometryToStreetsByGroupPhotomeries(streets)
                } else {
                    this.alertService.warning(this.translateService.instant('streets.notStreetToCalc'))
                    return EMPTY;
                }
            }),
        ).subscribe(jobId => {
            this.streetsService.updateValueStreetsInQueue();
        }, error => {
            console.log(error)
        })
    }

    abortAssociaPhotometry() {
        this.loading.abortProcessCalc.active = true;
        const streets$ = this.getSelectStreet$()

        streets$.pipe(
            switchMap(streets => {
                const message = this.translateService.instant('abortAssociatePhotometrySubstitutionMessage', {numberStreet: streets.length});
                return this.myDialog.openDialogForDelete(message, 'center').pipe(
                    switchMap((resp) => {
                        if (resp == null || resp == false) {
                            return EMPTY;
                        } else {
                            return of(streets)
                        }
                    }),
                )
            }),
            switchMap(streets => {
                if (arrayIsSet(streets)) {
                    return this.streetsService.abortAssignedPhotometryToStreetsByGroupPhotomeries(streets)
                } else {
                    this.alertService.warning(this.translateService.instant('streets.notStreetToCalc'))
                    return EMPTY;
                }
            }),
        ).subscribe(queque => {
                this.streetsService.updateValueStreetsInQueue();
                this.loading.abortProcessCalc.active = false
            }, error => {
                console.log(error)
                this.loading.abortProcessCalc.active = false
            },
            () => this.loading.abortProcessCalc.active = false)
    }

    createNewStreet() {
        const newElement = {
            objectId: undefined,
            titolo: this.translateService.instant('newStreet'),
            priority: null
        };
        const element = this.addingTab.addingElement(this.openedStreetList, newElement);
        this.openedStreetList = element.openedList;
        this.indexTab = {value: (element.index >= 0) ? element.index + 1 : 1};
        this.forceUpdateTab = !this.forceUpdateTab;
    }
}

interface StreetSummary {
    objectId: string;
    nome: string;
    note: string;
    classificazione: string;
    larghezza: string;
    altezza: string;
    interdistanza: string;
    createdAt: Date;
    updatedAt: Date;
    fotometrie: FotometriaParse[];
    gruppoFotometrie: GruppoFotometrieParse;
    includiMarciapiedi: boolean;
    calcoloSemicilindrico: boolean;
    isCentroStorico: boolean;
    verificaIPEI_A: boolean;
    noteCalcolo: KeyStringValueAny,
    status?: 'isReady' | 'isWaiting' | 'isError'
}
