<div class="scale-container">
    <div>{{min}} {{unitOfMeasure}}</div>
    <ng-container *ngFor="let item of ElementidellaScala;index as i">
        <div *ngIf="i%numeroElementi==0 || i==ElementidellaScala.length"
             class="form"
             [ngStyle]="{'background-color':'#'+item.color}"
        ></div>
    </ng-container>
    <div>{{max}} {{unitOfMeasure}}</div>
    <div class="form"
         [ngStyle]="{'background-color':'#cccccc'}"
    ></div>
</div>

