import * as Parse from 'parse';
import {ElectricLinesMap} from "../providers/services/map.service";
import {arrayIsSet} from "./Models";

export class LocationElementsParse extends Parse.Object {

    public static CLASS_NAME = 'LocationElements';

    // tslint:disable-next-line:variable-name


    constructor() {
        super(LocationElementsParse.CLASS_NAME);
    }


    public set objectId(value) {
        this.id = value;
    }

    public get objectId(): string {
        return this.id;
    }

    public set ACL(value) {
        super.set('ACL', value);
    }

    public get ACL() {
        return super.get('ACL');
    }

    public set createdAt(value) {
        super.set('createdAt', value);
    }

    public get createdAt(): Date {
        return super.get('createdAt');
    }

    public set updatedAt(value) {
        super.set('updatedAt', value);
    }

    public get updatedAt(): Date {
        return super.get('updatedAt');
    }

    public set progetto(value) {
        super.set('progetto', value);
    }

    public get progetto() {
        return super.get('progetto');
    }


    public set createdBy(value) {
        super.set('createdBy', value);
    }

    public get createdBy() {
        return super.get('createdBy');
    }

    public set location(value) {
        super.set('location', value);
    }

    public get location(): Parse.GeoPoint {
        return super.get('location');
    }

    public addLineaElettrica(value: any) {
        super.add('lineeElettriche', value);
    }

    public removeLineaElettrica(value: any) {
        if (arrayIsSet(this.lineeElettriche)) {
            const removed = this.lineeElettriche.filter(linea => linea.objectId !== value.objectId);
            if (!arrayIsSet(removed)) {
                super.unset('lineeElettriche');
            } else {
                this.lineeEletriche = removed;
            }

        }
    }

    public set lineeEletriche(value) {
        super.set('lineeElettriche', value);
    }

    public get lineeElettriche(): any[] {
        return super.get('lineeElettriche');
    }


    public set sortingNumber(value) {
        super.set('sortingNumber', value);
    }

    public get sortingNumber(): any {
        return super.get('sortingNumber');
    }

    public addSortingNumber(value: any) {
        let obj;
        if (this.sortingNumber != null) {
            obj = {...this.sortingNumber, ...value};
        } else {
            obj = value;
        }
        this.sortingNumber = obj;
    }

    public removeSortingNumber(value: any) {
        const idLineaElettrica = value.objectId;
        if (this.sortingNumber != null && this.sortingNumber[idLineaElettrica] != null) {
            let obj;
            obj = {...this.sortingNumber};
            delete obj[idLineaElettrica];
            if (!arrayIsSet(Object.keys(obj))) {
                super.unset('sortingNumber');
            } else {
                this.sortingNumber = obj;
            }
        }
    }

    public removeSortingNumberLineaElettricaByObjectLineaElettrica(lineaElettrica: Parse.Object) {
        this.removeSortingNumber(lineaElettrica);
        this.removeLineaElettrica(lineaElettrica);
    }

    public get query(): Parse.Query<LocationElementsParse> {
        const query = new Parse.Query(LocationElementsParse);
        return query;
    }
}

Parse.Object.registerSubclass(LocationElementsParse.CLASS_NAME, LocationElementsParse);
