<mat-card style="width: 350px;max-width: 100%">
    <mat-card-title>
        {{title | translate}}!
    </mat-card-title>
    <mat-card-subtitle *ngIf="subTitle">
        {{subTitle | translate}}
    </mat-card-subtitle>
    <ng-container *ngIf="errors">
        <div class="flex-around-center" *ngFor="let error of errors">
            <div>
                <h4>{{error.title|translate}}</h4>
            </div>
            <div>
                {{error.value|translate}}
            </div>
        </div>
    </ng-container>
    <div class="spacer">
        {{'errors.contact_info'| translate}}
        <h5>info@huna.io
            <button mat-icon-button color="accent"
                    cdkCopyToClipboard="info@huna.io" (cdkCopyToClipboardCopied)="copied()">
                <mat-icon>content_copy</mat-icon>
            </button>
        </h5>
    </div>
</mat-card>