<mat-dialog-content style="max-height: 75vh;">
    <app-split-screen [breakPoint]="breakPointFilterTable">
        <div sx class="half-screen-wrapper" [formGroup]="form">
            <app-field-form-foto-tipologia [fotoTipologie]="data.fotoTipologia"
                                           [selectable]="true"
                                           [addNew]="false"
                                           classSizeBox="size-box-foto-tipologia-static"
                                           formControlName="selected"
            >

            </app-field-form-foto-tipologia>
        </div>
        <div dx class="half-screen-wrapper">
            <div class="scrol" *ngIf="dataSource">
                <table mat-table [dataSource]="dataSource" class="table">
                    <!-- Implicit Column -->
                    <ng-container [matColumnDef]="col" *ngFor="let col of displayedColumns">
                        <th mat-header-cell *matHeaderCellDef> {{getPathTraduction + col + '.title'|translate}} </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container [ngSwitch]="col">
                                <ng-container *ngSwitchCase="'circuito'">
                                    {{getValueCircuito(data[col])}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'fotoTipologia'">
                                    {{getValueFototipologia(data[col])}}
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    {{getValue(data, col)}}
                                </ng-container>
                            </ng-container>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </div>
    </app-split-screen>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <ng-container
            *ngIf="numberClickRemoveFotoTipologia$|async |convertValueByPredicateFunction:getTraductionRemoveButtonPredicate:limitClickRemove as traduction">
        <button mat-raised-button color="warn" (click)="clickRemoeFotoTipologia()"
                [matBadge]="numberClickRemoveFotoTipologia$|async"
                matBadgeColor="warn"
                class="margin-button"
        >
            {{traduction|translate}}
        </button>
    </ng-container>
    <button mat-raised-button [mat-dialog-close]="undefined"
            class="margin-button">
        <mat-icon>clear</mat-icon>
    </button>
    <button mat-raised-button color="primary"
            class="margin-button" (click)="saveValue()" [disabled]="disableSaveButton$|async" >
        <mat-icon>save</mat-icon>
    </button>
</mat-dialog-actions>