import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'g[sidewalk]',
  templateUrl: './sidewalk.component.html',
  styleUrls: ['./sidewalk.component.scss']
})
export class SidewalkComponent implements OnInit {
  @Input()
  height: number = 100;
  @Input()
  width: number = 100;
  @Input()
  x: number = 0;
  @Input()
  y: number = 0;

  viewBoxHeight = 100;
  viewBoxWidth = 100;
  get viewBox() {
    return `0 0 ${this.viewBoxWidth} ${this.viewBoxHeight}`;
  }
  
  constructor() { }

  ngOnInit() {
  }

}
