import * as Parse from 'parse';

export class SegnaliTlcNodoChangesHistoryParse extends Parse.Object {
    public static CLASS_NAME = 'SegnaliTlcNodoChangesHistory';

    constructor() {
        super(SegnaliTlcNodoChangesHistoryParse.CLASS_NAME);
    }

    public set objectId(value) {
        super.set('objectId', value);
    }

    public get objectId() {
        return super.get('objectId');
    }

    public set updatedAt(value) {
        super.set('updatedAt', value);
    }

    public get updatedAt() {
        return super.get('updatedAt');
    }

    public set createdAt(value) {
        super.set('createdAt', value);
    }

    public get createdAt() {
        return super.get('createdAt');
    }

    public set ACL(value) {
        super.set('ACL', value);
    }

    public get ACL() {
        return super.get('ACL');
    }

    public set fase(value) {
        super.set('fase', value);
    }

    public get fase() {
        return super.get('fase');
    }

    public set lampadaAccesa(value) {
        super.set('lampadaAccesa', value);
    }

    public get lampadaAccesa() {
        return super.get('lampadaAccesa');
    }

    public set lampadaFunzionante(value) {
        super.set('lampadaFunzionante', value);
    }

    public get lampadaFunzionante() {
        return super.get('lampadaFunzionante');
    }
    public set problemaDispositivoTelecontrollo(value) {
        super.set('problemaDispositivoTelecontrollo', value);
    }

    public get problemaDispositivoTelecontrollo() {
        return super.get('problemaDispositivoTelecontrollo');
    }

    public set progetto(value) {
        super.set('progetto', value);
    }

    public get progetto() {
        return super.get('progetto');
    }

    public set puntoLuce(value) {
        super.set('puntoLuce', value);
    }

    public get puntoLuce() {
        return super.get('puntoLuce');
    }

    public set temperatura(value) {
        super.set('temperatura', value);
    }

    public get temperatura() {
        return super.get('temperatura');
    }

    public set temperaturaCPU(value) {
        super.set('temperaturaCPU', value);
    }

    public get temperaturaCPU() {
        return super.get('temperaturaCPU');
    }

}

Parse.Object.registerSubclass(SegnaliTlcNodoChangesHistoryParse.CLASS_NAME, SegnaliTlcNodoChangesHistoryParse);
