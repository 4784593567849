import {Injectable} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ReportStates} from "../../../config/static-data";

@Injectable({
    providedIn: 'root'
})
export class ActivityFormService {

    constructor(
        private fb: UntypedFormBuilder
    ) {
    }

    public getCreateActivityForm(): UntypedFormGroup {
        return this.fb.group({
                comment: [''],
                foto: [''],
                allegato: ['']
            }
        );
    }

    public getCreateNewReportCommentForm(obj: { [k: string]: any } | undefined): UntypedFormGroup {
        const objForm = {
            comment: [undefined],
            nuovoStato: [ReportStates.COMPLETATO, Validators.required],
            foto: [undefined],
            allegato: [undefined],
            typeReport: ['comment'],
            compiledScheduleMaintenance: null
        }
        if (obj != null) {
            Object.keys(obj).forEach(key => {
                objForm[key] = obj[key];
            })
        }
        return this.fb.group(objForm);
    }

}
