import {Injectable} from '@angular/core';
import {LocalSotrageService} from "./local-sotrage.service";
import {modalityViewMap} from "../../models/Models";

@Injectable({
    providedIn: 'root'
})
export class CurrentViewService {
    private _currentView = modalityViewMap.default;
    get currentView(): string {
        return this._currentView
    }

    set currentView(value) {
        this._currentView = value;
        this.localSotrageService.modalityViewMap = value;
    }

    constructor(
        private localSotrageService: LocalSotrageService
    ) {
        const typeView = this.localSotrageService.modalityViewMap;
        if (typeView == modalityViewMap.scheduleMaintenance) {
            this.activeModalitaManutenzione()
        } else {
            this.disActiveModalitaManutenzione()
        }

    }

    get isActiveModalitaManutenzione() {
        return this.currentView == modalityViewMap.scheduleMaintenance
    }

    activeModalitaManutenzione() {
        this.currentView = modalityViewMap.scheduleMaintenance;
        this.localSotrageService.modalityViewMap = modalityViewMap.scheduleMaintenance;
    }

    disActiveModalitaManutenzione() {
        this.currentView = modalityViewMap.default;
        this.localSotrageService.modalityViewMap = modalityViewMap.default;
    }

}
