import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {formatDate} from "@angular/common";
import {TranslateService} from "@ngx-translate/core";
import {Subscription} from "rxjs";
import {isNotNullOrUndefined} from "../../models/Models";

@Component({
    selector: 'app-move-mount-year-widgets',
    templateUrl: './move-mount-year-widgets.component.html',
    styleUrls: ['./move-mount-year-widgets.component.scss']
})
export class MoveMountYearWidgetsComponent implements OnInit {
    @Output() changeDate = new EventEmitter();
    @Input() currentMonthYear;
    public dateForm: UntypedFormGroup;
    subscriptions: Subscription[] = [];


    constructor(private translate: TranslateService,) {
        this.dateForm = new UntypedFormGroup({
            month: new UntypedFormControl({
                value: null,
                disabled: false
            }),
            year: new UntypedFormControl({value: null, disabled: false}),
        });
    }

    ngOnInit(): void {
        const subscription = this.dateForm.valueChanges
            .subscribe((value) => {
                this.changeDate.emit(value);
            });
        if (this.currentMonthYear == null) {
            this.currentMonthYear = {month: new Date().getMonth() + 1, year: new Date().getFullYear()}
        }
        setTimeout(() => {
            this.dateForm.get('year').setValue(this.currentMonthYear.year, {emitEvent: false})
            this.dateForm.get('month').setValue(this.currentMonthYear.month)
        })
        this.subscriptions.push(subscription)
    }

    changeMonthNext() {
        const month = this.dateForm.get('month').value - 1// è traslato di un piu uno il backend
        const year = this.dateForm.get('year').value
        const date = new Date(year, month + 1, 1, 0, 0, 0, 0);
        if (date.getFullYear() == year) {
            this.dateForm.get('month').setValue(date.getMonth() + 1);
        } else {
            this.dateForm.get('month').setValue(date.getMonth() + 1, {emitEvent: false});
            this.dateForm.get('year').setValue(date.getFullYear());
        }
    }

    changeMonthPrevius() {
        const month = this.dateForm.get('month').value - 1// è traslato di un piu uno il backend;
        const year = this.dateForm.get('year').value;
        const date = new Date(year, month - 1, 1, 0, 0, 0, 0);
        if (date.getFullYear() == year) {
            this.dateForm.get('month').setValue(date.getMonth() + 1);
        } else {
            this.dateForm.get('month').setValue(date.getMonth() + 1, {emitEvent: false});
            this.dateForm.get('year').setValue(date.getFullYear());
        }
    }

    changeYearNext() {
        const year = this.dateForm.get('year').value;
        this.dateForm.get('year').setValue(year + 1);
    }

    changeYearPrevius() {
        const year = this.dateForm.get('year').value;
        this.dateForm.get('year').setValue(year - 1);
    }


    get dateForHtml() {
        const month = this.dateForm.get('month').value
        const year = this.dateForm.get('year').value
        return this.getMonthYear(month - 1, year);
    }

    getMonthYear(month, year) {
        const locale = this.translate.currentLang;
        if (!isNotNullOrUndefined(month) || !isNotNullOrUndefined(year)) {
            return month + ' ' + year;
        } else {
            const monthNumber = (typeof month == "number") ? month : parseInt(month);
            const yearNumber = (typeof year == "number") ? year : parseInt(year);
            if (!isNaN(monthNumber) && !isNaN(yearNumber)) {
                const date = new Date(yearNumber, monthNumber, 1, 0, 0, 0, 0);
                const separator = (locale == 'en') ? ', ' : ' ';
                const stringDate = formatDate(date, 'MMMM' + separator + 'y', locale);
                return stringDate;
            } else {
                return month + ' ' + year;
            }
        }
    }

    get monthIsAfterToday() {
        const year = this.dateForm.get('year').value;
        const month = this.dateForm.get('month').value;
        const today = new Date();
        return month > today.getMonth() && year + 1 > today.getFullYear();
    }

    get yearIsAfterToday() {
        const year = this.dateForm.get('year').value;
        const today = new Date();
        return year + 1 > today.getFullYear();
    }


}
