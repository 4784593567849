import * as Parse from 'parse';


export class SettaggiTlcCircuitiParse extends Parse.Object {
    public static CLASS_NAME = 'SettaggiTlcCircuiti';

    constructor() {
        super(SettaggiTlcCircuitiParse.CLASS_NAME);
    }

    public set objectId(value) {
        this.id = value;
    }

    public get objectId() {
        return this.id;
    }


    public set allarmeCrepuscolare(value) {
        super.set('allarmeCrepuscolare', value);
    }

    public get allarmeCrepuscolare(): boolean {
        return super.get('allarmeCrepuscolare');
    }

    public set createdAt(value) {
        super.set('createdAt', value);
    }

    public get createdAt(): Date {
        return super.get('createdAt');
    }

    public set updatedAt(value) {
        super.set('updatedAt', value);
    }

    public get updatedAt(): Date {
        return super.get('updatedAt');
    }

    public set allarmeConsumiAnomali(value) {
        super.set('allarmeConsumiAnomali', value);
    }

    public get allarmeConsumiAnomali(): boolean {
        return super.get('allarmeConsumiAnomali');
    }

    public set allarmeDifferenziale(value) {
        super.set('allarmeDifferenziale', value);
    }

    public get allarmeDifferenziale(): boolean {
        return super.get('allarmeDifferenziale');
    }

    public set allarmeMagnetoTermico1(value) {
        super.set('allarmeMagnetoTermico1', value);
    }

    public get allarmeMagnetoTermico1(): boolean {
        return super.get('allarmeMagnetoTermico1');
    }

    public set allarmeMagnetoTermico2(value) {
        super.set('allarmeMagnetoTermico2', value);
    }

    public get allarmeMagnetoTermico2(): boolean {
        return super.get('allarmeMagnetoTermico2');
    }

    public set allarmeMagnetoTermico3(value) {
        super.set('allarmeMagnetoTermico3', value);
    }

    public get allarmeMagnetoTermico3(): boolean {
        return super.get('allarmeMagnetoTermico3');
    }

    public set allarmeMagnetoTermico4(value) {
        super.set('allarmeMagnetoTermico4', value);
    }

    public get allarmeMagnetoTermico4(): boolean {
        return super.get('allarmeMagnetoTermico4');
    }

    public set allarmePresenzaRete(value) {
        super.set('allarmePresenzaRete', value);
    }

    public get allarmePresenzaRete(): boolean {
        return super.get('allarmePresenzaRete');
    }

    public set allarmeSportelloAperto(value) {
        super.set('allarmeSportelloAperto', value);
    }

    public get allarmeSportelloAperto(): boolean {
        return super.get('allarmeSportelloAperto');
    }

    public set allarmeTeleruttore1(value) {
        super.set('allarmeTeleruttore1', value);
    }

    public get allarmeTeleruttore1(): boolean {
        return super.get('allarmeTeleruttore1');
    }

    public set allarmeTeleruttore2(value) {
        super.set('allarmeTeleruttore2', value);
    }

    public get allarmeTeleruttore2(): boolean {
        return super.get('allarmeTeleruttore2');
    }

    public set allarmeTeleruttore3(value) {
        super.set('allarmeTeleruttore3', value);
    }

    public get allarmeTeleruttore3(): boolean {
        return super.get('allarmeTeleruttore3');
    }

    public set allarmeTeleruttore4(value) {
        super.set('allarmeTeleruttore4', value);
    }

    public get allarmeTeleruttore4(): boolean {
        return super.get('allarmeTeleruttore4');
    }

    public set acceso1(value) {
        super.set('acceso1', value);
    }

    public get acceso1(): boolean {
        return super.get('acceso1');
    }

    public set acceso2(value) {
        super.set('acceso2', value);
    }

    public get acceso2(): boolean {
        return super.get('acceso2');
    }

    public set acceso3(value) {
        super.set('acceso3', value);
    }

    public get acceso3(): boolean {
        return super.get('acceso3');
    }

    public set acceso4(value) {
        super.set('acceso4', value);
    }

    public get acceso4(): boolean {
        return super.get('acceso4');
    }

    public set circuito(value) {
        super.set('circuito', value);
    }

    public get circuito(): any {
        return super.get('circuito');
    }

    public set deltaMinutiAccensione(value) {
        super.set('deltaMinutiAccensione', value);
    }

    public get deltaMinutiAccensione(): number {
        return super.get('deltaMinutiAccensione');
    }

    public set deltaMinutiSpegnimento(value) {
        super.set('deltaMinutiSpegnimento', value);
    }

    public get deltaMinutiSpegnimento(): number {
        return super.get('deltaMinutiSpegnimento');
    }

    public set modalita(value) {
        super.set('modalita', value);
    }

    public get modalita(): string {
        return super.get('modalita');
    }

    public set numeroDiLinee(value) {
        super.set('numeroDiLinee', value);
    }

    public get numeroDiLinee(): number {
        return super.get('numeroDiLinee');
    }

    public set progetto(value) {
        super.set('progetto', value);
    }

    public get progetto(): any {
        return super.get('progetto');
    }

    public set ricevuto(value) {
        super.set('ricevuto', value);
    }

    public get ricevuto(): boolean {
        return super.get('ricevuto');
    }

    public set tolleranzaAllarmiConsumi(value) {
        super.set('tolleranzaAllarmiConsumi', value);
    }

    public get tolleranzaAllarmiConsumi(): number {
        return super.get('tolleranzaAllarmiConsumi');
    }

    public get query(): Parse.Query<SettaggiTlcCircuitiParse> {
        return new Parse.Query(SettaggiTlcCircuitiParse)
    }
}

Parse.Object.registerSubclass(SettaggiTlcCircuitiParse.CLASS_NAME, SettaggiTlcCircuitiParse);
