import {Component, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {MatStepper} from "@angular/material/stepper";
import {ProgrammiMVParse} from "../../../models/ProgrammiMV.Parse";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {arrayIsSet, customThemeNgxDataPicker, isNotNullOrUndefined, stringIsSet} from "../../../models/Models";
import {combineLatest, Observable, Subject} from "rxjs";
import {filter, map, startWith} from "rxjs/operators";
import {MatAccordion, MatExpansionPanel} from "@angular/material/expansion";
import {TLC_ProfiloFunzionamentoParse} from "../../../models/TLC_ProfiloFunzionamento.Parse";
import {TypeVersioneProtocolloLM} from "../../../providers/services/remote-control.service";
import {HunaValidators} from "../../../providers/validators/HunaValidators";
import {typeFormValue} from "../../../models/configurationProperty/configurationPropertyUtils";

export const valueActionClickButton = {
    searchBrokenLamp: "SEARCH_BROKEN_LAMP",
    resetFactoryDevice: "RESET_FACTORY_DEVICE",
}
export type ValueActionClickButtonType = typeof valueActionClickButton [keyof typeof valueActionClickButton];

@Component({
    selector: 'app-form-light-mate',
    templateUrl: './form-light-mate.component.html',
    styleUrls: ['./form-light-mate.component.scss']
})
export class FormLightMateComponent implements OnInit, OnChanges {
    @ViewChild('stepper') stepper: MatStepper;
    @ViewChild(MatExpansionPanel) matExpansionPanel: MatExpansionPanel;
    @Input() public circuitoId;
    @Input() public deviceId;
    @Input() public device;
    @Input() public yesGroupId;
    @Input() public lastProgrammiMVParse: ProgrammiMVParse[] = [];
    @Input() public loadingAll = false;
    @Input() public loadingSearchWrong = false;
    @Input() public loadingResetFactory = false;
    @Input() public showDetailLastComand = true;
    @Input() public showIdGruppo = true;
    @Input() public showLine = true;
    @Input() public showSearchWrong = true;
    @Input() public profilesTlc: TLC_ProfiloFunzionamentoParse[];
    @Input() public typeForm: undefined = undefined;
    @Output() private updateProgram = new Subject();
    @Output() private clickAction = new Subject<{ key: ValueActionClickButtonType, lineAndGroupId }>();
    @Output() private saveValues = new Subject<any>();
    form: UntypedFormGroup;
    formsStepper: UntypedFormGroup[] = [];
    typesPeriod = {scheduleWeek: 'scheduleWeek', schedulePeriod: 'schedulePeriod'};
    typePeriod$: Observable<string>;
    public typeVersioneProtocolloLM = TypeVersioneProtocolloLM

    private objectStepperForm = {
        potenza: [undefined, Validators.required],
        ora: [undefined, Validators.required],
    };

    public selectedProgrammaMV$: Observable<ProgrammiMVParse>;
    public selectedProgrammaMVNotIsLast$: Observable<boolean>;
    public atLeastOneLineIsSet$: Observable<boolean>;
    public colorLamp$: Observable<string>;
    today = new Date()
    tiles: { text, cols, rows, textAlign }[] = [
        {text: 'Two', cols: 3, rows: 1, textAlign: 'center'},
    ];
    public backgroundColorText: { color: string, title: string } = {
        color: '', title: ''
    };
    valueActionClickButton = valueActionClickButton;
    groupIdPossibleValues: number[] | undefined;
    public customThemNgxDataPicker = customThemeNgxDataPicker;
    private baseColor = {
        max: {
            r: 250,
            g: 250,
            b: 50
        },
        min: {
            r: 50,
            g: 50,
            b: 50
        }
    };
    public errorProfiles$: Observable<boolean>
    public errorWeeks$: Observable<boolean>

    constructor(private fb: UntypedFormBuilder,) {
        this.form = fb.group({
            protocolloPRT: fb.group({
                fromDate: new Date(),
                toDate: undefined,
                repeatYear: undefined,
                days: undefined,
                profileTlc: undefined,
                typePeriod: this.typesPeriod.scheduleWeek
            }),
            selectedProgrammaMV: undefined,
            powerLiveLamp: '50',
            groupId: ['', Validators.required],
            linea1: [true,],
            linea2: [true,],
            linea3: [true,],
            linea4: [true,],
        })

        this.errorProfiles$ = this.form.get('protocolloPRT').get('profileTlc').valueChanges.pipe(
            startWith(this.form.get('protocolloPRT').get('profileTlc').value),
            map(v => v == null)
        )
        const days$ = this.form.get('protocolloPRT').get('days').valueChanges.pipe(
            startWith(undefined),
            map(() => this.form.get('protocolloPRT').get('days').value)
        )
        const fromDate$ = this.form.get('protocolloPRT').get('fromDate').valueChanges.pipe(
            startWith(undefined),
            map(() => this.form.get('protocolloPRT').get('fromDate').value)
        )
        const toDate$ = this.form.get('protocolloPRT').get('toDate').valueChanges.pipe(
            startWith(undefined),
            map(() => this.form.get('protocolloPRT').get('toDate').value)
        )

        this.typePeriod$ = this.form.get('protocolloPRT').get('typePeriod').valueChanges.pipe(
            startWith(undefined),
            map(() => this.form.get('protocolloPRT').get('typePeriod').value)
        )
        this.errorWeeks$ = combineLatest([this.typePeriod$, days$, fromDate$, toDate$]).pipe(
            map(([typePeriod, days, fromDate, toDate]) => {
                if (typePeriod == this.typesPeriod.scheduleWeek) {
                    return !arrayIsSet(days)
                } else {
                    return fromDate == null || toDate == null
                }
            })
        )
        this.formsStepper.push(
            fb.group(this.objectStepperForm),
            fb.group(this.objectStepperForm),
            fb.group(this.objectStepperForm),
            fb.group(this.objectStepperForm),
            fb.group(this.objectStepperForm),
        )
        this.selectedProgrammaMV$ = this.form.get('selectedProgrammaMV').valueChanges.pipe(
            startWith(this.form.get('selectedProgrammaMV').value),
            map((program: ProgrammiMVParse) => {
                if (program != null) {
                    const parseProgram = program.allProperty().reduce((prev, currentKey) => {
                        if (program[currentKey] != null) {
                            if (currentKey == 'durataComando') {
                                prev[currentKey] = program[currentKey] * 60;
                            } else {
                                prev[currentKey] = program[currentKey];
                            }
                        } else {
                            prev[currentKey] = undefined;
                        }
                        return prev;
                    }, {})
                    return parseProgram as ProgrammiMVParse;
                } else {
                    return program
                }
            })
        )
        this.selectedProgrammaMVNotIsLast$ = this.selectedProgrammaMV$.pipe(
            filter(program => program != null),
            map(program => {
                const index = this.lastProgrammiMVParse.findIndex(p => p.createdAt.getTime() > program.createdAt.getTime())
                return index >= 0
            })
        )


        this.atLeastOneLineIsSet$ = combineLatest([
            this.form.get('linea1').valueChanges.pipe(
                startWith(this.form.get('linea1').value)
            ), this.form.get('linea2').valueChanges.pipe(
                startWith(this.form.get('linea2').value)
            ), this.form.get('linea3').valueChanges.pipe(
                startWith(this.form.get('linea3').value)
            ), this.form.get('linea4').valueChanges.pipe(
                startWith(this.form.get('linea4').value)
            ),
        ]).pipe(
            map(values => {
                const index = values.findIndex(v => v == true)
                return index >= 0;
            })
        )

        this.colorLamp$ = this.form.get('powerLiveLamp').valueChanges.pipe(
            startWith(this.form.get('powerLiveLamp').value),
            map(power => {
                const incrementoR = (this.baseColor.max.r - this.baseColor.min.r) / 100;
                const incrementoG = (this.baseColor.max.g - this.baseColor.min.g) / 100;
                const incrementoB = (this.baseColor.max.b - this.baseColor.min.b) / 100;
                const r = Math.min(255, this.baseColor.min.r + incrementoR * power);
                const g = Math.min(255, this.baseColor.min.g + incrementoG * power);
                const b = Math.min(255, this.baseColor.min.b + incrementoB * power);
                return 'rgb(' + r + ',' + g + ',' + b + ')';
            })
        )
    }

    ngOnInit(): void {

    }

    ngOnChanges(changes: SimpleChanges) {
        this.today = new Date();
        if (changes.lastProgrammiMVParse && arrayIsSet(this.lastProgrammiMVParse)) {
            this.form.get('selectedProgrammaMV').setValue(this.lastProgrammiMVParse[0])
            setTimeout(() => {
                this.form.get('selectedProgrammaMV').updateValueAndValidity()
            }, 50);
        }
        if (changes.yesGroupId) {
            if (this.yesGroupId) {
                this.groupIdPossibleValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
                this.form.get('groupId').setValue(0);
            } else {
                this.groupIdPossibleValues = [];
                let i = 1;
                while (i < 241) {
                    this.groupIdPossibleValues.push(i);
                    i++;
                }
                const idDevice = (this.deviceId) ? this.deviceId : null;
                this.form.get('groupId').setValue(idDevice);
                this.form.get('groupId').disable();
            }
        }
        if (changes.profilesTlc && arrayIsSet(this.profilesTlc)) {
            const profileId = this.form.get('protocolloPRT').get('profileTlc').value
            setTimeout(() => {
                if (profileId == null) {
                    this.form.get('protocolloPRT').get('profileTlc').setValue(this.profilesTlc[0].objectId)
                } else {
                    if (!this.profilesTlc.some(p => p.objectId == profileId)) {
                        this.form.get('protocolloPRT').get('profileTlc').setValue(this.profilesTlc[0].objectId)
                    }
                }
            }, 200)

        }
    }


    moveUltimoComandoDown() {
        const selectedLastProgramm = this.form.get('selectedProgrammaMV').value
        let index = this.lastProgrammiMVParse.findIndex(ultimoComando =>
            ultimoComando.objectId === selectedLastProgramm.objectId
        );
        if (index >= 0) {
            if (index - 1 < 0) {
                index = this.lastProgrammiMVParse.length - 1;
            } else {
                index = (index - 1) % this.lastProgrammiMVParse.length;
            }
            this.form.get('selectedProgrammaMV').setValue(this.lastProgrammiMVParse[index])
        }
    }

    moveUltimoComandoUp() {
        const selectedLastProgramm = this.form.get('selectedProgrammaMV').value
        let index = this.lastProgrammiMVParse.findIndex(ultimoComando =>
            ultimoComando.objectId === selectedLastProgramm.objectId
        );
        if (index >= 0) {
            index = (index + 1) % this.lastProgrammiMVParse.length;
            this.form.get('selectedProgrammaMV').setValue(this.lastProgrammiMVParse[index])
        }
    }


    clickUpdateProgram() {
        this.updateProgram.next();
    }

    public get protocolloLMisRM_V1(): boolean {
        if (isNotNullOrUndefined(this.device)) {
            return this.device.versioneProtocolloLM === 'RT_V1';
        } else {
            return false;
        }
    }

    private createTilesComandPredicateFunction(ultimoProgramma: ProgrammiMVParse, protocolloLMisRM_V1: boolean, anticonvertHourTODecimalHour) {
        const groupid = (isNotNullOrUndefined(ultimoProgramma.groupId)) ? ultimoProgramma.groupId.toString() : '-';
        const groupIdText = 'lightMate.groupId';
        const deviceid = (isNotNullOrUndefined(ultimoProgramma.deviceId)) ? ultimoProgramma.deviceId.toString() : '-';
        const deviceIdText = 'lightMate.deviceId';

        const lines = (ultimoProgramma.linee && !protocolloLMisRM_V1) ? ultimoProgramma.linee.toString() : '-';
        const firstNumberColumn = 7;
        const secondNumberColumn = 5;
        let tiles;
        if (ultimoProgramma.resetToDefault) {
            tiles = [
                {text: deviceIdText, cols: firstNumberColumn, rows: 1, textAlign: 'start'},
                {text: deviceid, cols: secondNumberColumn, rows: 1, textAlign: 'center'},
                {text: groupIdText, cols: firstNumberColumn, rows: 1, textAlign: 'start'},
                {text: groupid, cols: secondNumberColumn, rows: 1, textAlign: 'center'},
                {text: 'lightMate.lines', cols: firstNumberColumn, rows: 1, textAlign: 'start'},
                {text: lines, cols: secondNumberColumn, rows: 1, textAlign: 'center'},
                {text: 'commandSent', cols: firstNumberColumn, rows: 1, textAlign: 'start'},
                {text: 'lightMate.resetToDefault', cols: secondNumberColumn, rows: 1, textAlign: 'center'}
            ];
        } else if (ultimoProgramma.modalitaManutenzione) {
            tiles = [
                {text: deviceIdText, cols: firstNumberColumn, rows: 1, textAlign: 'start'},
                {text: deviceid, cols: secondNumberColumn, rows: 1, textAlign: 'center'},
                {text: groupIdText, cols: firstNumberColumn, rows: 1, textAlign: 'start'},
                {text: groupid, cols: secondNumberColumn, rows: 1, textAlign: 'center'},
                {text: 'lightMate.lines', cols: firstNumberColumn, rows: 1, textAlign: 'start'},
                {text: lines, cols: secondNumberColumn, rows: 1, textAlign: 'center'},
                {text: 'commandSent', cols: firstNumberColumn, rows: 1, textAlign: 'start'},
                {text: 'lightMate.searchLuminariesFails', cols: secondNumberColumn, rows: 1, textAlign: 'center'}
            ];
        } else if (ultimoProgramma.modalitaRicerca) {
            tiles = [
                {text: deviceIdText, cols: firstNumberColumn, rows: 1, textAlign: 'start'},
                {text: deviceid, cols: secondNumberColumn, rows: 1, textAlign: 'center'},
                {text: groupIdText, cols: firstNumberColumn, rows: 1, textAlign: 'start'},
                {text: groupid, cols: secondNumberColumn, rows: 1, textAlign: 'center'},
                {text: 'lightMate.lines', cols: firstNumberColumn, rows: 1, textAlign: 'start'},
                {text: lines, cols: secondNumberColumn, rows: 1, textAlign: 'center'},
                {text: 'commandSent', cols: firstNumberColumn, rows: 1, textAlign: 'start'},
                {text: 'lightMate.searchIdLightMate', cols: secondNumberColumn, rows: 1, textAlign: 'center'}
            ];
        } else {
            const p0 = (isNotNullOrUndefined(ultimoProgramma.p0)) ? (ultimoProgramma.p0.toString()) : '-';
            if (!protocolloLMisRM_V1) {
                const p1 = (isNotNullOrUndefined(ultimoProgramma.p1)) ? (ultimoProgramma.p1.toString()) : '-';
                const p2 = (isNotNullOrUndefined(ultimoProgramma.p2)) ? (ultimoProgramma.p2.toString()) : '-';
                const p3 = (isNotNullOrUndefined(ultimoProgramma.p3)) ? (ultimoProgramma.p3.toString()) : '-';
                const p4 = (isNotNullOrUndefined(ultimoProgramma.p4)) ? (ultimoProgramma.p4.toString()) : '-';
                const t0 = (isNotNullOrUndefined(ultimoProgramma.t0)) ? anticonvertHourTODecimalHour(ultimoProgramma.t0) : '-';
                const t1 = (isNotNullOrUndefined(ultimoProgramma.t1)) ? anticonvertHourTODecimalHour(ultimoProgramma.t1) : '-';
                const t2 = (isNotNullOrUndefined(ultimoProgramma.t3)) ? anticonvertHourTODecimalHour(ultimoProgramma.t2) : '-';
                const t3 = (isNotNullOrUndefined(ultimoProgramma.t3)) ? anticonvertHourTODecimalHour(ultimoProgramma.t3) : '-';
                const t4 = (isNotNullOrUndefined(ultimoProgramma.t4)) ? anticonvertHourTODecimalHour(ultimoProgramma.t4) : '-';
                tiles = [
                    {text: deviceIdText, cols: firstNumberColumn, rows: 1, textAlign: 'start'},
                    {text: deviceid, cols: secondNumberColumn, rows: 1, textAlign: 'center'},
                    {text: groupIdText, cols: firstNumberColumn, rows: 1, textAlign: 'start'},
                    {text: groupid, cols: secondNumberColumn, rows: 1, textAlign: 'center'},
                    {text: 'lightMate.lines', cols: firstNumberColumn, rows: 1, textAlign: 'start'},
                    {text: lines, cols: secondNumberColumn, rows: 1, textAlign: 'center'},
                    {text: 'lightMate.functionProfile', cols: 8, rows: 1, textAlign: 'center'},
                    {text: '', cols: 4, rows: 1, textAlign: 'center', isButtonCopy: true},
                    {text: 'lightMate.period', cols: 6, rows: 1, textAlign: 'start'}, {
                        text: 'lightMate.power',
                        cols: 3,
                        rows: 1,
                        textAlign: 'center',
                    }, {text: 'lightMate.hours', cols: 3, rows: 1, textAlign: 'center'},
                    {text: 'lightMate.powerOn', cols: 6, rows: 1, textAlign: 'start'}, {
                        text: p0,
                        cols: 3,
                        rows: 1,
                        textAlign: 'center',
                    }, {text: t0, cols: 3, rows: 1, textAlign: 'center'},
                    {text: 'lightMate.level1', cols: 6, rows: 1, textAlign: 'start'}, {
                        text: p1,
                        cols: 3,
                        rows: 1,
                        textAlign: 'center',
                    }, {text: t1, cols: 3, rows: 1, textAlign: 'center'},
                    {text: 'lightMate.level2', cols: 6, rows: 1, textAlign: 'start'}, {
                        text: p2,
                        cols: 3,
                        rows: 1,
                        textAlign: 'center',
                    }, {text: t2, cols: 3, rows: 1, textAlign: 'center'},
                    {text: 'lightMate.level3', cols: 6, rows: 1, textAlign: 'start'}, {
                        text: p3,
                        cols: 3,
                        rows: 1,
                        textAlign: 'center',
                    }, {text: t3, cols: 3, rows: 1, textAlign: 'center'},
                    {text: 'lightMate.level4', cols: 6, rows: 1, textAlign: 'start'}, {
                        text: p4,
                        cols: 3,
                        rows: 1,
                        textAlign: 'center',
                    }, {text: t4, cols: 3, rows: 1, textAlign: 'center'},
                ];
            } else {
                tiles = [
                    {text: 'lightMate.power', cols: 6, rows: 1, textAlign: 'start'}, {
                        text: p0,
                        cols: 3,
                        rows: 1,
                        textAlign: 'center',
                    }]
            }
        }
        return tiles;
    }

    private anticonvertHourTODecimalHour(oraDecimal) {
        if (oraDecimal !== null && oraDecimal !== undefined && oraDecimal !== -1) {
            const oraDecimalString = oraDecimal.toString();
            const oraTemp = Math.floor(parseInt(oraDecimalString) / 10);
            let ora = oraTemp.toString().padStart(2, '0');
            const minuti = Math.round((Number(oraDecimal) % 10) * 6).toString().padStart(2, '0');
            // console.log(ora + ':' + minuti + ' ' + aMpM);
            return ora + ':' + minuti;
        } else {
            return '-';
        }
    }

    clickActionButton(value: ValueActionClickButtonType) {
        this.clickAction.next({key: value, lineAndGroupId: this.lineAndGroupId()})
    }

    formatLabel(value: number) {
        if (value >= 0) {
            return value;
        }
        return value;
    }

    public resetStepperByIndex(index) {
        if (index != 5) {
            this.stepper.selectedIndex = index;
            this.stepper.selected.reset();
        } else {
            this.stepper.reset();
            this.formsStepper[0] = this.fb.group(this.objectStepperForm);
            this.formsStepper[1] = this.fb.group(this.objectStepperForm);
            this.formsStepper[2] = this.fb.group(this.objectStepperForm);
            this.formsStepper[3] = this.fb.group(this.objectStepperForm);
            this.formsStepper[4] = this.fb.group(this.objectStepperForm);
        }
    }

    saveFunctionProfile() {
        let datiDaSalvare: { name: string, value }[] = [];
        const convertHourTODecimalHour = (ora: string) => {
            let oraDivisa = ora.split(':');
            oraDivisa = oraDivisa.concat(oraDivisa[1].split(' '));
            oraDivisa.splice(1, 1);
            oraDivisa[0] = (oraDivisa[0]) ? oraDivisa[0] : '00';
            oraDivisa[1] = (oraDivisa[1]) ? oraDivisa[1] : '00';
            return parseInt(oraDivisa[0]) * 10 + parseInt(oraDivisa[1]) / parseInt('6');
        }
        this.formsStepper.forEach((form, index) => {
            if (form.dirty && index < 5) {
                const tempOra = (form.get('ora').value) ? convertHourTODecimalHour(form.get('ora').value) % 240 : -1;
                const ora = {name: 't' + index, value: tempOra};
                const potenza = {
                    name: 'p' + index,
                    value: (Number.isNaN(parseInt(form.get('potenza').value)) ? 50 : parseInt(form.get('potenza').value))
                };
                datiDaSalvare.push(ora);
                datiDaSalvare.push(potenza);
                form.reset();
            }
        });
        datiDaSalvare = datiDaSalvare.concat(this.lineAndGroupId());
        this.saveValues.next(datiDaSalvare);
        this.stepper.reset();
    }

    saveFunctionProfilePRT() {
        const daysInForm = this.form.get('protocolloPRT').get('days').value;
        const fromDate = this.form.get('protocolloPRT').get('fromDate').value;
        const toDate = this.form.get('protocolloPRT').get('toDate').value;
        const profileTlcId = this.form.get('protocolloPRT').get('profileTlc').value;
        const typePeriod = this.form.get('protocolloPRT').get('typePeriod').value;
        const repeatYearForm = this.form.get('protocolloPRT').get('repeatYear').value;
        if ((arrayIsSet(daysInForm) || (fromDate && toDate)) && stringIsSet(profileTlcId)) {
            let dates = undefined;
            let days = undefined;
            let repeatYear = undefined;
            if (typePeriod == this.typesPeriod.scheduleWeek) {
                days = daysInForm
            } else {
                repeatYear = repeatYearForm;
                dates = []
                const fromDate12 = new Date(fromDate)
                fromDate12.setHours(12, 0, 0, 0)
                const toDate12 = new Date(toDate)
                toDate12.setHours(12, 0, 0, 0)
                let currentDate = fromDate12;
                while (currentDate.getTime() < toDate12.getTime()) {
                    dates.push(currentDate)
                    currentDate = new Date(currentDate.getTime())
                    currentDate.setDate(currentDate.getDate() + 1)
                }
                dates.push(toDate12)
            }

            const profileTlc = new TLC_ProfiloFunzionamentoParse()
            profileTlc.objectId = profileTlcId;
            this.saveValues.next({
                profileTlc,
                days,
                dates,
                repeatYear,
                versioneProtocollo: this.typeVersioneProtocolloLM.prt_v1
            })
        }
    }

    public lineAndGroupId(): { name: string, value }[] {
        const linee: number[] = [];
        ['linea1', 'linea2', 'linea3', 'linea4'].forEach((key, index) => {
            if (this.form.get(key).value == true) {
                linee.push((index + 1));
            }
        })

        let daSalvare = this.getGroupIdLinee(linee);
        return daSalvare;
    }

    private getGroupIdLinee(linee) {
        let daSalvare;
        const groupId = this.form.get('groupId').value;
        if (this.yesGroupId) {
            daSalvare = [{name: 'groupId', value: groupId}, {name: 'linee', value: linee}];
        } else {
            daSalvare = [{name: 'deviceId', value: groupId}, {name: 'linee', value: linee}];
        }
        return daSalvare;
    }

    liveDimming() {
        const power = parseInt(this.form.get('powerLiveLamp').value);
        const datiDaSalvare = this.lineAndGroupId().concat({name: 'p0', value: power});
        this.saveValues.next(datiDaSalvare);
    }

    selectProgrammiMV(programmmaMv: ProgrammiMVParse) {
        this.form.get('selectedProgrammaMV').setValue(programmmaMv)
    }

    isSelectedProgrammaMv(programmaMV: ProgrammiMVParse, selectedProgrammaMV: ProgrammiMVParse) {
        return selectedProgrammaMV && selectedProgrammaMV.objectId == programmaMV.objectId;
    }


    pasteValue(event: { ora, potenza, index }[]) {
        if (arrayIsSet(event)) {
            this.matExpansionPanel.open();
            event.forEach(value => {
                if (this.formsStepper.length >= value.index) {
                    const form = this.formsStepper[value.index];
                    form.get('potenza').setValue(value.potenza);
                    form.get('ora').setValue(value.ora);
                    form.markAsDirty();
                    this.stepper.selectedIndex = value.index;
                }
            })
            this.stepper.selectedIndex = 5;
        }

    }


    getCopyValue(selectedProgrammaMV: ProgrammiMVParse, anticonvertHourTODecimalHour) {
        const valuesToCopy = [];
        let index = 0;
        while (index <= 4) {
            const ora = selectedProgrammaMV['t' + index]
            const potenza = selectedProgrammaMV['p' + index]
            if (ora != null && potenza != null) {
                valuesToCopy.push({ora: anticonvertHourTODecimalHour(ora), potenza, index});
            }
            index++;
        }
        return arrayIsSet(valuesToCopy) ? valuesToCopy : undefined;
    }
}
