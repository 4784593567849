import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReportsRoutingModule} from './reports-routing.module';
import {ListReportsComponent} from './list-reports/list-reports.component';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import {CoreModule} from '../../core/core.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {WidgetsModule} from '../../widgets/widgets.module';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {DirectivesModule} from '../../providers/directives/directives.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {InfoLockedPlanModule} from '../../components/locked-plan/info-locked-plan.module';
import {CreateReportComponent} from './create-report/create-report.component';
import {InfoDeleteDialogComponent} from './info-delete-dialog/info-delete-dialog.component';
import {InfoSaveDialogComponent} from './info-save-dialog/info-save-dialog.component';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {NewReportCommentComponent} from './new-report-comment/new-report-comment.component';
import {NewReportCommentDialogComponent} from './new-report-comment-dialog/new-report-comment-dialog.component';
import {CreateGroupComponent} from './create-group/create-group.component';
import {InfoReportComponent} from './info-report/info-report.component';
import {InfoGroupComponent} from './info-group/info-group.component';
import {PipesModule} from '../../providers/pipes/pipes.module';
import {ListActivitiesComponent} from './list-activities/list-activities.component';
import {ListControlComponent} from './list-control/list-control.component';
import {PdfViewDialogComponent} from './pdf-view-dialog/pdf-view-dialog.component';
import {BoxesModule} from "../../boxes/boxes.module";
import {OpenInTabModule} from "../../components/open-in-tab/open-in-tab.module";
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from "@angular/material/legacy-progress-spinner";
import {
    ScheduleMaintenaceUtilitiesModule
} from "../../components/schedule-maintenace-utilities/schedule-maintenace-utilities.module";
import {MatLegacyProgressBarModule as MatProgressBarModule} from "@angular/material/legacy-progress-bar";

@NgModule({
    declarations: [
        ListReportsComponent,
        CreateReportComponent,
        InfoDeleteDialogComponent,
        InfoSaveDialogComponent,
        NewReportCommentComponent,
        NewReportCommentDialogComponent,
        CreateGroupComponent,
        InfoReportComponent,
        InfoGroupComponent,
        ListActivitiesComponent,
        ListControlComponent,
        PdfViewDialogComponent,
    ],
    exports: [],
    imports: [
        MatTabsModule,
        MatAutocompleteModule,
        CommonModule,
        ReportsRoutingModule,
        MatSelectModule,
        CoreModule,
        FlexLayoutModule,
        MatButtonModule,
        MatInputModule,
        MatGridListModule,
        MatSlideToggleModule,
        FormsModule,
        MatTooltipModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        TranslateModule,
        MatChipsModule,
        MatSliderModule,
        MatDividerModule,
        MatRadioModule,
        WidgetsModule,
        NgxDropzoneModule,
        MatIconModule,
        MatListModule,
        MatDialogModule,
        MatCardModule,
        MatCheckboxModule,
        DirectivesModule,
        InfoLockedPlanModule,
        PipesModule,
        BoxesModule,
        OpenInTabModule,
        MatProgressSpinnerModule,
        ScheduleMaintenaceUtilitiesModule,
        MatProgressBarModule,
    ]
})
export class ReportsModule {
}
