import {Component, Input, OnChanges, OnInit, Output} from '@angular/core';
import {CaricoEsogenoParse} from "../../../models/CaricoEsogeno.Parse";
import {CaricoEsogenoService} from "../../../providers/services/carico-esogeno.service";
import {UntypedFormGroup} from "@angular/forms";
import {EMPTY, of, Subject} from "rxjs";
import {switchMap} from "rxjs/operators";
import {arrayIsSet} from "../../../models/Models";
import {AlertService} from "../../../providers/services/alert.service";
import {TranslateService} from "@ngx-translate/core";
import {DialogPopUpService} from "../../../providers/services/dialog-pop-up.service";

@Component({
    selector: 'app-list-carichi-esogeni',
    templateUrl: './list-carichi-esogeni.component.html',
    styleUrls: ['./list-carichi-esogeni.component.scss']
})
export class ListCarichiEsogeniComponent implements OnChanges {
    @Input() carichiEsogeni: CaricoEsogenoParse[]
    @Input() carichiEsogeniExternal: CaricoEsogenoParse[]
    @Input() addSpacer: boolean=false;
    form: UntypedFormGroup;
    public reductionNote: string
    @Output() updateCaricoEsogeno = new Subject<{ caricoEsogeno: CaricoEsogenoParse, values: any, remove?: boolean }>();

    constructor(private caricoEsogenoService: CaricoEsogenoService,
                private dialogConfirmDelete: DialogPopUpService,
                private alertService: AlertService,
                private ts: TranslateService) {


    }

    ngOnChanges(): void {
    }

    openButton(caricoEsogeno: CaricoEsogenoParse | undefined = undefined) {
        this.caricoEsogenoService.formUpdateCaricoEsogeno$(caricoEsogeno)
            .pipe(switchMap((values) => {
                if (values == null) {
                    return EMPTY;
                } else if (!arrayIsSet(Object.keys(values))) {
                    return this.alertService.warning(this.ts.instant('notFieldIsUpdated')).pipe(switchMap(() => EMPTY))
                } else {
                    return of(values)
                }
            }))
            .subscribe(values => {
                this.updateCaricoEsogeno.next({values, caricoEsogeno})
            })
    }

    deleteCaricoEsogeno(caricoEsogeno: CaricoEsogenoParse) {
        this.dialogConfirmDelete.openDialogForDelete(this.ts.instant('confermDelete')).subscribe(
            resp => {
                if (resp) {
                    this.updateCaricoEsogeno.next({values: undefined, caricoEsogeno, remove: true});
                }
            }
        )
    }

    openOther() {
        if (!arrayIsSet(this.carichiEsogeniExternal)) {
            const message = this.ts.instant('dashboard_sidenav.CaricoEsogeno.notSelectable');
            this.alertService.warning(message)
        } else {
            const message = this.ts.instant('dashboard_sidenav.CaricoEsogeno.messageConfirmSubstitution');
            this.caricoEsogenoService.openSelectCaricoEsogeno$(this.carichiEsogeniExternal).pipe(
                switchMap((resp) => {
                        if (resp == null) {
                            return EMPTY;
                        } else if (!arrayIsSet(resp.add)) {
                            return this.alertService.warning(this.ts.instant('dashboard_sidenav.CaricoEsogeno.notSelected')).pipe(switchMap(() => EMPTY))
                        } else {
                            return this.dialogConfirmDelete.openDialogForDelete(message, 'center').pipe(
                                switchMap((choose => {
                                    if (choose) {
                                        return of(resp);
                                    } else {
                                        return EMPTY;
                                    }
                                }))
                            )
                        }
                    }
                )
            )
                .subscribe(
                    resp => {
                        const caricoEsogeno = resp.add[0];
                        this.updateCaricoEsogeno.next({caricoEsogeno, values: {}, remove: false})
                    }
                )
        }
    }
}
