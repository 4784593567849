import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StreetPreviewComponent} from './street-preview/street-preview.component';
import {RoadwayComponent} from './roadway/roadway.component';
import {LampComponent} from './lamp/lamp.component';
import {SidewalkComponent} from './sidewalk/sidewalk.component';
import {TrafficDividerComponent} from './traffic-divider/traffic-divider.component';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {TranslateModule} from '@ngx-translate/core';
import {EditStreetComponent} from "./edit-street/edit-street.component";
import {EditStreetFormComponent} from "./edit-street-form/edit-street-form.component";
import {WidgetsModule} from "../../widgets/widgets.module";
import {MatLegacyTabsModule as MatTabsModule} from "@angular/material/legacy-tabs";
import {ComputeResultsComponent} from "./compute-results/compute-results.component";
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {LightStreetFormComponent} from "./light-street-form/light-street-form.component";
import {MatLegacyCheckboxModule as MatCheckboxModule} from "@angular/material/legacy-checkbox";
import {MatIconModule} from "@angular/material/icon";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import {ReactiveFormsModule} from "@angular/forms";
import {CoreModule} from "../../core/core.module";
import {StreetsRoutingModule} from "../../pages-modules/streets/streets-routing.module";
import {FlexModule} from "@angular/flex-layout";
import {DirectivesModule} from "../../providers/directives/directives.module";
import {BoxesModule} from "../../boxes/boxes.module";
import {MatNativeDateModule} from "@angular/material/core";
import {PipesModule} from "../../providers/pipes/pipes.module";
import {MatLegacyRadioModule as MatRadioModule} from "@angular/material/legacy-radio";
import {MaterialTimePickerModule} from "../../dependecies/material-time-picker/material-time-picker.module";
import {MatLegacyDialogModule as MatDialogModule} from "@angular/material/legacy-dialog";
import {MatLegacyFormFieldModule as MatFormFieldModule} from "@angular/material/legacy-form-field";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatLegacyInputModule as MatInputModule} from "@angular/material/legacy-input";
import {MatLegacySlideToggleModule as MatSlideToggleModule} from "@angular/material/legacy-slide-toggle";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatLegacyListModule as MatListModule} from "@angular/material/legacy-list";
// import {AgmCoreModule} from "@agm/core";
import {MatLegacySelectModule as MatSelectModule} from "@angular/material/legacy-select";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatLegacyMenuModule as MatMenuModule} from "@angular/material/legacy-menu";
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from "@angular/material/legacy-progress-spinner";
import {LightResultComponent} from "./light-result/light-result.component";
import {PhotometryComponent} from "./photometry/photometry.component";
//todo aggiornanre mappa

@NgModule({
    declarations: [
        StreetPreviewComponent,
        RoadwayComponent,
        LampComponent,
        SidewalkComponent,
        TrafficDividerComponent,
        EditStreetComponent,
        EditStreetFormComponent,
        ComputeResultsComponent,
        LightStreetFormComponent,
        LightResultComponent,
        PhotometryComponent
    ],
    imports: [
        CommonModule,
        StreetsRoutingModule,
        MatCardModule,
        FlexModule,
        TranslateModule,
        DirectivesModule,
        WidgetsModule,
        MatIconModule,
        CoreModule,
        MatTabsModule,
        BoxesModule,
        MatNativeDateModule,
        PipesModule,
        ReactiveFormsModule,
        MatRadioModule,
        MaterialTimePickerModule,
        MatDialogModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatInputModule,
        MatSlideToggleModule,
        MatButtonToggleModule,
        MatListModule,
        // AgmCoreModule,
        MatButtonModule,
        MatListModule,
        MatSelectModule,
        MatExpansionModule,
        MatTooltipModule,
        MatMenuModule,
        FlexModule,
        MatCheckboxModule,
        MatProgressSpinnerModule
    ],
    exports: [
        StreetPreviewComponent,
        EditStreetComponent,
        LightResultComponent
    ],
    schemas: [
        NO_ERRORS_SCHEMA
    ]
})
export class StreetModule {
}
