<div class="pinch-zoom-content" [class.pz-dragging]="isDragging">
    <ng-content>
    </ng-content>
</div>
<button class="pz-zoom-button pz-zoom-control-position-bottom" mat-mini-fab color="primary" (click)="toggleZoom()"
        *ngIf="isControl()">
    <mat-icon>
        <ng-container *ngIf="isZoomedIn;else ZoomOut">
            zoom_out
        </ng-container>
        <ng-template #ZoomOut>
            zoom_in
        </ng-template>
    </mat-icon>
</button>

