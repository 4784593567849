<mat-card class="dimension-box huna-material-class"
        matBadgePosition="before"
          [matBadgeDisabled]="disabled"
          [matBadge]="photometryNumber"
          [matBadgeColor]="selected?'primary':'accent'">
    <div class="flex-center-width100" style="height: 100%">
        <div style="flex:1;display:flex;justify-content: center" (click)="cardClick()">
            <mat-icon>folder_open</mat-icon>
        </div>
        <div style="flex:2" (click)="cardClick()">
            <h4>{{name}}</h4>
        </div>
        <div style="display:flex;justify-content: flex-end" *ngIf="visualizedMenu">
            <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
            </button>
        </div>
    </div>
</mat-card>

<mat-menu #menu="matMenu" xPosition="before">
    <!--    <button mat-menu-item [matMenuTriggerFor]="photometryList">{{'fotometrie.add'|translate}}</button>-->
    <button mat-menu-item (click)="remove()">{{'fotometrie.remove'|translate}}</button>
    <button mat-menu-item (click)="update()">  {{'fotometrie.modify'|translate}}</button>
</mat-menu>

<!--<mat-menu #photometryList="matMenu">-->
<!--    <div mat-menu-item (click)="$event.stopPropagation();">-->
<!--        <mat-checkbox>fotometria 1</mat-checkbox>-->
<!--    </div>-->
<!--    <div mat-menu-item (click)="$event.stopPropagation();">-->
<!--        <mat-checkbox>fotometria 2</mat-checkbox>-->
<!--    </div>-->
<!--    <div mat-menu-item (click)="confirmAddPhotometries()">-->
<!--        <mat-icon>check</mat-icon>-->
<!--    </div>-->
<!--</mat-menu>-->
