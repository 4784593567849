import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CalcolariceEsadecimaleService {

  constructor() {
  }

  public sumHex(elm1: string, elm2: string,): string {
    const somma: string = (parseInt(elm1, 16) + parseInt(elm2, 16)).toString(16).toUpperCase();
    //console.log("somma", somma);
    return (somma);
  }

  public sotHex(elm1: string, elm2: string,): string {
    const sot: string = (parseInt(elm1, 16) - parseInt(elm2, 16)).toString(16).toUpperCase();
    //console.log("somma", sot);
    return (sot);
  }

  public divHex(elm1: string, elm2: string,): string {
    const div: string = (parseInt(elm1, 16) / parseInt(elm2, 16)).toString(16).toUpperCase();
    //console.log("somma", div);
    return (div);
  }

  public moltHex(elm1: string, elm2: string,): string {
    const molt: string = (parseInt(elm1, 16) * parseInt(elm2, 16)).toString(16).toUpperCase();
    //console.log("somma", molt);
    return (molt);
  }

}
