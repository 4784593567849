import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {ReportsService} from "../../../providers/services/reports.service";

@Component({
    selector: 'app-pdf-view-dialog',
    templateUrl: './pdf-view-dialog.component.html'
})
export class PdfViewDialogComponent implements OnInit {

    public planForm: UntypedFormGroup;

    constructor(
        private fb: UntypedFormBuilder,
        private planService: ReportsService,
        public dialogRef: MatDialogRef<PdfViewDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { reportObjectId: string }
    ) {

    }

    ngOnInit() {
        this.planForm = this.fb.group({
                formato: ['a4'],
                mapType: ['roadmap'],
            }
        )

    }

    public async closePlan() {
        let fileLink = await this.planService.previewLink(this.data.reportObjectId, this.planForm.value.formato, this.planForm.value.mapType);
        window.open(fileLink);
        this.dialogRef.close()
    }


}