<mat-dialog-content>
    <div style="display: flex;align-items: center;flex-wrap:wrap;justify-content: center">
        <div class="data" style="width:400px;text-align: center;">
            <H4>
                <img class="icon-dimension" src="/assets/icon/dialogCompnent/ask.png">
            </H4>
            <mat-list role="list">
                <mat-list-item role="listitem">
                    <h5>{{data.message|translate}}</h5>
                </mat-list-item>
                <mat-list-item role="listitem">
                    <div style="border-radius:5px;margin-right:10px;width: 10px;height: 10px"
                         [ngStyle]="{background:color.pure}"></div>
                    <p style=" font-size:15px;">   {{'doesNotBelong'|translate}}</p>
                </mat-list-item>
                <mat-list-item role="listitem">
                    <div style="border-radius:5px;margin-right:10px;width: 10px;height: 10px"
                         [ngStyle]="{background:color.single}"></div>
                    <p style=" font-size:15px;">   {{'fieldOneUse'|translate}}</p>
                </mat-list-item>
                <mat-list-item role="listitem">
                    <div style="border-radius:5px;margin-right:10px;width: 10px;height: 10px"
                         [ngStyle]="{background:color.double}"></div>
                    <p style=" font-size:15px;">  {{'fieldTwoUse'|translate}}</p>
                </mat-list-item>
                <mat-list-item role="listitem">
                    <h5 style="color: #535353">{{'emptyField'|translate}}: {{numberOfNotValue}}</h5>
                </mat-list-item>
            </mat-list>
        </div>
        <h5 style="color: #535353;width: 100%">{{'rowsColoumnSelectRow'|translate}}</h5>
    </div>
    <div class="example-container">
        <form [formGroup]="formTitle">
            <mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="end" stickyEnd>
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <mat-icon>more_vert</mat-icon>
                    </td>
                </ng-container>
                <ng-container *ngFor="let key of visibleColumns;let i = index">
                    <ng-container *ngIf="key!='end'">
                        <ng-container [matColumnDef]="key">
                            <th mat-header-cell *matHeaderCellDef
                                style="padding: 5px;border-left: 1px solid rgba(128,128,128,0.37);border-top: 1px solid rgba(128,128,128,0.37);text-align: center">
                                {{data.rowTitleRaw[i]}}
                            </th>
                            <td mat-cell *matCellDef="let element"
                                style="padding: 5px;text-align: center;border-left: 1px solid rgba(128,128,128,0.37);margin-top: 5px">
                                <mat-form-field appearance="fill">
                                    <mat-label>{{getChar(i)}}</mat-label>
                                    <mat-select [formControlName]="key">
                                        <mat-option [value]="title"
                                                    *ngFor="let title of originalAllTitle">
                                            <div style="width:100%;display: flex;align-items: center;justify-content: flex-start">
                                                <div style="border-radius:5px;margin-right:10px;width: 10px;height: 10px"
                                                     [ngStyle]="{background:markOption(title)}"></div>
                                                <p>{{getProjectName(title)|translate}} {{getPathTraduction(title)|translate}}</p>
                                            </div>
                                        </mat-option>
                                    </mat-select>
                                    <button
                                            matSuffix mat-icon-button class="small"
                                            [disabled]="formTitle.get(key).disabled"
                                            (click)="$event.stopPropagation();eliminaCampo(key)">
                                        <mat-icon>clear</mat-icon>
                                    </button>
                                </mat-form-field>
                            </td>
                        </ng-container>
                    </ng-container>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="visibleColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: visibleColumns;"></tr>
            </mat-table>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button [disabled]="formTitle.invalid || atLeatOneFieldIsFull()" color="primary" mat-flat-button
            [mat-dialog-close]="rowTitle">
        <mat-icon>check</mat-icon>
    </button>
</mat-dialog-actions>
