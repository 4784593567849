import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {MonthYear} from "../../../classes/MonthYear";
import {LuxDataMapElementService} from "../../../providers/services/lux-data-map-element.service";
import {Subject, Subscription} from "rxjs";
import {formatDate} from "@angular/common";
import {TranslateService} from "@ngx-translate/core";
import {className, isNotNullOrUndefined} from "../../../models/Models";
import {LuxDataClusterService} from "../../../providers/services/lux-data-cluster.service";
import {AlertService} from "../../../providers/services/alert.service";
import {FileServiceService} from "../../../providers/services/file-service.service";

@Component({
    selector: 'app-illuminamento-terra',
    templateUrl: './illuminamento-terra.component.html',
    styleUrls: ['./illuminamento-terra.component.scss']
})
export class IlluminamentoTerraComponent implements OnInit, OnDestroy {

    public dateForm: UntypedFormGroup
    currentMonthYear = new MonthYear(this.luxDataClusterService.currentMonth, new Date().getFullYear()) //Jan = 0
    storedMonthYear = new MonthYear(this.currentMonthYear.month, this.currentMonthYear.year)
    @Output() monthYearImpostato = new EventEmitter();
    @Output() activeIlluminamentoATerra = new EventEmitter();
    @Input() visibilityLuxDataCluster
    @Input() private resetAllFilter: Subject<boolean> = new Subject();
    private subscriptions: Subscription[] = [];
    public loading = {print: false};


    constructor(
        private translate: TranslateService,
        private luxDataMapElementService: LuxDataMapElementService,
        private luxDataClusterService: LuxDataClusterService,
        private alert: AlertService,
        private fileService: FileServiceService,
    ) {
        const attivoIlluminamentoATerra = false;
        this.dateForm = new UntypedFormGroup({
            activeIlluminamentoAterra: new UntypedFormControl({
                value: attivoIlluminamentoATerra,
                disabled: false
            }),
            monthLuxCluster: new UntypedFormControl({
                value: this.currentMonthYear.month,
                disabled: !attivoIlluminamentoATerra
            }),
            yearLuxCluster: new UntypedFormControl({value: this.currentMonthYear.year, disabled: !attivoIlluminamentoATerra}),
        });

        let subscription = this.dateForm.get('monthLuxCluster').valueChanges.subscribe(value => {
            if (this.illuminamentoATerraAttivo) {
                this.emitNewMonthYear();
            }
        })
        this.subscriptions.push(subscription);
        subscription = this.dateForm.get('yearLuxCluster').valueChanges.subscribe(value => {
            if (this.illuminamentoATerraAttivo) {
                this.emitNewMonthYear();
            }
        })
        this.subscriptions.push(subscription);
        // subscription = this.dateForm.get('zoomTresholdLineToCircle').valueChanges.subscribe(value => {
        //     this.zoomTresholdLineToCircleChanged(value);
        // })
        // this.subscriptions.push(subscription);
        subscription = this.dateForm.get('activeIlluminamentoAterra').valueChanges.subscribe(value => {
            if (value) {
                // this.dateForm.get('zoomTresholdLineToCircle').enable()
                this.dateForm.get('yearLuxCluster').enable({emitEvent: false})
                this.dateForm.get('monthLuxCluster').enable({emitEvent: false})
            } else {
                // this.dateForm.get('zoomTresholdLineToCircle').disable()
                this.dateForm.get('yearLuxCluster').disable({emitEvent: false})
                this.dateForm.get('monthLuxCluster').disable({emitEvent: false})
            }
            this.activeIlluminamentoATerra.emit(value);
        })
        this.subscriptions.push(subscription);
    }


    get illuminamentoATerraAttivo() {
        return this.dateForm.get('activeIlluminamentoAterra').value
    }

    ngOnInit(): void {
        const subscription = this.resetAllFilter.asObservable().subscribe(
            resetAllFilter => {
                if (resetAllFilter) {
                    this.unsetIlluminamentoAterra();
                }
            }
        )
        this.subscriptions.push(subscription);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe())
    }


    /**
     * Confronta se il nuovo mese e anno che si vuole applicare è diverso da quello visualizzato correntemente.
     * per fare questo memorizza quello vecchio e lo confronta. Se diverso, emette nuovo valore a pages-main-map
     */
    emitNewMonthYear() {
        const monthYear = new MonthYear(this.dateForm.get('monthLuxCluster').value, this.dateForm.get('yearLuxCluster').value)
        this.storedMonthYear = monthYear;
        this.monthYearImpostato.emit(monthYear);
    }

    /**
     * Aggiorna il dato nel service, che ricrea gli elementi col nuovo parametro.
     * @param value: nuova soglia
     */
    zoomTresholdLineToCircleChanged(value: number) {
        return this.luxDataMapElementService.updateZoomTresholdLineToCircle(value)
    }

    getMonthYear(month, year) {
        const locale = this.translate.currentLang;
        if (!isNotNullOrUndefined(month) || !isNotNullOrUndefined(year)) {
            return month + ' ' + year;
        } else {
            const monthNumber = (typeof month == "number") ? month : parseInt(month);
            const yearNumber = (typeof year == "number") ? year : parseInt(year);
            if (!isNaN(monthNumber) && !isNaN(yearNumber)) {
                const date = new Date(yearNumber, monthNumber, 1, 0, 0, 0, 0);
                const separator = (locale == 'en') ? ', ' : ' ';
                const stringDate = formatDate(date, 'MMMM' + separator + 'y', locale);
                return stringDate;
            } else {
                return month + ' ' + year;
            }
        }
    }


    setIlluminamentoAterra(value) {
        if (this.chipIsSelected(value)) {
            this.unsetIlluminamentoAterra();
        } else {
            this.dateForm.get('activeIlluminamentoAterra').setValue(value);
        }
    }

    unsetIlluminamentoAterra() {
        this.dateForm.get('activeIlluminamentoAterra').reset();
    }

    chipIsSelected(value) {
        return this.dateForm.get('activeIlluminamentoAterra').value === value;
    }

    chipColor(value) {
        if (this.chipIsSelected(value)) {
            return 'primary';
        } else {
            return 'accent'
        }
    }


    get dateForHtml() {
        const month = this.dateForm.get('monthLuxCluster').value
        const year = this.dateForm.get('yearLuxCluster').value
        return this.getMonthYear(month - 1, year);
    }

    changeMonthNext() {
        const month = this.dateForm.get('monthLuxCluster').value - 1// è traslato di un piu uno il backend
        const year = this.dateForm.get('yearLuxCluster').value
        const date = new Date(year, month + 1, 1, 0, 0, 0, 0);
        if (date.getFullYear() == year) {
            this.dateForm.get('monthLuxCluster').setValue(date.getMonth() + 1);
        } else {
            this.dateForm.get('monthLuxCluster').setValue(date.getMonth() + 1, {emitEvent: false});
            this.dateForm.get('yearLuxCluster').setValue(date.getFullYear());
        }
    }

    changeMonthPrevius() {
        const month = this.dateForm.get('monthLuxCluster').value - 1// è traslato di un piu uno il backend;
        const year = this.dateForm.get('yearLuxCluster').value;
        const date = new Date(year, month - 1, 1, 0, 0, 0, 0);
        if (date.getFullYear() == year) {
            this.dateForm.get('monthLuxCluster').setValue(date.getMonth() + 1);
        } else {
            this.dateForm.get('monthLuxCluster').setValue(date.getMonth() + 1, {emitEvent: false});
            this.dateForm.get('yearLuxCluster').setValue(date.getFullYear());
        }
    }

    changeYearNext() {
        const year = this.dateForm.get('yearLuxCluster').value;
        this.dateForm.get('yearLuxCluster').setValue(year + 1);
    }

    changeYearPrevius() {
        const year = this.dateForm.get('yearLuxCluster').value;
        this.dateForm.get('yearLuxCluster').setValue(year - 1);
    }

    get monthIsAfterToday() {
        const year = this.dateForm.get('yearLuxCluster').value;
        const month = this.dateForm.get('monthLuxCluster').value;
        const today = new Date();
        return month > today.getMonth() && year + 1 > today.getFullYear();
    }

    get yearIsAfterToday() {
        const year = this.dateForm.get('yearLuxCluster').value;
        const today = new Date();
        return year + 1 > today.getFullYear();
    }


    private creaFileLuxDataCluster(luxdata, hederTranslate) {
        const data = new Date()
        try {
            if (luxdata.length > 0) {
                this.fileService.downloadFile(luxdata, 'luxDataClusters' + data.getHours() + '-' + data.getMinutes() + '.csv', className.clusterLuxData, hederTranslate);
                const exportmessage: string = this.translate.instant('export.success');
                this.alert.success(exportmessage);
            } else {
                const exportmessage: string = this.translate.instant('export.error');
                this.alert.error(exportmessage);
            }
        } catch (e) {
            this.alert.error(e.message);
        }
    }

    public downloadFileLuxDataCluster() {
        this.loading.print = true;
        const hederObj = ["latitude", "longitude", "type", "value", "referenceMonth", "referenceYear"];
        const hederTranslate = hederObj.map(key => this.translate.instant('clustersLuxData.' + key));
        this.luxDataMapElementService.getForPrintLuxData().subscribe(
            luxDataClusters => {
                const daStampare = luxDataClusters.map(
                    luxData => {
                        const obj = {};
                        hederObj.forEach(key => {
                            if (key == 'latitude' || key == 'longitude') {
                                obj[key] = luxData.location[key];
                            } else {
                                obj[key] = luxData[key];
                            }
                        })
                        return obj;
                    }
                )
                this.creaFileLuxDataCluster(daStampare, hederTranslate)
                this.loading.print = false;
            }, error => {
                this.loading.print = false;
            }
        );
    }

    get mapZoom(): number {
        if (this.luxDataMapElementService.map) {
            return this.luxDataMapElementService.map.getZoom();
        } else {
            return -1;
        }
    }

    get enoughZoomDownload(): boolean {
        return this.mapZoom > 9
    }

}
