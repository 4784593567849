import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {LineaElettricaService} from '../../../providers/services/linea-elettrica.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {LineaElettricaParse} from '../../../models/LineaElettrica.Parse';
import {configurationPropertyElectricLine} from '../../../models/configurationProperty/electricLine';
import {
    castValueByConfigurationElement,
    configurationElement, getElementByKeyName, getPossibleValuePointer, getSubForm, getValidator, getValidatorFormField,
    isPointerConfigurationElement, typeDatabase,
    typeFormValue
} from '../../../models/configurationProperty/configurationPropertyUtils';
import {arrayIsSet, className, isNotNullOrUndefined} from '../../../models/Models';
import {Subject, Subscription} from 'rxjs';
import {htmlData, htmlFormElement} from '../../dashboard-sidenav/form-item-on-map/form-item-on-map.component';
import {CircuitiParse} from '../../../models/Circuiti.Parse';

@Component({
    selector: 'app-new-electric-line-detail',
    templateUrl: './new-electric-line-detail.component.html',
    styleUrls: ['./new-electric-line-detail.component.scss']
})
export class NewElectricLineDetailComponent implements OnInit, OnDestroy {
    public htmlData: htmlData[] = [];
    public configElementSubForm: { element: configurationElement, htmlFormElement: htmlFormElement }[] = [];
    public subscriptionsKeyWithSubForm: Subscription[] = [];
    public form: UntypedFormGroup;
    sectionNumberName = {0: 'dashboard_sidenav.LineaElettrica.features', 1: 'finale'};
    isSetForm = new Subject();
    private nomiClassi = className;

    private get circuitsInProject(): CircuitiParse[] | null {
        return this.data.circuitsInProject;
    }

    constructor(public dialogRef: MatDialogRef<NewElectricLineDetailComponent>,
                @Inject(MAT_DIALOG_DATA)
                public data: { oldValue: any, message: string, circuitsInProject: CircuitiParse[] },
                private fb: UntypedFormBuilder,
                private lineaElettricaService: LineaElettricaService) {
        const linea = new LineaElettricaParse();
        if (data.oldValue != null) {
            Object.keys(data.oldValue).forEach(key => {
                linea[key] = data.oldValue[key];
            });
        }
        this.composeElectricLineInfo(linea);
        // this.form = this.lineaElettricaService.getForm(this.data.oldValue);
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.closeSubscriptionForm();
        this.isSetForm.complete();
    }

    closeWithValue(): void {
        const obj = {};
        Object.keys(this.form.value).forEach(key => {
            if (this.form.get(key).dirty) {
                const element = getElementByKeyName(key, configurationPropertyElectricLine);
                let value;
                if (element != null) {
                    if (element.pointer != null && element.pointer.className === this.nomiClassi.circuiti) {
                        value = castValueByConfigurationElement(this.form.value[key], element, this.circuitsInProject);
                    } else {
                        value = castValueByConfigurationElement(this.form.value[key], element, undefined);
                    }
                } else {
                    value = this.form.value[key];
                }
                if (value != null) {
                    obj[key] = value;
                }
            }
        });
        this.dialogRef.close(obj);
    }

    private closeSubscriptionForm() {
        this.subscriptionsKeyWithSubForm.forEach(sub => sub.unsubscribe());
        this.subscriptionsKeyWithSubForm = [];
    }

    private addHtmlElement(element: configurationElement, htmlFormElement: htmlFormElement, htmlData: htmlData[]): void {
        const tabGroupName = this.sectionNumberName.hasOwnProperty(element.section) ? this.sectionNumberName[element.section] : 'null';
        const index = htmlData.findIndex(htmlValue => htmlValue.tabGroupName === tabGroupName);
        if (index >= 0) {
            htmlData[index].forms.push(htmlFormElement);
        } else {
            htmlData.push({tabGroupName, forms: [htmlFormElement]});
        }
    }


    getFormHtmlData(configurationData: configurationElement[], detail): { formGruop: UntypedFormGroup, htmlData: htmlData [], configElementSubForm: { element: configurationElement, htmlFormElement: htmlFormElement }[] } {
        const html: htmlData[] = [];
        const objForm = {};
        const configElementSubForm = [];
        configurationData.forEach(element => {
            if (element.showInForm) {
                let possibleValues;
                let valueForm;
                if (element.typeDatabase === typeDatabase.POINTER && (element.typeForm === typeFormValue.ELENCO_VINCOLATO || element.typeForm === typeFormValue.ELENCO_APERTO)) {
                    if (element.pointer != null && element.pointer.className === this.nomiClassi.circuiti) {
                        if (arrayIsSet(this.circuitsInProject)) {
                            const pc = getPossibleValuePointer(detail[element.keyName], element, this.circuitsInProject);
                            possibleValues = pc.possibleValues;
                            valueForm = pc.currentValue;
                        }
                    }
                } else if (element.typeForm === typeFormValue.GEO_POINT) {
                    valueForm = {lat: detail[element.keyName].latitude, lng: detail[element.keyName].longitude};
                } else if (isPointerConfigurationElement(element)) {
                    valueForm = element.pointer.getValue(detail);
                } else if (arrayIsSet(element.possibleValues)) {
                    const pValueCurrent = this.lineaElettricaService.getPossibleValuesCurrentValue(element.possibleValues, detail[element.keyName]);
                    possibleValues = pValueCurrent.possibleValues;
                    valueForm = pValueCurrent.currentValue;
                } else {
                    valueForm = detail[element.keyName];
                }

                // tslint:disable-next-line:no-shadowed-variable
                const htmlFormElement: htmlFormElement = {
                    type: element.typeForm,
                    possibleValues,
                    formControlName: element.keyName,
                    traduction: element.traductionKey,
                    formGroupChild: undefined
                };
                objForm[element.keyName] = [{
                    value: valueForm,
                    disabled: !element.editable
                }, getValidator(element)];
                const subFormValue = this.lineaElettricaService.getFormGroupChild(element, detail, detail[element.keyName], detail.className);
                if (isNotNullOrUndefined(subFormValue.formGroupChild)) {
                    htmlFormElement.formGroupChild = subFormValue.formGroupChild;
                    objForm[subFormValue.subObjForm.key] = subFormValue.subObjForm.formGroup;
                    configElementSubForm.push({element, htmlFormElement});
                }
                this.addHtmlElement(element, htmlFormElement, html);
            }
        });
        return {formGruop: this.fb.group(objForm), htmlData: html, configElementSubForm};
    }

    public composeElectricLineInfo(detail: LineaElettricaParse) {
        this.htmlData = [];
        this.form = undefined;
        this.configElementSubForm = [];
        setTimeout(() => {
            const configurationData = configurationPropertyElectricLine.sort((aValue, bValue) => aValue.sortingValue - bValue.sortingValue);
            const valueFormHtml = this.getFormHtmlData(configurationData, detail);
            this.form = valueFormHtml.formGruop;
            this.htmlData = valueFormHtml.htmlData;
            this.configElementSubForm = valueFormHtml.configElementSubForm;
            this.configElementSubForm.forEach(elementHtml => {
                const subScription = this.form.get(elementHtml.element.keyName).valueChanges.subscribe(
                    changeValue => {
                        elementHtml.htmlFormElement.formGroupChild = this.lineaElettricaService.getFormGroupChild(elementHtml.element, {}, changeValue, this.nomiClassi.lineaElettrica).formGroupChild;
                    });
                this.subscriptionsKeyWithSubForm.push(subScription);
            });
            this.isSetForm.next(true);
        });

    }

}
