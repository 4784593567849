<div style="position: relative;height: 100%">
    <h5 style="margin-top: 0px;">{{'remote_control.createNew'|translate}}</h5>
    <form class="example-form" [formGroup]="newIotDevicesForm">
        <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>{{'square_number'|translate}}</mat-label>
            <input type="text" matInput formControlName="circuiti"
                   [matAutocomplete]="autoNumeroQuadro">
            <mat-autocomplete #autoNumeroQuadro="matAutocomplete"
                              [displayWith]="displayFnNumeroQuadro.bind(this)"
                              (optionSelected)="selectCircuit($event.option.value)">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option.objectId">
                    {{option.numeroQuadro}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </form>
    <button style="position:absolute;top: 95%;left: 40%" color="accent" mat-flat-button
            [disabled]="!newIotDevicesForm.get('circuiti').value"
            [mat-dialog-close]="newIotDevicesForm.get('circuiti').value">
        <mat-icon>save</mat-icon>
    </button>
</div>
