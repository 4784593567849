<cdk-virtual-scroll-viewport [itemSize]="itemSize"
                             class="example-viewport"
                             *ngIf="dynamicDataSources!=null">
    <div class="flex-start-center  w100 flex-flow-row">
        <ng-container *cdkVirtualFor="let item of dynamicDataSources; let index=index">
            <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: item,index:index,items:items}">
            </ng-container>
        </ng-container>
    </div>
</cdk-virtual-scroll-viewport>

