<div mat-dialog-title style="position: relative;">{{'info_new_report_comment' | translate}}
    <button style="position: absolute;top: -16px;right: -16px;" mat-icon-button color="accent"
            (click)="closeDialog()">
        <mat-icon>cancel</mat-icon>
    </button>
</div>
<div mat-dialog-content>
    <form *ngIf="activityForm" [formGroup]="activityForm">
        <div>
            <mat-form-field *ngIf="visualizedMultiSelect;else radioButtonNuovoStato" color="primary">
                <mat-label>{{ 'status' | translate}}</mat-label>
                <mat-select formControlName="nuovoStato">
                    <mat-option value="{{states.COMPLETATO}}">
                        {{'completed_intervention' | translate}}
                    </mat-option>
                    <mat-option value="{{states.MATERIALE}}">
                        {{'material_waiting' | translate}}
                    </mat-option>
                    <mat-option value="{{states.INTERVENTO}}">
                        {{'intervention_waiting' | translate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <ng-template #radioButtonNuovoStato>

                <div>
                    <mat-form-field>
                        <label class="label">{{ 'status' | translate}}</label>
                        <mat-select formControlName="nuovoStato">
                            <mat-option
                                    value="{{states.COMPLETATO}}">{{'completed_intervention' | translate}}</mat-option>
                            <mat-option value="{{states.MATERIALE}}">{{'material_waiting' | translate}}</mat-option>
                            <mat-option
                                    value="{{states.INTERVENTO}}">{{'intervention_waiting' | translate}}</mat-option>
                            <mat-option
                                    value="{{states.INSTALLATO_MULETTO}}">{{'segnalazioniParse.stato.' + states.INSTALLATO_MULETTO | translate}}</mat-option>
                            <mat-option
                                    value="{{states.MESSA_IN_SICUREZZA}}">{{'segnalazioniParse.stato.' + states.MESSA_IN_SICUREZZA | translate}}</mat-option>
                            <mat-option
                                    value="{{states.EMESSO_PREVENTIVO}}">{{'segnalazioniParse.stato.' + states.EMESSO_PREVENTIVO | translate}}</mat-option>
                            <mat-option
                                    value="{{states.RICERCA_GUASTO}}">{{'segnalazioniParse.stato.' + states.RICERCA_GUASTO | translate}}</mat-option>
                            <mat-option
                                    value="{{states.IN_ATTESA}}">{{'segnalazioniParse.stato.' + states.IN_ATTESA | translate}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!--                    <mat-radio-group formControlName="nuovoStato" id="nuovoStato" color="primary">-->
                    <!--                        <mat-radio-button value="{{states.COMPLETATO}}">{{'completed_intervention' | translate}}-->
                    <!--                        </mat-radio-button>-->
                    <!--                        <mat-radio-button value="{{states.MATERIALE}}">{{'material_waiting' | translate}}-->
                    <!--                        </mat-radio-button>-->
                    <!--                        <mat-radio-button value="{{states.INTERVENTO}}">{{'intervention_waiting' | translate}}-->
                    <!--                        </mat-radio-button>-->
                    <!--                    </mat-radio-group>-->
                </div>
            </ng-template>
        </div>
        <div>
            <mat-form-field *ngIf="visualizedMultiSelect;else radioButtonTypeReport" color="primary">
                <mat-label>{{ 'comment_typology' | translate}}</mat-label>
                <mat-select formControlName="typeReport">
                    <mat-option value="comment">
                        {{'text' | translate}}
                    </mat-option>
                    <mat-option value="photo">
                        {{'photo' | translate}}
                    </mat-option>
                    <mat-option value="file">
                        {{'attachment' | translate}}
                    </mat-option>
                    <mat-option value="schedaManutenzione">
                        {{'SchedaManutenzione' | translate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <ng-template #radioButtonTypeReport>
                <label class="label">{{ 'comment_typology' | translate}}</label>
                <div style="padding-bottom:10px;">
                    <mat-radio-group formControlName="typeReport" color="primary">
                        <mat-radio-button value="comment">
                            {{'text' | translate}}</mat-radio-button>
                        <mat-radio-button value="photo">{{'photo' | translate}}
                        </mat-radio-button>
                        <mat-radio-button value="file">
                            {{'attachment' | translate}}
                        </mat-radio-button>
                        <mat-radio-button style="margin-top: 0.2rem;" value="schedaManutenzione"
                        >
                            {{'SchedaManutenzione' | translate}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </ng-template>
        </div>

        <ng-container [ngSwitch]="typeReport">
            <ng-container *ngSwitchCase="'comment'">
                <app-form-group-template title="{{ 'comment' | translate}}">
                    <mat-form-field floatLabel="auto" appearance="fill">
                        <mat-label>{{ 'comment' | translate}}</mat-label>
                        <textarea
                                cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize"
                                cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="15"
                                formControlName="comment" matInput>
                        </textarea>
                    </mat-form-field>
                    <!--                    <mat-error *ngIf="activityForm.get('comment').invalid && saving" >{{ 'comment_validation' | translate}}</mat-error>-->
                </app-form-group-template>
            </ng-container>
            <ng-container *ngSwitchCase="'photo'">
                <div>
                    <div class="mat-form-field">
                        <label class="h2"> {{ 'photo' | translate}}</label>
                        <ngx-dropzone (change)="onLoadImage($event.addedFiles,'foto')" accept="image/*">
                            <ngx-dropzone-label>
                                {{ 'drag_image' | translate}}
                            </ngx-dropzone-label>
                        </ngx-dropzone>
                        <!--                        <mat-error *ngIf="activityForm.get('foto').invalid && saving" style="margin-top: 0.5rem">{{ 'photo_validation' | translate}}</mat-error>-->
                        <mat-list role="list">
                            <ng-container *ngIf="activityForm.get('foto').value">
                                <div>
                                    <a href="{{activityForm.get('foto').value.url}}" target="_blank">
                                        <img src="{{activityForm.get('foto').value.url}}" height="30px">
                                    </a>
                                </div>
                                <button class="remove-attachment-button" (click)="deleteSavedFile('foto')">
                                    {{ 'remove_attachement' | translate}}
                                </button>
                            </ng-container>
                        </mat-list>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="'file'">
                <div>
                    <div class="mat-form-field" style="overflow: scroll;">
                        <label class="h2"> {{ 'attachment' | translate}}</label>
                        <ngx-dropzone (change)="onLoadImage($event.addedFiles,'allegato')"
                                      accept="application/pdf">
                            <ngx-dropzone-label>
                                {{ 'drag_file' | translate}}
                            </ngx-dropzone-label>
                        </ngx-dropzone>
                        <!--                        <mat-error *ngIf="activityForm.get('comment').invalid && saving" style="margin-top: 0.5rem">{{ 'pdf_validation' | translate}}</mat-error>-->
                        <mat-list role="list">
                            <ng-container *ngIf="activityForm.get('allegato').value">
                                <div>
                                    <a href="{{activityForm.get('allegato').value.url}}" target="_blank">
                                        <div fxLayout="row" fxLayoutAlign="start center">
                                            <mat-icon height="30px">attachment</mat-icon>
                                            <span style="margin-left: 0.5rem">
                                        {{activityForm.get('allegato').value.name.split('_')[1]}}
                                    </span>
                                        </div>
                                        <!--                                            <img src="{{activityForm.get('allegato').value.url}}" height="30px">-->
                                    </a>
                                </div>
                                <button class="remove-attachment-button" (click)="deleteSavedFile('allegato')">
                                    {{ 'remove_attachement' | translate}}
                                </button>

                            </ng-container>
                        </mat-list>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="'schedaManutenzione'">
                <ng-container *ngIf="load.schedeManutenzione;else noLoadScheda">
                    <div class="flex-center-width100">
                        <mat-spinner></mat-spinner>
                    </div>
                </ng-container>
                <ng-template #noLoadScheda>
                    <ng-container *ngIf="loadImage.show">
                        <h5 style="text-align: center;margin-bottom: 5px;">
                            {{'scheduleMaintenance.saving'|translate}}&#160;{{loadImage.progress}} %
                        </h5>
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </ng-container>
                    <div>
                        <app-list-schedule-small
                                [elementSchedaCompilata]="{className:'Segnalazioni',objectId:data.reportObjectId}"
                                style="max-width: 100%;"
                                [disabled]="disable.schedaManutenzione"
                                [loading]="false"
                                [schedeManutenzione]="data.schedeManutenzione"
                                [myRole]="data.myRole"
                                [abbonamentiAttivi]="data.tuttiAbbonamenti"
                                (savedValue)="saveSchedaManutenzione($event)"
                                [visualizeSchedeDaSelezionare]="true"
                                [cssPositionSaveButton]="cssPositionButton"
                                [bozzeSchedePrecompilate]="bozzeSchedePrecompilate">
                        </app-list-schedule-small>
                    </div>
                </ng-template>
            </ng-container>
        </ng-container>
        <div class="spacer" *ngIf="typeReport!=='schedaManutenzione'">
            <button [disabled]="disable.schedaManutenzione"
                    mat-flat-button color="primary" (click)="save()">{{ 'save' | translate}}</button>
        </div>
    </form>
</div>