<div *ngIf="activityIsSet;else noActivity">
    <div *ngFor="let activity of activities" class="spacer"
         style="width: 100%; display: flex; flex-direction: column;"
         [ngClass]="commentOfAuthor(activity.nomeAutore) ? 'comment-align-right' : 'comment-align-left'">

        <div style="max-width:calc(100% - 20px); padding: 10px;"
             [ngClass]="commentOfAuthor(activity.nomeAutore) ? 'comment-author' : 'comment'">

            <div class="comment-label" style="margin-bottom: 0.3rem;">
                {{activity.nomeAutore}} - {{activity.createdAt |customDate :'medium'|async}}
            </div>
            <div>
                <div *ngIf="activity.nuovoStato" class="card-label color-primary"
                     style="display: flex; align-items: center; justify-content:flex-start;">
                    <div>
                        {{('segnalazioniParse.stato.' + activity.statoPrecedente|translate)|notPresentInTraduction:activity.statoPrecedente:'reportSegnalazioni.stato.'}}
                    </div>
                    <mat-icon style="margin-left: 0.2rem; margin-right: 0.2rem;">
                        double_arrow
                    </mat-icon>
                    <div>
                        {{('segnalazioniParse.stato.' + activity.nuovoStato|translate)|notPresentInTraduction:activity.nuovoStato:'reportSegnalazioni.stato.'}}
                    </div>
                </div>
                <div *ngIf="visualizeObjectUser(activity)" class="flex-center-width100 color-primary">
                    {{getNameUser(activity.objectUser)}}
                </div>
                <div *ngIf="activity.comment">
                    {{activity.comment}}
                </div>
                <div *ngIf="activity.foto">
                    <a (click)="onOpenImage(activity)" style="cursor: pointer;">
                        <img src="{{activity.foto.url()}}" width="145px">
                    </a>
                </div>
                <div *ngIf="activity.allegato">
                    <a href="{{activity.allegato.url()}}" target="_blank"
                       style="cursor: pointer;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <img src="assets/pdf.png" height="40px">
                            <span style="margin-left: 0.5rem">{{getSplitNameObject(activity, 'name')}}</span>
                        </div>
                    </a>
                    <!--                                                        <a style="cursor: pointer;">-->
                    <!--                                                            Allegato-->
                    <!--&lt;!&ndash;                                                            <img src="{{activity.allegato.url}}" width="145px">&ndash;&gt;-->
                    <!--                                                        </a>-->
                </div>
                <div *ngIf="activity.schedaManutenzioneCompilata"
                     style="width: 100%">
                    <app-box-scheda-manutenzione-compilata
                            [notVisualized]="{createdAt:true,compiledBy:true}"
                            [schedaManutenzioneCompilata]="activity.schedaManutenzioneCompilata"
                            [selected]="[activity.schedaManutenzioneCompilata]"
                    >
                        <div badge *ngIf="showAlert(activity.schedaManutenzioneCompilata)">
                            <button mat-mini-fab color="warn"
                            >
                                <mat-icon>priority_high</mat-icon>
                            </button>
                        </div>
                    </app-box-scheda-manutenzione-compilata>
                </div>
            </div>

        </div>
    </div>
</div>
<ng-template #noActivity>
    <mat-card-subtitle style="margin-top: 0.5rem;">{{'no_activities' | translate}}
    </mat-card-subtitle>
</ng-template>
