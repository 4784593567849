import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {UserService} from "../services/user.service";
import {ProjectService} from "../services/project.service";
import {LocalSotrageService} from "../services/local-sotrage.service";

@Injectable({
    providedIn: 'root'
})
export class NotLoggedGuard implements CanActivate {

    constructor(
        private userService: UserService,
        private localStorage: LocalSotrageService,
        private router: Router
    ) {
    }


    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot):
        boolean {
        if (!this.userService.isLogged()) {
            this.router.navigate(['/auth/login']);
            return false;
        } else {
            return true;
        }
    }
}
