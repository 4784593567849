import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {PuntiLuceParse} from "../../../models/PuntiLuce.Parse";
import {CircuitiParse} from "../../../models/Circuiti.Parse";
import {ArredoUrbanoParse} from "../../../models/ArredoUrbano.Parse";
import {TranslateService} from "@ngx-translate/core";
import {FotoTipologiaType} from "../../../providers/services/foto-tipologia.service";
import {arrayIsSet, isNotNullOrUndefined} from "../../../models/Models";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {Observable, Subscription} from "rxjs";
import {filter, map, startWith} from "rxjs/operators";

@Component({
    selector: 'app-batch-editor-foto-tipologia',
    templateUrl: './batch-editor-foto-tipologia.component.html',
    styleUrls: ['./batch-editor-foto-tipologia.component.scss']
})
export class BatchEditorFotoTipologiaComponent implements OnInit, OnDestroy {

    public dataSource = [];
    public displayedColumns = ['circuito', 'indirizzo', 'targa', 'fotoTipologia'];
    disableSaveButton$: Observable<boolean>;
    breakPointFilterTable
    public form: UntypedFormGroup;
    numberClickRemoveFotoTipologia$: Observable<number>
    public limitClickRemove = 2;
    private subscription = new Subscription();

    constructor(public dialogRef: MatDialogRef<BatchEditorFotoTipologiaComponent>,
                @Inject(MAT_DIALOG_DATA) public data: {
                    lightPoints: PuntiLuceParse [],
                    fotoTipologia: FotoTipologiaType[]
                },
                private translateService: TranslateService,
                fb: UntypedFormBuilder) {
        this.dataSource = arrayIsSet(this.data.lightPoints) ? this.data.lightPoints : undefined
        this.form = fb.group({selected: undefined, numberClickRemoveFotoTipologia: 0});
        this.disableSaveButton$ = this.form.get('selected').valueChanges.pipe(
            startWith(undefined),
            map(selected => this.form.get('selected').value == null)
        )
        this.numberClickRemoveFotoTipologia$ = this.form.get('numberClickRemoveFotoTipologia').valueChanges.pipe(
            startWith(undefined),
            map(() => {
                    let value = this.form.get('numberClickRemoveFotoTipologia').value == null ? 0 : this.form.get('numberClickRemoveFotoTipologia').value;
                    return this.limitClickRemove - value;
                }
            )
        )
        this.subscription.add(
            this.form.get('selected').valueChanges.pipe(
                startWith(undefined),
            ).subscribe(() => {
                this.form.get('numberClickRemoveFotoTipologia').setValue(0);
            })
        )
    }

    ngOnInit(): void {

        setTimeout(() => {
            this.breakPointFilterTable = [
                {
                    maxWidth: 1000,
                    sx: {percent: 80, px: 470},
                    dx: {percent: undefined, px: undefined},
                }, {
                    maxWidth: 800,
                    sx: {percent: 50, px: undefined},
                    dx: {percent: 50, px: undefined}
                }, {
                    maxWidth: 600,
                    sx: {percent: 100, px: undefined},
                    dx: {percent: 100, px: undefined}
                }
            ];
        }, 200)
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    getValueCircuito(value) {
        const translate = this.translateService.instant('filterPage.nessunQuadro')
        return isNotNullOrUndefined(value) && isNotNullOrUndefined(value.numeroQuadro) ? value.numeroQuadro : translate;
    }

    getValueFototipologia(value) {
        return isNotNullOrUndefined(value) ? value.titolo : undefined;
    }

    getPathTraduction = 'dashboard_sidenav.PuntiLuce.';

    getValue(value, key) {
        const translate = this.translateService.instant('NaN');
        return isNotNullOrUndefined(value[key]) ? value[key] : translate;
    }

    saveValue() {
        this.dialogRef.close({selected: this.form.get('selected').value})
    }

    getTraductionRemoveButtonPredicate(numberlick: number, limit) {
        if (numberlick == null || numberlick == limit) {
            return 'fotoTipologia.removeFotoTipologia';
        } else {
            return 'fotoTipologia.areSureRemoveFotoTipologia';
        }
    }

    clickRemoeFotoTipologia() {
        let numberClick = this.form.get('numberClickRemoveFotoTipologia').value == null ? 0 : this.form.get('numberClickRemoveFotoTipologia').value;
        if (numberClick >= this.limitClickRemove) {
            this.dialogRef.close({removeFotoTipologia: true})
        } else {
            numberClick++;
        }
        this.form.get('numberClickRemoveFotoTipologia').setValue(numberClick);
    }
}
