import {Directive, ElementRef, Input, NgZone, OnInit, Renderer2} from '@angular/core';
import * as EXIF from 'exif-js/exif';

@Directive({
  selector: '[appAutorotate]'
})
export class AutorotateDirective implements OnInit {

  @Input() appAutorotate: string;

  constructor(
      private el: ElementRef,
      private renderer: Renderer2,
      private ngZone: NgZone
  ) {
  }

  ngOnInit(): void {
    this.onLoadImage();
  }


  public onLoadImage() {
    const {renderer, el, ngZone} = this;
    // https://github.com/exif-js/exif-js/issues/78
    // there is a bug in exif-js, at some point it reads a variable "n" that doesn't exist
    // causing an error in images with exif data.
    // the following line fixes the problem until the library gets fixed (but the last commit is from 2018 so it probably won't happen)
    globalThis.n = 0;
    // @ts-ignore
    EXIF.getData({src: this.appAutorotate}, function () {
      const allMetaData = EXIF.getAllTags(this);
      const orientation = allMetaData.Orientation;
      if(navigator.userAgent.includes('Firefox') || navigator.userAgent.includes('Chrome/80.0.3987.162')){
      ngZone.run(() => {
        switch (orientation) {
          case 2:
            renderer.setStyle(el.nativeElement, 'transform', '-flip horizontal');
            break;
          case 3:
            renderer.setStyle(el.nativeElement, 'transform', 'rotate(180deg)');
            break;
          case 4:
            renderer.setStyle(el.nativeElement, 'transform', '-flip vertical');
            break;
          case 5:
            renderer.setStyle(el.nativeElement, 'transform', '-transpose');
            break;
          case 6:
            renderer.setStyle(el.nativeElement, 'transform', 'rotate(90deg)');
            renderer.setStyle(el.nativeElement, 'margin', '2rem');
            break;
          case 7:
            renderer.setStyle(el.nativeElement, 'transform', '-transverse');
            break;
          case 8:
            renderer.setStyle(el.nativeElement, 'transform', 'rotate(270deg)');
            renderer.setStyle(el.nativeElement, 'margin', '2rem');
            break;
          case 1:
          default:
            break;
        }
        // renderer.setStyle(el.nativeElement, 'opacity', 1);
      });
    }
    });

  }


}
