import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {FormGroupTemplateComponent} from './form-group-template/form-group-template.component';
import {LoaderComponent} from './loader/loader.component';
import {ButtonComponent} from './button/button.component';
import {ChartJsComponent} from './chart-js/chart-js.component';
import {ContainerComponent} from './container/container.component';
import {HunaCardComponent} from './huna-card/huna-card.component';
import {ContainerChildComponent} from './container-child/container-child.component';
import {SnackbarForFullScreenComponent} from './snackbar-for-full-screen/snackbar-for-full-screen.component';
import {HunaSpinnerComponent} from './huna-spinner/huna-spinner.component';
import {ColorLampComponent} from './color-lamp/color-lamp.component';
import {ContainerA4Component} from './container-a4/container-a4.component';
import {TranslateModule} from '@ngx-translate/core';
import {PipesModule} from '../providers/pipes/pipes.module';
import {HunaPrintTableComponent} from './huna-print-table/huna-print-table.component';
import {ListIsEmptyComponent} from './list-is-empty/list-is-empty.component';
import {ButtonSpinnerComponent} from './button-spinner/button-spinner.component';
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import {ChipsFromArrayLabelComponent} from './chips-from-array-label/chips-from-array-label.component';
import {RangeSliderComponent} from './range-slider/range-slider.component';
import {MatLegacySliderModule as MatSliderModule} from "@angular/material/legacy-slider";
import {ReactiveFormsModule} from "@angular/forms";
import {MultiSelectComponent} from './multi-select/multi-select.component';
import {MatLegacyFormFieldModule as MatFormFieldModule} from "@angular/material/legacy-form-field";
import {MatLegacyInputModule as MatInputModule} from "@angular/material/legacy-input";
import {MatLegacyListModule as MatListModule} from "@angular/material/legacy-list";
import {SplitScreenComponent} from './split-screen/split-screen.component';
import {AutoCompleteFormComponent} from './auto-complete-form/auto-complete-form.component';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from "@angular/material/legacy-autocomplete";
import {VisulizedTypeFormComponent} from './visulized-type-form/visulized-type-form.component';
import {MatLegacySelectModule as MatSelectModule} from "@angular/material/legacy-select";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {DirectivesModule} from "../providers/directives/directives.module";
import {NgxDropzoneModule} from "ngx-dropzone";
import {MatLegacyCheckboxModule as MatCheckboxModule} from "@angular/material/legacy-checkbox";
import {MoveMountYearWidgetsComponent} from './move-mount-year-widgets/move-mount-year-widgets.component';
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {ScrollWithButtonComponent} from './scroll-with-button/scroll-with-button.component';
import {WidgetSelectArredoUrbanoComponent} from './widget-select-arredo-urbano/widget-select-arredo-urbano.component';
import {MatLegacyTooltipModule as MatTooltipModule} from "@angular/material/legacy-tooltip";
import {
    GetFormFielByConfigurationElementComponent
} from './get-form-fiel-by-configuration-element/get-form-fiel-by-configuration-element.component';
import {NgxMaterialTimepickerModule} from "ngx-material-timepicker";
import {MoveDateWithRangeComponent} from './move-date-with-range/move-date-with-range.component';
import {MatLegacyProgressBarModule as MatProgressBarModule} from "@angular/material/legacy-progress-bar";
import {RangeFormFieldComponent} from './range-form-field/range-form-field.component';
import {MultipleValueFreeTextComponent} from './multiple-value-free-text/multiple-value-free-text.component';
import {MatLegacyChipsModule as MatChipsModule} from "@angular/material/legacy-chips";
import {PinchToZoomComponent} from "./pinch-to-zoom/pinch-to-zoom.component";
import {MultiSelectFormFieldComponent} from './multi-select-form-field/multi-select-form-field.component';
import {GalleryComponent} from './gallery/gallery.component';
import {TruncateTextComponent} from './truncate-text/truncate-text.component';
import {
    ChipsMultiselectFormFieldComponent
} from './chips-multiselect-form-field/chips-multiselect-form-field.component';
import {MatLegacyMenuModule as MatMenuModule} from "@angular/material/legacy-menu";
import {DatepickerComponent} from './datepicker/datepicker.component';
import {OverlayModule} from '@angular/cdk/overlay';
import {LongListInfinityScrollComponent} from "./long-list-infinity-scroll/long-list-infinity-scroll.component";
import {LongListPaginateComponent} from './long-list-paginate/long-list-paginate.component';
import {MatRippleModule} from "@angular/material/core";
import {IncrementFieldComponent} from './increment-field/increment-field.component';
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import { SelectToolbarComponent } from './select-toolbar/select-toolbar.component';
import { FieldEditLightPointsComponent } from './field-edit-light-points/field-edit-light-points.component';
import {MatToolbarModule} from "@angular/material/toolbar";
import { FieldFormFotoTipologiaComponent } from './field-form-foto-tipologia/field-form-foto-tipologia.component';
import {BoxesModule} from "../boxes/boxes.module";
import {MatLegacyRadioModule as MatRadioModule} from "@angular/material/legacy-radio";
import { SelectMonthYearFieldComponent } from './select-month-year-field/select-month-year-field.component';
import { ButtonMultipleClickComponent } from './button-multiple-click/button-multiple-click.component';
import {MatBadgeModule} from "@angular/material/badge";


@NgModule({
    declarations: [
        LoaderComponent,
        FormGroupTemplateComponent,
        ButtonComponent,
        ChartJsComponent,
        ContainerComponent,
        HunaCardComponent,
        ContainerChildComponent,
        SnackbarForFullScreenComponent,
        HunaSpinnerComponent,
        ColorLampComponent,
        ContainerA4Component,
        HunaPrintTableComponent,
        ListIsEmptyComponent,
        ButtonSpinnerComponent,
        ChipsFromArrayLabelComponent,
        RangeSliderComponent,
        MultiSelectComponent,
        SplitScreenComponent,
        AutoCompleteFormComponent,
        VisulizedTypeFormComponent,
        MoveMountYearWidgetsComponent,
        ScrollWithButtonComponent,
        WidgetSelectArredoUrbanoComponent,
        GetFormFielByConfigurationElementComponent,
        MoveDateWithRangeComponent,
        RangeFormFieldComponent,
        MultipleValueFreeTextComponent,
        PinchToZoomComponent,
        MultiSelectFormFieldComponent,
        GalleryComponent,
        TruncateTextComponent,
        ChipsMultiselectFormFieldComponent,
        DatepickerComponent,
        LongListInfinityScrollComponent,
        LongListPaginateComponent,
        IncrementFieldComponent,
        SelectToolbarComponent,
        FieldEditLightPointsComponent,
        FieldFormFotoTipologiaComponent,
        SelectMonthYearFieldComponent,
        ButtonMultipleClickComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        MatIconModule,
        MatProgressSpinnerModule,
        TranslateModule,
        PipesModule,
        MatButtonModule,
        MatSliderModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatListModule,
        MatAutocompleteModule,
        MatSelectModule,
        MatDatepickerModule,
        DirectivesModule,
        NgxDropzoneModule,
        MatCheckboxModule,
        MatCardModule,
        MatTooltipModule,
        NgxMaterialTimepickerModule,
        MatProgressBarModule,
        MatChipsModule,
        MatMenuModule,
        OverlayModule,
        MatRippleModule,
        MatButtonToggleModule,
        MatToolbarModule,
        MatRadioModule,
        MatBadgeModule,
    ],
    exports: [
        LoaderComponent,
        FormGroupTemplateComponent,
        ButtonComponent,
        ChartJsComponent,
        ContainerComponent,
        HunaCardComponent,
        ContainerChildComponent,
        SnackbarForFullScreenComponent,
        HunaSpinnerComponent,
        ColorLampComponent,
        ContainerA4Component,
        HunaPrintTableComponent,
        ListIsEmptyComponent,
        ButtonSpinnerComponent,
        ChipsFromArrayLabelComponent,
        RangeSliderComponent,
        MultiSelectComponent,
        SplitScreenComponent,
        AutoCompleteFormComponent,
        VisulizedTypeFormComponent,
        MoveMountYearWidgetsComponent,
        ScrollWithButtonComponent,
        WidgetSelectArredoUrbanoComponent,
        GetFormFielByConfigurationElementComponent,
        MoveDateWithRangeComponent,
        RangeFormFieldComponent,
        MultipleValueFreeTextComponent,
        PinchToZoomComponent,
        MultiSelectFormFieldComponent,
        GalleryComponent,
        TruncateTextComponent,
        ChipsMultiselectFormFieldComponent,
        LongListInfinityScrollComponent,
        LongListPaginateComponent,
        IncrementFieldComponent,
        SelectToolbarComponent,
        FieldEditLightPointsComponent,
        FieldFormFotoTipologiaComponent,
        ButtonMultipleClickComponent
    ]
})
export class WidgetsModule {
}
