import * as Parse from 'parse';
import {allPropertyClass, isNotNullOrUndefined} from "./Models";

export class CircuitiParse extends Parse.Object {

    public static CLASS_NAME = 'Circuiti';

    constructor() {
        super(CircuitiParse.CLASS_NAME);
    }


    private _icon;
    public get icon() {
        return this._icon
    }

    public set icon(value) {
        this._icon = value;
    }

    private _labelMarker;
    public get labelMarker() {
        return this._labelMarker
    }

    public set labelMarker(value) {
        this._labelMarker = value;
    }

    private _segnalazioni;
    public get segnalazioni(): any[] {
        return this._segnalazioni
    }

    public set segnalazioni(value) {
        this._segnalazioni = value;
    }

    public set objectId(value) {
        this.id = value;
    }

    public get objectId() {
        return this.id;
    }

    public set numeroQuadro(value) {
        super.set('numeroQuadro', value);
    }

    public get numeroQuadro() {
        return super.get('numeroQuadro');
    }

    public set classeIsolamento(value) {
        super.set('classeIsolamento', value);
    }

    public get classeIsolamento() {
        return super.get('classeIsolamento');
    }


    public set costoAnnualeRiferimento(value) {
        super.set('costoAnnualeRiferimento', value);
    }

    public get costoAnnualeRiferimento() {
        return super.get('costoAnnualeRiferimento');
    }

    public set circuitoPadre(value) {
        super.set('circuitoPadre', value);
    }

    public get circuitoPadre() {
        return super.get('circuitoPadre');
    }

    public set circuitoAccorpamento(value) {
        super.set('circuitoAccorpamento', value);
    }

    public get circuitoAccorpamento() {
        return super.get('circuitoAccorpamento');
    }


    public set foto(value) {
        super.set('foto', value);
    }

    public get foto() {
        return super.get('foto');
    }

    public set progetto(value) {
        super.set('progetto', value);
    }

    public get progetto() {
        return super.get('progetto');
    }

    public set POD(value) {
        super.set('POD', value);
    }

    public get POD() {
        return super.get('POD');
    }

    public set indirizzoFornitura(value) {
        super.set('indirizzoFornitura', value);
    }

    public get indirizzoFornitura() {
        return super.get('indirizzoFornitura');
    }

    public set regolazioneFlusso(value) {
        super.set('regolazioneFlusso', value);
    }

    public get regolazioneFlusso() {
        return super.get('regolazioneFlusso');
    }

    public set potenzaImpegnata(value) {
        super.set('potenzaImpegnata', value);
    }

    public get potenzaImpegnata() {
        return super.get('potenzaImpegnata');
    }

    public set ACL(value) {
        super.set('ACL', value);
    }

    public get ACL() {
        return super.get('ACL');
    }

    public set numeroFasi(value) {
        super.set('numeroFasi', value);
    }

    public get numeroFasi() {
        return super.get('numeroFasi');
    }

    public set tipologiaAccensione(value) {
        super.set('tipologiaAccensione', value);
    }

    public get tipologiaAccensione() {
        return super.get('tipologiaAccensione');
    }

    public set statoDiConservazione(value) {
        super.set('statoDiConservazione', value);
    }

    public get statoDiConservazione() {
        return super.get('statoDiConservazione');
    }

    public set updatedAt(value) {
        super.set('updatedAt', value);
    }

    public get updatedAt() {
        return super.get('updatedAt');
    }

    public set numeroCircuitiInUscita(value) {
        super.set('numeroCircuitiInUscita', value);
    }

    public get numeroCircuitiInUscita() {
        return super.get('numeroCircuitiInUscita');
    }

    public set note(value) {
        super.set('note', value);
    }

    public get note() {
        return super.get('note');
    }

    public set gestoreSIM(value) {
        super.set('gestoreSIM', value);
    }

    public get gestoreSIM() {
        return super.get('gestoreSIM');
    }

    public set location(value) {
        super.set('location', value);
    }

    public get location() {
        return super.get('location');
    }

    public set protezioneGenerale(value) {
        super.set('protezioneGenerale', value);
    }

    public get protezioneGenerale() {
        return super.get('protezioneGenerale');
    }

    public set proprietaCabina(value) {
        super.set('proprietaCabina', value);
    }

    public get proprietaCabina() {
        return super.get('proprietaCabina');
    }

    public set lastUpdateBy(value) {
        super.set('lastUpdateBy', value);
    }

    public get lastUpdateBy() {
        return super.get('lastUpdateBy');
    }

    public set lotto(value) {
        super.set('lotto', value);
    }

    public get lotto() {
        return super.get('lotto');
    }

    public set fornitoreEnergia(value) {
        super.set('fornitoreEnergia', value);
    }

    public get fornitoreEnergia() {
        return super.get('fornitoreEnergia');
    }

    public set accensioneParzializzata(value) {
        super.set('accensioneParzializzata', value);
    }

    public get accensioneParzializzata() {
        return super.get('accensioneParzializzata');
    }

    public set createdAt(value) {
        super.set('createdAt', value);
    }

    public get createdAt() {
        return super.get('createdAt');
    }

    public set numeroSIM(value) {
        super.set('numeroSIM', value);
    }

    public get numeroSIM() {
        return super.get('numeroSIM');
    }

    public set numeroOreFlussoRidotto(value) {
        super.set('numeroOreFlussoRidotto', value);
    }

    public get numeroOreFlussoRidotto() {
        return super.get('numeroOreFlussoRidotto');
    }


    public set statoOperativo(value) {
        super.set('statoOperativo', value);
    }

    public get statoOperativo() {
        return super.get('statoOperativo');
    }

    public set tipologiaContrattoEnergia(value) {
        super.set('tipologiaContrattoEnergia', value);
    }

    public get tipologiaContrattoEnergia() {
        return super.get('tipologiaContrattoEnergia');
    }

    public set tipologiaContrattoGestione(value) {
        super.set('tipologiaContrattoGestione', value);
    }

    public get tipologiaContrattoGestione() {
        return super.get('tipologiaContrattoGestione');
    }

    public set costoAnnualeAttuale(value) {
        super.set('costoAnnualeAttuale', value);
    }

    public get costoAnnualeAttuale() {
        return super.get('costoAnnualeAttuale');
    }

    public set canReceiveAllarmiRemoti(value) {
        super.set('canReceiveAllarmiRemoti', value);
    }

    public get canReceiveAllarmiRemoti() {
        return super.get('canReceiveAllarmiRemoti');
    }

    public set nomeAffidatarioGestione(value) {
        super.set('nomeAffidatarioGestione', value);
    }

    public get nomeAffidatarioGestione() {
        return super.get('nomeAffidatarioGestione');
    }

    public set oreAnnualiAccensione(value) {
        super.set('oreAnnualiAccensione', value);
    }

    public get oreAnnualiAccensione() {
        return super.get('oreAnnualiAccensione');
    }


    public set valoreMedioParzializzazione(value) {
        super.set('valoreMedioParzializzazione', value);
    }

    public get valoreMedioParzializzazione() {
        return super.get('valoreMedioParzializzazione');
    }

    public set consumoAnnualeRiferimento(value) {
        super.set('consumoAnnualeRiferimento', value);
    }

    public get consumoAnnualeRiferimento() {
        return super.get('consumoAnnualeRiferimento');
    }

    public set valoreMedioRiduzionePotenza(value) {
        super.set('valoreMedioRiduzionePotenza', value);
    }

    public get valoreMedioRiduzionePotenza() {
        return super.get('valoreMedioRiduzionePotenza');
    }


    public set indirizzo(value) {
        super.set('indirizzo', value);
    }

    public get indirizzo() {
        return super.get('indirizzo');
    }

    public set tipologiaLineaAlimentazione(value) {
        super.set('tipologiaLineaAlimentazione', value);
    }

    public get tipologiaLineaAlimentazione() {
        return super.get('tipologiaLineaAlimentazione');
    }

    public set consumoAnnualeAttuale(value) {
        super.set('consumoAnnualeAttuale', value);
    }

    public get consumoAnnualeAttuale() {
        return super.get('consumoAnnualeAttuale');
    }


    public set numeroOreParzializzazione(value) {
        super.set('numeroOreParzializzazione', value);
    }

    public get numeroOreParzializzazione() {
        return super.get('numeroOreParzializzazione');
    }

    public set notaIndirizzo(value) {
        super.set('notaIndirizzo', value);
    }

    public get notaIndirizzo() {
        return super.get('notaIndirizzo');
    }

    public set tipologiaCaricoEsogeno(value) {
        super.set('tipologiaCaricoEsogeno', value);
    }

    public get tipologiaCaricoEsogeno() {
        return super.get('tipologiaCaricoEsogeno');
    }

    public set tipologiaTelecontrollo(value) {
        super.set('tipologiaTelecontrollo', value);
    }

    public get tipologiaTelecontrollo() {
        return super.get('tipologiaTelecontrollo');
    }


    public set tipologiaAccesso(value) {
        super.set('tipologiaAccesso', value);
    }

    public get tipologiaAccesso() {
        return super.get('tipologiaAccesso');
    }


    public set annoRiferimentoConsumi(value) {
        super.set('annoRiferimentoConsumi', value);
    }

    public get annoRiferimentoConsumi() {
        return super.get('annoRiferimentoConsumi');
    }


    public set fattoreDiPotenzaAttuale(value) {
        super.set('fattoreDiPotenzaAttuale', value);
    }

    public get fattoreDiPotenzaAttuale() {
        return super.get('fattoreDiPotenzaAttuale');
    }

    public set files(value) {
        super.set('files', value);
    }

    public get files(): Parse.Relation {
        const relation = this.relation('files')
        return relation;
    }


    public set tensioneNominale(value) {
        super.set('tensioneNominale', value);
    }

    public get tensioneNominale() {
        return super.get('tensioneNominale');
    }


    public set distributoreEnergia(value) {
        super.set('distributoreEnergia', value);
    }

    public get distributoreEnergia() {
        return super.get('distributoreEnergia');
    }

    public set schedeManutenzione(value) {
        super.set('schedeManutenzione', value);
    }

    public get schedeManutenzione(): any[] {
        return super.get('schedeManutenzione');
    }

    public hasProperty(key: string): boolean {
        return this.allProperty().includes(key);
    }

    public allProperty() {
        return allPropertyClass(CircuitiParse)
            .filter(key => key != 'query')
    }

    public allPropertyOnParse() {
        const notInParse = ['constructor', 'includesInArray', 'hasProperty', 'labelMarker', 'icon', 'allPropertyOnParse', 'allProperty', 'query'];
        return this.allProperty().filter(key => !notInParse.includes(key))
    }


    public includesInArray(circuiti: any [], key = null): number {
        const index: number = circuiti.findIndex(
            circuito => {
                if (!!key) {
                    return isNotNullOrUndefined(circuito[key]) && circuito[key].id == this.id
                } else {
                    return circuito.id == this.id;
                }
            }
        );
        return index;
    }

    public get query(): Parse.Query {
        return new Parse.Query(CircuitiParse)
    }



}

Parse.Object.registerSubclass(CircuitiParse.CLASS_NAME, CircuitiParse);
