import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {FotometriaParse} from "../../../models/Fotometria.Parse";
import {ChipsFromArrayService} from "../../../providers/services/chips-from-array.service";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {UtilsService} from "../../../providers/services/utils.service";
import {forkJoin, Observable, of, Subscription} from "rxjs";
import {MatSort} from "@angular/material/sort";
import {CdkDragDrop, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {GruppoFotometrieParse} from "../../../models/GruppoFotometrie.Parse";
import {TranslateService} from "@ngx-translate/core";
import {arrayIsSet, hunaSortNumericString, isNotNullOrUndefined, stringIsSet} from "../../../models/Models";
import {dataForm} from "../../confirm-delete/select-or-create/select-or-create.component";
import {UserService} from "../../../providers/services/user.service";
import {FotometrieUtilsService} from "../../../providers/services/fotometrie-utils.service";
import {map} from "rxjs/operators";
import {AlertService} from "../../../providers/services/alert.service";
import { Photometry } from 'src/app/providers/services/streets.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
    selector: 'app-create-update-photometry',
    templateUrl: './create-update-photometry.component.html',
    styleUrls: ['./create-update-photometry.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class CreateUpdatePhotometryComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('filteredPhotometriesWrapper') filteredPhotometriesWrapperRef: CdkVirtualScrollViewport;
    @ViewChild('selectedPhotometriesWrapper') selectedPhotometriesWrapperRef: ElementRef<HTMLElement>;
    public distinctValues;
    public produttoreChipsLabel;
    public itemsProduttore;
    public filteredChipsPhotometries: any[] = [];
    public filteredPhotometries: any[] = [];
    public selectedPhotometries: any[] = [];
    public chipsSelected = {};
    public chipsSelectedProduttore = [];
    public chipsSelectedTypePhotometry = [];
    public labelsChips;
    private labelsRange;
    public labelsText;
    public formFilter: UntypedFormGroup;
    public formListFotometrie: UntypedFormGroup;
    public formTextFotometrie: UntypedFormGroup;
    public minMaxValue: any = {};
    private lastValueMinMax: any = {};
    private lastValueText = {};

    public formAutoCompleteFotometrie: UntypedFormGroup;

    private privatePhotometry: FotometriaParse[] = [];
    private publicPhotometry: FotometriaParse[] = [];
    private sharedPhotometry: FotometriaParse[] = [];
    private previousElementId;


    items = Array.from({length: 100000}).map((_, i) => `Item #${i}`);

    get allKeyToCreateChips() {
        return this.fotometrieUtilsService.keysChips;
    }

    get orderLabelChips() {
        return this.fotometrieUtilsService.orderLabelChips;
    }

    public loading = {
        fotometrieSelezionate: false,
        fotometrieNonSelezionate: false
    }

    private subscriptions: Subscription[] = [];
    @ViewChild(MatSort) sort: MatSort;

    ngAfterViewInit() {
    }


    constructor(
        public dialogRef: MatDialogRef<CreateUpdatePhotometryComponent>,
                @Inject(MAT_DIALOG_DATA)
                public data: { photometries: FotometriaParse[], groupPhotometry: GruppoFotometrieParse },
                private chipsFromArrayService: ChipsFromArrayService,
                private fb: UntypedFormBuilder,
                private utilityService: UtilsService,
                private translateService: TranslateService,
                private userService: UserService,
                private fotometrieUtilsService: FotometrieUtilsService,
                private alertService: AlertService
    ) {
        this.formListFotometrie = this.fb.group({list: []})
        const labelsText = ['nomeFile'];
        let objForm = {};
        labelsText.forEach(label => objForm[label] = '')
        this.labelsText = labelsText;
        this.formTextFotometrie = this.fb.group(objForm);
        objForm = {};
        objForm['produttore'] = '';
        this.formAutoCompleteFotometrie = this.fb.group(objForm);

    }


    optSel(event, label: string) {
        this.clickChipProduttore(label, {value: this.formAutoCompleteFotometrie.get(label).value})
        this.formAutoCompleteFotometrie.get(label).reset();
    }

    getItemsProduttore(values: string[]) {
        const itemsProduttore = [];
        values.forEach((value, index) => {
            if (stringIsSet(value) || typeof value == 'number') {
                const obj: dataForm = {
                    valueForm: (new Date().getTime() + index).toString(32),
                    html: value
                };
                itemsProduttore.push(obj)
            }
        })
        itemsProduttore.sort((a, b) => {
            return hunaSortNumericString(a.html, b.html, this.translateService.currentLang)
        })
        return itemsProduttore;
    }


    isSetMinMax(key) {
        return isNotNullOrUndefined(this.minMaxValue) && isNotNullOrUndefined(this.minMaxValue[key])
    }

    get allFotometrie(): any[] {
        const keys = new FotometriaParse().allPropertyOnParse();
        return this.privatePhotometry.concat(this.publicPhotometry).concat(this.sharedPhotometry).map(fotometria => {
            const objNonParse = {}
            keys.forEach(key => {
                objNonParse[key] = fotometria[key]
            })
            return objNonParse;
        }).filter(fotometria => {
            const isSelected = this.selectedPhotometries.findIndex(selezionata => selezionata.objectId == (fotometria as any).objectId) >= 0;
            return !isSelected
        })
    }

    get allFotometrieFilteredProduttore(): any[] {
        const keys = new FotometriaParse().allPropertyOnParse();
        return this.photometriesFilteredByTypePhotometry.map(fotometria => {
            const objNonParse = {}
            keys.forEach(key => {
                objNonParse[key] = fotometria[key]
            })
            return objNonParse;
        }).filter(fotometria => {
            const isSelected = this.selectedPhotometries.findIndex(selezionata => selezionata.objectId == (fotometria as any).objectId) >= 0;
            const isPresentProduttore = this.chipsSelectedProduttore.findIndex(produttore => {
                return this.chipsFromArrayService.isEquals(produttore, (fotometria as any).produttore)
            }) >= 0;
            return !isSelected && isPresentProduttore
        })
    }

    ngOnInit(): void {
        this.loading.fotometrieNonSelezionate = true;
        this.loading.fotometrieSelezionate = true;

        this.chipsSelectedTypePhotometry = ['private'];
        this.changeTypePhotometryVisualized(this.chipsSelectedTypePhotometry).subscribe(
            fotometriePublicPrivateShared => {
                fotometriePublicPrivateShared.forEach(
                    fotometrie => {
                        this.updatePhotometry(fotometrie);
                    }
                )
                this.produttoreChipsLabel = this.chipsFromArrayService.getDistinctValues(this.allFotometrie, ['produttore'])
                this.itemsProduttore = this.getItemsProduttore(this.produttoreChipsLabel.produttore)
                setTimeout(async () => {
                    this.filteredChipsPhotometries = this.allFotometrie;
                    this.loading.fotometrieNonSelezionate = false;
                    if (isNotNullOrUndefined(this.data.groupPhotometry) && Array.isArray(this.data.groupPhotometry.fotometrieScaricate)) {
                        const keys = new FotometriaParse().allPropertyOnParse();
                        this.selectedPhotometries = this.data.groupPhotometry.fotometrieScaricate.map(fotometria => {
                            const objNonParse = {}
                            keys.forEach(key => {
                                objNonParse[key] = fotometria[key]
                            })
                            const index = this.filteredChipsPhotometries.findIndex(fotometriaFiltrata => fotometriaFiltrata.objectId == fotometria.objectId);
                            if (index >= 0) {
                                this.filteredChipsPhotometries.splice(index, 1);
                            }
                            return objNonParse
                        });
                    } else if (isNotNullOrUndefined(this.data.groupPhotometry) && isNotNullOrUndefined(this.data.groupPhotometry.fotometrie)) {
                        const queryFotometrie = this.data.groupPhotometry.fotometrie.query()
                        queryFotometrie.limit(5000)
                        this.data.groupPhotometry.fotometrieScaricate = await queryFotometrie.find();
                        const keys = new FotometriaParse().allPropertyOnParse();
                        this.selectedPhotometries = this.data.groupPhotometry.fotometrieScaricate.map(fotometria => {
                            const objNonParse = {}
                            keys.forEach(key => {
                                objNonParse[key] = fotometria[key]
                            })
                            const index = this.filteredChipsPhotometries.findIndex(fotometriaFiltrata => fotometriaFiltrata.objectId == fotometria.objectId);
                            if (index >= 0) {
                                this.filteredChipsPhotometries.splice(index, 1);
                            }
                            return objNonParse
                        });
                    }
                    this.filteredPhotometries = this.photometriesFilteredByTypePhotometry;
                    this.loading.fotometrieSelezionate = false;
                }, 200)
            },
            error => {
                this.alertService.error(error)
            }
        )


        const labelsNumberType = ['flussoMax', 'flussoMin', 'potenzaMax', 'potenzaMin', 'efficienzaMax', 'efficienzaMin'];
        const objForm = {};
        labelsNumberType.forEach(label => this.minMaxValue[label] = {
            min: undefined,
            max: undefined,
            options: undefined
        })
        this.allFotometrie.forEach(
            fotometria => {
                labelsNumberType.forEach(label => {
                    if (isNotNullOrUndefined(fotometria[label]) && (!isNotNullOrUndefined(this.minMaxValue[label].min) || this.minMaxValue[label].min > fotometria[label])) {
                        this.minMaxValue[label].min = fotometria[label]
                    }
                    if (isNotNullOrUndefined(fotometria[label]) && (!isNotNullOrUndefined(this.minMaxValue[label].max) || this.minMaxValue[label].max < fotometria[label])) {
                        this.minMaxValue[label].max = fotometria[label]
                    }
                })
            }
        )
        // this.labelsRange = Object.keys(this.minMaxValue);
        // this.labelsRange.forEach(key => {
        //     objForm[key] = this.fb.group({min: this.minMaxValue[key].min, max: this.minMaxValue[key].max});
        //     this.lastValueMinMax[key] = {min: this.minMaxValue[key].min, max: this.minMaxValue[key].max};
        //     const step = Math.round((this.minMaxValue[key].max - this.minMaxValue[key].min) / 30)
        //     const options: Options = {
        //         floor: this.minMaxValue[key].min,
        //         ceil: this.minMaxValue[key].max,
        //         step: step,
        //         translate: (value: number, label: LabelType): string => {
        //             return this.utilityService.transformNumberToK(value);
        //         }
        //     };
        //     this.minMaxValue[key].options = options;
        // })
        this.formFilter = this.fb.group(objForm);
        let subscription = this.formFilter.valueChanges.subscribe(
            value => {
                this.applyFilterNumber();
                this.labelsText.forEach(label => {
                    this.filteredPhotometries = this.applyFilterText(label, this.filteredPhotometries)
                });
            }
        )

        this.subscriptions.push(subscription);
        this.labelsText.forEach(label => {
            this.lastValueText[label] = undefined;
            const subscription = this.formTextFotometrie.get(label).valueChanges.pipe(
            ).subscribe(
                value => {
                    setTimeout(() => {
                        if (this.lastValueText[label] == value) {
                            this.applyFilterNumber();
                            this.filteredPhotometries = this.applyFilterText(label, this.filteredPhotometries);
                        }
                    }, 500);
                    this.lastValueText[label] = value
                }
            )
            this.subscriptions.push(subscription);
        })
    }


    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }


    isBeetweenInFilter(value: number, key: string) {
        const max = this.formFilter.get(key).get('max').value
        const min = this.formFilter.get(key).get('min').value
        return value <= max && value >= min
    }

    getchipsSelected(key) {
        return (Array.isArray(this.chipsSelected[key])) ? this.chipsSelected[key] : []
    }

    applyFilter() {
        const isNotEmpty = Object.values(this.chipsSelected).findIndex(value => Array.isArray(value) && value.length > 0) >= 0;
        if (isNotEmpty) {
            this.filteredChipsPhotometries = this.allFotometrieFilteredProduttore.filter(fotometria => {
                const isPresentProduttore = this.chipsSelectedProduttore.findIndex(produttore => {
                    return this.chipsFromArrayService.isEquals(produttore, fotometria.produttore)
                }) >= 0;
                let isPresentInLabel = true;
                if (isPresentProduttore) {
                    this.labelsChips.forEach(label => {
                        let index = 1;
                        if (this.chipsSelected[label].length > 0) {
                            index = this.chipsSelected[label].findIndex(valueStored => {
                                return this.chipsFromArrayService.isEquals(valueStored, fotometria[label])
                            })
                        }
                        if (index < 0) {
                            isPresentInLabel = false;
                        }
                    })
                }
                return isPresentInLabel && isPresentProduttore;
            })
        } else {
            this.filteredChipsPhotometries = this.allFotometrieFilteredProduttore
        }
        // this.filteredPhotometries = this.filteredChipsPhotometries;
        this.applyFilterNumber();
        this.labelsText.forEach(label => {
            this.filteredPhotometries = this.applyFilterText(label, this.filteredPhotometries)
        });
    }

    applyFilterNumber() {
        // this.filteredPhotometries = this.filteredChipsPhotometries.filter((fotometria: FotometriaParse) => {
        //     const compresoNeiRange = this.labelsRange.findIndex(label => {
        //         return !this.isBeetweenInFilter(fotometria[label], label)
        //     }) < 0;
        //     return compresoNeiRange;
        // })
        this.filteredPhotometries = this.filteredChipsPhotometries;
    }

    applyFilterText(key: string, photometries: FotometriaParse[]) {
        const value = this.formTextFotometrie.get(key).value
        if (isNotNullOrUndefined(value) && typeof value == "string" && value.trim().length > 0) {
            if (key === 'nomeLampada' || key == 'nomeFile') {
                return photometries.filter((fotometria: FotometriaParse) => {
                    const containedInNomeLampada = (fotometria.nomeLampada) ? this.chipsFromArrayService.isIncludes(fotometria.nomeLampada, value) : false;
                    const containedInNomeFile = (fotometria.nomeFile) ? this.chipsFromArrayService.isIncludes(fotometria.nomeFile, value) : false;
                    return containedInNomeFile || containedInNomeLampada;
                })
            }
        } else {
            return photometries
        }
    }

    clickChipTypePhotometry(chipName: 'private' | 'public' | 'shared') {
        const index = this.chipsSelectedTypePhotometry.indexOf(chipName)
        if (index < 0) {
            this.chipsSelectedTypePhotometry.push(chipName);
        } else if (this.chipsSelectedTypePhotometry.length > 1) {
            this.chipsSelectedTypePhotometry.splice(index, 1);
        }
        this.changeTypePhotometryVisualized(this.chipsSelectedTypePhotometry).subscribe(
            fotometriePublicPrivateShared => {
                fotometriePublicPrivateShared.forEach(
                    fotometrie => {
                        this.updatePhotometry(fotometrie);
                        this.produttoreChipsLabel = this.chipsFromArrayService.getDistinctValues(this.photometriesFilteredByTypePhotometry, ['produttore'])
                        this.chipsSelectedProduttore = this.chipsSelectedProduttore.filter(
                            name => this.produttoreChipsLabel.produttore.includes(name)
                        )
                        this.itemsProduttore = this.getItemsProduttore(this.produttoreChipsLabel.produttore)
                        this.filteredPhotometries = this.photometriesFilteredByTypePhotometry;
                        this.updateFilterProduttore();
                    }
                )
            },
            error => {
                this.alertService.error(error)
            }
        )
    }


    updatePhotometry(fotometrie: { key: string, fotometrie: FotometriaParse[] }) {
        if (fotometrie.key == 'private') {
            this.privatePhotometry = fotometrie.fotometrie;
        } else if (fotometrie.key == 'public') {
            this.publicPhotometry = fotometrie.fotometrie;
        } else if (fotometrie.key == 'shared') {
            this.sharedPhotometry = fotometrie.fotometrie;
        }
    }


    changeTypePhotometryVisualized(chipsSelectedTypePhotometry: string[]): Observable<{ fotometrie: FotometriaParse[]; key: string }[]> {
        const observables = []
        chipsSelectedTypePhotometry.forEach(
            key => {
                if (key == 'private') {
                    let observable: Observable<{ fotometrie: FotometriaParse[]; key: string }>;
                    if (arrayIsSet(this.privatePhotometry)) {
                        observable = of(this.privatePhotometry).pipe(
                            map((fotometrie => {
                                    return {fotometrie, key}
                                })
                            )
                        )
                    } else {
                        observable = this.fotometrieUtilsService.getFotometrieByUser()
                            .pipe(
                                map((fotometrie => {
                                        return {fotometrie, key}
                                    })
                                )
                            )
                    }
                    observables.push(observable)
                } else if (key == 'public') {
                    let observable: Observable<{ fotometrie: FotometriaParse[]; key: string }>;
                    if (arrayIsSet(this.publicPhotometry)) {
                        observable = of(this.publicPhotometry).pipe(
                            map((fotometrie => {
                                    return {fotometrie, key}
                                })
                            )
                        )
                    } else {
                        observable = this.fotometrieUtilsService.getPublicFotometrie()
                            .pipe(
                                map((fotometrie => {
                                        return {fotometrie, key}
                                    })
                                )
                            )
                    }
                    observables.push(observable)
                } else if (key == 'shared') {
                    let observable: Observable<{ fotometrie: FotometriaParse[]; key: string }>;
                    if (arrayIsSet(this.sharedPhotometry)) {
                        observable = of(this.sharedPhotometry).pipe(
                            map((fotometrie => {
                                    return {fotometrie, key}
                                })
                            )
                        )
                    } else {
                        observable = this.fotometrieUtilsService.getSharedFotometrie()
                            .pipe(
                                map((fotometrie => {
                                        return {fotometrie, key}
                                    })
                                )
                            )
                    }
                    observables.push(observable);
                }
            }
        )
        return forkJoin(observables) as Observable<{ fotometrie: FotometriaParse[]; key: string }[]>
    }

    get photometriesFilteredByTypePhotometry() {
        const fotometrie = [];
        this.chipsSelectedTypePhotometry.forEach(key => {
            if (key == 'private') {
                fotometrie.push(...this.privatePhotometry);
            } else if (key == 'public') {
                fotometrie.push(...this.publicPhotometry);
            } else if (key == 'shared') {
                fotometrie.push(...this.sharedPhotometry);
            }
        })
        return fotometrie
    }

    clickChipProduttore(label, chipName: { value }) {
        const index = this.chipsSelectedProduttore.findIndex(valueStored => {
            return this.chipsFromArrayService.isEquals(valueStored, chipName.value)
        })
        if (index < 0) {
            this.chipsSelectedProduttore.push(chipName.value)
        } else {
            this.chipsSelectedProduttore.splice(index, 1);
        }
        this.updateFilterProduttore();
    }

    updateDistinctValues(fotometrie) {
        const labelsChip = (arrayIsSet(this.chipsSelectedProduttore)) ? this.allKeyToCreateChips : [];
        const distinctValue = this.fotometrieUtilsService.getDistinctValueWithOrder(fotometrie, this.chipsSelected, this.allKeyToCreateChips, this.orderLabelChips, labelsChip, this.labelsText, this.lastValueText, this.chipsFromArrayService)
        this.distinctValues = distinctValue;
        // this.distinctValues = this.chipsFromArrayService.getDistinctValues(fotometrie, labelsChip)

        this.labelsChips = Object.keys(this.distinctValues).sort((a, b) => this.fotometrieUtilsService.sortFromArray(a, b, this.orderLabelChips));
        this.labelsChips.forEach((key) => {
            this.distinctValues[key] = this.distinctValues[key].filter(value => stringIsSet(value) || typeof value == "number")
        })
        this.labelsChips.sort((key) => {
            this.distinctValues[key]
                .sort((a, b) => hunaSortNumericString(a, b, this.translateService.currentLang))
        })
    }

    updateFilterProduttore() {
        const fotometrie = this.allFotometrieFilteredProduttore;
        if (fotometrie.length > 0) {
            this.updateDistinctValues(fotometrie);
            this.filteredChipsPhotometries = fotometrie;
            this.labelsChips.forEach(key => {
                this.chipsSelected[key] = []
            })
        } else {
            this.filteredChipsPhotometries = this.photometriesFilteredByTypePhotometry;
            this.distinctValues = {}
            this.labelsChips = Object.keys(this.distinctValues).sort((a, b) => this.fotometrieUtilsService.sortFromArray(a, b, this.orderLabelChips));
        }
        this.applyFilterNumber();
        this.labelsText.forEach(label => {
            this.filteredPhotometries = this.applyFilterText(label, this.filteredPhotometries)
        });
    }

    clickChip(label, chipName: { value }) {
        const index = this.chipsSelected[label].findIndex(valueStored => {
            return this.chipsFromArrayService.isEquals(valueStored, chipName.value)
        })
        if (index < 0) {
            this.chipsSelected[label].push(chipName.value)
        } else {
            this.chipsSelected[label].splice(index, 1);
        }
        this.applyFilter();
        this.updateDistinctValues(this.allFotometrieFilteredProduttore);
    }


    valueChange(key, minMax: 'min' | 'max', value) {
        const currentValue = this.formFilter.get(key).get(minMax).value;
        setTimeout(() => {
            if (this.lastValueMinMax[key][minMax] == value && currentValue != value) {
                this.formFilter.get(key).get(minMax).setValue(value);
            }
        }, 1500);
        this.lastValueMinMax[key][minMax] = value;
    }

    dragReleased(e) {
        this.previousElementId = e.source.getPlaceholderElement()?.previousElementSibling?.getAttribute('data-objectId');
    }

    sortFn() {
        return 0;
    }

    drop(event: CdkDragDrop<never, never, FotometriaParse>) {
        // There are several problems with the values of previousIndex and currentIndex contained in the event
        // https://github.com/angular/components/issues/18947
        // https://github.com/angular/components/issues/14280
        // https://github.com/angular/components/issues/15880
        // (and maybe more...)
        // I'm not sure which one was the cause of the buggy behavior in this context, but we're trying to use
        // drag & drop in a Dialog with one of the two lists being a virtual list, so it's probably a combination
        // of several bugs. Before this fix, sometimes the element the user was dragging and the element that
        // actually got dragged weren't the same, making the drag & drop unusable.
        // The indexes returned by the event are therefore ignored and they're recomputed manually using
        // the dragRelease listener to retrieve the objectId of the element right before the placeholder
        // (which is the element added by the drag & drop to show the destination of the item that is being moved)
        // The placeholder is in a different position when sorting items within the same list, so the
        // feature has been completely disabled (it was already partially disabled and the purpose of this drag &
        // drop is to allow the user to move elements between the two lists).
        // When dropping an element, the interface doesn't always allow the user to pick the exact index where to
        // put the element; this is probably related to one of the bugs linked above (or another bug entirely...),
        // but it's out of our control. If the order is important and the interface isn't cooperating,
        // the user can click on the button to move the elements.
        const filteredElementsRef = this.filteredPhotometriesWrapperRef.elementRef.nativeElement;
        const selectedElementsRef = this.selectedPhotometriesWrapperRef.nativeElement;
        let fromRef: HTMLElement;
        let toRef: HTMLElement;
        let fromArray;
        let toArray;
        if (event.previousContainer.element.nativeElement === filteredElementsRef) {
            fromRef = filteredElementsRef;
            fromArray = this.filteredPhotometries;
        } else {
            fromRef = selectedElementsRef;
            fromArray = this.selectedPhotometries;
        }
        if (event.container.element.nativeElement === filteredElementsRef) {
            toRef = filteredElementsRef;
            toArray = this.filteredPhotometries;
        } else {
            toRef = selectedElementsRef;
            toArray = this.selectedPhotometries;
        }
        const fromIndex = fromArray.findIndex((d) => d.objectId === event.item.data.objectId);
        let toIndex = (this.previousElementId ? toArray.findIndex((d) => d.objectId === this.previousElementId) : -1) + 1;
        if (event.previousContainer !== event.container) {
            transferArrayItem(fromArray,
                toArray,
                fromIndex,
                toIndex);
            this.filteredPhotometries = [...this.filteredPhotometries];
            this.selectedPhotometries = [...this.selectedPhotometries];
        }
        this.previousElementId = null;
    }

    get resultFilter() {
        let removePhotometry = [];
        let selectedPhotometry = [];
        if (Array.isArray(this.data.groupPhotometry.fotometrieScaricate)) {
            removePhotometry = this.data.groupPhotometry.fotometrieScaricate
                .filter(fotometria => {
                    return this.selectedPhotometries.findIndex(fotometriaSelezionata => {
                        return fotometriaSelezionata.objectId == fotometria.objectId
                    }) < 0
                })
                .map(
                    fotometria => {
                        const fotometriaParse = new FotometriaParse();
                        fotometriaParse.objectId = fotometria.objectId;
                        return fotometriaParse;
                    }
                )
        }
        if (Array.isArray(this.selectedPhotometries)) {
            selectedPhotometry = this.selectedPhotometries
                .map(
                    fotometria => {
                        const fotometriaParse = new FotometriaParse();
                        fotometriaParse.objectId = fotometria.objectId;
                        return fotometriaParse;
                    }
                )
        }
        return {
            groupFotometry: this.data.groupPhotometry,
            addPhotometry: selectedPhotometry,
            removePhotometry: removePhotometry
        }
    }

    closeDialog(emitValue) {
        if (emitValue) {
            this.dialogRef.close(this.resultFilter)
        } else {
            this.dialogRef.close(undefined)
        }
    }


    isSelected(fotometria) {
        const index = this.selectedPhotometries.findIndex(fotometriaSelezionata => fotometriaSelezionata.objectId == fotometria.objectId)
        return index >= 0;
    }

    moveElement(element: FotometriaParse, index: number, type: 'moveToSelected' | 'moveToFiltered') {
        if (type === 'moveToSelected') {
            // const destinationIndex = (index > this.selectedPhotometries.length) ? this.selectedPhotometries.length : index
            transferArrayItem(this.filteredPhotometries,
                this.selectedPhotometries,
                index,
                0);
            this.filteredPhotometries = [...this.filteredPhotometries];
            this.selectedPhotometries = [...this.selectedPhotometries];
        } else {
            // const destinationIndex = (index > this.filteredPhotometries.length) ? this.filteredPhotometries.length : index
            transferArrayItem(this.selectedPhotometries,
                this.filteredPhotometries,
                index,
                0);
            this.filteredPhotometries = [...this.filteredPhotometries];
            this.selectedPhotometries = [...this.selectedPhotometries];
        }

    }

    massiveSelect() {
        this.loading.fotometrieNonSelezionate = true;
        setTimeout(() => {
            this.filteredPhotometries.forEach(
                fotometria => {
                    const index = this.selectedPhotometries.findIndex(selezionata => selezionata.objectId == fotometria.objectId)
                    if (index < 0) {
                        this.selectedPhotometries.push(fotometria);
                    }
                }
            )
            this.filteredPhotometries = []
            this.loading.fotometrieNonSelezionate = false;
        }, 100)

    }

    massiveDeSelect() {
        this.loading.fotometrieSelezionate = true;
        setTimeout(() => {
            this.filteredPhotometries = this.selectedPhotometries.concat(this.filteredPhotometries)
            this.selectedPhotometries = []
            this.loading.fotometrieSelezionate = false;
        }, 100);

    }

    photometryTracker(_index: number, photometry: Photometry) {
        return photometry.objectId;
    }
}
