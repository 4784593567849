import {Component, Inject, OnInit} from '@angular/core';
import {CircuitiParse} from "../../../models/Circuiti.Parse";
import {PuntiLuceParse} from "../../../models/PuntiLuce.Parse";
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {DocumentsFileParse} from "../../../models/DocumentsFile.Parse";
import {UtilsService} from "../../../providers/services/utils.service";
import {TranslateService} from "@ngx-translate/core";
import {ArredoUrbanoParse} from "../../../models/ArredoUrbano.Parse";
import {isNotNullOrUndefined} from "../../../models/Models";

@Component({
    selector: 'app-batch-editor-files',
    templateUrl: './batch-editor-files.component.html',
    styleUrls: ['./batch-editor-files.component.scss']
})
export class BatchEditorFilesComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<BatchEditorFilesComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { lightPoints: PuntiLuceParse [], circuits: CircuitiParse[], arrediUrbani: ArredoUrbanoParse[] },
        private translateService: TranslateService,
        private utils: UtilsService) {
    }


    public displayedColumns = [];
    public isPossibleValues = [];
    public dataSource = [];
    public connectedFiles = [];
    public newFiles = [];

    private initObjNewFiles = 'newfile'

    ngOnInit(): void {
        if (this.isLightPoints) {
            this.displayedColumns = ['circuito', 'indirizzo', 'targa'];
            this.dataSource = this.lightPoints;
        } else if (this.isCircuits) {
            this.displayedColumns = ['numeroQuadro', 'indirizzo', 'POD'];
            this.dataSource = this.circuits;
        } else {
            this.displayedColumns = ['tipologia'];
            this.isPossibleValues = ['tipologia'];
            this.dataSource = this.arrediUrbani;
        }
    }

    getPathTraductionWithKey(key) {
        if (this.isLightPoints) {
            return 'dashboard_sidenav.PuntiLuce.' + key + '.title'
        } else if (this.isCircuits) {
            return 'dashboard_sidenav.Circuiti.' + key + '.title'
        } else {
            return 'dashboard_sidenav.ArredoUrbano.' + key + '.title'
        }
    }

    getPathTraductionWithClassName() {
        if (this.isLightPoints) {
            return 'dashboard_sidenav.PuntiLuce.'
        } else if (this.isCircuits) {
            return 'dashboard_sidenav.Circuiti.'
        } else {
            return 'dashboard_sidenav.ArredoUrbano.'
        }
    }

//
    get isLightPoints() {
        return isNotNullOrUndefined(this.data.lightPoints)
    }

    get lightPoints(): PuntiLuceParse[] {
        return this.data.lightPoints
    }


    get isCircuits() {
        return isNotNullOrUndefined(this.data.circuits)
    }

    get circuits(): CircuitiParse[] {
        return this.data.circuits;
    }

    get isArredoUrbano() {
        return isNotNullOrUndefined(this.data.arrediUrbani)
    }

    get arrediUrbani(): ArredoUrbanoParse[] {
        return this.data.arrediUrbani;
    }

    get isSetDataSource() {
        return Array.isArray(this.dataSource) && this.dataSource.length > 0
    }

//
    refreshButton() {

    }

//
    searchButton(event) {
        if (event && Array.isArray(event.files)) {
            event.files.forEach(file => {
                const index = this.connectedFiles.findIndex(sotredFiles => {
                    return sotredFiles.objectId == file.objectId
                })
                if (index < 0) {
                    this.connectedFiles.push(file);
                }
            })
        }
    }

//
    addFiles(event) {
        if (event && Array.isArray(event)) {
            event.forEach(file => {
                const index = this.newFiles.findIndex(sotredFiles => {
                    return sotredFiles.name == file.name
                })
                if (index < 0) {
                    const newDate = new Date()
                    const newFile = new DocumentsFileParse();
                    newFile.objectId = this.initObjNewFiles + newDate.getTime().toString(32);
                    newFile.parentFolder = undefined;
                    newFile.name = file.name;
                    newFile.file = file;
                    newFile.updatedAt = newDate;
                    newFile.extension = this.utils.getExtensionFileFromFileName(file.name)
                    this.newFiles.push(newFile);
                }
            })
        }
    }


    get allConnectedFiles() {
        return this.connectedFiles.concat(this.newFiles);
    }

    get closedValue() {
        return {
            newFilesToConnect: this.newFiles.map(file => {
                return file.file;
            }),
            existedFilesToConnect: this.connectedFiles
        }
    }

    closeDialog(confirm) {
        if (confirm) {
            this.dialogRef.close(this.closedValue)
        } else {
            this.dialogRef.close(undefined)
        }
    }

    getValueCircuito(value) {
        const translate = this.translateService.instant('filterPage.nessunQuadro')
        return isNotNullOrUndefined(value) && isNotNullOrUndefined(value.numeroQuadro) ? value.numeroQuadro : translate;
    }

    getValue(value, key) {
        if (this.isPossibleValues.includes(key)) {
            const translate = this.translateService.instant(this.getPathTraductionWithClassName() + key + '.possibleValues.' + value[key]);
            return translate.includes(this.getPathTraductionWithClassName()) ? value[key] : translate;
        } else {
            const translate = this.translateService.instant('NaN');
            return isNotNullOrUndefined(value[key]) ? value[key] : translate;
        }
    }

    removeFile(file: { definitely: boolean, files: DocumentsFileParse[] }) {
        file.files.forEach(
            file => {
                let index;
                if (file.objectId.includes(this.initObjNewFiles)) {
                    index = this.newFiles.findIndex(storedFile => {
                        return storedFile.objectId == file.objectId
                    })
                    this.newFiles.splice(index, 1);
                } else {
                    index = this.connectedFiles.findIndex(storedFile => {
                        return storedFile.objectId == file.objectId
                    })
                    this.connectedFiles.splice(index, 1)
                }
            }
        )
    }

    isNewFile(file) {
        return isNotNullOrUndefined(file.objectId) && file.objectId.includes(this.initObjNewFiles)
    }

}
