<div style="padding: .3em">
  <span>
    <b>{{'streets.manufacturer' | translate}}</b> {{normalizedPhotometry.produttore}}
  </span> |
  <span>
    <b>{{'streets.family_name' | translate}}</b> {{normalizedPhotometry.nomeFamiglia}}
  </span> |
  <span>
    <b>{{'streets.product_name' | translate}}</b> {{normalizedPhotometry.nomeProdotto}}
  </span> |
  <span>
    <b>{{'streets.lamp_name' | translate}}</b> {{normalizedPhotometry.nomeLampada}}
  </span> |
  <span>
    <b>{{'streets.optics' | translate}}</b> {{normalizedPhotometry.ottica}}
  </span> |
  <span>
    <b>{{'streets.power' | translate}}</b> {{normalizedPhotometry.potenzaMin}} - {{normalizedPhotometry.potenzaMax}}
  </span> |
  <span>
    <b>{{'streets.flux' | translate}}</b> {{normalizedPhotometry.flussoMin}} - {{normalizedPhotometry.flussoMax}}
  </span> |
  <span>
    <b>{{'streets.efficiency' | translate}}</b> {{normalizedPhotometry.efficienzaMin}} - {{normalizedPhotometry.efficienzaMax}}
  </span> |
  <span>
    <b>{{'streets.color' | translate}}</b> {{normalizedPhotometry.temperaturaColore}}
  </span> |
  <span>
    <b>{{'streets.cri' | translate}}</b> {{normalizedPhotometry.CRI}}
  </span>
  <br>
  <span>{{normalizedPhotometry.nomeFile}}</span>
</div>