import {environment} from '../../environments/environment';

const servicePdf = environment.pdfUrl;
const serviceFotoCircuiti = environment.pdfUrl;

export const endpoints = {

    //  Plans
    plans: {
        closePlan: servicePdf + 'v1/_:planId/close?session=:sessionToken',
        openPlan: servicePdf + 'v1/_:planId/open?session=:sessionToken',
        getPDFPlan: servicePdf + 'v1/_:planId?session=:sessionToken',
        getPDFReport: servicePdf + 'v1/reports/_:reportId?session=:sessionToken&formato=:formato&mapType=:mapType',
    },

    fotoCircuiti: {
        // è in grado di resistuire la creazione dei file è stata rpeferita dal frontend per le traduzioni
        getFoto: serviceFotoCircuiti + 'v1/export/image/circuit/_:projectId?session=:sessionToken&message=:message&noFoto=:noFoto&email=:email&fileCircuitiSi=:fileCircuitiSi&fotoCircuitiSi=:fotoCircuitiSi',
    },
    fotoPuntiLuce: {
        // è in grado di resistuire la creazione dei file è stata rpeferita dal frontend per le traduzioni
        getFoto: serviceFotoCircuiti + 'v1/export/image/lightPoint/_:projectId?session=:sessionToken&message=:message&noFoto=:noFoto&email=:email&filePuntiLuceSi=:filePuntiLuceSi&fotoPuntiLuceSi=:fotoPuntiLuceSi',
    },
    fotoAll:{
        // è in grado di resistuire la creazione dei file è stata rpeferita dal frontend per le traduzioni
        getFoto: serviceFotoCircuiti + 'v1/export/image/all/_:projectId?session=:sessionToken&message=:message&noFoto=:noFoto&email=:email&fileCircuitiSi=:fileCircuitiSi&filePuntiLuceSi=:filePuntiLuceSi&fotoCircuitiSi=:fotoCircuitiSi&fotoPuntiLuceSi=:fotoPuntiLuceSi',
    }
}

