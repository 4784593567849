import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    OnDestroy,
    OnChanges, SimpleChanges, ViewChild,
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ordinamentoEcampiTraduzioni} from 'src/app/models/ordinamentoEcampiTraduzioni';
import {FormatterPipeService} from 'src/app/providers/services/formatter-pipe.service';
import {DatePipe} from '@angular/common';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {Subscription} from "rxjs/internal/Subscription";
import {PuntiLuceScaleColorService} from "../../../providers/services/punti-luce-scale-color.service";
import {PuntiLuceLabelService} from "../../../providers/services/punti-luce-label.service";
import {debounceTime, filter, pairwise, startWith} from "rxjs/operators";
import {
    arrayIsSet,
    getDifferenceArray,
    getDifferenceArrayWithKey,
    isNotNullOrUndefined, isValidDate,
    KeyStringValueString,
    modalityViewMap, stringIsSet
} from "../../../models/Models";
import {CalendarioManutenzioniParse} from "../../../models/CalendarioManutenzioni.Parse";
import {MatLegacyTabGroup as MatTabGroup} from '@angular/material/legacy-tabs';
import {dataForm} from '../../confirm-delete/select-or-create/select-or-create.component';
import {CircuitiParse} from "../../../models/Circuiti.Parse";


@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy, OnChanges {

    displayedColumns: string[] = ['color', 'key'];
    pathForTranslate = 'dashboard_sidenav.PuntiLuce.';
    public labelForm: UntypedFormGroup;
    public formSettings: UntypedFormGroup;
    public valueForm = {};

    @ViewChild('tabGroup') tabGroup: MatTabGroup;

    private subscriptions: Subscription[] = [];


    @Input()
    public modificaAvvenuta: boolean;
    @Input()
    public circuiti;
    @Input() public puntiLuce;
    @Input()
    public mapZoom: number;
    @Input()
    public maxZoomLabelVisualized: number;
    @Output() scalaColoreImpostata = new EventEmitter();
    @Output() elementoEtichettaImpostato = new EventEmitter();
    @Output() campoScalaCaloreCircuitoCambiato = new EventEmitter();
    @Output() fieldLabelCircuitoChange = new EventEmitter();
    @Output() monthYearImpostato = new EventEmitter();
    @Output() activeIlluminamentoATerra = new EventEmitter();
    @Output() close = new Subject();
    @Output() scheduleMaintenanceValueChange = new Subject();
    @Output() changeScaleColor = new Subject<any>();


    @Input()
    sidenavIsOpen = false;
    @Input()
    defaultValue: any = {maintenanceComponent: undefined};
    @Input() abbonamenti = {telecontrolloAttivo: false, illuminamentoAttivo: false, gestioneAttivo: false};
    @Input() visibilityLuxDataCluster;
    @Input() resetAllFilter: Subject<boolean> = new Subject();
    @Input() currentVisualization: string;
    @Input() calendarioManutenzioni: { date: number, calendariManutenzione: CalendarioManutenzioniParse[] };
    @Input() calendarioManutenzioniNonEffetuate: { date: number, calendariManutenzione: CalendarioManutenzioniParse[] };

    @Input() clusterVisualizzatoSullaMappa: any[];
    @Input() getElementOnParseLoad: Observable<any>;
    @Input() keyScaleColorSetElecttricLine: string;
    @Input() scaleColorSetElecttricLine: KeyStringValueString;
    subscriptionGetElementOnParseLoad: Subscription;

    loading = {month: false, year: false}

    circuitoSelectedTab = 0;

    puntiLuceAutocompleteFields: dataForm[] = [];
    private circuitiEmit: BehaviorSubject<CircuitiParse[]>;
    public circuiti$: Observable<CircuitiParse[]>;
    private puntiLuceEmit: BehaviorSubject<CircuitiParse[]>;
    public puntiLuce$: Observable<CircuitiParse[]>;

    constructor(
        private fb: UntypedFormBuilder,
        private formatterService: FormatterPipeService,
        private datePipe: DatePipe,
        private puntiLuceScaleColorService: PuntiLuceScaleColorService,
        private puntiLabelMarkerService: PuntiLuceLabelService,
    ) {
        this.circuitiEmit = new BehaviorSubject(this.circuiti);
        this.circuiti$ = this.circuitiEmit.asObservable();
        let campiSelectTemp = [];
        campiSelectTemp.push({key: 'lampadaFunzionante', sortingValue: 1000});
        Object.keys(this.filterOrdinamento().PuntiLuce).forEach((key) => {
            if (key != 'location') {
                if (ordinamentoEcampiTraduzioni.PuntiLuce[key].hasOwnProperty('varianteKey')) {
                    campiSelectTemp.push({
                        key: ordinamentoEcampiTraduzioni.PuntiLuce[key].varianteKey,
                        sortingValue: 100000 + ordinamentoEcampiTraduzioni.PuntiLuce[key].sortingValue
                    })
                }
                campiSelectTemp.push({key: key, sortingValue: ordinamentoEcampiTraduzioni.PuntiLuce[key].sortingValue});
            }
        });
        campiSelectTemp.sort((a, b) => a.sortingValue - b.sortingValue);
        this.puntiLuceAutocompleteFields = campiSelectTemp.map(({key}) => {
            const baseTranslation = this.pathForTranslate + key + '.title';
            if (this.addProjectTraduction(key)) {
                return {html: key, valueForm: key, traductions: [baseTranslation, ' - ', 'project']}
            }
            return {html: key, valueForm: key, traduction: baseTranslation};
        });
        this.valueForm['scalaColore'] = [{value: this.puntiLuceScaleColorService.campoScalaColore, disabled: false}];
        const filterForm = this.fb.group(this.valueForm);
        let subscription;
        this.valueForm['label'] = [{value: this.puntiLabelMarkerService.campoLabel, disabled: false}];
        this.labelForm = this.fb.group(this.valueForm);

        // this.aggiornaScala(this.elementoScalaAttuale);

        // Riempimento array mesi e anni

        this.formSettings = this.fb.group({
            scheduleMaintenance: this.fb.group({
                active: false,
                values: null,
                calendarsActive: null
            }),
            labelForm: this.labelForm,
            filterForm: filterForm

        });
        subscription = this.formSettings.get('filterForm').get('scalaColore').valueChanges.pipe(
            filter(key => {
                let index = 0;
                if (stringIsSet(key)) {
                    index = this.puntiLuceAutocompleteFields.findIndex(v => (v.valueForm == key || v.html == key))
                }
                return index >= 0
            })
        )
            .subscribe(
                key => {
                    if (stringIsSet(key)) {
                        this.puntiLuceScaleColorService.calcolaScalaColore(key, this.puntiLuce);
                        this.invioScala()
                    } else {
                        this.formSettings.get('filterForm').get('scalaColore').setValue('tipologiaSorgenteLuminosa')
                    }
                }
            );
        this.subscriptions.push(subscription);
        subscription = this.formSettings.get('labelForm').get('label').valueChanges.pipe(
            filter(key => {
                let index = 0;
                if (stringIsSet(key)) {
                    index = this.puntiLuceAutocompleteFields.findIndex(v => (v.valueForm == key || v.html == key))
                }
                return index >= 0
            })
        )
            .subscribe(
                key => {
                    const keySet = stringIsSet(key) ? key : undefined
                    this.puntiLabelMarkerService.calcNumeroElementiPerValore(keySet, this.puntiLuce);
                    this.invioEtichetta(key)
                }
            );
        this.subscriptions.push(subscription);
        subscription = this.formSettings.get('scheduleMaintenance').valueChanges
            .pipe(
                debounceTime(500),
                startWith(this.formSettings.get('scheduleMaintenance').value),
                pairwise(),
            )
            .subscribe(
                ([previus, current]) => {
                    this.scheduleMaintenanceValueChange.next(this.getObjectDifference(previus, current))
                }
            )
        this.subscriptions.push(subscription);
    }


    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => {
            sub.unsubscribe();
        })
        if (this.subscriptionGetElementOnParseLoad != null) {
            this.subscriptionGetElementOnParseLoad.unsubscribe();
        }
    }

    get numeroElementiPerValore() {
        return this.puntiLabelMarkerService.numeroElementiPerValore;
    }

    get tipoDato() {
        return this.puntiLuceScaleColorService.tipoDato
    }

    get campoScalaColore() {
        return this.puntiLuceScaleColorService.campoScalaColore
    }

    private filterOrdinamento(): {
        PuntiLuce,
        PictureCameraPoint,
        Circuiti
    } {
        let newObject: {
            PuntiLuce,
            PictureCameraPoint,
            Circuiti
        } = {
            PuntiLuce: null,
            PictureCameraPoint: null,
            Circuiti: null
        };
        Object.keys(ordinamentoEcampiTraduzioni).forEach((classe) => {
            let field = {};
            Object.keys(ordinamentoEcampiTraduzioni[classe]).forEach((key) => {
                if (ordinamentoEcampiTraduzioni[classe][key].showInForm || ordinamentoEcampiTraduzioni[classe][key].showInLabel) {
                    field[key] = ordinamentoEcampiTraduzioni[classe][key];
                }
            });
            newObject[classe] = field;
        });
        return newObject;
    }


    invioScala() {
        let invio = {
            tabellaImpostataScala: this.puntiLuceScaleColorService.campoScalaColore,
        };
        this.scalaColoreImpostata.emit(invio);
    }

    private invioEtichetta(label) {
        this.elementoEtichettaImpostato.emit(label);
    }

    ngOnInit(): void {
    }


    abbonamentoIsUpdate = false;

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.subscriptionGetElementOnParseLoad && this.getElementOnParseLoad) {
            this.subscriptionGetElementOnParseLoad = this.getElementOnParseLoad.subscribe(
                value => {
                    if (value != null) {
                        const obj: any = {};
                        Object.keys(value).forEach(key => {
                            obj[key] = value[key]
                        })
                        this.loading = obj;
                    }
                }
            )
        }
        if (changes.currentVisualization) {
            this.formSettings.get('scheduleMaintenance').get('active').setValue(this.currentVisualization == modalityViewMap.scheduleMaintenance);
        }
        if (changes.abbonamenti) {
            const previusGestione = (changes.abbonamenti.previousValue) ? changes.abbonamenti.previousValue.gestioneAttivo : null;
            const currentGestione = (changes.abbonamenti.currentValue) ? changes.abbonamenti.currentValue.gestioneAttivo : null;
            if (previusGestione !== currentGestione) {
                this.formSettings.get('scheduleMaintenance').get('active')
                    .setValue(this.currentVisualization === modalityViewMap.scheduleMaintenance && changes.abbonamenti.currentValue.gestioneAttivo);
                this.abbonamentoIsUpdate = true;
            }
        }

        if (changes.sidenavIsOpen && changes.sidenavIsOpen.currentValue && this.tabGroup) {
            // without this, when the sidenav is first opened, the selected tab doesn't get underlined properly
            this.tabGroup.realignInkBar();
        }
        if (changes.circuiti) {

            const difference = getDifferenceArrayWithKey(changes.circuiti.previousValue, changes.circuiti.currentValue, 'objectId', 'objectId')
            if (arrayIsSet(difference)) {
                this.circuitiEmit.next(arrayIsSet(this.circuiti) ? [...this.circuiti] : [])
            }
        }


    }


    get elementiScalaArray() {
        return this.puntiLuceScaleColorService.scalaColoreArray
    }

    get isSetElementiScalaArray() {
        return Array.isArray(this.elementiScalaArray) && this.elementiScalaArray.length > 0
    }

    public onSubmitLabel(event) {
        this.close.next(true);
        setTimeout(() => {
            this.invioEtichetta(event.value);
        }, 250);
    }

    closeSidenav() {
        this.close.next();
    }


    get rowHeight() {
        if (this.formSettings.get('labelForm').get('label').value.toUpperCase() == 'INDIRIZZO') {
            return '4rem';
        } else {
            return '2rem';
        }
    }

    setLampadaFunzionante() {
        this.close.next(true);
        this.formSettings.get('filterForm').get('scalaColore').setValue('lampadaFunzionante');
    }


    cambiaScalaColoreCircuito(campo) {
        this.campoScalaCaloreCircuitoCambiato.next(campo)
    }

    unsteValue() {
        this.formSettings.get('labelForm').get('label').reset();
    }


    public currentMonthYearChange(event) {
        this.monthYearImpostato.next(event);
    }

    public addProjectTraduction(key: string) {
        return isNotNullOrUndefined(key) && typeof key == "string" && key.includes('variante');
    }

    isActive(key) {
        return this.formSettings.get(key) && this.formSettings.get(key).get('active') && this.formSettings.get(key).get('active').value;
    }

    maintenanceScheduleChangeValue(event) {
        this.formSettings.get('scheduleMaintenance').get('values').setValue(event)
    }


    getDifferenceArrayWithObjectKeys(currentObject, previusObject, keyToGetDifference) {
        const arr1 = Array.isArray(currentObject) ? currentObject.map(item => item[keyToGetDifference]) : [];
        const arr2 = Array.isArray(previusObject) ? previusObject.map(item => item[keyToGetDifference]) : [];
        return getDifferenceArray(arr1, arr2);
    }


    getObjectDifference(previusObject: object, currentObject: object): any {
        if (!isNotNullOrUndefined(currentObject)) {
            return null
        } else {
            let obj = {};
            Object.keys(currentObject).forEach(key => {
                if (Array.isArray(currentObject[key])) {
                    const previus = (isNotNullOrUndefined(previusObject)) ? previusObject[key] : null;
                    obj[key] = {
                        value: currentObject[key],
                        changed: this.getDifferenceArrayWithObjectKeys(previus, currentObject[key], 'objectId').length > 0
                    }
                } else if (currentObject != null && isValidDate(currentObject[key])) {
                    const previusGetTime = (isNotNullOrUndefined(previusObject)) && isNotNullOrUndefined(previusObject[key]) ? previusObject[key].getTime() : null;
                    const currentGetTime = (isNotNullOrUndefined(currentObject)) && isNotNullOrUndefined(currentObject[key]) ? currentObject[key].getTime() : null;
                    obj[key] = {
                        value: currentObject[key],
                        changed: (isNotNullOrUndefined(previusObject) && previusObject.hasOwnProperty(key)) ? previusGetTime != currentGetTime : true
                    };
                } else if (typeof currentObject[key] != 'object' || !isNotNullOrUndefined(currentObject[key])) {
                    obj[key] = {
                        value: currentObject[key],
                        changed: (isNotNullOrUndefined(previusObject) && previusObject.hasOwnProperty(key)) ? previusObject[key] != currentObject[key] : true
                    };
                } else {
                    const previus = (isNotNullOrUndefined(previusObject)) ? previusObject[key] : null;
                    obj[key] = this.getObjectDifference(previus, currentObject[key])
                }
            })
            return obj;
        }
    }

    emitCalendario(event) {
        this.formSettings.get('scheduleMaintenance').get('calendarsActive').setValue([...event])
    }

    emitChangeScaleColor(value: any) {
        this.changeScaleColor.next(value);
    }

    selectPuntoLuceColorScale(e: { formValues: dataForm | null }) {
        const value = e.formValues?.valueForm ?? null;
        this.puntiLuceScaleColorService.calcolaScalaColore(value, this.puntiLuce);
        this.invioScala();
    }

    selectPuntoLuceLabel(e: { formValues: dataForm | null }) {
        if (e.formValues) {
            this.invioEtichetta(e.formValues.valueForm);
        } else {
            this.unsteValue();
        }
    }

}
