import {Injectable} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {
    CircuitiErrori,
    colorError,
    errorType,
    LevelError,
    PuntiLuceErrori
} from "../../pages-modules/attachments/SubModels";
import {isNotNullOrUndefined} from "../../models/Models";

@Injectable({
    providedIn: 'root'
})
export class AttachmentFormService {

    constructor(
        private fb: UntypedFormBuilder
    ) {
    }

    public getCreateAttachmentForm(): UntypedFormGroup {
        return this.fb.group({
                tipoFile: ['PUNTO_LUCE', Validators.required],
                csv: ['', Validators.required],
            }
        );
    }

    public getColorError(puntoLuce: PuntiLuceErrori | CircuitiErrori): string {
        if (!puntoLuce.hasError()) {
            return colorError.noError
        } else {
            return this.getColorWithError(puntoLuce.error);
        }
    }

    public getColorWithError(error: errorType[]) {
        if (!isNotNullOrUndefined(error)) {
            return undefined;
        }
        let errorDetected;
        error.forEach((singleError) => {
            if (!isNotNullOrUndefined(errorDetected)) {
                errorDetected = singleError.level;
            } else if (singleError.level > errorDetected)
                errorDetected = singleError.level;
        });
        return this.getColorByLevel(errorDetected);
    }

    private getColorByLevel(errorDetected) {
        if (errorDetected == LevelError.warning) {
            return colorError.warning
        } else if (errorDetected === LevelError.error) {
            return colorError.error
        } else {
            return undefined;
        }
    }


    public colorErrorWithKey(errors: errorType[], key) {
        if (!isNotNullOrUndefined(errors)) {
            return undefined;
        } else {
            let levelError;
            errors.forEach((error) => {
                if (error.key === key) {
                    levelError = error.level;
                }
            });
            return this.getColorByLevel(levelError);
        }


    }
    getStrumentForm(): UntypedFormGroup {
        return this.fb.group({
                moveElement: false,
                selects: undefined,
                rotate: 0
            }
        );
    }
}
