<div class="split-container" #containerSplitScreen>
    <ng-container *ngIf="(widthHeighBeforBreakPoint$|async) as width;else noBreakPoint">
        <div class="split-child-sx" [ngStyle]="{width:width.sx,order:width.orderSx}">
            <ng-content select="[sx]">

            </ng-content>
        </div>
        <div class="split-child-dx" [ngStyle]="{width:width.dx,order:width.orderDx}">
            <ng-content select="[dx]">

            </ng-content>
        </div>
    </ng-container>
    <ng-template #noBreakPoint>
        <div class="split-child-sx" style="width: 50%">
            <ng-content select="[sx]">

            </ng-content>
        </div>
        <div class="split-child-dx" style="width: 50%">
            <ng-content select="[dx]">

            </ng-content>
        </div>
    </ng-template>
</div>