<div class="flex">
    <div class="data"  (click)="snackBarRef.dismiss()">
        <img class="icon-dimension" src="/assets/icon/errors-component/{{iconTitle}}">
        <div>
        <p style="text-align: center; margin: 0 16px;" [style.color]="textColor">{{data.title}}</p>
        <p style="text-align: center; margin-bottom: 0; margin-top: 8px;"
        [style.color]="textColor"
        *ngFor=" let tabelWidthError of data.text">{{tabelWidthError}}</p></div>
    </div>
    
</div>
