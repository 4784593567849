<div class="container">
    <div class="data" style="text-align: center;margin-top: -30px;">
        <H4 style="margin-top: 0;">
            <img class="icon-dimension" src="/assets/icon/dialogCompnent/ask.png">
        </H4>
        <h5>{{data.message}}</h5>
    </div>
    <div class="flex-container" *ngIf="visualizzaBottone;">
        <button mat-mini-fab [mat-dialog-close]="[nomiClasse.puntiLuce]">
            <img src="/assets/icon/dialogCompnent/lampione.svg" style="max-height: 24px;min-height: 24px;"
                 alt="lampione">
        </button>
        <button mat-mini-fab [mat-dialog-close]="[nomiClasse.circuiti]">
            <img src="/assets/icon/dialogCompnent/quadro.png" style="max-height: 24px;min-height: 24px;"
                 alt="quadro elettrico">
        </button>
        <button mat-mini-fab [mat-dialog-close]="[nomiClasse.arredoUrbano]">
            <img src="/assets/icon/dashboard/segnaleStradale.svg"
                 style="max-height: 24px;min-height: 24px;filter: invert(1)"
                 alt="arredo urbano">
        </button>
        <button mat-mini-fab [mat-dialog-close]="nomiClasse.lineaElettrica">
            <mat-icon>electrical_services</mat-icon>
        </button>
    </div>
</div>


<!--<ng-template #visualizzaForm>-->
<!--    <ng-container [ngSwitch]="typeSelect">-->
<!--        <ng-container *ngSwitchCase="nomiClasse.circuiti">-->
<!--            <mat-form-field class="example-chip-list">-->
<!--                <mat-chip-list #chipList aria-label="numeroQuadro selection" (change)="addNumeroQuadro($event)">-->
<!--                    <mat-chip *ngFor="let numeroQuadro of numeriQuadro" [selectable]="selectable"-->
<!--                              [removable]="removable"-->
<!--                              (removed)="removeNumeroQuadro(numeroQuadro)" (click)="selectMe(numeroQuadro)"-->
<!--                              [mat-dialog-close]="[nomiClasse.circuiti,numeroQuadro]">-->
<!--                        {{numeroQuadro}}-->
<!--                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>-->
<!--                    </mat-chip>-->
<!--                    <input [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"-->
<!--                           [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="addNumeroQuadro($event)">-->
<!--                </mat-chip-list>-->
<!--            </mat-form-field>-->
<!--        </ng-container>-->
<!--        <ng-container *ngSwitchCase="nomiClasse.arredoUrbano">-->
<!--            <form [formGroup]="formType" *ngIf="formType">-->
<!--                <mat-form-field class="example-chip-list">-->
<!--                    <input matInput formControlName="type">-->
<!--                    <button matSuffix mat-icon-button color="primary" (click)="$event.stopPropagation();sendType()"-->
<!--                            [disabled]="disableButtonSendType">-->
<!--                        <mat-icon>send</mat-icon>-->
<!--                    </button>-->
<!--                </mat-form-field>-->
<!--            </form>-->
<!--            <div class="flex-around-center w100 flex-flow-row">-->
<!--                <div *ngFor="let type of typesArredoUrbano">-->
<!--                    <button mat-raised-button color="primary" style="margin: 10px"-->
<!--                            (click)="addTypeArredoUrbano(type.key)" [matTooltip]="type.traduction">-->
<!--                        <div style="width: 100%;margin-top: 5px;margin-bottom: 5px" class="flex-center-width100">-->
<!--                            <svg width="30px" height="30px">-->
<!--                                <g>-->
<!--                                    <path [attr.d]="type.path" fill="#FFFFFF"></path>-->
<!--                                </g>-->
<!--                            </svg>-->
<!--                        </div>-->
<!--                    </button>-->
<!--                </div>-->
<!--            </div>-->
<!--        </ng-container>-->
<!--    </ng-container>-->
<!--</ng-template>-->

