import {Injectable} from '@angular/core';
import {combineLatest, EMPTY, Observable} from "rxjs";
import {CalendarioManutenzioniParse} from "../../models/CalendarioManutenzioni.Parse";
import {ProjectService} from "./project.service";
import {fromPromise} from "rxjs/internal-compatibility";
import {
    arrayIsSet,
    className,
    getItemInArrayByKeyValue,
    isNotNullOrUndefined,
    paramsApiParse_v2, stringIsSet
} from "../../models/Models";
import {ProgettiParse} from "../../models/Progetti.Parse";
import * as Parse from "parse";
import {expand, map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class CalendarioManutenzioniService {

    constructor(private projectService: ProjectService) {
    }


    get project() {
        return this.projectService.currentProject;
    }

    getCalendarioManutenzioni(dayTime: number, projectId = null,
                              rangeDate: {
                                  fromDate: Date,
                                  toDate: Date
                              } = undefined
    ): Observable<CalendarioManutenzioniParse[]> {
        let progettoId;
        if (stringIsSet(projectId)) {
            progettoId = projectId
        } else {
            progettoId = this.project.objectId
        }
        let data = {progettoId};
        if (dayTime) {
            data['dayTime'] = dayTime
        } else {
            data['fromDate'] = rangeDate.fromDate.getTime();
            data['toDate'] = rangeDate.toDate.getTime();
        }
        const params = paramsApiParse_v2(data);
        return fromPromise(Parse.Cloud.run('getCalendarioManutenzioniEffettuate', params)).pipe(
            map(calendari => {
                if (arrayIsSet(calendari)) {
                    return calendari.map(c => {
                        const calendarioParse = new CalendarioManutenzioniParse();
                        calendarioParse.objectId = c.id;
                        return calendarioParse;
                    })
                } else {
                    return []
                }
            })
        );

    }


    getCalendarioManutenzioniByElement(elementParse: any, idsToNotGet: string[], projectId = null): Observable<CalendarioManutenzioniParse[]> {
        let circuitoId;
        let puntoLuceId;
        if (elementParse.className == className.puntiLuce) {
            puntoLuceId = elementParse.objectId
        } else if (elementParse.className == className.circuiti) {
            circuitoId = elementParse.objectId
        }
        const params = paramsApiParse_v2({idNonAccettabili: idsToNotGet, circuitoId, puntoLuceId});
        return fromPromise(Parse.Cloud.run('getCalendarioManutenzioniByElement', params)).pipe(
            map(calendari => {
                if (arrayIsSet(calendari)) {
                    return calendari.map(c => {
                        const calendarioParse = new CalendarioManutenzioniParse();
                        calendarioParse.objectId = c.id;
                        return calendarioParse;
                    })
                } else {
                    return []
                }
            })
        );
    }

    getCalendariNonEffetuati(dayTime: number, projectId = null, rangeDate: {
        fromDate: Date,
        toDate: Date
    } = undefined) {
        let progettoId;
        if (stringIsSet(projectId)) {
            progettoId = projectId
        } else {
            progettoId = this.project.objectId
        }
        const params = paramsApiParse_v2({dayTime, progettoId});
        return fromPromise(Parse.Cloud.run('getCalendarioManutenzioniNonEffettuate', params)).pipe(
            map(calendari => {
                if (arrayIsSet(calendari)) {
                    return calendari.map(c => {
                        const calendarioParse = new CalendarioManutenzioniParse();
                        calendarioParse.objectId = c.id;
                        return calendarioParse;
                    })
                } else {
                    return []
                }
            })
        );
        ;
    }

    getDateById(id: string): Date | undefined {
        console.log(id)
        const time = parseInt(id, 32);
        return isNaN(time) ? undefined : new Date(time)
    }

    getIdForDate(date: Date) {
        date.setHours(0, 0, 0, 0);
        return date.getTime().toString(32)
    }

    getDate(date: Date) {
        date.setHours(12, 0, 0, 0);
        return date
    }

    getClusterCalendariSchedeManutenzione(calendarioManutenzioni: CalendarioManutenzioniParse[]): {
        objectId: string,
        date,
        calendariManutenzione: CalendarioManutenzioniParse[]
    }[] {
        const dates: { objectId: string, date, calendariManutenzione: CalendarioManutenzioniParse[] }[] = []
        calendarioManutenzioni.forEach(calendario => {
            const id = this.getIdForDate(calendario.maintenanceDate);
            const index = dates.findIndex(item => item.objectId === id)
            if (index >= 0) {
                dates[index].calendariManutenzione.push(calendario);
            } else if (calendario.maintenanceDate instanceof Date) {
                dates.push({
                    objectId: id,
                    date: this.getDate(calendario.maintenanceDate),
                    calendariManutenzione: [calendario]
                })
            }
        })
        return dates;
    }


    public createCalendarioManutenzione(maintenanceDate: Date, schedaManutenzioneId, puntiLuceIds, circuitiIds): Observable<CalendarioManutenzioniParse> {
        return fromPromise(Parse.Cloud.run('createCalendarioManutenzione',
            {
                maintenanceDateTime: maintenanceDate.getTime() / 1000,
                schedaManutenzioneId,
                puntiLuceIds,
                circuitiIds
            }, {})
        )
    }

    public createCalendariManutenzione(calendari: {
        maintenanceDate: Date,
        schedaManutenzioneId,
        puntiLuceIds,
        circuitiIds
    }[]): Observable<{ item: CalendarioManutenzioniParse, progress: number, finished: boolean }> {

        const last = calendari.length;

        const fetchPage = (page, calendari) => {
            const calendario = calendari[page];
            const progress = Math.ceil((page + 1) / last * 100)
            let isLast;
            if (page + 1 == last) {
                isLast = true;
            } else {
                isLast = false;
            }
            return this.createCalendarioManutenzione(calendario.maintenanceDate, calendario.schedaManutenzioneId, calendario.puntiLuceIds, calendario.circuitiIds)
                .pipe(
                    map((item) => {
                        return {
                            item: item,
                            progress: progress,
                            nextPage: isLast ? undefined : (page += 1),
                            finished: isLast,
                        };
                    })
                )
        }


        return fetchPage(0, calendari).pipe(
            expand(({nextPage}) => nextPage ? fetchPage(nextPage, calendari) : EMPTY),
        )
    }


}
