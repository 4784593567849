import {Component, Input, OnInit} from '@angular/core';
import {IntervalsType, TLC_ProfiloFunzionamentoParse} from "../../models/TLC_ProfiloFunzionamento.Parse";

@Component({
    selector: 'app-box-profilo-funzionamento',
    templateUrl: './box-profilo-funzionamento.component.html',
    styleUrls: ['./box-profilo-funzionamento.component.scss']
})
export class BoxProfiloFunzionamentoComponent implements OnInit {
    @Input() profiloFunzionamento: TLC_ProfiloFunzionamentoParse

    constructor() {
    }

    ngOnInit(): void {
    }

    predicateFunctionGetHour(interval: IntervalsType): string {
        return interval.hour.toString().padStart(2, '0') + ':' + interval.minute.toString().padStart(2, '0')
    }
}
