import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {SchedaManutenzioneParse} from "../../../models/SchedaManutenzione.Parse";
import {Observable, Subscription} from "rxjs";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {
    filtersSchedeManutenzioneCompilate,
    formFieldGroupName,
    formFiledMaintenance, formFiledMaintenanceFormContolName,
    formFiledMaintenanceFormGroup, operatorFiltersSchedeManutenzioneCompilate,
    typeFormFieldMaintenance
} from "../../../providers/services/maintenance.service";
import {
    arrayIsSet,
    customThemeNgxDataPicker,
    getArrayToRemveItem, getItemInArrayByKeyValue,
    hunaParseFileName, isNotNullOrUndefined,
    stringIsSet
} from "../../../models/Models";
import {ImageService} from "../../../providers/services/image.service";
import {AlertService} from "../../../providers/services/alert.service";
import {TranslateService} from "@ngx-translate/core";
import {dataForm} from "../../confirm-delete/select-or-create/select-or-create.component";
import {typeFormValue} from "../../../models/configurationProperty/configurationPropertyUtils";

@Component({
    selector: 'app-form-to-filter',
    templateUrl: './form-to-filter.component.html',
    styleUrls: ['./form-to-filter.component.scss']
})
export class FormToFilterComponent implements OnInit, OnDestroy, OnChanges {


    @Input() schedaSelezionata: SchedaManutenzioneParse
    @Input() cssPositionSaveButton: 'fab' | 'centerPositionButtonSave' | 'notShow' = 'fab';
    @Input() savedCompleted: Observable<any>;
    @Input() typeNotVisualized: any[];
    @Input() visualizedUnsetButton = false;
    @Output() emitValue = new EventEmitter<any>();

    subscriptionSavedCompleted: Subscription;
    subscriptionForm: Subscription;

    expandedDetail = {};

    public loading = {
        saveScheda: false,
    }
    public forms: UntypedFormGroup = this.fb.group({});
    public formFieldSchedaSelezionata: formFiledMaintenanceFormGroup[] = [];
    typeFormField = typeFormFieldMaintenance
    typeFormFieldComponentConfigurationElement = typeFormValue
    formFieldGroupName = formFieldGroupName
    files = {};
    public customThemNgxDataPicker = customThemeNgxDataPicker;

    constructor(
        private fb: UntypedFormBuilder,
        private imageService: ImageService,
        private alertService: AlertService,
        public translate: TranslateService,
    ) {
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        if (this.subscriptionForm != null) {
            this.subscriptionForm.unsubscribe()
        }
        if (this.subscriptionSavedCompleted != null) {
            this.subscriptionSavedCompleted.unsubscribe()
        }
    }

    setExpandMoreLessNote(field: formFiledMaintenanceFormGroup) {
        if (this.expandedDetail[field.formControlName]) {
            this.expandedDetail[field.formControlName] = false;
        } else {
            this.expandedDetail[field.formControlName] = true;
        }
    }

    getExpandMoreLessNote(field: formFiledMaintenanceFormGroup) {
        return this.expandedDetail[field.formControlName];
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.subscriptionSavedCompleted && this.savedCompleted) {
            this.subscriptionSavedCompleted = this.savedCompleted.subscribe(
                value => {
                    if (value.schedaManutenzione) {
                        if (!value.schedaManutenzione.error) {
                            this.forms.reset();
                            this.files = {};
                        }
                        this.loading.saveScheda = false;
                    }
                }
            )
        }
        if (isNotNullOrUndefined(changes.schedaSelezionata)) {
            const getDefaultValue = (keyForm) => {
                return null
                // if (keyForm.type === typeFormFieldMaintenance.checkBox) {
                //     return false;
                // } else {
                //     return null;
                // }
            }
            this.formFieldSchedaSelezionata = undefined;
            if (this.subscriptionForm != null) {
                this.subscriptionForm.unsubscribe();
            }
            // viene utilizzato aggiungi e rimuovi campi dal form, perchè atrimenti poteva causare dei warning nell html
            const formFieldSchedaSelezionata = this.schedaSelezionata.form
                .filter(keyForm => !(arrayIsSet(this.typeNotVisualized) && this.typeNotVisualized.includes(keyForm.type)))
                .map(
                    (keyForm, index) => {
                        const keyFormCopy = {...keyForm}
                        const key = this.getKey(keyForm.traduction, index);
                        const defaultValue = getDefaultValue(keyForm);
                        const validators = [];
                        let formGroup: { name: string, type: typeFormValue, traductionMin: string, traductionMax: string, operators: { formControlname: string, operator: string }[] };
                        // if (keyForm.type == typeFormFieldMaintenance.checkBox) {
                        //   validators.push(Validators.requiredTrue)
                        // } else if (keyForm.required) {
                        //   validators.push(Validators.required)
                        // }

                        let possibleValuesWithKey;
                        if (keyFormCopy.type == this.typeFormField.autoComplete || keyFormCopy.type == this.typeFormField.multiSelect) {
                            possibleValuesWithKey = this.getAutoComplete(keyFormCopy.possibleValues)
                        }
                        if (isNotNullOrUndefined(this.forms) && this.forms.contains(key)) {
                            this.forms.removeControl(key)
                        }
                        if (keyFormCopy.type == this.typeFormField.inputNumber) {
                            const fieldsSubForm: any[] = [{
                                formControlName: 'min',
                                operator: '>='
                            }, {
                                formControlName: 'max',
                                operator: '<='
                            }]
                            const objForm = {};
                            fieldsSubForm.forEach(val => {
                                objForm[val.formControlName] = null;
                            })
                            const obj = {};
                            obj[key] = this.fb.group(objForm)
                            this.forms = this.fb.group({
                                ...this.forms.controls,
                                ...obj
                            });
                            keyFormCopy.type = this.formFieldGroupName;
                            formGroup = {
                                name: key,
                                type: this.typeFormFieldComponentConfigurationElement.INPUT_NUMBER,
                                traductionMin: 'min',
                                traductionMax: 'max',
                                operators: fieldsSubForm
                            }
                        } else if (keyFormCopy.type == this.typeFormField.dataPicker) {
                            const fieldsSubForm: any [] = [{
                                formControlName: 'min',
                                operator: '>='
                            }, {
                                formControlName: 'max',
                                operator: '<='
                            }]
                            const objForm = {};
                            fieldsSubForm.forEach(val => {
                                objForm[val.formControlName] = null;
                            })
                            const obj = {};
                            obj[key] = this.fb.group(objForm)

                            this.forms = this.fb.group({
                                ...this.forms.controls,
                                ...obj
                            });

                            keyFormCopy.type = this.formFieldGroupName;
                            formGroup = {
                                name: key,
                                type: this.typeFormFieldComponentConfigurationElement.DATE,
                                traductionMin: 'from',
                                traductionMax: 'to',
                                operators: fieldsSubForm
                            }
                        } else {
                            this.forms.addControl(key, this.fb.control(null, validators));
                            this.forms.get(key).setValue(defaultValue);
                        }
                        if (arrayIsSet(validators)) {
                            this.forms.get(key).setValidators(validators)
                        }
                        let allObj = {...keyFormCopy, formControlName: key, formGroup: formGroup}
                        if (possibleValuesWithKey) {
                            allObj['possibleValuesWithKey'] = possibleValuesWithKey
                        }
                        return allObj
                    }
                )

            formFieldSchedaSelezionata.sort((fieldA, fieldB) => {
                const sortA = isNotNullOrUndefined(fieldA.sortingNumber) ? fieldA.sortingNumber : 0;
                const sortB = isNotNullOrUndefined(fieldB.sortingNumber) ? fieldB.sortingNumber : 0;
                return sortA - sortB;
            });
            const currentKeys = formFieldSchedaSelezionata.map(key => key.formControlName)
            Object.keys(this.forms.value).forEach(key => {
                if (!currentKeys.includes(key)) {
                    this.forms.removeControl(key)
                }
            })

            this.formFieldSchedaSelezionata = formFieldSchedaSelezionata;
            this.initEmitLiveValue();
        }
    }


    initEmitLiveValue() {
        if (this.subscriptionForm != null) {
            this.subscriptionForm.unsubscribe();
        }
        this.subscriptionForm = this.forms.valueChanges
            .subscribe(value => {
                this.emitValue.emit(
                    {
                        filters: this.getValueForm(),
                    }
                )
            })
    }

    getAutoComplete(values: string[]): dataForm[] {
        return values.map((value, index) => {
            return {html: value, valueForm: this.getKey(value, index)}
        })
    }

    getKey(value: string, index: number) {
        if (stringIsSet(value)) {
            return value
                .trim()
                .replace(/ /g, '')
                .replace(/-/g, '')
                .replace(/_/g, '')
                .replace(/\./g, '')
                .toLowerCase() + index
        }
    }

    getFieldName(field: formFiledMaintenance) {
        let name = field.traduction;
        if (field.required) {
            name += ' *';
        }
        return name
    }

    getFieldNote(field: formFiledMaintenance) {
        // return 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.';
        return field.note;
    }

    getImageForForm(base64, name) {
        return this.imageService.compressImage(base64).then(compressed => {
            const base64Compressa: string = compressed as string;
            return {
                url: null,
                file: base64Compressa,
                name: hunaParseFileName(name)
            };
            // this.salvaImage = true;
        })
    }

    onLoadImage(files: File[], label) {
        try {
            let fileUploadControl = files[0];
            if (files.length == 0) {
                this.alertService.error(this.translate.instant('load_correct_file'));
            } else {
                if (fileUploadControl) {
                    const file = fileUploadControl;
                    this.files[label] = fileUploadControl;
                    const name = fileUploadControl.name;
                    const reader = new FileReader();
                    reader.onloadend = (e: ProgressEvent) => {
                        const base64 = (e.target as FileReader).result as any;
                        this.getImageForForm(base64, name).then(
                            (value) => {
                                this.forms.get(label).setValue(value);
                            }
                        );
                    };
                    reader.onerror = (e) => {
                        this.alertService.error(e)
                    }
                    reader.readAsDataURL(file);

                }
            }
        } catch (e) {
            console.error(e);
        }
    }

    onRemoveFile(label) {
        if (isNotNullOrUndefined(this.files[label])) {
            delete this.files[label]
        }
        this.forms.get(label).reset();
    }


    getColorYesOrNotButton(key, valueSet: boolean): 'primary' | 'warn' | 'unset' {
        if (this.forms.get(key).value !== valueSet) {
            return 'unset'
        } else if (valueSet) {
            return 'primary'
        } else {
            return 'warn'
        }
    }

    setValueYesOrNotButton(key, valueSet: boolean) {
        return this.forms.get(key).setValue(valueSet);
    }

    unsetField(key) {
        return this.forms.get(key).reset();
    }

    getFilterSchedeManutenzioneCompilate(key: any, valueForm: any, formControlName: string): filtersSchedeManutenzioneCompilate [] | undefined {
        const filters: filtersSchedeManutenzioneCompilate [] = []
        if (key.formGroup != null && arrayIsSet(key.formGroup.operators)) {
            key.formGroup.operators.forEach((field, index) => {
                const value = valueForm[field.formControlName]
                const operator = key.formGroup.operators[index].operator
                if (value != null && operator != null) {
                    filters.push({
                        value: value,
                        operator: operator,
                        key: key.traduction
                    })
                }
            })
        }
        return filters;
    }

    getValueForm() {
        let filters: filtersSchedeManutenzioneCompilate [] = []
        const convertDateToDecimal = (date) => {
            if (date == null || date.split == null) {
                return null
            }
            const dateSplit = date.split(':');
            let hour = parseInt(dateSplit[0]);
            let minute = parseInt(dateSplit[1]);
            if (isNaN(hour)) {
                hour = 0
            }
            if (isNaN(minute)) {
                minute = 0
            }
            return hour + Math.round(minute / 60 * 10000) / 10000
        }
        const valueForm = {...this.forms.value}
        const keys = this.formFieldSchedaSelezionata;
        Object.keys(valueForm).forEach(key => {
            const fieldForm = getItemInArrayByKeyValue(keys, key, 'formControlName');
            const value = valueForm[key];
            if (isNotNullOrUndefined(fieldForm.possibleValuesWithKey)) {
                const possibleValue = getItemInArrayByKeyValue(fieldForm.possibleValuesWithKey, value, 'valueForm')
                if (isNotNullOrUndefined(possibleValue) && stringIsSet(possibleValue.html)) {
                    filters.push({key: fieldForm.traduction, value: possibleValue.html, operator: '=='})
                }
            } else if (fieldForm.type == this.typeFormField.hour && value != null) {
                filters.push({key: fieldForm.traduction, value: convertDateToDecimal(value), operator: '=='})
            } else if (fieldForm.type == this.formFieldGroupName && valueForm[key] != null) {
                const filterField = this.getFilterSchedeManutenzioneCompilate(fieldForm, valueForm[key], key)
                if (arrayIsSet(filterField)) {
                    filters = filters.concat(filterField)
                }
            } else if (value != null) {
                filters.push({key: fieldForm.traduction, value: value, operator: '=='})
            }
        });
        return filters
    }


    setButton(key: string, value: any) {
        this.forms.get(key).setValue(value)
    }

    getColor(key: string, value: any) {
        return this.forms.get(key).value !== value ? 'unset' : 'accent'
    }

    isInError(field: formFiledMaintenanceFormGroup, keyError: string) {
        return this.forms.touched && this.forms.get(field.formControlName).hasError(keyError)
    }

}
