import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OpenNewTabComponent} from './open-new-tab/open-new-tab.component';
import {MatLegacyTabsModule as MatTabsModule} from "@angular/material/legacy-tabs";
import {MatIconModule} from "@angular/material/icon";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import {TranslateModule} from "@ngx-translate/core";
import {BoxesModule} from "../../boxes/boxes.module";
// import {AgmCoreModule} from "@agm/core";
import {WidgetsModule} from "../../widgets/widgets.module";
import {CoreModule} from "../../core/core.module";
import {UpdateCreateModule} from "../update-create/update-create.module";
import {StreetModule} from "../street/street.module";
//todo aggiornanre mappa


@NgModule({
    declarations: [
        OpenNewTabComponent
    ],
    exports: [
        OpenNewTabComponent,
    ],
    imports: [
        CommonModule,
        MatTabsModule,
        MatIconModule,
        MatButtonModule,
        TranslateModule,
        BoxesModule,
        // AgmCoreModule,
        WidgetsModule,
        CoreModule,
        UpdateCreateModule,
        StreetModule,
    ]
})
export class OpenInTabModule {

}
