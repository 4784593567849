import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {UserService} from "../services/user.service";
import {LocalSotrageService} from "../services/local-sotrage.service";

@Injectable({
    providedIn: 'root'
})
export class PathGuard implements CanActivate {

    constructor(
        private localStorage: LocalSotrageService,
        private router: Router
    ) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const firstView = this.localStorage.projectFirstView;
        if (firstView == "TELECONTROLLO") {
            this.router.navigate(['/controllo-remoto']);
            return false;
        } else {
            return true;
        }
    }

}
