<app-container>
    <app-container-child>
        <button mat-flat-button color="primary" (click)="dialogRef.close()">
            <mat-icon>clear</mat-icon>
        </button>
        <h3>{{'fotometria.correctResultSettings'|translate}}</h3>
        <h4 style="font-weight: bold;margin-bottom: -20px;min-width: 110px">
            <img style="max-width: 24px;margin-right: 10px"
                 src="/assets/icon/logos/chrome_logo.svg">{{'fotometria.chrome'|translate}}</h4>
        <figure>
            <figcaption><h4>{{'fotometria.correctChromeSettings'|translate}}</h4></figcaption>
            <img style="max-width: 100%" src="/assets/icon/photometry/impostazioniStampanteCrhome.png">
        </figure>
        <h4 style="font-weight: bold;margin-bottom: -20px">
            <img style="max-width: 24px;margin-right: 10px"
                 src="/assets/icon/logos/Firefox_logo,_2019.svg">{{'fotometria.firefox'|translate}}</h4>
        <figure>
            <figcaption>
                <h4>{{'fotometria.correctFirefoxSettings.general'|translate}}</h4>
                <p>{{'fotometria.correctFirefoxSettings.printFileSelect'|translate}}</p>
            </figcaption>
            <img style="max-width: 100%" src="/assets/icon/photometry/stampaFirefox1.png">
        </figure>
        <figure>
            <figcaption><h4>{{'fotometria.correctFirefoxSettings.settingPage'|translate}}</h4></figcaption>
            <img style="max-width: 100%" src="/assets/icon/photometry/stampaFirefox2.png">
        </figure>
        <figure>
            <figcaption><h4>{{'fotometria.correctFirefoxSettings.options'|translate}}</h4></figcaption>
            <img style="max-width: 100%" src="/assets/icon/photometry/stampaFirefox3.png">
        </figure>
    </app-container-child>
</app-container>
