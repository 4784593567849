<div class="container">
    <ng-container *ngIf="report">
        <app-template-form subtitle="{{ 'summary' | translate}}" title="{{ 'reports' | translate}}"
                           [informativa]="false">
            <mat-card>
                <mat-card-content>
                    <div class="spacer" *ngIf="report.oggetto">
                        <div><span
                                class="card-label color-primary">{{ 'list.reports.oggetto' | translate}}</span><br>{{report.oggetto}}
                        </div>
                    </div>
                    <div class="spacer">
                        <div><span
                                class="card-label color-primary">{{ 'author' | translate}}</span><br>{{report.nomeAutore}}
                        </div>
                    </div>
                    <div class="spacer">
                        <div><span
                                class="card-label color-primary">{{ 'created' | translate}}</span><br>{{report.createdAt
                            |customDate :'long'|async}}
                        </div>
                    </div>
                    <div class="spacer">
                        <div><span
                                class="card-label color-primary">{{ 'updated' | translate}}</span><br>{{report.updatedAt
                            |customDate :'long'|async}}
                        </div>
                    </div>
                    <div class="spacer">
                        <div>
                            <span class="card-label color-primary">{{ 'state' | translate}}</span>
                            <br>
                            {{('segnalazioniParse.stato.' + report.stato|translate)|notPresentInTraduction:report.stato:'reportSegnalazioni.stato.'}}
                        </div>
                    </div>
                    <form *ngIf="planForm && !author" [formGroup]="planForm">
                        <div class="spacer">
                            <div><span
                                    class="card-label color-primary">{{ 'title' | translate}}</span><br>{{report.titolo}}
                            </div>
                        </div>
                        <div class="spacer">
                            <div><span
                                    class="card-label color-primary">{{ 'priority' | translate}}</span><br>{{priority}}
                            </div>
                        </div>
                        <div class="spacer">
                            <div><span class="card-label color-primary">{{ 'intervent_type' |
                                translate}}</span><br>{{report.tipologiaIntervento}}
                            </div>
                        </div>
                        <div class="spacer">
                            <div><span class="card-label color-primary">{{ 'description' | translate}}</span>
                                <br>
                                <div [innerHTML]="report.descrizione|safeText" class="truncate-text-3-line">
                                </div>
                                <span (click)="$event.stopPropagation();openOtherText()" class="color-primary"
                                      style="cursor: pointer">{{'other'|translate}}...</span>
                            </div>
                        </div>
                        <div class="spacer">
                            <ng-container *ngIf="report.foto&&report.foto.url">
                                <span class="card-label color-primary">{{ 'photo' | translate}}</span><br>
                                <div>
                                    <a href="{{report.foto.url()}}" target="_blank">
                                        <img src="{{report.foto.url()}}" height="100px">
                                    </a>
                                </div>
                            </ng-container>
                        </div>
                        <br>
                        <div style="display: flex; align-items: stretch; justify-content:start; flex-direction: row;">
                            <div class="box-small primary" style="margin-right: 0.2rem; padding: 4px 10px;">
                                <!--                                <a href="{{fileLink}}" target="_blank">-->
                                <a (click)="onPdf()">
                                    <div style="display: flex; justify-content: space-around; align-items: center">
                                        {{ 'download_report_plan' | translate}}
                                        <mat-icon>get_app</mat-icon>
                                    </div>
                                </a>
                            </div>
                            <!--                    </div>-->
                            <div class="box-small primary" style="padding: 0 10px;" (click)="viewMap()">
                                {{ 'view_on_map' | translate}}</div>
                        </div>

                        <!--                        <div fxLayout="row" fxLayoutAlign="start stretch">-->
                        <!--                            <div fxFlex="24">-->
                        <!--                                <div class="box-small primary">-->
                        <!--                                    <a href="{{fileLink}}" target="_blank">-->
                        <!--                                        <div fxLayout="row" fxLayoutAlign="space-around center">-->
                        <!--                                            <div fxFlex="66">-->
                        <!--                                                {{ 'download_report_plan' | translate}}-->
                        <!--                                            </div>-->
                        <!--                                            <div fxFlex="33">-->
                        <!--                                                <mat-icon>get_app</mat-icon>-->
                        <!--                                            </div>-->
                        <!--                                        </div>-->
                        <!--                                    </a>-->
                        <!--                                </div>-->
                        <!--                            </div>-->
                        <!--                            <div fxFlex="3"></div>-->
                        <!--                            <button mat-flat-button color="primary"-->
                        <!--                                    (click)="viewMap()">{{ 'view_on_map' | translate}}</button>-->
                        <!--                        </div>-->
                        <!--                            </mat-card-content>-->
                        <!--                        </mat-card>-->
                    </form>
                </mat-card-content>
            </mat-card>
            <br>

            <form *ngIf="planForm && author" [formGroup]="planForm">
                <app-form-group-template title="{{ 'update_report' | translate}}">
                    <mat-form-field floatLabel="auto" appearance="fill">
                        <mat-label>{{ 'title' | translate}}</mat-label>
                        <input formControlName="titolo" id="titolo" matInput>
                    </mat-form-field>
                    <div>
                        <label class="label">{{ 'priority' | translate}}</label>
                        <div>
                            <mat-radio-group formControlName="priorita" id="priorita" #radioButton>
                                <mat-radio-button style="margin-top: 0.2rem;" value="0">
                                    {{'list.reports.filters.low' | translate}}
                                </mat-radio-button>
                                <mat-radio-button style="margin-top: 0.2rem;" value="1">
                                    {{'list.reports.filters.medium' | translate}}
                                </mat-radio-button>
                                <mat-radio-button style="margin-top: 0.2rem;" value="2">
                                    {{'list.reports.filters.high' | translate}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                    <br>
                    <br>
                    <app-auto-complete-form class="example-full-width" title="{{'intervent_type'|translate}}"
                                            formControlName="tipologiaIntervento" [items]="tipiSegnalazione"
                                            appearance="fill"
                                            [hiddenClearButton]="false">
                        <!--&lt;!&ndash;                        <div suffix>&ndash;&gt;-->
                        <!--&lt;!&ndash;                            <mat-icon>search</mat-icon>&ndash;&gt;-->
                        <!--&lt;!&ndash;                        </div>&ndash;&gt;-->
                    </app-auto-complete-form>
                    <!--                    <mat-form-field class="example-full-width" floatLabel="auto" appearance="fill">-->
                    <!--                        <mat-label>{{'intervent_type' | translate}}</mat-label>-->
                    <!--                        <input formControlName="tipologiaIntervento" id="tipologiaIntervento" matInput>-->
                    <!--                    </mat-form-field>-->
                    <mat-slide-toggle formControlName="privata">{{'privata' | translate}}</mat-slide-toggle>
                    <br>
                    <br>
                    <br>
                    <mat-form-field floatLabel="auto" appearance="fill">
                        <mat-label>{{ 'description' | translate}}</mat-label>
                        <textarea class="scrollbar-visible" formControlName="descrizione" id="descrizione" matInput
                                  rows="12"></textarea>
                    </mat-form-field>
                    <div class="mat-form-field" style="overflow: scroll;">
                        <label class="label"> {{ 'photo' | translate}}</label>
                        <ngx-dropzone [disabled]="planForm.get('foto').disabled"
                                      (change)="onLoadImage($event.addedFiles,'foto')" accept="image/*">
                            <ngx-dropzone-label>
                                {{ 'drag_image' | translate}}
                            </ngx-dropzone-label>
                        </ngx-dropzone>
                        <mat-list role="list">
                            <ng-container *ngIf="planForm.get('foto').value">
                                <div>
                                    <a href="{{planForm.get('foto').value.url}}" target="_blank">
                                        <img src="{{planForm.get('foto').value.url}}" height="30px">
                                    </a>
                                </div>
                                <button class="remove-attachment-button" (click)="deleteSavedFile('foto')">
                                    {{ 'remove_attachement' | translate}}
                                </button>
                            </ng-container>
                        </mat-list>
                    </div>

                </app-form-group-template>
                <div *ngIf="planForm" style="display: flex; align-items: stretch; overflow: scroll;">
                    <button [disabled]="disableFormSegnalazione" color="primary" mat-flat-button
                            style=" font-size: 14px;" (click)="updateReport()">{{ 'save' | translate}}</button>
                    <!--                    <div style="margin-right: 0.2rem; margin-left: 0.2rem; ">-->
                    <div class="box-small primary" style="margin-right: 0.2rem; margin-left: 0.2rem; padding: 0 8px;">

                        <!--                            <a href="{{fileLink}}" target="_blank">-->
                        <a (click)="onPdf()">
                            <div
                                    style="display: flex; justify-content: space-around; align-items: center; word-break: normal;">
                                {{ 'download_report_plan' | translate}}
                                <mat-icon>get_app</mat-icon>
                            </div>
                        </a>
                    </div>
                    <!--                    </div>-->
                    <div class="box-small primary" style=" padding: 0 8px;" (click)="viewMap()"
                         *ngIf="visualezedButtonSeaMap">
                        {{ 'view_on_map' | translate}}</div>
                </div>
            </form>
            <div class="spacer"></div>
            <div style="margin-bottom: 10px;" *ngIf="planForm" fxLayout="row" fxLayoutAlign="start stretch"
                 fxLayoutGap="5px">
                <!--                <div fxFlex *ngIf="visualizedRichiediIntervento" style="padding-right: 0.2rem;">-->
                <!--                    <a (click)="reopenReport()">-->
                <!--                        <div class="box warn">-->
                <!--                            <div class="">-->
                <!--                                <mat-icon>lock_open</mat-icon>-->
                <!--                            </div>-->
                <!--                            <div style="padding: 0.2rem;">-->
                <!--                                {{ 're_open' | translate}}-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                    </a>-->
                <!--                </div>-->
                <button class="action-button" mat-flat-button color="warn" fxFlex *ngIf="visualizedChiudiSegnalazione"
                        (click)="closeReport()">
                    <div class="action-button-icon">
                        <mat-icon>lock</mat-icon>
                    </div>
                    <div class="action-button-text">
                        {{ 'close_report' | translate}}
                    </div>
                </button>
                <button class="action-button" mat-flat-button color="accent" fxFlex *ngIf="visualizedAssengaA"
                        (click)="clickAssignTo()">
                    <div class="action-button-icon">
                        <mat-icon>assignment_ind</mat-icon>
                    </div>
                    <div class="action-button-text">
                        {{ 'assignedTo' | translate}}
                    </div>
                </button>
                <button class="action-button" mat-flat-button color="primary" fxFlex
                        *ngIf="visualizedRichiediIntervento" (click)="searchIntervention()">
                    <div class="action-button-icon">
                        <mat-icon>announcement</mat-icon>
                    </div>
                    <div class="action-button-text">
                        {{ 'intervention' | translate}}
                    </div>
                </button>
                <button class="action-button" mat-flat-button color="primary" fxFlex *ngIf="visualizedPresaInCarico"
                        (click)="takeCharge()">
                    <div class="action-button-icon">
                        <mat-icon>assignment_turned_in</mat-icon>
                    </div>
                    <div class="action-button-text">
                        {{ 'take_charge' | translate}}
                    </div>
                </button>
                <button class="action-button" mat-flat-button color="accent" fxFlex *ngIf="visualizedGeneraReport"
                        [disabled]="load.schedeManutenzione" (click)="openDialog()">
                    <ng-container *ngIf="!load.schedeManutenzione;else loadingSchede">
                        <div class="action-button-icon">
                            <mat-icon>add_comment</mat-icon>
                        </div>
                        <div class="action-button-text">
                            {{ 'generates_report' | translate}}
                        </div>
                    </ng-container>
                    <ng-template #loadingSchede>
                        <mat-spinner class="circle-color-white" style="margin: 0 auto;" diameter="40"></mat-spinner>
                    </ng-template>
                    <!--                    <a (click)="generatesReport()">-->
                    <!--                        <div class="box accent">-->
                    <!--                            <div class="">-->
                    <!--                                <mat-icon>save</mat-icon>-->
                    <!--                            </div>-->
                    <!--                            <div>-->
                    <!--                                {{ 'generates_report' | translate}}-->
                    <!--                            </div>-->
                    <!--                        </div>-->
                    <!--                    </a>-->
                </button>
            </div>
            <mat-divider></mat-divider>
            <!--            <div class="spacer"></div>-->
            <div class="activities-section">
                <h4 class="add-comment-title"> {{'activity' | translate}}</h4>
                <h6 class="add-comment-subtitle">{{'new_activity' | translate}}</h6>

                <mat-radio-group aria-label="Select an option">
                    <mat-radio-button style="margin-top: 0.2rem;" value="1" (click)="onChangeComment('comment')"
                                      [checked]="true">{{'text' | translate}}</mat-radio-button>
                    <mat-radio-button style="margin-top: 0.2rem;" value="2" (click)="onChangeComment('photo')">
                        {{'photo' | translate}}</mat-radio-button>
                    <mat-radio-button style="margin-top: 0.2rem;" value="3" (click)="onChangeComment('file')">
                        {{'attachment' | translate}}
                    </mat-radio-button>
                    <!--                    <mat-radio-button style="margin-top: 0.2rem;" value="4"-->
                    <!--                                      (click)="onChangeComment('schedaManutenzione')">-->
                    <!--                        {{'SchedaManutenzione' | translate}}-->
                    <!--                    </mat-radio-button>-->
                </mat-radio-group>

                <!--                        <app-template-form [informativa]="false">-->
                <form *ngIf="activityForm" [formGroup]="activityForm">
                    <ng-container [ngSwitch]="typeComment">
                        <ng-container *ngSwitchCase="'comment'">
                            <app-form-group-template title="{{ 'comment' | translate}}">
                                <mat-form-field floatLabel="auto" appearance="fill">
                                    <mat-label>{{ 'comment' | translate}}</mat-label>
                                    <textarea
                                            cdkTextareaAutosize
                                            #autosize="cdkTextareaAutosize"
                                            cdkAutosizeMinRows="1"
                                            cdkAutosizeMaxRows="15"
                                            formControlName="comment" matInput></textarea>
                                </mat-form-field>
                            </app-form-group-template>
                        </ng-container>
                        <ng-container *ngSwitchCase="'photo'">
                            <div>
                                <div class="mat-form-field border-left-1-dotted-primary"
                                     style="overflow: scroll; padding-left: 10px;">
                                    <label class="h2"> {{ 'photo' | translate}}</label>
                                    <ngx-dropzone (change)="onLoadImage($event.addedFiles,'foto', 'activity')"
                                                  accept="image/*">
                                        <ngx-dropzone-label>
                                            {{ 'drag_image' | translate}}
                                        </ngx-dropzone-label>
                                    </ngx-dropzone>
                                    <mat-list role="list">
                                        <ng-container *ngIf="activityForm.get('foto').value">
                                            <div>
                                                <a href="{{activityForm.get('foto').value.url}}" target="_blank">
                                                    <img src="{{activityForm.get('foto').value.url}}" height="30px">
                                                </a>
                                            </div>
                                            <button class="remove-attachment-button"
                                                    (click)="deleteSavedFile('foto', 'activity')">
                                                {{ 'remove_attachement' | translate}}
                                            </button>
                                        </ng-container>
                                    </mat-list>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'file'">
                            <div>
                                <div class="mat-form-field border-left-1-dotted-primary"
                                     style="overflow: scroll; padding-left: 10px;">
                                    <label class="h2"> {{ 'attachment' | translate}}</label>
                                    <ngx-dropzone (change)="onLoadImage($event.addedFiles,'allegato', 'activity')"
                                                  accept="application/pdf">
                                        <ngx-dropzone-label>
                                            {{ 'drag_file' | translate}}
                                        </ngx-dropzone-label>
                                    </ngx-dropzone>
                                    <mat-list role="list">
                                        <ng-container *ngIf="activityForm.get('allegato').value">
                                            <div>
                                                <a href="{{activityForm.get('allegato').value.url}}" target="_blank">
                                                    <div fxLayout="row" fxLayoutAlign="start center">
                                                        <mat-icon height="30px">attachment</mat-icon>
                                                        <span style="margin-left: 0.5rem">
                                                            {{getSplitNameForm(activityForm, 'allegato')}}
                                                        </span>
                                                    </div>
                                                    <!--                                            <img src="{{activityForm.get('allegato').value.url}}" height="30px">-->
                                                </a>
                                            </div>
                                            <button class="remove-attachment-button"
                                                    (click)="deleteSavedFile('allegato', 'activity')">
                                                {{ 'remove_attachement' | translate}}
                                            </button>
                                        </ng-container>
                                    </mat-list>
                                </div>
                            </div>
                        </ng-container>
                        <!--                        <ng-container *ngSwitchCase="'schedaManutenzione'">-->
                        <!--                            <ng-container *ngIf="load.schedeManutenzione;else noLoadScheda">-->
                        <!--                                <div class="flex-center-width100">-->
                        <!--                                    <mat-spinner></mat-spinner>-->
                        <!--                                </div>-->
                        <!--                            </ng-container>-->
                        <!--                            <ng-template #noLoadScheda>-->
                        <!--                                <app-form-schedule-maintenance-->
                        <!--                                        style="max-width: 100%"-->
                        <!--                                        [savedCompleted]="saveSchedaCompilata"-->
                        <!--                                        [schedeManutenzione]="schedeManutenzione"-->
                        <!--                                        [myRole]="myRole"-->
                        <!--                                        [abbonamentiAttivi]="tuttiAbbonamenti"-->
                        <!--                                        (savedValue)="saveSchedaManutenzione($event)"-->
                        <!--                                        cssPositionSaveButton="centerPositionButtonSave"-->
                        <!--                                >-->
                        <!--                                </app-form-schedule-maintenance>-->
                        <!--                            </ng-template>-->
                        <!--                        </ng-container>-->
                    </ng-container>
                    <div class="spacer">
                        <button mat-flat-button color="primary" [disabled]="disableSaveComment"
                                (click)="onSaveActivity()">{{ 'save' | translate}}</button>
                    </div>
                </form>

                <mat-divider></mat-divider>
                <br>
                <span class="card-label color-primary">{{ 'list_activity' | translate}}</span>
                <br>
                <app-list-activity [activities]="activities"></app-list-activity>
            </div>

            <!--                        </app-template-form>-->

            <!--                <div class="spacer"></div>-->

        </app-template-form>
    </ng-container>
</div>
<div style="position: fixed;bottom: 5px;right: 5px;z-index: 10000;">
    <div class="flex-around-center w100">

        <app-button-spinner [loading]="disableButton.print" [progress]="progress.print" type="mat-flat-button"
                            class="margin-button">
            <button [disabled]="disableButton.print" mat-flat-button color="accent" type="button"
                    (click)="printReports()">
                <mat-icon>print</mat-icon>
            </button>
        </app-button-spinner>
    </div>
</div>

<ng-template #extendedDescription>
    <mat-dialog-content>
        <span class="card-label color-primary">{{ 'description' | translate}}</span>
        <br>
        <div [innerHTML]="report.descrizione|safeText">
        </div>
    </mat-dialog-content>
</ng-template>