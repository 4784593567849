import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {PuntiLuceParse} from "../../../models/PuntiLuce.Parse";
import {CircuitiParse} from "../../../models/Circuiti.Parse";
import {ArredoUrbanoParse} from "../../../models/ArredoUrbano.Parse";
import {TranslateService} from "@ngx-translate/core";
import {UtilsService} from "../../../providers/services/utils.service";
import {SchedaManutenzioneParse} from "../../../models/SchedaManutenzione.Parse";
import {
    arrayIsSet,
    getArrayToRemveItem,
    getItemInArrayByKeyValue, isNotNullOrUndefined,
    stringIsSet,
    valuePeriodOne
} from "../../../models/Models";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {EMPTY} from "rxjs";
import {ReportsService} from "../../../providers/services/reports.service";

@Component({
    selector: 'app-batch-editor-assign-schede-manutenzione',
    templateUrl: './batch-editor-assign-schede-manutenzione.component.html',
    styleUrls: ['./batch-editor-assign-schede-manutenzione.component.scss']
})
export class BatchEditorAssignSchedeManutenzioneComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<BatchEditorAssignSchedeManutenzioneComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { lightPoints: PuntiLuceParse [], circuits: CircuitiParse[], arrediUrbani: ArredoUrbanoParse[], schedeManutenzione: SchedaManutenzioneParse[], dialog },
        private translateService: TranslateService,
        private fb: UntypedFormBuilder,
    ) {
        this.formFilter = this.fb.group({progetti: null})

    }

    public displayedColumns = [];
    public isPossibleValues = [];
    public typeArray = [];
    public dataSource = [];
    public formFilter: UntypedFormGroup;
    public formDate: UntypedFormGroup;
    public itemsAutoCompleteFilter = []


    selectedSchedeManutenzione: SchedaManutenzioneParse[] = []
    public titleTraduction = '';
    private valuePeriodOne = valuePeriodOne

    ngOnInit(): void {
        if (this.isLightPoints) {
            this.typeArray = ['schedeManutenzione']
            this.displayedColumns = ['circuito', 'indirizzo', 'targa', 'schedeManutenzione'];
            this.dataSource = this.lightPoints;
            this.titleTraduction = 'PuntiLuce'
        } else if (this.isCircuits) {
            this.typeArray = ['schedeManutenzione']
            this.displayedColumns = ['numeroQuadro', 'indirizzo', 'POD', 'schedeManutenzione'];
            this.dataSource = this.circuits;
            this.titleTraduction = 'Circuiti'
        } else {
            this.displayedColumns = ['tipologia'];
            this.isPossibleValues = ['tipologia'];
            this.dataSource = this.arrediUrbani;
            this.titleTraduction = 'arredoUrbano'
        }
        const itemsAutoComplete = []
        const objFormSchedeDate = {};
        this.schedeManutenzione.forEach(scheda => {
            if (scheda.isOnlyOnce) {
                objFormSchedeDate[scheda.objectId] = scheda.dataDiScadenza
            }
            if (stringIsSet(scheda.name)) {
                const isIncludes = getItemInArrayByKeyValue(itemsAutoComplete, scheda.objectId, 'valueForm')
                if (!isIncludes) {
                    const dataForm = {
                        valueForm: scheda.objectId,
                        html: scheda.name
                    }
                    itemsAutoComplete.push(dataForm)
                }
            }
        })
        this.itemsAutoCompleteFilter = itemsAutoComplete;
        this.formDate = this.fb.group(objFormSchedeDate)
    }

    getPathTraductionWithKey(key) {
        if (this.isLightPoints) {
            return 'dashboard_sidenav.PuntiLuce.' + key + '.title'
        } else if (this.isCircuits) {
            return 'dashboard_sidenav.Circuiti.' + key + '.title'
        } else {
            return 'dashboard_sidenav.ArredoUrbano.' + key + '.title'
        }
    }

    getPathTraductionWithClassName() {
        if (this.isLightPoints) {
            return 'dashboard_sidenav.PuntiLuce.'
        } else if (this.isCircuits) {
            return 'dashboard_sidenav.Circuiti.'
        } else {
            return 'dashboard_sidenav.ArredoUrbano.'
        }
    }

//
    get isLightPoints() {
        return isNotNullOrUndefined(this.data.lightPoints)
    }

    get lightPoints(): PuntiLuceParse[] {
        return this.data.lightPoints
    }


    get isCircuits() {
        return isNotNullOrUndefined(this.data.circuits)
    }

    get circuits(): CircuitiParse[] {
        return this.data.circuits;
    }

    get isArredoUrbano() {
        return isNotNullOrUndefined(this.data.arrediUrbani)
    }

    get arrediUrbani(): ArredoUrbanoParse[] {
        return this.data.arrediUrbani;
    }

    get isSetDataSource() {
        return Array.isArray(this.dataSource) && this.dataSource.length > 0
    }

    get schedeManutenzione(): SchedaManutenzioneParse[] {
        return this.data.schedeManutenzione
    }


    get closedValue() {
        const calendari: { maintenanceDate: Date, schedaManutenzione: SchedaManutenzioneParse }[] = [];
        const schedeSelezionate: SchedaManutenzioneParse[] = [];
        this.selectedSchedeManutenzione.forEach(
            scheda => {
                if (!scheda.isOnlyOnce) {
                    schedeSelezionate.push(scheda)
                } else if (this.getCaelndario(scheda)) {
                    const obj = {
                        maintenanceDate: this.getCaelndario(scheda),
                        schedaManutenzione: scheda,
                    }
                    calendari.push(obj)
                }
            }
        )
        return {
            selectedSchedeManutenzione:
                {remove: false, items: schedeSelezionate},
            calendariDaCreare: {items: calendari}
        }
    }

    get removeValue() {
        return {
            selectedSchedeManutenzione:
                {remove: true, items: this.selectedSchedeManutenzione.filter(scheda => !scheda.isOnlyOnce)}
        }
    }


    get confirmDelete() {
        if (this.data.dialog) {
            let message = this.translateService.instant('scheduleMaintenance.removeScheduleToElement.init');
            message += this.translateService.instant(this.titleTraduction);
            message += this.translateService.instant('scheduleMaintenance.removeScheduleToElement.end');
            return this.data.dialog.openDialogForDelete(message, 'center');
        } else {
            return EMPTY;
        }
    }

    removeSchedule() {
        this.confirmDelete
            .subscribe(result => {
                if (result) {
                    this.dialogRef.close(this.removeValue)
                }
            })
    }

    closeDialog(confirm) {
        if (confirm) {
            this.dialogRef.close(this.closedValue)
        } else {
            this.dialogRef.close(undefined)
        }
    }

    getValueCircuito(value) {
        const translate = this.translateService.instant('filterPage.nessunQuadro')
        return isNotNullOrUndefined(value) && isNotNullOrUndefined(value.numeroQuadro) ? value.numeroQuadro : translate;
    }

    getValue(value, key) {
        if (this.isPossibleValues.includes(key)) {
            const translate = this.translateService.instant(this.getPathTraductionWithClassName() + key + '.possibleValues.' + value[key]);
            return translate.includes(this.getPathTraductionWithClassName()) ? value[key] : translate;
        } else if (this.typeArray.includes(key)) {
            return arrayIsSet(value[key]) ? value[key].length : 0
        } else {
            const translate = this.translateService.instant('NaN');
            return isNotNullOrUndefined(value[key]) ? value[key] : translate;
        }
    }


    clickSchedaManutenzione(schedaManutenzione: SchedaManutenzioneParse) {
        if (this.isSelectedSchedaManutenzione(schedaManutenzione)) {
            this.selectedSchedeManutenzione = getArrayToRemveItem(this.selectedSchedeManutenzione, schedaManutenzione.objectId, 'objectId')
        } else {
            this.selectedSchedeManutenzione.push(schedaManutenzione)
        }

    }


    isSelectedSchedaManutenzione(schedaManutenzione: SchedaManutenzioneParse) {
        return isNotNullOrUndefined(getItemInArrayByKeyValue(this.selectedSchedeManutenzione, schedaManutenzione.objectId, 'objectId'))
    }

    predicateFunction(schedaManutenzione: SchedaManutenzioneParse, value) {
        if (stringIsSet(value)) {
            const getLowerCase = (value) => (stringIsSet(value)) ? value.toLowerCase() : '';
            const lowerName = getLowerCase(schedaManutenzione.name);
            return (lowerName.trim().length > 0) ? lowerName.includes(getLowerCase(value)) : false;
        } else {
            return true
        }
    }


    get breakPointFilterTable() {
        // se viene modificato il breack point bisogno contrallare la mediaquery all interno di questo component nel caso di passaggio a 100%
        return [
            {
                maxWidth: 1000,
                sx: {percent: 80, px: 470},
                dx: {percent: undefined, px: undefined},
            }, {
                maxWidth: 800,
                sx: {percent: 50, px: undefined},
                dx: {percent: 50, px: undefined}
            }, {
                maxWidth: 600,
                sx: {percent: 100, px: undefined},
                dx: {percent: 100, px: undefined}
            }
        ]
    }


    schedaIsInElement(element: CircuitiParse | PuntiLuceParse): boolean {
        if (arrayIsSet(element.schedeManutenzione) && arrayIsSet(this.selectedSchedeManutenzione)) {
            const idSchede = (arrayIsSet(element.schedeManutenzione)) ? element.schedeManutenzione.map(scheda => scheda.objectId) : [];
            return this.selectedSchedeManutenzione.findIndex(schedaSelezionata => idSchede.includes(schedaSelezionata.objectId)) >= 0;
        } else {
            return false;
        }

    }


    getCaelndario(schedaDiManutnezione: SchedaManutenzioneParse) {
        if (this.formDate.get(schedaDiManutnezione.objectId)) {
            return this.formDate.get(schedaDiManutnezione.objectId).value
        }
    }


    dataDiScadenza(event, schedaDiManutnezione: SchedaManutenzioneParse) {
        if (this.formDate.get(schedaDiManutnezione.objectId)) {
            this.formDate.get(schedaDiManutnezione.objectId).setValue(event)
        }

        if (!this.isSelectedSchedaManutenzione(schedaDiManutnezione)) {
            this.clickSchedaManutenzione(schedaDiManutnezione);
        }
    }

    removedSchedeManutenzioneLength() {
        const diff = this.selectedSchedeManutenzione.filter(scheda => !scheda.isOnlyOnce).length
        return diff > 0 ? diff : 0
    }

}


