<div style="display: flex;align-items: center;justify-content: center;flex-wrap: wrap;flex-flow: column">
    <div>
        <button style="margin-right: 10px"
                mat-flat-button color="primary" (click)="closeDialog()">
            <mat-icon>clear</mat-icon>
        </button>
        <button *ngIf="!loaderPrint;else printing"
                mat-flat-button color="primary" (click)="callPrint()" [disabled]="disablePrint">
            <mat-icon>print</mat-icon>
        </button>
        <ng-template #printing>
            <div style="width: 50px">
                <mat-spinner diameter="40"></mat-spinner>
            </div>
        </ng-template>
    </div>
    <app-container-a4 class="printableArea">
        <div style="
  display: -webkit-box;
display: -moz-box;
display: -webkit-flex;
display:-ms-flexbox;
display:-webkit-flex;
display:flex;
align-items: center;justify-content: space-around;flex-wrap: wrap;flex-direction: column;height: 250mm">
            <header style="
            border: 1px solid rgba(0, 0, 0, 0.15);
            border-radius:3mm;
            display: flex;justify-content: space-around;
            align-items: center;
            flex-wrap: wrap;
            flex-flow: row;
            width: 180mm">
                <img class="img" [src]="getLogoUrl()" style="width: 30mm;padding: 4mm"
                     alt="logo" *ngIf="!!data.organizzazione.logo">
                <h2>{{getOrganizzazioneName()}}</h2>
            </header>
            <h2>{{'lightingCalculation.calc'|translate}}</h2>
            <h3 style="margin-top:-15mm;">{{projectName}}</h3>
            <h3 style="margin-top:-15mm;">{{data.strada.nome}}</h3>
            <div #imagePreview>
                <app-street-preview
                        [widthSVG]="imagePreview.clientWidth"
                        [heigthSVG]="imagePreview.clientHeight"
                        [printPreview]="true"
                        style="width: 180mm"
                        [interdistance]="data.strada.interdistanza"
                        [layoutItems]="data.strada.layout" [lamps]="data.strada.lampade">
                </app-street-preview>
            </div>
            <footer style="
            width: 150mm;
            margin-bottom: -10mm;
            display: -webkit-box;
display: -moz-box;
display: -webkit-flex;
display:-ms-flexbox;
display:-webkit-flex;
display:flex;
            align-items: center;
            justify-content: space-around;
            text-align: center;
            border: 1px solid rgba(0, 0, 0, 0.15);
            border-radius:3mm;">
                <img class="img" src="/assets/logo.png" style="height: 10mm;;padding: 4mm"
                     alt="huna">
                <b>{{today |customDate :'longDate'|async}}</b>
            </footer>
        </div>
    </app-container-a4>
    <app-container-a4
            class="printableArea"
            [nowDate]="today"
            [logoAzienda]="getLogoUrl()"
            [nomeAzienda]="getOrganizzazioneName()"
            [projectName]="projectName"
            page="auto">
        <div style="
    font-family: Roboto, 'Helvetica Neue', sans-serif;
  background: white;
  display: -webkit-box;
display: -moz-box;
display: -webkit-flex;
display:-ms-flexbox;
display:-webkit-flex;
display:flex;
  align-items: center;
  flex-flow: column;
  -webkit-flex-flow: column;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-around;
  width: 200mm;
">
            <h2 style="margin-left:60mm;margin-right:60mm;margin-bottom: 20mm">{{'fotometria.fotometry'|translate}}</h2>
            <img
                    [src]="imageInForometry" alt="image fotometry" style="width: 50mm;height:50mm;margin: 3mm">
            <div style="height:100mm;width: 150mm">
                <app-huna-print-table
                        widthTable="150mm"
                        [verticalTitle]="nameBulb"
                        [orientation]="'vertical'"
                        [dataSet]="arrayTableFotometry">
                </app-huna-print-table>
            </div>
        </div>
    </app-container-a4>
    <app-container-a4
            class="printableArea"
            [nowDate]="today" [logoAzienda]="getLogoUrl()"
            [nomeAzienda]="getOrganizzazioneName()"
            [projectName]="projectName"
            page="auto">
        <div style="
        font-family: Roboto, 'Helvetica Neue', sans-serif;
        background: white;
        display: -webkit-box;
        display: -moz-box;
        display: -webkit-flex;
        display:-ms-flexbox;
        display:-webkit-flex;
        display:flex;
        align-items: center;
        flex-flow: column;
        -webkit-flex-flow: column;
        -ms-flex-direction: column;
        flex-wrap: wrap;
       -webkit-flex-wrap: wrap;
       -ms-flex-wrap: wrap;
       flex-direction: column;
        justify-content: space-around;
        width: 200mm;
    ">
            <h2>{{'fotometria.geometryInformation.title'|translate}}</h2>
            <img
                    src="/assets/icon/photometry/allGeometry.svg"
                    style="height: 230mm;max-width:200mm;">
        </div>
    </app-container-a4>
    <app-container-a4
            class="printableArea"
            [nowDate]="today" [logoAzienda]="getLogoUrl()"
            [nomeAzienda]="getOrganizzazioneName()"
            [projectName]="projectName"
            page="auto">
        <h2 style="width: 200mm;text-align: center">{{'fotometria.geometryInformation.streetGeometry'|translate}}</h2>
        <div style="">
            <h4 style="text-align: center">{{'fotometria.referencesStreetElements'|translate}}</h4>
            <figure style="width: 60mm;">
                <app-street-preview
                        [generateLabelLayout]="true"
                        [interdistance]="larghezzaTotale"
                        [layoutItems]="data.strada.layout"
                        [lamps]="[]">
                </app-street-preview>
            </figure>
        </div>
        <div style="width: 180mm"></div>
        <div style="width: 160mm">
            <h4 style="margin-bottom:-3mm;text-align: center">{{'fotometria.referencesIlluminatingElements'|translate}}</h4>
            <ng-container *ngFor="let lamps of splitLamps">
                <figure style="width: 150mm;height: 60mm">
                    <app-street-preview
                            [heigthSVG]="'60mm'"
                            [widthSVG]="'150mm'"
                            [generateLabelLamp]="true"
                            [generateMisureInizialAndFinal]="lamps.generateMisureInizialAndFinal"
                            [distanceInizialPosition]="lamps.distanceInizialPosition"
                            [interdistance]="lamps.interdistanza"
                            [layoutItems]="data.strada.layout"
                            [lamps]="lamps.lampade">
                    </app-street-preview>
                </figure>
            </ng-container>
        </div>
        <app-huna-print-table
                widthTable="150mm"
                [verticalTitle]="'fotometria.parametriStrada'"
                [orientation]="'vertical'"
                [dataSet]="parametriStrada">
        </app-huna-print-table>
    </app-container-a4>
    <app-container-a4 [nowDate]="today" [logoAzienda]="getLogoUrl()"
                      [nomeAzienda]="getOrganizzazioneName()"
                      [projectName]="projectName"
                      page="auto" class="printableArea">
        <h2 style="width: 200mm;text-align: center">{{'fotometria.geometryInformation.streetGeometry'|translate}}</h2>
        <app-huna-print-table
                widthTable="150mm"
                [orientation]="'horizontal'"
                [dataSet]="dataTableBulbsDescription">
        </app-huna-print-table>
        <app-huna-print-table
                widthTable="150mm"
                [orientation]="'horizontal'"
                [dataSet]="dataForSteet">
        </app-huna-print-table>
    </app-container-a4>
    <!--    vengono generate un numero di pagine variabile in base al numero di tabelle-->
    <app-container-a4
            *ngFor="let pageReseltsTables of pagesResultsTables; first as isFirst;"
            [nowDate]="today" [logoAzienda]="getLogoUrl()"
            [nomeAzienda]="getOrganizzazioneName()"
            [projectName]="projectName"
            page="auto" class="printableArea"
            style="align-items: center;justify-content: flex-start">
        <h2 style="width: 199mm;text-align: center">{{'fotometria.result'|translate}}</h2>
        <div style="margin-top:-50mm;position:relative;width: 120mm;height: 60mm" *ngIf="isFirst">
            <app-light-result
                #layoutStrada
                [resolution]="resolutionLightCalc"
                class="changePosition asd"
                style="position: absolute;
                                left: calc(50% - 60mm);
                                width: 120mm;"
                [street]="data.strada"
                [result]="data.risutatiCalcoloIlluminotecnico"
            >
            </app-light-result>
        </div>
        <div style="display: flex;flex-direction: row;flex-wrap: wrap;align-items:flex-start;justify-content:space-around;width: 199mm">
            <app-huna-print-table *ngFor="let table of pageReseltsTables"
                                  style="margin: 5px"
                                  widthTable="120mm"
                                  [isInnerHtml]="true"
                                  [verticalTitle]="table.name"
                                  [orientation]="'vertical'"
                                  [dataSet]="table.dataSet">
            </app-huna-print-table>
        </div>
    </app-container-a4>
    <app-container-a4
            *ngFor="let dataPage of getPagesDataTableLuminanzaOilluminamento(10)"
            [nowDate]="today" [logoAzienda]="getLogoUrl()"
            [nomeAzienda]="getOrganizzazioneName()"
            [projectName]="projectName"
            page="auto" class="printableArea">
        <h2 style="width: 200mm;text-align: center">{{'fotometria.calcTable'|translate}}</h2>
        <ng-container *ngFor="let table of dataPage">
            <div style="width:190mm;margin-left:5mm;display: flex;align-items: center;justify-content: center;flex-wrap: wrap;flex-direction: row">
                <h4 style="margin-bottom: 2mm">{{table.dataTable.titlePage}}</h4>
                <app-huna-print-table
                        *ngIf="table.osservatore"
                        [orientation]="'horizontal'"
                        widthTable="190mm"
                        [dataSet]="table.osservatore.data">
                </app-huna-print-table>
                <app-huna-print-table
                        widthTable="190mm"
                        [verticalFirstRowIsBold]="true"
                        [verticalTitle]="table.dataTable.title"
                        [orientation]="'vertical'"
                        [dataSet]="table.dataTable.data">
                </app-huna-print-table>
            </div>
        </ng-container>
    </app-container-a4>
    <!--  visualized for pdf print -->
    <app-container-a4 style="opacity: 0">
        <div style="position: absolute;z-index: -100">
            <div style="position: relative;">
                <figure style="width: 300mm;" #immagineResultP1>
                    <app-street-preview
                            [widthSVG]="immagineResultP1.clientWidth+'mm'"
                            [heigthSVG]="immagineResultP1.clientHeight+'mm'"
                            [printPreview]="false"
                            style="width: 300mm;"
                            [interdistance]="data.strada.interdistanza"
                            [layoutItems]="data.strada.layout" [lamps]="data.strada.lampade">
                    </app-street-preview>
                </figure>
                <img #immagineResultP2
                     style="
                             position: absolute;
                             top:0;
                             left: 0;
                             width: 300mm;
                             transform: translateY(25%);
                             z-index: 100" [src]="url" alt="over light"
                     *ngIf="!!url">
            </div>
        </div>
        <div #imageWithLabel style="width: 200mm;z-index: -100">
            <app-street-preview
                    [widthSVG]="imageWithLabel.clientWidth+'mm'"
                    [heigthSVG]="imageWithLabel.clientHeight+'mm'"
                    [generateLabelLayout]="true"
                    [interdistance]="larghezzaTotale"
                    [layoutItems]="data.strada.layout"
                    [lamps]="[]">
            </app-street-preview>
        </div>
        <ng-container *ngFor="let lamps of splitLamps; let i = index">
            <figure style="width: 700px;z-index: -100" #imageLampsLabels>
                <app-street-preview
                        [widthSVG]="imageLampsLabels.clientWidth"
                        [heigthSVG]="imageLampsLabels.clientHeight"
                        [generateLabelLamp]="true"
                        [generateMisureInizialAndFinal]="lamps.generateMisureInizialAndFinal"
                        [distanceInizialPosition]="lamps.distanceInizialPosition"
                        [interdistance]="lamps.interdistanza"
                        [layoutItems]="data.strada.layout"
                        [lamps]="lamps.lampade">
                </app-street-preview>
            </figure>
        </ng-container>
        <img #imageDefinition
             src="/assets/icon/photometry/allGeometry.svg"
             style="height: 460mm;max-width:400mm;">
    </app-container-a4>
</div>