import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    OnChanges,
    ViewChild,
    ChangeDetectorRef,
    SimpleChanges, Output
} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ProgrammiMvService} from '../../../providers/services/programmi-mv.service';
import {DialogPopUpService} from '../../../providers/services/dialog-pop-up.service';
import {AlertService} from '../../../providers/services/alert.service';
import {
    arrayIsSet,
    chiaviFiltri,
    customThemeNgxDataPicker, daysInWeek,
    isNotNullOrUndefined,
    stringIsSet
} from '../../../models/Models';
import {ProgrammiMVParse} from "../../../models/ProgrammiMV.Parse";
import {valueActionClickButton, ValueActionClickButtonType} from "../form-light-mate/form-light-mate.component";
import {RemoteControlService, TypeVersioneProtocolloLM} from "../../../providers/services/remote-control.service";
import {IoTDevicesParse} from "../../../models/IoTDevices.Parse";
import {TLC_ProfiloFunzionamentoParse} from "../../../models/TLC_ProfiloFunzionamento.Parse";
import {SettaggiTlcNodoParse} from "../../../models/SettaggiTlcNodo.Parse";
import {EMPTY, Subject} from "rxjs";
import {switchMap} from "rxjs/operators";


@Component({
    selector: 'app-light-mate-group',
    templateUrl: './light-mate-group.component.html',
    styleUrls: ['./light-mate-group.component.scss']
})


export class LightMateGroupComponent implements OnInit, OnDestroy, OnChanges {


    lastProgrammiMVParse: ProgrammiMVParse[]
    isLinear = false;
    formGroup: UntypedFormGroup[] = [];
    public istanzaSearchWrongLight;
    public istanzaResetDefault;
    public customThemNgxDataPicker = customThemeNgxDataPicker;
    @Input()
    public circuitoId;
    @Input() public lightPointId;
    @Input() public deviceId;
    @Input() public groupId;
    @Input() public device: IoTDevicesParse;
    @Input() public settaggiTlcNodo: SettaggiTlcNodoParse[];
    @Input() public externalLoading: boolean = false;

    @Input() public yesGroupId;
    @Input() public isLightPoint = false;
    @Input() public profilesTlc: TLC_ProfiloFunzionamentoParse[];
    @Output() action = new Subject<{ action: 'remove' | 'add', settaggiTlcNodo?: SettaggiTlcNodoParse[] }>()
    public typeForm;
    public typeFormValue = TypeVersioneProtocolloLM;
    public loadingSearchWrong = false;
    public loadingResetFactory = false;

    public date = '1597190400000';
    public chiaviFiltri = chiaviFiltri;

    public loading: { searchWrong?: boolean, resetFactory?: boolean, general?: boolean } = {};
    public loadingAll = false;

    // tslint:disable-next-line:variable-name
    constructor(private myDialog: DialogPopUpService,
                private programmiMvService: ProgrammiMvService,
                private remoteService: RemoteControlService,
                private alertService: AlertService,
                private translateService: TranslateService,
                private cd: ChangeDetectorRef) {
    }


    ngOnInit() {

    }


    ngOnChanges(changes: SimpleChanges) {
        if (changes.device != null) {
            if (changes.device.currentValue != null && this.device.versioneProtocolloLM === TypeVersioneProtocolloLM.prt_v1) {
                this.typeForm = this.device.versioneProtocolloLM
            } else {
                this.typeForm = undefined;
                this.getLastProgrammaMV();
            }
        }

    }


    public getLastProgrammaMV() {
        this.loadingAll = true;
        let programmiMv$;
        if (this.isLightPoint && this.circuitoId != this.chiaviFiltri.nessunQuadro) {
            programmiMv$ = this.programmiMvService.getProgrammiMvWithDeviceIdGruppoId(this.circuitoId, this.deviceId, this.groupId);
        } else {
            programmiMv$ = this.programmiMvService.getProgrammiMv(this.circuitoId);
        }
        programmiMv$.subscribe((ultimiProgrammi) => {
            this.lastProgrammiMVParse = ultimiProgrammi
            this.loadingAll = false;
            this.cd.detectChanges();
        }, error => {
            this.lastProgrammiMVParse = undefined
            this.alertService.error(error)
            this.loadingAll = false;
            this.cd.detectChanges();
        });

    }


    ngOnDestroy() {
        if (this.istanzaSearchWrongLight) {
            this.istanzaSearchWrongLight.unsubscribe();
        }
        if (this.istanzaResetDefault) {
            this.istanzaResetDefault.unsubscribe();
        }
    }


    public searchWrongLight(lineAndGroupId) {
        this.loadingSearchWrong = true;
        const message = this.translateService.instant('lightMate.procededWrongLight');
        this.istanzaSearchWrongLight = this.myDialog.openDialogForDelete(message, "center").subscribe((risposta) => {
            this.loadingSearchWrong = false;
            if (risposta) {
                const daSalvare: { name: string, value }[] = lineAndGroupId.concat([{
                    name: 'modalitaManutenzione',
                    value: true
                }]);
                this.programmiMvService.updateTableProgrammiMV(daSalvare, this.circuitoId).subscribe((response) => {
                    const message = this.translateService.instant('lightMate.operazioneRiuscita');
                    this.alertService.success(message);
                }, (error) => this.alertService.error(error));
            }
        }, error => {
            this.alertService.error(error);
            this.loadingSearchWrong = false;
        });
    }

    public reseteToDefualt(lineAndGroupId) {
        this.loadingResetFactory = true;
        const message = this.translateService.instant('lightMate.resetDefault');
        this.istanzaResetDefault = this.myDialog.openDialogForDelete(message, "center").subscribe((risposta) => {
            this.loadingResetFactory = false;
            if (risposta) {
                const daSalvare: { name: string, value }[] = lineAndGroupId.concat([{
                    name: 'resetToDefault',
                    value: true
                }]);
                this.programmiMvService.updateTableProgrammiMV(daSalvare, this.circuitoId).subscribe((response) => {
                    const message = this.translateService.instant('lightMate.operazioneRiuscita');
                    this.alertService.success(message);
                }, error => {
                    this.alertService.error(error);
                    this.loadingResetFactory = false;
                });
            }
        });
    }


    saveOnDatabase(datiDaSalvare) {
        this.loading.general = true;
        if (datiDaSalvare && datiDaSalvare.versioneProtocollo === TypeVersioneProtocolloLM.prt_v1) {
            let addSettaggi$;
            if (stringIsSet(this.lightPointId)) {
                addSettaggi$ = this.remoteService.addSettaggiTlcNodo(datiDaSalvare.profileTlc.objectId, datiDaSalvare.days, [this.lightPointId], undefined)
            } else {
                addSettaggi$ = this.remoteService.addSettaggiTlcNodo(datiDaSalvare.profileTlc.objectId, datiDaSalvare.days, undefined, [this.circuitoId])
            }
            addSettaggi$.subscribe((response) => {
                this.action.next({action: 'add', settaggiTlcNodo: response})
                this.loading.general = false;
                this.cd.detectChanges();
                const message = this.translateService.instant('lightMate.operazioneRiuscita');
                this.alertService.success(message);
            }, (error) => {
                this.alertService.error(error);
                this.loading.general = false;
            });
        } else {
            this.programmiMvService.updateTableProgrammiMV(datiDaSalvare, this.circuitoId).subscribe((response) => {
                this.loading.general = false;
                const message = this.translateService.instant('lightMate.operazioneRiuscita');
                this.alertService.success(message);
                this.getLastProgrammaMV();
            }, (error) => {
                this.alertService.error(error);
                this.loading.general = false;
            });
        }
    }


    clickAction(event: { key: ValueActionClickButtonType, lineAndGroupId }) {
        if (event.key === valueActionClickButton.searchBrokenLamp) {
            this.searchWrongLight(event.lineAndGroupId);
        } else if (event.key == valueActionClickButton.resetFactoryDevice) {
            this.reseteToDefualt(event.lineAndGroupId);
        }
    }

    destroyCalendar(settaggio: SettaggiTlcNodoParse) {
        this.loading.general = true;
        this.myDialog.openDialogForDelete(this.translateService.instant('pageProfile.confirmDeleteWeek'), 'center').pipe(
            switchMap((choose) => {
                if (choose) {
                    return this.remoteService.deleteSettaggiTlcNodo([settaggio.objectId])
                } else {
                    return EMPTY;
                }
            })
        )
            .subscribe((removeds) => {
                this.action.next({action: 'remove', settaggiTlcNodo: removeds});
                this.loading.general = false;
            }, error => {
                this.alertService.error(error);
                this.loading.general = false;
            }, () => {
                this.loading.general = false;
            })
    }


    predicateSettaggiTlcNodo(settaggi: SettaggiTlcNodoParse[]): { settaggiTlcNodo?: SettaggiTlcNodoParse, traducution?: string, day?: number }[] {
        const values = [];
        if (arrayIsSet(settaggi)) {

            let i = 0;
            while (i < 7) {
                const obj = {};
                const index = settaggi.findIndex(settaggio => settaggio.giornoSettimana == i);
                if (index >= 0) {
                    obj['settaggiTlcNodo'] = settaggi[index];
                } else {
                    obj['traduction'] = '100%'
                    obj['day'] = i;
                }
                values.push(obj);
                i++;
            }
        } else {
            let i = 0;
            while (i < 7) {
                const obj = {};
                obj['traduction'] = '100%'
                obj['day'] = i;
                values.push(obj);
                i++;
            }
        }
        values.sort((a, b) => {
            const dayA = a.settaggiTlcNodo ? a.settaggiTlcNodo.giornoSettimana : a.day;
            const dayB = b.settaggiTlcNodo ? b.settaggiTlcNodo.giornoSettimana : b.day;
            let moveToSundayToLastA = dayA;
            let moveToSundayToLastB = dayB;
            if (moveToSundayToLastA == daysInWeek.sunday) {
                moveToSundayToLastA = daysInWeek.saturday + 1
            }

            if (moveToSundayToLastB == daysInWeek.sunday) {
                moveToSundayToLastB = daysInWeek.saturday + 1
            }
            return moveToSundayToLastA - moveToSundayToLastB;
        })
        return values

    }

// ricevuto + eseguito pallino verde
// ricevuto + arancio in esecuzione in attesa di conferma
// valido + rosso
// durante

    //spostare il tab energy after lightmate
    //inserire un flag su progetto

}
