<app-open-new-tab whatOpen="report"
                  [openedList]="openedReportsList"
                  (updateList)="refreshData()"
                  (itemModified)="itemModified($event)"
                  [indexTab]="indexTab" [forceUpdate]="forceUpdateTab">
    <div class="container">
        <app-loader [loaderInput]="loading||loadFilter"></app-loader>
        <ng-container *ngIf="!loading && !error">
            <app-list (changePage)="onChangePage($event)"
                      (changeSort)="onChangeSort($event)"
                      (filterDate)="onFilterDate($event)"
                      (changeFilters)="addOrSubstituionFilter($event)"
                      (open)="openReport($event)"
                      (resolved)="onResolved()"
                      (search)="onSearch($event)"
                      (updateElement)="onUpdateElement({objectId:$event.objectId,titolo:$event.titolo,priority:$event.priorita})"
                      *ngIf="data$|async as data"
                      [config]="listConfig"
                      [data]="data"
                      [getValueFunction]="getValueObject"
            >

                <app-list-custom-filter [formGroup]="formGroupFiter">
                    <label class="label">{{'filters'|translate}}</label>
                    <div class="w100 flex-start-center">
                        <app-chips-multiselect-form-field [possibleValues]="keysAcceptedFilter"
                                                          [showMenu]="false"
                                                          formControlName="acceptedElements"
                        >
                        </app-chips-multiselect-form-field>
                        <div style="margin: 20px">
                            <mat-checkbox
                                    formControlName="telecontrollo">
                                {{'list.reports.filters.remote_control'|translate}}
                            </mat-checkbox>
                        </div>
                    </div>
                </app-list-custom-filter>
            </app-list>
            <ng-container *ngIf="!showTable && !error">
                <div fxLayout="column" [style.height]="'100%'" fxLayoutAlign="left left" class="cmp">
                    <app-template-form title="{{'no_reports' | translate}}" [informativa]="false">
                    </app-template-form>
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="error">
            <div fxLayout="column" [style.height]="'100%'" fxLayoutAlign="left left" class="cmp">
                <app-template-form title="{{'error' | translate}}!" [informativa]="false">
                    <div class="spacer">{{'error_reports' | translate}}.<br>
                        {{'platform_mainten' | translate}}
                        <a class="email" style="text-decoration: underline" href="mailto:info@huna.io"
                           label="Email">info@huna.io</a>
                    </div>
                </app-template-form>
            </div>
        </ng-container>
    </div>
</app-open-new-tab>

