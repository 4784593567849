<app-open-new-tab [openedList]="openedStreetList"
                  whatOpen="street"
                  (itemModified)="itemModified($event)"
                  [forceUpdate]="forceUpdateTab"
                  [indexTab]="indexTab"
                  [emitIndex]="true"
                  (currentIndex)="indexChange($event)"
                  [gruppiFotomotrie]="gruppiFotometrieInDataBase"
                  [forceUpdateItemByList]="forceUpdateItemByList"
                  (updateItem)="updateStreet($event)"
>
    <div class="container" style="padding-bottom: 70px">
        <app-paginator
                [selectItem]="true"
                [searchEnabled]="true"
                [columns]="columns"
                [objectValue]="valuesStatusStreet$|async"
                [transformFunction]="predicateFunctionValuesInStreet"
                [dataSource]="paginate"
                (itemClick)="onStreetClick($event)"
                (clickPhotometry)="clickPhotometry($event)"
                (clickGroupPhotometry)="clickGroupPhotometry($event)"
                [forceUpdate]="forceUpdateList"
                (clickCheck)="clickCheck($event)"
                (clickOtherProperty)="clickOtherProperty($event)"
                [pageSizeOptions]="[10,50,100,200]"
        >
        </app-paginator>
    </div>
</app-open-new-tab>
<div style="position: fixed;bottom: 5px;left: 0;z-index: 10000 ;width: 470px" *ngIf="!indexTab||indexTab.value==0">
    <div class="flex-around-center w100">
        <app-button-spinner [progress]="loading.destroyAll.progress" [loading]="loading.destroyAll.active"
                            type="mat-fab">
            <button (click)="destroyAll()" mat-fab color="warn" type="button" [disabled]="numberStreet==0">
                <mat-icon>delete</mat-icon>
            </button>
        </app-button-spinner>
        <button (click)="createNewStreet()" mat-fab color="accent" type="button">
            <mat-icon>add</mat-icon>
        </button>
        <button (click)="openDialog()" mat-fab color="accent" type="button" [disabled]="disable.groupPhotometry">
            <mat-icon>create_new_folder</mat-icon>
        </button>
        <button (click)="initVariable()" mat-fab color="accent" type="button">
            <mat-icon>refresh</mat-icon>
        </button>
        <app-button-spinner [progress]="loading.export.progress" [loading]="loading.export.active" type="mat-fab">
            <button (click)="abortAssociaPhotometry()" mat-fab color="warn" type="button" [disabled]="numberStreet==0"
                    matTooltip="{{'abortAssociatePhotometryMessage'|translate}}">
                <img src="assets/icon/buttonUtilities/disableCable.svg" class="icon-M">
            </button>
        </app-button-spinner>
        <app-button-spinner [progress]="loading.export.progress" [loading]="loading.export.active" type="mat-fab">
            <button (click)="associaPhotometry()" mat-fab color="accent" type="button" [disabled]="numberStreet==0"
                    matTooltip="{{'associatePhotometryMessage'|translate}}">
                <mat-icon>cable</mat-icon>
            </button>
        </app-button-spinner>
        <app-button-spinner [progress]="loading.printAll.progress" [loading]="loading.printAll.active" type="mat-fab">
            <button (click)="printAll()" mat-fab color="primary" type="button" [disabled]="numberStreet==0">
                <mat-icon>print</mat-icon>
            </button>
        </app-button-spinner>
        <app-button-spinner [progress]="loading.export.progress" [loading]="loading.export.active" type="mat-fab">
            <button (click)="exportCsv()" mat-fab color="primary" type="button" [disabled]="numberStreet==0">
                <mat-icon>download</mat-icon>
            </button>
        </app-button-spinner>
    </div>
</div>