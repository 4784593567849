import {AbstractControl, ValidatorFn, Validators} from "@angular/forms";
import {
    arrayIsSet,
    getItemInArrayByKeyValue,
    hunaParseFloat,
    isNotNullOrUndefined, KeyStringValueAny,
    stringIsSet
} from "../../models/Models";

export class HunaValidators {

    public static isNumberWithDecimal(): ValidatorFn {
        // la regex inclde i numeri con i decimali oppure linea vuota
        // ^(\d+)(?:\.(\d{1,2}))?$ non accetta le righe vuote
        return (control: AbstractControl): { [key: string]: any } | null => {
            let forbidden = true;
            if (!isNotNullOrUndefined(control.value) || control.value.toString().length === 0) {
                forbidden = false;
            } else if (typeof control.value === 'number') {
                forbidden = false;
            } else if (typeof control.value === 'string') {
                const castValue = hunaParseFloat(control.value);
                forbidden = (!isNotNullOrUndefined(castValue)) ? true : (isNaN(castValue) || castValue.toString().length !== (control.value as string).length);
            }
            return forbidden ? {'notADecimalNumber': {value: control.value}} : null;
        };
    }

    public static isNumber(): ValidatorFn {
        return Validators.pattern(/^([+-]?[1-9]\d*|0)$/);
    }

    public static isInArray(array: any[]): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            let selectboxValue = c.value;
            if (!stringIsSet(selectboxValue)) {
                return null;
            }
            let pickedOrNot = [];
            if (!arrayIsSet(array)) {
                return null;
            } else {
                pickedOrNot = array.filter(
                    (alias) => {
                        return alias.nome.toUpperCase().includes(selectboxValue.toUpperCase());
                    }
                );
            }

            if (pickedOrNot.length > 0) {
                // everything's fine. return no error. therefore it's null.
                return null;
            } else {
                //there's no matching selectboxvalue selected. so return match error.
                return {match: true};
            }
        };
    }

    public static notInArray(array: any[], key = null): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            let selectboxValue = c.value;
            if (!stringIsSet(selectboxValue) || !arrayIsSet(array)) {
                return null;
            }
            const item = getItemInArrayByKeyValue(array, selectboxValue, key)
            const isInArray = isNotNullOrUndefined(item)
            if (isInArray) {
                // everything's fine. return no error. therefore it's null.
                return {isPresentInArray: true};
            } else {
                //there's no matching selectboxvalue selected. so return match error.
                return null;
            }
        };
    }

    public static arrayIsSet(): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            let selectboxValue = c.value;
            if (arrayIsSet(selectboxValue)) {
                return null
            } else {
                return {match: true};
            }
        };
    }

    public static allSingleCharacterIsNumber(): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            let forbidden = true;
            const value = c.value;
            if (value == null || (typeof value == "string" && value.trim().length == 0)) {
                forbidden = false;
            } else if (typeof value == "number") {
                forbidden = false;
            } else if (stringIsSet(value)) {
                const length = value.length;
                let i = 0;
                while (i < length) {
                    forbidden = isNaN(parseInt(value[i]))
                    if (forbidden) {
                        i = length;
                    } else {
                        i++;
                    }
                }
            }
            return forbidden ? {'notAllSingleCharacterIsNumber': true} : null;
        };
    }


}
