import * as Parse from 'parse';
import {fromPromise} from "rxjs/internal-compatibility";
import {arrayIsSet, paramsApiParse_v2, stringIsSet} from "./Models";
import {ProgettiParse} from "./Progetti.Parse";
import {PuntiLuceParse} from "./PuntiLuce.Parse";
import {Observable, of, throwError} from "rxjs";
import {keyParseError} from "../providers/services/error-parse.service";
import {map, switchMap} from "rxjs/operators";

export class GruppiPuntiLuceParse extends Parse.Object {

    public static CLASS_NAME = 'GruppiPuntiLuce';

    constructor() {
        super(GruppiPuntiLuceParse.CLASS_NAME);
    }


    public set objectId(value) {
        this.id = value;
    }

    public get objectId() {
        return this.id;
    }

    public set ACL(value) {
        super.set('ACL', value);
    }

    public get ACL() {
        return super.get('ACL');
    }

    public set createdAt(value) {
        super.set('createdAt', value);
    }

    public get createdAt() {
        return super.get('createdAt');
    }

    public set nome(value) {
        super.set('nome', value);
    }

    public get nome() {
        return super.get('nome');
    }

    public set progetto(value) {
        super.set('progetto', value);
    }

    public get progetto() {
        return super.get('progetto');
    }

    public set updatedAt(value) {
        super.set('updatedAt', value);
    }

    public get updatedAt() {
        return super.get('updatedAt');
    }

    public set createdBy(value) {
        super.set('createdBy', value);
    }

    public get createdBy() {
        return super.get('createdBy');
    }

    public set puntiLuce(value) {
        super.set('puntiLuce', value);
    }

    public get puntiLuce(): Parse.Relation<PuntiLuceParse> {
        return super.get('puntiLuce');
    }

    public getGroupsLightPoint$(project = undefined) {
        let projectId;
        if (project != null) {
            projectId = project.objectId
        } else if (this.progetto != null) {
            projectId = this.progetto.objectId
        }

        if (projectId == null) {
            return throwError(keyParseError.progettoMissing)
        } else {
            const params = paramsApiParse_v2({progettoId: projectId});
            return fromPromise(Parse.Cloud.run('getGruppiPuntiLuce', params));
        }
    }

    public editGroupsLightPoint$(gruppiPuntiLuceJSON: any[]): Observable<GruppiPuntiLuceParse[]> {
        if (gruppiPuntiLuceJSON == null) {
            return throwError(keyParseError.dataMissing)
        } else {
            const params = paramsApiParse_v2({gruppiPuntiLuce: gruppiPuntiLuceJSON});
            return fromPromise(Parse.Cloud.run('editGruppiPuntiLuce', params));
        }
    }

    public deleteGroupsLightPoint$(gruppiPuntiLuceIds: string[]): Observable<GruppiPuntiLuceParse[]> {
        if (gruppiPuntiLuceIds == null) {
            return throwError(keyParseError.dataMissing)
        } else {
            const params = paramsApiParse_v2({gruppiPuntiLuceIds});
            return fromPromise(Parse.Cloud.run('deleteGruppiPuntiLuce', params));
        }
    }

    delete$(): Observable<GruppiPuntiLuceParse> {
        return this.deleteGroupsLightPoint$([this.objectId]).pipe(
            map(ids => ids[0])
        )
    }

    public fetchGruppiPuntiLuce$(idsGruppiPuntiLuce: string[] = undefined): Observable<GruppiPuntiLuceParse[]> {
        if (idsGruppiPuntiLuce == null) {
            return throwError(keyParseError.dataMissing)
        } else {
            const params = paramsApiParse_v2({idsGruppiPuntiLuce});
            return fromPromise(Parse.Cloud.run('getGruppiPuntiLuce', params));
        }
    }

    public createGruppiPuntiLuce$(project: ProgettiParse = undefined, idsLightPoint: string[] = undefined): Observable<GruppiPuntiLuceParse[]> {
        //to test
        let progettoId;
        if (project != null) {
            progettoId = project.objectId
        } else if (this.progetto) {
            progettoId = this.progetto.objectId
        }
        if (progettoId == null || idsLightPoint == null) {
            return throwError(keyParseError.dataMissing)
        } else {
            const params = paramsApiParse_v2({progettoId, idsLightPoint});
            return fromPromise(Parse.Cloud.run('getGruppiPuntiLuce', params));
        }
    }


    public fetch$(): Observable<GruppiPuntiLuceParse> {
        if (this.objectId == null) {
            return throwError(keyParseError.dataMissing)
        } else {
            return this.fetchGruppiPuntiLuce$([this.objectId]).pipe(
                switchMap(gruppi => {
                    if (!arrayIsSet(gruppi)) {
                        return throwError(keyParseError.objectIdNotValid)
                    } else {
                        return of(gruppi)
                    }
                }),
                map(gruppi => gruppi[0])
            )
        }
    }

    public getLightPoint$(id: string = undefined): Observable<PuntiLuceParse[]> {
        if (this.objectId == null && id == null) {
            return throwError(keyParseError.dataMissing)
        } else {
            const gruppoId = stringIsSet(id) ? id : this.objectId;
            const params = paramsApiParse_v2({gruppoId});
            return fromPromise(Parse.Cloud.run('getPuntiLuceFromGruppo', params));

        }
    }


}

Parse.Object.registerSubclass(GruppiPuntiLuceParse.CLASS_NAME, GruppiPuntiLuceParse);
