import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {arrayIsSet, className, isNotNullOrUndefined} from "../../../models/Models";
import {VisualizedImageComponent} from "../visualized-image/visualized-image.component";
import { typeFormFieldMaintenance } from 'src/app/providers/services/maintenance.service';

@Component({
    selector: 'app-visualized-form-schede',
    templateUrl: './visualized-form-schede.html',
    styleUrls: ['./visualized-form-schede.scss']
})
export class VisualizedFormSchedeComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<VisualizedFormSchedeComponent>,
                @Inject(MAT_DIALOG_DATA)
                public data: { form: any[], formTemplate: { type: typeFormFieldMaintenance, traduction: string }[] },
                private dialog: MatDialog
    ) {
    }

    getTypeToVisualized(value, name: string) {
        if (!isNotNullOrUndefined(value)) {
            return 'default'
        } else if (typeof value == "boolean") {
            return 'bool'
        } else if (value instanceof Date) {
            return 'date'
        } else if (value.url) {
            return 'image'
        } else if (arrayIsSet(value) && value[0].className != null && value[0].className == className.documentsFile) {
            return 'image'
        } else if (this.data.formTemplate.find((e) => e.traduction === name)?.type === typeFormFieldMaintenance.hour) {
            return 'hour'
        } else {
            return 'default'
        }
    }

    ngOnInit(): void {
        if (arrayIsSet(this.form)) {
            this.form.forEach(fieldCompiled => {
                this.typeSchede[fieldCompiled.traduction] = this.getTypeToVisualized(fieldCompiled.value, fieldCompiled.traduction)
            })
            this.form.forEach(field => {
                if (this.typeSchede[field.traduction] == 'image') {
                    if (arrayIsSet(field.value)) {
                        this.images[field.traduction] = []
                        field.value.forEach(value => {
                            if (value.file != null && value.file.url != null) {
                                this.images[field.traduction].push(value.file.url())
                            }
                        })
                    } else if (field.value != null && field.value.url) {
                        this.images[field.traduction] = [field.value.url()]
                    }
                }
            })
        }
    }

    colorIcon = 'accent'

    openImageFull(url) {
        const width: string = '100%';
        const height: string = '100%';
        this.dialog.open(VisualizedImageComponent, {
            width: width,
            maxWidth: '100%',
            height: height,
            maxHeight: '100%',
            data: {src: url, type: 'image'}
        });
    }

    get form() {
        return this.data.form
    }

    public typeSchede = {};
    images = {}

    public anticonvertHourTODecimalHour(oraDecimal: string | number): string {
        if (oraDecimal) {
            const oraDecimalString = oraDecimal.toString();
            const ore = parseInt(oraDecimalString);
            const minute = Math.floor((parseFloat(oraDecimalString) - ore) * 60).toString().padStart(2, '0');
            let aMpM;
            let ora;
            if (ore > 12) {
                ora = (ore - 12).toString().padStart(2, '0');
                aMpM = 'PM';
            } else {
                ora = ore.toString().padStart(2, '0');
                aMpM = 'AM';
            }
            return ora + ':' + minute + ' ' + aMpM;
        } else {
            return '-';
        }
    }
}
