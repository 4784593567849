import {Component, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {
    AbstractControl, ControlValueAccessor,
    UntypedFormBuilder,
    UntypedFormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors
} from "@angular/forms";
import {dataForm} from "../../components/confirm-delete/select-or-create/select-or-create.component";
import {
    arrayIsSet,
    cleanToSpecialCharacterAndLowerCaseString,
    isNotNullOrUndefined, KeyStringValueAny,
    stringIsSet
} from "../../models/Models";
import {Observable, Subject, Subscription} from "rxjs";
import {startWith} from "rxjs/operators";

@Component({
    selector: 'app-chips-multiselect-form-field',
    templateUrl: './chips-multiselect-form-field.component.html',
    styleUrls: ['./chips-multiselect-form-field.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: ChipsMultiselectFormFieldComponent
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: ChipsMultiselectFormFieldComponent
        }
    ]
})
export class ChipsMultiselectFormFieldComponent implements OnInit, OnChanges, ControlValueAccessor {
    @Input() titleTraduction: string;
    @Input() possibleValues: dataForm[];
    @Input() otherValues: KeyStringValueAny | undefined;
    @Input() showMenu = false;
    @Output() valueChanges = new Subject<any[]>();

    public form: UntypedFormGroup;
    private subScriptions = new Subscription();
    public valueSetted$: Observable<string[]>;

    constructor(private fb: UntypedFormBuilder) {
        this.form = this.fb.group({value: null})
        this.valueSetted$ = this.form.get('value').valueChanges.pipe(
            startWith(this.form.get('value').value)
        )
        const sub = this.valueSetted$.subscribe(values => this.valueChanges.next(values));
        this.subScriptions.add(sub);
    }

    ngOnInit(): void {

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.possibleValues != null) {
            let chipsSelected = arrayIsSet(this.form.get('value').value) ? [...this.form.get('value').value] : [];
            if (arrayIsSet(this.possibleValues) && arrayIsSet(chipsSelected)) {
                chipsSelected = chipsSelected.filter(
                    set => {
                        const index = this.possibleValues.findIndex(p => cleanToSpecialCharacterAndLowerCaseString(p.valueForm) === cleanToSpecialCharacterAndLowerCaseString(set))
                        return index >= 0;
                    }
                )
                if (!arrayIsSet(chipsSelected)) {
                    chipsSelected = this.possibleValues.map(v => v.valueForm);
                }
                this.form.get('value').setValue(chipsSelected);
            }
        }
    }

    unsetValue() {
        this.form.get('value').reset()
    }

    ngOnDestroy(): void {
        this.subScriptions.unsubscribe()
    }

    onTouched: Function = () => {
    };


    registerOnChange(onChange: any) {
        const sub = this.form.get('value').valueChanges
            .subscribe(onChange);
        this.subScriptions.add(sub)
    }

    registerOnTouched(onTouched: Function) {
        this.onTouched = onTouched;
    }


    setDisabledState(disabled: boolean) {
        if (disabled) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    writeValue(value: any[]) {
        if (!arrayIsSet(value)) {
            this.form.get('value').reset();
        } else {
            this.form.get('value').setValue(value);
        }
        setTimeout(() => {
            this.form.get('value').updateValueAndValidity();
        }, 0)
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (!isNotNullOrUndefined(this.form.get('value').validator)) {
            this.form.get('value').setValidators(control.validator)
        }
        if (this.form.get('value').valid) {
            return null
        } else {
            return this.form.get('value').errors;
        }
    }

    clickChips(value: dataForm) {
        if (this.form.enabled) {
            let chipsSelected = arrayIsSet(this.form.get('value').value) ? [...this.form.get('value').value] : [];
            const index = chipsSelected.findIndex(valueStored => {
                return cleanToSpecialCharacterAndLowerCaseString(valueStored) === cleanToSpecialCharacterAndLowerCaseString(value.valueForm)
            })
            if (index < 0) {
                chipsSelected.push(value.valueForm)
            } else if (chipsSelected.length > 1) {
                chipsSelected.splice(index, 1);
            }
            this.form.get('value').setValue(chipsSelected);
        }
    }

    classPredicateFunction(valuesSetted: string[] | undefined, value: dataForm): string[] | undefined {
        let classCss;
        if (arrayIsSet(valuesSetted) && stringIsSet(value.cssClass)) {
            const index = valuesSetted.findIndex(valueSet => cleanToSpecialCharacterAndLowerCaseString(valueSet) === cleanToSpecialCharacterAndLowerCaseString(value.valueForm))
            classCss = index >= 0 ? [value.cssClass] : undefined;
        }
        return classCss;
    }

    isSelectedPredicateFunction(valuesSetted: string[] | undefined, value: dataForm): boolean {
        let isSedelect = false;
        if (arrayIsSet(valuesSetted)) {
            const index = valuesSetted.findIndex(valueSet => cleanToSpecialCharacterAndLowerCaseString(valueSet) === cleanToSpecialCharacterAndLowerCaseString(value.valueForm))
            isSedelect = index >= 0;
        }
        return isSedelect;
    }

    clickSelezioneUnica(value: dataForm) {
        this.form.get('value').reset(undefined, {emitEvent: false})
        this.form.get('value').setValue([value.valueForm])
    }
}
