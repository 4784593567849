import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    Input,
    Output,
    EventEmitter,
    ViewChildren,
    QueryList,
    ContentChildren
} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {
    RoadLightCategory,
    SidewalkLightCategory,
    StreetLayoutItemType,
    RoadwayItem,
    TrafficDividerItem,
    SidewalkItem,
    StreetLayoutItem,
    StreetLamp,
    StreetsService,
    EditableStreet,
    LampPresetType, ParcheggioItem, PistaCiclabileItem, ParkingCategory
} from 'src/app/providers/services/streets.service';
import {OrderChanged} from 'src/app/core/orderable-list/orderable-list.component';
import {moveItemInArray} from '@angular/cdk/drag-drop';
import {TranslateService} from '@ngx-translate/core';
import {AlertService} from 'src/app/providers/services/alert.service';
import {MatExpansionPanel} from '@angular/material/expansion';

@Component({
    selector: 'app-edit-street-form',
    templateUrl: './edit-street-form.component.html',
    styleUrls: ['./edit-street-form.component.scss']
})
export class EditStreetFormComponent implements OnInit {

    @ViewChild('nativeForm')
    nativeForm: ElementRef<HTMLFormElement> = undefined!;
    @ViewChildren(MatExpansionPanel)
    panels: QueryList<MatExpansionPanel> = undefined!;

    formGroup: UntypedFormGroup = new UntypedFormGroup({});
    @Input()
    street: EditableStreet = null!;
    saving: boolean = false;

    @Output()
    edit: EventEmitter<void> = new EventEmitter();
    @Output()
    saved: EventEmitter<void> = new EventEmitter();
    tipologiaAreaIlluminata = this.streetService.tipologiaAreaIlluminata;

    constructor(
        private streetService: StreetsService,
        private translate: TranslateService,
        private alertService: AlertService
    ) {
    }


    get roadwayCategories() {
        return Object.values(RoadLightCategory);
    }

    get sidewalkCategories() {
        return Object.values(SidewalkLightCategory);
    }

    get parkingCategories() {
        return Object.values(ParkingCategory);
    }

    get layoutItemTypes() {
        return Object.values(StreetLayoutItemType);
    }

    get StreetLayoutItemType() {
        return StreetLayoutItemType;
    }

    get layoutItemTemplates() {
        return {
            [StreetLayoutItemType.carreggiata]: {
                categoriaIlluminotecnica: RoadLightCategory.C0,
                larghezza: 8,
                numeroCorsie: 2,
                tipo: StreetLayoutItemType.carreggiata,
            } as RoadwayItem,
            [StreetLayoutItemType.spartitraffico]: {
                larghezza: 1.5,
                tipo: StreetLayoutItemType.spartitraffico,
            } as TrafficDividerItem,
            [StreetLayoutItemType.marciapiede]: {
                categoriaIlluminotecnica: SidewalkLightCategory.P1,
                larghezza: 2,
                tipo: StreetLayoutItemType.marciapiede,
            } as SidewalkItem,
            [StreetLayoutItemType.parcheggio]: {
                categoriaIlluminotecnica: ParkingCategory.P1,
                larghezza: 2,
                tipo: StreetLayoutItemType.parcheggio,
            } as ParcheggioItem,
            [StreetLayoutItemType.pistaciclabile]: {
                categoriaIlluminotecnica: ParkingCategory.P1,
                larghezza: 2,
                tipo: StreetLayoutItemType.pistaciclabile,
            } as PistaCiclabileItem,
        };
    }

    get singleLampTemplate() {
        return {
            altezza: 8,
            origine_x: 0,
            origine_y: 0,
            sbraccio: 1,
            angoloRotazione: 0,
            angoloInclinazione: 0,
            gruppo: 1,
            name: undefined
        };
    };

    getItemY(item: StreetLayoutItem) {
        const index = this.street.layout.indexOf(item);
        let y = 0;
        for (let i = 0; i < index; i++) {
            y += this.street.layout[i].larghezza;
        }
        return y;
    }

    get maxY() {
        return this.street.layout.reduce((a, i) => a + i.larghezza, 0);
    }

    get lampPresetsKeys() {
        return Object.values(LampPresetType);
    }

    decimals(n: number, decimals: number = 1) {
        return Math.floor(n * Math.pow(10, decimals)) / Math.pow(10, decimals);
    }

    getLampPreset(road: RoadwayItem, presetKey: LampPresetType) {
        switch (presetKey) {
            case LampPresetType.sideBottom: {
                const y = this.getItemY(road);
                return [
                    {
                        altezza: 8,
                        origine_x: this.street.interdistanza / 2,
                        origine_y: this.decimals(y),
                        sbraccio: 0,
                        angoloRotazione: 0,
                        angoloInclinazione: 0,
                        gruppo: 1,
                    },
                ];
            }
            case LampPresetType.sideTop: {
                const y = this.getItemY(road) + road.larghezza;
                return [
                    {
                        altezza: 8,
                        origine_x: this.street.interdistanza / 2,
                        origine_y: this.decimals(y),
                        sbraccio: 0,
                        angoloRotazione: 180,
                        angoloInclinazione: 0,
                        gruppo: 1,
                    },
                ];
            }
            case LampPresetType.bothSides: {
                return this.getLampPreset(road, LampPresetType.sideTop).concat(
                    this.getLampPreset(road, LampPresetType.sideBottom)
                );
            }
            case LampPresetType.quincunx: {
                const y = this.getItemY(road);
                return [
                    {
                        altezza: 8,
                        origine_x: this.street.interdistanza / 4,
                        origine_y: this.decimals(y),
                        sbraccio: 0,
                        angoloRotazione: 0,
                        angoloInclinazione: 0,
                        gruppo: 1,
                    },
                    {
                        altezza: 8,
                        origine_x: this.street.interdistanza * 3 / 4,
                        origine_y: this.decimals(y + road.larghezza),
                        sbraccio: 0,
                        angoloRotazione: 180,
                        angoloInclinazione: 0,
                        gruppo: 1,
                    }
                ];
            }
            case LampPresetType.centreline: {
                return this.getCentrelineLamps(road, 1);
            }
            case LampPresetType.doubleCentreline: {
                return this.getCentrelineLamps(road, 2);
            }
            case LampPresetType.centrelineAndSides: {
                return this.getLampPreset(road, LampPresetType.centreline).concat(
                    this.getLampPreset(road, LampPresetType.bothSides)
                );
            }
        }
    }

    getCentrelineLamps(road: RoadwayItem, numberOfLamps: number) {
        let lamps = [];
        const roadY = this.getItemY(road);
        for (let i = 1; i <= numberOfLamps; i++) {
            lamps.push(
                {
                    altezza: 8,
                    origine_x: this.street.interdistanza / 2,
                    origine_y: this.decimals(roadY + (road.larghezza * i) / (numberOfLamps + 1)),
                    sbraccio: 0,
                    angoloRotazione: 0,
                    angoloInclinazione: 0,
                    gruppo: 1,
                }
            );
        }
        return lamps;
    }

    forceRefresh() {
        // Change detection for street-preview component
        this.street.layout = this.street.layout.slice();
        this.street.lampade = this.street.lampade.slice();
    }

    updateObjectAndRefresh(obj: { [key: string]: any }, key: string, value: any, cast = null) {
        if (cast) {
            obj[key] = (window as any)[cast](value);
        } else {
            obj[key] = value;
        }
        this.forceRefresh();
        this.edit.emit();
    }

    deleteItem(item: StreetLayoutItem) {
        this.street.layout = this.street.layout.filter((i) => i !== item);
        this.edit.emit();
    }

    deleteLamp(lamp: StreetLamp) {
        this.street.lampade = this.street.lampade.filter((l) => l !== lamp);
        this.edit.emit();
    }

    addItem(type: StreetLayoutItemType) {
        this.street.layout.unshift(this.layoutItemTemplates[type]);
        this.forceRefresh();
        this.edit.emit();
    }

    addLamp() {
        this.street.lampade.push(this.singleLampTemplate);
        this.forceRefresh();
        this.edit.emit();
    }

    get roads() {
        return this.street.layout.filter(
            (x) => x.tipo === StreetLayoutItemType.carreggiata
        );
    }

    get reversedRoads() {
        return this.street.layout.filter(
            (x) => x.tipo === StreetLayoutItemType.carreggiata
        ).reverse();
    }

    addPreset(presetKey: LampPresetType, road: RoadwayItem) {
        this.street.lampade = this.street.lampade.concat(
            this.getLampPreset(road, presetKey)
        );
        this.edit.emit();
    }

    resetLayout() {
        this.street.layout = [];
        this.edit.emit();
    }

    resetLamps() {
        this.street.lampade = [];
        this.edit.emit();
    }

    ngOnInit() {
        if (this.street.layout.length === 0) {
            this.street.layout.push(this.layoutItemTemplates[StreetLayoutItemType.carreggiata]);
        }
        this.forceRefresh();
    }

    onSubmitButtonClick(e: Event) {
        e.preventDefault();
        if (this.nativeForm.nativeElement.checkValidity()) {
            this.nativeForm.nativeElement.dispatchEvent(new CustomEvent('submit'));
        } else {
            this.panels
                .filter(p => p._body.nativeElement.querySelectorAll(':invalid').length > 0)
                .forEach(p => p.open());
            setTimeout(() => {
                if (this.nativeForm.nativeElement.reportValidity) {
                    this.nativeForm.nativeElement.reportValidity();
                } else {
                    this.alertService.error(this.translate.instant('streets.form_invalid'));
                }
            }, 500);
        }
    }

    async moveItem(event: OrderChanged) {
        const oldIndex = event.oldIndex
        const newIndex = event.newIndex
        moveItemInArray(this.street.layout, oldIndex, newIndex);
        this.forceRefresh();
        this.edit.emit();
    }

    async moveLamp({oldIndex, newIndex}: OrderChanged) {
        moveItemInArray(this.street.lampade, oldIndex, newIndex);
        this.forceRefresh();
        this.edit.emit();
    }

    async save() {
        this.alertService.success(this.translate.instant('streets.saving'));
        this.saving = true;
        try {
            this.street.objectId = await this.streetService.save({
                objectId: this.street.objectId,
                fattoreManutenzione: this.street.fattoreManutenzione,
                interdistanza: this.street.interdistanza,
                lampade: this.street.lampade,
                layout: this.street.layout,
                nome: this.street.nome,
                note: this.street.note,
                isCentroStorico: this.street.isCentroStorico,
                includiMarciapiedi: this.street.includiMarciapiedi,
                verificaIPEI_A: this.street.verificaIPEI_A,
                calcoloSemicilindrico: this.street.calcoloSemicilindrico,
                tipologiaAreaIlluminata: this.street.tipologiaAreaIlluminata,
            });
            this.alertService.success(this.translate.instant('streets.save_success'));
            this.saved.emit();
        } catch (e) {
            this.alertService.error(this.translate.instant('error_verified'));
        }
        this.saving = false;
    }

    expandAllAccordion(e) {
        console.log(e);
    }

    getTraduction(key, value): string {
        const traduction: string = this.translate.instant(key + value)
        if (traduction.includes(key)) {
            return value;
        } else {
            return traduction;
        }
    }


}
