import {Pipe, PipeTransform} from '@angular/core';
import {UtilsService} from "../services/utils.service";

@Pipe({
    name: 'hourParser'
})
export class HourParserPipe implements PipeTransform {

    constructor(private utilsService: UtilsService) {
    }

    transform(value: any): any {
        return this.utilsService.mapNumber(value);
    }

}
