import {Injectable} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {LineaElettricaParse} from '../../models/LineaElettrica.Parse';
import {fromPromise} from 'rxjs/internal-compatibility';
import {expand, map, switchMap, toArray} from 'rxjs/operators';
import {EMPTY, forkJoin, Observable, of, Subject} from 'rxjs';
import {ProjectService} from './project.service';
import {LocationElementsParse} from '../../models/LocationElements.Parse';
import * as Parse from 'parse';
import {ElectricLinesMap} from './map.service';
import {
    arrayIsSet,
    getItemInArrayByKeyValue,
    isDate,
    isNotNullOrUndefined, KeyStringValue,
    KeyStringValueString, paramsApiParse_v2,
    stringIsSet
} from '../../models/Models';
import {GoogleServiceService} from './google-service.service';
import {
    configurationElement, getElementByKeyName, getSubForm, getTypeDataBase, getValidator, getValidatorFormField,
    isPointerConfigurationElement, PossibleValuesType, typeDatabase,
    typeFormValue
} from '../../models/configurationProperty/configurationPropertyUtils';
import {configurationPropertyElectricLine} from '../../models/configurationProperty/electricLine';
import {dataForm} from '../../components/confirm-delete/select-or-create/select-or-create.component';
import {ScaleColorService} from './scale-color.service';
import {CircuitiParse} from '../../models/Circuiti.Parse';
import {PuntiLuceParse} from '../../models/PuntiLuce.Parse';
import {DataTypeLegend} from "../../components/scale-for-map/legend-key-color/legend-key-color.component";

export const valueDatalabel = {
    date: 'date',
    text: 'text',
    traduction: 'TRADUCTION',
    bool: 'bool',
    number: 'number',
    elenco: 'elenco',
};
export type TypeDatalabel = typeof valueDatalabel[keyof typeof valueDatalabel];

@Injectable({
    providedIn: 'root'
})
export class LineaElettricaService {
    private configurationProperty = configurationPropertyElectricLine;
    private typeDataBase = typeDatabase;
    private allLocationElements: LocationElementsParse[] = [];
    private updatedBackgroundLocalPositionElement: Subject<LocationElementsParse> = new Subject<LocationElementsParse>();
    private updatedBackgroundLocalPositionElement$ = this.updatedBackgroundLocalPositionElement.asObservable();

    constructor(private fb: UntypedFormBuilder,
                private projectService: ProjectService,
                private googleService: GoogleServiceService,
                private scaleColorService: ScaleColorService) {

    }

    public resetLocalLocationElements() {
        this.allLocationElements = [];
    }

    private addOrUpdateLocationElements(point: LocationElementsParse) {
        const index = this.allLocationElements.findIndex(stored => stored.objectId === point.objectId);
        if (index < 0) {
            this.allLocationElements.push(point);
        } else {
            this.updatedBackgroundLocalPositionElement.next(point);
            this.allLocationElements[index] = point;
        }
    }

    private getLocalLocationElements(pointId: string): LocationElementsParse | null {
        const index = this.allLocationElements.findIndex(stored => stored.objectId === pointId);
        if (index >= 0) {
            return this.allLocationElements[index];
        } else {
            return null;
        }
    }

    getScalaColore(keyToScale: string, elementiInMap: LineaElettricaParse[], oldScaleColor: DataTypeLegend[] | undefined): {
        scaleColor: KeyStringValueString,
        legend: DataTypeLegend[]
    } | undefined {
        const element = getElementByKeyName(keyToScale, this.configurationProperty);
        if (element != null) {
            let tipoDato: any = getTypeDataBase(element);
            const isPointer: any = isPointerConfigurationElement(element);
            let possibleValues = [];
            if (isPointer) {
                tipoDato = '';
            } else if (arrayIsSet(element.possibleValues)) {
                tipoDato = valueDatalabel.elenco;
                possibleValues = element.possibleValues;
            } else if (tipoDato === this.typeDataBase.STRING) {
                tipoDato = valueDatalabel.text;
            } else if (tipoDato === this.typeDataBase.BOOLEAN) {
                tipoDato = valueDatalabel.bool;
            } else if (tipoDato === this.typeDataBase.DATE) {
                tipoDato = valueDatalabel.date;
            } else if (tipoDato === this.typeDataBase.NUMBER) {
                tipoDato = valueDatalabel.number;
            }
            const scalaColorePredefinite = {};
            elementiInMap.sort((a, b) => {
                if (typeof a[keyToScale] === 'string') {
                    return a[keyToScale].localeCompare(b[keyToScale]);
                } else if (typeof a[keyToScale] === 'number') {
                    return a[keyToScale] - b[keyToScale];
                } else {
                    return 0;
                }
            });
            const allScale = this.scaleColorService.creaScalaColore(keyToScale, elementiInMap as any[], scalaColorePredefinite, tipoDato, isPointer, possibleValues);
            const scaleColor: any = allScale.scalaColore;
            if ([valueDatalabel.text, valueDatalabel.date].includes(tipoDato) && arrayIsSet(oldScaleColor)) {
                const keysScale = Object.keys(scaleColor);
                oldScaleColor.forEach(scale => {
                    const oldKey = this.scaleColorService.createKey(scale.key);
                    if (keysScale.includes(oldKey) && scaleColor[oldKey] !== scale.color) {
                        const oldColor = scaleColor[oldKey];
                        scaleColor[oldKey] = scale.color;
                        const keyScaleToSub = keysScale
                            .find(k => k !== oldKey && scaleColor[k] === scale.color);
                        if (keyScaleToSub != null) {
                            scaleColor[keyScaleToSub] = oldColor;
                        }
                    }
                });
            }
            const scaleValue = allScale.keyValue;
            const legend: DataTypeLegend[] = Object.keys(scaleColor).map(keyObj => {
                let type;
                let key = keyObj;
                const color = scaleColor[keyObj];
                if (keyObj === this.scaleColorService.keyAltro || keyObj === this.scaleColorService.keyNonSpecificato) {
                    type = valueDatalabel.traduction;
                } else if (tipoDato === valueDatalabel.bool) {
                    type = valueDatalabel.traduction;
                } else if (tipoDato === valueDatalabel.date) {
                    type = valueDatalabel.date;
                } else if (tipoDato === valueDatalabel.elenco) {
                    if (possibleValues.includes(keyObj)) {
                        type = valueDatalabel.traduction;
                    } else {
                        type = valueDatalabel.text;
                    }
                } else {
                    type = tipoDato;
                }
                if (type === valueDatalabel.text) {
                    if (scaleValue[keyObj]) {
                        key = scaleValue[keyObj];
                    } else {
                        key = keyObj;
                    }
                }
                return {key, type, color};
            });
            return {scaleColor, legend};
        } else {
            return undefined;
        }
    }

    createNewElectricLines(detailsElectricLine: any, locations: {
        latitude: number,
        longitude: number
    }[]): Observable<LineaElettricaParse> {
        const lineaElettrica = new LineaElettricaParse();
        lineaElettrica.progetto = this.projectService.currentProject;
        if (detailsElectricLine != null) {
            Object.keys(detailsElectricLine).forEach(key => {
                if (detailsElectricLine[key] != null) {
                    lineaElettrica[key] = detailsElectricLine[key];
                }
            });
            return fromPromise(lineaElettrica.save())
                .pipe(
                    switchMap(lineaElettricaSaved => {
                        return this.addLineaElettricaToLocations(lineaElettricaSaved, locations.map((geo, sortingNumber) => {
                                return {geoPoint: new Parse.GeoPoint(geo.latitude, geo.longitude), sortingNumber};
                            })
                        ).pipe(
                            map(() => lineaElettricaSaved)
                        );
                    }),
                );
        }
    }

    updateElectricLines(lineaElettrica: LineaElettricaParse, detailsElectricLine: any): Observable<LineaElettricaParse> {
        if (detailsElectricLine != null) {
            const linesMap = lineaElettrica.linesMap;
            Object.keys(detailsElectricLine).forEach(key => {
                if (detailsElectricLine[key] != null) {
                    lineaElettrica[key] = detailsElectricLine[key];
                }
            });
            return fromPromise(lineaElettrica.save()).pipe(
                map(lineaElettricaUpdated => {
                    lineaElettricaUpdated.linesMap = linesMap;
                    return lineaElettricaUpdated;
                })
            );
        } else {
            return of(lineaElettrica);
        }
    }

    destroyElectricLines(lineaElettrica: LineaElettricaParse): Observable<LineaElettricaParse> {
        return this.getLocationsElementsByLineaElettrica(lineaElettrica).pipe(
            switchMap((points) => {
                points.forEach(locationElement => {
                    locationElement.removeSortingNumberLineaElettricaByObjectLineaElettrica(lineaElettrica);
                });
                return fromPromise(Parse.Object.saveAll(points));
            }),
            switchMap(points => {
                return fromPromise(lineaElettrica.destroy());
            }),
        );
    }


    public getLocation(location: Parse.GeoPoint): Observable<LocationElementsParse> {

        const query = new LocationElementsParse().query;
        query.equalTo('progetto', this.projectService.currentProject);
        query.equalTo('location', location);
        return fromPromise(query.first())
            .pipe(
                switchMap(locationParse => {
                    if (locationParse == null) {
                        const newLocation = new LocationElementsParse();
                        newLocation.progetto = this.projectService.currentProject;
                        newLocation.location = location;
                        return fromPromise(newLocation.save());
                    } else {
                        return of(locationParse);
                    }
                })
            );
    }

    addLineaElettricaToLocation(lineaElettrica: LineaElettricaParse, location: Parse.GeoPoint, sortingNumber: number): Observable<LocationElementsParse> {
        return this.getLocation(location)
            .pipe(
                switchMap((locationElement) => {
                    const sortingNumberWithKey = {};
                    sortingNumberWithKey[lineaElettrica.objectId] = sortingNumber;
                    locationElement.addLineaElettrica(lineaElettrica);
                    locationElement.addSortingNumber(sortingNumberWithKey);
                    return fromPromise(locationElement.save());
                })
            );
    }

    updateSortingNumberLocations(lineaElettrica: LineaElettricaParse, points: LocationElementsParse[], startPoint: LocationElementsParse, newPoint: Parse.GeoPoint): Observable<LocationElementsParse[]> {
        const firstIndex = points.findIndex(point => point.objectId === startPoint.objectId);
        const updatePoint: Observable<LocationElementsParse>[] = [];
        if (firstIndex >= 0) {
            points.forEach((point, index) => {
                if (index === firstIndex) {
                    const locationPoint$ = this.addLineaElettricaToLocation(lineaElettrica, newPoint, index + 1);
                    updatePoint.push(locationPoint$);
                } else if (index > firstIndex) {
                    const val = {};
                    val[lineaElettrica.objectId] = index + 1;
                    point.addSortingNumber(val);
                    updatePoint.push(fromPromise(point.save()));
                }
            });
        }
        return forkJoin(updatePoint).pipe(
            map((updatedPoint) => {
                const idUpdated = updatedPoint.map(point => point.objectId);
                const notUpdate: LocationElementsParse[] = points.filter(point => !idUpdated.includes(point.objectId));
                const allValue = updatedPoint.concat(notUpdate);
                allValue.sort((a, b) => {
                    const valueA = (a.sortingNumber != null && a.sortingNumber[lineaElettrica.objectId] != null) ? a.sortingNumber[lineaElettrica.objectId] : -1;
                    const valueB = (b.sortingNumber != null && b.sortingNumber[lineaElettrica.objectId] != null) ? b.sortingNumber[lineaElettrica.objectId] : -1;
                    return valueA - valueB;
                });
                return allValue;
            })
        );
    }

    addLineaElettricaToLocations(lineaElettrica: LineaElettricaParse, locations: {
        geoPoint: Parse.GeoPoint,
        sortingNumber: number
    }[]): Observable<any> {
        const fetchPage = (page = 0) => {
            const location = locations[page].geoPoint;
            const sortingNumber = locations[page].sortingNumber;
            return this.addLineaElettricaToLocation(lineaElettrica, location, sortingNumber)
                .pipe(
                    map(item => {
                        return {
                            items: item,
                            nextPage: (page + 1) >= locations.length ? -1 : page + 1,
                        };
                    })
                );
        };
        return fetchPage(0).pipe(
            expand(({nextPage}) => nextPage >= 0 ? fetchPage(nextPage) : EMPTY),
            toArray()
        );
    }

    getElectricLines(valuesEqualTo: { [k: string]: any } | undefined): Observable<LineaElettricaParse[]> {
        const lineeElettriche$ = new LineaElettricaParse().getAllLineeElettriche$(this.projectService.currentProject);
        return lineeElettriche$
    }


    getElectricLinesByGeoBox(sudWest: Parse.GeoPoint, northEast: Parse.GeoPoint, visualized: LineaElettricaParse[]): Observable<LineaElettricaParse[]> {
        return this.getLineeElettricheByGeoBox$(sudWest, northEast).pipe(
            map(lineeElettricheScaricate => {
                if (lineeElettricheScaricate != null && arrayIsSet(lineeElettricheScaricate.lineeElettriche)) {

                    const lineeElettriche = lineeElettricheScaricate.lineeElettriche;
                    const pointsByLine = lineeElettricheScaricate.pointsLineeElettriche;
                    lineeElettriche.forEach((lineaElettrica, index) => {
                        if (arrayIsSet(pointsByLine[lineaElettrica.objectId])) {
                            pointsByLine[lineaElettrica.objectId].sort((a, b) => {
                                const valueA = (a.sortingNumber != null && a.sortingNumber[lineaElettrica.objectId] != null) ? a.sortingNumber[lineaElettrica.objectId] : -1;
                                const valueB = (b.sortingNumber != null && b.sortingNumber[lineaElettrica.objectId] != null) ? b.sortingNumber[lineaElettrica.objectId] : -1;
                                return valueA - valueB;
                            });
                            lineaElettrica.linesMap = this.getLineMapByPoints(pointsByLine[lineaElettrica.objectId]);
                        }
                    });
                    return lineeElettriche
                }else {
                    return undefined
                }
            })
        );
    }

    getElectricLineMapByLineaElettrica(lineeElettriche: LineaElettricaParse[]): Observable<LineaElettricaParse[]> {

        const lineeElettricheDaScaricare = [];
        const lineeElettricheScaricate = [];
        if (arrayIsSet(lineeElettriche)) {
            lineeElettriche.forEach(linea => {
                if (!arrayIsSet(linea.linesMap)) {
                    lineeElettricheDaScaricare.push(linea);
                } else {
                    lineeElettricheScaricate.push(linea);
                }
            });
        }

        if (!arrayIsSet(lineeElettricheDaScaricare)) {
            return of(lineeElettriche);
        } else {
            return this.getLocationsElementsByLineeElettriche$(lineeElettricheDaScaricare).pipe(
                map(pointsByLine => {
                    Object.keys(pointsByLine).forEach(key => pointsByLine[key].forEach(p => {
                        this.addOrUpdateLocationElements(p);
                    }));
                    lineeElettricheDaScaricare.forEach((lineaElettrica, index) => {
                        if (arrayIsSet(pointsByLine[lineaElettrica.objectId])) {
                            pointsByLine[lineaElettrica.objectId].sort((a, b) => {
                                const valueA = (a.sortingNumber != null && a.sortingNumber[lineaElettrica.objectId] != null) ? a.sortingNumber[lineaElettrica.objectId] : -1;
                                const valueB = (b.sortingNumber != null && b.sortingNumber[lineaElettrica.objectId] != null) ? b.sortingNumber[lineaElettrica.objectId] : -1;
                                return valueA - valueB;
                            });
                            lineaElettrica.linesMap = this.getLineMapByPoints(pointsByLine[lineaElettrica.objectId]);
                        }
                    });
                    return lineeElettricheDaScaricare.concat(lineeElettricheScaricate);
                })
            );
        }
    }

    public getLineMapByPoints(points: LocationElementsParse[]) {
        let lines = [];
        points.map(point => {
            return {
                lat: point.location.latitude,
                lng: point.location.longitude,
                id: point.objectId
            };
        })
            .forEach((point) => {
                const color = this.getColor(0);
                lines = this.getLineMap(lines, point, color);
            });
        return lines;
    }

    getLocationsElementsByLineaElettrica(lineaElettrica: LineaElettricaParse):
        Observable<LocationElementsParse[]> {
        const query = new LocationElementsParse().query;
        query.equalTo('lineeElettriche', lineaElettrica);
        return fromPromise(query.find()).pipe(
            map(points => {
                points.sort((a, b) => {
                    const valueA = (a.sortingNumber != null && a.sortingNumber[lineaElettrica.objectId] != null) ? a.sortingNumber[lineaElettrica.objectId] : -1;
                    const valueB = (b.sortingNumber != null && b.sortingNumber[lineaElettrica.objectId] != null) ? b.sortingNumber[lineaElettrica.objectId] : -1;
                    return valueA - valueB;
                });
                return points;
            })
        );
    }


    getLocationsElementsByLineeElettriche$(lineElettriche: LineaElettricaParse[]): Observable<KeyStringValue<LocationElementsParse[]>> {
        const params = paramsApiParse_v2({
            lineeElettricheIds: lineElettriche.map(l => l.objectId),
        });
        const fetched$ = fromPromise(Parse.Cloud.run('getLocationElementsByLineeElettriche', params));
        return fetched$
    }

    getLocationPointsByGeoBox$(sudWest: Parse.GeoPoint, northEast: Parse.GeoPoint,) {
        const params = paramsApiParse_v2({
            sudWest,
            northEast,
            progettoId: this.projectService.currentProject.objectId
        });
        const fetched$ = fromPromise(Parse.Cloud.run('getLocationPointsByGeoBox', params));
        return fetched$
    }

    getLineeElettricheByGeoBox$(sudWest: Parse.GeoPoint, northEast: Parse.GeoPoint,): Observable<{
        pointsLineeElettriche: KeyStringValue<LocationElementsParse[]>,
        lineeElettriche: LineaElettricaParse[]
    }> {
        const params = paramsApiParse_v2({
            sudWest,
            northEast,
            progettoId: this.projectService.currentProject.objectId
        });
        const fetched$ = fromPromise(Parse.Cloud.run('getLineeElettricheByGeoBox', params));
        return fetched$
    }

    distanceBetweenPoint(from: { latitude: number, longitude: number }, to: {
        latitude: number,
        longitude: number
    }): number {
        const fromGeoPoint = new Parse.GeoPoint(from.latitude, from.longitude);
        const toGeoPoint = new Parse.GeoPoint(to.latitude, to.longitude);
        return Math.round(fromGeoPoint.kilometersTo(toGeoPoint) * 1000 * 100) / 100;
    }

    getLineMap(electriclines, values, color: string): ElectricLinesMap[] {
        const today = new Date();
        const coordsL = {
            location: {latitude: values.lat, longitude: values.lng}
        };
        const newInterdistance: ElectricLinesMap = {
            color,
            start: coordsL.location,
        };
        if (values.id) {
            newInterdistance.objectId = values.id;
        } else {
            newInterdistance.customObjectId = (today.getTime() + values.lat + values.lng).toString(32);
        }
        const line: ElectricLinesMap[] = (arrayIsSet(electriclines)) ? [...electriclines] : [];
        line.push(newInterdistance);
        return line;
    }

    substitutionInLine(electriclines, pointToSubstituion: ElectricLinesMap, coords: { lat: number, lng: number }):
        ElectricLinesMap[] {
        const getId = (p) => (p.objectId != null) ? p.objectId : p.customObjectId;
        const line: ElectricLinesMap[] = (electriclines.length > 0) ? [...electriclines] : [];
        const pointObjectId = getId(pointToSubstituion);
        if (line.length > 0) {
            const index = line.findIndex(point => getId(point) === pointObjectId);
            if (index >= 0) {
                line[index].start.latitude = coords.lat;
                line[index].start.longitude = coords.lng;
            }
        }
        if (line.length > 1) {
            line.forEach((point, index) => {
                let successivePoint;
                if (index + 1 < line.length) {
                    successivePoint = line[index + 1];
                    const middlePoint = this.googleService.midPoint(point.start, successivePoint.start);
                    const distance = this.distanceBetweenPoint(point.start, successivePoint.start);
                    successivePoint.color = '#FFFFFF';
                    successivePoint.middlePoint = middlePoint;
                    successivePoint.distance = distance;
                }
            });
        }
        return line;
    }

    changeColorInElectricLineMap(electriclines: ElectricLinesMap[], color): ElectricLinesMap[] {
        const line: ElectricLinesMap[] = (electriclines.length > 0) ? electriclines : [];
        if (arrayIsSet(line)) {
            line.forEach((point, index) => {
                point.color = color;
            });
        }
        return line;
    }

    getColor(index: number): string {
        const colors = ['#FF0000', '#ff8c0b', '#1b29ff', '#cd19ff'];
        const colorIndex = index % colors.length;
        return colors[colorIndex];
    }

    getColorByScale(value, scaleColor: KeyStringValueString): string {
        if (scaleColor != null && value != null && stringIsSet(scaleColor[value])) {
            return scaleColor[value];
        } else {
            return '#000000';
        }
    }

    getForm(detail: any): UntypedFormGroup {
        const objForm = {};
        configurationPropertyElectricLine.forEach(element => {
            if (element.showInForm) {
                let valueForm;
                if (element.typeForm === typeFormValue.GEO_POINT) {
                    valueForm = {lat: detail[element.keyName].latitude, lng: detail[element.keyName].longitude};
                } else if (isPointerConfigurationElement(element)) {
                    valueForm = element.pointer.getValue(detail);
                } else if (arrayIsSet(element.possibleValues)) {
                    const pValueCurrent = this.getPossibleValuesCurrentValue(element.possibleValues, detail[element.keyName]);
                    valueForm = pValueCurrent.currentValue;
                } else {
                    valueForm = detail[element.keyName];
                }

                // tslint:disable-next-line:no-shadowed-variable
                objForm[element.keyName] = [{
                    value: valueForm,
                    disabled: !element.editable
                }, getValidator(element)];
                const subFormValue = this.getFormGroupChild(element, detail, detail[element.keyName], detail.className);
                if (isNotNullOrUndefined(subFormValue.formGroupChild)) {
                    objForm[subFormValue.subObjForm.key] = subFormValue.subObjForm.formGroup;
                }
            }
        });
        return this.fb.group(objForm);
    }

    getFormGroupChild(element: configurationElement, detail, referenceKey: string, className: string): {
        formGroupChild: { formGroupName, fields } | undefined,
        subObjForm: { key: string, formGroup: UntypedFormGroup } | undefined
    } {
        const subForm = getSubForm(element, referenceKey);
        let formGroupChild;
        let formGroup;
        if (isNotNullOrUndefined(subForm)) {
            const subObjForm = {};
            const subDetail = isNotNullOrUndefined(detail[element.forms.databaseKey]) ? detail[element.forms.databaseKey] : {};
            const subHtml = [];
            subForm
                .sort((aValue, bValue) => aValue.sortingValue - bValue.sortingValue)
                .forEach(subElement => {
                    const pValueCurrent = this.getPossibleValuesCurrentValue(subElement.possibleValues, subDetail[subElement.keyName]);
                    subObjForm[subElement.keyName] = [{
                        disabled: !element.editable,
                        value: pValueCurrent.currentValue,
                    }, getValidatorFormField(subElement)];
                    // tslint:disable-next-line:no-shadowed-variable
                    const subForm = {
                        type: subElement.typeForm,
                        possibleValues: pValueCurrent.possibleValues,
                        formControlName: subElement.keyName,
                        traduction: subElement.keyTraduction
                    };
                    subHtml.push(subForm);
                });
            formGroupChild = {formGroupName: undefined, fields: undefined};
            formGroupChild.formGroupName = element.forms.databaseKey;
            formGroupChild.fields = subHtml;
            formGroup = this.fb.group(subObjForm);
            return {formGroupChild, subObjForm: {key: element.forms.databaseKey, formGroup}};
        } else {
            return {formGroupChild: undefined, subObjForm: undefined};
        }
    }

    getPossibleValuesCurrentValue(possibleValues: PossibleValuesType[], cValue): {
        possibleValues: dataForm[] | undefined,
        currentValue: string | undefined
    } {
        let currentValue;
        if (arrayIsSet(possibleValues)) {
            const pValues = possibleValues.map((possibleValue) => {
                const keyForm = possibleValue.dataBaseValue.toString();
                if (keyForm === cValue) {
                    currentValue = keyForm;
                }
                const obj = {
                    valueForm: keyForm,
                    html: keyForm,
                    traduction: '',
                };
                if (possibleValue.traduction != null) {
                    obj.traduction = possibleValue.traduction;
                } else if (possibleValue.noTraduction != null) {
                    obj.traduction = possibleValue.noTraduction;
                }
                return obj;
            });
            const index = pValues.findIndex((value) => value.valueForm == cValue);
            if (index < 0 && cValue) {
                pValues.push({valueForm: cValue, html: cValue, traduction: cValue});
                currentValue = cValue;
            }
            return {possibleValues: pValues, currentValue};
        } else {
            return {possibleValues: undefined, currentValue: cValue};
        }
    }

    fetchPoint(objectId: string): Observable<LocationElementsParse> {
        const point = new LocationElementsParse();
        let point$: Observable<LocationElementsParse>;
        if (stringIsSet(objectId)) {
            const p = this.getLocalLocationElements(objectId);
            if (p == null) {
                point.objectId = objectId;
                point$ = fromPromise(point.fetch());
            } else {
                point$ = of(p);
            }
        } else {
            point.progetto = this.projectService.currentProject;
            point$ = of(point);
        }
        return point$;
    }

    fetchLine(objectId: string): Observable<LineaElettricaParse> {
        if (stringIsSet(objectId)) {
            const line = new LineaElettricaParse();
            line.objectId = objectId;
            return fromPromise(line.fetch()).pipe(
                switchMap((lineWithoutPoint) => this.getElectricLineMapByLineaElettrica([lineWithoutPoint])),
                map(lines => lines[0])
            );
        } else {
            return of(new LineaElettricaParse());
        }
    }

    destroyLocationElementsForLine(pointToDestroy: LocationElementsParse, line: LineaElettricaParse): Observable<{
        line: LineaElettricaParse,
        locationElement: LocationElementsParse
    }> {
        pointToDestroy.removeSortingNumberLineaElettricaByObjectLineaElettrica(line);
        pointToDestroy.removeLineaElettrica(line);
        return fromPromise(pointToDestroy.save()).pipe(
            map(pointDestroyed => {
                if (arrayIsSet(line.linesMap)) {
                    line.linesMap = line.linesMap.filter(p => p.objectId !== pointDestroyed.objectId);
                }
                return {locationElement: pointDestroyed, line};
            })
        );
    }

    separatesPointFromLine(pointToSeparates: LocationElementsParse, line: LineaElettricaParse): Observable<{
        line: LineaElettricaParse,
        locationElement: LocationElementsParse
    }> {
        const sortingNumber = pointToSeparates.sortingNumber[line.objectId];
        pointToSeparates.removeSortingNumberLineaElettricaByObjectLineaElettrica(line);
        return fromPromise(pointToSeparates.save()).pipe(
            switchMap(pointUpdated => {
                const newPoint = new LocationElementsParse();
                newPoint.progetto = this.projectService.currentProject;
                const pGoogle = this.googleService.newLatLng(
                    pointUpdated.location.latitude,
                    pointUpdated.location.longitude
                );
                const pAtDistance = this.googleService.getPointAtFixedDistanceAndAngle(pGoogle, 0.001, 0);
                newPoint.location = new Parse.GeoPoint(pAtDistance.lat(), pAtDistance.lng());
                const sortingNumberWithKey = {};
                sortingNumberWithKey[line.objectId] = sortingNumber;
                newPoint.addLineaElettrica(line);
                newPoint.addSortingNumber(sortingNumberWithKey);
                return fromPromise(newPoint.save());
            }),
            map((newPoint) => {
                if (arrayIsSet(line.linesMap)) {
                    const index = line.linesMap.findIndex(p => p.objectId === pointToSeparates.objectId);
                    if (index >= 0) {
                        const pointMap: ElectricLinesMap = {
                            objectId: newPoint.objectId,
                            color: this.getColor(0),
                            start: {latitude: newPoint.location.latitude, longitude: newPoint.location.longitude}
                        };
                        line.linesMap[index] = pointMap;
                    }
                }
                return {line, locationElement: newPoint};
            })
        );
    }

    extendLocationElementsToElectricLine(electricLine: LineaElettricaParse, locations: ElectricLinesMap[]): Observable<LineaElettricaParse> {
        if (arrayIsSet(locations)) {
            const pointToExtend = locations[0];
            const index = electricLine.linesMap.findIndex(p => p.objectId === pointToExtend.objectId);
            locations.splice(0, 1);
            const locationElements: LocationElementsParse[] = electricLine.linesMap.map(p => {
                const location = new LocationElementsParse();
                location.objectId = p.objectId;
                return location;
            });
            electricLine.linesMap = undefined;
            let obs$;
            if (index === 0) {
                const values = [];
                locations
                    .reverse()
                    .forEach((location, idex) => {
                        values.push(
                            this.getLocation(new Parse.GeoPoint(location.start.latitude, location.start.longitude))
                                .pipe(
                                    switchMap((locationElement) => {
                                        const sortingNumberWithKey = {};
                                        sortingNumberWithKey[electricLine.objectId] = idex;
                                        locationElement.addLineaElettrica(electricLine);
                                        locationElement.addSortingNumber(sortingNumberWithKey);
                                        return fromPromise(locationElement.save());
                                    })
                                )
                        );
                    });
                const lastIndex = values.length + 1;
                locationElements.forEach((location, idex) => {
                    const sortingNumberWithKey = {};
                    sortingNumberWithKey[electricLine.objectId] = idex + lastIndex;
                    location.addSortingNumber(sortingNumberWithKey);
                    values.push(
                        fromPromise(location.save())
                    );
                });
                obs$ = forkJoin(values);
            } else if (index > 0) {
                const lastSortingNumber = locationElements.length + 1;
                const values = [];
                locations
                    .forEach((location, idex) => {
                        values.push(
                            this.getLocation(new Parse.GeoPoint(location.start.latitude, location.start.longitude))
                                .pipe(
                                    switchMap((locationElement) => {
                                        const sortingNumberWithKey = {};
                                        sortingNumberWithKey[electricLine.objectId] = lastSortingNumber + idex;
                                        locationElement.addLineaElettrica(electricLine);
                                        locationElement.addSortingNumber(sortingNumberWithKey);
                                        return fromPromise(locationElement.save());
                                    })
                                )
                        );
                    });
                obs$ = forkJoin(values);
            }
            return obs$.pipe(
                switchMap(() => this.getElectricLineMapByLineaElettrica([electricLine])),
                map(electricLines => {
                    return electricLines[0]
                })
            );
        } else {
            return of(electricLine);
        }
    }

    public updateLocationPoint(line: LineaElettricaParse, point: LocationElementsParse, location: Parse.GeoPoint): Observable<LocationElementsParse> {
        const m = 0.5;
        let sortingNumber;
        if (point.sortingNumber != null && typeof point.sortingNumber[line.objectId] === 'number') {
            sortingNumber = point.sortingNumber[line.objectId];
        }
        const query = new LocationElementsParse().query;
        query.equalTo('progetto', this.projectService.currentProject);
        query.withinKilometers('location', location, m / 1000, true);
        query.limit(1);
        return fromPromise(query.first()).pipe(
            switchMap((element) => {
                if (element != null) {
                    if (arrayIsSet(line.linesMap)) {
                        const index = line.linesMap.findIndex(p => p.objectId === point.objectId);
                        if (index >= 0) {
                            line.linesMap[index].objectId = element.objectId;
                            sortingNumber = (sortingNumber != null) ? sortingNumber : index;
                        }
                    }
                    point.removeSortingNumberLineaElettricaByObjectLineaElettrica(line);
                    const sortingNumberWithKey = {};
                    sortingNumberWithKey[line.objectId] = sortingNumber;
                    element.addLineaElettrica(line);
                    element.addSortingNumber(sortingNumberWithKey);
                    return fromPromise(point.save()).pipe(
                        switchMap(() => fromPromise(element.save()))
                    );
                } else {
                    point.location = location;
                    return fromPromise(point.save());
                }
            })
        );
    }

    changeColorLinesMap(electricLines: LineaElettricaParse[], key: string, scaleColor: KeyStringValueString) {
        let lines;
        if (arrayIsSet(electricLines)) {
            lines = [...electricLines];
        }
        lines.forEach((line) => {
            if (arrayIsSet(line.linesMap)) {
                let keyScale;
                if (isDate(line[key])) {
                    keyScale = this.scaleColorService.getKeyDate(line[key]);
                } else {
                    keyScale = this.scaleColorService.createKey(line[key]);
                }
                this.changeColorInElectricLineMap(line.linesMap, this.getColorByScale(keyScale, scaleColor));
            }
        });
        return lines;
    }


}
