import {Injectable} from '@angular/core';
import * as Parse from 'parse';
import {fromPromise} from 'rxjs/internal-compatibility';
import {ExportedFile} from '../../models/ExportedFile.Parse';
import {ProgettiParse} from '../../models/Progetti.Parse';
import {FileDaEsportare} from '../../models/FileDaEsportare.Parse';
import {UserService} from './user.service';
import {combineLatest} from 'rxjs';
import {ProjectService} from "./project.service";
import {stringIsSet} from "../../models/Models";

@Injectable({
    providedIn: 'root'
})
export class ExportedFileService {

    constructor(private userService: UserService,
                private projectService: ProjectService) {

    }

    get project(): ProgettiParse {
        return this.projectService.currentProject;
    }

    public getAllByProgectId(projectId) {
        const query = new Parse.Query(ExportedFile);
        const progetto = new ProgettiParse();
        progetto.id = stringIsSet(projectId) ? projectId : this.project.objectId;
        query.equalTo('progetto', this.project);
        query.descending('createdAt');
        return fromPromise(query.find({sessionToken: this.userService.sessionToken()}));
    }

    public sendRequestOnFileDaEsportare(form, lang = 'it') {
        let arrayDaEsportare = [];
        let index = 0;
        let progetto = new ProgettiParse();
        progetto.id = this.project.objectId;
        // il form contiene valori boolean e vengono creati per ogni richiesta le chiamate
        Object.keys(form).forEach((key) => {
            if (form[key]) {
                const fileDaEsportare = new FileDaEsportare();
                fileDaEsportare.oggettoDaEsportare = key;
                fileDaEsportare.progetto = progetto;
                fileDaEsportare.language = lang;
                arrayDaEsportare[index] = fromPromise(fileDaEsportare.save());
                index++;
            }
        });
        // l'operatore combine latest non dovrebbe fermarsi nel caso un salvataggio andrà in errore
        return combineLatest(arrayDaEsportare);
    }


    public getFileInCoda(projectId) {
        const query = new Parse.Query(FileDaEsportare);
        const progetto = new ProgettiParse();
        progetto.id = stringIsSet(projectId) ? projectId : this.project.objectId;
        query.equalTo('progetto', progetto);
        query.include('requestedBy');
        query.descending('createdAt');
        return fromPromise(query.find({sessionToken: this.userService.sessionToken()}));
    }

}
