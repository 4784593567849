import {Pipe, PipeTransform, Predicate} from '@angular/core';

@Pipe({
    name: 'filterByPredicateFunction'
})
export class FilterByPredicateFunctionPipe implements PipeTransform {

    transform<T>(values: T[], predicateFunction: (a, ...args) => {}, ...args): T[] {
        if (Array.isArray(values) && predicateFunction != null) {
            const filterd = values.filter(value => {
                return predicateFunction(value, ...args);
            });
            return [...filterd];
        } else {
            return values;
        }
    }

}
