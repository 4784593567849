import {Injectable} from '@angular/core';
import {PuntiLuceParse} from "../../models/PuntiLuce.Parse";
import * as Parse from 'parse';
import {fromPromise} from "rxjs/internal-compatibility";
import {FotoCircuitiParse} from "../../models/FotoCircuiti.Parse";
import {CircuitiParse} from "../../models/Circuiti.Parse";
import {chiaviFiltri} from "../../models/Models";
import {switchMap} from "rxjs/operators";
import {SafeUrl} from '@angular/platform-browser';
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class SavePhotoService {

    constructor() {
    }

    public createFotoCircuiti(circuito: CircuitiParse, fotoEsterno: any | null, fotoInterno: any | null): Observable<FotoCircuitiParse> {
        let fotoCircuito = new FotoCircuitiParse();
        fotoCircuito.circuito = circuito;
        fotoCircuito.progetto = circuito.progetto;
        if (circuito.numeroQuadro != null) {
            fotoCircuito.numeroQuadro = circuito.numeroQuadro
        }
        if (fotoEsterno && fotoEsterno.fileUploadControl) {
            const parseFotoEsterno = new Parse.File(fotoEsterno.nome, fotoEsterno.fileUploadControl);
            fotoCircuito.fotoEsterno = parseFotoEsterno;
        }
        if (fotoInterno && fotoInterno.fileUploadControl) {
            const parseFotoInterno = new Parse.File(fotoInterno.nome, fotoInterno.fileUploadControl);
            fotoCircuito.fotoInterno = parseFotoInterno;
        }
        return fromPromise(fotoCircuito.save());
    }

    public updateFotoCircuito(fotoCirucito: FotoCircuitiParse, images: { fotoEsterno?: any, fotoInterno?: any }): Observable<FotoCircuitiParse> {
        if ('fotoEsterno' in images) {
            const fotoEsterno = images.fotoEsterno;
            if (fotoEsterno == null) {
                fotoCirucito.unset('fotoEsterno');
            } else if (fotoEsterno && fotoEsterno.fileUploadControl) {
                const parseFotoEsterno = new Parse.File(fotoEsterno.nome, fotoEsterno.fileUploadControl, "image/jpg");
                fotoCirucito.fotoEsterno = parseFotoEsterno;
            }
        }
        if ('fotoInterno' in images) {
            const fotoInterno = images.fotoInterno
            if (fotoInterno == null) {
                fotoCirucito.unset('fotoInterno');
            } else if (fotoInterno && fotoInterno.fileUploadControl) {
                const parseFotoInterno = new Parse.File(fotoInterno.nome, fotoInterno.fileUploadControl, "image/jpg");
                fotoCirucito.fotoInterno = parseFotoInterno;
            }
        }
        return fromPromise(fotoCirucito.save());
    }

    public updatePointerFotoCircuitiCircuiti(circuito: CircuitiParse, fotoCircuito: FotoCircuitiParse) {
        circuito.foto = fotoCircuito;
        return fromPromise(circuito.save());
    }

    public updateFotoPuntiLuce(puntoLuce: PuntiLuceParse, images: { foto?: { html: SafeUrl, nome: string, file: number[], fileUploadControl: File } }) {
        if ('foto' in images) {
            if (images.foto && images.foto.fileUploadControl) {
                const parseFotoEsterno = new Parse.File(images.foto.nome, images.foto.fileUploadControl);
                puntoLuce.foto = parseFotoEsterno;
            } else {
                puntoLuce.unset('foto');
            }
        }
        return fromPromise(puntoLuce.save());
    }

}


// resettare
// adding loader and disattiva bopttone