import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';

@Component({
    selector: 'app-choose-actions',
    templateUrl: './choose-actions.component.html',
    styleUrls: ['./choose-actions.component.scss']
})
export class ChooseActionsComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<ChooseActionsComponent>,
                @Inject(MAT_DIALOG_DATA) public data: { actions: any[], message: string }, ) {
    }

    ngOnInit(): void {
    }

    selectActions(actions: any) {
        this.dialogRef.close(actions);
    }

}
