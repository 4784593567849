import {Injectable} from '@angular/core';
import jsPDF, {TextOptionsLight} from "jspdf";
import autoTable from "jspdf-autotable";
import {OrganizzazioneParse} from "../../models/Organizzazione.Parse";
import {TranslateService} from "@ngx-translate/core";
import {formatDate} from "@angular/common";
import {font} from "../../../assets/customFont/fontBase64";
import {
    arrayIsSet,
    cleanToSpecialCharacterAndLowerCaseString,
    isNotNullOrUndefined,
    stringIsSet
} from "../../models/Models";
import {environment} from "../../../environments/environment";
import {LayoutType, StradeParse} from "../../models/Strade.Parse";
import {ComputeResult, ComputeResults, LayoutItemResult, Photometry, StreetLayoutItemType} from "./streets.service";
import {IpeaCalcService} from "./ipea-calc.service";

@Injectable({
    providedIn: 'root'
})
export class PrintPageService {

    private helveticaNeue = font.helveticaNeue;

    constructor(private translate: TranslateService,
                private ipeaCalcService: IpeaCalcService) {
        this.hunaLogo = new Image();
        this.hunaLogo.src = 'assets/logo.png';
    }

    hunaLogo;
    _organizzazioneLogo;


    public async getBase64FromUrl(url): Promise<string> {
        const headers = new Headers();
        headers.set('Access-Control-Allow-Origin', '*');
        headers.set('X-Parse-Javascript-Key', environment.parse.javascriptKey);
        headers.set("X-Parse-Application-Id", environment.parse.applicationId);
        const blob = await fetch(url, {
            cache: 'no-cache',
            headers: headers,
            referrerPolicy: 'strict-origin-when-cross-origin'
        })
            .then(data => {
                return data.blob();
            })
            .catch((e) => {
                console.error(e);
                return null
            });
        return new Promise((resolve) => {
            if (!isNotNullOrUndefined(blob)) {
                resolve(null);
            } else {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    const base64data = reader.result;
                    resolve(base64data as string);
                }
                reader.onerror = (e) => {
                    resolve(null);
                }
            }
        });
    }

    public async getImageWithUrl(url: string): Promise<{ image: HTMLImageElement } | undefined> {
        const base64 = await this.getBase64FromUrl(url);
        if (!isNotNullOrUndefined(base64)) {
            return undefined;
        } else {
            return new Promise(resolve => {
                const image = new Image();
                image.src = base64;
                image.onload = () => {
                    resolve({image})
                }
            })

        }
    }

    public set organizzazioneLogo(image) {
        this._organizzazioneLogo = image;
    }

    public get organizzazioneLogo(): HTMLImageElement {
        return this._organizzazioneLogo
    }

    private donwloadLogoOrganizazzioneFailed = false;

    private _headColor = [243, 130, 50];
    public set headColor(color: [number, number, number]) {
        this._headColor = color;
    }

    private _headHeight = 30;

    public set headHeight(height) {
        this._headHeight = height;
    }

    public get headHeight() {
        return this._headHeight;
    }

    public addFont(doc: jsPDF) {
        doc.addFileToVFS('helveticaWithEuroSymbol.ttf', this.helveticaNeue);
        doc.addFont('helveticaWithEuroSymbol.ttf', 'helvetivaNeue', 'normal');
        doc.setFont('helvetivaNeue', 'normal');
    }

    public firstPage(doc: jsPDF, organizzazione: OrganizzazioneParse, today: number, logo: HTMLImageElement | undefined = undefined) {
        this.organizzazioneLogo = undefined;
        doc.roundedRect(10, 10, 190, 35, 7, 7, 'S');
        doc.setFontSize(20);
        doc.setFont('Helvetica', 'bold');
        if (isNotNullOrUndefined(organizzazione)) {
            doc.text(organizzazione.nome, 180, 30, {align: 'right'});
        }
        let hunaImage = this.hunaLogo;
        const height = 10;
        const width = ((hunaImage.width / hunaImage.height) * height);
        let heightOrganizzazioneLogo;
        let widthOrganizzazioneLogo = 35;
        if (logo != null) {
            this.organizzazioneLogo = logo;
        } else if (isNotNullOrUndefined(organizzazione) && isNotNullOrUndefined(organizzazione.logo) && isNotNullOrUndefined(organizzazione.logo.url)) {
            const image = new Image();
            image.src = organizzazione.logo.url()
            this.organizzazioneLogo = image;
        }
        if (isNotNullOrUndefined(this.organizzazioneLogo)) {
            heightOrganizzazioneLogo = ((this.organizzazioneLogo.naturalHeight / this.organizzazioneLogo.naturalWidth) * widthOrganizzazioneLogo);
        }
        if (!this.donwloadLogoOrganizazzioneFailed && isNotNullOrUndefined(this.organizzazioneLogo)) {
            try {
                if (heightOrganizzazioneLogo > this.headHeight) {
                    heightOrganizzazioneLogo = this.headHeight;
                    widthOrganizzazioneLogo = ((this.organizzazioneLogo.naturalWidth / this.organizzazioneLogo.naturalHeight) * heightOrganizzazioneLogo);
                }
                doc.addImage(this.organizzazioneLogo, 'png', 30, 10 + 35 / 2 - heightOrganizzazioneLogo / 2, widthOrganizzazioneLogo, heightOrganizzazioneLogo, 'logoAzienda', 'MEDIUM');
            } catch (e) {
                console.error(e)
                this.donwloadLogoOrganizazzioneFailed = true;
            }
        }
        doc.addImage(hunaImage, 'JPEG', 30, 262.5, width, height, 'logo', 'SLOW');
        doc.roundedRect(10, 250, 190, 35, 7, 7, 'S');
        const extendToday = formatDate(today, 'mediumDate', this.translate.currentLang);
        doc.text(extendToday, 130, 270);
        doc.setFont('helvetivaNeue', 'normal');
        doc.setFontSize(10);
    }


    addshape(doc: jsPDF, shape: string, color, x, y, r) {
        const fillColor = doc.getFillColor();
        doc.setDrawColor(color);
        doc.setFillColor(color);
        let xPosition;
        let yPosition;
        if (shape == 'circle') {
            xPosition = x + r;
            yPosition = y;
            doc.circle(xPosition, yPosition, r / 2, 'FD');
            xPosition += r / 2;
            yPosition += r / 2;
        } else if (shape == 'square') {
            xPosition = x + r / 2;
            yPosition = y - r / 2;
            doc.roundedRect(xPosition, yPosition, r, r, r * 0.1, r * 0.1, 'FD')
            xPosition += r;
            yPosition += r;
        } else if (shape == 'triangle') {
            xPosition = x + r / 2;
            yPosition = y - r / 2;
            doc.triangle(x + r / 2, y + r - r / 2, x + r / 2 + r / 2, y - r / 2, x + r + r / 2, y + r - r / 2, 'FD');
            xPosition += r;
            yPosition += r;
        }
        doc.setDrawColor(fillColor);
        doc.setFillColor(fillColor);
        return {lastX: xPosition, lastY: yPosition}
    }

    public addHeader(doc: jsPDF, organizzazione: OrganizzazioneParse, projectName: string, today, reports: {
        page: number[],
        text: string
    }[] = undefined, image2: HTMLImageElement | undefined = undefined) {
        const numbePage = doc.getNumberOfPages();
        let i = 0;
        let hunaImage = this.hunaLogo;
        const heightHunaLogo = 5;
        const widthHunaLogo = ((hunaImage.naturalWidth / hunaImage.naturalHeight) * heightHunaLogo);
        let widthOrganizzazioneLogo = 35;
        let heightOrganizzazioneLogo;
        if (isNotNullOrUndefined(this.organizzazioneLogo)) {
            heightOrganizzazioneLogo = ((this.organizzazioneLogo.naturalHeight / this.organizzazioneLogo.naturalWidth) * widthOrganizzazioneLogo);
        }
        while (i < numbePage) {
            if (i !== 0) {
                if (!this.donwloadLogoOrganizazzioneFailed && isNotNullOrUndefined(this.organizzazioneLogo)) {
                    try {
                        if (heightOrganizzazioneLogo > 15) {
                            heightOrganizzazioneLogo = 15;
                            widthOrganizzazioneLogo = ((this.organizzazioneLogo.naturalWidth / this.organizzazioneLogo.naturalHeight) * heightOrganizzazioneLogo);
                        }
                        doc.addImage(this.organizzazioneLogo, 'png', 30 - widthOrganizzazioneLogo / 2, this.headHeight / 2 - heightOrganizzazioneLogo / 2, widthOrganizzazioneLogo, heightOrganizzazioneLogo, 'logoAzienda', 'MEDIUM');
                        if (image2 != null) {
                            let widthImage2Logo = 35;
                            let heightImage2Logo;
                            heightImage2Logo = ((image2.naturalHeight / image2.naturalWidth) * widthImage2Logo);
                            if (heightImage2Logo > 15) {
                                heightImage2Logo = 15;
                                widthImage2Logo = ((image2.naturalWidth / image2.naturalHeight) * heightImage2Logo);
                            }
                            doc.addImage(image2, 'png', 30+widthOrganizzazioneLogo/2+ widthImage2Logo / 2, this.headHeight / 2 - heightImage2Logo / 2, widthImage2Logo, heightImage2Logo, 'logoAziendaImage2', 'MEDIUM');
                        }
                    } catch (e) {
                        console.error(e)
                        this.donwloadLogoOrganizazzioneFailed = true;
                        widthOrganizzazioneLogo = 0;
                    }
                } else {
                    widthOrganizzazioneLogo = 0;
                }
                doc.addImage(this.hunaLogo, 'png', 185 - widthHunaLogo / 2, this.headHeight / 2 - heightHunaLogo / 2, widthHunaLogo, heightHunaLogo, 'logoHuna', 'MEDIUM');

                doc.setPage(i + 1);
                if (arrayIsSet(reports)) {
                    const currentPage = doc.getCurrentPageInfo().pageNumber;
                    const index = reports.findIndex(textPage => textPage.page.includes(currentPage));
                    if (index >= 0) {
                        const text = reports[index].text;
                        const firstPageReport = Math.min(...reports[index].page) + 1;
                        const sizeRemport = doc.getTextDimensions(text);
                        this.setBoldText(doc);
                        this.addTextWithLink(doc, text, 185 + widthHunaLogo / 2 - sizeRemport.w / 2, this.headHeight - sizeRemport.h, {pageNumber: firstPageReport});
                        this.setDefaultText(doc);
                    }
                }
                const page = this.translate.instant('pageNumber') + ' ' + i;
                const nomeOrganizzazione = (isNotNullOrUndefined(organizzazione) && stringIsSet(organizzazione.nome)) ? organizzazione.nome : '';
                const nomeProgetto = (isNotNullOrUndefined(projectName) && stringIsSet(projectName)) ? projectName : '';
                const heightText = doc.getTextDimensions(nomeOrganizzazione).h;
                const widthText = {};
                widthText[nomeOrganizzazione] = doc.getTextWidth(nomeOrganizzazione);
                widthText[nomeProgetto] = doc.getTextWidth(nomeProgetto) - 15;
                widthText[today] = doc.getTextWidth(today);
                widthText[page] = doc.getTextWidth(page);
                const allKeyText = Object.keys(widthText);

                let spaceBetweenWords = (185 - widthOrganizzazioneLogo / 2 - 30 + widthHunaLogo / 2);
                let textHeaderLength = 0;
                allKeyText.forEach(key => {
                        textHeaderLength += widthText[key]
                    }
                )
                spaceBetweenWords -= textHeaderLength;
                spaceBetweenWords /= (allKeyText.length + 1);
                let xWord = 30 + widthOrganizzazioneLogo;
                allKeyText.forEach((key) => {
                    xWord += widthText[key] / 2 + spaceBetweenWords;
                    this.addBoldString(doc, key, xWord, this.headHeight / 2, 'center', "middle");
                });
                doc.line(10, this.headHeight, 200, this.headHeight);
            }
            i++;
        }
    }

    public addImage(doc: jsPDF, image, maxWidth: number, maxHeight: number, x: number, y: number, alignxy: 'center center' | 'center start' | 'start start' = 'center center', ratio = null): {
        lastX: number,
        lastY: number
    } {
        let localImage = new Image();
        localImage.src = image;
        let height = maxHeight;
        let localRatio;
        if (isNotNullOrUndefined(ratio)) {
            localRatio = ratio;
        } else {
            localRatio = (isNaN(localImage.naturalWidth / localImage.naturalHeight)) ? localImage.width / localImage.height : localImage.naturalWidth / localImage.naturalHeight;
        }
        let width = (localRatio * height);
        if (width > maxWidth) {
            width = maxWidth;
            height = (1 / localRatio) * width;
        }
        let xPosition;
        let yPosition;
        if (alignxy == 'start start') {
            xPosition = x;
            yPosition = y;
        } else if (alignxy === 'center center') {
            xPosition = x - width / 2;
            yPosition = y - height / 2;
        } else {
            xPosition = x - width / 2;
            yPosition = y;
        }
        try {
            doc.addImage(localImage, 'png', xPosition, yPosition, width, height);
            return {lastX: x + width / 2, lastY: y + height}
        } catch (e) {
            return {lastX: x, lastY: y};
        }
    }

    public addTwoCenteredImage(doc: jsPDF, images: any[], x, y, ratio: number []): { lastY, lastX } {
        const widthResultImage = 180;
        const heigthResultImage = 180 * (1 / ratio[0]);
        let image0 = new Image();
        image0.src = images[0];
        let image1 = new Image();
        image1.src = images[1];
        let lastY = y;
        doc.addImage(image0, 'png', x - widthResultImage / 2, y - heigthResultImage / 2, widthResultImage, widthResultImage * (1 / ratio[0]));
        if (ratio[1] > 2) {
            doc.addImage(image1, 'png', x - widthResultImage / 2, y - widthResultImage * (1 / ratio[1]) / 2, widthResultImage, widthResultImage * (1 / ratio[1]))
            lastY = y + widthResultImage * (1 / ratio[1]) / 2;
        } else {
            doc.addImage(image1, 'png', x - heigthResultImage * (ratio[1]) / 2, y - heigthResultImage / 2, heigthResultImage * (ratio[1]), heigthResultImage)
            lastY = y + heigthResultImage
        }
        return {lastY, lastX: x + widthResultImage / 2}
    }

    public addH1(doc: jsPDF, title, x: number, y: number): { lastX: number, lastY: number } {
        const fontSize = 25;
        doc.setFontSize(fontSize);
        doc.setFont('Helvetica', 'bold');
        doc.text(title, x, y, {align: 'center'});
        doc.setFont('helvetivaNeue', 'normal');
        doc.setFontSize(10);
        return {lastX: x + doc.getTextWidth(title) / 2, lastY: y + fontSize / 2};
    }

    public addH2(doc: jsPDF, title, x: number, y: number): { lastX: number, lastY: number } {
        const fontSize = 20;
        doc.setFontSize(fontSize);
        doc.setFont('Helvetica', 'bold');
        doc.text(title, x, y, {align: 'center'});
        doc.setFont('helvetivaNeue', 'normal');
        doc.setFontSize(10);
        return {lastX: x + doc.getTextWidth(title) / 2, lastY: y + fontSize / 2};
    }

    public addH3(doc: jsPDF, title, x: number, y: number): { lastX: number, lastY: number } {
        const fontSize = 17;
        doc.setFontSize(fontSize);
        doc.setFont('Helvetica', 'bold');
        doc.text(title, x, y, {align: 'center'});
        doc.setFont('helvetivaNeue', 'normal');
        doc.setFontSize(10);
        return {lastX: x + doc.getTextWidth(title) / 2, lastY: y + fontSize / 2};
    }

    public addH4(doc: jsPDF, title, x: number, y: number): { lastX: number, lastY: number } {
        this.setH4Text(doc);
        doc.text(title, x, y, {align: 'center'});
        const fontSize = doc.getFontSize();
        this.setDefaultText(doc);
        return {lastX: x + doc.getTextWidth(title) / 2, lastY: y + fontSize / 2};
    }

    public addText(doc: jsPDF, title, x: number, y: number, options: TextOptionsLight = {align: "center"}, bold = false): {
        lastX: number,
        lastY: number
    } {
        doc.text(title, x, y, options);
        if (bold) {
            doc.setFont('Helvetica', 'bold');
        }
        const fontSize = doc.getFontSize();
        const sizeText = doc.getTextDimensions(title, {...options, fontSize: fontSize})
        if (bold) {
            doc.setFont('helvetivaNeue', 'normal')
        }
        return {lastX: x + sizeText.w / 2, lastY: y + sizeText.h};
    }

    public previewAddText(doc: jsPDF, title, x: number, y: number, options: TextOptionsLight = {align: "center"}): {
        lastX: number,
        lastY: number
    } {
        const fontSize = doc.getFontSize();
        const sizeText = doc.getTextDimensions(title, {...options, fontSize: fontSize})
        return {lastX: x + doc.getTextWidth(title) / 2, lastY: y + sizeText.h};
    }

    public addBoldString(doc: jsPDF, title, x: number, y: number,
                         align: "left" | "center" | "right" | "justify" = "left",
                         baseLine: | "alphabetic" | "ideographic" | "bottom" | "top" | "middle" | "hanging" = undefined): {
        lastX: number,
        lastY: number
    } {
        doc.setFont('Helvetica', 'bold');
        const options = {align: align};
        if (isNotNullOrUndefined(baseLine)) {
            options['baseline'] = baseLine;
        }
        doc.text(title, x, y, options);
        doc.setFont('helvetivaNeue', 'normal');
        return {lastX: x + doc.getTextWidth(title), lastY: y + doc.getFontSize()};
    }

    public addTable(doc: jsPDF, data: {}[], title: string, direction: 'horizzontal' | 'vertical', startY = 0, tableWidth: "auto" | "wrap" | number = 'auto', firstRowIsBold = false, textWithLink: {
        [k: string]: { url?: string, pageNumber?: number }
    } = undefined, columnStyles = undefined) {
        const last: { lastX, lastY } = {lastX: undefined, lastY: undefined};
        const keys = Object.keys(data[0]);
        let head;
        if (direction === 'vertical') {
            head = [{
                content: (isNotNullOrUndefined(title)) ? title : '',
                colSpan: keys.length,
                styles: {halign: 'center', fillColor: this._headColor}
            }]
        } else {
            head = keys.map((key) => this.translate.instant(key));
        }
        keys.sort((a, b) => a.localeCompare(b))
        const value = data.map((row) => {
                return keys.map(key => row[key])
            }
        );
        const pageWidth = doc.internal.pageSize.getWidth();
        const tableWithCast = parseFloat(tableWidth.toString());
        let marginFirstTable;
        let marginSecondTable;
        if (!isNaN(tableWithCast)) {
            const avaiableSpace = pageWidth - tableWithCast * 2;
            marginFirstTable = {top: 0, left: avaiableSpace * (1 / 4)};
            marginSecondTable = {top: 0, left: pageWidth - tableWithCast - marginFirstTable.left};
        }
        autoTable(doc, {
            tableWidth: tableWidth,
            startY: startY,
            margin: marginFirstTable,
            head: [head],
            body: value,
            theme: "striped",
            styles: {
                cellWidth: 'wrap',
                valign: 'middle',
                font: 'times',
                fontSize: 10,
                overflow: 'linebreak',
            },
            columnStyles,
            headStyles: {
                fillColor: (this._headColor as [number, number, number])
            },
            didParseCell: (data) => {
                data.cell.styles.font = 'helvetivaNeue';
                data.cell.styles.fontSize = 9;
                const text = this.getTextInformation(data.cell.text[0]);
                if (isNotNullOrUndefined(text)) {
                    text.keys.forEach((subElement) => {
                        if (subElement.key === 'fontStyle') {
                            data.cell.styles.fontStyle = subElement.value;
                        }
                        if (subElement.key === 'fontSize') {
                            data.cell.styles.fontSize = subElement.value;
                        }
                        if (subElement.key === 'textColor') {
                            data.cell.styles.textColor = JSON.parse(subElement.value);
                        }
                    });
                    data.cell.text[0] = text.text;
                }
                if (data.column.index == 0 && data.section !== 'head') {
                    data.cell.styles.fontStyle = "bold";
                    data.cell.styles.textColor = [0, 0, 0];
                }
                if (data.row.index == 0 && data.section !== 'head' && firstRowIsBold) {
                    data.cell.styles.fontStyle = "bold";
                    data.cell.styles.textColor = [0, 0, 0];
                }
                if (textWithLink != null && textWithLink[data.cell.text[0]] != null) {
                    data.cell.styles.textColor = [0, 84, 194];
                }
            },
            willDrawCell: (data) => {
                const heigthText = data.cell.height;
                const widthText = data.cell.width;
                if (arrayIsSet(data.cell.text)) {
                    const text = data.cell.text.reduce((text, subText) => {
                        text += subText;
                        return text
                    }, '')

                    if (textWithLink != null) {
                        let options;
                        let textUrl;
                        if (textWithLink[text] != null) {
                            options = {...textWithLink[text], maxWidth: widthText}
                            textUrl = text;
                        } else {
                            const keys = Object.keys(textWithLink);
                            const index = keys.findIndex(key => cleanToSpecialCharacterAndLowerCaseString(key) === cleanToSpecialCharacterAndLowerCaseString(text))
                            if (index >= 0) {
                                const key = keys[index];
                                options = {...textWithLink[key], maxWidth: widthText}
                                textUrl = text;
                            }
                        }
                        if (stringIsSet(textUrl)) {
                            doc.textWithLink(textUrl, data.cell.x, data.cell.y + heigthText / 2, options)
                        }
                    }
                }
            },
            didDrawPage: (data) => {
                //quando sdoppia la tabella in piu pagine la scende dell altezza dell header
                data.settings.margin.top = this.headHeight + 10
                last['lastX'] = data.cursor.x;
                last['lastY'] = data.cursor.y;
            }
        });
        this.setDefaultColor(doc);
        return last;
    }


    /**
     *
     * @param doc il riferimento del documento da creare
     * @param multipleTableData ogni elemento dell' array è una tabella
     * @param startY la prima posizione utile
     * @param firstRowIsBold se vogliamo tabelle con anche la prima riga in grassetto
     * le tabelle sono di misura uguale e vengno calcolate in base al numero di tabelle presenti
     */
    public sideBySideTable(doc: jsPDF, multipleTableData: {}[][], title: string[], direction: 'horizzontal' | 'vertical', tableWidth = 50, startY = 0, startX = 0, firstRowIsBold = false,): {
        lastX: number,
        lastY: number
    } {
        const numberOfTable = multipleTableData.length;
        const last: { lastX, lastY } = {lastX: undefined, lastY: undefined};
        let lastP = startX;
        multipleTableData.forEach((data, index) => {
            const keys = Object.keys(data[0]);
            let head;
            if (direction === 'vertical') {
                head = [{
                    content: (isNotNullOrUndefined(title[index])) ? title[index] : '',
                    colSpan: keys.length,
                    styles: {halign: 'center', fillColor: this._headColor}
                }]
            } else {
                head = keys;
            }
            const value = data.map((rawRow) => {
                const row = Object.values(rawRow);
                if (direction === 'vertical') {
                    row[0] = this.translate.instant(row[0] as string);
                }
                return row
            });
            const pageWidth = doc.internal.pageSize.getWidth();
            // viene ridotta la dimensione
            const riduction = (numberOfTable > 1) ? 0.8 : 1;
            let tableWithCast = Math.round((pageWidth - startX) / numberOfTable) * riduction;
            let marginTable;
            if (!isNaN(tableWithCast)) {
                const avaiableSpace = (pageWidth - startX) - tableWithCast * numberOfTable;
                const lastX = avaiableSpace * (1 / (numberOfTable + 1));
                last['spaceBetweenTable'] = lastX;
                marginTable = {top: 0, left: lastP + lastX};
                lastP = marginTable.left + tableWithCast;
            }
            tableWithCast = (tableWithCast < tableWidth) ? tableWithCast : tableWidth;
            autoTable(doc, {
                tableWidth: tableWithCast,
                startY: startY,
                margin: marginTable,
                head: [head],
                body: value,
                theme: "striped",
                styles: {
                    cellWidth: 'wrap',
                    valign: 'middle',
                    font: 'times',
                    fontSize: 12,
                    overflow: 'linebreak'
                },
                headStyles: {
                    fillColor: (this._headColor as [number, number, number])
                },
                didParseCell: (data) => {
                    data.cell.styles.font = 'helvetivaNeue';
                    data.cell.styles.fontSize = 9;
                    const text = this.getTextInformation(data.cell.text[0]);
                    if (isNotNullOrUndefined(text)) {
                        text.keys.forEach((subElement) => {
                            if (subElement.key === 'fontStyle') {
                                data.cell.styles.fontStyle = subElement.value;
                            }
                            if (subElement.key === 'fontSize') {
                                data.cell.styles.fontSize = subElement.value;
                            }
                            if (subElement.key === 'textColor') {
                                data.cell.styles.textColor = JSON.parse(subElement.value);
                            }
                        });
                        data.cell.text[0] = text.text;
                    }
                    if (data.column.index == 0 && data.section !== 'head') {
                        data.cell.styles.fontStyle = "bold";
                        data.cell.styles.textColor = [0, 0, 0];
                    }
                    if (data.row.index == 0 && data.section !== 'head' && firstRowIsBold) {
                        data.cell.styles.fontStyle = "bold";
                        data.cell.styles.textColor = [0, 0, 0];
                    }
                },
                didDrawPage: (data) => {
                    //quando sdoppia la tabella in piu pagina la scende dell altezza dell header
                    data.settings.margin.top = this.headHeight + 10;
                    last['lastX'] = data.cursor.x;
                    last['lastY'] = data.cursor.y;
                }
            });
        });
        return last;
    }

    public convertToPdf(doc: jsPDF, title) {
        doc.save(title + '.pdf');
    }


    // bisogna passare html collection da stamoare
    print(elementPrintable, chiaveElementiNascosti: string) {
        const w = window.open();
        // tslint:disable-next-line:variable-name
        const is_chrome = navigator.userAgent.toUpperCase().includes('CHROME');
        const allPage = elementPrintable;
        const numberPage = allPage.length;
        let indice = 0;
        const indiciHidden: { indice, subIndice }[] = [];
        while (indice < numberPage) {
            const subElement = allPage.item(indice).getElementsByClassName(chiaveElementiNascosti);
            if (isNotNullOrUndefined(subElement)) {
                let subIndice = 0;
                while (subIndice < subElement.length) {
                    if (subElement.item(subIndice).hidden) {
                        indiciHidden.push({indice: indice, subIndice: subIndice});
                        subElement.item(subIndice).hidden = false;
                    }
                    subIndice++;
                }
            }
            w.document.write(allPage.item(indice).innerHTML);
            indice++;
        }
        indiciHidden.forEach((indice) => {
            elementPrintable.item(indice.indice).getElementsByClassName('visualizedOnlyInPrint').item(indice.subIndice).hidden = true;
        });
        if (is_chrome) {
            setTimeout(() => {
                w.document.close();
                w.focus();
                w.print();
                w.close();
            }, 250);
        } else {
            w.document.close();
            w.focus();
            w.print();
            w.close();
        }
    }


    /**
     *
     * @param elementsToPrint sono gli elementi presi con getElementByClassName è una collection html
     */
    generateNumberPages(elementsToPrint) {
        let i = 0;
        let numberPage = 1;
        while (i < elementsToPrint.length) {
            if (isNotNullOrUndefined(elementsToPrint.item(i).getElementsByClassName('numberPage')[0])) {
                elementsToPrint.item(i).getElementsByClassName('numberPage')[0].innerHTML = numberPage;
                numberPage++;
            }
            i++;
        }
    }

    private getTextInformation(value: string): { text, keys: { key, value }[] } {
        // let myString = '(((color:red;text:bold))) other text';
        const cpos = value.indexOf("(((");
        const spos = value.indexOf(")))");
        if (cpos >= 0 && spos >= 0) {
            const keyText = {
                text: value.substr(0, cpos) + value.substr(spos + 3),
                keys: []
            };
            value.substr(cpos + 3, spos - 3).split(';').forEach((sub) => {
                keyText['keys'].push({key: sub.split(':')[0], value: sub.split(':')[1]});
            });
            return keyText;
        } else {
            return undefined
        }
    }

    setDefaultColor(doc: jsPDF) {
        doc.setFillColor(255, 255, 255);
        doc.setDrawColor(0, 0, 0);
    }

    checkbox(doc: jsPDF, x, y, width, checked: boolean | undefined = undefined) {
        const tickLine = width / 10;
        const tick = tickLine * 0.35;
        const xPosition = x
        const yPosition = y
        const normalWidthLine = doc.getLineWidth();
        if (checked == true) {
            doc.setFillColor(243, 130, 50);
            doc.setDrawColor(243, 130, 5);
            doc.roundedRect(xPosition, yPosition, width, width, 1, 1, 'FD');
            doc.setFillColor(255, 255, 255);
            doc.setLineWidth(tickLine);
            doc.setDrawColor(255, 255, 255);
            doc.line(xPosition + width * 0.2, yPosition + width * 0.45, xPosition + width * 0.41 + tick, yPosition + width * 0.69 + tick);
            doc.line(xPosition + width * 0.41, yPosition + width * 0.69, xPosition + width * 0.8, yPosition + width * 0.23 + tick * 3,);
        } else if (checked == false) {
            doc.setFillColor(0, 0, 0);
            doc.setDrawColor(50, 50, 50);
            doc.roundedRect(xPosition, yPosition, width, width, 1, 1, 'FD');
            doc.setFillColor(255, 255, 255);
            doc.setLineWidth(tickLine);
            doc.setDrawColor(255, 255, 255);
            doc.line(xPosition + width * 0.25, yPosition + width * 0.25, xPosition + width * 0.75, yPosition + width * 0.75);
            doc.line(xPosition + width * 0.25, yPosition + width * 0.75, xPosition + width * 0.75, yPosition + width * 0.25);
            doc.roundedRect(xPosition, yPosition, width, width, 1, 1, null);
        } else {
            doc.setFillColor(210, 210, 210);
            doc.roundedRect(xPosition, yPosition, width, width, 1, 1, 'FD');
        }
        this.setDefaultColor(doc);
        doc.setLineWidth(normalWidthLine);
        return {lastX: xPosition + width, lastY: yPosition + width}
    }


    setH4Text(doc) {
        const fontSize = 14;
        doc.setFontSize(fontSize);
        doc.setFont('Helvetica', 'bold');
    }

    setBoldText(doc) {
        doc.setFont('Helvetica', 'bold');
    }

    setDefaultText(doc) {
        doc.setFont('helvetivaNeue', 'normal');
        doc.setFontSize(10);
    }

    setColorLink(doc: jsPDF) {
        doc.setTextColor(0, 84, 194);
    }

    addTextWithLink(doc: jsPDF, text, x, y, options: { url?: string, pageNumber?: number } | undefined = undefined) {
        const sizeText = doc.getTextDimensions(text)
        this.setColorLink(doc);
        doc.textWithLink(text, x - sizeText.w / 2, y, options);
        doc.setTextColor(0, 0, 0);
        return {lastX: x + sizeText.w / 2, lastY: y + sizeText.h / 2}
    }

    public layoutStreetType = {
        typeStudioGMS: 'studioGms'
    }

    streetPageLayoutType(doc: jsPDF, logoComuneSrc: string, nomeComune: string, strada: StradeParse, calcoliIlluminotecnici: ComputeResults, escludiLimitiSuperiori: boolean, layopuType = this.layoutStreetType.typeStudioGMS): HeaderFooterOptions {
        if (this.layoutStreetType.typeStudioGMS) {
            let results: ComputeResult[];
            const forCsv = [];
            doc.setLineWidth(0.3)
            if (calcoliIlluminotecnici.bestResult != null) {
                results = [calcoliIlluminotecnici.bestResult]
            } else {
                results = calcoliIlluminotecnici.allResults;
            }
            let fotometrie: Photometry[];
            if (calcoliIlluminotecnici.bestFotometria != null) {
                fotometrie = [calcoliIlluminotecnici.bestFotometria]
            } else {
                fotometrie = calcoliIlluminotecnici.allFotometrie;
            }
            const getString: (value: any, numberDecimal?: number) => string = (value: any, numberDecimal = 2) => {
                if (value == null) {
                    return ''
                } else if (typeof value == "number") {
                    const tenPower = Math.pow(10, numberDecimal)
                    return (Math.round(value * tenPower) / tenPower).toString();
                } else if (typeof value == "string") {
                    return value
                } else if (value.toString != null) {
                    return value.toString();
                }
            }
            const indexResult = 0
            const titleFontSize = 20;
            const subTitleFontSize = 13;
            const normalFontSize = 10;
            const addPage = (addTitle = false) => {
                last = {lastX: marginx.right, lastY: marginy.top + heigthHeader};
                doc.addPage()
                if (addTitle) {
                    doc.setFontSize(titleFontSize);
                    last = this.addBoldString(doc, strada.nome, marginx.right, last.lastY);
                    last.lastY -= doc.getFontSize() - 7;
                    doc.setFontSize(normalFontSize)
                    last = this.addBoldString(doc, this.translate.instant('segueNormativaE13201_2015'), marginx.right, last.lastY);
                    doc.setFontSize(normalFontSize);

                }
            }

            const marginx = {left: 10, right: 10}
            const marginy = {top: 10, bottom: 10}
            const heigthHeader = 25;
            const heigthFooter = 25;
            let last = {lastX: marginx.right, lastY: marginy.top};
            const usefullWidth = doc.internal.pageSize.getWidth() - marginx.right - marginx.left;
            const usefullHeigth = doc.internal.pageSize.getHeight() - marginy.top - marginy.bottom;
            const verifyToAddPage = (last, newHeigth = 0) => {
                if (last.lastY + newHeigth > usefullHeigth) {
                    addPage();
                }
            }
            doc.setFontSize(titleFontSize);
            const pageInitPrintStreet = doc.getCurrentPageInfo().pageNumber;
            last = this.addBoldString(doc, strada.nome, marginx.right, heigthHeader + last.lastY);
            last.lastY -= doc.getFontSize() - 7;
            doc.setFontSize(normalFontSize)
            last = this.addBoldString(doc, this.translate.instant('segueNormativaE13201_2015'), marginx.right, last.lastY);
            doc.setFontSize(normalFontSize);
            const values = this.drawLayoutLampStreet(doc, strada, marginx.left, last.lastY, usefullWidth, 120)
            last.lastY = values.lastY;
            last.lastX = values.lastX;
            const objectByIndexlayout = values.objectByIndexlayout;
            const getNameByIndexlayout = values.getNameByIndexlayout;
            last.lastY += marginy.top;
            doc.setFontSize(subTitleFontSize);
            doc.setFont('Helvetica', 'bold');
            last = this.addText(doc, this.translate.instant('resultCalc'), marginx.right, last.lastY, {baseline: "top"}, true);
            doc.setFontSize(normalFontSize);

            last = this.addText(doc, this.translate.instant('streets.maintenance_index') + ' ' + strada.fattoreManutenzione, marginx.right, last.lastY, {baseline: "top"});
            doc.setFontSize(normalFontSize);
            last.lastY += marginy.top
            if (arrayIsSet(results[indexResult].risultati)) {
                doc.setFontSize(normalFontSize)
                const titles = [
                    undefined,
                    undefined,
                    this.translate.instant('streets.calc'),
                    this.translate.instant('streets.limit'),
                    undefined
                ]
                last = this.addRowSeparateLine(doc, usefullWidth, marginx.left, last.lastY, titles, true, 0, false, {
                    2: 'center',
                    3: 'center'
                }, undefined, undefined);
                results[indexResult].risultati.forEach((risultato, indiceLayout) => {
                    const lastPreview = this.addCalcoliEmEmin(doc, risultato, marginx.right, last.lastY, usefullWidth, true, undefined, escludiLimitiSuperiori);
                    verifyToAddPage(lastPreview);
                    last = this.addCalcoliEmEmin(doc, risultato, marginx.right, last.lastY, usefullWidth, false, getNameByIndexlayout(objectByIndexlayout, indiceLayout, true), escludiLimitiSuperiori);
                })
            }
            addPage(true);

            const fotometria = fotometrie[indexResult];
            const widthCol = usefullWidth * 3 / 4 - marginx.left * 2
            const widthCol1 = widthCol / 2
            const widthCol2 = widthCol / 2
            const widthCol3 = usefullWidth - widthCol;
            doc.setFontSize(normalFontSize)
            const photometryManufacturer = stringIsSet(fotometria.produttore) ? fotometria.produttore : '-';
            const photometryNomeFile = stringIsSet(fotometria.nomeFile) ? fotometria.nomeFile : '-';
            const photometryOptics = stringIsSet(fotometria.ottica) ? fotometria.ottica : '-';
            const rowsCol1 = [
                [this.translate.instant('streets.manufacturer'),
                    photometryManufacturer],
                [this.translate.instant('streets.photometry'),
                    photometryNomeFile],
                [this.translate.instant('streets.optics'),
                    photometryOptics],

            ];
            let lastCol1 = last
            rowsCol1.forEach(row => {
                verifyToAddPage(lastCol1);
                lastCol1 = this.addRowSeparateLine(doc, widthCol1, marginx.right, lastCol1.lastY, row, false, 0, false, "left")
            })
            const photometryPotenza = getString(results[indexResult].potenza)
            const photometryFlussoLuminoso = getString(results[indexResult].flusso)
            const photometryEfficienza = getString(results[indexResult].efficienza)
            const photometryTemperaturaColore = getString(fotometria.temperaturaColore)
            const photometryIPEA = getString(results[indexResult].IPEA);
            const rowsCol2 = [
                [this.translate.instant('fotometrie.potenza'), photometryPotenza + ' W'],
                [this.translate.instant('fotometrie.flussoSistema'), photometryFlussoLuminoso + ' lm'],
                [this.translate.instant('fotometrie.efficienza'), photometryEfficienza + ' Lm/W'],
                [this.translate.instant('fotometrie.temperaturaColore'), photometryTemperaturaColore + ' K'],
                [this.translate.instant('IPEA'), photometryIPEA],
            ]
            let lastCol2 = last
            rowsCol2.forEach(row => {
                verifyToAddPage(lastCol2);
                lastCol2 = this.addRowSeparateLine(doc, widthCol1, marginx.right + widthCol1 + marginx.left, lastCol2.lastY, row, false, 0, false, "left")
            })
            doc.addImage('assets/icon/logos/infoLamp.png', 'png', marginx.right + widthCol1 + marginx.left + widthCol2, last.lastY, widthCol3 - 20, widthCol3 - 20);
            last.lastY += widthCol3 - 25 + marginy.top;
            const ulr = (results[indexResult].ULR != null) ? getString(results[indexResult].ULR) : '-'
            const ulor = (results[indexResult].ULR != null) ? getString(results[indexResult].ULR) : '-'
            const oreAnno = (results[indexResult].ore_esercizio_anno != null) ? getString(results[indexResult].ore_esercizio_anno) : '-'
            const ptenza_km = (results[indexResult].potenza_km != null) ? getString(results[indexResult].potenza_km) : '-'
            const maxIntensitaLuminose1Deg = (results[indexResult].max_intensitaLuminose[0] != null) ? getString(results[indexResult].max_intensitaLuminose[0].deg) : '-'
            const maxIntensitaLuminose1Cd = (results[indexResult].max_intensitaLuminose[0] != null) ? getString(results[indexResult].max_intensitaLuminose[0].value) : '-'
            const maxIntensitaLuminose2Deg = (results[indexResult].max_intensitaLuminose[1] != null) ? getString(results[indexResult].max_intensitaLuminose[1].deg) : '-'
            const maxIntensitaLuminose2Cd = (results[indexResult].max_intensitaLuminose[1] != null) ? getString(results[indexResult].max_intensitaLuminose[1].value) : '-'
            const maxIntensitaLuminose3Deg = (results[indexResult].max_intensitaLuminose[2] != null) ? getString(results[indexResult].max_intensitaLuminose[2].deg) : '-'
            const maxIntensitaLuminose3Cd = (results[indexResult].max_intensitaLuminose[2] != null) ? getString(results[indexResult].max_intensitaLuminose[2].value) : '-'
            const categoriaIntensitaLuminosa = (results[indexResult].categoriaIntensitaLuminosa != null) ? getString(results[indexResult].categoriaIntensitaLuminosa) : '-'
            const indiceAbbagliamento = (results[indexResult].indiceAbbagliamento != null) ? getString(results[indexResult].indiceAbbagliamento) : '-'
            let tipologiaAreaIlluminata = '-';
            if (stringIsSet(strada.tipologiaAreaIlluminata)) {
                let tipologiaTraduction = this.translate.instant('streets.tipologiaAreaIlluminata.' + strada.tipologiaAreaIlluminata);
                tipologiaAreaIlluminata = tipologiaTraduction.includes('streets.tipologiaAreaIlluminata.') ? strada.tipologiaAreaIlluminata : tipologiaTraduction;
            }
            const getValueMultipleLamp = (key: string, unitOfMeasure: string = undefined) => {
                let value = '';
                if (arrayIsSet(strada.lampade)) {
                    if (strada.lampade.length > 1) {
                        value += '( ';
                    }
                    strada.lampade.forEach((lampada, index) => {
                        value += getString(lampada[key]);
                        if (index < strada.lampade.length - 1) {
                            value += '; ';
                        }
                    });
                    if (strada.lampade.length > 1) {
                        value += ' )';
                    }
                }
                if (stringIsSet(value) && stringIsSet(unitOfMeasure)) {
                    value += ' ';
                    value += unitOfMeasure;
                }
                return value;
            }

            const distanceLamp = getString(strada.interdistanza);
            const distancesFocus = getValueMultipleLamp('origine_y',);
            const heightFocus = getValueMultipleLamp('altezza',);
            const inclinationOutreach = getValueMultipleLamp('angoloInclinazione');
            const lengthOutreach = getValueMultipleLamp('sbraccio');
            const ourAnnualExsercize = oreAnno + ' h : 100%, ' + fotometria.potenza + ' W';
            const consuption = ptenza_km;
            const ulr_ulor = ulr + '/' + ulor;
            const maxIntensitaLuminose = getString('≥ ' + maxIntensitaLuminose1Deg + '° : ' + maxIntensitaLuminose1Cd + ' cd/klm');
            const maxIntensitaLuminoseInfo_1 = getString('≥ ' + maxIntensitaLuminose2Deg + '° : ' + maxIntensitaLuminose2Cd + ' cd/klm');
            const maxIntensitaLuminoseInfo_2 = getString('≥ ' + maxIntensitaLuminose3Deg + '° : ' + maxIntensitaLuminose3Cd + ' cd/klm');
            const rowsCol3 = [
                [this.translate.instant('streets.distanceLamp'), distanceLamp + ' m'],
                [this.translate.instant('streets.heightFocus'), heightFocus + ' m'],
                [this.translate.instant('streets.distanceFocus'), distancesFocus + ' m'],
                [this.translate.instant('streets.inclinationOutreach'), inclinationOutreach + ' °'],
                [this.translate.instant('streets.lengthOutreach'), lengthOutreach + ' m'],
                [this.translate.instant('streets.ourAnnualExsercize'), ourAnnualExsercize],
                [this.translate.instant('streets.consuption'), consuption + ' W/km'],
                [this.translate.instant('streets.ulr_ulor'), ulr_ulor],
                [this.translate.instant('streets.maxIntensitaLuminose'), maxIntensitaLuminose],
                [this.translate.instant('streets.maxIntensitaLuminoseInfo_1'), maxIntensitaLuminoseInfo_1],
                [this.translate.instant('streets.maxIntensitaLuminoseInfo_2'), maxIntensitaLuminoseInfo_2],
                [this.translate.instant('streets.maxIntensitaLuminoseInfo_3'), undefined],
                [this.translate.instant('streets.classeIntensitaLuminose'), categoriaIntensitaLuminosa],
                [this.translate.instant('streets.classeIntensitaLuminoseInfo'), undefined],
                [this.translate.instant('streets.classeIndiceAbbagliamento'), indiceAbbagliamento],
                [this.translate.instant('streets.tipologiaAreaIlluminata.title'), tipologiaAreaIlluminata],
            ]

            let lastCol3 = last
            rowsCol3
                .map(f => {
                    const notPrintLine = [this.translate.instant('streets.maxIntensitaLuminose'), this.translate.instant('streets.maxIntensitaLuminoseInfo_1'), this.translate.instant('streets.maxIntensitaLuminoseInfo_2'), this.translate.instant('streets.classeIntensitaLuminose')]
                    return {row: f, printLine: !notPrintLine.includes(f[0])}
                })
                .forEach(row => {
                    verifyToAddPage(lastCol3);
                    lastCol3 = this.addRowSeparateLine(doc, usefullWidth, marginx.right, lastCol3.lastY, row.row, false, 0, false, "left", row.printLine, {"1": {left: 7}})
                })
            last = lastCol3;
            doc.setFontSize(normalFontSize);
            last.lastY += 5
            last = this.addBoldString(doc, this.translate.instant('streets.indicatorEnergyEfficence'), marginx.right, last.lastY + normalFontSize / 2);
            last.lastY -= doc.getFontSize() / 2;
            doc.setFontSize(normalFontSize);
            const De = (results[indexResult].De != null) ? getString(results[indexResult].De) : '0'
            const Dp = (results[indexResult].Dp != null) ? getString(results[indexResult].Dp, 4) : '0'
            const IPEI = (results[indexResult].IPEI != null) ? getString(results[indexResult].IPEI) : '0'
            const rowsCol4 = [
                [undefined, this.translate.instant('streets.unita'), this.translate.instant('streets.calc')],
                [getString(strada.nome), 'Dp', Dp + ((Dp != '0') ? ' kWh/m2 * anno' : '')],
                [getString(fotometria.nomeFile), 'De', De + ((De != '0') ? ' W/lx * m2' : '')],
                [undefined, 'IPEI', IPEI],
            ]
            let lastCol4 = last
            rowsCol4
                .forEach(row => {
                    verifyToAddPage(lastCol4);
                    lastCol4 = this.addRowSeparateLine(doc, usefullWidth, marginx.right, lastCol4.lastY, row, false, 0, false, "left", true)
                })
            last = lastCol4;
            doc.setFontSize(normalFontSize);
            // if (arrayIsSet(rowsCol1) || arrayIsSet(rowsCol2) || arrayIsSet(rowsCol3) || arrayIsSet(rowsCol4)) {
            //     addPage();
            // }

            if (arrayIsSet(strada.layout)) {
                strada.layout.forEach((l, index) => {
                    const risultato = results[indexResult].risultati[index];
                    const matrix = [];
                    const unitOfMeasure = stringIsSet(risultato.unitaMisuraMatriceValori) ? risultato.unitaMisuraMatriceValori : '';
                    if (risultato.matriceValori) {
                        const vector = risultato.matriceValori[indexResult];
                        if (arrayIsSet(vector)) {
                            const x_calc = risultato.x_calc;
                            const y_calc = risultato.y_calc;
                            for (let i = 0; i < x_calc.length; i++) {
                                matrix.push([]);
                                for (let j = 0; j < y_calc.length; j++) {
                                    matrix[i].push(getString(vector[i * y_calc.length + j]));
                                }
                            }
                        }
                    }
                    const matrixLux = [];
                    if (risultato.matriceValoriLux) {
                        const vector = risultato.matriceValoriLux;
                        if (arrayIsSet(vector)) {
                            const x_calc = risultato.x_calc;
                            const y_calc = risultato.y_calc;
                            for (let i = 0; i < x_calc.length; i++) {
                                matrixLux.push([]);
                                for (let j = 0; j < y_calc.length; j++) {
                                    matrixLux[i].push(getString(vector[i * y_calc.length + j]));
                                }
                            }
                        }
                    }
                    const getRisultatiRowsPrint = this.getRisultatiRowsPrint(risultato, undefined, escludiLimitiSuperiori)
                    const getCheckedtext = (valore: number, limiti: { min?: number | null, max?: number | null }) => {
                        let checked = undefined;
                        let text = undefined;
                        if (limiti != null) {
                            const min = limiti.min != null ? Math.round(limiti.min * 100) / 100 : undefined;
                            const max = limiti.max != null ? Math.round(limiti.max * 100) / 100 : undefined;
                            if (min != null && max != null) {
                                text = '[ ' + min + ' - ' + max + ' ]'
                                if (escludiLimitiSuperiori) {
                                    checked = valore >= min;
                                } else {
                                    checked = valore >= min && valore <= max;
                                }
                            } else if (min != null && max == null) {
                                text = ' >= ' + min
                                checked = valore >= min;
                            } else if (min == null && max != null) {
                                text = ' <= ' + max
                                checked = valore <= max;
                            }
                        }
                        return {checked: checked, text: text}
                    }
                    const riferimentoStrada = strada.nome;
                    const riferimentoElementoStrada = getNameByIndexlayout(objectByIndexlayout, index, true);
                    const categoriaIlluminotecnicaStrada = l.categoriaIlluminotecnica;
                    const tipologiaAreaIlluminataTrad = strada.tipologiaAreaIlluminata != null ? this.translate.instant('streets.tipologiaAreaIlluminata.' + strada.tipologiaAreaIlluminata) : '';
                    const tipologiaAreaIlluminata = tipologiaAreaIlluminataTrad.includes('streets.tipologiaAreaIlluminata') ? strada.tipologiaAreaIlluminata : tipologiaAreaIlluminataTrad;
                    const isCentroStorico = strada.isCentroStorico ? this.translate.instant('yes') : this.translate.instant('no')


                    if (arrayIsSet(matrix) || arrayIsSet(getRisultatiRowsPrint)) {
                        if (arrayIsSet(matrix)) {
                            addPage();
                        }
                        doc.setFontSize(titleFontSize);
                        this.setBoldText(doc)
                        last = this.addText(doc, strada.nome, marginx.right, last.lastY, {align: "left"});
                        this.setDefaultText(doc)
                        doc.setFontSize(subTitleFontSize);
                        last = this.addText(doc, getNameByIndexlayout(objectByIndexlayout, index, true), marginx.right, last.lastY, {align: "left"});
                        doc.setFontSize(normalFontSize);

                        // last = this.addBoldString(doc, getNameByIndexlayout(objectByIndexlayout, index, true), marginx.right, heigthHeader + last.lastY);
                        // last.lastY -= doc.getFontSize() / 2;
                        getRisultatiRowsPrint.forEach(row => {
                            last = this.addRowSeparateLine(doc, usefullWidth, marginx.left, last.lastY, row)
                        })
                        if (arrayIsSet(risultato.osservatori)) {
                            verifyToAddPage(last, 50);
                            doc.setFontSize(normalFontSize);
                            last = this.addText(doc, this.translate.instant('streets.risultatiosservatore'), marginx.right, last.lastY + marginy.top, {align: "left"}, true);
                            doc.setFontSize(normalFontSize);
                            const valoriOsservatore = risultato.valoriOsservatori
                            const limiti = risultato.limiti;
                            const keysOsservatore = Object.keys(risultato.valori)
                            const rowsOsservatori = []
                            risultato.osservatori.forEach((osservatore: any, index) => {
                                const rowsOsservatore = [];
                                keysOsservatore.forEach((key, indexKey) => {
                                    if (valoriOsservatore[key][index] != null) {
                                        const row = []
                                        if (indexKey == 0) {
                                            row.push(this.translate.instant('fotometria.osservatore') + ' ' + (index + 1))
                                        } else if (indexKey == 1) {
                                            row.push(this.translate.instant('streets.position'))
                                        } else if (indexKey == 2) {
                                            row.push(osservatore.x + ' m' + ', ' + osservatore.y + ' m' + ', ' + osservatore.z + ' m')
                                        } else {
                                            row.push(undefined)
                                        }
                                        const translateKey = this.translate.instant('fotometria.' + key)
                                        row.push(translateKey)
                                        row.push(getString(valoriOsservatore[key][index]))
                                        const cT = getCheckedtext(valoriOsservatore[key][index], limiti[key])
                                        row.push(cT.text)
                                        row.push(cT.checked)
                                        rowsOsservatore.push(row)
                                    }
                                })
                                rowsOsservatori.push(rowsOsservatore)
                            })
                            rowsOsservatori.forEach((rowsOsservatore) => {
                                rowsOsservatore.forEach((row, indexRow) => {
                                    let initCol = 1;
                                    if (indexRow == rowsOsservatore.length - 1) {
                                        initCol = 0
                                    }
                                    last = this.addRowSeparateLine(doc, usefullWidth, marginx.left, last.lastY, row, false, initCol, false, 'left')
                                })
                            })
                            // console.log(risultato.osservatori)
                        }
                        if (arrayIsSet(matrix)) {
                            if (last.lastY + 110 > usefullHeigth) {
                                addPage()
                                doc.setFontSize(titleFontSize);
                                last = this.addText(doc, strada.nome, marginx.right, last.lastY, {align: "left"});
                                doc.setFontSize(subTitleFontSize);
                                last = this.addText(doc, getNameByIndexlayout(objectByIndexlayout, index, true), marginx.right, last.lastY, {align: "left"});
                                doc.setFontSize(normalFontSize);
                            }
                            last = this.drawMatrixOnStreet(doc, l, matrix, marginx.left, last.lastY, usefullWidth, 100, strada.interdistanza + ' m', l.larghezza + ' m')
                            doc.setFontSize(normalFontSize);
                            last.lastY += marginy.top
                        }
                        if (risultato.matriceValoriLux) {
                            console.log(risultato.matriceValoriLux)
                            if (last.lastY + 110 > usefullHeigth) {
                                addPage()
                                doc.setFontSize(titleFontSize);
                                last = this.addText(doc, strada.nome, marginx.right, last.lastY, {align: "left"});
                                doc.setFontSize(subTitleFontSize);
                                last = this.addText(doc, getNameByIndexlayout(objectByIndexlayout, index, true) + 'lux', marginx.right, last.lastY, {align: "left"});
                                doc.setFontSize(normalFontSize);
                            }
                            last = this.drawMatrixOnStreet(doc, l, matrixLux, marginx.left, last.lastY, usefullWidth, 100, strada.interdistanza + ' m', l.larghezza + ' m')
                            doc.setFontSize(normalFontSize);
                            last.lastY += marginy.top
                        }
                    }
                    let conforme = true;
                    const obj = {
                        riferimentoStrada,
                        riferimentoElementoStrada,
                        categoriaIlluminotecnicaStrada,
                        tipologiaAreaIlluminata,
                        isCentroStorico,
                        photometryManufacturer,
                        photometryNomeFile,
                        photometryOptics,
                        photometryPotenza,
                        photometryFlussoLuminoso,
                        photometryEfficienza,
                        photometryTemperaturaColore,
                        photometryIPEA,
                        distanceLamp,
                        heightFocus,
                        distancesFocus,
                        lengthOutreach,
                        inclinationOutreach,
                        ourAnnualExsercize,
                        consuption,
                        ulr_ulor,
                        maxIntensitaLuminose,
                        maxIntensitaLuminoseInfo_1,
                        maxIntensitaLuminoseInfo_2,
                        categoriaIntensitaLuminosa,
                        indiceAbbagliamento,
                        Dp,
                        De,
                        IPEI
                    }
                    if (risultato.valori != null) {
                        Object.keys(risultato.valori).forEach(key => {
                            const cT = getCheckedtext(risultato.valori[key], risultato.limiti[key])

                            if (!cT.checked) {
                                conforme = false;
                            }
                            obj[key] = risultato.valori[key]
                            obj[key + 'Conforme'] = cT.checked
                            obj[key + 'Limiti'] = cT.text
                        });
                        obj['conforme'] = (conforme) ? this.translate.instant('yes') : this.translate.instant('no');
                        obj['indice'] = doc.getCurrentPageInfo().pageNumber
                        obj['pageInitPrintStreet'] = pageInitPrintStreet
                        forCsv.push(obj);
                    }
                });
            }
            doc.setFontSize(normalFontSize);
            addPage();
            return {usefullWidth, usefullHeigth, heigthHeader, heigthFooter, forCsv}
        }
        return {usefullWidth: null, usefullHeigth: null, heigthHeader: null, heigthFooter: null, forCsv: null}

    }

    addHeaderFooter(doc: jsPDF, headerFooterOptions: HeaderFooterOptions, name: string, logoHeader: string, logoAziendale = undefined) {
        const numberPage = doc.getNumberOfPages();
        let i = 0;
        const normalFontSize = doc.getFontSize()
        const usefullWidth = headerFooterOptions.usefullWidth
        const usefullHeigth = headerFooterOptions.usefullHeigth
        const heigthHeader = headerFooterOptions.heigthHeader
        const heigthFooter = headerFooterOptions.heigthFooter
        let heigthImage = heigthHeader / 2
        while (i < numberPage) {
            if (logoHeader) {
                try {
                    const pImage = doc.getImageProperties(logoHeader)
                    const heigth = pImage.height
                    const width = pImage.width;
                    const ratio = heigth / width;
                    let widthImage = heigthHeader / 2
                    heigthImage = widthImage * ratio;
                    if (heigthImage > heigthHeader / 2) {
                        heigthImage = heigthHeader / 2
                        widthImage = heigthImage / ratio
                    }
                    if (heigthImage == null) {
                        heigthImage = heigthHeader / 2
                    }
                    if (widthImage == null) {
                        widthImage = heigthHeader / 2
                    }
                    doc.addImage(logoHeader, 'png', usefullWidth - heigthHeader, heigthHeader / 2 - heigthImage / 2, widthImage, heigthImage, 'logoHeader')
                } catch (e) {
                    console.error(e)
                }
            }
            doc.setPage(i + 1);
            this.addText(doc, name, 10, heigthHeader / 2, {align: "left", baseline: "middle"})
            let lastX = usefullWidth - heigthHeader - 10;
            // try {
            //     const logoFooter1 = 'assets/icon/logos/pell.png';
            //     const pImage = doc.getImageProperties(logoFooter1)
            //     const heigth = pImage.height
            //     const width = pImage.width;
            //     const ratio = heigth / width;
            //     let widthImage = heigthHeader / 2
            //     heigthImage = widthImage * ratio;
            //     if (heigthImage > heigthHeader / 3.5) {
            //         heigthImage = heigthHeader / 3.5
            //         widthImage = heigthImage / ratio
            //     }
            //     if (heigthImage == null) {
            //         heigthImage = heigthHeader / 2
            //     }
            //     if (widthImage == null) {
            //         widthImage = heigthHeader / 2
            //     }
            //     lastX = (logoAziendale != null) ? usefullWidth / 2 + 30 + 2 * widthImage : lastX
            //     doc.addImage(logoFooter1, 'png', usefullWidth / 2 + 10 + widthImage, usefullHeigth + heigthFooter / 2 - heigthImage / 2 - 5, widthImage, heigthImage, 'logoFooter1')
            // } catch (e) {
            //     console.error(e);
            // }
            if (logoAziendale != null) {
                try {
                    const logoFooter2 = logoAziendale;
                    const pImage = doc.getImageProperties(logoFooter2)
                    const heigth = pImage.height
                    const width = pImage.width;
                    const ratio = heigth / width;
                    let widthImage = heigthHeader / 2
                    heigthImage = widthImage * ratio;
                    if (heigthImage > heigthHeader / 2) {
                        heigthImage = heigthHeader / 2
                        widthImage = heigthImage / ratio
                    }
                    if (heigthImage == null) {
                        heigthImage = heigthHeader / 2
                    }
                    if (widthImage == null) {
                        widthImage = heigthHeader / 2
                    }
                    doc.addImage(logoFooter2, 'png', lastX, usefullHeigth + heigthFooter / 2 - heigthImage / 2 - 5, widthImage, heigthImage, 'logoFooter2')
                    lastX = lastX + widthImage + 5
                } catch (e) {
                    console.error(e);
                }
            }
            try {
                const logoFooter3 = 'assets/logo.png';
                const pImage = doc.getImageProperties(logoFooter3)
                const heigth = pImage.height
                const width = pImage.width;
                const ratio = heigth / width;

                heigthImage = heigthHeader / 3;
                let widthImage = heigthImage / ratio
                if (widthImage > 40) {
                    widthImage = 40
                    heigthImage = widthImage * ratio;
                }
                if (heigthImage == null) {
                    heigthImage = heigthHeader / 2
                }
                if (widthImage == null) {
                    widthImage = heigthHeader / 2
                }
                doc.addImage(logoFooter3, 'png', lastX, usefullHeigth + heigthFooter / 2 - heigthImage / 2 - 5, widthImage, heigthImage, 'logoFooter3')
            } catch (e) {
                console.error(e);
            }
            this.addText(doc, (i + 1).toString(), 10, usefullHeigth + heigthFooter / 2 - 5, {align: "left"})
            i++;
        }
    }

    drawMatrixOnStreet(doc: jsPDF, layout: LayoutType, matrix, x: number, y: number, width: number, heigth: number, textButtom: string, textRight: string) {
        let usefullWidth = width
        const numeroCorsie = typeof layout.numeroCorsie == "number" ? layout.numeroCorsie : 0;
        if (textRight != null) {
            usefullWidth = this.addRightBar(doc, x, y, usefullWidth, heigth, textRight)
        }
        const nomrmalFont = doc.getFontSize();
        const normalWidthLine = doc.getLineWidth();
        const normalFillColor = doc.getFillColor();
        const normalDrawColor = doc.getDrawColor();
        const chGray = 50;
        doc.setDrawColor(0);
        doc.setFillColor(255, 255, 255);
        doc.roundedRect(x, y, usefullWidth, heigth, 0.5, 0.5, 'FD')
        doc.setDrawColor(chGray, chGray, chGray);
        doc.setFillColor(chGray, chGray, chGray);
        const ticknessDashedLine = 0.2
        const percentualeH = heigth * 0.03
        let lasty = y + percentualeH;
        if (layout.tipo == StreetLayoutItemType.carreggiata) {
            doc.roundedRect(x, lasty, usefullWidth, ticknessDashedLine * 3, 0.5, 0.5, 'F')
            doc.roundedRect(x, y + heigth - 2 * percentualeH, usefullWidth, ticknessDashedLine * 3, 0.5, 0.5, 'F')
            const usefullHeigth = heigth - 2 * percentualeH;
            const distanceYForCorsia = usefullHeigth / numeroCorsie;
            for (let i = 0; i < numeroCorsie - 1; i++) {
                doc.setLineDashPattern([7, 3], 10);
                doc.setLineWidth(ticknessDashedLine)
                doc.line(x, y + percentualeH + ticknessDashedLine / 2 + (i + 1) * distanceYForCorsia, x + usefullWidth, y + percentualeH + ticknessDashedLine / 2 + (i + 1) * distanceYForCorsia, 'S');
                doc.setLineWidth(normalWidthLine)
                doc.setLineDashPattern(null, null)
            }
        }


        this.setDefaultColor(doc)
        const numberX = matrix.length;
        const numberY = matrix[0].length;
        const textWidth = usefullWidth / numberX
        const textHeigth = heigth / numberY;
        doc.setDrawColor(100, 100, 100);
        doc.setFillColor(100, 100, 100);
        // doc.setFontSize(smallFont);
        matrix.forEach((row, indexRow) => {
            row.forEach((value, indexCol) => {
                const castValue = typeof value == "number" ? (Math.round(value * 100) / 100).toString() : value;
                const xCenter = x + textWidth * indexRow + textWidth / 2
                const yCenter = y + textHeigth * indexCol + textHeigth / 2
                this.addText(doc, castValue, xCenter - 3.4, yCenter - 3, {
                    align: "center",
                    baseline: "middle"
                })
                doc.line(xCenter - 1, yCenter, xCenter + 1, yCenter)
                doc.line(xCenter, yCenter - 1, xCenter, yCenter + 1)
                // doc.rect(x + textWidth * indexRow, y + textHeigth * indexCol, textWidth, textHeigth, 'S')
            })
        })
        doc.setFontSize(nomrmalFont);
        doc.setFillColor(normalFillColor)
        doc.setDrawColor(normalDrawColor)
        let lastY = y + heigth
        if (textButtom != null) {
            lastY = this.addButtomBar(doc, x, y, usefullWidth, heigth, textButtom)
        }

        return {lastX: x + width, lastY};
    }

    addRowSeparateLine(doc, usefullWidth: number, x: number, y: number, texts: string[], bold = false, initColumn = 0, preview = false, align: "center" | "left" | {
        [k: string]: "center" | "left"
    } = "center", printLine = true, margin: { [k: string]: { left: number } } = undefined, links: {
        [k: string]: { url?: string, pageNumber?: number }
    } = undefined) {
        const padding = 2;
        const normalWidthLine = doc.getLineWidth();
        doc.setLineWidth(null)
        const getAlign = (index: number) => {
            const xText = align == "center" ? x + width * (index + 1) - width / 2 : x + width * index;
            const obj: { align: "center" | "left", xText: number } = {align: undefined, xText}
            if (typeof align == "string") {
                const xLeft = margin != null && margin[index] != null && margin[index].left != null ? margin[index].left : 0;
                obj.align = align;
                obj.xText = xText + xLeft;
            } else {
                if (align != null && stringIsSet(align[index])) {
                    const xText = align[index] == "center" ? x + width * (index + 1) - width / 2 : x + width * index;
                    const xLeft = margin != null && margin[index] != null && margin[index].left != null ? margin[index].left : 0;
                    obj.align = align[index];
                    obj.xText = xText + xLeft;
                }
            }
            return obj
        }
        let last = {lastX: x, lastY: y + padding};
        const width = usefullWidth / texts.length;
        texts.forEach((text, index) => {
            if (typeof text == "boolean" && !preview) {
                this.checkbox(doc, x + width * (index + 1) - width / 2, y - 1.3 + padding, 3, text);
            } else if (stringIsSet(text)) {
                const xTextAlign = getAlign(index)
                if (preview) {
                    last = this.previewAddText(doc, text, xTextAlign.xText, y + padding, {
                        align: xTextAlign.align,
                        baseline: "middle"
                    });
                } else {
                    let lastKey;
                    if (links && links[text] != null) {
                        const currentColor = doc.getTextColor()
                        this.setColorLink(doc);
                        doc.textWithLink(text, xTextAlign.xText, y + padding, {
                            ...links[text],
                            align: xTextAlign.align,
                            baseline: "middle",
                            maxWidth: width
                        });
                        doc.setTextColor(currentColor);
                        const sizeText = doc.getTextDimensions(text, {
                            align: xTextAlign.align,
                            baseline: "middle",
                            maxWidth: width
                        });
                        lastKey = {lastX: x + sizeText.w, lastY: y + padding + sizeText.h}
                    } else {
                        lastKey = this.addText(doc, text, xTextAlign.xText, y + padding, {
                            align: xTextAlign.align,
                            baseline: "middle",
                            maxWidth: width
                        }, bold);
                    }
                    if (lastKey.lastY > last.lastY) {
                        last.lastY = lastKey.lastY
                    }
                    if (lastKey.lastX > last.lastX) {
                        last.lastX = lastKey.lastX
                    }
                }

            }
        })
        if (printLine) {
            doc.line(x + initColumn * width, last.lastY, x + usefullWidth, last.lastY)
            last.lastY += padding;
        }
        doc.setLineWidth(normalWidthLine)
        return last
    }

    getRisultatiRowsPrint(risultato: LayoutItemResult, keynmae: string = undefined, escludiLimitiSuperiori: boolean) {
        let keysRisultati;
        if (risultato.valori != null) {
            keysRisultati = Object.keys(risultato.valori)
        }
        let keysLimiti;
        if (risultato.limiti != null) {
            keysLimiti = Object.keys(risultato.limiti);
        }
        const rowsPrint = []
        if (arrayIsSet(keysRisultati) && arrayIsSet(keysLimiti)) {
            keysRisultati.forEach((key, index) => {
                const row = [];
                let tipo = stringIsSet(keynmae) ? keynmae : undefined;
                tipo = stringIsSet(tipo) ? tipo : '';
                const tipologiaLayout = tipo
                    .trim()
                    .replace(/^\w/, (c) => c.toUpperCase())
                if (index == 0) {
                    row.push(tipologiaLayout)
                } else {
                    row.push(undefined)
                }
                const translatekey = this.translate.instant('fotometria.' + key)
                row.push(translatekey);
                if (risultato.valori[key] != null) {
                    let castString = typeof risultato.valori[key] == "number" ? (Math.round(risultato.valori[key] * 100) / 100).toString() : risultato.valori[key]
                    castString = castString.toString != null ? castString.toString() : castString
                    castString = typeof castString == "string" ? castString : '-'
                    row.push(castString)
                }
                let checked = false
                if (risultato.limiti[key] != null) {
                    const limiti = risultato.limiti[key]
                    const min = limiti.min != null ? Math.round(limiti.min * 100) / 100 : undefined;
                    const max = limiti.max != null ? Math.round(limiti.max * 100) / 100 : undefined;
                    const valore = risultato.valori[key];
                    if (min != null && max != null) {
                        row.push('[ ' + min + ' - ' + max + ' ]')
                        if (escludiLimitiSuperiori) {
                            checked = valore >= min;
                        } else {
                            checked = valore >= min && valore <= max;
                        }
                    } else if (min != null && max == null) {
                        row.push(' >= ' + min)
                        checked = valore >= min;
                    } else if (min == null && max != null) {
                        row.push(' <= ' + max)
                        checked = valore <= max;
                    }
                }
                row.push(checked)
                rowsPrint.push(row);
            })
        }
        return rowsPrint;
    }

    addCalcoliEmEmin(doc: jsPDF, risultato: LayoutItemResult, x: number, y: number, usefullWidth: number, preview = false, keyName: string = undefined, escludiLimitiSuperiori: boolean): {
        lastX: number,
        lastY: number
    } {
        let last = {lastX: x, lastY: y};
        const normalFont = doc.getFontSize();
        const padding = 2;
        if (risultato != null) {
            const rowsPrint = this.getRisultatiRowsPrint(risultato, keyName, escludiLimitiSuperiori)
            rowsPrint.forEach((row, index) => {
                let initClomunLine = 1;
                if (index === rowsPrint.length - 1) {
                    initClomunLine = 0;
                }
                last = this.addRowSeparateLine(doc, usefullWidth, x, last.lastY, row, false, initClomunLine, preview, {
                    0: 'center',
                    1: 'left',
                    2: 'left',
                    3: 'center'
                }, !preview, {1: {left: 15}, 2: {left: 15}})
            })
        }
        doc.setFontSize(normalFont)
        return last;
    }


    drawSideWalk(doc: jsPDF, x: number, y: number, width: number, heigth: number, textButtom: string | undefined = undefined, textRight: string | undefined = undefined, keyname: string = undefined) {
        let usefullWidth = width
        if (textRight != null) {
            usefullWidth = this.addRightBar(doc, x, y, usefullWidth, heigth, textRight)
        }
        doc.setDrawColor(180, 180, 180);
        doc.setFillColor(180, 180, 180);
        doc.roundedRect(x, y, usefullWidth, heigth, 0.5, 0.5, 'F')
        doc.setDrawColor(210, 210, 210);
        doc.setFillColor(210, 210, 210);
        const percentualeH = heigth * 0.1
        doc.roundedRect(x, y + percentualeH, usefullWidth, heigth - 2 * percentualeH, 0.5, 0.5, 'F')
        this.setDefaultColor(doc)
        let lasty = y + heigth;
        if (stringIsSet(keyname)) {
            doc.setTextColor(0, 0, 0)
            this.addText(doc, keyname, x + 10, y + heigth / 2, {align: "left", baseline: "middle"})
        }
        if (textButtom != null) {
            this.addButtomBar(doc, x, y, usefullWidth, heigth, textButtom)
        }
        return {lastX: x + width, lastY: y + heigth};
    }

    drawParking(doc: jsPDF, x: number, y: number, width: number, heigth: number, textButtom: string | undefined = undefined, textRight: string | undefined = undefined, keyname: string | undefined = undefined) {
        let usefullWidth = width
        if (textRight != null) {
            usefullWidth = this.addRightBar(doc, x, y, usefullWidth, heigth, textRight)
        }
        doc.setDrawColor(180, 180, 180);
        doc.setFillColor(180, 180, 180);
        doc.roundedRect(x, y, usefullWidth, heigth, 0.5, 0.5, 'F')
        doc.setDrawColor(210, 210, 210);
        doc.setFillColor(210, 210, 210);

        const widthPark = 20
        const heigthPark = 30 > heigth ? heigth : 30

        for (let i = 7.5; i < usefullWidth - widthPark; i = i + widthPark) {
            doc.roundedRect(x + i, y, widthPark, heigthPark, 0.5, 0.5, 'S')
        }
        this.setDefaultColor(doc)
        let lasty = y + heigth;
        if (stringIsSet(keyname)) {
            doc.setTextColor(0, 0, 0)
            this.addText(doc, keyname, x + 10, y + heigth / 2, {align: "left", baseline: "middle"})
        }
        if (textButtom != null) {
            this.addButtomBar(doc, x, y, usefullWidth, heigth, textButtom)
        }
        return {lastX: x + width, lastY: y + heigth};
    }

    emptyLayout(doc: jsPDF, x: number, y: number, width: number, heigth: number, textButtom: string | undefined = undefined, textRight: string | undefined = undefined,) {
        let usefullWidth = width
        if (textRight != null) {
            usefullWidth = this.addRightBar(doc, x, y, usefullWidth, heigth, textRight)
        }
        doc.setDrawColor(180, 180, 180);
        doc.setFillColor(180, 180, 180);
        doc.line(x, y + heigth, x + usefullWidth, y + heigth)
        if (textButtom != null) {
            this.addButtomBar(doc, x, y, usefullWidth, heigth, textButtom)
        }
        return {lastX: x + width, lastY: y + heigth};
    }

    drawBollard(doc: jsPDF, x: number, y: number, width: number, heigth: number, textButtom: string | undefined = undefined, textRight: string | undefined = undefined, keyname: string | undefined = undefined) {
        let usefullWidth = width
        if (textRight != null) {
            usefullWidth = this.addRightBar(doc, x, y, usefullWidth, heigth, textRight)
        }

        const normalFillColor = doc.getFillColor();
        const normalDrawColor = doc.getDrawColor();
        const ch = 200
        doc.setDrawColor(0);
        doc.setFillColor(ch, ch, ch);
        doc.roundedRect(x, y, usefullWidth, heigth, 0, 0, 'FD')
        this.setDefaultColor(doc)
        if (stringIsSet(keyname)) {
            doc.setTextColor(0, 0, 0)
            this.addText(doc, keyname, x + 10, y + heigth / 2, {align: "left", baseline: "middle"})
        }
        doc.setFillColor(normalFillColor)
        doc.setDrawColor(normalDrawColor)
        if (textButtom != null) {
            this.addButtomBar(doc, x, y, usefullWidth, heigth, textButtom)
        }
        return {lastX: x + width, lastY: y + heigth};
    }

    drawRoadWay(doc: jsPDF, x: number, y: number, width: number, heigth: number, numeroCorsie: number, textButtom: string | undefined = undefined, textRight: string | undefined = undefined, keyname: string | undefined = undefined) {
        let usefullWidth = width
        if (textRight != null) {
            usefullWidth = this.addRightBar(doc, x, y, usefullWidth, heigth, textRight)
        }
        const normalWidthLine = doc.getLineWidth();
        const normalFillColor = doc.getFillColor();
        const normalDrawColor = doc.getDrawColor();
        const ch = 80
        doc.setDrawColor(0);
        doc.setFillColor(255, 255, 255);
        doc.roundedRect(x, y, usefullWidth, heigth, 0, 0, 'FD')
        if (stringIsSet(keyname)) {
            this.addText(doc, keyname, x + 10, y + 10, {align: "left"})
        }
        const percentualeH = heigth * 0.03
        const ticknessDashedLine = 0.4
        let lasty = y + percentualeH;
        doc.setDrawColor(ch, ch, ch);
        doc.setFillColor(ch, ch, ch);
        doc.roundedRect(x, lasty, usefullWidth, ticknessDashedLine, 0, 0, 'F')
        doc.roundedRect(x, y + heigth - 2 * percentualeH, usefullWidth, ticknessDashedLine, 0, 0, 'F')
        const usefullHeigth = heigth - 2 * percentualeH;
        const distanceYForCorsia = usefullHeigth / numeroCorsie;
        for (let i = 0; i < numeroCorsie - 1; i++) {
            doc.setLineDashPattern([7, 3,], 10);
            doc.setLineWidth(ticknessDashedLine)
            doc.line(x, y + percentualeH + ticknessDashedLine / 2 + (i + 1) * distanceYForCorsia, x + usefullWidth, y + percentualeH + ticknessDashedLine / 2 + (i + 1) * distanceYForCorsia,);
            doc.setLineWidth(normalWidthLine)
            doc.setLineDashPattern(null, null)
        }
        doc.setLineWidth(normalWidthLine)
        doc.setFillColor(normalFillColor)
        doc.setDrawColor(normalDrawColor)
        this.setDefaultColor(doc)
        if (textButtom != null) {
            this.addButtomBar(doc, x, y, usefullWidth, heigth, textButtom)
        }
        return {lastX: x + width, lastY: y + heigth};
    }


    private addButtomBar(doc: jsPDF, x, y, usefullWidth, heigth, text: string) {
        let lasty = y + heigth;
        doc.setDrawColor(0, 0, 0);
        doc.setFillColor(255, 255, 255);
        const detailWord = doc.getTextDimensions(text)
        lasty = lasty + detailWord.h
        doc.line(x, lasty, x + usefullWidth, lasty)
        doc.line(x, lasty - 2, x, lasty + 2)
        doc.line(x + usefullWidth, lasty - 2, x + usefullWidth, lasty + 2)
        const paddingTextTopButtom = 1.5;
        const paddingTextrightleft = 5;
        doc.roundedRect(x + usefullWidth / 2 - detailWord.w / 2 - paddingTextrightleft / 2, lasty - detailWord.h / 2 - paddingTextTopButtom / 2, detailWord.w + paddingTextrightleft, detailWord.h + paddingTextTopButtom, 0.5, 0.5, 'F')
        this.setDefaultColor(doc)
        doc.setTextColor(0, 0, 0)
        doc.text(text, x + usefullWidth / 2, lasty, {
            align: "center", baseline: "middle"
        })
        lasty = lasty + detailWord.h
        doc.text(this.translate.instant('streets.interdistanza'), x + usefullWidth / 2, lasty, {
            align: "center",
            baseline: "middle"
        })
        this.setDefaultText(doc)
        doc.setTextColor(0, 0, 0)
        return lasty + doc.getFontSize() / 2
    }

    private addRightBar(doc: jsPDF, x, y, usefullWidth, heigth, text: string) {
        doc.setDrawColor(0, 0, 0);
        doc.setFillColor(255, 255, 255);
        const maxWidthHeigth = 10;
        const paddingTextTopButtom = 1.5;
        const paddingTextrightleft = 5;
        const detailWord = doc.getTextDimensions(text, {maxWidth: maxWidthHeigth - paddingTextrightleft * 2})
        const widthDetailWord = maxWidthHeigth
        const widRect = widthDetailWord + paddingTextrightleft;
        const spaceRectStreet = 0.5
        usefullWidth = usefullWidth - widRect - spaceRectStreet;
        const xLine = x + usefullWidth + spaceRectStreet
        doc.line(xLine + widRect / 2 - 2, y, xLine + widRect / 2 + 2, y)
        doc.line(xLine + widRect / 2 - 2, y + heigth, xLine + widRect / 2 + 2, y + heigth)
        doc.line(xLine + widRect / 2, y, xLine + widRect / 2, y + heigth)
        doc.roundedRect(xLine, y + heigth / 2 - paddingTextTopButtom / 2 - detailWord.h / 2, widRect, detailWord.h + paddingTextTopButtom, 0.5, 0.5, 'F')
        doc.setTextColor(0, 0, 0)
        doc.text(text, xLine + widRect / 2, y + heigth / 2, {
            align: "center",
            baseline: "middle"
        })
        this.setDefaultText(doc);
        doc.setTextColor(0, 0, 0)
        return usefullWidth;
    }

    drawLamp(doc: jsPDF, x: number, y: number, sbraccio: number, inclinazione: number, rotazione) {
        const widthRect = 2;
        const heigthRect = 5;
        const x1 = x - (5 / 2) * widthRect;
        const y1 = y
        const v1 = {x: x1, y: y1};
        const normalWidthLine = doc.getLineWidth();
        const rad = (angle) => Math.PI / 180 * angle
        const yR = sbraccio * Math.cos(rad(rotazione))
        const xR = sbraccio * Math.sin(rad(rotazione))
        const x2 = v1.x - xR;
        const y2 = v1.y - yR
        const v2 = {x: x2, y: y2};
        doc.setDrawColor(100, 100, 100);
        doc.setFillColor(100, 100, 100);
        doc.setLineWidth(0.3)
        doc.line(v1.x, v1.y, v2.x, v2.y)
        doc.setLineWidth(normalWidthLine)
        doc.setDrawColor(200, 200, 0);
        doc.setFillColor(230, 230, 0);
        doc.circle(v2.x, v2.y, widthRect, 'F');
        // doc.line(v1.x, v1.y, v2.x, v1.y)
        // doc.line(v1.x, v1.y, v1.x, v2.y)
        // doc.line(v2.x, v1.y, v2.x, v2.y)
        // doc.line(v1.x, v2.y, v2.x, v2.y)
        this.setDefaultColor(doc)
    }

    drawLayoutLampStreet(doc: jsPDF, strada: StradeParse, x, y, maxWidth, maxHeight) {
        const layouts = strada.layout;
        const lampade = strada.lampade;
        const lunghezzaStrada = strada.interdistanza;
        const sumHeigth = layouts.reduce((previousValue, currentValue) => previousValue + currentValue.larghezza, 0)
        let rapportoDocumentWidthMetriReali = maxWidth / lunghezzaStrada;

        const padding = 5;
        const hegthBar = 5;

        // doc.roundedRect(xInit, y, widthContenetrect, sumHeigth * rapportoDocumentWidthMetriReali + padding + hegthBar, 1, 1, 'S');
        let maxNegativeY = 0
        lampade.forEach(lampada => {
            if (lampada.origine_y < 0 && maxNegativeY > lampada.origine_y) {
                maxNegativeY = lampada.origine_y;
            }
        })
        if ((sumHeigth + Math.abs(maxNegativeY)) * rapportoDocumentWidthMetriReali > maxHeight) {
            rapportoDocumentWidthMetriReali = maxHeight / (sumHeigth + Math.abs(maxNegativeY));
        }
        const widthContenetrect = lunghezzaStrada * rapportoDocumentWidthMetriReali + padding
        const xInit = x + (maxWidth - widthContenetrect) / 2;
        let last = {lastX: xInit + padding / 2, lastY: y + padding / 2};

        let objectByIndexlayout: { [k: string]: { index: number, tipo: string, categoriaIlluminotecnica: string } } = {}
        const getObjectByIndexlayout = (layout: LayoutType, objectByIndexlayout: {
            [k: string]: { index: number, tipo: string, categoriaIlluminotecnica: string }
        }, indexL) => {
            const index = Object.values(objectByIndexlayout)
                .sort((keyA, keyB) => keyB.index - keyA.index)
                .findIndex(key => cleanToSpecialCharacterAndLowerCaseString(key.tipo) == cleanToSpecialCharacterAndLowerCaseString(layout.tipo))

            const tipo = layout.tipo;
            const tipoTraduction = this.translate.instant('streets.type.' + tipo).includes('streets.type') ? tipo : this.translate.instant('streets.type.' + tipo);
            if (index >= 0) {
                objectByIndexlayout[indexL] = {
                    tipo: tipoTraduction,
                    index: Object.values(objectByIndexlayout)[index].index + 1,
                    categoriaIlluminotecnica: layout.categoriaIlluminotecnica
                }
            } else {
                objectByIndexlayout[indexL] = {
                    tipo: tipoTraduction,
                    index: 1,
                    categoriaIlluminotecnica: layout.categoriaIlluminotecnica
                }
            }
            return objectByIndexlayout

        }
        const getNameByIndexlayout = (objectByIndexlayout: {
            [k: string]: { index: number, tipo: string, categoriaIlluminotecnica: string }
        }, indexL: number, addCategoria = false) => {
            if (objectByIndexlayout[indexL.toString()] != null) {
                const tipo = objectByIndexlayout[indexL].tipo
                    .trim()
                    .toLowerCase()
                    .replace(/^\w/, (c) => c.toUpperCase())
                const index = objectByIndexlayout[indexL].index
                const categoria = (addCategoria && stringIsSet(objectByIndexlayout[indexL].categoriaIlluminotecnica)) ? ' (' + objectByIndexlayout[indexL].categoriaIlluminotecnica.toUpperCase() + ')' : '';
                return tipo + ' ' + index + categoria
            } else {
                return '-'
            }
        }
        const reverseLayout = [...layouts].reverse()
        const lastIndex = reverseLayout.length - 1;
        reverseLayout
            .forEach((layout, index) => {
                // indexName necessario in quanto nella costruzione del pdf non viene fatto il reverse dei layout
                const indexName = lastIndex - index
                objectByIndexlayout = getObjectByIndexlayout(layout, objectByIndexlayout, indexName)
                const altezza = layout.larghezza + ' m';
                const larghezza = index == layouts.length - 1 ? lunghezzaStrada + ' m' : undefined;
                if (layout.tipo == StreetLayoutItemType.marciapiede) {
                    last = this.drawSideWalk(doc, xInit + padding / 2, last.lastY, lunghezzaStrada * rapportoDocumentWidthMetriReali, layout.larghezza * rapportoDocumentWidthMetriReali, larghezza, altezza, getNameByIndexlayout(objectByIndexlayout, indexName))
                } else if (layout.tipo == StreetLayoutItemType.parcheggio) {
                    last = this.drawParking(doc, xInit + padding / 2, last.lastY, lunghezzaStrada * rapportoDocumentWidthMetriReali, layout.larghezza * rapportoDocumentWidthMetriReali, larghezza, altezza, getNameByIndexlayout(objectByIndexlayout, indexName))
                } else if (layout.tipo == StreetLayoutItemType.pistaciclabile) {
                    last = this.drawRoadWay(doc, xInit + padding / 2, last.lastY, lunghezzaStrada * rapportoDocumentWidthMetriReali, layout.larghezza * rapportoDocumentWidthMetriReali, 0, larghezza, altezza, getNameByIndexlayout(objectByIndexlayout, indexName))
                } else if (layout.tipo == StreetLayoutItemType.spartitraffico) {
                    last = this.drawBollard(doc, xInit + padding / 2, last.lastY, lunghezzaStrada * rapportoDocumentWidthMetriReali, layout.larghezza * rapportoDocumentWidthMetriReali, larghezza, altezza, getNameByIndexlayout(objectByIndexlayout, indexName))
                } else if (layout.tipo == StreetLayoutItemType.carreggiata) {
                    last = this.drawRoadWay(doc, xInit + padding / 2, last.lastY, lunghezzaStrada * rapportoDocumentWidthMetriReali, layout.larghezza * rapportoDocumentWidthMetriReali, layout.numeroCorsie, larghezza, altezza, getNameByIndexlayout(objectByIndexlayout, indexName))
                }
            });
        if (maxNegativeY != 0) {
            const altezza = Math.abs(maxNegativeY) + ' m';
            last = this.emptyLayout(doc, xInit + padding / 2, last.lastY, lunghezzaStrada * rapportoDocumentWidthMetriReali, Math.abs(maxNegativeY) * rapportoDocumentWidthMetriReali, undefined, altezza)
        }
        lampade.forEach(lampada => {
            const origineX = lampada.origine_x * rapportoDocumentWidthMetriReali;
            const origineY = (lampada.origine_y - maxNegativeY) * rapportoDocumentWidthMetriReali;
            this.drawLamp(doc, xInit + (origineX ?? 0), last.lastY - (origineY ?? 0), lampada.sbraccio * rapportoDocumentWidthMetriReali, lampada.angoloInclinazione, lampada.angoloRotazione)
        })
        return {
            lastX: xInit + lunghezzaStrada * rapportoDocumentWidthMetriReali + padding,
            lastY: y + (sumHeigth + Math.abs(maxNegativeY)) * rapportoDocumentWidthMetriReali + padding + hegthBar,
            objectByIndexlayout,
            getNameByIndexlayout: getNameByIndexlayout

        }
    }

}

export type HeaderFooterOptions = {
    usefullWidth: number;
    usefullHeigth: number;
    heigthHeader: number;
    heigthFooter: number;
    forCsv: any[];
}