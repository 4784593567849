import { Directive, TemplateRef, ContentChild } from '@angular/core';

@Directive({
  selector: '[orderable-list-item]'
})
export class OrderableListItemDirective {

  constructor(
    public templateRef: TemplateRef<any>
  ) { }

}
