import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {PuntiLuceErrori} from "../../../pages-modules/attachments/SubModels";
import {ordinamentoEcampiTraduzioni} from "../../../models/ordinamentoEcampiTraduzioni";
import {isNotNullOrUndefined} from "../../../models/Models";

@Component({
    selector: 'app-no-location',
    templateUrl: './no-location.component.html',
    styleUrls: ['./no-location.component.scss']
})
export class NoLocationComponent implements OnInit, OnChanges {

    @Input()
    noLocation;
    @Input()
    className;

    public ordinamento = ordinamentoEcampiTraduzioni;
    public keys;

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.keys = [];
        const keys = (Object.keys(this.ordinamento[this.className]) as string[]).filter((key) => (key !== 'foto' && key !== 'fotoTipologia'));
        keys.sort((a, b) => {
            return this.ordinamento[this.className][b].sortingValue - this.ordinamento[this.className][a].sortingValue;
        });
        keys.push('positionInFile');
        keys.forEach(key => {
            if (key === 'location') {
                this.keys.push('longitude');
                this.keys.push('latitude');
            } else {
                this.keys.push(key);
            }
        });
        this.keys.reverse();
        this.keys.push('end');
    }

    public getPathTraduction(key): string {
        if (!isNotNullOrUndefined(key)) {
            return undefined;
        } else if (key === 'latitude' || key === 'longitude' || key === 'positionInFile') {
            return key;
        } else {
            return 'dashboard_sidenav.' + this.className + '.' + key + '.title';
        }
    }

    public getLat(element) {
        return (isNotNullOrUndefined(element.location)) ? element.location.latitude : undefined;
    }

    public getLng(element) {
        return (isNotNullOrUndefined(element.location)) ? element.location.longitude : undefined;
    }

    ngOnInit(): void {

    }

}
