import {Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
    selector: '[appScrollEvent]'
})
export class ScrollEventDirective {


    @Output() emitEvent = new EventEmitter();

    constructor(private el: ElementRef) {
    }


    @HostListener('scroll', ['$event'])
    onWindowScroll(event) {
        this.emitEvent.emit(this.el)
    }

}
