import {Injectable} from '@angular/core';
import * as Parse from 'parse';
import {Observable, of, throwError} from "rxjs";
import {StatisticheTelecontrolloParse} from "../../models/StatisticheTelecontrollo.Parse";
import {ProgrammiMVParse} from "../../models/ProgrammiMV.Parse";
import {
    arrayIsSet,
    getUniqueValueInArray,
    hunaChartColors,
    paramsApiParse,
    stringIsSet
} from "../../models/Models";
import {ProgettiParse} from "../../models/Progetti.Parse";
import {CircuitiParse} from "../../models/Circuiti.Parse";
import {PuntiLuceParse} from "../../models/PuntiLuce.Parse";
import {fromPromise} from "rxjs/internal-compatibility";
import {map} from "rxjs/operators";
import {SegnaliTlcNodoParse, SensorDataSegnaliTlc} from "../../models/SegnaliTlcNodo.Parse";
import {ChartServiceService} from "./chart-service.service";
import {SegnaliTlcNodoChangesHistoryParse} from "../../models/SegnaliTlcNodoChangesHistory.Parse";

export type VirtualProgramMv = {
    t0?: { value: number, date: Date };
    t1?: { value: number, date: Date };
    t2?: { value: number, date: Date };
    t3?: { value: number, date: Date };
    t4?: { value: number, date: Date };
    p0?: { value: number, date: Date };
    p1?: { value: number, date: Date };
    p2?: { value: number, date: Date };
    p3?: { value: number, date: Date };
    p4?: { value: number, date: Date };
}
export type DataRawTypeChart = {
    data,
    ora: number,
    oreAccensioneNellOra: number,
    potenzaPuntoLuce: number,
    numeroLampade: number,
    percentualeDiPotenzaFunzionamento: number
}
export type DataRawReportAggregate = {
    lastProgramMv?: VirtualProgramMv,
    dataRawChart?: DataRawTypeChart[],
    totalEnergyNotReducedConsuption?: number,
    totalEnergyReducedConsuption?: number,
    totalHourOpened?: number,
    totalData?: number,
    sensorData?: SensorDataSegnaliTlc[],
    dataEnegyInError?: number,
    dataHourInError?: number,
    lampadaFunzionante?: boolean,
    lampadaAccesa?: boolean,
    fase?: number
    dateCurrentState?: Date,
    lastFunctionProfile?: { time: number, power: number }[],
    lastDayOpened?: { hour: number, minute: number, onLamp: boolean }[],
    period?: { fromDate: Date, toDate: Date }
}
export type AggregataProgrammaMvType = { inPeriod?: ProgrammiMVParse[], lastBeforeDate?: ProgrammiMVParse }
export type AggregataSegnaliTlcNodoHistory = {
    inPeriod?: SegnaliTlcNodoChangesHistoryParse[],
    lastBeforeDate?: SegnaliTlcNodoChangesHistoryParse
}
export type AggregataDataType = {
    segnaliTlcNodo: SegnaliTlcNodoParse;
    programmiMv: {
        p0?: AggregataProgrammaMvType;
        p1?: AggregataProgrammaMvType;
        p2?: AggregataProgrammaMvType;
        p3?: AggregataProgrammaMvType;
        p4?: AggregataProgrammaMvType;
        t0?: AggregataProgrammaMvType;
        t1?: AggregataProgrammaMvType;
        t2?: AggregataProgrammaMvType;
        t3?: AggregataProgrammaMvType;
        t4?: AggregataProgrammaMvType;
        resetToDefault?: AggregataProgrammaMvType;
    };
    statisticheTelecontrollo: {
        fromDate: Date;
        toDate: Date;
        statisticheTelecontrollo: StatisticheTelecontrolloParse[];
    };
    segnaliTlcNodoChangesHistory: AggregataSegnaliTlcNodoHistory
}

@Injectable({
    providedIn: 'root'
})
export class AggregateDataService {
    private loacalAggregateData: {
        puntoLuceId: string,
        fromDateId: string,
        toDateId: string,
        values: AggregataDataType
    }[]

    constructor(private chartService: ChartServiceService) {
    }

    private getDateId(date: Date): string {
        const dateTemp = new Date(date);
        dateTemp.setHours(0, 0, 0, 0);
        return dateTemp.getTime().toString(32);
    }

    /**
     *
     *
     * @param programs
     * viene eseguito un ciclo su tutti i programmimv,
     * per ogni chiave tra 't0', 't1', 't2', 't3', 't4', 'p0', 'p1', 'p2', 'p3', 'p4' cerca il programmamv con il valore non null e con la updatedAt maggiore
     * nel caso di resetToDefault == true cancella tutte le chiavi con date antecedente ad updatedAt di quel programmamv
     * @return un programmaMv composto dal 't0', 't1', 't2', 't3', 't4', 'p0', 'p1', 'p2', 'p3', 'p4' piu aggiornato tra tutti i programmiMv
     * @return {[k:string]:{value:number,date:Date}}
     */
    getLastProgramMvByProgrammsMv(programs: ProgrammiMVParse[]): VirtualProgramMv {
        let virtualProgramMv = {};
        if (arrayIsSet(programs)) {
            const allKeyTemp = ['t0', 't1', 't2', 't3', 't4',];
            const allKeyPower = ['p0', 'p1', 'p2', 'p3', 'p4',];
            // ordine decrescente, in modo tale che il successivo è sempre stato inviato dopo del precedente
            programs
                .sort((a, b) => {
                    const updatedTimeA = a.updatedAt != null ? a.updatedAt.getTime() : 0;
                    const updatedTimeB = b.updatedAt != null ? b.updatedAt.getTime() : 0;
                    return updatedTimeA - updatedTimeB;
                })
                .forEach(programm => {
                    allKeyTemp
                        .concat(allKeyPower)
                        .forEach(key => {
                            if (programm[key] != null) {
                                if (virtualProgramMv[key] == null) {
                                    virtualProgramMv[key] = {value: undefined, date: undefined}
                                }
                                if (virtualProgramMv[key].date == null || virtualProgramMv[key].date.getTime() < programm.updatedAt.getTime()) {
                                    virtualProgramMv[key].value = programm[key];
                                    virtualProgramMv[key].date = programm.updatedAt;
                                }
                            }
                        })
                    if (virtualProgramMv != null && arrayIsSet(Object.keys(virtualProgramMv))) {
                        if (programm.resetToDefault == true) {
                            Object.keys(virtualProgramMv).forEach(key => {
                                if (virtualProgramMv[key].date.getTime() > programm.updatedAt.getTime()) {
                                    delete virtualProgramMv[key];
                                }
                            })
                        }
                    }

                })
        } else {
            const date = new Date();
            virtualProgramMv = {'t0': {value: 0, date}, 'p0': {value: 100, date}}
        }

        return virtualProgramMv
    }

    /**
     *
     * @param aggregateData
     * @param date
     * @returns ritorna tutti i programmiMv prima di 'date'
     */
    getLastProgrammiMvBeforeDate(aggregateData: AggregataDataType, date: Date = undefined): ProgrammiMVParse[] {
        const tempDate = new Date(date);
        if (aggregateData.programmiMv != null) {
            return Object.keys(aggregateData.programmiMv)
                .reduce((prev, key) => {
                    let lastProgrammiMv = [];
                    const programmaMv = aggregateData.programmiMv[key];
                    if (arrayIsSet(programmaMv.inPeriod)) {
                        if (date != null) {
                            lastProgrammiMv = programmaMv.inPeriod.filter(p => {
                                const temp = new Date(p.updatedAt)
                                return tempDate.getTime() <= temp.getTime();
                            })
                        } else {
                            lastProgrammiMv = programmaMv.inPeriod
                        }
                    }
                    if (programmaMv.lastBeforeDate) {
                        lastProgrammiMv.push(programmaMv.lastBeforeDate)
                    }
                    if (arrayIsSet(lastProgrammiMv)) {
                        const idsPreviusProgramMv = prev.map(p => p.objectId)
                        prev = prev.concat(
                            lastProgrammiMv
                                .filter(lP => !idsPreviusProgramMv.includes(lP.objectId))
                        );
                    }
                    return prev;
                }, [])


        }
        return undefined;
    }

    /**
     *
     * @param date
     * @param hourReferementData
     * @param aggregateData
     * @returns restituisce la potenza utilizzata da una singola lampada in base al profilo di funzionamento.
     *
     */
    getPowerByDate(date: Date, hourReferementData: number, aggregateData: AggregataDataType): number {
        if (aggregateData && aggregateData.programmiMv == null) {
            return 1;
        }
        const allProgramMvBeforeDate: ProgrammiMVParse[] = this.getLastProgrammiMvBeforeDate(aggregateData, date)
        let virtualProgramMv = this.getLastProgramMvByProgrammsMv(allProgramMvBeforeDate)
        let i = 0;
        let diffPositive = {power: undefined, timeDiff: undefined}
        let diffNegative = {power: 100, timeDiff: undefined}
        if (virtualProgramMv != null && arrayIsSet(Object.keys(virtualProgramMv))) {
            while (i < 5) {
                if (virtualProgramMv['t' + i] != null && virtualProgramMv['t' + i].value != null) {
                    const diff = hourReferementData * 10 - virtualProgramMv['t' + i].value
                    if (diff >= 0 && (diffPositive.timeDiff == null || diff < diffPositive.timeDiff)) {
                        diffPositive.power = virtualProgramMv['p' + i].value
                        diffPositive.timeDiff = diff;
                    } else if (diff < 0 && (diffNegative.timeDiff == null || diff < diffNegative.timeDiff)) {
                        diffNegative.power = virtualProgramMv['p' + i].value
                        diffNegative.timeDiff = diff;
                    }

                }
                i++;
            }
        }
        const power = diffPositive.timeDiff >= 0 ? diffPositive.power : diffNegative.power;
        return power;
    }

    /**
     *
     * @param virtualProgramMv
     * converte l' ultimo programmaMv in un arraycon ora e potenza
     */
    getProfileFunctionByProgramMv(virtualProgramMv: VirtualProgramMv): { time: number, power: number }[] {
        const profile: { time, power, updatedAt }[] = [];
        let i = 0;
        while (i < 5) {
            if (virtualProgramMv['t' + i] != null && virtualProgramMv['t' + i].value != null) {
                const time = virtualProgramMv['t' + i].value
                const power = virtualProgramMv['p' + i].value != null ? virtualProgramMv['p' + i].value : 100;
                const timeDate = virtualProgramMv['t' + i].date
                const powerDate = virtualProgramMv['p' + i].date;
                const updatedAt = timeDate.getTime() > powerDate.getTime() ? timeDate : powerDate;
                const index = profile.findIndex(p => p.time === time);
                if (index >= 0) {
                    const updatedAtStored = profile[index].updatedAt;
                    if (updatedAtStored.getTime() < updatedAt.getTime()) {
                        profile[index].time = time;
                        profile[index].power = power;
                        profile[index].updatedAt = updatedAt;
                    }
                } else {
                    profile.push({time, power, updatedAt});
                }
            }
            i++;
        }
        profile.sort((a, b) => {
            const timeA = a.time != null ? a.time : 0
            const timeB = b.time != null ? b.time : 0
            return timeA - timeB;
        })
        return profile
    }

    getLampadaFunzionanteByDate(values: AggregataDataType, date: Date): number {
        let changeHistory: SegnaliTlcNodoChangesHistoryParse;
        let history = [];
        if (values.segnaliTlcNodo != null) {
            const updatedAt = values.segnaliTlcNodo.updatedAt;
            const lampadaFunzionante = values.segnaliTlcNodo.lampadaFunzionante;
            history.push({updatedAt, lampadaFunzionante})
        }
        if (values.segnaliTlcNodoChangesHistory != null) {
            if (arrayIsSet(values.segnaliTlcNodoChangesHistory.inPeriod)) {
                history = history.concat(values.segnaliTlcNodoChangesHistory.inPeriod);
            }
            if (values.segnaliTlcNodoChangesHistory.lastBeforeDate != null) {
                history.unshift(values.segnaliTlcNodoChangesHistory.lastBeforeDate)
            }
        }
        if (arrayIsSet(history)) {
            history.sort((a, b) => {
                const timeA = a.updatedAt != null ? a.updatedAt.getTime() : 0
                const timeB = b.updatedAt != null ? b.updatedAt.getTime() : 0
                return timeB - timeA;
            })
            const index = history.findIndex(h => date.getTime() >= h.updatedAt.getTime())
            if (index >= 0) {
                changeHistory = history[index];
            }
        }
        return (changeHistory == null || changeHistory.lampadaFunzionante) ? 1 : 0;
    }

    getAggregateLocal(puntoLuce: PuntiLuceParse, fromDate: Date, toDate: Date): AggregataDataType {
        if (arrayIsSet(this.loacalAggregateData)) {
            const values = this.loacalAggregateData.find(v => {
                return v.puntoLuceId === puntoLuce.objectId && v.fromDateId === this.getDateId(fromDate) && v.toDateId === this.getDateId(toDate);
            })
            if (values != null) {
                return values.values
            }
        }
    }

    addAggregateLocal(puntoLuce: PuntiLuceParse, fromDate: Date, toDate: Date, values: AggregataDataType): void {
        if (!arrayIsSet(this.loacalAggregateData)) {
            this.loacalAggregateData = [];
        }
        const index = this.loacalAggregateData.findIndex(v => {
            return v.puntoLuceId === puntoLuce.objectId && v.fromDateId === this.getDateId(fromDate) && v.toDateId === this.getDateId(toDate);
        })
        if (index < 0) {
            this.loacalAggregateData.push({
                puntoLuceId: puntoLuce.objectId,
                fromDateId: this.getDateId(fromDate),
                toDateId: this.getDateId(toDate),
                values
            })
        } else {
            this.loacalAggregateData[index].values = values
        }
        if (this.loacalAggregateData.length > 20) {
            this.loacalAggregateData.shift();
        }

    }

    unsetAggregateLocal(): void {
        this.loacalAggregateData = undefined;
    }

    getAggregateData$(progetto: ProgettiParse, circuito: CircuitiParse, puntoLuce: PuntiLuceParse, fromDate: Date, toDate: Date, forceLoadToDatabase = true): Observable<AggregataDataType> {
        const data = {fromDate, toDate};
        if (progetto != null) {
            data['progettoId'] = progetto.objectId
        }
        if (circuito != null) {
            data['circuitoId'] = circuito.objectId
        }
        if (puntoLuce != null) {
            data['puntoLuceId'] = puntoLuce.objectId
        }
        if (puntoLuce == null || (puntoLuce.potenzaNominale == null && puntoLuce.potenzaEffettiva == null)) {
            return throwError('potenzaNominale or potenzaEffetiva is required')
        }
        let res: Observable<AggregataDataType>;
        if (!forceLoadToDatabase && arrayIsSet(this.loacalAggregateData)) {
            const values = this.getAggregateLocal(puntoLuce, fromDate, toDate);
            if (values != null) {
                res = of(values);
            }
        }
        if (res == null) {
            res = fromPromise(Parse.Cloud.run('getAggreagateReportsElement', paramsApiParse(data)))
        }
        return res.pipe(
            map(values => {
                if (values != null) {
                    this.addAggregateLocal(puntoLuce, fromDate, toDate, values);
                    if (values.programmiMv && arrayIsSet(Object.keys(values.programmiMv))) {
                        values.programmiMv = Object.keys(values.programmiMv)
                            .reduce((prev, currentKey) => {
                                if (values.programmiMv[currentKey] != null) {
                                    if (prev[currentKey] == null) {
                                        prev[currentKey] = {};
                                    }
                                    if (values.programmiMv[currentKey].lastBeforeDate != null) {
                                        const programmaMv = new ProgrammiMVParse();
                                        programmaMv.objectId = values.programmiMv[currentKey].lastBeforeDate.id;
                                        prev[currentKey]['lastBeforeDate'] = programmaMv;
                                    }
                                    if (arrayIsSet(values.programmiMv[currentKey].inPeriod)) {
                                        prev[currentKey]['inPeriod'] = values.programmiMv[currentKey].inPeriod.map(p => {
                                            const programmaMv = new ProgrammiMVParse();
                                            programmaMv.objectId = p.id;
                                            return programmaMv
                                        });
                                    }
                                }
                                return prev
                            }, {} as any);
                    }
                    if (values.statisticheTelecontrollo && arrayIsSet(values.statisticheTelecontrollo.statisticheTelecontrollo)) {
                        values.statisticheTelecontrollo.statisticheTelecontrollo = values.statisticheTelecontrollo.statisticheTelecontrollo.map(stat => {
                            const statistica = new StatisticheTelecontrolloParse();
                            statistica.objectId = stat.id
                            return statistica;
                        });
                    }
                }
                return values
            })
        );
    }

    getDataChartAndProgramMv(puntoLuce, values: AggregataDataType): DataRawReportAggregate {
        let returnValues = {};
        if (values.statisticheTelecontrollo != null) {
            const dataRawChart: DataRawTypeChart[] = [];
            const potenza = puntoLuce.potenzaNominale ? puntoLuce.potenzaNominale : puntoLuce.potenzaEffettiva;
            const numeroLampade = puntoLuce.numeroLampade ? puntoLuce.numeroLampade : 1;
            let totalEnergyReducedConsuption = 0;
            let totalEnergyNotReducedConsuption = 0;
            let totalHourOpened = 0;
            let dataEnegyInError = 0;
            let dataHourInError = 0;
            const totalData = values.statisticheTelecontrollo.statisticheTelecontrollo.length;
            values.statisticheTelecontrollo.statisticheTelecontrollo.forEach(statistica => {
                const data = statistica.dataRiferimento;
                let ora = statistica.createdAt.getHours();
                const minute = statistica.createdAt.getMinutes();
                if (minute >= 50) {
                    ora = ora + 1;
                }
                const percentualeDiPotenzaFunzionamento = this.getPowerByDate(data, ora, values) / 100
                let oreAcceso = statistica.oreAcceso * 100 > 95 && statistica.oreSpento == 0 ? 1 : Math.round(statistica.oreAcceso * 1000) / 1000
                const lampadaFunzionante = this.getLampadaFunzionanteByDate(values, data)
                oreAcceso = oreAcceso * lampadaFunzionante
                dataRawChart.push({
                    data,
                    ora,
                    oreAccensioneNellOra: oreAcceso,
                    potenzaPuntoLuce: potenza,
                    numeroLampade,
                    percentualeDiPotenzaFunzionamento
                })
                const powerConsuption = numeroLampade * potenza * oreAcceso
                if (isNaN(powerConsuption) || isNaN(percentualeDiPotenzaFunzionamento)) {
                    dataEnegyInError++;
                } else if (powerConsuption != 0) {
                    totalEnergyReducedConsuption += powerConsuption * percentualeDiPotenzaFunzionamento;
                    totalEnergyNotReducedConsuption += powerConsuption;
                }
                if (typeof oreAcceso != "number") {
                    dataHourInError++;
                } else if (oreAcceso >= 0) {
                    totalHourOpened += oreAcceso;
                }
            })
            const lastProgramMv = this.getLastProgramMvByProgrammsMv(this.getLastProgrammiMvBeforeDate(values, undefined))
            const lastFunctionProfile = this.getProfileFunctionByProgramMv(lastProgramMv)
            const lastDayOpened: { hour: number, minute: number, isOn: boolean }[] = [];
            values.statisticheTelecontrollo.statisticheTelecontrollo
                .sort((a, b) => {
                    const dateA = a.updatedAt ? a.updatedAt.getTime() : 0;
                    const dateB = b.updatedAt ? b.updatedAt.getTime() : 0;
                    return dateB - dateA
                })
            let i = 0;
            while (i < 24) {
                const statistica = values.statisticheTelecontrollo.statisticheTelecontrollo.find(s => {
                    const updateAt = s.dataRiferimento;
                    if (updateAt != null) {
                        return updateAt.getHours() == i;
                    }
                })
                if (statistica != null) {
                    lastDayOpened.push({
                        hour: statistica.dataRiferimento.getHours(),
                        minute: statistica.dataRiferimento.getMinutes(),
                        isOn: statistica.oreAcceso >= 0.25 //sotto i 15 minuti è considerato spento
                    })
                }
                i++;
            }

            returnValues = {
                lastProgramMv,
                lastFunctionProfile,
                lastDayOpened,
                dataRawChart,
                totalEnergyReducedConsuption,
                totalEnergyNotReducedConsuption,
                totalHourOpened,
                period: {
                    fromDate: values.statisticheTelecontrollo.fromDate,
                    toDate: values.statisticheTelecontrollo.toDate
                },
                totalData,
                dataHourInError,
                dataEnegyInError
            }
        }
        if (values.segnaliTlcNodo != null) {
            const dateCurrentState = values.segnaliTlcNodo.updatedAt;
            const lampadaAccesa = values.segnaliTlcNodo.lampadaAccesa
            const lampadaFunzionante = values.segnaliTlcNodo.lampadaFunzionante
            const fase = values.segnaliTlcNodo.fase
            let sensorData;
            if (arrayIsSet(values.segnaliTlcNodo.sensorData)) {
                sensorData = values.segnaliTlcNodo.sensorData;
            }
            returnValues = {...returnValues, lampadaFunzionante, lampadaAccesa, fase, sensorData, dateCurrentState}
        }
        return arrayIsSet(Object.keys(returnValues)) ? returnValues : undefined;
    }

    getDataChartProfile(profile: { time: number, power: number }[], lastDayOpened: {
        hour: number,
        minute: number,
        isOn: boolean
    }[], pointRadius = 3, maintainAspectRatio = false, title: {
        x?: string,
        y?: string,
        chart?: string,
        labelDataSet?: string
    } = undefined) {
        const datasets = []
        if (arrayIsSet(profile)) {
            let centerdMidNight = []
            let i = 0
            while (i < 24) {
                const timeSlot = profile.find(p => Math.trunc(p.time / 10) == i)
                let x = (i < 13) ? i + 24 : i;
                const beforePower = arrayIsSet(centerdMidNight) ? centerdMidNight[centerdMidNight.length - 1].y : profile[profile.length - 1].power;
                const hour = Math.trunc(i);
                const minute = i - hour;
                let isOn = {value: undefined, minDiff: undefined};
                lastDayOpened
                    .filter(day => day.hour === hour || (day.hour - 1) === hour || (day.hour + 1) === hour)
                    .forEach(day => {
                        const hourInMinute = Math.abs(day.hour - hour) * 60;
                        const diffMinute = Math.abs(minute * 60 + hourInMinute - day.minute);
                        if (diffMinute < 60 && (isOn.minDiff == null || diffMinute < isOn.minDiff)) {
                            isOn.minDiff = diffMinute;
                            isOn.value = day.isOn == true ? 1 : 0;
                        }
                    })
                if (timeSlot != null) {
                    centerdMidNight.push({x: x, y: timeSlot.power, isOn: isOn.value})
                } else {
                    centerdMidNight.push({x: x, y: beforePower, isOn: isOn.value})
                }
                i += 0.25;
            }
            centerdMidNight
                .sort((a, b) => {
                    return a.x - b.x
                })
            centerdMidNight = centerdMidNight
                .map((data) => {
                    return {x: data.x, y: data.y * data.isOn}
                })
            centerdMidNight = centerdMidNight
                .reduce((chartProfile, timeSlot, index) => {
                    const nextTimeSlot = centerdMidNight[index + 1];
                    chartProfile.push({x: timeSlot.x, y: timeSlot.y});
                    if (nextTimeSlot != null && timeSlot.y != nextTimeSlot.y) {
                        chartProfile.push({x: nextTimeSlot.x, y: timeSlot.y});
                    }
                    return chartProfile
                }, [])
            const color = hunaChartColors.orange;
            datasets.push(
                {
                    backgroundColor: (context) => {
                        const ctx = context && context.chart ? context.chart.canvas : undefined
                        return this.chartService.getGradientBackGroundColorChart('orange', undefined, ctx);
                    },
                    label: title && stringIsSet(title.labelDataSet) ? title.labelDataSet : '',
                    data: centerdMidNight,
                    borderColor: color,
                    pointBorderColor: color,
                    pointBackgroundColor: hunaChartColors.orangeOpacity(0.3),
                    pointHoverBackgroundColor: color,
                    pointHoverBorderColor: color,
                    borderWidth: 2,
                    pointRadius,
                    datalabels: {
                        display: false
                    }
                }
            )
            let dataChart;

            let tooltips: any = {
                callbacks: {
                    title: (tooltipItem, data) => {
                        const xLabel = tooltipItem[0].xLabel
                        if (typeof xLabel == "number") {
                            let hour = Math.trunc(xLabel);
                            const minuteDecimal = xLabel - hour;
                            const minute = minuteDecimal * 60;
                            console.log(hour, minuteDecimal)
                            if (hour > 23) {
                                hour = (hour % 24)
                            }
                            return hour + ':' + minute.toString().padStart(2, '0')

                        }
                        return xLabel;
                    }
                }
            };


            if (arrayIsSet(datasets)) {
                dataChart = {
                    type: 'line',
                    labels: getUniqueValueInArray(centerdMidNight.map(a => a.x)),
                    datasets,
                    options: {
                        tooltips,
                        animation: {
                            duration: 0
                        },
                        maintainAspectRatio,
                        spanGaps: false,
                        responsive: true,
                        scales: {
                            type: 'linear',
                            xAxes: [{
                                afterTickToLabelConversion: function (data) {
                                    var xLabels = data.ticks;
                                    xLabels.forEach(function (labels, i) {
                                        if (stringIsSet(labels) && parseFloat(labels) % 1 !== 0) {
                                            xLabels[i] = undefined;
                                        }
                                    });
                                },
                                reverse: true,
                                display: true,
                                scaleLabel: {
                                    labelString: title && title.x && stringIsSet(title.x) ? title.x : '',
                                    display: true
                                },
                                ticks: {
                                    display: true,
                                    autoSkip: false,
                                    stepSize: 1,
                                    callback: function (label, index, labels) {
                                        if (label > 23) {
                                            return (label % 24).toString()
                                        } else {
                                            return label.toString()
                                        }
                                    }
                                }
                            }],
                            yAxes: [
                                {
                                    gridLines: {
                                        display: false
                                    },
                                    display: true,
                                    scaleLabel: {
                                        labelString: title && title.y && stringIsSet(title.y) ? title.y : '',
                                        display: true
                                    },
                                    ticks: {
                                        suggestedMin: 0,
                                        suggestedMax: 100,
                                    },
                                },

                            ],
                            title: {
                                display: false,
                            }
                        },
                        title: {
                            display: true,
                            text: title && title.chart && stringIsSet(title.chart) ? title.chart : '',
                            fontSize: 14
                        },
                        legend: {
                            display: true,
                        },
                        elements: {
                            line: {
                                tension: 0
                            },
                            point: {
                                radius: pointRadius
                            }
                        },
                    },
                    plugins: {
                        datalabels: {
                            display: false
                        },
                    }
                }
            }
            return dataChart;
        }

    }
}
