<div [formGroup]="form">
    <mat-form-field
            [ngClass]="[classStyle]"
            [appearance]="appearance"
    >
        <mat-label *ngIf="title" [ngClass]="classTitle">{{title}}</mat-label>
        <input

            #autoCompleteInput
            matInput
            formControlName="autoComplete"
            [matAutocomplete]="autoItems"
        >
        <mat-autocomplete #autoItems="matAutocomplete"
                          (closed)="onTouched()"
                          [displayWith]="displayFnNumeroQuadro.bind(this)"
                          (optionSelected)="emitOptionSelected($event)"
        >
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.valueForm">
                {{option|convertValueByPredicateFunction:getVisuzlizedKeyPredicate:translateInstan}}
            </mat-option>
        </mat-autocomplete>
        <button mat-icon-button matSuffix *ngIf="!hiddenClearButton" (click)="$event.stopPropagation();resetForm()"
                [disabled]="form.disabled">
            <mat-icon>clear</mat-icon>
        </button>
        <span matPrefix>
            <ng-content select="[prefix]">
            </ng-content>
        </span>
        <div matSuffix>
            <ng-content select="[suffix]">
            </ng-content>
        </div>
        <mat-hint style="color: red" *ngIf="hasError&&messageError!=null">
            {{messageError}}
        </mat-hint>
    </mat-form-field>
</div>

