<mat-card>
    <mat-card-subtitle>
        <form [formGroup]="formFilter">
            <div class="flex-around-center w100 flex-flow-row">
                <app-auto-complete-form
                        [classStyle]="'marginForCenterFormInTable'"
                        style="width: 100%; margin: 0 5px;"
                        title="{{'scheduleMaintenance.filterName'|translate}}"
                        formControlName="progetti"
                        [items]="itemsAutoCompleteFilter"
                        appearance="fill"
                        [hiddenClearButton]="true">
                    <div suffix>
                        <mat-icon>search</mat-icon>
                    </div>
                </app-auto-complete-form>
            </div>
        </form>
    </mat-card-subtitle>
    <div style="max-width: 100%;overflow: hidden">
        <div class="flex-center-width100 flex-flow-column">
            <div class="flex-start-start flex-flow-row container-child-width">
                <div *ngFor="let schedeManutenzione of schedeManutenzione|filterByPredicateFunction:predicateFunction:formFilter.get('progetti').value"
                        [ngClass]="['dimension-card-maintenance-'+dimensionCardMaintenance]"
                >
                    <app-box-schede-manutenzione
                            (clickCard)="emitSchedaManutenzione(schedeManutenzione)"
                            [visualizedMenu]="false"
                            [selected]="isSelectedSchedaManutenzione(schedeManutenzione)"
                            [disabled]="isDisabledSchedaManutenzione(schedeManutenzione)"
                            [schedeManutenzione]="schedeManutenzione"
                            (dataDiScadenza)="dataDiScadenza($event,schedeManutenzione)"
                            [selectionMode]="selectionMode"
                    >
                        <div badge *ngIf="visualizedRemoveButton" style="transform: scale(0.8)">
                            <button mat-mini-fab color="warn"
                                    (click)="clickRemove(schedeManutenzione)">
                                <mat-icon>clear</mat-icon>
                            </button>
                        </div>

                        <div badge *ngIf="!visualizedRemoveButton && selectionMode">
                            <mat-checkbox style="margin-right: 14px; margin-top: 10px;" color="primary" [disableRipple]="true" [disabled]="isDisabledSchedaManutenzione(schedeManutenzione)" [checked]="isDisabledSchedaManutenzione(schedeManutenzione) || isSelectedSchedaManutenzione(schedeManutenzione)"></mat-checkbox>
                        </div>
                    </app-box-schede-manutenzione>
                </div>
            </div>
        </div>
    </div>
</mat-card>