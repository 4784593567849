import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver, EventEmitter,
    HostListener,
    Inject,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit, Output,
    ViewChild,
    ViewChildren,
} from '@angular/core';

import {UserService} from '../../../providers/services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../providers/services/auth.service';
import {ProjectService} from '../../../providers/services/project.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {MatSidenav} from '@angular/material/sidenav';
import {AlertService} from '../../../providers/services/alert.service';
import {PlansService} from '../../../providers/services/plans.service';
import {InterdistanceLightPoint, MapService} from '../../../providers/services/map.service';
import {FilterService} from '../../../providers/services/filter.service';
import {NavService} from '../../../providers/services/nav.service';
import {FiltersideService} from '../../../providers/services/filterside.service';
import {DetailsideService} from '../../../providers/services/detailside.service';
import {Subscription} from 'rxjs/internal/Subscription';
// import {AgmMarker, GoogleMapsAPIWrapper, MarkerManager} from '@agm/core';
import {IconService} from '../../../providers/services/icon.service';
import {InfoDialogComponent} from '../info-dialog/info-dialog.component';
import {LoaderService} from '../../../providers/services/loader.service';
import {BreakException} from '../../../classes/BreakException';
import {ButtonComponent} from '../../../widgets/button/button.component';
import {CreateReportComponent} from '../../reports/create-report/create-report.component';
import {CreateGroupComponent} from '../../reports/create-group/create-group.component';
import {InfoReportComponent} from '../../reports/info-report/info-report.component';
import {InfoGroupComponent} from '../../reports/info-group/info-group.component';
import {TranslateService} from '@ngx-translate/core';
import {UtilsService} from '../../../providers/services/utils.service';
import {UserRole} from '../../../../config/static-data';
import {isObject} from 'rxjs/internal-compatibility';
import {ordinamentoEcampiTraduzioni} from 'src/app/models/ordinamentoEcampiTraduzioni';

import {PuntiLuceParse} from 'src/app/models/PuntiLuce.Parse';
import {PictureCameraPointParse} from 'src/app/models/PictureCameraPoint.Parse';
import {combineLatest, forkJoin, interval, Observable, of, Subject} from 'rxjs';
import {chiaviFiltri, chiaviScala, className, scalaColoreNomeTabella} from 'src/app/models/Models';
import {CircuitiParse} from 'src/app/models/Circuiti.Parse';
import {DialogPopUpService} from 'src/app/providers/services/dialog-pop-up.service';
import {delay, filter, map, switchMap, timeInterval, toArray} from 'rxjs/operators';
import {ChangeElementInComponentService} from 'src/app/providers/services/change-element-in-component.service';

import {HttpClient} from '@angular/common/http';
import {SettingsService} from 'src/app/providers/services/settings.service';
import {DatePipe, formatDate} from '@angular/common';
import {ScaleColorService} from 'src/app/providers/services/scale-color.service';
import {TransformForTranslatePipe} from 'src/app/providers/pipes/transform-for-translate.pipe';
import {isNotNullOrUndefined} from "../../../models/Models";
import {SegnaliTlcNodoService} from "../../../providers/services/segnali-tlc-nodo.service";
import {SegnaliTlcNodoParse} from "../../../models/SegnaliTlcNodo.Parse";
import {valueLinearScaleColorService} from "../../../providers/services/value-linear-color.service";
import {GruppiPuntiLuceParse} from "../../../models/GruppiPuntiLuce.Parse";
import {FormFilterComponent} from "../../../providers/forms/filter-form.service";
import {PuntiLuceScaleColorService} from "../../../providers/services/punti-luce-scale-color.service";


declare var google: any;

export const maxZoomLabelVisualized: number = 17;

//todo aggiornanre mappa

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    // providers: [
    //     GoogleMapsAPIWrapper
    // ]
})
export class DashboardComponent implements OnInit, OnDestroy, OnChanges {

    // viene recuperata l' icona dei quadri elettrici
    public maxIcon = this.iconService.iconQuadroMy();


    public chiaviFiltri = chiaviFiltri;
    public mapTypeId = 'roadmap';
    public circuiti;
    private captureMarker = false;
    public detail;
    public loading = true;
    public lat: number;
    public lng: number;
    private readonly filterSubscription: Subscription;
    private refresh = true;
    private map;
    // public rows?;
    public rowsR = [];//contiene i lightpoint da visusalizzare
    public reports = [];
    public fullWindow = true;
    public oldLocalFilterLightpoint = [];
    public oldLocalFilterCircuiti = [];
    public oldLocalAdvancedFilterLightpoint = [];
    public modalitaResponsive: boolean = false;
    // @ViewChildren(AgmMarker) markers;

    @ViewChild(ButtonComponent, {static: false}) buttonComponentRef: ButtonComponent;

    @ViewChild('filterside', {static: true}) filterside: MatSidenav;

    /** dati per creare un gruppo e/o una segnalazione */
    private event = [];                                     // tutte le aree generate
    private polygons = [];
    private circles = [];
    private rectangles = [];
    private drawingManager;
    private overLayCompleteListener;                        // listener sulla creazione della singola area
    private groupsCircuiti = [];
    private groupsRows = [];
    public visible = false;

    public luxScaleOff: boolean = true;
    public puntiLuceLuxScale = [];
    public elementiScalaLux;
    public luxmax;
    public luxmin;

    public elemetLabel = 'nobody';


    public planPreviewLink;
    public plan;

    private infoWindowOpened = new Array(2);

    public stradeAppartenentiAlProgettoExport = [];
    public circuitiAppartenentiAlProgettoExport = [];
    public gruppiAppartenentiAlProgettoExport = [];

    public elementiclasse = ordinamentoEcampiTraduzioni;

    public puntiStampa: PictureCameraPointParse[] = [];
    //viene consrvato lo spigolo a nordovest e a sud est
    private clickedOpenEyeButton: boolean = false;
    private disabledEyeButton: boolean = false;
    public disableGroupReportsButton: boolean = false;
    public markerSpostabile: boolean = false;
    public visibleUndoButton: boolean = false;
    public disableUndoButton: boolean = false;
    public disableMoveButton: boolean = false;
    public disableAddButton: boolean = false;

    public confiniPolitici;
    public windowsRowsR: PuntiLuceParse[] = [];//contiene i lightpoint visti nella finestra da visualizzare
    private posizioniIniziliaMarker: { id, latitudine, longitudine, indirizzo, nomeClasse, provenienza }[];
    public iconPuntiStampa: string = 'assets/marker/Marker_stampa_foto.svg';

    private coordinateNuovoElemento = new Subject<{ lat, lng }>();
    private conservaDialog; // viene conservato per chiudere l aggiunta degli elementi

    private istanzaSubScribeChangeProgect;
    private istanzaSubScribeGetConfiniAmministrativi;
    public confiniAmministrativi;


    public modificaAvvenutaPerSetting: boolean = false;
    public vettoreDeiCluster = [];

    public changeProjectcIdchangeConfiniSelect: boolean = true;
    public changeProjectcIdchangePuntiStampaSelect: boolean = true;

    // i valori per ogni polyline
    public interdistances: InterdistanceLightPoint[] = [];
    // i valori degli  infoWindow da visualizzare per ogni polyline
    public polyLineInfoWindow: { objectId, lat, lng, persistent }[] = [];
    public interdistanceOff: boolean = true;
    public interdistanceDetail: { average: number, total: number, maximun: number, minimum: number };


    @Input()
    public reportId;
    @Input()
    public planId;

    @Output()
    planIsSaved = new EventEmitter<{}>();

    public zoomSetting = 0;
    public maxZoomToViusualizedLabel = maxZoomLabelVisualized;


    @ViewChild('detailside', {static: true}) detailside: MatSidenav;

    @ViewChild('settingSide', {static: true}) settingSide: MatSidenav;

//todo aggiornanre mappa

    constructor(
        // private markerManager: MarkerManager,
        private userService: UserService,
        private router: Router,
        // private googleMapService: GoogleMapsAPIWrapper,
        private activatedRoute: ActivatedRoute,
        private authService: AuthService,
        private loaderService: LoaderService,
        public dialog: MatDialog,
        public iconService: IconService,
        private filterService: FilterService,
        @Inject(ComponentFactoryResolver) private factoryResolver,
        private plansService: PlansService,
        private mapService: MapService,
        private navService: NavService,
        private detailSideService: DetailsideService,
        private filterSideService: FiltersideService,
        private settingSideService: SettingsService,
        private alertService: AlertService,
        private projectService: ProjectService,
        private ngZone: NgZone,
        private translate: TranslateService,
        private utilsService: UtilsService,
        private changeDetector: ChangeDetectorRef,
        private translateService: TranslateService,
        private myDialog: DialogPopUpService,
        private changeProgect: ChangeElementInComponentService,
        private serviHttp: HttpClient,
        private datePipe: DatePipe,
        private scaleColor: ScaleColorService,
        private linearScaleService: valueLinearScaleColorService,
        private segnaliTlcNodoService: SegnaliTlcNodoService,
        private scaleColorPuntiLuce: PuntiLuceScaleColorService,
    ) {


        this.filterService.setLocaleConfiniAmministrativi([chiaviFiltri.confiniAmministrativi]);
        this.scaleColor.elementoScalaAttuale = 'tipologiaSorgenteLuminosa';
        //  quando vien cambiato il progetto vengono azzerati i vettori ed i filtri e riinizializzata la mappa
        this.istanzaSubScribeChangeProgect = this.projectService.projectChange()
            .pipe(
                switchMap((project) => {
                    const combine = [this.mapService.getCircuiti(undefined, project), this.mapService.getStrade(undefined, project), this.mapService.getGruppiPuntiLuce()];
                    return combineLatest(combine);
                })
            )
            .subscribe((CircuitiStradeGruppi) => {
                this.filterService.destroyAllFilter();
                this.filterService.setLocaleConfiniAmministrativi([chiaviFiltri.confiniAmministrativi]);
                this.changeProjectcIdchangeConfiniSelect = true;
                this.changeProjectcIdchangePuntiStampaSelect = true;
                this.rowsR = [];
                this.windowsRowsR = [];
                this.circuiti = [];
                this.puntiStampa = [];
                this.confiniAmministrativi = [];
                this.initMap();
                const circuiti = CircuitiStradeGruppi[0];
                const strade = CircuitiStradeGruppi[1];
                const gruppi = CircuitiStradeGruppi[2] as GruppiPuntiLuceParse[];
                this.circuitiAppartenentiAlProgettoExport = this.aggiornaVettoreDetailFilter(circuiti, this.elementiclasse.PuntiLuce.circuito.possibleValues)
                this.stradeAppartenentiAlProgettoExport = this.aggiornaVettoreDetailFilter(strade, this.elementiclasse.PuntiLuce.strada.possibleValues);
                this.setGruppiPuntiLuce(gruppi);
            });

        this.mapService.getGruppiPuntiLuce().subscribe(gruppi => {
            this.setGruppiPuntiLuce(gruppi);
        })
        //vengono importati in detailside e filterSide
        this.mapService.getCircuiti().subscribe((circuiti) => {
                this.setCircuiti(circuiti);
            }
        );
        this.mapService.getStrade().subscribe((ArrayStrade) => {
                this.stradeAppartenentiAlProgettoExport = this.aggiornaVettoreDetailFilter(ArrayStrade, this.elementiclasse.PuntiLuce.strada.possibleValues);
            }
        );

        if (window.innerWidth < 512) {
            this.fullWindow = false;
        }
        if (this.filterService.getReport().length > 0) {
            this.visible = true;
        }
        if (!this.projectService.getProjectLocal()) {
            this.alertService.error(this.translate.instant('select_project_error'));
            this.router.navigate(['/project']);
        }
        this.filterSubscription = this.filterService.observableFilter().subscribe((res) => {
            // console.log(res)
            // console.log(this.filterService.getLocaleAddress(),this.plansService.currentMap)
            if (res.event === 'destroy') {
                // this.rows = undefined;
                // viene svuotato l array contenela chiamata con i filtri
                // this.rowsR.splice(0,this.rowsR.length);
                this.circuiti = undefined;
                this.initMap(true, false);
            } else if (res.event === 'search') {
                if (this.filterService.getLocaleAddress()) {
                    this.lat = this.mapService.getLat();
                    this.lng = this.mapService.getLng();
                    if (this.plansService.currentMap) {
                        this.plansService.currentMap.setCenter(new google.maps.LatLng(this.lat, this.lng));
                    }
                    this.createRemoveMarkerInDissolvence(this.map, {
                        lat: this.mapService.getLat(),
                        lng: this.mapService.getLng()
                    }, 1);
                }
            } else {
                // this.rows = undefined;
                // viene svuotato l array
                // this.rowsR.splice(0,this.rowsR.length);
                // this.circuiti = undefined;
                this.initMap(false, false);
            }
        });
        this.reportId = this.activatedRoute.snapshot.queryParams.reportId;
        this.planId = this.activatedRoute.snapshot.queryParams.planId;
    }

    symmetricDifferenceInArray(arr1: any[], arr2: any[]): {
        isPositive: boolean,
        difference,
        massiveDeselect: boolean
    } {
        if (Array.isArray(arr1) && Array.isArray(arr2)) {
            return {
                isPositive: arr1.length < arr2.length,
                difference: arr1
                    .filter(x => !arr2.includes(x))
                    .concat(
                        arr2.filter(x => !arr1.includes(x))),
                massiveDeselect: false
            };
        } else if (Array.isArray(arr1) && !Array.isArray(arr2)) {
            return {
                isPositive: false,
                difference: arr1,
                massiveDeselect: true
            };
        } else {
            return {
                isPositive: true,
                difference: arr2,
                massiveDeselect: false
            };
        }
    }

    changeFilter(appValueFilter: { previusValue: FormFilterComponent, currentValue: FormFilterComponent }) {
        let difference;
        if (isNotNullOrUndefined(appValueFilter.previusValue)) {
            difference = this.symmetricDifferenceInArray(appValueFilter.previusValue.gruppiPuntiLuce, appValueFilter.currentValue.gruppiPuntiLuce)
        } else {
            difference = this.symmetricDifferenceInArray(null, appValueFilter.currentValue.gruppiPuntiLuce)
        }
        if (difference.isPositive) {
            const value = difference.difference;
            if (Array.isArray(value) && value.length > 0) {
                const loaderId = this.loaderService.addLoader();
                const allLightPoint = [];
                value.forEach(gruppoId => {
                    const gruppoIdObs = this.mapService.getPuntiLuceDelGruppo(gruppoId)
                    allLightPoint.push(gruppoIdObs);
                })
                return forkJoin(allLightPoint).pipe(
                    toArray(),
                    map(arrayArrayPuntiLuce => {
                        let arrayPuntiLuce: PuntiLuceParse[] = [];
                        arrayArrayPuntiLuce.forEach((arrayArraypls: PuntiLuceParse[][]) => {
                            arrayArraypls.forEach((pls, indx) => {
                                pls.forEach(pl => {
                                    const includeInArray = pl.includesInArray(arrayPuntiLuce);
                                    if (includeInArray.presente) {
                                        if (Array.isArray(arrayPuntiLuce[includeInArray.indice].gruppiId) && pl.isSetGruppoId()) {
                                            arrayPuntiLuce[includeInArray.indice].gruppiId.push(...pl.gruppiId)
                                        }
                                    } else {
                                        arrayPuntiLuce.push(pl)
                                    }
                                })
                            })
                        })
                        return arrayPuntiLuce
                    }),
                ).subscribe(
                    puntiLuce => {
                        const ingresso: scalaColoreNomeTabella = {
                            scalaColore: this.scaleColor.getelementiScala(),
                            tabellaImpostataScala: this.scaleColor.elementoScalaAttuale
                        };
                        const puntiLuceNonAppertenetiAWindowsRows = []
                        puntiLuce.forEach(
                            puntoLuce => {
                                const includesInArrayWindows = puntoLuce.includesInArray(this.windowsRowsR)
                                const includesInArrayRows = puntoLuce.includesInArray(this.rowsR)

                                if (includesInArrayWindows.presente) {
                                    if (Array.isArray(this.windowsRowsR[includesInArrayWindows.indice].gruppiId)) {
                                        this.windowsRowsR[includesInArrayWindows.indice].gruppiId.push(...puntoLuce.gruppiId);
                                    } else {
                                        this.windowsRowsR[includesInArrayWindows.indice].gruppiId = puntoLuce.gruppiId;
                                    }
                                } else {
                                    puntoLuce.icon = this.iconService.compose(puntoLuce.icon, puntoLuce.tipologiaSorgenteLuminosa);
                                    puntoLuce.icon = this.calcColorIconVector(puntoLuce, ingresso);
                                    this.windowsRowsR.push(puntoLuce);
                                }
                                if (!includesInArrayWindows.presente && !includesInArrayRows.presente) {
                                    puntiLuceNonAppertenetiAWindowsRows.push(puntoLuce)
                                }
                            }
                        )
                        this.cambiaColoreIconeDopoAggionramento(this.rowsR, puntiLuceNonAppertenetiAWindowsRows, true, null, 5);
                        this.vettoreDeiCluster = this.creaClusterWithColor(this.rowsR, puntiLuceNonAppertenetiAWindowsRows);
                        this.changeDetector.detectChanges();
                        this.loaderService.removeLoader(loaderId);
                    }, error => this.loaderService.removeLoader(loaderId)
                )
            }
        } else {
            const value = difference.difference;
            this.windowsRowsR = this.windowsRowsR.filter(
                puntoLuce => {
                    if (puntoLuce.isSetGruppoId()) {
                        let difference = puntoLuce.gruppiId.filter(x => !value.includes(x));
                        puntoLuce.gruppiId = difference;
                        return puntoLuce.gruppiId.length > 0
                    } else {
                        return true
                    }
                }
            )
        }
    }


    private setGruppiPuntiLuce(gruppiPuntiLuce: GruppiPuntiLuceParse[]) {
        this.gruppiAppartenentiAlProgettoExport = this.aggiornaVettoreDetailFilter(gruppiPuntiLuce, 'GruppiPuntiLuce.nome');
    }

    public get isSviluppatore() {
        return this.userService.isSviluppatore;
    }

    private setCircuiti(circuiti: CircuitiParse[]) {
        // this.circuiti = circuiti;
        this.circuitiAppartenentiAlProgettoExport = this.aggiornaVettoreDetailFilter(circuiti, this.elementiclasse.PuntiLuce.circuito.possibleValues);
    }

    private createRemoveMarkerInDissolvence(map, coords: { lat, lng }, durataDissolvenzaSecondi) {
        // tslint:disable-next-line:no-unused-expression
        const marker = new google.maps.Marker({
            animation: google.maps.Animation.BOUNCE,
            position: {lat: coords.lat, lng: coords.lng},
            map: map,
            icon: 'assets/marker/blue-dot.png'
        });
        const durataDissolvenza: number = durataDissolvenzaSecondi * 1000;
        const peridoInMesc: number = 50;
        const numeroDiStepDellaDissolvenza = Math.round(durataDissolvenza / peridoInMesc);
        const mSecond = interval(peridoInMesc);
        const refMsecond = mSecond.pipe(
            delay(5000),
            timeInterval())
            .subscribe(
                value => {
                    if (value.value == numeroDiStepDellaDissolvenza) {
                        marker.setMap(null);
                        refMsecond.unsubscribe();
                    } else {
                        marker.setOpacity(1 - value.value * (1 / numeroDiStepDellaDissolvenza));
                    }
                },
                err => console.log(err),
            );
    }

    // viene preso il progetto, poi l id del comune di conseguenza i confini amministrativi
    private getConfini() {
        let progetto;
        if (this.projectService.avaiableProject) {
            progetto = this.projectService.actualProject;
        } else {
            progetto = this.mapService.getCurrentProjectMy();
        }
        if (isNotNullOrUndefined(progetto) && isNotNullOrUndefined(progetto.comune)) {
            return this.mapService.getComuniByObjectId(progetto.comune.id)
                .pipe(
                    filter((comune) => comune != undefined),//caso in cui il campo comune non è definito altrimenti darebbe errore
                    map((comune) => comune.confiniAmministrativiFile._url),
                    switchMap((confini) => this.serviHttp.get(confini)
                        .pipe(
                            map((confiniInArray) => confiniInArray)
                        )
                    )
                )
        } else {
            return this.mapService.getprogettoAttuale().pipe(
                switchMap((progetto) => {
                    this.projectService.actualProject = progetto;
                    return this.mapService.getComuniByObjectId((!!progetto.comune) ? progetto.comune.id : '').pipe(
                        filter((comune) => comune != undefined),//caso in cui il campo comune non è definito altrimenti darebbe errore
                        map((comune) => comune.confiniAmministrativiFile._url)
                    )
                }),
                switchMap((confini) => this.serviHttp.get(confini).pipe(
                    map((confiniInArray) => confiniInArray)))
            )
        }

    }

    private aggiornaVettoreDetailFilter(arrayElementi, nomeTabellaDaPossibleValues) {
        let vettoreDaAggiornare = [];
        let tabellaDaRecuperare = nomeTabellaDaPossibleValues;
        tabellaDaRecuperare = tabellaDaRecuperare.split('.')[tabellaDaRecuperare.split('.').length - 1];
        // console.log(this.quadriPresentiNelProgetto);
        arrayElementi.forEach((element) => {
            vettoreDaAggiornare.push({
                formValues: element.id,
                formHtml: element[tabellaDaRecuperare]
            });
        });
        return vettoreDaAggiornare;
    }

    public circuitiSelezionati = [];

    async ngOnInit() {
        if (!this.projectService.getProjectLocal()) {
            this.alertService.error(this.translate.instant('select_project_error'));
            this.router.navigate(['/project']);
        } else {
            this.detailSideService.setSidenav(this.detailside);
            this.filterSideService.setSidenav(this.filterside);
            this.settingSideService.setSidenav(this.settingSide);
        }
        this.initMap(true, false, this.planId, this.reportId);
    }

    public get abbonamentoAttivo(): boolean {
        return this.projectService.abbonamentoGestioneAttivo;
    }

    public get abbonamentoIlluminametoAttivo(): boolean {
        return this.projectService.abbonamentoIlluminamentoAttivo;
    }

    public get abbonamentoProgettazioneAttivo(): boolean {
        return this.projectService.abbonamentoProgettazioneAttivo;
    }

    public get userAlmenoLivello5(): boolean {
        return this.userService.abbonamentoAlmenoLivello5;
    }


    ngOnChanges() {
        //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
        //Add '${implements OnChanges}' to the class.
    }


    public get gestore() {
        return (isNotNullOrUndefined(this.projectService.actualRoles)) ? this.projectService.actualRoles.includes(UserRole.GESTORE) : false;
    }


    private aggiornaVettoriElementi(elementiVisualizzatiConFiltri, elementiVisualizzatiConOcchio, puntoLuceModificato, p) {
        let inWindowsR: boolean = false;
        inWindowsR = (elementiVisualizzatiConFiltri.every((puntoLuce, index) => {
            if (puntoLuce.id == p.id) {
                elementiVisualizzatiConFiltri.splice(index, 1);
                return false;
            } else {
                return true;
            }
        }));
        if (inWindowsR) {
            elementiVisualizzatiConOcchio = elementiVisualizzatiConOcchio.filter((puntoLuce) => {
                return (puntoLuce.id != p.id);
            });
            if (puntoLuceModificato != null) {
                elementiVisualizzatiConOcchio.push(puntoLuceModificato);
            }
        } else {
            if (puntoLuceModificato != null) {
                elementiVisualizzatiConFiltri.push(puntoLuceModificato);
            }
        }
        return [elementiVisualizzatiConFiltri, elementiVisualizzatiConOcchio];
    }


    //verifica la classe di p fa la chiamata al database e lo sostituisce 
    // con quello gia presente
    private sostituisciElementoDelVettore(p) {
        if (p.classe == className.puntiLuce) {
            let puntoLuceModificato: PuntiLuceParse = null;
            p.filtroCircuito = (Array.isArray(p.filtroCircuito)) ? p.filtroCircuito : [p.filtroCircuito];
            const ingresso: scalaColoreNomeTabella = {
                scalaColore: this.scaleColor.getelementiScala(),
                tabellaImpostataScala: this.scaleColor.elementoScalaAttuale
            };
            this.mapService.getPuntoLuceByObjectIdAndLocalLIghtPoint(p.id, p.filtroCircuito).subscribe(
                (puntoLuce) => {
                    puntoLuceModificato = puntoLuce;
                    if ((puntoLuceModificato != null) ? !isObject(puntoLuceModificato.icon) : false) {
                        puntoLuceModificato.icon = this.iconService.compose(puntoLuceModificato.icon, puntoLuceModificato.tipologiaSorgenteLuminosa);
                        puntoLuceModificato.icon = this.calcColorIconVector(puntoLuceModificato, ingresso);
                    }
                    //viene eliminato il punto luce modificato dall array di tutti i punti luce
                    const vettoriAggiornati = this.aggiornaVettoriElementi(this.rowsR, this.windowsRowsR, puntoLuceModificato, p);
                    this.rowsR = vettoriAggiornati[0];
                    this.windowsRowsR = vettoriAggiornati[1];
                    //todo da
                    this.cambiaColoreIconeDopoAggionramento(vettoriAggiornati[0], vettoriAggiornati[1], true, puntoLuceModificato, p, 1);
                    //se il punto luce è stato resituito viene aggiunto alla mappa
                    this.changeDetector.detectChanges();
                }
            );
        } else if (p.classe == className.circuiti) {
            let circuitoModificato: CircuitiParse = null;
            this.mapService.getCircuiti([p.id]).subscribe((circuito) => {
                circuitoModificato = circuito[0];
                //per eliminare da detail e da filter il circuito
                if (circuitoModificato == null) {
                    this.circuitiAppartenentiAlProgettoExport = this.circuitiAppartenentiAlProgettoExport.filter((circuitoDaEliminare) =>
                        circuitoDaEliminare.formValues != p.id);
                }
                this.circuiti = this.aggiornaVettoriElementi(this.circuiti, [], circuitoModificato, p)[0];
                this.changeDetector.detectChanges();

            });
        } else if (p.classe == className.puntiStampa) {
            let puntoStampaModificato: PictureCameraPointParse = null;
            this.mapService.getPuntiCameraObjectId(p.id).subscribe((puntoStampa) => {
                puntoStampaModificato = puntoStampa;
                this.puntiStampa = this.aggiornaVettoriElementi(this.puntiStampa, [], puntoStampaModificato, p)[0];
                this.changeDetector.detectChanges();
            });
        }
    }

    //se il punto luce viene restituito allora viene aggiornato sul vettore 
    //altrimenti viene elimianto dalal mappa
    public async aggiornaElementoInDetail(p) {
        this.closeInfoWindowOnClick();
        this.sostituisciElementoDelVettore(p);
        const ingresso = {
            tabellaImpostataScala: this.scaleColor.elementoScalaAttuale,
            scalaColore: this.scaleColor.getelementiScala()
        };
        // this.cambiaColoreDelleIcone(ingresso);
    }

    //fissa lo zoom ed il cento in modo tale da contenere l array di marker passato in argomento se vuoto visualizza tutta l italia
    private setCenterZoom(marker: any[]): void {
        let bounds = new google.maps.LatLngBounds();
        if (isNotNullOrUndefined(this.map)) {
            if (marker != null && marker.length > 0) {
                if (marker[0].className != undefined) {
                    marker.forEach(element => {
                        const loc = new google.maps.LatLng(element.location.latitude, element.location.longitude);
                        bounds.extend(loc);
                    });
                    this.map.fitBounds(bounds);
                    this.map.panToBounds(bounds);
                } else {
                    marker.forEach(confine => {
                        confine.forEach((coordinate) => {
                            let loc = new google.maps.LatLng(coordinate.lat, coordinate.lng);
                            bounds.extend(loc);
                        });
                    });
                    this.map.fitBounds(bounds);
                    this.map.panToBounds(bounds);
                }

            } else {
                let loc = new google.maps.LatLng(35.866667, 14.45);
                bounds.extend(loc);
                loc = new google.maps.LatLng(47.218333, 12.173333);
                bounds.extend(loc);
                this.map.fitBounds(bounds);
                this.map.panToBounds(bounds);
            }
            this.mapService.setLat(this.map.getCenter().lat());
            this.mapService.setLng(this.map.getCenter().lng());
        }
        this.changeDetector.detectChanges();
    }

    /**
     *
     * @param puntiLuce sono i puntiluce devono essere aggiornati
     */
    private async updatePuntiLuce(puntiLuce: PuntiLuceParse[]) {
        //contiene gli objectID dei circuiti per fare il refresh dei punti luce
        let filtriCircuito = [];
        let daConservare: boolean;
        //viene composto il vettore dei filtri con l' object id da visualizzare
        puntiLuce.forEach((puntoLuce) => {
            if (!!puntoLuce.circuito) {
                if (!filtriCircuito.includes(puntoLuce.circuito.id)) {
                    filtriCircuito.push(puntoLuce.circuito.id);
                }
            } else if (!filtriCircuito.includes(chiaviFiltri.nessunQuadro)) {
                filtriCircuito.push(chiaviFiltri.nessunQuadro);
            }
        });
        if (filtriCircuito.length == 0) {
            return [];
        }
        return (await this.mapService.getPuntiLuce(null, null, {
            filters: null,
            lightPoints: filtriCircuito
        })).filter((puntoLuceDaChiamata) => {
            daConservare = !(puntiLuce.every((puntoLuce) => {
                return puntoLuce.id != puntoLuceDaChiamata.id;
            }));
            if (daConservare) {
                // vengono composte le icone altrimenti non si visualizzerebbero
                puntoLuceDaChiamata.icon = this.iconService.compose(puntoLuceDaChiamata.icon, puntoLuceDaChiamata.tipologiaSorgenteLuminosa);
            }
            return daConservare;
        });
    }

    public async initMap(loadCircuiti = false, loadPuntiLuce = false, planId?, report?) {
        this.captureMarker = false;
        this.refresh = false;
        this.ngZone.runOutsideAngular(() => {
            setTimeout(() => {
                this.refresh = true;
            }, 200);
        });
        const loaderId = this.loaderService.addLoader();
        if (planId) {
            this.filterService.setLocaleConfiniAmministrativi([]);
            this.planPreviewLink = await this.plansService.previewLink(planId);
            const plan = await this.plansService.getPlan(planId);
            loadPuntiLuce = true;
            this.plan = plan;
            this.lat = (plan.lat);
            this.lng = (plan.lng);
            this.mapTypeId = (plan.tipologiaMappa) ? plan.tipologiaMappa : 'roadmap';
            let filtri;
            if (isNotNullOrUndefined(this.map) && isNotNullOrUndefined(plan.zoom)) {
                this.map.setZoom(plan.zoom);
            }
            if (isNotNullOrUndefined(plan.filtri)) {
                this.filterService.setLocaleFilter(JSON.parse(plan.filtri));
                filtri = JSON.parse(plan.filtri);
            } else {

            }
            if (isNotNullOrUndefined(plan.filtriPuntiLuce)) {
                this.filterService.setLocaleLightPoints(JSON.parse(plan.filtriPuntiLuce));
            } else {
                this.filterService.setLocaleLightPoints([]);
            }
            if (isNotNullOrUndefined(plan.filtriCircuiti)) {
                this.filterService.setLocaleCircuits(JSON.parse(plan.filtriCircuiti));
            } else {
                this.filterService.setLocaleCircuits([]);
            }
            if (isNotNullOrUndefined(plan.filtriObjectIdPuntiLuce)) {
                this.filterService.setLocaleObjectIdLightPoints(plan.filtriObjectIdPuntiLuce);
            } else {
                this.filterService.setLocaleObjectIdLightPoints([]);
            }
            const filtriObjectIdPuntiLuce = plan.filtriObjectIdPuntiLuce ? plan.filtriObjectIdPuntiLuce : [];
            const filtriPuntiLuce = plan.filtriPuntiLuce ? JSON.parse(plan.filtriPuntiLuce) : [];
            const filtriCircuit = plan.filtriCircuiti ? JSON.parse(plan.filtriCircuiti) : [];
            this.circuitiSelezionati = (Array.isArray(filtriCircuit)) ? filtriCircuit : [];
            if (loadPuntiLuce) {
                try {
                    if (Array.isArray(filtri) && filtri.length > 0) {
                        this.rowsR = (await this.mapService.getPuntiLuce(undefined, undefined, {
                            filters: filtri,
                            lightPoints: filtriPuntiLuce,
                        }));
                    }
                    if (Array.isArray(filtriObjectIdPuntiLuce) && filtriObjectIdPuntiLuce.length > 0) {
                        this.windowsRowsR = (await this.mapService.getPuntiLuce(undefined, undefined, {
                            filters: filtri,
                            lightPoints: filtriPuntiLuce,
                        }, filtriObjectIdPuntiLuce));
                    }

                    // this.rows = await this.mapService.getPuntiLuce();
                    // console.log("rows ",this.rows);

                } catch (e) {
                    // this.circuiti = await this.mapService.getCircuiti();
                    this.mapService.getCircuiti().subscribe(
                        (elemento) => {
                            this.circuiti = elemento;
                            // this.setCenterZoom(this.circuiti);
                            this.changeDetector.detectChanges();
                        });
                }
                this.changeDetector.detectChanges();
            }
            if (loadCircuiti) {
                // this.circuiti = await this.mapService.getCircuiti(filtriCircuit);
                if (isNotNullOrUndefined(filtriCircuit) && filtriCircuit.length > 0) {
                    this.mapService.getCircuiti(filtriCircuit).subscribe(
                        (elemento) => {
                            this.circuiti = elemento;
                            // this.setCenterZoom(this.circuiti);
                            this.changeDetector.detectChanges();
                        });
                }
            }
            this.loading = false;
            this.changeDetector.detectChanges();
        } else if (report) {
            this.changeDetector.markForCheck();
            this.rowsR = await this.mapService.getPuntiLuceReport(report);
            this.router.navigate([], {
                queryParams: {
                    reportId: null,
                    youCanRemoveMultiple: null,
                },
                queryParamsHandling: 'merge'
            });
            this.loading = false;
            this.filterService.setLocaleLightPoints([]);
            this.vettoreDeiCluster = this.creaClusterWithColor(this.rowsR, this.windowsRowsR);
            this.mapService.setLat(this.rowsR[0].location.latitude);
            this.mapService.setLng(this.rowsR[0].location.longitude);
            this.mapService.setZoom(18);

            this.changeDetector.detectChanges();
            this.loading = true;
        } else if (this.filterService.hasFilter()) {
            try {
                if (this.filterService.hasConfiniAmministrativi()) {
                    if (this.changeProjectcIdchangeConfiniSelect && !(Array.isArray(this.confiniAmministrativi) && this.confiniAmministrativi.length > 0)) {
                        this.istanzaSubScribeGetConfiniAmministrativi = this.getConfini().subscribe((a) => {
                                this.confiniAmministrativi = a;
                                this.confiniAmministrativi = this.confiniAmministrativi.map(
                                    (progetti) =>
                                        progetti.map((a) => {
                                            return {lat: a[0], lng: a[1]};
                                        }));
                                // this.setCenterZoom(this.confiniAmministrativi);
                                this.changeProjectcIdchangeConfiniSelect = false;
                                this.changeDetector.detectChanges();
                            },
                            (error) => {
                                console.log(error);
                            });
                    }
                } else {
                    this.changeProjectcIdchangeConfiniSelect = true;
                    this.confiniAmministrativi = [];
                }
                // necessarie per il tasto refresh
                // this.windowsRowsR = await this.updatePuntiLuce(this.windowsRowsR);
                await this.loadPuntiLucePaginataConDifferenzaSuiFiltri(this.filterService.getLocaleLightPoints(), this.oldLocalFilterLightpoint,
                    this.filterService.getLocaleFilter(), this.oldLocalAdvancedFilterLightpoint, loadPuntiLuce);
                this.oldLocalFilterLightpoint = this.filterService.getLocaleLightPoints();
                this.oldLocalAdvancedFilterLightpoint = this.filterService.getLocaleFilter();
                if (Array.isArray(this.filterService.getLocaleCircuits()) && this.filterService.getLocaleCircuits().length > 0) {
                    this.getCircuitiWithDifferenceFilter(this.oldLocalFilterCircuiti, this.filterService.getLocaleCircuits(), loadCircuiti);
                    this.oldLocalFilterCircuiti = this.filterService.getLocaleCircuits();
                } else {
                    this.circuiti = []
                    this.oldLocalFilterCircuiti = [];
                    this.changeDetector.markForCheck();
                }
                if (this.filterService.hasPuntiStampa()) {
                    if (this.changeProjectcIdchangePuntiStampaSelect) {
                        this.mapService.getPuntiCamera().subscribe((puntiStampaDatabase) => {
                                this.puntiStampa = puntiStampaDatabase;
                                this.changeProjectcIdchangePuntiStampaSelect = false;
                                if (!this.isSetAndContainElement(this.confiniAmministrativi)) {
                                    this.setCenterZoom(puntiStampaDatabase);
                                }

                            },
                            (error) => {
                                console.log(error);
                            });
                    }


                } else {
                    this.changeProjectcIdchangePuntiStampaSelect = true;
                    this.puntiStampa = [];
                }
                this.cambiaColoreIconeDopoAggionramento(this.rowsR, this.windowsRowsR, true, null, 2);
            } catch (e) {
                console.log(e);
            }
        } else {
            //todo verificare che non serve
            if (!this.filterService.hasConfiniAmministrativi()) {
                this.confiniAmministrativi = [];
            }
            try {
                if (loadPuntiLuce) {
                    try {
                        this.changeDetector.markForCheck();
                        // this.rowsR = await this.mapService.getPuntiLuce();
                        // this.rows = await this.mapService.getPuntiLuce();

                    } catch (e) {
                        // this.circuiti = await this.mapService.getCircuiti();
                        this.mapService.getCircuiti(this.filterService.getLocaleCircuits()).subscribe(
                            (elemento) => {
                                this.circuiti = elemento;
                            });
                    }
                }
                if (loadCircuiti) {
                    // this.circuiti = await this.mapService.getCircuiti();
                    this.mapService.getCircuiti(this.filterService.getLocaleCircuits()).subscribe(
                        (elemento) => {
                            this.circuiti = elemento;

                            // this.setCenterZoom(this.circuiti);
                            // this.setCenterZoom(elemento);
                        });
                }
            } catch (e) {
                console.log(e);
            }
        }

        if (this.map) {
            this.initializeMarker();
        }
        this.cambiaColoreIconeDopoAggionramento(this.rowsR, this.windowsRowsR, false, null, 3);
        this.loaderService.removeLoader(loaderId);
        this.loading = false;

    }

    getCircuitiWithDifferenceFilter(oldFilter, newFilter, forceReload) {
        const oldFilerLength = (oldFilter == null || newFilter == undefined) ? -1 : oldFilter.length;
        const newFilterMaggioreDiOld: boolean = (newFilter != null) ? newFilter.length > oldFilerLength : false;
        const newFilterUgualeZero: boolean = (newFilter != null) ? newFilter.length == 0 : true;
        if (newFilterMaggioreDiOld && (oldFilerLength > 0)) {
            const differenzaTraFiltroFuturoPassato = this.filterService.getDifferenceFilter(newFilter, oldFilter);
            this.mapService.getCircuiti(differenzaTraFiltroFuturoPassato).subscribe((circuiti) => {
                this.changeDetector.markForCheck();
                if (Array.isArray(this.circuiti)) {
                    this.circuiti = this.circuiti.concat(circuiti);
                } else {
                    this.circuiti = circuiti;
                }
            })
        } else if (newFilterMaggioreDiOld && oldFilerLength == 0) {
            this.circuiti = [];
            this.mapService.getCircuiti(newFilter).subscribe((circuiti) => {
                this.changeDetector.markForCheck();
                this.circuiti = circuiti
            });
        } else if (newFilterUgualeZero && isNotNullOrUndefined(oldFilter)) {
            this.circuiti = [];
            this.mapService.getCircuiti(newFilter).subscribe((circuiti) => {
                this.changeDetector.markForCheck();
                this.circuiti = circuiti
            });
        } else if (forceReload) {
            this.circuiti = [];
            this.mapService.getCircuiti(newFilter).subscribe((circuiti) => {
                this.changeDetector.markForCheck();
                this.circuiti = circuiti
            });
        } else {
            if (Array.isArray(this.circuiti)) {
                this.circuiti = this.circuiti.filter((circuito) => {
                    return (isNotNullOrUndefined(newFilter)) ? (newFilter.includes(circuito.objectId)) : true;
                });
            }
        }
        this.changeDetector.markForCheck();
    }

    mapReady(event: any) {
        this.map = this.plansService.setCurrentMap(event);
        this.map.setOptions({
            tilt: 45,
            rotateControl: true,
            rotateControlOptions: {
                position: google.maps.ControlPosition.RIGHT_CENTER
            },
        });
        if (this.fullWindow) {
            this.modalitaResponsive = true;
            this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(document.getElementById('map-nav'));
            this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(document.getElementById('map-info'));
            this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(document.getElementById('map-refresh'));
            this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(document.getElementById('map-settings'));
            this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(document.getElementById('map-luxScale'));
            this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(document.getElementById('map-luxScaleDisabled'));
            this.map.controls[google.maps.ControlPosition.RIGHT_CENTER].push(document.getElementById('map-scale'));
            this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(document.getElementById('map-aggiornaPlanimetria'));
            this.map.controls[google.maps.ControlPosition.LEFT_CENTER].push(document.getElementById('map-interdistancesDetail'));
        } else {
            this.modalitaResponsive = false;
            this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(document.getElementById('map-nav'));
            this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(document.getElementById('map-info'));
            this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(document.getElementById('map-refresh'));
            this.map.controls[google.maps.ControlPosition.RIGHT_TOP].push(document.getElementById('map-settings'));
            this.map.controls[google.maps.ControlPosition.RIGHT_TOP].push(document.getElementById('map-luxScale'));
            this.map.controls[google.maps.ControlPosition.RIGHT_TOP].push(document.getElementById('map-luxScaleDisabled'));
            this.map.controls[google.maps.ControlPosition.RIGHT_CENTER].push(document.getElementById('map-scale'));
            this.map.controls[google.maps.ControlPosition.RIGHT_TOP].push(document.getElementById('map-aggiornaPlanimetria'));
            this.map.controls[google.maps.ControlPosition.LEFT_CENTER].push(document.getElementById('map-interdistancesDetail'));
        }
        // questo ordina cambia la posizione di reinderizzazzione fatta da google
        this.map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(document.getElementById('map-hidden-interdistance'));
        this.map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(document.getElementById('map-zoom'));
        this.map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(document.getElementById('map-eye'));
        this.map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(document.getElementById('map-hidden-eye'));
        this.map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(document.getElementById('drawing'));
        if (this.mapService.getLng() && this.mapService.getLat()) {
            this.map.setCenter(new google.maps.LatLng(this.mapService.getLat(), this.mapService.getLng()));
            this.map.setZoom(5);
        }
        this.initializeMarker();
        if (isNotNullOrUndefined(this.plan) || isNotNullOrUndefined(this.activatedRoute.snapshot.queryParams.planId)) {
            if (isNotNullOrUndefined(this.plan.lat) && isNotNullOrUndefined(this.plan.lng)) {
                this.map.setCenter(new google.maps.LatLng(this.plan.lat, this.plan.lng));
            }
            if (isNotNullOrUndefined(this.plan.zoom)) {
                this.map.setZoom(this.plan.zoom);
            } else {
                this.visualizzaTuttiIMarcker(false);
            }
        }
        this.changeDetector.detectChanges();
    }


    private containedInMap(coordinate): boolean {
        let containedInMap = false;
        if (isNotNullOrUndefined(this.map) && isNotNullOrUndefined(this.map.getBounds())) {
            const latLng = new google.maps.LatLng(coordinate.latitude, coordinate.longitude);
            containedInMap = this.map.getBounds().contains(latLng);
        }
        return containedInMap;
    }

    public async addFilterToPlan(planId) {
        try {
            let filterLightPoint = [];
            let filterCircuiti = []
            if (!!this.windowsRowsR && this.windowsRowsR.length > 0) {
                this.windowsRowsR.forEach((puntoLuce) => {
                    if (this.containedInMap(puntoLuce.location)) {
                        filterLightPoint.push(puntoLuce.id);
                    }
                });
            }
            if (!!this.rowsR && this.rowsR.length > 0) {
                this.rowsR.forEach((puntoLuce) => {
                    if (!puntoLuce.includesInArray(this.windowsRowsR).presente) {
                        filterLightPoint.push(puntoLuce.id);
                    }
                });
            }
            if (!!this.circuiti && this.circuiti.length > 0) {
                this.circuiti.forEach((circuito) => {
                    filterCircuiti.push(circuito.id);
                });
            }
            await this.plansService.saveFilterOnPlan(planId, filterCircuiti, filterLightPoint);
            this.alertService.success(this.translate.instant('success_plan_save'));
            this.planIsSaved.emit({saved: true});
        } catch (e) {
            this.alertService.error(this.translate.instant('error') + ' ' + e.code + ' ' + e.message);
            this.planIsSaved.emit({saved: false});
        }
    }

    mapRefresh() {
        this.filterSideService.close();
        this.detailSideService.close();
        this.settingSideService.close();
        this.navService.close();
        // l info window cambia con il refresh della mappa e darebbe errore se non la chiudiamo
        this.closeInfoWindowOnClick();
        this.initMap(true, true);
    }

    initializeMarker() {
        // se vengono aggiornati i marker viene azzerato l array contente infowindow,
        //  con la reindirizzazzione gli infowindows sono diversi quindi si generebbero error
        this.closeInfoWindowOnClick();
        this.infoWindowOpened = [];
        //console.time("init marker");
        // const bounds = new google.maps.LatLngBounds();
        if (this.rowsR) {
            this.rowsR.forEach((item) => {
                // console.log("sostegno",item.get('tipologiaSostegno'),"colore",item.get('tipologiaSorgenteLuminosa'));
                // se l icona è giastata creata allora passa alla successiva, iconservice.compose crea un oggetto
                if (!isObject(item.icon)) {
                    // console.log(item.icon);
                    item.icon = this.iconService.compose(item.icon, item.get('tipologiaSorgenteLuminosa'));
                }
                // bounds.extend(this.getLocationMapped(item));
                //     console.log("icon",item.icon);

            });
        }
        if (this.circuiti) {
            this.circuiti.forEach((item) => {
                item.icon = this.iconService.iconQuadro();
                // console.log("item",item.icon);
                // bounds.extend(this.getLocationMapped(item));
            });
        }
        // if (bounds.isEmpty()) {
        //     this.map.setZoom(10);
        // } else {
        //      this.mapService.setLat(this.map.getCenter().lat());
        //     this.mapService.setLng(this.map.getCenter().lng());
        //     this.map.fitBounds(bounds);
        //     this.map.panToBounds(bounds);
        // }
        //console.timeEnd("init marker");

        this.cambiaColoreIconeDopoAggionramento(this.rowsR, this.windowsRowsR, true, null, 4);
    }

    mapClick(coordinatePlaceId) {
        // le coordinate dove piazzare i nuovi elementi
        this.coordinateNuovoElemento.next(coordinatePlaceId.coords);
        this.closeInfoWindowOnClick();
        this.filterSideService.close();
        this.detailSideService.close();
        this.settingSideService.close();
        this.navService.close();
    }


    openInfo(row) {
        if (!!this.conservaDialog) {
            this.conservaDialog.unsubscribe();
        }
        this.detail = row;
        this.detailSideService.open();
        this.filterSideService.close();
        this.settingSideService.close();
        this.navService.close();
    }

    openFilter() {
        if (!!this.conservaDialog) {
            this.conservaDialog.unsubscribe();
        }
        this.detailSideService.close();
        this.navService.close();
        this.settingSideService.close();
        this.filterSideService.toggle();
        this.destroyDrawingManager();
        this.changeDetector.detectChanges();
    }

    ngOnDestroy() {
        if (this.filterSubscription) {
            this.filterSubscription.unsubscribe();
        }
        if (!!this.istanzaSubScribeChangeProgect) {
            this.istanzaSubScribeChangeProgect.unsubscribe();
        }
        if (!!this.istanzaSubScribeGetConfiniAmministrativi) {
            this.istanzaSubScribeGetConfiniAmministrativi.unsubscribe();
        }
        if (!!this.conservaDialog) {
            this.conservaDialog.unsubscribe();
        }

        this.filterService.destroyAllFilter();
        this.filterService.destoryLocaleConfiniAmministrativi();

    }

    openMapInfo() {
        this.utilsService.lockBody();
        // la somma dei punti luce visualizzati con l' occhio
        const puntiLuceTotali = this.rowsR.concat(this.windowsRowsR);
        const a = this.dialog.open(InfoDialogComponent, {
            width: '600px',
            data: {
                puntiLuce: puntiLuceTotali,
                circuiti: this.circuiti
            }
        });
        a.afterClosed().subscribe(() => this.utilsService.unlockBody());
    }

    private openInfoWindowOnClick(infoWindow) {
        this.infoWindowOpened[0] = {};
        if (this.infoWindowOpened[1] != undefined) {
            this.infoWindowOpened[1].close();
        }
        this.infoWindowOpened[1] = infoWindow;
        this.infoWindowOpened[1].open();
    }

    private closeInfoWindowOnClick() {
        if (this.infoWindowOpened[1] != undefined) {
            this.infoWindowOpened[1].close();
            this.infoWindowOpened.splice(1, 1);
        }
    }

    onClick(item, infoWindow) {
        this.openInfoWindowOnClick(infoWindow);
        if (this.captureMarker) {
            if (this.isReport(item)) {
                this.removeItem(item);
            } else {
                this.addItem(item);
            }
        } else {
            this.openInfo(item);
        }
    }

    private closeInfoWindowOnMouseOut() {
        let haChiavi: boolean = false;
        // every tipo un foreach, return false stoppa il ciclo return true continua
        Object.keys(this.infoWindowOpened[0]).every((A) => {
            haChiavi = true;
            return false;
        });
        if (haChiavi) {
            this.infoWindowOpened[0].close();
        }
    }

    private openInfoWindowOnMouseOut(infoWindow) {
        if (this.infoWindowOpened[1] != undefined) {
            if (infoWindow._id != this.infoWindowOpened[1]._id) {
                this.infoWindowOpened[0] = infoWindow;
                this.infoWindowOpened[0].open();
            }
        } else {
            this.infoWindowOpened[0] = infoWindow;
            this.infoWindowOpened[0].open();
        }
    }

    public onMouseOut() {
        this.closeInfoWindowOnMouseOut();
    }

    public onMouseOver(infoWindow) {
        this.openInfoWindowOnMouseOut(infoWindow);
    }

    addItem(item) {
        this.reports.push(item.id);
        this.filterService.setReport(this.reports);
    }

    addReports() {
        this.filterService.setReport(this.reports);
    }

    removeItem(item) {
        this.reports.splice(this.reports.indexOf(item.id), 1);
        this.filterService.setReport(this.reports);
    }

    isReport(item) {
        this.reports = this.filterService.getReport();
        return this.reports.includes(item.id);
    }

    setOpacity(m) {
        const res = Object.assign(m);
        if (this.filterService.getReport().length > 0) {
            if (this.filterService.getReport().includes(res.id)) {
                return .8;
            } else {
                return .2;
            }
        } else {
            return .8;
        }
    }


    //ritorna true se non presente in puntiLuceDaFiltri altrimenti false
    private puntoLuceNonPresente(puntiLuceDaFiltri: { id }[], idpuntiLuceDaFinestra: string): boolean {
        // some se incontra true interrompe il ciclo e ritorna true se il ciclo si conslude torna false
        return !puntiLuceDaFiltri.some(puntoLuce => puntoLuce.id == idpuntiLuceDaFinestra);
    }


    // in base alla mappa visualizzata vengono caricati i puntiluce
    private loadPuntiLuceFinestra() {
        const loaderId = this.loaderService.addLoader();
        const ingresso: scalaColoreNomeTabella = {
            scalaColore: this.scaleColor.getelementiScala(),
            tabellaImpostataScala: this.scaleColor.elementoScalaAttuale
        };
        this.mapService.getPuntiLuceTramiteCoordinate(this.map.getBounds()).subscribe((puntiLuceFinestra) => {
            puntiLuceFinestra.forEach((puntoLuceFinestra) => {
                if (this.puntoLuceNonPresente(this.rowsR, puntoLuceFinestra.id)) {
                    puntoLuceFinestra.icon = this.iconService.compose(puntoLuceFinestra.icon, puntoLuceFinestra.tipologiaSorgenteLuminosa);
                    puntoLuceFinestra.icon = this.calcColorIconVector(puntoLuceFinestra, ingresso);
                    this.windowsRowsR.push(puntoLuceFinestra);
                }
            });
            this.cambiaColoreIconeDopoAggionramento(this.rowsR, this.windowsRowsR, true, null, 5);
            this.vettoreDeiCluster = this.creaClusterWithColor(this.rowsR, this.windowsRowsR);
            this.disabledEyeButton = !this.disabledEyeButton;
            this.loaderService.removeLoader(loaderId);
            this.changeDetector.detectChanges();
        });
    }

    //
    public visualizedLightPointInMapWindow() {
        this.windowsRowsR = [];
        this.disabledEyeButton = !this.disabledEyeButton;
        this.changeDetector.detectChanges();
        if (!this.clickedOpenEyeButton) {
            this.clickedOpenEyeButton = !this.clickedOpenEyeButton;
        }
        this.loadPuntiLuceFinestra();
    }

    // se non sono presenti punti luce nel vettore dei puntiluce il bottone viene disattivato
    public get disabledHiddenEyeButton(): boolean {
        return !(isNotNullOrUndefined(this.windowsRowsR) && this.windowsRowsR.length > 0);
    }

    public async notvisualizedLightPointInMapWindow() {
        this.clickedOpenEyeButton = !this.clickedOpenEyeButton;
        this.vettoreDeiCluster.forEach((puntiluce) => puntiluce.daOcchio = []);
        this.windowsRowsR = [];
        this.cambiaColoreIconeDopoAggionramento(this.rowsR, this.windowsRowsR, true, null, 6);
        this.changeDetector.detectChanges();
        // console.log(this.presentObjectId(this.rowsR, this.objectIdLIghtPointEyeVisualized));
    }

    onCaptureMarker() {
        /**
         * viene abilitato il tasto dei group
         * viene abilitato il tasto dei report
         * viengono resi non spostabili i marker
         * viene reso invisibile il tasto undo
         */

        if (this.gestore) {
            this.disableGroupReportsButton = false;
            //console.time("on capture marker");
            this.captureMarker = true;
            const options = {
                drawingMode: google.maps.drawing.OverlayType.POLYGON,
                drawingControl: true,
                drawingControlOptions: {
                    position: (window.innerWidth > 600) ? google.maps.ControlPosition.BOTTOM_RIGHT : google.maps.ControlPosition.RIGHT_CENTER,
                    drawingModes: ['polygon', 'circle', 'rectangle']
                },
                polygonOptions: {
                    editable: true
                },
                rectangleOptions: {
                    editable: true
                },
                circleOptions: {
                    editable: true
                }
            };
            this.drawingManager = new google.maps.drawing.DrawingManager(options);
            this.drawingManager.setMap(this.map);
            this.overLayCompleteListener = google.maps.event.addListener(this.drawingManager, 'overlaycomplete', (event) => {
                    this.event.push(event);
                }
            );
            google.maps.event.addListener(this.drawingManager, 'polygoncomplete', (polygon) => {
                this.polygons.push(polygon);
                this.confirmSelected();
                google.maps.event.addListener(polygon.getPath(), 'set_at', () => {
                    this.confirmSelected();
                });
            });

            google.maps.event.addListener(this.drawingManager, 'circlecomplete', (circle) => {
                this.circles.push(circle);
                this.confirmSelected();
                google.maps.event.addListener(circle, 'radius_changed', () => {
                    if (this.visible) {
                        this.confirmSelected();
                    }
                });
            });

            google.maps.event.addListener(this.drawingManager, 'rectanglecomplete', (rectangle) => {
                this.rectangles.push(rectangle);
                this.confirmSelected();
                google.maps.event.addListener(rectangle, 'bounds_changed', () => {
                    if (this.visible) {
                        this.confirmSelected();
                    }
                });
            });
        } else {
            const erroreGenerato: string = this.translateService.instant('dashboard.alert.permissionDenied');
            this.alertService.error(erroreGenerato);
        }
        //console.timeEnd("on capture marker");

    }


    get isSetInterdistance() {
        return !this.interdistanceOff && isNotNullOrUndefined(this.interdistances) && this.interdistances.length > 0
    }

    disableInterdistance() {
        this.interdistanceOff = true;
        this.disableMoveButton = false;
        this.polyLineInfoWindow = [];
        this.interdistances = [];
        this.interdistanceDetail = undefined;
    }

    public midPoint(SW, NE) {
        const locSw = new google.maps.LatLng(SW.latitude, SW.longitude);
        const locNe = new google.maps.LatLng(NE.latitude, NE.longitude);
        const bounds = new google.maps.LatLngBounds();
        bounds.extend(locSw);
        bounds.extend(locNe);
        return bounds.getCenter();
    }

    onEndCaptureMarker(type: 'reporting' | 'groups' | 'interdistance') {
        this.confirmSelected();
        let a;
        const objectIdLightPoints = this.filterService.getReport();
        if (objectIdLightPoints.length > 0) {
            switch (type) {
                case 'reporting':
                    this.utilsService.lockBody();
                    a = this.dialog.open(CreateReportComponent);
                    a.afterClosed().subscribe(() => this.utilsService.unlockBody());
                    break;
                case 'groups':
                    this.utilsService.lockBody();
                    a = this.dialog.open(CreateGroupComponent);
                    a.afterClosed().subscribe(() => this.utilsService.unlockBody());
                    break;
                case 'interdistance':
                    this.interdistanceOff = false;
                    if (objectIdLightPoints.length > 1) {
                        this.getInterdistance(objectIdLightPoints).subscribe((distances) => {
                                this.polyLineInfoWindow = [];
                                this.interdistances = [];
                                this.interdistanceDetail = undefined;
                                this.disableMoveButton = true;
                                this.linearScaleService.generateScale(4, true)
                                this.linearScaleService.setMinMaxValue(distances.interdistanzaMin, distances.interdistanzaMax, 100);
                                this.elementiScalaLux = this.linearScaleService.ElementiScala;
                                this.luxmin = Math.round(this.linearScaleService.keyMin);
                                this.luxmax = Math.round(this.linearScaleService.keyMax);
                                this.interdistanceDetail = {
                                    average: Math.round(distances.interdistanza),
                                    total: Math.round(distances.distanzaTotale),
                                    maximun: Math.round(distances.interdistanzaMax),
                                    minimum: Math.round(distances.interdistanzaMin)
                                };
                                const forZoom = [];
                                distances.path.forEach((distance, index) => {
                                    const start = distance[0];
                                    const end = distance[1];
                                    forZoom.push(start);
                                    forZoom.push(end);
                                    const newInterdistance: InterdistanceLightPoint = {
                                        objectId: start.objectId + end.objectId,
                                        color: this.getPrendiColoreInbaseAlValore(Math.round(distance[2])),
                                        start: start.location,
                                        end: end.location,
                                        distance: Math.round(distance[2])
                                    };
                                    const isPresent = this.interdistances.findIndex((interdistanza) =>
                                        interdistanza.objectId.includes(start.objectId) && interdistanza.objectId.includes(end.objectId)
                                    );
                                    if (isPresent < 0) {
                                        const middlePoint = this.midPoint(start.location, end.location);
                                        this.interdistances.push(newInterdistance);
                                        this.polyLineInfoWindow.push({
                                            objectId: start.objectId + end.objectId,
                                            lat: middlePoint.lat(),
                                            lng: middlePoint.lng(),
                                            persistent: true
                                        });
                                    }
                                });
                                this.setCenterZoom(forZoom);
                            },
                            error => {
                                this.alertService.error(error.message);
                            });
                    } else {
                        this.alertService.warning(this.translateService.instant('dashboard.alert.atLeastTwo'));
                    }

                    // console.log("start-1", this.interdistances[1].end.latitude, this.interdistances[1].end.longitude);
                    // console.log("end-1", this.interdistances[1].end.latitude, this.interdistances[1].end.longitude);
                    break;
            }
            this.buttonComponentRef.isVisible = false;
            this.captureMarker = false;
            this.destroyDrawingManager();
        } else {
            switch (type) {
                case 'reporting':
                    this.utilsService.lockBody();
                    a = this.dialog.open(InfoReportComponent);
                    a.afterClosed().subscribe(() => this.utilsService.unlockBody());
                    break;
                case 'groups':
                    this.utilsService.lockBody();
                    a = this.dialog.open(InfoGroupComponent);
                    a.afterClosed().subscribe(() => this.utilsService.unlockBody());
                    break;
                case 'interdistance':
                    this.alertService.warning(this.translateService.instant('dashboard.alert.notLightPoints'));
                    break;
            }
        }
        // }
        //console.timeEnd("on end capture marker");

    }

    confirmSelected() {
        if (this.event.length != 0) {
            this.event.forEach(async eventElement => {
                let funct;
                let secondParam;
                switch (eventElement.type) {
                    case google.maps.drawing.OverlayType.POLYGON:
                        funct = this.checkPointsPolygon;
                        secondParam = new google.maps.Polygon(eventElement.overlay);
                        break;
                    case google.maps.drawing.OverlayType.CIRCLE:
                        funct = this.checkPointsCircle;
                        secondParam = eventElement.overlay;
                        break;
                    case google.maps.drawing.OverlayType.RECTANGLE:
                        funct = this.checkPointsRectangle;
                        secondParam = eventElement.overlay;
                        break;
                    default:
                }
                this.rowsR.concat(this.windowsRowsR).forEach(el => {
                    if (funct(el, secondParam)) {
                        if (!this.isReport(el)) {
                            this.addItem(el);
                        }
                    }
                });
            });
        }
        this.changeDetector.detectChanges();
    }

    /**
     * viengono resi non spostabili i marker
     * viene reso invisibile il tasto undo
     */
    public destroyDrawingManager() {
        //viene impostato di default il cursore
        this.map.setOptions({draggableCursor: ''});
        if (!!this.conservaDialog) {
            this.conservaDialog.unsubscribe();
        }

        if (!!this.drawingManager) {
            this.visible = false;
            this.filterService.setReport([]);
            this.drawingManager.setMap(null);
            this.polygons.forEach(el => el.setPath([]));
            this.rectangles.forEach(el => el.setBounds(null));
            this.circles.forEach(el => {
                el.setRadius(null);
                el.setCenter(null);
            });
            this.event.forEach(el => el.overlay.setMap(null));
            this.event = [];
            this.polygons = [];
            this.circles = [];
            this.rectangles = [];
            this.groupsRows = [];
            this.groupsCircuiti = [];
            this.drawingManager = undefined;
            this.overLayCompleteListener.remove();
            this.captureMarker = false;
        }
    }

    private getLocationMapped = ({location}) => ({lat: Number(location.latitude), lng: Number(location.longitude)});

    private checkPointsCircle: (element: any, event: any) => boolean = (element, event) => {
        const item = this.getLocationMapped(element);
        const latLng = new google.maps.LatLng(item.lat, item.lng);
        return (
            event.getBounds().contains(latLng) &&
            google.maps.geometry.spherical.computeDistanceBetween(event.getCenter(), latLng) <= event.getRadius()
        );
    };

    private checkPointsPolygon: (item: any, event: any) => boolean = (element, event) => {
        const item = this.getLocationMapped(element);
        const latLng = new google.maps.LatLng(item.lat, item.lng);
        return google.maps.geometry.poly.containsLocation(latLng, event);
    };

    private checkPointsRectangle: (item: any, event: any) => boolean = (element, event) => {
        const item = this.getLocationMapped(element);
        const latLng = new google.maps.LatLng(item.lat, item.lng);
        return event.getBounds().contains(latLng);
    };

    private addMarkerCircuiti(el): void {
        try {
            this.groupsCircuiti.forEach(circuit => {
                if (el.id === circuit.id) {
                    throw new BreakException();
                }
            });
            this.groupsCircuiti.push(el);
        } catch (e) {
            if (!(e instanceof BreakException)) {
                throw e;
            }
        }
    }

    private addMarkerRows(el): void {
        try {
            this.groupsRows.forEach(row => {
                if (el.id === row.id) {
                    throw new BreakException();
                }
            });
            this.groupsRows.push(el);
        } catch (e) {
            if (!(e instanceof BreakException)) {
                throw e;
            }
        }
    }

    // richiede in maniera ricorsiva i punti luce, elementiPerPagina sono il numero di elementi deve tornare,
    // paginaDiinizio imposta la prima sezione dei puti luce da andare a prendere di defautl pesca tutto.
    private async paginazionePuntiLuce(rows: any[], filtri: any[], elementiPerPagina: number, paginaDiinizio: number = 0) {
        this.changeDetector.markForCheck();
        const elementiTrovati = (await this.mapService.getPuntiLuce(elementiPerPagina, paginaDiinizio, {
            filters: this.filterService.getLocaleFilter(),
            lightPoints: filtri
        }));


        // si estrapola il numero degli elementi presi dalla query
        const numeroElementiTrovati = elementiTrovati.length;
        elementiTrovati.forEach((puntoLuce) => {
            if (!puntoLuce.includesInArray(this.windowsRowsR).presente) {
                rows.push(puntoLuce);
            }
        });
        // se il numero di elementi trovati e minore del numero per pagina da estrapolare
        // vuol dire la query ha restituito gli ultimi valori
        // altrimenti richiediamo la pagina successiva
        if (numeroElementiTrovati < elementiPerPagina) {
            // console.log("fine lung", numeroElementiTrovati);
            // console.log("rows", rows);
            return 1;
        } else {
            // console.log("ricorsione lung", lunghezza);
            return this.paginazionePuntiLuce(rows, filtri, elementiPerPagina, paginaDiinizio + 1);
        }

    }


    // effetua una query solamente se la lunghezza del nuovo filtro e maggiore del vecchio filtro,
    // la query verrà effettuata solo sulla differenza tra i filtri
    // altrimenti cancella gli elementi del vettore non presenti nei fitlri
    private async loadPuntiLucePaginataConDifferenzaSuiFiltri(newFilterLightPoint, oldFilterLightPoint, newAdvancedFilter, oldAdvancedFilter, loadPuntiluce: boolean) {
        const elementiPerPagina = 500;

        const alterAdvancedFilter: boolean = !(JSON.stringify(newAdvancedFilter) == JSON.stringify(oldAdvancedFilter));//true se i filtri avanzati sono modificati

        this.loading = false;
        const oldFilerLength = (oldFilterLightPoint == null || oldFilterLightPoint == undefined) ? -1 : oldFilterLightPoint.length;
        const newFilterMaggioreDiOld: boolean = (isNotNullOrUndefined(newFilterLightPoint) && newFilterLightPoint.length > 0) ? newFilterLightPoint.length > oldFilerLength : false;
        const newFilterUgualeZero: boolean = (newFilterLightPoint != null) ? newFilterLightPoint.length == 0 : true;


        // console.time("load");
        // caso A cambiano i filtri semplici effetua una nuova query al database solo con la differenza dei filtri
        // caso B svuotiamo il vettore in caso di deselezionatutto
        // caso C cambiano soli i filtri avanzati effetua una nuova query su tutto il database
        // caso D cambiano i filtri semplice non facciamo la chiamata ma filtriamo il vettore
        if (newFilterMaggioreDiOld) {
            /*A*/
            const differenzaTraFiltroFuturoPassato = this.filterService.getDifferenceFilter(newFilterLightPoint, oldFilterLightPoint);
            // necessario per il tracby altrimenti non avviene l aggiornamento in maniera live su html
            await this.paginazionePuntiLuce(this.rowsR, differenzaTraFiltroFuturoPassato, elementiPerPagina);
            // console.log(this.rowsR);
            // ripristinato come gia presente nel codice
        } else if (newFilterUgualeZero) {
            /*B*/
            this.rowsR = [];
        } else if (alterAdvancedFilter || loadPuntiluce) {
            /*C*/
            this.rowsR.splice(0, this.rowsR.length);
            await this.paginazionePuntiLuce(this.rowsR, newFilterLightPoint, elementiPerPagina);
            // ripristinato come gia presente nel codice
        } else {
            /*D*/
            this.rowsR = this.rowsR.filter((puntoLuce) => {
                //nel caso di punti luce senza id
                if (puntoLuce.circuito != undefined) {
                    return (newFilterLightPoint.includes(puntoLuce.circuito.id));
                } else {
                    return newFilterLightPoint.includes(chiaviFiltri.nessunQuadro);
                }
            });
            // this.cancellazioneTotaleRicorsivo(this.rowsR,100);
        }
        this.loading = true;

        // this.cambiaColoreIconeDopoAggionramento(this.rowsR, this.windowsRowsR, false, 7);
        //console.timeEnd("load")
    }

    // per la reinderizzare solo i punti luce modificati
    public trackRows(index: number, m): string {
        //console.log("tb",m.id);
        return m.id;
    }


    /**
     * viene abilitato lo spostamento dei punti luce
     * viene azzerato il vettore contenente le posizioni originali dei punti luce
     * viene evidenziato il tasto di spostamento
     * viene disabilitato il tasto torna undo
     * viene disabiltiato il tasto group
     * viene diabilitato il tasto report
     * vengono resi spostabili i marker
     */
    public abilitaSpostaPuntiLuce(): void {
        this.posizioniIniziliaMarker = [];
        this.disableUndoButton = !this.posizioniIniziliaMarker.length;
        if (this.disableGroupReportsButton) {
            this.map.setOptions({draggableCursor: ''});
            this.disableAddButton = !this.disableAddButton;
            this.markerSpostabile = false;
            this.visibleUndoButton = false;
            this.disableMoveButton = false;
            this.disableAddButton = false;
            this.disableGroupReportsButton = false;
        } else {
            this.markerSpostabile = false;
            this.visibleUndoButton = false;
            this.markerSpostabile = !this.markerSpostabile;
            this.visibleUndoButton = true;
            this.disableGroupReportsButton = !this.disableGroupReportsButton;
            this.disableAddButton = !this.disableAddButton;
        }
    }

    private updateLocationAddress(coordinate, nomeClasse, idPuntoLuce, indirizzo, idQuadro) {
        if (nomeClasse == className.puntiLuce) {
            forkJoin([this.mapService.updateLocationPuntiLuce(coordinate, idPuntoLuce),
                this.mapService.updateAddressPuntiLuce(coordinate, idPuntoLuce, indirizzo)]
            ).subscribe(() => {
                const message: string = this.translateService.instant('dashboard.alert.riposizionato.success');
                this.sostituisciElementoDelVettore({
                    id: idPuntoLuce,
                    filtroCircuito: idQuadro,
                    classe: nomeClasse
                });
                this.alertService.success(message);
            }, (error) => {
                const erroreGenerato: string = error.message;
                const message = this.translateService.instant('dashboard.alert.riposizionato.error');
                this.alertService.error(erroreGenerato);
                console.log(error);
            });
        } else if (nomeClasse == className.circuiti) {
            forkJoin([this.mapService.updateLocationCircuiti(coordinate, idPuntoLuce),
                this.mapService.updateAddressCircuiti(coordinate, idPuntoLuce, indirizzo)]
            ).subscribe(() => {
                const message: string = this.translateService.instant('dashboard.alert.riposizionato.success');
                this.sostituisciElementoDelVettore({
                    id: idPuntoLuce,
                    filtroCircuito: idQuadro,
                    classe: nomeClasse
                });
                this.alertService.success(message);
            }, (error) => {
                const erroreGenerato: string = error.message;
                const message = this.translateService.instant('dashboard.alert.riposizionato.error');
                this.alertService.error(erroreGenerato);
                console.log(error);
            });
        } else if (nomeClasse == className.puntiStampa) {
            this.mapService.updateLocationPuntiCamera(coordinate, idPuntoLuce).subscribe(() => {
                const message: string = this.translateService.instant('dashboard.alert.riposizionato.success');
                this.alertService.success(message);
            }, (error) => {
                const erroreGenerato: string = error.message;
                const message = this.translateService.instant('dashboard.alert.riposizionato.error');
                this.alertService.error(erroreGenerato);
                console.log(error);
            });
        }
    }

    /**viene chiamata la funzione di aggiormanento */
    public spostaPuntoLuce(coordinate, idPuntoLuce, className, idQuadro): void {
        this.detailSideService.close();
        this.settingSideService.close();
        this.updateLocationAddress(coordinate.coords, className, idPuntoLuce, null, idQuadro);
    }

    /*vengono consevate le posizioni iniziali dei punti luce
    viene abilitato il tasto di torna indietro in caso ci sono
    elementi nell array delle posizioni originali
    */
    public conservaPosizioneIniziale(coordinate, idPuntoLuce, indirizzo, nomeClasse, idQuadro, provenienza: 'puntiLuceFiltri' | 'puntiLuceOcchio' | 'quadriOcchio' | 'quadriFiltri'): void {
        let nonPresente: boolean = !this.posizioniIniziliaMarker.some((item) => {
            return item.id == idPuntoLuce;
        });
        if (nonPresente) {
            this.posizioniIniziliaMarker.push({
                id: idPuntoLuce,
                latitudine: coordinate.coords.lat,
                longitudine: coordinate.coords.lng,
                indirizzo: indirizzo,
                provenienza: provenienza,
                nomeClasse: nomeClasse
            });
        }
        this.disableUndoButton = !this.posizioniIniziliaMarker.length;
    }

    //sostituisce le coordinate dei vettori aggiornati con le coordinate nelle posizioni iniziali
    private aggiornaConPosizioniIniziali(puntiLuce: any[], posizioniIniziliaMarker): [any[], []] {
        let coordinate: { lat, lng };
        let indirizzo;
        puntiLuce.every((puntoLuce, indiceRows) => {
            if (puntoLuce.id == this.posizioniIniziliaMarker[posizioniIniziliaMarker.length - 1].id) {
                coordinate = {
                    lat: this.posizioniIniziliaMarker[posizioniIniziliaMarker.length - 1].latitudine,
                    lng: this.posizioniIniziliaMarker[posizioniIniziliaMarker.length - 1].longitudine
                };
                indirizzo = this.posizioniIniziliaMarker[posizioniIniziliaMarker.length - 1].indirizzo;
                const idQuadro = (puntoLuce.className == className.puntiLuce) ?
                    (puntoLuce.circuito) ? puntoLuce.circuito.id : chiaviFiltri.nessunQuadro :
                    null;
                this.updateLocationAddress(coordinate, puntoLuce.className, puntoLuce.id, indirizzo, idQuadro);
                puntiLuce[indiceRows].location.latitude = this.posizioniIniziliaMarker[posizioniIniziliaMarker.length - 1].latitudine;
                puntiLuce[indiceRows].location.longitude = this.posizioniIniziliaMarker[posizioniIniziliaMarker.length - 1].longitudine;
                this.posizioniIniziliaMarker.splice(posizioniIniziliaMarker.length - 1, 1);
                return false;
            } else {
                return true;
            }
        });
        return [puntiLuce, posizioniIniziliaMarker];
    }

    /*aggiorna nel vettore dei punti luce,puntistampa e circuiti  le posizioni rimpiazzandole con le originali
     */
    public ripristinaSpostaPuntiLuceQuadri() {
        let provenienza = this.posizioniIniziliaMarker[this.posizioniIniziliaMarker.length - 1].provenienza;
        if (provenienza == 'puntiLuceOcchio') {
            const vettoriAggiornati = this.aggiornaConPosizioniIniziali(this.windowsRowsR, this.posizioniIniziliaMarker);
            this.windowsRowsR = vettoriAggiornati[0];
            this.posizioniIniziliaMarker = vettoriAggiornati[1];
        } else if (provenienza == 'puntiLuceFiltri') {
            //si occupa di riposizionare i punti luce alle posizioni originali
            const vettoriAggiornati = this.aggiornaConPosizioniIniziali(this.rowsR, this.posizioniIniziliaMarker);
            this.rowsR = vettoriAggiornati[0];
            this.posizioniIniziliaMarker = vettoriAggiornati[1];
        } else if (provenienza == 'quadriFiltri') {
            const vettoriAggiornati = this.aggiornaConPosizioniIniziali(this.circuiti, this.posizioniIniziliaMarker);
            this.circuiti = vettoriAggiornati[0];
            this.posizioniIniziliaMarker = vettoriAggiornati[1];
        } else if (provenienza == 'puntiStampaFiltri') {
            const vettoriAggiornati = this.aggiornaConPosizioniIniziali(this.puntiStampa, this.posizioniIniziliaMarker);
            this.puntiStampa = vettoriAggiornati[0];
            this.posizioniIniziliaMarker = vettoriAggiornati[1];
        }
        this.disableUndoButton = !this.posizioniIniziliaMarker.length;
    }

    //migliorabili con gli switchmap
    private createNewElementInDatabase(classeElementoDacreare, cooridinateDoveCrearlo) {
        const classeElementoDaCreareNonArray = (Array.isArray(classeElementoDacreare)) ? classeElementoDacreare[0] : classeElementoDacreare;
        if (classeElementoDaCreareNonArray == className.puntiLuce) {
            this.mapService.createNewPuntoLuce(cooridinateDoveCrearlo).subscribe((puntoLuce) => {
                puntoLuce.icon = this.iconService.compose('Light Bulb 32x32', 'null');
                this.rowsR.push(puntoLuce);
                this.vettoreDeiCluster = this.creaClusterWithColor(this.rowsR, this.windowsRowsR);
                this.changeDetector.detectChanges();
            }, (error) => {
                console.log(error);
                const erroreGenerato: string = error.message;
                this.alertService.error(erroreGenerato);
            });
        } else if (classeElementoDaCreareNonArray == className.circuiti) {
            let numeroMax = 0;
            this.circuitiAppartenentiAlProgettoExport.forEach(element => {
                if (classeElementoDacreare[1] == element.formHtml.slice(0, 2) && numeroMax < Number.parseInt(element.formHtml.slice(2))) {
                    numeroMax = Number.parseInt(element.formHtml.slice(2));
                }
            });
            const numeroMaxString = classeElementoDacreare[1] + (numeroMax + 1).toString().padStart(3, '0');
            let tabellaDaRecuperare;
            this.circuitiAppartenentiAlProgettoExport = [];

            // console.log("elemento da crare", classeElementoDacreare[1], numeroMax,numeroMaxString);
            this.mapService.createNewCircuito(cooridinateDoveCrearlo, numeroMaxString).subscribe((quadro) => {
                this.circuiti.push(quadro);
                this.changeDetector.detectChanges();
                this.mapService.getCircuiti().subscribe((ArrayPuntiLuce) => {
                        tabellaDaRecuperare = this.elementiclasse.PuntiLuce.circuito.possibleValues;
                        tabellaDaRecuperare = tabellaDaRecuperare.split('.')[tabellaDaRecuperare.split('.').length - 1];
                        // console.log(this.quadriPresentiNelProgetto);
                        ArrayPuntiLuce.forEach((element) => {
                            this.circuitiAppartenentiAlProgettoExport.push({
                                formValues: element.id,
                                formHtml: element[tabellaDaRecuperare]
                            });
                        });
                        this.changeDetector.detectChanges();
                    }
                );

            }, (error) => {
                console.log(error);
                const erroreGenerato: string = error.message;
                this.alertService.error(erroreGenerato);
            });
        } else if (classeElementoDaCreareNonArray == className.puntiStampa) {
            this.mapService.createNewPuntiCamera(cooridinateDoveCrearlo).subscribe((puntiCamera) => {
                this.puntiStampa.push(puntiCamera);
                this.changeDetector.detectChanges();
            }, (error) => {
                console.log(error);
                const erroreGenerato: string = error.message;
                this.alertService.error(erroreGenerato);
            });
        }
    }

    public addPuntoLuceCircuito() {
        if (this.disableMoveButton) {
            this.disableMoveButton = false;

            this.disableGroupReportsButton = false;
            this.map.setOptions({draggableCursor: ''});
        } else {
            this.map.setOptions({draggableCursor: 'url(/assets/icon/dashboard/picket24.svg), auto'});
            this.disableMoveButton = !this.disableMoveButton;
            this.markerSpostabile = !this.markerSpostabile;
            this.disableGroupReportsButton = !this.disableGroupReportsButton;
            let numeriQuadro = this.circuitiAppartenentiAlProgettoExport.map((a) => a.formHtml);
            numeriQuadro.push('AA');
            numeriQuadro.push('BB');
            this.conservaDialog = this.myDialog.openDialogForNewElement(this.translateService.instant('dashboard.newElementAsk'), numeriQuadro, null).pipe(
                switchMap((elementoDaCreare) => {
                        if (!elementoDaCreare) {
                            this.addPuntoLuceCircuito();
                            this.changeDetector.detectChanges();
                        }
                        return this.coordinateNuovoElemento.asObservable().pipe(
                            map((coordinate) => {
                                return {elementoDaCreare, coordinate};
                            })
                        );
                    }
                )
            ).subscribe(({elementoDaCreare, coordinate}) => {
                this.createNewElementInDatabase(elementoDaCreare, coordinate);
            });
        }
    }

    // viene aperto la sidenav della scala
    public settings() {
        this.closeInfoWindowOnClick();
        this.filterSideService.close();
        this.detailSideService.close();
        this.scaleColor.ElementiVisualizzatiInMappa = this.rowsR.concat(this.windowsRowsR);
        this.modificaAvvenutaPerSetting = !this.modificaAvvenutaPerSetting;
        this.settingSideService.open();
        this.changeDetector.detectChanges();
    }

    public calcColorIconVector(puntoLuce: PuntiLuceParse, ingresso: scalaColoreNomeTabella): object {
        const tipoDato = (ingresso.tabellaImpostataScala === 'lampadaFunzionante') ? 'bool' : ordinamentoEcampiTraduzioni.PuntiLuce[ingresso.tabellaImpostataScala].type.toLowerCase();
        let possibleValuesNonEarray: boolean = false;
        if (ingresso.tabellaImpostataScala !== 'lampadaFunzionante' && !!ordinamentoEcampiTraduzioni.PuntiLuce[ingresso.tabellaImpostataScala].possibleValues && !Array.isArray(ordinamentoEcampiTraduzioni.PuntiLuce[ingresso.tabellaImpostataScala].possibleValues)) {
            possibleValuesNonEarray = true;
        }
        let icon: object;
        if (tipoDato == 'bool') {
            let nomeElemento;
            if (!isNotNullOrUndefined(puntoLuce[ingresso.tabellaImpostataScala])) {
                nomeElemento = 'undefined';
            } else {
                nomeElemento = (puntoLuce[ingresso.tabellaImpostataScala]) ? 'true' : 'false';
            }
            icon = this.iconService.changeColor(puntoLuce.icon.path, puntoLuce.icon.anchor, ingresso.scalaColore[nomeElemento]);
        } else if (typeof puntoLuce[ingresso.tabellaImpostataScala] == 'string') {
            const nomeElemento = (puntoLuce[ingresso.tabellaImpostataScala] != undefined) ? puntoLuce[ingresso.tabellaImpostataScala].replace(/ /g, '_') : puntoLuce[ingresso.tabellaImpostataScala];
            icon = this.iconService.changeColor(puntoLuce.icon.path, puntoLuce.icon.anchor, ingresso.scalaColore[nomeElemento]);
        } else if (tipoDato == 'date') {
            const dataMs = (puntoLuce[ingresso.tabellaImpostataScala] != undefined) ? new Date(this.datePipe.transform(puntoLuce[ingresso.tabellaImpostataScala], 'yyyy-MM-dd')).getTime() : puntoLuce[ingresso.tabellaImpostataScala];
            const nomeElemento = puntoLuce[ingresso.tabellaImpostataScala];
            icon = this.iconService.changeColor(puntoLuce.icon.path, puntoLuce.icon.anchor, ingresso.scalaColore[dataMs]);
        } else {
            if (possibleValuesNonEarray) {
                const possibleValue = ordinamentoEcampiTraduzioni.PuntiLuce[ingresso.tabellaImpostataScala].possibleValues.split('.')[1];
                const nomeElemento = (puntoLuce[ingresso.tabellaImpostataScala] == undefined) ? puntoLuce[ingresso.tabellaImpostataScala] : puntoLuce[ingresso.tabellaImpostataScala][possibleValue].replace(/ /g, '_');
                icon = this.iconService.changeColor(puntoLuce.icon.path, puntoLuce.icon.anchor, ingresso.scalaColore[nomeElemento]);
            } else {
                const nomeElemento = puntoLuce[ingresso.tabellaImpostataScala];
                icon = this.iconService.changeColor(puntoLuce.icon.path, puntoLuce.icon.anchor, ingresso.scalaColore[nomeElemento]);
            }
        }
        return icon;
    }

    private updateColorArrayLightPoint(ingresso: scalaColoreNomeTabella) {
        this.rowsR.forEach((puntoLuce) => {
            if (isObject(puntoLuce.icon)) {
                puntoLuce.icon = this.calcColorIconVector(puntoLuce, ingresso);
            } else {
                puntoLuce.icon = this.iconService.compose(puntoLuce.icon, puntoLuce.tipologiaSorgenteLuminosa);
                puntoLuce.icon = this.calcColorIconVector(puntoLuce, ingresso);
            }
        });
        this.windowsRowsR.forEach((puntoLuce) => {
            if (isObject(puntoLuce.icon)) {
                puntoLuce.icon = this.calcColorIconVector(puntoLuce, ingresso);
            } else {
                puntoLuce.icon = this.iconService.compose(puntoLuce.icon, puntoLuce.tipologiaSorgenteLuminosa);
                puntoLuce.icon = this.calcColorIconVector(puntoLuce, ingresso);
            }
        });
    }

    public cambiaColoreDelleIcone(ingresso: scalaColoreNomeTabella) {
        const rowsGreatherThanZero = (Array.isArray(this.rowsR) && this.rowsR.length > 0);
        const windowsRowsRGreatherThanZero = (Array.isArray(this.windowsRowsR) && this.windowsRowsR.length > 0);
        if (ingresso.tabellaImpostataScala === 'lampadaFunzionante' && (rowsGreatherThanZero || windowsRowsRGreatherThanZero)) {
            const loaderId = this.loaderService.addLoader();
            const segnaliDivisiPuntiLuce = [];
            if (rowsGreatherThanZero) {
                segnaliDivisiPuntiLuce.push(this.segnaliTlcNodoService.getLampadaFunzionante(this.rowsR));
            }
            if (windowsRowsRGreatherThanZero) {
                segnaliDivisiPuntiLuce.push(this.segnaliTlcNodoService.getLampadaFunzionante(this.windowsRowsR));
            }
            forkJoin(segnaliDivisiPuntiLuce)
                .subscribe((segnali) => {
                        if (rowsGreatherThanZero) {
                            const segnale = segnali[0] as SegnaliTlcNodoParse[];
                            segnale.forEach(segnale => {
                                const index = this.rowsR.findIndex(puntoLuce => puntoLuce.objectId == segnale.puntoLuce.objectId);
                                if (index > -1) {
                                    this.rowsR[index].lampadaFunzionante = segnale.lampadaFunzionante;
                                }
                            });
                        }
                        if (!rowsGreatherThanZero && windowsRowsRGreatherThanZero) {
                            const segnale = segnali[0] as SegnaliTlcNodoParse[];
                            segnale.forEach(segnale => {
                                const index = this.windowsRowsR.findIndex(puntoLuce => puntoLuce.objectId == segnale.puntoLuce.objectId);
                                if (index > -1) {
                                    this.windowsRowsR[index].lampadaFunzionante = segnale.lampadaFunzionante;
                                }
                            });
                        } else if (windowsRowsRGreatherThanZero) {
                            const segnale = segnali[1] as SegnaliTlcNodoParse[];
                            segnale.forEach(segnale => {
                                const index = this.windowsRowsR.findIndex(puntoLuce => puntoLuce.objectId = segnale.puntoLuce.objectId);
                                if (index > -1) {
                                    this.windowsRowsR[index].lampadaFunzionante = segnale.lampadaFunzionante;
                                }
                            });
                        }
                        this.updateColorArrayLightPoint(ingresso);
                        this.loaderService.removeLoader(loaderId);
                        this.vettoreDeiCluster = this.creaClusterWithColor(this.rowsR, this.windowsRowsR);
                        this.changeDetector.detectChanges();
                    },
                    error => {
                        this.updateColorArrayLightPoint(ingresso);
                        this.loaderService.removeLoader(loaderId);
                        this.vettoreDeiCluster = this.creaClusterWithColor(this.rowsR, this.windowsRowsR);
                        this.changeDetector.detectChanges();
                    });
        } else {
            this.updateColorArrayLightPoint(ingresso);
            this.vettoreDeiCluster = this.creaClusterWithColor(this.rowsR, this.windowsRowsR);
        }

        // this.creaCluster(this.rowsR, this.windowsRowsR);
    }

    private sostituisciNelCluster(puntoLuceModificato, p, scalaColori) {
        const perColore = (puntoLuceModificato != null) ? puntoLuceModificato[scalaColori.tabellaImpostataScala] : p[scalaColori.tabellaImpostataScala];
        let nomeDelNero;
        Object.keys(scalaColori.scalaColore).forEach((key) => {
            if (scalaColori.scalaColore[key] == chiaviScala.blackColor) {
                nomeDelNero = key;
            }
        });
        this.vettoreDeiCluster.forEach((a, index) => {
            if (a.colore == scalaColori.scalaColore[(perColore == undefined) ? nomeDelNero : perColore]) {
                this.vettoreDeiCluster[index].daFiltro = this.aggiornaVettoriElementi(a.daFiltro, a.daOcchio, puntoLuceModificato, p)[0];
                this.vettoreDeiCluster[index].daOcchio = this.aggiornaVettoriElementi(a.daFiltro, a.daOcchio, puntoLuceModificato, p)[1];
            }
        });
    }

    private cambiaColoreIconeDopoAggionramento(daFiltri: PuntiLuceParse[], daFinestra: PuntiLuceParse[], forceRefresh: boolean, puntoLuceModificato = null, p = null, contantore = null) {
        this.scaleColor.ElementiVisualizzatiInMappa = daFiltri.concat(daFinestra);
        const scalaColori: scalaColoreNomeTabella = {
            scalaColore: this.scaleColor.getelementiScala(),
            tabellaImpostataScala: this.scaleColor.elementoScalaAttuale
        };
        this.sostituisciNelCluster(puntoLuceModificato, p, scalaColori);
        if (this.scaleColor.minEcambiato || this.scaleColor.maxEcambiato || forceRefresh || this.scaleColor.scaleEcambiato) {
            this.cambiaColoreDelleIcone(scalaColori);
        }
    }

    private prendiPiuVicino(valore, scalaColore): number {
        let arrayKey = Array.from(Object.keys(scalaColore));
        let elementoCast;
        let temp: number = 12345678;
        arrayKey.sort();
        arrayKey.every((element) => {
            elementoCast = (element as any);
            // console.log(Math.abs(elementoCast - valore), (Math.abs(valore - temp)))
            if (Math.abs(elementoCast - valore) < (Math.abs(valore - temp))) {
                temp = elementoCast;
                return true;
            } else {
                return false;
            }
        });
        return temp;
    }

    // sostituisce creaCluster da testare prima di eliminare
    private creaClusterWithColor(vettoriDaFilter: PuntiLuceParse[], vettoriDaOcchio: PuntiLuceParse[]) {
        const vettoreDeiCluster = [];
        // vettoreDeiCluster.push({daFiltro: [], daOcchio: [], colore: scalaColore[key]});

        vettoriDaFilter.forEach(puntoLuce => {
            const index = vettoreDeiCluster.findIndex(cluster => cluster.colore == puntoLuce.icon.strokeColor);
            if (index > -1) {
                vettoreDeiCluster[index].daFiltro.push(puntoLuce);
            } else {
                vettoreDeiCluster.push({daFiltro: [puntoLuce], daOcchio: [], colore: puntoLuce.icon.strokeColor});
            }
        });
        vettoriDaOcchio.forEach(puntoLuce => {
            const index = vettoreDeiCluster.findIndex(cluster => cluster.colore == puntoLuce.icon.strokeColor);
            if (index > -1) {
                vettoreDeiCluster[index].daOcchio.push(puntoLuce);
            } else {
                vettoreDeiCluster.push({daFiltro: [], daOcchio: [puntoLuce], colore: puntoLuce.icon.strokeColor});
            }
        });
        return vettoreDeiCluster;
    }

    // vengono creati cluster in funzione della scala impostata,
    // metodo deprecato
    // private creaCluster(vetteroiDaFilter: PuntiLuceParse[], vettoriDaOcchio: PuntiLuceParse[]) {
    //     let vettoreDeiCluster = [];
    //     // necessario inizializzarlo per la teabella delle etichette in settings in modo tale da visualizzare le differenze
    //     this.scaleColor.ElementiVisualizzatiInMappa = this.rowsR.concat(this.windowsRowsR);
    //     const tabellaSuCuiSpezzare = this.scaleColor.elementoScalaAttuale;
    //     const tipoDato = this.scaleColor.tipoDato;
    //     const scalaColore = this.scaleColor.getelementiScala();
    //     const filterPipe = new TransformForTranslatePipe();
    //     let elementoSenzaSpazi;
    //     let nomeDelNero;
    //     let elemento;
    //     let i: object = {};
    //     const possibleValueArray = (ordinamentoEcampiTraduzioni.PuntiLuce[tabellaSuCuiSpezzare].hasOwnProperty('possibleValues')) ?
    //         Array.isArray(ordinamentoEcampiTraduzioni.PuntiLuce[tabellaSuCuiSpezzare].possibleValues) : false;
    //     Object.keys(scalaColore).forEach((key, index) => {
    //         i[key] = index;
    //         if (scalaColore[key] == chiaviScala.blackColor) {
    //             nomeDelNero = key;
    //         }
    //         vettoreDeiCluster.push({daFiltro: [], daOcchio: [], colore: scalaColore[key]});
    //     });
    //     vetteroiDaFilter.forEach((element) => {
    //         if (tipoDato == 'date') {
    //             elemento = (element[tabellaSuCuiSpezzare] != undefined) ? new Date(this.datePipe.transform(element[tabellaSuCuiSpezzare], 'yyyy-MM-dd')).getTime() : element[tabellaSuCuiSpezzare];
    //             elemento = (elemento != undefined) ? elemento : nomeDelNero;
    //         } else if (tipoDato != 'pointer_circuiti' && tipoDato != 'pointer_strade') {
    //             elementoSenzaSpazi = filterPipe.transform(element[tabellaSuCuiSpezzare]) as string;
    //             elemento = (scalaColore[elementoSenzaSpazi] != undefined) ? elementoSenzaSpazi : nomeDelNero;
    //         } else {
    //             const nomeTabella = ordinamentoEcampiTraduzioni.PuntiLuce[tabellaSuCuiSpezzare].possibleValues.split('.')[1];
    //             if (element[tabellaSuCuiSpezzare] != undefined) {
    //                 elementoSenzaSpazi = filterPipe.transform(element[tabellaSuCuiSpezzare][nomeTabella]) as string;
    //                 elemento = (scalaColore[elementoSenzaSpazi] != undefined) ? elementoSenzaSpazi : (nomeDelNero != undefined) ? nomeDelNero : 'undefined';
    //             } else {
    //                 elemento = (nomeDelNero != undefined) ? nomeDelNero : 'undefined';
    //             }
    //         }
    //         vettoreDeiCluster[i[elemento]].daFiltro.push(element);
    //     });
    //     vettoriDaOcchio.forEach((element) => {
    //         if (tipoDato == 'date') {
    //             (element[tabellaSuCuiSpezzare] != undefined) ? new Date(this.datePipe.transform(element[tabellaSuCuiSpezzare], 'yyyy-MM-dd')).getTime() : element[tabellaSuCuiSpezzare];
    //             elemento = (elemento != undefined) ? elemento : nomeDelNero;
    //         } else if (tipoDato != 'pointer_circuiti' && tipoDato != 'pointer_strade') {
    //             elementoSenzaSpazi = filterPipe.transform(element[tabellaSuCuiSpezzare]) as string;
    //             elemento = (scalaColore[elementoSenzaSpazi] != undefined) ? elementoSenzaSpazi : nomeDelNero;
    //         } else {
    //             const nomeTabella = ordinamentoEcampiTraduzioni.PuntiLuce[tabellaSuCuiSpezzare].possibleValues.split('.')[1];
    //             if (element[tabellaSuCuiSpezzare] != undefined) {
    //                 elementoSenzaSpazi = filterPipe.transform(element[tabellaSuCuiSpezzare][nomeTabella]) as string;
    //                 elemento = (scalaColore[elementoSenzaSpazi] != undefined) ? elementoSenzaSpazi : (nomeDelNero != undefined) ? nomeDelNero : 'undefined';
    //             } else {
    //                 elemento = (nomeDelNero != undefined) ? nomeDelNero : 'undefined';
    //             }
    //         }
    //         vettoreDeiCluster[i[elemento]].daOcchio.push(element);
    //     });
    //     console.log("clusters-old: ",vettoreDeiCluster);
    //     return vettoreDeiCluster;
    // }

    //crea la icona del cluster
    private getGoogleClusterInlineSvg = function (color) {
        var encoded = window.btoa('<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-100 -100 200 200"><defs><g id="a" transform="rotate(45)"><path d="M0 47A47 47 0 0 0 47 0L62 0A62 62 0 0 1 0 62Z" fill-opacity="0.7"/><path d="M0 67A67 67 0 0 0 67 0L81 0A81 81 0 0 1 0 81Z" fill-opacity="0.5"/><path d="M0 86A86 86 0 0 0 86 0L100 0A100 100 0 0 1 0 100Z" fill-opacity="0.3"/></g></defs><g fill="' + color + '"><circle r="42"/><use xlink:href="#a"/><g transform="rotate(120)"><use xlink:href="#a"/></g><g transform="rotate(240)"><use xlink:href="#a"/></g></g></svg>');
        return ('data:image/svg+xml;base64,' + encoded);
    };

    //ritorna l'icona del color, color deve essere del tipocolor esadecimale #AABBCC
    public colorTextCluster(color) {
        let textColor: string = '#060606';
        if (color == '#000000') {
            textColor = '#DDDDDD';
        }
        return [
            {
                width: 70,
                height: 70,
                url: this.getGoogleClusterInlineSvg(color),
                textColor: textColor,
                textSize: 12
            }
        ];
    }


    public visualizzaTuttiIMarcker(confiniAmministrativiSi: boolean): void {
        let vectorForZoom = this.rowsR.concat(this.windowsRowsR).concat(this.circuiti).concat(this.puntiStampa).filter((element) => element != undefined);
        if (!!vectorForZoom && vectorForZoom.length != 0) {
            // if (Array.isArray(this.confiniAmministrativi) && confiniAmministrativiSi) {
            //     this.confiniAmministrativi.forEach(confini => {
            //         confini.forEach(coordinate => {
            //             vectorForZoom.push({location: {latitude: coordinate.lat, longitude: coordinate.lng}});
            //         });
            //
            //     });
            // }
            this.setCenterZoom(vectorForZoom);
        } else {
            const message = this.translate.instant('noLightPointInMap');
            this.alertService.warning(message);
            // this.setCenterZoom(this.confiniAmministrativi);
        }
    }


    public getPrendiColoreInbaseAlValore(value): string {
        if (!isNotNullOrUndefined(value)) {
            return '#000000';
        } else {
            const castValue = (typeof value != 'number') ? parseFloat(value) : value;
            return this.linearScaleService.PrendiColore(castValue);
        }
    }

    public disabledLuxScale() {
        this.puntiLuceLuxScale = [];
        this.elementiScalaLux = undefined;
        this.luxScaleOff = true;
    }

    public luxScale() {
        if (this.map.getZoom() >= 17) {
            const loaderId = this.loaderService.addLoader();
            this.luxScaleOff = false;
            this.puntiLuceLuxScale = [];
            this.mapService.getLuxeScale(this.map.getBounds(), 20000).subscribe((luxesValue: any) => {
                if (luxesValue.results.length > 0) {
                    this.linearScaleService.setMinMaxLux(luxesValue.results, 'lux', 101, 4, false);
                    this.elementiScalaLux = this.linearScaleService.ElementiScala;
                    this.luxmax = Math.round(this.linearScaleService.keyMax);
                    this.luxmin = Math.round(this.linearScaleService.keyMin);
                } else {
                    this.alertService.error(this.translate.instant('noDataLuxScale'));
                    this.luxScaleOff = true;
                }
                let bounds = new google.maps.LatLngBounds();
                luxesValue.results.forEach((element) => {
                        // setta il centro della mappa
                        const loc = new google.maps.LatLng(element.location.latitude, element.location.longitude);
                        bounds.extend(loc);
                        this.map.fitBounds(bounds);
                        this.map.panToBounds(bounds);
                        const lat = Number.parseFloat(element.location.latitude);
                        const lng = Number.parseFloat(element.location.longitude);
                        this.puntiLuceLuxScale.push(
                            {
                                location: {
                                    lat: lat,
                                    lng: lng
                                },
                                lux: element.lux
                            }
                        );
                    },
                    error => {
                        this.luxScaleOff = true;
                        this.alertService.error(error.message);
                    });
                this.loaderService.removeLoader(loaderId);
                this.changeDetector.detectChanges();
            });
        } else {
            this.alertService.warning(this.translate.instant('luxScaleLargeWindow'));
        }

    }

    // //elimina i filtri una volta chiusa la pagina
    @HostListener('window:unload')
    public beforeunloadHandler() {
        this.filterService.destroyAllFilter();
        this.filterService.destoryLocaleConfiniAmministrativi();
        this.filterService.destoryLocalePuntiStampa();
    }

    showBatchEditingTable = false;
    innerTableClick = false;
    editableLights: PuntiLuceParse[] = [];

    batchEdit() {
        const objectIdLightPoints = this.filterService.getReport();
        if (Array.isArray(objectIdLightPoints) && objectIdLightPoints.length > 0) {
            this.editableLights = objectIdLightPoints.map((idLightPoint) => {
                const puntoLuce = new PuntiLuceParse();
                puntoLuce.objectId = idLightPoint;
                return puntoLuce;
            });
            this.destroyDrawingManager();
        } else {
            this.editableLights = this.windowsRowsR
                .filter((r) => !this.rowsR.find((r2) => r2.id === r.id))
                .concat(this.rowsR.slice());
        }
        this.showBatchEditingTable = true;
    }

    handleInnerClick() {
        this.innerTableClick = true;
    }

    handleBackdropClick() {
        if (!this.innerTableClick) {
            this.showBatchEditingTable = false;
        }
        // TODO: refresh punti luce su mappa solo se modifica tipologia sostegno o tipologia corpo illuminante
        this.innerTableClick = false;
    }

    /**
     *
     * @param element
     * ritorna true se è settato ed in caso di array anche se contiene elementi
     */
    public isSetAndContainElement(element): boolean {
        if (Array.isArray(element)) {
            return (isNotNullOrUndefined(element)) ? element.length != 0 : false;
        } else {
            return isNotNullOrUndefined(element);
        }
    }

    public activeCaptureMarker() {
        if (this.captureMarker) {
            this.destroyDrawingManager();
        } else {
            this.onCaptureMarker();
        }

    }

    isDisableIcon(value: boolean) {
        if (value) {
            return 'Disable';
        } else {
            return null;
        }
    }


    public deleteAllElement() {
        const numeroCircuiti = (isNotNullOrUndefined(this.circuiti)) ? this.circuiti.length : 0;
        const numeroPuntiLuce = (isNotNullOrUndefined(this.rowsR.concat(this.windowsRowsR))) ? this.rowsR.concat(this.windowsRowsR).length : 0;
        this.setCenterZoom(this.circuiti.concat(this.rowsR).concat(this.windowsRowsR));
        let loaderId;
        this.myDialog.openDialogForDeleteAllVisualizedElement(numeroCircuiti, numeroPuntiLuce).pipe(
            switchMap((result) => {
                loaderId = this.loaderService.addLoader();
                if (result == className.circuiti) {
                    return this.mapService.destroyAllCircuit(this.circuiti).pipe(
                        switchMap(() => this.mapService.getCircuiti(this.filterService.getLocaleCircuits())),
                        map((result) => {
                            return {eliminato: className.circuiti};
                        })
                    );
                } else if (result == className.puntiLuce) {
                    return this.mapService.destroyAllpuntiLuce(this.rowsR.concat(this.windowsRowsR)).pipe(
                        map((result) => {
                            return {eliminato: className.puntiLuce};
                        })
                    );
                } else {
                    return of({eliminato: false});
                }
            })
        ).subscribe((result) => {
            if (result.eliminato === className.circuiti) {
                this.circuiti = [];
                this.alertService.success(this.translate.instant('deleteSuccessufully'));
                this.mapRefresh();
                this.changeDetector.detectChanges();
            } else if (result.eliminato === className.puntiLuce) {
                this.rowsR = [];
                this.windowsRowsR = [];
                this.alertService.success(this.translate.instant('deleteSuccessufully'));
                this.mapRefresh();
                this.changeDetector.detectChanges();
            } else {
                this.alertService.success(this.translate.instant('alert.operationCancel'));
            }
            this.loaderService.removeLoader(loaderId);
        }, error => {
            this.loaderService.removeLoader(loaderId);
            this.alertService.error(error);
        });
    }

    getTextLabel(puntoLuce: PuntiLuceParse): string {
        const type: string = (!!this.elementiclasse.PuntiLuce[this.elemetLabel]) ? this.elementiclasse.PuntiLuce[this.elemetLabel].type : null;
        const ND = this.translate.instant('NaN');
        let value;
        if (isNotNullOrUndefined(type)) {
            if (type.includes('POINTER')) {
                const field = this.elementiclasse.PuntiLuce[this.elemetLabel].possibleValues.split('.')[1];
                value = (isNotNullOrUndefined(puntoLuce[this.elemetLabel])) ? puntoLuce[this.elemetLabel][field] : null;
            } else {
                switch (type) {
                    case 'DATE':
                        return formatDate(puntoLuce[this.elemetLabel], 'mediumDate', this.translateService.currentLang);
                        break;
                    case 'BOOL':
                        if (!isNotNullOrUndefined(puntoLuce[this.elemetLabel])) {
                            value = null;
                        } else {
                            const si = this.translate.instant('yes');
                            const no = this.translate.instant('no');
                            value = (puntoLuce[this.elemetLabel]) ? si : no;
                        }
                        break;
                    case 'ELENCO' :
                        if (isNotNullOrUndefined(puntoLuce[this.elemetLabel])) {
                            const filterPipe = new TransformForTranslatePipe();
                            const chiaveSenzaSpazi = filterPipe.transform(puntoLuce[this.elemetLabel]) as string;
                            const traduzione: string = this.translateService.instant('dashboard_sidenav.PuntiLuce.' + this.elemetLabel + '.possibleValues.' + chiaveSenzaSpazi);
                            value = (traduzione.includes('dashboard_sidenav.PuntiLuce')) ? puntoLuce[this.elemetLabel] : traduzione;
                        } else {
                            value = null;
                        }
                        break;
                    case'NUMBER':
                    case'INT':
                        value = (isNotNullOrUndefined(puntoLuce[this.elemetLabel])) ? puntoLuce[this.elemetLabel].toString() : ND;
                        break;
                    default:
                        value = puntoLuce[this.elemetLabel];
                        break;
                }
            }
        } else {
            value = null;
        }
        return (isNotNullOrUndefined(value) && value.trim().length > 0) ? value : ND;
    }


    public mapChangeZoom(event) {
        this.zoomSetting = event;
    }


    public getNumberElementLoad(puntoLuce): { text: string, value, key } {
        let text: string = this.getTextLabel(puntoLuce);
        const filterPipe = new TransformForTranslatePipe();
        const chiaveSenzaSpazi = filterPipe.transform(text) as string;
        text = (text.length > 13) ? text.substring(0, 13) + '...' : text;
        return {text: text, value: [puntoLuce.objectId + puntoLuce.createdAt.getTime()], key: chiaveSenzaSpazi};
    }

    public get numerElementInMapForLabel() {
        const numeroElementiPerLabel = {};
        this.rowsR.concat(this.windowsRowsR).forEach((puntoLuce) => {
            const tutto = this.getNumberElementLoad(puntoLuce);
            if (!isNotNullOrUndefined(numeroElementiPerLabel[tutto.key])) {
                numeroElementiPerLabel[tutto.key] = tutto.value;
            } else {
                if (!numeroElementiPerLabel[tutto.key].includes(tutto.value)) {
                    numeroElementiPerLabel[tutto.key].push(tutto.value);
                }
            }
        });
        return numeroElementiPerLabel;
    }

    public getLabelMarker(puntoLuce: PuntiLuceParse) {
        let containedInMap = false;
        if (isNotNullOrUndefined(this.map) && isNotNullOrUndefined(this.map.getBounds())) {
            const latLng = new google.maps.LatLng(puntoLuce.location.latitude, puntoLuce.location.longitude);
            containedInMap = this.map.getBounds().contains(latLng);
        }
        // tslint:disable-next-line:max-line-length
        if (isNotNullOrUndefined(this.elemetLabel) && this.elemetLabel != 'nobody' && this.map.getZoom() > this.maxZoomToViusualizedLabel && containedInMap) {
            const text = this.getNumberElementLoad(puntoLuce).text;
            return {
                color: (this.map.mapTypeId == 'roadmap') ? 'black' : 'white',
                fontWeight: 'bold',
                fontSize: '12px',
                text: text,
            };
        }
    }

    public changeLabel(event) {
        this.elemetLabel = event;
    }

    public boundChangeNow() {
        let containedInMap = false;
        if (isNotNullOrUndefined(this.map)) {
            this.rowsR.concat(this.windowsRowsR).every((puntoLuce) => {
                const latLng = new google.maps.LatLng(puntoLuce.location.latitude, puntoLuce.location.longitude);
                containedInMap = this.map.getBounds().contains(latLng);
                return !containedInMap;
            });
            if (containedInMap && this.elemetLabel != 'nobody' && this.map.getZoom() > this.maxZoomToViusualizedLabel) {
                this.changeDetector.detectChanges();
            }
        }
    }


    /*
 per riscalare le icone
    private getScale(latLng, zoom) {
        return 156543.03392 * Math.cos(latLng.lat() * Math.PI / 180) / Math.pow(2, zoom);
    }

    createMarkerabc() {
        const map=this.map;
        const position=map.getCenter();
        var zoom = map.getZoom();
        var scale = this.getScale(position, zoom + 1); //meters per pixel
        var width = 1500 / scale;
        var height = width;

        var icon = {
            url: 'https://openclipart.org/download/82549/blue-circle.svg',
            anchor: new google.maps.Point(50, 50),
            scaledSize: new google.maps.Size(width, height)
        };

        return new google.maps.Marker({
            position: position,
            map: map,
            icon: icon
        });
    }*/


    getInterdistance(objectIdLightPoints: String[]) {
        return this.mapService.getInterdistanceLightPoint(objectIdLightPoints);
    }


    clicLine(event, idInfoWindow) {
        this.polyLineInfoWindow.push({
            objectId: idInfoWindow,
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
            persistent: true
        });
        this.changeDetector.detectChanges();
    }

    mouseOver(event, idInfoWindow) {
        const indexInfoWindow = this.polyLineInfoWindow.findIndex(lineCoord => lineCoord.objectId == idInfoWindow);
        if (indexInfoWindow < 0) {
            this.polyLineInfoWindow.push({
                objectId: idInfoWindow,
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
                persistent: false
            });
        }
        this.changeDetector.detectChanges();
    }


    mouseOut(event, idInfoWindow) {
        setTimeout(() => {
            this.removePositionInfoWindoInPolyLine(idInfoWindow);
            this.changeDetector.detectChanges();
        }, 1000);
    }

    getPositionInfoWindoInPolyLine(idInfoWindow) {
        const index = this.polyLineInfoWindow.findIndex(lineCoord => lineCoord.objectId == idInfoWindow);
        return (index >= 0) ? {
            lat: this.polyLineInfoWindow[index].lat,
            lng: this.polyLineInfoWindow[index].lng
        } : undefined;
    }

    // cosi non vengono autoeliminati dal mouseOver, gli info window generati dal click sulla linea sono persistenti e vengono eliminati solo dall chiusura della finestra
    removePositionInfoWindoInPolyLine(idInfoWindow, removePersistent = false) {
        const indexInfoWindow = this.polyLineInfoWindow.findIndex(lineCoord => (lineCoord.objectId == idInfoWindow && (lineCoord.persistent == removePersistent || !lineCoord.persistent)));
        if (indexInfoWindow >= 0) {
            this.polyLineInfoWindow.splice(indexInfoWindow, 1);
        }
    }

}


//
