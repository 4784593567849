import {Component, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {LoaderService} from '../../../providers/services/loader.service';
import {AlertService} from '../../../providers/services/alert.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../providers/services/user.service';
import {ProjectService} from "../../../providers/services/project.service";
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {ReportFormService} from "../../../providers/forms/report-form.service";
import {ReportsService} from "../../../providers/services/reports.service";
import {FilterService} from "../../../providers/services/filter.service";
import {TranslateService} from "@ngx-translate/core";
import {MapService} from "../../../providers/services/map.service";

@Component({
    selector: 'app-create-group',
    templateUrl: './create-group.component.html'
})
export class CreateGroupComponent implements OnInit {

    public planForm: UntypedFormGroup;
    public user;
    public project;
    public reports;
    public loading = true;
    public spliReports = [];
    public canICreatePlan = false;

    constructor(
        public dialogRef: MatDialogRef<CreateGroupComponent>,
        private planUserForm: ReportFormService,
        private planService: ReportsService,
        private loaderService: LoaderService,
        private filterService: FilterService,
        private alertServices: AlertService,
        private userService: UserService,
        private router: Router,
        private projectService: ProjectService,
        public dialog: MatDialog,
        private activatedRoute: ActivatedRoute,
        private translate: TranslateService,
        private mapService: MapService
    ) {
        this.loadData();
    }

    ngOnInit() {
    }

    private async loadData() {
        this.user = this.userService.user();
        this.planForm = this.planUserForm.getCreateGroupForm();
        this.canICreatePlan = await this.projectService.isValidSubscription(5, false, true, false, false);
        this.reports = this.planService.reportsInMap;
    }

    async createGroup() {
        let group;
        if (this.planForm.invalid) {
            this.alertServices.error(this.translate.instant('compile_form'));
        } else {
            const loaderId = this.loaderService.addLoader();
            try {
                if (await this.projectService.isValidSubscription(5, false, true, false, false)) {
                    // await this.verifySplitGroup();
                    // for (let i in this.spliReports) {
                    //     this.spliReports[i]
                    //     let titolo;
                    //     if (Object.keys(this.spliReports).length == 1) {
                    //         titolo = this.planForm.value.titolo
                    //     } else {
                    //         titolo = this.planForm.value.titolo + ' - ' + i
                    //     }
                    //     await this.planService.createGroup(this.planForm.value, this.spliReports[i], titolo);
                    // }
                    group = await this.planService.createGroup(this.planForm.value, this.reports);
                    this.alertServices.success(this.translate.instant('group_saved'));
                } else {
                    this.alertServices.error(this.translate.instant('create_group_error'));
                }
            } catch (e) {
                this.alertServices.error(this.translate.instant('error') + ' ' + e.message);
            } finally {
                this.loaderService.removeLoader(loaderId);
                this.dialogRef.close(group)
            }
        }
    }

    async verifySplitGroup() {
        for (let item of this.reports) {
            let c = (await this.mapService.getLightByObjectId(item)).circuito.id;
            if (!this.spliReports[c]) {
                this.spliReports[c] = []
            }
            this.spliReports[c].push(item)
        }
    }

}
