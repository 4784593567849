<div [formGroup]="form">
  <mat-form-field [appearance]="appearance">
    <mat-label>{{ label }}</mat-label>
    <input 
      matInput
      [matDatepicker]="picker" 
      (dateChange)="dateChange.emit($event.value)" 
      [min]="minDate" 
      [max]="maxDate"
      formControlName="date">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker [touchUi]="isSmallDevice" #picker></mat-datepicker>
  </mat-form-field>
</div>