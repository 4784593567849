import {Directive, ElementRef, HostListener, Input, Renderer2} from '@angular/core';

@Directive({
    selector: '[appScroolTo]'
})
export class ScroolToDirective {

    @Input() nativeElementReference: ElementRef;
    @Input() firstValueTop: number;
    @Input() deltaY: number;

    constructor(
        private renderer: Renderer2,
        private el: ElementRef) {
        this.renderer.addClass(this.el.nativeElement, 'overflow-container');
    }

    @HostListener('scroll', ['$event'])
    onWindowScroll(event) {
        let value;
        if (event.target.scrollTop < this.deltaY) {
            value = this.firstValueTop - event.target.scrollTop;
            value = 'translate(-50%,' + -event.target.scrollTop + 'px)';
        } else {
            value = this.firstValueTop - this.deltaY;
            value = 'translate(-50%,' + -this.deltaY + 'px)';
        }
        this.renderer.setStyle(this.nativeElementReference, 'transform', value);

    }
}
