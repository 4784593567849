<h1 mat-dialog-title>{{'details' | translate}}</h1>
<div mat-dialog-content>
    <div>
        <div class="spacer">
            <div><span class="card-label">{{'lights_n' | translate}}</span>: {{puntiLuce}}
            </div>
        </div>
        <div class="spacer">
            <div><span class="card-label">{{'circuits_n' | translate}}</span>: {{circuiti}}
            </div>
        </div>
        <div class="spacer">
            <div><span class="card-label">{{'lamps_n' | translate}}</span>: {{lampade}}
            </div>
        </div>
        <div class="spacer">
            <div><span class="card-label">{{'watt' | translate}}</span>: {{potenza}} kW
            </div>
        </div>
    </div>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button (click)="dialogRef.close()">{{ 'close' | translate}}</button>
</div>
