<form [formGroup]="form" *ngIf="isSetForm$|async ">
    <mat-tab-group>
        <ng-container *ngFor="let tab of htmlData">
            <mat-tab [label]="tab.tabGroupName|translate">
                <mat-list style="padding-bottom: 60px">


                    <ng-container *ngFor="let form of tab.forms">
                        <mat-list-item style="height: unset;width: 100%" >
                            <app-get-form-fiel-by-configuration-element class="w100" [type]="form.type"
                                                                        [titleTraduction]="form.traduction"
                                                                        [possibleValues]="form.possibleValues"
                                                                        [formControlName]="form.formControlName">
                            </app-get-form-fiel-by-configuration-element>
                        </mat-list-item>
                        <!--                    <div class="flex-center-width100">-->
                        <!--                        <div class="w100">-->
                        <!--                        </div>-->
                        <!--                        <div style="margin: 0 5px 34px 5px;">-->
                        <!--                            <ng-container *ngIf="form!=null&&form.visualizeButton==true&&form.detailButton!=null">-->
                        <!--                                <app-button-spinner [loading]="loading[form.nameVariableToGetPossibleValues]">-->
                        <!--                                    <button-->
                        <!--                                            mat-raised-button color="primary"-->
                        <!--                                            (click)="htmlButton(form.detailButton.message,form)">-->
                        <!--                                        <mat-icon>{{form.detailButton.icon}}</mat-icon>-->
                        <!--                                    </button>-->
                        <!--                                </app-button-spinner>-->
                        <!--                            </ng-container>-->
                        <!--                        </div>-->
                        <!--                    </div>-->
                        <mat-card *ngIf="form.formGroupChild">
                            <ng-container [formGroupName]="form.formGroupChild.formGroupName">
                                <ng-container *ngFor="let subForm of form.formGroupChild.fields">
                                    <app-get-form-fiel-by-configuration-element [type]="subForm.type"
                                                                                [titleTraduction]="subForm.traduction"
                                                                                [possibleValues]="subForm.possibleValues"
                                                                                [formControlName]="subForm.formControlName">
                                    </app-get-form-fiel-by-configuration-element>
                                </ng-container>
                            </ng-container>
                        </mat-card>
                    </ng-container>
                </mat-list>
            </mat-tab>
        </ng-container>
    </mat-tab-group>
</form>
<button class="fab" color="accent" mat-mini-fab [disabled]="disabledSaveButton"
        matTooltip="{{'button.save' | translate}}" style="z-index: 10000;margin-right: 6rem;" type="button"
        (click)="clickSaveButton()">
    <mat-icon>save</mat-icon>
</button>