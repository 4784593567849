import * as Parse from 'parse';
import {ElectricLinesMap} from '../providers/services/map.service';
import {arrayIsSet, paramsApiParse_v2} from './Models';
import {fromPromise} from "rxjs/internal-compatibility";
import {Observable} from "rxjs";

export class LineaElettricaParse extends Parse.Object {

    public static CLASS_NAME = 'LineaElettrica';
    // tslint:disable-next-line:variable-name
    private _linesMap: ElectricLinesMap[] | undefined;

    constructor() {
        super(LineaElettricaParse.CLASS_NAME);
    }

    public set linesMap(value) {
        this._linesMap = value;
    }

    public get linesMap(): ElectricLinesMap[] {
        return this._linesMap;
    }

    public unsetLinesMap() {
        this.linesMap = undefined;
    }

    /**
     *   length expressed in meters
     */
    public get lengthMeter(): number {
        let length = 0;
        if (arrayIsSet(this.linesMap)) {
            const firstValue = {
                length: 0,
                lastPoint: new Parse.GeoPoint(this.linesMap[0].start.latitude, this.linesMap[0].start.longitude)
            };
            length = this.linesMap.reduce((previus, current) => {
                const previusPoint = previus.lastPoint;
                const currentPoint = new Parse.GeoPoint(current.start.latitude, current.start.longitude);
                const lastLength = previus.length + previusPoint.kilometersTo(currentPoint) * 1000;
                return {lastPoint: currentPoint, length: lastLength};
            }, firstValue).length;
        }
        return length;
    }

    public get lengthConvertInMeter(): string {
        let length;
        if (arrayIsSet(this.linesMap)) {
            const firstValue = {
                length: 0,
                lastPoint: new Parse.GeoPoint(this.linesMap[0].start.latitude, this.linesMap[0].start.longitude)
            };
            length = this.linesMap.reduce((previus, current) => {
                const previusPoint = previus.lastPoint;
                const currentPoint = new Parse.GeoPoint(current.start.latitude, current.start.longitude);
                const lastLength = previus.length + previusPoint.kilometersTo(currentPoint) * 1000;
                return {lastPoint: currentPoint, length: lastLength};
            }, firstValue).length;
            length = (Math.round(length * 100) / 100).toString() + ' m';
        } else {
            length = '-';
        }
        return length;
    }

    public set objectId(value) {
        this.id = value;
    }

    public get objectId(): string {
        return this.id;
    }

    public set ACL(value) {
        super.set('ACL', value);
    }

    public get ACL() {
        return super.get('ACL');
    }

    public set createdAt(value) {
        super.set('createdAt', value);
    }

    public get createdAt(): Date {
        return super.get('createdAt');
    }

    public set updatedAt(value) {
        super.set('updatedAt', value);
    }

    public get updatedAt(): Date {
        return super.get('updatedAt');
    }

    public set annoInstallazione(value) {
        super.set('annoInstallazione', value);
    }

    public get annoInstallazione(): string {
        return super.get('annoInstallazione');
    }

    public set name(value) {
        super.set('name', value);
    }

    public get name(): any {
        return super.get('name');
    }

    public set cavoDiTerraPresente(value) {
        super.set('cavoDiTerraPresente', value);
    }

    public get cavoDiTerraPresente(): any {
        return super.get('cavoDiTerraPresente');
    }

    public set circuito(value) {
        super.set('circuito', value);
    }

    public get circuito(): any {
        return super.get('circuito');
    }

    public set coloreGuaina1(value) {
        super.set('coloreGuaina1', value);
    }

    public get coloreGuaina1(): any {
        return super.get('coloreGuaina1');
    }

    public set coloreGuaina2(value) {
        super.set('coloreGuaina2', value);
    }

    public get coloreGuaina2(): any {
        return super.get('coloreGuaina2');
    }

    public set correnteNominale(value) {
        super.set('correnteNominale', value);
    }

    public get correnteNominale(): number {
        return super.get('correnteNominale');
    }

    public set daPosare(value) {
        super.set('daPosare', value);
    }

    public get daPosare(): boolean {
        return super.get('daPosare');
    }

    public set description(value) {
        super.set('description', value);
    }

    public get description(): any {
        return super.get('description');
    }


    public set progetto(value) {
        super.set('progetto', value);
    }

    public get progetto() {
        return super.get('progetto');
    }

    public set produttore(value) {
        super.set('produttore', value);
    }

    public get produttore(): string {
        return super.get('produttore');
    }

    public set posizionamentoLinea(value) {
        super.set('posizionamentoLinea', value);
    }

    public get posizionamentoLinea(): string {
        return super.get('posizionamentoLinea');
    }

    public set materialeConduttore(value) {
        super.set('materialeConduttore', value);
    }

    public get materialeConduttore(): string {
        return super.get('materialeConduttore');
    }

    public set modello(value) {
        super.set('modello', value);
    }

    public get modello(): string {
        return super.get('modello');
    }

    public set numeroPoli(value) {
        super.set('numeroPoli', value);
    }

    public get numeroPoli(): number {
        return super.get('numeroPoli');
    }

    public set sezione(value) {
        super.set('sezione', value);
    }

    public get sezione(): number {
        return super.get('sezione');
    }

    public set schermato(value) {
        super.set('schermato', value);
    }

    public get schermato(): boolean {
        return super.get('schermato');
    }

    public set tensioneMaxAC(value) {
        super.set('tensioneMaxAC', value);
    }

    public get tensioneMaxAC(): number {
        return super.get('tensioneMaxAC');
    }

    public set tensioneMaxDC(value) {
        super.set('tensioneMaxDC', value);
    }

    public get tensioneMaxDC(): number {
        return super.get('tensioneMaxDC');
    }

    public set temperaturaMin(value) {
        super.set('temperaturaMin', value);
    }

    public get temperaturaMin(): number {
        return super.get('temperaturaMin');
    }

    public set temperaturaMax(value) {
        super.set('temperaturaMax', value);
    }

    public get temperaturaMax(): number {
        return super.get('temperaturaMax');
    }

    public set tipologiaConduttore(value) {
        super.set('tipologiaConduttore', value);
    }

    public get tipologiaConduttore(): string {
        return super.get('tipologiaConduttore');
    }

    public set tipologiaIsolamento(value) {
        super.set('tipologiaIsolamento', value);
    }

    public get tipologiaIsolamento(): string {
        return super.get('tipologiaIsolamento');
    }


    public set createdBy(value) {
        super.set('createdBy', value);
    }

    public get createdBy() {
        return super.get('createdBy');
    }

    public get query(): Parse.Query<LineaElettricaParse> {
        const query = new Parse.Query(LineaElettricaParse);
        return query;
    }


    public getAllLineeElettriche$(progetto): Observable<LineaElettricaParse[]> {
        let progettoId;
        if (progetto != null) {
            progettoId = progetto.objectId
        } else {
            progettoId = this.progetto.objectId
        }
        const params = paramsApiParse_v2({progettoId})
        return fromPromise(Parse.Cloud.run('getAllLineeElettriche', params));
    }
}

Parse.Object.registerSubclass(LineaElettricaParse.CLASS_NAME, LineaElettricaParse);
