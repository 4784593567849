import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    OnInit, QueryList,
    Sanitizer,
    ViewChild, ViewChildren
} from '@angular/core';
import {PrintPageService} from '../../../providers/services/print-page.service';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {DialogExportComponent} from '../dialog-export/dialog-export.component';
import {OrganizzazioneParse} from '../../../models/Organizzazione.Parse';
import {DomSanitizer} from '@angular/platform-browser';
import {FileServiceService} from '../../../providers/services/file-service.service';
import {NavbarService} from '../../../providers/services/navbar.service';
import {TranslateService} from '@ngx-translate/core';
import {allKeysLayout} from '../../../providers/services/streets.service';
import {DialogPopUpInfoService} from '../../../providers/services/dialog-pop-up-info.service';
import jsPDF from "jspdf";
import {formatDate} from "@angular/common";
import html2canvas from 'html2canvas';
import {combineLatest} from "rxjs";
import {fromPromise} from "rxjs/internal-compatibility";
import {isNotNullOrUndefined} from "../../../models/Models";

@Component({
    selector: 'app-print-street',
    templateUrl: './print-street.component.html',
    styleUrls: ['./print-street.component.scss']
})
export class PrintStreetComponent implements OnInit, AfterViewInit {


    @ViewChild('layoutStrada')
    layoutStrada;
    @ViewChild('immagineResultP1')
    immagineResultP1;
    @ViewChild('immagineResultP2')
    immagineResultP2;
    @ViewChild('imagePreview')
    immaginePreview;
    @ViewChild('imageWithLabel')
    immagineConTitoli;
    @ViewChild('imageDefinition')
    immagineDefinizioniStrada;
    @ViewChildren('imageLampsLabels')
    immagineConTitoliLampade: QueryList<ElementRef>;


    public url;
    public disablePrint = true;
    public hidden = true;

    private allKeyLayout = allKeysLayout;
    public today = Date.now();
    public loaderPrint: boolean = false;


    constructor(private printPage: PrintPageService,
                private file: FileServiceService,
                private sanitizer: DomSanitizer,
                private changeDetection: ChangeDetectorRef,
                private elementRef: ElementRef,
                private navBar: NavbarService,
                private translate: TranslateService,
                private dialogInfo: DialogPopUpInfoService,
                public dialogRef: MatDialogRef<DialogExportComponent>,
                @Inject(MAT_DIALOG_DATA)
                public data: { strada, risutatiCalcoloIlluminotecnico, fotometria, organizzazione: OrganizzazioneParse, ipea }
    ) {
    }


    get projectName() {
        return this.navBar.nameProject;
    }

    get layoutLength() {
        let length = 0;
        this.data.strada.layout.forEach((a) => {
            length += a.larghezza;
        });
        return length;
    }

    public closeDialog() {
        this.dialogRef.close();
    }

    ngAfterViewInit(): void {
        this.generateNumberPages();
        setTimeout(() => {
            this.url = this.layoutStrada.canvasOverlay.nativeElement.toDataURL();
            this.disablePrint = false;
        }, 200);
    }


    ngOnInit(): void {
    }

    get larghezzaTotale() {
        let larghezza = 0;
        this.data.strada.layout.forEach(layout => {
            larghezza += layout.larghezza;
        });
        return larghezza;
    }

    getvalueForMatrix(vector, i, j, y_calc_length) {
        return Math.round(vector[i * y_calc_length + j] * 100) / 100;
    }

    get imageInForometry() {
        return (isNotNullOrUndefined(this.data.fotometria.image)) ? this.data.fotometria.image.url() : '/assets/icon/photometry/lampsNull.png';
    }

    private roundAndAddingUnity(key, value) {
        if (isNotNullOrUndefined(value)) {
            if (key.includes('efficienza')) {
                return Math.round(value * 100) / 100 + ' (lm/W)';
            } else if (key.includes('potenza')) {
                return Math.round(value * 100) / 100 + ' (W)';
            } else if (key.includes('flusso')) {
                return Math.round(value * 100) / 100 + ' (lm)';
            } else if (key.toLowerCase().includes('temperatura') && key.toLowerCase().includes('colore')) {
                return value + ' (K)';
            } else {
                return value;
            }
        } else {
            return this.translate.instant('NaN');
        }
    }

    isInArray(arrayValue: string[], key: string) {
        return !arrayValue.every((value) => {
            return !key.includes(value);
        });
    }

    get arrayTableFotometry() {
        const arrayTable = [];
        const titleCalcoloIlluminotecnicoRaw = ['efficienza', 'flusso', 'potenza'];
        const titleFotometria = Object.keys(this.data.fotometria).filter(key => !(key === 'objectId' || key === 'nomeFile' || key === 'nomeLampada' || this.isInArray(titleCalcoloIlluminotecnicoRaw, key)));
        titleFotometria.forEach((key) => {
            const value = this.roundAndAddingUnity(key, this.data.fotometria[key]);
            const keyTrad = this.translate.instant('fotometria.' + key);
            arrayTable.push({key: keyTrad, value: value});
        });
        const titleCalcoloIlluminotecnico = Object.keys(this.data.risutatiCalcoloIlluminotecnico).filter(key => this.isInArray(titleCalcoloIlluminotecnicoRaw, key));
        titleCalcoloIlluminotecnico.forEach((key) => {
            const value = this.roundAndAddingUnity(key, this.data.risutatiCalcoloIlluminotecnico[key]);
            const keyTrad = this.translate.instant('fotometria.' + key);
            arrayTable.push({key: keyTrad, value: value});
        });
        let keyTrad = this.translate.instant('streets.ipea2013');
        arrayTable.push({key: keyTrad, value: this.data.ipea.ipea2013});
        keyTrad = this.translate.instant('streets.ipea2018');
        arrayTable.push({key: keyTrad, value: this.data.ipea.ipea2018});
        return arrayTable;
    }

    private conversionToRad(angle) {
        const angleConver = angle * Math.PI / 180;
        return Math.round(angleConver * 100) / 100;
    }

    private getL2Lampada(lampada) {
        const l2 = this.getBottomEdge(lampada);
        if (isNotNullOrUndefined(l2)) {
            return Math.round(l2 * 100) / 100;
        } else {
            return this.translate.instant('NaN');
        }
    }

    private betweenOrSup(x, inf, sup): boolean {
        if (isNotNullOrUndefined(sup)) {
            return (x >= inf && x < sup);
        } else {
            return x >= inf;
        }
    }

    private getBottomEdge(lampada) {
        let larghezza = 0;
        let L2;
        const y_lampada = lampada.origine_y + lampada.sbraccio * Math.cos(this.conversionToRad(lampada.angoloRotazione));
        this.data.strada.layout.forEach((layout, index) => {
            const sup = (index === this.data.strada.layout.length) ? larghezza + layout.larghezza : undefined;
            if (this.betweenOrSup(lampada.origine_y, larghezza, sup)) {
                const layout_y2 = larghezza + layout.larghezza;
                const diff1 = Math.abs(y_lampada - larghezza);
                const diff2 = Math.abs(y_lampada - layout_y2);
                const diff = Math.min(diff1, diff2);
                L2 = diff;
            }
            larghezza += layout.larghezza;
        });
        return L2;
    }

    get dataTableBulbsDescription() {
        const keyForTableInLampade = {
            'fotometria.H': 'altezza',
            'fotometria.L1': 'sbraccio',
            'fotometria.L2': 'origine_y',
            A1: 'angoloInclinazione',
            A2: 'angoloRotazione'
        };
        return this.data.strada.lampade.map((lampada, index) => {
            const objectForHtml = {};
            const name = this.translate.instant('fotometria.light');
            objectForHtml[name] = index + 1;
            Object.keys(keyForTableInLampade).forEach(key => {
                if (key === 'fotometria.L2') {
                    objectForHtml[key] = this.getL2Lampada(lampada);
                } else if (key === 'A1' || key === 'A2') {
                    const lampadaKey = keyForTableInLampade[key];
                    objectForHtml[key] = lampada[lampadaKey] + '°';
                } else {
                    const lampadaKey = keyForTableInLampade[key];
                    objectForHtml[key] = lampada[lampadaKey];
                }
            });
            return objectForHtml;
        });
    }

    getNameLayout(index) {
        const tipo = this.data.strada.layout[index].tipo;
        let i = 0;
        let numberForType = 1;
        while (i < index) {
            if (tipo === this.data.strada.layout[i].tipo) {
                numberForType++;
            }
            i++;
        }
        const tipoTrad = this.translate.instant('fotometria.' + tipo);
        return tipoTrad + ' ' + numberForType;
    }

    get dataForSteet() {
        const nD = this.translate.instant('NaN');
        return this.data.strada.layout.map((layout, index) => {
            const obj = {};
            this.allKeyLayout.forEach((key) => {
                if (layout.tipo === 'carreggiata' && key === 'tipologiaAsfalto') {
                    obj['fotometria.' + key] = 'C2';
                } else if (key === 'tipo') {
                    obj['fotometria.' + key] = this.getNameLayout(index);
                } else {
                    obj['fotometria.' + key] = (isNotNullOrUndefined(layout[key])) ? layout[key] : nD;
                }
            });
            return obj;
        });
    }

    get parametriStrada() {
        const interdistanceTrad = this.translate.instant('streets.interdistance');
        const maintenanceIndexTrad = this.translate.instant('streets.maintenance_index');
        return [
            {field: interdistanceTrad, value: this.data.strada.interdistanza},
            {field: maintenanceIndexTrad, value: this.data.strada.fattoreManutenzione}
        ];
    }

    getLimiti(limiti, x) {
        if (limiti.hasOwnProperty('min') && limiti.hasOwnProperty('max')) {
            const max = Math.round(limiti.max * 100) / 100;
            const min = Math.round(limiti.min * 100) / 100;
            let color;
            if (!(x > min && x < max)) {
                color = 'red';
            } else {
                color = 'rgb(23,114,69)';
            }
            return min + ' / ' + '<span style="white-space:nowrap;font-weight: bold;font-size:larger;color:' + color + '">' + x + '</span>' + ' / ' + max;
        } else if (limiti.hasOwnProperty('min')) {
            const min = Math.round(limiti.min * 100) / 100;
            let color;
            if (x < min) {
                color = 'red';
            } else {
                color = 'rgb(23,114,69)';
            }
            return min + ' / ' + '<span style="white-space:nowrap;font-weight: bold;font-size:larger;color:' + color + '">' + x + '</span>';
        } else {
            const max = Math.round(limiti.max * 100) / 100;
            let color;
            if (x > max) {
                color = 'red';
            } else {
                color = 'rgb(23,114,69)';
            }
            return '<span style="white-space:nowrap;font-weight: bold;font-size:larger;color:' + color + '">' + x + '</span>' + ' / ' + max;
        }
    }


    getLimitiJsPdf(limiti, x): { text, min, max } {
        if (limiti.hasOwnProperty('min') && limiti.hasOwnProperty('max')) {
            const max = Math.round(limiti.max * 100) / 100;
            const min = Math.round(limiti.min * 100) / 100;
            let color;
            if (!(x > min && x < max)) {
                color = JSON.stringify([218, 50, 50]);
            } else {
                color = JSON.stringify([23, 114, 69]);
            }
            return {text: '(((textColor:' + color + ';fontStyle:bold;fontSize:14)))' + x, min, max}
        } else if (limiti.hasOwnProperty('min')) {
            const min = Math.round(limiti.min * 100) / 100;
            let color;
            if (x < min) {
                color = JSON.stringify([218, 50, 50]);
            } else {
                color = JSON.stringify([23, 114, 69]);
            }
            return {text: '(((textColor:' + color + ';fontStyle:bold;fontSize:14)))' + x, min, max: false};
        } else {
            const max = Math.round(limiti.max * 100) / 100;
            let color;
            if (x > max) {
                color = JSON.stringify([218, 50, 50]);
            } else {
                color = JSON.stringify([23, 114, 69]);
            }
            return {text: '(((textColor:' + color + ';fontStyle:bold;fontSize:14)))' + x, min: false, max};
        }
    }

    paginationData(elementi: any [], numeroElementiPerPrimaPagina, numeroElementiDaSecondaPagina) {
        const pages = [];
        let i = 0;
        while (i < elementi.length) {
            let numeroElementiPerPagina;
            let ultimoElemento;
            if (i === 0) {
                ultimoElemento = (i + numeroElementiPerPrimaPagina <= elementi.length) ? i + numeroElementiPerPrimaPagina : elementi.length;
                numeroElementiPerPagina = numeroElementiPerPrimaPagina;
            } else {
                ultimoElemento = (i + numeroElementiDaSecondaPagina <= elementi.length) ? i + numeroElementiDaSecondaPagina : elementi.length;
                numeroElementiPerPagina = numeroElementiDaSecondaPagina;
            }
            pages.push(elementi.slice(i, ultimoElemento));
            i = ultimoElemento;
        }
        return pages;
    }

    get pagesResultsTables(): { name: string, dataSet: any[] }[][] {
        const dataTabels = [];
        this.data.risutatiCalcoloIlluminotecnico.risultati.forEach((risultato, index) => {
            if (risultato.hasOwnProperty('x_calc') && risultato.hasOwnProperty('y_calc')) {
                const nameTable = this.getNameLayout(index);
                const dataSet = Object.keys(risultato.valori).map((key) => {
                    const keyTrad = this.translate.instant('fotometria.' + key);
                    return {key: keyTrad, value: this.getLimiti(risultato.limiti[key], risultato.valori[key])};
                });
                dataTabels.push({name: nameTable, dataSet: dataSet});
            }
        });
        this.generateNumberPages();
        return this.paginationData(dataTabels, 2, 6);
    }

    get pagesResultsTablesJsPDF(): { name: string, dataSet: any[] }[][] {
        const dataTabels = [];
        this.data.risutatiCalcoloIlluminotecnico.risultati.forEach((risultato, index) => {
            if (risultato.hasOwnProperty('x_calc') && risultato.hasOwnProperty('y_calc')) {
                const nameTable = this.getNameLayout(index);
                const dataSet = Object.keys(risultato.valori).map((key) => {
                    const keyTrad = this.translate.instant('fotometria.' + key);
                    const limitiJspdf = this.getLimitiJsPdf(risultato.limiti[key], risultato.valori[key]);
                    return {
                        key: keyTrad,
                        valueMin: (limitiJspdf.min) ? limitiJspdf.min : '-',
                        text: limitiJspdf.text,
                        valueMax: (limitiJspdf.max) ? limitiJspdf.max : '-'
                    };
                });
                dataTabels.push({name: nameTable, dataSet: dataSet});
            }
        });
        this.generateNumberPages();
        return this.paginationData(dataTabels, 2, 6);
    }

    get nameBulb() {
        return this.data.fotometria.nomeLampada;
    }


    generateNumberPages() {
        const elementsToPrint = this.elementRef.nativeElement.getElementsByClassName('printableArea');
        this.printPage.generateNumberPages(elementsToPrint);
    }

    getTableValueLuminance(risultato, vector, numeroDiColonneMassimo = 9) {
        const completeTable = [];
        const splitTable: any[] = [];
        let obj = {value0: '(m)'};
        let newObj = {};
        newObj['value0'] = '(m)';
        risultato.x_calc.forEach((x, index) => {
            if ((index + 1) % numeroDiColonneMassimo === 0) {
                splitTable.push([newObj]);
                newObj = {value0: '(m)'};
            }
            newObj['value' + index + 1] = x.toFixed(2);
            obj['value' + index + 1] = x.toFixed(2);
        });
        splitTable.push([newObj]);
        completeTable.push(obj);
        const y_calc = risultato.y_calc;
        const x_calc = risultato.x_calc;
        let numberTable = 0;
        for (let i = 0; i < y_calc.length; i++) {
            obj = {value0: y_calc[i].toFixed(2)};
            newObj = {value0: y_calc[i].toFixed(2)};
            numberTable = 0;
            for (let j = 0; j < x_calc.length; j++) {
                if ((j + 1) % numeroDiColonneMassimo === 0) {
                    splitTable[numberTable].push(newObj);
                    numberTable++;
                    newObj = {value0: y_calc[i].toFixed(2)};
                    newObj['value' + j + 1] = this.getvalueForMatrix(vector, i, j, y_calc.length).toString();
                }
                newObj['value' + j + 1] = this.getvalueForMatrix(vector, i, j, y_calc.length).toString();
                obj['value' + j + 1] = this.getvalueForMatrix(vector, i, j, y_calc.length).toString();
            }
            splitTable[numberTable].push(newObj);
            completeTable.push(obj);
        }
        return splitTable;
    }

    public getChar(index: number) {
        return (index >= 26 ? this.getChar((index / 26 >> 0) - 1) : '') + String.fromCharCode(65 + index % 26 >> 0);
    }

    getPagesDataTableLuminanzaOilluminamento(numeroMassimoDiColonne: number) {
        const tables = [];
        const osservatoreTrad = this.translate.instant('fotometria.osservatore') + ' ';
        const illuminanceTrad = this.translate.instant('fotometria.illuminance');
        const luminanzaTrad = this.translate.instant('fotometria.luminanza');
        this.data.risutatiCalcoloIlluminotecnico.risultati.forEach((risultato, indexRisultato) => {
            if (risultato.hasOwnProperty('x_calc') && risultato.hasOwnProperty('y_calc')) {
                if (Array.isArray(risultato.osservatori) && risultato.osservatori.length > 0) {
                    risultato.osservatori.forEach((osservatore, index) => {
                        const dataOsservatore = [{
                            'fotometria.tipo': osservatoreTrad + (index + 1),
                            'fotometria.x': Math.abs(Math.round(osservatore.x * 100) / 100),
                            'fotometria.y': Math.abs(Math.round(osservatore.y * 100) / 100),
                            'fotometria.z': Math.abs(Math.round(osservatore.z * 100) / 100)
                        }];
                        const vector = risultato.matriceValori[index];
                        const table = this.getTableValueLuminance(risultato, vector, numeroMassimoDiColonne);
                        table.forEach((data, index) => {
                            const part = (table.length > 1) ? '-' + this.getChar(index) : '';
                            tables.push({
                                dataTable: {
                                    title: luminanzaTrad,
                                    data: data,
                                    titlePage: this.getNameLayout(indexRisultato) + part
                                },
                                osservatore: (index === 0) ? {title: undefined, data: dataOsservatore} : undefined
                            });
                        });
                    });
                } else {
                    const vector = risultato.matriceValori[0];
                    const table = this.getTableValueLuminance(risultato, vector, numeroMassimoDiColonne);
                    table.forEach((data, index) => {
                        const part = (table.length > 1) ? '-' + this.getChar(index) : '';
                        tables.push({
                            dataTable: {
                                title: luminanzaTrad,
                                data: data,
                                titlePage: this.getNameLayout(indexRisultato) + part
                            },
                            osservatore: undefined
                        });
                    });
                }
            }
        });
        this.generateNumberPages();
        let i = 0;
        let numeroElementiPerPrimaPagina = 2;
        let numeroRighe = 0;
        while ((i < numeroElementiPerPrimaPagina) && i < tables.length) {
            numeroRighe += tables[i].dataTable.data.length + 1;
            numeroRighe += (isNotNullOrUndefined(tables[i].osservatore)) ? tables[i].osservatore.data.length + 1 : 0;
            i++;
        }
        numeroElementiPerPrimaPagina = (numeroRighe >= 17) ? 1 : 2;
        return this.paginationData(tables, numeroElementiPerPrimaPagina, numeroElementiPerPrimaPagina);
    }


    callPrint() {
        this.loaderPrint = true;
        this.disablePrint = true;
        setTimeout(() => {
            const labelsLampsImage = this.immagineConTitoliLampade.toArray();
            const allImages = [
                fromPromise(html2canvas(this.immaginePreview.nativeElement)),
                fromPromise(html2canvas(this.immagineResultP1.nativeElement, {
                    allowTaint: true,
                })),
                fromPromise(html2canvas(this.immagineResultP2.nativeElement, {
                    allowTaint: true,
                    backgroundColor: null
                })),
                fromPromise(html2canvas(this.immagineConTitoli.nativeElement, {
                    removeContainer: true,
                })),
                fromPromise(html2canvas(this.immagineDefinizioniStrada.nativeElement, {
                    removeContainer: true,
                })),
            ];
            labelsLampsImage.forEach((lampsLabel) => {
                allImages.push(
                    fromPromise(html2canvas(lampsLabel.nativeElement, {
                        removeContainer: true,
                        width: 700,
                    }))
                );
            });
            combineLatest(allImages).subscribe((images) => {
                //images[0] immagine da inserire in copertina
                //images[1] immagine finale senza la scala colori
                //images[2] immagine finale con la scala colori
                //images[3] immagine che mostra i layout
                //images[4] imaggine contenente le difinizioni della strada
                //images[5] imaggine contenente l etichette delle lampade
                //images[6] (opzionale in caso di strade molto lunghe) imaggine contenente l etichette delle lampade
                labelsLampsImage.forEach((lampsLabel) => {
                    lampsLabel.nativeElement.hidden = true;
                });
                if (this.data.organizzazione != null && this.data.organizzazione.logo != null && this.data.organizzazione.logo.url != null) {
                    const image = new Image();
                    image.src = this.data.organizzazione.logo.url();
                    this.printPage.organizzazioneLogo = image;
                }
                const doc = new jsPDF();
                const firstYavaiableInPage = this.printPage.headHeight;
                this.printPage.addFont(doc);
                const pageWidth = doc.internal.pageSize.getWidth();
                this.printPage.headHeight = 25;
                this.printPage.firstPage(doc, this.data.organizzazione, this.today);
                let title = this.translate.instant('lightingCalculation.calc');
                this.printPage.addH1(doc, title, 100, 80);
                this.printPage.addH2(doc, this.projectName, 100, 100);
                this.printPage.addH2(doc, this.data.strada.nome, 100, 120);
                let lastPosition;
                let ratio = images[0].width / images[0].height;
                lastPosition = this.printPage.addImage(doc, images[0].toDataURL('image/jpeg', 0.8), 180, 80, pageWidth / 2, 140, 'center start', ratio);
                doc.addPage();
                // first page
                title = this.translate.instant('fotometria.fotometry');
                lastPosition = this.printPage.addH2(doc, title, pageWidth / 2, firstYavaiableInPage + 10);
                ratio = images[4].width / images[4].height;
                let imageTemp = new Image();
                imageTemp.src = this.imageInForometry;
                lastPosition = this.printPage.addImage(doc, imageTemp.src, 40, 40, pageWidth / 2, lastPosition.lastY + 0, 'center start');
                this.printPage.addTable(doc, this.arrayTableFotometry, this.nameBulb, "vertical", lastPosition.lastY + 10);
                // second page
                doc.addPage();
                title = this.translate.instant('fotometria.geometryInformation.title');
                lastPosition = this.printPage.addH2(doc, title, pageWidth / 2, firstYavaiableInPage + 10);
                ratio = images[4].width / images[4].height;
                lastPosition = this.printPage.addImage(doc, images[4].toDataURL('image/jpeg', 0.8), 200, 230, pageWidth / 2, lastPosition.lastY + 0, 'center start', ratio);
                // third page
                doc.addPage();
                title = this.translate.instant('fotometria.geometryInformation.streetGeometry');
                lastPosition = this.printPage.addH2(doc, title, pageWidth / 2, firstYavaiableInPage + 10);
                title = this.translate.instant('fotometria.referencesStreetElements');
                lastPosition = this.printPage.addH3(doc, title, pageWidth / 2, lastPosition.lastY + 10);
                ratio = images[3].width / images[3].height;
                lastPosition = this.printPage.addImage(doc, images[3].toDataURL('image/jpeg', 0.8), 180, 60, pageWidth / 2, lastPosition.lastY, 'center start', ratio);
                title = this.translate.instant('fotometria.referencesIlluminatingElements');
                lastPosition = this.printPage.addH3(doc, title, pageWidth / 2, lastPosition.lastY + 10);
                ratio = images[5].width / images[5].height;
                const maxHeight = 60 * labelsLampsImage.length;
                const returned = 20 * (labelsLampsImage.length - 1);
                lastPosition = this.printPage.addImage(doc, images[5].toDataURL('image/jpeg', 0.8), 180, maxHeight, pageWidth / 2, lastPosition.lastY - returned, 'center start', ratio);
                if (isNotNullOrUndefined(images[6]) && labelsLampsImage.length > 1) {
                    ratio = images[6].width / images[6].height;
                    lastPosition = this.printPage.addImage(doc, images[6].toDataURL('image/jpeg', 0.8), 180, maxHeight, pageWidth / 2, lastPosition.lastY - returned, 'center start', ratio);
                    doc.addPage();
                    title = this.translate.instant('fotometria.parametriStrada');
                    lastPosition = this.printPage.addTable(doc, this.parametriStrada, title, "vertical", firstYavaiableInPage + 10);
                } else {
                    title = this.translate.instant('fotometria.parametriStrada');
                    this.printPage.addTable(doc, this.parametriStrada, title, "vertical", lastPosition.lastY + 20);
                    doc.addPage();
                    lastPosition = {lastY: firstYavaiableInPage, lastX: 0};
                }
                title = this.translate.instant('fotometria.geometryInformation.streetGeometry');
                lastPosition = this.printPage.addH2(doc, title, pageWidth / 2, lastPosition.lastY + 10);
                lastPosition = this.printPage.addTable(doc, this.dataTableBulbsDescription, title, "horizzontal", lastPosition.lastY + 20);
                lastPosition = this.printPage.addTable(doc, this.dataForSteet, title, "horizzontal", lastPosition.lastY + 20);
                doc.addPage();
                title = this.translate.instant('fotometria.result');
                lastPosition = this.printPage.addH2(doc, title, pageWidth / 2, firstYavaiableInPage + 10);
                ratio = images[1].width / images[1].height;
                const ratioTwoImage = [ratio];
                const widthResultImage = 180;
                const heigthResultImage = 180 * (1 / ratio);
                ratio = images[2].width / images[2].height;
                ratioTwoImage.push(ratio);
                lastPosition = this.printPage.addTwoCenteredImage(doc, [images[1].toDataURL('image/jpeg', 0.8), images[2].toDataURL()], pageWidth / 2, lastPosition.lastY + heigthResultImage / 2, ratioTwoImage);
                title = this.translate.instant('lightingCalculation.calc');
                this.pagesResultsTablesJsPDF.forEach(tables => {
                    tables.forEach((table) => {
                        lastPosition = this.printPage.addTable(doc, table.dataSet, table.name, "vertical", lastPosition.lastY + 20);
                    });
                    doc.addPage();
                    lastPosition = {lastY: firstYavaiableInPage, lastX: 0}
                });
                const tabelleIlluminanzaOilluminamentoPaginate = this.getPagesDataTableLuminanzaOilluminamento(11);
                tabelleIlluminanzaOilluminamentoPaginate.forEach((tables, index) => {
                    tables.forEach((table) => {
                        lastPosition = this.printPage.addH3(doc, table.dataTable.titlePage, pageWidth / 2, lastPosition.lastY + 20);
                        if (table.osservatore) {
                            lastPosition = this.printPage.addTable(doc, table.osservatore.data, '', "horizzontal", lastPosition.lastY + 5);
                        }
                        lastPosition = this.printPage.addTable(doc, table.dataTable.data, table.dataTable.title, "vertical", lastPosition.lastY + 5, "auto", true);
                    });
                    if (index !== tabelleIlluminanzaOilluminamentoPaginate.length - 1) {
                        doc.addPage();
                    }
                    lastPosition = {lastY: firstYavaiableInPage, lastX: 0};
                });
                const today = formatDate(this.today, 'shortDate', this.translate.currentLang);
                this.printPage.addHeader(doc, this.data.organizzazione, this.projectName, today);
                this.printPage.convertToPdf(doc, title + ' ' + this.projectName);
                this.disablePrint = false;
                this.loaderPrint = false;
            });
        }, 500);

    }

// calcola risuluzione dell imaggine in base alla sua lunghezza
    get resolutionLightCalc() {
        if (this.data.strada.interdistanza > 150) {
            return 2;
        } else if (this.data.strada.interdistanza > 75) {
            return 4;
        } else if (this.data.strada.interdistanza > 37) {
            return 5;
        } else {
            return 6;
        }
    }

    get splitLamps(): { lampade: any[], interdistanza: number, distanceInizialPosition: number, generateMisureInizialAndFinal: boolean }[] {
        if (this.data.strada.interdistanza < 150) {
            return [{
                lampade: this.data.strada.lampade,
                interdistanza: this.data.strada.interdistanza,
                distanceInizialPosition: 0,
                generateMisureInizialAndFinal: false
            }];
        } else {
            const width = this.data.strada.interdistanza / 2;
            const lampade1 = [];
            const lampade2 = [];
            const tolleranza = 10;
            this.data.strada.lampade.forEach((lampada, index) => {
                lampada.name = index + 1;
                if (lampada.origine_x <= width) {
                    lampade1.push(lampada);
                } else if (lampada.origine_x <= width + tolleranza) {
                    lampade1.push(lampada);
                    const newLampada = {...lampada};
                    newLampada.origine_x = newLampada.origine_x - width;
                    lampade2.push(newLampada);
                } else {
                    const newLampada = {...lampada};
                    newLampada.origine_x = newLampada.origine_x - width;
                    lampade2.push(newLampada);
                }
            });
            return [
                {
                    lampade: lampade1,
                    interdistanza: width + 10,
                    distanceInizialPosition: 0,
                    generateMisureInizialAndFinal: true
                },
                {
                    lampade: lampade2,
                    interdistanza: width + 10,
                    generateMisureInizialAndFinal: true,
                    distanceInizialPosition: width
                }
            ];
        }

    }


    getLogoUrl() {
        if (this.data != null && this.data.organizzazione != null && this.data.organizzazione.logo != null && this.data.organizzazione.logo.url != null && this.data.organizzazione.logo.url()) {
            return this.data.organizzazione.logo.url()
        } else {
            return undefined
        }
    }

    getOrganizzazioneName() {
        if (this.data != null && this.data.organizzazione != null && this.data.organizzazione.nome != null) {
            return this.data.organizzazione.nome;
        } else {
            return undefined
        }
    }

    openInfo() {
        this.dialogInfo.openInfoPrintStreet();
    }

}




