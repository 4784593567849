import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef
} from "@angular/material/legacy-dialog";
import {PuntiLuceParse} from "../../../models/PuntiLuce.Parse";
import {
    arrayIsSet, checkDiff,
    cleanToSpecialCharacterAndLowerCaseString, daysInWeek,
    isNotNullOrUndefined,
    KeyStringValueString
} from "../../../models/Models";
import {TranslateService} from "@ngx-translate/core";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {
    valueActionClickButton,
    ValueActionClickButtonType
} from "../../virtual-mid-night/form-light-mate/form-light-mate.component";
import {Observable, Subscription} from "rxjs";
import {AlertService} from "../../../providers/services/alert.service";
import {BreakPointType} from "../../../widgets/split-screen/split-screen.component";
import {IoTDevicesParse} from "../../../models/IoTDevices.Parse";
import {TLC_ProfiloFunzionamentoParse} from "../../../models/TLC_ProfiloFunzionamento.Parse";
import {TypeVersioneProtocolloLM} from "../../../providers/services/remote-control.service";
import {SettaggiTlcNodoParse} from "../../../models/SettaggiTlcNodo.Parse";
import {CircuitiParse} from "../../../models/Circuiti.Parse";
import {map, startWith} from "rxjs/operators";

@Component({
    selector: 'app-batch-virtual-midnight',
    templateUrl: './batch-virtual-midnight.component.html',
    styleUrls: ['./batch-virtual-midnight.component.scss']
})
export class BatchVirtualMidnightComponent implements OnInit, OnDestroy {

    private subscriptions = new Subscription();
    showSearchWrong = false
    yesGroupId = true
    showTable = true

    get lightPoints(): PuntiLuceParse[] {
        return this.data.lightPoints
    }

    get circuits(): CircuitiParse[] {
        return this.data.circuits
    }

    breakPoint: BreakPointType[] = [
        {
            maxWidth: 1000,
            sx: {percent: 80, px: 470},
            dx: {percent: undefined, px: undefined},
        }, {
            maxWidth: 800,
            sx: {percent: 50, px: undefined},
            dx: {percent: 50, px: undefined}
        }, {
            maxWidth: 600,
            sx: {percent: 100, px: undefined},
            dx: {percent: 100, px: undefined}
        }
    ]
    public displayedColumns = [];
    public displayedColumnsTraduction: KeyStringValueString;
    public isPossibleValues = [];
    public typeArray = [];
    public dataSource = [];
    titleTraduction
    isSetDataSource
    public formGroup: UntypedFormGroup;
    typeForm;
    profilesTlc: TLC_ProfiloFunzionamentoParse[];
    typeVersioneProtocolloLM = TypeVersioneProtocolloLM
    profileFunctionForDays: { day: number, name?: string, traduction?: string }[] = [];
    profileFunctionForDates: { date: Date, name?: string, traduction?: string, objectId: string }[] = [];
    destroyData$: Observable<string[]>
    destroyDataLength$: Observable<number>

    get destroyData(): string[] {
        return this.formGroup.get('destroyData').value
    }

    set destroyData(value) {
        this.formGroup.get('destroyData').setValue(value)
    }

    constructor(public dialogRef: MatDialogRef<BatchVirtualMidnightComponent>,
                @Inject(MAT_DIALOG_DATA) public data: {
                    lightPoints: PuntiLuceParse[],
                    circuits: CircuitiParse[],
                    iotDevices: IoTDevicesParse[],
                    profilesTlc: TLC_ProfiloFunzionamentoParse[],
                    settaggiTlcNodo: SettaggiTlcNodoParse[]
                },
                private translateService: TranslateService,
                private alertService: AlertService,
                private fb: UntypedFormBuilder) {
        this.isSetDataSource = arrayIsSet(this.data.lightPoints)
        this.formGroup = fb.group({
            virtualMidnightValues: undefined,
            newGroupId: undefined,
            destroyData: undefined
        })
        this.destroyData$ = this.formGroup.get('destroyData').valueChanges.pipe(
            startWith(undefined),
            map(() => arrayIsSet(this.destroyData) ? this.destroyData : [])
        )
        this.destroyDataLength$ = this.destroyData$.pipe(
            map(destroy => destroy && destroy.length)
        )
        if (this.data && arrayIsSet(this.data.iotDevices) && this.data.iotDevices[0].versioneProtocolloLM === TypeVersioneProtocolloLM.prt_v1) {
            this.typeForm = TypeVersioneProtocolloLM.prt_v1;
            this.breakPoint = [
                {
                    maxWidth: 1301,
                    sx: {percent: 50, px: undefined},
                    dx: {percent: undefined, px: undefined},
                }, {
                    maxWidth: 1300,
                    sx: {percent: 100, px: undefined},
                    dx: {percent: 100, px: undefined}
                }
            ]
        }

        if (arrayIsSet(this.data.settaggiTlcNodo)) {
            let i = 0;
            while (i < 7) {
                const obj = {day: i, name: undefined, traduction: undefined}
                const settaggiForDate = this.data.settaggiTlcNodo.filter(s => s.giornoSettimana == i);
                if (arrayIsSet(settaggiForDate)) {
                    const profilesIdInDate = settaggiForDate.map(settaggio => settaggio.tlcProfiloFunzionamento && settaggio.tlcProfiloFunzionamento.objectId);
                    if (!checkDiff(profilesIdInDate)) {
                        const idLightPointsInSettaggi = settaggiForDate.map(s => s.puntoLuce && s.puntoLuce.objectId);
                        const isDifferent = this.lightPoints.some(p => !idLightPointsInSettaggi.includes(p.objectId));
                        if (isDifferent) {
                            obj.traduction = 'pageProfile.profileDifferent';
                        } else {
                            obj.name = settaggiForDate[0].tlcProfiloFunzionamento.name
                        }
                    } else {
                        obj.traduction = 'pageProfile.profileDifferent';
                    }
                } else {
                    obj.name = '100%'
                }
                this.profileFunctionForDays.push(obj)
                i++;
            }

            this.profileFunctionForDates = this.data.settaggiTlcNodo.reduce((profileFunctionForDates, settaggio) => {
                if (settaggio.data != null && settaggio.tlcProfiloFunzionamento != null) {
                    const today = new Date()
                    today.setHours(0, 0, 0, 0)
                    if (settaggio.repeatYear || (settaggio.data.getTime() > today.getTime())) {
                        const index = profileFunctionForDates.findIndex(sett => this.datesIsEquals(settaggio.data, sett.data))
                        if (index < 0) {
                            profileFunctionForDates.push({
                                objectId: settaggio.objectId,
                                date: settaggio.data,
                                name: settaggio.tlcProfiloFunzionamento.name,
                                traduction: 'pageProfile.profilo_funzionamento'
                            })
                        }

                    }
                }
                return profileFunctionForDates
            }, [])
            this.profileFunctionForDates.sort((a, b) => {
                const dateA = a.date.getTime();
                const dateB = b.date.getTime();
                return dateA - dateB
            })
            this.profileFunctionForDays.sort((a, b) => {
                const dayA = a.day
                const dayB = b.day
                let moveToSundayToLastA = dayA;
                if (moveToSundayToLastA == daysInWeek.sunday) {
                    moveToSundayToLastA = daysInWeek.saturday + 1
                }
                let moveToSundayToLastB = dayB;
                if (moveToSundayToLastB == daysInWeek.sunday) {
                    moveToSundayToLastB = daysInWeek.saturday + 1
                }
                return moveToSundayToLastA - moveToSundayToLastB;
            })
        }
        if (this.data && arrayIsSet(this.data.profilesTlc)) {
            this.profilesTlc = this.data.profilesTlc;
        }

        this.subscriptions.add(
            this.formGroup.get('virtualMidnightValues').valueChanges.subscribe(
                values => {
                    this.sendValue()
                }
            ))
    }

    datesIsEquals(date1: Date, date2: Date) {
        if (date1 != null && date2 != null) {
            const _date1 = new Date(date1)
            _date1.setHours(0, 0, 0, 0)
            const _date2 = new Date(date2)
            _date2.setHours(0, 0, 0, 0)
            return _date1.getTime() === _date2.getTime()
        } else {
            return false
        }
    }

    ngOnInit(): void {
        if (arrayIsSet(this.lightPoints)) {
            this.typeArray = ['schedeManutenzione']
            this.displayedColumns = ['circuito', 'targa', 'indirizzo', 'idGruppoLightMate', 'idMezzanotte'];
            this.displayedColumnsTraduction = {};
            this.dataSource = this.lightPoints
                .sort((prev, current) => {
                    const numeroTargaPrev = prev.circuito != null ? cleanToSpecialCharacterAndLowerCaseString(prev.circuito.numeroTarga) : '_No_Circuit_'
                    const numeroTargaCurrent = current.circuito != null ? cleanToSpecialCharacterAndLowerCaseString(current.circuito.numeroTarga) : '_No_Circuit_'
                    if (numeroTargaPrev == numeroTargaCurrent) {
                        const numeroIdGruppoLightMatePrev = current.idGruppoLightMate != null ? current.idGruppoLightMate : -100
                        const numeroIdGruppoLightMateCurrent = prev.idGruppoLightMate != null ? prev.idGruppoLightMate : -100
                        if (numeroIdGruppoLightMatePrev == numeroIdGruppoLightMateCurrent) {
                            const idLightMatePrev = current.idMezzanotte != null ? current.idMezzanotte : -100
                            const idLightMateCurrent = prev.idMezzanotte != null ? prev.idMezzanotte : -100
                            return idLightMatePrev > idLightMateCurrent;
                        }
                        // Price is only important when cities are the same
                        return numeroIdGruppoLightMatePrev > numeroIdGruppoLightMateCurrent
                    }
                    return numeroTargaPrev.localeCompare(numeroTargaCurrent);
                })
                .map(lightPoints => {
                    const obj = {};
                    this.displayedColumns.forEach(key => {
                        if (key == 'circuito') {
                            obj[key] = this.getValueCircuito(lightPoints[key])
                        } else {
                            obj[key] = this.getValue(lightPoints, key)
                        }
                    })
                    obj['isWarn'] = lightPoints.circuito == null || lightPoints.idMezzanotte == null;
                    return obj;
                });
            this.displayedColumnsTraduction = this.displayedColumns.reduce((prev, current) => {
                prev[current] = this.getPathTraductionWithKey(current)
                return prev;
            }, {})
            this.titleTraduction = 'PuntiLuce'
            this.showSearchWrong = false;
            this.showTable = true;
            this.yesGroupId = true;
        } else if (arrayIsSet(this.circuits)) {
            this.showSearchWrong = true;
            this.showTable = false;
            this.yesGroupId = false;
        }

    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    getPathTraductionWithKey(key) {
        return 'dashboard_sidenav.PuntiLuce.' + key + '.title'
    }

    getPathTraductionWithClassName() {
        return 'dashboard_sidenav.PuntiLuce.'
    }

    getValue(value, key) {
        if (this.isPossibleValues.includes(key)) {
            const translate = this.translateService.instant(this.getPathTraductionWithClassName() + key + '.possibleValues.' + value[key]);
            return translate.includes(this.getPathTraductionWithClassName()) ? value[key] : translate;
        } else if (this.typeArray.includes(key)) {
            return arrayIsSet(value[key]) ? value[key].length : 0
        } else {
            const translate = this.translateService.instant('NaN');
            return isNotNullOrUndefined(value[key]) ? value[key] : translate;
        }
    }

    getValueCircuito(value) {
        const translate = this.translateService.instant('filterPage.nessunQuadro')
        return isNotNullOrUndefined(value) && isNotNullOrUndefined(value.numeroQuadro) ? value.numeroQuadro : translate;
    }

    sendValue() {
        const values = this.formGroup.get('virtualMidnightValues').value;
        if (arrayIsSet(this.lightPoints)) {
            if (values && values.versioneProtocollo === this.typeVersioneProtocolloLM.prt_v1) {
                this.dialogRef.close({
                    lightPoints: this.lightPoints,
                    ...values
                })
            } else {
                const lightPointsToSave = this.lightPoints.filter(lightPoint => lightPoint.circuito != null && lightPoint.idMezzanotte != null)
                let programmiMvValues = [{name: 'linee', value: [1, 2, 3, 4]}]
                if (arrayIsSet(this.formGroup.get('virtualMidnightValues').value)) {
                    programmiMvValues = programmiMvValues.concat(this.formGroup.get('virtualMidnightValues').value)
                }
                if (this.formGroup.get('newGroupId').value != null) {
                    programmiMvValues.push({name: 'newGroupId', value: this.formGroup.get('newGroupId').value})
                }
                if (arrayIsSet(lightPointsToSave)) {
                    this.dialogRef.close({
                        lightPoints: lightPointsToSave,
                        programmiMvValues,
                    });
                } else {
                    const message = this.translateService.instant('lightMate.savedSuccessufully');
                    this.alertService.warning(message);
                }
            }
        } else if (arrayIsSet(this.circuits) && arrayIsSet(values)) {
            if (arrayIsSet(values)) {
                let programmiMvValues = [{name: 'linee', value: [1, 2, 3, 4]}, ...values]
                this.dialogRef.close({
                    ciruits: this.circuits,
                    programmiMvValues,
                })
            } else {
                this.alertService.warning(this.translateService.instant('notFieldIsUpdated'))
            }

        }

    }

    setValuesVirtualMidnight(values) {
        if (values.versioneProtocollo === this.typeVersioneProtocolloLM.prt_v1) {
            this.formGroup.get('virtualMidnightValues').setValue(values);
        } else {
            this.formGroup.get('virtualMidnightValues').setValue(
                values
                    .filter(v => !v.name.includes('deviceId') && !v.name.includes('linee'))
            )
        }
    }

    setValuesClickAction(values: { key: ValueActionClickButtonType, }) {
        if (values.key === valueActionClickButton.searchBrokenLamp) {
            this.formGroup.get('virtualMidnightValues').setValue([{
                name: 'modalitaManutenzione',
                value: true
            }])
        } else if (values.key === valueActionClickButton.resetFactoryDevice) {
            this.formGroup.get('virtualMidnightValues').setValue([{
                name: 'resetToDefault',
                value: true
            }])
        }
    }

    addRemoveDestroySetting(objectId) {
        const destroyData = arrayIsSet(this.destroyData) ? [...this.destroyData] : []
        const index = destroyData.indexOf(objectId)
        if (index < 0) {
            destroyData.push(objectId)
        } else {
            destroyData.splice(index, 1)
        }
        this.destroyData = destroyData
    }

    getIconDestroy(destroyData: string[], objectId: string) {
        if (destroyData && destroyData.includes(objectId)) {
            return {name: 'delete_forever', color: 'accent'}
        } else {
            return {name: 'delete', color: 'warn'}
        }
    }

    removeDataSetting() {
        this.dialogRef.close({
            removeDatesSettings: this.destroyData
        });
    }

}
