import { Component, OnInit, Input } from '@angular/core';
import { Photometry } from 'src/app/providers/services/streets.service';

@Component({
  selector: 'app-photometry',
  templateUrl: './photometry.component.html',
  styleUrls: ['./photometry.component.scss']
})
export class PhotometryComponent implements OnInit {

  @Input()
  photometry: Photometry;

  normalizedPhotometry: any;

  constructor() {
    
  }

  ngOnInit() {
    function normalizeNumber(n?: number) {
      return n === undefined ? 'N/A' : n.toFixed(2);
    }
    this.normalizedPhotometry = {
      produttore: this.photometry.produttore || 'N/A',
      nomeFamiglia: this.photometry.nomeFamiglia || 'N/A',
      nomeProdotto: this.photometry.nomeProdotto || 'N/A',
      nomeLampada: this.photometry.nomeLampada || 'N/A',
      ottica: this.photometry.ottica || 'N/A',
      potenzaMin: normalizeNumber(this.photometry.potenzaMin),
      potenzaMax: normalizeNumber(this.photometry.potenzaMax),
      flussoMin: normalizeNumber(this.photometry.flussoMin),
      flussoMax: normalizeNumber(this.photometry.flussoMax),
      efficienzaMin: normalizeNumber(this.photometry.efficienzaMin),
      efficienzaMax: normalizeNumber(this.photometry.efficienzaMax),
      temperaturaColore: normalizeNumber(this.photometry.temperaturaColore),
      CRI: this.photometry.CRI || 'N/A',
      nomeFile: this.photometry.nomeFile || 'N/A',
    };
  }

}
