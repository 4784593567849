import {Pipe, PipeTransform} from '@angular/core';
import {RolesService} from '../services/roles.service';

@Pipe({
    name: 'roles'
})
export class RolesPipe implements PipeTransform {

    constructor(private rolesService: RolesService) {
    }

    transform(rolesActive: string[], roles: string[]): any {
        if (rolesActive) {
            return this.rolesService.checkRoles(rolesActive, roles);
        }
        return false;
    }

}
