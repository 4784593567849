<app-template-form subtitle="{{'create_group' | translate}}" title="{{'group' | translate}}" [informativa]="false"
                   *ngIf="canICreatePlan">
    <form *ngIf="planForm" [formGroup]="planForm">
        <mat-form-field floatLabel="auto" appearance="fill">
            <mat-label>{{ 'name' | translate}}</mat-label>
            <input formControlName="nome" matInput required>
        </mat-form-field>
        <div class="spacer">
            <button matTooltip="Salva" mat-flat-button color="primary" class="" (click)="createGroup()">{{ 'create' |
                translate}}
            </button>
        </div>
    </form>
</app-template-form>
<app-template-form *ngIf="!canICreatePlan" subtitle="{{'create_group' | translate}}" title="{{'group' | translate}}"
                   [informativa]="false">
    <div mat-card-content>
        <div>
            {{'create_group_error' | translate}}
        </div>
    </div>
</app-template-form>
