import {Injectable} from '@angular/core';
import {ProjectService} from "./project.service";
import * as Parse from 'parse';
import {SegnaliTlcNodoChangesHistoryParse} from "../../models/SegnaliTlcNodoChangesHistory.Parse";
import {PuntiLuceParse} from "../../models/PuntiLuce.Parse";
import {fromPromise} from "rxjs/internal-compatibility";
import {Observable} from "rxjs";
import {arrayIsSet, paramsApiParse_v2} from "../../models/Models";
import {map} from "rxjs/operators";

export type HistoryLightPointsType = {
    puntoLuce: {
        objectId: string,
        targa?: string,
        attivo?: boolean,
        potenza?: number
    },
    circuito?: { objectId: string, numeroQuadro: string };
    historyLightPoint: {
        objectId: string,
        updatedAt: Date,
        lightMateId: number,
        lampadaFunzionante?: boolean,
        fase?: number,
    }[]
}

@Injectable({
    providedIn: 'root'
})
export class SegnaliTlcNodoChangesHistoryService {

    constructor(private projectService: ProjectService) {
    }

    getHistoryOfLightPoint(puntoLuceId): Observable<SegnaliTlcNodoChangesHistoryParse[]> {
        const params = paramsApiParse_v2({puntoLuceId});
        return fromPromise(Parse.Cloud.run('getHistoryLightPoint', params)).pipe(
            map(history => {
                if(arrayIsSet(history)){
                    return history.map(segnale=>{
                        const segnaleParse=new SegnaliTlcNodoChangesHistoryParse()
                        segnaleParse.objectId=segnale.id
                        return  segnaleParse
                    })
                }
                return history;
            })
        );
    }

    getHistoryLightPointsByCircuitsOrProjects(circuitoIds: string[], progettoId: string = undefined): Observable<HistoryLightPointsType[]> {
        const params = paramsApiParse_v2({circuitoIds, progettoId});
        return fromPromise(Parse.Cloud.run('getHistoryLightPointsByCircuitsOrProjects', params));
    }

}

