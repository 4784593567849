import {Component, Input, OnInit} from '@angular/core';
import {formFiledMaintenance, typeFormFieldMaintenance} from "../../providers/services/maintenance.service";
import {customThemeNgxDataPicker} from "../../models/Models";

@Component({
    selector: 'app-visulized-type-form',
    templateUrl: './visulized-type-form.component.html',
    styleUrls: ['./visulized-type-form.component.scss']
})
export class VisulizedTypeFormComponent implements OnInit {

    @Input() field: formFiledMaintenance;
    typeFormField = typeFormFieldMaintenance
    click = {yes: false, not: false}
    expandNote = false;
    public customThemNgxDataPicker = customThemeNgxDataPicker;

    constructor() {
    }

    ngOnInit(): void {
    }


    getAutoComplete(values: string[]) {
        return values.map(value => {
            return {html: value, valueForm: value}
        })
    }

    getFieldName(field: formFiledMaintenance) {
        let name = field.traduction;
        if (field.required) {
            name += ' *'
        }
        return name
    }

    getNote(field: formFiledMaintenance) {
        let note = field.note;
        return note
    }


    setValueYesOrNotButton(key: 'yes' | 'not', value: boolean) {
        Object.keys(this.click).filter(keyClick => keyClick != key).forEach(keyClick => this.click[keyClick] = false)
        this.click[key] = value;
    }

    getColorYesOrNotButton(key: 'yes' | 'not') {
        return this.click[key] ? 'primary' : 'accent';
    }

    expandMoreLessNote() {
        this.expandNote = !this.expandNote
    }





}
