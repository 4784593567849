<div class="container-otherForm" *ngIf="!(loadingAll);else LoadingComando">
    <ng-container *ngIf="showDetailLastComand">
        <div class="container-grid">
            <div class="items-text-100">
                <strong> {{'lightMate.actualComandState'|translate}}</strong>
            </div>
            <div class="flex-center-width100 flex-flow-column">
                <div class="flex-center-Between" style="width: 300px">
                    <button class="custom-button"
                            [disabled]="lastProgrammiMVParse==null||lastProgrammiMVParse.length==0"
                            (click)="moveUltimoComandoUp()"
                            mat-flat-button color="primary"
                    >
                        <mat-icon>keyboard_arrow_left</mat-icon>
                    </button>
                    <div style="width: 100%;display: flex;justify-content: center"
                         *ngIf="selectedProgrammaMV$|async as selectedProgrammaMV">
                        <mat-card [matMenuTriggerFor]="dates"
                                  style="padding: 11px!important;cursor: pointer"
                                  [ngClass]="{'background-last-date':(selectedProgrammaMVNotIsLast$|async)}">
                            <h4 style="width: 100%;" [ngStyle]="{'text-align': 'center'}">
                                {{selectedProgrammaMV.updatedAt |customDate :'medium'|async}}
                            </h4>
                        </mat-card>
                    </div>
                    <button class="custom-button"
                            [disabled]="lastProgrammiMVParse==null||lastProgrammiMVParse.length==0"
                            (click)="moveUltimoComandoDown()"
                            color="primary"
                            mat-flat-button
                    >
                        <mat-icon>keyboard_arrow_right</mat-icon>
                    </button>
                </div>
                <ng-container>
                    <ng-container *ngIf="selectedProgrammaMV$|async as selectedProgrammaMV">
                        <div class="w100 flex-around-center flex-flow-row">
                            <div class="size-sx">
                                <p>{{'lightMate.comandDuration'|translate}}</p>
                            </div>
                            <div class="size-dx text-center">
                                {{selectedProgrammaMV.durataComando|convertSecInhms}}
                            </div>
                        </div>
                        <div class="w100 flex-around-center flex-flow-row" *ngIf="selectedProgrammaMV.state">
                            <div class="size-sx">
                                <p>{{selectedProgrammaMV.state.message|translate}}</p>
                            </div>
                            <div class="size-dx text-center flex-around-center">
                                <div class="items-text-circle-20"
                                     [ngStyle]="{background:selectedProgrammaMV.state.color}">
                                </div>
                            </div>
                        </div>
                        <div class="w100 flex-around-center flex-flow-row" *ngIf="selectedProgrammaMV.state">
                            <div class="size-sx">
                                <p>{{'lightMate.update'|translate}}</p>
                            </div>
                            <div class="size-dx text-center flex-around-center">
                                <button (click)="clickUpdateProgram()" mat-icon-button color="primary">
                                    <mat-icon>cached</mat-icon>
                                </button>
                            </div>
                        </div>
                        <mat-grid-list cols="12" rowHeight="40px" class="huna-material-class w100">
                            <ng-container
                                    *ngFor="let tile of selectedProgrammaMV|convertValueByPredicateFunction:createTilesComandPredicateFunction:protocolloLMisRM_V1:anticonvertHourTODecimalHour">
                                <mat-grid-tile
                                        [colspan]="tile.cols"
                                        [rowspan]="tile.rows"
                                >
                                    <ng-container *ngIf="!tile.isButtonCopy;else ButtonCopy">
                                        <div style="width: 100%;" [ngStyle]="{'text-align': tile.textAlign}">
                                            {{tile.text |translate}}
                                        </div>
                                    </ng-container>
                                    <ng-template #ButtonCopy>
                                        <button mat-icon-button color="accent"
                                                (click)="$event.stopPropagation();"
                                                appCopyPaste
                                                key="virtualMidnightPower"
                                                [copyValue]="selectedProgrammaMV|convertValueByPredicateFunction:getCopyValue:anticonvertHourTODecimalHour">
                                            <mat-icon>content_copy</mat-icon>
                                        </button>
                                    </ng-template>
                                </mat-grid-tile>
                            </ng-container>
                        </mat-grid-list>
                        <div class="w100 flex-around-center flex-flow-row">
                            <p style="font-size: .9em; color: rgba(0,0,0,.64);">{{'lightMate.functionProfileTimesInfo'|translate}}</p>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </ng-container>
    <ng-container [ngSwitch]="typeForm">
        <ng-container *ngSwitchCase="typeVersioneProtocolloLM.prt_v1">
            <form [formGroup]="form" class="w100">
                <mat-card class="container-card-profile" formGroupName="protocolloPRT">
                    <div class="w100 flex-around-center flex-flow-row">
                        <div class="width-profile">
                            <h5>{{'pageProfile.profiles'|translate}}</h5>
                            <mat-error *ngIf="errorProfiles$|async"
                                       style="margin: -20px 0 20px 0">{{'pageProfile.profileRequired'|translate}}
                            </mat-error>
                            <mat-radio-group formControlName="profileTlc">
                                <mat-accordion>
                                    <app-box-profilo-funzionamento [profiloFunzionamento]="profile"
                                                                   *ngFor="let profile of profilesTlc"
                                    >
                                        <ng-container titleDescription>
                                            <mat-radio-button [value]="profile.objectId"
                                                              (click)="$event.stopPropagation()"
                                                              style="margin:0 5px">
                                            </mat-radio-button>
                                        </ng-container>
                                    </app-box-profilo-funzionamento>
                                </mat-accordion>
                            </mat-radio-group>
                        </div>
                        <mat-card class="width-week">
                            <mat-button-toggle-group class="accent" formControlName="typePeriod">
                                <mat-button-toggle
                                        [value]="typesPeriod.scheduleWeek">{{'pageProfile.scheduleWeek' | translate}}
                                </mat-button-toggle>
                                <mat-button-toggle
                                        [value]='typesPeriod.schedulePeriod'>{{'pageProfile.schedulePeriod' | translate}}
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                            <ng-container [ngSwitch]="typePeriod$|async">
                                <ng-container *ngSwitchCase="typesPeriod.schedulePeriod">
                                    <h5>{{'pageProfile.setPeriod'|translate}}</h5>
                                    <mat-error *ngIf="errorWeeks$|async"
                                               style="margin: -20px 0 20px 0">{{'pageProfile.weekRequired'|translate}}
                                    </mat-error>
                                    <mat-form-field>
                                        <mat-date-range-input
                                                [min]="today"
                                                [rangePicker]="campaignOnePicker">
                                            <input matStartDate formControlName="fromDate">
                                            <input matEndDate formControlName="toDate">
                                        </mat-date-range-input>
                                        <mat-datepicker-toggle matSuffix
                                                               [for]="campaignOnePicker"></mat-datepicker-toggle>
                                        <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
                                    </mat-form-field>
                                    <mat-checkbox formControlName="repeatYear">{{'pageProfile.repeatYear'|translate}}</mat-checkbox>
                                </ng-container>
                                <ng-container *ngSwitchCase="typesPeriod.scheduleWeek">
                                    <h5>{{'pageProfile.setWeek'|translate}}</h5>
                                    <mat-error *ngIf="errorWeeks$|async"
                                               style="margin: -20px 0 20px 0">{{'pageProfile.weekRequired'|translate}}
                                    </mat-error>
                                    <mat-selection-list style="padding-top: 0" formControlName="days">
                                        <mat-list-option *ngFor="let date of [1,2,3,4,5,6,0]" [value]="date">
                                            {{('week.' + date)|translate}}
                                        </mat-list-option>
                                    </mat-selection-list>
                                </ng-container>
                            </ng-container>
                        </mat-card>
                    </div>
                    <mat-card-actions align="end">
                        <button mat-flat-button color="primary" (click)="saveFunctionProfilePRT()"
                                [disabled]="(errorProfiles$|async)||(errorWeeks$|async)">
                            <mat-icon>send</mat-icon>
                        </button>
                    </mat-card-actions>
                </mat-card>
            </form>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <div *ngIf="showLine">
                <div class="w100 flex-around-center flex-flow-row">
                    <form [formGroup]="form">
                        <div class="container-otherForm">
                            <mat-form-field appearance="fill" style="width: 400px;max-width: 100%"
                                            *ngIf="(!yesGroupId)||protocolloLMisRM_V1">
                                <mat-label>
                                    <ng-container *ngIf="yesGroupId;else deviceId">
                                        {{'lightMate.selectGroupId' | translate}}
                                    </ng-container>
                                    <ng-template #deviceId>
                                        {{'lightMate.selectDeviceId' | translate}}
                                    </ng-template>
                                </mat-label>
                                <mat-select formControlName="groupId">
                                    <mat-option [value]="0">{{'lightMate.all'|translate}}</mat-option>
                                    <mat-option [value]="value"
                                                *ngFor="let value of groupIdPossibleValues">{{value}}</mat-option>
                                </mat-select>
                                <mat-error>campo obbligatorio</mat-error>
                            </mat-form-field>
                            <div class="container-linee" *ngIf="!protocolloLMisRM_V1">
                                <div class="items-text-100">
                                    {{'lightMate.selectLine'|translate}}
                                </div>
                                <div class="items-linee items-width-25">
                                    <mat-slide-toggle color="primary"
                                                      formControlName="linea1">{{'lightMate.line1' | translate}}</mat-slide-toggle>
                                </div>
                                <div class="items-linee items-width-25">
                                    <mat-slide-toggle color="primary"
                                                      formControlName="linea2">{{'lightMate.line2' | translate}}</mat-slide-toggle>
                                </div>
                                <div class="items-linee items-width-25">
                                    <mat-slide-toggle color="primary"
                                                      formControlName="linea3">{{'lightMate.line3' | translate}}</mat-slide-toggle>
                                </div>
                                <div class="items-linee items-width-25">
                                    <mat-slide-toggle color="primary"
                                                      formControlName="linea4">{{'lightMate.line4' | translate}}</mat-slide-toggle>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="container-linee">
                <ng-container *ngIf="!protocolloLMisRM_V1&&showSearchWrong">
                    <div class="items-text-60">
                        {{'lightMate.searchWrongLight'|translate}}
                    </div>
                    <div class="items-linee items-width-60">
                        <app-button-spinner [loading]="loadingSearchWrong" type="mat-raised-button">
                            <button (click)="clickActionButton(valueActionClickButton.searchBrokenLamp)"
                                    mat-raised-button
                                    color="primary"
                                    [disabled]="!(atLeastOneLineIsSet$|async)"
                            >
                                <img src="/assets/icon/lightMate/wrongLigh.png" style="width: 24px">
                            </button>
                        </app-button-spinner>
                    </div>
                    <div class="spacer"></div>
                </ng-container>
                <div class="items-text-60">
                    {{'lightMate.restoreFactorySettings'|translate}}
                </div>
                <div class="items-linee items-width-60">
                    <app-button-spinner [loading]="loadingResetFactory" type="mat-raised-button">
                        <button (click)="clickActionButton(valueActionClickButton.resetFactoryDevice)" mat-raised-button
                                color="warn"
                                [disabled]="!(atLeastOneLineIsSet$|async)"
                        >
                            <img src="/assets/icon/lightMate/resetIcon.png" style="width: 24px">
                        </button>
                    </app-button-spinner>
                </div>
            </div>
            <mat-accordion class="container-grid huna-material-class" style="border-bottom: 1px solid #949494;">
                <mat-expansion-panel style="background: #f5f5f5 " [expanded]="protocolloLMisRM_V1"
                                     *ngIf="protocolloLMisRM_V1;else noLiveDimmingPower">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <img src="assets/icon/lightMate/liveDimming.svg"
                                 style="max-width: 24px;">
                        </mat-panel-title>
                        <mat-panel-description style="color: black">
                            {{'lightMate.liveStreamingPower' | translate}}
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div style="display: flex;align-items: center;justify-content: center;width: 100%">
                        <app-color-lamp [height]="200" [color]="colorLamp$|async"></app-color-lamp>
                    </div>
                    <form [formGroup]="form">
                        <div style="margin-top: 20px">
                            <mat-label>
                                {{'lightMate.power' | translate}}
                            </mat-label>
                            <mat-slider
                                    formControlName="powerLiveLamp"
                                    style="width: 100%"
                                    color="accent"
                                    thumbLabel
                                    [displayWith]="formatLabel"
                                    tickInterval="100"
                                    min="0"
                                    max="100">
                            </mat-slider>
                        </div>
                    </form>
                    <button mat-flat-button style="margin-left: 157px" color="primary" (click)="liveDimming()"
                            [disabled]="!(atLeastOneLineIsSet$|async)">
                        <mat-icon>save</mat-icon>
                    </button>
                </mat-expansion-panel>
                <ng-template #noLiveDimmingPower>
                    <mat-expansion-panel style="background: #f5f5f5;padding: 0" [disabled]="loadingAll">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>update</mat-icon>
                            </mat-panel-title>
                            <mat-panel-description style="color: black">
                                {{'lightMate.createProfileVirtualMidNIght' | translate}}
                            </mat-panel-description>
                            <button mat-icon-button color="accent" (click)="$event.stopPropagation();" appCopyPaste
                                    key="virtualMidnightPower"
                                    [isPasteButton]="true" (pasteValue)="pasteValue($event)">
                                <mat-icon>content_paste</mat-icon>
                            </button>
                        </mat-expansion-panel-header>
                        <mat-vertical-stepper [linear]="false" #stepper>
                            <mat-step [stepControl]="formStepper"
                                      *ngFor="let formStepper of formsStepper; let i = index">
                                <form [formGroup]="formStepper">
                                    <ng-template matStepLabel>{{'lightMate.powerOn' | translate}}</ng-template>
                                    <div style=" box-shadow: #7b7b7b">
                                        <div class="ngx-timepicker-field-example">
                                            <mat-label>
                                                {{'lightMate.hours' | translate}}
                                            </mat-label>
                                            <ngx-timepicker-field
                                                    [format]="24"
                                                    [clockTheme]="customThemNgxDataPicker"
                                                    [confirmBtnTmpl]="confirmBtn"
                                                    [cancelBtnTmpl]="cancelBtn"
                                                    [toggleIcon]="icon"
                                                    formControlName="ora">
                                            </ngx-timepicker-field>
                                            <ng-template #icon>
                                                <mat-icon color="accent">watch_later</mat-icon>
                                            </ng-template>
                                            <ng-template #confirmBtn>
                                                <button mat-mini-fab color="accent" style="margin-left: 10px">
                                                    <mat-icon>done</mat-icon>
                                                </button>
                                            </ng-template>
                                            <ng-template #cancelBtn>
                                                <button mat-mini-fab color="warn">
                                                    <mat-icon>clear</mat-icon>
                                                </button>
                                            </ng-template>
                                        </div>
                                        <div style="margin-top: 20px">
                                            <mat-label>
                                                {{'lightMate.power' | translate}}
                                            </mat-label>
                                            <p></p>
                                            <mat-slider
                                                    style="width: 100%"
                                                    formControlName="potenza"
                                                    color="accent"
                                                    thumbLabel
                                                    [displayWith]="formatLabel"
                                                    tickInterval="100"
                                                    min="0"
                                                    max="100"></mat-slider>
                                        </div>
                                    </div>
                                    <div>
                                        <button matStepperNext mat-mini-fab color="primary">
                                            <mat-icon>navigate_next</mat-icon>
                                        </button>
                                        &nbsp;
                                        <button mat-mini-fab color="warn" (click)="resetStepperByIndex(i)">
                                            <mat-icon>clear</mat-icon>
                                        </button>
                                    </div>
                                </form>
                            </mat-step>
                            <mat-step>
                                <ng-template matStepLabel>  {{'lightMate.end' | translate}}</ng-template>
                                <p>{{'lightMate.endText' | translate}}.</p>
                                <div>
                                    <button matStepperPrevious mat-mini-fab color="primary">
                                        <mat-icon>navigate_before</mat-icon>
                                    </button>
                                    &nbsp;
                                    <button mat-mini-fab color="warn" (click)="resetStepperByIndex(5)">
                                        <mat-icon>clear</mat-icon>
                                    </button>
                                    &nbsp;
                                    <button mat-mini-fab color="primary" (click)="saveFunctionProfile()"
                                            [disabled]="!(atLeastOneLineIsSet$|async)">
                                        <mat-icon>save</mat-icon>
                                    </button>
                                </div>
                            </mat-step>
                        </mat-vertical-stepper>
                    </mat-expansion-panel>
                </ng-template>
            </mat-accordion>
        </ng-container>
    </ng-container>
</div>
<ng-template #LoadingComando>
    <div style="display: flex;justify-content: center;align-items: center">
        <mat-spinner [diameter]="20"></mat-spinner>
    </div>
</ng-template>

<mat-menu #dates="matMenu">
    <button mat-menu-item *ngFor="let programma of lastProgrammiMVParse"
            [class.list-item-active]="programma|convertValueByPredicateFunction:isSelectedProgrammaMv:(selectedProgrammaMV$|async)"
            (click)="selectProgrammiMV(programma)">
        {{programma.updatedAt |customDate :'medium'|async}}
    </button>
</mat-menu>
