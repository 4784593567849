import {Component, Input, OnInit, Output} from '@angular/core';
import {SchedaManutenzioneCompilataParse} from "../../models/SchedaManutenzioneCompilata.Parse";
import {Subject} from "rxjs";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {arrayIsSet, className, isNotNullOrUndefined, stringIsSet} from "../../models/Models";
import {TranslateService} from "@ngx-translate/core";
import {DialogPopUpInfoService} from "../../providers/services/dialog-pop-up-info.service";
import {typeFormFieldMaintenance} from "../../providers/services/maintenance.service";

@Component({
    selector: 'app-box-scheda-manutenzione-compilata',
    templateUrl: './box-scheda-manutenzione-compilata.component.html',
    styleUrls: ['./box-scheda-manutenzione-compilata.component.scss']
})
export class BoxSchedaManutenzioneCompilataComponent implements OnInit {
    @Input() schedaManutenzioneCompilata: SchedaManutenzioneCompilataParse
    @Input() disabled: boolean
    @Input() selected: boolean
    @Input() notVisualized: any = {};

    @Output() clickCard = new Subject();
    @Output() dbClickCard = new Subject();

    private timerSingleClick;
    private preventSingleClick = false;
    public isSetPuntoLuceOCircuito = false;
    public elementAssociated;
    public keysVisualizedExpandElementDetail;
    public pathInitTraductionElementDetail = '';
    public pathEndTraductionElementDetail = '.title';
    public indirizzo;

    public compiledBy = {nome: null, cognome: null}

    public typeSchede = {};

    constructor(private matIconRegistry: MatIconRegistry,
                private domSanitizer: DomSanitizer,
                private translateService: TranslateService,
                private popUpInfoService: DialogPopUpInfoService,
    ) {
        this.matIconRegistry.addSvgIcon(
            "report",
            this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icon/hamburgerNavbar/report.svg")
        );
        this.matIconRegistry.addSvgIcon(
            "lampione",
            this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icon/dialogCompnent/lampione.svg")
        );
        this.matIconRegistry.addSvgIcon(
            "quadro",
            this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/marker/quadro.svg")
        );
    }


    ngOnInit(): void {
        if (isNotNullOrUndefined(this.schedaManutenzioneCompilata)) {
            if (this.schedaManutenzioneCompilata.compiledBy) {
                const user = this.schedaManutenzioneCompilata.compiledBy;
                Object.keys(this.compiledBy).forEach(key => {
                    this.compiledBy[key] = user.get(key)
                })
            }

            if (isNotNullOrUndefined(this.schedaManutenzioneCompilata.circuito)) {
                this.pathInitTraductionElementDetail = 'dashboard_sidenav.Circuiti.'
                this.keysVisualizedExpandElementDetail = ['numeroQuadro', 'POD'];
                this.elementAssociated = this.schedaManutenzioneCompilata.circuito;
                this.indirizzo = this.getIndirizzo();
                this.isSetPuntoLuceOCircuito = true;

            } else if (isNotNullOrUndefined(this.schedaManutenzioneCompilata.puntoLuce)) {
                this.pathInitTraductionElementDetail = 'dashboard_sidenav.PuntiLuce.'
                this.keysVisualizedExpandElementDetail = ['targa', 'targaCustom'];
                this.elementAssociated = this.schedaManutenzioneCompilata.puntoLuce;
                this.indirizzo = this.getIndirizzo();
                this.isSetPuntoLuceOCircuito = true;

            } else if (isNotNullOrUndefined(this.schedaManutenzioneCompilata.segnalazione)) {
                this.isSetPuntoLuceOCircuito = true;
                this.elementAssociated = this.schedaManutenzioneCompilata.segnalazione;
                this.indirizzo = this.getIndirizzo();

                this.pathInitTraductionElementDetail = 'segnalazioniParse.';
                this.keysVisualizedExpandElementDetail = ['titolo', 'descrizione'];
            }
        }

        if (arrayIsSet(this.schedaManutenzioneCompilata.form)) {
            this.schedaManutenzioneCompilata.form.forEach(fieldCompiled => {
                let index = -1;
                if (this.schedaManutenzioneCompilata.schedaManutenzione && arrayIsSet(this.schedaManutenzioneCompilata.schedaManutenzione.form)) {
                    index = this.schedaManutenzioneCompilata.schedaManutenzione.form.findIndex(field => field.traduction == fieldCompiled.traduction)
                }
                if (index >= 0) {
                    this.typeSchede[fieldCompiled.traduction] = this.getTypeToVisualizedByFormfieldType(this.schedaManutenzioneCompilata.schedaManutenzione.form[index].type);
                } else {
                    this.typeSchede[fieldCompiled.traduction] = this.getTypeToVisualized(fieldCompiled.value)
                }
            })
            this.schedaManutenzioneCompilata.form.forEach(field => {
                if (this.typeSchede[field.traduction] == 'image') {
                    if (arrayIsSet(field.value)) {
                        this.images[field.traduction] = []
                        field.value.forEach(value => {
                            if (value.file != null && value.file.url != null) {
                                this.images[field.traduction].push(value.file.url())
                            }
                        })
                    } else if (field.value != null && field.value.url) {
                        this.images[field.traduction] = [field.value.url()]
                    }
                }
            })
        }
    }

    images = {}

    getTypeToVisualized(value) {
        if (!isNotNullOrUndefined(value)) {
            return 'default'
        } else if (typeof value == "boolean") {
            return 'bool'
        } else if (value instanceof Date) {
            return 'date'
        } else if (value.url) {
            return 'image'
        } else if (arrayIsSet(value) && value[0].className != null && value[0].className == className.documentsFile) {
            return 'image'
        } else {
            return 'default'
        }
    }

    getTypeToVisualizedByFormfieldType(value) {
        if (!isNotNullOrUndefined(value)) {
            return 'default'
        } else if (value == typeFormFieldMaintenance.checkBox || value == typeFormFieldMaintenance.yesOrNot) {
            return 'bool'
        } else if (value == typeFormFieldMaintenance.dataPicker) {
            return 'date'
        } else if (value == typeFormFieldMaintenance.image) {
            return 'image'
        } else if (value == typeFormFieldMaintenance.hour) {
            return 'hour'
        } else {
            return 'default'
        }
    }

    get rejectedBy() {
        return {
            nome: this.schedaManutenzioneCompilata?.rejectedBy?.get('nome') ?? null,
            cognome: this.schedaManutenzioneCompilata?.rejectedBy?.get('cognome') ?? null,
        }
    }

    get className() {
        return (isNotNullOrUndefined(this.elementAssociated)) ? this.elementAssociated.className : ''
    }

    getTraductionPath(key) {
        return this.pathInitTraductionElementDetail + key + this.pathEndTraductionElementDetail;
    }

    get selectDisable() {
        if (this.disabled) {
            return 'card-disabled'
        } else if (this.selected) {
            return 'menu-select-card';
        } else {
            return 'background-white'
        }
    }

    get colorIcon() {
        if (this.disabled) {
            return 'disable';
        } else if (this.selected) {
            return 'primary';
        } else {
            return ''
        }
    }

    get opacity() {
        return !this.disabled ? 1 : 0.3
    }

    get colorIconImage() {
        if (this.colorIcon) {
            return 'img-icon-color-' + this.colorIcon;
        }
        return 'img-icon-color-black';
    }


    singleCardClick() {
        if (!this.disabled) {
            this.preventSingleClick = false;
            this.timerSingleClick = setTimeout(() => {
                if (!this.preventSingleClick) {
                    this.clickCard.next()
                }
            }, 200)
        }
    }

    dbCardClick() {
        if (!this.disabled) {
            if (isNotNullOrUndefined(this.timerSingleClick)) {
                this.preventSingleClick = true;
                clearTimeout(this.timerSingleClick);
            }
            this.dbClickCard.next()
        }
    }

    getIndirizzo() {
        let indirizzo;
        if (this.elementAssociated.className == className.puntiLuce) {
            indirizzo = this.elementAssociated.indirizzo;
        } else if (this.elementAssociated.className == className.circuiti) {
            indirizzo = this.elementAssociated.indirizzo || this.elementAssociated.indirizzoFornitura
        } else if (this.elementAssociated.className == className.segnalazioni) {
            indirizzo = undefined;
        }
        return stringIsSet(indirizzo) ? indirizzo : this.translateService.instant('NaN')
    }


    openImageFull(url) {
        this.popUpInfoService.openVisualizedImage(url, 'image')
    }

    get showCauseRejecet() {
        return this.schedaManutenzioneCompilata.rifiutata && stringIsSet(this.schedaManutenzioneCompilata.motivoRifiuto)
    }

    public anticonvertHourTODecimalHour(oraDecimal: string | number): string {
        if (oraDecimal) {
            const oraDecimalString = oraDecimal.toString();
            const ore = parseInt(oraDecimalString);
            const minute = Math.floor((parseFloat(oraDecimalString) - ore) * 60).toString().padStart(2, '0');
            let aMpM;
            let ora;
            if (ore > 12) {
                ora = (ore - 12).toString().padStart(2, '0');
                aMpM = 'PM';
            } else {
                ora = ore.toString().padStart(2, '0');
                aMpM = 'AM';
            }
            return ora + ':' + minute + ' ' + aMpM;
        } else {
            return '-';
        }
    }

    get associatedElementIcon() {
        if (this.schedaManutenzioneCompilata.segnalazione) {
            return 'report';
        }
        if (this.schedaManutenzioneCompilata.puntoLuce) {
            return 'lampione';
        }
        if (this.schedaManutenzioneCompilata.circuito) {
            return 'quadro';
        }
    }

}
