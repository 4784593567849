import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddFormFieldComponent } from './add-form-field/add-form-field.component';
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import {MatIconModule} from "@angular/material/icon";
import {MatLegacyDialogModule as MatDialogModule} from "@angular/material/legacy-dialog";
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {ReactiveFormsModule} from "@angular/forms";
import {MatLegacyFormFieldModule as MatFormFieldModule} from "@angular/material/legacy-form-field";
import {TranslateModule} from "@ngx-translate/core";
import {MatLegacyInputModule as MatInputModule} from "@angular/material/legacy-input";
import {MatLegacyCheckboxModule as MatCheckboxModule} from "@angular/material/legacy-checkbox";
import {MatLegacySelectModule as MatSelectModule} from "@angular/material/legacy-select";
import {WidgetsModule} from "../../widgets/widgets.module";
import {MatLegacyRadioModule as MatRadioModule} from "@angular/material/legacy-radio";
import {MatLegacyChipsModule as MatChipsModule} from "@angular/material/legacy-chips";
import {MatDatepickerModule} from "@angular/material/datepicker";
import { NewElectricLineDetailComponent } from './new-electric-line-detail/new-electric-line-detail.component';



@NgModule({
  declarations: [AddFormFieldComponent, NewElectricLineDetailComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatDialogModule,
        MatCardModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        TranslateModule,
        MatInputModule,
        MatCheckboxModule,
        MatSelectModule,
        WidgetsModule,
        MatRadioModule,
        MatChipsModule,
        MatDatepickerModule
    ]
})
export class PopUpCreateNewModule { }
