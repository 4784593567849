import * as Parse from 'parse';
export class PictureCameraPointParse extends Parse.Object {

    public static CLASS_NAME = 'PictureCameraPoint';
    constructor() {
        super(PictureCameraPointParse.CLASS_NAME);
    }


    public set objectId(value) {
        this.id = value;
    }

    public get objectId() {
        return this.id;
    }

    public set ACL(value) {
        super.set('ACL', value);
    }
    public get ACL() {
        return super.get('ACL');
    }

    public set createdAt(value) {
        super.set('createdAt', value);
    }
    public get createdAt() {
        return super.get('createdAt');
        }

    public set lunghezzaLato(value) {
        super.set('lunghezzaLato', value);
    }
    public get lunghezzaLato() {
        return super.get('lunghezzaLato');
    }

    public set name(value) {
        super.set('name', value);
    }
    public get name() {
        return super.get('name');
    }

    public set progetto(value) {
        super.set('progetto', value);
    }
    public get progetto() {
        return super.get('progetto');
    }

    public set location(value) {
        super.set('location', value);
    }
    public get location() {
        return super.get('location');
    }

    public set foto(value) {
        super.set('foto', value);
    }
    public get foto() {
        return super.get('foto');
    }

    public set updatedAt(value) {
        super.set('updatedAt', value);
    }
    public get updatedAt() {
        return super.get('updatedAt');
    }

}

Parse.Object.registerSubclass(PictureCameraPointParse.CLASS_NAME, PictureCameraPointParse);
