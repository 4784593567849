import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {className} from "../../../models/Models";

@Component({
    selector: 'app-select-element-to-proceed',
    templateUrl: './select-element-to-proceed.component.html',
    styleUrls: ['./select-element-to-proceed.component.scss']
})
export class SelectElementToProceedComponent implements OnInit {

    public nomiClasse = className;


    constructor(public dialogRef: MatDialogRef<SelectElementToProceedComponent>,
                @Inject(MAT_DIALOG_DATA) public data: { disabledButton: { lightPoint, circuits, arredoUrbano} }) {
    }

    ngOnInit(): void {
    }


}
