import {
    Component,
    OnInit,
    Input,
    OnChanges,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
    ChangeDetectorRef
} from '@angular/core';
import {
    Range,
    EditableStreet,
    ComputeResults,
    StreetLayoutItemType,
    StreetsService
} from 'src/app/providers/services/streets.service';
import {ordinamentoEcampiTraduzioni} from '../../../models/ordinamentoEcampiTraduzioni';
import {DialogPopUpService} from '../../../providers/services/dialog-pop-up.service';
import {switchMap} from 'rxjs/operators';
import {fromPromise} from 'rxjs/internal-compatibility';
import {AlertService} from '../../../providers/services/alert.service';
import {of} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {PrintPageService} from '../../../providers/services/print-page.service';
import {StradeParse} from "../../../models/Strade.Parse";
import {FotometriaParse} from "../../../models/Fotometria.Parse";
import {arrayIsSet, isNotNullOrUndefined} from "../../../models/Models";
import jsPDF from "jspdf";
import {FileServiceService} from "../../../providers/services/file-service.service";

@Component({
    selector: 'app-compute-results',
    templateUrl: './compute-results.component.html',
    styleUrls: ['./compute-results.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush, //forse viene ereditata dal padre

})
export class ComputeResultsComponent implements OnInit, OnChanges {

    @Input()
    street: EditableStreet;
    @Input()
    computationResults: ComputeResults;
    @Output() updateStreet = new EventEmitter()

    allResultsIndex: number = 0;
    showBestResult = false;

    ordinamentoCampi = ordinamentoEcampiTraduzioni;
    hunaLoader = {
        avviaLoad: false,
        valueSpinner: 0
    };
    loading = {assegnaFotometrie: false, print: false}


    get computationResult() {
        if (this.showBestResult) {
            return this.computationResults.bestResult;
        } else {
            return this.computationResults ? this.computationResults.allResults[this.allResultsIndex] : undefined;
        }
    }

    get photometryResult() {
        if (this.showBestResult) {
            return this.computationResults.bestFotometria;
        } else {
            return this.computationResults ? this.computationResults.allFotometrie[this.allResultsIndex] : undefined;
        }
    }

    constructor(private streetService: StreetsService,
                private myDialog: DialogPopUpService,
                private alertService: AlertService,
                private changeDetection: ChangeDetectorRef,
                private translate: TranslateService,
                private printPageService: PrintPageService,
                private fileService: FileServiceService) {
    }

    ngOnInit() {
    }

    ngOnChanges() {
        this.showBestResult = !!this.computationResults.bestResult;
        this.allResultsIndex = 0;
    }

    fixedDigits(n?: number): string | undefined {
        return n !== undefined && n !== null ? n.toFixed(2) : undefined;
    }

    showResult(index: number) {
        if (0 <= index && index < this.computationResults.allResults.length) {
            this.allResultsIndex = index;
        }
    }

    setShowBestResult(value: boolean) {
        if (this.allResultsIsSet) {
            this.showBestResult = value;
        }
    }

    get allResultsIsSet() {
        return this.computationResults != null && arrayIsSet(this.computationResults.allResults)
    }

    get StreetLayoutItemTypes() {
        return StreetLayoutItemType;
    }

    getObjectKeys(obj: object) {
        return Object.keys(obj);
    }

    reverse(arr: any[]) {
        return arr.slice().reverse();
    }

    getClassFromValueAndLimit(value: number, range: Range) {
        if (range.max !== undefined && range.min !== undefined) {
            if (value < range.min) {
                return 'red';
            }
            if (value > range.max) {
                return 'yellow';
            }
        }
        if (value < range.min || value > range.max) {
            return 'red';
        }
        return '';
    }


    private castValue(value, type) {
        let castedValue;
        if (isNotNullOrUndefined(type)) {
            switch (type.toUpperCase()) {
                case  'INT':
                    const intero = parseInt(value);
                    castedValue = (isNaN(intero)) ? undefined : intero;
                    break;
                case  'NUMBER':
                    const float = parseFloat(value);
                    castedValue = (isNaN(float)) ? undefined : float;
                    break;
                default:
                    if (isNotNullOrUndefined(value)) {
                        castedValue = (typeof value === 'string' && value.trim().length < 0) ? undefined : value.toString();
                    }
                    break;
            }
        }
        return castedValue;
    }

    get varianteKeys() {
        let varianteKeys = {};
        const keysComputation = [
            {puntoLuce: 'potenzaNominale', computationResult: 'potenza'},
            {puntoLuce: 'flussoLuminoso', computationResult: 'flusso'},
        ];
        const keysPhotometryResult = [
            {puntoLuce: 'fornitoreSorgenteLuminosa', photometryResult: 'produttore'},
            {puntoLuce: 'modelloSorgenteLuminosa', photometryResult: 'nomeLampada'},
            {puntoLuce: 'tipologiaCorpoIlluminante', photometryResult: 'tipologiaCorpoIlluminante'},
            {puntoLuce: 'tipologiaSorgenteLuminosa', photometryResult: 'LED'},
            {puntoLuce: 'temperaturaColore', photometryResult: 'temperaturaColore'},
        ];

        const keyIpea = [
            'IPEA2013',
            'IPEA2018',
        ];
        keysComputation.forEach((key) => {
            const varianteKey = this.ordinamentoCampi.PuntiLuce[key.puntoLuce].varianteKey;
            const type = this.ordinamentoCampi.PuntiLuce[key.puntoLuce].type;
            const value = this.castValue(this.computationResult[key.computationResult], type);
            if (isNotNullOrUndefined(value)) {
                varianteKeys[varianteKey] = value;
            }
        });
        keysPhotometryResult.forEach((key) => {
            const varianteKey = this.ordinamentoCampi.PuntiLuce[key.puntoLuce].varianteKey;
            const type = this.ordinamentoCampi.PuntiLuce[key.puntoLuce].type;
            const value = this.castValue(this.photometryResult[key.photometryResult], type);
            if (isNotNullOrUndefined(value)) {
                varianteKeys[varianteKey] = value;
            }
        });

        keyIpea.forEach((key) => {
            const varianteKey = this.ordinamentoCampi.PuntiLuce[key].varianteKey;
            const value = this.getIpea(key as 'IPEA2013' | 'IPEA2018');
            if (isNotNullOrUndefined(value)) {
                varianteKeys[varianteKey] = value;
            }
        });
        return varianteKeys;
    }


    public getIpea(ipea: 'IPEA2013' | 'IPEA2018') {
        if (ipea === 'IPEA2013') {
            return undefined
        } else {
            return this.computationResult.IPEA;
        }
    }


    /**
     * step 1 the light points related to the select project and select road  are downloaded
     * step 2 ask you if you want to upgrade lightPoints;
     * step 3 if you accept updtated le varianteKey lightPoint;
     */
    updateVarianteKey() {
        let errorMessage;
        this.hunaLoader.avviaLoad = true;
        this.hunaLoader.valueSpinner = 0;
        this.streetService.getPuntiLuceWithStreet(this.street.objectId)
            .pipe(
                switchMap(lightPoints => {
                    const messages = [
                        'streets.warngingYuoAreUpdated_1',
                        'streets.warngingYuoAreUpdated_2',
                        lightPoints.length.toString(),
                        'streets.warngingYuoAreUpdated_3'
                    ];
                    return this.myDialog.openDialogForDelete(messages)
                        .pipe(switchMap((choice) => {
                            if (choice) {
                                return fromPromise(this.streetService.updateVarianteKeyLightPoint(lightPoints, this.varianteKeys, 100));
                            } else {
                                return of(false);
                            }
                        }));
                }))
            .subscribe((choice) => {
                this.changeDetection.markForCheck();
                if (!choice) {
                    this.hunaLoader.avviaLoad = false;
                    this.hunaLoader.valueSpinner = 0;
                    if (isNotNullOrUndefined(subScription)) {
                        subScription.unsubscribe();
                    }
                }
            });

        const subScription = this.streetService.advancedRatingSave.subscribe((step) => {
            this.changeDetection.markForCheck();
            this.hunaLoader.valueSpinner = step.progress;
            if (step.error) {
                errorMessage = step.error;
            }
            if (step.finished) {
                this.hunaLoader.avviaLoad = false;
                this.hunaLoader.valueSpinner = 0;
                if (isNotNullOrUndefined(errorMessage)) {
                    this.alertService.error(errorMessage);
                } else {
                    this.alertService.success(this.translate.instant('alert.success'));
                }
                subScription.unsubscribe();
            }
        });
    }

    openDialog() {
        // const ipea = {
        //     ipea2013: 'N.D.',
        //     ipea2018: this.computationResult.IPEA
        // };
        // this.userService.getOrganizzazione().pipe(
        //     // switchMap((organizzazione) =>
        //     //     this.myDialog.openPrintStreet(this.street, this.computationResult, this.photometryResult, organizzazione, ipea))
        // ).subscribe();
        this.loading.print = true;
        let headerFooterOptions;
        const doc = new jsPDF();
        this.printPageService.addFont(doc);
        const csvRow = [];

        this.streetService.projectWithOrganizzazioneComune$.subscribe(progetto => {
            let logoComune
            if (progetto.htmlLogo != null && progetto.htmlLogo.url != null) {
                logoComune = progetto.htmlLogo.url();
            }
            let nomeComune
            if (progetto.comune != null && progetto.comune.nome != null) {
                nomeComune = progetto.comune.nome;
            }
            const strada = new StradeParse()
            strada.objectId = this.street.objectId;
            const fotometria = new FotometriaParse();
            fotometria.objectId = this.photometryResult.objectId;
            const calcoliIlliminotecnici: ComputeResults = {
                allResults: [],
                allFotometrie: [],
                bestResult: this.computationResult,
                bestFotometria: this.photometryResult
            }

            const headerFooter = this.printPageService.streetPageLayoutType(doc, logoComune, nomeComune, strada, calcoliIlliminotecnici, true)
            if (headerFooter != null) {
                headerFooterOptions = headerFooter;
            }
            if (headerFooter != null && arrayIsSet(headerFooter.forCsv)) {
                csvRow.push(...headerFooter.forCsv);
            }

            let logoAziendale
            if (progetto.organizzazione != null && progetto.organizzazione.get('logo') != null && progetto.organizzazione.get('logo').url != null) {
                logoAziendale = progetto.organizzazione.get('logo').url();
            }
            doc.deletePage(doc.getNumberOfPages())
            if (headerFooterOptions != null) {
                this.printPageService.addHeaderFooter(doc, headerFooterOptions, progetto.name, logoComune, logoAziendale)
            }
            this.printPageService.convertToPdf(doc, this.translate.instant('streets.nameFile.calcoloIlluminotecnico') + '_' + progetto.name + '_' + strada.nome)
            // this.disable.printAll = false;
            const dataToCsv = this.streetService.getDataCsv(csvRow)
            this.fileService.dowloadJson(this.translate.instant('streets.nameFile.csvFile') + '_' + progetto.name + '_' + strada.nome, dataToCsv.rows, dataToCsv.header, ';')
            this.loading.print = false;
            this.changeDetection.detectChanges();
        }, error => {
            this.loading.print = false
            this.changeDetection.detectChanges();
            this.alertService.error(error)
        })
    }


    assegnaFotometria() {
        this.loading.assegnaFotometrie = true
        const strada = new StradeParse()
        strada.objectId = this.street.objectId;
        const fotometria = new FotometriaParse();
        fotometria.objectId = this.photometryResult.objectId;
        this.streetService.updateStreet(strada, {fotometrie: [fotometria]}).subscribe(strada => {
            this.updateStreet.emit(strada);
            this.loading.assegnaFotometrie = false
            this.changeDetection.detectChanges()
        }, error => {
            this.loading.assegnaFotometrie = false
            this.alertService.error(error)
            this.changeDetection.detectChanges()
        })
    }

}
