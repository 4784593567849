import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {LoaderService} from '../../../providers/services/loader.service';
import {AlertService} from '../../../providers/services/alert.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../providers/services/user.service';
import {ProjectService} from "../../../providers/services/project.service";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {InfoLockedPlanDialogComponent} from "../../../components/locked-plan/info-locked-plan-dialog/info-locked-plan-dialog.component";
import {ReportFormService} from "../../../providers/forms/report-form.service";
import {ReportsService} from "../../../providers/services/reports.service";
import {FilterService} from "../../../providers/services/filter.service";
import {Observable} from "rxjs";
import {map, startWith} from "rxjs/operators";
import * as Parse from 'parse';
import {TIPOSEGNALAZIONE, UserRole} from "../../../../config/static-data";
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {TranslateService} from "@ngx-translate/core";
import {MapService} from "../../../providers/services/map.service";
import {UtilsService} from "../../../providers/services/utils.service";

@Component({
    selector: 'app-create-report',
    templateUrl: './create-report.component.html'
})
export class CreateReportComponent implements OnInit {

    public circuit = false;
    public planForm: UntypedFormGroup;
    public user;
    public loaded = false;
    public project;
    public reports;
    public lightNumber;
    public loading = true;
    myControl = new UntypedFormControl();
    options: string[] = TIPOSEGNALAZIONE;
    filteredOptions: Observable<string[]>;
    public spliReports = [];

    public canICreatePlan = false;

    constructor(
        public dialogRef: MatDialogRef<CreateReportComponent>,
        private planUserForm: ReportFormService,
        private planService: ReportsService,
        private loaderService: LoaderService,
        private filterService: FilterService,
        private alertServices: AlertService,
        private userService: UserService,
        private router: Router,
        private projectService: ProjectService,
        public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any,
        private activatedRoute: ActivatedRoute,
        private translate: TranslateService,
        private mapService: MapService,
        private utilsService: UtilsService,
    ) {
        if (this.data) {
            this.circuit = this.data.circuit;
        } else {
            this.circuit = false;
        }
        this.loadData();
        this.reports = this.planService.reportsInMap;
        this.lightNumber = this.reports.length;
    }

    ngOnInit() {
        this.filteredOptions = this.myControl.valueChanges
            .pipe(
                startWith(''),
                map(value => this._filter(value))
            );
    }

    setType(option) {
        this.planForm.get('tipologiaIntervento').setValue(option);
    }

    private _filter(value): string[] {
        const filterValue = value.toLowerCase();

        return this.options.filter(option => option.toLowerCase().includes(filterValue));
    }


    private async loadData() {
        const loaderId = this.loaderService.addLoader();
        this.user = this.userService.user();
        this.planForm = this.planUserForm.getCreateReportForm();
        this.canICreatePlan = await this.projectService.isValidSubscription(5, false, false, true, false);
        this.loaded = true;
        this.loaderService.removeLoader(loaderId);
    }


    async createReport() {
        if (this.planForm.invalid) {
            this.alertServices.error(this.translate.instant('compiled_form'));
        } else {
            const loaderId = this.loaderService.addLoader();
            if (await this.projectService.isValidSubscription(5, false, false, true, false)) {
                let foto;
                if (this.planForm.value.foto == null) {
                    foto = null;
                } else {
                    if (this.planForm.value.foto.file) {
                        foto = this.planForm.value.foto.file;
                    } else {
                        foto = this.planForm.value.foto;
                    }
                }
                this.planForm.get('foto').setValue(foto);
                this.planService.createReport(this.planForm.value, this.circuit, this.reports, this.planForm.value.titolo).subscribe(report => {
                    this.alertServices.success(this.translate.instant('report_send'));
                    this.dialog.closeAll()
                    this.loaderService.removeLoader(loaderId);
                }, error => {
                    if (error.code == 200) {
                        this.alertServices.warning(error.message);
                        this.dialogRef.close()
                    } else {
                        this.alertServices.error(error.message);
                    }
                    this.loaderService.removeLoader(loaderId);
                })
                /*
                if (this.circuit) {
                    await this.planService.createReport(this.planForm.value, this.circuit, null, this.planForm.value.titolo)
                } else {
                    await this.verifySplitReport();
                    for (let i in this.spliReports) {
                        this.spliReports[i]
                        let titolo;
                        if (Object.keys(this.spliReports).length == 1) {
                            titolo = this.planForm.value.titolo
                        } else {
                            titolo = this.planForm.value.titolo + ' - ' + i
                        }
                        await this.planService.createReport(this.planForm.value, this.circuit, this.spliReports[i], titolo)
                    }
                }
                */
                // const res = await this.planService.createReport(this.planForm.value, this.circuit, this.reports);

                // this.router.navigate(['/segnalazioni/modifica/' + res.objectId]);
            } else {

                this.utilsService.lockBody();

                const dialogRef = this.dialog.open(InfoLockedPlanDialogComponent, {
                    width: '600px',
                    data: {
                        userSession: this.userService.sessionToken()
                    }
                });
                dialogRef.afterClosed().subscribe(res => {
                    if (res) {
                        this.loadData();
                    }
                    this.utilsService.unlockBody();
                });
            }

        }
    }

    async verifySplitReport() {
        for (let item of this.reports) {
            let c = (await this.mapService.getLightByObjectId(item)).circuito.numeroQuadro;
            if (!this.spliReports[c]) {
                this.spliReports[c] = []
            }
            this.spliReports[c].push(item)
        }
    }

    onLoadImage(files: File[], label) {
        try {
            const fileUploadControl = files[0];
            const loaderId = this.loaderService.addLoader();
            if (fileUploadControl) {
                const file = fileUploadControl;
                const name = fileUploadControl.name;
                const reader = new FileReader();
                reader.onloadend = (e: ProgressEvent) => {
                    const base64 = (e.target as FileReader).result as any;
                    const parseFile = new Parse.File(name, {base64: base64});
                    return parseFile.save().then(() => {
                        this.planForm.get(label).setValue({
                            name: parseFile.name(),
                            url: parseFile.url(),
                            file: parseFile
                        });

                        this.loaderService.removeLoader(loaderId);
                    });

                };
                reader.readAsDataURL(file);

            }
        } catch (e) {
            console.log(e)
        }

    }

    async deleteSavedFile(label) {
        this.planForm.get(label).setValue(null);
    }

    isUser() {
        return this.projectService.isUser();
    }

}
