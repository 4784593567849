import {Component, Input, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {Observable, Subscription} from "rxjs";
import {debounceTime, map, pairwise, startWith} from "rxjs/operators";
import {arrayIsSet} from "../../models/Models";

@Component({
    selector: 'app-long-list-paginate',
    templateUrl: './long-list-paginate.component.html',
    styleUrls: ['./long-list-paginate.component.scss']
})
export class LongListPaginateComponent implements OnInit, OnDestroy {
    @Input() itemTemplate: TemplateRef<any>;
    @Input() items: any[] = []
    @Input() sizePages = [10, 50, 100, 500];
    public form: UntypedFormGroup;
    public currentPage$: Observable<number>;
    public numberElementForPage$: Observable<number>;
    public subscription = new Subscription();

    constructor(private fb: UntypedFormBuilder) {
        this.form = this.fb.group({
            currentPage: 1,
            numberElementForPage: this.sizePages[0]
        })
        this.currentPage$ = this.form.get('currentPage').valueChanges.pipe(
            startWith(this.currentPage),
            map(page => page - 1)
        )
        this.numberElementForPage$ = this.form.get('numberElementForPage').valueChanges.pipe(
            startWith(this.numberElementForPage)
        )
        this.subscription.add(
            this.currentPage$.pipe(
                pairwise()
            ).subscribe(([prevPage, currentPage]) => {
                if (currentPage >= this.numberOfPage) {
                    setTimeout(() => {
                        this.currentPage = (prevPage + 1) > 0 ? prevPage + 1 : 1
                    }, 0)
                }
            })
        )
    }

    ngOnInit(): void {
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    get currentPage() {
        return this.form.get('currentPage').value
    }

    set currentPage(value: number) {
        this.form.get('currentPage').setValue(value);
    }

    get numberElementForPage() {
        return this.form.get('numberElementForPage').value
    }

    set numberElementForPage(value: number) {
        this.form.get('numberElementForPage').setValue(value);
    }

    addPage() {
        if (this.currentPage < this.numberOfPage) {
            this.currentPage = this.currentPage + 1;
        }
    }

    removePage() {
        if (this.currentPage > 1) {
            this.currentPage = this.currentPage - 1;
        }
    }

    get numberOfPage() {
        return Math.ceil(this.items.length / this.numberElementForPage)
    }

    predicateNumberElement(items: any[], pageSize: number) {
        if (arrayIsSet(items)) {
            const numberPages = Math.ceil(items.length / pageSize)
            return numberPages > 0 ? numberPages : 1;
        } else {
            return 1;
        }
    }

    setNumberElement(pageSize: number) {
        this.numberElementForPage = pageSize
    }
}
