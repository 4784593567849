import {Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {Subscription} from "rxjs";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {startWith} from "rxjs/operators";

@Directive({
    selector: '[appResponsiveClass]'
})
export class ResponsiveClassDirective implements OnDestroy, OnInit {

    @Input() responsiveClassXS;
    @Input() responsiveClassS;
    @Input() responsiveClassM;
    @Input() responsiveClassL;
    @Input() responsiveClassXL;

    private subscription: Subscription;

    constructor(private el: ElementRef, private breakpoint: BreakpointObserver, private renderer: Renderer2) {


    }

    ngOnInit(): void {
        this.subscription = this.breakpoint.observe([
            Breakpoints.XSmall,
            Breakpoints.Small,
            Breakpoints.Medium,
            Breakpoints.Large,
            Breakpoints.XLarge
        ]).pipe(startWith(0)).subscribe(() => {
            this.setItem();
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private setItem() {
        let item;
        if (this.responsiveClassXS) {
            this.renderer.removeClass(this.el.nativeElement, this.responsiveClassXS);
        }
        if (this.responsiveClassS) {
            this.renderer.removeClass(this.el.nativeElement, this.responsiveClassS);
        }
        if (this.responsiveClassM) {
            this.renderer.removeClass(this.el.nativeElement, this.responsiveClassM);
        }
        if (this.responsiveClassL) {
            this.renderer.removeClass(this.el.nativeElement, this.responsiveClassL);
        }
        if (this.responsiveClassXL) {
            this.renderer.removeClass(this.el.nativeElement, this.responsiveClassXL);
        }
        if (this.breakpoint.isMatched(Breakpoints.XLarge)) {
            item = this.getCurrentItem(Breakpoints.XLarge);
        } else if (this.breakpoint.isMatched(Breakpoints.Large)) {
            item = this.getCurrentItem(Breakpoints.Large);
        } else if (this.breakpoint.isMatched(Breakpoints.Medium)) {
            item = this.getCurrentItem(Breakpoints.Medium);
        } else if (this.breakpoint.isMatched(Breakpoints.Small)) {
            item = this.getCurrentItem(Breakpoints.Small);
        } else if (this.breakpoint.isMatched(Breakpoints.XSmall)) {
            item = this.getCurrentItem(Breakpoints.XSmall);
        }

        if (item) {
            this.renderer.addClass(this.el.nativeElement, item);
        }
    }

    private getCurrentItem(current) {
        switch (current) {
            case Breakpoints.XLarge:
                if (this.responsiveClassXL) {
                    return this.responsiveClassXL;
                }
            // tslint:disable-next-line:no-switch-case-fall-through
            case Breakpoints.Large:
                if (this.responsiveClassL) {
                    return this.responsiveClassL;
                }
            // tslint:disable-next-line:no-switch-case-fall-through
            case Breakpoints.Medium:
                if (this.responsiveClassM) {
                    return this.responsiveClassM;
                }
            // tslint:disable-next-line:no-switch-case-fall-through
            case Breakpoints.Small:
                if (this.responsiveClassS) {
                    return this.responsiveClassS;
                }
            // tslint:disable-next-line:no-switch-case-fall-through
            case Breakpoints.XSmall:
                if (this.responsiveClassXS) {
                    return this.responsiveClassXS;
                }
            // tslint:disable-next-line:no-switch-case-fall-through
            default :
                return '';
        }

    }
}
