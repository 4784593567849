// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    // prod 'https://plan.huna.entopanlab.it/',
    // previe https://pdf.huna.entopanlab.it/
    // local: 'http://localhost:8081/',
    pdfUrl: 'http://localhost:8081/',
    api_key: 'AIzaSyCoGHvTptQkmea8i544iI_euHNYkvDECXg',
    baseUrl: 'https://api.light.touch.huna.io/serverInfo',
    parse: {
        applicationId: 'ZQZxlYwEqf23rZrSkf3RE8RJO54Ce2OgLiKE634F', // This is your Application ID for work
        javascriptKey: 'i5XCNbrvhS0sSseS6h7YTctfBYkf3nDHn26D9u73', // This is your Javascript key for work
        serverUrl: 'https://hunatest.back4app.io/', // for work
        // applicationId: 'h9rKz6voMG62MbJYSKM3b4one56PgO4eJka41keY', // This is your Application ID for production
        // javascriptKey: 'XdP3dHmjZbMxBMnyLSRxnOgNH6bM9BClIgZq8JSA', // This is your Javascript key for production
        // serverUrl: 'https://ligeam-pi.back4app.io'
    },
    apiParse: {
        XParseApplicationId: 'lZCPPNlQLAGZTzfnIsrAfTs1hJHdnL8QZ215AZH2',
        XParseRESTAPIKey: 'o2Hj6DZ0bCaKfpiSC7rgRJhK1edsmFB88AfGrTli',
        urlApiParse: 'https://parseapi.back4app.com/classes/'
    },
    urlExternalVisualizedDocument: {
        google: 'https://docs.google.com/viewer?url=',
        office: 'https://view.officeapps.live.com/op/embed.aspx?src='
    },
    lightPointLimit: 1000
};


/*

  applicationId: 'h9rKz6voMG62MbJYSKM3b4one56PgO4eJka41keY', // This is your Application ID for production
        javascriptKey: 'XdP3dHmjZbMxBMnyLSRxnOgNH6bM9BClIgZq8JSA', // This is your Javascript key for production
        serverUrl: 'https://ligeam-pi.back4app.io' // for production
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
