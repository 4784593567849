import {ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {CaricoEsogenoParse} from "../../models/CaricoEsogeno.Parse";
import {className, stringIsSet} from "../../models/Models";
import {ArredoUrbanoUtilsService} from "../../providers/services/arredo-urbano-utils.service";
import {DialogPopUpInfoService} from "../../providers/services/dialog-pop-up-info.service";

@Component({
    selector: 'app-box-carico-esogeno',
    templateUrl: './box-carico-esogeno.component.html',
    styleUrls: ['./box-carico-esogeno.component.scss']
})
export class BoxCaricoEsogenoComponent implements OnChanges {
    @Input() caricoEsogeno: CaricoEsogenoParse;
    @Input() showElementType: boolean = false;
    public name: string;
    public type: string;
    public icon: string;
    public isArredoUrbano = false;

    urlImage: string

    constructor(
        private dialogPopUpInfo: DialogPopUpInfoService,
        private arredoUrbanoUtilService: ArredoUrbanoUtilsService,
        private cdf: ChangeDetectorRef) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.caricoEsogeno.foto != null) {
            const documentFile = this.caricoEsogeno.foto;
            if (documentFile.file == null || !stringIsSet(documentFile.file.url())) {
                documentFile.fetch().then(file => {
                    if (stringIsSet(file.file.url())) {
                        this.urlImage = file.file.url()
                        this.cdf.detectChanges();
                    }
                });
            }

        }
        if (changes.caricoEsogeno) {
            this.type = undefined;
            this.name = undefined;
            this.icon = undefined;
            this.isArredoUrbano = false;
        }
        if (this.showElementType) {
            if (this.caricoEsogeno.puntoLuce != null) {
                this.type = className.puntiLuce;
                this.name = this.caricoEsogeno.puntoLuce.getTarga();
                this.icon = 'assets/icon/dialogCompnent/lampione.svg';
                this.isArredoUrbano = false;
            } else if (this.caricoEsogeno.circuito != null) {
                this.type = className.circuiti
                this.name = this.caricoEsogeno.circuito.numeroQuadro
                this.icon = 'https://media-manager.net/storage/huna-app/sorgente/quadro/quadro.svg'
                this.isArredoUrbano = false;
            } else if (this.caricoEsogeno.arredoUrbano != null) {
                this.type = className.arredoUrbano
                this.name = this.caricoEsogeno.arredoUrbano.tipologia
                this.icon = this.arredoUrbanoUtilService.getIcon(this.caricoEsogeno.arredoUrbano).path
                this.isArredoUrbano = true;
            }
        }
    }


    getValue(value) {
        return value == null ? '-' : value
    }

    openImageFull() {
        if (stringIsSet(this.urlImage)) {
            this.dialogPopUpInfo.openVisualizedImage(this.urlImage, 'image', false)
                .subscribe();
        }
    }
}
