<div class="w100 flex-around-center" style="margin: 10px">
    <button mat-flat-button (click)="openButton()" color="accent">
        <mat-icon>add</mat-icon>
    </button>
    <button mat-flat-button (click)="openOther()" color="primary">
        {{'dashboard_sidenav.CaricoEsogeno.getOtherCaricoEsogeno'|translate}}
    </button>
</div>
<ng-container>
    <cdk-virtual-scroll-viewport itemSize="50" class="container-list">
        <div class="flex-start-stretch flex-flow-row">
            <div *cdkVirtualFor="let caricoEsogeno of (carichiEsogeni);let i=index"
                 class="box-carico-esogeno"
            >
                <app-box-carico-esogeno [caricoEsogeno]="caricoEsogeno"
                                        [showElementType]="true">
                    <div headerRightBox class="flex-end-center"
                         style="width: calc(100% - 150px);min-height: 62px">
                        <button type="button" mat-flat-button color="warn"
                                (click)="$event.stopPropagation();">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </app-box-carico-esogeno>
            </div>
            <div style="padding: 31px 0" *ngIf="addSpacer"></div>
        </div>
    </cdk-virtual-scroll-viewport>
</ng-container>