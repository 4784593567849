import {typeFormValue} from "./configurationProperty/configurationPropertyUtils";
import {CircuitiParse} from "./Circuiti.Parse";
import {PuntiLuceParse} from "./PuntiLuce.Parse";

export const ordinamentoEcampiTraduzioni = {
    "PuntiLuce": {
        "foto": {
            "copyable": false,
            "type": "FILE",
            "possibleValues": null,
            "sortingValue": 100,
            "editable": false,
            "showInForm": false,
            "showInLabel": true,
            getValueInLabel: (pl: PuntiLuceParse) => {
                return {
                    key: pl && (pl.foto != null),
                    showingLikeType: 'bool'
                };
            },
            "filtrable": true,
            typeForm: typeFormValue.IMAGE
        },
        "fotoTipologia": {
            "sortingValue": 101,
            "possibleValues": "fotoPuntiLuce.titolo",
            "type": "POINTER_fotoPuntiLuce",
            getValuePointer: (p: PuntiLuceParse) => {
                // bisogna tornare tuttle chiavi vengono visualizzate sull export
                const obj = {fotoTipologia: undefined, foto: undefined};
                if (p != null) {
                    if (p.fotoTipologia != null && p.fotoTipologia.get('foto') != null) {
                        obj.fotoTipologia = p.fotoTipologia.get('foto').url();
                    }
                    if (p.foto != null) {
                        obj.foto = p.foto.url();
                    }
                }
                return obj;
            },
            "showInLabel": true,
            getValueInLabel: (pl: PuntiLuceParse) => {
                let key = '';
                if (pl && pl.fotoTipologia != null) {
                    key = 'name' in pl.fotoTipologia ? pl.fotoTipologia.name : pl.fotoTipologia.get('titolo');
                }
                return {
                    key,
                    showingLikeType: 'text'
                };
            },
            "editable": false,
            "showInForm": false,
            "filtrable": true,
            "copyable": false,
            "section": 0
        },
        "presenzaFotocellula": {
            "copyable": true,
            "section": 2,
            "type": "BOOL",
            "possibleValues": [true, false],
            "sortingValue": 45,
            "editable": true,
            "showInForm": true,
            "filtrable": true
        },
        "matricolaCorpoIlluminante": {
            "editable": true,
            "showInForm": true,
            "filtrable": true,
            "possibleValues": undefined,
            "copyable": true,
            "type": "TEXT",
            typeForm: typeFormValue.INPUT_TEXT,
            "sortingValue": 32.9,
            "section": 2
        },
        "statoDiConservazioneCorpoIlluminante": {
            "editable": true,
            "showInForm": true,
            "filtrable": true,
            "possibleValues": [
                "Accettabile",
                "Lampada da sostituire",
                "Corpo illuminante da sostituire",
                "Mancante",
                "Da verificare",
                "Non specificato"
            ],
            "copyable": true,
            "type": "ELENCO",
            typeForm: typeFormValue.ELENCO_APERTO,
            "sortingValue": 33,
            "section": 2
        },
        "alimentazioneLineaQuadro": {
            "type": "NUMBER",
            "section": 0,
            "copyable": true,
            "sortingValue": 10,
            "editable": true,
            "showInForm": true,
            "filtrable": true,
        },
        "realizzato": {
            "copyable": true,
            "type": "BOOL",
            "possibleValues": [true, false],
            "editable": true,
            "showInForm": true,
            "filtrable": true,
            "section": 0,
            "sortingValue": 11
        },
        "statoDiConservazioneSbraccio": {
            "copyable": true,
            "type": "ELENCO",
            typeForm: typeFormValue.ELENCO_APERTO,
            "section": 2,
            "sortingValue": 32,
            "possibleValues": [
                "Accettabile",
                "Da riverniciare",
                "Da sostituire",
                "Mancante",
                "Da riposizionare",
                "Pericoloso",
                "Da verificare",
                "Non specificato"
            ],
            "editable": true,
            "showInForm": true,
            "filtrable": true,
        },
        "distanzaBordoCarreggiata": {
            "editable": true,
            "showInForm": true,
            "filtrable": true,
            "type": "NUMBER",
            "sortingValue": 41,
            "section": 2,
            "copyable": true
        },
        "lunghezzaSbraccio": {
            "sortingValue": 40,
            "section": 2,
            "editable": true,
            "showInForm": true,
            "filtrable": true,
            "copyable": true,
            "type": "NUMBER"
        },
        "classe": {
            "sortingValue": 5,
            "type": "INT",
            "editable": true,
            "showInForm": true,
            "filtrable": true,
            "section": 0,
            "possibleValues": [
                0,
                1,
                2
            ],
            "copyable": true
        },
        "tipologiaAreaIlluminata": {
            "section": 0,
            "type": "ELENCO",
            typeForm: typeFormValue.ELENCO_APERTO,
            "editable": true,
            "showInForm": true,
            "filtrable": true,
            "sortingValue": 14,
            "possibleValues": [
                "Area pedonale",
                "area_ciclo_pedonale",
                "area_verde",
                "Attraversamento pedonale",
                "Edificio",
                "grande_area",
                "Incrocio",
                "Marciapiede",
                "Monumento",
                "Pista ciclabile",
                "Parco",
                "Piazza",
                "Parcheggio",
                "Rotatoria",
                "spiazzale",
                "Strada",
                "Tunnel",
                "Altro",
                "Non specificato"
            ],
            "copyable": true
        },
        "temperaturaColore": {
            "section": 1,
            "editable": true,
            "showInForm": true,
            "filtrable": true,
            "type": "ELENCO",
            "possibleValues": [
                "2200",
                "2700",
                "3000",
                "3500",
                "4000",
                "5000",
                "RGB",
                "Altro",
                "Non specificato"
            ],
            "varianteKey": "varianteTemperaturaColore",
            "copyable": true,
            "sortingValue": 24
        },
        "statoDiConservazione": {
            "copyable": true,
            "section": 2,
            "type": "ELENCO",
            typeForm: typeFormValue.ELENCO_APERTO,
            "sortingValue": 31,
            "possibleValues": [
                "Accettabile",
                "Da riverniciare",
                "Da sostituire",
                "Mancante",
                "Da riposizionare",
                "Pericoloso",
                "Da verificare",
                "Non specificato"
            ],
            "editable": true,
            "showInForm": true,
            "filtrable": true,
        },
        "createdAt": {
            "type": "DATE",
            "section": 0,
            "editable": false,
            "showInForm": true,
            "filtrable": true,
            "copyable": false,
            "sortingValue": 0
        },
        "installationDate": {
            "type": "DATE",
            "section": 0,
            "editable": true,
            "showInForm": true,
            "filtrable": true,
            "copyable": false,
            "sortingValue": 1.5
        },
        "replacementDate": {
            "type": "DATE",
            "section": 0,
            "editable": true,
            "showInForm": true,
            "filtrable": true,
            "copyable": false,
            "sortingValue": 1.8
        },
        "lineaAlimentazione": {
            "varianteKey": "varianteLineaAlimentazione",
            "possibleValues": [
                "Aerea",
                "Interrata",
                "A parete",
                "Sottotraccia",
                "Solare",
                "Non specificato"
            ],
            "section": 2,
            "copyable": true,
            "sortingValue": 43,
            "editable": true,
            "showInForm": true,
            "filtrable": true,
            "type": "ELENCO",
            typeForm: typeFormValue.ELENCO_APERTO,
        },
        "location": {
            "editable": false,
            "showInForm": true,
            "filtrable": false,
            "copyable": false,
            "section": 0,
            "sortingValue": 8,
            "type": "GEO_POINT"
        },
        "flussoLuminoso": {
            "sortingValue": 23,
            "type": "NUMBER",
            "editable": true,
            "showInForm": true,
            "filtrable": true,
            "copyable": true,
            "varianteKey": "varianteFlussoLuminoso",
            "section": 1
        },
        "oreRiduzioneFlusso": {
            "sortingValue": 23.01,
            "type": "NUMBER",
            "editable": true,
            "showInForm": true,
            "filtrable": true,
            "copyable": true,
            "section": 1
        },
        "percentualePotenzaInRiduzioneFlusso": {
            "sortingValue": 23.02,
            "type": "NUMBER",
            "editable": true,
            "showInForm": true,
            "filtrable": true,
            "copyable": true,
            "section": 1
        },
        "tipologiaSostegno": {
            "sortingValue": 27,
            "type": "ELENCO",
            typeForm: typeFormValue.ELENCO_APERTO,
            "editable": true,
            "showInForm": true,
            "filtrable": true,
            "copyable": true,
            "varianteKey": "varianteTipologiaSostegno",
            "section": 2,
            "possibleValues": [
                "Palina",
                "Palo dritto",
                "Palo a frusta",
                "Palo con sbraccio",
                "Tesata",
                "Mensola a muro",
                "Mensola a palo",
                "Sbraccio a muro",
                "Sbraccio a palo",
                "Staffa a muro",
                "Staffa a terra",
                "Staffa a palo",
                "PALO_CON_SBRACCIO_PASTORALE",
                "SBRACCIO_PASTORALE_A_PALO",
                "SBRACCIO_PASTORALE_A_MURO",
                "Incasso a parete",
                "Incasso a soffitto",
                "Interrato",
                "Catenaria",
                "Torre faro",
                "TESATA_PALO_PALO",
                "TESATA_PALO_PARETE",
                "TESATA_PARETE_PARETE",
                "INCASSO_SOMMERSO",
                "Altro",
                "Non specificato"
            ]
        },
        "tipologiaSezionePalo": {
            "sortingValue": 27,
            "type": "ELENCO",
            typeForm: typeFormValue.ELENCO_APERTO,
            "editable": true,
            "showInForm": true,
            "filtrable": true,
            "copyable": true,
            "section": 2,
            "possibleValues": [
                "PROPRIETA_SEZIONE_PALO_CONICO",
                "PROPRIETA_SEZIONE_PALO_RASTREMATO",
                "PROPRIETA_SEZIONE_PALO_OTTAGONALE",
                "PROPRIETA_SEZIONE_PALO_CILINDRICO",
                "Altro",
                "Non specificato",
            ]
        },
        "isBackupLight": {
            "type": "BOOL",
            "possibleValues": [true, false],
            "editable": true,
            "showInForm": true,
            "section": 2,
            "required": false,
            "sortingValue": 27.1
        }, "pozzettoPresente": {
            "type": "BOOL",
            "possibleValues": [true, false],
            "editable": true,
            "showInForm": true,
            "section": 2,
            "required": false,
            "sortingValue": 27.2
        },
        "presenzaMessaATerra": {
            "type": "BOOL",
            "possibleValues": [true, false],
            "editable": true,
            "showInForm": true,
            "section": 2,
            "required": false,
            "sortingValue": 27.3
        },
        "materialeSbraccio": {
            "type": "ELENCO",
            typeForm: typeFormValue.ELENCO_APERTO,
            "sortingValue": 29,
            "section": 2,
            "possibleValues": [
                "Alluminio",
                "Acciaio",
                "Acciaio zincato",
                "Ghisa",
                "Vetroresina",
                "Legno",
                "Cemento",
                "Ferro zincato",
                "Verniciato",
                "Altro",
                "Non specificato"
            ],
            "editable": true,
            "showInForm": true,
            "filtrable": true,
            "copyable": true
        },
        "distribuzionePuntiLuce": {
            "copyable": true,
            "type": "ELENCO",
            "sortingValue": 42,
            "editable": true,
            "showInForm": true,
            "filtrable": true,
            "section": 2,
            "possibleValues": [
                "Un lato",
                "Entrambi",
                "Quinconce",
                "Solo mezzeria",
                "Doppio mezzeria",
                "Mezzeria e lati",
                "Doppio un lato",
                "Doppio entrambi",
                "Doppio quinconce",
                "Altro",
                "Non specificato"
            ]
        },
        "promiscuita": {
            "sortingValue": 44,
            "section": 2,
            "type": "ELENCO",
            typeForm: typeFormValue.ELENCO_APERTO,
            "possibleValues": [
                "Elettrica",
                "Meccanica",
                "Elettrica e meccanica",
                "QUINTO_FILO",
                "Non promiscuo",
                "Non specificato"
            ],
            "editable": true,
            "showInForm": true,
            "filtrable": true,
            "copyable": true
        },
        "proprietaAmministrazioneComunale": {
            "copyable": true,
            "section": 0,
            "type": "BOOL",
            "possibleValues": [true, false],
            "sortingValue": 17,
            "editable": true,
            "showInForm": true,
            "filtrable": true,
        },
        "proprietario": {
            "copyable": true,
            "section": 0,
            "type": "ELENCO",
            typeForm: typeFormValue.ELENCO_APERTO,
            "possibleValues": [
                'PROPRIETA_PUNTO_LUCE_COMUNE',
                'PROPRIETA_PUNTO_LUCE_SOLE',
                'PROPRIETA_PUNTO_LUCE_EX_SOLE',
                'Altro',
                'Non specificato',
            ],
            "sortingValue": 17.1,
            "editable": true,
            "showInForm": true,
            "filtrable": true,
        },
        "inPrivateProperty": {
            "copyable": true,
            "section": 0,
            "type": "BOOL",
            "possibleValues": [true, false],
            "sortingValue": 17.2,
            "editable": true,
            "showInForm": true,
            "filtrable": true,
        },
        "lotto": {
            "copyable": true,
            "sortingValue": 17.03,
            "section": 0,
            "type": "TEXT",
            "editable": true,
            "showInForm": true,
            "filtrable": true,
        },
        "presenzaCaricoEsogenoElettrico": {
            "type": "BOOL",
            "possibleValues": [true, false],
            "editable": true,
            "showInForm": true,
            "section": 0,
            "required": false,
            "sortingValue": 17.4
        },
        "idMezzanotte": {
            "type": "NUMBER",
            "editable": true,
            "editableMassive": false,
            "copyable": false,
            "showInForm": true,
            "filtrable": true,
            "section": 0,
            "sortingValue": 6
        },
        "idGruppoLightMate": {
            "type": "NUMBER",
            "editable": false,
            "copyable": false,
            "showInForm": true,
            "filtrable": true,
            "section": 0,
            "sortingValue": 6.1
        },
        "note": {
            "copyable": true,
            "sortingValue": 9,
            "section": 0,
            "type": "TEXT",
            "editable": true,
            "showInForm": true,
            "filtrable": true,
        },
        "noteCorpoIlluminante": {
            "copyable": true,
            "sortingValue": 9.1,
            "section": 0,
            "type": "TEXT",
            "editable": true,
            "showInForm": true,
            "filtrable": true,
        }, "noteSostegno": {
            "copyable": true,
            "sortingValue": 9.1,
            "section": 0,
            "type": "TEXT",
            "editable": true,
            "showInForm": true,
            "filtrable": true,
        },
        "noteLinea": {
            "copyable": true,
            "sortingValue": 9.1,
            "section": 0,
            "type": "TEXT",
            "editable": true,
            "showInForm": true,
            "filtrable": true,
        },
        "statoDiConservazionePozzetto": {
            "section": 2,
            "sortingValue": 37,
            "possibleValues": [
                "Accettabile",
                "Da riverniciare",
                "Da sostituire",
                "Mancante",
                "Da riposizionare",
                "Pericoloso",
                "Da verificare",
                "Non specificato"
            ],
            "editable": true,
            "showInForm": true,
            "filtrable": true,
            "type": "ELENCO",
            typeForm: typeFormValue.ELENCO_APERTO,
            "copyable": true
        },
        "circuito": {
            "sortingValue": 2,
            "section": 0,
            "possibleValues": "Circuiti.numeroQuadro",
            "copyable": false,
            "editable": true,
            "showInForm": true,
            "filtrable": true,
            "type": "POINTER_CIRCUITI",
            typeForm: typeFormValue.ELENCO_APERTO,
            "varianteKey": "varianteCircuito"
        },
        "strada": {
            "sortingValue": 15,
            "possibleValues": "Strada.nome",
            "type": "POINTER_STRADE",
            typeForm: typeFormValue.ELENCO_APERTO,
            "editable": true,
            "showInForm": true,
            "filtrable": true,
            "copyable": false,
            "section": 0
        },
        "classificazioneStrada": {
            "sortingValue": 15.09,
            "type": "TEXT",
            "editable": true,
            "showInForm": true,
            "filtrable": true,
            "copyable": false,
            "section": 0
        },
        "puntoLucePadre": {
            "sortingValue": 15.1,
            "possibleValues": "PuntoLuce.targa",
            "type": "POINTER_PUNTOLUCE",
            typeForm: typeFormValue.ELENCO_APERTO,
            "editable": true,
            "showInForm": true,
            "filtrable": false,
            "copyable": false,
            "section": 0
        },
        "indirizzo": {
            "sortingValue": 7,
            "section": 0,
            "type": "TEXT",
            "editable": false,
            "showInForm": true,
            "filtrable": false,
            "copyable": false
        },
        "potenzaNominale": {
            "varianteKey": "variantePotenzaNominale",
            "sortingValue": 21,
            "section": 1,
            "copyable": true,
            "type": "NUMBER",
            "editable": true,
            "showInForm": true,
            "filtrable": true,

        },
        "targa": {
            "editable": false,
            "showInForm": true,
            "type": "TARGA",
            "copyable": false,
            "section": 0,
            "sortingValue": 12,
            "filtrable": true,
        },
        "tipologiaSorgenteLuminosa": {
            "type": "ELENCO",
            "possibleValues": [
                "LED",
                "SAP",
                "SBP",
                "JM",
                "HG",
                "ALO",
                "FL",
                "INC",
                "Neon",
                "SORGENTE_MISCELATA",
                "Altro",
                "Non specificato"
            ],
            "editable": true,
            "showInForm": true,
            "section": 1,
            "sortingValue": 19,
            "copyable": true,
            "filtrable": true,
            "varianteKey": "varianteSorgenteLuminosa"
        },
        "tipologiaCorpoIlluminante": {
            "editable": true,
            "filtrable": true,
            "showInForm": true,
            "section": 1,
            "type": "ELENCO",
            typeForm: typeFormValue.ELENCO_APERTO,
            "possibleValues": [
                "Armatura stradale",
                "Armatura design",
                "Armatura circolare",
                "Campana",
                "Fungo",
                "Lanterna",
                "Lanternina",
                "Lampara",
                "Lampara a goccia",
                "Globo",
                "Plafoniera",
                "Lineare",
                "Faro",
                "Faretto",
                "Proiettore artistico",
                "Segnapasso",
                "Segnale luminoso lampeggiante",
                "Segnale luminoso attraversamento",
                "Parabola",
                "Strip",
                "Altro",
                "Non specificato"
            ],
            "sortingValue": 18,
            "varianteKey": "varianteCorpoIlluminante",
            "copyable": true
        },
        "bearingAngle": {
            "editable": true,
            "filtrable": true,
            "showInForm": true,
            "section": 1,
            "type": "NUMBER",
            typeForm: typeFormValue.INPUT_NUMBER,
            "sortingValue": 18,
            "copyable": true
        },
        "updatedAt": {
            "copyable": false,
            "section": 0,
            "sortingValue": 1,
            "type": "DATE",
            "editable": false,
            "showInForm": true,
            "filtrable": true,

        },
        "artistico": {
            "section": 0,
            "type": "BOOL",
            "possibleValues": [true, false],
            "copyable": true,
            "sortingValue": 3,
            "editable": true,
            "showInForm": true,
            "filtrable": true,


        },
        "fornitoreSorgenteLuminosa": {
            "section": 1,
            "type": "TEXT",
            "editable": true,
            "showInForm": true,
            "sortingValue": 25,
            "copyable": true,
            "filtrable": true,
            "varianteKey": "varianteFornitoreSorgenteLuminosa"
        },
        "materialeSostegno": {
            "sortingValue": 28,
            "section": 2,
            "copyable": true,
            "editable": true,
            "showInForm": true,
            "filtrable": true,
            "varianteKey": "varianteMaterialeSostegno",
            "type": "ELENCO",
            typeForm: typeFormValue.ELENCO_APERTO,
            "possibleValues": [
                "Alluminio",
                "Acciaio",
                "Acciaio zincato",
                "Ghisa",
                "Vetroresina",
                "Legno",
                "Cemento",
                "Ferro zincato",
                "Verniciato",
                "Altro",
                "Non specificato"
            ]
        },
        "statoDiConservazioneLineaAlimentazione": {
            "editable": true,
            "showInForm": true,
            "filtrable": true,
            "section": 2,
            "possibleValues": [
                "Accettabile",
                "Da sostituire",
                "Da interrare",
                "Da manutenere",
                "Pericoloso",
                "Mancante",
                "Da verificare"
            ],
            "copyable": true,
            "type": "ELENCO",
            typeForm: typeFormValue.ELENCO_APERTO,
            "sortingValue": 34
        },
        "statoDiConservazioneCollarino": {
            "section": 2,
            "copyable": true,
            "filtrable": true,
            "type": "ELENCO",
            typeForm: typeFormValue.ELENCO_APERTO,
            "sortingValue": 35,
            "editable": true,
            "showInForm": true,
            "possibleValues": [
                "Accettabile",
                "Da riverniciare",
                "Da sostituire",
                "Mancante",
                "Da riposizionare",
                "Pericoloso",
                "Da verificare",
                "Non specificato"
            ]
        },
        "statoCaricoEsogeno": {
            "possibleValues": [
                "Accettabile",
                "DA_ELIMINARE",
                "DA_RIALIMENTARE",
                "DA_SEGNALARE",
                "NON_ATTIVO",
                "Da verificare",
            ],
            "sortingValue": 38,
            "section": 2,
            "editable": true,
            "showInForm": true,
            "type": "ELENCO",
            typeForm: typeFormValue.ELENCO_APERTO,
            "filtrable": true,
            "copyable": true
        },
        "tipologiaVetroChiusura": {
            "possibleValues": [
                "Vetro piano",
                "Diffondente",
                "Senza vetro",
                "Vetro piano da tiltare",
                "Vetro curvo",
                "Vetro curvo con cut-off",
                "Vetro diffondente con cut-off",
                "Altro",
                "Non specificato"
            ],
            "sortingValue": 38.1,
            "section": 2,
            "editable": true,
            "showInForm": true,
            "type": "ELENCO",
            typeForm: typeFormValue.ELENCO_APERTO,
            "filtrable": true,
            "copyable": true
        },
        "etaSostegno": {
            "sortingValue": 38.2,
            "editable": true,
            "filtrable": true,
            "showInForm": true,
            "possibleValues": [
                "m_tre_anni",
                "tre_dieci_anni",
                "M_dieci_anni",
                "Non specificato",
            ],
            "section": 2,
            "copyable": true,
            "type": "ELENCO",
            typeForm: typeFormValue.ELENCO_APERTO
        },
        "altezza": {
            "section": 2,
            "copyable": true,
            "type": "NUMBER",
            "sortingValue": 39,
            "editable": true,
            "filtrable": true,
            "showInForm": true,
        },
        "altezzaPalo": {
            "section": 2,
            "copyable": true,
            "type": "NUMBER",
            "sortingValue": 39.01,
            "editable": true,
            "filtrable": true,
            "showInForm": true,
        },
        "modelloSorgenteLuminosa": {
            "editable": true,
            "filtrable": true,
            "showInForm": true,
            "copyable": true,
            "section": 1,
            "varianteKey": "varianteModelloSorgenteLuminosa",
            "type": "TEXT",
            "sortingValue": 26
        },
        "statoDiConservazioneMorsettiera": {
            "sortingValue": 36,
            "type": "ELENCO",
            typeForm: typeFormValue.ELENCO_APERTO,
            "copyable": true,
            "editable": true,
            "filtrable": true,
            "showInForm": true,
            "possibleValues": [
                "Accettabile",
                "Da riverniciare",
                "Da sostituire",
                "Mancante",
                "Da riposizionare",
                "Pericoloso",
                "Da verificare",
                "Non specificato"
            ],
            "section": 2
        },
        "canone": {
            "section": 0,
            "sortingValue": 16,
            "editable": true,
            "filtrable": true,
            "showInForm": true,
            "copyable": true,
            "type": "NUMBER"
        },
        "numeroLampade": {
            "editable": true,
            "showInForm": true,
            "copyable": true,
            "filtrable": true,
            "varianteKey": "varianteNumeroLampade",
            "sortingValue": 20,
            "section": 1,
            "type": "INT"
        },
        "attivo": {
            "editable": true,
            "showInForm": true,
            "copyable": true,
            "filtrable": true,
            "type": "BOOL",
            "possibleValues": [true, false],
            "sortingValue": 4,
            "section": 0
        },
        "potenzaEffettiva": {
            "sortingValue": 22,
            "type": "NUMBER",
            "editable": true,
            "showInForm": true,
            "varianteKey": "variantePotenzaEffettiva",
            "copyable": true,
            "filtrable": true,
            "section": 1
        },
        "materialePozzetto": {
            "sortingValue": 30,
            "editable": true,
            "showInForm": true,
            "filtrable": true,
            "possibleValues": [
                "Alluminio",
                "Acciaio",
                "Acciaio zincato",
                "Ghisa",
                "Vetroresina",
                "Legno",
                "Cemento",
                "Ferro zincato",
                "Verniciato",
                "Altro",
                "Non specificato"
            ],
            "section": 2,
            "copyable": true,
            "type": "ELENCO",
            typeForm: typeFormValue.ELENCO_APERTO
        },
        "targaCustom": {
            "section": 0,
            "sortingValue": 13,
            "type": "TEXT",
            "copyable": false,
            "filtrable": true,
            "editable": true,
            "showInForm": true,
        },
        "attaccoCorpoIlluminante": {
            "sortingValue": 39.1,
            "editable": true,
            "filtrable": true,
            "showInForm": true,
            "possibleValues": [
                "D30",
                "D40",
                "D50",
                "D60",
                "D70",
                "D80",
                "D90",
                "D100",
                "D110",
                "D120",
                "Altro",
                "Non specificato"
            ],
            "section": 2,
            "copyable": true,
            "type": "ELENCO",
            typeForm: typeFormValue.ELENCO_APERTO
        },
        "orientamentoAttaccoCorpoIlluminante": {
            "sortingValue": 39.2,
            "editable": true,
            "showInForm": true,
            "filtrable": true,
            "possibleValues": [
                "ATTACCO_ALTO",
                "ATTACCO_BASSO",
                "ATTACCO_LATERALE",
                "TESTA_PALO",
                "Altro",
                "Non specificato"
            ],
            "section": 2,
            "copyable": true,
            "type": "ELENCO",
            typeForm: typeFormValue.ELENCO_APERTO
        },
        "tipologiaCaricoEsogeno": {
            "sortingValue": 17.1,
            "editable": true,
            "showInForm": true,
            "filtrable": true,
            "possibleValues": [
                "VIDEOCAMERA",
                "HOTSPOT_WI_FI",
                "SENSORE_TRAFFICO",
                "SENSORE_AMBIENTALE",
                "PANNELLO_MESSAGGIO_VARIABILE",
                "Altro",
                "Non specificato"
            ],
            "section": 0,
            "copyable": true,
            "type": "ELENCO",
            typeForm: typeFormValue.ELENCO_APERTO
        },
        "IPEA2013": {
            "sortingValue": 26.1,
            "editable": true,
            "filtrable": true,
            "showInForm": true,
            "possibleValues": [
                "IPEA_App",
                "IPEA_Ap",
                "IPEA_A",
                "IPEA_B",
                "IPEA_C",
                "IPEA_D",
                "IPEA_E",
                "IPEA_F",
                "IPEA_G",
                "Non specificato"
            ],
            "section": 1,
            "copyable": true,
            "varianteKey": "varianteIPEA2013",
            "type": "ELENCO"
        },
        "IPEA2018": {
            "sortingValue": 26.2,
            "editable": true,
            "filtrable": true,
            "showInForm": true,
            "possibleValues": [
                "IPEA_A20p",
                "IPEA_A19p",
                "IPEA_A18p",
                "IPEA_A17p",
                "IPEA_A16p",
                "IPEA_A16p",
                "IPEA_A15p",
                "IPEA_A14p",
                "IPEA_A13p",
                "IPEA_A12p",
                "IPEA_A11p",
                "IPEA_A10p",
                "IPEA_A9p",
                "IPEA_A8p",
                "IPEA_A7p",
                "IPEA_A6p",
                "IPEA_A5p",
                "IPEA_A4p",
                "IPEA_A3p",
                "IPEA_App",
                "IPEA_Ap",
                "IPEA_A",
                "IPEA_B",
                "IPEA_C",
                "IPEA_D",
                "IPEA_E",
                "IPEA_F",
                "IPEA_G",
                "Non specificato"
            ],
            "section": 1,
            "copyable": true,
            "varianteKey": "varianteIPEA2018",
            "type": "ELENCO"
        },
        "etaCorpoIlluminante": {
            "sortingValue": 26.3,
            "editable": true,
            "filtrable": true,
            "showInForm": true,
            "possibleValues": [
                "m_tre_anni",
                "tre_cinque_anni",
                "cinque_dieci_anni",
                "M_dieci_anni",
                "Non specificato",
            ],
            "section": 1,
            "copyable": true,
            "type": "ELENCO",
            typeForm: typeFormValue.ELENCO_APERTO
        },
        "presenzaCutOff": {
            "type": "BOOL",
            "possibleValues": [true, false],
            "editable": true,
            "showInForm": true,
            "section": 1,
            "required": false,
            "sortingValue": 26.4
        }
    },
    "Circuiti": {
        "regolazioneFlusso": {
            "section": 2,
            "sortingValue": 35,
            "editable": true,
            "filtrable": true,
            "showInForm": true,
            "required": false,
            "possibleValues": [
                "PUNTO_PUNTO",
                "VIRTUAL_MIDNIGHT",
                "DA_QUADRO",
                "NON_PRESENTE",
                "Non specificato"
            ],
            "type": "ELENCO"
        },
        "numeroOreFlussoRidotto": {
            "editable": true,
            "showInForm": true,
            "filtrable": true,
            "type": "NUMBER",
            "required": false,
            "sortingValue": 36,
            "section": 2
        },
        "numeroSIM": {
            "editable": true,
            "showInForm": true,
            "filtrable": true,
            "sortingValue": 11,
            "required": false,
            "section": 0,
            "type": "TEXT"
        },
        "statoOperativo": {
            "possibleValues": [
                "ATTIVO",
                "NON_ATTIVO",
                "IN_COSTRUZIONE",
                "IN_DISUSO",
                "IN_MANUTENZIONE",
                "ATTIVO_DA_RIMUOVERE",
                "NON_ATTIVO_DA_RIMUOVERE",
                "DA_REALIZZARE",
                "Altro",
                "Non specificato"
            ],
            "editable": true,
            "showInForm": true,
            "filtrable": true,
            "section": 0,
            "sortingValue": 8,
            "type": "ELENCO",
            "required": false
        },
        "proprietaCabina": {
            "possibleValues": [
                "COMUNE",
                "PROMISCUO",
                "DISTRIBUTORE",
                "FUORI_CABINA",
                "Altro",
                "Non specificato"
            ],
            "editable": true,
            "showInForm": true,
            "filtrable": true,
            "section": 0,
            "sortingValue": 8.1,
            "type": "ELENCO",
            "required": false
        },
        "tipologiaContrattoEnergia": {
            "sortingValue": 12,
            "possibleValues": [
                "NAZIONALE",
                "MERCATO_LIBERO",
                "MAGGIOR_TUTELA",
                "SALVAGUARDIA",
                "FTT",
                "Altro",
                "Non specificato"
            ],
            "editable": true,
            "showInForm": true,
            "filtrable": true,
            "type": "ELENCO",
            "section": 1,
            "required": false
        },
        "tipologiaContrattoGestione": {
            "editable": true,
            "showInForm": true,
            "filtrable": true,
            "section": 2,
            "possibleValues": [
                "NAZIONALE",
                "MANUTENZIONE_SEMPLICE",
                "CONCESSIONE",
                "INTERNA",
                "Altro",
                "Non specificato"
            ],
            "type": "ELENCO",
            "sortingValue": 40,
            "required": false
        },
        "statoDiConservazione": {
            "editable": true,
            "showInForm": true,
            "filtrable": true,
            "type": "ELENCO",
            "possibleValues": [
                "NEW",
                "GOOD",
                "BAD",
                "DANGEROUS",
                "SPORTELLO_ROTTO",
                "Non specificato"
            ],
            "required": false,
            "sortingValue": 9,
            "section": 0
        },
        "numeroFasi": {
            "type": "INT",
            "editable": true,
            "showInForm": true,
            "required": false,
            "filtrable": true,
            "sortingValue": 15,
            "section": 1,
            "possibleValues": [
                1,
                3
            ]
        },
        "tipologiaAccensione": {
            "type": "ELENCO",
            "editable": true,
            "showInForm": true,
            "section": 2,
            "required": false,
            "filtrable": true,
            "possibleValues": [
                "CREPUSCOLARE",
                "ASTRONOMICAL_CLOCK",
                "TELEGESTIONE",
                "OROLOGIO",
                "MANUALE",
                "OROLOGIO_ASTRONOMICO_TELEGESTIONE",
                "Altro",
                "Non specificato"
            ],
            "sortingValue": 30
        },
        "costoAnnualeAttuale": {
            "section": 1,
            "type": "NUMBER",
            "editable": true,
            "showInForm": true,
            "filtrable": true,
            "required": false,
            "sortingValue": 23
        },
        "canReceiveAllarmiRemoti": {
            "sortingValue": 39,
            "section": 2,
            "type": "BOOL",
            "possibleValues": [true, false],
            "required": false,
            "filtrable": true,
            "editable": true,
            "showInForm": true,
        },
        "nomeAffidatarioGestione": {
            "section": 2,
            "required": false,
            "sortingValue": 41,
            "editable": true,
            "filtrable": true,
            "showInForm": true,
            "type": "TEXT"
        },
        "oreAnnualiAccensione": {
            "editable": true,
            "showInForm": true,
            "sortingValue": 31,
            "type": "NUMBER",
            "filtrable": true,
            "required": false,
            "section": 2
        },
        "valoreMedioParzializzazione": {
            "section": 2,
            "editable": true,
            "showInForm": true,
            "filtrable": true,
            "required": false,
            "sortingValue": 34,
            "type": "NUMBER"
        },
        "consumoAnnualeRiferimento": {
            "required": false,
            "sortingValue": 20,
            "section": 1,
            "editable": true,
            "showInForm": true,
            "filtrable": true,
            "type": "NUMBER"
        },
        "POD": {
            "sortingValue": 3,
            "section": 0,
            "type": "TEXT",
            "editable": true,
            "showInForm": true,
            "filtrable": true,
            "required": false
        },
        "circuitoPadre": {
            "sortingValue": 3.1,
            "section": 0,
            "possibleValues": "Circuiti.numeroQuadro",
            "editable": true,
            "showInForm": true,
            "filtrable": true,
            "type": "POINTER_CIRCUITI",
            typeForm: typeFormValue.ELENCO_APERTO
        },
        "circuitoAccorpamento": {
            "sortingValue": 3.2,
            "section": 0,
            "possibleValues": "Circuiti.numeroQuadro",
            "editable": true,
            "showInForm": true,
            "filtrable": true,
            "type": "POINTER_CIRCUITI",
            typeForm: typeFormValue.ELENCO_APERTO
        },
        "lotto": {
            "copyable": true,
            "sortingValue": 3.3,
            "section": 0,
            "type": "TEXT",
            "editable": true,
            "showInForm": true,
            "filtrable": true,
        },
        "valoreMedioRiduzionePotenza": {
            "sortingValue": 37,
            "section": 2,
            "editable": true,
            "showInForm": true,
            "required": false,
            "type": "NUMBER"
        },
        "indirizzo": {
            "section": 0,
            "type": "TEXT",
            "editable": false,
            "showInForm": true,
            "required": true,
            "sortingValue": 5
        },
        "createdAt": {
            "editable": false,
            "showInForm": true,
            "required": false,
            "sortingValue": 0,
            "section": 0,
            "type": "DATE"
        },
        "numeroQuadro": {
            "sortingValue": 2,
            "section": 0,
            "type": "TARGA",
            "required": true,
            "editable": true,
            "showInForm": true,

        },
        "tipologiaLineaAlimentazione": {
            "sortingValue": 26,
            "section": 1,
            "editable": true,
            "showInForm": true,
            "type": "ELENCO",
            "possibleValues": [
                "Aerea",
                "Interrata",
                "INTERRATA_CON_RISALITA",
                "PRECORDATO_SU_PALO",
                "PRECORDATO_A_PARETE",
                "SU_FUNE_SU_PALO",
                "SU_FUNE_A_PARETE",
                "CONDUTTORI_NUDI",
                "A parete",
                "Sottotraccia",
                "Solare",
                "Non specificato"
            ],
            "required": false
        },
        "classeIsolamento": {
            "possibleValues": [
                0,
                1,
                2
            ],
            "required": false,
            "section": 1,
            "type": "INT",
            "sortingValue": 27,
            "editable": true,
            "showInForm": true
        },
        "consumoAnnualeAttuale": {
            "editable": true,
            "showInForm": true,
            "required": false,
            "type": "NUMBER",
            "sortingValue": 22,
            "section": 1
        },
        "numeroOreParzializzazione": {
            "required": false,
            "section": 2,
            "sortingValue": 33,
            "editable": true,
            "showInForm": true,
            "type": "NUMBER"
        },
        "accensioneParzializzata": {
            "type": "BOOL",
            "possibleValues": [true, false],
            "editable": true,
            "showInForm": true,
            "section": 2,
            "required": false,
            "sortingValue": 32
        },
        "notaIndirizzo": {
            "required": false,
            "sortingValue": 6,
            "section": 0,
            "editable": true,
            "showInForm": true,
            "type": "TEXT"
        },
        "numeroCircuitiInUscita": {
            "section": 1,
            "required": false,
            "editable": true,
            "showInForm": true,
            "sortingValue": 29,
            "type": "INT"
        },
        "gestoreSIM": {
            "sortingValue": 10,
            "section": 0,
            "editable": true,
            "showInForm": true,
            "type": "TEXT",
            "required": false
        },
        "updatedAt": {
            "sortingValue": 1,
            "editable": false,
            "showInForm": true,
            "required": false,
            "section": 0,
            "type": "DATE"
        },
        "tipologiaCaricoEsogeno": {
            "editable": true,
            "showInForm": true,
            "required": false,
            "section": 1,
            "possibleValues": [
                "PRESENTE",
                "ASSENTE",
                "PERMANENTE",
                "TEMPORANEO"
            ],
            "type": "ELENCO",
            "sortingValue": 25
        },
        "note": {
            "sortingValue": 42,
            "editable": true,
            "showInForm": true,
            "required": false,
            "section": 2,
            "type": "TEXT"
        },
        "tipologiaTelecontrollo": {
            "section": 2,
            "sortingValue": 38,
            "editable": true,
            "showInForm": true,
            "possibleValues": [
                "PRESENTE",
                "STATICO",
                "ADATTIVO",
                "COMPLETO",
                "ASSENTE"
            ],
            "type": "ELENCO",
            "required": false
        },
        "tipologiaAccesso": {
            "type": "ELENCO",
            "editable": true,
            "showInForm": true,
            "sortingValue": 7,
            "section": 0,
            "required": false,
            "possibleValues": [
                "ESTERNA",
                "INTERNA",
                "SU_PALO",
                "BASAMENTO",
                "A_PARETE",
                "INTERRATO",
                "IN_CABINA_ENEL",
                "ESTERNO_A_PARETE",
                "ESTERNO_SU_BASAMENTO",
                "Altro",
                "Non specificato"
            ]
        },
        "annoRiferimentoConsumi": {
            "required": false,
            "editable": true,
            "showInForm": true,
            "section": 1,
            "type": "INT",
            "sortingValue": 19
        },
        "fattoreDiPotenzaAttuale": {
            "editable": true,
            "showInForm": true,
            "required": false,
            "section": 1,
            "type": "NUMBER",
            "sortingValue": 24
        },
        "indirizzoFornitura": {
            "editable": true,
            "showInForm": true,
            "required": false,
            "type": "TEXT",
            "sortingValue": 13,
            "section": 1
        },
        "tensioneNominale": {
            "editable": true,
            "showInForm": true,
            "sortingValue": 14,
            "section": 1,
            "type": "NUMBER",
            "required": false
        },
        "protezioneGenerale": {
            "type": "ELENCO",
            "possibleValues": [
                "NESSUNA",
                "DIFFERENZIALE",
                "MAGNETOTERMICO",
                "MAGNETOTERMICO_DIFFERENZIALE",
                "Non specificato"
            ],
            "sortingValue": 28,
            "section": 1,
            "editable": true,
            "showInForm": true,
            "required": false
        },
        "location": {
            "section": 0,
            "sortingValue": 4,
            "editable": false,
            "showInForm": true,
            "required": true,
            "type": "GEO_POINT"
        },
        "distributoreEnergia": {
            "section": 1,
            "editable": true,
            "showInForm": true,
            "required": false,
            "sortingValue": 17,
            "type": "TEXT"
        },
        "fornitoreEnergia": {
            "type": "TEXT",
            "required": false,
            "section": 1,
            "editable": true,
            "showInForm": true,
            "sortingValue": 18
        },
        "potenzaImpegnata": {
            "sortingValue": 16,
            "required": false,
            "editable": true,
            "showInForm": true,
            "section": 1,
            "type": "NUMBER"
        },
        "costoAnnualeRiferimento": {
            "sortingValue": 21,
            "type": "NUMBER",
            "editable": true,
            "showInForm": true,
            "required": false,
            "section": 1
        },
        "foto": {
            "sortingValue": 100,
            "possibleValues": "fotoPuntiLuce.titolo",
            "type": "POINTER_fotoCircuito",
            getValuePointer: (c: CircuitiParse) => {
                // bisogna tornare tuttle chiavi vengono visualizzate sull export
                const obj = {fotoEsterno: undefined, fotoInterno: undefined};
                if (c != null && c.foto != null && (c.foto.fotoEsterno != null || c.foto.fotoInterno != null)) {
                    if (c.foto.fotoEsterno != null) {
                        obj.fotoEsterno = c.foto.fotoEsterno.url();
                    }
                    if (c.foto.fotoInterno != null) {
                        obj.fotoInterno = c.foto.fotoInterno.url();
                    }
                }
                return obj;
            },
            getValueInLabel: (circuito: CircuitiParse) => {
                return {
                    key: circuito && (circuito.foto != null) && (circuito.foto.fotoEsterno != null || circuito.foto.fotoInterno != null),
                    showingLikeType: 'bool'
                };
            },
            "showInLabel": true,
            "editable": false,
            "showInForm": false,
            "filtrable": true,
            "copyable": false,
            "section": 0
        },
    },
    "PictureCameraPoint": {
        "createdAt": {
            "section": 0,
            "editable": false,
            "showInForm": true,
            "sortingValue": 0,
            "type": "DATE"
        },
        "updatedAt": {
            "type": "DATE",
            "section": 0,
            "sortingValue": 1,
            "editable": false,
            "showInForm": true,
        },
        "name": {
            "editable": true,
            "showInForm": true,
            "type": "TEXT",
            "section": 0,
            "sortingValue": 2
        },
        "lunghezzaLato": {
            "editable": true,
            "showInForm": true,
            "type": "NUMBER",
            "section": 0,
            "sortingValue": 3
        }
    }, "ArredoUrbano": {
        "createdAt": {
            "section": 100,
            "editable": false,
            "showInForm": true,
            "sortingValue": 0,
            "type": "DATE"
        },
        "updatedAt": {
            "type": "DATE",
            "section": 0,
            "sortingValue": 200,
            "editable": false,
            "showInForm": true,
        },
        "location": {
            "section": 0,
            "sortingValue": 300,
            "editable": false,
            "showInForm": true,
            "required": true,
            "type": "GEO_POINT"
        },
        "foto": {
            "copyable": false,
            "section": 500,
            "type": "FILE",
            "possibleValues": null,
            "sortingValue": 100,
            "editable": false,
            "showInForm": false,
            "filtrable": true
        },
        "tipologia": {
            "sortingValue": 400,
            "section": 0,
            "editable": true,
            "showInForm": true,
            "type": "ELENCO",
            "possibleValues": [
                "attraversamentoPedonale",
                "bikeSharing",
                "cassonetto",
                "cestino",
                "colonnineRicaricaElettrica",
                "dissuasore",
                "dosso",
                "fermataBus",
                "fermataTaxi",
                "fermataTram",
                "fontana",
                "fotoZona",
                "panchina",
                "parcoGiochi",
                "parcometro",
                "rastrelliera",
                "segnaleStradale",
                "semaforo",
                "telecamera",
                "tombino"
            ],
            "required": false
        },
        "note": {
            "copyable": true,
            "sortingValue": 500,
            "section": 0,
            "type": "TEXT",
            "editable": true,
            "showInForm": true,
            "filtrable": true,
        },
    },
}
