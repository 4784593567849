import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {MapService} from '../../../providers/services/map.service';
import {ActivitiesService} from "../../../providers/services/activities.service";
import {AlertService} from "../../../providers/services/alert.service";
import {TranslateService} from "@ngx-translate/core";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {UtilsService} from "../../../providers/services/utils.service";
import {AddingTabInComponentService} from "../../../providers/services/adding-tab-in-component.service";
import {SchedaManutenzioneCompilataParse} from "../../../models/SchedaManutenzioneCompilata.Parse";
import {DialogPopUpInfoService} from "../../../providers/services/dialog-pop-up-info.service";
import {isNotNullOrUndefined, stringIsSet} from "../../../models/Models";

@Component({
    selector: 'app-list-activities',
    templateUrl: './list-activities.component.html',
    styleUrls: ['./list-activities.component.scss']
})
export class ListActivitiesComponent implements OnInit {

    @ViewChild('activityScrollWrapper')
    activityScrollWrapper: ElementRef<HTMLDivElement>;
    public activities;
    public loading = true;
    public skip = 0;
    pageSize = 25;
    totalEntries: number|undefined = undefined;
    activityListLastScrollTop = 0;
    constructor(private router: Router,
                protected mapService: MapService,
                private activitiesService: ActivitiesService,
                private alertService: AlertService,
                public translate: TranslateService,
                public dialog: MatDialog,
                private utilsService: UtilsService,
                private addingTab: AddingTabInComponentService,
                private popUpInfoService: DialogPopUpInfoService,

    ) {
        this.loadData();

    }

    ngOnInit() {

    }

    onActivityListScroll($event: Event) {
        if ('scrollTop' in $event.target) {
            this.activityListLastScrollTop = ($event.target as HTMLDivElement).scrollTop;
        }
    }

    onTabChange({ value }: { value: number }) {
        if (value === 0 && this.activityScrollWrapper.nativeElement) {
            setTimeout(() => {
                this.activityScrollWrapper.nativeElement.scrollTo({ top: this.activityListLastScrollTop, behavior: 'smooth' });
            }, 1);
        }
    }
    private async loadData() {
        this.loading = true;
        try {
            this.activities = await this.activitiesService.getActivitiesOfProject(this.skip, this.pageSize);
            if (this.activities.length < this.pageSize) {
                this.totalEntries = this.skip + this.activities.length;
            }
            if (this.skip > 0 && this.activities.length == 0) {
                this.skip -= this.pageSize;
                this.loadData();
            }
        } catch (e) {
            this.alertService.error(this.translate.instant('error_verified'));
        } finally {
            this.loading = false;
        }
    }

    public openedActiviesList = [];
    public indexTab;
    public forceUpdateTab: boolean;


    public onUpdateElement(newActivity) {
        const element = this.addingTab.addingElement(this.openedActiviesList, newActivity);
        this.openedActiviesList = element.openedList;
        this.forceUpdateTab = !this.forceUpdateTab;
        this.indexTab = {value:(element.index >= 0) ? element.index + 1 : 1};
        // this.router.navigate(['segnalazioni/modifica/', event]);
    }

    public onFirst() {
        this.skip = 0;
        this.loadData();
    }

    onPageSizeChange(newPageSize: number) {
        this.pageSize = newPageSize;
        this.loadData();
    }

    changePage(direction: 'left'|'right') {
        this.skip += direction === 'left' ? (-this.pageSize) : this.pageSize;
        if (this.skip < 0) { this.skip = 0; }
        this.loadData();
    }

    onOpenImage(activity, event) {
        event.stopPropagation();
        this.popUpInfoService.openVisualizedImage(activity.foto.url(), 'image')

    }

    onOpenPdf(activity, event) {
        event.stopPropagation();
        window.open(activity.allegato.url());
    }

    public itemModified(event) {
        switch (event.action) {
            case  'close':
                this.openedActiviesList = this.addingTab.removingElement(this.openedActiviesList, event.item);
                return 'close';
        }
    }

    showAlert(schedaManutenzione: SchedaManutenzioneCompilataParse) {
        return schedaManutenzione.rifiutata == true;
    }


    getSplitNameObject(obj: any, key: string) {
        if (isNotNullOrUndefined(obj) && isNotNullOrUndefined(obj.allegato) && isNotNullOrUndefined(obj.allegato[key]) && stringIsSet(obj.allegato[key])) {
            return obj.allegato[key].split('_')[1];
        }
    }
}