import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MapService} from '../../../providers/services/map.service';
import {FilterService} from "../../../providers/services/filter.service";
import {typeFormValue} from "../../../models/configurationProperty/configurationPropertyUtils";
import {dataForm} from "../../confirm-delete/select-or-create/select-or-create.component";
import {TransformForTranslatePipe} from "../../../providers/pipes/transform-for-translate.pipe";
import {arrayIsSet, isNotNullOrUndefined} from "../../../models/Models";
import {FotoTipologiaService} from "../../../providers/services/foto-tipologia.service";
import {map} from "rxjs/operators";
import {StreetsService} from "../../../providers/services/streets.service";

enum Operators {
    NULL = 'IS NULL',
    EXIST = 'EXIST'
}

@Component({
    selector: 'app-filter-dialog',
    templateUrl: './filter-dialog.component.html',
    styleUrls: ['./filter-dialog.component.scss']
})
export class FilterDialogComponent implements OnInit {
    Object = Object;
    public filters: any[];
    public filtersActive: any[] = [];
    public filterForm: UntypedFormGroup;
    public promises = {};
    public valuesAutoCompleteData = {};


    constructor(
        private mapService: MapService,
        private streetService: StreetsService,
        private filterService: FilterService,
        private fotoTipologiaService: FotoTipologiaService,
        public dialogRef: MatDialogRef<FilterDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
        private fb: UntypedFormBuilder
    ) {
        this.loadData();
        this.initForm();

        if (Object.keys(this.filterForm.get('filters').value).length === 0) {
            this.addFilter();
        }
    }

    ngOnInit() {
    }

    onApply() {
        this.filterService.setLocaleFilter(Object.values(this.filterForm.get('filters').value));
        // this.dialogRef.close();
    }


    addFilter() {
        const max = this.filtersActive.reduce((currentMax, f) => {
            if (Number(f) > Number(currentMax)) {
                return f;
            }
            return currentMax;
        }, -1);
        const newId = (Number(max) + 1).toString();
        (this.filterForm.get('filters') as UntypedFormGroup).addControl(newId,
            this.fb.group({
                key: [{
                    value: null,
                    disabled: false
                }, Validators.required],
                operator: [{
                    value: null,
                    disabled: true
                }, Validators.required],
                value: [{
                    value: null,
                    disabled: true
                }],
            }));
        this.filtersActive = [...this.filtersActive, newId];
    }

    removeFilter(index) {
        const filterIndex = this.filtersActive.indexOf(index);
        (this.filterForm.get('filters') as UntypedFormGroup).removeControl(filterIndex.toString());
        this.filtersActive = this.filtersActive.filter((f) => f !== index);
    }


    filterEasy(index) {
        const keyOperator = this.getFilterRow(index).get('operator').value;
        if (keyOperator) {
            switch (keyOperator) {
                case Operators.EXIST:
                case Operators.NULL:
                    return true;
                default:
                    return false;
            }
        }
        return false;
    }

    getOperators(filterName) {
        const filter = this.getKeyFilter(filterName);
        if (filter.key && filter.filtriPossibili) {
            return filter.filtriPossibili;
        }
        return [];
    }

    filterIsFree(filterName) {
        const filter = this.getKeyFilter(filterName);
        return filter ? filter.campoLibero : false;
    }

    getValues(filterName): { filter, key } {
        const keyFilter = this.getKeyFilter(filterName);
        if (keyFilter.key) {
            return {
                filter: keyFilter.valoriAmmessi,
                key: keyFilter.key.key
            };
        } else {
            return {
                filter: [],
                key: ''
            };
        }
    }

    dynamicFilter(filterName) {
        return this.getKeyFilter(filterName).campoDinamico;
    }

    async getValuesDynamicsByKeyFilter(keyFilter) {
        if (keyFilter.key && keyFilter.campoDinamico) {
            if (keyFilter.pointerClass === 'fotoPuntiLuce') {
                return await this.fotoTipologiaService.getFotoTipologia$().pipe(
                    map(fotoTipologie => {
                        if (arrayIsSet(fotoTipologie)) {
                            return fotoTipologie.map(foto => {
                                return {objectId: foto.objectId, titolo: foto.name}
                            })
                        } else {
                            return undefined
                        }
                    })
                )
                    .toPromise();
            } else if (keyFilter.pointerClass === 'Circuiti') {
                return await this.mapService.getCircuiti$().toPromise()
            } else if (keyFilter.pointerClass === 'Strade') {
                return await this.streetService.getAllStradeParse$().toPromise()
            } else {
                return await this.filterService.getDynamicFilter(
                    keyFilter.pointerClass,
                    keyFilter.pointerKey,
                    await this.mapService.getCurrentProject()
                );
            }
        }
        return [];
    }

    async getValuesDynamicsByRows(filterName) {
        const keyFilter = this.getKeyFilter(filterName);
        return this.getValuesDynamicsByKeyFilter(keyFilter);
    }

    onChangeOperator(filterName, newValue) {
        const controlItem = this.getFilterRow(filterName);
        if (newValue) {
            // controlItem.get('value').reset();
            controlItem.get('value').enable();
        } else {
            controlItem.get('value').reset();
            controlItem.get('value').disable();
        }
        // controlItem.get('value').setValue('');
    }

    //ho aggiunto l if altrimenti dava errore
    // il return false potrebbe non essere giusto
    // inserito in maniera ragionevole
    keyCompareFn(el1, el2): boolean {
        if (isNotNullOrUndefined(el1) && isNotNullOrUndefined(el2) && el2.key != undefined) {
            return el1.key.key === el2.key.key;
        } else {
            return false;
        }
    }

    onChangeKey(filterName) {
        const controlItem = this.getFilterRow(filterName);
        controlItem.get('operator').reset();
        controlItem.get('operator').enable();
        this.onChangeOperator(filterName, null);
        if (controlItem.value.key.campoDinamico) {
            this.promises[filterName] = this.getValuesDynamicsByRows(filterName);
        }
    }

    onChangeKey_v2(filter) {
        const controlItem = this.getFilterRow(filter);
        controlItem.get('operator').reset();
        controlItem.get('operator').enable();
        this.onChangeOperator(filter, null);
        if (controlItem.value.key.campoDinamico) {
            this.promises[filter] = this.getValuesDynamicsByRows(filter);
        }
    }

    public getKeyFilter(filterName) {
        const item = this.getFilterRow(filterName)?.get('key').value;
        if (item && item.key) {
            return item;
        }
        return false;
    }


    private loadData() {
        this.filters = this.filterService.getFilters();
        this.filters.forEach((el, index) => {
            this.filters[index].key = el.key;
            if (el.filtriPossibili) {
                this.filters[index].filtriPossibili = el.filtriPossibili;
            }
            if (el.valoriAmmessi) {
                this.filters[index].valoriAmmessi = el.valoriAmmessi;
            }
            if (el.tipoDato == typeFormValue.ELENCO_APERTO) {
                const transform = new TransformForTranslatePipe()
                const valueForAutoComplete = el.valoriAmmessi.map(value => {
                    const obj: dataForm = {
                        valueForm: value.key,
                        html: value.key,
                        traduction: 'dashboard_sidenav.PuntiLuce.' + el.key.key + '.possibleValues.' + transform.transform(value.key)
                    };
                    return obj
                })
                this.valuesAutoCompleteData[el.key.key] = valueForAutoComplete
            }
        });
    }

    private initForm() {
        this.filterForm = this.fb.group({
            filters: this.fb.group({})
        });
        if (this.data.localFilter) {
            const filtersFormGroup: UntypedFormGroup = (this.filterForm.get('filters') as UntypedFormGroup);
            this.data.localFilter.forEach((el) => {
                const filterName = this.filtersActive.length.toString();
                this.filtersActive = [...this.filtersActive, filterName];
                filtersFormGroup.addControl(filterName, this.fb.group({
                    key: [{
                        value: el.key,
                        disabled: false
                    }, Validators.required],
                    operator: [{
                        value: el.operator,
                        disabled: false
                    }, Validators.required],
                    value: [{
                        value: el.value,
                        disabled: false
                    }],
                }));
                /* const lastFilter = (this.filtersActive.length - 1).toString();
                this.onChangeKey(lastFilter);
                filtersFormGroup.get(lastFilter).get('operator').setValue(el.operator);
                filtersFormGroup.get(lastFilter).get('value').setValue(el.value); */
            });
            this.data.localFilter.forEach((keyFilter, filterName) => {
                if (keyFilter.key.campoDinamico) {
                    this.promises[filterName] = this.getValuesDynamicsByKeyFilter(keyFilter.key);
                }
            })
        }
    }

    private getFilterRow(filterName) {
        return (this.filterForm.get('filters') as UntypedFormGroup).get(filterName);
    }


    getTypeFilter(filterName) {
        const filter = this.getKeyFilter(filterName);
        if (filter.tipoDato == typeFormValue.ELENCO_APERTO) {
            return this.typeFilter.elencoAperto;
        } else if (filter.tipoDato && filter.tipoDato.toLowerCase() == 'date') {
            return this.typeFilter.date;
        } else if (this.filterIsFree(filterName)) {
            return this.typeFilter.free;
        } else if (this.dynamicFilter(filterName)) {
            return this.typeFilter.dynamic;
        } else {
            return this.typeFilter.other;
        }
    }

    public typeFilter = {
        free: 'FREE',
        openedList: 'listaAperta',
        dynamic: 'DYNAMIC',
        other: 'OTHER',
        date: 'DATE',
        elencoAperto: typeFormValue.ELENCO_APERTO
    }

    filtersTracker(_index: number, filter: number) {
        return filter;
    }

    getFiltersAutocompleteItemPredicate(filters) {
        return filters.reduce((prev, current) => {
            const obj: dataForm = {
                traduction: 'dashboard_sidenav.PuntiLuce.' + current.key.key + '.title',
                registerValue: {...current,objectId:current.key.key,className:current.key.key },
                valueForm: current,
                html: current,
            }
            if (current.campoFiltrabile) {
                prev.push(obj);
            }
            return prev;
        }, [])
        return []
    }
}
