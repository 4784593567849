<div style="position: relative;height: 100%">
    <h5 style="margin-top: 0px;">{{data.title.popUp|translate}}</h5>
    <form class="example-form" [formGroup]="createUpdateForm">
        <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>{{data.title.form|translate}}</mat-label>
            <input type="text" matInput formControlName="selectValue"
                   [matAutocomplete]="autoNumeroQuadro">
            <mat-autocomplete #autoNumeroQuadro="matAutocomplete"
                              [displayWith]="displayFnNumeroQuadro.bind(this)"
            >
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option.valueForm">
                    {{option.html}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </form>
    <ng-container>
    </ng-container>
    <div style="position:absolute;top: 95%;left: 50%;transform: translateX(-50%)" *ngIf="data.icon">
        <ng-container *ngIf="valueIsInDatSet();else createNew">
            <button color="accent" mat-flat-button
                    [disabled]="!createUpdateForm.valid"
                    [mat-dialog-close]="getValue(false)">
                <mat-icon>{{data.icon.update}}</mat-icon>
                <span style="margin-left: 10px;font-weight: bold">{{data.title.update|translate}}</span>
            </button>
        </ng-container>
        <ng-template #createNew>
            <button [mat-dialog-close]="getValue(true)"
                    color="accent"
                    mat-flat-button>
                <mat-icon>{{data.icon.createNew}}</mat-icon>
                <span style="margin-left: 10px;font-weight: bold">{{data.title.createNew|translate}}</span>
            </button>
        </ng-template>
    </div>
</div>
