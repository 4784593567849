import {Injectable} from '@angular/core';
import {CalcolariceEsadecimaleService} from "./calcolarice-esadecimale.service";
import {isNotNullOrUndefined} from "../../models/Models";

@Injectable({
    providedIn: 'root'
})
export class valueLinearScaleColorService {
    private luxVector;
    public keyMax: number;
    public keyMin: number;
    private keyScalemin: number;
    private keyScalemax: number;
    private keyLimit: number;
    public ElementiScala = [];

    constructor(
        private calcHex: CalcolariceEsadecimaleService
    ) {
        this.scalacolore("FF0000", "FFFF00", 4);
        this.scalacolore("FFFF00", "00FF00", 4);
        this.scalacolore("00FF00", "0000FF", 4);
        this.scalacolore("0000FF", "FF00FF", 4);
    }


    /**
     *
     * @param elementConILux sono gli elementi dove prendere il minimo ed il massimo
     * @param keyLimit è il tetto massimo dove vengono tagliati  i massimi
     */
    public setMinMaxLux(elementConILux: {}[] | number [], key = undefined, keyLimit = 150, numberColorScale: 4 | 2 = 4, reverse = false, setMinMax = true) {
        if (numberColorScale === 4) {
            this.ElementiScala = [];
            this.scalacolore("FF0000", "FFFF00", 4);
            this.scalacolore("FFFF00", "00FF00", 4);
            this.scalacolore("00FF00", "0000FF", 4);
            this.scalacolore("0000FF", "FF00FF", 4);
        } else {
            this.ElementiScala = [];
            this.scalacolore("FF0000", "FFFF00", 6);
            this.scalacolore("FFFF00", "00FF00", 6);
        }
        if (reverse) {
            this.ElementiScala.reverse();
        }
        if (setMinMax) {
            this.keyLimit = keyLimit;
            if (isNotNullOrUndefined(key)) {
                this.luxVector = elementConILux.filter((A) => {
                    return !isNaN(A[key]) && (A[key] < this.keyLimit)
                }).map((result) => {
                    return result[key]
                });
            } else {
                this.luxVector = elementConILux.filter((value) => {
                    const castValue = value as number;
                    return !isNaN(castValue) && (value < this.keyLimit)
                })
            }
            this.setMinMaxValue(Math.min(...this.luxVector), Math.max(...this.luxVector), this.keyLimit);
        }

        this.keyScalemin = 0;
        this.keyScalemax = this.ElementiScala.length - 1;
    };


    public generateScale(numberColorScale: 4 | 2 = 4, reverse = false) {
        if (numberColorScale === 4) {
            this.ElementiScala = [];
            this.scalacolore("FF0000", "FFFF00", 4);
            this.scalacolore("FFFF00", "00FF00", 4);
            this.scalacolore("00FF00", "0000FF", 4);
            this.scalacolore("0000FF", "FF00FF", 4);
        } else {
            this.ElementiScala = [];
            this.scalacolore("FF0000", "FFFF00", 6);
            this.scalacolore("FFFF00", "00FF00", 6);
        }
        if (reverse) {
            this.ElementiScala.reverse();
        }
        this.keyScalemin = 0;
        this.keyScalemax = this.ElementiScala.length - 1;
    }

    public setMinMaxValue(min: number, max: number, keyLimit: number) {
        this.keyMax = max;
        this.keyMin = min;
        this.keyMin = (!isFinite(this.keyMin) || isNaN(this.keyMin)) ? 0 : this.keyMin;
        this.keyMax = (this.keyMax === this.keyMin || !isFinite(this.keyMax) || isNaN(this.keyMax)) ? keyLimit : this.keyMax;
    }

    //serve per trasformare il valore f(x)=A*x+B
    private linearTransform(Variable: number, Scalemin: number, Scalemax: number, keyMax: number, keyMin: number): number {
        //perchè elimino la costruzione
        if (Variable < this.keyMin) {
            return 0
        }
        if (Variable > this.keyMax) {
            return this.ElementiScala.length - 1;
        }
        const trasformato = Math.round(Scalemin + ((Scalemax - Scalemin) / (keyMax - keyMin)) * (Variable - keyMin));
        // console.log("Lmax", this.keyMax, "Lmin", this.keyMin, "Smax", this.Scalemax, "Smin", this.Scalemin, "tot", trasformato);
        //console.log( "Lux: ",Variable, " tot ", trasformato);
        return trasformato;
    }

// restituisce un valore in esadecimale in funzione del valore di lux usa lineartransform
    public PrendiColore(value: number): string {
        if (value > this.keyLimit && !isNaN(value)) {
            return "#cccccc";
        } else if (this.keyMax === this.keyMin) {
            return "#" + this.ElementiScala[0].color;
        } else if (this.ElementiScala && this.ElementiScala.length > 0 && !isNaN(value)) {
            return "#" + this.ElementiScala[this.linearTransform(value, this.keyScalemin, this.keyScalemax, this.keyMax, this.keyMin)].color;
        } else
            return "#000000";
    }

    // serve per linearizzare i colori in esadecimale
    private interpolationLinear(min, max, i, nStep) {
        nStep--;//il ciclo parte da 0 non da 1
        let iHex: string;
        let Max_Min: string = this.calcHex.divHex(this.calcHex.sotHex(max, min), nStep.toString(16));
        //console.log("min: ",min,"max: ",max);
        iHex = (i).toString(16);
        return this.calcHex.sumHex(min, this.calcHex.moltHex(iHex, Max_Min)).toUpperCase()
        //   console.log(iHex,"max-min: ",this.calcHex.sotHex(max, min),"max-min/nstep:",Max_Min,"i*max-min/nstep:",this.calcHex.moltHex(iHex, Max_Min),"tot: ",this.calcHex.sumHex(min,this.calcHex.moltHex(iHex, Max_Min))  );
    }

    //si aggiungono gli elementi al vettore contenete la scala colore puo essere chiamata piu volte
    private scalacolore(min: string, max: string, nStep: number) {
        let i = 0;
        let iHex: string;
        const Rmin = min.substr(0, 2);
        const Rmax = max.substr(0, 2);
        const Gmin = min.substr(2, 2);
        const Gmax = max.substr(2, 2);
        const Bmin = min.substr(4, 2);
        const Bmax = max.substr(4, 2);
        // console.log(Rmin, Rmax, Gmin, Gmax, Bmin, Bmax);

        let R;
        let G;
        let B;
        let RGB;

        let Max_Min: string = this.calcHex.divHex(this.calcHex.sotHex(max, min), nStep.toString(16));

        while (i < nStep) {

            R = this.interpolationLinear(Rmin, Rmax, i, nStep).padStart(2, '0').toUpperCase();
            G = this.interpolationLinear(Gmin, Gmax, i, nStep).padStart(2, '0').toUpperCase();
            B = this.interpolationLinear(Bmin, Bmax, i, nStep).padStart(2, '0').toUpperCase();
            RGB = R + G + B;
            // attenzione deve prima verificare la lunghezza altrimenti da errore perche potrrebbe non esistere
            if (this.ElementiScala.length == 0) {
                this.ElementiScala.push({color: RGB});
            } else if (RGB != this.ElementiScala[this.ElementiScala.length - 1].color) {
                this.ElementiScala.push({color: RGB});
            }
            i++;
        }
        // this.newElementiScala;//per visualizzare alla corretta maniera
    }
}
