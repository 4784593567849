import {EventEmitter, Injectable} from '@angular/core';
import * as Parse from 'parse';
import {ParseApiService} from './parse-api.service';
import {TranslateService} from "@ngx-translate/core";
import {UserService} from './user.service';
import {ordinamentoEcampiTraduzioni} from "../../models/ordinamentoEcampiTraduzioni";
import {hunaParseFloat, isNotNullOrUndefined} from "../../models/Models";
import {typeFormValue} from "../../models/configurationProperty/configurationPropertyUtils";

export interface StrutturaValoriPuntiLuce {
    campoDinamico: boolean,
    campoFiltrabile: boolean,
    campoLibero: boolean,
    filtriPossibili: object[],
    sortingNumber: number,
    tipoDato: string,
    valoriAmmessi: object[],
    pointerKey: string,
    pointerClass: string,
    key: { key: string }
}

export interface OrdinamentoECampi {
    key: string,
    varianteKey: string,
    possibleValues: [] | string,
    section: number,
    copyable: boolean,
    sortingValue: number,
    editable: boolean,
    filtrable: boolean,
    type: string
    typeForm: number
}

@Injectable({
    providedIn: 'root'
})
export class FilterService extends ParseApiService {

    filter: EventEmitter<any> = new EventEmitter();

    private readonly FILTERS_KEY_STORAGE = 'filter';
    private readonly FILTERS_LIGHT_POINTS_STORAGE = 'filter_light_points';
    private readonly FILTERS_OBJECTID_LIGHT_POINTS_STORAGE = 'filter_objectId_light_points';
    private readonly FILTERS_CIRCUITS_STORAGE = 'filter_circuits';
    private readonly FILTERS_CONFINI_AMMINISTRATIVI_STORAGE = 'filter_confini_amministrativi';
    private readonly FILTERS_PUNTI_STAMPA_STORAGE = 'filter_punti_stampa';
    private readonly FILTERS_ADDRESS_STORAGE = 'filter_address';
    private readonly REPORT_STORAGE = 'report_storage';

    constructor(protected translate: TranslateService,
                private userService: UserService) {

        super();
    }

    public setLocaleFilter(filters) {
        localStorage.setItem(this.FILTERS_KEY_STORAGE, JSON.stringify(filters));
    }

    public getLocaleFilter() {
        if (!localStorage.getItem(this.FILTERS_KEY_STORAGE)) {
            return false;
        }
        return JSON.parse(localStorage.getItem(this.FILTERS_KEY_STORAGE));
    }

    public setReport(reports) {
        localStorage.setItem(this.REPORT_STORAGE, JSON.stringify(reports));
    }

    public getReport() {
        if (!localStorage.getItem(this.REPORT_STORAGE)) {
            return [];
        }
        return JSON.parse(localStorage.getItem(this.REPORT_STORAGE));
    }


    public getLocaleFilterCount() {
        if (!localStorage.getItem(this.FILTERS_KEY_STORAGE) || localStorage.getItem(this.FILTERS_KEY_STORAGE) === 'null') {
            return 0;
        }
        return JSON.parse(localStorage.getItem(this.FILTERS_KEY_STORAGE)).length;
    }


    public emitFilter(value) {
        this.filter.emit(value);
    }

    public observableFilter() {
        return this.filter.asObservable();
    }


    public setLocaleAddress(address) {
        if (address) {
            localStorage.setItem(this.FILTERS_ADDRESS_STORAGE, address);
        } else {
            localStorage.removeItem(this.FILTERS_ADDRESS_STORAGE);
        }
    }

    public setLocaleLightPoints(items) {
        if (items) {
            localStorage.setItem(this.FILTERS_LIGHT_POINTS_STORAGE, JSON.stringify(items));
        } else {
            localStorage.removeItem(this.FILTERS_LIGHT_POINTS_STORAGE);
        }
    }

    public getLocaleLightPoints(): any[] {
        if (localStorage.getItem(this.FILTERS_LIGHT_POINTS_STORAGE) && localStorage.getItem(this.FILTERS_LIGHT_POINTS_STORAGE) != 'null') {
            return JSON.parse(localStorage.getItem(this.FILTERS_LIGHT_POINTS_STORAGE));
        } else {
            return null;
        }
    }

    public setLocaleObjectIdLightPoints(items) {
        if (items) {
            localStorage.setItem(this.FILTERS_OBJECTID_LIGHT_POINTS_STORAGE, JSON.stringify(items));
        } else {
            localStorage.removeItem(this.FILTERS_OBJECTID_LIGHT_POINTS_STORAGE);
        }
    }

    public getLocaleObjectIdLightPoints(): any[] {
        if (localStorage.getItem(this.FILTERS_OBJECTID_LIGHT_POINTS_STORAGE) && localStorage.getItem(this.FILTERS_OBJECTID_LIGHT_POINTS_STORAGE) != 'null') {
            return JSON.parse(localStorage.getItem(this.FILTERS_OBJECTID_LIGHT_POINTS_STORAGE));
        } else {
            return null;
        }
    }

    public setLocaleCircuits(items) {
        if (items) {
            localStorage.setItem(this.FILTERS_CIRCUITS_STORAGE, JSON.stringify(items));
        } else {
            localStorage.removeItem(this.FILTERS_CIRCUITS_STORAGE);
        }
    }

    public getLocaleCircuits(): any[] {
        if (localStorage.getItem(this.FILTERS_CIRCUITS_STORAGE) && localStorage.getItem(this.FILTERS_CIRCUITS_STORAGE) != 'null') {
            return JSON.parse(localStorage.getItem(this.FILTERS_CIRCUITS_STORAGE));
        } else {
            return null;
        }
    }

    public setLocalePuntiStampa(items) {
        if (items) {
            localStorage.setItem(this.FILTERS_PUNTI_STAMPA_STORAGE, JSON.stringify(items));
        } else {
            localStorage.removeItem(this.FILTERS_PUNTI_STAMPA_STORAGE);
        }
    }

    public setLocaleConfiniAmministrativi(items) {
        if (items) {
            localStorage.setItem(this.FILTERS_CONFINI_AMMINISTRATIVI_STORAGE, JSON.stringify(items));
        } else {
            localStorage.removeItem(this.FILTERS_CONFINI_AMMINISTRATIVI_STORAGE);
        }
    }

    public getLocalePuntiStampa(): any[] {
        if (localStorage.getItem(this.FILTERS_PUNTI_STAMPA_STORAGE) && localStorage.getItem(this.FILTERS_PUNTI_STAMPA_STORAGE) != 'null') {
            return JSON.parse(localStorage.getItem(this.FILTERS_PUNTI_STAMPA_STORAGE));
        } else {
            return [];
        }
    }

    public destoryLocalePuntiStampa(): void {
        localStorage.removeItem(this.FILTERS_PUNTI_STAMPA_STORAGE);
    }

    public getLocaleConfiniAmministrativi(): any[] {
        if (localStorage.getItem(this.FILTERS_CONFINI_AMMINISTRATIVI_STORAGE) && localStorage.getItem(this.FILTERS_CONFINI_AMMINISTRATIVI_STORAGE) != 'null') {
            return JSON.parse(localStorage.getItem(this.FILTERS_CONFINI_AMMINISTRATIVI_STORAGE));
        } else {
            return [];
        }
    }

    public destoryLocaleConfiniAmministrativi(): void {
        localStorage.removeItem(this.FILTERS_CONFINI_AMMINISTRATIVI_STORAGE);
    }

    public getLocaleAddress() {
        if (localStorage.getItem(this.FILTERS_ADDRESS_STORAGE)) {
            return localStorage.getItem(this.FILTERS_ADDRESS_STORAGE);
        } else {
            return null;
        }
    }

    public destroyLocaleAddress() {
        localStorage.removeItem(this.FILTERS_ADDRESS_STORAGE);
    }


    public destroyAllFilter() {
        localStorage.removeItem(this.FILTERS_ADDRESS_STORAGE);
        localStorage.removeItem(this.FILTERS_CIRCUITS_STORAGE);
        localStorage.removeItem(this.FILTERS_LIGHT_POINTS_STORAGE);
        localStorage.removeItem(this.FILTERS_OBJECTID_LIGHT_POINTS_STORAGE);
        localStorage.removeItem(this.FILTERS_KEY_STORAGE);
        localStorage.removeItem(this.REPORT_STORAGE);
        this.emitFilter({"event": "destroy"});
    }

    public hasFilter(): boolean {
        return !!(this.getLocaleFilter()) || !!(this.getLocaleAddress()) || this.hasLightPoints() || this.hasCircuits() || this.hasPuntiStampa() || this.hasConfiniAmministrativi();
    }

    public hasAddress(): boolean {
        return !!this.getLocaleAddress();
    }

    public hasLightPoints(): boolean {
        return !!this.getLocaleLightPoints();
    }

    public hasCircuits(): boolean {
        return Array.isArray(this.getLocaleCircuits()) ? this.getLocaleCircuits().length > 0 : false;
    }

    public hasFiltriObjectIdPuntiLuce(): boolean {
        return Array.isArray(this.getLocaleObjectIdLightPoints()) ? this.getLocaleObjectIdLightPoints().length > 0 : false;
    }

    public hasPuntiStampa(): boolean {
        return !!this.getLocalePuntiStampa().length;
    }

    public hasConfiniAmministrativi(): boolean {
        return !!this.getLocaleConfiniAmministrativi().length;
    }

    private getElemetForAdvancedFilter(elemento: OrdinamentoECampi): StrutturaValoriPuntiLuce {
        if (elemento.type.includes('POINTER')) {
            const filtriAmmessi = [{
                operatore: '='
            }, {
                operatore: '!='
            }, {
                operatore: 'EXIST'
            }, {
                operatore: 'IS NULL'
            }];
            const pointer = (elemento.possibleValues as string).split('.')
            return {
                key: {key: elemento.key},
                valoriAmmessi: null,
                pointerKey: pointer[1],
                pointerClass: (pointer[0].toLowerCase() == 'strada') ? 'Strade' : pointer[0],
                campoDinamico: true,
                campoFiltrabile: elemento.filtrable,
                campoLibero: false,
                filtriPossibili: filtriAmmessi,
                sortingNumber: elemento.sortingValue,
                tipoDato: 'POINTER',
            }
        } else {
            const campoLibero = !(elemento.hasOwnProperty('possibleValues') && Array.isArray(elemento.possibleValues));
            let filtriAmmessi;
            let tipoDato;
            if (elemento.typeForm == typeFormValue.ELENCO_APERTO) {
                tipoDato = typeFormValue.ELENCO_APERTO;
                filtriAmmessi = [{
                    operatore: '='
                }, {
                    operatore: 'EXIST'
                }, {
                    operatore: '!='
                }, {
                    operatore: 'STARTS WITH'
                }, {
                    operatore: 'ENDS WITH'
                }, {
                    operatore: 'IS NULL'
                }];

            } else if (elemento.type.toLowerCase() == 'bool') {
                tipoDato = 'BOOL';
                filtriAmmessi = [{
                    operatore: '='
                }, {
                    operatore: 'EXIST'
                }, {
                    operatore: 'IS NULL'
                }];
            } else if (elemento.type.toLowerCase() == 'file') {
                tipoDato = 'FILE';
                filtriAmmessi = [{
                    operatore: 'EXIST'
                }, {
                    operatore: 'IS NULL'
                }];
            } else if (campoLibero && elemento.type.toLowerCase() != 'number' && elemento.type.toLowerCase() != 'int' && elemento.type.toLowerCase() != 'date') {
                tipoDato = 'STRING';
                filtriAmmessi = [{
                    operatore: '='
                }, {
                    operatore: 'EXIST'
                }, {
                    operatore: '!='
                }, {
                    operatore: 'STARTS WITH'
                }, {
                    operatore: 'ENDS WITH'
                }, {
                    operatore: 'IS NULL'
                }];
            } else if (elemento.type.toLowerCase() == 'number' || elemento.type.toLowerCase() == 'int') {
                tipoDato = elemento.type;
                filtriAmmessi = [{
                    operatore: '='
                }, {
                    operatore: '!='
                }, {
                    operatore: '>'
                }, {
                    operatore: '>='
                }, {
                    operatore: '<'
                }, {
                    operatore: '<='
                }, {
                    operatore: 'EXIST'
                }, {
                    operatore: 'IS NULL'
                }];
            } else if (elemento.type.toLowerCase() == 'date') {
                tipoDato = elemento.type
                filtriAmmessi = [{
                    operatore: '>'
                }, {
                    operatore: '>='
                }, {
                    operatore: '<'
                }, {
                    operatore: '<='
                }]
            } else {
                tipoDato = 'OTHER';
                filtriAmmessi = [{
                    operatore: '='
                }, {
                    operatore: 'EXIST'
                }, {
                    operatore: '!='
                }, {
                    operatore: 'IS NULL'
                }];
            }

            return {
                key: {key: elemento.key},
                valoriAmmessi: (elemento.hasOwnProperty('possibleValues') && Array.isArray(elemento.possibleValues)) ? elemento.possibleValues.map((el) => {
                    return {
                        key: el
                    }
                }) : null,
                pointerKey: null,
                pointerClass: null,
                campoDinamico: false,
                campoFiltrabile: elemento.filtrable,
                campoLibero: campoLibero,
                filtriPossibili: filtriAmmessi,
                sortingNumber: elemento.sortingValue,
                tipoDato: tipoDato,
            }

        }
    }


    // vengono creati i filti pe i filtri avanzati
    public getFilters() {
        let elementiDaCreare: OrdinamentoECampi[] = [];
        Object.keys((ordinamentoEcampiTraduzioni.PuntiLuce as any)).forEach((key) => {
            elementiDaCreare.push({
                key: key,
                varianteKey: (isNotNullOrUndefined(ordinamentoEcampiTraduzioni.PuntiLuce[key].varianteKey)) ? ordinamentoEcampiTraduzioni.PuntiLuce[key].varianteKey : null,
                possibleValues: (isNotNullOrUndefined(ordinamentoEcampiTraduzioni.PuntiLuce[key].possibleValues)) ? ordinamentoEcampiTraduzioni.PuntiLuce[key].possibleValues : null,
                section: ordinamentoEcampiTraduzioni.PuntiLuce[key].section,
                copyable: ordinamentoEcampiTraduzioni.PuntiLuce[key].copyable,
                sortingValue: ordinamentoEcampiTraduzioni.PuntiLuce[key].sortingValue,
                editable: ordinamentoEcampiTraduzioni.PuntiLuce[key].editable,
                filtrable: (isNotNullOrUndefined(ordinamentoEcampiTraduzioni.PuntiLuce[key].filtrable)) ? ordinamentoEcampiTraduzioni.PuntiLuce[key].filtrable : false,
                type: ordinamentoEcampiTraduzioni.PuntiLuce[key].type,
                typeForm: ordinamentoEcampiTraduzioni.PuntiLuce[key].typeForm
            })
        });
        elementiDaCreare.sort((a, b) => a.sortingValue - b.sortingValue);
        let elementiDaTornare: StrutturaValoriPuntiLuce[] = [];
        elementiDaCreare.forEach((element) => {
            elementiDaTornare.push(this.getElemetForAdvancedFilter(element))
        });
        return elementiDaTornare;
        // try {
        //     const StrutturaValoriPuntiLuce = Parse.Object.extend('StrutturaValoriPuntiLuce');
        //     const query = new Parse.Query(StrutturaValoriPuntiLuce);
        //     query.ascending('sortingNumber');
        //     const res = await query.find({sessionToken: this.userService.sessionToken()});
        //     return res.map((item) => {
        //         return item.toJSON();
        //     });
        // } catch (e) {
        //     throw e;
        // }
    }

    public async getDynamicFilter(pointerClass, pointerKey: string, projectId) {
        const classResult = Parse.Object.extend(pointerClass);
        const query = new Parse.Query(classResult);
        const progetto = projectId;
        query.equalTo('progetto', progetto);
        let result = await query.select(pointerKey).find({sessionToken: this.userService.sessionToken()});
        return result.map(el => el.toJSON());
    }


    public getFilter(filter) {
        let x: any[] = [];
        switch (filter.key.tipoDato) {
            case DataTypes.OTHER:
            case DataTypes.FILE:
            case DataTypes.BOOL:
            case DataTypes.STRING:
            case typeFormValue.ELENCO_APERTO:
                x = [filter.key.key.key, filter.operator, filter.value];
                break;
            case DataTypes.INTEGER:
            case DataTypes.INT:
            case DataTypes.INT:
                x = [filter.key.key.key, filter.operator, parseInt(filter.value)];
                break;
            case DataTypes.NUMBER:
                x = [filter.key.key.key, filter.operator, hunaParseFloat(filter.value)];
                break;
            case DataTypes.POINTER:
                x = [filter.key.key.key, filter.operator, filter.value];
                break;
            case DataTypes.DATE:
                const day = new Date(filter.value);
                x = [filter.key.key.key, filter.operator, day.getTime() / 1000];
                break;
        }

        return x;
    }

    // ritorna gli elementi presenti in newFilter e non presenti in oldfilter
    //  getDifferenceFilter( [ abc, efg, hil ],[ abc, hil ]) output: [ efg ]
    public getDifferenceFilter(newFilter: any[], oldFilter: any[]): any[] {
        if (oldFilter != null && oldFilter != undefined) {
            return newFilter.filter((A) => {
                return !oldFilter.includes(A)
            });
        } else {
            return newFilter;
        }
    }


}


enum DataTypes {
    BOOL = 'BOOL',
    INTEGER = 'INTEGER',
    INT = 'INT',
    STRING = 'STRING',
    NUMBER = 'NUMBER',
    POINTER = 'POINTER',
    OTHER = 'OTHER',
    FILE = 'FILE',
    DATE = 'DATE',
}



