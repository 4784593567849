import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {stringIsSet} from "../../models/Models";

@Directive({
    selector: '[appTruncateMiddle]'
})
export class TruncateMiddleDirective implements OnInit {
    @Input() inputText: string

    constructor(
        private el: ElementRef,
        private renderer: Renderer2
    ) {
    }

    ngOnInit() {
        if (stringIsSet(this.inputText)) {
            const text = this.inputText;
            const middle = Math.round(text.length / 2);
            const newspan = this.renderer.createElement("div");
            const newspan2 = this.renderer.createElement("div");
            this.renderer.addClass(this.el.nativeElement, 'truncate-middle');
            this.renderer.addClass(newspan, 'w50-flex-1')
            this.renderer.addClass(newspan, 'ellipsis')
            this.renderer.addClass(newspan2, 'w50-flex-1')
            this.renderer.addClass(newspan2, 'rtl')
            newspan.innerHTML = text.slice(0, middle)
            let last = text.slice(middle)
            const char = last.charAt(0);
            if (char.split(/^[^a-zA-Z0-9]+$/).length > 1) {
                last = ' ' + last.slice(1)
            }
            newspan2.innerHTML = last
            this.el.nativeElement.appendChild(newspan);
            this.el.nativeElement.appendChild(newspan2);
        }
    }
}
