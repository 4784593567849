import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateApi'
})
export class TranslateApiPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value['IT-it'];
  }

}
