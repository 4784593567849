<div mat-dialog-title>
    {{'confirm' | translate}}
</div>

<form *ngIf="planForm" [formGroup]="planForm">
    <div class="spacer">
        <label class="label">{{ 'formato' | translate}}</label>
        <div>
            <mat-radio-group formControlName="formato" id="formato">
                <mat-radio-button value="a3">A3
                </mat-radio-button>
                <mat-radio-button value="a4">A4
                </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div class="spacer">
        <mat-form-field floatLabel="auto" appearance="fill">
            <mat-label>{{ 'choose_typeMaps' | translate}}</mat-label>
            <mat-select formControlName="mapType" id="mapType">
                <mat-option value="roadmap">{{ 'static' | translate}}</mat-option>
                <mat-option value="hybrid">{{ 'hybrid' | translate}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</form>

<div mat-dialog-actions>
    <button mat-flat-button color="primary" (click)="closePlan()" cdkFocusInitial>{{ 'download' | translate}}</button>
    &nbsp;&nbsp;
    <button mat-stroked-button (click)="dialogRef.close()">{{'cancel' | translate}}
    </button>
</div>
