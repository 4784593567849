import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {Observable, of} from "rxjs";
import {catchError, map, startWith, switchMap} from "rxjs/operators";
import {arrayIsSet, stringIsSet} from "../../models/Models";

@Pipe({
    name: 'traductionOnlyHasTraduction'
})
export class TraductionOnlyHasTraductionPipe implements PipeTransform {
    constructor(private translateService: TranslateService) {
    }

    transform(key: string, pathVerify: string = undefined, returnKeyValue: string = undefined): Observable<string> {
        return this.translateService.onLangChange.pipe(
            startWith(undefined),
            switchMap(() => stringIsSet(key) ? this.translateService.get(key) : of(undefined)),
            map((traduction) => {
                const notExist = () => {
                    const pathVerifyCast = stringIsSet(pathVerify) ? pathVerify : key;
                    return traduction == null || (stringIsSet(traduction) && traduction === pathVerifyCast);
                }
                if (notExist()) {
                    const keySplitted = key.split('.')
                    if (returnKeyValue != null) {
                        return returnKeyValue;
                    } else if (arrayIsSet(keySplitted)) {
                        return keySplitted[keySplitted.length - 1];
                    } else {
                        return key;
                    }
                } else {
                    return this.translateService.instant(key)
                }
            })
        );
    }

}
