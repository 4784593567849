<div class="w100" #ElementRef>
    <div class="flex-center-width100"
         style="flex-flow: row;flex-wrap: wrap;justify-content: space-evenly;margin-top:40px;margin-bottom:10px ">
        <div class="order-button-previus">
            <button [disabled]="dateForm.disabled||disabled||startOEndIsUnset"
                    (click)="changePrevius()"
                    color="accent"
                    mat-mini-fab
            >
                <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
        </div>
        <div class="flex-center-Between flex-flow-row order-date">
            <mat-card class="date-container"
                      (click)="openDialog(DateDialog,ElementRef)"
            >
                <mat-card-subtitle *ngIf="endDateTitle">
                    {{endDateTitle}}
                </mat-card-subtitle>
                {{settingDateMin|customDate:'mediumDate':'noYear'|async}}
                - {{settingDateMax|customDate:'mediumDate':'noYear'|async}}
                ,  {{settingDateMax|customDate:'custom':'yyyy'|async}}
            </mat-card>
        </div>
        <div class="order-button-next">
            <button [disabled]="isAfterToday||disabled||startOEndIsUnset"
                    (click)="changeNext()"
                    color="accent"
                    mat-mini-fab
            >
                <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
        </div>
    </div>
    <mat-calendar
            [dateClass]="dateClass" (selectedChange)="selectDate($event)"
            [maxDate]="maxDate"
            [startAt]="this.dateForm.get('start').value"
            *ngIf="showCalendar&&!disabled">
    </mat-calendar>
</div>

<ng-template #DateDialog>
    <div style="position: relative">
        <button mat-icon-button style="position: absolute;right: -13px;top: -15px" (click)="closeDialog()">
            <mat-icon>clear</mat-icon>
        </button>
        <mat-calendar
                [dateClass]="dateClass" (selectedChange)="selectDate($event)"
                [maxDate]="maxDate"
                [startAt]="this.dateForm.get('start').value"
        >
        </mat-calendar>
    </div>
</ng-template>