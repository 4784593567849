import {Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormGroup} from "@angular/forms";
import {AlertService} from "../../../providers/services/alert.service";
import {ActivatedRoute, Router} from '@angular/router';
import {LoaderService} from "../../../providers/services/loader.service";
import * as Parse from 'parse';
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {UserService} from "../../../providers/services/user.service";
import {TranslateService} from "@ngx-translate/core";
import {ReportFormService} from "../../../providers/forms/report-form.service";
import {ReportsService} from "../../../providers/services/reports.service";
import {ProjectService} from "../../../providers/services/project.service";
import {ActivityFormService} from "../../../providers/forms/activity-form.service";
import {ActivitiesService} from "../../../providers/services/activities.service";
import {Priority, ReportStates, TIPOSEGNALAZIONE, UserRole, UserRoleNumber} from "../../../../config/static-data";
import {FilterService} from "../../../providers/services/filter.service";
import {MapService} from "../../../providers/services/map.service";
import {PdfViewDialogComponent} from "../../../pages-modules/reports/pdf-view-dialog/pdf-view-dialog.component";
import {UtilsService} from "../../../providers/services/utils.service";
import {
    NewReportCommentDialogComponent
} from "../../../pages-modules/reports/new-report-comment-dialog/new-report-comment-dialog.component";
import {ImageService} from "../../../providers/services/image.service";
import {DialogPopUpService} from "../../../providers/services/dialog-pop-up.service";
import {environment} from "../../../../environments/environment";
import {PuntiLuceScaleColorService} from "../../../providers/services/punti-luce-scale-color.service";
import {CircuitiParse} from "../../../models/Circuiti.Parse";
import {CircuitScaleColorService} from "../../../providers/services/circuit-scale-color.service";
import {forkJoin, Observable, of, Subject} from "rxjs";
import {formFiledMaintenanceFormContolName, MaintenanceService} from "../../../providers/services/maintenance.service";
import {SchedaManutenzioneParse} from "../../../models/SchedaManutenzione.Parse";
import {
    arrayIsSet,
    getItemInArrayByKeyValue,
    getUniqueValueInArray,
    stringIsSet,
    isNotNullOrUndefined
} from "../../../models/Models";
import {map, switchMap, take} from "rxjs/operators";
import {fromPromise} from "rxjs/internal-compatibility";
import {CommentiSegnalazioniParse} from "../../../models/CommentiSegnalazioni.Parse";
import {DialogPopUpInfoService} from "../../../providers/services/dialog-pop-up-info.service";
import {SchedaManutenzioneCompilataParse} from "../../../models/SchedaManutenzioneCompilata.Parse";
import {ReportMaintenanceService} from "../../../providers/services/report-maintenance.service";
import {dataForm} from "../../confirm-delete/select-or-create/select-or-create.component";
import {
    FormFieldComponent,
    FormFieldPopUpData,
    FormFieldPopUpType
} from "../../pop-up-info/form-field/form-field.component";
import {typeFormValue} from "../../../models/configurationProperty/configurationPropertyUtils";
import {CircuitLabelService} from "../../../providers/services/circuit-label.service";
import {PuntiLuceLabelService} from "../../../providers/services/punti-luce-label.service";
import {CdkTextareaAutosize} from "@angular/cdk/text-field";

@Component({
    selector: 'app-update-report',
    templateUrl: './update-report.component.html',
    styleUrls: ['./update-report.component.scss']
})
export class UpdateReportComponent implements OnInit {
    @ViewChild('radioButton') radioButton;
    @ViewChild('extendedDescription') extendedDescription;


    public planForm: UntypedFormGroup;
    public activityForm: UntypedFormGroup;
    public loading = true;
    public report;
    public priority;
    private stateReport;
    public activities: CommentiSegnalazioniParse[];
    public fileLink;
    @Input()
    private readonly planSlug;

    @Input()
    public visualezedButtonSeaMap = true;

    @Output() update = new EventEmitter<void>();

    public user;
    public readonly states = ReportStates;
    public readonly roles = UserRole;
    public readonly priorities = Priority;
    public author;
    public commento = true;
    public photo = false;
    public file = false;

    public typeComment: 'comment' | 'photo' | 'file' | 'schedaManutenzione' = 'comment'


    progress: { print: any } = {print: undefined}
    disableButton: { print: any } = {print: undefined}
    tipiSegnalazione: dataForm[] = TIPOSEGNALAZIONE.map(tipo => {
        return {traduction: tipo, valueForm: tipo, html: tipo}
    });



    constructor(
        private planUserForm: ReportFormService,
        private activityFormService: ActivityFormService,
        private planService: ReportsService,
        private activitiesService: ActivitiesService,
        private route: ActivatedRoute,
        private alertService: AlertService,
        private loaderService: LoaderService,
        private projectService: ProjectService,
        private router: Router,
        private userService: UserService,
        public dialog: MatDialog,
        public translate: TranslateService,
        private filterService: FilterService,
        private mapService: MapService,
        private utilsService: UtilsService,
        private scaleColorPuntiLuce: PuntiLuceScaleColorService,
        private scaleColorCircuiti: CircuitScaleColorService,
        private labelPuntiLuce: PuntiLuceLabelService,
        private labelCircuiti: CircuitLabelService,
        private imageService: ImageService,
        private myDialog: DialogPopUpService,
        private maintenanceService: MaintenanceService,
        private popUpInfoService: DialogPopUpInfoService,
        private dialogPopUpInfoService: DialogPopUpInfoService,
        private reportMaintenanceService: ReportMaintenanceService,
        private _ngZone: NgZone
    ) {
        this.planSlug = this.route.snapshot.params.slug;
        this.planForm = this.planUserForm.getReportForm(undefined);
    }

    ngOnInit() {

        this.loadData();
    }


    private async loadData() {
        try {
            this.report = await this.planService.getReport(this.planSlug);
            if (this.report.priorita == 0) {
                this.priority = this.priorities.BASSA;
            } else if (this.report.priorita == 1) {
                this.priority = this.priorities.MEDIA;
            } else {
                this.priority = this.priorities.ALTA;
            }
            this.stateReport = this.report.stato;
        } catch (e) {
            this.alertService.error(this.translate.instant('error_verified'));
            this.router.navigate(['/segnalazioni']);
        }
        try {
            this.activities = await this.activitiesService.getActivitiesOfReport(this.report, true);
            const objUser = []
            const getId = (item) => item.id;
            this.activities.forEach(activity => {
                if (activity.objectUser) {
                    const id = getId(activity.objectUser)
                    if (!isNotNullOrUndefined(getItemInArrayByKeyValue(objUser, id))) {
                        objUser.push(id)
                    }
                }
            })
            if (arrayIsSet(objUser)) {
                this.userService.getUserDetail(objUser).subscribe(userDetail => {
                    userDetail.forEach(user => {
                        const index = this.activities.findIndex(activity => {
                            if (activity.objectUser) {
                                const id = getId(activity.objectUser)
                                return id == getId(user)
                            } else {
                                return false
                            }
                        })
                        if (index >= 0) {
                            this.activities[index].objectUser = user;
                        }
                    })
                })
            }
        } catch (e) {
            this.alertService.error(this.translate.instant('error_verified'));
        }

        this.user = await this.userService.user();

        this.isAuthor(this.report);
        const disable = this.report != null && this.report.stato !== ReportStates.APERTO;
        this.disableFormSegnalazione = !this.author || disable;
        this.planUserForm.updateForm(this.planForm, this.report, disable);
        this.activityForm = this.activityFormService.getCreateActivityForm();
        // this.fileLink = await this.planService.previewLink(this.planSlug);
    }

    public get role() {
        return this.projectService.actualRoles;
    }

    public get roleNumber() {
        return this.projectService.myRole;
    }

    onPdf() {
        this.utilsService.lockBody();
        const a = this.dialog.open(PdfViewDialogComponent, {
            data: {
                reportObjectId: this.planSlug,
            }
        });
        a.afterClosed().subscribe(() => this.utilsService.unlockBody());
    }

    updateReport() {
        this.planService.updateReport(
            this.planSlug,
            this.planForm.value
        ).subscribe(resp => {
            this.loading = false;
            this.alertService.success(this.translate.instant('report_update_success'));
            this.loadData();
            this.update.emit();
        }, error => {
            this.alertService.error(error)
        })
    }

    onLoadImage(files: File[], label, form?) {
        try {
            const fileUploadControl = files[0];
            const loaderId = this.loaderService.addLoader();
            if (fileUploadControl) {
                const file = fileUploadControl;
                const name = fileUploadControl.name;
                const reader = new FileReader();
                reader.onloadend = (e: ProgressEvent) => {
                    const fileReaderResult = (e.target as FileReader).result as ArrayBuffer
                    const bytes = new Uint8Array(fileReaderResult);
                    // the base64 option in parse is bugged https://github.com/parse-community/Parse-SDK-JS/issues/1510
                    // the issue is currently closed, but the fix hasn't been released yet
                    // using an array buffer fixes the problem
                    try {
                        const parseFile = new Parse.File(name, Array.from(bytes));
                        if (form == 'activity') {
                            return parseFile.save().then(() => {
                                this.activityForm.get(label).setValue({
                                    name: parseFile.name(),
                                    url: parseFile.url(),
                                    file: parseFile
                                });
                            }).finally(() => this.loaderService.removeLoader(loaderId));
                        } else {
                            return parseFile.save().then(() => {
                                this.planForm.get(label).setValue({
                                    name: parseFile.name(),
                                    url: parseFile.url(),
                                    file: parseFile
                                });
                            }).finally(() => this.loaderService.removeLoader(loaderId));
                        }
                    } catch (err) {
                        this.loaderService.removeLoader(loaderId);
                        this.alertService.error(this.translate.instant('error') + ' ' + err.message);
                    }

                };
                reader.readAsArrayBuffer(file);

            } else {
                this.loaderService.removeLoader(loaderId);
                this.alertService.error(this.translate.instant('load_correct_file'));
            }
        } catch (e) {
            this.alertService.error(this.translate.instant('error') + ' ' + e.message);
        }
    }

    load = {schedeManutenzione: false};
    schedeManutenzione: SchedaManutenzioneParse[];

    getSchedemanutenzione(): Observable<SchedaManutenzioneParse[]> {
        if (arrayIsSet(this.schedeManutenzione)) {
            return of(this.schedeManutenzione)
        } else {
            return this.maintenanceService.getSchedeManutenzioneSegnalazioni()
        }
    }

    onChangeComment(type: 'comment' | 'photo' | 'file' | 'schedaManutenzione') {
        this.typeComment = type;
        // if (type == 'schedaManutenzione') {
        //     this.load.schedeManutenzione = true;
        //     this.getSchedemanutenzione().subscribe(schede => {
        //         if (arrayIsSet(schede)) {
        //             this.schedeManutenzione = schede;
        //         }
        //         this.load.schedeManutenzione = false;
        //     }, error => {
        //         this.alertService.error(error.message)
        //         this.load.schedeManutenzione = false;
        //
        //     })
        //
        // }
        this.activityForm.get('comment').setValue('');
    }

    async deleteSavedFile(label, form?) {
        if (form == 'activity') {
            this.activityForm.get(label).setValue(null);
        } else {
            this.planForm.get(label).setValue(null);
        }
    }

    // reopenReport() {
    //     const res = this.planService.openReport(
    //         this.planSlug
    //     );
    //     res.then(resp => {
    //         this.loading = false;
    //         this.alertService.success(this.translate.instant('reopen_report_success'));
    //         this.loadData();
    //     }).catch(e => {
    //         this.loading = false;
    //         this.alertService.error(e.message);
    //     });
    // }

    closeReport() {
        const res = this.planService.closeReport(
            this.planSlug
        );
        res.then(resp => {
            this.loading = false;
            this.alertService.success(this.translate.instant('close_report_success'));
            this.update.emit();
            this.loadData();
        }).catch(e => {
            this.loading = false;
            this.alertService.error(e.message);
        });
    }

    searchIntervention() {
        //Richiedi un intervento
        const res = this.planService.richiediInterventoSuSegnalazione(
            this.planSlug
        );
        res.then(resp => {
            this.loading = false;
            this.alertService.success(this.translate.instant('search_intervention'));
            this.update.emit();
            this.loadData();
        }).catch(e => {
            this.loading = false;
            this.alertService.error(this.translate.instant('error') + ' ' + e.code + ' ' + e.message);
        });
    }

    takeCharge() {
        //Prendi in carico
        const res = this.planService.prendiInCaricoLaSegnalazione(
            this.planSlug
        );
        res.then(resp => {
            this.loading = false;
            this.alertService.success(this.translate.instant('take_charged'));
            this.update.emit();
            this.loadData();
        }).catch(e => {
            this.loading = false;
            this.alertService.error(this.translate.instant('error') + ' ' + e.code + ' ' + e.message);
        });
    }

    isAuthor(report) {
        try {
            this.author = report.nomeAutore == (this.user.nome + ' ' + this.user.cognome);
        } catch (e) {

        }
    }

    disableFormSegnalazione: boolean = false;

    isReopen() {
        // return true;
        return this.report.stato == this.states.RISOLTO && this.role.includes(UserRole.AMMINISTRATORE);
    }

    isClose() {
        // return true;
        return this.report.stato == this.states.COMPLETATO && (this.role.includes(UserRole.AMMINISTRATORE) || this.role.includes(UserRole.GESTORE));
    }

    isSearchIntervention() {
        // return true;
        return this.report.stato == this.states.COMPLETATO && (this.role.includes(UserRole.AMMINISTRATORE) || this.role.includes(UserRole.GESTORE));
    }

    endState() {
        return this.report.stato == this.states.COMPLETATO || this.report.stato == this.states.RISOLTO
    }

    isUser() {
        return this.role.includes(UserRole.UTENTE);
    }

    onSaveActivity() {
        const res = this.activitiesService.createActivity(
            this.report, this.activityForm.value.comment, this.activityForm.value.foto, this.activityForm.value.allegato
        );
        res.then(resp => {
            this.loading = false;
            this.alertService.success(this.translate.instant('activity_success_create'));
            this.loadData();
            this.update.emit();
        }).catch(e => {
            this.loading = false;
            this.alertService.error(e.message);
        });
    }

    commentOfAuthor(nomeAutore) {
        return (this.user.nome + ' ' + this.user.cognome) == nomeAutore
    }


    fetchCircuiti() {
        let circuiti;
        if (Array.isArray(this.report.circuito)) {
            circuiti = this.report.circuito.map(circuito => {
                const circuitiParse = new CircuitiParse();
                circuitiParse.objectId = circuito.objectId;
                return circuitiParse;
            })
        } else {
            const circuitiParse = new CircuitiParse();
            circuitiParse.objectId = this.report.circuito.objectId;
            circuiti = [circuitiParse];
        }
        return this.mapService.fetchCircuiti(circuiti);
    }


    fetchPuntiLuce() {
        const objectIdPuntiLuce = this.report.puntiLuce.map((puntoLuce) => puntoLuce.objectId)
        return this.mapService.getLightPointPaginate(environment.lightPointLimit, null, null, objectIdPuntiLuce)
    }

    async viewMap() {
        if (this.report.circuito && this.report.puntiLuce) {
            const loader = this.loaderService.addLoader();
            this.scaleColorCircuiti.campoScalaColore = undefined;
            this.scaleColorPuntiLuce.calcolaScalaColore('tipologiaSorgenteLuminosa', []);
            let puntiLuce = []
            forkJoin([this.fetchCircuiti(), this.fetchPuntiLuce()]).subscribe(
                circuitiPuntiLuce => {
                    const circuiti = circuitiPuntiLuce[0];
                    puntiLuce = puntiLuce.concat(circuitiPuntiLuce[1].puntiLuce);
                    if (circuitiPuntiLuce[1].finished) {
                        circuiti.forEach(circuito => {
                            circuito.icon = this.scaleColorCircuiti.getIconCircuito(circuito);
                        })
                        puntiLuce.forEach(puntoLuce => {
                            puntoLuce.icon = this.scaleColorPuntiLuce.getIconPuntoLuce(puntoLuce);
                        });
                        this.myDialog.openDialogSeeMap("report", null, puntiLuce, circuiti, false, true);
                    }
                    this.loaderService.removeLoader(loader);
                }, error => {
                    this.loaderService.removeLoader(loader);
                    this.alertService.error(error)
                }
            )
        } else if (this.report.circuito) {
            const loader = this.loaderService.addLoader();
            this.scaleColorCircuiti.campoScalaColore = undefined;
            this.fetchCircuiti().subscribe(circuiti => {
                circuiti.forEach(circuito => {
                    circuito.icon = this.scaleColorCircuiti.getIconCircuito(circuito);
                })
                this.myDialog.openDialogSeeMap("report", null, null, circuiti, false, true);
                this.loaderService.removeLoader(loader);
            }, error => {
                this.loaderService.removeLoader(loader);
                this.alertService.error(error)
            })
        } else if (this.report.puntiLuce) {
            const loader = this.loaderService.addLoader();
            let puntiLuce = []
            this.fetchPuntiLuce().subscribe(
                puntiLuceState => {
                    puntiLuce = puntiLuce.concat(puntiLuceState.puntiLuce);
                    if (puntiLuceState.finished) {
                        this.scaleColorPuntiLuce.calcolaScalaColore('tipologiaSorgenteLuminosa', []);
                        puntiLuce.forEach(puntoLuce => {
                            puntoLuce.icon = this.scaleColorPuntiLuce.getIconPuntoLuce(puntoLuce);
                        });
                        this.myDialog.openDialogSeeMap("report", null, puntiLuce, null, false, true);
                        this.loaderService.removeLoader(loader);
                    }
                }, error => {
                    this.loaderService.removeLoader(loader);
                    this.alertService.error(error)
                }
            )
        } else {
            const message = this.translate.instant('alert.notElementToVisualized')
            this.alertService.warning(message);
        }
    }

    get isMobile() {
        return this.utilsService.isMobile
    }

// open
    onOpenImage(activity) {
        this.popUpInfoService.openVisualizedImage(activity.foto.url(), 'image')
    }

    openDialog() {
        // this.utilsService.lockBody();
        this.getSchedemanutenzione().pipe(
            switchMap((schedeManutenzione) => {
                this.schedeManutenzione = schedeManutenzione;
                const a = this.dialog.open(NewReportCommentDialogComponent, {
                    minWidth: this.isMobile ? '100vw' : undefined,
                    data: {
                        reportObjectId: this.planSlug,
                        tuttiAbbonamenti: this.tuttiAbbonamenti,
                        myRole: this.myRole,
                        schedeManutenzione: schedeManutenzione,
                    }
                })
                return a.afterClosed()
            }),
            switchMap((value) => {
                if (value == null) {
                    return of(null);
                } else if (value.typeReport == 'schedaManutenzione') {
                    const formFields: FormFieldPopUpType[] = [
                        {
                            title: null,
                            type: typeFormValue.RADIO_BUTTON,
                            formControlName: 'nuovoStato',
                            possibleValues: [
                                {value: this.states.COMPLETATO, title: {traduction: 'completed_intervention'}},
                                {value: this.states.MATERIALE, title: {traduction: 'material_waiting'}},
                                {value: this.states.INTERVENTO, title: {traduction: 'intervention_waiting'}},
                            ],
                            defaultValue: value.nuovoStato
                        }
                    ]
                    const data: FormFieldPopUpData = {
                        formFields,
                        title: {traduction: 'confermeStatuReports'},
                        disableClose: true,
                        visualizeCheckAllButton: false
                    }
                    return this.dialog.open(FormFieldComponent, {data}).afterClosed().pipe(
                        map((response) => {
                            Object.keys(response)
                                .forEach(key => {
                                    if (value[key] != null) {
                                        value[key] = response[key];
                                    }
                                })
                            return value
                        })
                    )
                } else {
                    return of(value)
                }
            })
        )
            .subscribe((value) => {
                if (isNotNullOrUndefined(value)) {
                    if (value.typeReport !== 'schedaManutenzione') {
                        const res = this.activitiesService.createActivity(
                            this.report, value.value.comment, value.value.foto, value.value.allegato, null, {nuovoStato: value.value.nuovoStato}
                        );
                        res.then(a => {
                            this.alertService.success(this.translate.instant('activity_success_create'));
                            this.loadData();
                            this.update.emit();
                        }).catch(error => {
                            this.saveSchedaCompilata.next({error: true})
                            // this.saveSchedaCompilata.next({error: true})
                            this.alertService.error(error);
                        })
                        //
                        // this.activityService.salvaNuovoReportSegnalazione(value.reportObjectId, value.value)
                        //     .then(() => {
                        //     this.alertService.success(this.translate.instant('success_report_comment'));
                        // }).catch((err) => {
                        //     this.alertService.error(this.translate.instant('error') + ' ' + err.code + ' ' + err.message);
                        // });
                    } else if (isNotNullOrUndefined(value) && isNotNullOrUndefined(value.event) && value.typeReport === 'schedaManutenzione') {
                        value.event.elementToSave = this.report;
                        this.saveSchedaManutenzione(value.event, value.nuovoStato)
                    }
                } else {
                    this.loadData();
                }

                // this.utilsService.unlockBody();
            });
    }


    public get myRole() {
        return this.projectService.myRole
    }

    get tuttiAbbonamenti() {
        return {
            telecontrolloAttivo: this.projectService.abbonamentoTelecontrolloAttivo,
            illuminamentoAttivo: this.projectService.abbonamentoIlluminamentoAttivo,
            gestioneAttivo: this.projectService.abbonamentoGestioneAttivo
        }
    }

    public saveSchedaCompilata = new Subject()

    saveSchedaManutenzione(event: {
        elementToSave: any,
        valuesSchedaManutenzione: any,
        keys: formFiledMaintenanceFormContolName[],
        maintenanceScheduleReference: SchedaManutenzioneParse,
        saveOnDatabase: boolean
    }, nuovoStato = null) {
        const parseElement = event.elementToSave
        const valueForm = event.valuesSchedaManutenzione
        const keys = event.keys;
        const schedaManutenzione = event.maintenanceScheduleReference;
        const statusObj = isNotNullOrUndefined(nuovoStato) ? {nuovoStato} : null
        const valueFormCasted = this.maintenanceService.getCastValueToSaveByKeys(valueForm, keys);
        this.maintenanceService.createSchedaManutenzioneCompilataCloud(schedaManutenzione.objectId, valueFormCasted, parseElement).pipe(
            switchMap(schedaCompilata => {
                const res = this.activitiesService.createActivity(
                    this.report, this.activityForm.value.comment, this.activityForm.value.foto, this.activityForm.value.allegato, schedaCompilata, statusObj
                );
                return fromPromise(res)
            })
        ).subscribe(
            commentoSegnalazione => {
                this.maintenanceService.destroyLocalSchedaPrecompilata(schedaManutenzione.objectId, parseElement);
                this.alertService.success(this.translate.instant('activity_success_create'));
                this.loadData();
                this.update.emit();
                this.saveSchedaCompilata.next(true)
            }, error => {
                this.saveSchedaCompilata.next({error: true})
                // this.saveSchedaCompilata.next({error: true})
                this.alertService.error(error);
            }
        );
        // this.maintenanceService.createSchedaManutenzioneCompilata(parseElement, valueForm, keys, valuesSchedaCompilata)
        // .pipe(
        //     switchMap(schedaCompilata => {
        //         const res = this.activitiesService.createActivity(
        //             this.report, this.activityForm.value.comment, this.activityForm.value.foto, this.activityForm.value.allegato, schedaCompilata, statusObj
        //         );
        //         return fromPromise(res)
        //     })
        // ).subscribe(
        //     commentoSegnalazione => {
        //         this.alertService.success(this.translate.instant('activity_success_create'));
        //         this.loadData();
        //         this.saveSchedaCompilata.next(true)
        //     }, error => {
        //         this.saveSchedaCompilata.next({error: true})
        //         // this.saveSchedaCompilata.next({error: true})
        //         this.alertService.error(error);
        //     }
        // );
    }

    showAlert(schedaManutenzione: SchedaManutenzioneCompilataParse) {
        return schedaManutenzione.rifiutata == true;
    }

    getSplitNameForm(form: UntypedFormGroup, key: string) {
        if (form && stringIsSet(form.get(key).value.name)) {
            return form.get(key).value.name.split('_')[1];
        }
    }

    getSplitNameObject(obj: any, key: string) {
        if (isNotNullOrUndefined(obj) && isNotNullOrUndefined(obj.allegato) && isNotNullOrUndefined(obj.allegato[key]) && stringIsSet(obj.allegato[key])) {
            return obj.allegato[key].split('_')[1];
        }
    }


    clickAssignTo() {
        // const users = [this.userService.currentUser];
        this.loading = true;
        const getValue = (user, key) => {
            let value;
            if (key === 'organizzazione') {
                value = (user.get('organizzazione')) ? user.get('organizzazione').nome : null
            } else if (key == 'objectId') {
                value = user.id
            } else if (key == 'role') {
                return user.role;
            } else {
                value = user.get(key)
            }
            return value;
        }
        const getId = (item) => item.id;
        const usersIdInstallatori = [];
        const usersIdOperatori = [];
        const usersIdGestori = [];
        const project = this.projectService.actualProject
        if (arrayIsSet(project.installatori)) {
            project.installatori.forEach(user => {
                const id = getId(user)
                if (!isNotNullOrUndefined(getItemInArrayByKeyValue(usersIdInstallatori, id))) {
                    usersIdInstallatori.push(id)
                }
            })
        }
        if (arrayIsSet(project.operatori)) {
            project.operatori.forEach(user => {
                const id = getId(user)
                if (!isNotNullOrUndefined(getItemInArrayByKeyValue(usersIdOperatori, id))) {
                    usersIdOperatori.push(id)
                }
            })
        }
        if (arrayIsSet(project.gestori)) {
            project.gestori.forEach(user => {
                const id = getId(user);
                if (!isNotNullOrUndefined(getItemInArrayByKeyValue(usersIdGestori, id))) {
                    usersIdGestori.push(id);
                }
            });
        }
        const uniqueId = getUniqueValueInArray(usersIdInstallatori.concat(...usersIdOperatori, ...usersIdGestori));
        if (arrayIsSet(uniqueId)) {
            this.mapService.getUserDetail(uniqueId).pipe(
                map((users) => {
                    return users.map(user => {
                        user['role'] = [];
                        if (getItemInArrayByKeyValue(usersIdGestori, getId(user))) {
                            user['role'].push('gestore');
                        }
                        if (getItemInArrayByKeyValue(usersIdInstallatori, getId(user))) {
                            user['role'].push('installatore');
                        }
                        if (getItemInArrayByKeyValue(usersIdOperatori, getId(user))) {
                            user['role'].push('operatore');
                        }
                        return user;
                    })
                }),
                switchMap((users) => {
                    return this.dialogPopUpInfoService.openSelectUsers(users, getValue, getId, false, this.report.presaInCaricoDa?.id ?? undefined)
                }),
                switchMap(usersSelect => {
                    if (arrayIsSet(usersSelect)) {
                        const userId = getId(usersSelect[0]);
                        if (userId !== this.report.presaInCaricoDa?.id) {
                            return this.planService.assegnaLaSegnalazione(this.report.objectId, userId);
                        }
                        // the assignment is not needed because we'd be reassigning it to the same user
                        return of(null);
                    } else {
                        return of(null)
                    }
                })
            ).subscribe(segnalazione => {
                this.loading = false;
                if (segnalazione !== null) {
                    this.loadData();
                    this.update.emit();
                }
            }, error => {
                this.alertService.error(error.message)
                this.loading = false;
            })
        } else {
            this.alertService.warning(this.translate.instant('noOperatorOrInstallator'));
        }

    }


    get currentUser() {
        return this.userService.currentUser
    }

    get visualizedPresaInCarico() {
        return this.stateReport == ReportStates.APERTO && (this.roleNumber == UserRoleNumber.GESTORE || this.roleNumber == UserRoleNumber.OPERATORE || this.roleNumber == UserRoleNumber.INSTALLATORE)
    }

    get visualizedAssengaA() {
        return this.roleNumber == UserRoleNumber.GESTORE && (
            this.stateReport == ReportStates.APERTO
            || this.stateReport == ReportStates.INCARICO
            || this.stateReport == ReportStates.ASSEGNATOA
            || this.stateReport == ReportStates.INTERVENTO
            || this.stateReport == ReportStates.MATERIALE
            || this.stateReport == ReportStates.INSTALLATO_MULETTO
            || this.stateReport == ReportStates.EMESSO_PREVENTIVO
            || this.stateReport == ReportStates.RICERCA_GUASTO
            || this.stateReport == ReportStates.MESSA_IN_SICUREZZA
            || this.stateReport == ReportStates.IN_ATTESA
        )
    }


    // get visualizedAssengaA() {
    //     return this.roleNumber == UserRoleNumber.GESTORE
    //         && isNotNullOrUndefined(this.stateReport)
    //         && (
    //             this.stateReport == ReportStates.APERTO
    //             || this.stateReport == ReportStates.INCARICO
    //             || this.stateReport == ReportStates.ASSEGNATOA
    //             || this.stateReport == ReportStates.INTERVENTO
    //             || this.stateReport == ReportStates.MATERIALE
    //         )
    // }

    get visualizedGeneraReport() {
        return isNotNullOrUndefined(this.report.presaInCaricoDa)
            && this.report.presaInCaricoDa.id == this.currentUser.id
            && (
                this.roleNumber == UserRoleNumber.GESTORE
                || this.roleNumber == UserRoleNumber.OPERATORE
                || this.roleNumber == UserRoleNumber.INSTALLATORE
            )
            && (
                this.stateReport == ReportStates.APERTO
                || this.stateReport == ReportStates.INCARICO
                || this.stateReport == ReportStates.ASSEGNATOA
                || this.stateReport == ReportStates.INTERVENTO
                || this.stateReport == ReportStates.MATERIALE
                || this.stateReport == ReportStates.INSTALLATO_MULETTO
                || this.stateReport == ReportStates.EMESSO_PREVENTIVO
                || this.stateReport == ReportStates.RICERCA_GUASTO
                || this.stateReport == ReportStates.MESSA_IN_SICUREZZA
                || this.stateReport == ReportStates.IN_ATTESA
            )
    }

    get visualizedChiudiSegnalazione() {
        return this.roleNumber == UserRoleNumber.GESTORE && (this.stateReport == ReportStates.COMPLETATO)
    }


    get visualizedRichiediIntervento() {
        return this.visualizedChiudiSegnalazione
    }

    get disableSaveComment() {
        return (this.typeComment === 'comment' && !this.activityForm.get('comment').value)
            || (this.typeComment === 'file' && !this.activityForm.get('allegato').value)
            || (this.typeComment === 'photo' && !this.activityForm.get('foto').value)
    }


    printReports() {
        this.progress.print = 0;
        this.disableButton.print = true;
        const today = new Date()
        const reference = this.report.createdAt;
        this.reportMaintenanceService.createPdfByReports([this.report], [], reference, today)
            .subscribe(values => {
                    const progress = values.currentValue
                    this.progress.print = progress.progress as any
                    if (progress.finished) {
                        this.disableButton.print = false;
                        this.progress.print = undefined;
                    }
                },
                error => {
                    console.log(error)
                    this.disableButton.print = false;
                    this.progress.print = undefined;
                    this.alertService.error(error)
                });
    }

    openOtherText() {
        this.dialog.open(this.extendedDescription)
    }

}