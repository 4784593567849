<mat-toolbar class="">
    <mat-toolbar-row>
        <button color="primary" mat-icon-button (click)="onClickNav()" [disabled]="navbarService.disabledHamburger">
            <mat-icon>menu</mat-icon>
        </button>
        <a routerLink="/">
            <img src="assets/logo.png" width="100px">
        </a>
        <span class="example-spacer"></span>
        <b>{{nameProject}}</b>
        <span class="example-spacer"></span>
        <a mat-icon-button color="primary" routerLink="/gestione-abbonati"
           *ngIf="isAmministratore">
            <mat-icon color="primary" class="example-icon">
                supervised_user_circle
            </mat-icon>
        </a>
        <mat-icon color="primary" class="example-icon" [matMenuTriggerFor]="projects">
            assignment
        </mat-icon>
        <mat-icon color="primary" class="example-icon" [matMenuTriggerFor]="menu">
            account_circle
        </mat-icon>
        <button mat-icon-button [matMenuTriggerFor]="language" matTooltip="{{'selectLanguage'|translate}}"
                style="margin-top: -3px;">
            <img src="assets/icon/flags/{{iconLanguage+'.svg'}}" color="primary" class="example-icon"
                 class="img-icon">
        </button>
    </mat-toolbar-row>
</mat-toolbar>

<mat-menu #projects="matMenu">
    <mat-nav-list>
        <ng-container *ngIf="(myProjects$|async) as myProjects">
            <mat-list-item *ngFor="let item of myProjects" mat-list-item
                           (click)="setProject(item.objectId, null, item);"
                           [class.list-item-active]="projectActive(item.objectId)"
                           [routerLink]="getPathProject(item.firstViewOnOpening)">
                {{item.name}}
            </mat-list-item>
        </ng-container>
        <ng-container *ngIf="(freeProjects$|async) as freeProjects">
            <mat-list-item *ngFor="let item of freeProjects" (click)="setProject(item.objectId, 'Utente', item);"
                           [class.list-item-active]="projectActive(item.objectId)"
                           [routerLink]="getPathProject(item.firstViewOnOpening)">
                {{'progetto_nav' | translate:{name: item.name} }}
            </mat-list-item>
        </ng-container>
    </mat-nav-list>
</mat-menu>

<mat-menu #language="matMenu">
    <div mat-menu-item style="text-align: center;" (click)="setLanguage('it')">
        <button mat-icon-button matTooltip="Italiano">
            <img src="assets/icon/flags/it.svg" color="primary" class="example-icon" class="img-icon">
        </button>
    </div>
    <div mat-menu-item style="text-align: center;" (click)="setLanguage('ca')">
        <button mat-icon-button matTooltip="Català">
            <img src="assets/icon/flags/ca.svg" color="primary" class="example-icon" class="img-icon">
        </button>
    </div>
    <div mat-menu-item style="text-align: center;" (click)="setLanguage('es')">
        <button mat-icon-button matTooltip="Español">
            <img src="assets/icon/flags/es.svg" color="primary" class="example-icon" class="img-icon">
        </button>
    </div>
    <div mat-menu-item style="text-align: center;" (click)="setLanguage('en')">
        <button mat-icon-button matTooltip="English">
            <img src="assets/icon/flags/en.svg" color="primary" class="example-icon" class="img-icon">
        </button>
    </div>
    <div mat-menu-item style="text-align: center;" (click)="setLanguage('fr')">
        <button mat-icon-button matTooltip="Francaise">
            <img src="assets/icon/flags/fr.svg" color="primary" class="example-icon" class="img-icon">
        </button>
    </div>
</mat-menu>


<mat-menu #menu="matMenu">
    <a [disabled]="true" mat-menu-item>{{'welcome' | translate:{name: user?.nome} }}</a>
    <a mat-menu-item [routerLink]="'/user/account'">
        <mat-icon>edit</mat-icon>
        <span>{{'edit_profile' | translate}}</span>
    </a>
    <a mat-menu-item (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
        <span>{{'exit' | translate}}</span>
    </a>
    <a mat-menu-item [disabled]="true">
        <div class="flex-center-width100">
            <img src="assets/icon/hamburgerNavbar/version.png" style="max-width: 24px;">
            <span style="margin-left: 0.4rem;color: black">{{'version' | translate}} {{currentVersion}}</span>
        </div>
    </a>
</mat-menu>
