<mat-dialog-content class="scrollable-dialog-content">
    <form [formGroup]="formType">
        <div class="flex-center-width100 flex-flow-column">
            <div class="flex-center-width100">
                <mat-form-field>
                    <mat-label>{{'scheduleMaintenance.traduction'|translate}}</mat-label>
                    <input matInput formControlName="traduction">
                    <mat-error *ngIf="getErrors('traduction','isPresentInArray')">
                        {{'nameUsed'|translate}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="flex-center-width100">
                <mat-form-field>
                    <mat-label>{{'scheduleMaintenance.note'|translate}}</mat-label>
                    <textarea matInput formControlName="note">
                    </textarea>
                </mat-form-field>
                <button
                        matSuffix mat-icon-button
                        color="primary"
                        (click)="resetForm('note')">
                    <mat-icon>clear</mat-icon>
                </button>
            </div>
            <div class="flex-center-width100">
                <mat-checkbox formControlName="required">{{'required'|translate}}</mat-checkbox>
            </div>
            <div *ngIf="visualizedAddOption" style="width: 100%;">
                <ng-container [formGroupName]="'formPossibleValues'">
                    <mat-form-field>
                        <mat-label>
                            {{'options'|translate}}
                        </mat-label>
                        <input matInput formControlName="possibleValue">
                        <button
                                [disabled]="formType.get('formPossibleValues').get('possibleValue').invalid||isPresentPossibleValues"
                                matSuffix mat-icon-button class="small"
                                (click)="addPossibleValue()">
                            <mat-icon>add</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-chip-list>
                        <mat-chip *ngFor="let item of possibleValues"
                                    [removable]="true" (removed)="remove(item)">
                            {{item}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </ng-container>
            </div>
            <mat-radio-group formControlName="type" style="width: 100%;">
                <div class="flex-center-width100 flex-flow-column">
                    <div class="radio-list">
                        <mat-radio-button [value]="typeFormField.inputString" class="field-type-selector-wrapper">
                            {{'scheduleMaintenance.keyFree'|translate}}
                        </mat-radio-button>
                        <mat-radio-button [value]="typeFormField.inputNumber" class="field-type-selector-wrapper">
                            {{'scheduleMaintenance.numeric'|translate}}
                        </mat-radio-button>
                        <mat-radio-button [value]="typeFormField.multiSelect" class="field-type-selector-wrapper">
                            {{'scheduleMaintenance.multiSelect'|translate}}
                        </mat-radio-button>
                        <mat-radio-button [value]="typeFormField.autoComplete" class="field-type-selector-wrapper">
                            {{'scheduleMaintenance.autoComplete'|translate}}
                        </mat-radio-button>
                        <mat-radio-button [value]="typeFormField.dataPicker" class="field-type-selector-wrapper">
                            {{'scheduleMaintenance.date'|translate}}
                        </mat-radio-button>
                        <mat-radio-button [value]="typeFormField.hour" class="field-type-selector-wrapper">
                            {{'scheduleMaintenance.hour'|translate}}
                        </mat-radio-button>
                        <mat-radio-button [value]="typeFormField.image" class="field-type-selector-wrapper">
                            {{'scheduleMaintenance.image'|translate}}
                        </mat-radio-button>
                        <mat-radio-button [value]="typeFormField.checkBox" class="field-type-selector-wrapper">
                            {{'scheduleMaintenance.checkBox'|translate}}
                            <span class="sub-form">{{'scheduleMaintenance.checkBoxInfo'|translate}}</span>
                        </mat-radio-button>
                        <mat-radio-button [value]="typeFormField.yesOrNot" class="field-type-selector-wrapper">
                            {{'scheduleMaintenance.yesOrNot'|translate}}
                            <span class="sub-form">{{'scheduleMaintenance.yesOrNoButtonInfo'|translate}}</span>
                        </mat-radio-button>
                    </div>
                </div>
            </mat-radio-group>
        </div>
    </form>
</mat-dialog-content>


<mat-dialog-actions>
    <button [mat-dialog-close]="undefined" color="warn"
            mat-raised-button>
        <mat-icon>clear</mat-icon>
    </button>
    <button [disabled]="formType.invalid"
            (click)="confirm()"
            mat-raised-button color="primary">
        <mat-icon>task_alt</mat-icon>
    </button>
    <button [disabled]="formType.invalid"
            (click)="confirm(true)"
            mat-raised-button color="accent">
        <mat-icon>add_task</mat-icon>
    </button>
</mat-dialog-actions>