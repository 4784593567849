<form [formGroup]="dateForm" style="height: 100%">
    <div class="flex-center-width100" style="margin-top: 20px">
        <mat-card class="dimension-favorites-segnalazioni">
            <mat-card-subtitle>
                {{'segnalazioniParse.visualizedSegnalzioni'|translate}}
                <mat-checkbox style="margin-left: 30px" formControlName="visualizedSegnalzioni"></mat-checkbox>
            </mat-card-subtitle>
            <app-chips-multiselect-form-field
                    formControlName="prioritySegnalzioni"
                    [possibleValues]="keyChipsPrioritySegnalazioni"
            >
            </app-chips-multiselect-form-field>
        </mat-card>
    </div>
    <div class="flex-center-width100" style="margin-top: 20px">
        <mat-chip-list [disabled]="dateForm.disabled" [multiple]="true">
            <mat-chip class="clickable-chip"
                      [value]="typeElementScheduleMaintence.PuntiLuce"
                      [color]="chipColor(typeElementScheduleMaintence.PuntiLuce)"
                      (click)="setTypeSchedaMautenzione(typeElementScheduleMaintence.PuntiLuce)"
                      [ngClass]="{'disable-chip':dateForm.disabled}"
                      [selected]="chipIsSelected(typeElementScheduleMaintence.PuntiLuce)"
            >
                {{'scheduleMaintenance.' + typeElementScheduleMaintence.PuntiLuce|translate}}
            </mat-chip>
            <mat-chip class="clickable-chip"
                      [value]="typeElementScheduleMaintence.Circuiti"
                      [color]="chipColor(typeElementScheduleMaintence.Circuiti)"
                      (click)="setTypeSchedaMautenzione(typeElementScheduleMaintence.Circuiti)"
                      [ngClass]="{'disable-chip':dateForm.disabled}"
                      [selected]="chipIsSelected(typeElementScheduleMaintence.Circuiti)"
            >
                {{'scheduleMaintenance.' + typeElementScheduleMaintence.Circuiti|translate}}
            </mat-chip>
        </mat-chip-list>
    </div>
    <div class="flex-center-width100"
         style="flex-flow: row;flex-wrap: wrap;justify-content: space-evenly;margin-top:40px;margin-bottom:10px; padding: 0 4px; box-sizing: border-box;"
    >
        <app-move-date-with-range class="w100"
                [settingDateMin]="fromDate$|async"
                [settingDateMax]="toDate$|async"
                [deltaDate]="deltaDate$|async"
                (changeDateEnd)="changeDateEnd($event)"
                (changeDateStart)="changeDateStart($event)"
                (changeCalendar)="changeCalendar($event)"
        >
        </app-move-date-with-range>
        <!--        <div class="calendar-navigation-wrapper">-->
        <!--            <button [disabled]="dateForm.get('year')&&dateForm.get('year').disabled"-->
        <!--                    color="accent"-->
        <!--                    mat-mini-fab-->
        <!--                    (click)="changeYearPrevius()"-->
        <!--            >-->
        <!--                <mat-icon>keyboard_arrow_left</mat-icon>-->
        <!--                <mat-icon style="margin-left: -15px;margin-right: 3px;">keyboard_arrow_left</mat-icon>-->
        <!--            </button>-->
        <!--            <button [disabled]="dateForm.get('month')&&dateForm.get('month').disabled"-->
        <!--                    (click)="changeMonthPrevius()"-->
        <!--                    color="accent"-->
        <!--                    mat-mini-fab-->
        <!--            >-->
        <!--                <mat-icon>keyboard_arrow_left</mat-icon>-->
        <!--            </button>-->
        <!--            <mat-card class="date-container">-->
        <!--                {{dateForHtml}}-->
        <!--            </mat-card>-->
        <!--            <button [disabled]="dateForm.get('month')&&dateForm.get('month').disabled"-->
        <!--                    (click)="changeMonthNext()"-->
        <!--                    color="accent"-->
        <!--                    mat-mini-fab-->
        <!--            >-->
        <!--                <mat-icon>keyboard_arrow_right</mat-icon>-->
        <!--            </button>-->
        <!--            <button [disabled]="dateForm.get('year')&&dateForm.get('year').disabled"-->
        <!--                    color="accent"-->
        <!--                    mat-mini-fab-->
        <!--                    (click)="changeYearNext()"-->
        <!--            >-->
        <!--                <mat-icon style="margin-left: 3px;">keyboard_arrow_right</mat-icon>-->
        <!--                <mat-icon style="margin-left: -15px;">keyboard_arrow_right</mat-icon>-->
        <!--            </button>-->
        <!--        </div>-->
        <div class="w100" style="text-align: center;margin-top: 10px">
            <mat-checkbox
                    formControlName="visualizedExpired">{{'scheduleMaintenance.visualizedExpired'|translate}}</mat-checkbox>
        </div>
        <mat-card *ngIf="dateForm.get('visualizedExpired').value" class="w100"
                  style="padding: 0;margin: 10px 20px 10px 20px"
                  [matBadge]="getLength(clusterManutenzioniNonCompilate)" matBadgeColor="warn"
        >
            <mat-card-title class="color-warn"
                            style="
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            padding: 10px;
            font-size: medium;
            white-space: normal;">
                {{'scheduleMaintenance.maintenanceExpired'|translate}}
            </mat-card-title>
            <div class="flex-center-width100 flex-flow-column"
            >
                <div class="dimension-favorites"
                     *ngFor="let cluster of clusterManutenzioniNonCompilate|filterByPredicateFunction:predicateFunction:this.dateForm.get('typeSchedaMautenzione').value">
                    <mat-card style="margin: 10px; padding: 0;">
                        <button [disabled]="disabled" class="box-main-card-clickable-header"
                                [ngClass]="getBgClass(cluster, true)" (click)="clickCard(cluster)">
                            <mat-card-header>
                                <mat-card-title>
                                    {{cluster.date|customDate:'':'d MMMM'|async}}
                                </mat-card-title>
                                <mat-card-subtitle style="margin-bottom: 0;">
                                    {{'Q_ta'|translate}} {{cluster.calendariManutenzione.length}}
                                </mat-card-subtitle>
                            </mat-card-header>
                        </button>
                        <mat-card-content style="padding: 10px;">
                            <div class="flex-center-Between w100 flex-flow-column">
                                <ng-container
                                        *ngFor="let detail of  getDetailCluster(cluster); trackBy: clusterTracker">
                                    <ng-container *ngIf="detail as detailStamp">
                                        <div class="w100">
                                            <app-box-general-info
                                                    [titleCardIcon]="getTitleIcon(detailStamp)"
                                                    [details]="getDetail(detailStamp)"
                                            >
                                            </app-box-general-info>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </mat-card>
        <ng-container *ngIf="!(disabledKey&&(disabledKey.month||disabledKey.year));else loadingMonthYear">
            <div class="flex-center-width100 flex-flow-column" *ngIf="clusterManutenzioni">
                <div class="dimension-favorites"
                     *ngFor="let cluster of clusterManutenzioni|filterByPredicateFunction:predicateFunction:this.dateForm.get('typeSchedaMautenzione').value">
                    <mat-card style="margin: 10px; padding: 0;">
                        <button [disabled]="disabled" class="box-main-card-clickable-header"
                                [ngClass]="getBgClass(cluster)" (click)="clickCard(cluster)">
                            <mat-card-header>
                                <mat-card-title>
                                    {{cluster.date|customDate:'':'d MMMM'|async}}
                                </mat-card-title>
                                <mat-card-subtitle style="margin-bottom: 0;">
                                    {{'Q_ta'|translate}} {{cluster.calendariManutenzione.length}}
                                </mat-card-subtitle>
                            </mat-card-header>
                        </button>
                        <mat-card-content style="padding: 10px;">
                            <div class="flex-center-Between w100 flex-flow-column">
                                <ng-container
                                        *ngFor="let detail of  getDetailCluster(cluster); trackBy: clusterTracker">
                                    <ng-container *ngIf="detail as detailStamp">
                                        <div class="w100">
                                            <app-box-general-info
                                                    [titleCardIcon]="getTitleIcon(detailStamp)"
                                                    [details]="getDetail(detailStamp)"
                                            >
                                            </app-box-general-info>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </ng-container>
        <ng-template #loadingMonthYear class="flex-center-width100 flex-flow-column">
            <mat-spinner diameter="40"></mat-spinner>
        </ng-template>
        <!--        <mat-form-field appearance="fill" class="chield-form-field">-->
        <!--            <mat-label>{{'mese'| translate}}</mat-label>-->
        <!--            <mat-select [formControlName]="'monthLuxCluster'">-->
        <!--                <mat-option *ngFor="let month of months" [value]="month">-->
        <!--                    {{getMonthExtendedName(month - 1) }}-->
        <!--                </mat-option>-->
        <!--            </mat-select>-->
        <!--        </mat-form-field>-->
        <!--        <mat-form-field appearance="fill" class="chield-form-field">-->
        <!--            <mat-label>{{ 'anno'| translate}}</mat-label>-->
        <!--            <mat-select [formControlName]="'yearLuxCluster'">-->
        <!--                <mat-option *ngFor="let year of years" [value]="year">-->
        <!--                    {{year}}-->
        <!--                </mat-option>-->
        <!--            </mat-select>-->
        <!--        </mat-form-field>-->
        <!--        <button-->
        <!--                style="margin-top:-25px;margin-bottom: 5px"-->
        <!--                mat-raised-button color="primary" (click)="emitNewMonthYear()"-->
        <!--                [disabled]="!dateForm.dirty || monthYearNotChanged() || !illuminamentoATerraAttivo">-->
        <!--            <mat-icon>check</mat-icon>-->
        <!--        </button>-->
    </div>
</form>
