import * as Parse from 'parse';

export class ClusterLuxDataParse extends Parse.Object {
  [x: string]: any;

  public static CLASS_NAME = 'ClusterLuxData';

  constructor() {
    super(ClusterLuxDataParse.CLASS_NAME);
  }

  public set type(value) {
    super.set('type', value);
  }

  public get type() {
    return super.get('type');
  }

  public set location(value) {
    super.set('location', value);
  }

  public get location() {
    return super.get('location');
  }

  public set value(value) {
    super.set('value', value);
  }

  public get value() {
    return super.get('value');
  }

  public set geohash(value) {
    super.set('geohash', value);
  }

  public get geohash() {
    return super.get('geohash');
  }

  public set precision(value) {
    super.set('precision', value);
  }

  public get precision() {
    return super.get('precision');
  }

  public set clusterPoints(value) {
    super.set('clusterPoints', value);
  }

  public get clusterPoints() {
    return super.get('clusterPoints');
  }

  public set referenceMonth(value) {
    super.set('referenceMonth', value);
  }

  public get referenceMonth() {
    return super.get('referenceMonth');
  }

  public set referenceYear(value) {
    super.set('referenceYear', value);
  }

  public get referenceYear() {
    return super.get('referenceYear');
  }

  public set referenceHour(value) {
    super.set('referenceHour', value);
  }

  public get referenceHour() {
    return super.get('referenceHour');
  }

  static getLatLngFromLocation(cluster){
    return new google.maps.LatLng(cluster.get("location").latitude,cluster.get("location").longitude)
  }



}


Parse.Object.registerSubclass(ClusterLuxDataParse.CLASS_NAME, ClusterLuxDataParse);
