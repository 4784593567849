import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {DashboardRoutingModule} from './dashboard-routing.module';
import {DashboardComponent} from './dashboard/dashboard.component';
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {MatLegacyOptionModule as MatOptionModule} from "@angular/material/legacy-core";
import {MatDividerModule} from "@angular/material/divider";
import {MatLegacyFormFieldModule as MatFormFieldModule} from "@angular/material/legacy-form-field";
import {MatLegacyListModule as MatListModule} from "@angular/material/legacy-list";
import {MatLegacySelectModule as MatSelectModule} from "@angular/material/legacy-select";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatLegacyTableModule as MatTableModule} from "@angular/material/legacy-table";
import {MatToolbarModule} from "@angular/material/toolbar";
import {SelectProjectComponent} from './select-project/select-project.component';
import {CoreModule} from "../../core/core.module";
import {DashboardSidenavModule} from "../../components/dashboard-sidenav/dashboard-sidenav.module";
import {FilterModule} from "../../components/filter/filter.module";
// import {AgmCoreModule, GoogleMapsAPIWrapper, MarkerManager} from "@agm/core";
import {InfoDialogComponent} from "./info-dialog/info-dialog.component";
import {TranslateModule} from "@ngx-translate/core";
import {WidgetsModule} from "../../widgets/widgets.module";
// import {AgmJsMarkerClustererModule} from "@agm/js-marker-clusterer";
import {MatLegacyTooltipModule as MatTooltipModule} from "@angular/material/legacy-tooltip";
import {CreateReportComponent} from "../reports/create-report/create-report.component";
import {CreateGroupComponent} from "../reports/create-group/create-group.component";
import {InfoReportComponent} from "../reports/info-report/info-report.component";
import {InfoGroupComponent} from "../reports/info-group/info-group.component";
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from "@angular/material/legacy-autocomplete";
import {MatIconModule} from '@angular/material/icon';
import {SettingModule} from 'src/app/components/setting/setting.module';
import {BoxesModule} from "../../boxes/boxes.module";
import {ProjectSidenavModule} from "../../components/project-sidenav/project-sidenav.module";
import {DirectivesModule} from "../../providers/directives/directives.module";
import {BatchLightEditorModule} from 'src/app/components/batch-light-editor/batch-light-editor.module';
import {ScaleForMapModule} from "../../components/scale-for-map/scale-for-map.module";
import {PipesModule} from "../../providers/pipes/pipes.module";
import {MatLegacyInputModule as MatInputModule} from "@angular/material/legacy-input";
import {ReactiveFormsModule} from "@angular/forms";
import {PagesMainMapComponent} from "./pages-main-map/pages-main-map.component";
import {ComponentWithMapModule} from "../../components/component-with-map/component-with-map.module";
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from "@angular/material/legacy-progress-spinner";
import {ScheduleMaintenaceUtilitiesModule} from "../../components/schedule-maintenace-utilities/schedule-maintenace-utilities.module";
import {MatLegacyCheckboxModule as MatCheckboxModule} from "@angular/material/legacy-checkbox";
import {PopUpInfoModule} from "../../components/pop-up-info/pop-up-info.module";


@NgModule({
    declarations: [DashboardComponent, SelectProjectComponent, InfoDialogComponent, PagesMainMapComponent],
    imports: [
        MatButtonToggleModule,
        CommonModule,
        FilterModule,
        DashboardRoutingModule,
        MatCardModule,
        MatFormFieldModule,
        TranslateModule,
        MatOptionModule,
        MatTableModule,
        MatSelectModule,
        // AgmCoreModule,
        // AgmJsMarkerClustererModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatDividerModule,
        MatListModule,
        MatToolbarModule,
        MatSidenavModule,
        CoreModule,
        DashboardSidenavModule,
        WidgetsModule,
        MatTooltipModule,
        MatIconModule,
        SettingModule,
        BoxesModule,
        ProjectSidenavModule,
        DirectivesModule,
        BatchLightEditorModule,
        ScaleForMapModule,
        PipesModule,
        MatInputModule,
        ReactiveFormsModule,
        ComponentWithMapModule,
        MatProgressSpinnerModule,
        ScheduleMaintenaceUtilitiesModule,
        MatCheckboxModule,
        PopUpInfoModule
    ],
    // providers: [GoogleMapsAPIWrapper, MarkerManager],
    exports: [
        DashboardComponent
    ]
})
export class DashboardModule {
}
