import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-input-errors',
  templateUrl: './input-errors.component.html',
  styleUrls: ['./input-errors.component.scss']
})
export class InputErrorsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  @Input()
  public element;
  @Input()
  public matcher;
  @Input()
  public filterForm;

}
