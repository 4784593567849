import * as Parse from 'parse';
import {arrayIsSet, className, isNotNullOrUndefined, paramsApiParse_v2, stringIsSet} from "../models/Models";
import {ProjectService} from "../providers/services/project.service";
import {LocalFilters} from "../pages-modules/reports/list-reports/list-reports.component";
import {fromPromise} from "rxjs/internal-compatibility";
import {BehaviorSubject} from "rxjs";


export abstract class ListAbstract {

    private dataEmit = new BehaviorSubject<{ rows: any, count: number }>({rows: null, count: 0})
    public data$ = this.dataEmit.asObservable();
    public loading = true;
    public loadFilter = true;
    public error = false;
    private skip = 0;
    private columnSort = 'objectId';
    private typeSort;
    private term;
    public loaded = 0;
    public showTable = false;
    public resolved = false;
    public filterDate;
    public activeFilters;
    public errorMessage;

    protected constructor(
        public readonly listConfig: ListConfig,
        protected projectService: ProjectService
    ) {
        this.getData();
    }

    get data() {
        return this.dataEmit.value;
    }

    set data(value) {
        this.dataEmit.next(value);
    }

    public forceUpdateData() {
        this.getData();
    }

    public abstract onUpdateElement(event);

    public onChangePage(page: number) {
        this.skip = page * this.listConfig.take;
        this.loadFilter = true;
        this.getData();
    }

    public onResolved() {
        this.resolved = !this.resolved;
        this.getData();
    }

    public onFilterDate(event) {
        this.filterDate = event.value;
        this.loadFilter = true;
        this.getData();
    }

    public onChangeSort({column, type}) {
        this.columnSort = column;
        this.typeSort = type;
        this.loadFilter = true;
        this.getData();
    }

    public onSearch(term: string) {
        this.term = term;
        this.loadFilter = true;
        this.getData();
    }

    public onFilter(filters: {
        label: string, items: Filter[], type: string, code: string
    }[]) {
        this.activeFilters = filters;
        this.getData();
    }

    public addOrSubstituionFilter(filters: {
        label: string, items: Filter[], type: string, code: string
    }[]) {
        if (arrayIsSet(this.activeFilters)) {
            filters.forEach(filter => {
                const index = this.activeFilters.findIndex(activeFilter => filter.code === activeFilter.code);
                if (index >= 0) {
                    this.activeFilters[index] = filter
                } else {
                    this.activeFilters.push(filter)
                }
            })
        } else {
            this.activeFilters = filters;
        }
        this.onFilter(this.activeFilters);
    }

    filterScehdeManutenzione: any

    public onSchedaManutenzioneCompilata(event: any) {
        this.filterScehdeManutenzione = event
        this.getData();
    }


    public async getData() {
        if ((isNotNullOrUndefined(this.listConfig.staticFilters) && isNotNullOrUndefined(this.listConfig.staticFilters.value)) || !isNotNullOrUndefined(this.listConfig.staticFilters)) {
            try {

                localStorage.setItem('reportsFilterCheckRadio', JSON.stringify(this.listConfig.filters));
                this.error = false;
                const Model = Parse.Object.extend(this.listConfig.model);
                if (this.listConfig.model === className.segnalazioni) {
                    let lastUsefulDate;
                    this.data.count = 0;
                    this.data.rows = [];
                    if (this.listConfig.dataFilter) {
                        const date = new Date();
                        //console.log(Number(this.filterDate));
                        switch (Number(this.filterDate)) {
                            case 1:
                                lastUsefulDate = new Date(date.setDate(date.getDate() - 14));
                                break;
                            case 2:
                                lastUsefulDate = new Date(date.setDate(date.getDate() - 30));
                                break;
                            case 3:
                                lastUsefulDate = new Date(date.setDate(date.getDate() - 182));
                                break;
                            case 4:
                                lastUsefulDate = new Date(date.setDate(date.getDate() - 365));
                                break;
                            case 5:
                                break;
                            case 0:
                            default:
                                lastUsefulDate = new Date(date.setDate(date.getDate() - 7));
                                break;
                        }
                    }
                    let priority;
                    let elementsAccepted;
                    if (arrayIsSet(this.activeFilters)) {
                        this.activeFilters.forEach(activeFilter => {
                            if (activeFilter.code === 'general') {
                                if (arrayIsSet(activeFilter.items)) {
                                    activeFilter.items.forEach(item => {
                                        if (elementsAccepted == null) {
                                            elementsAccepted = [];
                                        }
                                        elementsAccepted.push(item.type);
                                    })
                                }
                            } else if (activeFilter.code === 'priority') {
                                priority = activeFilter.value
                            }
                        })
                    }
                    const limit = this.listConfig.pagination && this.listConfig.take ? this.listConfig.take : undefined
                    const searchString = stringIsSet(this.term) ? {
                        value: this.term,
                        columns: this.listConfig.columnsToSearch
                    } : undefined
                    const params = paramsApiParse_v2({
                        progettoId: this.projectService.currentProject.objectId,
                        priority,
                        lastUsefulDate,
                        elementsAccepted,
                        resolved: this.resolved,
                        columnSort: this.columnSort,
                        skip: this.skip,
                        limit,
                        typeSort: this.typeSort,
                        searchString
                    });
                    fromPromise(Parse.Cloud.run('getSegnalazioniByFilter', params))
                        .subscribe(({segnalazioni, count}) => {
                            if (arrayIsSet(segnalazioni)) {
                                this.data.rows = segnalazioni.map((item) => {
                                    return item.toJSON();
                                });
                                this.data.count = count;
                                // if (this.loaded === 0) {
                                this.showTable = count !== 0;
                                this.data = {...this.data};
                            } else {
                                this.data.rows = undefined
                                this.data.count = 0;
                                // if (this.loaded === 0) {
                                this.showTable = false;
                                this.data = {...this.data};
                            }
                            this.loading = false;
                            this.loadFilter = false;

                        }, error => {
                            this.data.rows = undefined
                            this.data.count = 0;
                            // if (this.loaded === 0) {
                            this.showTable = false;
                            this.loading = false;
                            this.loadFilter = false;
                        });
                    this.loaded++;

                } else {
                    let query = new Parse.Query(Model);
                    if (this.listConfig.project) {
                        if (this.projectService.currentProject) {
                            if (this.term) {
                                const innerQueries = [];
                                this.listConfig.columnsToSearch.forEach((item) => {
                                    const innerQuery = new Parse.Query(Model);
                                    // innerQuery.contains does not allow a case insensitive search
                                    // innerQuery.matches uses a regex instead of a string (hence the replace to escape
                                    // the special characters). This is not the best solution because if a new js standard
                                    // were to update the syntax of regular expressions, searching forsome specific strings
                                    // might not work as expected anymore.
                                    // The best option would be to use innerQuery.fullText instead, but it would require
                                    // the creation of an index for each column that may be searched (otherwise an error
                                    // is thrown).
                                    innerQuery.matches(item, this.term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i');
                                    // innerQuery.contains(item, this.term);
                                    // funzione fantastica per la ricerca ma funziona su una sola colonna
                                    // innerQuery.fullText(item, this.term, {caseSensitive: false, diacriticSensitive: false})
                                    innerQueries.push(innerQuery);
                                });
                                query = Parse.Query.and(Parse.Query.or(...innerQueries));
                            }
                            query.equalTo('progetto', this.projectService.currentProject);
                            if (this.listConfig.dataFilter) {
                                const date = new Date();
                                //console.log(Number(this.filterDate));
                                switch (Number(this.filterDate)) {
                                    case 1:
                                        query.greaterThanOrEqualTo('updatedAt', new Date(date.setDate(date.getDate() - 14)));
                                        break;
                                    case 2:
                                        query.greaterThanOrEqualTo('updatedAt', new Date(date.setDate(date.getDate() - 30)));
                                        break;
                                    case 3:
                                        query.greaterThanOrEqualTo('updatedAt', new Date(date.setDate(date.getDate() - 182)));
                                        break;
                                    case 4:
                                        query.greaterThanOrEqualTo('updatedAt', new Date(date.setDate(date.getDate() - 365)));
                                        break;
                                    case 5:
                                        break;
                                    case 0:
                                    default:
                                        query.greaterThanOrEqualTo('updatedAt', new Date(date.setDate(date.getDate() - 7)));
                                        break;
                                }
                            }
                            if (this.compareString(this.listConfig.model, 'Segnalazioni') && !this.resolved) {
                                query.notEqualTo('stato', 'Risolto');
                            }

                            if (this.listConfig.filters && this.activeFilters) {
                                query = this.listConfig.filters.filterFunction(query, this.listConfig.model, this.activeFilters);
                                //(this.listConfig.model, this.activeFilters);
                            }
                            // if (this.filterScehdeManutenzione) {
                            //     query.equalTo('');
                            //     console.log(this.filterScehdeManutenzione)
                            // }

                            if (this.columnSort) {
                                if (this.typeSort) {
                                    if (this.compareString(this.typeSort, 'desc')) {
                                        query.descending(this.columnSort);
                                    } else if (this.compareString(this.typeSort, 'asc')) {
                                        query.ascending(this.columnSort);
                                    }
                                } else {
                                    if (
                                        this.compareString(this.listConfig.model, 'Segnalazioni') &&
                                        this.compareString(this.columnSort, 'objectId')
                                    ) {
                                        this.columnSort = 'updatedAt';
                                    }
                                    query.descending(this.columnSort);
                                }
                            }

                            if (this.listConfig.staticFilters) {
                                query = this.whereRelation(
                                    query,
                                    this.listConfig.staticFilters.relationObjectName,
                                    this.listConfig.staticFilters.relationColumnName,
                                    this.listConfig.staticFilters.value,
                                    this.listConfig.staticFilters.otherColumnName
                                );
                            }
                            query.limit(this.listConfig.pagination && this.listConfig.take ? this.listConfig.take : undefined);
                            query.skip(this.skip);
                            const res = await query.find();
                            const count = await query.count();

                            this.data.rows = res.map((item) => {
                                return item.toJSON();
                            });
                            this.data.count = count;
                            // if (this.loaded === 0) {
                            this.showTable = count !== 0;
                            // }

                            this.loaded++;
                            this.loading = false;
                            this.loadFilter = false;
                        } else {
                            this.data.count = 0;
                            this.data.rows = [];
                            this.showTable = false;
                            this.loaded++;
                            this.loading = false;
                            this.loadFilter = false;
                        }
                        this.data = {...this.data};
                    }
                }
            } catch (e) {
                this.loading = false;
                this.loadFilter = false;
                this.error = true;
                this.errorMessage = e.message;
                console.log(e);
                return e;
            }

        } else {
            this.loading = false;
            this.loadFilter = false;

        }

    }

    private compareString(string1, string2) {
        // tslint:disable-next-line:triple-equals
        return string1 == string2;
    }

    private whereRelation(query: Parse.Query<Parse.Object>, relationObjectName: string, relationColumnName: string, value, otherColumnName: string = 'objectId') {
        const Relation = Parse.Object.extend(relationObjectName);
        const innerQuery = new Parse.Query(Relation);
        innerQuery.equalTo(otherColumnName, value);
        query.matchesQuery(relationColumnName, innerQuery);
        return query;
    }
}

export interface ListConfig {
    search?: boolean;
    pagination?: boolean;
    model?: string;
    order?: string[];
    take?: number;
    properties: { [key: string]: string }[];
    columnsToDisplay: string[];
    columnsToSearch: string[];
    project: boolean;
    resolved?: boolean;
    dataFilter?: boolean;
    filters?: {
        filters: {
            label: string, items: Filter[], type: string, code: string, value?: any
        }[],
        filterFunction: (query: Parse.Query<Parse.Object>, model: string, filtersActive: {
            label: string, items: Filter[], type: string, code: string
        }[]) => Parse.Query<Parse.Object>
    };
    staticFilters?: {
        relationObjectName: string,
        relationColumnName: string,
        value: any,
        otherColumnName: string
    };
}

export interface Filter {
    isActive?: boolean;
    label: string;
    value?: any;
    type?: string;
}
