import {Component, Input, OnInit, Output} from '@angular/core';
import {Subject} from "rxjs";

@Component({
    selector: 'app-button-multiple-click',
    templateUrl: './button-multiple-click.component.html',
    styleUrls: ['./button-multiple-click.component.scss']
})
export class ButtonMultipleClickComponent implements OnInit {
    @Input() color: 'primary' | 'accent' | 'warn' | undefined;
    @Input() traductionAfterNumberClick: string = 'confermDelete';
    @Input() tresholdClickButton: number = 3;
    @Output() clickComplete = new Subject<any>();
    numberClick = 0;

    ngOnInit() {

    }

    clickButton() {
        this.numberClick++
        if (this.numberClick > this.tresholdClickButton) {
            this.numberClick = 0
            this.clickComplete.next()
        }
    }
}
