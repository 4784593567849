import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {UntypedFormBuilder, Validators} from "@angular/forms";

@Component({
    selector: 'app-dialog-export',
    templateUrl: './dialog-export.component.html',
    styleUrls: ['./dialog-export.component.scss']
})
export class DialogExportComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<DialogExportComponent>,
                @Inject(MAT_DIALOG_DATA) public data: { email },
                private fb: UntypedFormBuilder) {
        this.formExport = this.fb.group({
            email: [{value:data.email,disabled:true}, [Validators.required, Validators.email]],
            fotoCircuiti: {value:false,disabled:true},
            fileCircuiti: true,
            fotoPuntiLuce: {value:false,disabled:true},
            filePuntiLuce: true,
        });

    }

    public formExport;

    ngOnInit(): void {
    }

}
