import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'convertSecondInDayHourMinutes'
})
export class ConvertSecondInDayHourMinutesPipe implements PipeTransform {

    transform(seconds: number, ...args: unknown[]): unknown {

        const d = Math.floor(seconds / (3600 * 24));
        const h = Math.floor(seconds % (3600 * 24) / 3600);
        // const m = Math.floor(seconds % 3600 / 60);
        // const s = Math.floor(seconds % 60);

        if (d == 0 && h == 0) {
            return '0'
        } else {
            let time = ''
            if (d > 0) {
                time += d + ' g ';
            }
            if (h > 0) {
                time += h + ' h ';
            }
            // if (m > 0) {
            // }
            // if (s > 0) {
            // }
            return time;
        }

    }

}
