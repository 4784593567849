import {Injectable} from '@angular/core';
import * as Parse from 'parse';
import {ProgettiParse} from '../../models/Progetti.Parse';
import {fromPromise} from 'rxjs/internal-compatibility';
import {BehaviorSubject, EMPTY, forkJoin, Observable, of} from 'rxjs';
import {OrganizzazioneParse} from '../../models/Organizzazione.Parse';
import {catchError, map} from 'rxjs/operators';
import {arrayIsSet, isNotNullOrUndefined, paramsApiParse_v2, stringIsSet} from "../../models/Models";
import {LocalSotrageService} from "./local-sotrage.service";
import {UserRole} from "../../../config/static-data";


@Injectable({
    providedIn: 'root'
})
export class UserService {

    usersDetails: any[];
    private userProjectsEmit = new BehaviorSubject<ProgettiParse[]>(undefined);
    userProjects$: Observable<ProgettiParse[]>;


    constructor(private localStorage: LocalSotrageService) {

        this.getRoleUsers()
            .subscribe((roles) => {
                if (arrayIsSet(roles)) {
                    this.role = roles;
                } else {
                    this.role = [];
                }
            })
        this.userProjects$ = this.userProjectsEmit.asObservable();

        this.updateUserProject();
    }

    updateUserProject() {
        this.getUserProjects().subscribe(
            projects => {
                if (arrayIsSet(projects)) {
                    this.userProjects = projects
                } else {
                    this.userProjects = undefined;
                }
            }
        )
    }

    get userProjects(): ProgettiParse[] | undefined {
        return this.userProjectsEmit.value;
    }

    set userProjects(projects: ProgettiParse[]) {
        this.userProjectsEmit.next(projects);
    }

    public getRoleUsers(): Observable<string[]> {
        const isSystemDeveloper$ = fromPromise(Parse.Cloud.run('isSystemDeveloper'));
        const isSystemAdmin$ = fromPromise(Parse.Cloud.run('isSystemAdmin'));
        return forkJoin([
            isSystemAdmin$.pipe(
                catchError((error) => {
                    console.error(error)
                    return of(false);
                })
            )
            ,
            isSystemDeveloper$.pipe(
                catchError((error) => {
                    console.error(error)
                    return of(false);
                })
            )
        ]).pipe(
            map(rolesBoolean => {
                let roles = [];
                if (rolesBoolean[0] == true) {
                    roles.push(UserRole.Sviluppatore);
                }
                if (rolesBoolean[1] == true) {
                    roles.push(UserRole.AMMINISTRATORE)
                }
                return arrayIsSet(roles) ? roles : undefined
            })
        )
    }

    private _role: String[] = [];

    public get role(): String [] {
        return this._role;
    }

    public set role(value) {
        this._role = value;
    }


    public isLogged(): boolean {
        try {
            const sessionToken = this.sessionToken();
            if (sessionToken != null && sessionToken.length > 0) {
                return true;
            }
            return false;
        } catch (e) {
            return e;
        }
    }


    invalidSessionTokenTest_v2() {
        return this.fetchUser$().pipe(
            map(user => {
                return {user, logout: false};
            }),
            catchError(errorLogIn => {
                return fromPromise(Parse.User.logOut()).pipe(
                    map(() => ({user: undefined, logout: true, errorLogIn})),
                    catchError(errorLogOut => {
                        return of({user: undefined, logout: true, errorLogIn, errorLogOut})
                    })
                )
            })
        )
    }

    fetchUser$() {
        if (Parse.User.current() != null) {
            const fetchUser = Parse.Cloud.run('fetchUser')
            return fromPromise(fetchUser);
        } else {
            return of(undefined);
        }
    }

    public async user() {
        try {
            return await Parse.User.current().toJSON();
        } catch (e) {
            return e;
        }
    }

    public get currentUser(): Parse.User {
        try {
            return Parse.User.current();
        } catch (e) {
        }
    }

    public get isSviluppatore() {
        return this.role.includes('Sviluppatore');
    }

    public get abbonamentoAlmenoLivello5() {
        return Parse.User.current().get('livelloAbbonamento') >= 5;
    }

    public get abbonamentoAlmenoLivello0() {
        return Parse.User.current().get('livelloAbbonamento') >= 0;
    }

    public abbonamentoAlmenoLivello(value: number) {
        const today = new Date();
        const abbonamentoUtenteAttivo = Parse.User.current().get('subscriptionExpirationDate') && Parse.User.current().get('subscriptionExpirationDate').getTime() >= today.getTime()
        const levelAbbonamentoCheck = Parse.User.current().get('livelloAbbonamento') >= value
        return Parse.User && Parse.User.current() && abbonamentoUtenteAttivo && levelAbbonamentoCheck;
    }


    public getProject() {
        try {
            const projectId = this.localStorage.projectId;
            if (stringIsSet(projectId)) {
                const project = new ProgettiParse();
                project.objectId = projectId;
                return project;
            } else {
                return undefined;
            }
        } catch (e) {
            return e;
        }
    }

    public async getUser() {
        try {
            const Project = Parse.Object.extend('User');
            const query = new Parse.Query(Project);
            const res = await query.equalTo('objectId', Parse.User.current().id).find({sessionToken: this.sessionToken()});
            const objUser = {...res[0].attributes};
            objUser['objectId'] = res[0].id;
            return objUser;
        } catch (e) {
            return e;
        }
    }

    public getUserProjects(): Observable<ProgettiParse[]> {
        const params = paramsApiParse_v2();
        const res = Parse.Cloud.run('getProgettiList',
            params, {});
        return (fromPromise(res));
    }


    public async updateUserAccount(name, surname, newsletter, is2FAEnabled) {
        try {
            const user = Parse.User.current();
            user.set('nome', name);
            user.set('cognome', surname);
            user.set('notificheEmailAttive', newsletter);
            if (is2FAEnabled) {
                user.set('is2FAEnabled', is2FAEnabled);
            }
            user.save(null, {sessionToken: this.sessionToken()});
        } catch (e) {
            return e;
        }
    }

    public getUserDetail(ids: string[]): Observable<any[]> {
        const res = Parse.Cloud.run('getUsers',
            {
                objectsIds: ids
            }, {});
        return (fromPromise(res));

    }

    public sessionToken() {
        let sessionToken = localStorage.getItem('parseSessionToken');
        if (sessionToken == null && Parse.User.current() != null) {
            sessionToken = Parse.User.current().getSessionToken();
        }
        return sessionToken;
    }

    public getOrganizzazione(): Observable<OrganizzazioneParse> {
        const organizzazione = Parse.User.current().get('organizzazione');
        if (organizzazione != null) {
            return fromPromise(organizzazione.fetch()).pipe(
                map((organizzazione) => organizzazione['attributes'])
            );
        } else {
            return of(undefined)
        }
    }

    newUser(itemsWithValue: { key, value }[]): Parse.User {
        const user = new Parse.User();
        itemsWithValue.forEach(item => {
            user.set(item.key, item.value)
        })
        return user;
    }

    getUserDetails$(user: Parse.User) {
        const userFinded = arrayIsSet(this.usersDetails) ? this.usersDetails.find(u => u.id == user.id) : undefined;
        if (userFinded != null) {
            return of(userFinded)
        } else {
            return this.getUserDetail([user.id]).pipe(
                map(newUser => {
                    if (arrayIsSet(this.usersDetails)) {
                        const index = this.usersDetails.find(userFind => userFind.id === newUser[0].id)
                        if (index < 0) {
                            this.usersDetails.push(newUser[0]);
                        }
                    } else {
                        this.usersDetails = newUser;
                    }
                    return newUser[0];
                })
            )
        }
    }

    addNewProject(project: ProgettiParse) {
        const currentProjects = arrayIsSet(this.userProjects) ? [...this.userProjects] : [];
        currentProjects.push(project);
        this.userProjects = currentProjects;
    }

    removeProject(project: ProgettiParse) {
        const currentProjects = arrayIsSet(this.userProjects) ? [...this.userProjects] : [];
        const index = currentProjects.findIndex(p => p.objectId === project.objectId);
        if (index >= 0) {
            currentProjects.splice(index, 1);
        }
        this.userProjects = currentProjects;
    }

}
