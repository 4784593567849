<div mat-dialog-content>
    <mat-card>
        <mat-card-title>{{'info_create_plan' | translate}}</mat-card-title>
        <mat-card-content>
            <div class="spacer">
                {{'info_create_plan_2' | translate}}
            </div>
            <ul>
                <li>{{'info_logo' | translate}}</li>
                <li>{{'info_logo_2' | translate}}</li>
                <li>{{'info_title' | translate}}</li>
                <li>{{'info_map' | translate}}</li>
                <li>{{'info_legend' | translate}}</li>
            </ul>
            <div class="spacer">
                {{'info_create_plan_3' | translate}} <br>
                {{'info_create_plan_template' | translate}}
            </div>
            <div class="spacer">
                {{'info_create_plan_4' | translate}}
            </div>
        </mat-card-content>
    </mat-card>
<!--    <div>-->
<!--        <img src="assets/map_info.png" width="100%">-->
<!--    </div>-->
    <div style="margin-top: 0.5rem;">
<!--        <p class="roboto">{{'info_template_1' | translate}}</p>-->
        <img src="assets/map_info_temp1.png" width="100%">
    </div>
    <div>
<!--        <p class="roboto">{{'info_template_2' | translate}}</p>-->
        <img src="assets/map_info_temp2.png" width="100%">
    </div>
</div>
