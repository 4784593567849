<form [formGroup]="formSettings">
    <mat-tab-group animationDuration="0ms" #tabGroup>
        <mat-tab label="{{'scheduleMaintenance.maintenanceModality' |translate}}"
                 *ngIf="abbonamenti.gestioneAttivo || !abbonamentoIsUpdate">
            <ng-container formGroupName="scheduleMaintenance">
                <div class="flex-center-width100 flex-flow-column" style="margin-top: 8px;">
                    <mat-checkbox
                            formControlName="active">{{'scheduleMaintenance.active'|translate}}</mat-checkbox>
                    <app-maintenance-modality
                            [defaultValue]="defaultValue.maintenanceComponent"
                            [clusterVisualizzatoSullaMappa]="clusterVisualizzatoSullaMappa"
                            [calendarioManutenzioni]="calendarioManutenzioni"
                            [calendarioManutenzioniNonEffetuate]="calendarioManutenzioniNonEffetuate"
                            (clickCalendario)="emitCalendario($event)"
                            [disabled]="!isActive('scheduleMaintenance')"
                            [disabledKey]="loading"
                            (changeValues)="maintenanceScheduleChangeValue($event)">
                    </app-maintenance-modality>
                </div>
            </ng-container>
        </mat-tab>
        <mat-tab label="{{'PuntiLuce' |translate}}">
            <div *ngIf="sidenavIsOpen">
                <mat-tab-group animationDuration="0ms">
                    <mat-tab label="{{'scaleColor' |translate}}">
                        <ng-container formGroupName="filterForm">
                            <div class="flexContainer" style="padding: 0 8px; box-sizing: border-box;">
                                <app-auto-complete-form
                                        appearance="outline"
                                        style="width: 100%; margin-bottom: -1.34375em;"
                                        formControlName="scalaColore"
                                        [hiddenClearButton]="false"
                                        [title]="'titleSetting'|translate"
                                        [items]="puntiLuceAutocompleteFields">

                                </app-auto-complete-form>
                                <div *ngIf="abbonamenti.telecontrolloAttivo">
                                    <button (click)="setLampadaFunzionante()"
                                            style="margin-left: 8px;"
                                            color="accent" mat-flat-button
                                            matTooltip="{{'dashboard_sidenav.PuntiLuce.lampadaFunzionante.title' | translate}}"
                                            type="button">
                                        <img src="/assets/icon/hamburgerNavbar/icon-telecontrollo-white.png"
                                             style="max-height: 24px">
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="isSetElementiScalaArray">
                            <div style="display:flex;align-items: center; justify-content: center;">
                                <table mat-table [dataSource]="elementiScalaArray" class="mat-elevation-z4"
                                       style="margin: 20px 0;">
                                    <ng-container matColumnDef="color">
                                        <th mat-header-cell *matHeaderCellDef>{{'colorSettings'|translate}}</th>
                                        <td mat-cell *matCellDef="let element">
                                            <div class="elementoLeggenda"
                                                 [ngStyle]="{'background-color':element.color}">
                                            </div>
                                        </td>
                                    </ng-container>
                                    <!-- Name Column -->
                                    <ng-container matColumnDef="key">
                                        <th mat-header-cell *matHeaderCellDef>{{'rappresentedElement'|translate}}</th>
                                        <td mat-cell *matCellDef="let element">
                                            <ng-container
                                                    *ngIf="element.key!='Altro'&& element.key!='undefined'&& element.key!='Non_specificato'&& element.key!='Non specificato';else altroNonSpecificato">
                                                <ng-container [ngSwitch]="tipoDato">
                                                    <ng-container *ngSwitchCase="'lampadaFunzionante'">
                                                        {{('lampadaFunzionante.' + element.key)|translate|sostituisciUndercoreConSpazi}}
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="'text'">
                                                        {{element.key|translate|sostituisciUndercoreConSpazi}}
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="'bool'">
                                                        {{element.key|translate|sostituisciUndercoreConSpazi}}
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="'targa'">
                                                        {{element.key|translate|sostituisciUndercoreConSpazi}}
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="'elenco'">
                                                        {{pathForTranslate + campoScalaColore + '.possibleValues.' + (element.key|transformForTranslate)|translate}}
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="'pointer_circuiti'">
                                                        {{element.key}}
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="'pointer_strade'">
                                                        {{element.key|sostituisciUndercoreConSpazi}}
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="'pointer_puntoluce'">
                                                        {{element.key}}
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="'date'">
                                                        {{element.key|customDate :'longDate'|async}}
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="'number'">
                                                        {{element.key}}
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="'int'">
                                                        {{element.key}}
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        {{element.key}}
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                            <ng-template #altroNonSpecificato>
                                                {{element.key|translate|sostituisciUndercoreConSpazi}}
                                            </ng-template>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                </table>
                            </div>
                        </ng-container>
                    </mat-tab>
                    <mat-tab label="{{'labels' |translate}}">
                        <div formGroupName="labelForm" style="padding: 8px; box-sizing: border-box;">
                            <app-auto-complete-form
                                    appearance="outline"
                                    style="width: 100%;"
                                    formControlName="label"
                                    [hiddenClearButton]="false"
                                    [title]="'titleSetting'|translate"
                                    [items]="puntiLuceAutocompleteFields">
                            </app-auto-complete-form>
                        </div>
                        <ng-container *ngIf="!!numeroElementiPerValore">
                            <ng-container>
                                <app-huna-card>
                                    <mat-grid-list cols="24" [rowHeight]="rowHeight">
                                        <mat-grid-tile [colspan]="19"
                                                       [rowspan]="1">
                                            <b>{{'value'|translate}}</b>
                                        </mat-grid-tile>
                                        <mat-grid-tile [colspan]="5"
                                                       [rowspan]="1">
                                            <b style="text-align: center;width: 100%">{{'number'|translate}}</b>
                                        </mat-grid-tile>
                                        <ng-container *ngFor="let key of numeroElementiPerValore |keyvalue">
                                            <mat-grid-tile [colspan]="19"
                                                           [rowspan]="1">
                                                <p style="font-size: 14px">{{key.key|sostituisciUndercoreConSpazi}}</p>
                                            </mat-grid-tile>
                                            <mat-grid-tile [colspan]="5"
                                                           [rowspan]="1">
                                                <p style="font-size: 14px;text-align: center;width: 100%">{{key.value}}</p>
                                            </mat-grid-tile>
                                        </ng-container>
                                    </mat-grid-list>
                                </app-huna-card>
                            </ng-container>
                        </ng-container>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </mat-tab>
        <mat-tab label="{{'Circuiti' |translate}}">
            <ng-template matTabContent>
                <div class="example-container" *ngIf="sidenavIsOpen">
                    <mat-tab-group animationDuration="0ms" class="huna-material-class"
                                   [(selectedIndex)]="circuitoSelectedTab">
                        <mat-tab label="{{'scaleColor' |translate}}">
                            <app-circuit-scale-color [circuiti]="circuiti$|async"
                                                     (campoScalaCaloreCircuitoCambiato)="cambiaScalaColoreCircuito($event)">
                            </app-circuit-scale-color>
                        </mat-tab>
                        <mat-tab label="{{'labels' |translate}}">
                            <app-circuit-label-marker [circuiti]="circuiti$|async"
                                                      (fieldLabelCircuitoChange)="fieldLabelCircuitoChange.next($event)">

                            </app-circuit-label-marker>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </ng-template>
        </mat-tab>
        <mat-tab label="{{'LineaElettrica' |translate}}">
            <app-scale-color-and-label
                    (changeScaleColor)="emitChangeScaleColor($event)"
                    [keyScaleColorSet]="keyScaleColorSetElecttricLine"
                    [scaleColor]="scaleColorSetElecttricLine"
            >
            </app-scale-color-and-label>
        </mat-tab>
        <mat-tab label="{{'illuminamentoAterra.ambientalData' |translate}}" *ngIf="abbonamenti.illuminamentoAttivo">
            <app-illuminamento-terra (monthYearImpostato)="currentMonthYearChange($event)"
                                     (activeIlluminamentoATerra)="activeIlluminamentoATerra.next($event)"
                                     [resetAllFilter]="resetAllFilter"
            >
            </app-illuminamento-terra>
        </mat-tab>
    </mat-tab-group>
</form>

