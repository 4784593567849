import * as Parse from 'parse';


export class DocumentsFolderParse extends Parse.Object {
    public static CLASS_NAME = 'DocumentsFolder';

    constructor() {
        super(DocumentsFolderParse.CLASS_NAME);
    }

    public get extension() {
        return 'folder'
    }

    public set objectId(value) {
        this.id = value;
    }

    public get objectId() {
        return this.id;
    }

    public set updatedAt(value) {
        super.set('updatedAt', value);
    }

    public get updatedAt() {
        return super.get('updatedAt');
    }

    public set createdAt(value) {
        super.set('createdAt', value);
    }

    public get createdAt() {
        return super.get('createdAt');
    }

    public set ACL(value) {
        super.set('ACL', value);
    }

    public get ACL() {
        return super.get('ACL');
    }

    public set name(value) {
        super.set('name', value);
    }

    public get name() {
        return super.get('name');
    }

    public set parentFolder(value) {
        super.set('parentFolder', value);
    }

    public get parentFolder() {
        return super.get('parentFolder');
    }

    public set progetto(value) {
        super.set('progetto', value);
    }

    public get progetto() {
        return super.get('progetto');
    }

    public get query() {
        return new Parse.Query(DocumentsFolderParse)
    }


}

Parse.Object.registerSubclass(DocumentsFolderParse.CLASS_NAME, DocumentsFolderParse);
