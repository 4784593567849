import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {take} from "rxjs/operators";
import {arrayIsSet} from "../../models/Models";

@Injectable({
    providedIn: 'root'
})
export class CopyPasteService {
    private valueEmit = new BehaviorSubject(undefined)
    value$: Observable<any> = this.valueEmit.asObservable();

    set value(value: any) {
        this.value$.pipe(
            take(1)
        ).subscribe(
            valueStored => {
                let newValues;
                if (arrayIsSet(valueStored)) {
                    const index = valueStored.findIndex(v => v.key === value.key)
                    if (index >= 0) {
                        valueStored[index] = value;
                    } else {
                        valueStored.push(value)
                    }
                    newValues = [...valueStored];
                } else {
                    newValues = [value]
                }
                this.valueEmit.next(newValues)
            }
        )
    }

    constructor() {
    }

}
