import {
    Component,
    EventEmitter, Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {
    formFiledMaintenance,
    formFiledMaintenanceFormContolName,
    typeFormFieldMaintenance
} from "../../../providers/services/maintenance.service";
import {
    arrayIsSet, customThemeNgxDataPicker,
    getArrayToRemveItem,
    getItemInArrayByKeyValue,
    hunaParseFileName, isNotNullOrUndefined,
    stringIsSet
} from "../../../models/Models";
import {dataForm} from "../../confirm-delete/select-or-create/select-or-create.component";
import {ImageService} from "../../../providers/services/image.service";
import {AlertService} from "../../../providers/services/alert.service";
import {TranslateService} from "@ngx-translate/core";
import {Observable, Subscription} from "rxjs";
import {debounceTime} from "rxjs/operators";
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {IconService} from "../../../providers/services/icon.service";
import { DialogPopUpService } from 'src/app/providers/services/dialog-pop-up.service';

@Component({
    selector: 'app-form-to-compile-schedule-maintenance',
    templateUrl: './form-to-compile-schedule-maintenance.component.html',
    styleUrls: ['./form-to-compile-schedule-maintenance.component.scss']
})
export class FormToCompileScheduleMaintenanceComponent implements OnInit, OnChanges, OnDestroy {

    @Input() savedCompleted: Observable<any>;
    @Input() typeNotVisualized: any[];
    @Input() setValidator = true;
    @Input() visualizedUnsetButton = false;

    @Output() emitValue = new EventEmitter<any>();
    @Output() savedValue = new EventEmitter();


    get schedaSelezionata() {
        if (this.data.schedaSelezionata != null) {
            return this.data.schedaSelezionata;
        }
    }

    get valuesForm() {
        return this.data.valuesForm;
    }


    get isOpenByDialog() {
        return this.data != null;
    }

    subscriptionSavedCompleted: Subscription;
    subscriptionForm: Subscription;

    expandedDetail = {};

    public loading = {
        saveScheda: false,
    };
    public forms: UntypedFormGroup = this.fb.group({});
    public formFieldSchedaSelezionata: formFiledMaintenanceFormContolName[] = [];
    typeFormField = typeFormFieldMaintenance
    files = {};
    public customThemNgxDataPicker = customThemeNgxDataPicker;
    public nameIconCancel: string;
    private formDirty = false;

    constructor(
        public dialogRef: MatDialogRef<FormToCompileScheduleMaintenanceComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: any,
        private fb: UntypedFormBuilder,
        private imageService: ImageService,
        private alertService: AlertService,
        public translate: TranslateService,
        private iconService: IconService,
        private dialogPopupService: DialogPopUpService,
    ) {
        this.nameIconCancel = this.iconService.addSvgIcon('doubleColor', 'assets/icon/buttonUtilities/cancelDoubleColor.svg')
        if (this.schedaSelezionata != null) {
            this.createOrUpdateForm(this.valuesForm);
        }
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        if (this.subscriptionForm != null) {
            this.subscriptionForm.unsubscribe();
        }
        if (this.subscriptionSavedCompleted != null) {
            this.subscriptionSavedCompleted.unsubscribe();
        }
    }


    setExpandMoreLessNote(field: formFiledMaintenanceFormContolName) {
        if (this.expandedDetail[field.formControlName]) {
            this.expandedDetail[field.formControlName] = false;
        } else {
            this.expandedDetail[field.formControlName] = true;
        }
    }

    getExpandMoreLessNote(field: formFiledMaintenanceFormContolName) {
        return this.expandedDetail[field.formControlName];
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.subscriptionSavedCompleted && this.savedCompleted) {
            this.subscriptionSavedCompleted = this.savedCompleted.subscribe(
                value => {
                    if (value.schedaManutenzione) {
                        if (!value.schedaManutenzione.error) {
                            this.forms.reset();
                            this.files = {};
                        }
                        this.loading.saveScheda = false;
                    }
                }
            )
        }
        if (isNotNullOrUndefined(changes._schedaSelezionata)) {
            this.createOrUpdateForm(this.valuesForm);
        }
    }


    createOrUpdateForm(defaultValues: any[] | undefined) {
        const getDefaultValue = (keyForm, defaultValues: any[] | undefined) => {
            if (arrayIsSet(defaultValues)) {
                const index = defaultValues.findIndex(value => value.traduction === keyForm.traduction);
                if (index >= 0) {
                    if (keyForm.type === typeFormFieldMaintenance.image) {
                        const images = arrayIsSet(defaultValues[index].value) ? defaultValues[index].value : defaultValues[index].value.file;
                        const getObjValue = (image) => {
                            if (image != null) {
                                if (stringIsSet(image.image)) {
                                    return image
                                }
                                if (stringIsSet(image.url)) {
                                    return {url: image.url, objectId: image.objectId, className: image.className}
                                }
                                if (image.file != null && stringIsSet(image.file.url())) {
                                    return {url: image.file.url(), objectId: image.objectId, className: image.className}
                                }
                            }
                        }
                        if (arrayIsSet(images)) {
                            const urls = images
                                .filter(image => getObjValue(image) != null)
                                .map(image => {
                                    return getObjValue(image)
                                });
                            return {file: urls}
                        }
                    } else {
                        return defaultValues[index].value;
                    }
                }
            }
            return null;
            // if (keyForm.type === typeFormFieldMaintenance.checkBox) {
            //     return false;
            // } else {
            //     return null;
            // }
        };
        this.formFieldSchedaSelezionata = undefined;
        if (this.subscriptionForm != null) {
            this.subscriptionForm.unsubscribe();
        }
        // viene utilizzato aggiungi e rimuovi campi dal form, perchè atrimenti poteva causare dei warning nell html
        let oldKeysToRemoveAfterUpdate = Object.keys(this.forms.value);
        const formFieldSchedaSelezionata = this.schedaSelezionata.form
            .filter(keyForm => !(arrayIsSet(this.typeNotVisualized) && this.typeNotVisualized.includes(keyForm.type)))
            .map(
                (keyForm, index) => {
                    const key = this.getKey(keyForm.traduction, index);
                    const defaultValue = getDefaultValue(keyForm, defaultValues);
                    const validators = [];
                    if (this.setValidator) {
                        if (keyForm.type === typeFormFieldMaintenance.checkBox) {
                            validators.push(Validators.requiredTrue)
                        } else if (keyForm.required) {
                            validators.push(Validators.required)
                        }
                    }

                    let possibleValuesWithKey;
                    if (keyForm.type === this.typeFormField.autoComplete || keyForm.type == this.typeFormField.multiSelect) {
                        possibleValuesWithKey = this.getAutoComplete(keyForm.possibleValues)
                    }
                    if (isNotNullOrUndefined(this.forms) && isNotNullOrUndefined(this.forms.get(key))) {
                        if (arrayIsSet(validators)) {
                            this.forms.get(key).setValidators(validators)
                        } else {
                            this.forms.get(key).clearValidators()
                        }
                        this.forms.get(key).reset();
                        this.forms.get(key).setValue(defaultValue);

                        oldKeysToRemoveAfterUpdate = getArrayToRemveItem(oldKeysToRemoveAfterUpdate, key)
                    } else {
                        this.forms.addControl(key, this.fb.control(null, validators));
                        this.forms.get(key).setValue(defaultValue);
                    }
                    const allObj = {...keyForm, formControlName: key,}
                    if (possibleValuesWithKey) {
                        allObj['possibleValuesWithKey'] = possibleValuesWithKey
                    }
                    return allObj
                }
            )
        oldKeysToRemoveAfterUpdate.forEach(key => {
            if (isNotNullOrUndefined(this.forms) && isNotNullOrUndefined(this.forms.get(key))) {
                this.forms.removeControl(key);
            }
        })
        formFieldSchedaSelezionata.sort((fieldA, fieldB) => {
            const sortA = isNotNullOrUndefined(fieldA.sortingNumber) ? fieldA.sortingNumber : 0;
            const sortB = isNotNullOrUndefined(fieldB.sortingNumber) ? fieldB.sortingNumber : 0;
            return sortA - sortB;
        });

        this.formFieldSchedaSelezionata = formFieldSchedaSelezionata;
        this.initEmitLiveValue();
    }

    initEmitLiveValue() {
        if (this.subscriptionForm != null) {
            this.subscriptionForm.unsubscribe();
        }
        this.subscriptionForm = this.forms.valueChanges
            .pipe(
                debounceTime(500)
            )
            .subscribe(value => {
                this.emitValue.emit(
                    {
                        maintenanceScheduleReference: this.schedaSelezionata,
                        valuesSchedaManutenzione: this.getValueForm(),
                        keys: this.formFieldSchedaSelezionata
                    }
                )
            })
    }

    getAutoComplete(values: string[]): dataForm[] {
        return values.map((value, index) => {
            return {html: value, valueForm: this.getKey(value, index)}
        })
    }

    getKey(value: string, index: number) {
        if (stringIsSet(value)) {
            return value
                .trim()
                .replace(/ /g, '')
                .replace(/-/g, '')
                .replace(/_/g, '')
                .replace(/\./g, '')
                .toLowerCase() + index
        }
    }

    getFieldName(field: formFiledMaintenance) {
        let name = field.traduction;
        if (field.required) {
            name += ' *';
        }
        return name
    }

    getFieldNote(field: formFiledMaintenance) {
        // return 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.';
        return field.note;
    }


    loadImage = {};

    onLoadImage(files: File[], label) {
        if (files.length == 0) {
            this.alertService.error(this.translate.instant('load_correct_file'));
        } else {
            this.loadImage[label] = true;

            this.imageService.getImagesByFiles(files).subscribe(images => {
                    const previousFiles = this.forms.get(label).value?.file ?? [];
                    this.forms.get(label).setValue({file: [...previousFiles, ...images], url: undefined})
                    this.loadImage[label] = false;
                    this.formDirty = true;
                },
                error => {
                    this.alertService.error(error)
                    this.loadImage[label] = false;
                })
        }
    }

    onRemoveFile(label, file) {
        if (arrayIsSet(this.forms.get(label).value.file)) {
            const index = this.forms.get(label).value.file.findIndex(value => value.image == file)
            if (index >= 0) {
                this.forms.get(label).value.file.splice(index, 1)
                this.formDirty = true;
            }
        }

    }


    getColorYesOrNotButton(key, valueSet: boolean): 'primary' | 'basic' {
        return this.forms.get(key).value == valueSet ? 'primary' : 'basic';
    }

    setValueYesOrNotButton(key, valueSet: boolean) {
        this.formDirty = true;
        return this.forms.get(key).setValue(valueSet);
    }

    unsetField(key) {
        this.formDirty = true;
        return this.forms.get(key).reset();
    }


    getValueForm() {
        const valueForm = {...this.forms.value}
        const keys = this.formFieldSchedaSelezionata;
        Object.keys(valueForm).forEach(key => {
            const fieldForm = getItemInArrayByKeyValue(keys, key, 'formControlName');
            const value = valueForm[key];
            if (isNotNullOrUndefined(fieldForm.possibleValuesWithKey)) {
                const possibleValue = getItemInArrayByKeyValue(fieldForm.possibleValuesWithKey, value, 'valueForm')
                if (isNotNullOrUndefined(possibleValue) && stringIsSet(possibleValue.html)) {
                    valueForm[key] = possibleValue.html
                }
            }
        });
        return valueForm
    }

    saveAll(saveOnDatabase: boolean) {
        this.loading.saveScheda = true;
        const valueForm = this.getValueForm();
        const keys = this.formFieldSchedaSelezionata;
        Object.keys(valueForm).forEach(key => {
            const fieldForm = getItemInArrayByKeyValue(keys, key, 'formControlName');
            const value = valueForm[key];
            if (isNotNullOrUndefined(fieldForm.possibleValuesWithKey)) {
                const possibleValue = getItemInArrayByKeyValue(fieldForm.possibleValuesWithKey, value, 'valueForm')
                if (isNotNullOrUndefined(possibleValue) && stringIsSet(possibleValue.html)) {
                    valueForm[key] = possibleValue.html
                }
            }
        });

        const objValues = {
            maintenanceScheduleReference: this.schedaSelezionata,
            valuesSchedaManutenzione: valueForm,
            keys: this.formFieldSchedaSelezionata,
            saveOnDatabase
        };
        if (!this.isOpenByDialog) {
            this.savedValue.emit(
                objValues
            )
        } else {
            this.dialogRef.close(objValues)
        }

    }


    isInError(field: formFiledMaintenanceFormContolName, keyError: string) {
        return this.forms.touched && this.forms.get(field.formControlName).hasError(keyError)
    }

    showError(field: formFiledMaintenanceFormContolName, keyError: string) {
        return this.isInError(field, keyError) && !this.getExpandMoreLessNote(field)
    }


    confirmCloseDialog() {
        // formDirty is required because some fields don't get changed directly by the user (e.g. radios and images),
        // thus forms.dirty would be false if only fields of those types have changed
        if (this.forms.dirty || this.formDirty) {
            this.dialogPopupService.openDialogForDelete([this.translate.instant('yourChangesWillBeDiscarded'), this.translate.instant('proceedAnyway')], 'center').subscribe((res) => {
                if (res) {
                    this.dialogRef.close();
                }
            });
        } else {
            // if the user has made no changes to the form, the dialog can be closed without asking because no data will be lost
            this.dialogRef.close();
        }
    }
}
