import {Component, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    UntypedFormBuilder,
    UntypedFormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR, ValidationErrors
} from "@angular/forms";
import {Observable, Subject, Subscription} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {arrayIsSet, isNotNullOrUndefined} from "../../models/Models";
import {debounceTime, filter, map, pairwise} from "rxjs/operators";

@Component({
    selector: 'app-increment-field',
    templateUrl: './increment-field.component.html',
    styleUrls: ['./increment-field.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: IncrementFieldComponent
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: IncrementFieldComponent
        }]
})
export class IncrementFieldComponent implements OnChanges, OnDestroy, ControlValueAccessor {
    private subScriptions = new Subscription();
    public formGroup: UntypedFormGroup;
    @Input() currentValue: number | undefined;
    @Input() min: number | undefined;
    @Input() max: number | undefined;
    @Input() step: number | undefined = 1;
    @Input() title: string | undefined;
    @Input() disabled: boolean | undefined;
    @Output() changeValue = new Subject();
    disableAdd$: Observable<boolean>
    disableRemove$: Observable<boolean>
    public focusOn = false

    constructor(private fb: UntypedFormBuilder,
                public translateService: TranslateService) {
        this.formGroup = this.fb.group({value: undefined});
        const sub = this.formGroup.get('value').valueChanges.pipe(
            pairwise(),
            filter(([prev, current]) => prev != current),
            map(([prev, current]) => current),
            filter(() => !this.focusOn)
        )
            .subscribe(value => this.changeValue.next(value));
        this.subScriptions.add(sub);
        this.disableAdd$ = this.formGroup.get('value').valueChanges.pipe(
            map(value => this.max != null && value >= this.max)
        )
        this.disableRemove$ = this.formGroup.get('value').valueChanges.pipe(
            map(value => this.min != null && value <= this.min)
        )
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.currentValue) {
            setTimeout(() => {
                this.focusOn = true;
                if (this.currentValue != null) {
                    this.formGroup.get('value').setValue(this.currentValue);
                } else {
                    this.formGroup.get('value').reset();
                }
                this.focusOn = false;
            })
        }
        if (changes.max) {
            const currentValue = this.formGroup.get('value').value;
            if (currentValue > this.max) {
                this.formGroup.get('value').setValue(this.max);
            }
        }
        if (changes.min) {
            const currentValue = this.formGroup.get('value').value;
            if (currentValue < this.min) {
                this.formGroup.get('value').setValue(this.min);
            }
        }
        if (changes.disabled) {
            if (this.disabled == true) {
                this.formGroup.disable();
            } else {
                this.formGroup.enable();
            }
        }
    }

    ngOnDestroy() {
        this.subScriptions.unsubscribe();

    }

    onTouched = () => {
    }


    registerOnChange(onChange: any) {
        const sub = this.formGroup.get('value').valueChanges
            .subscribe(onChange);
        this.subScriptions.add(sub);
    }

    registerOnTouched(onTouched) {
        this.onTouched = onTouched;
    }


    setDisabledState(disabled: boolean) {
        if (disabled) {
            this.formGroup.disable();
        } else {
            this.formGroup.enable();
        }
    }


    writeValue(value: any) {
        if (!isNotNullOrUndefined(value)) {
            this.formGroup.reset();
        } else {
            this.formGroup.get('value').setValue(value);
        }
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (!isNotNullOrUndefined(this.formGroup.get('value').validator)) {
            this.formGroup.get('value').setValidators(control.validator);
        }
        if (this.formGroup.get('value').valid) {
            return null;
        } else {
            return this.formGroup.get('value').errors;
        }
    }

    private tenPower() {
        const stepString = this.step.toString();
        if (stepString.includes('.')) {
            const numberDigit = stepString.split('.')[1].length
            return Math.pow(10, numberDigit);
        } else {
            return 1
        }
    }

    private roundValue(value: number) {
        const tenPower = this.tenPower();
        return Math.round(value * tenPower) / tenPower;
    }

    clickSum() {
        if (this.formGroup.get('value').enabled) {
            const currentValue = this.formGroup.get('value').value;
            let value;
            if (this.max != null) {
                if ((currentValue + this.step) < this.max) {
                    const divisible = Math.floor((currentValue * this.tenPower()) / (this.step * this.tenPower())) + 1;
                    value = divisible * this.step;
                } else {
                    value = this.max;
                }
            } else {
                value = currentValue + this.step;
            }
            this.formGroup.get('value').setValue(this.roundValue(value));
        }
    }

    clickRemove() {
        if (this.formGroup.get('value').enabled) {
            const currentValue = this.formGroup.get('value').value;
            let value;
            if (this.min != null) {
                if ((currentValue - this.step) > this.min) {
                    const divisible = Math.floor((currentValue * this.tenPower()) / (this.step * this.tenPower())) - 1;
                    value = divisible * this.step;
                } else {
                    value = this.min;
                }
            } else {
                value = currentValue - this.step;
            }
            this.formGroup.get('value').setValue(this.roundValue(value));
        }
    }

    focusInInput() {
        this.focusOn = true;
    }

    focusOutInput() {
        this.focusOn = false;
        const currentValue = this.formGroup.get('value').value;
        if (currentValue > this.max) {
            this.formGroup.get('value').setValue(this.max)
        } else if (currentValue < this.min) {
            this.formGroup.get('value').setValue(this.min)
        } else {
            this.formGroup.get('value').updateValueAndValidity();
            this.changeValue.next(this.formGroup.get('value').value);
        }
    }
}
