import {Injectable} from '@angular/core';
import {isNotNullOrUndefined} from "../../models/Models";

@Injectable({
    providedIn: 'root'
})
export class ChipsFromArrayService {

    constructor() {

    }


    castValue(value) {
        return value.toString()
            .replace(/_/g, ' ')
            .replace(/-/g, ' ')
            .replace(/\./g, '')
            .trim()
            .toLowerCase();
    }

    isEquals(value1, value2) {
        if (!isNotNullOrUndefined(value1) ||
            !isNotNullOrUndefined(value2) ||
            !(typeof value1 === "string" || typeof value1 === "number") ||
            !(typeof value2 === "string" || typeof value2 === "number")) {
            return undefined
        } else {
            const castValue1 = this.castValue(value1);
            const castValue2 = this.castValue(value2);
            return castValue1 === castValue2
        }

    }

    isIncludes(value1, value2) {
        if (!isNotNullOrUndefined(value1) ||
            !isNotNullOrUndefined(value2) ||
            !(typeof value1 === "string" || typeof value1 === "number") ||
            !(typeof value2 === "string" || typeof value2 === "number")) {
            return undefined
        } else {
            const castValue1 = this.castValue(value1)
            const castValue2 = this.castValue(value2);
            return castValue1.includes(castValue2)
        }

    }

    getDistinctValues(arrayValues: any[], labels: string[]) {
        const distinctValue = {};
        labels.forEach(label => {
            distinctValue[label] = [];
        })
        arrayValues.forEach(value => {
            labels.forEach(label => {
                const valueLabel = value[label]
                if (valueLabel != null) {
                    const index = distinctValue[label].findIndex(valueStored => {
                        return this.isEquals(valueLabel, valueStored);
                    })
                    if (index < 0) {
                        distinctValue[label].push(value[label])
                    }
                }

            })
        })
        return distinctValue;
    }
    getDistinctValue<T>(arrayValues: T[], label: string):T[] {
        const distinctValue =[]
        arrayValues.forEach(value => {
                const valueLabel = value[label]
                if (valueLabel != null) {
                    const index = distinctValue.findIndex(valueStored => {
                        return this.isEquals(valueLabel, valueStored);
                    })
                    if (index < 0) {
                        distinctValue.push(value[label])
                    }
                }
        })
        return distinctValue;
    }

}
