import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {ordinamentoEcampiTraduzioni} from '../../../models/ordinamentoEcampiTraduzioni';
import {arrayIsSet, className, isNotNullOrUndefined} from '../../../models/Models';
import {TranslateService} from '@ngx-translate/core';
import {PuntiLuceService} from '../../../providers/services/punti-luce.service';
import {CircuitiService} from '../../../providers/services/circuiti.service';
import {AlertService} from '../../../providers/services/alert.service';
import {FileServiceService} from '../../../providers/services/file-service.service';
import {ExportedFileService} from '../../../providers/services/exported-file.service';
import {delay, switchMap} from "rxjs/operators";
import {PuntiLuceParse} from "../../../models/PuntiLuce.Parse";
import {CircuitiParse} from "../../../models/Circuiti.Parse";
import {forkJoin, Observable, of} from "rxjs";

@Component({
    selector: 'app-reqest-file',
    templateUrl: './reqest-file.component.html',
    styleUrls: ['./reqest-file.component.scss']
})
export class ReqestFileComponent implements OnInit {
    public formExport;
    breakpoint: number;
    public exportLightPoint: PuntiLuceParse[];
    public exportCircuits: CircuitiParse[];

    constructor(private fb: UntypedFormBuilder,
                public translate: TranslateService,
                private puntiLuceService: PuntiLuceService,
                private circuitiService: CircuitiService,
                private alert: AlertService,
                private fileService: FileServiceService,
                private exportFileService: ExportedFileService,
    ) {
        this.formExport = this.fb.group({
            tutto: {value: false, disabled: false},
            FOTO_CIRCUITI: false,
            FOTO_TIPOLOGIE_PUNTI_LUCE: {value: false, disabled: false},
            FOTO_PUNTI_LUCE: false,
            XML_PELL: false,
            XLS_ENEL: false,
            XLS_PROGETTO: false
        });
    }

    public downloadFile = {puntiLuce: false, circuiti: false, dxf: false};


    private sostituisciSpaziconUnderscore(value) {
        return this.fileService.sostituisciSpaziconUnderscore(value)
    }

    private sostituisciVirgoleConSpazi(value) {
        if (typeof value == 'string') {
            return value.replace(/;/g, ' - ');
        } else {
            return value;
        }
    }

    private getFileTypeFromUrl(url) {
        const pathArray = url.split('/');
        if (pathArray == null) {
            return;
        }
        const lastPath = pathArray[pathArray.length - 1];
        if (lastPath.includes('.')) {
            const dotArray = lastPath.split('.');
            return dotArray[dotArray.length - 1];
        }
        return;
    }

    private oggettoConDati(elemento, ordine, varianteKey) {
        const classeNome = elemento.className;
        const oggettoDati = {objectId: elemento.id};
        ordine.forEach((key) => {
            const tipoDato = ordinamentoEcampiTraduzioni[classeNome][key].type.split('_')[0];
            if (tipoDato.toUpperCase() == 'POINTER') {
                const possibelValue = ordinamentoEcampiTraduzioni[classeNome][key]['possibleValues'].split('.')[1];
                const getValuePointer = ordinamentoEcampiTraduzioni[classeNome][key].getValuePointer
                let valoreDaInserire;

                if (getValuePointer != null) {
                    const value = getValuePointer(elemento)
                    if (value != null) {
                        Object.keys(value).forEach(key => {
                            const valoraDaInserire = value[key] != null ? value[key] : '';
                            oggettoDati[key] = valoraDaInserire;
                        });
                    }
                } else {
                    valoreDaInserire = (!!elemento[key]) ? elemento[key].get(possibelValue) : '';
                    oggettoDati[key] = this.sostituisciVirgoleConSpazi(valoreDaInserire);
                }
            } else if (tipoDato.toUpperCase() == 'FILE') {
                let valoreDaInserire = (elemento[key] != null) ? elemento[key].url() || '' : '';
                oggettoDati[key] = this.sostituisciVirgoleConSpazi(valoreDaInserire);
            } else if ((!!ordinamentoEcampiTraduzioni[classeNome][key]['possibleValues'] && Array.isArray(ordinamentoEcampiTraduzioni[classeNome][key]['possibleValues']))
                || tipoDato.toUpperCase() == 'BOOL') {
                let valoreDaInserire = (isNotNullOrUndefined(elemento[key])) ? this.translate.instant('dashboard_sidenav.' + [classeNome] + '.' + key + '.possibleValues.' + this.sostituisciSpaziconUnderscore(elemento[key])) : '';
                valoreDaInserire = (typeof valoreDaInserire == 'string' && valoreDaInserire.includes('dashboard_sidenav.' + [classeNome])) ? this.sostituisciSpaziconUnderscore(elemento[key]) : valoreDaInserire;
                oggettoDati[key] = this.sostituisciVirgoleConSpazi(valoreDaInserire);
            } else if (key.toUpperCase() == 'LOCATION') {
                if (elemento[key] != null && elemento[key].latitude != null && elemento[key].longitude != null) {
                    oggettoDati['latitude'] = elemento[key].latitude;
                    oggettoDati['longitude'] = elemento[key].longitude;
                } else {
                    oggettoDati['latitude'] = '';
                    oggettoDati['longitude'] = '';
                }
            } else if (tipoDato.toUpperCase() == 'TEXT') {
                let valoreDaInserire = (!!elemento[key]) ? '"' + elemento[key] + '"' : '';
                oggettoDati[key] = this.sostituisciVirgoleConSpazi(valoreDaInserire);
            } else if (tipoDato.toUpperCase() == 'TARGA') {
                let valoreDaInserire = (!!elemento[key]) ? '"' + elemento[key] + '"' : '';
                oggettoDati[key] =valoreDaInserire;
            } else {
                let valoreDaInserire = (!!elemento[key]) ? elemento[key] : '';
                oggettoDati[key] = this.sostituisciVirgoleConSpazi(valoreDaInserire);
            }
        });
        varianteKey.forEach((chiave) => {
            let valoreDaInserire;
            // se il tipo di dato della chiave è un pointer allora viene prese il possible values
            const tipoDato = ordinamentoEcampiTraduzioni[classeNome][chiave.key].type.split('_')[0];
            if (tipoDato.toUpperCase() == 'POINTER') {
                const possibelValue = ordinamentoEcampiTraduzioni[classeNome][chiave.key]['possibleValues'].split('.')[1];
                valoreDaInserire = (!!elemento[chiave.varianteKey]) ? elemento[chiave.varianteKey].get(possibelValue) : '';
                oggettoDati['progetto12345' + chiave.varianteKey] = this.sostituisciVirgoleConSpazi(valoreDaInserire);
            } else {
                valoreDaInserire = (!!elemento[chiave.varianteKey]) ? elemento[chiave.varianteKey] : '';
                oggettoDati['progetto12345' + chiave.varianteKey] = valoreDaInserire;
            }
        });
        return oggettoDati;
    }

    private creaFilePuntiLuce(puntiLuce: any[]) {
        let datiDaConvertire = [];
        const keys = (Object.keys(ordinamentoEcampiTraduzioni.PuntiLuce) as any[]).sort((a, b) => {
            return ordinamentoEcampiTraduzioni.PuntiLuce[a].sortingValue - ordinamentoEcampiTraduzioni.PuntiLuce[b].sortingValue;
        });
        let varianteKey = [];
        keys.forEach((key) => {
            if (ordinamentoEcampiTraduzioni.PuntiLuce[key].hasOwnProperty('varianteKey')) {
                varianteKey.push({key: key, varianteKey: ordinamentoEcampiTraduzioni.PuntiLuce[key].varianteKey});
            }
        });
        puntiLuce.forEach((puntoLuce) => {
            datiDaConvertire.push(this.oggettoConDati(puntoLuce, keys, varianteKey));
        });
        try {
            if (datiDaConvertire.length > 0) {
                this.fileService.downloadFile(datiDaConvertire, this.getNameFile(className.puntiLuce) + '.csv', className.puntiLuce);
                const exportmessage: string = this.translate.instant('export.success');
                this.alert.success(exportmessage);
            } else {
                const exportmessage: string = this.translate.instant('export.error');
                this.alert.error(exportmessage);
            }
        } catch (e) {
            this.alert.error(e.message);
        }
    }

    public downloadFileCsvPuntiLuce() {
        this.downloadFile.puntiLuce = true;
        this.getPuntiLuce().subscribe((puntiLuce) => {
            this.exportLightPoint = puntiLuce;
            this.creaFilePuntiLuce(puntiLuce);
            this.downloadFile.puntiLuce = false;
        }, error => {
            this.downloadFile.puntiLuce = false;
            this.downloadFile.circuiti = false;
            this.alert.error(error);
        });
    }


    public getNameFile(classParse: string) {
        // Quadri - aaaa_mm_gg_HH_MM
        const who = (classParse == className.puntiLuce) ? 'lights' : 'circuits';
        const whoTraduction = this.translate.instant(who);

        const now = new Date();
        // const date = formatDate(now, 'shortDate', this.translate.currentLang) + ' ' + formatDate(now, 'shortTime', this.translate.currentLang)
        return whoTraduction + ' - ' + now.getFullYear() + '_' + (now.getMonth() + 1).toString().padStart(2, '0') + '_' + now.getDate().toString().padStart(2, '0') + '_' + now.getHours().toString().padStart(2, '0') + '_' + now.getMinutes().toString().padStart(2, '0');

    }

    public getNameFileMultipleClass(classParse: string[]) {
        // Quadri - aaaa_mm_gg_HH_MM
        let whoTraduction = '';
        classParse.forEach(
            (name, index) => {
                if (index != 0) {
                    whoTraduction += ' - '
                }
                const who = (name == className.puntiLuce) ? 'lights' : 'circuits';
                whoTraduction += this.translate.instant(who);
            }
        )


        const now = new Date();
        // const date = formatDate(now, 'shortDate', this.translate.currentLang) + ' ' + formatDate(now, 'shortTime', this.translate.currentLang)
        return whoTraduction + ' - ' + now.getFullYear() + '_' + (now.getMonth() + 1).toString().padStart(2, '0') + '_' + now.getDate().toString().padStart(2, '0') + '_' + now.getHours().toString().padStart(2, '0') + '_' + now.getMinutes().toString().padStart(2, '0');

    }

    public downloadFileGeojsonPuntiLuce() {
        this.downloadFile.puntiLuce = true;
        this.getPuntiLuce().subscribe((puntiLuce) => {
            this.exportLightPoint = puntiLuce;
            this.fileService.downloadGeojsonElement(puntiLuce, className.puntiLuce, this.getNameFile(className.puntiLuce));
            this.downloadFile.puntiLuce = false;
        }, error => {
            this.downloadFile.puntiLuce = false;
            this.downloadFile.circuiti = false;
            this.alert.error(error);
        });
    }

    public downloadFileKmlPuntiLuce() {
        this.downloadFile.puntiLuce = true;
        this.getPuntiLuce().pipe(
            switchMap(puntiLuce => {
                this.exportLightPoint = puntiLuce;
                return this.fileService.downloadKml(puntiLuce, this.getNameFile(className.puntiLuce), className.puntiLuce)
            })
        ).subscribe((responseDownload) => {
            this.downloadFile.puntiLuce = false;
        }, error => {
            this.downloadFile.puntiLuce = false;
            this.downloadFile.circuiti = false;
            this.alert.error(error);
        });
    }


    public sendRequest() {
        const values = {...this.formExport.value};
        if (isNotNullOrUndefined(values.tutto)) {
            delete values.tutto;
        }
        const lang = this.translate.currentLang
        this.exportFileService.sendRequestOnFileDaEsportare(values, lang).subscribe((element) => {
                this.alert.success(this.translate.instant('requestedSended'));
            },
            (error) => {
                if (error.code != 200) {
                    this.alert.error(error.message);
                } else {
                    this.alert.success(this.translate.instant('requestIsPresent'));
                }
            });

    }

    private creaFileCircuiti(circuiti: any[]) {
        let datiDaConvertire = [];
        const keys = (Object.keys(ordinamentoEcampiTraduzioni.Circuiti) as any[])
            .sort((a, b) => {
                return ordinamentoEcampiTraduzioni.Circuiti[a].sortingValue - ordinamentoEcampiTraduzioni.Circuiti[b].sortingValue;
            });
        let varianteKey = [];
        keys.forEach((key) => {
            if (ordinamentoEcampiTraduzioni.Circuiti[key].hasOwnProperty('varianteKey')) {
                varianteKey.push({key: key, varianteKey: ordinamentoEcampiTraduzioni.Circuiti[key].varianteKey});
            }
        });
        circuiti.forEach((circuito) => {
            datiDaConvertire.push(this.oggettoConDati(circuito, keys, varianteKey));
        });
        const data = new Date();
        try {
            if (datiDaConvertire.length > 0) {
                this.fileService.downloadFile(datiDaConvertire, this.getNameFile(className.circuiti) + '.csv', className.circuiti);
                const exportmessage: string = this.translate.instant('export.success');
                this.alert.success(exportmessage);
            } else {
                const exportmessage: string = this.translate.instant('export.error');
                this.alert.error(exportmessage);
            }
        } catch (e) {
            this.alert.error(e.message);
        }
    }

    public downloadFileCsvCircuiti() {
        this.downloadFile.circuiti = true;
        this.getCircuiti().subscribe((circuiti) => {
            this.exportCircuits = circuiti;
            this.creaFileCircuiti(circuiti);
            this.downloadFile.circuiti = false;
        }, error => {
            this.downloadFile.puntiLuce = false;
            this.downloadFile.circuiti = false;
            this.alert.error(error);
        });
    }

    public downloadFileGeojsonCircuiti() {
        this.downloadFile.circuiti = true;
        this.getCircuiti().subscribe((circuiti) => {
            this.exportCircuits = circuiti;
            this.fileService.downloadGeojsonElement(circuiti, className.circuiti, this.getNameFile(className.circuiti));
            this.downloadFile.circuiti = false;
        }, error => {
            this.downloadFile.puntiLuce = false;
            this.downloadFile.circuiti = false;
            this.alert.error(error);
        });
    }

    public downloadFileKmlCircuiti() {
        this.downloadFile.circuiti = true;
        this.getCircuiti().pipe(
            switchMap(circuiti => {
                this.exportCircuits = circuiti;
                return this.fileService.downloadKml(circuiti, this.getNameFile(className.circuiti), className.circuiti)
            })
        ).subscribe((responseDownload) => {
            this.downloadFile.circuiti = false;
        }, error => {
            this.downloadFile.puntiLuce = false;
            this.downloadFile.circuiti = false;
            this.alert.error(error);
        });
    }


    public downloadFileDxf() {
        this.downloadFile.dxf = true;
        forkJoin([this.getPuntiLuce(), this.getCircuiti()]).subscribe(
            puntiLuceCircuiti => {
                this.exportLightPoint = puntiLuceCircuiti[0];
                this.exportCircuits = puntiLuceCircuiti[1];
                this.fileService.downloadDxfFile(this.exportLightPoint, this.exportCircuits, this.getNameFileMultipleClass([className.puntiLuce, className.circuiti]));
                this.downloadFile.dxf = false;
            }, error => {
                this.alert.error(error)
                this.downloadFile.dxf = false;
            }
        )
    }

    // private sleep(secondi: number): Promise<any> {
    //     const sec = secondi * 1000;
    //     return new Promise(resolve => setTimeout(resolve, sec));
    // }
    //
    // onResize(event) {
    //     if (event.target.innerWidth <= 400) {
    //         this.breakpoint = 1;
    //     } else if (event.target.innerWidth <= 900) {
    //         this.breakpoint = 3;
    //     } else {
    //         this.breakpoint = 6;
    //     }
    // }

    ngOnInit(): void {
        // la creazioe
        if (window.innerWidth <= 400) {
            this.breakpoint = 1;
        } else if (window.innerWidth <= 900) {
            this.breakpoint = 3;
        } else {
            this.breakpoint = 6;
        }
    }


    // servono per poter rendere dinamica la selezione degli elementi da generare
    private isAllTrue(keyFilter, checked): boolean {
        return (Object.keys(this.formExport.value).every((key) => {
            if (key == keyFilter) {
                return checked;
            }
            if (key == 'tutto') {
                return true;
            } else {
                return this.formExport.value[key];
            }
        }));
    }

    public changeAll(event) {
        if (event.checked) {
            Object.keys(this.formExport.value).forEach((key) => {
                this.formExport.get(key).setValue(true);
            });
        }
    }

    public changeSingle(event, key) {
        this.formExport.get('tutto').setValue(this.isAllTrue(key, event.checked));
    }

    public isOneTrue(): boolean {
        return (this.formExport.value.FOTO_CIRCUITI ||
            this.formExport.value.FOTO_PUNTI_LUCE ||
            this.formExport.value.FOTO_TIPOLOGIE_PUNTI_LUCE ||
            this.formExport.value.XML_PELL ||
            this.formExport.value.XLS_ENEL ||
            this.formExport.value.XLS_PROGETTO ||
            this.formExport.value.tutto);

    }


    getCircuiti(): Observable<CircuitiParse[]> {
        if (arrayIsSet(this.exportCircuits)) {
            return of(this.exportCircuits).pipe(delay(200))
        } else {
            return this.circuitiService.getTuttiCircuitiAppartentiAlProgetto();
        }
    }

    getPuntiLuce(): Observable<PuntiLuceParse[]> {
        if (arrayIsSet(this.exportLightPoint)) {
            return of(this.exportLightPoint).pipe(delay(200))
        } else {
            return this.puntiLuceService.getTuttiPuntiLuce();
        }
    }


}
