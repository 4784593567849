<mat-card class="dimension-box huna-material-class">
    <button [disabled]="disabled" class="flex-around-center box-main-card-clickable-header" [ngClass]="[selectDisable]" (click)="singleCardClick()" (dblclick)="dbCardClick()">
        <mat-card-title style="margin-bottom: 0px!important;padding: 10px;font-size: 1.2em">
            <span *ngIf="name">{{name}}</span>
            <div style="position: absolute;right: 0px;top:0;">
                <ng-content select="[badge]">

                </ng-content>
            </div>
        </mat-card-title>    
    </button>
    <div class="w100-10 flex-around-center" *ngIf="visualizedMenu">
        <span style="margin-bottom: 0px!important;font-size: 14px;color: rgba(0,0,0,.54);">
            {{'scheduleMaintenance.updateBeforeImport'|translate}}
        </span>
        <button mat-icon-button [color]="colorIcon" (click)="$event.stopPropagation();updateBeforeImport()"
            [disabled]="disableButton">
            <mat-icon svgIcon="copyEdit" [ngClass]=[colorIconImage]>
            </mat-icon>
        </button>
        <!--        <button mat-icon-button-->
        <!--                (click)="$event.stopPropagation()"-->
        <!--                [matMenuTriggerFor]="menu"-->
        <!--                [disabled]="disableButton">-->
        <!--        </button>-->
    </div>
    <!--    <div class="w100-10 flex-around-center grid-progetti-form" *ngIf="name">-->
    <!--        <div class="text-note-title" [ngClass]="colorText">-->
    <!--            {{'scheduleMaintenance.name'|translate}}-->
    <!--        </div>-->
    <!--        <div class="text-note-text">-->
    <!--            {{name}}-->
    <!--        </div>-->
    <!--    </div>-->

    <mat-card-content>


        <div class="w100-10 flex-around-center grid-progetti-form" *ngIf="periodo">
            <ng-container *ngIf="!showSetDataDiScadenza;else dataDiScadenza">
                <div class="text-note-title" [ngClass]="colorText">
                    {{'scheduleMaintenance.periodo'|translate}}
                </div>
                <div class="text-note-text">
                    {{periodo}} {{'scheduleMaintenance.days'|translate}}
                </div>
            </ng-container>
            <ng-template #dataDiScadenza>
                <ng-container [formGroup]="formGroup">
                    <app-get-form-fiel-by-configuration-element [minDate]="minDate" [type]="typeFormGetFormField.DATE"
                        [titleTraduction]="'scheduleMaintenance.dataDiScadenza'" formControlName="dataDiScadenza">
                    </app-get-form-fiel-by-configuration-element>
                </ng-container>
            </ng-template>
        </div>
        <div class="w100-10 flex-around-center grid-progetti-form" *ngIf="note">
            <div class="text-note-title" [ngClass]="colorText">
                {{'scheduleMaintenance.note'|translate}}
            </div>
            <div class="text-note-text">
                {{note}}
            </div>
        </div>
        <div style="padding: 4px;">
            <mat-accordion>
            <mat-expansion-panel [disabled]="progetti.length<=0" style="max-width: 100%;">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{'scheduleMaintenance.progetti'|translate}}
                    </mat-panel-title>
                    <mat-panel-description style="color: rgba(0, 0, 0, 0.87);">
                        {{progetti.length}}
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <div style="padding: 4px;">
                        <div class="scrollbar-visible" style="max-width: 100%; overflow-x: auto; width: 100%;">
                            <div style="min-width: max-content;">
                                <table mat-table [dataSource]="dataSourceProject" style="min-width: 100%; margin-bottom: 4px;">
                                    <ng-container *ngFor="let column of displayColumnsProject">
                                        <ng-container [matColumnDef]="column">
                                            <th mat-header-cell *matHeaderCellDef style="padding: 0 24px; white-space: nowrap; text-align: center;">
                                                {{column|translate}}</th>
                                            <td mat-cell *matCellDef="let progetto"
                                                style="padding: 0 24px; white-space: nowrap; text-align: center;"> {{getValueHtmlProgetto(progetto,
                                                column)}} </td>
                                        </ng-container>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayColumnsProject"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayColumnsProject;"></tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </mat-expansion-panel>
            <mat-expansion-panel [disabled]="form.length<=0">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{'scheduleMaintenance.form'|translate}}
                    </mat-panel-title>
                    <mat-panel-description style="color: rgba(0, 0, 0, 0.87);">
                        {{form.length}}
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <div class="flex-center-width100 flex-flow-column">
                        <div class="flex-start-center flex-flow-row w100">
                            <div class="dimension-favorites flex-center-width100" *ngFor="let field of form">
                                <app-visulized-type-form class="w100" [field]="field" (click)="$event.stopPropagation()"
                                    style="margin: 5px">

                                </app-visulized-type-form>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </mat-expansion-panel>
            </mat-accordion>
        </div>
    </mat-card-content>
</mat-card>
<!--<mat-menu #menu="matMenu" xPosition="after">-->
<!--    <button mat-menu-item (click)="$event.stopPropagation();updateBeforeImport()"-->
<!--            *ngIf="visualizedMoveInMenu">-->
<!--        <mat-icon color="accent">drive_file_rename_outline</mat-icon>-->
<!--        {{'scheduleMaintenance.modify'|translate}}</button>-->
<!--</mat-menu>-->