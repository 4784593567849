import {Injectable} from '@angular/core';
import {GruppoFotometrieParse} from "../../models/GruppoFotometrie.Parse";
import {fromPromise} from "rxjs/internal-compatibility";
import {FotometriaParse} from "../../models/Fotometria.Parse";
import {ProjectService} from "./project.service";
import {catchError, expand, map, switchMap, toArray} from "rxjs/operators";
import {EMPTY, forkJoin, Observable, of} from "rxjs";
import {ProgettiParse} from "../../models/Progetti.Parse";
import {DialogPopUpInfoService} from "./dialog-pop-up-info.service";
import {DialogPopUpService} from "./dialog-pop-up.service";
import * as Parse from "parse";
import {arrayIsSet, isNotNullOrUndefined} from "../../models/Models";

@Injectable({
    providedIn: 'root'
})
export class GruppoFotometrieService {

    constructor(private projectService: ProjectService,
                private dialogInfo: DialogPopUpInfoService,
                private dialogService: DialogPopUpService,) {
    }


    get currentProject() {
        const progetto = new ProgettiParse()
        progetto.objectId = this.projectService.getProjectLocal();
        return progetto;
    }

    addFotometrieToGroup(gruppoFotometria: GruppoFotometrieParse, addFotometrie: FotometriaParse[], removeFotometrie: FotometriaParse[]) {
        if (isNotNullOrUndefined(gruppoFotometria.objectId)) {
            const relation = gruppoFotometria.relation("fotometrie");
            if (addFotometrie.length > 0) {
                relation.add(addFotometrie);
            }
            if (removeFotometrie.length > 0) {
                relation.remove(removeFotometrie);
            }
            return fromPromise(gruppoFotometria.save()).pipe(
                switchMap(gruppoFotometria => {
                    const queryFotometrie = gruppoFotometria.fotometrie.query()
                    queryFotometrie.limit(5000)
                    return fromPromise(queryFotometrie.find())
                }),
                map((fotometrie) => {
                    gruppoFotometria.fotometrieScaricate = fotometrie;
                    return gruppoFotometria;
                })
            )
        } else {
            gruppoFotometria.progetto = this.currentProject;
            return fromPromise(gruppoFotometria.save()).pipe(
                switchMap(newGruppoFotometria => {
                    const relation = newGruppoFotometria.relation("fotometrie");
                    if (addFotometrie.length > 0) {
                        relation.add(addFotometrie);
                    }
                    if (removeFotometrie.length > 0) {
                        relation.remove(removeFotometrie);
                    }
                    return fromPromise(newGruppoFotometria.save())
                }),
                switchMap(gruppoFotometria => {
                    const queryFotometrie = gruppoFotometria.fotometrie.query()
                    queryFotometrie.limit(5000)
                    return fromPromise(queryFotometrie.find())
                }),
                map((fotometrie) => {
                    gruppoFotometria.fotometrieScaricate = fotometrie;
                    return gruppoFotometria;
                })
            )
        }
    }


    getAllGruppiFotometrie(): Observable<GruppoFotometrieParse[]> {
        const query = new GruppoFotometrieParse().query;
        query.equalTo('progetto', this.currentProject);
        return fromPromise(query.find())
            .pipe(
                map((gruppi) => {
                    return gruppi
                })
            );
    }

    destroyGruppiFotometrie(gruppiFotometrie: GruppoFotometrieParse[]) {
        return fromPromise(Parse.Object.destroyAll(gruppiFotometrie));

    }


    openCreateUpdateGruppoFotometrie(photometries: FotometriaParse[], valueForm: {
        valueForm: string,
        html: string
    }[], gruppiFotometrie: GruppoFotometrieParse[] = undefined): Observable<GruppoFotometrieParse> {
        return this.dialogService.openCreateUpdateGrouopPhotometry(valueForm)
            .pipe(
                switchMap(nameGroup => {
                        if (!isNotNullOrUndefined(nameGroup)) {
                            return of(undefined)
                        } else {
                            let group;
                            if (nameGroup.isNew) {
                                group = new GruppoFotometrieParse();
                                group.name = nameGroup.valueForm
                            } else if (Array.isArray(gruppiFotometrie)) {
                                const index = gruppiFotometrie.findIndex(
                                    gruppo => gruppo.objectId == nameGroup.valueForm
                                )
                                if (index >= 0) {
                                    group = gruppiFotometrie[index]
                                } else {
                                    group = new GruppoFotometrieParse();
                                    group.objectId = nameGroup.valueForm
                                }
                            } else {
                                group = new GruppoFotometrieParse();
                                group.objectId = nameGroup.valueForm
                            }
                            return this.dialogInfo.openCreateUpdatePhotometry(photometries, group)
                        }
                    }
                ),
                switchMap(fotometriaGruppo => {
                    if (isNotNullOrUndefined(fotometriaGruppo)) {
                        return this.addFotometrieToGroup(fotometriaGruppo.groupFotometry, fotometriaGruppo.addPhotometry, fotometriaGruppo.removePhotometry)
                    } else {
                        return of(undefined)
                    }
                })
            )
    }

    openUpdatePhotometry(photometries, group) {
        return this.dialogInfo.openCreateUpdatePhotometry(photometries, group)
            .pipe(
                switchMap(fotometriaGruppo => {
                    if (isNotNullOrUndefined(fotometriaGruppo)) {
                        return this.addFotometrieToGroup(fotometriaGruppo.groupFotometry, fotometriaGruppo.addPhotometry, fotometriaGruppo.removePhotometry)
                    } else {
                        return of(undefined)
                    }
                })
            )
    }

    getGruppoFotometrieByName(name): Observable<GruppoFotometrieParse> {
        const query = new GruppoFotometrieParse().query;
        query.equalTo('progetto', this.currentProject);
        query.contains('name', name);
        return fromPromise(query.first())
    }

    getAllPhotometryByGroup$(groups: GruppoFotometrieParse[]) {
        if (arrayIsSet(groups)) {
            const fetchPage = (page = 0,) => {
                let isLast = (page + 1) >= groups.length;
                const current = page;
                const next = isLast ? (groups.length) : (page + 1);
                const progress = Math.ceil(next / groups.length * 100)
                const group = groups[current]
                const value$ = group.fotometrieScaricate$
                return value$.pipe(
                    map((items) => {
                        return {
                            items: items,
                            progress: progress,
                            nextPage: isLast ? undefined : (page += 1),
                            finished: isLast,
                            error: null
                        };
                    }),
                    catchError(error => {
                        return of({
                            items: undefined,
                            progress: progress,
                            nextPage: isLast ? undefined : (page += 1),
                            finished: isLast,
                            error: error
                        });
                    }),
                );
            }
            return fetchPage(0).pipe(
                expand(({nextPage}) => nextPage ? fetchPage(nextPage) : EMPTY),
                toArray(),
                map(items => {
                    if (arrayIsSet(items)) {
                        return items.reduce((prev, item) => {
                            if (arrayIsSet(item.items)) {
                                const idsPrev = prev.map(fototmetria => fototmetria.objectId)
                                prev = prev.concat(item.items.filter(fototmetria => !idsPrev.includes(fototmetria.objectId)));
                            }
                            return prev;
                        }, [])
                    }
                })
            )
        } else {
            return of([])
        }
    }

}
