import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BarScaleColorComponent } from './bar-scale-color/bar-scale-color.component';
import { LegendKeyColorComponent } from './legend-key-color/legend-key-color.component';
import {MatLegacyTableModule as MatTableModule} from "@angular/material/legacy-table";
import {TranslateModule} from "@ngx-translate/core";
import {PipesModule} from "../../providers/pipes/pipes.module";



@NgModule({
    declarations: [BarScaleColorComponent, LegendKeyColorComponent],
    exports: [
        BarScaleColorComponent,
        LegendKeyColorComponent
    ],
    imports: [
        CommonModule,
        MatTableModule,
        TranslateModule,
        PipesModule
    ]
})
export class ScaleForMapModule { }
