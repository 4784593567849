import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NotLoggedGuard} from './providers/guard/not-logged.guard';
import {LoggedGuard} from './providers/guard/logged.guard';
import {IsSetProjectGuard} from "./providers/guard/is-set-project.guard";
import {IsAmministratoreGuard} from "./providers/guard/is-amministratore.guard";

const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('./pages-modules/auth/auth.module').then(m => m.AuthModule),
        canActivate: [LoggedGuard]
    },
    {
        path: '',
        loadChildren: () => import('./pages-modules/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [NotLoggedGuard]
    },
    {
        path: 'user',
        loadChildren: () => import('./pages-modules/user/user.module').then(m => m.UserModule),
        canActivate: [NotLoggedGuard]
    },
    {
        path: 'planimetrie',
        loadChildren: () => import('./pages-modules/plans/plans.module').then(m => m.PlansModule),
        canActivate: [NotLoggedGuard, IsSetProjectGuard]
    },
    {
        path: 'importazione',
        loadChildren: () => import('./pages-modules/attachments/attachments.module').then(m => m.AttachmentsModule),
        canActivate: [NotLoggedGuard, IsSetProjectGuard]
    },
    {
        path: 'segnalazioni',
        loadChildren: () => import('./pages-modules/reports/reports.module').then(m => m.ReportsModule),
        canActivate: [NotLoggedGuard, IsSetProjectGuard]
    },
    {
        path: 'controllo-remoto',
        loadChildren: () => import('./pages-modules/remote-control/remote-control.module').then(m => m.RemoteControlModule),
        canActivate: [NotLoggedGuard, IsSetProjectGuard]
    },
    {
        path: 'strade',
        loadChildren: () => import('./pages-modules/streets/streets.module').then(m => m.StreetsModule),
        canActivate: [NotLoggedGuard, IsSetProjectGuard]
    },
    {
        path: 'esporta-file',
        loadChildren: () => import('./pages-modules/export-image-csv-xml/export-image-csv-xml.module').then(m => m.ExportImageCsvXmlModule),
        canActivate: [NotLoggedGuard, IsSetProjectGuard]
    },
    {
        path: 'energia-report',
        loadChildren: () => import('./pages-modules/report-energia/report-energia.module').then(m => m.ReportEnergiaModule),
        canActivate: [NotLoggedGuard, IsSetProjectGuard]
    },
    {
        path: 'file-manager',
        loadChildren: () => import('./pages-modules/huna-drop-box/huna-drop-box.module').then(m => m.HunaDropBoxModule),
        canActivate: [NotLoggedGuard, IsSetProjectGuard]
    },
    {
        path: 'fotometrie',
        loadChildren: () => import('./pages-modules/page-fotometrie/page-fotometrie.module').then(m => m.PageFotometrieModule),
        canActivate: [NotLoggedGuard, IsSetProjectGuard]
    }, {
        path: 'gestione-abbonati',
        loadChildren: () => import('./pages-modules/subscription-management/subscription-management.module').then(m => m.SubscriptionManagementModule),
        canActivate: [NotLoggedGuard, IsAmministratoreGuard]
    }, {
        path: 'manuntenzione-programmata',
        loadChildren: () => import('./pages-modules/schedule-maintenance/schedule-maintenance.module').then(m => m.ScheduleMaintenanceModule),
        canActivate: [NotLoggedGuard, IsSetProjectGuard]
    }, {
        path: 'segnalazioni-report',
        loadChildren: () => import('./pages-modules/page-report-segnalazioni/page-report-segnalazioni.module').then(m => m.PageReportSegnalazioniModule),
        canActivate: [NotLoggedGuard, IsSetProjectGuard]
    }, {
        path: 'carichi-esogeni',
        loadChildren: () => import('./pages-modules/carichi-esogeni/carichi-esogeni.module').then(m => m.CarichiEsogeniModule),
        canActivate: [NotLoggedGuard, IsSetProjectGuard]
    }, {
        path: 'new-installation',
        loadChildren: () => import('./pages-modules/lamp-installation/lamp-installation.module').then(m => m.LampInstallationModule),
        canActivate: [NotLoggedGuard, IsSetProjectGuard]
    }, {
        path: 'new-profile',
        loadChildren: () => import('./pages-modules/tlc-profile/tlc-profile.module').then(m => m.TlcProfileModule),
        canActivate: [NotLoggedGuard, IsSetProjectGuard]
    }, {
        path: 'group',
        loadChildren: () => import('./pages-modules/page-group/page-group.module').then(m => m.PageGroupModule),
        canActivate: [NotLoggedGuard, IsSetProjectGuard]
    },
    {
        path: 'foto-tipologia',
        loadChildren: () => import('./pages-modules/page-foto-tipologia/page-foto-tipologia.module').then(m => m.PageFotoTipologiaModule),
        canActivate: [NotLoggedGuard, IsSetProjectGuard]
    }, {
        path: 'verifica-illuminotecnica',
        loadChildren: () => import('./pages-modules/verify-lighting/verify-lighting.module').then(m => m.VerifyLightingModule),
        canActivate: [NotLoggedGuard, IsSetProjectGuard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
