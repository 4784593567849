import {Component, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FotoTipologiaType} from "../../providers/services/foto-tipologia.service";
import {
    AbstractControl,
    ControlValueAccessor,
    UntypedFormBuilder,
    UntypedFormGroup, NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors
} from "@angular/forms";
import {
    arrayIsSet,
    cleanToSpecialCharacterAndLowerCaseString,
    isNotNullOrUndefined, KeyStringValue,
    stringIsSet
} from "../../models/Models";
import {EMPTY, Observable, of, Subject, Subscription} from "rxjs";
import {map, startWith, switchMap} from "rxjs/operators";
import {DialogPopUpInfoService} from "../../providers/services/dialog-pop-up-info.service";
import {DialogPopUpService} from "../../providers/services/dialog-pop-up.service";
import {TranslateService} from "@ngx-translate/core";

export type UpdateFotoType = {
    destroy?: { image: FotoTipologiaType };
    edit?: { image: FotoTipologiaType, newImages: File[] }
}

@Component({
    selector: 'app-field-form-foto-tipologia',
    templateUrl: './field-form-foto-tipologia.component.html',
    styleUrls: ['./field-form-foto-tipologia.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: FieldFormFotoTipologiaComponent
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: FieldFormFotoTipologiaComponent
        }
    ]
})
export class FieldFormFotoTipologiaComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
    @Input() fotoTipologie: FotoTipologiaType[];
    @Input() savingNewFoto: boolean;
    @Input() loadingFotoTipologia: KeyStringValue<boolean>;
    @Input() selectable: boolean = true;
    @Input() addNew: boolean = true;
    @Input() classSizeBox: 'size-box-foto-tipologia' | 'size-box-foto-tipologia-static' = 'size-box-foto-tipologia';
    @Output() newImages: Subject<File[]> = new Subject<File[]>();
    @Output() updateFoto: Subject<UpdateFotoType> = new Subject<UpdateFotoType>();
    public form: UntypedFormGroup
    public searchName$: Observable<string | undefined>
    private subscriptions = new Subscription();

    constructor(fb: UntypedFormBuilder,
                private dialogPopUpInfo: DialogPopUpInfoService,
                private dialogPopUp: DialogPopUpService,
                private ts: TranslateService
    ) {
        this.form = fb.group({selected: undefined, searchName: undefined})
        this.searchName$ = this.form.get('searchName').valueChanges.pipe(
            startWith(undefined),
            map(() => this.form.get('searchName').value)
        )
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.fotoTipologie) {
            if (arrayIsSet(this.fotoTipologie)) {
                const currentFotoIds = this.form.get('selected').value;
                if (arrayIsSet(currentFotoIds)) {
                    this.form.get('selected').setValue(this.fotoTipologie.filter(f => currentFotoIds.includes(f.objectId)))
                }
            } else {
                this.form.reset();
            }
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    onTouched: Function = () => {
    };


    registerOnChange(onChange: any) {
        const sub = this.form.get('selected').valueChanges.pipe(
            map(fotoId => {
                const index = arrayIsSet(this.fotoTipologie) ? this.fotoTipologie.findIndex(f => f.objectId === fotoId) : -1;
                if (index >= 0) {
                    return this.fotoTipologie[index];
                } else {
                    return undefined;
                }
            })
        ).subscribe(onChange);
        this.subscriptions.add(sub)
    }

    registerOnTouched(onTouched: Function) {
        this.onTouched = onTouched;
    }


    setDisabledState(disabled: boolean) {
        if (disabled) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    writeValue(value: FotoTipologiaType) {
        if (value == null) {
            this.form.get('selected').reset();
        } else {
            this.form.get('selected').setValue(value.objectId);
        }
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (!isNotNullOrUndefined(this.form.get('selected').validator)) {
            this.form.get('selected').setValidators(control.validator)
        }
        if (this.form.get('selected').valid) {
            return null
        } else {
            return this.form.get('selected').errors;
        }
    }

    onLoadImage(files: File[]) {
        this.newImages.next(files);
    }

    openFullImage(image: FotoTipologiaType) {
        if (this.loadingFotoTipologia == null || this.loadingFotoTipologia[image.objectId] != true) {
            this.dialogPopUpInfo.openVisualizedImage(image.url, 'image', true, true)
                .pipe(
                    switchMap(response => {
                        if (response != null) {
                            if (response.destroy) {
                                return this.dialogPopUp.openDialogForDelete(this.ts.instant('fotoTipologia.deleteFoto', {name: image.name}), 'center').pipe(
                                    switchMap(choose => {
                                        if (choose) {
                                            return of({destroy: {image}});
                                        } else {
                                            return EMPTY;
                                        }
                                    })
                                )
                            } else if (response.edit != null) {
                                return this.dialogPopUp.openDialogForDelete(this.ts.instant('fotoTipologia.editFoto', {name: image.name}), 'center').pipe(
                                    switchMap(choose => {
                                        if (choose) {
                                            return of({edit: {image, ...response.edit}})
                                        } else {
                                            return EMPTY;
                                        }
                                    })
                                )
                            }
                        }
                        return EMPTY;
                    }),
                )
                .subscribe(
                    (response) => {
                        this.updateFoto.next(response as any)
                    }
                );
        }
    }

    filterPredicate(fotoTipologia: FotoTipologiaType[], searchName: string): FotoTipologiaType[] {
        if (arrayIsSet(fotoTipologia) && stringIsSet(searchName)) {
            const cleanedSearchName = cleanToSpecialCharacterAndLowerCaseString(searchName)
            return fotoTipologia.filter(foto => {
                const cleanedName = cleanToSpecialCharacterAndLowerCaseString(foto.name)
                return cleanedName.includes(cleanedSearchName);
            });
        } else {
            return fotoTipologia;
        }
    }

    areLoadingPredicate(fotoTipologia: FotoTipologiaType, loading: KeyStringValue<boolean>) {
        if (fotoTipologia != null && loading != null) {
            return loading[fotoTipologia.objectId] == true;
        } else {
            return false;
        }
    }

    cursorPredicate(fotoTipologia: FotoTipologiaType, loading: KeyStringValue<boolean>) {
        if (fotoTipologia != null && loading != null && loading[fotoTipologia.objectId] == true) {
            return undefined
        } else {
            return 'pointer';
        }
    }
}
