import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {arrayIsSet} from "../../../models/Models";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {MatSort} from "@angular/material/sort";
import {map, pairwise, startWith} from "rxjs/operators";
import {MatLegacyCheckboxChange as MatCheckboxChange} from "@angular/material/legacy-checkbox";

@Component({
    selector: 'app-select-photometry',
    templateUrl: './select-photometry.component.html',
    styleUrls: ['./select-photometry.component.scss']
})
export class SelectPhotometryComponent implements OnInit, AfterViewInit {
    public form: UntypedFormGroup
    dataSource: MatTableDataSource<any>;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    displayedColumns

    constructor(public dialogRef: MatDialogRef<SelectPhotometryComponent>,
                @Inject(MAT_DIALOG_DATA)
                public data: { elements: any[], getValue, getId, displayColumns: string[], traductionDisplayColumns: { [k: string]: string }, defaultValues: { [k: string]: string } },
                private fb: UntypedFormBuilder,) {
        const obj = {};
        this.dataSource = new MatTableDataSource<any>();
        if (arrayIsSet(data.elements)) {
            data.elements.forEach(el => {
                const id = this.getId(el);
                obj[id] = this.data.defaultValues[id];
            })
        }
        this.displayedColumns = ['checkBox'].concat(this.data.displayColumns);
        this.form = this.fb.group(obj);
    }

    getTraductions(column: string) {
        if (this.data.traductionDisplayColumns[column] != null) {
            return this.data.traductionDisplayColumns[column]
        } else if (column == 'checkBox') {
            return '';
        } else {
            return column;
        }
    }

    getId(element: any) {
        if (this.data.getId) {
            return this.data.getId(element)
        } else {
            return element.objectId
        }
    }

    getValue(element: any, key: string) {
        if (this.data.getValue != null) {
            return this.data.getValue(element)
        } else {
            return element[key];
        }
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.dataSource.data = this.data.elements;
        }, 100)
    }

    ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
            if (sortHeaderId !== 'checkBox') {
                return this.getValue(data, sortHeaderId)
            } else {
                return this.form.get(this.getId(data)).value + ''
            }
        };


    }

    clickCheckBox(el: any, value: MatCheckboxChange) {
        const id = this.getId(el);
        this.form.reset();
        this.form.get(id).setValue(value.checked);
    }

    save() {
        const ids = Object.keys(this.form.value).filter(key => this.form.value[key] == true);
        const elements = this.data.elements.filter(el => ids.includes(this.getId(el)));
        this.dialogRef.close(elements);
    }

}
