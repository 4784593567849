<mat-sidenav-container fullscreen [hasBackdrop]="false">
    <mat-sidenav
            class="sidenav-size"
            #sidenavStart mode="over">
        <div class="flex-center-width100" style="flex-direction: column;">
            <app-filter *ngIf="!(isActiveModalitaManutenzione||dynamicViewIsOn)"
                        class="w100"
                        [sidenavIsOpened]="true"
                        [initValue]="formPage.get('oldValueFilter').value"
                        (formValueChanges)="appFilterValueChanges($event)"
                        [circuits]="circuitiAppartenentiAlProgetto|convertValueByPredicateFunction:predicateGetCircuitiPossibleValues"
                        [changeFilter]="changeFilter"
                        [gruppiPuntiLuce]="gruppiAppartenentiAlProgetto|convertValueByPredicateFunction:predicateGetGruppiPuntiLucePossibleValues"
                        [arrediUrbani]="arredoUrbanoAppartenentiAlProgettoExport"
                        [electricLines]="electricLines"
            ></app-filter>
            <div style="position: absolute;bottom: 10px;">
                <div style="position: relative">
                    <div>
                        <button mat-mini-fab (click)="sidenavStart.close()">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </mat-sidenav>
    <!--sidenav-->
    <mat-sidenav
            class="sidenav-size"
            #sidenavEnd mode="over" position="end">
        <div *ngIf="projectLoaded"
             style="display: flex;align-items: center;justify-content: center;flex-direction: column">
            <ng-container *ngIf="sidenavEndWhoOpen==='formLightPoint';else settingsComponent">
                <ng-container [ngSwitch]="currentVisualization">
                    <ng-container *ngSwitchCase="modalityViewMap.scheduleMaintenance">
                        <div style="position: absolute;bottom: 20px;right:67px;z-index: 10000"
                        >
                            <div style="position: relative">
                                <app-button-spinner
                                        *ngIf="elementoCliccato"
                                        [loading]="loading.navigate"
                                        type="mat-mini-fab">
                                    <button
                                            (click)="openNavigator(undefined,elementoCliccato)"
                                            color="accent" mat-mini-fab matTooltip="{{'button.navigate' | translate}}"
                                            type="button">
                                        <mat-icon>navigation</mat-icon>
                                    </button>
                                </app-button-spinner>
                            </div>
                        </div>
                        <app-form-schedule-maintenance
                                style="max-width: 100%;width: calc(100% - 40px)"
                                [savedCompleted]="saveSchedaCompilata"
                                [sidenavIsOpen]="sidenavEnd.opened"
                                [elemento]="elementoCliccato"
                                [myRole]="myRole"
                                [currentUser]="currentUser"
                                [abbonamentiAttivi]="tuttiGliAbbonamenti$|async"
                                [segnalazioniAperteOnMap]="segnalazioniAperteOnMap"
                                [clusterCalendariManutenzioneSelezionato]="clusterCalendariManutenzioneSelezionato"
                                [calendariByElement]="calendariByElement"
                                [schedeManutenzioneCompiled]="schedeCompilateByElement"
                                (savedValue)="saveSchedaManutenzione($event)"
                                (assignTo)="clickAssignToInSegnalazione($event)"
                                (closeReport)="clickCloseReportInSegnalazione($event)"
                                (takeCharge)="clicktakeChargeInSegnalazione($event)"
                                (generateReport)="clickGenerateReportInSegnalazione($event)"
                                (reopenReport)="clickReopenReportInSegnalazione($event)"
                                (sendComment)="clickSendComment($event)"
                                (getCalendariByElement)="clickGetCalendari($event)"
                        >
                        </app-form-schedule-maintenance>
                        <div style="padding: 31px 0"></div>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <div style="position: absolute;bottom: 20px;z-index: 10000"
                             [ngStyle]="{right:elementoCliccatoIsCircuito?'213px':'67px'}"
                        >
                            <div style="position: relative">
                                <app-button-spinner
                                        *ngIf="elementoCliccato"
                                        [loading]="loading.navigate"
                                        type="mat-mini-fab">
                                    <button
                                            (click)="openNavigator(undefined,elementoCliccato)"
                                            color="accent" mat-mini-fab
                                            matTooltip="{{'button.navigate' | translate}}"
                                            type="button">
                                        <mat-icon>navigation</mat-icon>
                                    </button>
                                </app-button-spinner>
                            </div>
                        </div>
                        <app-dashboard-sidenav
                                class="w100"
                                [sidenavIsOpen]="sidenavEnd.opened"
                                [forceUpdate]="forceUpdateDashboardSidenav"
                                [detail]="elementoCliccato"
                                [saveElement]="saveElement"
                                [circuitiAppartenentiAlProgettoInput]="circuitiAppartenentiAlProgetto"
                                [stradeAppartenentiAlProgettoInput]="stradeAppartenentiAlProgetto"
                                [carichiEsogeniElemento]="(carichiEsogeniNelPorgetto$|async)|convertValueByPredicateFunction:filterCarichiEsogeni:elementoCliccato:nomeClassi:true"
                                [carichiEsogeniExternal]="(carichiEsogeniNelPorgetto$|async)|convertValueByPredicateFunction:filterCarichiEsogeni:elementoCliccato:nomeClassi:false"
                                (clickOpenNavigator)="openNavigator($event)"
                                (requestSchedeManutenzione)="updateSchedeManutenzione($event)"
                                (idEClasseElemento)='aggiornaElementoInDetail($event)'
                                (close)="sidenavEnd.close();elementoCliccato=undefined;"
                        >
                        </app-dashboard-sidenav>
                    </ng-container>
                </ng-container>
            </ng-container>
            <ng-template #settingsComponent>
                <app-settings
                        class="w100"
                        [sidenavIsOpen]="sidenavEnd.opened"
                        [circuiti]="circuits$|async"
                        [puntiLuce]="lightPoints$_v2|async"
                        [abbonamenti]="tuttiAbbonamenti"
                        [resetAllFilter]="resetIlluminamentoTerra"
                        [currentVisualization]="currentVisualization"
                        [calendarioManutenzioni]="calendariManutenzione"
                        [calendarioManutenzioniNonEffetuate]="calendariManutenzioneNonEffettuate"
                        [clusterVisualizzatoSullaMappa]="clusterCalendariManutenzioneSelezionato"
                        [defaultValue]="defaultValue"
                        [getElementOnParseLoad]="getElementOnParseLoad"
                        [keyScaleColorSetElecttricLine]="electricLineKey"
                        [scaleColorSetElecttricLine]="electricLineScaleColor"
                        (close)="sidenavStart.close()"
                        (scalaColoreImpostata)="cambiaColoreDelleIcone($event)"
                        (elementoEtichettaImpostato)="changeLabel($event)"
                        (campoScalaCaloreCircuitoCambiato)="cambioScalaColoreCircuito($event)"
                        (fieldLabelCircuitoChange)="labelCircuitiChange($event)"
                        (monthYearImpostato)="updateMonthYearForLuxDataClusters($event)"
                        (activeIlluminamentoATerra)="changeLuxDataVisibility($event)"
                        (scheduleMaintenanceValueChange)="scheduleMaintenanceChangeValue($event)"
                        (changeScaleColor)="setScaleColor($event)"
                >
                </app-settings>
            </ng-template>
            <div style="position: absolute;bottom: 20px;right:19px;z-index: 10000">
                <div style="position: relative">
                    <div>
                        <button mat-mini-fab (click)="sidenavEnd.close();">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </mat-sidenav>
    <div class="top-left-map">
        <div class="left-top-horizzontal-vertical-map">
            <button class="spacer-left-top-5" *ngIf="!(isActiveModalitaManutenzione||dynamicViewIsOn)"
                    mat-raised-button color="accent" (click)="sidenavStart.toggle()">
                <mat-icon>search</mat-icon>
            </button>
            <button class="spacer-left-top-5"
                    mat-raised-button color="accent" (click)="openMapInfo()">
                <mat-icon>info</mat-icon>
            </button>
            <app-button-spinner [loading]="loading.refreshButton||loading.elementsInMap" color="accent"
                                type="mat-raised-button"
                                class="spacer-left-top-5">
                <button
                        mat-raised-button color="accent" (click)="refresh()">
                    <mat-icon>replay</mat-icon>
                </button>
            </app-button-spinner>
            <app-button-spinner [loading]="loading.updateLightPoint" color="accent" type="mat-raised-button"
                                class="spacer-left-top-5">
            </app-button-spinner>
        </div>
    </div>
    <div class="top-right-map">
        <div class="right-top-horizzontal-vertical-map">
            <app-button-spinner [loading]="loading.settingsButton" color="accent" type="mat-raised-button"
                                class="spacer-right-top-5">
                <button
                        mat-raised-button color="accent" (click)="sidenavEnd.toggle();settings();">
                    <mat-icon>settings</mat-icon>
                </button>
            </app-button-spinner>
        </div>
    </div>
    <div *ngIf="isSetInterdistance" class="left-center-map">
        <div class="left-center-map-vertical-map">
            <div class="interdistancesDetail" *ngIf="!!interdistanceDetail">
                <h4>{{'streets.interdistance'|translate}}</h4>
                <div style="margin-right: 15px"
                     class="interdistancesDetail-child">{{'total'|translate}}
                    : {{interdistanceDetail.total}}
                </div>
                <div class="interdistancesDetail-child">{{'maximum'|translate}}
                    : {{interdistanceDetail.maximun}}
                    <div class="circle"
                         [ngStyle]="{backgroundColor:getPrendiColoreInbaseAlValore(interdistanceDetail.maximun)}"></div>
                </div>
                <div class="interdistancesDetail-child">{{'average'|translate}}
                    : {{interdistanceDetail.average}}
                    <div class="circle"
                         [ngStyle]="{backgroundColor:getPrendiColoreInbaseAlValore(interdistanceDetail.average)}"></div>
                </div>
                <div class="interdistancesDetail-child">{{'minimum'|translate}}
                    : {{interdistanceDetail.minimum}}
                    <div class="circle"
                         [ngStyle]="{backgroundColor:getPrendiColoreInbaseAlValore(interdistanceDetail.minimum)}"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="right-center-map">
        <div class="right-center-map-vertical-map">
            <app-bar-scale-color
                    *ngIf="elementiScalaLux!=undefined && !interdistanceOff"
                    [ElementidellaScala]="elementiScalaLux"
                    [min]="luxmin"
                    [max]="luxmax"
                    [unitOfMeasure]="'m'"
            ></app-bar-scale-color>
        </div>
    </div>
    <!--    <div class="bottom-left-map">-->
    <!--        <div class="left-bottom-horizzontal-vertical-map">-->
    <!--            -->
    <!--        </div>-->
    <!--    </div>-->
    <div class="bottom-right-map">
        <form [formGroup]="formPage">
            <div style="margin:0px 10px 10px 0;width:250px;max-width: 100%"
                 [ngClass]="{expandButtonSubLeft:expandSearchAddress}">
                <ng-container [ngSwitch]="classNameAddElement">
                    <ng-container *ngSwitchCase="nomeClassi.circuiti">
                        <mat-card *ngIf="openedAddPuntiLuceCircuito "
                        >
                            <mat-form-field appearance="fill">
                                <mat-label>{{'dashboard_sidenav.Circuiti.numeroQuadro.title'|translate}}</mat-label>
                                <input matInput formControlName="numeroQuadro">
                                <mat-hint>{{'dashboard_sidenav.Circuiti.numeroQuadro.automaticatillySelected'|translate}}</mat-hint>
                            </mat-form-field>
                        </mat-card>
                    </ng-container>
                    <ng-container *ngSwitchCase="nomeClassi.arredoUrbano">
                        <app-widget-select-arredo-urbano
                                [typesArredoUrbanoInProject]="customTypeArredoUrbano"
                                (changeValue)="addTypeArredoUrbano($event)" maxHeigth="250px">
                        </app-widget-select-arredo-urbano>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <ng-container *ngIf="openSearchSmart">
                            <ng-container formGroupName="searchSmart">
                                <app-multiple-value-free-text formControlName="targhePuntiLuce"
                                                              [disableEmitEvent]="false"
                                                              [isSetting]="true"
                                                              [highlightedChips]="formPage.get('searchSmart').get('visualizedChips').value"
                                                              (clickChips)="clickChipsSearchSmart($event)"
                                                              titleCard="{{'searchLightPoint'|translate}}"
                                                              label="{{'etichetta'|translate}}"
                                >
                                    <cornerRight>

                                        <button mat-icon-button
                                                [disableRipple]="true"
                                                style="transform: scale(0.6);"
                                                (click)="closeSearchSmart()">
                                            <mat-icon>clear</mat-icon>
                                        </button>

                                    </cornerRight>
                                </app-multiple-value-free-text>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="dynamicViewOpenSetting$|async">
                        <ng-container formGroupName="dynamicView">
                            <mat-card>
                                <div style="position: absolute;top:0px;right:-1px">
                                    <div style="cursor: pointer"
                                         (click)="closeDynamicView()">
                                        <mat-icon>cancel</mat-icon>
                                    </div>
                                </div>
                                <div class="flex-center-width100 flex-flow-column">
                                    <div class="flex-start-center w100 flex-flow-row">
                                        <mat-form-field appearance="fill" floatLabel="always">
                                            <input class="input"
                                                   [disabled]="formPage.get('dynamicView').get('searchAddress').disabled"
                                                   matInput
                                                   type="text"
                                                   appGetGoogleAddress
                                                   (changeAddress)="setSearchAddress($event)"
                                                   (focusin)="expandSearchAddress=true"
                                                   (focusout)="expandSearchAddress=false"
                                            >
                                            <!--                                                                                        <button matSuffix mat-icon-button-->
                                            <!--                                                                                                (click)="$event.stopPropagation();formPage.get('dynamicView').get('searchAddress').reset()">-->
                                            <!--                                                                                            <mat-icon>clear</mat-icon>-->
                                            <!--                                                                                        </button>-->
                                        </mat-form-field>
                                    </div>
                                    <div class="flex-start-center w100 flex-flow-row">
                                        <mat-checkbox [formControlName]="nomeClassi.puntiLuce"
                                                      style="margin-right: 5px">
                                        </mat-checkbox>
                                        <mat-label>{{nomeClassi.puntiLuce|translate}}</mat-label>
                                    </div>
                                    <div class="flex-start-center w100 flex-flow-row">
                                        <mat-checkbox [formControlName]="nomeClassi.circuiti"
                                                      style="margin-right: 5px">
                                        </mat-checkbox>
                                        <mat-label>{{nomeClassi.circuiti|translate}}</mat-label>
                                    </div>
                                    <div class="flex-start-center w100 flex-flow-row">
                                        <mat-checkbox [formControlName]="nomeClassi.arredoUrbano"
                                                      style="margin-right: 5px">
                                        </mat-checkbox>
                                        <mat-label>{{nomeClassi.arredoUrbano|translate}}</mat-label>
                                    </div>
                                    <div class="flex-start-center w100 flex-flow-row">
                                        <mat-checkbox [formControlName]="nomeClassi.lineaElettrica"
                                                      style="margin-right: 5px">
                                        </mat-checkbox>
                                        <mat-label>{{nomeClassi.lineaElettrica|translate}}</mat-label>
                                    </div>
                                </div>
                            </mat-card>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </form>
        <div class="right-bottom-horizzontal-vertical-map">
            <app-button-spinner
                    *ngIf="!(isActiveModalitaManutenzione||dynamicViewIsOn)"
                    [loading]="loading.searchSmart"
                    color="accent"
                    type="mat-mini-fab"
                    class="spacer-bottom-right-5">
                <button (click)="initSearchSmart()"
                        color="accent"
                        mat-mini-fab>
                    <mat-icon>manage_search</mat-icon>
                </button>
            </app-button-spinner>
            <ng-container *ngIf="openedDrawingElectricLine">
                <app-button-spinner
                        [loading]="loading.saveLine"
                        color="accent"
                        type="mat-mini-fab"
                        class="spacer-bottom-right-5">
                    <button [disabled]="!lengthAtLeast(drawedElectricLines,1)"
                            (click)="emitPointElectricLines()"
                            color="primary"
                            mat-mini-fab
                    >
                        <mat-icon>save</mat-icon>
                    </button>
                </app-button-spinner>
                <button
                        (click)="openEditNewElectricLine()"
                        class="spacer-bottom-right-5"
                        color="primary"
                        mat-mini-fab
                >
                    <mat-icon>edit</mat-icon>
                </button>
            </ng-container>

            <button class="spacer-bottom-right-5"
                    *ngIf="(isGestore$|async) && abilitaSpostamentoMarker"
                    mat-mini-fab
                    (click)="ripristinaSpostaPuntiLuceQuadri()"
                    [disabled]="!isSetPosizioneInizialiMarker||loading.moveSave"
                    matTooltip="{{'dashboard.button.spostaPuntoLuce' | translate}}"
                    matTooltipPosition="before"
                    color="primary">
                <mat-icon *ngIf="!loading.moveSave;else loaderInButton">undo</mat-icon>
                <ng-template #loaderInButton>
                    <mat-spinner style="margin-left: 2px;margin-top: 2px" diameter="20"></mat-spinner>
                </ng-template>
            </button>
            <button class="spacer-bottom-right-5"
                    *ngIf="isSetInterdistance"
                    (click)="disableInterdistance()"
                    color="primary"
                    mat-mini-fab>
                <img src="assets/icon/dashboard/interdistanceDisable.svg"
                     style="max-width: 24px;">
            </button>
            <button class="spacer-bottom-right-5"
                    *ngIf="repositioningElectricLines"
                    [disabled]="loading.eyeButton"
                    (click)="closeRepositioning()"
                    color="primary"
                    mat-mini-fab>
                <mat-icon>clear</mat-icon>
            </button>
            <button *ngIf="streetViewIsOpened&&openedAddPuntiLuceCircuito"
                    class="spacer-bottom-right-5"
                    (click)="addElementoByStreetView()"
                    color="primary"
                    [disabled]="disableButton.addElement"
                    [ngClass]="{'disableIconImg':disableButton.addElement}"
                    mat-mini-fab>
                <img src="/assets/icon/dashboard/addLight.png" style="max-width: 24px;">
            </button>
            <button class="spacer-bottom-right-5"
                    *ngIf="openedRuler"
                    (click)="disableRuler()"
                    color="primary"
                    mat-mini-fab>
                <img src="assets/icon/dashboard/disableRulerIconWhite.svg"
                     style="max-width: 24px;">
            </button>
            <button *ngIf="!myPosition.isEnabled;else disableGps"
                    (click)="openCloseGps()"
                    class="spacer-bottom-right-5"
                    color="accent"
                    mat-mini-fab
            >
                <mat-icon>location_searching</mat-icon>
            </button>
            <ng-template #disableGps>
                <button
                        (click)="openCloseGps()"
                        class="spacer-bottom-right-5"
                        color="primary"
                        mat-mini-fab
                >
                    <mat-icon>location_disabled</mat-icon>
                </button>
            </ng-template>
            <button
                    (click)="visualizedAllMarkerInMap()"
                    class="spacer-bottom-right-5"
                    color="accent" mat-mini-fab
                    matTooltip="{{'dashboard.button.markerSuMap' | translate}}"
                    matTooltipPosition="before"
                    [disabled]="false">
                <img src="assets/icon/dashboard/zoomout.png"
                     style="max-width: 24px;">
            </button>
            <app-button-spinner [loading]="loading.eyeButton" color="accent" type="mat-mini-fab"
                                class="spacer-bottom-right-5">
                <button [disabled]="false"
                        appLongPress
                        [emitOnceForClick]="true"
                        [disabledEmit]="loading.eyeButton"
                        (longPress)="longPressEye()"
                        (shortPress)="shortPressEye()"
                        color="accent"
                        mat-mini-fab
                >
                    <img src="assets/icon/dashboard/Eye_Light_Bulb_40x40.png"
                         style="max-width: 24px;">
                </button>
            </app-button-spinner>
            <button
                    (click)="removeAllLightPoint()"
                    class="spacer-bottom-right-5"
                    color="accent"
                    mat-mini-fab
                    [disabled]="(lightPointsIsSet$|async)==false&&!dynamicViewIsOn||openedDrawingElectricLine||repositioningElectricLines">
                <img src="assets/icon/dashboard/Eye_Light_Bulb_hidden40x40.png"
                     style="max-width: 24px;">
            </button>
        </div>
    </div>
    <div class="bottom-right-map-first">
        <div>
            <app-button
                    [loading]="loading.plusButton"
                    [vis]="visible"
                    (changeVisibility)="closeDrawingManager($event)"
                    iconClose="add"
                    iconOpened="clear"
                    class="button-position-newreport-newgroup">
                <ng-container *ngIf="!openedDrawingManager;else noCapture">
                    <button
                            *ngIf="myRole>=userRoleNumber.UTENTE && ((abbonamentoSegnalazioniAttivo$|async)||(abbonamentoProgettazioneAttivo$|async)||userAlmenoLivello5)"
                            (click)="openDrawingManager()"
                            color="accent"
                            mat-icon-button
                            [disabled]="disableButton.handButton"
                            [ngClass]="{'disableIconImg':disableButton.handButton}">
                        <img src="/assets/icon/dashboard/handPointer.svg"
                             style="max-width: 24px;">
                    </button>
                    <button *ngIf="((isGestore$|async)  || (isOperatore$|async)) && ((abbonamentoProgettazioneAttivo$|async) || userAlmenoLivello0)"
                            mat-icon-button color="primary"
                            (click)="addPuntoLuceCircuito()"
                            matTooltip="{{'dashboard.button.aggiungiElemento.title' | translate}}"
                            matTooltipPosition="before"
                            [disabled]="disableButton.addElement"
                            [ngClass]="{'disableIconImg':disableButton.addElement}"
                    >
                        <img src="/assets/icon/dashboard/addLight.png" style="max-width: 24px;">
                    </button>
                    <button *ngIf="((isGestore$|async) || (isOperatore$|async)) && ((abbonamentoProgettazioneAttivo$|async) || userAlmenoLivello0)"
                            mat-icon-button (click)="abilitaSpostaPuntiLuce()"
                            [disabled]="disableButton.move"
                            matTooltip="{{'dashboard.button.spostaPuntoLuce' | translate}}"
                            matTooltipPosition="before"
                            style="margin-bottom: 3px;">
                        <mat-icon>zoom_out_map</mat-icon>
                    </button>
                    <button *ngIf="((isGestore$|async) || (isOperatore$|async)) && ((abbonamentoProgettazioneAttivo$|async) || userAlmenoLivello5)"
                            mat-icon-button
                            color="primary"
                            (click)="openRuler()"
                            matTooltip="{{'dashboard.button.aggiungiElemento.ruler' | translate}}"
                            matTooltipPosition="before"
                            [disabled]="disableButton.ruler"
                            [ngClass]="{'disableIconImg':disableButton.ruler}"
                    >
                        <img src="/assets/icon/dashboard/rulerIconWhite.svg" style="max-width: 24px;">
                    </button>
                </ng-container>
                <ng-template #noCapture>
                    <button mat-icon-button
                            *ngIf="myRole>=userRoleNumber.UTENTE && (abbonamentoSegnalazioniAttivo$|async)"
                            (click)="createReports()"
                            matTooltip="{{'dashboard.button.create_report' | translate}}"
                            matTooltipPosition="before"
                    >
                        <mat-icon>warning</mat-icon>
                    </button>
                    <button *ngIf="(isGestore$|async) && ((abbonamentoProgettazioneAttivo$|async) || userAlmenoLivello5)"
                            mat-icon-button
                            (click)="createGroups()"
                            matTooltip="{{'dashboard.button.create_group' | translate}}"
                            matTooltipPosition="before"
                    >
                        <mat-icon>group_work</mat-icon>
                    </button>
                    <button *ngIf="(isGestore$|async) && ((abbonamentoProgettazioneAttivo$|async) || userAlmenoLivello5)"
                            mat-icon-button color="primary"
                            (click)="calcolaInterdistanza()"
                            matTooltip="{{'dashboard.button.calculateDistance' | translate}}"
                            matTooltipPosition="before"
                    >
                        <img src="/assets/icon/dashboard/interdistance.svg"
                             style="max-width: 24px;">
                    </button>
                    <button *ngIf="((isGestore$|async)|| (isOperatore$|async)) && ((abbonamentoProgettazioneAttivo$|async) || userAlmenoLivello0)"
                            mat-icon-button
                            [disabled]="disableButton.groupLightPoint"
                            [ngClass]="{'disableIconImg':disableButton.groupLightPoint}"
                            (click)="raggruppaPuntiLuce()"
                            matTooltip="{{'dashboard.button.groupLightPoint' | translate}}"
                            matTooltipPosition="before"
                    >
                        <img src="/assets/icon/dashboard/padre_figlio_punti_luce.svg" style="max-width: 24px;">
                    </button>
                </ng-template>
                <button *ngIf="((isGestore$|async)|| (isOperatore$|async)) && ((abbonamentoProgettazioneAttivo$|async) || userAlmenoLivello0)"
                        mat-icon-button
                        [disabled]="disableButtonConnection"
                        [ngClass]="{'disableIconImg':disableButtonConnection}"
                        (click)="groupFileConnections()"
                        matTooltip="{{'dashboard.button.fileConnections' | translate}}"
                        matTooltipPosition="before"
                >
                    <img src="/assets/icon/file/conncetionFile.svg" style="max-width: 24px;">
                </button>
                <button *ngIf="((isGestore$|async)|| (isOperatore$|async)) && ((abbonamentoProgettazioneAttivo$|async) || userAlmenoLivello0)"
                        mat-icon-button
                        [disabled]="disableButtonConnection"
                        [ngClass]="{'disableIconImg':disableButtonConnection}"
                        (click)="groupAssignSchedaManutenzione()"
                        matTooltip="{{'dashboard.button.schedeManutenzione' | translate}}"
                        matTooltipPosition="before"
                >
                    <img src="/assets/icon/hamburgerNavbar/calendar-setting.svg" class="invert-color"
                         style="max-width: 24px;">
                </button>
                <button *ngIf="((isGestore$|async)|| (isOperatore$|async)) && ((abbonamentoProgettazioneAttivo$|async) || userAlmenoLivello0)"
                        mat-icon-button (click)="deleteAllElement()"
                        [disabled]="disableButton.deleteAll"
                        matTooltip="{{'dashboard.button.deleteAllElement' | translate}}"
                        matTooltipPosition="before"
                >
                    <mat-icon>delete</mat-icon>
                </button>
                <button *ngIf="((isGestore$|async)|| (isOperatore$|async)) && ((abbonamentoProgettazioneAttivo$|async) || userAlmenoLivello0)"
                        mat-icon-button
                        [disabled]="(lightPointsIsSet$|async)==false || disableButton.batchEdit"
                        (click)="openBatchFotoTipologia()"
                        matTooltip="{{'button.fotoTipologiaButton' | translate}}"
                        matTooltipPosition="before"
                >
                    <mat-icon>photo_library</mat-icon>
                </button>
                <button *ngIf="((isGestore$|async)|| (isOperatore$|async)) && ((abbonamentoProgettazioneAttivo$|async) || userAlmenoLivello0)"
                        mat-icon-button color="primary"
                        (click)="batchEdit()"
                        [disabled]="(lightPointsIsSet$|async)==false ||disableButton.batchEdit"
                        matTooltip="{{'dashboard.button.modifica' | translate}}"
                        matTooltipPosition="before"
                        [ngClass]="{'disableIconImg':lightPoints.length<=0||disableButton.batchEdit}">
                    <img src="/assets/icon/dashboard/editLight.png"
                         style="max-width: 24px;">
                </button>
                <app-button-spinner [loading]="loading.batchVirtualMidnight" type="mat-icon-button">
                    <button *ngIf="((isGestore$|async)|| (isOperatore$|async)) && ((abbonamentoTelecontrolloAttivo$|async) || userAlmenoLivello5)"
                            mat-icon-button color="primary"
                            (click)="openBatchVirtualMidnight()"
                            [disabled]="(lightPointsIsSet$|async)==false ||disableButton.batchEdit"
                            matTooltip="{{'dashboard.button.virtualMidnight' | translate}}"
                            matTooltipPosition="before"
                            [ngClass]="{'disableIconImg':(lightPointsIsSet$|async)==false||disableButton.batchEdit}">
                        <img src="/assets/icon/hamburgerNavbar/icon-telecontrollo-white.png"
                             style="max-width: 24px;">
                    </button>
                </app-button-spinner>
            </app-button>
        </div>
    </div>
    <app-main-map
            [arrediUrbani]="arrediUrbaniOnMap"
            [boundChangeActive]="this.formPage.get('dynamicView').get('isOn').valueChanges|async"
            [confiniAmministrativi]="confiniAmministrativi"
            [forceRendering]="forceRendering"
            [lightPoints_v2]="(lightPoints$_v2|async)|convertValueByPredicateFunction:getIconSelectedLightpointsPredicate:(selectedLightPoints$|async):getIconSelected"
            [maxZoomCluster]="17"
            [clusterCircuits]="clustersCircuits$|async"
            [circuits_v2]="circuits$_v2|async"
            [myPosition]="myPosition"
            [forceUpdateCenter]="changeZoomInMap"
            [changeConfiniAmministrativi]="forceUpdateCenterMap"
            [interdistances]="interdistances"
            [distancess]="distancess"
            [drawedElectricLines]="drawedElectricLines"
            [electricLines]="electricLinesOnMap"
            [openedInterdistances]="isSetInterdistance"
            [openedDrawingManager]="openedDrawingManager"
            [openedAddPuntiLuceCircuito]="openedAddPuntiLuceCircuito"
            [openedRuler]="openedRuler"
            [openedDrawingElectricLine]="openedDrawingElectricLine"
            [repositioningElectricLines]="repositioningElectricLines"
            [markerSpostabile]="abilitaSpostamentoMarker&&!loading.moveSave"
            [markerClickable]="markerClickable"
            [locationsToCenterZoom]="locationsToCenterZoom"
            [searchAddress]="searchAddressCoordinate"
            (mapReference)="assignMap($event)"
            (elementoSelezionato)="clickElement($event,sidenavEnd)"
            (longPressMarker)="longPressMarker($event)"
            (drawingManagerValue)="drawingManager($event)"
            (coordinatePointerClick)="mapClicksCoords($event)"
            (coordinatePointerDbClick)="mapDoubleClick($event)"
            (informazioniInizioSpostamentoMarker)="conservaPosizioniIniziali($event)"
            (informazioniFineSpostamentoMarker)="spostaElemento($event)"
            (dragPointChangePosition)="dragChangePosition($event)"
            (boundChange)="mapBoundChange($event)"
            (loadingElementsInMap)="loadingElementsInMap($event)"
    ></app-main-map>
</mat-sidenav-container>
