<form [formGroup]="formGroup" class="w100">
    <div class="w100 flex-center-Between flex-flow-row">
        <div class="flex-center flex-flow-column width-box">
            <div>
                <button mat-icon-button color="primary" (click)="addMounth()" [disabled]="disableAddMonth$|async">
                    <mat-icon>expand_less</mat-icon>
                </button>
            </div>
            <mat-form-field appearance="outline" style="margin-bottom: -21px">
                <mat-label>{{'month'|translate}}</mat-label>
                <mat-select formControlName="month">
                    <mat-option *ngFor="let month of (possibleValuesMonth$|async)" [value]="month.value">{{month.date|customDate:'':'MMMM'|async}}</mat-option>
                </mat-select>
            </mat-form-field>
            <div>
                <button mat-icon-button color="primary" (click)="removeMounth()">
                    <mat-icon>expand_more</mat-icon>
                </button>
            </div>
        </div>
        <div class="flex-center flex-flow-column width-box">
            <div>
                <button mat-icon-button color="accent" (click)="addYear()" [disabled]="disableAddYear$|async">
                    <mat-icon>expand_less</mat-icon>
                </button>
            </div>
            <mat-form-field appearance="outline" style="margin-bottom: -21px">
                <mat-label>{{'year'|translate}}</mat-label>
                <mat-select formControlName="year">
                    <mat-option *ngFor="let year of (possibleValuesYear$|async)" [value]="year">{{year}}</mat-option>
                </mat-select>
            </mat-form-field>
            <div>
                <button mat-icon-button color="accent" (click)="removeYear()">
                    <mat-icon>expand_more</mat-icon>
                </button>
            </div>
        </div>
    </div>

</form>