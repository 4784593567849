import {Injectable} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";


export const keyParseError = {
    progettoMissing: "PROGETTO_MISSING",
    locationtMissing: "LOCATION_MISSING",
    objectIdNotValid: "OBJECT_NOT_VALID",
    dataMissing: "DATA_MISSING",
}

@Injectable({
    providedIn: 'root'
})
export class ErrorParseService {

    constructor(private translate: TranslateService) {
    }

    projectMissing() {
        return this.translate.instant('parseError.message.' + keyParseError.progettoMissing)
    }

    locationtMissing() {
        return this.translate.instant('parseError.message.' + keyParseError.locationtMissing)
    }

}
