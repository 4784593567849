<div>
    <div *ngIf="activities && activities.length == 0" fxLayout="column" [style.height]="'100%'"
         fxLayoutAlign="left left" class="cmp">
        <app-template-form title="{{'no_activities' | translate}}" [informativa]="false">
        </app-template-form>
    </div>
    <app-open-new-tab whatOpen="report" [openedList]="openedActiviesList" (itemModified)="itemModified($event)"
                      [emitIndex]="true" [indexTab]="indexTab" [forceUpdate]="forceUpdateTab" [isSubGroup]="true"
                      (currentIndex)="onTabChange($event)">
        <div class="scrollbar-visible activities-wrapper" (scroll)="onActivityListScroll($event)"
             #activityScrollWrapper>

            <ng-container *ngIf="activities">
                <div *ngFor="let activity of activities">
                    <mat-card style="margin-top: 0.5rem;">
                        <button style="position: absolute; top: 0; right: 0; z-index: 1;" mat-icon-button (click)="onUpdateElement(
                            {
                            objectId:activity.segnalazione.objectId,
                            titolo:activity.segnalazione.titolo,
                            priority:activity.segnalazione.priorita
                            })">
                            <mat-icon>
                                open_in_new
                            </mat-icon>
                        </button>
                        <mat-list-item class="spacer">
                            <div style="overflow: scroll;">
                                <div fxFlex="30%" fxLayoutAlign="start top">
                                    {{activity.nomeAutore}}
                                </div>
                                <div fxLayout="column" fxFlex="70%" fxLayoutAlign="center" style="padding-left: 10px;">
                                    <div fxLayoutAlign="start top" style="margin-bottom: 0.5rem; font-size: 12px"
                                         class="comment-label">
                                        {{activity.createdAt  |customDate :'medium'|async}}
                                    </div>
                                    <div *ngIf="activity.nuovoStato" fxLayout="row">
                                        <mat-label fxLayoutAlign="center center" class="card-label color-primary"
                                                   style="line-height: 15px;">
                                            {{('segnalazioniParse.stato.' + activity.statoPrecedente|translate)|notPresentInTraduction:activity.statoPrecedente:'reportSegnalazioni.stato.'}}
                                            <mat-icon style="margin-left: 0.2rem; margin-right: 0.2rem;">double_arrow
                                            </mat-icon>
                                            {{('segnalazioniParse.stato.' + activity.nuovoStato|translate)|notPresentInTraduction:activity.nuovoStato:'reportSegnalazioni.stato.'}}
                                        </mat-label>
                                    </div>
                                    <div *ngIf="activity.comment" fxLayout="row">
                                        <mat-label class="card-label color-primary" fxLayout="column">
                                            {{'comment' | translate}}:
                                        </mat-label>
                                        <div fxLayoutAlign="center center" style="margin-left: 0.5rem;">
                                            {{activity.comment}}
                                        </div>
                                    </div>
                                    <div *ngIf="activity.foto" fxLayout="row">
                                        <mat-label class="card-label color-primary" fxLayoutAlign="start center">
                                            {{'photo' | translate}}:
                                        </mat-label>
                                        <div fxLayoutAlign="center center" style="margin-left: 2rem">
                                            <a (click)="onOpenImage(activity, $event)" style="cursor: pointer;">
                                                <img style="max-width:100%" src="{{activity.foto.url()}}" width="145px">
                                            </a>
                                        </div>
                                    </div>
                                    <div *ngIf="activity.allegato" fxLayout="row">
                                        <mat-label class="card-label color-primary" fxLayoutAlign="start center">
                                            {{'attachment' | translate}}:
                                        </mat-label>
                                        <div fxLayoutAlign="center center" style="margin-left: 2rem">
                                            <a (click)="onOpenPdf(activity, $event)" target="_blank"
                                               style="cursor: pointer;">
                                                <div fxLayout="row" fxLayoutAlign="start center">
                                                    <img src="assets/pdf.png" width="40px">
                                                    <span style="margin-left: 0.3rem;">
                                                    {{getSplitNameObject(activity, 'name')}}</span>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div *ngIf="activity.schedaManutenzioneCompilata"
                                         style="max-width:100%;width:350px;margin-bottom: 5px;">
                                        <app-box-scheda-manutenzione-compilata
                                                [notVisualized]="{createdAt:true,compiledBy:true}"
                                                [schedaManutenzioneCompilata]="activity.schedaManutenzioneCompilata"
                                                [selected]="[activity.schedaManutenzioneCompilata]"
                                                (clickCard)="onUpdateElement({
                                                    objectId:activity.segnalazione.objectId,
                                                    titolo:activity.segnalazione.titolo,
                                                    priority:activity.segnalazione.priorita
                                                })"
                                        >
                                            <div badge *ngIf="showAlert(activity.schedaManutenzioneCompilata)">
                                                <button mat-mini-fab color="warn"
                                                >
                                                    <mat-icon>priority_high</mat-icon>
                                                </button>
                                            </div>
                                        </app-box-scheda-manutenzione-compilata>
                                    </div>

                                </div>
                            </div>
                        </mat-list-item>
                    </mat-card>
                </div>

            </ng-container>

        </div>

        <div class="activities-paginator" *ngIf="activities">
            <div class="paginator-page-size-selector">
                <span class="paginator-page-size-selector-label">{{'pagination.items_per_page' | translate}}:</span>
                <mat-select [value]="pageSize" (selectionChange)="onPageSizeChange($event.value)">
                    <mat-option [value]="5">5</mat-option>
                    <mat-option [value]="25">25</mat-option>
                    <mat-option [value]="50">50</mat-option>
                </mat-select>
            </div>
            <button [disabled]="skip <= 0 || loading" mat-icon-button
                    matTooltip="{{'pagination.first_page' | translate}}" (click)="onFirst()">
                <mat-icon>first_page</mat-icon>
            </button>
            <button
                    [disabled]="skip <= 0 || loading"
                    mat-icon-button
                    matTooltip="{{'pagination.previous_page' | translate}}"
                    (click)="changePage('left')">
                <mat-icon>chevron_left</mat-icon>
            </button>
            <button [disabled]="(totalEntries !== undefined && pageSize + skip >= totalEntries) || loading"
                    mat-icon-button matTooltip="{{'pagination.next_page' | translate}}" (click)="changePage('right')">
                <mat-icon>chevron_right</mat-icon>
            </button>
        </div>
    </app-open-new-tab>
    <div class="loader-wrapper" *ngIf="loading">
        <mat-spinner></mat-spinner>
    </div>
</div>