import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {isNotNullOrUndefined, stringIsSet} from "../../../models/Models";

@Component({
    selector: 'app-select-user-from-list',
    templateUrl: './select-user-from-list.component.html',
    styleUrls: ['./select-user-from-list.component.scss']
})
export class SelectUserFromListComponent implements OnInit {
    formElements: UntypedFormGroup = this.fb.group({});
    filtersForm: UntypedFormGroup = this.fb.group({nome: null, cognome: null, role: null});

    constructor(
        public dialogRef: MatDialogRef<SelectUserFromListComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: { elements: any[], getValueFunction, getIdFunction, multipleValues, defaultValue: string[] | string | undefined },
        private fb: UntypedFormBuilder,
    ) {
    }

    ngOnInit(): void {
        this.formElements = this.getForm(this.elements, this.data.defaultValue)
    }

    public get elements() {
        return this.data.elements
    }

    getId(item) {
        if (this.data.getIdFunction) {
            return this.data.getIdFunction(item)
        } else {
            return item.objectId
        }
    }

    getValue(element, column) {
        return this.data.getValueFunction(element, column)
    }

    get multipleValues() {
        return this.data.multipleValues;
    }

    getForm(elements: any[], defaultValue: string | string[] | undefined): UntypedFormGroup {
        const objForm = {}
        if (this.multipleValues) {
            elements.forEach(element => {
                objForm[element.id] = defaultValue !== undefined && Array.isArray(defaultValue) && defaultValue.includes(element.id)
            })
        } else {
            objForm['objectId'] = defaultValue && elements.some((e) => e.id === defaultValue) ? defaultValue : null;
        }
        return this.fb.group(objForm)
    }

    isSet(user) {
        if (this.multipleValues) {
            return this.formElements.get(this.getId(user)).value
        } else {
            return this.getId(user) == this.formElements.get('objectId').value;
        }
    }

    getColorIcon(user) {
        return this.isSet(user) ? 'primary' : 'default';
    }

    getActivedElements() {
        const activeElements = []
        if (this.multipleValues) {
            Object.keys(this.formElements.value).forEach((idElement => {
                if (this.formElements.get(idElement).value) {
                    const index = this.data.elements.findIndex(element => this.getId(element) === idElement);
                    if (index >= 0) {
                        activeElements.push(this.data.elements[index])
                    }
                }
            }))
        } else {
            const idElement = this.formElements.get('objectId').value
            const index = this.data.elements.findIndex(element => this.getId(element) === idElement);
            if (index >= 0) {
                activeElements.push(this.data.elements[index])
            }
        }
        this.dialogRef.close(activeElements)
    }

    predicateFunction(user, nome: string, cognome: string) {
        if (!isNotNullOrUndefined(user) || (!stringIsSet(nome) && !stringIsSet(cognome))) {
            return true;
        } else {
            let isFiltered = false
            const getLowerCase = (value) => (stringIsSet(value)) ? value.toLowerCase() : '';
            const lowerName = getLowerCase(user.get('nome'));
            isFiltered = (lowerName.trim().length > 0) ? lowerName.includes(getLowerCase(nome)) : false;
            if (isFiltered) {
                const lowerSurname = getLowerCase(user.get('cognome'));
                isFiltered = (lowerSurname.trim().length > 0) ? lowerSurname.includes(getLowerCase(cognome)) : false;
            }
            return isFiltered
        }
    }

}
