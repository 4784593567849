import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';
import {TranslateService} from "@ngx-translate/core";

@Pipe({
    name: 'formatter'
})
export class FormatterPipe implements PipeTransform {

    public regex = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(?:\.\d+)?Z/;

    constructor(private translateService: TranslateService) {
    }

    transform(value: any, getValueObject: { [key: string]: (value: any) => string }, key: string): any {
        const typeOf = typeof (value);
        if (getValueObject != null && getValueObject[key] != null) {
            return getValueObject[key](value)
        } else if (typeOf === 'boolean') {
            if (value === true) {
                return this.translateService.instant('yes');
            } else {
                return this.translateService.instant('no');
            }
        } else if (typeOf === 'string') {
            if (this.regex.exec(value)) {
                return moment(value).locale(navigator.language).format('lll');
            } else {
                return value;
            }
        } else {
            return value;
        }


    }
}
