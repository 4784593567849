import {Component, Input, OnInit} from '@angular/core';
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
    selector: 'app-box-general-info',
    templateUrl: './box-general-info.component.html',
    styleUrls: ['./box-general-info.component.scss']
})
export class BoxGeneralInfoComponent implements OnInit {
    @Input() selected;
    @Input() disabled;
    @Input() titleCardIcon: { title: string, icon: string, iconPath: string };
    @Input() details: { traduction: string, values: any[], overflow: string }[] = [];

    constructor(private matIconRegistry: MatIconRegistry,
                private domSanitizer: DomSanitizer,) {
    }

    ngOnInit(): void {
        this.matIconRegistry.addSvgIcon(
            this.titleCardIcon.icon,
            this.domSanitizer.bypassSecurityTrustResourceUrl(this.titleCardIcon.iconPath)
        );
    }

    getOverflow(detail) {
        return (typeof detail.overflow === "string") ? detail.overflow : 'unset'
    }
}
