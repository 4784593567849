<div class="spacer">
    <button (click)="openDialog()" mat-stroked-button color="primary">
        <div style="display: flex; align-items: center; padding: 4px 0">
            <div style="display: flex; flex-direction: column; ">
                <mat-icon>info</mat-icon>
            </div>
            <div style="display: flex; flex-direction: column; margin-left: 0.5rem; white-space: normal; line-height: 16px;">
                {{'info' | translate}}
            </div>
        </div>
    </button>
</div>