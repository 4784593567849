<form class="" [formGroup]="formGroup">
    <div class="hide-arrow-input">
        <mat-form-field appearance="fill">
            <mat-label *ngIf="title">{{title}}</mat-label>
            <input (focusin)="focusInInput()" (focusout)="focusOutInput()"
                   matInput
                   formControlName="value"
                   style="font-weight: bold;font-size: large;" type="number">
            <div class="flex-center-Between" style="width: 60px;margin-top: 5px" matSuffix>
                <div (click)="clickRemove()" class="background-primary size-button-add-remove pointer" [ngClass]="{disbled:(disableRemove$|async)||formGroup.disabled}">
                    <mat-icon style="margin-left: 1px">remove</mat-icon>
                </div>
                <div (click)="clickSum()" class="background-primary size-button-add-remove pointer" [ngClass]="{disbled:(disableAdd$|async)||formGroup.disabled}">
                    <mat-icon style="margin-left: 1px">add</mat-icon>
                </div>
            </div>
        </mat-form-field>
    </div>
</form>
