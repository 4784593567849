import { Injectable } from '@angular/core';
import * as Parse from 'parse';

@Injectable({
    providedIn: 'root'
})
export class LocationService {

    constructor() {
    }

    getCenterCoordinates(values: { location: Parse.GeoPoint, }[]): { center: Parse.GeoPoint } {
        if (values.length == 0) {
            return {
                center: new Parse.GeoPoint(0, 0)
            }; 

        }
        let sumLat = 0
        let sumLng = 0
        values.forEach(
            value => {
                const lat = value.location.latitude;
                const lng = value.location.longitude;
                if (lat != null && lng != null) {
                    sumLat += lat
                    sumLng += lng
                }
            }
        )
        const meanLat = sumLat / values.length
        const meanLng = sumLng / values.length
        return {
            center: new Parse.GeoPoint(meanLat, meanLng)
        };
    }


    getNewPoint(latitude, longitude): Parse.GeoPoint {
        return new Parse.GeoPoint(latitude, longitude)

    }

}
