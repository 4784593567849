import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sostituisciUndercoreConSpazi'
})
export class SostituisciUndercoreConSpaziPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (typeof value == 'string') {
      return value.replace(/_/g,' ');
    } else {
      return value;
    }

  }

}
