import {Injectable} from '@angular/core';
import {SchedaManutenzioneParse} from "../../models/SchedaManutenzione.Parse";
import {EMPTY, forkJoin, Observable, of} from "rxjs";
import {ProjectService} from "./project.service";
import {ProgettiParse} from "../../models/Progetti.Parse";
import {fromPromise} from "rxjs/internal-compatibility";
import {
    arrayIsSet, returnsValueWithFunctionIsInError$,
    className, getDifferenceDateInDay,
    getItemInArrayByKeyValue,
    getParseObjectByClassNameObjectId, getToday, parseFetchAll$,
    stringIsSet, isNotNullOrUndefined, paramsApiParse_v2, fetchParseObjects$
} from "../../models/Models";
import * as Parse from "parse";
import {catchError, concatMap, delay, expand, map, switchMap, toArray} from "rxjs/operators";
import {PuntiLuceParse} from "../../models/PuntiLuce.Parse";
import {CircuitiParse} from "../../models/Circuiti.Parse";
import {dataForm} from "../../components/confirm-delete/select-or-create/select-or-create.component";
import {SchedaManutenzioneCompilataParse} from "../../models/SchedaManutenzioneCompilata.Parse";
import {CalendarioManutenzioniParse} from "../../models/CalendarioManutenzioni.Parse";
import {SegnalazioniParse} from "../../models/Segnalazioni.Parse";
import {Priority, ReportStates} from "../../../config/static-data";
import {typeFormValue} from "../../models/configurationProperty/configurationPropertyUtils";
import {DocumentsFolderParse} from "../../models/DocumentsFolder.Parse";
import {TranslateService} from "@ngx-translate/core";
import {CustomDatePipe} from "../pipes/custom-date.pipe";
import {DocumentsFileParse} from "../../models/DocumentsFile.Parse";
import {UserService} from "./user.service";
import {TemporaryFileParse} from "../../models/TemporaryFile.Parse";
import {BozzaSchedaManutenzioneCompilata, LocalSotrageService} from "./local-sotrage.service";
import {CircuitiService} from "./circuiti.service";

export enum typeFormFieldMaintenance {
    inputString,
    inputNumber,
    multiSelect,
    autoComplete,
    dataPicker,
    image,
    checkBox,
    yesOrNot,
    hour
}

export const formFieldGroupName = 'FormGruoupType'
export type formFiledMaintenance = {
    traduction: string,
    type,
    possibleValues: string[],
    sortingNumber: number,
    note: string,
    required: boolean
}
export type formFiledMaintenanceFormContolName =
    formFiledMaintenance
    & { formControlName, possibleValuesWithKey?: dataForm [] }

export type formFiledMaintenanceFormGroup =
    Omit<formFiledMaintenanceFormContolName, 'type'>
    & {
    type: typeFormFieldMaintenance | typeof formFieldGroupName,
    formGroup?: {
        name: string,
        type: typeFormValue,
        traductionMin: string,
        traductionMax: string,
        operators: { formControlname: string, operator: string }[]
    },
}
export const typeElementScheduleMaintence = {
    PuntiLuce: 'PUNTI_LUCE',
    Circuiti: 'CIRCUITI',
    Segnalazioni: 'SEGNALAZIONI'
}


export const typeEmitListScheduleToApprove = {
    print: 'PRINT',
    reject: 'REJECT'
}


@Injectable({
    providedIn: 'root'
})
export class MaintenanceService {

    private _activeModalitaManute;

    constructor(
        private projectService: ProjectService,
        private translateService: TranslateService,
        private userService: UserService,
        private localStorageService: LocalSotrageService,
        private circuitiService: CircuitiService
    ) {
    }


    get project() {
        if (this.projectService.actualProject) {
            return this.projectService.actualProject
        } else {
            const progetto = new ProgettiParse()
            progetto.objectId = this.projectService.getProjectLocal()
            return progetto;
        }
    }


    createNewScheduleMaintenance(form: {
        note: string,
        periodo: number,
        type: string,
        dataDiScadenza: Date,
        forms: typeFormFieldMaintenance[]
    }): Observable<SchedaManutenzioneParse> {
        const schedaManutenzione = new SchedaManutenzioneParse();
        schedaManutenzione.progetti = [this.project];
        Object.keys(form).forEach(key => {
            schedaManutenzione[key] = form[key];
        })
        return fromPromise(schedaManutenzione.save())
    }

    destroyScheduleMaintenance(schedaManutenzione: SchedaManutenzioneParse): Observable<SchedaManutenzioneParse> {
        return fromPromise(schedaManutenzione.destroy())
    }

    private assingValueByFormSchedaManutenzione(schedaManutenzione: SchedaManutenzioneParse, form: {
        note: string,
        periodo: number,
        forms: typeFormFieldMaintenance[]
    }) {
        if (!arrayIsSet(schedaManutenzione.progetti)) {
            schedaManutenzione.progetti = [this.project];
        } else if (!isNotNullOrUndefined(getItemInArrayByKeyValue(schedaManutenzione.progetti, this.project.objectId, 'objectId'))) {
            schedaManutenzione.progetti.push(this.project);
        }
        if (isNotNullOrUndefined(form)) {
            Object.keys(form).forEach(key => {
                schedaManutenzione[key] = form[key];
            })
        }
        return schedaManutenzione
    }

    updateNewScheduleMaintenance(form: {
        note: string,
        periodo: number,
        forms: typeFormFieldMaintenance[]
    }, schedaManutenzione: SchedaManutenzioneParse): Observable<SchedaManutenzioneParse> {
        if (!arrayIsSet(schedaManutenzione.progetti)) {
            schedaManutenzione.progetti = [this.project];
        } else if (!isNotNullOrUndefined(getItemInArrayByKeyValue(schedaManutenzione.progetti, this.project.objectId, 'objectId'))) {
            schedaManutenzione.progetti.push(this.project);
        }
        Object.keys(form).forEach(key => {
            schedaManutenzione[key] = form[key];
        })
        const updatedScheda = this.assingValueByFormSchedaManutenzione(schedaManutenzione, form)
        return fromPromise(updatedScheda.save())
    }


    fetchPage(page = 0, numberElementForCycle, schedeManutenzione: SchedaManutenzioneParse[], form) {
        const first = page * numberElementForCycle;
        let isLast;
        let last;
        if (first + numberElementForCycle >= schedeManutenzione.length) {
            last = schedeManutenzione.length;
            isLast = true;
        } else {
            last = first + numberElementForCycle;
            isLast = false;
        }
        const progress = Math.ceil(last / schedeManutenzione.length * 100)
        const schedeManutenzioneToSave = schedeManutenzione.slice(first, last);
        schedeManutenzioneToSave.map(schedaManutenzione => this.assingValueByFormSchedaManutenzione(schedaManutenzione, form))
        const res = Parse.Object.saveAll(schedeManutenzioneToSave)
        return fromPromise(res).pipe(
            map((item) => {
                return {
                    item: item,
                    progress: progress,
                    nextPage: isLast ? undefined : (page += 1),
                    finished: isLast,
                };
            }),
            delay(1000)
        );
    }


    updateNewScheduleMaintenancesPaginate(form: {
        note: string,
        periodo: number,
        forms: typeFormFieldMaintenance[]
    }, schedeManutenzione: SchedaManutenzioneParse[], numberElementForCycle): Observable<any> {
        return this.fetchPage(0, numberElementForCycle, schedeManutenzione, form).pipe(
            expand(({nextPage}) => nextPage ? this.fetchPage(nextPage, numberElementForCycle, schedeManutenzione, form) : EMPTY),
        )
    }


    // getFormFiledMaintenance(value: formFiledMaintenance = undefined): FormGroup {
    //     let obj: formFiledMaintenance;
    //     if (isNotNullOrUndefined(value)) {
    //         obj = {
    //             traduction: value.traduction,
    //             type: value.type,
    //             possibleValues: value.possibleValues,
    //             sortingNumber: value.sortingNumber,
    //             required: value.required,
    //             note: value.note
    //         }
    //     } else {
    //         obj = {
    //             traduction: null,
    //             type: null,
    //             possibleValues: null,
    //             sortingNumber: null,
    //             required: null,
    //             note: null
    //         }
    //     }
    //     return this.fb.group(obj);
    // }


    public getSegnalazioni(priorities: string[] = undefined, rangeDate: {
        fromDate: Date,
        toDate: Date
    }): Observable<SegnalazioniParse[]> {
        let fromDate;
        let toDate;
        if (rangeDate != null) {
            fromDate = rangeDate.fromDate.getTime();
            toDate = rangeDate.toDate.getTime();
        }
        const segnalazioni = Parse.Cloud.run('getSegnalazioniForMap', paramsApiParse_v2({
            priorities,
            fromDate,
            toDate,
            progettoId: this.project.objectId
        }));
        return fromPromise(segnalazioni);
    }

    getAllSchedeManutenzioneByProject(progetto: ProgettiParse = this.project): Observable<SchedaManutenzioneParse[]> {
        const query = new SchedaManutenzioneParse().query;
        query.equalTo('progetti', progetto);
        return fromPromise(query.find());
    }


    getAllSchedeManutenzioneByProjectAndOthers(project: ProgettiParse, vaules: object): Observable<SchedaManutenzioneParse[]> {
        const progetto = (isNotNullOrUndefined(project)) ? project : this.project;
        const query = new SchedaManutenzioneParse().query;
        query.equalTo('progetti', progetto);
        Object.keys(vaules).forEach(key => {
            if (isNotNullOrUndefined(vaules[key])) {
                query.equalTo(key, vaules[key]);
            } else {
                query.doesNotExist(key)
            }
        })
        return fromPromise(query.find());
    }

    getSchedeManutenzioneSegnalazioni(project: ProgettiParse = null) {
        const progetto = (isNotNullOrUndefined(project)) ? project : this.project;
        const query = new SchedaManutenzioneParse().query;
        query.equalTo('progetti', progetto);
        query.equalTo('type', typeElementScheduleMaintence.Segnalazioni);
        return fromPromise(query.find());
    }

    getSchedeManutenzioneNonInProgetto(progetto: ProgettiParse = this.project): Observable<SchedaManutenzioneParse[]> {
        const query = new SchedaManutenzioneParse().query;
        query.notEqualTo('progetti', progetto);
        return fromPromise(query.find());
    }

    getLightPointCircuitoByScheda(numberElementForCycle, schedeManutenzione: SchedaManutenzioneParse[]) {
        const project = this.project;
        const chiavi: string[] = [];
        const operatori: string[] = [];
        const valori: any[] = [];
        schedeManutenzione.forEach(schedaManutenzione => {
            chiavi.push('schedeManutenzione');
            operatori.push('=');
            valori.push(schedaManutenzione.objectId);
        })
        let dataApi;

        function fetchPage(page = 0) {
            dataApi = {
                pageSize: numberElementForCycle,
                requestedPage: page,
                progetto: project.objectId,
                keys: (chiavi) ? chiavi : [],
                constraints: (operatori) ? operatori : [],
                values: (valori) ? valori : []
            };
            const res = Parse.Cloud.run('getPuntiLuceAndMapIcons',
                dataApi, {});
            return fromPromise(res).pipe(
                map((item) => {
                    const puntiLuce: PuntiLuceParse[] = item[0].map((puntoLuce, index) => {
                        const newPuntoLuce = puntoLuce;
                        puntoLuce.icon = item[1][index];
                        return newPuntoLuce;
                    });
                    const isLast = puntiLuce.length < numberElementForCycle;
                    return {
                        item: puntiLuce,
                        nextPage: isLast ? undefined : (page += 1),
                        finished: isLast
                    };
                })
            );
        }

        return fetchPage().pipe(
            expand(({nextPage}) => nextPage ? fetchPage(nextPage) : EMPTY),
            concatMap(({item, finished}) => {
                let items = [];
                items.push(item);
                return of({puntiLuce: item, finished: finished});
            }),
        ) as Observable<{ puntiLuce: PuntiLuceParse[], finished: boolean }>;
    }


    getCircuiti(schedeManutenzione: SchedaManutenzioneParse[]): Observable<CircuitiParse[]> {
        const ids = schedeManutenzione.map(s => s.objectId);
        return this.circuitiService.getCircuiti$(this.project, undefined, undefined, undefined, ids);
    }

    fetchAll(schedeManutenzione: SchedaManutenzioneParse[]): Observable<SchedaManutenzioneParse[]> {
        const className = (schedeManutenzione[0] as any).className
        const includes = ['progetto']
        return fetchParseObjects$<SchedaManutenzioneParse>(className, schedeManutenzione.map((o: any) => o.objectId), includes)
    }


    castValueSchedaManutenzioneCompilata(form, key, formField: formFiledMaintenanceFormContolName) {
        const value = form[key];
        const type = formField.type
        if (!isNotNullOrUndefined(value)) {
            return undefined
        } else {
            let castValue;
            switch (type) {
                case typeFormFieldMaintenance.image:
                    if (arrayIsSet(value.file)) {
                        castValue = value.file.map(file => new Parse.File(file.name, {base64: file.image}, "image/jpg"));
                    }
                    break;
                case typeFormFieldMaintenance.autoComplete:
                case typeFormFieldMaintenance.multiSelect:
                case typeFormFieldMaintenance.inputString:
                    if (typeof value == "string") {
                        castValue = value
                    } else if (value.toString) {
                        castValue = value.toString().trim();
                    }
                    break
                case typeFormFieldMaintenance.inputNumber:
                    if (typeof value == "number") {
                        castValue = value
                    } else if (typeof value == "string" && isFinite(parseInt(value))) {
                        castValue = parseInt(value);
                    }
                    break
                case typeFormFieldMaintenance.dataPicker:
                    castValue = value;
                    break
                default:
                    castValue = value;
            }
            return castValue
        }
    }


    getSchedaManutenzioneCompilata(rangeDate: {
        firstDate,
        lastDate
    } = null): Observable<SchedaManutenzioneCompilataParse[]> {
        const query = new SchedaManutenzioneCompilataParse().query;
        query.equalTo('progetto', this.project);
        if (isNotNullOrUndefined(rangeDate) && rangeDate.firstDate instanceof Date) {
            query.greaterThanOrEqualTo('createdAt', rangeDate.firstDate)
        }
        if (isNotNullOrUndefined(rangeDate) && rangeDate.lastDate instanceof Date) {
            query.lessThan('createdAt', rangeDate.lastDate)
        }
        query.include(['circuito', 'puntoLuce']);

        query.include(['segnalazione.circuito', 'segnalazione.puntiLuce']);
        return fromPromise(query.find()).pipe(
            switchMap(schedeCompilate => {
                return this.fetchDocumentFileInFormSchedeCompilate(schedeCompilate)
            })
        )
    }


    fetchDocumentFileInFormSchedeCompilate(schedeCompilate: SchedaManutenzioneCompilataParse[]): Observable<SchedaManutenzioneCompilataParse[]> {
        const documentsFile: {
            documentFile: DocumentsFileParse,
            schedaCompilata: SchedaManutenzioneCompilataParse,
            traduction: string
        } [] = [];
        schedeCompilate.forEach(schedaCompilate => {
            schedaCompilate.form.forEach(field => {
                if (arrayIsSet(field.value)) {
                    field.value.forEach(value => {
                        if (value.className && value.className == className.documentsFile) {
                            documentsFile.push({
                                documentFile: value,
                                schedaCompilata: schedaCompilate,
                                traduction: field.traduction
                            })
                        }
                    })
                }
            })
        })
        if (arrayIsSet(documentsFile)) {
            return forkJoin(documentsFile.map(value => fromPromise(value.documentFile.fetch()))).pipe(
                map((documentFileFectched: any[]) => {
                    documentFileFectched.forEach(documentFileFetch => {
                        const index = documentsFile.findIndex(df => df.documentFile.objectId == documentFileFetch.objectId)
                        if (index >= 0) {
                            const indexForm = documentsFile[index].schedaCompilata.form.findIndex(field => field.traduction == documentsFile[index].traduction)
                            if (indexForm >= 0) {
                                const indexFile = documentsFile[index].schedaCompilata.form[indexForm].value.findIndex(value => value.objectId == documentFileFetch.objectId)
                                if (indexFile >= 0) {
                                    documentsFile[index].schedaCompilata.form[indexForm].value[indexFile] = documentFileFetch;
                                }
                            }
                        }
                    })
                    return schedeCompilate
                }),
                returnsValueWithFunctionIsInError$(schedeCompilate)
            )
        } else {
            return of(schedeCompilate)
        }
    }

    updateSchedeManutenzioneCompilata(schedeManutenzioneCopilate: SchedaManutenzioneCompilataParse[], values: any, chunk = 100) {
        schedeManutenzioneCopilate.forEach(scheda => {
            Object.keys(values).forEach(key => {
                scheda[key] = values[key]
            })
        })
        return fromPromise(Parse.Object.saveAll(schedeManutenzioneCopilate, {batchSize: chunk}))
    }

    getInfoForSchedaManutenzione(isdScheda: string[], idParseObject: string, who: className.circuiti | className.puntiLuce) {
        let data = {
            schedaIds: isdScheda,
            puntoLuceId: undefined,
            circuitoId: undefined,
        }
        if (who == className.circuiti) {
            data.circuitoId = idParseObject
        } else if (who == className.puntiLuce) {
            data.puntoLuceId = idParseObject
        }
        const res = Parse.Cloud.run('getInfoForSchedaManutenzione',
            data, {});
        return fromPromise(res)
    }


    getSchedeByCluster(cluster: { calendariManutenzione: CalendarioManutenzioniParse[] }[]): SchedaManutenzioneParse[] {
        if (arrayIsSet(cluster)) {
            let schedeSelezionate = cluster
                .map(cluster => {
                    return cluster.calendariManutenzione
                })
                .reduce((acc, curr) => (acc.concat(curr)))
                .map(calendario => {
                    return calendario.schedaManutenzione
                });
            schedeSelezionate = schedeSelezionate.filter((scheda, index) => {
                const indexDoppione = schedeSelezionate.findIndex(schedaSelezionataConDoppioni => schedaSelezionataConDoppioni.objectId === scheda.objectId)
                return indexDoppione == index
            });
            return schedeSelezionate
        } else {
            return []
        }
    }


    getSchedeManutenzioneComiplateByFormField(schedaSelezionata: SchedaManutenzioneParse, formValues: {
        traduction: string,
        value: any
    }[]) {
        const query = new SchedaManutenzioneCompilataParse().query;
        query.equalTo("progetto", this.project);
        if (schedaSelezionata != null) {
            query.equalTo("schedaManutenzione", schedaSelezionata);
        }
        if (!arrayIsSet(formValues)) {
            query.limit(50);
        } else {
            formValues.forEach(value => {
                query.equalTo("form", value);
            })
        }
        query.include(['puntoLuce', 'circuito'])
        query.include(['segnalazione.circuito', 'segnalazione.puntiLuce']);
        return fromPromise(query.find());
    }

    getSchedeManutenzioneComiplateByFormFieldCloud(schedaSelezionata: SchedaManutenzioneParse, filters: filtersSchedeManutenzioneCompilate[] | undefined) {
        const data = {
            progettoId: this.project.objectId,
            schedaManutenzioneId: schedaSelezionata.objectId
        }
        if (arrayIsSet(filters)) {
            data['filters'] = filters;
        } else {
            return of([])
        }
        const res = Parse.Cloud.run('findSchedaManutenzioneCompilata',
            data, {});
        return fromPromise(res);
    }


    createNewTemporaryFile(file: {
        name: string,
        extension: string,
        image: string,
        pureFile?: File
    }): Observable<TemporaryFileParse> {

        let parseFile
        if (file.pureFile != null) {
            parseFile = new Parse.File(file.name, file.pureFile, "image/jpg");
        } else {
            parseFile = new Parse.File(file.name, {base64: file.image}, "image/jpg");
        }
        const temporatyFile = new TemporaryFileParse();
        temporatyFile.file = parseFile;
        temporatyFile.user = Parse.User.current();
        temporatyFile.extension = file.extension;
        return fromPromise(temporatyFile.save());
    }

    createNewTemporaryFiles(files: { name: string, extension: string, image: string, pureFile?: File }[]): Observable<{
        item: TemporaryFileParse,
        progress: number,
        finished: boolean
    }> {
        const fetchPage = (page: number) => {
            const file = files[page];
            const isLast = page + 1 >= files.length;
            const progress = Math.ceil((page + 1) / files.length * 100);
            return this.createNewTemporaryFile(file).pipe(
                map((file) => {
                    return {
                        item: file,
                        progress,
                        nextPage: isLast ? undefined : (page + 1),
                        finished: isLast,
                    };
                })
            )
        }
        return fetchPage(0).pipe(
            expand(({nextPage}) => nextPage ? fetchPage(nextPage) : EMPTY),
        )
    }

    createTemporaryFileByFormValues(files: {
        traduction: string,
        value: { name: string, image: string, pureFile: File }[],
        formControlName: string,
        type: typeFormFieldMaintenance
    }[]): Observable<{
        item: {
            traduction: string,
            value: TemporaryFileParse[],
            formControlName: string,
            type: typeFormFieldMaintenance
        },
        progress: number,
        finished: boolean
    }> {
        const fetchPage = (page: number) => {
            const file = files[page];
            const isLast = page + 1 >= files.length;
            const progress = Math.ceil((page + 1) / files.length * 100);
            const valueWithExtension = file.value.map(value => {
                const name = value.name;
                const indexPoint = name.lastIndexOf('.');
                const extension = (indexPoint >= 0) ? name.substring(indexPoint + 1, name.length).toLocaleLowerCase() : null;
                const image = value.image;
                const pureFile = value.pureFile
                return {name, extension, image, pureFile}
            })
            return this.createNewTemporaryFiles(valueWithExtension).pipe(
                toArray(),
                map((temporaryFiles) => {
                    return {
                        item: {
                            traduction: file.traduction,
                            value: temporaryFiles.map(fi => fi.item),
                            type: file.type,
                            formControlName: file.formControlName
                        },
                        progress,
                        nextPage: isLast ? undefined : (page + 1),
                        finished: isLast,
                    };
                })
            )
        }
        return fetchPage(0).pipe(
            expand(({nextPage}) => nextPage ? fetchPage(nextPage) : EMPTY),
        )
    }

    getLightPointByAdvancedFilter(values: any) {
        const dataApi = {
            progetto: this.project.objectId,
        };
        if (values != null) {
            Object.keys(values).forEach(key => {
                dataApi[key] = values[key]
            })
        }
        const res = Parse.Cloud.run('getPuntiLuceAndMapIcons',
            dataApi, {});
        return fromPromise(res)
            .pipe(
                map(lightPointIcon => lightPointIcon.map((lightPoint, index) => {
                    lightPoint.icon = lightPointIcon[index][1];
                    return lightPoint
                }))
            );
    }

    getSchedeCompilateByLightPointValues(values: any) {
        return this.getLightPointByAdvancedFilter(values).pipe(
            switchMap(lightPoints => this.getSchedeCompilateByLightPoint(lightPoints))
        )
    }

    getSchedeCompilateByLightPoint(lightPoints: PuntiLuceParse[]) {
        if (arrayIsSet(lightPoints)) {
            const querySchedaManutenzioneCompilataPuntiLuce = new SchedaManutenzioneCompilataParse().query;
            querySchedaManutenzioneCompilataPuntiLuce.equalTo("progetto", this.project);
            querySchedaManutenzioneCompilataPuntiLuce.containedIn("puntoLuce", lightPoints);

            const querySchedaManutenzioneCompilataParseSegnalazione = new SchedaManutenzioneCompilataParse().query;
            querySchedaManutenzioneCompilataParseSegnalazione.equalTo("progetto", this.project);

            const innerQuerySegnalazione = new SegnalazioniParse().query;
            innerQuerySegnalazione.equalTo("progetto", this.project);
            innerQuerySegnalazione.containedIn('puntiLuce', lightPoints);
            querySchedaManutenzioneCompilataParseSegnalazione.matchesQuery('segnalazione', innerQuerySegnalazione);

            const queryTotale = Parse.Query.or(querySchedaManutenzioneCompilataPuntiLuce, querySchedaManutenzioneCompilataParseSegnalazione);
            return fromPromise(queryTotale.find())
        } else {
            return of([])
        }
    }

    getSchedeCompilateByCircuito(circuits: CircuitiParse[]) {
        if (arrayIsSet(circuits)) {
            const querySchedaManutenzioneCompilataPuntiLuce = new SchedaManutenzioneCompilataParse().query;
            querySchedaManutenzioneCompilataPuntiLuce.equalTo("progetto", this.project);
            querySchedaManutenzioneCompilataPuntiLuce.containedIn("circuito", circuits);

            const querySchedaManutenzioneCompilataParseSegnalazione = new SchedaManutenzioneCompilataParse().query;
            querySchedaManutenzioneCompilataParseSegnalazione.equalTo("progetto", this.project);

            const innerQuerySegnalazione = new SegnalazioniParse().query;
            innerQuerySegnalazione.equalTo("progetto", this.project);
            innerQuerySegnalazione.containedIn('circuito', circuits);
            querySchedaManutenzioneCompilataParseSegnalazione.matchesQuery('segnalazione', innerQuerySegnalazione);

            const queryTotale = Parse.Query.or(querySchedaManutenzioneCompilataPuntiLuce, querySchedaManutenzioneCompilataParseSegnalazione);
            return fromPromise(queryTotale.find())
        } else {
            return of([])
        }
    }


    getSchedeByElementParse(elementParse: any): Observable<SchedaManutenzioneCompilataParse[]> {
        if (elementParse == null || elementParse.className == null) {
            return of([])
        } else if (elementParse.className == className.puntiLuce) {
            return this.getSchedeCompilateByLightPoint([elementParse]);
        } else if (elementParse.className == className.circuiti) {
            return this.getSchedeCompilateByCircuito([elementParse]);
        } else {
            return of([])
        }
    }

    /*
    *
    * http://maps.google.com/maps?saddr=38,38&daddr=39,39
    * https://www.google.com/maps/dir/?api=1&origin=38.9507725,16.3270095&destination=38.9622298,16.3118771,18&travelmode=driving
    *
    * */

    private getIndexInArrayUsers(usersWithPosition: {
        user,
        compiledBy: number [],
        rejectBy: number []
    }[], user): number {
        const users = usersWithPosition.map(item => item.user);
        return users.findIndex(userInArray => userInArray.id == user.id)
    }

    getUserDetailSchede(schedeManutenzioneCompilateDaApprovare: SchedaManutenzioneCompilataParse[]) {
        const usersWithPosition: { user, compiledBy: number [], rejectBy: number [] }[] = [];
        schedeManutenzioneCompilateDaApprovare.forEach((schedaManutenzioneCompilata, indexSchedaCompilata) => {
            if (schedaManutenzioneCompilata.compiledBy) {
                const user = schedaManutenzioneCompilata.compiledBy;
                const index = this.getIndexInArrayUsers(usersWithPosition, user)
                if (index < 0) {
                    const userWithPosition = {
                        user: user,
                        compiledBy: [indexSchedaCompilata],
                        rejectBy: []
                    }
                    usersWithPosition.push(userWithPosition);
                } else {
                    usersWithPosition[index].compiledBy.push(indexSchedaCompilata)
                }

            }
            if (schedaManutenzioneCompilata.rejectedBy) {
                const user = schedaManutenzioneCompilata.rejectedBy;
                const index = this.getIndexInArrayUsers(usersWithPosition, user)
                if (index < 0) {
                    const userWithPosition = {
                        user: user,
                        compiledBy: [],
                        rejectBy: [indexSchedaCompilata]
                    }
                    usersWithPosition.push(userWithPosition);
                } else {
                    usersWithPosition[index].rejectBy.push(indexSchedaCompilata)
                }
            }
        })
        if (!arrayIsSet(usersWithPosition)) {
            return of(schedeManutenzioneCompilateDaApprovare)
        } else {
            return this.userService.getUserDetail(usersWithPosition.map(item => item.user.id)).pipe(
                map(detailUser => {
                    detailUser.forEach(user => {
                        const index = this.getIndexInArrayUsers(usersWithPosition, user)
                        if (index >= 0) {
                            usersWithPosition[index].compiledBy.forEach(indexSchedeCompilate => {
                                schedeManutenzioneCompilateDaApprovare[indexSchedeCompilate].compiledBy = user;
                            })
                            usersWithPosition[index].rejectBy.forEach(indexSchedeCompilate => {
                                schedeManutenzioneCompilateDaApprovare[indexSchedeCompilate].rejectedBy = user;
                            })
                        }
                    })
                    return schedeManutenzioneCompilateDaApprovare;
                })
            )
        }
    }

    public saveLocalObject(event: {
        elementToSave: any,
        valuesSchedaManutenzione: any,
        keys: formFiledMaintenanceFormContolName[],
        maintenanceScheduleReference: SchedaManutenzioneParse,
        saveOnDatabase: boolean
    }): Observable<{
        error?,
        valuesForm: { traduction: string, formControlName: string, type: typeFormFieldMaintenance, value: any }[],
        schedamanutenzioneId: string,
        progress: number,
        finished: boolean
    }> {
        return new Observable(subscriber => {
            const today = getToday();
            const valuesForm = [];
            const filesForm = [];
            const schedamanutenzioneId = event.maintenanceScheduleReference.objectId;
            const element = event.elementToSave;
            event.keys.forEach(key => {
                if (event.valuesSchedaManutenzione[key.formControlName] != null) {
                    if (key.type === typeFormFieldMaintenance.image) {
                        const files = event.valuesSchedaManutenzione[key.formControlName].file
                        const filesNotCreated = files.filter(fileToSave => stringIsSet(fileToSave.image))
                        if (arrayIsSet(filesNotCreated)) {
                            const obj = {
                                traduction: key.traduction,
                                value: filesNotCreated,
                                formControlName: key.formControlName,
                                type: key.type
                            };
                            filesForm.push(obj)
                        } else {
                            const obj = {
                                traduction: key.traduction,
                                value: files,
                                formControlName: key.formControlName,
                                type: key.type
                            };
                            valuesForm.push(obj)
                        }
                    } else {
                        const obj = {
                            traduction: key.traduction,
                            value: event.valuesSchedaManutenzione[key.formControlName],
                            formControlName: key.formControlName,
                            type: key.type
                        };
                        valuesForm.push(obj);
                    }
                }
            })
            if (arrayIsSet(filesForm)) {
                this.createTemporaryFileByFormValues(filesForm).subscribe(val => {
                    subscriber.next({
                        valuesForm: undefined,
                        schedamanutenzioneId,
                        progress: val.progress,
                        finished: val.finished
                    });
                    if (val.item) {
                        const temporaryFileReducedToLocalSave = val.item.value.map(file => {
                            return {className: file.className, objectId: file.objectId}
                        })
                        valuesForm.push({
                            traduction: val.item.traduction,
                            value: temporaryFileReducedToLocalSave,
                            type: val.item.type,
                            formControlName: val.item.formControlName
                        });
                    }
                    if (val.finished) {
                        if (arrayIsSet(valuesForm) && stringIsSet(schedamanutenzioneId)) {
                            this.localStorageService.pushOrSubstitionSchedaCompilata({
                                schedamanutenzioneId,
                                element,
                                valuesForm,
                                createdAt: today.getTime()
                            });
                        } else {
                            this.localStorageService.destroySchedaManutenzionePrecompilataByElementIdSchedamanutenzioneId(schedamanutenzioneId, element);
                        }
                        subscriber.next({
                            valuesForm,
                            schedamanutenzioneId,
                            progress: val.progress,
                            finished: val.finished
                        });
                        subscriber.complete();
                        subscriber.unsubscribe();

                    }
                }, error => {
                    subscriber.next({
                        error,
                        finished: true,
                        valuesForm: undefined,
                        progress: 100,
                        schedamanutenzioneId
                    });
                    subscriber.complete();
                    subscriber.unsubscribe();
                })
            } else {
                if (arrayIsSet(valuesForm) && stringIsSet(schedamanutenzioneId)) {
                    this.localStorageService.pushOrSubstitionSchedaCompilata({
                        schedamanutenzioneId,
                        valuesForm,
                        element,
                        createdAt: today.getTime()
                    });
                } else {
                    this.localStorageService.destroySchedaManutenzionePrecompilataByElementIdSchedamanutenzioneId(schedamanutenzioneId, element);
                }
                subscriber.next({valuesForm, schedamanutenzioneId, progress: 100, finished: true});
                subscriber.complete();
                subscriber.unsubscribe();
            }
        })
    }

    public getCastValueToSaveByKeys(valuesForm: { [k: string]: any }, keys: formFiledMaintenanceFormContolName[]): {
        traduction: string,
        value: any
    }[] {
        return Object.keys(valuesForm)
            .filter(key => valuesForm[key] != null)
            .map(key => {
                let values = valuesForm[key];
                let traduction = key;
                const keyVal = keys.find(keyWithType => keyWithType.formControlName === key)
                if (keyVal != null) {
                    if (keyVal.type == typeFormFieldMaintenance.image && arrayIsSet(values.file)) {
                        values = values.file.map(v => {
                            return {
                                className: v.className,
                                objectId: v.objectId
                            }
                        })
                    }
                    traduction = keyVal.traduction;
                }
                return {traduction, value: values}
            })
    }

    private maxDateExpiredSchedaPrecompilata = 2;

    public getLocalSchedaPrecompilata$(schedaManutenzioneId, element: {
        className: string,
        objectId: string
    } | null = null): Observable<{
        valuesForm: any,
        scehdaManutenzioneId: string,
        element: { className: string, objectId: string }
    }> {
        let toFetch = [];
        const localValuesForm = this.localStorageService.getSchedaManutenzionePrecompilataBySchedaManutenzioneIdElementId(schedaManutenzioneId, element);
        if (localValuesForm == null || localValuesForm.schedamanutenzioneId != schedaManutenzioneId || !arrayIsSet(localValuesForm.valuesForm) || localValuesForm.element == null) {
            return of(null);
        }
        const createdAt = new Date(localValuesForm.createdAt);
        const difference = getDifferenceDateInDay(createdAt, getToday());
        if (difference > this.maxDateExpiredSchedaPrecompilata) {
            this.localStorageService.destroySchedaManutenzionePrecompilataByElementIdSchedamanutenzioneId(schedaManutenzioneId, element);
            return of(null);
        }
        if (localValuesForm.element.className !== element.className || localValuesForm.element.objectId !== element.objectId) {
            return of(null);
        }
        localValuesForm.valuesForm.forEach(form => {
            if (arrayIsSet(form.value)) {
                form.value = form.value.map(file => getParseObjectByClassNameObjectId(file.className, file.objectId))
                toFetch = toFetch.concat(form.value);
            }
        })
        let obj$;
        if (arrayIsSet(toFetch)) {
            obj$ = parseFetchAll$(toFetch).pipe(
                catchError(err => of(null)),
                map(() => {
                    return {
                        valuesForm: localValuesForm.valuesForm,
                        schedaManutenzioneId,
                        element: localValuesForm.element
                    };
                })
            )
        } else {
            obj$ = of({valuesForm: localValuesForm.valuesForm, schedaManutenzioneId, element: localValuesForm.element});
        }
        return obj$;
    }

    getRawValueBozzaSchedaManutenzioneBySchedaManutenzioneId(): BozzaSchedaManutenzioneCompilata[] | undefined {
        const schedePreCompilate = this.localStorageService.schedeManutenzionePrecompilate
        if (arrayIsSet(schedePreCompilate)) {
            return schedePreCompilate.filter(scheda => {
                const createdAt = new Date(scheda.createdAt);
                const difference = getDifferenceDateInDay(createdAt, getToday());
                return difference <= this.maxDateExpiredSchedaPrecompilata;
            });
        } else {
            return undefined;
        }
    }

    public destroyLocalSchedaPrecompilata(schedaManutenzioneId, element: {
        className: string,
        objectId: string
    } | null = null) {
        this.localStorageService.destroySchedaManutenzionePrecompilataByElementIdSchedamanutenzioneId(schedaManutenzioneId, element);
    }

    public destroyLocalSchedaPrecompilataExpiredDate(schedaManutenzioneId, element: {
        className: string,
        objectId: string
    } | null = null) {
        let schedeCompilate = this.localStorageService.schedeManutenzionePrecompilate;
        let schedeCompilateNotExpired;
        if (arrayIsSet(schedeCompilate)) {
            schedeCompilateNotExpired = schedeCompilate.filter(scheda => {
                const createdAt = new Date(scheda.createdAt)
                const difference = getDifferenceDateInDay(createdAt, getToday());
                return difference < this.maxDateExpiredSchedaPrecompilata;
            })
            if (arrayIsSet(schedeCompilateNotExpired)) {
                this.localStorageService.schedeManutenzionePrecompilate = schedeCompilateNotExpired
            } else {
                this.localStorageService.destroySchedaManutenzionePrecompilata();
            }
        }
    }

    public createSchedaManutenzioneCompilataCloud(schedaManutenzioneId: string, formValues: any [], referenceElement: any): Observable<SchedaManutenzioneCompilataParse> {
        const data = {progettoId: this.project.objectId};
        if (schedaManutenzioneId != null) {
            data['schedaManutenzioneId'] = schedaManutenzioneId;
        }
        if (arrayIsSet(formValues)) {
            data['formValues'] = formValues;
        }
        if (referenceElement != null && stringIsSet(referenceElement.className) && stringIsSet(referenceElement.objectId)) {
            data['referenceElement'] = {className: referenceElement.className, objectId: referenceElement.objectId};
        }
        const res = Parse.Cloud.run('createSchedaManutenzioneCompilata', data, {});
        return fromPromise(res)
    }

}

export type filtersSchedeManutenzioneCompilate = {
    key: string,
    operator: operatorFiltersSchedeManutenzioneCompilate,
    value: any
}
export type operatorFiltersSchedeManutenzioneCompilate = '==' | '!=' | '<' | '>' | '>=' | '<=' | 'CONTAINS';

