import {Injectable} from '@angular/core';
import {combineLatest, EMPTY, forkJoin, interval, Observable, of, throwError} from "rxjs";
import {ChipsFromArrayService} from "./chips-from-array.service";
import {DocumentsFolderParse} from "../../models/DocumentsFolder.Parse";
import {ProjectService} from "./project.service";
import {fromPromise} from "rxjs/internal-compatibility";
import {DocumentsFileParse} from "../../models/DocumentsFile.Parse";
import {UtilityParseFileService} from "./utility-parse-file.service";
import {catchError, delay, endWith, expand, map, switchMap, take, takeWhile, toArray} from "rxjs/operators";
import {DialogPopUpService} from "./dialog-pop-up.service";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {DialogPopUpInfoService} from "./dialog-pop-up-info.service";
import {environment} from "../../../environments/environment";
import {FileServiceService} from "./file-service.service";
import * as Parse from "parse";
import {ProgettiParse} from "../../models/Progetti.Parse";
import {jobPossibleStatus, ParseApiService} from "./parse-api.service";
import {UtilsService} from "./utils.service";
import {
    arrayIsSet, className, hunaParseFileName, isNotNullOrUndefined,
    masterFoldersFileManager,
    PaginationParserequestType, paramsApiParse_v2,
    stringIsSet
} from "../../models/Models";

export type emitMenu = 'remove' | 'preview' | 'download' | 'move' | 'rename' | 'map' | 'select';
export const imgExtensions = ['jpeg', 'jfif', 'exif', 'tiff', 'gif', 'bmp', 'png', 'ppm', 'pgm', 'pbm', 'pnm', 'webp', 'hdr', 'heif', 'bat', 'bpg', 'deep', 'drw', 'ecw', 'fits', 'svg', 'jpg']
export type DestroyDocumentsType = {
    destroyed?: { objectId: string }[],
    error?: { objectId: string, error: any }[]
}

@Injectable({
    providedIn: 'root'
})
export class FileManagerService {

    constructor(
        private projectService: ProjectService,
        private popUpService: DialogPopUpService,
        private utilityParseFileService: UtilityParseFileService,
        private popUpInfoService: DialogPopUpInfoService,
        private fileService: FileServiceService,
        private dialog: MatDialog,
        private parseApiService: ParseApiService,
        private chipsFromArrayService: ChipsFromArrayService,
        private utilsService: UtilsService) {
    }


    public get projectName() {
        const name = (isNotNullOrUndefined(this.projectService.actualProject) && isNotNullOrUndefined(this.projectService.actualProject.name)) ? this.projectService.actualProject.name : 'home';
        return name;
    }

    public get project() {
        const progetto = new ProgettiParse()
        progetto.objectId = this.projectService.getProjectLocal()
        return progetto;
    }

    isEquals(value1, value2) {
        return this.chipsFromArrayService.isEquals(value1, value2)
    }


    createNewFolder(name, parentFolder): Observable<DocumentsFolderParse> {
        const folder = new DocumentsFolderParse();
        folder.name = name;
        folder.progetto = this.projectService.actualProject;
        if (parentFolder) {
            folder.parentFolder = parentFolder;
        }
        return fromPromise(folder.save())
    }

    getExtensionByName(name: string) {
        return this.utilsService.getExtensionFileFromFileName(name);
    }

    createNewFileAndConnectToElement(name, extension, file, parentFolder: DocumentsFolderParse, puntoLuceIds, circuitoIds, caricoEsogenoIds): Observable<DocumentsFileParse> {
        // documentsFile.progetto = ;
        let parentFolderId;
        if (parentFolder) {
            parentFolderId = parentFolder.objectId
        }


        return this.fileService.getUint8Array$(file).pipe(
            switchMap((uintFile) => {
                const params = paramsApiParse_v2({
                    progettoId: this.projectService.actualProject.objectId,
                    name: hunaParseFileName(name),
                    file: uintFile,
                    extension,
                    parentFolderId,
                    puntoLuceIds,
                    circuitoIds,
                    caricoEsogenoIds
                });
                return fromPromise(Parse.Cloud.run('createNewFileAndConnectToElements', params));
            })
        )

        return EMPTY
        // return fromPromise(documentsFile.save())
    }

    createNewFilesAndConnectToElements(files: File[], parentFolder: DocumentsFolderParse, elements: any): Observable<DocumentsFileParse[]> {
        // documentsFile.progetto = ;
        let parentFolderId;
        if (parentFolder) {
            parentFolderId = parentFolder.objectId
        }


        let puntoLuceIds;
        let circuitoIds;
        let caricoEsogenoIds;
        if (arrayIsSet(elements)) {
            const classNameObj = elements[0].className;
            if (classNameObj == className.puntiLuce) {
                puntoLuceIds = elements.map(pl => pl.objectId);
            } else if (classNameObj == className.circuiti) {
                circuitoIds = elements.map(c => c.objectId);
            } else if (classNameObj == className.caricoEsogeno) {
                caricoEsogenoIds = elements.map(c => c.objectId);
            }
        }

        return forkJoin(files.map(file => this.fileService.getUint8Array$(file)))
            .pipe(
                map(uintFiles => {
                    return files.map((file, index) => {
                        const name = hunaParseFileName(file.name);
                        const extension = this.getExtensionByName(name)
                        return {
                            name,
                            extension,
                            data: uintFiles[index]
                        }
                    })
                }),
                switchMap((uintFiles) => {
                    const params = paramsApiParse_v2({
                        progettoId: this.projectService.actualProject.objectId,
                        files: uintFiles,
                        parentFolderId,
                        puntoLuceIds,
                        circuitoIds,
                        caricoEsogenoIds
                    });
                    return fromPromise(Parse.Cloud.run('createNewFilesAndConnectToElements', params))
                })
            )


        return EMPTY;
        // return fromPromise(documentsFile.save())
    }

    updateFile(file: DocumentsFileParse, value: {}, unsetKeys: string[] = []): Observable<DocumentsFileParse> {
        Object.keys(value).forEach(
            key => {
                if (isNotNullOrUndefined(value[key])) {
                    file[key] = value[key];
                }
            }
        )
        unsetKeys.forEach(key => {
            file.unset(key);
        });
        return fromPromise(file.save());
    }

    updateFolders(folders: DocumentsFolderParse, value: {}, unsetKeys: string[] = []): Observable<DocumentsFolderParse> {
        Object.keys(value).forEach(
            key => {
                if (isNotNullOrUndefined(value[key])) {
                    folders[key] = value[key];
                }
            }
        )
        unsetKeys.forEach(key => {
            folders.unset(key);
        });
        return fromPromise(folders.save());
    }


    connectFileToElement(element: any, documentFile: DocumentsFileParse,): Observable<DocumentsFileParse> {
        const elementClassName = element.className;
        const elementId = element.objectId;
        const fileId = documentFile.objectId;
        const progettoId = this.project.objectId;
        const res = fromPromise(
            Parse.Cloud.run('associateFileToElement', {elementClassName, elementId, fileId, progettoId})
        )
        return res;
    }

    connectFilesToElement(element: any, documentFiles: DocumentsFileParse[],): Observable<{
        notSaved: DocumentsFileParse[]
    }> {
        const elementClassName = element.className;
        const elementId = element.objectId;
        const fileIds = documentFiles.map(documentFile => documentFile.objectId);
        const params = paramsApiParse_v2({elementClassName, elementId, fileIds});
        const res = fromPromise(
            Parse.Cloud.run('associateFilesToElement', params)
        )
        return res;
    }

    createNewFilesAndConnectToElement(files: File[], element): Observable<PaginationParserequestType<DocumentsFileParse>> {

        return this.createNewFilesPaginate(files, null, 1).pipe(
            switchMap(response => {
                    if (arrayIsSet(response.items)) {
                        return this.connectFileToElement(element, response.items[0]).pipe(
                            map(() => response)
                        )
                    } else {
                        return of(response)
                    }
                }
            )
        )
    }

    createNewFiles(files: File[], parentFolder, puntoLuceIds, circuitoIds, arredoUrbanoIds): Observable<DocumentsFileParse[]> {
        const newDocumentsFile: Observable<DocumentsFileParse> [] = [];
        files.forEach(file => {
            const name = file.name;
            const indexPoint = name.lastIndexOf('.');
            const extension = (indexPoint >= 0) ? name.substring(indexPoint + 1, name.length).toLocaleLowerCase() : null;
            newDocumentsFile.push(this.createNewFileAndConnectToElement(name, extension, file, parentFolder, puntoLuceIds, circuitoIds, arredoUrbanoIds))
        })
        return combineLatest(newDocumentsFile)
    }

    createNewFilesPaginate(files: File[], parentFolder, numberElementForCycle = 1, elements: any[] = undefined): Observable<PaginationParserequestType<DocumentsFileParse>> {
        const lengthArray = files.length;

        let puntoLuceIds;
        let circuitoIds;
        let caricoEsogenoIds;
        if (arrayIsSet(elements)) {
            const classNameObj = elements[0].className;
            if (classNameObj == className.puntiLuce) {
                puntoLuceIds = elements.map(pl => pl.objectId);
            } else if (classNameObj == className.circuiti) {
                circuitoIds = elements.map(c => c.objectId);
            } else if (classNameObj == className.caricoEsogeno) {
                caricoEsogenoIds = elements.map(c => c.objectId);
            }
        }

        const fetchPage: (page: number, numberElementForCycle: number) => Observable<PaginationParserequestType<DocumentsFileParse>> = (page = 0, numberElementForCycle = 1) => {
            const lastElement = (page * numberElementForCycle < lengthArray) ? page * numberElementForCycle + numberElementForCycle : lengthArray;
            const progress = Math.ceil(lastElement / lengthArray * 100)
            const fetchFiles = files
                .slice(page * numberElementForCycle, lastElement)
            const paginationArray = fetchFiles
                .map(file => {
                    const name = file.name;
                    let extension = this.utilsService.getExtensionFormMimetype(file.type);
                    if (!stringIsSet(extension)) {
                        extension = this.utilsService.getExtensionFileFromFileName(file.name)
                    }
                    return this.createNewFileAndConnectToElement(name, extension, file, parentFolder, puntoLuceIds, circuitoIds, caricoEsogenoIds)
                });
            return forkJoin(paginationArray).pipe(
                map((files) => {
                    const isLast = lastElement >= lengthArray;
                    return {items: files, progress, nextPage: isLast ? undefined : (page += 1), finished: isLast}
                }),
                catchError((error, caught) => {
                    const isLast = lastElement >= lengthArray;
                    return of({
                        items: [],
                        progress,
                        nextPage: isLast ? undefined : (page += 1),
                        finished: isLast,
                        error: {files: fetchFiles, error}
                    })
                })
            );
        }
        return fetchPage(0, numberElementForCycle).pipe(
            expand(({nextPage}) => (nextPage < lengthArray) ? fetchPage(nextPage, 1) : EMPTY)
        )
    }


    getAllFoldersInFolder(parentFolder, includeMasterFolder = true): Observable<DocumentsFolderParse[]> {
        let parentFolderId;
        let progettoId;
        if (parentFolder) {
            parentFolderId = parentFolder.objectId;
        } else {
            progettoId = this.project.objectId;
        }
        const params = paramsApiParse_v2({progettoId, parentFolderId, includeMasterFolder});
        return fromPromise(Parse.Cloud.run('getAllDocumentFolders', params))
    }

    getAllFilesInFolder(parentFolder): Observable<DocumentsFileParse[]> {
        let parentFolderId;
        let progettoId;
        if (parentFolder) {
            parentFolderId = parentFolder.objectId;
        } else {
            progettoId = this.project.objectId;
        }
        const params = paramsApiParse_v2({progettoId, parentFolderId});
        return fromPromise(Parse.Cloud.run('getAllDocumentFiles', params))
    }

    deleteFolder(folder: DocumentsFolderParse) {
        const folderId = folder.objectId
        const params = paramsApiParse_v2({
            folderId
        });
        return fromPromise(Parse.Cloud.run('deleteFolder', params))
    }

    deleteFile(file: DocumentsFileParse) {
        const fileId = file.objectId
        const params = paramsApiParse_v2({
            fileId
        });
        return fromPromise(Parse.Cloud.run('deleteFile', params))
    }

    deleteFiles(files: DocumentsFileParse[]): Observable<DestroyDocumentsType> {
        const fileIds = files.map(file => file.objectId);
        const params = paramsApiParse_v2({
            fileIds
        });
        return fromPromise(Parse.Cloud.run('deleteFiles', params))
    }

    deletesFolders(folders: DocumentsFolderParse[]): Observable<DestroyDocumentsType> {
        const folderIds = folders.map(file => file.objectId);
        const params = paramsApiParse_v2({
            folderIds
        });
        return fromPromise(Parse.Cloud.run('deleteFolders', params))
    }

    wordExtensions = ['doc', 'dot', 'wbk', 'docx', 'docm', 'dotx', 'dotm', 'docb']
    excelExtensions = ['xls', 'xlt', 'xlm', 'xlsx', 'xlsm', 'xltx', 'xltm', 'xlsb', 'xla', 'xlam', 'xll', 'xlw']
    powerPointExtensions = ['ppt', 'pot', 'pps', 'pptx', 'pptm', 'potx', 'potm', 'ppam', 'ppsx', 'ppsm', 'sldx', 'sldm']
    pdfExtensions = ['pdf']
    imgExtensions = imgExtensions;

    getTypeFile(file): { type: string, symbol: string } {
        if (!isNotNullOrUndefined(file) || !isNotNullOrUndefined(file.extension) || typeof file.extension !== 'string') {
            return {type: 'other', symbol: undefined}
        } else {
            const extensionLowerCase = file.extension.toLowerCase();
            if (this.wordExtensions.includes(extensionLowerCase)) {
                return {type: 'word', symbol: 'w'}
            } else if (this.excelExtensions.includes(extensionLowerCase)) {
                return {type: 'excel', symbol: 'x'}
            } else if (this.powerPointExtensions.includes(extensionLowerCase)) {
                return {type: 'powerPoint', symbol: 'p'}
            } else if (this.pdfExtensions.includes(extensionLowerCase)) {
                return {type: 'pdf', symbol: 'pdf'}
            } else if (this.imgExtensions.includes(extensionLowerCase)) {
                return {type: 'image', symbol: 'img'};
            } else {
                return {type: extensionLowerCase, symbol: extensionLowerCase};
            }
        }
    }


    getDistinctValuesgetDistinctValues(arrayValues, labels) {
        const distinctValue = {};
        labels.forEach(label => {
            distinctValue[label] = [];
        })
        arrayValues.forEach(value => {
            labels.forEach(label => {
                const valueExtension = (label == 'extension') ? this.getTypeFile(value).symbol : value[label]
                const index = distinctValue[label].findIndex(valueStored => {
                    return this.isEquals(valueExtension, valueStored);
                })
                if (index < 0) {
                    distinctValue[label].push(valueExtension)
                }
            })
        })
        return distinctValue;
    }

    getExtensionsFromSymbol(symbol) {
        if (symbol == 'w') {
            return this.wordExtensions;
        } else if (symbol == 'x') {
            return this.excelExtensions;
        } else if (symbol == 'p') {
            return this.powerPointExtensions;
        } else if (symbol == 'pdf') {
            return this.pdfExtensions;
        } else if (symbol == 'img') {
            return this.imgExtensions;
        } else {
            return [symbol]
        }
    }

    dialogCreateNewFolder(folder: DocumentsFolderParse): Observable<DocumentsFolderParse> {
        return this.popUpService.openCreateUpdateFolder([])
            .pipe(
                switchMap((result => {
                        if (result) {
                            return this.createNewFolder(result.valueForm, folder)
                        } else {
                            return EMPTY
                        }
                    })
                ),
            )
    }

    dialogForDeleteFolder(folder: DocumentsFolderParse, message: string): Observable<DocumentsFolderParse> {
        return this.popUpService.openDialogForDelete(message, 'center').pipe(
            switchMap((result) => {
                if (result) {
                    return this.deleteFolder(folder)
                } else {
                    return EMPTY
                }
            }))
    }

    dialogForDeleteFile(file: DocumentsFileParse, message: string): Observable<DocumentsFileParse> {
        return this.popUpService.openDialogForDelete(message, 'center').pipe(
            switchMap((result) => {
                if (result) {
                    return this.deleteFile(file)
                } else {
                    return EMPTY
                }
            })
        )
    }


    dialogForDeleteFiles(files: DocumentsFileParse[], folders: DocumentsFolderParse[], message: string): Observable<{
        files: DestroyDocumentsType,
        folders: DestroyDocumentsType
    }> {
        return this.popUpService.openDialogForDelete(message, 'center').pipe(
            switchMap((result) => {
                if (result) {
                    let destroyed: Observable<{ files: any, folders: any }> = of({files: undefined, folders: undefined})
                    if (arrayIsSet(files)) {
                        destroyed = destroyed.pipe(
                            switchMap((fileFolder) => this.deleteFiles(files).pipe(
                                map(filesDestroyed => {
                                    return {files: filesDestroyed, folders: fileFolder.folders}
                                })
                            ))
                        )
                    }
                    if (arrayIsSet(folders)) {
                        destroyed = destroyed.pipe(
                            switchMap((fileFolder) => this.deletesFolders(folders).pipe(
                                map(foldersDestroyed => {
                                    return {files: fileFolder.files, folders: foldersDestroyed}
                                })
                            ))
                        )
                    }
                    return destroyed;
                } else {
                    return EMPTY
                }
            })
        )
    }

    destroyPaginate(files: DocumentsFileParse[], folders: DocumentsFolderParse[]): Observable<PaginationParserequestType<any>> {
        let totalElements = []
        if (arrayIsSet(files)) {
            totalElements = totalElements.concat(files);
        }
        if (arrayIsSet(folders)) {
            totalElements = totalElements.concat(folders);
        }
        const fetchPage = (page = 0,) => {
            let isLast = page + 1 >= totalElements.length;
            const progress = Math.ceil(page / totalElements.length * 100)
            const element = totalElements[page];
            const value$ = fromPromise(element.destroy());
            return value$.pipe(
                map((items) => {
                    return {
                        items: [items],
                        progress: progress,
                        nextPage: isLast ? undefined : (page += 1),
                        finished: isLast,
                        error: null
                    };
                }),
                catchError(error => {
                    let name;
                    if (element != null && stringIsSet(element.name)) {
                        name = element.name;
                    }
                    return of({
                        items: undefined,
                        progress: progress,
                        nextPage: isLast ? undefined : (page += 1),
                        finished: isLast,
                        error: {error, name}
                    });
                }),
            );
        }
        return fetchPage(0).pipe(
            expand(({nextPage}) => nextPage ? fetchPage(nextPage) : EMPTY),
        )
    }

    goToLink(url: string) {
        window.open(url, "_blank");
    }

    openFile(file): Observable<any> {
        const type = this.getTypeFile(file).type;
        if (type === 'kmz' || type === 'kml') {
            return this.popUpInfoService.openVisualizedImage(file.url, type)
        } else if (type === 'image') {
            return this.popUpInfoService.openVisualizedImage(file.url, type)
        } else if (type == 'zip') {
            return of({error: 'cantOpen', message: 'cantOpen'});
        } else {
            this.goToLink(environment.urlExternalVisualizedDocument.google + file.url);
            return of(true);
        }
    }


    downloadFile(file: DocumentsFileParse) {
        this.fileService.openLink(file.file.url(), file.name);
    }

    openDeconnectFile(file, message: { deconnect: string, removeToDataBase: string }) {
        return this.popUpService.openDialogForDelete(message.deconnect, 'center')
            .pipe(
                switchMap(deconnect => {
                    if (deconnect) {
                        return this.popUpService.openDialogForDelete(message.removeToDataBase, 'center')
                    } else {
                        return EMPTY;
                    }
                }),
            )
    }

    public associateImageToElement(options: any): Observable<string> {
        if (options != null) {
            const res = Parse.Cloud.run('associateImageInFolderToElements',
                {...options, progettoId: this.project.objectId}, {});
            return fromPromise(res);
        } else {
            return of(null);
        }
    }

    getStatusAssociateImage(jobStatusId: string, maxTentative: number, delaySecond: number): Observable<{
        jobStatus: Parse.Object,
        isLastTentative: boolean
    }> {
        return interval(delaySecond * 1000).pipe(
            switchMap((count) => {
                    return this.parseApiService.getJobInfo$(jobStatusId).pipe(
                        map((jobStatus) => {
                            return {jobStatus: jobStatus, count: count + 1};
                        })
                    )
                }
            ),
            takeWhile(({jobStatus, count}) => {
                return jobStatus.get('finishedAt') == null || jobStatus.get('status') == jobPossibleStatus.running;
            }, true),
            take(maxTentative),
            map(({jobStatus, count}) => {
                return {jobStatus, isLastTentative: count >= maxTentative}
            })
        )
    }


    updateFoldersPaginate(folders: DocumentsFolderParse[], parentFolder: DocumentsFolderParse): Observable<PaginationParserequestType<DocumentsFolderParse>> {
        const fetchPage = (page = 0,) => {
            let isLast = page + 1 >= folders.length;
            const progress = Math.ceil(page / folders.length * 100)
            const f = folders[page];
            let value$;
            if (parentFolder) {
                value$ = this.updateFolders(f, {parentFolder})
            } else {
                value$ = this.updateFolders(f, {}, ['parentFolder']);
            }
            return value$.pipe(
                map((items) => {
                    return {
                        items: [items],
                        progress: progress,
                        nextPage: isLast ? undefined : (page += 1),
                        finished: isLast,
                        error: null
                    };
                }),
                catchError(error => {
                    return of({
                        items: undefined,
                        progress: progress,
                        nextPage: isLast ? undefined : (page += 1),
                        finished: isLast,
                        error: error
                    });
                }),
            );
        }
        return fetchPage(0).pipe(
            expand(({nextPage}) => nextPage ? fetchPage(nextPage) : EMPTY),
        )
    }

    updateFilesPaginate(files: DocumentsFileParse[], parentFolder: DocumentsFolderParse): Observable<PaginationParserequestType<DocumentsFileParse>> {
        const fetchPage = (page = 0,) => {
            let isLast = page + 1 >= files.length;
            const progress = Math.ceil(page / files.length * 100)
            const f = files[page];
            let value$;
            if (parentFolder) {
                value$ = this.updateFile(f, {parentFolder})
            } else {
                value$ = this.updateFile(f, {}, ['parentFolder']);
            }
            return value$.pipe(
                map((items) => {
                    return {
                        items: [items],
                        progress: progress,
                        nextPage: isLast ? undefined : (page += 1),
                        finished: isLast,
                        error: null
                    };
                }),
                catchError(error => {
                    return of({
                        items: undefined,
                        progress: progress,
                        nextPage: isLast ? undefined : (page += 1),
                        finished: isLast,
                        error: error
                    });
                }),
            );
        }
        return fetchPage(0).pipe(
            expand(({nextPage}) => nextPage ? fetchPage(nextPage) : EMPTY),
        )
    }

}
