import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {CircuitLabelService} from "../../../providers/services/circuit-label.service";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {ordinamentoEcampiTraduzioni} from "../../../models/ordinamentoEcampiTraduzioni";
import {isNotNullOrUndefined, stringIsSet} from "../../../models/Models";
import {dataForm} from '../../confirm-delete/select-or-create/select-or-create.component';
import {BehaviorSubject, combineLatest, Subscription} from "rxjs";
import {filter, startWith} from "rxjs/operators";

@Component({
    selector: 'app-circuit-label-marker',
    templateUrl: './circuit-label-marker.component.html',
    styleUrls: ['./circuit-label-marker.component.scss']
})
export class CircuitLabelMarkerComponent implements OnInit, OnDestroy, OnChanges {

    pathForTranslate = "dashboard_sidenav.Circuiti.";
    public labelForm: UntypedFormGroup;
    @Output() fieldLabelCircuitoChange = new EventEmitter();
    @Input() public circuiti;
    private circuitiEmit;
    private circuiti$;
    circuitAutocompleteFields: dataForm[] = [];
    private subscriptions = new Subscription();

    constructor(private circuitLabelService: CircuitLabelService,
                private fb: UntypedFormBuilder,
    ) {
        this.circuitiEmit = new BehaviorSubject(this.circuiti);
        this.circuiti$ = this.circuitiEmit.asObservable();
        const valueForm = {};
        valueForm['label'] = [{value: this.circuitLabelService.campoLabel, disabled: false}];
        this.labelForm = this.fb.group(valueForm);
        let campiSelectTemp = [];
        Object.keys(this.filterOrdinamento.Circuiti).forEach((key) => {
            if (key != 'location') {
                campiSelectTemp.push({
                    key: key,
                    sortingValue: ordinamentoEcampiTraduzioni.Circuiti[key].sortingValue
                });
            }
        });
        campiSelectTemp.sort((a, b) => a.sortingValue - b.sortingValue);
        this.circuitAutocompleteFields = campiSelectTemp.map(({key}) => ({
            traduction: this.pathForTranslate + key + '.title',
            html: key,
            valueForm: key,
        }));
        this.subscriptions.add(
            combineLatest([
                this.labelForm.get('label').valueChanges.pipe(
                    startWith(this.labelForm.get('label').value),
                    filter(key => {
                        let index = 0;
                        if (stringIsSet(key)) {
                            index = this.circuitAutocompleteFields.findIndex(v => (v.valueForm == key || v.html == key))
                        }
                        return index >= 0
                    })
                ),
                this.circuiti$
            ])
                .subscribe(values => {
                    const key = values[0]
                    const circuiti = values[1]
                    this.circuitLabelService.calcNumeroElementiPerValore(key, circuiti);
                    this.fieldLabelCircuitoChange.emit(key);
                })
        )
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.circuiti) {
            this.circuitiEmit.next(this.circuiti)
        }
    }

    get rowHeight() {
        const labelField = this.labelForm.get('label').value;
        if (isNotNullOrUndefined(labelField) && typeof labelField === 'string' && labelField.toUpperCase() == 'INDIRIZZO') {
            return '4rem';
        } else {
            return '2rem';
        }
    }

    unsteValue() {
        this.labelForm.get('label').reset();
    }

    private get filterOrdinamento(): {
        PuntiLuce,
        PictureCameraPoint,
        Circuiti
    } {
        let newObject: {
            PuntiLuce,
            PictureCameraPoint,
            Circuiti
        } = {
            PuntiLuce: null,
            PictureCameraPoint: null,
            Circuiti: null
        };
        Object.keys(ordinamentoEcampiTraduzioni).forEach((classe) => {
            let field = {};
            Object.keys(ordinamentoEcampiTraduzioni[classe]).forEach((key) => {
                if (ordinamentoEcampiTraduzioni[classe][key].showInForm || ordinamentoEcampiTraduzioni[classe][key].showInLabel) {
                    field[key] = ordinamentoEcampiTraduzioni[classe][key];
                }
            });
            newObject[classe] = field;
        });
        return newObject;
    }

    ngOnInit(): void {
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    get numeroElementiPerValore() {
        return this.circuitLabelService.numeroElementiPerValore
    }

    selectCircuitLabel(e: { formValues: dataForm | null }) {
        const value = e.formValues?.valueForm ?? null;
        if (value === null) {
            this.unsteValue();
        }
        this.circuitLabelService.calcNumeroElementiPerValore(value, this.circuiti);
        this.fieldLabelCircuitoChange.emit(value);
    }
}
