import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Pipe({
    name: 'translateByPredicateFunction'
})
export class TranslateByPredicateFunctionPipe implements PipeTransform {
    constructor(private translateService: TranslateService) {
    }

    transform(value: string, predicateFunction: (a, ...args) => { traduction: string, notTraduction: string }, ...args): Observable<string> {
        return this.translateService.onLangChange.pipe(
            startWith({lang: this.translateService.currentLang}),
            map(lang => {
                const predicateValue = predicateFunction(value, ...args);
                if (typeof predicateValue.traduction === 'string') {
                    return this.translateService.instant(predicateValue.traduction);
                } else if (typeof predicateValue.notTraduction === 'string') {
                    return predicateValue.notTraduction;
                } else {
                    return this.translateService.instant('NaN');
                }
            })
        );
    }
}
