<mat-card>
    <ng-container
            *ngIf="detailsScheda;else periodo">
        <ng-container *ngIf=" detailsScheda.lastMaintenanceDate">
            <div style="font-weight: bold;"
                 class="spacer-width-primary-text ">
                <mat-icon color="accent" style="margin-right: 10px">calendar_today</mat-icon>
                <span>{{'scheduleMaintenance.completedOn'|translate}}</span>
            </div>
            <div class="spacer-width-text-secondary-text">
                {{detailsScheda.lastMaintenanceDate|customDate:'mediumDate'|async}}
            </div>
        </ng-container>
        <ng-container
                *ngIf=" detailsScheda.compiledBy &&(detailsScheda.compiledBy.nome||detailsScheda.compiledBy.cognome)">
            <div style="font-weight: bold"
                 class="spacer-width-primary-text"
            >
                <mat-icon color="accent" style="margin-right: 10px">person</mat-icon>
                <span>{{"by"|translate}}</span>

            </div>
            <div class="spacer-width-text-secondary-text">
                {{detailsScheda.compiledBy.nome}} {{detailsScheda.compiledBy.cognome}}
            </div>
        </ng-container>
        <ng-container *ngIf="detailsScheda.nextMaintenanceDate">
            <div style="font-weight: bold"
                 class="spacer-width-primary-text"
            >
                <mat-icon color="accent" style="margin-right: 10px">today</mat-icon>
                <span>{{"scheduleMaintenance.nextMaintenanceDate"|translate}}</span>

            </div>
            <div class="spacer-width-text-secondary-text">
                {{detailsScheda.nextMaintenanceDate|customDate:'mediumDate'|async}}
            </div>
        </ng-container>
    </ng-container>
    <ng-template #periodo>
        <ng-container *ngIf="schedaManutenzione.periodo">
            <div style="font-weight: bold"
                 class="spacer-width-primary-text"
            >
                <mat-icon color="accent" style="margin-right: 10px">today</mat-icon>
                <span> {{'scheduleMaintenance.periodo'|translate}}</span>

            </div>
            <div class="spacer-width-text-secondary-text">
                {{schedaManutenzione.periodo}} {{'scheduleMaintenance.days'|translate}}
            </div>
        </ng-container>
    </ng-template>
    <ng-container *ngIf="schedaManutenzione.note">
        <div style="font-weight: bold"
             class="spacer-width-primary-text">
            <mat-icon color="accent" style="margin-right: 10px">wysiwyg</mat-icon>
            <span>{{'scheduleMaintenance.note'|translate}}</span>

        </div>
        <div style="white-space: normal;overflow: auto;text-align: start;"
             class="spacer-width-text-secondary-text">
            {{schedaManutenzione.note}}
        </div>
    </ng-container>
</mat-card>
<mat-dialog-actions>
    <button mat-flat-button color="accent" (click)="closeDialog(false)">
        <mat-icon>clear</mat-icon>
    </button>
    <button mat-flat-button color="primary" (click)="closeDialog(true)">
        {{'select'|translate}}
    </button>
</mat-dialog-actions>