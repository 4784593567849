import {Component, Inject, OnInit} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';


@Component({
    selector: 'app-info-plan-dialog',
    templateUrl: './info-plan-dialog.component.html'
})
export class InfoPlanDialogComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<InfoPlanDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { planObjectId: string, userSession: string },
    ) {

    }

    ngOnInit() {

    }

}
