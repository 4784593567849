import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
    OnChanges,
    ChangeDetectorRef,
    SimpleChanges, Output, EventEmitter
} from '@angular/core';
import {CircuitiErrori, PuntiLuceErrori} from "../../../pages-modules/attachments/SubModels";
import {BehaviorSubject, Subject, Subscription} from "rxjs";
import {maxZoomLabelVisualized} from "../../../pages-modules/dashboard/dashboard/dashboard.component";
import {arrayIsSet, DefaultCenterMap, isNotNullOrUndefined, objectIsSet} from "../../../models/Models";
import {GoogleServiceService} from "../../../providers/services/google-service.service";
import {debounceTime} from "rxjs/operators";
import {ClusterType, MarkerType} from "../main-map/main-map.component";
import {IconService} from "../../../providers/services/icon.service";
import {PuntiLuceLabelService} from "../../../providers/services/punti-luce-label.service";

declare var google: any;

@Component({
    selector: 'app-import-file-map',
    templateUrl: './import-file-map.component.html',
    styleUrls: ['./import-file-map.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImportFileMapComponent implements OnInit, OnChanges {

    maxZoomToViusualizedLabel = maxZoomLabelVisualized;
    defaultCenterMap = DefaultCenterMap;
    @Input()
    clusterElement: [{ color: string, element: PuntiLuceErrori[] | CircuitiErrori[] }];
    markerLightPointsEmit = new BehaviorSubject<any>(undefined);
    markerLightPoints$ = this.markerLightPointsEmit.asObservable();
    @Output()
    elementoSelezionato = new EventEmitter();

    @Output()
    mapClick = new Subject();
    @Output() dragEnd = new Subject();

    @Input() moveLightPoint = false;
    maxZoomCluster = 20;
    private map;
    mapChangeEmit = new BehaviorSubject<{
        tiles: google.maps.LatLngBounds[],
        zoom: number,
        center: google.maps.LatLng
    } | undefined>(undefined)
    mapChange$ = this.mapChangeEmit.asObservable();
    public subscriptions = new Subscription();

    constructor(private changeDetector: ChangeDetectorRef,
                private googleService: GoogleServiceService,
                private iconService: IconService,
                private puntiLuceLabelService: PuntiLuceLabelService) {
        this.subscriptions.add(
            this.mapChange$.pipe(
                debounceTime(300)
            ).subscribe(mapChange => {
                this.getElementsInMapWebWorkers(this.clusterElement, mapChange);
            })
        )
    }


    getElementsInMapWebWorkers(clusters: {
        color: string,
        element: PuntiLuceErrori[] | CircuitiErrori[]
    }[], mapChange: {
        tiles: google.maps.LatLngBounds[],
        zoom: number,
        center: google.maps.LatLng
    }) {
        if (typeof Worker !== 'undefined') {
            const worker = new Worker(new URL('../../../providers/webWorkers/calculate-clusters.worker', import.meta.url));
            if (objectIsSet(mapChange)) {
                const tiles = mapChange.tiles.map(tile => {
                    const northEast = tile.getNorthEast();
                    const southWest = tile.getSouthWest();
                    return {
                        northEast: {latitude: northEast.lat(), longitude: northEast.lng()},
                        southWest: {latitude: southWest.lat(), longitude: southWest.lng()},
                        center: {latitude: tile.getCenter().lat(), longitude: tile.getCenter().lng()}
                    }
                })
                const center = {latitude: mapChange.center.lat(), longitude: mapChange.center.lat()};
                const zoom = mapChange.zoom;
                const elements = clusters.reduce((prev, current) => {
                    if (arrayIsSet(current.element)) {
                        prev = prev.concat(current.element.map(l => {
                            l.icon.strokeColor = current.color;
                            l.icon.fillColor = current.color;
                            return l
                        }))
                    }
                    return prev
                }, [])
                worker.postMessage({
                    mapChange: {tiles, center, zoom},
                    lightPoints: elements.map(element => {
                        return {
                            objectId: element.localOrObjectId,
                            location: {latitude: element.location.latitude, longitude: element.location.longitude},
                            icon: element.icon,
                        }
                    })
                });
                worker.onmessage = (a) => {
                    if (arrayIsSet(a.data.elementsInMap) || arrayIsSet(a.data.clustersInMap)) {
                        if (arrayIsSet(a.data.elementsInMap)) {
                            const ids = a.data.elementsInMap.map(idLight => idLight.objectId)
                            a.data.elementsInMap = elements
                                .filter(l => ids.includes(l.localOrObjectId))
                                .map(l => new MarkerType(l, {
                                    objectId: l.localOrObjectId,
                                    labelMarker: this.puntiLuceLabelService.getOnlyLabel(l.positionInFile.toString(), 'white')
                                }))
                        }
                        if (arrayIsSet(a.data.clustersInMap)) {
                            a.data.clustersInMap = this.googleService.splitClustersWithEqualsCenter(a.data.clustersInMap)
                                .map(cluster => {
                                    cluster.icon = this.iconService.getImageClusterLightPoint(cluster.numeroPuntiLuce, cluster.color, 0.5)
                                    return cluster
                                })
                        }
                    }
                    this.markerLightPoints = a.data;

                };


            }
        }
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.clusterElement) {
            this.getElementsInMapWebWorkers(this.clusterElement, this.mapChange);
        }
    }

    public trackRowsPuntiLuce(index: number, puntoLuce): string {
        return puntoLuce.objectId;
    }

    get markerLightPoints() {
        return this.markerLightPointsEmit.value
    }

    set markerLightPoints(event) {
        this.markerLightPointsEmit.next(event)
    }

    get mapChange() {
        return this.mapChangeEmit.value
    }

    set mapChange(event) {
        this.mapChangeEmit.next(event)
    }


    private getGoogleClusterInlineSvg = function (color) {
        var encoded = window.btoa('<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-100 -100 200 200"><defs><g id="a" transform="rotate(45)"><path d="M0 47A47 47 0 0 0 47 0L62 0A62 62 0 0 1 0 62Z" fill-opacity="0.7"/><path d="M0 67A67 67 0 0 0 67 0L81 0A81 81 0 0 1 0 81Z" fill-opacity="0.5"/><path d="M0 86A86 86 0 0 0 86 0L100 0A100 100 0 0 1 0 100Z" fill-opacity="0.3"/></g></defs><g fill="' + color + '"><circle r="42"/><use xlink:href="#a"/><g transform="rotate(120)"><use xlink:href="#a"/></g><g transform="rotate(240)"><use xlink:href="#a"/></g></g></svg>');
        return ('data:image/svg+xml;base64,' + encoded);
    };

    //ritorna l'icona del color, color deve essere del tipocolor esadecimale #AABBCC
    public colorTextCluster(color) {
        let textColor: string = '#060606';
        if (color == '#000000') {
            textColor = '#DDDDDD'
        }
        return [
            {
                width: 70,
                height: 70,
                url: this.getGoogleClusterInlineSvg(color),
                textColor: textColor,
                textSize: 12
            }
        ];
    }

    public clickMarker(markerCliccato: MarkerType) {
        let element = this.getElementByCluster(markerCliccato);
        this.elementoSelezionato.emit(element);
    }

    mapReady(event) {
        this.map = event;
        this.map.setMapTypeId(google.maps.MapTypeId.SATELLITE)
        this.map.setCenter(DefaultCenterMap);
        this.setCenterZoom();
    }

    private setCenterZoom(): void {
        if (isNotNullOrUndefined(this.map)) {
            let bounds = new google.maps.LatLngBounds();
            if (isNotNullOrUndefined(this.map)) {
                Object.keys(this.clusterElement).forEach((key) => {
                    this.clusterElement[key]['element'].forEach((element) => {
                        if (isNotNullOrUndefined(element)) {
                            const loc = new google.maps.LatLng(element.location.latitude, element.location.longitude);
                            bounds.extend(loc);
                        }
                    });
                });
            }
            this.map.fitBounds(bounds);
            this.map.panToBounds(bounds);
        }
        this.changeDetector.detectChanges();
    }

    onMapClick() {
        this.mapClick.next();
    }

    changeZoom() {
        if (this.map.getZoom() > this.maxZoomToViusualizedLabel) {
            this.changeDetector.detectChanges();
        }
    }

    mapBoundChange() {
        const bounds = this.map.getBounds();
        let tiles: google.maps.LatLngBounds[];
        const center = this.map.getCenter();
        const zoom = this.map.getZoom();
        if (this.map.getZoom() > this.maxZoomCluster && this.map.getBounds() != null) {
            tiles = this.googleService.tilesMap(bounds, 0);
        } else if (this.map.getZoom() > (this.maxZoomCluster - 2) && this.map.getBounds() != null) {
            tiles = this.googleService.tilesMap(bounds, 1);
        } else {
            tiles = this.googleService.tilesMap(bounds, 2);
        }
        this.mapChange = {tiles, zoom, center}
    }


    public getLabelMarker(puntoLuce: PuntiLuceErrori) {
        let containedInMap = false;
        if (isNotNullOrUndefined(this.map) && isNotNullOrUndefined(this.map.getBounds())) {
            if (isNotNullOrUndefined(puntoLuce)) {
                const latLng = new google.maps.LatLng(puntoLuce.location.latitude, puntoLuce.location.longitude);
                containedInMap = this.map.getBounds().contains(latLng);
            } else {
                console.log(puntoLuce);
            }
            if (this.map.getZoom() > this.maxZoomToViusualizedLabel && containedInMap) {
                const text = puntoLuce.positionInFile.toString();
                return {
                    color: (this.map.mapTypeId == 'roadmap') ? "black" : 'white',
                    fontWeight: "bold",
                    fontSize: "12px",
                    text: text,
                }
            }
        }
    }

    const

    getElementByCluster(marker: MarkerType) {
        let element;
        this.clusterElement.every(cluster => {
            const index = cluster.element.findIndex(el => el.localOrObjectId === marker.objectId)
            if (index >= 0) {
                element = cluster.element[index];
                return true;
            } else {
                return false;
            }
        })
        return element;
    }

    public dragEndEmit(marker: MarkerType, event) {
        const element = this.getElementByCluster(marker)
        const newCoords = {coords: {lat: event.latLng.lat(), lng: event.latLng.lng()}}
        this.dragEnd.next({
            element,
            newCoords
        })

    }

    getPosition(puntoLuce: PuntiLuceErrori) {
        const position = new google.maps.LatLng(puntoLuce.location.latitude, puntoLuce.location.longitude)
        return position;
    }

    clusterClick(cluster: ClusterType) {
        const center = cluster.center ? cluster.center : cluster.bounds.getCenter();
        const zoom = this.map.getZoom() < 15 ? this.map.getZoom() + 2 : this.map.getZoom() + 1
        this.map.moveCamera({center, zoom})
    }

    protected readonly DefaultCenterMap = DefaultCenterMap;
}
