import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-button-spinner',
    templateUrl: './button-spinner.component.html',
    styleUrls: ['./button-spinner.component.scss']
})
export class ButtonSpinnerComponent implements OnInit {


    @Input()
    loading = false;
    @Input()
    diameter = 20;
    @Input()
    progress;
    @Input()
    color: 'primary' | 'accent' | 'warn' | '' = 'primary';
    @Input()
    type: 'mat-flat-button' | 'mat-raised-button' | 'mat-stroked-button' | 'mat-icon-button' | 'mat-fab' | 'mat-mini-fab' = 'mat-flat-button';

    constructor() {
    }

    ngOnInit(): void {
    }

    get progressIsSet() {
        return this.progress != null
    }

}
