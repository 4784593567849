import {Component, Input, OnInit, Output} from '@angular/core';
import {Subject} from "rxjs";


@Component({
    selector: 'app-box-group-photometries',
    templateUrl: './box-group-photometries.component.html',
    styleUrls: ['./box-group-photometries.component.scss']
})
export class BoxGroupPhotometriesComponent implements OnInit {

    constructor() {
    }

    @Input() name: string
    @Input() photometryNumber;
    @Input() selected: boolean
    @Input() disabled: boolean
    @Input() visualizedMenu: boolean=true;
    @Output() addPhotometriesButton = new Subject();
    @Output() clickCard = new Subject();
    @Output() clickRemove = new Subject();
    @Output() clickUpdate = new Subject();

    ngOnInit(): void {
    }


    confirmAddPhotometries() {
        if (!this.disabled) {
            this.addPhotometriesButton.next(['asdsaf', 'fddsadf'])
        }
    }

    cardClick() {
        if (!this.disabled) {
            this.clickCard.next(['asdsaf', 'fddsadf'])
        }
    }

    remove() {
        if (!this.disabled) {
            this.clickRemove.next(['asdsaf', 'fddsadf'])
        }
    }

    update() {
        if (!this.disabled) {
            this.clickUpdate.next(['asdsaf', 'fddsadf'])
        }
    }


    get badgeColor() {
        return this.selected ? 'menu-select-card' : 'menu-accent-card';
    }

    get badgeSize() {
        const castValue = parseInt(this.photometryNumber);
        return (isNaN(castValue) || castValue < 999) ? 'badge-size-medium' : 'badge-size-large'
    }

    get selectDisable() {
        if (this.disabled) {
            return 'card-disabled'
        } else if (this.selected) {
            return 'menu-select-card';
        } else {
            return ''
        }
    }
}
