import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {isNotNullOrUndefined} from "../../models/Models";

const incr = 1;

@Component({
    selector: 'app-huna-spinner',
    templateUrl: './huna-spinner.component.html',
    styleUrls: ['./huna-spinner.component.scss']
})
export class HunaSpinnerComponent implements OnInit, OnChanges {

    @Input()
    avvia: boolean = false;


    @Input()
    dimension: number;

    @Input()
    instantValue;

    @Input()
    fakeActivies: boolean = false;

    public oldInstantValue;

    get fontSize() {
        if (this.dimension > 80) {
            return 15
        } else {
            return 10
        }
    }


    constructor() {
    }


    public progress = 0;

    ngOnInit() {
    }

    manageProgress() {
        if (this.progress > 90) {
            clearInterval(this.ref);
        } else {
            this.progress = this.progress + incr;
        }
    }

    public ref;

    ngOnChanges(changes) {
        if (isNotNullOrUndefined(changes.instantValue)) {
            this.progress = (changes.instantValue.currentValue > this.progress || !this.fakeActivies) ? changes.instantValue.currentValue : this.progress;
        }
        if (isNotNullOrUndefined(changes.avvia) && changes.avvia && this.fakeActivies) {
            this.progress = 0;
            this.ref = setInterval(() => this.manageProgress(), 200);
        }
        if (this.avvia && !isNotNullOrUndefined(this.ref) && this.fakeActivies) {
            this.ref = setInterval(() => this.manageProgress(), 200);
        }
    }
}
