import {Injectable} from '@angular/core';
import {ParseApiService} from './parse-api.service';
import * as Parse from 'parse';
import {PluginApiService} from '../plugins/plugin-api.service';
import {endpoints} from '../../config/api';
import {UserService} from './user.service';
import {FilterService} from './filter.service';
import {TranslateService} from '@ngx-translate/core';
import {PuntiLuceParse} from "../../models/PuntiLuce.Parse";
import {SegnalazioniParse} from "../../models/Segnalazioni.Parse";
import {fromPromise} from "rxjs/internal-compatibility";
import {BehaviorSubject, Observable} from "rxjs";
import {switchMap} from "rxjs/operators";
import {CircuitiParse} from "../../models/Circuiti.Parse";
import {ProjectService} from "./project.service";
import {arrayIsSet, className, fetchParseObject$} from "../../models/Models";

export const AllarmsCode = {
    'allarmeConsumiAnomali': {codes: [201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213]},
    'allarmeMagnetoTermico1': {codes: [301]},
    'allarmeMagnetoTermico2': {codes: [302]},
    'allarmeMagnetoTermico3': {codes: [303]},
    'allarmeMagnetoTermico4': {codes: [304]},
    'allarmeTeleruttore1': {codes: [310]},
    'allarmeTeleruttore2': {codes: [311]},
    'allarmeTeleruttore3': {codes: [312]},
    'allarmeTeleruttore4': {codes: [313]},
    'allarmeDifferenziale': {codes: [320]},
    'allarmePresenzaRete': {codes: [330]},
    'allarmeCrepuscolare': {codes: [340]},
}

@Injectable({
    providedIn: 'root'
})
export class ReportsService extends ParseApiService {

    constructor(
        private apiService: PluginApiService,
        private userService: UserService,
        private filterService: FilterService,
        protected translateService: TranslateService,
        private projectService: ProjectService
    ) {
        super();
    }


    get reportsInMap() {
        return this.reportsInMapEmit.value
    }

    set reportsInMap(value) {
        this.reportsInMapEmit.next(value)
    }

    private reportsInMapEmit = new BehaviorSubject<string[]>([]);
    reportsInMap$ = this.reportsInMapEmit.asObservable();

    public async richiediInterventoSuSegnalazione(objectId) {
        try {
            return await Parse.Cloud.run('richiediInterventoSuSegnalazione',
                {
                    segnalazioneId: objectId
                }, {});
        } catch (e) {
            throw e;
        }
    }

    public richiediInterventoSuSegnalazioneObs(objectId): Observable<SegnalazioniParse> {
        return fromPromise(this.richiediInterventoSuSegnalazione(objectId))
    }


    public async prendiInCaricoLaSegnalazione(objectId) {
        try {
            return await Parse.Cloud.run('prendiInCaricoLaSegnalazione',
                {
                    segnalazioneId: objectId
                }, {});
        } catch (e) {
            throw e;
        }
    }

    public prendiInCaricoLaSegnalazioneObs(objectId) {
        return fromPromise(Parse.Cloud.run('prendiInCaricoLaSegnalazione',
            {
                segnalazioneId: objectId
            }, {})).pipe(
            switchMap(() => {
                const senglazione = new SegnalazioniParse()
                senglazione.objectId = objectId;
                return fromPromise(senglazione.fetch())
            })
        )
    }

    public assegnaLaSegnalazione(segnalazioneId, userId) {
        return fromPromise(Parse.Cloud.run('assegnaLaSegnalazione',
            {
                segnalazioneId: segnalazioneId,
                userToAssignId: userId
            }, {}))
    }

    public async getReport(reportObjectd) {
        try {
            const res = await fetchParseObject$(className.segnalazioni, reportObjectd).toPromise();
            return res;
        } catch (e) {
            throw e;
        }
    }

    public async deleteReport(reportObjectd) {
        try {
            const Segnalazioni = Parse.Object.extend('Segnalazioni');
            const query = new Parse.Query(Segnalazioni);
            const res = await query.equalTo('objectId', reportObjectd).first({sessionToken: this.userService.sessionToken()});
            return await res.destroy({sessionToken: this.userService.sessionToken()});
        } catch (e) {
            throw e;
        }
    }


    public updateReport(objectId: string, values: { [k: string]: any }) {
        const segnalazione = new SegnalazioniParse()
        segnalazione.objectId = objectId;
        segnalazione.titolo = values.titolo
        segnalazione.descrizione = values.descrizione
        segnalazione.priorita = Number(values.priorita)
        if (values.foto) {
            segnalazione.foto = values.foto;
        }
        segnalazione.privata = values.privata
        segnalazione.tipologiaIntervento = values.tipologiaIntervento
        return fromPromise(segnalazione.save())
    }

    public async closeReport(objectId) {
        try {

            return await Parse.Cloud.run('chiudiSegnalazioneEvasa',
                {
                    segnalazioneId: objectId
                }, {});
            // const Segnalazioni = Parse.Object.extend('Segnalazioni');
            // const query = new Parse.Query(Segnalazioni);
            //
            // query.get(objectId).then(report => {
            //         report.set('stato', 'Chiuso');
            //         report.save();
            //     },
            //     (error) => {
            //         throw new Error(this.translateService.instant('error_report_update') + error.code + ' ' + error.message);
            //     });
        } catch (e) {
            throw e;
        }
    }

    public closeReportObs(objectId): Observable<any> {
        return fromPromise(Parse.Cloud.run('chiudiSegnalazioneEvasa',
            {
                segnalazioneId: objectId
            }, {})
        )
    }

    public async openReport(objectId) {
        try {
            const Segnalazioni = Parse.Object.extend('Segnalazioni');
            const query = new Parse.Query(Segnalazioni);

            query.get(objectId).then(report => {
                    report.set('stato', 'Aperto');
                    report.save(null, {sessionToken: this.userService.sessionToken()});
                },
                (error) => {
                    throw new Error(this.translateService.instant('error_report_update') + error.code + ' ' + error.message);
                });
        } catch (e) {
            throw e;
        }
    }


    public createReport(values, circuitId?, reports?, titolo?): Observable<any> {
        const Segnalazione = Parse.Object.extend('Segnalazioni');
        const report = new Segnalazione();
        if (titolo) {
            report.set('titolo', titolo);
        } else {
            report.set('titolo', values.titolo);
        }
        report.set('descrizione', values.descrizione);
        report.set('priorita', Number(values.priorita));
        if (values.foto) {
            report.set('foto', values.foto);
        }
        report.set('privata', values.privata);
        report.set('tipologiaIntervento', values.tipologiaIntervento);
        report.set('progetto', this.userService.getProject());
        const circuito = new CircuitiParse();
        circuito.objectId = circuitId
        if (circuitId) {
            report.set('circuito', circuito);
        } else {
            let lights = [];
            for (let item of reports) {
                lights.push(this.getLight(item));
            }
            report.set('puntiLuce', lights);
        }
        return fromPromise(report.save(null, {sessionToken: this.userService.sessionToken()}))

    }

    public async createGroup(values, reports?, nome?) {
        try {
            const Group = Parse.Object.extend('GruppiPuntiLuce');
            const group = new Group();
            if (nome) {
                group.set('nome', nome);
            } else {
                group.set('nome', values.nome);
            }
            group.set('progetto', await this.getCurrentProject());
            let relation = group.relation('puntiLuce');
            if (reports) {
                for (let item of reports) {
                    relation.add(this.getLight(item));
                }
            }
            await group.save(null, {sessionToken: this.userService.sessionToken()});
            return group.toJSON();
        } catch (e) {
            throw new Error(this.translateService.instant('error_group_saved') + e.code +
                this.translateService.instant('message') + e.message);
        }

    }

    public async getCurrentProject() {
        try {
            return this.projectService.currentProject
        } catch (e) {
            throw e;
        }
    }

    public getLight(lightObjectd) {
        const puntoLuce = new PuntiLuceParse()
        puntoLuce.objectId = lightObjectd
        return puntoLuce
    }

    public async getCircuit(objectId) {
        try {
            const Project = Parse.Object.extend('Circuiti');
            const query = new Parse.Query(Project);
            return await query.equalTo('objectId', objectId).first({sessionToken: this.userService.sessionToken()});
        } catch (e) {
            return e;
        }
    }

    public async fileLink(plan) {
        return (plan.filePDF) ? plan.filePDF.url : null;
    }

    public async previewLink(objectId, formato, mapType) {
        return endpoints.plans.getPDFReport.replace(':reportId', objectId)
            .replace(':sessionToken', this.userService.sessionToken())
            .replace(':formato', formato)
            .replace(':mapType', mapType);
    }

    public async getReportObject(reportObjectd) {
        try {
            const Segnalazioni = Parse.Object.extend('Segnalazioni');
            const query = new Parse.Query(Segnalazioni);
            return await query.equalTo('objectId', reportObjectd).first({sessionToken: this.userService.sessionToken()});
        } catch (e) {
            throw e;
        }
    }


    addItem(item: PuntiLuceParse) {
        const repors = arrayIsSet(this.reportsInMap) ? [...this.reportsInMap] : []
        repors.push(item.objectId)
        this.reportsInMap = repors
    }

    removeItem(item: PuntiLuceParse) {
        const reports = arrayIsSet(this.reportsInMap) ? [...this.reportsInMap] : []
        reports.splice(reports.indexOf(item.objectId), 1)
        this.reportsInMap = reports
    }

    isReport(item: PuntiLuceParse) {
        return this.reportsInMap.includes(item.objectId);
    }

    clearReportsInMap() {
        this.reportsInMap = [];
    }
}

