import { Component, OnInit, ContentChildren, Input, ContentChild, TemplateRef, OnChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-template-paginator',
  templateUrl: './template-paginator.component.html',
  styleUrls: ['./template-paginator.component.scss']
})
export class TemplatePaginatorComponent implements OnInit, OnChanges {
  @ContentChild(TemplateRef)
  template: TemplateRef<{item: object}>;

  @Input()
  observe: any|undefined = undefined;
  @Input()
  searchEnabled: boolean = true;
  @Input()
  take: number = 10;
  @Input()
  skip: number = 0;
  @Input()
  filter: Filter = { search: "" };
  order: Ordering = {};
  totalCount: number = 0;
  @Input()
  loading = false;
  @Input()
  error = false;
  items: object[] = [];
  @Input()
  dataSource: AsyncDataSource = () => Promise.resolve({ items: [], total: 0 });
  searchForm: UntypedFormGroup;
  searchFormSubscription: Subscription;
  @Input()
  errorKey: string = "paginator.error";
  @Input()
  noResultKey: string = "paginator.no_result_found";

  constructor(
    private formBuilder: UntypedFormBuilder
  ) { }

  onChangePage({pageIndex}: {pageIndex: number}) {
    this.skip = pageIndex * this.take;
    this.update();
  }


  onSortChange({active, direction}: {active: string, direction: OrderingDirection}) {
    this.order = {
      [active]: direction
    };
    this.update();
  }

  ngOnInit() {
    if (this.searchEnabled) {
      this.searchForm = this.formBuilder.group({
        search: "",
      });
      this.searchFormSubscription = this.searchForm
        .get("search")
        .valueChanges.pipe(debounceTime(1000))
        .subscribe((term) => {
          this.onSearch(term);
        });
    }
    this.update();
  }

  ngOnChanges() {
    this.update();
  }

  ngDestroy() {
    if (this.searchFormSubscription) {
      this.searchFormSubscription.unsubscribe();
    }
  }

  onSearch(term: string) {
    this.filter.search = term.toLowerCase();
    this.update();
  }

  async reloadFromDataSource() {
    const { items, total } = await this.dataSource(
      this.filter,
      this.take,
      this.skip,
      this.order
    );
    this.items = items;
    this.totalCount = total;
  }

  async update() {
    try {
      this.error = false;
      this.loading = true;
      await this.reloadFromDataSource();
      if (this.skip > this.totalCount) {
        this.skip = (this.totalCount % this.take) === 0 ?
          Math.max(0, this.totalCount - this.take) :
          this.totalCount - (this.totalCount % this.take);

          await this.reloadFromDataSource();
      }
    } catch (e) {
      this.error = true;
      console.log(e);
      return e;
    } finally {
      this.loading = false;
    }
  }

  getTemplateContext(item: object) {
    return {
      item
    };
  }

  get Math() {
    return Math;
  }
}

export type OrderingDirection = "asc" | "desc" | "";

export type Ordering = { [property: string]: OrderingDirection };

export interface Filter {
  search: string;
}

export interface DataSourceResult {
  items: object[];
  total: number;
}

export type AsyncDataSource = (filter: Filter, take: number, skip: number, order: Ordering) => Promise<DataSourceResult>;
