<mat-dialog-content>
    <div style="padding: 10px 0;" *ngIf="data!=null&&data.message!=null">{{data.message}}</div>
    <form [formGroup]="form" *ngIf="isSetForm|async ">
        <ng-container *ngFor="let tab of htmlData">
            <ng-container *ngFor="let form of tab.forms">
                <app-get-form-fiel-by-configuration-element [type]="form.type"
                                                            [titleTraduction]="form.traduction"
                                                            [possibleValues]="form.possibleValues"
                                                            [formControlName]="form.formControlName">

                </app-get-form-fiel-by-configuration-element>
                <mat-card *ngIf="form.formGroupChild">
                    <ng-container [formGroupName]="form.formGroupChild.formGroupName">
                        <ng-container *ngFor="let subForm of form.formGroupChild.fields">
                            <app-get-form-fiel-by-configuration-element [type]="subForm.type"
                                                                        [titleTraduction]="subForm.traduction"
                                                                        [possibleValues]="subForm.possibleValues"
                                                                        [formControlName]="subForm.formControlName">

                            </app-get-form-fiel-by-configuration-element>
                        </ng-container>
                    </ng-container>
                </mat-card>
            </ng-container>
        </ng-container>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-flat-button color="accent" [mat-dialog-close]="undefined">
        <mat-icon>clear</mat-icon>
    </button>
    <ng-container *ngIf="isSetForm|async">
        <button mat-flat-button color="primary" [disabled]="form.invalid"
                (click)="closeWithValue()">
            <mat-icon>check</mat-icon>
        </button>
    </ng-container>
</mat-dialog-actions>