import {AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {fromEvent, Subject, Subscription} from "rxjs";
import {ChipsFromArrayService} from "../../providers/services/chips-from-array.service";
import {pairwise, startWith} from "rxjs/operators";
import {isNotNullOrUndefined} from "../../models/Models";
import { CdkOverlayOrigin } from '@angular/cdk/overlay';

@Component({
    selector: 'app-multi-select',
    templateUrl: './multi-select.component.html',
    styleUrls: ['./multi-select.component.scss']
})
export class MultiSelectComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

    @Input() selectedItems;
    @Input() allItems;
    @Input() keyToDisplay;
    @Input() keyToForm;
    @Input() keyToTraduction;
    @Input() labelSearch = '';
    @Output() selctedItemsChange = new Subject();

    @ViewChild('trigger') trigger: CdkOverlayOrigin;
    public formGroup: UntypedFormGroup;
    private subscriptions: Subscription[] = [];

    openList = false;
    innerClick = false;
    private filteredList = [];

    private osberver: ResizeObserver;

    searchBarWidth = 0;
    // public typeVisualization: 'onlySearchBar' | 'searchChips' | 'searchChipsList' | 'searchList' = 'onlySearchBar'

    constructor(private fb: UntypedFormBuilder,
                private chipsFromArrayService: ChipsFromArrayService) {
        this.formGroup = this.fb.group({name: '', items: []});
        this.osberver = new ResizeObserver(() => {
            this.updateSearchBarWidth();
        });
        this.subscriptions.push(fromEvent(document.body, 'click').subscribe(() => this.clickOutside()))
    }

    private updateSearchBarWidth() {
        setTimeout(() => {
            this.searchBarWidth = this.trigger?.elementRef?.nativeElement?.offsetWidth ?? 0;
        });
    }

    ngAfterViewInit(): void {
        this.osberver.observe(this.trigger.elementRef.nativeElement);
    }


    arrayIsDifferent(array1: string[], array2: string[]) {
        if (array1.length != array2.length) {
            return true
        } else {
            return array1.findIndex(value => array2.includes(value)) >= 0
        }
    }

    ngOnInit(): void {
        let subscriptionForm;
        subscriptionForm = this.formGroup.get('name').valueChanges.subscribe(
            name => {
                const filteredList = this.allItems.filter(item => {
                    return this.chipsFromArrayService.isIncludes(this.getNameTraduction(item), name)
                })
                    .map(item => this.getUniqueId(item));
                this.filteredList = filteredList
                const item = this.allItems[0];
            }
        )
        this.subscriptions.push(subscriptionForm);
        subscriptionForm = this.formGroup.get('items').valueChanges
            .pipe(
                startWith(undefined),
                pairwise())
            .subscribe(([prev, next]) => {
                    if (Array.isArray(prev) && Array.isArray(next)) {
                        if (this.arrayIsDifferent(prev, next)) {
                            this.emitValueFrom(next)
                        }
                    } else if (!Array.isArray(prev) && Array.isArray(next)) {
                        this.emitValueFrom(next)
                    }
                }
            )
        this.subscriptions.push(subscriptionForm);
    }


    emitValueFrom(objectIds: string[]) {
        const comleteItems = this.allItems
            .filter(
                item => objectIds.includes(this.getUniqueId(item))
            ).map(item => this.getNameItem(item))
        this.selctedItemsChange.next(comleteItems)
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (isNotNullOrUndefined(changes.allItems) && Array.isArray(changes.allItems.currentValue)) {
            if (changes.allItems.currentValue.length > 0) {
                this.allItems = this.allItems.sort((a, b) => {
                    return this.getNameTraduction(a).localeCompare(this.getNameTraduction(b))
                })
                this.formGroup.enable({emitEvent: false})
            } else {
                this.formGroup.disable({emitEvent: false})
                this.filteredList = []
            }
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        })
        this.osberver.disconnect();
    }


    getNameItem(item) {
        return item[this.keyToDisplay]
    }

    getNameTraduction(item) {
        return item != null && typeof item[this.keyToTraduction] == "string" ? item[this.keyToTraduction] : 'N.D.'
    }

    getUniqueId(item) {
        return item[this.keyToForm]
    }

    inSearchIn() {
        this.openList = true;
        if (this.formGroup.get('name')) {
            this.formGroup.get('name').updateValueAndValidity();
        }
    }


    clickIcon() {
        this.openList = !this.openList;
        if (!this.openList) {
            this.formGroup.get('name').setValue('')
        }
    }

    get selectedChips() {
        const items = this.formGroup.get('items').value;
        const itemsIsSet = Array.isArray(items) && items.length > 0
        if (itemsIsSet) {
            return items.map(
                itemId => {
                    const index = this.allItems.findIndex(
                        item => {
                            return item[this.keyToForm] == itemId;
                        }
                    )
                    return this.allItems[index]
                }
            )
        } else {
            return []
        }
    }

    hiddenValue(item) {
        return !this.filteredList.includes(this.getUniqueId(item))
    }

    removeToSelect(item) {
        const items = this.formGroup.get('items').value;
        const itemsIsSet = Array.isArray(items) && items.length > 0
        if (itemsIsSet) {
            const filteredItems = items.filter(itemId => {
                return itemId !== this.getUniqueId(item)
            });
            this.formGroup.get('items').setValue(filteredItems)
        }
    }

    clickOutside() {
        if (this.openList && !this.innerClick) {
            this.clickIcon();
        } else if (this.innerClick) {
            this.innerClick = false;
        }
    }

    setInnerClick() {
        this.innerClick = true;
    }

}
