<mat-sidenav-container fullscreen>
    <mat-sidenav #sidenavProject position="end" class="sidenav-size">
        <app-sidenav-progect *ngIf="progettoSelezionato!=undefined;else newProject"
                             [progetto]="progettoSelezionato"
                             (deleteProgect)="filtraProgetto($event)"
                             (close)="sidenavProject.close()">
        </app-sidenav-progect>
        <ng-template #newProject>
            <app-sidenav-new-project
                    (createNewProgect)="newProjectInput($event)"
                    (close)="sidenavProject.close()">
            </app-sidenav-new-project>
        </ng-template>
    </mat-sidenav>
    <section>
        <div class="container">
            <div class="flex-center-width100"
                 style="justify-content:space-around;flex-flow: row;flex-wrap: wrap">
                <h2>{{'project_select' | translate}}</h2>
                <div style="margin-left: 10px;margin-right: 10px">
                    <form [formGroup]="filterProject" *ngIf="filterProject">
                        <mat-form-field (focusout)="changeColorIcon('')"
                                        (focusin)="changeColorIcon('primary')">
                            <mat-label>
                                {{'filter'|translate}}
                            </mat-label>
                            <input formControlName="name" matInput>
                            <mat-icon matSuffix [color]="colorIconSearch">search</mat-icon>
                        </mat-form-field>
                    </form>
                </div>
            </div>
            <!--    <div style="display: flex; flex-direction: row; align-items: center">-->
            <div class="container-start">
                <div class="container-center" *ngIf="isSetValueFilter()">
                    <div class="flex-item">
                        <!--     possiamo inserire immagine e logo e volendo anche in new project-->
                        <app-box-progect provincia="{{'newProject'|translate}}"
                                         [isNewProject]="true"
                                         (click)="openNewProjectSidenav()"
                        >
                        </app-box-progect>
                    </div>
                </div>
                <ng-container *ngIf="(projects$|async) as projects">
                    <div class="container-center"
                         *ngFor="let project of projects|convertValueByPredicateFunction:filterPredicate:(valueFilter$|async)">
                        <div class="flex-item">
                            <app-box-progect [provincia]="project.name" [image]="project.image"
                                             [numeroCircuito]="project.numeroCircuiti"
                                             (click)="projectApp(project.objectId, null , project)"
                                             [sigla]="project.sigla"
                                             [logo]="project.htmlLogo"
                                             [numeroPuntiLuce]="project.numeroLampade">
                            </app-box-progect>
                            <div class="button-container" style="margin: 3px;">
                                <button mat-mini-fab color="accent" (click)="openForm($event,project)"
                                        *ngIf="project|convertValueByPredicateFunction:isGestorePredicate ">
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </section>
</mat-sidenav-container>