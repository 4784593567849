import {Component, Injectable, Input} from '@angular/core';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {HttpResponse} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AlertService {

    constructor(private snackBar: MatSnackBar) {
    }

    success(message,duration=3000) {
       return  this.snackBar.open(message, 'Ok', {
            duration,
            panelClass: ['snackbar-success']
        }).onAction();
    }

    error(message,duration=3000) {
        return this.snackBar.open(message, 'Ok', {
            duration,
            panelClass: ['snackbar-error']
        }).onAction();
    }

    warning(message,duration=3000) {
        return this.snackBar.open(message, 'Ok', {
            duration,
            panelClass: ['snackbar-warning']
        }).onAction();
    }

    showAlertFromResponse(resp: HttpResponse<any>) {
        switch (resp.status) {
            case 200:
            case 204:
                this.success(resp.headers.get('message'));
                break;
            case 422:
                this.error(resp.headers.get('message'));
                break;
            default:
                this.error(resp.headers.get('message'));
                break;
        }
    }

}

