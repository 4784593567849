import * as Parse from 'parse';
import {ElectricLinesMap} from '../providers/services/map.service';
import {arrayIsSet} from './Models';

export class TemporaryFileParse extends Parse.Object {

    public static CLASS_NAME = 'TemporaryFile';

    // tslint:disable-next-line:variable-name

    constructor() {
        super(TemporaryFileParse.CLASS_NAME);
    }

    public set objectId(value) {
        this.id = value;
    }

    public get objectId(): string {
        return this.id;
    }

    public set ACL(value) {
        super.set('ACL', value);
    }

    public get ACL() {
        return super.get('ACL');
    }

    public set createdAt(value) {
        super.set('createdAt', value);
    }

    public get createdAt(): Date {
        return super.get('createdAt');
    }

    public set updatedAt(value) {
        super.set('updatedAt', value);
    }

    public get updatedAt(): Date {
        return super.get('updatedAt');
    }

    public set extension(value) {
        super.set('extension', value);
    }

    public get extension(): string {
        return super.get('extension');
    }

    public set file(value) {
        super.set('file', value);
    }

    public get file(): Parse.File {
        return super.get('file');
    }

    public set user(value) {
        super.set('user', value);
    }

    public get user(): Parse.User {
        return super.get('user');
    }


    public get query(): Parse.Query<TemporaryFileParse> {
        const query = new Parse.Query(TemporaryFileParse);
        return query;
    }
}

Parse.Object.registerSubclass(TemporaryFileParse.CLASS_NAME, TemporaryFileParse);
