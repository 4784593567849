import {Injectable} from '@angular/core';
import {ParseApiService} from './parse-api.service';
import * as Parse from 'parse';
import {PluginApiService} from '../plugins/plugin-api.service';
import {endpoints} from '../../config/api';
import {UserService} from './user.service';
import {MapService} from './map.service';
import {FilterService} from './filter.service';
import {TranslateService} from '@ngx-translate/core';
import {fromPromise} from "rxjs/internal-compatibility";
import {Observable} from "rxjs";
import {arrayIsSet, isNotNullOrUndefined} from "../../models/Models";

@Injectable({
    providedIn: 'root'
})
export class PlansService extends ParseApiService {

    private resp;
    public currentMap;

    public tipologiaValues = {
        PROJECT: 'project',
        CURRENT: 'state_of_fact'
    };


    constructor(
        private apiService: PluginApiService,
        private userService: UserService,
        private mapService: MapService,
        private filterService: FilterService,
        protected translate: TranslateService
    ) {
        super();
    }

    public async getPlan(planObjectd) {
        try {
            const Planimetrie = Parse.Object.extend('Planimetrie');
            const plan = new Planimetrie();
            plan.set('objectId', planObjectd);
            return (await plan.fetch()).toJSON();
        } catch (e) {
            throw e;
        }
    }

    public async deletePlan(planObjectd) {
        try {
            const Planimetrie = Parse.Object.extend('Planimetrie');
            const query = new Parse.Query(Planimetrie);
            const res = await query.equalTo('objectId', planObjectd).first({sessionToken: this.userService.sessionToken()});
            return await res.destroy({sessionToken: this.userService.sessionToken()});
        } catch (e) {
            throw e;
        }
    }

    public async updatePlan(objectId, value, logoAzienda, logoProgetto, images?) {
        try {
            const Planimetrie = Parse.Object.extend('Planimetrie');
            const plan = new Planimetrie();
            plan.set('objectId', objectId);
            plan.set('nome', value.name);
            plan.set('titolo', value.title);
            plan.set('formato', value.formato);
            plan.set('definitivo', value.definitivo);
            // plan.set('logoAzienda', value.logoAzienda);
            // plan.set('logoProgetto', value.logoProgetto);
            if (logoAzienda) {
                plan.set('logoAzienda', logoAzienda);
            } else {
                plan.unset('logoAzienda');
            }
            if (logoProgetto) {
                plan.set('logoProgetto', logoProgetto);
            } else {
                plan.unset('logoProgetto');
            }
            plan.set('tipologiaMappa', value.mapType);
            plan.set('legenda', value.legenda);
            if (typeof value.tipologia == "string") {
                plan.set('tipologia', value.tipologia);
            } else {
                plan.unset('tipologia');
            }
            plan.set('template', Number(value.template));
            if (value.etichettaPuntoLuce) {
                plan.set('etichettaPuntoLuce', value.etichettaPuntoLuce);
            } else {
                plan.unset('etichettaPuntoLuce');
            }
            if (images.length > 0) {
                if (images[0]) {
                    plan.set('image1', images[0]);
                } else {
                    plan.unset('image1');
                }
                if (images[1]) {
                    plan.set('image2', images[1]);
                } else {
                    plan.unset('image2');
                }
                if (images[2]) {
                    plan.set('image3', images[2]);
                } else {
                    plan.unset('image3');
                }
                if (images[3]) {
                    plan.set('image4', images[3]);
                } else {
                    plan.unset('image4');
                }
            } else {
                plan.unset('image1');
                plan.unset('image2');
                plan.unset('image3');
                plan.unset('image4');
            }
            return await plan.save(null, {sessionToken: this.userService.sessionToken()});
        } catch (e) {
            throw e;
        }
    }

    public async uploadAttachment(objectId, value, csvFile) {
        try {
            const Attachment = Parse.Object.extend('FileDaImportare');
            const query = new Attachment();
            query.set('csvFile', csvFile);
            query.set('progetto', await this.mapService.getCurrentProject());
            query.set('tipoFile', value.tipoFile);
            await query.save(null, {sessionToken: this.userService.sessionToken()});
        } catch (e) {
            throw e;
        }
    }

    public closePlan(planId: string, sessionToken: string) {
        try {
            const resp = this.apiService.put(endpoints.plans.closePlan.replace(':planId', planId).replace(':sessionToken', sessionToken), {}).toPromise();
            return resp;
        } catch (e) {
            throw e;
        }

    }

    public async openPlan(planId: string, sessionToken: string) {
        try {
            const resp = await this.apiService.put(endpoints.plans.openPlan.replace(':planId', planId).replace(':sessionToken', sessionToken), {}).toPromise();
            return resp;
        } catch (e) {
            throw e;
        }
    }


    public async createPlan(title?: string, name?: string, activeFilters?) {


        //Planimetria Magnolie
        try {
            const Planimetria = Parse.Object.extend('Planimetrie');
            const plan = new Planimetria();
            const address = this.filterService.getLocaleAddress();
            let zoom = null;
            let center = null;
            let lat = null;
            let lng = null;
            let filters = null;
            let tipologiaMappa = null;
            if (this.currentMap) {
                tipologiaMappa = this.currentMap.getMapTypeId();
                zoom = this.currentMap.getZoom();
                lat = this.currentMap.getCenter().lat();
                lng = this.currentMap.getCenter().lng();
            }

            if (this.filterService.getLocaleFilter() != false) {
                filters = JSON.stringify(this.filterService.getLocaleFilter());
            }
            plan.set('lng', lng);
            plan.set('lat', lat);
            plan.set('titolo', title);
            plan.set('nome', name);
            plan.set('formato', 'a4');
            plan.set('progetto', await this.mapService.getCurrentProject());
            plan.set('filtri', (filters) ? filters : null);
            if (this.filterService.hasLightPoints()) {
                plan.set('filtriPuntiLuce', JSON.stringify(this.filterService.getLocaleLightPoints()));
            }
            if (this.filterService.hasCircuits()) {
                plan.set('filtriCircuiti', JSON.stringify(this.filterService.getLocaleCircuits()));
            }
            if (this.filterService.hasFiltriObjectIdPuntiLuce()) {
                plan.set('filtriObjectIdPuntiLuce', this.filterService.getLocaleObjectIdLightPoints());
            }
            plan.set('definitivo', false);
            plan.set('tipologiaMappa', (tipologiaMappa) ? tipologiaMappa : null);
            plan.set('indirizzi', (address) ? address : null);
            plan.set('zoom', (zoom) ? zoom : null);
            plan.set('legenda', false);
            await plan.save(null, {sessionToken: this.userService.sessionToken()});
            return plan.toJSON();
        } catch (e) {
            throw new Error(this.translate.instant('services.planimetry_create_error', {
                code: e.code,
                message: e.message
            }));
        }

    }

    public savePlanimetry$(planId: string, zoom: number, center: google.maps.LatLng, objectIdsCircuiti: string[], objectIdsPuntiLuce: string[]) {
        const Planimetrie = Parse.Object.extend('Planimetrie');
        // const query = new Parse.Query(Planimetrie);
        let plan = new Planimetrie()
        plan.id = planId;
        if (arrayIsSet(objectIdsCircuiti)) {
            plan.set('filtriCircuiti', JSON.stringify(objectIdsCircuiti));
        } else {
            plan.unset('filtriCircuiti')
        }
        if (arrayIsSet(objectIdsPuntiLuce)) {
            plan.set('filtriObjectIdPuntiLuce', objectIdsPuntiLuce);
        } else {
            plan.unset('filtriObjectIdPuntiLuce')
        }
        //plan.set('indirizzi', (address) ? address : null);
        plan.set('zoom', zoom);
        plan.set('lat', center.lat());
        plan.set('lng', center.lng());
        return fromPromise(plan.save())
    }

    public async saveFilterOnPlan(planObjectId, objectIdsCircuiti, objectIdsPuntiLuce) {
        try {
            //const address = this.filterService.getLocaleAddress();
            // let filters = null;
            // if (this.filterService.getLocaleFilter() != false) {
            //     filters = JSON.stringify(this.filterService.getLocaleFilter());
            // }
            // const filters = JSON.stringify(this.filterService.getLocaleFilter());
            const Planimetrie = Parse.Object.extend('Planimetrie');
            // const query = new Parse.Query(Planimetrie);
            let plan = new Planimetrie()
            plan.id = planObjectId
            const zoom: number = this.currentMap.getZoom();
            // aggiornamento per la nuova versione
            plan.unset('filtri');
            plan.unset('filtriPuntiLuce');
            if (objectIdsCircuiti != null && objectIdsCircuiti.length > 0) {
                plan.set('filtriCircuiti', JSON.stringify(objectIdsCircuiti));
            } else {
                plan.unset('filtriCircuiti')
            }
            if (objectIdsPuntiLuce != null && objectIdsPuntiLuce.length > 0) {
                plan.set('filtriObjectIdPuntiLuce', objectIdsPuntiLuce);
            } else {
                plan.unset('filtriObjectIdPuntiLuce')
            }
            plan.set('tipologiaMappa', this.currentMap.getMapTypeId());
            //plan.set('indirizzi', (address) ? address : null);
            plan.set('zoom', zoom);
            plan.set('lat', this.currentMap.getCenter().lat());
            plan.set('lng', this.currentMap.getCenter().lng());
            return await plan.save(null, {sessionToken: this.userService.sessionToken()});
        } catch (e) {
            throw new Error(this.translate.instant('services.planimetry_create_error', {
                code: e.code,
                message: e.message
            }));
        }

    }

    public async previewLink(objectId) {
        return endpoints.plans.getPDFPlan.replace(':planId', objectId).replace(':sessionToken', this.userService.sessionToken());
    }


    public async fileLink(plan) {
        return (plan.filePDF) ? plan.filePDF.url : null;
    }

    public setCurrentMap(map) {
        return this.currentMap = map;
    }

    public getTipologiaValues() {
        return this.tipologiaValues;
    }

    public getImageAndLegendForPlanimetry(planId, progettoId, label): Observable<{
        legenda: legendaPlanimetria[],
        planimetria: string
    }> {
        const data = {planId: planId, progettoId: progettoId};
        if (isNotNullOrUndefined(label)) {
            data['label'] = label;
        }
        const response = Parse.Cloud.run('getPlanMapImage', data);
        return fromPromise(response);
    }

}

export interface legendaPlanimetria {
    value: string,
    color: string,
    numberOfObject: number,
    shape: string
}
