<div style="display:flex;align-items: center; justify-content: center;">
    <table mat-table [dataSource]="data" class="mat-elevation-z4" style="margin-bottom: 20px;">
        <ng-container matColumnDef="color">
            <th mat-header-cell *matHeaderCellDef>{{titleSxColumn}}</th>
            <td mat-cell *matCellDef="let element">
                <div class="elementoLeggenda" [ngStyle]="{'background-color':element.color}">
                </div>
            </td>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="key">
            <th mat-header-cell *matHeaderCellDef>{{titleDxColumn}}</th>
            <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="element.key!=null">
                    <ng-container [ngSwitch]="element.type">
                        <ng-container *ngSwitchCase="types.traduction">
                            {{element.key|translate}}
                        </ng-container>
                        <ng-container *ngSwitchCase="types.date">
                           {{element.key|customDate :'longDate'|async}}
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            {{element.key}}
                        </ng-container>
                    </ng-container>
                </ng-container>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>