import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    UntypedFormBuilder,
    UntypedFormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR, ValidationErrors
} from "@angular/forms";
import {fromEvent, Observable, Subscription} from "rxjs";
import {dataForm} from "../../components/confirm-delete/select-or-create/select-or-create.component";
import {debounceTime, map, startWith} from "rxjs/operators";
import {arrayIsSet, isNotNullOrUndefined, stringIsSet} from "../../models/Models";
import {MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent, MatLegacyAutocompleteTrigger as MatAutocompleteTrigger} from "@angular/material/legacy-autocomplete";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-auto-complete-form',
    templateUrl: './auto-complete-form.component.html',
    styleUrls: ['./auto-complete-form.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: AutoCompleteFormComponent
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: AutoCompleteFormComponent
        }
    ]
})
export class AutoCompleteFormComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor {

    @ViewChild('autoCompleteInput', {read: MatAutocompleteTrigger})
    autoComplete: MatAutocompleteTrigger;
    @Input() title: string;
    @Input() hiddenClearButton: boolean = true;
    @Input() registerOnChangeKeyValue: string = 'html';

    @Input() items: dataForm[] = [];

    @Input() appearance: 'fill' | 'legacy' | 'outline' | 'standard' = 'standard';
    @Input() classTitle: string[];
    @Input() emitFormValue = false;
    @Input() classStyle = '';

    @Input() liveEmitValue = false;
    @Input() hasError = false;
    @Input() messageError: string | null = null;

    @Output()
    optionSelected = new EventEmitter<{ formValues: dataForm, matAuotoComplete: MatAutocompleteSelectedEvent }>();

    form: UntypedFormGroup;
    filteredOptions: Observable<dataForm[]>;
    public translateInstan;
    scrollEvent = (event: any): void => {
        if (this.autoComplete.panelOpen) {
            this.autoComplete.updatePosition();
            //this.autoComplete.closePanel();
        }
    };

    ngOnInit(): void {
        window.addEventListener('scroll', this.scrollEvent, true);
        if (this.liveEmitValue) {
            const sub = this.form.get('autoComplete').valueChanges
                .pipe(debounceTime(300))
                .subscribe(value => {
                    this.emitOptionDigit(value);
                });
            this.onChangeSubs.push(sub);
            // this.filteredOptions = this.form.get('autoComplete').valueChanges
            //     .pipe(
            //         startWith(''),
            //         map(value => {
            //             return stringIsSet(value) ? this._filter(value) : this.items.slice()
            //         })
            //     );
        }
    }

    ngOnChanges(changes): void {
        this.filteredOptions = this.form.get('autoComplete').valueChanges
            .pipe(
                startWith(''),
                map(value => {
                    return stringIsSet(value) ? this._filter(value) : this.items.slice()
                })
            );
    }

    private _filter(value: string): dataForm[] {
        if (arrayIsSet(this.items)) {
            const filterValue = value.toLowerCase();
            const filtered = this.items.filter(data => {
                const key = this.getVisuzlizedKey(data);
                const castKey = stringIsSet(key) ? key : ''
                return castKey.toLowerCase().includes(filterValue)
            })
            return filtered;
        } else {
            return []
        }

    }

    onTouched: Function = () => {
    };

    onChangeSubs: Subscription[] = [];

    constructor(private fb: UntypedFormBuilder,
                private translate: TranslateService) {
        this.translateInstan = (v) => this.translate.instant(v);
        const obj = {};
        obj['autoComplete'] = '';
        this.form = this.fb.group(obj);
    }

    ngOnDestroy() {
        for (let sub of this.onChangeSubs) {
            sub.unsubscribe();
        }
        window.removeEventListener('scroll', this.scrollEvent);

    }

    registerOnChange(onChange: any) {
        const sub = this.form.get('autoComplete').valueChanges
            .pipe(
                map((value) => {
                    const index = (this.emitFormValue) ? -1 : this.items.findIndex(item => item.valueForm == value)
                    if (index >= 0) {
                        const valueSet = this.items[index].registerValue != null ? this.items[index].registerValue : this.items[index][this.registerOnChangeKeyValue]
                        return valueSet;
                    } else {
                        return value
                    }
                })
            )
            .subscribe(onChange);
        this.onChangeSubs.push(sub);
    }

    registerOnTouched(onTouched: Function) {
        this.onTouched = onTouched;
    }

    emitOptionSelected(event: MatAutocompleteSelectedEvent) {
        const index = this.items.findIndex(item => item.valueForm == event.option.value)
        const value = {
            formValues: this.items[index],
            matAuotoComplete: event
        }
        this.optionSelected.emit(value)
    }

    emitOptionDigit(valueInsert) {
        const valueDigit = stringIsSet(valueInsert) ? valueInsert.trim() : ''
        const index = this.items.findIndex(item => {
            const value = stringIsSet(this.getVisuzlizedKey(item)) ? this.getVisuzlizedKey(item).toLowerCase() : ''
            return value == valueDigit
        })
        const value = index >= 0 ? this.items[index] : {valueForm: valueDigit, html: valueDigit}
        const emitValue = {
            formValues: value,
            matAuotoComplete: undefined
        }
        this.optionSelected.emit(emitValue)
    }

    setDisabledState(disabled: boolean) {
        if (disabled) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    writeValue(value: any) {
        if (!isNotNullOrUndefined(value)) {
            this.form.get('autoComplete').reset();
        } else if (value != null) {
            let writedValues = value;
            if (value != null && stringIsSet(value.className) && stringIsSet(value.objectId)) {
                const item = this.items.find(item => {
                    if (item.registerValue != null && stringIsSet(item.registerValue.objectId)) {
                        return item.registerValue.objectId == value.objectId
                    } else {
                        return item.valueForm == value.objectId
                    }
                })
                if (item != null) {
                    writedValues = item.valueForm
                }
            }
            this.form.get('autoComplete').setValue(writedValues);
        }
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return null
        // if (!isNotNullOrUndefined(this.form.get('autoComplete').validator)) {
        //     this.form.get('autoComplete').setValidators(control.validator)
        // }

        // if (this.form.get('autoComplete').valid) {
        //     return null
        // } else {
        //     return this.form.get('autoComplete').errors;
        // }
    }

    addControlErrors(allErrors: any, controlName: string) {
        const errors = {...allErrors};
        const controlErrors = this.form.controls[controlName].errors;
        if (controlErrors) {
            errors[controlName] = controlErrors;
        }
        return errors;
    }

    displayFnNumeroQuadro(id): string {
        if (arrayIsSet(this.items)) {
            const index = this.items.findIndex(data => data.valueForm == id);
            if (index >= 0) {
                return this.getVisuzlizedKey(this.items[index]);
            } else {
                return id;
            }
        } else {
            return id;
        }

    }


    resetForm() {
        this.form.get('autoComplete').reset();
        this.optionSelected.emit({
            formValues: null,
            matAuotoComplete: null
        });
    }


    getVisuzlizedKey(item: dataForm) {
        return this.getVisuzlizedKeyPredicate(item, this.translateInstan)
    }

    getVisuzlizedKeyPredicate(item: dataForm, translateInstant: (value: string) => string) {
        if (isNotNullOrUndefined(item.traductions)) {
            let keys = '';
            item.traductions.forEach(traduction => {
                keys += translateInstant(traduction);
            })
            return keys;
        } else if (isNotNullOrUndefined(item.traduction)) {
            return translateInstant(item.traduction);
        } else {
            return item.html;
        }
    }

}
