import {debounceTime} from 'rxjs/operators';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Filter, ListConfig} from '../../abstract/list-abstract';
import {MatLegacyPaginatorIntl as MatPaginatorIntl} from '@angular/material/legacy-paginator';
import {Sort} from '@angular/material/sort';
import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {DimensionsService} from "../../providers/services/dimensions.service";
import {TranslateService} from "@ngx-translate/core";
import {listFilter} from "../../models/Models";
import {Observable, Subscription} from 'rxjs';

@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, OnDestroy, OnChanges {


    @Input() data: { rows: any[], count: number };
    @Input() config: ListConfig;

    @Output() updateElement: EventEmitter<any> = new EventEmitter<any>();
    @Output() addElement: EventEmitter<void> = new EventEmitter<void>();
    @Output() changePage: EventEmitter<number> = new EventEmitter<number>();
    @Output() changeFilters: EventEmitter<{
        label: string, items: Filter[], type: string, code: string
    }[]> = new EventEmitter<{
        label: string, items: Filter[], type: string, code: string
    }[]>();
    @Output() search: EventEmitter<string> = new EventEmitter<string>();
    @Output() resolved: EventEmitter<string> = new EventEmitter<string>();
    @Output() filterDate: EventEmitter<any> = new EventEmitter<string>();
    @Output() open: EventEmitter<any> = new EventEmitter<any>();
    @Input() searchInfo = true;
    @Input() getValueFunction: { [key: string]: (value: any) => string | Observable<string> };

    @Output() changeSort: EventEmitter<{
        column: string,
        type: 'asc' | 'desc' | ''
    }> = new EventEmitter();

    sort: {
        column: string,
        type: 'asc' | 'desc' | ''
    } = {
        column: '',
        type: 'asc'
    };

    public searchForm: UntypedFormGroup;
    private subscription: Subscription | undefined;
    private langChangeSubscription: Subscription | undefined;
    public isResolved = false;
    public allFiltersActive = {};
    public dataFilterChecked = {};
    pageIndex = 0;

    constructor(private fb: UntypedFormBuilder,
                private matPaginatorService: MatPaginatorIntl,
                public dimensionService: DimensionsService,
                private translateService: TranslateService,
    ) {
        this.dataFilterChecked['one_week'] = true
        /*
        this.dataFilterChecked['one_week'] = (localStorage.getItem('reportsDateFilter') == '0')
        this.dataFilterChecked['two_week'] = (localStorage.getItem('reportsDateFilter') == '1')
        this.dataFilterChecked['one_month'] = (localStorage.getItem('reportsDateFilter') == '2')
        this.dataFilterChecked['six_month'] = (localStorage.getItem('reportsDateFilter') == '3')
        this.dataFilterChecked['one_year'] = (localStorage.getItem('reportsDateFilter') == '4')
        this.dataFilterChecked['all_data'] = (localStorage.getItem('reportsDateFilter') == '5')
        */
    }

    ngOnInit() {
        // this.config.filters.filters = listFilter;
        if (this.config.search) {
            this.searchForm = this.fb.group({
                search: ''
            });
            this.subscription = this.searchForm.get('search').valueChanges.pipe(debounceTime(1000)).subscribe(res => {
                this.onSearch(res);
            });
        }
        if (this.config.filters) {
            this.config.filters.filters
                .forEach(el => {
                    if (el.type === 'checkbox') {
                        //console.log(el.code, el);
                        if (Array.isArray(el.items)) {
                            this.allFiltersActive[el.code] = el.items.every((item) => !item.isActive);
                        } else {
                            this.allFiltersActive[el.code] = true;
                        }
                    }
                });
        }

        this.initPaginator();
        this.langChangeSubscription = this.translateService.onLangChange.subscribe(() => {
            this.initPaginator();
            this.matPaginatorService.changes.next();
        });
    }

    ngOnDestroy(): void {
        if (this.config.search && this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.langChangeSubscription) {
            this.langChangeSubscription.unsubscribe();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data && this.config.pagination) {
            // pageIndex must be kept up to date with the actual data being shown
            // if some filters have changed, making the current pageIndex non existent, then the page will be updated
            // to the last valid page and the new data will be loaded
            const lastValidPage = Math.min(this.pageIndex, Math.max(0, Math.ceil(this.data.count / this.config.take - 1)));
            if (this.pageIndex !== lastValidPage) {
                this.pageIndex = lastValidPage;
                setTimeout(() => { // timeout required to prevent the "expression changed after being checked" error
                    this.changePage.emit(this.pageIndex);
                });
            }
        }
    }

    public onChangeSort(event: Sort) {
        if (this.sort.column === event.active) {
            this.sort.type = this.sort.type == 'asc' ? 'desc' : 'asc';
        } else {
            this.sort.type = event.direction;
        }
        this.sort.column = event.active;
        this.changeSort.emit(this.sort);
    }

    public onChangePage(event) {
        this.pageIndex = event.pageIndex;
        this.changePage.emit(event.pageIndex);
    }

    public onResolve() {
        this.isResolved = !this.isResolved;
        this.resolved.emit();
    }

    public onFilterDate(event) {
        localStorage.setItem('reportsDateFilter', event.value);
        this.filterDate.emit(event);
    }

    public onUpdateElement(slug) {
        this.updateElement.emit(slug);
    }


    public sortDisabled(prop): boolean {
        if (!this.config.order || this.config.order.length === 0) {
            return true;
        }
        const index = this.config.order.indexOf(prop);
        return index === -1;
    }

    public onChangeAllFilters(event, ind, code, type) {
        this.config.filters.filters[ind].items.forEach(el => el.isActive = false);
        this.onChangeFilters(ind, code, type);
    }

    public onChangeFilters(ind, code, type) {
        setTimeout(() => {
            if (type == 'checkbox') {
                const filtersActive = this.config.filters.filters[ind].items.filter(el => el.isActive);
                const allFiltersActive = filtersActive.length === this.config.filters.filters[ind].items.length;
                allFiltersActive || !filtersActive.length ? this.allFiltersActive[code] = true : delete this.allFiltersActive[code];
            }
            const filtersActiveTotal = this.config.filters.filters.map(el => {
                const newItem = Object.assign({}, el);
                if (el.type == 'checkbox') {
                    newItem.items = newItem.items.filter(el2 => el2.isActive);
                } else {
                    // i valori non mi servono per il calcolo
                    newItem.items = [];
                }
                return newItem;
            }).filter(el => el.items.length || el.value != undefined);
            this.changeFilters.emit(filtersActiveTotal);
        }, 0);
    }

    private onSearch(term) {
        this.search.emit(term);
    }

    private initPaginator() {
        if (this.config.pagination) {
            this.matPaginatorService.firstPageLabel = this.translateService.instant('pagination.first_page');
            this.matPaginatorService.itemsPerPageLabel = this.translateService.instant('pagination.items_per_page');
            this.matPaginatorService.nextPageLabel = this.translateService.instant('pagination.next_page');
            this.matPaginatorService.previousPageLabel = this.translateService.instant('pagination.previous_page');
            this.matPaginatorService.lastPageLabel = this.translateService.instant('pagination.last_page');
            this.matPaginatorService.getRangeLabel = (page: number, pageSize: number, length: number): string => {
                let firstShownElement = 0;
                let lastShownElement = 0;
                if (length > 0 && length / pageSize > page) {
                    firstShownElement = Math.min(length, page * pageSize + 1);
                    lastShownElement = Math.min(length, (page + 1) * pageSize);
                }
                return this.translateService.instant('pagination.count', {firstShownElement, lastShownElement, length});
            };
        }
    }

}
