<div mat-dialog-title>
    {{ 'advanced_filters' | translate}}
</div>
<mat-dialog-content>
    <div class="spacer">
        <button (click)="addFilter()" mat-stroked-button color="primary">{{ 'add_row' | translate}}</button>
    </div>
    <mat-card class="filter-dialog-card" [style.display]="filtersActive.length > 0 ? 'block' : 'none'">
        <form class="scrollable" [formGroup]="filterForm">
            <div class="filter-dialog-row"
                 *ngFor="let filter of filtersActive" formArrayName="filters">
                <ng-container [formGroupName]="filter">
                    <div class="filter-dialog-row-fields-wrapper">
                        <div>
                            <app-auto-complete-form
                                    formControlName="key"
                                    title="{{ 'field' | translate}}"
                                    [items]="filters|convertValueByPredicateFunction:getFiltersAutocompleteItemPredicate"
                                    appearance="fill"
                                    [hiddenClearButton]="true"
                                    (optionSelected)="onChangeKey_v2(filter)"

                            >
                            </app-auto-complete-form>
<!--                            <mat-form-field floatLabel="auto" appearance="fill">-->
<!--                                <mat-label>{{ 'field' | translate}}</mat-label>-->
<!--                                <mat-select formControlName="key"-->
<!--                                            (selectionChange)="onChangeKey(filter)"-->
<!--                                            [compareWith]="keyCompareFn">-->
<!--                                    <ng-container *ngFor="let item of filters">-->
<!--                                        <mat-option *ngIf="item.campoFiltrabile"-->
<!--                                                    [value]="item">{{'dashboard_sidenav.PuntiLuce.' + item.key.key + '.title' | translate}}-->
<!--                                        </mat-option>-->
<!--                                    </ng-container>-->
<!--                                </mat-select>-->
<!--                            </mat-form-field>-->
                        </div>
                        <div>
                            <mat-form-field floatLabel="auto" appearance="fill">
                                <mat-label>{{ 'operator' | translate}}</mat-label>
                                <mat-select (selectionChange)="onChangeOperator(filter, $event)"
                                            formControlName="operator">
                                    <mat-option *ngFor="let op of getOperators(filter)" [value]="op.operatore">
                                        {{'operators.' + (op.operatore |transformForTranslate) | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <ng-container *ngIf="!filterEasy(filter)">
                            <div>
                                <ng-container [ngSwitch]="getTypeFilter(filter)">
                                    <ng-container *ngSwitchCase="typeFilter.elencoAperto">
                                        <app-auto-complete-form
                                                title="{{'value'|translate}}"
                                                formControlName="value"
                                                [items]="valuesAutoCompleteData[getValues(filter).key]"
                                                appearance="fill"
                                                [hiddenClearButton]="true">
                                        </app-auto-complete-form>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="typeFilter.free">
                                        <mat-form-field floatLabel="auto" appearance="fill">
                                            <mat-label>{{ 'value' | translate}}</mat-label>
                                            <input matInput formControlName="value">
                                        </mat-form-field>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="typeFilter.dynamic">
                                        <mat-form-field floatLabel="auto" appearance="fill"
                                                        *ngIf="dynamicFilter(filter)">
                                            <mat-label>{{ 'value' | translate}}</mat-label>
                                            <mat-select formControlName="value">
                                                <mat-option
                                                        *ngFor="let val of (promises[filter] | async)"
                                                        [value]="val.objectId">
                                                    {{val[getKeyFilter(filter).pointerKey]}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="typeFilter.date">
                                        <mat-form-field floatLabel="auto" appearance="fill">
                                            <mat-label>{{ 'value' | translate}}</mat-label>
                                            <input matInput [matDatepicker]="picker" formControlName="value">
                                            <mat-datepicker-toggle matSuffix [for]="picker">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </mat-form-field>
                                    </ng-container>
                                    <ng-container *ngSwitchDefault>
                                        <mat-form-field floatLabel="auto" appearance="fill"
                                                        *ngIf="!dynamicFilter(filter)">
                                            <mat-label>{{ 'specify_value' | translate}}</mat-label>
                                            <mat-select formControlName="value">
                                                <mat-option *ngFor="let val of getValues(filter).filter"
                                                            [value]="val.key">{{'dashboard_sidenav.PuntiLuce.' + getValues(filter).key + '.possibleValues.' + (val.key|transformForTranslate).toString()|translate }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
                <ng-container>
                    <button mat-button class="filter-dialog-remove-button" (click)="removeFilter(filter)">
                        {{ 'remove' | translate}}
                    </button>
                </ng-container>
            </div>
        </form>
    </mat-card>
</mat-dialog-content>
<mat-dialog-actions>
    <button
            (click)="dialogRef.close()"
            mat-flat-button>{{ 'close' | translate}}
    </button>
    <button
            [disabled]="filterForm.invalid"
            [mat-dialog-close]="Object.values(filterForm.get('filters').value)"
            (click)="onApply()" mat-flat-button color="primary">{{ 'apply' | translate}}
    </button>
</mat-dialog-actions>
