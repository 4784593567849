import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {SchedaManutenzioneParse} from "../../../models/SchedaManutenzione.Parse";
import {getArrayToRemveItem, getItemInArrayByKeyValue, isNotNullOrUndefined} from "../../../models/Models";

@Component({
    selector: 'app-list-and-select-to-add-schede-manutenzione',
    templateUrl: './list-and-select-to-add-schede-manutenzione.component.html',
    styleUrls: ['./list-and-select-to-add-schede-manutenzione.component.scss']
})
export class ListAndSelectToAddSchedeManutenzioneComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<ListAndSelectToAddSchedeManutenzioneComponent>,
                @Inject(MAT_DIALOG_DATA) public data: { schedeManutenzione: SchedaManutenzioneParse[], schedeManutenzioniGiaAssociate: SchedaManutenzioneParse[] }) {
    }

    public selectedSchedeManutenzione: SchedaManutenzioneParse[] = []
    public calendariDaCreare = {};

    ngOnInit(): void {
    }

    clickSchedaManutenzione(event) {
        const schedaManutenzione = event.schedaCliccata;
        this.calendariDaCreare = event.calendari;
        if (this.isSelected(schedaManutenzione)) {
            this.selectedSchedeManutenzione = getArrayToRemveItem(this.selectedSchedeManutenzione, schedaManutenzione.objectId, 'objectId')
        } else {
            this.selectedSchedeManutenzione.push(schedaManutenzione)
        }
    }

    isSelected(schedaManutenzione: SchedaManutenzioneParse) {
        const item = getItemInArrayByKeyValue(this.selectedSchedeManutenzione, schedaManutenzione.objectId, 'objectId')
        return isNotNullOrUndefined(item)
    }


    closeDialog(confirm) {
        const calendariDaCreare = [];
        const idsSchedeManutenzioneCalendariDaCreare = Object.keys(this.calendariDaCreare)
        idsSchedeManutenzioneCalendariDaCreare.forEach(key => {
            const index = this.selectedSchedeManutenzione.findIndex(scheda => scheda.objectId == key);
            if (index >= 0) {
                calendariDaCreare.push({
                    maintenanceDate: this.calendariDaCreare[key],
                    schedaDiManutenzione: this.selectedSchedeManutenzione[index]
                })
            }
        })
        const schedeSelezionate = this.selectedSchedeManutenzione.filter(scheda => !idsSchedeManutenzioneCalendariDaCreare.includes(scheda.objectId));
        if (confirm) {
            this.dialogRef.close({selectedSchedeManutenzione: schedeSelezionate, calendariDaCreare: calendariDaCreare})
        } else {
            this.dialogRef.close(undefined)
        }
    }

    disabled(schedaManutenzione: SchedaManutenzioneParse) {
        const item = getItemInArrayByKeyValue(this.data.schedeManutenzioniGiaAssociate, schedaManutenzione.objectId, 'objectId')
        return isNotNullOrUndefined(item)
    }

}
