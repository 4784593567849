<div class="container">
    <ng-container *ngIf="saved; else notSaved">
        <app-template-form subtitle="{{ 'summary' | translate}}" title="{{ 'plans' | translate}}" [informativa]="false">
            <form *ngIf="planForm" [formGroup]="planForm">
                <mat-card>
                    <mat-card-content style="overflow: scroll">
                        <div class="spacer">
                            <div><span class="card-label">{{ 'label' | translate}}</span><br>{{plan.nome}}
                            </div>
                        </div>
                        <div class="spacer">
                            <div><span
                                    class="card-label">{{ 'title' | translate}}</span><br>{{plan.titolo}}
                            </div>
                        </div>
                        <div class="spacer">
                            <div><span class="card-label">{{ 'formato' | translate}}</span><br><span
                                    class="uppercase">{{plan.formato}}</span></div>
                        </div>
                        <div class="spacer">
                            <div>
                                <span class="card-label">{{ 'zoom' | translate}}</span><br><span
                                    class="uppercase">{{plan.zoom}}</span>
                            </div>
                        </div>
                        <div class="spacer">
                            <div>
                                <span class="card-label">{{ 'legenda' | translate}}</span><br><span
                                    class="uppercase">{{plan.legenda}}</span>
                            </div>
                        </div>
                        <div class="spacer">
                            <div>
                                <span class="card-label">{{ 'tipologia_planimetria' | translate}}</span><br><span
                                    class="uppercase">{{plan.tipologia}}</span>
                            </div>
                        </div>
                        <div class="spacer">
                            <div><span
                                    class="card-label">{{ 'matType' | translate}}</span><br><span>{{plan.tipologiaMappa}}</span>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
                <div><br><br></div>
                <div fxLayout="row" fxLayoutAlign="space-between stretch">
                    <div fxFlex="33">
                        <div class="box primary">
                            <a href="{{fileLink}}" target="_blank">
                                <div class="">
                                    <mat-icon>get_app</mat-icon>
                                </div>
                                <div>
                                    {{ 'download_pdf' | translate}}
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </form>

        </app-template-form>
    </ng-container>
    <ng-template #notSaved>
        <app-template-form subtitle="{{ 'update_plans' | translate}}" title="{{ 'plans' | translate}}"
                           [informativa]="false">
            <form *ngIf="planForm" [formGroup]="planForm" style="overflow: scroll">
                <app-form-group-template title="{{ 'file_name' | translate}}">
                    <mat-form-field floatLabel="auto" appearance="fill">
                        <mat-label>{{ 'file_name' | translate}}</mat-label>
                        <input formControlName="name" id="name" matInput
                        >
                    </mat-form-field>
                </app-form-group-template>
                <app-form-group-template title="{{ 'compose_plans' | translate}}">
                    <mat-form-field floatLabel="auto" appearance="fill">
                        <mat-label>{{ 'title' | translate}}</mat-label>
                        <input formControlName="title" id="title" matInput
                        >
                    </mat-form-field>
                    <div class="spacer">
                        <label class="label">{{ 'formato' | translate}}</label>
                        <div>
                            <mat-radio-group formControlName="formato" id="formato">
                                <mat-radio-button value="a3">A3
                                </mat-radio-button>
                                <mat-radio-button value="a4">A4
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="spacer">
                        <label class="label">{{ 'template' | translate}}</label>
                        <div>
                            <mat-radio-group formControlName="template" id="template">
                                <ng-container>
                                    <mat-radio-button
                                            value="1">1
                                    </mat-radio-button>
                                    <mat-radio-button
                                            [disabled]="planForm.value.formato !== 'a3'"
                                            value="2">2
                                    </mat-radio-button>
                                </ng-container>
                                <mat-radio-button value="3">3
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                    <br>
                    <div class="spacer">
                        <mat-form-field floatLabel="auto" appearance="fill">
                            <mat-label>{{ 'choose_typeMaps' | translate}}</mat-label>
                            <mat-select formControlName="mapType" id="mapType">
                                <mat-option value="roadmap">{{ 'static' | translate}}</mat-option>
                                <mat-option value="hybrid">{{ 'hybrid' | translate}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="spacer">
                        <mat-form-field floatLabel="auto" appearance="fill">
                            <mat-label>{{ 'tipologia_planimetria' | translate}}</mat-label>
                            <mat-select formControlName="tipologia">
                                <mat-option *ngFor="let t of tipologiaValues|keyvalue" [value]="t.key">
                                    {{ t.value | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="spacer">
                        <mat-form-field floatLabel="auto" appearance="fill">
                            <mat-label>{{ 'plans_label_lightPoint' | translate}}</mat-label>
                            <mat-select formControlName="etichettaPuntoLuce">
                                <mat-option *ngFor="let etichetta of etichettePuntiLuce" [value]="etichetta">
                                    {{pathForTranslate + etichetta + '.title' | translate}}{{addProjectTraduction(etichetta)}}
                                </mat-option>
                            </mat-select>
                            <button mat-icon-button
                                    matPrefix
                                    (click)="$event.stopPropagation();unsteValue('etichettaPuntoLuce')">
                                <mat-icon>clear</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="spacer">
                        <mat-checkbox formControlName="legenda" id="legenda">
                            {{ 'legenda_comment' | translate}}
                        </mat-checkbox>
                    </div>
                    <div class="spacer">
                        <div class="mat-form-field">
                            <label class="label"> {{ 'company_logo' | translate}}</label>
                            <ngx-dropzone (change)="onLoadImage($event.addedFiles,'logoAzienda')"
                                          accept="image/jpeg,image/jpg,image/png">
                                <ngx-dropzone-label>
                                    {{ 'drag_image' | translate}}
                                </ngx-dropzone-label>
                            </ngx-dropzone>
                            <mat-list role="list">
                                <ng-container *ngIf="planForm.get('logoAzienda').value">
                                    <div>
                                        <a href="{{planForm.get('logoAzienda').value.url}}" target="_blank">
                                            <img src="{{planForm.get('logoAzienda').value.url}}" height="30px">
                                        </a>
                                    </div>
                                    <button class="remove-attachment-button" (click)="deleteSavedFile('logoAzienda')">
                                        {{ 'remove_attachement' | translate}}
                                    </button>
                                </ng-container>
                            </mat-list>
                        </div>
                    </div>
                    <div class="spacer">
                        <div class="mat-form-field">
                            <label class="label"> {{ 'project_logo' | translate}}</label>
                            <ngx-dropzone (change)="onLoadImage($event.addedFiles,'logoProgetto')"
                                          accept="image/jpeg,image/jpg,image/png">
                                <ngx-dropzone-label>
                                    {{ 'drag_image' | translate}}
                                </ngx-dropzone-label>
                            </ngx-dropzone>
                            <mat-list role="list">
                                <ng-container *ngIf="planForm.get('logoProgetto').value">
                                    <div>
                                        <a href="{{planForm.get('logoProgetto').value.url}}" target="_blank">
                                            <img src="{{planForm.get('logoProgetto').value.url}}" height="30px">
                                        </a>
                                    </div>
                                    <button class="remove-attachment-button" (click)="deleteSavedFile('logoProgetto')">
                                        {{ 'remove_attachement' | translate}}
                                    </button>
                                </ng-container>
                            </mat-list>
                        </div>
                    </div>

                    <div class="spacer">
                        <div class="mat-form-field">
                            <label class="label"> {{ 'images' | translate}}</label>
                            <ngx-dropzone (change)="onLoadImage($event.addedFiles,'images')" [disabled]="isDisabled()"
                                          accept="image/jpeg,image/jpg,image/png">
                                <ngx-dropzone-label>
                                    {{ 'drag_image' | translate}}
                                </ngx-dropzone-label>
                            </ngx-dropzone>
                            <div fxLayout="row" fxLayoutAlign="start stretch">
                                <div *ngFor="let item of images" fxFlex="25" style="margin-right: 0.2rem;">
                                    <mat-list role="list">
                                        <ng-container *ngIf="planForm.get(item).value">
                                            <div>

                                                <a href="{{planForm.get(item).value.url}}" target="_blank">
                                                    <img src="{{planForm.get(item).value.url}}" height="30px">
                                                </a>
                                            </div>
                                            <button class="remove-attachment-button" (click)="deleteSavedFile(item)">
                                                {{ 'remove_attachement' | translate}}
                                            </button>
                                        </ng-container>
                                    </mat-list>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--                    <div class="spacer">-->
                    <!--                        <div class="mat-form-field">-->
                    <!--                            <label class="label"> {{ 'project_logo2' | translate}}</label>-->
                    <!--                            <ngx-dropzone #dropzoneImg (filesAdded)="onLoadImage($event,'logoProgetto2')"-->
                    <!--                                          accept="image/*"-->
                    <!--                                          label="{{ 'drag_image' | translate}}"></ngx-dropzone>-->
                    <!--                            <mat-list role="list">-->
                    <!--                                <ng-container *ngIf="planForm.get('logoProgetto2').value">-->
                    <!--                                    <div>-->
                    <!--                                        <a href="{{planForm.get('logoProgetto2').value.url}}" target="_blank">-->
                    <!--                                            <img src="{{planForm.get('logoProgetto2').value.url}}" height="30px">-->
                    <!--                                        </a>-->
                    <!--                                    </div>-->
                    <!--                                    <div style="font-size:12px;margin-top: 5px">-->
                    <!--                                        <a (click)="deleteSavedFile('logoProgetto2')">-->
                    <!--                                            {{ 'remove_attachement' | translate}}-->

                    <!--                                        </a>-->
                    <!--                                    </div>-->
                    <!--                                </ng-container>-->
                    <!--                            </mat-list>-->
                    <!--                        </div>-->
                    <!--                    </div>-->

                </app-form-group-template>
                <div fxLayout="row" fxLayoutAlign="space-between stretch">
                    <div fxFlex="33">
                        <a (click)="onLink()" target="_blank" *ngIf="!loadPdf;else loadingPdf">
                            <div class="box primary">
                                <div class="">
                                    <mat-icon>visibility</mat-icon>
                                </div>
                                <div>
                                    {{ 'preview' | translate}}
                                </div>
                            </div>
                        </a>
                        <ng-template #loadingPdf>
                            <div class="box " style="background: rgba(128,128,128,0.4);cursor: default">
                                <div class="">
                                    <mat-spinner [diameter]="40"></mat-spinner>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                    <div fxFlex="33" style="cursor: pointer" (click)="seaMap()">
                        <div class="box warn">
                            <div class="">
                                <mat-icon>edit</mat-icon>
                            </div>
                            <div>
                                {{ 'update' | translate}}
                            </div>
                        </div>
                    </div>
                    <div fxFlex="33">
                        <a (click)="updatePlan(); definitelySave()">
                            <div class="box accent">
                                <div class="">
                                    <mat-icon>save</mat-icon>
                                </div>
                                <div>
                                    {{ 'complete' | translate}}
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="spacer">
                    <ng-container *ngIf="!savingPlan;else notSavingPlan">
                        <button mat-flat-button color="primary" (click)="updatePlan()">{{ 'save' | translate}}</button>
                    </ng-container>
                    <ng-template #notSavingPlan>
                        <mat-spinner [diameter]="20"></mat-spinner>
                    </ng-template>
                </div>
                <p>&nbsp;</p>
                <hr>
                <p>&nbsp;</p>
                <div class="spacer">
                    <button mat-flat-button color="warn" (click)="deletePlan()">{{ 'remove' | translate}}</button>
                </div>
            </form>
            <div fxLayout="column" [style.height]="'100%'" fxLayoutAlign="left left" class="cmp">
                <!--                <app-template-form  [informativa]="false">-->
                <div class="spacer">
                    <app-info-plan></app-info-plan>
                </div>
                <!--                </app-template-form>-->
            </div>
        </app-template-form>
    </ng-template>
    <div *ngIf="planForm">
        <canvas #imaggine></canvas>
    </div>
</div>
