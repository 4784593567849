<mat-dialog-content>
    <ng-container *ngIf="data&&data.carichiEsogeni" [formGroup]="form" >
        <mat-selection-list formControlName="value" [multiple]="typeSelect.radio!==data.typeSelect"
                            [disableRipple]="true" #SelectList class="huna-mat-list-option">
            <app-box-carico-esogeno [caricoEsogeno]="caricoEsogeno"
                                    [showElementType]="true"
                                    class="pointer-list-select"
                                    *ngFor="let caricoEsogeno of data.carichiEsogeni"
                                    (click)="setCaricoEsogeno(SelectList,caricoEsogeno)"
                                    [ngClass]="{'type-radio':typeSelect.radio===data.typeSelect}"
            >
                <ng-container headerCheckBox>
                    <mat-list-option [value]="caricoEsogeno.objectId" (click)="$event.stopPropagation()">
                        <div class="like-radio">
                            <div class="external">
                                <div class="internal">

                                </div>
                            </div>
                        </div>
                    </mat-list-option>
                    <!--                    <ng-container *ngIf="data.typeSelect===typeSelect.multiSelct;else Radio">-->
                    <!--                        <mat-checkbox [formControlName]="caricoEsogeno.objectId"></mat-checkbox>-->
                    <!--                    </ng-container>-->
                    <!--                    <ng-template #Radio>-->
                    <!--                        <mat-radio-button [checked]="form.get(caricoEsogeno.objectId).value"></mat-radio-button>-->
                    <!--                    </ng-template>-->
                </ng-container>
            </app-box-carico-esogeno>
        </mat-selection-list>
    </ng-container>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-flat-button [mat-dialog-close]="undefined" color="accent">
        <mat-icon>clear</mat-icon>
    </button>
    <button mat-flat-button (click)="save()" color="primary" [disabled]="form.invalid">
        <mat-icon>save</mat-icon>
    </button>
</mat-dialog-actions>