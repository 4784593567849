import {Component, OnInit} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {InfoPlanDialogComponent} from '../info-plan-dialog/info-plan-dialog.component';
import {UtilsService} from "../../../providers/services/utils.service";

@Component({
    selector: 'app-info-plan',
    templateUrl: './info-plan.component.html'
})
export class InfoPlanComponent implements OnInit {

    constructor(public dialog: MatDialog, private utilsService: UtilsService) {
    }

    ngOnInit(): void {
    }


    openDialog() {

        this.utilsService.lockBody();
        const a = this.dialog.open(InfoPlanDialogComponent);
        a.afterClosed().subscribe(() => this.utilsService.unlockBody());
    }
}
