import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {isNotNullOrUndefined} from "../../../models/Models";


@Component({
    selector: 'app-select-row',
    templateUrl: './select-row.component.html',
    styleUrls: ['./select-row.component.scss']
})
export class SelectRowComponent implements OnInit, OnDestroy {

    constructor(public dialogRef: MatDialogRef<SelectRowComponent>,
                @Inject(MAT_DIALOG_DATA) public data: { message, actualRowTitle, allTitle, rowTitleRaw, className, getPathTraduction: (key: string) => string },
                private fb: UntypedFormBuilder) {
    }

    public visibleColumns;
    public dataSource;
    public originalAllTitle;
    public allTitle = [];
    public formTitle: UntypedFormGroup;
    public subscriptionFormGroup;

    public color = {
        pure: '#0000FF',
        single: '#ffa214',
        double: '#FF0000'
    }


    public getPathTraduction(key): string {
        if (this.data.getPathTraduction != null) {
            return this.data.getPathTraduction(key);
        } else if (!isNotNullOrUndefined(key)) {
            return undefined;
        } else if (key.toUpperCase() === 'LOCATIONLATITUDE') {
            return 'dashboard_sidenav.' + this.data.className + '.location.latitude.title';
        } else if (key.toUpperCase() === 'LOCATIONLONGITUDE') {
            return 'dashboard_sidenav.' + this.data.className + '.location.longitude.title';
        } else {
            return 'dashboard_sidenav.' + this.data.className + '.' + key + '.title';
        }
    }

    getProjectName(key: string) {
        if (key.includes('variante')) {
            return 'projectName';
        } else {
            return undefined
        }
    }

    ngOnInit(): void {
        const objData = {};
        const objForm = {};
        this.data.actualRowTitle.forEach((key, index) => {
            if (isNotNullOrUndefined(key)) {
                this.allTitle.push(key);
                objData[key] = key;
                objForm[key] = [{value: key, disabled: false}, []];
            } else {
                const titleObject: string = 'noTitle_' + index;
                objData[titleObject] = undefined;
                objForm[titleObject] = [{value: '', disabled: false}, []];
            }
        });
        this.visibleColumns = Object.keys(objData);
        this.visibleColumns.push('end');
        this.formTitle = this.fb.group(objForm);
        this.originalAllTitle = this.data.allTitle;
        this.originalAllTitle.sort();
        this.dataSource = [objData];
        this.subscriptionFormGroup = this.formTitle.valueChanges.subscribe(
            (newValue) => {
                this.allTitle = [];
                Object.keys(newValue).forEach(key => {
                    if (isNotNullOrUndefined(newValue[key])) {
                        this.allTitle.push(newValue[key]);
                    }
                });
            });
    }

    ngOnDestroy(): void {
        this.subscriptionFormGroup.unsubscribe();
    }

    atLeatOneFieldIsFull(): boolean {
        return Object.values(this.formTitle.value).every((a) => {
            return (isNotNullOrUndefined(a)) ? (a as string).trim().length === 0 : true;
        });
    }

    get rowTitle() {
        const keyTitle = Object.values(this.formTitle.value).map((a) => {
            if (isNotNullOrUndefined(a) && (a as string).trim().length > 0) {
                return a;
            } else return undefined;
        });
        return {newRowTitle: keyTitle};
    }

    markOption(key): string {
        if (this.allTitle.filter((el) => el === key).length > 1) {
            return this.color.double
        } else if (this.allTitle.filter((el) => el === key).length == 1) {
            return this.color.single
        } else {
            return this.color.pure
        }
    }


    public eliminaCampo(campo) {
        this.formTitle.get(campo).reset();
    }

    public getChar(index: number) {
        return (index >= 26 ? this.getChar((index / 26 >> 0) - 1) : '') + String.fromCharCode(65 + index % 26 >> 0);
    }

    public get numberOfNotValue() {
        return Object.values(this.formTitle.value).filter((el) => (isNotNullOrUndefined(el) ? (el as string).trim().length === 0 : true)).length
    }
}
