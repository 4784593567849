import {Injectable} from '@angular/core';
import {LocalSotrageService} from "./local-sotrage.service";
import {arrayIsSet} from "../../models/Models";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";

@Injectable({
    providedIn: 'root'
})
export class SmartSearchServiceService {

    constructor(private localStorageService: LocalSotrageService,
                private fb: UntypedFormBuilder) {
    }

    get lastActiveSmartSearch() {
        const activeTarghe = arrayIsSet(this.localStorageService.lastSmartSearchValue) ? this.localStorageService.lastSmartSearchValue : [];
        return activeTarghe;
    }

    set lastActiveSmartSearch(values) {
        if (arrayIsSet(values)) {
            const activeTarghe = values.length <= 5 ? values : values.slice(values.length - 5, values.length);
            this.localStorageService.lastSmartSearchValue = activeTarghe;
        } else {
            this.localStorageService.destroyLastSmartSearchValue();
        }
    }

    public formSmartSearch(otherKeys: string[]): UntypedFormGroup {
        const objForm = {
            targhePuntiLuce: {add: this.lastActiveSmartSearch, remove: undefined},
            visualizedChips: {},
        };
        otherKeys.forEach(key => {
            objForm[key] = undefined;
        });
        return this.fb.group(objForm);
    }

}
