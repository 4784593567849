<mat-sidenav-container fullscreen>
    <mat-sidenav-content>
        <ng-container>
            <mat-sidenav-container fullscreen [ngStyle]="showBatchEditingTable ? {display: 'none'} : {}">
                <mat-sidenav #filterside mode="over" class="sidenav-size">
                    <!--non apre la sidenav sei il  vettore è vuoto-->
                    <app-filter [circuits]='circuitiAppartenentiAlProgettoExport' [perPlanimetria]="true"
                                [circuitiSelezionati]="circuitiSelezionati"
                                [gruppiPuntiLuce]="gruppiAppartenentiAlProgettoExport"
                                (formValueChanges)="changeFilter($event)"
                                (close)="filterside.close()">
                    </app-filter>
                    <div style="position: absolute;bottom: 20px;left:50%;transform: translateX(-50%)">
                        <button mat-mini-fab color="accent" (click)="filterside.close()">
                            <mat-icon>clear</mat-icon>
                        </button>
                    </div>
                </mat-sidenav>
                <mat-sidenav #detailside mode="over" position="end" class="sidenav-size">
                    <app-dashboard-sidenav [detail]="detail"
                                           [circuitiAppartenentiAlProgettoInput]="circuitiAppartenentiAlProgettoExport"
                                           [stradeAppartenentiAlProgettoInput]="stradeAppartenentiAlProgettoExport"
                                           (idEClasseElemento)='aggiornaElementoInDetail($event)'
                                           (close)="detailside.close()"
                                           *ngIf="detail">
                    </app-dashboard-sidenav>
                </mat-sidenav>
                <mat-sidenav-content>
                    <mat-sidenav-container fullscreen>
                        <mat-sidenav #settingSide mode="over" position="end" class="sidenav-size">
                            <app-settings [modificaAvvenuta]="modificaAvvenutaPerSetting"
                                          (scalaColoreImpostata)='cambiaColoreDelleIcone($event)'
                                          (elementoEtichettaImpostato)="changeLabel($event)"
                                          [maxZoomLabelVisualized]="maxZoomToViusualizedLabel"
                                          [mapZoom]="zoomSetting"
                                          (close)="settingSide.close()">
                            </app-settings>
                        </mat-sidenav>
                        <mat-sidenav-content>
                            <div *ngIf="!loading" class="map"
                                 style="display: flex;flex-direction: column; height: 100%;" #map>
<!--                                <agm-map #gm [style.height.px]="map.offsetHeight"-->
<!--                                         (mapClick)="mapClick($event)"-->
<!--                                         (mapReady)="mapReady($event)"-->
<!--                                         (boundsChange)="boundChangeNow()"-->
<!--                                         [mapTypeId]="mapTypeId"-->
<!--                                         [mapTypeControl]='true'-->
<!--                                         (zoomChange)="mapChangeZoom($event)"-->
<!--                                >-->
<!--                                    <ng-container *ngIf="luxScaleOff;else yesLuxScale">-->
<!--                                        &lt;!&ndash; Visualizzia marker e cluster del quadro comandi&ndash;&gt;-->
<!--                                        <agm-marker-cluster [imagePath]="'assets/cluster/c'" [minimumClusterSize]="5"-->
<!--                                                            [maxZoom]="17">-->
<!--                                            <agm-marker (markerClick)="onClick(m,wCircuiti)"-->
<!--                                                        (mouseOut)="onMouseOut()"-->
<!--                                                        (mouseOver)="onMouseOver(wCircuiti)" *ngFor="let m of circuiti"-->
<!--                                                        [iconUrl]="maxIcon"-->
<!--                                                        [latitude]="m.get('location').latitude"-->
<!--                                                        [longitude]="m.get('location').longitude"-->
<!--                                                        [markerDraggable]="markerSpostabile"-->
<!--                                                        (dragStart)="conservaPosizioneIniziale($event,m.id,m.indirizzo,m.className,'null','quadriFiltri')"-->
<!--                                                        (dragEnd)="spostaPuntoLuce($event,m.id,m.className,'null')"-->
<!--                                            >-->
<!--                                                <agm-info-window #wCircuiti>-->
<!--                                                    <div>-->
<!--                                                        <h4>{{'square_number' | translate}}</h4>-->
<!--                                                        <div>{{m.get('numeroQuadro')}}</div>-->
<!--                                                    </div>-->
<!--                                                </agm-info-window>-->
<!--                                            </agm-marker>-->
<!--                                        </agm-marker-cluster>-->
<!--                                        &lt;!&ndash;Visualizzia marker e cluster dei punti luce *ngIf="rowsR.length>0" altrimenti va in tilt-->
<!--                                        la reinderizzazzione in caso di deselezionatutto&ndash;&gt;-->
<!--                                        <ng-container *ngFor="let cluster of vettoreDeiCluster">-->
<!--                                            <agm-marker-cluster [styles]="colorTextCluster(cluster.colore)"-->
<!--                                                                [minimumClusterSize]="10" [maxZoom]="17">-->
<!--                                                <ng-container *ngIf="rowsR.length>0">-->
<!--                                                    <agm-marker (markerClick)="onClick(m,wRow)"-->
<!--                                                                (mouseOut)="onMouseOut()"-->
<!--                                                                (mouseOver)="onMouseOver(wRow)"-->
<!--                                                                *ngFor="let m of cluster.daFiltro; trackBy:trackRows" #d-->
<!--                                                                [iconUrl]="m.icon"-->
<!--                                                                [opacity]="setOpacity(m)"-->
<!--                                                                [latitude]="m.location.latitude"-->
<!--                                                                [longitude]="m.location.longitude"-->
<!--                                                                [label]="getLabelMarker(m)"-->
<!--                                                                [markerDraggable]="markerSpostabile"-->
<!--                                                                (dragStart)="conservaPosizioneIniziale($event,m.id,m.indirizzo,m.className,(m.circuito)?m.circuito.id:chiaviFiltri.nessunQuadro,'puntiLuceFiltri')"-->
<!--                                                                (dragEnd)="spostaPuntoLuce($event,m.id,m.className,(m.circuito)?m.circuito.id:chiaviFiltri.nessunQuadro)">-->
<!--                                                        <agm-info-window #wRow [latitude]="m.location.latitude"-->
<!--                                                                         [longitude]="m.location.longitude"-->
<!--                                                                         maxWidth="200">-->
<!--                                                            <div>-->
<!--                                                                <h4>{{'license_plate' | translate}}</h4>-->
<!--                                                                <div>{{m.targa}}</div>-->
<!--                                                            </div>-->
<!--                                                        </agm-info-window>-->
<!--                                                    </agm-marker>-->
<!--                                                </ng-container>-->
<!--                                                <ng-container *ngIf="windowsRowsR.length>0">-->
<!--                                                    <agm-marker (markerClick)="onClick(m,wRow)"-->
<!--                                                                (mouseOut)="onMouseOut()"-->
<!--                                                                (mouseOver)="onMouseOver(wRow)"-->
<!--                                                                *ngFor="let m of cluster.daOcchio; trackBy:trackRows" #d-->
<!--                                                                [iconUrl]="m.icon"-->
<!--                                                                [opacity]="setOpacity(m)"-->
<!--                                                                [latitude]="m.location.latitude"-->
<!--                                                                [longitude]="m.location.longitude"-->
<!--                                                                [label]="getLabelMarker(m)"-->
<!--                                                                [markerDraggable]="markerSpostabile"-->
<!--                                                                (dragStart)="conservaPosizioneIniziale($event,m.id,m.indirizzo,m.className,(m.circuito)?m.circuito.id:chiaviFiltri.nessunQuadro,'puntiLuceOcchio')"-->
<!--                                                                (dragEnd)="spostaPuntoLuce($event,m.id,m.className,(m.circuito)?m.circuito.id:chiaviFiltri.nessunQuadro)">-->
<!--                                                        <agm-info-window #wRow [latitude]="m.location.latitude"-->
<!--                                                                         [longitude]="m.location.longitude"-->
<!--                                                                         maxWidth="200">-->
<!--                                                            <div>-->
<!--                                                                <h4>{{'license_plate' | translate}}</h4>-->
<!--                                                                <div>{{m.targa}}</div>-->
<!--                                                            </div>-->
<!--                                                        </agm-info-window>-->
<!--                                                    </agm-marker>-->
<!--                                                </ng-container>-->
<!--                                            </agm-marker-cluster>-->
<!--                                        </ng-container>-->
<!--                                        <agm-marker-cluster [imagePath]="'assets/cluster/m'" [minimumClusterSize]="5"-->
<!--                                                            [maxZoom]="17"-->
<!--                                                            *ngIf="windowsRowsR.length>0">-->
<!--                                        </agm-marker-cluster>-->
<!--                                        &lt;!&ndash; Visualizzia marker e cluster dei punti stampa-->
<!--                                             *ngIf="rowsR.length>0" altrimenti va in tilt-->
<!--                                             la reinderizzazzione in caso di deselezionatutto &ndash;&gt;-->
<!--                                        <agm-marker-cluster [imagePath]="'assets/cluster/m'" [minimumClusterSize]="5"-->
<!--                                                            [maxZoom]="17">-->
<!--                                            <agm-marker (markerClick)="onClick(puntoStampa,puntoStampaWindow)"-->
<!--                                                        *ngFor="let puntoStampa of puntiStampa; trackBy:trackRows"-->
<!--                                                        [iconUrl]="iconPuntiStampa"-->
<!--                                                        [latitude]="puntoStampa.location.latitude"-->
<!--                                                        [longitude]="puntoStampa.location.longitude"-->
<!--                                                        [markerDraggable]="markerSpostabile"-->
<!--                                                        (dragStart)="conservaPosizioneIniziale($event,puntoStampa.id,'nessuno',puntoStampa.className,'null','puntiStampaFiltri')"-->
<!--                                                        (dragEnd)="spostaPuntoLuce($event,puntoStampa.id,puntoStampa.className,'null')">-->
<!--                                                <agm-info-window #puntoStampaWindow-->
<!--                                                                 [latitude]="puntoStampa.location.latitude"-->
<!--                                                                 [longitude]="puntoStampa.location.longitude"-->
<!--                                                                 maxWidth="200">-->
<!--                                                    <div style="text-align: center;"-->
<!--                                                         *ngIf="puntoStampa.foto;else noFoto">-->
<!--                                                        <a href="{{puntoStampa.foto._url}}">-->
<!--                                                            <i class="material-icons">archive</i>-->
<!--                                                        </a>-->
<!--                                                        <h4>{{'dashboard.button.download_image' | translate}}</h4>-->
<!--                                                    </div>-->
<!--                                                    <ng-template #noFoto>-->
<!--                                                        <div style="text-align: center;">-->
<!--                                                            <img src="/assets/icon/dashboard/notFoundImage.png"-->
<!--                                                                 style="max-width: 24px;">-->
<!--                                                            <h4>{{'dashboard.button.noFoto' | translate}}</h4>-->
<!--                                                        </div>-->
<!--                                                    </ng-template>-->
<!--                                                </agm-info-window>-->
<!--                                            </agm-marker>-->
<!--                                        </agm-marker-cluster>-->
<!--                                        &lt;!&ndash; fare il poligono &ndash;&gt;-->
<!--                                        &lt;!&ndash; <agm-polygon [fillColor]="'#01B9FF'" [fillColor]="0" [strokeColor]="'#01B9FF'"  [paths]="progetto" *ngFor="let progetto of confiniAmministrativi">-->
<!--                                        </agm-polygon> &ndash;&gt;-->
<!--                                        <agm-polyline [strokeColor]="'#01B9FF'" [strokeWeight]="'5'"-->
<!--                                                      *ngFor="let progetto of confiniAmministrativi">-->
<!--                                            <agm-polyline-point [latitude]="coordinate.lat" [longitude]="coordinate.lng"-->
<!--                                                                *ngFor="let coordinate of progetto;">-->
<!--                                            </agm-polyline-point>-->
<!--                                            &lt;!&ndash; per farla trattegiata &ndash;&gt;-->
<!--                                            &lt;!&ndash; <agm-icon-sequence [strokeColor]="'#000000'" [repeat]="'10px'" [offset]="0"-->
<!--                                                [strokeWeight]="3" [scale]="2" [strokeOpacity]="100" [path]="'M 0,-1 0,1'">-->
<!--                                            </agm-icon-sequence> &ndash;&gt;-->
<!--                                        </agm-polyline>-->
<!--                                    </ng-container>-->
<!--                                    <ng-template #yesLuxScale>-->
<!--                                        <agm-marker-cluster [imagePath]="'assets/cluster/m'"-->
<!--                                                            [minimumClusterSize]="500"-->
<!--                                                            [maxZoom]="17">-->
<!--                                            <ng-container *ngFor="let item of puntiLuceLuxScale trackBy:trackRows">-->
<!--                                                <agm-marker-->
<!--                                                        [visible]="false"-->
<!--                                                        [latitude]="item.location.lat"-->
<!--                                                        [longitude]="item.location.lng">-->
<!--                                                    <agm-info-window>-->
<!--                                                        lux:{{item.lux}}-->
<!--                                                    </agm-info-window>-->
<!--                                                </agm-marker>-->
<!--                                                <agm-circle [latitude]="item.location.lat"-->
<!--                                                            [longitude]="item.location.lng"-->
<!--                                                            [radius]="2"-->
<!--                                                            [fillOpacity]="0.6"-->
<!--                                                            [fillColor]="getPrendiColoreInbaseAlValore(item.lux)"-->
<!--                                                >-->
<!--                                                </agm-circle>-->
<!--                                            </ng-container>-->
<!--                                        </agm-marker-cluster>-->
<!--                                    </ng-template>-->
<!--                                    <ng-container *ngIf="isSetInterdistance">-->
<!--                                        <agm-polyline-->
<!--                                                *ngFor="let interdistanza of interdistances"-->
<!--                                                (lineClick)="clicLine($event,interdistanza.objectId)"-->
<!--                                                (lineMouseOver)="mouseOver($event,interdistanza.objectId)"-->
<!--                                                (lineMouseOut)="mouseOut($event,interdistanza.objectId)"-->
<!--                                                [strokeWeight]="'5'"-->
<!--                                                [strokeOpacity]="0.7"-->
<!--                                                [strokeColor]="interdistanza.color">-->
<!--                                            <ng-container>-->
<!--                                                &lt;!&ndash;                                            <agm-icon-sequence [fixedRotation]="false"&ndash;&gt;-->
<!--                                                &lt;!&ndash;                                                               [strokeWeight]="'3'"&ndash;&gt;-->
<!--                                                &lt;!&ndash;                                                               [path]="'FORWARD_OPEN_ARROW'">&ndash;&gt;-->
<!--                                                &lt;!&ndash;                                            </agm-icon-sequence>&ndash;&gt;-->
<!--                                                <agm-polyline-point [latitude]="interdistanza.start.latitude"-->
<!--                                                                    [longitude]="interdistanza.start.longitude"-->
<!--                                                >-->
<!--                                                </agm-polyline-point>-->
<!--                                                <agm-polyline-point-->
<!--                                                        [latitude]="interdistanza.end.latitude"-->
<!--                                                        [longitude]="interdistanza.end.longitude"-->
<!--                                                >-->
<!--                                                </agm-polyline-point>-->
<!--                                                <agm-info-window-->
<!--                                                        (infoWindowClose)="removePositionInfoWindoInPolyLine(interdistanza.objectId,true)"-->
<!--                                                        disableAutoPan="true" [isOpen]="true"-->
<!--                                                        *ngIf="getPositionInfoWindoInPolyLine(interdistanza.objectId)!=undefined"-->
<!--                                                        [latitude]="getPositionInfoWindoInPolyLine(interdistanza.objectId).lat"-->
<!--                                                        [longitude]="getPositionInfoWindoInPolyLine(interdistanza.objectId).lng">-->
<!--                                                    <h5 [ngStyle]="{borderColor:interdistanza.color}"-->
<!--                                                        class="distanceLine">-->
<!--                                                        <span class="childDistanceLine">{{interdistanza.distance}}-->
<!--                                                            m</span>-->
<!--                                                    </h5>-->
<!--                                                </agm-info-window>-->
<!--                                            </ng-container>-->
<!--                                        </agm-polyline>-->
<!--                                    </ng-container>-->
<!--                                    &lt;!&ndash; 3 bottoni in alto a sinistra&ndash;&gt;-->

<!--                                    <button (click)="openFilter()" class="button-class" color="accent" id="map-nav"-->
<!--                                            mat-flat-button style="margin-left:10px; margin-top: 10px">-->
<!--                                        <i class="material-icons">search</i>-->
<!--                                    </button>-->
<!--                                    <button fab (click)="openMapInfo()" class="button-class" color="accent"-->
<!--                                            id="map-info" mat-flat-button style="margin-left:10px;margin-top: 10px">-->
<!--                                        <i class="material-icons">info</i>-->
<!--                                    </button>-->
<!--                                    <button (click)="mapRefresh()" class="button-class" color="accent"-->
<!--                                            id="map-refresh"-->
<!--                                            mat-flat-button style="margin-left:10px;margin-top: 10px">-->
<!--                                        <i class="material-icons">replay</i>-->
<!--                                    </button>-->
<!--                                    &lt;!&ndash;  bottoni in alto a destra&ndash;&gt;-->
<!--                                    <button (click)="settings()" class="button-class" color="accent"-->
<!--                                            id="map-settings"-->
<!--                                            mat-flat-button style="margin-right:10px;margin-top: 10px">-->
<!--                                        <i class="material-icons">settings</i>-->
<!--                                    </button>-->
<!--                                    <div id="map-luxScale"-->
<!--                                         [hidden]="!abbonamentoIlluminametoAttivo">-->
<!--                                        <button [disabled]="!abbonamentoIlluminametoAttivo"-->
<!--                                                (click)="luxScale()" class="button-class" color="accent"-->
<!--                                                mat-flat-button style="margin-right:10px;margin-top: 10px">-->

<!--                                            <img src="assets/icon/dashboard/luxScale.png" style="width: 24px">-->
<!--                                        </button>-->
<!--                                    </div>-->
<!--                                    <div id="map-luxScaleDisabled" [hidden]="!abbonamentoIlluminametoAttivo">-->
<!--                                        <button (click)="disabledLuxScale()" class="button-class" color="accent"-->
<!--                                                [disabled]="luxScaleOff"-->
<!--                                                mat-flat-button style="margin-right:10px;margin-top: 10px">-->
<!--                                            <img src="assets/icon/dashboard/disableLuxScale.png" style="width: 24px">-->
<!--                                        </button>-->
<!--                                    </div>-->
<!--                                    <div id="map-scale"-->
<!--                                         style="margin-right:10px;margin-top: 10px">-->
<!--                                        <app-bar-scale-color-->
<!--                                                *ngIf="elementiScalaLux!=undefined && (!luxScaleOff||!interdistanceOff)"-->
<!--                                                [ElementidellaScala]="elementiScalaLux"-->
<!--                                                [min]="luxmin"-->
<!--                                                [max]="luxmax"-->
<!--                                                [unitOfMeasure]="(!luxScaleOff)?'Lux':'m'"-->
<!--                                        ></app-bar-scale-color>-->
<!--                                    </div>-->
<!--                                    <button (click)="addFilterToPlan(plan.objectId)" class="button-class" color="accent"-->
<!--                                            id="map-aggiornaPlanimetria" mat-flat-button-->
<!--                                            style="margin-right:10px;margin-top: 10px" >-->
<!--                                        <div style="font-weight: bold" *ngIf="modalitaResponsive;else withimages">-->
<!--                                            {{'save_planimetry' | translate}}</div>-->
<!--                                        <ng-template #withimages>-->
<!--                                            <img src="assets/icon/dashboard/refreshIcon.png" style="width: 24px">-->
<!--                                        </ng-template>-->
<!--                                    </button>-->
<!--                                    <div>-->
<!--                                        <button (click)="visualizedLightPointInMapWindow()" class="button-class"-->
<!--                                                color="accent"-->
<!--                                                id="map-eye" mat-mini-fab-->
<!--                                                style="margin-right:10px;margin-bottom:20px"-->
<!--                                                [disabled]="disabledEyeButton">-->
<!--                                            <img src="assets/icon/dashboard/Eye_Light_Bulb_40x40.png"-->
<!--                                                 style="max-width: 24px;">-->
<!--                                        </button>-->
<!--                                        <button (click)="notvisualizedLightPointInMapWindow()" class="button-class"-->
<!--                                                color="accent" id="map-hidden-eye" mat-mini-fab-->
<!--                                                style="margin-right:10px;margin-bottom:20px"-->
<!--                                                [disabled]="disabledHiddenEyeButton">-->
<!--                                            <img src="assets/icon/dashboard/Eye_Light_Bulb_hidden40x40.png"-->
<!--                                                 style="max-width: 24px;">-->
<!--                                        </button>-->
<!--                                        <div [hidden]="!isSetInterdistance" id="map-hidden-interdistance">-->
<!--                                            <button-->
<!--                                                    (click)="disableInterdistance()"-->
<!--                                                    class="button-class"-->
<!--                                                    color="primary"-->
<!--                                                    mat-mini-fab-->
<!--                                                    style="margin-right:10px;margin-bottom:20px">-->
<!--                                                <img src="assets/icon/dashboard/interdistanceDisable.svg"-->
<!--                                                     style="max-width: 24px;">-->
<!--                                            </button>-->
<!--                                        </div>-->
<!--                                        <div [hidden]="!isSetInterdistance"-->
<!--                                             id="map-interdistancesDetail">-->
<!--                                            <div class="interdistancesDetail" *ngIf="!!interdistanceDetail">-->
<!--                                                <h4>{{'streets.interdistance'|translate}}</h4>-->
<!--                                                <div style="margin-right: 15px"-->
<!--                                                     class="interdistancesDetail-child">{{'total'|translate}}-->
<!--                                                    : {{interdistanceDetail.total}}-->
<!--                                                </div>-->
<!--                                                <div class="interdistancesDetail-child">{{'maximum'|translate}}-->
<!--                                                    : {{interdistanceDetail.maximun}}-->
<!--                                                    <div class="circle"-->
<!--                                                         [ngStyle]="{backgroundColor:getPrendiColoreInbaseAlValore(interdistanceDetail.maximun)}"></div>-->
<!--                                                </div>-->
<!--                                                <div class="interdistancesDetail-child">{{'average'|translate}}-->
<!--                                                    : {{interdistanceDetail.average}}-->
<!--                                                    <div class="circle"-->
<!--                                                         [ngStyle]="{backgroundColor:getPrendiColoreInbaseAlValore(interdistanceDetail.average)}"></div>-->
<!--                                                </div>-->
<!--                                                <div class="interdistancesDetail-child">{{'minimum'|translate}}-->
<!--                                                    : {{interdistanceDetail.minimum}}-->
<!--                                                    <div class="circle"-->
<!--                                                         [ngStyle]="{backgroundColor:getPrendiColoreInbaseAlValore(interdistanceDetail.minimum)}"></div>-->
<!--                                                </div>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                        <button (click)="visualizzaTuttiIMarcker(true)" class="button-class"-->
<!--                                                color="accent" id="map-zoom" mat-mini-fab-->
<!--                                                matTooltip="{{'dashboard.button.markerSuMap' | translate}}"-->
<!--                                                style="margin-right:10px;margin-bottom:20px" [disabled]="false">-->
<!--                                            <img src="assets/icon/dashboard/zoomout.png"-->
<!--                                                 style="max-width: 24px;">-->
<!--                                        </button>-->
<!--                                        <app-button-->
<!--                                                (changeVisibility)="destroyDrawingManager()"-->
<!--                                                [vis]="visible" [attr.id]="'drawing'"-->
<!--                                                iconClose="add"-->
<!--                                                iconOpened="clear"-->
<!--                                                class="button-position-newreport-newgroup">-->
<!--                                            <ng-container *ngIf="captureMarker;else noCapture">-->
<!--                                                <button mat-icon-button (click)="onEndCaptureMarker('reporting')"-->
<!--                                                        *ngIf="gestore && abbonamentoAttivo"-->
<!--                                                        matTooltip="{{'dashboard.button.create_report' | translate}}"-->
<!--                                                        style="margin-bottom: -10px;"-->
<!--                                                        [disabled]="disableGroupReportsButton">-->
<!--                                                    <i class="material-icons">warning</i>-->
<!--                                                </button>-->
<!--                                                <button *ngIf="gestore && (abbonamentoProgettazioneAttivo || userAlmenoLivello5)"-->
<!--                                                        mat-icon-button-->
<!--                                                        (click)="onEndCaptureMarker('groups')"-->
<!--                                                        matTooltip="{{'dashboard.button.create_group' | translate}}"-->
<!--                                                        style="margin-bottom: -10px;"-->
<!--                                                        [disabled]="disableGroupReportsButton">-->
<!--                                                    <i class="material-icons">group_work</i>-->
<!--                                                </button>-->
<!--                                                <button *ngIf="gestore && (abbonamentoProgettazioneAttivo || userAlmenoLivello5)"-->
<!--                                                        mat-icon-button color="primary"-->
<!--                                                        (click)="onEndCaptureMarker('interdistance')"-->
<!--                                                        matTooltip="{{'dashboard.button.calculateDistance' | translate}}"-->
<!--                                                        [disabled]="disableGroupReportsButton"-->
<!--                                                        style="margin-bottom: -10px;">-->
<!--                                                    <img src="/assets/icon/dashboard/interdistance.svg"-->
<!--                                                         style="max-width: 24px;">-->
<!--                                                </button>-->
<!--                                            </ng-container>-->
<!--                                            <ng-template #noCapture>-->
<!--                                                <button mat-icon-button (click)="activeCaptureMarker()"-->
<!--                                                        *ngIf="gestore && (abbonamentoAttivo||userAlmenoLivello5)"-->
<!--                                                        matTooltip="{{'dashboard.button.create_report' | translate}} {{'dashboard.button.create_group' | translate}}"-->
<!--                                                        style="margin-bottom: -10px;"-->
<!--                                                        [disabled]="disableGroupReportsButton">-->
<!--                                                    <img src="/assets/icon/dashboard/handPointer{{isDisableIcon(disableGroupReportsButton)}}.svg"-->
<!--                                                         style="max-width: 24px;">-->
<!--                                                </button>-->
<!--                                            </ng-template>-->
<!--                                            <button *ngIf="gestore && visibleUndoButton" mat-icon-button-->
<!--                                                    (click)="ripristinaSpostaPuntiLuceQuadri()"-->
<!--                                                    [disabled]="disableUndoButton"-->
<!--                                                    matTooltip="{{'dashboard.button.spostaPuntoLuce' | translate}}"-->
<!--                                                    style="margin-bottom: -10px;">-->
<!--                                                <i class="material-icons">undo</i>-->
<!--                                            </button>-->
<!--                                            <button *ngIf="gestore" mat-icon-button color="primary"-->
<!--                                                    (click)="batchEdit()"-->
<!--                                                    [disabled]="!isSetAndContainElement(windowsRowsR)&&!isSetAndContainElement(rowsR)"-->
<!--                                                    matTooltip="{{'dashboard.button.modifica' | translate}}"-->
<!--                                                    style="margin-bottom: -10px;">-->
<!--                                                <img src="/assets/icon/dashboard/editLight{{isDisableIcon(!isSetAndContainElement(windowsRowsR)&&!isSetAndContainElement(rowsR))}}.png"-->
<!--                                                     style="max-width: 24px;">-->
<!--                                            </button>-->
<!--                                            <button *ngIf="gestore" mat-icon-button color="primary"-->
<!--                                                    (click)="addPuntoLuceCircuito()"-->
<!--                                                    matTooltip="{{'dashboard.button.aggiungiElemento.title' | translate}}"-->
<!--                                                    [disabled]="disableAddButton" style="margin-bottom: -10px;">-->
<!--                                                <img src="/assets/icon/dashboard/addLight.png" style="max-width: 24px;">-->
<!--                                            </button>-->
<!--                                            <button *ngIf="gestore" mat-icon-button (click)="deleteAllElement()"-->
<!--                                                    [disabled]="disableAddButton || isSetInterdistance"-->
<!--                                                    matTooltip="{{'dashboard.button.deleteAllElement' | translate}}"-->
<!--                                                    style="margin-bottom: -10px;">-->
<!--                                                <i class="material-icons">delete</i>-->
<!--                                            </button>-->
<!--                                            <button *ngIf="gestore" mat-icon-button (click)="abilitaSpostaPuntiLuce()"-->
<!--                                                    [disabled]="disableMoveButton"-->
<!--                                                    matTooltip="{{'dashboard.button.spostaPuntoLuce' | translate}}"-->
<!--                                                    style="margin-bottom: 3px;">-->
<!--                                                <i class="material-icons">zoom_out_map</i>-->
<!--                                            </button>-->
<!--                                        </app-button>-->
<!--                                    </div>-->
<!--                                </agm-map>-->
                            </div>
                        </mat-sidenav-content>
                    </mat-sidenav-container>
                </mat-sidenav-content>
            </mat-sidenav-container>
        </ng-container>
    </mat-sidenav-content>
</mat-sidenav-container>


<!--                    <div *ngIf="!loading" class="map" style="display: flex;flex-direction: column; height: 100%;" #map>-->
<!--                        <button (click)="openFilter()" class="button-class" color="accent" id="map-nav"-->
<!--                                mat-flat-button style="margin-left:10px; margin-top: 10px">-->
<!--                            <i class="material-icons">search</i>-->
<!--                        </button>-->
<!--                        <mat-list role="list">-->
<!--                            <mat-list-item role="listitem" *ngIf="rowsR">Numero dei punti luce {{rowsR.length}} </mat-list-item>-->
<!--                            <mat-list-item role="listitem" *ngFor="let m of rowsR; trackBy:trackRows">-->
<!--                                lat: {{m.location.latitude}}-->
<!--                                lon: {{m.location.longitude}}-->
<!--                                classe: {{m.classe}}</mat-list-item>-->
<!--                        </mat-list>-->
<!--                    </div>-->

<div *ngIf="showBatchEditingTable" (click)="handleBackdropClick()" class="batch-edit-container">
    <div class="close-button-container">
        <button mat-icon-button matTooltip="{{ 'close' | translate }}">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div (click)="handleInnerClick()" class="content">
        <app-batch-light-editor [editableLights]="editableLights"
                                [circuitiAppartenentiAlProgettoInput]="circuitiAppartenentiAlProgettoExport"
                                [stradeAppartenentiAlProgettoInput]="stradeAppartenentiAlProgettoExport">
        </app-batch-light-editor>
    </div>
</div>
