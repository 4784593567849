import {Injectable} from '@angular/core';
import {ordinamentoEcampiTraduzioni} from "../../models/ordinamentoEcampiTraduzioni";
import {TransformForTranslatePipe} from "../pipes/transform-for-translate.pipe";
import {CircuitiParse} from "../../models/Circuiti.Parse";
import {LabelAndListMarkerTextService} from "./label-and-list-marker-text.service";
import {arrayIsSet, className, isNotNullOrUndefined, stringIsSet} from "../../models/Models";
import {LocalSotrageService} from "./local-sotrage.service";

@Injectable({
    providedIn: 'root'
})
export class CircuitLabelService {

    private _campoLabel: string;
    public elementiclasse = ordinamentoEcampiTraduzioni;
    public circuiti: CircuitiParse[] = [];
    public numeroElementiPerValore;


    constructor(private labelMarkerText: LabelAndListMarkerTextService,
                private localStorage: LocalSotrageService) {
        if (stringIsSet(localStorage.labelLabelsCircuits) && !stringIsSet(this.campoLabel)) {
            this.campoLabel = localStorage.labelLabelsCircuits;
        }
    }

    public get campoLabel() {
        return this._campoLabel
    }

    public set campoLabel(value) {
        this._campoLabel = value;
        this.localStorage.labelLabelsCircuits = value;
    }

    private get tipoDato() {
        let tipoDato;
        if (ordinamentoEcampiTraduzioni.Circuiti[this.campoLabel] == null || ordinamentoEcampiTraduzioni.Circuiti[this.campoLabel].type == null) {
            tipoDato = 'elenco';
        } else {
            tipoDato = ordinamentoEcampiTraduzioni.Circuiti[this.campoLabel].type.toLowerCase();
        }
        return tipoDato;
    };

    private get isPointer() {
        return this.tipoDato.includes('pointer');
    }

    private get possibleValues(): string[] {
        if (this.isPointer) {
            return (ordinamentoEcampiTraduzioni.Circuiti[this.campoLabel] &&
                ordinamentoEcampiTraduzioni.Circuiti[this.campoLabel].hasOwnProperty('possibleValues') &&
                typeof ordinamentoEcampiTraduzioni.Circuiti[this.campoLabel].possibleValues === 'string') ?
                ordinamentoEcampiTraduzioni.Circuiti[this.campoLabel].possibleValues.split('.') : [];
        } else {
            return (
                ordinamentoEcampiTraduzioni.Circuiti[this.campoLabel] &&
                ordinamentoEcampiTraduzioni.Circuiti[this.campoLabel].hasOwnProperty('possibleValues')) ?
                ordinamentoEcampiTraduzioni.Circuiti[this.campoLabel].possibleValues : [];
        }
    }

    private typeStringIsNotNull(value) {
        return (typeof value === 'string') ? value.trim().length > 0 : true;
    }

    private getText(circuito) {
        let tipoDato = this.tipoDato;
        if (ordinamentoEcampiTraduzioni.Circuiti.hasOwnProperty(this.campoLabel) && ordinamentoEcampiTraduzioni.Circuiti[this.campoLabel].getValueInLabel != null) {
            const {
                key,
                showingLikeType
            } = ordinamentoEcampiTraduzioni.Circuiti[this.campoLabel].getValueInLabel(undefined);
            tipoDato = showingLikeType
        }
        return this.labelMarkerText.getTextLabel(className.circuiti, circuito, tipoDato, this.campoLabel, this.possibleValues)
    }


    private getNumberElementLoad(circuito): { text: string, key: string } {
        let text: string = this.getText(circuito);
        const filterPipe = new TransformForTranslatePipe();
        const chiaveSenzaSpazi = filterPipe.transform(text) as string;
        text = (text.length > 13) ? text.substring(0, 13) + '...' : text;
        return {text: text, key: chiaveSenzaSpazi};
    }

    public calcNumeroElementiPerValore(campoLabel, circuiti) {
        this.campoLabel = campoLabel;
        this.circuiti = circuiti;
        let numeroElementiPerLabel;
        if (isNotNullOrUndefined(this.campoLabel)) {
            numeroElementiPerLabel = {};
            if (arrayIsSet(this.circuiti) && isNotNullOrUndefined(this.campoLabel)) {
                this.circuiti.forEach((circuito) => {
                    const textKey = this.getNumberElementLoad(circuito);
                    if (isNotNullOrUndefined(numeroElementiPerLabel[textKey.key])) {
                        numeroElementiPerLabel[textKey.key] += 1;
                    } else {
                        numeroElementiPerLabel[textKey.key] = 1;
                    }
                });
            }
        }
        this.numeroElementiPerValore = numeroElementiPerLabel
    }

    updateValues() {
        this.calcNumeroElementiPerValore(this.campoLabel, this.circuiti);
    }

    public getLabelMarker(circuito: CircuitiParse, color: string): {
        color: string,
        fontWeight: string,
        fontSize: string,
        text
    } {
        if (!isNotNullOrUndefined(this.campoLabel)) {
            return null;
        } else {
            const text = this.getNumberElementLoad(circuito).text;
            return {
                color: color,
                fontWeight: 'bold',
                fontSize: '12px',
                text: text,
            };
        }
    }


}
