import {Injectable} from '@angular/core';
import * as Parse from 'parse';
import {Observable, Subject} from "rxjs";
import {Router} from '@angular/router';
import {AlertService} from "./alert.service";
import {MapService} from "./map.service";
import {FilterService} from "./filter.service";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private resp;
    private loginEmitter: Subject<boolean> = new Subject<boolean>();

    constructor(private router: Router,
                private alertService: AlertService,
                private mapsService: MapService,
                private filterService: FilterService,
                protected translate: TranslateService) {

    }

    public async login(username, password) {
        let result;
        try {
            result = await Parse.User.logIn(username, password);
            this.loginEmitter.next(true);
            return this.resp = {'username': result.getUsername()};
        } catch (e) {
            this.loginEmitter.next(false);
            return this.resp = {'code': e.code, 'message': e.message};
        }
    }

    public async signup(body) {
        let result;
        try {
            const user = new Parse.User();
            user.set('username', body.email);
            user.set('email', body.email);
            user.set('password', body.password);
            user.set('nome', body.name);
            user.set('cognome', body.surname);
            user.set('cellulare', body.phone);
            await user.save();
            let username = body.email;
            let password = body.password;
            result = await this.login(username, password);
            return result;

        } catch (e) {
            return this.resp = {'code': e.code, 'message': e.message};
        }

    }


    public logout() {
        try {
            Parse.User.logOut().then(() => {
                localStorage.removeItem('role');
                localStorage.removeItem('projectId');
                this.mapsService.destroyAllFilter();
                this.filterService.destroyAllFilter();
                this.loginEmitter.next(false);
                this.goToLogIn();
            });
        } catch (e) {
            return e;
        }
    }


    public async recovery(value) {

        try {
            await Parse.User.requestPasswordReset(value.email);
            return this.resp = {
                'status': 200,
                'message': this.translate.instant('services.request_completed_successfully')
            };
        } catch (e) {
            return this.resp = {'code': e.code, 'message': e.message};
        }
    }

    public async reset(passwords) {
        let result;
        try {
            let user = Parse.User.current();
            user.setPassword(passwords.password);
            result = user.save();
            return this.resp = {'user': result.toJSON()};
        } catch (e) {
            return this.resp = {'code': e.code, 'message': e.message};
        }
    }

    public getObservableForLogin(): Observable<boolean> {
        return this.loginEmitter.asObservable();
    }

    public async attivazioneAutentificazione2fattori() {
        const link = await Parse.Cloud.run('configure2FALogin');
        return link;
    }

    public async signUpDueFattori(username, otp) {
        const res = await Parse.Cloud.run('send2FAToken',
            {
                username: username,
                otp: otp
            }, {});
        return res;
    }



    goToLogIn() {
        this.router.navigate(['/auth/login']);
    }

}
