<mat-dialog-content>
    <mat-paginator [pageSizeOptions]="[ 10, 50, 100,500]" aria-label="Select page of users"></mat-paginator>
    <form [formGroup]="form" [hidden]="form==null">
        <table mat-table [dataSource]="dataSource" matSort matSortActive="checkBox" matSortDirection="desc"
               class="huna-table">
            <ng-container *ngFor="let column of displayedColumns">
                <ng-container [matColumnDef]="column">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getTraductions(column)}}</th>
                    <td mat-cell *matCellDef="let el">
                        <ng-container *ngIf="column!='checkBox';else isCheckBox">
                            {{getValue(el, column)}}
                        </ng-container>
                        <ng-template #isCheckBox>
                            <mat-checkbox [formControlName]="getId(el)" color="primary"
                                          (change)="clickCheckBox(el,$event)"></mat-checkbox>
                        </ng-template>
                    </td>
                </ng-container>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-flat-button [mat-dialog-close]="undefined" color="accent">
        <mat-icon>clear</mat-icon>
    </button>
    <button mat-flat-button (click)="save()" color="primary">
        <mat-icon>save</mat-icon>
    </button>
</mat-dialog-actions>