import * as Parse from 'parse';
import {Injectable} from '@angular/core';
import {UserService} from './user.service';
import {MapService} from './map.service';
import {PuntiLuceParse} from '../../models/PuntiLuce.Parse';
import {StradeParse} from '../../models/Strade.Parse';
import {fromPromise} from 'rxjs/internal-compatibility';
import {BehaviorSubject, EMPTY, forkJoin, interval, Observable, of, Subscription, throwError} from 'rxjs';
import {DownloadJsonHeaderType, DownloadJsonObjectType, FileServiceService} from "./file-service.service";
import {FotometriaParse} from "../../models/Fotometria.Parse";
import {
    arrayIsSet,
    className,
    cleanToSpecialCharacterAndLowerCaseString,
    KeyStringValueAny,
    objectForkJoin$,
    PaginationParserequestType,
    paramsApiParse,
    stringIsSet,
    stringIsSetWithoutSpecialCharacter,
    valueOverlapStrings
} from "../../models/Models";
import {ProgettiParse} from "../../models/Progetti.Parse";
import {ordinamentoEcampiTraduzioni} from "../../models/ordinamentoEcampiTraduzioni";
import {TranslateService} from "@ngx-translate/core";
import {GruppoFotometrieParse} from "../../models/GruppoFotometrie.Parse";
import {catchError, delay, expand, map, switchMap} from "rxjs/operators";
import {ProjectService} from "./project.service";
import {FotometrieUtilsService} from "./fotometrie-utils.service";
import {GruppoFotometrieService} from "./gruppo-fotometrie.service";
import {CaricoEsogenoParse} from "../../models/CaricoEsogeno.Parse";


@Injectable({
    providedIn: 'root',
})
export class StreetsService {
    public matchKeyCSVParseClass = {
        objectId: ['Id oggetto'],
        tipologiaAreaIlluminata: ['Tipologia area illuminata'],
        fotometrie: {fotometria: ['Fotometria']},
        gruppoFotometrie: ['Gruppo fotometrie'],
        includiMarciapiedi: ['Includi marciapiedi'],
        verificaIPEI_A: ['IPEI > B'],
        calcoloSemicilindrico: ['Calcolo semicilindrico'],
        isCentroStorico: ['Centro storico'],
        lampade: {
            altezza: ['h'],
            origine_x: ['x'],
            origine_y: ['y'],
            sbraccio: ['Sbraccio'],
            angoloRotazione: ['Rotazione'],
            angoloInclinazione: ['Inclinazione'],
            gruppo: ['gruppo']
        },
        layout: {
            tipo: ['Layout'],
            categoriaIlluminotecnica: ['Classificazione'],
            larghezza: ['Larghezza'],
            numeroCorsie: ['Numero corsie']
        },
        interdistanza: ['Interdistanza'],
        note: ['Note'],
        fattoreManutenzione: ['Fattore di manutenzione'],
        nome: ['Nome'],
    }

    public tipologiaAreaIlluminata = ordinamentoEcampiTraduzioni.PuntiLuce.tipologiaAreaIlluminata.possibleValues;
    private streetsInQueueEmit = new BehaviorSubject<StreetStateInQueue>({});
    private subscriptionInitStreet: Subscription | undefined;
    public streetsInQueue$: Observable<StreetStateInQueue> = this.streetsInQueueEmit.asObservable();

    private fotomotrie: { [k: string]: FotometriaParse } = {};
    private gruppoFotomotrie: { [k: string]: GruppoFotometrieParse } = {};

    getTypeKeyStreet(key: string) {
        const queryFotoMetria = new FotometriaParse().query
        const queryGruppoFotometrie = new GruppoFotometrieParse().query
        const typesKey = {
            objectId: {type: 'string'},
            nome: {type: 'string'},
            fotometrie: {type: 'pointer', pointer: 'Fotometria', query: queryFotoMetria, keyName: 'nomeFile'},
            gruppoFotometrie: {
                type: 'pointer',
                pointer: 'GruppoFotometrie',
                query: queryGruppoFotometrie,
                keyName: 'name'
            },
            note: {type: 'string'},
            interdistanza: {type: 'number'},
            layout: {type: 'array'},
            lampade: {type: 'array'},
            fattoreManutenzione: {type: 'number'},
            tipologiaAreaIlluminata: {
                type: 'elenco', possibleValues: this.tipologiaAreaIlluminata.map(key => {
                    const traduction = this.translateService.instant('dashboard_sidenav.PuntiLuce.tipologiaAreaIlluminata.possibleValues.' + key)
                    return {key, traduction}
                })
            },
            isCentroStorico: {type: 'bool'},
            calcoloSemicilindrico: {type: 'bool'},
            includiMarciapiedi: {type: 'bool'},
            verificaIPEI_A: {type: 'bool'},
        }
        return typesKey[key];
    }

    castValueByeKey(key, value) {
        let castValue;
        const type = this.getTypeKeyStreet(key)
        if (value == null || key == null || type == null) {
            return castValue
        } else if (type.type == 'bool') {
            const yes = this.translateService.instant('yes')
            castValue = stringIsSet(value) ? cleanToSpecialCharacterAndLowerCaseString(value) == cleanToSpecialCharacterAndLowerCaseString(yes) : false;
        } else if (type.type == 'number') {
            let toStringValue = value.toString != null ? value.toString() : value
            toStringValue = typeof toStringValue == "string" ? value.replace(',', '.') : value
            castValue = parseFloat(toStringValue)
            if (isNaN(castValue)) {
                castValue = undefined;
            }
        } else if (type.type == 'elenco') {
            const possibleValues = type.possibleValues;
            if (arrayIsSet(possibleValues)) {
                const cleanValue = cleanToSpecialCharacterAndLowerCaseString(value)
                const possibleValue = possibleValues.find(possibleValue => {
                    const castPossibleValue = cleanToSpecialCharacterAndLowerCaseString(possibleValue.key)
                    const castPossibleTraduction = cleanToSpecialCharacterAndLowerCaseString(possibleValue.traduction)
                    return castPossibleValue == cleanValue || castPossibleTraduction == cleanValue;
                })
                if (possibleValue != null) {
                    castValue = possibleValue.key
                }
                if (castValue == null) {
                    castValue = value;
                }
            }
        } else if (type.type == 'string') {
            castValue = value.toString != null ? value.toString() : value
            if (!stringIsSet(castValue)) {
                castValue = undefined;
            }
        } else {
            castValue = value
        }
        return castValue
    }

    castValueByeKeySubKey(key, subKey, value) {
        const typesKey = {
            layout: {
                tipo: {type: 'string'},
                categoriaIlluminotecnica: {type: 'string'},
                larghezza: {type: 'number'},
                numeroCorsie: {type: 'number'}
            },
            lampade: {
                altezza: {type: 'number', defaultValue: 0},
                origine_x: {type: 'number', defaultValue: 0},
                origine_y: {type: 'number', defaultValue: 0},
                sbraccio: {type: 'number', defaultValue: 0},
                angoloRotazione: {type: 'number', defaultValue: 0},
                angoloInclinazione: {type: 'number', defaultValue: 0},
                gruppo: {type: 'number', defaultValue: 1}
            },
        }
        let castValue;
        const type = typesKey[key][subKey].type
        if (value == null || key == null || type == null) {
            return castValue
        } else if (type == 'number') {
            let toStringValue = value.toString != null ? value.toString() : value
            toStringValue = typeof toStringValue == "string" ? toStringValue.replace(',', '.') : toStringValue
            castValue = parseFloat(toStringValue);
            if (isNaN(castValue)) {
                if (typesKey[key][subKey].defaultValue != null) {
                    castValue = typesKey[key][subKey].defaultValue
                } else {
                    castValue = undefined;
                }
            }
        } else if (type == 'string') {
            castValue = value.toString != null ? value.toString() : value
            if (key == 'layout') {
                if (subKey == 'tipo') {
                    castValue = cleanToSpecialCharacterAndLowerCaseString(castValue) == 'parcheggio' ? 'parcheggio' : castValue;
                    const find = Object.values(StreetLayoutItemType)
                        .find(valueEnum => {
                            return cleanToSpecialCharacterAndLowerCaseString(castValue) === cleanToSpecialCharacterAndLowerCaseString(valueEnum) || (valueOverlapStrings(valueEnum, castValue) * 100 > 90)
                        })
                    if (find != null) {
                        castValue = find;
                    }
                } else if (subKey === 'categoriaIlluminotecnica') {
                    let find: any = Object.values(RoadLightCategory)
                        .find(valueEnum => cleanToSpecialCharacterAndLowerCaseString(castValue) === cleanToSpecialCharacterAndLowerCaseString(valueEnum))
                    if (find == null) {
                        find = Object.values(SidewalkLightCategory)
                            .find(valueEnum => cleanToSpecialCharacterAndLowerCaseString(castValue) === cleanToSpecialCharacterAndLowerCaseString(valueEnum))
                    }
                    if (find != null) {
                        castValue = find;
                    }
                }
            }
            if (!stringIsSet(castValue)) {
                castValue = undefined;
            }
        } else {
            castValue = value
        }
        return castValue
    }

    get projectWithOrganizzazioneComune$(): Observable<ProgettiParse> {
        return fromPromise(this.mapService.getCurrentProjectMy()
            .fetchWithInclude(['organizzazione', 'comune']))
    }

    get project(): ProgettiParse {
        return this.projectService.currentProject;
    }

    getStreetWithPhotometry$(): Observable<StradeParse[]> {
        const query = new StradeParse().query;
        query.equalTo('progetto', this.mapService.getCurrentProjectMy())
        query.exists('fotometrie');
        return fromPromise(query.find());
    }

    getStreetWithoutPhotometry$(): Observable<StradeParse[]> {
        const query = new StradeParse().query;
        query.equalTo('progetto', this.mapService.getCurrentProjectMy())
        query.doesNotExist('fotometrie');
        return fromPromise(query.find());
    }

    getRequiredKeys(key): string[] {
        const requiredKey = {
            fotometrie: {fotometria: {required: true}},
            fattoreManutenzione: {required: true},
            note: {required: false},
            nome: {required: true},
            interdistanza: {required: false},
            layout: {
                tipo: {required: true},
                categoriaIlluminotecnica: {required: false},
                larghezza: {required: true},
                numeroCorsie: {required: false}
            },
            lampade: {
                altezza: {required: true},
                origine_x: {required: true},
                origine_y: {required: false},
                sbraccio: {required: false},
                angoloRotazione: {required: false},
                angoloInclinazione: {required: false},
                gruppo: {required: false}
            }
        }
        return Object.keys(requiredKey[key])
            .filter(subKey => requiredKey[key][subKey].required)
            .map(key => key);
    }

    constructor(
        private userService: UserService,
        private mapService: MapService,
        private projectService: ProjectService,
        private fileService: FileServiceService,
        private translateService: TranslateService,
        private fotometrieService: FotometrieUtilsService,
        private gruppoFotomotrieService: GruppoFotometrieService
    ) {
    }

    saveLocaltStreetOnDatabase(strada: StradeParse): Observable<StradeParse> {
        strada.progetto = this.mapService.getCurrentProjectMy();
        if (arrayIsSet(strada.lampade)) {
            strada.lampade = strada.lampade.map(lampada => {
                if (lampada.gruppo == null) {
                    lampada.gruppo = 1;
                }
                return lampada
            })
        }
        return fromPromise(strada.save());
    }

    updateStreet(strada: StradeParse, values: any): Observable<StradeParse> {
        if (values != null && strada != null) {
            Object.keys(values).forEach(key => {
                if (values[key] != null) {
                    strada[key] = values[key]
                }
            })
            return fromPromise(strada.save());
        }
        return of(strada)
    }

    async save(street: Pick<Street, Exclude<keyof Street, 'updatedAt' | 'createdAt'>>): Promise<string> {
        let strada;
        if (street.objectId) {
            strada = await this.getParse(street.objectId);
        } else {
            const Strade = Parse.Object.extend('Strade');
            strada = new Strade();
            strada.set('progetto', this.mapService.getCurrentProjectMy());
        }
        for (const key in street) {
            if (key !== 'objectId') {
                strada.set(key, street[key]);
            }
        }
        const result = await strada.save(null, {
            sessionToken: this.userService.sessionToken(),
        });
        return street.objectId = result.id;
    }

    async compute(stradaId: string, photometriesIds: string[], computeSidewalks: boolean): Promise<ComputeResults> {
        const result = await Parse.Cloud.run('eseguiCalcoloIlluminotecnico_v2', {
            fotometrieIds: photometriesIds,
            stradaId,
            verificaMarciapiedi: computeSidewalks
        }, {
            sessionToken: this.userService.sessionToken()
        });
        return {
            allFotometrie: result.allFotometrie.map(p => {
                let attributes = p.attributes;
                if (typeof attributes == "object" && attributes != null) {
                    attributes = {...attributes, objectId: p.id}
                }
                return this.jsonToPhotometry(attributes)
            }),
            allResults: result.allResults.map(r => this.jsonToComputeResult(r)),
            bestResult: result.bestResult && Object.keys(result.bestResult).length > 0 ? this.jsonToComputeResult(result.bestResult) : undefined,
            bestFotometria: result.bestFotometria ? this.parseToPhotometry(result.bestFotometria) : undefined
        };

    }

    jsonToComputeResult(json: { [key: string]: any }): ComputeResult {
        return {
            De: json.De,
            Dp: json.Dp,
            ULR: json.ULR,
            categoriaIntensitaLuminosa: json.categoriaIntensitaLuminosa,
            indiceAbbagliamento: json.indiceAbbagliamento,
            max_intensitaLuminose: json.max_intensitaLuminose,
            ore_esercizio_anno: json.ore_esercizio_anno,
            potenza_km: json.potenza_km,
            risultati: json.risultati,
            verificato: json.verificato,
            efficienza: json.efficienza,
            flusso: json.flusso,
            potenza: json.potenza,
            IPEI: json.IPEI,
            IPEA: json.IPEA,
        };
    }

    parseToComputeResult(parse: Parse.Object): ComputeResult {
        const json = parse.toJSON();
        return this.jsonToComputeResult(json);
    }

    async getAll(): Promise<Street[]> {
        const coludFunction = (options) => {
            return Parse.Cloud.run('getAllStrade', options)
        }
        return await coludFunction({progettoId: this.project.objectId, includeKeys: ['gruppoFotometrie']})
        // return (await query.find({sessionToken: this.userService.sessionToken()})).map(parse => this.parseToStreet(parse));
    }

    getAllStradeParse$(includeKeys = undefined): Observable<StradeParse[]> {
        return fromPromise(Parse.Cloud.run('getAllStrade', {progettoId: this.project.objectId, includeKeys}))
    }

    async getAllPhotometries(): Promise<any[]> {
        let result = [];
        const options = {sessionToken: this.userService.sessionToken()};
        const Fotometria = Parse.Object.extend('Fotometria');

        const process = async (skip: number): Promise<any[]> => {
            let query = new Parse.Query(Fotometria);
            query.skip(skip);
            query.limit(1000);
            query.ascending('produttore')
                .addAscending('nomeFile');

            const results = await query.find(options);
            result = result.concat(results);
            if (results.length === 1000) {
                return process(result.length);
            } else {
                return result;
            }
        };
        return (await process(0)).map(parse => this.parseToPhotometry(parse));
    }

    parseToStreet(parse: Parse.Object): Street {
        if (parse == null) {
            return;
        }
        const json = parse.toJSON();
        return {
            objectId: json.objectId,
            fattoreManutenzione: json.fattoreManutenzione,
            interdistanza: json.interdistanza,
            lampade: json.lampade,
            layout: json.layout,
            nome: json.nome,
            note: json.note,
            createdAt: json.createdAt,
            updatedAt: json.updatedAt,
            fotometrie: json.fotometrie,
            isCentroStorico: json.isCentroStorico,
            tipologiaAreaIlluminata: json.tipologiaAreaIlluminata,
            gruppoFotometrie: json.gruppoFotometrie,
            includiMarciapiedi: json.includiMarciapiedi,
            verificaIPEI_A: json.verificaIPEI_A,
            noteCalcolo: json.noteCalcolo,
            calcoloSemicilindrico: json.calcoloSemicilindrico
        };
    }

    jsonToPhotometry(json: { [key: string]: any }): Photometry {
        //json è bloccato percio ne creamo una copia modificabile
        json = Object.assign({}, json);
        if (json.potenza !== undefined) {
            json.potenzaMin = json.potenza;
            json.potenzaMax = json.potenza;
        }
        if (json.flussoLuminoso !== undefined) {
            json.flussoMin = json.flussoLuminoso;
            json.flussoMax = json.flussoLuminoso;
        }
        if (json.efficienza !== undefined) {
            json.efficienzaMin = json.efficienza;
            json.efficienzaMax = json.efficienza;
        }
        return {
            objectId: json.objectId,
            nomeFile: json.nomeFile,
            produttore: json.produttore,
            nomeFamiglia: json.nomeFamiglia,
            nomeProdotto: json.nomeProdotto,
            nomeLampada: json.nomeLampada,
            ottica: json.ottica,
            tipologiaCorpoIlluminante: json.tipologiaCorpoIlluminante,
            temperaturaColore: json.temperaturaColore, // Kelvin
            CRI: json.CRI,
            potenza: json.potenza,
            potenzaMin: json.potenzaMin, // Watt
            potenzaMax: json.potenzaMax, // Watt
            flussoLuminoso: json.flussoLuminoso, // Lumen
            flussoMin: json.flussoMin, // Lumen
            flussoMax: json.flussoMax, // Lumen,
            efficienza: json.efficienza,
            efficienzaMin: json.efficienzaMin, // Lumen / Watt
            efficienzaMax: json.efficienzaMax, // Lumen / Watt
        };
    }

    parseToPhotometry(parse: Parse.Object): Photometry {
        const json = parse.toJSON();
        return this.jsonToPhotometry(json);
    }

    async get(objectId: string): Promise<Street> {
        return this.parseToStreet(await this.getParse(objectId));
    }

    async getParse(objectId: string): Promise<Parse.Object> {
        const stradaParse = new StradeParse();
        if (stringIsSet(objectId)) {
            stradaParse.objectId = objectId;
            return await stradaParse.fetch({sessionToken: this.userService.sessionToken()});
        } else {
            return stradaParse;
        }
    }

    public getPuntiLuceWithStreet(streetId: string): Observable<PuntiLuceParse[]> {
        const strada = new StradeParse();
        strada.objectId = streetId;
        const query = new Parse.Query(PuntiLuceParse);
        query.equalTo('progetto', this.mapService.getCurrentProjectMy());
        query.equalTo('strada', strada);
        query.limit(1000);
        return fromPromise(query.find({sessionToken: this.userService.sessionToken()}));
    }

    public async updateVarianteKeyLightPoint(lightPoints: PuntiLuceParse[], varinateKeyForUpdates, chunk = 100): Promise<PuntiLuceParse[]> {
        lightPoints.forEach((lightPoint) => {
            Object.keys(varinateKeyForUpdates).forEach(key => {
                if (lightPoint.hasProperty(key)) {
                    lightPoint[key] = varinateKeyForUpdates[key];
                }
            });
        });
        return await this.mapService.chunkSaveAsyncLightPoint(lightPoints, chunk, false);
    }

    public get advancedRatingSave() {
        return this.mapService.getChunkObservable();
    }

    getDataCsv(rowsRaw: any[]): { rows: DownloadJsonObjectType[], header: DownloadJsonHeaderType[] } {
        const header: DownloadJsonHeaderType[] = [
            {key: 'pageInitPrintStreet', traduction: 'fotometria.pageInitPrintStreet'},
            {key: 'indice', traduction: 'fotometria.indice'},
            {key: 'riferimentoStrada', traduction: 'streets.riferimentoStrada'},
            {key: 'riferimentoElementoStrada', traduction: 'streets.riferimentoElementoStrada'},
            {key: 'tipologiaAreaIlluminata', traduction: 'streets.tipologiaAreaIlluminata.title'},
            {key: 'isCentroStorico', traduction: 'streets.isCentroStorico'},
            {key: 'categoriaIlluminotecnicaStrada', traduction: 'fotometria.categoriaIlluminotecnica'},
            {key: 'conforme', traduction: 'streets.conforme'},
            {key: 'ui', traduction: 'fotometria.ui'},
            {key: 'uo', traduction: 'fotometria.uo'},
            {key: 'ti', traduction: 'fotometria.ti'},
            {key: 'rei', traduction: 'fotometria.rei'},
            {key: 'em', traduction: 'fotometria.em'},
            {key: 'emin', traduction: 'fotometria.emin'},
            {key: 'esc_min', traduction: 'fotometria.esc_min'},
            {key: 'luminanzaMedia', traduction: 'fotometria.luminanzaMedia'},
            {key: 'uiLimiti', traduction: 'fotometria.uiLimiti'},
            {key: 'uoLimiti', traduction: 'fotometria.uoLimiti'},
            {key: 'tiLimiti', traduction: 'fotometria.tiLimiti'},
            {key: 'reiLimiti', traduction: 'fotometria.reiLimiti'},
            {key: 'emLimiti', traduction: 'fotometria.emLimiti'},
            {key: 'eminLimiti', traduction: 'fotometria.eminLimiti'},
            {key: 'escLimiti', traduction: 'fotometria.escLimiti'},
            {key: 'esc_minLimiti', traduction: 'fotometria.esc_minLimiti'},
            {key: 'luminanzaMediaLimiti', traduction: 'fotometria.luminanzaMediaLimiti'},
            {key: 'Dp', notTraduction: 'Dp [kWh/m2 * anno]'},
            {key: 'De', notTraduction: 'De [W/lx * m2]'},
            {key: 'IPEI', notTraduction: 'IPEI'},
            {key: 'distanceLamp', traduction: 'streets.distanceLamp', notTraduction: ' [m]'},
            {key: 'heightFocus', traduction: 'streets.heightFocus', notTraduction: ' [m]'},
            {key: 'distancesFocus', traduction: 'streets.distanceFocus', notTraduction: ' [m]'},
            {key: 'inclinationOutreach', traduction: 'streets.inclinationOutreach', notTraduction: ' [°]'},
            {key: 'lengthOutreach', traduction: 'streets.lengthOutreach', notTraduction: ' [m]'},
            {key: 'ourAnnualExsercize', traduction: 'streets.ourAnnualExsercize'},
            {key: 'consuption', traduction: 'streets.consuption', notTraduction: ' [W/km]'},
            {key: 'ulr_ulor', traduction: 'streets.ulr_ulor'},
            {key: 'maxIntensitaLuminose', traduction: 'streets.maxIntensitaLuminose'},
            {key: 'maxIntensitaLuminoseInfo_1', traduction: 'streets.maxIntensitaLuminose'},
            {key: 'maxIntensitaLuminoseInfo_2', traduction: 'streets.maxIntensitaLuminose'},
            {key: 'categoriaIntensitaLuminosa', traduction: 'streets.classeIntensitaLuminose'},
            {key: 'photometryManufacturer', traduction: 'streets.manufacturer'},
            {key: 'photometryNomeFile', traduction: 'streets.photometry'},
            {key: 'photometryOptics', traduction: 'streets.optics'},
            {key: 'photometryPotenza', traduction: 'fotometrie.potenza', notTraduction: ' [W]'},
            {key: 'photometryFlussoLuminoso', traduction: 'fotometrie.flussoSistema', notTraduction: ' [Lm]'},
            {key: 'photometryEfficienza', traduction: 'fotometrie.efficienza', notTraduction: ' [Lm/W]'},
            {key: 'photometryTemperaturaColore', traduction: 'fotometrie.temperaturaColore', notTraduction: ' [K]'},
            {key: 'photometryIPEA', traduction: 'IPEA'}
        ];

        const keys = header.map(c => c.key)
        const rows = rowsRaw.map(row => {
            const obj: DownloadJsonObjectType = {};
            keys.forEach(key => {
                if (row[key] != null) {
                    obj[key] = row[key]
                } else {
                    obj[key] = '';
                }
            })
            return obj;
        })
        return {header, rows}
    }

    getMaxNumberOfLayoutMaxNumberOfLightMaxNumberOfFotometrie(streets: StradeParse[]): {
        maxNumberOfLayout: number,
        maxNumberOfLight: number,
        maxNumberOfFotometrie: number
    } {
        let maxNumberOfLayout = 0;
        let maxNumberOfLight = 0;
        let maxNumberOfFotometrie = 0;
        streets.forEach(street => {
            const numberOfLayout = arrayIsSet(street.layout) ? street.layout.length : 0
            const numberOfLight = arrayIsSet(street.lampade) ? street.lampade.length : 0
            const numberOfPhotometry = arrayIsSet(street.fotometrie) ? street.fotometrie.length : 0
            if (maxNumberOfLayout < numberOfLayout) {
                maxNumberOfLayout = numberOfLayout
            }
            if (maxNumberOfLight < numberOfLight) {
                maxNumberOfLight = numberOfLight
            }
            if (maxNumberOfFotometrie < numberOfPhotometry) {
                maxNumberOfFotometrie = numberOfPhotometry
            }
        });
        return {maxNumberOfLayout, maxNumberOfLight, maxNumberOfFotometrie}
    }

    getHeaderToExportStreets(maxNumberOfLayout: number, maxNumberOfLight: number, maxNumberOfFotometrie: number): HeaderForCsv[] {
        const header: HeaderForCsv[] = [];
        const objectIsSet = (value: any) => {
            return value != null && typeof value == "object" && arrayIsSet(Object.keys(value))
        }
        const addObjSubKeyToHeader = (maxNumber, key, subKey) => {
            let i = 0
            const keyPointer = this.getTypeKeyStreet(key).keyName;
            while (i < maxNumber) {
                const obj: HeaderForCsv = {
                    objectIdKey: key + subKey + (i + 1),
                    keyStreet: key,
                    position: i,
                    subKey,
                    type: this.getTypeKeyStreet(key).type,
                    keyPointer,
                    traduction: this.matchKeyCSVParseClass[key][subKey][0] + ' ' + (i + 1)
                }
                header.push(obj);
                i++;
            }
        }
        Object.keys(this.matchKeyCSVParseClass)
            .reverse()
            .forEach(key => {
                if (!arrayIsSet(this.matchKeyCSVParseClass[key]) && objectIsSet(this.matchKeyCSVParseClass[key])) {
                    const subKeys = Object.keys(this.matchKeyCSVParseClass[key]);
                    subKeys.forEach(subKey => {
                        if (key == 'layout') {
                            addObjSubKeyToHeader(maxNumberOfLayout, key, subKey)
                        } else if (key == 'lampade') {
                            addObjSubKeyToHeader(maxNumberOfLight, key, subKey)
                        } else if (key == 'fotometrie') {
                            addObjSubKeyToHeader(maxNumberOfFotometrie, key, subKey)
                        }
                    })
                } else {
                    const keyPointer = this.getTypeKeyStreet(key).keyName;
                    const obj: HeaderForCsv = {
                        objectIdKey: key,
                        keyStreet: key,
                        type: this.getTypeKeyStreet(key).type,
                        keyPointer,
                        traduction: this.matchKeyCSVParseClass[key][0]
                    }
                    header.push(obj);
                }

            })
        return header;
    }

    getStreetJsonforCsv(street: StradeParse, header: HeaderForCsv[]) {
        const streetCsv = {}
        const getStreetValue = (street, key: HeaderForCsv) => {
            if (key.type == 'array') {
                return street[key.keyStreet] != null && street[key.keyStreet][key.position] != null && street[key.keyStreet][key.position][key.subKey] != null ? street[key.keyStreet][key.position][key.subKey] : ''
            } else if (key.type == 'pointer') {
                if (key.position != null) {
                    return street[key.keyStreet] != null && street[key.keyStreet][key.position] != null && street[key.keyStreet][key.position][key.keyPointer] != null ? street[key.keyStreet][key.position][key.keyPointer] : ''
                } else {
                    return street[key.keyStreet] != null && street[key.keyStreet][key.keyPointer] ? street[key.keyStreet][key.keyPointer] : ''
                }
            } else if (key.type == 'bool') {
                const value = street[key.keyStreet] != null ? street[key.keyStreet] : ''
                return value == true ? this.translateService.instant('yes') : this.translateService.instant('no');
            } else {
                return street[key.keyStreet] != null ? street[key.keyStreet] : '';
            }
        }
        header.forEach(key => {
            streetCsv[key.objectIdKey] = getStreetValue(street, key)
        })
        return streetCsv;
    }

    assignedPhotometryToStreetsByGroupPhotomeries(streets: StradeParse[]): Observable<any> {
        const streetsId = streets.map(street => street.objectId);
        return fromPromise(Parse.Cloud.run('addStreetsToLightingCalculation', {
            progettoId: this.project.objectId,
            streetsId
        }))
    }

    abortAssignedPhotometryToStreetsByGroupPhotomeries(streets: StradeParse[]): Observable<any> {
        const streetsId = streets.map(street => street.objectId);
        const progettoId = this.project.objectId;
        if (arrayIsSet(streets)) {
            return fromPromise(Parse.Cloud.run('abortStreetCalculation', paramsApiParse({streetsId, progettoId})))
        } else {
            return EMPTY;
        }
    }

    getStreetStateInQueue(projectId: string): Observable<StreetStateInQueue> {
        return fromPromise(Parse.Cloud.run('getStreetCalculationStatus', {
            progettoId: stringIsSet(projectId) ? projectId : this.project.objectId
        }))
    }

    updateValueStreetsInQueue() {
        this.getStreetStateInQueue(this.project.objectId).subscribe(values => {
                this.streetsInQueueEmit.next(values)
            },
            error => {
                console.error(error)
            })
    }

    initGetDynamicStreetInQueue() {
        if (this.subscriptionInitStreet == null) {
            this.updateValueStreetsInQueue();
            this.subscriptionInitStreet = interval(30000)
                .pipe(
                    switchMap(() => this.getStreetStateInQueue(this.project.objectId)),
                )
                .subscribe(values => {
                        this.streetsInQueueEmit.next(values);
                    },
                    error => {
                        console.error(error)
                    })
        }
    }

    destroyGetDynamicStreetInQueue() {
        if (this.subscriptionInitStreet) {
            this.subscriptionInitStreet.unsubscribe();
            this.subscriptionInitStreet = undefined;
        }
    }

    paginationComputeResultStreets$(streets: StradeParse[]): Observable<PaginationParserequestType<{
        calcoliilluminotecnici: ComputeResults,
        strada: StradeParse
    }>> {
        const fetchPage = (page = 0,) => {
            let isLast = page + 1 >= streets.length;
            const progress = Math.ceil(page / streets.length * 100)
            const strada = streets[page];
            const stradaId = strada.objectId;
            const fotometriaId = [strada.fotometrie[0].objectId];
            const includiMarciapiedi = strada.includiMarciapiedi;

            const value$ = fromPromise(this.compute(stradaId, fotometriaId, includiMarciapiedi))
                .pipe(
                    map((calcoliilluminotecnici) => ({calcoliilluminotecnici, strada}))
                )

            return value$.pipe(
                map((items) => {
                    return {
                        items: [items],
                        progress: progress,
                        nextPage: isLast ? undefined : (page += 1),
                        finished: isLast,
                        error: null
                    };
                }),
                catchError(error => {
                    return of({
                        items: undefined,
                        progress: progress,
                        nextPage: isLast ? undefined : (page += 1),
                        finished: isLast,
                        error: error
                    });
                }),
            );
        }
        return fetchPage(0).pipe(
            expand(({nextPage}) => nextPage ? fetchPage(nextPage) : EMPTY),
        )
    }

    getFotometriaByName(name: string): Observable<FotometriaParse> {
        let fotometria$;
        const keyName = name.toLowerCase()
            .replace(/ /g, '');
        if (this.fotomotrie != null && this.fotomotrie[keyName] != null) {
            fotometria$ = of(this.fotomotrie[keyName])
        } else {
            fotometria$ = this.fotometrieService.getFotometrieByName(name).pipe(
                map(fotometria => {
                        if (fotometria != null) {
                            this.fotomotrie[keyName] = fotometria;
                        }
                        return fotometria;
                    }
                )
            )
        }
        return fotometria$
    }

    getGruppoFotometriaByName(name: string): Observable<FotometriaParse> {
        let fotometria$;
        const keyName = name.toLowerCase()
            .replace(/ /g, '');
        if (this.gruppoFotomotrie != null && this.gruppoFotomotrie[keyName] != null) {
            fotometria$ = of(this.gruppoFotomotrie[keyName])
        } else {
            fotometria$ = this.gruppoFotomotrieService.getGruppoFotometrieByName(name).pipe(
                map(fotometria => {
                        if (fotometria != null) {
                            this.gruppoFotomotrie[keyName] = fotometria;
                        }
                        return fotometria;
                    }
                )
            )
        }
        return fotometria$
    }

    public importStreet$(rawFile: { data: any[] }, indexCategories: any[]): Observable<PaginationParserequestType<{
        saved?: StradeParse[],
        error?: any[]
    }>> {
        const fetchPage = (page = 0,) => {
            let isLast = page + 1 >= rawFile.data.length;
            const progress = Math.ceil(page / rawFile.data.length * 100)
            const strada = {};
            const objectQuery = {fotometrie: {}, gruppoFotometrie: {}}
            const row = rawFile.data[page];
            indexCategories.forEach(categoria => {
                const key = Object.keys(categoria)[0];
                const typeQuery = this.getTypeKeyStreet(key);
                if (typeQuery != null && typeQuery.type === 'array') {
                    if (arrayIsSet(categoria[key])) {
                        const arrayValues = categoria[key]
                            .sort((a, b) => a.number - b.number)
                            .filter(value => {
                                const keys = Object.keys(value)
                                    .filter(keyF => keyF != 'number')
                                    .filter(keyF => {
                                        const indexColumn = value[keyF].indexColumn;
                                        return stringIsSetWithoutSpecialCharacter(row[indexColumn])
                                    })

                                const requiredKeys = this.getRequiredKeys(key)
                                const indexNotCompile = requiredKeys.findIndex(requiredKey => !keys.includes(requiredKey))
                                return indexNotCompile < 0
                            })
                            .map(categoria => {
                                const layout = {}
                                Object.keys(categoria).forEach(keyCat => {
                                    if (keyCat != 'number') {
                                        const indexColumn = categoria[keyCat].indexColumn
                                        const value = this.castValueByeKeySubKey(key, keyCat, row[indexColumn])
                                        if (value != null) {
                                            layout[keyCat] = value;
                                        }
                                    }
                                })
                                return layout
                            });
                        strada[key] = arrayValues;
                    }
                    // console.log(row[indexColumn])
                } else if (typeQuery != null && typeQuery.type === 'pointer') {
                    if (typeQuery.pointer === className.gruppiFotometrie && arrayIsSet(categoria[key])) {
                        const indexColumn = categoria[key][0].indexColumn
                        const keyName = row[indexColumn];
                        if (stringIsSetWithoutSpecialCharacter(keyName) && objectQuery.gruppoFotometrie[keyName] == null) {
                            objectQuery.gruppoFotometrie[keyName] = this.getGruppoFotometriaByName(keyName)
                        }
                    } else {
                        categoria[key].forEach(categoria => {
                            Object.keys(categoria)
                                .filter(keyCategoria => {
                                    const indexColumn = categoria[keyCategoria].indexColumn
                                    return keyCategoria != 'number' && stringIsSetWithoutSpecialCharacter(row[indexColumn])
                                })
                                .map(keyCategoria => {
                                    const indexColumn = categoria[keyCategoria].indexColumn
                                    const keyName = row[indexColumn];
                                    if (objectQuery.fotometrie[keyName] == null) {
                                        objectQuery.fotometrie[keyName] = this.getFotometriaByName(keyName)
                                    }
                                });
                        })
                    }
                } else if (typeQuery != null) {
                    if (arrayIsSet(categoria[key])) {
                        const indexColumn = categoria[key][0].indexColumn
                        const value = this.castValueByeKey(key, row[indexColumn])
                        if (value != null) {
                            strada[key] = value
                        }
                    }
                }
            })

            const stradaParse = new StradeParse();
            Object.keys(strada).forEach(key => stradaParse[key] = strada[key])
            let strada$: Observable<StradeParse> = of(stradaParse);
            let stradaToSave$: Observable<StradeParse> = of(stradaParse);
            const queryIsIncludesBeforeSave = Object.keys(objectQuery).findIndex(key => Object.keys(objectQuery[key]).length > 0) >= 0
            if (!queryIsIncludesBeforeSave) {
                stradaToSave$ = this.saveLocaltStreetOnDatabase(stradaParse)
            } else {
                if (Object.keys(objectQuery.fotometrie).length > 0) {
                    strada$ = strada$.pipe(
                        switchMap((stradaToSave) => {
                            return objectForkJoin$(objectQuery.fotometrie).pipe(
                                map((objectFotometrie) => {
                                    Object.keys(objectFotometrie)
                                        .forEach(key => stradaToSave.addFotometria(objectFotometrie[key]))
                                    return stradaToSave
                                })
                            )
                        }))
                }

                if (Object.keys(objectQuery.gruppoFotometrie).length > 0) {
                    strada$ = strada$.pipe(
                        switchMap((stradaToSave) => {
                            return objectForkJoin$(objectQuery.gruppoFotometrie).pipe(
                                map((objectGruppoFotometrie) => {
                                    Object.keys(objectGruppoFotometrie)
                                        .forEach(key => stradaToSave.gruppoFotometrie = objectGruppoFotometrie[key])
                                    return stradaToSave
                                })
                            )
                        }))
                }
                stradaToSave$ = strada$.pipe(
                    switchMap((stradaParseAggiornata) => this.saveLocaltStreetOnDatabase(stradaParseAggiornata))
                )
            }
            return stradaToSave$.pipe(
                map((items) => {
                    return {
                        items: [{saved: [items]}],
                        progress: progress,
                        nextPage: isLast ? undefined : (page += 1),
                        finished: isLast,
                        error: null
                    };
                }),
                catchError(error => {
                    strada['errorMessage'] = error;
                    return of({
                        items: [{error: [strada]}],
                        progress: progress,
                        nextPage: isLast ? undefined : (page += 1),
                        finished: isLast,
                        error: error
                    })
                }),
            );
        }


        return fetchPage(0).pipe(
            expand(({nextPage}) => nextPage ? fetchPage(nextPage) : EMPTY),
        )
    }


    public destroyAllStreet(streets: StradeParse[]): Observable<PaginationParserequestType<StradeParse>> {
        const fetchPage = (page = 0,) => {
            let isLast = page + 1 >= streets.length;
            const progress = Math.ceil(page / streets.length * 100)
            const strada = streets[page];
            const value$ = fromPromise(strada.destroy());
            return value$.pipe(
                map((items) => {
                    return {
                        items: [items],
                        progress: progress,
                        nextPage: isLast ? undefined : (page += 1),
                        finished: isLast,
                        error: null
                    };
                }),
                catchError(error => {
                    return of({
                        items: undefined,
                        progress: progress,
                        nextPage: isLast ? undefined : (page += 1),
                        finished: isLast,
                        error: error
                    });
                }),
            );
        }
        return fetchPage(0).pipe(
            expand(({nextPage}) => nextPage ? fetchPage(nextPage) : EMPTY),
        )
    }
}

export enum RoadLightCategory {
    P1 = 'P1',
    P2 = 'P2',
    P3 = 'P3',
    P4 = 'P4',
    P5 = 'P5',
    P6 = 'P6',
    P7 = 'P7',
    M1 = 'M1',
    M2 = 'M2',
    M3 = 'M3',
    M4 = 'M4',
    M5 = 'M5',
    M6 = 'M6',
    C0 = 'C0',
    C1 = 'C1',
    C2 = 'C2',
    C3 = 'C3',
    C4 = 'C4',
    C5 = 'C5'
}

export enum SidewalkLightCategory {
    P1 = 'P1',
    P2 = 'P2',
    P3 = 'P3',
    P4 = 'P4',
    P5 = 'P5',
    P6 = 'P6',
    P7 = 'P7',
}

export enum ParkingCategory {
    C0 = 'C0',
    C1 = 'C1',
    C2 = 'C2',
    C3 = 'C3',
    C4 = 'C4',
    C5 = 'C5',
    P1 = 'P1',
    P2 = 'P2',
    P3 = 'P3',
    P4 = 'P4',
    P5 = 'P5',
    P6 = 'P6',
    P7 = 'P7',
}

export enum StreetLayoutItemType {
    marciapiede = 'marciapiede',
    spartitraffico = 'spartitraffico',
    carreggiata = 'carreggiata',
    parcheggio = 'parcheggio',
    pistaciclabile = 'pista_ciclabile'
}

export interface StreetLayoutItem {
    tipo: StreetLayoutItemType;
    larghezza: number;
}

export interface RoadwayItem extends StreetLayoutItem {
    tipo: StreetLayoutItemType.carreggiata;
    numeroCorsie: number;
    categoriaIlluminotecnica?: RoadLightCategory;
}

export interface SidewalkItem extends StreetLayoutItem {
    tipo: StreetLayoutItemType.marciapiede;
    categoriaIlluminotecnica?: SidewalkLightCategory;
}

export interface ParcheggioItem extends StreetLayoutItem {
    tipo: StreetLayoutItemType.parcheggio;
    categoriaIlluminotecnica?: ParkingCategory;
}

export interface PistaCiclabileItem extends StreetLayoutItem {
    tipo: StreetLayoutItemType.pistaciclabile;
    categoriaIlluminotecnica?: ParkingCategory;
}

export interface TrafficDividerItem extends StreetLayoutItem {
    tipo: StreetLayoutItemType.spartitraffico;
}

export const allKeysLayout = ['tipo', 'numeroCorsie', 'categoriaIlluminotecnica', 'larghezza', 'tipologiaAsfalto'];

export interface StreetLamp {
    altezza: number;
    origine_x: number;
    origine_y: number;
    sbraccio: number;
    angoloRotazione: number;
    angoloInclinazione: number;
    gruppo: number;
    name: string;
}

export interface Street {
    objectId: string;
    nome?: string;
    fattoreManutenzione?: number;
    note?: string;
    layout?: StreetLayoutItem[];
    lampade?: StreetLamp[];
    interdistanza?: number;
    createdAt: string;
    updatedAt: string;
    fotometrie?: FotometriaParse[];
    isCentroStorico: boolean;
    tipologiaAreaIlluminata: string;
    gruppoFotometrie?: GruppoFotometrieParse;
    includiMarciapiedi?: boolean,
    verificaIPEI_A?: boolean,
    noteCalcolo?: KeyStringValueAny,
    calcoloSemicilindrico?: boolean

}

export interface EditableStreet {
    objectId?: string;
    nome: string;
    fattoreManutenzione: number;
    note: string;
    layout: StreetLayoutItem[];
    lampade: StreetLamp[];
    interdistanza: number;
    isCentroStorico: boolean;
    includiMarciapiedi: boolean;
    calcoloSemicilindrico: boolean;
    verificaIPEI_A: boolean;
    noteCalcolo?: KeyStringValueAny;
    tipologiaAreaIlluminata: string;

}

export enum LampPresetType {
    sideTop = 'sideTop',
    sideBottom = 'sideBottom',
    bothSides = 'bothSides',
    quincunx = 'quincunx',
    centreline = 'centreline',
    doubleCentreline = 'doubleCentreline',
    centrelineAndSides = 'centrelineAndSides',
}

export interface Photometry {
    objectId: string;
    nomeFile?: string;
    produttore?: string;
    nomeFamiglia?: string;
    nomeProdotto?: string;
    nomeLampada?: string;
    ottica?: string;
    tipologiaCorpoIlluminante?: string;
    temperaturaColore?: number; // Kelvin
    CRI?: number;
    potenza?: number; // Watt
    potenzaMin?: number; // Watt
    potenzaMax?: number; // Watt
    flussoLuminoso?: number; // Lumen
    flussoMin?: number; // Lumen
    flussoMax?: number; // Lumen
    efficienza?: number; // Lumen / Watt
    efficienzaMin?: number; // Lumen / Watt
    efficienzaMax?: number; // Lumen / Watt
}

export interface Range {
    min?: number;
    max?: number;
    name: string;
}

export interface ColorMapItem {
    color: string;
    value: number;
}

export interface LayoutItemResult {
    verificato: boolean;
    x_calc: number[];
    y_calc: number[];
    matriceValori?: number[][];
    matriceValoriLux?: { x: number, y: number, value }[];
    unitaMisuraMatriceValori: string
    osservatori?: [];
    minValAccettabile?: number;
    maxValAccettabile?: number;
    colorMap: ColorMapItem[];
    valori: { [key: string]: number };
    limiti: { [key: string]: Range };
    tipo: string,
    valoriOsservatori?: {
        luminanzaMedia: number[]
        rei: number[]
        ti: number[]
        ui: number[]
        uo: number[]
    }

}

export interface ComputeResult {
    verificato: boolean;
    potenza?: number;
    flusso?: number;
    efficienza?: number;
    risultati: LayoutItemResult[],
    De: number,
    Dp: number,
    ULR: number,
    IPEI: string,
    IPEA: string,
    categoriaIntensitaLuminosa: string
    indiceAbbagliamento: string
    max_intensitaLuminose: { deg: number, value: number } []
    ore_esercizio_anno: number,
    potenza_km: number,
}


export interface ComputeResults {
    allFotometrie: Photometry[];
    allResults: ComputeResult[];
    bestResult?: ComputeResult;
    bestFotometria?: Photometry;
}

export type HeaderForCsv = {
    objectIdKey: string;
    keyStreet: string;
    position?: number;
    type: string;
    traduction: string;
    subKey?: string;
    keyPointer?: string
}

export const statusStreetInQueue = {
    ERROR: 'ERROR',
    PROCESSING: 'PROCESSING',
    WAITING: 'WAITING',
}
export type StreetStateInQueue = { [k: string]: { status: keyof typeof statusStreetInQueue, message?: string } };