import {Injectable} from '@angular/core';
import {interval, Observable, of, Subject} from "rxjs";
import {map, startWith, take} from "rxjs/operators";

export type ReadValueBackgroundUploading = {
    progress: number,
    message?: string,
    title?: string,
    classCss?: 'init' | 'finish'
}

@Injectable({
    providedIn: 'root'
})
export class UploadBackgroundService {
    private uploadingEmitValue: Subject<ReadValueBackgroundUploading>
    uploadingReadValue$: Observable<ReadValueBackgroundUploading>
    uploadingTerminate: boolean = true;
    cssClass: string[] = ['position-init'];

    constructor() {
    }


    public initBackgroundProcess(): boolean {
        if (this.uploadingTerminate) {
            this.uploadingEmitValue = new Subject<any>();
            this.uploadingReadValue$ = this.uploadingEmitValue.asObservable();
            this.uploadingTerminate = false;
            this.cssClass = ['position-init'];
            return true;
        } else {
            return false;
        }
    }

    destroyBackgroundProcess(): void {
        this.uploadingTerminate = true;
        if (this.uploadingEmitValue != null) {
            this.uploadingEmitValue.complete();
            this.uploadingEmitValue.unsubscribe();
        }
        this.uploadingEmitValue = undefined;
        this.uploadingReadValue$ = undefined;
        this.cssClass = undefined;
    }

    emitValue(value: ReadValueBackgroundUploading): boolean {
        if (this.uploadingEmitValue != null) {
            this.uploadingEmitValue.next(value);
            return true;
        } else {
            return false;
        }
    }


    closeShowOrHidden() {
        if (this.cssClass.includes('position-init')) {
            this.cssClass = ['position-finish-rapid'];
        } else {
            if (this.cssClass.includes('position-show') || this.cssClass.includes('position-finish-rapid')) {
                this.cssClass = ['position-hidden']
            } else {
                this.cssClass = ['position-show']
            }
        }
    }


}
