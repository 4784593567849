import * as Parse from 'parse';
import {className} from "./Models";


export class StatisticheTelecontrolloParse extends Parse.Object {
    public static CLASS_NAME = className.statisticheTelecontrolloParse;

    constructor() {
        super(StatisticheTelecontrolloParse.CLASS_NAME);
    }

    public set objectId(value) {
        this.id = value;
    }

    public get objectId() {
        return this.id;
    }

    public set createdAt(value) {
        super.set('createdAt', value);
    }

    public get createdAt(): Date {
        return super.get('createdAt');
    }

    public set updatedAt(value) {
        super.set('updatedAt', value);
    }

    public get updatedAt(): Date {
        return super.get('updatedAt');
    }

    public get dataRiferimento(): Date {
        const date = new Date(this.updatedAt);
        date.setHours(date.getHours() - 1)
        return date;
    }

    public set circuito(value) {
        super.set('circuito', value);
    }

    public get circuito(): any {
        return super.get('circuito');
    }

    public set oreAcceso(value) {
        super.set('oreAcceso', value);
    }

    public get oreAcceso(): number {
        return super.get('oreAcceso');
    }

    public set oreSpento(value) {
        super.set('oreSpento', value);
    }

    public get oreSpento(): number {
        return super.get('oreSpento');
    }

    public set oreVentola(value) {
        super.set('oreVentola', value);
    }

    public get oreVentola(): number {
        return super.get('oreVentola');
    }


    public set progetto(value) {
        super.set('progetto', value);
    }

    public get progetto(): any {
        return super.get('progetto');
    }


    public set puntoLuce(value) {
        super.set('puntoLuce', value);
    }

    public get puntoLuce(): any {
        return super.get('puntoLuce');
    }

    public set resetAnalizzatore(value) {
        super.set('resetAnalizzatore', value);
    }

    public get resetAnalizzatore(): number {
        return super.get('resetAnalizzatore');
    }

    public set rtcTime(value) {
        super.set('rtcTime', value);
    }

    public get rtcTime(): any {
        return super.get('rtcTime');
    }

    public set serialeDevice(value) {
        super.set('serialeDevice', value);
    }

    public get serialeDevice(): any {
        return super.get('serialeDevice');
    }

    public set startupVentola(value) {
        super.set('startUpVentola', value);
    }

    public get startupVentola(): number {
        return super.get('startUpVentola');
    }

    public set tempoOnline(value) {
        super.set('tempoOnline', value);
    }

    public get tempoOnline(): number {
        return super.get('tempoOnline');
    }

    public set temperaturaMassima(value) {
        super.set('temperaturaMassima', value);
    }

    public get temperaturaMassima(): number {
        return super.get('temperaturaMassima');
    }

    public set temperaturaMedia(value) {
        super.set('temperaturaMedia', value);
    }

    public get temperaturaMedia(): number {
        return super.get('temperaturaMedia');
    }

    public set temperaturaMinima(value) {
        super.set('temperaturaMinima', value);
    }

    public get temperaturaMinima(): number {
        return super.get('temperaturaMinima');
    }

    public set timeSinceBoot(value) {
        super.set('timeSinceBoot', value);
    }

    public get timeSinceBoot(): number {
        return super.get('timeSinceBoot');
    }


    public get query(): Parse.Query<StatisticheTelecontrolloParse> {
        return new Parse.Query(StatisticheTelecontrolloParse)
    }
}

Parse.Object.registerSubclass(StatisticheTelecontrolloParse.CLASS_NAME, StatisticheTelecontrolloParse);
