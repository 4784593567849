<div class="flex-center-width100 flex-flow-column add-element-wrapper">
    <span>{{'scheduleMaintenance.import'|translate}}</span>
    <button mat-flat-button color="accent" (click)="addElement()">
        <mat-icon>add</mat-icon>
    </button>
</div>
<ng-container *ngIf="isSetScheduleMaintenace;else notSetSchedule">
    <app-list-box-schede-manutenzione
            (clickRemoveSchedaManutenzione)="clickRemoveSchedaManutenzione.emit($event)"
            [schedeManutenzione]="scheduleMaintenace"
            [visualizedRemoveButton]="true"
    >
    </app-list-box-schede-manutenzione>
</ng-container>
<ng-template #notSetSchedule>
    <app-list-is-empty>
        <p> {{"scheduleMaintenance.noElement"|translate}}</p>
    </app-list-is-empty>
</ng-template>