import {Injectable} from '@angular/core';
import {InfoPrintStreetComponent} from '../../components/pop-up-info/info-print-street/info-print-street.component';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig} from '@angular/material/legacy-dialog';
import {
    CreateUpdatePhotometryComponent
} from "../../components/pop-up-info/create-update-photometry/create-update-photometry.component";
import {FotometriaParse} from "../../models/Fotometria.Parse";
import {GruppoFotometrieParse} from "../../models/GruppoFotometrie.Parse";
import {VisualizedImageComponent} from "../../components/pop-up-info/visualized-image/visualized-image.component";
import {
    SelectedUsersInTableComponent
} from "../../components/pop-up-info/selected-users-in-table/selected-users-in-table.component";
import {
    ConfirmElementBeforeSaveComponent
} from "../../components/pop-up-info/confirm-element-before-save/confirm-element-before-save.component";
import {Observable} from "rxjs";
import {
    ConfirmDeleteWithNoteComponent
} from "../../components/pop-up-info/confirm-delete-with-note/confirm-delete-with-note.component";
import {
    SelectUserFromListComponent
} from "../../components/pop-up-info/select-user-from-list/select-user-from-list.component";
import {ExpandDetailComponent} from "../../components/pop-up-info/expand-detail/expand-detail.component";
import {
    ScheduleMaintenanceDetailComponent
} from "../../components/pop-up-info/schedule-maintenance-detail/schedule-maintenance-detail.component";
import {SchedaManutenzioneParse} from "../../models/SchedaManutenzione.Parse";
import {
    FormToCompileScheduleMaintenanceComponent
} from "../../components/schedule-maintenace-utilities/form-to-compile-schedule-maintenance/form-to-compile-schedule-maintenance.component";
import {
    VisualizedFormSchedeComponent
} from "../../components/pop-up-info/visualized-array-values/visualized-form-schede";
import {typeFormValue} from "../../models/configurationProperty/configurationPropertyUtils";
import {FormFieldComponent, FormFieldPopUpData} from "../../components/pop-up-info/form-field/form-field.component";
import {SelectPhotometryComponent} from "../../components/pop-up-info/select-photometry/select-photometry.component";
import {CaricoEsogenoParse} from "../../models/CaricoEsogeno.Parse";
import {
    SelectCaricoEsogenoComponent
} from "../../components/pop-up-info/select-carico-esogeno/select-carico-esogeno.component";

@Injectable({
    providedIn: 'root'
})
export class DialogPopUpInfoService {

    constructor(private dialog: MatDialog) {
    }


    public openInfoPrintStreet() {
        const height = window.innerHeight;
        const dialogRef = this.dialog.open(InfoPrintStreetComponent, {
            minWidth: '100%',
            maxWidth: '100%',
            minHeight: height + 'px',
            maxHeight: '100%',
            data: {}
        });
        return dialogRef.afterClosed();
    }

    public openCreateUpdatePhotometry(photometries: FotometriaParse[], groupPhotometry: GruppoFotometrieParse) {
        const dialogRef = this.dialog.open(CreateUpdatePhotometryComponent, {
            width: '100%',
            maxWidth: '100%',
            height: '100%',
            maxHeight: '100%',
            data: {photometries, groupPhotometry}
        });
        return dialogRef.afterClosed();
    }

    public openVisualizedImage(src: string, type: 'kmz' | 'kml' | 'image', visualizeDestroyButton = false,visualizeEditButton = false, width: string = '100%', height: string = '100%'): Observable<{
        destroy?: boolean,
        edit?:any,
    } | undefined> {
        const dialogRef = this.dialog.open(VisualizedImageComponent, {
            width: width,
            maxWidth: '100%',
            height: height,
            maxHeight: '100%',
            data: {src, type, visualizeDestroyButton,visualizeEditButton}
        });
        return dialogRef.afterClosed();
    }


    public openSelectUsersWithTable(users: any [], selectedUsers: any[]): Observable<{ add, remove, all }> {

        const dialogRef = this.dialog.open(SelectedUsersInTableComponent, {
            width: '100%',
            maxWidth: '100%',
            height: '100%',
            maxHeight: '100%',
            data: {users, selectedUsers}
        });
        return dialogRef.afterClosed();
    }

    public openConfirmElementsBeforeSave(elements: any [], displayedColumns: any[], getValueFunction, getIdFunction = undefined, multipleValues = true, title: string = undefined, style: {
        width: string,
        heigth: string
    } = {width: '100%', heigth: '100%'}) {
        const dialogRef = this.dialog.open(ConfirmElementBeforeSaveComponent, {
            width: style.width,
            maxWidth: '100%',
            height: style.heigth,
            maxHeight: '100%',
            data: {elements, displayedColumns, getValueFunction, getIdFunction, multipleValues, title}
        });
        return dialogRef.afterClosed();
    }


    public openSelectUsers(elements: any [], getValueFunction, getIdFunction = undefined, multipleValues = true, defaultValue: string | string[] | undefined = undefined): Observable<any[]> {
        const dialogRef = this.dialog.open(SelectUserFromListComponent, {
            maxWidth: '100%',
            maxHeight: '100%',
            data: {elements, getValueFunction, getIdFunction, multipleValues, defaultValue}
        });
        return dialogRef.afterClosed();
    }

    public openConfirmDeleteWithNote(keyForm: { type, key, traduction, initValue, required } [], messages: string[]) {
        const dialogRef = this.dialog.open(ConfirmDeleteWithNoteComponent, {
            maxWidth: '100%',
            maxHeight: '100%',
            width: '400px',
            data: {keyForm, messages}
        });
        return dialogRef.afterClosed();
    }

    public openExpandedDetail(data: { texts: string[], image: { url: string }, title: string, subTitle: string, }) {
        const dialogRef = this.dialog.open(ExpandDetailComponent, {
            maxWidth: '100%',
            width: '600px',
            maxHeight: '100%',
            data: data
        });
        return dialogRef.afterClosed();
    }

    public openScheduleMaintenanceDetail(data: { schedaManutenzione: string, detailsScheda: any }): Observable<{
        select: boolean,
        schedaManutenzione: SchedaManutenzioneParse
    }> {
        const dialogRef = this.dialog.open(ScheduleMaintenanceDetailComponent, {
            maxWidth: '100%',
            width: '600px',
            maxHeight: '100%',
            data: data
        });
        return dialogRef.afterClosed();
    }

    public openFormScheduleMaintenance(data) {
        let width;
        if (window.innerWidth < 600) {
            width = '100vw';
        } else {
            width = '600px';
        }
        const dialogRef = this.dialog.open(FormToCompileScheduleMaintenanceComponent, {
            maxWidth: '100vw',
            width,
            data: data,
            disableClose: true
        });
        return dialogRef.afterClosed();
    }

    public openVisualedOtherValue(data) {
        const dialogRef = this.dialog.open(VisualizedFormSchedeComponent, {
            maxWidth: '100%',
            width: '600px',
            maxHeight: '100%',
            data: data,
            panelClass: 'custom-dialog-container-unset-max-height'
        });
        return dialogRef.afterClosed();
    }

    public openFormFields(data: FormFieldPopUpData, style: undefined | 'default' | {
        width?: string,
        maxWidth?: string,
        height?: string,
        maxHeight?: string
    }) {

        let config: MatDialogConfig;
        if (style === 'default') {
            config = {
                width: '400px',
                maxWidth: '100%',
                maxHeight: '100%',
                data
            }
        } else if (typeof style === 'object') {
            config = {...style, data}
        } else {
            config = {data}
        }
        const dialogRef = this.dialog.open(FormFieldComponent, config);
        return dialogRef.afterClosed();
    }


    openMessagesWithAutoClose(data: {
        texts: string[],
        image: { url: string },
        title: string,
        subTitle: string,
    }, secondToClose: number) {
        const dialogRef = this.dialog.open(ExpandDetailComponent, {
            maxWidth: '100%',
            width: '500px',
            data: data
        });
        setTimeout(() => {
            dialogRef.close();
        }, secondToClose * 1000)
        return dialogRef.afterClosed();
    }

    public openSelectPhotometry<T>(fotometrie: T[], getId: (...args) => any, getValue: (...args) => any, displayColumns: string[], traductionDisplayColumns: {
        [k: string]: string
    }, defaultValues: { [k: string]: string }): Observable<T[]> {

        const dialogRef = this.dialog.open(SelectPhotometryComponent, {
            maxWidth: '100%',
            maxHeight: '100%',
            data: {elements: fotometrie, getId, getValue, displayColumns, traductionDisplayColumns, defaultValues}
        });
        return dialogRef.afterClosed();
    }

    public openSelectCaricoEsogeno(carichiEsogeni: CaricoEsogenoParse[], typeSelect: SelectTypePopUpType): Observable<{
        add: CaricoEsogenoParse[]
    }> {
        const dialogRef = this.dialog.open(SelectCaricoEsogenoComponent, {
            data: {carichiEsogeni, typeSelect}
        });
        return dialogRef.afterClosed();
    }


}

export const selectTypePopUp = {
    radio: 'radio',
    multiSelct: 'multiSelect'
}
export type SelectTypePopUpType = typeof selectTypePopUp [keyof typeof selectTypePopUp];



