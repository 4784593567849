import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";


@Component({
    selector: 'app-range-slider',
    templateUrl: './range-slider.component.html',
    styleUrls: ['./range-slider.component.scss']
})
export class RangeSliderComponent implements OnInit {
    @Input() min: number;
    @Input() max: number;
    @Input() step: number;
    tickInterval = 10;
    formFilter: UntypedFormGroup;
    subscriptions: Subscription[] = [];
    totalNumberStep = 10;
    minSet;
    maxSet;

    constructor(private fb: UntypedFormBuilder) {

    }

    ngOnInit(): void {
        this.tickInterval = Math.round((this.max - this.min) / 10);
        this.minSet = this.max
        this.maxSet = this.max
        this.formFilter = this.fb.group({
            min: [{
                value: this.max,
                disabled: false
            }, Validators.required],
            max: [{
                value: this.max,
                disabled: false
            }, Validators.required],
        });
        this.totalNumberStep = Math.round((this.max - this.min) / this.step);
        let subscription = this.formFilter.valueChanges.subscribe(min => {
            this.minSet = this.maxSet - min.min + this.min + this.step;
            this.maxSet = min.max - this.step;
        })
    }


    get widthSxSlider() {
        const partialStep = Math.round((this.maxSet - this.min) / this.step);

        return partialStep / this.totalNumberStep * 100
    }

    get widthDxSlider() {
        return 100 - this.widthSxSlider
    }

    displayLabel(value: number) {
        if (value >= 0) {
            return this.max - value + this.min;
        }
        return value;
    }


}
