<div mat-dialog-title>
    {{'confirm' | translate}}
</div>
<div mat-dialog-content>
    <div>
        {{'confirm_create_plan'}}
    </div>
</div>
<div mat-dialog-actions>
    <button mat-flat-button color="primary" (click)="closePlan()" cdkFocusInitial>{{ 'save' | translate}}</button>
    &nbsp;&nbsp;
    <button mat-stroked-button (click)="dialogRef.close()">{{
        'cancel' | translate}}
    </button>
</div>
