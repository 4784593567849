<div class="container-box" style="height: 100%">
    <mat-card style="height: calc(100% - 40px)">
        <div class="flex-start-center flex-flow-row header-carico-esogeno background-accent color-white">
            <div>
                <ng-content select="[headerCheckBox]">

                </ng-content>
            </div>
            <div>
                <mat-card-header *ngIf="showElementType" style="min-width: 150px">
                    <ng-container *ngIf="icon">
                        <ng-container *ngIf="!isArredoUrbano; else ArredoUrbano">
                            <img mat-card-avatar [src]="icon" class="background-accent">
                        </ng-container>
                        <ng-template #ArredoUrbano>
                            <div mat-card-avatar class="background-accent flex-around-center">
                                <svg width="30px" height="30px">
                                    <g>
                                        <path [attr.d]="icon" fill="#FFFFFF"></path>
                                    </g>
                                </svg>
                            </div>
                        </ng-template>
                    </ng-container>
                    <mat-card-title *ngIf="name">
                        {{name|translate}}
                    </mat-card-title>
                    <mat-card-subtitle *ngIf="type" style="margin-bottom: -3px" class="color-white">{{type}}</mat-card-subtitle>
                </mat-card-header>
            </div>
            <ng-content select="[headerRightBox]">

            </ng-content>
        </div>
        <div class="w100 flex-around-center  flex-flow">
            <div class="cover img-size pointer" *ngIf="urlImage!=null"
                 (click)="$event.stopPropagation();openImageFull()">
                <img class="img-object-fit-scale-down" [src]="urlImage">
            </div>
            <div class="flex-center-Between flex-flow-row" [ngClass]="urlImage?'text-size':'text-size-full'">
                <div class=" flex-flow-column flex-center-start" style="width: 50%"
                >
                    <div class="title color-primaryù">
                        {{'dashboard_sidenav.CaricoEsogeno.dataAttivazione'|translate}}
                    </div>
                    <div class="no-title">
                        <ng-template [ngTemplateOutlet]="ValueNoValue"
                                     [ngTemplateOutletContext]="{value:caricoEsogeno.dataAttivazione,isDate:true}"
                        >
                        </ng-template>
                    </div>
                </div>
                <div class="w100 flex-flow-column flex-center-start" style="width: 50%"
                >
                    <div class="title">
                        {{'dashboard_sidenav.CaricoEsogeno.dataDisattivazione'|translate}}
                    </div>
                    <div class="no-title">
                        <ng-template [ngTemplateOutlet]="ValueNoValue"
                                     [ngTemplateOutletContext]="{value:caricoEsogeno.dataDisattivazione,isDate:true}"
                        >
                        </ng-template>
                    </div>
                </div>
                <div class="w100 flex-flow-column flex-center-start" style="width: 50%">
                    <div class="title">
                        {{'dashboard_sidenav.CaricoEsogeno.potenzaEffettiva'|translate}}
                    </div>
                    <div class="no-title">
                        <ng-template [ngTemplateOutlet]="ValueNoValue"
                                     [ngTemplateOutletContext]="{value:caricoEsogeno.potenzaEffettiva}"
                        >
                        </ng-template>
                    </div>
                </div>
                <div class="w100 flex-flow-column flex-center-start" style="width: 50%">
                    <div class="title">
                        {{'dashboard_sidenav.CaricoEsogeno.potenzaNominale'|translate}}
                    </div>
                    <div class="no-title">
                        <ng-template [ngTemplateOutlet]="ValueNoValue"
                                     [ngTemplateOutletContext]="{value:caricoEsogeno.potenzaNominale}"
                        >
                        </ng-template>
                    </div>
                </div>
                <div class="w100 flex-flow-column flex-center-start" style="width: 50%">
                    <div class="title">
                        {{'dashboard_sidenav.CaricoEsogeno.oreFunzionamentoGiorno'|translate}}
                    </div>
                    <div class="no-title">
                        <ng-template [ngTemplateOutlet]="ValueNoValue"
                                     [ngTemplateOutletContext]="{value:caricoEsogeno.oreFunzionamentoGiorno}"
                        >
                        </ng-template>
                    </div>
                </div>
                <div class="w100 flex-flow-column flex-center-start" style="width: 50%">
                    <div class="title">
                        {{'dashboard_sidenav.CaricoEsogeno.oreFunzionamentoAnno'|translate}}
                    </div>
                    <div class="no-title">
                        <ng-template [ngTemplateOutlet]="ValueNoValue"
                                     [ngTemplateOutletContext]="{value:caricoEsogeno.oreFunzionamentoAnno}"
                        >
                        </ng-template>
                    </div>
                </div>

                <div class="w100 flex-flow-column flex-center-start" style="width: 50%">
                    <div class="title">
                        {{'dashboard_sidenav.CaricoEsogeno.consumoAnno'|translate}}
                    </div>
                    <div class="no-title">
                        <ng-template [ngTemplateOutlet]="ValueNoValue"
                                     [ngTemplateOutletContext]="{value:caricoEsogeno.consumoAnno}"
                        >
                        </ng-template>
                    </div>
                </div>
                <div class="w100 flex-flow-column flex-center-start" style="width: 50%">
                    <div class="title">
                        {{'dashboard_sidenav.CaricoEsogeno.tipologia'|translate}}
                    </div>
                    <div class="no-title">
                        <ng-template [ngTemplateOutlet]="ValueNoValue"
                                     [ngTemplateOutletContext]="{value:caricoEsogeno.tipologia}"
                        >
                        </ng-template>
                    </div>
                </div>
            </div>
            <div class="w100 flex-flow-column flex-center-start huna-expansion-panel-color-header">
                {{'dashboard_sidenav.CaricoEsogeno.note'|translate}}
                <app-truncate-text [text]="caricoEsogeno.note"
                                   [subTitle]="name"
                                   style="max-width: 100%"
                                   title="{{'dashboard_sidenav.CaricoEsogeno.note'|translate}}"
                >
                </app-truncate-text>
            </div>
        </div>
        <mat-card-actions>
            <ng-content select="[actions]">

            </ng-content>
        </mat-card-actions>
    </mat-card>
</div>
<ng-template #ValueNoValue let-value='value' let-isDate='isDate'>
    <ng-container *ngIf="value!=null; else NoValue">
        <ng-container *ngIf="!isDate;else IsDate">
            {{value}}
        </ng-container>
        <ng-template #IsDate>
            {{value |customDate:'longDate'|async}}
        </ng-template>
    </ng-container>
    <ng-template #NoValue>
        -
    </ng-template>
</ng-template>