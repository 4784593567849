import {Injectable} from '@angular/core';
import * as Parse from 'parse';
import {PuntiLuceParse} from "../../models/PuntiLuce.Parse";
import {fromPromise} from "rxjs/internal-compatibility";
import {concatMap, expand, map, toArray} from "rxjs/operators";
import {MapService} from "./map.service";
import {environment} from "../../../environments/environment";
import {EMPTY, Observable, of} from "rxjs";
import {arrayIsSet, isNotNullOrUndefined, PaginationParserequestType, paramsApiParse_v2} from "../../models/Models";
import {ProjectService} from "./project.service";

@Injectable({
    providedIn: 'root'
})
export class PuntiLuceService {

    constructor(private mapService: MapService,
                private projectService: ProjectService) {
    }

    public getCurrentProjectMy() {
        try {
            return this.projectService.currentProject
        } catch (e) {
            throw e;
        }
    }

    public getTuttiPuntiLuce(): Observable<PuntiLuceParse[]> {
        return this.mapService.getLightPointPaginate(environment.lightPointLimit).pipe(
            toArray(),
            map(puntiLuceArrays => {
                let allPuntiLuce: PuntiLuceParse[] = [];
                puntiLuceArrays.forEach(puntiLuce => {
                    allPuntiLuce = allPuntiLuce.concat(puntiLuce.puntiLuce)
                })
                return allPuntiLuce
            })
        )
    }

    getIconForLigthPoint(puntiLuce: PuntiLuceParse[]) {
        const keyForGetIcons = ['tipologiaCorpoIlluminante', 'tipologiaSostegno', 'numeroLampade', 'artistico'];
        const tiplogieDiverse = [];
        puntiLuce.forEach(pl => {
            const puntoLuce = new PuntiLuceParse()
            puntoLuce.objectId = pl.objectId;
            const forGetIcons = {};
            keyForGetIcons.forEach(key => {
                if (isNotNullOrUndefined(puntoLuce[key])) {
                    forGetIcons[key] = puntoLuce[key];
                } else {
                    forGetIcons[key] = null;
                }
            });
            tiplogieDiverse.push(forGetIcons);
        });
        const iconsQuery = Parse.Cloud.run('getPuntiLuceIcons',
            {puntiLuceData: tiplogieDiverse}, {});
        return fromPromise(iconsQuery).pipe(
            map(icons => {
                return puntiLuce.map((pl, index) => {
                    pl.icon = icons[index].iconName;
                    return pl
                })
            })
        )
    }


    getAllPuntiLuceIdsForProject$(progettoId: string): Observable<PuntiLuceParse[]> {
        function fetchPage(page = 0) {
            const params = paramsApiParse_v2({progettoId, page, pageSize: environment.lightPointLimit})

            const res = Parse.Cloud.run('getAllPuntiLuceIdsForProject',
                params, {});
            return fromPromise(res).pipe(
                map((items) => {
                    const isLast = items.length <= 0;
                    return {
                        items: items,
                        nextPage: isLast ? undefined : (page += 1),
                        finished: isLast,
                        progress: undefined,
                        error: undefined
                    };
                })
            );
        }

        return fetchPage().pipe(
            expand(({nextPage}) => nextPage ? fetchPage(nextPage) : EMPTY),
            toArray(),
            map(values => {
                if (arrayIsSet(values)) {
                    return values.reduce((prev, value) => {
                        if (arrayIsSet(value.items)) {
                            prev = prev.concat(value.items);
                        }
                        return prev
                    }, [])
                } else {
                    return undefined;
                }
            })
        )
    }
}
