import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-bar-scale-color',
    templateUrl: './bar-scale-color.component.html',
    styleUrls: ['./bar-scale-color.component.scss']
})
export class BarScaleColorComponent implements OnInit {

    constructor() {
    }


    @Input()
    public ElementidellaScala;
    @Input()
    public min;
    @Input()
    public max;
    @Input()
    public unitOfMeasure;

    ngOnInit(): void {
    }

    get numeroElementi() {
        if (window.innerHeight < 600) {
            return 2
        } else {
            return 1
        }
    }

}
