import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    ViewChild
} from '@angular/core';
import {BehaviorSubject, EMPTY, forkJoin, Observable, of, Subject, Subscription, throwError} from "rxjs";
import {GeoPointH, MapService} from "../../../providers/services/map.service";
import {UserService} from "../../../providers/services/user.service";
import {FormatterPipeService} from "../../../providers/services/formatter-pipe.service";
import {FilterService} from "../../../providers/services/filter.service";
import {CreateReportComponent} from "../../../pages-modules/reports/create-report/create-report.component";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {UtilsService} from "../../../providers/services/utils.service";
import {TranslateService} from "@ngx-translate/core";
import {ordinamentoEcampiTraduzioni} from "../../../models/ordinamentoEcampiTraduzioni";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {MyErrorStateMatcher} from "../../../providers/matchers/error-state-matcher";
import {ErrorsService} from "../../../providers/services/errors.service";
import {
    arrayIsSet,
    chiaviFiltri,
    className,
    cleanToSpecialCharacterAndLowerCaseString,
    getArrayToRemveItem,
    getItemInArrayByKeyValue,
    isNotNullOrUndefined, KeyStringValue, KeyStringValueAny,
    stringIsSet
} from 'src/app/models/Models';
import {DialogPopUpService} from 'src/app/providers/services/dialog-pop-up.service';
import {SavePhotoService} from "../../../providers/services/save-photo.service";
import {filter, map, startWith, switchMap, take} from "rxjs/operators";
import {DomSanitizer} from "@angular/platform-browser";
import {ImageService} from "../../../providers/services/image.service";
import {AlertService} from "../../../providers/services/alert.service";
import {ProjectService} from "../../../providers/services/project.service";
import {UserRole} from "../../../../config/static-data";
import {
    SegnaliTlcNodoChangesHistoryService
} from "../../../providers/services/segnali-tlc-nodo-changes-history.service";
import {SegnaliTlcNodoChangesHistoryParse} from "../../../models/SegnaliTlcNodoChangesHistory.Parse";
import {MatLegacyTabGroup as MatTabGroup} from "@angular/material/legacy-tabs";
import {DocumentsFileParse} from "../../../models/DocumentsFile.Parse";
import {FileManagerService, imgExtensions} from "../../../providers/services/file-manager.service";
import {MaintenanceService, typeElementScheduleMaintence} from "../../../providers/services/maintenance.service";
import {SchedaManutenzioneParse} from "../../../models/SchedaManutenzione.Parse";
import {getValidator, typeFormValue} from "../../../models/configurationProperty/configurationPropertyUtils";
import {dataForm} from "../../confirm-delete/select-or-create/select-or-create.component";
import {CircuitiParse} from "../../../models/Circuiti.Parse";
import {NgxDropzoneChangeEvent} from "ngx-dropzone";
import {FileServiceService} from "../../../providers/services/file-service.service";
import {LocalSotrageService} from "../../../providers/services/local-sotrage.service";
import {DialogPopUpInfoService} from "../../../providers/services/dialog-pop-up-info.service";
import {CaricoEsogenoParse} from "../../../models/CaricoEsogeno.Parse";
import {RemoteControlService, TypeVersioneProtocolloLM} from "../../../providers/services/remote-control.service";
import {IoTDevicesParse} from "../../../models/IoTDevices.Parse";
import {TLC_ProfiloFunzionamentoParse} from "../../../models/TLC_ProfiloFunzionamento.Parse";
import {ProfileTlcService} from "../../../providers/services/profile-tlc.service";
import {SettaggiTlcNodoParse} from "../../../models/SettaggiTlcNodo.Parse";
import {StradeParse} from "../../../models/Strade.Parse";
import {PuntiLuceParse} from "../../../models/PuntiLuce.Parse";
import {AggregateDataService, DataRawReportAggregate} from "../../../providers/services/aggregate-data.service";
import {SelectToolbarItemType} from "../../../widgets/select-toolbar/select-toolbar.component";

@Component({
    selector: 'app-dashboard-sidenav',
    templateUrl: './dashboard-sidenav.component.html',
    styleUrls: ['./dashboard-sidenav.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush, //forse viene ereditata dal padre
})
export class DashboardSidenavComponent implements OnChanges, OnDestroy {

    //necessario per l autosize delle text area
    // @ViewChild("tabGroup", {static: false}) tabGroup: MatTabGroup;

    @Input() detail;
    @Input() onlyLecture = false;
    @Input() sidenavIsOpen: boolean = true;
    @Input() saveElement: any;
    @Input() forceUpdate: boolean;
    @Input() schedeManutenzione: object = {};
    @Input() myPosition;
    @Input() centerMap;
    @Input() visualizedTabSchedaManutenzione = true;
    @Input() visualizedTabHistoryLightPoint = true;
    @Input() visualizedTabConnectedFile = true;
    @Input() visualizedTabVirtualMidNight = true;
    @Input() visualizedTabCaricoEsogeno = true;
    @Input() visualizedButtonSave = true;
    @Input() profilesTlc: TLC_ProfiloFunzionamentoParse[];
    @Input() settaggiTlcNodo: SettaggiTlcNodoParse[];
    @Output() getProfiles = new Subject<TLC_ProfiloFunzionamentoParse[]>();
    displayedColumns: string[] = ['value'];// sono le tabelle da visualizzare
    iotDevice: IoTDevicesParse;
    public typeVersioneProtocolloLM = TypeVersioneProtocolloLM
    public circuito;
    public user;
    private reports;

    @Output() close = new Subject();
    @Output() requestSchedeManutenzione = new EventEmitter();
    @Output() clickOpenNavigator = new EventEmitter();


    private userRole = UserRole;

    @Output() idEClasseElemento = new EventEmitter();

    invioIdEClasseElemento(action: 'updated' | 'removed' = 'updated', values = undefined) {
        const filtroCircuito = (!!this.detail.circuito) ? this.detail.circuito.id : chiaviFiltri.nessunQuadro;
        this.idEClasseElemento.emit({
            id: this.detail.id,
            classe: this.detail.className,
            filtroCircuito: filtroCircuito,
            action: action,
            values
        });
    }

    public elementiclasse = this.filterOrdinamento();

    public valueForm = {};
    public translateInstant;
    public filterForm: UntypedFormGroup;
    public favorites$: Observable<string[]>;
    public typeVisualizedKeys$: Observable<'all' | 'favorites' | 'compiled' | 'updated'>;
    public searchName$: Observable<string>;
    public selectedTab$: Observable<string>;
    public detailForm: UntypedFormGroup;
    public detailFormValues$: Observable<{ [k: string]: any }>;
    public compiledFormFields$: Observable<{ [k: string]: any }>;
    public imageForm: UntypedFormGroup;
    public aggregateForm: UntypedFormGroup;
    public rawValuesImageFormWithEnable$: Observable<{ [k: string]: any }>;
    public matcher = new MyErrorStateMatcher();//gestisce gli errori del textarea lato html
    public saveBottonDisabled: boolean = false;
    public addImageDisabled: { fotoInterno: boolean, fotoEsterno: boolean } = {fotoInterno: false, fotoEsterno: false};
    private instanzaDialogDeleteImmagine;
    public disabledGroupButtonSidenav: boolean = false;
    public loadHistoryLightPoint: boolean = true;
    public loadConnectedFile: boolean = false;
    public loadConnectedImages: boolean = false;
    public loadScheduleMaintence: boolean = false;
    public loadVirtualMidnight: boolean = false;
    public connectedFiles: DocumentsFileParse[];
    public connectedImages: DocumentsFileParse[];

    public historyLigthPoint: SegnaliTlcNodoChangesHistoryParse[] = undefined;

    public nomiClassi = className;
    public chiaviFiltri = chiaviFiltri;
    public salvaImage: boolean;

    @Input() public stradeAppartenentiAlProgettoInput;
    @Input() public lightPointsNearest: PuntiLuceParse[];
    @Input() public circuitiAppartenentiAlProgettoInput: CircuitiParse[];
    @Input() public carichiEsogeniElemento: CaricoEsogenoParse[];
    @Input() public carichiEsogeniExternal: CaricoEsogenoParse[];
    private subscriptions = new Subscription();
    public loadingFormFieldEmit = new BehaviorSubject<{ [k: string]: boolean }>(undefined);
    public loadingFormField$ = this.loadingFormFieldEmit.asObservable();
    aggregateDataReportEmit = new BehaviorSubject<DataRawReportAggregate>(undefined);
    aggregateDataReport$ = this.aggregateDataReportEmit.asObservable();
    loadingEmit = new BehaviorSubject<KeyStringValue<boolean>>(undefined);
    loading$ = this.loadingEmit.asObservable();
    tabsForm;
    keyPossibleTabs = {
        state_of_fact: 'state_of_fact',
        project: 'project',
        images: 'images',
        virtualMidNight: 'virtualMidNight',
        connectedFiles: 'connectedFiles',
        historyLightPoint: 'historyLightPoint',
        caricoEsogeno: 'caricoEsogeno',
        reportsAggregate: 'reportsAggregate',
        scheduleMaintenance: 'scheduleMaintenance',
    }
    possibleTabs: SelectToolbarItemType[] = [
        {
            displayValue: 'state_of_fact',
            key: this.keyPossibleTabs.state_of_fact,
            registerValue: this.keyPossibleTabs.state_of_fact
        },
        {
            displayValue: 'dashboard_sidenav.images',
            key: this.keyPossibleTabs.images,
            registerValue: this.keyPossibleTabs.images
        },
        {
            displayValue: 'historyLightPoint.titleTab',
            key: this.keyPossibleTabs.historyLightPoint,
            registerValue: this.keyPossibleTabs.historyLightPoint
        }, {
            displayValue: 'fileManager.connectedFile.title',
            key: this.keyPossibleTabs.connectedFiles,
            registerValue: this.keyPossibleTabs.connectedFiles
        }, {
            displayValue: 'virtualMidNight',
            key: this.keyPossibleTabs.virtualMidNight,
            registerValue: this.keyPossibleTabs.virtualMidNight
        }, {
            displayValue: 'dashboard_sidenav.CaricoEsogeno.title',
            key: this.keyPossibleTabs.caricoEsogeno,
            registerValue: this.keyPossibleTabs.caricoEsogeno
        }, {
            displayValue: 'reportsAggregate.title',
            key: this.keyPossibleTabs.reportsAggregate,
            registerValue: this.keyPossibleTabs.reportsAggregate
        }, {
            displayValue: 'SchedaManutenzione',
            key: this.keyPossibleTabs.scheduleMaintenance,
            registerValue: this.keyPossibleTabs.scheduleMaintenance
        }
    ]
    // A,B,C because the keyvalue pipe order automatically sort alphabetic
    public typePeriodAggregateData = {
        Alast7day: 'last7day',
        Blast15day: 'last15day',
        Clast30day: 'last30day',
    }
    public isGestore$: Observable<boolean>;
    public abbonamentoGestioneAttivo$: Observable<boolean>;
    public abbonamentoTelecontrolloAttivo$: Observable<boolean>;

    constructor(private mapService: MapService,
                private userService: UserService,
                private formatterService: FormatterPipeService,
                private filterService: FilterService,
                private changeDetector: ChangeDetectorRef,
                public dialog: MatDialog,
                private utilsService: UtilsService,
                private translateService: TranslateService,
                private fb: UntypedFormBuilder,
                private errors: ErrorsService,
                private myDialog: DialogPopUpService,
                private myDialogInfo: DialogPopUpInfoService,
                private saveFotoCircuiti: SavePhotoService,
                private _sanitizer: DomSanitizer,
                private imageService: ImageService,
                private alertService: AlertService,
                private projectService: ProjectService,
                private segnaliTlcNodoChangesHistory: SegnaliTlcNodoChangesHistoryService,
                private fileManagerService: FileManagerService,
                private maintenanceService: MaintenanceService,
                private fileService: FileServiceService,
                private localStorageService: LocalSotrageService,
                private remoteControlService: RemoteControlService,
                private tlcService: ProfileTlcService,
                private aggregateDataService: AggregateDataService
    ) {
        this.isGestore$ = this.projectService.isGestore$
        this.abbonamentoGestioneAttivo$ = this.projectService.abbonamentoGestioneAttivo$
        this.abbonamentoTelecontrolloAttivo$ = this.projectService.abbonamentoTelecontrolloAttivo$
        this.translateInstant = (t) => this.translateService.instant(t)
        this.imageForm = this.fb.group({fotoEsterno: null, fotoInterno: null,});
        this.rawValuesImageFormWithEnable$ = this.imageForm.valueChanges.pipe(
            startWith(this.imageForm.getRawValue()),
            map(() => this.imageForm.getRawValue()),
            map((values) => {
                if (values == null) {
                    return undefined
                } else {
                    return Object.keys(values).reduce((prev, key) => {
                        const disabled = this.imageForm.get(key).disabled;
                        const isSet = values[key] != null;
                        prev[key] = {disabled, isSet};
                        return prev;
                    }, {})
                }
            })
        )
        this.filterForm = fb.group({favorites: undefined, typeVisualizedKeys: 'all', searchName: undefined})
        this.favorites$ = this.filterForm.get('favorites').valueChanges.pipe(
            startWith(this.filterForm.get('favorites').value),
            map(() => this.filterForm.get('favorites').value)
        )
        this.searchName$ = this.filterForm.get('searchName').valueChanges.pipe(
            startWith(this.filterForm.get('searchName').value),
            map(() => this.filterForm.get('searchName').value),
        )
        this.typeVisualizedKeys$ = this.filterForm.get('typeVisualizedKeys').valueChanges.pipe(
            startWith(this.filterForm.get('typeVisualizedKeys').value),
            map(() => this.filterForm.get('typeVisualizedKeys').value),
        )
        this.subscriptions.add(
            this.favorites$
                .pipe(
                    filter(() => this.detail != null)
                )
                .subscribe(favorites => {
                    if (this.detail.className === this.nomiClassi.circuiti) {
                        this.localStorageService.favoritesKeyCircuiti = favorites
                    } else if (this.detail.className === this.nomiClassi.puntiLuce) {
                        this.localStorageService.favoritesKeyPuntiLuce = favorites
                    }
                })
        )

        this.aggregateForm = this.fb.group({period: this.typePeriodAggregateData.Clast30day});
        const datePeriod$ = this.aggregateForm.get('period').valueChanges.pipe(
            startWith(undefined),
            map(() => {
                const period = this.aggregateForm.get('period').value;
                if (period != null) {
                    const toDate = new Date();
                    toDate.setHours(0, 0, 0, 0);
                    const fromDate = new Date(toDate)
                    let beforDate = 30;
                    if (period === this.typePeriodAggregateData.Clast30day) {
                        beforDate = 30;
                    } else if (period === this.typePeriodAggregateData.Blast15day) {
                        beforDate = 15;
                    } else if (period === this.typePeriodAggregateData.Alast7day) {
                        beforDate = 7;
                    }
                    fromDate.setDate(fromDate.getDate() - beforDate)
                    return {fromDate, toDate}
                }
                return undefined
            }),
            filter(date => date != null && this.detail != null)
        )
        this.subscriptions.add(
            datePeriod$
                .subscribe(date => {
                    this.getAggregateData(date.fromDate, date.toDate);
                })
        )
        this.tabsForm = this.fb.group({selectedTab: {value: this.possibleTabs[0].key, disabled: false}});
        this.selectedTab$ = this.tabsForm.get('selectedTab').valueChanges.pipe(
            startWith(undefined),
            map(() => this.tabsForm.get('selectedTab').value)
        )
        this.subscriptions.add(
            this.selectedTab$.subscribe(tab => {
                this.clickTab(tab)
            })
        )


    }

    ngOnInit() {
    }


    classNameIsIncludes(detail, valuesClassName: string []) {
        if (isNotNullOrUndefined(detail) && isNotNullOrUndefined(detail.className)) {
            return valuesClassName.includes(detail.className)
        }
    }

    ngOnChanges(changes) {
        if (isNotNullOrUndefined(changes.forceUpdate) && !isNotNullOrUndefined(changes.detail)) {
            this.changeDetector.detectChanges();
        } else {
            this.lightPointsNearest = undefined;
            this.aggregateDataReportEmit.next(undefined);
            this.aggregateForm.reset();
            this.connectedFiles = undefined;
            this.connectedImages = undefined;
            this.loadConnectedFile = false;
            if (isNotNullOrUndefined(changes.saveElement) && isNotNullOrUndefined(changes.saveElement.currentValue)) {
                if (this.saveElement.action == 'removed') {
                    this.closeSidenav();
                }
                this.buttonOpenClose('open')
            }
            if (!isNotNullOrUndefined(this.detail)) {
                this.closeSidenav();
            } else if (isNotNullOrUndefined(changes.sidenavIsOpen)) {
                this.imageForm.reset();
                this.detailForm = undefined;
                this.loadData(this.detail);
                this.changeDetector.detectChanges();
                this.historyLigthPoint = undefined;
                this.loadHistoryLightPoint = true;
            } else {
                this.imageForm.reset();
                this.loadData(this.detail);
                this.changeDetector.detectChanges();
                this.historyLigthPoint = undefined;
                this.loadHistoryLightPoint = true;

            }
        }
        if (this.detail != null && this.detail.className === this.nomiClassi.circuiti) {
            this.filterForm.get('favorites').setValue(this.localStorageService.favoritesKeyCircuiti)
        } else if (this.detail != null && this.detail.className === this.nomiClassi.puntiLuce) {
            this.filterForm.get('favorites').setValue(this.localStorageService.favoritesKeyPuntiLuce)
        }


    }

    ngOnDestroy() {
        if (this.instanzaDialogDeleteImmagine) {
            this.instanzaDialogDeleteImmagine.unsubscribe();
        }
        this.subscriptions.unsubscribe();
    }


    getTypeSchedaManutenzioneClassName() {
        let type;
        if (this.detail.className == this.nomiClassi.puntiLuce) {
            type = typeElementScheduleMaintence.PuntiLuce
        } else if (this.detail.className == this.nomiClassi.circuiti) {
            type = typeElementScheduleMaintence.Circuiti
        }
        return type
    }

    public get isSviluppatore() {
        return this.userService.isSviluppatore;
    }

    private filterOrdinamento() {
        let newObject = {};
        Object.keys(ordinamentoEcampiTraduzioni).forEach((classe) => {
            let field = {};
            Object.keys(ordinamentoEcampiTraduzioni[classe]).forEach((key) => {
                if (ordinamentoEcampiTraduzioni[classe][key].showInForm) {
                    field[key] = ordinamentoEcampiTraduzioni[classe][key];
                }
            });
            newObject[classe] = field;
        });
        return newObject;
    }


    private buttonOpenClose(button: | 'open' | 'close') {
        if (button == 'open') {
            this.saveBottonDisabled = false;
            this.addImageDisabled['fotoEsterno'] = false;
            this.addImageDisabled['fotoInterno'] = false;
            this.changeDetector.detectChanges();
        } else {
            this.addImageDisabled['fotoEsterno'] = (this.salvaImage) ? !!this.imageForm.get('fotoEsterno').value : false;
            this.addImageDisabled['fotoInterno'] = (this.salvaImage) ? !!this.imageForm.get('fotoInterno').value : false;
            this.saveBottonDisabled = true;
        }
    }

    //tenta il salvataggio su database dei puntiluce un popup confermera l'avvenuto salvataggio oppure eventuali errori
    private updateOnParsePuntiLuce(detailValues: KeyStringValueAny, imageValues: KeyStringValueAny) {
        this.buttonOpenClose('close');
        let saveImage$;
        if (imageValues != null) {
            saveImage$ = this.saveFotoCircuiti.updateFotoPuntiLuce(this.detail, {foto: imageValues.fotoEsterno});
        }
        let saveDetails$;
        if (detailValues != null) {
            saveDetails$ = this.mapService.updatePuntiLuce(this.detail.id, detailValues);
        }
        let saveAll$: Observable<CircuitiParse>;
        if (saveImage$ == null && saveDetails$ == null) {
            saveAll$ = throwError('Not field compiled');
        } else if (saveImage$ != null && saveDetails$ != null) {
            saveAll$ = saveImage$.pipe(
                switchMap(foto => saveDetails$)
            )
        } else if (saveImage$ != null && saveDetails$ == null) {
            saveAll$ = saveImage$
        } else {
            saveAll$ = saveDetails$
        }
        saveAll$.subscribe((puntoLuce) => {
                this.invioIdEClasseElemento('updated', {puntoLuce: puntoLuce});
                const salvato: string = this.translateService.instant("dashboard_sidenav.alert.formgroup.success");
                this.alertService.success(salvato);
                this.buttonOpenClose('open');
                this.changeDetector.detectChanges();
            },
            (error) => {
                this.alertService.error(error);
                this.buttonOpenClose('open');
                this.changeDetector.detectChanges();
            });
    }


    //tenta il salvataggio su database dei curcuiti un popu confermera l'avvenuto salvataggio oppure eventuali errori
    private updateOnParseCircuito(detailValues: KeyStringValueAny, imageValues: KeyStringValueAny) {
        this.buttonOpenClose('close');

        let saveImage$
        if (imageValues) {
            if (this.detail.foto != null) {
                saveImage$ = this.saveFotoCircuiti.updateFotoCircuito(this.detail.foto, imageValues as any)
            } else {
                saveImage$ = this.saveFotoCircuiti.createFotoCircuiti(this.detail, imageValues.fotoEsterno, imageValues.fotoInterno).pipe(
                    switchMap((foto) => this.saveFotoCircuiti.updatePointerFotoCircuitiCircuiti(this.detail, foto))
                );
            }
        }

        let saveDetails$;
        if (detailValues != null) {
            saveDetails$ = this.mapService.updateCircuito(this.detail.id, detailValues)
        }
        let saveAll$: Observable<CircuitiParse>;

        if (saveImage$ == null && saveDetails$ == null) {
            saveAll$ = throwError('not field compiled');
        } else if (saveImage$ != null && saveDetails$ != null) {
            saveAll$ = saveImage$.pipe(
                switchMap(foto => saveDetails$)
            )
        } else if (saveImage$ != null && saveDetails$ == null) {
            saveAll$ = saveImage$
        } else {
            saveAll$ = saveDetails$
        }
        saveAll$.subscribe(
            (circuito) => {
                this.invioIdEClasseElemento();
                //salvataggio avvenuto senza errrori
                const salvato: string = this.translateService.instant("dashboard_sidenav.alert.formgroup.success");
                //il messaggio popup
                this.alertService.success(salvato)
                this.buttonOpenClose('open');
            },
            (error) => {
                this.alertService.error(error)
                this.buttonOpenClose('open');
            });

    }


    onSubmitFormItem(event) {
        this.buttonOpenClose('close');
        this.idEClasseElemento.emit({
            id: this.detail.id,
            classe: this.detail.className,
            filtroCircuito: undefined,
            action: 'updated',
            values: event
        });
    }

    public eliminaElemento() {
        this.myDialog.openDialogForDelete(this.translateService.instant("dialog.elimina_elemento")).subscribe(result => {
            if (result) {
                if (this.detail.className == this.nomiClassi.arredoUrbano || this.detail.className == this.nomiClassi.lineaElettrica) {
                    this.invioIdEClasseElemento("removed");
                } else {
                    this.mapService.eliminaElemnto(this.detail).subscribe(() => {
                            this.invioIdEClasseElemento("removed");
                            //salvataggio avvenuto senza errrori
                            const salvato: string = this.translateService.instant("dashboard_sidenav.alert.formgroup.success");
                            //il messaggio popup
                            this.errors.openSnackBarcomponent([], salvato, 'success').subscribe(() => {
                            });
                            this.closeSidenav();
                        },
                        (error) => {
                            //salvataggiato non avvenuto segnaliamo l errore nel popup
                            const erroreGenerato: string = error.message;
                            //il messaggio popup
                            this.errors.openSnackBarcomponent([erroreGenerato], "Salvataggio non riuscito", "error").subscribe(() => {
                                this.buttonOpenClose('open');
                            });
                        });
                }
            }
        });
    }

    onSubmit() {

        const valuesUpdated =
            Object.keys(this.detailForm.value)
                .filter(key => this.detailForm.get(key).dirty)
                .reduce((updatedValue, key) => {
                    if (updatedValue == null) {
                        updatedValue = {};
                    }
                    let value = this.detailForm.get(key).value
                    updatedValue[key] = value;
                    return updatedValue;
                }, undefined as KeyStringValueAny)
        const imagesUpdated = Object.keys(this.imageForm.value)
            .filter(key => this.imageForm.get(key).dirty)
            .reduce((updatedValue, key) => {
                if (updatedValue == null) {
                    updatedValue = {};
                }
                updatedValue[key] = this.imageForm.get(key).value;
                return updatedValue;
            }, undefined as KeyStringValueAny)
        //viene controllato che il form sia completato
        if (valuesUpdated == null && imagesUpdated == null) {
            const message = this.translateService.instant('dashboard_sidenav.formgroup.errori.notFieldCompiled')
            this.alertService.error(message);
        } else if (this.detailForm && !this.detailForm.valid) {
            let campiObbligatoriNonCompilati: string[]; // i campi obbligatori non sono stati riempiti
            let campoObbligatoroNonCompilato: string; // i campi obbligatori non sono stati riempiti
            let title: string;//titiolo da passare al popup
            title = this.translateService.instant("dashboard_sidenav.formgroup.errori.compilaObbligatorio");
            campiObbligatoriNonCompilati = [];
            Object.keys(this.detailForm.controls).forEach((key) => {
                if (this.detailForm.controls[key].invalid) {
                    // console.log(key);
                    if (this.elementiclasse[this.detail.className][key].hasOwnProperty('varianteKey')) {
                        campoObbligatoroNonCompilato = this.translateService.instant('dashboard_sidenav.' + this.detail.className + '.' + key + '.title')[0].toUpperCase() +
                            this.translateService.instant('dashboard_sidenav.' + this.detail.className + '.' + key + '.title').slice(1);
                    } else {
                        campoObbligatoroNonCompilato = this.translateService.instant('dashboard_sidenav.' + this.detail.className + '.' + key + '.title')[0].toUpperCase() +
                            this.translateService.instant('dashboard_sidenav.' + this.detail.className + '.' + key + '.title').slice(1);
                    }
                    campiObbligatoriNonCompilati.push(campoObbligatoroNonCompilato);
                }
            });
            this.alertService.warning(campiObbligatoriNonCompilati);
        } else if (this.detail.className == this.nomiClassi.puntiLuce) {
            this.updateOnParsePuntiLuce(valuesUpdated, imagesUpdated);
        } else if (this.detail.className == this.nomiClassi.circuiti) {
            this.updateOnParseCircuito(valuesUpdated, imagesUpdated);
        }
        this.changeDetector.detectChanges();

    }


    typeFormValue = typeFormValue;

    addProjectTabToPossibleTabsIfContainedVarianteKey(formFields: FormFieldsTabsHtmlType[]) {
        const formContainProject = formFields.some(f => f.tab === this.keyPossibleTabs.project)
        if (formContainProject) {
            const possibleTabsContainProject = this.possibleTabs.some(f => f.key === this.keyPossibleTabs.project)
            if (!possibleTabsContainProject) {
                this.possibleTabs.splice(1, 0, {
                    displayValue: 'project',
                    key: this.keyPossibleTabs.project,
                    registerValue: this.keyPossibleTabs.project
                })
            }
        } else {
            const index = this.possibleTabs.findIndex(pValue => pValue.key === this.keyPossibleTabs.project)
            if (index >= 0) {
                this.possibleTabs.splice(index, 1);
            }
        }
    }

    getFormCircuito(detail): UntypedFormGroup {
        //viene azzerato il vettore di visualizzazione dell html
        const formFields = this.getFormByDetailPredicate(detail);
        this.addProjectTabToPossibleTabsIfContainedVarianteKey(formFields);
        const objectForm = formFields.reduce((objForm, fieldHtml) => {
            fieldHtml.formFields.forEach((field) => {
                const formControlName = field.formControlName;
                const disabled = field.disabled;
                const required = field.required;
                const typeForm = field.typeForm;
                const value = detail[formControlName];
                const validatori = getValidator({required, typeForm} as any)
                objForm[formControlName] = [{value: value, disabled: disabled}, validatori]
            })
            return objForm;
        }, {})
        this.imageForm.get('fotoInterno').enable();
        let fotoCircuiti$
        if (detail.foto != null) {
            fotoCircuiti$ = this.mapService.getPhotoCircuitoByObjectId((detail as CircuitiParse).foto).pipe(
                switchMap((foto) => {
                    if (foto != null) {
                        return of(foto)
                    } else {
                        return this.mapService.getPhotoCircuitoByCircuito(detail as CircuitiParse)
                    }
                })
            )
        } else {
            fotoCircuiti$ = this.mapService.getPhotoCircuitoByCircuito(detail as CircuitiParse);
        }

        fotoCircuiti$.subscribe((foto) => {
            if (foto != null) {
                if (foto.fotoInterno != null) {
                    this.imageForm.get('fotoInterno').setValue({url: foto.fotoInterno.url()});
                    this.imageForm.get('fotoInterno').markAsPristine();
                }
                if (foto.fotoEsterno != null) {
                    this.imageForm.get('fotoEsterno').setValue({url: foto.fotoEsterno.url()});
                    this.imageForm.get('fotoEsterno').markAsPristine();
                }
            } else {
                this.imageForm.reset();
            }
            this.changeDetector.detectChanges();
        }, error => {
            this.alertService.error(error);
        });
        return this.fb.group(objectForm)
    }

    getFormPuntoLuce(detail): UntypedFormGroup {
        // il nome viene caricato in parallelo
        const formFields = this.getFormByDetailPredicate(detail)
        this.addProjectTabToPossibleTabsIfContainedVarianteKey(formFields);
        const objectForm = formFields.reduce((objForm, fieldHtml) => {
            fieldHtml.formFields.forEach((field) => {
                const formControlName = field.formControlName;
                const disabled = field.disabled;
                const required = field.required;
                const typeForm = field.typeForm;
                const value = detail[formControlName];
                const validatori = getValidator({required, typeForm} as any)
                objForm[formControlName] = [{value: value, disabled: disabled}, validatori]
            })
            return objForm;
        }, {})
        try {
            if (detail.fotoTipologia != undefined) {
                this.mapService.getPhotoPuntoLuceByObjectId(detail.fotoTipologia).subscribe((fotoPuntiLuce) => {
                    // vengono caricate le foto
                    if (fotoPuntiLuce != null && fotoPuntiLuce.url) {
                        this.imageForm.get('fotoInterno').setValue({url: fotoPuntiLuce.url});
                        this.imageForm.get('fotoInterno').markAsPristine();
                    } else {
                        this.imageForm.get('fotoInterno').reset();
                    }

                });
            }
            // vengono caricate le foto

            if (detail.foto != null && detail.foto.url() != null) {
                this.imageForm.get('fotoEsterno').setValue({url: detail.foto.url()});
                this.imageForm.get('fotoEsterno').markAsPristine();
            } else {
                this.imageForm.get('fotoEsterno').reset();
            }

            this.imageForm.get('fotoInterno').disable();

            // photoType = await this.findPhotoPuntoLuce(detail.fotoTipologia.id);
        } catch (e) {

        }
        return this.fb.group(objectForm);
    }


    circuitReport() {
        this.utilsService.lockBody();
        const a = this.dialog.open(CreateReportComponent, {
            data: {
                circuit: this.detail.id
            }
        });
        a.afterClosed().subscribe(() => this.utilsService.unlockBody());
    }

    private loadData(detail) {
        this.detailForm = undefined;
        let formGroup;
        if (detail.className == this.nomiClassi.puntiLuce) {
            formGroup = this.getFormPuntoLuce(detail);
        } else if (detail.className == this.nomiClassi.circuiti) {
            formGroup = this.getFormCircuito(detail);
        }
        setTimeout(() => {
            if (formGroup) {
                this.detailForm = formGroup;
                this.detailFormValues$ = this.detailForm.valueChanges.pipe(
                    startWith(undefined),
                    map(() => this.detailForm.value)
                );
                this.compiledFormFields$ = this.detailFormValues$.pipe(
                    map(values => {
                        if (values == null) {
                            return undefined;
                        } else {
                            return Object.keys(values).reduce((prev, key) => {
                                if (prev == null) {
                                    prev = {};
                                }
                                if (this.detailForm.get(key).dirty) {
                                    prev[key] = {dirty: true};
                                }
                                return prev;
                            }, undefined as KeyStringValueAny)
                        }
                    })
                )
                this.changeDetector.detectChanges();
            }
            this.isGestore$.pipe(
                take(1)
            ).subscribe(isGestore => {
                if (!isGestore || this.onlyLecture) {
                    this.detailForm.disable();
                    this.imageForm.disable();
                }
            })
        })
    }

    closeSidenav() {
        setTimeout(() => {
            this.close.next();
        })
    }

    removeItem() {
        this.reports = this.filterService.getReport();
        this.reports.splice(this.reports.indexOf(this.detail.id), 1);
        this.filterService.setReport(this.reports);
    }

    isReport() {
        this.reports = this.filterService.getReport();
        return this.reports.includes(this.detail.id);
    }


    private loadHistoryOfLightPoint(): void {
        this.segnaliTlcNodoChangesHistory.getHistoryOfLightPoint(this.detail.objectId).subscribe((historyLightPoint) => {
            this.loadHistoryLightPoint = false;
            this.historyLigthPoint = historyLightPoint;
            this.changeDetector.detectChanges();
        });
    }

    private getConnectedFile(): void {
        this.loadConnectedFile = true;
        this.mapService.getDocumentsFileNotImage(this.detail).subscribe((files) => {
            this.loadConnectedFile = false;
            this.connectedFiles = files;
            this.changeDetector.detectChanges();
        }, error => {
            this.alertService.error(error)
            this.loadConnectedFile = false;
            this.changeDetector.detectChanges();
        });
    }

    private getConnectedImages(): void {
        this.loadConnectedImages = true;
        this.mapService.getDocumentsFileImage(this.detail).subscribe((files) => {
            this.loadConnectedImages = false;
            this.connectedImages = files;
            this.changeDetector.detectChanges();
        }, error => {
            this.alertService.error(error)
            this.loadConnectedImages = false;
            this.changeDetector.detectChanges();
        });
    }

    private getConnectedScheduleMaintenance(): void {
        if (isNotNullOrUndefined(this.detail) && arrayIsSet(this.detail.schedeManutenzione)) {
            this.loadScheduleMaintence = true;
            this.maintenanceService.fetchAll(this.detail.schedeManutenzione).subscribe(scheda => {
                this.loadScheduleMaintence = false;
                this.changeDetector.detectChanges();
            }, error => {
                this.alertService.error(error);
                this.loadScheduleMaintence = false;
                this.changeDetector.detectChanges();
            })
        }
        // this.maintenanceService.getAllSchedeManutenzioneByProjectAndOther(null,).subscribe((files) => {
        //     this.loadScheduleMaintence = false;
        //     // this.connectedFiles = files;
        //     this.changeDetector.detectChanges();
        // }, error => {
        //     this.alertService.error(error)
        //     this.loadConnectedFile = false;
        //     this.changeDetector.detectChanges();
        // });
    }

    updateRemoveSettaggi(event: { action: 'remove' | 'add', settaggiTlcNodo: SettaggiTlcNodoParse[] }) {
        if (arrayIsSet(event.settaggiTlcNodo)) {
            if (event.action === 'remove') {
                if (arrayIsSet(this.settaggiTlcNodo)) {
                    const removeIds = event.settaggiTlcNodo.map(s => s.objectId)
                    this.settaggiTlcNodo = this.settaggiTlcNodo.filter(s => !removeIds.includes(s.objectId));
                }
            } else if (event.action === 'add') {
                const settaggi = arrayIsSet(this.settaggiTlcNodo) ? [...this.settaggiTlcNodo] : []
                event.settaggiTlcNodo.forEach(sUpdate => {
                    const index = settaggi.findIndex(s => s.objectId === sUpdate.objectId)
                    if (index >= 0) {
                        settaggi[index] = sUpdate
                    } else {
                        settaggi.push(sUpdate)
                    }
                })
                settaggi.sort((setA, setB) => {
                    const dateA = setA.giornoSettimana ? setA.giornoSettimana : 0
                    const dateB = setB.giornoSettimana ? setB.giornoSettimana : 0
                    return dateA - dateB;
                })
                this.settaggiTlcNodo = settaggi
            }
        }
    }

    setLoading(value: boolean, key) {
        const currentLoading = this.loadingEmit.value != null ? {...this.loadingEmit.value} : {};
        if (value == true) {
            currentLoading[key] = true;
        } else if (key in currentLoading) {
            delete currentLoading[key];
        }
        this.loadingEmit.next(currentLoading);
    }

    private getAggregateData(fromDate: Date, toDate: Date) {
        if (!(typeof this.detail.potenzaNominale == "number" || typeof this.detail.potenzaEffettiva == "number")) {
            const nominalPower = this.translateService.instant('dashboard_sidenav.PuntiLuce.potenzaNominale.title')
            const effectivePower = this.translateService.instant('dashboard_sidenav.PuntiLuce.potenzaEffettiva.title')
            this.alertService.warning(this.translateService.instant('alert.errorPower', {nominalPower, effectivePower}))
        } else {
            let circuito;
            let puntoLuce;
            if (this.detail.className === this.nomiClassi.puntiLuce) {
                puntoLuce = this.detail
            } else if (this.detail.className === this.nomiClassi.circuiti) {
                circuito = this.detail
            }
            this.setLoading(true, 'aggregateDataReport');

            this.aggregateDataService.getAggregateData$(undefined, circuito, puntoLuce, fromDate, toDate, false)
                .subscribe(values => {
                    if (values != null) {
                        this.aggregateDataReportEmit.next(this.aggregateDataService.getDataChartAndProgramMv(this.detail, values));
                    } else {
                        this.aggregateDataReportEmit.next(undefined);
                    }
                    this.setLoading(false, 'aggregateDataReport');
                }, error => {
                    this.alertService.error(error)
                    this.setLoading(false, 'aggregateDataReport');
                })
        }
    }

    private getIotDeviceSettaggiProfiles(): void {
        this.iotDevice = undefined;
        this.settaggiTlcNodo = undefined;
        const circuito = this.detail.className === this.nomiClassi.circuiti ? this.detail : this.detail.circuito
        if ([this.nomiClassi.circuiti, this.nomiClassi.puntiLuce].includes(this.detail.className) && circuito != null) {
            this.loadVirtualMidnight = true;
            this.remoteControlService.getIotDevices(undefined, [circuito.objectId]).pipe(
                switchMap(iotDevices => {
                    const iotDevice = iotDevices[0];
                    if (iotDevice == null) {
                        return EMPTY;
                    } else if (iotDevice.versioneProtocolloLM === TypeVersioneProtocolloLM.prt_v1) {
                        let profiles$
                        if (arrayIsSet(this.profilesTlc)) {
                            profiles$ = of(this.profilesTlc);
                        } else {
                            profiles$ = this.tlcService.getProfiliTelecontrollo();
                        }
                        return profiles$.pipe(
                            map(profiles => {
                                return {profiles, iotDevice}
                            }),
                            switchMap(({profiles, iotDevice}) => {
                                let circuitsId;
                                let lightPointsId;
                                if (this.detail.className === this.nomiClassi.circuiti) {
                                    circuitsId = [this.detail.objectId]
                                } else if (this.detail.className === this.nomiClassi.puntiLuce) {
                                    lightPointsId = [this.detail.objectId]
                                }
                                return this.remoteControlService.getSettaggiTlcNodo(lightPointsId, circuitsId, ['tlcProfiloFunzionamento']).pipe(
                                    map((settaggiTlcNodo) => {
                                        return {profiles, iotDevice, settaggiTlcNodo}
                                    })
                                )
                            })
                        )
                    } else {
                        return of({iotDevice, profiles: undefined, settaggiTlcNodo: undefined})
                    }
                }),
            )
                .subscribe(({iotDevice, profiles, settaggiTlcNodo}) => {
                    this.iotDevice = iotDevice;
                    if (arrayIsSet(profiles)) {
                        this.profilesTlc = profiles
                        this.getProfiles.next(profiles);
                    }

                    this.settaggiTlcNodo = settaggiTlcNodo;
                    this.loadVirtualMidnight = false;
                    this.changeDetector.detectChanges();
                }, error => {
                    this.alertService.error(error);
                    this.loadVirtualMidnight = false;
                    this.changeDetector.detectChanges();
                }, () => {
                    this.loadVirtualMidnight = false;
                    this.changeDetector.detectChanges();
                })
        }
    }


    clickTab(idTab: string) {
        if (!isNotNullOrUndefined(this.detail)) {
            this.disabledGroupButtonSidenav = false;
        } else if (this.detail.className == this.nomiClassi.circuiti && idTab == this.keyPossibleTabs.virtualMidNight) {
            this.disabledGroupButtonSidenav = true;
            this.getIotDeviceSettaggiProfiles();
        } else if (this.detail.className == this.nomiClassi.puntiLuce && idTab == this.keyPossibleTabs.virtualMidNight) {
            this.disabledGroupButtonSidenav = true;
            this.getIotDeviceSettaggiProfiles();
        } else if (this.detail.className == this.nomiClassi.puntiLuce && idTab === this.keyPossibleTabs.historyLightPoint) {
            // la chiamata viene fatta solo al click del tab
            this.loadHistoryOfLightPoint();
        } else if (
            (
                this.detail.className == this.nomiClassi.puntiLuce ||
                this.detail.className == this.nomiClassi.circuiti ||
                this.detail.className == this.nomiClassi.arredoUrbano
            ) && idTab === this.keyPossibleTabs.scheduleMaintenance
        ) {
            this.getConnectedScheduleMaintenance()
        } else if (
            (
                this.detail.className == this.nomiClassi.puntiLuce ||
                this.detail.className == this.nomiClassi.circuiti ||
                this.detail.className == this.nomiClassi.arredoUrbano
            ) && idTab === this.keyPossibleTabs.connectedFiles
        ) {
            this.getConnectedFile();
        } else if (
            (
                this.detail.className == this.nomiClassi.puntiLuce ||
                this.detail.className == this.nomiClassi.circuiti
            ) && idTab === this.keyPossibleTabs.images
        ) {
            this.getConnectedImages();
        } else if (
            (
                this.detail.className == this.nomiClassi.puntiLuce ||
                this.detail.className == this.nomiClassi.circuiti
            ) && idTab === this.keyPossibleTabs.reportsAggregate
        ) {
            if (!this.isDateSetAggregateForm()) {
                this.initAggregateForm();
            }
        } else {
            this.disabledGroupButtonSidenav = false;
        }
    }


    public setTab(value: string) {
        const tab = this.possibleTabs.find(pValue => pValue.key === value)
        if (tab != null) {
            this.tabsForm.get('selectedTab').setValue(tab)
        }
    }


    addImagesToElement(files: NgxDropzoneChangeEvent) {
        if (arrayIsSet(files.addedFiles)) {
            this.connectFileToElement(files.addedFiles)
        }
    }

    removeImagesToElement(images) {
        const documentsFile = images
            .filter((image) => image.objectId)
            .map(image => {
                const documentFile = new DocumentsFileParse();
                documentFile.objectId = image.objectId;
                return documentFile;
            })
        const message = {
            deconnect: this.translateService.instant('fileManager.connectedFile.removeConnectedFile'),
            removeToDataBase: this.translateService.instant('fileManager.connectedFile.removedDefinitevly')
        }
        this.fileManagerService.openDeconnectFile(documentsFile, message)
            .pipe(
                map((definitely) => {
                    return {definitely, files: documentsFile, images: documentsFile}
                })
            )
            .subscribe(event => {
                this.removingFileToElement(event)
            })
    }

    concatCustomArray<T>(array1: T[], values: T[]): T[] {
        if (arrayIsSet(array1) && arrayIsSet(values)) {
            return array1.concat(values);
        } else if (arrayIsSet(array1) && !arrayIsSet(values)) {
            return array1;
        } else if (!arrayIsSet(array1) && arrayIsSet(values)) {
            return values;
        } else {
            return [];
        }
    }

    connectFileToElement(filesAdded) {
        let filesNotSaved: { file: File, error: string }[] = [];
        this.setLoading(true, 'addImageFile');
        const getFileImage = (puntoLuceFiles) => {
            const images = [];
            const files = [];
            puntoLuceFiles.forEach(file => {
                let extension = this.utilsService.getExtensionFormMimetype(file.type);
                if (extension == null) {
                    extension = this.utilsService.getExtensionFileFromFileName(file.name);
                }
                if (imgExtensions.includes(extension)) {
                    images.push(file);
                } else {
                    files.push(file);
                }
            });
            return {images, files};
        }
        let saving$: Observable<{ images: any, files: any }> = of({images: {finished: true}, files: {finished: true}});
        const {images, files} = getFileImage(filesAdded);
        if (arrayIsSet(images)) {
            saving$ = saving$.pipe(
                switchMap((response) => this.fileManagerService.createNewFilesPaginate(images, undefined, 1, [this.detail]).pipe(
                    map(images => {
                        return {images, files: response.files}
                    })
                ))
            )
        }
        if (arrayIsSet(files)) {
            saving$ = saving$.pipe(
                switchMap((images) => this.fileManagerService.createNewFilesPaginate(files, undefined, 1, [this.detail])
                    .pipe(
                        map((response) => {
                            return {images: images.images, files: response}
                        })
                    ))
            )
        }
        saving$.subscribe(
            puntoLuceFiles => {
                const newImages = puntoLuceFiles.images.items
                const newFiles = puntoLuceFiles.files.items
                if (arrayIsSet(newFiles)) {
                    this.connectedFiles = this.concatCustomArray(this.connectedFiles, newFiles);
                }
                if (arrayIsSet(newImages)) {
                    this.connectedImages = this.concatCustomArray(this.connectedImages, newImages);
                }
                if (puntoLuceFiles.images.error && arrayIsSet(puntoLuceFiles.images.error.files)) {
                    filesNotSaved = filesNotSaved.concat(puntoLuceFiles.images.error.files.map(e => {
                        const code = puntoLuceFiles.images.error.error.code != null ? '[' + puntoLuceFiles.images.error.error.code + '] ' : '';
                        const message = puntoLuceFiles.images.error.error.message != null ? puntoLuceFiles.images.error.error.message : '';
                        return {file: e, error: code + message}
                    }));
                }
                if (puntoLuceFiles.files.error && arrayIsSet(puntoLuceFiles.files.error.files)) {
                    filesNotSaved = filesNotSaved.concat(puntoLuceFiles.files.error.files.map(e => {
                        const code = puntoLuceFiles.files.error.error.code != null ? '[' + puntoLuceFiles.files.error.error.code + '] ' : '';
                        const message = puntoLuceFiles.files.error.error.message != null ? puntoLuceFiles.files.error.error.message : '';
                        return {file: e, error: code + message}
                    }));
                }
                const allTerminate = (puntoLuceFiles.images != null && puntoLuceFiles.images.finished && puntoLuceFiles.files != null && puntoLuceFiles.files.finished)
                if (allTerminate) {
                    if (arrayIsSet(filesNotSaved)) {
                        this.alertService.warning(this.translateService.instant(filesNotSaved[0].error));
                        const jsonPrint = filesNotSaved.map(file => {
                            return {
                                name: file.file.name,
                                error: file.error
                            }
                        })
                        const headers = [{traduction: 'file_name'}, {traduction: 'error'}];
                        const now = new Date();
                        const fileName = this.translateService.instant('fileManager.fileNotImported') + ' - ' + now.getFullYear() + '_' + (now.getMonth() + 1).toString().padStart(2, '0') + '_' + now.getDate().toString().padStart(2, '0') + '_' + now.getHours().toString().padStart(2, '0') + '_' + now.getMinutes().toString().padStart(2, '0');
                        this.fileService.dowloadJson(fileName, jsonPrint, headers);
                    } else {
                        this.alertService.success(this.translateService.instant('services.request_completed_successfully'));
                    }
                    this.setLoading(false, 'addImageFile');
                }
                this.changeDetector.detectChanges();
            }, error => {
                this.alertService.error(error);
                this.setLoading(false, 'addImageFile');
            },
            () => this.setLoading(false, 'addImageFile')
        )

    }

    removeFiles(removedFile: any[], allFilesVisualized: DocumentsFileParse[]) {
        const idFilesRemoved = arrayIsSet(removedFile) ? removedFile.map(file => file.objectId) : [];
        if (arrayIsSet(allFilesVisualized)) {
            return allFilesVisualized.filter(file => !idFilesRemoved.includes(file.objectId))
        } else {
            return [];
        }

    }

    removingFileToElement(event: { definitely: boolean, files: DocumentsFileParse[], images?: DocumentsFileParse[] }) {
        const documentFiles = event.files.map(file => {
            const documentFile = new DocumentsFileParse()
            documentFile.objectId = file.objectId;
            return documentFile;
        })
        if (this.detail.className == this.nomiClassi.puntiLuce) {
            if (event.definitely) {
                forkJoin([this.mapService.removeFilesRelationLightPoint(this.detail, documentFiles), this.fileManagerService.deleteFiles(documentFiles)]).subscribe(
                    removedFiles => {
                        this.connectedFiles = this.removeFiles(event.files, this.connectedFiles);
                        this.connectedImages = this.removeFiles(event.images, this.connectedImages);
                        this.changeDetector.detectChanges();
                        const message = this.translateService.instant('fileManager.deleteSuccess')
                        this.alertService.success(message);
                    }, error => {
                        this.alertService.error(error);
                    }
                )
            } else {
                this.mapService.removeFilesRelationLightPoint(this.detail, documentFiles).subscribe(
                    removedFiles => {
                        this.connectedFiles = this.removeFiles(event.files, this.connectedFiles);
                        this.connectedImages = this.removeFiles(event.images, this.connectedImages);
                        this.changeDetector.detectChanges();
                        const message = this.translateService.instant('fileManager.removedSuccess')
                        this.alertService.success(message);
                    }, error => {
                        this.alertService.error(error);
                    }
                )
            }
        } else if (this.detail.className == this.nomiClassi.circuiti) {
            if (event.definitely) {
                forkJoin([this.mapService.removeFilesRelationCircuit(this.detail, documentFiles), this.fileManagerService.deleteFiles(documentFiles)]).subscribe(
                    removedFiles => {
                        this.connectedFiles = this.removeFiles(event.files, this.connectedFiles);
                        this.connectedImages = this.removeFiles(event.images, this.connectedImages);
                        this.changeDetector.detectChanges();
                        const message = this.translateService.instant('fileManager.deleteSuccess')
                        this.alertService.success(message);
                    }, error => {
                        this.alertService.error(error);
                    }
                )
            } else {
                this.mapService.removeFilesRelationCircuit(this.detail, documentFiles).subscribe(
                    removedFiles => {
                        this.connectedFiles = this.removeFiles(event.files, this.connectedFiles);
                        this.connectedImages = this.removeFiles(event.images, this.connectedImages);
                        this.changeDetector.detectChanges();
                        const message = this.translateService.instant('fileManager.removedSuccess')
                        this.alertService.success(message);
                    }, error => {
                        this.alertService.error(error);
                    }
                )
            }
        } else if (this.detail.className == this.nomiClassi.arredoUrbano) {
            if (event.definitely) {
                forkJoin([this.mapService.removeFilesRelationGeneric(this.detail, documentFiles), this.fileManagerService.deleteFiles(documentFiles)]).subscribe(
                    removedFiles => {
                        this.connectedFiles = this.removeFiles(removedFiles[1].destroyed, this.connectedFiles);
                        this.connectedImages = this.removeFiles(event.images, this.connectedImages);
                        this.changeDetector.detectChanges();
                        const message = this.translateService.instant('fileManager.deleteSuccess')
                        this.alertService.success(message);
                    }, error => {
                        this.alertService.error(error);
                    }
                )
            } else {
                this.mapService.removeFilesRelationGeneric(this.detail, documentFiles).subscribe(
                    removedFiles => {
                        this.connectedFiles = this.removeFiles(event.files, this.connectedFiles);
                        this.connectedImages = this.removeFiles(event.images, this.connectedImages);
                        this.changeDetector.detectChanges();
                        const message = this.translateService.instant('fileManager.removedSuccess')
                        this.alertService.success(message);
                    }, error => {
                        this.alertService.error(error);
                    }
                )
            }
        }
    }

    openNavigateInFileManager(event) {
        if (event && Array.isArray(event.files)) {
            let updateValue: Observable<any> = EMPTY;
            if (this.detail.className == this.nomiClassi.puntiLuce) {
                updateValue = this.fileManagerService.connectFilesToElement(this.detail, event.files)
            } else if (this.detail.className == this.nomiClassi.circuiti) {
                updateValue = this.fileManagerService.connectFilesToElement(this.detail, event.files)
            } else if (this.detail.className == this.nomiClassi.arredoUrbano) {
                updateValue = this.fileManagerService.connectFilesToElement(this.detail, event.files)
            }
            updateValue.pipe(map((files: any) => {
                if (files && arrayIsSet(files.notSaved) && arrayIsSet(event.files)) {
                    const idsFileNotSaved = files.notSaved.map(file => file.objectId);
                    return {files: event.files.filter(file => !idsFileNotSaved.includes(file.objectId))}
                } else {
                    return {files: event.files,}

                }
            }))
                .subscribe(
                    puntoLuceFile => {
                        puntoLuceFile.files.forEach(file => {
                            const index = this.connectedFiles.findIndex(sotredFiles => {
                                return sotredFiles.objectId == file.objectId
                            })
                            if (index < 0) {
                                this.connectedFiles.push(file);
                            }
                        })
                        this.changeDetector.detectChanges()
                    }, error => {
                        this.alertService.error(error)
                    }
                )
        }

    }

    removeSchedaManutenzione(schedaManutenzione: SchedaManutenzioneParse) {
        this.loadScheduleMaintence = true;
        let schedeManutenzione = this.detail.schedeManutenzione;
        const item = getItemInArrayByKeyValue(schedeManutenzione, schedaManutenzione.objectId, 'objectId')
        if (isNotNullOrUndefined(item)) {
            schedeManutenzione = getArrayToRemveItem(schedeManutenzione, schedaManutenzione.objectId, 'objectId');
        }
        this.detail.schedeManutenzione = schedeManutenzione;
        this.mapService.updateAll([this.detail]).subscribe(updated => {
            this.loadScheduleMaintence = false;
            const message = this.translateService.instant('fileManager.removedSuccess')
            this.alertService.success(message);
            this.changeDetector.detectChanges()
        }, error => {
            this.alertService.error(error)
            this.loadScheduleMaintence = false;
            this.changeDetector.detectChanges()
        })
    }


    addSchedaManutenzione() {
        this.requestSchedeManutenzione.emit({
            typeSchedaManutenzione: this.getTypeSchedaManutenzioneClassName(),
            elemento: this.detail
        })
    }


    openUrlNavigator() {
        const destination = {lat: null, lng: null};
        if (this.detail.className === this.nomiClassi.lineaElettrica) {
            if (arrayIsSet(this.detail.linesMap)) {
                destination.lat = this.detail.linesMap[0].start.latitude;
                destination.lng = this.detail.linesMap[0].start.longitude;
            }
        } else {
            destination.lat = this.detail.location.latitude;
            destination.lng = this.detail.location.longitude;
        }
        if (destination.lat != null && destination.lng != null) {
            this.clickOpenNavigator.emit({click: true, destination});
        }
    }

    getCircuitiParse(circuiti: any[]): CircuitiParse[] | undefined {
        if (arrayIsSet(circuiti)) {
            return circuiti.map(circ => {
                const circuito = new CircuitiParse();
                circuito.objectId = circ.formValues;
                return circuito;
            });
        } else {
            return undefined;
        }
    }

    predicateFilterFunction(fields: FormFieldsTabsHtmlType, favorites: string[], searchName: string, typeVisualizedKeys: 'all' | 'favorites' | 'compiled' | 'updated', compiledFormFields: {
        [k: string]: { dirty: boolean }
    }, values, translateInstant: (string) => string): FormFieldsHtmlType[] {
        const searchNameIsSet = stringIsSet(searchName) && searchName.length > 2;
        if (searchNameIsSet || (stringIsSet(typeVisualizedKeys) && typeVisualizedKeys != 'all') || arrayIsSet(favorites) && arrayIsSet(fields.formFields)) {
            return fields.formFields.filter(field => {
                let preserve = true;
                if (stringIsSet(field.formControlName)) {
                    if (stringIsSet(typeVisualizedKeys)) {
                        if (typeVisualizedKeys == 'favorites') {
                            preserve = favorites.includes(field.formControlName);
                        } else if (typeVisualizedKeys == 'compiled') {
                            preserve = values[field.formControlName] != null;
                        } else if (typeVisualizedKeys == 'updated') {
                            preserve = compiledFormFields[field.formControlName] != null && compiledFormFields[field.formControlName].dirty === true;
                        }
                    }
                    if (preserve && searchNameIsSet) {
                        const key = stringIsSet(field.traduction) ? translateInstant(field.traduction) : field.formControlName;
                        preserve = cleanToSpecialCharacterAndLowerCaseString(key).includes(cleanToSpecialCharacterAndLowerCaseString(searchName));
                    }
                }
                return preserve
            })
        } else {
            return fields.formFields
        }
    }


    updateCaricoEsogeno(event) {
        const values = event.values != null ? event.values : {}
        const detailClassName = this.detail != null && stringIsSet(this.detail.className) ? this.detail.className : ''
        if (detailClassName == this.nomiClassi.circuiti) {
            values['circuito'] = this.detail;
        } else if (detailClassName == this.nomiClassi.puntiLuce) {
            values['puntoLuce'] = this.detail;
        } else if (detailClassName == this.nomiClassi.arredoUrbano) {
            values['arredoUrbano'] = this.detail;
        }
        this.idEClasseElemento.emit({
            id: event.caricoEsogeno != null ? event.caricoEsogeno.objectId : undefined,
            classe: this.nomiClassi.caricoEsogeno,
            filtroCircuito: undefined,
            action: event.remove == true ? 'removed' : 'update',
            values: event.values
        });
    }

    getFormByDetailPredicate(detail: any, currentTabKey: 'state_of_fact' | 'project' | 'images' = undefined): FormFieldsTabsHtmlType[] {
        const values: FormFieldsTabsHtmlType[] = [
            {tab: 'state_of_fact', formFields: []},
            {tab: 'project', formFields: []},
            {tab: 'dashboard_sidenav.images', formFields: []},
        ]
        const allFormFileds = ordinamentoEcampiTraduzioni[detail.className]
        if (allFormFileds != null) {
            const getFieldsForm = (key) => {
                let typeForm;
                const type = stringIsSet(allFormFileds[key].type) ? allFormFileds[key].type : '';
                let possibleValues: dataForm[];
                if (arrayIsSet(allFormFileds[key].possibleValues)) {
                    possibleValues = allFormFileds[key].possibleValues.map(p => {
                        return {
                            valueForm: p,
                            traduction: 'dashboard_sidenav.' + detail.className + '.' + key + '.possibleValues.' + p,
                            html: p
                        }
                    });
                }
                let tabName;
                if (allFormFileds[key].typeForm != null) {
                    typeForm = allFormFileds[key].typeForm
                } else if (type.toLowerCase() === 'elenco') {
                    typeForm = typeFormValue.ELENCO_APERTO;
                } else if (type.toLowerCase() === 'bool') {
                    typeForm = typeFormValue.RADIO_BUTTON;
                    possibleValues = [
                        {valueForm: true as any, traduction: 'yes', html: 'true'},
                        {valueForm: false as any, traduction: 'no', html: 'false'}
                    ]
                } else if (type.toLowerCase() === 'number') {
                    typeForm = typeFormValue.INPUT_NUMBER;
                } else if (type.toLowerCase() === 'int') {
                    if (arrayIsSet(possibleValues)) {
                        possibleValues = possibleValues.map(pValue => {
                            delete pValue.traduction;
                            return pValue
                        })
                        typeForm = typeFormValue.ELENCO_APERTO;
                    } else {
                        typeForm = typeFormValue.INPUT_NUMBER;
                    }
                } else if (type.toLowerCase() === 'date') {
                    typeForm = typeFormValue.DATE;
                } else if (type.toLowerCase() === 'geo_point') {
                    typeForm = typeFormValue.GEO_POINT;
                } else if (type.toLowerCase() === 'text') {
                    typeForm = typeFormValue.INPUT_TEXT;
                } else {
                    typeForm = typeFormValue.INPUT_TEXT;
                }
                if (typeForm === typeFormValue.IMAGE || (stringIsSet(type) && type.includes('fotoPuntiLuce'))) {
                    tabName = 'dashboard_sidenav.images';
                }
                const traduction = typeForm != typeFormValue.GEO_POINT ? 'dashboard_sidenav.' + detail.className + '.' + key + '.title' : undefined;
                const fieldKeys = []
                tabName = stringIsSet(tabName) ? tabName : 'state_of_fact';
                if (currentTabKey == null || currentTabKey == tabName) {
                    const fieldKey = {
                        key,
                        ...allFormFileds[key],
                        possibleValues,
                        typeForm,
                        tab: tabName,
                        traduction
                    };
                    fieldKeys.push(fieldKey)
                }
                tabName = stringIsSet(tabName) && tabName != 'state_of_fact' ? tabName : 'project';
                if ((currentTabKey == null || currentTabKey == tabName) && stringIsSet(allFormFileds[key].varianteKey)) {
                    const varianteFieldKey = {
                        ...allFormFileds[key],
                        possibleValues,
                        typeForm,
                        tab: tabName,
                        key: allFormFileds[key].varianteKey,
                        traduction
                    };
                    fieldKeys.push(varianteFieldKey)
                }
                return fieldKeys;
            }
            const keys = Object
                .keys(allFormFileds)
                .filter(key => allFormFileds[key].showInForm != false)
                .sort((keyA, keyB) => {
                    const sectionA = allFormFileds[keyA].section != null ? allFormFileds[keyA].section : 0;
                    const sectionB = allFormFileds[keyB].section != null ? allFormFileds[keyB].section : 0;
                    if (sectionA === sectionB) {
                        const sortingValueA = allFormFileds[keyA].sortingValue != null ? allFormFileds[keyA].sortingValue : 0;
                        const sortingValueB = allFormFileds[keyB].sortingValue != null ? allFormFileds[keyB].sortingValue : 0;
                        return sortingValueA - sortingValueB;
                    }
                    return sectionA - sectionB;
                })
            let previusSection;
            keys.forEach((key) => {
                const fields = getFieldsForm(key);
                if (arrayIsSet(fields)) {
                    fields.forEach((field, index) => {
                        const possibleValues = arrayIsSet(field.possibleValues) ? field.possibleValues : undefined;
                        const value = {
                            type: field.type,
                            traduction: field.traduction,
                            formControlName: field.key,
                            formGroupName: undefined,
                            possibleValues,
                            required: field.required,
                            disabled: field.editable == false,
                            typeForm: field.typeForm
                        }

                        let indexValues;
                        if (field.tab === 'dashboard_sidenav.images') {
                            indexValues = 2;
                        } else if (field.tab == 'project') {
                            indexValues = 1;
                        } else {
                            indexValues = 0;
                        }
                        const currentSection = field.section;
                        if (previusSection == null || currentSection != previusSection) {
                            let sectionName
                            if (currentSection === 2) {
                                sectionName = 'dashboard_sidenav.management_parameters'
                            } else if (currentSection === 1) {
                                sectionName = 'dashboard_sidenav.electrical_characteristics'
                            } else {
                                sectionName = 'dashboard_sidenav.general_features'
                            }
                            values[indexValues].formFields.push({sectionName});
                        }
                        values[indexValues].formFields.push(value);
                        previusSection = field.section ? field.section : 0;
                    })
                }
            });
            return values.filter(v => arrayIsSet(v.formFields))
        }
        return undefined;
    }

    getTraductionFormImageByDetailPredicate(detail: any): FormFieldsHtmlType[] {
        const isSetImage = () => {
            if (className.puntiLuce == detail.className) {
                return true
            } else if (className.circuiti == detail.className) {
                return detail.foto != null
            } else {
                return true;
            }
        }
        if (isSetImage()) {
            if (className.puntiLuce == detail.className) {
                return [
                    {
                        formControlName: 'fotoEsterno',
                        traduction: 'dashboard_sidenav.photo',
                        typeForm: typeFormValue.IMAGE
                    },
                    {
                        formControlName: 'fotoInterno',
                        traduction: 'dashboard_sidenav.photo_type',
                        typeForm: typeFormValue.IMAGE
                    }
                ]
            } else {
                return [
                    {formControlName: 'fotoInterno', traduction: 'fotoInterno', typeForm: typeFormValue.IMAGE},
                    {formControlName: 'fotoEsterno', traduction: 'fotoEsterno', typeForm: typeFormValue.IMAGE}
                ]
            }
        } else {
            return undefined;
        }
    }

    imageIsSetAndFormIsEnabled(field: FormFieldsHtmlType, values: KeyStringValueAny): boolean {
        const key = field.formControlName
        return values == null || (!values[key].disabled) || (values[key].disabled && values[key].isSet)
    }

    clickFavorite(formControlName: string) {
        const favorites = arrayIsSet(this.filterForm.get('favorites').value) ? [...this.filterForm.get('favorites').value] : [];
        const index = favorites.indexOf(formControlName)
        if (index >= 0) {
            favorites.splice(index, 1)
        } else {
            favorites.push(formControlName);
        }
        this.filterForm.get('favorites').setValue(favorites);
    }

    favoriteIsSetPredicate(formControlName: string, favorites: string[]): boolean {
        if (arrayIsSet(favorites)) {
            const index = favorites.indexOf(formControlName)
            return index >= 0;
        } else {
            return false;
        }
    }

    possibleValuesPredicate(field: FormFieldsHtmlType, circuiti: CircuitiParse[], strade: StradeParse[], lightPointsNearest: PuntiLuceParse[]): dataForm[] {
        let possibleValues: dataForm[] = []
        if (stringIsSet(field.type) && field.type.toUpperCase() === 'POINTER_CIRCUITI' && arrayIsSet(circuiti)) {
            possibleValues = circuiti.map(c => {
                const objDataForm: dataForm = {
                    traduction: undefined,
                    html: c.numeroQuadro,
                    valueForm: c.objectId,
                    registerValue: {objectId: c.objectId},
                }
                return objDataForm
            });
        }
        if (stringIsSet(field.type) && field.type.toUpperCase() === 'POINTER_STRADE' && arrayIsSet(strade)) {
            possibleValues = strade.map(s => {
                const objDataForm: dataForm = {
                    traduction: undefined,
                    html: s.nome,
                    valueForm: s.objectId,
                    registerValue: {objectId: s.objectId},
                }
                return objDataForm
            });
        }
        if (stringIsSet(field.type) && field.type.toUpperCase() === 'POINTER_PUNTOLUCE') {
            if (arrayIsSet(lightPointsNearest)) {
                possibleValues = lightPointsNearest.map(p => {
                    const objDataForm: dataForm = {
                        traduction: undefined,
                        html: p.getTarga(),
                        valueForm: p.objectId,
                        registerValue: {objectId: p.objectId},
                    }
                    return objDataForm
                });
            }
        } else if (arrayIsSet(field.possibleValues)) {
            possibleValues = field.possibleValues
        }
        return possibleValues;
    }

    focusIn(field: FormFieldsHtmlType) {
        if (stringIsSet(field.type) && field.type.toUpperCase() === 'POINTER_PUNTOLUCE') {
            let loadingFormField
            const key = field.formControlName;
            let lightPointsNearest$: Observable<PuntiLuceParse[]>;
            if (arrayIsSet(this.lightPointsNearest)) {
                lightPointsNearest$ = of(this.lightPointsNearest)
            } else {
                if (this.loadingFormFieldEmit.value == null) {
                    loadingFormField = {};
                } else {
                    loadingFormField = {...this.loadingFormFieldEmit.value};
                }
                loadingFormField[key] = true;
                this.loadingFormFieldEmit.next(loadingFormField);
                loadingFormField = {...loadingFormField};
                lightPointsNearest$ = this.mapService.getNearetLightPoint(this.detail);
            }
            lightPointsNearest$.subscribe(
                pls => {
                    this.lightPointsNearest = pls;
                    if (loadingFormField && key in loadingFormField) {
                        delete loadingFormField[key];
                        this.loadingFormFieldEmit.next(loadingFormField)
                    }
                },
                error => {
                    this.alertService.error(error);
                    if (loadingFormField && key in loadingFormField) {
                        delete loadingFormField[key];
                        this.loadingFormFieldEmit.next(loadingFormField)
                    }
                }
            )
        }
    }

    isInLoadiongPredicate(field: FormFieldsHtmlType, loading: { [k: string]: boolean }) {
        return loading == null || loading[field.formControlName] != true;
    }

    transformDocumentFilesImages(images: any[]): { src: string, objectId }[] | null {
        if (arrayIsSet(images)) {
            return images
                .map(image => {
                    const i = {src: null, objectId: null}
                    i.src = image.url as any;
                    i.objectId = image.objectId;
                    return i;
                })
        } else {
            return null
        }
    }

    loadingPredicate(loading: KeyStringValue<boolean>, key): boolean {
        return loading && loading[key] && loading[key] == true;
    }

    initAggregateForm() {
        this.aggregateForm.setValue({period: this.typePeriodAggregateData.Clast30day});
    }

    isDateSetAggregateForm() {
        return this.aggregateForm.get('period').value != null
    }

    possibleTabsPredicate(keys: SelectToolbarItemType[], possibleKeys, detail, abbonamentoGestioneAttivo, abbonamentoTelecontrollo, isGestoreOnLocalStorage, isSviluppatore, visualizedTabHistoryLightPoint: boolean, visualizedTabVirtualMidNight: boolean, visualizedTabConnectedFile: boolean, visualizedTabSchedaManutenzione: boolean, visualizedTabCaricoEsogeno: boolean) {
        if (arrayIsSet(keys)) {
            const filterFuntion = (key: SelectToolbarItemType) => {
                const classNameIsIncludes = (valuesClassName: string[]) => {
                    if (isNotNullOrUndefined(detail) && isNotNullOrUndefined(detail.className)) {
                        return valuesClassName.includes(detail.className)
                    }
                }
                if (key.key === possibleKeys.caricoEsogeno) {
                    return visualizedTabCaricoEsogeno && isGestoreOnLocalStorage && classNameIsIncludes([className.puntiLuce, className.circuiti]);
                } else if (key.key === possibleKeys.reportsAggregate) {
                    return abbonamentoTelecontrollo && isGestoreOnLocalStorage && classNameIsIncludes([className.circuiti]) != null && (detail.idMezzanotte != null || detail.idGruppoLightMate != null) && classNameIsIncludes([className.puntiLuce])
                } else if (key.key === possibleKeys.scheduleMaintenance) {
                    return visualizedTabSchedaManutenzione && abbonamentoGestioneAttivo && isGestoreOnLocalStorage && classNameIsIncludes([className.puntiLuce, className.circuiti])
                } else if (key.key === possibleKeys.connectedFiles) {
                    return visualizedTabConnectedFile && abbonamentoGestioneAttivo && isGestoreOnLocalStorage && classNameIsIncludes([className.puntiLuce, className.circuiti])
                } else if (key.key === possibleKeys.historyLightPoint) {
                    return visualizedTabHistoryLightPoint && abbonamentoGestioneAttivo && isGestoreOnLocalStorage && classNameIsIncludes([className.puntiLuce])
                } else if (key.key === possibleKeys.virtualMidNight) {
                    return visualizedTabVirtualMidNight && abbonamentoTelecontrollo && isGestoreOnLocalStorage && classNameIsIncludes([className.puntiLuce, className.circuiti])
                } else {
                    return true;
                }
            }
            return keys.filter(filterFuntion)
        }
        return keys;
    }
}

export type FormFieldsTabsHtmlType = {
    tab: 'project' | 'state_of_fact' | 'dashboard_sidenav.images';
    formFields?: FormFieldsHtmlType[];
}
export type FormFieldsHtmlType = {
    formControlName?: string,
    traduction?: string,
    formGroupName?: string;
    typeForm?: typeFormValue;
    type?: string;
    required?: boolean;
    disabled?: boolean;
    sectionName?: string;
    possibleValues?: dataForm[]
};