import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-form-group-template',
  templateUrl: './form-group-template.component.html',
  styleUrls: ['./form-group-template.component.scss']
})
export class FormGroupTemplateComponent implements OnInit {

  @Input() title;

  constructor() { }

  ngOnInit() {
  }

}
