import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {
    CircuitiErrori,
    forHtmlVisualizedEditor,
    LevelError,
    PuntiLuceErrori
} from '../../../pages-modules/attachments/SubModels';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {className, isNotNullOrUndefined} from '../../../models/Models';
import {ordinamentoEcampiTraduzioni} from '../../../models/ordinamentoEcampiTraduzioni';
import {HunaValidators} from '../../../providers/validators/HunaValidators';
import {FormatterPipeService} from '../../../providers/services/formatter-pipe.service';
import {AttachmentFormService} from '../../../providers/forms/attachment-form.service';
import {Subject} from 'rxjs';
import {ProjectService} from "../../../providers/services/project.service";
import {UserRole} from "../../../../config/static-data";


@Component({
    selector: 'app-single-editor',
    templateUrl: './single-editor.component.html',
    styleUrls: ['./single-editor.component.scss']
})
export class SingleEditorComponent implements OnInit, OnChanges {

    @Input()
    elementoConErrori: PuntiLuceErrori | CircuitiErrori;
    @Input()
    className: string;

    @Input()
    allCircuitInProject;
    @Input()
    allCircuitInFile;
    @Output()
    close = new Subject();
    @Output()
    deleteElement = new EventEmitter();
    @Output()
    updateElement = new EventEmitter();


    private userRole = UserRole;
    allClassName = className;
    elementiclasse = ordinamentoEcampiTraduzioni;
    livelloErrori = LevelError;

    public arrayPerTable: forHtmlVisualizedEditor[];
    public formFilter: UntypedFormGroup;

    constructor(private fb: UntypedFormBuilder,
                private formatterService: FormatterPipeService,
                private attachmentFormService: AttachmentFormService,
                private projectService: ProjectService
    ) {
    }

    ngOnInit(): void {
    }


    getValidator(nomeClasse, key, type: string): Validators[] {
        let validatori: Validators [] = [];
        if (this.elementiclasse[nomeClasse][key].hasOwnProperty('required') && this.elementiclasse[nomeClasse][key].required) {
            /*A*/
            validatori.push(Validators.required);
        }
        if ((type.toUpperCase() == 'NUMBER' ||
            this.elementiclasse[nomeClasse][key].type.toUpperCase() == 'GEO_POINT')) {
            /*B*/
            validatori.push(HunaValidators.isNumberWithDecimal());
        } else if (type.toUpperCase() == 'INT') {
            validatori.push(HunaValidators.isNumber());
        }
        return validatori;
    }


    private getValue(type, value) {
        if (!isNotNullOrUndefined(value)) {
            return null;
        } else if ((typeof value == 'string' || typeof value == 'number') && (value as string | number).toString().trim().length <= 0) {
            return null;
        }
        switch (type.toUpperCase()) {
            case'DATE':
                return this.formatterService.transform(value);
            case 'POINTER_FOTOPUNTILUCE':
            case 'POINTER_CIRCUITI':
            case 'POINTER_STRADE':
                return (isNotNullOrUndefined(value.id)) ? value.id : value;
            case 'FILE':
                console.log(value);
                return null;
            case 'BOOL':
                return value;
            case 'GEO_POINT':
                const val = this.fb.group({
                    latitude: [{
                        value: this.elementoConErrori.location.latitude,
                        disabled: true
                    }, HunaValidators.isNumberWithDecimal()],
                    longitude: [{
                        value: this.elementoConErrori.location.longitude,
                        disabled: true
                    }, HunaValidators.isNumberWithDecimal()]
                });
                return val;
            case 'NUMBER':
            case 'INT':
                return value.toString();
            default:
                return value;
        }
    }

    getObjectFormCreateArryaToHtml(nomeClasse, solaLettura): UntypedFormGroup {
        let objectForForm = {};
        let titleSection;
        if (nomeClasse === this.allClassName.circuiti) {
            titleSection = {
                sezione1: 'dashboard_sidenav.general_features',
                sezione2: 'dashboard_sidenav.electrical_characteristics',
                sezione3: 'dashboard_sidenav.management_parameters',
                sezione4: 'project',
            };
        } else if (nomeClasse === this.allClassName.puntiLuce) {
            titleSection = {
                sezione1: 'dashboard_sidenav.general_features',
                sezione2: 'dashboard_sidenav.characteristics_of_the_lighting_body',
                sezione3: 'dashboard_sidenav.support_features',
                sezione4: 'project',
            };
        }
        let sections: forHtmlVisualizedEditor[] = [
            {title: titleSection.sezione1, dataTable: []},
            {title: titleSection.sezione2, dataTable: []},
            {title: titleSection.sezione3, dataTable: []},
            {title: titleSection.sezione4, dataTable: []}
        ];
        if (nomeClasse == this.allClassName.puntiLuce) {
            Object.keys(this.elementiclasse.PuntiLuce).forEach((key) => {
                let varianteKey;
                const type = this.elementiclasse[nomeClasse][key].type;
                const disabled = (solaLettura || type.toUpperCase().includes('POINTER')) ? true : !this.elementiclasse[nomeClasse][key].editable;// disable=!editable
                const validatori = this.getValidator(nomeClasse, key, type);
                const possibleValue = this.elementiclasse[nomeClasse][key].possibleValues;
                const sezione: number = this.elementiclasse[nomeClasse][key].section;
                const sortingValue = this.elementiclasse[nomeClasse][key].sortingValue;
                if (this.elementiclasse[nomeClasse][key].hasOwnProperty('varianteKey')) {
                    let valorePresente;
                    varianteKey = this.elementiclasse[nomeClasse][key].varianteKey;
                    valorePresente = this.getValue(type, this.elementoConErrori[varianteKey]);
                    let possibleValueTemp;
                    if (Array.isArray(possibleValue) && isNotNullOrUndefined(valorePresente) && valorePresente.toString().trim().length > 0 && !possibleValue.includes(valorePresente)) {
                        possibleValueTemp = [valorePresente].concat(possibleValue);
                    }
                    objectForForm[varianteKey] = [{value: valorePresente, disabled: disabled}, validatori];
                    if (isNotNullOrUndefined(sezione) && isNotNullOrUndefined(sections[3].dataTable)) {
                        sections[3].dataTable.push({
                            key: varianteKey,
                            type: type.toUpperCase(),
                            possibleValues: possibleValueTemp,
                            value: valorePresente,
                            error: this.elementoConErrori.error,
                            sortingValue: sortingValue
                        });
                    } else {
                        sections[3].dataTable = [{
                            key: key,
                            type: type.toUpperCase(),
                            possibleValues: possibleValueTemp,
                            value: valorePresente,
                            error: this.elementoConErrori.error,
                            sortingValue: sortingValue
                        }];
                    }
                }
                if (this.elementoConErrori.hasProperty(key)) {
                    let valorePresente = this.getValue(type, this.elementoConErrori[key]);
                    let possibleValueTemp = possibleValue;
                    if (Array.isArray(possibleValue) && isNotNullOrUndefined(valorePresente) && valorePresente.toString().trim().length > 0 && !possibleValue.includes(valorePresente)) {
                        possibleValueTemp = [valorePresente].concat(possibleValue);
                    }
                    if (isNotNullOrUndefined(sezione) && isNotNullOrUndefined(sections[sezione].dataTable)) {
                        sections[sezione].dataTable.push({
                            key: key,
                            type: type.toUpperCase(),
                            possibleValues: possibleValueTemp,
                            value: valorePresente,
                            error: this.elementoConErrori.error,
                            sortingValue: sortingValue
                        });
                    } else if (isNotNullOrUndefined(sezione)) {
                        sections[sezione].dataTable = [{
                            key: key,
                            type: type.toUpperCase(),
                            possibleValues: possibleValueTemp,
                            value: valorePresente,
                            error: this.elementoConErrori.error,
                            sortingValue: sortingValue
                        }];
                    }
                    if (type == 'GEO_POINT') {
                        objectForForm[key] = valorePresente;
                    } else {
                        objectForForm[key] = [{value: valorePresente, disabled: disabled}, validatori];
                    }
                }
            });
        } else if (nomeClasse == this.allClassName.circuiti) {
            Object.keys(this.elementiclasse.Circuiti).forEach((key, index) => {
                let varianteKey;
                const type = this.elementiclasse[nomeClasse][key].type;
                const disabled = (solaLettura) ? solaLettura : !this.elementiclasse[nomeClasse][key].editable;// disable=!editable
                const validatori = this.getValidator(nomeClasse, key, type);
                const possibleValue = this.elementiclasse[nomeClasse][key].possibleValues;
                const sezione: number = this.elementiclasse[nomeClasse][key].section;
                const sortingValue = this.elementiclasse[nomeClasse][key].sortingValue;
                if (this.elementiclasse[nomeClasse][key].hasOwnProperty('varianteKey')) {
                    let valorePresente;
                    varianteKey = this.elementiclasse[nomeClasse][key].varianteKey;
                    valorePresente = this.getValue(type, this.elementoConErrori[varianteKey]);
                    let possibleValueTemp = possibleValue;
                    if (Array.isArray(possibleValueTemp) && isNotNullOrUndefined(valorePresente) && !possibleValue.includes(possibleValueTemp) && valorePresente.toString().trim().length > 0) {
                        possibleValueTemp.push(valorePresente);
                    }
                    objectForForm[varianteKey] = [{value: valorePresente, disabled: disabled}, validatori];
                    if (isNotNullOrUndefined(sezione) && isNotNullOrUndefined(sections[sezione].dataTable)) {
                        sections[sezione].dataTable.push({
                            key: varianteKey,
                            type: type.toUpperCase(),
                            possibleValues: possibleValueTemp,
                            value: valorePresente,
                            error: this.elementoConErrori.error,
                            sortingValue: sortingValue
                        });
                    } else {
                        sections[sezione].dataTable = [{
                            key: key,
                            type: type.toUpperCase(),
                            possibleValues: possibleValueTemp,
                            value: valorePresente,
                            error: this.elementoConErrori.error,
                            sortingValue: sortingValue
                        }];
                    }
                }
                if (this.elementoConErrori.hasProperty(key)) {
                    let valorePresente = this.getValue(type, this.elementoConErrori[key]);
                    let possibleValueTemp = possibleValue;
                    if (Array.isArray(possibleValueTemp) && isNotNullOrUndefined(valorePresente) && !possibleValue.includes(possibleValueTemp) && valorePresente.toString().trim().length > 0) {
                        possibleValueTemp.push(valorePresente);
                    }
                    if (isNotNullOrUndefined(sezione) && isNotNullOrUndefined(sections[sezione].dataTable)) {
                        sections[sezione].dataTable.push({
                            key: key,
                            type: type.toUpperCase(),
                            possibleValues: possibleValueTemp,
                            value: valorePresente,
                            error: this.elementoConErrori.error,
                            sortingValue: sortingValue
                        });
                    } else {
                        sections[sezione].dataTable = [{
                            key: key,
                            type: type.toUpperCase(),
                            possibleValues: possibleValueTemp,
                            value: valorePresente,
                            error: this.elementoConErrori.error,
                            sortingValue: sortingValue
                        }];
                    }
                    if (type == 'GEO_POINT') {
                        objectForForm[key] = valorePresente;
                    } else {
                        objectForForm[key] = [{value: valorePresente, disabled: disabled}, validatori];
                    }
                }
            });
        }
        sections.forEach((section) => {
            if (Array.isArray(section.dataTable)) {
                (section.dataTable as any[]).sort((a, b) => a.sortingValue - b.sortingValue);
            }
        });
        this.arrayPerTable = sections;
        return this.fb.group(objectForForm);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.arrayPerTable = undefined;
        this.formFilter = undefined;
        const solaLettura = !(this.projectService.actualRoles.includes(this.userRole.GESTORE) || this.projectService.actualRoles.includes(this.userRole.OPERATORE));

        if (isNotNullOrUndefined(this.elementoConErrori)) {
            this.formFilter = this.getObjectFormCreateArryaToHtml(this.className, solaLettura);
        }
    }

    public getColor(error, key) {
        return this.attachmentFormService.colorErrorWithKey(error, key);
    }

    updateSingle() {
        const keyError = (isNotNullOrUndefined(this.elementoConErrori.error)) ? this.elementoConErrori.error.map((errore) => errore.key) : undefined;
        Object.keys(this.formFilter.value).forEach((key) => {
            // console.log(this.formFilter.get(key).pristine,this.formFilter.get(key).dirty ,key);
            if (this.elementoConErrori.hasProperty(key)) {
                if (this.formFilter.get(key).dirty) {

                    this.elementoConErrori[key] = this.formFilter.get(key).value
                    if (isNotNullOrUndefined(keyError) && keyError.includes(key)) {
                        const indice = this.elementoConErrori.error.findIndex((errore) => errore.key === key);
                        this.elementoConErrori.error.splice(indice, 1);
                    }
                }
            }
        });
        this.updateElement.emit(this.elementoConErrori);
    }


    public eliminaCampo(campo) {
        this.formFilter.get(campo).reset();
    }

    deleteSingle() {
        this.deleteElement.emit(this.elementoConErrori);
    }

    closeSidenav() {
        this.close.next();
    }

    public getPathTraduction(key): string {
        if (!isNotNullOrUndefined(key)) {
            return undefined;
        } else {
            return 'dashboard_sidenav.' + this.className + '.' + key + '.title';
        }
    }

    public getPathTraductionPossibleValue(key): string {
        if (!isNotNullOrUndefined(key)) {
            return undefined;
        } else {
            return 'dashboard_sidenav.' + this.className + '.' + key + '.possibleValues.';
        }
    }


    public get disabledButton() {
        let disableButton = false;
        if (isNotNullOrUndefined(this.elementoConErrori)) {
            if (this.elementoConErrori.hasError()) {
                this.elementoConErrori.error.forEach((error) => {
                    disableButton = this.isPresent(error.key);
                    const possibleValues = (isNotNullOrUndefined(this.elementiclasse[this.className][error.key])) ? this.elementiclasse[this.className][error.key].possibleValues : undefined;
                    if (!disableButton && Array.isArray(possibleValues)) {
                        if (error.level >= this.livelloErrori.error && !possibleValues.includes(this.formFilter.get(error.key).value)) {
                            disableButton = true;
                        }
                    }
                });
            }
        }
        return disableButton;
    }


    public isPresent(key): boolean {
        if (isNotNullOrUndefined(this.allCircuitInFile)) {
            const indice = this.allCircuitInFile
                .findIndex((circuito) => circuito.numeroQuadro === this.formFilter.get(key).value);
            return indice >= 0;
        } else {
            return false;
        }
    }

}
