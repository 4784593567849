import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-info-print-street',
  templateUrl: './info-print-street.component.html',
  styleUrls: ['./info-print-street.component.scss']
})
export class InfoPrintStreetComponent implements OnInit {

  constructor(  public dialogRef: MatDialogRef<InfoPrintStreetComponent>,
                @Inject(MAT_DIALOG_DATA)
                public data: any) { }

  ngOnInit(): void {
  }

}
