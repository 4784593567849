import * as Parse from 'parse';
import {allPropertyClass} from "./Models";


export class ProgrammiMVParse extends Parse.Object {
    public static CLASS_NAME = 'ProgrammiMV';

    constructor() {
        super(ProgrammiMVParse.CLASS_NAME);
    }


    public set annullaUltimoComando(value) {
        super.set('annullaUltimoComando', value);
    }

    public get annullaUltimoComando() {
        return super.get('annullaUltimoComando');
    }

    public set objectId(value) {
        this.id = value;
    }

    public get objectId() {
        return this.id;
    }

    public set autoMidnight(value) {
        super.set('autoMidnight', value);
    }

    public get autoMidnight() {
        return super.get('autoMidnight');
    }

    public set autoVMOffset(value) {
        super.set('autoVMOffset', value);
    }

    public get autoVMOffset() {
        return super.get('autoVMOffset');
    }

    public set createdAt(value) {
        super.set('createdAt', value);
    }

    public get createdAt() {
        return super.get('createdAt');
    }

    public set updatedAt(value) {
        super.set('updatedAt', value);
    }

    public get updatedAt() {
        return super.get('updatedAt');
    }

    public set circuito(value) {
        super.set('circuito', value);
    }

    public get circuito() {
        return super.get('circuito');
    }

    public set deviceId(value) {
        super.set('deviceId', value);
    }

    public get deviceId() {
        return super.get('deviceId');
    }

    public set durataComando(value) {
        super.set('durataComando', value);
    }

    public get durataComando() {
        return super.get('durataComando');
    }

    public set eseguito(value) {
        super.set('eseguito', value);
    }

    public get eseguito() {
        return super.get('eseguito');
    }

    public set groupId(value) {
        super.set('groupId', value);
    }

    public get groupId() {
        return super.get('groupId');
    }

    public set inCoda(value) {
        super.set('inCoda', value);
    }

    public get inCoda(): boolean {
        return super.get('inCoda');
    }

    public set linee(value) {
        super.set('linee', value);
    }

    public get linee() {
        return super.get('linee');
    }

    public set modalitaManutenzione(value) {
        super.set('modalitaManutenzione', value);
    }

    public get modalitaManutenzione() {
        return super.get('modalitaManutenzione');
    }

    public set modalitaRicerca(value) {
        super.set('modalitaRicerca', value);
    }

    public get modalitaRicerca() {
        return super.get('modalitaRicerca');
    }

    public set modello(value) {
        super.set('modello', value);
    }

    public get modello() {
        return super.get('modello');
    }

    public set newGroupId(value) {
        super.set('newGroupId', value);
    }

    public get newGroupId() {
        return super.get('newGroupId');
    }

    public set p0(value) {
        super.set('p0', value);
    }

    public get p0() {
        return super.get('p0');
    }

    public set p1(value) {
        super.set('p1', value);
    }

    public get p1() {
        return super.get('p1');
    }

    public set p2(value) {
        super.set('p2', value);
    }

    public get p2() {
        return super.get('p2');
    }

    public set p3(value) {
        super.set('p3', value);
    }

    public get p3() {
        return super.get('p3');
    }

    public set p4(value) {
        super.set('p4', value);
    }

    public get p4() {
        return super.get('p4');
    }

    public set p0WE(value) {
        super.set('p0WE', value);
    }

    public get p0WE() {
        return super.get('p0WE');
    }

    public set p1WE(value) {
        super.set('p1WE', value);
    }

    public get p1WE() {
        return super.get('p1WE');
    }

    public set p2WE(value) {
        super.set('p2WE', value);
    }

    public get p2WE() {
        return super.get('p2WE');
    }

    public set resetToDefault(value) {
        super.set('resetToDefault', value);
    }

    public get resetToDefault():boolean {
        return super.get('resetToDefault');
    }

    public set ricevuto(value) {
        super.set('ricevuto', value);
    }

    public get ricevuto() {
        return super.get('ricevuto');
    }

    public set t0(value) {
        super.set('t0', value);
    }

    public get t0() {
        return super.get('t0');
    }

    public set t1(value) {
        super.set('t1', value);
    }

    public get t1() {
        return super.get('t1');
    }

    public set t2(value) {
        super.set('t2', value);
    }

    public get t2() {
        return super.get('t2');
    }

    public set t3(value) {
        super.set('t3', value);
    }

    public get t3() {
        return super.get('t3');
    }

    public set t4(value) {
        super.set('t4', value);
    }

    public get t4() {
        return super.get('t4');
    }


    public set t1WE(value) {
        super.set('t1WE', value);
    }

    public get t1WE() {
        return super.get('t1WE');
    }

    public set t2WE(value) {
        super.set('t2WE', value);
    }

    public get t2WE() {
        return super.get('t2WE');
    }

    public set t3WE(value) {
        super.set('t3WE', value);
    }

    public get t3WE() {
        return super.get('t3WE');
    }


    public set tempoOn(value) {
        super.set('tempoOn', value);
    }

    public get tempoOn() {
        return super.get('tempoOn');
    }

    public set timerFisso(value) {
        super.set('timerFisso', value);
    }

    public get timerFisso() {
        return super.get('timerFisso');
    }

    public set valido(value) {
        super.set('valido', value);
    }

    public get valido() {
        return super.get('valido');
    }


    public set vmOffset(value) {
        super.set('vmOffset', value);
    }

    public get vmOffset() {
        return super.get('vmOffset');
    }

    public set weDay1(value) {
        super.set('weDay1', value);
    }

    public get weDay1() {
        return super.get('weDay1');
    }

    public set weDay2(value) {
        super.set('weDay2', value);
    }

    public get weDay2() {
        return super.get('weDay2');
    }

    public set weekday(value) {
        super.set('weekday', value);
    }

    public get weekday() {
        return super.get('weekday');
    }

    public set weEnabled(value) {
        super.set('weEnabled', value);
    }

    public get weEnabled() {
        return super.get('weEnabled');
    }


    public get state(): { color: string, message: string } {
        const red = '#ff2213';
        const orange = '#ffe000';
        const green = '#15e61c';
        const blue = '#0c96c8';
        let color;
        let message;
        if (this.inCoda) {
            color = blue;
            message = 'lightMate.pending';
        } else if (this.valido === false) {
            color = red;
            message = 'lightMate.failure';
        } else if (this.ricevuto && this.eseguito) {
            color = green;
            message = 'lightMate.riceived';
        } else {
            const dataTermineEsecuzione = (typeof this.durataComando === 'number') ? this.createdAt.getTime() + this.durataComando * 1000 : null;
            if (dataTermineEsecuzione - Date.now() < 0) {
                color = orange;
                message = 'lightMate.pendingConfirmation';
            } else {
                color = orange;
                message = 'lightMate.running';
            }
        }
        return {color, message}
    }

    public allProperty() {
        return allPropertyClass(ProgrammiMVParse)
    }

    public allPropertyOnParse() {
        const notInParse = ['constructor', 'query', 'state'];
        return this.allProperty().filter(key => !notInParse.includes(key))
    }

}

Parse.Object.registerSubclass(ProgrammiMVParse.CLASS_NAME, ProgrammiMVParse);
