<mat-card style="">
    <div class="flex-start-center flex-flow-row" style="max-width: 100%">
        <h5>{{'fileManager.file'|translate}}</h5>
        <button style="margin-left: 10px" mat-flat-button color="primary" (click)="clickRefresh()"
                *ngIf="visualizedRefreshButton">
            <mat-icon>refresh</mat-icon>
        </button>
    </div>
    <div class="container-start">
        <div class="container-center">
            <div class="flex-item-drag-box">
                <ngx-dropzone [disabled]="loading"
                        class="drop-zone-item-background"
                              accept="*"
                              (change)="addFileToCurrentElement($event)"
                >
                    <ngx-dropzone-label>
                        <ng-container *ngIf="!loading;else Loading">
                        {{ 'fileManager.copyFile' | translate}}
                        </ng-container>
                    </ngx-dropzone-label>
                </ngx-dropzone>
            </div>
            <div class="flex-item-button">
                <button mat-flat-button color="primary" (click)="clickSearchButton()">
                    <mat-icon>search</mat-icon>
                </button>
            </div>
        </div>
        <div [ngClass]="{'media-query':boxResize}"
             class="container-center box-width"
             *ngFor="let file of connectedFiles">
            <div class="flex-item" style="text-align: center">
                <app-box-document
                        [parentFolderName]="(getNameParentFolder(file)|translateByPredicateFunction:traductionFunction)|async"
                        [visualizedMoveInMenu]="false"
                        [visualizedDownloadButton]="visualizedDownloadButton"
                        [visualizedPreviewButton]="visualizedPreviewButton"
                        [visualizedRenameButton]="false"
                        [size]="file.size+'mb'"
                        [selected]="file.selected"
                        [disabled]="file.diabled"
                        [name]="file.name"
                        [typeFile]="getTypeFile(file)"
                        [updatedAt]="file.updatedAt"
                        (clickCard)="clickFile(file)"
                        (dbClickCard)="dbClickFile(file)"
                        (clickMenu)="clickMenuFile($event,file)"
                >
                </app-box-document>
            </div>
        </div>
        <ng-container *ngIf="isSetNewConnectedFile">
            <div [ngClass]="{'media-query':boxResize}"
                 class="container-center box-width"
                 *ngFor="let file of newConnectedFiles">
                <div class="flex-item" style="text-align: center">
                    <app-box-document
                            [parentFolderName]="(getNameParentFolder(file)|translateByPredicateFunction:traductionFunction)|async"
                            [visualizedMoveInMenu]="false"
                            [visualizedDownloadButton]="false"
                            [visualizedPreviewButton]="false"
                            [visualizedRenameButton]="false"
                            [size]="file.size+'mb'"
                            [selected]="file.selected"
                            [disabled]="file.diabled"
                            [name]="file.name"
                            [typeFile]="getTypeFile(file)"
                            [updatedAt]="file.updatedAt"
                            (dbClickCard)="dbClickFile(file)"
                            (clickMenu)="clickMenuFile($event,file)"
                    >
                    </app-box-document>
                </div>
            </div>
        </ng-container>
    </div>
</mat-card>

<ng-template #Loading>
    <mat-spinner [diameter]="20"></mat-spinner>
</ng-template>