<div class="example-card">
    <mat-grid-list cols="12" rowHeight="25px">
        <mat-grid-tile [colspan]="12"
                       [rowspan]="7" class="principlaGridTile"
                       [ngStyle]="{background: backGroundColor}">
            <ng-container *ngIf="image!=undefined && image!=null;else noImage">
                <div class="principal-image-container">
                    <img src="{{image._url}}" alt="{{image._url}}"
                         class="principal-image">
                </div>
            </ng-container>
            <ng-template #noImage>
                <ng-container *ngIf="!isNewProject;else newProject">
                    <div class="noImage"
                         [ngStyle]="{backgroundImage: 'url(assets/icon/selectPrject/backGround/noConfine.png)'}">
                    </div>
                </ng-container>
                <ng-template #newProject>
                    <div class="newProject">
                        <div class="plus radius">
                        </div>
                    </div>
                </ng-template>
            </ng-template>
            <div class="logo-container" style="text-align: center;align-items: center" *ngIf="!isNewProject && loading">
                <img src="{{logo._url}}" alt="{{logo._url}}"
                     class="logo-image" *ngIf="logo!=undefined;else nologo">
                <ng-template #nologo>
                    <div class="noLogo" [ngStyle]="{fontSize: resizeText()}">{{sigla}}</div>
                </ng-template>
            </div>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="12"
                       [rowspan]="1">
            <div style="width: 100%;text-align: center;margin-left: -10px;">
                {{provincia}}
            </div>
        </mat-grid-tile>
        <ng-container *ngIf="!isNewProject">
            <mat-grid-tile [colspan]="7"
                           [rowspan]="1">

                {{'numeroCircuiti'|translate}}:
            </mat-grid-tile>
            <mat-grid-tile [colspan]="5"
                           [rowspan]="1">
                {{numeroCircuito}}
            </mat-grid-tile>
            <mat-grid-tile [colspan]="7"
                           [rowspan]="1">
                {{'numeroPuntiLuce'|translate}}:
            </mat-grid-tile>
            <mat-grid-tile [colspan]="5"
                           [rowspan]="1">
                {{numeroPuntiLuce}}
            </mat-grid-tile>
        </ng-container>
    </mat-grid-list>
</div>
