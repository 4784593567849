<google-map height="100%" width="100%"
            (mapInitialized)="mapReady($event)"
            (mapClick)="onMapClick()"
            (zoomChanged)="changeZoom()"
            (boundsChanged)="mapBoundChange()"

>
    <ng-container>
        <ng-container
                *ngIf="(markerLightPoints$|async) as lightPointsCluster">
            <ng-container>
                <ng-container *ngFor="let element of lightPointsCluster.elementsInMap;trackBy:trackRowsPuntiLuce">
                    <map-marker
                        #marker="mapMarker"
                        [options]="{draggable:moveLightPoint}"
                        [position]="element['position']"
                        [icon]="element.icon"
                        [label]="element.labelMarker"
                        (mapClick)="clickMarker(element)"
                        (mapDragend)="dragEndEmit(element,$event)"
                    >
                    </map-marker>
                </ng-container>
                <ng-container *ngFor="let cluster of lightPointsCluster.clustersInMap">
                    <map-marker
                            (mapClick)="clusterClick(cluster)"
                            [position]="cluster.center"
                            [icon]="cluster.icon"
                            [options]="{zIndex:1100}"

                    >
                    </map-marker>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
</google-map>
<!--todo aggiornare mappa-->