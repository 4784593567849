import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'paginationArray',
    pure: true
})
export class PaginationArrayPipe implements PipeTransform {

    transform<T>(value: T [], elementForPage: number, actualPage: number, length: number): T[] {
        if (value != undefined) {
            const firstElement: number = elementForPage * actualPage;
            const lastElement: number = (firstElement + elementForPage < value.length) ? firstElement + elementForPage : value.length;
            return value.slice(firstElement, lastElement);
        } else {
            return null;
        }
    }

}
