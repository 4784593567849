<div class="example-card" [ngStyle]="{background: color}">
    <mat-grid-list cols="24" rowHeight="25px">
        <mat-grid-tile [colspan]="24"
                       [rowspan]="2"
                       style="background: #FFFFFF;border-top-right-radius:10px;border-top-left-radius:10px;text-transform: capitalize ">
            <div style="width: 100%;display: flex;justify-content:space-around;align-items: center;margin-left: -10px;"
                 [ngStyle]="{color: color}">
                <ng-container *ngIf="!installazioneLampada.riferimentoPuntoLuce;else oldLightPoint">
                    <h2 style="width: 200px; text-align: center">{{'newInstallation.newLightPoint'|translate}}</h2>
                    <div *ngIf="labelEsistente"
                         style="border-radius: 6px;width:80px;text-align: center;border:solid;border-width: 3px;"
                         [ngStyle]="{borderColor: color,background:color}">
                        <h3 style="margin: 4px;color: white">{{labelnstallazioneLampada}}</h3>
                    </div>
                </ng-container>
                <ng-template #oldLightPoint>
                    <h2 style="width: 200px; text-align: center;">{{'newInstallation.oldLightPoint'|translate}}</h2>
                    <div *ngIf="labelEsistente && !allFieldIsEmpty"
                         style="border-radius: 6px;width:80px;text-align: center;border:solid;border-width: 3px;"
                         [ngStyle]="{borderColor: color,background:color}">
                        <h3 style="margin: 4px;color: white">{{labelnstallazioneLampada}}</h3>
                    </div>
                </ng-template>
            </div>
        </mat-grid-tile>
        <ng-container *ngIf="allFieldIsEmpty;else notEmpty">
            <mat-grid-tile [colspan]="24"
                           [rowspan]="10">
                <div style="display: flex;align-items: center;justify-content: center;width:100% ">
                    <div *ngIf="labelEsistente"
                         style="border-radius: 6px;width:150px;text-align: center;border:solid;border-width: 3px;"
                         [ngStyle]="{borderColor: 'white',background:'white'}">
                        <h3 style="margin: 20px;font-size: x-large"
                            [ngStyle]="{color: color}"
                        >{{labelnstallazioneLampada}}</h3>
                    </div>
                </div>
            </mat-grid-tile>
        </ng-container>
        <ng-template #notEmpty>
            <mat-grid-tile [colspan]="10"
                           [rowspan]="1">
                <strong style="color: white">
                    {{'newInstallation.field'|translate}}</strong>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="7"
                           [rowspan]="1">
                <strong style="color: white">
                    {{'newInstallation.before'|translate}}</strong>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="7"
                           [rowspan]="1">
                <strong style="color: white">
                    {{'newInstallation.after'|translate}}</strong>
            </mat-grid-tile>
            <ng-container *ngIf="idMezzanotteInstallazioneLampada!=undefined">
                <mat-grid-tile [colspan]="24"
                               [rowspan]="1">
                    <strong style="margin-top: 10px;color: white">
                        {{'newInstallation.lightMate'|translate}}
                    </strong>
                </mat-grid-tile>
                <mat-grid-tile [colspan]="10"
                               [rowspan]="1">
                    <p style="color: white">
                        {{'newInstallation.' + idMezzanotteInstallazioneLampada.key|translate}}
                    </p>
                </mat-grid-tile>
                <mat-grid-tile [colspan]="7"
                               [rowspan]="1">
                    <p style="color: white">
                        {{idMezzanotteInstallazioneLampada.before}}
                    </p>
                </mat-grid-tile>
                <mat-grid-tile [colspan]="7"
                               [rowspan]="1">
                    <p style="color: white">
                        {{idMezzanotteInstallazioneLampada.after}}</p>
                </mat-grid-tile>
            </ng-container>
            <ng-container *ngIf="datiInstallazioneLampada.length>0">
                <mat-grid-tile [colspan]="24"
                               [rowspan]="1">
                    <strong style="margin-top: 10px;color: white">
                        {{'newInstallation.lightSource'|translate}}
                    </strong>
                </mat-grid-tile>
                <ng-container *ngFor="let installazione of datiInstallazioneLampada ">
                    <mat-grid-tile [colspan]="10"
                                   [rowspan]="1">
                        <p style="color: white">
                            {{'newInstallation.' + installazione.key|translate}}</p>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="7"
                                   [rowspan]="1">
                        <p style="color: white">
                            {{installazione.before}} {{(installazione.key != 'potenzaNominale' || installazione.before == 'Nd') ? '' : 'measureUnit.watt'|translate}}</p>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="7"
                                   [rowspan]="1">
                        <p style="color: white">
                            {{installazione.after}} {{(installazione.key != 'potenzaNominale' || installazione.after == 'Nd') ? '' : 'measureUnit.watt'|translate}}</p>
                    </mat-grid-tile>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="locationInstallazioneLampada.length>0">
                <mat-grid-tile [colspan]="24"
                               [rowspan]="1">
                    <strong style="margin-top: 10px;color: white">
                        {{'newInstallation.location'|translate}}
                    </strong>
                </mat-grid-tile>
                <ng-container *ngFor="let location of locationInstallazioneLampada ">
                    <mat-grid-tile [colspan]="10"
                                   [rowspan]="1">
                        <p style="color: white">
                            {{'newInstallation.' + location.key|translate}}
                        </p>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="7"
                                   [rowspan]="1">
                        <p style="color: white">
                            {{location.before}}</p>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="7"
                                   [rowspan]="1">
                        <p style="color: white">
                            {{location.after}}</p>
                    </mat-grid-tile>
                </ng-container>
            </ng-container>
            <mat-grid-tile [colspan]="24"
                           [rowspan]="2">
                <ng-content></ng-content>
            </mat-grid-tile>
        </ng-template>
    </mat-grid-list>
</div>