import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {isNotNullOrUndefined} from "../../models/Models";

@Component({
    selector: 'app-huna-print-table',
    templateUrl: './huna-print-table.component.html',
    styleUrls: ['./huna-print-table.component.scss']
})
export class HunaPrintTableComponent implements OnInit {

    /**
     * nel caso di orientamento verticale i titoli delle colonne vengono prese come le chiavi dell oggetto
     * contenute nell array data set.
     * verticalTitle non ha nessuno effetto
     * nel caso di orientamento verticale il i titolo viene preso da verticalTitle,
     * le chiavi dell oggetto non vengono stampate.
     * solo i valori presenti in data sete vengono utilizzati
     *
     * se il dataset presenti oggetti diversi solamenti le chiavi presenti nel primo oggetto vengono stampate
     */


    @Input()
    orientation: 'horizontal' | 'veritcal' = 'horizontal';
    @Input()
    dataSet: object[];

    @Input()
    isInnerHtml = false;
    @Input()
    verticalFirstRowIsBold: boolean = false;

    @Input()
    verticalTitle;
    @Input()
    orizzontalTitle;
    @Input()
    widthTable;

    private borderColor = '#e7e7e7';

    constructor(private sanitizer: DomSanitizer,
    ) {
    }

    get titlecolumn() {
        if (isNotNullOrUndefined(this.dataSet)) {
            return Object.keys(this.dataSet[0]);
        } else {
            return [];
        }
    }

    get fontSize() {
        if (this.titlecolumn.length > 11) {
            return 2 + 'em';
        } else {
            return 0.8 + 'em';
        }
    }

    ngOnInit(): void {
    }

    sanizeHtml(value) {
        return this.sanitizer.bypassSecurityTrustHtml(value);
    }


    getNgTdStyleHorizontalTable(firstX, lastX, firstY, lastY) {
        return {
            borderBottom: '1px solid ' + this.borderColor,
            borderBottomRightRadius: (lastX && lastY) ? 10 + 'px' : null,
            borderBottomLeftRadius: (lastX && firstY) ? 10 + 'px' : null,
            borderRight: (lastY) ? '1px solid ' + this.borderColor : null,
            borderLeft: (firstY) ? '1px solid ' + this.borderColor : null,
            fontSize: this.fontSize
        };
    }

    getNgTdStyleVerticalTable(firstX, lastX, firstY, lastY) {
        return {
            fontWeight: (firstY || (firstX && this.verticalFirstRowIsBold)) ? 'bold' : null,
            borderBottom: '1px solid ' + this.borderColor,
            borderBottomRightRadius: (lastX && lastY) ? 10 + 'px' : null,
            borderBottomLeftRadius: (lastX && firstY) ? 10 + 'px' : null,
            borderRight: (lastY) ? '1px solid ' + this.borderColor : null,
            borderLeft: (firstY) ? '1px solid ' + this.borderColor : null,
            fontSize: this.fontSize
        };
    }
}
