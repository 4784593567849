<form class="flex-center-width100" [formGroup]="form">
    <div class="flex-center-Between flex-flow-row" style="width: 400px">
        <div>
            <button mat-icon-button color="accent" (click)="removePage()">
                <mat-icon>
                    keyboard_arrow_left
                </mat-icon>
            </button>
        </div>
        <div class="hide-arrow-input">
            <input class="box-current-page background-accent "
                   matInput
                   formControlName="currentPage"
                   style="font-weight: bold;font-size: large;text-align: center" type="number" min="0">
            / <span
                style="font-size: large;font-weight: bold">{{items|convertValueByPredicateFunction:predicateNumberElement:(numberElementForPage$|async)}}</span>
        </div>
        <div>
            <div class="background-accent" [matMenuTriggerFor]="SelectElementMenu" style="min-height: 50px;border-radius: 5px" matRipple>
                <div style="text-align: center;padding: 5px">
                    <div style="font-weight: bold;">{{numberElementForPage$|async}}</div>
                    <div style="font-size: small">{{'paginator.itemsForPage'|translate}}</div>
                </div>
            </div>
        </div>
        <div>
            <button mat-icon-button color="accent" (click)="addPage()">
                <mat-icon>
                    keyboard_arrow_right
                </mat-icon>
            </button>
        </div>
    </div>
</form>

<ng-container *ngIf="items|paginationArray:(numberElementForPage$|async):(currentPage$|async) as itemsPaginated">
    <ng-container *ngFor="let item of itemsPaginated; let index=i">
        <ng-container>
            <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: item,index:index,items:items}">
            </ng-container>
        </ng-container>
    </ng-container>
</ng-container>
<mat-menu #SelectElementMenu="matMenu">
    <button mat-menu-item *ngFor="let pageSize of sizePages" (click)="setNumberElement(pageSize)">{{pageSize}}</button>
</mat-menu>