import * as Parse from 'parse';
import {allPropertyClass, stringIsSet} from "./Models";


export class FotometriaParse extends Parse.Object {
    public static CLASS_NAME = 'Fotometria';

    constructor() {
        super(FotometriaParse.CLASS_NAME);
    }

    public set ACL(value) {
        super.set('ACL', value);
    }

    public get ACL() {
        return super.get('ACL');
    }

    public set objectId(value) {
        this.id = value;
    }

    public get objectId() {
        return this.id;
    }

    public set createdAt(value) {
        super.set('createdAt', value);
    }

    public get createdAt() {
        return super.get('createdAt');
    }

    public set updatedAt(value) {
        super.set('updatedAt', value);
    }

    public get updatedAt() {
        return super.get('updatedAt');
    }

    public set nomeFamiglia(value) {
        super.set('nomeFamiglia', value);
    }

    public get nomeFamiglia() {
        return super.get('nomeFamiglia');
    }

    public set potenzaMin(value) {
        super.set('potenzaMin', value);
    }

    public get potenzaMin() {
        return super.get('potenzaMin');
    }

    public set efficienzaMax(value) {
        super.set('efficienzaMax', value);
    }

    public get efficienzaMax() {
        return super.get('efficienzaMax');
    }

    public set ottica(value) {
        super.set('ottica', value);
    }

    public get ottica() {
        return super.get('ottica');
    }

    public set efficienzaMin(value) {
        super.set('efficienzaMin', value);
    }

    public get efficienzaMin() {
        return super.get('efficienzaMin');
    }

    public set tipologiaCorpoIlluminante(value) {
        super.set('tipologiaCorpoIlluminante', value);
    }

    public get tipologiaCorpoIlluminante() {
        return super.get('tipologiaCorpoIlluminante');
    }

    public set nomeLampada(value) {
        super.set('nomeLampada', value);
    }

    public get nomeLampada() {
        return super.get('nomeLampada');
    }

    get nomeFotometria(): string {
        if (stringIsSet(this.nomeFile)) {
            return this.nomeFile
        } else if (stringIsSet(this.nomeProdotto)) {
            return this.nomeProdotto
        } else {
            return ''
        }
    }

    public set flussoMax(value) {
        super.set('flussoMax', value);
    }

    public get flussoMax() {
        return super.get('flussoMax');
    }

    public set produttore(value) {
        super.set('produttore', value);
    }

    public get produttore() {
        return super.get('produttore');
    }

    public set flussoMin(value) {
        super.set('flussoMin', value);
    }

    public get flussoMin() {
        return super.get('flussoMin');
    }

    public set fileLDT(value) {
        super.set('fileLDT', value);
    }

    public get fileLDT() {
        return super.get('fileLDT');
    }

    public set nomeProdotto(value) {
        super.set('nomeProdotto', value);
    }

    public get nomeProdotto() {
        return super.get('nomeProdotto');
    }

    public set temperaturaColore(value) {
        super.set('temperaturaColore', value);
    }

    public get temperaturaColore() {
        return super.get('temperaturaColore');
    }

    public set CRI(value) {
        super.set('CRI', value);
    }

    public get CRI() {
        return super.get('CRI');
    }

    public set nomeFile(value) {
        super.set('nomeFile', value);
    }

    public get nomeFile() {
        return super.get('nomeFile');
    }

    public set potenzaMax(value) {
        super.set('potenzaMax', value);
    }

    public get potenzaMax() {
        return super.get('potenzaMax');
    }

    public set efficienza(value) {
        super.set('efficienza', value);
    }

    public get efficienza() {
        return super.get('efficienza');
    }

    public set flussoLuminoso(value) {
        super.set('flussoLuminoso', value);
    }

    public get flussoLuminoso() {
        return super.get('flussoLuminoso');
    }

    public set potenza(value) {
        super.set('potenza', value);
    }

    public get potenza() {
        return super.get('potenza');
    }

    public set uploadedBy(value) {
        super.set('uploadedBy', value);
    }

    public get uploadedBy(): Parse.User {
        return super.get('uploadedBy');
    }

    public get query() {
        const query = new Parse.Query(FotometriaParse);
        return query;
    }

    public allProperty() {
        return allPropertyClass(FotometriaParse)
    }

    public allPropertyOnParse() {
        const notInParse = ['constructor', 'query', 'allPropertyOnParse', 'allProperty'];
        return this.allProperty().filter(key => !notInParse.includes(key))
    }


}

Parse.Object.registerSubclass(FotometriaParse.CLASS_NAME, FotometriaParse);
