import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-box-adding-tab',
  templateUrl: './box-adding-tab.component.html',
  styleUrls: ['./box-adding-tab.component.scss']
})
export class BoxAddingTabComponent implements OnInit {

  @Input() titolo;
  @Input() color;
  @Input() maxWidth:string='80px';
  constructor() { }

  ngOnInit(): void {
  }

}
