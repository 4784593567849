import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {InstallazioneLampade} from "../../models/InstallazioneLampade.Parse";
import {isNotNullOrUndefined} from "../../models/Models";

export interface visualizedOnHtml {
    key: string,
    after: string | number,
    before: string | number
}

@Component({
    selector: 'app-box-installazione-lampade',
    templateUrl: './box-installazione-lampade.component.html',
    styleUrls: ['./box-installazione-lampade.component.scss']
})
export class BoxInstallazioneLampadeComponent implements OnInit, OnChanges {

    @Input()
    installazioneLampada: InstallazioneLampade;
    @Input()
    selezionata: boolean = false;

    public datiInstallazioneLampada: visualizedOnHtml[] = [];
    public locationInstallazioneLampada: visualizedOnHtml[] = [];
    public idMezzanotteInstallazioneLampada: visualizedOnHtml;
    public labelnstallazioneLampada;

    constructor() {
    }

    ngOnInit(): void {
        this.transformInArray();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.transformInArray();
    }

    get color(): string {
        if (this.selezionata) {
            return '#f38232';

        } else {
            return '#5dbed7';
        }
    }

    private transformInArray() {
        this.locationInstallazioneLampada = [];
        this.datiInstallazioneLampada = [];
        if (!!this.installazioneLampada.riferimentoPuntoLuce) {
            if (!!this.installazioneLampada.datiPuntoLuce) {
                Object.keys(this.installazioneLampada.datiPuntoLuce).forEach((key) => {
                    const before = (this.installazioneLampada.riferimentoPuntoLuce[key]) ? this.installazioneLampada.riferimentoPuntoLuce[key] : 'Nd';
                    if (key == 'idMezzanotte') {
                        this.idMezzanotteInstallazioneLampada = {
                            key: key,
                            after: this.installazioneLampada.datiPuntoLuce[key],
                            before: before
                        };
                    } else if (key == 'targaCustom') {
                        this.labelnstallazioneLampada = this.installazioneLampada.datiPuntoLuce[key];
                    } else if (key == 'circuito') {
                        this.datiInstallazioneLampada.push({
                            key: key,
                            after: this.installazioneLampada.datiPuntoLuce[key].numeroQuadro,
                            before: before != null ? before.numeroQuadro : 'ND'
                        });
                    } else {
                        this.datiInstallazioneLampada.push({
                            key: key,
                            after: this.installazioneLampada.datiPuntoLuce[key],
                            before: before
                        });
                    }
                });
            }
            if (!!this.installazioneLampada.location) {
                const beforeLatitude = (this.installazioneLampada.riferimentoPuntoLuce.location.latitude) ? Number.parseFloat(this.installazioneLampada.riferimentoPuntoLuce.location.latitude).toFixed(8) : 'Nd';
                const beforeLongitude = (this.installazioneLampada.riferimentoPuntoLuce.location.longitude) ? Number.parseFloat(this.installazioneLampada.riferimentoPuntoLuce.location.longitude).toFixed(8) : 'Nd';
                const afterLatitude = Number.parseFloat(this.installazioneLampada.location.latitude).toFixed(8)
                const afterLongitude = Number.parseFloat(this.installazioneLampada.location.longitude).toFixed(8)

                this.locationInstallazioneLampada.push({
                    key: 'latitude',
                    after: afterLatitude,
                    before: beforeLatitude
                }, {
                    key: 'longitude',
                    after: afterLongitude,
                    before: beforeLongitude
                });
            }
        } else {
            if (!!this.installazioneLampada.datiPuntoLuce) {
                Object.keys(this.installazioneLampada.datiPuntoLuce).forEach((key) => {
                    if (key == 'idMezzanotte') {
                        this.idMezzanotteInstallazioneLampada = {
                            key: key,
                            after: this.installazioneLampada.datiPuntoLuce[key],
                            before: 'Nd'
                        };
                    } else if (key == 'targaCustom') {
                        this.labelnstallazioneLampada = this.installazioneLampada.datiPuntoLuce[key];
                    } else if (key == 'circuito') {
                        this.datiInstallazioneLampada.push({
                            key: key,
                            after: this.installazioneLampada.datiPuntoLuce[key].numeroQuadro,
                            before: 'Nd'
                        });
                    } else {
                        this.datiInstallazioneLampada.push({
                            key: key,
                            after: this.installazioneLampada.datiPuntoLuce[key],
                            before: 'Nd'
                        });
                    }
                });
            }
            if (!!this.installazioneLampada.location) {
                this.locationInstallazioneLampada.push({
                    key: 'latitude',
                    after: Number.parseFloat(this.installazioneLampada.location.latitude).toFixed(8),
                    before: 'Nd'
                }, {
                    key: 'longitude',
                    after: Number.parseFloat(this.installazioneLampada.location.longitude).toFixed(8),
                    before: 'Nd'
                });
            }
        }
    }

    public get labelEsistente() {
        return isNotNullOrUndefined(this.labelnstallazioneLampada)
    }

    public get allFieldIsEmpty() {
        return this.datiInstallazioneLampada.length == 0 &&
            this.locationInstallazioneLampada.length == 0 &&
            !isNotNullOrUndefined(this.idMezzanotteInstallazioneLampada);
    }

}
