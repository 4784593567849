import {configurationElement,  typeDatabase, typeFormValue} from "./configurationPropertyUtils";
import {CircuitiParse} from "../Circuiti.Parse";


export const configurationPropertyElectricLine: configurationElement[] =
    [{
        keyName: 'createdAt',
        varianteKey: '',
        traductionKey: 'dashboard_sidenav.LineaElettrica.createdAt.title',
        typeForm: typeFormValue.DATE,
        typeDatabase: typeDatabase.DATE,
        editable: false,
        showInForm: false,
        filtrable: true,
        copyable: false,
        required: false,
        possibleValues: undefined,
        pointer: undefined,
        sortingValue: 200,
        section: 0,
        forms: undefined
    }, {
        keyName: 'updatedAt',
        varianteKey: '',
        traductionKey: 'dashboard_sidenav.LineaElettrica.updatedAt.title',
        typeForm: typeFormValue.DATE,
        typeDatabase: typeDatabase.DATE,
        editable: false,
        showInForm: false,
        filtrable: true,
        copyable: false,
        required: false,
        possibleValues: undefined,
        pointer: undefined,
        sortingValue: 300,
        section: 0,
        forms: undefined
    }, {
        keyName: 'circuito',
        varianteKey: '',
        traductionKey: 'dashboard_sidenav.LineaElettrica.circuito.title',
        typeForm: typeFormValue.ELENCO_VINCOLATO,
        typeDatabase: typeDatabase.POINTER,
        editable: true,
        showInForm: true,
        filtrable: true,
        copyable: true,
        required: false,
        possibleValues: undefined,
        pointer: {
            className: 'Circuiti',
            getValue: (circuito: CircuitiParse) => {
                return circuito.numeroQuadro;
            },
            getId: (circuito: CircuitiParse) => {
                return circuito.objectId;
            }
        },
        sortingValue: 401,
        section: 0,
        forms: undefined
    }, {
        keyName: 'lengthConvertInMeter',
        varianteKey: '',
        traductionKey: 'dashboard_sidenav.LineaElettrica.lengthConvertInMeter.title',
        typeForm: typeFormValue.INPUT_TEXT,
        typeDatabase: typeDatabase.STRING,
        editable: false,
        showInForm: false,
        filtrable: true,
        copyable: false,
        required: false,
        possibleValues: undefined,
        pointer: undefined,
        sortingValue: 400,
        section: 0,
        forms: undefined
    }, {
        keyName: 'name',
        varianteKey: undefined,
        traductionKey: 'dashboard_sidenav.LineaElettrica.name.title',
        typeForm: typeFormValue.INPUT_TEXT,
        typeDatabase: typeDatabase.STRING,
        editable: true,
        showInForm: true,
        filtrable: true,
        copyable: false,
        required: true,
        possibleValues: undefined,
        pointer: undefined,
        sortingValue: 500,
        section: 0,
        forms: undefined
    }, {
        keyName: 'description',
        varianteKey: undefined,
        traductionKey: 'dashboard_sidenav.LineaElettrica.description.title',
        typeForm: typeFormValue.INPUT_TEXT,
        typeDatabase: typeDatabase.STRING,
        editable: true,
        showInForm: true,
        filtrable: true,
        copyable: false,
        required: false,
        possibleValues: undefined,
        pointer: undefined,
        sortingValue: 600,
        section: 0,
        forms: undefined
    }, {
        keyName: 'posizionamentoLinea',
        varianteKey: undefined,
        traductionKey: 'dashboard_sidenav.LineaElettrica.posizionamentoLinea.title',
        typeForm: typeFormValue.ELENCO_VINCOLATO,
        typeDatabase: typeDatabase.STRING,
        editable: true,
        showInForm: true,
        filtrable: true,
        copyable: false,
        required: false,
        possibleValues: [
            {
                dataBaseValue: 'AEREA',
                traduction: 'dashboard_sidenav.LineaElettrica.posizionamentoLinea.possibleValues.AEREA'
            }, {
                dataBaseValue: 'INTERRATA',
                traduction: 'dashboard_sidenav.LineaElettrica.posizionamentoLinea.possibleValues.INTERRATA'
            }, {
                dataBaseValue: 'INTERRATA_CON_RISALITA',
                traduction: 'dashboard_sidenav.LineaElettrica.posizionamentoLinea.possibleValues.INTERRATA_CON_RISALITA'
            }, {
                dataBaseValue: 'SOTTOTRACCIA',
                traduction: 'dashboard_sidenav.LineaElettrica.posizionamentoLinea.possibleValues.SOTTOTRACCIA'
            }, {
                dataBaseValue: 'A_PARETE',
                traduction: 'dashboard_sidenav.LineaElettrica.posizionamentoLinea.possibleValues.A_PARETE'
            }, {
                dataBaseValue: 'PRECORDATO_SU_PALO',
                traduction: 'dashboard_sidenav.LineaElettrica.posizionamentoLinea.possibleValues.PRECORDATO_SU_PALO'
            }, {
                dataBaseValue: 'PRECORDATO_A_PARETE',
                traduction: 'dashboard_sidenav.LineaElettrica.posizionamentoLinea.possibleValues.PRECORDATO_A_PARETE'
            }, {
                dataBaseValue: 'SU_FUNE_SU_PALO',
                traduction: 'dashboard_sidenav.LineaElettrica.posizionamentoLinea.possibleValues.SU_FUNE_SU_PALO'
            }, {
                dataBaseValue: 'SU_FUNE_A_PARETE',
                traduction: 'dashboard_sidenav.LineaElettrica.posizionamentoLinea.possibleValues.SU_FUNE_A_PARETE'
            }, {
                dataBaseValue: 'NON_SPECIFICATA',
                traduction: 'dashboard_sidenav.LineaElettrica.posizionamentoLinea.possibleValues.NON_SPECIFICATA'
            },
        ],
        pointer: undefined,
        sortingValue: 700,
        section: 0,
        forms: undefined
    }, {
        keyName: 'numeroPoli',
        varianteKey: undefined,
        traductionKey: 'dashboard_sidenav.LineaElettrica.numeroPoli.title',
        typeForm: typeFormValue.INPUT_INTEGER,
        typeDatabase: typeDatabase.NUMBER,
        editable: true,
        showInForm: true,
        filtrable: true,
        copyable: false,
        required: false,
        possibleValues: undefined,
        pointer: undefined,
        sortingValue: 800,
        section: 0,
        forms: undefined
    }, {
        keyName: 'tipologiaIsolamento',
        varianteKey: undefined,
        traductionKey: 'dashboard_sidenav.LineaElettrica.tipologiaIsolamento.title',
        typeForm: typeFormValue.ELENCO_VINCOLATO,
        typeDatabase: typeDatabase.STRING,
        editable: true,
        showInForm: true,
        filtrable: true,
        copyable: false,
        required: false,
        possibleValues: [
            {
                dataBaseValue: 'DOPPIO',
                traduction: 'dashboard_sidenav.LineaElettrica.tipologiaIsolamento.possibleValues.DOPPIO'
            }, {
                dataBaseValue: 'SINGOLO',
                traduction: 'dashboard_sidenav.LineaElettrica.tipologiaIsolamento.possibleValues.SINGOLO'
            }, {
                dataBaseValue: 'NESSUNO',
                traduction: 'dashboard_sidenav.LineaElettrica.tipologiaIsolamento.possibleValues.NESSUNO'
            }
        ],
        pointer: undefined,
        sortingValue: 900,
        section: 0,
        forms: undefined
    }, {
        keyName: 'cavoDiTerraPresente',
        varianteKey: undefined,
        traductionKey: 'dashboard_sidenav.LineaElettrica.cavoDiTerraPresente.title',
        typeForm: typeFormValue.CHECKBOX,
        typeDatabase: typeDatabase.BOOLEAN,
        editable: true,
        showInForm: true,
        filtrable: true,
        copyable: false,
        required: false,
        possibleValues: undefined,
        pointer: undefined,
        sortingValue: 1000,
        section: 0,
        forms: undefined
    }, {
        keyName: 'sezione',
        varianteKey: undefined,
        traductionKey: 'dashboard_sidenav.LineaElettrica.sezione.title',
        typeForm: typeFormValue.ELENCO_VINCOLATO,
        typeDatabase: typeDatabase.NUMBER,
        editable: true,
        showInForm: true,
        filtrable: true,
        copyable: false,
        required: false,
        possibleValues: [
            {
                dataBaseValue: 1.5,
                noTraduction: '1.5'
            }, {
                dataBaseValue: 2.5,
                noTraduction: '2.5'
            }, {
                dataBaseValue: 4,
                noTraduction: '4'
            }, {
                dataBaseValue: 6,
                noTraduction: '6'
            }, {
                dataBaseValue: 10,
                noTraduction: '10'
            }, {
                dataBaseValue: 16,
                noTraduction: '16'
            }, {
                dataBaseValue: 0.14,
                noTraduction: '0.14'
            }, {
                dataBaseValue: 0.34,
                noTraduction: '0.34'
            }, {
                dataBaseValue: 0.5,
                noTraduction: '0.5'
            }, {
                dataBaseValue: 0.75,
                noTraduction: '0.75'
            }, {
                dataBaseValue: 1,
                noTraduction: '1'
            }, {
                dataBaseValue: 1.25,
                noTraduction: '1.25'
            }, {
                dataBaseValue: 25,
                noTraduction: '25'
            }, {
                dataBaseValue: 35,
                noTraduction: '35'
            }, {
                dataBaseValue: 50,
                noTraduction: '50'
            }, {
                dataBaseValue: 75,
                noTraduction: '75'
            },
        ],
        pointer: undefined,
        sortingValue: 1100,
        section: 0,
        forms: undefined
    }, {
        keyName: 'coloreGuaina1',
        varianteKey: undefined,
        traductionKey: 'dashboard_sidenav.LineaElettrica.coloreGuaina1.title',
        typeForm: typeFormValue.ELENCO_VINCOLATO,
        typeDatabase: typeDatabase.STRING,
        editable: true,
        showInForm: true,
        filtrable: true,
        copyable: false,
        required: false,
        possibleValues: [
            {
                dataBaseValue: 'GRIGIO',
                traduction: 'dashboard_sidenav.LineaElettrica.coloreGuaina1.possibleValues.GRIGIO'
            }, {
                dataBaseValue: 'BIANCO',
                traduction: 'dashboard_sidenav.LineaElettrica.coloreGuaina1.possibleValues.BIANCO'
            }, {
                dataBaseValue: 'NERO',
                traduction: 'dashboard_sidenav.LineaElettrica.coloreGuaina1.possibleValues.NERO'
            }, {
                dataBaseValue: 'ARANCIONE',
                traduction: 'dashboard_sidenav.LineaElettrica.coloreGuaina1.possibleValues.ARANCIONE'
            }, {
                dataBaseValue: 'AZZURRO',
                traduction: 'dashboard_sidenav.LineaElettrica.coloreGuaina1.possibleValues.AZZURRO'
            }, {
                dataBaseValue: 'BLU',
                traduction: 'dashboard_sidenav.LineaElettrica.coloreGuaina1.possibleValues.BLU'
            }, {
                dataBaseValue: 'BLU_SCURO',
                traduction: 'dashboard_sidenav.LineaElettrica.coloreGuaina1.possibleValues.BLU_SCURO'
            }, {
                dataBaseValue: 'GIALLO',
                traduction: 'dashboard_sidenav.LineaElettrica.coloreGuaina1.possibleValues.GIALLO'
            }, {
                dataBaseValue: 'MARRONE',
                traduction: 'dashboard_sidenav.LineaElettrica.coloreGuaina1.possibleValues.MARRONE'
            }, {
                dataBaseValue: 'ROSSO',
                traduction: 'dashboard_sidenav.LineaElettrica.coloreGuaina1.possibleValues.ROSSO'
            }, {
                dataBaseValue: 'VERDE',
                traduction: 'dashboard_sidenav.LineaElettrica.coloreGuaina1.possibleValues.VERDE'
            }, {
                dataBaseValue: 'ROSA',
                traduction: 'dashboard_sidenav.LineaElettrica.coloreGuaina1.possibleValues.ROSA'
            },
        ],
        pointer: undefined,
        sortingValue: 1200,
        section: 0,
        forms: undefined
    }, {
        keyName: 'coloreGuaina2',
        varianteKey: undefined,
        traductionKey: 'dashboard_sidenav.LineaElettrica.coloreGuaina2.title',
        typeForm: typeFormValue.ELENCO_VINCOLATO,
        typeDatabase: typeDatabase.STRING,
        editable: true,
        showInForm: true,
        filtrable: true,
        copyable: false,
        required: false,
        possibleValues: [
            {
                dataBaseValue: 'GRIGIO',
                traduction: 'dashboard_sidenav.LineaElettrica.coloreGuaina1.possibleValues.GRIGIO'
            }, {
                dataBaseValue: 'BIANCO',
                traduction: 'dashboard_sidenav.LineaElettrica.coloreGuaina1.possibleValues.BIANCO'
            }, {
                dataBaseValue: 'NERO',
                traduction: 'dashboard_sidenav.LineaElettrica.coloreGuaina1.possibleValues.NERO'
            }, {
                dataBaseValue: 'ARANCIONE',
                traduction: 'dashboard_sidenav.LineaElettrica.coloreGuaina1.possibleValues.ARANCIONE'
            }, {
                dataBaseValue: 'AZZURRO',
                traduction: 'dashboard_sidenav.LineaElettrica.coloreGuaina1.possibleValues.AZZURRO'
            }, {
                dataBaseValue: 'BLU',
                traduction: 'dashboard_sidenav.LineaElettrica.coloreGuaina1.possibleValues.BLU'
            }, {
                dataBaseValue: 'BLU_SCURO',
                traduction: 'dashboard_sidenav.LineaElettrica.coloreGuaina1.possibleValues.BLU_SCURO'
            }, {
                dataBaseValue: 'GIALLO',
                traduction: 'dashboard_sidenav.LineaElettrica.coloreGuaina1.possibleValues.GIALLO'
            }, {
                dataBaseValue: 'MARRONE',
                traduction: 'dashboard_sidenav.LineaElettrica.coloreGuaina1.possibleValues.MARRONE'
            }, {
                dataBaseValue: 'ROSSO',
                traduction: 'dashboard_sidenav.LineaElettrica.coloreGuaina1.possibleValues.ROSSO'
            }, {
                dataBaseValue: 'VERDE',
                traduction: 'dashboard_sidenav.LineaElettrica.coloreGuaina1.possibleValues.VERDE'
            }, {
                dataBaseValue: 'ROSA',
                traduction: 'dashboard_sidenav.LineaElettrica.coloreGuaina1.possibleValues.ROSA'
            },
        ],
        pointer: undefined,
        sortingValue: 1200,
        section: 0,
        forms: undefined
    }, {
        keyName: 'correnteNominale',
        varianteKey: undefined,
        traductionKey: 'dashboard_sidenav.LineaElettrica.correnteNominale.title',
        typeForm: typeFormValue.INPUT_NUMBER,
        typeDatabase: typeDatabase.NUMBER,
        editable: true,
        showInForm: true,
        filtrable: true,
        copyable: false,
        required: false,
        possibleValues: undefined,
        pointer: undefined,
        sortingValue: 1200,
        section: 0,
        forms: undefined
    }, {
        keyName: 'tensioneMaxAC',
        varianteKey: undefined,
        traductionKey: 'dashboard_sidenav.LineaElettrica.tensioneMaxAC.title',
        typeForm: typeFormValue.INPUT_NUMBER,
        typeDatabase: typeDatabase.NUMBER,
        editable: true,
        showInForm: true,
        filtrable: true,
        copyable: false,
        required: false,
        possibleValues: undefined,
        pointer: undefined,
        sortingValue: 1300,
        section: 0,
        forms: undefined
    }, {
        keyName: 'tensioneMaxDC',
        varianteKey: undefined,
        traductionKey: 'dashboard_sidenav.LineaElettrica.tensioneMaxDC.title',
        typeForm: typeFormValue.INPUT_NUMBER,
        typeDatabase: typeDatabase.NUMBER,
        editable: true,
        showInForm: true,
        filtrable: true,
        copyable: false,
        required: false,
        possibleValues: undefined,
        pointer: undefined,
        sortingValue: 1400,
        section: 0,
        forms: undefined
    }, {
        keyName: 'temperaturaMin',
        varianteKey: undefined,
        traductionKey: 'dashboard_sidenav.LineaElettrica.temperaturaMin.title',
        typeForm: typeFormValue.INPUT_NUMBER,
        typeDatabase: typeDatabase.NUMBER,
        editable: true,
        showInForm: true,
        filtrable: true,
        copyable: false,
        required: false,
        possibleValues: undefined,
        pointer: undefined,
        sortingValue: 1500,
        section: 0,
        forms: undefined
    }, {
        keyName: 'temperaturaMax',
        varianteKey: undefined,
        traductionKey: 'dashboard_sidenav.LineaElettrica.temperaturaMax.title',
        typeForm: typeFormValue.INPUT_NUMBER,
        typeDatabase: typeDatabase.NUMBER,
        editable: true,
        showInForm: true,
        filtrable: true,
        copyable: false,
        required: false,
        possibleValues: undefined,
        pointer: undefined,
        sortingValue: 1600,
        section: 0,
        forms: undefined
    }, {
        keyName: 'tipologiaConduttore',
        varianteKey: undefined,
        traductionKey: 'dashboard_sidenav.LineaElettrica.tipologiaConduttore.title',
        typeForm: typeFormValue.ELENCO_VINCOLATO,
        typeDatabase: typeDatabase.STRING,
        editable: true,
        showInForm: true,
        filtrable: true,
        copyable: false,
        required: false,
        possibleValues: [
            {
                dataBaseValue: 'INTRECCIATO',
                traduction: 'dashboard_sidenav.LineaElettrica.tipologiaConduttore.possibleValues.INTRECCIATO'
            }, {
                dataBaseValue: 'SOLIDO',
                traduction: 'dashboard_sidenav.LineaElettrica.tipologiaConduttore.possibleValues.SOLIDO'
            }],
        pointer: undefined,
        sortingValue: 1700,
        section: 0,
        forms: undefined
    }, {
        keyName: 'materialeConduttore',
        varianteKey: undefined,
        traductionKey: 'dashboard_sidenav.LineaElettrica.materialeConduttore.title',
        typeForm: typeFormValue.ELENCO_VINCOLATO,
        typeDatabase: typeDatabase.STRING,
        editable: true,
        showInForm: true,
        filtrable: true,
        copyable: false,
        required: false,
        possibleValues: [
            {
                dataBaseValue: 'RAME',
                traduction: 'dashboard_sidenav.LineaElettrica.materialeConduttore.possibleValues.RAME'
            }, {
                dataBaseValue: 'ALLUMINIO',
                traduction: 'dashboard_sidenav.LineaElettrica.materialeConduttore.possibleValues.ALLUMINIO'
            }],
        pointer: undefined,
        sortingValue: 1800,
        section: 0,
        forms: undefined
    }, {
        keyName: 'produttore',
        varianteKey: undefined,
        traductionKey: 'dashboard_sidenav.LineaElettrica.produttore.title',
        typeForm: typeFormValue.INPUT_TEXT,
        typeDatabase: typeDatabase.STRING,
        editable: true,
        showInForm: true,
        filtrable: true,
        copyable: false,
        required: false,
        possibleValues: undefined,
        pointer: undefined,
        sortingValue: 1800,
        section: 0,
        forms: undefined
    }, {
        keyName: 'modello',
        varianteKey: undefined,
        traductionKey: 'dashboard_sidenav.LineaElettrica.modello.title',
        typeForm: typeFormValue.INPUT_TEXT,
        typeDatabase: typeDatabase.STRING,
        editable: true,
        showInForm: true,
        filtrable: true,
        copyable: false,
        required: false,
        possibleValues: undefined,
        pointer: undefined,
        sortingValue: 1800,
        section: 0,
        forms: undefined
    }, {
        keyName: 'schermato',
        varianteKey: undefined,
        traductionKey: 'dashboard_sidenav.LineaElettrica.schermato.title',
        typeForm: typeFormValue.CHECKBOX,
        typeDatabase: typeDatabase.BOOLEAN,
        editable: true,
        showInForm: true,
        filtrable: true,
        copyable: false,
        required: false,
        possibleValues: undefined,
        pointer: undefined,
        sortingValue: 1800,
        section: 0,
        forms: undefined
    }, {
        keyName: 'daPosare',
        varianteKey: undefined,
        traductionKey: 'dashboard_sidenav.LineaElettrica.daPosare.title',
        typeForm: typeFormValue.CHECKBOX,
        typeDatabase: typeDatabase.BOOLEAN,
        editable: true,
        showInForm: true,
        filtrable: true,
        copyable: false,
        required: false,
        possibleValues: undefined,
        pointer: undefined,
        sortingValue: 1900,
        section: 0,
        forms: undefined
    }, {
        keyName: 'annoInstallazione',
        varianteKey: undefined,
        traductionKey: 'dashboard_sidenav.LineaElettrica.annoInstallazione.title',
        typeForm: typeFormValue.INPUT_INTEGER,
        typeDatabase: typeDatabase.NUMBER,
        editable: true,
        showInForm: true,
        filtrable: true,
        copyable: false,
        required: false,
        possibleValues: undefined,
        pointer: undefined,
        sortingValue: 2000,
        section: 0,
        forms: undefined
    },
    ];
//



