<mat-dialog-content>
    <mat-expansion-panel class="mat-elevation-z1" [disabled]="numberFileSelected === 0" [(expanded)]="openSelectedFile" hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <h3 *ngIf="numberFileSelected!==0;else noFileSelected">{{numberFileSelected}} {{'fileManager.selectedFile'|translate}}</h3>
                <ng-template #noFileSelected>
                    <h3>{{'fileManager.notSelectedFile'|translate}} </h3>
                </ng-template>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-chip-list>
            <ng-container *ngFor="let file of selectedFiles">
                <mat-chip
                     (removed)="selectFile(file)">
                     {{file.name}}
                     <button matChipRemove>
                        <mat-icon>cancel</mat-icon>
                      </button>
                </mat-chip>
            </ng-container>
        </mat-chip-list>
    </mat-expansion-panel>
    <div style="display: flex; align-items: center; margin-top: 8px; overflow-x: auto; max-width: 100%;" class="scrollbar-visible">
        <button class="path-navigation-button" [disabled]="openPaths.length === 0" (click)="goBackMultipleSteps(openPaths.length)">{{homeFolderName}}</button>
        <span *ngFor="let path of openPaths; let i = index" style="display: flex; align-items: center; white-space: nowrap;">
            <mat-icon>
                chevron_right
            </mat-icon>
            <button class="path-navigation-button" [disabled]="pathButtonDisabled(i)" (click)="goBackMultipleSteps(openPaths.length - i - 1)">{{path}}</button>
        </span>
    </div>
    <div style="overflow: auto">
        <mat-action-list class="w100" *ngIf="!loading.folder; else loadingFolder">
            <ng-container *ngFor="let folder of folders">
                <button mat-list-item (click)="openFolder(folder)">
                    <mat-icon color="accent" matListIcon>folder_open</mat-icon>
                    <span style="display: inline-flex; justify-content: space-between; width: 100%; align-items: center;">{{(folder.name|translateByPredicateFunction:traductionFunction)|async}}
                    <mat-icon>keyboard_arrow_right</mat-icon></span>
                </button>
            </ng-container>
            <ng-container *ngFor="let file of files">
                <button mat-list-item (click)="selectFile(file)" [ngClass]="{'selected-file': isSelectedFile(file) }">
                    <mat-icon [color]="getColorIcon(file)" matListIcon>insert_drive_file</mat-icon>
                    {{file.name}}
                </button>
            </ng-container>
        </mat-action-list>
        <ng-template #loadingFolder>
            <mat-spinner style="margin: 20px auto;"></mat-spinner>
        </ng-template>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="flex-center-width100">
        <div class="flex-around-center" style="width: 350px;max-width: calc(100% - 50px)">
            <button [disabled]="disableComeBackButton"
                    (click)="comeBack()"
                    mat-flat-button type="button">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <button (click)="closeDialog('close')" mat-flat-button color="warn" type="button">
                <mat-icon>clear</mat-icon>
            </button>
            <button [disabled]="disableSaveButton"
                    (click)="closeDialog('connectFile')"
                    mat-flat-button color="primary" type="button">
                <mat-icon>done</mat-icon>
            </button>
        </div>
    </div>
</mat-dialog-actions>