import {Injectable} from '@angular/core';
import {TransformForTranslatePipe} from "../pipes/transform-for-translate.pipe";
import {
    chiaviScala, isNotNullOrUndefined, puntiLuceScalaColorePredefiniti, puntiLuceValueScalaColore,
    qualitativeScaleColor, stringIsSet
} from "../../models/Models";
import {ScaleColorService} from "./scale-color.service";
import {DatePipe} from "@angular/common";
import {IconService} from "./icon.service";
import {ordinamentoEcampiTraduzioni} from "../../models/ordinamentoEcampiTraduzioni";
import {PuntiLuceParse} from "../../models/PuntiLuce.Parse";
import {isObject} from "rxjs/internal-compatibility";
import {LocalSotrageService} from "./local-sotrage.service";

@Injectable({
    providedIn: 'root'
})
export class PuntiLuceScaleColorService {

    private _puntiLuceInMappa: PuntiLuceParse[] = [];
    private _campoScalaColore: string;
    private valueForScalaColore = puntiLuceValueScalaColore;
    private scalaColorePredefinite = puntiLuceScalaColorePredefiniti;
    private chiaviScalaColore = chiaviScala;
    public scalaColoreCompleta;
    public scalaColoreArray = [];

    constructor(private scaleColorService: ScaleColorService,
                private datePipe: DatePipe,
                private iconService: IconService,
                private localStorage: LocalSotrageService) {
        if (stringIsSet(localStorage.labelScaleColorLightPoints) && !stringIsSet(this.campoScalaColore)) {
            this.campoScalaColore = localStorage.labelScaleColorLightPoints;
        } else if (!stringIsSet(this.campoScalaColore)) {
            this.campoScalaColore = 'tipologiaSorgenteLuminosa';
        }
    }

    get puntiLuceInMappa() {
        return this._puntiLuceInMappa;
    }

    set puntiLuceInMappa(value) {
        this._puntiLuceInMappa = value;
    }

    get campoScalaColore() {
        return this._campoScalaColore;
    }

    set campoScalaColore(value) {
        this._campoScalaColore = value;
        this.localStorage.labelScaleColorLightPoints = value;
    }

    public get tipoDato() {
        const getType = (key) => {
            return ordinamentoEcampiTraduzioni.PuntiLuce[key].type.toLowerCase();
        }
        if (ordinamentoEcampiTraduzioni.PuntiLuce.hasOwnProperty(this.campoScalaColore)) {
            return getType(this.campoScalaColore);
        } else if (this.campoScalaColore == 'lampadaFunzionante') {
            return this.campoScalaColore;
        } else {
            const key = this.keyOfVarianteKey;
            if (isNotNullOrUndefined(key)) {
                return getType(key);
            }
        }
    };


    get keyOfVarianteKey() {
        const keys = Object.keys(ordinamentoEcampiTraduzioni.PuntiLuce);
        const index = keys.findIndex(
            key => {
                return ordinamentoEcampiTraduzioni.PuntiLuce[key].hasOwnProperty('varianteKey') && ordinamentoEcampiTraduzioni.PuntiLuce[key].varianteKey === this.campoScalaColore
            }
        );
        return (index >= 0) ? keys[index] : undefined
    }

    public get isPointer() {
        return this.tipoDato.includes('pointer');
    }

    get possibleValues(): string[] {
        if (this.isPointer && ordinamentoEcampiTraduzioni.PuntiLuce.hasOwnProperty(this.campoScalaColore)) {
            return (ordinamentoEcampiTraduzioni.PuntiLuce[this.campoScalaColore] &&
                ordinamentoEcampiTraduzioni.PuntiLuce[this.campoScalaColore].hasOwnProperty('possibleValues') &&
                typeof ordinamentoEcampiTraduzioni.PuntiLuce[this.campoScalaColore].possibleValues === 'string') ?
                ordinamentoEcampiTraduzioni.PuntiLuce[this.campoScalaColore].possibleValues.split('.') : [];
        } else if (ordinamentoEcampiTraduzioni.PuntiLuce.hasOwnProperty(this.campoScalaColore)) {
            return (
                ordinamentoEcampiTraduzioni.PuntiLuce[this.campoScalaColore] &&
                ordinamentoEcampiTraduzioni.PuntiLuce[this.campoScalaColore].hasOwnProperty('possibleValues')) ?
                ordinamentoEcampiTraduzioni.PuntiLuce[this.campoScalaColore].possibleValues : [];
        } else {
            const key = this.keyOfVarianteKey;
            if (this.isPointer) {
                return (ordinamentoEcampiTraduzioni.PuntiLuce[key] &&
                    ordinamentoEcampiTraduzioni.PuntiLuce[key].hasOwnProperty('possibleValues') &&
                    typeof ordinamentoEcampiTraduzioni.PuntiLuce[key].possibleValues === 'string') ?
                    ordinamentoEcampiTraduzioni.PuntiLuce[key].possibleValues.split('.') : [];
            } else {
                return (
                    isNotNullOrUndefined(key) &&
                    ordinamentoEcampiTraduzioni.PuntiLuce[key] &&
                    ordinamentoEcampiTraduzioni.PuntiLuce[key].hasOwnProperty('possibleValues')) ?
                    ordinamentoEcampiTraduzioni.PuntiLuce[key].possibleValues : [];
            }
        }
    }

    resetAllVariable() {
        this.puntiLuceInMappa = [];
        this.campoScalaColore = undefined;
        this.scalaColoreCompleta = undefined;
        this.scalaColoreArray = undefined;
    }

    public createKey(key: any | undefined) {
        if (!isNotNullOrUndefined(key) || typeof key === 'object') {
            const filterPipe = new TransformForTranslatePipe();
            const chiaveSenzaSpazi = filterPipe.transform(this.chiaviScalaColore.non_specificato) as string;
            return chiaveSenzaSpazi;
        } else if (typeof key === 'string') {
            const filterPipe = new TransformForTranslatePipe();
            const chiaveSenzaSpazi = filterPipe.transform(key) as string;
            return chiaveSenzaSpazi;
        } else {
            return key;
        }
    }

    public getKey(puntoLuce: PuntiLuceParse | undefined, key: string) {
        let value = puntoLuce[key]
        let tipoDato = this.tipoDato
        if (ordinamentoEcampiTraduzioni.PuntiLuce.hasOwnProperty(this.campoScalaColore) && ordinamentoEcampiTraduzioni.PuntiLuce[this.campoScalaColore].getValueInLabel != null) {
            const {
                key,
                showingLikeType
            } = ordinamentoEcampiTraduzioni.PuntiLuce[this.campoScalaColore].getValueInLabel(puntoLuce);
            value = key;
            tipoDato = showingLikeType
        }
        if (!isNotNullOrUndefined(value)) {
            const filterPipe = new TransformForTranslatePipe();
            const chiaveSenzaSpazi = filterPipe.transform(this.chiaviScalaColore.non_specificato) as string;
            return chiaveSenzaSpazi;
        } else if (tipoDato === 'lampadaFunzionante') {
            const lampadaFunzionante = puntoLuce.lampadaFunzionante;
            const problemaDispositivoTelecontrollo = puntoLuce.problemaDispositivoTelecontrollo;
            if (lampadaFunzionante == true) {
                if (problemaDispositivoTelecontrollo === true) {
                    return 'DISCONESSA'
                } else {
                    return 'FUNZIONANTE'
                }
            } else if (lampadaFunzionante == false) {
                return 'NON_FUNZIONANTE'
            } else {
                return chiaviScala.non_specificato
            }

        } else if (
            tipoDato === 'text' ||
            tipoDato === 'targa' ||
            tipoDato === 'elenco' ||
            this.valueForScalaColore.hasOwnProperty(this.campoScalaColore)
        ) {
            const filterPipe = new TransformForTranslatePipe();
            const chiaveSenzaSpazi = filterPipe.transform(value) as string;
            return chiaveSenzaSpazi;
        } else if (tipoDato === 'date') {
            const key = new Date(this.datePipe.transform(value, 'yyyy-MM-dd')).getTime();
            return key;
        } else if (tipoDato === 'bool') {
            const key = (value) ? 'true' : 'false';
            return key;
        } else if (this.isPointer) {
            const pointerClass = this.possibleValues[0];
            const pointerKey = this.possibleValues[1];
            const poineterValue = value[pointerKey];
            const filterPipe = new TransformForTranslatePipe();
            if (isNotNullOrUndefined(poineterValue)) {
                return filterPipe.transform(poineterValue) as string;
            } else {
                return filterPipe.transform(this.chiaviScalaColore.non_specificato) as string;
            }
        } else {
            return value;
        }
    }

    private creaScalaColore(): { scalaColore, scalaColoreHtml } {
        if (this.scalaColorePredefinite.hasOwnProperty(this.campoScalaColore)) {
            const keyColor = this.scalaColorePredefinite[this.campoScalaColore];
            return {
                scalaColore: keyColor,
                scalaColoreHtml: this.transformScalaColoreInArray(keyColor)
            };
        } else {
            let tipoDato = this.tipoDato;
            if (ordinamentoEcampiTraduzioni.PuntiLuce.hasOwnProperty(this.campoScalaColore) && ordinamentoEcampiTraduzioni.PuntiLuce[this.campoScalaColore].getValueInLabel != null) {
                const {
                    key,
                    showingLikeType
                } = ordinamentoEcampiTraduzioni.PuntiLuce[this.campoScalaColore].getValueInLabel(undefined);
                tipoDato = showingLikeType
            }
            return this.scaleColorService.creaScalaColore(this.campoScalaColore, this.puntiLuceInMappa, this.valueForScalaColore, tipoDato, this.isPointer, this.possibleValues)
        }
    }

    calcolaScalaColore(campoScalaColore: string, puntiLuceInMappa: PuntiLuceParse[]) {
        if (!isNotNullOrUndefined(campoScalaColore)) {
            this.resetAllVariable()
        } else {
            this.campoScalaColore = campoScalaColore;
            this.puntiLuceInMappa = puntiLuceInMappa;
            const scale = this.creaScalaColore();
            this.scalaColoreCompleta = scale.scalaColore;
            this.scalaColoreArray = scale.scalaColoreHtml;
        }
    }

    aggiornaScalaColore() {
        const scale = this.creaScalaColore();
        this.scalaColoreCompleta = scale.scalaColore;
        this.scalaColoreArray = scale.scalaColoreHtml;
    }


    public getIconPuntoLuce(puntoLuce, opacity = 1, color = undefined) {
        let icon = puntoLuce.icon;
        if (!isObject(icon)) {
            icon = this.iconService.compose(puntoLuce.icon, puntoLuce.tipologiaSorgenteLuminosa);
        }
        if (!isNotNullOrUndefined(this.campoScalaColore)) {
            return icon;
        } else {
            const key = this.campoScalaColore;
            const scalaColore = this.scalaColoreCompleta;
            const keyPuntoLuce = this.getKey(puntoLuce, key);

            const indexColor = Object.keys(scalaColore).findIndex(key => key == keyPuntoLuce)
            if (indexColor >= 0) {
                const colorIcon = color == null ? scalaColore[keyPuntoLuce] : color
                return this.iconService.changeColor(icon.path, icon.anchor, colorIcon, opacity);
            } else {
                return icon;
            }
        }
    }


    transformScalaColoreInArray(keyColor) {
        return this.scaleColorService.transformScalaColoreInArray(keyColor)
    }


    public lightPointsContainedNewValue(puntiLuce: PuntiLuceParse[]) {
        if (!isNotNullOrUndefined(this.campoScalaColore) || !(Array.isArray(puntiLuce) && puntiLuce.length >= 0)) {
            return false
        } else {
            const key = this.campoScalaColore;
            const scalaColore = this.scalaColoreCompleta;
            const newValueForScaleColor = puntiLuce.findIndex(
                puntoLuce => {
                    const keyPuntoLuce = this.getKey(puntoLuce, key);
                    const indexColor = Object.keys(scalaColore).findIndex(key => key == keyPuntoLuce);
                    return indexColor < 0;
                }
            );
            return newValueForScaleColor >= 0;
        }
    }


}


