<mat-dialog-content style="max-height: 75vh;">
    <app-connected-files
        [connectedFiles]="connectedFiles"
        [newConnectedFiles]="newFiles"
        [visualizedRefreshButton]="false"
        [openDialogRemoveButton]="false"
        [boxResize]="true"
        (searchButton)="searchButton($event)"
        (addingFile)="addFiles($event)"
        (removingFile)="removeFile($event)"
    >
    </app-connected-files>
    <div class="scrol" *ngIf="isSetDataSource">
        <table mat-table [dataSource]="dataSource" class="table">
            <!-- Implicit Column -->
            <ng-container [matColumnDef]="col" *ngFor="let col of displayedColumns">
                <th mat-header-cell *matHeaderCellDef> {{getPathTraductionWithKey(col)|translate}} </th>
                <td mat-cell *matCellDef="let data">
                    <ng-container [ngSwitch]="col">
                        <ng-container *ngSwitchCase="'circuito'">
                            {{getValueCircuito(data[col])}}
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            {{getValue(data,col)}}
                        </ng-container>
                    </ng-container>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button (click)="closeDialog(false)" mat-raised-button>
        <mat-icon>clear</mat-icon>
    </button>
    <button [disabled]="allConnectedFiles.length===0"
            (click)="closeDialog(true)" mat-raised-button color="primary">
        <mat-icon>save</mat-icon>
    </button>
</mat-dialog-actions>