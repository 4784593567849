import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {Subscription} from "rxjs";
import {MatCalendar, MatCalendarCellCssClasses} from "@angular/material/datepicker";
import {isNotNullOrUndefined} from "../../models/Models";
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig} from "@angular/material/legacy-dialog";

@Component({
    selector: 'app-move-date-with-range',
    templateUrl: './move-date-with-range.component.html',
    styleUrls: ['./move-date-with-range.component.scss']
})
export class MoveDateWithRangeComponent implements OnInit, OnChanges {

    @Input() startDateTitle: string;
    @Input() endDateTitle: string;
    @Input() public maxDate: Date;
    @Input() public settingDateMin: Date;
    @Input() public settingDateMax: Date;
    @Input() public forceCloseCalendar: boolean;

    @Input() disabled: boolean = false;
    @ViewChild(MatCalendar, {static: false}) calendar: MatCalendar<Date>;
    @Output() changeDateEnd = new EventEmitter();
    @Output() changeDateStart = new EventEmitter();
    @Output() changeDate = new EventEmitter();
    @Output() changeCalendar = new EventEmitter();

    public dateForm: UntypedFormGroup;
    subscriptions: Subscription[] = [];
    showCalendar = false;
    @Input() private deltaDate;
    private dialogId: string;

    constructor(private dialog: MatDialog) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        this.maxDate = today;
        const beforSevenDay = new Date();
        const deltaDate = this.deltaDate ? this.deltaDate : 7;
        beforSevenDay.setDate(today.getDate() - deltaDate);
        this.dateForm = new UntypedFormGroup({
            start: new UntypedFormControl({value: beforSevenDay, disabled: false}),
            end: new UntypedFormControl({value: today, disabled: false}),
        });
    }

    ngOnInit(): void {
        let subscription = this.dateForm.valueChanges
            .subscribe((value) => {
                this.changeDate.emit(value);
            });
        this.subscriptions.push(subscription)
        this.dateForm.get('start').setValue(this.settingDateMin);
        subscription = this.dateForm.get('start').valueChanges
            .subscribe((value) => {
                this.changeDateStart.emit(value);
            });
        this.subscriptions.push(subscription);
        subscription = this.dateForm.get('end').valueChanges
            .subscribe((value) => {
                this.changeDateEnd.emit(value);
            });
        this.subscriptions.push(subscription);
        this.dateForm.get('end').setValue(this.settingDateMax);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (isNotNullOrUndefined(changes.settingDateMax) || isNotNullOrUndefined(changes.settingDateMin)) {
            this.updateCalendar()
        }
        if('forceCloseCalendar' in changes){
            if(this.forceCloseCalendar){
                this.closeDialog()
            }
        }
    }

    updateCalendar() {
        if (this.calendar) {
            this.calendar.updateTodaysDate();
        }
    }

    changeNext() {
        if (!this.startOEndIsUnset) {
            const deltaDate = this.deltaDate ? this.deltaDate : 7;
            let end = new Date(this.settingDateMax);
            const start = new Date(end);
            start.setHours(0, 0, 0, 0);
            end.setDate(start.getDate() + deltaDate);
            if (end.getTime() > this.maxDate.getTime()) {
                end = new Date(this.maxDate.getTime())
            }
            end.setHours(0, 0, 0, 0);
            this.dateForm.get('start').setValue(start);
            this.dateForm.get('end').setValue(end);
            this.updateCalendar();
        }
    }


    changePrevius() {
        if (!this.startOEndIsUnset) {
            const deltaDate = this.deltaDate ? this.deltaDate : 7;
            const start: Date = new Date(this.settingDateMin);
            start.setHours(0, 0, 0, 0)
            const end = new Date(start);
            end.setHours(0, 0, 0, 0)
            start.setDate(end.getDate() - deltaDate);
            this.dateForm.get('start').setValue(start);
            this.dateForm.get('end').setValue(end);
            this.updateCalendar();
        }
    }


    get isAfterToday() {
        if (!this.startOEndIsUnset) {
            const end: Date = new Date(this.dateForm.get('end').value);
            end.setHours(0, 0, 0, 0);
            return end.getTime() >= this.maxDate.getTime();
        }
    }

    get startOEndIsUnset() {
        let start: Date = this.dateForm.get('start').value;
        const end: Date = this.dateForm.get('end').value;
        return !isNotNullOrUndefined(start) || !isNotNullOrUndefined(end)
    }


    isEqualsDate(date1: Date, date2: Date) {
        return isNotNullOrUndefined(date2) && isNotNullOrUndefined(date1) && date1.getDate() == date2.getDate() &&
            date1.getMonth() == date2.getMonth() &&
            date1.getFullYear() == date2.getFullYear();
    }

    isOverDate(date1: Date, date2: Date) {
        return isNotNullOrUndefined(date2) && isNotNullOrUndefined(date1) && date1.getTime() >= date2.getTime()
    }

    selectDate(event) {
        const start = this.dateForm.get('start').value;
        const end = this.dateForm.get('end').value;
        const setEndDate =
            isNotNullOrUndefined(start) && !isNotNullOrUndefined(end) && this.isOverDate(event, start)
        if (setEndDate) {
            event.setHours(0, 0, 0, 0)
            this.dateForm.get('end').setValue(event);
            this.changeCalendar.emit(this.dateForm.value)
        } else {
            event.setHours(0, 0, 0, 0)
            this.dateForm.get('start').setValue(event);
            this.dateForm.get('end').reset(null, {emitEvent: false});
        }
        this.updateCalendar();
    }

    dateClass = (d: Date): MatCalendarCellCssClasses => {
        const init: Date = new Date(this.settingDateMin);
        const end: Date = new Date(this.settingDateMax);
        const isBetween = (init: Date, x: Date, end: Date) => {
            if (isNotNullOrUndefined(init) && isNotNullOrUndefined(end)) {
                init.setHours(0, 0, 0, 0);
                end.setHours(0, 0, 0, 0);
                return init.getTime() < x.getTime() && x.getTime() < end.getTime()
            } else {
                return false;
            }
        }

        if (isBetween(init, d, end)) {
            return 'example-custom-date-class-preview'
        } else if (this.isEqualsDate(d, init) || this.isEqualsDate(d, end)) {
            return 'example-custom-date-class'
        } else {
            return ''
        }
    }


    openDialog(dialog, triggerElement: HTMLElement) {
        if (this.dialogId == null) {
            let position;
            const matDialogConfig: MatDialogConfig = new MatDialogConfig();
            matDialogConfig.width = '400px';
            matDialogConfig.maxWidth = '100%';
            matDialogConfig.hasBackdrop = false;
            if (window.innerWidth > 500 && triggerElement && triggerElement.getBoundingClientRect() != null) {
                const rect = triggerElement.getBoundingClientRect();
                position = {left: `${rect.left - 20}px`, top: `${rect.bottom + 5}px`};
                matDialogConfig.position = position;
                // if (triggerElement.clientWidth > 300) {
                //     matDialogConfig.width = triggerElement.clientWidth + 'px';
                // }
            }
            this.dialogId = new Date().getTime().toString(32) + '_Date_Dialog_'
            matDialogConfig.id = this.dialogId;
            this.dialog.open(dialog, matDialogConfig).afterClosed().subscribe(() => {
                this.dialogId = undefined;
            })
        }
    }

    closeDialog() {
        const dialog = this.dialog.getDialogById(this.dialogId);
        if (dialog != null) {
            dialog.close();
        }
        this.dialogId = undefined;
    }

    outsideCalendar(){
        console.log('outside calendar')
    }
}
