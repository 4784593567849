import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {typeFormFieldMaintenance} from "../../../providers/services/maintenance.service";
import {isNotNullOrUndefined} from "../../../models/Models";

@Component({
    selector: 'app-confirm-delete-with-note',
    templateUrl: './confirm-delete-with-note.component.html',
    styleUrls: ['./confirm-delete-with-note.component.scss']
})
export class ConfirmDeleteWithNoteComponent implements OnInit {

    public formGroup: UntypedFormGroup;
    public typeFormField = typeFormFieldMaintenance;

    constructor(public dialogRef: MatDialogRef<ConfirmDeleteWithNoteComponent>,
                @Inject(MAT_DIALOG_DATA)
                public data: { keyForm: { type, key, traduction, initValue, required } [], messages: string[] },
                private fb: UntypedFormBuilder) {
        this.formGroup = this.fb.group({})
    }

    ngOnInit(): void {
        this.data.keyForm.forEach(field => {
            const validators = [];
            if (field.required) {
                validators.push(Validators.required)
            }
            this.formGroup.addControl(field.key, this.fb.control(null, validators));
            if (isNotNullOrUndefined(field.initValue)) {
                this.formGroup.get(field.key).setValue(field.initValue);
            }
        })
    }

    get formsValue(): { type, key, traduction, required, initValue }[] {
        return this.data.keyForm
    }

    closeDialog(emitValue: boolean) {
        if (emitValue) {
            this.dialogRef.close(this.formGroup.value)
        } else {
            this.dialogRef.close(undefined)
        }
    }


}
