import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DialogPopUpInfoService} from "../../providers/services/dialog-pop-up-info.service";
import {NgxDropzoneChangeEvent} from "ngx-dropzone";
import {arrayIsSet} from "../../models/Models";


@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
    @Input() images: { src: string, objectId: string }[] = []
    @Input() visualizeAddButton = true;
    @Input() visualizeRemoveButton = true;
    @Input() loadingImage = false;
    @Output() addImages = new EventEmitter<NgxDropzoneChangeEvent>()
    @Output() removeImages = new EventEmitter<{ objectId: string }[]>()

    constructor(private dialogPopUpInfo: DialogPopUpInfoService,
               ) {
    }

    ngOnInit(): void {
    }

    onLoadImage(images: NgxDropzoneChangeEvent) {
        this.addImages.emit(images)
    }

    opeImageFull(image: any) {
        this.dialogPopUpInfo.openVisualizedImage(image.src, 'image', this.visualizeRemoveButton)
            .subscribe(
                (response) => {
                    if (response != null && response.destroy) {
                        this.removeImages.emit([image])
                    }
                }
            );
    }

    get isSetImages(): boolean {
        return arrayIsSet(this.images)
    }
}
