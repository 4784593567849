import {Component, Input, OnInit, Output} from '@angular/core';
import {Subject} from "rxjs";
import {emitMenu} from "../../providers/services/file-manager.service";
import {isNotNullOrUndefined} from "../../models/Models";

@Component({
    selector: 'app-box-document',
    templateUrl: './box-document.component.html',
    styleUrls: ['./box-document.component.scss']
})
export class BoxDocumentComponent implements OnInit {

    @Input() visualizedMoveInMenu = true;
    @Input() visualizedRemoveButton = true;
    @Input() visualizedPreviewButton = true;
    @Input() visualizedRenameButton = true;
    @Input() visualizedDownloadButton = true;
    @Input() visualizedMapdButton = true;
    @Input() visualizedSelectButton = false;
    @Input() selected
    @Input() disabled
    @Input() loading = false;
    @Input() size
    @Input() name
    @Input() typeFile: { type: string, symbol: string };
    @Input() updatedAt;
    @Input() parentFolderName;

    @Output() clickCard = new Subject();
    @Output() dbClickCard = new Subject();
    @Output() clickMenu = new Subject<emitMenu>();

    private timerSingleClick;
    private preventSingleClick = false;
    public notPreviewType = ['zip'];
    public notMoveType = [];
    public notDownloadType = ['folder'];

    constructor() {
    }

    ngOnInit(): void {
    }


    get disableButton() {
        return this.disabled;
    }

    get badgeColor() {
        return this.selected ? 'menu-select-card' : 'menu-accent-card';
    }

    get badgeSize() {
        const numberOfCharacter = this.size.toString().length;
        if (numberOfCharacter < 3) {
            return 'badge-size-medium'
        } else if (numberOfCharacter < 4) {
            return 'badge-size-large'
        } else {
            return 'badge-size-Xlarge'
        }
    }

    get selectDisable() {
        if (this.disabled) {
            return 'card-disabled'
        } else if (this.selected) {
            return 'menu-select-card';
        } else {
            return ''
        }
    }


    singleCardClick() {
        if (!this.disabled) {
            this.preventSingleClick = false;
            this.timerSingleClick = setTimeout(() => {
                if (!this.preventSingleClick) {
                    this.clickCard.next(true)
                    //Navigate on single click
                }
            }, 200)
        }
    }

    dbCardClick() {
        if (!this.disabled) {
            if (isNotNullOrUndefined(this.timerSingleClick)) {
                this.preventSingleClick = true;
                clearTimeout(this.timerSingleClick);
            }
            this.dbClickCard.next(['asdsaf', 'fddsadf'])
        }
    }

    rename() {
        if (!this.disabled) {
            this.clickMenu.next('rename')
        }
    }

    remove() {
        if (!this.disabled) {
            this.clickMenu.next('remove')
        }
    }

    move() {
        if (!this.disabled) {
            this.clickMenu.next('move')
        }
    }

    preview() {
        if (!this.disabled) {
            this.clickMenu.next('preview')
        }
    }

    downolad() {
        if (!this.disabled) {
            this.clickMenu.next('download')
        }
    }

    goToMap() {
        if (!this.disabled) {
            this.clickMenu.next('map')
        }
    }

    select() {
        if (!this.disabled) {
            this.clickMenu.next('select')
        }
    }


    getTraductionFromDate(updatedAt: Date): string {
        let traduction = 'fileManager.';
        if (!isNotNullOrUndefined(updatedAt)) {
            return null;
        } else {
            const now = new Date();
            const diff = now.getTime() - updatedAt.getTime();
            const oneDay = 1000 * 60 * 60 * 24;
            const day = Math.floor(diff / oneDay);
            const isCurrentYear = now.getFullYear() - updatedAt.getFullYear() == 0;
            const isLastMonth = now.getMonth() - updatedAt.getMonth() == 1 && isCurrentYear;
            const isCurrentMonth = !Boolean(now.getMonth() - updatedAt.getMonth());
            if (!isCurrentYear) {
                traduction += 'plusLastYear';
            } else if (!isCurrentMonth && !isLastMonth) {
                traduction += 'currentYear';
            } else if (isLastMonth) {
                traduction += 'lastMonth';
            } else if (isCurrentMonth && day > 7) {
                traduction += 'currentMonth';
            } else if (day <= 7 && day > 1) {
                traduction += 'currentWeek';
            } else if (day <= 1 && day > 0) {
                traduction += 'yesterday';
            } else if (day <= 0) {
                traduction += 'today';
            }
        }
        return traduction;
    }

}

