<div fxLayout="column" [style.height]="'100%'" fxLayoutAlign="left left" class="cmp" [ngClass]="[getSizeCss()]">
    <div *ngIf="logo" class="logo">
        <a routerLink="/auth/login"><img src="assets/logo.png" width="150px"></a>
    </div>
    <mat-card>
        <mat-card-title class="flex-center-Between" style="display: flex">
            {{title}}
            <ng-content select="[titleSx]">

            </ng-content>
        </mat-card-title>
        <mat-card-subtitle>
            {{subtitle}}
        </mat-card-subtitle>
        <mat-card-content>
            <ng-content></ng-content>
        </mat-card-content>
    </mat-card>
    <ng-container *ngIf="informativa">
        <div fxLayout="column" fxLayoutAlign="space-around center" class="text-center disclaimer">
            <div fxFlex>
                {{'consult' | translate}}&nbsp;<a href="https://huna.io/privacy-policy" target="_blank">
                {{ 'privacy' | translate}}</a>&nbsp;{{'and' | translate}}&nbsp;
                <a href="https://huna.io/termini-e-condizioni" target="_blank">{{'conditions' | translate}}</a>
            </div>
            <div fxFlex>&nbsp;</div>
            <div fxFlex>
                <a href="https://huna.io" target="_blank">{{'huna' | translate}}</a>
            </div>
        </div>
    </ng-container>
</div>
