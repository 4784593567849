import * as Parse from 'parse';
import {allPropertyClass, className} from "./Models";

export class OrariAccensioneCircuitiParse extends Parse.Object {
    public static CLASS_NAME = className.orariAccensioneCircuiti;


    constructor() {
        super(OrariAccensioneCircuitiParse.CLASS_NAME);
    }

    public get objectId() {
        return this.id;
    }

    public set objectId(value) {
        this.id = value;
    }

    public set ACL(value) {
        super.set('ACL', value);
    }

    public get ACL() {
        return super.get('ACL');
    }

    public set createdAt(value) {
        super.set('createdAt', value);
    }

    public get createdAt() {
        return super.get('createdAt');
    }

    public set updatedAt(value) {
        super.set('updatedAt', value);
    }

    public get updatedAt() {
        return super.get('updatedAt');
    }

    public set circuito(value) {
        super.set('circuito', value);
    }

    public get circuito(): Parse.Object<any> {
        return super.get('circuito');
    }

    public set giornoSettimana(value) {
        super.set('giornoSettimana', value);
    }

    public get giornoSettimana(): number {
        return super.get('giornoSettimana');
    }

    public set oraSpegnimentoLinea1(value) {
        super.set('oraSpegnimentoLinea1', value);
    }

    public get oraSpegnimentoLinea1(): number {
        return super.get('oraSpegnimentoLinea1');
    }

    public set oraSpegnimentoLinea2(value) {
        super.set('oraSpegnimentoLinea2', value);
    }

    public get oraSpegnimentoLinea2(): number {
        return super.get('oraSpegnimentoLinea2');
    }

    public set oraSpegnimentoLinea3(value) {
        super.set('oraSpegnimentoLinea3', value);
    }

    public get oraSpegnimentoLinea3(): number {
        return super.get('oraSpegnimentoLinea3');
    }

    public set oraSpegnimentoLinea4(value) {
        super.set('oraSpegnimentoLinea4', value);
    }

    public get oraSpegnimentoLinea4(): number {
        return super.get('oraSpegnimentoLinea4');
    }

    public set oraAccensioneLinea1(value) {
        super.set('oraAccensioneLinea1', value);
    }

    public get oraAccensioneLinea1(): number {
        return super.get('oraAccensioneLinea1');
    }

    public set oraAccensioneLinea2(value) {
        super.set('oraAccensioneLinea2', value);
    }

    public get oraAccensioneLinea2(): number {
        return super.get('oraAccensioneLinea2');
    }

    public set oraAccensioneLinea3(value) {
        super.set('oraAccensioneLinea3', value);
    }

    public get oraAccensioneLinea3(): number {
        return super.get('oraAccensioneLinea3');
    }

    public set oraAccensioneLinea4(value) {
        super.set('oraAccensioneLinea4', value);
    }

    public get oraAccensioneLinea4(): number {
        return super.get('oraAccensioneLinea4');
    }

    public set ricevuto(value) {
        super.set('ricevuto', value);
    }

    public get ricevuto(): boolean {
        return super.get('ricevuto');
    }

    public get query(): Parse.Query<OrariAccensioneCircuitiParse> {
        return new Parse.Query(OrariAccensioneCircuitiParse)
    }

    public allProperty() {
        return allPropertyClass(OrariAccensioneCircuitiParse)
    }

    public allPropertyOnParse() {
        const notInParse = ['constructor', 'query',];
        return this.allProperty()
            .filter(key => !notInParse.includes(key))
    }
}

Parse.Object.registerSubclass(OrariAccensioneCircuitiParse.CLASS_NAME, OrariAccensioneCircuitiParse);
