import * as Parse from 'parse';
import {fetchParseObject$, paramsApiParse_v2} from "./Models";
import {fromPromise} from "rxjs/internal-compatibility";
import {Observable} from "rxjs";

export class ProgettiParse extends Parse.Object {
    public static CLASS_NAME = 'Progetti';

    constructor() {
        super(ProgettiParse.CLASS_NAME);
    }

    public set objectId(value) {
        this.id = value;
    }

    public get objectId() {
        return this.id;
    }

    public set ACL(value) {
        super.set('ACL', value);
    }

    public get ACL() {
        return super.get('ACL');
    }

    public set createdAt(value) {
        super.set('createdAt', value);
    }

    public get createdAt() {
        return super.get('createdAt');
    }

    public set costoMedioEnergia(value) {
        super.set('costoMedioEnergia', value);
    }

    public get costoMedioEnergia() {
        return super.get('costoMedioEnergia');
    }

    public get comune() {
        return super.get('comune');
    }

    public set comune(value) {
        super.set('comune', value);
    }

    public set gestori(value) {
        super.set('gestori', value);
    }

    public get gestori() {
        return super.get('gestori');
    }

    public set gestoriMax(value) {
        super.set('gestoriMax', value);
    }

    public get gestoriMax() {
        return super.get('gestoriMax');
    }

    public get gestioneAttivo() {
        return super.get('gestioneAttivo');
    }

    public set gestioneAttivo(value) {
        super.set('gestioneAttivo', value);
    }


    public set htmlLogo(value) {
        super.set('htmlLogo', value);
    }

    public get htmlLogo(): Parse.File {
        return super.get('htmlLogo');
    }

    public set image(value) {
        super.set('image', value);
    }

    public get image() {
        return super.get('image');
    }

    public set installatori(value) {
        super.set('installatori', value);
    }

    public get installatori() {
        return super.get('installatori');
    }

    public set installatoriMax(value) {
        super.set('installatoriMax', value);
    }

    public get installatoriMax() {
        return super.get('installatoriMax');
    }

    public set illuminamentoAttivo(value) {
        super.set('illuminamentoAttivo', value);
    }

    public get illuminamentoAttivo() {
        return super.get('illuminamentoAttivo');
    }

    public set location(value) {
        super.set('location', value);
    }

    public get location() {
        return super.get('location');
    }

    public set locale(value) {
        super.set('locale', value);
    }

    public get locale() {
        return super.get('locale');
    }

    public set lotto(value) {
        super.set('lotto', value);
    }

    public get lotto() {
        return super.get('lotto');
    }

    public set maxRegionDistance(value) {
        super.set('maxRegionDistance', value);
    }

    public get maxRegionDistance() {
        return super.get('maxRegionDistance');
    }

    public set name(value) {
        super.set('name', value);
    }

    public get name() {
        return super.get('name');
    }

    public set nomeGestore(value) {
        super.set('nomeGestore', value);
    }

    public get nomeGestore() {
        return super.get('nomeGestore');
    }

    public set number(value) {
        super.set('number', value);
    }

    public get number() {
        return super.get('number');
    }

    public set numeroCircuiti(value) {
        super.set('numeroCircuiti', value);
    }

    public get numeroCircuiti() {
        return super.get('numeroCircuiti');
    }

    public set numeroLampade(value) {
        super.set('numeroLampade', value);
    }

    public get numeroLampade() {
        return super.get('numeroLampade');
    }

    public set numeroPuntiLuce(value) {
        super.set('numeroPuntiLuce', value);
    }

    public get numeroPuntiLuce() {
        return super.get('numeroPuntiLuce');
    }

    public set operatori(value) {
        super.set('operatori', value);
    }

    public get operatori() {
        return super.get('operatori');
    }

    public set operatoriMax(value) {
        super.set('operatoriMax', value);
    }

    public get operatoriMax() {
        return super.get('operatoriMax');
    }

    public set organizzazione(value) {
        super.set('organizzazione', value);
    }

    public get organizzazione() {
        return super.get('organizzazione');
    }

    public set progettazioneAttivo(value) {
        super.set('progettazioneAttivo', value);
    }

    public get progettazioneAttivo() {
        return super.get('progettazioneAttivo');
    }

    public set regionRadius(value) {
        super.set('regionRadius', value);
    }

    public get regionRadius() {
        return super.get('regionRadius');
    }

    public set responsabileComune(value) {
        super.set('responsabileComune', value);
    }

    public get responsabileComune() {
        return super.get('responsabileComune');
    }

    public get sigla() {
        return super.get('sigla');
    }

    public set sigla(value) {
        super.set('sigla', value);
    }

    public get subscriptionExpirationDate() {
        return super.get('subscriptionExpirationDate');
    }

    public set subscriptionExpirationDate(value) {
        super.set('subscriptionExpirationDate', value);
    }


    public set telecontrolloAttivo(value) {
        super.set('telecontrolloAttivo', value);
    }

    public get telecontrolloAttivo() {
        return super.get('telecontrolloAttivo');
    }

    public set timeZoneName(value) {
        super.set('timeZoneName', value);
    }

    public get timeZoneName() {
        return super.get('timeZoneName');
    }

    public set updatedAt(value) {
        super.set('updatedAt', value);
    }

    public get updatedAt() {
        return super.get('updatedAt');
    }

    public set utenti(value) {
        super.set('utenti', value);
    }

    public get utenti() {
        return super.get('utenti');
    }

    public set utentiMax(value) {
        super.set('utentiMax', value);
    }

    public get utentiMax() {
        return super.get('utentiMax');
    }

    public get progettoPubblico() {
        return super.get('progettoPubblico');
    }

    public set firstViewOnOpening(value) {
        super.set('firstViewOnOpening', value);
    }

    public get firstViewOnOpening() {
        return super.get('firstViewOnOpening');
    }

    public fetch$(): Observable<ProgettiParse> {
        return fetchParseObject$(ProgettiParse.CLASS_NAME, this.objectId);
    }
}

Parse.Object.registerSubclass(ProgettiParse.CLASS_NAME, ProgettiParse);
