<ng-container [formGroup]="form">
    <ng-container *ngIf="showHeader">
        <ng-content select="[header]">

        </ng-content>
    </ng-container>
    <div class="width-range-container flex-flow-row" >
        <div [ngClass]="[type!==typeFormValues.DATE?'width-range-component':'width-range-component-date']" >
            <app-get-form-fiel-by-configuration-element
                    [type]="type"
                    [titleTraduction]="traductionMin"
                    formControlName="min"
                    [appearance]="appearance"
            >
            </app-get-form-fiel-by-configuration-element>
        </div>
        <div [ngClass]="[type!==typeFormValues.DATE?'width-range-component':'width-range-component-date']">
            <app-get-form-fiel-by-configuration-element
                    [type]="type"
                    [titleTraduction]="traductionMax"
                    formControlName="max"
                    [appearance]="appearance"
            >
            </app-get-form-fiel-by-configuration-element>
        </div>
    </div>
</ng-container>
