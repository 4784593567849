import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';

@Component({
    selector: 'app-info-dialog',
    templateUrl: './info-dialog.component.html'
})
export class InfoDialogComponent implements OnInit {


    public puntiLuce;
    public circuiti;
    public potenza = 0;
    public lampade = 0;

    constructor(
        public dialogRef: MatDialogRef<InfoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { planObjectId: string }
    ) {

    }

    ngOnInit() {
        this.lampade = 0;
        this.potenza = 0;
        this.circuiti = 0;
        this.puntiLuce = 0;

        this.puntiLuce = (this.data["puntiLuce"]) ? this.data["puntiLuce"].length : 0;
        this.circuiti = (this.data["circuiti"]) ? this.data["circuiti"].length : 0;
        if (this.data["puntiLuce"]) {
            this.data["puntiLuce"].forEach((value) => {
                if (value.numeroLampade) {
                    if (typeof value.numeroLampade === 'number' && !isNaN(value.numeroLampade)) {
                        this.lampade += value.numeroLampade;
                    }
                    const potenza = value.numeroLampade * value.potenzaNominale;
                    if (typeof potenza === 'number' && !isNaN(potenza)) {
                        this.potenza += potenza;
                    }
                }
            });
            this.potenza = Math.round(this.potenza) / 1000;
        }
    }


}
