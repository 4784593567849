import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { APP_DATE_FORMATS } from 'src/app/override/app-date-adapter';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS,
    }
  ]
})
export class DatepickerComponent implements OnInit, OnDestroy, OnChanges {

  @Input() appearance: MatFormFieldAppearance = 'fill';
  @Input() label = '';
  @Input() minDate: Date | undefined;
  @Input() maxDate: Date | undefined;
  @Input() value: Date | number | null | undefined;
  @Input() disabled: boolean | undefined;
  @Output() dateChange = new EventEmitter<Date | null>();
  @ViewChild('picker') datepicker: MatDatepicker<Date> | undefined;

  isSmallDevice = window.innerWidth < 600;
  
  form: UntypedFormGroup;
  private subscriptions: Subscription[] = [];
  constructor(private fb: UntypedFormBuilder, private translateService: TranslateService, private adapter: DateAdapter<Date>) {
    this.form = this.fb.group({ date: null });
    this.adapter.setLocale(this.translateService.currentLang);
    this.subscriptions.push(
      fromEvent(window, 'resize').pipe(debounceTime(100)).subscribe(() => {
        this.isSmallDevice = window.innerWidth < 600;
      }),
      this.translateService.onLangChange.subscribe((event) => {
        this.adapter.setLocale(event.lang);
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value && this.form) {
      let newValue = null;
      if (this.value !== null && this.value !== undefined) {
        newValue = new Date(this.value);
      }
      this.form.get('date').setValue(newValue);
    }
    if (changes.disabled && this.form) {
      if (this.disabled) {
        this.form.get('date').disable()
      } else {
        this.form.get('date').enable();
      }
    }
  }

}
