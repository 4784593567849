import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input, OnChanges, OnDestroy,
    OnInit,
    Output, SimpleChanges, ViewChild
} from '@angular/core';
import {BehaviorSubject, combineLatest, forkJoin, interval, Observable, of, Subject, Subscription} from 'rxjs';
import {CircuitiParse} from '../../../models/Circuiti.Parse';
import {IconService} from '../../../providers/services/icon.service';
import {LabelAndListMarkerTextService} from '../../../providers/services/label-and-list-marker-text.service';
import {PuntiLuceParse} from '../../../models/PuntiLuce.Parse';
import {ReportsService} from '../../../providers/services/reports.service';
import {
    ActionClickMarker,
    DistancesRuler,
    ElectricLinesMap,
    InterdistanceLightPoint,
    positionMarker
} from '../../../providers/services/map.service';
import {
    debounceTime,
    delay,
    timeInterval,
    map,
    switchMap,
    startWith, filter
} from 'rxjs/operators';
import {GoogleServiceService} from '../../../providers/services/google-service.service';
import {LuxDataMapElementService} from '../../../providers/services/lux-data-map-element.service';
import {ArredoUrbanoParse} from '../../../models/ArredoUrbano.Parse';
import {
    arrayIsSet, className, DefaultCenterMap,
    getDifferenceArray,
    isNotNullOrUndefined, KeyStringValue, objectIsSet,
    stringIsSet
} from '../../../models/Models';
import {GeoPointService} from '../../../providers/services/geo-point.service';
import {LineaElettricaParse} from '../../../models/LineaElettrica.Parse';
import {EmulateLongPressService} from '../../../providers/services/emulate-long-press.service';
import {ClusterIconStyle, MapInfoWindow, MapMarker} from "@angular/google-maps";
import {FormBuilder, FormGroup} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";

export type ClusterColorType<T> = { color: string, element: T[] }

export interface ElectricLineDetail {
    strokeColor: string;
    strokeWeight: number;
    icon: any;
}

export type InfoWindowValueType = {
    marker: any,
    traductions: string[],
    values: string[]
}
export type ClusterType = {
    color: string,
    counter: number,
    radius: number,
    center: google.maps.LatLng,
    bounds: google.maps.LatLngBounds,
    numeroPuntiLuce: number,
    numeroLampade: number,
    potenza: number,
    icon: any,
    markerIds: any,
    locations: any,
}

export class MarkerType {
    constructor(pl: PuntiLuceParse | CircuitiParse | ArredoUrbanoParse,
                objectJson: KeyStringValue<any> = undefined,
                ts: TranslateService = undefined
    ) {
        if (objectJson != null) {
            Object.keys(objectJson).forEach(key => {
                if (key in this) {
                    this[key] = objectJson[key];
                }
            })
        }
        if (this.objectId == null) {
            this.objectId = pl.objectId;
        }
        this.object = {
            objectId: this.objectId,
            latitudine: pl.location.latitude,
            longitudine: pl.location.longitude,
            indirizzo: (pl as any).indirizzo,
            nomeClasse: pl.className
        }
        let targhe;
        let traductions;
        let labelMarker;

        if (pl.className === className.puntiLuce) {
            const element: PuntiLuceParse = (pl as PuntiLuceParse);
            targhe = element.getTargaTraductionValue()
            if (targhe) {
                traductions = targhe.traductions
            }
            labelMarker = element.labelMarker;
        } else if (pl.className === className.circuiti) {
            const element = (pl as CircuitiParse)
            targhe = {values: [element.numeroQuadro]}
            traductions = ['square_number'];
            labelMarker = element.labelMarker;
        } else if (pl.className === className.arredoUrbano) {
            const element = (pl as ArredoUrbanoParse)
            const tipologia = element.tipologia;
            const keyTraduction = 'dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.' + tipologia;
            const traduction = ts.instant(keyTraduction)

            if (tipologia == null || keyTraduction != traduction) {
                traductions = [keyTraduction];
            } else {
                traductions = ['dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.arredo generico'];
            }
            targhe = {values: undefined}
        }
        if (this.infoWindow == null && targhe != null) {
            const infoWindow = {values: targhe.values, traductions};
            this.infoWindow = infoWindow;
        }
        if (this.labelMarker == null) {
            this.labelMarker = labelMarker;
        }
        if (this.icon == null) {
            this.icon = pl.icon;
        }
        if (this.position == null) {
            const latLng = new google.maps.LatLng({
                lat: pl.location.latitude,
                lng: pl.location.longitude
            })
            this.position = latLng;
        }

    }

    _objectId: string;
    _position: any;
    _icon: any;
    _labelMarker: any;
    _infoWindow: { traductions: string[], values: string[] };
    _object: any

    get objectId() {
        return this._objectId
    }

    set objectId(value) {
        this._objectId = value;
    }

    get position() {
        return this._position
    }

    set position(value) {
        this._position = value;
    }

    get icon() {
        return this._icon
    }

    set icon(value) {
        this._icon = value;
    }

    get labelMarker() {
        return this._labelMarker
    }

    set labelMarker(value) {
        this._labelMarker = value;
    }

    get infoWindow() {
        return this._infoWindow
    }

    set infoWindow(value) {
        this._infoWindow = value;
    }

    get object() {
        return this._object
    }

    set object(value) {
        this._object = value;
    }
}

@Component({
    selector: 'app-main-map',
    templateUrl: './main-map.component.html',
    styleUrls: ['./main-map.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainMapComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;
    @Input() repositioningElectricLines = false;
    @Input() showMarker = true;
    @Input() lightPoints_v2: PuntiLuceParse[];
    @Input() circuits_v2: CircuitiParse[];
    @Input() _clusterElement: ClusterColorType<PuntiLuceParse>[];
    @Input() maxZoomCluster = 19;
    @Input() minNumberCluster = 20;
    @Output()
    elementoSelezionato = new EventEmitter();
    @Output()
    longPressMarker = new EventEmitter();
    @Output()
    dragPointChangePosition = new EventEmitter();
    @Output()
    informazioniInizioSpostamentoMarker: EventEmitter<positionMarker> = new EventEmitter();
    @Output()
    informazioniFineSpostamentoMarker = new EventEmitter();

    @Output()
    coordinatePointerClick = new Subject();
    @Output()
    coordinatePointerDbClick = new Subject();
    @Output()
    clickPolyLine = new Subject();
    // @Output()
    // mapReference: EventEmitter<AgmMap> = new EventEmitter();
    @Output()
    mapReference: EventEmitter<any> = new EventEmitter();
    @Output()
    drawingManagerValue: EventEmitter<any> = new EventEmitter();
    @Output() loadingElementsInMap: BehaviorSubject<{
        lightPoints?: boolean,
        circuits?: boolean
    } | undefined> = new BehaviorSubject(undefined);
    @Input()
    forceRendering: boolean;
    @Input()
    myPosition: { isEnabled: boolean, lat: number, lng: number, icon };
    private map: google.maps.Map;
    @Input()
    public confiniAmministrativi;
    @Input()
    public clusterCircuits: [{ color: string, element: CircuitiParse[] }];
    @Input()
    public arrediUrbani: ArredoUrbanoParse[] = [];
    @Input() public fitBounds = false;
    @Input()
    public forceUpdateCenter: Subject<boolean>;
    public subscriptions = new Subscription();
    @Input()
    public changeConfiniAmministrativi = false;
    @Input()
    public markerClickable = false;
    @Input()
    openedDrawingManager: boolean;
    @Input()
    markerSpostabile: boolean;
    @Input()
    openedAddPuntiLuceCircuito: boolean;
    @Input()
    openedRuler: boolean;
    @Input()
    openedDrawingElectricLine: boolean;
    @Input()
    openedInterdistances: boolean;
    @Input()
    public interdistances: InterdistanceLightPoint[] = [];
    @Input()
    public distancess: [DistancesRuler[]] = [[]];
    @Input()
    public drawedElectricLines: ElectricLinesMap[] = [];
    isSetDrawedElectricLines = false;
    @Input()
    public electricLines: LineaElettricaParse[] = [];
    isSetElectricLines = false;
    @Input()
    searchAddress;
    @Input()
    boundChangeActive;
    @Output()
    boundChange = new Subject();
    @Input() private locationsToCenterZoom;
    private debouncerBoundChange;
    private subscriptionDebouncerBoundChange: Subscription;

    private searchAddreesLoading = false;

    // public iconPuntiStampa: string = 'assets/marker/Marker_stampa_foto.svg';

    // private infoWindowOpened: AgmInfoWindow[] = new Array(2);
    private infoWindowOpened: any[] = new Array(2);

    private drawingManager;
    private overLayCompleteListener;                        // listener sulla creazione della singola area
    private overlaysDrawingManager = [];

    public polyLineInfoWindow: { objectId, lat, lng, persistent }[] = [];

    dbClick = false;
    mapChangeEmit = new BehaviorSubject<{
        tiles: google.maps.LatLngBounds[],
        zoom: number,
        center: google.maps.LatLng
    } | undefined>(undefined)
    mapChange$ = this.mapChangeEmit.asObservable();
    formGruop: FormGroup;
    infoWindowValue$: Observable<InfoWindowValueType>;
    public isSetIconElectricLines = false;
    private lastCircuitiChangeColor;
    public electricLineDetail: { [k: string]: ElectricLineDetail } = {};
    private lineSubscripiton = this.emulateLongPress.newSubscriptionObject;
    public actionLine = ActionClickMarker;
    public mapTypesIs = google.maps.MapTypeId;
    colorTextCluster
    expandListRulers
    defaultCenterMap = DefaultCenterMap;

    constructor(
        private iconService: IconService,
        private labelAndListMarkerTextService: LabelAndListMarkerTextService,
        private reportsService: ReportsService,
        private googleService: GoogleServiceService,
        private geoPointService: GeoPointService,
        private luxDataMapElementService: LuxDataMapElementService,
        fb: FormBuilder,
        private ts: TranslateService,
        private emulateLongPress: EmulateLongPressService
    ) {
        {
            this.formGruop = fb.group({infoWindowValue: undefined})
            this.infoWindowValue$ = this.formGruop.get('infoWindowValue').valueChanges.pipe(
                startWith(undefined),
                map(() => this.formGruop.get('infoWindowValue').value)
            )
        }
    }

    getElementsInMapWebWorkers(elements: any[], mapChange: {
        tiles: google.maps.LatLngBounds[],
        zoom: number,
        center: google.maps.LatLng
    }) {
        if (typeof Worker !== 'undefined') {
            // Create a new
            const worker = new Worker(new URL('../../../providers/webWorkers/get-element-in-map.worker', import.meta.url));
            if (objectIsSet(mapChange)) {
                const tiles = mapChange.tiles.map(tile => {
                    const northEast = tile.getNorthEast();
                    const southWest = tile.getSouthWest();
                    return {
                        northEast: {latitude: northEast.lat(), longitude: northEast.lng()},
                        southWest: {latitude: southWest.lat(), longitude: southWest.lng()},
                        center: {latitude: tile.getCenter().lat(), longitude: tile.getCenter().lng()}
                    }
                })
                const center = {latitude: mapChange.center.lat(), longitude: mapChange.center.lat()};
                const zoom = mapChange.zoom;
                worker.postMessage({
                    mapChange: {tiles, center, zoom},
                    elements: elements.map(l => {
                        return {
                            objectId: l.objectId,
                            location: {latitude: l.location.latitude, longitude: l.location.longitude},
                            icon: l.icon
                        }
                    })
                });
            }
            worker.onmessage = (data) => {
                if (arrayIsSet(data.data)) {
                    const idsCircuitsInMap = data.data.reduce((prev, current) => {
                        if (arrayIsSet(current.elements)) {
                            prev = prev.concat(current.elements.map(el => el.objectId))
                        }
                        return prev;
                    }, [])
                    const elementsInMap = this.circuitsv2
                        .filter(circuit => idsCircuitsInMap.includes(circuit.objectId))
                        .map(circuito => new MarkerType(circuito))
                    this.markerCircuitsEmit.next({elementsInMap, clustersInMap: undefined})
                } else {
                    this.markerCircuitsEmit.next({elementsInMap: undefined, clustersInMap: undefined})
                }

            }

        }
    }

    startLoadElements(key: 'circuits' | 'lightPoints') {
        const current = this.loadingElementsInMap.value != null ? this.loadingElementsInMap.value : {}
        current[key] = true;
        this.loadingElementsInMap.next(current);
    }

    endLoadElements(key: 'circuits' | 'lightPoints') {
        const current = this.loadingElementsInMap.value != null ? this.loadingElementsInMap.value : {}
        delete current[key];
        this.loadingElementsInMap.next(current);
    }

    calcClustersLightPointsInWebWorkers(lightPoints: PuntiLuceParse[], mapChange: {
        tiles: google.maps.LatLngBounds[],
        zoom: number,
        center: google.maps.LatLng,
    }, forceReload: boolean = false) {
        if (typeof Worker !== 'undefined') {
            // Create a new
            const worker = new Worker(new URL('../../../providers/webWorkers/calculate-clusters.worker', import.meta.url));
            if (objectIsSet(mapChange)) {
                this.startLoadElements('lightPoints')
                const tiles = mapChange.tiles.map(tile => {
                    const northEast = tile.getNorthEast();
                    const southWest = tile.getSouthWest();
                    return {
                        northEast: {latitude: northEast.lat(), longitude: northEast.lng()},
                        southWest: {latitude: southWest.lat(), longitude: southWest.lng()},
                        center: {latitude: tile.getCenter().lat(), longitude: tile.getCenter().lng()}
                    }
                })
                const center = {latitude: mapChange.center.lat(), longitude: mapChange.center.lat()};
                const zoom = mapChange.zoom;
                worker.postMessage({
                    mapChange: {tiles, center, zoom},
                    lightPoints: lightPoints.map(l => {
                        return {
                            objectId: l.objectId,
                            location: {latitude: l.location.latitude, longitude: l.location.longitude},
                            icon: l.icon
                        }
                    })
                });
            }
            worker.onmessage = (a) => {
                if (arrayIsSet(a.data.elementsInMap) || arrayIsSet(a.data.clustersInMap)) {
                    if (arrayIsSet(a.data.elementsInMap)) {
                        const ids = a.data.elementsInMap.map(idLight => idLight.objectId)
                        a.data.elementsInMap =
                            lightPoints
                                .filter(l => ids.includes(l.objectId))
                                .map(l => new MarkerType(l))
                    }
                    if (arrayIsSet(a.data.clustersInMap)) {
                        a.data.clustersInMap = this.googleService.splitClustersWithEqualsCenter(a.data.clustersInMap)
                            .map(cluster => {
                                cluster.icon = this.getImageClusterLightPoint(cluster.numeroPuntiLuce, cluster.color, 0.5)
                                return cluster
                            })
                    }
                    const oldValue = this.markerLightPointsEmit.value
                    const currentValue = a.data;
                    if (oldValue != null && currentValue != null) {
                        let oldIdsMarker = [];
                        if (oldValue.elementsInMap) {
                            oldIdsMarker = oldValue.elementsInMap.map(marker => marker.objectId);
                        }
                        if (arrayIsSet(oldValue.clustersInMap)) {
                            oldIdsMarker = oldValue.clustersInMap.reduce((ids, current) => {
                                if (arrayIsSet(current.markerIds)) {
                                    ids = ids.concat(current.markerIds);
                                }
                                return ids;
                            }, oldIdsMarker)
                        }

                        let currentIdsMarker = [];
                        if (currentValue.elementsInMap) {
                            currentIdsMarker = currentValue.elementsInMap.map(marker => marker.objectId);
                        }
                        if (arrayIsSet(currentValue.clustersInMap)) {
                            currentIdsMarker = currentValue.clustersInMap.reduce((ids, current) => {
                                if (arrayIsSet(current.markerIds)) {
                                    ids = ids.concat(current.markerIds);
                                }
                                return ids;
                            }, currentIdsMarker)
                        }
                        if (arrayIsSet(getDifferenceArray(oldIdsMarker, currentIdsMarker, true))) {
                            this.markerLightPointsEmit.next(currentValue);
                        } else if (forceReload) {
                            this.markerLightPointsEmit.next(currentValue);
                        }
                    } else {
                        this.markerLightPointsEmit.next(currentValue);
                    }
                } else {
                    this.markerLightPointsEmit.next(undefined)
                }
                this.endLoadElements('lightPoints');
            };
        } else {
            // Web workers are not supported in this environment.
            // You should add a fallback so that your program still executes correctly.
        }
    }

    get infoWindowValue(): InfoWindowValueType {
        return this.formGruop.get('infoWindowValue').value
    }

    set infoWindowValue(value: InfoWindowValueType | undefined) {
        if (value == null) {
            this.formGruop.get('infoWindowValue').reset();
        } else {
            this.formGruop.get('infoWindowValue').setValue(value)
        }
    }





    get rulerIcon() {
        return this.iconService.getIconRuler();
    }


    get mapChange() {
        return this.mapChangeEmit.value
    }

    set mapChange(event) {
        this.mapChangeEmit.next(event)
    }


    ngOnInit(): void {
        const subscription = this.forceUpdateCenter.asObservable().subscribe(
            changeCenter => {
                if (changeCenter && this.changeConfiniAmministrativi) {
                    this.zoomAtFirstRendering();
                } else if (changeCenter) {
                    this.zommAllElementInMap();
                }
            }
        );
        this.subscriptions.add(subscription);
        this.subscriptions.add(
            this.infoWindowValue$.subscribe(value => {
                if (value && value.marker) {
                    this.infoWindow.open(value.marker);
                }
            })
        )
        this.subscriptions.add(
            this.mapChange$.pipe(
                debounceTime(300)
            ).subscribe(mapChange => {
                this.calcClustersLightPointsInWebWorkers(this.lightPointsv2, mapChange);
            })
        )
        this.subscriptions.add(
            this.mapChange$.pipe(
                filter(() => this.map != null && this.map.getBounds() != null),
                debounceTime(300),
                map(() => {
                    const tiles = [];
                    tiles.push(this.map.getBounds());
                    return {tiles, zoom: this.map.getZoom(), center: this.map.getCenter()}
                })
            ).subscribe(mapChange => {
                this.getElementsInMapWebWorkers(this.circuitsv2, mapChange);
            })
        )
        // subscription = this.obs.pipe(debounceTime(1000)).subscribe(
        //     center => {
        //         this.visualizedElementInMap = true
        //         console.log(this.visualizedElementInMap)
        //     }
        // )
        // this.subscriptions.push(subscription);
    }

    mapBoundChange() {
        const bounds = this.map.getBounds();
        if (bounds != null) {
            const sudWest = {
                latitude: this.map.getBounds().getSouthWest().lat(),
                longitude: this.map.getBounds().getSouthWest().lng()
            }
            const northEast = {
                latitude: this.map.getBounds().getNorthEast().lat(),
                longitude: this.map.getBounds().getNorthEast().lng()
            }
            const center = this.map.getCenter();
            const zoom = this.map.getZoom();
            const event = {bounds, zoom, center}
            if (this.debouncerBoundChange != null) {
                this.debouncerBoundChange.next(event);
            }

            let tiles: google.maps.LatLngBounds[];

            if (this.map.getZoom() > this.maxZoomCluster && this.map.getBounds() != null) {
                tiles = this.googleService.tilesMap(bounds, 0);
            } else if (this.map.getZoom() > (this.maxZoomCluster - 2) && this.map.getBounds() != null) {
                tiles = this.googleService.tilesMap(bounds, 2);
            } else {
                tiles = this.googleService.tilesMap(bounds, 3);
            }
            this.mapChange = {tiles, zoom, center}
        }
    }

    mapClick(coordinatePlaceId) {
        const coords = {lat: coordinatePlaceId.latLng.lat(), lng: coordinatePlaceId.latLng.lng()};
        const zoom = this.map.getZoom();
        if (this.openedDrawingElectricLine) {
            setTimeout(() => {
                if (!this.dbClick) {
                    this.coordinatePointerClick.next({coords, zoom});
                }
                this.dbClick = false;
            }, 300);
        } else if (this.openedRuler) {
            setTimeout(() => {
                if (!this.dbClick) {
                    this.coordinatePointerClick.next({coords, zoom});
                }
                this.dbClick = false;
            }, 300);
        } else if (this.openedAddPuntiLuceCircuito) {
            this.coordinatePointerClick.next({coords, zoom});
        }
    }

    mapDbClick(coordinatePlaceId) {
        const coords = {lat: coordinatePlaceId.latLng.lat(), lng: coordinatePlaceId.latLng.lng()};
        if (this.openedDrawingElectricLine) {
            this.dbClick = true;
            this.coordinatePointerDbClick.next(coords);
        } else if (this.openedRuler) {
            this.dbClick = true;
            this.coordinatePointerDbClick.next(coords);
        }
    }

    zoomAtFirstRendering() {
        if (arrayIsSet(this.confiniAmministrativi)) {
            this.setCenterZoom(this.confiniAmministrativi);
        } else if (isNotNullOrUndefined(this.clusterCircuits)) {
            this.setCenterZoom(this.clusterCircuits.map(
                cluster => cluster.element
            )[0]);
        } else if (arrayIsSet(this._clusterElement)) {
            const allLightPoint = this._clusterElement.map(
                cluster => cluster.element
            )[0];
            this.setCenterZoom(allLightPoint);
        }
        this.changeConfiniAmministrativi = false;
    }


    zommAllElementInMap() {
        let allLightPoint = this.lightPointsv2;
        allLightPoint = (Array.isArray(allLightPoint)) ? allLightPoint : [];
        let cluster;
        if (Array.isArray(this.clusterCircuits)) {
            cluster = this.clusterCircuits.map(
                cluster => cluster.element
            )[0];
        } else {
            cluster = [];
        }
        const linesLocation = [];
        if (arrayIsSet(this.electricLines)) {
            this.electricLines.forEach(line => {
                if (arrayIsSet(line.linesMap)) {
                    line.linesMap.forEach(point => {
                        if (point.start.latitude != null && point.start.longitude != null) {
                            linesLocation.push({
                                className: 'LinePoint',
                                location: {
                                    latitude: point.start.latitude,
                                    longitude: point.start.longitude
                                }
                            });
                        }
                    });
                }
            });
        }
        const vectorForZoom = (allLightPoint as any)
            .concat(this.arrediUrbani)
            .concat(cluster)
            .concat(linesLocation)
            .filter((element) => element != null);
        if (arrayIsSet(vectorForZoom)) {
            this.setCenterZoom(vectorForZoom);
        } else if (arrayIsSet(this.confiniAmministrativi)) {
            this.setCenterZoom(this.confiniAmministrativi);
        }
    }

    initLongPressLine() {
        if (this.lineSubscripiton.short == null && this.lineSubscripiton.long == null) {
            const {shortPress, longPress, objectId} = this.emulateLongPress.init();
            this.lineSubscripiton.objectId = objectId;
            this.lineSubscripiton.short = shortPress.subscribe(value => {
                if (value != null && value.line != null) {
                    this.clickMarker(value.line, undefined);
                }
            });
            this.lineSubscripiton.long = longPress.subscribe(value => {
                this.longClickMarker(value, undefined);
            });
        }
    }

    destroyLongPressLine() {
        this.emulateLongPress.destroy(this.lineSubscripiton.long, this.lineSubscripiton.short, this.lineSubscripiton.objectId);
        this.lineSubscripiton.short = undefined;
        this.lineSubscripiton.long = undefined;
    }


    // initMarkerOverLine() {
    //     if (this.markerOverSubscripiton.short == null && this.markerOverSubscripiton.long == null) {
    //         const {shortPress, longPress, objectId} = this.emulateLongPress.init();
    //         this.markerOverSubscripiton.objectId = objectId;
    //         this.markerOverSubscripiton.short = shortPress.pipe(
    //             switchMap((value) => {
    //                 if (this.longPresEmitValue) {
    //                     return of(value);
    //                 } else {
    //                     return EMPTY;
    //                 }
    //             })
    //         )
    //             .subscribe(value => {
    //                 console.log('short over');
    //             });
    //         this.markerOverSubscripiton.long = longPress.pipe(
    //             switchMap((value) => {
    //                 if (this.longPresEmitValue) {
    //                     return of(value);
    //                 } else {
    //                     return EMPTY;
    //                 }
    //             })
    //         )
    //             .subscribe(value => {
    //                 this.longPressMarker.next(value);
    //             });
    //     }
    // }
    //
    // destroyMarkerOverLine() {
    //     this.emulateLongPress.destroy(this.markerOverSubscripiton.long, this.markerOverSubscripiton.short, this.markerOverSubscripiton.objectId);
    //     this.markerOverSubscripiton.short = undefined;
    //     this.markerOverSubscripiton.long = undefined;
    // }


    ngOnChanges(changes: SimpleChanges): void {
        this.closeInfoWindowOnClick();
        // if (isNotNullOrUndefined(changes.changeConfiniAmministrativi)) {
        //     this.changeConfiniAmministrativi = true;
        //     this.zoomAtFirstRendering();
        // }
        if (isNotNullOrUndefined(changes.openedDrawingManager)) {
            if (changes.openedDrawingManager.currentValue) {
                this.onCaptureMarker();
            } else {
                this.onDestroyDrawingManager();
            }
        }
        if (isNotNullOrUndefined(changes.openedAddPuntiLuceCircuito) && isNotNullOrUndefined(this.map)) {
            if (changes.openedAddPuntiLuceCircuito.currentValue) {
                this.map.setOptions({draggableCursor: 'url(/assets/icon/dashboard/picket24.svg), auto'});
            } else {
                this.map.setOptions({draggableCursor: ''});
            }
        }
        if (isNotNullOrUndefined(changes.openedRuler) && isNotNullOrUndefined(this.map)) {
            if (changes.openedRuler.currentValue) {
                this.map.setOptions({draggableCursor: 'url(/assets/icon/dashboard/picket24.svg), auto'});
            } else {
                this.map.setOptions({draggableCursor: ''});
            }
        }

        if (isNotNullOrUndefined(changes.openedDrawingElectricLine) && isNotNullOrUndefined(this.map)) {
            if (changes.openedDrawingElectricLine.currentValue) {
                this.map.setOptions({draggableCursor: 'url(/assets/icon/dashboard/picket24.svg), auto'});
            } else {
                this.map.setOptions({draggableCursor: ''});
            }
        }
        if (isNotNullOrUndefined(changes.drawedElectricLines)) {
            this.isSetDrawedElectricLines = arrayIsSet(this.drawedElectricLines);
        }
        if (isNotNullOrUndefined(changes.electricLines)) {
            this.electricLines_v2 = this.electricLines;
            if (arrayIsSet(this.electricLines)) {
                const electricLinesWithlinesMap = this.electricLines.filter(line => arrayIsSet(line.linesMap));

                if (arrayIsSet(electricLinesWithlinesMap)) {
                    this.electricLinesDetailResetDefaultValueLines(electricLinesWithlinesMap)
                        .subscribe(values => {
                            this.initLongPressLine();
                            this.electricLineDetail = values;
                            this.isSetIconElectricLines = true;
                            this.isSetElectricLines = arrayIsSet(electricLinesWithlinesMap);
                        });
                } else {
                    this.destroyLongPressLine();
                }
            } else {
                this.isSetElectricLines = false;
                this.destroyLongPressLine();
            }
        }
        if (isNotNullOrUndefined(changes.boundChangeActive)) {
            if (this.boundChangeActive) {
                if (this.subscriptionDebouncerBoundChange) {
                    this.subscriptionDebouncerBoundChange.unsubscribe();
                }
                this.debouncerBoundChange = new Subject().pipe(
                    debounceTime(700)
                );
                this.subscriptionDebouncerBoundChange = this.debouncerBoundChange.subscribe(spigoli => {
                    const northEast = this.geoPointService.newGeopoint({
                        lat: spigoli.bounds.getNorthEast().lat(),
                        lng: spigoli.bounds.getNorthEast().lng()
                    });
                    const sudWest = this.geoPointService.newGeopoint({
                        lat: spigoli.bounds.getSouthWest().lat(),
                        lng: spigoli.bounds.getSouthWest().lng()
                    });
                    const zoom = spigoli.zoom;
                    this.boundChange.next({northEast, sudWest, zoom});
                });
                const bounds = this.map.getBounds();
                const center = this.map.getCenter();
                const zoom = this.map.getZoom();
                const event = {bounds, zoom, center}
                this.debouncerBoundChange.next(event);
            } else {
                if (this.subscriptionDebouncerBoundChange) {
                    this.subscriptionDebouncerBoundChange.unsubscribe();
                }
            }
        }
        if (changes.locationsToCenterZoom) {
            if (arrayIsSet(this.locationsToCenterZoom)) {
                const zoomElement = this.locationsToCenterZoom.map(location => {
                    return {className: 'line', location};
                });
                this.setCenterZoom(zoomElement);
            }
        }
        // if (changes.repositioningElectricLines) {
        // if (this.repositioningElectricLines) {
        //     this.initMarkerOverLine();
        // } else {
        //     this.destroyMarkerOverLine();
        // }
        // }
        if (isNotNullOrUndefined(changes.searchAddress)
            && isNotNullOrUndefined(changes.searchAddress.currentValue)
            && isNotNullOrUndefined(changes.searchAddress.currentValue.lat)
            && isNotNullOrUndefined(changes.searchAddress.currentValue.lng)
            && !this.searchAddreesLoading) {
            this.searchAddreesLoading = true;
            this.createRemoveMarkerInDissolvence(this.map, {
                lat: changes.searchAddress.currentValue.lat,
                lng: changes.searchAddress.currentValue.lng
            }, 1);
        }
        if (changes.lightPoints_v2) {
            this.lightPointsv2 = this.lightPoints_v2;
            this.calcClustersLightPointsInWebWorkers(this.lightPointsv2, this.mapChange, true);
        }
        if (changes.circuits_v2) {
            this.circuitsv2 = this.circuits_v2;
            if (this.map && this.map.getBounds() != null) {
                const center = this.map.getCenter();
                const zoom = this.map.getZoom();
                const bounds = this.map.getBounds();
                this.getElementsInMapWebWorkers(this.circuitsv2, {tiles: [bounds], zoom, center})
            }
        }
        if (changes.arrediUrbani) {
            this.arredoUrbanov2 = this.arrediUrbani;
        }
        if (changes.distancess) {
            this.rulerDistances = this.distancess;
        }
        if (changes.interdistances) {
            this.interdistances_v2 = this.interdistances;
        }
        if (changes.drawedElectricLines) {
            this.drawedElectricLines_v2 = this.drawedElectricLines;
        }


        // if (Array.isArray(this.distances) && this.distances.length > 0) console.log(this.distances[0],this.distances[0].start.latitude, this.distances[0].start.longitude);
    }

    electricLinesEmit = new BehaviorSubject<LineaElettricaParse[]>([])
    electricLines$: Observable<LineaElettricaParse[]> = this.electricLinesEmit.asObservable();
    drawedElectricLinesEmit = new BehaviorSubject<ElectricLinesMap[]>([])
    drawedElectricLines$: Observable<ElectricLinesMap[]> = this.drawedElectricLinesEmit.asObservable();
    interdistancesEmit = new BehaviorSubject<InterdistanceLightPoint[]>([])
    interdistances$: Observable<InterdistanceLightPoint[]> = this.interdistancesEmit.asObservable();
    markerElectricLines$: Observable<{
        polyLines: { color: string, vertices: google.maps.LatLng[], objectId: string }[],
        markers: any[]
    }> = this.electricLines$.pipe(
        switchMap(electricLines => {
            return of({electricLines})
        }),
        map(({electricLines}) => {
            if (arrayIsSet(electricLines)) {
                const polyLines: {
                    color: string,
                    vertices: google.maps.LatLng[],
                    objectIdsPoint: string[],
                    objectId: string
                }[] = [];
                const markers = []
                electricLines
                    .filter(line => arrayIsSet(line.linesMap))
                    .forEach(line => {
                        const color = line.linesMap[0].color;
                        const lineId = line.objectId;
                        const vertices = []
                        const objectIdsPoint = []
                        line.linesMap.forEach(point => {
                            const pointMap = new google.maps.LatLng(point.start.latitude, point.start.longitude)
                            vertices.push(pointMap)
                            objectIdsPoint.push(point.objectId)
                            const objectId = point.objectId;
                            markers.push({lineId, position: pointMap, color, objectId});
                        })
                        polyLines.push({color, vertices, objectId: lineId, objectIdsPoint});
                    })
                return {polyLines, markers};
            } else {
                return {polyLines: undefined, markers: undefined}
            }
        })
    )

    electricLinesIsSet$: Observable<boolean> = this.electricLines$.pipe(
        map(electricLines => {
            return arrayIsSet(electricLines) && arrayIsSet(electricLines.filter(line => arrayIsSet(line.linesMap)));
        })
    )
    markerDrawedElectricLines$: Observable<{
        polyLine: { color: string, vertices: google.maps.LatLng[] },
        markers: any[]
    }> = this.drawedElectricLines$.pipe(
        map((electricinesDrawed) => {
            if (arrayIsSet(electricinesDrawed)) {
                const color = electricinesDrawed[0].color;
                const polyLines = electricinesDrawed.reduce((prev, current) => {
                    const pointMap = new google.maps.LatLng(current.start.latitude, current.start.longitude)

                    prev.polyLine.vertices.push(pointMap)
                    const objectId = current.objectId
                    const customObjectId = current.customObjectId
                    prev.markers.push({position: pointMap, color, objectId, customObjectId})
                    return prev;
                }, {polyLine: {color, vertices: [] as any}, markers: []})
                return polyLines;
            }
            return {polyLine: undefined, markers: undefined}
        })
    );
    drawedElectricLinesIsSet$: Observable<boolean> = this.drawedElectricLines$.pipe(
        map(line => {
            return arrayIsSet(line)
        })
    );
    markerInterdistances$ = this.interdistances$.pipe(
        map(interdistances => {
            let polyLines: { color: string, vertices: google.maps.LatLng[] }[] = [];
            interdistances.map(point => {
                const start = new google.maps.LatLng(point.start.latitude, point.start.longitude)
                const end = new google.maps.LatLng(point.end.latitude, point.end.longitude)
                polyLines.push(
                    {
                        color: point.color,
                        vertices: [start, end]
                    }
                )
            })
            return polyLines
        })
    )
    lightPointsEmit = new BehaviorSubject<PuntiLuceParse[]>([])
    lightPoints$: Observable<any> = this.lightPointsEmit.asObservable();
    circuitsEmit = new BehaviorSubject<CircuitiParse[]>([])
    circuits$: Observable<any> = this.circuitsEmit.asObservable();
    arredoUrbanoEmit = new BehaviorSubject<ArredoUrbanoParse[]>([])
    arredoUrbano$: Observable<any> = this.arredoUrbanoEmit.asObservable();
    rulerDistancesEmit = new BehaviorSubject<[DistancesRuler[]] | undefined>(undefined)
    rulerDistances$: Observable<[DistancesRuler[]]> = this.rulerDistancesEmit.asObservable();
    rulerDistancesIsSet$: Observable<boolean> = this.rulerDistances$.pipe(
        map(distancess => arrayIsSet(distancess) &&
            arrayIsSet(distancess.filter(distances => arrayIsSet(distances)))
        )
    )
    rulerDistancesTotalLength$: Observable<{ color: string, length: string }[]> = this.rulerDistances$.pipe(
        map((distancess) => {
            if (arrayIsSet(distancess) && arrayIsSet(distancess.filter(distances => arrayIsSet(distances)))) {
                const totalLength: { color: string, length: number }[] = [];
                distancess.forEach(distances => {
                    distances.forEach((distance, index) => {
                        if (index == 0) {
                            const color = distance.color
                            totalLength.push({color, length: 0})
                        }
                        const subLength = distance.polylineInfoWindow && distance.polylineInfoWindow.distance != null ? distance.polylineInfoWindow.distance : 0
                        totalLength[totalLength.length - 1].length += subLength;
                    })
                })

                return totalLength.map(length => {
                    return {length: Math.round(length.length) + ' m', color: length.color}
                })
            } else {
                return undefined
            }
        })
    )
    markerRulerDistances$: Observable<{
        polyLines: { vertices: google.maps.LatLng[], color: string }[],
        markers: { position: google.maps.LatLng }[],
    }> = this.rulerDistances$.pipe(
        map(distancess => {
            if (arrayIsSet(distancess)) {
                let polyLines: { vertices: google.maps.LatLng[], color: string }[] = undefined;
                let markers: { position: google.maps.LatLng, distancePreviusPoint: number }[] = undefined;
                distancess.forEach(distances => {
                    if (arrayIsSet(distances)) {
                        distances.forEach((distance, index) => {
                            const position = new google.maps.LatLng(distance.start.latitude, distance.start.longitude)
                            if (!arrayIsSet(markers)) {
                                markers = [] as any;
                            }
                            const distancePreviusPoint = distance.polylineInfoWindow && distance.polylineInfoWindow.distance != null ? Math.round(distance.polylineInfoWindow.distance) : undefined;
                            markers.push({position, distancePreviusPoint});
                            if (!arrayIsSet(polyLines)) {
                                polyLines = [] as any;
                            }
                            if (index == 0) {
                                const color = distance.color
                                polyLines.unshift({color, vertices: []})
                            }
                            polyLines[0].vertices.push(position)
                        })
                    }
                });
                return {polyLines, markers}
            }
            return undefined;
        })
    );
    markerLightPointsEmit = new BehaviorSubject<any>(undefined);
    markerLightPoints$ = this.markerLightPointsEmit.asObservable();
    // markerLightPoints$: Observable<{
    //     clustersInMap: ClusterType[],
    //     elementsInMap: MarkerType[]
    // }> = combineLatest([
    //     this.lightPoints$,
    //     this.mapChange$
    //         .pipe(debounceTime(300))
    // ]).pipe(
    //     map(([lightPoints, mapChange]) => {
    //         if (!arrayIsSet(lightPoints) || mapChange == null) {
    //             return undefined
    //         } else {
    //             let clustersInMap: ClusterType[] = [];
    //             let elementsInMap: any[] = []
    //             const tiles = mapChange.tiles;
    //             const radius = this.geoPointService.getDistance(tiles[0].getSouthWest(), tiles[0].getNorthEast()) * (1000 / 6)
    //             if (arrayIsSet(tiles)) {
    //                 let tilesWithMarkers = tiles.map(tile => {
    //                     return {tile, markers: []}
    //                 })
    //                 if (arrayIsSet(lightPoints)) {
    //
    //                     lightPoints.forEach(el => {
    //                         const indexTiles = tilesWithMarkers.findIndex(tileMarker => {
    //                             const googlePoint = new google.maps.LatLng(el.location.latitude, el.location.longitude);
    //                             return tileMarker.tile.contains(googlePoint);
    //                         })
    //                         if (indexTiles >= 0) {
    //                             tilesWithMarkers[indexTiles].markers.push(el);
    //                         }
    //                     })
    //
    //                 }
    //
    //
    //                 const markerIds = [];
    //                 tilesWithMarkers.forEach(tile => {
    //                         if (
    //                             arrayIsSet(tile.markers) &&
    //                             tile.markers.length > this.minNumberCluster
    //                             && mapChange.zoom < this.maxZoomCluster
    //                         ) {
    //                             const colors = qualitativeScaleColor
    //                             const numberMarkers = tile.markers.length;
    //                             let color;
    //                             if (numberMarkers < 50) {
    //                                 color = colors[0];
    //                             } else if (numberMarkers < 250) {
    //                                 color = colors[1];
    //                             } else if (numberMarkers < 500) {
    //                                 color = colors[2];
    //                             } else if (numberMarkers < 750) {
    //                                 color = colors[3];
    //                             } else {
    //                                 color = colors[4];
    //                             }
    //                             let numeroLampadeTotale = 0;
    //                             let potenzaTotale = 0;
    //                             const numeroPuntiLuce = tile.markers.length;
    //                             markerIds.push(...tile.markers.map(marker => marker.objectId));
    //                             tile.markers.forEach(marker => {
    //                                 const numeroLampada = (marker as PuntiLuceParse).numeroLampade ? marker.numeroLampade : 1;
    //                                 let potenza = 0
    //                                 if (marker.potenzaNominale != null) {
    //                                     potenza = marker.potenzaNominale
    //                                 } else if (marker.potenzaEffettiva != null) {
    //                                     potenza = marker.potenzaEffettiva
    //                                 }
    //                                 potenzaTotale += potenza * numeroLampada;
    //                                 numeroLampadeTotale += numeroLampada;
    //                             })
    //
    //                             clustersInMap.push({
    //                                 radius,
    //                                 color,
    //                                 counter: tile.markers.length,
    //                                 center: tile.tile.getCenter(),
    //                                 bounds: tile.tile,
    //                                 potenza: Math.round(potenzaTotale),
    //                                 numeroLampade: numeroLampadeTotale,
    //                                 numeroPuntiLuce,
    //                                 icon: this.getImageClusterLightPoint(numeroPuntiLuce, numeroLampadeTotale, Math.round(potenzaTotale))
    //                             });
    //                         } else if (arrayIsSet(tile.markers)) {
    //                             elementsInMap.push(...tile.markers.map(marker => {
    //                                 return new MarkerType(marker)
    //                             }));
    //                         }
    //                     }
    //                 )
    //
    //                 return {clustersInMap, elementsInMap, markerIds, zoom: mapChange.zoom}
    //             } else {
    //
    //                 return {
    //                     clustersInMap: undefined,
    //                     elementsInMap: lightPoints.map(pl => new MarkerType(pl)),
    //                     markerIds: undefined,
    //                     zoom: mapChange.zoom
    //                 }
    //             }
    //         }
    //     }),
    //     pairwise(),
    //     filter(([prev, current]) => {
    //         if (prev != null && current != null) {
    //             const elementsInMapPrev = prev.elementsInMap;
    //             const markerIdsInMapPrev = prev.markerIds;
    //             const zoomIdsInMapPrev = prev.zoom;
    //             const elementsInMapCurr = current.elementsInMap;
    //             const markerIdsInMapCurr = current.markerIds;
    //             const zoomIdsInMapCurr = current.zoom;
    //             const zoomIsDifferent = zoomIdsInMapPrev != zoomIdsInMapCurr
    //             let elementsInMapPrevIds = [];
    //             let elementsInMapCurrIds = [];
    //             if (arrayIsSet(elementsInMapPrev)) {
    //                 elementsInMapPrevIds = elementsInMapPrev.map(el => el.objectId);
    //             }
    //             if (arrayIsSet(elementsInMapCurr)) {
    //                 elementsInMapCurrIds = elementsInMapCurr.map(el => el.objectId);
    //             }
    //             const diffEl = arrayIsSet(getDifferenceArray(elementsInMapPrevIds.concat(markerIdsInMapPrev), elementsInMapCurrIds.concat(markerIdsInMapCurr), true))
    //
    //             // const diffCluster = arrayIsSet(getDifferenceArray(markerIdsInMapPrev, markerIdsInMapCurr, true));
    //             // let clustersInMapPrevIds;
    //             // let clustersInMapCurrIds;
    //             // if (arrayIsSet(clustersInMapPrev)) {
    //             //     clustersInMapPrevIds = clustersInMapPrev.map(cluster => {
    //             //         const center = cluster.center ? cluster.center.toString() : cluster.bounds.getCenter().toString();
    //             //         const counter = cluster.counter
    //             //         return center + counter;
    //             //     });
    //             // }
    //             // if (arrayIsSet(clustersInMapCurr)) {
    //             //     clustersInMapCurrIds = clustersInMapCurr.map(cluster => {
    //             //         const center = cluster.center ? cluster.center.toString() : cluster.bounds.getCenter().toString();
    //             //         const counter = cluster.counter
    //             //         return center + counter;
    //             //     });
    //             // }
    //             // const diffCluster = arrayIsSet(getDifferenceArray(clustersInMapPrevIds, clustersInMapCurrIds))
    //             return diffEl || zoomIsDifferent;
    //         }
    //         return true;
    //     }),
    //     map(([prev, current]) => current)
    // )
    markerCircuitsEmit = new BehaviorSubject<{
        clustersInMap: ClusterType[],
        elementsInMap: MarkerType[]
    }>(undefined);
    markerCircuits$ = this.markerCircuitsEmit.asObservable();

    // markerCircuits$: Observable<{
    //     clustersInMap: ClusterType[],
    //     elementsInMap: MarkerType[]
    // }> = combineLatest([
    //     this.circuits$,
    //     this.mapChange$
    //         .pipe(
    //             filter(() => this.map != null && this.map.getBounds() != null),
    //             debounceTime(300),
    //             map(() => {
    //                 const tiles = [];
    //                 tiles.push(this.map.getBounds());
    //                 return {tiles, zoom: this.map.getZoom(), center: this.map.getCenter()}
    //             })
    //         )
    // ]).pipe(
    //     map(([circuits, mapChange]) => {
    //         if (!arrayIsSet(circuits) || mapChange == null) {
    //             return undefined
    //         } else {
    //             let tiles = mapChange.tiles
    //             if (arrayIsSet(circuits) && arrayIsSet(tiles)) {
    //                 let markersInMap = [];
    //                 circuits.forEach(circuito => {
    //                     const indexTiles = tiles.findIndex(tileMarker => {
    //                         const googlePoint = new google.maps.LatLng(circuito.location.latitude, circuito.location.longitude);
    //                         return tileMarker.contains(googlePoint);
    //                     })
    //                     if (indexTiles >= 0) {
    //                         markersInMap.push(new MarkerType(circuito));
    //                     }
    //                 })
    //                 return {elementsInMap: markersInMap, clustersInMap: undefined}
    //             }
    //         }
    //     })
    // )
    markerArredoUrbano$: Observable<{
        clustersInMap: ClusterType[],
        elementsInMap: MarkerType[]
    }> = combineLatest([
        this.arredoUrbano$,
        this.mapChange$
            .pipe(
                debounceTime(300),
                map(() => {
                    const tiles = [];
                    tiles.push(this.map.getBounds());
                    return {tiles, zoom: this.map.getZoom(), center: this.map.getCenter()}
                })
            )
    ]).pipe(
        map(([arrediUrbani, mapChange]) => {
            if (!arrayIsSet(arrediUrbani) || mapChange == null) {
                return undefined
            } else {
                let tiles = mapChange.tiles
                if (arrayIsSet(arrediUrbani) && arrayIsSet(tiles)) {
                    let markersInMap = [];
                    arrediUrbani.forEach(arredoUrbano => {
                        const indexTiles = tiles.findIndex(tileMarker => {
                            const googlePoint = new google.maps.LatLng(arredoUrbano.location.latitude, arredoUrbano.location.longitude);
                            return tileMarker.contains(googlePoint);
                        })
                        if (indexTiles >= 0) {
                            markersInMap.push(new MarkerType(arredoUrbano, undefined, this.ts));
                        }
                    })
                    return {elementsInMap: markersInMap, clustersInMap: undefined}
                }
            }
        })
    )

    get lightPointsv2() {
        return this.lightPointsEmit.value
    }

    set lightPointsv2(value: PuntiLuceParse[]) {
        this.lightPointsEmit.next(value);
    }

    get circuitsv2() {
        return this.circuitsEmit.value
    }

    set circuitsv2(value: CircuitiParse[]) {
        this.circuitsEmit.next(value);
    }

    get arredoUrbanov2() {
        return this.arredoUrbanoEmit.value
    }

    set arredoUrbanov2(value: ArredoUrbanoParse[]) {
        this.arredoUrbanoEmit.next(value);
    }

    get rulerDistances() {
        return this.rulerDistancesEmit.value
    }

    set rulerDistances(value: [DistancesRuler[]]) {
        this.rulerDistancesEmit.next(value);
    }

    get interdistances_v2() {
        return this.interdistancesEmit.value
    }

    set interdistances_v2(value: any) {
        this.interdistancesEmit.next(value);
    }

    get electricLines_v2() {
        return this.electricLinesEmit.value
    }

    set electricLines_v2(value: LineaElettricaParse[]) {
        this.electricLinesEmit.next(value);
    }

    get drawedElectricLines_v2() {
        return this.drawedElectricLinesEmit.value
    }

    set drawedElectricLines_v2(value: ElectricLinesMap[]) {
        this.drawedElectricLinesEmit.next(value);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
        if (this.subscriptionDebouncerBoundChange) {
            this.subscriptionDebouncerBoundChange.unsubscribe();
        }

        this.emulateLongPress.destroyAll([this.lineSubscripiton]);
    }

    // public clickMarker(elementoCliccato, infoWindow: AgmInfoWindow | undefined) {
    //     this.shortClickMarker(elementoCliccato, infoWindow);
    // }
    public clickMarker(elementoCliccato, infoWindow: any | undefined) {
        this.shortClickMarker(elementoCliccato, infoWindow);
    }


    // ritorna l'icona del color, color deve essere del tipocolor esadecimale #AABBCC

    mapReady(event) {
        this.map = event;
        this.map.setOptions({
            tilt: 45,
            rotateControl: true,
            rotateControlOptions: {
                position: google.maps.ControlPosition.LEFT_BOTTOM
            },
            center: DefaultCenterMap,
            mapTypeId: this.mapTypesIs.SATELLITE,
            fullscreenControl: false,
            minZoom: 7,
        });
        this.mapReference.emit(event);
        this.zoomAtFirstRendering();
        this.luxDataMapElementService.map = event;
    }

    public trackRowsCircuito(index: number, circuito): string {
        return circuito.objectId;
    }

    public trackRowsPuntiLuce(index: number, puntoLuce): string {
        return puntoLuce.objectId;
    }

    public trackRowsCluster(index: number, cluster): string {
        return cluster.color;
    }

    public trackRowsArrediUrbani(index: number, puntoLuce): string {
        return puntoLuce.objectId;
    }

    public trackRowsConfini(index: number, coordinate): string {
        return coordinate.lat + coordinate.lng;
    }


    private openInfoWindowOnClick(infoWindow) {
        this.infoWindowOpened[0] = null;
        if (this.infoWindowOpened[1] != undefined) {
            this.infoWindowOpened[1].close();
        }
        this.infoWindowOpened[1] = infoWindow;
        this.infoWindowOpened[1].open();
    }

    private closeInfoWindowOnClick() {
        if (this.infoWindowOpened[1] != undefined) {
            this.infoWindowOpened[1].close();
            this.infoWindowOpened.splice(1, 1);
        }
    }


    // fissa lo zoom ed il cento in modo tale da contenere l array di marker passato in argomento se vuoto visualizza tutta l italia
    private setCenterZoom(marker: any[]): void {
        const bounds = new google.maps.LatLngBounds();
        if (isNotNullOrUndefined(this.map)) {
            if (!!marker && marker.length > 0) {
                if (marker[0].className != undefined) {
                    marker.forEach(element => {
                        const loc = new google.maps.LatLng(element.location.latitude, element.location.longitude);
                        bounds.extend(loc);
                    });
                    (this.map as any).fitBounds(bounds);
                } else {
                    marker.forEach(confine => {
                        confine.forEach((coordinate) => {
                            const loc = new google.maps.LatLng(coordinate.lat, coordinate.lng);
                            bounds.extend(loc);
                        });
                    });
                    (this.map as any).fitBounds(bounds);
                }
            } else {
                let loc = new google.maps.LatLng(35.866667, 14.45);
                bounds.extend(loc);
                loc = new google.maps.LatLng(47.218333, 12.173333);
                bounds.extend(loc);
                (this.map as any).fitBounds(bounds);
            }
        }
    }


    private getFunctionSecondParam(eventElement) {
        let funct;
        let secondParam;
        switch (eventElement.type) {
            case google.maps.drawing.OverlayType.POLYGON:
                funct = this.checkPointsPolygon;
                secondParam = new google.maps.Polygon(eventElement.overlay);
                break;
            case google.maps.drawing.OverlayType.CIRCLE:
                funct = this.checkPointsCircle;
                secondParam = eventElement.overlay;
                break;
            case google.maps.drawing.OverlayType.RECTANGLE:
                funct = this.checkPointsRectangle;
                secondParam = eventElement.overlay;
                break;
            default:
        }
        return {funct, secondParam};
    }

    onCaptureMarker() {
        const options: google.maps.drawing.DrawingManagerOptions = {
            drawingMode: google.maps.drawing.OverlayType.POLYGON,
            drawingControl: true,
            drawingControlOptions: {
                position: (window.innerWidth > 600) ? google.maps.ControlPosition.BOTTOM_RIGHT : google.maps.ControlPosition.RIGHT_CENTER,
                drawingModes: [google.maps.drawing.OverlayType.POLYGON, google.maps.drawing.OverlayType.CIRCLE, google.maps.drawing.OverlayType.RECTANGLE]
            },
            polygonOptions: {
                editable: true
            },
            rectangleOptions: {
                editable: true
            },
            circleOptions: {
                editable: true
            },
        };
        this.drawingManager = new google.maps.drawing.DrawingManager(options);
        this.drawingManager.setMap(this.map);
        this.overLayCompleteListener = google.maps.event.addListener(this.drawingManager, 'overlaycomplete', (eventElement) => {
                this.overlaysDrawingManager.push(eventElement);
                const functSecondParam = this.getFunctionSecondParam(eventElement);
                this.drawingManagerValue.next({
                    method: 'overlayComplete',
                    value: eventElement,
                    funct: functSecondParam.funct,
                    secondParam: functSecondParam.secondParam
                });
            }
        );
        google.maps.event.addListener(this.drawingManager, 'polygoncomplete', (polygon) => {
            // this.drawingManagerValue.next({method: 'polyLine', value: polygon})
            google.maps.event.addListener(polygon.getPath(), 'set_at', () => {
                let funct;
                let secondParam;
                funct = this.checkPointsPolygon;
                secondParam = polygon;
                this.drawingManagerValue.next({
                    method: 'overlayComplete',
                    value: polygon,
                    funct,
                    secondParam
                });
                // this.drawingManagerValue.next({method: 'polyLine', value: polygon})
            });
        });

        google.maps.event.addListener(this.drawingManager, 'circlecomplete', (circle) => {
            // this.drawingManagerValue.next({method: 'circle', value: circle})
            google.maps.event.addListener(circle, 'radius_changed', () => {
                let funct;
                let secondParam;
                funct = this.checkPointsCircle;
                secondParam = circle;
                this.drawingManagerValue.next({
                    method: 'overlayComplete',
                    value: circle,
                    funct,
                    secondParam
                });
                // this.drawingManagerValue.next({method: 'circle', value: circle})
            });
        });

        google.maps.event.addListener(this.drawingManager, 'rectanglecomplete', (rectangle) => {
            google.maps.event.addListener(rectangle, 'bounds_changed', () => {
                let funct;
                let secondParam;
                funct = this.checkPointsRectangle;
                secondParam = rectangle;
                this.drawingManagerValue.next({
                    method: 'overlayComplete',
                    value: rectangle,
                    funct,
                    secondParam
                });
                // this.drawingManagerValue.next({method: 'rectangle', value: rectangle})
            });
        });
    }


    private getLocationMapped = ({location}) => ({lat: Number(location.latitude), lng: Number(location.longitude)});

    private checkPointsCircle: (element: any, event: any) => boolean = (element, event) => {
        const item = this.getLocationMapped(element);
        const latLng = new google.maps.LatLng(item.lat, item.lng);
        return (
            event.getBounds().contains(latLng) &&
            google.maps.geometry.spherical.computeDistanceBetween(event.getCenter(), latLng) <= event.getRadius()
        );
    }

    private checkPointsPolygon: (item: any, event: any) => boolean = (element, event) => {
        const item = this.getLocationMapped(element);
        const latLng = new google.maps.LatLng(item.lat, item.lng);
        return google.maps.geometry.poly.containsLocation(latLng, event);
    }

    private checkPointsRectangle: (item: any, event: any) => boolean = (element, event) => {
        const item = this.getLocationMapped(element);
        const latLng = new google.maps.LatLng(item.lat, item.lng);
        return event.getBounds().contains(latLng);
    }

    onDestroyDrawingManager() {
        if (isNotNullOrUndefined(this.drawingManager)) {
            this.drawingManager.setMap(null);
            this.drawingManager = undefined;
            this.overLayCompleteListener.remove();
            this.overlaysDrawingManager.forEach(el => {
                if (el.type == 'polygon') {
                    el.overlay.setPath([]);
                }
            });
            this.overlaysDrawingManager.forEach(el => el.overlay.setMap(null));
            this.overlaysDrawingManager = [];
            this.drawingManagerValue.next({method: 'destroy', value: null});
        }
    }

    setOpacity(puntoLuce: PuntiLuceParse) {
        if (this.reportsService.reportsInMap.length > 0 && this.openedDrawingManager) {
            if (this.reportsService.reportsInMap.includes(puntoLuce.objectId)) {
                return .8;
            } else {
                return .2;
            }
        }
    }


    getImageClusterLightPoint(numeroPuntiLuce: number, color: string, opacityCircle = 0.36) {
        return this.iconService.getImageClusterLightPoint(numeroPuntiLuce, color, opacityCircle)
    }

    getPositionInfoWindoInPolyLine(idInfoWindow) {
        const index = this.polyLineInfoWindow.findIndex(lineCoord => lineCoord.objectId == idInfoWindow);
        return (index >= 0) ? {
            lat: this.polyLineInfoWindow[index].lat,
            lng: this.polyLineInfoWindow[index].lng
        } : undefined;
    }

    // cosi non vengono autoeliminati dal mouseOver, gli info window generati dal click sulla linea sono persistenti e vengono eliminati solo dall chiusura della finestra
    removePositionInfoWindoInPolyLine(idInfoWindow, removePersistent = false) {
        const indexInfoWindow = this.polyLineInfoWindow.findIndex(lineCoord => (lineCoord.objectId == idInfoWindow && (lineCoord.persistent == removePersistent || !lineCoord.persistent)));
        if (indexInfoWindow >= 0) {
            this.polyLineInfoWindow.splice(indexInfoWindow, 1);
        }
    }

    clicLine(event, idInfoWindow) {
        this.polyLineInfoWindow.push({
            objectId: idInfoWindow,
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
            persistent: true
        });
    }

    mouseOver(event, idInfoWindow) {
        const indexInfoWindow = this.polyLineInfoWindow.findIndex(lineCoord => lineCoord.objectId == idInfoWindow);
        if (indexInfoWindow < 0) {
            this.polyLineInfoWindow.push({
                objectId: idInfoWindow,
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
                persistent: false
            });
        }
    }


    mouseOut(event, idInfoWindow) {
        this.removePositionInfoWindoInPolyLine(idInfoWindow);
    }

    dragStart(puntoLuce: MarkerType) {
        if (this.markerSpostabile) {
            const object = puntoLuce.object
            this.informazioniInizioSpostamentoMarker.emit(
                object
            );
        }
    }

    dragEnd(puntoLuce, event) {
        if (this.markerSpostabile) {
            const object = puntoLuce.object
            this.informazioniFineSpostamentoMarker.emit(
                {
                    id: object.objectId,
                    latitudine: event.latLng.lat(),
                    longitudine: event.latLng.lng(),
                    nomeClasse: object.nomeClasse
                }
            );
        }
    }

    private locationsIsInTollerance(point1, point2) {
        const distInm = this.geoPointService.getDistance(point1, point2) * 1000;
        return distInm < 0.5;
    }

    private getLatitudeLongitudeWidthMinDistance(event: google.maps.MapMouseEvent): {
        latitude: number,
        longitude: number
    } {
        let latitude = event.latLng.lat();
        let longitude = event.latLng.lng();
        const min = {dist: 1000, latitude, longitude};
        // const lineParseElements = [];
        if (arrayIsSet(this.clusterCircuits)) {
            this.clusterCircuits.forEach(cluster => {
                cluster.element.forEach(element => {
                    const geoPoint = this.geoPointService.newGeopoint({lat: latitude, lng: longitude});
                    const distInm = this.geoPointService.getDistance(element.location, geoPoint) * 1000;
                    if (this.locationsIsInTollerance(geoPoint, element.location)) {
                        if (distInm < min.dist) {
                            min.latitude = element.location.latitude;
                            min.longitude = element.location.longitude;
                        }
                        // lineParseElements.push(element);
                    }
                });
            });
        }
        if (arrayIsSet(this._clusterElement)) {
            this._clusterElement.forEach(cluster => {
                cluster.element.forEach(element => {
                    const geoPoint = this.geoPointService.newGeopoint({
                        lat: event.latLng.lat(),
                        lng: event.latLng.lng()
                    });
                    const distInm = this.geoPointService.getDistance(element.location, geoPoint) * 1000;
                    if (this.locationsIsInTollerance(geoPoint, element.location)) {
                        if (distInm < min.dist) {
                            min.latitude = element.location.latitude;
                            min.longitude = element.location.longitude;
                        }
                        // lineParseElements.push(element);
                    }
                });
            });
        }
        latitude = min.latitude;
        longitude = min.longitude;
        return {latitude, longitude};
    }

    dragEndLine(marker, event: google.maps.MapMouseEvent) {
        const {latitude, longitude} = this.getLatitudeLongitudeWidthMinDistance(event);
        this.informazioniFineSpostamentoMarker.emit(
            {
                line: marker,
                latitudine: latitude,
                longitudine: longitude,
            }
        );
    }

    private createRemoveMarkerInDissolvence(map, coords: { lat, lng }, durataDissolvenzaSecondi) {
        // tslint:disable-next-line:no-unused-expression
        const marker = new google.maps.Marker({
            animation: google.maps.Animation.BOUNCE,
            position: {lat: coords.lat, lng: coords.lng},
            map,
            icon: 'assets/marker/blue-dot.png'
        });
        const bounds = new google.maps.LatLngBounds();
        const loc = new google.maps.LatLng(coords.lat, coords.lng);
        bounds.extend(loc);
        (this.map as any).fitBounds(bounds);
        const durataDissolvenza: number = durataDissolvenzaSecondi * 1000;
        const peridoInMesc = 50;
        const numeroDiStepDellaDissolvenza = Math.round(durataDissolvenza / peridoInMesc);
        const mSecond = interval(peridoInMesc);
        const refMsecond = mSecond.pipe(
            delay(5000),
            timeInterval())
            .subscribe(
                value => {
                    if (value.value == numeroDiStepDellaDissolvenza) {
                        marker.setMap(null);
                        this.searchAddreesLoading = false;
                        refMsecond.unsubscribe();
                    } else {
                        marker.setOpacity(1 - value.value * (1 / numeroDiStepDellaDissolvenza));
                    }
                },
                err => console.log(err),
            );
    }

    getColorDistances(distances: DistancesRuler[]) {
        if (Array.isArray(distances) && distances.length > 0 && isNotNullOrUndefined(distances[0].color)) {
            return distances[0].color;
        } else {
            return '#FFFFFF';
        }
    }

    isStringAndIsSetByKey(item: any, key: string) {
        return isNotNullOrUndefined(item) && isNotNullOrUndefined(item[key]) && stringIsSet(item[key]);
    }

    clickLine(line) {
        this.clickPolyLine.next({line});
    }


    predicteFunctionElectricLines(electricLine: LineaElettricaParse): boolean {
        return arrayIsSet(electricLine.linesMap);
    }


    electricLineDetailResetDefaultValue(line: LineaElettricaParse): Observable<{ [k: string]: ElectricLineDetail }> {
        return this.iconService.getIconsColorPoint([line.linesMap[0].color], 'S').pipe(
            map((icons) => {
                const defaultValue = {strokeColor: line.linesMap[0].color, strokeWeight: 3, icon: icons[0]};
                const detail = {};
                detail[line.objectId] = defaultValue;
                return detail;
            })
        );

    }

    electricLinesDetailResetDefaultValueLines(lines: LineaElettricaParse[]): Observable<{
        [k: string]: ElectricLineDetail
    }> {
        return forkJoin(lines.map(line => this.electricLineDetailResetDefaultValue(line)))
            .pipe(
                map((values) => {
                    let detail = {};
                    values.forEach(value => {
                        detail = {...detail, ...value};
                    });
                    return detail;
                })
            );
    }


    electricLineDetailUpdateDetailByLine(line: LineaElettricaParse, color: string | undefined, size: 'S' | 'M' | 'L' | 'XL' | 'XXL'): Observable<{
        [k: string]: ElectricLineDetail
    }> {
        const colorLine = arrayIsSet(line.linesMap) ? line.linesMap[0].color : undefined;
        const colorPoint = stringIsSet(color) ? color : colorLine;
        let strokeWeight;
        if (size === 'M') {
            strokeWeight = 6;
        } else if (size === 'L') {
            strokeWeight = 9;
        } else if (size === 'XL') {
            strokeWeight = 12;
        } else if (size === 'XXL') {
            strokeWeight = 15;
        } else {
            strokeWeight = 3;
        }
        return this.iconService.getIconsColorPoint([colorPoint], size).pipe(
            map((icons) => {
                const defaultValue = {strokeColor: line.linesMap[0].color, strokeWeight, icon: icons[0]};
                const detail = {};
                detail[line.objectId] = defaultValue;
                return detail;
            })
        );
    }

    electricLineDetailUpdateDetailByLines(lines: LineaElettricaParse[], color: string | undefined, size: 'S' | 'M' | 'L' | 'XL' | 'XXL'): Observable<{
        [k: string]: ElectricLineDetail
    }> {
        return forkJoin(lines.map(line => this.electricLineDetailUpdateDetailByLine(line, color, size)))
            .pipe(
                map((values) => {
                    let detail = {};
                    values.forEach(value => {
                        detail = {...detail, ...value};
                    });
                    return detail;
                })
            );
    }

    selectedElectricLines: LineaElettricaParse;

    lineMouseOver(event, line: LineaElettricaParse) {
        this.selectedElectricLines = line;
        // this.electricLineDetailUpdateDetailByLine(line, undefined, 'L').subscribe(detail => {
        //     this.electricLineDetail[line.objectId] = detail[line.objectId];
        //     this.isSetIconElectricLines = true;
        //     const locationsMap = line.linesMap.map(point => point.start);
        //     const lastCircuitiChangeColor = [];
        //     this.clusterCircuits.forEach(cluster => cluster.element.forEach(circuito => {
        //             const index = locationsMap.findIndex(locationLine => this.locationsIsInTollerance(circuito.location, locationLine));
        //             if (index >= 0) {
        //                 lastCircuitiChangeColor.push(circuito.objectId);
        //                 circuito.icon = this.iconService.iconQuadroChangedSize('S');
        //             }
        //         })
        //     );
        //     if (arrayIsSet(lastCircuitiChangeColor)) {
        //         this.lastCircuitiChangeColor = lastCircuitiChangeColor;
        //     }
        // });
    }


    lineMouseOut(event, line: LineaElettricaParse) {
        this.selectedElectricLines = undefined;
        // this.electricLineDetailResetDefaultValue(line).subscribe(detail => {
        //     this.electricLineDetail[line.objectId] = detail[line.objectId];
        //     this.isSetIconElectricLines = true;
        //     if (arrayIsSet(this.lastCircuitiChangeColor)) {
        //         this.clusterCircuits.forEach(cluster => cluster.element.forEach(circuito => {
        //                 if (this.lastCircuitiChangeColor.includes(circuito.objectId)) {
        //                     circuito.icon = this.iconService.iconQuadroMy();
        //                 }
        //             })
        //         );
        //     }
        // });
    }

    lineMouseDown(event: google.maps.PolyMouseEvent, line: any, action: ActionClickMarker) {
        const electricLine = this.electricLines.find(l => l.objectId == line.objectId)
        let start;
        if (arrayIsSet(line.vertices)) {
            const lastIndex = line.vertices.length - 1
            const indexVertice = line.vertices.findIndex((vertice, index) => {
                if (index + 1 <= lastIndex) {
                    const startP = vertice;
                    const endP = line.vertices[index + 1];
                    const polyLine = new google.maps.Polyline();
                    polyLine.setPath([startP, endP]);
                    const isDentro = google.maps.geometry.poly.isLocationOnEdge(event.latLng, polyLine, 0.0001)
                    return isDentro;
                }
            })
            start = {objectId: line.objectIdsPoint[indexVertice]};
        }
        this.emulateLongPress.onDown(this.lineSubscripiton.objectId, {
            line: electricLine,
            action,
            start,
            newPoint: this.geoPointService.newGeopoint({lat: event.latLng.lat(), lng: event.latLng.lng()})
        });
    }

    lineMouseUp(event) {
        this.emulateLongPress.onUp(this.lineSubscripiton.objectId);
    }


    shortClickMarker(elementoCliccato, infoWindow) {
        if (infoWindow != null) {
            this.openInfoWindowOnClick(infoWindow);
        }
        setTimeout(() => {
            this.elementoSelezionato.emit({element: elementoCliccato, zoom: this.map.getZoom()});
        }, 50);
    }

    longClickMarker(event, infoWindow) {
        if (infoWindow != null) {
            this.openInfoWindowOnClick(infoWindow);
        }
        setTimeout(() => {
            let obj: any = {
                element: event.line,
                event: event.event,
                start: event.start,
                newPoint: event.newPoint,
                action: event.action,
                zoom: this.map.getZoom()
            };
            if (event.event != null && event.event.latLng != null) {
                let geoPoint = this.geoPointService.newGeopoint({
                    lat: event.event.latLng.lat(),
                    lng: event.event.latLng.lng()
                });
                const geoPointStart = this.geoPointService.newGeopoint({
                    lat: event.start.start.latitude,
                    lng: event.start.start.longitude
                });
                const geoPointEnd = this.geoPointService.newGeopoint({
                    lat: event.end.start.latitude,
                    lng: event.end.start.longitude
                });
                const distanceStartEnd = geoPointStart.kilometersTo(geoPointEnd);
                const distanceStart = geoPointStart.kilometersTo(geoPoint);
                const distanceEnd = geoPointEnd.kilometersTo(geoPoint);
                if (distanceStart > distanceStartEnd || distanceEnd > distanceStartEnd) {
                    const mid = this.googleService.midPoint(geoPointStart, geoPointEnd);
                    geoPoint = this.geoPointService.newGeopoint({lat: mid.lat(), lng: mid.lng()});
                }
                obj = {...obj, geoPoint};
            }
            this.longPressMarker.emit(obj);
        }, 50);
    }

    getElectricLinePredicateFunction(linesMaps: ElectricLinesMap[]): {
        start: ElectricLinesMap,
        end: ElectricLinesMap
    }[] {
        const values = [];
        linesMaps.forEach((point, index) => {
            if (index + 1 < linesMaps.length) {
                const polyLine = {
                    start: point,
                    end: linesMaps[index + 1]
                };
                values.push(polyLine);
            }
        });
        return values;
    }


    dragEndElectricLines(event, marker: any) {
        if (this.repositioningElectricLines) {
            const electricLine: LineaElettricaParse = this.electricLines_v2.find(el => el.objectId === marker.lineId);
            const {latitude, longitude} = this.getLatitudeLongitudeWidthMinDistance(event);
            const geoPoint = this.geoPointService.newGeopoint({lat: latitude, lng: longitude});
            this.dragPointChangePosition.emit({point: marker, line: electricLine, location: geoPoint});
        }
    }

    doubleClickPointInLine(event, line) {
        const electricLine = this.electricLines_v2.find(el => el.objectId === line.lineId)
        if (this.repositioningElectricLines) {
            this.longPressMarker.emit({event, line: electricLine, point: line});
        }

    }

    convertClusterElementWithGeoPointToPositionPredicate(clusterColor: ClusterColorType<any>[] | undefined, colorText: (color) => ClusterIconStyle[]): ClusterColorType<any>[] {
        if (arrayIsSet(clusterColor)) {
            return clusterColor
                .filter(color => arrayIsSet(color.element))
                .map(color => {
                    color['icon'] = colorText(color.color);
                    color.element = color.element
                        .map(el => {
                            const latLng = new google.maps.LatLng({
                                lat: el.location.latitude,
                                lng: el.location.longitude
                            })
                            el['position'] = latLng;
                            return el;
                        });
                    return color
                })
        }
        return undefined;
    }


    openInfoWindowPuntoLuce(marker: MapMarker, markerClicked: MarkerType) {
        console.log(markerClicked)
        const values = markerClicked.infoWindow.values
        const traductions = markerClicked.infoWindow.traductions
        this.infoWindowValue = {traductions, values, marker};
        const puntoLuce = this.lightPoints_v2.find(pl => pl.objectId === markerClicked.object.objectId)
        this.clickMarker(puntoLuce, undefined)
    }

    openInfoWindowCircuito(marker: MapMarker, markerClicked: MarkerType) {
        const values = markerClicked.infoWindow.values
        const traductions = markerClicked.infoWindow.traductions
        this.infoWindowValue = {traductions, values, marker};
        const circuito = this.circuits_v2.find(pl => pl.objectId === markerClicked.object.objectId)
        this.clickMarker(circuito, undefined)
    }

    openInfoWindowArredoUrbano(marker: MapMarker, markerClicked: MarkerType) {
        const traductions = markerClicked.infoWindow.traductions
        this.infoWindowValue = {traductions, values: undefined, marker};
        const arredo = this.arredoUrbanov2.find(arredo => arredo.objectId === markerClicked.object.objectId)
        this.clickMarker(arredo, undefined)
    }

    closeInfoWindow() {
        this.infoWindowValue = undefined;
    }

    clusterClick(cluster: ClusterType) {
        const center = cluster.center ? cluster.center : cluster.bounds.getCenter();
        const zoom = this.map.getZoom() < 15 ? this.map.getZoom() + 2 : this.map.getZoom() + 1
        this.map.moveCamera({center, zoom})
    }

    clickMarkerRuler(marker, mapMarker: MapMarker) {
        if (marker.distancePreviusPoint != null) {
            this.infoWindowValue = {
                traductions: undefined,
                values: [marker.distancePreviusPoint + ' m'],
                marker: mapMarker
            };
        }

    }

    clickExpandRulerList() {
        this.expandListRulers = !this.expandListRulers;
    }

    getOptionElectricLines(line: any, selectedElectricLines: LineaElettricaParse) {
        if (selectedElectricLines != null && line.objectId === selectedElectricLines.objectId) {
            return {strokeColor: line.color, strokeWeight: 5.5}
        } else {
            return {strokeColor: line.color, strokeWeight: 3}
        }
    }

    getIconMarkerRuler$ = (colorPoint, size) => this.iconService.getIconsColorPoint([colorPoint], size).pipe(
        map(icons => icons[0])
    )

    getOptionMarkerElectricLines(markerLine: any, selectedElectricLines: LineaElettricaParse, getIconMarkerRuler$): Observable<any> {
        if (selectedElectricLines != null && markerLine.lineId === selectedElectricLines.objectId) {
            return getIconMarkerRuler$(markerLine.color, 'M')
        } else {
            return getIconMarkerRuler$(markerLine.color, 'S')
        }
    }

    getOptionMarkerDrawedElectricLines(markerLine: any, getIconMarkerRuler$): Observable<any> {
        return getIconMarkerRuler$(markerLine.color, 'M')
    }

    clickMarkerElectricLine(line) {
        const electricLine = this.electricLines_v2.find(el => el.objectId === line.lineId);
        console.log(line);
        // this.clickMarker(electricLine, undefined)
    }

    getLabelMarker(puntoLuce: any, labels: KeyStringValue<google.maps.MarkerLabel>): google.maps.MarkerLabel {
        if (puntoLuce.objectId in labels) {
            return labels[puntoLuce.objectId]
        } else {
            return null
        }
    }

    protected readonly DefaultCenterMap = DefaultCenterMap;
}
