import * as Parse from 'parse';
import {arrayIsSet} from "./Models";
import {of} from "rxjs";
import {fromPromise} from "rxjs/internal-compatibility";
import {map} from "rxjs/operators";


export class GruppoFotometrieParse extends Parse.Object {
    public static CLASS_NAME = 'GruppoFotometrie';

    constructor() {
        super(GruppoFotometrieParse.CLASS_NAME);
    }

    private _fotometrieScaricate;

    public get fotometrieScaricate$() {
        if (arrayIsSet(this.fotometrieScaricate) || this.fotometrie == null) {
            return of(this.fotometrieScaricate)
        } else {
            const query = this.fotometrie.query();
            query.limit(500);
            return fromPromise(query.find()).pipe(
                map(fotometrie => this.fotometrieScaricate = fotometrie)
            )
        }
    }

    public get fotometrieScaricate() {
        return this._fotometrieScaricate;
    }

    public set fotometrieScaricate(value) {
        this._fotometrieScaricate = value;
    }

    public get query() {
        return new Parse.Query(GruppoFotometrieParse)
    }

    public set ACL(value) {
        super.set('ACL', value);
    }

    public get ACL() {
        return super.get('ACL');
    }

    public set objectId(value) {
        this.id = value;
    }

    public get objectId() {
        return this.id;
    }

    public set createdAt(value) {
        super.set('createdAt', value);
    }

    public get createdAt() {
        return super.get('createdAt');
    }

    public set updatedAt(value) {
        super.set('updatedAt', value);
    }

    public get updatedAt() {
        return super.get('updatedAt');
    }

    public set name(value) {
        super.set('name', value);
    }

    public get name() {
        return super.get('name');
    }

    public set fotometrie(value) {
        super.set('fotometrie', value);
    }

    public get fotometrie() {
        return super.get('fotometrie');
    }

    public set fotometrieCounter(value) {
        super.set('fotometrieCounter', value);
    }

    public get fotometrieCounter() {
        return super.get('fotometrieCounter');
    }

    public set progetto(value) {
        super.set('progetto', value);
    }

    public get progetto() {
        return super.get('progetto');
    }


}

Parse.Object.registerSubclass(GruppoFotometrieParse.CLASS_NAME, GruppoFotometrieParse);
