<mat-card class="card-container">
    <mat-card-header *ngIf="data&&data.title">
        <mat-card-title>{{data.title}}</mat-card-title>
    </mat-card-header>
    <form [formGroup]="formElements" [hidden]="!formElements">
        <table mat-table [dataSource]="dataSource" matSort class="huna-table">
            <ng-container *ngFor="let column of displayedColumns">
                <ng-container *ngIf="multipleValues;else singleValue">
                    <ng-container [matColumnDef]="column">
                        <ng-container *ngIf="column!='checkBox';else checkBox">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{column}}</th>
                            <td mat-cell *matCellDef="let element"> {{getValue(element, column)}} </td>
                        </ng-container>
                        <ng-template #checkBox>
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                <mat-checkbox (change)="globalCheckBoxChange($event.checked)"
                                              *ngIf="multipleValues"></mat-checkbox>
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <mat-checkbox [formControlName]="element.id"></mat-checkbox>
                            </td>
                        </ng-template>
                    </ng-container>
                </ng-container>
                <ng-template #singleValue>
                    <mat-radio-group formControlName="objectId">
                        <ng-container [matColumnDef]="column">
                            <ng-container *ngIf="column!='checkBox';else checkBox">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{column}}</th>
                                <td mat-cell *matCellDef="let element"> {{getValue(element, column)}} </td>
                            </ng-container>
                            <ng-template #checkBox>
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    <!--                                <mat-checkbox (change)="globalCheckBoxChange($event.checked)"-->
                                    <!--                                              *ngIf="multipleValues"></mat-checkbox>-->
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-radio-button [value]="element.id"></mat-radio-button>
                                </td>
                            </ng-template>
                        </ng-container>
                    </mat-radio-group>
                </ng-template>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </form>

    <mat-card-actions>
        <div class="flex-around-center" style="width: 180px;">
            <button (click)="closeDialog()"
                    mat-raised-button color="primary">
                <mat-icon>clear</mat-icon>
            </button>
            <button *ngIf="visualizedCheckButton"
                    (click)="getActivedElements()"
                    mat-raised-button color="accent">
                <mat-icon>check</mat-icon>
            </button>
        </div>
    </mat-card-actions>
    <!--    <div style="position: absolute;bottom: 2px;left: 0;z-index: 10000 ;" class="flex-end-center w100">-->
    <!--       -->
    <!--    </div>-->
</mat-card>