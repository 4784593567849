import {Directive, Output, EventEmitter, OnDestroy, HostListener, Input} from '@angular/core';
import {merge, of, Subject, Subscription, timer} from 'rxjs';
import {map, switchMap, takeUntil} from 'rxjs/operators';

@Directive({
    selector: '[appLongPress]'
})
export class LongPressDirective implements OnDestroy {
    private eventSubscribe: Subscription;
    private mousedown = new Subject();
    private mouseup = new Subject();
    private touchstart = new Subject();
    private touchend = new Subject();
    private stopInterval$ = new Subject();
    private longPressEmitted = false;
    @Input() threshold = 800;
    @Input() disabledEmit = false;
    @Input() emitOnceForClick = false;
    @Output() longPress = new EventEmitter();
    @Output() shortPress = new EventEmitter();

    @HostListener('mousedown', ['$event'])
    onMouseDown($event) {
        this.longPressEmitted = false;
        this.mousedown.next(true);
    }

    @HostListener('mouseup', ['$event'])
    onMouseUp($event) {
        this.mouseup.next(false);
        this.stopInterval$.next();
    }

    @HostListener('touchstart', ['$event'])
    onTouchStart($event) {
        this.touchstart.next(true);
    }

    @HostListener('touchend', ['$event'])
    onTouchEnd($event) {
        this.touchend.next(false);
        this.stopInterval$.next();
    }


    emitShort() {
        if (!this.disabledEmit) {
            setTimeout(() => {
                this.shortPress.emit();
            }, 100);
        }
    }

    emitLong() {
        if (!this.disabledEmit) {
            setTimeout(() => {
                this.longPress.emit();
            }, 100);
        }
    }

    constructor() {
        const click$ = (state: boolean) => {
            if (state) {
                return timer(this.threshold, 100).pipe(
                    takeUntil(this.stopInterval$),
                    map(time => {
                        return {
                            key: 'longPress',
                            time
                        };
                    })
                );
            } else {
                return of({
                    key: 'shortPress',
                    time: -1
                });
            }
        };
        const mousedown = this.mousedown.asObservable();
        const mouseup = this.mouseup.asObservable();
        const touchstart = this.touchstart.asObservable();
        const touchEnd = this.touchend.asObservable();
        this.eventSubscribe = merge(mousedown, mouseup, touchstart, touchEnd)
            .pipe(
                switchMap((state: boolean) => {
                        return click$(state);
                    }
                ),
            )
            .subscribe((val) => {
                if (val.key === 'shortPress' && val.time === -1 && !this.longPressEmitted) {
                    this.emitShort();
                } else if (val.time >= 0 && val.key === 'longPress') {
                    if (!this.emitOnceForClick) {
                        this.emitLong();
                    } else if (this.emitOnceForClick && val.time === 0) {
                        this.emitLong();
                        this.stopInterval$.next();
                    }
                }
                this.longPressEmitted = val.time >= 0;
            });
    }

    ngOnDestroy(): void {
        if (this.eventSubscribe) {
            this.eventSubscribe.unsubscribe();
        }
        this.mouseup.complete();
        this.mousedown.complete();
        this.touchstart.complete();
        this.touchend.complete();
    }
}
