import {Injectable} from '@angular/core';
import {ParseApiService} from './parse-api.service';
import * as Parse from 'parse';
import {PluginApiService} from '../plugins/plugin-api.service';
import {UserService} from './user.service';
import {TranslateService} from '@ngx-translate/core';
import {SchedaManutenzioneCompilataParse} from "../../models/SchedaManutenzioneCompilata.Parse";
import {SegnalazioniParse} from "../../models/Segnalazioni.Parse";
import {CommentiSegnalazioniParse} from "../../models/CommentiSegnalazioni.Parse";
import {Observable} from "rxjs";
import {fromPromise} from "rxjs/internal-compatibility";
import {
    arrayIsSet, className,
    fetchParseObjects$,
    getItemInArrayByKeyValue,
    isNotNullOrUndefined,
    paramsApiParse_v2
} from "../../models/Models";
import {SchedaManutenzioneParse} from "../../models/SchedaManutenzione.Parse";
import {MaintenanceService} from "./maintenance.service";
import {ProjectService} from "./project.service";

@Injectable({
    providedIn: 'root'
})
export class ActivitiesService extends ParseApiService {

    constructor(
        private apiService: PluginApiService,
        private userService: UserService,
        private projectService: ProjectService,
        protected translate: TranslateService,
        private maintenanceService: MaintenanceService
    ) {
        super();
    }

    public async salvaNuovoReportSegnalazione(segnalazioneId, values) {
        try {

            let comment = false;
            if (values.comment) {
                comment = values.comment;
            } else if (values.foto) {
                comment = values.foto.file;
            } else if (values.allegato) {
                comment = values.allegato.file;
            }

            if (!comment) {
                throw new Error(this.translate.instant('no_element'));
            }
            const res = await Parse.Cloud.run('salvaNuovoReportSegnalazione',
                {
                    segnalazioneId,
                    comment,
                    stato: values.status,
                }, {});

            return res;
        } catch (e) {
            throw e;
        }
    }


    public async getActivitiesOfReport(report, includeSchedeManutenzione = false) {
        try {
            const params = paramsApiParse_v2({
                segnalazioneId: report.objectId
            });
            const res1 = await Parse.Cloud.run('getCommentiSegnalazioni', params);


            if (includeSchedeManutenzione) {
                let schedeManutenzioni: SchedaManutenzioneParse[] = []
                const schedeManutenzioniCompilate: SchedaManutenzioneCompilataParse[] = []
                res1.forEach(activity => {
                    if (isNotNullOrUndefined(activity.schedaManutenzioneCompilata)) {
                        if (!isNotNullOrUndefined(getItemInArrayByKeyValue(schedeManutenzioni, activity.schedaManutenzioneCompilata.schedaManutenzione, 'objectId'))) {
                            schedeManutenzioni.push(activity.schedaManutenzioneCompilata.schedaManutenzione)
                        }
                        schedeManutenzioniCompilate.push(activity.schedaManutenzioneCompilata)
                    }
                })
                if (arrayIsSet(schedeManutenzioni)) {
                    schedeManutenzioni = await fetchParseObjects$<SchedaManutenzioneParse>(className.schedaManutenzione, schedeManutenzioni.map(s => s.objectId)).toPromise();
                }
                if (arrayIsSet(schedeManutenzioniCompilate)) {
                    const schedeFetched = await this.maintenanceService.fetchDocumentFileInFormSchedeCompilate(schedeManutenzioniCompilate).toPromise();
                    res1.forEach(commentoSegnalazione => {
                        if (commentoSegnalazione.schedaManutenzioneCompilata != null) {
                            const index = schedeFetched.findIndex(schedaFectched => schedaFectched.objectId == commentoSegnalazione.schedaManutenzioneCompilata.objectId)
                            if (index >= 0) {
                                commentoSegnalazione.schedaManutenzioneCompilata = schedeFetched[index]
                            }
                        }
                    })
                }
            }
            return res1.map(c => {
                const commento = new CommentiSegnalazioniParse()
                commento.objectId = c.id
                return commento;
            })
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    public getActivitiesOfReportObs(segnalazione: SegnalazioniParse, includeSchedeManutenzione = false): Observable<CommentiSegnalazioniParse[]> {
        return fromPromise(this.getActivitiesOfReport(segnalazione, includeSchedeManutenzione))
    }

    public async getActivitiesOfProject(skip: number, limit: number) {
        try {
            const params = paramsApiParse_v2({
                progettoId: this.projectService.currentProject.objectId,
                skip,
                limit
            });
            const res = await Parse.Cloud.run('getCommentiSegnalazioni', params);

            const schedeCompilate = res
                .filter(commento => commento.schedaManutenzioneCompilata != null)
                .map(commento => commento.schedaManutenzioneCompilata);
            if (arrayIsSet(schedeCompilate)) {
                const schedeFetched = await this.maintenanceService.fetchDocumentFileInFormSchedeCompilate(schedeCompilate).toPromise();
                res.forEach(commentoSegnalazione => {
                    if (commentoSegnalazione.schedaManutenzioneCompilata != null) {
                        const index = schedeFetched.findIndex(schedaFectched => schedaFectched.objectId == commentoSegnalazione.schedaManutenzioneCompilata.objectId)
                        if (index >= 0) {
                            commentoSegnalazione.schedaManutenzioneCompilata = schedeFetched[index]
                        }
                    }
                })
            }
            return res;
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    public async createActivity(report, comment?: string, foto?, allegato?, schedaManutenzioneCompilata?: SchedaManutenzioneCompilataParse, values: any = undefined) {
        try {
            const Attività = Parse.Object.extend('CommentiSegnalazioni');
            const activity = new Attività();
            if (comment) {
                activity.set('comment', comment);
            } else if (foto) {
                activity.set('foto', foto.file);
            } else if (allegato) {
                activity.set('allegato', allegato.file);
            } else if (schedaManutenzioneCompilata) {
                activity.set('schedaManutenzioneCompilata', schedaManutenzioneCompilata);
            } else {
                throw new Error(this.translate.instant('services.photo_or_message_error'));
            }
            if (isNotNullOrUndefined(values)) {
                Object.keys(values).forEach(key => {
                    if (isNotNullOrUndefined(values[key])) {
                        activity.set(key, values[key])
                    }
                })
            }
            activity.set('segnalazione', report);
            await activity.save(null, {sessionToken: this.userService.sessionToken()});
            return activity
        } catch (e) {
            throw new Error(this.translate.instant('services.activity_error', {message: e.message}));
        }

    }

    public createActivityObs(report, comment?: string, foto?, allegato?, schedaManutenzioneCompilata?: SchedaManutenzioneCompilataParse, values: any = undefined) {
        const Attività = Parse.Object.extend('CommentiSegnalazioni');
        const activity = new Attività();
        if (comment) {
            activity.set('comment', comment);
        } else if (foto) {
            activity.set('foto', foto.file);
        } else if (allegato) {
            activity.set('allegato', allegato.file);
        } else if (schedaManutenzioneCompilata) {
            activity.set('schedaManutenzioneCompilata', schedaManutenzioneCompilata);
        } else {
            throw new Error(this.translate.instant('services.photo_or_message_error'));
        }
        if (isNotNullOrUndefined(values)) {
            Object.keys(values).forEach(key => {
                if (isNotNullOrUndefined(values[key])) {
                    activity.set(key, values[key])
                }
            })
        }
        activity.set('segnalazione', report);
        return fromPromise(activity.save(null, {sessionToken: this.userService.sessionToken()}))
    }


    getParseFile(file) {

    }

}

