import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SidenavProgectComponent} from './sidenav-progect/sidenav-progect.component';
import {MatIconModule} from "@angular/material/icon";
import {TranslateModule} from "@ngx-translate/core";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import {MatLegacyTooltipModule as MatTooltipModule} from "@angular/material/legacy-tooltip";
import {DirectivesModule} from "../../providers/directives/directives.module";
import {ReactiveFormsModule} from "@angular/forms";
import {MatLegacyFormFieldModule as MatFormFieldModule} from "@angular/material/legacy-form-field";
import {MatLegacySelectModule as MatSelectModule} from "@angular/material/legacy-select";
import {MatLegacyInputModule as MatInputModule} from "@angular/material/legacy-input";
import {MatLegacyChipsModule as MatChipsModule} from "@angular/material/legacy-chips";
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from "@angular/material/legacy-autocomplete";
import { SidenavNewProjectComponent } from './sidenav-new-project/sidenav-new-project.component';
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {NgxDropzoneModule} from "ngx-dropzone";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from "@angular/material/legacy-progress-spinner";


@NgModule({
    declarations: [SidenavProgectComponent, SidenavNewProjectComponent],
    exports: [
        SidenavProgectComponent,
        SidenavNewProjectComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        TranslateModule,
        MatButtonModule,
        MatTooltipModule,
        DirectivesModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatCardModule,
        NgxDropzoneModule,
        MatToolbarModule,
        MatProgressSpinnerModule

    ]
})
export class ProjectSidenavModule {
}
