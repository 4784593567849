import * as Parse from 'parse';

export class CommentiSegnalazioniParse extends Parse.Object {
    public static CLASS_NAME = "CommentiSegnalazioni";


    constructor() {
        super(CommentiSegnalazioniParse.CLASS_NAME);
    }

    public get objectId() {
        return this.id;
    }

    public set objectId(value) {
        this.id = value;
    }

    public set ACL(value) {
        super.set('ACL', value);
    }

    public get ACL() {
        return super.get('ACL');
    }

    public set createdAt(value) {
        super.set('createdAt', value);
    }

    public get createdAt() {
        return super.get('createdAt');
    }

    public set updatedAt(value) {
        super.set('updatedAt', value);
    }

    public get updatedAt() {
        return super.get('updatedAt');
    }

    public set allegato(value) {
        super.set('allegato', value);
    }

    public get allegato(): Parse.File {
        return super.get('allegato');
    }

    public set foto(value) {
        super.set('foto', value);
    }

    public get foto(): Parse.File {
        return super.get('foto');
    }

    public set comment(value) {
        super.set('comment', value);
    }

    public get comment(): string {
        return super.get('comment');
    }

    public set progetto(value) {
        super.set('progetto', value);
    }

    public get progetto() {
        return super.get('progetto');
    }

    public set nomeAutore(value) {
        super.set('nomeAutore', value);
    }

    public get nomeAutore(): string {
        return super.get('nomeAutore');
    }

    public set nuovoStato(value) {
        super.set('nuovoStato', value);
    }

    public get nuovoStato(): string {
        return super.get('nuovoStato');
    }

    public set objectUser(value) {
        super.set('objectUser', value);
    }

    public get objectUser(): Parse.User {
        return super.get('objectUser');
    }

    public set statoPrecedente(value) {
        super.set('statoPrecedente', value);
    }

    public get statoPrecedente(): string {
        return super.get('statoPrecedente');
    }

    public set segnalazione(value) {
        super.set('segnalazione', value);
    }

    public get segnalazione() {
        return super.get('segnalazione');
    }

    public set schedaManutenzioneCompilata(value) {
        super.set('schedaManutenzioneCompilata', value);
    }

    public get schedaManutenzioneCompilata() {
        return super.get('schedaManutenzioneCompilata');
    }

    public set user(value) {
        super.set('user', value);
    }

    public get user(): Parse.User {
        return super.get('user');
    }

    public set vistoDa(value) {
        super.set('vistoDa', value);
    }

    public get vistoDa(): Parse.User[] {
        return super.get('vistoDa');
    }


}

Parse.Object.registerSubclass(CommentiSegnalazioniParse.CLASS_NAME, CommentiSegnalazioniParse);
