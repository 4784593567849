import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LoaderService} from '../../providers/services/loader.service';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {

    public loader: boolean = false;
    private subscription;

    @Input() 
    loaderInput = false;


    constructor(private loaderService: LoaderService) {
        this.subscription = this.loaderService.getObservable().subscribe(res => setTimeout(() => this.loader = res, 1));
    }

    ngOnInit() {
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
