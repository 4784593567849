<div>
    <span style="margin-right: 20px">{{'remote_control_settings.applyValueOnHeaderTable'|translate}}</span>
    <button mat-flat-button (click)="applyValue()" color="primary">
        <mat-icon>check</mat-icon>
    </button>
</div>
<mat-dialog-content style="padding: 0">
    <form [formGroup]="formGrup">
        <mat-selection-list formControlName="selectedRows">
            <table mat-table [dataSource]="dataSource" *ngIf="currentValues$|async as currentValuesForm">
                <ng-container [matColumnDef]="columnName" sticky="true">
                    <th mat-header-cell *matHeaderCellDef style="padding: 5px;min-width: 80px">
                        {{'iotDevices.globalForm'|translate}}
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 10px">{{element.circuito.numeroQuadro}}</td>
                </ng-container>
                <ng-container [matColumnDef]="checkBoxName" sticky="true">
                    <th mat-header-cell *matHeaderCellDef>
                        <ng-container [formGroupName]="globalFormName">
                            <mat-checkbox style="padding: 16px" color="primary"
                                          (click)="$event.stopPropagation();"
                                          [formControlName]="'selected'">
                            </mat-checkbox>
                        </ng-container>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <mat-list-option [value]="element.objectId">
                        </mat-list-option>
                    </td>
                </ng-container>
                <ng-container *ngFor="let key of displayedColumnsToVisualization">
                    <ng-container [matColumnDef]="key.key">
                        <th mat-header-cell *matHeaderCellDef
                        >
                            <div class="flex-around-center flex-flow-column width-title huna-form-field">
                                <ng-container [formGroupName]="globalFormName">
                                    <ng-container [ngSwitch]="key.type">
                                        <ng-container *ngSwitchCase="'bool'">
                                            <div class="checkbox-table">
                                                <mat-label>{{'iotDevices.' + key.key|translate}}</mat-label>
                                                <div class="flex-around-center w100" style="margin-top: 5px">
                                                    <button mat-stroked-button class="size-button"
                                                            [color]="formGrup.get(globalFormName).get(key.key).value==true?'primary':null"
                                                            (click)="yesOrNotButton(true,key.key)">{{'yes'|translate}}
                                                    </button>
                                                    <button mat-stroked-button
                                                            class="size-button"
                                                            [color]="formGrup.get(globalFormName).get(key.key).value==false?'primary':null"
                                                            (click)="yesOrNotButton(false,key.key)">{{'no'|translate}}
                                                    </button>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'number'">
                                            <mat-checkbox formControlName="{{subStringSelectKeyCol +key.key}}"></mat-checkbox>
                                            <mat-form-field appearance="fill" floatLabel="always"
                                                            style="margin-bottom: -19px">
                                                <mat-label>{{'iotDevices.' + key.key|translate}}</mat-label>
                                                <input matInput [formControlName]="key.key" type="number">
                                                <button mat-icon-button matSuffix
                                                        (click)="$event.stopPropagation();unsetGlobalFormField(key.key)">
                                                    <mat-icon>clear</mat-icon>
                                                </button>
                                            </mat-form-field>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element"
                            [ngClass]="{'background-primary-transparent':currentValuesForm|convertValueByPredicateFunction:isDirtyPredicate:element:key.key}">
                            <div class="flex-around-center width-title">
                                <ng-container [formGroupName]="element.objectId">
                                    <ng-container [ngSwitch]="key.type">
                                        <ng-container *ngSwitchCase="'bool'">
                                            <div class="checkbox-table">
                                                <mat-label> {{'iotDevices.' + key.key|translate}}</mat-label>
                                                <mat-checkbox
                                                        (click)="$event.stopPropagation();"
                                                        [formControlName]="key.key">
                                                </mat-checkbox>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'number'">
                                            <mat-form-field appearance="outline" style="margin-bottom: -19px">
                                                <mat-label>{{'iotDevices.' + key.key|translate}}</mat-label>
                                                <input matInput [formControlName]="key.key" type="number">
                                            </mat-form-field>
                                        </ng-container>
                                        <ng-container *ngSwitchDefault>
                                            {{element[key.key]}}
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </td>
                    </ng-container>
                </ng-container>
                <ng-container matColumnDef="allarme" [sticky]="true">
                    <th mat-header-cell *matHeaderCellDef
                        style="border-left: 1px solid;min-width: 55px;font-weight: bold"> {{'alarms'|translate}} :
                    </th>
                    <td mat-cell *matCellDef="let element" style="border-left: 1px solid"></td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true;"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </mat-selection-list>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-flat-button [mat-dialog-close]="undefined" color="accent">
        <mat-icon>clear</mat-icon>
    </button>
    <button mat-flat-button (click)="save()" color="primary">
        <mat-icon>save</mat-icon>
    </button>
</mat-dialog-actions>
