<mat-dialog-content>
    <app-split-screen [breakPoint]="breakPoint">
        <div sx>
            <div>
                <form [formGroup]="formGroup" style="margin: 0 36px"
                      *ngIf="typeForm!==typeVersioneProtocolloLM.prt_v1&&yesGroupId">
                    <mat-form-field appearance="fill">
                        <mat-label>{{'newGroupId'|translate}}</mat-label>
                        <input matInput [min]="0" [max]="253" type="number" formControlName="newGroupId">
                        <button mat-icon-button color="primary" matSuffix
                                (click)="$event.stopPropagation();sendValue()">
                            <mat-icon>send</mat-icon>
                        </button>
                    </mat-form-field>
                </form>
                <div class="flex-center-Between  " style="margin: 5px">
                    <app-form-light-mate class="w100"
                                         [showDetailLastComand]="false"
                                         [showIdGruppo]="false"
                                         [showLine]="false"
                                         [showSearchWrong]="showSearchWrong"
                                         [typeForm]="typeForm"
                                         [profilesTlc]="profilesTlc"
                                         (saveValues)="setValuesVirtualMidnight($event)"
                                         (clickAction)="setValuesClickAction($event)"
                    >
                    </app-form-light-mate>
                </div>
            </div>
            <ng-container>
                <mat-card style="margin: 5px" *ngIf="showTable">
                    <!--                <mat-card-subtitle>-->
                    <!--                    {{'pageProfile.profileDifferentMassive'|translate}}-->
                    <!--                </mat-card-subtitle>-->
                    <div class="flex-around-center  flex-flow-row">
                        <mat-card *ngFor="let i of profileFunctionForDays" style="margin: 5px">
                            <div class="flex-center-width100 flex-flow-column">
                                <div style="font-size: large;">{{'week.' + i.day|translate}}&nbsp;</div>
                                <div *ngIf="i.name">{{i.name}}</div>
                                <div *ngIf="i.traduction">{{i.traduction|translate}}</div>
                            </div>
                        </mat-card>
                    </div>
                    <div class="flex-around-center  flex-flow-row">
                        <app-button-multiple-click *ngIf="destroyDataLength$|async" color="warn"
                                                   [tresholdClickButton]="2"
                                                   (clickComplete)="removeDataSetting()">
                            <mat-icon>delete</mat-icon>
                        </app-button-multiple-click>
                        <mat-card *ngFor="let i of profileFunctionForDates" style="margin: 5px">
                            <div style="position: absolute;top: 0;right: 0">
                                <ng-container
                                        *ngIf="((destroyData$|async)|convertValueByPredicateFunction:getIconDestroy:i.objectId) as icon">
                                    <button mat-icon-button color="{{icon.color}}"
                                            (click)="addRemoveDestroySetting(i.objectId)">
                                        <mat-icon>{{icon.name}}</mat-icon>
                                    </button>
                                </ng-container>
                            </div>
                            <div class="flex-center-width100 flex-flow-column">
                                <div style="">{{i.date|customDate:'':'dd/MMMM'|async}}&nbsp;</div>
                                <div *ngIf="i.traduction" style="font-weight: bold">{{i.traduction|translate}}</div>
                                <div *ngIf="i.name">{{i.name}}</div>
                            </div>
                        </mat-card>
                    </div>

                </mat-card>
            </ng-container>
        </div>
        <div dx *ngIf="showTable">
            <mat-card style="margin: 5px">
                <mat-card-subtitle class="title-size">
                    {{titleTraduction|translate}}
                </mat-card-subtitle>
            </mat-card>
            <mat-card style="margin: 5px">
                <div class="scroll" *ngIf="isSetDataSource">
                    <table mat-table [dataSource]="dataSource" class="table">
                        <!-- Implicit Column -->
                        <ng-container [matColumnDef]="col" *ngFor="let col of displayedColumns">
                            <th mat-header-cell *matHeaderCellDef> {{displayedColumnsTraduction[col]|translate}} </th>
                            <td mat-cell *matCellDef="let data">
                                {{data[col]}}
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                            [ngClass]="{'warning-row': row.isWarn}"></tr>
                    </table>
                </div>
            </mat-card>
        </div>
    </app-split-screen>
</mat-dialog-content>
<mat-dialog-actions>
    <div style="width: 220px">
        <div class="flex-around-center w100">
            <button [mat-dialog-close]="undefined" mat-raised-button color="accent">
                <mat-icon>clear</mat-icon>
            </button>
        </div>
    </div>
</mat-dialog-actions>
