import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {Observable, of} from "rxjs";
import {map, startWith, switchMap} from "rxjs/operators";
import {arrayIsSet, stringIsSet} from "../../models/Models";

@Pipe({
    name: 'traductionNotTraduction'
})
export class TraductionNotTraductionPipe implements PipeTransform {

    constructor(private translateService: TranslateService) {
    }

    transform(item: {
        traduction?: string,
        notTraduction?: string,
        traductions?: { keys: string[], separetor?: string },
    }): Observable<string> {
        return this.translateService.onLangChange.pipe(
            startWith(undefined),
            map((traduction) => {
                if (item.traductions && arrayIsSet(item.traductions.keys)) {
                    return item.traductions.keys.reduce((prev, trad, currentIndex) => {
                        prev += this.translateService.instant(trad);
                        if (currentIndex < item.traductions.keys.length - 1 && stringIsSet(item.traductions.separetor)) {
                            prev += item.traductions.separetor;
                        }
                        return prev;
                    }, '')
                } else if (stringIsSet(item.traduction)) {
                    return this.translateService.instant(item.traduction)
                } else if (stringIsSet(item.notTraduction)) {
                    return item.notTraduction
                } else {
                    return '';
                }
            })
        );
    }

}
