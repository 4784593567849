import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FilterComponent} from "./filter/filter.component";
import { MatBadgeModule } from "@angular/material/badge";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyListModule as MatListModule } from "@angular/material/legacy-list";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import {ReactiveFormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {FilterDialogComponent} from './filter-dialog/filter-dialog.component';
import {PipesModule} from "../../providers/pipes/pipes.module";
import {CoreModule} from "../../core/core.module";
import {FlexLayoutModule} from "@angular/flex-layout";
import {RouterModule} from "@angular/router";
import {InfoLockedPlanDialogComponent} from "../locked-plan/info-locked-plan-dialog/info-locked-plan-dialog.component";
import {InfoLockedPlanModule} from "../locked-plan/info-locked-plan.module";
import {MatLegacyTooltipModule as MatTooltipModule} from "@angular/material/legacy-tooltip";
import {WidgetsModule} from "../../widgets/widgets.module";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {DirectivesModule} from "../../providers/directives/directives.module";
import {MatLegacyRadioModule as MatRadioModule} from "@angular/material/legacy-radio";

@NgModule({
    declarations: [
        FilterComponent,
        FilterDialogComponent,
    ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        TranslateModule,
        MatButtonModule,
        MatDialogModule,
        FlexLayoutModule,
        RouterModule,
        MatInputModule,
        InfoLockedPlanModule,
        MatListModule,
        MatGridListModule,
        MatCardModule,
        MatIconModule,
        MatBadgeModule,
        PipesModule,
        CoreModule,
        MatSelectModule,
        MatTooltipModule,
        WidgetsModule,
        MatDatepickerModule,
        DirectivesModule,
        MatRadioModule
    ],
    exports: [FilterComponent]
})
export class FilterModule {
}
