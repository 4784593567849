import {Injectable} from '@angular/core';
import * as Parse from 'parse';
import {fromPromise} from "rxjs/internal-compatibility";
import {concat, EMPTY, from, Observable, of} from "rxjs";
import {concatMap, delay, expand, first, map, switchMap, toArray} from "rxjs/operators";
import {ArredoUrbanoParse} from "../../models/ArredoUrbano.Parse";
import {typeKeyConstrainValue} from "./arredo-urbano-utils.service";
import {FotometriaParse} from "../../models/Fotometria.Parse";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {arrayIsSet, isNotNullOrUndefined, stringIsSet} from "../../models/Models";
import {ProjectService} from "./project.service";

export interface propertiesLoadNewPhotometry {
    nomeFamiglia: string,
    nomeLampada: string,
    nomeProdotto: string,
    nomeFotometria: string,
    ottica: string,
    produttore: string,
    tipologiaCorpoIlluminante: string,
    isArtistico: boolean
}

export interface loadNewPhotometry {
    base64: string,
    id: string,
    properties: propertiesLoadNewPhotometry,
}

@Injectable({
    providedIn: 'root'
})
export class FotometrieUtilsService {

    constructor(private fb: UntypedFormBuilder,
                private projectService: ProjectService
    ) {
    }

    public orderLabelChips = ['produttore', 'nomeFamiglia', 'nomeProdotto', 'nomeLampada', 'temperaturaColore', 'CRI', 'ottica', 'tipologiaCorpoIlluminante'];
    public keysChips = ['nomeFamiglia', 'nomeProdotto', 'temperaturaColore', 'CRI', 'tipologiaCorpoIlluminante']
    public keysFormBoolean = ['isArtistico']

    fetchPage(page = 0, numberElementForCycle, fotometrie: loadNewPhotometry[]) {
        const first = page * numberElementForCycle;
        let isLast;
        let last;
        if (first + numberElementForCycle >= fotometrie.length) {
            last = fotometrie.length;
            isLast = true;
        } else {
            last = first + numberElementForCycle;
            isLast = false;
        }
        const progress = Math.ceil(last / fotometrie.length * 100)
        const fotomotrieToSave = fotometrie.slice(first, last);
        const ids = fotomotrieToSave.map(
            fotometria => fotometria.id
        )
        const res = Parse.Cloud.run('caricaFotometrie', {fotometrie: fotomotrieToSave})
        return fromPromise(res).pipe(
            map((item) => {
                return {
                    item: item,
                    progress: progress,
                    nextPage: isLast ? undefined : (page += 1),
                    finished: isLast,
                    ids: ids
                };
            }),
            delay(100)
        );
    }

    createNewPhotometryBatch(page = 0, numberElementForCycle, fotometrie: loadNewPhotometry[] = undefined): Observable<{ fotomotrie: FotometriaParse[], finished: boolean, progress: number, ids: string[] }> {
        return this.fetchPage(page, numberElementForCycle, fotometrie).pipe(
            expand(({nextPage}) => nextPage ? this.fetchPage(nextPage, numberElementForCycle, fotometrie) : EMPTY),
            concatMap(({item, finished, progress, ids}) => {
                let items = [];
                items.push(item);
                return of({fotomotrie: item, finished: finished, progress: progress, ids});
            }),
        ) as Observable<{ fotomotrie: FotometriaParse[], finished: boolean, progress: number, ids: string[] }>;
    }


    createNewFotomotrie(newFotometrie: loadNewPhotometry[]): Observable<any> {
        const allFotometrieToSave = [];
        newFotometrie.forEach(fotometria => {
            allFotometrieToSave.push(fromPromise(Parse.Cloud.run('caricaFotometrie', {fotometrie: [fotometria]})))
        })
        return concat(...allFotometrieToSave)
    }

    getAllFotometrie(): Observable<FotometriaParse[]> {
        const query = new FotometriaParse().query
        query.limit(10000);
        return fromPromise(query.find())
    }

    getFotometrieByUser(user = Parse.User.current()): Observable<FotometriaParse[]> {
        const limit = 1000
        const process$ = (results: FotometriaParse[], page): Observable<{ page: number, results: FotometriaParse[] }> => {
            let query = new FotometriaParse().query;
            query.skip(results.length * page);
            query.equalTo('uploadedBy', user);
            query.limit(limit);
            query.ascending('produttore')
                .addAscending('nomeFile');
            return fromPromise(query.find()).pipe(map((results) => {
                return {page: page + 1, results}
            }));
        };
        return process$([], 0).pipe(
            expand(({page, results}) => results && results.length == limit ? process$(results, page) : EMPTY),
            toArray(),
            map(values => {
                return values.reduce((prev, current) => {
                    if (arrayIsSet(current.results)) {
                        prev = prev.concat(current.results);
                    }
                    return prev;
                }, [])
            })
        )
    }

    getFotometrieByName(name: string): Observable<FotometriaParse> {
        const query = new FotometriaParse().query
        let searchName = name.toLowerCase().replace(/ /g, '');
        query.equalTo('searchName', searchName);
        return fromPromise(query.first()).pipe(
            switchMap((fotometria) => {
                if (fotometria != null) {
                    return of(fotometria)
                } else {
                    let searchName2 = name
                        .toLowerCase()
                        .replace(/ /g, '');
                    const indexPoint = searchName2.lastIndexOf('.');
                    if (indexPoint > 1) {
                        searchName2 = searchName2.slice(0, indexPoint)
                    }
                    query.equalTo('searchName', searchName2);
                    return fromPromise(query.first())
                }
            })
        )
    }

    getSharedFotometrie(user = Parse.User.current()): Observable<FotometriaParse[]> {
        const query = new FotometriaParse().query
        query.exists('uploadedBy');
        query.notEqualTo('uploadedBy', user);
        query.limit(10000);
        return fromPromise(query.find())
    }

    getPublicFotometrie(user = Parse.User.current()): Observable<FotometriaParse[]> {
        const query = new FotometriaParse().query
        query.doesNotExist('uploadedBy');
        query.limit(10000);
        return fromPromise(query.find())
    }


    // getProduttoriFotometrie(publiche = true, personali = true, condivise) {
    //     const res = Parse.Cloud.run('getProduttoriFotometrie', {public: publiche, private: personali, shared: condivise})
    //     return fromPromise(res);

    // }

    getKeysProperties(): string[] {
        const obj: propertiesLoadNewPhotometry = {
            nomeFamiglia: '',
            nomeFotometria: '',
            nomeLampada: '',
            nomeProdotto: '',
            ottica: '',
            tipologiaCorpoIlluminante: '',
            produttore: '',
            isArtistico: undefined
        }
        return Object.keys(obj);
    }

    destroyAll(fotometrie: FotometriaParse[]): Observable<FotometriaParse[]> {
        return fromPromise(Parse.Object.destroyAll(fotometrie))
    }

    saveAll(fotometrie: FotometriaParse[]): Observable<FotometriaParse[]> {
        return fromPromise(Parse.Object.saveAll(fotometrie))
    }

    get keysFormGroupArea() {
        const notTextArea = ['nomeFotometria'].concat(this.keysFormBoolean)
        return this.getKeysProperties().filter(key => !notTextArea.includes(key));
    }

    get formCommonsField(): UntypedFormGroup {
        const obj = {};
        this.keysFormGroupArea.forEach(key => {
            obj[key] = [{
                value: '',
                disabled: false
            },];
        })
        obj['tipologiaCorpoIlluminante'] = [{
            value: '',
            disabled: false
        },];
        this.keysFormBoolean.forEach(key => {
            obj[key] = [{
                value: undefined,
                disabled: false
            },];
        })
        return this.fb.group(obj)
    }


    filterPredicate(fotometria: FotometriaParse, value, chipsSelected, labelsChips, chipsFromArrayService, labelsText, textSelected): boolean {
        const isNotEmpty = Object.values(chipsSelected).findIndex((valueSelected: any[]) => arrayIsSet(valueSelected)) >= 0;
        const isNotEmptyText = Object.values(textSelected).findIndex(valueSelected => stringIsSet(valueSelected)) >= 0;
        if (isNotEmpty) {
            let isPresentInLabel = true;
            labelsChips.forEach(label => {
                let index = 1;
                if (arrayIsSet(chipsSelected[label])) {
                    index = chipsSelected[label].findIndex(valueStored => {
                        if (stringIsSet(valueStored)) {
                            return chipsFromArrayService.isEquals(valueStored, fotometria[label])
                        } else {
                            return !stringIsSet(fotometria[label])
                        }
                    })
                }
                if (index < 0) {
                    isPresentInLabel = false;
                }
            })
            let isPresentInName = true;
            if (isPresentInLabel && arrayIsSet(labelsText)) {
                labelsText.forEach(label => {
                    const value = textSelected[label];
                    if (isNotNullOrUndefined(value) && stringIsSet(value) && (label === 'nomeLampada' || label == 'nomeFile')) {
                        const containedInNomeLampada = (fotometria.nomeLampada) ? chipsFromArrayService.isIncludes(fotometria.nomeLampada, value) : false;
                        const containedInNomeFile = !containedInNomeLampada && (fotometria.nomeFile) ? chipsFromArrayService.isIncludes(fotometria.nomeFile, value) : false;
                        isPresentInName = containedInNomeFile || containedInNomeLampada;
                    } else if (stringIsSet(value)) {
                        isPresentInName = chipsFromArrayService.isIncludes(textSelected[label], fotometria[label])
                    }
                })
            }
            return isPresentInLabel && isPresentInName
        } else if (isNotEmptyText) {
            let isPresentInName = true;
            if (arrayIsSet(labelsText)) {
                labelsText.forEach(label => {
                    const value = textSelected[label];
                    if (stringIsSet(value) && label === 'nomeLampada' || label == 'nomeFile') {
                        const containedInNomeLampada = (fotometria.nomeLampada) ? chipsFromArrayService.isIncludes(fotometria.nomeLampada, value) : false;
                        const containedInNomeFile = !containedInNomeLampada && (fotometria.nomeFile) ? chipsFromArrayService.isIncludes(fotometria.nomeFile, value) : false;
                        isPresentInName = containedInNomeFile || containedInNomeLampada;
                    } else if (stringIsSet(value)) {
                        isPresentInName = chipsFromArrayService.isIncludes(textSelected[label], fotometria[label])
                    }
                })
            }
            return isPresentInName
        } else {
            return true
        }
    }

    getFiltredFotometrieByValue(filtredFotometrieByProduttore, chipsSelected, labelsChips, chipsFromArrayService, labelsText, textSelected) {
        const isNotEmpty = Object.values(chipsSelected).findIndex((value: any[]) => arrayIsSet(value)) >= 0;
        if (isNotEmpty) {
            const fotomotrieFiltrate = filtredFotometrieByProduttore.filter(fotometria => {
                    return this.filterPredicate(fotometria, null, chipsSelected, labelsChips, chipsFromArrayService, labelsText, textSelected)
                }
            )
            return fotomotrieFiltrate
        } else {
            return filtredFotometrieByProduttore
        }

    }

    sortFromArray(a, b, orderLabelChips) {
        const indexA = orderLabelChips.indexOf(a);
        const indexB = orderLabelChips.indexOf(b);
        return indexA - indexB
    }


    getDistinctValueWithOrder_v3(filtredFotometrieByProduttore: FotometriaParse[], chipsSelected, allKeyToCreateChips, orderLabelChips, labelsChip, labelsText, textSelected, chipsFromArrayService) {
        const distinctValue = {}
        let i = 0, page = 500;
        while (i < filtredFotometrieByProduttore.length) {
            const last = (i + page) < filtredFotometrieByProduttore.length ? (i + page) : filtredFotometrieByProduttore.length;
            const fractionFotometrie = filtredFotometrieByProduttore.slice(i, last)
            let lastFotometrieFiltered = fractionFotometrie
            labelsChip.forEach(key => {
                const index = orderLabelChips.indexOf(key);
                const keys = orderLabelChips
                    .filter(keyOrder => allKeyToCreateChips.includes(keyOrder))
                    .slice(0, index - 1)
                    .filter(keyOrder => keyOrder != key)
                const chipsSelectedTemp = {}
                keys.forEach(key => {
                    chipsSelectedTemp[key] = chipsSelected[key];
                })
                if (!arrayIsSet(distinctValue[key])) {
                    distinctValue[key] = []
                }

                const fotometrie = this.getFiltredFotometrieByValue(lastFotometrieFiltered, chipsSelectedTemp, labelsChip, chipsFromArrayService, labelsText, textSelected)
                const fotometrieNotIncludes = fotometrie.filter(f => !distinctValue[key].includes(f[key]))
                distinctValue[key] = distinctValue[key].concat(chipsFromArrayService.getDistinctValue(fotometrieNotIncludes, key));
                lastFotometrieFiltered = fotometrie;
            })
            i = last;
        }
        return distinctValue
    }


    getDistinctValueWithOrder(filtredFotometrieByProduttore, chipsSelected, allKeyToCreateChips, orderLabelChips, labelsChip, labelsText, textSelected, chipsFromArrayService) {
        // console.time('v2')
        // const d2 = this.getDistinctValueWithOrder_v2(filtredFotometrieByProduttore, chipsSelected, allKeyToCreateChips, orderLabelChips, labelsChip, labelsText, textSelected, chipsFromArrayService);
        // console.timeEnd('v2')
        console.time('v3')
        const d3 = this.getDistinctValueWithOrder_v3(filtredFotometrieByProduttore, chipsSelected, allKeyToCreateChips, orderLabelChips, labelsChip, labelsText, textSelected, chipsFromArrayService);
        console.timeEnd('v3')
        // console.timeEnd('v1')
        // console.log('v1', distinctValue)
        // console.log('v2', d)
        return d3
    }


    getUsersInProject() {
        return this.projectService.getAllUsersDetailsInProject();
    }

    sharePhotometry(fotometrieIds: string[], userIds: string[]): Observable<{ errors?: any[], fotometriAggiornate: FotometriaParse[] }> {
        const cluodFunction = (options: any) => Parse.Cloud.run('condividiFotometrie', options)
        return fromPromise(cluodFunction({fotometrieIds, userIds}))
    }

}
