import {EventEmitter, Injectable} from '@angular/core';
import {FilterService} from "./filter.service";
import * as Parse from 'parse';
import {FormatterPipeService} from "./formatter-pipe.service";
import {TranslateService} from "@ngx-translate/core";
import {fromPromise} from "rxjs/internal-compatibility";
import {ProgettiParse} from "../../models/Progetti.Parse";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {HunaValidators} from "../validators/HunaValidators";
import {ComuniParse} from "../../models/Comuni.Parse";
import {UserService} from "./user.service";
import {EMPTY, Observable, of} from "rxjs";
import {map, switchMap} from "rxjs/operators";
import {LocalSotrageService} from "./local-sotrage.service";
import {UserRole, UserRoleNumber} from "../../../config/static-data";
import {arrayIsSet, className, isNotNullOrUndefined, isValidDate, paramsApiParse_v2} from "../../models/Models";
import {CurrentViewService} from "./current-view.service";


@Injectable({
    providedIn: 'root'
})
export class ProjectService {


    private project: EventEmitter<any> = new EventEmitter();

    private nameForm: string;
    private installatoriForm: string;
    private operatoriForm: string;
    private utentiForm: string;
    private gestoriForm: string;
    private _actualProject: ProgettiParse;
    private _actualRoles = [];
    private _projectId;
    private _cachedProject: Parse.Object<Parse.Attributes> | null = null;

    public avaiableProject = true;
    public currentProject$: Observable<ProgettiParse>
    public currentRoles$: Observable<string[]>
    public currentRolesNumber$: Observable<number>
    isGestore$: Observable<boolean>;
    isUtente$: Observable<boolean>;
    isAmministratore$: Observable<boolean>;
    isOperatore$: Observable<boolean>;
    abbonamentoGestioneAttivo$: Observable<boolean>;
    abbonamentoProgettazioneAttivo$: Observable<boolean>;
    abbonamentoIlluminamentoAttivo$: Observable<boolean>;
    abbonamentoTelecontrolloAttivo$: Observable<boolean>;

    constructor(
        private filterService: FilterService,
        private formatterService: FormatterPipeService,
        protected translate: TranslateService,
        private fb: UntypedFormBuilder,
        private userService: UserService,
        private localStorage: LocalSotrageService,
        private currentViewService: CurrentViewService,
    ) {
        this.currentProject$ = this.localStorage.currentProjectEmit.asObservable();
        this.currentRoles$ = this.currentProject$.pipe(
            map(p => {
                if (p != null) {
                    return this.getRoleByProject(p)
                } else {
                    return undefined;
                }
            })
        );
        this.currentRolesNumber$ = this.currentRoles$.pipe(
            map(roles => {
                if (arrayIsSet(roles)) {
                    if (roles.includes(UserRole.AMMINISTRATORE)) {
                        return UserRoleNumber.AMMINISTRATORE;
                    } else if (roles.includes(UserRole.GESTORE)) {
                        return UserRoleNumber.GESTORE;
                    } else if (roles.includes(UserRole.INSTALLATORE)) {
                        return UserRoleNumber.INSTALLATORE;
                    } else if (roles.includes(UserRole.OPERATORE)) {
                        return UserRoleNumber.OPERATORE;
                    } else if (roles.includes(UserRole.UTENTE)) {
                        return UserRoleNumber.UTENTE;
                    }
                }
                return 0;
            })
        )
        this.isGestore$ = this.currentRoles$.pipe(
            map(roles => {
                if (arrayIsSet(roles)) {
                    return roles.includes(UserRole.GESTORE);
                } else {
                    return false;
                }
            })
        );
        this.isAmministratore$ = this.currentRoles$.pipe(
            map(roles => {
                if (arrayIsSet(roles)) {
                    return roles.includes(UserRole.AMMINISTRATORE);
                } else {
                    return false;
                }
            })
        );
        this.isOperatore$ = this.currentRoles$.pipe(
            map(roles => {
                if (arrayIsSet(roles)) {
                    return roles.includes(UserRole.OPERATORE);
                } else {
                    return false;
                }
            })
        );
        this.isUtente$ = this.currentRoles$.pipe(
            map(roles => {
                if (arrayIsSet(roles)) {
                    return roles.includes(UserRole.UTENTE);
                } else {
                    return false;
                }
            })
        );
        this.abbonamentoProgettazioneAttivo$ = this.currentProject$.pipe(
            map(progetto => {
                return progetto != null &&
                progetto.progettazioneAttivo &&
                isValidDate(progetto.subscriptionExpirationDate) ? progetto.subscriptionExpirationDate.getTime() > Date.now() : false
            })
        );
        this.abbonamentoGestioneAttivo$ = this.currentProject$.pipe(
            map(progetto => {
                return progetto != null &&
                progetto.gestioneAttivo &&
                isValidDate(progetto.subscriptionExpirationDate) ? progetto.subscriptionExpirationDate.getTime() > Date.now() : false
            })
        );
        this.abbonamentoIlluminamentoAttivo$ = this.currentProject$.pipe(
            map(progetto => {
                return progetto != null &&
                progetto.illuminamentoAttivo &&
                isValidDate(progetto.subscriptionExpirationDate) ? progetto.subscriptionExpirationDate.getTime() > Date.now() : false
            })
        );
        this.abbonamentoTelecontrolloAttivo$ = this.currentProject$.pipe(
            map(progetto => {
                return progetto != null &&
                progetto.telecontrolloAttivo &&
                isValidDate(progetto.subscriptionExpirationDate) ? progetto.subscriptionExpirationDate.getTime() > Date.now() : false
            })
        );
        const progId = this.getProjectLocal();
        if (isNotNullOrUndefined(progId)) {
            this.projectId = progId;
            const project = new ProgettiParse()
            project.objectId = this.projectId;
            project.fetch$().subscribe(project => {
                if (isNotNullOrUndefined(project)) {
                    this.actualProject = project;
                    this.actualRoles = this.getRoleByProject(project);
                }
            }, error => {
                console.error(error)
            })
        }
        this.projectChange().pipe(
            switchMap((projectId) => {
                this.avaiableProject = false;
                this.projectId = projectId;
                const project = new ProgettiParse()
                project.objectId = projectId;
                this.actualProject = project;
                return project.fetch$()
            })
        ).subscribe(project => {
            const roles = this.getRoleByProject(project)
            this.actualProject = project;
            this.actualRoles = roles;
            this.localStorage.roleProgetto = roles;
            this.avaiableProject = true;
        });
    }

    get project_v2(): ProgettiParse {
        return this.localStorage.project
    }

    set project_v2(project: ProgettiParse) {
        this.localStorage.project = project;
    }

    public get actualProject() {
        return this._actualProject
    }


    public get currentProject(): ProgettiParse {
        if (this.actualProject) {
            return this.actualProject;
        } else {
            const progetto = new ProgettiParse();
            progetto.objectId = this.getProjectLocal();
            return progetto;
        }

    }

    public set actualProject(progetto: ProgettiParse) {
        this.localStorage.nomeProgetto = progetto.name;
        this.localStorage.siglaProgetto = progetto.sigla;
        this.localStorage.projectFirstView = progetto.firstViewOnOpening;
        this._actualProject = progetto;
    }

    public get projectId() {
        return this._projectId;
    }

    public set projectId(value: string) {
        this._projectId = value;
    }

    public get actualRoles(): string[] {
        return this._actualRoles;
    }

    public set actualRoles(roles: string[]) {
        if (arrayIsSet(roles) && (roles.includes(UserRole.INSTALLATORE) || roles.includes(UserRole.OPERATORE))) {
            this.currentViewService.activeModalitaManutenzione()
        }
        this._actualRoles = roles;
    }


    public get projectForm(): UntypedFormGroup {
        return this.fb.group({
                name: [{
                    value: this.nameForm,
                    disabled: false
                }, [Validators.required]],
                comune: [{value: '', disabled: false}, []],
                sigla: [{value: '', disabled: true}, []],
                operatori: [{value: this.operatoriForm, disabled: false}, []],
                installatori: [{value: this.installatoriForm, disabled: false}, []],
                utenti: [{value: this.utentiForm, disabled: false}, []],
                gestori: [{value: this.gestoriForm, disabled: false}, []],
                responsabileComune: [{value: this.gestoriForm, disabled: false}, []],
                nomeGestore: [{value: this.gestoriForm, disabled: false}, []],
                costoMedioEnergia: [{value: this.gestoriForm, disabled: false}, [HunaValidators.isNumberWithDecimal()]],
                image: [{value: this.gestoriForm, disabled: false}, []],
                htmlLogo: [{value: this.gestoriForm, disabled: false}, []],
            }
        );
    }


    public get newProjectForm(): UntypedFormGroup {
        return this.fb.group({
                name: [{
                    value: this.nameForm,
                    disabled: false
                }, [Validators.required]],
                sigla: [{value: '', disabled: false}, [Validators.required]],
                comune: [{value: '', disabled: false}, []],
                responsabileComune: [{value: this.gestoriForm, disabled: false}, []],
                nomeGestore: [{value: this.gestoriForm, disabled: false}, []],
                costoMedioEnergia: [{value: this.gestoriForm, disabled: false}, [HunaValidators.isNumberWithDecimal()]],
                htmlLogo: [{value: '', disabled: false}, []],
                image: [{value: '', disabled: false}, []],
            }
        );
    }


    //verifica che l abbonamento è attivo per il progetto attuale
    public get abbonamentoGestioneAttivo(): boolean {
        if (isNotNullOrUndefined(this.actualProject)) {
            const progetto = this.actualProject;
            return (progetto.gestioneAttivo &&
                (isNotNullOrUndefined(progetto.subscriptionExpirationDate)) ? progetto.subscriptionExpirationDate.getTime() > Date.now() : false
            )
        } else {
            return false;
        }
    }

    public get abbonamentoTelecontrolloAttivo(): boolean {
        if (isNotNullOrUndefined(this.actualProject)) {
            const progetto = this.actualProject;
            return (progetto.telecontrolloAttivo &&
                (isNotNullOrUndefined(progetto.subscriptionExpirationDate)) ? progetto.subscriptionExpirationDate.getTime() > Date.now() : false
            )
        } else {
            return false;
        }

    }

    public get abbonamentoIlluminamentoAttivo(): boolean {
        if (isNotNullOrUndefined(this.actualProject)) {
            const progetto = this.actualProject;
            return (!!progetto.illuminamentoAttivo &&
                (isNotNullOrUndefined(progetto.subscriptionExpirationDate)) ? progetto.subscriptionExpirationDate.getTime() > Date.now() : false
            )
        } else {
            return false;
        }
    }

    public get abbonamentoProgettazioneAttivo(): boolean {
        if (isNotNullOrUndefined(this.actualProject)) {
            const progetto = this.actualProject;
            return (!!progetto.progettazioneAttivo &&
                (isNotNullOrUndefined(progetto.subscriptionExpirationDate)) ? progetto.subscriptionExpirationDate.getTime() > Date.now() : false
            )
        } else {
            return false;
        }
    }


    public mapData(object: {}): {}[] {
        const result = [];
        const keys = Object.keys(object);
        for (let ind = 0; ind < object[keys[0]].length; ind++) {
            const item = {};
            keys.forEach(el => {
                item[el] = object[el][ind];
            });
            result.push(item);
        }
        return result;
    }

    public getCurrentProjectBtObjectId(objectId: string): Observable<ProgettiParse> {
        const queryProject = new Parse.Query(ProgettiParse);
        queryProject.equalTo('objectId', objectId);
        const res = queryProject.first({sessionToken: this.userService.sessionToken()});
        return fromPromise(res);
    }


    public async getInstantCurrentProject() {
        const queryProject = new Parse.Query(ProgettiParse);
        queryProject.equalTo('objectId', this.getProjectLocal());
        return await queryProject.first({sessionToken: this.userService.sessionToken()});
    }


    public setProjectLocal(id, project) {
        try {
            this.localStorage.nomeProgetto = project.name;
            this.localStorage.siglaProgetto = project.sigla;
            this.localStorage.projectId = id;
            this.filterService.destroyAllFilter();
            this.filterService.setLocaleLightPoints([]);
            // this.filterService.setLocaleCircuits([]);
            // this.mapsService.setDefaultLatLng(project.progetto.location);
            this.project.emit();
        } catch (e) {
            console.log(e);
        }
    }

    public getObservable() {
        return this.project.asObservable();
    }

    public getProjectLocal() {
        return (isNotNullOrUndefined(this.projectId)) ? this.projectId : this.localStorage.projectId;
    }


    public async isValidSubscription(livelloMinimo: Number | null | undefined, telecontrollo: Boolean, progettazione: Boolean, gestione: Boolean, illuminamenti: Boolean) {
        const proggettoVerificato = await this.projectSubscriptionIsValid(telecontrollo, progettazione, gestione, illuminamenti)
        if (livelloMinimo != null) {
            return proggettoVerificato || this.userSubscriptionIsValid(livelloMinimo)
        } else {
            return proggettoVerificato
        }
    }

    public async projectSubscriptionIsValid(telecontrollo: Boolean, progettazione: Boolean, gestione: Boolean, illuminamenti: Boolean) {
        let project: ProgettiParse;
        if (isNotNullOrUndefined(this.actualProject)) {
            project = this.actualProject;
        } else if (isNotNullOrUndefined(this.getProjectLocal())) {
            this.actualProject = await this.getInstantCurrentProject();
            project = this.actualProject;
        }
        if (project == null || project.subscriptionExpirationDate == null || project.subscriptionExpirationDate.getTime() < Date.now()) {
            return false
        }
        if (telecontrollo === true && project.telecontrolloAttivo !== true) {
            return false
        }
        if (progettazione === true && project.progettazioneAttivo !== true) {
            return false
        }
        if (gestione === true && project.gestioneAttivo !== true) {
            return false
        }
        if (illuminamenti === true && project.illuminamentoAttivo !== true) {
            return false
        }
        return true
    }

    public async userSubscriptionIsValid(livelloMinimo: Number) {
        const user = Parse.User.current()
        if (user == null) {
            return false
        }
        const expDate = user.get('subscriptionExpirationDate');
        const livelloAbbonamento = user.get('livelloAbbonamento')
        if (expDate == null || expDate.getTime() < Date.now() || livelloAbbonamento == null || livelloAbbonamento < livelloMinimo) {
            return false
        }
        return true
    }

    public eliminaProgetto(progettooDaEliminare: ProgettiParse) {
        return fromPromise(progettooDaEliminare.destroy({sessionToken: this.userService.sessionToken()}));

    }

    public createNewProject(values, idComune) {
        const htlmLogoPresente = (values.htmlLogo != undefined || values.htmlLogo != null) ? (values.htmlLogo.file != undefined && values.htmlLogo.file != null) : false;
        const imagePresente = (values.image != undefined || values.image != null) ? (values.image.file != undefined && values.image.file != null) : false;
        if (htlmLogoPresente) {
            values.htmlLogo = {name: values.htmlLogo.nome, file: {base64: values.htmlLogo.file}}
        } else {
            delete values.htmlLogo
        }
        if (imagePresente) {
            values.image = {name: values.image.nome, file: {base64: values.image.file}};
        } else {
            delete values.image
        }
        if (idComune && idComune.length > 0) {
            values.comune = {objectId: idComune, className: className.comune};
        } else {
            delete values.comune
        }

        if (typeof values.costoMedioEnergia == 'string' && values.costoMedioEnergia.trim().length > 0) {
            values.costoMedioEnergia = parseFloat(values.costoMedioEnergia);
        }
        const params = paramsApiParse_v2({values});
        return fromPromise(Parse.Cloud.run('createProgetto', params));

    }

    public updateProgetto(progettoId, values, installatoriOperatoriUtentiGestori, idComune) {
        const htlmLogoPresente = (values.htmlLogo != undefined || values.htmlLogo != null) ? (values.htmlLogo.file != undefined && values.htmlLogo.file != null) : false;
        const imagePresente = (values.image != undefined || values.image != null) ? (values.image.file != undefined && values.image.file != null) : false;

        if (htlmLogoPresente) {
            values.htmlLogo = {name: values.htmlLogo.nome, file: {base64: values.htmlLogo.file}}
        }
        if (imagePresente) {
            values.image = {name: values.image.nome, file: {base64: values.image.file}};
        }
        if (idComune && idComune.length > 0) {
            values.comune = {objectId: idComune, className: className.comune};
        }

        if (typeof values.costoMedioEnergia == 'string' && values.costoMedioEnergia.trim().length > 0) {
            values.costoMedioEnergia = parseFloat(values.costoMedioEnergia);
        }
        if (installatoriOperatoriUtentiGestori) {
            ['installatori', 'operatori', 'gestori', 'utenti'].forEach(key => {
                if (arrayIsSet(installatoriOperatoriUtentiGestori[key])) {
                    values[key] = installatoriOperatoriUtentiGestori[key].map(user => user.id);
                } else {
                    values[key] = null;
                }
            })
        }

        const params = paramsApiParse_v2({progettoId, values});
        return fromPromise(Parse.Cloud.run('editProgetto', params));

    }


    public deleteFotoProgetto(idProgetto, label) {
        const values = {};
        values[label] = null;
        return this.updateProgetto(idProgetto, values, undefined, undefined);
    }

    public getComuneAutocompletamento(searchText: string) {
        const params = paramsApiParse_v2({searchText});
        const fetched = Parse.Cloud.run('getComuniList', params)
        return fromPromise(fetched);

    }


    public projectChange() {
        return this.localStorage.changeProject$.asObservable();
    }


    public getRoleByProject(project: ProgettiParse): string[] {
        const currentUser = Parse.User.current();
        return [
            {key: 'gestori', role: UserRole.GESTORE},
            {key: 'installatori', role: UserRole.INSTALLATORE},
            {key: 'operatori', role: UserRole.OPERATORE},
            {key: 'utenti', role: UserRole.UTENTE}
        ].reduce((prev, roleKey) => {
            const key = roleKey.key;
            if (arrayIsSet(project[key])) {
                if (project[key].some(user => user.id === currentUser.id)) {
                    prev.push(roleKey.role)
                }
            }
            return prev;
        }, [])

    }

    public getRoleProject(projectId): Observable<string[]> {
        if (isNotNullOrUndefined(projectId)) {
            const queryRole = new Parse.Query(Parse.Role);
            const progetto = new ProgettiParse();
            progetto.objectId = projectId;
            queryRole.equalTo('progetto', progetto);
            queryRole.equalTo('users', Parse.User.current());
            const res = queryRole.find({sessionToken: this.userService.sessionToken()});
            return fromPromise(res).pipe(
                map((roles) => roles.map(role => role.get('tipo')))
            )
        } else {
            return of([])
        }
    }

    public isGestore() {
        return (isNotNullOrUndefined(this.actualRoles) ? this.actualRoles.includes(UserRole.GESTORE) : false);
    }

    public isUtente() {
        return (isNotNullOrUndefined(this.actualRoles) ? this.actualRoles.includes(UserRole.UTENTE) : false);
    }

    public isInstallatore() {
        return (isNotNullOrUndefined(this.actualRoles) ? this.actualRoles.includes(UserRole.INSTALLATORE) : false);
    }

    public isOperatore() {
        return (isNotNullOrUndefined(this.actualRoles) ? this.actualRoles.includes(UserRole.OPERATORE) : false);
    }

    public isUser() {
        return (isNotNullOrUndefined(this.actualRoles) ? this.actualRoles.includes(UserRole.UTENTE) : false);
    }

    public get myRole() {
        if (isNotNullOrUndefined(this.actualRoles)) {
            if (this.actualRoles.includes(UserRole.AMMINISTRATORE)) {
                return UserRoleNumber.AMMINISTRATORE;
            } else if (this.actualRoles.includes(UserRole.GESTORE)) {
                return UserRoleNumber.GESTORE;
            } else if (this.actualRoles.includes(UserRole.INSTALLATORE)) {
                return UserRoleNumber.INSTALLATORE;
            } else if (this.actualRoles.includes(UserRole.OPERATORE)) {
                return UserRoleNumber.OPERATORE;
            } else if (this.actualRoles.includes(UserRole.UTENTE)) {
                return UserRoleNumber.UTENTE;
            }
        }
        return 0;
    }


    get isAmministratore() {
        return this.userService.role.includes(UserRole.AMMINISTRATORE) || this.myRole == UserRoleNumber.AMMINISTRATORE;
    }

    getAllUsersDetailsInProject(): Observable<any[]> {
        const idsUser = [];

        if (arrayIsSet(this.currentProject.gestori)) {
            this.currentProject.gestori.forEach(user => {
                idsUser.push(user.id)
            })
        }
        if (arrayIsSet(this.currentProject.installatori)) {
            this.currentProject.installatori.forEach(user => {
                idsUser.push(user.id)
            })
        }
        if (arrayIsSet(this.currentProject.operatori)) {
            this.currentProject.operatori.forEach(user => {
                idsUser.push(user.id)
            })
        }
        if (arrayIsSet(this.currentProject.utenti)) {
            this.currentProject.utenti.forEach(user => {
                idsUser.push(user.id)
            })
        }
        if (arrayIsSet(idsUser)) {
            return this.userService.getUserDetail(idsUser);
        } else {
            return of([])
        }
    }

}
