<form [formGroup]="forms" *ngIf="forms&&formFieldSchedaSelezionata">
    <div class="flex-center-width100 flex-flow-column">
        <ng-container *ngFor="let formField of formFieldSchedaSelezionata">
            <div style="width: calc(100% - 20px)">
                <ng-container [ngSwitch]="formField.type">
                    <ng-container *ngSwitchCase="formFieldGroupName">
                        <ng-container *ngIf="formField.formGroup!=null&&formField.formGroup.name!=null">
                            <mat-card style="padding-bottom: 0;margin-bottom: 34px">
                                <mat-card-subtitle>
                                    <button mat-icon-button (click)="unsetField(formField.formControlName)">
                                        <mat-icon>clear</mat-icon>
                                    </button>
                                    {{getFieldName(formField)}}
                                </mat-card-subtitle>
                                <app-range-form-field [formGroupName]="formField.formGroup.name"
                                                      [type]="formField.formGroup.type"
                                                      appearance="fill"
                                                      traductionMax="{{formField.formGroup.traductionMax|translate}}"
                                                      traductionMin="{{formField.formGroup.traductionMin|translate}}"
                                >
                                </app-range-form-field>
                            </mat-card>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="typeFormField.inputString">
                        <mat-form-field appearance="fill" class=" width-form-field">
                            <mat-label>
                                {{getFieldName(formField)}}
                            </mat-label>
                            <input matInput [formControlName]="formField.formControlName">
                            <div matPrefix class="flex-around-center flex-flow-row">
                                <ng-container
                                        [ngTemplateOutlet]="clearInfoButton"
                                        [ngTemplateOutletContext]="{formField:formField}">
                                </ng-container>
                            </div>

                        </mat-form-field>
                        <div style="margin-top: -31px;margin-bottom: 34px;">
                            <ng-container
                                    [ngTemplateOutlet]="expandedDetailFormField"
                                    [ngTemplateOutletContext]="{title:getFieldName(formField),note:getFieldNote(formField),expanded:getExpandMoreLessNote(formField)}">
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="typeFormField.inputNumber">
                        <mat-form-field appearance="fill" class=" width-form-field">
                            <mat-label>
                                {{getFieldName(formField)}}
                            </mat-label>
                            <input type="number" matInput
                                   [formControlName]="formField.formControlName">
                            <div matPrefix class="flex-around-center flex-flow-row">
                                <ng-container
                                        [ngTemplateOutlet]="clearInfoButton"
                                        [ngTemplateOutletContext]="{formField:formField}">
                                </ng-container>
                            </div>
                        </mat-form-field>
                        <div style="margin-top: -31px;margin-bottom: 34px;">
                            <ng-container
                                    [ngTemplateOutlet]="expandedDetailFormField"
                                    [ngTemplateOutletContext]="{title:getFieldName(formField),note:getFieldNote(formField),expanded:getExpandMoreLessNote(formField)}">
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="typeFormField.multiSelect">
                        <mat-form-field appearance="fill" class=" width-form-field">
                            <mat-label>
                                {{getFieldName(formField)}}
                            </mat-label>
                            <mat-select [formControlName]="formField.formControlName">
                                <mat-option [value]="opt.valueForm"
                                            *ngFor="let opt of formField.possibleValuesWithKey">
                                    {{opt.html}}
                                </mat-option>
                            </mat-select>
                            <div matPrefix class="flex-around-center flex-flow-row">
                                <ng-container
                                        [ngTemplateOutlet]="clearInfoButton"
                                        [ngTemplateOutletContext]="{formField:formField}">
                                </ng-container>
                            </div>
                        </mat-form-field>
                        <div style="margin-top: -31px;margin-bottom: 34px;">
                            <ng-container
                                    [ngTemplateOutlet]="expandedDetailFormField"
                                    [ngTemplateOutletContext]="{title:getFieldName(formField),note:getFieldNote(formField),expanded:getExpandMoreLessNote(formField)}">
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="typeFormField.autoComplete">
                        <app-auto-complete-form
                                class="width-form-field"
                                classStyle=""
                                [title]="getFieldName(formField)"
                                [items]="formField.possibleValuesWithKey"
                                appearance="fill"
                                [hiddenClearButton]="true"
                                [formControlName]="formField.formControlName"
                        >
                            <div prefix class="flex-around-center flex-flow-row">
                                <ng-container
                                        [ngTemplateOutlet]="clearInfoButton"
                                        [ngTemplateOutletContext]="{formField:formField}">
                                </ng-container>
                            </div>
                        </app-auto-complete-form>
                        <div style="margin-top: -31px;margin-bottom: 34px;">
                            <ng-container
                                    [ngTemplateOutlet]="expandedDetailFormField"
                                    [ngTemplateOutletContext]="{title:getFieldName(formField),note:getFieldNote(formField),expanded:getExpandMoreLessNote(formField)}">
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="typeFormField.dataPicker">
                        <mat-form-field appearance="fill" class=" width-form-field">
                            <mat-label>{{getFieldName(formField)}}</mat-label>
                            <input matInput [matDatepicker]="picker"
                                   [formControlName]="formField.formControlName">
                            <div matPrefix class="flex-around-center flex-flow-row">
                                <ng-container
                                        [ngTemplateOutlet]="clearInfoButton"
                                        [ngTemplateOutletContext]="{formField:formField}">
                                </ng-container>
                            </div>
                            <mat-datepicker-toggle matSuffix
                                                   [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                        <div style="margin-top: -31px;margin-bottom: 34px;">
                            <ng-container
                                    [ngTemplateOutlet]="expandedDetailFormField"
                                    [ngTemplateOutletContext]="{title:getFieldName(formField),note:getFieldNote(formField),expanded:getExpandMoreLessNote(formField)}">
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="typeFormField.hour">
                        <div style="margin: 0px 0 0px 0;background: rgb(245,245,245);border-bottom: 1px solid rgb(142 142 142);color:rgba(0,0,0,0.65);">
                            <div class="ngx-theme">
                                <div style="display: flex;justify-content: space-between"
                                     [ngClass]="{'error-button-yesOrNotCheck':isInError(formField,'required')}">
                                    <div style="max-width: calc(100% - 182px);" class="truncate-text">
                                        <button style="margin-left: 8px;" *ngIf="visualizedUnsetButton"
                                                mat-icon-button
                                                (click)="$event.stopPropagation();unsetField(formField.formControlName);">
                                            <mat-icon>clear</mat-icon>
                                        </button>
                                        <button style="margin-left: 8px;"
                                                mat-icon-button
                                                (click)="$event.stopPropagation();setExpandMoreLessNote(formField);">
                                            <mat-icon>info</mat-icon>
                                        </button>
                                        {{getFieldName(formField)}}
                                    </div>
                                    <ngx-timepicker-field
                                            [formControlName]="formField.formControlName"
                                            style="margin-right: 10px;"
                                            [format]="24"
                                            [clockTheme]="customThemNgxDataPicker"
                                            [confirmBtnTmpl]="confirmBtn"
                                            [cancelBtnTmpl]="cancelBtn"
                                            [toggleIcon]="icon"
                                    >
                                    </ngx-timepicker-field>
                                    <ng-template #icon>
                                        <mat-icon color="accent">watch_later</mat-icon>
                                    </ng-template>
                                    <ng-template #confirmBtn>
                                        <button mat-mini-fab color="accent" style="margin-left: 10px">
                                            <mat-icon>done</mat-icon>
                                        </button>
                                    </ng-template>
                                    <ng-template #cancelBtn>
                                        <button mat-mini-fab color="accent">
                                            <mat-icon>clear</mat-icon>
                                        </button>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                        <div style="margin-bottom: 34px;">
                            <ng-container
                                    [ngTemplateOutlet]="expandedDetailFormField"
                                    [ngTemplateOutletContext]="{title:getFieldName(formField),note:getFieldNote(formField),expanded:getExpandMoreLessNote(formField)}">
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="typeFormField.image">
                        <div class="flex-around-center w100 ">
                            <ngx-dropzone
                                    (change)="onLoadImage($event.addedFiles,formField.formControlName)"
                                    class="drop-zone-item-background "
                                    accept="image/jpeg,image/jpg,image/png"
                            >
                                <ngx-dropzone-label class="w100">
                                    <div class="flex-around-center w100"
                                         [ngClass]="{'error-button-yesOrNotCheck':isInError(formField,'required')}"
                                    >
                                        <div style="width: 51px;text-align: end">
                                            <button mat-icon-button
                                                    (click)="$event.stopPropagation();setExpandMoreLessNote(formField);">
                                                <mat-icon style="color: black">info</mat-icon>
                                            </button>
                                        </div>
                                        <div class="flex-start-start flex-flow-column" style="width: calc(100% - 51px)">
                                            <span style="text-align: start;font-size: 14px;margin-bottom: 3px;margin-left: 20px">{{ getFieldName(formField)}}</span>
                                            <div class="w100"
                                                 style="text-align: center">   {{ 'fileManager.copyFile' | translate}}</div>
                                        </div>
                                    </div>
                                </ngx-dropzone-label>
                                <ngx-dropzone-image-preview
                                        style="width: 100%"
                                        ngProjectAs="ngx-dropzone-preview"
                                        *ngIf="files[formField.formControlName]"
                                        [file]="files[formField.formControlName]"
                                        [removable]="true"
                                        (removed)="onRemoveFile(formField.formControlName)">
                                </ngx-dropzone-image-preview>
                            </ngx-dropzone>
                        </div>
                        <div style="margin-bottom: 34px;margin-top: -28px;">
                            <ng-container
                                    [ngTemplateOutlet]="expandedDetailFormField"
                                    [ngTemplateOutletContext]="{title:getFieldName(formField),note:getFieldNote(formField),expanded:getExpandMoreLessNote(formField)}">
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="typeFormField.checkBox">
                        <div style="margin: 0 0 0px 0;background: rgb(245,245,245);border-bottom: 1px solid rgb(142 142 142);color:rgba(0,0,0,0.65);"
                             [ngClass]="{'error-button-yesOrNotCheck':isInError(formField,'required')}">
                            <div style="margin:5px 0  5px 0;" class="flex-center-Between w100">
                                <p style="margin:5px 0  5px 11px;">
                                    <button matPrefix mat-icon-button
                                            (click)="$event.stopPropagation();setExpandMoreLessNote(formField);">
                                        <mat-icon style="color: black">info</mat-icon>
                                    </button>
                                    {{getFieldName(formField)}}
                                    <button mat-icon-button
                                            (click)="unsetField(formField.formControlName)">
                                        <mat-icon>replay_circle_filled</mat-icon>
                                    </button>
                                </p>
                                <div>
                                    <button style="margin: 5px" mat-flat-button
                                            [color]="getColorYesOrNotButton(formField.formControlName,true)"
                                            (click)="setValueYesOrNotButton(formField.formControlName,true)">
                                        <mat-icon>check_circle</mat-icon>
                                    </button>
                                    <button style="margin: 5px" mat-flat-button
                                            [color]="getColorYesOrNotButton(formField.formControlName,false)"
                                            (click)="setValueYesOrNotButton(formField.formControlName,false)">
                                        <mat-icon>cancel</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div style="margin-bottom: 34px;">
                            <ng-container
                                    [ngTemplateOutlet]="expandedDetailFormField"
                                    [ngTemplateOutletContext]="{title:getFieldName(formField),note:getFieldNote(formField),expanded:getExpandMoreLessNote(formField)}">
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="typeFormField.yesOrNot">
                        <div style="margin: 0 0 0px 0;background: rgb(245,245,245);border-bottom: 1px solid rgb(142 142 142);color:rgba(0,0,0,0.65);"
                             [ngClass]="{'error-button-yesOrNotCheck':isInError(formField,'required')}">
                            <div style="margin:5px 0  5px 0;" class="flex-center-Between w100">
                                <p style="margin:5px 0  5px 11px;">
                                    <button matPrefix mat-icon-button
                                            (click)="$event.stopPropagation();setExpandMoreLessNote(formField);">
                                        <mat-icon style="color: black">info</mat-icon>
                                    </button>
                                    {{getFieldName(formField)}}
                                    <button mat-icon-button
                                            (click)="unsetField(formField.formControlName)">
                                        <mat-icon>replay_circle_filled</mat-icon>
                                    </button>
                                </p>
                                <div>
                                    <button style="margin: 5px" mat-flat-button
                                            [color]="getColorYesOrNotButton(formField.formControlName,true)"
                                            (click)="setValueYesOrNotButton(formField.formControlName,true)">
                                        <mat-icon>check_circle</mat-icon>
                                    </button>
                                    <button style="margin: 5px" mat-flat-button
                                            [color]="getColorYesOrNotButton(formField.formControlName,false)"
                                            (click)="setValueYesOrNotButton(formField.formControlName,false)">
                                        <mat-icon>cancel</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div style="margin-bottom: 34px;">
                            <ng-container
                                    [ngTemplateOutlet]="expandedDetailFormField"
                                    [ngTemplateOutletContext]="{title:getFieldName(formField),note:getFieldNote(formField),expanded:getExpandMoreLessNote(formField)}">
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>
    </div>
</form>

<ng-template #expandedDetailFormField let-title='title' let-note='note' let-expanded='expanded'>
    <mat-card *ngIf="expanded">
        <mat-card-subtitle>{{title}}</mat-card-subtitle>
        {{note}}
    </mat-card>
</ng-template>

<ng-template #clearInfoButton let-formField='formField'>
    <button mat-icon-button *ngIf="visualizedUnsetButton"
            (click)="$event.stopPropagation();unsetField(formField.formControlName);">
        <mat-icon>clear</mat-icon>
    </button>
    <button mat-icon-button
            (click)="$event.stopPropagation();setExpandMoreLessNote(formField);">
        <mat-icon>info</mat-icon>
    </button>
</ng-template>