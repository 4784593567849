import * as Parse from 'parse';
import {allPropertyClass} from "./Models";

export class SchedaManutenzioneCompilataParse extends Parse.Object {

    public static CLASS_NAME = 'SchedaManutenzioneCompilata';

    constructor() {
        super(SchedaManutenzioneCompilataParse.CLASS_NAME);
    }


    public set objectId(value) {
        this.id = value;
    }

    public get objectId(): string {
        return this.id;
    }

    public set ACL(value) {
        super.set('ACL', value);
    }

    public get ACL() {
        return super.get('ACL');
    }

    public set createdAt(value) {
        super.set('createdAt', value);
    }

    public get createdAt(): Date {
        return super.get('createdAt');
    }

    public set updatedAt(value) {
        super.set('updatedAt', value);
    }

    public get updatedAt(): Date {
        return super.get('updatedAt');
    }

    public set approvata(value) {
        super.set('approvata', value);
    }

    public get approvata(): boolean {
        return super.get('approvata');
    }


    public set motivoRifiuto(value) {
        super.set('motivoRifiuto', value);
    }

    public get motivoRifiuto() {
        return super.get('motivoRifiuto');
    }

    public set circuito(value) {
        super.set('circuito', value);
    }

    public get circuito() {
        return super.get('circuito');
    }

    public set compiledBy(value) {
        super.set('compiledBy', value);
    }

    public get compiledBy(): Parse.User {
        return super.get('compiledBy');
    }

    public set progetto(value) {
        super.set('progetto', value);
    }

    public get progetto() {
        return super.get('progetto');
    }

    public set puntoLuce(value) {
        super.set('puntoLuce', value);
    }

    public get puntoLuce() {
        return super.get('puntoLuce');
    }

    public set rejectedBy(value) {
        super.set('rejectedBy', value);
    }

    public get rejectedBy(): Parse.User {
        return super.get('rejectedBy');
    }

    public set form(value) {
        super.set('form', value);
    }

    public get form(): { value: any|any[], traduction: string }[] {
        return super.get('form');
    }

    public set name(value) {
        super.set('name', value);
    }

    public get name(): string {
        return super.get('name');
    }

    public set rifiutata(value) {
        super.set('rifiutata', value);
    }

    public get rifiutata(): boolean {
        return super.get('rifiutata');
    }

    public set schedaManutenzione(value) {
        super.set('schedaManutenzione', value);
    }

    public get schedaManutenzione(): any {
        return super.get('schedaManutenzione');
    }

    public set segnalazione(value) {
        super.set('segnalazione', value);
    }

    public get segnalazione(): any {
        return super.get('segnalazione');
    }

    public get query(): Parse.Query<SchedaManutenzioneCompilataParse> {
        let query = new Parse.Query(SchedaManutenzioneCompilataParse);
        return query;
    }

    public allProperty() {
        return allPropertyClass(SchedaManutenzioneCompilataParse)  }

    public allPropertyOnParse() {
        const notInParse = ['constructor', 'query'];
        return this.allProperty().filter(key => !notInParse.includes(key))
    }
}

Parse.Object.registerSubclass(SchedaManutenzioneCompilataParse.CLASS_NAME, SchedaManutenzioneCompilataParse);
