<div *ngIf="!!progetto" class="container-form">
    <div class="container-form">
        <mat-card style="margin:10px ">
            <mat-card-content>
                <form [formGroup]="filterForm" class="example-chip-list">
                    <mat-form-field appearance="fill">
                        <mat-label>{{'name' |translate}}</mat-label>
                        <textarea matInput [formControlName]="'name'"
                                  cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                  cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"
                                  [errorStateMatcher]="matcher">
                                                </textarea>
                        <!--viene visualizzata la x svuota campo solo alle caselle editabili-->
                        <button
                                *ngIf="this.filterForm.controls['name'].status.toLowerCase()!='disabled'"
                                matSuffix mat-icon-button class="small"
                                (click)="eliminaCampo('name')">
                            <mat-icon>clear</mat-icon>
                        </button>
                        <mat-hint style="color: orangered"
                                  *ngIf="filterForm.get('name').hasError('required');">
                            {{"dashboard_sidenav.formgroup.errori.obbligatorio"|translate}}
                        </mat-hint>
                        <mat-error
                                *ngIf="filterForm.get('name').hasError('notADecimalNumber') && !filterForm.get('name').hasError('required')">
                            {{"dashboard_sidenav.formgroup.errori.numeroDecimale"|translate}}
                        </mat-error>
                        <mat-error
                                *ngIf="filterForm.get('name').hasError('required')">
                            {{"dashboard_sidenav.formgroup.errori.obbligatorio"|translate}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>{{'sigla' |translate}}</mat-label>
                        <textarea matInput [formControlName]="'sigla'"
                                  cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                  cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"
                                  [errorStateMatcher]="matcher">
                                                </textarea>
                        <!--viene visualizzata la x svuota campo solo alle caselle editabili-->
                        <button
                                *ngIf="this.filterForm.controls['sigla'].status.toLowerCase()!='disabled'"
                                matSuffix mat-icon-button class="small"
                                (click)="eliminaCampo('sigla')">
                            <mat-icon>clear</mat-icon>
                        </button>
                        <mat-hint style="color: orangered"
                                  *ngIf="filterForm.get('sigla').hasError('required');">
                            {{"dashboard_sidenav.formgroup.errori.obbligatorio"|translate}}
                        </mat-hint>
                        <mat-error
                                *ngIf="filterForm.get('sigla').hasError('notADecimalNumber') && !filterForm.get('sigla').hasError('required')">
                            {{"dashboard_sidenav.formgroup.errori.numeroDecimale"|translate}}
                        </mat-error>
                        <mat-error
                                *ngIf="filterForm.get('sigla').hasError('required')">
                            {{"dashboard_sidenav.formgroup.errori.obbligatorio"|translate}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>{{'comune' |translate}}</mat-label>
                        <textarea matInput [formControlName]="'comune'"
                                  [matAutocomplete]="auto"
                                  cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                  cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"
                                  [errorStateMatcher]="matcher"
                                  (input)="getComuniDaAutocompletare($event.target.value,0.5)">
                    </textarea>
                        <!--viene visualizzata la x svuota campo solo alle caselle editabili-->
                        <button
                                *ngIf="this.filterForm.controls['comune'].status.toLowerCase()!='disabled'"
                                matSuffix mat-icon-button class="small"
                                (click)="eliminaCampo('comune')">
                            <mat-icon>clear</mat-icon>
                        </button>
                        <mat-hint style="color: orangered"
                                  *ngIf="filterForm.get('comune').hasError('required');">
                            {{"dashboard_sidenav.formgroup.errori.obbligatorio"|translate}}
                        </mat-hint>
                        <mat-error
                                *ngIf="filterForm.get('comune').hasError('notADecimalNumber') && !filterForm.get('comune').hasError('required')">
                            {{"dashboard_sidenav.formgroup.errori.numeroDecimale"|translate}}
                        </mat-error>
                        <mat-error
                                *ngIf="filterForm.get('comune').hasError('required')">
                            {{"dashboard_sidenav.formgroup.errori.obbligatorio"|translate}}
                        </mat-error>
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                            <mat-option *ngFor="let comune of cominiPerAutoCompletare " [value]="comune.nome">
                                {{comune.nome}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>{{'responsabileMunicipality' |translate}}</mat-label>
                        <textarea matInput [formControlName]="'responsabileComune'"
                                  cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                  cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5">
                                                </textarea>
                        <!--viene visualizzata la x svuota campo solo alle caselle editabili-->
                        <button
                                *ngIf="this.filterForm.controls['responsabileComune'].status.toLowerCase()!='disabled'"
                                matSuffix mat-icon-button class="small"
                                (click)="eliminaCampo('responsabileComune')">
                            <mat-icon>clear</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>{{'managerName' |translate}}</mat-label>
                        <textarea matInput [formControlName]="'nomeGestore'"
                                  cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                  cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"
                        >
                                                </textarea>
                        <!--viene visualizzata la x svuota campo solo alle caselle editabili-->
                        <button
                                *ngIf="this.filterForm.controls['nomeGestore'].status.toLowerCase()!='disabled'"
                                matSuffix mat-icon-button class="small"
                                (click)="eliminaCampo('nomeGestore')">
                            <mat-icon>clear</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>{{'energyAverageCost' |translate}}</mat-label>
                        <textarea matInput [formControlName]="'costoMedioEnergia'"
                                  cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                  cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"
                                  [errorStateMatcher]="matcher">
                                                </textarea>
                        <!--viene visualizzata la x svuota campo solo alle caselle editabili-->
                        <button
                                *ngIf="this.filterForm.controls['costoMedioEnergia'].status.toLowerCase()!='disabled'"
                                matSuffix mat-icon-button class="small"
                                (click)="eliminaCampo('costoMedioEnergia')">
                            <mat-icon>clear</mat-icon>
                        </button>
                        <mat-error
                                *ngIf="filterForm.get('costoMedioEnergia').hasError('notADecimalNumber')">
                            {{"dashboard_sidenav.formgroup.errori.numeroDecimale"|translate}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="example-chip-list">
                        <mat-chip-list #gestoriChipList aria-label="Fruit selection">
                            <mat-chip
                                    color="primary" selected
                                    *ngFor="let gestore of installatoriOperatoriUtentiGestori.gestori;"
                                    [selectable]="selectable"
                                    (click)="copyInClickBoard(gestore.id)"
                                    [removable]="removable"
                                    (removed)="remove(gestore,'gestori')">
                                {{gestore.get('nome') + ' ' + gestore.get('cognome')}}
                                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                            </mat-chip>
                            <input
                                    placeholder="{{'gestori' | translate}}"
                                    [formControlName]="'gestori'"
                                    [matChipInputFor]="gestoriChipList"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    (matChipInputTokenEnd)="add($event,'gestori')"
                            >
                        </mat-chip-list>
                        <mat-error> {{messageError | translate}}</mat-error>

                        <!--                           [matAutocomplete]="auto"-->
                        <!-- aggiungere questa stringa ad input-->
                        <!--                per suggerire quelli associati-->
                        <!--                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">-->
                        <!--                    <mat-option *ngFor="let fruit of filteredFruits | async" [value]="fruit">-->
                        <!--                        {{fruit}}-->
                        <!--                    </mat-option>-->
                        <!--                </mat-autocomplete>-->
                    </mat-form-field>
                    <mat-form-field class="example-chip-list">
                        <mat-chip-list #operatoriChipList aria-label="Fruit selection">
                            <mat-chip
                                    color="primary" selected
                                    *ngFor="let operatore of installatoriOperatoriUtentiGestori.operatori;"
                                    [selectable]="selectable"
                                    (click)="copyInClickBoard(operatore.id)"
                                    [removable]="removable"
                                    (removed)="remove(operatore,'operatori')">
                                {{operatore.get('nome') + ' ' + operatore.get('cognome')}}
                                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                            </mat-chip>
                            <input
                                    placeholder="{{'operatori' | translate}}"
                                    [formControlName]="'operatori'"
                                    [matChipInputFor]="operatoriChipList"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    (matChipInputTokenEnd)="add($event,'operatori')">
                        </mat-chip-list>
                        <mat-error> {{messageError | translate}}</mat-error>

                        <!--                           [matAutocomplete]="auto"-->
                        <!-- aggiungere questa stringa ad input-->
                        <!--                per suggerire quelli associati-->
                        <!--                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">-->
                        <!--                    <mat-option *ngFor="let fruit of filteredFruits | async" [value]="fruit">-->
                        <!--                        {{fruit}}-->
                        <!--                    </mat-option>-->
                        <!--                </mat-autocomplete>-->
                    </mat-form-field>
                    <mat-form-field class="example-chip-list">
                        <mat-chip-list #installatoriChipList aria-label="Fruit selection">
                            <mat-chip
                                    *ngFor="let installatore of installatoriOperatoriUtentiGestori.installatori;"
                                    [selectable]="selectable"
                                    (click)="copyInClickBoard(installatore.id)"
                                    [removable]="removable"
                                    (removed)="remove(installatore,'installatori')"
                                    color="primary" selected>
                                {{installatore.get('nome') + ' ' + installatore.get('cognome')}}
                                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                            </mat-chip>
                            <input
                                    placeholder="{{'installatori' | translate}}"
                                    [formControlName]="'installatori'"
                                    [matChipInputFor]="installatoriChipList"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    (matChipInputTokenEnd)="add($event,'installatori')">
                        </mat-chip-list>
                        <mat-error> {{messageError | translate}}</mat-error>

                        <!--                           [matAutocomplete]="auto"-->
                        <!-- aggiungere questa stringa ad input-->
                        <!--                per suggerire quelli associati-->
                        <!--                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">-->
                        <!--                    <mat-option *ngFor="let fruit of filteredFruits | async" [value]="fruit">-->
                        <!--                        {{fruit}}-->
                        <!--                    </mat-option>-->
                        <!--                </mat-autocomplete>-->
                    </mat-form-field>
                    <mat-form-field class="example-chip-list">
                        <mat-chip-list #utentiChipList aria-label="Fruit selection">
                            <mat-chip
                                    color="primary" selected
                                    *ngFor="let utente of installatoriOperatoriUtentiGestori.utenti;"
                                    [selectable]="selectable"
                                    (click)="copyInClickBoard(utente.id)"
                                    [removable]="removable"
                                    (removed)="remove(utente,'utenti')">
                                {{utente.get('nome') + ' ' + utente.get('cognome')}}
                                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                            </mat-chip>
                            <input
                                    placeholder="{{'utenti' | translate}}"
                                    [formControlName]="'utenti'"
                                    [matChipInputFor]="utentiChipList"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    (matChipInputTokenEnd)="add($event,'utenti')">
                        </mat-chip-list>
                        <mat-error> {{messageError | translate}}</mat-error>

                        <!--                           [matAutocomplete]="auto"-->
                        <!-- aggiungere questa stringa ad input-->
                        <!--                per suggerire quelli associati-->
                        <!--                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">-->
                        <!--                    <mat-option *ngFor="let fruit of filteredFruits | async" [value]="fruit">-->
                        <!--                        {{fruit}}-->
                        <!--                    </mat-option>-->
                        <!--                </mat-autocomplete>-->
                    </mat-form-field>
                    <mat-toolbar>
                        <mat-toolbar-row>
                            <span style="color: #2196f3">{{'imageLogo'|translate}}</span>
                        </mat-toolbar-row>
                    </mat-toolbar>
                    <div style="display: flex;flex-direction: column; justify-content: center;align-items: center ">
                        <mat-spinner *ngIf="addImageDisabled['htmlLogo']"></mat-spinner>
                        <div *ngIf="!!progetto.htmlLogo"
                             style="display: flex;flex-direction: row; justify-content: space-around;align-items: center; margin-bottom:30px">
                            <div>
                                <img alt="logo"
                                     src="{{progetto.htmlLogo._url}}"
                                     class="object-fit_cover image-anteprima">
                            </div>
                            <div>
                                <button mat-mini-fab style="margin-top: -300px!important;"
                                        (click)="deleteFoto('htmlLogo')">
                                    <img src="/assets/icon/dashboardsidenav/deleteimg.png" style="height: 24px">
                                </button>
                            </div>
                        </div>
                        <div style="display: flex;flex-direction: row; justify-content: space-around;align-items: center ">
                            <ng-container *ngIf="filterForm.get('htmlLogo').value">
                                <div>
                                    <img [src]="filterForm.get('htmlLogo').value.html"
                                         class="object-fit_cover image-anteprima">
                                </div>
                                <div style="font-size:12px;">
                                    <button mat-mini-fab (click)="deleteSavedFile('htmlLogo')"
                                            matTooltip="{{ 'remove_attachement' | translate}}">
                                        <img src="/assets/icon/dashboardsidenav/removeattac.png"
                                             style="height: 24px">
                                    </button>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div style="display: flex;flex-direction: column; justify-content: center;align-items: center ">
                        <label class="label"> {{ 'photo' | translate}}</label>
                        <ngx-dropzone style="background: #f3f3f3"
                                      (change)="onLoadImage($event.addedFiles,'htmlLogo')"
                                      [disabled]="addImageDisabled['htmlLogo']"
                                      accept="image/*">
                            <ngx-dropzone-label>
                                {{ 'drag_image' | translate}}
                            </ngx-dropzone-label>
                        </ngx-dropzone>
                    </div>
                    <mat-toolbar>
                        <mat-toolbar-row>
                            <span style="color: #2196f3">{{'imageBackground'|translate}}</span>
                        </mat-toolbar-row>
                    </mat-toolbar>

                    <div style="display: flex; flex-direction: column;justify-content: flex-start;align-items: center">
                        <mat-spinner *ngIf="addImageDisabled['image']"></mat-spinner>
                        <div *ngIf="!!progetto.image"
                             style="display: flex;flex-direction: row; justify-content: space-around;align-items: center; margin-bottom:30px">
                            <div>
                                <img alt="image"
                                     src="{{progetto.image._url}}"
                                     class="object-fit_cover image-sfondo">
                            </div>
                            <div>
                                <button mat-mini-fab style="margin-top: -300px!important;"
                                        (click)="deleteFoto('image')">
                                    <img src="/assets/icon/dashboardsidenav/deleteimg.png" style="height: 24px">
                                </button>
                            </div>
                        </div>
                        <div style="display: flex;flex-direction: row; justify-content: space-around;align-items: center; margin-bottom:30px">
                            <ng-container *ngIf="filterForm.get('image').value">
                                <div>
                                    <img [src]="filterForm.get('image').value.html"
                                         class="image-sfondo">
                                </div>
                                <div style="font-size:12px;">
                                    <button mat-mini-fab (click)="deleteSavedFile('image')"
                                            matTooltip="{{ 'remove_attachement' | translate}}">
                                        <img src="/assets/icon/dashboardsidenav/removeattac.png"
                                             style="height: 24px">
                                    </button>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div style="display: flex;flex-direction: column; justify-content: center;align-items: center;">
                        <label class="label"> {{ 'photo' | translate}}</label>
                        <ngx-dropzone style="background: #f3f3f3"
                                      (change)="onLoadImage($event.addedFiles,'image')"
                                      [disabled]="addImageDisabled['image']"
                                      accept="image/*">
                            <ngx-dropzone-label>
                                {{ 'drag_image' | translate}}
                            </ngx-dropzone-label>
                        </ngx-dropzone>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
        <button class="fab" color="accent" [disabled]="false" fab mat-mini-fab
                matTooltip="{{'button.save' | translate}}" style="z-index: 10000;margin-right: 3rem;" type="button"
                (click)="onSubmit()">
            <mat-icon>save</mat-icon>
        </button>
        <button (click)="closeSidenav()" class="fab" color="accent" mat-mini-fab
                matTooltip="{{'button.close' | translate}}"
                style="z-index: 10000" type="button">
            <mat-icon>close</mat-icon>
        </button>
        <button (click)="eliminaProgetto()" class="fab" color="accent" mat-mini-fab
                matTooltip="{{'button.delete' | translate}}"
                style="z-index: 10000;margin-right: 6rem;" type="button">
            <mat-icon>delete</mat-icon>
        </button>
    </div>
</div>
