import {Injectable} from '@angular/core';
import * as Parse from 'parse';
import {ProjectService} from "./project.service";
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {arrayIsSet, paramsApiParse_v2, stringIsSet} from "../../models/Models";
import {fromPromise} from "rxjs/internal-compatibility";
import {map, switchMap} from "rxjs/operators";
import {PuntiLuceParse} from "../../models/PuntiLuce.Parse";

export type FotoTipologiaType = {
    url: string,
    name: string,
    objectId: string
}

@Injectable({
    providedIn: 'root'
})
export class FotoTipologiaService {
    fotoTipologie$: Observable<FotoTipologiaType[]>;
    loadFotoTipologie$: Observable<boolean>;
    private fotoTipologieEmit = new BehaviorSubject<FotoTipologiaType[]>(undefined);
    private loadFotoTipologieEmit = new BehaviorSubject<boolean>(false);
    private subscription: Subscription | undefined;


    constructor(private projectService: ProjectService,) {
        this.fotoTipologie$ = this.fotoTipologieEmit.asObservable()
        this.loadFotoTipologie$ = this.loadFotoTipologieEmit.asObservable()
    }

    get fotoTipologie(): FotoTipologiaType[] {
        return this.fotoTipologieEmit.value;
    }

    set fotoTipologie(foto: FotoTipologiaType[]) {
        this.fotoTipologieEmit.next(foto);
    }

    get loadFotoTipologie(): boolean {
        return this.loadFotoTipologieEmit.value;
    }

    set loadFotoTipologie(loading: boolean) {
        this.loadFotoTipologieEmit.next(loading);
    }

    getFotoTipologia$(projectId = undefined): Observable<FotoTipologiaType[]> {
        this.loadFotoTipologie = true;
        let progettoId;
        if (stringIsSet(projectId)) {
            progettoId = projectId;
        } else {
            progettoId = this.projectService.currentProject.objectId;
        }
        const params = paramsApiParse_v2({progettoId});
        return fromPromise(Parse.Cloud.run('getFotoPuntiLuce', params));
    }

    createFotoPuntiLuce$(file: any, projectId = undefined): Observable<FotoTipologiaType> {
        this.loadFotoTipologie = true;
        let progettoId;
        if (stringIsSet(projectId)) {
            progettoId = projectId;
        } else {
            progettoId = this.projectService.currentProject.objectId;
        }
        const params = paramsApiParse_v2({progettoId, file});
        return fromPromise(Parse.Cloud.run('createFotoPuntiLuce', params));
    }

    editFotoPuntiLuce$(file: any, foto: FotoTipologiaType): Observable<FotoTipologiaType> {
        this.loadFotoTipologie = true;
        let fotoTipologiaId = foto.objectId;

        const params = paramsApiParse_v2({fotoTipologiaId, file});
        return fromPromise(Parse.Cloud.run('editFotoPuntiLuce', params));
    }

    deleteFotoPuntiLuce$(foto: FotoTipologiaType): Observable<FotoTipologiaType> {
        let fotoTipologiaId = foto.objectId;
        const params = paramsApiParse_v2({fotoTipologiaId});
        return fromPromise(Parse.Cloud.run('deleteFotoPuntiLuce', params));
    }

    assignFotoTipologiaToLightPoints$(puntiLuceIds: string[], fotoTipologiaId: string): Observable<boolean> {
        const params = paramsApiParse_v2({fotoTipologiaId, puntiLuceIds});
        return fromPromise(Parse.Cloud.run('assignFotoPuntiLuceToPuntiLuce', params));
    }
    removeAssignedFotoPuntiLuceToPuntiLuce$(puntiLuceIds: string[]): Observable<boolean> {
        const params = paramsApiParse_v2({ puntiLuceIds});
        return fromPromise(Parse.Cloud.run('removeAssignedFotoPuntiLuceToPuntiLuce', params));
    }

    initFotoTipologia() {
        this.destroyFotoTipologia();
        this.subscription = this.projectService.currentProject$.pipe(
            switchMap(projectId => {
                return this.getFotoTipologia$(projectId)
            })
        ).subscribe(foto => {
            this.fotoTipologie = foto;
            this.loadFotoTipologie = false;
        }, error => {
            this.fotoTipologie = undefined
            this.loadFotoTipologie = false;
        }, () => {
            this.fotoTipologie = undefined
            this.loadFotoTipologie = false;
        })

    }

    destroyFotoTipologia() {
        if (this.subscription != null) {
            this.subscription.unsubscribe()
            this.subscription = undefined;
        }
    }

    addOrSubsituionFotoTipologia(foto: FotoTipologiaType) {
        const currentFoto = arrayIsSet(this.fotoTipologie) ? [...this.fotoTipologie] : []
        const index = currentFoto.findIndex(current => current.objectId === foto.objectId);
        if (index < 0) {
            currentFoto.push(foto)
        } else {
            currentFoto[index] = foto
        }
        this.fotoTipologie = currentFoto;
    }

    removeFotoTipologia(foto: FotoTipologiaType) {
        const currentFoto = arrayIsSet(this.fotoTipologie) ? [...this.fotoTipologie] : []
        const index = currentFoto.findIndex(current => current.objectId === foto.objectId);
        if (index >= 0) {
            currentFoto.splice(index, 1);
        }
        this.fotoTipologie = currentFoto;
    }

    addNewFoto(file): Observable<FotoTipologiaType> {
        return this.createFotoPuntiLuce$(file)
    }

    editFoto(file, foto: FotoTipologiaType): Observable<FotoTipologiaType> {
        return this.editFotoPuntiLuce$(file, foto)
    }

}
