import {ChangeDetectorRef, OnDestroy, Pipe, PipeTransform} from "@angular/core";
import {AsyncPipe} from "@angular/common";

@Pipe({
    name: "maybeAsync",
    pure: false
})
export class MaybeAsyncPipe implements PipeTransform, OnDestroy {

    private asyncPipe: AsyncPipe;

    constructor(private cdr: ChangeDetectorRef) {
        this.asyncPipe = new AsyncPipe(this.cdr);
    }

    ngOnDestroy() {
        this.asyncPipe.ngOnDestroy();
    }

    transform(value: any): any {
        if (
            typeof value === "object" &&
            ("subscribe" in value || "then" in value)
        ) {
            return this.asyncPipe.transform(value);
        }
        return value;
    }
}
