import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateApiPipe} from './translate-api.pipe';
import {JsonParsePipe} from './json-parse.pipe';
import {FormatterPipe} from './formatter.pipe';
import {MyDatePipe} from './my-date.pipe';
import {PowerFormatPipe} from './power-format.pipe';
import {SafeTextPipe} from './safe-text.pipe';
import {HourParserPipe} from './hour-parser.pipe';
import {TranslateModule} from '@ngx-translate/core';
import {RolesPipe} from './roles.pipe';
import {TransformForTranslatePipe} from './transform-for-translate.pipe';
import {SostituisciUndercoreConSpaziPipe} from './sostituisci-undercore-con-spazi.pipe';
import {CustomDatePipe} from './custom-date.pipe';
import {RoundNumberPipe} from './round-number.pipe';
import {PaginationArrayPipe} from './pagination-array.pipe';
import {NotPresentInTraductionPipe} from './not-present-in-traduction.pipe';
import {ConvertSecInhmsPipe} from './convert-sec-inhms.pipe';
import {FilterListPipe} from './filter-list.pipe';
import {SortListPipe} from './sort-list.pipe';
import {FilterByPredicateFunctionPipe} from './filter-by-predicate-function.pipe';
import {ConvertSecondInDayHourMinutesPipe} from './convert-second-in-day-hour-minutes.pipe';
import {ExtractUniqueValueByPredicateFunctionPipe} from './extract-unique-value-by-predicate-function.pipe';
import {GetClassByValuesPipe} from './get-class-by-values.pipe';
import {ConvertValueByPredicateFunctionPipe} from './convert-value-by-predicate-function.pipe';
import {TranslateByPredicateFunctionPipe} from './translate-by-predicate-function.pipe';
import {MaybeAsyncPipe} from './maybe-async.pipe';
import {GetUserDetailsPipe} from './get-user-details.pipe';
import {TraductionOnlyHasTraductionPipe} from "./traduction-only-has-traduction.pipe";
import { TraductionNotTraductionPipe } from './traduction-not-traduction.pipe';

@NgModule({
    // tslint:disable-next-line:max-line-length
    declarations: [TranslateApiPipe, JsonParsePipe, FormatterPipe, MyDatePipe, PowerFormatPipe, SafeTextPipe, HourParserPipe, RolesPipe, TransformForTranslatePipe, SostituisciUndercoreConSpaziPipe, CustomDatePipe, RoundNumberPipe, PaginationArrayPipe, NotPresentInTraductionPipe, ConvertSecInhmsPipe, FilterListPipe, SortListPipe, FilterByPredicateFunctionPipe, ConvertSecondInDayHourMinutesPipe, ExtractUniqueValueByPredicateFunctionPipe, GetClassByValuesPipe, ConvertValueByPredicateFunctionPipe, TranslateByPredicateFunctionPipe, MaybeAsyncPipe, GetUserDetailsPipe, TraductionOnlyHasTraductionPipe, TraductionNotTraductionPipe],
    imports: [
        CommonModule,
        TranslateModule
    ],
    exports: [TranslateApiPipe,
        JsonParsePipe, FormatterPipe, MyDatePipe, PowerFormatPipe, SafeTextPipe, HourParserPipe, RolesPipe, TransformForTranslatePipe, SostituisciUndercoreConSpaziPipe, CustomDatePipe, RoundNumberPipe, PaginationArrayPipe, NotPresentInTraductionPipe, ConvertSecInhmsPipe, FilterListPipe, SortListPipe, FilterByPredicateFunctionPipe, ConvertSecondInDayHourMinutesPipe, ExtractUniqueValueByPredicateFunctionPipe, GetClassByValuesPipe, ConvertValueByPredicateFunctionPipe, TranslateByPredicateFunctionPipe, MaybeAsyncPipe, GetUserDetailsPipe, TraductionOnlyHasTraductionPipe, TraductionNotTraductionPipe]
})
export class PipesModule {
}
