import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {ProjectService} from "../services/project.service";
import {isNotNullOrUndefined} from "../../models/Models";

@Injectable({
    providedIn: 'root'
})
export class IsSetProjectGuard implements CanActivate {
    constructor(private projectService: ProjectService,
                private router: Router
    ) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (!isNotNullOrUndefined(this.projectService.getProjectLocal())) {
            this.router.navigate(['/project']);
            return false;
        } else {
            return true;
        }
    }

}
