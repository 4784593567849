import {Injectable} from '@angular/core';
import {stringIsSet} from "../../models/Models";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
    providedIn: 'root'
})
export class TranslateParseErrorService {

    constructor(private translateService: TranslateService) {
    }

    getTraductionByError(value: any) {
        let errorMessage
        let errorTranslate;
        if (stringIsSet(value.message)) {
            errorMessage = value.message
        } else if (value.toString) {
            errorMessage = value.toString();
        }
        if (stringIsSet(errorMessage)) {
            errorTranslate = this.translateService.instant('parseError.message.' + errorMessage)
            if (errorTranslate.includes('parseError.message.')) {
                errorTranslate = errorMessage;
                if (errorMessage.includes('ParseError: 2001')) {
                    errorTranslate = this.translateService.instant('parseError.message.' + errorMessage.replace('ParseError: 2001', '').trim())
                    if (errorTranslate.includes('parseError.message.')) {
                        errorTranslate = errorMessage;
                    }
                }
            }
        }
        return errorTranslate;
    }
}
