<svg xmlns="http://www.w3.org/2000/svg" [attr.x]="xSvg" [attr.y]="ySvg" [attr.height]="height" [attr.width]="width" [attr.viewBox]="viewBox" version="1.1" preserveAspectRatio="none">
  <g [attr.transform]="transform">
    <rect [attr.y]="outreachY" [attr.x]="outreachX" [attr.height]="outreachHeight" [attr.width]="outreachWidth"
      style="opacity:1;fill:#000000;fill-opacity:1;stroke:none;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:normal" />
    <path *ngIf="!round"
      style="opacity:1;fill:#ffff52;fill-opacity:1;stroke:none;stroke-width:0.40253827;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:normal"
      [attr.d]="arc" />
    <ellipse *ngIf="round"
      style="opacity:1;fill:#ffff52;fill-opacity:1;stroke:none;stroke-width:0.40253827;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:normal"
      [attr.cx]="centerX"
      [attr.cy]="centerY"
      [attr.rx]="radius"
      [attr.ry]="radius" />
  </g>
</svg>
