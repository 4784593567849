import * as Parse from 'parse';
import {
    allPropertyClass,
    arrayIsSet,
    className,
    PaginationParserequestType,
    paramsApiParse_v2,
    stringIsSet
} from "./Models";
import {GeoPointH} from "../providers/services/map.service";
import {EMPTY, Observable, of, throwError} from "rxjs";
import {fromPromise} from "rxjs/internal-compatibility";
import {keyParseError} from "../providers/services/error-parse.service";
import {catchError, expand, map} from "rxjs/operators";

export type ResponseDeleteLightPoints =
    {
        errors: { puntoLuceId: string, error: { code: number, message: string } }[],
        totalPuntiLuceRemoved: number
    }

export class PuntoLuce_v2 {
    public static CLASS_NAME = className.puntiLuce;
    private localId?: string;
    private _objectId?: string;

    constructor(objectId: string) {
        if (stringIsSet(objectId)) {
            this._objectId = objectId;
        } else {
            this.localId = new Date().getTime().toString(32);
        }
    }

    public set objectId(objectId: string) {
        this._objectId = objectId;
    }

    public get objectId() {
        return stringIsSet(this._objectId) ? this._objectId : this.localId
    }

    public get currentIdIsSet() {
        return stringIsSet(this._objectId);
    }

    create$(projectId: string, location: GeoPointH): Observable<PuntiLuceParse> {
        if (location == null) {
            return throwError(keyParseError.locationtMissing)
        } else if (projectId == null) {
            return throwError(keyParseError.progettoMissing)
        } else {
            const params = paramsApiParse_v2({location, progettoId: projectId});
            return fromPromise(Parse.Cloud.run('createPuntoLuce', params));
        }
    }

    changePuntoLuceLocation$(newLocation: GeoPointH): Observable<PuntiLuceParse> {
        if (!this.currentIdIsSet) {
            return throwError(keyParseError.objectIdNotValid)
        } else if (location == null) {
            return throwError(keyParseError.locationtMissing)
        } else {
            const params = paramsApiParse_v2({newLocation, puntoLuceId: this.objectId});
            return fromPromise(Parse.Cloud.run('changePuntoLuceLocation', params));
        }
    }

    private editPuntiLuce$(puntiLuce: any[]): Observable<PuntiLuceParse[]> {
        const params = paramsApiParse_v2({puntiLuce});
        return fromPromise(Parse.Cloud.run('editPuntiLuce', params))
    }

    edit$(values: any): Observable<PuntiLuceParse> {
        if (values == null) {
            return throwError(keyParseError.dataMissing);
        } else if (!this.currentIdIsSet) {
            return throwError(keyParseError.objectIdNotValid);
        } else {
            values['objectId'] = this.objectId;

            return this.editPuntiLuce$([values]).pipe(
                map(puntiLuce => arrayIsSet(puntiLuce) ? puntiLuce[0] : undefined)
            );
        }
    }

    edits$(values: any[], paginate: number | undefined = undefined): Observable<PaginationParserequestType<PuntiLuceParse>> {
        if (values == null) {
            return throwError(keyParseError.dataMissing);
        } else if (values.some(p => !stringIsSet(p.objectId))) {
            return throwError(keyParseError.objectIdNotValid);
        } else {
            if (paginate != null) {
                const fetchPage = (page = 0,) => {
                    let isLast = (page + 1) * paginate >= values.length;
                    const current = page * paginate;
                    const next = isLast ? (values.length) : (page + 1) * paginate;
                    const progress = Math.ceil(next / values.length * 100)
                    const lightPoints = values.slice(current, next);
                    const value$ = this.editPuntiLuce$(lightPoints);
                    return value$.pipe(
                        map((items) => {
                            return {
                                items: items,
                                progress: progress,
                                nextPage: isLast ? undefined : (page += 1),
                                finished: isLast,
                                error: null
                            };
                        }),
                        catchError(error => {
                            return of({
                                items: undefined,
                                progress: progress,
                                nextPage: isLast ? undefined : (page += 1),
                                finished: isLast,
                                error: error
                            });
                        }),
                    );
                }
                return fetchPage(0).pipe(
                    expand(({nextPage}) => nextPage ? fetchPage(nextPage) : EMPTY),
                )


            } else {
                return this.editPuntiLuce$(values).pipe(
                    map((pl) => {
                        return {
                            items: pl,
                            progress: 100,
                            nextPage: undefined,
                            finished: true,
                        }
                    })
                );
            }
        }
    }

    private deletePuntiLuce$(puntiLuceIds: string[]): Observable<ResponseDeleteLightPoints> {
        const params = paramsApiParse_v2({puntiLuceIds});
        return fromPromise(Parse.Cloud.run('deletePuntiLuce', params));
    }

    delete$(): Observable<ResponseDeleteLightPoints> {
        if (this.currentIdIsSet) {
            return this.deletePuntiLuce$([this.objectId])
        } else {
            return throwError(keyParseError.objectIdNotValid);
        }
    }

    deletes$(puntiLuceIds: string[], paginate: number | undefined = undefined): Observable<PaginationParserequestType<ResponseDeleteLightPoints>> {
        if (arrayIsSet(puntiLuceIds)) {
            if (paginate != null) {
                const fetchPage = (page = 0,) => {
                    let isLast = (page + 1) * paginate >= puntiLuceIds.length;
                    const current = page * paginate;
                    const next = isLast ? (puntiLuceIds.length) : (page + 1) * paginate;
                    const progress = Math.ceil(next / puntiLuceIds.length * 100)
                    const lightPoints = puntiLuceIds.slice(current, next);
                    const value$ = this.deletePuntiLuce$(lightPoints);
                    return value$.pipe(
                        map((items) => {
                            return {
                                items: [items],
                                progress: progress,
                                nextPage: isLast ? undefined : (page += 1),
                                finished: isLast,
                                error: null
                            };
                        }),
                        catchError(error => {
                            return of({
                                items: undefined,
                                progress: progress,
                                nextPage: isLast ? undefined : (page += 1),
                                finished: isLast,
                                error: error
                            });
                        }),
                    );
                }
                return fetchPage(0).pipe(
                    expand(({nextPage}) => nextPage ? fetchPage(nextPage) : EMPTY),
                )
            } else {
                return this.deletePuntiLuce$(puntiLuceIds).pipe(
                    map((pl) => {
                        return {
                            items: [pl],
                            progress: 100,
                            nextPage: undefined,
                            finished: true,
                        }
                    })
                );
            }
        } else {
            return throwError(keyParseError.objectIdNotValid);
        }
    }

}

export class PuntiLuceParse extends Parse.Object {

    public static CLASS_NAME = 'PuntiLuce';

    constructor() {
        super(PuntiLuceParse.CLASS_NAME);
    }

// icon non presente nel database creata per uniformare i codice come utilizzato
// prima della creazione delle classi
    private _icon;
    private _lampadaFunzionante;
    private _problemaDispositivoTelecontrollo;
    private _labelMarker;
    private _gruppiId;

    public get icon() {
        return this._icon;
    }

    public set icon(value) {
        this._icon = value;
    }

    public get labelMarker() {
        return this._labelMarker
    }

    public set labelMarker(value) {
        this._labelMarker = value;
    }

    public get lampadaFunzionante() {
        return this._lampadaFunzionante;
    }

    public setSegnale(segnale) {
        if (segnale) {
            this.lampadaFunzionante = segnale.lampadaFunzionante;
            this.problemaDispositivoTelecontrollo = segnale.problemaDispositivoTelecontrollo;
        }
    }

    public unsetSegnale() {
        this.lampadaFunzionante = undefined
        this.problemaDispositivoTelecontrollo = undefined
    }

    public set lampadaFunzionante(value) {
        this._lampadaFunzionante = value;
    }

    public get gruppiId() {
        return this._gruppiId;
    }

    public set gruppiId(value) {
        this._gruppiId = value;
    }

    addGruppoId(gruppoId: string) {
        if (arrayIsSet(this.gruppiId)) {
            if (!this.gruppiId.some(id => id === gruppoId)) {
                this.gruppiId.push(gruppoId);
            }
        } else {
            this.gruppiId = [gruppoId];
        }
    }

    public isSetGruppoId() {
        return Array.isArray(this.gruppiId) && this.gruppiId.length > 0
    }

    public set objectId(value) {
        this.id = value;
    }

    public get objectId() {
        return this.id;
    }


    public set problemaDispositivoTelecontrollo(value) {
        this._problemaDispositivoTelecontrollo = value
    }

    public get problemaDispositivoTelecontrollo() {
        return this._problemaDispositivoTelecontrollo;
    }

    public set attivo(value) {
        super.set('attivo', value);
    }

    public get attivo() {
        return super.get('attivo');
    }

    public set attaccoCorpoIlluminante(value) {
        super.set('attaccoCorpoIlluminante', value);
    }

    public get attaccoCorpoIlluminante() {
        return super.get('attaccoCorpoIlluminante');
    }


    public set bearingAngle(value) {
        super.set('bearingAngle', value);
    }

    public get bearingAngle() {
        return super.get('bearingAngle');
    }

    public set classe(value) {
        super.set('classe', value);
    }

    public get classe() {
        return super.get('classe');
    }

    public set canone(value) {
        super.set('canone', value);
    }

    public get canone() {
        return super.get('canone');
    }

    public set isBackupLight(value) {
        super.set('isBackupLight', value);
    }

    public get isBackupLight(): Date {
        return super.get('isBackupLight');
    }

    public set installationDate(value) {
        super.set('installationDate', value);
    }

    public get installationDate(): Date {
        return super.get('installationDate');
    }

    public set location(value) {
        super.set('location', value);
    }

    public get location(): Parse.GeoPoint {
        return super.get('location');
    }

    public set replacementDate(value) {
        super.set('replacementDate', value);
    }

    public get replacementDate(): Date {
        return super.get('replacementDate');
    }

    public set etaCorpoIlluminante(value) {
        super.set('etaCorpoIlluminante', value);
    }

    public get etaCorpoIlluminante() {
        return super.get('etaCorpoIlluminante');
    }

    public set etaSostegno(value) {
        super.set('etaSostegno', value);
    }

    public get etaSostegno() {
        return super.get('etaSostegno');
    }

    public set distribuzionePuntiLuce(value) {
        super.set('distribuzionePuntiLuce', value);
    }

    public get distribuzionePuntiLuce() {
        return super.get('distribuzionePuntiLuce');
    }

    public set fotoTipologia(value) {
        super.set('fotoTipologia', value);
    }

    public get fotoTipologia() {
        return super.get('fotoTipologia');
    }

    public set foto(value) {
        super.set('foto', value);
    }

    public get foto() {
        return super.get('foto');
    }

    public set progetto(value) {
        super.set('progetto', value);
    }

    public get progetto() {
        return super.get('progetto');
    }

    public set proprietario(value) {
        super.set('proprietario', value);
    }

    public get proprietario() {
        return super.get('proprietario');
    }

    public set lineaAlimentazione(value) {
        super.set('lineaAlimentazione', value);
    }

    public get lineaAlimentazione() {
        return super.get('lineaAlimentazione');
    }

    public set varianteLineaAlimentazione(value) {
        super.set('varianteLineaAlimentazione', value);
    }

    public get varianteLineaAlimentazione() {
        return super.get('varianteLineaAlimentazione');
    }

    public set altezza(value) {
        super.set('altezza', value);
    }

    public get altezza() {
        return super.get('altezza');
    }

    public set altezzaPalo(value) {
        super.set('altezzaPalo', value);
    }

    public get altezzaPalo() {
        return super.get('altezzaPalo');
    }

    public get circuito() {
        return super.get('circuito');
    }

    public set circuito(circuito) {
        super.set('circuito', circuito);
    }

    public get varianteCircuito() {
        return super.get('varianteCircuito');
    }

    public set varianteCircuito(value) {
        super.set('varianteCircuito', value);
    }


    public set potenzaTotale(value) {
        super.set('potenzaTotale', value);
    }

    public get potenzaTotale() {
        return super.get('potenzaTotale');
    }

    public set puntoLucePadre(value) {
        super.set('puntoLucePadre', value);
    }

    public get puntoLucePadre() {
        return super.get('puntoLucePadre');
    }

    public set larghezzaStrada(value) {
        super.set('larghezzaStrada', value);
    }

    public get larghezzaStrada() {
        return super.get('larghezzaStrada');
    }

    public set tiplogiaSorgenteLuminosa(value) {
        super.set('tiplogiaSorgenteLuminosa', value);
    }

    public get tiplogiaSorgenteLuminosa() {
        return super.get('tiplogiaSorgenteLuminosa');
    }

    public set tiplogiaCorpoIlluminante(value) {
        super.set('tiplogiaCorpoIlluminante', value);
    }

    public get tiplogiaCorpoIlluminante() {
        return super.get('tiplogiaCorpoIlluminante');
    }

    public set tipologiaSostegno(value) {
        super.set('tipologiaSostegno', value);
    }

    public get tipologiaSostegno() {
        return super.get('tipologiaSostegno');
    }

    public set tipologiaSezionePalo(value) {
        super.set('tipologiaSezionePalo', value);
    }

    public get tipologiaSezionePalo() {
        return super.get('tipologiaSezionePalo');
    }

    public set varianteTipologiaSostegno(value) {
        super.set('varianteTipologiaSostegno', value);
    }

    public get varianteTipologiaSostegno() {
        return super.get('varianteTipologiaSostegno');
    }

    public set ACL(value) {
        super.set('ACL', value);
    }

    public get ACL() {
        return super.get('ACL');
    }

    public set varianteModelloSorgenteLuminosa(value) {
        super.set('varianteModelloSorgenteLuminosa', value);
    }

    public get varianteModelloSorgenteLuminosa() {
        return super.get('varianteModelloSorgenteLuminosa');
    }

    public set modelloSorgenteLuminosa(value) {
        super.set('modelloSorgenteLuminosa', value);
    }

    public get modelloSorgenteLuminosa() {
        return super.get('modelloSorgenteLuminosa');
    }

    public set orientamentoAttaccoCorpoIlluminante(value) {
        super.set('orientamentoAttaccoCorpoIlluminante', value);
    }

    public get orientamentoAttaccoCorpoIlluminante() {
        return super.get('orientamentoAttaccoCorpoIlluminante');
    }

    public set oreRiduzioneFlusso(value) {
        super.set('oreRiduzioneFlusso', value);
    }

    public get oreRiduzioneFlusso(): number {
        return super.get('oreRiduzioneFlusso');
    }


    public set strada(value) {
        super.set('strada', value);
    }

    public get strada() {
        return super.get('strada');
    }


    public set numeroLampade(value) {
        super.set('numeroLampade', value);
    }

    public get numeroLampade() {
        return super.get('numeroLampade');
    }

    public set statoDiConservazione(value) {
        super.set('statoDiConservazione', value);
    }

    public get statoDiConservazione() {
        return super.get('statoDiConservazione');
    }

    public set statoCaricoEsogeno(value) {
        super.set('statoCaricoEsogeno', value);
    }

    public get statoCaricoEsogeno() {
        return super.get('statoCaricoEsogeno');
    }

    public set indirizzo(value) {
        super.set('indirizzo', value);
    }

    public get indirizzo() {
        return super.get('indirizzo');
    }

    public set artistico(value) {
        super.set('artistico', value);
    }

    public get artistico() {
        return super.get('artistico');
    }


    public set riferimentoSequenzialeCircuito(value) {
        super.set('riferimentoSequenzialeCircuito', value);
    }

    public get riferimentoSequenzialeCircuito() {
        return super.get('riferimentoSequenzialeCircuito');
    }

    public set variantePotenzaNominale(value) {
        super.set('variantePotenzaNominale', value);
    }

    public get variantePotenzaNominale() {
        return super.get('variantePotenzaNominale');
    }

    public set varianteNumeroLampade(value) {
        super.set('varianteNumeroLampade', value);
    }

    public get varianteNumeroLampade() {
        return super.get('varianteNumeroLampade');
    }

    public set potenzaNominale(value) {
        super.set('potenzaNominale', value);
    }

    public get potenzaNominale() {
        return super.get('potenzaNominale');
    }

    public set potenzaEffettiva(value) {
        super.set('potenzaEffettiva', value);
    }

    public get potenzaEffettiva() {
        return super.get('potenzaEffettiva');
    }

    public set variantePotenzaEffettiva(value) {
        super.set('variantePotenzaEffettiva', value);
    }

    public get variantePotenzaEffettiva() {
        return super.get('variantePotenzaEffettiva');
    }

    public set varianteFornitoreSorgenteLuminosa(value) {
        super.set('varianteFornitoreSorgenteLuminosa', value);
    }

    public get varianteFornitoreSorgenteLuminosa() {
        return super.get('varianteFornitoreSorgenteLuminosa');
    }

    public set fornitoreSorgenteLuminosa(value) {
        super.set('fornitoreSorgenteLuminosa', value);
    }

    public get fornitoreSorgenteLuminosa() {
        return super.get('fornitoreSorgenteLuminosa');
    }

    public set files(value) {
        super.set('files', value);
    }

    public get files(): Parse.Relation {
        const relation = this.relation('files')
        return relation;
    }

    public set realizzato(value) {
        super.set('realizzato', value);
    }

    public get realizzato() {
        return super.get('realizzato');
    }

    public set classificazioneStrada(value) {
        super.set('classificazioneStrada', value);
    }

    public get classificazioneStrada() {
        return super.get('classificazioneStrada');
    }

    public set note(value) {
        super.set('note', value);
    }

    public get note() {
        return super.get('note');
    }

    public set noteCorpoIlluminante(value) {
        super.set('noteCorpoIlluminante', value);
    }

    public get noteCorpoIlluminante() {
        return super.get('noteCorpoIlluminante');
    }

    public set noteSostegno(value) {
        super.set('noteSostegno', value);
    }

    public get noteSostegno() {
        return super.get('noteSostegno');
    }

    public set noteLinea(value) {
        super.set('noteLinea', value);
    }

    public get noteLinea() {
        return super.get('noteLinea');
    }

    public set presenzaCaricoEsogenoElettrico(value) {
        super.set('presenzaCaricoEsogenoElettrico', value);
    }

    public get presenzaCaricoEsogenoElettrico() {
        return super.get('presenzaCaricoEsogenoElettrico');
    }

    public set presenzaCutOff(value) {
        super.set('presenzaCutOff', value);
    }

    public get presenzaCutOff() {
        return super.get('presenzaCutOff');
    }

    public set presenzaMessaATerra(value) {
        super.set('presenzaMessaATerra', value);
    }

    public get presenzaMessaATerra() {
        return super.get('presenzaMessaATerra');
    }

    public set pozzettoPresente(value) {
        super.set('pozzettoPresente', value);
    }

    public get pozzettoPresente() {
        return super.get('pozzettoPresente');
    }

    public set percentualePotenzaInRiduzioneFlusso(value) {
        super.set('percentualePotenzaInRiduzioneFlusso', value);
    }

    public get percentualePotenzaInRiduzioneFlusso(): number {
        return super.get('percentualePotenzaInRiduzioneFlusso');
    }


    public set lastUpdateBy(value) {
        super.set('lastUpdateBy', value);
    }

    public get lastUpdateBy() {
        return super.get('lastUpdateBy');
    }

    public set matricolaCorpoIlluminante(value) {
        super.set('matricolaCorpoIlluminante', value);
    }

    public get matricolaCorpoIlluminante(): string {
        return super.get('matricolaCorpoIlluminante');
    }

    public set createdAt(value) {
        super.set('createdAt', value);
    }

    public get createdAt() {
        return super.get('createdAt');
    }

    public set tipologiaAreaIlluminata(value) {
        super.set('tipologiaAreaIlluminata', value);
    }

    public get tipologiaAreaIlluminata() {
        return super.get('tipologiaAreaIlluminata');
    }

    public set varianteSorgenteLuminosa(value) {
        super.set('varianteSorgenteLuminosa', value);
    }

    public get varianteSorgenteLuminosa() {
        return super.get('varianteSorgenteLuminosa');
    }

    public set varianteCorpoIlluminante(value) {
        super.set('varianteCorpoIlluminante', value);
    }

    public get varianteCorpoIlluminante() {
        return super.get('varianteCorpoIlluminante');
    }

    public set varianteFlussoLuminoso(value) {
        super.set('varianteFlussoLuminoso', value);
    }

    public get varianteFlussoLuminoso() {
        return super.get('varianteFlussoLuminoso');
    }


    public set alimentazioneLineaQuadro(value) {
        super.set('alimentazioneLineaQuadro', value);
    }

    public get alimentazioneLineaQuadro() {
        return super.get('alimentazioneLineaQuadro');
    }

    public set presenzaFotocellula(value) {
        super.set('presenzaFotocellula', value);
    }

    public get presenzaFotocellula() {
        return super.get('presenzaFotocellula');
    }

    public set statoDiConservazioneCorpoIlluminante(value) {
        super.set('statoDiConservazioneCorpoIlluminante', value);
    }

    public get statoDiConservazioneCorpoIlluminante() {
        return super.get('statoDiConservazioneCorpoIlluminante');
    }

    public set statoDiConservazioneSbraccio(value) {
        super.set('statoDiConservazioneSbraccio', value);
    }

    public get statoDiConservazioneSbraccio() {
        return super.get('statoDiConservazioneSbraccio');
    }

    public set tipologiaCaricoEsogeno(value) {
        super.set('tipologiaCaricoEsogeno', value);
    }

    public get tipologiaCaricoEsogeno() {
        return super.get('tipologiaCaricoEsogeno');
    }


    public set distanzaBordoCarreggiata(value) {
        super.set('distanzaBordoCarreggiata', value);
    }

    public get distanzaBordoCarreggiata() {
        return super.get('distanzaBordoCarreggiata');
    }

    public set lunghezzaSbraccio(value) {
        super.set('lunghezzaSbraccio', value);
    }

    public get lunghezzaSbraccio() {
        return super.get('lunghezzaSbraccio');
    }

    public set temperaturaColore(value) {
        super.set('temperaturaColore', value);
    }

    public get temperaturaColore() {
        return super.get('temperaturaColore');
    }

    public set flussoLuminoso(value) {
        super.set('flussoLuminoso', value);
    }

    public get flussoLuminoso() {
        return super.get('flussoLuminoso');
    }

    public set idGruppoLightMate(value) {
        super.set('idGruppoLightMate', value);
    }

    public get idGruppoLightMate(): number {
        return super.get('idGruppoLightMate');
    }

    public set IPEA2013(value) {
        super.set('IPEA2013', value);
    }

    public get IPEA2013() {
        return super.get('IPEA2013');
    }

    public set IPEA2018(value) {
        super.set('IPEA2018', value);
    }

    public get IPEA2018() {
        return super.get('IPEA2018');
    }

    public set materialeSbraccio(value) {
        super.set('materialeSbraccio', value);
    }

    public get materialeSbraccio() {
        return super.get('materialeSbraccio');
    }

    public set promiscuita(value) {
        super.set('promiscuita', value);
    }

    public get promiscuita() {
        return super.get('promiscuita');
    }

    public set proprietaAmministrazioneComunale(value) {
        super.set('proprietaAmministrazioneComunale', value);
    }

    public get proprietaAmministrazioneComunale() {
        return super.get('proprietaAmministrazioneComunale');
    }

    public set inPrivateProperty(value) {
        super.set('inPrivateProperty', value);
    }

    public get inPrivateProperty() {
        return super.get('inPrivateProperty');
    }

    public set idMezzanotte(value) {
        super.set('idMezzanotte', value);
    }

    public get idMezzanotte() {
        return super.get('idMezzanotte');
    }

    public set statoDiConservazionePozzetto(value) {
        super.set('statoDiConservazionePozzetto', value);
    }

    public get statoDiConservazionePozzetto() {
        return super.get('statoDiConservazionePozzetto');
    }

    public set tipologiaSorgenteLuminosa(value) {
        super.set('tipologiaSorgenteLuminosa', value);
    }

    public get tipologiaSorgenteLuminosa() {
        return super.get('tipologiaSorgenteLuminosa');
    }

    public set tipologiaCorpoIlluminante(value) {
        super.set('tipologiaCorpoIlluminante', value);
    }

    public get tipologiaCorpoIlluminante() {
        return super.get('tipologiaCorpoIlluminante');
    }

    public set updatedAt(value) {
        super.set('updatedAt', value);
    }

    public get updatedAt() {
        return super.get('updatedAt');
    }

    public set materialeSostegno(value) {
        super.set('materialeSostegno', value);
    }

    public get materialeSostegno() {
        return super.get('materialeSostegno');
    }

    public set statoDiConservazioneLineaAlimentazione(value) {
        super.set('statoDiConservazioneLineaAlimentazione', value);
    }

    public get statoDiConservazioneLineaAlimentazione() {
        return super.get('statoDiConservazioneLineaAlimentazione');
    }

    public set statoDiConservazioneCollarino(value) {
        super.set('statoDiConservazioneCollarino', value);
    }

    public get statoDiConservazioneCollarino() {
        return super.get('statoDiConservazioneCollarino');
    }

    public set tipologiaVetroChiusura(value) {
        super.set('tipologiaVetroChiusura', value);
    }

    public get tipologiaVetroChiusura() {
        return super.get('tipologiaVetroChiusura');
    }

    public set statoDiConservazioneMorsettiera(value) {
        super.set('statoDiConservazioneMorsettiera', value);
    }

    public get statoDiConservazioneMorsettiera() {
        return super.get('statoDiConservazioneMorsettiera');
    }


    public set materialePozzetto(value) {
        super.set('materialePozzetto', value);
    }

    public get materialePozzetto() {
        return super.get('materialePozzetto');
    }

    public set targa(value) {
        super.set('targa', value);
    }

    public get targa() {
        return super.get('targa');
    }

    public set targaCustom(value) {
        super.set('targaCustom', value);
    }

    public get targaCustom() {
        return super.get('targaCustom');
    }

    public getTarga() {
        if (stringIsSet(this.targaCustom)) {
            return this.targaCustom
        } else {
            return this.targa;
        }
    }

    public getTargaTraductionValue(): { traductions: string[], values: string[] } {
        if (stringIsSet(this.targa) && stringIsSet(this.targaCustom)) {
            return {
                traductions: ['license_plate', 'dashboard_sidenav.PuntiLuce.targaCustom.title'],
                values: [this.targa, this.targaCustom]
            }
        } else if (stringIsSet(this.targa)) {
            return {
                traductions: ['license_plate'],
                values: [this.targa]
            }
        } else if (stringIsSet(this.targaCustom)) {
            return {
                traductions: ['dashboard_sidenav.PuntiLuce.targaCustom.title'],
                values: [this.targaCustom]
            }
        } else {
            return undefined
        }
    }

    public set varianteTemperaturaColore(value) {
        super.set('varianteTemperaturaColore', value);
    }

    public get varianteTemperaturaColore() {
        return super.get('varianteTemperaturaColore');
    }

    public set varianteMaterialeSostegno(value) {
        super.set('varianteMaterialeSostegno', value);
    }

    public get varianteMaterialeSostegno() {
        return super.get('varianteMaterialeSostegno');
    }

    public set varianteIPEA2013(value) {
        super.set('varianteIPEA2013', value);
    }

    public get varianteIPEA2013() {
        return super.get('varianteIPEA2013');
    }

    public set varianteIPEA2018(value) {
        super.set('varianteIPEA2018', value);
    }

    public get varianteIPEA2018() {
        return super.get('varianteIPEA2018');
    }

    public set lotto(value) {
        super.set('lotto', value);
    }

    public get lotto() {
        return super.get('lotto');
    }

    public set isImported(value) {
        super.set('isImported', value);
    }

    public get isImported() {
        return super.get('isImported');
    }

    public set schedeManutenzione(value) {
        super.set('schedeManutenzione', value);
    }

    public get schedeManutenzione(): any[] {
        return super.get('schedeManutenzione');
    }

    /**
     *
     * @param arrayWitdhPuntiLuce se la classe è punti luce non bisogna passare la key
     * @param key il campo dove è contenuto il puntoLuce
     * @return ritorna se l' oggetto è presente e la sua posizione in arrayWitdhPuntiLuce
     */
    public includesInArray(arrayWitdhPuntiLuce: any [], key = null): { presente: boolean, indice: number } {
        let indice: number;
        return {
            presente: !arrayWitdhPuntiLuce.every((el, index) => {
                indice = index;
                if (!!key) {
                    return (!!el[key]) ? el[key].id != this.id : true
                } else {
                    return el.id != this.id;
                }
            }), indice: indice
        };
    }


    public hasProperty(key: string): boolean {
        return this.allProperty().includes(key);
    }

    public allProperty() {
        return allPropertyClass(PuntiLuceParse)
            .filter(key => key != 'query');
    }

    public allPropertyOnParse() {
        const notInParse = ['constructor', 'includesInArray', 'hasProperty', 'labelMarker', 'icon', 'allPropertyOnParse', 'allProperty', 'fromJson', 'query'];
        return this.allProperty().filter(key => !notInParse.includes(key))
    }


    public fromJson(json) {
        if (json != undefined) {
            this.allProperty().forEach(
                key => {
                    if (typeof json[key] != "function" && typeof this[key] != "function") {
                        this[key] = json[key]
                    }
                }
            )
        }
    }

    public get query() {
        return new Parse.Query(PuntiLuceParse)
    }
}

Parse.Object.registerSubclass(PuntiLuceParse.CLASS_NAME, PuntiLuceParse);
