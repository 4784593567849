import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormGroup} from '@angular/forms';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {MapService} from '../../../providers/services/map.service';
import {FilterDialogComponent} from '../filter-dialog/filter-dialog.component';
import {
    activeLightPointBoth,
    FilterFormService,
    FormFilterComponent
} from '../../../providers/forms/filter-form.service';
import {
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    Output,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
    Input,
    SimpleChanges
} from '@angular/core';
import {AlertService} from '../../../providers/services/alert.service';
import {PlansService} from '../../../providers/services/plans.service';
import {UserService} from '../../../providers/services/user.service';
import {PlanFormService} from '../../../providers/forms/plan-form.service';
import {FilterService} from '../../../providers/services/filter.service';
import {ProjectService} from '../../../providers/services/project.service';
import {TranslateService} from '@ngx-translate/core';
import {UserRole} from '../../../../config/static-data';
import {UtilsService} from '../../../providers/services/utils.service';
import {Subject, Subscription} from 'rxjs';
import {
    arrayIsSet,
    chiaviFiltri,
    isNotNullOrUndefined,
    objectsEqual,
    PossibleValuesFormField
} from 'src/app/models/Models';
import {pairwise, startWith} from 'rxjs/operators';
import {LineaElettricaParse} from '../../../models/LineaElettrica.Parse';

declare const google: any;

@Component({
    selector: 'app-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default, // forse viene ereditata dal padre
})
export class FilterComponent implements OnInit, OnDestroy {


    public readonly adressType = 'geocode';
    private readonly subscriptionFilterService;
    private readonly subscriptionFilterForm;
    private readonly subscriptionProject;
    private subscriptionFormGroup;

    public filterForm: UntypedFormGroup;
    // public mapFilter;
    // public numberFilter;
    public planForm: UntypedFormGroup;
    public user;
    public plan;
    public roles;
    public userRole = UserRole;
    public planPreviewLink;
    public loaded = false;
    @Input()
    public circuits;
    @Input()
    public initValue;
    @Input()
    public gruppiPuntiLuce = [];
    @Input()
    public electricLines = [];
    @Input()
    public arrediUrbani: any = {};
    @Input()
    public perPlanimetria = false;
    @Input()
    public circuitiSelezionati = [];
    @Input()
    private changeFilter: Subject<{ puntiLuce?: boolean, circuiti?: boolean, addElectricLines: LineaElettricaParse[] }> = new Subject();
    @Input()
    public sidenavIsOpened = true;
    @Input()
    public circuitiGruppiIsReady = false;
    private forceUpdate = false;


    private subscriptions: Subscription[] = [];
    isSetElectricLines = false;
    isSetArrediUrbani = false;
    isSeGruppiPuntiLuce = false;
    public tipologiaArrediurbani: any[];

    @Output() close = new Subject();

    @Output() public formValueChanges = new Subject<{ previusValue: FormFilterComponent, currentValue: FormFilterComponent }>();

    // public oldProjectId: string;

    public chiaviFiltri = chiaviFiltri;
    bothActiveDisactiveKey = activeLightPointBoth;

    constructor(private mapService: MapService,
                private filterFormService: FilterFormService,
                private filterService: FilterService,
                private userService: UserService,
                private matDialog: MatDialog,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private projectService: ProjectService,
                private planUserForm: PlanFormService,
                private plansService: PlansService,
                private alertService: AlertService,
                public dialog: MatDialog,
                public translate: TranslateService,
                private utilsService: UtilsService,
                private changeDetector: ChangeDetectorRef
    ) {

        this.filterForm = this.filterFormService.getForm();

        // if (this.filterService.getLocaleAddress()) {
        //     // this.filterForm.patchValue({address: this.filterService.getLocaleAddress()});
        // }
        // this.subscriptionFilterService = this.filterService.observableFilter().subscribe(() => {
        //     // this.initPage();
        // });
        // this.subscriptionProject = projectService.getObservable().subscribe(() => {
        //     this.initPage();
        // });
        // this.subscriptionFilterForm = this.filterForm.get('address').valueChanges.subscribe(() => this.mapService.destroyLatLng());
    }

    get isUtente() {
        return this.projectService.isUtente();
    }

    ngOnInit() {
        const subscription = this.changeFilter.asObservable().subscribe(
            changeValues => {
                this.forceUpdate = true;
                if (changeValues.puntiLuce) {
                    this.onChangeMassive('gruppiPuntiLuce', 'deselectAll');
                    this.onChangeMassive('lightPoints', 'deselectAll');
                } else if (changeValues.puntiLuce === false) {
                    this.onChangeMassive('lightPoints', 'selectAll');
                    this.onChangeMassive('gruppiPuntiLuce', 'selectAll');
                }
                if (changeValues.circuiti) {
                    this.onChangeMassive('circuits', 'deselectAll');
                }
                if (arrayIsSet(changeValues.addElectricLines)) {
                    this.filterForm.get('electricLines').setValue(changeValues.addElectricLines);
                }
            }
        );
        this.subscriptions.push(subscription);
        this.initSubscription();
    }

    private resetValueForm() {
        if (this.initValue != null) {
            Object.keys(this.initValue)
                .filter(key => this.initValue[key] != null)
                .forEach(key => {
                    this.filterForm.get(key).setValue(this.initValue[key], {emitEvent: false});
                });
            setTimeout(() => {
                this.filterForm.updateValueAndValidity();
            });
        } else {
            this.initFormValue();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.circuits != null) {
            this.resetValueForm();
        }

        if (changes.electricLines) {
            this.isSetElectricLines = arrayIsSet(this.electricLines);
        }
        if (changes.arrediUrbani) {
            this.isSetArrediUrbani = arrayIsSet(this.arrediUrbani.tipologia);
            if (this.isSetArrediUrbani) {
                this.tipologiaArrediurbani = [...this.arrediUrbani.tipologia];
            }
        }
        if (changes.gruppiPuntiLuce) {
            this.isSeGruppiPuntiLuce = arrayIsSet(this.gruppiPuntiLuce);
        }

    }

    public get numberFilter() {
        return Array.isArray(this.filterForm.get('advancedFilter').value) ? this.filterForm.get('advancedFilter').value.length : 0;
    }

    ngOnDestroy() {
        if (this.subscriptionFilterService) {
            this.subscriptionFilterService.unsubscribe();
        }
        if (this.subscriptionFilterForm) {
            this.subscriptionFilterForm.unsubscribe();
        }
        if (this.subscriptionProject) {
            this.subscriptionProject.unsubscribe();
        }
        if (this.subscriptionFormGroup) {
            this.subscriptionFormGroup.unsubscribe();
        }
        this.subscriptions.forEach(
            sub => sub.unsubscribe()
        );
    }


    private initFormValue() {
        if (this.filterService.hasAddress()) {
            this.filterForm.get('address').setValue(this.filterService.getLocaleAddress(), {emitEvent: false});
        }
        // if (this.filterService.hasPuntiStampa()) {
        //     this.filterForm.get('puntiStampa').setValue(this.filterService.getLocalePuntiStampa(), {emitEvent: false});
        // }
        if (this.filterService.hasConfiniAmministrativi()) {
            this.filterForm.get('confiniAmministrativi').setValue(this.filterService.getLocaleConfiniAmministrativi(), {emitEvent: false});
        } else if (!this.perPlanimetria) {
            this.filterForm.get('confiniAmministrativi').setValue([this.chiaviFiltri.confiniAmministrativi], {emitEvent: false});
        } else {
            this.filterForm.get('confiniAmministrativi').setValue(null, {emitEvent: false});
        }
        if (this.filterService.hasLightPoints()) {
            this.filterForm.get('lightPoints').setValue(this.filterService.getLocaleLightPoints(), {emitEvent: false});
        }
        if (this.filterService.hasCircuits()) {
            this.filterForm.get('circuits').setValue(this.filterService.getLocaleCircuits(), {emitEvent: false});
        } else if (this.circuits != undefined && !this.perPlanimetria) {
            const circuiti = this.circuits.map(el => el.formValues);
            this.filterForm.get('circuits').setValue(circuiti);
        } else if (this.circuitiSelezionati.length > 0) {
            this.filterForm.get('circuits').setValue(this.circuitiSelezionati, {emitEvent: false});
        }
        if (this.filterService.getLocaleFilter()) {
            this.filterForm.get('advancedFilter').setValue(this.filterService.getLocaleFilter(), {emitEvent: false});
        }
    }

    private initSubscription() {
        // this.filterForm.reset({}, {emitEvent: false});
        // this.loadData();
        if (!isNotNullOrUndefined(this.subscriptionFormGroup)) {
            this.formValueChanges.next({previusValue: undefined, currentValue: this.filterForm.value});
            this.subscriptionFormGroup = this.filterForm.valueChanges
                .pipe(
                    startWith(this.filterForm.value),
                    pairwise())
                .subscribe(([prev, next]: [FormFilterComponent, FormFilterComponent]) => {
                    if (!isNotNullOrUndefined(prev) || this.forceUpdate) {
                        this.onFilter();
                        this.formValueChanges.next({previusValue: prev, currentValue: next});
                        this.forceUpdate = false;
                    } else if (isNotNullOrUndefined(prev) && !objectsEqual(prev, next)) {
                        this.onFilter();
                        this.formValueChanges.next({previusValue: prev, currentValue: next});
                    }

                });
        }
        this.loaded = true;
    }


    onChangeMassive(listName: 'circuits' | 'lightPoints' | 'gruppiPuntiLuce', fx: 'selectAll' | 'deselectAll') {
        if (fx === 'selectAll' && listName == 'circuits') {
            this.filterForm.get(listName).setValue(this.circuits.map(el => el.formValues));
        } else if (fx === 'selectAll' && listName == 'lightPoints') {
            this.filterForm.get(listName).setValue(this.circuits.map(el => el.formValues).concat(this.chiaviFiltri.nessunQuadro));
        } else if (fx === 'selectAll' && listName == 'gruppiPuntiLuce') {
            this.filterForm.get(listName).setValue(this.gruppiPuntiLuce.map(el => el.formValues));
        } else {
            this.filterForm.get(listName).reset();
        }
    }


    public closeSidenav() {
        this.close.next();
    }

    public openFiltersDialog() {
        this.utilsService.lockBody();
        const dialog = this.matDialog
            .open(FilterDialogComponent, {data: {localFilter: this.filterService.getLocaleFilter()}});
        dialog.afterClosed().subscribe((advancedFilters) => {
            if (isNotNullOrUndefined(advancedFilters)) {
                this.filterForm.get('advancedFilter').setValue(advancedFilters);
            }
            // this.onFilter();
            this.utilsService.unlockBody();
        });
    }

    public onFilter() {
        try {
            this.filterService.destroyLocaleAddress();
            this.filterService.setLocaleLightPoints(this.filterForm.get('lightPoints').value);
            this.filterService.setLocaleCircuits(this.filterForm.get('circuits').value);
            // this.filterService.setLocalePuntiStampa(this.filterForm.get('puntiStampa').value);
            this.filterService.setLocaleConfiniAmministrativi(this.filterForm.get('confiniAmministrativi').value);
        } catch (e) {
        }
        this.filterService.emitFilter({event: 'filter'});
    }

    public setAddress(event) {
        if (event != null) {
            this.filterForm.get('address').setValue(event, {emitEvent: false});
        } else {
            this.filterForm.get('address').reset(null, {emitEvent: false});
        }
        this.onSearch();
    }

    public onSearch() {
        try {
            this.filterForm.get('address').updateValueAndValidity({emitEvent: true, onlySelf: false});
            this.filterService.setLocaleAddress(this.filterForm.get('address').value);
            const value = this.filterForm.get('address').value;
            if (value != null && value.lat != null && value.lng != null) {
                this.mapService.setLat(this.filterForm.get('address').value.lat);
                this.mapService.setLng(this.filterForm.get('address').value.lng);
            } else {
                this.filterService.destroyLocaleAddress();
            }
        } catch (e) {
        }
        this.filterService.emitFilter({event: 'search'});
    }

    public destroyFilter() {
        this.filterService.destroyAllFilter();
        this.mapService.destroyAllFilter();
    }

    // private getPlaceAutocomplete() {
    //     if (isNotNullOrUndefined(this.addressElement)) {
    //         const sub = new google.maps.places.Autocomplete(this.addressElement.nativeElement,
    //             {
    //                 types: [this.adressType],  // 'establishment' / 'address' / 'geocode'
    //                 strictBounds: true
    //             });
    //         sub.addListener('place_changed', () => {
    //             const place = sub.getPlace();
    //             const formattedAddress = place.formatted_address;
    //             if (place.geometry) {
    //                 this.mapService.setLat(place.geometry.location.lat());
    //                 this.mapService.setLng(place.geometry.location.lng());
    //             } else {
    //                 this.mapService.destroyLatLng();
    //             }
    //             this.filterForm.get('address').setValue({
    //                 lat: place.geometry.location.lat(),
    //                 lng: place.geometry.location.lng()
    //             }, {emitEvent: true});
    //         });
    //     }
    // }

    public getIsSetArredoUrbanoWithKey(key) {
        return isNotNullOrUndefined(this.arrediUrbani) && isNotNullOrUndefined(this.arrediUrbani[key]);
    }

    public multipleSelectChange(key, value: string[]) {
        this.filterForm.get(key).setValue(value);
    }


    getTranslateArrediUrbani(key, keyToDisplay, keyToTraduction) {
        return this.arrediUrbani[key].map(item => {
            const translate = this.translate.instant('dashboard_sidenav.ArredoUrbano.' + keyToDisplay + '.possibleValues.' + item[keyToDisplay]);
            item[keyToTraduction] = translate.includes('dashboard_sidenav.ArredoUrbano') ? item[keyToDisplay] : translate;
            return item;
        });

    }

    getElectricLinePredicateFunction(lineeElettriche: LineaElettricaParse[]): PossibleValuesFormField[] {
        return lineeElettriche.map(linea => {
            return {
                objectId: linea.objectId,
                noTraduction: linea.name,
                registerValue: linea
            };
        });
    }

    getArredoUrbanoPossibleValuesPredicateFunction(arrediUrbani: any, translateService): PossibleValuesFormField[] {
        return arrediUrbani.map(value => {
                const translate = translateService.instant('dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.' + value.tipologia);
                let obj: PossibleValuesFormField;
                if (translate.includes('dashboard_sidenav.ArredoUrbano')) {
                    obj = {objectId: value.objectId, noTraduction: value.tipologia, registerValue: value.tipologia};
                } else {
                    obj = {
                        objectId: value.objectId,
                        traduction: 'dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.' + value.tipologia,
                        registerValue: value.tipologia
                    };
                }
                return obj;
            }
        );
    }

    getGruppiPiuntiLucePossibleValuesPredicateFunction(gruppi: any): PossibleValuesFormField[] {
        return gruppi.map(value => {
                const obj: PossibleValuesFormField = {
                    objectId: value.formValues,
                    registerValue: value.formValues,
                    noTraduction: value.formHtml
                };
                return obj;
            }
        );
    }

    // public circuitOfLightPointsOptIsSelected(objId): boolean {
    //     if (this.filterService.hasLightPoints()) {
    //         return this.filterService.getLocaleLightPoints().find(el => el === objId);
    //     } else {
    //         return false;
    //     }
    // }
    //

    // public circuitOptIsSelected(objId): boolean {
    //     if (this.filterService.hasCircuits()) {
    //         return this.filterService.getLocaleCircuits().find(el => el === objId);
    //     }
    //     return true;
    // }
    //
    // public puntiStampaOptIsSelected(objId): boolean {
    //     if (this.filterService.hasPuntiStampa()) {
    //         return !!this.filterService.getLocalePuntiStampa().find(el => el === objId);
    //     }
    //     return false;
    // }
    //
    // public confiniAmministrativiOptIsSelected(objId): boolean {
    //     if (this.filterService.hasConfiniAmministrativi) {
    //         return !!this.filterService.getLocaleConfiniAmministrativi().find(el => el === objId);
    //     }
    //     return false;
    // }

    // public selectionChange(select: 'circuitsElement' | 'lightPointsElement' | 'puntiStampaElement' | 'confiniAmministrativiElement') {
    //     const values = this[select].selectedOptions.selected.map(el => el.value);
    //     this.changeDetector.detectChanges();
    //     switch (select) {
    //         case 'circuitsElement':
    //             this.filterForm.get('circuits').setValue(values);
    //             this.onFilter();
    //             return;
    //         case 'lightPointsElement':
    //             this.filterForm.get('lightPoints').setValue(values);
    //             this.onFilter();
    //             return;
    //         case 'puntiStampaElement':
    //             this.filterForm.get('puntiStampa').setValue(values);
    //             this.onFilter();
    //             return;
    //         case 'confiniAmministrativiElement':
    //             this.filterForm.get('confiniAmministrativi').setValue(values);
    //             this.onFilter();
    //             return;
    //     }
    // }

    // public async addFilterToPlan(planId) {
    //     try {
    //         await this.plansService.saveFilterOnPlan(planId);
    //         this.alertService.success(this.translate.instant('success_plan_save'));
    //         this.router.navigate(['/planimetrie/modifica/' + planId]);
    //     } catch (e) {
    //         this.alertService.error(this.translate.instant('error') + ' ' + e.code + ' ' + e.message);
    //     }
    // }

    // public async createPlan() {
    //     if (this.planForm.invalid) {
    //         this.alertService.error('Compilare i campi richiesti');
    //     } else {
    //         try {
    //             if (await this.projectService.getActiveProject() == true) {
    //                 const res = await this.plansService.createPlan(null, this.planForm.value.name);
    //                 this.alertService.success(this.translate.instant('success_plan_save'));
    //                 this.router.navigate(['/planimetrie/modifica/' + res.objectId]);
    //             } else {
    //                 this.utilsService.lockBody();
    //                 const dialogRef = this.dialog.open(InfoLockedPlanDialogComponent, {
    //                     width: '600px',
    //                     data: {
    //                         userSession: this.userService.sessionToken()
    //                     }
    //                 });
    //                 dialogRef.afterClosed().subscribe(res => {
    //                     if (res) {
    //                         this.loadData();
    //                     }
    //                     this.utilsService.unlockBody();
    //                 });
    //             }
    //         } catch (e) {
    //             this.alertService.error(this.translate.instant('error') + ' ' + e.code + ' ' + e.message);
    //         } finally {
    //         }
    //     }
    // }


    // private async loadData() {
    //     this.user = this.userService.user();
    //     const planId = this.activatedRoute.snapshot.queryParams.planId;
    //     if (planId) {
    //         this.plan = await this.plansService.getPlan(planId);
    //         this.planPreviewLink = await this.plansService.previewLink(planId);
    //     } else {
    //         this.planForm = this.planUserForm.getCreatePlanForm();
    //     }
    // }
    //
    //
    // public get isGestore() {
    //     return this.projectService.isGestore()
    // }
    //
    // public get isUser() {
    //     return this.projectService.isUser()
    // }


}
