import {Component, NgZone, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {UserService} from "../../../providers/services/user.service";
import {Router} from "@angular/router";
import {ProjectService} from "../../../providers/services/project.service";
import {MapService} from "../../../providers/services/map.service";
import {FilterService} from "../../../providers/services/filter.service";
import {ProgettiParse} from "../../../models/Progetti.Parse";
import {MatSidenav} from "@angular/material/sidenav";
import {SidenavSelectProjectService} from "../../../providers/services/sidenav-select-project.service";
import {NavbarService} from "../../../providers/services/navbar.service";
import {LocalSotrageService} from "../../../providers/services/local-sotrage.service";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {Observable, Subscription} from "rxjs";
import {
    arrayIsSet,
    cleanToSpecialCharacterAndLowerCaseString,
    isNotNullOrUndefined,
    stringIsSet
} from "../../../models/Models";
import {map, startWith} from "rxjs/operators";
import * as Parse from "parse";

@Component({
    selector: 'app-select-project',
    templateUrl: './select-project.component.html',
    styleUrls: ['./select-project.component.scss']
})

export class SelectProjectComponent implements OnInit, OnDestroy {

    @ViewChild('sidenavProject', {static: true}) sidenavProject: MatSidenav;
    public filterProject: UntypedFormGroup;
    public valueFilter: string;
    public valueFilter$: Observable<string>;
    public colorIconSearch = '';
    public projects$: Observable<ProgettiParse[]>;
    public loading = true;
    public roles = [];
    public progettoSelezionato;
    public isOpenModifySidenav = true;

    constructor(
        private userService: UserService,
        private router: Router,
        private projectService: ProjectService,
        private mapService: MapService,
        private filterService: FilterService,
        private sidenavService: SidenavSelectProjectService,
        private navbarService: NavbarService,
        private localStorage: LocalSotrageService,
        private formBuilder: UntypedFormBuilder,
    ) {
        localStorage.removeAll();
        this.navbarService.disabledHamburger = true;
        this.filterProject = this.formBuilder.group(
            {
                name: this.valueFilter
            }
        )
        this.valueFilter$ = this.filterProject.get('name').valueChanges.pipe(
            startWith(undefined),
            map(() => this.filterProject.get('name').value),
        )
        this.projects$ = this.userService.userProjects$.pipe(
            map(projects => {
                if (arrayIsSet(projects)) {
                    return projects
                } else {
                    return undefined;
                }
            })
        )
        this.userService.updateUserProject();
    }


    isSetValueFilter() {
        return !(isNotNullOrUndefined(this.valueFilter) && this.valueFilter.length > 0);
    }

    changeColorIcon(color: 'primary' | '') {
        this.colorIconSearch = color;
    }


    ngOnInit() {
        this.mapService.destroyAllFilter();
        this.filterService.destroyAllFilter();
        this.sidenavService.setSidenav(this.sidenavProject);
    }


    public get gestore() {
        return this.projectService.isGestore();
    }


    ngOnDestroy(): void {
        this.navbarService.disabledHamburger = false;
    }

    isGestorePredicate(progetto: ProgettiParse) {
        if (arrayIsSet(progetto.gestori)) {
            return progetto.gestori.some((gestore) => gestore.id == Parse.User.current().id);
        } else {
            return false;
        }
    }

    filterPredicate(projects: ProgettiParse[], key: string) {
        if (arrayIsSet(projects) && (stringIsSet(key))) {
            const cleanedKey = cleanToSpecialCharacterAndLowerCaseString(key);
            return projects.filter(project => {
                const name = cleanToSpecialCharacterAndLowerCaseString(project.name);
                const sigla = cleanToSpecialCharacterAndLowerCaseString(project.sigla);
                return name.includes(cleanedKey) || sigla.includes(cleanedKey)
            })
        } else {
            return projects
        }
    }

    projectApp(id, role, project) {
        this.projectService.setProjectLocal(id, project);
        if (project.firstViewOnOpening === 'TELECONTROLLO') {
            this.router.navigate(['/controllo-remoto']);
        } else {
            this.router.navigate(['/']);

        }
    }


    openForm(event, progetto) {
        event.stopPropagation();
        this.isOpenModifySidenav = true;
        this.progettoSelezionato = progetto;
        this.sidenavService.open();
    }

    openNewProjectSidenav() {
        this.isOpenModifySidenav = false;
        this.progettoSelezionato = undefined;
        this.sidenavService.open();
    }

    public newProjectInput(progetto: ProgettiParse) {
        this.userService.addNewProject(progetto);
    }

    public filtraProgetto(progetto) {
        this.userService.removeProject(progetto);
    }


}
