import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {

    private readonly ID_MAX = 100000;
    private readonly ID_MIN = 1;

    private subjectLoader: Subject<any> = new Subject();
    private loaderList: any[] = [];

    constructor() {
    }

    public getObservable(): Observable<any> {
        return this.subjectLoader.asObservable();
    }

    public addLoader(): number {
        const id = Math.floor(Math.random() * (this.ID_MAX - this.ID_MIN + 1)) + 1;
        this.loaderList.push(id);
        this.updateLoader();
        return id;
    }

    public removeLoader(loaderId: number) {
        const index = this.loaderList.findIndex(el => el === loaderId);
        this.loaderList.splice(index, 1);
        this.updateLoader();
    }

    private updateLoader() {
        this.subjectLoader.next(this.loaderList.length > 0);
    }
}

