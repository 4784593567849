import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LightMateGroupComponent} from './light-mate-group/light-mate-group.component';
import {WidgetsModule} from "../../widgets/widgets.module";
import {CoreModule} from "../../core/core.module";
import {TranslateModule} from "@ngx-translate/core";
import {MatStepperModule} from "@angular/material/stepper";
import {ReactiveFormsModule} from "@angular/forms";
import {MatLegacyFormFieldModule as MatFormFieldModule} from "@angular/material/legacy-form-field";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import {MatIconModule} from "@angular/material/icon";
import {MatLegacyInputModule as MatInputModule} from "@angular/material/legacy-input";
import {MatLegacySliderModule as MatSliderModule} from "@angular/material/legacy-slider";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {MatLegacySelectModule as MatSelectModule} from "@angular/material/legacy-select";
import {MatLegacySlideToggleModule as MatSlideToggleModule} from "@angular/material/legacy-slide-toggle";
import {PipesModule} from "../../providers/pipes/pipes.module";
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from "@angular/material/legacy-progress-spinner";
import {MatGridListModule} from "@angular/material/grid-list";
import {MatExpansionModule} from '@angular/material/expansion';
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import { FormLightMateComponent } from './form-light-mate/form-light-mate.component';
import {MatLegacyMenuModule as MatMenuModule} from "@angular/material/legacy-menu";
import {ClipboardModule} from "@angular/cdk/clipboard";
import {DirectivesModule} from "../../providers/directives/directives.module";
import {BoxesModule} from "../../boxes/boxes.module";
import {MatLegacyListModule as MatListModule} from "@angular/material/legacy-list";
import {MatLegacyRadioModule as MatRadioModule} from "@angular/material/legacy-radio";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatCheckboxModule} from "@angular/material/checkbox";

@NgModule({
    declarations: [LightMateGroupComponent, FormLightMateComponent],
    imports: [
        CommonModule,
        WidgetsModule,
        CoreModule,
        TranslateModule,
        MatStepperModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatSliderModule,
        MatDatepickerModule,
        NgxMaterialTimepickerModule,
        MatSelectModule,
        MatSlideToggleModule,
        PipesModule,
        MatProgressSpinnerModule,
        MatGridListModule,
        MatExpansionModule,
        MatCardModule,
        MatMenuModule,
        ClipboardModule,
        DirectivesModule,
        BoxesModule,
        MatListModule,
        MatRadioModule,
        MatButtonToggleModule,
        MatCheckboxModule,


    ],
    exports: [LightMateGroupComponent, FormLightMateComponent]
})
export class VirtualMidNightModule {
}
