<div class="flex">
    <div class="data" style="text-align: center;">
        <H4>
            <img class="icon-dimension" src="/assets/icon/dialogCompnent/ask.png">
        </H4>
        <h5 *ngFor="let message of data.messages" style="margin-top: -5px">{{message |translate}}</h5>
        <button mat-icon-button [mat-dialog-close]="false">
            <mat-icon>close</mat-icon>
        </button>
        <button mat-icon-button color="warn" [mat-dialog-close]="true">
            <mat-icon>check</mat-icon>
        </button>
    </div>
</div>