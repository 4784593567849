<ng-container *ngIf=" !!arrayPerTable">
    <form [formGroup]="formFilter" *ngIf="!!formFilter">
        <ng-container *ngFor="let data of arrayPerTable ">
            <ng-container *ngIf="data.dataTable.length>0">
                <mat-toolbar color="primary">
                    <mat-toolbar-row>
                        <span style="color: #2196f3">{{data.title|translate}}</span>
                    </mat-toolbar-row>
                </mat-toolbar>
                <table mat-table [dataSource]="data.dataTable" class="mat-elevation-z8">
                    <ng-container matColumnDef="a">
                        <td mat-cell *matCellDef="let element">
                            <ng-container [ngSwitch]="element.type">
                                <div [ngStyle]="{background:getColor(element.error,element.key)}"
                                     style="width: 100%;height: 20px;border-radius: 3px;"></div>
                                <ng-container *ngSwitchCase="'GEO_POINT'">
                                    <ng-container [formGroupName]="element.key">
                                        <mat-form-field appearance="fill">
                                            <mat-label>{{'latitude'|translate}}</mat-label>
                                            <input matInput formControlName="latitude">
                                            <button
                                                    matSuffix mat-icon-button class="small"
                                                    [disabled]="formFilter.get(element.key).get('latitude').disabled"
                                                    (click)="$event.stopPropagation();eliminaCampo(element.key)">
                                                <mat-icon>clear</mat-icon>
                                            </button>
                                        </mat-form-field>
                                        <mat-form-field appearance="fill">
                                            <mat-label>{{'longitude'|translate}}</mat-label>
                                            <input matInput formControlName="longitude">
                                            <button
                                                    matSuffix mat-icon-button class="small"
                                                    [disabled]="formFilter.get(element.key).get('longitude').disabled"
                                                    (click)="$event.stopPropagation();eliminaCampo(element.key)">
                                                <mat-icon>clear</mat-icon>
                                            </button>
                                        </mat-form-field>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngSwitchCase="'ELENCO'">
                                    <mat-form-field appearance="fill">
                                        <mat-label>{{getPathTraduction(element.key)|translate}}</mat-label>
                                        <mat-select [formControlName]="element.key">
                                            <mat-option *ngFor="let possibleValue of element.possibleValues"
                                                        [value]="possibleValue">
                                                {{((getPathTraductionPossibleValue(element.key) + (possibleValue |transformForTranslate)) |translate)|notPresentInTraduction:possibleValue:'dashboard_sidenav'}}
                                            </mat-option>
                                        </mat-select>
                                        <button
                                                matSuffix mat-icon-button class="small"
                                                [disabled]="formFilter.get(element.key).disabled"
                                                (click)="$event.stopPropagation();eliminaCampo(element.key)">
                                            <mat-icon>clear</mat-icon>
                                        </button>
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngSwitchCase="'BOOL'">
                                    <mat-form-field appearance="fill">
                                        <mat-label>{{getPathTraduction(element.key)|translate}}</mat-label>
                                        <mat-select [formControlName]="element.key">
                                            <mat-option *ngFor="let possibleValue of element.possibleValues"
                                                        [value]="possibleValue">
                                                {{((getPathTraductionPossibleValue(element.key) + (possibleValue |transformForTranslate)) |translate)|notPresentInTraduction:possibleValue:'dashboard_sidenav'}}
                                            </mat-option>
                                        </mat-select>
                                        <button
                                                matSuffix mat-icon-button class="small"
                                                (click)="$event.stopPropagation();eliminaCampo(element.key)">
                                            <mat-icon>clear</mat-icon>
                                        </button>
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngSwitchCase="'NUMBER'">
                                    <mat-form-field appearance="fill">
                                        <mat-label>{{getPathTraduction(element.key)|translate}}</mat-label>
                                        <ng-container *ngIf="!element.possibleValues;else noPossibleValue">
                                            <input matInput type="number" step="0.01" [formControlName]="element.key">
                                        </ng-container>
                                        <ng-template #noPossibleValue>
                                            <mat-select [formControlName]="element.key">
                                                <mat-option *ngFor="let possibleValue of element.possibleValues"
                                                            [value]="possibleValue">
                                                    {{((getPathTraductionPossibleValue(element.key) + (possibleValue |transformForTranslate)) |translate)|notPresentInTraduction:possibleValue:'dashboard_sidenav'}}
                                                </mat-option>
                                            </mat-select>
                                        </ng-template>
                                        <button
                                                matSuffix mat-icon-button class="small"
                                                [disabled]="formFilter.get(element.key).disabled"
                                                (click)="$event.stopPropagation();eliminaCampo(element.key)">
                                            <mat-icon>clear</mat-icon>
                                        </button>
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngSwitchCase="'INT'">
                                    <mat-form-field appearance="fill">
                                        <mat-label>{{getPathTraduction(element.key)|translate}}</mat-label>
                                        <ng-container *ngIf="!element.possibleValues;else noPossibleValue">
                                            <input matInput type="number" step="1" [formControlName]="element.key">
                                        </ng-container>
                                        <ng-template #noPossibleValue>
                                            <mat-select [formControlName]="element.key">
                                                <mat-option *ngFor="let possibleValue of element.possibleValues"
                                                            [value]="possibleValue">
                                                    {{((getPathTraductionPossibleValue(element.key) + (possibleValue |transformForTranslate)) |translate)|notPresentInTraduction:possibleValue:'dashboard_sidenav'}}
                                                </mat-option>
                                            </mat-select>
                                        </ng-template>
                                        <button
                                                matSuffix mat-icon-button class="small"
                                                [disabled]="formFilter.get(element.key).disabled"
                                                (click)="$event.stopPropagation();eliminaCampo(element.key)">
                                            <mat-icon>clear</mat-icon>
                                        </button>
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngSwitchCase="'TARGA'">
                                    <mat-form-field appearance="fill">
                                        <mat-label>{{getPathTraduction(element.key)|translate}}</mat-label>
                                        <input matInput type="text" [formControlName]="element.key">
                                        <mat-hint *ngIf="isPresent(element.key)">{{'numeroTargaIsPresent'|translate}}</mat-hint>
                                        <button
                                                matSuffix mat-icon-button class="small"
                                                [disabled]="formFilter.get(element.key).disabled"
                                                (click)="$event.stopPropagation();eliminaCampo(element.key)">
                                            <mat-icon>clear</mat-icon>
                                        </button>
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    <mat-form-field appearance="fill">
                                        <mat-label>{{getPathTraduction(element.key)|translate}}</mat-label>
                                        <textarea matInput [formControlName]="element.key"
                                                  cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                                  cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5">
                                </textarea>
                                        <button
                                                matSuffix mat-icon-button class="small"
                                                [disabled]="formFilter.get(element.key).disabled"
                                                (click)="$event.stopPropagation();eliminaCampo(element.key)">
                                            <mat-icon>clear</mat-icon>
                                        </button>
                                    </mat-form-field>
                                </ng-container>
                            </ng-container>
                        </td>
                    </ng-container>
                    <tr mat-row *matRowDef="let row; columns: ['a'];"></tr>
                </table>
            </ng-container>
        </ng-container>
    </form>
</ng-container>
<button (click)="closeSidenav()" class="fab" color="accent" fab mat-mini-fab
        matTooltip="{{'button.close' | translate}}"
        style="z-index: 10000" type="button">
    <mat-icon>close</mat-icon>
</button>
<button class="fab" color="accent" [disabled]="disabledButton" fab mat-mini-fab
        matTooltip="{{'button.save' | translate}}" style="z-index: 10000;margin-right: 3rem;" type="button"
        (click)="updateSingle()">
    <mat-icon>save</mat-icon>
</button>
<button class="fab" color="accent" [disabled]="false" fab mat-mini-fab
        matTooltip="{{'button.delete' | translate}}" style="z-index: 10000;margin-right: 6rem;" type="button"
        (click)="deleteSingle()">
    <mat-icon>delete</mat-icon>
</button>
