import {Injectable} from '@angular/core';
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {AddFormFieldComponent} from "../../components/pop-up-create-new/add-form-field/add-form-field.component";
import {Observable} from "rxjs";
import {formFiledMaintenance} from "./maintenance.service";
import {
    NewElectricLineDetailComponent
} from "../../components/pop-up-create-new/new-electric-line-detail/new-electric-line-detail.component";

@Injectable({
    providedIn: 'root'
})
export class DialogPopUpCreateNewService {

    constructor(private dialog: MatDialog) {

    }

    public openAddFormField(traductionSaved: string[], fieldValue: formFiledMaintenance = undefined): Observable<{ value: formFiledMaintenance, openNew: boolean }> {
        const dialogRef = this.dialog.open(AddFormFieldComponent, {
            width: '500px',
            maxWidth: '100%',
            maxHeight: '100%',
            data: {traductionSaved, fieldValue}
        });
        return dialogRef.afterClosed();
    }

    public openNewElectricLine(value): Observable<{ value: formFiledMaintenance, openNew: boolean }> {
        const dialogRef = this.dialog.open(NewElectricLineDetailComponent, {
            width: '500px',
            maxWidth: '100%',
            maxHeight: '100%',
            data: value,
            autoFocus: false
        });
        return dialogRef.afterClosed();
    }

}
