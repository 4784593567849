<div>
    <ng-container *ngIf="!loadingElement;else LOADING">
        <div style="position:absolute;z-index: 1000000;margin-left: 180px;width:139px;margin-top:12px;"
             *ngIf="!(sidenavStartOpen$|async)&&data.showSearchLightPoint">
            <ng-container>
                <button style="margin: 0 5px"
                        color="accent"
                        mat-flat-button
                        (click)="toggleFilter()">
                    <mat-icon>search</mat-icon>
                </button>
                <button
                        color="primary"
                        mat-flat-button
                        (click)="getBounds()">
                    <img src="assets/icon/dashboard/Eye_Light_Bulb_40x40.png"
                         style="max-width: 24px;">
                </button>
            </ng-container>
        </div>
        <div style="position:absolute;z-index: 1000000;width:190px;margin-left: calc(100% - 145px);margin-top:12px;"
             *ngIf="!(sidenavEndOpen$|async)">
            <button style="margin: 0 5px"
                    color="primary"
                    mat-flat-button
                    (click)="closeDialog()">
                <mat-icon>clear</mat-icon>
            </button>
            <button *ngIf="data.selectable"
                    color="accent"
                    mat-flat-button
                    (click)="saveSelected()"
            >
                <mat-icon>check</mat-icon>
            </button>
            <button *ngIf="data.showSearchLightPoint"
                    color="accent"
                    mat-flat-button
                    (click)="savePlanimetria()"
            >
                <mat-icon>save</mat-icon>
            </button>
        </div>
    </ng-container>
    <mat-drawer-container autosize [hasBackdrop]="false">
        <mat-drawer #SidenavEnd mode="over" position="end" *ngIf="data.showDetails">
            <app-dashboard-sidenav [detail]="element"
                                   [visualizedTabConnectedFile]="false"
                                   [visualizedTabHistoryLightPoint]="false"
                                   [visualizedTabSchedaManutenzione]="false"
                                   [visualizedTabVirtualMidNight]="false"
                                   [visualizedTabCaricoEsogeno]="false"
                                   [visualizedButtonSave]="false"
                                   [onlyLecture]="true"
            >
            </app-dashboard-sidenav>
            <div style="position: absolute;bottom: 20px;right:19px;">
                <div style="position: relative">
                    <div>
                        <button mat-flat-button color="primary"
                                *ngIf="element|convertValueByPredicateFunction:getRemoveButtonPredicate"
                                (click)="removeToPlanimetry(element)"
                                style="margin: 0 5px">
                            {{ 'removeFromPlanimetry'|translate }}
                        </button>
                        <button mat-mini-fab (click)="SidenavEnd.close();">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </mat-drawer>
        <ng-container>
            <mat-drawer #SidenavStart mode="over" position="start" style="z-index: 1001"
                        *ngIf="data.showSearchLightPoint">
                <form [formGroup]="livesValues">

                    <app-multiple-value-free-text
                            [disableEmitEvent]="false"
                            [isSetting]="true"
                            [highlightedChips]="{}"
                            (emitChips)="setSearchSmart($event,'targhePuntiLuce')"
                            (clickChips)="clickChipsSearchSmart($event,'targhePuntiLuce')"
                            titleCard="{{'searchLightPoint'|translate}}"
                            label="{{'etichetta'|translate}}"
                    >
                    </app-multiple-value-free-text>
                    <ng-container formGroupName="supportValues">
                        <mat-card class="box-filter">
                            <mat-card-subtitle>
                                {{ 'PuntiLuce'|translate }}
                            </mat-card-subtitle>
                            <mat-card-content>
                                <app-multi-select-form-field formControlName="filtriPuntiLuce"
                                                             [possibleValues]="data.circuitiInProject|convertValueByPredicateFunction:getCircuitiPossibleValuesPredicateFunction"
                                >
                                </app-multi-select-form-field>
                            </mat-card-content>
                        </mat-card>
                        <mat-card class="box-filter">
                            <mat-card-subtitle>
                                {{ 'Circuiti'|translate }}
                            </mat-card-subtitle>
                            <mat-card-content>
                                <app-multi-select-form-field formControlName="filtriCircuiti"
                                                             [possibleValues]="data.circuitiInProject|convertValueByPredicateFunction:getCircuitiPossibleValuesPredicateFunction"
                                >
                                </app-multi-select-form-field>
                            </mat-card-content>
                        </mat-card>
                    </ng-container>
                </form>
                <div style="position: absolute;bottom: 20px;right:19px;">
                    <div style="position: relative">
                        <div>
                            <button mat-mini-fab (click)="SidenavStart.close();">
                                <mat-icon>close</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </mat-drawer>
        </ng-container>


        <div class="containerMap">
            <app-visualized-elements-in-map
                    [circuiti]="data.circuiti|convertValueByPredicateFunction:getCircuitiWithMarker:(labelCircuiti$|async):getLabelMarkerPuntiLuce"
                    [puntiLuce]="data.puntiLuce|convertValueByPredicateFunction:getPuntiLuceWithMarker:(labelPuntiLuce$|async):getLabelMarkerPuntiLuce:(selectedLightPoints$|async):getIconSelected"
                    [fitBounds]="data.fitBounds"
                    [mapControl]="{type:true}"
                    (emitMarker)="clickMarker($event)"
                    [markerCliccable]="markerCliccable"
                    [boundChangeActive]="boundsChangeActive"
                    (mapReady)="mapReady($event)"
            >
            </app-visualized-elements-in-map>
        </div>
        <div style="position: absolute;bottom: 15px;left: 15px;max-width: 100%;background: white;padding: 5px 10px;border-radius: 5px"
             *ngIf="data.showLabels==true&&puntiLuceIsSet"
             class="flex-start-center " [formGroup]="form">
            <mat-form-field color="primary" style="margin-bottom: -30px">
                <mat-label>{{ 'label'|translate }}&nbsp;{{ 'PuntiLuce'|translate }}</mat-label>
                <mat-select formControlName="etichettaPuntiLuce">
                    <mat-option value="targa">
                        {{ 'dashboard_sidenav.PuntiLuce.targa.title' | translate }}
                    </mat-option>
                    <mat-option value="targaCustom">
                        {{ 'dashboard_sidenav.PuntiLuce.targaCustom.title' | translate }}
                    </mat-option>
                    <mat-option value="idMezzanotte">
                        {{ 'dashboard_sidenav.PuntiLuce.idMezzanotte.title' | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </mat-drawer-container>
</div>
<ng-template #LOADING>
    <div style="position:absolute;z-index: 1000000;margin-left: 50%;margin-top:12px;"
         *ngIf="!(sidenavStartOpen$|async)">
        <ng-container>
            <div style="background: rgba(150,150,150,0.25);padding: 10px;border-radius: 50%"
                 [disabled]="true">
                <mat-spinner [diameter]="40"></mat-spinner>
            </div>
        </ng-container>
    </div>
</ng-template>