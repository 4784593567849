import {AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import {DialogExportComponent} from '../../pop-up-export/dialog-export/dialog-export.component';
import {CircuitiParse} from "../../../models/Circuiti.Parse";
import {PuntiLuceParse} from "../../../models/PuntiLuce.Parse";
import {MatSidenav} from "@angular/material/sidenav";
import {FormGroup, UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {PuntiLuceLabelService} from "../../../providers/services/punti-luce-label.service";
import {combineLatest, Observable, of, Subscription} from "rxjs";
import {
    arrayIsSet, className, getDifferenceArray, getUniqueValueInArray,
    KeyStringValue,
    PossibleValuesFormField,
    stringIsSet
} from "../../../models/Models";
import {map, pairwise, startWith} from "rxjs/operators";
import {PuntiLuceScaleColorService} from "../../../providers/services/punti-luce-scale-color.service";

@Component({
    selector: 'app-see-on-the-map',
    templateUrl: './see-on-the-map.component.html',
    styleUrls: ['./see-on-the-map.component.scss']
})
export class SeeOnTheMapComponent implements OnInit, AfterViewInit, OnDestroy {
    form: UntypedFormGroup;
    labelPuntiLuce$: Observable<string | undefined>;
    labelCircuiti$: Observable<string | undefined>;
    getLabelMarkerPuntiLuce
    getIconPuntiLuce
    getLabelMarkerCircuiti
    puntiLuceIsSet = false;
    @ViewChild('SidenavStart', {static: false}) sidenavStart: MatSidenav;
    @ViewChild('SidenavEnd', {static: false}) sidenavEnd: MatSidenav;

    livesValues: FormGroup;
    livesValuesIn$: Observable<KeyStringValue<any>>;
    private subscriptions = new Subscription();
    sidenavStartOpen$: Observable<boolean> = of(false);
    sidenavEndOpen$: Observable<boolean> = of(false);
    private map: google.maps.Map;
    boundsChangeActive = true;

    loadingElement = false;
    selectedLightPoints$ = of(undefined);
    markerCliccable = false
    getIconSelected

    constructor(public dialogRef: MatDialogRef<DialogExportComponent>,
                @Inject(MAT_DIALOG_DATA) public data: {
                    planId,
                    circuiti: CircuitiParse[],
                    circuitiInProject: CircuitiParse[],
                    puntiLuce: PuntiLuceParse[],
                    showDetails: boolean,
                    showLabels: boolean,
                    selectable: boolean,
                    fitBounds: boolean,
                    maxLightPointSelectable: number | undefined,
                    showSearchLightPoint: boolean,
                    selectedLightPoints: PuntiLuceParse[] | undefined,

                },
                private fb: UntypedFormBuilder,
                private labelPuntiLuceService: PuntiLuceLabelService,
                private scaleColorPuntiLuce: PuntiLuceScaleColorService,
    ) {
        if (this.data.showDetails || this.data.selectable) {
            this.markerCliccable = true
        }
        let objForm: any = {etichettaPuntiLuce: undefined, etichettaCircuiti: undefined}
        if (this.data.selectable) {
            objForm = {...objForm, selected: undefined}
        }
        this.form = this.fb.group(objForm);
        if (this.data.selectable) {
            this.selectedLightPoints$ = this.form.get('selected').valueChanges.pipe(
                startWith(undefined),
                map(() => this.form.get('selected').value)
            )
            this.getIconSelected = (pl, opacity, color) => {
                return this.scaleColorPuntiLuce.getIconPuntoLuce(pl, opacity, color)
            }
            if (arrayIsSet(this.data.selectedLightPoints)) {
                this.data.selectedLightPoints.forEach(l => {
                    this.addSelectLightPoints(l);
                })
            }
        }
        this.livesValues = this.fb.group({
                supportValues: this.fb.group(
                    {
                        filtriPuntiLuce: undefined,
                        filtriCircuiti: undefined,
                    },
                ),
                inValues: undefined,
                outValues: this.fb.group(
                    {
                        add: this.fb.group({
                            filtriPuntiLuce: undefined,
                            filtriCircuiti: undefined,
                            boundsPuntiLuce: undefined,
                            searchSmart: undefined
                        }),
                        remove: this.fb.group({
                            filtriPuntiLuce: undefined,
                            filtriCircuiti: undefined,
                            filtriObjectIdPuntiLuce: undefined,
                            searchSmart: undefined
                        }),
                    },
                )
            }
        );
        this.livesValuesIn$ = this.livesValues.valueChanges.pipe(
            startWith(undefined),
            map(() => this.livesValues.get('inValues').value)
        );
        ['filtriPuntiLuce', 'filtriCircuiti'].forEach(key => {
            this.subscriptions.add(
                this.livesValues.get('supportValues').get(key).valueChanges.pipe(
                    pairwise(),
                    map(([prev, current]: string[][]) => {
                        const obj = {}
                        if (!arrayIsSet(prev) && arrayIsSet(current)) {
                            obj['add'] = getUniqueValueInArray([...current]);
                        }
                        if (!arrayIsSet(current) && arrayIsSet(prev)) {
                            obj['remove'] = getUniqueValueInArray([...prev]);
                        }
                        if (arrayIsSet(prev) && arrayIsSet(current)) {
                            obj['add'] = getUniqueValueInArray([...getDifferenceArray(current, prev, false)])
                            obj['remove'] = getUniqueValueInArray([...getDifferenceArray(prev, current, false)])
                        }
                        return obj;
                    })
                ).subscribe((values) => {
                    if ('add' in values) {
                        this.livesValues.get('outValues').get('add').get(key).setValue(values.add)
                    }
                    if ('remove' in values) {
                        this.livesValues.get('outValues').get('remove').get(key).setValue(values.remove)
                    }
                })
            )
        })

        this.subscriptions.add(
            this.livesValuesIn$.subscribe(values => {
                if (values != null) {
                    Object.keys(values).forEach(key => {

                        this.data[key] = values[key];
                    })
                    if (this.data.showLabels) {
                        this.puntiLuceIsSet = this.data && arrayIsSet(this.data.puntiLuce);
                        this.form.get('etichettaPuntiLuce').setValue('targa');
                        this.form.get('etichettaCircuiti').setValue('numeroQuadro');
                    }
                }
            })
        )
        this.labelPuntiLuce$ = this.form.get('etichettaPuntiLuce').valueChanges.pipe(
            startWith(undefined),
            map(() => this.form.get('etichettaPuntiLuce').value)
        )
        this.labelCircuiti$ = this.form.get('etichettaCircuiti').valueChanges.pipe(
            startWith(undefined),
            map(() => this.form.get('etichettaCircuiti').value)
        )
        if (this.data.showLabels) {
            this.puntiLuceIsSet = this.data && arrayIsSet(this.data.puntiLuce);
            this.form.get('etichettaPuntiLuce').setValue('targa');
            this.form.get('etichettaCircuiti').setValue('numeroQuadro');
        }
        this.getLabelMarkerPuntiLuce = (label, color) => this.labelPuntiLuceService.getOnlyLabel(label, color);
        // this.getIconPuntiLuce = (label, color) => this..getOnlyLabel(label, color);
    }

    public element: any;

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        if (this.sidenavStart) {
            this.sidenavStartOpen$ = combineLatest([
                this.sidenavStart.openedStart.pipe(startWith(undefined)),
                this.sidenavStart.closedStart.pipe(startWith(undefined)),
            ]).pipe(
                map(() => {
                    return this.sidenavStart.opened
                })
            )
        }
        if (this.sidenavEnd) {
            this.sidenavEndOpen$ = combineLatest([
                this.sidenavEnd.openedStart.pipe(startWith(undefined)),
                this.sidenavEnd.closedStart.pipe(startWith(undefined)),
            ]).pipe(
                map(() => {
                    return this.sidenavEnd.opened
                })
            )
        }

    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }


    savePlanimetria() {
        this.dialogRef.close({
            save: true,
            circuiti: this.data.circuiti,
            puntiLuce: this.data.puntiLuce,
            center: this.map.getCenter(),
            tipologiaMappa: this.map.getMapTypeId(),
            zoom: this.map.getZoom()
        })
    }

    saveSelected() {
        this.dialogRef.close({
            puntiLuce: this.selectLightPoint,
        })
    }

    closeDialog(close = true) {
        if (close) {
            this.dialogRef.close({closed: true});
        }
    }

    get showDetails(): boolean {
        return this.data != null && this.data.showDetails === true
    }

    get selectLightPoint(): PuntiLuceParse[] {
        return this.form.get('selected').value
    }

    set selectLightPoint(pls) {
        this.form.get('selected').setValue(pls)
    }

    addSelectLightPoints(lightPoint) {
        const selected = arrayIsSet(this.selectLightPoint) ? [...this.selectLightPoint] : []
        const index = selected.findIndex(pl => pl.objectId === lightPoint.objectId)
        if (index >= 0) {
            selected.splice(index, 1)
        } else {
            if (this.data.maxLightPointSelectable != null && selected.length >= this.data.maxLightPointSelectable) {
                selected.shift()
            }
            selected.push(lightPoint)
        }
        this.selectLightPoint = selected
    }

    clickMarker(event: CircuitiParse | PuntiLuceParse) {
        if (this.sidenavEnd != null) {
            this.element = event;
            this.sidenavEnd.toggle();
        }
        if (this.data.selectable) {
            this.addSelectLightPoints(event)
        }
    }

    toggleFilter() {
        this.sidenavStart.toggle();
    }

    getPuntiLuceWithMarker(puntiLuce: PuntiLuceParse[], labelPuntiLuce: string, getLabelMarkerPuntiLuce: (v1, v2) => any, selected: PuntiLuceParse[] | undefined, getIconSelected): PuntiLuceParse[] {
        if (arrayIsSet(puntiLuce) && stringIsSet(labelPuntiLuce)) {
            return puntiLuce.map(p => {
                let value;
                if (labelPuntiLuce == 'targa' || labelPuntiLuce == 'targaCustom') {
                    value = p.getTarga();
                } else {
                    value = p[labelPuntiLuce]
                }
                p.labelMarker = getLabelMarkerPuntiLuce(value, 'white')
                if (arrayIsSet(selected)) {
                    const indexSelected = selected.findIndex(pls => pls.objectId == p.objectId)
                    if (indexSelected >= 0) {
                        p.icon = getIconSelected(p, 1)
                    } else {
                        p.icon = getIconSelected(p, 0.5, '#000000')
                    }
                }
                return p;
            });
        }
        return puntiLuce;
    }

    getCircuitiWithMarker(circuiti: CircuitiParse[], labelPuntiLuce: string, getLabelMarkerPuntiLuce: (v1, v2) => any): CircuitiParse[] {
        if (arrayIsSet(circuiti) && stringIsSet(labelPuntiLuce)) {
            return circuiti.map(p => {
                p.labelMarker = getLabelMarkerPuntiLuce(p[labelPuntiLuce], 'white')
                return p;
            });
        }
        return circuiti;
    }

    getCircuitiPossibleValuesPredicateFunction(circuiti: CircuitiParse[]): PossibleValuesFormField[] {
        if (!arrayIsSet(circuiti)) {
            return [];
        } else {
            return circuiti.map(value => {
                    const obj = {
                        objectId: value.objectId,
                        noTraduction: value.numeroQuadro,
                        registerValue: value.objectId
                    };
                    return obj;
                }
            );
        }
    }

    mapReady(map) {
        this.map = map
    }

    getBounds() {
        this.livesValues.get('outValues').get('add').get('boundsPuntiLuce').setValue(this.map.getBounds())
    }

    setSearchSmart(event, label) {
        if (event.remove != null) {
            const currentRemove = this.livesValues.get('outValues').get('remove').get('searchSmart').value != null ? {...this.livesValues.get('outValues').get('remove').get('searchSmart').value} : {}
            currentRemove[label] = event.remove;
            this.livesValues.get('outValues').get('remove').get('searchSmart').setValue(currentRemove)
        } else if (event.add != null) {
            const currentAdd = this.livesValues.get('outValues').get('add').get('searchSmart').value != null ? {...this.livesValues.get('outValues').get('add').get('searchSmart').value} : {}
            currentAdd[label] = event.add;
            this.livesValues.get('outValues').get('add').get('searchSmart').setValue(currentAdd)
        }
    }

    clickChipsSearchSmart(event, label) {
        this.setSearchSmart({add: event.add}, label)
    }

    removeToPlanimetry(value: any) {
        if (value.className === className.puntiLuce) {
            const values = arrayIsSet(this.livesValues.get('outValues').get('remove').get('filtriObjectIdPuntiLuce').value) ? [...this.livesValues.get('outValues').get('remove').get('filtriObjectIdPuntiLuce').value] : [];
            values.push(value.objectId);
            this.livesValues.get('outValues').get('remove').get('filtriObjectIdPuntiLuce').setValue(values)
        } else {
            const values = arrayIsSet(this.livesValues.get('outValues').get('remove').get('filtriCircuiti').value) ? [...this.livesValues.get('outValues').get('remove').get('filtriCircuiti').value] : [];
            values.push(value.objectId);
            this.livesValues.get('outValues').get('remove').get('filtriCircuiti').setValue(values)
        }
        this.sidenavEnd.close();
    }

    getRemoveButtonPredicate(element) {
        return element != null && element.className === className.puntiLuce
    }


}
