<app-button-spinner [loading]="loading" color="accent" type="mat-mini-fab">
    <div class="button-container">
        <div *ngIf="isVisible" class="buttons">
            <ng-content>

            </ng-content>
        </div>
        <a (click)="onChangeVisibility()">
            <i class="material-icons open-close-button">{{isVisible ? iconOpened : iconClose}}</i>
        </a>
    </div>
</app-button-spinner>
