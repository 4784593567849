<mat-chip-list [multiple]="true" [disabled]="form.disabled" >
    <mat-chip
            *ngFor="let value of possibleValues"
            (click)="clickChips(value)"
            [color]="value.color"
            [ngClass]="(valueSetted$|async)|convertValueByPredicateFunction:classPredicateFunction:value"
            [selected]="(valueSetted$|async)|convertValueByPredicateFunction:isSelectedPredicateFunction:value"
    >
        {{value.traduction ? (value.traduction|translate) : value.html}}
        <ng-container *ngIf="otherValues!=null&&otherValues[value.valueForm]!=null">
            {{otherValues[value.valueForm]}}
        </ng-container>
        <mat-icon matChipTrailingIcon (click)="$event.stopPropagation()" [matMenuTriggerFor]="menu"
                  [matMenuTriggerData]="{value:value}" *ngIf="showMenu">
            more_vert
        </mat-icon>
    </mat-chip>
</mat-chip-list>
<mat-menu #menu="matMenu">
    <ng-template matMenuContent let-value="value">
        <a mat-menu-item (click)="clickSelezioneUnica(value)">
            <mat-icon color="primary">rule</mat-icon>
            <span>{{'button.uniqueSelect'|translate}}</span>
        </a>
    </ng-template>
</mat-menu>