import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Subject} from "rxjs";
import {UntypedFormGroup} from "@angular/forms";
import {MyErrorStateMatcher} from "../../../providers/matchers/error-state-matcher";
import {ProjectService} from "../../../providers/services/project.service";
import {AlertService} from "../../../providers/services/alert.service";
import {TranslateService} from "@ngx-translate/core";
import {
    MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent
} from "@angular/material/legacy-autocomplete";
import {ProgettiParse} from "../../../models/Progetti.Parse";
import {HunaValidators} from "../../../providers/validators/HunaValidators";
import {chiaviFiltri} from "../../../models/Models";
import {ImageService} from "../../../providers/services/image.service";
import {DomSanitizer} from "@angular/platform-browser";
import {fromPromise} from "rxjs/internal-compatibility";
import {delay, switchMap} from "rxjs/operators";

@Component({
    selector: 'app-sidenav-new-project',
    templateUrl: './sidenav-new-project.component.html',
    styleUrls: ['./sidenav-new-project.component.scss']
})
export class SidenavNewProjectComponent implements OnInit {

    //sidebar
    @Output() close = new Subject();


    public filterForm: UntypedFormGroup;
    public matcher = new MyErrorStateMatcher();//gestisce gli errori del textarea lato html
    public cominiPerAutoCompletare;
    @Output() createNewProgect = new EventEmitter();
    public salvaImage;
    public addImageDisabled: { htmlLogo: boolean, image: boolean };
    public idComune;


    constructor(private projectService: ProjectService,
                private alert: AlertService,
                private translate: TranslateService,
                private imageService: ImageService,
                private _sanitizer: DomSanitizer,
    ) {
        this.filterForm = this.projectService.newProjectForm;
        this.addImageDisabled = {htmlLogo: false, image: false};
    }

    ngOnInit(): void {
    }


    public invioProgettoAppenaCreato(progetto: ProgettiParse) {
        this.createNewProgect.emit(progetto);
    }

    onSubmit() {
        if (this.filterForm.valid) {
            const values = Object.keys(this.filterForm.value).reduce((prev, key) => {
                if (this.filterForm.get(key).dirty) {
                    prev[key] = this.filterForm.get(key).value;
                }
                return prev;
            }, {})
            this.projectService.createNewProject(values, this.idComune).subscribe((progetto) => {
                this.invioProgettoAppenaCreato(progetto);
                const message = this.translate.instant("project_saved");
                this.alert.success(message);
                this.closeSidenav();
            }, (error => this.alert.error(error)))
        }
    }


    onLoadImage(files: File[], label: string) {
        try {
            const fileUploadControl = files[0];
            // const loaderId = this.loaderService.addLoader();
            if (fileUploadControl) {
                const file = fileUploadControl;
                const name = label;
                const reader = new FileReader();
                reader.onloadend = (e: ProgressEvent) => {
                    const base64 = (e.target as FileReader).result as any;
                    this.imageService.compressImage(base64).then(compressed => {
                        const base64Compressa: string = compressed as string;
                        this.filterForm.get(label).setValue({
                            html: this._sanitizer.bypassSecurityTrustUrl(base64Compressa),
                            file: base64Compressa,
                            nome: name
                        });
                        this.filterForm.get(label).markAsDirty();
                        this.salvaImage = true;
                    })
                };
                reader.readAsDataURL(file);
            } else {
                this.alert.error(this.translate.instant('load_correct_file'));
            }
        } catch (e) {
            this.alert.error(this.translate.instant('error') + ' ' + e.message);
        }

    }

    public getComuniDaAutocompletare(subString, secondi: number) {
        if (subString.length >= 3) {
            this.projectService.getComuneAutocompletamento(subString.toUpperCase()).pipe(
                delay(secondi * 500)
            ).subscribe((comuni) => {
                    this.cominiPerAutoCompletare = comuni;
                    this.filterForm.get('comune').setValidators(HunaValidators.isInArray(this.cominiPerAutoCompletare));
                    this.idComune = this.verifiaComuneInserito();
                }
            );
        }
    }

    public verifiaComuneInserito() {
        const filtraComuni = this.cominiPerAutoCompletare.filter(comune => {
            return comune.nome == this.filterForm.get('comune').value
        });
        return (Array.isArray(filtraComuni) && filtraComuni.length > 0) ? filtraComuni[0].id : false;
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        this.idComune = this.verifiaComuneInserito();
    }

    deleteSavedFile(label, form?) {
        this.salvaImage = false;
        this.filterForm.get(label).reset(null);
        this.filterForm.get(label).markAsDirty();
    }

    closeSidenav() {
        this.close.next();
    }

    public eliminaCampo(campo) {
        this.filterForm.get(campo).setValue("");
    }

}
