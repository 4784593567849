<div fxFlex fxLayout="row" class="w-mat-timepicker">
  <mat-form-field appearance="fill" fxFlex class="timeContainer" style="width: 100px; max-width: 100px">
      <mat-label>{{ label }}</mat-label>
      <input matInput class="timeInput" placeholder="Select time"
             [value]="time()"
             [disabled]="disabled"
             (change)="updateUserTime($event)">
      <mat-icon matSuffix (click)="showPicker()" [ngClass]="{'icon-disabled': disabled}">access_time</mat-icon>
  </mat-form-field>
</div>
