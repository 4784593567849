import {Pipe, PipeTransform} from '@angular/core';
import {isNotNullOrUndefined} from "../../models/Models";

@Pipe({
    name: 'convertValueByPredicateFunction'
})
export class ConvertValueByPredicateFunctionPipe implements PipeTransform {

    transform<T>(values: any[] | any, predicateFunction: (a, ...args) => T, ...args): T | null {
        if (values != null && isNotNullOrUndefined(predicateFunction)) {
            return predicateFunction(values, ...args);
        } else {
            return null
        }
    }

}
