import {
    Component,
    Input,
    OnInit,
    ViewChild,
    ElementRef,
    AfterContentChecked, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy, NgZone,
} from '@angular/core';
import {
    UntypedFormBuilder,
} from '@angular/forms';
import {
    StreetsService,
    EditableStreet,
    ComputeResults,
} from 'src/app/providers/services/streets.service';
import {ActivatedRoute} from '@angular/router';
import {MatLegacyTabGroup as MatTabGroup} from '@angular/material/legacy-tabs';
import {TranslateService} from '@ngx-translate/core';
import {AlertService} from 'src/app/providers/services/alert.service';
import {fromPromise} from 'rxjs/internal-compatibility';
import {arrayIsSet} from "../../../models/Models";
import {Observable, Subject, Subscription} from "rxjs";
import {filter} from "rxjs/operators";
import {ViewportRuler} from '@angular/cdk/scrolling';

@Component({
    selector: 'app-edit-street',
    templateUrl: './edit-street.component.html',
    styleUrls: ['./edit-street.component.scss'],
})
export class EditStreetComponent implements OnInit, AfterContentChecked, OnChanges, OnDestroy {
    street?: EditableStreet;
    edited: boolean = false;
    computationResults: ComputeResults | undefined;
    @Output() updateStreet = new EventEmitter()
    @Input() forceUpdateItemByList;

    @Input()
    public streetId;
    @Input()
    public photometries;
    @Input()
    public gruppiFotometrie;
    streetDataEmit = new Subject<any>();
    streetData$: Observable<any>;
    @ViewChild('mainTab')
    mainTab: MatTabGroup = undefined!;
    @ViewChild('mobilePreviewsContainer')
    mobilePreviewsContainer: ElementRef<HTMLDivElement> = undefined!;
    @ViewChild('childStreet') childStreet: ElementRef<HTMLDivElement> | undefined;
    childStreetMaxHeight: string | undefined = undefined;

    resizeSubscription: Subscription | undefined;

    constructor(
        private streetService: StreetsService,
        private route: ActivatedRoute,
        private formBuilder: UntypedFormBuilder,
        private translate: TranslateService,
        private alertService: AlertService,
        private viewportRuler: ViewportRuler,
        private ngZone: NgZone
    ) {
        this.streetId = (this.streetId == null) ? route.snapshot.params.id : this.streetId;
        this.streetData$ = this.streetDataEmit.asObservable().pipe(
            filter((id) => id != null)
        );
    }

    ngOnDestroy(): void {
        this.resizeSubscription?.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.forceUpdateItemByList != null) {
            this.streetDataEmit.next({streetId: this.streetId})
        }
    }

    ngOnInit() {
        this.resizeSubscription = this.viewportRuler.change(300).subscribe(() => {
            this.ngZone.run(() => this.updateChildMaxHeight());
        });

        function objCoalesce(obj, property, elseValue) {
            return obj && obj[property] !== undefined ? obj[property] : elseValue;
        }

        this.streetDataEmit.next({streetId: this.streetId})
        fromPromise(this.streetService.get(this.streetId)).subscribe(
            (street) => {
                this.street = {
                    tipologiaAreaIlluminata: objCoalesce(street, 'tipologiaAreaIlluminata', ''),
                    isCentroStorico: objCoalesce(street, 'isCentroStorico', false),
                    nome: objCoalesce(street, 'nome', ''),
                    fattoreManutenzione: objCoalesce(street, 'fattoreManutenzione', 0.9),
                    interdistanza: objCoalesce(street, 'interdistanza', 25),
                    note: objCoalesce(street, 'note', ''),
                    objectId: objCoalesce(street, 'objectId', undefined),
                    includiMarciapiedi: objCoalesce(street, 'includiMarciapiedi', undefined),
                    calcoloSemicilindrico: objCoalesce(street, 'calcoloSemicilindrico', undefined),
                    verificaIPEI_A: objCoalesce(street, 'verificaIPEI_A', undefined),
                    lampade: JSON.parse(
                        JSON.stringify(
                            objCoalesce(street, 'lampade', [])
                        )
                    ), // Deep copy oggetti per evitare modifica dell'originale
                    layout: JSON.parse(
                        JSON.stringify(
                            objCoalesce(street, 'layout', [])
                        )
                    ),
                };
                if (!this.street.objectId) {
                    this.edited = true;
                }

                setTimeout(() => this.updateChildMaxHeight());
            }
        );
    }

    private updateChildMaxHeight() {
        const childStreetElement = this.childStreet.nativeElement;
        this.childStreetMaxHeight = `${window.innerHeight - childStreetElement.getBoundingClientRect().top - 25}px`;
    }

    onCompute(computationResults: ComputeResults) {
        const bestResultIsSet = (computationResults.bestResult != null && arrayIsSet(Object.keys(computationResults.bestResult)))
        if (computationResults.allResults.length === 0 && !bestResultIsSet) {
            this.alertService.warning(this.translate.instant('streets.no_result_from_compute'));
            this.computationResults = undefined;
        } else {
            this.computationResults = computationResults;
            if (this.mobilePreviewsContainer && window.getComputedStyle(this.mobilePreviewsContainer.nativeElement).display !== 'none') {
                if (document.querySelector('mat-sidenav-content')) {
                    document.querySelector('mat-sidenav-content').scrollTop = 0;
                }
            }
        }
    }

    onStreetSaved() {
        this.edited = false;
        this.streetDataEmit.next({streetId: this.streetId})
        this.updateStreetEmit(this.street)
    }

    onLampClick(index: number) {

    }

    showPreview = true;

    ngAfterContentChecked() {
        this.showPreview = !this.computationResults || this.mainTab.selectedIndex === 0;
    }

    reverse(arr: any[]) {
        return arr.slice().reverse();
    }

    updateStreetEmit(event) {
        this.updateStreet.emit(event)
    }

}
