import {AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {dataForm} from "../../../components/confirm-delete/select-or-create/select-or-create.component";
import {Subscription} from "rxjs";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {MatSort, MatSortable} from "@angular/material/sort";
import {stringIsSet} from "../../../models/Models";
import {ChipsFromArrayService} from "../../../providers/services/chips-from-array.service";

@Component({
    selector: 'app-selected-users-in-table',
    templateUrl: './selected-users-in-table.component.html',
    styleUrls: ['./selected-users-in-table.component.scss']
})
export class SelectedUsersInTableComponent implements OnInit, OnDestroy, AfterViewInit {

    formUsers: UntypedFormGroup;
    formFilter: UntypedFormGroup;
    displayColumnsAutocomplete;
    displayedColumns = ['checkBox', 'nome', 'cognome', 'email'];
    displayedColumnsNotForm = ['nome', 'cognome', 'email'];
    subscriptions: Subscription[] = [];
    dataSource: MatTableDataSource<any>;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;


    constructor(public dialogRef: MatDialogRef<SelectedUsersInTableComponent>,
                @Inject(MAT_DIALOG_DATA)
                public data: { users: Parse.User[], selectedUsers: Parse.User[] },
                private fb: UntypedFormBuilder,
                private chipsFromArrayService: ChipsFromArrayService,
    ) {

        const keyFilterDefault = 'nome';
        this.formFilter = fb.group({
            keyFilter: keyFilterDefault,
            filterValue: '',
        })
        this.dataSource = new MatTableDataSource<any>();

        this.displayColumnsAutocomplete = this.displayedColumns.map((col, index) => {
            const dataForm: dataForm = {valueForm: null, html: null};
            dataForm.html = col;
            dataForm.valueForm = (new Date().getTime() + index).toString(32);
            return dataForm;
        });

        let subcription = this.formFilter.get('keyFilter').valueChanges.subscribe(key => {
            if (this.displayedColumns.includes(key)) {
                this.dataSource.filterPredicate = (progetto, filterValue) => this.filterPredicate(progetto, filterValue, key)
                this.formFilter.get('filterValue').updateValueAndValidity();
            }
        })
        this.formFilter.get('keyFilter').updateValueAndValidity();
        this.subscriptions.push(subcription)

        subcription = this.formFilter.get('filterValue').valueChanges.subscribe(key => {
            this.dataSource.filter = key
        })
        this.subscriptions.push(subcription)


    }

    ngOnInit(): void {
        this.formUsers = this.getForm(this.data.users, this.data.selectedUsers)
        setTimeout(() => {
            this.dataSource.data = this.data.users;
        }, 100)
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe())
    }

    ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
            if (sortHeaderId !== 'checkBox') {
                return this.getValue(data, sortHeaderId)
            } else {
                return this.formUsers.get(data.id).value + ''
            }
        };


    }


    filterPredicate(user, filterValue: string, filterKey) {
        if (stringIsSet(filterKey) && stringIsSet(filterValue)) {
            const value = (filterKey !== 'checkBox') ? this.getValue(user, filterKey) : this.formUsers.get(user.id).value + '';
            return this.chipsFromArrayService.isIncludes(value, filterValue)
        } else {
            return true
        }

    }

    getForm(users: Parse.User[], selectedUsers: Parse.User[]): UntypedFormGroup {
        const objForm = {}
        users.forEach(user => {
            const indexUser = selectedUsers.findIndex(userSelected => userSelected.id === user.id);
            objForm[user.id] = indexUser >= 0
        })
        return this.fb.group(objForm)
    }


    closeDialog() {
        this.dialogRef.close(undefined)
    }

    addUser() {
        const users = [];
        Object.keys(this.formUsers.value).forEach(idUser => {
            if (this.formUsers.value[idUser]) {
                const index = this.data.users.findIndex(user => user.id == idUser)
                if (index >= 0) {
                    users.push(this.data.users[index])
                }
            }
        })
        const addUsers = users.filter(userInForm => {
            const index = this.data.selectedUsers.findIndex(user => user.id == userInForm.id)
            return index < 0
        })
        const removeUsers = this.data.selectedUsers.filter(user => {
            const index = users.findIndex(userForm => userForm.id == user.id)
            return index < 0
        })
        this.dialogRef.close({add: addUsers, remove: removeUsers, all: users})
    }

    getValue(user: Parse.User, column) {
        if (column === 'email') {
            return (user.getEmail()) ? user.getEmail() : user.getUsername()
        } else {
            return user.get(column)
        }
    }


    globalCheckBoxChange(checked) {
        if (checked) {
            Object.keys(this.formUsers.value).forEach(idUser => {
                this.formUsers.get(idUser).setValue(checked)
            })
        } else {
            this.formUsers.reset()
        }

    }


}
