import {Pipe, PipeTransform} from '@angular/core';
import {stringIsSet} from "../../models/Models";

@Pipe({
    name: 'notPresentInTraduction'
})
export class NotPresentInTraductionPipe implements PipeTransform {

    transform(value: string, orignalValue, valueContain): string {
        if (stringIsSet(value) && value.includes(valueContain)) {
            return orignalValue;
        } else {
            return value;
        }
    }

}
