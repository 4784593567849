<div mat-dialog-content class="w-timepicker-dialog">
    <w-time [color]="color"
            [userTime]="userTime"
            (onRevert)="revert()"
            (onSubmit)="submit()"
            (userTimeChange)="change(1)"
            [revertLabel]="data.revertLabel"
            [submitLabel]="data.submitLabel"></w-time>
</div>

