import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'g[roadway]',
  templateUrl: './roadway.component.html',
  styleUrls: ['./roadway.component.scss']
})
export class RoadwayComponent implements OnInit, OnChanges {

  @Input()
  height: number = 100;
  @Input()
  width: number = 100;
  @Input()
  x: number = 0;
  @Input()
  y: number = 0;
  @Input()
  lanes: number = 2;

  viewBoxHeight = 100;
  viewBoxWidth = 100;
  get viewBox() {
    return `0 0 ${this.viewBoxWidth} ${this.viewBoxHeight}`;
  }
  
  get lineHeight() {
    return this.viewBoxHeight / 10 / this.lanes;
  }

  ngOnChanges() {
    
  }


  constructor() { }

  ngOnInit() {
  }

  get dividers() {
    const dividers = [];
    for (let i = 1; i < this.lanes; i++) {
      dividers.push(this.viewBoxHeight / (this.lanes) * i - (this.lineHeight / 2));
    }
    return dividers;
  }
}