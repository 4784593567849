import {Component, Inject, OnInit} from '@angular/core';
import {className} from "../../../models/Models";
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {numeroQuadro} from "../select-new-element/select-new-element.component";

@Component({
  selector: 'app-what-import',
  templateUrl: './what-import.component.html',
  styleUrls: ['./what-import.component.scss']
})
export class WhatImportComponent implements OnInit {

  public nomiClasse = className;
  public visualizzaBottone = true;
  visible = true;

  constructor(
      public dialogRef: MatDialogRef<WhatImportComponent>,
      @Inject(MAT_DIALOG_DATA) public data: { message, numeriQuadro },
  ) { }

  ngOnInit(): void {
  }

}
