import {
    AfterContentChecked,
    AfterViewChecked,
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {
    arrayIsSet,
    getDifferenceArray,
    getDifferenceArrayGenricObject,
    isNotNullOrUndefined
} from "../../models/Models";
import {BehaviorSubject, combineLatest, fromEvent, interval, Observable, of} from "rxjs";
import {debounceTime, delay, filter, last, map, pairwise, startWith} from "rxjs/operators";
import {FormBuilder, FormGroup} from "@angular/forms";

export type BreakPointType = {
    maxWidth: number,
    sx: { percent: number, px: number, minWidth?: number, order?: number, },
    dx: { percent: number, px: number, minWidth?: number, order?: number, },
}

@Component({
    selector: 'app-split-screen',
    templateUrl: './split-screen.component.html',
    styleUrls: ['./split-screen.component.scss']
})
export class SplitScreenComponent implements OnInit, OnChanges {
    @ViewChild('containerSplitScreen', {static: false}) private containerSplitScreen: ElementRef;

    // i breakPoint devono essere ordinati in maniera ascendente per maxWidth
    @Input() breakPoint: BreakPointType[] = [
        {
            maxWidth: 1000,
            sx: {percent: 0.8, px: 470, minWidth: 1},
            dx: {percent: 0.2, px: 0, minWidth: 1},
        },
    ]

    @Input() referenceWidth
    @Input() columnReverse: boolean = false;
    private windowResize$ = fromEvent(window, 'resize').pipe(
        startWith(window)
    )
    private elementResize$ = interval(10).pipe(
        map(() => {
            let width;
            if (this.containerSplitScreen != null && this.containerSplitScreen.nativeElement != null && this.containerSplitScreen.nativeElement.offsetWidth > 0) {
                width = this.containerSplitScreen.nativeElement.offsetWidth - 1;
            }
            return width
        }),
        pairwise(),
        filter(([widthPrevius, widthCurrent]) => {
            if (widthCurrent > 0) {
                return widthCurrent != widthPrevius;
            }
            return false;
        }),
        map(([widthPrevius, widthCurrent]) => {
            return widthCurrent;
        }),
    );

    private breakPointEmit = new BehaviorSubject<BreakPointType[]>(this.breakPoint);
    private breakPoint$ = this.breakPointEmit.asObservable();
    public widthHeighBeforBreakPoint$: Observable<{ sx: string, dx: string, orderSx: number, orderDx: number }>

    constructor() {
        this.widthHeighBeforBreakPoint$ = combineLatest([
            this.breakPoint$,
            this.windowResize$,
            this.elementResize$.pipe(
                startWith(window.innerWidth)
            )
        ]).pipe(
            map(values => {
                const breakPoint = values[0];
                const window = values[1];
                const width = values[2]
                return this.getWidthHeightBeforBreakPoint(breakPoint, window, width)
            }),
        )
    }

    ngOnInit(): void {
    }


    ngOnChanges(changes: SimpleChanges): void {
        if (changes.breakPoint != null) {
            const difference = getDifferenceArrayGenricObject(changes.breakPoint.previousValue, changes.breakPoint.currentValue)
            if (arrayIsSet(difference)) {
                this.breakPointEmit.next(this.breakPoint);
            }
        }
    }


    get screenWidth() {
        if (isNotNullOrUndefined(this.containerSplitScreen) && isNotNullOrUndefined(this.containerSplitScreen.nativeElement) && this.containerSplitScreen.nativeElement.offsetWidth) {
            return this.containerSplitScreen.nativeElement.offsetWidth - 1;
        } else {
            return window.innerWidth
        }
    }

    getScreenWidth(window, containerSplitScreen: ElementRef) {
        if (isNotNullOrUndefined(containerSplitScreen) && isNotNullOrUndefined(containerSplitScreen.nativeElement) && containerSplitScreen.nativeElement.offsetWidth) {
            return containerSplitScreen.nativeElement.offsetWidth - 1;
        } else {
            return window.innerWidth
        }
    }


    getSxValue(value): string {
        if (isNotNullOrUndefined(value.sx.px) || isNotNullOrUndefined(value.sx.percent)) {
            return (value.sx.px) ? value.sx.px + 'px' : value.sx.percent + '%'
        } else {
            if (isNotNullOrUndefined(value.dx.px)) {
                return this.screenWidth - value.dx.px + 'px'
            } else {
                return 100 - value.dx.percent + '%'
            }
        }

    }

    getDxValue(value): string {
        if (isNotNullOrUndefined(value.dx.px) || isNotNullOrUndefined(value.dx.percent)) {
            return (value.dx.px) ? value.dx.px + 'px' : value.dx.percent + '%'
        } else {
            if (isNotNullOrUndefined(value.sx.px)) {
                return this.screenWidth - value.sx.px + 'px'
            } else {
                return 100 - value.sx.percent + '%'
            }
        }
    }

    get widthHeightBeforBreakPoint(): { sx: string, dx: string, orderSx: number, orderDx: number } {
        const screenWidth = this.screenWidth;
        let index = this.breakPoint.findIndex((point, index) => {
            if (index + 1 >= this.breakPoint.length) {
                return false
            }
            const currentBreakPoint = point.maxWidth;
            const successiveBreakPoint = this.breakPoint[index + 1].maxWidth;
            if (screenWidth >= successiveBreakPoint) {
                return true;
            } else {
                return false;
            }
        });
        if (index < 0) {
            index = this.breakPoint.length - 1
        }
        const sx = this.getSxValue(this.breakPoint[index]);
        const dx = this.getDxValue(this.breakPoint[index]);
        const orderSx = this.breakPoint[index].sx != null ? this.breakPoint[index].sx.order : undefined;
        const orderDx = this.breakPoint[index].dx != null ? this.breakPoint[index].dx.order : undefined;
        const width = {
            sx: sx,
            dx: dx,
            orderSx,
            orderDx
        }
        return width
    }

    getWidthHeightBeforBreakPoint(breakPoint: BreakPointType[], window, screenWidth: number): { sx: string, dx: string, orderSx: number, orderDx: number } {
        let index = breakPoint.findIndex((point, index) => {
            if (index + 1 >= breakPoint.length) {
                return false
            }
            const currentBreakPoint = point.maxWidth;
            const successiveBreakPoint = breakPoint[index + 1].maxWidth;
            if (screenWidth >= successiveBreakPoint) {
                return true;
            } else {
                return false;
            }
        });
        if (index < 0) {
            index = breakPoint.length - 1
        }
        const sx = this.getSxValue(breakPoint[index]);
        const dx = this.getDxValue(breakPoint[index]);
        const orderSx = breakPoint[index].sx != null ? breakPoint[index].sx.order : undefined;
        const orderDx = breakPoint[index].dx != null ? breakPoint[index].dx.order : undefined;
        const width = {
            sx: sx,
            dx: dx,
            orderSx,
            orderDx
        }
        return width;
    }

}
