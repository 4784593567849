import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {CaricoEsogenoParse} from "../../../models/CaricoEsogeno.Parse";
import {Observable} from "rxjs";
import {map, startWith} from "rxjs/operators";
import {SelectTypePopUpType, selectTypePopUp} from "../../../providers/services/dialog-pop-up-info.service";
import {arrayIsSet} from "../../../models/Models";
import {MatLegacySelectionList as MatSelectionList} from "@angular/material/legacy-list";


@Component({
    selector: 'app-select-carico-esogeno',
    templateUrl: './select-carico-esogeno.component.html',
    styleUrls: ['./select-carico-esogeno.component.scss']
})
export class SelectCaricoEsogenoComponent implements OnInit {
    form: UntypedFormGroup;
    public selectCarichiEsogeni$: Observable<string[]>
    public disableButton$: Observable<boolean>
    public typeSelect = selectTypePopUp

    constructor(public dialogRef: MatDialogRef<SelectCaricoEsogenoComponent>,
                @Inject(MAT_DIALOG_DATA)
                public data: { carichiEsogeni: CaricoEsogenoParse[], typeSelect: SelectTypePopUpType },
                private fb: UntypedFormBuilder,) {
        const obj = {}
        this.data.carichiEsogeni.forEach(carico => obj[carico.objectId] = false)
        this.form = this.fb.group({
            value: [{
                value: null,
                disabled: false
            }, Validators.required]
        });
        this.selectCarichiEsogeni$ = this.form.valueChanges.pipe(
            startWith(this.form.value))
    }

    ngOnInit(): void {

    }

    save() {
        if (arrayIsSet(this.form.get('value').value)) {
            const addCarichiEsogeni = this.data.carichiEsogeni
                .filter(c => this.form.get('value').value.includes(c.objectId));
            this.dialogRef.close({
                add: addCarichiEsogeni,
            });
        } else {
            this.dialogRef.close()
        }
    }

    setCaricoEsogeno(selectList: MatSelectionList, caricoEsogeno: CaricoEsogenoParse) {
        const option = selectList.options
            .toArray()
            .find(a => a.value == caricoEsogeno.objectId)
        if (selectList.selectedOptions.isSelected(option) && selectList.selectedOptions.isMultipleSelection()) {
            selectList.selectedOptions.deselect(option);
        } else {
            selectList.selectedOptions.select(option);
        }

    }
}
