import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {Router} from "@angular/router";
import {catchError} from "rxjs/operators";
import {throwError} from "rxjs";
import {fromPromise} from "rxjs/internal-compatibility";
import * as Parse from 'parse';

@Injectable({
    providedIn: 'root'
})
export class PluginApiService {

    private readonly x = {
        'Content-Type': 'application/json;charset=utf-8',
        'Access-Control-Allow-Headers': '*'
        //  'Platform-Token': '9GgdRExtxoulkObmN1qEyuMsaJgft6LWGErIKpNVJgD6pwtXJtFZ0Sdgpo6LyVPC'
    };

    private readonly keyToken = 'Authorization';
    private readonly keyLabId = 'Lab-ID';
    private readonly keyUserCookie: string = 'User-Cookie';
    private readonly keyLanguage: string = 'Accept-Language';

    private readonly cookiePath: string = '/';

    constructor(private http: HttpClient,
                @Inject(PLATFORM_ID) private appId,
                private routing: Router) {
        //this.setUserCookie();
    }


    get<T>(url: string, reqOpts?: any, observe = 'response'): Observable<any> {
        return this.http.get<T>(url, this.getHttpOptions(observe, reqOpts));
    }

    post<T>(url: string, body: any, reqOpts?: any, observe = 'response'): Observable<any> {
        return this.http.post<T>(url, body, this.getHttpOptions(observe, reqOpts));
    }

    put<T>(url: string, body: any, reqOpts?: any, observe = 'response'): Observable<any> {
        return this.http.put<T>(url, body, this.getHttpOptions(observe, reqOpts));
    }

    delete(url: string, reqOpts?: any, observe = 'response'): Observable<any> {
        return this.http.delete(url, this.getHttpOptions(observe, reqOpts));
    }

    patch(url: string, body: any, reqOpts?: any, observe = 'response'): Observable<any> {
        return this.http.patch(url, body, this.getHttpOptions(observe, reqOpts));
    }

    getFile(url): Observable<any> {
        const a = new HttpHeaders();
        a.set('Access-Control-Allow-Headers', '*');
        a.set('Access-Control-Allow-Origin', '*');
        return this.http.get(url, {headers: a});
    }

    setHttpOptions(opts: any): void {
        for(let i in opts) {
            this.x[i] = opts[i];
        }
    }

    deleteHttpOptions(opts: string[]): void {
        for (var i of opts) {
            delete this.x[i];
        }
    }


    public getParamsFromJson(keys, params) {
        let len;
        if ((len = keys.length) === 0) {
            return '';
        }
        let result = '?';
        keys.forEach((item, index) => {
            result = `${result}${item}=${params[item]}`;
            if ((index + 1) !== len) {
                result = `${result}&`;
            }
        });
        return result;
    }

    private getHttpOptions(observe, reqOpts?: any): any {
        const httpOptions = {};
        Object.assign(httpOptions, this.x);
        if (reqOpts) {
            for (let i in reqOpts) {
                if (reqOpts[i] !== null && reqOpts[i] !== undefined && reqOpts[i] !== 'null' && reqOpts[i] !== 'undefined') {
                    httpOptions[i] = String(reqOpts[i]);
                }
            }
        }
       /* if (isPlatformBrowser(this.appId)) {
            if (Cookies.get(this.keyToken)) {
                httpOptions[this.keyToken] = Cookies.get(this.keyToken);
            }
            if (Cookies.get(this.keyUserCookie)) {
                httpOptions[this.keyUserCookie] = Cookies.get(this.keyUserCookie);
            }
            if (Cookies.get(this.keyLanguage)) {
                httpOptions[this.keyLanguage] = Cookies.get(this.keyLanguage);
            }
            if (Cookies.get(this.keyLabId)) {
                httpOptions[this.keyLabId] = Cookies.get(this.keyLabId);
            }
        }*/
        return {
            headers: new HttpHeaders(httpOptions),
            observe
        };
    }

   /* private setUserCookie() {
        if (isPlatformBrowser(this.appId)) {
            if (!Cookie.get(this.keyUserCookie)) {
                Cookies.set(this.keyUserCookie, this.getUserAgentEncoded(), {
                    path: this.cookiePath,
                    secure: environment.production
                });
            }
        }
    }*/

    private getUserAgentEncoded(): string {
        let result = '';
        const ua = window.navigator.userAgent;
        for (let i = 128; i > 0; --i) {
            result += ua[Math.floor(Math.random() * ua.length)];
        }
        return btoa(result);
    }


    public composePath(baseUrl, endpoint: string): string {
        return baseUrl + endpoint;
    }

    controlAccessToken$<T>(observableFunction: Observable<T>): Observable<T> {
        return observableFunction.pipe(
            catchError(error => {
                console.log(error);
                if (error.code == Parse.Error.INVALID_SESSION_TOKEN) {
                    Parse.User.logOut();
                    this.routing.navigate(['/auth/login']);
                }
                return throwError(error)
            })
        )
    }

    runCloudFunction<T>(name: string, requestParams: any, options: any=undefined): Observable<any> {
        return this.controlAccessToken$(fromPromise(Parse.Cloud.run(name, requestParams,options)))
    }
}
