<mat-card style="margin: 10px 0" [formGroup]="form">
    <div style="position: absolute;top:0px;right:-1px">
        <ng-content select="cornerRight">

        </ng-content>
    </div>
    <mat-card-subtitle style="margin-bottom: 0;display: flex" class="flex-start-center">
        <span>{{titleCard}}</span>
    </mat-card-subtitle>
    <mat-form-field class="form-field-without-margin" style="margin-top: 4px;">
        <mat-label>{{label}}</mat-label>
        <input matInput formControlName="setTargheEtichette" (keydown.enter)="clickSet()">
        <button mat-icon-button matSuffix [disabled]="!form.get('setTargheEtichette').value" (click)="clickSet()">
            <mat-icon>add_box</mat-icon>
        </button>
    </mat-form-field>
    <mat-chip-list *ngIf="visualizedChips" [disabled]="form.get('targhePuntiLuce').disabled">
        <ng-container *ngFor="let targa of activeTarghe">
            <mat-chip 
                class="enabled" 
                (click)="_clickChips(targa)" 
                [disabled]="form.get('targhePuntiLuce').disabled"
                (removed)="removeStringElement(targa,'targhePuntiLuce')"
                [color]="getColorChipsSearch(targa,highlightedChips) ? 'primary' : undefined"
                [selected]="getColorChipsSearch(targa,highlightedChips)"
                [ngClass]="{'disabled':form.get('targhePuntiLuce').disabled}">
                {{targa}}
                <button matChipRemove [disabled]="form.get('targhePuntiLuce').disabled">
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip>
        </ng-container>
    </mat-chip-list>
    <button mat-stroked-button *ngIf="activeTarghe.length > 0" [disableRipple]="true" style="transform: scale(0.95); margin-top: 10px; margin-left: -4px;" [disabled]="form.get('targhePuntiLuce').disabled" (click)="unset('targhePuntiLuce')">
        <span style="text-transform: none; font-weight: 400;">{{'clear_selection' | translate}}</span>
    </button>
</mat-card>