<div class="container">
    <app-loader [loaderInput]="!street"></app-loader>
    <div class="container-street" *ngIf="street">
        <div class="child-street scrollbar-visible" [style.max-height]="childStreetMaxHeight" #childStreet>
            <mat-tab-group mat-align-tabs="center" dynamicHeight #mainTab class="huna-material-class-street-form">
                <mat-tab>
                    <ng-template mat-tab-label>
                        {{('streets.details' | translate) + (edited ? ' *' : '')}}
                    </ng-template>
                    <div style="max-width: 800px; margin: 0 auto">
                        <app-street-preview class="hidden-over-lg" [interdistance]="street.interdistanza"
                                            (lampClick)="onLampClick($event)" [layoutItems]="street.layout"
                                            [lamps]="street.lampade">
                        </app-street-preview>
                    </div>
                    <app-edit-street-form [street]="street" (edit)="edited = true" (saved)="onStreetSaved()">
                    </app-edit-street-form>
                </mat-tab>
                <mat-tab [label]="'streets.light' | translate" [disabled]="edited">
                    <ng-template mat-tab-label>
                        <label *ngIf="edited"
                               [matTooltip]="'streets.save_street_before_continuing' | translate">{{('streets.light' | translate)}}</label>
                        <label *ngIf="!edited">{{('streets.light' | translate)}}</label>
                    </ng-template>
                    <div style="max-width: 800px; margin: 0 auto;" class="hidden-over-lg" #mobilePreviewsContainer>
                        <app-street-preview [interdistance]="street.interdistanza" (lampClick)="onLampClick($event)"
                                            [layoutItems]="street.layout" [lamps]="street.lampade" *ngIf="showPreview">
                        </app-street-preview>
                        <app-compute-results *ngIf="!showPreview" [street]="street"
                                             [computationResults]="computationResults"
                                             (updateStreet)="updateStreetEmit($event)"
                        ></app-compute-results>
                    </div>
                    <app-light-street-form
                            [streetId]="streetId"
                            [streetData]="(streetData$|async)"
                            (compute)="onCompute($event)"
                            [photometries]="photometries"
                            [gruppiFotometrie]="gruppiFotometrie"

                    >
                    </app-light-street-form>
                </mat-tab>
            </mat-tab-group>
        </div>
        <div class="child-street hidden-under-lg">
            <mat-card>
                <mat-card-content>
                    <app-street-preview *ngIf="showPreview" [interdistance]="street.interdistanza"
                                        [layoutItems]="street.layout"
                                        (lampClick)="onLampClick($event)" [lamps]="street.lampade">
                    </app-street-preview>
                    <app-compute-results
                            *ngIf="!showPreview" [street]="street"
                            [computationResults]="computationResults"
                            (updateStreet)="updateStreetEmit($event)"
                    ></app-compute-results>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
