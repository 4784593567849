import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {arrayIsSet, stringIsSet} from "../../../models/Models";

@Component({
    selector: 'app-expand-detail',
    templateUrl: './expand-detail.component.html',
    styleUrls: ['./expand-detail.component.scss']
})
export class ExpandDetailComponent implements OnInit {

    public texts: string[];
    public imageUrl: string;
    public subTitle: string;
    public title: string;
    cardContentSize = 'card-content-size-without-image'

    constructor(public dialogRef: MatDialogRef<ExpandDetailComponent>,
                @Inject(MAT_DIALOG_DATA) public data: { texts: string[], image: { url: string }, title: string, subTitle: string, },) {
    }


    ngOnInit(): void {

        if (this.data) {
            if (arrayIsSet(this.data.texts)) {
                this.texts = this.data.texts
            }
            if (this.data.image && stringIsSet(this.data.image.url)) {
                this.cardContentSize = 'card-content-size-image';
                this.imageUrl = this.data.image.url
            }
            if (stringIsSet(this.data.subTitle)) {
                this.subTitle = this.data.subTitle
            }
            if (stringIsSet(this.data.title)) {
                this.title = this.data.title
            }
        }


    }

}
