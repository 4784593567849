<div mat-dialog-title>
    {{ 'error' | translate}}
</div>
<div mat-dialog-content>
    <div>
        {{'create_plan_error' | translate}}
    </div>
</div>

<div mat-dialog-actions>
    <button mat-flat-button color="primary" (click)="dialogRef.close()">{{ 'cancel' | translate}}</button>
</div>
