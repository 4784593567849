import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'g[lamp]',
  templateUrl: './lamp.component.html',
  styleUrls: ['./lamp.component.scss']
})
export class LampComponent implements OnInit {

  minSide = 1;

  @Input()
  x: number = 0;
  @Input()
  y: number = 0;
  @Input()
  outreach: number = 0;
  @Input()
  rotate: number = 0;
  @Input()
  round: boolean = false;

  get side() {
    return Math.max(this.outreach * 2, this.minSide);
  }
  get viewBoxSide() {
    return this.side;
  }
  get width() {
    return this.side + 2 * this.padding;
  }
  get height() {
    return this.side + 2 * this.padding;
  }
  get transform() {
    return `rotate(${-this.rotate} ${this.viewBoxSide / 2} ${this.viewBoxSide / 2})`;
  }
  get xSvg() {
    return this.x - this.side / 2 - this.padding;
  }
  get ySvg() {
    return this.y - this.side / 2 - this.padding;
  }
  get padding() {
    return this.radius;
  }
  get viewBoxSidePadding() {
    return this.viewBoxSide + 2*this.padding;
  }
  
  get viewBox() {
    return `${-this.padding} ${-this.padding} ${this.viewBoxSidePadding} ${this.viewBoxSidePadding}`;
  }

  get radius() {
    return .5;
  }
  get centerX() {
    return this.viewBoxSide / 2;
  }
  get centerY() {
    return this.viewBoxSide / 2 - this.outreachHeight;
  }
  get outreachHeight() {
    return Math.min(this.viewBoxSide / 2, this.outreach);
  }
  get outreachWidth() {
    return this.viewBoxSide / 50 / this.side;
  }
  get outreachY() {
    return this.viewBoxSide / 2 - this.outreachHeight;
  }
  get outreachX() {
    return this.viewBoxSide / 2 - this.outreachWidth / 2;
  }

  get arc() {
    return `M ${this.centerX - this.radius} ${this.centerY}
            A ${this.radius} ${this.radius} 0 0 1 ${this.centerX + this.radius} ${this.centerY}`
  }

  constructor() { }

  ngOnInit() {
  }

}
