import {Injectable} from '@angular/core';
import {ProjectService} from "./project.service";
import {ProgettiParse} from "../../models/Progetti.Parse";
import {Observable} from "rxjs";
import {IntervalsType, TLC_ProfiloFunzionamentoParse} from "../../models/TLC_ProfiloFunzionamento.Parse";
import {fromPromise} from "rxjs/internal-compatibility";
import {arrayIsSet, paramsApiParse} from "../../models/Models";
import * as Parse from "parse";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class ProfileTlcService {
    private get currentProject() {
        return this.projectService.currentProject;
    }

    constructor(private projectService: ProjectService) {
    }


    getProfiliTelecontrollo(project: ProgettiParse = undefined): Observable<TLC_ProfiloFunzionamentoParse[]> {
        let currentProject;
        if (project != null) {
            currentProject = project
        } else {
            currentProject = this.currentProject
        }
        const parseFunctions$ = fromPromise(
            Parse.Cloud.run('getProfiliFunzionamentoTLC', paramsApiParse({
                projectId: currentProject.objectId,
            }))
        )
        return parseFunctions$.pipe(
            map(profiles => {
                if (arrayIsSet(profiles)) {
                    return profiles.map(p => {
                        const profileParse = new TLC_ProfiloFunzionamentoParse()
                        profileParse.objectId = p.objectId
                        return profileParse;
                    })
                } else {
                    return []
                }
            })
        )
    }

    updateProfiloTelecontrollo(id: string, values: { name: string, intervals: IntervalsType[] }): Observable<TLC_ProfiloFunzionamentoParse> {
        const parseFunctions$ = fromPromise(
            Parse.Cloud.run('editProfiloFunzionamentoTLC', paramsApiParse({
                objectId: id,
                ...values
            }))
        )
        return parseFunctions$
    }

    addProfiloTelecontrollo(values: { name: string, intervals: IntervalsType[] }): Observable<TLC_ProfiloFunzionamentoParse> {
        const parseFunctions$ = fromPromise(
            Parse.Cloud.run('addProfiloFunzionamentoTLC', paramsApiParse({projectId: this.currentProject.objectId, ...values}))
        )
        return parseFunctions$
    }

    destroyProfiloTelecontrollo(idTlcProfile: string): Observable<TLC_ProfiloFunzionamentoParse> {
        const parseFunctions$ = fromPromise(
            Parse.Cloud.run('deleteProfiloFunzionamentoTLC', paramsApiParse({objectId: idTlcProfile}))
        )
        return parseFunctions$
    }
}
