import {Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2} from '@angular/core';
import {fromEvent, Subject, Subscription} from "rxjs";
import {CopyPasteService} from "../services/copy-paste.service";
import {AlertService} from "../services/alert.service";
import {TranslateService} from "@ngx-translate/core";
import {filter, map, take} from "rxjs/operators";
import {arrayIsSet} from "../../models/Models";

@Directive({
    selector: '[appCopyPaste]'
})
export class CopyPasteDirective implements OnInit, OnDestroy {
    @Input() copyValue;
    @Input() isPasteButton = false;
    @Input() key: string = 'default';
    @Input() functionSaveValue: (...args) => any = undefined;
    @Output() pasteValue = new Subject();
    private subscription = new Subscription()

    set value(v) {
        if (this.functionSaveValue != null) {
            this.copyPasteService.value = {key: this.key, value: this.functionSaveValue(v)};
        } else {
            this.copyPasteService.value = {key: this.key, value: v};
        }
    }


    constructor(private elementRef: ElementRef,
                private copyPasteService: CopyPasteService,
                alertService: AlertService,
                translateService: TranslateService,
                private renderer2: Renderer2
    ) {
        this.subscription.add(
            fromEvent(elementRef.nativeElement, 'click').subscribe(a => {
                const value = this.functionSaveValue != null ? this.functionSaveValue(this.copyValue) : this.copyValue;
                if (value != null) {
                    this.value = this.copyValue;
                    alertService.success(translateService.instant('copy'));
                } else {
                    alertService.warning(translateService.instant('notElementCopy'));
                }
                if (this.isPasteButton) {
                    copyPasteService.value$.pipe(
                        take(1),
                        map((value) => {
                            let index = -1
                            if (arrayIsSet(value)) {
                                index = value.findIndex(v => v.key === this.key)
                            }
                            if (index >= 0) {
                                return value[index].value
                            }
                            return undefined;
                        }),
                        filter(v => v != null)
                    ).subscribe(value => {
                        this.pasteValue.next(value);
                        alertService.success(translateService.instant('paste'));
                    })
                }
            })
        )
    }


    ngOnInit() {
        this.subscription.add(
            this.copyPasteService.value$
                .pipe(
                    map((value) => {
                        let index = -1
                        if (arrayIsSet(value)) {
                            index = value.findIndex(v => v.key === this.key)
                        }
                        if (index >= 0) {
                            return value[index].value
                        }
                        return undefined;
                    }),
                )
                .subscribe(value => {
                    if (value == null && this.isPasteButton) {
                        this.renderer2.setStyle(this.elementRef.nativeElement, 'color', 'gray');
                        this.renderer2.setStyle(this.elementRef.nativeElement, 'cursor', 'default');
                    } else {
                        this.renderer2.removeStyle(this.elementRef.nativeElement, 'color');
                        this.renderer2.setStyle(this.elementRef.nativeElement, 'cursor', 'pointer');
                    }
                })
        )
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

}
