import {configurationElement, formField, typeDatabase, typeFormValue} from "./configurationPropertyUtils";
import {className} from "../Models";
import {CaricoEsogenoParse} from "../CaricoEsogeno.Parse";

const typeArredoUrbanoValueForm: { [k: string]: formField[] } = {
    attraversamentoPedonale_colonnineRicaricaElettrica: [
        {
            keyName: 'integer',
            varianteKey: undefined,
            keyTraduction: 'integer',
            sortingValue: 100,
            required: true,
            possibleValues: undefined,
            typeForm: typeFormValue.INPUT_INTEGER,
            typeDatabase: typeDatabase.NUMBER
        },
        {
            keyName: 'number',
            varianteKey: undefined,
            keyTraduction: 'number',
            sortingValue: 200,
            required: true,
            possibleValues: undefined,
            typeForm: typeFormValue.INPUT_NUMBER,
            typeDatabase: typeDatabase.NUMBER
        },
        {
            keyName: 'text',
            varianteKey: undefined,
            keyTraduction: 'text',
            sortingValue: 300,
            required: true,
            possibleValues: undefined,
            typeForm: typeFormValue.INPUT_TEXT,
            typeDatabase: typeDatabase.STRING
        },
        {
            keyName: 'tipologia',
            varianteKey: undefined,
            keyTraduction: 'tipologia',
            sortingValue: 50,
            required: true,
            possibleValues: [
                {
                    dataBaseValue: 'attraversamentoPedonale',
                    traduction: 'dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.attraversamentoPedonale'
                },
                {
                    dataBaseValue: 'bikeSharing',
                    traduction: 'dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.bikeSharing'
                },
                {
                    dataBaseValue: 'cassonetto',
                    traduction: 'dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.cassonetto'
                },
                {
                    dataBaseValue: 'cestino',
                    traduction: 'dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.cestino'
                },
                {
                    dataBaseValue: 'colonnineRicaricaElettrica',
                    traduction: 'dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.colonnineRicaricaElettrica'
                },
                {
                    dataBaseValue: 'dissuasore',
                    traduction: 'dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.dissuasore'
                },
                {dataBaseValue: 'dosso', traduction: 'dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.dosso'},
                {
                    dataBaseValue: 'fermataBus',
                    traduction: 'dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.fermataBus'
                },
                {
                    dataBaseValue: 'fermataTaxi',
                    traduction: 'dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.fermataTaxi'
                },
                {
                    dataBaseValue: 'fermataTram',
                    traduction: 'dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.fermataTram'
                },
                {
                    dataBaseValue: 'fontana',
                    traduction: 'dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.fontana'
                },
                {
                    dataBaseValue: 'fotoZona',
                    traduction: 'dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.fotoZona'
                },
                {
                    dataBaseValue: 'panchina',
                    traduction: 'dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.panchina'
                },
                {
                    dataBaseValue: 'parcoGiochi',
                    traduction: 'dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.parcoGiochi'
                },
                {
                    dataBaseValue: 'parcometro',
                    traduction: 'dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.parcometro'
                },
                {
                    dataBaseValue: 'rastrelliera',
                    traduction: 'dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.rastrelliera'
                },
                {
                    dataBaseValue: 'segnaleStradale',
                    traduction: 'dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.segnaleStradale'
                },
                {
                    dataBaseValue: 'semaforo',
                    traduction: 'dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.semaforo'
                },
                {
                    dataBaseValue: 'telecamera',
                    traduction: 'dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.telecamera'
                },
                {
                    dataBaseValue: 'tombino',
                    traduction: 'dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.tombino'
                },
            ],
            typeForm: typeFormValue.ELENCO_VINCOLATO,
            typeDatabase: typeDatabase.STRING
        }
    ]
}

export const configurationPropertyUrbanForniture: configurationElement [] =
    [{
        keyName: 'lastUpdateBy',
        varianteKey: '',
        traductionKey: 'dashboard_sidenav.ArredoUrbano.lastUpdateBy.title',
        typeForm: typeFormValue.INPUT_TEXT,
        typeDatabase: typeDatabase.STRING,
        editable: false,
        showInForm: true,
        filtrable: true,
        copyable: false,
        required: false,
        possibleValues: undefined,
        pointer: {
            className: '_User',
            getValue: (user) => {
                return user.get('name');
            },
            getId: null
        },
        sortingValue: 100,
        section: 0,
        forms: undefined
    }, {
        keyName: 'createdAt',
        varianteKey: '',
        traductionKey: 'dashboard_sidenav.ArredoUrbano.createdAt.title',
        typeForm: typeFormValue.DATE,
        typeDatabase: typeDatabase.DATE,
        editable: false,
        showInForm: true,
        filtrable: true,
        copyable: false,
        required: false,
        possibleValues: undefined,
        pointer: undefined,
        sortingValue: 200,
        section: 0,
        forms: undefined
    }, {
        keyName: 'updatedAt',
        varianteKey: '',
        traductionKey: 'dashboard_sidenav.ArredoUrbano.updatedAt.title',
        typeForm: typeFormValue.DATE,
        typeDatabase: typeDatabase.DATE,
        editable: false,
        showInForm: true,
        filtrable: true,
        copyable: false,
        required: false,
        possibleValues: undefined,
        pointer: undefined,
        sortingValue: 300,
        section: 0,
        forms: undefined
    }, {
        keyName: 'location',
        varianteKey: '',
        traductionKey: 'dashboard_sidenav.ArredoUrbano.location.title',
        typeForm: typeFormValue.GEO_POINT,
        typeDatabase: typeDatabase.GEOPOINT,
        editable: false,
        showInForm: true,
        filtrable: true,
        copyable: false,
        required: false,
        possibleValues: undefined,
        pointer: undefined,
        sortingValue: 400,
        section: 0,
        forms: undefined
    }, {
        keyName: 'note',
        varianteKey: undefined,
        traductionKey: 'dashboard_sidenav.ArredoUrbano.note.title',
        typeForm: typeFormValue.INPUT_TEXT,
        typeDatabase: typeDatabase.STRING,
        editable: true,
        showInForm: true,
        filtrable: true,
        copyable: false,
        required: false,
        possibleValues: undefined,
        pointer: undefined,
        sortingValue: 500,
        section: 0,
        forms: undefined
    }, {
        keyName: 'tipologia',
        varianteKey: undefined,
        traductionKey: 'dashboard_sidenav.ArredoUrbano.tipologia.title',
        typeForm: typeFormValue.ELENCO_APERTO,
        typeDatabase: typeDatabase.STRING,
        editable: true,
        showInForm: true,
        filtrable: true,
        copyable: true,
        required: false,
        possibleValues: [
            {
                dataBaseValue: 'attraversamentoPedonale',
                traduction: 'dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.attraversamentoPedonale'
            },
            {
                dataBaseValue: 'bikeSharing',
                traduction: 'dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.bikeSharing'
            },
            {
                dataBaseValue: 'cassonetto',
                traduction: 'dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.cassonetto'
            },
            {
                dataBaseValue: 'cestino',
                traduction: 'dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.cestino'
            },
            {
                dataBaseValue: 'colonnineRicaricaElettrica',
                traduction: 'dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.colonnineRicaricaElettrica'
            },
            {
                dataBaseValue: 'dissuasore',
                traduction: 'dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.dissuasore'
            },
            {dataBaseValue: 'dosso', traduction: 'dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.dosso'},
            {
                dataBaseValue: 'fermataBus',
                traduction: 'dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.fermataBus'
            },
            {
                dataBaseValue: 'fermataTaxi',
                traduction: 'dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.fermataTaxi'
            },
            {
                dataBaseValue: 'fermataTram',
                traduction: 'dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.fermataTram'
            },
            {
                dataBaseValue: 'fontana',
                traduction: 'dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.fontana'
            },
            {
                dataBaseValue: 'fotoZona',
                traduction: 'dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.fotoZona'
            },
            {
                dataBaseValue: 'panchina',
                traduction: 'dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.panchina'
            },
            {
                dataBaseValue: 'parcoGiochi',
                traduction: 'dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.parcoGiochi'
            },
            {
                dataBaseValue: 'parcometro',
                traduction: 'dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.parcometro'
            },
            {
                dataBaseValue: 'rastrelliera',
                traduction: 'dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.rastrelliera'
            },
            {
                dataBaseValue: 'segnaleStradale',
                traduction: 'dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.segnaleStradale'
            },
            {
                dataBaseValue: 'semaforo',
                traduction: 'dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.semaforo'
            },
            {
                dataBaseValue: 'telecamera',
                traduction: 'dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.telecamera'
            },
            {
                dataBaseValue: 'tombino',
                traduction: 'dashboard_sidenav.ArredoUrbano.tipologia.possibleValues.tombino'
            },
        ],
        pointer: undefined,
        sortingValue: 600,
        section: 0,
        forms: {
            databaseKey: 'detail',
            values: {
                // attraversamentoPedonale: typeArredoUrbanoValueForm.attraversamentoPedonale_colonnineRicaricaElettrica,
                // colonnineRicaricaElettrica: typeArredoUrbanoValueForm.attraversamentoPedonale_colonnineRicaricaElettrica
            }
        }
    },
        // {
        //     keyName: 'caricoEsogeno',
        //     varianteKey: undefined,
        //     traductionKey: 'dashboard_sidenav.ArredoUrbano.caricoEsogeno.title',
        //     typeForm: typeFormValue.ELENCO_VINCOLATO,
        //     typeDatabase: typeDatabase.POINTER,
        //     editable: true,
        //     showInForm: true,
        //     filtrable: true,
        //     copyable: false,
        //     required: false,
        //     possibleValues: undefined,
        //     pointer: {
        //         className: className.caricoEsogeno,
        //         getValue: (caricoEsogeno: CaricoEsogenoParse) => {
        //             return caricoEsogeno.tipologia;
        //         },
        //         getId: (caricoEsogeno: CaricoEsogenoParse) => {
        //             return caricoEsogeno.objectId;
        //         }
        //     },
        //     sortingValue: 450,
        //     section: 0,
        //     forms: undefined
        // },
    ];
//



