import {Injectable} from '@angular/core';
import {PuntiLuceParse} from "../../models/PuntiLuce.Parse";
import {formatDate} from "@angular/common";
import {TransformForTranslatePipe} from "../pipes/transform-for-translate.pipe";
import {TranslateService} from "@ngx-translate/core";
import {isNotNullOrUndefined} from "../../models/Models";
import {ordinamentoEcampiTraduzioni} from "../../models/ordinamentoEcampiTraduzioni";

declare var google: any;

@Injectable({
    providedIn: 'root'
})
export class LabelAndListMarkerTextService {


    constructor(private translateService: TranslateService) {
    }

    private typeStringIsNotNull(value) {
        return (typeof value === 'string') ? value.trim().length > 0 : true;
    }

    getTextLabel(className: string, elemento: PuntiLuceParse, tipoDato: string | undefined, campoLabel: string, possibleValues): string {
        const isPointer = tipoDato?.includes('pointer') ?? false;
        const ND = this.translateService.instant('NaN');


        let value = elemento[campoLabel]
        if (ordinamentoEcampiTraduzioni.PuntiLuce.hasOwnProperty(campoLabel) && ordinamentoEcampiTraduzioni.PuntiLuce[campoLabel].getValueInLabel != null) {
            const {
                key,
                showingLikeType
            } = ordinamentoEcampiTraduzioni.PuntiLuce[campoLabel].getValueInLabel(elemento);
            value = key;
            tipoDato = showingLikeType
        }

        if (!isNotNullOrUndefined(tipoDato) || !isNotNullOrUndefined(elemento) || !isNotNullOrUndefined(value) || !this.typeStringIsNotNull(value)) {
            return ND;
        } else if (tipoDato === 'date') {
            return formatDate(elemento[campoLabel], 'mediumDate', this.translateService.currentLang);
        } else if (tipoDato === 'file') {
            const si = this.translateService.instant('yes');
            const no = this.translateService.instant('no');
            return (value) ? si : no;
        } else if (tipoDato === 'bool') {
            const si = this.translateService.instant('yes');
            const no = this.translateService.instant('no');
            return (value) ? si : no;
        } else if (tipoDato === 'elenco') {
            const filterPipe = new TransformForTranslatePipe();
            const chiaveSenzaSpazi = filterPipe.transform(value) as string;
            const traduzione: string = this.translateService.instant('dashboard_sidenav.' + className + '.' + campoLabel + '.possibleValues.' + chiaveSenzaSpazi);
            return (traduzione.includes('dashboard_sidenav.' + className)) ? elemento[campoLabel] : traduzione;
        } else if (tipoDato == 'number' || tipoDato == 'int') {
            return value.toString()
        }  else if (tipoDato == 'text' || tipoDato == 'targa') {
            return value;
        } else if (isPointer) {
            const pointerClass = possibleValues[0];
            const pointerKey = possibleValues[1];
            return value[pointerKey];
        }else {
            return value
        }
    }


}
