import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'powerFormat'
})
export class PowerFormatPipe implements PipeTransform {

    transform(value: number,): any {
        if (value < 1) {
            value = value * 1000;
            return `${value.toFixed(0)} ${Units.Watt}`;
        } else if (value < 10) {
            return `${value.toFixed(2)} ${Units.KiloWatt}`;
        } else {
            return `${value.toFixed(1)} ${Units.KiloWatt}`;
        }
    }

}

enum Units {
    Watt = 'W',
    KiloWatt = 'kW'
}
