<div [ngClass]="[position]">
    <mat-card style="padding: 0;box-shadow: none" class="overflow-slider-card invert-order">
        <div class="flex-center-width100 overflow-slider"
             *ngIf="!isMobile && visualizeButtonLeftRight && maxScrolWith>0"
        >
            <div class="flex-container container-opacity" style="width: 300px;z-index: 1000000;">
                <button style="margin-top: -5px"
                        (mousedown)="activeScroll('left')"
                        (mouseup)="stoppingScroll()"
                        mat-icon-button [color]="color">
                    <mat-icon>arrow_left</mat-icon>
                </button>
                <mat-slider (input)="moveSlider($event.value)" [(value)]="valueScroll" class="w100" [color]="color"
                            min="0"
                            max="100"
                            [step]="step"
                >
                </mat-slider>
                <button
                        style="margin-top: -5px"
                        (mousedown)="activeScroll('right')"
                        (mouseup)="stoppingScroll()"
                        mat-icon-button [color]="color">
                    <mat-icon>arrow_right</mat-icon>
                </button>
            </div>
        </div>
        <div class="scroll" #scrollDiv>
            <ng-content>

            </ng-content>
            <div *ngIf="!isMobile && visualizeButtonLeftRight && maxScrolWith>0" [ngClass]="{'end': valueScroll === 100, 'start': valueScroll === 0}" class="scroll-overlay"></div>
        </div>
    </mat-card>
</div>