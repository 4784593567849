import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class IpeaCalcService {

    constructor() {
    }

    private desinenzaIpea = 'IPEA_';

    private isInRange(value: number, min: number, max: number): boolean {
        return value > min && value <= max
    }

    private getEtaRstradeEgrandiAeree2013(potenza: number): number {
        if (this.isInRange(potenza, 0, 55)) {
            return 60;
        } else if (this.isInRange(potenza, 55, 75)) {
            return 65;
        } else if (this.isInRange(potenza, 75, 105)) {
            return 75;
        } else if (this.isInRange(potenza, 105, 155)) {
            return 81;
        } else if (this.isInRange(potenza, 155, 255)) {
            return 93;
        } else if (this.isInRange(potenza, 255, 405)) {
            return 99
        }
    }

    private getEtaRstradeEgrandiAeree2018(potenza: number): number {
        if (this.isInRange(potenza, 0, 65)) {
            return 73;
        } else if (this.isInRange(potenza, 65, 85)) {
            return 75;
        } else if (this.isInRange(potenza, 85, 115)) {
            return 83;
        } else if (this.isInRange(potenza, 115, 175)) {
            return 90;
        } else if (this.isInRange(potenza, 175, 285)) {
            return 98;
        } else if (this.isInRange(potenza, 285, 450)) {
            return 100
        } else if (potenza > 450) {
            return 100
        }
    }

    /**
     * DLOR
     *  il rapporto fra flusso luminoso emesso dall’apparecchio e rivolto verso l’emisfero
     *  inferiore e flusso luminoso totale emesso dal modulo LED presente, così come indicato
     *  sopra; il flusso risulta pertanto decurtato delle eventuali dispersioni dovute alle ottiche
     *  secondarie applicate oppure degli schermi protettivi.
     *  Rapporto tra flusso luminoso dell’apparecchio
     */


    private getEtaGlobaleLed(efficenzaLumenWatt: number, DLOR: number): number {
        return efficenzaLumenWatt * DLOR;
    }

    getIpea2013(potenza, efficenzaLumenWatt: number, DLOR: number): string {
        const ipea =
            this.getEtaGlobaleLed(efficenzaLumenWatt, DLOR) /
            this.getEtaRstradeEgrandiAeree2013(potenza);
        if (ipea > 1.1) {
            return this.desinenzaIpea + 'Ap';
        } else if (this.isInRange(ipea, 1.05, 1.10)) {
            return this.desinenzaIpea + 'A';
        } else if (this.isInRange(ipea, 1.0, 1.05)) {
            return this.desinenzaIpea + 'B';
        } else if (this.isInRange(ipea, 0.93, 1.00)) {
            return this.desinenzaIpea + 'C';
        } else if (this.isInRange(ipea, 0.84, 0.93)) {
            return this.desinenzaIpea + 'D';
        } else if (this.isInRange(ipea, 0.75, 0.84)) {
            return this.desinenzaIpea + 'E'
        } else if (this.isInRange(ipea, 0.65, 0.75)) {
            return this.desinenzaIpea + 'F'
        } else if (ipea <= 0.65) {
            return this.desinenzaIpea + 'G';
        }
    }

    getIpea2018(potenza, efficenzaLumenWatt: number, DLOR: number): string {
        const ipea =
            this.getEtaGlobaleLed(efficenzaLumenWatt, DLOR) /
            this.getEtaRstradeEgrandiAeree2018(potenza);
        if (ipea > 1.4) {
            const numeroDiPiu = Math.round((ipea - 1.1) / 0.1);
            return this.desinenzaIpea + 'A' + numeroDiPiu + 'p';
        } else if (this.isInRange(ipea, 1.3, 1.40)) {
            return this.desinenzaIpea + 'App';
        } else if (this.isInRange(ipea, 1.2, 1.30)) {
            return this.desinenzaIpea + 'Ap';
        } else if (this.isInRange(ipea, 1.1, 1.20)) {
            return this.desinenzaIpea + 'A';
        } else if (this.isInRange(ipea, 1.0, 1.1)) {
            return this.desinenzaIpea + 'B';
        } else if (this.isInRange(ipea, 0.85, 1.00)) {
            return this.desinenzaIpea + 'C';
        } else if (this.isInRange(ipea, 0.70, 0.85)) {
            return this.desinenzaIpea + 'D';
        } else if (this.isInRange(ipea, 0.55, 0.70)) {
            return this.desinenzaIpea + 'E'
        } else if (this.isInRange(ipea, 0.4, 0.55)) {
            return this.desinenzaIpea + 'F'
        } else if (ipea <= 0.4) {
            return this.desinenzaIpea + 'G';
        }
    }

}
