<app-container>
    <app-template-form [informativa]="false" [title]="'settings.virtulaMidnight' | translate">

        <ng-container *ngIf="circuitoId!=null&&circuitoId!=chiaviFiltri.nessunQuadro;else nessunQuadro">
            <app-form-light-mate
                    [circuitoId]="circuitoId"
                    [deviceId]="deviceId"
                    [device]="device"
                    [yesGroupId]="yesGroupId"
                    [lastProgrammiMVParse]="lastProgrammiMVParse"
                    [typeForm]="typeForm"
                    [showDetailLastComand]="typeForm==null"
                    [profilesTlc]="profilesTlc"
                    (updateProgram)="getLastProgrammaMV()"
                    (clickAction)="clickAction($event)"
                    (saveValues)="saveOnDatabase($event)"
                    [loadingAll]="loadingAll||externalLoading"
                    [loadingResetFactory]="loadingResetFactory"
                    [loadingSearchWrong]="loadingSearchWrong"
            >
            </app-form-light-mate>
            <mat-card style="margin: 5px 0" *ngIf="typeForm===typeFormValue.prt_v1">
                <mat-card-header>
                    <mat-card-title>
                        {{'pageProfile.currentProfile'|translate}}
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div class="flex-start-start flex-flow-column">
                        <ng-container
                                *ngFor="let settaggioTrad of settaggiTlcNodo|convertValueByPredicateFunction:predicateSettaggiTlcNodo">
                            <ng-container *ngIf="settaggioTrad.settaggiTlcNodo!=null;else Traduction">
                                <app-box-profilo-funzionamento class="w100"
                                                               [profiloFunzionamento]="settaggioTrad.settaggiTlcNodo.tlcProfiloFunzionamento">
                                    <div titleDescription style="font-weight: bold">
                                        <button mat-icon-button color="warn"
                                                (click)="$event.stopPropagation();destroyCalendar(settaggioTrad.settaggiTlcNodo)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                        &nbsp; {{'week.' + settaggioTrad.settaggiTlcNodo.giornoSettimana|translate}}
                                    </div>
                                </app-box-profilo-funzionamento>
                            </ng-container>
                            <ng-template #Traduction>
                                <mat-expansion-panel class="w100" [disabled]="true"
                                                     style="opacity: 1;color: black">
                                    <mat-expansion-panel-header style="opacity: 1;color: black">
                                        <mat-panel-title>
                                            <span style="margin-left: 50px;font-weight: bold">
                                            {{'week.' + settaggioTrad.day|translate}}
                                            </span>
                                        </mat-panel-title>
                                        <mat-panel-description style="opacity: 1">
                                            100%
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                </mat-expansion-panel>
                            </ng-template>
                        </ng-container>
                    </div>

                </mat-card-content>
            </mat-card>
        </ng-container>
        <ng-template #nessunQuadro>
            <div class="container-otherForm">
                <div class="container-linee">
                    <div class="items-text-100">
                        <mat-icon color="warn">error</mat-icon>
                    </div>
                    <div class="items-linee items-width-80">
                        <h4>{{'lightMate.whithoutCircuit' | translate}}</h4>
                    </div>
                </div>
            </div>
        </ng-template>
    </app-template-form>
</app-container>


