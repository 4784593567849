import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {emitMenu, FileManagerService} from "../../../providers/services/file-manager.service";
import {DocumentsFileParse} from "../../../models/DocumentsFile.Parse";
import {NgxDropzoneChangeEvent} from "ngx-dropzone";
import {AlertService} from "../../../providers/services/alert.service";
import {TranslateService} from "@ngx-translate/core";
import {MoveFolderPopUpService} from "../../../providers/services/move-folder-pop-up.service";
import {isNotNullOrUndefined, traductionFunctionMasterFolder} from "../../../models/Models";

@Component({
    selector: 'app-connected-files',
    templateUrl: './connected-files.component.html',
    styleUrls: ['./connected-files.component.scss']
})
export class ConnectedFilesComponent implements OnInit {

    @Input() connectedFiles;
    @Input() newConnectedFiles;
    @Input() visualizedRefreshButton = true;
    @Input() visualizedDownloadButton = true;
    @Input() visualizedPreviewButton = true;
    @Input() openDialogRemoveButton = true;
    @Input() boxResize = false;
    @Input() loading = false;
    @Output() addingFile = new EventEmitter();
    @Output() refreshButton = new EventEmitter();
    @Output() searchButton = new EventEmitter();
    @Output() removingFile = new EventEmitter<{ definitely: boolean, files: DocumentsFileParse[] }>();


    constructor(private fileManagerService: FileManagerService,
                private alertService: AlertService,
                private translateService: TranslateService,
                private moveFolderPopUpService: MoveFolderPopUpService
    ) {
    }

    ngOnInit(): void {
    }

    getTypeFile(file) {
        return this.fileManagerService.getTypeFile(file);
    }


    openRemoveFileDialog(file) {
        if (this.openDialogRemoveButton) {
            const message = {
                deconnect: this.translateService.instant('fileManager.connectedFile.removeConnectedFile'),
                removeToDataBase: this.translateService.instant('fileManager.connectedFile.removedDefinitevly')
            }
            this.fileManagerService.openDeconnectFile(file, message)
                .subscribe(
                    result => {
                        if (result) {
                            this.removingFile.next({definitely: true, files: [file]})
                        } else {
                            this.removingFile.next({definitely: false, files: [file]})
                        }
                    }
                )
        } else {
            this.removingFile.next({definitely: true, files: [file]})

        }

    }

    clickMenuFile(event: emitMenu, file: DocumentsFileParse) {
        if (event == 'preview') {
            this.fileManagerService.openFile(file);
        } else if (event == 'download') {
            this.fileManagerService.downloadFile(file);
        } else if (event == 'remove') {
            this.openRemoveFileDialog(file);
        }
    }

    dbClickFile(file: DocumentsFileParse) {
    }

    clickFile(file: DocumentsFileParse) {
        this.fileManagerService.openFile(file);
    }

    addFileToCurrentElement(files: NgxDropzoneChangeEvent) {
        this.addingFile.emit(files.addedFiles);
    }

    clickSearchButton() {
        this.moveFolderPopUpService.openMoveToFoldersFiles().subscribe(
            result => {
                if (result && result.files) {
                    this.searchButton.emit(result)
                } else {
                    this.searchButton.emit(undefined)
                }
            }
        )
    }

    clickRefresh() {
        this.refreshButton.emit(true);
    }

    getNameParentFolder(file: DocumentsFileParse): string {
        return (isNotNullOrUndefined(file) && isNotNullOrUndefined(file.parentFolder) && isNotNullOrUndefined(file.parentFolder.name)) ? file.parentFolder.name : this.fileManagerService.projectName
    }


    get isSetNewConnectedFile() {
        return Array.isArray(this.newConnectedFiles) && this.newConnectedFiles.length > 0
    }

    traductionFunction = traductionFunctionMasterFolder;

}
