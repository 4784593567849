import {Component, Input, OnInit, NgZone} from '@angular/core';
import {FilterService} from "../../providers/services/filter.service";
import {UserService} from "../../providers/services/user.service";
import {UserRole} from "../../../config/static-data";
import {TranslateService} from "@ngx-translate/core";
import {UtilsService} from "../../providers/services/utils.service";
import {ProjectService} from "../../providers/services/project.service";
import {isNotNullOrUndefined} from "../../models/Models";

@Component({
    selector: 'app-box-circuit',
    templateUrl: './box-circuit.component.html',
    styleUrls: ['./box-circuit.component.scss']
})
export class BoxCircuitComponent implements OnInit {

    private static MS_PER_HOUR = 1000 * 60 * 60;
    private static MAX_HOURS = 4;

    @Input() targa;
    @Input() objectId;
    @Input() statoAccensione;
    @Input() statoAnalizzatore;
    @Input() statoCrepuscolare;
    @Input() statoPresenzaRete;
    @Input() accensioneConOrologio;

    onTime;
    offTime;
    ultimaData;
    isOldDate;

    @Input() statoGuasto;
    @Input() ultimaPotenza;

    @Input('ultimaData') set _ultimaData(value) {
        this.ultimaData = value;
        this.isOldDate = this.dateDiffInHours(value) > BoxCircuitComponent.MAX_HOURS;
    }

    @Input('onTime') set _onTime(value: number) {
        this.onTime = this.mapNumber(value);
    }

    @Input('offTime') set _offTime(value) {
        this.offTime = this.mapNumber(value);
    }

    constructor(
        private filterService: FilterService,
        private userService: UserService,
        private translate: TranslateService,
        private utilsService: UtilsService,
        private ngZone: NgZone,
        private projectService: ProjectService
    ) {
    }

    ngOnInit() {
    }

    async chooseOpen() {
        await this.ngZone.run(() => {
            // this.router.navigate(['/controllo-remoto/' + this.objectId]);
        });
    }


    public get isGestore() {
        return (isNotNullOrUndefined(this.projectService.actualRoles)) ? this.projectService.actualRoles.includes(UserRole.GESTORE) : false;
    }


    private mapNumber = (value: number) => {
        if (value < 0) {
            return this.translate.instant('mixed');
        }
        let n1: any = Math.floor(value);
        let n2: any = Math.floor((value - Math.floor(value)) * 60);
        // let n = `${Math.floor(value)}:${Math.floor((value - Math.floor(value)) * 60)}`;
        if (n1 < 10) {
            n1 = '0' + n1.toString();
        }
        if (n2 < 10) {
            n2 = '0' + n2.toString();
        }
        return n1 + ':' + n2;
        // return `${Math.floor(value)}:${Math.floor((value - Math.floor(value)) * 60)}`;
    }

    private dateDiffInHours(a) {
        const now = new Date();
        if (a != null) {
            // Esclude l'ora ed il fuso orario
            return Math.floor((now.getTime() - a.getTime()) / BoxCircuitComponent.MS_PER_HOUR);
        } else return null
    }


    public resizeText() {
        if (this.targa.length < 10) {
            return null;
        } else if (this.targa.length > 8 && this.targa.length < 14) {
            return 15 + 'px';
        } else {
            this.targa = this.targa.substring(0, 12) + '...';
            return 15 + 'px';
        }
    }

}
