import {className, stringIsSet} from "../Models";
import {configurationElement, typeDatabase, typeFormValue} from "./configurationPropertyUtils";
import {CircuitiParse} from "../Circuiti.Parse";
import {PuntiLuceParse} from "../PuntiLuce.Parse";
import {DocumentsFileParse} from "../DocumentsFile.Parse";


export const configurationPropertyCaricoEsogeno: configurationElement[] =
    [{
        keyName: 'createdAt',
        varianteKey: '',
        traductionKey: 'createdAt',
        typeForm: typeFormValue.DATE,
        typeDatabase: typeDatabase.DATE,
        editable: false,
        showInForm: false,
        filtrable: false,
        copyable: false,
        required: false,
        possibleValues: undefined,
        pointer: undefined,
        sortingValue: 200,
        section: 0,
        forms: undefined
    }, {
        keyName: 'updatedAt',
        varianteKey: '',
        traductionKey: 'updatedAt',
        typeForm: typeFormValue.DATE,
        typeDatabase: typeDatabase.DATE,
        editable: false,
        showInForm: false,
        filtrable: false,
        copyable: false,
        required: false,
        possibleValues: undefined,
        pointer: undefined,
        sortingValue: 300,
        section: 0,
        forms: undefined
    }, {
        keyName: 'dataAttivazione',
        varianteKey: '',
        traductionKey: 'dashboard_sidenav.CaricoEsogeno.dataAttivazione',
        typeForm: typeFormValue.DATE,
        typeDatabase: typeDatabase.DATE,
        editable: true,
        showInForm: true,
        filtrable: true,
        copyable: false,
        required: false,
        possibleValues: undefined,
        pointer: undefined,
        sortingValue: 400,
        section: 0,
        forms: undefined
    }, {
        keyName: 'dataDisattivazione',
        varianteKey: '',
        traductionKey: 'dashboard_sidenav.CaricoEsogeno.dataDisattivazione',
        typeForm: typeFormValue.DATE,
        typeDatabase: typeDatabase.DATE,
        editable: true,
        showInForm: true,
        filtrable: true,
        copyable: false,
        required: false,
        possibleValues: undefined,
        pointer: undefined,
        sortingValue: 500,
        section: 0,
        forms: undefined
    }, {
        keyName: 'circuito',
        varianteKey: '',
        traductionKey: 'circuito',
        typeForm: typeFormValue.ELENCO_VINCOLATO,
        typeDatabase: typeDatabase.POINTER,
        editable: true,
        showInForm: true,
        filtrable: true,
        copyable: true,
        required: false,
        possibleValues: undefined,
        pointer: {
            className: className.circuiti,
            getValue: (circuito: CircuitiParse) => {
                return circuito.numeroQuadro;
            },
            setValue: (value: string) => {
                if (stringIsSet(value)) {
                    return {className: className.circuiti, numeroQuadro: value};
                } else {
                    return undefined
                }
            },
            getId: (circuito: CircuitiParse) => {
                return circuito.objectId;
            },
        },
        sortingValue: 600,
        section: 0,
        forms: undefined
    }, {
        keyName: 'puntoLuce',
        varianteKey: '',
        traductionKey: 'puntoLuce',
        typeForm: typeFormValue.ELENCO_VINCOLATO,
        typeDatabase: typeDatabase.POINTER,
        editable: true,
        showInForm: true,
        filtrable: true,
        copyable: true,
        required: false,
        possibleValues: undefined,
        pointer: {
            className: className.puntiLuce,
            getValue: (puntoLuce: PuntiLuceParse) => {
                const getValue = (puntoLuce) => {
                    if (puntoLuce.getTarga != null) {
                        return puntoLuce.getTarga();
                    } else if (puntoLuce.targaCustom != null) {
                        return puntoLuce.targaCustom;
                    } else if (puntoLuce.targa != null) {
                        return puntoLuce.targa;
                    }
                    return undefined
                }
                return getValue(puntoLuce);
            },
            setValue: (value: string) => {
                if (stringIsSet(value)) {
                    return {className: className.puntiLuce, targa: value};
                } else {
                    return undefined
                }
            },
            getId: (puntoLuce: PuntiLuceParse) => {
                return puntoLuce.objectId;
            }
        },
        sortingValue: 700,
        section: 0,
        forms: undefined
    }, {
        keyName: 'tipologia',
        varianteKey: undefined,
        traductionKey: 'dashboard_sidenav.CaricoEsogeno.tipologia',
        typeForm: typeFormValue.ELENCO_APERTO,
        typeDatabase: typeDatabase.STRING,
        editable: true,
        showInForm: true,
        filtrable: true,
        copyable: false,
        required: true,
        possibleValues: [
            {
                dataBaseValue: 'VIDEOCAMERA',
                traduction: 'dashboard_sidenav.CaricoEsogeno.VIDEOCAMERA'
            }, {
                dataBaseValue: 'FONTANA',
                traduction: 'dashboard_sidenav.CaricoEsogeno.FONTANA'
            }, {
                dataBaseValue: 'HOTSPOT',
                traduction: 'dashboard_sidenav.CaricoEsogeno.HOTSPOT'
            },
        ],
        pointer: undefined,
        sortingValue: 800,
        section: 0,
        forms: undefined
    }, {
        keyName: 'potenzaNominale',
        varianteKey: undefined,
        traductionKey: 'dashboard_sidenav.CaricoEsogeno.potenzaNominale',
        typeForm: typeFormValue.INPUT_NUMBER,
        typeDatabase: typeDatabase.NUMBER,
        editable: true,
        showInForm: true,
        filtrable: true,
        copyable: false,
        required: false,
        possibleValues: undefined,
        pointer: undefined,
        sortingValue: 900,
        section: 0,
        forms: undefined
    }, {
        keyName: 'potenzaEffettiva',
        varianteKey: undefined,
        traductionKey: 'dashboard_sidenav.CaricoEsogeno.potenzaEffettiva',
        typeForm: typeFormValue.INPUT_NUMBER,
        typeDatabase: typeDatabase.NUMBER,
        editable: true,
        showInForm: true,
        filtrable: true,
        copyable: false,
        required: false,
        possibleValues: undefined,
        pointer: undefined,
        sortingValue: 1000,
        section: 0,
        forms: undefined
    }, {
        keyName: 'oreFunzionamentoAnno',
        varianteKey: undefined,
        traductionKey: 'dashboard_sidenav.CaricoEsogeno.oreFunzionamentoAnno',
        typeForm: typeFormValue.INPUT_NUMBER,
        typeDatabase: typeDatabase.NUMBER,
        editable: true,
        showInForm: true,
        filtrable: true,
        copyable: false,
        required: false,
        possibleValues: undefined,
        pointer: undefined,
        sortingValue: 1100,
        section: 0,
        forms: undefined
    }, {
        keyName: 'oreFunzionamentoGiorno',
        varianteKey: undefined,
        traductionKey: 'dashboard_sidenav.CaricoEsogeno.oreFunzionamentoGiorno',
        typeForm: typeFormValue.INPUT_NUMBER,
        typeDatabase: typeDatabase.NUMBER,
        editable: true,
        showInForm: true,
        filtrable: true,
        copyable: false,
        required: false,
        possibleValues: undefined,
        pointer: undefined,
        sortingValue: 1200,
        section: 0,
        forms: undefined
    }, {
        keyName: 'consumoAnno',
        varianteKey: undefined,
        traductionKey: 'dashboard_sidenav.CaricoEsogeno.consumoAnno',
        typeForm: typeFormValue.INPUT_NUMBER,
        typeDatabase: typeDatabase.NUMBER,
        editable: true,
        showInForm: true,
        filtrable: true,
        copyable: false,
        required: false,
        possibleValues: undefined,
        pointer: undefined,
        sortingValue: 1300,
        section: 0,
        forms: undefined
    }, {
        keyName: 'note',
        varianteKey: undefined,
        traductionKey: 'dashboard_sidenav.CaricoEsogeno.note',
        typeForm: typeFormValue.INPUT_TEXT,
        typeDatabase: typeDatabase.STRING,
        editable: true,
        showInForm: true,
        filtrable: false,
        copyable: false,
        required: false,
        possibleValues: undefined,
        pointer: undefined,
        sortingValue: 1300,
        section: 0,
        forms: undefined
    }, {
        keyName: 'foto',
        varianteKey: '',
        traductionKey: 'foto',
        typeForm: typeFormValue.IMAGE,
        typeDatabase: typeDatabase.POINTER,
        editable: true,
        showInForm: true,
        filtrable: true,
        copyable: true,
        required: false,
        headerImport: false,
        possibleValues: undefined,
        pointer: {
            className: className.documentsFile,
            getValue: (file: DocumentsFileParse) => {
                const url = file.file != null && stringIsSet(file.file.url()) ? file.file.url() : undefined;
                const name = file.file != null && stringIsSet(file.file.name()) ? file.file.name() : undefined;
                return {url, name}
            },
            getValueExport: (file: DocumentsFileParse) => {
                const name = file.file != null && stringIsSet(file.file.name()) ? file.file.name() : undefined;
                return name
            },
            getId: (file: DocumentsFileParse) => {
                return file.objectId;
            }
        },
        sortingValue: 1400,
        section: 0,
        forms: undefined
    },
    ];
//



